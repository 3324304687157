import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Col,
  Row,
  Button,
  Card,
  Modal,
  notification,
  Spin,
} from "antd";
import {
  RightOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import LocationDetails from "./spaceOverviewTabs/LocationDetails";
import phantomDevice from "../../assets/common-phantom-device.png";
import userGroup from "../../assets/common-add-user.png";
import location from "../../assets/location.png";
import { onboardingBaseUrl } from "../../common/BaseUrl";
import locationImg from "../../assets/locationImg.png";
import "./Styles.css";
import LoadingSpinner from "../../common/pageLoader/loadingSpinner";
import MapModal from "./spaceOverviewTabs/MapModal";
import AddLocationForm from "./spaceOverviewTabs/AddLocationForm";
import FilterForm from "../../services/headerFilter/FilterForm";

const OnBoarding = () => {
  const navigate = useNavigate();
  const accessToken = window.localStorage.getItem("accessToken");
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const deviceType = window.localStorage.getItem("devicetype");
  const [locationDetails, setLocationDetails] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [deleteElevator, setDeleteElevator] = useState();
  const [listLoading, setlistloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openNewLocation, setOpenNewLocation] = useState(false);
  const [loacationdataloading, setLoacationdataloading] = useState(false);
  const [mapModal, setMapModal] = useState(false);
  const [storeResult, setStoreResult] = useState();
  const [parentAllData, setParentAllData] = useState();
  const [countryName, SetCountryName] = useState();
  const [cityName, SetCityName] = useState();
  const [locationName, SetLocationName] = useState();
  const [buildingName, SetBuildingName] = useState();
  // eslint-disable-next-line no-unused-vars
  const [elevatorName, SetElevatorName] = useState();
  const [filterHeaderapiLoader, setFilterHeaderapiLoader] = useState(false);
  const divHeight = window.localStorage.getItem("SpaceHeight");
  const [newdivHeight, setNewdivHeight] = useState();
  useEffect(() => {
    setNewdivHeight(divHeight - 195);
  }, [divHeight]);
  const onboardingData = [
    {
      key: "1",
      title: "Location details",
      icon: location,
      state: false,
      tabsState: false,
      content: "",
      items: <LocationDetails />,
      label: `Location Details`,
    },

    {
      title: `${deviceType} Details`,
      icon: phantomDevice,
      state: false,
      tabsState: false,
      label: `Elevator Details`,
      key: "2",
      // children: <PhantomDevice />,
    },
    {
      title: "User & Groups",
      icon: userGroup,
      state: false,
      tabsState: false,
      label: `Users & Group`,
      key: "6",
      // children: <UsersGroup />,
    },
  ];

  const hideModal = () => {
    setOpen(false);
  };
  const ConfirmeModal = () => {
    if (deleteElevator) {
      setLoacationdataloading(true);
      axios
        .post(
          `${onboardingBaseUrl}/deleteLocation/${deleteElevator?._id}`,
          {},
          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((Response) => {
          setOpen(false);
          setLoacationdataloading(false);
          setlistloading(!listLoading);

          if (Response?.data?.data?.message === "Device Deleted Successfully") {
            notification.success({
              description: "Device Deleted Successfully",
            });
          }
        });
    }
  };


  useEffect(() => {
    axios
      .post(
        `${onboardingBaseUrl}/list`,
        {
          PARENT_ID: userProfile?.["custom:parent_id"],
          TENANT_ID: userProfile?.["custom:tenant_id"],
          COUNTRY: countryName,
          CITY: cityName,
          LOCATION: locationName,
          STORE_ID: buildingName,
        },
        {
          headers: { ["x-access-Token"]: accessToken },
        }
      )
      .then((response) => {
        setLocationDetails(response?.data?.data);
        setIsloading(false);
      })
      .catch(() => {
        setIsloading(false);
      });
  }, [filterHeaderapiLoader, listLoading]);
  useEffect(() => {
    axios
      .post(
        `${onboardingBaseUrl}/parentDetailsList`,
        {},
        {
          headers: { ["x-access-token"]: accessToken },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          setParentAllData(response?.data);
        }
      });
  }, []);

  return (
    <div >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="content-container"
      //  className="MainLayoutCss
      //  " style={{display:"flex", flexDirection:"column",overflow:"hidden"}}
        >
        <div className="layoutStyle">
          <Row className="headerRow">
            {onboardingData?.map((step, index) => {
              return (
                <Col key={index} md={4} xs={8} style={{ marginBottom: "60px" }}>
                  <div
                    className="circleStyle"
                    style={{
                      backgroundColor: step?.state
                        ? "#005691"
                        : step?.tabsState && "#005691",
                    }}
                  >
                    {step?.tabsState ? (
                      <CheckOutlined style={{ marginTop: "15px" }} />
                    ) : (
                      <img src={step.icon} className="center" />
                    )}
                    <label className="labelStyle">{step.title}</label>
                  </div>

                  {index < onboardingData?.length - 1 && (
                    <div className="lineStyle"></div>
                  )}
                </Col>
              );
            })}
            <img
              src={locationImg}
              height="120px"
              style={{ marginLeft: "20vw" }}
            />
          </Row>
  
            <div style={{backgroundColor:"#d9dbe0",height:"50px"}}>
                <Button
                  type="primary"
                  style={{
                    color: "white",
                    margin: "2px 10px",
                    height: "40px",
                    float: "right"
                  }}
                  onClick={() => {
                    setOpenNewLocation(true);
                  }}
                >
                  Add New Location
                </Button>
                <FilterForm     
                filtertype="onboarding"
                SetCountryName={SetCountryName}
                SetCityName={SetCityName}
                SetLocationName={SetLocationName}
                SetBuildingName={SetBuildingName}
                SetElevatorName={SetElevatorName}
                setFilterHeaderapiLoader={setFilterHeaderapiLoader}
                filterHeaderapiLoader={filterHeaderapiLoader}/>
              </div>

              </div>
          <div 
          // className="card-container"
          style={{height:`${newdivHeight}px`, overflowY:"auto"}}
          >
          <Spin
                spinning={loacationdataloading}
                tip="Loading..."
                size="large"
              >
                <Row>
                  {locationDetails?.map((loc, index) => {
                    return (
                      <Col
                      key={index}
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 12 }}
                        sm={{ span: 12 }}
                        xs={{ span: 24 }}
                      >
                        <Card
                          style={{
                            boxShadow: "0 14px 28px rgba(0,0,0,0.25)",
                            position: "relative",
                            margin: "10px",
                            borderRadius: "10px",
                            height: "200px",
                          }}
                        >
                          <div
                            className="spectrum-background_LocationCard"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              backgroundColor: "##dfdfe0",
                            }}
                          >
                            <span className="LocationStoreName">
                              <Button
                                onClick={() => {
                                  setDeleteElevator(loc);
                                  setOpen(true);
                                }}
                                style={{ borderRadius: "10px" }}
                              >
                                <DeleteOutlined />
                              </Button>
                            </span>

                            <span className="LocationStoreName">
                              <RightOutlined
                                onClick={() => {
                                  navigate("/on-boarding-overview", {
                                    state: loc,
                                  });
                                }}
                              />
                            </span>
                          </div>

                          <div style={{ marginTop: "30px" }}>
                            <div
                              style={{ fontSize: "18px", fontWeight: "bold" }}
                            >
                              {loc?.STORE_NAME}
                            </div>
                            <div>{loc?.ADDRESS?.toUpperCase()}</div>
                            <div>{loc?.CITY}</div>
                            {/* <div>{loc?.state}</div> */}
                            <div>{loc?.ZIPCODE}</div>
                          </div>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Spin>
          </div>
        
        </div>
      )}
      <Modal
        open={open}
        onOk={ConfirmeModal}
        onCancel={hideModal}
        okText="Confirm "
        cancelText="Cancel"
      >
        <div
          style={{ textAlign: "center", fontSize: "80px", color: "#008ecf" }}
        >
          <ExclamationCircleOutlined />
        </div>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          ARE YOU SURE YOU WANT TO REMOVE THIS LOCATION?
        </h3>
      </Modal>
      <Modal
        title="Add Location Details"
        // centered
        open={openNewLocation}
        onOk={() => setOpenNewLocation(false)}
        onCancel={() => setOpenNewLocation(false)}
        width={1000}
        footer={null}
      >
        <div style={{ height: "500px", overflow: "auto" }}>
          <AddLocationForm
            parentAllData={parentAllData}
            setOpenNewLocation={setOpenNewLocation}
            setMapModal={setMapModal}
            storeResult={storeResult}
            userData={locationDetails}
            setlistloading={setlistloading}
            listLoading={listLoading}
          />
        </div>
      </Modal>
      <Modal
        title="Select Location"
        width={1000}
        open={mapModal}
        onOk={() => setMapModal(false)}
        onCancel={() => setMapModal(false)}
        footer={
          <Button
            onClick={() => {
              setMapModal(false);
            }}
          >
            OK
          </Button>
        }
        closable={false}
        style={{ marginTop: "-65px" }}
      >
        <MapModal setStoreResult={setStoreResult} />
      </Modal>
    </div>
  );
};

export default OnBoarding;







// <Form form={form} onValuesChange={handleFormValuesChange}>
// <Row
//   gutter={15}
//   style={{
//     padding: "10px 10px",
//     backgroundColor: "#d8d8d8",
//     height: "60px",
//   }}
// >
//   <Col
//     xl={{ span: 3 }}
//     lg={{ span: 3 }}
//     md={{ span: 8 }}
//     sm={{ span: 10 }}
//     xs={{ span: 24 }}
//     style={{ margin: "0px 5px 0px 5px" }}
//   >
//     <div>
//       <Form.Item name="country">
//         <Select
//           style={{
//             width: "100%",
//           }}
//           onChange={(value, key) => {
//             setSelectIndex({ ...selectIndex, country: key.key });
//           }}
//           placeholder="Countries"
//         >
//           {filterData?.map((country, index) => {
//             return (
//               <Option value={country?.COUNTRY} key={index}>
//                 {country?.COUNTRY}
//               </Option>
//             );
//           })}
//         </Select>
//       </Form.Item>
//     </div>
//   </Col>
//   <Col
//     xl={{ span: 3 }}
//     lg={{ span: 3 }}
//     md={{ span: 8 }}
//     sm={{ span: 10 }}
//     xs={{ span: 24 }}
//     style={{ margin: "0px 5px 0px 5px" }}
//   >
//     <div>
//       <Form.Item name="city">
//         <Select
//           style={{
//             width: "100%",
//           }}
//           onChange={(value, key) => {
//             setSelectIndex({ ...selectIndex, city: key.key });
//           }}
//           placeholder="City"
//           disabled={selectIndex?.country ? false : true}
//         >
//           {filterData?.[selectIndex?.country]?.CITIES?.map(
//             (city, cityIndex) => {
//               return (
//                 <Option value={city?.CITY} key={cityIndex}>
//                   {city?.CITY}
//                 </Option>
//               );
//             }
//           )}
//         </Select>
//       </Form.Item>
//     </div>
//   </Col>
//   <Col
//     xl={{ span: 3 }}
//     lg={{ span: 3 }}
//     md={{ span: 8 }}
//     sm={{ span: 10 }}
//     xs={{ span: 24 }}
//     style={{ margin: "0px 5px 0px 5px" }}
//   >
//     <div>
//       <Form.Item name="location">
//         <Select
//           style={{
//             width: "100%",
//           }}
//           onChange={(value, key) => {
//             setSelectIndex({ ...selectIndex, location: key.key });
//           }}
//           placeholder="Location"
//           disabled={
//             selectIndex?.country && selectIndex?.city
//               ? false
//               : true
//           }
//         >
//           {selectIndex?.city &&
//             filterData?.[selectIndex?.country]?.CITIES?.[
//               selectIndex?.city
//             ].LOCATION?.map((store, storeIndex) => {
//               return (
//                 <Option value={store?.LOCATION} key={storeIndex}>
//                   {store?.LOCATION}
//                 </Option>
//               );
//             })}
//         </Select>
//       </Form.Item>
//     </div>
//   </Col>
//   <Col
//     xl={{ span: 3 }}
//     lg={{ span: 3 }}
//     md={{ span: 8 }}
//     sm={{ span: 10 }}
//     xs={{ span: 24 }}
//     style={{ margin: "0px 5px 0px 5px" }}
//   >
//     <div>
//       <Form.Item name="building">
//         <Select
//           style={{
//             width: "100%",
//           }}
//           onChange={(value, key) => {
//             setSelectIndex({ ...selectIndex, store: key.key });
//           }}
//           placeholder="Building"
//           disabled={
//             selectIndex?.country && selectIndex?.city
//               ? false
//               : true
//           }
//         >
//           {selectIndex?.location &&
//             filterData?.[selectIndex?.country]?.CITIES?.[
//               selectIndex?.city
//             ].LOCATION?.[selectIndex?.location]?.STORE?.map(
//               (store, storeIndex) => {
//                 return (
//                   <Option
//                     value={store?.STORE_ID}
//                     key={storeIndex}
//                   >
//                     {store?.STORE_NAME}
//                   </Option>
//                 );
//               }
//             )}
//         </Select>
//       </Form.Item>
//     </div>
//   </Col>
//   <Col
//     xl={{ span: 3 }}
//     lg={{ span: 3 }}
//     md={{ span: 8 }}
//     sm={{ span: 10 }}
//     xs={{ span: 24 }}
//   >
//     <RedoOutlined
//       onClick={resetData}
//       onMouseEnter={() => {
//         setHover(true);
//       }}
//       onMouseLeave={() => {
//         setHover(false);
//       }}
//       style={{
//         fontSize: hover === true ? "22px" : "20px",
//         marginTop: "8px",
//         color: hover === true ? "#1890FF" : "black",
//         fontWeight: "bold",
//       }}
//     />
//   </Col>
// </Row>
// </Form>