/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  Button,
  Col,
  Form,
  notification,
  Modal,
  Row,
  Select,
  Radio,
  Table,
  Space,
  message,
  Popconfirm,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { baseUrl } from "../../../common/BaseUrl";
import axios from "axios";
import "../Styles.css";
const UsersGroup = ({ locationData }) => {
  const [form] = Form.useForm();
  const accessToken = window.localStorage.getItem("accessToken");
  const [addUserModal, setAddUserModal] = useState(false);
  const [userData, setUserData] = useState([]);
  const [updateRole, setUpdateRole] = useState();
  const [userOption, setUseOption] = useState();
  const [allUser, setAllUser] = useState();
  const [allAreaUser, setAllAreaUser] = useState();
  const [userLoader, setUserLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [removeUser, setRemoveUser] = useState();
  const divHeight = window.localStorage.getItem("SpaceHeight");
  const [newdivHeight, setNewdivHeight] = useState();
  useEffect(() => {
    setNewdivHeight(divHeight - 240);
  }, [divHeight]);
  const confirm = () => {
    if (removeUser) {
      axios
        .post(
          `${baseUrl}/changeAttributes`,
          {
            flag: "remove",
            role:
              removeUser?.USER_TYPE === "STORE INCHARGE"
                ? "store-incharge"
                : removeUser?.USER_TYPE === "EXECUTIVE AREA"
                ? "area-executive"
                : removeUser?.USER_TYPE === "OPERATOR" && "operator",
            username: removeUser?.EMAIL_ID,
            CITY: locationData?.CITY,
            store_id:
              removeUser?.USER_TYPE === "OPERATOR" ||
              removeUser?.USER_TYPE === "STORE INCHARGE"
                ? locationData?.STORE_ID
                : undefined,
            location:
              removeUser?.USER_TYPE === "EXECUTIVE AREA" ||
              removeUser?.USER_TYPE === "STORE INCHARGE"
                ? locationData?.LOCATION
                : undefined,
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then(() => {
          setTableLoader(!tableLoader);
          setUserLoader(!userLoader);
        });
    }
    message.success("User Deleted Successfully");
  };
  const cancel = () => {};
  const options = [
    {
      value: "EXECUTIVE AREA",
      label: "Area executive",
    },
    {
      value: "STORE INCHARGE",
      label: "Building in charge",
    },
    {
      value: "OPERATOR",
      label: "Maintenance engineer",
    },
  ];
  const columns = [
    {
      title: "Name",
      dataIndex: "NAME",
      width:"100px"
    },
    {
      title: "Email ID",
      dataIndex: "EMAIL_ID",
      width:"200px"
    },
    {
      title: "Cell Phone",
      dataIndex: "PHONE",
      width:"130px"
    },
    
    {
      title: "Area executive",
      dataIndex: "USER_TYPE",
      align: "center",
      width:"120px",
      render: (role) => <Radio checked={role === "EXECUTIVE AREA"} />,
    },
    {
      title: "Building in charge",
      dataIndex: "USER_TYPE",
      align: "center",
      width:"140px",
      render: (role) => <Radio checked={role === "STORE INCHARGE"} />,
    },
    {
      title: "Maintenance engineer",
      dataIndex: "USER_TYPE",
      align: "center",
      width:"180px",
      render: (role) => <Radio checked={role === "OPERATOR"} />,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width:"80px",
      render: (text, record, index) => (
        <Popconfirm
          title="Delete the User"
          description="Are you sure to delete this User?"
          onConfirm={confirm}
          onCancel={cancel}
          placement="left"
          okText="Yes"
          cancelText="No"
        >
          <Button onClick={() => setRemoveUser(record)}>
            <DeleteOutlined />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    if (updateRole) {
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: [updateRole],
            COUNTRY: locationData?.COUNTRY,
            CITY: locationData?.CITY,
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setUseOption(response?.data?.data);
        });
    }
  }, [updateRole, userLoader]);

  let allUserData = [];
  useEffect(() => {
    axios
      .post(
        `${baseUrl}/roleWiseUsers`,
        {
          ROLES: ["STORE INCHARGE", "OPERATOR"],
          COUNTRY: locationData?.COUNTRY,
          CITY: locationData?.CITY,
          STORE_ID: locationData?.STORE_ID?.toString(),
        },

        { headers: { ["x-access-token"]: accessToken } }
      )
      .then((response) => {
        setAllUser(response?.data?.data);
      });

    axios
      .post(
        `${baseUrl}/roleWiseUsers`,
        {
          ROLES: ["EXECUTIVE AREA"],
          COUNTRY: locationData?.COUNTRY,
          CITY: locationData?.CITY,
          LOCATION: locationData?.LOCATION,
        },

        { headers: { ["x-access-token"]: accessToken } }
      )
      .then((response) => {
        setAllAreaUser(response?.data?.data);
      });
  }, [userLoader, tableLoader]);

  if (allUser) {
    allUser?.map((item, key) => {
      allUserData?.push(item);
    });
  }
  if (allAreaUser) {
    allAreaUser?.map((item, key) => {
      allUserData?.push(item);
    });
  }

  let userOptionData = [];
  if (userOption) {
    userOption?.map((item, key) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        value: item?.EMAIL_ID,
      });
    });
  }

  const handleChange = (value) => {
    setUpdateRole(value);
  };

  const onFinish = (values) => {
    setUserData([...userData, values]);
    let params = {
      role:
        values?.userRole === "OPERATOR"
          ? "operator"
          : values?.userRole === "STORE INCHARGE"
          ? "store-incharge"
          : values?.userRole === "EXECUTIVE AREA" && "area-executive",
      username: values?.userMail,
      CITY: locationData?.CITY,
    };
    if (
      values?.userRole === "OPERATOR" ||
      values?.userRole === "STORE INCHARGE"
    ) {
      params.store_id = locationData?.STORE_ID;
    }
    if (
      values?.userRole === "EXECUTIVE AREA" ||
      values?.userRole === "STORE INCHARGE"
    )
      params.location = locationData?.LOCATION;

    axios
      .create({
        validateStatus: () => true,
      })
      .post(`${baseUrl}/changeAttributes`, params, {
        headers: { ["x-access-token"]: accessToken },
      })
      .then((response) => {
        form.resetFields();
        setTableLoader(!tableLoader);
        setUserLoader(!userLoader);
        if (response?.data?.code === 200) {
          notification.success({
            description: "Successfully Added",
          });
        }
        if (response.data.code == 400 || response.data.code == 500) {
          notification.error({
            description: response?.data?.message,
          });
        }
      });

    setAddUserModal(false);
  };
  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "userRole") {
      form.setFieldsValue({ userMail: undefined });
    }
  };
  return (
    <>
      <div style={{ float: "right", marginTop: "-45px" }}>
        <Button
          type="primary"
          style={{ color: "white", margin: "2px 10px" }}
          onClick={() => setAddUserModal(true)}
        >
          Add User
        </Button>
      </div>
      <div
        style={{
          backgroundColor: "#e6f2fc",
        }}
      >
        <Table
          columns={columns}
          dataSource={allUserData}
          scroll={{ y: newdivHeight }}
          size="small"
          pagination={false}
        />

        <Modal
          width={1000}
          title="Add User"
          open={addUserModal}
          onOk={() => setAddUserModal(false)}
          onCancel={() => {
            setAddUserModal(false);
            form.resetFields();
          }}
          footer={false}
          className="location"
        >
          <Form
            name="basic"
            className="backgroundStyle"
            labelCol={{
              span: 24,
            }}
            form={form}
            onFinish={onFinish}
            onValuesChange={handleFormValuesChange}
            autoComplete="off"
          >
            <Row gutter={15}>
              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  name="userRole"
                  label="Role"
                  rules={[
                    {
                      required: true,
                      message: `Please select the Role`,
                    },
                  ]}
                >
                  <Select
                    // placeholder={"Operator"}
                    onChange={handleChange}
                    options={options}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12}>
                <Form.Item
                  name="userMail"
                  label="User"
                  rules={[
                    {
                      required: true,
                      message: `Please select the Role`,
                    },
                  ]}
                >
                  <Select placeholder={"User"} options={userOptionData} />
                </Form.Item>
              </Col>
            </Row>

            <div style={{ display: "flex", justifyContent: "right" }}>
              <Form.Item>
                <Button
                  className="addButton"
                  onClick={() => setAddUserModal(false)}
                >
                  Cancel
                </Button>
                <Button className="saveButton" htmlType="submit">
                  Save
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default UsersGroup;