import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import Logo from "../assets/phantom-logo.png";
import Equipment from "../assets/Equipment.png";
import Logo2 from "../assets/bosch-logo.png";
import Strip from "../assets/Strip.png";
import {
  SettingOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined,
  ApartmentOutlined,
  BoxPlotOutlined ,
  UserSwitchOutlined,
  BankOutlined
} from "@ant-design/icons";
import { Button, Col, Menu, Row, Modal } from "antd";
import "antd/dist/antd.css";
import "./Styles.css";
import Version from "../common/Version";

const Header = () => {
  const [current, setCurrent] = useState();
  const [subcurrent, setSubCurrent] = useState();
  const [open, setOpen] = useState(false);
  const [userAccess, setUserAccess] = useState();
  const navigate = useNavigate();
  const role = window.localStorage.getItem("role");
  const deviceType = window.localStorage.getItem("devicetype");

  let allRole = role?.split(",");
  useEffect(() => {
    setUserAccess(allRole.some((val) => val === "admin"));
  }, [allRole]);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };

  const ConfirmeModal = () => {
    window.localStorage.clear();
    navigate("/");
    setOpen(false);
  };
 
  useEffect(() => {
    let index = window.location.pathname;
    if (index === "/real-time-monitoring-Details") {
      index = index.slice(0, index.lastIndexOf("-"));
    }
    if (index === "/real-time-monitoring-perameter") {
      index = "/real-time-monitoring";
    }
    locations.map((pathname) => {
      if (pathname.path === index) {
        setCurrent(pathname.key);
      }
    });
  });
  const locations = [
    {
      path: "/dashboard",
      key: "1",
    },
    {
      path: "/real-time-monitoring",
      key: "2",
    },
    {
      path: "/reports-analysis",
      key: "3",
    },
    {
      path: "/alert-notification",
      key: "4",
    },
    {
      path: "/periodic-maintainance",
      key: "5",
    },
    {
      path: "/reports",
      key: "6",
    },
  ];
  let selectedKey = "";
  let defaultOpenKey = "";

  locations.forEach((item) => {
    if (window.location.pathname === item.path) {
      selectedKey = item.key;
    }
    return selectedKey;
  });

  locations.forEach((item) => {
    if (window.location.pathname === item.path) {
      defaultOpenKey = item.key;
    }
    return defaultOpenKey;
  });
  const onClick = (e) => {
    setSubCurrent(e.key);
  };
  const userName = window.localStorage.getItem("userName");
  const items = [
    {
      key: "SubMenu",
      icon: <SettingOutlined />,
      children: [
        {
          type: "group",
          children: [
            {
              label: (
                <div
                  onClick={() => {
                    navigate("/parent-configuration");
                  }}
                >
                 <ApartmentOutlined /> &nbsp; Parent
                </div>
              ),
              
              key: "setting:0",
            },
            {
              label: (
                <div
                  onClick={() => {
                    navigate("/store-configuration");
                  }}
                >
                 <BankOutlined /> &nbsp; Store
                </div>
              ),
              
              key: "setting:4",
            },
            {
              label: (
                <div
                  onClick={() => {
                    navigate("/on-boarding");
                  }}
                >
                 <BoxPlotOutlined /> &nbsp; Assets
                </div>
              ),
              key: "setting:1",
            },
            userAccess == true && {
              label: (
                <div
                  onClick={() => {
                    navigate("/user-control");
                  }}
                >
                 <UserSwitchOutlined /> &nbsp; Users
                </div>
              ),
              key: "setting:2",
            },
            
          ],
        },
      ],
    },
  ];
  const onSubClick = (e) => {
    setSubCurrent(e.key);
    setCurrent("");
  };

  return (
    <div style={{borderBottom:"0.1px solid #c7c7c9"}}>
      <img
        alt=""
        src={Strip}
        width="100%"
        height="16px"
        style={{ marginTop: "-10px" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 10px",
        }}
      >
        <div>
          {deviceType === "Elevator" ? (
            <img src={Logo} height="30px" alt="" />
          ) : (
            <img src={Equipment} height="30px" alt="" />
          )}
        </div>
        <div>
          <img src={Logo2} height="25px" alt="" />
        </div>
      </div>

      <Row>
        <Col
          xl={{ span: 20 }}
          lg={{ span: 18 }}
          md={{ span: 18 }}
          sm={{ span: 14 }}
          xs={{ span: 12 }}
        >
          <Menu
            onClick={onClick}
            mode="horizontal"
            defaultSelectedKeys={["1"]}
            selectedKeys={[current, selectedKey]}
            style={{marginTop:"revert"}}
          >
            <Menu.Item
              onClick={() => {
                setCurrent("dashboard");
                setSubCurrent("");
              }}
              key="1"
            >
              <Link to="dashboard">Dashboard</Link>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                setCurrent("real-time-monitoring");
                setSubCurrent("");
              }}
              key="2"
            >
              <Link to="real-time-monitoring">Real Time Monitoring</Link>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                setCurrent("reports-analysis");
                setSubCurrent("");
              }}
              key="3"
            >
              <Link to="reports-analysis">Reports and Analysis</Link>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                setCurrent("alert-notification");
                setSubCurrent("");
              }}
              key="4"
            >
              <Link to="/alert-notification">Alerts and Notification</Link>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                setCurrent("periodic-maintainance");
                setSubCurrent("");
              }}
              key="5"
            >
              <Link to="/periodic-maintainance">Periodic Maintenance</Link>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                setCurrent("reports");
                setSubCurrent("");
              }}
              key="6"
            >
              <Link to="/reports">Reports</Link>
            </Menu.Item>
          </Menu>
        </Col>
        <Col
          xl={{ span: 4 }}
          lg={{ span: 6 }}
          md={{ span: 6 }}
          sm={{ span: 10 }}
          xs={{ span: 12 }}
        >
          <div style={{ float: "right", marginRight: "10px" }}>
            <div>Welcome {userName}</div>

            <div style={{ float: "right", display: "flex" }}>
              <div style={{marginRight:"10px"}}> <Version /></div>
             
              {userAccess === true && (
                <Menu
                  onClick={onSubClick}
                  selectedKeys={[subcurrent]}
                  mode="horizontal"
                  items={items}
                />
              )}

              {/* <Popconfirm
                placement="bottom"
                onConfirm={() => {
                  setCurrent("");
                  navigate("/on-boarding");
                }}
                showCancel={false}
                okText={"on Boarding"}
                okType="none"
                icon
              >
                <Button style={{ border: "none" }}>
                  <SettingOutlined />
                </Button>
              </Popconfirm> */}
              {/* <Popover
                placement="bottom"
                content={
                  <Button
                    onClick={() => {
                      setCurrent("");
                      navigate("/on-boarding");
                    }}
                    style={{ border: "none" }}
                  >
                    On Boarding
                  </Button>
                }
                trigger="click"
              >
                <Button style={{ border: "none" }}>
                  <SettingOutlined />
                </Button>
              </Popover> */}

              <Button
                style={{ border: "none", borderLeft: "2px solid #c7c7c9" }}
              >
                <LogoutOutlined onClick={showModal} />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        title={
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              color: "#008ecf",
            }}
          >
            Log Out
          </div>
        }
        open={open}
        onOk={ConfirmeModal}
        onCancel={hideModal}
        okText="Confirm "
        cancelText="Cancel"
      >
        <div
          style={{ textAlign: "center", fontSize: "80px", color: "#008ecf" }}
        >
          <ExclamationCircleOutlined />
        </div>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          ARE YOU SURE YOU WANT TO LOGOUT?
        </h3>
      </Modal>
    </div>
  );
};

export default Header;
