/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import markerIcon from "../../assets/blue-darker.png";
import { useNavigate } from "react-router";
const DashboardMap = ({ mapdata }) => {

  const navigate = useNavigate();

  

  useEffect(() => {
    let map = L.map("leafletmap").setView([10, 60], 12);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
      map
    );
    L.Marker.prototype.setIcon(
      L.icon({
        iconUrl: markerIcon,
        iconSize: [25, 40],
      })
    );
    let uniqueMap = new Map();
    if (mapdata) {
      for (let obj of mapdata) {
        let key = JSON.stringify({
          LATTITUDE: obj["LATTITUDE"],
          LONGITUDE: obj["LONGITUDE"],
          // CITY: obj["CITY"],
        });
        let value = uniqueMap.get(key);
        if (!value) {
          value = [obj["STORE_NAME"]];
        } else {
          value.push(obj["STORE_NAME"]);
        }
        uniqueMap.set(key, value);
      }

      for (let [key, value] of uniqueMap) {
       
        new L.Marker([JSON.parse(key).LATTITUDE, JSON.parse(key).LONGITUDE])
          .bindPopup(`${value}`)
          .addTo(map)
          .openPopup();
      }
    }
    return () => {
      map.remove();
    };
  }, []);

useEffect(() => {
  const locationNames = document.querySelectorAll(".leaflet-popup-content");


  locationNames.forEach((el) => {

    el.addEventListener("click", () => {
      const sendingVal=mapdata.filter((item)=>item.STORE_NAME===el.innerHTML)
      navigate("/real-time-monitoring", {
        state: { states: sendingVal[0], itemNo: 1}
        
      });
    });
  });
}, []);
  

  return <div id="leafletmap" style={{ width: "100%", height: "280px" }}></div>;
};
export default DashboardMap;
