/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import "antd/dist/antd.css";
import "./Styles.css";
import moment from "moment";
import { baseUrl } from "../../common/BaseUrl";
import axios from "axios";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Radio,
  InputNumber,
} from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { formData, radioOptions, ruleTypeOptions, Priority } from "./FormData";
import { useEffect } from "react";

const SupplyParamterTab = ({
  getAlertList,
  setSelectTab,
  editRowData,
  setParams,
  setEventModal,
  selectIndex,
  setSelectIndex,
  selectFilter,
  setButtonSave,
}) => {
  const userEmail = window.localStorage.getItem("userEmail");
  const accessToken = window.localStorage.getItem("accessToken");
  const deviceType = window.localStorage.getItem("devicetype");
  const { Option } = Select;
  const [form] = Form.useForm();
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const [supplyParameterData, setSupplyParameterData] = useState(formData);
  const [apiCalled, setApiCalled] = useState(false);
  const [adminData, setAdminData] = useState();
  const [operatorData, setOperatorData] = useState();
  const [apiStorId, setApiStorId] = useState();
  const [apiLocation, setApiLocation] = useState();
  const [apiCity, setApiCity] = useState();
  const [apiCountry, setApiCountry] = useState();
  const [userAgainEditData, setUserAgainEditData] = useState();
  const [criteriaType, setCriteriaType] = useState();
  const [inChargeData, setInchargeData] = useState();
  const [supplyElevatorsDeviceID, setSupplyElevatorsDeviceID] = useState();
  const [userCityData, setUserCityData] = useState();
  const [userCountryData, setuserCountryData] = useState();

  const addButtonInValid=()=>{
    console.log("4321",form.getFieldValue(['users']))
    if( userOptionData?.length === 0)
    {
      return true;
    }
    else if( (form.getFieldValue(['users']).length>0&&form.getFieldValue(['users'])?.[form.getFieldValue(['users'])?.length-1]===undefined))
    {
    
      return true;
    }
    else if( (form.getFieldValue(['users']).length>0&&form.getFieldValue(['users'])?.[form.getFieldValue(['users'])?.length-1]!=undefined))
    {
      
     if(Object.keys(form.getFieldValue(['users'])?.[0])?.length===0)
     {
      return true;
     }
     return false;
    }
    return false;
    
  }

  const removeButtonInvalid=()=>{
     if( (form.getFieldValue(['users']).length>0&&form.getFieldValue(['users'])?.[0]===undefined))
      {
      
        return true;
      }
      else if( (form.getFieldValue(['users']).length>0&&form.getFieldValue(['users'])?.[0]!=undefined))
        {
          
         if(Object.keys(form.getFieldValue(['users'])?.[0])?.length===0)
         {
          return true;
         }
         return false;
        }
        return false;
    // Object?.keys(form?.getFieldValue(['users'])?.[0]).length===0
  }


  useEffect(() => {
    if (editRowData) {
      setApiStorId(editRowData?.STORE_ID);
      setApiLocation(editRowData?.LOCATION);
      setApiCity(editRowData?.CITY);
      setApiCountry(editRowData?.COUNTRY);
      if (editRowData?.CRITERIA === 0) {
        setCriteriaType("overVoltage");
      } else if (editRowData?.CRITERIA === 6) {
        setCriteriaType("UnderVoltage");
      }
      if (editRowData?.STORE_ID) {
        setApiStorId(editRowData?.STORE_ID);
      }
      if (editRowData?.RESPONSIBLE_CRITERIA) {
        setUserAgainEditData(editRowData?.RESPONSIBLE_CRITERIA);
      }

      form.setFieldsValue({
        OCCURANCE: editRowData?.OCCURANCE,
        prognosis_alert: editRowData?.IS_PROGNOSIS_ALERT_REQUIRED,
        country: editRowData?.COUNTRY,
        city: editRowData?.CITY,
        location: editRowData?.LOCATION,
        building: editRowData?.STORE_NAME,
        elevator: editRowData?.DEVICE_NAME,
        users: editRowData?.RESPONSIBLE_CRITERIA,
        LowerLimit: editRowData?.THRESHOLD?.[0],
        UpperLimit: editRowData?.THRESHOLD?.[1],
        Phase: editRowData?.PHASE,
        Criteria:
          editRowData?.CRITERIA === 0
            ? "overVoltage"
            : editRowData?.CRITERIA === 1
            ? "Current"
            : editRowData?.CRITERIA === 2
            ? "ReactivePower"
            : editRowData?.CRITERIA === 3
            ? "PF"
            : editRowData?.CRITERIA === 4
            ? "V-THD"
            : editRowData?.CRITERIA === 5
            ? "PowerDemand"
            : editRowData?.CRITERIA === 6 && "UnderVoltage",
        priority: editRowData?.PRIORITY,
      });
    }
  }, [editRowData]);

  const onFinish = (values) => {
    values?.users?.map((da, i) => {
      da.EMAIL = values?.users?.[i]?.COMMUNICATION_MEDIUM?.includes("EMAIL");
      da.SMS = values?.users?.[i]?.COMMUNICATION_MEDIUM?.includes("SMS");
    });
    const paramsValue = {
      OCCURANCE: 0,
      IS_PROGNOSIS_ALERT_REQUIRED:values?.prognosis_alert?true:false,
      TENANT_ID: userProfile?.["custom:tenant_id"],
      PARENT_ID: userProfile?.["custom:parent_id"],
      PARAMETER: 0,
      COMMUNICATION_MEDIUM: values?.email,
      STATUS: "SUBMITTED",
      DEVICE_ID: supplyElevatorsDeviceID,
      COUNTRY: values?.country,
      CITY: values?.city,
      CREATED_ON: moment().format("YYYY-MM-DDTHH:MM:SS"),
      STORE_ID: parseInt(
        selectIndex?.location &&
          selectFilter?.[selectIndex?.country]?.CITIES?.[selectIndex?.city]
            ?.LOCATION?.[selectIndex?.location]?.STORE?.[selectIndex?.store]
            ?.STORE_ID
      ),
      STORE_NAME: values?.building,
      DEVICE_NAME: values?.elevator,
      LOCATION: values?.location,
      RESPONSIBILITY: userEmail,
      THRESHOLD:
        criteriaType === "overVoltage"
          ? [values?.UpperLimit, values?.UpperLimit]
          : criteriaType === "UnderVoltage" ? [
              values?.LowerLimit,
              values?.LowerLimit,
            ]:[
              values?.LowerLimit,
              values?.UpperLimit,
            ],
      PHASE: values?.Phase,
      EVENT_TYPE: 0,
      DESCRIPTION: "Alert from Supply Side",
      CRITERIA:
        values?.Criteria === "overVoltage"
          ? 0
          : values?.Criteria === "Current"
          ? 1
          : values?.Criteria === "ReactivePower"
          ? 2
          : values?.Criteria === "PF"
          ? 3
          : values?.Criteria === "V-THD"
          ? 4
          : values?.Criteria === "PowerDemand"
          ? 5
          : values?.Criteria === "UnderVoltage" && 6,
      RESPONSIBLE_CRITERIA: values?.users,
      PRIORITY: values?.priority,
    };
    setSelectTab(true);

    if (editRowData) {
      const formdata = form.getFieldsValue();
      formdata?.users?.map((da, i) => {
        da.EMAIL =
          formdata?.users?.[i]?.COMMUNICATION_MEDIUM?.includes("EMAIL");
        da.SMS = formdata?.users?.[i]?.COMMUNICATION_MEDIUM?.includes("SMS");
      });
      paramsValue.LAST_ALERT_TIME = editRowData?.LAST_ALERT_TIME
        ? editRowData?.LAST_ALERT_TIME
        : undefined;
      paramsValue.THRESHOLD =
        criteriaType === "overVoltage"
          ? [formdata?.UpperLimit, formdata?.UpperLimit]
          : criteriaType === "UnderVoltage"
          ? [formdata?.LowerLimit, formdata?.LowerLimit]
          : [formdata?.LowerLimit, formdata?.UpperLimit];
      paramsValue.STORE_ID = editRowData?.STORE_ID;
      paramsValue.DEVICE_ID = editRowData?.DEVICE_ID;
      paramsValue.RESPONSIBLE_CRITERIA = formdata?.users;
      paramsValue.EVENT_TYPE = 0;
      paramsValue.PARAMETER = 0;
      paramsValue.CREATED_ON = editRowData?.CREATED_ON;
      paramsValue.ALERT_ID = editRowData.ALERT_ID;
      paramsValue.PRIORITY = formdata?.priority;
      paramsValue.DESCRIPTION = "Alert from Supply Side";
      paramsValue.CRITERIA =
        values?.Criteria === "overVoltage"
          ? 0
          : values?.Criteria === "Current"
          ? 1
          : values?.Criteria === "ReactivePower"
          ? 2
          : values?.Criteria === "PF"
          ? 3
          : values?.Criteria === "V-THD"
          ? 4
          : values?.Criteria === "PowerDemand"
          ? 5
          : values?.Criteria === "UnderVoltage" && 6;

      paramsValue.RESPONSIBLE_CRITERIA = formdata?.users;
    }

    setParams({ ...paramsValue });

    setButtonSave(true);
  };
  const handleRadioOnChange = (e) => {
    supplyParameterData?.map((item) => {
      if (item?.item === "Phase") {
        item?.radioOptions?.map((radio) => {
          if (radio?.label === e.target.value) {
            radio.state = true;
          } else {
            radio.state = false;
          }
        });
      }
    });
    setSupplyParameterData([...supplyParameterData]);
  };
  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "country") {
      form.setFieldsValue({
        city: undefined,
        location: undefined,
        building: undefined,
        elevator: undefined,
        users: [{}],
      });
    } else if (formFieldName === "city") {
      form.setFieldsValue({
        location: undefined,
        building: undefined,
        elevator: undefined,
        users: [{}],
      });
    } else if (formFieldName === "location") {
      form.setFieldsValue({
        building: undefined,
        elevator: undefined,
        users: [{}],
      });
    } else if (formFieldName === "building") {
      form.setFieldsValue({
        elevator: undefined,
        users: [{}],
      });
    }
  };

  useEffect(() => {
    if (apiStorId) {
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["EXECUTIVE AREA"],
            COUNTRY: apiCountry,
            CITY: apiCity,
            LOCATION: apiLocation,
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setInchargeData(response?.data?.data);
        });
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          { ROLES: ["ADMIN", "EXECUTIVE GLOBAL"] },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setAdminData(response?.data?.data);
        });
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["STORE INCHARGE", "OPERATOR"],
            COUNTRY: apiCountry,
            CITY: apiCity,
            STORE_ID: apiStorId?.toString(),
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setOperatorData(response?.data?.data);
        });
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["EXECUTIVE COUNTRY"],
            COUNTRY: apiCountry,
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setuserCountryData(response?.data?.data);
        });
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["EXECUTIVE CITY"],
            COUNTRY: apiCountry,
            CITY: apiCity,
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setUserCityData(response?.data?.data);
        });
    }
  }, [apiStorId, apiCalled]);

  let userOptionData = [];
  if (inChargeData) {
    inChargeData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (adminData) {
    adminData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (operatorData) {
    operatorData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (userCountryData) {
    userCountryData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (userCityData) {
    userCityData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  return (
    <Form
      form={form}
      onValuesChange={handleFormValuesChange}
      onFinish={onFinish}
      style={{ padding: "0px 15px 0px 0px" }}
    >
      <Row className="rowHeadingStyle" >
        Type Defination
      </Row>
      {
        criteriaType==="overVoltage"
       ||criteriaType==="Current"
      ||criteriaType==="PowerDemand"
      ||  criteriaType==="UnderVoltage"
      ?
     
       <Row gutter={15}>
       <Col span={15}>
           <Form.Item
              name="prognosis_alert"
              initialValue={true} 
              valuePropName="checked">
              <Checkbox >*prompt a special message after every 5th consecutive alert within 24 hours.</Checkbox>
             </Form.Item>
        </Col>
        </Row>
      :<></>
      }
      <Row gutter={15}>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Country</label>
            <Form.Item
              name="country"
              rules={[
                {
                  required: true,
                  message: "Please Select Country!",
                },
              ]}
            >
              <Select
              placeholder="Country"
              disabled={editRowData?.COUNTRY && true}
                onChange={(value, key) => {
                  setSelectIndex({ ...selectIndex, country: key.key });
                  setApiCountry(selectFilter?.[key.key]?.COUNTRY);
                }}
              >
                {selectFilter?.map((country, index) => {
                  return (
                    <Option value={country?.COUNTRY} key={index}>
                      {country?.COUNTRY}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">City</label>
            <Form.Item
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please Select City!",
                },
              ]}
            >
              <Select
                onChange={(value, key) => {
                  setSelectIndex({ ...selectIndex, city: key.key });
                  setApiCity(
                    selectFilter?.[selectIndex?.country]?.CITIES?.[key.key]
                      ?.CITY
                  );
                }}
                placeholder="Cities"
                disabled={selectIndex?.country ? false : true}
                className="ReportModal"
              >
                {selectFilter?.[selectIndex?.country]?.CITIES?.map(
                  (city, cityIndex) => {
                    return (
                      <Option value={city?.CITY} key={cityIndex}>
                        {city?.CITY}
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Location</label>
            <Form.Item
              name="location"
              rules={[
                {
                  required: true,
                  message: "Please Select Location!",
                },
              ]}
            >
              <Select
              placeholder="Location"
                onChange={(value, key) => {
                  setSelectIndex({ ...selectIndex, location: key.key });
                  setApiLocation(
                    selectFilter?.[selectIndex?.country]?.CITIES?.[
                      selectIndex?.city
                    ].LOCATION?.[key.key]?.LOCATION
                  );
                }}
                disabled={
                  selectIndex?.country && selectIndex?.city ? false : true
                }
              >
                {selectIndex?.city &&
                  selectFilter?.[selectIndex?.country]?.CITIES?.[
                    selectIndex?.city
                  ].LOCATION?.map((store, storeIndex) => {
                    return (
                      <Option value={store?.LOCATION} key={storeIndex}>
                        {store?.LOCATION}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Building</label>
            <Form.Item
              name="building"
            
              rules={[
                {
                  required: true,
                  message: "Please Select Building!",
                },
              ]}
            >
              <Select
                onChange={(value, key) => {
                  setApiStorId(
                    selectFilter?.[selectIndex?.country]?.CITIES?.[
                      selectIndex?.city
                    ].LOCATION?.[selectIndex?.location]?.STORE?.[key.key]
                      ?.STORE_ID
                  );
                  setSelectIndex({ ...selectIndex, store: key.key });
                }}
                placeholder="Building"
                className="ReportModal"
                disabled={selectIndex?.location ? false : true}
              >
                {selectIndex?.location &&
                  selectFilter?.[selectIndex?.country]?.CITIES?.[
                    selectIndex?.city
                  ].LOCATION?.[selectIndex?.location]?.STORE?.map(
                    (store, storeIndex) => {
                      return (
                        <Option value={store?.STORE_NAME} key={storeIndex}>
                          {store?.STORE_NAME}
                        </Option>
                      );
                    }
                  )}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">{deviceType}</label>
            <Form.Item
              name="elevator"
              rules={[
                {
                  required: true,
                  message: "Please Select Elevator!",
                },
              ]}
            >
              <Select
              placeholder={deviceType}
                onChange={(value, key) => {
                  setSelectIndex({
                    ...selectIndex,
                    elevator: key.key,
                  });
                  setSupplyElevatorsDeviceID(
                    selectIndex?.store &&
                      selectFilter?.[selectIndex?.country]?.CITIES?.[
                        selectIndex?.city
                      ].LOCATION?.[selectIndex?.location]?.STORE?.[
                        selectIndex?.store
                      ]?.DEVICE_DETAILS?.[key.key]?.DEVICE_ID
                  );
                }}
                disabled={
                  selectIndex?.country &&
                  selectIndex?.city &&
                  selectIndex?.location &&
                  selectIndex?.store
                    ? false
                    : true
                }
              >
                {selectIndex?.store &&
                  selectFilter?.[selectIndex?.country]?.CITIES?.[
                    selectIndex?.city
                  ].LOCATION?.[selectIndex?.location]?.STORE?.[
                    selectIndex?.store
                  ]?.DEVICE_DETAILS?.map((device, deviceIndex) => {
                    return (
                      <Option value={device?.DEVICE_NAME} key={deviceIndex}>
                        {device?.DEVICE_NAME}
                      </Option>
                    );
                  })}
                {/* {elevators?.map((device, storeIndex) => {
                  return (
                    <Option value={device?.DEVICE_NAME} key={storeIndex}>
                      {device?.DEVICE_NAME}
                    </Option>
                  );
                })} */}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Alert Type</label>
            <Form.Item
              name="Criteria"
              rules={[
                {
                  required: true,
                  message: `Please Select Criteria Type`,
                },
              ]}
            >
              <Select
              placeholder="Alert Type"
                onChange={(value) => setCriteriaType(value)}
                disabled={editRowData?.CRITERIA && true}
                options={ruleTypeOptions}
                className="ReportModal"
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row className="rowHeadingStyle11" style={{ marginTop: "15px" }}>
        Threshold Limit
      </Row>
      <Row gutter={15}>
        <Col md={6} sm={6} xs={18}>
          <label>Select Phase</label>
        </Col>
        <Col md={18} sm={18} xs={18}>
          <Form.Item
            name="Phase"
            rules={[
              {
                required: true,
                message: `Please Select the Phase`,
              },
            ]}
          >
            <Radio.Group className="Trigger">
              {radioOptions?.map((data, index) => {
                return (
                  <Radio.Button
                  key={index}
                    value={data.value}
                    style={{
                      border: "1.5px solid #005691",
                      width: "130px",
                      textAlign: "center",
                      color: "black",
                      fontWeight: "600",
                    }}
                    onChange={(value) => {
                      handleRadioOnChange(value);
                    }}
                  >
                    {data.label}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={15}>
        {criteriaType === "overVoltage" ? (
          <></>
        ) : (
          <Col xs={12} sm={12}>
            <div className="formInputStyleAlert">
              <label className="formInputLabel">Lower Limit</label>
              <Form.Item
                name="LowerLimit"
                rules={[
                  {
                    required: true,
                    message: "Please Select Lower Limit !",
                  },
                ]}
              >
                <InputNumber
                  className="formInput"
                />
              </Form.Item>
            </div>
          </Col>
        )}
        {criteriaType === "UnderVoltage" ? (
          <></>
        ) : (
          <Col xs={12} sm={12}>
            <div className="formInputStyleAlert">
              <label className="formInputLabel">Upper Limit</label>
              <Form.Item
                name="UpperLimit"
                rules={[
                  {
                    required: true,
                    message: "Please Select Upper Limit !",
                  },
                ]}
              >
                <InputNumber
                className="formInput"

                />
              </Form.Item>
            </div>
          </Col>
        )}

        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formInputLabel">Priority</label>
            <Form.Item
              name="priority"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
              placeholder="Priority"
                style={{
                  width: "100%",
                }}
                value={Priority.value}
                options={Priority}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row className="rowHeadingStyle11" style={{ marginTop: "15px" }}>
        User Notify
      </Row>
      <Form.List name="users" initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Row key={index} gutter={[12, 12]}>
                <Col span={8}>
                  <div className="formInputStyle">
                    <label className="formLabelStyle">User</label>
                    <Form.Item
                      {...restField}
                      name={[name, "USERID"]}
                      rules={[
                        {
                          required: true,
                          message: "Select USERID",
                        },
                      ]}
                    >
                      <Select
                      placeholder="User"
                        className="ReportModal"
                        onChange={(value, key) => {
                          let data = [...form.getFieldValue("users")];
                          data[data.length - 1]["PHONE_NUMBER"] =
                            userOptionData?.[key.key]?.mobile;
                          form.setFieldsValue({ users: data });
                          // count++;
                          userOptionData.splice(key.key, 1);
                        }}
                        // disabled={
                        //   selectIndex?.country &&
                        //   selectIndex?.city &&
                        //   selectIndex?.location
                        //     ? false
                        //     : true
                        // }
                      >
                        {userOptionData?.map((device, storeIndex) => {
                          return (
                            <Option value={device?.label} key={storeIndex}>
                              {device?.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col span={7}>
                  <div className="formInputStyle">
                    <label className="formLabelStyle">Mobile No.</label>
                    <Form.Item
                      {...restField}
                      name={[name, "PHONE_NUMBER"]}
                      rules={[
                        {
                          required: true,
                          message: "Mobile no.",
                        },
                      ]}
                    >
                      <Input
                        disabled
                        className="formInput"
                        placeholder={"Mobile no."}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={7}>
                  <div className="formInputStyle">
                    <label className="formLabelStyle">
                      Communication Medium
                    </label>
                    <div style={{ display: "flex", height: "30px" }}>
                      <Form.Item
                        {...restField}
                        name={[name, "COMMUNICATION_MEDIUM"]}
                        rules={[
                          {
                            required: true,
                            message: "Select the Communication Medium",
                          },
                        ]}
                      >
                        <Checkbox.Group>
                          <Checkbox value="EMAIL">Email</Checkbox>
                          <Checkbox value="SMS">SMS</Checkbox>
                        </Checkbox.Group>
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col span={2}>
                  <Button
                  //  disabled={form.getFieldValue(['users'])?.length<=1}
                  disabled={removeButtonInvalid()}
                    style={{ marginTop: "30px" }}
                    onClick={() => {
                      if(form?.getFieldValue(['users']).length===1)
                        {
                            form.setFieldsValue({["users"]:[{}]});
                            return;
                        }
                      if (userAgainEditData) {
                        userAgainEditData.splice(name, 1);
                        setApiCalled(!apiCalled);
                      }
                      remove(name);
                    }}
                  >
                    <MinusCircleOutlined />
                  </Button>
                </Col>
              </Row>
            ))}
            <Form.Item>
              <Button
              disabled={addButtonInValid()}
                // disabled={userOptionData?.length === 0||!form.getFieldValue(['users'])?.[form.getFieldValue(['users'])?.length-1]||Object.keys(form.getFieldValue(['users'])?.[0]).length === 0}
                type="primary"
                onClick={() => {
                  // let tempUsers=form.getFieldValue(['users']);
                  // let tempUsers2=form.getFieldValue(['users'])?.filter(item=>item!==undefined);
                  // if(tempUsers?.length!==tempUsers2.length||Object.keys(tempUsers[0]).length === 0)
                  //   {
                  //     return;
                  //   }
                  add();
                  let tempObj = {};
                  userAgainEditData?.map(
                    (item) => (tempObj[item["USERID"]] = 1)
                  );

                  userOptionData = userOptionData?.filter((item) => {
                    if (!tempObj[item["label"]]) {
                      return item;
                    }
                  });
                }}
                block
                icon={<PlusOutlined />}
                style={{ width: "120px", marginTop: "30px" }}
              >
                Add User
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button
          className="addButton"
          onClick={() => {
            setEventModal(false);
            getAlertList();
          }}
        >
          Cancel
        </Button>
        <Button className="saveButton" htmlType="submit" type="primary">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SupplyParamterTab;
