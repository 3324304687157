import { Button, Col, Collapse, Row, Spin, Table, Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  CaretRightOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import "../Styles.css";
import axios from "axios";
import AddEditParent from "./AddEditParent";
import { onboardingBaseUrl } from "../../../common/BaseUrl";

const Parent = () => {
  const { Panel } = Collapse;
  const accessToken = window.localStorage.getItem("accessToken");
  const [parentdata, setParentData] = useState();
  const [parentLoader, setParentLoader] = useState(false);
  const [addTenantModal, setAddTenantModal] = useState(false);
  const [editTenantModal, setEditTenantModal] = useState(false);
  const [editTenantData, setEditTenantData] = useState();
  const [deleteparent, setDeleteparent] = useState();
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  
  const [parentListLoader, setParentListLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false)
  const tanentColumn = [
    {
      title: "S/n",
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: "TENANT NAME",
      dataIndex: "TENANT_NAME",
      key: "TENANT_NAME",
      align: "center",
    },
    {
      title: "TENANT ID",
      dataIndex: "TENANT_ID",
      key: "TENANT_ID",
      align: "center",
    },
    {
      title: "ELECTRICAL PARAMETER",
      dataIndex: "ONLY_ELECTRICAL_PARAMETER",
      key: "ONLY_ELECTRICAL_PARAMETER",
      align: "center",
      render: (ONLY_ELECTRICAL_PARAMETER) => {
        if (ONLY_ELECTRICAL_PARAMETER === true) {
          return <div>TRUE</div>;
        } else {
          return <div>FALSE</div>;
        }
      },
    },
  ];
  useEffect(() => {
    setParentLoader(true);
    axios
      .post(
        `${onboardingBaseUrl}/parentDetailsList`,
        {},
        {
          headers: { ["x-access-token"]: accessToken },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          setParentData(response?.data);
          setParentLoader(false);
        }
      });
  }, [parentListLoader]);
  useEffect(() => {}, []);
  const ConfirmeModal = () => {
    if (deleteparent) {
      setDeleteLoader(true)
      axios
        .post(
          `${onboardingBaseUrl}/deleteParentDetails/${deleteparent?.PARENT_ID}`,
          {},
          {
            headers: { ["x-access-token"]: accessToken },
          }
        )
        .then((response) => {
          notification.success({ description: response?.data })
          setDeleteConfirmModal(false)
          setDeleteLoader(false)
          setParentListLoader(!parentListLoader)
        });
    }
  };
  return (
    <div className="MainLayoutCss" style={{display:"flex", flexDirection:"column",overflow:"hidden"}}>
      <div className="parentbuttonDiv">
        <Button
          type="primary"
          className="parentButton"
          onClick={() => setAddTenantModal(true)}
        >
          ADD NEW PARENT & TENANT
        </Button>
      </div>
      <div className="content-container" style={{ textAlign: "center" }}>
        <Spin
          className="parentLoader"
          tip="Loading..."
          delay={500}
          spinning={parentLoader}
        >
          {parentdata?.length === 0 && <DeleteOutlined style={{fontSize:"200px"}} />}
          {Array.isArray(parentdata) && 
            parentdata?.map((parent, index) => {
            return (
              <Collapse
                key={index}
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined
                    style={{ fontSize: "30px" }}
                    rotate={isActive ? 90 : 0}
                  />
                )}
                className="parentColapse"
              >
                <Panel
                  header={
                    <div>
                      <span className="LocationStoreName">
                        {parent?.PARENT_NAME}
                      </span>
                      <div style={{ float: "right" }}>
                        <EditOutlined
                          className="parentEdit"
                          onClick={() => {
                            setEditTenantModal(true);
                            setEditTenantData(parent);
                          }}
                        />
                        <DeleteOutlined
                          className="parentDelete"
                          onClick={() => {
                            setDeleteConfirmModal(true);
                            setDeleteparent(parent);
                          }}
                        />
                      </div>
                    </div>
                  }
                >
                  <div className="parentpanelDiv">
                    <Row>
                      <Col
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 8 }}
                        sm={{ span: 12 }}
                        xs={{ span: 12 }}
                      >
                        <div className="parentContain">
                          <div className="parentHeaderContain">PARENT NAME</div>{" "}
                          : &nbsp;
                          <span>{parent?.PARENT_NAME}</span>
                        </div>
                      </Col>
                      <Col
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 8 }}
                        sm={{ span: 12 }}
                        xs={{ span: 12 }}
                      >
                        <div className="parentContain">
                          <div className="parentHeaderContain">PARENT ID</div> :
                          &nbsp;
                          <span>{parent?.PARENT_ID}</span>
                        </div>
                      </Col>
                      <Col
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 8 }}
                        sm={{ span: 12 }}
                        xs={{ span: 12 }}
                      >
                        <div className="parentContain">
                          <div className="parentHeaderContain">CREATED BY</div>{" "}
                          : &nbsp;
                          <span>{parent?.CREATED_BY}</span>
                        </div>
                      </Col>
                      <Col
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 8 }}
                        sm={{ span: 12 }}
                        xs={{ span: 12 }}
                      >
                        <div className="parentContain">
                          <div className="parentHeaderContain">CREATED ON</div>{" "}
                          : &nbsp;
                          <span>{parent?.CREATED_ON?.split('T')[0]}</span>
                        </div>
                      </Col>
                      <Col
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 8 }}
                        sm={{ span: 12 }}
                        xs={{ span: 12 }}
                      >
                        <div className="parentContain">
                          <div className="parentHeaderContain">UPDATED BY</div>{" "}
                          : &nbsp;
                          <span>{parent?.UPDATED_BY}</span>
                        </div>
                      </Col>
                      <Col
                        xl={{ span: 8 }}
                        lg={{ span: 8 }}
                        md={{ span: 8 }}
                        sm={{ span: 12 }}
                        xs={{ span: 12 }}
                      >
                        <div className="parentContain">
                          <div className="parentHeaderContain">UPDATED ON</div>{" "}
                          : &nbsp;
                          <span>{parent?.UPDATED_ON?.split('T')[0]}</span>
                        </div>
                      </Col>
                    </Row>
                    <div style={{ marginTop: "10px" }}></div>
                    <Table
                      className="MainTable"
                      columns={tanentColumn}
                      dataSource={parent?.TENANTS}
                      pagination={false}
                      rowKey={(record) => record?.TENANT_ID}
                    />
                  </div>
                </Panel>
              </Collapse>
            );
          })
        }
          
        </Spin>
      </div>
      <Modal
        title={<h4 style={{textAlign:"center"}}>Add Parent and Tenant</h4>}
        open={addTenantModal}
        onOk={() => setAddTenantModal(false)}
        onCancel={() => setAddTenantModal(false)}
        okText="Confirm "
        cancelText="Cancel"
        width={700}
        footer={null}
        destroyOnClose={true}
      >
        <AddEditParent 
        setAddTenantModal={setAddTenantModal} 
        setParentListLoader={setParentListLoader}
        parentListLoader={parentListLoader}/>
      </Modal>
      <Modal
        title={<h4 style={{textAlign:"center"}}>Edit Parent and Tenant</h4>}
        open={editTenantModal}
        onOk={() => setEditTenantModal(false)}
        onCancel={() => setEditTenantModal(false)}
        okText="Confirm "
        cancelText="Cancel"
        width={700}
        footer={null}
        destroyOnClose={true}
      >
        <AddEditParent
          editTenantData={editTenantData}
          setEditTenantModal={setEditTenantModal}
          setParentListLoader={setParentListLoader}
          parentListLoader={parentListLoader}
        />
      </Modal>
      <Modal
        open={deleteConfirmModal}
        onOk={ConfirmeModal}
        onCancel={()=>setDeleteConfirmModal(false)}
        okText="Confirm "
        cancelText="Cancel"
      >
         <Spin
          className="parentFormLoader"
          spinning={deleteLoader}
        >
        <div
          style={{ textAlign: "center", fontSize: "80px", color: "#008ecf" }}
        >
          <ExclamationCircleOutlined />
        </div>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          ARE YOU SURE YOU WANT TO REMOVE THIS PARENT?
        </h3>
        </Spin>
      </Modal>
      
    </div>
    
  );
};

export default Parent;
