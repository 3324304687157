import React, { useState, useEffect } from "react";
import { Tabs, Button, Modal, Divider } from "antd";
import {AlertBaseUrl } from "../../common/BaseUrl";
import axios from "axios";
import {PlusOutlined} from "@ant-design/icons";
import Upcomming from "./Upcomming";
import OverDue from "./OverDue";
import Configuration from "./Configuration";
import MaintainanceLog from "./MaintainanceLog";
import LoadingSpinner from "../../common/pageLoader/loadingSpinner";
import RegisterMaintenanceForm from "./RegisterMaintenanceForm";
const PeriodicMaintanance = () => {
  const role = window.localStorage.getItem("role");
  let allRole = role?.split(",");
  const accessToken = window.localStorage.getItem("accessToken");
  const [pageLoader, setPageLoader] = useState(false);
  const [selectFilter, setSelectFilter] = useState();
  const [allTableLoader, setAllTableLoader] = useState(false);
  const [typeMaintain, setTypeMaintain] = useState(0);
  const [elevatorList, setElevatorList] = useState();
  const [maintenanceMOpen, setmMaintenanceMOpen] = useState(false);
  const [defaultTabSelection, setDefaultTabSelection] = useState("1");
  const [userAccess, setUserAccess] = useState();
  const [selectIndex, setSelectIndex] = useState({
    country: "",
    city: "",
    location: "",
    store: "",
    elevator: "",
  });
  const [newTableHeight, setNewTableHeight] = useState();
  const divHeight = window.localStorage.getItem("SpaceHeight");
  useEffect(() => {
    setNewTableHeight(divHeight - 190);
  }, [divHeight]);
  useEffect(() => {
    setUserAccess(allRole.some((val) => val === "operator"));
  }, [allRole]);
  useEffect(() => {
    axios
      .post(
        `${AlertBaseUrl}/getElevatorList`,
        {},
        { headers: { ["x-access-token"]: accessToken } }
      )
      .then((response) => {
        setElevatorList(response?.data);
      });
  }, []);

  const alertTripSpan = [
    {
      value: "DAILY",
      label: "Daily",
    },
    {
      value: "WEEKLY",
      label: "Weekly",
    },
    {
      value: "MONTHLY",
      label: "Monthly",
    },
  ];
  const operations = (
    <Button
      type="primary"
      style={{ color: "white", margin: "2px 10px" }}
      onClick={() => setmMaintenanceMOpen(true)}
    >
      Schedule Maintenance <PlusOutlined/>
    </Button>
  );
  return (
    <div>
      {pageLoader ? (
        <LoadingSpinner />
      ) : (
        <>
          <Tabs
            tabBarExtraContent={!userAccess ? operations : ""}
            defaultActiveKey={defaultTabSelection}
            style={{
              backgroundColor: "white",
            }}
          >
            <Tabs.TabPane tab="Upcoming" key="1">
              <Upcomming
                userAccess={userAccess}
                elevatorList={elevatorList}
                newTableHeight={newTableHeight}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Overdue" key="2">
              <OverDue
                userAccess={userAccess}
                elevatorList={elevatorList}
                newTableHeight={newTableHeight}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Configuration" key="3">
              <Configuration
                userAccess={userAccess}
                setmMaintenanceMOpen={setmMaintenanceMOpen}
                allTableLoader={allTableLoader}
                setAllTableLoader={setAllTableLoader}
                pageLoader={pageLoader}
                setPageLoader={setPageLoader}
                alertTripSpan={alertTripSpan}
                setDefaultTabSelection={setDefaultTabSelection}
                setSelectIndex={setSelectIndex}
                selectIndex={selectIndex}
                selectFilter={selectFilter}
                setSelectFilter={setSelectFilter}
                newTableHeight={newTableHeight}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Logs" key="4">
              <MaintainanceLog
                elevatorList={elevatorList}
                newTableHeight={newTableHeight}
              />
            </Tabs.TabPane>
          </Tabs>
        </>
      )}
      <Modal
        open={maintenanceMOpen}
        onOk={() => setmMaintenanceMOpen(false)}
        onCancel={() => {
          setmMaintenanceMOpen(false);
        }}
        width={1000}
        footer={null}
        destroyOnClose={true}
      >
      <h3 style={{textAlign:"center", fontWeight:"bold"}}>Add Maintenance Configuration</h3>
      <Divider/>
        <div
          style={{
            height: "30vw",
            overflowY: "auto",
          }}
        >
       
     

          <RegisterMaintenanceForm
          setSelectIndex={setSelectIndex}
            selectIndex={selectIndex}
            selectFilter={selectFilter}
            setSelectFilter={setSelectFilter}
            setmMaintenanceMOpen={setmMaintenanceMOpen}
            setDefaultTabSelection={setDefaultTabSelection}
            setAllTableLoader={setAllTableLoader}
            allTableLoader={allTableLoader}
            alertTripSpan={alertTripSpan}
            pageLoader={pageLoader}
            setPageLoader={setPageLoader}
            setTypeMaintain={setTypeMaintain}
            typeMaintain={typeMaintain}
            />
        </div>
      </Modal>
    </div>
  );
};
export default PeriodicMaintanance;
