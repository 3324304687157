
import React from "react";
import { BulbOutlined } from "@ant-design/icons";
export const searchElements = [
  { element: "Country" },
  { element: "City" },
  { element: "Location" },
];
export const segmentOptions = [
  {
    label: (
      <>
        <BulbOutlined />
        Hourly
      </>
    ),
    value: "Hourly",
  },
  {
    label: (
      <>
        <BulbOutlined />
        Daily
      </>
    ),
    value: "Daily",
  },
  {
    label: (
      <>
        <BulbOutlined />
        Weekly
      </>
    ),
    value: "Weekly",
  },
  {
    label: (
      <>
        <BulbOutlined />
        Monthly
      </>
    ),
    value: "Monthly",
  },
];
