/* eslint-disable react/prop-types */
import React from "react";
import { Checkbox, Col, Divider, Row, Table } from "antd";
import moment from "moment";
const CurrentAlertPreviewModal = ({ previewRowData }) => {
  const deviceType = window.localStorage.getItem("devicetype");
  const UserGroupColumn = [
    {
      title: "USER EMAIL",
      dataIndex: "USERID",
      key: "USERID",
      align: "center",
      width: 300,
    },
    {
      title: "EMAIL",
      dataIndex: "EMAIL",
      width: "90px",
      align: "center",
      render: (text, record, index) => (
        <Checkbox key={index} checked={record.EMAIL} disabled={true} />
      ),
    },
    {
      title: "SMS",
      dataIndex: "SMS",
      width: "90px",
      align: "center",
      render: (text, record, index) => (
        <Checkbox key={index} checked={record.SMS} disabled={true} />
      ),
    },
  ];
  return (
    <div>
      <h3 style={{ fontWeight: "bold", fontSize:"18px", textAlign:"center" }}>
        {previewRowData?.CRITERIA === 0 && previewRowData?.PARAMETER === 101 ? "Trip Alert Configuration"
          : previewRowData?.CRITERIA === 2 && previewRowData?.PARAMETER === 101 ? "Floor Alert Configuration"
          : previewRowData?.CRITERIA === 1 && previewRowData?.PARAMETER === 101 ? "Time  Alert Configuration"
          : previewRowData?.CRITERIA === 3 && previewRowData?.PARAMETER === 101 ? "Idle Time Alert Configuration"
          : previewRowData?.CRITERIA === 4 && previewRowData?.PARAMETER === 101 ? "Operation Outside SOP Alert Configuration"
          : previewRowData?.CRITERIA === 5 && previewRowData?.PARAMETER === 101 ? "Offline/Lost Communication/No power Alert Configuration"
          : previewRowData?.CRITERIA === 6 && previewRowData?.PARAMETER === 101 ? "Manual Tampering Alert Configuration"
          : previewRowData?.CRITERIA === 7 && previewRowData?.PARAMETER === 101 ? "Power Shutdown Alert Configuration"
          : previewRowData?.CRITERIA === 8 && previewRowData?.PARAMETER === 101 ? "Elevator Online Alert Configuration"
          : previewRowData?.CRITERIA === 9 && previewRowData?.PARAMETER === 101 ? "Lift Stuck Alert Configuration"
          : previewRowData?.CRITERIA === 0 && previewRowData?.PARAMETER === 0 ? "Over Voltage Alert Configuration"
          : previewRowData?.CRITERIA === 1 && previewRowData?.PARAMETER === 0 ? "Over Current Alert Configuration"
          : previewRowData?.CRITERIA === 2 && previewRowData?.PARAMETER === 0 ? "Reactive Power Alert Configuration"
          : previewRowData?.CRITERIA === 3 && previewRowData?.PARAMETER === 0 ? "PF Lag Alert Configuration"
          : previewRowData?.CRITERIA === 4 && previewRowData?.PARAMETER === 0 ? "V-THD Alert Configuration"
          : previewRowData?.CRITERIA === 5 && previewRowData?.PARAMETER === 0 ? "Power Demand Alert Configuration"
          : previewRowData?.CRITERIA === 6 && previewRowData?.PARAMETER === 0 ? "Under Voltage Alert Configuration"
          : previewRowData?.CRITERIA === 5 && previewRowData?.PARAMETER === 1 && "Target Breach Alert Configuration"}
      </h3>
      <Divider />
      <Row>
        <Col xs={24} sm={12}>
          <h3>
            Country :
            <span
              style={{
                margin: "0px 4px",
                fontWeight: "bold",
                color: "#00867dff",
              }}
            >
              {previewRowData?.COUNTRY}
            </span>
          </h3>
        </Col>
        <Col xs={24} sm={12}>
          <h3>
            City :
            <span
              style={{
                margin: "0px 4px",
                fontWeight: "bold",
                color: "#00867dff",
              }}
            >
              {previewRowData?.CITY}
            </span>
          </h3>
        </Col>
        <Col xs={24} sm={12}>
          <h3>
            Building :
            <span
              style={{
                margin: "0px 4px",
                fontWeight: "bold",
                color: "#00867dff",
              }}
            >
              {previewRowData?.STORE_NAME}
            </span>
          </h3>
        </Col>
        <Col xs={24} sm={12}>
          <h3>
            {deviceType} :
            <span
              style={{
                margin: "0px 4px",
                fontWeight: "bold",
                color: "#00867dff",
              }}
            >
              {previewRowData?.DEVICE_NAME}
            </span>
          </h3>
        </Col>
        
        <Col xs={24} sm={12}>
          <h3>
            Prognosis Alert :
            <span
              style={{
                margin: "0px 4px",
                fontWeight: "bold",
                color: "#00867dff",
              }}
            >
              {previewRowData?.IS_PROGNOSIS_ALERT_REQUIRED==true?'Activated':'Not Activated'}
            </span>
          </h3>
        </Col>
      </Row>
   <div style={{heigh:"30vh", overflowY:"auto"}}>
      <div
        style={{
          padding: "10px",
          background: "rgb(245, 243, 237)",
          borderRadius: "13px",
        }}
      >
        
        <Divider style={{fontSize:"18px", fontWeight:"bold"}}>User Notify</Divider>
        <Table
        className="MainTable"
          columns={UserGroupColumn}
          dataSource={previewRowData?.RESPONSIBLE_CRITERIA}
          pagination={false}
          size="small"
        />
        <Divider style={{fontSize:"18px", fontWeight:"bold"}}>Rule Set</Divider>
        <Row style={{ marginTop: "10px" }}>
          <Col xs={24} sm={12}>
            <h3>
              Priority:
              <span
                style={{
                  margin: "0px 4px",
                  fontWeight: "bold",
                  color: "#00867dff",
                }}
              >
                {previewRowData?.PRIORITY}
              </span>
            </h3>
          </Col>
       
        {previewRowData?.CRITERIA === 0 && previewRowData?.PARAMETER === 101 ? (
          <>
            <Col xs={24} sm={12}>
              <h3>
                Trip Limit :
                <span
                  style={{
                    margin: "0px 4px",
                    fontWeight: "bold",
                    color: "#00867dff",
                  }}
                >
                  {previewRowData?.ALERT_DEFINITION?.THRESHOLD_LIMIT} Trips
                </span>
              </h3>
            </Col>
            <Col xs={24} sm={12}>
              <h3>
                Alert Period :
                <span
                  style={{
                    margin: "0px 4px",
                    fontWeight: "bold",
                    color: "#00867dff",
                  }}
                >
                  {previewRowData?.ALERT_DEFINITION?.THRESHOLD_PERIOD}
                </span>
              </h3>
            </Col>
          </>
        ) : previewRowData?.CRITERIA === 2 &&
          previewRowData?.PARAMETER === 101 ? (
          <>
            <Col xs={24} sm={12}>
              <h3>
                Floor Travelled LIMIT :
                <span
                  style={{
                    margin: "0px 4px",
                    fontWeight: "bold",
                    color: "#00867dff",
                  }}
                >
                  {previewRowData?.ALERT_DEFINITION?.THRESHOLD_LIMIT} Floors
                </span>
              </h3>
            </Col>
            <Col xs={24} sm={12}>
              <h3>
                ALERT PERIOD :
                <span
                  style={{
                    margin: "0px 4px",
                    fontWeight: "bold",
                    color: "#00867dff",
                  }}
                >
                  {previewRowData?.ALERT_DEFINITION?.THRESHOLD_PERIOD}
                </span>
              </h3>
            </Col>
          </>
        ) : previewRowData?.CRITERIA === 1 &&
          previewRowData?.PARAMETER === 101 ? (
          <>
            <Col xs={24} sm={12}>
              <h3>
                Generation Day :
                <span
                  style={{
                    margin: "0px 4px",
                    fontWeight: "bold",
                    color: "#00867dff",
                  }}
                >
                  {previewRowData?.ALERT_DEFINITION?.GENERATION_DAY}
                </span>
              </h3>
            </Col>
            <Col xs={24} sm={12}>
              <h3>
                Recurrance :
                <span
                  style={{
                    margin: "0px 4px",
                    fontWeight: "bold",
                    color: "#00867dff",
                  }}
                >
                  {previewRowData?.ALERT_DEFINITION?.RECURRANCE}
                </span>
              </h3>
            </Col>
          </>
        ) : previewRowData?.CRITERIA === 3 &&
          previewRowData?.PARAMETER === 101 ? (
          <>
            <Col xs={24} sm={12}>
              <h3>
                Idle Hour Limit :
                <span
                  style={{
                    margin: "0px 4px",
                    fontWeight: "bold",
                    color: "#00867dff",
                  }}
                >
                  {previewRowData?.ALERT_DEFINITION?.THRESHOLD_LIMIT} Hours
                </span>
              </h3>
            </Col>
            <Col xs={24} sm={12}>
              <h3>
                Working Hours :
                <span
                  style={{
                    margin: "0px 4px",
                    fontWeight: "bold",
                    color: "#00867dff",
                  }}
                >
                  {moment(
                    previewRowData?.ALERT_DEFINITION?.WORKING_HOUR_START,
                    "HH:mm"
                  ).format("h:mm A")}
                  &nbsp; To &nbsp;
                  {moment(
                    previewRowData?.ALERT_DEFINITION?.WORKING_HOUR_END,
                    "HH:mm"
                  ).format("h:mm A")}
                </span>
              </h3>
            </Col>
            <Col xs={24} sm={12}>
              <h3>
                Working Days :
                <span
                  style={{
                    margin: "0px 4px",
                    fontWeight: "bold",
                    color: "#00867dff",
                  }}
                >
                  {previewRowData?.ALERT_DEFINITION?.WORKING_DAYS?.map(
                    (item, index) => (
                      <span
                        key={index}
                        style={{
                          margin: "0px 4px",
                          fontWeight: "bold",
                          color: "#00867dff",
                        }}
                      >
                        {item}
                      </span>
                    )
                  )}
                </span>
              </h3>
            </Col>
            <Row></Row>
          </>
        ) : previewRowData?.CRITERIA === 4 &&
          previewRowData?.PARAMETER === 101 ? (
          <>
            <Col xs={24} sm={12}>
              <h3>
                Working Days :
                <span
                  style={{
                    margin: "0px 4px",
                    fontWeight: "bold",
                    color: "#00867dff",
                  }}
                >
                  {previewRowData?.ALERT_DEFINITION?.WORKING_DAYS?.map(
                    (item, index) => (
                      <span
                        key={index}
                        style={{
                          margin: "0px 4px",
                          fontWeight: "bold",
                          color: "#00867dff",
                        }}
                      >
                        {item}
                      </span>
                    )
                  )}
                </span>
              </h3>
            </Col>
            <Col xs={24} sm={12}>
              <h3>
                Working Hours :
                <span
                  style={{
                    margin: "0px 4px",
                    fontWeight: "bold",
                    color: "#00867dff",
                  }}
                >
                  {moment(
                    previewRowData?.ALERT_DEFINITION?.WORKING_HOUR_START,
                    "HH:mm"
                  ).format("h:mm A")}
                  &nbsp; To &nbsp;
                  {moment(
                    previewRowData?.ALERT_DEFINITION?.WORKING_HOUR_END,
                    "HH:mm"
                  ).format("h:mm A")}
                </span>
              </h3>
            </Col>
          </>
        ) : previewRowData?.PARAMETER === 0 ? (
          <>
            <Col xs={24} sm={12}>
              <h3>
                Phase :
                <span
                  style={{
                    margin: "0px 4px",
                    fontWeight: "bold",
                    color: "#00867dff",
                  }}
                >
                  {previewRowData?.PHASE}
                </span>
              </h3>
            </Col>
            <Col xs={24} sm={12}>
              <h3>
                Lower Limit :
                <span
                  style={{
                    margin: "0px 4px",
                    fontWeight: "bold",
                    color: "#00867dff",
                  }}
                >
                  {previewRowData?.THRESHOLD?.[0]}
                </span>
              </h3>
            </Col>
            <Col xs={24} sm={12}>
              <h3>
                Upper Limit :
                <span
                  style={{
                    margin: "0px 4px",
                    fontWeight: "bold",
                    color: "#00867dff",
                  }}
                >
                  {previewRowData?.THRESHOLD?.[1]}
                </span>
              </h3>
            </Col>
          </>
        ) : (
          previewRowData?.CRITERIA === 5 &&
          previewRowData?.PARAMETER === 1 && (
            <>
              <Col xs={24} sm={12}>
                <h3>
                Frequency :
                  <span
                    style={{
                      margin: "0px 4px",
                      fontWeight: "bold",
                      color: "#00867dff",
                    }}
                  >
                    {previewRowData?.THRESHOLD_PERIOD}
                  </span>
                </h3>
              </Col> 
              <Col xs={24} sm={12}>
                <h3>
                  Target :
                  <span
                    style={{
                      margin: "0px 4px",
                      fontWeight: "bold",
                      color: "#00867dff",
                    }}
                  >
                    {previewRowData?.TARGET}
                  </span>
                </h3>
              </Col>
              {/* <Col xs={24} sm={12}>
                <h3>
                  Start Date :
                  <span
                    style={{
                      margin: "0px 4px",
                      fontWeight: "bold",
                      color: "#00867dff",
                    }}
                  >
                    {previewRowData?.TIME_PERIOD_FROM?.slice(0, 10)}
                  </span>
                </h3>
              </Col> */}
              {/* <Col xs={24} sm={12}>
                <h3>
                  End Date :
                  <span
                    style={{
                      margin: "0px 4px",
                      fontWeight: "bold",
                      color: "#00867dff",
                    }}
                  >
                    {previewRowData?.TIME_PERIOD_TO?.slice(0, 10)}
                  </span>
                </h3>
              </Col> */}
            </>
          )
        )}
        </Row>
      </div>
      </div>
    </div>
  );
};

export default CurrentAlertPreviewModal;
