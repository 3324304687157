/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { RedoOutlined } from "@ant-design/icons";
import { Col, Row, Form, Select } from "antd";
import axios from "axios";
import axiosRetry from "axios-retry";
import {
  PriorityOption,
  StatusOption,
  alertFilterTypeEquipment,
  alertFilterTypeElevator,
  elevatorStatusOption,
  maintenanceStatusOption,
  maintenanceTypeOption,
} from "./FilterData";
import { AlertBaseUrl, onboardingBaseUrl } from "../../common/BaseUrl";

const FilterForm = ({
  SetCountryName,
  SetCityName,
  SetLocationName,
  SetBuildingName,
  SetElevatorName,
  SetAlertTypeName,
  SetStatusData,
  SetPriorityFilter,
  SetMaintenanceFilter,
  filterHeaderapiLoader,
  setFilterHeaderapiLoader,
  filtertype,
  alertTypeName,
  resetApiCall,
  setAlertTypeDashboard,
  disableWhileLoding
}) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const deviceType = window.localStorage.getItem("devicetype");
  const accessToken = window.localStorage.getItem("accessToken");
  const [StoreData, setStoreData] = useState();
  const [hover, setHover] = useState(false);
  const [elevatorList, setElevatorList] = useState();
  const [selectIndex, setSelectIndex] = useState({
    country: "",
    city: "",
    location: "",
    store: ""
  });

  useEffect(() => {
    setStoreData();
    setSelectIndex({});
  }, [filtertype]);

  useEffect(() => {
    if (alertTypeName) {
      form.setFieldsValue({
        Cretria: alertTypeName,
      });
    }
  }, [alertTypeName]);

  const resetData = () => {
    form.setFieldsValue({
      Cretria: alertTypeName,
    });
    form.resetFields();
    if (filtertype === "alertNotificationtab") {
      SetCountryName();
      SetCityName();
      SetLocationName();
      SetBuildingName();
      SetElevatorName();
      SetStatusData();
      SetAlertTypeName();
      setAlertTypeDashboard();
      const pageNo = 1;
      const pageSize = 10;
      resetApiCall(pageNo, pageSize, accessToken);
    } else if (filtertype === "alertNotificationcurrenttab") {
      SetCountryName();
      SetCityName();
      SetLocationName();
      SetBuildingName();
      SetElevatorName();
      SetAlertTypeName();
      SetPriorityFilter();
      setFilterHeaderapiLoader(!filterHeaderapiLoader);
    } else if (filtertype === "PeriodicConfiguration") {
      setFilterHeaderapiLoader(!filterHeaderapiLoader);
      SetCountryName();
      SetCityName();
      SetLocationName();
      SetBuildingName();
      SetElevatorName();
      SetMaintenanceFilter();
    } else if (filtertype === "Periodiupcomming") {
      SetElevatorName();
      SetStatusData();
      setFilterHeaderapiLoader(!filterHeaderapiLoader);
    } else if (filtertype === "PeriodicLog") {
      SetElevatorName();
      setFilterHeaderapiLoader(!filterHeaderapiLoader);
    } else if (filtertype === "rtmMainPage") {
      SetCountryName();
      SetCityName();
      SetLocationName();
      SetBuildingName();
      setFilterHeaderapiLoader(!filterHeaderapiLoader);
    } else if (filtertype === "rtmMainmodule") {
      SetCountryName();
      SetCityName();
      SetLocationName();
      SetBuildingName();
      SetStatusData();
      setFilterHeaderapiLoader(!filterHeaderapiLoader);
    } else if (filtertype === "onboarding") {
      SetCountryName();
      SetCityName();
      SetLocationName();
      SetBuildingName();
      setFilterHeaderapiLoader(!filterHeaderapiLoader);
    }
  };

  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "country") {
      SetCityName();
      SetLocationName();
      SetBuildingName();
      SetElevatorName();
      form.setFieldsValue({
        city: undefined,
        location: undefined,
        building: undefined,
        elevator: undefined,
        Cretria: undefined,
        Status: undefined,
        Priority: undefined,
        maintenanceTp: undefined,
        elevatorlistMaintensnce: undefined,
      });
    } else if (formFieldName === "city") {
      SetLocationName();
      SetBuildingName();
      SetElevatorName();
      form.setFieldsValue({
        location: undefined,
        building: undefined,
        elevator: undefined,
      });
    } else if (formFieldName === "location") {
      SetBuildingName();
      SetElevatorName();
      form.setFieldsValue({
        building: undefined,
        elevator: undefined,
      });
    } else if (formFieldName === "building") {
      SetElevatorName();
      form.setFieldsValue({
        elevator: undefined,
      });
    }
    setFilterHeaderapiLoader(!filterHeaderapiLoader);
  };

  useEffect(() => {
    const params = {
      PARENT_ID: userProfile?.["custom:parent_id"],
      TENANT_ID: userProfile?.["custom:tenant_id"],
    };

    axios.post(`${onboardingBaseUrl}/store-count/data`, params, {
      headers: { ["x-access-token"]: accessToken },
    }).then((response) => {
      setStoreData(response?.data?.data);
    });

    if (filtertype === "Periodiupcomming" || filtertype === "PeriodicLog") {
      axios.post(`${AlertBaseUrl}/getElevatorList`, {}, {
        headers: { ["x-access-token"]: accessToken }
      }).then((response) => {
        setElevatorList(response?.data);
      });
    }

    axiosRetry(axios, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      },
      retryCondition: (error) => error.response.status === 504,
    });

  }, []);

  return (
    <div className="CurrenttabfromDiv">
      <Form form={form} onValuesChange={handleFormValuesChange}>
        <Row className={filtertype === "onboarding" ? "SecondoptionRow" : "SecondRowAlertTab"} >

          {/* country */}
          {filtertype === "alertNotificationtab" ||
            filtertype === "alertNotificationcurrenttab" ||
            filtertype === "PeriodicConfiguration" ||
            filtertype === "rtmMainPage" ||
            filtertype === "onboarding" ||
            filtertype === "rtmMainmodule" ? (
            <Col className="filterCol">
              <Form.Item name="country">
                <Select
                  style={{ width: "8vw", }}
                  showSearch
                  onChange={(value, key) => {
                    setSelectIndex({ ...selectIndex, country: key.key });
                    SetCountryName(value);
                    setFilterHeaderapiLoader(!filterHeaderapiLoader);
                  }}
                  placeholder="Country"
                  disabled={disableWhileLoding}
                >
                  {StoreData?.map((country, index) => {
                    return (
                      <Option value={country?.COUNTRY} key={index}> {country?.COUNTRY} </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}

          {/* city */}
          {filtertype === "alertNotificationtab" ||
            filtertype === "alertNotificationcurrenttab" ||
            filtertype === "PeriodicConfiguration" ||
            filtertype === "rtmMainPage" ||
            filtertype === "onboarding" ||
            filtertype === "rtmMainmodule" ? (
            <Col className="filterCol">
              <Form.Item name="city">
                <Select
                  style={{ width: "10vw", }}
                  showSearch
                  onChange={(value, key) => {
                    SetCityName(value);
                    setSelectIndex({ ...selectIndex, city: key.key });
                    setFilterHeaderapiLoader(!filterHeaderapiLoader);
                  }}
                  placeholder="City"
                  disabled={disableWhileLoding || (selectIndex?.country ? false : true)}
                >
                  {StoreData?.[selectIndex?.country]?.CITIES?.map(
                    (city, cityIndex) => {
                      return (
                        <Option value={city?.CITY} key={cityIndex}> {city?.CITY} </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}

          {/*location */}
          {filtertype === "alertNotificationtab" ||
            filtertype === "alertNotificationcurrenttab" ||
            filtertype === "PeriodicConfiguration" ||
            filtertype === "rtmMainPage" ||
            filtertype === "onboarding" ||
            filtertype === "rtmMainmodule" ? (
            <Col className="filterCol">
              <Form.Item name="location">
                <Select
                  style={{ width: "13vw", }}
                  showSearch
                  onChange={(value, key) => {
                    setSelectIndex({ ...selectIndex, location: key.key });
                    SetLocationName(value);
                    setFilterHeaderapiLoader(!filterHeaderapiLoader);
                  }}
                  placeholder="Location"
                  disabled={disableWhileLoding || (selectIndex?.country && selectIndex?.city ? false : true)}
                >
                  {selectIndex?.city && StoreData?.[selectIndex?.country]?.CITIES?.[selectIndex?.city].LOCATION?.map((store, storeIndex) => {
                    return (
                      <Option value={store?.LOCATION} key={storeIndex}>
                        {store?.LOCATION}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}

          {/* building */}
          {filtertype === "alertNotificationtab" ||
            filtertype === "alertNotificationcurrenttab" ||
            filtertype === "PeriodicConfiguration" ||
            filtertype === "rtmMainPage" ||
            filtertype === "onboarding" ||
            filtertype === "rtmMainmodule" ? (
            <Col className="filterCol">
              <Form.Item name="building">
                <Select
                  style={{ width: "13vw", }}
                  showSearch
                  onChange={(value, key) => {
                    setSelectIndex({ ...selectIndex, store: key.key });
                    SetBuildingName(value);
                  }}
                  placeholder="Building"
                  disabled={disableWhileLoding || (selectIndex?.country && selectIndex?.city ? false : true)}
                >
                  {selectIndex?.city && StoreData?.[selectIndex?.country]?.CITIES?.[selectIndex?.city]?.LOCATION?.[selectIndex?.location]?.STORE?.map(
                    (store, storeIndex) => {
                      return (
                        <Option value={store?.STORE_ID} key={storeIndex}>
                          {store?.STORE_NAME}
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}

          {/* elevator*/}
          {filtertype === "alertNotificationtab" ||
            filtertype === "alertNotificationcurrenttab" ||
            filtertype === "PeriodicConfiguration" ? (
            <Col className="filterCol">
              <Form.Item name="elevator">
                <Select
                  style={{ width: "13vw", }}
                  showSearch
                  placeholder={deviceType}
                  disabled={selectIndex?.store ? false : true}
                  onChange={(value, key) => {
                    setSelectIndex({ ...selectIndex, elevator: key.key });
                    SetElevatorName(value);
                    setFilterHeaderapiLoader(!filterHeaderapiLoader);
                  }}
                >
                  {selectIndex?.store &&
                    StoreData?.[selectIndex?.country]?.CITIES?.[selectIndex?.city].LOCATION?.[selectIndex?.location]?.STORE?.[selectIndex?.store]?.DEVICE_DETAILS?.map(
                      (device, deviceIndex) => {
                        return (
                          <Option value={device?.DEVICE_ID} key={deviceIndex}>
                            {device?.DEVICE_NAME}
                          </Option>
                        );
                      })}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}

          {/* alert type */}
          {filtertype === "alertNotificationtab" ||
            filtertype === "alertNotificationcurrenttab" ? (
            <Col className="filterCol">
              <Form.Item name="Cretria">
                <Select
                  placeholder="Alert type"
                  onChange={(value) => {
                    SetAlertTypeName(value);
                    setFilterHeaderapiLoader(!filterHeaderapiLoader);
                  }}
                  style={{ width: 200, }}
                  options={window.localStorage.getItem('devicetype') == "Elevator" ? alertFilterTypeElevator : alertFilterTypeEquipment}
                />
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}
          
          {/* Priority */}
          {filtertype === "alertNotificationcurrenttab" && (
            <Col className="filterCol">
              <Form.Item name="Priority">
                <Select
                  style={{ width: "100%", }}
                  onChange={(value) => {
                    SetPriorityFilter(value);
                    setFilterHeaderapiLoader(!filterHeaderapiLoader);
                  }}
                  placeholder="Priority"
                >
                  {PriorityOption?.map((status, Index) => {
                    return (<Option value={status} key={Index}> {status} </Option>);
                  })}
                </Select>
              </Form.Item>
            </Col>
          )}

          {/* elevatorList */}
          {filtertype === "Periodiupcomming" || filtertype === "PeriodicLog" ? (
            <Col className="filterCol">
              <Form.Item name="elevatorlistMaintensnce">
                <Select
                  style={{ width: "100%", }}
                  onChange={(value) => {
                    SetElevatorName(value);
                    setFilterHeaderapiLoader(!filterHeaderapiLoader);
                  }}
                  placeholder={deviceType}
                >
                  {elevatorList?.map((elevator, index) => {
                    return (<Option value={elevator} key={index}> {elevator} </Option>);
                  })}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}

          {/* alert Status */}
          {filtertype === "alertNotificationtab" ? (
            <Col className="filterCol">
              <Form.Item name="Status">
                <Select
                  onChange={(value) => {
                    SetStatusData(value);
                    setFilterHeaderapiLoader(!filterHeaderapiLoader);
                  }}
                  placeholder="Status"
                >
                  {StatusOption?.map((status, Index) => {
                    return (<Option value={status} key={Index}> {status} </Option>);
                  })}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}

          {filtertype === "rtmMainmodule" ? (
            <Col className="filterCol">
              <Form.Item name="RtmStatus">
                <Select
                  onChange={(value) => {
                    SetStatusData(value);
                    setFilterHeaderapiLoader(!filterHeaderapiLoader);
                  }}
                  placeholder="Status"
                  disabled={disableWhileLoding}
                >
                  {elevatorStatusOption?.map((status, Index) => {
                    return (<Option value={status} key={Index}> {status} </Option>);
                  })}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}

          {/* Maintennace Status */}
          {filtertype === "Periodiupcomming" ? (
            <Col>
              <Form.Item name="maintenanceStatus">
                <Select
                  onChange={(value) => {
                    SetStatusData(value);
                    setFilterHeaderapiLoader(!filterHeaderapiLoader);
                  }}
                  placeholder="Status"
                >
                  {maintenanceStatusOption?.map((status, Index) => {
                    return (<Option value={status} key={Index}> {status} </Option>);
                  })}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}

          {/* Maintennace Type */}
          {filtertype === "PeriodicConfiguration" ? (
            <Col>
              <Form.Item name="maintenanceTp">
                <Select
                  placeholder="Maintenance type"
                  onChange={(value) => {
                    SetMaintenanceFilter(value);
                    setFilterHeaderapiLoader(!filterHeaderapiLoader);
                  }}
                  style={{ width: 200, }}
                  options={maintenanceTypeOption}
                />
              </Form.Item>
            </Col>
          ) : (
            <></>
          )}

          <Col>
            <RedoOutlined
              onClick={resetData}
              onMouseEnter={() => { setHover(true); }}
              onMouseLeave={() => { setHover(false); }}
              style={{
                fontSize: hover === true ? "22px" : "20px",
                marginTop: "8px",
                color: hover === true ? "#1890FF" : "black",
                fontWeight: "bold",
                marginLeft: "10px",
              }}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FilterForm;
