/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Row,
  Button,
  Select,
  Form,
  Input,
  Spin,
  notification,
  Modal,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { onboardingBaseUrl } from "../../../common/BaseUrl";

const AddEditStore = ({
  setOpenEditStoreModal,
  setOpenAddStoreModal,
  editStoreData,
  ParentAllData,
  setAddStoreLoader,
  addStoreLoader,
}) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const accessToken = window.localStorage.getItem("accessToken");
  const [storeFormLoader, setStoreFormLoader] = useState(false);
  const [selectParent, setSelectParent] = useState();
  const [selectTEnantVAlue, setSelectTEnantVAlue] = useState();
  const [editedFields, setEditedFields] = useState({});
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const [removeIndex, setRemoveIndex] = useState()
  useEffect(() => {
    if (editStoreData) {
      form.setFieldsValue({
        PARENT_NAME: editStoreData?.PARENT_NAME,
        TENANT_NAME: editStoreData?.TENANT_NAME,
        STORES: editStoreData?.STORES,
      });

      const editedFieldsCopy = {};
      editStoreData?.STORES?.forEach((store, index) => {
        editedFieldsCopy[index] = store.STORE_NAME;
      });
      setEditedFields(editedFieldsCopy);
    }
  }, [editStoreData]);

  useEffect(() => {
    if (ParentAllData) {
      for (let i = 0; i < ParentAllData.length; i++) {
        const tenantList = ParentAllData[i];
        for (let j = 0; j < tenantList.TENANTS.length; j++) {
          const tenantInfo = tenantList.TENANTS[j];
          if (tenantInfo.TENANT_ID === selectTEnantVAlue) {
            if (tenantInfo.STORES && tenantInfo.STORES.length > 0) {
              form.setFieldsValue({
                STORES: tenantInfo.STORES,
              });
            }
            break;
          }
        }
      }
    }
  }, [ParentAllData, selectTEnantVAlue]);
  const handleStoreNameChange = (index, value) => {
    const stores = form.getFieldValue("STORES");
    const editedFieldsCopy = { ...editedFields };

    if (editedFieldsCopy[index] !== undefined) {
      stores[index].STORE_NAME = value;
      stores[index].EDIT_STORE_NAME = true;
      stores[index].ADD_STORE_NAME = undefined;
    } else {
      stores[index] = {
        STORE_NAME: value,
      };
      if (editStoreData) {
        stores[index].ADD_STORE_NAME = true;
      }
    }

    setEditedFields(editedFieldsCopy);

    form.setFieldsValue({
      STORES: stores,
    });
  };
  const onStoreFormSubmit = (value) => {
    setStoreFormLoader(true);
    if (editStoreData) {
      axios
        .post(
          `${onboardingBaseUrl}/editStoreDetails/${editStoreData?.PARENT_ID}`,
          {
            TENANT_ID: editStoreData?.TENANT_ID,
            STORES: value?.STORES,
          },
          {
            headers: { ["x-access-token"]: accessToken },
          }
        )
        .then((response) => {
          if (response?.status === 200) {
            notification.success({ description: response?.data });
            setOpenEditStoreModal(false);
            setStoreFormLoader(false);
            setAddStoreLoader(!addStoreLoader);
          }
        });
    } else {
      axios
        .post(
          `${onboardingBaseUrl}/addStoreDetails/${value?.PARENT_NAME}`,
          {
            TENANT_ID: value?.TENANT_NAME,
            STORES: value?.STORES,
          },
          {
            headers: { ["x-access-token"]: accessToken },
          }
        )
        .then((response) => {
          if (response?.status === 200) {
            notification.success({ description: response?.data });
            setOpenAddStoreModal(false);
            setStoreFormLoader(false);
            setAddStoreLoader(!addStoreLoader);
          }
        });
    }
  };
  const showRemoveModal = (index) => {
    const stores = form.getFieldValue("STORES");
    if (editStoreData) {
      if (stores[index]?.STORE_NAME) {
        setRemoveModalVisible(true);
        setRemoveIndex(index)
      } else {
        stores.splice(index, 1);
        form.setFieldsValue({
          STORES: stores,
        });
      }
    } else {
      stores.splice(index, 1);
      form.setFieldsValue({
        STORES: stores,
      });
    }
  };
  const handleRemoveConfirm = () => {
    const stores = form.getFieldValue("STORES");
    stores.splice(removeIndex, 1);
    form.setFieldsValue({
      STORES: stores,
    });
    setRemoveModalVisible(false);
  };
  return (
    <div>
      <Spin
        className="parentFormLoader"
        tip={editStoreData ? "Editing Data ..." : "Adding Data..."}
        delay={500}
        spinning={storeFormLoader}
      >
        <Form
          name="Store form"
          form={form}
          initialvalue={{}}
          onFinish={onStoreFormSubmit}
        >
          <div className="formInputStyleLocation">
            <label className="formLabelStyle">Parent Name</label>
            <Form.Item
              name="PARENT_NAME"
              rules={[
                {
                  required: true,
                  message: "Please Select the Parent Name",
                },
              ]}
            >
              <Select
                placeholder="Parent Name"
                className="ReportModal"
                disabled={!editStoreData ? false : true}
                onChange={(value) => {
                  setSelectParent(value);
                }}
              >
                {ParentAllData?.map((parent, index) => {
                  return (
                    <Option key={index} value={parent?.PARENT_ID}>
                      {parent?.PARENT_NAME}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div className="formInputStyleLocation" style={{ marginTop: "20px" }}>
            <label className="formLabelStyle">Tenant Name</label>
            <Form.Item
              name="TENANT_NAME"
              rules={[
                {
                  required: true,
                  message: "Please Select the Tenant Name",
                },
              ]}
            >
              <Select
                placeholder="Tenant Name"
                disabled={selectParent || !editStoreData ? false : true}
                onChange={(value) => {
                  setSelectTEnantVAlue(value);
                }}
              >
                {ParentAllData?.filter(
                  (parent) => parent?.PARENT_ID === selectParent
                )?.map((alltenants) =>
                  alltenants?.TENANTS?.map((tenant, index) => {
                    return (
                      <Option key={index} value={tenant?.TENANT_ID}>
                        {tenant?.TENANT_NAME}
                      </Option>
                    );
                  })
                )}
              </Select>
            </Form.Item>
          </div>
          <Row className="rowHeadingStyle11" style={{ marginTop: "25px" }}>
            {editStoreData ? "Edit Store" : "Add Store"}
          </Row>
          <Form.List
            name="STORES"
            initialValue={editStoreData ? editStoreData.STORE : [{}]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#edede8",
                        padding: "10px",
                        height: "90px",
                      }}
                    >
                      <div
                        className="formInputStyleLocation"
                        style={{ width: "30vw" }}
                      >
                        <label className="formLabelStyle">Store Name</label>
                        <Form.Item
                          key={key}
                          {...restField}
                          name={[name, "STORE_NAME"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Store name",
                            },
                          ]}
                        >
                          <Input
                            className="formInput101"
                            placeholder="Store Name"
                            onChange={(e) =>
                              handleStoreNameChange(index, e.target.value)
                            }
                          />
                        </Form.Item>
                      </div>
                      <Button
                        type="primary"
                        onClick={() =>{ 
                          showRemoveModal(index)
                        }}
                        disabled={fields.length === 1}
                      >
                        remove
                      </Button>
                    </div>
                  );
                })}

                <Form.Item>
                  <Button
                    style={{ marginTop: "30px", color: "white" }}
                    type="primary"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Store
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button type="primary" htmlType="cancel">
                Submit
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: "10px", color: "white" }}
                onClick={() => {
                  form.resetFields();
                  if (editStoreData) {
                    setOpenEditStoreModal(false);
                  } else {
                    setOpenAddStoreModal(false);
                  }
                }}
              >
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Spin>
      <Modal
        title="Remove Store"
        open={removeModalVisible}
        onOk={handleRemoveConfirm}
        onCancel={() => setRemoveModalVisible(false)}
      >
        <div
          style={{ textAlign: "center", fontSize: "80px", color: "#008ecf" }}
        >
          <ExclamationCircleOutlined />
        </div>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          ARE YOU SURE YOU WANT TO REMOVE THIS STORE?
        </h3>
      </Modal>
    </div>
  );
};

export default AddEditStore;
