export const countryList = [
  {
    COUNTRY: "ANTIGUA AND BARBUDA",
    CODE: "AG",
    CITIES: [
      {
        CITY: "ALL SAINTS",
      },
      {
        CITY: "CASSADA GARDENS",
      },
      {
        CITY: "CODRINGTON",
      },
      {
        CITY: "OLD ROAD",
      },
      {
        CITY: "PARHAM",
      },
      {
        CITY: "WOODS",
      },
    ],
  },
  {
    COUNTRY: "ALBANIA",
    CODE: "AL",
    CITIES: [
      {
        CITY: "ELBASAN",
      },
      {
        CITY: "PETRAN",
      },
      {
        CITY: "POGRADEC",
      },
      {
        CITY: "SHKODER",
      },
      {
        CITY: "TIRANA",
      },
      {
        CITY: "URA VAJGURORE",
      },
    ],
  },
  {
    COUNTRY: "ANGOLA",
    CODE: "AO",
    CITIES: [
      {
        CITY: "AMBRIZ",
      },
      {
        CITY: "BENGUELA",
      },
      {
        CITY: "CABINDA",
      },
      {
        CITY: "CACOLE",
      },
      {
        CITY: "CAMABATELA",
      },
      {
        CITY: "CAZETA",
      },
      {
        CITY: "HUAMBO",
      },
      {
        CITY: "KUITO",
      },
      {
        CITY: "LOBITO",
      },
      {
        CITY: "LUANDA",
      },
      {
        CITY: "LUBANGO",
      },
      {
        CITY: "LUCAPA",
      },
      {
        CITY: "LUMEJE",
      },
      {
        CITY: "MALANJE",
      },
      {
        CITY: "MENONGUE",
      },
      {
        CITY: "MUXALUANDO",
      },
      {
        CITY: "NAMIBE",
      },
      {
        CITY: "ONDJIVA",
      },
      {
        CITY: "PIRI",
      },
      {
        CITY: "SAURIMO",
      },
      {
        CITY: "TALATONA",
      },
    ],
  },
  {
    COUNTRY: "ARGENTINA",
    CODE: "AR",
    CITIES: [
      {
        CITY: "28 DE NOVIEMBRE",
      },
      {
        CITY: "ABASTO",
      },
      {
        CITY: "ACASSUSO",
      },
      {
        CITY: "ACEBAL",
      },
      {
        CITY: "ACEVEDO",
      },
      {
        CITY: "ADELIA MARIA",
      },
      {
        CITY: "AGUA DE ORO",
      },
      {
        CITY: "ALBARDON",
      },
      {
        CITY: "ALBARELLOS",
      },
      {
        CITY: "ALBERDI",
      },
      {
        CITY: "ALBERTI",
      },
      {
        CITY: "ALDO BONZI",
      },
      {
        CITY: "ALEJANDRO KORN",
      },
      {
        CITY: "ALICIA",
      },
      {
        CITY: "ALLEN",
      },
      {
        CITY: "ALMAFUERTE",
      },
      {
        CITY: "ALMAGRO",
      },
      {
        CITY: "ALMIRANTE BROWN",
      },
      {
        CITY: "ALTA GRACIA",
      },
      {
        CITY: "ALTA ITALIA",
      },
      {
        CITY: "ALVAREZ",
      },
      {
        CITY: "ALVEAR",
      },
      {
        CITY: "ANATUYA",
      },
      {
        CITY: "ANGELICA",
      },
      {
        CITY: "ANTONIO CARBONI",
      },
      {
        CITY: "APOSTOLES",
      },
      {
        CITY: "AREQUITO",
      },
      {
        CITY: "ARMSTRONG",
      },
      {
        CITY: "ARRECIFES",
      },
      {
        CITY: "ARROYITO",
      },
      {
        CITY: "ARROYITO",
      },
      {
        CITY: "ARROYITO CHALLACO",
      },
      {
        CITY: "ARROYO SECO",
      },
      {
        CITY: "ARTEAGA",
      },
      {
        CITY: "ASAMBLEA",
      },
      {
        CITY: "ASCENSION",
      },
      {
        CITY: "AVELLANEDA",
      },
      {
        CITY: "AVELLANEDA",
      },
      {
        CITY: "AYACUCHO",
      },
      {
        CITY: "AZARA",
      },
      {
        CITY: "AZUL",
      },
      {
        CITY: "BALCARCE",
      },
      {
        CITY: "BALNEARIA",
      },
      {
        CITY: "BANDA DEL RIO SALI",
      },
      {
        CITY: "BANDERA",
      },
      {
        CITY: "BANFIELD",
      },
      {
        CITY: "BARADERO",
      },
      {
        CITY: "BARILOCHE",
      },
      {
        CITY: "BARRIO FISHERTON",
      },
      {
        CITY: "BATAN",
      },
      {
        CITY: "BECCAR",
      },
      {
        CITY: "BELEN DE ESCOBAR",
      },
      {
        CITY: "BELGRANO",
      },
      {
        CITY: "BELGRANO",
      },
      {
        CITY: "BELL VILLE",
      },
      {
        CITY: "BELLA VISTA",
      },
      {
        CITY: "BELLA VISTA",
      },
      {
        CITY: "BENAVIDEZ",
      },
      {
        CITY: "BERAZATEGUI",
      },
      {
        CITY: "BERISSO",
      },
      {
        CITY: "BERNAL",
      },
      {
        CITY: "BERNARDO LARROUDE",
      },
      {
        CITY: "BERNASCONI",
      },
      {
        CITY: "BIGAND",
      },
      {
        CITY: "BOMBAL",
      },
      {
        CITY: "BORDENAVE",
      },
      {
        CITY: "BOSCH",
      },
      {
        CITY: "BOSQUES",
      },
      {
        CITY: "BOULOGNE",
      },
      {
        CITY: "BOVRIL",
      },
      {
        CITY: "BRAGADO",
      },
      {
        CITY: "BRANDSEN",
      },
      {
        CITY: "BRINKMANN",
      },
      {
        CITY: "BUENOS AIRES",
      },
      {
        CITY: "BURZACO",
      },
      {
        CITY: "BUSTINZA",
      },
      {
        CITY: "CABALLITO",
      },
      {
        CITY: "CALDERON",
      },
      {
        CITY: "CALETA OLIVIA",
      },
      {
        CITY: "CALEUFU",
      },
      {
        CITY: "CAMILO ALDAO",
      },
      {
        CITY: "CAMPANA",
      },
      {
        CITY: "CANADA DE GOMEZ",
      },
      {
        CITY: "CANADA DEL UCLE",
      },
      {
        CITY: "CANADA ROSQUIN",
      },
      {
        CITY: "CANALS",
      },
      {
        CITY: "CANNING",
      },
      {
        CITY: "CANUELAS",
      },
      {
        CITY: "CAPILLA DEL MONTE",
      },
      {
        CITY: "CAPILLA DEL SENOR",
      },
      {
        CITY: "CAPITAN BERMUDEZ",
      },
      {
        CITY: "CARHUE",
      },
      {
        CITY: "CARLOS CASARES",
      },
      {
        CITY: "CARLOS PELLEGRINI",
      },
      {
        CITY: "CARLOS TEJEDOR",
      },
      {
        CITY: "CASEROS",
      },
      {
        CITY: "CASILDA",
      },
      {
        CITY: "CASTELAR",
      },
      {
        CITY: "CASTELLI",
      },
      {
        CITY: "CASTILLO",
      },
      {
        CITY: "CATRIEL",
      },
      {
        CITY: "CATRILO",
      },
      {
        CITY: "CAVANAGH",
      },
      {
        CITY: "CENTENARIO",
      },
      {
        CITY: "CERES",
      },
      {
        CITY: "CERVANTES",
      },
      {
        CITY: "CHACABUCO",
      },
      {
        CITY: "CHACARITA",
      },
      {
        CITY: "CHAJARI",
      },
      {
        CITY: "CHARATA",
      },
      {
        CITY: "CHATEAUBRIAND",
      },
      {
        CITY: "CHILECITO",
      },
      {
        CITY: "CHIVILCOY",
      },
      {
        CITY: "CHOELE CHOEL",
      },
      {
        CITY: "CHORROARIN",
      },
      {
        CITY: "CINCO SALTOS",
      },
      {
        CITY: "CIPOLLETTI",
      },
      {
        CITY: "CITY BELL",
      },
      {
        CITY: "CIUDAD GENERAL BELGRANO",
      },
      {
        CITY: "CIUDADELA",
      },
      {
        CITY: "CLAYPOLE",
      },
      {
        CITY: "CLORINDA",
      },
      {
        CITY: "COLON",
      },
      {
        CITY: "COLON",
      },
      {
        CITY: "COLONIA BARON",
      },
      {
        CITY: "COLONIA CAROYA",
      },
      {
        CITY: "COLONIA SAN MIGUEL ARCANGEL",
      },
      {
        CITY: "COMODORO RIVADAVIA",
      },
      {
        CITY: "CONCEPCION",
      },
      {
        CITY: "CONCEPCION",
      },
      {
        CITY: "CONCORDIA",
      },
      {
        CITY: "CONSTITUYENTES",
      },
      {
        CITY: "CORONEL DORREGO",
      },
      {
        CITY: "CORONEL MARTINEZ DE HOZ",
      },
      {
        CITY: "CORONEL PRINGLES",
      },
      {
        CITY: "CORRAL DE BUSTOS",
      },
      {
        CITY: "CORRALITOS",
      },
      {
        CITY: "CORRIENTES",
      },
      {
        CITY: "COSQUIN",
      },
      {
        CITY: "COY AIKE",
      },
      {
        CITY: "CRAMER",
      },
      {
        CITY: "CRESPO",
      },
      {
        CITY: "CRUZ DEL EJE",
      },
      {
        CITY: "CURUZU CUATIA",
      },
      {
        CITY: "CUTRAL-CO",
      },
      {
        CITY: "DARREGUEIRA",
      },
      {
        CITY: "DE MAYO",
      },
      {
        CITY: "DEL CAMPILLO",
      },
      {
        CITY: "DEL VISO",
      },
      {
        CITY: "DESPENADEROS",
      },
      {
        CITY: "DEVOTO",
      },
      {
        CITY: "DIAZ",
      },
      {
        CITY: "DIEGO DE ALVEAR",
      },
      {
        CITY: "DOBLAS",
      },
      {
        CITY: "DOCK SUD",
      },
      {
        CITY: "DOLORES",
      },
      {
        CITY: "DON BOSCO",
      },
      {
        CITY: "DON TORCUATO",
      },
      {
        CITY: "DRABBLE",
      },
      {
        CITY: "EDUARDO CASTEX",
      },
      {
        CITY: "EL CALAFATE",
      },
      {
        CITY: "EL DORADO",
      },
      {
        CITY: "EL HOYO",
      },
      {
        CITY: "EL PALOMAR",
      },
      {
        CITY: "EL PALOMAR",
      },
      {
        CITY: "EL TALAR",
      },
      {
        CITY: "EL TREBOL",
      },
      {
        CITY: "ELDORADO",
      },
      {
        CITY: "EMBALSE",
      },
      {
        CITY: "EMPALME LOBOS",
      },
      {
        CITY: "ENSENADA",
      },
      {
        CITY: "ESPERANZA",
      },
      {
        CITY: "ESQUEL",
      },
      {
        CITY: "ESTEBAN ECHEVERRIA",
      },
      {
        CITY: "EZEIZA",
      },
      {
        CITY: "EZPELETA",
      },
      {
        CITY: "FAMAILLA",
      },
      {
        CITY: "FATIMA",
      },
      {
        CITY: "FEDERAL",
      },
      {
        CITY: "FERNANDEZ",
      },
      {
        CITY: "FIRMAT",
      },
      {
        CITY: "FLORENCIO VARELA",
      },
      {
        CITY: "FLORENTINO AMEGHINO",
      },
      {
        CITY: "FLORES",
      },
      {
        CITY: "FLORESTA",
      },
      {
        CITY: "FLORIDA",
      },
      {
        CITY: "FORMOSA",
      },
      {
        CITY: "FRANCISCO ALVAREZ",
      },
      {
        CITY: "FRANCK",
      },
      {
        CITY: "FRAY LUIS A. BELTRAN",
      },
      {
        CITY: "FREYRE",
      },
      {
        CITY: "FRIAS",
      },
      {
        CITY: "FUNES",
      },
      {
        CITY: "GAIMAN",
      },
      {
        CITY: "GALVEZ",
      },
      {
        CITY: "GARIN",
      },
      {
        CITY: "GARUPA",
      },
      {
        CITY: "GENERAL ACHA",
      },
      {
        CITY: "GENERAL ALVEAR",
      },
      {
        CITY: "GENERAL CABRERA",
      },
      {
        CITY: "GENERAL DEHEZA",
      },
      {
        CITY: "GENERAL GUIDO",
      },
      {
        CITY: "GENERAL JUAN MADARIAGA",
      },
      {
        CITY: "GENERAL LAGOS",
      },
      {
        CITY: "GENERAL LAS HERAS",
      },
      {
        CITY: "GENERAL LAVALLE",
      },
      {
        CITY: "GENERAL MANSILLA",
      },
      {
        CITY: "GENERAL MARTIN MIGUEL DE GUEMES",
      },
      {
        CITY: "GENERAL PACHECO",
      },
      {
        CITY: "GENERAL PAZ",
      },
      {
        CITY: "GENERAL PICO",
      },
      {
        CITY: "GENERAL ROCA",
      },
      {
        CITY: "GENERAL ROCA",
      },
      {
        CITY: "GENERAL RODRIGUEZ",
      },
      {
        CITY: "GENERAL SAN MARTIN",
      },
      {
        CITY: "GENERAL SAN MARTIN",
      },
      {
        CITY: "GENERAL VIAMONTE",
      },
      {
        CITY: "GENERAL VILLEGAS",
      },
      {
        CITY: "GERMANIA",
      },
      {
        CITY: "GLEW",
      },
      {
        CITY: "GOBERNADOR CRESPO",
      },
      {
        CITY: "GOBERNADOR GALVEZ",
      },
      {
        CITY: "GODOY",
      },
      {
        CITY: "GODOY CRUZ",
      },
      {
        CITY: "GONZALEZ CATAN",
      },
      {
        CITY: "GONZALEZ MORENO",
      },
      {
        CITY: "GOYA",
      },
      {
        CITY: "GRANADERO BAIGORRIA",
      },
      {
        CITY: "GRAND BOURG",
      },
      {
        CITY: "GUALEGUAY",
      },
      {
        CITY: "GUATRACHE",
      },
      {
        CITY: "GUERNICA",
      },
      {
        CITY: "HENDERSON",
      },
      {
        CITY: "HERNANDO",
      },
      {
        CITY: "HERSILIA",
      },
      {
        CITY: "HILARIO",
      },
      {
        CITY: "HILARIO ASCASUBI",
      },
      {
        CITY: "HIPATIA",
      },
      {
        CITY: "HIPOLITO YRIGOYEN",
      },
      {
        CITY: "HUANCHILLAS",
      },
      {
        CITY: "HUANGUELEN",
      },
      {
        CITY: "HUINCA RENANCO",
      },
      {
        CITY: "HUMAHUACA",
      },
      {
        CITY: "HURLINGHAM",
      },
      {
        CITY: "IBARLUCEA",
      },
      {
        CITY: "IBICUY",
      },
      {
        CITY: "INDEPENDENCIA",
      },
      {
        CITY: "INGENIERO BEAUGEY",
      },
      {
        CITY: "INGENIERO LUIGGI",
      },
      {
        CITY: "INGENIERO MASCHWITZ",
      },
      {
        CITY: "INTENDENTE ALVEAR",
      },
      {
        CITY: "ISIDRO CASANOVA",
      },
      {
        CITY: "ITUZAINGO",
      },
      {
        CITY: "JAMES CRAIK",
      },
      {
        CITY: "JAUREGUI",
      },
      {
        CITY: "JEPPENER",
      },
      {
        CITY: "JESUS MARIA",
      },
      {
        CITY: "JOSE LEON SUAREZ",
      },
      {
        CITY: "JOSE MARMOL",
      },
      {
        CITY: "JUAN PUJOL",
      },
      {
        CITY: "JUSTINIANO POSSE",
      },
      {
        CITY: "LA BANDA",
      },
      {
        CITY: "LA BOCA",
      },
      {
        CITY: "LA CALERA",
      },
      {
        CITY: "LA CUMBRE",
      },
      {
        CITY: "LA FALDA",
      },
      {
        CITY: "LA LEONESA",
      },
      {
        CITY: "LA LUCILA",
      },
      {
        CITY: "LA MADRID",
      },
      {
        CITY: "LA PAMPA",
      },
      {
        CITY: "LA PARA",
      },
      {
        CITY: "LA PAZ",
      },
      {
        CITY: "LA PAZ",
      },
      {
        CITY: "LA PLATA",
      },
      {
        CITY: "LA PUNTA",
      },
      {
        CITY: "LA RIOJA",
      },
      {
        CITY: "LA TABLADA",
      },
      {
        CITY: "LA UNION",
      },
      {
        CITY: "LA VIOLETA",
      },
      {
        CITY: "LABORDE",
      },
      {
        CITY: "LABOULAYE",
      },
      {
        CITY: "LAFERRERE",
      },
      {
        CITY: "LAGUNA ALSINA",
      },
      {
        CITY: "LANUS",
      },
      {
        CITY: "LARROQUE",
      },
      {
        CITY: "LAS CATITAS",
      },
      {
        CITY: "LAS FLORES",
      },
      {
        CITY: "LAS FLORES",
      },
      {
        CITY: "LAS HERAS",
      },
      {
        CITY: "LAS PERDICES",
      },
      {
        CITY: "LAS ROSAS",
      },
      {
        CITY: "LAS TALITAS",
      },
      {
        CITY: "LAS VARILLAS",
      },
      {
        CITY: "LAVALLE",
      },
      {
        CITY: "LEANDRO N. ALEM",
      },
      {
        CITY: "LEONES",
      },
      {
        CITY: "LIBERTAD",
      },
      {
        CITY: "LIMA",
      },
      {
        CITY: "LINIERS",
      },
      {
        CITY: "LLAVALLOL",
      },
      {
        CITY: "LOBOS",
      },
      {
        CITY: "LOMAS DE ZAMORA",
      },
      {
        CITY: "LOMAS DEL MIRADOR",
      },
      {
        CITY: "LONGCHAMPS",
      },
      {
        CITY: "LOS ANTIGUOS",
      },
      {
        CITY: "LOS CARDALES",
      },
      {
        CITY: "LOS MOLINOS",
      },
      {
        CITY: "LOS POLVORINES",
      },
      {
        CITY: "LUIS GUILLON",
      },
      {
        CITY: "LUJAN DE CUYO",
      },
      {
        CITY: "LUQUE",
      },
      {
        CITY: "LUZURIAGA",
      },
      {
        CITY: "LYNCH",
      },
      {
        CITY: "MACACHIN",
      },
      {
        CITY: "MAGDALENA",
      },
      {
        CITY: "MAGDALENA",
      },
      {
        CITY: "MAGGIOLO",
      },
      {
        CITY: "MAIPU",
      },
      {
        CITY: "MAIPU",
      },
      {
        CITY: "MANANTIAL",
      },
      {
        CITY: "MANFREDI",
      },
      {
        CITY: "MANUEL J. COBO",
      },
      {
        CITY: "MAQUINISTA SAVIO",
      },
      {
        CITY: "MAR DE AJO",
      },
      {
        CITY: "MAR DEL PLATA",
      },
      {
        CITY: "MAR DEL TUYU",
      },
      {
        CITY: "MARCOS JUAREZ",
      },
      {
        CITY: "MARCOS PAZ",
      },
      {
        CITY: "MARGARITA",
      },
      {
        CITY: "MARIA IGNACIA",
      },
      {
        CITY: "MARIA JUANA",
      },
      {
        CITY: "MARIANO ACOSTA",
      },
      {
        CITY: "MARIANO J. HAEDO",
      },
      {
        CITY: "MARIANO MORENO",
      },
      {
        CITY: "MARTINEZ",
      },
      {
        CITY: "MATHEU",
      },
      {
        CITY: "MAYOR BURATOVICH",
      },
      {
        CITY: "MELINCUE",
      },
      {
        CITY: "MENDIOLAZA",
      },
      {
        CITY: "MENDOZA",
      },
      {
        CITY: "MERCEDES",
      },
      {
        CITY: "MERCEDES",
      },
      {
        CITY: "MERLO",
      },
      {
        CITY: "MERLO",
      },
      {
        CITY: "MINACAR",
      },
      {
        CITY: "MIRAMAR",
      },
      {
        CITY: "MIRAMAR",
      },
      {
        CITY: "MONJE",
      },
      {
        CITY: "MONTE HERMOSO",
      },
      {
        CITY: "MONTEROS",
      },
      {
        CITY: "MONTSERRAT",
      },
      {
        CITY: "MORENO",
      },
      {
        CITY: "MORON",
      },
      {
        CITY: "MORTEROS",
      },
      {
        CITY: "MUNIZ",
      },
      {
        CITY: "MUNRO",
      },
      {
        CITY: "NAVARRO",
      },
      {
        CITY: "NAVARRO",
      },
      {
        CITY: "NECOCHEA",
      },
      {
        CITY: "NOGOYA",
      },
      {
        CITY: "NORDELTA",
      },
      {
        CITY: "NUNEZ",
      },
      {
        CITY: "OBERA",
      },
      {
        CITY: "OLIVA",
      },
      {
        CITY: "OLIVEROS",
      },
      {
        CITY: "OLIVOS",
      },
      {
        CITY: "ONCATIVO",
      },
      {
        CITY: "OPEN DOOR",
      },
      {
        CITY: "ORDONEZ",
      },
      {
        CITY: "PALERMO",
      },
      {
        CITY: "PALMIRA",
      },
      {
        CITY: "PALPALA",
      },
      {
        CITY: "PARTIDO DE JOSE C. PAZ",
      },
      {
        CITY: "PASCO",
      },
      {
        CITY: "PASO DEL REY",
      },
      {
        CITY: "PATERNAL",
      },
      {
        CITY: "PAVON",
      },
      {
        CITY: "PEDERNALES",
      },
      {
        CITY: "PEDRO LURO",
      },
      {
        CITY: "PELLEGRINI",
      },
      {
        CITY: "PEREZ",
      },
      {
        CITY: "PERGAMINO",
      },
      {
        CITY: "PERICO",
      },
      {
        CITY: "PERITO MORENO",
      },
      {
        CITY: "PIAMONTE",
      },
      {
        CITY: "PICO DE SALAMANCA",
      },
      {
        CITY: "PICO TRUNCADO",
      },
      {
        CITY: "PIGUE",
      },
      {
        CITY: "PILAR",
      },
      {
        CITY: "PILAR",
      },
      {
        CITY: "PILAR",
      },
      {
        CITY: "PINAMAR",
      },
      {
        CITY: "PIQUETE CABADO",
      },
      {
        CITY: "PLATANOS",
      },
      {
        CITY: "PLAZA HUINCUL",
      },
      {
        CITY: "PLOTTIER",
      },
      {
        CITY: "PONTEVEDRA",
      },
      {
        CITY: "PORTENA",
      },
      {
        CITY: "POSADAS",
      },
      {
        CITY: "POZO DEL MOLLE",
      },
      {
        CITY: "PRESIDENTE DERQUI",
      },
      {
        CITY: "PUAN",
      },
      {
        CITY: "PUEBLO SAN JOSE",
      },
      {
        CITY: "PUERTO MADRYN",
      },
      {
        CITY: "PUERTO RICO",
      },
      {
        CITY: "PUEYRREDON",
      },
      {
        CITY: "PUNTA ALTA",
      },
      {
        CITY: "QUILMES",
      },
      {
        CITY: "RADA TILLY",
      },
      {
        CITY: "RAFAEL CALZADA",
      },
      {
        CITY: "RAFAEL CASTILLO",
      },
      {
        CITY: "RAFAEL OBLIGADO",
      },
      {
        CITY: "RAFAELA",
      },
      {
        CITY: "RAMALLO",
      },
      {
        CITY: "RAMOS MEJIA",
      },
      {
        CITY: "RANCHOS",
      },
      {
        CITY: "RANCUL",
      },
      {
        CITY: "RANELAGH",
      },
      {
        CITY: "RAWSON",
      },
      {
        CITY: "RAWSON",
      },
      {
        CITY: "REALICO",
      },
      {
        CITY: "RECOLETA",
      },
      {
        CITY: "RECONQUISTA",
      },
      {
        CITY: "REMEDIOS DE ESCALADA",
      },
      {
        CITY: "RESISTENCIA",
      },
      {
        CITY: "RETIRO",
      },
      {
        CITY: "RIO CEBALLOS",
      },
      {
        CITY: "RIO COLORADO",
      },
      {
        CITY: "RIO GRANDE",
      },
      {
        CITY: "RIO PIEDRAS",
      },
      {
        CITY: "RIO SEGUNDO",
      },
      {
        CITY: "RIO TERCERO",
      },
      {
        CITY: "RIVADAVIA",
      },
      {
        CITY: "RIVADAVIA",
      },
      {
        CITY: "RIVADAVIA",
      },
      {
        CITY: "ROCAMORA",
      },
      {
        CITY: "RODRIGUEZ PENA",
      },
      {
        CITY: "ROJAS",
      },
      {
        CITY: "ROLDAN",
      },
      {
        CITY: "ROQUE PEREZ",
      },
      {
        CITY: "ROSARIO",
      },
      {
        CITY: "ROSAS",
      },
      {
        CITY: "RUFINO",
      },
      {
        CITY: "SACANTA",
      },
      {
        CITY: "SAENZ PENA",
      },
      {
        CITY: "SALADILLO",
      },
      {
        CITY: "SALADILLO",
      },
      {
        CITY: "SALGUERO",
      },
      {
        CITY: "SALSIPUEDES",
      },
      {
        CITY: "SALTA",
      },
      {
        CITY: "SALTO",
      },
      {
        CITY: "SALTO GRANDE",
      },
      {
        CITY: "SAMPACHO",
      },
      {
        CITY: "SAN ANDRES",
      },
      {
        CITY: "SAN ANDRES DE GILES",
      },
      {
        CITY: "SAN ANTONIO DE ARECO",
      },
      {
        CITY: "SAN ANTONIO DE ARREDONDO",
      },
      {
        CITY: "SAN ANTONIO DE OBLIGADO",
      },
      {
        CITY: "SAN ANTONIO DE PADUA",
      },
      {
        CITY: "SAN ANTONIO OESTE",
      },
      {
        CITY: "SAN BENITO",
      },
      {
        CITY: "SAN BERNARDO",
      },
      {
        CITY: "SAN CARLOS DE BOLIVAR",
      },
      {
        CITY: "SAN CAYETANO",
      },
      {
        CITY: "SAN CLEMENTE",
      },
      {
        CITY: "SAN CRISTOBAL",
      },
      {
        CITY: "SAN FERNANDO",
      },
      {
        CITY: "SAN FERNANDO DEL VALLE DE CATAMARCA",
      },
      {
        CITY: "SAN FRANCISCO",
      },
      {
        CITY: "SAN FRANCISCO DE SANTA FE",
      },
      {
        CITY: "SAN FRANCISCO SOLANO",
      },
      {
        CITY: "SAN GENARO",
      },
      {
        CITY: "SAN GREGORIO",
      },
      {
        CITY: "SAN GUILLERMO",
      },
      {
        CITY: "SAN ISIDRO",
      },
      {
        CITY: "SAN ISIDRO",
      },
      {
        CITY: "SAN ISIDRO DE LULES",
      },
      {
        CITY: "SAN JAVIER",
      },
      {
        CITY: "SAN JERONIMO NORTE",
      },
      {
        CITY: "SAN JORGE",
      },
      {
        CITY: "SAN JOSE",
      },
      {
        CITY: "SAN JOSE DE LA ESQUINA",
      },
      {
        CITY: "SAN JUAN",
      },
      {
        CITY: "SAN JUSTO",
      },
      {
        CITY: "SAN LORENZO",
      },
      {
        CITY: "SAN LUIS",
      },
      {
        CITY: "SAN MANUEL",
      },
      {
        CITY: "SAN MARTIN",
      },
      {
        CITY: "SAN MARTIN DE LAS ESCOBAS",
      },
      {
        CITY: "SAN MIGUEL",
      },
      {
        CITY: "SAN MIGUEL",
      },
      {
        CITY: "SAN MIGUEL",
      },
      {
        CITY: "SAN MIGUEL DEL MONTE",
      },
      {
        CITY: "SAN NICOLAS",
      },
      {
        CITY: "SAN PEDRO",
      },
      {
        CITY: "SAN PEDRO",
      },
      {
        CITY: "SAN RAFAEL",
      },
      {
        CITY: "SAN SALVADOR",
      },
      {
        CITY: "SAN SALVADOR",
      },
      {
        CITY: "SAN SALVADOR DE JUJUY",
      },
      {
        CITY: "SAN TELMO",
      },
      {
        CITY: "SAN VICENTE",
      },
      {
        CITY: "SANCHEZ",
      },
      {
        CITY: "SANTA CLARA DE SAGUIER",
      },
      {
        CITY: "SANTA ELENA",
      },
      {
        CITY: "SANTA FE",
      },
      {
        CITY: "SANTA LUCIA",
      },
      {
        CITY: "SANTA LUCIA",
      },
      {
        CITY: "SANTA RITA",
      },
      {
        CITY: "SANTA ROSA",
      },
      {
        CITY: "SANTA TERESITA",
      },
      {
        CITY: "SANTIAGO DEL ESTERO",
      },
      {
        CITY: "SANTO TOME",
      },
      {
        CITY: "SANTOS LUGARES",
      },
      {
        CITY: "SARANDI",
      },
      {
        CITY: "SARMIENTO",
      },
      {
        CITY: "SARMIENTO",
      },
      {
        CITY: "SEGUI",
      },
      {
        CITY: "SIERRA DE LA VENTANA",
      },
      {
        CITY: "SIERRA DE LOS PADRES",
      },
      {
        CITY: "SINSACATE",
      },
      {
        CITY: "SUIPACHA",
      },
      {
        CITY: "SUNCHALES",
      },
      {
        CITY: "TABLADA",
      },
      {
        CITY: "TACUARI",
      },
      {
        CITY: "TAFI VIEJO",
      },
      {
        CITY: "TANDIL",
      },
      {
        CITY: "TAPALQUE",
      },
      {
        CITY: "TAPIALES",
      },
      {
        CITY: "TEMPERLEY",
      },
      {
        CITY: "TEODELINA",
      },
      {
        CITY: "THAMES",
      },
      {
        CITY: "TIGRE",
      },
      {
        CITY: "TIO PUJIO",
      },
      {
        CITY: "TODD",
      },
      {
        CITY: "TORNQUIST",
      },
      {
        CITY: "TORTUGUITAS",
      },
      {
        CITY: "TOSTADO",
      },
      {
        CITY: "TOTORAS",
      },
      {
        CITY: "TRELEW",
      },
      {
        CITY: "TRENQUE LAUQUEN",
      },
      {
        CITY: "TRES ARROYOS",
      },
      {
        CITY: "TREVELIN",
      },
      {
        CITY: "TRISTAN SUAREZ",
      },
      {
        CITY: "TUNUYAN",
      },
      {
        CITY: "TUPUNGATO",
      },
      {
        CITY: "TURDERA",
      },
      {
        CITY: "UCACHA",
      },
      {
        CITY: "URIBURU",
      },
      {
        CITY: "USHUAIA",
      },
      {
        CITY: "VALLE HERMOSO",
      },
      {
        CITY: "VEDIA",
      },
      {
        CITY: "VEINTICINCO DE MAYO",
      },
      {
        CITY: "VEINTICINCO DE MAYO",
      },
      {
        CITY: "VENADO TUERTO",
      },
      {
        CITY: "VERA",
      },
      {
        CITY: "VERONICA",
      },
      {
        CITY: "VIALE",
      },
      {
        CITY: "VIAMONTE",
      },
      {
        CITY: "VICENTE LOPEZ",
      },
      {
        CITY: "VICTORIA",
      },
      {
        CITY: "VICTORIA",
      },
      {
        CITY: "VICUNA MACKENNA",
      },
      {
        CITY: "VIEDMA",
      },
      {
        CITY: "VILLA ABERASTAIN",
      },
      {
        CITY: "VILLA ADELINA",
      },
      {
        CITY: "VILLA ALLENDE",
      },
      {
        CITY: "VILLA ALSINA",
      },
      {
        CITY: "VILLA AMELIA",
      },
      {
        CITY: "VILLA ANGELA",
      },
      {
        CITY: "VILLA BALLESTER",
      },
      {
        CITY: "VILLA BOSCH",
      },
      {
        CITY: "VILLA CANAS",
      },
      {
        CITY: "VILLA CARLOS PAZ",
      },
      {
        CITY: "VILLA CONSTITUCION",
      },
      {
        CITY: "VILLA DE MARIA",
      },
      {
        CITY: "VILLA DE MAYO",
      },
      {
        CITY: "VILLA DEL PARQUE",
      },
      {
        CITY: "VILLA DOLORES",
      },
      {
        CITY: "VILLA DOMINICO",
      },
      {
        CITY: "VILLA ELISA",
      },
      {
        CITY: "VILLA GENERAL BELGRANO",
      },
      {
        CITY: "VILLA GESELL",
      },
      {
        CITY: "VILLA GIARDINO",
      },
      {
        CITY: "VILLA HUIDOBRO",
      },
      {
        CITY: "VILLA INSUPERABLE",
      },
      {
        CITY: "VILLA LA ANGOSTURA",
      },
      {
        CITY: "VILLA LAS ROSAS",
      },
      {
        CITY: "VILLA LUGANO",
      },
      {
        CITY: "VILLA LUZURIAGA",
      },
      {
        CITY: "VILLA MADERO",
      },
      {
        CITY: "VILLA MARIA GRANDE",
      },
      {
        CITY: "VILLA MEDIA AGUA",
      },
      {
        CITY: "VILLA MERCEDES",
      },
      {
        CITY: "VILLA MERCEDES",
      },
      {
        CITY: "VILLA NUEVA",
      },
      {
        CITY: "VILLA NUEVA",
      },
      {
        CITY: "VILLA OCAMPO",
      },
      {
        CITY: "VILLA PARANACITO",
      },
      {
        CITY: "VILLA REGINA",
      },
      {
        CITY: "VILLA ROBLES",
      },
      {
        CITY: "VILLA ROSA",
      },
      {
        CITY: "VILLA TRINIDAD",
      },
      {
        CITY: "VILLA URQUIZA",
      },
      {
        CITY: "VILLAGUAY",
      },
      {
        CITY: "VUELTA DE OBLIGADO",
      },
      {
        CITY: "WARNES",
      },
      {
        CITY: "WHEELWRIGHT",
      },
      {
        CITY: "WILDE",
      },
      {
        CITY: "WINIFREDA",
      },
      {
        CITY: "YERBA BUENA",
      },
      {
        CITY: "ZENON PEREYRA",
      },
    ],
  },
  {
    COUNTRY: "ARUBA",
    CODE: "AW",
    CITIES: [
      {
        CITY: "NOORD",
      },
      {
        CITY: "ORANJESTAD",
      },
      {
        CITY: "PALM BEACH",
      },
      {
        CITY: "PARADERA",
      },
      {
        CITY: "PONTON",
      },
      {
        CITY: "SABANETA",
      },
      {
        CITY: "SAN BARBOLA",
      },
      {
        CITY: "SANTA CRUZ",
      },
      {
        CITY: "SERO BLANCO",
      },
      {
        CITY: "SINT NICOLAAS",
      },
      {
        CITY: "TANKI LENDER",
      },
    ],
  },
  {
    COUNTRY: "AUSTRIA",
    CODE: "AT",
    CITIES: [
      {
        CITY: "ABSAM",
      },
      {
        CITY: "ABSDORF",
      },
      {
        CITY: "ABTENAU",
      },
      {
        CITY: "ABTSDORF",
      },
      {
        CITY: "ACH",
      },
      {
        CITY: "ACHENKIRCH",
      },
      {
        CITY: "ACHENSEE",
      },
      {
        CITY: "ADMONT",
      },
      {
        CITY: "ADNET",
      },
      {
        CITY: "AFRITZ",
      },
      {
        CITY: "AGGSBACH",
      },
      {
        CITY: "AICHBACH",
      },
      {
        CITY: "AICHDORF",
      },
      {
        CITY: "AIFERSDORF",
      },
      {
        CITY: "AIGEN",
      },
      {
        CITY: "AIGEN IM MUEHLKREIS",
      },
      {
        CITY: "AINET",
      },
      {
        CITY: "AISTERSHEIM",
      },
      {
        CITY: "ALBERNDORF IN DER RIEDMARK",
      },
      {
        CITY: "ALBERSCHWENDE",
      },
      {
        CITY: "ALDRANS",
      },
      {
        CITY: "ALKOVEN",
      },
      {
        CITY: "ALLAND",
      },
      {
        CITY: "ALLHARTSBERG",
      },
      {
        CITY: "ALPBACH",
      },
      {
        CITY: "ALTACH",
      },
      {
        CITY: "ALTENBERG",
      },
      {
        CITY: "ALTENBERG BEI LINZ",
      },
      {
        CITY: "ALTENBURG",
      },
      {
        CITY: "ALTENDORF",
      },
      {
        CITY: "ALTENDORF",
      },
      {
        CITY: "ALTENHOF AM HAUSRUCK",
      },
      {
        CITY: "ALTENMARKT AN DER TRIESTING",
      },
      {
        CITY: "ALTENMARKT IM PONGAU",
      },
      {
        CITY: "ALTHEIM",
      },
      {
        CITY: "ALTHOFEN",
      },
      {
        CITY: "ALTLENGBACH",
      },
      {
        CITY: "ALTLICHTENWARTH",
      },
      {
        CITY: "ALTMUNSTER",
      },
      {
        CITY: "ALTWAIDHOFEN",
      },
      {
        CITY: "AM SEE",
      },
      {
        CITY: "AMALIENDORF",
      },
      {
        CITY: "AMPASS",
      },
      {
        CITY: "AMPFELWANG",
      },
      {
        CITY: "AMSTETTEN",
      },
      {
        CITY: "ANDORF",
      },
      {
        CITY: "ANGER",
      },
      {
        CITY: "ANIF",
      },
      {
        CITY: "ANNENHEIM",
      },
      {
        CITY: "ANSFELDEN",
      },
      {
        CITY: "ANTHERING",
      },
      {
        CITY: "APFELBERG",
      },
      {
        CITY: "ARBESTHAL",
      },
      {
        CITY: "ARDAGGER MARKT",
      },
      {
        CITY: "ARDNING",
      },
      {
        CITY: "ARNOLDSTEIN",
      },
      {
        CITY: "ARRIACH",
      },
      {
        CITY: "ASBERG",
      },
      {
        CITY: "ASCHACH AN DER DONAU",
      },
      {
        CITY: "ASCHAU",
      },
      {
        CITY: "ASPANG MARKT",
      },
      {
        CITY: "ASPARN",
      },
      {
        CITY: "ASPERHOFEN",
      },
      {
        CITY: "ASTEN",
      },
      {
        CITY: "ATTERSEE",
      },
      {
        CITY: "ATTNANG-PUCHHEIM",
      },
      {
        CITY: "ATZBACH",
      },
      {
        CITY: "AU",
      },
      {
        CITY: "AUERSTHAL",
      },
      {
        CITY: "AUFHAUSEN",
      },
      {
        CITY: "AUGSDORF",
      },
      {
        CITY: "AUROLZMUNSTER",
      },
      {
        CITY: "AXAMS",
      },
      {
        CITY: "BACH",
      },
      {
        CITY: "BAD BLUMAU",
      },
      {
        CITY: "BAD DEUTSCH-ALTENBURG",
      },
      {
        CITY: "BAD ERLACH",
      },
      {
        CITY: "BAD FISCHAU",
      },
      {
        CITY: "BAD GAMS",
      },
      {
        CITY: "BAD GASTEIN",
      },
      {
        CITY: "BAD GLEICHENBERG",
      },
      {
        CITY: "BAD GOISERN",
      },
      {
        CITY: "BAD HALL",
      },
      {
        CITY: "BAD HOFGASTEIN",
      },
      {
        CITY: "BAD ISCHL",
      },
      {
        CITY: "BAD KREUZEN",
      },
      {
        CITY: "BAD PIRAWARTH",
      },
      {
        CITY: "BAD RADKERSBURG",
      },
      {
        CITY: "BAD SCHALLERBACH",
      },
      {
        CITY: "BAD TATZMANNSDORF",
      },
      {
        CITY: "BAD VOSLAU",
      },
      {
        CITY: "BADEN BEI WIEN",
      },
      {
        CITY: "BAERNBACH",
      },
      {
        CITY: "BALDRAMSDORF",
      },
      {
        CITY: "BARTHOLOMABERG",
      },
      {
        CITY: "BARWIES",
      },
      {
        CITY: "BAUER",
      },
      {
        CITY: "BAUMGARTEN IM BURGENLAND",
      },
      {
        CITY: "BAUMGARTENBERG",
      },
      {
        CITY: "BAUMKIRCHEN",
      },
      {
        CITY: "BERG",
      },
      {
        CITY: "BERGAU",
      },
      {
        CITY: "BERGHEIM",
      },
      {
        CITY: "BERNDORF",
      },
      {
        CITY: "BERNHARDSTHAL",
      },
      {
        CITY: "BERNSTEIN",
      },
      {
        CITY: "BERWANG",
      },
      {
        CITY: "BEZAU",
      },
      {
        CITY: "BIBERBACH",
      },
      {
        CITY: "BIBERWIER",
      },
      {
        CITY: "BICHLBACH",
      },
      {
        CITY: "BIEDERMANN",
      },
      {
        CITY: "BIEDERMANNSDORF",
      },
      {
        CITY: "BIRGITZ",
      },
      {
        CITY: "BISAMBERG",
      },
      {
        CITY: "BIZAU",
      },
      {
        CITY: "BLEIBERG OB VILLACH",
      },
      {
        CITY: "BLEIBURG",
      },
      {
        CITY: "BLINDENMARKT",
      },
      {
        CITY: "BLUDENZ",
      },
      {
        CITY: "BLUDESCH",
      },
      {
        CITY: "BLUMAU",
      },
      {
        CITY: "BOCKFLIESS",
      },
      {
        CITY: "BODENSDORF",
      },
      {
        CITY: "BOHEIMKIRCHEN",
      },
      {
        CITY: "BOSENDURNBACH",
      },
      {
        CITY: "BRAMBERG AM WILDKOGEL",
      },
      {
        CITY: "BRANDBERG",
      },
      {
        CITY: "BRAUNAU AM INN",
      },
      {
        CITY: "BREGENZ",
      },
      {
        CITY: "BREITENBRUNN",
      },
      {
        CITY: "BREITENFURT BEI WIEN",
      },
      {
        CITY: "BREITENWANG",
      },
      {
        CITY: "BRIXEN IM THALE",
      },
      {
        CITY: "BRUCK AM ZILLER",
      },
      {
        CITY: "BRUCK AN DER GROSSGLOCKNERSTRASSE",
      },
      {
        CITY: "BRUCK AN DER LEITHA",
      },
      {
        CITY: "BRUCK AN DER MUR",
      },
      {
        CITY: "BRUCKL",
      },
      {
        CITY: "BRUCKNEUDORF",
      },
      {
        CITY: "BRUENDL",
      },
      {
        CITY: "BRUGGEN",
      },
      {
        CITY: "BRUNN AM GEBIRGE",
      },
      {
        CITY: "BUCH",
      },
      {
        CITY: "BUCH BEI JENBACH",
      },
      {
        CITY: "BUCHKIRCHEN",
      },
      {
        CITY: "BURGKIRCHEN",
      },
      {
        CITY: "BURGSCHLEINITZ",
      },
      {
        CITY: "BURMOOS",
      },
      {
        CITY: "DALAAS",
      },
      {
        CITY: "DECHANTSKIRCHEN",
      },
      {
        CITY: "DELLACH",
      },
      {
        CITY: "DESSELBRUNN",
      },
      {
        CITY: "DEUTSCH GORITZ",
      },
      {
        CITY: "DEUTSCH-WAGRAM",
      },
      {
        CITY: "DEUTSCHFEISTRITZ",
      },
      {
        CITY: "DEUTSCHKREUTZ",
      },
      {
        CITY: "DEUTSCHLANDSBERG",
      },
      {
        CITY: "DIEPOLTSHAM",
      },
      {
        CITY: "DIETACH",
      },
      {
        CITY: "DIETERSDORF AM GNASBACH",
      },
      {
        CITY: "DOBERSBERG",
      },
      {
        CITY: "DOBERSDORF",
      },
      {
        CITY: "DOBLBACH",
      },
      {
        CITY: "DOBLDORF",
      },
      {
        CITY: "DOEBRIACH",
      },
      {
        CITY: "DONNERSKIRCHEN",
      },
      {
        CITY: "DOREN",
      },
      {
        CITY: "DORF",
      },
      {
        CITY: "DORFGASTEIN",
      },
      {
        CITY: "DORFLA",
      },
      {
        CITY: "DORNBIRN",
      },
      {
        CITY: "DRASSBURG",
      },
      {
        CITY: "DRASSMARKT",
      },
      {
        CITY: "DROSS",
      },
      {
        CITY: "DURNSTEIN",
      },
      {
        CITY: "EBBS",
      },
      {
        CITY: "EBEN IM PONGAU",
      },
      {
        CITY: "EBENAU",
      },
      {
        CITY: "EBENDORF",
      },
      {
        CITY: "EBENFURTH",
      },
      {
        CITY: "EBENSEE",
      },
      {
        CITY: "EBENTHAL",
      },
      {
        CITY: "EBERGASSING",
      },
      {
        CITY: "EBERNDORF",
      },
      {
        CITY: "EBERSCHWANG",
      },
      {
        CITY: "EBERSDORF",
      },
      {
        CITY: "EBERSTEIN",
      },
      {
        CITY: "EBREICHSDORF",
      },
      {
        CITY: "ECKARTSAU",
      },
      {
        CITY: "EDELSCHROTT",
      },
      {
        CITY: "EDELSTAL",
      },
      {
        CITY: "EDLITZ",
      },
      {
        CITY: "EDT BEI LAMBACH",
      },
      {
        CITY: "EFERDING",
      },
      {
        CITY: "EGG",
      },
      {
        CITY: "EGG AM FAAKER SEE",
      },
      {
        CITY: "EGGENBURG",
      },
      {
        CITY: "EGGENDORF",
      },
      {
        CITY: "EGGENDORF",
      },
      {
        CITY: "EGGENDORF IM THALE",
      },
      {
        CITY: "EGGERSDORF BEI GRAZ",
      },
      {
        CITY: "EHRENHAUSEN",
      },
      {
        CITY: "EHRWALD",
      },
      {
        CITY: "EIBISWALD",
      },
      {
        CITY: "EICHGRABEN",
      },
      {
        CITY: "EISBACH",
      },
      {
        CITY: "EISENBERG",
      },
      {
        CITY: "EISENERZ",
      },
      {
        CITY: "EISENSTADT",
      },
      {
        CITY: "EISENTRATTEN",
      },
      {
        CITY: "EISGARN",
      },
      {
        CITY: "ELIXHAUSEN",
      },
      {
        CITY: "ELLMAU",
      },
      {
        CITY: "ELSBETHEN",
      },
      {
        CITY: "EMBERG",
      },
      {
        CITY: "EMMERSDORF AN DER DONAU",
      },
      {
        CITY: "ENGELHARTSZELL",
      },
      {
        CITY: "ENGERSDORF",
      },
      {
        CITY: "ENGERWITZDORF",
      },
      {
        CITY: "ENNS",
      },
      {
        CITY: "ENNSDORF",
      },
      {
        CITY: "ENZENKIRCHEN",
      },
      {
        CITY: "ENZENREITH",
      },
      {
        CITY: "ENZERSDORF AN DER FISCHA",
      },
      {
        CITY: "ENZERSFELD",
      },
      {
        CITY: "ENZESFELD",
      },
      {
        CITY: "ERLAUF",
      },
      {
        CITY: "ERNST",
      },
      {
        CITY: "ERNSTBRUNN",
      },
      {
        CITY: "ERNSTHOFEN",
      },
      {
        CITY: "ERPERSDORF",
      },
      {
        CITY: "ERPFENDORF",
      },
      {
        CITY: "ESTERNBERG",
      },
      {
        CITY: "EUGENDORF",
      },
      {
        CITY: "FAAK AM SEE",
      },
      {
        CITY: "FALKENBURG",
      },
      {
        CITY: "FEHRING",
      },
      {
        CITY: "FEISTRITZ",
      },
      {
        CITY: "FEISTRITZ AN DER DRAU",
      },
      {
        CITY: "FELD AM SEE",
      },
      {
        CITY: "FELDBACH",
      },
      {
        CITY: "FELDKIRCH",
      },
      {
        CITY: "FELDKIRCHEN AN DER DONAU",
      },
      {
        CITY: "FELDKIRCHEN BEI GRAZ",
      },
      {
        CITY: "FELDKIRCHEN BEI MATTIGHOFEN",
      },
      {
        CITY: "FELDKIRCHEN IN KARNTEN",
      },
      {
        CITY: "FELIXDORF",
      },
      {
        CITY: "FERNDORF",
      },
      {
        CITY: "FERNITZ",
      },
      {
        CITY: "FEUCHTEN",
      },
      {
        CITY: "FEUERSBRUNN",
      },
      {
        CITY: "FIEBERBRUNN",
      },
      {
        CITY: "FILZMOOS",
      },
      {
        CITY: "FINKENBERG",
      },
      {
        CITY: "FINSING",
      },
      {
        CITY: "FISCHAMEND DORF",
      },
      {
        CITY: "FISCHBACH",
      },
      {
        CITY: "FISCHERNDORF",
      },
      {
        CITY: "FISS",
      },
      {
        CITY: "FLACHAU",
      },
      {
        CITY: "FLADNITZ IM RAABTAL",
      },
      {
        CITY: "FLATTACH",
      },
      {
        CITY: "FLIESS",
      },
      {
        CITY: "FLINSDORF",
      },
      {
        CITY: "FOEDERLACH",
      },
      {
        CITY: "FOHNSDORF",
      },
      {
        CITY: "FORST",
      },
      {
        CITY: "FRANKENBURG",
      },
      {
        CITY: "FRANKENMARKT",
      },
      {
        CITY: "FRATTINGSDORF",
      },
      {
        CITY: "FRAUENKIRCHEN",
      },
      {
        CITY: "FREIENSTEIN",
      },
      {
        CITY: "FREILASSING",
      },
      {
        CITY: "FREINBERG",
      },
      {
        CITY: "FREISTADT",
      },
      {
        CITY: "FRIEDBURG",
      },
      {
        CITY: "FRIESACH",
      },
      {
        CITY: "FROHNLEITEN",
      },
      {
        CITY: "FUERNITZ",
      },
      {
        CITY: "FUERSTENFELD",
      },
      {
        CITY: "FUGEN",
      },
      {
        CITY: "FULPMES",
      },
      {
        CITY: "FURTHOF",
      },
      {
        CITY: "FUSCH AN DER GLOCKNERSTRASSE",
      },
      {
        CITY: "FUSCHL AM SEE",
      },
      {
        CITY: "FUSSACH",
      },
      {
        CITY: "GAADEN",
      },
      {
        CITY: "GAAL",
      },
      {
        CITY: "GABLITZ",
      },
      {
        CITY: "GAFLENZ",
      },
      {
        CITY: "GAILITZ",
      },
      {
        CITY: "GAISBERG",
      },
      {
        CITY: "GALLBRUNN",
      },
      {
        CITY: "GALLNEUKIRCHEN",
      },
      {
        CITY: "GALLSPACH",
      },
      {
        CITY: "GALLZEIN",
      },
      {
        CITY: "GALTUR",
      },
      {
        CITY: "GAMING",
      },
      {
        CITY: "GAMPERN",
      },
      {
        CITY: "GANSBACH",
      },
      {
        CITY: "GANSERNDORF",
      },
      {
        CITY: "GARGELLEN",
      },
      {
        CITY: "GARS AM KAMP",
      },
      {
        CITY: "GARSTEN",
      },
      {
        CITY: "GASCHURN",
      },
      {
        CITY: "GASPOLTSHOFEN",
      },
      {
        CITY: "GASTHOF",
      },
      {
        CITY: "GATTENDORF",
      },
      {
        CITY: "GAWEINSTAL",
      },
      {
        CITY: "GEINBERG",
      },
      {
        CITY: "GERAS",
      },
      {
        CITY: "GERASDORF AM STEINFELDE",
      },
      {
        CITY: "GERASDORF BEI WIEN",
      },
      {
        CITY: "GERERSDORF",
      },
      {
        CITY: "GERLOS",
      },
      {
        CITY: "GETZERSDORF",
      },
      {
        CITY: "GFOHL",
      },
      {
        CITY: "GIESSHUBL",
      },
      {
        CITY: "GLAN",
      },
      {
        CITY: "GLAUBENDORF",
      },
      {
        CITY: "GLEISDORF",
      },
      {
        CITY: "GLINZENDORF",
      },
      {
        CITY: "GLOGGNITZ",
      },
      {
        CITY: "GMUND",
      },
      {
        CITY: "GMUNDEN",
      },
      {
        CITY: "GNADENWALD",
      },
      {
        CITY: "GNAS",
      },
      {
        CITY: "GOBELSBURG",
      },
      {
        CITY: "GOEDERSDORF",
      },
      {
        CITY: "GOESSELSDORF",
      },
      {
        CITY: "GOFIS",
      },
      {
        CITY: "GOING",
      },
      {
        CITY: "GOLDEGG",
      },
      {
        CITY: "GOLLERSDORF",
      },
      {
        CITY: "GOLLING AN DER SALZACH",
      },
      {
        CITY: "GOLS",
      },
      {
        CITY: "GORTIPOHL",
      },
      {
        CITY: "GOSAU",
      },
      {
        CITY: "GOSDORF",
      },
      {
        CITY: "GOSING",
      },
      {
        CITY: "GOSTLING AN DER YBBS",
      },
      {
        CITY: "GOTZENDORF",
      },
      {
        CITY: "GOTZENDORF AN DER LEITHA",
      },
      {
        CITY: "GOTZENS",
      },
      {
        CITY: "GOTZIS",
      },
      {
        CITY: "GRABELSDORF",
      },
      {
        CITY: "GRAEN",
      },
      {
        CITY: "GRAFENBACH",
      },
      {
        CITY: "GRAFENDORF",
      },
      {
        CITY: "GRAFENDORF BEI HARTBERG",
      },
      {
        CITY: "GRAFENDORF BEI STAINZ",
      },
      {
        CITY: "GRAFENSCHLAG",
      },
      {
        CITY: "GRAFENSTEIN",
      },
      {
        CITY: "GRAFENWEG",
      },
      {
        CITY: "GRAFENWORTH",
      },
      {
        CITY: "GRAMASTETTEN",
      },
      {
        CITY: "GRAMATNEUSIEDL",
      },
      {
        CITY: "GRAMBACH",
      },
      {
        CITY: "GRATKORN",
      },
      {
        CITY: "GRATWEIN",
      },
      {
        CITY: "GRAZ",
      },
      {
        CITY: "GREIN",
      },
      {
        CITY: "GRESTEN",
      },
      {
        CITY: "GRIES",
      },
      {
        CITY: "GRIES AM BRENNER",
      },
      {
        CITY: "GRIESELSTEIN",
      },
      {
        CITY: "GRIESKIRCHEN",
      },
      {
        CITY: "GRIFFEN",
      },
      {
        CITY: "GRIMMENSTEIN",
      },
      {
        CITY: "GROBMING",
      },
      {
        CITY: "GRODIG",
      },
      {
        CITY: "GROSS",
      },
      {
        CITY: "GROSS-ENZERSDORF",
      },
      {
        CITY: "GROSS-SCHWEINBARTH",
      },
      {
        CITY: "GROSS-SIEGHARTS",
      },
      {
        CITY: "GROSSARL",
      },
      {
        CITY: "GROSSEBERSDORF",
      },
      {
        CITY: "GROSSKLEIN",
      },
      {
        CITY: "GROSSPESENDORF",
      },
      {
        CITY: "GROSSRAMING",
      },
      {
        CITY: "GROSSRUSSBACH",
      },
      {
        CITY: "GROSSWEIKERSDORF",
      },
      {
        CITY: "GRUNBACH AM SCHNEEBERG",
      },
      {
        CITY: "GRUNER BAUM",
      },
      {
        CITY: "GSCHNITZ",
      },
      {
        CITY: "GUESSING",
      },
      {
        CITY: "GUGGING",
      },
      {
        CITY: "GUGLWALD",
      },
      {
        CITY: "GUMPOLDSKIRCHEN",
      },
      {
        CITY: "GUNSELSDORF",
      },
      {
        CITY: "GUNSKIRCHEN",
      },
      {
        CITY: "GUNTERSDORF",
      },
      {
        CITY: "GUNTRAMSDORF",
      },
      {
        CITY: "GURTEN",
      },
      {
        CITY: "GURTIS",
      },
      {
        CITY: "GUTAU",
      },
      {
        CITY: "GUTENSTEIN",
      },
      {
        CITY: "GUTTARING",
      },
      {
        CITY: "HAAG",
      },
      {
        CITY: "HAAG",
      },
      {
        CITY: "HAAG AM HAUSRUCK",
      },
      {
        CITY: "HADERSDORF AM KAMP",
      },
      {
        CITY: "HADERSFELD",
      },
      {
        CITY: "HADRES",
      },
      {
        CITY: "HAGENBERG IM MUHLKREIS",
      },
      {
        CITY: "HAIBACH OB DER DONAU",
      },
      {
        CITY: "HAID",
      },
      {
        CITY: "HAIDERSHOFEN",
      },
      {
        CITY: "HAIMING",
      },
      {
        CITY: "HAIMINGERBERG",
      },
      {
        CITY: "HAINBURG AN DER DONAU",
      },
      {
        CITY: "HAINFELD",
      },
      {
        CITY: "HALBTURN",
      },
      {
        CITY: "HALDENSEE",
      },
      {
        CITY: "HALL",
      },
      {
        CITY: "HALL IN TIROL",
      },
      {
        CITY: "HALLEIN",
      },
      {
        CITY: "HALLER",
      },
      {
        CITY: "HALLSTATT",
      },
      {
        CITY: "HARBACH",
      },
      {
        CITY: "HARD",
      },
      {
        CITY: "HART",
      },
      {
        CITY: "HARTBERG",
      },
      {
        CITY: "HARTKIRCHEN",
      },
      {
        CITY: "HASLAU AN DER DONAU",
      },
      {
        CITY: "HATTING",
      },
      {
        CITY: "HATZENDORF",
      },
      {
        CITY: "HAUS",
      },
      {
        CITY: "HAUSBRUNN",
      },
      {
        CITY: "HAUSLEITEN",
      },
      {
        CITY: "HAUSMANNSTATTEN",
      },
      {
        CITY: "HAUTZENDORF",
      },
      {
        CITY: "HEIDENREICHSTEIN",
      },
      {
        CITY: "HEILIGENKREUZ AM WAASEN",
      },
      {
        CITY: "HEIMSCHUH",
      },
      {
        CITY: "HEISSBERG",
      },
      {
        CITY: "HEISSINGFELDING",
      },
      {
        CITY: "HEITERWANG",
      },
      {
        CITY: "HELLMONSODT",
      },
      {
        CITY: "HENNDORF AM WALLERSEE",
      },
      {
        CITY: "HENNERSDORF",
      },
      {
        CITY: "HERMAGOR",
      },
      {
        CITY: "HERNSTEIN",
      },
      {
        CITY: "HERRNBAUMGARTEN",
      },
      {
        CITY: "HERZOGENBURG",
      },
      {
        CITY: "HERZOGSDORF",
      },
      {
        CITY: "HIMBERG",
      },
      {
        CITY: "HIMMELREICH",
      },
      {
        CITY: "HINTERBRUEHL",
      },
      {
        CITY: "HINTERBURG",
      },
      {
        CITY: "HINTERGLEMM",
      },
      {
        CITY: "HINTERSDORF",
      },
      {
        CITY: "HINTERSTODER",
      },
      {
        CITY: "HINTERTHIERSEE",
      },
      {
        CITY: "HINTERTUX",
      },
      {
        CITY: "HINTERWALDBERG",
      },
      {
        CITY: "HIPPING",
      },
      {
        CITY: "HIRM",
      },
      {
        CITY: "HIRSCHEGG",
      },
      {
        CITY: "HIRTENBERG",
      },
      {
        CITY: "HOCHBURG",
      },
      {
        CITY: "HOCHGURGL",
      },
      {
        CITY: "HOCHLEITHEN",
      },
      {
        CITY: "HOCHNEUKIRCHEN",
      },
      {
        CITY: "HOCHSOELDEN",
      },
      {
        CITY: "HOCHST",
      },
      {
        CITY: "HOEHE",
      },
      {
        CITY: "HOF AM LEITHABERGE",
      },
      {
        CITY: "HOF BEI SALZBURG",
      },
      {
        CITY: "HOFEN",
      },
      {
        CITY: "HOFKIRCHEN IM TRAUNKREIS",
      },
      {
        CITY: "HOFLEIN",
      },
      {
        CITY: "HOFSTATTEN AN DER RAAB",
      },
      {
        CITY: "HOFSTETTEN",
      },
      {
        CITY: "HOHENAU",
      },
      {
        CITY: "HOHENBERG",
      },
      {
        CITY: "HOHENBURG",
      },
      {
        CITY: "HOHENEICH",
      },
      {
        CITY: "HOHENEMS",
      },
      {
        CITY: "HOHENTAUERN",
      },
      {
        CITY: "HOHENWARTH",
      },
      {
        CITY: "HOHENZELL",
      },
      {
        CITY: "HOHNHART",
      },
      {
        CITY: "HOLLABRUNN",
      },
      {
        CITY: "HOLLENTHON",
      },
      {
        CITY: "HOLLERSBACH IM PINZGAU",
      },
      {
        CITY: "HOLZHAUSEN",
      },
      {
        CITY: "HOPFGARTEN IM BRIXENTAL",
      },
      {
        CITY: "HORBRANZ",
      },
      {
        CITY: "HORERSDORF",
      },
      {
        CITY: "HORN",
      },
      {
        CITY: "HORNSTEIN",
      },
      {
        CITY: "HORSCHING",
      },
      {
        CITY: "HUETTEN",
      },
      {
        CITY: "HUTTSCHLAG",
      },
      {
        CITY: "IGLS",
      },
      {
        CITY: "IM BRAND",
      },
      {
        CITY: "IMST",
      },
      {
        CITY: "INNERKREMS",
      },
      {
        CITY: "INNERMANZING",
      },
      {
        CITY: "INNSBRUCK",
      },
      {
        CITY: "INZERSDORF IM KREMSTAL",
      },
      {
        CITY: "INZING",
      },
      {
        CITY: "ISCHGL",
      },
      {
        CITY: "ISPER",
      },
      {
        CITY: "JAGENBACH",
      },
      {
        CITY: "JAGERBERG",
      },
      {
        CITY: "JAPONS",
      },
      {
        CITY: "JEGING",
      },
      {
        CITY: "JENBACH",
      },
      {
        CITY: "JENNERSDORF",
      },
      {
        CITY: "JERZENS",
      },
      {
        CITY: "JOCHBERG",
      },
      {
        CITY: "JOIS",
      },
      {
        CITY: "JUDENAU",
      },
      {
        CITY: "JUDENBURG",
      },
      {
        CITY: "JUDENDORF",
      },
      {
        CITY: "JUNGHOLZ",
      },
      {
        CITY: "KAINACH BEI VOITSBERG",
      },
      {
        CITY: "KAINDORF",
      },
      {
        CITY: "KALSDORF BEI GRAZ",
      },
      {
        CITY: "KALTENBRUNN",
      },
      {
        CITY: "KALTENLEUTGEBEN",
      },
      {
        CITY: "KANZELHOEHE",
      },
      {
        CITY: "KAPELLN",
      },
      {
        CITY: "KAPFENBERG",
      },
      {
        CITY: "KAPFING",
      },
      {
        CITY: "KAPPERN",
      },
      {
        CITY: "KAPPL",
      },
      {
        CITY: "KAPRUN",
      },
      {
        CITY: "KARL",
      },
      {
        CITY: "KARLSTETTEN",
      },
      {
        CITY: "KARTEIS",
      },
      {
        CITY: "KATSDORF",
      },
      {
        CITY: "KATZELSDORF",
      },
      {
        CITY: "KAUMBERG",
      },
      {
        CITY: "KEFERMARKT",
      },
      {
        CITY: "KEMATEN IN TIROL",
      },
      {
        CITY: "KENNELBACH",
      },
      {
        CITY: "KEUTSCHACH AM SEE",
      },
      {
        CITY: "KIENBERG",
      },
      {
        CITY: "KIERLING",
      },
      {
        CITY: "KILB",
      },
      {
        CITY: "KINDBERG",
      },
      {
        CITY: "KIRCHBACH IN STEIERMARK",
      },
      {
        CITY: "KIRCHBERG AM WAGRAM",
      },
      {
        CITY: "KIRCHBERG IN TIROL",
      },
      {
        CITY: "KIRCHBICHL",
      },
      {
        CITY: "KIRCHDORF AN DER KREMS",
      },
      {
        CITY: "KIRCHDORF IN TIROL",
      },
      {
        CITY: "KIRCHHAM",
      },
      {
        CITY: "KIRCHSCHLAG",
      },
      {
        CITY: "KIRCHSCHLAG BEI LINZ",
      },
      {
        CITY: "KITTSEE",
      },
      {
        CITY: "KLAGENFURT",
      },
      {
        CITY: "KLAM",
      },
      {
        CITY: "KLAUS",
      },
      {
        CITY: "KLEIN-HARRAS",
      },
      {
        CITY: "KLEINBERGL",
      },
      {
        CITY: "KLEINKIRCHHEIM",
      },
      {
        CITY: "KLEINSTUBING",
      },
      {
        CITY: "KLINGFURTH",
      },
      {
        CITY: "KLOSTERLE",
      },
      {
        CITY: "KLOSTERMARIENBERG",
      },
      {
        CITY: "KLOSTERNEUBURG",
      },
      {
        CITY: "KNAPPENBERG",
      },
      {
        CITY: "KNITTELFELD",
      },
      {
        CITY: "KOBENZ",
      },
      {
        CITY: "KOBERSDORF",
      },
      {
        CITY: "KOBLACH",
      },
      {
        CITY: "KOESTENBERG",
      },
      {
        CITY: "KOETSCHACH",
      },
      {
        CITY: "KOFLACH",
      },
      {
        CITY: "KOLLMITZBERG",
      },
      {
        CITY: "KONIGSTETTEN",
      },
      {
        CITY: "KONIGSWIESEN",
      },
      {
        CITY: "KOPFING IM INNKREIS",
      },
      {
        CITY: "KOPPL",
      },
      {
        CITY: "KORNEUBURG",
      },
      {
        CITY: "KOSSEN",
      },
      {
        CITY: "KOTTINGBRUNN",
      },
      {
        CITY: "KOTTMANNSDORF",
      },
      {
        CITY: "KRAIG",
      },
      {
        CITY: "KRAMSACH",
      },
      {
        CITY: "KREMS",
      },
      {
        CITY: "KREMSBRUECKE",
      },
      {
        CITY: "KREMSDORF",
      },
      {
        CITY: "KREMSMUNSTER",
      },
      {
        CITY: "KRENGLBACH",
      },
      {
        CITY: "KREUZ",
      },
      {
        CITY: "KRIEGLACH",
      },
      {
        CITY: "KRISPL",
      },
      {
        CITY: "KRITZENDORF",
      },
      {
        CITY: "KROATISCH MINIHOF",
      },
      {
        CITY: "KROTTENDORF BEI LIGIST",
      },
      {
        CITY: "KRUMBACH",
      },
      {
        CITY: "KRUMBACH MARKT",
      },
      {
        CITY: "KRUMEGG",
      },
      {
        CITY: "KRUMPENDORF",
      },
      {
        CITY: "KUCHL",
      },
      {
        CITY: "KUEHNSDORF",
      },
      {
        CITY: "KUEHTAI",
      },
      {
        CITY: "KUFSTEIN",
      },
      {
        CITY: "KUMBERG",
      },
      {
        CITY: "KUNDL",
      },
      {
        CITY: "LAA AN DER THAYA",
      },
      {
        CITY: "LAAB IM WALDE",
      },
      {
        CITY: "LAABEN",
      },
      {
        CITY: "LAAKIRCHEN",
      },
      {
        CITY: "LABACH",
      },
      {
        CITY: "LAMBRECHTEN",
      },
      {
        CITY: "LAMPRECHTSHAUSEN",
      },
      {
        CITY: "LANDECK",
      },
      {
        CITY: "LANDEGG",
      },
      {
        CITY: "LANDSKRON",
      },
      {
        CITY: "LANERSBACH",
      },
      {
        CITY: "LANGECK IM BURGENLAND",
      },
      {
        CITY: "LANGENLOIS",
      },
      {
        CITY: "LANGENROHR",
      },
      {
        CITY: "LANGENSTEIN",
      },
      {
        CITY: "LANGENZERSDORF",
      },
      {
        CITY: "LANNACH",
      },
      {
        CITY: "LANZENKIRCHEN",
      },
      {
        CITY: "LASSEE",
      },
      {
        CITY: "LASSNITZHOHE",
      },
      {
        CITY: "LATSCHACH",
      },
      {
        CITY: "LAUNSDORF",
      },
      {
        CITY: "LAUTERACH",
      },
      {
        CITY: "LAVAMUND",
      },
      {
        CITY: "LAVANT",
      },
      {
        CITY: "LAXENBURG",
      },
      {
        CITY: "LEBRING",
      },
      {
        CITY: "LECH",
      },
      {
        CITY: "LECHASCHAU",
      },
      {
        CITY: "LEIBNITZ STYRIA",
      },
      {
        CITY: "LEISACH",
      },
      {
        CITY: "LEITEN",
      },
      {
        CITY: "LEITRING",
      },
      {
        CITY: "LEMBACH IM MUHLKREIS",
      },
      {
        CITY: "LEND",
      },
      {
        CITY: "LENDORF",
      },
      {
        CITY: "LENZING",
      },
      {
        CITY: "LEOBEN",
      },
      {
        CITY: "LEOBENDORF",
      },
      {
        CITY: "LEOBERSDORF",
      },
      {
        CITY: "LEOGANG",
      },
      {
        CITY: "LEONDING",
      },
      {
        CITY: "LEONFELDEN",
      },
      {
        CITY: "LEOPOLDSDORF",
      },
      {
        CITY: "LERMOOS",
      },
      {
        CITY: "LEUTASCH",
      },
      {
        CITY: "LEUTSCHACH",
      },
      {
        CITY: "LICHTENAU",
      },
      {
        CITY: "LICHTENBERG",
      },
      {
        CITY: "LICHTENEGG",
      },
      {
        CITY: "LICHTENWORTH",
      },
      {
        CITY: "LIEBENFELS",
      },
      {
        CITY: "LIEBOCH",
      },
      {
        CITY: "LIENZ",
      },
      {
        CITY: "LIESFELD",
      },
      {
        CITY: "LIEZEN",
      },
      {
        CITY: "LIGIST",
      },
      {
        CITY: "LILIENFELD",
      },
      {
        CITY: "LINDENBERG",
      },
      {
        CITY: "LINZ",
      },
      {
        CITY: "LITSCHAU",
      },
      {
        CITY: "LOBENSTEIN",
      },
      {
        CITY: "LOCHAU",
      },
      {
        CITY: "LOCHEN",
      },
      {
        CITY: "LOFER",
      },
      {
        CITY: "LOHNSBURG",
      },
      {
        CITY: "LOIMERSDORF",
      },
      {
        CITY: "LOIPERSBACH",
      },
      {
        CITY: "LOOSDORF",
      },
      {
        CITY: "LORETTO",
      },
      {
        CITY: "LUDESCH",
      },
      {
        CITY: "LUFTENBERG AN DER DONAU",
      },
      {
        CITY: "LUNGOTZ",
      },
      {
        CITY: "LUSTENAU",
      },
      {
        CITY: "LUTZMANNSBURG",
      },
      {
        CITY: "MADER",
      },
      {
        CITY: "MADSEIT",
      },
      {
        CITY: "MAGLERN",
      },
      {
        CITY: "MAIERSDORF",
      },
      {
        CITY: "MAILDORF",
      },
      {
        CITY: "MAIRHOF",
      },
      {
        CITY: "MAISSAU",
      },
      {
        CITY: "MALLNITZ",
      },
      {
        CITY: "MANDLING",
      },
      {
        CITY: "MANK",
      },
      {
        CITY: "MANNERSDORF AM LEITHAGEBIRGE",
      },
      {
        CITY: "MANNERSDORF AN DER RABNITZ",
      },
      {
        CITY: "MANNSWORTH",
      },
      {
        CITY: "MARBACH",
      },
      {
        CITY: "MARCHEGG",
      },
      {
        CITY: "MARCHTRENK",
      },
      {
        CITY: "MARGARETHEN AM MOOS",
      },
      {
        CITY: "MARIA ALM AM STEINERNEN MEER",
      },
      {
        CITY: "MARIA BUCH",
      },
      {
        CITY: "MARIA ELEND",
      },
      {
        CITY: "MARIA ELLEND",
      },
      {
        CITY: "MARIA ENZERSDORF",
      },
      {
        CITY: "MARIA LANKOWITZ",
      },
      {
        CITY: "MARIA LANZENDORF",
      },
      {
        CITY: "MARIA SAAL",
      },
      {
        CITY: "MARIA SCHMOLLN",
      },
      {
        CITY: "MARIA TAFERL",
      },
      {
        CITY: "MARIA WORTH",
      },
      {
        CITY: "MARIA-ANZBACH",
      },
      {
        CITY: "MARIAZELL",
      },
      {
        CITY: "MARKGRAFNEUSIEDL",
      },
      {
        CITY: "MARKT ALLHAU",
      },
      {
        CITY: "MARKT NEUHODIS",
      },
      {
        CITY: "MARKT PIESTING",
      },
      {
        CITY: "MARKTL",
      },
      {
        CITY: "MARZ",
      },
      {
        CITY: "MATHON",
      },
      {
        CITY: "MATREI AM BRENNER",
      },
      {
        CITY: "MATREI IN OSTTIROL",
      },
      {
        CITY: "MATTERSBURG",
      },
      {
        CITY: "MATTSEE",
      },
      {
        CITY: "MAUERBACH",
      },
      {
        CITY: "MAUERKIRCHEN",
      },
      {
        CITY: "MAURACH",
      },
      {
        CITY: "MAUTERNDORF",
      },
      {
        CITY: "MAUTHAUSEN",
      },
      {
        CITY: "MAYRHOFEN",
      },
      {
        CITY: "MEGGENHOFEN",
      },
      {
        CITY: "MEHRNBACH",
      },
      {
        CITY: "MEININGEN",
      },
      {
        CITY: "MEISTEN",
      },
      {
        CITY: "MELK",
      },
      {
        CITY: "MELLACH",
      },
      {
        CITY: "METTMACH",
      },
      {
        CITY: "MICHELBACH MARKT",
      },
      {
        CITY: "MICHELDORF IN OBEROESTERREICH",
      },
      {
        CITY: "MIEMING",
      },
      {
        CITY: "MIESENBACH",
      },
      {
        CITY: "MIESENBACH",
      },
      {
        CITY: "MILLSTATT",
      },
      {
        CITY: "MISTELBACH",
      },
      {
        CITY: "MITTELBERG",
      },
      {
        CITY: "MITTERDORF IM MUERZTAL",
      },
      {
        CITY: "MITTERKIRCHEN IM MACHLAND",
      },
      {
        CITY: "MITTERNDORF AN DER FISCHA",
      },
      {
        CITY: "MITTERSILL",
      },
      {
        CITY: "MOELLBRUECKE",
      },
      {
        CITY: "MOLLERSDORF",
      },
      {
        CITY: "MOLLN",
      },
      {
        CITY: "MOLLRAM",
      },
      {
        CITY: "MONCHHOF",
      },
      {
        CITY: "MONDSEE",
      },
      {
        CITY: "MOOS",
      },
      {
        CITY: "MOOSBURG",
      },
      {
        CITY: "MOOSDORF",
      },
      {
        CITY: "MOOSKIRCHEN",
      },
      {
        CITY: "MOSERN",
      },
      {
        CITY: "MOTZ",
      },
      {
        CITY: "MUCKENDORF AN DER DONAU",
      },
      {
        CITY: "MUERZHOFEN",
      },
      {
        CITY: "MUERZSTEG",
      },
      {
        CITY: "MUERZZUSCHLAG",
      },
      {
        CITY: "MUHLBACH AM MANHARTSBERG",
      },
      {
        CITY: "MUNCHENDORF",
      },
      {
        CITY: "MUNTLIX",
      },
      {
        CITY: "MUNZBACH",
      },
      {
        CITY: "MUNZKIRCHEN",
      },
      {
        CITY: "MURAU",
      },
      {
        CITY: "MURFELD",
      },
      {
        CITY: "MUTTERS",
      },
      {
        CITY: "NAARN IM MACHLANDE",
      },
      {
        CITY: "NASSEREITH",
      },
      {
        CITY: "NATTERNBACH",
      },
      {
        CITY: "NAUDERS",
      },
      {
        CITY: "NECKENMARKT",
      },
      {
        CITY: "NEIDLING",
      },
      {
        CITY: "NENZING",
      },
      {
        CITY: "NESSELWANGLE",
      },
      {
        CITY: "NESTELBACH BEI GRAZ",
      },
      {
        CITY: "NEUDAU",
      },
      {
        CITY: "NEUDORF BEI LANDSEE",
      },
      {
        CITY: "NEUDORF BEI STAATZ",
      },
      {
        CITY: "NEUDORFL",
      },
      {
        CITY: "NEUFELD AN DER LEITHA",
      },
      {
        CITY: "NEUHOFEN",
      },
      {
        CITY: "NEUHOFEN AN DER KREMS",
      },
      {
        CITY: "NEUHOFEN AN DER YBBS",
      },
      {
        CITY: "NEUKIRCHEN",
      },
      {
        CITY: "NEUKIRCHEN AM WALDE",
      },
      {
        CITY: "NEUKIRCHEN AN DER ENKNACH",
      },
      {
        CITY: "NEUKIRCHEN AN DER VOCKLA",
      },
      {
        CITY: "NEULENGBACH",
      },
      {
        CITY: "NEUMARKT AM WALLERSEE",
      },
      {
        CITY: "NEUMARKT AN DER YBBS",
      },
      {
        CITY: "NEUMARKT IM HAUSRUCKKREIS",
      },
      {
        CITY: "NEUMARKT IM MUHLKREIS",
      },
      {
        CITY: "NEUMARKT IN STEIERMARK",
      },
      {
        CITY: "NEUNKIRCHEN",
      },
      {
        CITY: "NEUSIEDL AM SEE",
      },
      {
        CITY: "NEUSIEDL BEI GUESSING",
      },
      {
        CITY: "NEUSTIFT",
      },
      {
        CITY: "NEUTAL",
      },
      {
        CITY: "NEUZEUG",
      },
      {
        CITY: "NICKELSDORF",
      },
      {
        CITY: "NIEDERACHEN",
      },
      {
        CITY: "NIEDERALM",
      },
      {
        CITY: "NIEDERDORF",
      },
      {
        CITY: "NIEDERKREUZSTETTEN",
      },
      {
        CITY: "NIEDERLAND",
      },
      {
        CITY: "NIEDERLEIS",
      },
      {
        CITY: "NIEDERNDORF",
      },
      {
        CITY: "NIEDERNEUKIRCHEN",
      },
      {
        CITY: "NIEDERWALDKIRCHEN",
      },
      {
        CITY: "NIEDERWOLZ",
      },
      {
        CITY: "NOCHLING",
      },
      {
        CITY: "NUSSDORF",
      },
      {
        CITY: "NUSSDORF AM HAUNSBERG",
      },
      {
        CITY: "NUZIDERS",
      },
      {
        CITY: "OBDACH",
      },
      {
        CITY: "OBER-GRAFENDORF",
      },
      {
        CITY: "OBERAICH",
      },
      {
        CITY: "OBERAICHWALD",
      },
      {
        CITY: "OBERALM",
      },
      {
        CITY: "OBERAU",
      },
      {
        CITY: "OBERGRUENBURG",
      },
      {
        CITY: "OBERGURGL",
      },
      {
        CITY: "OBERHAUSEN",
      },
      {
        CITY: "OBERHOFEN IM INNTAL",
      },
      {
        CITY: "OBERKAPPEL",
      },
      {
        CITY: "OBERKIRCHBACH",
      },
      {
        CITY: "OBERKOHLSTAETTEN",
      },
      {
        CITY: "OBERNBERG",
      },
      {
        CITY: "OBERNBERG AM BRENNER",
      },
      {
        CITY: "OBERNDORF AN DER MELK",
      },
      {
        CITY: "OBERNDORF BEI SALZBURG",
      },
      {
        CITY: "OBERNEUKIRCHEN",
      },
      {
        CITY: "OBERORT",
      },
      {
        CITY: "OBERPERFUSS",
      },
      {
        CITY: "OBERPULLENDORF",
      },
      {
        CITY: "OBERROHRBACH",
      },
      {
        CITY: "OBERSCHUTZEN",
      },
      {
        CITY: "OBERSDORF",
      },
      {
        CITY: "OBERSIEBENBRUNN",
      },
      {
        CITY: "OBERTAUERN",
      },
      {
        CITY: "OBERTRUM AM SEE",
      },
      {
        CITY: "OBERVELLACH",
      },
      {
        CITY: "OBERWALTERSDORF",
      },
      {
        CITY: "OBERWART",
      },
      {
        CITY: "OBERWEIS",
      },
      {
        CITY: "OBERWOLBLING",
      },
      {
        CITY: "OBRITZBERG",
      },
      {
        CITY: "OBSTEIG",
      },
      {
        CITY: "OED",
      },
      {
        CITY: "OEHLING",
      },
      {
        CITY: "OEPPING",
      },
      {
        CITY: "OETZ",
      },
      {
        CITY: "OETZTAL-BAHNHOF",
      },
      {
        CITY: "OFFENHAUSEN",
      },
      {
        CITY: "OFTERING",
      },
      {
        CITY: "OGGAU",
      },
      {
        CITY: "OHLSDORF",
      },
      {
        CITY: "OLLERN",
      },
      {
        CITY: "OPPONITZ",
      },
      {
        CITY: "ORT IM INNKREIS",
      },
      {
        CITY: "ORTH AN DER DONAU",
      },
      {
        CITY: "OSLIP",
      },
      {
        CITY: "OSSIACH",
      },
      {
        CITY: "OSTERMIETHING",
      },
      {
        CITY: "OTTENDORF BEI GLEISDORF",
      },
      {
        CITY: "OTTENHEIM",
      },
      {
        CITY: "OTTENSHEIM",
      },
      {
        CITY: "PABNEUKIRCHEN",
      },
      {
        CITY: "PACHFURTH",
      },
      {
        CITY: "PALDAU",
      },
      {
        CITY: "PANZENDORF",
      },
      {
        CITY: "PARNDORF",
      },
      {
        CITY: "PARSCHLUG",
      },
      {
        CITY: "PASCHING",
      },
      {
        CITY: "PASSAIL",
      },
      {
        CITY: "PASSTHURN",
      },
      {
        CITY: "PATSCH",
      },
      {
        CITY: "PATTERGASSEN",
      },
      {
        CITY: "PAYERBACH",
      },
      {
        CITY: "PEGGAU",
      },
      {
        CITY: "PERCHTOLDSDORF",
      },
      {
        CITY: "PERG",
      },
      {
        CITY: "PERNEGG AN DER MUR",
      },
      {
        CITY: "PERNITZ",
      },
      {
        CITY: "PERTISAU",
      },
      {
        CITY: "PERWANG AM GRABENSEE",
      },
      {
        CITY: "PETERSDORF",
      },
      {
        CITY: "PETRONELL-CARNUNTUM",
      },
      {
        CITY: "PETTENBACH",
      },
      {
        CITY: "PETTNAU",
      },
      {
        CITY: "PETTNEU",
      },
      {
        CITY: "PETZENKIRCHEN",
      },
      {
        CITY: "PEUERBACH",
      },
      {
        CITY: "PFAFFSTATTEN",
      },
      {
        CITY: "PFONS",
      },
      {
        CITY: "PFUNDS",
      },
      {
        CITY: "PIBERBACH",
      },
      {
        CITY: "PICHL",
      },
      {
        CITY: "PICHL BEI WELS",
      },
      {
        CITY: "PICHLING BEI STAINZ",
      },
      {
        CITY: "PIESENDORF",
      },
      {
        CITY: "PILLBERG",
      },
      {
        CITY: "PILLICHSDORF",
      },
      {
        CITY: "PINGGAU",
      },
      {
        CITY: "PINKAFELD",
      },
      {
        CITY: "PINSDORF",
      },
      {
        CITY: "PIRINGSDORF",
      },
      {
        CITY: "PIRKA",
      },
      {
        CITY: "PISCHELDORF",
      },
      {
        CITY: "PISCHELSDORF AM ENGELBACH",
      },
      {
        CITY: "PISCHELSDORF IN STEIERMARK",
      },
      {
        CITY: "PITTEN",
      },
      {
        CITY: "PLANGEROSS",
      },
      {
        CITY: "PLANKENAU",
      },
      {
        CITY: "PLESCHERKEN",
      },
      {
        CITY: "POCHLARN",
      },
      {
        CITY: "POELTEN",
      },
      {
        CITY: "POERTSCHACH AM WOERTHERSEE",
      },
      {
        CITY: "POETTING",
      },
      {
        CITY: "POGGSTALL",
      },
      {
        CITY: "POLFING",
      },
      {
        CITY: "POTTELSDORF",
      },
      {
        CITY: "POTTENBRUNN",
      },
      {
        CITY: "POTTENDORF",
      },
      {
        CITY: "POTTENSTEIN",
      },
      {
        CITY: "POTTSCHING",
      },
      {
        CITY: "POTZNEUSIEDL",
      },
      {
        CITY: "POYSBRUNN",
      },
      {
        CITY: "POYSDORF",
      },
      {
        CITY: "PRABACH",
      },
      {
        CITY: "PRAM",
      },
      {
        CITY: "PREDING",
      },
      {
        CITY: "PREGARTEN",
      },
      {
        CITY: "PRESSBAUM",
      },
      {
        CITY: "PRINZERSDORF",
      },
      {
        CITY: "PUCH",
      },
      {
        CITY: "PUCH BEI HALLEIN",
      },
      {
        CITY: "PUCHBERG AM SCHNEEBERG",
      },
      {
        CITY: "PUCHENAU",
      },
      {
        CITY: "PUCKING",
      },
      {
        CITY: "PURBACH",
      },
      {
        CITY: "PURBACH AM NEUSIEDLERSEE",
      },
      {
        CITY: "PURGG",
      },
      {
        CITY: "PURGSTALL",
      },
      {
        CITY: "PURGSTALL",
      },
      {
        CITY: "PURKERSDORF",
      },
      {
        CITY: "PYHRA",
      },
      {
        CITY: "RAAB",
      },
      {
        CITY: "RAABA",
      },
      {
        CITY: "RAABS AN DER THAYA",
      },
      {
        CITY: "RADFELD",
      },
      {
        CITY: "RADSTADT",
      },
      {
        CITY: "RAMSAU IM ZILLERTAL",
      },
      {
        CITY: "RANDEGG",
      },
      {
        CITY: "RANGERSDORF",
      },
      {
        CITY: "RANKWEIL",
      },
      {
        CITY: "RANNERSDORF",
      },
      {
        CITY: "RAPPOLTENKIRCHEN",
      },
      {
        CITY: "RAUCHENWARTH",
      },
      {
        CITY: "RAURIS",
      },
      {
        CITY: "REDLHAM",
      },
      {
        CITY: "REGAU",
      },
      {
        CITY: "REGELSBRUNN",
      },
      {
        CITY: "REICHENAU AN DER RAX",
      },
      {
        CITY: "REICHENAU IM MUHLKREIS",
      },
      {
        CITY: "REICHENDORF",
      },
      {
        CITY: "REICHRAMING",
      },
      {
        CITY: "REIFNITZ",
      },
      {
        CITY: "REISENBERG",
      },
      {
        CITY: "REITDORF",
      },
      {
        CITY: "REITH",
      },
      {
        CITY: "REITH BEI SEEFELD",
      },
      {
        CITY: "REITH IM ALPBACHTAL",
      },
      {
        CITY: "REKAWINKEL",
      },
      {
        CITY: "REUTTE",
      },
      {
        CITY: "RIED IM INNKREIS",
      },
      {
        CITY: "RIED IM TRAUNKREIS",
      },
      {
        CITY: "RIED IM ZILLERTAL",
      },
      {
        CITY: "RIED IN DER RIEDMARK",
      },
      {
        CITY: "RIEDENTHAL",
      },
      {
        CITY: "RIEDING",
      },
      {
        CITY: "RIEDLINGSDORF",
      },
      {
        CITY: "RIEFENSBERG",
      },
      {
        CITY: "RIEGERSBURG",
      },
      {
        CITY: "RIEZLERN",
      },
      {
        CITY: "RINGELSDORF",
      },
      {
        CITY: "RINN",
      },
      {
        CITY: "ROHRAU",
      },
      {
        CITY: "ROHRBACH AM KULM",
      },
      {
        CITY: "ROHRBACH AM STEINFELDE",
      },
      {
        CITY: "ROHRBACH IN OBEROSTERREICH",
      },
      {
        CITY: "ROHRMOOS",
      },
      {
        CITY: "ROPPEN",
      },
      {
        CITY: "ROSEGG",
      },
      {
        CITY: "ROSENAU AM HENGSTPASS",
      },
      {
        CITY: "ROSENBACH",
      },
      {
        CITY: "ROSENTAL",
      },
      {
        CITY: "ROSENTAL",
      },
      {
        CITY: "ROTENBACH",
      },
      {
        CITY: "ROTHIS",
      },
      {
        CITY: "ROTHOLZ",
      },
      {
        CITY: "ROTTENBACH",
      },
      {
        CITY: "ROTTENMANN",
      },
      {
        CITY: "RUBRING",
      },
      {
        CITY: "RUDEN",
      },
      {
        CITY: "RUDERSDORF",
      },
      {
        CITY: "RUM",
      },
      {
        CITY: "RUTZENMOOS",
      },
      {
        CITY: "SAALBACH",
      },
      {
        CITY: "SAALFELDEN AM STEINERNEN MEER",
      },
      {
        CITY: "SACHENDORF",
      },
      {
        CITY: "SACHSENBURG",
      },
      {
        CITY: "SAINT ANTON",
      },
      {
        CITY: "SALZBURG",
      },
      {
        CITY: "SANKT AGATHA",
      },
      {
        CITY: "SANKT ANDRA AM ZICKSEE",
      },
      {
        CITY: "SANKT ANDRAE",
      },
      {
        CITY: "SANKT FLORIAN",
      },
      {
        CITY: "SANKT GALLENKIRCH",
      },
      {
        CITY: "SANKT GEORGEN AM YBBSFELDE",
      },
      {
        CITY: "SANKT GEORGEN IM ATTERGAU",
      },
      {
        CITY: "SANKT JOHANN IM PONGAU",
      },
      {
        CITY: "SANKT KANZIAN",
      },
      {
        CITY: "SANKT KATHREIN AM HAUENSTEIN",
      },
      {
        CITY: "SANKT LORENZEN",
      },
      {
        CITY: "SANKT LORENZEN IM MUERZTAL",
      },
      {
        CITY: "SANKT MARGARETHEN IM BURGENLAND",
      },
      {
        CITY: "SANKT MARIENKIRCHEN AM HAUSRUCK",
      },
      {
        CITY: "SANKT MARTIN AM TECHELSBERG",
      },
      {
        CITY: "SANKT MARTIN AM YBBSFELDE",
      },
      {
        CITY: "SANKT MICHAEL IM BURGENLAND",
      },
      {
        CITY: "SANKT MICHAEL IN OBERSTEIERMARK",
      },
      {
        CITY: "SANKT OSWALD",
      },
      {
        CITY: "SANKT PANKRAZ",
      },
      {
        CITY: "SANKT PETER",
      },
      {
        CITY: "SANKT POLTEN",
      },
      {
        CITY: "SANKT RUPRECHT AN DER RAAB",
      },
      {
        CITY: "SANKT STEFAN IM ROSENTAL",
      },
      {
        CITY: "SANKT ULRICH BEI STEYR",
      },
      {
        CITY: "SANKT VALENTIN",
      },
      {
        CITY: "SANKT VEIT AN DER GLAN",
      },
      {
        CITY: "SANKT VEIT IM PONGAU",
      },
      {
        CITY: "SANKT WOLFGANG",
      },
      {
        CITY: "SANKT WOLFGANG",
      },
      {
        CITY: "SARLEINSBACH",
      },
      {
        CITY: "SATTEINS",
      },
      {
        CITY: "SAUERBRUNN",
      },
      {
        CITY: "SAUSENSTEIN",
      },
      {
        CITY: "SCHACHENDORF",
      },
      {
        CITY: "SCHALCHEN",
      },
      {
        CITY: "SCHARDENBERG",
      },
      {
        CITY: "SCHARDING",
      },
      {
        CITY: "SCHARNSTEIN",
      },
      {
        CITY: "SCHARTEN",
      },
      {
        CITY: "SCHATTENDORF",
      },
      {
        CITY: "SCHEFFAU AM WILDEN KAISER",
      },
      {
        CITY: "SCHEIFLING",
      },
      {
        CITY: "SCHIEFLING AM SEE",
      },
      {
        CITY: "SCHLADMING",
      },
      {
        CITY: "SCHLEINBACH",
      },
      {
        CITY: "SCHLIERBACH",
      },
      {
        CITY: "SCHLINS",
      },
      {
        CITY: "SCHLITTERS",
      },
      {
        CITY: "SCHMIRN",
      },
      {
        CITY: "SCHNEEBERG",
      },
      {
        CITY: "SCHOENAU",
      },
      {
        CITY: "SCHOENBERG IM STUBAITAL",
      },
      {
        CITY: "SCHOERFLING",
      },
      {
        CITY: "SCHONBUHEL AN DER DONAU",
      },
      {
        CITY: "SCHONGRABERN",
      },
      {
        CITY: "SCHONKIRCHEN",
      },
      {
        CITY: "SCHOPPERNAU",
      },
      {
        CITY: "SCHREMS",
      },
      {
        CITY: "SCHRUNS",
      },
      {
        CITY: "SCHUTZEN AM GEBIRGE",
      },
      {
        CITY: "SCHWADORF",
      },
      {
        CITY: "SCHWAIGS",
      },
      {
        CITY: "SCHWANBERG",
      },
      {
        CITY: "SCHWANENSTADT",
      },
      {
        CITY: "SCHWARZACH",
      },
      {
        CITY: "SCHWARZACH IM PONGAU",
      },
      {
        CITY: "SCHWARZAU AM STEINFELDE",
      },
      {
        CITY: "SCHWARZAU IM GEBIRGE",
      },
      {
        CITY: "SCHWARZENAU",
      },
      {
        CITY: "SCHWARZENBERG AM BOEHMERWALD",
      },
      {
        CITY: "SCHWAZ",
      },
      {
        CITY: "SCHWECHAT",
      },
      {
        CITY: "SCHWEIGGERS",
      },
      {
        CITY: "SCHWENDAU",
      },
      {
        CITY: "SCHWERTBERG",
      },
      {
        CITY: "SCHWOICH",
      },
      {
        CITY: "SEBERSDORF",
      },
      {
        CITY: "SECKAU",
      },
      {
        CITY: "SEEBODEN",
      },
      {
        CITY: "SEEFELD IN TIROL",
      },
      {
        CITY: "SEEHAM",
      },
      {
        CITY: "SEEKIRCHEN AM WALLERSEE",
      },
      {
        CITY: "SEEWALCHEN",
      },
      {
        CITY: "SEIERSBERG",
      },
      {
        CITY: "SEITENSTETTEN MARKT",
      },
      {
        CITY: "SEMMERING",
      },
      {
        CITY: "SEMRIACH",
      },
      {
        CITY: "SERFAUS",
      },
      {
        CITY: "SERNAU",
      },
      {
        CITY: "SIBRATSGFALL",
      },
      {
        CITY: "SIEBENHIRTEN",
      },
      {
        CITY: "SIEBENHIRTEN",
      },
      {
        CITY: "SIEGENDORF IM BURGENLAND",
      },
      {
        CITY: "SIEGHARTSKIRCHEN",
      },
      {
        CITY: "SIERNDORF",
      },
      {
        CITY: "SIERNING",
      },
      {
        CITY: "SIEZENHEIM",
      },
      {
        CITY: "SILLIAN",
      },
      {
        CITY: "SILZ",
      },
      {
        CITY: "SINABELKIRCHEN",
      },
      {
        CITY: "SINNERSDORF",
      },
      {
        CITY: "SIPBACHZELL",
      },
      {
        CITY: "SIRNITZ",
      },
      {
        CITY: "SISTRANS",
      },
      {
        CITY: "SITZENBERG",
      },
      {
        CITY: "SOLDEN",
      },
      {
        CITY: "SOLL",
      },
      {
        CITY: "SOLLENAU",
      },
      {
        CITY: "SOMMEREIN",
      },
      {
        CITY: "SOOSS",
      },
      {
        CITY: "SPIELBERG BEI KNITTELFELD",
      },
      {
        CITY: "SPILLERN",
      },
      {
        CITY: "SPITAL AM PYHRN",
      },
      {
        CITY: "SPITTAL AN DER DRAU",
      },
      {
        CITY: "SPITZ",
      },
      {
        CITY: "SPRATZERN",
      },
      {
        CITY: "STAATZ",
      },
      {
        CITY: "STADELBACH",
      },
      {
        CITY: "STADL-PAURA",
      },
      {
        CITY: "STADTSCHLAINING",
      },
      {
        CITY: "STAINZ",
      },
      {
        CITY: "STAINZ BEI STRADEN",
      },
      {
        CITY: "STAMS",
      },
      {
        CITY: "STANS",
      },
      {
        CITY: "STATTEGG",
      },
      {
        CITY: "STATZENDORF",
      },
      {
        CITY: "STEG",
      },
      {
        CITY: "STEGERSBACH",
      },
      {
        CITY: "STEIN AN DER DONAU",
      },
      {
        CITY: "STEIN AN DER ENNS",
      },
      {
        CITY: "STEIN IM JAUNTAL",
      },
      {
        CITY: "STEINABRUCKL",
      },
      {
        CITY: "STEINACH AM BRENNER",
      },
      {
        CITY: "STEINAKIRCHEN AM FORST",
      },
      {
        CITY: "STEINBERG BEI LIGIST",
      },
      {
        CITY: "STEINDORF AM OSSIACHER SEE",
      },
      {
        CITY: "STEINERBERG",
      },
      {
        CITY: "STEINERKIRCHEN AN DER TRAUN",
      },
      {
        CITY: "STEINFELD",
      },
      {
        CITY: "STEINHAUS",
      },
      {
        CITY: "STEINHAUS AM SEMMERING",
      },
      {
        CITY: "STETTENHOF",
      },
      {
        CITY: "STEYR",
      },
      {
        CITY: "STEYREGG",
      },
      {
        CITY: "STIEGL",
      },
      {
        CITY: "STOCKERAU",
      },
      {
        CITY: "STOOB",
      },
      {
        CITY: "STRADEN",
      },
      {
        CITY: "STRASS",
      },
      {
        CITY: "STRASSEN",
      },
      {
        CITY: "STRASSENGEL",
      },
      {
        CITY: "STRASSHOF AN DER NORDBAHN",
      },
      {
        CITY: "STRASSWALCHEN",
      },
      {
        CITY: "STRAU",
      },
      {
        CITY: "STROBL",
      },
      {
        CITY: "STUBACH",
      },
      {
        CITY: "STUBENBERG",
      },
      {
        CITY: "STUDENZEN",
      },
      {
        CITY: "STUHLFELDEN",
      },
      {
        CITY: "STUHLSDORF",
      },
      {
        CITY: "STUMM",
      },
      {
        CITY: "STUMMERBERG",
      },
      {
        CITY: "SULZ",
      },
      {
        CITY: "SULZ IM WIENERWALD",
      },
      {
        CITY: "SULZAU",
      },
      {
        CITY: "SULZBERG",
      },
      {
        CITY: "TADTEN",
      },
      {
        CITY: "TAL",
      },
      {
        CITY: "TAMSWEG",
      },
      {
        CITY: "TANNHEIM",
      },
      {
        CITY: "TARRENZ",
      },
      {
        CITY: "TATTENDORF",
      },
      {
        CITY: "TAUFKIRCHEN AN DER PRAM",
      },
      {
        CITY: "TAUFKIRCHEN AN DER TRATTNACH",
      },
      {
        CITY: "TAUPLITZ",
      },
      {
        CITY: "TAXENBACH",
      },
      {
        CITY: "TELFES IM STUBAI",
      },
      {
        CITY: "TELFS",
      },
      {
        CITY: "TERNBERG",
      },
      {
        CITY: "TERNITZ",
      },
      {
        CITY: "TEUFENBACH",
      },
      {
        CITY: "THAL",
      },
      {
        CITY: "THALGAU",
      },
      {
        CITY: "THALHEIM BEI WELS",
      },
      {
        CITY: "THALLING",
      },
      {
        CITY: "THAUR",
      },
      {
        CITY: "THAYA",
      },
      {
        CITY: "THERESIENFELD",
      },
      {
        CITY: "THOERL",
      },
      {
        CITY: "THURINGEN",
      },
      {
        CITY: "TIEFENBACH",
      },
      {
        CITY: "TIEFGRABEN",
      },
      {
        CITY: "TIESCHEN",
      },
      {
        CITY: "TIROL",
      },
      {
        CITY: "TOBAJ",
      },
      {
        CITY: "TOESCHLING",
      },
      {
        CITY: "TOESENSSTEINACH",
      },
      {
        CITY: "TRABOCH",
      },
      {
        CITY: "TRAISEN",
      },
      {
        CITY: "TRAISKIRCHEN",
      },
      {
        CITY: "TRAISMAUER",
      },
      {
        CITY: "TRASDORF",
      },
      {
        CITY: "TRAUN",
      },
      {
        CITY: "TRAUNKIRCHEN",
      },
      {
        CITY: "TRAUNSTEIN",
      },
      {
        CITY: "TRAUSDORF AN DER WULKA",
      },
      {
        CITY: "TRAUTMANNSDORF AN DER LEITHA",
      },
      {
        CITY: "TRIBUSWINKEL",
      },
      {
        CITY: "TRIEBEN",
      },
      {
        CITY: "TRISTACH",
      },
      {
        CITY: "TROEPOLACH",
      },
      {
        CITY: "TROFAIACH",
      },
      {
        CITY: "TRUMAU",
      },
      {
        CITY: "TSCHAGGUNS",
      },
      {
        CITY: "TSCHOERAN",
      },
      {
        CITY: "TULLN",
      },
      {
        CITY: "TULLNERBACH-LAWIES",
      },
      {
        CITY: "TUMELTSHAM",
      },
      {
        CITY: "TURNITZ",
      },
      {
        CITY: "TURRACH",
      },
      {
        CITY: "TWENG",
      },
      {
        CITY: "UDERNS",
      },
      {
        CITY: "ULMERFELD",
      },
      {
        CITY: "ULRICHSBERG",
      },
      {
        CITY: "ULRICHSKIRCHEN",
      },
      {
        CITY: "UNGENACH",
      },
      {
        CITY: "UNTERBERG",
      },
      {
        CITY: "UNTERGRUB",
      },
      {
        CITY: "UNTERJOCH",
      },
      {
        CITY: "UNTERLAMM",
      },
      {
        CITY: "UNTERPARSCHENBRUNN",
      },
      {
        CITY: "UNTERPREMSTATTEN",
      },
      {
        CITY: "UNTERPULLENDORF",
      },
      {
        CITY: "UNTERPURKLA",
      },
      {
        CITY: "UNTERRADLBERG",
      },
      {
        CITY: "UNTERROHR",
      },
      {
        CITY: "UNTERROHR",
      },
      {
        CITY: "UNTERSIEBENBRUNN",
      },
      {
        CITY: "UNTERWALTERSDORF",
      },
      {
        CITY: "UNTERWEITERSDORF",
      },
      {
        CITY: "UNZMARKT",
      },
      {
        CITY: "UTTENDORF",
      },
      {
        CITY: "UTZENAICH",
      },
      {
        CITY: "VEITSCH",
      },
      {
        CITY: "VELDEN AM WOERTHERSEE",
      },
      {
        CITY: "VENT",
      },
      {
        CITY: "VIENNA",
      },
      {
        CITY: "VIGAUN",
      },
      {
        CITY: "VIKTORSBERG",
      },
      {
        CITY: "VIKTRING",
      },
      {
        CITY: "VILLACH",
      },
      {
        CITY: "VIRGEN",
      },
      {
        CITY: "VITIS",
      },
      {
        CITY: "VOCKLABRUCK",
      },
      {
        CITY: "VOCKLAMARKT",
      },
      {
        CITY: "VOELKERMARKT",
      },
      {
        CITY: "VOITSBERG",
      },
      {
        CITY: "VOLDERS",
      },
      {
        CITY: "VOLS",
      },
      {
        CITY: "VORAU",
      },
      {
        CITY: "VORCHDORF",
      },
      {
        CITY: "VORDERLANERSBACH",
      },
      {
        CITY: "VORDERNBERG",
      },
      {
        CITY: "VOSENDORF",
      },
      {
        CITY: "WAGNA",
      },
      {
        CITY: "WAGRAIN",
      },
      {
        CITY: "WAIDHOFEN AN DER THAYA",
      },
      {
        CITY: "WAIDHOFEN AN DER YBBS",
      },
      {
        CITY: "WAIDMANNSFELD",
      },
      {
        CITY: "WAIDRING",
      },
      {
        CITY: "WAIZENKIRCHEN",
      },
      {
        CITY: "WALBERSDORF",
      },
      {
        CITY: "WALCHSEE",
      },
      {
        CITY: "WALD AM ARLBERG",
      },
      {
        CITY: "WALDENSTEIN",
      },
      {
        CITY: "WALDING",
      },
      {
        CITY: "WALDKIRCHEN AM WESEN",
      },
      {
        CITY: "WALDZELL",
      },
      {
        CITY: "WALLERN AN DER TRATTNACH",
      },
      {
        CITY: "WALLERN IM BURGENLAND",
      },
      {
        CITY: "WALLSEE",
      },
      {
        CITY: "WALS",
      },
      {
        CITY: "WALTERSDORF AN DER MARCH",
      },
      {
        CITY: "WALTERSDORF IN OSTSTEIERMARK",
      },
      {
        CITY: "WAMPERSDORF",
      },
      {
        CITY: "WANG",
      },
      {
        CITY: "WANGLE",
      },
      {
        CITY: "WARTBERG",
      },
      {
        CITY: "WARTBERG AN DER KREMS",
      },
      {
        CITY: "WARTH",
      },
      {
        CITY: "WARTH",
      },
      {
        CITY: "WATTENS",
      },
      {
        CITY: "WATZELSDORF",
      },
      {
        CITY: "WATZENDORF",
      },
      {
        CITY: "WAXENBERG",
      },
      {
        CITY: "WEIBERN",
      },
      {
        CITY: "WEIDEN AM SEE",
      },
      {
        CITY: "WEIGELSDORF",
      },
      {
        CITY: "WEIKENDORF",
      },
      {
        CITY: "WEIKERSDORF AM STEINFELDE",
      },
      {
        CITY: "WEILBACH",
      },
      {
        CITY: "WEILER",
      },
      {
        CITY: "WEINBURG AM SASSBACH",
      },
      {
        CITY: "WEISSBRIACH",
      },
      {
        CITY: "WEISSENBACH AM LECH",
      },
      {
        CITY: "WEISSENKIRCHEN IN DER WACHAU",
      },
      {
        CITY: "WEISSKIRCHEN AN DER TRAUN",
      },
      {
        CITY: "WEISSKIRCHEN IN STEIERMARK",
      },
      {
        CITY: "WEITEN",
      },
      {
        CITY: "WEITERSFELD",
      },
      {
        CITY: "WEITRA",
      },
      {
        CITY: "WEIZ",
      },
      {
        CITY: "WELS",
      },
      {
        CITY: "WENG BEI ADMONT",
      },
      {
        CITY: "WENG IM INNKREIS",
      },
      {
        CITY: "WENNS",
      },
      {
        CITY: "WEPPERSDORF",
      },
      {
        CITY: "WERFEN",
      },
      {
        CITY: "WERFENWENG",
      },
      {
        CITY: "WERNDORF",
      },
      {
        CITY: "WERNSTEIN AM INN",
      },
      {
        CITY: "WESTENDORF",
      },
      {
        CITY: "WETTMANNSTATTEN",
      },
      {
        CITY: "WIENER NEUDORF",
      },
      {
        CITY: "WIENER NEUSTADT",
      },
      {
        CITY: "WIENERHERBERG",
      },
      {
        CITY: "WIES",
      },
      {
        CITY: "WIESELBURG",
      },
      {
        CITY: "WIESING",
      },
      {
        CITY: "WIESMATH",
      },
      {
        CITY: "WIETING",
      },
      {
        CITY: "WILDON",
      },
      {
        CITY: "WILFLEINSDORF",
      },
      {
        CITY: "WILHELMSBURG",
      },
      {
        CITY: "WILHERING",
      },
      {
        CITY: "WIMPASSING AN DER LEITHA",
      },
      {
        CITY: "WIMPASSING IM SCHWARZATALE",
      },
      {
        CITY: "WINDEN AM SEE",
      },
      {
        CITY: "WINDISCHGARSTEN",
      },
      {
        CITY: "WINKL",
      },
      {
        CITY: "WINZENDORF",
      },
      {
        CITY: "WOELFNITZ",
      },
      {
        CITY: "WOLFAU",
      },
      {
        CITY: "WOLFPASSING",
      },
      {
        CITY: "WOLFSBERG",
      },
      {
        CITY: "WOLFSGRABEN",
      },
      {
        CITY: "WOLFURT",
      },
      {
        CITY: "WOLKERSDORF IM WEINVIERTEL",
      },
      {
        CITY: "WOLLERSDORF",
      },
      {
        CITY: "WORDERN",
      },
      {
        CITY: "WORGL",
      },
      {
        CITY: "WULKAPRODERSDORF",
      },
      {
        CITY: "WUNDSCHUH",
      },
      {
        CITY: "WURFLACH",
      },
      {
        CITY: "WURMBRAND",
      },
      {
        CITY: "YBBS AN DER DONAU",
      },
      {
        CITY: "YBBSITZ",
      },
      {
        CITY: "ZAMS",
      },
      {
        CITY: "ZEILLERN",
      },
      {
        CITY: "ZEISELMAUER",
      },
      {
        CITY: "ZELL AM MOOS",
      },
      {
        CITY: "ZELL AM SEE",
      },
      {
        CITY: "ZELL AM ZILLER",
      },
      {
        CITY: "ZELL AN DER YBBS",
      },
      {
        CITY: "ZELLERNDORF",
      },
      {
        CITY: "ZELTWEG",
      },
      {
        CITY: "ZEMENDORF",
      },
      {
        CITY: "ZETTLING",
      },
      {
        CITY: "ZIERSDORF",
      },
      {
        CITY: "ZILLINGDORF",
      },
      {
        CITY: "ZILLINGTAL",
      },
      {
        CITY: "ZIPF",
      },
      {
        CITY: "ZIRL",
      },
      {
        CITY: "ZISTERSDORF",
      },
      {
        CITY: "ZOBING",
      },
      {
        CITY: "ZUERS",
      },
      {
        CITY: "ZURNDORF",
      },
      {
        CITY: "ZWENTENDORF",
      },
      {
        CITY: "ZWETTL STADT",
      },
      {
        CITY: "ZWOLFAXING",
      },
    ],
  },
  {
    COUNTRY: "ARMENIA",
    CODE: "AM",
    CITIES: [
      {
        CITY: "ABOVYAN",
      },
      {
        CITY: "AGARAK",
      },
      {
        CITY: "APAGA",
      },
      {
        CITY: "APARAN",
      },
      {
        CITY: "ARABKIR",
      },
      {
        CITY: "ASHTARAK",
      },
      {
        CITY: "EREBUNI FORTRESS",
      },
      {
        CITY: "HRAZDAN",
      },
      {
        CITY: "IJEVAN",
      },
      {
        CITY: "JERMUK",
      },
      {
        CITY: "KAPAN",
      },
      {
        CITY: "TSAGHKADZOR",
      },
      {
        CITY: "VANADZOR",
      },
      {
        CITY: "YEREVAN",
      },
    ],
  },
  {
    COUNTRY: "ANDORRA",
    CODE: "AD",
    CITIES: [
      {
        CITY: "ANDORRA LA VELLA",
      },
      {
        CITY: "CANILLO",
      },
      {
        CITY: "ENCAMP",
      },
      {
        CITY: "ENGORDANY",
      },
      {
        CITY: "ESCALDES-ENGORDANY",
      },
      {
        CITY: "LA MASSANA",
      },
      {
        CITY: "LLORTS",
      },
      {
        CITY: "ORDINO",
      },
      {
        CITY: "SANTA COLOMA",
      },
      {
        CITY: "SISPONY",
      },
      {
        CITY: "SOLDEU",
      },
    ],
  },
  {
    COUNTRY: "AFGHANISTAN",
    CODE: "AF",
    CITIES: [
      {
        CITY: "HERAT",
      },
      {
        CITY: "KABUL",
      },
      {
        CITY: "KANDAHAR",
      },
      {
        CITY: "MOLAH",
      },
      {
        CITY: "RANA",
      },
      {
        CITY: "SHAR",
      },
      {
        CITY: "SHARIF",
      },
      {
        CITY: "WAZIR AKBAR KHAN",
      },
    ],
  },
  {
    COUNTRY: "ALGERIA",
    CODE: "DZ",
    CITIES: [
      {
        CITY: "ALGIERS",
      },
      {
        CITY: "ANNABA",
      },
      {
        CITY: "AZAZGA",
      },
      {
        CITY: "BATNA CITY",
      },
      {
        CITY: "BLIDA",
      },
      {
        CITY: "BORDJ",
      },
      {
        CITY: "BORDJ BOU ARRERIDJ",
      },
      {
        CITY: "BOUGARA",
      },
      {
        CITY: "CHERAGA",
      },
      {
        CITY: "CHLEF",
      },
      {
        CITY: "CONSTANTINE",
      },
      {
        CITY: "DJELFA",
      },
      {
        CITY: "DRARIA",
      },
      {
        CITY: "EL TARF",
      },
      {
        CITY: "HUSSEIN DEY",
      },
      {
        CITY: "ILLIZI",
      },
      {
        CITY: "JIJEL",
      },
      {
        CITY: "KOUBA",
      },
      {
        CITY: "LAGHOUAT",
      },
      {
        CITY: "ORAN",
      },
      {
        CITY: "OUARGLA",
      },
      {
        CITY: "OUED SMAR",
      },
      {
        CITY: "RELIZANE",
      },
      {
        CITY: "ROUIBA",
      },
      {
        CITY: "SAIDA",
      },
      {
        CITY: "SOUK AHRAS",
      },
      {
        CITY: "TAMANGHASSET",
      },
      {
        CITY: "TIARET",
      },
      {
        CITY: "TISSEMSILT",
      },
      {
        CITY: "TIZI",
      },
      {
        CITY: "TIZI OUZOU",
      },
      {
        CITY: "TLEMCEN",
      },
    ],
  },
  {
    COUNTRY: "AUSTRALIA",
    CODE: "AU",
    CITIES: [
      {
        CITY: "ABBOTSFORD",
      },
      {
        CITY: "ABBOTSFORD",
      },
      {
        CITY: "ABBOTSHAM",
      },
      {
        CITY: "ABERDEEN",
      },
      {
        CITY: "ABERFOYLE",
      },
      {
        CITY: "ABERGLASSLYN",
      },
      {
        CITY: "ABERMAIN",
      },
      {
        CITY: "ACACIA RIDGE",
      },
      {
        CITY: "ADAMSTOWN",
      },
      {
        CITY: "ADELAIDE",
      },
      {
        CITY: "ADELONG",
      },
      {
        CITY: "ADVANCETOWN",
      },
      {
        CITY: "AGNES BANKS",
      },
      {
        CITY: "AGNES WATER",
      },
      {
        CITY: "AIRLIE BEACH",
      },
      {
        CITY: "AITKENVALE",
      },
      {
        CITY: "ALBANY",
      },
      {
        CITY: "ALBANY CREEK",
      },
      {
        CITY: "ALBERT PARK",
      },
      {
        CITY: "ALBERTON",
      },
      {
        CITY: "ALBERTON",
      },
      {
        CITY: "ALBION",
      },
      {
        CITY: "ALBION PARK",
      },
      {
        CITY: "ALBURY",
      },
      {
        CITY: "ALDGATE",
      },
      {
        CITY: "ALDINGA",
      },
      {
        CITY: "ALDINGA BEACH",
      },
      {
        CITY: "ALEXANDRA",
      },
      {
        CITY: "ALEXANDRA",
      },
      {
        CITY: "ALEXANDRIA",
      },
      {
        CITY: "ALFREDTON",
      },
      {
        CITY: "ALICE SPRINGS",
      },
      {
        CITY: "ALLAMBEE",
      },
      {
        CITY: "ALLANSFORD",
      },
      {
        CITY: "ALLANSON",
      },
      {
        CITY: "ALLORA",
      },
      {
        CITY: "ALMA",
      },
      {
        CITY: "ALPHINGTON",
      },
      {
        CITY: "ALPINE",
      },
      {
        CITY: "ALSTONVILLE",
      },
      {
        CITY: "ALTON DOWNS",
      },
      {
        CITY: "ALTONA",
      },
      {
        CITY: "AMAMOOR",
      },
      {
        CITY: "AMAROO",
      },
      {
        CITY: "ANGASTON",
      },
      {
        CITY: "ANGLESEA",
      },
      {
        CITY: "ANNA BAY",
      },
      {
        CITY: "ANNANDALE",
      },
      {
        CITY: "APOLLO BAY",
      },
      {
        CITY: "APPLECROSS",
      },
      {
        CITY: "APPLETHORPE",
      },
      {
        CITY: "ARARAT",
      },
      {
        CITY: "ARCADIA",
      },
      {
        CITY: "ARGENTS HILL",
      },
      {
        CITY: "ARMADALE",
      },
      {
        CITY: "ARMADALE",
      },
      {
        CITY: "ARMIDALE",
      },
      {
        CITY: "ARMSTRONG CREEK",
      },
      {
        CITY: "ARNO BAY",
      },
      {
        CITY: "ARRAWARRA",
      },
      {
        CITY: "ARTARMON",
      },
      {
        CITY: "ASCOT",
      },
      {
        CITY: "ASCOT VALE",
      },
      {
        CITY: "ASHBURTON",
      },
      {
        CITY: "ASHFIELD",
      },
      {
        CITY: "ASHGROVE",
      },
      {
        CITY: "ASHTON",
      },
      {
        CITY: "ASPENDALE",
      },
      {
        CITY: "ASPLEY",
      },
      {
        CITY: "ASQUITH",
      },
      {
        CITY: "ATHERTON",
      },
      {
        CITY: "ATTADALE",
      },
      {
        CITY: "AUBURN",
      },
      {
        CITY: "AUGUSTA",
      },
      {
        CITY: "AUSTINMER",
      },
      {
        CITY: "AUSTINS FERRY",
      },
      {
        CITY: "AUSTRALIND",
      },
      {
        CITY: "AVALON",
      },
      {
        CITY: "AVENUE",
      },
      {
        CITY: "AVOCA BEACH",
      },
      {
        CITY: "AVONDALE",
      },
      {
        CITY: "AXEDALE",
      },
      {
        CITY: "AYLMERTON",
      },
      {
        CITY: "AYR",
      },
      {
        CITY: "BABINDA",
      },
      {
        CITY: "BACCHUS MARSH",
      },
      {
        CITY: "BAGDAD",
      },
      {
        CITY: "BAHRS SCRUB",
      },
      {
        CITY: "BAIRNSDALE",
      },
      {
        CITY: "BAKER",
      },
      {
        CITY: "BALACLAVA",
      },
      {
        CITY: "BALAKLAVA",
      },
      {
        CITY: "BALD HILLS",
      },
      {
        CITY: "BALGOWLAH",
      },
      {
        CITY: "BALGOWNIE",
      },
      {
        CITY: "BALHANNAH",
      },
      {
        CITY: "BALLAN",
      },
      {
        CITY: "BALLARAT",
      },
      {
        CITY: "BALLINA",
      },
      {
        CITY: "BALMAIN",
      },
      {
        CITY: "BALMORAL",
      },
      {
        CITY: "BALNARRING",
      },
      {
        CITY: "BALWYN",
      },
      {
        CITY: "BALWYN NORTH",
      },
      {
        CITY: "BANGALOW",
      },
      {
        CITY: "BANGOR",
      },
      {
        CITY: "BANKSMEADOW",
      },
      {
        CITY: "BANKSTOWN",
      },
      {
        CITY: "BANNOCKBURN",
      },
      {
        CITY: "BARCOO",
      },
      {
        CITY: "BARELLAN",
      },
      {
        CITY: "BARGARA",
      },
      {
        CITY: "BARGO",
      },
      {
        CITY: "BARINGHUP",
      },
      {
        CITY: "BARKSTEAD",
      },
      {
        CITY: "BARNSLEY",
      },
      {
        CITY: "BARRY",
      },
      {
        CITY: "BARWON DOWNS",
      },
      {
        CITY: "BARWON HEADS",
      },
      {
        CITY: "BARWON HEADS",
      },
      {
        CITY: "BASIN VIEW",
      },
      {
        CITY: "BASS HILL",
      },
      {
        CITY: "BASSENDEAN",
      },
      {
        CITY: "BATCHELOR",
      },
      {
        CITY: "BATEAU BAY",
      },
      {
        CITY: "BATESFORD",
      },
      {
        CITY: "BATHURST",
      },
      {
        CITY: "BATLOW",
      },
      {
        CITY: "BAULKHAM HILLS",
      },
      {
        CITY: "BAXTER",
      },
      {
        CITY: "BAYLES",
      },
      {
        CITY: "BAYNTON",
      },
      {
        CITY: "BAYSWATER",
      },
      {
        CITY: "BEACHMERE",
      },
      {
        CITY: "BEACON",
      },
      {
        CITY: "BEACONSFIELD",
      },
      {
        CITY: "BEACONSFIELD UPPER",
      },
      {
        CITY: "BEAUDESERT",
      },
      {
        CITY: "BEAUMARIS",
      },
      {
        CITY: "BEAUMONT",
      },
      {
        CITY: "BEAUMONT",
      },
      {
        CITY: "BEAUTY POINT",
      },
      {
        CITY: "BEDFORD PARK",
      },
      {
        CITY: "BEDFORDALE",
      },
      {
        CITY: "BEEAC",
      },
      {
        CITY: "BEECHWOOD",
      },
      {
        CITY: "BEECHWORTH",
      },
      {
        CITY: "BEENAK",
      },
      {
        CITY: "BEENLEIGH",
      },
      {
        CITY: "BEERWAH",
      },
      {
        CITY: "BEGA",
      },
      {
        CITY: "BELAIR",
      },
      {
        CITY: "BELCONNEN",
      },
      {
        CITY: "BELGIAN GARDENS",
      },
      {
        CITY: "BELGRAVE",
      },
      {
        CITY: "BELGRAVE HEIGHTS",
      },
      {
        CITY: "BELL",
      },
      {
        CITY: "BELLAMBI",
      },
      {
        CITY: "BELLARA",
      },
      {
        CITY: "BELLBIRD",
      },
      {
        CITY: "BELLBRAE",
      },
      {
        CITY: "BELLERIVE",
      },
      {
        CITY: "BELLEVUE HILL",
      },
      {
        CITY: "BELMONT",
      },
      {
        CITY: "BELMONT",
      },
      {
        CITY: "BELMONT",
      },
      {
        CITY: "BELROSE",
      },
      {
        CITY: "BENALLA",
      },
      {
        CITY: "BENAMBRA",
      },
      {
        CITY: "BENDIGO",
      },
      {
        CITY: "BENOWA",
      },
      {
        CITY: "BENSVILLE",
      },
      {
        CITY: "BENTLEIGH",
      },
      {
        CITY: "BENTLEIGH EAST",
      },
      {
        CITY: "BENTLEY",
      },
      {
        CITY: "BENTLEY",
      },
      {
        CITY: "BERESFIELD",
      },
      {
        CITY: "BERKELEY",
      },
      {
        CITY: "BERKELEY VALE",
      },
      {
        CITY: "BEROWRA",
      },
      {
        CITY: "BERRIDALE",
      },
      {
        CITY: "BERRIMA",
      },
      {
        CITY: "BERRY",
      },
      {
        CITY: "BERWICK",
      },
      {
        CITY: "BETHANIA JUNCTION",
      },
      {
        CITY: "BEVERIDGE",
      },
      {
        CITY: "BEVERLEY",
      },
      {
        CITY: "BEVERLEY",
      },
      {
        CITY: "BEXLEY",
      },
      {
        CITY: "BICHENO",
      },
      {
        CITY: "BICTON",
      },
      {
        CITY: "BIG PATS CREEK",
      },
      {
        CITY: "BILINGA",
      },
      {
        CITY: "BILOELA",
      },
      {
        CITY: "BILPIN",
      },
      {
        CITY: "BINDA",
      },
      {
        CITY: "BINNAWAY",
      },
      {
        CITY: "BIRDWOOD",
      },
      {
        CITY: "BIRKDALE",
      },
      {
        CITY: "BIRKENHEAD",
      },
      {
        CITY: "BLACK HILL",
      },
      {
        CITY: "BLACK ROCK",
      },
      {
        CITY: "BLACKBURN",
      },
      {
        CITY: "BLACKBUTT",
      },
      {
        CITY: "BLACKHEATH",
      },
      {
        CITY: "BLACKMANS BAY",
      },
      {
        CITY: "BLACKTOWN",
      },
      {
        CITY: "BLACKWALL",
      },
      {
        CITY: "BLACKWARRY",
      },
      {
        CITY: "BLACKWATER",
      },
      {
        CITY: "BLACKWOOD",
      },
      {
        CITY: "BLACKWOOD",
      },
      {
        CITY: "BLAIR ATHOL",
      },
      {
        CITY: "BLAIR ATHOL",
      },
      {
        CITY: "BLAKEHURST",
      },
      {
        CITY: "BLAXLAND",
      },
      {
        CITY: "BLAYNEY",
      },
      {
        CITY: "BLI BLI",
      },
      {
        CITY: "BOAMBEE",
      },
      {
        CITY: "BOAT HARBOUR",
      },
      {
        CITY: "BOBIN",
      },
      {
        CITY: "BODDINGTON",
      },
      {
        CITY: "BOGANGAR",
      },
      {
        CITY: "BOHLE PLAINS",
      },
      {
        CITY: "BOLTON",
      },
      {
        CITY: "BOLWARRA",
      },
      {
        CITY: "BOMADERRY",
      },
      {
        CITY: "BOMBALA",
      },
      {
        CITY: "BONBEACH",
      },
      {
        CITY: "BONDI",
      },
      {
        CITY: "BONEO",
      },
      {
        CITY: "BONGAREE",
      },
      {
        CITY: "BONNELLS BAY",
      },
      {
        CITY: "BONNET BAY",
      },
      {
        CITY: "BONYTHON",
      },
      {
        CITY: "BOOKER BAY",
      },
      {
        CITY: "BOOL LAGOON",
      },
      {
        CITY: "BOOLARRA",
      },
      {
        CITY: "BOONAH",
      },
      {
        CITY: "BOONDALL",
      },
      {
        CITY: "BOORAL",
      },
      {
        CITY: "BORDERTOWN",
      },
      {
        CITY: "BORONIA",
      },
      {
        CITY: "BOTANY",
      },
      {
        CITY: "BOULDER",
      },
      {
        CITY: "BOURKE",
      },
      {
        CITY: "BOWEN",
      },
      {
        CITY: "BOWENFELS",
      },
      {
        CITY: "BOWRAL",
      },
      {
        CITY: "BOWRAVILLE",
      },
      {
        CITY: "BOX HILL",
      },
      {
        CITY: "BOX HILL SOUTH",
      },
      {
        CITY: "BOYUP BROOK",
      },
      {
        CITY: "BRADBURY",
      },
      {
        CITY: "BRADDON",
      },
      {
        CITY: "BRAESIDE",
      },
      {
        CITY: "BRAIDWOOD",
      },
      {
        CITY: "BRANDON",
      },
      {
        CITY: "BRANDY HILL",
      },
      {
        CITY: "BRANXTON",
      },
      {
        CITY: "BREWARRINA",
      },
      {
        CITY: "BRIAR HILL",
      },
      {
        CITY: "BRIDGETOWN",
      },
      {
        CITY: "BRIDGEWATER",
      },
      {
        CITY: "BRIDGEWATER",
      },
      {
        CITY: "BRIGHTON",
      },
      {
        CITY: "BRIGHTON",
      },
      {
        CITY: "BRIGHTON",
      },
      {
        CITY: "BRIGHTON EAST",
      },
      {
        CITY: "BRIGHTON-LE-SANDS",
      },
      {
        CITY: "BRINGELLY",
      },
      {
        CITY: "BRISBANE",
      },
      {
        CITY: "BROADBEACH",
      },
      {
        CITY: "BROADFORD",
      },
      {
        CITY: "BROADMEADOWS",
      },
      {
        CITY: "BROADWATER",
      },
      {
        CITY: "BROADWATER",
      },
      {
        CITY: "BROKE",
      },
      {
        CITY: "BROKEN HILL",
      },
      {
        CITY: "BRONTE",
      },
      {
        CITY: "BROOKDALE",
      },
      {
        CITY: "BROOKFIELD",
      },
      {
        CITY: "BROOKFIELD",
      },
      {
        CITY: "BROOKLYN",
      },
      {
        CITY: "BROOKVALE",
      },
      {
        CITY: "BROOME",
      },
      {
        CITY: "BROWN HILL",
      },
      {
        CITY: "BROWNS PLAINS",
      },
      {
        CITY: "BRUCE",
      },
      {
        CITY: "BRUNSWICK",
      },
      {
        CITY: "BUCHAN",
      },
      {
        CITY: "BUCKLAND",
      },
      {
        CITY: "BUDERIM",
      },
      {
        CITY: "BUDGEWOI",
      },
      {
        CITY: "BULAHDELAH",
      },
      {
        CITY: "BULGA",
      },
      {
        CITY: "BULIMBA",
      },
      {
        CITY: "BULLA",
      },
      {
        CITY: "BULLEEN",
      },
      {
        CITY: "BULLI",
      },
      {
        CITY: "BULLSBROOK",
      },
      {
        CITY: "BULLYARD",
      },
      {
        CITY: "BULN BULN",
      },
      {
        CITY: "BUNBURY",
      },
      {
        CITY: "BUNDABERG",
      },
      {
        CITY: "BUNDALONG",
      },
      {
        CITY: "BUNDAMBA",
      },
      {
        CITY: "BUNDANOON",
      },
      {
        CITY: "BUNDEENA",
      },
      {
        CITY: "BUNDOOK",
      },
      {
        CITY: "BUNDOORA",
      },
      {
        CITY: "BUNGENDORE",
      },
      {
        CITY: "BUNGONIA",
      },
      {
        CITY: "BUNINYONG",
      },
      {
        CITY: "BUREKUP",
      },
      {
        CITY: "BURLEIGH HEADS",
      },
      {
        CITY: "BURNETT HEADS",
      },
      {
        CITY: "BURNIE",
      },
      {
        CITY: "BURNLEY",
      },
      {
        CITY: "BURNSIDE",
      },
      {
        CITY: "BURPENGARY",
      },
      {
        CITY: "BURRADOO",
      },
      {
        CITY: "BURRANEER",
      },
      {
        CITY: "BURRUM",
      },
      {
        CITY: "BURRUM HEADS",
      },
      {
        CITY: "BURWOOD",
      },
      {
        CITY: "BURWOOD",
      },
      {
        CITY: "BUSHLAND BEACH",
      },
      {
        CITY: "BUSSELTON",
      },
      {
        CITY: "BUTCHERS RIDGE",
      },
      {
        CITY: "BUXTON",
      },
      {
        CITY: "BYFORD",
      },
      {
        CITY: "BYRON BAY",
      },
      {
        CITY: "CABOOLTURE",
      },
      {
        CITY: "CAIRNS",
      },
      {
        CITY: "CALEN",
      },
      {
        CITY: "CALINGIRI",
      },
      {
        CITY: "CALLALA BAY",
      },
      {
        CITY: "CALLINGTON",
      },
      {
        CITY: "CALOUNDRA",
      },
      {
        CITY: "CALTOWIE",
      },
      {
        CITY: "CAMBERWELL",
      },
      {
        CITY: "CAMBEWARRA",
      },
      {
        CITY: "CAMBRIDGE",
      },
      {
        CITY: "CAMDEN",
      },
      {
        CITY: "CAMDEN HAVEN",
      },
      {
        CITY: "CAMMERAY",
      },
      {
        CITY: "CAMPBELL",
      },
      {
        CITY: "CAMPBELLFIELD",
      },
      {
        CITY: "CAMPBELLTOWN",
      },
      {
        CITY: "CAMPBELLTOWN",
      },
      {
        CITY: "CAMPBELLTOWN",
      },
      {
        CITY: "CAMPERDOWN",
      },
      {
        CITY: "CAMPERDOWN",
      },
      {
        CITY: "CANADIAN",
      },
      {
        CITY: "CANBERRA",
      },
      {
        CITY: "CANNINGTON",
      },
      {
        CITY: "CANNONVALE",
      },
      {
        CITY: "CANTERBURY",
      },
      {
        CITY: "CANTERBURY",
      },
      {
        CITY: "CANUNGRA",
      },
      {
        CITY: "CAPALABA",
      },
      {
        CITY: "CARAGABAL",
      },
      {
        CITY: "CARAVONICA",
      },
      {
        CITY: "CARBROOK",
      },
      {
        CITY: "CARDIFF",
      },
      {
        CITY: "CARDIGAN",
      },
      {
        CITY: "CARDINIA",
      },
      {
        CITY: "CARDUP",
      },
      {
        CITY: "CARINGBAH",
      },
      {
        CITY: "CARLINGFORD",
      },
      {
        CITY: "CARLSRUHE",
      },
      {
        CITY: "CARLTON",
      },
      {
        CITY: "CARLTON NORTH",
      },
      {
        CITY: "CARLTON SOUTH",
      },
      {
        CITY: "CARNARVON",
      },
      {
        CITY: "CARNEGIE",
      },
      {
        CITY: "CAROLINE SPRINGS",
      },
      {
        CITY: "CARRICK",
      },
      {
        CITY: "CARRINGTON",
      },
      {
        CITY: "CARRUM",
      },
      {
        CITY: "CARRUM DOWNS",
      },
      {
        CITY: "CASINO",
      },
      {
        CITY: "CASTERTON",
      },
      {
        CITY: "CASTLE HILL",
      },
      {
        CITY: "CASTLECRAG",
      },
      {
        CITY: "CASTLEMAINE",
      },
      {
        CITY: "CAULFIELD",
      },
      {
        CITY: "CAULFIELD NORTH",
      },
      {
        CITY: "CAULFIELD SOUTH",
      },
      {
        CITY: "CAVERSHAM",
      },
      {
        CITY: "CAWARRAL",
      },
      {
        CITY: "CAWONGLA",
      },
      {
        CITY: "CEDAR GROVE",
      },
      {
        CITY: "CEDUNA",
      },
      {
        CITY: "CENTRAL COAST",
      },
      {
        CITY: "CESSNOCK",
      },
      {
        CITY: "CHARLESTOWN",
      },
      {
        CITY: "CHATSWOOD",
      },
      {
        CITY: "CHATSWORTH ISLAND",
      },
      {
        CITY: "CHELSEA",
      },
      {
        CITY: "CHELTENHAM",
      },
      {
        CITY: "CHINCHILLA",
      },
      {
        CITY: "CHIPPENDALE",
      },
      {
        CITY: "CHIPPING NORTON",
      },
      {
        CITY: "CHRISTIES",
      },
      {
        CITY: "CHULLORA",
      },
      {
        CITY: "CHURCHILL",
      },
      {
        CITY: "CITY OF PARRAMATTA",
      },
      {
        CITY: "CITY OF WEST TORRENS",
      },
      {
        CITY: "CLARE",
      },
      {
        CITY: "CLARE",
      },
      {
        CITY: "CLAREMONT",
      },
      {
        CITY: "CLAREMONT",
      },
      {
        CITY: "CLARENCE TOWN",
      },
      {
        CITY: "CLAREVILLE",
      },
      {
        CITY: "CLARINDA",
      },
      {
        CITY: "CLARKEFIELD",
      },
      {
        CITY: "CLAYTON NORTH",
      },
      {
        CITY: "CLEVELAND",
      },
      {
        CITY: "CLIFTON",
      },
      {
        CITY: "CLIFTON HILL",
      },
      {
        CITY: "CLIFTON SPRINGS",
      },
      {
        CITY: "CLONTARF",
      },
      {
        CITY: "CLONTARF",
      },
      {
        CITY: "CLOSEBURN",
      },
      {
        CITY: "CLOVELLY",
      },
      {
        CITY: "CLUNES",
      },
      {
        CITY: "CLYDE",
      },
      {
        CITY: "CLYDE",
      },
      {
        CITY: "CLYDE NORTH",
      },
      {
        CITY: "COBBITTY",
      },
      {
        CITY: "COBURG",
      },
      {
        CITY: "COCKATOO",
      },
      {
        CITY: "COCKBURN",
      },
      {
        CITY: "COFFS HARBOUR",
      },
      {
        CITY: "COLAC",
      },
      {
        CITY: "COLEAMBALLY",
      },
      {
        CITY: "COLEDALE",
      },
      {
        CITY: "COLERAINE",
      },
      {
        CITY: "COLLAROY",
      },
      {
        CITY: "COLLIE",
      },
      {
        CITY: "COLLINGWOOD",
      },
      {
        CITY: "COLLINSVALE",
      },
      {
        CITY: "COLO",
      },
      {
        CITY: "COLO VALE",
      },
      {
        CITY: "COMO",
      },
      {
        CITY: "CONCORD",
      },
      {
        CITY: "CONDAMINE",
      },
      {
        CITY: "CONDOBOLIN",
      },
      {
        CITY: "CONISTON",
      },
      {
        CITY: "CONNELLS POINT",
      },
      {
        CITY: "COOBER PEDY",
      },
      {
        CITY: "COOEE",
      },
      {
        CITY: "COOGEE",
      },
      {
        CITY: "COOGEE",
      },
      {
        CITY: "COOK",
      },
      {
        CITY: "COOKTOWN",
      },
      {
        CITY: "COOLAH",
      },
      {
        CITY: "COOLAMON",
      },
      {
        CITY: "COOLANGATTA",
      },
      {
        CITY: "COOLOOLABIN",
      },
      {
        CITY: "COOLUM",
      },
      {
        CITY: "COOLUM BEACH",
      },
      {
        CITY: "COOLUP",
      },
      {
        CITY: "COOMA",
      },
      {
        CITY: "COOMERA",
      },
      {
        CITY: "COONAWARRA",
      },
      {
        CITY: "COONDOO",
      },
      {
        CITY: "COORANBONG",
      },
      {
        CITY: "COOROY",
      },
      {
        CITY: "COORPAROO",
      },
      {
        CITY: "COOTAMUNDRA",
      },
      {
        CITY: "COPACABANA",
      },
      {
        CITY: "COPMANHURST",
      },
      {
        CITY: "CORINDA",
      },
      {
        CITY: "CORINTHIA",
      },
      {
        CITY: "CORIO",
      },
      {
        CITY: "CORLETTE",
      },
      {
        CITY: "COROMANDEL VALLEY",
      },
      {
        CITY: "CORRIMAL",
      },
      {
        CITY: "COTHAM",
      },
      {
        CITY: "COTTESLOE",
      },
      {
        CITY: "COUTTS CROSSING",
      },
      {
        CITY: "COWAN",
      },
      {
        CITY: "COWES",
      },
      {
        CITY: "COWRA",
      },
      {
        CITY: "CRAFERS",
      },
      {
        CITY: "CRAIGIEBURN",
      },
      {
        CITY: "CRANBOURNE",
      },
      {
        CITY: "CRAWFORD",
      },
      {
        CITY: "CREMORNE",
      },
      {
        CITY: "CREMORNE",
      },
      {
        CITY: "CRESCENT HEAD",
      },
      {
        CITY: "CRESWICK",
      },
      {
        CITY: "CRIB POINT",
      },
      {
        CITY: "CRINGILA",
      },
      {
        CITY: "CRONULLA",
      },
      {
        CITY: "CROOKWELL",
      },
      {
        CITY: "CROSSLEY",
      },
      {
        CITY: "CROWS NEST",
      },
      {
        CITY: "CROWS NEST",
      },
      {
        CITY: "CROYDON",
      },
      {
        CITY: "CROYDON",
      },
      {
        CITY: "CROYDON NORTH",
      },
      {
        CITY: "CRYSTAL CREEK",
      },
      {
        CITY: "CUDLEE CREEK",
      },
      {
        CITY: "CUMMINS",
      },
      {
        CITY: "CUNDLETOWN",
      },
      {
        CITY: "CURL CURL",
      },
      {
        CITY: "CURRUMBIN",
      },
      {
        CITY: "CYGNET",
      },
      {
        CITY: "DACEYVILLE",
      },
      {
        CITY: "DAISY HILL",
      },
      {
        CITY: "DAKABIN",
      },
      {
        CITY: "DALBY",
      },
      {
        CITY: "DALKEITH",
      },
      {
        CITY: "DANDENONG",
      },
      {
        CITY: "DAPTO",
      },
      {
        CITY: "DARDANUP",
      },
      {
        CITY: "DARKAN",
      },
      {
        CITY: "DARLEY",
      },
      {
        CITY: "DARLING",
      },
      {
        CITY: "DARLING POINT",
      },
      {
        CITY: "DARLINGHURST",
      },
      {
        CITY: "DARLINGTON",
      },
      {
        CITY: "DARLINGTON",
      },
      {
        CITY: "DARRA",
      },
      {
        CITY: "DARWIN",
      },
      {
        CITY: "DAVIDSON",
      },
      {
        CITY: "DAVISTOWN",
      },
      {
        CITY: "DAWSON",
      },
      {
        CITY: "DAYBORO",
      },
      {
        CITY: "DAYLESFORD",
      },
      {
        CITY: "DEAGON",
      },
      {
        CITY: "DEAKIN",
      },
      {
        CITY: "DECEPTION BAY",
      },
      {
        CITY: "DEEPDENE",
      },
      {
        CITY: "DEER PARK",
      },
      {
        CITY: "DELORAINE",
      },
      {
        CITY: "DENHAM",
      },
      {
        CITY: "DENMAN",
      },
      {
        CITY: "DEREEL",
      },
      {
        CITY: "DERRIMUT",
      },
      {
        CITY: "DERWENT",
      },
      {
        CITY: "DEVONPORT",
      },
      {
        CITY: "DIAMOND CREEK",
      },
      {
        CITY: "DIGGERS REST",
      },
      {
        CITY: "DILSTON",
      },
      {
        CITY: "DIMBOOLA",
      },
      {
        CITY: "DIMBULAH",
      },
      {
        CITY: "DINGLEY",
      },
      {
        CITY: "DIRRANBANDI",
      },
      {
        CITY: "DODGES FERRY",
      },
      {
        CITY: "DOLANS BAY",
      },
      {
        CITY: "DON",
      },
      {
        CITY: "DONCASTER",
      },
      {
        CITY: "DONCASTER EAST",
      },
      {
        CITY: "DONNYBROOK",
      },
      {
        CITY: "DONVALE",
      },
      {
        CITY: "DOONSIDE",
      },
      {
        CITY: "DOREEN",
      },
      {
        CITY: "DORRIGO",
      },
      {
        CITY: "DOUBTFUL CREEK",
      },
      {
        CITY: "DOUGLAS",
      },
      {
        CITY: "DOUGLAS PARK",
      },
      {
        CITY: "DOVER HEIGHTS",
      },
      {
        CITY: "DRAYTON",
      },
      {
        CITY: "DROMANA",
      },
      {
        CITY: "DROUIN",
      },
      {
        CITY: "DROUIN SOUTH",
      },
      {
        CITY: "DROUIN WEST",
      },
      {
        CITY: "DRUMMOND COVE",
      },
      {
        CITY: "DRUMMOYNE",
      },
      {
        CITY: "DRYSDALE",
      },
      {
        CITY: "DUBBO",
      },
      {
        CITY: "DUMBALK",
      },
      {
        CITY: "DUMBLEYUNG",
      },
      {
        CITY: "DUNDAS",
      },
      {
        CITY: "DUNDOWRAN",
      },
      {
        CITY: "DUNGOG",
      },
      {
        CITY: "DUNKELD",
      },
      {
        CITY: "DUNMORE",
      },
      {
        CITY: "DUNOLLY",
      },
      {
        CITY: "DUNSBOROUGH",
      },
      {
        CITY: "DURAL",
      },
      {
        CITY: "EAGLE FARM",
      },
      {
        CITY: "EAGLE HEIGHTS",
      },
      {
        CITY: "EAGLEBY",
      },
      {
        CITY: "EAGLEHAWK",
      },
      {
        CITY: "EAGLEMONT",
      },
      {
        CITY: "EARLWOOD",
      },
      {
        CITY: "EAST FREMANTLE",
      },
      {
        CITY: "EAST GOSFORD",
      },
      {
        CITY: "EAST MAITLAND",
      },
      {
        CITY: "EAST MALVERN",
      },
      {
        CITY: "EASTWOOD",
      },
      {
        CITY: "EATON",
      },
      {
        CITY: "EBBW VALE",
      },
      {
        CITY: "EBENEZER",
      },
      {
        CITY: "ECHUCA",
      },
      {
        CITY: "ECHUNGA",
      },
      {
        CITY: "EDEN",
      },
      {
        CITY: "EDEN PARK",
      },
      {
        CITY: "EDGE HILL",
      },
      {
        CITY: "EDGECLIFF",
      },
      {
        CITY: "EDITH",
      },
      {
        CITY: "EDITHVALE",
      },
      {
        CITY: "EDMONTON",
      },
      {
        CITY: "EDWARDSTOWN",
      },
      {
        CITY: "EIGHT MILE PLAINS",
      },
      {
        CITY: "ELDERSLIE",
      },
      {
        CITY: "ELIZABETH",
      },
      {
        CITY: "ELLALONG",
      },
      {
        CITY: "ELLANGOWAN",
      },
      {
        CITY: "ELLENDALE",
      },
      {
        CITY: "ELLERSLIE",
      },
      {
        CITY: "ELSTERNWICK",
      },
      {
        CITY: "ELSTERNWICK",
      },
      {
        CITY: "ELTHAM",
      },
      {
        CITY: "ELTHAM NORTH",
      },
      {
        CITY: "ELWOOD",
      },
      {
        CITY: "EMERALD",
      },
      {
        CITY: "EMERALD",
      },
      {
        CITY: "EMPIRE BAY",
      },
      {
        CITY: "EMU PARK",
      },
      {
        CITY: "EMU PLAINS",
      },
      {
        CITY: "EMU VALE",
      },
      {
        CITY: "ENCOUNTER BAY",
      },
      {
        CITY: "ENDEAVOUR",
      },
      {
        CITY: "ENDEAVOUR HILLS",
      },
      {
        CITY: "ENFIELD",
      },
      {
        CITY: "ENGADINE",
      },
      {
        CITY: "ENMORE",
      },
      {
        CITY: "ENOGGERA",
      },
      {
        CITY: "EPPING",
      },
      {
        CITY: "EPPING",
      },
      {
        CITY: "ERINA",
      },
      {
        CITY: "ERSKINE",
      },
      {
        CITY: "ESCHOL",
      },
      {
        CITY: "ESK",
      },
      {
        CITY: "ESPERANCE",
      },
      {
        CITY: "ESSENDON",
      },
      {
        CITY: "ETTALONG BEACH",
      },
      {
        CITY: "EUDLO",
      },
      {
        CITY: "EUDUNDA",
      },
      {
        CITY: "EUMUNDI",
      },
      {
        CITY: "EUROA",
      },
      {
        CITY: "EVANDALE",
      },
      {
        CITY: "EVERTON",
      },
      {
        CITY: "EXETER",
      },
      {
        CITY: "EXMOUTH",
      },
      {
        CITY: "FAILFORD",
      },
      {
        CITY: "FAIRFIELD",
      },
      {
        CITY: "FAIRFIELD",
      },
      {
        CITY: "FAIRVIEW",
      },
      {
        CITY: "FAIRY MEADOW",
      },
      {
        CITY: "FASSIFERN",
      },
      {
        CITY: "FAULCONBRIDGE",
      },
      {
        CITY: "FAWKNER",
      },
      {
        CITY: "FERN TREE",
      },
      {
        CITY: "FERN TREE GULLY",
      },
      {
        CITY: "FERNDALE",
      },
      {
        CITY: "FERNTREE GULLY",
      },
      {
        CITY: "FERNVALE",
      },
      {
        CITY: "FERNY CREEK",
      },
      {
        CITY: "FIGTREE",
      },
      {
        CITY: "FINDON",
      },
      {
        CITY: "FINGAL",
      },
      {
        CITY: "FINGAL BAY",
      },
      {
        CITY: "FITZROY",
      },
      {
        CITY: "FLEMINGTON",
      },
      {
        CITY: "FLINDERS",
      },
      {
        CITY: "FLINDERS",
      },
      {
        CITY: "FLYNN",
      },
      {
        CITY: "FOOTSCRAY",
      },
      {
        CITY: "FORBES",
      },
      {
        CITY: "FOREST",
      },
      {
        CITY: "FOREST HILL",
      },
      {
        CITY: "FORESTVILLE",
      },
      {
        CITY: "FORREST",
      },
      {
        CITY: "FORREST",
      },
      {
        CITY: "FORSAYTH",
      },
      {
        CITY: "FORSTER",
      },
      {
        CITY: "FOSTER",
      },
      {
        CITY: "FRANKLIN",
      },
      {
        CITY: "FRANKSTON",
      },
      {
        CITY: "FREELING",
      },
      {
        CITY: "FREEMANS REACH",
      },
      {
        CITY: "FREMANTLE",
      },
      {
        CITY: "FRESHWATER",
      },
      {
        CITY: "FULHAM",
      },
      {
        CITY: "FULLERTON",
      },
      {
        CITY: "FYSHWICK",
      },
      {
        CITY: "GALSTON",
      },
      {
        CITY: "GARFIELD",
      },
      {
        CITY: "GATTON",
      },
      {
        CITY: "GAWLER",
      },
      {
        CITY: "GAYNDAH",
      },
      {
        CITY: "GAYTHORNE",
      },
      {
        CITY: "GEELONG",
      },
      {
        CITY: "GEELONG WEST",
      },
      {
        CITY: "GELLIBRAND",
      },
      {
        CITY: "GEORGE TOWN",
      },
      {
        CITY: "GEPPS CROSS",
      },
      {
        CITY: "GERALDTON",
      },
      {
        CITY: "GERRINGONG",
      },
      {
        CITY: "GHEERULLA",
      },
      {
        CITY: "GILBERTON",
      },
      {
        CITY: "GILMORE",
      },
      {
        CITY: "GILSTON",
      },
      {
        CITY: "GIN GIN",
      },
      {
        CITY: "GIRRAWEEN",
      },
      {
        CITY: "GISBORNE",
      },
      {
        CITY: "GLADESVILLE",
      },
      {
        CITY: "GLADSTONE",
      },
      {
        CITY: "GLASS HOUSE MOUNTAINS",
      },
      {
        CITY: "GLEBE",
      },
      {
        CITY: "GLEN HUNTLY",
      },
      {
        CITY: "GLEN HUON",
      },
      {
        CITY: "GLEN INNES",
      },
      {
        CITY: "GLEN IRIS",
      },
      {
        CITY: "GLEN OSMOND",
      },
      {
        CITY: "GLEN WAVERLEY",
      },
      {
        CITY: "GLENBROOK",
      },
      {
        CITY: "GLENBURN",
      },
      {
        CITY: "GLENELG",
      },
      {
        CITY: "GLENFIELD",
      },
      {
        CITY: "GLENGARRY",
      },
      {
        CITY: "GLENHAVEN",
      },
      {
        CITY: "GLENORCHY",
      },
      {
        CITY: "GLENORCHY",
      },
      {
        CITY: "GLENORE GROVE",
      },
      {
        CITY: "GLENORIE",
      },
      {
        CITY: "GLENREAGH",
      },
      {
        CITY: "GLENROY",
      },
      {
        CITY: "GLENVALE",
      },
      {
        CITY: "GLOSSODIA",
      },
      {
        CITY: "GLOUCESTER",
      },
      {
        CITY: "GOLCONDA",
      },
      {
        CITY: "GOLD COAST",
      },
      {
        CITY: "GOLDEN GROVE",
      },
      {
        CITY: "GOODNA",
      },
      {
        CITY: "GOODWOOD",
      },
      {
        CITY: "GOOLWA",
      },
      {
        CITY: "GOOMERI",
      },
      {
        CITY: "GOONELLABAH",
      },
      {
        CITY: "GOORNONG",
      },
      {
        CITY: "GOOSEBERRY HILL",
      },
      {
        CITY: "GORDON",
      },
      {
        CITY: "GORDON",
      },
      {
        CITY: "GORDONVALE",
      },
      {
        CITY: "GORE HILL",
      },
      {
        CITY: "GOROKAN",
      },
      {
        CITY: "GOSFORD",
      },
      {
        CITY: "GOSFORTH",
      },
      {
        CITY: "GOSNELLS",
      },
      {
        CITY: "GOULBURN",
      },
      {
        CITY: "GOULD",
      },
      {
        CITY: "GOWRIE",
      },
      {
        CITY: "GRACEMERE",
      },
      {
        CITY: "GRAFTON",
      },
      {
        CITY: "GRANDCHESTER",
      },
      {
        CITY: "GRANGE",
      },
      {
        CITY: "GRANT",
      },
      {
        CITY: "GRANTHAM",
      },
      {
        CITY: "GRANVILLE",
      },
      {
        CITY: "GRASSMERE",
      },
      {
        CITY: "GRAVESEND",
      },
      {
        CITY: "GREEN HILL",
      },
      {
        CITY: "GREEN POINT",
      },
      {
        CITY: "GREENBANK",
      },
      {
        CITY: "GREENDALE",
      },
      {
        CITY: "GREENSBOROUGH",
      },
      {
        CITY: "GREENVALE",
      },
      {
        CITY: "GREENWICH",
      },
      {
        CITY: "GREGADOO",
      },
      {
        CITY: "GRENFELL",
      },
      {
        CITY: "GRETA",
      },
      {
        CITY: "GRIFFITH",
      },
      {
        CITY: "GROVE",
      },
      {
        CITY: "GROVEDALE",
      },
      {
        CITY: "GUILDFORD",
      },
      {
        CITY: "GUILDFORD",
      },
      {
        CITY: "GUNDAROO",
      },
      {
        CITY: "GUNNEDAH",
      },
      {
        CITY: "GYMEA",
      },
      {
        CITY: "GYMEA BAY",
      },
      {
        CITY: "GYMPIE",
      },
      {
        CITY: "HABERFIELD",
      },
      {
        CITY: "HACKHAM",
      },
      {
        CITY: "HADDON",
      },
      {
        CITY: "HADSPEN",
      },
      {
        CITY: "HAGLEY",
      },
      {
        CITY: "HAHNDORF",
      },
      {
        CITY: "HALL",
      },
      {
        CITY: "HALLAM",
      },
      {
        CITY: "HALLETT",
      },
      {
        CITY: "HALLS GAP",
      },
      {
        CITY: "HAMILTON",
      },
      {
        CITY: "HAMILTON",
      },
      {
        CITY: "HAMILTON",
      },
      {
        CITY: "HAMILTON",
      },
      {
        CITY: "HAMMOND",
      },
      {
        CITY: "HAMPTON",
      },
      {
        CITY: "HAMPTON PARK",
      },
      {
        CITY: "HAPPY VALLEY",
      },
      {
        CITY: "HARRINGTON",
      },
      {
        CITY: "HARRISTOWN",
      },
      {
        CITY: "HARVEY",
      },
      {
        CITY: "HASTINGS",
      },
      {
        CITY: "HASTINGS POINT",
      },
      {
        CITY: "HAT HEAD",
      },
      {
        CITY: "HAWKER",
      },
      {
        CITY: "HAWKESBURY HEIGHTS",
      },
      {
        CITY: "HAWKSBURN",
      },
      {
        CITY: "HAWTHORN",
      },
      {
        CITY: "HAWTHORNE",
      },
      {
        CITY: "HAYMARKET",
      },
      {
        CITY: "HAZELBROOK",
      },
      {
        CITY: "HAZELWOOD",
      },
      {
        CITY: "HEALESVILLE",
      },
      {
        CITY: "HEATHCOTE",
      },
      {
        CITY: "HEATHCOTE",
      },
      {
        CITY: "HEATHCOTE JUNCTION",
      },
      {
        CITY: "HEATHERTON",
      },
      {
        CITY: "HEATHMONT",
      },
      {
        CITY: "HEDDON GRETA",
      },
      {
        CITY: "HEIDELBERG",
      },
      {
        CITY: "HELENSBURGH",
      },
      {
        CITY: "HELENSVALE",
      },
      {
        CITY: "HENDON",
      },
      {
        CITY: "HEPBURN SPRINGS",
      },
      {
        CITY: "HERVEY BAY",
      },
      {
        CITY: "HEXHAM",
      },
      {
        CITY: "HIGHBURY",
      },
      {
        CITY: "HIGHETT",
      },
      {
        CITY: "HIGHFIELDS",
      },
      {
        CITY: "HILL TOP",
      },
      {
        CITY: "HILLCREST",
      },
      {
        CITY: "HINDMARSH",
      },
      {
        CITY: "HOBART",
      },
      {
        CITY: "HODDLES CREEK",
      },
      {
        CITY: "HOLLOW TREE",
      },
      {
        CITY: "HOLROYD",
      },
      {
        CITY: "HOLSWORTHY",
      },
      {
        CITY: "HOMEBUSH",
      },
      {
        CITY: "HOMEBUSH",
      },
      {
        CITY: "HOPE ISLAND",
      },
      {
        CITY: "HOPE VALLEY",
      },
      {
        CITY: "HOPETOUN",
      },
      {
        CITY: "HOPPERS CROSSING",
      },
      {
        CITY: "HORNSBY",
      },
      {
        CITY: "HORSHAM",
      },
      {
        CITY: "HOVE",
      },
      {
        CITY: "HOWARD",
      },
      {
        CITY: "HOWARD SPRINGS",
      },
      {
        CITY: "HOWDEN",
      },
      {
        CITY: "HOWE",
      },
      {
        CITY: "HOWLONG",
      },
      {
        CITY: "HUGHESDALE",
      },
      {
        CITY: "HUNTER",
      },
      {
        CITY: "HUNTERS HILL",
      },
      {
        CITY: "HUNTLY",
      },
      {
        CITY: "HUONVILLE",
      },
      {
        CITY: "HURSTBRIDGE",
      },
      {
        CITY: "HURSTVILLE",
      },
      {
        CITY: "HURSTVILLE GROVE",
      },
      {
        CITY: "HYLAND PARK",
      },
      {
        CITY: "ILLAWARRA",
      },
      {
        CITY: "ILLAWONG",
      },
      {
        CITY: "ILUKA",
      },
      {
        CITY: "INDENTED HEAD",
      },
      {
        CITY: "INGHAM",
      },
      {
        CITY: "INGLE FARM",
      },
      {
        CITY: "INGLEBURN",
      },
      {
        CITY: "INGLEWOOD",
      },
      {
        CITY: "INNISFAIL",
      },
      {
        CITY: "INVERELL",
      },
      {
        CITY: "INVERLOCH",
      },
      {
        CITY: "INVERMAY",
      },
      {
        CITY: "IPSWICH",
      },
      {
        CITY: "ISABELLA",
      },
      {
        CITY: "IVANHOE",
      },
      {
        CITY: "IVANHOE EAST",
      },
      {
        CITY: "IVES",
      },
      {
        CITY: "JACOBS WELL",
      },
      {
        CITY: "JANNALI",
      },
      {
        CITY: "JARRAHDALE",
      },
      {
        CITY: "JENSEN",
      },
      {
        CITY: "JILLIBY",
      },
      {
        CITY: "JIMBOOMBA",
      },
      {
        CITY: "JINDABYNE",
      },
      {
        CITY: "JINDIVICK",
      },
      {
        CITY: "JUDBURY",
      },
      {
        CITY: "JUNEE",
      },
      {
        CITY: "KADINA",
      },
      {
        CITY: "KALGOORLIE",
      },
      {
        CITY: "KALLISTA",
      },
      {
        CITY: "KALORAMA",
      },
      {
        CITY: "KAMBALDA",
      },
      {
        CITY: "KANGAROO FLAT",
      },
      {
        CITY: "KANGAROO POINT",
      },
      {
        CITY: "KANWAL",
      },
      {
        CITY: "KAPUNDA",
      },
      {
        CITY: "KARALEE",
      },
      {
        CITY: "KARANA DOWNS",
      },
      {
        CITY: "KARDELLA",
      },
      {
        CITY: "KARDELLA SOUTH",
      },
      {
        CITY: "KAREELA",
      },
      {
        CITY: "KARNUP",
      },
      {
        CITY: "KAROONDA",
      },
      {
        CITY: "KARRATHA",
      },
      {
        CITY: "KATHERINE",
      },
      {
        CITY: "KATOOMBA",
      },
      {
        CITY: "KAWUNGAN",
      },
      {
        CITY: "KEILOR",
      },
      {
        CITY: "KEIRAVILLE",
      },
      {
        CITY: "KEITH",
      },
      {
        CITY: "KELLYVILLE",
      },
      {
        CITY: "KELMSCOTT",
      },
      {
        CITY: "KELSO",
      },
      {
        CITY: "KELVIN GROVE",
      },
      {
        CITY: "KEMPSEY",
      },
      {
        CITY: "KEMPTON",
      },
      {
        CITY: "KENDALL",
      },
      {
        CITY: "KENILWORTH",
      },
      {
        CITY: "KENMORE",
      },
      {
        CITY: "KENNINGTON",
      },
      {
        CITY: "KENSINGTON",
      },
      {
        CITY: "KENSINGTON",
      },
      {
        CITY: "KENSINGTON AND NORWOOD",
      },
      {
        CITY: "KENTHURST",
      },
      {
        CITY: "KENWICK",
      },
      {
        CITY: "KERANG",
      },
      {
        CITY: "KERRY",
      },
      {
        CITY: "KESWICK",
      },
      {
        CITY: "KETTERING",
      },
      {
        CITY: "KEW",
      },
      {
        CITY: "KEW",
      },
      {
        CITY: "KEW EAST",
      },
      {
        CITY: "KEYSBOROUGH",
      },
      {
        CITY: "KEYSBROOK",
      },
      {
        CITY: "KIAMA",
      },
      {
        CITY: "KILCOY",
      },
      {
        CITY: "KILCUNDA",
      },
      {
        CITY: "KILLARA",
      },
      {
        CITY: "KILLARNEY",
      },
      {
        CITY: "KILLARNEY",
      },
      {
        CITY: "KILLCARE",
      },
      {
        CITY: "KILLINGWORTH",
      },
      {
        CITY: "KILMORE",
      },
      {
        CITY: "KILSYTH",
      },
      {
        CITY: "KINCUMBER",
      },
      {
        CITY: "KINDRED",
      },
      {
        CITY: "KINGAROY",
      },
      {
        CITY: "KINGLAKE",
      },
      {
        CITY: "KINGS CROSS",
      },
      {
        CITY: "KINGS PARK",
      },
      {
        CITY: "KINGSCLIFF",
      },
      {
        CITY: "KINGSTHORPE",
      },
      {
        CITY: "KINGSTON",
      },
      {
        CITY: "KINGSTON",
      },
      {
        CITY: "KINGSTON",
      },
      {
        CITY: "KINGSTON",
      },
      {
        CITY: "KINGSWOOD",
      },
      {
        CITY: "KINROSS",
      },
      {
        CITY: "KIRRAWEE",
      },
      {
        CITY: "KIRRIBILLI",
      },
      {
        CITY: "KIRWAN",
      },
      {
        CITY: "KOAH",
      },
      {
        CITY: "KOBBLE",
      },
      {
        CITY: "KOETONG",
      },
      {
        CITY: "KOGARAH",
      },
      {
        CITY: "KOJONUP",
      },
      {
        CITY: "KOO-WEE-RUP",
      },
      {
        CITY: "KOOLEWONG",
      },
      {
        CITY: "KOORAWATHA",
      },
      {
        CITY: "KOORNALLA",
      },
      {
        CITY: "KOOTINGAL",
      },
      {
        CITY: "KOOYONG",
      },
      {
        CITY: "KOROIT",
      },
      {
        CITY: "KORUMBURRA",
      },
      {
        CITY: "KOTARA",
      },
      {
        CITY: "KUNUNURRA",
      },
      {
        CITY: "KURANDA",
      },
      {
        CITY: "KURNELL",
      },
      {
        CITY: "KURRAJONG HEIGHTS",
      },
      {
        CITY: "KURRI KURRI",
      },
      {
        CITY: "KYABRAM",
      },
      {
        CITY: "KYNETON",
      },
      {
        CITY: "KYOGLE",
      },
      {
        CITY: "LAANG",
      },
      {
        CITY: "LAGUNA",
      },
      {
        CITY: "LAIDLEY",
      },
      {
        CITY: "LAKE CATHIE",
      },
      {
        CITY: "LAKE HEIGHTS",
      },
      {
        CITY: "LAKE ILLAWARRA",
      },
      {
        CITY: "LAKE MUNMORAH",
      },
      {
        CITY: "LAKEMBA",
      },
      {
        CITY: "LAKES ENTRANCE",
      },
      {
        CITY: "LALOR",
      },
      {
        CITY: "LAMBTON",
      },
      {
        CITY: "LAMINGTON",
      },
      {
        CITY: "LANCEFIELD",
      },
      {
        CITY: "LANCELIN",
      },
      {
        CITY: "LANDSBOROUGH",
      },
      {
        CITY: "LANE COVE",
      },
      {
        CITY: "LANG LANG",
      },
      {
        CITY: "LANGHORNE CREEK",
      },
      {
        CITY: "LANGLEY",
      },
      {
        CITY: "LANGWARRIN",
      },
      {
        CITY: "LARA",
      },
      {
        CITY: "LARGS NORTH",
      },
      {
        CITY: "LATROBE",
      },
      {
        CITY: "LAUDERDALE",
      },
      {
        CITY: "LAUNCESTON",
      },
      {
        CITY: "LAVERTON",
      },
      {
        CITY: "LAVERTON",
      },
      {
        CITY: "LAVINGTON",
      },
      {
        CITY: "LAWGI",
      },
      {
        CITY: "LAWNTON",
      },
      {
        CITY: "LAWSON",
      },
      {
        CITY: "LEBRINA",
      },
      {
        CITY: "LEETON",
      },
      {
        CITY: "LEGANA",
      },
      {
        CITY: "LEICHHARDT",
      },
      {
        CITY: "LEMON TREE PASSAGE",
      },
      {
        CITY: "LENNOX HEAD",
      },
      {
        CITY: "LEONGATHA",
      },
      {
        CITY: "LEOPOLD",
      },
      {
        CITY: "LETHBRIDGE",
      },
      {
        CITY: "LEUMEAH",
      },
      {
        CITY: "LEURA",
      },
      {
        CITY: "LEWISTON",
      },
      {
        CITY: "LIDCOMBE",
      },
      {
        CITY: "LILLI PILLI",
      },
      {
        CITY: "LILYDALE",
      },
      {
        CITY: "LIMESTONE",
      },
      {
        CITY: "LINDEN",
      },
      {
        CITY: "LINDENOW",
      },
      {
        CITY: "LINDFIELD",
      },
      {
        CITY: "LINDISFARNE",
      },
      {
        CITY: "LISAROW",
      },
      {
        CITY: "LISMORE",
      },
      {
        CITY: "LITHGOW",
      },
      {
        CITY: "LITTLE BAY",
      },
      {
        CITY: "LITTLE HAMPTON",
      },
      {
        CITY: "LITTLE RIVER",
      },
      {
        CITY: "LIVERPOOL",
      },
      {
        CITY: "LOCH",
      },
      {
        CITY: "LOCKHART",
      },
      {
        CITY: "LOCKRIDGE",
      },
      {
        CITY: "LOFTUS",
      },
      {
        CITY: "LOGAN",
      },
      {
        CITY: "LOGAN CITY",
      },
      {
        CITY: "LOGAN VILLAGE",
      },
      {
        CITY: "LOGANHOLME",
      },
      {
        CITY: "LOGANLEA",
      },
      {
        CITY: "LONG JETTY",
      },
      {
        CITY: "LONG PLAINS",
      },
      {
        CITY: "LONG POINT",
      },
      {
        CITY: "LONGFORD",
      },
      {
        CITY: "LONGREACH",
      },
      {
        CITY: "LONGUEVILLE",
      },
      {
        CITY: "LONGWARRY",
      },
      {
        CITY: "LOVELY BANKS",
      },
      {
        CITY: "LOWANNA",
      },
      {
        CITY: "LOWER CHITTERING",
      },
      {
        CITY: "LOWER PLENTY",
      },
      {
        CITY: "LOWOOD",
      },
      {
        CITY: "LOXTON",
      },
      {
        CITY: "LUDDENHAM",
      },
      {
        CITY: "LUDLOW",
      },
      {
        CITY: "LUGARNO",
      },
      {
        CITY: "LULWORTH",
      },
      {
        CITY: "LUTANA",
      },
      {
        CITY: "LYNDHURST",
      },
      {
        CITY: "LYNDOCH",
      },
      {
        CITY: "LYNEHAM",
      },
      {
        CITY: "LYONS",
      },
      {
        CITY: "LYSTERFIELD",
      },
      {
        CITY: "MACARTHUR",
      },
      {
        CITY: "MACEDON",
      },
      {
        CITY: "MACKAY",
      },
      {
        CITY: "MACKSVILLE",
      },
      {
        CITY: "MACLEAN",
      },
      {
        CITY: "MACLEOD",
      },
      {
        CITY: "MACQUARIE PARK",
      },
      {
        CITY: "MADDINGTON",
      },
      {
        CITY: "MAFFRA",
      },
      {
        CITY: "MAGILL",
      },
      {
        CITY: "MAIDENWELL",
      },
      {
        CITY: "MAITLAND",
      },
      {
        CITY: "MALABAR",
      },
      {
        CITY: "MALENY",
      },
      {
        CITY: "MALLALA",
      },
      {
        CITY: "MALLANGANEE",
      },
      {
        CITY: "MALMSBURY",
      },
      {
        CITY: "MALVERN",
      },
      {
        CITY: "MANDALONG",
      },
      {
        CITY: "MANDURAH",
      },
      {
        CITY: "MANGALORE",
      },
      {
        CITY: "MANGERTON",
      },
      {
        CITY: "MANILDRA",
      },
      {
        CITY: "MANJIMUP",
      },
      {
        CITY: "MANLY",
      },
      {
        CITY: "MANLY",
      },
      {
        CITY: "MANNERING PARK",
      },
      {
        CITY: "MANNING",
      },
      {
        CITY: "MANNING POINT",
      },
      {
        CITY: "MANNUM",
      },
      {
        CITY: "MANSFIELD",
      },
      {
        CITY: "MAPLETON",
      },
      {
        CITY: "MAREEBA",
      },
      {
        CITY: "MARGARET RIVER",
      },
      {
        CITY: "MARGATE",
      },
      {
        CITY: "MARGATE",
      },
      {
        CITY: "MARIAN",
      },
      {
        CITY: "MARIBYRNONG",
      },
      {
        CITY: "MARINO",
      },
      {
        CITY: "MARION",
      },
      {
        CITY: "MARLEE",
      },
      {
        CITY: "MARMOR",
      },
      {
        CITY: "MARONG",
      },
      {
        CITY: "MAROOCHYDORE",
      },
      {
        CITY: "MAROOTA",
      },
      {
        CITY: "MAROUBRA",
      },
      {
        CITY: "MARRICKVILLE",
      },
      {
        CITY: "MARSDEN",
      },
      {
        CITY: "MARSDEN PARK",
      },
      {
        CITY: "MARSHALLTOWN",
      },
      {
        CITY: "MARTINS CREEK",
      },
      {
        CITY: "MARULAN",
      },
      {
        CITY: "MARYBOROUGH",
      },
      {
        CITY: "MARYBOROUGH",
      },
      {
        CITY: "MARYLAND",
      },
      {
        CITY: "MARYSVILLE",
      },
      {
        CITY: "MASCOT",
      },
      {
        CITY: "MASLIN BEACH",
      },
      {
        CITY: "MATCHAM",
      },
      {
        CITY: "MATRAVILLE",
      },
      {
        CITY: "MAUDSLAND",
      },
      {
        CITY: "MAYBOLE",
      },
      {
        CITY: "MAYFIELD",
      },
      {
        CITY: "MAYLANDS",
      },
      {
        CITY: "MCCRAE",
      },
      {
        CITY: "MCGRATHS HILL",
      },
      {
        CITY: "MCKINNON",
      },
      {
        CITY: "MCLACHLAN",
      },
      {
        CITY: "MCLAREN FLAT",
      },
      {
        CITY: "MCLAREN VALE",
      },
      {
        CITY: "MEADOWS",
      },
      {
        CITY: "MEDLOW BATH",
      },
      {
        CITY: "MEDOWIE",
      },
      {
        CITY: "MELBOURNE",
      },
      {
        CITY: "MELROSE",
      },
      {
        CITY: "MELTON",
      },
      {
        CITY: "MELTON SOUTH",
      },
      {
        CITY: "MELVILLE",
      },
      {
        CITY: "MENAI",
      },
      {
        CITY: "MENANGLE",
      },
      {
        CITY: "MENTONE",
      },
      {
        CITY: "MEREDITH",
      },
      {
        CITY: "MEREWETHER",
      },
      {
        CITY: "MERINGANDAN",
      },
      {
        CITY: "MERLYNSTON",
      },
      {
        CITY: "MERNDA",
      },
      {
        CITY: "MERREDIN",
      },
      {
        CITY: "MERRIWA",
      },
      {
        CITY: "MERRYLANDS",
      },
      {
        CITY: "MIDDLEMOUNT",
      },
      {
        CITY: "MIDDLETON",
      },
      {
        CITY: "MIDDLETON",
      },
      {
        CITY: "MIDWAY POINT",
      },
      {
        CITY: "MILDURA",
      },
      {
        CITY: "MILE END",
      },
      {
        CITY: "MILES",
      },
      {
        CITY: "MILTON",
      },
      {
        CITY: "MINERS REST",
      },
      {
        CITY: "MINLATON",
      },
      {
        CITY: "MINMI",
      },
      {
        CITY: "MINTO",
      },
      {
        CITY: "MIRANDA",
      },
      {
        CITY: "MIRANI",
      },
      {
        CITY: "MIRBOO",
      },
      {
        CITY: "MIRBOO NORTH",
      },
      {
        CITY: "MIRRABOOKA",
      },
      {
        CITY: "MISSION BEACH",
      },
      {
        CITY: "MITCHAM",
      },
      {
        CITY: "MITCHAM",
      },
      {
        CITY: "MITCHELL",
      },
      {
        CITY: "MITCHELTON",
      },
      {
        CITY: "MITIAMO",
      },
      {
        CITY: "MITTAGONG",
      },
      {
        CITY: "MOAMA",
      },
      {
        CITY: "MOANA",
      },
      {
        CITY: "MODBURY",
      },
      {
        CITY: "MOGGILL",
      },
      {
        CITY: "MONA VALE",
      },
      {
        CITY: "MONASH",
      },
      {
        CITY: "MONBULK",
      },
      {
        CITY: "MONT ALBERT NORTH",
      },
      {
        CITY: "MONTEAGLE",
      },
      {
        CITY: "MONTEREY",
      },
      {
        CITY: "MONTMORENCY",
      },
      {
        CITY: "MONTROSE",
      },
      {
        CITY: "MONTVILLE",
      },
      {
        CITY: "MOOLOOLABA",
      },
      {
        CITY: "MOOLOOLAH VALLEY",
      },
      {
        CITY: "MOONAH",
      },
      {
        CITY: "MOONEE PONDS",
      },
      {
        CITY: "MOONEY MOONEY",
      },
      {
        CITY: "MOORABBIN",
      },
      {
        CITY: "MOORALLA",
      },
      {
        CITY: "MOORE",
      },
      {
        CITY: "MOORE PARK",
      },
      {
        CITY: "MOOROODUC",
      },
      {
        CITY: "MOOROOLBARK",
      },
      {
        CITY: "MOOROOPNA",
      },
      {
        CITY: "MOOROOPNA",
      },
      {
        CITY: "MORANBAH",
      },
      {
        CITY: "MORANG",
      },
      {
        CITY: "MORAYFIELD",
      },
      {
        CITY: "MORDIALLOC",
      },
      {
        CITY: "MOREE",
      },
      {
        CITY: "MORIAC",
      },
      {
        CITY: "MORISSET",
      },
      {
        CITY: "MORNINGTON",
      },
      {
        CITY: "MORPHETT VALE",
      },
      {
        CITY: "MORTDALE",
      },
      {
        CITY: "MORTLAKE",
      },
      {
        CITY: "MORUYA",
      },
      {
        CITY: "MORWELL",
      },
      {
        CITY: "MOSMAN",
      },
      {
        CITY: "MOSMAN PARK",
      },
      {
        CITY: "MOSS VALE",
      },
      {
        CITY: "MOSSMAN",
      },
      {
        CITY: "MOUNT BARKER",
      },
      {
        CITY: "MOUNT COLAH",
      },
      {
        CITY: "MOUNT COTTON",
      },
      {
        CITY: "MOUNT DANDENONG",
      },
      {
        CITY: "MOUNT DIRECTION",
      },
      {
        CITY: "MOUNT DRUITT",
      },
      {
        CITY: "MOUNT EGERTON",
      },
      {
        CITY: "MOUNT ELIZA",
      },
      {
        CITY: "MOUNT EVELYN",
      },
      {
        CITY: "MOUNT GAMBIER",
      },
      {
        CITY: "MOUNT GRAVATT",
      },
      {
        CITY: "MOUNT HELEN",
      },
      {
        CITY: "MOUNT HELENA",
      },
      {
        CITY: "MOUNT ISA",
      },
      {
        CITY: "MOUNT JULIAN",
      },
      {
        CITY: "MOUNT KEIRA",
      },
      {
        CITY: "MOUNT LARCOM",
      },
      {
        CITY: "MOUNT LOFTY",
      },
      {
        CITY: "MOUNT MACEDON",
      },
      {
        CITY: "MOUNT MARTHA",
      },
      {
        CITY: "MOUNT MOLLOY",
      },
      {
        CITY: "MOUNT MORGAN",
      },
      {
        CITY: "MOUNT OUSLEY",
      },
      {
        CITY: "MOUNT PERRY",
      },
      {
        CITY: "MOUNT PLEASANT",
      },
      {
        CITY: "MOUNT SAINT THOMAS",
      },
      {
        CITY: "MOUNT WAVERLEY",
      },
      {
        CITY: "MOUNT WHITE",
      },
      {
        CITY: "MOURILYAN",
      },
      {
        CITY: "MUCHEA",
      },
      {
        CITY: "MUDGEE",
      },
      {
        CITY: "MUDGEERABA",
      },
      {
        CITY: "MUDJIMBA",
      },
      {
        CITY: "MULGOA",
      },
      {
        CITY: "MULGRAVE",
      },
      {
        CITY: "MULLEWA",
      },
      {
        CITY: "MULLUMBIMBY",
      },
      {
        CITY: "MUNDARING",
      },
      {
        CITY: "MUNDIJONG",
      },
      {
        CITY: "MURRAY BRIDGE",
      },
      {
        CITY: "MURRUMBA",
      },
      {
        CITY: "MURRUMBATEMAN",
      },
      {
        CITY: "MURRUMBEENA",
      },
      {
        CITY: "MURRURUNDI",
      },
      {
        CITY: "MURTOA",
      },
      {
        CITY: "MURWILLUMBAH",
      },
      {
        CITY: "MUSWELLBROOK",
      },
      {
        CITY: "MYLOR",
      },
      {
        CITY: "MYRRHEE",
      },
      {
        CITY: "MYRTLEFORD",
      },
      {
        CITY: "NAGAMBIE",
      },
      {
        CITY: "NAIRNE",
      },
      {
        CITY: "NAMBOUR",
      },
      {
        CITY: "NAMBUCCA HEADS",
      },
      {
        CITY: "NANA GLEN",
      },
      {
        CITY: "NANANGO",
      },
      {
        CITY: "NANNUP",
      },
      {
        CITY: "NAPOLEONS",
      },
      {
        CITY: "NAR NAR GOON",
      },
      {
        CITY: "NARANGBA",
      },
      {
        CITY: "NARARA",
      },
      {
        CITY: "NARELLAN",
      },
      {
        CITY: "NAROOMA",
      },
      {
        CITY: "NARRABEEN",
      },
      {
        CITY: "NARRABRI",
      },
      {
        CITY: "NARRANDERA",
      },
      {
        CITY: "NARRAWONG",
      },
      {
        CITY: "NARRE WARREN",
      },
      {
        CITY: "NARRE WARREN NORTH",
      },
      {
        CITY: "NARROGIN",
      },
      {
        CITY: "NARROMINE",
      },
      {
        CITY: "NEATH",
      },
      {
        CITY: "NEBO",
      },
      {
        CITY: "NEDLANDS",
      },
      {
        CITY: "NEERIM SOUTH",
      },
      {
        CITY: "NELSON BAY",
      },
      {
        CITY: "NERANG",
      },
      {
        CITY: "NERRIGUNDAH",
      },
      {
        CITY: "NERRINA",
      },
      {
        CITY: "NEUTRAL BAY",
      },
      {
        CITY: "NEW FARM",
      },
      {
        CITY: "NEW LAMBTON",
      },
      {
        CITY: "NEW TOWN",
      },
      {
        CITY: "NEWCASTLE",
      },
      {
        CITY: "NEWMARKET",
      },
      {
        CITY: "NEWNHAM",
      },
      {
        CITY: "NEWPORT",
      },
      {
        CITY: "NEWPORT",
      },
      {
        CITY: "NEWRY",
      },
      {
        CITY: "NEWSTEAD",
      },
      {
        CITY: "NEWSTEAD",
      },
      {
        CITY: "NEWSTEAD",
      },
      {
        CITY: "NEWTOWN",
      },
      {
        CITY: "NEWTOWN",
      },
      {
        CITY: "NHILL",
      },
      {
        CITY: "NINDAROO",
      },
      {
        CITY: "NINGI",
      },
      {
        CITY: "NIRRANDA",
      },
      {
        CITY: "NOARLUNGA",
      },
      {
        CITY: "NOBLE PARK",
      },
      {
        CITY: "NOOSA",
      },
      {
        CITY: "NOOSAVILLE",
      },
      {
        CITY: "NORAH",
      },
      {
        CITY: "NORDS WHARF",
      },
      {
        CITY: "NORLANE",
      },
      {
        CITY: "NORMAN PARK",
      },
      {
        CITY: "NORMANHURST",
      },
      {
        CITY: "NORMANTON",
      },
      {
        CITY: "NORMANVILLE",
      },
      {
        CITY: "NORTH BEACH",
      },
      {
        CITY: "NORTH FITZROY",
      },
      {
        CITY: "NORTH MACKAY",
      },
      {
        CITY: "NORTH MELBOURNE",
      },
      {
        CITY: "NORTH TAMBORINE",
      },
      {
        CITY: "NORTH TURRAMURRA",
      },
      {
        CITY: "NORTHAM",
      },
      {
        CITY: "NORTHCOTE",
      },
      {
        CITY: "NORTHGATE",
      },
      {
        CITY: "NORTHMEAD",
      },
      {
        CITY: "NORTON SUMMIT",
      },
      {
        CITY: "NORWOOD",
      },
      {
        CITY: "NOTTING HILL",
      },
      {
        CITY: "NOWRA",
      },
      {
        CITY: "NULLAWARRE",
      },
      {
        CITY: "NUNAWADING",
      },
      {
        CITY: "NURIOOTPA",
      },
      {
        CITY: "NYORA",
      },
      {
        CITY: "OAK PARK",
      },
      {
        CITY: "OAKDALE",
      },
      {
        CITY: "OAKEY",
      },
      {
        CITY: "OAKHURST",
      },
      {
        CITY: "OAKLANDS",
      },
      {
        CITY: "OAKLEIGH",
      },
      {
        CITY: "OAKLEIGH SOUTH",
      },
      {
        CITY: "OATLANDS",
      },
      {
        CITY: "OATLEY",
      },
      {
        CITY: "OBERON",
      },
      {
        CITY: "OBI OBI",
      },
      {
        CITY: "OCEAN SHORES",
      },
      {
        CITY: "OFFICER",
      },
      {
        CITY: "OLD BAR",
      },
      {
        CITY: "OLD BEACH",
      },
      {
        CITY: "OLINDA",
      },
      {
        CITY: "OMAN-AMA",
      },
      {
        CITY: "ONE TREE HILL",
      },
      {
        CITY: "ORANGE",
      },
      {
        CITY: "ORCHARD HILLS",
      },
      {
        CITY: "ORFORD",
      },
      {
        CITY: "ORIENT POINT",
      },
      {
        CITY: "ORMEAU",
      },
      {
        CITY: "ORMISTON",
      },
      {
        CITY: "ORMOND",
      },
      {
        CITY: "ORROROO",
      },
      {
        CITY: "OSBORNE",
      },
      {
        CITY: "OURIMBAH",
      },
      {
        CITY: "OXENFORD",
      },
      {
        CITY: "OXLEY",
      },
      {
        CITY: "OXLEY",
      },
      {
        CITY: "OYSTER BAY",
      },
      {
        CITY: "PADDINGTON",
      },
      {
        CITY: "PADSTOW",
      },
      {
        CITY: "PAKENHAM",
      },
      {
        CITY: "PALLARENDA",
      },
      {
        CITY: "PALM BEACH",
      },
      {
        CITY: "PALM COVE",
      },
      {
        CITY: "PALM ISLAND",
      },
      {
        CITY: "PALMER",
      },
      {
        CITY: "PALMWOODS",
      },
      {
        CITY: "PAMBULA",
      },
      {
        CITY: "PARADISE",
      },
      {
        CITY: "PARAMATTA",
      },
      {
        CITY: "PARAP",
      },
      {
        CITY: "PARK RIDGE",
      },
      {
        CITY: "PARKDALE",
      },
      {
        CITY: "PARKERVILLE",
      },
      {
        CITY: "PARKES",
      },
      {
        CITY: "PARKHURST",
      },
      {
        CITY: "PARKVILLE",
      },
      {
        CITY: "PARKWOOD",
      },
      {
        CITY: "PEARCE",
      },
      {
        CITY: "PEARCEDALE",
      },
      {
        CITY: "PECHEY",
      },
      {
        CITY: "PELICAN",
      },
      {
        CITY: "PENDLE HILLS",
      },
      {
        CITY: "PENFIELD",
      },
      {
        CITY: "PENGUIN",
      },
      {
        CITY: "PENNANT HILLS",
      },
      {
        CITY: "PENRITH",
      },
      {
        CITY: "PENSHURST",
      },
      {
        CITY: "PERENJORI",
      },
      {
        CITY: "PERTH",
      },
      {
        CITY: "PERTH",
      },
      {
        CITY: "PETERBOROUGH",
      },
      {
        CITY: "PETRIE",
      },
      {
        CITY: "PHILLIP",
      },
      {
        CITY: "PIALBA",
      },
      {
        CITY: "PIAWANING",
      },
      {
        CITY: "PICNIC BAY",
      },
      {
        CITY: "PICTON",
      },
      {
        CITY: "PIGGABEEN",
      },
      {
        CITY: "PIMPAMA",
      },
      {
        CITY: "PINE MOUNTAIN",
      },
      {
        CITY: "PINERY",
      },
      {
        CITY: "PINJARRA",
      },
      {
        CITY: "PINKENBA",
      },
      {
        CITY: "PIONEER",
      },
      {
        CITY: "PIPERS BROOK",
      },
      {
        CITY: "PITT TOWN",
      },
      {
        CITY: "PITTSWORTH",
      },
      {
        CITY: "PLYMPTON",
      },
      {
        CITY: "POINT CLARE",
      },
      {
        CITY: "POINT COOK",
      },
      {
        CITY: "POINT LONSDALE",
      },
      {
        CITY: "POINT LOOKOUT",
      },
      {
        CITY: "POINT PASS",
      },
      {
        CITY: "POINT VERNON",
      },
      {
        CITY: "POKOLBIN",
      },
      {
        CITY: "POMONA",
      },
      {
        CITY: "PONTVILLE",
      },
      {
        CITY: "POOWONG",
      },
      {
        CITY: "PORT ADELAIDE",
      },
      {
        CITY: "PORT AUGUSTA",
      },
      {
        CITY: "PORT DOUGLAS",
      },
      {
        CITY: "PORT FAIRY",
      },
      {
        CITY: "PORT HACKING",
      },
      {
        CITY: "PORT HEDLAND",
      },
      {
        CITY: "PORT KEMBLA",
      },
      {
        CITY: "PORT LINCOLN",
      },
      {
        CITY: "PORT MACDONNELL",
      },
      {
        CITY: "PORT MACQUARIE",
      },
      {
        CITY: "PORT MELBOURNE",
      },
      {
        CITY: "PORT NOARLUNGA",
      },
      {
        CITY: "PORT PIRIE",
      },
      {
        CITY: "PORT SORELL",
      },
      {
        CITY: "PORTARLINGTON",
      },
      {
        CITY: "PORTLAND",
      },
      {
        CITY: "PORTSEA",
      },
      {
        CITY: "POTTS POINT",
      },
      {
        CITY: "PRAHRAN",
      },
      {
        CITY: "PRESTON",
      },
      {
        CITY: "PROSERPINE",
      },
      {
        CITY: "PROSPECT",
      },
      {
        CITY: "PURNIM",
      },
      {
        CITY: "PYMBLE",
      },
      {
        CITY: "PYRMONT",
      },
      {
        CITY: "QUAKERS HILL",
      },
      {
        CITY: "QUEANBEYAN",
      },
      {
        CITY: "QUEENSCLIFF",
      },
      {
        CITY: "QUEENSFERRY",
      },
      {
        CITY: "QUEENSTOWN",
      },
      {
        CITY: "RAILTON",
      },
      {
        CITY: "RAMSGATE",
      },
      {
        CITY: "RANDWICK",
      },
      {
        CITY: "RATHMINES",
      },
      {
        CITY: "RAVENSWOOD",
      },
      {
        CITY: "RAWORTH",
      },
      {
        CITY: "RAYMOND TERRACE",
      },
      {
        CITY: "RED HILL",
      },
      {
        CITY: "REDAN",
      },
      {
        CITY: "REDBANK",
      },
      {
        CITY: "REDCLIFFE",
      },
      {
        CITY: "REDFERN",
      },
      {
        CITY: "REDHEAD",
      },
      {
        CITY: "REDLAND BAY",
      },
      {
        CITY: "REDLYNCH",
      },
      {
        CITY: "REEDY CREEK",
      },
      {
        CITY: "REGENTS PARK",
      },
      {
        CITY: "RESEARCH",
      },
      {
        CITY: "RESERVOIR",
      },
      {
        CITY: "RETREAT",
      },
      {
        CITY: "REVESBY",
      },
      {
        CITY: "REYNELLA",
      },
      {
        CITY: "RHODES",
      },
      {
        CITY: "RIANA",
      },
      {
        CITY: "RICHMOND",
      },
      {
        CITY: "RICHMOND",
      },
      {
        CITY: "RIDDELL",
      },
      {
        CITY: "RIDGLEY",
      },
      {
        CITY: "RINGWOOD",
      },
      {
        CITY: "RINGWOOD EAST",
      },
      {
        CITY: "RINGWOOD NORTH",
      },
      {
        CITY: "RIPLEY",
      },
      {
        CITY: "RISDON",
      },
      {
        CITY: "RIVER HEADS",
      },
      {
        CITY: "RIVERSIDE",
      },
      {
        CITY: "RIVERSTONE",
      },
      {
        CITY: "RIVERTON",
      },
      {
        CITY: "RIVERVIEW",
      },
      {
        CITY: "RIVERWOOD",
      },
      {
        CITY: "ROBERTSON",
      },
      {
        CITY: "ROBERTSTOWN",
      },
      {
        CITY: "ROBINVALE",
      },
      {
        CITY: "ROCHEDALE",
      },
      {
        CITY: "ROCHESTER",
      },
      {
        CITY: "ROCKBANK",
      },
      {
        CITY: "ROCKDALE",
      },
      {
        CITY: "ROCKHAMPTON",
      },
      {
        CITY: "ROCKINGHAM",
      },
      {
        CITY: "ROCKLEA",
      },
      {
        CITY: "RODBOROUGH",
      },
      {
        CITY: "ROKEBY",
      },
      {
        CITY: "ROLEYSTONE",
      },
      {
        CITY: "ROMA",
      },
      {
        CITY: "ROMSEY",
      },
      {
        CITY: "ROOTY HILL",
      },
      {
        CITY: "ROSANNA",
      },
      {
        CITY: "ROSE BAY",
      },
      {
        CITY: "ROSEBERY",
      },
      {
        CITY: "ROSEBERY",
      },
      {
        CITY: "ROSEBUD",
      },
      {
        CITY: "ROSEHILL",
      },
      {
        CITY: "ROSEVILLE",
      },
      {
        CITY: "ROSEWOOD",
      },
      {
        CITY: "ROSEWORTHY",
      },
      {
        CITY: "ROSS",
      },
      {
        CITY: "ROSS CREEK",
      },
      {
        CITY: "ROSS RIVER",
      },
      {
        CITY: "ROWVILLE",
      },
      {
        CITY: "ROYAL PARK",
      },
      {
        CITY: "ROZELLE",
      },
      {
        CITY: "RUDALL",
      },
      {
        CITY: "RUSHWORTH",
      },
      {
        CITY: "RUTHERFORD",
      },
      {
        CITY: "RYDALMERE",
      },
      {
        CITY: "RYDE",
      },
      {
        CITY: "RYE",
      },
      {
        CITY: "RYLSTONE",
      },
      {
        CITY: "SAFETY BAY",
      },
      {
        CITY: "SAINT ALBANS",
      },
      {
        CITY: "SAINT ANDREWS",
      },
      {
        CITY: "SAINT GEORGE",
      },
      {
        CITY: "SAINT GEORGES BASIN",
      },
      {
        CITY: "SAINT HELENA",
      },
      {
        CITY: "SAINT IVES",
      },
      {
        CITY: "SAINT JAMES",
      },
      {
        CITY: "SAINT LEONARDS",
      },
      {
        CITY: "SAINT LUCIA",
      },
      {
        CITY: "SAINT MARYS",
      },
      {
        CITY: "SAINT PETERS",
      },
      {
        CITY: "SAINTS",
      },
      {
        CITY: "SALAMANDER BAY",
      },
      {
        CITY: "SALE",
      },
      {
        CITY: "SALISBURY",
      },
      {
        CITY: "SALT ASH",
      },
      {
        CITY: "SAMFORD",
      },
      {
        CITY: "SAN REMO",
      },
      {
        CITY: "SANCTUARY POINT",
      },
      {
        CITY: "SANDFORD",
      },
      {
        CITY: "SANDGATE",
      },
      {
        CITY: "SANDGATE",
      },
      {
        CITY: "SANDRINGHAM",
      },
      {
        CITY: "SANDSTONE POINT",
      },
      {
        CITY: "SANDY BAY",
      },
      {
        CITY: "SANDY HOLLOW",
      },
      {
        CITY: "SANS SOUCI",
      },
      {
        CITY: "SAPPHIRE",
      },
      {
        CITY: "SARINA",
      },
      {
        CITY: "SARINA BEACH",
      },
      {
        CITY: "SASSAFRAS",
      },
      {
        CITY: "SCARBOROUGH",
      },
      {
        CITY: "SCARBOROUGH",
      },
      {
        CITY: "SCARNESS",
      },
      {
        CITY: "SCARSDALE",
      },
      {
        CITY: "SCHOFIELDS",
      },
      {
        CITY: "SCONE",
      },
      {
        CITY: "SCORESBY",
      },
      {
        CITY: "SCOTTSDALE",
      },
      {
        CITY: "SEACLIFF",
      },
      {
        CITY: "SEACOMBE",
      },
      {
        CITY: "SEAFORD",
      },
      {
        CITY: "SEAFORD",
      },
      {
        CITY: "SEAFORTH",
      },
      {
        CITY: "SEATON",
      },
      {
        CITY: "SEAVIEW",
      },
      {
        CITY: "SEBASTOPOL",
      },
      {
        CITY: "SELBOURNE",
      },
      {
        CITY: "SELBY",
      },
      {
        CITY: "SELLICKS BEACH",
      },
      {
        CITY: "SELLICKS HILL",
      },
      {
        CITY: "SEMAPHORE",
      },
      {
        CITY: "SERPENTINE",
      },
      {
        CITY: "SEVEN HILLS",
      },
      {
        CITY: "SEVEN MILE BEACH",
      },
      {
        CITY: "SEVILLE",
      },
      {
        CITY: "SEVILLE EAST",
      },
      {
        CITY: "SEYMOUR",
      },
      {
        CITY: "SHEFFIELD",
      },
      {
        CITY: "SHELLHARBOUR",
      },
      {
        CITY: "SHENTON PARK",
      },
      {
        CITY: "SHERBROOK",
      },
      {
        CITY: "SHOAL POINT",
      },
      {
        CITY: "SHORNCLIFFE",
      },
      {
        CITY: "SILVAN",
      },
      {
        CITY: "SILVERDALE",
      },
      {
        CITY: "SILVERWATER",
      },
      {
        CITY: "SINGLETON",
      },
      {
        CITY: "SIPPY DOWNS",
      },
      {
        CITY: "SLACKS CREEK",
      },
      {
        CITY: "SMITHFIELD",
      },
      {
        CITY: "SMITHFIELD",
      },
      {
        CITY: "SMITHTON",
      },
      {
        CITY: "SMYTHES CREEK",
      },
      {
        CITY: "SMYTHESDALE",
      },
      {
        CITY: "SNOWTOWN",
      },
      {
        CITY: "SOMERSBY",
      },
      {
        CITY: "SOMERSET",
      },
      {
        CITY: "SOMERSET",
      },
      {
        CITY: "SOMERTON",
      },
      {
        CITY: "SOMERVILLE",
      },
      {
        CITY: "SORRENTO",
      },
      {
        CITY: "SOUTH MELBOURNE",
      },
      {
        CITY: "SOUTH PERTH",
      },
      {
        CITY: "SOUTH WEST ROCKS",
      },
      {
        CITY: "SOUTH YARRA",
      },
      {
        CITY: "SOUTHBANK",
      },
      {
        CITY: "SOUTHERN BROOK",
      },
      {
        CITY: "SOUTHPORT",
      },
      {
        CITY: "SPEARWOOD",
      },
      {
        CITY: "SPENCER",
      },
      {
        CITY: "SPOTSWOOD",
      },
      {
        CITY: "SPREYTON",
      },
      {
        CITY: "SPRING FIELD",
      },
      {
        CITY: "SPRING HILL",
      },
      {
        CITY: "SPRING HILL",
      },
      {
        CITY: "SPRINGFIELD",
      },
      {
        CITY: "SPRINGVALE",
      },
      {
        CITY: "SPRINGVALE SOUTH",
      },
      {
        CITY: "SPRINGWOOD",
      },
      {
        CITY: "ST HELENS",
      },
      {
        CITY: "ST KILDA",
      },
      {
        CITY: "STANHOPE",
      },
      {
        CITY: "STANLEY",
      },
      {
        CITY: "STANMORE",
      },
      {
        CITY: "STANNUM",
      },
      {
        CITY: "STANTHORPE",
      },
      {
        CITY: "STAPYLTON",
      },
      {
        CITY: "STAWELL",
      },
      {
        CITY: "STIEGLITZ",
      },
      {
        CITY: "STIRLING",
      },
      {
        CITY: "STIRLING",
      },
      {
        CITY: "STOCKTON",
      },
      {
        CITY: "STOCKYARD CREEK",
      },
      {
        CITY: "STONEVILLE",
      },
      {
        CITY: "STRADBROKE",
      },
      {
        CITY: "STRATFORD",
      },
      {
        CITY: "STRATFORD",
      },
      {
        CITY: "STRATHALBYN",
      },
      {
        CITY: "STRATHFIELD",
      },
      {
        CITY: "STRATHFIELDSAYE",
      },
      {
        CITY: "STRATHMORE",
      },
      {
        CITY: "STRATHPINE",
      },
      {
        CITY: "STREAKY BAY",
      },
      {
        CITY: "STROUD",
      },
      {
        CITY: "STUART PARK",
      },
      {
        CITY: "STURT",
      },
      {
        CITY: "SUBIACO",
      },
      {
        CITY: "SUCCESS",
      },
      {
        CITY: "SUFFOLK PARK",
      },
      {
        CITY: "SULPHUR CREEK",
      },
      {
        CITY: "SUMMERHOLM",
      },
      {
        CITY: "SUMMERTOWN",
      },
      {
        CITY: "SUNBURY",
      },
      {
        CITY: "SUNNYBANK",
      },
      {
        CITY: "SUNNYSIDE",
      },
      {
        CITY: "SUNSHINE",
      },
      {
        CITY: "SURFERS PARADISE",
      },
      {
        CITY: "SURREY HILLS",
      },
      {
        CITY: "SUTHERLAND",
      },
      {
        CITY: "SWAN HILL",
      },
      {
        CITY: "SWAN VIEW",
      },
      {
        CITY: "SWANSEA",
      },
      {
        CITY: "SWANSEA",
      },
      {
        CITY: "SYDENHAM",
      },
      {
        CITY: "SYDNEY",
      },
      {
        CITY: "SYLVANIA",
      },
      {
        CITY: "SYLVANIA WATERS",
      },
      {
        CITY: "TAHMOOR",
      },
      {
        CITY: "TALLANGATTA",
      },
      {
        CITY: "TALLAROOK",
      },
      {
        CITY: "TALLEBUDGERA",
      },
      {
        CITY: "TALMALMO",
      },
      {
        CITY: "TAMBO UPPER",
      },
      {
        CITY: "TAMBORINE",
      },
      {
        CITY: "TAMMIN",
      },
      {
        CITY: "TAMWORTH",
      },
      {
        CITY: "TANNUM SANDS",
      },
      {
        CITY: "TANUNDA",
      },
      {
        CITY: "TAREE",
      },
      {
        CITY: "TAREN POINT",
      },
      {
        CITY: "TARLEE",
      },
      {
        CITY: "TARNEIT",
      },
      {
        CITY: "TAROONA",
      },
      {
        CITY: "TARRAGINDI",
      },
      {
        CITY: "TARRAWANNA",
      },
      {
        CITY: "TARRO",
      },
      {
        CITY: "TARZALI",
      },
      {
        CITY: "TATURA",
      },
      {
        CITY: "TAWONGA",
      },
      {
        CITY: "TEESDALE",
      },
      {
        CITY: "TELEGRAPH POINT",
      },
      {
        CITY: "TEMORA",
      },
      {
        CITY: "TEMPE",
      },
      {
        CITY: "TEMPLESTOWE",
      },
      {
        CITY: "TEMPLESTOWE LOWER",
      },
      {
        CITY: "TENAMBIT",
      },
      {
        CITY: "TENNYSON",
      },
      {
        CITY: "TENTERFIELD",
      },
      {
        CITY: "TERALBA",
      },
      {
        CITY: "TERRIGAL",
      },
      {
        CITY: "TERRY HILLS",
      },
      {
        CITY: "TEWANTIN",
      },
      {
        CITY: "THAGOONA",
      },
      {
        CITY: "THARGOMINDAH",
      },
      {
        CITY: "THE BASIN",
      },
      {
        CITY: "THE ENTRANCE",
      },
      {
        CITY: "THE OAKS",
      },
      {
        CITY: "THE PATCH",
      },
      {
        CITY: "THE ROCKS",
      },
      {
        CITY: "THEBARTON",
      },
      {
        CITY: "THIRLMERE",
      },
      {
        CITY: "THIRROUL",
      },
      {
        CITY: "THOMASTOWN",
      },
      {
        CITY: "THOMPSON",
      },
      {
        CITY: "THORNBURY",
      },
      {
        CITY: "THORNLANDS",
      },
      {
        CITY: "THORNLEIGH",
      },
      {
        CITY: "THORNTON",
      },
      {
        CITY: "THURINGOWA",
      },
      {
        CITY: "THURSDAY ISLAND",
      },
      {
        CITY: "TIARO",
      },
      {
        CITY: "TIERI",
      },
      {
        CITY: "TINAMBA",
      },
      {
        CITY: "TINCURRIN",
      },
      {
        CITY: "TINGOORA",
      },
      {
        CITY: "TOLGA",
      },
      {
        CITY: "TOM PRICE",
      },
      {
        CITY: "TOMAGO",
      },
      {
        CITY: "TONGALA",
      },
      {
        CITY: "TOOGOOLAWAH",
      },
      {
        CITY: "TOOGOOM",
      },
      {
        CITY: "TOOLANGI",
      },
      {
        CITY: "TOOLERN VALE",
      },
      {
        CITY: "TOONGABBIE",
      },
      {
        CITY: "TOORA",
      },
      {
        CITY: "TOORADIN",
      },
      {
        CITY: "TOORAK",
      },
      {
        CITY: "TOORBUL",
      },
      {
        CITY: "TOORONGA",
      },
      {
        CITY: "TOOWONG",
      },
      {
        CITY: "TOOWOOMBA",
      },
      {
        CITY: "TORBANLEA",
      },
      {
        CITY: "TORONTO",
      },
      {
        CITY: "TORQUAY",
      },
      {
        CITY: "TORQUAY",
      },
      {
        CITY: "TOTTENHAM",
      },
      {
        CITY: "TOUKLEY",
      },
      {
        CITY: "TOWNSVILLE",
      },
      {
        CITY: "TOWRADGI",
      },
      {
        CITY: "TRAFALGAR",
      },
      {
        CITY: "TRARALGON",
      },
      {
        CITY: "TRARALGON SOUTH",
      },
      {
        CITY: "TRENTHAM",
      },
      {
        CITY: "TRINITY BEACH",
      },
      {
        CITY: "TRUGANINA",
      },
      {
        CITY: "TRUNKEY",
      },
      {
        CITY: "TUGGERAH",
      },
      {
        CITY: "TUGGERANONG",
      },
      {
        CITY: "TUGUN",
      },
      {
        CITY: "TULLAMARINE",
      },
      {
        CITY: "TULLY",
      },
      {
        CITY: "TUMUT",
      },
      {
        CITY: "TUNCURRY",
      },
      {
        CITY: "TUNNACK",
      },
      {
        CITY: "TURNERS BEACH",
      },
      {
        CITY: "TURRAMURRA",
      },
      {
        CITY: "TWEED HEADS",
      },
      {
        CITY: "TWO ROCKS",
      },
      {
        CITY: "TWO WELLS",
      },
      {
        CITY: "TYABB",
      },
      {
        CITY: "TYALGUM",
      },
      {
        CITY: "TYNONG",
      },
      {
        CITY: "UKI",
      },
      {
        CITY: "ULLADULLA",
      },
      {
        CITY: "ULMARRA",
      },
      {
        CITY: "ULTIMO",
      },
      {
        CITY: "ULVERSTONE",
      },
      {
        CITY: "UMINA",
      },
      {
        CITY: "UNANDERRA",
      },
      {
        CITY: "UNDERWOOD",
      },
      {
        CITY: "UNLEY",
      },
      {
        CITY: "UPPER BROOKFIELD",
      },
      {
        CITY: "UPPER BURRINGBAR",
      },
      {
        CITY: "UPPER CABOOLTURE",
      },
      {
        CITY: "UPPER COOMERA",
      },
      {
        CITY: "UPWEY",
      },
      {
        CITY: "URAIDLA",
      },
      {
        CITY: "URANGAN",
      },
      {
        CITY: "VALLA BEACH",
      },
      {
        CITY: "VALLEY HEIGHTS",
      },
      {
        CITY: "VAUCLUSE",
      },
      {
        CITY: "VENUS BAY",
      },
      {
        CITY: "VERDUN",
      },
      {
        CITY: "VERMONT",
      },
      {
        CITY: "VICTOR HARBOR",
      },
      {
        CITY: "VICTORIA PARK",
      },
      {
        CITY: "VICTORIA POINT",
      },
      {
        CITY: "VILLAWOOD",
      },
      {
        CITY: "VINEYARD",
      },
      {
        CITY: "VIRGINIA",
      },
      {
        CITY: "VIRGINIA",
      },
      {
        CITY: "WAAIA",
      },
      {
        CITY: "WACOL",
      },
      {
        CITY: "WAGGA WAGGA",
      },
      {
        CITY: "WAHGUNYAH",
      },
      {
        CITY: "WAHROONGA",
      },
      {
        CITY: "WAIKERIE",
      },
      {
        CITY: "WAITARA",
      },
      {
        CITY: "WALGETT",
      },
      {
        CITY: "WALHALLA",
      },
      {
        CITY: "WALLACIA",
      },
      {
        CITY: "WALLAN",
      },
      {
        CITY: "WALLAROO",
      },
      {
        CITY: "WALLINGTON",
      },
      {
        CITY: "WALLISTON",
      },
      {
        CITY: "WALLSEND",
      },
      {
        CITY: "WALMER",
      },
      {
        CITY: "WAMBERAL",
      },
      {
        CITY: "WAMURAN",
      },
      {
        CITY: "WANBI",
      },
      {
        CITY: "WANDIN NORTH",
      },
      {
        CITY: "WANDOAN",
      },
      {
        CITY: "WANGARATTA",
      },
      {
        CITY: "WANGI WANGI",
      },
      {
        CITY: "WANNEROO",
      },
      {
        CITY: "WANTIRNA",
      },
      {
        CITY: "WANTIRNA SOUTH",
      },
      {
        CITY: "WARATAH",
      },
      {
        CITY: "WARBURTON",
      },
      {
        CITY: "WARNBRO",
      },
      {
        CITY: "WARNERVALE",
      },
      {
        CITY: "WARRAGUL",
      },
      {
        CITY: "WARRANDYTE",
      },
      {
        CITY: "WARRANE",
      },
      {
        CITY: "WARRAWONG",
      },
      {
        CITY: "WARREN",
      },
      {
        CITY: "WARREN SHIRE",
      },
      {
        CITY: "WARRIGAL",
      },
      {
        CITY: "WARRIMOO",
      },
      {
        CITY: "WARRNAMBOOL",
      },
      {
        CITY: "WARWICK",
      },
      {
        CITY: "WATERFORD",
      },
      {
        CITY: "WATERLOO",
      },
      {
        CITY: "WATERLOO",
      },
      {
        CITY: "WATERLOO",
      },
      {
        CITY: "WATERLOO CORNER",
      },
      {
        CITY: "WATSONIA",
      },
      {
        CITY: "WATTLE GROVE",
      },
      {
        CITY: "WATTLE GROVE",
      },
      {
        CITY: "WAUBRA",
      },
      {
        CITY: "WAUCHOPE",
      },
      {
        CITY: "WAURN PONDS",
      },
      {
        CITY: "WAVERLEY",
      },
      {
        CITY: "WAVERTON",
      },
      {
        CITY: "WAYVILLE",
      },
      {
        CITY: "WEDDERBURN",
      },
      {
        CITY: "WEDDERBURN",
      },
      {
        CITY: "WEETAH",
      },
      {
        CITY: "WEETHALLE",
      },
      {
        CITY: "WELLARD",
      },
      {
        CITY: "WELLINGTON",
      },
      {
        CITY: "WELLINGTON POINT",
      },
      {
        CITY: "WELSHPOOL",
      },
      {
        CITY: "WEMBLEY",
      },
      {
        CITY: "WENDOUREE",
      },
      {
        CITY: "WENTWORTH",
      },
      {
        CITY: "WENTWORTH FALLS",
      },
      {
        CITY: "WENTWORTHVILLE",
      },
      {
        CITY: "WERRIBEE",
      },
      {
        CITY: "WERRIBEE SOUTH",
      },
      {
        CITY: "WEST BURLEIGH",
      },
      {
        CITY: "WEST END",
      },
      {
        CITY: "WEST HOXTON",
      },
      {
        CITY: "WEST PYMBLE",
      },
      {
        CITY: "WEST WYALONG",
      },
      {
        CITY: "WESTBROOK",
      },
      {
        CITY: "WESTFIELD",
      },
      {
        CITY: "WESTMEAD",
      },
      {
        CITY: "WESTON",
      },
      {
        CITY: "WESTON CREEK",
      },
      {
        CITY: "WHEELERS HILL",
      },
      {
        CITY: "WHITE ROCK",
      },
      {
        CITY: "WHITFIELD",
      },
      {
        CITY: "WHITTLESEA",
      },
      {
        CITY: "WHYALLA",
      },
      {
        CITY: "WICKHAM",
      },
      {
        CITY: "WICKHAM",
      },
      {
        CITY: "WILBERFORCE",
      },
      {
        CITY: "WILLASTON",
      },
      {
        CITY: "WILLAURA",
      },
      {
        CITY: "WILLIAMS",
      },
      {
        CITY: "WILLIAMSTOWN",
      },
      {
        CITY: "WILLIAMSTOWN",
      },
      {
        CITY: "WILLOW GROVE",
      },
      {
        CITY: "WILLOW VALE",
      },
      {
        CITY: "WILLUNGA",
      },
      {
        CITY: "WILMOT",
      },
      {
        CITY: "WILSON",
      },
      {
        CITY: "WILTON",
      },
      {
        CITY: "WILUNA",
      },
      {
        CITY: "WIMBLEDON",
      },
      {
        CITY: "WINCHELSEA",
      },
      {
        CITY: "WINDANG",
      },
      {
        CITY: "WINDAROO",
      },
      {
        CITY: "WINDELLAMA",
      },
      {
        CITY: "WINDERMERE PARK",
      },
      {
        CITY: "WINDSOR",
      },
      {
        CITY: "WINDSOR",
      },
      {
        CITY: "WINDSOR",
      },
      {
        CITY: "WINDSOR",
      },
      {
        CITY: "WINDSOR DOWNS",
      },
      {
        CITY: "WINGHAM",
      },
      {
        CITY: "WINKLEIGH",
      },
      {
        CITY: "WODONGA",
      },
      {
        CITY: "WOLLAR",
      },
      {
        CITY: "WOLLERT",
      },
      {
        CITY: "WOLLONGBAR",
      },
      {
        CITY: "WOLLONGONG",
      },
      {
        CITY: "WOMBOOTA",
      },
      {
        CITY: "WON WRON",
      },
      {
        CITY: "WONDAI",
      },
      {
        CITY: "WONGA PARK",
      },
      {
        CITY: "WONGAN HILLS",
      },
      {
        CITY: "WONTHAGGI",
      },
      {
        CITY: "WOODBURN",
      },
      {
        CITY: "WOODEND",
      },
      {
        CITY: "WOODFORD",
      },
      {
        CITY: "WOODFORD",
      },
      {
        CITY: "WOODGATE",
      },
      {
        CITY: "WOODLEIGH",
      },
      {
        CITY: "WOODRIDGE",
      },
      {
        CITY: "WOODSIDE",
      },
      {
        CITY: "WOODSTOCK",
      },
      {
        CITY: "WOODVALE",
      },
      {
        CITY: "WOODVILLE",
      },
      {
        CITY: "WOOLAMAI",
      },
      {
        CITY: "WOOLGOOLGA",
      },
      {
        CITY: "WOOLLOOMOOLOO",
      },
      {
        CITY: "WOOLOOWARE",
      },
      {
        CITY: "WOOLOWEYAH",
      },
      {
        CITY: "WOOLWICH",
      },
      {
        CITY: "WOOMBAH",
      },
      {
        CITY: "WOOMBYE",
      },
      {
        CITY: "WOONONA",
      },
      {
        CITY: "WOORI YALLOCK",
      },
      {
        CITY: "WOORIM",
      },
      {
        CITY: "WOOROOLIN",
      },
      {
        CITY: "WORONGARY",
      },
      {
        CITY: "WORONORA",
      },
      {
        CITY: "WOY WOY",
      },
      {
        CITY: "WULKURAKA",
      },
      {
        CITY: "WYALKATCHEM",
      },
      {
        CITY: "WYALONG",
      },
      {
        CITY: "WYEE",
      },
      {
        CITY: "WYNDHAM",
      },
      {
        CITY: "WYNNUM",
      },
      {
        CITY: "WYNYARD",
      },
      {
        CITY: "WYONG",
      },
      {
        CITY: "WYONGAH",
      },
      {
        CITY: "WYREEMA",
      },
      {
        CITY: "YAAMBA",
      },
      {
        CITY: "YACKANDANDAH",
      },
      {
        CITY: "YALLINGUP",
      },
      {
        CITY: "YALLOURN",
      },
      {
        CITY: "YALLOURN NORTH",
      },
      {
        CITY: "YAMBA",
      },
      {
        CITY: "YANCHEP",
      },
      {
        CITY: "YANDINA",
      },
      {
        CITY: "YANGAN",
      },
      {
        CITY: "YANKALILLA",
      },
      {
        CITY: "YAPEEN",
      },
      {
        CITY: "YAROOMBA",
      },
      {
        CITY: "YARPTURK",
      },
      {
        CITY: "YARRA GLEN",
      },
      {
        CITY: "YARRA JUNCTION",
      },
      {
        CITY: "YARRAGON",
      },
      {
        CITY: "YARRAM",
      },
      {
        CITY: "YARRAMAN",
      },
      {
        CITY: "YARRAMBAT",
      },
      {
        CITY: "YARRAVILLE",
      },
      {
        CITY: "YARRAWARRAH",
      },
      {
        CITY: "YASS",
      },
      {
        CITY: "YATALA",
      },
      {
        CITY: "YEA",
      },
      {
        CITY: "YELLOW ROCK",
      },
      {
        CITY: "YENDA",
      },
      {
        CITY: "YEOVAL",
      },
      {
        CITY: "YEPPOON",
      },
      {
        CITY: "YERRINBOOL",
      },
      {
        CITY: "YINNAR",
      },
      {
        CITY: "YOLLA",
      },
      {
        CITY: "YORK",
      },
      {
        CITY: "YORKEYS KNOB",
      },
      {
        CITY: "YOUNG",
      },
      {
        CITY: "YOWIE BAY",
      },
      {
        CITY: "ZEEHAN",
      },
    ],
  },
  {
    COUNTRY: "BOTSWANA",
    CODE: "BW",
    CITIES: [
      {
        CITY: "FRANCISTOWN",
      },
      {
        CITY: "GABORONE",
      },
      {
        CITY: "ORAPA",
      },
      {
        CITY: "SEROWE",
      },
      {
        CITY: "VILLAGE",
      },
    ],
  },
  {
    COUNTRY: "BAHAMAS",
    CODE: "BS",
    CITIES: [
      {
        CITY: "ANDROS TOWN",
      },
      {
        CITY: "DUNMORE TOWN",
      },
      {
        CITY: "FREEPORT",
      },
      {
        CITY: "MARSH HARBOUR",
      },
      {
        CITY: "NASSAU",
      },
      {
        CITY: "PALMETTO POINT",
      },
      {
        CITY: "SPANISH WELLS",
      },
    ],
  },
  {
    COUNTRY: "BRAZIL",
    CODE: "BR",
    CITIES: [
      {
        CITY: "ABADIANIA",
      },
      {
        CITY: "ABAETETUBA",
      },
      {
        CITY: "ABELARDO LUZ",
      },
      {
        CITY: "ABIDOS",
      },
      {
        CITY: "ABRANTES",
      },
      {
        CITY: "ABREU",
      },
      {
        CITY: "ABREU E LIMA",
      },
      {
        CITY: "ACARAU",
      },
      {
        CITY: "ACOPIARA",
      },
      {
        CITY: "ACORIZAL",
      },
      {
        CITY: "ACU",
      },
      {
        CITY: "ACUCENA",
      },
      {
        CITY: "ADAMANTINA",
      },
      {
        CITY: "ADUSTINA",
      },
      {
        CITY: "AFOGADOS DA INGAZEIRA",
      },
      {
        CITY: "AFONSO CLAUDIO",
      },
      {
        CITY: "AGRESTINA",
      },
      {
        CITY: "AGUA BOA",
      },
      {
        CITY: "AGUA CLARA",
      },
      {
        CITY: "AGUAI",
      },
      {
        CITY: "AGUANIL",
      },
      {
        CITY: "AGUAS CLARAS",
      },
      {
        CITY: "AGUAS DA PRATA",
      },
      {
        CITY: "AGUAS DE CHAPECO",
      },
      {
        CITY: "AGUAS DE LINDOIA",
      },
      {
        CITY: "AGUAS DE SAO PEDRO",
      },
      {
        CITY: "AGUAS LINDAS",
      },
      {
        CITY: "AGUDO",
      },
      {
        CITY: "AGUDOS",
      },
      {
        CITY: "AGULHA",
      },
      {
        CITY: "ALAGOA GRANDE",
      },
      {
        CITY: "ALAGOA NOVA",
      },
      {
        CITY: "ALAGOINHA",
      },
      {
        CITY: "ALAGOINHAS",
      },
      {
        CITY: "ALAMBARI",
      },
      {
        CITY: "ALDEIA",
      },
      {
        CITY: "ALEGRE",
      },
      {
        CITY: "ALEGRES",
      },
      {
        CITY: "ALEGRETE",
      },
      {
        CITY: "ALEM PARAIBA",
      },
      {
        CITY: "ALFENAS",
      },
      {
        CITY: "ALFREDO CHAVES",
      },
      {
        CITY: "ALMEIRIM",
      },
      {
        CITY: "ALMIRANTE TAMANDARE",
      },
      {
        CITY: "ALOANDIA",
      },
      {
        CITY: "ALTA FLORESTA",
      },
      {
        CITY: "ALTAMIRA",
      },
      {
        CITY: "ALTANEIRA",
      },
      {
        CITY: "ALTEROSA",
      },
      {
        CITY: "ALTINHO",
      },
      {
        CITY: "ALTINOPOLIS",
      },
      {
        CITY: "ALTO",
      },
      {
        CITY: "ALTO ALEGRE",
      },
      {
        CITY: "ALTO DO RODRIGUES",
      },
      {
        CITY: "ALTO PARAISO DE GOIAS",
      },
      {
        CITY: "ALTO PIQUIRI",
      },
      {
        CITY: "ALTO SANTO",
      },
      {
        CITY: "ALTOS",
      },
      {
        CITY: "ALVARES MACHADO",
      },
      {
        CITY: "ALVORADA",
      },
      {
        CITY: "ALVORADA DO SUL",
      },
      {
        CITY: "AMAMBAI",
      },
      {
        CITY: "AMARAJI",
      },
      {
        CITY: "AMARANTE",
      },
      {
        CITY: "AMARANTE DO MARANHAO",
      },
      {
        CITY: "AMAZONAS",
      },
      {
        CITY: "AMERICA DE CIMA",
      },
      {
        CITY: "AMERICA DOURADA",
      },
      {
        CITY: "AMERICANA",
      },
      {
        CITY: "AMERICO BRASILIENSE",
      },
      {
        CITY: "AMPARO",
      },
      {
        CITY: "ANADIA",
      },
      {
        CITY: "ANAJAS",
      },
      {
        CITY: "ANANINDEUA",
      },
      {
        CITY: "ANCHIETA",
      },
      {
        CITY: "ANDARAI",
      },
      {
        CITY: "ANDRADAS",
      },
      {
        CITY: "ANDRADINA",
      },
      {
        CITY: "ANDRE",
      },
      {
        CITY: "ANDRE DA ROCHA",
      },
      {
        CITY: "ANGATUBA",
      },
      {
        CITY: "ANGICAL DO PIAUI",
      },
      {
        CITY: "ANGICOS",
      },
      {
        CITY: "ANGRA DOS REIS",
      },
      {
        CITY: "ANHEMBI",
      },
      {
        CITY: "ANHUMAS",
      },
      {
        CITY: "ANTAS",
      },
      {
        CITY: "ANTONINA",
      },
      {
        CITY: "ANTONIO CARLOS",
      },
      {
        CITY: "ANTONIO CARLOS",
      },
      {
        CITY: "ANTONIO PRADO",
      },
      {
        CITY: "APARECIDA",
      },
      {
        CITY: "APARECIDA DO RIO NEGRO",
      },
      {
        CITY: "APERIBE",
      },
      {
        CITY: "APIAI",
      },
      {
        CITY: "APIUNA",
      },
      {
        CITY: "APODI",
      },
      {
        CITY: "APORA",
      },
      {
        CITY: "APUCARANA",
      },
      {
        CITY: "APUIARES",
      },
      {
        CITY: "AQUIDABA",
      },
      {
        CITY: "AQUIDAUANA",
      },
      {
        CITY: "AQUIRAZ",
      },
      {
        CITY: "ARACAI",
      },
      {
        CITY: "ARACAJU",
      },
      {
        CITY: "ARACARIGUAMA",
      },
      {
        CITY: "ARACAS",
      },
      {
        CITY: "ARACATI",
      },
      {
        CITY: "ARACATUBA",
      },
      {
        CITY: "ARACI",
      },
      {
        CITY: "ARACOIABA DA SERRA",
      },
      {
        CITY: "ARACRUZ",
      },
      {
        CITY: "ARACUAI",
      },
      {
        CITY: "ARAGARCAS",
      },
      {
        CITY: "ARAGUARI",
      },
      {
        CITY: "ARANDU",
      },
      {
        CITY: "ARAPIRACA",
      },
      {
        CITY: "ARAPONGAS",
      },
      {
        CITY: "ARAPOTI",
      },
      {
        CITY: "ARAPUA",
      },
      {
        CITY: "ARAQUARI",
      },
      {
        CITY: "ARARANGUA",
      },
      {
        CITY: "ARARAQUARA",
      },
      {
        CITY: "ARARAS",
      },
      {
        CITY: "ARARICA",
      },
      {
        CITY: "ARARIPINA",
      },
      {
        CITY: "ARARUAMA",
      },
      {
        CITY: "ARARUNA",
      },
      {
        CITY: "ARAUJOS",
      },
      {
        CITY: "ARCEBURGO",
      },
      {
        CITY: "ARCOS",
      },
      {
        CITY: "ARCOVERDE",
      },
      {
        CITY: "AREADO",
      },
      {
        CITY: "AREAL",
      },
      {
        CITY: "AREALVA",
      },
      {
        CITY: "AREIA BRANCA",
      },
      {
        CITY: "AREIAL",
      },
      {
        CITY: "ARICANDUVA",
      },
      {
        CITY: "ARIQUEMES",
      },
      {
        CITY: "ARMACAO DE BUZIOS",
      },
      {
        CITY: "ARMAZEM",
      },
      {
        CITY: "AROEIRAS",
      },
      {
        CITY: "ARRAIAL",
      },
      {
        CITY: "ARRAIAL DO CABO",
      },
      {
        CITY: "ARROIO DO MEIO",
      },
      {
        CITY: "ARROIO DO SILVA",
      },
      {
        CITY: "ARROIO DO TIGRE",
      },
      {
        CITY: "ARROIO DOS RATOS",
      },
      {
        CITY: "ARROIO GRANDE",
      },
      {
        CITY: "ARTUR NOGUEIRA",
      },
      {
        CITY: "ASCURRA",
      },
      {
        CITY: "ASSIS",
      },
      {
        CITY: "ASSIS CHATEAUBRIAND",
      },
      {
        CITY: "ATIBAIA",
      },
      {
        CITY: "AURIFLAMA",
      },
      {
        CITY: "AVANHANDAVA",
      },
      {
        CITY: "AVARE",
      },
      {
        CITY: "AZURITA",
      },
      {
        CITY: "BADY BASSITT",
      },
      {
        CITY: "BAIA DA TRAICAO",
      },
      {
        CITY: "BAIAO",
      },
      {
        CITY: "BAIRRO DA VELHA CENTRAL",
      },
      {
        CITY: "BAIXO GUANDU",
      },
      {
        CITY: "BALSA NOVA",
      },
      {
        CITY: "BALSAMO",
      },
      {
        CITY: "BALSAS",
      },
      {
        CITY: "BAMBUI",
      },
      {
        CITY: "BANABUIU",
      },
      {
        CITY: "BANANAL",
      },
      {
        CITY: "BANDEIRANTES",
      },
      {
        CITY: "BANGU",
      },
      {
        CITY: "BARAO DE COCAIS",
      },
      {
        CITY: "BARAO DE COTEGIPE",
      },
      {
        CITY: "BARAO DE GRAJAU",
      },
      {
        CITY: "BARAUNA",
      },
      {
        CITY: "BARBACENA",
      },
      {
        CITY: "BARBALHA",
      },
      {
        CITY: "BARBOSA",
      },
      {
        CITY: "BARCARENA",
      },
      {
        CITY: "BARRA",
      },
      {
        CITY: "BARRA",
      },
      {
        CITY: "BARRA BONITA",
      },
      {
        CITY: "BARRA DA TIJUCA",
      },
      {
        CITY: "BARRA DE SAO MIGUEL",
      },
      {
        CITY: "BARRA DO BUGRES",
      },
      {
        CITY: "BARRA DO CORDA",
      },
      {
        CITY: "BARRA DO JACARE",
      },
      {
        CITY: "BARRA DO RIBEIRO",
      },
      {
        CITY: "BARRA MANSA",
      },
      {
        CITY: "BARRA VELHA",
      },
      {
        CITY: "BARRACAO",
      },
      {
        CITY: "BARRAS",
      },
      {
        CITY: "BARREIRAS",
      },
      {
        CITY: "BARRETOS",
      },
      {
        CITY: "BARRINHA",
      },
      {
        CITY: "BARRO",
      },
      {
        CITY: "BARROCAS",
      },
      {
        CITY: "BARUERI",
      },
      {
        CITY: "BATATAIS",
      },
      {
        CITY: "BATISTA",
      },
      {
        CITY: "BATURITE",
      },
      {
        CITY: "BAURU",
      },
      {
        CITY: "BAYEUX",
      },
      {
        CITY: "BEBEDOURO",
      },
      {
        CITY: "BEBERIBE",
      },
      {
        CITY: "BELA VISTA",
      },
      {
        CITY: "BELA VISTA",
      },
      {
        CITY: "BELA VISTA",
      },
      {
        CITY: "BELA VISTA DE GOIAS",
      },
      {
        CITY: "BELA VISTA DO PARAISO",
      },
      {
        CITY: "BELEM",
      },
      {
        CITY: "BELEM",
      },
      {
        CITY: "BELEM DO BREJO DO CRUZ",
      },
      {
        CITY: "BELFORD ROXO",
      },
      {
        CITY: "BELO HORIZONTE",
      },
      {
        CITY: "BELO HORIZONTE",
      },
      {
        CITY: "BELO JARDIM",
      },
      {
        CITY: "BENEDITO NOVO",
      },
      {
        CITY: "BENEVIDES",
      },
      {
        CITY: "BENTO",
      },
      {
        CITY: "BERILO",
      },
      {
        CITY: "BERNARDO",
      },
      {
        CITY: "BERTIOGA",
      },
      {
        CITY: "BETANIA",
      },
      {
        CITY: "BETIM",
      },
      {
        CITY: "BEZERROS",
      },
      {
        CITY: "BICAS",
      },
      {
        CITY: "BIGUACU",
      },
      {
        CITY: "BIRIGUI",
      },
      {
        CITY: "BIRITIBA MIRIM",
      },
      {
        CITY: "BIRITINGA",
      },
      {
        CITY: "BITURUNA",
      },
      {
        CITY: "BLUMENAU",
      },
      {
        CITY: "BOA ESPERANCA",
      },
      {
        CITY: "BOA ESPERANCA",
      },
      {
        CITY: "BOA VENTURA",
      },
      {
        CITY: "BOA VISTA",
      },
      {
        CITY: "BOA VISTA",
      },
      {
        CITY: "BOA VISTA",
      },
      {
        CITY: "BOA VISTA",
      },
      {
        CITY: "BOA VISTA",
      },
      {
        CITY: "BOA VISTA",
      },
      {
        CITY: "BOA VISTA DO BURICA",
      },
      {
        CITY: "BOCAIUVA",
      },
      {
        CITY: "BOCAIUVA DO SUL",
      },
      {
        CITY: "BOITUVA",
      },
      {
        CITY: "BOM CONSELHO",
      },
      {
        CITY: "BOM DESPACHO",
      },
      {
        CITY: "BOM JARDIM",
      },
      {
        CITY: "BOM JARDIM DE MINAS",
      },
      {
        CITY: "BOM JESUS",
      },
      {
        CITY: "BOM JESUS",
      },
      {
        CITY: "BOM JESUS",
      },
      {
        CITY: "BOM JESUS DA LAPA",
      },
      {
        CITY: "BOM JESUS DO AMPARO",
      },
      {
        CITY: "BOM JESUS DO ITABAPOANA",
      },
      {
        CITY: "BOM JESUS DOS PERDOES",
      },
      {
        CITY: "BOM PRINCIPIO",
      },
      {
        CITY: "BOM RETIRO DO SUL",
      },
      {
        CITY: "BOMBINHAS",
      },
      {
        CITY: "BONFIM",
      },
      {
        CITY: "BONITO",
      },
      {
        CITY: "BONITO",
      },
      {
        CITY: "BONITO",
      },
      {
        CITY: "BONITO",
      },
      {
        CITY: "BOQUEIRAO",
      },
      {
        CITY: "BOQUIM",
      },
      {
        CITY: "BORDA DA MATA",
      },
      {
        CITY: "BORRAZOPOLIS",
      },
      {
        CITY: "BOTAFOGO",
      },
      {
        CITY: "BOTAFOGO",
      },
      {
        CITY: "BOTUCATU",
      },
      {
        CITY: "BOTUPORA",
      },
      {
        CITY: "BOTUVERA",
      },
      {
        CITY: "BRACO DO NORTE",
      },
      {
        CITY: "BRAGANCA",
      },
      {
        CITY: "BRAGANCA PAULISTA",
      },
      {
        CITY: "BRANCO",
      },
      {
        CITY: "BRAS",
      },
      {
        CITY: "BRASA",
      },
      {
        CITY: "BRASIL",
      },
      {
        CITY: "BRASILANDIA",
      },
      {
        CITY: "BRASOPOLIS",
      },
      {
        CITY: "BREJETUBA",
      },
      {
        CITY: "BREJINHO DE NAZARE",
      },
      {
        CITY: "BREJO DA MADRE DE DEUS",
      },
      {
        CITY: "BREJO SANTO",
      },
      {
        CITY: "BREVES",
      },
      {
        CITY: "BROCHIER",
      },
      {
        CITY: "BRODOSQUI",
      },
      {
        CITY: "BROTAS",
      },
      {
        CITY: "BRUMADINHO",
      },
      {
        CITY: "BRUMADO",
      },
      {
        CITY: "BRUSQUE",
      },
      {
        CITY: "BUENO BRANDAO",
      },
      {
        CITY: "BUIQUE",
      },
      {
        CITY: "BURITAMA",
      },
      {
        CITY: "BURITI",
      },
      {
        CITY: "BURITI",
      },
      {
        CITY: "BURITIZEIRO",
      },
      {
        CITY: "BUTIA",
      },
      {
        CITY: "BUZIOS",
      },
      {
        CITY: "CAAPORA",
      },
      {
        CITY: "CAARAPO",
      },
      {
        CITY: "CABEDELO",
      },
      {
        CITY: "CABO DE SANTO AGOSTINHO",
      },
      {
        CITY: "CABO FRIO",
      },
      {
        CITY: "CABRALIA",
      },
      {
        CITY: "CABRALIA PAULISTA",
      },
      {
        CITY: "CABREUVA",
      },
      {
        CITY: "CABROBO",
      },
      {
        CITY: "CACAPAVA",
      },
      {
        CITY: "CACAPAVA DO SUL",
      },
      {
        CITY: "CACEQUI",
      },
      {
        CITY: "CACERES",
      },
      {
        CITY: "CACHOEIRA",
      },
      {
        CITY: "CACHOEIRA DO SUL",
      },
      {
        CITY: "CACHOEIRA GRANDE",
      },
      {
        CITY: "CACHOEIRA PAULISTA",
      },
      {
        CITY: "CACHOEIRAS",
      },
      {
        CITY: "CACHOEIRAS DE MACACU",
      },
      {
        CITY: "CACHOEIRINHA",
      },
      {
        CITY: "CACHOEIRO DE ITAPEMIRIM",
      },
      {
        CITY: "CACIMBA DE DENTRO",
      },
      {
        CITY: "CACIQUE DOBLE",
      },
      {
        CITY: "CACOAL",
      },
      {
        CITY: "CAETE",
      },
      {
        CITY: "CAETITE",
      },
      {
        CITY: "CAFARNAUM",
      },
      {
        CITY: "CAFELANDIA",
      },
      {
        CITY: "CAIANA",
      },
      {
        CITY: "CAIAPONIA",
      },
      {
        CITY: "CAIBATE",
      },
      {
        CITY: "CAICO",
      },
      {
        CITY: "CAIEIRAS",
      },
      {
        CITY: "CAIUA",
      },
      {
        CITY: "CAJAMAR",
      },
      {
        CITY: "CAJATI",
      },
      {
        CITY: "CAJAZEIRAS",
      },
      {
        CITY: "CAJOBI",
      },
      {
        CITY: "CAJURU",
      },
      {
        CITY: "CALCADO",
      },
      {
        CITY: "CALCOENE",
      },
      {
        CITY: "CALDAS",
      },
      {
        CITY: "CALDAS NOVAS",
      },
      {
        CITY: "CALIFORNIA",
      },
      {
        CITY: "CAMACARI",
      },
      {
        CITY: "CAMAMU",
      },
      {
        CITY: "CAMANDUCAIA",
      },
      {
        CITY: "CAMAQUA",
      },
      {
        CITY: "CAMARAGIBE",
      },
      {
        CITY: "CAMBE",
      },
      {
        CITY: "CAMBORIU",
      },
      {
        CITY: "CAMBUI",
      },
      {
        CITY: "CAMBUQUIRA",
      },
      {
        CITY: "CAMETA",
      },
      {
        CITY: "CAMPESTRE",
      },
      {
        CITY: "CAMPINA",
      },
      {
        CITY: "CAMPINA GRANDE",
      },
      {
        CITY: "CAMPINA GRANDE DO SUL",
      },
      {
        CITY: "CAMPINAS",
      },
      {
        CITY: "CAMPINAS DO SUL",
      },
      {
        CITY: "CAMPINORTE",
      },
      {
        CITY: "CAMPO",
      },
      {
        CITY: "CAMPO ALEGRE",
      },
      {
        CITY: "CAMPO ALEGRE",
      },
      {
        CITY: "CAMPO BOM",
      },
      {
        CITY: "CAMPO ERE",
      },
      {
        CITY: "CAMPO FORMOSO",
      },
      {
        CITY: "CAMPO GRANDE",
      },
      {
        CITY: "CAMPO GRANDE",
      },
      {
        CITY: "CAMPO LARGO",
      },
      {
        CITY: "CAMPO MAGRO",
      },
      {
        CITY: "CAMPO MAIOR",
      },
      {
        CITY: "CAMPO MOURAO",
      },
      {
        CITY: "CAMPO NOVO",
      },
      {
        CITY: "CAMPO VERDE",
      },
      {
        CITY: "CAMPOS",
      },
      {
        CITY: "CAMPOS",
      },
      {
        CITY: "CAMPOS DO JORDAO",
      },
      {
        CITY: "CAMPOS DOS GOYTACAZES",
      },
      {
        CITY: "CAMPOS GERAIS",
      },
      {
        CITY: "CAMPOS NOVOS",
      },
      {
        CITY: "CAMPOS SALES",
      },
      {
        CITY: "CANARANA",
      },
      {
        CITY: "CANAS",
      },
      {
        CITY: "CANAVIEIRAS",
      },
      {
        CITY: "CANDEAL",
      },
      {
        CITY: "CANDEIAS",
      },
      {
        CITY: "CANDEIAS",
      },
      {
        CITY: "CANDELARIA",
      },
      {
        CITY: "CANDIDO MOTA",
      },
      {
        CITY: "CANDOI",
      },
      {
        CITY: "CANELA",
      },
      {
        CITY: "CANELINHA",
      },
      {
        CITY: "CANGUCU",
      },
      {
        CITY: "CANHOTINHO",
      },
      {
        CITY: "CANINDE",
      },
      {
        CITY: "CANINDE DE SAO FRANCISCO",
      },
      {
        CITY: "CANOAS",
      },
      {
        CITY: "CANOINHAS",
      },
      {
        CITY: "CANSANCAO",
      },
      {
        CITY: "CANTAGALO",
      },
      {
        CITY: "CANTANHEDE",
      },
      {
        CITY: "CAPANEMA",
      },
      {
        CITY: "CAPAO ALTO",
      },
      {
        CITY: "CAPAO BONITO",
      },
      {
        CITY: "CAPAO DA CANOA",
      },
      {
        CITY: "CAPAO DO LEAO",
      },
      {
        CITY: "CAPARAO",
      },
      {
        CITY: "CAPELA",
      },
      {
        CITY: "CAPELA",
      },
      {
        CITY: "CAPELA DO ALTO",
      },
      {
        CITY: "CAPELINHA",
      },
      {
        CITY: "CAPETINGA",
      },
      {
        CITY: "CAPINOPOLIS",
      },
      {
        CITY: "CAPISTRANO",
      },
      {
        CITY: "CAPITAO POCO",
      },
      {
        CITY: "CAPIVARI",
      },
      {
        CITY: "CAPUTIRA",
      },
      {
        CITY: "CARAA",
      },
      {
        CITY: "CARAGUATATUBA",
      },
      {
        CITY: "CARAMBEI",
      },
      {
        CITY: "CARANGOLA",
      },
      {
        CITY: "CARAPEBUS",
      },
      {
        CITY: "CARAPICUIBA",
      },
      {
        CITY: "CARATINGA",
      },
      {
        CITY: "CARAUBAS",
      },
      {
        CITY: "CARAZINHO",
      },
      {
        CITY: "CARDEAL DA SILVA",
      },
      {
        CITY: "CARDOSO",
      },
      {
        CITY: "CARIACICA",
      },
      {
        CITY: "CARINHANHA",
      },
      {
        CITY: "CARIPI",
      },
      {
        CITY: "CARIUS",
      },
      {
        CITY: "CARLOS BARBOSA",
      },
      {
        CITY: "CARMO DA MATA",
      },
      {
        CITY: "CARMO DO CAJURU",
      },
      {
        CITY: "CARMO DO RIO CLARO",
      },
      {
        CITY: "CARMOPOLIS",
      },
      {
        CITY: "CARMOPOLIS DE MINAS",
      },
      {
        CITY: "CARNAIBA",
      },
      {
        CITY: "CAROLINA",
      },
      {
        CITY: "CARPINA",
      },
      {
        CITY: "CARUARU",
      },
      {
        CITY: "CASA NOVA",
      },
      {
        CITY: "CASCA",
      },
      {
        CITY: "CASCAVEL",
      },
      {
        CITY: "CASIMIRO DE ABREU",
      },
      {
        CITY: "CASSIA",
      },
      {
        CITY: "CASSILANDIA",
      },
      {
        CITY: "CASSINO",
      },
      {
        CITY: "CASTANHAL",
      },
      {
        CITY: "CASTELO",
      },
      {
        CITY: "CASTRO",
      },
      {
        CITY: "CATAGUASES",
      },
      {
        CITY: "CATALAO",
      },
      {
        CITY: "CATANDUVA",
      },
      {
        CITY: "CATANDUVAS",
      },
      {
        CITY: "CATARINA",
      },
      {
        CITY: "CATENDE",
      },
      {
        CITY: "CATU",
      },
      {
        CITY: "CAUCAIA",
      },
      {
        CITY: "CAXAMBU",
      },
      {
        CITY: "CAXAMBU",
      },
      {
        CITY: "CAXIAS",
      },
      {
        CITY: "CAXIAS DO SUL",
      },
      {
        CITY: "CEARA MIRIM",
      },
      {
        CITY: "CEDRAL",
      },
      {
        CITY: "CEDRO DO ABAETE",
      },
      {
        CITY: "CEILANDIA",
      },
      {
        CITY: "CENTENARIO DO SUL",
      },
      {
        CITY: "CENTRAL",
      },
      {
        CITY: "CERES",
      },
      {
        CITY: "CERQUEIRA CESAR",
      },
      {
        CITY: "CERQUILHO",
      },
      {
        CITY: "CERRO AZUL",
      },
      {
        CITY: "CERRO BRANCO",
      },
      {
        CITY: "CERRO GRANDE",
      },
      {
        CITY: "CERRO LARGO",
      },
      {
        CITY: "CESARIO LANGE",
      },
      {
        CITY: "CEU AZUL",
      },
      {
        CITY: "CHA GRANDE",
      },
      {
        CITY: "CHAPADA",
      },
      {
        CITY: "CHAPADAO",
      },
      {
        CITY: "CHAPADAO",
      },
      {
        CITY: "CHAPADINHA",
      },
      {
        CITY: "CHARQUEADA",
      },
      {
        CITY: "CHARQUEADAS",
      },
      {
        CITY: "CHAVAL",
      },
      {
        CITY: "CHAVANTES",
      },
      {
        CITY: "CHOPINZINHO",
      },
      {
        CITY: "CIANORTE",
      },
      {
        CITY: "CICERO DANTAS",
      },
      {
        CITY: "CIDADE",
      },
      {
        CITY: "CIDADE",
      },
      {
        CITY: "CIDADE DE DEUS",
      },
      {
        CITY: "CIDADE NOVA",
      },
      {
        CITY: "CIPOTANEA",
      },
      {
        CITY: "CLARO DOS POCOES",
      },
      {
        CITY: "CLAUDIO",
      },
      {
        CITY: "CLEMENTINA",
      },
      {
        CITY: "CLEVELANDIA",
      },
      {
        CITY: "COARI",
      },
      {
        CITY: "COCAL",
      },
      {
        CITY: "COCALINHO",
      },
      {
        CITY: "COCOS",
      },
      {
        CITY: "COELHO",
      },
      {
        CITY: "COLATINA",
      },
      {
        CITY: "COLINA",
      },
      {
        CITY: "COLINAS",
      },
      {
        CITY: "COLINAS",
      },
      {
        CITY: "COLOMBO",
      },
      {
        CITY: "COLORADO",
      },
      {
        CITY: "COMODORO",
      },
      {
        CITY: "CONCEICAO",
      },
      {
        CITY: "CONCEICAO",
      },
      {
        CITY: "CONCEICAO DA BARRA",
      },
      {
        CITY: "CONCEICAO DAS ALAGOAS",
      },
      {
        CITY: "CONCEICAO DE MACABU",
      },
      {
        CITY: "CONCEICAO DO ARAGUAIA",
      },
      {
        CITY: "CONCEICAO DO COITE",
      },
      {
        CITY: "CONCEICAO DO MATO DENTRO",
      },
      {
        CITY: "CONCHAL",
      },
      {
        CITY: "CONCHAS",
      },
      {
        CITY: "CONCORDIA",
      },
      {
        CITY: "CONDADO",
      },
      {
        CITY: "CONDE",
      },
      {
        CITY: "CONDE",
      },
      {
        CITY: "CONGONHAS",
      },
      {
        CITY: "CONSELHEIRO LAFAIETE",
      },
      {
        CITY: "CONTAGEM",
      },
      {
        CITY: "CONTENDA",
      },
      {
        CITY: "CONVENTO DA PENHA",
      },
      {
        CITY: "CORDEIRO",
      },
      {
        CITY: "CORDEIROPOLIS",
      },
      {
        CITY: "CORDILHEIRA",
      },
      {
        CITY: "COREAU",
      },
      {
        CITY: "COREMAS",
      },
      {
        CITY: "CORGUINHO",
      },
      {
        CITY: "CORNELIO PROCOPIO",
      },
      {
        CITY: "COROADOS",
      },
      {
        CITY: "COROAS",
      },
      {
        CITY: "COROATA",
      },
      {
        CITY: "COROMANDEL",
      },
      {
        CITY: "CORONEL",
      },
      {
        CITY: "CORONEL FABRICIANO",
      },
      {
        CITY: "CORREIA PINTO",
      },
      {
        CITY: "CORRENTINA",
      },
      {
        CITY: "CORTES",
      },
      {
        CITY: "CORUMBA DE GOIAS",
      },
      {
        CITY: "CORURIPE",
      },
      {
        CITY: "COSMOPOLIS",
      },
      {
        CITY: "COSMORAMA",
      },
      {
        CITY: "COTIA",
      },
      {
        CITY: "CRATEUS",
      },
      {
        CITY: "CRATO",
      },
      {
        CITY: "CRAVINHOS",
      },
      {
        CITY: "CRISTAIS",
      },
      {
        CITY: "CRISTALINA",
      },
      {
        CITY: "CRISTIANO OTONI",
      },
      {
        CITY: "CRUZ",
      },
      {
        CITY: "CRUZ ALTA",
      },
      {
        CITY: "CRUZ DAS ALMAS",
      },
      {
        CITY: "CRUZEIRO",
      },
      {
        CITY: "CRUZEIRO DA FORTALEZA",
      },
      {
        CITY: "CRUZEIRO DO OESTE",
      },
      {
        CITY: "CRUZEIRO DO SUL",
      },
      {
        CITY: "CRUZEIRO DO SUL",
      },
      {
        CITY: "CRUZILIA",
      },
      {
        CITY: "CUBATAO",
      },
      {
        CITY: "CUITE",
      },
      {
        CITY: "CUNHA PORA",
      },
      {
        CITY: "CUPIRA",
      },
      {
        CITY: "CURIMATA",
      },
      {
        CITY: "CURITIBA",
      },
      {
        CITY: "CURITIBANOS",
      },
      {
        CITY: "CURRAIS NOVOS",
      },
      {
        CITY: "CURURUPU",
      },
      {
        CITY: "CURVELO",
      },
      {
        CITY: "DELFINOPOLIS",
      },
      {
        CITY: "DESCALVADO",
      },
      {
        CITY: "DESCANSO",
      },
      {
        CITY: "DIADEMA",
      },
      {
        CITY: "DIAMANTINA",
      },
      {
        CITY: "DIAMANTINO",
      },
      {
        CITY: "DIAS",
      },
      {
        CITY: "DIONISIO CERQUEIRA",
      },
      {
        CITY: "DIVINOPOLIS",
      },
      {
        CITY: "DIX-SEPT ROSADO",
      },
      {
        CITY: "DOBRADA",
      },
      {
        CITY: "DOIS CORREGOS",
      },
      {
        CITY: "DOIS IRMAOS",
      },
      {
        CITY: "DOIS VIZINHOS",
      },
      {
        CITY: "DOM FELICIANO",
      },
      {
        CITY: "DOM PEDRITO",
      },
      {
        CITY: "DOM SILVERIO",
      },
      {
        CITY: "DOMINGOS MARTINS",
      },
      {
        CITY: "DORES DO INDAIA",
      },
      {
        CITY: "DOURADO",
      },
      {
        CITY: "DOURADOS",
      },
      {
        CITY: "DOUTOR CAMARGO",
      },
      {
        CITY: "DOUTOR MAURICIO CARDOSO",
      },
      {
        CITY: "DRACENA",
      },
      {
        CITY: "DUARTINA",
      },
      {
        CITY: "DUAS BARRAS",
      },
      {
        CITY: "DUMONT",
      },
      {
        CITY: "DUQUE DE CAXIAS",
      },
      {
        CITY: "ELDORADO",
      },
      {
        CITY: "ELIAS FAUSTO",
      },
      {
        CITY: "ELISIARIO",
      },
      {
        CITY: "ELOI MENDES",
      },
      {
        CITY: "EMBU",
      },
      {
        CITY: "EMBU GUACU",
      },
      {
        CITY: "ENCANTADO",
      },
      {
        CITY: "ENCRUZILHADA DO SUL",
      },
      {
        CITY: "ENGENHEIRO BELTRAO",
      },
      {
        CITY: "ENGENHEIRO SCHMIDT",
      },
      {
        CITY: "ENTRE RIOS",
      },
      {
        CITY: "ENTRE RIOS DE MINAS",
      },
      {
        CITY: "ERECHIM",
      },
      {
        CITY: "ERVAL GRANDE",
      },
      {
        CITY: "ERVAL VELHO",
      },
      {
        CITY: "ERVALIA",
      },
      {
        CITY: "ESCADA",
      },
      {
        CITY: "ESMERALDAS",
      },
      {
        CITY: "ESPERA FELIZ",
      },
      {
        CITY: "ESPERANCA",
      },
      {
        CITY: "ESPERANCA",
      },
      {
        CITY: "ESPERANTINA",
      },
      {
        CITY: "ESPINOSA",
      },
      {
        CITY: "ESPIRITO SANTO",
      },
      {
        CITY: "ESPIRITO SANTO DO PINHAL",
      },
      {
        CITY: "ESPUMOSO",
      },
      {
        CITY: "ESTANCIA",
      },
      {
        CITY: "ESTANCIA NOVA",
      },
      {
        CITY: "ESTANCIA VELHA",
      },
      {
        CITY: "ESTEIO",
      },
      {
        CITY: "ESTIVA",
      },
      {
        CITY: "ESTREITO",
      },
      {
        CITY: "ESTRELA",
      },
      {
        CITY: "EUGENIO",
      },
      {
        CITY: "EUGENOPOLIS",
      },
      {
        CITY: "EUSEBIO",
      },
      {
        CITY: "EXTREMA",
      },
      {
        CITY: "EXTREMOZ",
      },
      {
        CITY: "EXU",
      },
      {
        CITY: "FARROUPILHA",
      },
      {
        CITY: "FARTURA",
      },
      {
        CITY: "FATIMA",
      },
      {
        CITY: "FAXINAL",
      },
      {
        CITY: "FAXINAL DO SOTURNO",
      },
      {
        CITY: "FAXINAL DOS GUEDES",
      },
      {
        CITY: "FAZENDA RIO GRANDE",
      },
      {
        CITY: "FEIRA DE SANTANA",
      },
      {
        CITY: "FEIRA NOVA",
      },
      {
        CITY: "FELIZ",
      },
      {
        CITY: "FENIX",
      },
      {
        CITY: "FERNANDO PRESTES",
      },
      {
        CITY: "FERNANDOPOLIS",
      },
      {
        CITY: "FERRAZ DE VASCONCELOS",
      },
      {
        CITY: "FLEXEIRAS",
      },
      {
        CITY: "FLORANIA",
      },
      {
        CITY: "FLORES",
      },
      {
        CITY: "FLORES DA CUNHA",
      },
      {
        CITY: "FLORESTA AZUL",
      },
      {
        CITY: "FLORIANO",
      },
      {
        CITY: "FLORINIA",
      },
      {
        CITY: "FORMIGA",
      },
      {
        CITY: "FORMOSA",
      },
      {
        CITY: "FORQUILHA",
      },
      {
        CITY: "FORQUILHA",
      },
      {
        CITY: "FORQUILHINHA",
      },
      {
        CITY: "FORTALEZA",
      },
      {
        CITY: "FORTALEZA",
      },
      {
        CITY: "FORTALEZA",
      },
      {
        CITY: "FORTUNA",
      },
      {
        CITY: "FOZ DO JORDAO",
      },
      {
        CITY: "FRAGOSO",
      },
      {
        CITY: "FRANCA",
      },
      {
        CITY: "FRANCISCO BELTRAO",
      },
      {
        CITY: "FRANCISCO MORATO",
      },
      {
        CITY: "FRANCISCO SA",
      },
      {
        CITY: "FRANCO DA ROCHA",
      },
      {
        CITY: "FREDERICO WESTPHALEN",
      },
      {
        CITY: "FREI PAULO",
      },
      {
        CITY: "FREITAS",
      },
      {
        CITY: "FRONTEIRA",
      },
      {
        CITY: "FRUTAL",
      },
      {
        CITY: "FUNDAO",
      },
      {
        CITY: "GAMELEIRA",
      },
      {
        CITY: "GANDU",
      },
      {
        CITY: "GARANHUNS",
      },
      {
        CITY: "GARCA",
      },
      {
        CITY: "GARDENIA AZUL",
      },
      {
        CITY: "GARIBALDI",
      },
      {
        CITY: "GARIMPO NOVO",
      },
      {
        CITY: "GAROPABA",
      },
      {
        CITY: "GARUVA",
      },
      {
        CITY: "GASPAR",
      },
      {
        CITY: "GAVIAO PEIXOTO",
      },
      {
        CITY: "GENERAL CARNEIRO",
      },
      {
        CITY: "GENERAL SALGADO",
      },
      {
        CITY: "GETULIO VARGAS",
      },
      {
        CITY: "GIRAU DO PONCIANO",
      },
      {
        CITY: "GLORIA",
      },
      {
        CITY: "GLORIA DO GOITA",
      },
      {
        CITY: "GLORINHA",
      },
      {
        CITY: "GOIANA",
      },
      {
        CITY: "GOIANAPOLIS",
      },
      {
        CITY: "GOIANESIA",
      },
      {
        CITY: "GOIANINHA",
      },
      {
        CITY: "GOIANIRA",
      },
      {
        CITY: "GOIAS",
      },
      {
        CITY: "GOMES",
      },
      {
        CITY: "GONCALVES",
      },
      {
        CITY: "GOVERNADOR VALADARES",
      },
      {
        CITY: "GRACA ARANHA",
      },
      {
        CITY: "GRAJAU",
      },
      {
        CITY: "GRAMADO",
      },
      {
        CITY: "GRANITO",
      },
      {
        CITY: "GRANJA",
      },
      {
        CITY: "GRAO MOGOL",
      },
      {
        CITY: "GROAIRAS",
      },
      {
        CITY: "GUACUI",
      },
      {
        CITY: "GUAI",
      },
      {
        CITY: "GUAIANASES",
      },
      {
        CITY: "GUAICARA",
      },
      {
        CITY: "GUAIRA",
      },
      {
        CITY: "GUAIRA",
      },
      {
        CITY: "GUAJERU",
      },
      {
        CITY: "GUAMARE",
      },
      {
        CITY: "GUANAMBI",
      },
      {
        CITY: "GUANHAES",
      },
      {
        CITY: "GUAPIACU",
      },
      {
        CITY: "GUAPIMIRIM",
      },
      {
        CITY: "GUAPO",
      },
      {
        CITY: "GUAPORE",
      },
      {
        CITY: "GUARA",
      },
      {
        CITY: "GUARA",
      },
      {
        CITY: "GUARABIRA",
      },
      {
        CITY: "GUARACI",
      },
      {
        CITY: "GUARACIABA",
      },
      {
        CITY: "GUARAGI",
      },
      {
        CITY: "GUARAMIRIM",
      },
      {
        CITY: "GUARANESIA",
      },
      {
        CITY: "GUARANI DOESTE",
      },
      {
        CITY: "GUARANTA",
      },
      {
        CITY: "GUARAPARI",
      },
      {
        CITY: "GUARAPUAVA",
      },
      {
        CITY: "GUARARAPES",
      },
      {
        CITY: "GUARARAPES",
      },
      {
        CITY: "GUARAREMA",
      },
      {
        CITY: "GUARATINGUETA",
      },
      {
        CITY: "GUARATUBA",
      },
      {
        CITY: "GUAREI",
      },
      {
        CITY: "GUARIBA",
      },
      {
        CITY: "GUARUJA",
      },
      {
        CITY: "GUARULHOS",
      },
      {
        CITY: "GUAXUPE",
      },
      {
        CITY: "GURJAO",
      },
      {
        CITY: "GURUPI",
      },
      {
        CITY: "HELIODORA",
      },
      {
        CITY: "HELIOPOLIS",
      },
      {
        CITY: "HORIZONTINA",
      },
      {
        CITY: "IACANGA",
      },
      {
        CITY: "IACU",
      },
      {
        CITY: "IBAITI",
      },
      {
        CITY: "IBATE",
      },
      {
        CITY: "IBATIBA",
      },
      {
        CITY: "IBIACA",
      },
      {
        CITY: "IBICOARA",
      },
      {
        CITY: "IBIPEBA",
      },
      {
        CITY: "IBIPORA",
      },
      {
        CITY: "IBIRAMA",
      },
      {
        CITY: "IBIRAREMA",
      },
      {
        CITY: "IBIRATAIA",
      },
      {
        CITY: "IBIRITE",
      },
      {
        CITY: "IBIRUBA",
      },
      {
        CITY: "IBITINGA",
      },
      {
        CITY: "IBIUNA",
      },
      {
        CITY: "ICAPUI",
      },
      {
        CITY: "ICARA",
      },
      {
        CITY: "ICARAI",
      },
      {
        CITY: "ICEM",
      },
      {
        CITY: "ICO",
      },
      {
        CITY: "ICORACI",
      },
      {
        CITY: "IGARACU DO TIETE",
      },
      {
        CITY: "IGARAPAVA",
      },
      {
        CITY: "IGARAPE",
      },
      {
        CITY: "IGARASSU",
      },
      {
        CITY: "IGARATA",
      },
      {
        CITY: "IGREJA NOVA",
      },
      {
        CITY: "IGREJINHA",
      },
      {
        CITY: "IGUABA GRANDE",
      },
      {
        CITY: "IGUAI",
      },
      {
        CITY: "IGUAPE",
      },
      {
        CITY: "IGUARACU",
      },
      {
        CITY: "IGUATU",
      },
      {
        CITY: "IJUI",
      },
      {
        CITY: "ILHA SOLTEIRA",
      },
      {
        CITY: "ILHABELA",
      },
      {
        CITY: "ILHOTA",
      },
      {
        CITY: "IMBITUBA",
      },
      {
        CITY: "IMBITUVA",
      },
      {
        CITY: "IMPERATRIZ",
      },
      {
        CITY: "IMPERATRIZ",
      },
      {
        CITY: "INDAIAL",
      },
      {
        CITY: "INDAIATUBA",
      },
      {
        CITY: "INDIANOPOLIS",
      },
      {
        CITY: "INGA",
      },
      {
        CITY: "INHACORA",
      },
      {
        CITY: "INHANGAPI",
      },
      {
        CITY: "INHAUMA",
      },
      {
        CITY: "INHUMAS",
      },
      {
        CITY: "INOCENCIA",
      },
      {
        CITY: "INUBIA PAULISTA",
      },
      {
        CITY: "IPABA",
      },
      {
        CITY: "IPAMERI",
      },
      {
        CITY: "IPATINGA",
      },
      {
        CITY: "IPAUCU",
      },
      {
        CITY: "IPERO",
      },
      {
        CITY: "IPEUNA",
      },
      {
        CITY: "IPIAU",
      },
      {
        CITY: "IPIRA",
      },
      {
        CITY: "IPIRANGA",
      },
      {
        CITY: "IPOJUCA",
      },
      {
        CITY: "IPORA",
      },
      {
        CITY: "IPU",
      },
      {
        CITY: "IPUA",
      },
      {
        CITY: "IPUBI",
      },
      {
        CITY: "IPUMIRIM",
      },
      {
        CITY: "IRACEMA",
      },
      {
        CITY: "IRACEMAPOLIS",
      },
      {
        CITY: "IRACEMINHA",
      },
      {
        CITY: "IRAI",
      },
      {
        CITY: "IRANDUBA",
      },
      {
        CITY: "IRATI",
      },
      {
        CITY: "IRECE",
      },
      {
        CITY: "IRETAMA",
      },
      {
        CITY: "IRUPI",
      },
      {
        CITY: "ITABAIANA",
      },
      {
        CITY: "ITABAIANA",
      },
      {
        CITY: "ITABAIANINHA",
      },
      {
        CITY: "ITABAPOANA",
      },
      {
        CITY: "ITABERABA",
      },
      {
        CITY: "ITABIRA",
      },
      {
        CITY: "ITABIRINHA DE MANTENA",
      },
      {
        CITY: "ITABIRITO",
      },
      {
        CITY: "ITABORAI",
      },
      {
        CITY: "ITABUNA",
      },
      {
        CITY: "ITACARE",
      },
      {
        CITY: "ITAETE",
      },
      {
        CITY: "ITAGI",
      },
      {
        CITY: "ITAGIBA",
      },
      {
        CITY: "ITAGUACU",
      },
      {
        CITY: "ITAGUAI",
      },
      {
        CITY: "ITAGUATINS",
      },
      {
        CITY: "ITAIPAVA",
      },
      {
        CITY: "ITAIPU",
      },
      {
        CITY: "ITAITINGA",
      },
      {
        CITY: "ITAITUBA",
      },
      {
        CITY: "ITAJOBI",
      },
      {
        CITY: "ITAJUBA",
      },
      {
        CITY: "ITAJUIPE",
      },
      {
        CITY: "ITALVA",
      },
      {
        CITY: "ITAMARACA",
      },
      {
        CITY: "ITAMARATI",
      },
      {
        CITY: "ITAMBARACA",
      },
      {
        CITY: "ITAMBE",
      },
      {
        CITY: "ITAMOGI",
      },
      {
        CITY: "ITAMONTE",
      },
      {
        CITY: "ITANHAEM",
      },
      {
        CITY: "ITANHANDU",
      },
      {
        CITY: "ITANHOMI",
      },
      {
        CITY: "ITAOCARA",
      },
      {
        CITY: "ITAPACI",
      },
      {
        CITY: "ITAPAGIPE",
      },
      {
        CITY: "ITAPARICA",
      },
      {
        CITY: "ITAPE",
      },
      {
        CITY: "ITAPECERICA",
      },
      {
        CITY: "ITAPECERICA DA SERRA",
      },
      {
        CITY: "ITAPECURU MIRIM",
      },
      {
        CITY: "ITAPEJARA",
      },
      {
        CITY: "ITAPEMA",
      },
      {
        CITY: "ITAPERUNA",
      },
      {
        CITY: "ITAPETINGA",
      },
      {
        CITY: "ITAPETININGA",
      },
      {
        CITY: "ITAPEVA",
      },
      {
        CITY: "ITAPEVI",
      },
      {
        CITY: "ITAPICURU",
      },
      {
        CITY: "ITAPIPOCA",
      },
      {
        CITY: "ITAPIRA",
      },
      {
        CITY: "ITAPIRANGA",
      },
      {
        CITY: "ITAPISSUMA",
      },
      {
        CITY: "ITAPIXUNA",
      },
      {
        CITY: "ITAPO",
      },
      {
        CITY: "ITAPOLIS",
      },
      {
        CITY: "ITAPORANGA",
      },
      {
        CITY: "ITAPUA",
      },
      {
        CITY: "ITAPURANGA",
      },
      {
        CITY: "ITAQUAQUECETUBA",
      },
      {
        CITY: "ITAQUI",
      },
      {
        CITY: "ITARANTIM",
      },
      {
        CITY: "ITARARE",
      },
      {
        CITY: "ITATIAIA",
      },
      {
        CITY: "ITATIBA",
      },
      {
        CITY: "ITATINGA",
      },
      {
        CITY: "ITATIRA",
      },
      {
        CITY: "ITAU DE MINAS",
      },
      {
        CITY: "ITAUNA",
      },
      {
        CITY: "ITINGA DA SERRA",
      },
      {
        CITY: "ITIRAPINA",
      },
      {
        CITY: "ITIUBA",
      },
      {
        CITY: "ITOBI",
      },
      {
        CITY: "ITORORO",
      },
      {
        CITY: "ITU",
      },
      {
        CITY: "ITUBERA",
      },
      {
        CITY: "ITUIUTABA",
      },
      {
        CITY: "ITUMBIARA",
      },
      {
        CITY: "ITUPORANGA",
      },
      {
        CITY: "ITURAMA",
      },
      {
        CITY: "ITUVERAVA",
      },
      {
        CITY: "IUNA",
      },
      {
        CITY: "IVAIPORA",
      },
      {
        CITY: "IVOTI",
      },
      {
        CITY: "JABOATAO DOS GUARARAPES",
      },
      {
        CITY: "JABORA",
      },
      {
        CITY: "JABORANDI",
      },
      {
        CITY: "JABOTICABAL",
      },
      {
        CITY: "JABOTICATUBAS",
      },
      {
        CITY: "JACARAU",
      },
      {
        CITY: "JACAREPAGUA",
      },
      {
        CITY: "JACAREZINHO",
      },
      {
        CITY: "JACI",
      },
      {
        CITY: "JACIARA",
      },
      {
        CITY: "JACOBINA",
      },
      {
        CITY: "JACUIZINHO",
      },
      {
        CITY: "JACUPIRANGA",
      },
      {
        CITY: "JACUTINGA",
      },
      {
        CITY: "JAGUAPITA",
      },
      {
        CITY: "JAGUAQUARA",
      },
      {
        CITY: "JAGUARAO",
      },
      {
        CITY: "JAGUARAS",
      },
      {
        CITY: "JAGUARIAIVA",
      },
      {
        CITY: "JAGUARIBE",
      },
      {
        CITY: "JAGUARIPE",
      },
      {
        CITY: "JAGUARIUNA",
      },
      {
        CITY: "JAGUARUANA",
      },
      {
        CITY: "JAGUARUNA",
      },
      {
        CITY: "JAIBA",
      },
      {
        CITY: "JALES",
      },
      {
        CITY: "JAMPRUCA",
      },
      {
        CITY: "JANAUBA",
      },
      {
        CITY: "JANDAIA DO SUL",
      },
      {
        CITY: "JANDIRA",
      },
      {
        CITY: "JAPARATINGA",
      },
      {
        CITY: "JAPERI",
      },
      {
        CITY: "JAPIRA",
      },
      {
        CITY: "JARDIM ALEGRE",
      },
      {
        CITY: "JARDIM DE PIRANHAS",
      },
      {
        CITY: "JARDINOPOLIS",
      },
      {
        CITY: "JARINU",
      },
      {
        CITY: "JARU",
      },
      {
        CITY: "JATAI",
      },
      {
        CITY: "JATAIZINHO",
      },
      {
        CITY: "JEQUITINHONHA",
      },
      {
        CITY: "JERICO",
      },
      {
        CITY: "JERONIMO MONTEIRO",
      },
      {
        CITY: "JESUANIA",
      },
      {
        CITY: "JETIBA",
      },
      {
        CITY: "JI PARANA",
      },
      {
        CITY: "JIQUIRICA",
      },
      {
        CITY: "JOACABA",
      },
      {
        CITY: "JOANOPOLIS",
      },
      {
        CITY: "JOAO CAMARA",
      },
      {
        CITY: "JOAO LISBOA",
      },
      {
        CITY: "JOAO MONLEVADE",
      },
      {
        CITY: "JOAO NEIVA",
      },
      {
        CITY: "JOAO PINHEIRO",
      },
      {
        CITY: "JOAQUIM",
      },
      {
        CITY: "JOAQUIM TAVORA",
      },
      {
        CITY: "JOINVILLE",
      },
      {
        CITY: "JOSE BOITEUX",
      },
      {
        CITY: "JOSE BONIFACIO",
      },
      {
        CITY: "JOSE DE FREITAS",
      },
      {
        CITY: "JUATUBA",
      },
      {
        CITY: "JUAZEIRO",
      },
      {
        CITY: "JUAZEIRO DO NORTE",
      },
      {
        CITY: "JUCARA",
      },
      {
        CITY: "JUCURUTU",
      },
      {
        CITY: "JUIZ DE FORA",
      },
      {
        CITY: "JULIO DE CASTILHOS",
      },
      {
        CITY: "JULIO MESQUITA",
      },
      {
        CITY: "JUMIRIM",
      },
      {
        CITY: "JUNDIAI DO SUL",
      },
      {
        CITY: "JUNQUEIRO",
      },
      {
        CITY: "JUNQUEIROPOLIS",
      },
      {
        CITY: "JUQUIA",
      },
      {
        CITY: "JUQUITIBA",
      },
      {
        CITY: "JURIPIRANGA",
      },
      {
        CITY: "JURU",
      },
      {
        CITY: "JURUAIA",
      },
      {
        CITY: "JURUENA",
      },
      {
        CITY: "JURUTI",
      },
      {
        CITY: "JUSSARA",
      },
      {
        CITY: "JUSSARI",
      },
      {
        CITY: "LADARIO",
      },
      {
        CITY: "LAGARTO",
      },
      {
        CITY: "LAGES",
      },
      {
        CITY: "LAGOA",
      },
      {
        CITY: "LAGOA DA CANOA",
      },
      {
        CITY: "LAGOA DA PRATA",
      },
      {
        CITY: "LAGOA DO CARRO",
      },
      {
        CITY: "LAGOA DOS GATOS",
      },
      {
        CITY: "LAGOA SALGADA",
      },
      {
        CITY: "LAGOA SANTA",
      },
      {
        CITY: "LAGOA SECA",
      },
      {
        CITY: "LAGOA VERMELHA",
      },
      {
        CITY: "LAGUNA",
      },
      {
        CITY: "LAGUNA CARAPA",
      },
      {
        CITY: "LAJEADO",
      },
      {
        CITY: "LAJINHA",
      },
      {
        CITY: "LAMBARI",
      },
      {
        CITY: "LAMBARI",
      },
      {
        CITY: "LAPA",
      },
      {
        CITY: "LAPA",
      },
      {
        CITY: "LARANJAL PAULISTA",
      },
      {
        CITY: "LARANJEIRAS",
      },
      {
        CITY: "LARANJEIRAS DO SUL",
      },
      {
        CITY: "LAURO",
      },
      {
        CITY: "LAVINIA",
      },
      {
        CITY: "LAVRAS",
      },
      {
        CITY: "LAVRAS DA MANGABEIRA",
      },
      {
        CITY: "LAVRAS DO SUL",
      },
      {
        CITY: "LAVRINHAS",
      },
      {
        CITY: "LEBON REGIS",
      },
      {
        CITY: "LEME",
      },
      {
        CITY: "LENCOIS",
      },
      {
        CITY: "LENCOIS PAULISTA",
      },
      {
        CITY: "LEOPOLDINA",
      },
      {
        CITY: "LIBERDADE",
      },
      {
        CITY: "LIMA CAMPOS",
      },
      {
        CITY: "LIMEIRA",
      },
      {
        CITY: "LIMOEIRO",
      },
      {
        CITY: "LIMOEIRO DO NORTE",
      },
      {
        CITY: "LINHARES",
      },
      {
        CITY: "LINS",
      },
      {
        CITY: "LOANDA",
      },
      {
        CITY: "LONDRINA",
      },
      {
        CITY: "LONTRA",
      },
      {
        CITY: "LORENA",
      },
      {
        CITY: "LOUVEIRA",
      },
      {
        CITY: "LUCENA",
      },
      {
        CITY: "LUIS ANTONIO",
      },
      {
        CITY: "LUIS ANTONIO",
      },
      {
        CITY: "LUIS CORREIA",
      },
      {
        CITY: "LUMIAR",
      },
      {
        CITY: "LUMINARIAS",
      },
      {
        CITY: "LUZ",
      },
      {
        CITY: "LUZERNA",
      },
      {
        CITY: "LUZIANIA",
      },
      {
        CITY: "MACAIBA",
      },
      {
        CITY: "MACAPARANA",
      },
      {
        CITY: "MACATUBA",
      },
      {
        CITY: "MACAU",
      },
      {
        CITY: "MACAUBAS",
      },
      {
        CITY: "MACEDONIA",
      },
      {
        CITY: "MACHADINHO",
      },
      {
        CITY: "MACHADO",
      },
      {
        CITY: "MACHADOS",
      },
      {
        CITY: "MACUCO",
      },
      {
        CITY: "MADRE DE DEUS",
      },
      {
        CITY: "MAFRA",
      },
      {
        CITY: "MAGE",
      },
      {
        CITY: "MAIRI",
      },
      {
        CITY: "MAIRINQUE",
      },
      {
        CITY: "MALACACHETA",
      },
      {
        CITY: "MAMANGUAPE",
      },
      {
        CITY: "MAMBORE",
      },
      {
        CITY: "MAMONAS",
      },
      {
        CITY: "MANACAPURU",
      },
      {
        CITY: "MANAUS",
      },
      {
        CITY: "MANDAGUACU",
      },
      {
        CITY: "MANDAGUARI",
      },
      {
        CITY: "MANDIRITUBA",
      },
      {
        CITY: "MANGA",
      },
      {
        CITY: "MANGABEIRA",
      },
      {
        CITY: "MANGARATIBA",
      },
      {
        CITY: "MANGUEIRINHA",
      },
      {
        CITY: "MANHUACU",
      },
      {
        CITY: "MANHUMIRIM",
      },
      {
        CITY: "MANTENA",
      },
      {
        CITY: "MANTENOPOLIS",
      },
      {
        CITY: "MAR DE ESPANHA",
      },
      {
        CITY: "MARACAJU",
      },
      {
        CITY: "MARACANA",
      },
      {
        CITY: "MARACANA",
      },
      {
        CITY: "MARACANAU",
      },
      {
        CITY: "MARAGOGIPE",
      },
      {
        CITY: "MARAIAL",
      },
      {
        CITY: "MARANGUAPE",
      },
      {
        CITY: "MARATAIZES",
      },
      {
        CITY: "MARAU",
      },
      {
        CITY: "MARAVILHA",
      },
      {
        CITY: "MARCELINO RAMOS",
      },
      {
        CITY: "MARCELINO VIEIRA",
      },
      {
        CITY: "MARCO",
      },
      {
        CITY: "MARCOLANDIA",
      },
      {
        CITY: "MARECHAL CANDIDO RONDON",
      },
      {
        CITY: "MARECHAL DEODORO",
      },
      {
        CITY: "MARIA DA FE",
      },
      {
        CITY: "MARIALVA",
      },
      {
        CITY: "MARIANA",
      },
      {
        CITY: "MARICA",
      },
      {
        CITY: "MARILANDIA",
      },
      {
        CITY: "MARILUZ",
      },
      {
        CITY: "MARITUBA",
      },
      {
        CITY: "MARQUES",
      },
      {
        CITY: "MARTINOPOLIS",
      },
      {
        CITY: "MARUIM",
      },
      {
        CITY: "MARUMBI",
      },
      {
        CITY: "MARZAGAO",
      },
      {
        CITY: "MASSAPE",
      },
      {
        CITY: "MASSARANDUBA",
      },
      {
        CITY: "MATAO",
      },
      {
        CITY: "MATELANDIA",
      },
      {
        CITY: "MATEUS LEME",
      },
      {
        CITY: "MATINA",
      },
      {
        CITY: "MATINHOS",
      },
      {
        CITY: "MATIPO",
      },
      {
        CITY: "MATO GROSSO",
      },
      {
        CITY: "MATOZINHOS",
      },
      {
        CITY: "MATUTINA",
      },
      {
        CITY: "MAUA",
      },
      {
        CITY: "MAXARANGUAPE",
      },
      {
        CITY: "MEDEIROS NETO",
      },
      {
        CITY: "MEDIANEIRA",
      },
      {
        CITY: "MEIER",
      },
      {
        CITY: "MENDES",
      },
      {
        CITY: "MENDES PIMENTEL",
      },
      {
        CITY: "MENDONCA",
      },
      {
        CITY: "MESQUITA",
      },
      {
        CITY: "MESSIAS",
      },
      {
        CITY: "MIGUEL PEREIRA",
      },
      {
        CITY: "MIGUELOPOLIS",
      },
      {
        CITY: "MILAGRES",
      },
      {
        CITY: "MINAS",
      },
      {
        CITY: "MINEIROS",
      },
      {
        CITY: "MINEIROS DO TIETE",
      },
      {
        CITY: "MIRACATU",
      },
      {
        CITY: "MIRACEMA",
      },
      {
        CITY: "MIRACEMA DO TOCANTINS",
      },
      {
        CITY: "MIRAIMA",
      },
      {
        CITY: "MIRANDA",
      },
      {
        CITY: "MIRANDA",
      },
      {
        CITY: "MIRANDOPOLIS",
      },
      {
        CITY: "MIRASSOL",
      },
      {
        CITY: "MIRIM DOCE",
      },
      {
        CITY: "MIRINZAL",
      },
      {
        CITY: "MISSAO VELHA",
      },
      {
        CITY: "MOCOCA",
      },
      {
        CITY: "MOEMA",
      },
      {
        CITY: "MOGEIRO",
      },
      {
        CITY: "MOGI DAS CRUZES",
      },
      {
        CITY: "MOGI MIRIM",
      },
      {
        CITY: "MOGI-GAUCU",
      },
      {
        CITY: "MONGAGUA",
      },
      {
        CITY: "MONTE ALEGRE",
      },
      {
        CITY: "MONTE ALEGRE",
      },
      {
        CITY: "MONTE ALTO",
      },
      {
        CITY: "MONTE APRAZIVEL",
      },
      {
        CITY: "MONTE AZUL",
      },
      {
        CITY: "MONTE AZUL PAULISTA",
      },
      {
        CITY: "MONTE BELO",
      },
      {
        CITY: "MONTE CARMELO",
      },
      {
        CITY: "MONTE DOURADO",
      },
      {
        CITY: "MONTE MOR",
      },
      {
        CITY: "MONTE SANTO",
      },
      {
        CITY: "MONTE SIAO",
      },
      {
        CITY: "MONTEIRO",
      },
      {
        CITY: "MONTEIRO LOBATO",
      },
      {
        CITY: "MONTENEGRO",
      },
      {
        CITY: "MONTES ALTOS",
      },
      {
        CITY: "MONTES CLAROS",
      },
      {
        CITY: "MONTIVIDIU",
      },
      {
        CITY: "MORADA NOVA",
      },
      {
        CITY: "MORENO",
      },
      {
        CITY: "MORRETES",
      },
      {
        CITY: "MORRINHOS",
      },
      {
        CITY: "MORRINHOS",
      },
      {
        CITY: "MORRO AGUDO",
      },
      {
        CITY: "MORRO DA FUMACA",
      },
      {
        CITY: "MORRO DE SAO PAULO",
      },
      {
        CITY: "MORRO DO CHAPEU",
      },
      {
        CITY: "MORRO REUTER",
      },
      {
        CITY: "MORUNGABA",
      },
      {
        CITY: "MOSSORO",
      },
      {
        CITY: "MOSTARDAS",
      },
      {
        CITY: "MUCURI",
      },
      {
        CITY: "MULITERNO",
      },
      {
        CITY: "MUNHOZ",
      },
      {
        CITY: "MUNIZ FERREIRA",
      },
      {
        CITY: "MUNIZ FREIRE",
      },
      {
        CITY: "MUQUI",
      },
      {
        CITY: "MURITIBA",
      },
      {
        CITY: "MUTUM",
      },
      {
        CITY: "MUTUM",
      },
      {
        CITY: "MUZAMBINHO",
      },
      {
        CITY: "NANUQUE",
      },
      {
        CITY: "NAO ME TOQUE",
      },
      {
        CITY: "NARANDIBA",
      },
      {
        CITY: "NATAL",
      },
      {
        CITY: "NATERCIA",
      },
      {
        CITY: "NAVEGANTES",
      },
      {
        CITY: "NAVIRAI",
      },
      {
        CITY: "NAZARE",
      },
      {
        CITY: "NAZARE DA MATA",
      },
      {
        CITY: "NAZARE PAULISTA",
      },
      {
        CITY: "NEOPOLIS",
      },
      {
        CITY: "NEROPOLIS",
      },
      {
        CITY: "NILO PECANHA",
      },
      {
        CITY: "NILOPOLIS",
      },
      {
        CITY: "NIQUELANDIA",
      },
      {
        CITY: "NITEROI",
      },
      {
        CITY: "NITEROI",
      },
      {
        CITY: "NOBRES",
      },
      {
        CITY: "NORTE",
      },
      {
        CITY: "NOSSA SENHORA",
      },
      {
        CITY: "NOSSA SENHORA DA GLORIA",
      },
      {
        CITY: "NOSSA SENHORA DAS GRACAS",
      },
      {
        CITY: "NOSSA SENHORA DO SOCORRO",
      },
      {
        CITY: "NOVA",
      },
      {
        CITY: "NOVA",
      },
      {
        CITY: "NOVA ALIANCA",
      },
      {
        CITY: "NOVA ARACA",
      },
      {
        CITY: "NOVA AURORA",
      },
      {
        CITY: "NOVA BASSANO",
      },
      {
        CITY: "NOVA BRESCIA",
      },
      {
        CITY: "NOVA CANAA",
      },
      {
        CITY: "NOVA CRUZ",
      },
      {
        CITY: "NOVA ESPERANCA",
      },
      {
        CITY: "NOVA ESPERANCA",
      },
      {
        CITY: "NOVA FRIBURGO",
      },
      {
        CITY: "NOVA INDEPENDENCIA",
      },
      {
        CITY: "NOVA ITARANA",
      },
      {
        CITY: "NOVA LIMA",
      },
      {
        CITY: "NOVA LONDRINA",
      },
      {
        CITY: "NOVA ODESSA",
      },
      {
        CITY: "NOVA PALMA",
      },
      {
        CITY: "NOVA PETROPOLIS",
      },
      {
        CITY: "NOVA PONTE",
      },
      {
        CITY: "NOVA PRATA",
      },
      {
        CITY: "NOVA RESENDE",
      },
      {
        CITY: "NOVA SERRANA",
      },
      {
        CITY: "NOVA TIMBOTEUA",
      },
      {
        CITY: "NOVA TRENTO",
      },
      {
        CITY: "NOVA VENECIA",
      },
      {
        CITY: "NOVO HAMBURGO",
      },
      {
        CITY: "NOVO HORIZONTE",
      },
      {
        CITY: "NOVO MUNDO",
      },
      {
        CITY: "NOVO ORIENTE",
      },
      {
        CITY: "NOVO RECREIO",
      },
      {
        CITY: "NUCLEO BANDEIRANTE",
      },
      {
        CITY: "NUNES FREIRE",
      },
      {
        CITY: "NUPORANGA",
      },
      {
        CITY: "OCARA",
      },
      {
        CITY: "OEIRAS",
      },
      {
        CITY: "OLIMPIA",
      },
      {
        CITY: "OLINDA",
      },
      {
        CITY: "OLIVEIRA",
      },
      {
        CITY: "ORATORIOS",
      },
      {
        CITY: "ORLANDIA",
      },
      {
        CITY: "ORLEANS",
      },
      {
        CITY: "OROBO",
      },
      {
        CITY: "OSASCO",
      },
      {
        CITY: "OSORIO",
      },
      {
        CITY: "OTACILIO COSTA",
      },
      {
        CITY: "OURICANGAS",
      },
      {
        CITY: "OURICURI",
      },
      {
        CITY: "OURINHOS",
      },
      {
        CITY: "OURO BRANCO",
      },
      {
        CITY: "OURO FINO",
      },
      {
        CITY: "OURO PRETO",
      },
      {
        CITY: "OURO PRETO DO OESTE",
      },
      {
        CITY: "OURO VERDE",
      },
      {
        CITY: "OUVIDOR",
      },
      {
        CITY: "PACAEMBU",
      },
      {
        CITY: "PACATUBA",
      },
      {
        CITY: "PACHECOS",
      },
      {
        CITY: "PADRE",
      },
      {
        CITY: "PAICANDU",
      },
      {
        CITY: "PALESTINA",
      },
      {
        CITY: "PALESTINA",
      },
      {
        CITY: "PALHOCA",
      },
      {
        CITY: "PALMA",
      },
      {
        CITY: "PALMARES",
      },
      {
        CITY: "PALMARES DO SUL",
      },
      {
        CITY: "PALMAS",
      },
      {
        CITY: "PALMAS",
      },
      {
        CITY: "PALMEIRA",
      },
      {
        CITY: "PALMEIRA",
      },
      {
        CITY: "PALMEIRA DAS MISSOES",
      },
      {
        CITY: "PALMEIRA DOS INDIOS",
      },
      {
        CITY: "PALMEIRAS DE GOIAS",
      },
      {
        CITY: "PALMELO",
      },
      {
        CITY: "PALMITAL",
      },
      {
        CITY: "PALMITAL",
      },
      {
        CITY: "PALMITINHO",
      },
      {
        CITY: "PALMITOS",
      },
      {
        CITY: "PALOTINA",
      },
      {
        CITY: "PANAMBI",
      },
      {
        CITY: "PANCAS",
      },
      {
        CITY: "PANORAMA",
      },
      {
        CITY: "PAO DE ACUCAR",
      },
      {
        CITY: "PAPAGAIOS",
      },
      {
        CITY: "PARA DE MINAS",
      },
      {
        CITY: "PARACAMBI",
      },
      {
        CITY: "PARACURU",
      },
      {
        CITY: "PARAGOMINAS",
      },
      {
        CITY: "PARAGUACU",
      },
      {
        CITY: "PARAGUACU PAULISTA",
      },
      {
        CITY: "PARAI",
      },
      {
        CITY: "PARAIBA DO SUL",
      },
      {
        CITY: "PARAIBANO",
      },
      {
        CITY: "PARAIBUNA",
      },
      {
        CITY: "PARAIPABA",
      },
      {
        CITY: "PARAISO",
      },
      {
        CITY: "PARAISO",
      },
      {
        CITY: "PARAISO DO TOCANTINS",
      },
      {
        CITY: "PARAISOPOLIS",
      },
      {
        CITY: "PARAITINGA",
      },
      {
        CITY: "PARANA",
      },
      {
        CITY: "PARANA",
      },
      {
        CITY: "PARANA",
      },
      {
        CITY: "PARANAIBA",
      },
      {
        CITY: "PARANAPANEMA",
      },
      {
        CITY: "PARANAVAI",
      },
      {
        CITY: "PARANOA",
      },
      {
        CITY: "PARAOPEBA",
      },
      {
        CITY: "PARAPEUNA",
      },
      {
        CITY: "PARATY",
      },
      {
        CITY: "PARELHAS",
      },
      {
        CITY: "PARIQUERA ACU",
      },
      {
        CITY: "PARNAIBA",
      },
      {
        CITY: "PARNAMIRIM",
      },
      {
        CITY: "PARNAMIRIM",
      },
      {
        CITY: "PAROBE",
      },
      {
        CITY: "PASSO FUNDO",
      },
      {
        CITY: "PASSOS",
      },
      {
        CITY: "PASTOS BONS",
      },
      {
        CITY: "PATO BRANCO",
      },
      {
        CITY: "PATOS",
      },
      {
        CITY: "PATOS DE MINAS",
      },
      {
        CITY: "PATROCINIO",
      },
      {
        CITY: "PATROCINIO PAULISTA",
      },
      {
        CITY: "PAU BRASIL",
      },
      {
        CITY: "PAU DOS FERROS",
      },
      {
        CITY: "PAUINI",
      },
      {
        CITY: "PAULICEIA",
      },
      {
        CITY: "PAULINIA",
      },
      {
        CITY: "PAULISTA",
      },
      {
        CITY: "PAULISTA",
      },
      {
        CITY: "PAULO AFONSO",
      },
      {
        CITY: "PAULO JACINTO",
      },
      {
        CITY: "PAULO LOPES",
      },
      {
        CITY: "PAVERAMA",
      },
      {
        CITY: "PEABIRU",
      },
      {
        CITY: "PECEM",
      },
      {
        CITY: "PEDERNEIRAS",
      },
      {
        CITY: "PEDRA BONITA",
      },
      {
        CITY: "PEDRA GRANDE",
      },
      {
        CITY: "PEDRANOPOLIS",
      },
      {
        CITY: "PEDREIRA",
      },
      {
        CITY: "PEDREIRA",
      },
      {
        CITY: "PEDRO AFONSO",
      },
      {
        CITY: "PEDRO AVELINO",
      },
      {
        CITY: "PEDRO DE TOLEDO",
      },
      {
        CITY: "PEDRO LEOPOLDO",
      },
      {
        CITY: "PELOTAS",
      },
      {
        CITY: "PENA",
      },
      {
        CITY: "PENAPOLIS",
      },
      {
        CITY: "PENDENCIAS",
      },
      {
        CITY: "PENEDO",
      },
      {
        CITY: "PENHA",
      },
      {
        CITY: "PERDIGAO",
      },
      {
        CITY: "PERDOES",
      },
      {
        CITY: "PEREIRA BARRETO",
      },
      {
        CITY: "PEREIRO",
      },
      {
        CITY: "PERUIBE",
      },
      {
        CITY: "PESQUEIRA",
      },
      {
        CITY: "PETROLINA",
      },
      {
        CITY: "PIABETA",
      },
      {
        CITY: "PICADA",
      },
      {
        CITY: "PICOS",
      },
      {
        CITY: "PICUI",
      },
      {
        CITY: "PIEDADE",
      },
      {
        CITY: "PIEDADE DO RIO GRANDE",
      },
      {
        CITY: "PIEDADE DOS GERAIS",
      },
      {
        CITY: "PIEN",
      },
      {
        CITY: "PILAO ARCADO",
      },
      {
        CITY: "PILAR DO SUL",
      },
      {
        CITY: "PIMENTA",
      },
      {
        CITY: "PIMENTA BUENO",
      },
      {
        CITY: "PINDAMONHANGABA",
      },
      {
        CITY: "PINDOBACU",
      },
      {
        CITY: "PINDORETAMA",
      },
      {
        CITY: "PINHAIS",
      },
      {
        CITY: "PINHALAO",
      },
      {
        CITY: "PINHALZINHO",
      },
      {
        CITY: "PINHEIRAL",
      },
      {
        CITY: "PINHOES",
      },
      {
        CITY: "PIQUET CARNEIRO",
      },
      {
        CITY: "PIRACAIA",
      },
      {
        CITY: "PIRACANJUBA",
      },
      {
        CITY: "PIRACEMA",
      },
      {
        CITY: "PIRACICABA",
      },
      {
        CITY: "PIRAI",
      },
      {
        CITY: "PIRAI DO SUL",
      },
      {
        CITY: "PIRAJU",
      },
      {
        CITY: "PIRAJUBA",
      },
      {
        CITY: "PIRAJUI",
      },
      {
        CITY: "PIRANGI",
      },
      {
        CITY: "PIRAPORA DO BOM JESUS",
      },
      {
        CITY: "PIRAPOZINHO",
      },
      {
        CITY: "PIRAQUARA",
      },
      {
        CITY: "PIRASSUNUNGA",
      },
      {
        CITY: "PIRATININGA",
      },
      {
        CITY: "PIRITIBA",
      },
      {
        CITY: "PITANGA",
      },
      {
        CITY: "PITANGUEIRAS",
      },
      {
        CITY: "PITANGUEIRAS",
      },
      {
        CITY: "PITANGUI",
      },
      {
        CITY: "PIUMA",
      },
      {
        CITY: "PLANALTO",
      },
      {
        CITY: "PLANALTO",
      },
      {
        CITY: "PLATINA",
      },
      {
        CITY: "POA",
      },
      {
        CITY: "POCO BRANCO",
      },
      {
        CITY: "POCO DAS ANTAS",
      },
      {
        CITY: "POCO VERDE",
      },
      {
        CITY: "POJUCA",
      },
      {
        CITY: "POMBAL",
      },
      {
        CITY: "POMERODE",
      },
      {
        CITY: "POMPEIA",
      },
      {
        CITY: "POMPEU",
      },
      {
        CITY: "PONGAI",
      },
      {
        CITY: "PONTA GROSSA",
      },
      {
        CITY: "PONTA PORA",
      },
      {
        CITY: "PONTAL",
      },
      {
        CITY: "PONTAL DO PARANA",
      },
      {
        CITY: "PONTALINA",
      },
      {
        CITY: "PONTE DE ITABAPOANA",
      },
      {
        CITY: "PONTE NOVA",
      },
      {
        CITY: "PONTE SERRADA",
      },
      {
        CITY: "PONTES E LACERDA",
      },
      {
        CITY: "PORANGATU",
      },
      {
        CITY: "PORECATU",
      },
      {
        CITY: "PORTAO",
      },
      {
        CITY: "PORTEIRA DO PINHAL",
      },
      {
        CITY: "PORTO",
      },
      {
        CITY: "PORTO ALEGRE",
      },
      {
        CITY: "PORTO AMAZONAS",
      },
      {
        CITY: "PORTO BELO",
      },
      {
        CITY: "PORTO ESPERIDIAO",
      },
      {
        CITY: "PORTO FELIZ",
      },
      {
        CITY: "PORTO FERREIRA",
      },
      {
        CITY: "PORTO GRANDE",
      },
      {
        CITY: "PORTO NACIONAL",
      },
      {
        CITY: "PORTO REAL",
      },
      {
        CITY: "PORTO SAO JOSE VELHO",
      },
      {
        CITY: "PORTO SEGURO",
      },
      {
        CITY: "PORTO UNIAO",
      },
      {
        CITY: "PORTO VELHO",
      },
      {
        CITY: "PORTO VELHO",
      },
      {
        CITY: "POSSE",
      },
      {
        CITY: "POSTO FISCAL ROLIM DE MOURA",
      },
      {
        CITY: "POTE",
      },
      {
        CITY: "POTIRENDABA",
      },
      {
        CITY: "POUSO ALEGRE",
      },
      {
        CITY: "PRADOPOLIS",
      },
      {
        CITY: "PRAIA GRANDE",
      },
      {
        CITY: "PRATA",
      },
      {
        CITY: "PRATANIA",
      },
      {
        CITY: "PRATAPOLIS",
      },
      {
        CITY: "PRESIDENTE BERNARDES",
      },
      {
        CITY: "PRESIDENTE DUTRA",
      },
      {
        CITY: "PRESIDENTE EPITACIO",
      },
      {
        CITY: "PRESIDENTE GETULIO",
      },
      {
        CITY: "PRESIDENTE KENNEDY",
      },
      {
        CITY: "PRESIDENTE MEDICI",
      },
      {
        CITY: "PRESIDENTE OLEGARIO",
      },
      {
        CITY: "PRESIDENTE PRUDENTE",
      },
      {
        CITY: "PRESIDENTE VENCESLAU",
      },
      {
        CITY: "PRIMAVERA",
      },
      {
        CITY: "PRIMEIRO DE MAIO",
      },
      {
        CITY: "PROGRESSO",
      },
      {
        CITY: "PROGRESSO",
      },
      {
        CITY: "PROMISSAO",
      },
      {
        CITY: "PROPRIA",
      },
      {
        CITY: "PRUDENTOPOLIS",
      },
      {
        CITY: "PUTINGA",
      },
      {
        CITY: "PUXINANA",
      },
      {
        CITY: "QUARAI",
      },
      {
        CITY: "QUATA",
      },
      {
        CITY: "QUATIGUA",
      },
      {
        CITY: "QUATIS",
      },
      {
        CITY: "QUATRO BARRAS",
      },
      {
        CITY: "QUATRO PONTES",
      },
      {
        CITY: "QUEBRANGULO",
      },
      {
        CITY: "QUEIMADAS",
      },
      {
        CITY: "QUEIMADOS",
      },
      {
        CITY: "QUERENCIA",
      },
      {
        CITY: "QUERENCIA DO NORTE",
      },
      {
        CITY: "QUILOMBO",
      },
      {
        CITY: "QUIPAPA",
      },
      {
        CITY: "QUIRINOPOLIS",
      },
      {
        CITY: "QUISSAMA",
      },
      {
        CITY: "QUITANDINHA",
      },
      {
        CITY: "QUIXADA",
      },
      {
        CITY: "QUIXERAMOBIM",
      },
      {
        CITY: "QUIXERE",
      },
      {
        CITY: "RAFARD",
      },
      {
        CITY: "RANCHARIA",
      },
      {
        CITY: "RANCHO QUEIMADO",
      },
      {
        CITY: "RAPOSA (1)",
      },
      {
        CITY: "RAPOSOS",
      },
      {
        CITY: "RAUL SOARES",
      },
      {
        CITY: "REALEZA",
      },
      {
        CITY: "REBOUCAS",
      },
      {
        CITY: "RECANTO",
      },
      {
        CITY: "RECIFE",
      },
      {
        CITY: "REDENCAO",
      },
      {
        CITY: "REDENCAO",
      },
      {
        CITY: "REDUTO",
      },
      {
        CITY: "REGISTRO",
      },
      {
        CITY: "REMIGIO",
      },
      {
        CITY: "RENASCENCA",
      },
      {
        CITY: "REPARTIMENTO",
      },
      {
        CITY: "RESENDE",
      },
      {
        CITY: "RESENDE COSTA",
      },
      {
        CITY: "RESTINGA",
      },
      {
        CITY: "RESTINGA SECA",
      },
      {
        CITY: "RIACHAO DO JACUIPE",
      },
      {
        CITY: "RIACHO DAS ALMAS",
      },
      {
        CITY: "RIACHO FUNDO",
      },
      {
        CITY: "RIACHO FUNDO",
      },
      {
        CITY: "RIALMA",
      },
      {
        CITY: "RIBAS DO RIO PARDO",
      },
      {
        CITY: "RIBEIRA DO POMBAL",
      },
      {
        CITY: "RIBEIRAO",
      },
      {
        CITY: "RIBEIRAO BONITO",
      },
      {
        CITY: "RIBEIRAO CORRENTE",
      },
      {
        CITY: "RIBEIRAO DO PINHAL",
      },
      {
        CITY: "RIBEIRAO PIRES",
      },
      {
        CITY: "RIO ACIMA",
      },
      {
        CITY: "RIO AZUL",
      },
      {
        CITY: "RIO BANANAL",
      },
      {
        CITY: "RIO BONITO",
      },
      {
        CITY: "RIO BONITO",
      },
      {
        CITY: "RIO BRANCO",
      },
      {
        CITY: "RIO BRANCO",
      },
      {
        CITY: "RIO BRANCO DO SUL",
      },
      {
        CITY: "RIO BRILHANTE",
      },
      {
        CITY: "RIO CASCA",
      },
      {
        CITY: "RIO CLARO",
      },
      {
        CITY: "RIO CLARO",
      },
      {
        CITY: "RIO DAS OSTRAS",
      },
      {
        CITY: "RIO DAS PEDRAS",
      },
      {
        CITY: "RIO DE JANEIRO",
      },
      {
        CITY: "RIO DO PIRES",
      },
      {
        CITY: "RIO DO SUL",
      },
      {
        CITY: "RIO DOS CEDROS",
      },
      {
        CITY: "RIO FORMOSO",
      },
      {
        CITY: "RIO GRANDE",
      },
      {
        CITY: "RIO GRANDE DA SERRA",
      },
      {
        CITY: "RIO LARGO",
      },
      {
        CITY: "RIO MANSO",
      },
      {
        CITY: "RIO NEGRINHO",
      },
      {
        CITY: "RIO NEGRO",
      },
      {
        CITY: "RIO NOVO",
      },
      {
        CITY: "RIO NOVO DO SUL",
      },
      {
        CITY: "RIO PARANAIBA",
      },
      {
        CITY: "RIO PARDO",
      },
      {
        CITY: "RIO PARDO DE MINAS",
      },
      {
        CITY: "RIO PIRACICABA",
      },
      {
        CITY: "RIO TINTO",
      },
      {
        CITY: "RIO VERDE DE MATO GROSSO",
      },
      {
        CITY: "ROCA SALES",
      },
      {
        CITY: "RODEIO BONITO",
      },
      {
        CITY: "RODEIRO",
      },
      {
        CITY: "RODRIGUES",
      },
      {
        CITY: "ROLANDIA",
      },
      {
        CITY: "ROLANTE",
      },
      {
        CITY: "RONDA ALTA",
      },
      {
        CITY: "RONDON",
      },
      {
        CITY: "ROSALIA",
      },
      {
        CITY: "ROSARIO",
      },
      {
        CITY: "ROSARIO DO SUL",
      },
      {
        CITY: "ROSEIRA",
      },
      {
        CITY: "RUBIATABA",
      },
      {
        CITY: "RUBIM",
      },
      {
        CITY: "RUSSAS",
      },
      {
        CITY: "SABARA",
      },
      {
        CITY: "SABAUDIA",
      },
      {
        CITY: "SABINOPOLIS",
      },
      {
        CITY: "SACRAMENTO",
      },
      {
        CITY: "SALES",
      },
      {
        CITY: "SALES OLIVEIRA",
      },
      {
        CITY: "SALESOPOLIS",
      },
      {
        CITY: "SALGADO",
      },
      {
        CITY: "SALGUEIRO",
      },
      {
        CITY: "SALINAS DA MARGARIDA",
      },
      {
        CITY: "SALTO",
      },
      {
        CITY: "SALTO",
      },
      {
        CITY: "SALTO DE PIRAPORA",
      },
      {
        CITY: "SALTO GRANDE",
      },
      {
        CITY: "SALVADOR",
      },
      {
        CITY: "SALVADOR",
      },
      {
        CITY: "SALVADOR",
      },
      {
        CITY: "SAMAMBAIA",
      },
      {
        CITY: "SAMPAIO CORREIA",
      },
      {
        CITY: "SANANDUVA",
      },
      {
        CITY: "SANGAO",
      },
      {
        CITY: "SANHARO",
      },
      {
        CITY: "SANTA",
      },
      {
        CITY: "SANTA ADELIA",
      },
      {
        CITY: "SANTA BARBARA",
      },
      {
        CITY: "SANTA BARBARA DO SUL",
      },
      {
        CITY: "SANTA BRANCA",
      },
      {
        CITY: "SANTA CRUZ",
      },
      {
        CITY: "SANTA CRUZ CABRALIA",
      },
      {
        CITY: "SANTA CRUZ DA BAIXA VERDE",
      },
      {
        CITY: "SANTA CRUZ DO CAPIBARIBE",
      },
      {
        CITY: "SANTA CRUZ DO RIO PARDO",
      },
      {
        CITY: "SANTA CRUZ DO SUL",
      },
      {
        CITY: "SANTA FE",
      },
      {
        CITY: "SANTA FE DO SUL",
      },
      {
        CITY: "SANTA GERTRUDES",
      },
      {
        CITY: "SANTA HELENA",
      },
      {
        CITY: "SANTA HELENA",
      },
      {
        CITY: "SANTA INES",
      },
      {
        CITY: "SANTA ISABEL",
      },
      {
        CITY: "SANTA ISABEL DO IVAI",
      },
      {
        CITY: "SANTA ISABEL DO PARA",
      },
      {
        CITY: "SANTA LEOPOLDINA",
      },
      {
        CITY: "SANTA LUCIA",
      },
      {
        CITY: "SANTA LUZIA",
      },
      {
        CITY: "SANTA LUZIA",
      },
      {
        CITY: "SANTA LUZIA",
      },
      {
        CITY: "SANTA LUZIA",
      },
      {
        CITY: "SANTA MARIA",
      },
      {
        CITY: "SANTA MARIA",
      },
      {
        CITY: "SANTA MARIA DA VITORIA",
      },
      {
        CITY: "SANTA MARIA DE ITABIRA",
      },
      {
        CITY: "SANTA RITA",
      },
      {
        CITY: "SANTA RITA DO PASSA QUATRO",
      },
      {
        CITY: "SANTA RITA DO SAPUCAI",
      },
      {
        CITY: "SANTA ROSA",
      },
      {
        CITY: "SANTA TERESA",
      },
      {
        CITY: "SANTA TERESA",
      },
      {
        CITY: "SANTA TERESINHA",
      },
      {
        CITY: "SANTA TEREZINHA",
      },
      {
        CITY: "SANTANA",
      },
      {
        CITY: "SANTANA DA VARGEM",
      },
      {
        CITY: "SANTANA DE PARNAIBA",
      },
      {
        CITY: "SANTANA DO CARIRI",
      },
      {
        CITY: "SANTANA DO IPANEMA",
      },
      {
        CITY: "SANTANA DO LIVRAMENTO",
      },
      {
        CITY: "SANTANA DO MATOS",
      },
      {
        CITY: "SANTANA DO PARAISO",
      },
      {
        CITY: "SANTAREM NOVO",
      },
      {
        CITY: "SANTIAGO",
      },
      {
        CITY: "SANTO",
      },
      {
        CITY: "SANTO ALEIXO",
      },
      {
        CITY: "SANTO AMARO",
      },
      {
        CITY: "SANTO AMARO",
      },
      {
        CITY: "SANTO AMARO DA IMPERATRIZ",
      },
      {
        CITY: "SANTO ANASTACIO",
      },
      {
        CITY: "SANTO ANTONIO",
      },
      {
        CITY: "SANTO ANTONIO DA PATRULHA",
      },
      {
        CITY: "SANTO ANTONIO DA PLATINA",
      },
      {
        CITY: "SANTO ANTONIO DE JESUS",
      },
      {
        CITY: "SANTO ANTONIO DE LISBOA",
      },
      {
        CITY: "SANTO ANTONIO DE PADUA",
      },
      {
        CITY: "SANTO ANTONIO DE POSSE",
      },
      {
        CITY: "SANTO ANTONIO DO AMPARO",
      },
      {
        CITY: "SANTO ANTONIO DO CAIUA",
      },
      {
        CITY: "SANTO ANTONIO DO GRAMA",
      },
      {
        CITY: "SANTO ANTONIO DO JARDIM",
      },
      {
        CITY: "SANTO ANTONIO DO LEVERGER",
      },
      {
        CITY: "SANTO ANTONIO DO MONTE",
      },
      {
        CITY: "SANTO ANTONIO DO PINHAL",
      },
      {
        CITY: "SANTO ANTONIO DO RETIRO",
      },
      {
        CITY: "SANTO ESTEVAO",
      },
      {
        CITY: "SANTO EXPEDITO",
      },
      {
        CITY: "SANTO INACIO",
      },
      {
        CITY: "SANTOS",
      },
      {
        CITY: "SANTOS DUMONT",
      },
      {
        CITY: "SAO BENTO",
      },
      {
        CITY: "SAO BENTO",
      },
      {
        CITY: "SAO BENTO DO SAPUCAI",
      },
      {
        CITY: "SAO BERNARDO",
      },
      {
        CITY: "SAO CAETANO",
      },
      {
        CITY: "SAO CAETANO DO SUL",
      },
      {
        CITY: "SAO CARLOS",
      },
      {
        CITY: "SAO CRISTOVAO",
      },
      {
        CITY: "SAO DOMINGOS",
      },
      {
        CITY: "SAO DOMINGOS",
      },
      {
        CITY: "SAO DOMINGOS",
      },
      {
        CITY: "SAO DOMINGOS",
      },
      {
        CITY: "SAO DOMINGOS",
      },
      {
        CITY: "SAO FELIPE",
      },
      {
        CITY: "SAO FELIX",
      },
      {
        CITY: "SAO FIDELIS",
      },
      {
        CITY: "SAO FRANCISCO",
      },
      {
        CITY: "SAO FRANCISCO",
      },
      {
        CITY: "SAO FRANCISCO DE PAULA",
      },
      {
        CITY: "SAO FRANCISCO DO CONDE",
      },
      {
        CITY: "SAO FRANCISCO DO SUL",
      },
      {
        CITY: "SAO GABRIEL",
      },
      {
        CITY: "SAO GABRIEL",
      },
      {
        CITY: "SAO GABRIEL",
      },
      {
        CITY: "SAO GABRIEL DA CACHOEIRA",
      },
      {
        CITY: "SAO GERALDO DO ARAGUAIA",
      },
      {
        CITY: "SAO GONCALO",
      },
      {
        CITY: "SAO GONCALO DO AMARANTE",
      },
      {
        CITY: "SAO GONCALO DO PARA",
      },
      {
        CITY: "SAO GONCALO DO SAPUCAI",
      },
      {
        CITY: "SAO GONCALO DOS CAMPOS",
      },
      {
        CITY: "SAO GOTARDO",
      },
      {
        CITY: "SAO JERONIMO",
      },
      {
        CITY: "SAO JOAO",
      },
      {
        CITY: "SAO JOAO BATISTA",
      },
      {
        CITY: "SAO JOAO DA BARRA",
      },
      {
        CITY: "SAO JOAO DA BOA VISTA",
      },
      {
        CITY: "SAO JOAO DE MERITI",
      },
      {
        CITY: "SAO JOAO DE PIRABAS",
      },
      {
        CITY: "SAO JOAO DO PARAISO",
      },
      {
        CITY: "SAO JOAO EVANGELISTA",
      },
      {
        CITY: "SAO JOAO NEPOMUCENO",
      },
      {
        CITY: "SAO JOAQUIM",
      },
      {
        CITY: "SAO JOAQUIM DA BARRA",
      },
      {
        CITY: "SAO JOAQUIM DE BICAS",
      },
      {
        CITY: "SAO JOAQUIM DO MONTE",
      },
      {
        CITY: "SAO JOSE",
      },
      {
        CITY: "SAO JOSE",
      },
      {
        CITY: "SAO JOSE",
      },
      {
        CITY: "SAO JOSE DA BARRA",
      },
      {
        CITY: "SAO JOSE DA LAJE",
      },
      {
        CITY: "SAO JOSE DE MIPIBU",
      },
      {
        CITY: "SAO JOSE DE PIRANHAS",
      },
      {
        CITY: "SAO JOSE DE RIBAMAR",
      },
      {
        CITY: "SAO JOSE DO CALCADO",
      },
      {
        CITY: "SAO JOSE DO CEDRO",
      },
      {
        CITY: "SAO JOSE DO EGITO",
      },
      {
        CITY: "SAO JOSE DO GOIABAL",
      },
      {
        CITY: "SAO JOSE DO INHACORA",
      },
      {
        CITY: "SAO JOSE DO OURO",
      },
      {
        CITY: "SAO JOSE DO RIO PARDO",
      },
      {
        CITY: "SAO JOSE DO RIO PRETO",
      },
      {
        CITY: "SAO LOURENCO DA MATA",
      },
      {
        CITY: "SAO LOURENCO DA SERRA",
      },
      {
        CITY: "SAO LOURENCO DO SUL",
      },
      {
        CITY: "SAO LOURENCO DOESTE",
      },
      {
        CITY: "SAO LUIS",
      },
      {
        CITY: "SAO LUIS DE MONTES BELOS",
      },
      {
        CITY: "SAO LUIS DO PARAITINGA",
      },
      {
        CITY: "SAO LUIZ GONZAGA",
      },
      {
        CITY: "SAO MANOEL",
      },
      {
        CITY: "SAO MANUEL",
      },
      {
        CITY: "SAO MARCOS",
      },
      {
        CITY: "SAO MIGUEL",
      },
      {
        CITY: "SAO MIGUEL",
      },
      {
        CITY: "SAO MIGUEL ARCANJO",
      },
      {
        CITY: "SAO MIGUEL DO ARAGUAIA",
      },
      {
        CITY: "SAO MIGUEL DO IGUACU",
      },
      {
        CITY: "SAO MIGUEL DOS CAMPOS",
      },
      {
        CITY: "SAO PAULO",
      },
      {
        CITY: "SAO PAULO",
      },
      {
        CITY: "SAO PAULO DO POTENGI",
      },
      {
        CITY: "SAO PEDRO",
      },
      {
        CITY: "SAO PEDRO DA ALDEIA",
      },
      {
        CITY: "SAO PEDRO DO IVAI",
      },
      {
        CITY: "SAO PEDRO DO SUL",
      },
      {
        CITY: "SAO PEDRO DOS FERROS",
      },
      {
        CITY: "SAO ROQUE",
      },
      {
        CITY: "SAO ROQUE",
      },
      {
        CITY: "SAO SALVADOR",
      },
      {
        CITY: "SAO SEBASTIAO",
      },
      {
        CITY: "SAO SEBASTIAO",
      },
      {
        CITY: "SAO SEBASTIAO",
      },
      {
        CITY: "SAO SEBASTIAO",
      },
      {
        CITY: "SAO SEBASTIAO",
      },
      {
        CITY: "SAO SEBASTIAO DA AMOREIRA",
      },
      {
        CITY: "SAO SEBASTIAO DA GRAMA",
      },
      {
        CITY: "SAO SEBASTIAO DO CAI",
      },
      {
        CITY: "SAO SEBASTIAO DO MARANHAO",
      },
      {
        CITY: "SAO SEBASTIAO DO PARAISO",
      },
      {
        CITY: "SAO SEPE",
      },
      {
        CITY: "SAO SIMAO",
      },
      {
        CITY: "SAO TOME",
      },
      {
        CITY: "SAO VICENTE",
      },
      {
        CITY: "SAO VICENTE",
      },
      {
        CITY: "SAO VICENTE DE PAULA",
      },
      {
        CITY: "SAPE",
      },
      {
        CITY: "SAPEACU",
      },
      {
        CITY: "SAPIRANGA",
      },
      {
        CITY: "SAPUCAIA",
      },
      {
        CITY: "SAPUCAIA",
      },
      {
        CITY: "SAPUCAIA DO SUL",
      },
      {
        CITY: "SAQUAREMA",
      },
      {
        CITY: "SARANDI",
      },
      {
        CITY: "SARANDI",
      },
      {
        CITY: "SARAPUI",
      },
      {
        CITY: "SARDOA",
      },
      {
        CITY: "SARZEDO",
      },
      {
        CITY: "SATUBA",
      },
      {
        CITY: "SAUDADES",
      },
      {
        CITY: "SCHROEDER",
      },
      {
        CITY: "SEABRA",
      },
      {
        CITY: "SEARA",
      },
      {
        CITY: "SEBASTIANOPOLIS DO SUL",
      },
      {
        CITY: "SEBERI",
      },
      {
        CITY: "SEDE NOVA",
      },
      {
        CITY: "SEM PEIXE",
      },
      {
        CITY: "SENADOR CANEDO",
      },
      {
        CITY: "SENHOR DO BONFIM",
      },
      {
        CITY: "SERAFINA",
      },
      {
        CITY: "SEROPEDICA",
      },
      {
        CITY: "SERRA",
      },
      {
        CITY: "SERRA",
      },
      {
        CITY: "SERRA",
      },
      {
        CITY: "SERRA",
      },
      {
        CITY: "SERRA AZUL",
      },
      {
        CITY: "SERRA NEGRA",
      },
      {
        CITY: "SERRA PRETA",
      },
      {
        CITY: "SERRA TALHADA",
      },
      {
        CITY: "SERRANA",
      },
      {
        CITY: "SERRANIA",
      },
      {
        CITY: "SERRINHA",
      },
      {
        CITY: "SERRO",
      },
      {
        CITY: "SERTANIA",
      },
      {
        CITY: "SERTANOPOLIS",
      },
      {
        CITY: "SERTAO",
      },
      {
        CITY: "SERTAOZINHO",
      },
      {
        CITY: "SETE LAGOAS",
      },
      {
        CITY: "SIDROLANDIA",
      },
      {
        CITY: "SILVA JARDIM",
      },
      {
        CITY: "SILVEIRAS",
      },
      {
        CITY: "SIMAO DIAS",
      },
      {
        CITY: "SIMAO PEREIRA",
      },
      {
        CITY: "SIMOES",
      },
      {
        CITY: "SIMPLICIO MENDES",
      },
      {
        CITY: "SIQUEIRA CAMPOS",
      },
      {
        CITY: "SITIO FIGUEIRA",
      },
      {
        CITY: "SITIO NOVO",
      },
      {
        CITY: "SOBRADINHO",
      },
      {
        CITY: "SOBRAL",
      },
      {
        CITY: "SOCORRO",
      },
      {
        CITY: "SOLEDADE",
      },
      {
        CITY: "SOMBRIO",
      },
      {
        CITY: "SOROCABA",
      },
      {
        CITY: "SORRISO",
      },
      {
        CITY: "SOUSA",
      },
      {
        CITY: "STIO OURO FINO",
      },
      {
        CITY: "SULINA",
      },
      {
        CITY: "SUMARE",
      },
      {
        CITY: "SUZANO",
      },
      {
        CITY: "TABATINGA",
      },
      {
        CITY: "TABIRA",
      },
      {
        CITY: "TABOAO DA SERRA",
      },
      {
        CITY: "TABULEIRO DO NORTE",
      },
      {
        CITY: "TAGUATINGA",
      },
      {
        CITY: "TAGUATINGA",
      },
      {
        CITY: "TAIACU",
      },
      {
        CITY: "TAIOBEIRAS",
      },
      {
        CITY: "TAIUVA",
      },
      {
        CITY: "TAMANDARE",
      },
      {
        CITY: "TAMBAU",
      },
      {
        CITY: "TAMBORIL",
      },
      {
        CITY: "TAMOIOS",
      },
      {
        CITY: "TANABI",
      },
      {
        CITY: "TANGUA",
      },
      {
        CITY: "TANQUE NOVO",
      },
      {
        CITY: "TAPEJARA",
      },
      {
        CITY: "TAPIRA",
      },
      {
        CITY: "TAPIRAI",
      },
      {
        CITY: "TAQUARA",
      },
      {
        CITY: "TAQUARAL",
      },
      {
        CITY: "TAQUARANA",
      },
      {
        CITY: "TAQUARI",
      },
      {
        CITY: "TAQUARITINGA",
      },
      {
        CITY: "TARUMIRIM",
      },
      {
        CITY: "TATUAMUNHA",
      },
      {
        CITY: "TAUA",
      },
      {
        CITY: "TAUBATE",
      },
      {
        CITY: "TEIXEIRA",
      },
      {
        CITY: "TEIXEIRA",
      },
      {
        CITY: "TELEMACO BORBA",
      },
      {
        CITY: "TENENTE ANANIAS",
      },
      {
        CITY: "TENORIO",
      },
      {
        CITY: "TEODORO SAMPAIO",
      },
      {
        CITY: "TERENOS",
      },
      {
        CITY: "TERESINA",
      },
      {
        CITY: "TERESOPOLIS",
      },
      {
        CITY: "TERRA BOA",
      },
      {
        CITY: "TERRA NOVA",
      },
      {
        CITY: "TERRA NOVA",
      },
      {
        CITY: "TERRA RICA",
      },
      {
        CITY: "TERRA ROXA",
      },
      {
        CITY: "TERRA ROXA",
      },
      {
        CITY: "TEUTONIA",
      },
      {
        CITY: "TIANGUA",
      },
      {
        CITY: "TIBAGI",
      },
      {
        CITY: "TIBAU",
      },
      {
        CITY: "TIETE",
      },
      {
        CITY: "TIJUCA",
      },
      {
        CITY: "TIJUCAS",
      },
      {
        CITY: "TIMBAUBA",
      },
      {
        CITY: "TIMBE",
      },
      {
        CITY: "TIMBO",
      },
      {
        CITY: "TIMON",
      },
      {
        CITY: "TIMOTEO",
      },
      {
        CITY: "TIRADENTES",
      },
      {
        CITY: "TOBIAS BARRETO",
      },
      {
        CITY: "TOCANTINS",
      },
      {
        CITY: "TOLEDO",
      },
      {
        CITY: "TOMAR DO GERU",
      },
      {
        CITY: "TOME ACU",
      },
      {
        CITY: "TORITAMA",
      },
      {
        CITY: "TORRES",
      },
      {
        CITY: "TOUROS",
      },
      {
        CITY: "TRAMANDAI",
      },
      {
        CITY: "TREMEMBE",
      },
      {
        CITY: "TRES BARRAS",
      },
      {
        CITY: "TRES CACHOEIRAS",
      },
      {
        CITY: "TRES COROAS",
      },
      {
        CITY: "TRES MARIAS",
      },
      {
        CITY: "TRES PASSOS",
      },
      {
        CITY: "TRES PONTAS",
      },
      {
        CITY: "TRES RIOS",
      },
      {
        CITY: "TRINDADE",
      },
      {
        CITY: "TRINDADE",
      },
      {
        CITY: "TRIUNFO",
      },
      {
        CITY: "TUBARAO",
      },
      {
        CITY: "TUCUM",
      },
      {
        CITY: "TUCUNDUVA",
      },
      {
        CITY: "TUCURUI",
      },
      {
        CITY: "TUIUTI",
      },
      {
        CITY: "TUMIRITINGA",
      },
      {
        CITY: "TUPA",
      },
      {
        CITY: "TUPACIGUARA",
      },
      {
        CITY: "TUPANATINGA",
      },
      {
        CITY: "TUPANCIRETA",
      },
      {
        CITY: "TUPARENDI",
      },
      {
        CITY: "TUPI PAULISTA",
      },
      {
        CITY: "TURMALINA",
      },
      {
        CITY: "TURVO",
      },
      {
        CITY: "TURVO",
      },
      {
        CITY: "UAUA",
      },
      {
        CITY: "UBA",
      },
      {
        CITY: "UBAIRA",
      },
      {
        CITY: "UBAITABA",
      },
      {
        CITY: "UBARANA",
      },
      {
        CITY: "UBATA",
      },
      {
        CITY: "UBATUBA",
      },
      {
        CITY: "UBERABA",
      },
      {
        CITY: "UBERLANDIA",
      },
      {
        CITY: "UCHOA",
      },
      {
        CITY: "UIBAI",
      },
      {
        CITY: "UIRAUNA",
      },
      {
        CITY: "UMBAUBA",
      },
      {
        CITY: "UMBUZEIRO",
      },
      {
        CITY: "UMIRIM",
      },
      {
        CITY: "UMUARAMA",
      },
      {
        CITY: "UNAI",
      },
      {
        CITY: "UNIAO DA VITORIA",
      },
      {
        CITY: "URAI",
      },
      {
        CITY: "URANIA",
      },
      {
        CITY: "URUBICI",
      },
      {
        CITY: "URUBURETAMA",
      },
      {
        CITY: "URUCANIA",
      },
      {
        CITY: "URUCUCA",
      },
      {
        CITY: "URUGUAIANA",
      },
      {
        CITY: "URUPES",
      },
      {
        CITY: "URUSSANGA",
      },
      {
        CITY: "VACARIA",
      },
      {
        CITY: "VALENCA",
      },
      {
        CITY: "VALENTE",
      },
      {
        CITY: "VALENTIM GENTIL",
      },
      {
        CITY: "VALERIO",
      },
      {
        CITY: "VALINHOS",
      },
      {
        CITY: "VALPARAISO",
      },
      {
        CITY: "VARGEM ALTA",
      },
      {
        CITY: "VARGEM GRANDE DO SUL",
      },
      {
        CITY: "VARGEM GRANDE PAULISTA",
      },
      {
        CITY: "VARGINHA",
      },
      {
        CITY: "VARJOTA",
      },
      {
        CITY: "VARZEA",
      },
      {
        CITY: "VARZEA PAULISTA",
      },
      {
        CITY: "VARZELANDIA",
      },
      {
        CITY: "VASSOURAS",
      },
      {
        CITY: "VELHA",
      },
      {
        CITY: "VENANCIO AIRES",
      },
      {
        CITY: "VENDA NOVA",
      },
      {
        CITY: "VERA CRUZ",
      },
      {
        CITY: "VERA CRUZ",
      },
      {
        CITY: "VERA CRUZ",
      },
      {
        CITY: "VERA CRUZ",
      },
      {
        CITY: "VERANOPOLIS",
      },
      {
        CITY: "VESPASIANO",
      },
      {
        CITY: "VESPASIANO CORREA",
      },
      {
        CITY: "VIANA",
      },
      {
        CITY: "VICENCIA",
      },
      {
        CITY: "VICENTE",
      },
      {
        CITY: "VICOSA",
      },
      {
        CITY: "VICOSA",
      },
      {
        CITY: "VICOSA DO CEARA",
      },
      {
        CITY: "VIDEIRA",
      },
      {
        CITY: "VILA MARIA",
      },
      {
        CITY: "VILA MURIQUI",
      },
      {
        CITY: "VILA RICA",
      },
      {
        CITY: "VILA VELHA",
      },
      {
        CITY: "VILAR DOS TELES",
      },
      {
        CITY: "VILHENA",
      },
      {
        CITY: "VINHEDO",
      },
      {
        CITY: "VIRGEM DA LAPA",
      },
      {
        CITY: "VIRGINIA",
      },
      {
        CITY: "VIRGINOPOLIS",
      },
      {
        CITY: "VIRMOND",
      },
      {
        CITY: "VISCONDE DO RIO BRANCO",
      },
      {
        CITY: "VISTA ALEGRE DO ALTO",
      },
      {
        CITY: "VITORIA DE SANTO ANTAO",
      },
      {
        CITY: "VITORINO FREIRE",
      },
      {
        CITY: "VOLTA GRANDE",
      },
      {
        CITY: "VOLTA REDONDA",
      },
      {
        CITY: "VOTORANTIM",
      },
      {
        CITY: "VOTUPORANGA",
      },
      {
        CITY: "WENCESLAU BRAZ",
      },
      {
        CITY: "WITMARSUM",
      },
      {
        CITY: "XANXERE",
      },
      {
        CITY: "XAVANTINA",
      },
      {
        CITY: "XAXIM",
      },
    ],
  },
  {
    COUNTRY: "CAMEROON",
    CODE: "CM",
    CITIES: [
      {
        CITY: "BAFIA",
      },
      {
        CITY: "BAFOUSSAM",
      },
      {
        CITY: "BAMENDA",
      },
      {
        CITY: "BUEA",
      },
      {
        CITY: "DOUALA",
      },
      {
        CITY: "KRIBI",
      },
      {
        CITY: "KUMBA",
      },
      {
        CITY: "RINGO",
      },
      {
        CITY: "TIBATI",
      },
    ],
  },
  {
    COUNTRY: "BELIZE",
    CODE: "BZ",
    CITIES: [
      {
        CITY: "BELIZE CITY",
      },
      {
        CITY: "BELMOPAN",
      },
      {
        CITY: "BENQUE VIEJO DEL CARMEN",
      },
      {
        CITY: "FREETOWN SIBUN",
      },
      {
        CITY: "LADYVILLE",
      },
      {
        CITY: "SAN IGNACIO",
      },
      {
        CITY: "SAN PEDRO TOWN",
      },
    ],
  },
  {
    COUNTRY: "BULGARIA",
    CODE: "BG",
    CITIES: [
      {
        CITY: "AKHTOPOL",
      },
      {
        CITY: "AKSAKOVO",
      },
      {
        CITY: "ALEKSANDRIYA",
      },
      {
        CITY: "ALFATAR",
      },
      {
        CITY: "ANTON",
      },
      {
        CITY: "ANTONOVO",
      },
      {
        CITY: "ARDINO",
      },
      {
        CITY: "ASENOVGRAD",
      },
      {
        CITY: "AYTOS",
      },
      {
        CITY: "BABOVO",
      },
      {
        CITY: "BALTCHIK",
      },
      {
        CITY: "BANITE",
      },
      {
        CITY: "BANKYA",
      },
      {
        CITY: "BANSKO",
      },
      {
        CITY: "BARUTIN",
      },
      {
        CITY: "BATAK",
      },
      {
        CITY: "BELENE",
      },
      {
        CITY: "BELOGRADCHIK",
      },
      {
        CITY: "BELOSLAV",
      },
      {
        CITY: "BELOVO",
      },
      {
        CITY: "BERKOVITSA",
      },
      {
        CITY: "BISTRITSA",
      },
      {
        CITY: "BLAGOEVGRAD",
      },
      {
        CITY: "BOBOSHEVO",
      },
      {
        CITY: "BOBOVDOL",
      },
      {
        CITY: "BOROVAN",
      },
      {
        CITY: "BOROVO",
      },
      {
        CITY: "BOTEVGRAD",
      },
      {
        CITY: "BRATSIGOVO",
      },
      {
        CITY: "BREGARE",
      },
      {
        CITY: "BUKOVO",
      },
      {
        CITY: "BUKOVO",
      },
      {
        CITY: "BURGAS",
      },
      {
        CITY: "BYALA",
      },
      {
        CITY: "BYALA",
      },
      {
        CITY: "BYALA SLATINA",
      },
      {
        CITY: "CHAKALAROVO",
      },
      {
        CITY: "CHERNOLIK",
      },
      {
        CITY: "CHERNOMORETS",
      },
      {
        CITY: "CHERVEN",
      },
      {
        CITY: "CHERVEN BRYAG",
      },
      {
        CITY: "CHIPROVTSI",
      },
      {
        CITY: "CHIRPAN",
      },
      {
        CITY: "CHOBA",
      },
      {
        CITY: "DEBELETS",
      },
      {
        CITY: "DEVIN",
      },
      {
        CITY: "DIMITROVGRAD",
      },
      {
        CITY: "DOBRICH",
      },
      {
        CITY: "DOLNA BANYA",
      },
      {
        CITY: "DOLNA MITROPOLIYA",
      },
      {
        CITY: "DOLNA ORYAKHOVITSA",
      },
      {
        CITY: "DOSPAT",
      },
      {
        CITY: "DRAGASH VOYVODA",
      },
      {
        CITY: "DRAGINOVO",
      },
      {
        CITY: "DRAGIZHEVO",
      },
      {
        CITY: "DRYANOVO",
      },
      {
        CITY: "DULGOPOL",
      },
      {
        CITY: "DULOVO",
      },
      {
        CITY: "DUPNITSA",
      },
      {
        CITY: "ELENA",
      },
      {
        CITY: "ELIN PELIN",
      },
      {
        CITY: "ELKHOVO",
      },
      {
        CITY: "ETROPOLE",
      },
      {
        CITY: "GABROVO",
      },
      {
        CITY: "GANCHOVO",
      },
      {
        CITY: "GENERAL-TOSHEVO",
      },
      {
        CITY: "GLOGOVO",
      },
      {
        CITY: "GODECH",
      },
      {
        CITY: "GORNA ORYAKHOVITSA",
      },
      {
        CITY: "GOTSE DELCHEV",
      },
      {
        CITY: "GULUBOVO",
      },
      {
        CITY: "GURKOVO",
      },
      {
        CITY: "GURMAZOVO",
      },
      {
        CITY: "HASKOVO",
      },
      {
        CITY: "IKHTIMAN",
      },
      {
        CITY: "ISKRETS",
      },
      {
        CITY: "ISPERIKH",
      },
      {
        CITY: "ISPERIKHOVO",
      },
      {
        CITY: "IVAYLOVGRAD",
      },
      {
        CITY: "KABLESHKOVO",
      },
      {
        CITY: "KAMENOVO",
      },
      {
        CITY: "KARDZHALI",
      },
      {
        CITY: "KARLOVO",
      },
      {
        CITY: "KARNOBAT",
      },
      {
        CITY: "KAVARNA",
      },
      {
        CITY: "KAZANLAK",
      },
      {
        CITY: "KAZASHKA REKA",
      },
      {
        CITY: "KHARMANLI",
      },
      {
        CITY: "KHISARYA",
      },
      {
        CITY: "KNEZHA",
      },
      {
        CITY: "KONUSH",
      },
      {
        CITY: "KOPRIVETS",
      },
      {
        CITY: "KOPRIVLEN",
      },
      {
        CITY: "KOSTENETS",
      },
      {
        CITY: "KOSTIEVO",
      },
      {
        CITY: "KOSTINBROD",
      },
      {
        CITY: "KOVACHITE",
      },
      {
        CITY: "KOZLODUY",
      },
      {
        CITY: "KRAN",
      },
      {
        CITY: "KRICHIM",
      },
      {
        CITY: "KRIVODOL",
      },
      {
        CITY: "KRUMOVGRAD",
      },
      {
        CITY: "KRUPNIK",
      },
      {
        CITY: "KULA",
      },
      {
        CITY: "KYUSTENDIL",
      },
      {
        CITY: "LOM",
      },
      {
        CITY: "LOVECH",
      },
      {
        CITY: "LOZNO",
      },
      {
        CITY: "LUKOVIT",
      },
      {
        CITY: "LYASKOVETS",
      },
      {
        CITY: "LYULYAKOVO",
      },
      {
        CITY: "MADAN",
      },
      {
        CITY: "MALO KONARE",
      },
      {
        CITY: "MARIKOSTINOVO",
      },
      {
        CITY: "MARKOVO",
      },
      {
        CITY: "MESTA",
      },
      {
        CITY: "MEZDRA",
      },
      {
        CITY: "MIKREVO",
      },
      {
        CITY: "MODERNO PREDGRADIE",
      },
      {
        CITY: "MOMCHILGRAD",
      },
      {
        CITY: "MONTANA",
      },
      {
        CITY: "MORAVKA",
      },
      {
        CITY: "MUGLIZH",
      },
      {
        CITY: "NESEBAR",
      },
      {
        CITY: "NIKOPOL",
      },
      {
        CITY: "NOVA ZAGORA",
      },
      {
        CITY: "NOVI ISKUR",
      },
      {
        CITY: "NOVI PAZAR",
      },
      {
        CITY: "OBZOR",
      },
      {
        CITY: "OMURTAG",
      },
      {
        CITY: "ORIZOVO",
      },
      {
        CITY: "ORLOVETS",
      },
      {
        CITY: "ORYAKHOVETS",
      },
      {
        CITY: "ORYAKHOVITSA",
      },
      {
        CITY: "OVOSHTNIK",
      },
      {
        CITY: "PANAGYURISHTE",
      },
      {
        CITY: "PAVEL BANYA",
      },
      {
        CITY: "PAVLIKENI",
      },
      {
        CITY: "PAZARDZHIK",
      },
      {
        CITY: "PCHELISHTE",
      },
      {
        CITY: "PERNIK",
      },
      {
        CITY: "PESHTERA",
      },
      {
        CITY: "PETRICH",
      },
      {
        CITY: "PLEVEN",
      },
      {
        CITY: "PLOVDIV",
      },
      {
        CITY: "POLETO",
      },
      {
        CITY: "POLSKI TRUMBESH",
      },
      {
        CITY: "POMORIE",
      },
      {
        CITY: "POPOVO",
      },
      {
        CITY: "PRAVETS",
      },
      {
        CITY: "PRESPA",
      },
      {
        CITY: "PROVADIYA",
      },
      {
        CITY: "RADNEVO",
      },
      {
        CITY: "RADUIL",
      },
      {
        CITY: "RAKITOVO",
      },
      {
        CITY: "RAKOVSKI",
      },
      {
        CITY: "RAVDA",
      },
      {
        CITY: "RAZGRAD",
      },
      {
        CITY: "RAZLOG",
      },
      {
        CITY: "RILA",
      },
      {
        CITY: "RODOPI",
      },
      {
        CITY: "ROUSSE",
      },
      {
        CITY: "ROZINO",
      },
      {
        CITY: "RUDOZEM",
      },
      {
        CITY: "RUEN",
      },
      {
        CITY: "SAMOKOV",
      },
      {
        CITY: "SAMOVODENE",
      },
      {
        CITY: "SAMUIL",
      },
      {
        CITY: "SANDANSKI",
      },
      {
        CITY: "SANDROVO",
      },
      {
        CITY: "SEPTEMVRI",
      },
      {
        CITY: "SEVLIEVO",
      },
      {
        CITY: "SHABLA",
      },
      {
        CITY: "SHIVACHEVO",
      },
      {
        CITY: "SHUMEN",
      },
      {
        CITY: "SILISTRA",
      },
      {
        CITY: "SIMEONOVGRAD",
      },
      {
        CITY: "SIMITLI",
      },
      {
        CITY: "SKRAVENA",
      },
      {
        CITY: "SLATINA",
      },
      {
        CITY: "SLIVEN",
      },
      {
        CITY: "SLIVNITSA",
      },
      {
        CITY: "SMOLYAN",
      },
      {
        CITY: "SMYADOVO",
      },
      {
        CITY: "SOFIA",
      },
      {
        CITY: "SOFRONIEVO",
      },
      {
        CITY: "SOKOLETS",
      },
      {
        CITY: "SOPOT",
      },
      {
        CITY: "SOZOPOL",
      },
      {
        CITY: "SREDETS",
      },
      {
        CITY: "STAMBOLIYSKI",
      },
      {
        CITY: "STARA ZAGORA",
      },
      {
        CITY: "STRAZHITSA",
      },
      {
        CITY: "STRELCHA",
      },
      {
        CITY: "SUEDINENIE",
      },
      {
        CITY: "SVILENGRAD",
      },
      {
        CITY: "SVISHTOV",
      },
      {
        CITY: "SVOGE",
      },
      {
        CITY: "TARGOVISHTE",
      },
      {
        CITY: "TERVEL",
      },
      {
        CITY: "TOCHILARI",
      },
      {
        CITY: "TOPOLI",
      },
      {
        CITY: "TOPOLOVO",
      },
      {
        CITY: "TROYAN",
      },
      {
        CITY: "TRUSTENIK",
      },
      {
        CITY: "TRYAVNA",
      },
      {
        CITY: "TUTRAKAN",
      },
      {
        CITY: "VARNA",
      },
      {
        CITY: "VELIKO",
      },
      {
        CITY: "VELIKO TARNOVO",
      },
      {
        CITY: "VELINGRAD",
      },
      {
        CITY: "VETREN",
      },
      {
        CITY: "VIDIN",
      },
      {
        CITY: "VIEVO",
      },
      {
        CITY: "VLADO TRICHKOV",
      },
      {
        CITY: "VRACHESH",
      },
      {
        CITY: "VRATSA",
      },
      {
        CITY: "YAKORUDA",
      },
      {
        CITY: "YAMBOL",
      },
      {
        CITY: "ZAVET",
      },
      {
        CITY: "ZLATITRAP",
      },
      {
        CITY: "ZLATOGRAD",
      },
    ],
  },
  {
    COUNTRY: "BARBADOS",
    CODE: "BB",
    CITIES: [
      {
        CITY: "ATLANTIC SHORES",
      },
      {
        CITY: "BAGATELLE",
      },
      {
        CITY: "BLOOMSBURY",
      },
      {
        CITY: "BRIDGETOWN",
      },
      {
        CITY: "BRUCE VALE",
      },
      {
        CITY: "CAVE HILL",
      },
      {
        CITY: "CLAPHAM",
      },
      {
        CITY: "HASTINGS",
      },
      {
        CITY: "HOLETOWN",
      },
      {
        CITY: "HUSBANDS",
      },
      {
        CITY: "JACKMANS",
      },
      {
        CITY: "OISTINS",
      },
      {
        CITY: "PINE HOUSING ESTATE",
      },
      {
        CITY: "PORTERS",
      },
      {
        CITY: "RENDEZVOUS",
      },
      {
        CITY: "ROCKLEY",
      },
      {
        CITY: "THE GARDEN",
      },
      {
        CITY: "WANSTEAD",
      },
      {
        CITY: "WARNERS",
      },
      {
        CITY: "WARRENS",
      },
      {
        CITY: "WHITE HILL",
      },
      {
        CITY: "WILDEY",
      },
      {
        CITY: "WORTHING",
      },
    ],
  },
  {
    COUNTRY: "CANADA",
    CODE: "CA",
    CITIES: [
      {
        CITY: "100 MILE HOUSE",
      },
      {
        CITY: "ABBEY",
      },
      {
        CITY: "ABBOTSFORD",
      },
      {
        CITY: "ACADIA VALLEY",
      },
      {
        CITY: "ACME",
      },
      {
        CITY: "ACTON",
      },
      {
        CITY: "ACTON VALE",
      },
      {
        CITY: "AGASSIZ",
      },
      {
        CITY: "AGINCOURT",
      },
      {
        CITY: "AILSA CRAIG",
      },
      {
        CITY: "AIRDRIE",
      },
      {
        CITY: "AJAX",
      },
      {
        CITY: "AKLAVIK",
      },
      {
        CITY: "ALAMEDA",
      },
      {
        CITY: "ALBANEL",
      },
      {
        CITY: "ALBERTON",
      },
      {
        CITY: "ALDERGROVE",
      },
      {
        CITY: "ALERT BAY",
      },
      {
        CITY: "ALEXANDER",
      },
      {
        CITY: "ALEXANDRIA",
      },
      {
        CITY: "ALFRED",
      },
      {
        CITY: "ALLAN",
      },
      {
        CITY: "ALLARDVILLE",
      },
      {
        CITY: "ALLENFORD",
      },
      {
        CITY: "ALLISTON",
      },
      {
        CITY: "ALMA",
      },
      {
        CITY: "ALMA",
      },
      {
        CITY: "ALMONTE",
      },
      {
        CITY: "ALTON",
      },
      {
        CITY: "ALTONA",
      },
      {
        CITY: "ALVINSTON",
      },
      {
        CITY: "AMHERST",
      },
      {
        CITY: "AMHERSTBURG",
      },
      {
        CITY: "AMOS",
      },
      {
        CITY: "AMQUI",
      },
      {
        CITY: "ANCASTER",
      },
      {
        CITY: "ANDREW",
      },
      {
        CITY: "ANGE-GARDIEN",
      },
      {
        CITY: "ANGLIERS",
      },
      {
        CITY: "ANGUS",
      },
      {
        CITY: "ANNAPOLIS ROYAL",
      },
      {
        CITY: "ANTIGONISH",
      },
      {
        CITY: "APOHAQUI",
      },
      {
        CITY: "APPLE HILL",
      },
      {
        CITY: "APSLEY",
      },
      {
        CITY: "ARBORFIELD",
      },
      {
        CITY: "ARBORG",
      },
      {
        CITY: "ARCADIA",
      },
      {
        CITY: "ARCOLA",
      },
      {
        CITY: "ARCTIC BAY",
      },
      {
        CITY: "ARDMORE",
      },
      {
        CITY: "ARDROSSAN",
      },
      {
        CITY: "ARELEE",
      },
      {
        CITY: "ARICHAT",
      },
      {
        CITY: "ARKONA",
      },
      {
        CITY: "ARMAGH",
      },
      {
        CITY: "ARMSTRONG",
      },
      {
        CITY: "ARNPRIOR",
      },
      {
        CITY: "ARTHABASKA",
      },
      {
        CITY: "ARTHUR",
      },
      {
        CITY: "ARUNDEL",
      },
      {
        CITY: "ARVA",
      },
      {
        CITY: "ARVIAT",
      },
      {
        CITY: "ASBESTOS",
      },
      {
        CITY: "ASCOT CORNER",
      },
      {
        CITY: "ASHCROFT",
      },
      {
        CITY: "ASHMONT",
      },
      {
        CITY: "ASHVILLE",
      },
      {
        CITY: "ASSINIBOIA",
      },
      {
        CITY: "ASTON-JONCTION",
      },
      {
        CITY: "ATHABASCA",
      },
      {
        CITY: "ATHENS",
      },
      {
        CITY: "ATIKAMEG",
      },
      {
        CITY: "ATIKOKAN",
      },
      {
        CITY: "ATLIN",
      },
      {
        CITY: "ATTAWAPISKAT",
      },
      {
        CITY: "ATWOOD",
      },
      {
        CITY: "AUDET",
      },
      {
        CITY: "AURORA",
      },
      {
        CITY: "AUSTIN",
      },
      {
        CITY: "AUTHIER",
      },
      {
        CITY: "AVONMORE",
      },
      {
        CITY: "AYLESFORD",
      },
      {
        CITY: "AYLMER",
      },
      {
        CITY: "AYLMER",
      },
      {
        CITY: "AYLSHAM",
      },
      {
        CITY: "AYR",
      },
      {
        CITY: "AYTON",
      },
      {
        CITY: "BADDECK",
      },
      {
        CITY: "BADEN",
      },
      {
        CITY: "BAIE VERTE",
      },
      {
        CITY: "BAIE-COMEAU",
      },
      {
        CITY: "BAIE-DES-SABLES",
      },
      {
        CITY: "BAIE-DU-FEBVRE",
      },
      {
        CITY: "BAIE-SAINT-PAUL",
      },
      {
        CITY: "BAIE-SAINTE-CATHERINE",
      },
      {
        CITY: "BALA",
      },
      {
        CITY: "BALCARRES",
      },
      {
        CITY: "BALDONNEL",
      },
      {
        CITY: "BALDUR",
      },
      {
        CITY: "BALTIMORE",
      },
      {
        CITY: "BAMFIELD",
      },
      {
        CITY: "BANCROFT",
      },
      {
        CITY: "BANFF",
      },
      {
        CITY: "BARKERVILLE",
      },
      {
        CITY: "BARNABY RIVER",
      },
      {
        CITY: "BARONS",
      },
      {
        CITY: "BARRAUTE",
      },
      {
        CITY: "BARRHEAD",
      },
      {
        CITY: "BARRIE",
      },
      {
        CITY: "BARRIERE",
      },
      {
        CITY: "BARRINGTON",
      },
      {
        CITY: "BARRINGTON PASSAGE",
      },
      {
        CITY: "BASHAW",
      },
      {
        CITY: "BASSANO",
      },
      {
        CITY: "BATH",
      },
      {
        CITY: "BATHURST",
      },
      {
        CITY: "BATISCAN",
      },
      {
        CITY: "BATTLEFORD",
      },
      {
        CITY: "BAY BULLS",
      },
      {
        CITY: "BAY DE VERDE",
      },
      {
        CITY: "BAY ROBERTS",
      },
      {
        CITY: "BAYFIELD",
      },
      {
        CITY: "BEACHBURG",
      },
      {
        CITY: "BEACONSFIELD",
      },
      {
        CITY: "BEAMSVILLE",
      },
      {
        CITY: "BEAR RIVER",
      },
      {
        CITY: "BEARN",
      },
      {
        CITY: "BEARSKIN LAKE",
      },
      {
        CITY: "BEAUCEVILLE",
      },
      {
        CITY: "BEAUHARNOIS",
      },
      {
        CITY: "BEAUMONT",
      },
      {
        CITY: "BEAUPORT",
      },
      {
        CITY: "BEAUPRE",
      },
      {
        CITY: "BEAUSEJOUR",
      },
      {
        CITY: "BEAUVAL",
      },
      {
        CITY: "BEAUX-RIVAGES--LAC-DES-ECORCES--VAL-BARRETTE",
      },
      {
        CITY: "BEAVER BANK",
      },
      {
        CITY: "BEAVER CROSSING",
      },
      {
        CITY: "BEAVERLODGE",
      },
      {
        CITY: "BEAVERTON",
      },
      {
        CITY: "BECANCOUR",
      },
      {
        CITY: "BEDFORD",
      },
      {
        CITY: "BEDFORD",
      },
      {
        CITY: "BEETON",
      },
      {
        CITY: "BEGIN",
      },
      {
        CITY: "BEISEKER",
      },
      {
        CITY: "BELL ISLAND",
      },
      {
        CITY: "BELLA BELLA",
      },
      {
        CITY: "BELLE RIVER",
      },
      {
        CITY: "BELLEVILLE",
      },
      {
        CITY: "BELLEVUE",
      },
      {
        CITY: "BELLEVUE",
      },
      {
        CITY: "BELOEIL",
      },
      {
        CITY: "BELWOOD",
      },
      {
        CITY: "BENTLEY",
      },
      {
        CITY: "BERRY MILLS",
      },
      {
        CITY: "BERTHIER-SUR-MER",
      },
      {
        CITY: "BERTHIERVILLE",
      },
      {
        CITY: "BERWICK",
      },
      {
        CITY: "BERWYN",
      },
      {
        CITY: "BETHUNE",
      },
      {
        CITY: "BETSIAMITES",
      },
      {
        CITY: "BIC",
      },
      {
        CITY: "BIENCOURT",
      },
      {
        CITY: "BIENFAIT",
      },
      {
        CITY: "BIG RIVER",
      },
      {
        CITY: "BIG VALLEY",
      },
      {
        CITY: "BIGGAR",
      },
      {
        CITY: "BINSCARTH",
      },
      {
        CITY: "BIRCH HILLS",
      },
      {
        CITY: "BIRCHY BAY",
      },
      {
        CITY: "BIRTLE",
      },
      {
        CITY: "BLACK CREEK",
      },
      {
        CITY: "BLACK DIAMOND",
      },
      {
        CITY: "BLACKFALDS",
      },
      {
        CITY: "BLACKIE",
      },
      {
        CITY: "BLACKS HARBOUR",
      },
      {
        CITY: "BLACKVILLE",
      },
      {
        CITY: "BLAINE LAKE",
      },
      {
        CITY: "BLAINVILLE",
      },
      {
        CITY: "BLAIRMORE",
      },
      {
        CITY: "BLANDFORD",
      },
      {
        CITY: "BLENHEIM",
      },
      {
        CITY: "BLIND RIVER",
      },
      {
        CITY: "BLOOMFIELD",
      },
      {
        CITY: "BLUE RIVER",
      },
      {
        CITY: "BLUEWATER",
      },
      {
        CITY: "BOBCAYGEON",
      },
      {
        CITY: "BOIS-DES-FILION",
      },
      {
        CITY: "BOISBRIAND",
      },
      {
        CITY: "BOISCHATEL",
      },
      {
        CITY: "BOISSEVAIN",
      },
      {
        CITY: "BOLTON",
      },
      {
        CITY: "BONAVENTURE",
      },
      {
        CITY: "BONNE BAY",
      },
      {
        CITY: "BONNYVILLE",
      },
      {
        CITY: "BONSECOURS",
      },
      {
        CITY: "BONSHAW",
      },
      {
        CITY: "BORDEN",
      },
      {
        CITY: "BORDEN-CARLETON",
      },
      {
        CITY: "BOSWELL",
      },
      {
        CITY: "BOUCHERVILLE",
      },
      {
        CITY: "BOUCTOUCHE",
      },
      {
        CITY: "BOURGET",
      },
      {
        CITY: "BOW ISLAND",
      },
      {
        CITY: "BOWDEN",
      },
      {
        CITY: "BOWMANVILLE",
      },
      {
        CITY: "BOWSER",
      },
      {
        CITY: "BOYLE",
      },
      {
        CITY: "BRACEBRIDGE",
      },
      {
        CITY: "BRACKENDALE",
      },
      {
        CITY: "BRADFORD",
      },
      {
        CITY: "BRAMALEA",
      },
      {
        CITY: "BRAMPTON",
      },
      {
        CITY: "BRANDON",
      },
      {
        CITY: "BRANTFORD",
      },
      {
        CITY: "BRECHIN",
      },
      {
        CITY: "BREDENBURY",
      },
      {
        CITY: "BRENTWOOD BAY",
      },
      {
        CITY: "BRESLAU",
      },
      {
        CITY: "BRETON",
      },
      {
        CITY: "BRIDGE LAKE",
      },
      {
        CITY: "BRIDGETOWN",
      },
      {
        CITY: "BRIDGEWATER",
      },
      {
        CITY: "BRIGHAM",
      },
      {
        CITY: "BRIGHTON",
      },
      {
        CITY: "BRIGUS",
      },
      {
        CITY: "BRITT",
      },
      {
        CITY: "BROADVIEW",
      },
      {
        CITY: "BROCHET",
      },
      {
        CITY: "BROCKVILLE",
      },
      {
        CITY: "BROME",
      },
      {
        CITY: "BROMHEAD",
      },
      {
        CITY: "BROMONT",
      },
      {
        CITY: "BROOKFIELD",
      },
      {
        CITY: "BROOKLYN",
      },
      {
        CITY: "BROOKS",
      },
      {
        CITY: "BROSSARD",
      },
      {
        CITY: "BROSSARD",
      },
      {
        CITY: "BRUNO",
      },
      {
        CITY: "BRYSON",
      },
      {
        CITY: "BUCKHORN",
      },
      {
        CITY: "BUCKINGHAM",
      },
      {
        CITY: "BUFFALO NARROWS",
      },
      {
        CITY: "BURGEO",
      },
      {
        CITY: "BURGESSVILLE",
      },
      {
        CITY: "BURIN",
      },
      {
        CITY: "BURLINGTON",
      },
      {
        CITY: "BURNABY",
      },
      {
        CITY: "BURNS LAKE",
      },
      {
        CITY: "BURNSIDE",
      },
      {
        CITY: "CABANO",
      },
      {
        CITY: "CABRI",
      },
      {
        CITY: "CACHE CREEK",
      },
      {
        CITY: "CALDER",
      },
      {
        CITY: "CALEDON",
      },
      {
        CITY: "CALEDON",
      },
      {
        CITY: "CALEDONIA",
      },
      {
        CITY: "CALEDONIA",
      },
      {
        CITY: "CALGARY",
      },
      {
        CITY: "CALIXA-LAVALLEE",
      },
      {
        CITY: "CALLANDER",
      },
      {
        CITY: "CALMAR",
      },
      {
        CITY: "CAMBRIDGE",
      },
      {
        CITY: "CAMBRIDGE",
      },
      {
        CITY: "CAMBRIDGE BAY",
      },
      {
        CITY: "CAMLACHIE",
      },
      {
        CITY: "CAMPBELL RIVER",
      },
      {
        CITY: "CAMPBELLFORD",
      },
      {
        CITY: "CAMPBELLTON",
      },
      {
        CITY: "CAMPBELLVILLE",
      },
      {
        CITY: "CAMROSE",
      },
      {
        CITY: "CANAL FLATS",
      },
      {
        CITY: "CANDIAC",
      },
      {
        CITY: "CANFIELD",
      },
      {
        CITY: "CANIM LAKE",
      },
      {
        CITY: "CANMORE",
      },
      {
        CITY: "CANNING",
      },
      {
        CITY: "CANNINGTON",
      },
      {
        CITY: "CANOE NARROWS",
      },
      {
        CITY: "CANORA",
      },
      {
        CITY: "CANSO",
      },
      {
        CITY: "CANTERBURY",
      },
      {
        CITY: "CANWOOD",
      },
      {
        CITY: "CANYON",
      },
      {
        CITY: "CAP-AUX-MEULES",
      },
      {
        CITY: "CAP-CHAT",
      },
      {
        CITY: "CAP-SAINT-IGNACE",
      },
      {
        CITY: "CAP-SANTE",
      },
      {
        CITY: "CAPE DORSET",
      },
      {
        CITY: "CARAQUET",
      },
      {
        CITY: "CARBERRY",
      },
      {
        CITY: "CARBONEAR",
      },
      {
        CITY: "CARDIGAN",
      },
      {
        CITY: "CARDINAL",
      },
      {
        CITY: "CARDSTON",
      },
      {
        CITY: "CARIGNAN",
      },
      {
        CITY: "CARLETON PLACE",
      },
      {
        CITY: "CARLETON-SUR-MER",
      },
      {
        CITY: "CARLISLE",
      },
      {
        CITY: "CARLYLE",
      },
      {
        CITY: "CARMACKS",
      },
      {
        CITY: "CARMAN",
      },
      {
        CITY: "CARMEL",
      },
      {
        CITY: "CARNDUFF",
      },
      {
        CITY: "CAROLINE",
      },
      {
        CITY: "CARONPORT",
      },
      {
        CITY: "CARP",
      },
      {
        CITY: "CARROT RIVER",
      },
      {
        CITY: "CARRYING PLACE",
      },
      {
        CITY: "CARSELAND",
      },
      {
        CITY: "CARSTAIRS",
      },
      {
        CITY: "CARTERS COVE",
      },
      {
        CITY: "CARTIER",
      },
      {
        CITY: "CARTWRIGHT",
      },
      {
        CITY: "CASIMIR",
      },
      {
        CITY: "CASSELMAN",
      },
      {
        CITY: "CASTLE BAY",
      },
      {
        CITY: "CASTLEGAR",
      },
      {
        CITY: "CAT LAKE",
      },
      {
        CITY: "CAUSAPSCAL",
      },
      {
        CITY: "CAVENDISH",
      },
      {
        CITY: "CAYLEY",
      },
      {
        CITY: "CAYUGA",
      },
      {
        CITY: "CEDAR VALLEY",
      },
      {
        CITY: "CENTRAL BEDEQUE",
      },
      {
        CITY: "CENTREVILLE",
      },
      {
        CITY: "CEYLON",
      },
      {
        CITY: "CHALK RIVER",
      },
      {
        CITY: "CHAMBLY",
      },
      {
        CITY: "CHAMPLAIN",
      },
      {
        CITY: "CHANDLER",
      },
      {
        CITY: "CHAPAIS",
      },
      {
        CITY: "CHAPEL ARM",
      },
      {
        CITY: "CHAPLEAU",
      },
      {
        CITY: "CHARLEMAGNE",
      },
      {
        CITY: "CHARLESBOURG",
      },
      {
        CITY: "CHARLIE LAKE",
      },
      {
        CITY: "CHARLOTTETOWN",
      },
      {
        CITY: "CHARNY",
      },
      {
        CITY: "CHASE",
      },
      {
        CITY: "CHATEAU-RICHER",
      },
      {
        CITY: "CHATEAUGUAY",
      },
      {
        CITY: "CHATHAM",
      },
      {
        CITY: "CHATSWORTH",
      },
      {
        CITY: "CHEAPSIDE",
      },
      {
        CITY: "CHELMSFORD",
      },
      {
        CITY: "CHELSEA",
      },
      {
        CITY: "CHEMAINUS",
      },
      {
        CITY: "CHENEVILLE",
      },
      {
        CITY: "CHERRY GROVE",
      },
      {
        CITY: "CHERTSEY",
      },
      {
        CITY: "CHESLEY",
      },
      {
        CITY: "CHESTER",
      },
      {
        CITY: "CHESTERMERE",
      },
      {
        CITY: "CHESTERVILLE",
      },
      {
        CITY: "CHETICAMP",
      },
      {
        CITY: "CHIBOUGAMAU",
      },
      {
        CITY: "CHICOUTIMI",
      },
      {
        CITY: "CHICOUTIMI",
      },
      {
        CITY: "CHILLIWACK",
      },
      {
        CITY: "CHIPMAN",
      },
      {
        CITY: "CHURCH POINT",
      },
      {
        CITY: "CHURCHILL",
      },
      {
        CITY: "CHURCHILL",
      },
      {
        CITY: "CHURCHVILLE",
      },
      {
        CITY: "CHUTE-AUX-OUTARDES",
      },
      {
        CITY: "CIRCONSCRIPTION ELECTORALE DE JACQUES-CARTIER",
      },
      {
        CITY: "CLAIR",
      },
      {
        CITY: "CLAIRMONT",
      },
      {
        CITY: "CLAREMONT",
      },
      {
        CITY: "CLARENCE",
      },
      {
        CITY: "CLARENCE-ROCKLAND",
      },
      {
        CITY: "CLARENVILLE",
      },
      {
        CITY: "CLARENVILLE-SHOAL HARBOUR",
      },
      {
        CITY: "CLARESHOLM",
      },
      {
        CITY: "CLARKE CITY",
      },
      {
        CITY: "CLARKSON",
      },
      {
        CITY: "CLEARWATER",
      },
      {
        CITY: "CLEMENTSVALE",
      },
      {
        CITY: "CLERMONT",
      },
      {
        CITY: "CLEVELAND",
      },
      {
        CITY: "CLIFFORD",
      },
      {
        CITY: "CLINTON",
      },
      {
        CITY: "CLINTON",
      },
      {
        CITY: "CLIVE",
      },
      {
        CITY: "CLYDE RIVER",
      },
      {
        CITY: "COAL HARBOUR",
      },
      {
        CITY: "COALDALE",
      },
      {
        CITY: "COATICOOK",
      },
      {
        CITY: "COBBLE HILL",
      },
      {
        CITY: "COBDEN",
      },
      {
        CITY: "COBOCONK",
      },
      {
        CITY: "COBOURG",
      },
      {
        CITY: "COBURG",
      },
      {
        CITY: "COCHRANE",
      },
      {
        CITY: "COCHRANE",
      },
      {
        CITY: "COLBORNE",
      },
      {
        CITY: "COLD LAKE",
      },
      {
        CITY: "COLDSTREAM",
      },
      {
        CITY: "COLDWATER",
      },
      {
        CITY: "COLEMAN",
      },
      {
        CITY: "COLEMAN",
      },
      {
        CITY: "COLERAINE",
      },
      {
        CITY: "COLEVILLE",
      },
      {
        CITY: "COLINET",
      },
      {
        CITY: "COLLIERS",
      },
      {
        CITY: "COLLINGWOOD",
      },
      {
        CITY: "COLONSAY",
      },
      {
        CITY: "COLVILLE LAKE",
      },
      {
        CITY: "COMBER",
      },
      {
        CITY: "COMOX",
      },
      {
        CITY: "COMPTON",
      },
      {
        CITY: "CONCEPTION BAY SOUTH",
      },
      {
        CITY: "CONCORD",
      },
      {
        CITY: "CONESTOGO",
      },
      {
        CITY: "CONISTON",
      },
      {
        CITY: "CONQUEST",
      },
      {
        CITY: "CONSORT",
      },
      {
        CITY: "CONTRECOEUR",
      },
      {
        CITY: "COOKSHIRE-EATON",
      },
      {
        CITY: "COPPER CLIFF",
      },
      {
        CITY: "COQUITLAM",
      },
      {
        CITY: "CORBYVILLE",
      },
      {
        CITY: "CORNER BROOK",
      },
      {
        CITY: "CORNWALL",
      },
      {
        CITY: "CORNWALL",
      },
      {
        CITY: "CORNWALLIS",
      },
      {
        CITY: "COTE-SAINT-LUC",
      },
      {
        CITY: "COTTAM",
      },
      {
        CITY: "COURTENAY",
      },
      {
        CITY: "COURTLAND",
      },
      {
        CITY: "COURTRIGHT",
      },
      {
        CITY: "COUTTS",
      },
      {
        CITY: "COW HEAD",
      },
      {
        CITY: "COWANSVILLE",
      },
      {
        CITY: "COWICHAN BAY",
      },
      {
        CITY: "COWLEY",
      },
      {
        CITY: "CRABTREE",
      },
      {
        CITY: "CRAIK",
      },
      {
        CITY: "CRANBERRY",
      },
      {
        CITY: "CRANBERRY PORTAGE",
      },
      {
        CITY: "CRANBROOK",
      },
      {
        CITY: "CRAPAUD",
      },
      {
        CITY: "CREEMORE",
      },
      {
        CITY: "CRESTON",
      },
      {
        CITY: "CROFTON",
      },
      {
        CITY: "CROMER",
      },
      {
        CITY: "CROSS LAKE",
      },
      {
        CITY: "CROSSFIELD",
      },
      {
        CITY: "CUDWORTH",
      },
      {
        CITY: "CUMBERLAND",
      },
      {
        CITY: "CUMBERLAND",
      },
      {
        CITY: "CUMBERLAND BAY",
      },
      {
        CITY: "CUMBERLAND HOUSE",
      },
      {
        CITY: "CUPAR",
      },
      {
        CITY: "CUT KNIFE",
      },
      {
        CITY: "CZAR",
      },
      {
        CITY: "DALHOUSIE",
      },
      {
        CITY: "DALMENY",
      },
      {
        CITY: "DANVILLE",
      },
      {
        CITY: "DARTMOUTH",
      },
      {
        CITY: "DASHWOOD",
      },
      {
        CITY: "DAUPHIN",
      },
      {
        CITY: "DAVELUYVILLE",
      },
      {
        CITY: "DAVIDSON",
      },
      {
        CITY: "DAWSON CITY",
      },
      {
        CITY: "DAWSON CREEK",
      },
      {
        CITY: "DAWSON SETTLEMENT",
      },
      {
        CITY: "DAYSLAND",
      },
      {
        CITY: "DEBDEN",
      },
      {
        CITY: "DEBERT",
      },
      {
        CITY: "DEBOLT",
      },
      {
        CITY: "DEEP RIVER",
      },
      {
        CITY: "DEER LAKE",
      },
      {
        CITY: "DEER LAKE",
      },
      {
        CITY: "DELAWARE",
      },
      {
        CITY: "DELAWARE WEST",
      },
      {
        CITY: "DELBURNE",
      },
      {
        CITY: "DELHI",
      },
      {
        CITY: "DELINE",
      },
      {
        CITY: "DELISLE",
      },
      {
        CITY: "DELSON",
      },
      {
        CITY: "DELTA",
      },
      {
        CITY: "DENFIELD",
      },
      {
        CITY: "DENMAN ISLAND",
      },
      {
        CITY: "DEROCHE",
      },
      {
        CITY: "DESBIENS",
      },
      {
        CITY: "DESCHAILLONS-SUR-SAINT-LAURENT",
      },
      {
        CITY: "DESCHAMBAULT",
      },
      {
        CITY: "DESERONTO",
      },
      {
        CITY: "DEUX-MONTAGNES",
      },
      {
        CITY: "DEVON",
      },
      {
        CITY: "DIDSBURY",
      },
      {
        CITY: "DIEPPE",
      },
      {
        CITY: "DIGBY",
      },
      {
        CITY: "DIGBY",
      },
      {
        CITY: "DINSMORE",
      },
      {
        CITY: "DISRAELI",
      },
      {
        CITY: "DIXVILLE",
      },
      {
        CITY: "DOAKTOWN",
      },
      {
        CITY: "DOG CREEK",
      },
      {
        CITY: "DOLBEAU-MISTASSINI",
      },
      {
        CITY: "DOLLARD-DES ORMEAUX",
      },
      {
        CITY: "DOMAIN",
      },
      {
        CITY: "DOMINION CITY",
      },
      {
        CITY: "DOMREMY",
      },
      {
        CITY: "DON MILLS",
      },
      {
        CITY: "DONNACONA",
      },
      {
        CITY: "DONNELLY",
      },
      {
        CITY: "DORCHESTER",
      },
      {
        CITY: "DORION-GARDENS",
      },
      {
        CITY: "DORSET",
      },
      {
        CITY: "DORVAL",
      },
      {
        CITY: "DOVER",
      },
      {
        CITY: "DOWNSVIEW",
      },
      {
        CITY: "DRAYTON",
      },
      {
        CITY: "DRAYTON VALLEY",
      },
      {
        CITY: "DRESDEN",
      },
      {
        CITY: "DRUMBO",
      },
      {
        CITY: "DRUMHELLER",
      },
      {
        CITY: "DRUMMONDVILLE",
      },
      {
        CITY: "DRYDEN",
      },
      {
        CITY: "DUBLIN",
      },
      {
        CITY: "DUCHESS",
      },
      {
        CITY: "DUCK LAKE",
      },
      {
        CITY: "DUDSWELL",
      },
      {
        CITY: "DUFRESNE",
      },
      {
        CITY: "DUGALD",
      },
      {
        CITY: "DUHAMEL",
      },
      {
        CITY: "DUNCAN",
      },
      {
        CITY: "DUNDALK",
      },
      {
        CITY: "DUNDAS",
      },
      {
        CITY: "DUNDURN",
      },
      {
        CITY: "DUNHAM",
      },
      {
        CITY: "DUNNVILLE",
      },
      {
        CITY: "DUNSFORD",
      },
      {
        CITY: "DUPUY",
      },
      {
        CITY: "DURHAM",
      },
      {
        CITY: "EAGLESHAM",
      },
      {
        CITY: "EARLTON",
      },
      {
        CITY: "EAST ANGUS",
      },
      {
        CITY: "EAST BROUGHTON",
      },
      {
        CITY: "EAST CHEZZETCOOK",
      },
      {
        CITY: "EAST COULEE",
      },
      {
        CITY: "EAST MARGAREE",
      },
      {
        CITY: "EASTERN PASSAGE",
      },
      {
        CITY: "EASTMAN",
      },
      {
        CITY: "EASTPORT",
      },
      {
        CITY: "EATONVILLE",
      },
      {
        CITY: "ECHO BAY",
      },
      {
        CITY: "ECKVILLE",
      },
      {
        CITY: "ECONOMY",
      },
      {
        CITY: "EDAM",
      },
      {
        CITY: "EDEN",
      },
      {
        CITY: "EDGERTON",
      },
      {
        CITY: "EDGEWOOD",
      },
      {
        CITY: "EDMONTON",
      },
      {
        CITY: "EDMUNDSTON",
      },
      {
        CITY: "EDSON",
      },
      {
        CITY: "EGANVILLE",
      },
      {
        CITY: "ELBOW",
      },
      {
        CITY: "ELGIN",
      },
      {
        CITY: "ELGIN",
      },
      {
        CITY: "ELK POINT",
      },
      {
        CITY: "ELLIOT LAKE",
      },
      {
        CITY: "ELLISTON",
      },
      {
        CITY: "ELMA",
      },
      {
        CITY: "ELMIRA",
      },
      {
        CITY: "ELMSDALE",
      },
      {
        CITY: "ELMVALE",
      },
      {
        CITY: "ELORA",
      },
      {
        CITY: "ELROSE",
      },
      {
        CITY: "EMBRUN",
      },
      {
        CITY: "EMERALD",
      },
      {
        CITY: "EMERALD JUNCTION",
      },
      {
        CITY: "EMO",
      },
      {
        CITY: "EMPRESS",
      },
      {
        CITY: "EMSDALE",
      },
      {
        CITY: "ENDERBY",
      },
      {
        CITY: "ENFIELD",
      },
      {
        CITY: "ENGLEHART",
      },
      {
        CITY: "ENTERPRISE",
      },
      {
        CITY: "ENTWISTLE",
      },
      {
        CITY: "ERIKSDALE",
      },
      {
        CITY: "ERIN",
      },
      {
        CITY: "ESPANOLA",
      },
      {
        CITY: "ESPRIT-SAINT",
      },
      {
        CITY: "ESSEX",
      },
      {
        CITY: "ESTERHAZY",
      },
      {
        CITY: "ESTEVAN",
      },
      {
        CITY: "ESTON",
      },
      {
        CITY: "ETOBICOKE",
      },
      {
        CITY: "EUREKA",
      },
      {
        CITY: "EVERETT",
      },
      {
        CITY: "EXETER",
      },
      {
        CITY: "FABREVILLE",
      },
      {
        CITY: "FAIRMONT HOT SPRINGS",
      },
      {
        CITY: "FAIRVIEW ALBERTA",
      },
      {
        CITY: "FARNHAM",
      },
      {
        CITY: "FENELON FALLS",
      },
      {
        CITY: "FERGUS",
      },
      {
        CITY: "FERINTOSH",
      },
      {
        CITY: "FERME-NEUVE",
      },
      {
        CITY: "FERMEUSE",
      },
      {
        CITY: "FERMONT",
      },
      {
        CITY: "FERNIE",
      },
      {
        CITY: "FIELD",
      },
      {
        CITY: "FIELD",
      },
      {
        CITY: "FILLMORE",
      },
      {
        CITY: "FINCH",
      },
      {
        CITY: "FLAT RIVER",
      },
      {
        CITY: "FLESHERTON",
      },
      {
        CITY: "FLIN FLON",
      },
      {
        CITY: "FLORENCEVILLE-BRISTOL",
      },
      {
        CITY: "FOAM LAKE",
      },
      {
        CITY: "FOGO",
      },
      {
        CITY: "FONTHILL",
      },
      {
        CITY: "FOREMOST",
      },
      {
        CITY: "FOREST",
      },
      {
        CITY: "FORESTBURG",
      },
      {
        CITY: "FORESTVILLE",
      },
      {
        CITY: "FORT ALBANY",
      },
      {
        CITY: "FORT ERIE",
      },
      {
        CITY: "FORT FRANCES",
      },
      {
        CITY: "FORT GOOD HOPE",
      },
      {
        CITY: "FORT HOPE",
      },
      {
        CITY: "FORT KENT",
      },
      {
        CITY: "FORT MACLEOD",
      },
      {
        CITY: "FORT MCMURRAY",
      },
      {
        CITY: "FORT MCPHERSON",
      },
      {
        CITY: "FORT NELSON",
      },
      {
        CITY: "FORT SASKATCHEWAN",
      },
      {
        CITY: "FORT SEVERN",
      },
      {
        CITY: "FORT SIMPSON",
      },
      {
        CITY: "FORT SMITH",
      },
      {
        CITY: "FORT ST. JAMES",
      },
      {
        CITY: "FORT ST. JOHN",
      },
      {
        CITY: "FORT VERMILION",
      },
      {
        CITY: "FORT-COULONGE",
      },
      {
        CITY: "FORTIERVILLE",
      },
      {
        CITY: "FORTUNE",
      },
      {
        CITY: "FOX CREEK",
      },
      {
        CITY: "FOXTRAP",
      },
      {
        CITY: "FOYMOUNT",
      },
      {
        CITY: "FRANCIS",
      },
      {
        CITY: "FRANKFORD",
      },
      {
        CITY: "FRASER LAKE",
      },
      {
        CITY: "FREDERICTON",
      },
      {
        CITY: "FREDERICTON JUNCTION",
      },
      {
        CITY: "FRELIGHSBURG",
      },
      {
        CITY: "FROBISHER",
      },
      {
        CITY: "FRUITVALE",
      },
      {
        CITY: "GABRIOLA",
      },
      {
        CITY: "GADSBY",
      },
      {
        CITY: "GALAHAD",
      },
      {
        CITY: "GAMBO",
      },
      {
        CITY: "GANANOQUE",
      },
      {
        CITY: "GANDER",
      },
      {
        CITY: "GANDER BAY",
      },
      {
        CITY: "GANGES",
      },
      {
        CITY: "GARDEN RIVER",
      },
      {
        CITY: "GARIBALDI",
      },
      {
        CITY: "GARIBALDI HIGHLANDS",
      },
      {
        CITY: "GARNISH",
      },
      {
        CITY: "GARRICK",
      },
      {
        CITY: "GARSON",
      },
      {
        CITY: "GATINEAU",
      },
      {
        CITY: "GEM",
      },
      {
        CITY: "GEORGETOWN",
      },
      {
        CITY: "GERALDTON",
      },
      {
        CITY: "GIBSONS",
      },
      {
        CITY: "GILBERT PLAINS",
      },
      {
        CITY: "GILLAM",
      },
      {
        CITY: "GIMLI",
      },
      {
        CITY: "GIROUXVILLE",
      },
      {
        CITY: "GLACE BAY",
      },
      {
        CITY: "GLADSTONE",
      },
      {
        CITY: "GLASLYN",
      },
      {
        CITY: "GLEICHEN",
      },
      {
        CITY: "GLEN ROBERTSON",
      },
      {
        CITY: "GLENCOE",
      },
      {
        CITY: "GLENDON",
      },
      {
        CITY: "GLENTWORTH",
      },
      {
        CITY: "GLENWOOD",
      },
      {
        CITY: "GLENWOOD",
      },
      {
        CITY: "GLOUCESTER",
      },
      {
        CITY: "GLOVERTOWN",
      },
      {
        CITY: "GODERICH",
      },
      {
        CITY: "GOGAMA",
      },
      {
        CITY: "GOLD BRIDGE",
      },
      {
        CITY: "GOLDEN",
      },
      {
        CITY: "GOLDEN LAKE",
      },
      {
        CITY: "GORE",
      },
      {
        CITY: "GORE BAY",
      },
      {
        CITY: "GRANBY",
      },
      {
        CITY: "GRAND BEND",
      },
      {
        CITY: "GRAND FALLS",
      },
      {
        CITY: "GRAND FALLS-WINDSOR",
      },
      {
        CITY: "GRAND FORKS",
      },
      {
        CITY: "GRAND RAPIDS",
      },
      {
        CITY: "GRAND VALLEY",
      },
      {
        CITY: "GRAND-REMOUS",
      },
      {
        CITY: "GRAND-SAINT-ESPRIT",
      },
      {
        CITY: "GRANDE CACHE",
      },
      {
        CITY: "GRANDE PRAIRIE",
      },
      {
        CITY: "GRANDE-ANSE",
      },
      {
        CITY: "GRANDE-RIVIERE",
      },
      {
        CITY: "GRANDORA",
      },
      {
        CITY: "GRANTON",
      },
      {
        CITY: "GRANUM",
      },
      {
        CITY: "GRANVILLE FERRY",
      },
      {
        CITY: "GRASMERE",
      },
      {
        CITY: "GRAVELBOURG",
      },
      {
        CITY: "GRAVENHURST",
      },
      {
        CITY: "GRAY CREEK",
      },
      {
        CITY: "GREAT VILLAGE",
      },
      {
        CITY: "GREATER SUDBURY",
      },
      {
        CITY: "GREELY",
      },
      {
        CITY: "GREENFIELD PARK",
      },
      {
        CITY: "GREENSPOND",
      },
      {
        CITY: "GREENWOOD",
      },
      {
        CITY: "GREENWOOD",
      },
      {
        CITY: "GRENFELL",
      },
      {
        CITY: "GRENVILLE",
      },
      {
        CITY: "GRETNA",
      },
      {
        CITY: "GRIMSBY",
      },
      {
        CITY: "GRIMSHAW",
      },
      {
        CITY: "GRINDROD",
      },
      {
        CITY: "GRISWOLD",
      },
      {
        CITY: "GRONLID",
      },
      {
        CITY: "GRONO ROAD",
      },
      {
        CITY: "GUELPH",
      },
      {
        CITY: "GUIGUES",
      },
      {
        CITY: "GULL LAKE",
      },
      {
        CITY: "GUYSBOROUGH",
      },
      {
        CITY: "HAFFORD",
      },
      {
        CITY: "HAGENSBORG",
      },
      {
        CITY: "HAGERSVILLE",
      },
      {
        CITY: "HAILEYBURY",
      },
      {
        CITY: "HALFMOON BAY",
      },
      {
        CITY: "HALIBURTON",
      },
      {
        CITY: "HALIFAX",
      },
      {
        CITY: "HALIFAX",
      },
      {
        CITY: "HAM-NORD",
      },
      {
        CITY: "HAMILTON",
      },
      {
        CITY: "HAMIOTA",
      },
      {
        CITY: "HAMPDEN",
      },
      {
        CITY: "HAMPTON",
      },
      {
        CITY: "HAMPTON",
      },
      {
        CITY: "HANLEY",
      },
      {
        CITY: "HANNA",
      },
      {
        CITY: "HANOVER",
      },
      {
        CITY: "HANTSPORT",
      },
      {
        CITY: "HAPPY VALLEY-GOOSE BAY",
      },
      {
        CITY: "HARBOUR GRACE",
      },
      {
        CITY: "HARBOUR MAIN",
      },
      {
        CITY: "HARDISTY",
      },
      {
        CITY: "HARE BAY",
      },
      {
        CITY: "HARRISTON",
      },
      {
        CITY: "HARROW",
      },
      {
        CITY: "HARTLAND",
      },
      {
        CITY: "HARTLEY BAY",
      },
      {
        CITY: "HARTNEY",
      },
      {
        CITY: "HARVEY",
      },
      {
        CITY: "HASTINGS",
      },
      {
        CITY: "HAVELOCK",
      },
      {
        CITY: "HAVRE BOUCHER",
      },
      {
        CITY: "HAVRE-AUBERT",
      },
      {
        CITY: "HAVRE-SAINT-PIERRE",
      },
      {
        CITY: "HAWKESBURY",
      },
      {
        CITY: "HAWKESTONE BEACH",
      },
      {
        CITY: "HAY LAKES",
      },
      {
        CITY: "HAY RIVER",
      },
      {
        CITY: "HAZELTON",
      },
      {
        CITY: "HEAD OF JEDDORE",
      },
      {
        CITY: "HEARST",
      },
      {
        CITY: "HEBERTVILLE",
      },
      {
        CITY: "HEBRON",
      },
      {
        CITY: "HEMMINGFORD",
      },
      {
        CITY: "HENRYVILLE",
      },
      {
        CITY: "HENSALL",
      },
      {
        CITY: "HERBERT",
      },
      {
        CITY: "HERON BAY",
      },
      {
        CITY: "HESPELER",
      },
      {
        CITY: "HIGH LEVEL",
      },
      {
        CITY: "HIGH PRAIRIE",
      },
      {
        CITY: "HIGH RIVER",
      },
      {
        CITY: "HILLSBURGH",
      },
      {
        CITY: "HINTON",
      },
      {
        CITY: "HOBBEMA",
      },
      {
        CITY: "HOLDEN",
      },
      {
        CITY: "HOLMAN",
      },
      {
        CITY: "HOLYROOD",
      },
      {
        CITY: "HONEYMOON BAY",
      },
      {
        CITY: "HOPE",
      },
      {
        CITY: "HORNEPAYNE",
      },
      {
        CITY: "HOUSTON",
      },
      {
        CITY: "HOWICK",
      },
      {
        CITY: "HOWLEY",
      },
      {
        CITY: "HUBBARDS",
      },
      {
        CITY: "HUDSON",
      },
      {
        CITY: "HUDSON BAY",
      },
      {
        CITY: "HULL",
      },
      {
        CITY: "HUMBOLDT",
      },
      {
        CITY: "HUNTER RIVER",
      },
      {
        CITY: "HUNTINGDON",
      },
      {
        CITY: "HUNTSVILLE",
      },
      {
        CITY: "HURON EAST",
      },
      {
        CITY: "HYTHE",
      },
      {
        CITY: "IGNACE",
      },
      {
        CITY: "ILDERTON",
      },
      {
        CITY: "ILE-A-LA-CROSSE",
      },
      {
        CITY: "INDIAN HEAD",
      },
      {
        CITY: "INGERSOLL",
      },
      {
        CITY: "INGLESIDE",
      },
      {
        CITY: "INGLEWOOD",
      },
      {
        CITY: "INGLIS",
      },
      {
        CITY: "INGONISH",
      },
      {
        CITY: "INNISFAIL",
      },
      {
        CITY: "INNISFIL",
      },
      {
        CITY: "INUKJUAK",
      },
      {
        CITY: "INUVIK",
      },
      {
        CITY: "INVERMERE",
      },
      {
        CITY: "INVERNESS",
      },
      {
        CITY: "INWOOD",
      },
      {
        CITY: "IONA",
      },
      {
        CITY: "IQALUIT",
      },
      {
        CITY: "IRMA",
      },
      {
        CITY: "IROQUOIS",
      },
      {
        CITY: "IROQUOIS FALLS",
      },
      {
        CITY: "IRRICANA",
      },
      {
        CITY: "ISLINGTON",
      },
      {
        CITY: "ITALY CROSS",
      },
      {
        CITY: "ITUNA",
      },
      {
        CITY: "IVUJIVIK",
      },
      {
        CITY: "JACKSONS",
      },
      {
        CITY: "JASPER",
      },
      {
        CITY: "JENNER",
      },
      {
        CITY: "JERSEYVILLE",
      },
      {
        CITY: "JOLIETTE",
      },
      {
        CITY: "JONQUIERE",
      },
      {
        CITY: "JORDAN",
      },
      {
        CITY: "JORDAN FALLS",
      },
      {
        CITY: "KAGAWONG",
      },
      {
        CITY: "KAMLOOPS",
      },
      {
        CITY: "KAMOURASKA",
      },
      {
        CITY: "KAMSACK",
      },
      {
        CITY: "KANATA",
      },
      {
        CITY: "KANGIQSUJUAQ",
      },
      {
        CITY: "KANGIRSUK",
      },
      {
        CITY: "KAPUSKASING",
      },
      {
        CITY: "KARS",
      },
      {
        CITY: "KASLO",
      },
      {
        CITY: "KAZABAZUA",
      },
      {
        CITY: "KEDGWICK",
      },
      {
        CITY: "KEENE",
      },
      {
        CITY: "KEEWATIN",
      },
      {
        CITY: "KELOWNA",
      },
      {
        CITY: "KELVINGTON",
      },
      {
        CITY: "KEMPTVILLE",
      },
      {
        CITY: "KENORA",
      },
      {
        CITY: "KENSINGTON",
      },
      {
        CITY: "KENT BRIDGE",
      },
      {
        CITY: "KENTVILLE",
      },
      {
        CITY: "KERROBERT",
      },
      {
        CITY: "KERWOOD",
      },
      {
        CITY: "KESWICK",
      },
      {
        CITY: "KESWICK BEACH",
      },
      {
        CITY: "KETTLEBY",
      },
      {
        CITY: "KILLARNEY",
      },
      {
        CITY: "KILWORTHY",
      },
      {
        CITY: "KIMBERLEY",
      },
      {
        CITY: "KIMMIRUT",
      },
      {
        CITY: "KINCARDINE",
      },
      {
        CITY: "KINDERSLEY",
      },
      {
        CITY: "KING CITY",
      },
      {
        CITY: "KINGSEY FALLS",
      },
      {
        CITY: "KINGSTON",
      },
      {
        CITY: "KINGSTON",
      },
      {
        CITY: "KINGSVILLE",
      },
      {
        CITY: "KINGSVILLE",
      },
      {
        CITY: "KINISTINO",
      },
      {
        CITY: "KINUSO",
      },
      {
        CITY: "KIPPEN",
      },
      {
        CITY: "KIRKLAND",
      },
      {
        CITY: "KIRKLAND LAKE",
      },
      {
        CITY: "KIRKTON",
      },
      {
        CITY: "KISBEY",
      },
      {
        CITY: "KISPIOX",
      },
      {
        CITY: "KITCHENER",
      },
      {
        CITY: "KITCHENER",
      },
      {
        CITY: "KITIMAT",
      },
      {
        CITY: "KITSCOTY",
      },
      {
        CITY: "KITWANGA",
      },
      {
        CITY: "KLEMTU",
      },
      {
        CITY: "KNOWLTON",
      },
      {
        CITY: "KOHLER",
      },
      {
        CITY: "KOMOKA",
      },
      {
        CITY: "KUGAARUK",
      },
      {
        CITY: "KUGLUKTUK",
      },
      {
        CITY: "KUUJJUAQ",
      },
      {
        CITY: "KUUJJUARAPIK",
      },
      {
        CITY: "LA BAIE",
      },
      {
        CITY: "LA CONCEPTION",
      },
      {
        CITY: "LA COREY",
      },
      {
        CITY: "LA DURANTAYE",
      },
      {
        CITY: "LA GUADELOUPE",
      },
      {
        CITY: "LA LOCHE",
      },
      {
        CITY: "LA MALBAIE",
      },
      {
        CITY: "LA MORANDIERE",
      },
      {
        CITY: "LA PATRIE",
      },
      {
        CITY: "LA POCATIERE",
      },
      {
        CITY: "LA PRAIRIE",
      },
      {
        CITY: "LA PRESENTATION",
      },
      {
        CITY: "LA RONGE",
      },
      {
        CITY: "LA SALLE",
      },
      {
        CITY: "LA SARRE",
      },
      {
        CITY: "LA TABATIERE",
      },
      {
        CITY: "LA TUQUE",
      },
      {
        CITY: "LA VISITATION",
      },
      {
        CITY: "LABELLE",
      },
      {
        CITY: "LABRADOR CITY",
      },
      {
        CITY: "LAC DU BONNET",
      },
      {
        CITY: "LAC LA BICHE",
      },
      {
        CITY: "LAC-AUX-SABLES",
      },
      {
        CITY: "LAC-DROLET",
      },
      {
        CITY: "LAC-MEGANTIC",
      },
      {
        CITY: "LACHENAIE",
      },
      {
        CITY: "LACHINE",
      },
      {
        CITY: "LACHUTE",
      },
      {
        CITY: "LACOLLE",
      },
      {
        CITY: "LACOMBE",
      },
      {
        CITY: "LADYSMITH",
      },
      {
        CITY: "LADYSMITH",
      },
      {
        CITY: "LAFLECHE",
      },
      {
        CITY: "LAHAVE",
      },
      {
        CITY: "LAKE COWICHAN",
      },
      {
        CITY: "LAKE LENORE",
      },
      {
        CITY: "LAKE LOUISE",
      },
      {
        CITY: "LAKEBURN",
      },
      {
        CITY: "LAKEFIELD",
      },
      {
        CITY: "LAMBTON",
      },
      {
        CITY: "LAMONT",
      },
      {
        CITY: "LAMPMAN",
      },
      {
        CITY: "LANARK",
      },
      {
        CITY: "LANCASTER",
      },
      {
        CITY: "LANCASTER PARK",
      },
      {
        CITY: "LANDRIENNE",
      },
      {
        CITY: "LANG",
      },
      {
        CITY: "LANGDON",
      },
      {
        CITY: "LANGENBURG",
      },
      {
        CITY: "LANGHAM",
      },
      {
        CITY: "LANGLEY",
      },
      {
        CITY: "LANIGAN",
      },
      {
        CITY: "LANORAIE",
      },
      {
        CITY: "LANTZVILLE",
      },
      {
        CITY: "LASALLE",
      },
      {
        CITY: "LASHBURN",
      },
      {
        CITY: "LAURENTIA BEACH",
      },
      {
        CITY: "LAURIER-STATION",
      },
      {
        CITY: "LAVAL",
      },
      {
        CITY: "LAVALTRIE",
      },
      {
        CITY: "LAVERLOCHERE",
      },
      {
        CITY: "LAVILLETTE",
      },
      {
        CITY: "LAWRENCETOWN",
      },
      {
        CITY: "LEADER",
      },
      {
        CITY: "LEAMINGTON",
      },
      {
        CITY: "LEDUC",
      },
      {
        CITY: "LEFROY",
      },
      {
        CITY: "LEGAL",
      },
      {
        CITY: "LEMBERG",
      },
      {
        CITY: "LEONARD",
      },
      {
        CITY: "LERY",
      },
      {
        CITY: "LES CEDRES",
      },
      {
        CITY: "LES COTEAUX",
      },
      {
        CITY: "LES ESCOUMINS",
      },
      {
        CITY: "LES MECHINS",
      },
      {
        CITY: "LESLIEVILLE",
      },
      {
        CITY: "LETHBRIDGE",
      },
      {
        CITY: "LETHBRIDGE",
      },
      {
        CITY: "LEVACK",
      },
      {
        CITY: "LEVIS",
      },
      {
        CITY: "LEWISPORTE",
      },
      {
        CITY: "LILLOOET",
      },
      {
        CITY: "LIMOGES",
      },
      {
        CITY: "LINDSAY",
      },
      {
        CITY: "LIPTON",
      },
      {
        CITY: "LISTOWEL",
      },
      {
        CITY: "LITTLE CURRENT",
      },
      {
        CITY: "LITTLE NARROWS",
      },
      {
        CITY: "LIVELY",
      },
      {
        CITY: "LIVERPOOL",
      },
      {
        CITY: "LLOYDMINSTER",
      },
      {
        CITY: "LOGAN LAKE",
      },
      {
        CITY: "LONDON",
      },
      {
        CITY: "LONDONDERRY",
      },
      {
        CITY: "LONG SAULT",
      },
      {
        CITY: "LONGUEUIL",
      },
      {
        CITY: "LORRAINE",
      },
      {
        CITY: "LOTBINIERE",
      },
      {
        CITY: "LOUGHEED",
      },
      {
        CITY: "LOUISBOURG",
      },
      {
        CITY: "LOUISEVILLE",
      },
      {
        CITY: "LOURDES",
      },
      {
        CITY: "LOWER ISLAND COVE",
      },
      {
        CITY: "LUCAN",
      },
      {
        CITY: "LUCKNOW",
      },
      {
        CITY: "LUCKY LAKE",
      },
      {
        CITY: "LUMBY",
      },
      {
        CITY: "LUMSDEN",
      },
      {
        CITY: "LUMSDEN",
      },
      {
        CITY: "LUNENBURG",
      },
      {
        CITY: "LUSELAND",
      },
      {
        CITY: "LUSKVILLE",
      },
      {
        CITY: "LYN",
      },
      {
        CITY: "LYSTER",
      },
      {
        CITY: "LYTTON",
      },
      {
        CITY: "MABERLY",
      },
      {
        CITY: "MABOU",
      },
      {
        CITY: "MACAMIC",
      },
      {
        CITY: "MACGREGOR",
      },
      {
        CITY: "MACKENZIE",
      },
      {
        CITY: "MACKLIN",
      },
      {
        CITY: "MACTIER",
      },
      {
        CITY: "MADOC",
      },
      {
        CITY: "MAGOG",
      },
      {
        CITY: "MAGRATH",
      },
      {
        CITY: "MAHONE BAY",
      },
      {
        CITY: "MAIDSTONE",
      },
      {
        CITY: "MAIDSTONE",
      },
      {
        CITY: "MALAGAWATCH",
      },
      {
        CITY: "MALAKWA",
      },
      {
        CITY: "MALARTIC",
      },
      {
        CITY: "MALLAIG",
      },
      {
        CITY: "MALTON",
      },
      {
        CITY: "MANDEVILLE",
      },
      {
        CITY: "MANITOU",
      },
      {
        CITY: "MANITOUWADGE",
      },
      {
        CITY: "MANIWAKI",
      },
      {
        CITY: "MANKOTA",
      },
      {
        CITY: "MANNVILLE",
      },
      {
        CITY: "MANOR",
      },
      {
        CITY: "MANOTICK STATION",
      },
      {
        CITY: "MANSEAU",
      },
      {
        CITY: "MANSONVILLE",
      },
      {
        CITY: "MAPLE",
      },
      {
        CITY: "MAPLE CREEK",
      },
      {
        CITY: "MAPLE RIDGE",
      },
      {
        CITY: "MAR",
      },
      {
        CITY: "MARATHON",
      },
      {
        CITY: "MARBLETON",
      },
      {
        CITY: "MARCHAND",
      },
      {
        CITY: "MARGAREE VALLEY",
      },
      {
        CITY: "MARGARETSVILLE",
      },
      {
        CITY: "MARIA",
      },
      {
        CITY: "MARIEVILLE",
      },
      {
        CITY: "MARKDALE",
      },
      {
        CITY: "MARKHAM",
      },
      {
        CITY: "MARKSTAY",
      },
      {
        CITY: "MARMORA",
      },
      {
        CITY: "MARTINVILLE",
      },
      {
        CITY: "MARWAYNE",
      },
      {
        CITY: "MARYFIELD",
      },
      {
        CITY: "MARYSTOWN",
      },
      {
        CITY: "MARYSVILLE",
      },
      {
        CITY: "MASCOUCHE",
      },
      {
        CITY: "MASKINONGE",
      },
      {
        CITY: "MASSET",
      },
      {
        CITY: "MASSEY",
      },
      {
        CITY: "MASSON-ANGERS",
      },
      {
        CITY: "MASSUEVILLE",
      },
      {
        CITY: "MATAGAMI",
      },
      {
        CITY: "MATANE",
      },
      {
        CITY: "MATHESON",
      },
      {
        CITY: "MAXVILLE",
      },
      {
        CITY: "MAYNE",
      },
      {
        CITY: "MCBRIDE",
      },
      {
        CITY: "MCCREARY",
      },
      {
        CITY: "MCGREGOR",
      },
      {
        CITY: "MCLENNAN",
      },
      {
        CITY: "MCMASTERVILLE",
      },
      {
        CITY: "MEADOW LAKE",
      },
      {
        CITY: "MEAFORD",
      },
      {
        CITY: "MEDICINE HAT",
      },
      {
        CITY: "MELBOURNE RIDGE",
      },
      {
        CITY: "MELFORT",
      },
      {
        CITY: "MELITA",
      },
      {
        CITY: "MELOCHEVILLE",
      },
      {
        CITY: "MELVILLE",
      },
      {
        CITY: "MEMPHREMAGOG",
      },
      {
        CITY: "MEMRAMCOOK",
      },
      {
        CITY: "MERCIER",
      },
      {
        CITY: "MERLIN",
      },
      {
        CITY: "MERRICKVILLE",
      },
      {
        CITY: "MERRITT",
      },
      {
        CITY: "MERVILLE",
      },
      {
        CITY: "MESSINES",
      },
      {
        CITY: "METABETCHOUAN-LAC-A-LA-CROIX",
      },
      {
        CITY: "METEGHAN",
      },
      {
        CITY: "METEGHAN RIVER",
      },
      {
        CITY: "METIS-SUR-MER",
      },
      {
        CITY: "MIAMI",
      },
      {
        CITY: "MIDALE",
      },
      {
        CITY: "MIDDLE ARM",
      },
      {
        CITY: "MIDDLE MUSQUODOBOIT",
      },
      {
        CITY: "MIDDLETON",
      },
      {
        CITY: "MIDHURST",
      },
      {
        CITY: "MIDLAND",
      },
      {
        CITY: "MILDEN",
      },
      {
        CITY: "MILDMAY",
      },
      {
        CITY: "MILFORD",
      },
      {
        CITY: "MILFORD STATION",
      },
      {
        CITY: "MILK RIVER",
      },
      {
        CITY: "MILL VILLAGE",
      },
      {
        CITY: "MILLARVILLE",
      },
      {
        CITY: "MILLBROOK",
      },
      {
        CITY: "MILLET",
      },
      {
        CITY: "MILLVILLE",
      },
      {
        CITY: "MILTON",
      },
      {
        CITY: "MILTON",
      },
      {
        CITY: "MILVERTON",
      },
      {
        CITY: "MINDEN",
      },
      {
        CITY: "MINNEDOSA",
      },
      {
        CITY: "MINTO",
      },
      {
        CITY: "MIRA GUT",
      },
      {
        CITY: "MIRABEL",
      },
      {
        CITY: "MIRAMICHI",
      },
      {
        CITY: "MISCOUCHE",
      },
      {
        CITY: "MISSION",
      },
      {
        CITY: "MISSISSAUGA",
      },
      {
        CITY: "MISTATIM",
      },
      {
        CITY: "MITCHELL",
      },
      {
        CITY: "MOBERLY LAKE",
      },
      {
        CITY: "MOBILE",
      },
      {
        CITY: "MOFFAT",
      },
      {
        CITY: "MONCTON",
      },
      {
        CITY: "MONT-JOLI",
      },
      {
        CITY: "MONT-LAURIER",
      },
      {
        CITY: "MONT-ROYAL",
      },
      {
        CITY: "MONT-SAINT-GREGOIRE",
      },
      {
        CITY: "MONT-SAINT-HILAIRE",
      },
      {
        CITY: "MONT-SAINT-MICHEL",
      },
      {
        CITY: "MONT-TREMBLANT",
      },
      {
        CITY: "MONTAGUE",
      },
      {
        CITY: "MONTEBELLO",
      },
      {
        CITY: "MONTMAGNY",
      },
      {
        CITY: "MONTMARTRE",
      },
      {
        CITY: "MONTREAL",
      },
      {
        CITY: "MONTREAL EAST",
      },
      {
        CITY: "MOONBEAM",
      },
      {
        CITY: "MOORETOWN",
      },
      {
        CITY: "MOOSE CREEK",
      },
      {
        CITY: "MOOSE JAW",
      },
      {
        CITY: "MOOSE LAKE",
      },
      {
        CITY: "MOOSEHORN",
      },
      {
        CITY: "MOOSOMIN",
      },
      {
        CITY: "MOOSONEE",
      },
      {
        CITY: "MORAVIANTOWN",
      },
      {
        CITY: "MORDEN",
      },
      {
        CITY: "MORELL",
      },
      {
        CITY: "MOREWOOD",
      },
      {
        CITY: "MORINVILLE",
      },
      {
        CITY: "MORRIN",
      },
      {
        CITY: "MORRIS",
      },
      {
        CITY: "MORRISBURG",
      },
      {
        CITY: "MORTLACH",
      },
      {
        CITY: "MOUNT BRYDGES",
      },
      {
        CITY: "MOUNT ELGIN",
      },
      {
        CITY: "MOUNT FOREST",
      },
      {
        CITY: "MOUNT PEARL",
      },
      {
        CITY: "MOUNT STEWART",
      },
      {
        CITY: "MOUNT UNIACKE",
      },
      {
        CITY: "MOUNTAIN VIEW",
      },
      {
        CITY: "MUIRKIRK",
      },
      {
        CITY: "MULGRAVE",
      },
      {
        CITY: "MUNDARE",
      },
      {
        CITY: "MUNSON",
      },
      {
        CITY: "MUNSTER",
      },
      {
        CITY: "MURDOCHVILLE",
      },
      {
        CITY: "MURRAY HARBOUR",
      },
      {
        CITY: "MURRAY RIVER",
      },
      {
        CITY: "MUSQUODOBOIT HARBOUR",
      },
      {
        CITY: "MYRNAM",
      },
      {
        CITY: "NACKAWIC",
      },
      {
        CITY: "NAICAM",
      },
      {
        CITY: "NAKINA",
      },
      {
        CITY: "NAKUSP",
      },
      {
        CITY: "NAMPA",
      },
      {
        CITY: "NAMUR",
      },
      {
        CITY: "NANAIMO",
      },
      {
        CITY: "NANOOSE BAY",
      },
      {
        CITY: "NANTES",
      },
      {
        CITY: "NANTON",
      },
      {
        CITY: "NAPANEE",
      },
      {
        CITY: "NAPANEE",
      },
      {
        CITY: "NAPIERVILLE",
      },
      {
        CITY: "NAUWIGEWAUK",
      },
      {
        CITY: "NEEPAWA",
      },
      {
        CITY: "NEGUAC",
      },
      {
        CITY: "NEILBURG",
      },
      {
        CITY: "NELSON",
      },
      {
        CITY: "NEPEAN",
      },
      {
        CITY: "NEUDORF",
      },
      {
        CITY: "NEUVILLE",
      },
      {
        CITY: "NEW AIYANSH",
      },
      {
        CITY: "NEW CARLISLE",
      },
      {
        CITY: "NEW DENVER",
      },
      {
        CITY: "NEW DUNDEE",
      },
      {
        CITY: "NEW GLASGOW",
      },
      {
        CITY: "NEW HAMBURG",
      },
      {
        CITY: "NEW HARBOUR",
      },
      {
        CITY: "NEW LISKEARD",
      },
      {
        CITY: "NEW ROSS",
      },
      {
        CITY: "NEW WATERFORD",
      },
      {
        CITY: "NEW WESTMINSTER",
      },
      {
        CITY: "NEW-RICHMOND",
      },
      {
        CITY: "NEW-WES-VALLEY",
      },
      {
        CITY: "NEWBURGH",
      },
      {
        CITY: "NEWCASTLE CREEK",
      },
      {
        CITY: "NEWCASTLE VILLAGE",
      },
      {
        CITY: "NEWDALE",
      },
      {
        CITY: "NEWMARKET",
      },
      {
        CITY: "NEWPORT",
      },
      {
        CITY: "NEWTON",
      },
      {
        CITY: "NEWTOWN",
      },
      {
        CITY: "NIAGARA",
      },
      {
        CITY: "NIAGARA FALLS",
      },
      {
        CITY: "NIAGARA-ON-THE-LAKE",
      },
      {
        CITY: "NICOLET",
      },
      {
        CITY: "NIPAWIN",
      },
      {
        CITY: "NIPIGON",
      },
      {
        CITY: "NISKU",
      },
      {
        CITY: "NIVERVILLE",
      },
      {
        CITY: "NOBLEFORD",
      },
      {
        CITY: "NOBLETON",
      },
      {
        CITY: "NOKOMIS",
      },
      {
        CITY: "NOMININGUE",
      },
      {
        CITY: "NORFOLK COUNTY",
      },
      {
        CITY: "NORMAN WELLS",
      },
      {
        CITY: "NORMANDIN",
      },
      {
        CITY: "NORQUAY",
      },
      {
        CITY: "NORTH BATTLEFORD",
      },
      {
        CITY: "NORTH BAY",
      },
      {
        CITY: "NORTH DELTA",
      },
      {
        CITY: "NORTH HATLEY",
      },
      {
        CITY: "NORTH LUNENBURG",
      },
      {
        CITY: "NORTH PERTH",
      },
      {
        CITY: "NORTH PORTAL",
      },
      {
        CITY: "NORTH RUSSELL",
      },
      {
        CITY: "NORTH RUSTICO",
      },
      {
        CITY: "NORTH SAANICH",
      },
      {
        CITY: "NORTH STAR",
      },
      {
        CITY: "NORTH SYDNEY",
      },
      {
        CITY: "NORTH VANCOUVER",
      },
      {
        CITY: "NORTH YORK",
      },
      {
        CITY: "NORVAL",
      },
      {
        CITY: "NORWAY HOUSE",
      },
      {
        CITY: "NORWICH",
      },
      {
        CITY: "NORWOOD",
      },
      {
        CITY: "NOTRE-DAME-DE-LOURDES",
      },
      {
        CITY: "NOTRE-DAME-DU-BON-CONSEIL",
      },
      {
        CITY: "NOTRE-DAME-DU-MONT-CARMEL",
      },
      {
        CITY: "NOTRE-DAME-DU-NORD",
      },
      {
        CITY: "NOTRE-DAME-DU-PORTAGE",
      },
      {
        CITY: "NOUVELLE",
      },
      {
        CITY: "NOYAN",
      },
      {
        CITY: "OAK LAKE",
      },
      {
        CITY: "OAK POINT",
      },
      {
        CITY: "OAKVILLE",
      },
      {
        CITY: "OBEDJIWAN",
      },
      {
        CITY: "ODESSA",
      },
      {
        CITY: "OGOKI",
      },
      {
        CITY: "OHSWEKEN",
      },
      {
        CITY: "OIL SPRINGS",
      },
      {
        CITY: "OKA",
      },
      {
        CITY: "OKANAGAN CENTRE",
      },
      {
        CITY: "OKOTOKS",
      },
      {
        CITY: "OLD PERLICAN",
      },
      {
        CITY: "OLDCASTLE",
      },
      {
        CITY: "OLDS",
      },
      {
        CITY: "OLIVER",
      },
      {
        CITY: "OMEMEE",
      },
      {
        CITY: "OMERVILLE",
      },
      {
        CITY: "ONANOLE",
      },
      {
        CITY: "ONION LAKE",
      },
      {
        CITY: "ONOWAY",
      },
      {
        CITY: "ORANGEVILLE",
      },
      {
        CITY: "ORILLIA",
      },
      {
        CITY: "ORLEANS",
      },
      {
        CITY: "ORMSTOWN",
      },
      {
        CITY: "OROMOCTO",
      },
      {
        CITY: "ORONO",
      },
      {
        CITY: "ORTON",
      },
      {
        CITY: "OSHAWA",
      },
      {
        CITY: "OSOYOOS",
      },
      {
        CITY: "OTTAWA",
      },
      {
        CITY: "OTTER LAKE",
      },
      {
        CITY: "OTTERBURN PARK",
      },
      {
        CITY: "OTTERBURNE",
      },
      {
        CITY: "OUTLOOK",
      },
      {
        CITY: "OWEN SOUND",
      },
      {
        CITY: "OXBOW",
      },
      {
        CITY: "OXDRIFT",
      },
      {
        CITY: "OXFORD",
      },
      {
        CITY: "OXFORD HOUSE",
      },
      {
        CITY: "OYAMA",
      },
      {
        CITY: "PAIN COURT",
      },
      {
        CITY: "PAISLEY",
      },
      {
        CITY: "PAKENHAM",
      },
      {
        CITY: "PALMAROLLE",
      },
      {
        CITY: "PALMERSTON",
      },
      {
        CITY: "PAPINEAUVILLE",
      },
      {
        CITY: "PARADISE COVE",
      },
      {
        CITY: "PARADISE VALLEY",
      },
      {
        CITY: "PARHAM",
      },
      {
        CITY: "PARIS",
      },
      {
        CITY: "PARISVILLE",
      },
      {
        CITY: "PARKHILL",
      },
      {
        CITY: "PARKSVILLE",
      },
      {
        CITY: "PARRY SOUND",
      },
      {
        CITY: "PARSON",
      },
      {
        CITY: "PASADENA",
      },
      {
        CITY: "PASCAL",
      },
      {
        CITY: "PASPEBIAC",
      },
      {
        CITY: "PASS LAKE",
      },
      {
        CITY: "PAULATUK",
      },
      {
        CITY: "PEACE RIVER",
      },
      {
        CITY: "PEACHLAND",
      },
      {
        CITY: "PEFFERLAW",
      },
      {
        CITY: "PELLY",
      },
      {
        CITY: "PEMBERTON",
      },
      {
        CITY: "PEMBROKE",
      },
      {
        CITY: "PENETANGUISHENE",
      },
      {
        CITY: "PENHOLD",
      },
      {
        CITY: "PENNFIELD",
      },
      {
        CITY: "PENOBSQUIS",
      },
      {
        CITY: "PENTICTON",
      },
      {
        CITY: "PENZANCE",
      },
      {
        CITY: "PERTH",
      },
      {
        CITY: "PERTH",
      },
      {
        CITY: "PERTH-ANDOVER",
      },
      {
        CITY: "PETAWAWA",
      },
      {
        CITY: "PETERBOROUGH",
      },
      {
        CITY: "PETHERTON",
      },
      {
        CITY: "PETIT-DE-GRAT",
      },
      {
        CITY: "PETITCODIAC",
      },
      {
        CITY: "PETITE-RIVIERE-SAINT-FRANCOIS",
      },
      {
        CITY: "PETROLIA",
      },
      {
        CITY: "PICKERING",
      },
      {
        CITY: "PICTON",
      },
      {
        CITY: "PICTOU",
      },
      {
        CITY: "PIERREVILLE",
      },
      {
        CITY: "PIERSON",
      },
      {
        CITY: "PIKANGIKUM",
      },
      {
        CITY: "PILOT BUTTE",
      },
      {
        CITY: "PILOT MOUND",
      },
      {
        CITY: "PINANTAN LAKE",
      },
      {
        CITY: "PINAWA",
      },
      {
        CITY: "PINCHER CREEK",
      },
      {
        CITY: "PINCOURT",
      },
      {
        CITY: "PINE FALLS",
      },
      {
        CITY: "PINEY",
      },
      {
        CITY: "PITT MEADOWS",
      },
      {
        CITY: "PLACENTIA",
      },
      {
        CITY: "PLAMONDON",
      },
      {
        CITY: "PLANTAGENET STATION",
      },
      {
        CITY: "PLASTER ROCK",
      },
      {
        CITY: "PLESSISVILLE",
      },
      {
        CITY: "PLUM COULEE",
      },
      {
        CITY: "PLUMAS",
      },
      {
        CITY: "PLYMPTON",
      },
      {
        CITY: "POINT EDWARD",
      },
      {
        CITY: "POINTE-A-LA-CROIX",
      },
      {
        CITY: "POINTE-AUX-TREMBLES",
      },
      {
        CITY: "POINTE-CALUMET",
      },
      {
        CITY: "POINTE-CLAIRE",
      },
      {
        CITY: "POINTE-FORTUNE",
      },
      {
        CITY: "POINTE-LEBEL",
      },
      {
        CITY: "POINTE-VERTE",
      },
      {
        CITY: "PONOKA",
      },
      {
        CITY: "PONT-ROUGE",
      },
      {
        CITY: "PONT-VIAU",
      },
      {
        CITY: "PONTYPOOL",
      },
      {
        CITY: "POPLAR HILL",
      },
      {
        CITY: "PORT ALBERNI",
      },
      {
        CITY: "PORT ALICE",
      },
      {
        CITY: "PORT AU CHOIX",
      },
      {
        CITY: "PORT AU PORT",
      },
      {
        CITY: "PORT BURWELL",
      },
      {
        CITY: "PORT CLEMENTS",
      },
      {
        CITY: "PORT COLBORNE",
      },
      {
        CITY: "PORT COQUITLAM",
      },
      {
        CITY: "PORT CREDIT",
      },
      {
        CITY: "PORT DOVER",
      },
      {
        CITY: "PORT EDWARD",
      },
      {
        CITY: "PORT ELGIN",
      },
      {
        CITY: "PORT ELGIN",
      },
      {
        CITY: "PORT HARDY",
      },
      {
        CITY: "PORT HAWKESBURY",
      },
      {
        CITY: "PORT HOPE",
      },
      {
        CITY: "PORT LORNE",
      },
      {
        CITY: "PORT MCNEILL",
      },
      {
        CITY: "PORT MCNICOLL",
      },
      {
        CITY: "PORT MEDWAY",
      },
      {
        CITY: "PORT MOODY",
      },
      {
        CITY: "PORT MORIEN",
      },
      {
        CITY: "PORT PERRY",
      },
      {
        CITY: "PORT RENFREW",
      },
      {
        CITY: "PORT ROYAL",
      },
      {
        CITY: "PORT SAUNDERS",
      },
      {
        CITY: "PORT STANLEY",
      },
      {
        CITY: "PORT-CARTIER",
      },
      {
        CITY: "PORT-DANIEL",
      },
      {
        CITY: "PORTAGE",
      },
      {
        CITY: "PORTAGE LA PRAIRIE",
      },
      {
        CITY: "PORTAGE-DU-FORT",
      },
      {
        CITY: "PORTERS LAKE",
      },
      {
        CITY: "PORTNEUF",
      },
      {
        CITY: "PORTUGAL COVE",
      },
      {
        CITY: "POTTER",
      },
      {
        CITY: "POUCE COUPE",
      },
      {
        CITY: "POUCH COVE",
      },
      {
        CITY: "POWASSAN",
      },
      {
        CITY: "POWELL RIVER",
      },
      {
        CITY: "PREECEVILLE",
      },
      {
        CITY: "PRESCOTT",
      },
      {
        CITY: "PRICE",
      },
      {
        CITY: "PRICEVILLE",
      },
      {
        CITY: "PRINCE",
      },
      {
        CITY: "PRINCE ALBERT",
      },
      {
        CITY: "PRINCE GEORGE",
      },
      {
        CITY: "PRINCE RUPERT",
      },
      {
        CITY: "PRINCETON",
      },
      {
        CITY: "PRINCEVILLE",
      },
      {
        CITY: "PRITCHARD",
      },
      {
        CITY: "PROSPECT",
      },
      {
        CITY: "PROVOST",
      },
      {
        CITY: "PUGWASH",
      },
      {
        CITY: "PUKATAWAGAN",
      },
      {
        CITY: "PUSLINCH",
      },
      {
        CITY: "PUVIRNITUQ",
      },
      {
        CITY: "PYNNS",
      },
      {
        CITY: "QUALICUM BEACH",
      },
      {
        CITY: "QUAQTAQ",
      },
      {
        CITY: "QUATHIASKI COVE",
      },
      {
        CITY: "QUEBEC",
      },
      {
        CITY: "QUEEN CHARLOTTE",
      },
      {
        CITY: "QUESNEL",
      },
      {
        CITY: "QUILL LAKE",
      },
      {
        CITY: "QUISPAMSIS",
      },
      {
        CITY: "QUYON",
      },
      {
        CITY: "RACINE",
      },
      {
        CITY: "RADISSON",
      },
      {
        CITY: "RADVILLE",
      },
      {
        CITY: "RAINBOW LAKE",
      },
      {
        CITY: "RALEIGH",
      },
      {
        CITY: "RAMEA",
      },
      {
        CITY: "RANKIN INLET",
      },
      {
        CITY: "RAPID CITY",
      },
      {
        CITY: "RAWDON",
      },
      {
        CITY: "RAYMOND",
      },
      {
        CITY: "RAYMORE",
      },
      {
        CITY: "RED DEER",
      },
      {
        CITY: "RED LAKE",
      },
      {
        CITY: "RED ROCK",
      },
      {
        CITY: "REDCLIFF",
      },
      {
        CITY: "REDVERS",
      },
      {
        CITY: "REDWATER",
      },
      {
        CITY: "REGINA",
      },
      {
        CITY: "RENCONTRE EAST",
      },
      {
        CITY: "RENFREW",
      },
      {
        CITY: "RENOUS",
      },
      {
        CITY: "REPENTIGNY",
      },
      {
        CITY: "REVELSTOKE",
      },
      {
        CITY: "REXDALE",
      },
      {
        CITY: "RHEIN",
      },
      {
        CITY: "RICHIBUCTO",
      },
      {
        CITY: "RICHMOND",
      },
      {
        CITY: "RICHMOND",
      },
      {
        CITY: "RICHMOND",
      },
      {
        CITY: "RICHMOND HILL",
      },
      {
        CITY: "RIDGETOWN",
      },
      {
        CITY: "RIDGEWAY",
      },
      {
        CITY: "RIGAUD",
      },
      {
        CITY: "RIMBEY",
      },
      {
        CITY: "RIMOUSKI",
      },
      {
        CITY: "RIPLEY",
      },
      {
        CITY: "RIPON",
      },
      {
        CITY: "RIPPLES",
      },
      {
        CITY: "RIVER HEBERT",
      },
      {
        CITY: "RIVER JOHN",
      },
      {
        CITY: "RIVERS",
      },
      {
        CITY: "RIVERSIDE-ALBERT",
      },
      {
        CITY: "RIVERTON",
      },
      {
        CITY: "RIVERVIEW",
      },
      {
        CITY: "RIVIERE-BEAUDETTE",
      },
      {
        CITY: "RIVIERE-BLEUE",
      },
      {
        CITY: "RIVIERE-OUELLE",
      },
      {
        CITY: "RIVIERE-ROUGE",
      },
      {
        CITY: "RIVIERE-SAINT-PAUL",
      },
      {
        CITY: "ROBERTS CREEK",
      },
      {
        CITY: "ROBERVAL",
      },
      {
        CITY: "ROBLIN",
      },
      {
        CITY: "ROBLIN",
      },
      {
        CITY: "ROCANVILLE",
      },
      {
        CITY: "ROCKLAND",
      },
      {
        CITY: "ROCKVILLE",
      },
      {
        CITY: "ROCKWOOD",
      },
      {
        CITY: "ROCKY MOUNTAIN HOUSE",
      },
      {
        CITY: "RODDICKTON",
      },
      {
        CITY: "ROGERSVILLE",
      },
      {
        CITY: "ROLAND",
      },
      {
        CITY: "ROSALIND",
      },
      {
        CITY: "ROSEDALE",
      },
      {
        CITY: "ROSEMARY",
      },
      {
        CITY: "ROSEMERE",
      },
      {
        CITY: "ROSENEATH",
      },
      {
        CITY: "ROSETOWN",
      },
      {
        CITY: "ROSSBURN",
      },
      {
        CITY: "ROSSLAND",
      },
      {
        CITY: "ROSSPORT",
      },
      {
        CITY: "ROSTHERN",
      },
      {
        CITY: "ROTHESAY",
      },
      {
        CITY: "ROUGEMONT",
      },
      {
        CITY: "ROULEAU",
      },
      {
        CITY: "ROUYN-NORANDA",
      },
      {
        CITY: "ROXBORO",
      },
      {
        CITY: "ROXTON FALLS",
      },
      {
        CITY: "ROXTON POND",
      },
      {
        CITY: "RUMSEY",
      },
      {
        CITY: "RUSSELL",
      },
      {
        CITY: "RYCROFT",
      },
      {
        CITY: "SAANICHTON",
      },
      {
        CITY: "SABLE RIVER",
      },
      {
        CITY: "SABREVOIS",
      },
      {
        CITY: "SACKVILLE",
      },
      {
        CITY: "SAGUENAY",
      },
      {
        CITY: "SAINT ANDREWS",
      },
      {
        CITY: "SAINT ETIENNE-DE-LAUZON",
      },
      {
        CITY: "SAINT JOHN",
      },
      {
        CITY: "SAINT ROMUALD",
      },
      {
        CITY: "SAINT-ADALBERT",
      },
      {
        CITY: "SAINT-ADELPHE",
      },
      {
        CITY: "SAINT-AGAPIT",
      },
      {
        CITY: "SAINT-AIME-DU-LAC-DES-ILES",
      },
      {
        CITY: "SAINT-ALBAN",
      },
      {
        CITY: "SAINT-ALEXIS-DES-MONTS",
      },
      {
        CITY: "SAINT-AMBROISE",
      },
      {
        CITY: "SAINT-ANACLET-DE-LESSARD",
      },
      {
        CITY: "SAINT-ANDRE",
      },
      {
        CITY: "SAINT-ANDRE-AVELLIN",
      },
      {
        CITY: "SAINT-ANICET",
      },
      {
        CITY: "SAINT-ANSELME",
      },
      {
        CITY: "SAINT-ANTOINE-DE-TILLY",
      },
      {
        CITY: "SAINT-ANTOINE-SUR-RICHELIEU",
      },
      {
        CITY: "SAINT-ANTONIN",
      },
      {
        CITY: "SAINT-APOLLINAIRE",
      },
      {
        CITY: "SAINT-ARSENE",
      },
      {
        CITY: "SAINT-AUBERT",
      },
      {
        CITY: "SAINT-AUGUSTIN",
      },
      {
        CITY: "SAINT-AUGUSTIN-DE-DESMAURES",
      },
      {
        CITY: "SAINT-BARNABE-SUD",
      },
      {
        CITY: "SAINT-BARTHELEMY",
      },
      {
        CITY: "SAINT-BASILE",
      },
      {
        CITY: "SAINT-BASILE-LE-GRAND",
      },
      {
        CITY: "SAINT-BENJAMIN",
      },
      {
        CITY: "SAINT-BRUNO",
      },
      {
        CITY: "SAINT-BRUNO-DE-MONTARVILLE",
      },
      {
        CITY: "SAINT-CAMILLE",
      },
      {
        CITY: "SAINT-CAMILLE",
      },
      {
        CITY: "SAINT-CASIMIR",
      },
      {
        CITY: "SAINT-CESAIRE",
      },
      {
        CITY: "SAINT-CHARLES-DE-BELLECHASSE",
      },
      {
        CITY: "SAINT-CHARLES-SUR-RICHELIEU",
      },
      {
        CITY: "SAINT-CLEMENT",
      },
      {
        CITY: "SAINT-COLOMBAN",
      },
      {
        CITY: "SAINT-COME",
      },
      {
        CITY: "SAINT-COME--LINIERE",
      },
      {
        CITY: "SAINT-CYPRIEN",
      },
      {
        CITY: "SAINT-CYRILLE-DE-WENDOVER",
      },
      {
        CITY: "SAINT-DAMASE",
      },
      {
        CITY: "SAINT-DENIS",
      },
      {
        CITY: "SAINT-DENIS",
      },
      {
        CITY: "SAINT-DOMINIQUE",
      },
      {
        CITY: "SAINT-DONAT",
      },
      {
        CITY: "SAINT-ELIE",
      },
      {
        CITY: "SAINT-ELZEAR",
      },
      {
        CITY: "SAINT-ETIENNE-DES-GRES",
      },
      {
        CITY: "SAINT-EUSEBE",
      },
      {
        CITY: "SAINT-EUSTACHE",
      },
      {
        CITY: "SAINT-FABIEN",
      },
      {
        CITY: "SAINT-FABIEN-DE-PANET",
      },
      {
        CITY: "SAINT-FAUSTIN--LAC-CARRE",
      },
      {
        CITY: "SAINT-FELICIEN",
      },
      {
        CITY: "SAINT-FELIX-DE-KINGSEY",
      },
      {
        CITY: "SAINT-FELIX-DE-VALOIS",
      },
      {
        CITY: "SAINT-FERDINAND",
      },
      {
        CITY: "SAINT-FERREOL-LES-NEIGES",
      },
      {
        CITY: "SAINT-FLAVIEN",
      },
      {
        CITY: "SAINT-FRANCOIS-DU-LAC",
      },
      {
        CITY: "SAINT-FREDERIC",
      },
      {
        CITY: "SAINT-GABRIEL",
      },
      {
        CITY: "SAINT-GABRIEL-DE-VALCARTIER",
      },
      {
        CITY: "SAINT-GEDEON",
      },
      {
        CITY: "SAINT-GEORGES",
      },
      {
        CITY: "SAINT-GEORGES-DE-WINDSOR",
      },
      {
        CITY: "SAINT-GERMAIN-DE-GRANTHAM",
      },
      {
        CITY: "SAINT-GILLES",
      },
      {
        CITY: "SAINT-GODEFROI",
      },
      {
        CITY: "SAINT-GUILLAUME",
      },
      {
        CITY: "SAINT-HENRI",
      },
      {
        CITY: "SAINT-HILAIRE",
      },
      {
        CITY: "SAINT-HONORE-DE-TEMISCOUATA",
      },
      {
        CITY: "SAINT-HUBERT-DE-RIVIERE-DU-LOUP",
      },
      {
        CITY: "SAINT-HUGUES",
      },
      {
        CITY: "SAINT-HYACINTHE",
      },
      {
        CITY: "SAINT-IRENEE",
      },
      {
        CITY: "SAINT-ISIDORE",
      },
      {
        CITY: "SAINT-JACQUES",
      },
      {
        CITY: "SAINT-JEAN-DE-DIEU",
      },
      {
        CITY: "SAINT-JEAN-DE-MATHA",
      },
      {
        CITY: "SAINT-JEAN-PORT-JOLI",
      },
      {
        CITY: "SAINT-JEAN-SUR-RICHELIEU",
      },
      {
        CITY: "SAINT-JEROME",
      },
      {
        CITY: "SAINT-JOACHIM",
      },
      {
        CITY: "SAINT-JOACHIM-DE-SHEFFORD",
      },
      {
        CITY: "SAINT-JOSEPH-DE-BEAUCE",
      },
      {
        CITY: "SAINT-JUDE",
      },
      {
        CITY: "SAINT-LAMBERT",
      },
      {
        CITY: "SAINT-LAMBERT",
      },
      {
        CITY: "SAINT-LAZARE-DE-BELLECHASSE",
      },
      {
        CITY: "SAINT-LEON-DE-STANDON",
      },
      {
        CITY: "SAINT-LEONARD",
      },
      {
        CITY: "SAINT-LIBOIRE",
      },
      {
        CITY: "SAINT-LIN",
      },
      {
        CITY: "SAINT-LIN-LAURENTIDES",
      },
      {
        CITY: "SAINT-LOUIS DE KENT",
      },
      {
        CITY: "SAINT-LOUIS-DE-GONZAGUE",
      },
      {
        CITY: "SAINT-LUC",
      },
      {
        CITY: "SAINT-LUDGER",
      },
      {
        CITY: "SAINT-MALACHIE",
      },
      {
        CITY: "SAINT-MARC-DE-FIGUERY",
      },
      {
        CITY: "SAINT-MARC-DES-CARRIERES",
      },
      {
        CITY: "SAINT-MARC-SUR-RICHELIEU",
      },
      {
        CITY: "SAINT-MATHIEU",
      },
      {
        CITY: "SAINT-MAURICE",
      },
      {
        CITY: "SAINT-MICHEL",
      },
      {
        CITY: "SAINT-MICHEL-DE-BELLECHASSE",
      },
      {
        CITY: "SAINT-MICHEL-DES-SAINTS",
      },
      {
        CITY: "SAINT-NAZAIRE",
      },
      {
        CITY: "SAINT-ODILON-DE-CRANBOURNE",
      },
      {
        CITY: "SAINT-PACOME",
      },
      {
        CITY: "SAINT-PAMPHILE",
      },
      {
        CITY: "SAINT-PASCAL",
      },
      {
        CITY: "SAINT-PAUL",
      },
      {
        CITY: "SAINT-PAUL",
      },
      {
        CITY: "SAINT-PAUL-DE-LA-CROIX",
      },
      {
        CITY: "SAINT-PAULIN",
      },
      {
        CITY: "SAINT-PHILEMON",
      },
      {
        CITY: "SAINT-PHILIPPE",
      },
      {
        CITY: "SAINT-PHILIPPE-DE-NERI",
      },
      {
        CITY: "SAINT-PIE",
      },
      {
        CITY: "SAINT-PIERRE",
      },
      {
        CITY: "SAINT-PIERRE-DE-BROUGHTON",
      },
      {
        CITY: "SAINT-PIERRE-LES-BECQUETS",
      },
      {
        CITY: "SAINT-PLACIDE",
      },
      {
        CITY: "SAINT-POLYCARPE",
      },
      {
        CITY: "SAINT-PROSPER",
      },
      {
        CITY: "SAINT-QUENTIN",
      },
      {
        CITY: "SAINT-RAPHAEL",
      },
      {
        CITY: "SAINT-RAYMOND",
      },
      {
        CITY: "SAINT-REMI",
      },
      {
        CITY: "SAINT-ROCH",
      },
      {
        CITY: "SAINT-ROCH-DE-RICHELIEU",
      },
      {
        CITY: "SAINT-ROCH-DES-AULNAIES",
      },
      {
        CITY: "SAINT-SAUVEUR",
      },
      {
        CITY: "SAINT-SEBASTIEN",
      },
      {
        CITY: "SAINT-SEVERE",
      },
      {
        CITY: "SAINT-SIMON-DE-RIMOUSKI",
      },
      {
        CITY: "SAINT-STANISLAS",
      },
      {
        CITY: "SAINT-STANISLAS-DE-KOSTKA",
      },
      {
        CITY: "SAINT-SYLVERE",
      },
      {
        CITY: "SAINT-SYLVESTRE",
      },
      {
        CITY: "SAINT-TELESPHORE",
      },
      {
        CITY: "SAINT-THOMAS",
      },
      {
        CITY: "SAINT-TITE",
      },
      {
        CITY: "SAINT-UBALDE",
      },
      {
        CITY: "SAINT-ULRIC",
      },
      {
        CITY: "SAINT-URBAIN",
      },
      {
        CITY: "SAINT-URBAIN-PREMIER",
      },
      {
        CITY: "SAINT-VALLIER",
      },
      {
        CITY: "SAINT-VENANT-DE-PAQUETTE",
      },
      {
        CITY: "SAINT-VICTOR",
      },
      {
        CITY: "SAINT-WENCESLAS",
      },
      {
        CITY: "SAINT-ZACHARIE",
      },
      {
        CITY: "SAINT-ZENON",
      },
      {
        CITY: "SAINT-ZOTIQUE",
      },
      {
        CITY: "SAINTE-ADELE",
      },
      {
        CITY: "SAINTE-AGATHE-DES-MONTS",
      },
      {
        CITY: "SAINTE-ANGELE-DE-PREMONT",
      },
      {
        CITY: "SAINTE-ANNE-DE-BEAUPRE",
      },
      {
        CITY: "SAINTE-ANNE-DE-BELLEVUE",
      },
      {
        CITY: "SAINTE-ANNE-DE-LA-PERADE",
      },
      {
        CITY: "SAINTE-ANNE-DES-MONTS",
      },
      {
        CITY: "SAINTE-ANNE-DU-LAC",
      },
      {
        CITY: "SAINTE-BRIGITTE-DES-SAULTS",
      },
      {
        CITY: "SAINTE-CATHERINE",
      },
      {
        CITY: "SAINTE-CECILE-DE-MASHAM",
      },
      {
        CITY: "SAINTE-CECILE-DE-WHITTON",
      },
      {
        CITY: "SAINTE-CLAIRE",
      },
      {
        CITY: "SAINTE-CLOTILDE-DE-HORTON",
      },
      {
        CITY: "SAINTE-CROIX",
      },
      {
        CITY: "SAINTE-FAMILLE",
      },
      {
        CITY: "SAINTE-FOY",
      },
      {
        CITY: "SAINTE-GERTRUDE",
      },
      {
        CITY: "SAINTE-HELENE-DE-BAGOT",
      },
      {
        CITY: "SAINTE-HELENE-DE-BREAKEYVILLE",
      },
      {
        CITY: "SAINTE-JULIE",
      },
      {
        CITY: "SAINTE-JULIENNE",
      },
      {
        CITY: "SAINTE-JUSTINE",
      },
      {
        CITY: "SAINTE-MARCELLINE-DE-KILDARE",
      },
      {
        CITY: "SAINTE-MARGUERITE",
      },
      {
        CITY: "SAINTE-MARIE",
      },
      {
        CITY: "SAINTE-MARTHE",
      },
      {
        CITY: "SAINTE-MARTHE-SUR-LE-LAC",
      },
      {
        CITY: "SAINTE-MARTINE",
      },
      {
        CITY: "SAINTE-MELANIE",
      },
      {
        CITY: "SAINTE-MONIQUE",
      },
      {
        CITY: "SAINTE-PERPETUE",
      },
      {
        CITY: "SAINTE-THECLE",
      },
      {
        CITY: "SAINTE-THERESE",
      },
      {
        CITY: "SALABERRY-DE-VALLEYFIELD",
      },
      {
        CITY: "SALISBURY",
      },
      {
        CITY: "SALMO",
      },
      {
        CITY: "SALMON ARM",
      },
      {
        CITY: "SALMON RIVER",
      },
      {
        CITY: "SANDY BAY",
      },
      {
        CITY: "SANDY LAKE",
      },
      {
        CITY: "SANIKILUAQ",
      },
      {
        CITY: "SARDIS",
      },
      {
        CITY: "SARNIA",
      },
      {
        CITY: "SASKATOON",
      },
      {
        CITY: "SAULNIERVILLE",
      },
      {
        CITY: "SAULT STE. MARIE",
      },
      {
        CITY: "SAVONA",
      },
      {
        CITY: "SAWYERVILLE",
      },
      {
        CITY: "SAYABEC",
      },
      {
        CITY: "SAYWARD",
      },
      {
        CITY: "SCARBOROUGH",
      },
      {
        CITY: "SCHEFFERVILLE",
      },
      {
        CITY: "SCHOMBERG",
      },
      {
        CITY: "SCHREIBER",
      },
      {
        CITY: "SCOTLAND",
      },
      {
        CITY: "SCOTSBURN",
      },
      {
        CITY: "SCOTSTOWN",
      },
      {
        CITY: "SCOTT",
      },
      {
        CITY: "SCOUDOUC",
      },
      {
        CITY: "SEAFORTH",
      },
      {
        CITY: "SEARCHMONT",
      },
      {
        CITY: "SECHELT",
      },
      {
        CITY: "SELDOM-LITTLE SELDOM",
      },
      {
        CITY: "SELKIRK",
      },
      {
        CITY: "SENNETERRE",
      },
      {
        CITY: "SENNEVILLE",
      },
      {
        CITY: "SEPT-ILES",
      },
      {
        CITY: "SETON PORTAGE",
      },
      {
        CITY: "SEVERN BRIDGE",
      },
      {
        CITY: "SEXSMITH",
      },
      {
        CITY: "SHAMATTAWA",
      },
      {
        CITY: "SHANKLIN",
      },
      {
        CITY: "SHANNON",
      },
      {
        CITY: "SHANNONVILLE",
      },
      {
        CITY: "SHARBOT LAKE",
      },
      {
        CITY: "SHAUNAVON",
      },
      {
        CITY: "SHAWINIGAN",
      },
      {
        CITY: "SHAWINIGAN-SUD",
      },
      {
        CITY: "SHAWNIGAN LAKE",
      },
      {
        CITY: "SHAWVILLE",
      },
      {
        CITY: "SHEDIAC",
      },
      {
        CITY: "SHEHO",
      },
      {
        CITY: "SHELBURNE",
      },
      {
        CITY: "SHELBURNE",
      },
      {
        CITY: "SHELLBROOK",
      },
      {
        CITY: "SHERBROOKE",
      },
      {
        CITY: "SHERBROOKE",
      },
      {
        CITY: "SHERRINGTON",
      },
      {
        CITY: "SHERWOOD PARK",
      },
      {
        CITY: "SHIGAWAKE",
      },
      {
        CITY: "SHOAL LAKE",
      },
      {
        CITY: "SHUBENACADIE",
      },
      {
        CITY: "SICAMOUS",
      },
      {
        CITY: "SIDNEY",
      },
      {
        CITY: "SILLERY",
      },
      {
        CITY: "SILTON",
      },
      {
        CITY: "SIMCOE",
      },
      {
        CITY: "SIOUX LOOKOUT",
      },
      {
        CITY: "SKIDEGATE",
      },
      {
        CITY: "SLAVE LAKE",
      },
      {
        CITY: "SLOCAN",
      },
      {
        CITY: "SMITHERS",
      },
      {
        CITY: "SMITHS FALLS",
      },
      {
        CITY: "SMITHVILLE",
      },
      {
        CITY: "SMOKY LAKE",
      },
      {
        CITY: "SMOOTH ROCK FALLS",
      },
      {
        CITY: "SNOW LAKE",
      },
      {
        CITY: "SOINTULA",
      },
      {
        CITY: "SOMBRA",
      },
      {
        CITY: "SOOKE",
      },
      {
        CITY: "SOREL",
      },
      {
        CITY: "SOREL-TRACY",
      },
      {
        CITY: "SORRENTO",
      },
      {
        CITY: "SOURIS",
      },
      {
        CITY: "SOURIS",
      },
      {
        CITY: "SOUTH AUGUSTA",
      },
      {
        CITY: "SOUTH GOWER",
      },
      {
        CITY: "SOUTH INDIAN LAKE",
      },
      {
        CITY: "SOUTH LANCASTER",
      },
      {
        CITY: "SOUTH MOUNTAIN",
      },
      {
        CITY: "SOUTH PORCUPINE",
      },
      {
        CITY: "SOUTH RIVER",
      },
      {
        CITY: "SOUTH SLOCAN",
      },
      {
        CITY: "SOUTHEND REINDEER",
      },
      {
        CITY: "SOUTHEY",
      },
      {
        CITY: "SPANISH",
      },
      {
        CITY: "SPARTA",
      },
      {
        CITY: "SPENCERVILLE",
      },
      {
        CITY: "SPERLING",
      },
      {
        CITY: "SPILLIMACHEEN",
      },
      {
        CITY: "SPIRIT RIVER",
      },
      {
        CITY: "SPIRITWOOD",
      },
      {
        CITY: "SPRING HILL",
      },
      {
        CITY: "SPRINGDALE",
      },
      {
        CITY: "SPRINGFIELD",
      },
      {
        CITY: "SPRINGHILL",
      },
      {
        CITY: "SPRUCE GROVE",
      },
      {
        CITY: "SPY HILL",
      },
      {
        CITY: "SQUAMISH",
      },
      {
        CITY: "ST-HUBERT",
      },
      {
        CITY: "ST-LAZARE",
      },
      {
        CITY: "ST-TIMOTHEE",
      },
      {
        CITY: "ST. ALBERT",
      },
      {
        CITY: "ST. ANDREWS",
      },
      {
        CITY: "ST. ANTHONY",
      },
      {
        CITY: "ST. BRIEUX",
      },
      {
        CITY: "ST. CATHARINES",
      },
      {
        CITY: "ST. JACOBS",
      },
      {
        CITY: "ST. LOUIS",
      },
      {
        CITY: "ST. MARYS",
      },
      {
        CITY: "ST. PAUL",
      },
      {
        CITY: "ST. PETERS BAY",
      },
      {
        CITY: "ST. STEPHEN",
      },
      {
        CITY: "ST. THOMAS",
      },
      {
        CITY: "ST. WALBURG",
      },
      {
        CITY: "STANBRIDGE EAST",
      },
      {
        CITY: "STANDARD",
      },
      {
        CITY: "STANLEY BRIDGE HOPE RIVER BAYVIEW CAVENDISH AND NORTH RUSTICO",
      },
      {
        CITY: "STAR CITY",
      },
      {
        CITY: "STAYNER",
      },
      {
        CITY: "STEINBACH",
      },
      {
        CITY: "STELLA",
      },
      {
        CITY: "STELLARTON",
      },
      {
        CITY: "STEPHENVILLE",
      },
      {
        CITY: "STEPHENVILLE CROSSING",
      },
      {
        CITY: "STETTLER",
      },
      {
        CITY: "STEVENSVILLE",
      },
      {
        CITY: "STEWIACKE",
      },
      {
        CITY: "STIRLING",
      },
      {
        CITY: "STIRLING",
      },
      {
        CITY: "STITTSVILLE",
      },
      {
        CITY: "STONEHAM",
      },
      {
        CITY: "STONEWALL",
      },
      {
        CITY: "STONEY CREEK",
      },
      {
        CITY: "STONY MOUNTAIN",
      },
      {
        CITY: "STONY PLAIN",
      },
      {
        CITY: "STOUFFVILLE",
      },
      {
        CITY: "STOUGHTON",
      },
      {
        CITY: "STRASBOURG",
      },
      {
        CITY: "STRATFORD",
      },
      {
        CITY: "STRATFORD",
      },
      {
        CITY: "STRATHMORE",
      },
      {
        CITY: "STRATHROY",
      },
      {
        CITY: "STREETSVILLE",
      },
      {
        CITY: "STURGEON FALLS",
      },
      {
        CITY: "STURGIS",
      },
      {
        CITY: "SUMMERLAND",
      },
      {
        CITY: "SUMMERSIDE",
      },
      {
        CITY: "SUNDERLAND",
      },
      {
        CITY: "SUNDRIDGE",
      },
      {
        CITY: "SURREY",
      },
      {
        CITY: "SUSSEX",
      },
      {
        CITY: "SUTTON",
      },
      {
        CITY: "SUTTON",
      },
      {
        CITY: "SWAN RIVER",
      },
      {
        CITY: "SWASTIKA",
      },
      {
        CITY: "SWIFT CURRENT",
      },
      {
        CITY: "SYDENHAM",
      },
      {
        CITY: "SYDNEY",
      },
      {
        CITY: "SYDNEY",
      },
      {
        CITY: "SYDNEY MINES",
      },
      {
        CITY: "SYLVAN LAKE",
      },
      {
        CITY: "TABER",
      },
      {
        CITY: "TADOUSSAC",
      },
      {
        CITY: "TAHSIS",
      },
      {
        CITY: "TAKLA LANDING",
      },
      {
        CITY: "TAPPEN",
      },
      {
        CITY: "TASCHEREAU",
      },
      {
        CITY: "TASIUJAQ",
      },
      {
        CITY: "TATAMAGOUCHE",
      },
      {
        CITY: "TAVISTOCK",
      },
      {
        CITY: "TAYLOR",
      },
      {
        CITY: "TECUMSEH",
      },
      {
        CITY: "TECUMSEH",
      },
      {
        CITY: "TEESWATER",
      },
      {
        CITY: "TEMISCAMING",
      },
      {
        CITY: "TENNION",
      },
      {
        CITY: "TERRACE",
      },
      {
        CITY: "TERRACE BAY",
      },
      {
        CITY: "TERRE NOIRE",
      },
      {
        CITY: "TERREBONNE",
      },
      {
        CITY: "TEULON",
      },
      {
        CITY: "THAMESFORD",
      },
      {
        CITY: "THAMESVILLE",
      },
      {
        CITY: "THE PAS",
      },
      {
        CITY: "THEDFORD",
      },
      {
        CITY: "THEODORE",
      },
      {
        CITY: "THERESA",
      },
      {
        CITY: "THETFORD-MINES",
      },
      {
        CITY: "THOMPSON",
      },
      {
        CITY: "THORHILD",
      },
      {
        CITY: "THORNBURY",
      },
      {
        CITY: "THORNDALE",
      },
      {
        CITY: "THORNHILL",
      },
      {
        CITY: "THOROLD",
      },
      {
        CITY: "THREE HILLS",
      },
      {
        CITY: "THREE MILE PLAINS",
      },
      {
        CITY: "THUNDER BAY",
      },
      {
        CITY: "THURSO",
      },
      {
        CITY: "TILBURY",
      },
      {
        CITY: "TILLEY",
      },
      {
        CITY: "TILLSONBURG",
      },
      {
        CITY: "TIMBERLEA",
      },
      {
        CITY: "TIMMINS",
      },
      {
        CITY: "TISDALE",
      },
      {
        CITY: "TLELL",
      },
      {
        CITY: "TOFIELD",
      },
      {
        CITY: "TOFINO",
      },
      {
        CITY: "TOMPKINS",
      },
      {
        CITY: "TOMPKINS",
      },
      {
        CITY: "TORBAY",
      },
      {
        CITY: "TORONTO",
      },
      {
        CITY: "TORRINGTON",
      },
      {
        CITY: "TOTTENHAM",
      },
      {
        CITY: "TRACADIE",
      },
      {
        CITY: "TRAIL",
      },
      {
        CITY: "TREHERNE",
      },
      {
        CITY: "TRENTON",
      },
      {
        CITY: "TRENTON",
      },
      {
        CITY: "TRING-JONCTION",
      },
      {
        CITY: "TROCHU",
      },
      {
        CITY: "TROIS PISTOLES",
      },
      {
        CITY: "TROUT CREEK",
      },
      {
        CITY: "TROUT RIVER",
      },
      {
        CITY: "TROY",
      },
      {
        CITY: "TRURO",
      },
      {
        CITY: "TSAWWASSEN",
      },
      {
        CITY: "TUKTOYAKTUK",
      },
      {
        CITY: "TUMBLER RIDGE",
      },
      {
        CITY: "TURNER VALLEY",
      },
      {
        CITY: "TURTLEFORD",
      },
      {
        CITY: "TUSKET",
      },
      {
        CITY: "TWEED",
      },
      {
        CITY: "TWILLINGATE",
      },
      {
        CITY: "TWO HILLS",
      },
      {
        CITY: "TYNDALL",
      },
      {
        CITY: "UCLUELET",
      },
      {
        CITY: "UNION BAY",
      },
      {
        CITY: "UNIONVILLE",
      },
      {
        CITY: "UNITY",
      },
      {
        CITY: "UPPER ISLAND COVE",
      },
      {
        CITY: "UPTON",
      },
      {
        CITY: "UTTERSON",
      },
      {
        CITY: "UXBRIDGE",
      },
      {
        CITY: "VAL-BRILLANT",
      },
      {
        CITY: "VAL-DAVID",
      },
      {
        CITY: "VAL-DES-MONTS",
      },
      {
        CITY: "VAL-MORIN",
      },
      {
        CITY: "VALCOURT",
      },
      {
        CITY: "VALEMOUNT",
      },
      {
        CITY: "VALLEE-JONCTION",
      },
      {
        CITY: "VALLEY",
      },
      {
        CITY: "VALLEYVIEW",
      },
      {
        CITY: "VAN ANDA",
      },
      {
        CITY: "VANCOUVER",
      },
      {
        CITY: "VANDERHOOF",
      },
      {
        CITY: "VANKLEEK HILL",
      },
      {
        CITY: "VARENNES",
      },
      {
        CITY: "VAUDREUIL-DORION",
      },
      {
        CITY: "VAUGHAN",
      },
      {
        CITY: "VAUXHALL",
      },
      {
        CITY: "VEGREVILLE",
      },
      {
        CITY: "VERCHERES",
      },
      {
        CITY: "VERDUN",
      },
      {
        CITY: "VERMILION",
      },
      {
        CITY: "VERNER",
      },
      {
        CITY: "VERNON",
      },
      {
        CITY: "VERNON BRIDGE",
      },
      {
        CITY: "VICTORIA",
      },
      {
        CITY: "VICTORIA",
      },
      {
        CITY: "VICTORIA COVE",
      },
      {
        CITY: "VICTORIA HARBOUR",
      },
      {
        CITY: "VICTORIAVILLE",
      },
      {
        CITY: "VIEUX-SAINT-LAURENT",
      },
      {
        CITY: "VIKING",
      },
      {
        CITY: "VILLA MARIE",
      },
      {
        CITY: "VILLE-MARIE",
      },
      {
        CITY: "VINELAND STATION",
      },
      {
        CITY: "VIRDEN",
      },
      {
        CITY: "VISCOUNT",
      },
      {
        CITY: "VONDA",
      },
      {
        CITY: "VULCAN",
      },
      {
        CITY: "WABANA",
      },
      {
        CITY: "WABIGOON",
      },
      {
        CITY: "WABUSH",
      },
      {
        CITY: "WADENA",
      },
      {
        CITY: "WAINFLEET",
      },
      {
        CITY: "WAINWRIGHT",
      },
      {
        CITY: "WAKAW",
      },
      {
        CITY: "WAKEFIELD",
      },
      {
        CITY: "WALDECK",
      },
      {
        CITY: "WALDHEIM",
      },
      {
        CITY: "WALKERTON",
      },
      {
        CITY: "WALLACE",
      },
      {
        CITY: "WALLACEBURG",
      },
      {
        CITY: "WARKWORTH",
      },
      {
        CITY: "WARMAN",
      },
      {
        CITY: "WARNER",
      },
      {
        CITY: "WARSAW",
      },
      {
        CITY: "WARWICK",
      },
      {
        CITY: "WASAGA BEACH",
      },
      {
        CITY: "WASHAGO",
      },
      {
        CITY: "WATERDOWN",
      },
      {
        CITY: "WATERFORD",
      },
      {
        CITY: "WATERLOO",
      },
      {
        CITY: "WATERLOO",
      },
      {
        CITY: "WATERVILLE",
      },
      {
        CITY: "WATFORD",
      },
      {
        CITY: "WATROUS",
      },
      {
        CITY: "WATSON",
      },
      {
        CITY: "WATSON LAKE",
      },
      {
        CITY: "WAUBAUSHENE",
      },
      {
        CITY: "WAWA",
      },
      {
        CITY: "WAWEIG",
      },
      {
        CITY: "WAWOTA",
      },
      {
        CITY: "WEEDON",
      },
      {
        CITY: "WEIR",
      },
      {
        CITY: "WELDON",
      },
      {
        CITY: "WELLAND",
      },
      {
        CITY: "WELLESLEY",
      },
      {
        CITY: "WELLINGTON",
      },
      {
        CITY: "WELLINGTON",
      },
      {
        CITY: "WEMBLEY",
      },
      {
        CITY: "WEMINDJI",
      },
      {
        CITY: "WEST BAY",
      },
      {
        CITY: "WEST CHEZZETCOOK",
      },
      {
        CITY: "WEST HILL",
      },
      {
        CITY: "WEST LAKE",
      },
      {
        CITY: "WEST OSGOODE",
      },
      {
        CITY: "WESTBANK",
      },
      {
        CITY: "WESTERN BAY",
      },
      {
        CITY: "WESTLOCK",
      },
      {
        CITY: "WESTMOUNT",
      },
      {
        CITY: "WESTON",
      },
      {
        CITY: "WESTPORT",
      },
      {
        CITY: "WESTREE",
      },
      {
        CITY: "WESTVILLE",
      },
      {
        CITY: "WETASKIWIN",
      },
      {
        CITY: "WEYBURN",
      },
      {
        CITY: "WHEATLEY",
      },
      {
        CITY: "WHISTLER VILLAGE",
      },
      {
        CITY: "WHITBY",
      },
      {
        CITY: "WHITE CITY",
      },
      {
        CITY: "WHITE FOX",
      },
      {
        CITY: "WHITE RIVER",
      },
      {
        CITY: "WHITE ROCK",
      },
      {
        CITY: "WHITECOURT",
      },
      {
        CITY: "WHITEHORSE",
      },
      {
        CITY: "WHITEMOUTH",
      },
      {
        CITY: "WHITES LAKE",
      },
      {
        CITY: "WHITNEY",
      },
      {
        CITY: "WHYCOCOMAGH",
      },
      {
        CITY: "WIARTON",
      },
      {
        CITY: "WICKHAM",
      },
      {
        CITY: "WILCOX",
      },
      {
        CITY: "WILDWOOD",
      },
      {
        CITY: "WILKIE",
      },
      {
        CITY: "WILLIAMS LAKE",
      },
      {
        CITY: "WILLIAMSBURG",
      },
      {
        CITY: "WILLINGDON",
      },
      {
        CITY: "WILLOW BUNCH",
      },
      {
        CITY: "WILLOWDALE",
      },
      {
        CITY: "WILLOWVALE",
      },
      {
        CITY: "WINCHESTER",
      },
      {
        CITY: "WINDERMERE",
      },
      {
        CITY: "WINDHAM CENTRE",
      },
      {
        CITY: "WINDSOR",
      },
      {
        CITY: "WINDSOR",
      },
      {
        CITY: "WINDSOR",
      },
      {
        CITY: "WINFIELD",
      },
      {
        CITY: "WINFIELD",
      },
      {
        CITY: "WINGHAM",
      },
      {
        CITY: "WINKLER",
      },
      {
        CITY: "WINNIPEG",
      },
      {
        CITY: "WINNIPEGOSIS",
      },
      {
        CITY: "WISETON",
      },
      {
        CITY: "WISHART",
      },
      {
        CITY: "WITLESS BAY",
      },
      {
        CITY: "WITTENBURG",
      },
      {
        CITY: "WOLFVILLE",
      },
      {
        CITY: "WOLSELEY",
      },
      {
        CITY: "WOOD MOUNTAIN",
      },
      {
        CITY: "WOODBRIDGE",
      },
      {
        CITY: "WOODLANDS",
      },
      {
        CITY: "WOODRIDGE",
      },
      {
        CITY: "WOODSTOCK",
      },
      {
        CITY: "WOODSTOCK",
      },
      {
        CITY: "WORSLEY",
      },
      {
        CITY: "WRENTHAM",
      },
      {
        CITY: "WRIGHT-GRACEFIELD-NORTHFIELD",
      },
      {
        CITY: "WYMARK",
      },
      {
        CITY: "WYNYARD",
      },
      {
        CITY: "WYOMING",
      },
      {
        CITY: "YAHK",
      },
      {
        CITY: "YAMACHICHE",
      },
      {
        CITY: "YAMASKA",
      },
      {
        CITY: "YARKER",
      },
      {
        CITY: "YARMOUTH",
      },
      {
        CITY: "YELLOW GRASS",
      },
      {
        CITY: "YELLOWKNIFE",
      },
      {
        CITY: "YORK",
      },
      {
        CITY: "YORKTON",
      },
      {
        CITY: "YOUBOU",
      },
      {
        CITY: "YOUNG",
      },
      {
        CITY: "YOUNGSTOWN",
      },
    ],
  },
  {
    COUNTRY: "AZERBAIJAN",
    CODE: "AZ",
    CITIES: [
      {
        CITY: "BAKU",
      },
      {
        CITY: "NAKHCHIVAN",
      },
      {
        CITY: "QUBA",
      },
      {
        CITY: "QUSAR",
      },
      {
        CITY: "SULUTAPA",
      },
      {
        CITY: "SUMQAYIT",
      },
      {
        CITY: "XIRDALAN",
      },
      {
        CITY: "ZURGES",
      },
    ],
  },
  {
    COUNTRY: "BANGLADESH",
    CODE: "BD",
    CITIES: [
      {
        CITY: "AGRABAD",
      },
      {
        CITY: "BANGLA",
      },
      {
        CITY: "BARISAL",
      },
      {
        CITY: "BAZAR",
      },
      {
        CITY: "CHITTAGONG",
      },
      {
        CITY: "COMILLA",
      },
      {
        CITY: "DELTA",
      },
      {
        CITY: "DHAKA",
      },
      {
        CITY: "HOSSAIN",
      },
      {
        CITY: "JESSORE",
      },
      {
        CITY: "KABI",
      },
      {
        CITY: "KAZIPUR",
      },
      {
        CITY: "MYMENSINGH",
      },
      {
        CITY: "NARAYANGANJ",
      },
      {
        CITY: "RAJSHAHI",
      },
      {
        CITY: "TEJGAON",
      },
      {
        CITY: "UTTARA",
      },
    ],
  },
  {
    COUNTRY: "BAHRAIN",
    CODE: "BH",
    CITIES: [
      {
        CITY: "AL BUDAYYI`",
      },
      {
        CITY: "AL HADD",
      },
      {
        CITY: "AL HAMALAH",
      },
      {
        CITY: "AL JANABIYAH",
      },
      {
        CITY: "AL MARKH",
      },
      {
        CITY: "AL MUHARRAQ",
      },
      {
        CITY: "BANI JAMRAH",
      },
      {
        CITY: "BARBAR",
      },
      {
        CITY: "JURDAB",
      },
      {
        CITY: "MADINAT `ISA",
      },
      {
        CITY: "MADINAT HAMAD",
      },
      {
        CITY: "MANAMA",
      },
      {
        CITY: "OIL CITY",
      },
      {
        CITY: "SANABIS",
      },
      {
        CITY: "SANAD",
      },
      {
        CITY: "SITRAH",
      },
      {
        CITY: "TUBLI",
      },
    ],
  },
  {
    COUNTRY: "BOLIVIA",
    CODE: "BO",
    CITIES: [
      {
        CITY: "ANILLO",
      },
      {
        CITY: "AROMA",
      },
      {
        CITY: "BERMEJO",
      },
      {
        CITY: "CARACASA",
      },
      {
        CITY: "COBIJA",
      },
      {
        CITY: "COCHABAMBA",
      },
      {
        CITY: "COTOCA",
      },
      {
        CITY: "CRUZ",
      },
      {
        CITY: "GUAYARAMERIN",
      },
      {
        CITY: "LA PAZ",
      },
      {
        CITY: "ORURO",
      },
      {
        CITY: "RIBERALTA",
      },
      {
        CITY: "SANTA CRUZ",
      },
      {
        CITY: "SANTA CRUZ",
      },
      {
        CITY: "SUCRE",
      },
      {
        CITY: "TARIJA",
      },
      {
        CITY: "TRINIDAD",
      },
    ],
  },
  {
    COUNTRY: "BRUNEI",
    CODE: "BN",
    CITIES: [
      {
        CITY: "BANDAR SERI BEGAWAN",
      },
      {
        CITY: "BANGAR",
      },
      {
        CITY: "KAMPONG JERUDONG",
      },
      {
        CITY: "KUALA BALAI",
      },
      {
        CITY: "KUALA BELAIT",
      },
      {
        CITY: "PANAGA",
      },
      {
        CITY: "SERIA",
      },
      {
        CITY: "TUTONG",
      },
    ],
  },
  {
    COUNTRY: "BELARUS",
    CODE: "BY",
    CITIES: [
      {
        CITY: "BARANOVICHI",
      },
      {
        CITY: "BORISOV",
      },
      {
        CITY: "BREST",
      },
      {
        CITY: "DZYARZHYNSK",
      },
      {
        CITY: "HORKI",
      },
      {
        CITY: "HRODNA",
      },
      {
        CITY: "LAHOYSK",
      },
      {
        CITY: "LIDA",
      },
      {
        CITY: "LYAKHOVICHI",
      },
      {
        CITY: "LYASKAVICHY",
      },
      {
        CITY: "MAZYR",
      },
      {
        CITY: "MINSK",
      },
      {
        CITY: "MOGILEV",
      },
      {
        CITY: "NAVAPOLATSK",
      },
      {
        CITY: "ORSHA",
      },
      {
        CITY: "PINSK",
      },
      {
        CITY: "POLATSK",
      },
      {
        CITY: "RADOSHKOVICHI",
      },
      {
        CITY: "SHKLOW",
      },
      {
        CITY: "VITEBSK",
      },
      {
        CITY: "ZASLAWYE",
      },
      {
        CITY: "ZHODZINA",
      },
    ],
  },
  {
    COUNTRY: "CAMBODIA",
    CODE: "KH",
    CITIES: [
      {
        CITY: "MOUNG ROESSEI",
      },
      {
        CITY: "PHNOM PENH",
      },
      {
        CITY: "PHUMI BOENG (1)",
      },
      {
        CITY: "PHUMI CHHUK",
      },
      {
        CITY: "PHUMI PREAH HAOH",
      },
      {
        CITY: "PHUMI PREI",
      },
      {
        CITY: "PHUMI PREK MRINH",
      },
      {
        CITY: "PHUMI SIEM REAB",
      },
      {
        CITY: "PHUMI THMEI",
      },
      {
        CITY: "PHUMI THNAL",
      },
      {
        CITY: "PHUMI VOTT PHNUM",
      },
      {
        CITY: "SIHANOUKVILLE",
      },
      {
        CITY: "TUOL KOK",
      },
      {
        CITY: "VOTT KAMPONG SVAY",
      },
    ],
  },
  {
    COUNTRY: "BELGIUM",
    CODE: "BE",
    CITIES: [
      {
        CITY: "AALBEKE",
      },
      {
        CITY: "AALST",
      },
      {
        CITY: "AALTER",
      },
      {
        CITY: "AARSCHOT",
      },
      {
        CITY: "AARSELE",
      },
      {
        CITY: "AARTRIJKE",
      },
      {
        CITY: "AARTSELAAR",
      },
      {
        CITY: "ACHEL",
      },
      {
        CITY: "ADEGEM",
      },
      {
        CITY: "ADINKERKE",
      },
      {
        CITY: "AFSNEE",
      },
      {
        CITY: "AGIMONT",
      },
      {
        CITY: "ALKEN",
      },
      {
        CITY: "ALLEUR",
      },
      {
        CITY: "ALSEMBERG",
      },
      {
        CITY: "ALVERINGEM",
      },
      {
        CITY: "AMAY",
      },
      {
        CITY: "AMPSIN",
      },
      {
        CITY: "ANDENNE",
      },
      {
        CITY: "ANDERLECHT",
      },
      {
        CITY: "ANDERLUES",
      },
      {
        CITY: "ANDRIMONT",
      },
      {
        CITY: "ANGLEUR",
      },
      {
        CITY: "ANS",
      },
      {
        CITY: "ANSEROEUL",
      },
      {
        CITY: "ANTHISNES",
      },
      {
        CITY: "ANTWERP",
      },
      {
        CITY: "ANZEGEM",
      },
      {
        CITY: "APPELS",
      },
      {
        CITY: "APPELTERRE-EICHEM",
      },
      {
        CITY: "ARBREFONTAINE",
      },
      {
        CITY: "ARDOOIE",
      },
      {
        CITY: "ARENDONK",
      },
      {
        CITY: "ARGENTEAU",
      },
      {
        CITY: "ARLON",
      },
      {
        CITY: "ARQUENNES",
      },
      {
        CITY: "ARSIMONT",
      },
      {
        CITY: "AS",
      },
      {
        CITY: "ASSE",
      },
      {
        CITY: "ASSEBROEK",
      },
      {
        CITY: "ASSENEDE",
      },
      {
        CITY: "ASSENT",
      },
      {
        CITY: "ASSESSE",
      },
      {
        CITY: "ASTENE",
      },
      {
        CITY: "ASTENET",
      },
      {
        CITY: "ATH",
      },
      {
        CITY: "ATTENHOVEN",
      },
      {
        CITY: "ATTRE",
      },
      {
        CITY: "AUBANGE",
      },
      {
        CITY: "AUBEL",
      },
      {
        CITY: "AUDERGHEM",
      },
      {
        CITY: "AUVELAIS",
      },
      {
        CITY: "AVEKAPELLE",
      },
      {
        CITY: "AVELGEM",
      },
      {
        CITY: "AVERBODE",
      },
      {
        CITY: "AWANS",
      },
      {
        CITY: "AWIRS",
      },
      {
        CITY: "AYWAILLE",
      },
      {
        CITY: "BAAL",
      },
      {
        CITY: "BAARLE-HERTOG",
      },
      {
        CITY: "BAASRODE",
      },
      {
        CITY: "BAELEN",
      },
      {
        CITY: "BAISY-THY",
      },
      {
        CITY: "BALEN",
      },
      {
        CITY: "BANNEUX",
      },
      {
        CITY: "BARCHON",
      },
      {
        CITY: "BARRY",
      },
      {
        CITY: "BASECLES",
      },
      {
        CITY: "BASSENGE",
      },
      {
        CITY: "BASSEVELDE",
      },
      {
        CITY: "BASTOGNE",
      },
      {
        CITY: "BATTICE",
      },
      {
        CITY: "BAUDOUR",
      },
      {
        CITY: "BAVEGEM",
      },
      {
        CITY: "BAVIKHOVE",
      },
      {
        CITY: "BAZEL",
      },
      {
        CITY: "BEAUFAYS",
      },
      {
        CITY: "BEAUVECHAIN",
      },
      {
        CITY: "BEERNEM",
      },
      {
        CITY: "BEERSE",
      },
      {
        CITY: "BEERSEL",
      },
      {
        CITY: "BEERVELDE",
      },
      {
        CITY: "BEERZEL",
      },
      {
        CITY: "BEEZ",
      },
      {
        CITY: "BEGIJNENDIJK",
      },
      {
        CITY: "BEIGEM",
      },
      {
        CITY: "BEKKEVOORT",
      },
      {
        CITY: "BELGRADE",
      },
      {
        CITY: "BELLE FONTAINE",
      },
      {
        CITY: "BELLEM",
      },
      {
        CITY: "BELOEIL",
      },
      {
        CITY: "BELSELE",
      },
      {
        CITY: "BERCHEM",
      },
      {
        CITY: "BERCHEM",
      },
      {
        CITY: "BERENDRECHT",
      },
      {
        CITY: "BERINGEN",
      },
      {
        CITY: "BERLAAR",
      },
      {
        CITY: "BERLARE",
      },
      {
        CITY: "BERNISSART",
      },
      {
        CITY: "BERTEM",
      },
      {
        CITY: "BERTRIX",
      },
      {
        CITY: "BETEKOM",
      },
      {
        CITY: "BEVEL",
      },
      {
        CITY: "BEVEREN",
      },
      {
        CITY: "BEVEREN",
      },
      {
        CITY: "BEVERLO",
      },
      {
        CITY: "BEYNE-HEUSAY",
      },
      {
        CITY: "BIERBEEK",
      },
      {
        CITY: "BIERCEE",
      },
      {
        CITY: "BIERGES",
      },
      {
        CITY: "BIERSET",
      },
      {
        CITY: "BILSTAIN",
      },
      {
        CITY: "BILZEN",
      },
      {
        CITY: "BINCHE",
      },
      {
        CITY: "BISSEGEM",
      },
      {
        CITY: "BLAASVELD",
      },
      {
        CITY: "BLANDEN",
      },
      {
        CITY: "BLANKENBERGE",
      },
      {
        CITY: "BLEGNY",
      },
      {
        CITY: "BOCHOLT",
      },
      {
        CITY: "BOECHOUT",
      },
      {
        CITY: "BOEZINGE",
      },
      {
        CITY: "BONCELLES",
      },
      {
        CITY: "BONHEIDEN",
      },
      {
        CITY: "BONLEZ",
      },
      {
        CITY: "BOOISCHOT",
      },
      {
        CITY: "BOOM",
      },
      {
        CITY: "BOORSEM",
      },
      {
        CITY: "BOORTMEERBEEK",
      },
      {
        CITY: "BORCHTLOMBEEK",
      },
      {
        CITY: "BORGERHOUT",
      },
      {
        CITY: "BORGLOON",
      },
      {
        CITY: "BORNEM",
      },
      {
        CITY: "BORSBEEK",
      },
      {
        CITY: "BORSBEKE",
      },
      {
        CITY: "BOUGE",
      },
      {
        CITY: "BOUILLON",
      },
      {
        CITY: "BOUSSU",
      },
      {
        CITY: "BOUSVAL",
      },
      {
        CITY: "BOUTERSEM",
      },
      {
        CITY: "BOUWEL",
      },
      {
        CITY: "BOVEKERKE",
      },
      {
        CITY: "BOVESSE",
      },
      {
        CITY: "BRAINE-LE-CHATEAU",
      },
      {
        CITY: "BRAINE-LE-COMTE",
      },
      {
        CITY: "BRAIVES",
      },
      {
        CITY: "BRASMENIL",
      },
      {
        CITY: "BRASSCHAAT",
      },
      {
        CITY: "BRECHT",
      },
      {
        CITY: "BREDENE",
      },
      {
        CITY: "BREE",
      },
      {
        CITY: "BRESSOUX",
      },
      {
        CITY: "BROECHEM",
      },
      {
        CITY: "BRUGELETTE",
      },
      {
        CITY: "BRUGES",
      },
      {
        CITY: "BRUSSEGEM",
      },
      {
        CITY: "BRUSSELS",
      },
      {
        CITY: "BUDINGEN",
      },
      {
        CITY: "BUGGENHOUT",
      },
      {
        CITY: "BUIZINGEN",
      },
      {
        CITY: "BUNSBEEK",
      },
      {
        CITY: "BURCHT",
      },
      {
        CITY: "BURDINNE",
      },
      {
        CITY: "BURST",
      },
      {
        CITY: "BUTGENBACH",
      },
      {
        CITY: "BUVRINNES",
      },
      {
        CITY: "CARNIERES",
      },
      {
        CITY: "CASTEAU",
      },
      {
        CITY: "CELLES",
      },
      {
        CITY: "CEREXHE-HEUSEUX",
      },
      {
        CITY: "CHAPELLE-LEZ-HERLAIMONT",
      },
      {
        CITY: "CHARLEROI",
      },
      {
        CITY: "CHARNEUX",
      },
      {
        CITY: "CHASTRE",
      },
      {
        CITY: "CHATELET",
      },
      {
        CITY: "CHATELINEAU",
      },
      {
        CITY: "CHAUDFONTAINE",
      },
      {
        CITY: "CHAUMONT-GISTOUX",
      },
      {
        CITY: "CHAUSSEE-NOTRE-DAME-LOUVIGNIES",
      },
      {
        CITY: "CHENEE",
      },
      {
        CITY: "CHERCQ",
      },
      {
        CITY: "CHEVRON",
      },
      {
        CITY: "CHIEVRES",
      },
      {
        CITY: "CHIMAY",
      },
      {
        CITY: "CHINY",
      },
      {
        CITY: "CINEY",
      },
      {
        CITY: "CIPLET",
      },
      {
        CITY: "CLAVIER",
      },
      {
        CITY: "COLFONTAINE",
      },
      {
        CITY: "COMBLAIN-AU-PONT",
      },
      {
        CITY: "COMINES",
      },
      {
        CITY: "CORET",
      },
      {
        CITY: "CORROY-LE-GRAND",
      },
      {
        CITY: "COUILLET",
      },
      {
        CITY: "COUR-SUR-HEURE",
      },
      {
        CITY: "COURCELLES",
      },
      {
        CITY: "COURT-SAINT-ETIENNE",
      },
      {
        CITY: "COUTHUIN",
      },
      {
        CITY: "COUVIN",
      },
      {
        CITY: "CRISNEE",
      },
      {
        CITY: "CUESMES",
      },
      {
        CITY: "DADIZELE",
      },
      {
        CITY: "DALHEM",
      },
      {
        CITY: "DAMME",
      },
      {
        CITY: "DAMPREMY",
      },
      {
        CITY: "DAVE",
      },
      {
        CITY: "DE HAAN",
      },
      {
        CITY: "DE KLINGE",
      },
      {
        CITY: "DE PANNE",
      },
      {
        CITY: "DE PINTE",
      },
      {
        CITY: "DEERLIJK",
      },
      {
        CITY: "DEFTINGE",
      },
      {
        CITY: "DEINZE",
      },
      {
        CITY: "DENDERBELLE",
      },
      {
        CITY: "DENDERHOUTEM",
      },
      {
        CITY: "DENDERLEEUW",
      },
      {
        CITY: "DENDERMONDE",
      },
      {
        CITY: "DENTERGEM",
      },
      {
        CITY: "DESSEL",
      },
      {
        CITY: "DESSELGEM",
      },
      {
        CITY: "DESTELBERGEN",
      },
      {
        CITY: "DEURLE",
      },
      {
        CITY: "DEURNE",
      },
      {
        CITY: "DIEGEM",
      },
      {
        CITY: "DIEPENBEEK",
      },
      {
        CITY: "DIEST",
      },
      {
        CITY: "DIKSMUIDE",
      },
      {
        CITY: "DILBEEK",
      },
      {
        CITY: "DILSEN",
      },
      {
        CITY: "DINANT",
      },
      {
        CITY: "DISON",
      },
      {
        CITY: "DONCEEL",
      },
      {
        CITY: "DONGELBERG",
      },
      {
        CITY: "DORINNE",
      },
      {
        CITY: "DOTTIGNIES",
      },
      {
        CITY: "DOUR",
      },
      {
        CITY: "DRANOUTER",
      },
      {
        CITY: "DROGENBOS",
      },
      {
        CITY: "DRONGEN",
      },
      {
        CITY: "DUDZELE",
      },
      {
        CITY: "DUFFEL",
      },
      {
        CITY: "DUISBURG",
      },
      {
        CITY: "DWORP",
      },
      {
        CITY: "ECAUSSINNES-LALAING",
      },
      {
        CITY: "EDEGEM",
      },
      {
        CITY: "EEKLO",
      },
      {
        CITY: "EERNEGEM",
      },
      {
        CITY: "EGHEZEE",
      },
      {
        CITY: "EIGENBILZEN",
      },
      {
        CITY: "EKE",
      },
      {
        CITY: "EKEREN",
      },
      {
        CITY: "EKSAARDE",
      },
      {
        CITY: "EKSEL",
      },
      {
        CITY: "ELEN",
      },
      {
        CITY: "ELEWIJT",
      },
      {
        CITY: "ELINGEN",
      },
      {
        CITY: "ELLEZELLES",
      },
      {
        CITY: "ELLIGNIES-SAINTE-ANNE",
      },
      {
        CITY: "ELVERDINGE",
      },
      {
        CITY: "ELVERSELE",
      },
      {
        CITY: "EMBLEM",
      },
      {
        CITY: "EMBOURG",
      },
      {
        CITY: "EMPTINNE",
      },
      {
        CITY: "ENGHIEN",
      },
      {
        CITY: "ENGIS",
      },
      {
        CITY: "ENSIVAL",
      },
      {
        CITY: "EPPEGEM",
      },
      {
        CITY: "ERBAUT",
      },
      {
        CITY: "EREMBODEGEM",
      },
      {
        CITY: "EREZEE",
      },
      {
        CITY: "ERONDEGEM",
      },
      {
        CITY: "ERPE",
      },
      {
        CITY: "ERPENT",
      },
      {
        CITY: "ERPS-KWERPS",
      },
      {
        CITY: "ERQUELINNES",
      },
      {
        CITY: "ERTVELDE",
      },
      {
        CITY: "ESNEUX",
      },
      {
        CITY: "ESSEN",
      },
      {
        CITY: "ESSENE",
      },
      {
        CITY: "ESTAIMPUIS",
      },
      {
        CITY: "ESTINNES-AU-VAL",
      },
      {
        CITY: "ETALLE",
      },
      {
        CITY: "ETIKHOVE",
      },
      {
        CITY: "ETTERBEEK",
      },
      {
        CITY: "EUPEN",
      },
      {
        CITY: "EVERBEEK",
      },
      {
        CITY: "EVERBERG",
      },
      {
        CITY: "EVERE",
      },
      {
        CITY: "EVERGEM",
      },
      {
        CITY: "FAIMES",
      },
      {
        CITY: "FALISOLLE",
      },
      {
        CITY: "FALLAIS",
      },
      {
        CITY: "FARCIENNES",
      },
      {
        CITY: "FAYT-LEZ-MANAGE",
      },
      {
        CITY: "FELUY",
      },
      {
        CITY: "FERRIERES",
      },
      {
        CITY: "FEXHE-LE-HAUT-CLOCHER",
      },
      {
        CITY: "FEXHE-SLINS",
      },
      {
        CITY: "FILOT",
      },
      {
        CITY: "FLAWINNE",
      },
      {
        CITY: "FLEMALLE-GRANDE",
      },
      {
        CITY: "FLEMALLE-HAUTE",
      },
      {
        CITY: "FLENU",
      },
      {
        CITY: "FLERON",
      },
      {
        CITY: "FLEURUS",
      },
      {
        CITY: "FLOBECQ",
      },
      {
        CITY: "FLOREFFE",
      },
      {
        CITY: "FLORENNES",
      },
      {
        CITY: "FLORENVILLE",
      },
      {
        CITY: "FONTAINE",
      },
      {
        CITY: "FONTAINE-VALMONT",
      },
      {
        CITY: "FORCHIES-LA-MARCHE",
      },
      {
        CITY: "FOREST",
      },
      {
        CITY: "FORRIERES",
      },
      {
        CITY: "FOSSE DES LOUPS",
      },
      {
        CITY: "FRAMERIES",
      },
      {
        CITY: "FRANIERE",
      },
      {
        CITY: "FRASNES-LEZ-BUISSENAL",
      },
      {
        CITY: "FREYLANGE",
      },
      {
        CITY: "GAGES",
      },
      {
        CITY: "GALMAARDEN",
      },
      {
        CITY: "GANSHOREN",
      },
      {
        CITY: "GAVERE",
      },
      {
        CITY: "GEEL",
      },
      {
        CITY: "GEER",
      },
      {
        CITY: "GEETBETS",
      },
      {
        CITY: "GELLIK",
      },
      {
        CITY: "GELRODE",
      },
      {
        CITY: "GELUWE",
      },
      {
        CITY: "GEMBLOUX",
      },
      {
        CITY: "GENK",
      },
      {
        CITY: "GENTBRUGGE",
      },
      {
        CITY: "GENTINNES",
      },
      {
        CITY: "GENVAL",
      },
      {
        CITY: "GERAARDSBERGEN",
      },
      {
        CITY: "GERPINNES",
      },
      {
        CITY: "GESVES",
      },
      {
        CITY: "GHENT",
      },
      {
        CITY: "GHISLENGHIEN",
      },
      {
        CITY: "GHLIN",
      },
      {
        CITY: "GIERLE",
      },
      {
        CITY: "GIJZEGEM",
      },
      {
        CITY: "GILLY",
      },
      {
        CITY: "GINGELOM",
      },
      {
        CITY: "GISTEL",
      },
      {
        CITY: "GITS",
      },
      {
        CITY: "GLABBEEK",
      },
      {
        CITY: "GLIMES",
      },
      {
        CITY: "GOBARD",
      },
      {
        CITY: "GODARVILLE",
      },
      {
        CITY: "GOE",
      },
      {
        CITY: "GOOIK",
      },
      {
        CITY: "GOOR",
      },
      {
        CITY: "GOSSELIES",
      },
      {
        CITY: "GOURDINNE",
      },
      {
        CITY: "GOUTROUX",
      },
      {
        CITY: "GOUY-LEZ-PIETON",
      },
      {
        CITY: "GRAND",
      },
      {
        CITY: "GRAND-LEEZ",
      },
      {
        CITY: "GRAND-RECHAIN",
      },
      {
        CITY: "GRAND-RENG",
      },
      {
        CITY: "GREMBERGEN",
      },
      {
        CITY: "GREZ-DOICEAU",
      },
      {
        CITY: "GRIMBERGEN",
      },
      {
        CITY: "GRIMMINGE",
      },
      {
        CITY: "GRIVEGNEE",
      },
      {
        CITY: "GROBBENDONK",
      },
      {
        CITY: "GROOT-BIJGAARDEN",
      },
      {
        CITY: "GRUITRODE",
      },
      {
        CITY: "GULLEGEM",
      },
      {
        CITY: "HAACHT",
      },
      {
        CITY: "HAALTERT",
      },
      {
        CITY: "HAASDONK",
      },
      {
        CITY: "HAASRODE",
      },
      {
        CITY: "HACCOURT",
      },
      {
        CITY: "HAINE-SAINT-PAUL",
      },
      {
        CITY: "HALEN",
      },
      {
        CITY: "HALLAAR",
      },
      {
        CITY: "HALLE",
      },
      {
        CITY: "HAM",
      },
      {
        CITY: "HAM",
      },
      {
        CITY: "HAM-SUR-HEURE",
      },
      {
        CITY: "HAM-SUR-SAMBRE",
      },
      {
        CITY: "HAMME",
      },
      {
        CITY: "HAMME-MILLE",
      },
      {
        CITY: "HAMOIR",
      },
      {
        CITY: "HAMONT",
      },
      {
        CITY: "HAMPTEAU",
      },
      {
        CITY: "HANEFFE",
      },
      {
        CITY: "HANNUT",
      },
      {
        CITY: "HANSBEKE",
      },
      {
        CITY: "HARELBEKE",
      },
      {
        CITY: "HAREN",
      },
      {
        CITY: "HASSELT",
      },
      {
        CITY: "HAUT-ITTRE",
      },
      {
        CITY: "HAUTRAGE",
      },
      {
        CITY: "HAVAY",
      },
      {
        CITY: "HAVRE",
      },
      {
        CITY: "HECHTEL",
      },
      {
        CITY: "HEERS",
      },
      {
        CITY: "HEESTERT",
      },
      {
        CITY: "HEFFEN",
      },
      {
        CITY: "HEINDONK",
      },
      {
        CITY: "HEIST",
      },
      {
        CITY: "HEIST-OP-DEN-BERG",
      },
      {
        CITY: "HEKELGEM",
      },
      {
        CITY: "HELCHTEREN",
      },
      {
        CITY: "HEMIKSEM",
      },
      {
        CITY: "HENRI-CHAPELLE",
      },
      {
        CITY: "HENSIES",
      },
      {
        CITY: "HEPPEN",
      },
      {
        CITY: "HERDERSEM",
      },
      {
        CITY: "HERENT",
      },
      {
        CITY: "HERENTALS",
      },
      {
        CITY: "HERENTHOUT",
      },
      {
        CITY: "HERINNES",
      },
      {
        CITY: "HERK-DE-STAD",
      },
      {
        CITY: "HERLAIMONT",
      },
      {
        CITY: "HERMALLE-SOUS-ARGENTEAU",
      },
      {
        CITY: "HERMEE",
      },
      {
        CITY: "HERNE",
      },
      {
        CITY: "HERSEAUX",
      },
      {
        CITY: "HERSELT",
      },
      {
        CITY: "HERSTAL",
      },
      {
        CITY: "HERVE",
      },
      {
        CITY: "HERZELE",
      },
      {
        CITY: "HEULE",
      },
      {
        CITY: "HEURE-LE-ROMAIN",
      },
      {
        CITY: "HEUSDEN",
      },
      {
        CITY: "HEUSDEN",
      },
      {
        CITY: "HEUSY",
      },
      {
        CITY: "HEVER",
      },
      {
        CITY: "HEVERLEE",
      },
      {
        CITY: "HINGENE",
      },
      {
        CITY: "HOBOKEN",
      },
      {
        CITY: "HODEIGE",
      },
      {
        CITY: "HOEGAARDEN",
      },
      {
        CITY: "HOEILAART",
      },
      {
        CITY: "HOELEDEN",
      },
      {
        CITY: "HOESELT",
      },
      {
        CITY: "HOEVENEN",
      },
      {
        CITY: "HOFSTADE",
      },
      {
        CITY: "HOFSTADE",
      },
      {
        CITY: "HOGNOUL",
      },
      {
        CITY: "HOLLAIN",
      },
      {
        CITY: "HOLLAND",
      },
      {
        CITY: "HOLLOGNE",
      },
      {
        CITY: "HOLSBEEK",
      },
      {
        CITY: "HOMBEEK",
      },
      {
        CITY: "HOOGLEDE",
      },
      {
        CITY: "HOOGSTRATEN",
      },
      {
        CITY: "HORION-HOZEMONT",
      },
      {
        CITY: "HORNU",
      },
      {
        CITY: "HOTTON",
      },
      {
        CITY: "HOUDENG-GOEGNIES",
      },
      {
        CITY: "HOUFFALIZE",
      },
      {
        CITY: "HOUSSE",
      },
      {
        CITY: "HOUTAIN-LE-VAL",
      },
      {
        CITY: "HOUTAING",
      },
      {
        CITY: "HOUTHALEN",
      },
      {
        CITY: "HOUTHEM",
      },
      {
        CITY: "HOUTHULST",
      },
      {
        CITY: "HOUTVENNE",
      },
      {
        CITY: "HOVE",
      },
      {
        CITY: "HUIZINGEN",
      },
      {
        CITY: "HULDENBERG",
      },
      {
        CITY: "HULSHOUT",
      },
      {
        CITY: "HUMBEEK",
      },
      {
        CITY: "HUMBEEK",
      },
      {
        CITY: "HUY",
      },
      {
        CITY: "HYON",
      },
      {
        CITY: "ICHTEGEM",
      },
      {
        CITY: "IDDERGEM",
      },
      {
        CITY: "IDEGEM",
      },
      {
        CITY: "IMPE",
      },
      {
        CITY: "INCOURT",
      },
      {
        CITY: "INGELMUNSTER",
      },
      {
        CITY: "INGOOIGEM",
      },
      {
        CITY: "ISNES",
      },
      {
        CITY: "ITEGEM",
      },
      {
        CITY: "ITTERBEEK",
      },
      {
        CITY: "ITTRE",
      },
      {
        CITY: "IXELLES-ELSENE",
      },
      {
        CITY: "IZEGEM",
      },
      {
        CITY: "IZEL",
      },
      {
        CITY: "JABBEKE",
      },
      {
        CITY: "JAMBES",
      },
      {
        CITY: "JAMIOULX",
      },
      {
        CITY: "JAUCHE",
      },
      {
        CITY: "JEMAPPES",
      },
      {
        CITY: "JEMELLE",
      },
      {
        CITY: "JEMEPPE-SUR-MEUSE",
      },
      {
        CITY: "JEMEPPE-SUR-SAMBRE",
      },
      {
        CITY: "JETTE",
      },
      {
        CITY: "JODOIGNE",
      },
      {
        CITY: "JUMET",
      },
      {
        CITY: "JUPILLE-SUR-MEUSE",
      },
      {
        CITY: "JUPRELLE",
      },
      {
        CITY: "JURBISE",
      },
      {
        CITY: "KACHTEM",
      },
      {
        CITY: "KAIN",
      },
      {
        CITY: "KALKEN",
      },
      {
        CITY: "KALMTHOUT",
      },
      {
        CITY: "KAMPENHOUT",
      },
      {
        CITY: "KANEGEM",
      },
      {
        CITY: "KAPELLE-OP-DEN-BOS",
      },
      {
        CITY: "KAPELLEN",
      },
      {
        CITY: "KAPRIJKE",
      },
      {
        CITY: "KASTERLEE",
      },
      {
        CITY: "KAULILLE",
      },
      {
        CITY: "KEERBERGEN",
      },
      {
        CITY: "KEMZEKE",
      },
      {
        CITY: "KERKHOVE",
      },
      {
        CITY: "KERKSKEN",
      },
      {
        CITY: "KERMT",
      },
      {
        CITY: "KERSBEEK-MISKOM",
      },
      {
        CITY: "KESSEL",
      },
      {
        CITY: "KESSEL-LO",
      },
      {
        CITY: "KETTENIS",
      },
      {
        CITY: "KINROOI",
      },
      {
        CITY: "KLEIN-GELMEN",
      },
      {
        CITY: "KLEMSKERKE",
      },
      {
        CITY: "KNESSELARE",
      },
      {
        CITY: "KNOKKE",
      },
      {
        CITY: "KNOKKE-HEIST",
      },
      {
        CITY: "KOEKELARE",
      },
      {
        CITY: "KOERSEL",
      },
      {
        CITY: "KOKSIJDE",
      },
      {
        CITY: "KONINGSHOOIKT",
      },
      {
        CITY: "KONTICH",
      },
      {
        CITY: "KORBEEK-DIJLE",
      },
      {
        CITY: "KORBEEK-LO",
      },
      {
        CITY: "KORTEMARK",
      },
      {
        CITY: "KORTENAKEN",
      },
      {
        CITY: "KORTENBERG",
      },
      {
        CITY: "KORTESSEM",
      },
      {
        CITY: "KORTRIJK",
      },
      {
        CITY: "KORTRIJK-DUTSEL",
      },
      {
        CITY: "KOZEN",
      },
      {
        CITY: "KRAAINEM",
      },
      {
        CITY: "KRUIBEKE",
      },
      {
        CITY: "KRUISHOUTEM",
      },
      {
        CITY: "KURINGEN",
      },
      {
        CITY: "KUURNE",
      },
      {
        CITY: "KWAADMECHELEN",
      },
      {
        CITY: "LA CALAMINE",
      },
      {
        CITY: "LA HESTRE",
      },
      {
        CITY: "LA HULPE",
      },
      {
        CITY: "LAARNE",
      },
      {
        CITY: "LABUISSIERE",
      },
      {
        CITY: "LAKEN",
      },
      {
        CITY: "LAMBERMONT",
      },
      {
        CITY: "LAMBERMONT",
      },
      {
        CITY: "LANAKEN",
      },
      {
        CITY: "LANDEGEM",
      },
      {
        CITY: "LANDELIES",
      },
      {
        CITY: "LANDEN",
      },
      {
        CITY: "LANGDORP",
      },
      {
        CITY: "LANGEMARK",
      },
      {
        CITY: "LANKLAAR",
      },
      {
        CITY: "LATINNE",
      },
      {
        CITY: "LAUWE",
      },
      {
        CITY: "LE TILLEUL",
      },
      {
        CITY: "LEBBEKE",
      },
      {
        CITY: "LEDE",
      },
      {
        CITY: "LEDEBERG",
      },
      {
        CITY: "LEDEGEM",
      },
      {
        CITY: "LEEFDAAL",
      },
      {
        CITY: "LEERBEEK",
      },
      {
        CITY: "LEERNES",
      },
      {
        CITY: "LEEST",
      },
      {
        CITY: "LEFFINGE",
      },
      {
        CITY: "LEISELE",
      },
      {
        CITY: "LEMBEEK",
      },
      {
        CITY: "LENDELEDE",
      },
      {
        CITY: "LENNIK",
      },
      {
        CITY: "LEOPOLDSBURG",
      },
      {
        CITY: "LESSINES",
      },
      {
        CITY: "LETTERHOUTEM",
      },
      {
        CITY: "LEUVEN",
      },
      {
        CITY: "LEUZE",
      },
      {
        CITY: "LEVAL-TRAHEGNIES",
      },
      {
        CITY: "LIBRAMONT",
      },
      {
        CITY: "LICHTAART",
      },
      {
        CITY: "LICHTERVELDE",
      },
      {
        CITY: "LIEDEKERKE",
      },
      {
        CITY: "LIER",
      },
      {
        CITY: "LIGNY",
      },
      {
        CITY: "LILLE",
      },
      {
        CITY: "LILLOIS-WITTERZEE",
      },
      {
        CITY: "LIMAL",
      },
      {
        CITY: "LIMBOURG",
      },
      {
        CITY: "LIMELETTE",
      },
      {
        CITY: "LINCENT",
      },
      {
        CITY: "LINDEN",
      },
      {
        CITY: "LINKEBEEK",
      },
      {
        CITY: "LINT",
      },
      {
        CITY: "LIPPELO",
      },
      {
        CITY: "LISOGNE",
      },
      {
        CITY: "LISSEWEGE",
      },
      {
        CITY: "LIVES-SUR-MEUSE",
      },
      {
        CITY: "LIXHE",
      },
      {
        CITY: "LOBBES",
      },
      {
        CITY: "LOCHRISTI",
      },
      {
        CITY: "LODELINSART",
      },
      {
        CITY: "LOENHOUT",
      },
      {
        CITY: "LOKER",
      },
      {
        CITY: "LOKEREN",
      },
      {
        CITY: "LOMMEL",
      },
      {
        CITY: "LONCIN",
      },
      {
        CITY: "LONDERZEEL",
      },
      {
        CITY: "LONTZEN",
      },
      {
        CITY: "LOPPEM",
      },
      {
        CITY: "LOT",
      },
      {
        CITY: "LOTENHULLE",
      },
      {
        CITY: "LOUPOIGNE",
      },
      {
        CITY: "LOUVAIN-LA-NEUVE",
      },
      {
        CITY: "LOUVEIGNE",
      },
      {
        CITY: "LOVENDEGEM",
      },
      {
        CITY: "LOVERVAL",
      },
      {
        CITY: "LUBBEEK",
      },
      {
        CITY: "LUMMEN",
      },
      {
        CITY: "LUTTRE",
      },
      {
        CITY: "MAASEIK",
      },
      {
        CITY: "MAASMECHELEN",
      },
      {
        CITY: "MACHELEN",
      },
      {
        CITY: "MAGNEE",
      },
      {
        CITY: "MAISIERES",
      },
      {
        CITY: "MALDEGEM",
      },
      {
        CITY: "MALDEREN",
      },
      {
        CITY: "MALMEDY",
      },
      {
        CITY: "MALONNE",
      },
      {
        CITY: "MANAGE",
      },
      {
        CITY: "MANHAY",
      },
      {
        CITY: "MARAIS",
      },
      {
        CITY: "MARCHE-EN-FAMENNE",
      },
      {
        CITY: "MARCHE-LEZ-ECAUSSINNES",
      },
      {
        CITY: "MARCHIENNE-AU-PONT",
      },
      {
        CITY: "MARCHIN",
      },
      {
        CITY: "MARCINELLE",
      },
      {
        CITY: "MARIAKERKE",
      },
      {
        CITY: "MARIEKERKE",
      },
      {
        CITY: "MARIEMBOURG",
      },
      {
        CITY: "MARKE",
      },
      {
        CITY: "MARKEGEM",
      },
      {
        CITY: "MASSEMEN",
      },
      {
        CITY: "MAURAGE",
      },
      {
        CITY: "MECHELEN",
      },
      {
        CITY: "MEENSEL-KIEZEGEM",
      },
      {
        CITY: "MEERBEKE",
      },
      {
        CITY: "MEERHOUT",
      },
      {
        CITY: "MEERLAAR",
      },
      {
        CITY: "MEERLE",
      },
      {
        CITY: "MEERSEL",
      },
      {
        CITY: "MEEUWEN",
      },
      {
        CITY: "MEIGEM",
      },
      {
        CITY: "MEISE",
      },
      {
        CITY: "MELDERT",
      },
      {
        CITY: "MELEN",
      },
      {
        CITY: "MELKWEZER",
      },
      {
        CITY: "MELLE",
      },
      {
        CITY: "MELSBROEK",
      },
      {
        CITY: "MELSELE",
      },
      {
        CITY: "MEMBACH",
      },
      {
        CITY: "MENEN",
      },
      {
        CITY: "MERCHTEM",
      },
      {
        CITY: "MERE",
      },
      {
        CITY: "MERELBEKE",
      },
      {
        CITY: "MERENDREE",
      },
      {
        CITY: "MERKSEM",
      },
      {
        CITY: "MERKSPLAS",
      },
      {
        CITY: "MESPELARE",
      },
      {
        CITY: "MESSANCY",
      },
      {
        CITY: "METTET",
      },
      {
        CITY: "MEULEBEKE",
      },
      {
        CITY: "MIDDELBURG",
      },
      {
        CITY: "MIDDELKERKE",
      },
      {
        CITY: "MINDERHOUT",
      },
      {
        CITY: "MODAVE",
      },
      {
        CITY: "MOEN",
      },
      {
        CITY: "MOERBEKE",
      },
      {
        CITY: "MOERZEKE",
      },
      {
        CITY: "MOHA",
      },
      {
        CITY: "MOL",
      },
      {
        CITY: "MOLENBEEK",
      },
      {
        CITY: "MOLENBEEK-SAINT-JEAN",
      },
      {
        CITY: "MOLENSTEDE",
      },
      {
        CITY: "MOMALLE",
      },
      {
        CITY: "MONCEAU-SUR-SAMBRE",
      },
      {
        CITY: "MONS",
      },
      {
        CITY: "MONT-SAINT-GUIBERT",
      },
      {
        CITY: "MONT-SAINTE-ALDEGONDE",
      },
      {
        CITY: "MONT-SUR-MARCHIENNE",
      },
      {
        CITY: "MONTEGNEE",
      },
      {
        CITY: "MONTIGNIES-LEZ-LENS",
      },
      {
        CITY: "MONTIGNIES-SUR-SAMBRE",
      },
      {
        CITY: "MONTZEN",
      },
      {
        CITY: "MOORSEL",
      },
      {
        CITY: "MOORSLEDE",
      },
      {
        CITY: "MORIALME",
      },
      {
        CITY: "MORKHOVEN",
      },
      {
        CITY: "MORLANWELZ-MARIEMONT",
      },
      {
        CITY: "MORTSEL",
      },
      {
        CITY: "MOUSCRON",
      },
      {
        CITY: "MUIZEN",
      },
      {
        CITY: "MUIZEN",
      },
      {
        CITY: "MUNSTERBILZEN",
      },
      {
        CITY: "NAAST",
      },
      {
        CITY: "NALINNES",
      },
      {
        CITY: "NAMECHE",
      },
      {
        CITY: "NAMUR",
      },
      {
        CITY: "NANDRIN",
      },
      {
        CITY: "NATOYE",
      },
      {
        CITY: "NAZARETH",
      },
      {
        CITY: "NEDER-OVER-HEEMBEEK",
      },
      {
        CITY: "NEDERBRAKEL",
      },
      {
        CITY: "NEDERHASSELT",
      },
      {
        CITY: "NEERHAREN",
      },
      {
        CITY: "NEERIJSE",
      },
      {
        CITY: "NEERLINTER",
      },
      {
        CITY: "NEERPELT",
      },
      {
        CITY: "NETHEN",
      },
      {
        CITY: "NEUVILLE-EN-CONDROZ",
      },
      {
        CITY: "NEVELE",
      },
      {
        CITY: "NIEL",
      },
      {
        CITY: "NIEUWENHOVE",
      },
      {
        CITY: "NIEUWERKERKEN",
      },
      {
        CITY: "NIEUWERKERKEN",
      },
      {
        CITY: "NIEUWPOORT",
      },
      {
        CITY: "NIEUWPOORT",
      },
      {
        CITY: "NIEUWRODE",
      },
      {
        CITY: "NIJLEN",
      },
      {
        CITY: "NIL SAINT-VINCENT",
      },
      {
        CITY: "NIMY",
      },
      {
        CITY: "NINOVE",
      },
      {
        CITY: "NIVELLES",
      },
      {
        CITY: "NODEBAIS",
      },
      {
        CITY: "NOKERE",
      },
      {
        CITY: "NOSSEGEM",
      },
      {
        CITY: "NOUVELLES",
      },
      {
        CITY: "NUKERKE",
      },
      {
        CITY: "OBAIX",
      },
      {
        CITY: "OBOURG",
      },
      {
        CITY: "OEDELEM",
      },
      {
        CITY: "OELEGEM",
      },
      {
        CITY: "OESELGEM",
      },
      {
        CITY: "OHAIN",
      },
      {
        CITY: "OHEY",
      },
      {
        CITY: "OKEGEM",
      },
      {
        CITY: "OLEN",
      },
      {
        CITY: "OLNE",
      },
      {
        CITY: "ONZE-LIEVE-VROUW-WAVER",
      },
      {
        CITY: "OOIGEM",
      },
      {
        CITY: "OORDEGEM",
      },
      {
        CITY: "OOSTAKKER",
      },
      {
        CITY: "OOSTEEKLO",
      },
      {
        CITY: "OOSTENDE",
      },
      {
        CITY: "OOSTERZELE",
      },
      {
        CITY: "OOSTHAM",
      },
      {
        CITY: "OOSTKAMP",
      },
      {
        CITY: "OOSTMALLE",
      },
      {
        CITY: "OOSTROZEBEKE",
      },
      {
        CITY: "OOSTVLETEREN",
      },
      {
        CITY: "OPBRAKEL",
      },
      {
        CITY: "OPGLABBEEK",
      },
      {
        CITY: "OPGRIMBIE",
      },
      {
        CITY: "OPHAIN",
      },
      {
        CITY: "OPITTER",
      },
      {
        CITY: "OPOETEREN",
      },
      {
        CITY: "OPPEM",
      },
      {
        CITY: "OPPUURS",
      },
      {
        CITY: "OPWIJK",
      },
      {
        CITY: "ORBAIS",
      },
      {
        CITY: "ORP-LE-GRAND",
      },
      {
        CITY: "OSTEND",
      },
      {
        CITY: "OTEGEM",
      },
      {
        CITY: "OTHEE",
      },
      {
        CITY: "OTTIGNIES",
      },
      {
        CITY: "OUD-HEVERLEE",
      },
      {
        CITY: "OUD-TURNHOUT",
      },
      {
        CITY: "OUDEGEM",
      },
      {
        CITY: "OUDENAARDE",
      },
      {
        CITY: "OUDENBURG",
      },
      {
        CITY: "OUGREE",
      },
      {
        CITY: "OUPEYE",
      },
      {
        CITY: "OUTER",
      },
      {
        CITY: "OUTRIJVE",
      },
      {
        CITY: "OVERHEIDE",
      },
      {
        CITY: "OVERIJSE",
      },
      {
        CITY: "OVERMERE",
      },
      {
        CITY: "OVERPELT",
      },
      {
        CITY: "PAAL",
      },
      {
        CITY: "PAMEL",
      },
      {
        CITY: "PARIKE",
      },
      {
        CITY: "PARK",
      },
      {
        CITY: "PASSENDALE",
      },
      {
        CITY: "PEER",
      },
      {
        CITY: "PELLENBERG",
      },
      {
        CITY: "PEPINSTER",
      },
      {
        CITY: "PERONNES",
      },
      {
        CITY: "PERVIJZE",
      },
      {
        CITY: "PERWEZ",
      },
      {
        CITY: "PETEGEM",
      },
      {
        CITY: "PEUTIE",
      },
      {
        CITY: "PIPAIX",
      },
      {
        CITY: "PITTEM",
      },
      {
        CITY: "PLAINEVAUX",
      },
      {
        CITY: "PLANCENOIT",
      },
      {
        CITY: "PLOEGSTEERT",
      },
      {
        CITY: "POEDERLEE",
      },
      {
        CITY: "POELKAPELLE",
      },
      {
        CITY: "POLLARE",
      },
      {
        CITY: "PONT-A-CELLES",
      },
      {
        CITY: "PONT-DE-LOUP",
      },
      {
        CITY: "POPERINGE",
      },
      {
        CITY: "POPPEL",
      },
      {
        CITY: "POST",
      },
      {
        CITY: "PRESLES",
      },
      {
        CITY: "PROFONDEVILLE",
      },
      {
        CITY: "PULDERBOS",
      },
      {
        CITY: "PULLE",
      },
      {
        CITY: "PUTTE",
      },
      {
        CITY: "PUURS",
      },
      {
        CITY: "QUAREGNON",
      },
      {
        CITY: "QUENAST",
      },
      {
        CITY: "QUEVAUCAMPS",
      },
      {
        CITY: "QUIEVRAIN",
      },
      {
        CITY: "RAMET",
      },
      {
        CITY: "RAMSDONK",
      },
      {
        CITY: "RAMSEL",
      },
      {
        CITY: "RAMSKAPELLE",
      },
      {
        CITY: "RANCE",
      },
      {
        CITY: "RANSART",
      },
      {
        CITY: "RANST",
      },
      {
        CITY: "RAVELS",
      },
      {
        CITY: "REBAIX",
      },
      {
        CITY: "RECHT",
      },
      {
        CITY: "REET",
      },
      {
        CITY: "REKEM",
      },
      {
        CITY: "REKKEM",
      },
      {
        CITY: "RELEGEM",
      },
      {
        CITY: "REMERSDAAL",
      },
      {
        CITY: "REMICOURT",
      },
      {
        CITY: "RENINGE",
      },
      {
        CITY: "RENLIES",
      },
      {
        CITY: "RESSAIX",
      },
      {
        CITY: "RESSEGEM",
      },
      {
        CITY: "RETIE",
      },
      {
        CITY: "RETINNE",
      },
      {
        CITY: "RHISNES",
      },
      {
        CITY: "RICHELLE",
      },
      {
        CITY: "RIEMST",
      },
      {
        CITY: "RIJKEVORSEL",
      },
      {
        CITY: "RIJMENAM",
      },
      {
        CITY: "RILLAAR",
      },
      {
        CITY: "RIXENSART",
      },
      {
        CITY: "ROCHEFORT",
      },
      {
        CITY: "ROCOURT",
      },
      {
        CITY: "RODE",
      },
      {
        CITY: "ROESELARE",
      },
      {
        CITY: "ROEULX",
      },
      {
        CITY: "ROMSEE",
      },
      {
        CITY: "RONSE",
      },
      {
        CITY: "ROSIERES",
      },
      {
        CITY: "ROSMEER",
      },
      {
        CITY: "ROTSELAAR",
      },
      {
        CITY: "ROUX",
      },
      {
        CITY: "RUDDERVOORDE",
      },
      {
        CITY: "RUIEN",
      },
      {
        CITY: "RUISBROEK",
      },
      {
        CITY: "RUISBROEK",
      },
      {
        CITY: "RUISELEDE",
      },
      {
        CITY: "RUMBEKE",
      },
      {
        CITY: "RUMMEN",
      },
      {
        CITY: "RUMST",
      },
      {
        CITY: "SABLON",
      },
      {
        CITY: "SAINT-GEORGES-SUR-MEUSE",
      },
      {
        CITY: "SAINT-GHISLAIN",
      },
      {
        CITY: "SAINT-GILLES",
      },
      {
        CITY: "SAINT-JEAN-GEEST",
      },
      {
        CITY: "SAINT-JOSSE-TEN-NOODE",
      },
      {
        CITY: "SAINT-LEGER",
      },
      {
        CITY: "SAINT-MARD",
      },
      {
        CITY: "SAINT-NICOLAS",
      },
      {
        CITY: "SAINT-REMY",
      },
      {
        CITY: "SAINT-SAUVEUR",
      },
      {
        CITY: "SAINT-SERVAIS",
      },
      {
        CITY: "SAINT-SYMPHORIEN",
      },
      {
        CITY: "SAINTE-ODE",
      },
      {
        CITY: "SAINTES",
      },
      {
        CITY: "SAIVE",
      },
      {
        CITY: "SANKT VITH",
      },
      {
        CITY: "SART MELIN",
      },
      {
        CITY: "SART-DAMES-AVELINES",
      },
      {
        CITY: "SART-EN-FAGNE",
      },
      {
        CITY: "SART-LEZ-SPA",
      },
      {
        CITY: "SCHAARBEEK",
      },
      {
        CITY: "SCHAFFEN",
      },
      {
        CITY: "SCHALKHOVEN",
      },
      {
        CITY: "SCHELDEWINDEKE",
      },
      {
        CITY: "SCHELLE",
      },
      {
        CITY: "SCHELLEBELLE",
      },
      {
        CITY: "SCHENDELBEKE",
      },
      {
        CITY: "SCHEPDAAL",
      },
      {
        CITY: "SCHERPENHEUVEL",
      },
      {
        CITY: "SCHILDE",
      },
      {
        CITY: "SCHOONAARDE",
      },
      {
        CITY: "SCHORISSE",
      },
      {
        CITY: "SCHOTEN",
      },
      {
        CITY: "SCHRIEK",
      },
      {
        CITY: "SCHULEN",
      },
      {
        CITY: "SCLAYN",
      },
      {
        CITY: "SEILLES",
      },
      {
        CITY: "SENEFFE",
      },
      {
        CITY: "SERAING",
      },
      {
        CITY: "SERSKAMP",
      },
      {
        CITY: "SIJSELE",
      },
      {
        CITY: "SINAAI",
      },
      {
        CITY: "SINT-AGATHA-BERCHEM",
      },
      {
        CITY: "SINT-AGATHA-RODE",
      },
      {
        CITY: "SINT-AMANDS",
      },
      {
        CITY: "SINT-AMANDSBERG",
      },
      {
        CITY: "SINT-ANDRIES",
      },
      {
        CITY: "SINT-BAAFS-VIJVE",
      },
      {
        CITY: "SINT-DENIJS-WESTREM",
      },
      {
        CITY: "SINT-GENESIUS-RODE",
      },
      {
        CITY: "SINT-GILLIS-BIJ-DENDERMONDE",
      },
      {
        CITY: "SINT-GILLIS-WAAS",
      },
      {
        CITY: "SINT-KATELIJNE-WAVER",
      },
      {
        CITY: "SINT-KORNELIS-HOREBEKE",
      },
      {
        CITY: "SINT-LAMBRECHTS-HERK",
      },
      {
        CITY: "SINT-LAUREINS",
      },
      {
        CITY: "SINT-LIEVENS-HOUTEM",
      },
      {
        CITY: "SINT-MARIA-LATEM",
      },
      {
        CITY: "SINT-MARTENS-LATEM",
      },
      {
        CITY: "SINT-MICHIELS",
      },
      {
        CITY: "SINT-NIKLAAS",
      },
      {
        CITY: "SINT-PAUWELS",
      },
      {
        CITY: "SINT-PIETERS OP DE DIJK",
      },
      {
        CITY: "SINT-PIETERS-LEEUW",
      },
      {
        CITY: "SINT-STEVENS-WOLUWE",
      },
      {
        CITY: "SINT-TRUIDEN",
      },
      {
        CITY: "SIRAULT",
      },
      {
        CITY: "SLEIDINGE",
      },
      {
        CITY: "SOIGNIES",
      },
      {
        CITY: "SOMBREFFE",
      },
      {
        CITY: "SOUMAGNE",
      },
      {
        CITY: "SOUVRET",
      },
      {
        CITY: "SPA",
      },
      {
        CITY: "SPRIMONT",
      },
      {
        CITY: "STABROEK",
      },
      {
        CITY: "STADEN",
      },
      {
        CITY: "STAVELOT",
      },
      {
        CITY: "STEENDORP",
      },
      {
        CITY: "STEENOKKERZEEL",
      },
      {
        CITY: "STEKENE",
      },
      {
        CITY: "STEMBERT",
      },
      {
        CITY: "STENE",
      },
      {
        CITY: "STER",
      },
      {
        CITY: "STERREBEEK",
      },
      {
        CITY: "STEVOORT",
      },
      {
        CITY: "STOKKEM",
      },
      {
        CITY: "STOUMONT",
      },
      {
        CITY: "STREE",
      },
      {
        CITY: "STREPY-BRACQUEGNIES",
      },
      {
        CITY: "STROMBEEK-BEVER",
      },
      {
        CITY: "TAMINES",
      },
      {
        CITY: "TEMSE",
      },
      {
        CITY: "TERALFENE",
      },
      {
        CITY: "TERHAGEN",
      },
      {
        CITY: "TERNAT",
      },
      {
        CITY: "TERTRE",
      },
      {
        CITY: "TERVUREN",
      },
      {
        CITY: "TESSENDERLO",
      },
      {
        CITY: "TESTELT",
      },
      {
        CITY: "THEUX",
      },
      {
        CITY: "THIMEON",
      },
      {
        CITY: "THIMISTER",
      },
      {
        CITY: "THISNES",
      },
      {
        CITY: "THUIN",
      },
      {
        CITY: "THULIN",
      },
      {
        CITY: "THY-LE-CHATEAU",
      },
      {
        CITY: "THYNES",
      },
      {
        CITY: "TIEGEM",
      },
      {
        CITY: "TIELT",
      },
      {
        CITY: "TIELT",
      },
      {
        CITY: "TIENEN",
      },
      {
        CITY: "TILDONK",
      },
      {
        CITY: "TILFF",
      },
      {
        CITY: "TILLEUR",
      },
      {
        CITY: "TISSELT",
      },
      {
        CITY: "TOERNICH",
      },
      {
        CITY: "TOHOGNE",
      },
      {
        CITY: "TONGEREN",
      },
      {
        CITY: "TONGERLO",
      },
      {
        CITY: "TONGRE-NOTRE-DAME",
      },
      {
        CITY: "TORHOUT",
      },
      {
        CITY: "TOURNAI",
      },
      {
        CITY: "TRANSINNE",
      },
      {
        CITY: "TRAZEGNIES",
      },
      {
        CITY: "TREMELO",
      },
      {
        CITY: "TROOZ",
      },
      {
        CITY: "TUBIZE",
      },
      {
        CITY: "TURNHOUT",
      },
      {
        CITY: "UCCLE",
      },
      {
        CITY: "ULBEEK",
      },
      {
        CITY: "URSEL",
      },
      {
        CITY: "VANCE",
      },
      {
        CITY: "VARSENARE",
      },
      {
        CITY: "VAUX",
      },
      {
        CITY: "VAUX-SOUS-CHEVREMONT",
      },
      {
        CITY: "VEDRIN",
      },
      {
        CITY: "VEERLE",
      },
      {
        CITY: "VELAINE-SUR-SAMBRE",
      },
      {
        CITY: "VELAINES",
      },
      {
        CITY: "VELDEGEM",
      },
      {
        CITY: "VELM",
      },
      {
        CITY: "VELTEM-BEISEM",
      },
      {
        CITY: "VERLAINE",
      },
      {
        CITY: "VERREBROEK",
      },
      {
        CITY: "VERVIERS",
      },
      {
        CITY: "VEURNE",
      },
      {
        CITY: "VIANE",
      },
      {
        CITY: "VICHTE",
      },
      {
        CITY: "VIELSALM",
      },
      {
        CITY: "VIESVILLE",
      },
      {
        CITY: "VILLERS-LA-VILLE",
      },
      {
        CITY: "VILLERS-LE-BOUILLET",
      },
      {
        CITY: "VILLERS-SUR-SEMOIS",
      },
      {
        CITY: "VILVOORDE",
      },
      {
        CITY: "VINDERHOUTE",
      },
      {
        CITY: "VIRTON",
      },
      {
        CITY: "VISE",
      },
      {
        CITY: "VIVEGNIS",
      },
      {
        CITY: "VLADSLO",
      },
      {
        CITY: "VLEKKEM",
      },
      {
        CITY: "VLEZENBEEK",
      },
      {
        CITY: "VLIERMAAL",
      },
      {
        CITY: "VLIERMAALROOT",
      },
      {
        CITY: "VLIERZELE",
      },
      {
        CITY: "VLISSEGEM",
      },
      {
        CITY: "VOLLEZELE",
      },
      {
        CITY: "VOORMEZELE",
      },
      {
        CITY: "VOROUX-GOREUX",
      },
      {
        CITY: "VORSELAAR",
      },
      {
        CITY: "VOSSELAAR",
      },
      {
        CITY: "VOSSEM",
      },
      {
        CITY: "VOTTEM",
      },
      {
        CITY: "VREMDE",
      },
      {
        CITY: "WAARLOOS",
      },
      {
        CITY: "WAARSCHOOT",
      },
      {
        CITY: "WAASMUNSTER",
      },
      {
        CITY: "WACHTEBEKE",
      },
      {
        CITY: "WAGNELEE",
      },
      {
        CITY: "WAKKEN",
      },
      {
        CITY: "WALEFFE SAINT-GEORGES",
      },
      {
        CITY: "WALHAIN",
      },
      {
        CITY: "WALHORN",
      },
      {
        CITY: "WANFERCEE-BAULET",
      },
      {
        CITY: "WANGENIES",
      },
      {
        CITY: "WANNE",
      },
      {
        CITY: "WANZE",
      },
      {
        CITY: "WARCHIN",
      },
      {
        CITY: "WAREGEM",
      },
      {
        CITY: "WAREMME",
      },
      {
        CITY: "WARNETON",
      },
      {
        CITY: "WARQUIGNIES",
      },
      {
        CITY: "WARSAGE",
      },
      {
        CITY: "WASMES",
      },
      {
        CITY: "WATERLOO",
      },
      {
        CITY: "WATERMAEL-BOITSFORT",
      },
      {
        CITY: "WATERVLIET",
      },
      {
        CITY: "WAUDREZ",
      },
      {
        CITY: "WAVRE",
      },
      {
        CITY: "WAYS",
      },
      {
        CITY: "WECHELDERZANDE",
      },
      {
        CITY: "WEELDE",
      },
      {
        CITY: "WELKENRAEDT",
      },
      {
        CITY: "WELLE",
      },
      {
        CITY: "WELLEN",
      },
      {
        CITY: "WEMMEL",
      },
      {
        CITY: "WENDUINE",
      },
      {
        CITY: "WEPION",
      },
      {
        CITY: "WERCHTER",
      },
      {
        CITY: "WERKEN",
      },
      {
        CITY: "WERVIK",
      },
      {
        CITY: "WESPELAAR",
      },
      {
        CITY: "WESTENDE",
      },
      {
        CITY: "WESTERLO",
      },
      {
        CITY: "WESTMALLE",
      },
      {
        CITY: "WESTOUTER",
      },
      {
        CITY: "WETTEREN",
      },
      {
        CITY: "WEVELGEM",
      },
      {
        CITY: "WEZEMAAL",
      },
      {
        CITY: "WEZEMBEEK-OPPEM",
      },
      {
        CITY: "WICHELEN",
      },
      {
        CITY: "WIEKEVORST",
      },
      {
        CITY: "WIELSBEKE",
      },
      {
        CITY: "WIERDE",
      },
      {
        CITY: "WIEZE",
      },
      {
        CITY: "WIJGMAAL",
      },
      {
        CITY: "WIJNEGEM",
      },
      {
        CITY: "WILLEBROEK",
      },
      {
        CITY: "WILRIJK",
      },
      {
        CITY: "WILSELE",
      },
      {
        CITY: "WINGENE",
      },
      {
        CITY: "WINKSELE",
      },
      {
        CITY: "WOESTEN",
      },
      {
        CITY: "WOLFSDONK",
      },
      {
        CITY: "WOLKRANGE",
      },
      {
        CITY: "WOLUWE-SAINT-LAMBERT",
      },
      {
        CITY: "WOLUWE-SAINT-PIERRE - SINT-PIETERS-WOLUWE",
      },
      {
        CITY: "WOLVERTEM",
      },
      {
        CITY: "WOMMELGEM",
      },
      {
        CITY: "WONDELGEM",
      },
      {
        CITY: "WORTEGEM",
      },
      {
        CITY: "WUUSTWEZEL",
      },
      {
        CITY: "XHENDREMAEL",
      },
      {
        CITY: "XHORIS",
      },
      {
        CITY: "YPRES",
      },
      {
        CITY: "YVOIR",
      },
      {
        CITY: "ZAFFELARE",
      },
      {
        CITY: "ZANDBERGEN",
      },
      {
        CITY: "ZANDHOVEN",
      },
      {
        CITY: "ZARREN",
      },
      {
        CITY: "ZAVENTEM",
      },
      {
        CITY: "ZEDELGEM",
      },
      {
        CITY: "ZEEBRUGGE",
      },
      {
        CITY: "ZELE",
      },
      {
        CITY: "ZELLIK",
      },
      {
        CITY: "ZELZATE",
      },
      {
        CITY: "ZEMST",
      },
      {
        CITY: "ZEVENEKEN",
      },
      {
        CITY: "ZICHEM",
      },
      {
        CITY: "ZINGEM",
      },
      {
        CITY: "ZOERSEL",
      },
      {
        CITY: "ZOLDER",
      },
      {
        CITY: "ZOMERGEM",
      },
      {
        CITY: "ZONHOVEN",
      },
      {
        CITY: "ZONNEBEKE",
      },
      {
        CITY: "ZOTTEGEM",
      },
      {
        CITY: "ZOUTLEEUW",
      },
      {
        CITY: "ZUIENKERKE",
      },
      {
        CITY: "ZULTE",
      },
      {
        CITY: "ZUTENDAAL",
      },
      {
        CITY: "ZWARTENBROEK",
      },
      {
        CITY: "ZWEVEGEM",
      },
      {
        CITY: "ZWEVEZELE",
      },
      {
        CITY: "ZWIJNAARDE",
      },
      {
        CITY: "ZWIJNDRECHT",
      },
    ],
  },
  {
    COUNTRY: "BOSNIA AND HERZEGOVINA",
    CODE: "BA",
    CITIES: [
      {
        CITY: "BANJA",
      },
      {
        CITY: "BANJA LUKA",
      },
      {
        CITY: "BIJELJINA",
      },
      {
        CITY: "BOSANSKA DUBICA",
      },
      {
        CITY: "BOSANSKA KRUPA",
      },
      {
        CITY: "BRCKO",
      },
      {
        CITY: "BREZA",
      },
      {
        CITY: "BUGOJNO",
      },
      {
        CITY: "CAZIN",
      },
      {
        CITY: "CORE",
      },
      {
        CITY: "DOBOJ",
      },
      {
        CITY: "DONJA MAHALA",
      },
      {
        CITY: "GRACANICA",
      },
      {
        CITY: "ILIDZA",
      },
      {
        CITY: "JAJCE",
      },
      {
        CITY: "JELAH",
      },
      {
        CITY: "KISELJAK",
      },
      {
        CITY: "LIVNO",
      },
      {
        CITY: "LJUBUSKI",
      },
      {
        CITY: "LOPARE",
      },
      {
        CITY: "LUKAVAC",
      },
      {
        CITY: "MODRICA",
      },
      {
        CITY: "MOSTAR",
      },
      {
        CITY: "NOVI TRAVNIK",
      },
      {
        CITY: "ODZAK",
      },
      {
        CITY: "PRIJEDOR",
      },
      {
        CITY: "SANSKI MOST",
      },
      {
        CITY: "SARAJEVO",
      },
      {
        CITY: "SIROKI BRIJEG",
      },
      {
        CITY: "SRPSKA",
      },
      {
        CITY: "STUP",
      },
      {
        CITY: "TESANJ",
      },
      {
        CITY: "TRAVNIK",
      },
      {
        CITY: "TUZLA",
      },
      {
        CITY: "VISOKO",
      },
      {
        CITY: "VITEZ",
      },
      {
        CITY: "VOGOSCA",
      },
      {
        CITY: "ZENICA",
      },
      {
        CITY: "ZEPCE",
      },
      {
        CITY: "ZIVINICE",
      },
    ],
  },
  {
    COUNTRY: "CHINA",
    CODE: "CN",
    CITIES: [
      {
        CITY: "ANDONG",
      },
      {
        CITY: "ANGU",
      },
      {
        CITY: "ANGUO",
      },
      {
        CITY: "AISHANG",
      },
      {
        CITY: "AIZHOU",
      },
      {
        CITY: "AKSU",
      },
      {
        CITY: "ANBANG",
      },
      {
        CITY: "ANBU",
      },
      {
        CITY: "ANCHENG",
      },
      {
        CITY: "ANDA",
      },
      {
        CITY: "ANHU",
      },
      {
        CITY: "ANJI",
      },
      {
        CITY: "ANJIANG",
      },
      {
        CITY: "ANKANG",
      },
      {
        CITY: "ANKE",
      },
      {
        CITY: "ANQING",
      },
      {
        CITY: "ANQIU",
      },
      {
        CITY: "ANSHAN",
      },
      {
        CITY: "ANSHUN",
      },
      {
        CITY: "ANSU",
      },
      {
        CITY: "ANXI",
      },
      {
        CITY: "ANYANG",
      },
      {
        CITY: "ANYING",
      },
      {
        CITY: "ANZHOU",
      },
      {
        CITY: "ANZI",
      },
      {
        CITY: "AOJIANG",
      },
      {
        CITY: "AOMA",
      },
      {
        CITY: "BAGONG",
      },
      {
        CITY: "BAICHENG",
      },
      {
        CITY: "BAIDU",
      },
      {
        CITY: "BAIHUA",
      },
      {
        CITY: "BAILE",
      },
      {
        CITY: "BAILICUN",
      },
      {
        CITY: "BAIPO",
      },
      {
        CITY: "BAISE CITY",
      },
      {
        CITY: "BAISHA",
      },
      {
        CITY: "BAISHAN",
      },
      {
        CITY: "BAISHI",
      },
      {
        CITY: "BAITANG",
      },
      {
        CITY: "BAIXIANG",
      },
      {
        CITY: "BAIXIN",
      },
      {
        CITY: "BAIYASHI",
      },
      {
        CITY: "BAIYI",
      },
      {
        CITY: "BAIYIN",
      },
      {
        CITY: "BAIYU",
      },
      {
        CITY: "BAIYUN",
      },
      {
        CITY: "BAIZHANG",
      },
      {
        CITY: "BALIQIAO",
      },
      {
        CITY: "BAMAO",
      },
      {
        CITY: "BANGKE",
      },
      {
        CITY: "BANGTOU",
      },
      {
        CITY: "BANTOU",
      },
      {
        CITY: "BANXI",
      },
      {
        CITY: "BANYOU",
      },
      {
        CITY: "BAOCHENG",
      },
      {
        CITY: "BAODI",
      },
      {
        CITY: "BAODIAN",
      },
      {
        CITY: "BAODING",
      },
      {
        CITY: "BAOJIANG",
      },
      {
        CITY: "BAOLUO",
      },
      {
        CITY: "BAOQING",
      },
      {
        CITY: "BAOSHAN",
      },
      {
        CITY: "BAOTAO",
      },
      {
        CITY: "BAOTIAN",
      },
      {
        CITY: "BAOTOU",
      },
      {
        CITY: "BAOTOU",
      },
      {
        CITY: "BAOXING",
      },
      {
        CITY: "BAOYU",
      },
      {
        CITY: "BASE",
      },
      {
        CITY: "BAYING",
      },
      {
        CITY: "BAZHOU",
      },
      {
        CITY: "BAZIQIANG",
      },
      {
        CITY: "BEICHAN",
      },
      {
        CITY: "BEIDOU",
      },
      {
        CITY: "BEIHAI",
      },
      {
        CITY: "BEIJIE",
      },
      {
        CITY: "BEIJING",
      },
      {
        CITY: "BEILIN",
      },
      {
        CITY: "BEILUN",
      },
      {
        CITY: "BEISHAN",
      },
      {
        CITY: "BEIXI",
      },
      {
        CITY: "BEIXIAO",
      },
      {
        CITY: "BEIYING",
      },
      {
        CITY: "BEIYUAN",
      },
      {
        CITY: "BEIYUAN",
      },
      {
        CITY: "BENGBU",
      },
      {
        CITY: "BENXI",
      },
      {
        CITY: "BIANZHUANG",
      },
      {
        CITY: "BIBO",
      },
      {
        CITY: "BIJIE",
      },
      {
        CITY: "BINHE",
      },
      {
        CITY: "BINJIANG",
      },
      {
        CITY: "BINZHOU",
      },
      {
        CITY: "BIYANG",
      },
      {
        CITY: "BOBAI",
      },
      {
        CITY: "BOHAI",
      },
      {
        CITY: "BOJI",
      },
      {
        CITY: "BOLE",
      },
      {
        CITY: "BOLE",
      },
      {
        CITY: "BOLI",
      },
      {
        CITY: "BOSHAN",
      },
      {
        CITY: "BOXING",
      },
      {
        CITY: "BOZHOU",
      },
      {
        CITY: "BUXIN",
      },
      {
        CITY: "CAIJING",
      },
      {
        CITY: "CAISHEN",
      },
      {
        CITY: "CAIYUAN",
      },
      {
        CITY: "CAIYUAN",
      },
      {
        CITY: "CANGNAN",
      },
      {
        CITY: "CANGSHANG",
      },
      {
        CITY: "CANGZHOU",
      },
      {
        CITY: "CAOPU",
      },
      {
        CITY: "CAOXI",
      },
      {
        CITY: "CAOZHAI",
      },
      {
        CITY: "CENGANG",
      },
      {
        CITY: "CENYANG",
      },
      {
        CITY: "CEZI",
      },
      {
        CITY: "CHADIANZI",
      },
      {
        CITY: "CHAICUN",
      },
      {
        CITY: "CHAIGOUBU",
      },
      {
        CITY: "CHAMDO",
      },
      {
        CITY: "CHANGCHAO",
      },
      {
        CITY: "CHANGCHUN",
      },
      {
        CITY: "CHANGDE",
      },
      {
        CITY: "CHANGFENG",
      },
      {
        CITY: "CHANGHE",
      },
      {
        CITY: "CHANGJIAN",
      },
      {
        CITY: "CHANGJIANG",
      },
      {
        CITY: "CHANGLE",
      },
      {
        CITY: "CHANGLE",
      },
      {
        CITY: "CHANGNING",
      },
      {
        CITY: "CHANGPING",
      },
      {
        CITY: "CHANGQING",
      },
      {
        CITY: "CHANGSHA",
      },
      {
        CITY: "CHANGSHAN",
      },
      {
        CITY: "CHANGSHENG",
      },
      {
        CITY: "CHANGSHENG",
      },
      {
        CITY: "CHANGSHU CITY",
      },
      {
        CITY: "CHANGTING",
      },
      {
        CITY: "CHANGTU",
      },
      {
        CITY: "CHANGXIACUN",
      },
      {
        CITY: "CHANGXIN",
      },
      {
        CITY: "CHANGZHENG",
      },
      {
        CITY: "CHANGZHI",
      },
      {
        CITY: "CHANGZHONG",
      },
      {
        CITY: "CHANGZHOU",
      },
      {
        CITY: "CHAOHU",
      },
      {
        CITY: "CHAOYANG",
      },
      {
        CITY: "CHAOYANG",
      },
      {
        CITY: "CHAOZHOU",
      },
      {
        CITY: "CHEN XIAN",
      },
      {
        CITY: "CHENDU",
      },
      {
        CITY: "CHENGBEI",
      },
      {
        CITY: "CHENGDONG",
      },
      {
        CITY: "CHENGDONG",
      },
      {
        CITY: "CHENGDU",
      },
      {
        CITY: "CHENGFENG",
      },
      {
        CITY: "CHENGGUAN",
      },
      {
        CITY: "CHENGHUA",
      },
      {
        CITY: "CHENGJIAN",
      },
      {
        CITY: "CHENGMAI",
      },
      {
        CITY: "CHENGNAN",
      },
      {
        CITY: "CHENGQIAO",
      },
      {
        CITY: "CHENGUANG",
      },
      {
        CITY: "CHENGXI",
      },
      {
        CITY: "CHENGXIAN CHENGGUANZHEN",
      },
      {
        CITY: "CHENGXIANG",
      },
      {
        CITY: "CHENGXIANG",
      },
      {
        CITY: "CHENGYANG",
      },
      {
        CITY: "CHENGZHONG",
      },
      {
        CITY: "CHENYANG",
      },
      {
        CITY: "CHENZHOU",
      },
      {
        CITY: "CHIFENG",
      },
      {
        CITY: "CHIPING",
      },
      {
        CITY: "CHIZHOU",
      },
      {
        CITY: "CHONGQING",
      },
      {
        CITY: "CHONGWEN",
      },
      {
        CITY: "CHONGXIAN",
      },
      {
        CITY: "CHOUCHENG",
      },
      {
        CITY: "CHUANSHA",
      },
      {
        CITY: "CHUM",
      },
      {
        CITY: "CHUNHUA",
      },
      {
        CITY: "CHUNJIANG",
      },
      {
        CITY: "CHUXIONG",
      },
      {
        CITY: "CHUZHOU",
      },
      {
        CITY: "CICHENG",
      },
      {
        CITY: "CILI",
      },
      {
        CITY: "CITAI",
      },
      {
        CITY: "CIXI",
      },
      {
        CITY: "CUIBEI",
      },
      {
        CITY: "CUIYUAN",
      },
      {
        CITY: "CUIZHU",
      },
      {
        CITY: "CUNTOU",
      },
      {
        CITY: "DABAIZHUANG",
      },
      {
        CITY: "DABAO",
      },
      {
        CITY: "DABU",
      },
      {
        CITY: "DACHEN",
      },
      {
        CITY: "DACHUAN",
      },
      {
        CITY: "DAGANG",
      },
      {
        CITY: "DAHAI",
      },
      {
        CITY: "DAHUAISHU",
      },
      {
        CITY: "DAIDONG",
      },
      {
        CITY: "DAISHAN",
      },
      {
        CITY: "DAIXI",
      },
      {
        CITY: "DAIZHONG",
      },
      {
        CITY: "DAJI",
      },
      {
        CITY: "DAJIE",
      },
      {
        CITY: "DAJU",
      },
      {
        CITY: "DALI",
      },
      {
        CITY: "DALIAN",
      },
      {
        CITY: "DAMPU",
      },
      {
        CITY: "DANCHENG",
      },
      {
        CITY: "DANDONG",
      },
      {
        CITY: "DANFENG",
      },
      {
        CITY: "DANGSHAN",
      },
      {
        CITY: "DANGYANG",
      },
      {
        CITY: "DANJIANG",
      },
      {
        CITY: "DANJIANGKOU",
      },
      {
        CITY: "DANYANG",
      },
      {
        CITY: "DANZAO",
      },
      {
        CITY: "DANZHOU",
      },
      {
        CITY: "DAOJIANG",
      },
      {
        CITY: "DAOTOU",
      },
      {
        CITY: "DAOXU",
      },
      {
        CITY: "DAQIAO",
      },
      {
        CITY: "DAQING",
      },
      {
        CITY: "DAQING",
      },
      {
        CITY: "DAQIUZHUANG",
      },
      {
        CITY: "DASHA",
      },
      {
        CITY: "DASHIQIAO",
      },
      {
        CITY: "DASHUN",
      },
      {
        CITY: "DATANG",
      },
      {
        CITY: "DATONG",
      },
      {
        CITY: "DAWA",
      },
      {
        CITY: "DAWANG",
      },
      {
        CITY: "DAWUKOU",
      },
      {
        CITY: "DAXIE",
      },
      {
        CITY: "DAXING",
      },
      {
        CITY: "DAXING",
      },
      {
        CITY: "DAYAN",
      },
      {
        CITY: "DAYANG",
      },
      {
        CITY: "DAYE",
      },
      {
        CITY: "DAYUN",
      },
      {
        CITY: "DAZHONG",
      },
      {
        CITY: "DAZHOU",
      },
      {
        CITY: "DAZU",
      },
      {
        CITY: "DEFENG",
      },
      {
        CITY: "DEHUI",
      },
      {
        CITY: "DENGBU",
      },
      {
        CITY: "DENGZHOU",
      },
      {
        CITY: "DEQING",
      },
      {
        CITY: "DEYANG",
      },
      {
        CITY: "DEYUAN",
      },
      {
        CITY: "DEYUE",
      },
      {
        CITY: "DEZHOU",
      },
      {
        CITY: "DIANKOU",
      },
      {
        CITY: "DIANZHONG",
      },
      {
        CITY: "DIANZI",
      },
      {
        CITY: "DIGANG",
      },
      {
        CITY: "DINGCHENG",
      },
      {
        CITY: "DINGHAI",
      },
      {
        CITY: "DINGQIAO",
      },
      {
        CITY: "DINGTAO",
      },
      {
        CITY: "DINGXI",
      },
      {
        CITY: "DINGZHA",
      },
      {
        CITY: "DINGZHOU",
      },
      {
        CITY: "DIPU",
      },
      {
        CITY: "DONGCHANG",
      },
      {
        CITY: "DONGCHENG",
      },
      {
        CITY: "DONGCHENG",
      },
      {
        CITY: "DONGCUN",
      },
      {
        CITY: "DONGDU",
      },
      {
        CITY: "DONGFANG",
      },
      {
        CITY: "DONGFENG",
      },
      {
        CITY: "DONGGUAN",
      },
      {
        CITY: "DONGHAI",
      },
      {
        CITY: "DONGHE",
      },
      {
        CITY: "DONGHONG",
      },
      {
        CITY: "DONGHUXU",
      },
      {
        CITY: "DONGJIAYING",
      },
      {
        CITY: "DONGLI",
      },
      {
        CITY: "DONGLIN",
      },
      {
        CITY: "DONGLIU",
      },
      {
        CITY: "DONGNING",
      },
      {
        CITY: "DONGSHEN",
      },
      {
        CITY: "DONGSHENG",
      },
      {
        CITY: "DONGSHI",
      },
      {
        CITY: "DONGTOU",
      },
      {
        CITY: "DONGXI",
      },
      {
        CITY: "DONGXIAO",
      },
      {
        CITY: "DONGXIN",
      },
      {
        CITY: "DONGXING",
      },
      {
        CITY: "DONGYANG",
      },
      {
        CITY: "DONGYING",
      },
      {
        CITY: "DOUJIANG",
      },
      {
        CITY: "DOUMEN",
      },
      {
        CITY: "DUJIACUN",
      },
      {
        CITY: "DUNGDO",
      },
      {
        CITY: "DUOBA",
      },
      {
        CITY: "DUSHU",
      },
      {
        CITY: "DUWEI",
      },
      {
        CITY: "DUYUN",
      },
      {
        CITY: "EDONG",
      },
      {
        CITY: "EJIA",
      },
      {
        CITY: "ENSHI",
      },
      {
        CITY: "ERQI",
      },
      {
        CITY: "ESHAN",
      },
      {
        CITY: "EZHOU",
      },
      {
        CITY: "FANGQIAO",
      },
      {
        CITY: "FANGSHAN",
      },
      {
        CITY: "FANGXIAN CHENGGUANZHEN",
      },
      {
        CITY: "FANGZHUANG",
      },
      {
        CITY: "FANGZI",
      },
      {
        CITY: "FANJING",
      },
      {
        CITY: "FANZHONG",
      },
      {
        CITY: "FEIHONG",
      },
      {
        CITY: "FEILONG",
      },
      {
        CITY: "FEIXIAN",
      },
      {
        CITY: "FEIYUN",
      },
      {
        CITY: "FEIYUN",
      },
      {
        CITY: "FENDOU",
      },
      {
        CITY: "FENDOU",
      },
      {
        CITY: "FENGCHENG",
      },
      {
        CITY: "FENGCHENG",
      },
      {
        CITY: "FENGGANG",
      },
      {
        CITY: "FENGJIANG",
      },
      {
        CITY: "FENGLIN",
      },
      {
        CITY: "FENGLING",
      },
      {
        CITY: "FENGMING",
      },
      {
        CITY: "FENGNAN",
      },
      {
        CITY: "FENGQI",
      },
      {
        CITY: "FENGQIAO",
      },
      {
        CITY: "FENGQING",
      },
      {
        CITY: "FENGSHAN",
      },
      {
        CITY: "FENGSHANG",
      },
      {
        CITY: "FENGSHUN",
      },
      {
        CITY: "FENGTAI",
      },
      {
        CITY: "FENGTING",
      },
      {
        CITY: "FENGXIAN",
      },
      {
        CITY: "FENGXIANG",
      },
      {
        CITY: "FENGZHOU",
      },
      {
        CITY: "FENSHANG",
      },
      {
        CITY: "FENYANG",
      },
      {
        CITY: "FOSHAN",
      },
      {
        CITY: "FOTANG",
      },
      {
        CITY: "FUCHUN",
      },
      {
        CITY: "FUCHUNJIANG",
      },
      {
        CITY: "FUDAO",
      },
      {
        CITY: "FUDING",
      },
      {
        CITY: "FUGU",
      },
      {
        CITY: "FUHAO",
      },
      {
        CITY: "FULING",
      },
      {
        CITY: "FUMIN",
      },
      {
        CITY: "FUQIANG",
      },
      {
        CITY: "FURONG",
      },
      {
        CITY: "FUSHENG",
      },
      {
        CITY: "FUSHUN",
      },
      {
        CITY: "FUTIAN",
      },
      {
        CITY: "FUXIN",
      },
      {
        CITY: "FUYANG",
      },
      {
        CITY: "FUYANG",
      },
      {
        CITY: "FUZHOU",
      },
      {
        CITY: "FUZHOU",
      },
      {
        CITY: "GAIWEI",
      },
      {
        CITY: "GAIYU",
      },
      {
        CITY: "GAMLUNG",
      },
      {
        CITY: "GANGCUN",
      },
      {
        CITY: "GANJIANG",
      },
      {
        CITY: "GANNAN",
      },
      {
        CITY: "GANYAO",
      },
      {
        CITY: "GANZHOU",
      },
      {
        CITY: "GAOBEIDIAN",
      },
      {
        CITY: "GAOBU",
      },
      {
        CITY: "GAOCHENG",
      },
      {
        CITY: "GAOJI",
      },
      {
        CITY: "GAOPING",
      },
      {
        CITY: "GAOQIAO",
      },
      {
        CITY: "GAOTANG",
      },
      {
        CITY: "GAOTING",
      },
      {
        CITY: "GAOXIN",
      },
      {
        CITY: "GAOZHAO",
      },
      {
        CITY: "GAOZHOU",
      },
      {
        CITY: "GEJIU",
      },
      {
        CITY: "GONGCHANG",
      },
      {
        CITY: "GONGJIANG",
      },
      {
        CITY: "GONGJIAO",
      },
      {
        CITY: "GONGLI",
      },
      {
        CITY: "GONGYI",
      },
      {
        CITY: "GONGYUAN",
      },
      {
        CITY: "GOUSHAN",
      },
      {
        CITY: "GOUZHUANG",
      },
      {
        CITY: "GUALI",
      },
      {
        CITY: "GUANCHENG",
      },
      {
        CITY: "GUANGCHANG",
      },
      {
        CITY: "GUANGDONG",
      },
      {
        CITY: "GUANGMING",
      },
      {
        CITY: "GUANGTAI",
      },
      {
        CITY: "GUANGTONG",
      },
      {
        CITY: "GUANGXI",
      },
      {
        CITY: "GUANGYUAN",
      },
      {
        CITY: "GUANGZHOU",
      },
      {
        CITY: "GUANLI",
      },
      {
        CITY: "GUANQIAO",
      },
      {
        CITY: "GUANTIAN",
      },
      {
        CITY: "GUCHENG",
      },
      {
        CITY: "GUCHENG",
      },
      {
        CITY: "GUIGANG",
      },
      {
        CITY: "GUIGANG",
      },
      {
        CITY: "GUILI",
      },
      {
        CITY: "GUILIN",
      },
      {
        CITY: "GUIXI",
      },
      {
        CITY: "GUIYANG",
      },
      {
        CITY: "GUIYUAN",
      },
      {
        CITY: "GUIZHOUMANZUXIANG",
      },
      {
        CITY: "GUJIANG",
      },
      {
        CITY: "GUJIAO",
      },
      {
        CITY: "GULI",
      },
      {
        CITY: "GUNAN",
      },
      {
        CITY: "GUODIAN",
      },
      {
        CITY: "GUOHE",
      },
      {
        CITY: "GUOJI",
      },
      {
        CITY: "GUOSHANG",
      },
      {
        CITY: "GUOZHEN",
      },
      {
        CITY: "GUSHAN",
      },
      {
        CITY: "GUSHI",
      },
      {
        CITY: "GUXIANG",
      },
      {
        CITY: "GUYONG",
      },
      {
        CITY: "GUYUAN",
      },
      {
        CITY: "GYARI",
      },
      {
        CITY: "HAIBEI",
      },
      {
        CITY: "HAICHENG",
      },
      {
        CITY: "HAIDIAN",
      },
      {
        CITY: "HAIFU",
      },
      {
        CITY: "HAIHONG",
      },
      {
        CITY: "HAIJING",
      },
      {
        CITY: "HAIKOU",
      },
      {
        CITY: "HAILAR",
      },
      {
        CITY: "HAILIN",
      },
      {
        CITY: "HAINAN",
      },
      {
        CITY: "HAITANG",
      },
      {
        CITY: "HAIXING",
      },
      {
        CITY: "HAIYANG",
      },
      {
        CITY: "HAIYU",
      },
      {
        CITY: "HAIZHOU",
      },
      {
        CITY: "HANDAN",
      },
      {
        CITY: "HANGBU",
      },
      {
        CITY: "HANGONG",
      },
      {
        CITY: "HANGU",
      },
      {
        CITY: "HANGZHOU",
      },
      {
        CITY: "HANJIANG",
      },
      {
        CITY: "HANSHAN",
      },
      {
        CITY: "HANSHANG",
      },
      {
        CITY: "HANTING",
      },
      {
        CITY: "HANZHONG",
      },
      {
        CITY: "HAOCHENG",
      },
      {
        CITY: "HAOZHUANG",
      },
      {
        CITY: "HARBIN",
      },
      {
        CITY: "HEBEI",
      },
      {
        CITY: "HEBEI",
      },
      {
        CITY: "HEBI",
      },
      {
        CITY: "HECHENG",
      },
      {
        CITY: "HECHI",
      },
      {
        CITY: "HECHI",
      },
      {
        CITY: "HECHUN",
      },
      {
        CITY: "HECUN",
      },
      {
        CITY: "HEDONG",
      },
      {
        CITY: "HEFEI",
      },
      {
        CITY: "HEGANG",
      },
      {
        CITY: "HEIBEI",
      },
      {
        CITY: "HEIHE",
      },
      {
        CITY: "HEJIANG",
      },
      {
        CITY: "HEJIN",
      },
      {
        CITY: "HEKOU",
      },
      {
        CITY: "HELIANG",
      },
      {
        CITY: "HENGDIAN",
      },
      {
        CITY: "HENGHE",
      },
      {
        CITY: "HENGJIE",
      },
      {
        CITY: "HENGSHUI",
      },
      {
        CITY: "HENGYANG",
      },
      {
        CITY: "HENGZHOU",
      },
      {
        CITY: "HEPING",
      },
      {
        CITY: "HESHUN",
      },
      {
        CITY: "HETAO",
      },
      {
        CITY: "HETIAN",
      },
      {
        CITY: "HEXI",
      },
      {
        CITY: "HEXUN",
      },
      {
        CITY: "HEYI",
      },
      {
        CITY: "HEYUAN",
      },
      {
        CITY: "HEYUAN",
      },
      {
        CITY: "HEZE",
      },
      {
        CITY: "HEZHOU",
      },
      {
        CITY: "HEZHU",
      },
      {
        CITY: "HEZUO",
      },
      {
        CITY: "HOHHOT",
      },
      {
        CITY: "HONGCHANG",
      },
      {
        CITY: "HONGLIN",
      },
      {
        CITY: "HONGLING",
      },
      {
        CITY: "HONGPAN",
      },
      {
        CITY: "HONGQIAO",
      },
      {
        CITY: "HONGSHAN",
      },
      {
        CITY: "HONGSHUN",
      },
      {
        CITY: "HONGTANG",
      },
      {
        CITY: "HONGWEI",
      },
      {
        CITY: "HONGXI",
      },
      {
        CITY: "HONGYAN",
      },
      {
        CITY: "HONGYANGSHU",
      },
      {
        CITY: "HONGYUAN",
      },
      {
        CITY: "HONGYUN",
      },
      {
        CITY: "HOUXIANGCHENG",
      },
      {
        CITY: "HOUZHAI",
      },
      {
        CITY: "HUABEI",
      },
      {
        CITY: "HUACHENG",
      },
      {
        CITY: "HUADIAN",
      },
      {
        CITY: "HUADONG",
      },
      {
        CITY: "HUAFENG",
      },
      {
        CITY: "HUAGONG",
      },
      {
        CITY: "HUAGONGCHANG",
      },
      {
        CITY: "HUAIBEI",
      },
      {
        CITY: "HUAIHE",
      },
      {
        CITY: "HUAIHUA",
      },
      {
        CITY: "HUAINAN",
      },
      {
        CITY: "HUAIROU",
      },
      {
        CITY: "HUAJIASHAN",
      },
      {
        CITY: "HUAJIE",
      },
      {
        CITY: "HUALI",
      },
      {
        CITY: "HUALIN",
      },
      {
        CITY: "HUALONG",
      },
      {
        CITY: "HUAMEI",
      },
      {
        CITY: "HUANAN",
      },
      {
        CITY: "HUANGBAO",
      },
      {
        CITY: "HUANGDAO",
      },
      {
        CITY: "HUANGDU",
      },
      {
        CITY: "HUANGGANG",
      },
      {
        CITY: "HUANGHOU",
      },
      {
        CITY: "HUANGHU",
      },
      {
        CITY: "HUANGHUA",
      },
      {
        CITY: "HUANGJIA",
      },
      {
        CITY: "HUANGJUE",
      },
      {
        CITY: "HUANGSHAN",
      },
      {
        CITY: "HUANGSHI",
      },
      {
        CITY: "HUANGSHI",
      },
      {
        CITY: "HUANGXUAN",
      },
      {
        CITY: "HUANGYAN",
      },
      {
        CITY: "HUANGYUAN CHENGGUANZHEN",
      },
      {
        CITY: "HUANGZE",
      },
      {
        CITY: "HUANGZHAI",
      },
      {
        CITY: "HUANGZHOU",
      },
      {
        CITY: "HUANZHEN",
      },
      {
        CITY: "HUAQIANG",
      },
      {
        CITY: "HUAQIAO",
      },
      {
        CITY: "HUASHAN",
      },
      {
        CITY: "HUATAN",
      },
      {
        CITY: "HUATING",
      },
      {
        CITY: "HUAWEI",
      },
      {
        CITY: "HUAXIA",
      },
      {
        CITY: "HUAXIN",
      },
      {
        CITY: "HUAXING",
      },
      {
        CITY: "HUAYANG",
      },
      {
        CITY: "HUAYING",
      },
      {
        CITY: "HUAYUAN",
      },
      {
        CITY: "HUAYUE",
      },
      {
        CITY: "HUAYUJI",
      },
      {
        CITY: "HUAZHONG",
      },
      {
        CITY: "HUAZHOU",
      },
      {
        CITY: "HUGE",
      },
      {
        CITY: "HUICHENG",
      },
      {
        CITY: "HUIGANG",
      },
      {
        CITY: "HUILONGBA",
      },
      {
        CITY: "HUIMIN",
      },
      {
        CITY: "HUIMIN",
      },
      {
        CITY: "HUIXIN",
      },
      {
        CITY: "HUIYANG",
      },
      {
        CITY: "HUIZHOU",
      },
      {
        CITY: "HULAN",
      },
      {
        CITY: "HULING",
      },
      {
        CITY: "HULU",
      },
      {
        CITY: "HULUDAO",
      },
      {
        CITY: "HUOSHAN",
      },
      {
        CITY: "HUOZHOU",
      },
      {
        CITY: "HUPI",
      },
      {
        CITY: "HUQUAN",
      },
      {
        CITY: "HUSHAN",
      },
      {
        CITY: "HUZHEN",
      },
      {
        CITY: "HUZHOU",
      },
      {
        CITY: "JIADI",
      },
      {
        CITY: "JIADING",
      },
      {
        CITY: "JIAFU",
      },
      {
        CITY: "JIAHAO",
      },
      {
        CITY: "JIAHE CHENGGUANZHEN",
      },
      {
        CITY: "JIAHU",
      },
      {
        CITY: "JIALING",
      },
      {
        CITY: "JIAMUSI",
      },
      {
        CITY: "JIANCI",
      },
      {
        CITY: "JIANGBEI",
      },
      {
        CITY: "JIANGCHUANLU",
      },
      {
        CITY: "JIANGDONG",
      },
      {
        CITY: "JIANGGUANTUN",
      },
      {
        CITY: "JIANGJIA",
      },
      {
        CITY: "JIANGJIN QU",
      },
      {
        CITY: "JIANGKOU",
      },
      {
        CITY: "JIANGMEN",
      },
      {
        CITY: "JIANGNAN",
      },
      {
        CITY: "JIANGNAN",
      },
      {
        CITY: "JIANGSHAN",
      },
      {
        CITY: "JIANGTIAN",
      },
      {
        CITY: "JIANGWAN",
      },
      {
        CITY: "JIANGWANG",
      },
      {
        CITY: "JIANGYAN",
      },
      {
        CITY: "JIANGYIN",
      },
      {
        CITY: "JIANGYOU",
      },
      {
        CITY: "JIANNING",
      },
      {
        CITY: "JIANXING",
      },
      {
        CITY: "JIANYANG",
      },
      {
        CITY: "JIANYUAN",
      },
      {
        CITY: "JIANZHONG",
      },
      {
        CITY: "JIAOJIANG",
      },
      {
        CITY: "JIAOKOU",
      },
      {
        CITY: "JIAOTONG",
      },
      {
        CITY: "JIAOTOU",
      },
      {
        CITY: "JIAOWEI",
      },
      {
        CITY: "JIAOYU",
      },
      {
        CITY: "JIAOYUAN",
      },
      {
        CITY: "JIAOYUN",
      },
      {
        CITY: "JIAOZHOU",
      },
      {
        CITY: "JIAOZUO",
      },
      {
        CITY: "JIAQIN",
      },
      {
        CITY: "JIAXIN",
      },
      {
        CITY: "JIAXING",
      },
      {
        CITY: "JIAYING",
      },
      {
        CITY: "JIAYUAN",
      },
      {
        CITY: "JIAYUE",
      },
      {
        CITY: "JIAZHUANG",
      },
      {
        CITY: "JICHENG",
      },
      {
        CITY: "JIEDONG",
      },
      {
        CITY: "JIEFANG",
      },
      {
        CITY: "JIEHE",
      },
      {
        CITY: "JIELONG",
      },
      {
        CITY: "JIELONG",
      },
      {
        CITY: "JIELONG",
      },
      {
        CITY: "JIESHOU",
      },
      {
        CITY: "JIEXI",
      },
      {
        CITY: "JIEXIU",
      },
      {
        CITY: "JIEYANG",
      },
      {
        CITY: "JIFENG",
      },
      {
        CITY: "JIGUANG",
      },
      {
        CITY: "JILIN CITY",
      },
      {
        CITY: "JIMO",
      },
      {
        CITY: "JIN JIANG",
      },
      {
        CITY: "JINAN",
      },
      {
        CITY: "JINBI",
      },
      {
        CITY: "JINCHENG",
      },
      {
        CITY: "JINCHUAN",
      },
      {
        CITY: "JINCI",
      },
      {
        CITY: "JINDA",
      },
      {
        CITY: "JINDE",
      },
      {
        CITY: "JINDI",
      },
      {
        CITY: "JINDIAN",
      },
      {
        CITY: "JINDING",
      },
      {
        CITY: "JINDOU",
      },
      {
        CITY: "JINDU",
      },
      {
        CITY: "JINGBEI",
      },
      {
        CITY: "JINGCHENG",
      },
      {
        CITY: "JINGDEZHEN",
      },
      {
        CITY: "JINGDONG",
      },
      {
        CITY: "JINGDU",
      },
      {
        CITY: "JINGFENG",
      },
      {
        CITY: "JINGHAI",
      },
      {
        CITY: "JINGHE",
      },
      {
        CITY: "JINGHONG",
      },
      {
        CITY: "JINGHU",
      },
      {
        CITY: "JINGHUA",
      },
      {
        CITY: "JINGJIANG",
      },
      {
        CITY: "JINGLIAN",
      },
      {
        CITY: "JINGLONG",
      },
      {
        CITY: "JINGMEN",
      },
      {
        CITY: "JINGNING CHENGGUANZHEN",
      },
      {
        CITY: "JINGSHAN",
      },
      {
        CITY: "JINGTIAN",
      },
      {
        CITY: "JINGUANG",
      },
      {
        CITY: "JINGXI",
      },
      {
        CITY: "JINGYUAN",
      },
      {
        CITY: "JINGZHONG",
      },
      {
        CITY: "JINGZHOU",
      },
      {
        CITY: "JINHAI",
      },
      {
        CITY: "JINHE",
      },
      {
        CITY: "JINHONG",
      },
      {
        CITY: "JINHU",
      },
      {
        CITY: "JINHUA",
      },
      {
        CITY: "JINING",
      },
      {
        CITY: "JINJIANG",
      },
      {
        CITY: "JINLI",
      },
      {
        CITY: "JINLING",
      },
      {
        CITY: "JINLONG",
      },
      {
        CITY: "JINLU",
      },
      {
        CITY: "JINLUN",
      },
      {
        CITY: "JINMA",
      },
      {
        CITY: "JINMAO",
      },
      {
        CITY: "JINMEN",
      },
      {
        CITY: "JINMING",
      },
      {
        CITY: "JINNAN",
      },
      {
        CITY: "JINPAN",
      },
      {
        CITY: "JINPU",
      },
      {
        CITY: "JINQIAO",
      },
      {
        CITY: "JINQINGZHEN",
      },
      {
        CITY: "JINSHA",
      },
      {
        CITY: "JINSHADI",
      },
      {
        CITY: "JINSHAN",
      },
      {
        CITY: "JINSHI",
      },
      {
        CITY: "JINTAN",
      },
      {
        CITY: "JINTIAN",
      },
      {
        CITY: "JINWO",
      },
      {
        CITY: "JINXIANGZHEN",
      },
      {
        CITY: "JINXING",
      },
      {
        CITY: "JINYANG",
      },
      {
        CITY: "JINYI",
      },
      {
        CITY: "JINYIN",
      },
      {
        CITY: "JINYING",
      },
      {
        CITY: "JINYUAN",
      },
      {
        CITY: "JINYUN",
      },
      {
        CITY: "JINZAO",
      },
      {
        CITY: "JINZHONG",
      },
      {
        CITY: "JINZHOU",
      },
      {
        CITY: "JISHOU",
      },
      {
        CITY: "JISHU",
      },
      {
        CITY: "JIUBA",
      },
      {
        CITY: "JIUGUAN",
      },
      {
        CITY: "JIUZONG",
      },
      {
        CITY: "JIXI",
      },
      {
        CITY: "JIYA",
      },
      {
        CITY: "JIYANG",
      },
      {
        CITY: "JIYANG",
      },
      {
        CITY: "JIZHOU",
      },
      {
        CITY: "JONDAG",
      },
      {
        CITY: "JUCHUAN",
      },
      {
        CITY: "JUHUA",
      },
      {
        CITY: "JULONG",
      },
      {
        CITY: "JUNDU",
      },
      {
        CITY: "JUNLIN",
      },
      {
        CITY: "JUNPING",
      },
      {
        CITY: "JUNPU",
      },
      {
        CITY: "KAIFENG",
      },
      {
        CITY: "KAIHUA",
      },
      {
        CITY: "KAILI",
      },
      {
        CITY: "KAIYUAN",
      },
      {
        CITY: "KAIYUN",
      },
      {
        CITY: "KANDUN",
      },
      {
        CITY: "KANG XIAN",
      },
      {
        CITY: "KANGCUN",
      },
      {
        CITY: "KANGDA",
      },
      {
        CITY: "KARAMAY",
      },
      {
        CITY: "KASHGAR",
      },
      {
        CITY: "KECHENG",
      },
      {
        CITY: "KEQIAO",
      },
      {
        CITY: "KESHAN",
      },
      {
        CITY: "KORLA",
      },
      {
        CITY: "KOUQIAN",
      },
      {
        CITY: "KOUQUAN",
      },
      {
        CITY: "KUANGYAN",
      },
      {
        CITY: "KUICHENG",
      },
      {
        CITY: "KUIJU",
      },
      {
        CITY: "KUISHAN",
      },
      {
        CITY: "KUMUL",
      },
      {
        CITY: "KUNLUN",
      },
      {
        CITY: "KUNMING",
      },
      {
        CITY: "KUNSHAN",
      },
      {
        CITY: "KUNSHAN",
      },
      {
        CITY: "KUNYANG",
      },
      {
        CITY: "KUYTUN",
      },
      {
        CITY: "LAIBIN",
      },
      {
        CITY: "LAIWU",
      },
      {
        CITY: "LAIYI",
      },
      {
        CITY: "LAKE",
      },
      {
        CITY: "LANGFANG",
      },
      {
        CITY: "LANGLI",
      },
      {
        CITY: "LANGZHONG",
      },
      {
        CITY: "LANHAI",
      },
      {
        CITY: "LANJIANG",
      },
      {
        CITY: "LANPING",
      },
      {
        CITY: "LANTANG",
      },
      {
        CITY: "LANXI",
      },
      {
        CITY: "LANZHOU",
      },
      {
        CITY: "LAOHEKOU",
      },
      {
        CITY: "LAONIAN",
      },
      {
        CITY: "LECHENG",
      },
      {
        CITY: "LEDONG",
      },
      {
        CITY: "LENGSHUIJIANG",
      },
      {
        CITY: "LESHAN",
      },
      {
        CITY: "LHASA",
      },
      {
        CITY: "LIANGHU",
      },
      {
        CITY: "LIANGPING",
      },
      {
        CITY: "LIANGZHU",
      },
      {
        CITY: "LIANHUA",
      },
      {
        CITY: "LIANJIANG",
      },
      {
        CITY: "LIANNAN",
      },
      {
        CITY: "LIANPING",
      },
      {
        CITY: "LIANSHAN",
      },
      {
        CITY: "LIANSHI",
      },
      {
        CITY: "LIANSHUI",
      },
      {
        CITY: "LIANYUAN",
      },
      {
        CITY: "LIANYUNGANG",
      },
      {
        CITY: "LIAOCHENG",
      },
      {
        CITY: "LIAOYANG",
      },
      {
        CITY: "LIAOYANG",
      },
      {
        CITY: "LIAOYUAN",
      },
      {
        CITY: "LIBANG",
      },
      {
        CITY: "LICHENG",
      },
      {
        CITY: "LICHENG",
      },
      {
        CITY: "LIDA",
      },
      {
        CITY: "LIDONG",
      },
      {
        CITY: "LIDU",
      },
      {
        CITY: "LIHOU",
      },
      {
        CITY: "LIJIANG",
      },
      {
        CITY: "LIJING",
      },
      {
        CITY: "LINBEI",
      },
      {
        CITY: "LINCANG",
      },
      {
        CITY: "LINCHENG",
      },
      {
        CITY: "LINCHENG",
      },
      {
        CITY: "LINDIAN",
      },
      {
        CITY: "LINFEN",
      },
      {
        CITY: "LINGANG",
      },
      {
        CITY: "LINGCHENG",
      },
      {
        CITY: "LINGCHENG",
      },
      {
        CITY: "LINGDONG",
      },
      {
        CITY: "LINGHU",
      },
      {
        CITY: "LINGNGA",
      },
      {
        CITY: "LINGSHI",
      },
      {
        CITY: "LINGSHUI",
      },
      {
        CITY: "LINGXI",
      },
      {
        CITY: "LINGZHI",
      },
      {
        CITY: "LINHAI",
      },
      {
        CITY: "LINJIANG",
      },
      {
        CITY: "LINJIANG",
      },
      {
        CITY: "LINKOU",
      },
      {
        CITY: "LINNAN",
      },
      {
        CITY: "LINPING",
      },
      {
        CITY: "LINQI",
      },
      {
        CITY: "LINQING",
      },
      {
        CITY: "LINQU",
      },
      {
        CITY: "LINTONG",
      },
      {
        CITY: "LINXIA CHENGGUANZHEN",
      },
      {
        CITY: "LINYI",
      },
      {
        CITY: "LINZE",
      },
      {
        CITY: "LINZHOU",
      },
      {
        CITY: "LISHANG",
      },
      {
        CITY: "LISHU",
      },
      {
        CITY: "LISHUI",
      },
      {
        CITY: "LIU`AN",
      },
      {
        CITY: "LIUCHENG",
      },
      {
        CITY: "LIUDAO",
      },
      {
        CITY: "LIUDONG",
      },
      {
        CITY: "LIUHE",
      },
      {
        CITY: "LIUHENG",
      },
      {
        CITY: "LIUJIAN",
      },
      {
        CITY: "LIUJIANG",
      },
      {
        CITY: "LIULIN",
      },
      {
        CITY: "LIUSHI",
      },
      {
        CITY: "LIUZHOU",
      },
      {
        CITY: "LIWU",
      },
      {
        CITY: "LIYUAN",
      },
      {
        CITY: "LIZE",
      },
      {
        CITY: "LONGCHUAN",
      },
      {
        CITY: "LONGDONG",
      },
      {
        CITY: "LONGFENG",
      },
      {
        CITY: "LONGGANG",
      },
      {
        CITY: "LONGGONG",
      },
      {
        CITY: "LONGHU",
      },
      {
        CITY: "LONGHUA",
      },
      {
        CITY: "LONGHUA",
      },
      {
        CITY: "LONGJIAN",
      },
      {
        CITY: "LONGJIANG",
      },
      {
        CITY: "LONGJIN",
      },
      {
        CITY: "LONGLIN",
      },
      {
        CITY: "LONGMAO",
      },
      {
        CITY: "LONGMEN",
      },
      {
        CITY: "LONGNAN",
      },
      {
        CITY: "LONGQUAN",
      },
      {
        CITY: "LONGSHAN",
      },
      {
        CITY: "LONGTAI",
      },
      {
        CITY: "LONGTENG",
      },
      {
        CITY: "LONGXIANG",
      },
      {
        CITY: "LONGXIN",
      },
      {
        CITY: "LONGXING",
      },
      {
        CITY: "LONGXUN",
      },
      {
        CITY: "LONGYAN",
      },
      {
        CITY: "LONGZHOU",
      },
      {
        CITY: "LOUFAN",
      },
      {
        CITY: "LUCHENG",
      },
      {
        CITY: "LUGOUQIAO",
      },
      {
        CITY: "LUHUA",
      },
      {
        CITY: "LUJIAZHI",
      },
      {
        CITY: "LUNG",
      },
      {
        CITY: "LUOBU",
      },
      {
        CITY: "LUOCHENG",
      },
      {
        CITY: "LUOFANG",
      },
      {
        CITY: "LUOHE",
      },
      {
        CITY: "LUOHU",
      },
      {
        CITY: "LUOHU",
      },
      {
        CITY: "LUOLING",
      },
      {
        CITY: "LUOMEN",
      },
      {
        CITY: "LUORONG",
      },
      {
        CITY: "LUOXI",
      },
      {
        CITY: "LUOYANG",
      },
      {
        CITY: "LUOYANG",
      },
      {
        CITY: "LUOYANG",
      },
      {
        CITY: "LUOYUAN",
      },
      {
        CITY: "LUOZHUANG",
      },
      {
        CITY: "LUQIAO",
      },
      {
        CITY: "LUQIAO",
      },
      {
        CITY: "LUQUAN",
      },
      {
        CITY: "LUSHANG",
      },
      {
        CITY: "LUWAN",
      },
      {
        CITY: "LUZHOU",
      },
      {
        CITY: "LUZHOU",
      },
      {
        CITY: "MACAO",
      },
      {
        CITY: "MAJI",
      },
      {
        CITY: "MAJIN",
      },
      {
        CITY: "MAOBA",
      },
      {
        CITY: "MAODIAN",
      },
      {
        CITY: "MAODUN",
      },
      {
        CITY: "MAOMING",
      },
      {
        CITY: "MAOTAI",
      },
      {
        CITY: "MAPAI",
      },
      {
        CITY: "MAWEI",
      },
      {
        CITY: "MAYAO",
      },
      {
        CITY: "MAYU",
      },
      {
        CITY: "MEICHENG",
      },
      {
        CITY: "MEIJIANG",
      },
      {
        CITY: "MEILIAN",
      },
      {
        CITY: "MEILIN",
      },
      {
        CITY: "MEISHAN",
      },
      {
        CITY: "MEIXI",
      },
      {
        CITY: "MEIYU",
      },
      {
        CITY: "MEIYUAN",
      },
      {
        CITY: "MEIZHOU",
      },
      {
        CITY: "MEIZHOU",
      },
      {
        CITY: "MENGSHAN",
      },
      {
        CITY: "MENGYIN",
      },
      {
        CITY: "MENGZHOU",
      },
      {
        CITY: "MENGZI",
      },
      {
        CITY: "MIANYANG",
      },
      {
        CITY: "MIAOGAO",
      },
      {
        CITY: "MINGDE",
      },
      {
        CITY: "MINGDU",
      },
      {
        CITY: "MINGJIA",
      },
      {
        CITY: "MINGJING",
      },
      {
        CITY: "MINGSHUI",
      },
      {
        CITY: "MINGXI",
      },
      {
        CITY: "MINGXING",
      },
      {
        CITY: "MINGZHOU",
      },
      {
        CITY: "MINGZHOU",
      },
      {
        CITY: "MINGZHU",
      },
      {
        CITY: "MINHANG",
      },
      {
        CITY: "MINJIANG",
      },
      {
        CITY: "MINJIE",
      },
      {
        CITY: "MINZHU",
      },
      {
        CITY: "MINZU",
      },
      {
        CITY: "MISHAN",
      },
      {
        CITY: "MIYUN",
      },
      {
        CITY: "MOUSHAN",
      },
      {
        CITY: "MUDANJIANG",
      },
      {
        CITY: "MULING",
      },
      {
        CITY: "NAGQU",
      },
      {
        CITY: "CITY",
      },
      {
        CITY: "NANCHANG",
      },
      {
        CITY: "NANCHONG",
      },
      {
        CITY: "NANDA",
      },
      {
        CITY: "NANDU",
      },
      {
        CITY: "NANFANG",
      },
      {
        CITY: "NANGUO",
      },
      {
        CITY: "NANGXIAN",
      },
      {
        CITY: "NANHAI",
      },
      {
        CITY: "NANHAN",
      },
      {
        CITY: "NANHE",
      },
      {
        CITY: "NANHU",
      },
      {
        CITY: "NANJIANG",
      },
      {
        CITY: "NANJIN",
      },
      {
        CITY: "NANJING",
      },
      {
        CITY: "NANKAI",
      },
      {
        CITY: "NANLONG",
      },
      {
        CITY: "NANMA",
      },
      {
        CITY: "NANMEN",
      },
      {
        CITY: "NANNING",
      },
      {
        CITY: "NANNING",
      },
      {
        CITY: "NANPING",
      },
      {
        CITY: "NANSHAN",
      },
      {
        CITY: "NANTANG",
      },
      {
        CITY: "NANTONG",
      },
      {
        CITY: "NANXI",
      },
      {
        CITY: "NANXIANGLONG",
      },
      {
        CITY: "NANXUN",
      },
      {
        CITY: "NANYANG",
      },
      {
        CITY: "NANYING",
      },
      {
        CITY: "NANYUAN",
      },
      {
        CITY: "NANZHAO",
      },
      {
        CITY: "NANZHUANG",
      },
      {
        CITY: "NAPO",
      },
      {
        CITY: "NAXI YAOZUXIANG",
      },
      {
        CITY: "NEIJIANG",
      },
      {
        CITY: "NENJIANG",
      },
      {
        CITY: "NIAOJIANG",
      },
      {
        CITY: "NINGBO",
      },
      {
        CITY: "NINGDE",
      },
      {
        CITY: "NINGHAI",
      },
      {
        CITY: "NINGHE",
      },
      {
        CITY: "NINGYI",
      },
      {
        CITY: "NIULANSHAN",
      },
      {
        CITY: "NIUNAICHANG",
      },
      {
        CITY: "NONGKENG",
      },
      {
        CITY: "NYINGCHI COUNTY",
      },
      {
        CITY: "OUCHENG",
      },
      {
        CITY: "OUJIANG",
      },
      {
        CITY: "PANJIN SHI",
      },
      {
        CITY: "PANXI",
      },
      {
        CITY: "PANZHIHUA",
      },
      {
        CITY: "PENGCHENG",
      },
      {
        CITY: "PENGLAI",
      },
      {
        CITY: "PENGSHAN",
      },
      {
        CITY: "PENGYU",
      },
      {
        CITY: "PINGCHUAN",
      },
      {
        CITY: "PINGDINGSHAN",
      },
      {
        CITY: "PINGHU",
      },
      {
        CITY: "PINGHU",
      },
      {
        CITY: "PINGLIANG",
      },
      {
        CITY: "PINGLU",
      },
      {
        CITY: "PINGWANG",
      },
      {
        CITY: "PINGXIANG",
      },
      {
        CITY: "PINGYANG",
      },
      {
        CITY: "PINGYAO COUNTY",
      },
      {
        CITY: "PINGYI",
      },
      {
        CITY: "PINGYIN",
      },
      {
        CITY: "PINGYUAN",
      },
      {
        CITY: "PIZHOU",
      },
      {
        CITY: "POTANG",
      },
      {
        CITY: "PUCHENG",
      },
      {
        CITY: "PUDONG",
      },
      {
        CITY: "PUNING",
      },
      {
        CITY: "PUNING",
      },
      {
        CITY: "PUQING",
      },
      {
        CITY: "PUTIAN",
      },
      {
        CITY: "PUTIAN",
      },
      {
        CITY: "PUTUO",
      },
      {
        CITY: "PUTUOSHAN",
      },
      {
        CITY: "PUYANG",
      },
      {
        CITY: "PUYANG",
      },
      {
        CITY: "PUYUAN",
      },
      {
        CITY: "QIANDAO",
      },
      {
        CITY: "QIANDAOHU",
      },
      {
        CITY: "QIANHAI",
      },
      {
        CITY: "QIANJIANG",
      },
      {
        CITY: "QIANJIN",
      },
      {
        CITY: "QIANLING",
      },
      {
        CITY: "QIANQING",
      },
      {
        CITY: "QIANSHAN",
      },
      {
        CITY: "QIANTAN",
      },
      {
        CITY: "QIANTANG",
      },
      {
        CITY: "QIANYU",
      },
      {
        CITY: "QIAODOU",
      },
      {
        CITY: "QIAOLIAN",
      },
      {
        CITY: "QIAOTING",
      },
      {
        CITY: "QIAOXIA",
      },
      {
        CITY: "QIAOYUAN",
      },
      {
        CITY: "QIAOZHEN",
      },
      {
        CITY: "QIDU",
      },
      {
        CITY: "QIJIASHAN",
      },
      {
        CITY: "QIKE",
      },
      {
        CITY: "QINGDAO",
      },
      {
        CITY: "QINGFENG CHENGGUANZHEN",
      },
      {
        CITY: "QINGGANG",
      },
      {
        CITY: "QINGHAI",
      },
      {
        CITY: "QINGHU",
      },
      {
        CITY: "QINGMA",
      },
      {
        CITY: "QINGNIAN",
      },
      {
        CITY: "QINGQUAN",
      },
      {
        CITY: "QINGSHAN",
      },
      {
        CITY: "QINGSHUIWAN",
      },
      {
        CITY: "QINGTIAN",
      },
      {
        CITY: "QINGTIAN",
      },
      {
        CITY: "QINGTONG",
      },
      {
        CITY: "QINGXIN",
      },
      {
        CITY: "QINGYANG",
      },
      {
        CITY: "QINGYANG",
      },
      {
        CITY: "QINGYUAN",
      },
      {
        CITY: "QINGYUAN",
      },
      {
        CITY: "QINGZHEN",
      },
      {
        CITY: "QINGZHOU",
      },
      {
        CITY: "QINHUANGDAO",
      },
      {
        CITY: "QINJIANG",
      },
      {
        CITY: "QINZHOU",
      },
      {
        CITY: "QIONGHAI",
      },
      {
        CITY: "QIONGSHAN",
      },
      {
        CITY: "QIQIHAR",
      },
      {
        CITY: "QITAI",
      },
      {
        CITY: "QUANZHOU",
      },
      {
        CITY: "QUANZHOU",
      },
      {
        CITY: "QUJIANG",
      },
      {
        CITY: "QUJING",
      },
      {
        CITY: "QUSHAN",
      },
      {
        CITY: "QUZHOU",
      },
      {
        CITY: "RENHE",
      },
      {
        CITY: "RENHOU",
      },
      {
        CITY: "RENMIN",
      },
      {
        CITY: "RENQIU",
      },
      {
        CITY: "RENRONG",
      },
      {
        CITY: "RIZHAO",
      },
      {
        CITY: "RONGJIANG",
      },
      {
        CITY: "RONGJIANG",
      },
      {
        CITY: "RONGJIN",
      },
      {
        CITY: "RONGLE",
      },
      {
        CITY: "RONGSHAN",
      },
      {
        CITY: "RONGSHENG",
      },
      {
        CITY: "RONGXIAN",
      },
      {
        CITY: "RONGXING",
      },
      {
        CITY: "RUICHENG",
      },
      {
        CITY: "RUIFENG",
      },
      {
        CITY: "RUIHU",
      },
      {
        CITY: "RUILI",
      },
      {
        CITY: "RUITING",
      },
      {
        CITY: "RUIXING",
      },
      {
        CITY: "RUIYING",
      },
      {
        CITY: "RUJIA",
      },
      {
        CITY: "SAILING",
      },
      {
        CITY: "SANDU",
      },
      {
        CITY: "SANGUAN",
      },
      {
        CITY: "SANHE",
      },
      {
        CITY: "SANJIANG",
      },
      {
        CITY: "SANJIANGKOU",
      },
      {
        CITY: "SANJIAOCHENG",
      },
      {
        CITY: "SANJIU",
      },
      {
        CITY: "SANLIAN",
      },
      {
        CITY: "SANMAO",
      },
      {
        CITY: "SANMEN",
      },
      {
        CITY: "SANMENXIA",
      },
      {
        CITY: "SANMING",
      },
      {
        CITY: "SANPAILOU",
      },
      {
        CITY: "SANTAI",
      },
      {
        CITY: "SANTIAOLOU",
      },
      {
        CITY: "SANYA",
      },
      {
        CITY: "SANYI",
      },
      {
        CITY: "SANYOU",
      },
      {
        CITY: "SANYUANPU",
      },
      {
        CITY: "SANZHANG",
      },
      {
        CITY: "SHAMEN",
      },
      {
        CITY: "SHANCHENG",
      },
      {
        CITY: "SHANCHENG",
      },
      {
        CITY: "SHANGBAO",
      },
      {
        CITY: "SHANGBU",
      },
      {
        CITY: "SHANGBU",
      },
      {
        CITY: "SHANGDONG",
      },
      {
        CITY: "SHANGFANG",
      },
      {
        CITY: "SHANGHAI",
      },
      {
        CITY: "SHANGHU",
      },
      {
        CITY: "SHANGJIANG",
      },
      {
        CITY: "SHANGLUO",
      },
      {
        CITY: "SHANGMA",
      },
      {
        CITY: "SHANGNAN",
      },
      {
        CITY: "SHANGQIU",
      },
      {
        CITY: "SHANGRAO",
      },
      {
        CITY: "SHANGSHA",
      },
      {
        CITY: "SHANGSI",
      },
      {
        CITY: "SHANGTANG",
      },
      {
        CITY: "SHANGWANG",
      },
      {
        CITY: "SHANGWU",
      },
      {
        CITY: "SHANGXI",
      },
      {
        CITY: "SHANGYE",
      },
      {
        CITY: "SHANGYING",
      },
      {
        CITY: "SHANGZHANG",
      },
      {
        CITY: "SHANGZHI",
      },
      {
        CITY: "SHANGZHOU",
      },
      {
        CITY: "SHANHE",
      },
      {
        CITY: "SHANHU",
      },
      {
        CITY: "SHANRUI",
      },
      {
        CITY: "SHANSHUI",
      },
      {
        CITY: "SHANTOU",
      },
      {
        CITY: "SHANTOU",
      },
      {
        CITY: "SHANWEI",
      },
      {
        CITY: "SHANZHUANG",
      },
      {
        CITY: "SHAOGUAN",
      },
      {
        CITY: "SHAOGUANG",
      },
      {
        CITY: "SHAOWU",
      },
      {
        CITY: "SHAOXING",
      },
      {
        CITY: "SHASHAN",
      },
      {
        CITY: "SHASHI",
      },
      {
        CITY: "SHECHENG",
      },
      {
        CITY: "SHEJIAZHUANG",
      },
      {
        CITY: "SHEKOU",
      },
      {
        CITY: "SHENGDU",
      },
      {
        CITY: "SHENGFANG",
      },
      {
        CITY: "SHENGLI",
      },
      {
        CITY: "SHENGPING",
      },
      {
        CITY: "SHENGSHAN",
      },
      {
        CITY: "SHENJIAMEN",
      },
      {
        CITY: "SHENLONG",
      },
      {
        CITY: "SHENNAN",
      },
      {
        CITY: "SHENSHA",
      },
      {
        CITY: "SHENWAN",
      },
      {
        CITY: "SHENYAN",
      },
      {
        CITY: "SHENYANG",
      },
      {
        CITY: "SHENZHEN",
      },
      {
        CITY: "SHENZHOU",
      },
      {
        CITY: "SHIBEI",
      },
      {
        CITY: "SHIFAN",
      },
      {
        CITY: "SHIFANG",
      },
      {
        CITY: "SHIHEZI",
      },
      {
        CITY: "SHIJI",
      },
      {
        CITY: "SHIJIAZHUANG",
      },
      {
        CITY: "SHIJIAZHUANG",
      },
      {
        CITY: "SHIJING",
      },
      {
        CITY: "SHIJINGXIANG",
      },
      {
        CITY: "SHILING",
      },
      {
        CITY: "SHILOU",
      },
      {
        CITY: "SHIMA",
      },
      {
        CITY: "SHINIAN",
      },
      {
        CITY: "SHIPU",
      },
      {
        CITY: "SHIQI",
      },
      {
        CITY: "SHIQIAOPU",
      },
      {
        CITY: "SHISHI",
      },
      {
        CITY: "SHISHI",
      },
      {
        CITY: "SHIXIA",
      },
      {
        CITY: "SHIYAN",
      },
      {
        CITY: "SHIZHENG",
      },
      {
        CITY: "SHIZHONG",
      },
      {
        CITY: "SHIZHU",
      },
      {
        CITY: "SHOUCHANG",
      },
      {
        CITY: "SHOUCHUN",
      },
      {
        CITY: "SHOUGUANG",
      },
      {
        CITY: "SHOUYANG",
      },
      {
        CITY: "SHUANGCHENG",
      },
      {
        CITY: "SHUANGCHENG",
      },
      {
        CITY: "SHUANGLIN",
      },
      {
        CITY: "SHUANGLONG",
      },
      {
        CITY: "SHUANGQIAO",
      },
      {
        CITY: "SHUANGSHIPU",
      },
      {
        CITY: "SHUANGTANG",
      },
      {
        CITY: "SHUANGXI",
      },
      {
        CITY: "SHUANGYASHAN",
      },
      {
        CITY: "SHUGUANG",
      },
      {
        CITY: "SHUHONG",
      },
      {
        CITY: "SHUIGANG",
      },
      {
        CITY: "SHUIKU",
      },
      {
        CITY: "SHUITIAN",
      },
      {
        CITY: "SHUITOU",
      },
      {
        CITY: "SHUIWU",
      },
      {
        CITY: "SHULE",
      },
      {
        CITY: "SHULIANG",
      },
      {
        CITY: "SHUNDE",
      },
      {
        CITY: "SHUNSHUI",
      },
      {
        CITY: "SHUOZHOU",
      },
      {
        CITY: "SHUYI",
      },
      {
        CITY: "SICHUAN",
      },
      {
        CITY: "SIDE",
      },
      {
        CITY: "SIDUXU",
      },
      {
        CITY: "SIJIQING",
      },
      {
        CITY: "SILI",
      },
      {
        CITY: "SIMAO",
      },
      {
        CITY: "SIMCUN",
      },
      {
        CITY: "SIMEN",
      },
      {
        CITY: "SITING",
      },
      {
        CITY: "SIYANG",
      },
      {
        CITY: "SIZHOU",
      },
      {
        CITY: "SONGLING",
      },
      {
        CITY: "SONGYANG",
      },
      {
        CITY: "SONGYUAN",
      },
      {
        CITY: "SUIHUA",
      },
      {
        CITY: "SUINING",
      },
      {
        CITY: "SUIXI",
      },
      {
        CITY: "SUJI",
      },
      {
        CITY: "SUNDUN",
      },
      {
        CITY: "SUNGANG",
      },
      {
        CITY: "SUQIAN",
      },
      {
        CITY: "SUQIAN",
      },
      {
        CITY: "SUXI",
      },
      {
        CITY: "SUZHOU",
      },
      {
        CITY: "SUZHOU",
      },
      {
        CITY: "SUZHUANG",
      },
      {
        CITY: "TAICANG",
      },
      {
        CITY: "TAIHE",
      },
      {
        CITY: "TAIKANG",
      },
      {
        CITY: "TAILAI",
      },
      {
        CITY: "TAILI",
      },
      {
        CITY: "TAIMEN",
      },
      {
        CITY: "TAIPING",
      },
      {
        CITY: "TAIPING",
      },
      {
        CITY: "TAIPINGYAN",
      },
      {
        CITY: "TAISHAN",
      },
      {
        CITY: "TAISHAN",
      },
      {
        CITY: "TAIXING",
      },
      {
        CITY: "TAIYUAN",
      },
      {
        CITY: "TAIZHOU",
      },
      {
        CITY: "TANCHENG",
      },
      {
        CITY: "TANCHENG",
      },
      {
        CITY: "TANG XIAN",
      },
      {
        CITY: "TANGBIAN",
      },
      {
        CITY: "TANGCHANG",
      },
      {
        CITY: "TANGCHENG",
      },
      {
        CITY: "TANGDUKOU",
      },
      {
        CITY: "TANGTOU",
      },
      {
        CITY: "TANGXI",
      },
      {
        CITY: "TANGXIA",
      },
      {
        CITY: "TANGYUAN",
      },
      {
        CITY: "TANSHI",
      },
      {
        CITY: "TAOCHENG",
      },
      {
        CITY: "TAOHUA",
      },
      {
        CITY: "TAOSHAN",
      },
      {
        CITY: "TAOSHAN",
      },
      {
        CITY: "TAOXI",
      },
      {
        CITY: "TAOYUAN",
      },
      {
        CITY: "TAOZHUANG",
      },
      {
        CITY: "TARA",
      },
      {
        CITY: "TASHI",
      },
      {
        CITY: "TAXI",
      },
      {
        CITY: "TENGJIAO",
      },
      {
        CITY: "TENGZHOU",
      },
      {
        CITY: "TIANBAO",
      },
      {
        CITY: "TIANCHANG",
      },
      {
        CITY: "TIANCHANG",
      },
      {
        CITY: "TIANCHENG",
      },
      {
        CITY: "TIANCHI",
      },
      {
        CITY: "TIANDONG",
      },
      {
        CITY: "TIANFU",
      },
      {
        CITY: "TIANGANG",
      },
      {
        CITY: "TIANHAI",
      },
      {
        CITY: "TIANHONG",
      },
      {
        CITY: "TIANJIN",
      },
      {
        CITY: "TIANLE",
      },
      {
        CITY: "TIANLI",
      },
      {
        CITY: "TIANMA",
      },
      {
        CITY: "TIANNING",
      },
      {
        CITY: "TIANSHAN",
      },
      {
        CITY: "TIANSHI",
      },
      {
        CITY: "TIANSHUI",
      },
      {
        CITY: "TIANTAI CHENGGUANZHEN",
      },
      {
        CITY: "TIANYANG",
      },
      {
        CITY: "TIANYI",
      },
      {
        CITY: "TIANYUAN",
      },
      {
        CITY: "TIANZHEN",
      },
      {
        CITY: "TIANZHU",
      },
      {
        CITY: "TIELI",
      },
      {
        CITY: "TIELING",
      },
      {
        CITY: "TIESHAN",
      },
      {
        CITY: "TINGPANG",
      },
      {
        CITY: "TONGBAI",
      },
      {
        CITY: "TONGCHUAN",
      },
      {
        CITY: "TONGCUN",
      },
      {
        CITY: "TONGJI",
      },
      {
        CITY: "TONGLIAO",
      },
      {
        CITY: "TONGLING",
      },
      {
        CITY: "TONGLU",
      },
      {
        CITY: "TONGMIN",
      },
      {
        CITY: "TONGREN",
      },
      {
        CITY: "TONGWANG",
      },
      {
        CITY: "TONGXIANG",
      },
      {
        CITY: "TONGYU",
      },
      {
        CITY: "TONGYUAN",
      },
      {
        CITY: "TUMU",
      },
      {
        CITY: "TURPAN DIQU",
      },
      {
        CITY: "WANBAO",
      },
      {
        CITY: "WANGFU",
      },
      {
        CITY: "WANGLUO",
      },
      {
        CITY: "WANGSHANG",
      },
      {
        CITY: "WANGSU",
      },
      {
        CITY: "WANJIA",
      },
      {
        CITY: "WANNING",
      },
      {
        CITY: "WANQUAN",
      },
      {
        CITY: "WANSONG",
      },
      {
        CITY: "WANXI",
      },
      {
        CITY: "WEIBO",
      },
      {
        CITY: "WEIDUN",
      },
      {
        CITY: "WEIFANG",
      },
      {
        CITY: "WEIHAI",
      },
      {
        CITY: "WEINAN",
      },
      {
        CITY: "WEITANG",
      },
      {
        CITY: "WEITANG",
      },
      {
        CITY: "WEIYANG",
      },
      {
        CITY: "WENCHANG",
      },
      {
        CITY: "WENHOU",
      },
      {
        CITY: "WENLAN",
      },
      {
        CITY: "WENLIN",
      },
      {
        CITY: "WENLING",
      },
      {
        CITY: "WENQUAN",
      },
      {
        CITY: "WENSHAN",
      },
      {
        CITY: "WENSHANG",
      },
      {
        CITY: "WENXI",
      },
      {
        CITY: "WENXING",
      },
      {
        CITY: "WENZHOU",
      },
      {
        CITY: "WUHAI",
      },
      {
        CITY: "WUHAI",
      },
      {
        CITY: "WUHAN",
      },
      {
        CITY: "WUHOU",
      },
      {
        CITY: "WUHU",
      },
      {
        CITY: "WULING",
      },
      {
        CITY: "WUNING",
      },
      {
        CITY: "WUSHAN",
      },
      {
        CITY: "WUTAI",
      },
      {
        CITY: "WUTONG",
      },
      {
        CITY: "WUWEI",
      },
      {
        CITY: "WUXI",
      },
      {
        CITY: "WUXIA",
      },
      {
        CITY: "WUXING",
      },
      {
        CITY: "WUYANG",
      },
      {
        CITY: "WUYI",
      },
      {
        CITY: "WUYISHAN",
      },
      {
        CITY: "WUZHEN",
      },
      {
        CITY: "WUZHISHAN",
      },
      {
        CITY: "WUZHONG",
      },
      {
        CITY: "WUZHOU",
      },
      {
        CITY: "XIABANCHENG",
      },
      {
        CITY: "XIADIANMIAO",
      },
      {
        CITY: "XIAKOU",
      },
      {
        CITY: "XIAMEN",
      },
      {
        CITY: "XIANCHANG",
      },
      {
        CITY: "XIANDU",
      },
      {
        CITY: "XIANGCHENG",
      },
      {
        CITY: "XIANGFAN",
      },
      {
        CITY: "XIANGFENG",
      },
      {
        CITY: "XIANGJIANG",
      },
      {
        CITY: "XIANGNAN",
      },
      {
        CITY: "XIANGRU",
      },
      {
        CITY: "XIANGSHAN",
      },
      {
        CITY: "XIANGSHENG",
      },
      {
        CITY: "XIANGSI",
      },
      {
        CITY: "XIANGTAN",
      },
      {
        CITY: "XIANGTONG",
      },
      {
        CITY: "XIANGXI",
      },
      {
        CITY: "XIANGYI",
      },
      {
        CITY: "XIANGYUAN",
      },
      {
        CITY: "XIANJIANG",
      },
      {
        CITY: "XIANLIN",
      },
      {
        CITY: "XIANNING",
      },
      {
        CITY: "XIANQIAO",
      },
      {
        CITY: "XIANREN",
      },
      {
        CITY: "XIANRENQIAO",
      },
      {
        CITY: "XIANTAO",
      },
      {
        CITY: "XIANYANG",
      },
      {
        CITY: "XIANYOU",
      },
      {
        CITY: "XIAOGAN",
      },
      {
        CITY: "XIAOGANG",
      },
      {
        CITY: "XIAOHESHAN",
      },
      {
        CITY: "XIAOJIAHE",
      },
      {
        CITY: "XIAOJIANG",
      },
      {
        CITY: "XIAOMI",
      },
      {
        CITY: "XIAOQU",
      },
      {
        CITY: "XIAOSHAN",
      },
      {
        CITY: "XIAOSHUN",
      },
      {
        CITY: "XIAOSI",
      },
      {
        CITY: "XIAOXI",
      },
      {
        CITY: "XIAOZHENG",
      },
      {
        CITY: "XIAPU",
      },
      {
        CITY: "XIASHA",
      },
      {
        CITY: "XIASHI",
      },
      {
        CITY: "XIATANGYUNXIANG",
      },
      {
        CITY: "XIAYUAN",
      },
      {
        CITY: "XIAZHI",
      },
      {
        CITY: "XIAZHUANG",
      },
      {
        CITY: "XIBEI",
      },
      {
        CITY: "XIBU",
      },
      {
        CITY: "XICHANG",
      },
      {
        CITY: "XICHONG",
      },
      {
        CITY: "XIDIAN",
      },
      {
        CITY: "XIEDIAN",
      },
      {
        CITY: "XIEQIAO",
      },
      {
        CITY: "XIEZHOU",
      },
      {
        CITY: "XIGU",
      },
      {
        CITY: "XIGUAN",
      },
      {
        CITY: "XIHU",
      },
      {
        CITY: "XIJIAO",
      },
      {
        CITY: "XIJIAO",
      },
      {
        CITY: "XIJING",
      },
      {
        CITY: "XIKOU",
      },
      {
        CITY: "XILING",
      },
      {
        CITY: "XIMENG",
      },
      {
        CITY: "XINAO",
      },
      {
        CITY: "XINCAO",
      },
      {
        CITY: "XINCHANG",
      },
      {
        CITY: "XINCHEN",
      },
      {
        CITY: "XINCHENG",
      },
      {
        CITY: "XINDI",
      },
      {
        CITY: "XINDU",
      },
      {
        CITY: "XINGANG",
      },
      {
        CITY: "XINGCHANG",
      },
      {
        CITY: "XINGCHEN",
      },
      {
        CITY: "XINGCHENG",
      },
      {
        CITY: "XINGFENG",
      },
      {
        CITY: "XINGGUANG",
      },
      {
        CITY: "XINGHUA",
      },
      {
        CITY: "XINGQIAO",
      },
      {
        CITY: "XINGTAI",
      },
      {
        CITY: "XINGUANG",
      },
      {
        CITY: "XINGWANG",
      },
      {
        CITY: "XINGXIAN",
      },
      {
        CITY: "XINGXIANGCUN",
      },
      {
        CITY: "XINGXING",
      },
      {
        CITY: "XINGYE",
      },
      {
        CITY: "XINGYI",
      },
      {
        CITY: "XINGYUAN",
      },
      {
        CITY: "XINHUA",
      },
      {
        CITY: "XINHUA",
      },
      {
        CITY: "XINHUANG",
      },
      {
        CITY: "XINHUI",
      },
      {
        CITY: "XINING",
      },
      {
        CITY: "XINJIAN",
      },
      {
        CITY: "XINJIANG",
      },
      {
        CITY: "XINMIN",
      },
      {
        CITY: "XINNING",
      },
      {
        CITY: "XINQIAO",
      },
      {
        CITY: "XINRONG",
      },
      {
        CITY: "XINSHA",
      },
      {
        CITY: "XINSHIJIE",
      },
      {
        CITY: "XINTAI",
      },
      {
        CITY: "XINTANGBIAN",
      },
      {
        CITY: "XINTIAN",
      },
      {
        CITY: "XINXIANG",
      },
      {
        CITY: "XINXING",
      },
      {
        CITY: "XINYANG",
      },
      {
        CITY: "XINYI",
      },
      {
        CITY: "XINYOU",
      },
      {
        CITY: "XINZHA",
      },
      {
        CITY: "XINZHENG",
      },
      {
        CITY: "XINZHOU",
      },
      {
        CITY: "XIPING",
      },
      {
        CITY: "XIQU",
      },
      {
        CITY: "XISHAN",
      },
      {
        CITY: "XITANG",
      },
      {
        CITY: "XIUSHAN",
      },
      {
        CITY: "XIUSHUI",
      },
      {
        CITY: "XIUYING",
      },
      {
        CITY: "XIUYU",
      },
      {
        CITY: "XIXI",
      },
      {
        CITY: "XIYANG",
      },
      {
        CITY: "XIYUE",
      },
      {
        CITY: "XIZHANG",
      },
      {
        CITY: "XIZHOU",
      },
      {
        CITY: "XUANCHENG",
      },
      {
        CITY: "XUANWU",
      },
      {
        CITY: "XUANZHOU",
      },
      {
        CITY: "XUCHANG",
      },
      {
        CITY: "XUCHENG",
      },
      {
        CITY: "XUCUN",
      },
      {
        CITY: "XUEFENG",
      },
      {
        CITY: "XUEXI",
      },
      {
        CITY: "XUEXIAO",
      },
      {
        CITY: "XUEZHUANG",
      },
      {
        CITY: "XUHUI",
      },
      {
        CITY: "XUNXIAN",
      },
      {
        CITY: "XUNYI CHENGGUANZHEN",
      },
      {
        CITY: "XUSHAN",
      },
      {
        CITY: "XUZHOU",
      },
      {
        CITY: "YAHAO",
      },
      {
        CITY: "YAJIN",
      },
      {
        CITY: "YALAN",
      },
      {
        CITY: "YALONGWAN",
      },
      {
        CITY: "YANCHANG",
      },
      {
        CITY: "YANCHENG",
      },
      {
        CITY: "YANCHENG",
      },
      {
        CITY: "YANDANG",
      },
      {
        CITY: "YANDAO",
      },
      {
        CITY: "YANGCE",
      },
      {
        CITY: "YANGCUN",
      },
      {
        CITY: "YANGDA",
      },
      {
        CITY: "YANGFEN",
      },
      {
        CITY: "YANGGU",
      },
      {
        CITY: "YANGJIANG",
      },
      {
        CITY: "YANGLING",
      },
      {
        CITY: "YANGLOU",
      },
      {
        CITY: "YANGMIAO",
      },
      {
        CITY: "YANGPU",
      },
      {
        CITY: "YANGQITAN",
      },
      {
        CITY: "YANGQU",
      },
      {
        CITY: "YANGQUAN",
      },
      {
        CITY: "YANGSHAN",
      },
      {
        CITY: "YANGUAN",
      },
      {
        CITY: "YANGXUNQIAO",
      },
      {
        CITY: "YANGZHONG",
      },
      {
        CITY: "YANGZHOU",
      },
      {
        CITY: "YANGZHOU",
      },
      {
        CITY: "YANHAI",
      },
      {
        CITY: "YANJI",
      },
      {
        CITY: "YANJIANG",
      },
      {
        CITY: "YANJIAO",
      },
      {
        CITY: "YANJIAO",
      },
      {
        CITY: "YANJING",
      },
      {
        CITY: "YANMING",
      },
      {
        CITY: "YANSHI",
      },
      {
        CITY: "YANTA",
      },
      {
        CITY: "YANTA",
      },
      {
        CITY: "YANTAI",
      },
      {
        CITY: "YANTAN",
      },
      {
        CITY: "YAOCHENG",
      },
      {
        CITY: "YAOFENG",
      },
      {
        CITY: "YAOHUA",
      },
      {
        CITY: "YAOLUOPING",
      },
      {
        CITY: "YAOZHUANG",
      },
      {
        CITY: "YAYU",
      },
      {
        CITY: "YIBIN",
      },
      {
        CITY: "YICHANG",
      },
      {
        CITY: "YICHENG",
      },
      {
        CITY: "YICHENG",
      },
      {
        CITY: "YICHUN",
      },
      {
        CITY: "YIDONG",
      },
      {
        CITY: "YIFENG",
      },
      {
        CITY: "YIFU",
      },
      {
        CITY: "YIJIN",
      },
      {
        CITY: "YIJING",
      },
      {
        CITY: "YILI",
      },
      {
        CITY: "YINCHUAN",
      },
      {
        CITY: "YINDIAN",
      },
      {
        CITY: "YINFANG",
      },
      {
        CITY: "YINGCAI",
      },
      {
        CITY: "YINGCHUAN",
      },
      {
        CITY: "YINGDU",
      },
      {
        CITY: "YINGGEN",
      },
      {
        CITY: "YINGJIA",
      },
      {
        CITY: "YINGJIE",
      },
      {
        CITY: "YINGQIAN",
      },
      {
        CITY: "YINGTAI",
      },
      {
        CITY: "YINHAI",
      },
      {
        CITY: "YINING",
      },
      {
        CITY: "YINJIANG",
      },
      {
        CITY: "YINLIANG",
      },
      {
        CITY: "YINSHAN",
      },
      {
        CITY: "YINTIAN",
      },
      {
        CITY: "YINXIN",
      },
      {
        CITY: "YINZHOU",
      },
      {
        CITY: "YIQIAO",
      },
      {
        CITY: "YISA",
      },
      {
        CITY: "YISHAN",
      },
      {
        CITY: "YISHUI",
      },
      {
        CITY: "YITIAN",
      },
      {
        CITY: "YITING",
      },
      {
        CITY: "YIWU",
      },
      {
        CITY: "YIWU",
      },
      {
        CITY: "YIYANG",
      },
      {
        CITY: "YIZHENG",
      },
      {
        CITY: "YIZHONG",
      },
      {
        CITY: "YIZHOU",
      },
      {
        CITY: "YONGCHUAN",
      },
      {
        CITY: "YONGJIA",
      },
      {
        CITY: "YONGJIAWAN",
      },
      {
        CITY: "YONGJIN",
      },
      {
        CITY: "YONGJIU",
      },
      {
        CITY: "YONGKANG",
      },
      {
        CITY: "YONGNING",
      },
      {
        CITY: "YONGQIANG",
      },
      {
        CITY: "YONGXING",
      },
      {
        CITY: "YONGZHOU",
      },
      {
        CITY: "YOUCHEGANG",
      },
      {
        CITY: "YOUJIA",
      },
      {
        CITY: "YOUJIANG",
      },
      {
        CITY: "YOUSHI",
      },
      {
        CITY: "YOUYI",
      },
      {
        CITY: "YUANCHANG",
      },
      {
        CITY: "YUANHENG",
      },
      {
        CITY: "YUANLI",
      },
      {
        CITY: "YUANLING",
      },
      {
        CITY: "YUANPING",
      },
      {
        CITY: "YUANPU",
      },
      {
        CITY: "YUANQU",
      },
      {
        CITY: "YUANTONG",
      },
      {
        CITY: "YUANYANG",
      },
      {
        CITY: "YUCAI",
      },
      {
        CITY: "YUCHENG",
      },
      {
        CITY: "YUCHENG",
      },
      {
        CITY: "YUCHI",
      },
      {
        CITY: "YUECHENG",
      },
      {
        CITY: "YUEHU",
      },
      {
        CITY: "YUEHUA",
      },
      {
        CITY: "YUELE",
      },
      {
        CITY: "YUELIANGWAN",
      },
      {
        CITY: "YUEQING",
      },
      {
        CITY: "YUETAI",
      },
      {
        CITY: "YUHAI",
      },
      {
        CITY: "YUHAN",
      },
      {
        CITY: "YUHANG",
      },
      {
        CITY: "YUHONG",
      },
      {
        CITY: "YUHUAN",
      },
      {
        CITY: "YUHUAYUAN",
      },
      {
        CITY: "YUHUI",
      },
      {
        CITY: "YUJING",
      },
      {
        CITY: "YULIN",
      },
      {
        CITY: "YULONG",
      },
      {
        CITY: "YUNCHENG",
      },
      {
        CITY: "YUNFENG",
      },
      {
        CITY: "YUNFU",
      },
      {
        CITY: "YUNGANG",
      },
      {
        CITY: "YUNGCUN",
      },
      {
        CITY: "YUNLING",
      },
      {
        CITY: "YUNNAN",
      },
      {
        CITY: "YUNTING",
      },
      {
        CITY: "YUNYANG",
      },
      {
        CITY: "YUTING",
      },
      {
        CITY: "YUTOU",
      },
      {
        CITY: "YUXI",
      },
      {
        CITY: "YUXI",
      },
      {
        CITY: "YUXIANG",
      },
      {
        CITY: "YUYAO",
      },
      {
        CITY: "YUYUAN",
      },
      {
        CITY: "YUZHEN",
      },
      {
        CITY: "YUZHONG CHENGGUANZHEN",
      },
      {
        CITY: "YUZI",
      },
      {
        CITY: "ZAOYANG",
      },
      {
        CITY: "ZAOZHUANG",
      },
      {
        CITY: "ZHANCHENG",
      },
      {
        CITY: "ZHANGHE",
      },
      {
        CITY: "ZHANGHOU",
      },
      {
        CITY: "ZHANGJIAGANG",
      },
      {
        CITY: "ZHANGJIAGANG",
      },
      {
        CITY: "ZHANGJIAJIE",
      },
      {
        CITY: "ZHANGJIAKOU",
      },
      {
        CITY: "ZHANGJIANG",
      },
      {
        CITY: "ZHANGQI",
      },
      {
        CITY: "ZHANGQIU",
      },
      {
        CITY: "ZHANGSHAN",
      },
      {
        CITY: "ZHANGSHU",
      },
      {
        CITY: "ZHANGYE",
      },
      {
        CITY: "ZHANGZHAI",
      },
      {
        CITY: "ZHANGZHOU",
      },
      {
        CITY: "ZHANGZHUANG",
      },
      {
        CITY: "ZHANJIANG",
      },
      {
        CITY: "ZHANMAO",
      },
      {
        CITY: "ZHAOBAOSHAN",
      },
      {
        CITY: "ZHAODONG",
      },
      {
        CITY: "ZHAOQING",
      },
      {
        CITY: "ZHAOTONG",
      },
      {
        CITY: "ZHAOYU",
      },
      {
        CITY: "ZHAOZHOU",
      },
      {
        CITY: "ZHAPU",
      },
      {
        CITY: "ZHEDONG",
      },
      {
        CITY: "ZHENGDA",
      },
      {
        CITY: "ZHENGDI",
      },
      {
        CITY: "ZHENGDING",
      },
      {
        CITY: "ZHENGHAI",
      },
      {
        CITY: "ZHENGJIANG",
      },
      {
        CITY: "ZHENGYUAN",
      },
      {
        CITY: "ZHENGZHOU",
      },
      {
        CITY: "ZHENHUA",
      },
      {
        CITY: "ZHENJIANG",
      },
      {
        CITY: "ZHENXI",
      },
      {
        CITY: "ZHENXING",
      },
      {
        CITY: "ZHENZHOU",
      },
      {
        CITY: "ZHENZHUQUAN",
      },
      {
        CITY: "ZHESHANG",
      },
      {
        CITY: "ZHICHENG",
      },
      {
        CITY: "ZHIJIANG",
      },
      {
        CITY: "ZHILI",
      },
      {
        CITY: "ZHIMA",
      },
      {
        CITY: "ZHIXING",
      },
      {
        CITY: "ZHIYE",
      },
      {
        CITY: "ZHIYUAN",
      },
      {
        CITY: "ZHIZHONG",
      },
      {
        CITY: "ZHONGDIAN",
      },
      {
        CITY: "ZHONGFANG",
      },
      {
        CITY: "ZHONGFU",
      },
      {
        CITY: "ZHONGGONGMIAO",
      },
      {
        CITY: "ZHONGJI",
      },
      {
        CITY: "ZHONGJIAN",
      },
      {
        CITY: "ZHONGLIN",
      },
      {
        CITY: "ZHONGNAN",
      },
      {
        CITY: "ZHONGQIAO",
      },
      {
        CITY: "ZHONGSHAN",
      },
      {
        CITY: "ZHONGSHAN",
      },
      {
        CITY: "ZHONGTANG",
      },
      {
        CITY: "ZHONGTIAN",
      },
      {
        CITY: "ZHONGTU",
      },
      {
        CITY: "ZHONGWEI",
      },
      {
        CITY: "ZHONGXIANG",
      },
      {
        CITY: "ZHONGXIANG",
      },
      {
        CITY: "ZHONGXIN",
      },
      {
        CITY: "ZHONGXING",
      },
      {
        CITY: "ZHONGYANG",
      },
      {
        CITY: "ZHONGYICUN",
      },
      {
        CITY: "ZHONGYIN",
      },
      {
        CITY: "ZHONGYUAN",
      },
      {
        CITY: "ZHONGZHOU",
      },
      {
        CITY: "ZHONGZHOU",
      },
      {
        CITY: "ZHOUKOU",
      },
      {
        CITY: "ZHOUSHAN",
      },
      {
        CITY: "ZHOUSHAN",
      },
      {
        CITY: "ZHOUWANGMIAO",
      },
      {
        CITY: "ZHOUXIANG",
      },
      {
        CITY: "ZHUHAI",
      },
      {
        CITY: "ZHUJI",
      },
      {
        CITY: "ZHUJIAJIAN",
      },
      {
        CITY: "ZHUJIAJIAO",
      },
      {
        CITY: "ZHULIN",
      },
      {
        CITY: "ZHUMADIAN",
      },
      {
        CITY: "ZHUOZHOU",
      },
      {
        CITY: "ZHUXI CHENGGUANZHEN",
      },
      {
        CITY: "ZHUXIANG",
      },
      {
        CITY: "ZHUYUAN",
      },
      {
        CITY: "ZIBO",
      },
      {
        CITY: "ZICHENG",
      },
      {
        CITY: "ZIGONG",
      },
      {
        CITY: "ZIJIN",
      },
      {
        CITY: "ZIYANG",
      },
      {
        CITY: "ZIYU",
      },
      {
        CITY: "ZONGXOI",
      },
      {
        CITY: "ZOUCHENG",
      },
      {
        CITY: "ZUNYI",
      },
      {
        CITY: "ZUXI",
      },
    ],
  },
  {
    COUNTRY: "CONGO",
    CODE: "CG",
    CITIES: [
      {
        CITY: "BANANA",
      },
      {
        CITY: "GOMA",
      },
      {
        CITY: "KINSHASA",
      },
      {
        CITY: "LIKASI",
      },
      {
        CITY: "LUBUMBASHI",
      },
    ],
  },
  {
    COUNTRY: "CROATIA",
    CODE: "HR",
    CITIES: [
      {
        CITY: "ANTUNOVAC",
      },
      {
        CITY: "BASKA",
      },
      {
        CITY: "BASKA VODA",
      },
      {
        CITY: "BEDEKOVCINA",
      },
      {
        CITY: "BESTOVJE",
      },
      {
        CITY: "BETINA",
      },
      {
        CITY: "BIBINJE",
      },
      {
        CITY: "BIZOVAC",
      },
      {
        CITY: "BJELOVAR",
      },
      {
        CITY: "BRACEVCI",
      },
      {
        CITY: "BRDOVEC",
      },
      {
        CITY: "BREGANA",
      },
      {
        CITY: "BRELA",
      },
      {
        CITY: "BRSADIN",
      },
      {
        CITY: "BUJE",
      },
      {
        CITY: "CABUNA",
      },
      {
        CITY: "CAVTAT",
      },
      {
        CITY: "CEPIN",
      },
      {
        CITY: "CESTICA",
      },
      {
        CITY: "CORE",
      },
      {
        CITY: "CRIKVENICA",
      },
      {
        CITY: "DALJ",
      },
      {
        CITY: "DARDA",
      },
      {
        CITY: "DELNICE",
      },
      {
        CITY: "DESINIC",
      },
      {
        CITY: "DONJA PUSCA",
      },
      {
        CITY: "DONJA ZELINA",
      },
      {
        CITY: "DONJI KRALJEVEC",
      },
      {
        CITY: "DUBROVNIK",
      },
      {
        CITY: "DUGO SELO",
      },
      {
        CITY: "FAZANA",
      },
      {
        CITY: "FUZINE",
      },
      {
        CITY: "GALGOVO",
      },
      {
        CITY: "GLINA",
      },
      {
        CITY: "GORNJA LOMNICA",
      },
      {
        CITY: "GORNJI KRIZ",
      },
      {
        CITY: "GORNJI STUPNIK",
      },
      {
        CITY: "HRELJIN",
      },
      {
        CITY: "ICICI",
      },
      {
        CITY: "IVANEC",
      },
      {
        CITY: "IVANIC-GRAD",
      },
      {
        CITY: "KARLOVAC",
      },
      {
        CITY: "KAROJBA",
      },
      {
        CITY: "KASTEL GOMILICA",
      },
      {
        CITY: "KASTEL LUKSIC",
      },
      {
        CITY: "KASTEL SUCURAC",
      },
      {
        CITY: "KOPRIVNICA",
      },
      {
        CITY: "KOSTRENA",
      },
      {
        CITY: "KRALJEVEC NA SUTLI",
      },
      {
        CITY: "KRAPINA",
      },
      {
        CITY: "KRIZ",
      },
      {
        CITY: "KRK",
      },
      {
        CITY: "KRSAN",
      },
      {
        CITY: "KUTJEVO",
      },
      {
        CITY: "LABIN",
      },
      {
        CITY: "LAPAD",
      },
      {
        CITY: "LEKENIK",
      },
      {
        CITY: "LEPOGLAVA",
      },
      {
        CITY: "LOVRAN",
      },
      {
        CITY: "LUKAVEC",
      },
      {
        CITY: "LUPOGLAV",
      },
      {
        CITY: "LUPOGLAV",
      },
      {
        CITY: "MAKARSKA",
      },
      {
        CITY: "MATULJI",
      },
      {
        CITY: "MEDULIN",
      },
      {
        CITY: "MLINI",
      },
      {
        CITY: "MURSKO SREDISCE",
      },
      {
        CITY: "NEDELISCE",
      },
      {
        CITY: "NOVA GRADISKA",
      },
      {
        CITY: "NOVI MAROF",
      },
      {
        CITY: "NOVI VINODOLSKI",
      },
      {
        CITY: "NOVO CICE",
      },
      {
        CITY: "NUSTAR",
      },
      {
        CITY: "OKRUG GORNJI",
      },
      {
        CITY: "OPATIJA",
      },
      {
        CITY: "ORAHOVICA",
      },
      {
        CITY: "OREBIC",
      },
      {
        CITY: "ORLE",
      },
      {
        CITY: "OROSLAVJE",
      },
      {
        CITY: "OSIJEK",
      },
      {
        CITY: "PAKRAC",
      },
      {
        CITY: "PAZIN",
      },
      {
        CITY: "PETRCANE",
      },
      {
        CITY: "PETRINJA",
      },
      {
        CITY: "PISKOREVCI",
      },
      {
        CITY: "PODASTRANA",
      },
      {
        CITY: "PODGAJCI POSAVSKI",
      },
      {
        CITY: "POPOVACA",
      },
      {
        CITY: "POSTIRA",
      },
      {
        CITY: "PREGRADA",
      },
      {
        CITY: "PRELOG",
      },
      {
        CITY: "PRIMOSTEN",
      },
      {
        CITY: "PRIVLAKA",
      },
      {
        CITY: "PULA",
      },
      {
        CITY: "RAB",
      },
      {
        CITY: "RAKITJE",
      },
      {
        CITY: "RIJEKA",
      },
      {
        CITY: "RIJEKA",
      },
      {
        CITY: "ROVINJ",
      },
      {
        CITY: "SAMOBOR",
      },
      {
        CITY: "SAPJANE",
      },
      {
        CITY: "SENKOVEC",
      },
      {
        CITY: "SESVETE",
      },
      {
        CITY: "SESVETSKI KRALJEVEC",
      },
      {
        CITY: "SICE",
      },
      {
        CITY: "SILAS",
      },
      {
        CITY: "SIMUNI",
      },
      {
        CITY: "SINJ",
      },
      {
        CITY: "SISAK",
      },
      {
        CITY: "SLATINA",
      },
      {
        CITY: "SLAVONSKI BROD",
      },
      {
        CITY: "SOKOLOVAC",
      },
      {
        CITY: "SOLIN",
      },
      {
        CITY: "SPLIT",
      },
      {
        CITY: "SPLITSKA",
      },
      {
        CITY: "STRMEC",
      },
      {
        CITY: "STRMEC SAMOBORSKI",
      },
      {
        CITY: "SVETI IVAN ZELINA",
      },
      {
        CITY: "TAR",
      },
      {
        CITY: "TORCEC",
      },
      {
        CITY: "TROGIR",
      },
      {
        CITY: "TUZNO",
      },
      {
        CITY: "UMAG",
      },
      {
        CITY: "VARAZDINSKE TOPLICE",
      },
      {
        CITY: "VARDARAC",
      },
      {
        CITY: "VELIKA GORICA",
      },
      {
        CITY: "VETOVO",
      },
      {
        CITY: "VINICA",
      },
      {
        CITY: "VINKOVCI",
      },
      {
        CITY: "VIRJE",
      },
      {
        CITY: "VIROVITICA",
      },
      {
        CITY: "VISKOVCI",
      },
      {
        CITY: "VOJNIC",
      },
      {
        CITY: "VRATA",
      },
      {
        CITY: "VRATISINEC",
      },
      {
        CITY: "VRBNIK",
      },
      {
        CITY: "VRBOVEC",
      },
      {
        CITY: "VUKOVAR",
      },
      {
        CITY: "ZABOK",
      },
      {
        CITY: "ZADAR",
      },
      {
        CITY: "ZAGREB",
      },
      {
        CITY: "ZAPRESIC",
      },
      {
        CITY: "ZLATAR",
      },
    ],
  },
  {
    COUNTRY: "EGYPT",
    CODE: "EG",
    CITIES: [
      {
        CITY: "ABU HAMMAD",
      },
      {
        CITY: "AL MAHALLAH AL KUBRA",
      },
      {
        CITY: "AL MANSURAH",
      },
      {
        CITY: "AL MARJ",
      },
      {
        CITY: "ALEXANDRIA",
      },
      {
        CITY: "ALMAZAH",
      },
      {
        CITY: "AR RAWDAH",
      },
      {
        CITY: "ASSIUT",
      },
      {
        CITY: "AZ ZAMALIK",
      },
      {
        CITY: "BADR",
      },
      {
        CITY: "BANHA",
      },
      {
        CITY: "BANI SUWAYF",
      },
      {
        CITY: "CAIRO",
      },
      {
        CITY: "DAMIETTA",
      },
      {
        CITY: "FARASKUR",
      },
      {
        CITY: "FLAMINJ",
      },
      {
        CITY: "GIZA",
      },
      {
        CITY: "HELIOPOLIS",
      },
      {
        CITY: "HELWAN",
      },
      {
        CITY: "HURGHADA",
      },
      {
        CITY: "ISMAILIA",
      },
      {
        CITY: "KAFR ASH SHAYKH",
      },
      {
        CITY: "LUXOR",
      },
      {
        CITY: "MADINAT AN NASR",
      },
      {
        CITY: "MADINAT AS SADIS MIN UKTUBAR",
      },
      {
        CITY: "MINYA",
      },
      {
        CITY: "NASR",
      },
      {
        CITY: "NEW CAIRO",
      },
      {
        CITY: "PORT SAID",
      },
      {
        CITY: "RAFAH",
      },
      {
        CITY: "RAMSIS",
      },
      {
        CITY: "SADAT",
      },
      {
        CITY: "SHIRBIN",
      },
      {
        CITY: "SHUBRA",
      },
      {
        CITY: "SOHAG",
      },
      {
        CITY: "SUEZ",
      },
      {
        CITY: "TANTA",
      },
      {
        CITY: "TOUKH",
      },
      {
        CITY: "ZAGAZIG",
      },
    ],
  },
  {
    COUNTRY: "COLOMBIA",
    CODE: "CO",
    CITIES: [
      {
        CITY: "ACACIAS",
      },
      {
        CITY: "ACEVEDO",
      },
      {
        CITY: "AGUACHICA",
      },
      {
        CITY: "ANTIOQUIA",
      },
      {
        CITY: "ARAUCA",
      },
      {
        CITY: "ARMENIA",
      },
      {
        CITY: "ATLANTICO",
      },
      {
        CITY: "BARRANCABERMEJA",
      },
      {
        CITY: "BARRANQUILLA",
      },
      {
        CITY: "BELLO",
      },
      {
        CITY: "BERMUDEZ",
      },
      {
        CITY: "BOYACA",
      },
      {
        CITY: "BUCARAMANGA",
      },
      {
        CITY: "BUENAVENTURA",
      },
      {
        CITY: "BUGA",
      },
      {
        CITY: "CAJICA",
      },
      {
        CITY: "CALAMAR",
      },
      {
        CITY: "CALDAS",
      },
      {
        CITY: "CANDELARIA",
      },
      {
        CITY: "CARTAGENA",
      },
      {
        CITY: "CARTAGO",
      },
      {
        CITY: "CAUCA",
      },
      {
        CITY: "CHIA",
      },
      {
        CITY: "CHIQUINQUIRA",
      },
      {
        CITY: "CHOCHO",
      },
      {
        CITY: "COLUMBIA",
      },
      {
        CITY: "COROZAL",
      },
      {
        CITY: "COTA",
      },
      {
        CITY: "CUMARIBO",
      },
      {
        CITY: "CUNDINAMARCA",
      },
      {
        CITY: "DOSQUEBRADAS",
      },
      {
        CITY: "DUITAMA",
      },
      {
        CITY: "EL COLEGIO",
      },
      {
        CITY: "ENGATIVA",
      },
      {
        CITY: "ENVIGADO",
      },
      {
        CITY: "ESPINAL",
      },
      {
        CITY: "FLORENCIA",
      },
      {
        CITY: "FLORIDABLANCA",
      },
      {
        CITY: "FONSECA",
      },
      {
        CITY: "FUNDACION",
      },
      {
        CITY: "FUSAGASUGA",
      },
      {
        CITY: "GALAPA",
      },
      {
        CITY: "GINEBRA",
      },
      {
        CITY: "GIRON",
      },
      {
        CITY: "GUARNE",
      },
      {
        CITY: "IBAGUE",
      },
      {
        CITY: "INIRIDA",
      },
      {
        CITY: "IPIALES",
      },
      {
        CITY: "ITAGUI",
      },
      {
        CITY: "LA CEJA",
      },
      {
        CITY: "LA ESTRELLA",
      },
      {
        CITY: "LA MESA",
      },
      {
        CITY: "LA UNION",
      },
      {
        CITY: "LOS PATIOS",
      },
      {
        CITY: "MADRID",
      },
      {
        CITY: "MAGDALENA",
      },
      {
        CITY: "MANIZALES",
      },
      {
        CITY: "MIAMI",
      },
      {
        CITY: "MITU",
      },
      {
        CITY: "MONTENEGRO",
      },
      {
        CITY: "MOSQUERA",
      },
      {
        CITY: "MUNICIPIO DE COPACABANA",
      },
      {
        CITY: "NEIVA",
      },
      {
        CITY: "OBANDO",
      },
      {
        CITY: "PALMIRA",
      },
      {
        CITY: "PAMPLONA",
      },
      {
        CITY: "PASTO",
      },
      {
        CITY: "PEREIRA",
      },
      {
        CITY: "PIEDECUESTA",
      },
      {
        CITY: "PITALITO",
      },
      {
        CITY: "PLANADAS",
      },
      {
        CITY: "POPAYAN",
      },
      {
        CITY: "PUERTA ROJA",
      },
      {
        CITY: "PUERTO TEJADA",
      },
      {
        CITY: "PURIFICACION",
      },
      {
        CITY: "RIOHACHA",
      },
      {
        CITY: "RIONEGRO",
      },
      {
        CITY: "RISARALDA",
      },
      {
        CITY: "SABANA DE TORRES",
      },
      {
        CITY: "SABANETA",
      },
      {
        CITY: "SACHICA",
      },
      {
        CITY: "SAN CLEMENTE",
      },
      {
        CITY: "SAN DIEGO",
      },
      {
        CITY: "SAN GIL",
      },
      {
        CITY: "SAN MARTIN",
      },
      {
        CITY: "SANTA MARTA",
      },
      {
        CITY: "SANTA ROSA DE CABAL",
      },
      {
        CITY: "SANTA ROSA DEL SUR",
      },
      {
        CITY: "SANTIAGO DE CALI",
      },
      {
        CITY: "SILVANIA",
      },
      {
        CITY: "SINCELEJO",
      },
      {
        CITY: "SOACHA",
      },
      {
        CITY: "SOGAMOSO",
      },
      {
        CITY: "SOLEDAD",
      },
      {
        CITY: "SOPO",
      },
      {
        CITY: "TARAPACA",
      },
      {
        CITY: "TAURAMENA",
      },
      {
        CITY: "TENJO",
      },
      {
        CITY: "TOCANCIPA",
      },
      {
        CITY: "TUNJA",
      },
      {
        CITY: "TURBACO",
      },
      {
        CITY: "UBAQUE",
      },
      {
        CITY: "URUMITA",
      },
      {
        CITY: "VALLEDUPAR",
      },
      {
        CITY: "VELEZ",
      },
      {
        CITY: "VILLA DEL ROSARIO",
      },
      {
        CITY: "VILLAMARIA",
      },
      {
        CITY: "VILLAVICENCIO",
      },
      {
        CITY: "YOPAL",
      },
      {
        CITY: "YOTOCO",
      },
      {
        CITY: "YUMBO",
      },
    ],
  },
  {
    COUNTRY: "DOMINICAN REPUBLIC",
    CODE: "DO",
    CITIES: [
      {
        CITY: "ARENAZO",
      },
      {
        CITY: "BAVARO",
      },
      {
        CITY: "BOCA CHICA",
      },
      {
        CITY: "CABARETE",
      },
      {
        CITY: "COTUI",
      },
      {
        CITY: "DOMINICA",
      },
      {
        CITY: "GUARICANO",
      },
      {
        CITY: "HATO MAYOR DEL REY",
      },
      {
        CITY: "JIMANI",
      },
      {
        CITY: "LA ROMANA",
      },
      {
        CITY: "LOS ALCARRIZOS",
      },
      {
        CITY: "LOS PRADOS",
      },
      {
        CITY: "MOCA",
      },
      {
        CITY: "PEDERNALES",
      },
      {
        CITY: "PUERTO PLATA",
      },
      {
        CITY: "PUNTA CANA",
      },
      {
        CITY: "SABANETA",
      },
      {
        CITY: "SAN CRISTOBAL",
      },
      {
        CITY: "SAN FERNANDO DE MONTE CRISTI",
      },
      {
        CITY: "SAN JOSE DE OCOA",
      },
      {
        CITY: "SANTA CRUZ DE BARAHONA",
      },
      {
        CITY: "SANTIAGO DE LOS CABALLEROS",
      },
      {
        CITY: "SANTO DOMINGO",
      },
    ],
  },
  {
    COUNTRY: "FAROE ISLANDS",
    CODE: "FO",
    CITIES: [
      {
        CITY: "ARGIR",
      },
      {
        CITY: "GLYVRAR",
      },
      {
        CITY: "HVALBA",
      },
      {
        CITY: "INNAN GLYVUR",
      },
      {
        CITY: "LEIRVIK",
      },
      {
        CITY: "SALTANGARA",
      },
      {
        CITY: "SIGNABOUR",
      },
      {
        CITY: "STRENDUR",
      },
    ],
  },
  {
    COUNTRY: "CHILE",
    CODE: "CL",
    CITIES: [
      {
        CITY: "ALGARROBO",
      },
      {
        CITY: "ANGOL",
      },
      {
        CITY: "ANTOFAGASTA",
      },
      {
        CITY: "APOQUINDO",
      },
      {
        CITY: "ARAUCO",
      },
      {
        CITY: "ARICA",
      },
      {
        CITY: "BUIN",
      },
      {
        CITY: "BULNES",
      },
      {
        CITY: "CALAMA",
      },
      {
        CITY: "CALDERA",
      },
      {
        CITY: "CASTRO",
      },
      {
        CITY: "CATEMU",
      },
      {
        CITY: "CENTRO",
      },
      {
        CITY: "CERRILLOS DE TAMAYA",
      },
      {
        CITY: "CHICUREO ABAJO",
      },
      {
        CITY: "CHIGUAYANTE",
      },
      {
        CITY: "CHILLAN",
      },
      {
        CITY: "CHILLAN VIEJO",
      },
      {
        CITY: "CHIMBARONGO",
      },
      {
        CITY: "CHONCHI",
      },
      {
        CITY: "CHUQUICAMATA",
      },
      {
        CITY: "COIHAIQUE",
      },
      {
        CITY: "COLINA",
      },
      {
        CITY: "COLLIPULLI",
      },
      {
        CITY: "CONCON",
      },
      {
        CITY: "CONSTITUCION",
      },
      {
        CITY: "COQUIMBO",
      },
      {
        CITY: "CORONEL",
      },
      {
        CITY: "CUNCO",
      },
      {
        CITY: "CURACAVI",
      },
      {
        CITY: "DALCAHUE",
      },
      {
        CITY: "EL ARCO",
      },
      {
        CITY: "EL BOSQUE",
      },
      {
        CITY: "EL MONTE",
      },
      {
        CITY: "EL QUISCO",
      },
      {
        CITY: "EL SALTO",
      },
      {
        CITY: "EL SALVADOR",
      },
      {
        CITY: "EL VALLE",
      },
      {
        CITY: "FRESIA",
      },
      {
        CITY: "GALVARINO",
      },
      {
        CITY: "GRANEROS",
      },
      {
        CITY: "HANGA ROA",
      },
      {
        CITY: "IQUIQUE",
      },
      {
        CITY: "LA CALERA",
      },
      {
        CITY: "LA CISTERNA",
      },
      {
        CITY: "LA GRANJA",
      },
      {
        CITY: "LA LAJA",
      },
      {
        CITY: "LA LIGUA",
      },
      {
        CITY: "LA REINA",
      },
      {
        CITY: "LA SERENA",
      },
      {
        CITY: "LA UNION",
      },
      {
        CITY: "LAMPA",
      },
      {
        CITY: "LAS CONDES",
      },
      {
        CITY: "LAUTARO",
      },
      {
        CITY: "LLANQUIHUE",
      },
      {
        CITY: "LO BARNECHEA",
      },
      {
        CITY: "LOLOL",
      },
      {
        CITY: "LOS ANDES",
      },
      {
        CITY: "LOS ANGELES",
      },
      {
        CITY: "MACHALI",
      },
      {
        CITY: "MACUL",
      },
      {
        CITY: "MAIPU",
      },
      {
        CITY: "MAQUINA ATACAMA",
      },
      {
        CITY: "MAULE",
      },
      {
        CITY: "MEJILLONES",
      },
      {
        CITY: "MELIPILLA",
      },
      {
        CITY: "MIRAFLORES",
      },
      {
        CITY: "MOLINA",
      },
      {
        CITY: "MONTE AGUILA",
      },
      {
        CITY: "NACIMIENTO",
      },
      {
        CITY: "NOGALES",
      },
      {
        CITY: "NUNOA",
      },
      {
        CITY: "OLMUE",
      },
      {
        CITY: "OSORNO",
      },
      {
        CITY: "OVALLE",
      },
      {
        CITY: "PAILLACO",
      },
      {
        CITY: "PENABLANCA",
      },
      {
        CITY: "PENAFLOR",
      },
      {
        CITY: "PORT MONTT",
      },
      {
        CITY: "PROVIDENCIA",
      },
      {
        CITY: "PUCHUNCAVI",
      },
      {
        CITY: "PUCON",
      },
      {
        CITY: "PUENTE ALTO",
      },
      {
        CITY: "PUERTO AISEN",
      },
      {
        CITY: "PUERTO NATALES",
      },
      {
        CITY: "PUERTO VARAS",
      },
      {
        CITY: "PUNTA ARENAS",
      },
      {
        CITY: "PUREN",
      },
      {
        CITY: "PURRANQUE",
      },
      {
        CITY: "QUELLON",
      },
      {
        CITY: "QUEMCHI",
      },
      {
        CITY: "QUILICURA",
      },
      {
        CITY: "QUILLOTA",
      },
      {
        CITY: "RANCAGUA",
      },
      {
        CITY: "RECOLETA",
      },
      {
        CITY: "RENACA",
      },
      {
        CITY: "RENCA",
      },
      {
        CITY: "RENGO",
      },
      {
        CITY: "RINCONADA",
      },
      {
        CITY: "RIO BUENO",
      },
      {
        CITY: "SAN ANTONIO",
      },
      {
        CITY: "SAN BERNARDO",
      },
      {
        CITY: "SAN CARLOS",
      },
      {
        CITY: "SAN FELIPE",
      },
      {
        CITY: "SAN FERNANDO",
      },
      {
        CITY: "SAN JAVIER",
      },
      {
        CITY: "SAN MARTIN",
      },
      {
        CITY: "SAN PEDRO",
      },
      {
        CITY: "SANTA BARBARA",
      },
      {
        CITY: "SANTIAGO",
      },
      {
        CITY: "TALAGANTE",
      },
      {
        CITY: "TALCA",
      },
      {
        CITY: "TALCAHUANO",
      },
      {
        CITY: "TARAPACA",
      },
      {
        CITY: "TEMUCO",
      },
      {
        CITY: "TOME",
      },
      {
        CITY: "VALDIVIA",
      },
      {
        CITY: "VALLENAR",
      },
      {
        CITY: "VILLA ALEMANA",
      },
      {
        CITY: "VILLARRICA",
      },
      {
        CITY: "VITACURA",
      },
    ],
  },
  {
    COUNTRY: "EL SALVADOR",
    CODE: "SV",
    CITIES: [
      {
        CITY: "AHUACHAPAN",
      },
      {
        CITY: "ANTIGUO CUSCATLAN",
      },
      {
        CITY: "APANECA",
      },
      {
        CITY: "APOPA",
      },
      {
        CITY: "AYUTUXTEPEQUE",
      },
      {
        CITY: "BOTONCILLAL EL BOTONCILLO",
      },
      {
        CITY: "COATEPEQUE",
      },
      {
        CITY: "COLON",
      },
      {
        CITY: "COLONIA ESCALON",
      },
      {
        CITY: "CUSCATANCINGO",
      },
      {
        CITY: "DELGADO",
      },
      {
        CITY: "GIGANTE",
      },
      {
        CITY: "GUAZAPA",
      },
      {
        CITY: "ILOPANGO",
      },
      {
        CITY: "LA LIBERTAD",
      },
      {
        CITY: "LOURDES",
      },
      {
        CITY: "MEJICANOS",
      },
      {
        CITY: "NUEVO CUSCATLAN",
      },
      {
        CITY: "OZATLAN",
      },
      {
        CITY: "SAN JOSE VILLANUEVA",
      },
      {
        CITY: "SAN MARCOS",
      },
      {
        CITY: "SAN MIGUEL",
      },
      {
        CITY: "SAN SALVADOR",
      },
      {
        CITY: "SANTA ANA",
      },
      {
        CITY: "SANTA TECLA",
      },
      {
        CITY: "SANTIAGO TEXACUANGOS",
      },
      {
        CITY: "SONSONATE",
      },
      {
        CITY: "SOYAPANGO",
      },
      {
        CITY: "USULUTAN",
      },
      {
        CITY: "ZARAGOZA",
      },
    ],
  },
  {
    COUNTRY: "ECUADOR",
    CODE: "EC",
    CITIES: [
      {
        CITY: "AMBATO",
      },
      {
        CITY: "ATACAMES",
      },
      {
        CITY: "ATUNTAQUI",
      },
      {
        CITY: "BAHIA DE CARAQUEZ",
      },
      {
        CITY: "BANOS",
      },
      {
        CITY: "CALDERON",
      },
      {
        CITY: "CAYAMBE",
      },
      {
        CITY: "CUENCA",
      },
      {
        CITY: "DAULE",
      },
      {
        CITY: "EL CARMEN",
      },
      {
        CITY: "EL NARANJAL",
      },
      {
        CITY: "ESMERALDAS",
      },
      {
        CITY: "FLORIDA",
      },
      {
        CITY: "GENERAL LEONIDAS PLAZA GUTIERREZ",
      },
      {
        CITY: "GUABO",
      },
      {
        CITY: "GUALACEO",
      },
      {
        CITY: "GUAMOTE",
      },
      {
        CITY: "GUAYAQUIL",
      },
      {
        CITY: "GUAYAS",
      },
      {
        CITY: "GUAYLLABAMBA",
      },
      {
        CITY: "HACIENDA BOLIVIA",
      },
      {
        CITY: "HACIENDA DURAN",
      },
      {
        CITY: "HACIENDA IBARRA",
      },
      {
        CITY: "HACIENDA SANTA CATALINA",
      },
      {
        CITY: "HUAQUILLAS",
      },
      {
        CITY: "IBARRA",
      },
      {
        CITY: "JIPIJAPA",
      },
      {
        CITY: "LA ESPERANZA",
      },
      {
        CITY: "LA LIBERTAD",
      },
      {
        CITY: "LA TRONCAL",
      },
      {
        CITY: "LATACUNGA",
      },
      {
        CITY: "LOJA",
      },
      {
        CITY: "LORETO",
      },
      {
        CITY: "MACARA",
      },
      {
        CITY: "MACAS",
      },
      {
        CITY: "MACHACHI",
      },
      {
        CITY: "MACHALA",
      },
      {
        CITY: "MANTA",
      },
      {
        CITY: "MILAGRO",
      },
      {
        CITY: "MONTECRISTI",
      },
      {
        CITY: "NABON",
      },
      {
        CITY: "OLMEDO",
      },
      {
        CITY: "OTAVALO",
      },
      {
        CITY: "PACCHA",
      },
      {
        CITY: "PASAJE",
      },
      {
        CITY: "PELILEO",
      },
      {
        CITY: "PICHINCHA",
      },
      {
        CITY: "PLAYAS",
      },
      {
        CITY: "PORTOVIEJO",
      },
      {
        CITY: "PUERTO FRANCISCO DE ORELLANA",
      },
      {
        CITY: "PUYO",
      },
      {
        CITY: "QUEVEDO",
      },
      {
        CITY: "QUITO",
      },
      {
        CITY: "RIOBAMBA",
      },
      {
        CITY: "SALINAS",
      },
      {
        CITY: "SAMBORONDON",
      },
      {
        CITY: "SAN MIGUEL",
      },
      {
        CITY: "SAN RAFAEL",
      },
      {
        CITY: "SANTA CRUZ",
      },
      {
        CITY: "SANTA ELENA",
      },
      {
        CITY: "SANTO DOMINGO DE LOS COLORADOS",
      },
      {
        CITY: "SUCRE",
      },
      {
        CITY: "TABABELA",
      },
      {
        CITY: "TARAPOA",
      },
      {
        CITY: "TENA",
      },
      {
        CITY: "VINCES",
      },
      {
        CITY: "ZAMORA",
      },
      {
        CITY: "ZARUMA",
      },
      {
        CITY: "ZUMBA",
      },
    ],
  },
  {
    COUNTRY: "DENMARK",
    CODE: "DK",
    CITIES: [
      {
        CITY: "AABENRAA",
      },
      {
        CITY: "AABYBRO",
      },
      {
        CITY: "AALBORG",
      },
      {
        CITY: "AARHUS",
      },
      {
        CITY: "AARS",
      },
      {
        CITY: "ABYHOJ",
      },
      {
        CITY: "AGEDRUP",
      },
      {
        CITY: "AGERBAEK",
      },
      {
        CITY: "AGERSKOV",
      },
      {
        CITY: "AKIRKEBY",
      },
      {
        CITY: "ALBAEK",
      },
      {
        CITY: "ALBERTSLUND",
      },
      {
        CITY: "ALE",
      },
      {
        CITY: "ALESTRUP",
      },
      {
        CITY: "ALLER",
      },
      {
        CITY: "ALLEROD",
      },
      {
        CITY: "ALLINGABRO",
      },
      {
        CITY: "ALLINGE",
      },
      {
        CITY: "ALMIND",
      },
      {
        CITY: "ALSGARDE",
      },
      {
        CITY: "ALSLEV",
      },
      {
        CITY: "ALSLEV",
      },
      {
        CITY: "ANHOLT",
      },
      {
        CITY: "ANS",
      },
      {
        CITY: "ANSAGER",
      },
      {
        CITY: "ARDEN",
      },
      {
        CITY: "ARRE",
      },
      {
        CITY: "ARSLEV",
      },
      {
        CITY: "ARSLEV",
      },
      {
        CITY: "ARUP",
      },
      {
        CITY: "ASA",
      },
      {
        CITY: "ASKEBY",
      },
      {
        CITY: "ASNAES",
      },
      {
        CITY: "ASPERUP",
      },
      {
        CITY: "ASSENS",
      },
      {
        CITY: "ASTRUP",
      },
      {
        CITY: "AUGUSTENBORG",
      },
      {
        CITY: "AUNING",
      },
      {
        CITY: "AVLUM",
      },
      {
        CITY: "BAEKKE",
      },
      {
        CITY: "BAEKMARKSBRO",
      },
      {
        CITY: "BAELUM",
      },
      {
        CITY: "BAGSVAERD",
      },
      {
        CITY: "BALLE",
      },
      {
        CITY: "BALLERUP",
      },
      {
        CITY: "BANDHOLM",
      },
      {
        CITY: "BARING",
      },
      {
        CITY: "BARRIT",
      },
      {
        CITY: "BEDER",
      },
      {
        CITY: "BEDSTED",
      },
      {
        CITY: "BEVTOFT",
      },
      {
        CITY: "BILLUND",
      },
      {
        CITY: "BJAERT",
      },
      {
        CITY: "BJAEVERSKOV",
      },
      {
        CITY: "BJERRINGBRO",
      },
      {
        CITY: "BLAVAND",
      },
      {
        CITY: "BLOKHUS",
      },
      {
        CITY: "BLOMMENSLYST",
      },
      {
        CITY: "BOESLUNDE",
      },
      {
        CITY: "BOGENSE",
      },
      {
        CITY: "BOGO BY",
      },
      {
        CITY: "BOLDERSLEV",
      },
      {
        CITY: "BORDING STATIONSBY",
      },
      {
        CITY: "BORKOP",
      },
      {
        CITY: "BORRE",
      },
      {
        CITY: "BORUM",
      },
      {
        CITY: "BORUP",
      },
      {
        CITY: "BOVLINGBJERG",
      },
      {
        CITY: "BOVRUP",
      },
      {
        CITY: "BRABRAND",
      },
      {
        CITY: "BRAEDSTRUP",
      },
      {
        CITY: "BRAMMING",
      },
      {
        CITY: "BRANDE",
      },
      {
        CITY: "BRANDERUP",
      },
      {
        CITY: "BREDBALLE",
      },
      {
        CITY: "BREDEBRO",
      },
      {
        CITY: "BREDSTEN",
      },
      {
        CITY: "BREJNING",
      },
      {
        CITY: "BRENDERUP",
      },
      {
        CITY: "BROAGER",
      },
      {
        CITY: "BROBYVAERK",
      },
      {
        CITY: "BRONDBY STRAND",
      },
      {
        CITY: "BRONSHOJ",
      },
      {
        CITY: "BRORUP",
      },
      {
        CITY: "BROVST",
      },
      {
        CITY: "BRUUNSHAB",
      },
      {
        CITY: "BRYRUP",
      },
      {
        CITY: "BYLDERUP-BOV",
      },
      {
        CITY: "CHARLOTTENLUND",
      },
      {
        CITY: "CHRISTIANSFELD",
      },
      {
        CITY: "COPENHAGEN",
      },
      {
        CITY: "DALMOSE",
      },
      {
        CITY: "DANNEMARE",
      },
      {
        CITY: "DAUGARD",
      },
      {
        CITY: "DIANALUND",
      },
      {
        CITY: "DONG",
      },
      {
        CITY: "DRONNINGLUND",
      },
      {
        CITY: "DRONNINGMOLLE",
      },
      {
        CITY: "DYBVAD",
      },
      {
        CITY: "EBBERUP",
      },
      {
        CITY: "EBELTOFT",
      },
      {
        CITY: "EGA",
      },
      {
        CITY: "EGERNSUND",
      },
      {
        CITY: "EGTVED",
      },
      {
        CITY: "EJBY",
      },
      {
        CITY: "EJBY",
      },
      {
        CITY: "EJSTRUP",
      },
      {
        CITY: "ELSINORE",
      },
      {
        CITY: "ENGESVANG",
      },
      {
        CITY: "ERRINDLEV",
      },
      {
        CITY: "ERSLEV",
      },
      {
        CITY: "ESBJERG",
      },
      {
        CITY: "ESKEBJERG",
      },
      {
        CITY: "ESKILSTRUP",
      },
      {
        CITY: "ESPERGAERDE",
      },
      {
        CITY: "FAABORG",
      },
      {
        CITY: "FAKSE",
      },
      {
        CITY: "FAREVEJLE",
      },
      {
        CITY: "FARUM",
      },
      {
        CITY: "FARUP",
      },
      {
        CITY: "FARVANG",
      },
      {
        CITY: "FENSMARK",
      },
      {
        CITY: "FERRITSLEV",
      },
      {
        CITY: "FERSLEV",
      },
      {
        CITY: "FJENNESLEV",
      },
      {
        CITY: "FJERRITSLEV",
      },
      {
        CITY: "FLAUENSKJOLD",
      },
      {
        CITY: "FLEMMING",
      },
      {
        CITY: "FOLLENSLEV",
      },
      {
        CITY: "FOVLING",
      },
      {
        CITY: "FREDENSBORG",
      },
      {
        CITY: "FREDERICIA",
      },
      {
        CITY: "FREDERIKSBERG",
      },
      {
        CITY: "FREDERIKSBERG",
      },
      {
        CITY: "FREDERIKSDAL",
      },
      {
        CITY: "FREDERIKSHAVN",
      },
      {
        CITY: "FREDERIKSSUND",
      },
      {
        CITY: "FRORUP",
      },
      {
        CITY: "FROSTRUP",
      },
      {
        CITY: "FUGLEBJERG",
      },
      {
        CITY: "GADBJERG",
      },
      {
        CITY: "GADSTRUP",
      },
      {
        CITY: "GALTEN",
      },
      {
        CITY: "GAMMEL SVEBOLLE",
      },
      {
        CITY: "GANDRUP",
      },
      {
        CITY: "GANLOSE",
      },
      {
        CITY: "GEDVED",
      },
      {
        CITY: "GELSTED",
      },
      {
        CITY: "GENTOFTE",
      },
      {
        CITY: "GESTEN",
      },
      {
        CITY: "GILLELEJE",
      },
      {
        CITY: "GISLEV",
      },
      {
        CITY: "GISLINGE",
      },
      {
        CITY: "GISTRUP",
      },
      {
        CITY: "GIVE",
      },
      {
        CITY: "GJERLEV",
      },
      {
        CITY: "GJERN",
      },
      {
        CITY: "GJOL",
      },
      {
        CITY: "GLADSAXE",
      },
      {
        CITY: "GLAMSBJERG",
      },
      {
        CITY: "GLEJBJERG",
      },
      {
        CITY: "GLESBORG",
      },
      {
        CITY: "GLOSTRUP",
      },
      {
        CITY: "GLUMSO",
      },
      {
        CITY: "GLYNGORE",
      },
      {
        CITY: "GORDING",
      },
      {
        CITY: "GORLOSE",
      },
      {
        CITY: "GRAESTED",
      },
      {
        CITY: "GRAM",
      },
      {
        CITY: "GREDSTEDBRO",
      },
      {
        CITY: "GREVE",
      },
      {
        CITY: "GREVE STRAND",
      },
      {
        CITY: "GREVINGE",
      },
      {
        CITY: "GRINDSTED",
      },
      {
        CITY: "GUDBJERG",
      },
      {
        CITY: "GUDHJEM",
      },
      {
        CITY: "GUDME",
      },
      {
        CITY: "GULDBORG",
      },
      {
        CITY: "HADERSLEV",
      },
      {
        CITY: "HADERUP",
      },
      {
        CITY: "HADSTEN",
      },
      {
        CITY: "HADSUND",
      },
      {
        CITY: "HALS",
      },
      {
        CITY: "HAMMEL",
      },
      {
        CITY: "HAMMERSHOJ",
      },
      {
        CITY: "HAMPEN",
      },
      {
        CITY: "HANSTHOLM",
      },
      {
        CITY: "HARBOORE",
      },
      {
        CITY: "HARBY",
      },
      {
        CITY: "HARLEV",
      },
      {
        CITY: "HARLEV",
      },
      {
        CITY: "HARNDRUP",
      },
      {
        CITY: "HARPELUNDE",
      },
      {
        CITY: "HASLE",
      },
      {
        CITY: "HASLEV",
      },
      {
        CITY: "HASSELAGER",
      },
      {
        CITY: "HAVDRUP",
      },
      {
        CITY: "HAVNDAL",
      },
      {
        CITY: "HEDEHUSENE",
      },
      {
        CITY: "HEDENSTED",
      },
      {
        CITY: "HEJLS",
      },
      {
        CITY: "HEJNSVIG",
      },
      {
        CITY: "HELLEBAEK",
      },
      {
        CITY: "HELLERUP",
      },
      {
        CITY: "HELSINGE",
      },
      {
        CITY: "HEMMET",
      },
      {
        CITY: "HENNE",
      },
      {
        CITY: "HENNE STRAND",
      },
      {
        CITY: "HERFOLGE",
      },
      {
        CITY: "HERLEV",
      },
      {
        CITY: "HERLUFMAGLE",
      },
      {
        CITY: "HERNING",
      },
      {
        CITY: "HESSELAGER",
      },
      {
        CITY: "HIMMELEV",
      },
      {
        CITY: "HINNERUP",
      },
      {
        CITY: "HIRTSHALS",
      },
      {
        CITY: "HJALLERUP",
      },
      {
        CITY: "HJERM",
      },
      {
        CITY: "HJORTSHOJ",
      },
      {
        CITY: "HOBRO",
      },
      {
        CITY: "HODSAGER",
      },
      {
        CITY: "HOJBJERG",
      },
      {
        CITY: "HOJBY",
      },
      {
        CITY: "HOJE TASTRUP",
      },
      {
        CITY: "HOJSLEV",
      },
      {
        CITY: "HOLBAEK",
      },
      {
        CITY: "HOLEBY",
      },
      {
        CITY: "HOLME",
      },
      {
        CITY: "HOLME-OLSTRUP",
      },
      {
        CITY: "HOLSTEBRO",
      },
      {
        CITY: "HOLSTED",
      },
      {
        CITY: "HOLTE",
      },
      {
        CITY: "HOPTRUP",
      },
      {
        CITY: "HORBELEV",
      },
      {
        CITY: "HORNBAEK",
      },
      {
        CITY: "HORNING",
      },
      {
        CITY: "HORNSLET",
      },
      {
        CITY: "HORNSYLD",
      },
      {
        CITY: "HORSENS",
      },
      {
        CITY: "HORSLUNDE",
      },
      {
        CITY: "HORVE",
      },
      {
        CITY: "HOSTERKOB",
      },
      {
        CITY: "HOU",
      },
      {
        CITY: "HOVEDGARD",
      },
      {
        CITY: "HUMBLE",
      },
      {
        CITY: "HUMLEBAEK",
      },
      {
        CITY: "HUNDESTED",
      },
      {
        CITY: "HUNDSLUND",
      },
      {
        CITY: "HURUP",
      },
      {
        CITY: "HVIDE SANDE",
      },
      {
        CITY: "HVIDOVRE",
      },
      {
        CITY: "HYLLINGE",
      },
      {
        CITY: "IDESTRUP",
      },
      {
        CITY: "IKAST",
      },
      {
        CITY: "JANDERUP",
      },
      {
        CITY: "JELLING",
      },
      {
        CITY: "JERSLEV",
      },
      {
        CITY: "JERSLEV",
      },
      {
        CITY: "JERUP",
      },
      {
        CITY: "JORDRUP",
      },
      {
        CITY: "JORLUNDE",
      },
      {
        CITY: "JUELSMINDE",
      },
      {
        CITY: "JYDERUP",
      },
      {
        CITY: "JYLLINGE",
      },
      {
        CITY: "JYSTRUP",
      },
      {
        CITY: "KALDRED",
      },
      {
        CITY: "KALUNDBORG",
      },
      {
        CITY: "KALVEHAVE",
      },
      {
        CITY: "KARISE",
      },
      {
        CITY: "KARLSLUNDE",
      },
      {
        CITY: "KARLSTRUP",
      },
      {
        CITY: "KARREBAEKSMINDE",
      },
      {
        CITY: "KARUP",
      },
      {
        CITY: "KASTRUP",
      },
      {
        CITY: "KASTRUP",
      },
      {
        CITY: "KEJLSTRUP",
      },
      {
        CITY: "KERTEMINDE",
      },
      {
        CITY: "KETTINGE",
      },
      {
        CITY: "KIBAEK",
      },
      {
        CITY: "KIRKE ESKILSTRUP",
      },
      {
        CITY: "KIRKE HVALSO",
      },
      {
        CITY: "KIRKE SABY",
      },
      {
        CITY: "KIRKE-HYLLINGE",
      },
      {
        CITY: "KJARGAARD",
      },
      {
        CITY: "KJELLERUP",
      },
      {
        CITY: "KLAMPENBORG",
      },
      {
        CITY: "KLARUP",
      },
      {
        CITY: "KLEMENSKER",
      },
      {
        CITY: "KLIPPINGE",
      },
      {
        CITY: "KLOKKERHOLM",
      },
      {
        CITY: "KLOVBORG",
      },
      {
        CITY: "KNEBEL",
      },
      {
        CITY: "KOKKEDAL",
      },
      {
        CITY: "KOLDBY",
      },
      {
        CITY: "KOLDING",
      },
      {
        CITY: "KOLIND",
      },
      {
        CITY: "KOLSEN",
      },
      {
        CITY: "KONGENS LYNGBY",
      },
      {
        CITY: "KONGERSLEV",
      },
      {
        CITY: "KRUSA",
      },
      {
        CITY: "KVAERNDRUP",
      },
      {
        CITY: "KVISTGARD",
      },
      {
        CITY: "KVOLS",
      },
      {
        CITY: "LAESO",
      },
      {
        CITY: "LAMBJERG",
      },
      {
        CITY: "LANGA",
      },
      {
        CITY: "LANGESKOV",
      },
      {
        CITY: "LASBY",
      },
      {
        CITY: "LAURBJERG",
      },
      {
        CITY: "LEDOJE",
      },
      {
        CITY: "LEJRE",
      },
      {
        CITY: "LEM",
      },
      {
        CITY: "LEMMING",
      },
      {
        CITY: "LEMVIG",
      },
      {
        CITY: "LILLE SKENSVED",
      },
      {
        CITY: "LISELEJE",
      },
      {
        CITY: "LOGSTRUP",
      },
      {
        CITY: "LOKKEN",
      },
      {
        CITY: "LOSNING",
      },
      {
        CITY: "LUNDBY STATIONSBY",
      },
      {
        CITY: "LUNDERSKOV",
      },
      {
        CITY: "LYNGBY",
      },
      {
        CITY: "LYNGE",
      },
      {
        CITY: "LYSTRUP",
      },
      {
        CITY: "MALLING",
      },
      {
        CITY: "MALOV",
      },
      {
        CITY: "MARIAGER",
      },
      {
        CITY: "MARIBO",
      },
      {
        CITY: "MARSLET",
      },
      {
        CITY: "MARSLEV",
      },
      {
        CITY: "MARSTAL",
      },
      {
        CITY: "MARTOFTE",
      },
      {
        CITY: "MELBY",
      },
      {
        CITY: "MERN",
      },
      {
        CITY: "MIDDELFART",
      },
      {
        CITY: "MILLINGE",
      },
      {
        CITY: "MOLDRUP",
      },
      {
        CITY: "MORKE",
      },
      {
        CITY: "MORKOV",
      },
      {
        CITY: "MORUD",
      },
      {
        CITY: "MUNKE BJERGBY",
      },
      {
        CITY: "MUNKEBO",
      },
      {
        CITY: "NAERUM",
      },
      {
        CITY: "NAESBY",
      },
      {
        CITY: "NAESTVED",
      },
      {
        CITY: "NAKSKOV",
      },
      {
        CITY: "NEXO",
      },
      {
        CITY: "NIBE",
      },
      {
        CITY: "NIM",
      },
      {
        CITY: "NIMTOFTE",
      },
      {
        CITY: "NIVA",
      },
      {
        CITY: "NO",
      },
      {
        CITY: "NODEBO",
      },
      {
        CITY: "NORAGER",
      },
      {
        CITY: "NORDBORG",
      },
      {
        CITY: "NORDBY",
      },
      {
        CITY: "NORDENSKOV",
      },
      {
        CITY: "NORRE ABY",
      },
      {
        CITY: "NORRE ASMINDRUP",
      },
      {
        CITY: "NORRE BINDSLEV",
      },
      {
        CITY: "NORRE NEBEL",
      },
      {
        CITY: "NORRE SNEDE",
      },
      {
        CITY: "NORREBALLE",
      },
      {
        CITY: "NORRESUNDBY",
      },
      {
        CITY: "NYBORG",
      },
      {
        CITY: "NYGARD",
      },
      {
        CITY: "NYKOBING FALSTER",
      },
      {
        CITY: "NYKOBING MORS",
      },
      {
        CITY: "NYKOBING SJAELLAND",
      },
      {
        CITY: "NYSTED",
      },
      {
        CITY: "ODDER",
      },
      {
        CITY: "ODDESUND SYD",
      },
      {
        CITY: "ODENSE",
      },
      {
        CITY: "ODSTED",
      },
      {
        CITY: "OKSBOL",
      },
      {
        CITY: "OLSTED",
      },
      {
        CITY: "OLSTYKKE",
      },
      {
        CITY: "OMME",
      },
      {
        CITY: "ORBAEK",
      },
      {
        CITY: "ORNHOJ",
      },
      {
        CITY: "ORSTED",
      },
      {
        CITY: "ORUM",
      },
      {
        CITY: "OSBY",
      },
      {
        CITY: "OSTBIRK",
      },
      {
        CITY: "OSTER ASSELS",
      },
      {
        CITY: "OSTERMARIE",
      },
      {
        CITY: "OSTERVRA",
      },
      {
        CITY: "OTTERUP",
      },
      {
        CITY: "OURE",
      },
      {
        CITY: "PADBORG",
      },
      {
        CITY: "PANDRUP",
      },
      {
        CITY: "RAMLOSE",
      },
      {
        CITY: "RANDBOL",
      },
      {
        CITY: "RANDERS",
      },
      {
        CITY: "RANUM",
      },
      {
        CITY: "REBAEK",
      },
      {
        CITY: "REFSVINDINGE",
      },
      {
        CITY: "REGSTRUP",
      },
      {
        CITY: "RIBE",
      },
      {
        CITY: "RINGE",
      },
      {
        CITY: "RINGSTED",
      },
      {
        CITY: "RISSKOV",
      },
      {
        CITY: "RODDING",
      },
      {
        CITY: "RODKAERSBRO",
      },
      {
        CITY: "RODVIG",
      },
      {
        CITY: "RONDE",
      },
      {
        CITY: "ROSKILDE",
      },
      {
        CITY: "ROSLEV",
      },
      {
        CITY: "RUDE",
      },
      {
        CITY: "RUDS-VEDBY",
      },
      {
        CITY: "RUNGSTED",
      },
      {
        CITY: "RY",
      },
      {
        CITY: "RYNKEBY",
      },
      {
        CITY: "RYOMGARD",
      },
      {
        CITY: "RYSLINGE",
      },
      {
        CITY: "SABRO",
      },
      {
        CITY: "SAEBY",
      },
      {
        CITY: "SALTEN",
      },
      {
        CITY: "SALTUM",
      },
      {
        CITY: "SANDAGER",
      },
      {
        CITY: "SANDERUM",
      },
      {
        CITY: "SANDVED",
      },
      {
        CITY: "SHERREBEK",
      },
      {
        CITY: "SILKEBORG",
      },
      {
        CITY: "SINDAL",
      },
      {
        CITY: "SJOLUND",
      },
      {
        CITY: "SKAELSKOR",
      },
      {
        CITY: "SKAERING",
      },
      {
        CITY: "SKAGEN",
      },
      {
        CITY: "SKALS",
      },
      {
        CITY: "SKAMBY",
      },
      {
        CITY: "SKANDERBORG",
      },
      {
        CITY: "SKARUP",
      },
      {
        CITY: "SKIBBY",
      },
      {
        CITY: "SKIVE",
      },
      {
        CITY: "SKJERN",
      },
      {
        CITY: "SKODSBORG",
      },
      {
        CITY: "SKODSTRUP",
      },
      {
        CITY: "SKOVLUNDE",
      },
      {
        CITY: "SLAGELSE",
      },
      {
        CITY: "SLANGERUP",
      },
      {
        CITY: "SLET",
      },
      {
        CITY: "SNEDSTED",
      },
      {
        CITY: "SNEKKERSTEN",
      },
      {
        CITY: "SNERTINGE",
      },
      {
        CITY: "SOBORG",
      },
      {
        CITY: "SOBY",
      },
      {
        CITY: "SOESMARKE",
      },
      {
        CITY: "SOLBJERG",
      },
      {
        CITY: "SOLLESTED",
      },
      {
        CITY: "SOLROD",
      },
      {
        CITY: "SOMMERSTED",
      },
      {
        CITY: "SONDER OMME",
      },
      {
        CITY: "SONDER STENDERUP",
      },
      {
        CITY: "SONDERSO",
      },
      {
        CITY: "SORO",
      },
      {
        CITY: "SORRING",
      },
      {
        CITY: "SORVAD",
      },
      {
        CITY: "SPENTRUP",
      },
      {
        CITY: "SPJALD",
      },
      {
        CITY: "SPOTTRUP",
      },
      {
        CITY: "STAKROGE",
      },
      {
        CITY: "STEGE",
      },
      {
        CITY: "STENDERUP",
      },
      {
        CITY: "STENLILLE",
      },
      {
        CITY: "STENLOSE",
      },
      {
        CITY: "STENSTRUP",
      },
      {
        CITY: "STENSVED",
      },
      {
        CITY: "STOHOLM",
      },
      {
        CITY: "STOKKEMARKE",
      },
      {
        CITY: "STORE FUGLEDE",
      },
      {
        CITY: "STORE HEDDINGE",
      },
      {
        CITY: "STORE MERLOSE",
      },
      {
        CITY: "STORVORDE",
      },
      {
        CITY: "STOUBY",
      },
      {
        CITY: "STOVRING",
      },
      {
        CITY: "STRANDBY",
      },
      {
        CITY: "STRIB",
      },
      {
        CITY: "STROBY",
      },
      {
        CITY: "STRUER",
      },
      {
        CITY: "SULDRUP",
      },
      {
        CITY: "SULSTED",
      },
      {
        CITY: "SUNDBY",
      },
      {
        CITY: "SUNDS",
      },
      {
        CITY: "SVANEKE",
      },
      {
        CITY: "SVANNINGE",
      },
      {
        CITY: "SVENDBORG",
      },
      {
        CITY: "SVENSTRUP",
      },
      {
        CITY: "SVINNINGE",
      },
      {
        CITY: "TAASTRUP",
      },
      {
        CITY: "TAGERUP",
      },
      {
        CITY: "TAPPERNOJE",
      },
      {
        CITY: "TARBAEK",
      },
      {
        CITY: "TARM",
      },
      {
        CITY: "TARS",
      },
      {
        CITY: "TEJN",
      },
      {
        CITY: "TERNDRUP",
      },
      {
        CITY: "THEM",
      },
      {
        CITY: "THISTED",
      },
      {
        CITY: "THORSO",
      },
      {
        CITY: "TIKOB",
      },
      {
        CITY: "TILST",
      },
      {
        CITY: "TIM",
      },
      {
        CITY: "TINGLEV",
      },
      {
        CITY: "TISTRUP",
      },
      {
        CITY: "TISVILDELEJE",
      },
      {
        CITY: "TJAEREBORG",
      },
      {
        CITY: "TJEBBERUP",
      },
      {
        CITY: "TOFTLUND",
      },
      {
        CITY: "TOLLOSE",
      },
      {
        CITY: "TOMMERUP",
      },
      {
        CITY: "TOREBY",
      },
      {
        CITY: "TORNBY",
      },
      {
        CITY: "TORRIG",
      },
      {
        CITY: "TORRING",
      },
      {
        CITY: "TORRING",
      },
      {
        CITY: "TRANBJERG",
      },
      {
        CITY: "TRANEBJERG",
      },
      {
        CITY: "TRANEKAER",
      },
      {
        CITY: "TRELDE",
      },
      {
        CITY: "TRIGE",
      },
      {
        CITY: "TRUE",
      },
      {
        CITY: "TRUSTRUP",
      },
      {
        CITY: "TULLEBOLLE",
      },
      {
        CITY: "TUNE",
      },
      {
        CITY: "TUREBY",
      },
      {
        CITY: "TYLSTRUP",
      },
      {
        CITY: "UGERLOSE",
      },
      {
        CITY: "ULDUM",
      },
      {
        CITY: "ULFBORG",
      },
      {
        CITY: "ULLERSLEV",
      },
      {
        CITY: "ULSTRUP",
      },
      {
        CITY: "VADUM",
      },
      {
        CITY: "VAEGGERLOSE",
      },
      {
        CITY: "VAERLOSE",
      },
      {
        CITY: "VAMDRUP",
      },
      {
        CITY: "VANDEL",
      },
      {
        CITY: "VANTINGE",
      },
      {
        CITY: "VARDE",
      },
      {
        CITY: "VASBY",
      },
      {
        CITY: "VEBBESTRUP",
      },
      {
        CITY: "VEDBAEK",
      },
      {
        CITY: "VEDERSO",
      },
      {
        CITY: "VEFLINGE",
      },
      {
        CITY: "VEJBY",
      },
      {
        CITY: "VEJEN",
      },
      {
        CITY: "VEJLE",
      },
      {
        CITY: "VEJSTRUP",
      },
      {
        CITY: "VEKSO",
      },
      {
        CITY: "VEMB",
      },
      {
        CITY: "VEMMELEV",
      },
      {
        CITY: "VERNINGE",
      },
      {
        CITY: "VESTBJERG",
      },
      {
        CITY: "VESTER HASSING",
      },
      {
        CITY: "VESTER-SKERNINGE",
      },
      {
        CITY: "VESTERBORG",
      },
      {
        CITY: "VESTERVIG",
      },
      {
        CITY: "VIBORG",
      },
      {
        CITY: "VIBY",
      },
      {
        CITY: "VIBY",
      },
      {
        CITY: "VIG",
      },
      {
        CITY: "VILDBJERG",
      },
      {
        CITY: "VILS",
      },
      {
        CITY: "VINDERUP",
      },
      {
        CITY: "VINKEL",
      },
      {
        CITY: "VIPPEROD",
      },
      {
        CITY: "VIRRING",
      },
      {
        CITY: "VIRUM",
      },
      {
        CITY: "VISSENBJERG",
      },
      {
        CITY: "VIUF",
      },
      {
        CITY: "VIVILD",
      },
      {
        CITY: "VODSKOV",
      },
      {
        CITY: "VOJENS",
      },
      {
        CITY: "VORBASSE",
      },
      {
        CITY: "VORDINGBORG",
      },
      {
        CITY: "VRA",
      },
    ],
  },
  {
    COUNTRY: "ESTONIA",
    CODE: "EE",
    CITIES: [
      {
        CITY: "AASMAE",
      },
      {
        CITY: "AAVIKU",
      },
      {
        CITY: "AESPA",
      },
      {
        CITY: "AHTMA",
      },
      {
        CITY: "ALLIKU",
      },
      {
        CITY: "AMBLA",
      },
      {
        CITY: "ANTSLA",
      },
      {
        CITY: "ARDU",
      },
      {
        CITY: "AVINURME",
      },
      {
        CITY: "ELVA",
      },
      {
        CITY: "EMMASTE",
      },
      {
        CITY: "HAABNEEME",
      },
      {
        CITY: "HAAGE",
      },
      {
        CITY: "HAAPSALU",
      },
      {
        CITY: "HALINGA",
      },
      {
        CITY: "HARJU",
      },
      {
        CITY: "HARKU",
      },
      {
        CITY: "KAARMA",
      },
      {
        CITY: "KADRINA",
      },
      {
        CITY: "KEHRA",
      },
      {
        CITY: "KEILA",
      },
      {
        CITY: "KIILI",
      },
      {
        CITY: "KOHILA",
      },
      {
        CITY: "KOHTLA",
      },
      {
        CITY: "KOSE",
      },
      {
        CITY: "KULNA",
      },
      {
        CITY: "KURESSAARE",
      },
      {
        CITY: "KUUSALU",
      },
      {
        CITY: "LAAGRI",
      },
      {
        CITY: "LAGEDI",
      },
      {
        CITY: "LAHTSE",
      },
      {
        CITY: "LEPNA",
      },
      {
        CITY: "LOKSA",
      },
      {
        CITY: "LOO",
      },
      {
        CITY: "LUBJA",
      },
      {
        CITY: "MAARDU",
      },
      {
        CITY: "MURASTE",
      },
      {
        CITY: "NARVA",
      },
      {
        CITY: "OTEPAEAE",
      },
      {
        CITY: "PAIDE",
      },
      {
        CITY: "PAIKUSE",
      },
      {
        CITY: "PALDISKI",
      },
      {
        CITY: "PALIVERE",
      },
      {
        CITY: "PEETRI",
      },
      {
        CITY: "PUHJA",
      },
      {
        CITY: "RAKKE",
      },
      {
        CITY: "RAKVERE",
      },
      {
        CITY: "RAPLA",
      },
      {
        CITY: "SAKU",
      },
      {
        CITY: "SAUE",
      },
      {
        CITY: "SINDI",
      },
      {
        CITY: "SOMERPALU",
      },
      {
        CITY: "SYARE",
      },
      {
        CITY: "TABASALU",
      },
      {
        CITY: "TABIVERE",
      },
      {
        CITY: "TALLINN",
      },
      {
        CITY: "TAMSALU",
      },
      {
        CITY: "TAPA",
      },
      {
        CITY: "TARTU",
      },
      {
        CITY: "TOILA",
      },
      {
        CITY: "VAIDA",
      },
      {
        CITY: "VALGA",
      },
      {
        CITY: "VARA",
      },
      {
        CITY: "VENEVERE",
      },
      {
        CITY: "VIIMSI",
      },
      {
        CITY: "VILJANDI",
      },
      {
        CITY: "VOKA",
      },
    ],
  },
  {
    COUNTRY: "CUBA",
    CODE: "CU",
    CITIES: [
      {
        CITY: "BAYAMO",
      },
      {
        CITY: "CIENFUEGOS",
      },
      {
        CITY: "HABANA",
      },
      {
        CITY: "HAVANA",
      },
      {
        CITY: "LA HABANA",
      },
      {
        CITY: "LAS TUNAS",
      },
      {
        CITY: "MATANZAS",
      },
      {
        CITY: "SANTIAGO DE CUBA",
      },
      {
        CITY: "VARADERO",
      },
      {
        CITY: "VILLA",
      },
    ],
  },
  {
    COUNTRY: "CYPRUS",
    CODE: "CY",
    CITIES: [
      {
        CITY: "AGIA ANNA",
      },
      {
        CITY: "ARADIPPOU",
      },
      {
        CITY: "AYIA MARINA",
      },
      {
        CITY: "CHLORAKAS",
      },
      {
        CITY: "DERYNEIA",
      },
      {
        CITY: "FAMAGUSTA",
      },
      {
        CITY: "GEROSKIPOU",
      },
      {
        CITY: "KATO LAKATAMIA",
      },
      {
        CITY: "KATO POLEMIDIA",
      },
      {
        CITY: "KITI",
      },
      {
        CITY: "KYRENIA",
      },
      {
        CITY: "LARNACA",
      },
      {
        CITY: "LAXIA",
      },
      {
        CITY: "LIMASSOL",
      },
      {
        CITY: "LIVADIA",
      },
      {
        CITY: "MORFOU",
      },
      {
        CITY: "MOUTTAGIAKA",
      },
      {
        CITY: "NICOSIA",
      },
      {
        CITY: "PAPHOS",
      },
      {
        CITY: "PARALIMNI",
      },
      {
        CITY: "PAREKKLISHA",
      },
      {
        CITY: "PRODROMI",
      },
      {
        CITY: "SOTIRA",
      },
      {
        CITY: "STROVOLOS",
      },
      {
        CITY: "TERA",
      },
    ],
  },
  {
    COUNTRY: "COSTA RICA",
    CODE: "CR",
    CITIES: [
      {
        CITY: "ALAJUELA",
      },
      {
        CITY: "ALAJUELITA",
      },
      {
        CITY: "ALFARO",
      },
      {
        CITY: "ASERRI",
      },
      {
        CITY: "ATENAS",
      },
      {
        CITY: "BARVA",
      },
      {
        CITY: "CARTAGO",
      },
      {
        CITY: "COLON",
      },
      {
        CITY: "CORAZON DE JESUS",
      },
      {
        CITY: "CORONADO",
      },
      {
        CITY: "COYOL",
      },
      {
        CITY: "CURRIDABAT",
      },
      {
        CITY: "DESAMPARADOS",
      },
      {
        CITY: "ESCAZU",
      },
      {
        CITY: "ESPARZA",
      },
      {
        CITY: "FILADELFIA",
      },
      {
        CITY: "GRECIA",
      },
      {
        CITY: "GUACHIPELIN",
      },
      {
        CITY: "GUACIMA",
      },
      {
        CITY: "GUADALUPE",
      },
      {
        CITY: "GUAPILES",
      },
      {
        CITY: "HEREDIA",
      },
      {
        CITY: "ISLA DE TIBAS",
      },
      {
        CITY: "JACO",
      },
      {
        CITY: "LA GARITA",
      },
      {
        CITY: "LIBERIA",
      },
      {
        CITY: "LLORENTE",
      },
      {
        CITY: "LLORENTE",
      },
      {
        CITY: "MERCEDES",
      },
      {
        CITY: "MEXICO",
      },
      {
        CITY: "MIRAMAR",
      },
      {
        CITY: "MORAVIA",
      },
      {
        CITY: "PALMARES",
      },
      {
        CITY: "PARAISO",
      },
      {
        CITY: "PAVAS",
      },
      {
        CITY: "PEREZ",
      },
      {
        CITY: "POAS",
      },
      {
        CITY: "PUERTO JIMENEZ",
      },
      {
        CITY: "PUNTARENAS",
      },
      {
        CITY: "QUEPOS",
      },
      {
        CITY: "QUESADA",
      },
      {
        CITY: "SABANILLA",
      },
      {
        CITY: "SAN DIEGO",
      },
      {
        CITY: "SAN FRANCISCO",
      },
      {
        CITY: "SAN FRANCISCO",
      },
      {
        CITY: "SAN ISIDRO",
      },
      {
        CITY: "SAN JOAQUIN",
      },
      {
        CITY: "SAN JOSE DE LA MONTANA",
      },
      {
        CITY: "SAN JOSECITO",
      },
      {
        CITY: "SAN JUAN",
      },
      {
        CITY: "SAN MARCOS",
      },
      {
        CITY: "SAN PABLO",
      },
      {
        CITY: "SAN PEDRO",
      },
      {
        CITY: "SAN RAFAEL",
      },
      {
        CITY: "SAN RAFAEL",
      },
      {
        CITY: "SAN RAMON",
      },
      {
        CITY: "SANTA ANA",
      },
      {
        CITY: "SANTA CECILIA",
      },
      {
        CITY: "SANTA CRUZ",
      },
      {
        CITY: "SANTO DOMINGO",
      },
      {
        CITY: "SARCHI",
      },
      {
        CITY: "SIQUIRRES",
      },
      {
        CITY: "TRES RIOS",
      },
      {
        CITY: "TURRIALBA",
      },
      {
        CITY: "TURRUCARES",
      },
      {
        CITY: "ZAPOTE",
      },
      {
        CITY: "ZARCERO",
      },
    ],
  },
  {
    COUNTRY: "CAYMAN ISLANDS",
    CODE: "KY",
    CITIES: [
      {
        CITY: "BODDEN TOWN",
      },
      {
        CITY: "CORAL GABLES",
      },
      {
        CITY: "GEORGE TOWN",
      },
      {
        CITY: "NEWLANDS",
      },
      {
        CITY: "SAVANNAH",
      },
      {
        CITY: "SPOT BAY",
      },
      {
        CITY: "WEST BAY",
      },
    ],
  },
  {
    COUNTRY: "CZECH REPUBLIC",
    CODE: "CZ",
    CITIES: [
      {
        CITY: "ADAMOV",
      },
      {
        CITY: "ALBRECHTICE",
      },
      {
        CITY: "ARNOLTICE",
      },
      {
        CITY: "AS",
      },
      {
        CITY: "BABICE",
      },
      {
        CITY: "BANOV",
      },
      {
        CITY: "BASKA",
      },
      {
        CITY: "BATELOV",
      },
      {
        CITY: "BAVORY",
      },
      {
        CITY: "BECHYNE",
      },
      {
        CITY: "BELA POD BEZDEZEM",
      },
      {
        CITY: "BELOTIN",
      },
      {
        CITY: "BENATKY NAD JIZEROU",
      },
      {
        CITY: "BENESOV",
      },
      {
        CITY: "BENESOVICE",
      },
      {
        CITY: "BERNARTICE",
      },
      {
        CITY: "BERNARTICE NAD ODROU",
      },
      {
        CITY: "BEROUN",
      },
      {
        CITY: "BEZDEKOV",
      },
      {
        CITY: "BILINA",
      },
      {
        CITY: "BILINA",
      },
      {
        CITY: "BILOVICE",
      },
      {
        CITY: "BILOVICE NAD SVITAVOU",
      },
      {
        CITY: "BITOVANY",
      },
      {
        CITY: "BLANSKO",
      },
      {
        CITY: "BLATNA",
      },
      {
        CITY: "BLIZEVEDLY",
      },
      {
        CITY: "BLOVICE",
      },
      {
        CITY: "BLUCINA",
      },
      {
        CITY: "BLUDOV",
      },
      {
        CITY: "BLUDOVICE",
      },
      {
        CITY: "BOCHOV",
      },
      {
        CITY: "BOHUMIN",
      },
      {
        CITY: "BOHUNOVICE",
      },
      {
        CITY: "BOJANOV",
      },
      {
        CITY: "BOJKOVICE",
      },
      {
        CITY: "BOLATICE",
      },
      {
        CITY: "BOLEBOR",
      },
      {
        CITY: "BOR",
      },
      {
        CITY: "BORETICE",
      },
      {
        CITY: "BOROHRADEK",
      },
      {
        CITY: "BOROTIN",
      },
      {
        CITY: "BOROVANY",
      },
      {
        CITY: "BORSICE",
      },
      {
        CITY: "BOSIN",
      },
      {
        CITY: "BOSKOVICE",
      },
      {
        CITY: "BRADLEC",
      },
      {
        CITY: "BRANDYS NAD LABEM-STARA BOLESLAV",
      },
      {
        CITY: "BRANKY",
      },
      {
        CITY: "BRANOV",
      },
      {
        CITY: "BRATRIKOV",
      },
      {
        CITY: "BREZI",
      },
      {
        CITY: "BREZNO",
      },
      {
        CITY: "BREZOLUPY",
      },
      {
        CITY: "BREZOVA",
      },
      {
        CITY: "BRNO",
      },
      {
        CITY: "BROD",
      },
      {
        CITY: "BRODCE",
      },
      {
        CITY: "BRODEK",
      },
      {
        CITY: "BROUMOV",
      },
      {
        CITY: "BRTNICE",
      },
      {
        CITY: "BRUNTAL",
      },
      {
        CITY: "BRUSPERK",
      },
      {
        CITY: "BUCOVICE",
      },
      {
        CITY: "BUDYNE NAD OHRI",
      },
      {
        CITY: "BUKOVEC",
      },
      {
        CITY: "BUSTEHRAD",
      },
      {
        CITY: "BYLNICE",
      },
      {
        CITY: "BYSTRICE",
      },
      {
        CITY: "BYSTRICE",
      },
      {
        CITY: "BYSTRICE",
      },
      {
        CITY: "BYSTRICE NAD PERNSTEJNEM",
      },
      {
        CITY: "BYSTRICE POD HOSTYNEM",
      },
      {
        CITY: "CAKOVICE",
      },
      {
        CITY: "CASLAV",
      },
      {
        CITY: "CEBIN",
      },
      {
        CITY: "CECHTICE",
      },
      {
        CITY: "CEJC",
      },
      {
        CITY: "CEJKOVICE",
      },
      {
        CITY: "CELADNA",
      },
      {
        CITY: "CELAKOVICE",
      },
      {
        CITY: "CEPI",
      },
      {
        CITY: "CERCANY",
      },
      {
        CITY: "CEREKVICE NAD LOUCNOU",
      },
      {
        CITY: "CERHENICE",
      },
      {
        CITY: "CERNA HORA",
      },
      {
        CITY: "CERNA ZA BORY",
      },
      {
        CITY: "CERNCICE",
      },
      {
        CITY: "CERNOSICE",
      },
      {
        CITY: "CERNOTIN",
      },
      {
        CITY: "CERVENE PECKY",
      },
      {
        CITY: "CERVENY KOSTELEC",
      },
      {
        CITY: "CESKA",
      },
      {
        CITY: "CESKA SKALICE",
      },
      {
        CITY: "CESKA TREBOVA",
      },
      {
        CITY: "CESKA VES",
      },
      {
        CITY: "CESKE MEZIRICI",
      },
      {
        CITY: "CESKY BROD",
      },
      {
        CITY: "CESKY DUB",
      },
      {
        CITY: "CESTICE",
      },
      {
        CITY: "CHEB",
      },
      {
        CITY: "CHEZNOVICE",
      },
      {
        CITY: "CHLUMCANY",
      },
      {
        CITY: "CHLUMEC",
      },
      {
        CITY: "CHLUMEC NAD CIDLINOU",
      },
      {
        CITY: "CHLUPICE",
      },
      {
        CITY: "CHOCEN",
      },
      {
        CITY: "CHOCERADY",
      },
      {
        CITY: "CHODOV",
      },
      {
        CITY: "CHODOV",
      },
      {
        CITY: "CHODOVA PLANA",
      },
      {
        CITY: "CHOMUTOV",
      },
      {
        CITY: "CHOTEBOR",
      },
      {
        CITY: "CHOTESOV",
      },
      {
        CITY: "CHOTETOV",
      },
      {
        CITY: "CHOTOVINY",
      },
      {
        CITY: "CHOUSTNIK",
      },
      {
        CITY: "CHOUSTNIKOVO HRADISTE",
      },
      {
        CITY: "CHRAST",
      },
      {
        CITY: "CHRAST",
      },
      {
        CITY: "CHRASTNA",
      },
      {
        CITY: "CHRIBSKA",
      },
      {
        CITY: "CHROUSTOVICE",
      },
      {
        CITY: "CHRUDIM",
      },
      {
        CITY: "CHVALEC",
      },
      {
        CITY: "CHYNE",
      },
      {
        CITY: "CISTA",
      },
      {
        CITY: "CITOLIBY",
      },
      {
        CITY: "CITOV",
      },
      {
        CITY: "CITOV",
      },
      {
        CITY: "CKYNE",
      },
      {
        CITY: "CTIDRUZICE",
      },
      {
        CITY: "CVIKOV",
      },
      {
        CITY: "DALOVICE",
      },
      {
        CITY: "DAMNICE",
      },
      {
        CITY: "DARKOVICKY",
      },
      {
        CITY: "DAVLE",
      },
      {
        CITY: "DEBLIN",
      },
      {
        CITY: "DEHYLOV",
      },
      {
        CITY: "DEJVICE",
      },
      {
        CITY: "DESNA",
      },
      {
        CITY: "DETMAROVICE",
      },
      {
        CITY: "DILY",
      },
      {
        CITY: "DIVISOV",
      },
      {
        CITY: "DLOUHA TREBOVA",
      },
      {
        CITY: "DLOUHE",
      },
      {
        CITY: "DOBRA",
      },
      {
        CITY: "DOBRA VODA",
      },
      {
        CITY: "DOBRANY",
      },
      {
        CITY: "DOBRE",
      },
      {
        CITY: "DOBRICHOVICE",
      },
      {
        CITY: "DOBRIS",
      },
      {
        CITY: "DOBRIV",
      },
      {
        CITY: "DOBRONIN",
      },
      {
        CITY: "DOBROSLAVICE",
      },
      {
        CITY: "DOBRUSKA",
      },
      {
        CITY: "DOKSY",
      },
      {
        CITY: "DOLANY",
      },
      {
        CITY: "DOLNI BECVA",
      },
      {
        CITY: "DOLNI BENESOV",
      },
      {
        CITY: "DOLNI BERKOVICE",
      },
      {
        CITY: "DOLNI BOJANOVICE",
      },
      {
        CITY: "DOLNI BREZANY",
      },
      {
        CITY: "DOLNI DUNAJOVICE",
      },
      {
        CITY: "DOLNI JIRCANY",
      },
      {
        CITY: "DOLNI KOUNICE",
      },
      {
        CITY: "DOLNI NEMCI",
      },
      {
        CITY: "DOLNI POUSTEVNA",
      },
      {
        CITY: "DOLNI RADECHOVA",
      },
      {
        CITY: "DOLNI SUCHA",
      },
      {
        CITY: "DOLNI SYTOVA",
      },
      {
        CITY: "DOLNI TERLICKO",
      },
      {
        CITY: "DOLNI ZANDOV",
      },
      {
        CITY: "DOMASOV",
      },
      {
        CITY: "DOMAZELICE",
      },
      {
        CITY: "DOMAZLICE",
      },
      {
        CITY: "DOUBRAVNIK",
      },
      {
        CITY: "DOUBRAVY",
      },
      {
        CITY: "DOUDLEBY NAD ORLICI",
      },
      {
        CITY: "DRAHOTUSE",
      },
      {
        CITY: "DRISY",
      },
      {
        CITY: "DRNOVICE",
      },
      {
        CITY: "DRZKOV",
      },
      {
        CITY: "DRZOVICE",
      },
      {
        CITY: "DUBEC",
      },
      {
        CITY: "DUBENEC",
      },
      {
        CITY: "DUBI",
      },
      {
        CITY: "DUBICKO",
      },
      {
        CITY: "DUBNANY",
      },
      {
        CITY: "DUBNE",
      },
      {
        CITY: "DUCHCOV",
      },
      {
        CITY: "DUKOVANY",
      },
      {
        CITY: "DUSEJOV",
      },
      {
        CITY: "DVORCE",
      },
      {
        CITY: "DVUR KRALOVE NAD LABEM",
      },
      {
        CITY: "DYMOKURY",
      },
      {
        CITY: "EJPOVICE",
      },
      {
        CITY: "FILIPOVA",
      },
      {
        CITY: "FRANCOVA LHOTA",
      },
      {
        CITY: "FRANTISKOVY LAZNE",
      },
      {
        CITY: "FRENSTAT POD RADHOSTEM",
      },
      {
        CITY: "FRYDEK-MISTEK",
      },
      {
        CITY: "FRYDLANT",
      },
      {
        CITY: "FRYDLANT NAD OSTRAVICI",
      },
      {
        CITY: "FRYSTAK",
      },
      {
        CITY: "FULNEK",
      },
      {
        CITY: "GOLCUV JENIKOV",
      },
      {
        CITY: "GRUCOVICE",
      },
      {
        CITY: "HABARTOV",
      },
      {
        CITY: "HAJ VE SLEZSKU",
      },
      {
        CITY: "HAJE",
      },
      {
        CITY: "HAJNICE",
      },
      {
        CITY: "HALENKOV",
      },
      {
        CITY: "HAMR",
      },
      {
        CITY: "HARRACHOV",
      },
      {
        CITY: "HAVRICE",
      },
      {
        CITY: "HERMANUV MESTEC",
      },
      {
        CITY: "HLASNA TREBAN",
      },
      {
        CITY: "HLINSKO",
      },
      {
        CITY: "HLUBOCEPY",
      },
      {
        CITY: "HLUBOCKY",
      },
      {
        CITY: "HLUBOKA NAD VLTAVOU",
      },
      {
        CITY: "HLUCIN",
      },
      {
        CITY: "HODICE",
      },
      {
        CITY: "HODSLAVICE",
      },
      {
        CITY: "HOLASICE",
      },
      {
        CITY: "HOLESOV",
      },
      {
        CITY: "HOLICE",
      },
      {
        CITY: "HOLOUBKOV",
      },
      {
        CITY: "HOLUBICE",
      },
      {
        CITY: "HOLYSOV",
      },
      {
        CITY: "HORESEDLY",
      },
      {
        CITY: "HORICE",
      },
      {
        CITY: "HORIN",
      },
      {
        CITY: "HORNI BECVA",
      },
      {
        CITY: "HORNI BENESOV",
      },
      {
        CITY: "HORNI BOJANOVICE",
      },
      {
        CITY: "HORNI BRIZA",
      },
      {
        CITY: "HORNI LUTYNE",
      },
      {
        CITY: "HORNI NEMCI",
      },
      {
        CITY: "HORNI POCERNICE",
      },
      {
        CITY: "HORNI PODLUZI",
      },
      {
        CITY: "HORNI REDICE",
      },
      {
        CITY: "HORNI SLAVKOV",
      },
      {
        CITY: "HOROMERICE",
      },
      {
        CITY: "HOROUSANKY",
      },
      {
        CITY: "HOROVICE",
      },
      {
        CITY: "HORSOVSKY TYN",
      },
      {
        CITY: "HOSPOZIN",
      },
      {
        CITY: "HOSTERADICE",
      },
      {
        CITY: "HOSTICE",
      },
      {
        CITY: "HOSTIN",
      },
      {
        CITY: "HOSTINNE",
      },
      {
        CITY: "HOSTIVICE",
      },
      {
        CITY: "HOSTOMICE",
      },
      {
        CITY: "HOSTOMICE",
      },
      {
        CITY: "HOVEZI",
      },
      {
        CITY: "HOVORANY",
      },
      {
        CITY: "HOVORCOVICE",
      },
      {
        CITY: "HRABETICE",
      },
      {
        CITY: "HRADCOVICE",
      },
      {
        CITY: "HRADEC",
      },
      {
        CITY: "HRADEC NAD MORAVICI",
      },
      {
        CITY: "HRADEK",
      },
      {
        CITY: "HRADEK NAD NISOU",
      },
      {
        CITY: "HRADISTE",
      },
      {
        CITY: "HRADISTE",
      },
      {
        CITY: "HRANICE",
      },
      {
        CITY: "HRANICE",
      },
      {
        CITY: "HRDEJOVICE",
      },
      {
        CITY: "HRONOV",
      },
      {
        CITY: "HRUSOVANY NAD JEVISOVKOU",
      },
      {
        CITY: "HRUSOVANY U BRNA",
      },
      {
        CITY: "HUDLICE",
      },
      {
        CITY: "HUKVALDY",
      },
      {
        CITY: "HULIN",
      },
      {
        CITY: "HUMPOLEC",
      },
      {
        CITY: "HUSTOPECE",
      },
      {
        CITY: "HVOZD",
      },
      {
        CITY: "HYSLY",
      },
      {
        CITY: "IVANCICE",
      },
      {
        CITY: "IVANOVICE NA HANE",
      },
      {
        CITY: "JABLONEC NAD NISOU",
      },
      {
        CITY: "JABLONNE NAD ORLICI",
      },
      {
        CITY: "JABLONNE V PODJESTEDI",
      },
      {
        CITY: "JABLUNKA",
      },
      {
        CITY: "JABLUNKOV",
      },
      {
        CITY: "JALUBI",
      },
      {
        CITY: "JANOVICE",
      },
      {
        CITY: "JANOVICE NAD UHLAVOU",
      },
      {
        CITY: "JAROMER",
      },
      {
        CITY: "JAROSLAV",
      },
      {
        CITY: "JAROSLAVICE",
      },
      {
        CITY: "JEDOVNICE",
      },
      {
        CITY: "JENEC",
      },
      {
        CITY: "JESENICE",
      },
      {
        CITY: "JESENIK",
      },
      {
        CITY: "JEVICKO",
      },
      {
        CITY: "JEVISOVICE",
      },
      {
        CITY: "JEZOV",
      },
      {
        CITY: "JIHLAVA",
      },
      {
        CITY: "JILEMNICE",
      },
      {
        CITY: "JILOVE U PRAHY",
      },
      {
        CITY: "JILOVEC",
      },
      {
        CITY: "JILOVISTE",
      },
      {
        CITY: "JINCE",
      },
      {
        CITY: "JINDRICHUV HRADEC",
      },
      {
        CITY: "JINOCANY",
      },
      {
        CITY: "JIRICE",
      },
      {
        CITY: "JIRICKOV",
      },
      {
        CITY: "JIRKOV",
      },
      {
        CITY: "JIRNY",
      },
      {
        CITY: "JISTEBNIK",
      },
      {
        CITY: "KACEROV",
      },
      {
        CITY: "KACICE",
      },
      {
        CITY: "KADAN",
      },
      {
        CITY: "KAMENICE",
      },
      {
        CITY: "KAMENICE",
      },
      {
        CITY: "KAMENICKY SENOV",
      },
      {
        CITY: "KAMENNE ZEHROVICE",
      },
      {
        CITY: "KAMENNY PRIVOZ",
      },
      {
        CITY: "KAMYK",
      },
      {
        CITY: "KAPLICE",
      },
      {
        CITY: "KARLIN",
      },
      {
        CITY: "KARLOVY VARY",
      },
      {
        CITY: "KARVINA",
      },
      {
        CITY: "KAZNEJOV",
      },
      {
        CITY: "KBELY",
      },
      {
        CITY: "KDYNE",
      },
      {
        CITY: "KLADNO",
      },
      {
        CITY: "KLASTEREC NAD OHRI",
      },
      {
        CITY: "KLATOVY",
      },
      {
        CITY: "KLECANY",
      },
      {
        CITY: "KLICANY",
      },
      {
        CITY: "KLIMKOVICE",
      },
      {
        CITY: "KLOBOUKY",
      },
      {
        CITY: "KNEZMOST",
      },
      {
        CITY: "KOCHOVICE",
      },
      {
        CITY: "KOCI",
      },
      {
        CITY: "KOLODEJE",
      },
      {
        CITY: "KOMARICE",
      },
      {
        CITY: "KOMORNI LHOTKA",
      },
      {
        CITY: "KONICE",
      },
      {
        CITY: "KOPIDLNO",
      },
      {
        CITY: "KOPRIVNICE",
      },
      {
        CITY: "KORYCANY",
      },
      {
        CITY: "KOSMONOSY",
      },
      {
        CITY: "KOSTELEC NA HANE",
      },
      {
        CITY: "KOSTELEC NAD CERNYMI LESY",
      },
      {
        CITY: "KOSTELEC NAD LABEM",
      },
      {
        CITY: "KOSTELEC NAD ORLICI",
      },
      {
        CITY: "KOSTELNI HLAVNO",
      },
      {
        CITY: "KOSTICE",
      },
      {
        CITY: "KOTENCICE",
      },
      {
        CITY: "KOURIM",
      },
      {
        CITY: "KOUTY",
      },
      {
        CITY: "KOZMICE",
      },
      {
        CITY: "KRALICE NA HANE",
      },
      {
        CITY: "KRALIKY",
      },
      {
        CITY: "KRALOVICE",
      },
      {
        CITY: "KRALUPY NAD VLTAVOU",
      },
      {
        CITY: "KRALUV DVUR",
      },
      {
        CITY: "KRASLICE",
      },
      {
        CITY: "KRASNA HORA NAD VLTAVOU",
      },
      {
        CITY: "KRASNA LIPA",
      },
      {
        CITY: "KRATONOHY",
      },
      {
        CITY: "KRAVARE",
      },
      {
        CITY: "KRCHLEBY",
      },
      {
        CITY: "KRENOVICE",
      },
      {
        CITY: "KREPENICE",
      },
      {
        CITY: "KRESICE",
      },
      {
        CITY: "KRETIN",
      },
      {
        CITY: "KRHOVA",
      },
      {
        CITY: "KRIVOKLAT",
      },
      {
        CITY: "KRIZANOV",
      },
      {
        CITY: "KRMELIN",
      },
      {
        CITY: "KRNOV",
      },
      {
        CITY: "KRNSKO",
      },
      {
        CITY: "KROUNA",
      },
      {
        CITY: "KRTINY",
      },
      {
        CITY: "KRUMVIR",
      },
      {
        CITY: "KRUPKA",
      },
      {
        CITY: "KTIS",
      },
      {
        CITY: "KUNCICE",
      },
      {
        CITY: "KUNCINA",
      },
      {
        CITY: "KUNOVICE",
      },
      {
        CITY: "KUNRATICE",
      },
      {
        CITY: "KUNSTAT",
      },
      {
        CITY: "KURIM",
      },
      {
        CITY: "KVETNICE",
      },
      {
        CITY: "KYJOV",
      },
      {
        CITY: "KYJOV",
      },
      {
        CITY: "KYLESOVICE",
      },
      {
        CITY: "LACHOV",
      },
      {
        CITY: "LADVI",
      },
      {
        CITY: "LAMPERTICE",
      },
      {
        CITY: "LANSKROUN",
      },
      {
        CITY: "LANZHOT",
      },
      {
        CITY: "LASENICE",
      },
      {
        CITY: "LAZNE BOHDANEC",
      },
      {
        CITY: "LAZNE LIBVERDA",
      },
      {
        CITY: "LEDEC NAD SAZAVOU",
      },
      {
        CITY: "LEDNICE",
      },
      {
        CITY: "LESKOVEC",
      },
      {
        CITY: "LESONICE",
      },
      {
        CITY: "LETOHRAD",
      },
      {
        CITY: "LETOVICE",
      },
      {
        CITY: "LETY",
      },
      {
        CITY: "LHOTA",
      },
      {
        CITY: "LHOTA",
      },
      {
        CITY: "LHOTA",
      },
      {
        CITY: "LHOTA POD LIBCANY",
      },
      {
        CITY: "LHOTKA",
      },
      {
        CITY: "LIBAN",
      },
      {
        CITY: "LIBCANY",
      },
      {
        CITY: "LIBEREC",
      },
      {
        CITY: "LIBESICE",
      },
      {
        CITY: "LIBEZNICE",
      },
      {
        CITY: "LIBICE NAD CIDLINOU",
      },
      {
        CITY: "LIBIS",
      },
      {
        CITY: "LIBOCANY",
      },
      {
        CITY: "LIBOCHOVICKY",
      },
      {
        CITY: "LIBOS",
      },
      {
        CITY: "LICHKOV",
      },
      {
        CITY: "LICHNOV (O. NOVY JICIN)",
      },
      {
        CITY: "LINE",
      },
      {
        CITY: "LIPENCE",
      },
      {
        CITY: "LIPNIK NAD BECVOU",
      },
      {
        CITY: "LIPOVA",
      },
      {
        CITY: "LISOV",
      },
      {
        CITY: "LITAVA",
      },
      {
        CITY: "LITEN",
      },
      {
        CITY: "LITOVEL",
      },
      {
        CITY: "LITVINOV",
      },
      {
        CITY: "LODENICE",
      },
      {
        CITY: "LOKET",
      },
      {
        CITY: "LOMNICE",
      },
      {
        CITY: "LOMNICE NAD LUZNICI",
      },
      {
        CITY: "LOMNICE NAD POPELKOU",
      },
      {
        CITY: "LOUCANY",
      },
      {
        CITY: "LOUCEN",
      },
      {
        CITY: "LOUKOV",
      },
      {
        CITY: "LOUNOVICE POD BLANIKEM",
      },
      {
        CITY: "LOUNY",
      },
      {
        CITY: "LOVOSICE",
      },
      {
        CITY: "LUBENEC",
      },
      {
        CITY: "LUBY",
      },
      {
        CITY: "LUHACOVICE",
      },
      {
        CITY: "LUKA",
      },
      {
        CITY: "LUKOV",
      },
      {
        CITY: "LUSTENICE",
      },
      {
        CITY: "LUTIN",
      },
      {
        CITY: "LUZE",
      },
      {
        CITY: "LUZNA",
      },
      {
        CITY: "LYSA NAD LABEM",
      },
      {
        CITY: "LYSICE",
      },
      {
        CITY: "MALA SKALA",
      },
      {
        CITY: "MALCICE",
      },
      {
        CITY: "MALENICE",
      },
      {
        CITY: "MALENOVICE",
      },
      {
        CITY: "MALESOV",
      },
      {
        CITY: "MALY UJEZD",
      },
      {
        CITY: "MARIANSKA",
      },
      {
        CITY: "MARKVARTOVICE",
      },
      {
        CITY: "MAROV U UPICE",
      },
      {
        CITY: "MCELY",
      },
      {
        CITY: "MECHENICE",
      },
      {
        CITY: "MENANY",
      },
      {
        CITY: "MERKLIN",
      },
      {
        CITY: "MERKLIN",
      },
      {
        CITY: "MESICE",
      },
      {
        CITY: "MESTEC",
      },
      {
        CITY: "MESTO TOUSKOV",
      },
      {
        CITY: "MEZIBORI",
      },
      {
        CITY: "MEZILESI",
      },
      {
        CITY: "MICHLE",
      },
      {
        CITY: "MIKULOV",
      },
      {
        CITY: "MIKULOVICE",
      },
      {
        CITY: "MILEVSKO",
      },
      {
        CITY: "MILIKOV",
      },
      {
        CITY: "MILIN",
      },
      {
        CITY: "MILONICE",
      },
      {
        CITY: "MILOTICE",
      },
      {
        CITY: "MILOVICE",
      },
      {
        CITY: "MIMON",
      },
      {
        CITY: "MIROSLAV",
      },
      {
        CITY: "MIROSOV",
      },
      {
        CITY: "MIROSOVICE",
      },
      {
        CITY: "MLADA VOZICE",
      },
      {
        CITY: "MLADE BUKY",
      },
      {
        CITY: "MLADKOV",
      },
      {
        CITY: "MLAZOVICE",
      },
      {
        CITY: "MNICHOVICE",
      },
      {
        CITY: "MNISEK POD BRDY",
      },
      {
        CITY: "MODRICE",
      },
      {
        CITY: "MOHELNICE",
      },
      {
        CITY: "MOKRA HORAKOV",
      },
      {
        CITY: "MOKRANY",
      },
      {
        CITY: "MORAN",
      },
      {
        CITY: "MORAVICANY",
      },
      {
        CITY: "MORAVSKA NOVA VES",
      },
      {
        CITY: "MORAVSKA TREBOVA",
      },
      {
        CITY: "MORAVSKE BUDEJOVICE",
      },
      {
        CITY: "MORAVSKY BEROUN",
      },
      {
        CITY: "MORAVSKY KRUMLOV",
      },
      {
        CITY: "MORAVSKY PISEK",
      },
      {
        CITY: "MORAVSKY ZIZKOV",
      },
      {
        CITY: "MOST",
      },
      {
        CITY: "MOSTEK",
      },
      {
        CITY: "MOSTY U JABLUNKOVA",
      },
      {
        CITY: "MRATIN",
      },
      {
        CITY: "MRLINEK",
      },
      {
        CITY: "MSEC",
      },
      {
        CITY: "MSENO",
      },
      {
        CITY: "MUKAROV",
      },
      {
        CITY: "MUTENICE",
      },
      {
        CITY: "NACHOD",
      },
      {
        CITY: "NAKLO",
      },
      {
        CITY: "NAPAJEDLA",
      },
      {
        CITY: "NASAVRKY",
      },
      {
        CITY: "NAVSI U JABLUNKOVA",
      },
      {
        CITY: "NECHANICE",
      },
      {
        CITY: "NEDAKONICE",
      },
      {
        CITY: "NEDOMICE",
      },
      {
        CITY: "NEDVEDICE",
      },
      {
        CITY: "NEHVIZDY",
      },
      {
        CITY: "NEJDEK",
      },
      {
        CITY: "NELAHOZEVES",
      },
      {
        CITY: "NENKOVICE",
      },
      {
        CITY: "NEPOMYSL",
      },
      {
        CITY: "NERATOVICE",
      },
      {
        CITY: "NESOVICE",
      },
      {
        CITY: "NEZAMYSLICE",
      },
      {
        CITY: "NEZVESTICE",
      },
      {
        CITY: "NIKOLCICE",
      },
      {
        CITY: "NIVNICE",
      },
      {
        CITY: "NOVA CEREKEV",
      },
      {
        CITY: "NOVA LHOTA",
      },
      {
        CITY: "NOVA PAKA",
      },
      {
        CITY: "NOVA VCELNICE",
      },
      {
        CITY: "NOVA VES",
      },
      {
        CITY: "NOVE DVORY",
      },
      {
        CITY: "NOVE MESTO NA MORAVE",
      },
      {
        CITY: "NOVE MESTO NAD METUJI",
      },
      {
        CITY: "NOVE STRASECI",
      },
      {
        CITY: "NOVE VESELI",
      },
      {
        CITY: "NOVOSEDLY",
      },
      {
        CITY: "NOVY BOR",
      },
      {
        CITY: "NOVY BYDZOV",
      },
      {
        CITY: "NOVY HROZENKOV",
      },
      {
        CITY: "NOVY JICIN",
      },
      {
        CITY: "NOVY KNIN",
      },
      {
        CITY: "NOVY OLDRICHOV",
      },
      {
        CITY: "NUCICE",
      },
      {
        CITY: "NUPAKY",
      },
      {
        CITY: "NYDEK",
      },
      {
        CITY: "NYMBURK",
      },
      {
        CITY: "NYRANY",
      },
      {
        CITY: "NYRSKO",
      },
      {
        CITY: "OBRANY",
      },
      {
        CITY: "OBRISTVI",
      },
      {
        CITY: "OCHOZ",
      },
      {
        CITY: "ODOLENA VODA",
      },
      {
        CITY: "ODRY",
      },
      {
        CITY: "OHROBEC",
      },
      {
        CITY: "OKRISKY",
      },
      {
        CITY: "OLOMOUC",
      },
      {
        CITY: "OLOMUCANY",
      },
      {
        CITY: "OLOVI",
      },
      {
        CITY: "OLSANY",
      },
      {
        CITY: "ONDREJOV",
      },
      {
        CITY: "OPARANY",
      },
      {
        CITY: "OPATOVICE NAD LABEM",
      },
      {
        CITY: "OPAVA",
      },
      {
        CITY: "OPOCNO",
      },
      {
        CITY: "ORLOVA",
      },
      {
        CITY: "OSECNA",
      },
      {
        CITY: "OSEK",
      },
      {
        CITY: "OSIK",
      },
      {
        CITY: "OSLAVANY",
      },
      {
        CITY: "OSTOPOVICE",
      },
      {
        CITY: "OSTRAVA",
      },
      {
        CITY: "OSTROMER",
      },
      {
        CITY: "OSTROV",
      },
      {
        CITY: "OSTROV U MACOCHY",
      },
      {
        CITY: "OSTROZSKA LHOTA",
      },
      {
        CITY: "OSTROZSKA NOVA VES",
      },
      {
        CITY: "OTNICE",
      },
      {
        CITY: "OTROKOVICE",
      },
      {
        CITY: "PACOV",
      },
      {
        CITY: "PALKOVICE",
      },
      {
        CITY: "PARDUBICE",
      },
      {
        CITY: "PARIZ",
      },
      {
        CITY: "PAVLICE",
      },
      {
        CITY: "PCHERY",
      },
      {
        CITY: "PECIN",
      },
      {
        CITY: "PECKY",
      },
      {
        CITY: "PELHRIMOV",
      },
      {
        CITY: "PERSTEJN",
      },
      {
        CITY: "PETROVICE",
      },
      {
        CITY: "PETROVICE U KARVINE",
      },
      {
        CITY: "PETRVALD",
      },
      {
        CITY: "PILSEN",
      },
      {
        CITY: "PISECNA",
      },
      {
        CITY: "PISECNA",
      },
      {
        CITY: "PISTOVICE",
      },
      {
        CITY: "PLANA",
      },
      {
        CITY: "PLANANY",
      },
      {
        CITY: "PLOUZNICE",
      },
      {
        CITY: "POCATKY",
      },
      {
        CITY: "POCENICE",
      },
      {
        CITY: "PODBORANY",
      },
      {
        CITY: "PODEBRADY",
      },
      {
        CITY: "PODIVIN",
      },
      {
        CITY: "POLEPY",
      },
      {
        CITY: "POLESOVICE",
      },
      {
        CITY: "POLICE NAD METUJI",
      },
      {
        CITY: "POLICKA",
      },
      {
        CITY: "POLNA",
      },
      {
        CITY: "PORICANY",
      },
      {
        CITY: "PORITSCH",
      },
      {
        CITY: "POSTOLOPRTY",
      },
      {
        CITY: "POSTRELMOV",
      },
      {
        CITY: "POTSTEJN",
      },
      {
        CITY: "PRACHATICE",
      },
      {
        CITY: "PRACKOVICE NAD LABEM",
      },
      {
        CITY: "PRAGUE",
      },
      {
        CITY: "PRAZMO",
      },
      {
        CITY: "PRECKOV",
      },
      {
        CITY: "PREDKLASTERI",
      },
      {
        CITY: "PREDMERICE NAD JIZEROU",
      },
      {
        CITY: "PREDMERICE NAD LABEM",
      },
      {
        CITY: "PREDMESTI",
      },
      {
        CITY: "PRELOUC",
      },
      {
        CITY: "PREPERE",
      },
      {
        CITY: "PREROV NAD LABEM",
      },
      {
        CITY: "PRESTICE",
      },
      {
        CITY: "PREZLETICE",
      },
      {
        CITY: "PRIBOR",
      },
      {
        CITY: "PRIBYSLAV",
      },
      {
        CITY: "PRIKAZY",
      },
      {
        CITY: "PRIMDA",
      },
      {
        CITY: "PRLOV",
      },
      {
        CITY: "PROSETIN",
      },
      {
        CITY: "PROTIVIN",
      },
      {
        CITY: "PROVODOV",
      },
      {
        CITY: "PRUHONICE",
      },
      {
        CITY: "PRUSANKY",
      },
      {
        CITY: "PSARY",
      },
      {
        CITY: "PUSTEJOV",
      },
      {
        CITY: "PYSELY",
      },
      {
        CITY: "RADIM",
      },
      {
        CITY: "RADNICE",
      },
      {
        CITY: "RADOSTICE",
      },
      {
        CITY: "RADOSTIN",
      },
      {
        CITY: "RADUN",
      },
      {
        CITY: "RAJ",
      },
      {
        CITY: "RAJEC-JESTREBI",
      },
      {
        CITY: "RAJECKO",
      },
      {
        CITY: "RAKOVNIK",
      },
      {
        CITY: "RAPOTICE",
      },
      {
        CITY: "RAPOTIN",
      },
      {
        CITY: "RASPENAVA",
      },
      {
        CITY: "RATAJE",
      },
      {
        CITY: "RATIBOR",
      },
      {
        CITY: "RATISKOVICE",
      },
      {
        CITY: "REBESOVICE",
      },
      {
        CITY: "REPY",
      },
      {
        CITY: "RICANY",
      },
      {
        CITY: "RICANY",
      },
      {
        CITY: "RIKOVICE",
      },
      {
        CITY: "RITKA",
      },
      {
        CITY: "ROKYCANY",
      },
      {
        CITY: "ROKYTNICE",
      },
      {
        CITY: "ROKYTNICE NAD JIZEROU",
      },
      {
        CITY: "RONOV NAD DOUBRAVOU",
      },
      {
        CITY: "ROSICE",
      },
      {
        CITY: "ROSICE",
      },
      {
        CITY: "ROUDNICE NAD LABEM",
      },
      {
        CITY: "ROUDNICEK",
      },
      {
        CITY: "ROUSINOV",
      },
      {
        CITY: "ROVENSKO POD TROSKAMI",
      },
      {
        CITY: "ROVINA",
      },
      {
        CITY: "ROVNA",
      },
      {
        CITY: "ROZDROJOVICE",
      },
      {
        CITY: "ROZMITAL POD TREMSINEM",
      },
      {
        CITY: "ROZNOV POD RADHOSTEM",
      },
      {
        CITY: "ROZSOCHATEC",
      },
      {
        CITY: "ROZTOKY",
      },
      {
        CITY: "RTYNE",
      },
      {
        CITY: "RUDA NAD MORAVOU",
      },
      {
        CITY: "RUDIKOV",
      },
      {
        CITY: "RUDNA",
      },
      {
        CITY: "RUDOLFOV",
      },
      {
        CITY: "RUDOLTICE",
      },
      {
        CITY: "RYBNA",
      },
      {
        CITY: "RYCHNOV",
      },
      {
        CITY: "RYCHNOV NAD KNEZNOU",
      },
      {
        CITY: "RYCHVALD",
      },
      {
        CITY: "RYMAROV",
      },
      {
        CITY: "SADSKA",
      },
      {
        CITY: "SANOV",
      },
      {
        CITY: "SANY",
      },
      {
        CITY: "SATOV",
      },
      {
        CITY: "SEBEROV",
      },
      {
        CITY: "SEBROV",
      },
      {
        CITY: "SEDLCANY",
      },
      {
        CITY: "SEDLEC",
      },
      {
        CITY: "SEDLISTE",
      },
      {
        CITY: "SEIBERSDORF",
      },
      {
        CITY: "SEMILY",
      },
      {
        CITY: "SENEC",
      },
      {
        CITY: "SENICE NA HANE",
      },
      {
        CITY: "SENOHRABY",
      },
      {
        CITY: "SENOV",
      },
      {
        CITY: "SESTAJOVICE",
      },
      {
        CITY: "SEZEMICE",
      },
      {
        CITY: "SIBRINA",
      },
      {
        CITY: "SILHEROVICE",
      },
      {
        CITY: "SILUVKY",
      },
      {
        CITY: "SIVICE",
      },
      {
        CITY: "SKALICE",
      },
      {
        CITY: "SKALSKO",
      },
      {
        CITY: "SKRIPOV",
      },
      {
        CITY: "SKUHROV NAD BELOU",
      },
      {
        CITY: "SKUTEC",
      },
      {
        CITY: "SLANY",
      },
      {
        CITY: "SLAPANICE",
      },
      {
        CITY: "SLAPY",
      },
      {
        CITY: "SLAVICIN",
      },
      {
        CITY: "SLAVKOV U BRNA",
      },
      {
        CITY: "SLAVONICE",
      },
      {
        CITY: "SLOUP",
      },
      {
        CITY: "SLUKNOV",
      },
      {
        CITY: "SLUSOVICE",
      },
      {
        CITY: "SMIRICE",
      },
      {
        CITY: "SMRZOVKA",
      },
      {
        CITY: "SNEDOVICE",
      },
      {
        CITY: "SNEZNE",
      },
      {
        CITY: "SOBESLAV",
      },
      {
        CITY: "SOBOTKA",
      },
      {
        CITY: "SOKOLOV",
      },
      {
        CITY: "SPALENE PORICI",
      },
      {
        CITY: "SPYTIHNEV",
      },
      {
        CITY: "STALKOV",
      },
      {
        CITY: "STANKOV",
      },
      {
        CITY: "STARA PAKA",
      },
      {
        CITY: "STARA VODA",
      },
      {
        CITY: "STARE MESTO",
      },
      {
        CITY: "STARE MESTO",
      },
      {
        CITY: "STARE SEDLISTE",
      },
      {
        CITY: "STAREC",
      },
      {
        CITY: "STAROVICE",
      },
      {
        CITY: "STAROVICKY",
      },
      {
        CITY: "STARY BYDZOV",
      },
      {
        CITY: "STATENICE",
      },
      {
        CITY: "STENOVICE",
      },
      {
        CITY: "STEPANOV",
      },
      {
        CITY: "STERBOHOLY",
      },
      {
        CITY: "STERNBERK",
      },
      {
        CITY: "STETI",
      },
      {
        CITY: "STEZERY",
      },
      {
        CITY: "STITINA",
      },
      {
        CITY: "STITY",
      },
      {
        CITY: "STOCHOV",
      },
      {
        CITY: "STOD",
      },
      {
        CITY: "STOKY",
      },
      {
        CITY: "STRACHOTICE",
      },
      {
        CITY: "STRACHOTIN",
      },
      {
        CITY: "STRAKONICE",
      },
      {
        CITY: "STRANCICE",
      },
      {
        CITY: "STRASICE",
      },
      {
        CITY: "STRAZ NAD NISOU",
      },
      {
        CITY: "STRAZ POD RALSKEM",
      },
      {
        CITY: "STRAZEK",
      },
      {
        CITY: "STRAZNICE",
      },
      {
        CITY: "STRELICE",
      },
      {
        CITY: "STRIBREC",
      },
      {
        CITY: "STRIBRNA SKALICE",
      },
      {
        CITY: "STRIBRO",
      },
      {
        CITY: "STRITEZ NAD LUDINOU",
      },
      {
        CITY: "STRUNKOVICE NAD BLANICI",
      },
      {
        CITY: "STUDENKA",
      },
      {
        CITY: "SUCHA LOZ",
      },
      {
        CITY: "SUDKOV",
      },
      {
        CITY: "SUMICE",
      },
      {
        CITY: "SUMPERK",
      },
      {
        CITY: "SUSICE",
      },
      {
        CITY: "SVATAVA",
      },
      {
        CITY: "SVATY JAN NAD MALSI",
      },
      {
        CITY: "SVETLA NAD SAZAVOU",
      },
      {
        CITY: "SVIADNOV",
      },
      {
        CITY: "SVIHOV",
      },
      {
        CITY: "SVITAVKA",
      },
      {
        CITY: "SVITAVY",
      },
      {
        CITY: "SVOJEK",
      },
      {
        CITY: "SVOJKOV",
      },
      {
        CITY: "SYROVICE",
      },
      {
        CITY: "TACHLOVICE",
      },
      {
        CITY: "TACHOV",
      },
      {
        CITY: "TEHOV",
      },
      {
        CITY: "TELC",
      },
      {
        CITY: "TELNICE",
      },
      {
        CITY: "TEMICE",
      },
      {
        CITY: "TEPLICE",
      },
      {
        CITY: "TESETICE",
      },
      {
        CITY: "TETIN",
      },
      {
        CITY: "TISICE",
      },
      {
        CITY: "TISNOV",
      },
      {
        CITY: "TLUMACOV",
      },
      {
        CITY: "TMAN",
      },
      {
        CITY: "TOSOVICE",
      },
      {
        CITY: "TREBECHOVICE POD OREBEM",
      },
      {
        CITY: "TREBESTOVICE",
      },
      {
        CITY: "TREBON",
      },
      {
        CITY: "TREMESNA",
      },
      {
        CITY: "TREMOSNA",
      },
      {
        CITY: "TRHOVY STEPANOV",
      },
      {
        CITY: "TRINEC",
      },
      {
        CITY: "TRMICE",
      },
      {
        CITY: "TROJA",
      },
      {
        CITY: "TRSTENICE",
      },
      {
        CITY: "TRUTNOV",
      },
      {
        CITY: "TUCAPY",
      },
      {
        CITY: "TUCHLOVICE",
      },
      {
        CITY: "TUCHOMERICE",
      },
      {
        CITY: "TUPESY",
      },
      {
        CITY: "TURNOV",
      },
      {
        CITY: "TURSKO",
      },
      {
        CITY: "TVAROZNA",
      },
      {
        CITY: "TVRDONICE",
      },
      {
        CITY: "TYNEC NAD SAZAVOU",
      },
      {
        CITY: "TYNISTE NAD ORLICI",
      },
      {
        CITY: "UHERSKY BROD",
      },
      {
        CITY: "UHERSKY OSTROH",
      },
      {
        CITY: "UHONICE",
      },
      {
        CITY: "UHRICE",
      },
      {
        CITY: "UJEZD",
      },
      {
        CITY: "UJEZD",
      },
      {
        CITY: "UJEZD NAD LESY",
      },
      {
        CITY: "UNETICE",
      },
      {
        CITY: "UNICOV",
      },
      {
        CITY: "USTI NAD ORLICI",
      },
      {
        CITY: "USTIN",
      },
      {
        CITY: "UVALY",
      },
      {
        CITY: "UZICE",
      },
      {
        CITY: "VACENOVICE",
      },
      {
        CITY: "VALASSKE KLOBOUKY",
      },
      {
        CITY: "VALASSKE MEZIRICI",
      },
      {
        CITY: "VALTICE",
      },
      {
        CITY: "VAMBERK",
      },
      {
        CITY: "VARNSDORF",
      },
      {
        CITY: "VCELNA",
      },
      {
        CITY: "VELEHRAD",
      },
      {
        CITY: "VELESIN",
      },
      {
        CITY: "VELESOVICE",
      },
      {
        CITY: "VELKA BITES",
      },
      {
        CITY: "VELKA BYSTRICE",
      },
      {
        CITY: "VELKA DOBRA",
      },
      {
        CITY: "VELKA JESENICE",
      },
      {
        CITY: "VELKA POLOM",
      },
      {
        CITY: "VELKE ALBRECHTICE",
      },
      {
        CITY: "VELKE BREZNO",
      },
      {
        CITY: "VELKE HERALTICE",
      },
      {
        CITY: "VELKE HYDCICE",
      },
      {
        CITY: "VELKE LOSINY",
      },
      {
        CITY: "VELKE MEZIRICI",
      },
      {
        CITY: "VELKE OPATOVICE",
      },
      {
        CITY: "VELKE POPOVICE",
      },
      {
        CITY: "VELKE PRILEPY",
      },
      {
        CITY: "VELKE SVATONOVICE",
      },
      {
        CITY: "VELKY",
      },
      {
        CITY: "VELKY BOREK",
      },
      {
        CITY: "VELKY OSEK",
      },
      {
        CITY: "VELTRUSY",
      },
      {
        CITY: "VELVARY",
      },
      {
        CITY: "VENDRYNE",
      },
      {
        CITY: "VERNOVICE",
      },
      {
        CITY: "VESELI NAD LUZNICI",
      },
      {
        CITY: "VESELI NAD MORAVOU",
      },
      {
        CITY: "VEVERSKA BITYSKA",
      },
      {
        CITY: "VEVERSKE KNINICE",
      },
      {
        CITY: "VIDCE",
      },
      {
        CITY: "VIMPERK",
      },
      {
        CITY: "VINARICE",
      },
      {
        CITY: "VINICNE SUMICE",
      },
      {
        CITY: "VISNOVE",
      },
      {
        CITY: "VITKOV",
      },
      {
        CITY: "VIZOVICE",
      },
      {
        CITY: "VLACHOVICE",
      },
      {
        CITY: "VLADISLAV",
      },
      {
        CITY: "VLASIM",
      },
      {
        CITY: "VLASTISLAV",
      },
      {
        CITY: "VLCOVICE",
      },
      {
        CITY: "VOCHOV",
      },
      {
        CITY: "VODNANY",
      },
      {
        CITY: "VOJKOVICE",
      },
      {
        CITY: "VOLYNE",
      },
      {
        CITY: "VOTICE",
      },
      {
        CITY: "VRACOV",
      },
      {
        CITY: "VRANE NAD VLTAVOU",
      },
      {
        CITY: "VRATIMOV",
      },
      {
        CITY: "VRATKOV",
      },
      {
        CITY: "VRBICE",
      },
      {
        CITY: "VRBNO POD PRADEDEM",
      },
      {
        CITY: "VRCHLABI",
      },
      {
        CITY: "VRESINA",
      },
      {
        CITY: "VSECHOVICE",
      },
      {
        CITY: "VSECHOVICE",
      },
      {
        CITY: "VSELIBICE",
      },
      {
        CITY: "VSENORY",
      },
      {
        CITY: "VSESTARY",
      },
      {
        CITY: "VSETATY",
      },
      {
        CITY: "VSETIN",
      },
      {
        CITY: "VTELNO",
      },
      {
        CITY: "VYPRACHTICE",
      },
      {
        CITY: "VYSKER",
      },
      {
        CITY: "VYSOKA",
      },
      {
        CITY: "VYSOKA NAD LABEM",
      },
      {
        CITY: "VYSOKE MYTO",
      },
      {
        CITY: "VYSOKE VESELI",
      },
      {
        CITY: "ZABCICE",
      },
      {
        CITY: "ZABREH",
      },
      {
        CITY: "ZADNI TREBAN",
      },
      {
        CITY: "ZAJECI",
      },
      {
        CITY: "ZAMBERK",
      },
      {
        CITY: "ZAMPACH",
      },
      {
        CITY: "ZANDOV",
      },
      {
        CITY: "ZASOVA",
      },
      {
        CITY: "ZATCANY",
      },
      {
        CITY: "ZBOROVICE",
      },
      {
        CITY: "ZBUCH",
      },
      {
        CITY: "ZBYSOV",
      },
      {
        CITY: "ZDANICE",
      },
      {
        CITY: "ZDESLAV",
      },
      {
        CITY: "ZDIBY",
      },
      {
        CITY: "ZDICE",
      },
      {
        CITY: "ZDIREC",
      },
      {
        CITY: "ZDOUNKY",
      },
      {
        CITY: "ZEHUN",
      },
      {
        CITY: "ZELENEC",
      },
      {
        CITY: "ZELENKA",
      },
      {
        CITY: "ZELESICE",
      },
      {
        CITY: "ZELETAVA",
      },
      {
        CITY: "ZELEZNICE",
      },
      {
        CITY: "ZELEZNY BROD",
      },
      {
        CITY: "ZELIV",
      },
      {
        CITY: "ZERAVICE",
      },
      {
        CITY: "ZIDLOCHOVICE",
      },
      {
        CITY: "ZILINA",
      },
      {
        CITY: "ZIPOTIN",
      },
      {
        CITY: "ZIZKOV",
      },
      {
        CITY: "ZLATA",
      },
      {
        CITY: "ZLATE HORY",
      },
      {
        CITY: "ZLICIN",
      },
      {
        CITY: "ZLIV",
      },
      {
        CITY: "ZNOJMO",
      },
      {
        CITY: "ZUBRI",
      },
      {
        CITY: "ZULOVA",
      },
      {
        CITY: "ZVOLE",
      },
    ],
  },
  {
    COUNTRY: "FINLAND",
    CODE: "FI",
    CITIES: [
      {
        CITY: "AAPAJOKI",
      },
      {
        CITY: "AAVASAKSA",
      },
      {
        CITY: "AITOO",
      },
      {
        CITY: "AKAA",
      },
      {
        CITY: "ALASTARO",
      },
      {
        CITY: "ALAVETELI",
      },
      {
        CITY: "ALAVIESKA",
      },
      {
        CITY: "ALAVUS",
      },
      {
        CITY: "ALVETTULA",
      },
      {
        CITY: "ANGELNIEMI",
      },
      {
        CITY: "ANJALA",
      },
      {
        CITY: "ANTTILA",
      },
      {
        CITY: "ASKOLA",
      },
      {
        CITY: "AURA",
      },
      {
        CITY: "BERGOE",
      },
      {
        CITY: "BOX",
      },
      {
        CITY: "BROBY",
      },
      {
        CITY: "DEGERBY",
      },
      {
        CITY: "ENO",
      },
      {
        CITY: "ERAJARVI",
      },
      {
        CITY: "ESPOO",
      },
      {
        CITY: "EURA",
      },
      {
        CITY: "EURAJOKI",
      },
      {
        CITY: "EVIJAERVI",
      },
      {
        CITY: "EVITSKOG",
      },
      {
        CITY: "FISKARS",
      },
      {
        CITY: "FORSBY",
      },
      {
        CITY: "FORSSA",
      },
      {
        CITY: "GAMMELBY",
      },
      {
        CITY: "HAAPAVESI",
      },
      {
        CITY: "HAEME",
      },
      {
        CITY: "HAERMAE",
      },
      {
        CITY: "HAIJAA",
      },
      {
        CITY: "HALIKKO",
      },
      {
        CITY: "HALKIA",
      },
      {
        CITY: "HALLI",
      },
      {
        CITY: "HALSUA",
      },
      {
        CITY: "HAMINA",
      },
      {
        CITY: "HAMMASLAHTI",
      },
      {
        CITY: "HANKO",
      },
      {
        CITY: "HARJAVALTA",
      },
      {
        CITY: "HARVIALA",
      },
      {
        CITY: "HATTULA",
      },
      {
        CITY: "HAUHO",
      },
      {
        CITY: "HAUKIPUDAS",
      },
      {
        CITY: "HAUKKALA",
      },
      {
        CITY: "HAUSJAERVI",
      },
      {
        CITY: "HEIMARI",
      },
      {
        CITY: "HEINOLA",
      },
      {
        CITY: "HELSINGBY",
      },
      {
        CITY: "HELSINKI",
      },
      {
        CITY: "HERVANTA",
      },
      {
        CITY: "HILTULANLAHTI",
      },
      {
        CITY: "HIMANKA",
      },
      {
        CITY: "HINTHAARA",
      },
      {
        CITY: "HIRVENSALMI",
      },
      {
        CITY: "HIRVIHAARA",
      },
      {
        CITY: "HOEGSARA",
      },
      {
        CITY: "HOEYTIAE",
      },
      {
        CITY: "HOLLOLA",
      },
      {
        CITY: "HOLLOLA",
      },
      {
        CITY: "HUITTINEN",
      },
      {
        CITY: "HUMPPILA",
      },
      {
        CITY: "HUUVARI",
      },
      {
        CITY: "HYVINKAEAE",
      },
      {
        CITY: "IHODE",
      },
      {
        CITY: "II",
      },
      {
        CITY: "IISALMI",
      },
      {
        CITY: "IITTALA",
      },
      {
        CITY: "ILKKA",
      },
      {
        CITY: "ILMAJOKI",
      },
      {
        CITY: "ILMARINEN",
      },
      {
        CITY: "ILVESKYLAE",
      },
      {
        CITY: "IMATRA",
      },
      {
        CITY: "INKEROINEN",
      },
      {
        CITY: "ISOJOKI",
      },
      {
        CITY: "ITAEKYLAE",
      },
      {
        CITY: "IVALO",
      },
      {
        CITY: "JAERVELAE",
      },
      {
        CITY: "JAKOBSTAD",
      },
      {
        CITY: "JANAKKALA",
      },
      {
        CITY: "JOENSUU",
      },
      {
        CITY: "JOKELA",
      },
      {
        CITY: "JOKIKUNTA",
      },
      {
        CITY: "JOKIMAA",
      },
      {
        CITY: "JOKIOINEN",
      },
      {
        CITY: "JORMUA",
      },
      {
        CITY: "JOROINEN",
      },
      {
        CITY: "JORVAS",
      },
      {
        CITY: "JOUTSENO",
      },
      {
        CITY: "JURVA",
      },
      {
        CITY: "JUUKA",
      },
      {
        CITY: "JUUPAJOKI",
      },
      {
        CITY: "JYSKAE",
      },
      {
        CITY: "KAAKAMO",
      },
      {
        CITY: "KAARINA",
      },
      {
        CITY: "KAEMMENNIEMI",
      },
      {
        CITY: "KAERRBY",
      },
      {
        CITY: "KAIPIAINEN",
      },
      {
        CITY: "KAIPOLA",
      },
      {
        CITY: "KAIVANTO",
      },
      {
        CITY: "KAJAANI",
      },
      {
        CITY: "KALAJOKI",
      },
      {
        CITY: "KALANTI",
      },
      {
        CITY: "KANGASALA",
      },
      {
        CITY: "KANGASNIEMI",
      },
      {
        CITY: "KANKAANPAEAE",
      },
      {
        CITY: "KANNONKOSKI",
      },
      {
        CITY: "KANNUS",
      },
      {
        CITY: "KANTOMAANPAEAE",
      },
      {
        CITY: "KANTTI",
      },
      {
        CITY: "KANTVIK",
      },
      {
        CITY: "KARHULA",
      },
      {
        CITY: "KARINKANTA",
      },
      {
        CITY: "KARJAA",
      },
      {
        CITY: "KARJALOHJA",
      },
      {
        CITY: "KARKKILA",
      },
      {
        CITY: "KARKKU",
      },
      {
        CITY: "KARSTULA",
      },
      {
        CITY: "KARTTULA",
      },
      {
        CITY: "KARUNKI",
      },
      {
        CITY: "KARVIA",
      },
      {
        CITY: "KASKINEN",
      },
      {
        CITY: "KAUHAJOKI",
      },
      {
        CITY: "KAUHAKORPI",
      },
      {
        CITY: "KAUHAVA",
      },
      {
        CITY: "KAUNIAINEN",
      },
      {
        CITY: "KAUSALA",
      },
      {
        CITY: "KAUSTINEN",
      },
      {
        CITY: "KAUTTUA",
      },
      {
        CITY: "KAUVATSA",
      },
      {
        CITY: "KELLO",
      },
      {
        CITY: "KELLOKOSKI",
      },
      {
        CITY: "KEMI",
      },
      {
        CITY: "KEMINMAA",
      },
      {
        CITY: "KEMPELE",
      },
      {
        CITY: "KERAVA",
      },
      {
        CITY: "KERKKOO",
      },
      {
        CITY: "KEURUU",
      },
      {
        CITY: "KIHNIOE",
      },
      {
        CITY: "KIIMINKI",
      },
      {
        CITY: "KIISTALA",
      },
      {
        CITY: "KILJAVA",
      },
      {
        CITY: "KIMITO",
      },
      {
        CITY: "KINNULA",
      },
      {
        CITY: "KIRJALA",
      },
      {
        CITY: "KIRKKONUMMI",
      },
      {
        CITY: "KISKO",
      },
      {
        CITY: "KITEE",
      },
      {
        CITY: "KIURUVESI",
      },
      {
        CITY: "KIVIJAERVI",
      },
      {
        CITY: "KLAUKKALA",
      },
      {
        CITY: "KOKKOLA",
      },
      {
        CITY: "KOKKOLA",
      },
      {
        CITY: "KOLARI",
      },
      {
        CITY: "KOLINKYLAE",
      },
      {
        CITY: "KOLJONVIRTA",
      },
      {
        CITY: "KONTIOLAHTI",
      },
      {
        CITY: "KONTIOMAEKI",
      },
      {
        CITY: "KOPSA",
      },
      {
        CITY: "KORIA",
      },
      {
        CITY: "KORMU",
      },
      {
        CITY: "KORPLAX",
      },
      {
        CITY: "KORPPOO",
      },
      {
        CITY: "KORSHOLM",
      },
      {
        CITY: "KOSKENKORVA",
      },
      {
        CITY: "KOSKIOINEN",
      },
      {
        CITY: "KOTKA",
      },
      {
        CITY: "KOUVOLA",
      },
      {
        CITY: "KRONOBY",
      },
      {
        CITY: "KRUUSILA",
      },
      {
        CITY: "KUHMALAHTI",
      },
      {
        CITY: "KUHMO",
      },
      {
        CITY: "KUIVAKANGAS",
      },
      {
        CITY: "KUIVANIEMI",
      },
      {
        CITY: "KULHO",
      },
      {
        CITY: "KUNI",
      },
      {
        CITY: "KUOHU",
      },
      {
        CITY: "KUOPIO",
      },
      {
        CITY: "KUORTANE",
      },
      {
        CITY: "KURIKKA",
      },
      {
        CITY: "KUUSAMO",
      },
      {
        CITY: "KUUSANKOSKI",
      },
      {
        CITY: "KUUSISTO",
      },
      {
        CITY: "KVEVLAX",
      },
      {
        CITY: "KYLMAEKOSKI",
      },
      {
        CITY: "KYROESKOSKI",
      },
      {
        CITY: "KYYNAEROE",
      },
      {
        CITY: "LAEYLIAEINEN",
      },
      {
        CITY: "LAHELA",
      },
      {
        CITY: "LAHTI",
      },
      {
        CITY: "LAIHIA",
      },
      {
        CITY: "LAIKKO",
      },
      {
        CITY: "LAITIKKALA",
      },
      {
        CITY: "LAITILA",
      },
      {
        CITY: "LAMMI",
      },
      {
        CITY: "LAMPINSAARI",
      },
      {
        CITY: "LAPINLAHTI",
      },
      {
        CITY: "LAPPAJAERVI",
      },
      {
        CITY: "LAPPEENRANTA",
      },
      {
        CITY: "LAPUA",
      },
      {
        CITY: "LARSMO",
      },
      {
        CITY: "LATOVAINIO",
      },
      {
        CITY: "LAUKAA",
      },
      {
        CITY: "LAUKKOSKI",
      },
      {
        CITY: "LAUNONEN",
      },
      {
        CITY: "LAUTIOSAARI",
      },
      {
        CITY: "LEHMO",
      },
      {
        CITY: "LEHTIMAEKI",
      },
      {
        CITY: "LEMI",
      },
      {
        CITY: "LEPAA",
      },
      {
        CITY: "LEPPLAX",
      },
      {
        CITY: "LEVANTO",
      },
      {
        CITY: "LIEKSA",
      },
      {
        CITY: "LIELAX",
      },
      {
        CITY: "LIETO",
      },
      {
        CITY: "LIEVESTUORE",
      },
      {
        CITY: "LILJENDAL",
      },
      {
        CITY: "LIMINKA",
      },
      {
        CITY: "LINNA",
      },
      {
        CITY: "LINNAVUORI",
      },
      {
        CITY: "LIPERI",
      },
      {
        CITY: "LITTOINEN",
      },
      {
        CITY: "LOHJA",
      },
      {
        CITY: "LOHTAJA",
      },
      {
        CITY: "LOIMAA",
      },
      {
        CITY: "LOPPI",
      },
      {
        CITY: "LOUHI",
      },
      {
        CITY: "LOVIISA",
      },
      {
        CITY: "LUMIJOKI",
      },
      {
        CITY: "LUOHUA",
      },
      {
        CITY: "LUOMA-AHO",
      },
      {
        CITY: "MAENTTAE",
      },
      {
        CITY: "MAENTYHARJU",
      },
      {
        CITY: "MAJVIK",
      },
      {
        CITY: "MANKILA",
      },
      {
        CITY: "MANTILA",
      },
      {
        CITY: "MARTENSBY",
      },
      {
        CITY: "MARTTILA",
      },
      {
        CITY: "MASALA",
      },
      {
        CITY: "MASKU",
      },
      {
        CITY: "MATTILA",
      },
      {
        CITY: "MEHTAEKYLAE",
      },
      {
        CITY: "MERIMASKU",
      },
      {
        CITY: "METSAEKANSA",
      },
      {
        CITY: "MIETO",
      },
      {
        CITY: "MIKKELI",
      },
      {
        CITY: "MOUHIJAERVI",
      },
      {
        CITY: "MUHOS",
      },
      {
        CITY: "MULTIA",
      },
      {
        CITY: "MUNSALA",
      },
      {
        CITY: "MUONIO",
      },
      {
        CITY: "MUURAME",
      },
      {
        CITY: "MUURLA",
      },
      {
        CITY: "MYLLYKOSKI",
      },
      {
        CITY: "MYNAEMAEKI",
      },
      {
        CITY: "NAANTALI",
      },
      {
        CITY: "NAERVIJOKI",
      },
      {
        CITY: "NAHKELA",
      },
      {
        CITY: "NAKKILA",
      },
      {
        CITY: "NARVA",
      },
      {
        CITY: "NASTOLA",
      },
      {
        CITY: "NEUVOTON",
      },
      {
        CITY: "NIINIJOKI",
      },
      {
        CITY: "NIITTYLAHTI",
      },
      {
        CITY: "NIVALA",
      },
      {
        CITY: "NOKIA",
      },
      {
        CITY: "NOORMARKKU",
      },
      {
        CITY: "NOUSIAINEN",
      },
      {
        CITY: "NUKARI",
      },
      {
        CITY: "NUMMELA",
      },
      {
        CITY: "NUMMENKYLAE",
      },
      {
        CITY: "NUMMENKYLAE",
      },
      {
        CITY: "NUMMI",
      },
      {
        CITY: "NUNNANLAHTI",
      },
      {
        CITY: "NUPPULINNA",
      },
      {
        CITY: "NURMES",
      },
      {
        CITY: "NURMO",
      },
      {
        CITY: "NUUTAJAERVI",
      },
      {
        CITY: "NYKARLEBY",
      },
      {
        CITY: "OEVERMALAX",
      },
      {
        CITY: "OITTI",
      },
      {
        CITY: "OJA",
      },
      {
        CITY: "OKSKOSKI",
      },
      {
        CITY: "ORIMATTILA",
      },
      {
        CITY: "ORIVESI",
      },
      {
        CITY: "OTALAMPI",
      },
      {
        CITY: "OULAINEN",
      },
      {
        CITY: "OULU",
      },
      {
        CITY: "OULUNSALO",
      },
      {
        CITY: "OUTOKUMPU",
      },
      {
        CITY: "PAALIJAERVI",
      },
      {
        CITY: "PAATTINEN",
      },
      {
        CITY: "PADASJOKI",
      },
      {
        CITY: "PAELKAENE",
      },
      {
        CITY: "PAIMIO",
      },
      {
        CITY: "PAIPPINEN",
      },
      {
        CITY: "PALOJOKI",
      },
      {
        CITY: "PALOKKA",
      },
      {
        CITY: "PANKAKOSKI",
      },
      {
        CITY: "PARGAS",
      },
      {
        CITY: "PARKANO",
      },
      {
        CITY: "PARKKUU",
      },
      {
        CITY: "PATTIJOKI",
      },
      {
        CITY: "PEIPOHJA",
      },
      {
        CITY: "PELLINKI",
      },
      {
        CITY: "PELLO",
      },
      {
        CITY: "PERAELAE",
      },
      {
        CITY: "PERASEINAJOKI",
      },
      {
        CITY: "PERNIOE",
      },
      {
        CITY: "PERTTELI",
      },
      {
        CITY: "PERTTULA",
      },
      {
        CITY: "PIHTIPUDAS",
      },
      {
        CITY: "PIISPANRISTI",
      },
      {
        CITY: "PILKANMAA",
      },
      {
        CITY: "PIRKKALA",
      },
      {
        CITY: "PJELAX",
      },
      {
        CITY: "POCKAR",
      },
      {
        CITY: "POEYTYAE",
      },
      {
        CITY: "POHJOLA",
      },
      {
        CITY: "POIKELUS",
      },
      {
        CITY: "POITSILA",
      },
      {
        CITY: "PORI",
      },
      {
        CITY: "PORLAMMI",
      },
      {
        CITY: "PORNAINEN",
      },
      {
        CITY: "PORVOO",
      },
      {
        CITY: "PUKKILA",
      },
      {
        CITY: "PUNKAHARJU",
      },
      {
        CITY: "PUNKALAIDUN",
      },
      {
        CITY: "PUOLANKA",
      },
      {
        CITY: "PURMO",
      },
      {
        CITY: "PUROLA",
      },
      {
        CITY: "PUSULA",
      },
      {
        CITY: "PUUMALA",
      },
      {
        CITY: "PYHAEJOKI",
      },
      {
        CITY: "PYHAENTAE",
      },
      {
        CITY: "PYHAMAA",
      },
      {
        CITY: "RAAHE",
      },
      {
        CITY: "RAISIO",
      },
      {
        CITY: "RANTASALMI",
      },
      {
        CITY: "RASEBORG",
      },
      {
        CITY: "RAUMA",
      },
      {
        CITY: "RAUTALAMPI",
      },
      {
        CITY: "RAUTJAERVI",
      },
      {
        CITY: "RAUTJAERVI",
      },
      {
        CITY: "RAVIJOKI",
      },
      {
        CITY: "REVONLAHTI",
      },
      {
        CITY: "RIIHIKOSKI",
      },
      {
        CITY: "RIISTAVESI",
      },
      {
        CITY: "RISTIINA",
      },
      {
        CITY: "ROEYKKAE",
      },
      {
        CITY: "ROISMALA",
      },
      {
        CITY: "ROVANIEMI",
      },
      {
        CITY: "RUOKOLAHTI",
      },
      {
        CITY: "RUOVESI",
      },
      {
        CITY: "RUSKO",
      },
      {
        CITY: "RUTO",
      },
      {
        CITY: "RUUTANA",
      },
      {
        CITY: "RYTTYLAE",
      },
      {
        CITY: "SAAKSMAKI",
      },
      {
        CITY: "SAARIJAERVI",
      },
      {
        CITY: "SAEKYLAE",
      },
      {
        CITY: "SAEVIAE",
      },
      {
        CITY: "SAHALAHTI",
      },
      {
        CITY: "SAJANIEMI",
      },
      {
        CITY: "SALO",
      },
      {
        CITY: "SALOINEN",
      },
      {
        CITY: "SALOMAA",
      },
      {
        CITY: "SAMMALJOKI",
      },
      {
        CITY: "SAPPEE",
      },
      {
        CITY: "SAUKKOLA",
      },
      {
        CITY: "SAUNALAHTI",
      },
      {
        CITY: "SAUVO",
      },
      {
        CITY: "SAVITAIPALE",
      },
      {
        CITY: "SAVONLINNA",
      },
      {
        CITY: "SAYNATSALO",
      },
      {
        CITY: "SEINAEJOKI",
      },
      {
        CITY: "SIEVI",
      },
      {
        CITY: "SIIKAINEN",
      },
      {
        CITY: "SIIVIKKALA",
      },
      {
        CITY: "SIMONIEMI",
      },
      {
        CITY: "SIMPELE",
      },
      {
        CITY: "SIPOO",
      },
      {
        CITY: "SIUNTIO",
      },
      {
        CITY: "SIURO",
      },
      {
        CITY: "SKINNARBY",
      },
      {
        CITY: "SKURU",
      },
      {
        CITY: "SOEDERKULLA",
      },
      {
        CITY: "SOEOERMARKKU",
      },
      {
        CITY: "SOLBERG",
      },
      {
        CITY: "SOMERNIEMI",
      },
      {
        CITY: "SOMERO",
      },
      {
        CITY: "SOTKAMO",
      },
      {
        CITY: "SOTKUMA",
      },
      {
        CITY: "SUHMURA",
      },
      {
        CITY: "SUNDBY",
      },
      {
        CITY: "SUNDOM",
      },
      {
        CITY: "SUOLAHTI",
      },
      {
        CITY: "SUOMUSJAERVI",
      },
      {
        CITY: "SUOMUSSALMI",
      },
      {
        CITY: "SUONENJOKI",
      },
      {
        CITY: "SVARTA",
      },
      {
        CITY: "SYKAERAEINEN",
      },
      {
        CITY: "TAAVETTI",
      },
      {
        CITY: "TAIPALSAARI",
      },
      {
        CITY: "TAMMELA",
      },
      {
        CITY: "TAMPELLA",
      },
      {
        CITY: "TAMPERE",
      },
      {
        CITY: "TARTTILA",
      },
      {
        CITY: "TARVASJOKI",
      },
      {
        CITY: "TENALA",
      },
      {
        CITY: "TERJAERV",
      },
      {
        CITY: "TERVAJOKI",
      },
      {
        CITY: "TERVAKOSKI",
      },
      {
        CITY: "TERVALAMPI",
      },
      {
        CITY: "TEUVA",
      },
      {
        CITY: "TIKKAKOSKI",
      },
      {
        CITY: "TOELBY",
      },
      {
        CITY: "TOHOLAMPI",
      },
      {
        CITY: "TOIJALA",
      },
      {
        CITY: "TOIVALA",
      },
      {
        CITY: "TOLKIS",
      },
      {
        CITY: "TORNIO",
      },
      {
        CITY: "TORTINMAEKI",
      },
      {
        CITY: "TOTTIJARVI",
      },
      {
        CITY: "TUOMIKYLAE",
      },
      {
        CITY: "TURENKI",
      },
      {
        CITY: "TURKU",
      },
      {
        CITY: "TUULOS",
      },
      {
        CITY: "TUURI",
      },
      {
        CITY: "TUUSULA",
      },
      {
        CITY: "TYRNAEVAE",
      },
      {
        CITY: "UIMAHARJU",
      },
      {
        CITY: "ULVILA",
      },
      {
        CITY: "UTSJOKI",
      },
      {
        CITY: "UTTI",
      },
      {
        CITY: "UURAINEN",
      },
      {
        CITY: "UUSIKAUPUNKI",
      },
      {
        CITY: "VAAJAKOSKI",
      },
      {
        CITY: "VAASA",
      },
      {
        CITY: "VAEAEKSY",
      },
      {
        CITY: "VAESTERSKOG",
      },
      {
        CITY: "VAHTO",
      },
      {
        CITY: "VAKKOLA",
      },
      {
        CITY: "VALKAMA",
      },
      {
        CITY: "VALKEAKOSKI",
      },
      {
        CITY: "VALKEALA",
      },
      {
        CITY: "VALKOLA",
      },
      {
        CITY: "VAMMALA",
      },
      {
        CITY: "VANHALINNA",
      },
      {
        CITY: "VANTAA",
      },
      {
        CITY: "VARKAUS",
      },
      {
        CITY: "VASARAINEN",
      },
      {
        CITY: "VASKIO",
      },
      {
        CITY: "VAULAMMI",
      },
      {
        CITY: "VEHNIAE",
      },
      {
        CITY: "VEIKKOLA",
      },
      {
        CITY: "VENETHEITTO",
      },
      {
        CITY: "VESANKA",
      },
      {
        CITY: "VETELI",
      },
      {
        CITY: "VIANTIE",
      },
      {
        CITY: "VIHTAVUORI",
      },
      {
        CITY: "VIHTI",
      },
      {
        CITY: "VIIALA",
      },
      {
        CITY: "VIITASAARI",
      },
      {
        CITY: "VILJAKKALA",
      },
      {
        CITY: "VILPPULA",
      },
      {
        CITY: "VIMPELI",
      },
      {
        CITY: "VIRRAT",
      },
      {
        CITY: "VUOKATTI",
      },
      {
        CITY: "YLAEMYLLY",
      },
      {
        CITY: "YLIHAERMAE",
      },
      {
        CITY: "YLITORNIO",
      },
      {
        CITY: "YLIVIESKA",
      },
      {
        CITY: "YPAEJAE",
      },
    ],
  },
  {
    COUNTRY: "FRANCE",
    CODE: "FR",
    CITIES: [
      {
        CITY: "AAST",
      },
      {
        CITY: "ABANCOURT",
      },
      {
        CITY: "ABBANS-DESSUS",
      },
      {
        CITY: "ABBARETZ",
      },
      {
        CITY: "ABBECOURT",
      },
      {
        CITY: "ABBEVILLE",
      },
      {
        CITY: "ABBEVILLE-LES-CONFLANS",
      },
      {
        CITY: "ABEILHAN",
      },
      {
        CITY: "ABERE",
      },
      {
        CITY: "ABERGEMENT-SAINT-JEAN",
      },
      {
        CITY: "ABILLY",
      },
      {
        CITY: "ABLAIN-SAINT-NAZAIRE",
      },
      {
        CITY: "ABLAINCOURT-PRESSOIR",
      },
      {
        CITY: "ABLAINZEVELLE",
      },
      {
        CITY: "ABLIS",
      },
      {
        CITY: "ABLON-SUR-SEINE",
      },
      {
        CITY: "ABONDANCE",
      },
      {
        CITY: "ABONDANT",
      },
      {
        CITY: "ABRESCHVILLER",
      },
      {
        CITY: "ABREST",
      },
      {
        CITY: "ABSCON",
      },
      {
        CITY: "ABZAC",
      },
      {
        CITY: "ABZAC",
      },
      {
        CITY: "ACHATEL",
      },
      {
        CITY: "ACHENHEIM",
      },
      {
        CITY: "ACHERES",
      },
      {
        CITY: "ACHEVILLE",
      },
      {
        CITY: "ACHICOURT",
      },
      {
        CITY: "ACHIET-LE-GRAND",
      },
      {
        CITY: "ACHIET-LE-PETIT",
      },
      {
        CITY: "ACIGNE",
      },
      {
        CITY: "ACQ",
      },
      {
        CITY: "ACQUIGNY",
      },
      {
        CITY: "ACY-EN-MULTIEN",
      },
      {
        CITY: "ADAM-LES-VERCEL",
      },
      {
        CITY: "ADAMSWILLER",
      },
      {
        CITY: "ADELANS-ET-LE-VAL-DE-BITHAINE",
      },
      {
        CITY: "ADISSAN",
      },
      {
        CITY: "ADRIERS",
      },
      {
        CITY: "AFA",
      },
      {
        CITY: "AGAY",
      },
      {
        CITY: "AGDE",
      },
      {
        CITY: "AGEN",
      },
      {
        CITY: "AGNEAUX",
      },
      {
        CITY: "AGNETZ",
      },
      {
        CITY: "AGNIN",
      },
      {
        CITY: "AGNOS",
      },
      {
        CITY: "AGNY",
      },
      {
        CITY: "AGUILCOURT",
      },
      {
        CITY: "AHAXE-ALCIETTE-BASCASSAN",
      },
      {
        CITY: "AHUILLE",
      },
      {
        CITY: "AHUN",
      },
      {
        CITY: "AHUY",
      },
      {
        CITY: "AIFFRES",
      },
      {
        CITY: "AIGALIERS",
      },
      {
        CITY: "AIGLEMONT",
      },
      {
        CITY: "AIGNAN",
      },
      {
        CITY: "AIGNE",
      },
      {
        CITY: "AIGNY",
      },
      {
        CITY: "AIGONNAY",
      },
      {
        CITY: "AIGRE",
      },
      {
        CITY: "AIGREFEUILLE",
      },
      {
        CITY: "AIGREFEUILLE-SUR-MAINE",
      },
      {
        CITY: "AIGREMONT",
      },
      {
        CITY: "AIGREMONT",
      },
      {
        CITY: "AIGUEBELETTE-LE-LAC",
      },
      {
        CITY: "AIGUEBELLE",
      },
      {
        CITY: "AIGUEBLANCHE",
      },
      {
        CITY: "AIGUEFONDE",
      },
      {
        CITY: "AIGUEPERSE",
      },
      {
        CITY: "AIGUEPERSE",
      },
      {
        CITY: "AIGUES-MORTES",
      },
      {
        CITY: "AIGUES-VIVES",
      },
      {
        CITY: "AIGUES-VIVES",
      },
      {
        CITY: "AIGUILLON",
      },
      {
        CITY: "AILETTE",
      },
      {
        CITY: "AILLAS",
      },
      {
        CITY: "AILLEVILLERS-ET-LYAUMONT",
      },
      {
        CITY: "AILLON-LE-VIEUX",
      },
      {
        CITY: "AILLY-LE-HAUT-CLOCHER",
      },
      {
        CITY: "AILLY-SUR-NOYE",
      },
      {
        CITY: "AILLY-SUR-SOMME",
      },
      {
        CITY: "AIMARGUES",
      },
      {
        CITY: "AIME",
      },
      {
        CITY: "AINAY-LE-CHATEAU",
      },
      {
        CITY: "AINCOURT",
      },
      {
        CITY: "AINHOA",
      },
      {
        CITY: "AIRAINES",
      },
      {
        CITY: "AIRE-SUR-LA-LYS",
      },
      {
        CITY: "AIREL",
      },
      {
        CITY: "AIRVAULT",
      },
      {
        CITY: "AISEREY",
      },
      {
        CITY: "AISSEY",
      },
      {
        CITY: "AITON",
      },
      {
        CITY: "AIX-EN-ERGNY",
      },
      {
        CITY: "AIX-EN-ISSART",
      },
      {
        CITY: "AIX-EN-OTHE",
      },
      {
        CITY: "AIX-EN-PROVENCE",
      },
      {
        CITY: "AIX-LES-BAINS",
      },
      {
        CITY: "AIX-NOULETTE",
      },
      {
        CITY: "AIXE-SUR-VIENNE",
      },
      {
        CITY: "AIZENAY",
      },
      {
        CITY: "AJACCIO",
      },
      {
        CITY: "ALAIRAC",
      },
      {
        CITY: "ALATA",
      },
      {
        CITY: "ALBAN",
      },
      {
        CITY: "ALBAS",
      },
      {
        CITY: "ALBEFEUILLE-LAGARDE",
      },
      {
        CITY: "ALBENS",
      },
      {
        CITY: "ALBERT",
      },
      {
        CITY: "ALBERTVILLE",
      },
      {
        CITY: "ALBI",
      },
      {
        CITY: "ALBIAS",
      },
      {
        CITY: "ALBIGNY-SUR-SAONE",
      },
      {
        CITY: "ALBIOSC",
      },
      {
        CITY: "ALBOUSSIERE",
      },
      {
        CITY: "ALBUSSAC",
      },
      {
        CITY: "ALBY-SUR-CHERAN",
      },
      {
        CITY: "ALENYA",
      },
      {
        CITY: "ALFORTVILLE",
      },
      {
        CITY: "ALGOLSHEIM",
      },
      {
        CITY: "ALGRANGE",
      },
      {
        CITY: "ALIGNAN-DU-VENT",
      },
      {
        CITY: "ALISSAS",
      },
      {
        CITY: "ALIX",
      },
      {
        CITY: "ALIXAN",
      },
      {
        CITY: "ALIZAY",
      },
      {
        CITY: "ALLAIN",
      },
      {
        CITY: "ALLAIRE",
      },
      {
        CITY: "ALLAN",
      },
      {
        CITY: "ALLASSAC",
      },
      {
        CITY: "ALLAUCH",
      },
      {
        CITY: "ALLEGRE",
      },
      {
        CITY: "ALLEINS",
      },
      {
        CITY: "ALLEMANS-DU-DROPT",
      },
      {
        CITY: "ALLENNES-LES-MARAIS",
      },
      {
        CITY: "ALLERIOT",
      },
      {
        CITY: "ALLERY",
      },
      {
        CITY: "ALLEVARD",
      },
      {
        CITY: "ALLIANCELLES",
      },
      {
        CITY: "ALLIER",
      },
      {
        CITY: "ALLIERES",
      },
      {
        CITY: "ALLINGES",
      },
      {
        CITY: "ALLOGNY",
      },
      {
        CITY: "ALLONDRELLE-LA-MALMAISON",
      },
      {
        CITY: "ALLONNE",
      },
      {
        CITY: "ALLONNE",
      },
      {
        CITY: "ALLONNES",
      },
      {
        CITY: "ALLONNES",
      },
      {
        CITY: "ALLONVILLE",
      },
      {
        CITY: "ALLONZIER-LA-CAILLE",
      },
      {
        CITY: "ALLOS",
      },
      {
        CITY: "ALLOUAGNE",
      },
      {
        CITY: "ALLOUE",
      },
      {
        CITY: "ALLOUIS",
      },
      {
        CITY: "ALLOUVILLE-BELLEFOSSE",
      },
      {
        CITY: "ALLUY",
      },
      {
        CITY: "ALLUYES",
      },
      {
        CITY: "ALLY",
      },
      {
        CITY: "ALOS-SIBAS-ABENSE",
      },
      {
        CITY: "ALTECKENDORF",
      },
      {
        CITY: "ALTKIRCH",
      },
      {
        CITY: "ALTORF",
      },
      {
        CITY: "ALTVILLER",
      },
      {
        CITY: "ALVIMARE",
      },
      {
        CITY: "ALZONNE",
      },
      {
        CITY: "AMAGNEY",
      },
      {
        CITY: "AMAILLOUX",
      },
      {
        CITY: "AMANCEY",
      },
      {
        CITY: "AMANCY",
      },
      {
        CITY: "AMANGE",
      },
      {
        CITY: "AMANLIS",
      },
      {
        CITY: "AMANVILLERS",
      },
      {
        CITY: "AMATHAY-VESIGNEUX",
      },
      {
        CITY: "AMAYE-SUR-ORNE",
      },
      {
        CITY: "AMBAZAC",
      },
      {
        CITY: "AMBERAC",
      },
      {
        CITY: "AMBERIEUX",
      },
      {
        CITY: "AMBERIEUX-EN-DOMBES",
      },
      {
        CITY: "AMBERT",
      },
      {
        CITY: "AMBES",
      },
      {
        CITY: "AMBIERLE",
      },
      {
        CITY: "AMBLENY",
      },
      {
        CITY: "AMBLETEUSE",
      },
      {
        CITY: "AMBOISE",
      },
      {
        CITY: "AMBON",
      },
      {
        CITY: "AMBONNAY",
      },
      {
        CITY: "AMBRES",
      },
      {
        CITY: "AMBRIERES",
      },
      {
        CITY: "AMBRONAY",
      },
      {
        CITY: "AMBRUS",
      },
      {
        CITY: "AMBUTRIX",
      },
      {
        CITY: "AMELECOURT",
      },
      {
        CITY: "AMELIE-LES-BAINS-PALALDA",
      },
      {
        CITY: "AMEN",
      },
      {
        CITY: "AMES",
      },
      {
        CITY: "AMFREVILLE",
      },
      {
        CITY: "AMFREVILLE-LA-MI-VOIE",
      },
      {
        CITY: "AMFREVILLE-SOUS-LES-MONTS",
      },
      {
        CITY: "AMIENS",
      },
      {
        CITY: "AMIGNY",
      },
      {
        CITY: "AMIGNY-ROUY",
      },
      {
        CITY: "AMILLIS",
      },
      {
        CITY: "AMILLY",
      },
      {
        CITY: "AMMERSCHWIHR",
      },
      {
        CITY: "AMMERZWILLER",
      },
      {
        CITY: "AMNEVILLE",
      },
      {
        CITY: "AMOU",
      },
      {
        CITY: "AMPLEPUIS",
      },
      {
        CITY: "AMPONVILLE",
      },
      {
        CITY: "AMPUIS",
      },
      {
        CITY: "AMPUS",
      },
      {
        CITY: "AMURE",
      },
      {
        CITY: "ANAIS",
      },
      {
        CITY: "ANCE",
      },
      {
        CITY: "ANCEAUMEVILLE",
      },
      {
        CITY: "ANCEMONT",
      },
      {
        CITY: "ANCENIS",
      },
      {
        CITY: "ANCERVILLE",
      },
      {
        CITY: "ANCHE",
      },
      {
        CITY: "ANCHENONCOURT-ET-CHAZEL",
      },
      {
        CITY: "ANCINNES",
      },
      {
        CITY: "ANCIZAN",
      },
      {
        CITY: "ANCONE",
      },
      {
        CITY: "ANCY",
      },
      {
        CITY: "ANCY-LE-FRANC",
      },
      {
        CITY: "ANCY-SUR-MOSELLE",
      },
      {
        CITY: "ANDANCE",
      },
      {
        CITY: "ANDANCETTE",
      },
      {
        CITY: "ANDARD",
      },
      {
        CITY: "ANDE",
      },
      {
        CITY: "ANDECHY",
      },
      {
        CITY: "ANDEL",
      },
      {
        CITY: "ANDELOT-MORVAL",
      },
      {
        CITY: "ANDERNOS-LES-BAINS",
      },
      {
        CITY: "ANDEVILLE",
      },
      {
        CITY: "ANDIGNE",
      },
      {
        CITY: "ANDILLY",
      },
      {
        CITY: "ANDILLY",
      },
      {
        CITY: "ANDILLY",
      },
      {
        CITY: "ANDOINS",
      },
      {
        CITY: "ANDOLSHEIM",
      },
      {
        CITY: "ANDON",
      },
      {
        CITY: "ANDONVILLE",
      },
      {
        CITY: "ANDOUILLE",
      },
      {
        CITY: "ANDOUILLE-NEUVILLE",
      },
      {
        CITY: "ANDRES",
      },
      {
        CITY: "ANDREST",
      },
      {
        CITY: "ANDRESY",
      },
      {
        CITY: "ANDREZE",
      },
      {
        CITY: "ANDREZIEUX-BOUTHEON",
      },
      {
        CITY: "ANDUZE",
      },
      {
        CITY: "ANET",
      },
      {
        CITY: "ANETZ",
      },
      {
        CITY: "ANGE",
      },
      {
        CITY: "ANGEAC-CHAMPAGNE",
      },
      {
        CITY: "ANGEAC-CHARENTE",
      },
      {
        CITY: "ANGELY",
      },
      {
        CITY: "ANGERS",
      },
      {
        CITY: "ANGERVILLE-LA-MARTEL",
      },
      {
        CITY: "ANGERVILLIERS",
      },
      {
        CITY: "ANGEVILLERS",
      },
      {
        CITY: "ANGICOURT",
      },
      {
        CITY: "ANGIENS",
      },
      {
        CITY: "ANGLADE",
      },
      {
        CITY: "ANGLEFORT",
      },
      {
        CITY: "ANGLES",
      },
      {
        CITY: "ANGLET",
      },
      {
        CITY: "ANGLIERS",
      },
      {
        CITY: "ANGLURE",
      },
      {
        CITY: "ANGOULINS",
      },
      {
        CITY: "ANGOUME",
      },
      {
        CITY: "ANGOVILLE-SUR-AY",
      },
      {
        CITY: "ANGRES",
      },
      {
        CITY: "ANGUERNY",
      },
      {
        CITY: "ANGY",
      },
      {
        CITY: "ANHAUX",
      },
      {
        CITY: "ANHIERS",
      },
      {
        CITY: "ANIANE",
      },
      {
        CITY: "ANICHE",
      },
      {
        CITY: "ANIZY-LE-CHATEAU",
      },
      {
        CITY: "ANJOU",
      },
      {
        CITY: "ANJOUTEY",
      },
      {
        CITY: "ANLHIAC",
      },
      {
        CITY: "ANNAY",
      },
      {
        CITY: "ANNAY-SUR-SEREIN",
      },
      {
        CITY: "ANNEBAULT",
      },
      {
        CITY: "ANNECY",
      },
      {
        CITY: "ANNECY-LE-VIEUX",
      },
      {
        CITY: "ANNEMASSE",
      },
      {
        CITY: "ANNEPONT",
      },
      {
        CITY: "ANNEQUIN",
      },
      {
        CITY: "ANNESSE-ET-BEAULIEU",
      },
      {
        CITY: "ANNET-SUR-MARNE",
      },
      {
        CITY: "ANNEVILLE-AMBOURVILLE",
      },
      {
        CITY: "ANNEYRON",
      },
      {
        CITY: "ANNEZAY",
      },
      {
        CITY: "ANNEZIN",
      },
      {
        CITY: "ANNOEULLIN",
      },
      {
        CITY: "ANNOIS",
      },
      {
        CITY: "ANNONAY",
      },
      {
        CITY: "ANNOT",
      },
      {
        CITY: "ANNOUVILLE-VILMESNIL",
      },
      {
        CITY: "ANOR",
      },
      {
        CITY: "ANOULD",
      },
      {
        CITY: "ANSAC-SUR-VIENNE",
      },
      {
        CITY: "ANSAUVILLERS",
      },
      {
        CITY: "ANSE",
      },
      {
        CITY: "ANSOUIS",
      },
      {
        CITY: "ANTAGNAC",
      },
      {
        CITY: "ANTEUIL",
      },
      {
        CITY: "ANTHE",
      },
      {
        CITY: "ANTHEUIL-PORTES",
      },
      {
        CITY: "ANTHON",
      },
      {
        CITY: "ANTHY-SUR-LEMAN",
      },
      {
        CITY: "ANTIBES",
      },
      {
        CITY: "ANTIGNAC",
      },
      {
        CITY: "ANTIGNAC",
      },
      {
        CITY: "ANTIGNY",
      },
      {
        CITY: "ANTILLY",
      },
      {
        CITY: "ANTILLY",
      },
      {
        CITY: "ANTOGNY LE TILLAC",
      },
      {
        CITY: "ANTONY",
      },
      {
        CITY: "ANTRAIN",
      },
      {
        CITY: "ANZAT-LE-LUGUET",
      },
      {
        CITY: "ANZIN",
      },
      {
        CITY: "ANZIN-SAINT-AUBIN",
      },
      {
        CITY: "ANZY-LE-DUC",
      },
      {
        CITY: "AOSTE",
      },
      {
        CITY: "AOUGNY",
      },
      {
        CITY: "AOUSTE-SUR-SYE",
      },
      {
        CITY: "APACH",
      },
      {
        CITY: "APCHER",
      },
      {
        CITY: "APCHER",
      },
      {
        CITY: "APPENWIHR",
      },
      {
        CITY: "APPEVILLE",
      },
      {
        CITY: "APPEVILLE-ANNEBAULT",
      },
      {
        CITY: "APPIETTO",
      },
      {
        CITY: "APPOIGNY",
      },
      {
        CITY: "APPRIEU",
      },
      {
        CITY: "APREMONT",
      },
      {
        CITY: "APREMONT",
      },
      {
        CITY: "APT",
      },
      {
        CITY: "ARACHES-LA-FRASSE",
      },
      {
        CITY: "ARAGON",
      },
      {
        CITY: "ARAMITS",
      },
      {
        CITY: "ARAMON",
      },
      {
        CITY: "ARANCOU",
      },
      {
        CITY: "ARBENT",
      },
      {
        CITY: "ARBERATS-SILLEGUE",
      },
      {
        CITY: "ARBIN",
      },
      {
        CITY: "ARBOIS",
      },
      {
        CITY: "ARBONNE",
      },
      {
        CITY: "ARBOUCAVE",
      },
      {
        CITY: "ARBUS",
      },
      {
        CITY: "ARBUSIGNY",
      },
      {
        CITY: "ARC-ET-SENANS",
      },
      {
        CITY: "ARC-SUR-TILLE",
      },
      {
        CITY: "ARCACHON",
      },
      {
        CITY: "ARCAY",
      },
      {
        CITY: "ARCENS",
      },
      {
        CITY: "ARCES",
      },
      {
        CITY: "ARCHAMPS",
      },
      {
        CITY: "ARCHE",
      },
      {
        CITY: "ARCHES",
      },
      {
        CITY: "ARCHETTES",
      },
      {
        CITY: "ARCHIAC",
      },
      {
        CITY: "ARCHINGEAY",
      },
      {
        CITY: "ARCINE",
      },
      {
        CITY: "ARCIS-SUR-AUBE",
      },
      {
        CITY: "ARCON",
      },
      {
        CITY: "ARCON",
      },
      {
        CITY: "ARCUEIL",
      },
      {
        CITY: "ARCY-SAINTE-RESTITUE",
      },
      {
        CITY: "ARDENAY-SUR-MERIZE",
      },
      {
        CITY: "ARDENTES",
      },
      {
        CITY: "ARDES",
      },
      {
        CITY: "ARDIEGE",
      },
      {
        CITY: "ARDILLIERES",
      },
      {
        CITY: "ARDOIX",
      },
      {
        CITY: "ARDON",
      },
      {
        CITY: "ARDRES",
      },
      {
        CITY: "AREINES",
      },
      {
        CITY: "ARENGOSSE",
      },
      {
        CITY: "ARENTHON",
      },
      {
        CITY: "ARES",
      },
      {
        CITY: "ARESSY",
      },
      {
        CITY: "ARETTE",
      },
      {
        CITY: "ARGANCY",
      },
      {
        CITY: "ARGELERS",
      },
      {
        CITY: "ARGELES-GAZOST",
      },
      {
        CITY: "ARGELIERS",
      },
      {
        CITY: "ARGELLIERS",
      },
      {
        CITY: "ARGENCES",
      },
      {
        CITY: "ARGENS",
      },
      {
        CITY: "ARGENT-SUR-SAULDRE",
      },
      {
        CITY: "ARGENTAN",
      },
      {
        CITY: "ARGENTAT",
      },
      {
        CITY: "ARGENTEUIL",
      },
      {
        CITY: "ARGENTIERE",
      },
      {
        CITY: "ARGENTINE",
      },
      {
        CITY: "ARGENTON-LES-VALLEES",
      },
      {
        CITY: "ARGENTON-SUR-CREUSE",
      },
      {
        CITY: "ARGENTRE",
      },
      {
        CITY: "ARGENTRE-DU-PLESSIS",
      },
      {
        CITY: "ARGEVILLE",
      },
      {
        CITY: "ARGOL",
      },
      {
        CITY: "ARGUEIL",
      },
      {
        CITY: "ARINTHOD",
      },
      {
        CITY: "ARLANC",
      },
      {
        CITY: "ARLAY",
      },
      {
        CITY: "ARLEBOSC",
      },
      {
        CITY: "ARLES",
      },
      {
        CITY: "ARLES",
      },
      {
        CITY: "ARLEUX",
      },
      {
        CITY: "ARLEUX-EN-GOHELLE",
      },
      {
        CITY: "ARMAUCOURT",
      },
      {
        CITY: "ARMBOUTS-CAPPEL",
      },
      {
        CITY: "ARMENONVILLE-LES-GATINEAUX",
      },
      {
        CITY: "ARMES",
      },
      {
        CITY: "ARMOY",
      },
      {
        CITY: "ARNAC-POMPADOUR",
      },
      {
        CITY: "ARNAGE",
      },
      {
        CITY: "ARNAS",
      },
      {
        CITY: "ARNAY-LE-DUC",
      },
      {
        CITY: "ARNIERES-SUR-ITON",
      },
      {
        CITY: "ARNOUVILLE",
      },
      {
        CITY: "ARNOUVILLE-LES-MANTES",
      },
      {
        CITY: "ARON",
      },
      {
        CITY: "ARPAILLARGUES-ET-AUREILLAC",
      },
      {
        CITY: "ARPAJON",
      },
      {
        CITY: "ARPAJON-SUR-CERE",
      },
      {
        CITY: "ARPENANS",
      },
      {
        CITY: "ARPHEUILLES-SAINT-PRIEST",
      },
      {
        CITY: "ARQUES",
      },
      {
        CITY: "ARQUES-LA-BATAILLE",
      },
      {
        CITY: "ARQUETTES-EN-VAL",
      },
      {
        CITY: "ARRABLOY",
      },
      {
        CITY: "ARRADON",
      },
      {
        CITY: "ARRAS",
      },
      {
        CITY: "ARRAUTE-CHARRITTE",
      },
      {
        CITY: "ARREAU",
      },
      {
        CITY: "ARRENES",
      },
      {
        CITY: "ARRIGAS",
      },
      {
        CITY: "ARRIGNY",
      },
      {
        CITY: "ARROU",
      },
      {
        CITY: "ARS",
      },
      {
        CITY: "ARS-EN-RE",
      },
      {
        CITY: "ARS-LAQUENEXY",
      },
      {
        CITY: "ARS-SUR-FORMANS",
      },
      {
        CITY: "ARS-SUR-MOSELLE",
      },
      {
        CITY: "ARSAC",
      },
      {
        CITY: "ARSAC-EN-VELAY",
      },
      {
        CITY: "ARSAGUE",
      },
      {
        CITY: "ARTANNES-SUR-INDRE",
      },
      {
        CITY: "ARTAS",
      },
      {
        CITY: "ARTENAY",
      },
      {
        CITY: "ARTHAZ-PONT-NOTRE-DAME",
      },
      {
        CITY: "ARTHES",
      },
      {
        CITY: "ARTHEZ-DE-BEARN",
      },
      {
        CITY: "ARTHON",
      },
      {
        CITY: "ARTHON-EN-RETZ",
      },
      {
        CITY: "ARTIGAT",
      },
      {
        CITY: "ARTIGUELOUTAN",
      },
      {
        CITY: "ARTIGUELOUVE",
      },
      {
        CITY: "ARTIGUES",
      },
      {
        CITY: "ARTIGUES-PRES-BORDEAUX",
      },
      {
        CITY: "ARTIX",
      },
      {
        CITY: "ARTOLSHEIM",
      },
      {
        CITY: "ARUDY",
      },
      {
        CITY: "ARVERT",
      },
      {
        CITY: "ARVEYRES",
      },
      {
        CITY: "ARVILLARD",
      },
      {
        CITY: "ARVILLE",
      },
      {
        CITY: "ARVILLERS",
      },
      {
        CITY: "ARX",
      },
      {
        CITY: "ARZACQ-ARRAZIGUET",
      },
      {
        CITY: "ARZAL",
      },
      {
        CITY: "ARZANO",
      },
      {
        CITY: "ARZON",
      },
      {
        CITY: "ASASP-ARROS",
      },
      {
        CITY: "ASCAIN",
      },
      {
        CITY: "ASCHERES-LE-MARCHE",
      },
      {
        CITY: "ASCOUX",
      },
      {
        CITY: "ASCQ",
      },
      {
        CITY: "ASLONNES",
      },
      {
        CITY: "ASNANS-BEAUVOISIN",
      },
      {
        CITY: "ASNIERES",
      },
      {
        CITY: "ASNIERES-SUR-OISE",
      },
      {
        CITY: "ASNIERES-SUR-SEINE",
      },
      {
        CITY: "ASPACH-LE-BAS",
      },
      {
        CITY: "ASPACH-LE-HAUT",
      },
      {
        CITY: "ASPERES",
      },
      {
        CITY: "ASPET",
      },
      {
        CITY: "ASPREMONT",
      },
      {
        CITY: "ASQUE",
      },
      {
        CITY: "ASSAS",
      },
      {
        CITY: "ASSAT",
      },
      {
        CITY: "ASSAY",
      },
      {
        CITY: "ASSEVENT",
      },
      {
        CITY: "ASSIER",
      },
      {
        CITY: "ASSIGNAN",
      },
      {
        CITY: "ASSON",
      },
      {
        CITY: "ASTAFFORT",
      },
      {
        CITY: "ASTILLE",
      },
      {
        CITY: "ASTIS",
      },
      {
        CITY: "ATHEE",
      },
      {
        CITY: "ATHESANS-ETROITEFONTAINE",
      },
      {
        CITY: "ATHIES",
      },
      {
        CITY: "ATHIES-SOUS-LAON",
      },
      {
        CITY: "ATHIS-MONS",
      },
      {
        CITY: "ATTENSCHWILLER",
      },
      {
        CITY: "ATTICHES",
      },
      {
        CITY: "ATTICHY",
      },
      {
        CITY: "ATTIGNAT-ONCIN",
      },
      {
        CITY: "ATTIGNY",
      },
      {
        CITY: "ATTON",
      },
      {
        CITY: "ATUR",
      },
      {
        CITY: "AUBAGNE",
      },
      {
        CITY: "AUBAIS",
      },
      {
        CITY: "AUBAS",
      },
      {
        CITY: "AUBENAS",
      },
      {
        CITY: "AUBERCHICOURT",
      },
      {
        CITY: "AUBERGENVILLE",
      },
      {
        CITY: "AUBERIVES-SUR-VAREZE",
      },
      {
        CITY: "AUBERS",
      },
      {
        CITY: "AUBERVILLE-LA-CAMPAGNE",
      },
      {
        CITY: "AUBERVILLE-LA-RENAULT",
      },
      {
        CITY: "AUBERVILLIERS",
      },
      {
        CITY: "AUBEVOYE",
      },
      {
        CITY: "AUBIAC",
      },
      {
        CITY: "AUBIE-ET-ESPESSAS",
      },
      {
        CITY: "AUBIERE",
      },
      {
        CITY: "AUBIET",
      },
      {
        CITY: "AUBIGNAN",
      },
      {
        CITY: "AUBIGNE",
      },
      {
        CITY: "AUBIGNE-RACAN",
      },
      {
        CITY: "AUBIGNEY",
      },
      {
        CITY: "AUBIGNY",
      },
      {
        CITY: "AUBIGNY-AU-BAC",
      },
      {
        CITY: "AUBIGNY-EN-ARTOIS",
      },
      {
        CITY: "AUBIGNY-EN-LAONNOIS",
      },
      {
        CITY: "AUBIGNY-EN-PLAINE",
      },
      {
        CITY: "AUBIN",
      },
      {
        CITY: "AUBIN",
      },
      {
        CITY: "AUBIN-SAINT-VAAST",
      },
      {
        CITY: "AUBINGES",
      },
      {
        CITY: "AUBORD",
      },
      {
        CITY: "AUBOUE",
      },
      {
        CITY: "AUBRAC",
      },
      {
        CITY: "AUBRIVES",
      },
      {
        CITY: "AUBRY-DU-HAINAUT",
      },
      {
        CITY: "AUBUSSON",
      },
      {
        CITY: "AUBY",
      },
      {
        CITY: "AUCAMVILLE",
      },
      {
        CITY: "AUCH",
      },
      {
        CITY: "AUCHEL",
      },
      {
        CITY: "AUCHY-LES-HESDIN",
      },
      {
        CITY: "AUCHY-LES-MINES",
      },
      {
        CITY: "AUCHY-LEZ-ORCHIES",
      },
      {
        CITY: "AUDELANGE",
      },
      {
        CITY: "AUDENGE",
      },
      {
        CITY: "AUDES",
      },
      {
        CITY: "AUDIERNE",
      },
      {
        CITY: "AUDIGNIES",
      },
      {
        CITY: "AUDINCOURT",
      },
      {
        CITY: "AUDINCTHUN",
      },
      {
        CITY: "AUDRUICQ",
      },
      {
        CITY: "AUDUN-LE-ROMAN",
      },
      {
        CITY: "AUDUN-LE-TICHE",
      },
      {
        CITY: "AUFFARGIS",
      },
      {
        CITY: "AUFFAY",
      },
      {
        CITY: "AUFFREVILLE-BRASSEUIL",
      },
      {
        CITY: "AUGA",
      },
      {
        CITY: "AUGIGNAC",
      },
      {
        CITY: "AUGNY",
      },
      {
        CITY: "AULHAT-SAINT-PRIVAT",
      },
      {
        CITY: "AULNAT",
      },
      {
        CITY: "AULNAY",
      },
      {
        CITY: "AULNAY-SOUS-BOIS",
      },
      {
        CITY: "AULNAY-SUR-MARNE",
      },
      {
        CITY: "AULNAY-SUR-MAULDRE",
      },
      {
        CITY: "AULNOYE-AYMERIES",
      },
      {
        CITY: "AULT",
      },
      {
        CITY: "AUMAGNE",
      },
      {
        CITY: "AUMALE",
      },
      {
        CITY: "AUMENANCOURT",
      },
      {
        CITY: "AUMETZ",
      },
      {
        CITY: "AUMONT",
      },
      {
        CITY: "AUMONT-AUBRAC",
      },
      {
        CITY: "AUMONT-EN-HALATTE",
      },
      {
        CITY: "AUMONTZEY",
      },
      {
        CITY: "AUMUR",
      },
      {
        CITY: "AUNAY-LES-BOIS",
      },
      {
        CITY: "AUNAY-SOUS-AUNEAU",
      },
      {
        CITY: "AUNAY-SUR-ODON",
      },
      {
        CITY: "AUNEAU",
      },
      {
        CITY: "AUNEUIL",
      },
      {
        CITY: "AUPS",
      },
      {
        CITY: "AURAY",
      },
      {
        CITY: "AUREC-SUR-LOIRE",
      },
      {
        CITY: "AUREIL",
      },
      {
        CITY: "AUREILHAN",
      },
      {
        CITY: "AUREILHAN",
      },
      {
        CITY: "AURIAC",
      },
      {
        CITY: "AURIAC",
      },
      {
        CITY: "AURIAC-SUR-VENDINELLE",
      },
      {
        CITY: "AURIBEAU-SUR-SIAGNE",
      },
      {
        CITY: "AURIGNAC",
      },
      {
        CITY: "AURILLAC",
      },
      {
        CITY: "AURIOL",
      },
      {
        CITY: "AURONS",
      },
      {
        CITY: "AUSSEVIELLE",
      },
      {
        CITY: "AUSSILLON",
      },
      {
        CITY: "AUSSONCE",
      },
      {
        CITY: "AUSSONNE",
      },
      {
        CITY: "AUTECHAUX",
      },
      {
        CITY: "AUTERIVE",
      },
      {
        CITY: "AUTERIVE",
      },
      {
        CITY: "AUTEUIL",
      },
      {
        CITY: "AUTEUIL",
      },
      {
        CITY: "AUTEVIELLE-SAINT-MARTIN-BIDEREN",
      },
      {
        CITY: "AUTHEVERNES",
      },
      {
        CITY: "AUTHIE",
      },
      {
        CITY: "AUTHIE",
      },
      {
        CITY: "AUTHON",
      },
      {
        CITY: "AUTHOU",
      },
      {
        CITY: "AUTHOUILLET",
      },
      {
        CITY: "AUTOREILLE",
      },
      {
        CITY: "AUTRANS",
      },
      {
        CITY: "AUTRECHE",
      },
      {
        CITY: "AUTREMENCOURT",
      },
      {
        CITY: "AUTREVILLE",
      },
      {
        CITY: "AUTRY-LE-CHATEL",
      },
      {
        CITY: "AUTUN",
      },
      {
        CITY: "AUVERGNY",
      },
      {
        CITY: "AUVERS",
      },
      {
        CITY: "AUVERS-SAINT-GEORGES",
      },
      {
        CITY: "AUVERS-SUR-OISE",
      },
      {
        CITY: "AUVILLAR",
      },
      {
        CITY: "AUVILLARS",
      },
      {
        CITY: "AUVILLERS-LES-FORGES",
      },
      {
        CITY: "AUVILLIERS-EN-GATINAIS",
      },
      {
        CITY: "AUXAIS",
      },
      {
        CITY: "AUXANCES",
      },
      {
        CITY: "AUXANGE",
      },
      {
        CITY: "AUXERRE",
      },
      {
        CITY: "AUXEY-DURESSES",
      },
      {
        CITY: "AUXI-LE-CHATEAU",
      },
      {
        CITY: "AUXON",
      },
      {
        CITY: "AUXON-DESSOUS",
      },
      {
        CITY: "AUXON-DESSUS",
      },
      {
        CITY: "AUXONNE",
      },
      {
        CITY: "AUXY",
      },
      {
        CITY: "AUZANCES",
      },
      {
        CITY: "AUZEVILLE-TOLOSANE",
      },
      {
        CITY: "AUZIELLE",
      },
      {
        CITY: "AUZON",
      },
      {
        CITY: "AUZOUER-EN-TOURAINE",
      },
      {
        CITY: "AVAILLES-LIMOUZINE",
      },
      {
        CITY: "AVALLON",
      },
      {
        CITY: "AVANCON",
      },
      {
        CITY: "AVANNE-AVENEY",
      },
      {
        CITY: "AVANTON",
      },
      {
        CITY: "AVARAY",
      },
      {
        CITY: "AVEIZE",
      },
      {
        CITY: "AVEIZIEUX",
      },
      {
        CITY: "AVELIN",
      },
      {
        CITY: "AVELUY",
      },
      {
        CITY: "AVENAY",
      },
      {
        CITY: "AVENSAN",
      },
      {
        CITY: "AVENTIGNAN",
      },
      {
        CITY: "AVERDOINGT",
      },
      {
        CITY: "AVERDON",
      },
      {
        CITY: "AVERTON",
      },
      {
        CITY: "AVESNELLES",
      },
      {
        CITY: "AVESNES-EN-BRAY",
      },
      {
        CITY: "AVESNES-LE-COMTE",
      },
      {
        CITY: "AVESNES-LE-SEC",
      },
      {
        CITY: "AVESNES-LES-AUBERT",
      },
      {
        CITY: "AVESNES-SUR-HELPE",
      },
      {
        CITY: "AVEZE",
      },
      {
        CITY: "AVEZE",
      },
      {
        CITY: "AVIERNOZ",
      },
      {
        CITY: "AVIGNON",
      },
      {
        CITY: "AVIGNONET-LAURAGAIS",
      },
      {
        CITY: "AVION",
      },
      {
        CITY: "AVIRON",
      },
      {
        CITY: "AVIZE",
      },
      {
        CITY: "AVOINE",
      },
      {
        CITY: "AVON",
      },
      {
        CITY: "AVON-LA-PEZE",
      },
      {
        CITY: "AVORD",
      },
      {
        CITY: "AVOT",
      },
      {
        CITY: "AVRAINVILLE",
      },
      {
        CITY: "AVRANCHES",
      },
      {
        CITY: "AVREMESNIL",
      },
      {
        CITY: "AVRESSIEUX",
      },
      {
        CITY: "AVRIL",
      },
      {
        CITY: "AVRILLE",
      },
      {
        CITY: "AX-LES-THERMES",
      },
      {
        CITY: "AYDAT",
      },
      {
        CITY: "AYENCOURT",
      },
      {
        CITY: "AYETTE",
      },
      {
        CITY: "AYGUEMORTE-LES-GRAVES",
      },
      {
        CITY: "AYGUESVIVES",
      },
      {
        CITY: "AYMERIES",
      },
      {
        CITY: "AYNAC",
      },
      {
        CITY: "AYRENS",
      },
      {
        CITY: "AYSE",
      },
      {
        CITY: "AYTRE",
      },
      {
        CITY: "AZAS",
      },
      {
        CITY: "AZAY-LE-BRULE",
      },
      {
        CITY: "AZAY-LE-RIDEAU",
      },
      {
        CITY: "AZAY-SUR-CHER",
      },
      {
        CITY: "AZAY-SUR-INDRE",
      },
      {
        CITY: "AZE",
      },
      {
        CITY: "AZE",
      },
      {
        CITY: "AZERABLES",
      },
      {
        CITY: "AZERAILLES",
      },
      {
        CITY: "AZET",
      },
      {
        CITY: "AZILLE",
      },
      {
        CITY: "AZUR",
      },
      {
        CITY: "AZY-SUR-MARNE",
      },
      {
        CITY: "BABEAU-BOULDOUX",
      },
      {
        CITY: "BACCARAT",
      },
      {
        CITY: "BACH",
      },
      {
        CITY: "BACHANT",
      },
      {
        CITY: "BACHY",
      },
      {
        CITY: "BACILLY",
      },
      {
        CITY: "BACONNES",
      },
      {
        CITY: "BACQUEVILLE",
      },
      {
        CITY: "BACQUEVILLE-EN-CAUX",
      },
      {
        CITY: "BADEN",
      },
      {
        CITY: "BADEVEL",
      },
      {
        CITY: "BAGARD",
      },
      {
        CITY: "BAGAS",
      },
      {
        CITY: "BAGE-LA-VILLE",
      },
      {
        CITY: "BAGES",
      },
      {
        CITY: "BAGNAC-SUR-CELE",
      },
      {
        CITY: "BAGNEAUX",
      },
      {
        CITY: "BAGNEAUX-SUR-LOING",
      },
      {
        CITY: "BAGNEUX",
      },
      {
        CITY: "BAGNOLET",
      },
      {
        CITY: "BAGNOLS",
      },
      {
        CITY: "BAGNOLS",
      },
      {
        CITY: "BAGNOLS-EN-FORET",
      },
      {
        CITY: "BAGNOLS-SUR-CEZE",
      },
      {
        CITY: "BAGUER-MORVAN",
      },
      {
        CITY: "BAGUER-PICAN",
      },
      {
        CITY: "BAHO",
      },
      {
        CITY: "BAIGNES-SAINTE-RADEGONDE",
      },
      {
        CITY: "BAIGNEUX-LES-JUIFS",
      },
      {
        CITY: "BAILLARGUES",
      },
      {
        CITY: "BAILLEAU-LE-PIN",
      },
      {
        CITY: "BAILLET-EN-FRANCE",
      },
      {
        CITY: "BAILLEUL",
      },
      {
        CITY: "BAILLEUL-LE-SOC",
      },
      {
        CITY: "BAILLEUL-SIR-BERTHOULT",
      },
      {
        CITY: "BAILLEUL-SUR-THERAIN",
      },
      {
        CITY: "BAILLY",
      },
      {
        CITY: "BAILLY",
      },
      {
        CITY: "BAILLY-CARROIS",
      },
      {
        CITY: "BAILLY-LE-FRANC",
      },
      {
        CITY: "BAILLY-ROMAINVILLIERS",
      },
      {
        CITY: "BAIN-DE-BRETAGNE",
      },
      {
        CITY: "BAINS",
      },
      {
        CITY: "BAINS-LES-BAINS",
      },
      {
        CITY: "BAINS-SUR-OUST",
      },
      {
        CITY: "BAINVILLE-SUR-MADON",
      },
      {
        CITY: "BAISIEUX",
      },
      {
        CITY: "BAIXAS",
      },
      {
        CITY: "BAJUS",
      },
      {
        CITY: "BALAGNY-SUR-THERAIN",
      },
      {
        CITY: "BALAN",
      },
      {
        CITY: "BALANOD",
      },
      {
        CITY: "BALARUC-LE-VIEUX",
      },
      {
        CITY: "BALARUC-LES-BAINS",
      },
      {
        CITY: "BALAZE",
      },
      {
        CITY: "BALBIGNY",
      },
      {
        CITY: "BALDENHEIM",
      },
      {
        CITY: "BALDERSHEIM",
      },
      {
        CITY: "BALGAU",
      },
      {
        CITY: "BALLAINVILLIERS",
      },
      {
        CITY: "BALLAISON",
      },
      {
        CITY: "BALLAN-MIRE",
      },
      {
        CITY: "BALLANCOURT-SUR-ESSONNE",
      },
      {
        CITY: "BALLEROY",
      },
      {
        CITY: "BALLON",
      },
      {
        CITY: "BALLON",
      },
      {
        CITY: "BALMA",
      },
      {
        CITY: "BALSCHWILLER",
      },
      {
        CITY: "BALZAC",
      },
      {
        CITY: "BAN-DE-LAVELINE",
      },
      {
        CITY: "BANASSAC",
      },
      {
        CITY: "BANDOL AOC",
      },
      {
        CITY: "BANGOR",
      },
      {
        CITY: "BANIZE",
      },
      {
        CITY: "BANNALEC",
      },
      {
        CITY: "BANNE",
      },
      {
        CITY: "BANNES",
      },
      {
        CITY: "BANNIERES",
      },
      {
        CITY: "BANON",
      },
      {
        CITY: "BANTIGNY",
      },
      {
        CITY: "BANTZENHEIM",
      },
      {
        CITY: "BANVILLE",
      },
      {
        CITY: "BANVOU",
      },
      {
        CITY: "BANYULS DE LA MARENDA",
      },
      {
        CITY: "BANYULS-DELS-ASPRES",
      },
      {
        CITY: "BAPAUME",
      },
      {
        CITY: "BAR-LE-DUC",
      },
      {
        CITY: "BAR-SUR-AUBE",
      },
      {
        CITY: "BAR-SUR-SEINE",
      },
      {
        CITY: "BARAQUEVILLE",
      },
      {
        CITY: "BARASTRE",
      },
      {
        CITY: "BARBAIRA",
      },
      {
        CITY: "BARBASTE",
      },
      {
        CITY: "BARBATRE",
      },
      {
        CITY: "BARBAZAN-DEBAT",
      },
      {
        CITY: "BARBECHAT",
      },
      {
        CITY: "BARBENTANE",
      },
      {
        CITY: "BARBERAZ",
      },
      {
        CITY: "BARBERY",
      },
      {
        CITY: "BARBEVILLE",
      },
      {
        CITY: "BARBEZIEUX-SAINT-HILAIRE",
      },
      {
        CITY: "BARBIERES",
      },
      {
        CITY: "BARBIZON",
      },
      {
        CITY: "BARBY",
      },
      {
        CITY: "BARC",
      },
      {
        CITY: "BARCELONNETTE",
      },
      {
        CITY: "BARDOS",
      },
      {
        CITY: "BAREMBACH",
      },
      {
        CITY: "BARENTIN",
      },
      {
        CITY: "BARENTON-BUGNY",
      },
      {
        CITY: "BARFLEUR",
      },
      {
        CITY: "BARGES",
      },
      {
        CITY: "BARINQUE",
      },
      {
        CITY: "BARISEY-AU-PLAIN",
      },
      {
        CITY: "BARISEY-LA-COTE",
      },
      {
        CITY: "BARJAC",
      },
      {
        CITY: "BARJOLS",
      },
      {
        CITY: "BARJON",
      },
      {
        CITY: "BARJOUVILLE",
      },
      {
        CITY: "BARLIN",
      },
      {
        CITY: "BARNEVILLE-CARTERET",
      },
      {
        CITY: "BARON",
      },
      {
        CITY: "BARON",
      },
      {
        CITY: "BARONVILLE",
      },
      {
        CITY: "BARR",
      },
      {
        CITY: "BARRAUX",
      },
      {
        CITY: "BARREME",
      },
      {
        CITY: "BARRO",
      },
      {
        CITY: "BARROU",
      },
      {
        CITY: "BARTENHEIM",
      },
      {
        CITY: "BARZY-EN-THIERACHE",
      },
      {
        CITY: "BAS-EN-BASSET",
      },
      {
        CITY: "BASCONS",
      },
      {
        CITY: "BASSAN",
      },
      {
        CITY: "BASSE-GOULAINE",
      },
      {
        CITY: "BASSE-HAM",
      },
      {
        CITY: "BASSE-RENTGEN",
      },
      {
        CITY: "BASSE-SUR-LE-RUPT",
      },
      {
        CITY: "BASSEMBERG",
      },
      {
        CITY: "BASSENS",
      },
      {
        CITY: "BASSENS",
      },
      {
        CITY: "BASSIGNEY",
      },
      {
        CITY: "BASSILLAC",
      },
      {
        CITY: "BASSOLES-AULERS",
      },
      {
        CITY: "BASSOU",
      },
      {
        CITY: "BASSU",
      },
      {
        CITY: "BASSUSSARRY",
      },
      {
        CITY: "BASTENNES",
      },
      {
        CITY: "BASTIA",
      },
      {
        CITY: "BATILLY",
      },
      {
        CITY: "BATILLY-EN-GATINAIS",
      },
      {
        CITY: "BATTENHEIM",
      },
      {
        CITY: "BATTRANS",
      },
      {
        CITY: "BATZ-SUR-MER",
      },
      {
        CITY: "BATZENDORF",
      },
      {
        CITY: "BAUD",
      },
      {
        CITY: "BAUDEMONT",
      },
      {
        CITY: "BAUDREIX",
      },
      {
        CITY: "BAULAY",
      },
      {
        CITY: "BAULE",
      },
      {
        CITY: "BAULNE",
      },
      {
        CITY: "BAULON",
      },
      {
        CITY: "BAUME-LES-DAMES",
      },
      {
        CITY: "BAUME-LES-MESSIEURS",
      },
      {
        CITY: "BAUNE",
      },
      {
        CITY: "BAUPTE",
      },
      {
        CITY: "BAURECH",
      },
      {
        CITY: "BAUVIN",
      },
      {
        CITY: "BAVANS",
      },
      {
        CITY: "BAVAY",
      },
      {
        CITY: "BAVENT",
      },
      {
        CITY: "BAVILLIERS",
      },
      {
        CITY: "BAVINCOURT",
      },
      {
        CITY: "BAYE",
      },
      {
        CITY: "BAYET",
      },
      {
        CITY: "BAYEUX",
      },
      {
        CITY: "BAYON",
      },
      {
        CITY: "BAYONNE",
      },
      {
        CITY: "BAZAILLES",
      },
      {
        CITY: "BAZANCOURT",
      },
      {
        CITY: "BAZARNES",
      },
      {
        CITY: "BAZAS",
      },
      {
        CITY: "BAZEILLES",
      },
      {
        CITY: "BAZEMONT",
      },
      {
        CITY: "BAZENS",
      },
      {
        CITY: "BAZET",
      },
      {
        CITY: "BAZIAN",
      },
      {
        CITY: "BAZICOURT",
      },
      {
        CITY: "BAZIEGE",
      },
      {
        CITY: "BAZIEN",
      },
      {
        CITY: "BAZINCOURT-SUR-EPTE",
      },
      {
        CITY: "BAZOCHES",
      },
      {
        CITY: "BAZOCHES-LES-GALLERANDES",
      },
      {
        CITY: "BAZOCHES-SUR-GUYONNE",
      },
      {
        CITY: "BAZOCHES-SUR-HOENE",
      },
      {
        CITY: "BAZOCHES-SUR-LE-BETZ",
      },
      {
        CITY: "BAZOGES-EN-PAILLERS",
      },
      {
        CITY: "BAZOILLES-SUR-MEUSE",
      },
      {
        CITY: "BAZONCOURT",
      },
      {
        CITY: "BAZOUGES-LA-PEROUSE",
      },
      {
        CITY: "BAZOUGES-SOUS-HEDE",
      },
      {
        CITY: "BAZOUGES-SUR-LE-LOIR",
      },
      {
        CITY: "BAZUEL",
      },
      {
        CITY: "BEAUCAIRE",
      },
      {
        CITY: "BEAUCAMPS-LE-VIEUX",
      },
      {
        CITY: "BEAUCAMPS-LIGNY",
      },
      {
        CITY: "BEAUCE",
      },
      {
        CITY: "BEAUCHAMP",
      },
      {
        CITY: "BEAUCHAMPS",
      },
      {
        CITY: "BEAUCHAMPS",
      },
      {
        CITY: "BEAUCHAMPS-SUR-HUILLARD",
      },
      {
        CITY: "BEAUCHASTEL",
      },
      {
        CITY: "BEAUCHENE",
      },
      {
        CITY: "BEAUCOURT",
      },
      {
        CITY: "BEAUCOUZE",
      },
      {
        CITY: "BEAUCROISSANT",
      },
      {
        CITY: "BEAUDRICOURT",
      },
      {
        CITY: "BEAUFORT",
      },
      {
        CITY: "BEAUFORT",
      },
      {
        CITY: "BEAUFORT",
      },
      {
        CITY: "BEAUFORT-EN-VALLEE",
      },
      {
        CITY: "BEAUFOU",
      },
      {
        CITY: "BEAUGENCY",
      },
      {
        CITY: "BEAUJEU",
      },
      {
        CITY: "BEAULIEU-SOUS-PARTHENAY",
      },
      {
        CITY: "BEAULIEU-SUR-DORDOGNE",
      },
      {
        CITY: "BEAULIEU-SUR-LOIRE",
      },
      {
        CITY: "BEAULIEU-SUR-MER",
      },
      {
        CITY: "BEAUMETZ-LES-LOGES",
      },
      {
        CITY: "BEAUMONT",
      },
      {
        CITY: "BEAUMONT",
      },
      {
        CITY: "BEAUMONT",
      },
      {
        CITY: "BEAUMONT-DE-LOMAGNE",
      },
      {
        CITY: "BEAUMONT-DE-PERTUIS",
      },
      {
        CITY: "BEAUMONT-DU-GATINAIS",
      },
      {
        CITY: "BEAUMONT-DU-PERIGORD",
      },
      {
        CITY: "BEAUMONT-EN-VERON",
      },
      {
        CITY: "BEAUMONT-HAGUE",
      },
      {
        CITY: "BEAUMONT-LE-ROGER",
      },
      {
        CITY: "BEAUMONT-LES-AUTELS",
      },
      {
        CITY: "BEAUMONT-LES-NONAINS",
      },
      {
        CITY: "BEAUMONT-LES-VALENCE",
      },
      {
        CITY: "BEAUMONT-PIED-DE-BOEUF",
      },
      {
        CITY: "BEAUMONT-SUR-OISE",
      },
      {
        CITY: "BEAUMONT-SUR-SARTHE",
      },
      {
        CITY: "BEAUNE",
      },
      {
        CITY: "BEAUNE-LA-ROLANDE",
      },
      {
        CITY: "BEAUPONT",
      },
      {
        CITY: "BEAUPREAU",
      },
      {
        CITY: "BEAUPUY",
      },
      {
        CITY: "BEAUQUESNE",
      },
      {
        CITY: "BEAURAINS",
      },
      {
        CITY: "BEAURAINVILLE",
      },
      {
        CITY: "BEAUREGARD-BARET",
      },
      {
        CITY: "BEAUREGARD-VENDON",
      },
      {
        CITY: "BEAUREPAIRE",
      },
      {
        CITY: "BEAUREPAIRE",
      },
      {
        CITY: "BEAUREPAIRE",
      },
      {
        CITY: "BEAUREVOIR",
      },
      {
        CITY: "BEAURIEUX",
      },
      {
        CITY: "BEAUSEMBLANT",
      },
      {
        CITY: "BEAUSOLEIL",
      },
      {
        CITY: "BEAUSSAULT",
      },
      {
        CITY: "BEAUTHEIL",
      },
      {
        CITY: "BEAUTIRAN",
      },
      {
        CITY: "BEAUTOR",
      },
      {
        CITY: "BEAUTOT",
      },
      {
        CITY: "BEAUVAIS",
      },
      {
        CITY: "BEAUVAIS-SUR-MATHA",
      },
      {
        CITY: "BEAUVAL",
      },
      {
        CITY: "BEAUVALLON",
      },
      {
        CITY: "BEAUVENE",
      },
      {
        CITY: "BEAUVERNOIS",
      },
      {
        CITY: "BEAUVEZER",
      },
      {
        CITY: "BEAUVILLE",
      },
      {
        CITY: "BEAUVOIR",
      },
      {
        CITY: "BEAUVOIR",
      },
      {
        CITY: "BEAUVOIR-DE-MARC",
      },
      {
        CITY: "BEAUVOIR-SUR-MER",
      },
      {
        CITY: "BEAUVOIR-SUR-NIORT",
      },
      {
        CITY: "BEAUVOISIN",
      },
      {
        CITY: "BEAUVOISIN",
      },
      {
        CITY: "BEAUVOISIN",
      },
      {
        CITY: "BEAUZAC",
      },
      {
        CITY: "BEAUZELLE",
      },
      {
        CITY: "BECHY",
      },
      {
        CITY: "BECON-LES-GRANITS",
      },
      {
        CITY: "BEDARRIDES",
      },
      {
        CITY: "BEDEE",
      },
      {
        CITY: "BEDOUS",
      },
      {
        CITY: "BEGANNE",
      },
      {
        CITY: "BEGARD",
      },
      {
        CITY: "BEGLES",
      },
      {
        CITY: "BEGUEY",
      },
      {
        CITY: "BEHREN-LES-FORBACH",
      },
      {
        CITY: "BEIGNON",
      },
      {
        CITY: "BEINE-NAUROY",
      },
      {
        CITY: "BEINHEIM",
      },
      {
        CITY: "BEIRE-LE-CHATEL",
      },
      {
        CITY: "BEL AIR",
      },
      {
        CITY: "BELARGA",
      },
      {
        CITY: "BELAYE",
      },
      {
        CITY: "BELBEUF",
      },
      {
        CITY: "BELCODENE",
      },
      {
        CITY: "BELFORT",
      },
      {
        CITY: "BELFORT-DU-QUERCY",
      },
      {
        CITY: "BELGENTIER",
      },
      {
        CITY: "BELGODERE",
      },
      {
        CITY: "BELHADE",
      },
      {
        CITY: "BELIET",
      },
      {
        CITY: "BELIGNEUX",
      },
      {
        CITY: "BELLAC",
      },
      {
        CITY: "BELLAING",
      },
      {
        CITY: "BELLANCOURT",
      },
      {
        CITY: "BELLE-ISLE-EN-TERRE",
      },
      {
        CITY: "BELLEAU",
      },
      {
        CITY: "BELLEBRUNE",
      },
      {
        CITY: "BELLECHAUME",
      },
      {
        CITY: "BELLEFOND",
      },
      {
        CITY: "BELLEFONTAINE",
      },
      {
        CITY: "BELLEFOSSE",
      },
      {
        CITY: "BELLEGARDE",
      },
      {
        CITY: "BELLEGARDE-DU-RAZES",
      },
      {
        CITY: "BELLEGARDE-EN-DIOIS",
      },
      {
        CITY: "BELLEGARDE-SAINTE-MARIE",
      },
      {
        CITY: "BELLEGARDE-SUR-VALSERINE",
      },
      {
        CITY: "BELLEMAGNY",
      },
      {
        CITY: "BELLENAVES",
      },
      {
        CITY: "BELLENCOMBRE",
      },
      {
        CITY: "BELLENEUVE",
      },
      {
        CITY: "BELLENGREVILLE",
      },
      {
        CITY: "BELLENTRE",
      },
      {
        CITY: "BELLERAY",
      },
      {
        CITY: "BELLERIVE-SUR-ALLIER",
      },
      {
        CITY: "BELLESSERRE",
      },
      {
        CITY: "BELLEU",
      },
      {
        CITY: "BELLEVAUX",
      },
      {
        CITY: "BELLEVESVRE",
      },
      {
        CITY: "BELLEVILLE",
      },
      {
        CITY: "BELLEVILLE",
      },
      {
        CITY: "BELLEVILLE-EN-CAUX",
      },
      {
        CITY: "BELLEVILLE-SUR-LOIRE",
      },
      {
        CITY: "BELLEVILLE-SUR-MER",
      },
      {
        CITY: "BELLEVILLE-SUR-MEUSE",
      },
      {
        CITY: "BELLEVILLE-SUR-VIE",
      },
      {
        CITY: "BELLEVUE-LA-MONTAGNE",
      },
      {
        CITY: "BELLEY",
      },
      {
        CITY: "BELLIGNAT",
      },
      {
        CITY: "BELLOY-EN-FRANCE",
      },
      {
        CITY: "BELMONT",
      },
      {
        CITY: "BELMONT",
      },
      {
        CITY: "BELMONT-SUR-RANCE",
      },
      {
        CITY: "BELMONTET",
      },
      {
        CITY: "BELPECH",
      },
      {
        CITY: "BELRUPT-EN-VERDUNOIS",
      },
      {
        CITY: "BELVEZE",
      },
      {
        CITY: "BELZ",
      },
      {
        CITY: "BENAMENIL",
      },
      {
        CITY: "BENE",
      },
      {
        CITY: "BENERVILLE-SUR-MER",
      },
      {
        CITY: "BENESSE-MAREMNE",
      },
      {
        CITY: "BENESTROFF",
      },
      {
        CITY: "BENFELD",
      },
      {
        CITY: "BENIFONTAINE",
      },
      {
        CITY: "BENNEY",
      },
      {
        CITY: "BENNWIHR",
      },
      {
        CITY: "BENON",
      },
      {
        CITY: "BENOUVILLE",
      },
      {
        CITY: "BEON",
      },
      {
        CITY: "BERAT",
      },
      {
        CITY: "BERCENAY-EN-OTHE",
      },
      {
        CITY: "BERCK",
      },
      {
        CITY: "BERCK-PLAGE",
      },
      {
        CITY: "BERDOUES",
      },
      {
        CITY: "BERENX",
      },
      {
        CITY: "BERG-SUR-MOSELLE",
      },
      {
        CITY: "BERGERAC",
      },
      {
        CITY: "BERGHEIM",
      },
      {
        CITY: "BERGHOLTZ",
      },
      {
        CITY: "BERGONNE",
      },
      {
        CITY: "BERGUES",
      },
      {
        CITY: "BERJOU",
      },
      {
        CITY: "BERLAIMONT",
      },
      {
        CITY: "BERLES-AU-BOIS",
      },
      {
        CITY: "BERMERIES",
      },
      {
        CITY: "BERMICOURT",
      },
      {
        CITY: "BERNADETS",
      },
      {
        CITY: "BERNARD",
      },
      {
        CITY: "BERNAVILLE",
      },
      {
        CITY: "BERNAY",
      },
      {
        CITY: "BERNAY-SAINT-MARTIN",
      },
      {
        CITY: "BERNE",
      },
      {
        CITY: "BERNES-SUR-OISE",
      },
      {
        CITY: "BERNEUIL-SUR-AISNE",
      },
      {
        CITY: "BERNEVAL-LE-GRAND",
      },
      {
        CITY: "BERNEX",
      },
      {
        CITY: "BERNIERES-LE-PATRY",
      },
      {
        CITY: "BERNIERES-SUR-MER",
      },
      {
        CITY: "BERNIN",
      },
      {
        CITY: "BERNIS",
      },
      {
        CITY: "BERNOS-BEAULAC",
      },
      {
        CITY: "BERRIAC",
      },
      {
        CITY: "BERRIC",
      },
      {
        CITY: "BERRY-BOUY",
      },
      {
        CITY: "BERSAILLIN",
      },
      {
        CITY: "BERSEE",
      },
      {
        CITY: "BERSON",
      },
      {
        CITY: "BERTHECOURT",
      },
      {
        CITY: "BERTHELANGE",
      },
      {
        CITY: "BERTHEN",
      },
      {
        CITY: "BERTHOLENE",
      },
      {
        CITY: "BERTIN",
      },
      {
        CITY: "BERTINCOURT",
      },
      {
        CITY: "BERTRANGE",
      },
      {
        CITY: "BERTRIMOUTIER",
      },
      {
        CITY: "BERTRY",
      },
      {
        CITY: "BERVILLE-EN-ROUMOIS",
      },
      {
        CITY: "BERVILLE-LA-CAMPAGNE",
      },
      {
        CITY: "BERVILLE-SUR-SEINE",
      },
      {
        CITY: "BERVILLER-EN-MOSELLE",
      },
      {
        CITY: "BERZEME",
      },
      {
        CITY: "BERZET",
      },
      {
        CITY: "BESAYES",
      },
      {
        CITY: "BESNE",
      },
      {
        CITY: "BESSAIS-LE-FROMENTAL",
      },
      {
        CITY: "BESSAN",
      },
      {
        CITY: "BESSANCOURT",
      },
      {
        CITY: "BESSAY",
      },
      {
        CITY: "BESSE",
      },
      {
        CITY: "BESSE",
      },
      {
        CITY: "BESSE-SUR-BRAYE",
      },
      {
        CITY: "BESSE-SUR-ISSOLE",
      },
      {
        CITY: "BESSEGES",
      },
      {
        CITY: "BESSENAY",
      },
      {
        CITY: "BESSENS",
      },
      {
        CITY: "BESSEY-LES-CITEAUX",
      },
      {
        CITY: "BESSIERES",
      },
      {
        CITY: "BESSINES",
      },
      {
        CITY: "BESSINES-SUR-GARTEMPE",
      },
      {
        CITY: "BESSON",
      },
      {
        CITY: "BESSONCOURT",
      },
      {
        CITY: "BESSONIES",
      },
      {
        CITY: "BETAILLE",
      },
      {
        CITY: "BETHANCOURT-EN-VALOIS",
      },
      {
        CITY: "BETHENCOURT-SUR-MER",
      },
      {
        CITY: "BETHENIVILLE",
      },
      {
        CITY: "BETHENY",
      },
      {
        CITY: "BETHISY-SAINT-PIERRE",
      },
      {
        CITY: "BETHONCOURT",
      },
      {
        CITY: "BETSCHDORF",
      },
      {
        CITY: "BETTANCOURT-LA-FERREE",
      },
      {
        CITY: "BETTIGNIES",
      },
      {
        CITY: "BETTING",
      },
      {
        CITY: "BETTON",
      },
      {
        CITY: "BETTVILLER",
      },
      {
        CITY: "BETTWILLER",
      },
      {
        CITY: "BETZ",
      },
      {
        CITY: "BEUGIN",
      },
      {
        CITY: "BEUGNEUX",
      },
      {
        CITY: "BEURE",
      },
      {
        CITY: "BEURLAY",
      },
      {
        CITY: "BEUSTE",
      },
      {
        CITY: "BEUTAL",
      },
      {
        CITY: "BEUVILLERS",
      },
      {
        CITY: "BEUVRAGES",
      },
      {
        CITY: "BEUVRAIGNES",
      },
      {
        CITY: "BEUVRON-EN-AUGE",
      },
      {
        CITY: "BEUVRY-LA-FORET",
      },
      {
        CITY: "BEUZEC-CAP-SIZUN",
      },
      {
        CITY: "BEUZEVILLE",
      },
      {
        CITY: "BEVENAIS",
      },
      {
        CITY: "BEYCHAC-ET-CAILLAU",
      },
      {
        CITY: "BEYNES",
      },
      {
        CITY: "BEYNOST",
      },
      {
        CITY: "BEYREDE-JUMET",
      },
      {
        CITY: "BEYRIE-EN-BEARN",
      },
      {
        CITY: "BEYSSENAC",
      },
      {
        CITY: "BEZAC",
      },
      {
        CITY: "BEZANNES",
      },
      {
        CITY: "BEZONS",
      },
      {
        CITY: "BEZOUCE",
      },
      {
        CITY: "BEZU-SAINT-ELOI",
      },
      {
        CITY: "BIACHE-SAINT-VAAST",
      },
      {
        CITY: "BIARD",
      },
      {
        CITY: "BIARRITZ",
      },
      {
        CITY: "BIARS-SUR-CERE",
      },
      {
        CITY: "BIAUDOS",
      },
      {
        CITY: "BICHANCOURT",
      },
      {
        CITY: "BICQUELEY",
      },
      {
        CITY: "BIDACHE",
      },
      {
        CITY: "BIDARRAY",
      },
      {
        CITY: "BIDART",
      },
      {
        CITY: "BIEF-DU-FOURG",
      },
      {
        CITY: "BIELLE",
      },
      {
        CITY: "BIENVILLE",
      },
      {
        CITY: "BIENVILLERS-AU-BOIS",
      },
      {
        CITY: "BIERMONT",
      },
      {
        CITY: "BIERNE",
      },
      {
        CITY: "BIERRY-LES-BELLES-FONTAINES",
      },
      {
        CITY: "BIESHEIM",
      },
      {
        CITY: "BIESLES",
      },
      {
        CITY: "BIETLENHEIM",
      },
      {
        CITY: "BIEVILLE-BEUVILLE",
      },
      {
        CITY: "BIEVRES",
      },
      {
        CITY: "BIGANOS",
      },
      {
        CITY: "BIGNAC",
      },
      {
        CITY: "BIGNAN",
      },
      {
        CITY: "BIGNOUX",
      },
      {
        CITY: "BIGUGLIA",
      },
      {
        CITY: "BIHOREL",
      },
      {
        CITY: "BILIEU",
      },
      {
        CITY: "BILLERE",
      },
      {
        CITY: "BILLEY",
      },
      {
        CITY: "BILLIERS",
      },
      {
        CITY: "BILLOM",
      },
      {
        CITY: "BILLY",
      },
      {
        CITY: "BILLY",
      },
      {
        CITY: "BILLY-BERCLAU",
      },
      {
        CITY: "BILLY-MONTIGNY",
      },
      {
        CITY: "BILLY-SOUS-LES-COTES",
      },
      {
        CITY: "BILLY-SUR-OURCQ",
      },
      {
        CITY: "BINDERNHEIM",
      },
      {
        CITY: "BINGES",
      },
      {
        CITY: "BINIC",
      },
      {
        CITY: "BINING",
      },
      {
        CITY: "BIONVILLE-SUR-NIED",
      },
      {
        CITY: "BIOT",
      },
      {
        CITY: "BIOT",
      },
      {
        CITY: "BIOULE",
      },
      {
        CITY: "BIRAC-SUR-TREC",
      },
      {
        CITY: "BIRIATOU",
      },
      {
        CITY: "BIRIEUX",
      },
      {
        CITY: "BISCARROSSE",
      },
      {
        CITY: "BISCHHEIM",
      },
      {
        CITY: "BISCHOFFSHEIM",
      },
      {
        CITY: "BISCHWIHR",
      },
      {
        CITY: "BISCHWILLER",
      },
      {
        CITY: "BISEL",
      },
      {
        CITY: "BITCHE",
      },
      {
        CITY: "BIVIERS",
      },
      {
        CITY: "BIVILLE-SUR-MER",
      },
      {
        CITY: "BIZANOS",
      },
      {
        CITY: "BLACOURT",
      },
      {
        CITY: "BLACQUEVILLE",
      },
      {
        CITY: "BLACY",
      },
      {
        CITY: "BLAESHEIM",
      },
      {
        CITY: "BLAGNAC",
      },
      {
        CITY: "BLAGNY",
      },
      {
        CITY: "BLAIGNAN",
      },
      {
        CITY: "BLAIN",
      },
      {
        CITY: "BLAINVILLE",
      },
      {
        CITY: "BLAINVILLE-SUR-MER",
      },
      {
        CITY: "BLAINVILLE-SUR-ORNE",
      },
      {
        CITY: "BLAISE",
      },
      {
        CITY: "BLAISE-SOUS-ARZILLIERES",
      },
      {
        CITY: "BLAISON-GOHIER",
      },
      {
        CITY: "BLAMONT",
      },
      {
        CITY: "BLAMONT",
      },
      {
        CITY: "BLAN",
      },
      {
        CITY: "BLANCAFORT",
      },
      {
        CITY: "BLANCHEFOSSE-ET-BAY",
      },
      {
        CITY: "BLANGY-SUR-BRESLE",
      },
      {
        CITY: "BLANOT",
      },
      {
        CITY: "BLANQUEFORT",
      },
      {
        CITY: "BLANZAT",
      },
      {
        CITY: "BLANZAY-SUR-BOUTONNE",
      },
      {
        CITY: "BLANZY",
      },
      {
        CITY: "BLANZY-LA-SALONNAISE",
      },
      {
        CITY: "BLARINGHEM",
      },
      {
        CITY: "BLAUDEIX",
      },
      {
        CITY: "BLAUSASC",
      },
      {
        CITY: "BLAUZAC",
      },
      {
        CITY: "BLAVOZY",
      },
      {
        CITY: "BLAYE",
      },
      {
        CITY: "BLAYE-LES-MINES",
      },
      {
        CITY: "BLEIGNY-LE-CARREAU",
      },
      {
        CITY: "BLENDECQUES",
      },
      {
        CITY: "BLENOD-LES-PONT-A-MOUSSON",
      },
      {
        CITY: "BLENOD-LES-TOUL",
      },
      {
        CITY: "BLERANCOURDELLE",
      },
      {
        CITY: "BLERE",
      },
      {
        CITY: "BLERIOT-PLAGE",
      },
      {
        CITY: "BLESLE",
      },
      {
        CITY: "BLETTERANS",
      },
      {
        CITY: "BLEURY",
      },
      {
        CITY: "BLIGNY-LES-BEAUNE",
      },
      {
        CITY: "BLINCOURT",
      },
      {
        CITY: "BLINGEL",
      },
      {
        CITY: "BLODELSHEIM",
      },
      {
        CITY: "BLOIS",
      },
      {
        CITY: "BLOMARD",
      },
      {
        CITY: "BLONDEFONTAINE",
      },
      {
        CITY: "BLONVILLE-SUR-MER",
      },
      {
        CITY: "BLOTZHEIM",
      },
      {
        CITY: "BLOU",
      },
      {
        CITY: "BLOYE",
      },
      {
        CITY: "BLYES",
      },
      {
        CITY: "BOBIGNY",
      },
      {
        CITY: "BOCQUEGNEY",
      },
      {
        CITY: "BOE",
      },
      {
        CITY: "BOEGE",
      },
      {
        CITY: "BOEIL-BEZING",
      },
      {
        CITY: "BOEN-SUR-LIGNON",
      },
      {
        CITY: "BOERSCH",
      },
      {
        CITY: "BOESCHEPE",
      },
      {
        CITY: "BOESEGHEM",
      },
      {
        CITY: "BOESENBIESEN",
      },
      {
        CITY: "BOHAIN-EN-VERMANDOIS",
      },
      {
        CITY: "BOHAL",
      },
      {
        CITY: "BOIGNEVILLE",
      },
      {
        CITY: "BOIGNY",
      },
      {
        CITY: "BOIGNY-SUR-BIONNE",
      },
      {
        CITY: "BOIS",
      },
      {
        CITY: "BOIS-ANZERAY",
      },
      {
        CITY: "BOIS-ARNAULT",
      },
      {
        CITY: "BOIS-COLOMBES",
      },
      {
        CITY: "BOIS-DE-CENE",
      },
      {
        CITY: "BOIS-DE-GAND",
      },
      {
        CITY: "BOIS-DE-LA-PIERRE",
      },
      {
        CITY: "BOIS-GRENIER",
      },
      {
        CITY: "BOIS-GUILLAUME",
      },
      {
        CITY: "BOIS-JEROME-SAINT-OUEN",
      },
      {
        CITY: "BOIS-LE-ROI",
      },
      {
        CITY: "BOIS-LE-ROI",
      },
      {
        CITY: "BOISBERGUES",
      },
      {
        CITY: "BOISCOMMUN",
      },
      {
        CITY: "BOISEMONT",
      },
      {
        CITY: "BOISEMONT",
      },
      {
        CITY: "BOISJEAN",
      },
      {
        CITY: "BOISLEUX-AU-MONT",
      },
      {
        CITY: "BOISMONT",
      },
      {
        CITY: "BOISMORAND",
      },
      {
        CITY: "BOISSE-PENCHOT",
      },
      {
        CITY: "BOISSERON",
      },
      {
        CITY: "BOISSET-ET-GAUJAC",
      },
      {
        CITY: "BOISSEUIL",
      },
      {
        CITY: "BOISSEY-LE-CHATEL",
      },
      {
        CITY: "BOISSEZON",
      },
      {
        CITY: "BOISSIERES",
      },
      {
        CITY: "BOISSISE-LA-BERTRAND",
      },
      {
        CITY: "BOISSISE-LE-ROI",
      },
      {
        CITY: "BOISSY-FRESNOY",
      },
      {
        CITY: "BOISSY-LE-BOIS",
      },
      {
        CITY: "BOISSY-LE-CHATEL",
      },
      {
        CITY: "BOISSY-LE-GIRARD",
      },
      {
        CITY: "BOISSY-MAUGIS",
      },
      {
        CITY: "BOISSY-MAUVOISIN",
      },
      {
        CITY: "BOISSY-SOUS-SAINT-YON",
      },
      {
        CITY: "BOISVILLE-LA-SAINT-PERE",
      },
      {
        CITY: "BOISYVON",
      },
      {
        CITY: "BOLANDOZ",
      },
      {
        CITY: "BOLBEC",
      },
      {
        CITY: "BOLLENE",
      },
      {
        CITY: "BOLLEVILLE",
      },
      {
        CITY: "BOLLEZEELE",
      },
      {
        CITY: "BOLLWILLER",
      },
      {
        CITY: "BOLSENHEIM",
      },
      {
        CITY: "BOMMES",
      },
      {
        CITY: "BOMPAS",
      },
      {
        CITY: "BOMPAS",
      },
      {
        CITY: "BON-ENCONTRE",
      },
      {
        CITY: "BONCHAMP-LES-LAVAL",
      },
      {
        CITY: "BONCOURT-LE-BOIS",
      },
      {
        CITY: "BONCOURT-SUR-MEUSE",
      },
      {
        CITY: "BONDEVILLE",
      },
      {
        CITY: "BONDIGOUX",
      },
      {
        CITY: "BONDOUFLE",
      },
      {
        CITY: "BONDUES",
      },
      {
        CITY: "BONDY",
      },
      {
        CITY: "BONGHEAT",
      },
      {
        CITY: "BONIFACIO",
      },
      {
        CITY: "BONLIER",
      },
      {
        CITY: "BONNARD",
      },
      {
        CITY: "BONNAT",
      },
      {
        CITY: "BONNEBOSQ",
      },
      {
        CITY: "BONNEE",
      },
      {
        CITY: "BONNEGARDE",
      },
      {
        CITY: "BONNEGUETE",
      },
      {
        CITY: "BONNEIL",
      },
      {
        CITY: "BONNELLES",
      },
      {
        CITY: "BONNEMAIN",
      },
      {
        CITY: "BONNES",
      },
      {
        CITY: "BONNET",
      },
      {
        CITY: "BONNET",
      },
      {
        CITY: "BONNET",
      },
      {
        CITY: "BONNETABLE",
      },
      {
        CITY: "BONNETAGE",
      },
      {
        CITY: "BONNETAN",
      },
      {
        CITY: "BONNEUIL-EN-FRANCE",
      },
      {
        CITY: "BONNEUIL-EN-VALOIS",
      },
      {
        CITY: "BONNEUIL-LES-EAUX",
      },
      {
        CITY: "BONNEUIL-SUR-MARNE",
      },
      {
        CITY: "BONNEVAL",
      },
      {
        CITY: "BONNEVAUX-LE-PRIEURE",
      },
      {
        CITY: "BONNEVENT-VELLOREILLE",
      },
      {
        CITY: "BONNEVILLE",
      },
      {
        CITY: "BONNEVILLE",
      },
      {
        CITY: "BONNEVILLE-APTOT",
      },
      {
        CITY: "BONNEVILLE-LA-LOUVET",
      },
      {
        CITY: "BONNIERES-SUR-SEINE",
      },
      {
        CITY: "BONNIEUX",
      },
      {
        CITY: "BONNY-SUR-LOIRE",
      },
      {
        CITY: "BONREPOS",
      },
      {
        CITY: "BONREPOS-RIQUET",
      },
      {
        CITY: "BONS-EN-CHABLAIS",
      },
      {
        CITY: "BONSECOURS",
      },
      {
        CITY: "BONSON",
      },
      {
        CITY: "BONZAC",
      },
      {
        CITY: "BOOS",
      },
      {
        CITY: "BOOS",
      },
      {
        CITY: "BOQUEHO",
      },
      {
        CITY: "BOR-ET-BAR",
      },
      {
        CITY: "BORAN-SUR-OISE",
      },
      {
        CITY: "BORD",
      },
      {
        CITY: "BORD",
      },
      {
        CITY: "BORDEAUX",
      },
      {
        CITY: "BORDERES",
      },
      {
        CITY: "BORDERES-ET-LAMENSANS",
      },
      {
        CITY: "BORDES",
      },
      {
        CITY: "BORDS",
      },
      {
        CITY: "BORGO",
      },
      {
        CITY: "BORMES-LES-MIMOSAS",
      },
      {
        CITY: "BORNAY",
      },
      {
        CITY: "BORNEL",
      },
      {
        CITY: "BOSC RENOULT",
      },
      {
        CITY: "BOSC-GUERARD-SAINT-ADRIEN",
      },
      {
        CITY: "BOSC-LE-HARD",
      },
      {
        CITY: "BOSC-MESNIL",
      },
      {
        CITY: "BOSGOUET",
      },
      {
        CITY: "BOSGUERARD-DE-MARCOUVILLE",
      },
      {
        CITY: "BOSJEAN",
      },
      {
        CITY: "BOSMONT-SUR-SERRE",
      },
      {
        CITY: "BOSROBERT",
      },
      {
        CITY: "BOSSANCOURT",
      },
      {
        CITY: "BOSSAY-SUR-CLAISE",
      },
      {
        CITY: "BOSSENDORF",
      },
      {
        CITY: "BOSSEY",
      },
      {
        CITY: "BOTSORHEL",
      },
      {
        CITY: "BOUAFLES",
      },
      {
        CITY: "BOUAN",
      },
      {
        CITY: "BOUAYE",
      },
      {
        CITY: "BOUBERS-SUR-CANCHE",
      },
      {
        CITY: "BOUBIERS",
      },
      {
        CITY: "BOUC-BEL-AIR",
      },
      {
        CITY: "BOUCAU",
      },
      {
        CITY: "BOUCHAIN",
      },
      {
        CITY: "BOUCHEMAINE",
      },
      {
        CITY: "BOUCHEPORN",
      },
      {
        CITY: "BOUCHET",
      },
      {
        CITY: "BOUCOIRAN-ET-NOZIERES",
      },
      {
        CITY: "BOUEE",
      },
      {
        CITY: "BOUER",
      },
      {
        CITY: "BOUERE",
      },
      {
        CITY: "BOUEX",
      },
      {
        CITY: "BOUFFEMONT",
      },
      {
        CITY: "BOUFFERE",
      },
      {
        CITY: "BOUGARBER",
      },
      {
        CITY: "BOUGE-CHAMBALUD",
      },
      {
        CITY: "BOUGIVAL",
      },
      {
        CITY: "BOUGNON",
      },
      {
        CITY: "BOUGUE",
      },
      {
        CITY: "BOUGUENAIS",
      },
      {
        CITY: "BOUGY",
      },
      {
        CITY: "BOUHET",
      },
      {
        CITY: "BOUILLARGUES",
      },
      {
        CITY: "BOUILLY",
      },
      {
        CITY: "BOUILLY",
      },
      {
        CITY: "BOUILLY-EN-GATINAIS",
      },
      {
        CITY: "BOUIN",
      },
      {
        CITY: "BOUJAN-SUR-LIBRON",
      },
      {
        CITY: "BOULAGES",
      },
      {
        CITY: "BOULANCOURT",
      },
      {
        CITY: "BOULANGE",
      },
      {
        CITY: "BOULAY-LES-BARRES",
      },
      {
        CITY: "BOULAY-MOSELLE",
      },
      {
        CITY: "BOULAZAC",
      },
      {
        CITY: "BOULEURS",
      },
      {
        CITY: "BOULIAC",
      },
      {
        CITY: "BOULIEU-LES-ANNONAY",
      },
      {
        CITY: "BOULIGNEY",
      },
      {
        CITY: "BOULIGNY",
      },
      {
        CITY: "BOULLAY-LES-TROUX",
      },
      {
        CITY: "BOULLERET",
      },
      {
        CITY: "BOULLEVILLE",
      },
      {
        CITY: "BOULOC",
      },
      {
        CITY: "BOULOGNE-BILLANCOURT",
      },
      {
        CITY: "BOULOGNE-LA-GRASSE",
      },
      {
        CITY: "BOULOGNE-SUR-GESSE",
      },
      {
        CITY: "BOULOGNE-SUR-MER",
      },
      {
        CITY: "BOULOIRE",
      },
      {
        CITY: "BOULT-SUR-SUIPPE",
      },
      {
        CITY: "BOULZICOURT",
      },
      {
        CITY: "BOUNIAGUES",
      },
      {
        CITY: "BOUQUET",
      },
      {
        CITY: "BOUQUETOT",
      },
      {
        CITY: "BOURAY-SUR-JUINE",
      },
      {
        CITY: "BOURBON-LANCY",
      },
      {
        CITY: "BOURBONNE-LES-BAINS",
      },
      {
        CITY: "BOURBOURG",
      },
      {
        CITY: "BOURBRIAC",
      },
      {
        CITY: "BOURCEFRANC-LE-CHAPUS",
      },
      {
        CITY: "BOURDENAY",
      },
      {
        CITY: "BOURDON",
      },
      {
        CITY: "BOURESSE",
      },
      {
        CITY: "BOURG",
      },
      {
        CITY: "BOURG-ACHARD",
      },
      {
        CITY: "BOURG-ARGENTAL",
      },
      {
        CITY: "BOURG-BLANC",
      },
      {
        CITY: "BOURG-DE-THIZY",
      },
      {
        CITY: "BOURG-EN-BRESSE",
      },
      {
        CITY: "BOURG-LA-REINE",
      },
      {
        CITY: "BOURG-LE-COMTE",
      },
      {
        CITY: "BOURG-LE-ROI",
      },
      {
        CITY: "BOURG-LES-VALENCE",
      },
      {
        CITY: "BOURG-SAINT-ANDEOL",
      },
      {
        CITY: "BOURG-SAINT-MAURICE",
      },
      {
        CITY: "BOURG-SAINTE-MARIE",
      },
      {
        CITY: "BOURG-SOUS-CHATELET",
      },
      {
        CITY: "BOURGANEUF",
      },
      {
        CITY: "BOURGBARRE",
      },
      {
        CITY: "BOURGES",
      },
      {
        CITY: "BOURGET",
      },
      {
        CITY: "BOURGHELLES",
      },
      {
        CITY: "BOURGNEUF",
      },
      {
        CITY: "BOURGNEUF",
      },
      {
        CITY: "BOURGNEUF-EN-RETZ",
      },
      {
        CITY: "BOURGOGNE",
      },
      {
        CITY: "BOURGOGNE",
      },
      {
        CITY: "BOURGOIN",
      },
      {
        CITY: "BOURGUEBUS",
      },
      {
        CITY: "BOURGUEIL",
      },
      {
        CITY: "BOURGUIGNON-SOUS-COUCY",
      },
      {
        CITY: "BOURLON",
      },
      {
        CITY: "BOURNEVILLE",
      },
      {
        CITY: "BOURNEZEAU",
      },
      {
        CITY: "BOUROGNE",
      },
      {
        CITY: "BOURRAN",
      },
      {
        CITY: "BOURRON-MARLOTTE",
      },
      {
        CITY: "BOURS",
      },
      {
        CITY: "BOURS",
      },
      {
        CITY: "BOURSAULT",
      },
      {
        CITY: "BOURSEVILLE",
      },
      {
        CITY: "BOURSIERES",
      },
      {
        CITY: "BOURTH",
      },
      {
        CITY: "BOURY-EN-VEXIN",
      },
      {
        CITY: "BOUSBACH",
      },
      {
        CITY: "BOUSBECQUE",
      },
      {
        CITY: "BOUSIES",
      },
      {
        CITY: "BOUSSAC",
      },
      {
        CITY: "BOUSSAC",
      },
      {
        CITY: "BOUSSAY",
      },
      {
        CITY: "BOUSSENAC",
      },
      {
        CITY: "BOUSSENS",
      },
      {
        CITY: "BOUSSIERES",
      },
      {
        CITY: "BOUSSOIS",
      },
      {
        CITY: "BOUSSY-SAINT-ANTOINE",
      },
      {
        CITY: "BOUST",
      },
      {
        CITY: "BOUTANCOURT",
      },
      {
        CITY: "BOUTIERS-SAINT-TROJAN",
      },
      {
        CITY: "BOUTIGNY-PROUAIS",
      },
      {
        CITY: "BOUTIGNY-SUR-ESSONNE",
      },
      {
        CITY: "BOUVAINCOURT-SUR-BRESLE",
      },
      {
        CITY: "BOUVANTE",
      },
      {
        CITY: "BOUVELINGHEM",
      },
      {
        CITY: "BOUVESSE-QUIRIEU",
      },
      {
        CITY: "BOUVIGNY-BOYEFFLES",
      },
      {
        CITY: "BOUVILLE",
      },
      {
        CITY: "BOUVINCOURT-EN-VERMANDOIS",
      },
      {
        CITY: "BOUVRON",
      },
      {
        CITY: "BOUXIERES-AUX-CHENES",
      },
      {
        CITY: "BOUXIERES-AUX-DAMES",
      },
      {
        CITY: "BOUXWILLER",
      },
      {
        CITY: "BOUZEL",
      },
      {
        CITY: "BOUZIGUES",
      },
      {
        CITY: "BOUZINCOURT",
      },
      {
        CITY: "BOUZONVILLE",
      },
      {
        CITY: "BOUZONVILLE-EN-BEAUCE",
      },
      {
        CITY: "BOUZY",
      },
      {
        CITY: "BOUZY-LA-FORET",
      },
      {
        CITY: "BOVES",
      },
      {
        CITY: "BOYER",
      },
      {
        CITY: "BOYNES",
      },
      {
        CITY: "BOZ",
      },
      {
        CITY: "BOZEL",
      },
      {
        CITY: "BOZOULS",
      },
      {
        CITY: "BRACHY",
      },
      {
        CITY: "BRACIEUX",
      },
      {
        CITY: "BRAIN-SUR-ALLONNES",
      },
      {
        CITY: "BRAIN-SUR-VILAINE",
      },
      {
        CITY: "BRAINE",
      },
      {
        CITY: "BRAINS",
      },
      {
        CITY: "BRAM",
      },
      {
        CITY: "BRAMANS",
      },
      {
        CITY: "BRANCHES",
      },
      {
        CITY: "BRANCOURT-EN-LAONNOIS",
      },
      {
        CITY: "BRANDIVY",
      },
      {
        CITY: "BRANDO",
      },
      {
        CITY: "BRANDONNET",
      },
      {
        CITY: "BRANGES",
      },
      {
        CITY: "BRANNE",
      },
      {
        CITY: "BRANOUX-LES-TAILLADES",
      },
      {
        CITY: "BRANSLES",
      },
      {
        CITY: "BRAQUIS",
      },
      {
        CITY: "BRAS",
      },
      {
        CITY: "BRASLES",
      },
      {
        CITY: "BRASPARTS",
      },
      {
        CITY: "BRASSAC",
      },
      {
        CITY: "BRASSAC-LES-MINES",
      },
      {
        CITY: "BRASSEMPOUY",
      },
      {
        CITY: "BRAUCOURT",
      },
      {
        CITY: "BRAUD-ET-SAINT-LOUIS",
      },
      {
        CITY: "BRAX",
      },
      {
        CITY: "BRAX",
      },
      {
        CITY: "BRAY",
      },
      {
        CITY: "BRAY-DUNES",
      },
      {
        CITY: "BRAY-EN-VAL",
      },
      {
        CITY: "BRAY-ET-LU",
      },
      {
        CITY: "BRAY-SAINT-CHRISTOPHE",
      },
      {
        CITY: "BRAY-SUR-SEINE",
      },
      {
        CITY: "BRAY-SUR-SOMME",
      },
      {
        CITY: "BRAYE-SOUS-FAYE",
      },
      {
        CITY: "BRAZEY-EN-PLAINE",
      },
      {
        CITY: "BREAL-SOUS-MONTFORT",
      },
      {
        CITY: "BREBIERES",
      },
      {
        CITY: "BRECE",
      },
      {
        CITY: "BRECEY",
      },
      {
        CITY: "BRECH",
      },
      {
        CITY: "BRECONCHAUX",
      },
      {
        CITY: "BREE",
      },
      {
        CITY: "BREGY",
      },
      {
        CITY: "BREHAL",
      },
      {
        CITY: "BREHAND",
      },
      {
        CITY: "BREIL-SUR-ROYA",
      },
      {
        CITY: "BREITENBACH-HAUT-RHIN",
      },
      {
        CITY: "BRELES",
      },
      {
        CITY: "BREMENIL",
      },
      {
        CITY: "BREMONTIER-MERVAL",
      },
      {
        CITY: "BRENOUILLE",
      },
      {
        CITY: "BRENS",
      },
      {
        CITY: "BRESLES",
      },
      {
        CITY: "BRESSE-SUR-GROSNE",
      },
      {
        CITY: "BRESSOLLES",
      },
      {
        CITY: "BRESSOLLES",
      },
      {
        CITY: "BRESSOLS",
      },
      {
        CITY: "BRESSON",
      },
      {
        CITY: "BRESSUIRE",
      },
      {
        CITY: "BREST",
      },
      {
        CITY: "BRETAGNE",
      },
      {
        CITY: "BRETEIL",
      },
      {
        CITY: "BRETENIERE",
      },
      {
        CITY: "BRETENOUX",
      },
      {
        CITY: "BRETEUIL",
      },
      {
        CITY: "BRETEUIL",
      },
      {
        CITY: "BRETIGNOLLES",
      },
      {
        CITY: "BRETIGNOLLES-SUR-MER",
      },
      {
        CITY: "BRETIGNY",
      },
      {
        CITY: "BRETIGNY",
      },
      {
        CITY: "BRETTEVILLE-SUR-LAIZE",
      },
      {
        CITY: "BRETTEVILLE-SUR-ODON",
      },
      {
        CITY: "BREUIL",
      },
      {
        CITY: "BREUIL",
      },
      {
        CITY: "BREUIL-LE-SEC",
      },
      {
        CITY: "BREUIL-LE-VERT",
      },
      {
        CITY: "BREUIL-MAGNE",
      },
      {
        CITY: "BREUILLET",
      },
      {
        CITY: "BREUILLET",
      },
      {
        CITY: "BREUILPONT",
      },
      {
        CITY: "BREUSCHWICKERSHEIM",
      },
      {
        CITY: "BREUVANNES-EN-BASSIGNY",
      },
      {
        CITY: "BREUX-SUR-AVRE",
      },
      {
        CITY: "BREVAL",
      },
      {
        CITY: "BREVIANDES",
      },
      {
        CITY: "BREXENT-ENOCQ",
      },
      {
        CITY: "BREZE",
      },
      {
        CITY: "BREZINS",
      },
      {
        CITY: "BREZOLLES",
      },
      {
        CITY: "BRIANCONNET",
      },
      {
        CITY: "BRIARE",
      },
      {
        CITY: "BRIARRES-SUR-ESSONNE",
      },
      {
        CITY: "BRIATEXTE",
      },
      {
        CITY: "BRICON",
      },
      {
        CITY: "BRICQUEBEC",
      },
      {
        CITY: "BRICQUEVILLE-LA-BLOUETTE",
      },
      {
        CITY: "BRICQUEVILLE-SUR-MER",
      },
      {
        CITY: "BRICY",
      },
      {
        CITY: "BRIDES-LES-BAINS",
      },
      {
        CITY: "BRIDORE",
      },
      {
        CITY: "BRIE",
      },
      {
        CITY: "BRIE",
      },
      {
        CITY: "BRIE-COMTE-ROBERT",
      },
      {
        CITY: "BRIE-ET-ANGONNES",
      },
      {
        CITY: "BRIEC",
      },
      {
        CITY: "BRIENNE-LA-VIEILLE",
      },
      {
        CITY: "BRIENNON",
      },
      {
        CITY: "BRIENON-SUR-ARMANCON",
      },
      {
        CITY: "BRIERES-LES-SCELLES",
      },
      {
        CITY: "BRIEUIL-SUR-CHIZE",
      },
      {
        CITY: "BRIEULLES-SUR-MEUSE",
      },
      {
        CITY: "BRIEY",
      },
      {
        CITY: "BRIGNAC-LA-PLAINE",
      },
      {
        CITY: "BRIGNAIS",
      },
      {
        CITY: "BRIGNE",
      },
      {
        CITY: "BRIGNOLES",
      },
      {
        CITY: "BRIGNON",
      },
      {
        CITY: "BRIGNOUD",
      },
      {
        CITY: "BRIGUEUIL",
      },
      {
        CITY: "BRIIS-SOUS-FORGES",
      },
      {
        CITY: "BRIMEUX",
      },
      {
        CITY: "BRIN-SUR-SEILLE",
      },
      {
        CITY: "BRINAY",
      },
      {
        CITY: "BRINDAS",
      },
      {
        CITY: "BRINGOLO",
      },
      {
        CITY: "BRINON-SUR-BEUVRON",
      },
      {
        CITY: "BRIOLLAY",
      },
      {
        CITY: "BRION",
      },
      {
        CITY: "BRION",
      },
      {
        CITY: "BRIONNE",
      },
      {
        CITY: "BRIORD",
      },
      {
        CITY: "BRIOUDE",
      },
      {
        CITY: "BRIOUX-SUR-BOUTONNE",
      },
      {
        CITY: "BRIOUZE",
      },
      {
        CITY: "BRISCOUS",
      },
      {
        CITY: "BRISON-SAINT-INNOCENT",
      },
      {
        CITY: "BRISSAC",
      },
      {
        CITY: "BRISSAC-QUINCE",
      },
      {
        CITY: "BRISSY-HAMEGICOURT",
      },
      {
        CITY: "BRIVE",
      },
      {
        CITY: "BRIVE-LA-GAILLARDE",
      },
      {
        CITY: "BRIVES-CHARENSAC",
      },
      {
        CITY: "BRIVES-SUR-CHARENTE",
      },
      {
        CITY: "BRIX",
      },
      {
        CITY: "BROCHON",
      },
      {
        CITY: "BROGLIE",
      },
      {
        CITY: "BROGNON",
      },
      {
        CITY: "BROIN",
      },
      {
        CITY: "BROMBOS",
      },
      {
        CITY: "BRON",
      },
      {
        CITY: "BROONS",
      },
      {
        CITY: "BROSSAC",
      },
      {
        CITY: "BROSVILLE",
      },
      {
        CITY: "BROU",
      },
      {
        CITY: "BROU-SUR-CHANTEREINE",
      },
      {
        CITY: "BROUAY",
      },
      {
        CITY: "BROUCKERQUE",
      },
      {
        CITY: "BROUDERDORFF",
      },
      {
        CITY: "BROUE",
      },
      {
        CITY: "BROUENNES",
      },
      {
        CITY: "BROUILLA",
      },
      {
        CITY: "BROUQUEYRAN",
      },
      {
        CITY: "BROUSSE",
      },
      {
        CITY: "BROUSSE",
      },
      {
        CITY: "BROUSSEVAL",
      },
      {
        CITY: "BROUVILLER",
      },
      {
        CITY: "BRU",
      },
      {
        CITY: "BRUCH",
      },
      {
        CITY: "BRUE-AURIAC",
      },
      {
        CITY: "BRUEBACH",
      },
      {
        CITY: "BRUGES",
      },
      {
        CITY: "BRUGHEAS",
      },
      {
        CITY: "BRUGNY-VAUDANCOURT",
      },
      {
        CITY: "BRUGUIERES",
      },
      {
        CITY: "BRUILLE-SAINT-AMAND",
      },
      {
        CITY: "BRULEY",
      },
      {
        CITY: "BRULLIOLES",
      },
      {
        CITY: "BRULON",
      },
      {
        CITY: "BRUMATH",
      },
      {
        CITY: "BRUNOY",
      },
      {
        CITY: "BRUNSTATT",
      },
      {
        CITY: "BRUSSEY",
      },
      {
        CITY: "BRUSSIEU",
      },
      {
        CITY: "BRUSVILY",
      },
      {
        CITY: "BRUX",
      },
      {
        CITY: "BRUYERES-ET-MONTBERAULT",
      },
      {
        CITY: "BRUYERES-LE-CHATEL",
      },
      {
        CITY: "BRUYERES-SUR-OISE",
      },
      {
        CITY: "BRUZ",
      },
      {
        CITY: "BRY-SUR-MARNE",
      },
      {
        CITY: "BU",
      },
      {
        CITY: "BUANES",
      },
      {
        CITY: "BUBRY",
      },
      {
        CITY: "BUC",
      },
      {
        CITY: "BUCEELS",
      },
      {
        CITY: "BUCEY-LES-GY",
      },
      {
        CITY: "BUCHERES",
      },
      {
        CITY: "BUCHY",
      },
      {
        CITY: "BUCQUOY",
      },
      {
        CITY: "BUCY-LE-LONG",
      },
      {
        CITY: "BUCY-LES-CERNY",
      },
      {
        CITY: "BUDOS",
      },
      {
        CITY: "BUEIL",
      },
      {
        CITY: "BUELLAS",
      },
      {
        CITY: "BUFFARD",
      },
      {
        CITY: "BUGEAT",
      },
      {
        CITY: "BUGNICOURT",
      },
      {
        CITY: "BUHL",
      },
      {
        CITY: "BUIGNY-LES-GAMACHES",
      },
      {
        CITY: "BUIRE-LE-SEC",
      },
      {
        CITY: "BUIRONFOSSE",
      },
      {
        CITY: "BUIS-LES-BARONNIES",
      },
      {
        CITY: "BULAT-PESTIVIEN",
      },
      {
        CITY: "BULGNEVILLE",
      },
      {
        CITY: "BULHON",
      },
      {
        CITY: "BULLES",
      },
      {
        CITY: "BULLY",
      },
      {
        CITY: "BULLY-LES-MINES",
      },
      {
        CITY: "BUNO-BONNEVAUX",
      },
      {
        CITY: "BUNZAC",
      },
      {
        CITY: "BURBURE",
      },
      {
        CITY: "BURES-SUR-YVETTE",
      },
      {
        CITY: "BURIE",
      },
      {
        CITY: "BURLATS",
      },
      {
        CITY: "BURNHAUPT-LE-HAUT",
      },
      {
        CITY: "BUROS",
      },
      {
        CITY: "BURY",
      },
      {
        CITY: "BUSIGNY",
      },
      {
        CITY: "BUSLOUP",
      },
      {
        CITY: "BUSQUE",
      },
      {
        CITY: "BUSSAC-FORET",
      },
      {
        CITY: "BUSSANG",
      },
      {
        CITY: "BUSSIERE-GALANT",
      },
      {
        CITY: "BUSSIERE-POITEVINE",
      },
      {
        CITY: "BUSSIERES-LES-BELMONT",
      },
      {
        CITY: "BUSSUNARITS-SARRASQUETTE",
      },
      {
        CITY: "BUSSUS-BUSSUEL",
      },
      {
        CITY: "BUSSY-LE-CHATEAU",
      },
      {
        CITY: "BUSSY-LE-GRAND",
      },
      {
        CITY: "BUSSY-LES-DAOURS",
      },
      {
        CITY: "BUSSY-LETTREE",
      },
      {
        CITY: "BUSSY-SAINT-GEORGES",
      },
      {
        CITY: "BUSY",
      },
      {
        CITY: "BUTHIERS",
      },
      {
        CITY: "BUTRY-SUR-OISE",
      },
      {
        CITY: "BUXEROLLES",
      },
      {
        CITY: "BUXEUIL",
      },
      {
        CITY: "BUXIERES-LES-MINES",
      },
      {
        CITY: "BUXY",
      },
      {
        CITY: "BUZAN",
      },
      {
        CITY: "BUZANCAIS",
      },
      {
        CITY: "BUZET-SUR-BAISE",
      },
      {
        CITY: "BUZET-SUR-TARN",
      },
      {
        CITY: "BUZY",
      },
      {
        CITY: "CABANAC",
      },
      {
        CITY: "CABANAC-ET-VILLAGRAINS",
      },
      {
        CITY: "CABANNES",
      },
      {
        CITY: "CABARA",
      },
      {
        CITY: "CABARIOT",
      },
      {
        CITY: "CABASSE",
      },
      {
        CITY: "CABESTANY",
      },
      {
        CITY: "CABOURG",
      },
      {
        CITY: "CABRIES",
      },
      {
        CITY: "CABRIS",
      },
      {
        CITY: "CACHAN",
      },
      {
        CITY: "CADALEN",
      },
      {
        CITY: "CADAUJAC",
      },
      {
        CITY: "CADEN",
      },
      {
        CITY: "CADENET",
      },
      {
        CITY: "CADILLAC",
      },
      {
        CITY: "CADILLAC-EN-FRONSADAIS",
      },
      {
        CITY: "CADOLIVE",
      },
      {
        CITY: "CADOURS",
      },
      {
        CITY: "CAEN",
      },
      {
        CITY: "CAESTRE",
      },
      {
        CITY: "CAGNAC-LES-MINES",
      },
      {
        CITY: "CAGNES-SUR-MER",
      },
      {
        CITY: "CAGNICOURT",
      },
      {
        CITY: "CAGNY",
      },
      {
        CITY: "CAGNY",
      },
      {
        CITY: "CAHAGNES",
      },
      {
        CITY: "CAHORS",
      },
      {
        CITY: "CAHUZAC",
      },
      {
        CITY: "CAHUZAC",
      },
      {
        CITY: "CAHUZAC-SUR-VERE",
      },
      {
        CITY: "CAILLE",
      },
      {
        CITY: "CAILLOUEL-CREPIGNY",
      },
      {
        CITY: "CAILLOUX-SUR-FONTAINES",
      },
      {
        CITY: "CAIRANNE",
      },
      {
        CITY: "CAIRON",
      },
      {
        CITY: "CAISSARGUES",
      },
      {
        CITY: "CAJARC",
      },
      {
        CITY: "CALAIS",
      },
      {
        CITY: "CALAS",
      },
      {
        CITY: "CALAVANTE",
      },
      {
        CITY: "CALCATOGGIO",
      },
      {
        CITY: "CALENZANA",
      },
      {
        CITY: "CALLAC",
      },
      {
        CITY: "CALLAC",
      },
      {
        CITY: "CALLAS",
      },
      {
        CITY: "CALLEVILLE",
      },
      {
        CITY: "CALLIAN",
      },
      {
        CITY: "CALMELS-ET-LE-VIALA",
      },
      {
        CITY: "CALMONT",
      },
      {
        CITY: "CALONNE-RICOUART",
      },
      {
        CITY: "CALONNE-SUR-LA-LYS",
      },
      {
        CITY: "CALUIRE-ET-CUIRE",
      },
      {
        CITY: "CALVI",
      },
      {
        CITY: "CALVISSON",
      },
      {
        CITY: "CAMARET-SUR-AIGUES",
      },
      {
        CITY: "CAMARET-SUR-MER",
      },
      {
        CITY: "CAMARSAC",
      },
      {
        CITY: "CAMBES",
      },
      {
        CITY: "CAMBIEURE",
      },
      {
        CITY: "CAMBLAIN-CHATELAIN",
      },
      {
        CITY: "CAMBLANES-ET-MEYNAC",
      },
      {
        CITY: "CAMBO-LES-BAINS",
      },
      {
        CITY: "CAMBOUNET-SUR-LE-SOR",
      },
      {
        CITY: "CAMBRAI",
      },
      {
        CITY: "CAMBRIN",
      },
      {
        CITY: "CAMBRONNE-LES-RIBECOURT",
      },
      {
        CITY: "CAME",
      },
      {
        CITY: "CAMEYRAC",
      },
      {
        CITY: "CAMIAC-ET-SAINT-DENIS",
      },
      {
        CITY: "CAMIERS",
      },
      {
        CITY: "CAMLEZ",
      },
      {
        CITY: "CAMOEL",
      },
      {
        CITY: "CAMON",
      },
      {
        CITY: "CAMON",
      },
      {
        CITY: "CAMORS",
      },
      {
        CITY: "CAMPAGNAN",
      },
      {
        CITY: "CAMPAGNE",
      },
      {
        CITY: "CAMPAGNE",
      },
      {
        CITY: "CAMPAGNE-LES-HESDIN",
      },
      {
        CITY: "CAMPAGNE-LES-WARDRECQUES",
      },
      {
        CITY: "CAMPAGNOLLES",
      },
      {
        CITY: "CAMPEAUX",
      },
      {
        CITY: "CAMPENEAC",
      },
      {
        CITY: "CAMPET-ET-LAMOLERE",
      },
      {
        CITY: "CAMPHIN-EN-CAREMBAULT",
      },
      {
        CITY: "CAMPHIN-EN-PEVELE",
      },
      {
        CITY: "CAMPIGNEULLES-LES-PETITES",
      },
      {
        CITY: "CAMPREMY",
      },
      {
        CITY: "CAMPROND",
      },
      {
        CITY: "CAMPS-LA-SOURCE",
      },
      {
        CITY: "CAMPUAC",
      },
      {
        CITY: "CAMPUGNAN",
      },
      {
        CITY: "CANAPLES",
      },
      {
        CITY: "CANCALE",
      },
      {
        CITY: "CANCON",
      },
      {
        CITY: "CANDE",
      },
      {
        CITY: "CANDE-SUR-BEUVRON",
      },
      {
        CITY: "CANDILLARGUES",
      },
      {
        CITY: "CANDRESSE",
      },
      {
        CITY: "CANEJAN",
      },
      {
        CITY: "CANET-DE-SALARS",
      },
      {
        CITY: "CANET-PLAGE",
      },
      {
        CITY: "CANGEY",
      },
      {
        CITY: "CANISY",
      },
      {
        CITY: "CANLERS",
      },
      {
        CITY: "CANLY",
      },
      {
        CITY: "CANNES",
      },
      {
        CITY: "CANNES-ECLUSE",
      },
      {
        CITY: "CANNET",
      },
      {
        CITY: "CANOHES",
      },
      {
        CITY: "CANOUVILLE",
      },
      {
        CITY: "CANTELEU",
      },
      {
        CITY: "CANTIN",
      },
      {
        CITY: "CANY-BARVILLE",
      },
      {
        CITY: "CAPBRETON",
      },
      {
        CITY: "CAPDENAC-GARE",
      },
      {
        CITY: "CAPELLE",
      },
      {
        CITY: "CAPELLE-FERMONT",
      },
      {
        CITY: "CAPENDU",
      },
      {
        CITY: "CAPENS",
      },
      {
        CITY: "CAPESTANG",
      },
      {
        CITY: "CAPINGHEM",
      },
      {
        CITY: "CAPOULET-ET-JUNAC",
      },
      {
        CITY: "CAPPEL",
      },
      {
        CITY: "CAPPELLE-BROUCK",
      },
      {
        CITY: "CAPPELLE-EN-PEVELE",
      },
      {
        CITY: "CAPPELLE-LA-GRANDE",
      },
      {
        CITY: "CAPTIEUX",
      },
      {
        CITY: "CAPVERN",
      },
      {
        CITY: "CARAGOUDES",
      },
      {
        CITY: "CARAMAN",
      },
      {
        CITY: "CARANTEC",
      },
      {
        CITY: "CARANTILLY",
      },
      {
        CITY: "CARBON-BLANC",
      },
      {
        CITY: "CARBONNE",
      },
      {
        CITY: "CARCANS",
      },
      {
        CITY: "CARCASSONNE",
      },
      {
        CITY: "CARCES",
      },
      {
        CITY: "CARCOPINO",
      },
      {
        CITY: "CARDAILLAC",
      },
      {
        CITY: "CARDESSE",
      },
      {
        CITY: "CARDONVILLE",
      },
      {
        CITY: "CARDROC",
      },
      {
        CITY: "CARENCY",
      },
      {
        CITY: "CARENTAN",
      },
      {
        CITY: "CARENTOIR",
      },
      {
        CITY: "CARHAIX-PLOUGUER",
      },
      {
        CITY: "CARIGNAN",
      },
      {
        CITY: "CARLING",
      },
      {
        CITY: "CARLIPA",
      },
      {
        CITY: "CARLUCET",
      },
      {
        CITY: "CARLUS",
      },
      {
        CITY: "CARMAUX",
      },
      {
        CITY: "CARNAC",
      },
      {
        CITY: "CARNEVILLE",
      },
      {
        CITY: "CARNIERES",
      },
      {
        CITY: "CARNIN",
      },
      {
        CITY: "CARNON-PLAGE",
      },
      {
        CITY: "CARNOULES",
      },
      {
        CITY: "CAROLLES",
      },
      {
        CITY: "CAROMB",
      },
      {
        CITY: "CARPENTRAS",
      },
      {
        CITY: "CARPIQUET",
      },
      {
        CITY: "CARQUEFOU",
      },
      {
        CITY: "CARQUEIRANNE",
      },
      {
        CITY: "CARRESSE-CASSABER",
      },
      {
        CITY: "CARRIERES-SOUS-POISSY",
      },
      {
        CITY: "CARRIERES-SUR-SEINE",
      },
      {
        CITY: "CARROS",
      },
      {
        CITY: "CARROUGES",
      },
      {
        CITY: "CARRY-LE-ROUET",
      },
      {
        CITY: "CARSAC-AILLAC",
      },
      {
        CITY: "CARSPACH",
      },
      {
        CITY: "CARTELEGUE",
      },
      {
        CITY: "CARTIGNIES",
      },
      {
        CITY: "CARVILLE-LA-FOLLETIERE",
      },
      {
        CITY: "CARVIN",
      },
      {
        CITY: "CASANOVA",
      },
      {
        CITY: "CASES-DE-PENE",
      },
      {
        CITY: "CASSAGNE",
      },
      {
        CITY: "CASSAGNES",
      },
      {
        CITY: "CASSAGNES-BEGONHES",
      },
      {
        CITY: "CASSAGNOLES",
      },
      {
        CITY: "CASSEL",
      },
      {
        CITY: "CASSENEUIL",
      },
      {
        CITY: "CASSIS",
      },
      {
        CITY: "CASSON",
      },
      {
        CITY: "CAST",
      },
      {
        CITY: "CASTAGNEDE",
      },
      {
        CITY: "CASTANET-LE-HAUT",
      },
      {
        CITY: "CASTANET-TOLOSAN",
      },
      {
        CITY: "CASTEIDE-CAMI",
      },
      {
        CITY: "CASTELGINEST",
      },
      {
        CITY: "CASTELJALOUX",
      },
      {
        CITY: "CASTELLANE",
      },
      {
        CITY: "CASTELLAR",
      },
      {
        CITY: "CASTELLARE-DI-MERCURIO",
      },
      {
        CITY: "CASTELLO-DI-ROSTINO",
      },
      {
        CITY: "CASTELMORON-SUR-LOT",
      },
      {
        CITY: "CASTELNAU-DE-BRASSAC",
      },
      {
        CITY: "CASTELNAU-DE-LEVIS",
      },
      {
        CITY: "CASTELNAU-DE-MONTMIRAL",
      },
      {
        CITY: "CASTELNAU-DURBAN",
      },
      {
        CITY: "CASTELNAU-LE-LEZ",
      },
      {
        CITY: "CASTELNAU-MONTRATIER",
      },
      {
        CITY: "CASTELNAU-SUR-GUPIE",
      },
      {
        CITY: "CASTELNAU-VALENCE",
      },
      {
        CITY: "CASTELNAUD-DE-GRATECAMBE",
      },
      {
        CITY: "CASTELNAUDARY",
      },
      {
        CITY: "CASTELS",
      },
      {
        CITY: "CASTELSAGRAT",
      },
      {
        CITY: "CASTELSARRASIN",
      },
      {
        CITY: "CASTERA-LOUBIX",
      },
      {
        CITY: "CASTET",
      },
      {
        CITY: "CASTETNER",
      },
      {
        CITY: "CASTETS-EN-DORTHE",
      },
      {
        CITY: "CASTIGLIONE",
      },
      {
        CITY: "CASTILLON-DEBATS",
      },
      {
        CITY: "CASTILLON-DU-GARD",
      },
      {
        CITY: "CASTILLON-LA-BATAILLE",
      },
      {
        CITY: "CASTILLON-MASSAS",
      },
      {
        CITY: "CASTILLONNES",
      },
      {
        CITY: "CASTILLY",
      },
      {
        CITY: "CASTRES",
      },
      {
        CITY: "CASTRES-GIRONDE",
      },
      {
        CITY: "CASTRIES",
      },
      {
        CITY: "CATENAY",
      },
      {
        CITY: "CATENOY",
      },
      {
        CITY: "CATTENOM",
      },
      {
        CITY: "CATUS",
      },
      {
        CITY: "CAUCALIERES",
      },
      {
        CITY: "CAUDAN",
      },
      {
        CITY: "CAUDEBEC-EN-CAUX",
      },
      {
        CITY: "CAUDECOSTE",
      },
      {
        CITY: "CAUDROT",
      },
      {
        CITY: "CAUDRY",
      },
      {
        CITY: "CAUGE",
      },
      {
        CITY: "CAULLERY",
      },
      {
        CITY: "CAULNES",
      },
      {
        CITY: "CAUMONT",
      },
      {
        CITY: "CAUMONT",
      },
      {
        CITY: "CAUMONT-SUR-DURANCE",
      },
      {
        CITY: "CAUMONT-SUR-GARONNE",
      },
      {
        CITY: "CAUNEILLE",
      },
      {
        CITY: "CAUNES-MINERVOIS",
      },
      {
        CITY: "CAUREL",
      },
      {
        CITY: "CAUROIR",
      },
      {
        CITY: "CAUROY-LES-HERMONVILLE",
      },
      {
        CITY: "CAUSSADE-RIVIERE",
      },
      {
        CITY: "CAUSSE-DE-LA-SELLE",
      },
      {
        CITY: "CAUVIGNY",
      },
      {
        CITY: "CAUVILLE-SUR-MER",
      },
      {
        CITY: "CAUX",
      },
      {
        CITY: "CAUX-ET-SAUZENS",
      },
      {
        CITY: "CAUZAC",
      },
      {
        CITY: "CAVAILLON",
      },
      {
        CITY: "CAVALAIRE-SUR-MER",
      },
      {
        CITY: "CAVAN",
      },
      {
        CITY: "CAVEIRAC",
      },
      {
        CITY: "CAVIGNAC",
      },
      {
        CITY: "CAVILLARGUES",
      },
      {
        CITY: "CAVILLON",
      },
      {
        CITY: "CAYEUX-SUR-MER",
      },
      {
        CITY: "CAYLUS",
      },
      {
        CITY: "CAYRAC",
      },
      {
        CITY: "CAZALS-DES-BAYLES",
      },
      {
        CITY: "CAZAUX",
      },
      {
        CITY: "CAZAUX-LAYRISSE",
      },
      {
        CITY: "CAZAVET",
      },
      {
        CITY: "CAZERES",
      },
      {
        CITY: "CAZES-MONDENARD",
      },
      {
        CITY: "CAZEVIEILLE",
      },
      {
        CITY: "CAZILHAC",
      },
      {
        CITY: "CAZOULS-LES-BEZIERS",
      },
      {
        CITY: "CEAUCE",
      },
      {
        CITY: "CEAUX",
      },
      {
        CITY: "CEBAZAT",
      },
      {
        CITY: "CEIGNES",
      },
      {
        CITY: "CEINTREY",
      },
      {
        CITY: "CELLE-LEVESCAULT",
      },
      {
        CITY: "CELLES-EN-BASSIGNY",
      },
      {
        CITY: "CELLES-SUR-BELLE",
      },
      {
        CITY: "CELLES-SUR-DUROLLE",
      },
      {
        CITY: "CELLETTES",
      },
      {
        CITY: "CELLIEU",
      },
      {
        CITY: "CELON",
      },
      {
        CITY: "CENAC",
      },
      {
        CITY: "CENDRAS",
      },
      {
        CITY: "CENON",
      },
      {
        CITY: "CENON-SUR-VIENNE",
      },
      {
        CITY: "CENTRES",
      },
      {
        CITY: "CEPET",
      },
      {
        CITY: "CEPIE",
      },
      {
        CITY: "CEPOY",
      },
      {
        CITY: "CERANS-FOULLETOURTE",
      },
      {
        CITY: "CERCIER",
      },
      {
        CITY: "CERCOUX",
      },
      {
        CITY: "CERDON",
      },
      {
        CITY: "CERE",
      },
      {
        CITY: "CERELLES",
      },
      {
        CITY: "CERESTE",
      },
      {
        CITY: "CERET",
      },
      {
        CITY: "CERGY",
      },
      {
        CITY: "CERILLY",
      },
      {
        CITY: "CERISE",
      },
      {
        CITY: "CERISIERS",
      },
      {
        CITY: "CERISY-LA-FORET",
      },
      {
        CITY: "CERISY-LA-SALLE",
      },
      {
        CITY: "CERIZAY",
      },
      {
        CITY: "CERNAY",
      },
      {
        CITY: "CERNAY-LA-VILLE",
      },
      {
        CITY: "CERNEUX",
      },
      {
        CITY: "CERNY",
      },
      {
        CITY: "CERONS",
      },
      {
        CITY: "CERS",
      },
      {
        CITY: "CERTINES",
      },
      {
        CITY: "CERVENS",
      },
      {
        CITY: "CERVERA DE LA MARENDA",
      },
      {
        CITY: "CERVIERES",
      },
      {
        CITY: "CERVIONE",
      },
      {
        CITY: "CESAR",
      },
      {
        CITY: "CESARCHES",
      },
      {
        CITY: "CESCAU",
      },
      {
        CITY: "CESNY-AUX-VIGNES",
      },
      {
        CITY: "CESNY-BOIS-HALBOUT",
      },
      {
        CITY: "CESSALES",
      },
      {
        CITY: "CESSENON-SUR-ORB",
      },
      {
        CITY: "CESSERAS",
      },
      {
        CITY: "CESSEY",
      },
      {
        CITY: "CESSIEU",
      },
      {
        CITY: "CESSON",
      },
      {
        CITY: "CESSON",
      },
      {
        CITY: "CESSY",
      },
      {
        CITY: "CESTAS",
      },
      {
        CITY: "CETON",
      },
      {
        CITY: "CEYRAT",
      },
      {
        CITY: "CEYRESTE",
      },
      {
        CITY: "CEYSSAT",
      },
      {
        CITY: "CEYZERIAT",
      },
      {
        CITY: "CEZAY",
      },
      {
        CITY: "CHABAN",
      },
      {
        CITY: "CHABANAIS",
      },
      {
        CITY: "CHABESTAN",
      },
      {
        CITY: "CHABEUIL",
      },
      {
        CITY: "CHABLIS",
      },
      {
        CITY: "CHABONS",
      },
      {
        CITY: "CHABOTTES",
      },
      {
        CITY: "CHABRILLAN",
      },
      {
        CITY: "CHABRIS",
      },
      {
        CITY: "CHACENAY",
      },
      {
        CITY: "CHADELEUF",
      },
      {
        CITY: "CHADRAC",
      },
      {
        CITY: "CHADRON",
      },
      {
        CITY: "CHAFFOIS",
      },
      {
        CITY: "CHAGEY",
      },
      {
        CITY: "CHAGNY",
      },
      {
        CITY: "CHAIGNAY",
      },
      {
        CITY: "CHAIL",
      },
      {
        CITY: "CHAILLAC",
      },
      {
        CITY: "CHAILLAC-SUR-VIENNE",
      },
      {
        CITY: "CHAILLE-LES-MARAIS",
      },
      {
        CITY: "CHAILLES",
      },
      {
        CITY: "CHAILLEVETTE",
      },
      {
        CITY: "CHAILLOUE",
      },
      {
        CITY: "CHAILLY-EN-BIERE",
      },
      {
        CITY: "CHAILLY-EN-GATINAIS",
      },
      {
        CITY: "CHAINAZ-LES-FRASSES",
      },
      {
        CITY: "CHAINGY",
      },
      {
        CITY: "CHAIX",
      },
      {
        CITY: "CHALAIS",
      },
      {
        CITY: "CHALAMONT",
      },
      {
        CITY: "CHALAMPE",
      },
      {
        CITY: "CHALANDRY-ELAIRE",
      },
      {
        CITY: "CHALEINS",
      },
      {
        CITY: "CHALENCON",
      },
      {
        CITY: "CHALETTE-SUR-LOING",
      },
      {
        CITY: "CHALETTE-SUR-VOIRE",
      },
      {
        CITY: "CHALEZE",
      },
      {
        CITY: "CHALEZEULE",
      },
      {
        CITY: "CHALIGNY",
      },
      {
        CITY: "CHALINDREY",
      },
      {
        CITY: "CHALLAIN-LA-POTHERIE",
      },
      {
        CITY: "CHALLANS",
      },
      {
        CITY: "CHALLES-LES-EAUX",
      },
      {
        CITY: "CHALLET",
      },
      {
        CITY: "CHALLEX",
      },
      {
        CITY: "CHALMAISON",
      },
      {
        CITY: "CHALO-SAINT-MARS",
      },
      {
        CITY: "CHALON",
      },
      {
        CITY: "CHALONNES-SUR-LOIRE",
      },
      {
        CITY: "CHALONS-EN-CHAMPAGNE",
      },
      {
        CITY: "CHALONVILLARS",
      },
      {
        CITY: "CHALOU-MOULINEUX",
      },
      {
        CITY: "CHAMAGNE",
      },
      {
        CITY: "CHAMAGNIEU",
      },
      {
        CITY: "CHAMBALUD",
      },
      {
        CITY: "CHAMBERET",
      },
      {
        CITY: "CHAMBILLY",
      },
      {
        CITY: "CHAMBLAC",
      },
      {
        CITY: "CHAMBLANC",
      },
      {
        CITY: "CHAMBLES",
      },
      {
        CITY: "CHAMBLET",
      },
      {
        CITY: "CHAMBLEY-BUSSIERES",
      },
      {
        CITY: "CHAMBLY",
      },
      {
        CITY: "CHAMBOEUF",
      },
      {
        CITY: "CHAMBON",
      },
      {
        CITY: "CHAMBON-SUR-LAC",
      },
      {
        CITY: "CHAMBON-SUR-VOUEIZE",
      },
      {
        CITY: "CHAMBONAS",
      },
      {
        CITY: "CHAMBORD",
      },
      {
        CITY: "CHAMBORET",
      },
      {
        CITY: "CHAMBORIGAUD",
      },
      {
        CITY: "CHAMBORS",
      },
      {
        CITY: "CHAMBOST-LONGESSAIGNE",
      },
      {
        CITY: "CHAMBOULIVE",
      },
      {
        CITY: "CHAMBOURCY",
      },
      {
        CITY: "CHAMBOURG-SUR-INDRE",
      },
      {
        CITY: "CHAMBRECY",
      },
      {
        CITY: "CHAMBRETAUD",
      },
      {
        CITY: "CHAMBRY",
      },
      {
        CITY: "CHAMELET",
      },
      {
        CITY: "CHAMEYRAT",
      },
      {
        CITY: "CHAMIGNY",
      },
      {
        CITY: "CHAMONIX",
      },
      {
        CITY: "CHAMOUILLEY",
      },
      {
        CITY: "CHAMOUX",
      },
      {
        CITY: "CHAMOUX-SUR-GELON",
      },
      {
        CITY: "CHAMP",
      },
      {
        CITY: "CHAMP-LE-DUC",
      },
      {
        CITY: "CHAMP-SUR-DRAC",
      },
      {
        CITY: "CHAMPAGNAC",
      },
      {
        CITY: "CHAMPAGNAC-LE-VIEUX",
      },
      {
        CITY: "CHAMPAGNAT",
      },
      {
        CITY: "CHAMPAGNE",
      },
      {
        CITY: "CHAMPAGNE",
      },
      {
        CITY: "CHAMPAGNE-LES-MARAIS",
      },
      {
        CITY: "CHAMPAGNE-MOUTON",
      },
      {
        CITY: "CHAMPAGNE-SUR-OISE",
      },
      {
        CITY: "CHAMPAGNE-SUR-SEINE",
      },
      {
        CITY: "CHAMPAGNEY",
      },
      {
        CITY: "CHAMPAGNOLE",
      },
      {
        CITY: "CHAMPANGES",
      },
      {
        CITY: "CHAMPCENEST",
      },
      {
        CITY: "CHAMPCERIE",
      },
      {
        CITY: "CHAMPCEVINEL",
      },
      {
        CITY: "CHAMPCUEIL",
      },
      {
        CITY: "CHAMPDIEU",
      },
      {
        CITY: "CHAMPDOTRE",
      },
      {
        CITY: "CHAMPDRAY",
      },
      {
        CITY: "CHAMPEAUX",
      },
      {
        CITY: "CHAMPEAUX",
      },
      {
        CITY: "CHAMPEIX",
      },
      {
        CITY: "CHAMPENAY",
      },
      {
        CITY: "CHAMPENOUX",
      },
      {
        CITY: "CHAMPEON",
      },
      {
        CITY: "CHAMPEY-SUR-MOSELLE",
      },
      {
        CITY: "CHAMPFORGEUIL",
      },
      {
        CITY: "CHAMPFROMIER",
      },
      {
        CITY: "CHAMPHOL",
      },
      {
        CITY: "CHAMPIEN",
      },
      {
        CITY: "CHAMPIER",
      },
      {
        CITY: "CHAMPIGNE",
      },
      {
        CITY: "CHAMPIGNEULLES",
      },
      {
        CITY: "CHAMPIGNY",
      },
      {
        CITY: "CHAMPIGNY-SUR-MARNE",
      },
      {
        CITY: "CHAMPLAN",
      },
      {
        CITY: "CHAMPLEMY",
      },
      {
        CITY: "CHAMPLITTE",
      },
      {
        CITY: "CHAMPNIERS",
      },
      {
        CITY: "CHAMPOLY",
      },
      {
        CITY: "CHAMPOULET",
      },
      {
        CITY: "CHAMPS",
      },
      {
        CITY: "CHAMPS-SUR-MARNE",
      },
      {
        CITY: "CHAMPS-SUR-TARENTAINE-MARCHAL",
      },
      {
        CITY: "CHAMPS-SUR-YONNE",
      },
      {
        CITY: "CHAMPSAC",
      },
      {
        CITY: "CHAMPTERCIER",
      },
      {
        CITY: "CHAMPTOCE-SUR-LOIRE",
      },
      {
        CITY: "CHAMPTOCEAUX",
      },
      {
        CITY: "CHAMPVANS",
      },
      {
        CITY: "CHANAS",
      },
      {
        CITY: "CHANCEAUX-SUR-CHOISILLE",
      },
      {
        CITY: "CHANCELADE",
      },
      {
        CITY: "CHANCENAY",
      },
      {
        CITY: "CHANGE",
      },
      {
        CITY: "CHANGIS-SUR-MARNE",
      },
      {
        CITY: "CHANIERS",
      },
      {
        CITY: "CHANONAT",
      },
      {
        CITY: "CHANOZ-CHATENAY",
      },
      {
        CITY: "CHANTEAU",
      },
      {
        CITY: "CHANTECORPS",
      },
      {
        CITY: "CHANTEHEUX",
      },
      {
        CITY: "CHANTELOUP",
      },
      {
        CITY: "CHANTELOUP-EN-BRIE",
      },
      {
        CITY: "CHANTELOUP-LES-BOIS",
      },
      {
        CITY: "CHANTELOUP-LES-VIGNES",
      },
      {
        CITY: "CHANTEMERLE-SUR-LA-SOIE",
      },
      {
        CITY: "CHANTEPIE",
      },
      {
        CITY: "CHANTEUGES",
      },
      {
        CITY: "CHANTILLY",
      },
      {
        CITY: "CHANTOME",
      },
      {
        CITY: "CHANTONNAY",
      },
      {
        CITY: "CHANTRAINE",
      },
      {
        CITY: "CHANTRANS",
      },
      {
        CITY: "CHANVEAUX",
      },
      {
        CITY: "CHAON",
      },
      {
        CITY: "CHAPAREILLAN",
      },
      {
        CITY: "CHAPDES-BEAUFORT",
      },
      {
        CITY: "CHAPEIRY",
      },
      {
        CITY: "CHAPELLE-VALLON",
      },
      {
        CITY: "CHAPELLE-VOLAND",
      },
      {
        CITY: "CHAPONNAY",
      },
      {
        CITY: "CHAPONOST",
      },
      {
        CITY: "CHAPTELAT",
      },
      {
        CITY: "CHAPTUZAT",
      },
      {
        CITY: "CHARANCIEU",
      },
      {
        CITY: "CHARANTONNAY",
      },
      {
        CITY: "CHARAVINES",
      },
      {
        CITY: "CHARBONNIERES-LES-BAINS",
      },
      {
        CITY: "CHARBONNIERES-LES-VARENNES",
      },
      {
        CITY: "CHARBUY",
      },
      {
        CITY: "CHARENTAY",
      },
      {
        CITY: "CHARENTON-LE-PONT",
      },
      {
        CITY: "CHARGE",
      },
      {
        CITY: "CHARGEY-LES-GRAY",
      },
      {
        CITY: "CHARGEY-LES-PORT",
      },
      {
        CITY: "CHARLEVAL",
      },
      {
        CITY: "CHARLEVAL",
      },
      {
        CITY: "CHARLEVILLE",
      },
      {
        CITY: "CHARLIEU",
      },
      {
        CITY: "CHARLY",
      },
      {
        CITY: "CHARLY-SUR-MARNE",
      },
      {
        CITY: "CHARMANT",
      },
      {
        CITY: "CHARMES",
      },
      {
        CITY: "CHARMES",
      },
      {
        CITY: "CHARMES-SUR-RHONE",
      },
      {
        CITY: "CHARMONT-EN-BEAUCE",
      },
      {
        CITY: "CHARMONT-SOUS-BARBUISE",
      },
      {
        CITY: "CHARNAS",
      },
      {
        CITY: "CHARNAY",
      },
      {
        CITY: "CHARNAY",
      },
      {
        CITY: "CHARNAY-LES-MACON",
      },
      {
        CITY: "CHARNECLES",
      },
      {
        CITY: "CHARNY",
      },
      {
        CITY: "CHAROLLES",
      },
      {
        CITY: "CHARONVILLE",
      },
      {
        CITY: "CHAROST",
      },
      {
        CITY: "CHARQUEMONT",
      },
      {
        CITY: "CHARRECEY",
      },
      {
        CITY: "CHARRON",
      },
      {
        CITY: "CHARROUX",
      },
      {
        CITY: "CHARSONVILLE",
      },
      {
        CITY: "CHARTEVES",
      },
      {
        CITY: "CHARTRES",
      },
      {
        CITY: "CHARTRES-DE-BRETAGNE",
      },
      {
        CITY: "CHARTRETTES",
      },
      {
        CITY: "CHARVIEU-CHAVAGNEUX",
      },
      {
        CITY: "CHARVONNEX",
      },
      {
        CITY: "CHAS",
      },
      {
        CITY: "CHASNAIS",
      },
      {
        CITY: "CHASNE-SUR-ILLET",
      },
      {
        CITY: "CHASPINHAC",
      },
      {
        CITY: "CHASSAGNES",
      },
      {
        CITY: "CHASSAGNY",
      },
      {
        CITY: "CHASSE-SUR-RHONE",
      },
      {
        CITY: "CHASSELAY",
      },
      {
        CITY: "CHASSEMY",
      },
      {
        CITY: "CHASSENARD",
      },
      {
        CITY: "CHASSENEUIL",
      },
      {
        CITY: "CHASSENEUIL-DU-POITOU",
      },
      {
        CITY: "CHASSENEUIL-SUR-BONNIEURE",
      },
      {
        CITY: "CHASSEY-LE-CAMP",
      },
      {
        CITY: "CHASSIERS",
      },
      {
        CITY: "CHASSIEU",
      },
      {
        CITY: "CHATAINCOURT",
      },
      {
        CITY: "CHATEAU",
      },
      {
        CITY: "CHATEAU-ARNOUX-SAINT-AUBAN",
      },
      {
        CITY: "CHATEAU-CHERVIX",
      },
      {
        CITY: "CHATEAU-CHINON(VILLE)",
      },
      {
        CITY: "CHATEAU-DU-LOIR",
      },
      {
        CITY: "CHATEAU-GONTIER",
      },
      {
        CITY: "CHATEAU-LANDON",
      },
      {
        CITY: "CHATEAU-PORCIEN",
      },
      {
        CITY: "CHATEAU-RENAULT",
      },
      {
        CITY: "CHATEAU-SALINS",
      },
      {
        CITY: "CHATEAU-SUR-EPTE",
      },
      {
        CITY: "CHATEAUBLEAU",
      },
      {
        CITY: "CHATEAUBOURG",
      },
      {
        CITY: "CHATEAUBRIAND",
      },
      {
        CITY: "CHATEAUBRIANT",
      },
      {
        CITY: "CHATEAUDOUBLE",
      },
      {
        CITY: "CHATEAUFORT",
      },
      {
        CITY: "CHATEAUGAY",
      },
      {
        CITY: "CHATEAUGIRON",
      },
      {
        CITY: "CHATEAUMEILLANT",
      },
      {
        CITY: "CHATEAUNEUF",
      },
      {
        CITY: "CHATEAUNEUF-DE-BORDETTE",
      },
      {
        CITY: "CHATEAUNEUF-DE-GADAGNE",
      },
      {
        CITY: "CHATEAUNEUF-DE-GALAURE",
      },
      {
        CITY: "CHATEAUNEUF-DU-FAOU",
      },
      {
        CITY: "CHATEAUNEUF-DU-RHONE",
      },
      {
        CITY: "CHATEAUNEUF-EN-THYMERAIS",
      },
      {
        CITY: "CHATEAUNEUF-GRASSE",
      },
      {
        CITY: "CHATEAUNEUF-LA-FORET",
      },
      {
        CITY: "CHATEAUNEUF-LE-ROUGE",
      },
      {
        CITY: "CHATEAUNEUF-LES-MARTIGUES",
      },
      {
        CITY: "CHATEAUNEUF-SUR-CHARENTE",
      },
      {
        CITY: "CHATEAUNEUF-SUR-LOIRE",
      },
      {
        CITY: "CHATEAUNEUF-SUR-SARTHE",
      },
      {
        CITY: "CHATEAURENARD",
      },
      {
        CITY: "CHATEAUVILLAIN",
      },
      {
        CITY: "CHATEL",
      },
      {
        CITY: "CHATEL-DE-NEUVRE",
      },
      {
        CITY: "CHATEL-GUYON",
      },
      {
        CITY: "CHATEL-SAINT-GERMAIN",
      },
      {
        CITY: "CHATEL-SUR-MOSELLE",
      },
      {
        CITY: "CHATELAILLON-PLAGE",
      },
      {
        CITY: "CHATELAINES",
      },
      {
        CITY: "CHATELAIS",
      },
      {
        CITY: "CHATELAUDREN",
      },
      {
        CITY: "CHATELDON",
      },
      {
        CITY: "CHATELET",
      },
      {
        CITY: "CHATELUS",
      },
      {
        CITY: "CHATELUS-LE-MARCHEIX",
      },
      {
        CITY: "CHATENAY-SUR-SEINE",
      },
      {
        CITY: "CHATENET",
      },
      {
        CITY: "CHATENOIS",
      },
      {
        CITY: "CHATENOIS",
      },
      {
        CITY: "CHATENOIS-LES-FORGES",
      },
      {
        CITY: "CHATENOY",
      },
      {
        CITY: "CHATENOY-LE-ROYAL",
      },
      {
        CITY: "CHATIGNONVILLE",
      },
      {
        CITY: "CHATILLON",
      },
      {
        CITY: "CHATILLON-COLIGNY",
      },
      {
        CITY: "CHATILLON-LA-PALUD",
      },
      {
        CITY: "CHATILLON-LE-DUC",
      },
      {
        CITY: "CHATILLON-LE-ROI",
      },
      {
        CITY: "CHATILLON-LES-SONS",
      },
      {
        CITY: "CHATILLON-SUR-CHALARONNE",
      },
      {
        CITY: "CHATILLON-SUR-CHER",
      },
      {
        CITY: "CHATILLON-SUR-INDRE",
      },
      {
        CITY: "CHATILLON-SUR-LOIRE",
      },
      {
        CITY: "CHATILLON-SUR-SAONE",
      },
      {
        CITY: "CHATILLON-SUR-SEICHE",
      },
      {
        CITY: "CHATILLON-SUR-THOUET",
      },
      {
        CITY: "CHATONNAY",
      },
      {
        CITY: "CHATONRUPT-SOMMERMONT",
      },
      {
        CITY: "CHATOU",
      },
      {
        CITY: "CHATRES",
      },
      {
        CITY: "CHATRES-SUR-CHER",
      },
      {
        CITY: "CHATTE",
      },
      {
        CITY: "CHATUZANGE-LE-GOUBET",
      },
      {
        CITY: "CHAUCHE",
      },
      {
        CITY: "CHAUDEFONDS-SUR-LAYON",
      },
      {
        CITY: "CHAUDEFONTAINE",
      },
      {
        CITY: "CHAUDON",
      },
      {
        CITY: "CHAUDRON-EN-MAUGES",
      },
      {
        CITY: "CHAUFFAILLES",
      },
      {
        CITY: "CHAUFFRY",
      },
      {
        CITY: "CHAUFOUR-LES-BONNIERES",
      },
      {
        CITY: "CHAUFOUR-NOTRE-DAME",
      },
      {
        CITY: "CHAULNES",
      },
      {
        CITY: "CHAUMES",
      },
      {
        CITY: "CHAUMES-EN-BRIE",
      },
      {
        CITY: "CHAUMONT",
      },
      {
        CITY: "CHAUMONT",
      },
      {
        CITY: "CHAUMONT-EN-VEXIN",
      },
      {
        CITY: "CHAUMONTEL",
      },
      {
        CITY: "CHAUMOUSEY",
      },
      {
        CITY: "CHAUNY",
      },
      {
        CITY: "CHAURAY",
      },
      {
        CITY: "CHAURIAT",
      },
      {
        CITY: "CHAUSSIN",
      },
      {
        CITY: "CHAUSSOY-EPAGNY",
      },
      {
        CITY: "CHAUSSY",
      },
      {
        CITY: "CHAUVE",
      },
      {
        CITY: "CHAUVIGNY",
      },
      {
        CITY: "CHAUVINCOURT-PROVEMONT",
      },
      {
        CITY: "CHAUVRY",
      },
      {
        CITY: "CHAUX",
      },
      {
        CITY: "CHAVAGNE",
      },
      {
        CITY: "CHAVAGNES-EN-PAILLERS",
      },
      {
        CITY: "CHAVAGNES-LES-REDOUX",
      },
      {
        CITY: "CHAVAIGNES",
      },
      {
        CITY: "CHAVANAY",
      },
      {
        CITY: "CHAVANNES-SUR-REYSSOUZE",
      },
      {
        CITY: "CHAVANOD",
      },
      {
        CITY: "CHAVANOZ",
      },
      {
        CITY: "CHAVAROUX",
      },
      {
        CITY: "CHAVENAY",
      },
      {
        CITY: "CHAVIGNY",
      },
      {
        CITY: "CHAVILLE",
      },
      {
        CITY: "CHAZELLES",
      },
      {
        CITY: "CHAZELLES-SUR-LYON",
      },
      {
        CITY: "CHECY",
      },
      {
        CITY: "CHEDIGNY",
      },
      {
        CITY: "CHEF-BOUTONNE",
      },
      {
        CITY: "CHEFFES",
      },
      {
        CITY: "CHEHERY",
      },
      {
        CITY: "CHELAN",
      },
      {
        CITY: "CHELLES",
      },
      {
        CITY: "CHEMAZE",
      },
      {
        CITY: "CHEMERE",
      },
      {
        CITY: "CHEMERE-LE-ROI",
      },
      {
        CITY: "CHEMERY",
      },
      {
        CITY: "CHEMERY-LES-DEUX",
      },
      {
        CITY: "CHEMILLE-MELAY",
      },
      {
        CITY: "CHEMILLY-SUR-SEREIN",
      },
      {
        CITY: "CHEMINOT",
      },
      {
        CITY: "CHEMY",
      },
      {
        CITY: "CHENAILLER-MASCHEIX",
      },
      {
        CITY: "CHENAUD",
      },
      {
        CITY: "CHENAY-LE-CHATEL",
      },
      {
        CITY: "CHENERAILLES",
      },
      {
        CITY: "CHENEVELLES",
      },
      {
        CITY: "CHENEX",
      },
      {
        CITY: "CHENIMENIL",
      },
      {
        CITY: "CHENNEVIERES-SUR-MARNE",
      },
      {
        CITY: "CHENONCEAUX",
      },
      {
        CITY: "CHENOU",
      },
      {
        CITY: "CHENOVE",
      },
      {
        CITY: "CHENS-SUR-LEMAN",
      },
      {
        CITY: "CHENY",
      },
      {
        CITY: "CHEPOIX",
      },
      {
        CITY: "CHEPTAINVILLE",
      },
      {
        CITY: "CHEPY",
      },
      {
        CITY: "CHERAC",
      },
      {
        CITY: "CHERBOURG-OCTEVILLE",
      },
      {
        CITY: "CHERENG",
      },
      {
        CITY: "CHERET",
      },
      {
        CITY: "CHERIER",
      },
      {
        CITY: "CHERISEY",
      },
      {
        CITY: "CHERISY",
      },
      {
        CITY: "CHERON",
      },
      {
        CITY: "CHEROY",
      },
      {
        CITY: "CHERRE",
      },
      {
        CITY: "CHERREAU",
      },
      {
        CITY: "CHERVES-CHATELARS",
      },
      {
        CITY: "CHERVES-RICHEMONT",
      },
      {
        CITY: "CHERVETTES",
      },
      {
        CITY: "CHERVEUX",
      },
      {
        CITY: "CHESLEY",
      },
      {
        CITY: "CHESSY",
      },
      {
        CITY: "CHESSY",
      },
      {
        CITY: "CHESSY-LES-PRES",
      },
      {
        CITY: "CHEUX",
      },
      {
        CITY: "CHEVAIGNE",
      },
      {
        CITY: "CHEVAL-BLANC",
      },
      {
        CITY: "CHEVALET-LE-HAUT",
      },
      {
        CITY: "CHEVANCEAUX",
      },
      {
        CITY: "CHEVANNES",
      },
      {
        CITY: "CHEVANNES",
      },
      {
        CITY: "CHEVERNY",
      },
      {
        CITY: "CHEVIGNY",
      },
      {
        CITY: "CHEVIGNY-SAINT-SAUVEUR",
      },
      {
        CITY: "CHEVILLON",
      },
      {
        CITY: "CHEVILLON",
      },
      {
        CITY: "CHEVILLON-SUR-HUILLARD",
      },
      {
        CITY: "CHEVILLY",
      },
      {
        CITY: "CHEVILLY-LARUE",
      },
      {
        CITY: "CHEVINCOURT",
      },
      {
        CITY: "CHEVIRE-LE-ROUGE",
      },
      {
        CITY: "CHEVRAINVILLIERS",
      },
      {
        CITY: "CHEVREMONT",
      },
      {
        CITY: "CHEVREUSE",
      },
      {
        CITY: "CHEVRIER",
      },
      {
        CITY: "CHEVRIERES",
      },
      {
        CITY: "CHEVRIERES",
      },
      {
        CITY: "CHEVROTAINE",
      },
      {
        CITY: "CHEVRU",
      },
      {
        CITY: "CHEVRY",
      },
      {
        CITY: "CHEVRY-COSSIGNY",
      },
      {
        CITY: "CHEVRY-EN-SEREINE",
      },
      {
        CITY: "CHEYSSIEU",
      },
      {
        CITY: "CHEZERY-FORENS",
      },
      {
        CITY: "CHEZY",
      },
      {
        CITY: "CHEZY-SUR-MARNE",
      },
      {
        CITY: "CHICHE",
      },
      {
        CITY: "CHICHERY",
      },
      {
        CITY: "CHIERRY",
      },
      {
        CITY: "CHIEULLES",
      },
      {
        CITY: "CHIGNIN",
      },
      {
        CITY: "CHIGNY-LES-ROSES",
      },
      {
        CITY: "CHIGY",
      },
      {
        CITY: "CHILHAC",
      },
      {
        CITY: "CHILLE",
      },
      {
        CITY: "CHILLY",
      },
      {
        CITY: "CHILLY-MAZARIN",
      },
      {
        CITY: "CHIMILIN",
      },
      {
        CITY: "CHINON",
      },
      {
        CITY: "CHIRE-EN-MONTREUIL",
      },
      {
        CITY: "CHIRENS",
      },
      {
        CITY: "CHIRY-OURSCAMP",
      },
      {
        CITY: "CHISSAY-EN-TOURAINE",
      },
      {
        CITY: "CHISSEAUX",
      },
      {
        CITY: "CHISSEY-EN-MORVAN",
      },
      {
        CITY: "CHITENAY",
      },
      {
        CITY: "CHIVRES-EN-LAONNOIS",
      },
      {
        CITY: "CHIZE",
      },
      {
        CITY: "CHOCQUES",
      },
      {
        CITY: "CHOISEL",
      },
      {
        CITY: "CHOISEY",
      },
      {
        CITY: "CHOISY",
      },
      {
        CITY: "CHOISY-AU-BAC",
      },
      {
        CITY: "CHOISY-EN-BRIE",
      },
      {
        CITY: "CHOISY-LE-ROI",
      },
      {
        CITY: "CHOLET",
      },
      {
        CITY: "CHOMERAC",
      },
      {
        CITY: "CHORGES",
      },
      {
        CITY: "CHOURGNAC",
      },
      {
        CITY: "CHOUY",
      },
      {
        CITY: "CHOYE",
      },
      {
        CITY: "CHOZEAU",
      },
      {
        CITY: "CHUELLES",
      },
      {
        CITY: "CHUZELLES",
      },
      {
        CITY: "CIBOURE",
      },
      {
        CITY: "CIEL",
      },
      {
        CITY: "CIERREY",
      },
      {
        CITY: "CIEURAC",
      },
      {
        CITY: "CIEUX",
      },
      {
        CITY: "CINQ-MARS-LA-PILE",
      },
      {
        CITY: "CINQUEUX",
      },
      {
        CITY: "CINTEGABELLE",
      },
      {
        CITY: "CINTHEAUX",
      },
      {
        CITY: "CINTRE",
      },
      {
        CITY: "CIRES-LES-MELLO",
      },
      {
        CITY: "CIREY",
      },
      {
        CITY: "CIREY-SUR-VEZOUZE",
      },
      {
        CITY: "CIRON",
      },
      {
        CITY: "CIRY-LE-NOBLE",
      },
      {
        CITY: "CISSAC-MEDOC",
      },
      {
        CITY: "CISSE",
      },
      {
        CITY: "CISTRIERES",
      },
      {
        CITY: "CITERNE",
      },
      {
        CITY: "CITRY",
      },
      {
        CITY: "CIVAUX",
      },
      {
        CITY: "CIVENS",
      },
      {
        CITY: "CIVRAC-DE-BLAYE",
      },
      {
        CITY: "CIVRAC-EN-MEDOC",
      },
      {
        CITY: "CIVRAY",
      },
      {
        CITY: "CIVRAY",
      },
      {
        CITY: "CIVRAY-DE-TOURAINE",
      },
      {
        CITY: "CIVRIEUX",
      },
      {
        CITY: "CIVRY-EN-MONTAGNE",
      },
      {
        CITY: "CIZE",
      },
      {
        CITY: "CIZELY",
      },
      {
        CITY: "CLAIRA",
      },
      {
        CITY: "CLAIRAC",
      },
      {
        CITY: "CLAIRFONTAINE",
      },
      {
        CITY: "CLAIRMARAIS",
      },
      {
        CITY: "CLAIROIX",
      },
      {
        CITY: "CLAIRVAUX-DAVEYRON",
      },
      {
        CITY: "CLAIRVAUX-LES-LACS",
      },
      {
        CITY: "CLAIX",
      },
      {
        CITY: "CLAIX",
      },
      {
        CITY: "CLAMART",
      },
      {
        CITY: "CLAMECY",
      },
      {
        CITY: "CLAMECY",
      },
      {
        CITY: "CLAOUEY",
      },
      {
        CITY: "CLAPIERS",
      },
      {
        CITY: "CLARAC",
      },
      {
        CITY: "CLARACQ",
      },
      {
        CITY: "CLARAFOND",
      },
      {
        CITY: "CLARENSAC",
      },
      {
        CITY: "CLARQUES",
      },
      {
        CITY: "CLARY",
      },
      {
        CITY: "CLASSUN",
      },
      {
        CITY: "CLASVILLE",
      },
      {
        CITY: "CLAVEL",
      },
      {
        CITY: "CLAVETTE",
      },
      {
        CITY: "CLAVIERS",
      },
      {
        CITY: "CLAVILLE",
      },
      {
        CITY: "CLAYE-SOUILLY",
      },
      {
        CITY: "CLEDER",
      },
      {
        CITY: "CLEGUER",
      },
      {
        CITY: "CLEGUEREC",
      },
      {
        CITY: "CLELLES",
      },
      {
        CITY: "CLEMENT",
      },
      {
        CITY: "CLEMERY",
      },
      {
        CITY: "CLEMONT",
      },
      {
        CITY: "CLENAY",
      },
      {
        CITY: "CLEON",
      },
      {
        CITY: "CLERIEUX",
      },
      {
        CITY: "CLERMONT",
      },
      {
        CITY: "CLERMONT",
      },
      {
        CITY: "CLERMONT",
      },
      {
        CITY: "CLERMONT",
      },
      {
        CITY: "CLERMONT-FERRAND",
      },
      {
        CITY: "CLERVAL",
      },
      {
        CITY: "CLERY-SAINT-ANDRE",
      },
      {
        CITY: "CLESSE",
      },
      {
        CITY: "CLETY",
      },
      {
        CITY: "CLEVILLIERS",
      },
      {
        CITY: "CLICHY",
      },
      {
        CITY: "CLICHY-SOUS-BOIS",
      },
      {
        CITY: "CLINCHAMPS-SUR-ORNE",
      },
      {
        CITY: "CLIRON",
      },
      {
        CITY: "CLISSON",
      },
      {
        CITY: "CLOHARS-CARNOET",
      },
      {
        CITY: "CLOHARS-FOUESNANT",
      },
      {
        CITY: "CLOUANGE",
      },
      {
        CITY: "CLOUZEAU",
      },
      {
        CITY: "CLOYES-SUR-LE-LOIR",
      },
      {
        CITY: "CLUNY",
      },
      {
        CITY: "CLUSES",
      },
      {
        CITY: "COARAZE",
      },
      {
        CITY: "COARRAZE",
      },
      {
        CITY: "COBRIEUX",
      },
      {
        CITY: "COCHEREN",
      },
      {
        CITY: "COCUMONT",
      },
      {
        CITY: "CODOGNAN",
      },
      {
        CITY: "CODOLET",
      },
      {
        CITY: "COESMES",
      },
      {
        CITY: "COEUVRES-ET-VALSERY",
      },
      {
        CITY: "COEX",
      },
      {
        CITY: "COGLES",
      },
      {
        CITY: "COGNA",
      },
      {
        CITY: "COGNAC",
      },
      {
        CITY: "COGNIN",
      },
      {
        CITY: "COGOLIN",
      },
      {
        CITY: "COIGNET",
      },
      {
        CITY: "COIGNIERES",
      },
      {
        CITY: "COIMERES",
      },
      {
        CITY: "COINCY",
      },
      {
        CITY: "COIRAC",
      },
      {
        CITY: "COISE-SAINT-JEAN-PIED-GAUTHIER",
      },
      {
        CITY: "COIVREL",
      },
      {
        CITY: "COLAYRAC-SAINT-CIRQ",
      },
      {
        CITY: "COLEMBERT",
      },
      {
        CITY: "COLIGNY",
      },
      {
        CITY: "COLLANDRES-QUINCARNON",
      },
      {
        CITY: "COLLEGIEN",
      },
      {
        CITY: "COLLERET",
      },
      {
        CITY: "COLLEVILLE",
      },
      {
        CITY: "COLLEVILLE-MONTGOMERY",
      },
      {
        CITY: "COLLIAS",
      },
      {
        CITY: "COLLIOURE",
      },
      {
        CITY: "COLLOBRIERES",
      },
      {
        CITY: "COLLONGES-LES-PREMIERES",
      },
      {
        CITY: "COLLONGES-SOUS-SALEVE",
      },
      {
        CITY: "COLLORGUES",
      },
      {
        CITY: "COLMAR",
      },
      {
        CITY: "COLMARS",
      },
      {
        CITY: "COLOGNE",
      },
      {
        CITY: "COLOMARS",
      },
      {
        CITY: "COLOMBE",
      },
      {
        CITY: "COLOMBELLES",
      },
      {
        CITY: "COLOMBES",
      },
      {
        CITY: "COLOMBIER",
      },
      {
        CITY: "COLOMBIER",
      },
      {
        CITY: "COLOMBIER-FONTAINE",
      },
      {
        CITY: "COLOMBIER-LE-CARDINAL",
      },
      {
        CITY: "COLOMBIER-LE-VIEUX",
      },
      {
        CITY: "COLOMBIER-SAUGNIEU",
      },
      {
        CITY: "COLOMBIERES-SUR-ORB",
      },
      {
        CITY: "COLOMBIERS",
      },
      {
        CITY: "COLOMBY-SUR-THAON",
      },
      {
        CITY: "COLOMIERS",
      },
      {
        CITY: "COLOMIEU",
      },
      {
        CITY: "COLONDANNES",
      },
      {
        CITY: "COLONZELLE",
      },
      {
        CITY: "COLPO",
      },
      {
        CITY: "COLTAINVILLE",
      },
      {
        CITY: "COMBAS",
      },
      {
        CITY: "COMBLANCHIEN",
      },
      {
        CITY: "COMBLOUX",
      },
      {
        CITY: "COMBON",
      },
      {
        CITY: "COMBOURG",
      },
      {
        CITY: "COMBRAND",
      },
      {
        CITY: "COMBREE",
      },
      {
        CITY: "COMBRIT",
      },
      {
        CITY: "COMBRONDE",
      },
      {
        CITY: "COMBS-LA-VILLE",
      },
      {
        CITY: "COMINES",
      },
      {
        CITY: "COMMELLE-VERNAY",
      },
      {
        CITY: "COMMENCHON",
      },
      {
        CITY: "COMMENSACQ",
      },
      {
        CITY: "COMMENTRY",
      },
      {
        CITY: "COMMENY",
      },
      {
        CITY: "COMMEQUIERS",
      },
      {
        CITY: "COMMER",
      },
      {
        CITY: "COMMERCY",
      },
      {
        CITY: "COMMES",
      },
      {
        CITY: "COMMISSEY",
      },
      {
        CITY: "COMMUNAY",
      },
      {
        CITY: "COMPANS",
      },
      {
        CITY: "COMPERTRIX",
      },
      {
        CITY: "COMPOLIBAT",
      },
      {
        CITY: "COMPREIGNAC",
      },
      {
        CITY: "COMPS",
      },
      {
        CITY: "CONCARNEAU",
      },
      {
        CITY: "CONCEZE",
      },
      {
        CITY: "CONCHES-EN-OUCHE",
      },
      {
        CITY: "CONCHES-SUR-GONDOIRE",
      },
      {
        CITY: "CONCHY-LES-POTS",
      },
      {
        CITY: "CONDAC",
      },
      {
        CITY: "CONDAT",
      },
      {
        CITY: "CONDAT-SUR-VIENNE",
      },
      {
        CITY: "CONDE-EN-BRIE",
      },
      {
        CITY: "CONDE-NORTHEN",
      },
      {
        CITY: "CONDE-SAINTE-LIBIAIRE",
      },
      {
        CITY: "CONDE-SUR-ITON",
      },
      {
        CITY: "CONDE-SUR-MARNE",
      },
      {
        CITY: "CONDE-SUR-NOIREAU",
      },
      {
        CITY: "CONDE-SUR-SARTHE",
      },
      {
        CITY: "CONDE-SUR-SUIPPE",
      },
      {
        CITY: "CONDE-SUR-VESGRE",
      },
      {
        CITY: "CONDE-SUR-VIRE",
      },
      {
        CITY: "CONDECOURT",
      },
      {
        CITY: "CONDEON",
      },
      {
        CITY: "CONDETTE",
      },
      {
        CITY: "CONDEZAYGUES",
      },
      {
        CITY: "CONDILLAC",
      },
      {
        CITY: "CONDOM",
      },
      {
        CITY: "CONDRIEU",
      },
      {
        CITY: "CONFLANS-EN-JARNISY",
      },
      {
        CITY: "CONFLANS-SAINTE-HONORINE",
      },
      {
        CITY: "CONFLANS-SUR-LOING",
      },
      {
        CITY: "CONFLANS-SUR-SEINE",
      },
      {
        CITY: "CONFOLENS",
      },
      {
        CITY: "CONGENIES",
      },
      {
        CITY: "CONLIEGE",
      },
      {
        CITY: "CONNANTRAY-VAUREFROY",
      },
      {
        CITY: "CONNE-DE-LABARDE",
      },
      {
        CITY: "CONNERRE",
      },
      {
        CITY: "CONQUEREUIL",
      },
      {
        CITY: "CONQUES-SUR-ORBIEL",
      },
      {
        CITY: "CONS-SAINTE-COLOMBE",
      },
      {
        CITY: "CONTAMINE-SUR-ARVE",
      },
      {
        CITY: "CONTES",
      },
      {
        CITY: "CONTIGNE",
      },
      {
        CITY: "CONTOIRE",
      },
      {
        CITY: "CONTRE",
      },
      {
        CITY: "CONTRES",
      },
      {
        CITY: "CONTREXEVILLE",
      },
      {
        CITY: "CONTRISSON",
      },
      {
        CITY: "CONTZ-LES-BAINS",
      },
      {
        CITY: "COPPONEX",
      },
      {
        CITY: "COQUELLES",
      },
      {
        CITY: "CORANCEZ",
      },
      {
        CITY: "CORAY",
      },
      {
        CITY: "CORBARA",
      },
      {
        CITY: "CORBARIEU",
      },
      {
        CITY: "CORBAS",
      },
      {
        CITY: "CORBEHEM",
      },
      {
        CITY: "CORBEIL-CERF",
      },
      {
        CITY: "CORBEIL-ESSONNES",
      },
      {
        CITY: "CORBEILLES",
      },
      {
        CITY: "CORBELIN",
      },
      {
        CITY: "CORBENY",
      },
      {
        CITY: "CORBERE-ABERES",
      },
      {
        CITY: "CORBERE-LES-CABANES",
      },
      {
        CITY: "CORBIE",
      },
      {
        CITY: "CORBIERES",
      },
      {
        CITY: "CORBIERES",
      },
      {
        CITY: "CORBIGNY",
      },
      {
        CITY: "CORBONOD",
      },
      {
        CITY: "CORBREUSE",
      },
      {
        CITY: "CORCELLES-LES-CITEAUX",
      },
      {
        CITY: "CORCELLES-LES-MONTS",
      },
      {
        CITY: "CORCIEUX",
      },
      {
        CITY: "CORCONNE",
      },
      {
        CITY: "CORCY",
      },
      {
        CITY: "CORDELLE",
      },
      {
        CITY: "CORDEMAIS",
      },
      {
        CITY: "CORDES-SUR-CIEL",
      },
      {
        CITY: "CORDES-TOLOSANNES",
      },
      {
        CITY: "CORDON",
      },
      {
        CITY: "CORENC",
      },
      {
        CITY: "CORGNAC-SUR-LISLE",
      },
      {
        CITY: "CORGOLOIN",
      },
      {
        CITY: "CORIGNAC",
      },
      {
        CITY: "CORLAY",
      },
      {
        CITY: "CORMAINVILLE",
      },
      {
        CITY: "CORME-ECLUSE",
      },
      {
        CITY: "CORME-ROYAL",
      },
      {
        CITY: "CORMEILLES",
      },
      {
        CITY: "CORMEILLES-EN-PARISIS",
      },
      {
        CITY: "CORMEILLES-EN-VEXIN",
      },
      {
        CITY: "CORMELLES-LE-ROYAL",
      },
      {
        CITY: "CORMERAY",
      },
      {
        CITY: "CORMERY",
      },
      {
        CITY: "CORMES",
      },
      {
        CITY: "CORMICY",
      },
      {
        CITY: "CORMOLAIN",
      },
      {
        CITY: "CORMONTREUIL",
      },
      {
        CITY: "CORMORANCHE-SUR-SAONE",
      },
      {
        CITY: "CORNAC",
      },
      {
        CITY: "CORNAS",
      },
      {
        CITY: "CORNE",
      },
      {
        CITY: "CORNEBARRIEU",
      },
      {
        CITY: "CORNEILLA-DE-CONFLENT",
      },
      {
        CITY: "CORNEILLA-DEL-VERCOL",
      },
      {
        CITY: "CORNEILLA-LA-RIVIERE",
      },
      {
        CITY: "CORNIER",
      },
      {
        CITY: "CORNILLE",
      },
      {
        CITY: "CORNILLON-CONFOUX",
      },
      {
        CITY: "CORNILLON-EN-TRIEVES",
      },
      {
        CITY: "CORNIMONT",
      },
      {
        CITY: "CORNUS",
      },
      {
        CITY: "CORNY-SUR-MOSELLE",
      },
      {
        CITY: "CORON",
      },
      {
        CITY: "CORPE",
      },
      {
        CITY: "CORPS-NUDS",
      },
      {
        CITY: "CORREZE",
      },
      {
        CITY: "CORROY",
      },
      {
        CITY: "CORS",
      },
      {
        CITY: "CORSEPT",
      },
      {
        CITY: "CORSEUL",
      },
      {
        CITY: "CORTE",
      },
      {
        CITY: "CORVEISSIAT",
      },
      {
        CITY: "CORZE",
      },
      {
        CITY: "COSGES",
      },
      {
        CITY: "COSMES",
      },
      {
        CITY: "COSNE-COURS-SUR-LOIRE",
      },
      {
        CITY: "COSQUEVILLE",
      },
      {
        CITY: "COSSE-LE-VIVIEN",
      },
      {
        CITY: "COSSESSEVILLE",
      },
      {
        CITY: "COSTAROS",
      },
      {
        CITY: "COTDOUSSAN",
      },
      {
        CITY: "COTIGNAC",
      },
      {
        CITY: "COTTENCHY",
      },
      {
        CITY: "COTTEVRARD",
      },
      {
        CITY: "COTTUN",
      },
      {
        CITY: "COUARGUES",
      },
      {
        CITY: "COUBLEVIE",
      },
      {
        CITY: "COUBON",
      },
      {
        CITY: "COUBRON",
      },
      {
        CITY: "COUCHES",
      },
      {
        CITY: "COUCHEY",
      },
      {
        CITY: "COUCY-LE-CHATEAU-AUFFRIQUE",
      },
      {
        CITY: "COUDDES",
      },
      {
        CITY: "COUDEHARD",
      },
      {
        CITY: "COUDEKERQUE-BRANCHE",
      },
      {
        CITY: "COUDES",
      },
      {
        CITY: "COUDOUX",
      },
      {
        CITY: "COUDRAY",
      },
      {
        CITY: "COUDRAY-AU-PERCHE",
      },
      {
        CITY: "COUDRAY-RABUT",
      },
      {
        CITY: "COUDROY",
      },
      {
        CITY: "COUDUN",
      },
      {
        CITY: "COUERON",
      },
      {
        CITY: "COUESMES-VAUCE",
      },
      {
        CITY: "COUFOULEUX",
      },
      {
        CITY: "COUHE",
      },
      {
        CITY: "COUILLY-PONT-AUX-DAMES",
      },
      {
        CITY: "COUIZA",
      },
      {
        CITY: "COULAINES",
      },
      {
        CITY: "COULANDON",
      },
      {
        CITY: "COULANS-SUR-GEE",
      },
      {
        CITY: "COULAURES",
      },
      {
        CITY: "COULLONS",
      },
      {
        CITY: "COULOGNE",
      },
      {
        CITY: "COULOISY",
      },
      {
        CITY: "COULOMBIERS",
      },
      {
        CITY: "COULOMBIERS",
      },
      {
        CITY: "COULOMBY",
      },
      {
        CITY: "COULOMMES",
      },
      {
        CITY: "COULOMMIERS",
      },
      {
        CITY: "COULOMMIERS-LA-TOUR",
      },
      {
        CITY: "COULON",
      },
      {
        CITY: "COULONCES",
      },
      {
        CITY: "COULONGES",
      },
      {
        CITY: "COULONGES-THOUARSAIS",
      },
      {
        CITY: "COULOUNIEIX-CHAMIERS",
      },
      {
        CITY: "COULOUVRAY-BOISBENATRE",
      },
      {
        CITY: "COULVAIN",
      },
      {
        CITY: "COULX",
      },
      {
        CITY: "COUME",
      },
      {
        CITY: "COUPELLE-VIEILLE",
      },
      {
        CITY: "COUPESARTE",
      },
      {
        CITY: "COUPEVILLE",
      },
      {
        CITY: "COUPIGNY",
      },
      {
        CITY: "COUPRU",
      },
      {
        CITY: "COUPVRAY",
      },
      {
        CITY: "COUR-CHEVERNY",
      },
      {
        CITY: "COUR-ET-BUIS",
      },
      {
        CITY: "COUR-SUR-LOIRE",
      },
      {
        CITY: "COURANCES",
      },
      {
        CITY: "COURBEVEILLE",
      },
      {
        CITY: "COURBEVOIE",
      },
      {
        CITY: "COURCAIS",
      },
      {
        CITY: "COURCAY",
      },
      {
        CITY: "COURCELLES",
      },
      {
        CITY: "COURCELLES",
      },
      {
        CITY: "COURCELLES-CHAUSSY",
      },
      {
        CITY: "COURCELLES-EN-BASSEE",
      },
      {
        CITY: "COURCELLES-EPAYELLES",
      },
      {
        CITY: "COURCELLES-LES-GISORS",
      },
      {
        CITY: "COURCELLES-LES-LENS",
      },
      {
        CITY: "COURCELLES-SUR-NIED",
      },
      {
        CITY: "COURCELLES-SUR-SEINE",
      },
      {
        CITY: "COURCELLES-SUR-VIOSNE",
      },
      {
        CITY: "COURCEMONT",
      },
      {
        CITY: "COURCERAC",
      },
      {
        CITY: "COURCHATON",
      },
      {
        CITY: "COURCHELETTES",
      },
      {
        CITY: "COURCHEVEL",
      },
      {
        CITY: "COURCON",
      },
      {
        CITY: "COURCOURONNES",
      },
      {
        CITY: "COURCY",
      },
      {
        CITY: "COURCY",
      },
      {
        CITY: "COURCY-AUX-LOGES",
      },
      {
        CITY: "COURDEMANCHE",
      },
      {
        CITY: "COURDEMANCHE",
      },
      {
        CITY: "COURDIMANCHE-SUR-ESSONNE",
      },
      {
        CITY: "COURGIS",
      },
      {
        CITY: "COURLANS",
      },
      {
        CITY: "COURLAOUX",
      },
      {
        CITY: "COURLAY",
      },
      {
        CITY: "COURMELLES",
      },
      {
        CITY: "COURMEMIN",
      },
      {
        CITY: "COURNON",
      },
      {
        CITY: "COURNON-DAUVERGNE",
      },
      {
        CITY: "COURNONSEC",
      },
      {
        CITY: "COURNONTERRAL",
      },
      {
        CITY: "COURPALAY",
      },
      {
        CITY: "COURPIERE",
      },
      {
        CITY: "COURQUETAINE",
      },
      {
        CITY: "COURS",
      },
      {
        CITY: "COURS-DE-MONSEGUR",
      },
      {
        CITY: "COURS-LA-VILLE",
      },
      {
        CITY: "COURS-LES-BAINS",
      },
      {
        CITY: "COURS-LES-BARRES",
      },
      {
        CITY: "COURSAC",
      },
      {
        CITY: "COURSAN",
      },
      {
        CITY: "COURSEULLES-SUR-MER",
      },
      {
        CITY: "COURSON",
      },
      {
        CITY: "COURSON-LES-CARRIERES",
      },
      {
        CITY: "COURSON-MONTELOUP",
      },
      {
        CITY: "COURTELEVANT",
      },
      {
        CITY: "COURTENAY",
      },
      {
        CITY: "COURTENAY",
      },
      {
        CITY: "COURTENOT",
      },
      {
        CITY: "COURTERANGES",
      },
      {
        CITY: "COURTHEZON",
      },
      {
        CITY: "COURTISOLS",
      },
      {
        CITY: "COURTOMER",
      },
      {
        CITY: "COURTONNE-LA-MEURDRAC",
      },
      {
        CITY: "COURTRY",
      },
      {
        CITY: "COURVILLE-SUR-EURE",
      },
      {
        CITY: "COURZIEU",
      },
      {
        CITY: "COUSANCE",
      },
      {
        CITY: "COUSANCES-LES-FORGES",
      },
      {
        CITY: "COUSOLRE",
      },
      {
        CITY: "COUSSAY-LES-BOIS",
      },
      {
        CITY: "COUTANCES",
      },
      {
        CITY: "COUTARNOUX",
      },
      {
        CITY: "COUTERNE",
      },
      {
        CITY: "COUTERNON",
      },
      {
        CITY: "COUTEUGES",
      },
      {
        CITY: "COUTEVROULT",
      },
      {
        CITY: "COUTICHES",
      },
      {
        CITY: "COUTRAS",
      },
      {
        CITY: "COUVAINS",
      },
      {
        CITY: "COUVILLE",
      },
      {
        CITY: "COUVRON-ET-AUMENCOURT",
      },
      {
        CITY: "COUX",
      },
      {
        CITY: "COUY",
      },
      {
        CITY: "COUZE-ET-SAINT-FRONT",
      },
      {
        CITY: "COUZEIX",
      },
      {
        CITY: "COUZON",
      },
      {
        CITY: "COYE-LA-FORET",
      },
      {
        CITY: "COYRIERE",
      },
      {
        CITY: "COZES",
      },
      {
        CITY: "CRACH",
      },
      {
        CITY: "CRAINCOURT",
      },
      {
        CITY: "CRAMANT",
      },
      {
        CITY: "CRAN-GEVRIER",
      },
      {
        CITY: "CRANCEY",
      },
      {
        CITY: "CRANCOT",
      },
      {
        CITY: "CRANSAC",
      },
      {
        CITY: "CRANVES-SALES",
      },
      {
        CITY: "CRAON",
      },
      {
        CITY: "CRAONNE",
      },
      {
        CITY: "CRAPONNE",
      },
      {
        CITY: "CRAS-SUR-REYSSOUZE",
      },
      {
        CITY: "CRAVANCHE",
      },
      {
        CITY: "CRAVANS",
      },
      {
        CITY: "CRAVANT",
      },
      {
        CITY: "CRAVANT-LES-COTEAUX",
      },
      {
        CITY: "CRAVENT",
      },
      {
        CITY: "CRAYSSAC",
      },
      {
        CITY: "CRAZANNES",
      },
      {
        CITY: "CREANCES",
      },
      {
        CITY: "CRECHES-SUR-SAONE",
      },
      {
        CITY: "CRECY-SUR-SERRE",
      },
      {
        CITY: "CREDIN",
      },
      {
        CITY: "CREGY-LES-MEAUX",
      },
      {
        CITY: "CREHANGE",
      },
      {
        CITY: "CREHEN",
      },
      {
        CITY: "CREIL",
      },
      {
        CITY: "CREISSAN",
      },
      {
        CITY: "CREISSELS",
      },
      {
        CITY: "CREMEAUX",
      },
      {
        CITY: "CREMIEU",
      },
      {
        CITY: "CREPEY",
      },
      {
        CITY: "CRESANCEY",
      },
      {
        CITY: "CRESPIERES",
      },
      {
        CITY: "CRESPIN",
      },
      {
        CITY: "CRESPIN",
      },
      {
        CITY: "CRESSAT",
      },
      {
        CITY: "CRESSE",
      },
      {
        CITY: "CRESSERONS",
      },
      {
        CITY: "CREST",
      },
      {
        CITY: "CREULLY",
      },
      {
        CITY: "CREUSOT",
      },
      {
        CITY: "CREUTZWALD",
      },
      {
        CITY: "CREUZIER-LE-NEUF",
      },
      {
        CITY: "CREUZIER-LE-VIEUX",
      },
      {
        CITY: "CREVANT-LAVEINE",
      },
      {
        CITY: "CREVECHAMPS",
      },
      {
        CITY: "CREVECOEUR-LE-GRAND",
      },
      {
        CITY: "CREVIN",
      },
      {
        CITY: "CREZANCY",
      },
      {
        CITY: "CRIEL-SUR-MER",
      },
      {
        CITY: "CRILLON-LE-BRAVE",
      },
      {
        CITY: "CRIMOLOIS",
      },
      {
        CITY: "CRISENOY",
      },
      {
        CITY: "CRISOLLES",
      },
      {
        CITY: "CRISSEY",
      },
      {
        CITY: "CRISSEY",
      },
      {
        CITY: "CROCHTE",
      },
      {
        CITY: "CROCQ",
      },
      {
        CITY: "CROISETTE",
      },
      {
        CITY: "CROISILLES",
      },
      {
        CITY: "CROISILLES",
      },
      {
        CITY: "CROISILLES",
      },
      {
        CITY: "CROISSANVILLE",
      },
      {
        CITY: "CROISSY-BEAUBOURG",
      },
      {
        CITY: "CROISSY-SUR-CELLE",
      },
      {
        CITY: "CROISSY-SUR-SEINE",
      },
      {
        CITY: "CROIX",
      },
      {
        CITY: "CROIX",
      },
      {
        CITY: "CROIX-CHAPEAU",
      },
      {
        CITY: "CROIX-DE-VIE",
      },
      {
        CITY: "CROIX-MARE",
      },
      {
        CITY: "CROIXANVEC",
      },
      {
        CITY: "CROLLES",
      },
      {
        CITY: "CROS-DE-RONESQUE",
      },
      {
        CITY: "CROSNE",
      },
      {
        CITY: "CROTTET",
      },
      {
        CITY: "CROUAY",
      },
      {
        CITY: "CROUTELLE",
      },
      {
        CITY: "CROUTTES",
      },
      {
        CITY: "CROUY-SUR-COSSON",
      },
      {
        CITY: "CROUY-SUR-OURCQ",
      },
      {
        CITY: "CROZE",
      },
      {
        CITY: "CROZES-HERMITAGE",
      },
      {
        CITY: "CROZON",
      },
      {
        CITY: "CRUAS",
      },
      {
        CITY: "CRUET",
      },
      {
        CITY: "CRUGNY",
      },
      {
        CITY: "CRUSCADES",
      },
      {
        CITY: "CRUSEILLES",
      },
      {
        CITY: "CRUSNES",
      },
      {
        CITY: "CRUZILLES-LES-MEPILLAT",
      },
      {
        CITY: "CRUZY",
      },
      {
        CITY: "CUBLAC",
      },
      {
        CITY: "CUBRY-LES-SOING",
      },
      {
        CITY: "CUBZAC-LES-PONTS",
      },
      {
        CITY: "CUCQ",
      },
      {
        CITY: "CUCURON",
      },
      {
        CITY: "CUERS",
      },
      {
        CITY: "CUFFIES",
      },
      {
        CITY: "CUFFY",
      },
      {
        CITY: "CUGAND",
      },
      {
        CITY: "CUGES-LES-PINS",
      },
      {
        CITY: "CUGNAUX",
      },
      {
        CITY: "CUIGY-EN-BRAY",
      },
      {
        CITY: "CUINCHY",
      },
      {
        CITY: "CUINCY",
      },
      {
        CITY: "CUINZIER",
      },
      {
        CITY: "CUISE-LA-MOTTE",
      },
      {
        CITY: "CUISEAUX",
      },
      {
        CITY: "CUISERY",
      },
      {
        CITY: "CULHAT",
      },
      {
        CITY: "CULMONT",
      },
      {
        CITY: "CULOZ",
      },
      {
        CITY: "CUMIERES",
      },
      {
        CITY: "CUNFIN",
      },
      {
        CITY: "CUNLHAT",
      },
      {
        CITY: "CUON",
      },
      {
        CITY: "CUPERLY",
      },
      {
        CITY: "CUQUERON",
      },
      {
        CITY: "CURCHY",
      },
      {
        CITY: "CURDIN",
      },
      {
        CITY: "CUREL",
      },
      {
        CITY: "CURGIES",
      },
      {
        CITY: "CURTAFOND",
      },
      {
        CITY: "CURZAY-SUR-VONNE",
      },
      {
        CITY: "CUSSAY",
      },
      {
        CITY: "CUSSET",
      },
      {
        CITY: "CUSSEY-LES-FORGES",
      },
      {
        CITY: "CUSSY-LES-FORGES",
      },
      {
        CITY: "CUSTINES",
      },
      {
        CITY: "CUSY",
      },
      {
        CITY: "CUTTOLI-CORTICCHIATO",
      },
      {
        CITY: "CUVERGNON",
      },
      {
        CITY: "CUVERVILLE",
      },
      {
        CITY: "CUVILLY",
      },
      {
        CITY: "CUVRY",
      },
      {
        CITY: "CUXAC-CABARDES",
      },
      {
        CITY: "CUY",
      },
      {
        CITY: "CUY-SAINT-FIACRE",
      },
      {
        CITY: "CUZIEU",
      },
      {
        CITY: "CYSOING",
      },
      {
        CITY: "DABO",
      },
      {
        CITY: "DACHSTEIN",
      },
      {
        CITY: "DADONVILLE",
      },
      {
        CITY: "DAGNEUX",
      },
      {
        CITY: "DAINVILLE",
      },
      {
        CITY: "DALHUNDEN",
      },
      {
        CITY: "DALLET",
      },
      {
        CITY: "DALMAS",
      },
      {
        CITY: "DAMAZAN",
      },
      {
        CITY: "DAMBACH-LA-VILLE",
      },
      {
        CITY: "DAMBENOIS",
      },
      {
        CITY: "DAME-MARIE",
      },
      {
        CITY: "DAMELEVIERES",
      },
      {
        CITY: "DAMEREY",
      },
      {
        CITY: "DAMERY",
      },
      {
        CITY: "DAMGAN",
      },
      {
        CITY: "DAMIATTE",
      },
      {
        CITY: "DAMIGNY",
      },
      {
        CITY: "DAMMARIE-LES-LYS",
      },
      {
        CITY: "DAMMARIE-SUR-SAULX",
      },
      {
        CITY: "DAMMARTIN-EN-GOELE",
      },
      {
        CITY: "DAMMARTIN-EN-SERVE",
      },
      {
        CITY: "DAMPARIS",
      },
      {
        CITY: "DAMPIERRE",
      },
      {
        CITY: "DAMPIERRE-AU-TEMPLE",
      },
      {
        CITY: "DAMPIERRE-EN-BURLY",
      },
      {
        CITY: "DAMPIERRE-EN-CROT",
      },
      {
        CITY: "DAMPIERRE-LES-BOIS",
      },
      {
        CITY: "DAMPIERRE-LES-CONFLANS",
      },
      {
        CITY: "DAMPIERRE-SUR-BLEVY",
      },
      {
        CITY: "DAMPIERRE-SUR-BOUTONNE",
      },
      {
        CITY: "DAMPMART",
      },
      {
        CITY: "DAMPRICHARD",
      },
      {
        CITY: "DAMPSMESNIL",
      },
      {
        CITY: "DAMPVALLEY-LES-COLOMBE",
      },
      {
        CITY: "DAMVILLE",
      },
      {
        CITY: "DAMVIX",
      },
      {
        CITY: "DANCEVOIR",
      },
      {
        CITY: "DANGEAU",
      },
      {
        CITY: "DANGEUL",
      },
      {
        CITY: "DANJOUTIN",
      },
      {
        CITY: "DANNE-ET-QUATRE-VENTS",
      },
      {
        CITY: "DANNEMOINE",
      },
      {
        CITY: "DAOULAS",
      },
      {
        CITY: "DARDILLY",
      },
      {
        CITY: "DAREIZE",
      },
      {
        CITY: "DARGNIES",
      },
      {
        CITY: "DARGOIRE",
      },
      {
        CITY: "DARMONT",
      },
      {
        CITY: "DARNETAL",
      },
      {
        CITY: "DARNEY",
      },
      {
        CITY: "DARNIEULLES",
      },
      {
        CITY: "DARVOY",
      },
      {
        CITY: "DASLE",
      },
      {
        CITY: "DAUBENSAND",
      },
      {
        CITY: "DAUBEUF-LA-CAMPAGNE",
      },
      {
        CITY: "DAUENDORF",
      },
      {
        CITY: "DAUMERAY",
      },
      {
        CITY: "DAUX",
      },
      {
        CITY: "DAUZAT-SUR-VODABLE",
      },
      {
        CITY: "DAVENESCOURT",
      },
      {
        CITY: "DAVEZIEUX",
      },
      {
        CITY: "DAX",
      },
      {
        CITY: "DEAUVILLE",
      },
      {
        CITY: "DECAZEVILLE",
      },
      {
        CITY: "DECHY",
      },
      {
        CITY: "DECINES-CHARPIEU",
      },
      {
        CITY: "DECIZE",
      },
      {
        CITY: "DELETTES",
      },
      {
        CITY: "DELLE",
      },
      {
        CITY: "DELME",
      },
      {
        CITY: "DEMOUVILLE",
      },
      {
        CITY: "DEMUIN",
      },
      {
        CITY: "DENAIN",
      },
      {
        CITY: "DENAT",
      },
      {
        CITY: "DENEE",
      },
      {
        CITY: "DENEVRE",
      },
      {
        CITY: "DENICE",
      },
      {
        CITY: "DENIER",
      },
      {
        CITY: "DENNEBROEUCQ",
      },
      {
        CITY: "DENNEVILLE",
      },
      {
        CITY: "DENNEY",
      },
      {
        CITY: "DENTING",
      },
      {
        CITY: "DERCY",
      },
      {
        CITY: "DERNANCOURT",
      },
      {
        CITY: "DERVAL",
      },
      {
        CITY: "DESAIGNES",
      },
      {
        CITY: "DESERTINES",
      },
      {
        CITY: "DESVRES",
      },
      {
        CITY: "DETRIER",
      },
      {
        CITY: "DETTWILLER",
      },
      {
        CITY: "DEUIL-LA-BARRE",
      },
      {
        CITY: "DEULEMONT",
      },
      {
        CITY: "DEVECEY",
      },
      {
        CITY: "DEVILLAC",
      },
      {
        CITY: "DEVILLE-LES-ROUEN",
      },
      {
        CITY: "DEVROUZE",
      },
      {
        CITY: "DEYME",
      },
      {
        CITY: "DEYVILLERS",
      },
      {
        CITY: "DEZIZE-LES-MARANGES",
      },
      {
        CITY: "DHUISY",
      },
      {
        CITY: "DIARVILLE",
      },
      {
        CITY: "DIDENHEIM",
      },
      {
        CITY: "DIE",
      },
      {
        CITY: "DIEBLING",
      },
      {
        CITY: "DIEFFENBACH-AU-VAL",
      },
      {
        CITY: "DIEMERINGEN",
      },
      {
        CITY: "DIEMOZ",
      },
      {
        CITY: "DIENVILLE",
      },
      {
        CITY: "DIEPPE",
      },
      {
        CITY: "DIEPPE-SOUS-DOUAUMONT",
      },
      {
        CITY: "DIERRE",
      },
      {
        CITY: "DIETWILLER",
      },
      {
        CITY: "DIEUE-SUR-MEUSE",
      },
      {
        CITY: "DIEULEFIT",
      },
      {
        CITY: "DIEULOUARD",
      },
      {
        CITY: "DIEUPENTALE",
      },
      {
        CITY: "DIEUZE",
      },
      {
        CITY: "DIEVAL",
      },
      {
        CITY: "DIFFEMBACH-LES-HELLIMER",
      },
      {
        CITY: "DIGES",
      },
      {
        CITY: "DIGNE-LES-BAINS",
      },
      {
        CITY: "DIGOIN",
      },
      {
        CITY: "DIJON",
      },
      {
        CITY: "DIMBSTHAL",
      },
      {
        CITY: "DIMECHAUX",
      },
      {
        CITY: "DINAN",
      },
      {
        CITY: "DINARD",
      },
      {
        CITY: "DINGE",
      },
      {
        CITY: "DINGSHEIM",
      },
      {
        CITY: "DINGY-EN-VUACHE",
      },
      {
        CITY: "DINGY-SAINT-CLAIR",
      },
      {
        CITY: "DINOZE",
      },
      {
        CITY: "DIONAY",
      },
      {
        CITY: "DISSAY",
      },
      {
        CITY: "DISSE-SOUS-BALLON",
      },
      {
        CITY: "DISTRE",
      },
      {
        CITY: "DISTROFF",
      },
      {
        CITY: "DIVE",
      },
      {
        CITY: "DIVES-SUR-MER",
      },
      {
        CITY: "DIVION",
      },
      {
        CITY: "DIVONNE-LES-BAINS",
      },
      {
        CITY: "DIXMONT",
      },
      {
        CITY: "DIZY",
      },
      {
        CITY: "DOAZIT",
      },
      {
        CITY: "DOCELLES",
      },
      {
        CITY: "DOEUIL-SUR-LE-MIGNON",
      },
      {
        CITY: "DOGNEVILLE",
      },
      {
        CITY: "DOINGT",
      },
      {
        CITY: "DOISSIN",
      },
      {
        CITY: "DOIX",
      },
      {
        CITY: "DOL-DE-BRETAGNE",
      },
      {
        CITY: "DOLE",
      },
      {
        CITY: "DOLLON",
      },
      {
        CITY: "DOLO",
      },
      {
        CITY: "DOLOMIEU",
      },
      {
        CITY: "DOMAGNE",
      },
      {
        CITY: "DOMAIZE",
      },
      {
        CITY: "DOMALAIN",
      },
      {
        CITY: "DOMANCY",
      },
      {
        CITY: "DOMART-SUR-LA-LUCE",
      },
      {
        CITY: "DOMATS",
      },
      {
        CITY: "DOMBASLE-SUR-MEURTHE",
      },
      {
        CITY: "DOMENE",
      },
      {
        CITY: "DOMERAT",
      },
      {
        CITY: "DOMESSARGUES",
      },
      {
        CITY: "DOMESSIN",
      },
      {
        CITY: "DOMEVRE-SUR-DURBION",
      },
      {
        CITY: "DOMEZAIN-BERRAUTE",
      },
      {
        CITY: "DOMFRONT",
      },
      {
        CITY: "DOMGERMAIN",
      },
      {
        CITY: "DOMJEVIN",
      },
      {
        CITY: "DOMMARTIN",
      },
      {
        CITY: "DOMMARTIN",
      },
      {
        CITY: "DOMMARTIN-LES-REMIREMONT",
      },
      {
        CITY: "DOMMARTIN-LES-TOUL",
      },
      {
        CITY: "DOMONT",
      },
      {
        CITY: "DOMPIERRE-LES-ORMES",
      },
      {
        CITY: "DOMPIERRE-SUR-BESBRE",
      },
      {
        CITY: "DOMPIERRE-SUR-MER",
      },
      {
        CITY: "DOMPIERRE-SUR-VEYLE",
      },
      {
        CITY: "DOMPIERRE-SUR-YON",
      },
      {
        CITY: "DONCHERY",
      },
      {
        CITY: "DONCOURT-LES-CONFLANS",
      },
      {
        CITY: "DONGES",
      },
      {
        CITY: "DONJEUX",
      },
      {
        CITY: "DONNEMARIE-DONTILLY",
      },
      {
        CITY: "DONNERY",
      },
      {
        CITY: "DONNEVILLE",
      },
      {
        CITY: "DONNEZAC",
      },
      {
        CITY: "DONTRIEN",
      },
      {
        CITY: "DONVILLE-LES-BAINS",
      },
      {
        CITY: "DONZACQ",
      },
      {
        CITY: "DONZERE",
      },
      {
        CITY: "DONZY",
      },
      {
        CITY: "DONZY-LE-PERTUIS",
      },
      {
        CITY: "DORDIVES",
      },
      {
        CITY: "DORLISHEIM",
      },
      {
        CITY: "DORMANS",
      },
      {
        CITY: "DORMELLES",
      },
      {
        CITY: "DORNES",
      },
      {
        CITY: "DORNOT",
      },
      {
        CITY: "DORTAN",
      },
      {
        CITY: "DORVAL",
      },
      {
        CITY: "DOUAI",
      },
      {
        CITY: "DOUARNENEZ",
      },
      {
        CITY: "DOUBS",
      },
      {
        CITY: "DOUCHY",
      },
      {
        CITY: "DOUCHY-LES-MINES",
      },
      {
        CITY: "DOUDEVILLE",
      },
      {
        CITY: "DOUE-LA-FONTAINE",
      },
      {
        CITY: "DOUILLY",
      },
      {
        CITY: "DOULEZON",
      },
      {
        CITY: "DOULLENS",
      },
      {
        CITY: "DOUMY",
      },
      {
        CITY: "DOUNOUX",
      },
      {
        CITY: "DOURDAIN",
      },
      {
        CITY: "DOURDAN",
      },
      {
        CITY: "DOURGES",
      },
      {
        CITY: "DOURGNE",
      },
      {
        CITY: "DOUSSARD",
      },
      {
        CITY: "DOUVAINE",
      },
      {
        CITY: "DOUVILLE",
      },
      {
        CITY: "DOUVRES",
      },
      {
        CITY: "DOUVRES-LA-DELIVRANDE",
      },
      {
        CITY: "DOUVRIN",
      },
      {
        CITY: "DOUX",
      },
      {
        CITY: "DOUY-LA-RAMEE",
      },
      {
        CITY: "DOUZY",
      },
      {
        CITY: "DOVILLE",
      },
      {
        CITY: "DOYET",
      },
      {
        CITY: "DOZULE",
      },
      {
        CITY: "DRACE",
      },
      {
        CITY: "DRACHENBRONN-BIRLENBACH",
      },
      {
        CITY: "DRACY-LE-FORT",
      },
      {
        CITY: "DRAGUIGNAN",
      },
      {
        CITY: "DRAILLANT",
      },
      {
        CITY: "DRAIN",
      },
      {
        CITY: "DRANCY",
      },
      {
        CITY: "DRAP",
      },
      {
        CITY: "DRAVEIL",
      },
      {
        CITY: "DREFFEAC",
      },
      {
        CITY: "DREMIL-LAFAGE",
      },
      {
        CITY: "DRESLINCOURT",
      },
      {
        CITY: "DREUIL-LES-AMIENS",
      },
      {
        CITY: "DREUX",
      },
      {
        CITY: "DREVILLE",
      },
      {
        CITY: "DROCOURT",
      },
      {
        CITY: "DROM",
      },
      {
        CITY: "DROUPT-SAINT-BASLE",
      },
      {
        CITY: "DROUVILLE",
      },
      {
        CITY: "DRUILLAT",
      },
      {
        CITY: "DRULINGEN",
      },
      {
        CITY: "DRUSENHEIM",
      },
      {
        CITY: "DRUYE",
      },
      {
        CITY: "DRUYES-LES-BELLES-FONTAINES",
      },
      {
        CITY: "DRY",
      },
      {
        CITY: "DUCEY",
      },
      {
        CITY: "DUCLAIR",
      },
      {
        CITY: "DUERNE",
      },
      {
        CITY: "DUGNY",
      },
      {
        CITY: "DUGNY-SUR-MEUSE",
      },
      {
        CITY: "DUINGT",
      },
      {
        CITY: "DUISANS",
      },
      {
        CITY: "DULLIN",
      },
      {
        CITY: "DUN-LE-POELIER",
      },
      {
        CITY: "DUN-SUR-AURON",
      },
      {
        CITY: "DUNEAU",
      },
      {
        CITY: "DUNES",
      },
      {
        CITY: "DUNIERE",
      },
      {
        CITY: "DUNKIRK",
      },
      {
        CITY: "DUPPIGHEIM",
      },
      {
        CITY: "DURANCE",
      },
      {
        CITY: "DURANUS",
      },
      {
        CITY: "DURBAN-CORBIERES",
      },
      {
        CITY: "DURCET",
      },
      {
        CITY: "DURETTE",
      },
      {
        CITY: "DURFORT",
      },
      {
        CITY: "DURLINSDORF",
      },
      {
        CITY: "DURMENACH",
      },
      {
        CITY: "DURRENBACH",
      },
      {
        CITY: "DURRENENTZEN",
      },
      {
        CITY: "DURSTEL",
      },
      {
        CITY: "DURTAL",
      },
      {
        CITY: "DURTOL",
      },
      {
        CITY: "DURY",
      },
      {
        CITY: "DUTTLENHEIM",
      },
      {
        CITY: "EANCE",
      },
      {
        CITY: "EAUBONNE",
      },
      {
        CITY: "EAUCOURT-SUR-SOMME",
      },
      {
        CITY: "EAUNES",
      },
      {
        CITY: "EAUX-BONNES",
      },
      {
        CITY: "EAUX-PUISEAUX",
      },
      {
        CITY: "EAUZE",
      },
      {
        CITY: "EBERSVILLER",
      },
      {
        CITY: "EBLANGE",
      },
      {
        CITY: "ECAILLON",
      },
      {
        CITY: "ECALLES-ALIX",
      },
      {
        CITY: "ECAQUELON",
      },
      {
        CITY: "ECARDENVILLE-LA-CAMPAGNE",
      },
      {
        CITY: "ECARDENVILLE-SUR-EURE",
      },
      {
        CITY: "ECAULT",
      },
      {
        CITY: "ECCLES",
      },
      {
        CITY: "ECHALAS",
      },
      {
        CITY: "ECHARCON",
      },
      {
        CITY: "ECHAUFFOUR",
      },
      {
        CITY: "ECHAVANNE",
      },
      {
        CITY: "ECHEMIRE",
      },
      {
        CITY: "ECHENANS",
      },
      {
        CITY: "ECHENEVEX",
      },
      {
        CITY: "ECHENON",
      },
      {
        CITY: "ECHENOZ-LA-MELINE",
      },
      {
        CITY: "ECHEVANNES",
      },
      {
        CITY: "ECHEVIS",
      },
      {
        CITY: "ECHILLAIS",
      },
      {
        CITY: "ECHINGHEN",
      },
      {
        CITY: "ECHIRE",
      },
      {
        CITY: "ECKBOLSHEIM",
      },
      {
        CITY: "ECKWERSHEIM",
      },
      {
        CITY: "ECLARON-BRAUCOURT-SAINTE-LIVIERE",
      },
      {
        CITY: "ECLEUX",
      },
      {
        CITY: "ECLUZELLES",
      },
      {
        CITY: "ECOCHE",
      },
      {
        CITY: "ECOLE",
      },
      {
        CITY: "ECOLE-VALENTIN",
      },
      {
        CITY: "ECOMMOY",
      },
      {
        CITY: "ECORCEI",
      },
      {
        CITY: "ECOS",
      },
      {
        CITY: "ECOUEN",
      },
      {
        CITY: "ECOUFLANT",
      },
      {
        CITY: "ECOURT-SAINT-QUENTIN",
      },
      {
        CITY: "ECOUVIEZ",
      },
      {
        CITY: "ECOYEUX",
      },
      {
        CITY: "ECQUES",
      },
      {
        CITY: "ECQUEVILLY",
      },
      {
        CITY: "ECRETTEVILLE-LES-BAONS",
      },
      {
        CITY: "ECROSNES",
      },
      {
        CITY: "ECROUVES",
      },
      {
        CITY: "ECTOT-LES-BAONS",
      },
      {
        CITY: "ECUEIL",
      },
      {
        CITY: "ECUEILLE",
      },
      {
        CITY: "ECUELLES",
      },
      {
        CITY: "ECUILLE",
      },
      {
        CITY: "ECUIRES",
      },
      {
        CITY: "ECUISSES",
      },
      {
        CITY: "ECULLY",
      },
      {
        CITY: "ECURY-SUR-COOLE",
      },
      {
        CITY: "EDERN",
      },
      {
        CITY: "EFFIAT",
      },
      {
        CITY: "EFFRY",
      },
      {
        CITY: "EGLETONS",
      },
      {
        CITY: "EGLIGNY",
      },
      {
        CITY: "EGLY",
      },
      {
        CITY: "EGREVILLE",
      },
      {
        CITY: "EGUILLES",
      },
      {
        CITY: "EICHHOFFEN",
      },
      {
        CITY: "EINVILLE-AU-JARD",
      },
      {
        CITY: "EL VOLO",
      },
      {
        CITY: "ELBEUF",
      },
      {
        CITY: "ELETOT",
      },
      {
        CITY: "ELEU-DIT-LEAUWETTE",
      },
      {
        CITY: "ELINCOURT",
      },
      {
        CITY: "ELINCOURT-SAINTE-MARGUERITE",
      },
      {
        CITY: "ELLIANT",
      },
      {
        CITY: "ELLON",
      },
      {
        CITY: "ELNA",
      },
      {
        CITY: "ELNES",
      },
      {
        CITY: "ELOISE",
      },
      {
        CITY: "ELOYES",
      },
      {
        CITY: "ELS BANYS DE LA PRESTA",
      },
      {
        CITY: "ELVEN",
      },
      {
        CITY: "EMANCE",
      },
      {
        CITY: "EMBRUN",
      },
      {
        CITY: "EMBRY",
      },
      {
        CITY: "EMERAINVILLE",
      },
      {
        CITY: "EMIEVILLE",
      },
      {
        CITY: "EMMERIN",
      },
      {
        CITY: "ENCHENBERG",
      },
      {
        CITY: "ENENCOURT-LE-SEC",
      },
      {
        CITY: "ENGHIEN-LES-BAINS",
      },
      {
        CITY: "ENGINS",
      },
      {
        CITY: "ENGLEFONTAINE",
      },
      {
        CITY: "ENGLOS",
      },
      {
        CITY: "ENNERY",
      },
      {
        CITY: "ENNERY",
      },
      {
        CITY: "ENNEVELIN",
      },
      {
        CITY: "ENNEZAT",
      },
      {
        CITY: "ENNORDRES",
      },
      {
        CITY: "ENQUIN-LES-MINES",
      },
      {
        CITY: "ENSISHEIM",
      },
      {
        CITY: "ENSUES-LA-REDONNE",
      },
      {
        CITY: "ENTRAIGUES-SUR-LA-SORGUE",
      },
      {
        CITY: "ENTRAINS-SUR-NOHAIN",
      },
      {
        CITY: "ENTRAMMES",
      },
      {
        CITY: "ENTRANGE",
      },
      {
        CITY: "ENTRAYGUES-SUR-TRUYERE",
      },
      {
        CITY: "ENTRE-DEUX-GUIERS",
      },
      {
        CITY: "ENTRECASTEAUX",
      },
      {
        CITY: "ENTREMONT-LE-VIEUX",
      },
      {
        CITY: "ENTRESSEN",
      },
      {
        CITY: "ENTZHEIM",
      },
      {
        CITY: "ENVEIG",
      },
      {
        CITY: "ENVERMEU",
      },
      {
        CITY: "EPAGNE-EPAGNETTE",
      },
      {
        CITY: "EPAGNY",
      },
      {
        CITY: "EPAIGNES",
      },
      {
        CITY: "EPEGARD",
      },
      {
        CITY: "EPENANCOURT",
      },
      {
        CITY: "EPENEDE",
      },
      {
        CITY: "EPERCIEUX-SAINT-PAUL",
      },
      {
        CITY: "EPERLECQUES",
      },
      {
        CITY: "EPERNON",
      },
      {
        CITY: "EPERTULLY",
      },
      {
        CITY: "EPERVANS",
      },
      {
        CITY: "EPFIG",
      },
      {
        CITY: "EPIAIS-RHUS",
      },
      {
        CITY: "EPIEDS-EN-BEAUCE",
      },
      {
        CITY: "EPIERRE",
      },
      {
        CITY: "EPINAC",
      },
      {
        CITY: "EPINAY",
      },
      {
        CITY: "EPINAY-SOUS-SENART",
      },
      {
        CITY: "EPINEUIL-LE-FLEURIEL",
      },
      {
        CITY: "EPINOUZE",
      },
      {
        CITY: "EPINOY",
      },
      {
        CITY: "EPISY",
      },
      {
        CITY: "EPOISSES",
      },
      {
        CITY: "EPONE",
      },
      {
        CITY: "EPOUVILLE",
      },
      {
        CITY: "EPPE-SAUVAGE",
      },
      {
        CITY: "EPPEVILLE",
      },
      {
        CITY: "EPPING",
      },
      {
        CITY: "EPUISAY",
      },
      {
        CITY: "EQUEURDREVILLE-HAINNEVILLE",
      },
      {
        CITY: "EQUIHEN-PLAGE",
      },
      {
        CITY: "ER",
      },
      {
        CITY: "ERAGNY",
      },
      {
        CITY: "ERBRAY",
      },
      {
        CITY: "ERBREE",
      },
      {
        CITY: "ERCE-EN-LAMEE",
      },
      {
        CITY: "ERCE-PRES-LIFFRE",
      },
      {
        CITY: "ERCEVILLE",
      },
      {
        CITY: "ERCHEU",
      },
      {
        CITY: "ERDEVEN",
      },
      {
        CITY: "ERGERSHEIM",
      },
      {
        CITY: "ERGNY",
      },
      {
        CITY: "ERGUE-GABERIC",
      },
      {
        CITY: "ERIZE-SAINT-DIZIER",
      },
      {
        CITY: "ERMENONVILLE",
      },
      {
        CITY: "ERMONT",
      },
      {
        CITY: "ERNEE",
      },
      {
        CITY: "ERNEMONT-SUR-BUCHY",
      },
      {
        CITY: "ERNESTVILLER",
      },
      {
        CITY: "ERNOLSHEIM-BRUCHE",
      },
      {
        CITY: "EROME",
      },
      {
        CITY: "EROUDEVILLE",
      },
      {
        CITY: "ERQUINGHEM-LYS",
      },
      {
        CITY: "ERQUINVILLERS",
      },
      {
        CITY: "ERQUY",
      },
      {
        CITY: "ERRE",
      },
      {
        CITY: "ERROUVILLE",
      },
      {
        CITY: "ERSTEIN",
      },
      {
        CITY: "ERVAUVILLE",
      },
      {
        CITY: "ESBARRES",
      },
      {
        CITY: "ESBLY",
      },
      {
        CITY: "ESCALQUENS",
      },
      {
        CITY: "ESCAMES",
      },
      {
        CITY: "ESCASSEFORT",
      },
      {
        CITY: "ESCAUDAIN",
      },
      {
        CITY: "ESCAUDOEUVRES",
      },
      {
        CITY: "ESCAUTPONT",
      },
      {
        CITY: "ESCAZEAUX",
      },
      {
        CITY: "ESCHAU",
      },
      {
        CITY: "ESCHBACH-AU-VAL",
      },
      {
        CITY: "ESCHENTZWILLER",
      },
      {
        CITY: "ESCHES",
      },
      {
        CITY: "ESCLAINVILLERS",
      },
      {
        CITY: "ESCOLIVES-SAINTE-CAMILLE",
      },
      {
        CITY: "ESCOMBRES-ET-LE-CHESNOIS",
      },
      {
        CITY: "ESCONDEAUX",
      },
      {
        CITY: "ESCORNEBOEUF",
      },
      {
        CITY: "ESCOU",
      },
      {
        CITY: "ESCOUT",
      },
      {
        CITY: "ESCOUTOUX",
      },
      {
        CITY: "ESCUROLLES",
      },
      {
        CITY: "ESERY",
      },
      {
        CITY: "ESLETTES",
      },
      {
        CITY: "ESMERY-HALLON",
      },
      {
        CITY: "ESNANDES",
      },
      {
        CITY: "ESNOUVEAUX",
      },
      {
        CITY: "ESPAGNAC",
      },
      {
        CITY: "ESPALAIS",
      },
      {
        CITY: "ESPALION",
      },
      {
        CITY: "ESPALY-SAINT-MARCEL",
      },
      {
        CITY: "ESPARRON-DE-VERDON",
      },
      {
        CITY: "ESPEDAILLAC",
      },
      {
        CITY: "ESPELETTE",
      },
      {
        CITY: "ESPELUCHE",
      },
      {
        CITY: "ESPEZEL",
      },
      {
        CITY: "ESPIET",
      },
      {
        CITY: "ESPINASSES",
      },
      {
        CITY: "ESPIRA-DE-CONFLENT",
      },
      {
        CITY: "ESPIRAT",
      },
      {
        CITY: "ESPONDEILHAN",
      },
      {
        CITY: "ESQUAY-NOTRE-DAME",
      },
      {
        CITY: "ESQUAY-SUR-SEULLES",
      },
      {
        CITY: "ESQUELBECQ",
      },
      {
        CITY: "ESQUERCHIN",
      },
      {
        CITY: "ESQUERDES",
      },
      {
        CITY: "ESQUIBIEN",
      },
      {
        CITY: "ESQUIEZE-SERE",
      },
      {
        CITY: "ESSEGNEY",
      },
      {
        CITY: "ESSERT",
      },
      {
        CITY: "ESSERTAUX",
      },
      {
        CITY: "ESSERTS-SALEVE",
      },
      {
        CITY: "ESSEY",
      },
      {
        CITY: "ESSEY-LES-NANCY",
      },
      {
        CITY: "ESSIA",
      },
      {
        CITY: "ESSIGNY-LE-GRAND",
      },
      {
        CITY: "ESSON",
      },
      {
        CITY: "ESSOYES",
      },
      {
        CITY: "ESTADENS",
      },
      {
        CITY: "ESTAGEL",
      },
      {
        CITY: "ESTAING",
      },
      {
        CITY: "ESTAIRES",
      },
      {
        CITY: "ESTERNAY",
      },
      {
        CITY: "ESTEVELLES",
      },
      {
        CITY: "ESTILLAC",
      },
      {
        CITY: "ESTIVAREILLES",
      },
      {
        CITY: "ESTOUCHES",
      },
      {
        CITY: "ESTOUTEVILLE-ECALLES",
      },
      {
        CITY: "ESTOUY",
      },
      {
        CITY: "ESTRABLIN",
      },
      {
        CITY: "ESTREE-BLANCHE",
      },
      {
        CITY: "ESTREES",
      },
      {
        CITY: "ESTREES-LES-CRECY",
      },
      {
        CITY: "ESTREES-SAINT-DENIS",
      },
      {
        CITY: "ESTREES-SUR-NOYE",
      },
      {
        CITY: "ESVRES",
      },
      {
        CITY: "ETABLE",
      },
      {
        CITY: "ETABLES",
      },
      {
        CITY: "ETABLES-SUR-MER",
      },
      {
        CITY: "ETAIMPUIS",
      },
      {
        CITY: "ETAING",
      },
      {
        CITY: "ETAINHUS",
      },
      {
        CITY: "ETALONDES",
      },
      {
        CITY: "ETAMPES-SUR-MARNE",
      },
      {
        CITY: "ETANG-SUR-ARROUX",
      },
      {
        CITY: "ETAULES",
      },
      {
        CITY: "ETAULIERS",
      },
      {
        CITY: "ETAUX",
      },
      {
        CITY: "ETAVES-ET-BOCQUIAUX",
      },
      {
        CITY: "ETEIMBES",
      },
      {
        CITY: "ETEL",
      },
      {
        CITY: "ETELFAY",
      },
      {
        CITY: "ETERCY",
      },
      {
        CITY: "ETERNOZ",
      },
      {
        CITY: "ETERVILLE",
      },
      {
        CITY: "ETEVAUX",
      },
      {
        CITY: "ETH",
      },
      {
        CITY: "ETIGNY",
      },
      {
        CITY: "ETIOLLES",
      },
      {
        CITY: "ETIVAL",
      },
      {
        CITY: "ETIVAL-CLAIREFONTAINE",
      },
      {
        CITY: "ETIVAL-LES-LE-MANS",
      },
      {
        CITY: "ETOGES",
      },
      {
        CITY: "ETOILE-SUR-RHONE",
      },
      {
        CITY: "ETOURVY",
      },
      {
        CITY: "ETOUVANS",
      },
      {
        CITY: "ETOUVY",
      },
      {
        CITY: "ETRECHY",
      },
      {
        CITY: "ETRECHY",
      },
      {
        CITY: "ETREILLERS",
      },
      {
        CITY: "ETRELLES",
      },
      {
        CITY: "ETREMBIERES",
      },
      {
        CITY: "ETREPAGNY",
      },
      {
        CITY: "ETREPILLY",
      },
      {
        CITY: "ETREUX",
      },
      {
        CITY: "ETREZ",
      },
      {
        CITY: "ETRIAC",
      },
      {
        CITY: "ETRICHE",
      },
      {
        CITY: "ETRICOURT-MANANCOURT",
      },
      {
        CITY: "ETROEUNGT",
      },
      {
        CITY: "ETROUSSAT",
      },
      {
        CITY: "ETTENDORF",
      },
      {
        CITY: "ETTING",
      },
      {
        CITY: "ETUPES",
      },
      {
        CITY: "ETUZ",
      },
      {
        CITY: "ETZLING",
      },
      {
        CITY: "EU",
      },
      {
        CITY: "EULMONT",
      },
      {
        CITY: "EUVILLE",
      },
      {
        CITY: "EVANS",
      },
      {
        CITY: "EVENOS",
      },
      {
        CITY: "EVERLY",
      },
      {
        CITY: "EVEUX",
      },
      {
        CITY: "EVIN-MALMAISON",
      },
      {
        CITY: "EVRAN",
      },
      {
        CITY: "EVRECY",
      },
      {
        CITY: "EVRON",
      },
      {
        CITY: "EXCENEVEX",
      },
      {
        CITY: "EXCIDEUIL",
      },
      {
        CITY: "EXIDEUIL",
      },
      {
        CITY: "EXINCOURT",
      },
      {
        CITY: "EXIREUIL",
      },
      {
        CITY: "EXOUDUN",
      },
      {
        CITY: "EYBENS",
      },
      {
        CITY: "EYBOULEUF",
      },
      {
        CITY: "EYGALIERES",
      },
      {
        CITY: "EYGUIERES",
      },
      {
        CITY: "EYGURANDE-ET-GARDEDEUIL",
      },
      {
        CITY: "EYJEAUX",
      },
      {
        CITY: "EYLIAC",
      },
      {
        CITY: "EYMET",
      },
      {
        CITY: "EYMOUTIERS",
      },
      {
        CITY: "EYRAGUES",
      },
      {
        CITY: "EYRANS",
      },
      {
        CITY: "EYSINES",
      },
      {
        CITY: "EYZIN-PINET",
      },
      {
        CITY: "EZANVILLE",
      },
      {
        CITY: "EZE",
      },
      {
        CITY: "EZY-SUR-EURE",
      },
      {
        CITY: "FABREGUES",
      },
      {
        CITY: "FABREZAN",
      },
      {
        CITY: "FACHES",
      },
      {
        CITY: "FACHES-THUMESNIL",
      },
      {
        CITY: "FAGNIERES",
      },
      {
        CITY: "FAIMBE",
      },
      {
        CITY: "FAINS-VEEL",
      },
      {
        CITY: "FALAISE",
      },
      {
        CITY: "FALAISE",
      },
      {
        CITY: "FALCK",
      },
      {
        CITY: "FALICON",
      },
      {
        CITY: "FALLERANS",
      },
      {
        CITY: "FALLERON",
      },
      {
        CITY: "FALLETANS",
      },
      {
        CITY: "FAMARS",
      },
      {
        CITY: "FAMECK",
      },
      {
        CITY: "FAMPOUX",
      },
      {
        CITY: "FANJEAUX",
      },
      {
        CITY: "FARAMANS",
      },
      {
        CITY: "FARBUS",
      },
      {
        CITY: "FAREBERSVILLER",
      },
      {
        CITY: "FAREINS",
      },
      {
        CITY: "FAREMOUTIERS",
      },
      {
        CITY: "FARGES-LES-CHALON",
      },
      {
        CITY: "FARGUES",
      },
      {
        CITY: "FARGUES",
      },
      {
        CITY: "FARGUES-SAINT-HILAIRE",
      },
      {
        CITY: "FARINCOURT",
      },
      {
        CITY: "FATOUVILLE-GRESTAIN",
      },
      {
        CITY: "FAUCH",
      },
      {
        CITY: "FAUCIGNY",
      },
      {
        CITY: "FAUCOGNEY-ET-LA-MER",
      },
      {
        CITY: "FAUCOMPIERRE",
      },
      {
        CITY: "FAULQUEMONT",
      },
      {
        CITY: "FAULX",
      },
      {
        CITY: "FAUMONT",
      },
      {
        CITY: "FAUQUEMBERGUES",
      },
      {
        CITY: "FAUVILLE-EN-CAUX",
      },
      {
        CITY: "FAVARS",
      },
      {
        CITY: "FAVERGES",
      },
      {
        CITY: "FAVERGES-DE-LA-TOUR",
      },
      {
        CITY: "FAVEROLLES-LA-CAMPAGNE",
      },
      {
        CITY: "FAVEROLLES-SUR-CHER",
      },
      {
        CITY: "FAVIERES",
      },
      {
        CITY: "FAVIERES",
      },
      {
        CITY: "FAY-AUX-LOGES",
      },
      {
        CITY: "FAY-DE-BRETAGNE",
      },
      {
        CITY: "FAY-LES-NEMOURS",
      },
      {
        CITY: "FAYENCE",
      },
      {
        CITY: "FAYET-LE-CHATEAU",
      },
      {
        CITY: "FAYL-BILLOT",
      },
      {
        CITY: "FAYS-LA-CHAPELLE",
      },
      {
        CITY: "FAYSSAC",
      },
      {
        CITY: "FEAS",
      },
      {
        CITY: "FECHAIN",
      },
      {
        CITY: "FEGERSHEIM",
      },
      {
        CITY: "FEGREAC",
      },
      {
        CITY: "FEIGERES",
      },
      {
        CITY: "FEIGNIES",
      },
      {
        CITY: "FEILLENS",
      },
      {
        CITY: "FEIN",
      },
      {
        CITY: "FEISSONS-SUR-ISERE",
      },
      {
        CITY: "FELDKIRCH",
      },
      {
        CITY: "FELINES",
      },
      {
        CITY: "FELINES",
      },
      {
        CITY: "FELIX",
      },
      {
        CITY: "FELLERING",
      },
      {
        CITY: "FELLETIN",
      },
      {
        CITY: "FENAIN",
      },
      {
        CITY: "FENAY",
      },
      {
        CITY: "FENDEILLE",
      },
      {
        CITY: "FENETRANGE",
      },
      {
        CITY: "FENEU",
      },
      {
        CITY: "FENEYROLS",
      },
      {
        CITY: "FENIOUX",
      },
      {
        CITY: "FENOUILLET",
      },
      {
        CITY: "FENOUILLET",
      },
      {
        CITY: "FERE-CHAMPENOISE",
      },
      {
        CITY: "FERE-EN-TARDENOIS",
      },
      {
        CITY: "FEREL",
      },
      {
        CITY: "FERFAY",
      },
      {
        CITY: "FERICY",
      },
      {
        CITY: "FERIN",
      },
      {
        CITY: "FERMANVILLE",
      },
      {
        CITY: "FERNEY-VOLTAIRE",
      },
      {
        CITY: "FEROLLES-ATTILLY",
      },
      {
        CITY: "FERQUES",
      },
      {
        CITY: "FERRALS-LES-CORBIERES",
      },
      {
        CITY: "FERREOL",
      },
      {
        CITY: "FERRETTE",
      },
      {
        CITY: "FERREUX-QUINCEY",
      },
      {
        CITY: "FERRIERE-LA-GRANDE",
      },
      {
        CITY: "FERRIERE-LA-PETITE",
      },
      {
        CITY: "FERRIERE-LARCON",
      },
      {
        CITY: "FERRIERES",
      },
      {
        CITY: "FERRIERES",
      },
      {
        CITY: "FERRIERES-EN-BRIE",
      },
      {
        CITY: "FERRIERES-EN-GATINAIS",
      },
      {
        CITY: "FERRIERES-HAUT-CLOCHER",
      },
      {
        CITY: "FERRIERES-LES-SCEY",
      },
      {
        CITY: "FERRIERES-SUR-ARIEGE",
      },
      {
        CITY: "FESCHES-LE-CHATEL",
      },
      {
        CITY: "FESQUES",
      },
      {
        CITY: "FESSENHEIM",
      },
      {
        CITY: "FESTIEUX",
      },
      {
        CITY: "FESTUBERT",
      },
      {
        CITY: "FETERNES",
      },
      {
        CITY: "FEUCHEROLLES",
      },
      {
        CITY: "FEUGES",
      },
      {
        CITY: "FEUGUEROLLES-SUR-SEULLES",
      },
      {
        CITY: "FEUILLA",
      },
      {
        CITY: "FEUQUIERES",
      },
      {
        CITY: "FEUQUIERES-EN-VIMEU",
      },
      {
        CITY: "FEURS",
      },
      {
        CITY: "FEVES",
      },
      {
        CITY: "FEY",
      },
      {
        CITY: "FEYTIAT",
      },
      {
        CITY: "FEYZIN",
      },
      {
        CITY: "FIEFS",
      },
      {
        CITY: "FIENNES",
      },
      {
        CITY: "FIERVILLE-BRAY",
      },
      {
        CITY: "FIERVILLE-LES-MINES",
      },
      {
        CITY: "FIGANIERES",
      },
      {
        CITY: "FIGEAC",
      },
      {
        CITY: "FIGNIERES",
      },
      {
        CITY: "FILAIN",
      },
      {
        CITY: "FILLE",
      },
      {
        CITY: "FILLIEVRES",
      },
      {
        CITY: "FILLINGES",
      },
      {
        CITY: "FILSTROFF",
      },
      {
        CITY: "FINHAN",
      },
      {
        CITY: "FIRMI",
      },
      {
        CITY: "FIRMINY",
      },
      {
        CITY: "FISLIS",
      },
      {
        CITY: "FISMES",
      },
      {
        CITY: "FITILIEU",
      },
      {
        CITY: "FITZ-JAMES",
      },
      {
        CITY: "FLAGNAC",
      },
      {
        CITY: "FLAMANVILLE",
      },
      {
        CITY: "FLAMARENS",
      },
      {
        CITY: "FLANCOURT-CATELON",
      },
      {
        CITY: "FLANGEBOUCHE",
      },
      {
        CITY: "FLAUCOURT",
      },
      {
        CITY: "FLAUX",
      },
      {
        CITY: "FLAVACOURT",
      },
      {
        CITY: "FLAVIGNY",
      },
      {
        CITY: "FLAVIGNY-SUR-MOSELLE",
      },
      {
        CITY: "FLAVIN",
      },
      {
        CITY: "FLAVY-LE-MARTEL",
      },
      {
        CITY: "FLAXLANDEN",
      },
      {
        CITY: "FLAYOSC",
      },
      {
        CITY: "FLEAC",
      },
      {
        CITY: "FLECHIN",
      },
      {
        CITY: "FLEE",
      },
      {
        CITY: "FLERS",
      },
      {
        CITY: "FLERS-EN-ESCREBIEUX",
      },
      {
        CITY: "FLERS-SUR-NOYE",
      },
      {
        CITY: "FLETRANGE",
      },
      {
        CITY: "FLETRE",
      },
      {
        CITY: "FLEURANCE",
      },
      {
        CITY: "FLEURBAIX",
      },
      {
        CITY: "FLEURE",
      },
      {
        CITY: "FLEUREY-SUR-OUCHE",
      },
      {
        CITY: "FLEURIE",
      },
      {
        CITY: "FLEURIEL",
      },
      {
        CITY: "FLEURY",
      },
      {
        CITY: "FLEURY-EN-BIERE",
      },
      {
        CITY: "FLEURY-LES-AUBRAIS",
      },
      {
        CITY: "FLEURY-MEROGIS",
      },
      {
        CITY: "FLEURY-SUR-ANDELLE",
      },
      {
        CITY: "FLEURY-SUR-ORNE",
      },
      {
        CITY: "FLEVILLE-DEVANT-NANCY",
      },
      {
        CITY: "FLEVY",
      },
      {
        CITY: "FLINES-LES-MORTAGNE",
      },
      {
        CITY: "FLINES-LEZ-RACHES",
      },
      {
        CITY: "FLINS-SUR-SEINE",
      },
      {
        CITY: "FLIXECOURT",
      },
      {
        CITY: "FLOING",
      },
      {
        CITY: "FLOIRAC",
      },
      {
        CITY: "FLORAC",
      },
      {
        CITY: "FLORANGE",
      },
      {
        CITY: "FLORENSAC",
      },
      {
        CITY: "FLORENT-EN-ARGONNE",
      },
      {
        CITY: "FLORENTIN",
      },
      {
        CITY: "FLORENTIN",
      },
      {
        CITY: "FLOYON",
      },
      {
        CITY: "FLUMET",
      },
      {
        CITY: "FOAMEIX-ORNEL",
      },
      {
        CITY: "FOECY",
      },
      {
        CITY: "FOISSIAT",
      },
      {
        CITY: "FOIX",
      },
      {
        CITY: "FOLELLI",
      },
      {
        CITY: "FOLEMBRAY",
      },
      {
        CITY: "FOLGENSBOURG",
      },
      {
        CITY: "FOLLAINVILLE-DENNEMONT",
      },
      {
        CITY: "FOLLES",
      },
      {
        CITY: "FOLLEVILLE",
      },
      {
        CITY: "FOLSCHVILLER",
      },
      {
        CITY: "FONBEAUZARD",
      },
      {
        CITY: "FONCINE-LE-HAUT",
      },
      {
        CITY: "FONDETTES",
      },
      {
        CITY: "FONS",
      },
      {
        CITY: "FONS",
      },
      {
        CITY: "FONSORBES",
      },
      {
        CITY: "FONT-ROMEU-ODEILLO-VIA",
      },
      {
        CITY: "FONTAIN",
      },
      {
        CITY: "FONTAINE",
      },
      {
        CITY: "FONTAINE",
      },
      {
        CITY: "FONTAINE-AU-BOIS",
      },
      {
        CITY: "FONTAINE-BONNELEAU",
      },
      {
        CITY: "FONTAINE-EN-DORMOIS",
      },
      {
        CITY: "FONTAINE-ETOUPEFOUR",
      },
      {
        CITY: "FONTAINE-GUERIN",
      },
      {
        CITY: "FONTAINE-HEUDEBOURG",
      },
      {
        CITY: "FONTAINE-LA-MALLET",
      },
      {
        CITY: "FONTAINE-LE-COMTE",
      },
      {
        CITY: "FONTAINE-LE-DUN",
      },
      {
        CITY: "FONTAINE-LE-PIN",
      },
      {
        CITY: "FONTAINE-LES-BASSETS",
      },
      {
        CITY: "FONTAINE-LES-DIJON",
      },
      {
        CITY: "FONTAINE-LES-GRES",
      },
      {
        CITY: "FONTAINE-LES-LUXEUIL",
      },
      {
        CITY: "FONTAINE-MILON",
      },
      {
        CITY: "FONTAINE-NOTRE-DAME",
      },
      {
        CITY: "FONTAINE-NOTRE-DAME",
      },
      {
        CITY: "FONTAINE-SIMON",
      },
      {
        CITY: "FONTAINE-SOUS-JOUY",
      },
      {
        CITY: "FONTAINE-SOUS-PREAUX",
      },
      {
        CITY: "FONTAINE-SUR-SOMME",
      },
      {
        CITY: "FONTAINE-UTERTE",
      },
      {
        CITY: "FONTAINEBLEAU",
      },
      {
        CITY: "FONTAINES",
      },
      {
        CITY: "FONTAINES",
      },
      {
        CITY: "FONTAINES-SAINT-MARTIN",
      },
      {
        CITY: "FONTAINES-SUR-SAONE",
      },
      {
        CITY: "FONTANES",
      },
      {
        CITY: "FONTANIL-CORNILLON",
      },
      {
        CITY: "FONTANNES",
      },
      {
        CITY: "FONTCOUVERTE",
      },
      {
        CITY: "FONTENAI-LES-LOUVETS",
      },
      {
        CITY: "FONTENAY",
      },
      {
        CITY: "FONTENAY-AUX-ROSES",
      },
      {
        CITY: "FONTENAY-EN-PARISIS",
      },
      {
        CITY: "FONTENAY-LE-COMTE",
      },
      {
        CITY: "FONTENAY-LE-FLEURY",
      },
      {
        CITY: "FONTENAY-LE-VICOMTE",
      },
      {
        CITY: "FONTENAY-SOUS-BOIS",
      },
      {
        CITY: "FONTENAY-SUR-LOING",
      },
      {
        CITY: "FONTENAY-SUR-VEGRE",
      },
      {
        CITY: "FONTENAY-TRESIGNY",
      },
      {
        CITY: "FONTENELLE-EN-BRIE",
      },
      {
        CITY: "FONTENILLES",
      },
      {
        CITY: "FONTENOY",
      },
      {
        CITY: "FONTENOY-LA-JOUTE",
      },
      {
        CITY: "FONTENOY-LE-CHATEAU",
      },
      {
        CITY: "FONTES",
      },
      {
        CITY: "FONTJONCOUSE",
      },
      {
        CITY: "FONTPEDROSA",
      },
      {
        CITY: "FONTRABIOUSE",
      },
      {
        CITY: "FONTVANNES",
      },
      {
        CITY: "FONTVIEILLE",
      },
      {
        CITY: "FORBACH",
      },
      {
        CITY: "FORCALQUEIRET",
      },
      {
        CITY: "FORCALQUIER",
      },
      {
        CITY: "FORCE",
      },
      {
        CITY: "FOREST-SUR-MARQUE",
      },
      {
        CITY: "FORET-LA-FOLIE",
      },
      {
        CITY: "FORFRY",
      },
      {
        CITY: "FORGES",
      },
      {
        CITY: "FORGES-LES-BAINS",
      },
      {
        CITY: "FORGES-LES-EAUX",
      },
      {
        CITY: "FORLEANS",
      },
      {
        CITY: "FORMERIE",
      },
      {
        CITY: "FORS",
      },
      {
        CITY: "FORT-MAHON-PLAGE",
      },
      {
        CITY: "FORT-MARDYCK",
      },
      {
        CITY: "FORTSCHWIHR",
      },
      {
        CITY: "FOS-SUR-MER",
      },
      {
        CITY: "FOSSE",
      },
      {
        CITY: "FOSSEMANANT",
      },
      {
        CITY: "FOSSES",
      },
      {
        CITY: "FOSSES-ET-BALEYSSAC",
      },
      {
        CITY: "FOUCHY",
      },
      {
        CITY: "FOUDAY",
      },
      {
        CITY: "FOUENCAMPS",
      },
      {
        CITY: "FOUESNANT",
      },
      {
        CITY: "FOUG",
      },
      {
        CITY: "FOUGAX-ET-BARRINEUF",
      },
      {
        CITY: "FOUGERE",
      },
      {
        CITY: "FOUGERES",
      },
      {
        CITY: "FOUGERES",
      },
      {
        CITY: "FOUGERES-SUR-BIEVRE",
      },
      {
        CITY: "FOUGEROLLES",
      },
      {
        CITY: "FOUILLARD",
      },
      {
        CITY: "FOUILLEUSE",
      },
      {
        CITY: "FOULANGUES",
      },
      {
        CITY: "FOULAYRONNES",
      },
      {
        CITY: "FOULCREY",
      },
      {
        CITY: "FOUQUEBRUNE",
      },
      {
        CITY: "FOUQUIERES-LES-LENS",
      },
      {
        CITY: "FOUR",
      },
      {
        CITY: "FOURAS",
      },
      {
        CITY: "FOURCHAMBAULT",
      },
      {
        CITY: "FOURCHES",
      },
      {
        CITY: "FOURDRINOY",
      },
      {
        CITY: "FOURG",
      },
      {
        CITY: "FOURMIES",
      },
      {
        CITY: "FOURNES-EN-WEPPES",
      },
      {
        CITY: "FOURNIER",
      },
      {
        CITY: "FOURNIVAL",
      },
      {
        CITY: "FOURNOLS",
      },
      {
        CITY: "FOURONNES",
      },
      {
        CITY: "FOURQUES",
      },
      {
        CITY: "FOURQUES-SUR-GARONNE",
      },
      {
        CITY: "FOURQUEUX",
      },
      {
        CITY: "FOURS-SAINT-LAURENT",
      },
      {
        CITY: "FOUSSEMAGNE",
      },
      {
        CITY: "FRAGNES",
      },
      {
        CITY: "FRAISANS",
      },
      {
        CITY: "FRAISSES",
      },
      {
        CITY: "FRAIZE",
      },
      {
        CITY: "FRALIGNES",
      },
      {
        CITY: "FRAMBOUHANS",
      },
      {
        CITY: "FRAMERVILLE-RAINECOURT",
      },
      {
        CITY: "FRANCALTROFF",
      },
      {
        CITY: "FRANCARDO",
      },
      {
        CITY: "FRANCHELEINS",
      },
      {
        CITY: "FRANCHEVILLE",
      },
      {
        CITY: "FRANCIERES",
      },
      {
        CITY: "FRANCLENS",
      },
      {
        CITY: "FRANCOIS",
      },
      {
        CITY: "FRANCONVILLE",
      },
      {
        CITY: "FRANCOURVILLE",
      },
      {
        CITY: "FRANGY",
      },
      {
        CITY: "FRANOIS",
      },
      {
        CITY: "FRANQUEVILLE",
      },
      {
        CITY: "FRANVILLERS",
      },
      {
        CITY: "FRANXAULT",
      },
      {
        CITY: "FRAUENBERG",
      },
      {
        CITY: "FREBUANS",
      },
      {
        CITY: "FRECHEDE",
      },
      {
        CITY: "FRECHENCOURT",
      },
      {
        CITY: "FRECHOU",
      },
      {
        CITY: "FREDILLE",
      },
      {
        CITY: "FREGIMONT",
      },
      {
        CITY: "FREGOUVILLE",
      },
      {
        CITY: "FREISTROFF",
      },
      {
        CITY: "FREJAIROLLES",
      },
      {
        CITY: "FRELAND",
      },
      {
        CITY: "FRELINGHIEN",
      },
      {
        CITY: "FREMAINVILLE",
      },
      {
        CITY: "FREMIFONTAINE",
      },
      {
        CITY: "FREMONVILLE",
      },
      {
        CITY: "FRENCQ",
      },
      {
        CITY: "FRENES",
      },
      {
        CITY: "FRENEUSE",
      },
      {
        CITY: "FREPILLON",
      },
      {
        CITY: "FRESNAY-EN-RETZ",
      },
      {
        CITY: "FRESNAY-LE-GILMERT",
      },
      {
        CITY: "FRESNAY-SUR-SARTHE",
      },
      {
        CITY: "FRESNE",
      },
      {
        CITY: "FRESNE-CAUVERVILLE",
      },
      {
        CITY: "FRESNE-LA-MERE",
      },
      {
        CITY: "FRESNE-SAINT-MAMES",
      },
      {
        CITY: "FRESNEAUX-MONTCHEVREUIL",
      },
      {
        CITY: "FRESNES",
      },
      {
        CITY: "FRESNES-AU-MONT",
      },
      {
        CITY: "FRESNES-EN-WOEVRE",
      },
      {
        CITY: "FRESNES-LES-MONTAUBAN",
      },
      {
        CITY: "FRESNES-SUR-ESCAUT",
      },
      {
        CITY: "FRESNES-SUR-MARNE",
      },
      {
        CITY: "FRESNICOURT-LE-DOLMEN",
      },
      {
        CITY: "FRESNOY-LA-RIVIERE",
      },
      {
        CITY: "FRESNOY-LE-GRAND",
      },
      {
        CITY: "FRESPECH",
      },
      {
        CITY: "FRESSAC",
      },
      {
        CITY: "FRESSAIN",
      },
      {
        CITY: "FRESSE",
      },
      {
        CITY: "FRESSE-SUR-MOSELLE",
      },
      {
        CITY: "FRESSENNEVILLE",
      },
      {
        CITY: "FRESSIES",
      },
      {
        CITY: "FRESSINES",
      },
      {
        CITY: "FRETHUN",
      },
      {
        CITY: "FRETIGNEY-ET-VELLOREILLE",
      },
      {
        CITY: "FRETIN",
      },
      {
        CITY: "FREVENT",
      },
      {
        CITY: "FREVILLERS",
      },
      {
        CITY: "FREVIN-CAPELLE",
      },
      {
        CITY: "FRIAUVILLE",
      },
      {
        CITY: "FRIERES-FAILLOUEL",
      },
      {
        CITY: "FRIESEN",
      },
      {
        CITY: "FRIVILLE-ESCARBOTIN",
      },
      {
        CITY: "FROCOURT",
      },
      {
        CITY: "FROGES",
      },
      {
        CITY: "FROIDESTREES",
      },
      {
        CITY: "FROIDFOND",
      },
      {
        CITY: "FROISSY",
      },
      {
        CITY: "FROMELLES",
      },
      {
        CITY: "FROMONT",
      },
      {
        CITY: "FROMONVILLE",
      },
      {
        CITY: "FRONCLES",
      },
      {
        CITY: "FRONSAC",
      },
      {
        CITY: "FRONTENAC",
      },
      {
        CITY: "FRONTENAS",
      },
      {
        CITY: "FRONTENAY-ROHAN-ROHAN",
      },
      {
        CITY: "FRONTENAY-SUR-DIVE",
      },
      {
        CITY: "FRONTENEX",
      },
      {
        CITY: "FRONTIGNAN",
      },
      {
        CITY: "FRONTIGNAN-SAVES",
      },
      {
        CITY: "FRONTON",
      },
      {
        CITY: "FRONTONAS",
      },
      {
        CITY: "FROSSAY",
      },
      {
        CITY: "FROUARD",
      },
      {
        CITY: "FROUZINS",
      },
      {
        CITY: "FROZES",
      },
      {
        CITY: "FRUCOURT",
      },
      {
        CITY: "FRUGERES-LES-MINES",
      },
      {
        CITY: "FRUGES",
      },
      {
        CITY: "FUISSE",
      },
      {
        CITY: "FULTOT",
      },
      {
        CITY: "FUMAY",
      },
      {
        CITY: "FUMEL",
      },
      {
        CITY: "FURCHHAUSEN",
      },
      {
        CITY: "FURIANI",
      },
      {
        CITY: "FUSSY",
      },
      {
        CITY: "FUVEAU",
      },
      {
        CITY: "FYE",
      },
      {
        CITY: "GAAS",
      },
      {
        CITY: "GABASTON",
      },
      {
        CITY: "GABAT",
      },
      {
        CITY: "GACE",
      },
      {
        CITY: "GAGNAC-SUR-GARONNE",
      },
      {
        CITY: "GAGNIERES",
      },
      {
        CITY: "GAGNY",
      },
      {
        CITY: "GAHARD",
      },
      {
        CITY: "GAILHAN",
      },
      {
        CITY: "GAILLAC",
      },
      {
        CITY: "GAILLAC-TOULZA",
      },
      {
        CITY: "GAILLAN-EN-MEDOC",
      },
      {
        CITY: "GAILLARD",
      },
      {
        CITY: "GAILLEFONTAINE",
      },
      {
        CITY: "GAILLON",
      },
      {
        CITY: "GALAMETZ",
      },
      {
        CITY: "GALAPIAN",
      },
      {
        CITY: "GALARGUES",
      },
      {
        CITY: "GALFINGUE",
      },
      {
        CITY: "GALGON",
      },
      {
        CITY: "GALIE",
      },
      {
        CITY: "GALLARDON",
      },
      {
        CITY: "GALLICIAN",
      },
      {
        CITY: "GALLUIS",
      },
      {
        CITY: "GAMACHES",
      },
      {
        CITY: "GAMARDE-LES-BAINS",
      },
      {
        CITY: "GAMBAIS",
      },
      {
        CITY: "GAMBSHEIM",
      },
      {
        CITY: "GAN",
      },
      {
        CITY: "GANAC",
      },
      {
        CITY: "GANDRANGE",
      },
      {
        CITY: "GANGES",
      },
      {
        CITY: "GANNAT",
      },
      {
        CITY: "GAP",
      },
      {
        CITY: "GARANCIERES-EN-DROUAIS",
      },
      {
        CITY: "GARANOU",
      },
      {
        CITY: "GARAT",
      },
      {
        CITY: "GARCHE",
      },
      {
        CITY: "GARCHES",
      },
      {
        CITY: "GARCHIZY",
      },
      {
        CITY: "GARDANNE",
      },
      {
        CITY: "GARDONNE",
      },
      {
        CITY: "GARENCIERES",
      },
      {
        CITY: "GARENNES-SUR-EURE",
      },
      {
        CITY: "GAREOULT",
      },
      {
        CITY: "GARGENVILLE",
      },
      {
        CITY: "GARLAN",
      },
      {
        CITY: "GARLIN",
      },
      {
        CITY: "GARONS",
      },
      {
        CITY: "GARREVAQUES",
      },
      {
        CITY: "GARRIGUES",
      },
      {
        CITY: "GARRIS",
      },
      {
        CITY: "GARROSSE",
      },
      {
        CITY: "GAS",
      },
      {
        CITY: "GASNY",
      },
      {
        CITY: "GASSIN",
      },
      {
        CITY: "GASVILLE-OISEME",
      },
      {
        CITY: "GATELLES",
      },
      {
        CITY: "GATEY",
      },
      {
        CITY: "GATTIERES",
      },
      {
        CITY: "GAUCHY",
      },
      {
        CITY: "GAUCIEL",
      },
      {
        CITY: "GAURIAC",
      },
      {
        CITY: "GAURIAGUET",
      },
      {
        CITY: "GAUVILLE-LA-CAMPAGNE",
      },
      {
        CITY: "GAVARNIE",
      },
      {
        CITY: "GAVRAY",
      },
      {
        CITY: "GAYE",
      },
      {
        CITY: "GEANGES",
      },
      {
        CITY: "GEAUNE",
      },
      {
        CITY: "GEAY",
      },
      {
        CITY: "GEE",
      },
      {
        CITY: "GEISPOLSHEIM",
      },
      {
        CITY: "GELANNES",
      },
      {
        CITY: "GELLES",
      },
      {
        CITY: "GELOS",
      },
      {
        CITY: "GELOUX",
      },
      {
        CITY: "GEMEAUX",
      },
      {
        CITY: "GEMENOS",
      },
      {
        CITY: "GEMIL",
      },
      {
        CITY: "GEMOZAC",
      },
      {
        CITY: "GENAC",
      },
      {
        CITY: "GENAS",
      },
      {
        CITY: "GENAY",
      },
      {
        CITY: "GENCAY",
      },
      {
        CITY: "GENDREY",
      },
      {
        CITY: "GENE",
      },
      {
        CITY: "GENEBRIERES",
      },
      {
        CITY: "GENECH",
      },
      {
        CITY: "GENELARD",
      },
      {
        CITY: "GENERAC",
      },
      {
        CITY: "GENERARGUES",
      },
      {
        CITY: "GENET",
      },
      {
        CITY: "GENEUILLE",
      },
      {
        CITY: "GENEY",
      },
      {
        CITY: "GENILLE",
      },
      {
        CITY: "GENIS",
      },
      {
        CITY: "GENISSAC",
      },
      {
        CITY: "GENISSIAT",
      },
      {
        CITY: "GENISSIEUX",
      },
      {
        CITY: "GENLIS",
      },
      {
        CITY: "GENNES",
      },
      {
        CITY: "GENNES-SUR-SEICHE",
      },
      {
        CITY: "GENNETINES",
      },
      {
        CITY: "GENNEVILLIERS",
      },
      {
        CITY: "GENSAC",
      },
      {
        CITY: "GENSAC-LA-PALLUE",
      },
      {
        CITY: "GENTE",
      },
      {
        CITY: "GENTILLY",
      },
      {
        CITY: "GEORGES",
      },
      {
        CITY: "GEOVREISSET",
      },
      {
        CITY: "GER",
      },
      {
        CITY: "GER",
      },
      {
        CITY: "GERARD",
      },
      {
        CITY: "GERBAIX",
      },
      {
        CITY: "GERBECOURT-ET-HAPLEMONT",
      },
      {
        CITY: "GERBEVILLER",
      },
      {
        CITY: "GERGY",
      },
      {
        CITY: "GERLAND",
      },
      {
        CITY: "GERMAINE",
      },
      {
        CITY: "GERMAINVILLE",
      },
      {
        CITY: "GERMENAY",
      },
      {
        CITY: "GERMIGNY-SUR-LOIRE",
      },
      {
        CITY: "GERMOND-ROUVRE",
      },
      {
        CITY: "GERSTHEIM",
      },
      {
        CITY: "GERVAIS",
      },
      {
        CITY: "GERVANS",
      },
      {
        CITY: "GERZAT",
      },
      {
        CITY: "GESNES-LE-GANDELIN",
      },
      {
        CITY: "GESTE",
      },
      {
        CITY: "GESTEL",
      },
      {
        CITY: "GESVRES",
      },
      {
        CITY: "GETIGNE",
      },
      {
        CITY: "GEUDERTHEIM",
      },
      {
        CITY: "GEVEZE",
      },
      {
        CITY: "GEVREY-CHAMBERTIN",
      },
      {
        CITY: "GEVRIEUX",
      },
      {
        CITY: "GEX",
      },
      {
        CITY: "GEYSSANS",
      },
      {
        CITY: "GEZAINCOURT",
      },
      {
        CITY: "GEZONCOURT",
      },
      {
        CITY: "GHISONACCIA",
      },
      {
        CITY: "GHYVELDE",
      },
      {
        CITY: "GIAT",
      },
      {
        CITY: "GIBERVILLE",
      },
      {
        CITY: "GIDY",
      },
      {
        CITY: "GIEN",
      },
      {
        CITY: "GIERES",
      },
      {
        CITY: "GIEVRES",
      },
      {
        CITY: "GIF-SUR-YVETTE",
      },
      {
        CITY: "GIGEAN",
      },
      {
        CITY: "GIGNAC",
      },
      {
        CITY: "GIGNAC",
      },
      {
        CITY: "GIGNAC-LA-NERTHE",
      },
      {
        CITY: "GIGNY-SUR-SAONE",
      },
      {
        CITY: "GILETTE",
      },
      {
        CITY: "GILLES",
      },
      {
        CITY: "GILLEY",
      },
      {
        CITY: "GILLY-LES-CITEAUX",
      },
      {
        CITY: "GILLY-SUR-ISERE",
      },
      {
        CITY: "GIMBRETT",
      },
      {
        CITY: "GIMEUX",
      },
      {
        CITY: "GIMONT",
      },
      {
        CITY: "GINASSERVIS",
      },
      {
        CITY: "GINESTAS",
      },
      {
        CITY: "GINESTET",
      },
      {
        CITY: "GIRANCOURT",
      },
      {
        CITY: "GIRAUMONT",
      },
      {
        CITY: "GIROLLES",
      },
      {
        CITY: "GIROMAGNY",
      },
      {
        CITY: "GIRON",
      },
      {
        CITY: "GIRONCOURT-SUR-VRAINE",
      },
      {
        CITY: "GIRONDE-SUR-DROPT",
      },
      {
        CITY: "GIROUSSENS",
      },
      {
        CITY: "GISORS",
      },
      {
        CITY: "GIVENCHY-EN-GOHELLE",
      },
      {
        CITY: "GIVET",
      },
      {
        CITY: "GIVORS",
      },
      {
        CITY: "GIVRAINES",
      },
      {
        CITY: "GIVRAND",
      },
      {
        CITY: "GIVRAUVAL",
      },
      {
        CITY: "GIVRON",
      },
      {
        CITY: "GIVRY",
      },
      {
        CITY: "GLANON",
      },
      {
        CITY: "GLATIGNY",
      },
      {
        CITY: "GLEIZE",
      },
      {
        CITY: "GLENIC",
      },
      {
        CITY: "GLOMEL",
      },
      {
        CITY: "GLOS-SUR-RISLE",
      },
      {
        CITY: "GODERVILLE",
      },
      {
        CITY: "GODEWAERSVELDE",
      },
      {
        CITY: "GOERSDORF",
      },
      {
        CITY: "GOES",
      },
      {
        CITY: "GOETZENBRUCK",
      },
      {
        CITY: "GOEULZIN",
      },
      {
        CITY: "GOIN",
      },
      {
        CITY: "GOINCOURT",
      },
      {
        CITY: "GOLBEY",
      },
      {
        CITY: "GOLFE-JUAN",
      },
      {
        CITY: "GOMBERGEAN",
      },
      {
        CITY: "GOMELANGE",
      },
      {
        CITY: "GOMENE",
      },
      {
        CITY: "GOMER",
      },
      {
        CITY: "GOMETZ-LA-VILLE",
      },
      {
        CITY: "GOMETZ-LE-CHATEL",
      },
      {
        CITY: "GOMMEGNIES",
      },
      {
        CITY: "GOMMERVILLE",
      },
      {
        CITY: "GOMMERVILLE",
      },
      {
        CITY: "GONCELIN",
      },
      {
        CITY: "GONDECOURT",
      },
      {
        CITY: "GONDEVILLE",
      },
      {
        CITY: "GONDRECOURT-LE-CHATEAU",
      },
      {
        CITY: "GONDREVILLE",
      },
      {
        CITY: "GONESSE",
      },
      {
        CITY: "GONFARON",
      },
      {
        CITY: "GONFREVILLE-CAILLOT",
      },
      {
        CITY: "GONFREVILLE-LORCHER",
      },
      {
        CITY: "GONNEHEM",
      },
      {
        CITY: "GONNEVILLE-LA-MALLET",
      },
      {
        CITY: "GONNEVILLE-SUR-MER",
      },
      {
        CITY: "GONTIER",
      },
      {
        CITY: "GORBIO",
      },
      {
        CITY: "GORCY",
      },
      {
        CITY: "GORDES",
      },
      {
        CITY: "GORGES",
      },
      {
        CITY: "GORNAC",
      },
      {
        CITY: "GORRE",
      },
      {
        CITY: "GORRON",
      },
      {
        CITY: "GORZE",
      },
      {
        CITY: "GOSNAY",
      },
      {
        CITY: "GOSSELMING",
      },
      {
        CITY: "GOTTENHOUSE",
      },
      {
        CITY: "GOUAIX",
      },
      {
        CITY: "GOUAUX",
      },
      {
        CITY: "GOUDELIN",
      },
      {
        CITY: "GOUDOURVILLE",
      },
      {
        CITY: "GOUESNACH",
      },
      {
        CITY: "GOUESNOU",
      },
      {
        CITY: "GOUEZEC",
      },
      {
        CITY: "GOUILLONS",
      },
      {
        CITY: "GOUISE",
      },
      {
        CITY: "GOULET",
      },
      {
        CITY: "GOULT",
      },
      {
        CITY: "GOUPILLIERES",
      },
      {
        CITY: "GOUPILLIERES",
      },
      {
        CITY: "GOURDAN-POLIGNAN",
      },
      {
        CITY: "GOURDON",
      },
      {
        CITY: "GOURGE",
      },
      {
        CITY: "GOURHEL",
      },
      {
        CITY: "GOURIN",
      },
      {
        CITY: "GOURLIZON",
      },
      {
        CITY: "GOURNAY-EN-BRAY",
      },
      {
        CITY: "GOURNAY-SUR-MARNE",
      },
      {
        CITY: "GOURVILLETTE",
      },
      {
        CITY: "GOURY",
      },
      {
        CITY: "GOUSSAINVILLE",
      },
      {
        CITY: "GOUSSAINVILLE",
      },
      {
        CITY: "GOUSSE",
      },
      {
        CITY: "GOUTRENS",
      },
      {
        CITY: "GOUTS",
      },
      {
        CITY: "GOUTTIERES",
      },
      {
        CITY: "GOUVERNES",
      },
      {
        CITY: "GOUVES",
      },
      {
        CITY: "GOUVIEUX",
      },
      {
        CITY: "GOUVILLE-SUR-MER",
      },
      {
        CITY: "GOUX-LES-DAMBELIN",
      },
      {
        CITY: "GOUX-LES-USIERS",
      },
      {
        CITY: "GOUY-EN-ARTOIS",
      },
      {
        CITY: "GOUY-EN-TERNOIS",
      },
      {
        CITY: "GOUY-SAINT-ANDRE",
      },
      {
        CITY: "GOUY-SOUS-BELLONNE",
      },
      {
        CITY: "GOUZE",
      },
      {
        CITY: "GOUZEAUCOURT",
      },
      {
        CITY: "GOVEN",
      },
      {
        CITY: "GOXWILLER",
      },
      {
        CITY: "GOYRANS",
      },
      {
        CITY: "GRABELS",
      },
      {
        CITY: "GRACAY",
      },
      {
        CITY: "GRACES",
      },
      {
        CITY: "GRADIGNAN",
      },
      {
        CITY: "GRAGNAGUE",
      },
      {
        CITY: "GRAINCOURT-LES-HAVRINCOURT",
      },
      {
        CITY: "GRAINVILLE",
      },
      {
        CITY: "GRAINVILLE-LA-TEINTURIERE",
      },
      {
        CITY: "GRAINVILLE-SUR-ODON",
      },
      {
        CITY: "GRAISSESSAC",
      },
      {
        CITY: "GRAMAT",
      },
      {
        CITY: "GRAMMOND",
      },
      {
        CITY: "GRAND",
      },
      {
        CITY: "GRAND CHAMP",
      },
      {
        CITY: "GRAND-AUVERNE",
      },
      {
        CITY: "GRAND-CHARMONT",
      },
      {
        CITY: "GRAND-FORT-PHILIPPE",
      },
      {
        CITY: "GRAND-FOUGERAY",
      },
      {
        CITY: "GRAND-LAVIERS",
      },
      {
        CITY: "GRAND-ROZOY",
      },
      {
        CITY: "GRANDCHAMP",
      },
      {
        CITY: "GRANDCHAMPS-DES-FONTAINES",
      },
      {
        CITY: "GRANDE-SYNTHE",
      },
      {
        CITY: "GRANDFONTAINE",
      },
      {
        CITY: "GRANDFONTAINE-FOURNETS",
      },
      {
        CITY: "GRANDFRESNOY",
      },
      {
        CITY: "GRANDPRE",
      },
      {
        CITY: "GRANDRIS",
      },
      {
        CITY: "GRANDSAIGNE",
      },
      {
        CITY: "GRANDVAUX",
      },
      {
        CITY: "GRANDVILLARS",
      },
      {
        CITY: "GRANDVILLE-GAUDREVILLE",
      },
      {
        CITY: "GRANDVILLERS",
      },
      {
        CITY: "GRANDVILLIERS",
      },
      {
        CITY: "GRANE",
      },
      {
        CITY: "GRANGES",
      },
      {
        CITY: "GRANGES-LE-BOURG",
      },
      {
        CITY: "GRANGES-SUR-VOLOGNE",
      },
      {
        CITY: "GRANGUES",
      },
      {
        CITY: "GRANS",
      },
      {
        CITY: "GRANVILLE",
      },
      {
        CITY: "GRASSE",
      },
      {
        CITY: "GRASSENDORF",
      },
      {
        CITY: "GRATENTOUR",
      },
      {
        CITY: "GRATOT",
      },
      {
        CITY: "GRAULHET",
      },
      {
        CITY: "GRAUVES",
      },
      {
        CITY: "GRAVELINES",
      },
      {
        CITY: "GRAVERON-SEMERVILLE",
      },
      {
        CITY: "GRAVESON",
      },
      {
        CITY: "GRAVIGNY",
      },
      {
        CITY: "GRAY",
      },
      {
        CITY: "GRAZAC",
      },
      {
        CITY: "GRAZAY",
      },
      {
        CITY: "GREASQUE",
      },
      {
        CITY: "GREMECEY",
      },
      {
        CITY: "GREMEVILLERS",
      },
      {
        CITY: "GREMONVILLE",
      },
      {
        CITY: "GRENADE",
      },
      {
        CITY: "GRENAY",
      },
      {
        CITY: "GRENEVILLE-EN-BEAUCE",
      },
      {
        CITY: "GRENOBLE",
      },
      {
        CITY: "GRENTHEVILLE",
      },
      {
        CITY: "GRENTZINGEN",
      },
      {
        CITY: "GREOLIERES",
      },
      {
        CITY: "GREOUX-LES-BAINS",
      },
      {
        CITY: "GREPIAC",
      },
      {
        CITY: "GRESSE-EN-VERCORS",
      },
      {
        CITY: "GRESSWILLER",
      },
      {
        CITY: "GRESSY",
      },
      {
        CITY: "GRESY-SUR-AIX",
      },
      {
        CITY: "GRESY-SUR-ISERE",
      },
      {
        CITY: "GRETZ-ARMAINVILLIERS",
      },
      {
        CITY: "GREZ-EN-BOUERE",
      },
      {
        CITY: "GREZ-SUR-LOING",
      },
      {
        CITY: "GREZIEU-LA-VARENNE",
      },
      {
        CITY: "GREZILLAC",
      },
      {
        CITY: "GREZOLLES",
      },
      {
        CITY: "GRIES",
      },
      {
        CITY: "GRIESBACH-AU-VAL",
      },
      {
        CITY: "GRIESHEIM-PRES-MOLSHEIM",
      },
      {
        CITY: "GRIGNAN",
      },
      {
        CITY: "GRIGNOLS",
      },
      {
        CITY: "GRIGNY",
      },
      {
        CITY: "GRIGNY",
      },
      {
        CITY: "GRILLON",
      },
      {
        CITY: "GRILLY",
      },
      {
        CITY: "GRIMAUD",
      },
      {
        CITY: "GRIPPORT",
      },
      {
        CITY: "GRISELLES",
      },
      {
        CITY: "GRISELLES",
      },
      {
        CITY: "GRISOLLES",
      },
      {
        CITY: "GRISY-SUISNES",
      },
      {
        CITY: "GRISY-SUR-SEINE",
      },
      {
        CITY: "GRIVILLERS",
      },
      {
        CITY: "GROFFLIERS",
      },
      {
        CITY: "GROISSIAT",
      },
      {
        CITY: "GROISY",
      },
      {
        CITY: "GROIX",
      },
      {
        CITY: "GRON",
      },
      {
        CITY: "GROS-REDERCHING",
      },
      {
        CITY: "GROSBLIEDERSTROFF",
      },
      {
        CITY: "GROSBREUIL",
      },
      {
        CITY: "GROSLAY",
      },
      {
        CITY: "GROSROUVRE",
      },
      {
        CITY: "GROSSETO-PRUGNA",
      },
      {
        CITY: "GROSSOEUVRE",
      },
      {
        CITY: "GROZON",
      },
      {
        CITY: "GRUCHET-LE-VALASSE",
      },
      {
        CITY: "GRUEY-LES-SURANCE",
      },
      {
        CITY: "GRUFFY",
      },
      {
        CITY: "GRUGIES",
      },
      {
        CITY: "GRUISSAN",
      },
      {
        CITY: "GRUN-BORDAS",
      },
      {
        CITY: "GRUNDVILLER",
      },
      {
        CITY: "GRUSON",
      },
      {
        CITY: "GRUSSENHEIM",
      },
      {
        CITY: "GUEBWILLER",
      },
      {
        CITY: "GUECELARD",
      },
      {
        CITY: "GUEGON",
      },
      {
        CITY: "GUEMAR",
      },
      {
        CITY: "GUEMENE-PENFAO",
      },
      {
        CITY: "GUEMENE-SUR-SCORFF",
      },
      {
        CITY: "GUENANGE",
      },
      {
        CITY: "GUENGAT",
      },
      {
        CITY: "GUENIN",
      },
      {
        CITY: "GUENROUET",
      },
      {
        CITY: "GUER",
      },
      {
        CITY: "GUERANDE",
      },
      {
        CITY: "GUERARD",
      },
      {
        CITY: "GUERCHEVILLE",
      },
      {
        CITY: "GUERET",
      },
      {
        CITY: "GUERIGNY",
      },
      {
        CITY: "GUERLESQUIN",
      },
      {
        CITY: "GUERNES",
      },
      {
        CITY: "GUERTING",
      },
      {
        CITY: "GUERVILLE",
      },
      {
        CITY: "GUERVILLE",
      },
      {
        CITY: "GUESNAIN",
      },
      {
        CITY: "GUESSLING-HEMERING",
      },
      {
        CITY: "GUETHARY",
      },
      {
        CITY: "GUEUGNON",
      },
      {
        CITY: "GUEURES",
      },
      {
        CITY: "GUEUX",
      },
      {
        CITY: "GUEWENHEIM",
      },
      {
        CITY: "GUICHE",
      },
      {
        CITY: "GUICHEN",
      },
      {
        CITY: "GUICLAN",
      },
      {
        CITY: "GUIDEL",
      },
      {
        CITY: "GUIGNEN",
      },
      {
        CITY: "GUIGNES",
      },
      {
        CITY: "GUIGNEVILLE",
      },
      {
        CITY: "GUIGNICOURT",
      },
      {
        CITY: "GUIGNICOURT-SUR-VENCE",
      },
      {
        CITY: "GUILER-SUR-GOYEN",
      },
      {
        CITY: "GUILERS",
      },
      {
        CITY: "GUILHERAND-GRANGES",
      },
      {
        CITY: "GUILLESTRE",
      },
      {
        CITY: "GUILLIERS",
      },
      {
        CITY: "GUILLY",
      },
      {
        CITY: "GUILVINEC",
      },
      {
        CITY: "GUINES",
      },
      {
        CITY: "GUINGAMP",
      },
      {
        CITY: "GUIPAVAS",
      },
      {
        CITY: "GUIPEL",
      },
      {
        CITY: "GUIPRONVEL",
      },
      {
        CITY: "GUIPRY",
      },
      {
        CITY: "GUISCARD",
      },
      {
        CITY: "GUISE",
      },
      {
        CITY: "GUISENIERS",
      },
      {
        CITY: "GUITALENS",
      },
      {
        CITY: "GUIVRY",
      },
      {
        CITY: "GUJAN-MESTRAS",
      },
      {
        CITY: "GUMBRECHTSHOFFEN",
      },
      {
        CITY: "GUNDERSHOFFEN",
      },
      {
        CITY: "GUNDOLSHEIM",
      },
      {
        CITY: "GUNTZVILLER",
      },
      {
        CITY: "GUNY",
      },
      {
        CITY: "GURS",
      },
      {
        CITY: "GUYANCOURT",
      },
      {
        CITY: "GY-EN-SOLOGNE",
      },
      {
        CITY: "HABAS",
      },
      {
        CITY: "HABERE-LULLIN",
      },
      {
        CITY: "HABSHEIM",
      },
      {
        CITY: "HADOL",
      },
      {
        CITY: "HAGENBACH",
      },
      {
        CITY: "HAGENTHAL-LE-HAUT",
      },
      {
        CITY: "HAGETMAU",
      },
      {
        CITY: "HAGEVILLE",
      },
      {
        CITY: "HAGONDANGE",
      },
      {
        CITY: "HAGUENAU",
      },
      {
        CITY: "HAILLICOURT",
      },
      {
        CITY: "HAIRONVILLE",
      },
      {
        CITY: "HAISNES",
      },
      {
        CITY: "HALLENCOURT",
      },
      {
        CITY: "HALLENNES-LEZ-HAUBOURDIN",
      },
      {
        CITY: "HALLERING",
      },
      {
        CITY: "HALLINES",
      },
      {
        CITY: "HALLOY-LES-PERNOIS",
      },
      {
        CITY: "HALLUIN",
      },
      {
        CITY: "HAM",
      },
      {
        CITY: "HAM-EN-ARTOIS",
      },
      {
        CITY: "HAM-SOUS-VARSBERG",
      },
      {
        CITY: "HAM-SUR-MEUSE",
      },
      {
        CITY: "HAMARS",
      },
      {
        CITY: "HAMBACH",
      },
      {
        CITY: "HAMBERS",
      },
      {
        CITY: "HAMEL",
      },
      {
        CITY: "HAMMEVILLE",
      },
      {
        CITY: "HAN-SUR-NIED",
      },
      {
        CITY: "HANCHES",
      },
      {
        CITY: "HANGENBIETEN",
      },
      {
        CITY: "HANGEST-EN-SANTERRE",
      },
      {
        CITY: "HANNOGNE-SAINT-MARTIN",
      },
      {
        CITY: "HANTAY",
      },
      {
        CITY: "HANVEC",
      },
      {
        CITY: "HAPPONVILLIERS",
      },
      {
        CITY: "HARAMONT",
      },
      {
        CITY: "HARAUCOURT",
      },
      {
        CITY: "HARAUCOURT",
      },
      {
        CITY: "HARBONNIERES",
      },
      {
        CITY: "HARCANVILLE",
      },
      {
        CITY: "HARCIGNY",
      },
      {
        CITY: "HARCOURT",
      },
      {
        CITY: "HARDELOT-PLAGE",
      },
      {
        CITY: "HARDENCOURT",
      },
      {
        CITY: "HARDIFORT",
      },
      {
        CITY: "HARDINVAST",
      },
      {
        CITY: "HARFLEUR",
      },
      {
        CITY: "HARGICOURT",
      },
      {
        CITY: "HARGNIES",
      },
      {
        CITY: "HARNES",
      },
      {
        CITY: "HAROUE",
      },
      {
        CITY: "HARPRICH",
      },
      {
        CITY: "HARREBERG",
      },
      {
        CITY: "HARREVILLE-LES-CHANTEURS",
      },
      {
        CITY: "HARSKIRCHEN",
      },
      {
        CITY: "HARTMANNSWILLER",
      },
      {
        CITY: "HARY",
      },
      {
        CITY: "HASNON",
      },
      {
        CITY: "HASPARREN",
      },
      {
        CITY: "HASPRES",
      },
      {
        CITY: "HASTINGUES",
      },
      {
        CITY: "HATTEN",
      },
      {
        CITY: "HATTENVILLE",
      },
      {
        CITY: "HATTSTATT",
      },
      {
        CITY: "HAUBOURDIN",
      },
      {
        CITY: "HAUCONCOURT",
      },
      {
        CITY: "HAUCOURT-MOULAINE",
      },
      {
        CITY: "HAUDIOMONT",
      },
      {
        CITY: "HAUDIVILLERS",
      },
      {
        CITY: "HAULCHIN",
      },
      {
        CITY: "HAUSSIGNEMONT",
      },
      {
        CITY: "HAUSSIMONT",
      },
      {
        CITY: "HAUSSY",
      },
      {
        CITY: "HAUT-CLOCHER",
      },
      {
        CITY: "HAUTE-GOULAINE",
      },
      {
        CITY: "HAUTE-ISLE",
      },
      {
        CITY: "HAUTE-RIVOIRE",
      },
      {
        CITY: "HAUTE-VIGNEULLES",
      },
      {
        CITY: "HAUTECLOQUE",
      },
      {
        CITY: "HAUTEFAGE-LA-TOUR",
      },
      {
        CITY: "HAUTEFORT",
      },
      {
        CITY: "HAUTELUCE",
      },
      {
        CITY: "HAUTERIVE",
      },
      {
        CITY: "HAUTERIVE",
      },
      {
        CITY: "HAUTERIVES",
      },
      {
        CITY: "HAUTEVILLE-LES-DIJON",
      },
      {
        CITY: "HAUTEVILLE-LOMPNES",
      },
      {
        CITY: "HAUTEVILLE-SUR-MER",
      },
      {
        CITY: "HAUTMONT",
      },
      {
        CITY: "HAUTOT-SUR-MER",
      },
      {
        CITY: "HAUTVILLERS",
      },
      {
        CITY: "HAUTVILLERS-OUVILLE",
      },
      {
        CITY: "HAVELUY",
      },
      {
        CITY: "HAVERSKERQUE",
      },
      {
        CITY: "HAVRINCOURT",
      },
      {
        CITY: "HAYANGE",
      },
      {
        CITY: "HAYBES",
      },
      {
        CITY: "HAZEBROUCK",
      },
      {
        CITY: "HEBECOURT",
      },
      {
        CITY: "HEBECREVON",
      },
      {
        CITY: "HEBUTERNE",
      },
      {
        CITY: "HEDE-BAZOUGES",
      },
      {
        CITY: "HEGENHEIM",
      },
      {
        CITY: "HEIDWILLER",
      },
      {
        CITY: "HEILIGENBERG",
      },
      {
        CITY: "HEILLECOURT",
      },
      {
        CITY: "HEILLY",
      },
      {
        CITY: "HEIMERSDORF",
      },
      {
        CITY: "HEIMSBRUNN",
      },
      {
        CITY: "HEITEREN",
      },
      {
        CITY: "HELESMES",
      },
      {
        CITY: "HELETTE",
      },
      {
        CITY: "HELFAUT",
      },
      {
        CITY: "HELLEMMES-LILLE",
      },
      {
        CITY: "HELLIMER",
      },
      {
        CITY: "HEM",
      },
      {
        CITY: "HEMEVILLERS",
      },
      {
        CITY: "HEMING",
      },
      {
        CITY: "HEMONSTOIR",
      },
      {
        CITY: "HENANSAL",
      },
      {
        CITY: "HENDAYE",
      },
      {
        CITY: "HENIN-BEAUMONT",
      },
      {
        CITY: "HENIN-SUR-COJEUL",
      },
      {
        CITY: "HENNEBONT",
      },
      {
        CITY: "HENON",
      },
      {
        CITY: "HENONVILLE",
      },
      {
        CITY: "HENOUVILLE",
      },
      {
        CITY: "HENRICHEMONT",
      },
      {
        CITY: "HENRIDORFF",
      },
      {
        CITY: "HENVIC",
      },
      {
        CITY: "HERBEYS",
      },
      {
        CITY: "HERBIGNAC",
      },
      {
        CITY: "HERBLAY",
      },
      {
        CITY: "HERCHIES",
      },
      {
        CITY: "HEREPIAN",
      },
      {
        CITY: "HERES",
      },
      {
        CITY: "HERGNIES",
      },
      {
        CITY: "HERIC",
      },
      {
        CITY: "HERICOURT",
      },
      {
        CITY: "HERICY",
      },
      {
        CITY: "HERIMONCOURT",
      },
      {
        CITY: "HERIN",
      },
      {
        CITY: "HERISSON",
      },
      {
        CITY: "HERLIES",
      },
      {
        CITY: "HERMANVILLE-SUR-MER",
      },
      {
        CITY: "HERMELANGE",
      },
      {
        CITY: "HERMERAY",
      },
      {
        CITY: "HERMES",
      },
      {
        CITY: "HERMIES",
      },
      {
        CITY: "HERMILLON",
      },
      {
        CITY: "HERNY",
      },
      {
        CITY: "HEROUVILLETTE",
      },
      {
        CITY: "HERRLISHEIM-PRES-COLMAR",
      },
      {
        CITY: "HERSERANGE",
      },
      {
        CITY: "HERSIN-COUPIGNY",
      },
      {
        CITY: "HERY-SUR-ALBY",
      },
      {
        CITY: "HERZEELE",
      },
      {
        CITY: "HESDIN",
      },
      {
        CITY: "HESINGUE",
      },
      {
        CITY: "HESTRUS",
      },
      {
        CITY: "HETTANGE-GRANDE",
      },
      {
        CITY: "HEUCHIN",
      },
      {
        CITY: "HEUDEBOUVILLE",
      },
      {
        CITY: "HEUDICOURT",
      },
      {
        CITY: "HEUDICOURT-SOUS-LES-COTES",
      },
      {
        CITY: "HEUDREVILLE-SUR-EURE",
      },
      {
        CITY: "HEUGAS",
      },
      {
        CITY: "HEUQUEVILLE",
      },
      {
        CITY: "HEURTEVENT",
      },
      {
        CITY: "HEUTREGIVILLE",
      },
      {
        CITY: "HEYRIEUX",
      },
      {
        CITY: "HIGUERES-SOUYE",
      },
      {
        CITY: "HILBESHEIM",
      },
      {
        CITY: "HILLION",
      },
      {
        CITY: "HINDLINGEN",
      },
      {
        CITY: "HINGES",
      },
      {
        CITY: "HIPSHEIM",
      },
      {
        CITY: "HIREL",
      },
      {
        CITY: "HIRSINGUE",
      },
      {
        CITY: "HIRSON",
      },
      {
        CITY: "HOCHFELDEN",
      },
      {
        CITY: "HOCHSTATT",
      },
      {
        CITY: "HOCQUIGNY",
      },
      {
        CITY: "HODENC-EN-BRAY",
      },
      {
        CITY: "HODENG-HODENGER",
      },
      {
        CITY: "HOENHEIM",
      },
      {
        CITY: "HOERDT",
      },
      {
        CITY: "HOHFRANKENHEIM",
      },
      {
        CITY: "HOHWILLER",
      },
      {
        CITY: "HOLNON",
      },
      {
        CITY: "HOLTZHEIM",
      },
      {
        CITY: "HOLTZWIHR",
      },
      {
        CITY: "HOLVING",
      },
      {
        CITY: "HOMBLEUX",
      },
      {
        CITY: "HOMBLIERES",
      },
      {
        CITY: "HOMBOURG",
      },
      {
        CITY: "HOMBOURG-BUDANGE",
      },
      {
        CITY: "HOMBOURG-HAUT",
      },
      {
        CITY: "HOMECOURT",
      },
      {
        CITY: "HOMPS",
      },
      {
        CITY: "HON-HERGIES",
      },
      {
        CITY: "HONDEGHEM",
      },
      {
        CITY: "HONDOUVILLE",
      },
      {
        CITY: "HONDSCHOOTE",
      },
      {
        CITY: "HONFLEUR",
      },
      {
        CITY: "HONNECOURT-SUR-ESCAUT",
      },
      {
        CITY: "HOPITAL-CAMFROUT",
      },
      {
        CITY: "HORBOURG-WIHR",
      },
      {
        CITY: "HORDAIN",
      },
      {
        CITY: "HORGUES",
      },
      {
        CITY: "HORNAING",
      },
      {
        CITY: "HOSSEGOR",
      },
      {
        CITY: "HOUCHIN",
      },
      {
        CITY: "HOUDAIN-LEZ-BAVAY",
      },
      {
        CITY: "HOUDAN",
      },
      {
        CITY: "HOUDELMONT",
      },
      {
        CITY: "HOUDEMONT",
      },
      {
        CITY: "HOUEILLES",
      },
      {
        CITY: "HOUILLES",
      },
      {
        CITY: "HOULBEC-COCHEREL",
      },
      {
        CITY: "HOULETTE",
      },
      {
        CITY: "HOULGATE",
      },
      {
        CITY: "HOULLE",
      },
      {
        CITY: "HOUPLIN-ANCOISNE",
      },
      {
        CITY: "HOUPLINES",
      },
      {
        CITY: "HOUPPEVILLE",
      },
      {
        CITY: "HOURTIN",
      },
      {
        CITY: "HOURY",
      },
      {
        CITY: "HOUSSEN",
      },
      {
        CITY: "HOUSSEVILLE",
      },
      {
        CITY: "HOUVILLE-LA-BRANCHE",
      },
      {
        CITY: "HOUX",
      },
      {
        CITY: "HOYMILLE",
      },
      {
        CITY: "HUBERSENT",
      },
      {
        CITY: "HUBERVILLE",
      },
      {
        CITY: "HUCHENNEVILLE",
      },
      {
        CITY: "HUCQUELIERS",
      },
      {
        CITY: "HUDIMESNIL",
      },
      {
        CITY: "HUELGOAT",
      },
      {
        CITY: "HUEST",
      },
      {
        CITY: "HUEZ",
      },
      {
        CITY: "HUGIER",
      },
      {
        CITY: "HUIRON",
      },
      {
        CITY: "HUISSEAU-SUR-COSSON",
      },
      {
        CITY: "HUISSEAU-SUR-MAUVES",
      },
      {
        CITY: "HULLUCH",
      },
      {
        CITY: "HULTEHOUSE",
      },
      {
        CITY: "HUNDLING",
      },
      {
        CITY: "HUNDSBACH",
      },
      {
        CITY: "HUNINGUE",
      },
      {
        CITY: "HUNSPACH",
      },
      {
        CITY: "HUPPY",
      },
      {
        CITY: "HURE",
      },
      {
        CITY: "HURIEL",
      },
      {
        CITY: "HURIGNY",
      },
      {
        CITY: "HUSSEREN-LES-CHATEAUX",
      },
      {
        CITY: "HUSSEREN-WESSERLING",
      },
      {
        CITY: "HUSSIGNY-GODBRANGE",
      },
      {
        CITY: "HUTTENHEIM",
      },
      {
        CITY: "HYEMONDANS",
      },
      {
        CITY: "IDRAC-RESPAILLES",
      },
      {
        CITY: "IDRON",
      },
      {
        CITY: "IFFENDIC",
      },
      {
        CITY: "IFS",
      },
      {
        CITY: "IGNEY",
      },
      {
        CITY: "IGNY",
      },
      {
        CITY: "IGON",
      },
      {
        CITY: "IGOVILLE",
      },
      {
        CITY: "ILLANGE",
      },
      {
        CITY: "ILLE-SUR-TET",
      },
      {
        CITY: "ILLFURTH",
      },
      {
        CITY: "ILLHAEUSERN",
      },
      {
        CITY: "ILLIAT",
      },
      {
        CITY: "ILLIERS-COMBRAY",
      },
      {
        CITY: "ILLIFAUT",
      },
      {
        CITY: "ILLKIRCH-GRAFFENSTADEN",
      },
      {
        CITY: "ILLOIS",
      },
      {
        CITY: "ILLZACH",
      },
      {
        CITY: "IMLING",
      },
      {
        CITY: "IMPHY",
      },
      {
        CITY: "INCHEVILLE",
      },
      {
        CITY: "INCHY",
      },
      {
        CITY: "INDRE",
      },
      {
        CITY: "INGERSHEIM",
      },
      {
        CITY: "INGOLSHEIM",
      },
      {
        CITY: "INGRANDES",
      },
      {
        CITY: "INGRE",
      },
      {
        CITY: "INGUINIEL",
      },
      {
        CITY: "INGWILLER",
      },
      {
        CITY: "INNENHEIM",
      },
      {
        CITY: "INXENT",
      },
      {
        CITY: "IPPLING",
      },
      {
        CITY: "IRAI",
      },
      {
        CITY: "IRANCY",
      },
      {
        CITY: "IRIGNY",
      },
      {
        CITY: "IRISSARRY",
      },
      {
        CITY: "IRODOUER",
      },
      {
        CITY: "IROULEGUY",
      },
      {
        CITY: "IRREVILLE",
      },
      {
        CITY: "IS-SUR-TILLE",
      },
      {
        CITY: "ISBERGUES",
      },
      {
        CITY: "ISCHES",
      },
      {
        CITY: "ISIGNY-LE-BUAT",
      },
      {
        CITY: "ISIGNY-SUR-MER",
      },
      {
        CITY: "ISLE",
      },
      {
        CITY: "ISLE-ET-BARDAIS",
      },
      {
        CITY: "ISLES-LES-VILLENOY",
      },
      {
        CITY: "ISNEAUVILLE",
      },
      {
        CITY: "ISOLA",
      },
      {
        CITY: "ISPOURE",
      },
      {
        CITY: "ISQUES",
      },
      {
        CITY: "ISSANCOURT-ET-RUMEL",
      },
      {
        CITY: "ISSENHAUSEN",
      },
      {
        CITY: "ISSENHEIM",
      },
      {
        CITY: "ISSOIRE",
      },
      {
        CITY: "ISSOU",
      },
      {
        CITY: "ISSOUDUN",
      },
      {
        CITY: "ISSUS",
      },
      {
        CITY: "ISSY-LES-MOULINEAUX",
      },
      {
        CITY: "ISTRES",
      },
      {
        CITY: "ITANCOURT",
      },
      {
        CITY: "ITEUIL",
      },
      {
        CITY: "ITTENHEIM",
      },
      {
        CITY: "ITTEVILLE",
      },
      {
        CITY: "ITXASSOU",
      },
      {
        CITY: "IVILLE",
      },
      {
        CITY: "IVOY-LE-PRE",
      },
      {
        CITY: "IVRY-LA-BATAILLE",
      },
      {
        CITY: "IVRY-LE-TEMPLE",
      },
      {
        CITY: "IVRY-SUR-SEINE",
      },
      {
        CITY: "IWUY",
      },
      {
        CITY: "IZEAUX",
      },
      {
        CITY: "IZERNORE",
      },
      {
        CITY: "IZERON",
      },
      {
        CITY: "IZIER",
      },
      {
        CITY: "IZON",
      },
      {
        CITY: "IZOTGES",
      },
      {
        CITY: "JABREILLES-LES-BORDES",
      },
      {
        CITY: "JACOB-BELLECOMBETTE",
      },
      {
        CITY: "JACOU",
      },
      {
        CITY: "JAINVILLOTTE",
      },
      {
        CITY: "JALLAIS",
      },
      {
        CITY: "JALLANS",
      },
      {
        CITY: "JALLERANGE",
      },
      {
        CITY: "JALOGNES",
      },
      {
        CITY: "JALOGNY",
      },
      {
        CITY: "JALONS",
      },
      {
        CITY: "JAMBLES",
      },
      {
        CITY: "JAMETZ",
      },
      {
        CITY: "JANDUN",
      },
      {
        CITY: "JANNEYRIAS",
      },
      {
        CITY: "JANVILLE",
      },
      {
        CITY: "JANVILLE",
      },
      {
        CITY: "JANVILLE-SUR-JUINE",
      },
      {
        CITY: "JANVILLIERS",
      },
      {
        CITY: "JANVRY",
      },
      {
        CITY: "JANZE",
      },
      {
        CITY: "JARCIEU",
      },
      {
        CITY: "JARD-SUR-MER",
      },
      {
        CITY: "JARDIN",
      },
      {
        CITY: "JARDRES",
      },
      {
        CITY: "JARGEAU",
      },
      {
        CITY: "JARMENIL",
      },
      {
        CITY: "JARNAC-CHAMPAGNE",
      },
      {
        CITY: "JARNAGES",
      },
      {
        CITY: "JARNY",
      },
      {
        CITY: "JARRIE",
      },
      {
        CITY: "JARRIER",
      },
      {
        CITY: "JARS",
      },
      {
        CITY: "JARVILLE-LA-MALGRANGE",
      },
      {
        CITY: "JARZE",
      },
      {
        CITY: "JASSANS-RIOTTIER",
      },
      {
        CITY: "JASSERON",
      },
      {
        CITY: "JAUCOURT",
      },
      {
        CITY: "JAUJAC",
      },
      {
        CITY: "JAUNAY-CLAN",
      },
      {
        CITY: "JAURE",
      },
      {
        CITY: "JAUSIERS",
      },
      {
        CITY: "JAUX",
      },
      {
        CITY: "JAVENE",
      },
      {
        CITY: "JAVREZAC",
      },
      {
        CITY: "JAYAT",
      },
      {
        CITY: "JAZENEUIL",
      },
      {
        CITY: "JEANDELAINCOURT",
      },
      {
        CITY: "JEANMENIL",
      },
      {
        CITY: "JEBSHEIM",
      },
      {
        CITY: "JEGUN",
      },
      {
        CITY: "JENLAIN",
      },
      {
        CITY: "JESONVILLE",
      },
      {
        CITY: "JETTINGEN",
      },
      {
        CITY: "JEU-LES-BOIS",
      },
      {
        CITY: "JEUFOSSE",
      },
      {
        CITY: "JEUMONT",
      },
      {
        CITY: "JEUNE",
      },
      {
        CITY: "JEURRE",
      },
      {
        CITY: "JEUX-LES-BARD",
      },
      {
        CITY: "JEUXEY",
      },
      {
        CITY: "JEZAINVILLE",
      },
      {
        CITY: "JOB",
      },
      {
        CITY: "JOBOURG",
      },
      {
        CITY: "JOCH",
      },
      {
        CITY: "JOEUF",
      },
      {
        CITY: "JOIGNY",
      },
      {
        CITY: "JOIGNY-SUR-MEUSE",
      },
      {
        CITY: "JOINVILLE",
      },
      {
        CITY: "JOINVILLE-LE-PONT",
      },
      {
        CITY: "JOLIMETZ",
      },
      {
        CITY: "JOLIVET",
      },
      {
        CITY: "JONAGE",
      },
      {
        CITY: "JONCHERY-SUR-VESLE",
      },
      {
        CITY: "JONCOURT",
      },
      {
        CITY: "JONQUERETS-DE-LIVET",
      },
      {
        CITY: "JONQUERETTES",
      },
      {
        CITY: "JONQUIERES",
      },
      {
        CITY: "JONQUIERES",
      },
      {
        CITY: "JONQUIERES",
      },
      {
        CITY: "JONS",
      },
      {
        CITY: "JONVELLE",
      },
      {
        CITY: "JONVILLIERS",
      },
      {
        CITY: "JONZAC",
      },
      {
        CITY: "JONZIER-EPAGNY",
      },
      {
        CITY: "JONZIEUX",
      },
      {
        CITY: "JOSNES",
      },
      {
        CITY: "JOSSE",
      },
      {
        CITY: "JOSSELIN",
      },
      {
        CITY: "JOSSIGNY",
      },
      {
        CITY: "JOUARRE",
      },
      {
        CITY: "JOUARS-PONTCHARTRAIN",
      },
      {
        CITY: "JOUAVILLE",
      },
      {
        CITY: "JOUDREVILLE",
      },
      {
        CITY: "JOUE-SUR-ERDRE",
      },
      {
        CITY: "JOUGNE",
      },
      {
        CITY: "JOUHET",
      },
      {
        CITY: "JOUQUES",
      },
      {
        CITY: "JOURNANS",
      },
      {
        CITY: "JOURNIAC",
      },
      {
        CITY: "JOURNY",
      },
      {
        CITY: "JOUX",
      },
      {
        CITY: "JOUY-EN-JOSAS",
      },
      {
        CITY: "JOUY-LE-CHATEL",
      },
      {
        CITY: "JOUY-LE-MOUTIER",
      },
      {
        CITY: "JOUY-LE-POTIER",
      },
      {
        CITY: "JOUY-SOUS-THELLE",
      },
      {
        CITY: "JOUY-SUR-EURE",
      },
      {
        CITY: "JOUY-SUR-MORIN",
      },
      {
        CITY: "JUAN-LES-PINS",
      },
      {
        CITY: "JUGEALS-NAZARETH",
      },
      {
        CITY: "JUGON-LES-LACS",
      },
      {
        CITY: "JUIGNE-SUR-LOIRE",
      },
      {
        CITY: "JUIGNE-SUR-SARTHE",
      },
      {
        CITY: "JUILLAC",
      },
      {
        CITY: "JUILLAN",
      },
      {
        CITY: "JUILLES",
      },
      {
        CITY: "JUILLY",
      },
      {
        CITY: "JUILLY",
      },
      {
        CITY: "JUJURIEUX",
      },
      {
        CITY: "JULIENAS",
      },
      {
        CITY: "JULIENNE",
      },
      {
        CITY: "JULLY-SUR-SARCE",
      },
      {
        CITY: "JUMEAUVILLE",
      },
      {
        CITY: "JUMELLES",
      },
      {
        CITY: "JUMELLES",
      },
      {
        CITY: "JUMILHAC-LE-GRAND",
      },
      {
        CITY: "JUNAY",
      },
      {
        CITY: "JUNGHOLTZ",
      },
      {
        CITY: "JUNIVILLE",
      },
      {
        CITY: "JUPILLES",
      },
      {
        CITY: "JURANCON",
      },
      {
        CITY: "JURY",
      },
      {
        CITY: "JUSSEY",
      },
      {
        CITY: "JUSSY",
      },
      {
        CITY: "JUSSY",
      },
      {
        CITY: "JUVAINCOURT",
      },
      {
        CITY: "JUVIGNAC",
      },
      {
        CITY: "JUVIGNY",
      },
      {
        CITY: "JUVIGNY",
      },
      {
        CITY: "JUVIGNY-EN-PERTHOIS",
      },
      {
        CITY: "JUVILLE",
      },
      {
        CITY: "JUVISY-SUR-ORGE",
      },
      {
        CITY: "JUZIERS",
      },
      {
        CITY: "KALTENHOUSE",
      },
      {
        CITY: "KAPPELEN",
      },
      {
        CITY: "KATZENTHAL",
      },
      {
        CITY: "KAYSERSBERG",
      },
      {
        CITY: "KEDANGE-SUR-CANNER",
      },
      {
        CITY: "KEMBS",
      },
      {
        CITY: "KERBACH",
      },
      {
        CITY: "KERFOT",
      },
      {
        CITY: "KERFOURN",
      },
      {
        CITY: "KERGLOFF",
      },
      {
        CITY: "KERIEN",
      },
      {
        CITY: "KERITY",
      },
      {
        CITY: "KERLAZ",
      },
      {
        CITY: "KERLOUAN",
      },
      {
        CITY: "KERNASCLEDEN",
      },
      {
        CITY: "KERPERT",
      },
      {
        CITY: "KERVIGNAC",
      },
      {
        CITY: "KESSELDORF",
      },
      {
        CITY: "KIENHEIM",
      },
      {
        CITY: "KIENTZHEIM",
      },
      {
        CITY: "KILLEM",
      },
      {
        CITY: "KILSTETT",
      },
      {
        CITY: "KINGERSHEIM",
      },
      {
        CITY: "KINTZHEIM",
      },
      {
        CITY: "KNUTANGE",
      },
      {
        CITY: "KOENIGSMACKER",
      },
      {
        CITY: "KOEUR-LA-PETITE",
      },
      {
        CITY: "KRAFFT",
      },
      {
        CITY: "KRAUTERGERSHEIM",
      },
      {
        CITY: "KRIEGSHEIM",
      },
      {
        CITY: "KRUTH",
      },
      {
        CITY: "KUNHEIM",
      },
      {
        CITY: "KUNTZIG",
      },
      {
        CITY: "KURTZENHOUSE",
      },
      {
        CITY: "KUTZENHAUSEN",
      },
      {
        CITY: "LA BACONNIERE",
      },
      {
        CITY: "LA BARBEN",
      },
      {
        CITY: "LA BARRE-DE-MONTS",
      },
      {
        CITY: "LA BARRE-EN-OUCHE",
      },
      {
        CITY: "LA BARTHE",
      },
      {
        CITY: "LA BASSEE",
      },
      {
        CITY: "LA BASTIDE",
      },
      {
        CITY: "LA BASTIDE",
      },
      {
        CITY: "LA BASTIDE",
      },
      {
        CITY: "LA BATHIE",
      },
      {
        CITY: "LA BATIE",
      },
      {
        CITY: "LA BAULE-ESCOUBLAC",
      },
      {
        CITY: "LA BAUME-DE-TRANSIT",
      },
      {
        CITY: "LA BAUSSAINE",
      },
      {
        CITY: "LA BAZOCHE-GOUET",
      },
      {
        CITY: "LA BAZOGE",
      },
      {
        CITY: "LA BEGUE",
      },
      {
        CITY: "LA BERNERIE-EN-RETZ",
      },
      {
        CITY: "LA BERTHENOUX",
      },
      {
        CITY: "LA BIGNE",
      },
      {
        CITY: "LA BIOLLE",
      },
      {
        CITY: "LA BOHALLE",
      },
      {
        CITY: "LA BOISSIERE-DES-LANDES",
      },
      {
        CITY: "LA BOSSE",
      },
      {
        CITY: "LA BOSSE-DE-BRETAGNE",
      },
      {
        CITY: "LA BOUEXIERE",
      },
      {
        CITY: "LA BOUILLADISSE",
      },
      {
        CITY: "LA BOURBOULE",
      },
      {
        CITY: "LA BOURGONCE",
      },
      {
        CITY: "LA BOUSSAC",
      },
      {
        CITY: "LA BRESSE",
      },
      {
        CITY: "LA BRETONNE",
      },
      {
        CITY: "LA BRIDOIRE",
      },
      {
        CITY: "LA BRIGUE",
      },
      {
        CITY: "LA BRILLANNE",
      },
      {
        CITY: "LA BROQUE",
      },
      {
        CITY: "LA BROSSE",
      },
      {
        CITY: "LA BRUFFIERE",
      },
      {
        CITY: "LA BRULATTE",
      },
      {
        CITY: "LA BUISSE",
      },
      {
        CITY: "LA BUISSIERE",
      },
      {
        CITY: "LA BUSSIERE",
      },
      {
        CITY: "LA CALMETTE",
      },
      {
        CITY: "LA CALOTTERIE",
      },
      {
        CITY: "LA CANOURGUE",
      },
      {
        CITY: "LA CAPELLE",
      },
      {
        CITY: "LA CAUNETTE",
      },
      {
        CITY: "LA CAVALERIE",
      },
      {
        CITY: "LA CELLE",
      },
      {
        CITY: "LA CELLE",
      },
      {
        CITY: "LA CELLE",
      },
      {
        CITY: "LA CELLE-SAINT-CLOUD",
      },
      {
        CITY: "LA CELLE-SOUS-MONTMIRAIL",
      },
      {
        CITY: "LA CELLE-SUR-SEINE",
      },
      {
        CITY: "LA CERLANGUE",
      },
      {
        CITY: "LA CHAISE",
      },
      {
        CITY: "LA CHAISE",
      },
      {
        CITY: "LA CHAISE-BAUDOUIN",
      },
      {
        CITY: "LA CHAIZE-LE-VICOMTE",
      },
      {
        CITY: "LA CHAPELAUDE",
      },
      {
        CITY: "LA CHAPELLE",
      },
      {
        CITY: "LA CHAPELLE-AUX-BOIS",
      },
      {
        CITY: "LA CHAPELLE-AUX-LYS",
      },
      {
        CITY: "LA CHAPELLE-BATON",
      },
      {
        CITY: "LA CHAPELLE-BLANCHE",
      },
      {
        CITY: "LA CHAPELLE-DE-BRAIN",
      },
      {
        CITY: "LA CHAPELLE-DE-GUINCHAY",
      },
      {
        CITY: "LA CHAPELLE-EN-VERCORS",
      },
      {
        CITY: "LA CHAPELLE-GAUDIN",
      },
      {
        CITY: "LA CHAPELLE-GAUTHIER",
      },
      {
        CITY: "LA CHAPELLE-HEULIN",
      },
      {
        CITY: "LA CHAPELLE-LAURENT",
      },
      {
        CITY: "LA CHAPELLE-MONTLIGEON",
      },
      {
        CITY: "LA CHAPELLE-ORTHEMALE",
      },
      {
        CITY: "LA CHAPELLE-SAINT-LUC",
      },
      {
        CITY: "LA CHAPELLE-SUR-CHEZY",
      },
      {
        CITY: "LA CHAPELLE-YVON",
      },
      {
        CITY: "LA CHARME",
      },
      {
        CITY: "LA CHARMEE",
      },
      {
        CITY: "LA CHARTRE-SUR-LE-LOIR",
      },
      {
        CITY: "LA CHATAIGNERAIE",
      },
      {
        CITY: "LA CHATRE",
      },
      {
        CITY: "LA CHATRE-LANGLIN",
      },
      {
        CITY: "LA CHAUMUSSE",
      },
      {
        CITY: "LA CHAUSSAIRE",
      },
      {
        CITY: "LA CHAUSSEE",
      },
      {
        CITY: "LA CHAUX-DU-DOMBIEF",
      },
      {
        CITY: "LA CHENEVIERE",
      },
      {
        CITY: "LA CHEVALLERAIS",
      },
      {
        CITY: "LA CHEVROLIERE",
      },
      {
        CITY: "LA CIOTAT",
      },
      {
        CITY: "LA CLAYETTE",
      },
      {
        CITY: "LA CLISSE",
      },
      {
        CITY: "LA CLOTTE",
      },
      {
        CITY: "LA CLUSAZ",
      },
      {
        CITY: "LA CLUSE-ET-MIJOUX",
      },
      {
        CITY: "LA COLLE-SUR-LOUP",
      },
      {
        CITY: "LA COMBELLE",
      },
      {
        CITY: "LA COPECHAGNIERE",
      },
      {
        CITY: "LA COQUILLE",
      },
      {
        CITY: "LA CORNUAILLE",
      },
      {
        CITY: "LA COTE",
      },
      {
        CITY: "LA COTE",
      },
      {
        CITY: "LA COUARDE-SUR-MER",
      },
      {
        CITY: "LA COUCOURDE",
      },
      {
        CITY: "LA COUDRE",
      },
      {
        CITY: "LA COULONCHE",
      },
      {
        CITY: "LA COURNEUVE",
      },
      {
        CITY: "LA COURONNE",
      },
      {
        CITY: "LA COURONNE",
      },
      {
        CITY: "LA COUTURE-BOUSSEY",
      },
      {
        CITY: "LA CRAU",
      },
      {
        CITY: "LA CROIX-VALMER",
      },
      {
        CITY: "LA CROPTE",
      },
      {
        CITY: "LA DAGUENIERE",
      },
      {
        CITY: "LA DEFENSE",
      },
      {
        CITY: "LA DESTROUSSE",
      },
      {
        CITY: "LA DOMINELAIS",
      },
      {
        CITY: "LA FARE-EN-CHAMPSAUR",
      },
      {
        CITY: "LA FARLEDE",
      },
      {
        CITY: "LA FAUTE-SUR-MER",
      },
      {
        CITY: "LA FAYE",
      },
      {
        CITY: "LA FERE",
      },
      {
        CITY: "LA FERRIERE",
      },
      {
        CITY: "LA FERRIERE-BOCHARD",
      },
      {
        CITY: "LA FERTE",
      },
      {
        CITY: "LA FERTE-ALAIS",
      },
      {
        CITY: "LA FERTE-MACE",
      },
      {
        CITY: "LA FERTE-MILON",
      },
      {
        CITY: "LA FERTE-SAINT-CYR",
      },
      {
        CITY: "LA FERTE-SAINT-SAMSON",
      },
      {
        CITY: "LA FERTE-SOUS-JOUARRE",
      },
      {
        CITY: "LA FEUILLADE",
      },
      {
        CITY: "LA FEUILLIE",
      },
      {
        CITY: "LA FEUILLIE",
      },
      {
        CITY: "LA FLACHERE",
      },
      {
        CITY: "LA FLECHE",
      },
      {
        CITY: "LA FLOCELLIERE",
      },
      {
        CITY: "LA FLOTTE",
      },
      {
        CITY: "LA FORCE",
      },
      {
        CITY: "LA FOREST-LANDERNEAU",
      },
      {
        CITY: "LA FORET",
      },
      {
        CITY: "LA FORET",
      },
      {
        CITY: "LA FORET-FOUESNANT",
      },
      {
        CITY: "LA FORIE",
      },
      {
        CITY: "LA FOUILLADE",
      },
      {
        CITY: "LA FOUILLOUSE",
      },
      {
        CITY: "LA FOUX",
      },
      {
        CITY: "LA FOYE-MONJAULT",
      },
      {
        CITY: "LA FRASSE",
      },
      {
        CITY: "LA FRENAYE",
      },
      {
        CITY: "LA FRESNAIS",
      },
      {
        CITY: "LA FRETTE",
      },
      {
        CITY: "LA FRETTE",
      },
      {
        CITY: "LA FRETTE-SUR-SEINE",
      },
      {
        CITY: "LA GACILLY",
      },
      {
        CITY: "LA GAILLARDE",
      },
      {
        CITY: "LA GAILLARDE",
      },
      {
        CITY: "LA GARDE",
      },
      {
        CITY: "LA GARDE",
      },
      {
        CITY: "LA GARENNE",
      },
      {
        CITY: "LA GARENNE-COLOMBES",
      },
      {
        CITY: "LA GARNACHE",
      },
      {
        CITY: "LA GAUBRETIERE",
      },
      {
        CITY: "LA GAUDE",
      },
      {
        CITY: "LA GAVOTTE",
      },
      {
        CITY: "LA GLACERIE",
      },
      {
        CITY: "LA GORGUE",
      },
      {
        CITY: "LA GOUESNIERE",
      },
      {
        CITY: "LA GRAND-CROIX",
      },
      {
        CITY: "LA GRANDE-MOTTE",
      },
      {
        CITY: "LA GRANDVILLE",
      },
      {
        CITY: "LA GRAVE",
      },
      {
        CITY: "LA GRAVERIE",
      },
      {
        CITY: "LA GREE-SAINT-LAURENT",
      },
      {
        CITY: "LA GREVE-SUR-MIGNON",
      },
      {
        CITY: "LA GRIGONNAIS",
      },
      {
        CITY: "LA GROISE",
      },
      {
        CITY: "LA GUERCHE",
      },
      {
        CITY: "LA GUERCHE-DE-BRETAGNE",
      },
      {
        CITY: "LA GUEROULDE",
      },
      {
        CITY: "LA GUIERCHE",
      },
      {
        CITY: "LA GUYONNIERE",
      },
      {
        CITY: "LA HAIE",
      },
      {
        CITY: "LA HALLE",
      },
      {
        CITY: "LA HALLOTIERE",
      },
      {
        CITY: "LA HAYE",
      },
      {
        CITY: "LA HAYE-SAINT-SYLVESTRE",
      },
      {
        CITY: "LA HOUBLONNIERE",
      },
      {
        CITY: "LA HOUSSAYE-EN-BRIE",
      },
      {
        CITY: "LA HOUSSOYE",
      },
      {
        CITY: "LA JAILLE-YVON",
      },
      {
        CITY: "LA JARNE",
      },
      {
        CITY: "LA JARRIE",
      },
      {
        CITY: "LA JUBAUDIERE",
      },
      {
        CITY: "LA JUMELLIERE",
      },
      {
        CITY: "LA LAIGNE",
      },
      {
        CITY: "LA LANDE",
      },
      {
        CITY: "LA LANDE",
      },
      {
        CITY: "LA LANDEC",
      },
      {
        CITY: "LA LAUPIE",
      },
      {
        CITY: "LA LECHERE",
      },
      {
        CITY: "LA LIMOUZINIERE",
      },
      {
        CITY: "LA LIVINIERE",
      },
      {
        CITY: "LA LONDE",
      },
      {
        CITY: "LA LONDE-LES-MAURES",
      },
      {
        CITY: "LA LOUBIERE",
      },
      {
        CITY: "LA LOUPE",
      },
      {
        CITY: "LA LUCERNE-DOUTREMER",
      },
      {
        CITY: "LA MACHINE",
      },
      {
        CITY: "LA MADELEINE",
      },
      {
        CITY: "LA MADELEINE",
      },
      {
        CITY: "LA MADELEINE-SUR-LOING",
      },
      {
        CITY: "LA MAGDELAINE-SUR-TARN",
      },
      {
        CITY: "LA MAILLERAYE-SUR-SEINE",
      },
      {
        CITY: "LA MALHOURE",
      },
      {
        CITY: "LA MARCHE",
      },
      {
        CITY: "LA MAROLLE-EN-SOLOGNE",
      },
      {
        CITY: "LA MARTINIERE",
      },
      {
        CITY: "LA MAXE",
      },
      {
        CITY: "LA MEAUFFE",
      },
      {
        CITY: "LA MEAUGON",
      },
      {
        CITY: "LA MEIGNANNE",
      },
      {
        CITY: "LA MEILLERAIE-TILLAY",
      },
      {
        CITY: "LA MEILLERAYE-DE-BRETAGNE",
      },
      {
        CITY: "LA MEMBROLLE-SUR-CHOISILLE",
      },
      {
        CITY: "LA MEMBROLLE-SUR-LONGUENEE",
      },
      {
        CITY: "LA MENITRE",
      },
      {
        CITY: "LA MEZIERE",
      },
      {
        CITY: "LA MILESSE",
      },
      {
        CITY: "LA MONTAGNE",
      },
      {
        CITY: "LA MONTAGNE",
      },
      {
        CITY: "LA MONTAGNE",
      },
      {
        CITY: "LA MOTHE-SAINT-HERAY",
      },
      {
        CITY: "LA MOTTE",
      },
      {
        CITY: "LA MOTTE-CHALANCON",
      },
      {
        CITY: "LA MOTTE-DU-CAIRE",
      },
      {
        CITY: "LA MOTTE-FOUQUET",
      },
      {
        CITY: "LA MOTTE-SAINT-JEAN",
      },
      {
        CITY: "LA MOTTE-SAINT-MARTIN",
      },
      {
        CITY: "LA MOTTE-SERVOLEX",
      },
      {
        CITY: "LA MULATIERE",
      },
      {
        CITY: "LA MURAZ",
      },
      {
        CITY: "LA MURE",
      },
      {
        CITY: "LA NAPOULE",
      },
      {
        CITY: "LA NERTHE",
      },
      {
        CITY: "LA NEUVEVILLE-DEVANT-LEPANGES",
      },
      {
        CITY: "LA NEUVILLE-DU-BOSC",
      },
      {
        CITY: "LA NOE",
      },
      {
        CITY: "LA NORVILLE",
      },
      {
        CITY: "LA PALUD",
      },
      {
        CITY: "LA PAUTE",
      },
      {
        CITY: "LA PENNE-SUR-HUVEAUNE",
      },
      {
        CITY: "LA PERCHE",
      },
      {
        CITY: "LA PLACE",
      },
      {
        CITY: "LA PLAGNE",
      },
      {
        CITY: "LA PLAINE",
      },
      {
        CITY: "LA PLAINE",
      },
      {
        CITY: "LA PLAINE-SAINT-DENIS",
      },
      {
        CITY: "LA PLANCHE",
      },
      {
        CITY: "LA PLANE",
      },
      {
        CITY: "LA POINTE",
      },
      {
        CITY: "LA POITEVINIERE",
      },
      {
        CITY: "LA POMMERAYE",
      },
      {
        CITY: "LA PORCHERIE",
      },
      {
        CITY: "LA PORTE",
      },
      {
        CITY: "LA POSSONNIERE",
      },
      {
        CITY: "LA POSTE",
      },
      {
        CITY: "LA POTERIE",
      },
      {
        CITY: "LA POTERIE-MATHIEU",
      },
      {
        CITY: "LA POUEZE",
      },
      {
        CITY: "LA PUYE",
      },
      {
        CITY: "LA QUEUE-EN-BRIE",
      },
      {
        CITY: "LA QUEUE-LES-YVELINES",
      },
      {
        CITY: "LA RAVOIRE",
      },
      {
        CITY: "LA REMUEE",
      },
      {
        CITY: "LA RENAUDIERE",
      },
      {
        CITY: "LA REOLE",
      },
      {
        CITY: "LA REORTHE",
      },
      {
        CITY: "LA RICAMARIE",
      },
      {
        CITY: "LA RICHARDAIS",
      },
      {
        CITY: "LA RICHE",
      },
      {
        CITY: "LA RIVIERE",
      },
      {
        CITY: "LA RIVIERE",
      },
      {
        CITY: "LA RIVIERE",
      },
      {
        CITY: "LA RIVIERE",
      },
      {
        CITY: "LA RIVIERE-DE-CORPS",
      },
      {
        CITY: "LA RIXOUSE",
      },
      {
        CITY: "LA ROCHE",
      },
      {
        CITY: "LA ROCHE",
      },
      {
        CITY: "LA ROCHE",
      },
      {
        CITY: "LA ROCHE",
      },
      {
        CITY: "LA ROCHE-BLANCHE",
      },
      {
        CITY: "LA ROCHE-DES-ARNAUDS",
      },
      {
        CITY: "LA ROCHE-SUR-LE-BUIS",
      },
      {
        CITY: "LA ROCHE-SUR-YON",
      },
      {
        CITY: "LA ROCHEFOUCAULD",
      },
      {
        CITY: "LA ROCHEGIRON",
      },
      {
        CITY: "LA ROCHELLE",
      },
      {
        CITY: "LA ROCHENARD",
      },
      {
        CITY: "LA ROCHETTE",
      },
      {
        CITY: "LA ROCHETTE",
      },
      {
        CITY: "LA ROCHETTE-SUR-CREST",
      },
      {
        CITY: "LA ROMAGNE",
      },
      {
        CITY: "LA RONDE",
      },
      {
        CITY: "LA ROQUE-BAIGNARD",
      },
      {
        CITY: "LA ROQUEBRUSSANNE",
      },
      {
        CITY: "LA ROQUETTE-SUR-SIAGNE",
      },
      {
        CITY: "LA ROUINE",
      },
      {
        CITY: "LA ROUQUETTE",
      },
      {
        CITY: "LA ROUVIERE",
      },
      {
        CITY: "LA SALETTE-FALLAVAUX",
      },
      {
        CITY: "LA SALLE-DE-VIHIERS",
      },
      {
        CITY: "LA SALVETAT-LAURAGAIS",
      },
      {
        CITY: "LA SALVETAT-SAINT-GILLES",
      },
      {
        CITY: "LA SALVETAT-SUR-AGOUT",
      },
      {
        CITY: "LA SAULCE",
      },
      {
        CITY: "LA SAUVE",
      },
      {
        CITY: "LA SAUVETAT-DE-SAVERES",
      },
      {
        CITY: "LA SEAUVE-SUR-SEMENE",
      },
      {
        CITY: "LA SEGUINIERE",
      },
      {
        CITY: "LA SELLE-EN-HERMOY",
      },
      {
        CITY: "LA SELVE",
      },
      {
        CITY: "LA SENTINELLE",
      },
      {
        CITY: "LA SEYNE-SUR-MER",
      },
      {
        CITY: "LA SONE",
      },
      {
        CITY: "LA SOUCHE",
      },
      {
        CITY: "LA SOUTERRAINE",
      },
      {
        CITY: "LA SUZE-SUR-SARTHE",
      },
      {
        CITY: "LA TAGNIERE",
      },
      {
        CITY: "LA TAILLEE",
      },
      {
        CITY: "LA TALAUDIERE",
      },
      {
        CITY: "LA TARDIERE",
      },
      {
        CITY: "LA TERRASSE",
      },
      {
        CITY: "LA TESSOUALLE",
      },
      {
        CITY: "LA TESTE-DE-BUCH",
      },
      {
        CITY: "LA TOUR",
      },
      {
        CITY: "LA TOUR",
      },
      {
        CITY: "LA TOUR-DAUVERGNE",
      },
      {
        CITY: "LA TOUR-DE-SCAY",
      },
      {
        CITY: "LA TOUR-DU-CRIEU",
      },
      {
        CITY: "LA TOUR-SAINT-GELIN",
      },
      {
        CITY: "LA TOUR-SUR-ORB",
      },
      {
        CITY: "LA TOURETTE",
      },
      {
        CITY: "LA TRANCHE-SUR-MER",
      },
      {
        CITY: "LA TREILLE",
      },
      {
        CITY: "LA TREMBLADE",
      },
      {
        CITY: "LA TRIMOUILLE",
      },
      {
        CITY: "LA TRINITE",
      },
      {
        CITY: "LA TRINITE-DE-REVILLE",
      },
      {
        CITY: "LA TRONCHE",
      },
      {
        CITY: "LA TUILIERE",
      },
      {
        CITY: "LA TURBALLE",
      },
      {
        CITY: "LA TURBIE",
      },
      {
        CITY: "LA VACHERIE",
      },
      {
        CITY: "LA VALETTE-DU-VAR",
      },
      {
        CITY: "LA VALLEE DE DORMELLES",
      },
      {
        CITY: "LA VARENNE",
      },
      {
        CITY: "LA VARENNE",
      },
      {
        CITY: "LA VAUPALIERE",
      },
      {
        CITY: "LA VERPILLIERE",
      },
      {
        CITY: "LA VERRIE",
      },
      {
        CITY: "LA VERRIERE",
      },
      {
        CITY: "LA VESPIERE",
      },
      {
        CITY: "LA VEZE",
      },
      {
        CITY: "LA VILLE",
      },
      {
        CITY: "LA VILLE AUX CHIENS",
      },
      {
        CITY: "LA VILLE-AUX-BOIS",
      },
      {
        CITY: "LA VILLEDIEU",
      },
      {
        CITY: "LA VILLEDIEU-LES-QUENOCHE",
      },
      {
        CITY: "LA VILLENEUVE",
      },
      {
        CITY: "LA VILLETELLE",
      },
      {
        CITY: "LA VOULTE-SUR-RHONE",
      },
      {
        CITY: "LA VRAIE-CROIX",
      },
      {
        CITY: "LA WALCK",
      },
      {
        CITY: "LA WANTZENAU",
      },
      {
        CITY: "LAAS",
      },
      {
        CITY: "LABAROCHE",
      },
      {
        CITY: "LABARTHE-SUR-LEZE",
      },
      {
        CITY: "LABASTIDE-BEAUVOIR",
      },
      {
        CITY: "LABASTIDE-CEZERACQ",
      },
      {
        CITY: "LABASTIDE-DARMAGNAC",
      },
      {
        CITY: "LABASTIDE-DE-VIRAC",
      },
      {
        CITY: "LABASTIDE-GABAUSSE",
      },
      {
        CITY: "LABASTIDE-ROUAIROUX",
      },
      {
        CITY: "LABASTIDE-SAINT-PIERRE",
      },
      {
        CITY: "LABASTIDETTE",
      },
      {
        CITY: "LABATUT",
      },
      {
        CITY: "LABEGE",
      },
      {
        CITY: "LABENNE",
      },
      {
        CITY: "LABESSIERE-CANDEIL",
      },
      {
        CITY: "LABEUVRIERE",
      },
      {
        CITY: "LABLACHERE",
      },
      {
        CITY: "LABOUHEYRE",
      },
      {
        CITY: "LABOUQUERIE",
      },
      {
        CITY: "LABOURSE",
      },
      {
        CITY: "LABRETONIE",
      },
      {
        CITY: "LABRUGUIERE",
      },
      {
        CITY: "LABRUYERE",
      },
      {
        CITY: "LABRY",
      },
      {
        CITY: "LABUISSIERE",
      },
      {
        CITY: "LACABAREDE",
      },
      {
        CITY: "LACADEE",
      },
      {
        CITY: "LACANAU",
      },
      {
        CITY: "LACAPELLE-CABANAC",
      },
      {
        CITY: "LACAPELLE-MARIVAL",
      },
      {
        CITY: "LACAPELLE-VIESCAMP",
      },
      {
        CITY: "LACAUNE",
      },
      {
        CITY: "LACAUSSADE",
      },
      {
        CITY: "LACHAPELLE",
      },
      {
        CITY: "LACHAPELLE-SOUS-CHAUX",
      },
      {
        CITY: "LACHAPELLE-SOUS-ROUGEMONT",
      },
      {
        CITY: "LACHAUX",
      },
      {
        CITY: "LACOUR",
      },
      {
        CITY: "LACQ",
      },
      {
        CITY: "LACROIX-FALGARDE",
      },
      {
        CITY: "LACROIX-SAINT-OUEN",
      },
      {
        CITY: "LACROIX-SUR-MEUSE",
      },
      {
        CITY: "LACROST",
      },
      {
        CITY: "LACS",
      },
      {
        CITY: "LADOIX-SERRIGNY",
      },
      {
        CITY: "LADON",
      },
      {
        CITY: "LADUZ",
      },
      {
        CITY: "LAFARE",
      },
      {
        CITY: "LAFEUILLADE-EN-VEZIE",
      },
      {
        CITY: "LAFITOLE",
      },
      {
        CITY: "LAFOX",
      },
      {
        CITY: "LAGARDELLE-SUR-LEZE",
      },
      {
        CITY: "LAGARDERE",
      },
      {
        CITY: "LAGARRIGUE",
      },
      {
        CITY: "LAGNES",
      },
      {
        CITY: "LAGNIEU",
      },
      {
        CITY: "LAGNY-LE-SEC",
      },
      {
        CITY: "LAGOR",
      },
      {
        CITY: "LAGORD",
      },
      {
        CITY: "LAGRAVE",
      },
      {
        CITY: "LAGUENNE",
      },
      {
        CITY: "LAGUIOLE",
      },
      {
        CITY: "LAGUPIE",
      },
      {
        CITY: "LAHITTE-TOUPIERE",
      },
      {
        CITY: "LAHONCE",
      },
      {
        CITY: "LAHONTAN",
      },
      {
        CITY: "LAHOSSE",
      },
      {
        CITY: "LAHOURCADE",
      },
      {
        CITY: "LAIFOUR",
      },
      {
        CITY: "LAIGNE",
      },
      {
        CITY: "LAIGNE-EN-BELIN",
      },
      {
        CITY: "LAIGNELET",
      },
      {
        CITY: "LAIGNES",
      },
      {
        CITY: "LAIGNEVILLE",
      },
      {
        CITY: "LAIGUILLON-SUR-MER",
      },
      {
        CITY: "LAILLE",
      },
      {
        CITY: "LAILLY-EN-VAL",
      },
      {
        CITY: "LAINSECQ",
      },
      {
        CITY: "LAISSAC",
      },
      {
        CITY: "LAISSAUD",
      },
      {
        CITY: "LAISSEY",
      },
      {
        CITY: "LAIZ",
      },
      {
        CITY: "LAIZE",
      },
      {
        CITY: "LALANNE-ARQUE",
      },
      {
        CITY: "LALBENQUE",
      },
      {
        CITY: "LALEU",
      },
      {
        CITY: "LALINDE",
      },
      {
        CITY: "LALLAING",
      },
      {
        CITY: "LALONQUETTE",
      },
      {
        CITY: "LALOUBERE",
      },
      {
        CITY: "LAMAGDELAINE",
      },
      {
        CITY: "LAMAGISTERE",
      },
      {
        CITY: "LAMAIRE",
      },
      {
        CITY: "LAMALOU-LES-BAINS",
      },
      {
        CITY: "LAMANON",
      },
      {
        CITY: "LAMARCHE",
      },
      {
        CITY: "LAMARCHE-SUR-SAONE",
      },
      {
        CITY: "LAMASTRE",
      },
      {
        CITY: "LAMATIVIE",
      },
      {
        CITY: "LAMAZIERE-BASSE",
      },
      {
        CITY: "LAMBALLE",
      },
      {
        CITY: "LAMBERSART",
      },
      {
        CITY: "LAMBERT",
      },
      {
        CITY: "LAMBESC",
      },
      {
        CITY: "LAMBRES-LEZ-DOUAI",
      },
      {
        CITY: "LAMNAY",
      },
      {
        CITY: "LAMORLAYE",
      },
      {
        CITY: "LAMORVILLE",
      },
      {
        CITY: "LAMOTHE-LANDERRON",
      },
      {
        CITY: "LAMOTHE-MONTRAVEL",
      },
      {
        CITY: "LAMOTTE-BEUVRON",
      },
      {
        CITY: "LAMPAUL-GUIMILIAU",
      },
      {
        CITY: "LAMPAUL-PLOUARZEL",
      },
      {
        CITY: "LAMPERTHEIM",
      },
      {
        CITY: "LAMPERTSLOCH",
      },
      {
        CITY: "LANCEY",
      },
      {
        CITY: "LANCIE",
      },
      {
        CITY: "LANCIEUX",
      },
      {
        CITY: "LANCON-PROVENCE",
      },
      {
        CITY: "LANCRANS",
      },
      {
        CITY: "LANDAS",
      },
      {
        CITY: "LANDEBIA",
      },
      {
        CITY: "LANDEDA",
      },
      {
        CITY: "LANDELLES",
      },
      {
        CITY: "LANDELLES-ET-COUPIGNY",
      },
      {
        CITY: "LANDEMONT",
      },
      {
        CITY: "LANDEPEREUSE",
      },
      {
        CITY: "LANDERNEAU",
      },
      {
        CITY: "LANDERONDE",
      },
      {
        CITY: "LANDES",
      },
      {
        CITY: "LANDEVANT",
      },
      {
        CITY: "LANDEVIEILLE",
      },
      {
        CITY: "LANDIVISIAU",
      },
      {
        CITY: "LANDOGNE",
      },
      {
        CITY: "LANDOS",
      },
      {
        CITY: "LANDRECIES",
      },
      {
        CITY: "LANDRES",
      },
      {
        CITY: "LANDRETHUN-LE-NORD",
      },
      {
        CITY: "LANDRETHUN-LES-ARDRES",
      },
      {
        CITY: "LANDREVARZEC",
      },
      {
        CITY: "LANDRY",
      },
      {
        CITY: "LANDSER",
      },
      {
        CITY: "LANDUJAN",
      },
      {
        CITY: "LANDUNVEZ",
      },
      {
        CITY: "LANESTER",
      },
      {
        CITY: "LANEUVELOTTE",
      },
      {
        CITY: "LANEUVEVILLE-AUX-BOIS",
      },
      {
        CITY: "LANEUVEVILLE-DEVANT-BAYON",
      },
      {
        CITY: "LANEUVEVILLE-DEVANT-NANCY",
      },
      {
        CITY: "LANGAN",
      },
      {
        CITY: "LANGEAC",
      },
      {
        CITY: "LANGEAIS",
      },
      {
        CITY: "LANGENSOULTZBACH",
      },
      {
        CITY: "LANGLADE",
      },
      {
        CITY: "LANGOAT",
      },
      {
        CITY: "LANGOELAN",
      },
      {
        CITY: "LANGOIRAN",
      },
      {
        CITY: "LANGOLEN",
      },
      {
        CITY: "LANGON",
      },
      {
        CITY: "LANGON",
      },
      {
        CITY: "LANGONNET",
      },
      {
        CITY: "LANGOURLA",
      },
      {
        CITY: "LANGRES",
      },
      {
        CITY: "LANGRUNE-SUR-MER",
      },
      {
        CITY: "LANGUEUX",
      },
      {
        CITY: "LANGUIDIC",
      },
      {
        CITY: "LANHELIN",
      },
      {
        CITY: "LANING",
      },
      {
        CITY: "LANLEFF",
      },
      {
        CITY: "LANMERIN",
      },
      {
        CITY: "LANMEUR",
      },
      {
        CITY: "LANNE",
      },
      {
        CITY: "LANNE-EN-BARETOUS",
      },
      {
        CITY: "LANNEBERT",
      },
      {
        CITY: "LANNEMEZAN",
      },
      {
        CITY: "LANNILIS",
      },
      {
        CITY: "LANNION",
      },
      {
        CITY: "LANNOY",
      },
      {
        CITY: "LANNOY-CUILLERE",
      },
      {
        CITY: "LANOBRE",
      },
      {
        CITY: "LANOUEE",
      },
      {
        CITY: "LANRELAS",
      },
      {
        CITY: "LANRODEC",
      },
      {
        CITY: "LANS",
      },
      {
        CITY: "LANS-EN-VERCORS",
      },
      {
        CITY: "LANSAC",
      },
      {
        CITY: "LANSARGUES",
      },
      {
        CITY: "LANTA",
      },
      {
        CITY: "LANTENNE-VERTIERE",
      },
      {
        CITY: "LANTHENANS",
      },
      {
        CITY: "LANTIGNIE",
      },
      {
        CITY: "LANTILLY",
      },
      {
        CITY: "LANTON",
      },
      {
        CITY: "LANTRIAC",
      },
      {
        CITY: "LANVALLAY",
      },
      {
        CITY: "LANVAUDAN",
      },
      {
        CITY: "LANVEOC",
      },
      {
        CITY: "LANVOLLON",
      },
      {
        CITY: "LANZAC",
      },
      {
        CITY: "LAON",
      },
      {
        CITY: "LAPALISSE",
      },
      {
        CITY: "LAPALME",
      },
      {
        CITY: "LAPALUD",
      },
      {
        CITY: "LAPARROUQUIAL",
      },
      {
        CITY: "LAPENCHE",
      },
      {
        CITY: "LAPERRIERE-SUR-SAONE",
      },
      {
        CITY: "LAPEYROUSE-FOSSAT",
      },
      {
        CITY: "LAPEYROUSE-MORNAY",
      },
      {
        CITY: "LAPOUTROIE",
      },
      {
        CITY: "LAPOUYADE",
      },
      {
        CITY: "LAPTE",
      },
      {
        CITY: "LAPUGNOY",
      },
      {
        CITY: "LAQUENEXY",
      },
      {
        CITY: "LAQUEUILLE",
      },
      {
        CITY: "LARAGNE-MONTEGLIN",
      },
      {
        CITY: "LARBRESLE",
      },
      {
        CITY: "LARCAY",
      },
      {
        CITY: "LARCHANT",
      },
      {
        CITY: "LARCHE",
      },
      {
        CITY: "LARDY",
      },
      {
        CITY: "LARGEASSE",
      },
      {
        CITY: "LARGILLAY-MARSONNAY",
      },
      {
        CITY: "LARIVIERE-ARNONCOURT",
      },
      {
        CITY: "LARMOR-BADEN",
      },
      {
        CITY: "LARMOR-PLAGE",
      },
      {
        CITY: "LAROCHE-SAINT-CYDROINE",
      },
      {
        CITY: "LAROIN",
      },
      {
        CITY: "LAROQUE-DE-FA",
      },
      {
        CITY: "LARRAZET",
      },
      {
        CITY: "LARRESSORE",
      },
      {
        CITY: "LARRIVIERE-SAINT-SAVIN",
      },
      {
        CITY: "LARUNS",
      },
      {
        CITY: "LARZICOURT",
      },
      {
        CITY: "LASALLE",
      },
      {
        CITY: "LASBORDES",
      },
      {
        CITY: "LASSAY-LES-CHATEAUX",
      },
      {
        CITY: "LASSAY-SUR-CROISNE",
      },
      {
        CITY: "LASSERAN",
      },
      {
        CITY: "LASSERRE",
      },
      {
        CITY: "LASSEUBE",
      },
      {
        CITY: "LASSIGNY",
      },
      {
        CITY: "LASSON",
      },
      {
        CITY: "LASSY",
      },
      {
        CITY: "LASTIC",
      },
      {
        CITY: "LATHUILE",
      },
      {
        CITY: "LATILLE",
      },
      {
        CITY: "LATOUR",
      },
      {
        CITY: "LATOUR-BAS-ELNE",
      },
      {
        CITY: "LATRESNE",
      },
      {
        CITY: "LATRONQUIERE",
      },
      {
        CITY: "LATTAINVILLE",
      },
      {
        CITY: "LATTES",
      },
      {
        CITY: "LATTRE-SAINT-QUENTIN",
      },
      {
        CITY: "LAUDUN-LARDOISE",
      },
      {
        CITY: "LAUGNAC",
      },
      {
        CITY: "LAUNAGUET",
      },
      {
        CITY: "LAUNAY",
      },
      {
        CITY: "LAURABUC",
      },
      {
        CITY: "LAURE",
      },
      {
        CITY: "LAUREDE",
      },
      {
        CITY: "LAURENAN",
      },
      {
        CITY: "LAURENS",
      },
      {
        CITY: "LAURENT",
      },
      {
        CITY: "LAURET",
      },
      {
        CITY: "LAURIS",
      },
      {
        CITY: "LAUSSONNE",
      },
      {
        CITY: "LAUTENBACH",
      },
      {
        CITY: "LAUTERBOURG",
      },
      {
        CITY: "LAUTREC",
      },
      {
        CITY: "LAUZERTE",
      },
      {
        CITY: "LAUZERVILLE",
      },
      {
        CITY: "LAUZUN",
      },
      {
        CITY: "LAVAL",
      },
      {
        CITY: "LAVAL-SUR-DOULON",
      },
      {
        CITY: "LAVAL-SUR-VOLOGNE",
      },
      {
        CITY: "LAVANNES",
      },
      {
        CITY: "LAVANS-LES-SAINT-CLAUDE",
      },
      {
        CITY: "LAVANS-VUILLAFANS",
      },
      {
        CITY: "LAVARDAC",
      },
      {
        CITY: "LAVARDENS",
      },
      {
        CITY: "LAVARDIN",
      },
      {
        CITY: "LAVARDIN",
      },
      {
        CITY: "LAVAU",
      },
      {
        CITY: "LAVAUR",
      },
      {
        CITY: "LAVAUR",
      },
      {
        CITY: "LAVAURETTE",
      },
      {
        CITY: "LAVELANET",
      },
      {
        CITY: "LAVENTIE",
      },
      {
        CITY: "LAVERGNE",
      },
      {
        CITY: "LAVERNOSE-LACASSE",
      },
      {
        CITY: "LAVERSINE",
      },
      {
        CITY: "LAVERSINES",
      },
      {
        CITY: "LAVERUNE",
      },
      {
        CITY: "LAVIT",
      },
      {
        CITY: "LAVOURS",
      },
      {
        CITY: "LAVOUTE-CHILHAC",
      },
      {
        CITY: "LAXOU",
      },
      {
        CITY: "LAYE",
      },
      {
        CITY: "LAYRAC",
      },
      {
        CITY: "LAYRISSE",
      },
      {
        CITY: "LE BAIZIL",
      },
      {
        CITY: "LE BAN-SAINT-MARTIN",
      },
      {
        CITY: "LE BAR-SUR-LOUP",
      },
      {
        CITY: "LE BARBOUX",
      },
      {
        CITY: "LE BARCARES",
      },
      {
        CITY: "LE BARDON",
      },
      {
        CITY: "LE BARP",
      },
      {
        CITY: "LE BEAUSSET",
      },
      {
        CITY: "LE BERNARD",
      },
      {
        CITY: "LE BESSAT",
      },
      {
        CITY: "LE BEZ",
      },
      {
        CITY: "LE BIGNON",
      },
      {
        CITY: "LE BLANC",
      },
      {
        CITY: "LE BLANC-MESNIL",
      },
      {
        CITY: "LE BLEYMARD",
      },
      {
        CITY: "LE BOCASSE",
      },
      {
        CITY: "LE BOIS",
      },
      {
        CITY: "LE BOIS-PLAGE-EN-RE",
      },
      {
        CITY: "LE BONHOMME",
      },
      {
        CITY: "LE BONO",
      },
      {
        CITY: "LE BOSC",
      },
      {
        CITY: "LE BOSC-ROGER-EN-ROUMOIS",
      },
      {
        CITY: "LE BOUCHAGE",
      },
      {
        CITY: "LE BOULAY-MORIN",
      },
      {
        CITY: "LE BOUPERE",
      },
      {
        CITY: "LE BOURG",
      },
      {
        CITY: "LE BOURGET",
      },
      {
        CITY: "LE BOURGET",
      },
      {
        CITY: "LE BOURGNEUF-LA-FORET",
      },
      {
        CITY: "LE BOUSCAT",
      },
      {
        CITY: "LE BOUSQUET",
      },
      {
        CITY: "LE BREIL",
      },
      {
        CITY: "LE BREUIL",
      },
      {
        CITY: "LE BREUIL",
      },
      {
        CITY: "LE BREUIL",
      },
      {
        CITY: "LE BREUIL",
      },
      {
        CITY: "LE BREUIL-SUR-COUZE",
      },
      {
        CITY: "LE BROC",
      },
      {
        CITY: "LE BROC",
      },
      {
        CITY: "LE BRUGERON",
      },
      {
        CITY: "LE BUISSON-DE-CADOUIN",
      },
      {
        CITY: "LE CAILAR",
      },
      {
        CITY: "LE CANNET",
      },
      {
        CITY: "LE CASTELLET",
      },
      {
        CITY: "LE CASTERA",
      },
      {
        CITY: "LE CATEAU-CAMBRESIS",
      },
      {
        CITY: "LE CAYLAR",
      },
      {
        CITY: "LE CELLIER",
      },
      {
        CITY: "LE CENDRE",
      },
      {
        CITY: "LE CERGNE",
      },
      {
        CITY: "LE CHAMBON",
      },
      {
        CITY: "LE CHAMBON",
      },
      {
        CITY: "LE CHAMBON",
      },
      {
        CITY: "LE CHAMP-PRES-FROGES",
      },
      {
        CITY: "LE CHAMP-SAINT-PERE",
      },
      {
        CITY: "LE CHATEAU",
      },
      {
        CITY: "LE CHATEAU GAILLARD",
      },
      {
        CITY: "LE CHATEL",
      },
      {
        CITY: "LE CHATELARD",
      },
      {
        CITY: "LE CHATELET-EN-BRIE",
      },
      {
        CITY: "LE CHATELET-SUR-RETOURNE",
      },
      {
        CITY: "LE CHAYLARD",
      },
      {
        CITY: "LE CHESNAY",
      },
      {
        CITY: "LE CHESNE",
      },
      {
        CITY: "LE CHEYLAS",
      },
      {
        CITY: "LE COLLET-DE-DEZE",
      },
      {
        CITY: "LE COMTE",
      },
      {
        CITY: "LE CONQUET",
      },
      {
        CITY: "LE COTEAU",
      },
      {
        CITY: "LE COUDRAY",
      },
      {
        CITY: "LE COUDRAY-BAILLET",
      },
      {
        CITY: "LE COUDRAY-MONTCEAUX",
      },
      {
        CITY: "LE CRES",
      },
      {
        CITY: "LE CREST",
      },
      {
        CITY: "LE CREUSOT",
      },
      {
        CITY: "LE CROISIC",
      },
      {
        CITY: "LE CROTOY",
      },
      {
        CITY: "LE DELUGE",
      },
      {
        CITY: "LE DESCHAUX",
      },
      {
        CITY: "LE DEZERT",
      },
      {
        CITY: "LE DONJON",
      },
      {
        CITY: "LE DORAT",
      },
      {
        CITY: "LE DOULIEU",
      },
      {
        CITY: "LE FALGOUX",
      },
      {
        CITY: "LE FAOU",
      },
      {
        CITY: "LE FAOUET",
      },
      {
        CITY: "LE FAYET",
      },
      {
        CITY: "LE FENOUILLER",
      },
      {
        CITY: "LE FERRE",
      },
      {
        CITY: "LE FIDELAIRE",
      },
      {
        CITY: "LE FIEF-SAUVIN",
      },
      {
        CITY: "LE FOEIL",
      },
      {
        CITY: "LE FOLGOET",
      },
      {
        CITY: "LE FOSSAT",
      },
      {
        CITY: "LE FOUSSERET",
      },
      {
        CITY: "LE FRAYSSE",
      },
      {
        CITY: "LE FRESNE-CAMILLY",
      },
      {
        CITY: "LE FUILET",
      },
      {
        CITY: "LE GARRIC",
      },
      {
        CITY: "LE GAVRE",
      },
      {
        CITY: "LE GOND",
      },
      {
        CITY: "LE GRAND VILLAGE",
      },
      {
        CITY: "LE GRAND-BORNAND",
      },
      {
        CITY: "LE GRAND-PRESSIGNY",
      },
      {
        CITY: "LE GRAND-QUEVILLY",
      },
      {
        CITY: "LE GRATTERIS",
      },
      {
        CITY: "LE GRAU-DU-ROI",
      },
      {
        CITY: "LE GUA",
      },
      {
        CITY: "LE GUERNO",
      },
      {
        CITY: "LE GUILDO",
      },
      {
        CITY: "LE HAILLAN",
      },
      {
        CITY: "LE HAVRE",
      },
      {
        CITY: "LE HERIE-LA-VIEVILLE",
      },
      {
        CITY: "LE HOHWALD",
      },
      {
        CITY: "LE HOULME",
      },
      {
        CITY: "LE KREMLIN-BICETRE",
      },
      {
        CITY: "LE LANDIN",
      },
      {
        CITY: "LE LANDREAU",
      },
      {
        CITY: "LE LARDIN-SAINT-LAZARE",
      },
      {
        CITY: "LE LAVANDOU",
      },
      {
        CITY: "LE LEZ",
      },
      {
        CITY: "LE LONGERON",
      },
      {
        CITY: "LE LOROUX",
      },
      {
        CITY: "LE LOROUX-BOTTEREAU",
      },
      {
        CITY: "LE LOUROUX-BECONNAIS",
      },
      {
        CITY: "LE LUART",
      },
      {
        CITY: "LE LUC",
      },
      {
        CITY: "LE LUDE",
      },
      {
        CITY: "LE MANOIR",
      },
      {
        CITY: "LE MANS",
      },
      {
        CITY: "LE MARILLAIS",
      },
      {
        CITY: "LE MARTINET",
      },
      {
        CITY: "LE MAS",
      },
      {
        CITY: "LE MAY-SUR-EVRE",
      },
      {
        CITY: "LE MAYET",
      },
      {
        CITY: "LE MAZEAU",
      },
      {
        CITY: "LE MEE-SUR-SEINE",
      },
      {
        CITY: "LE MEIX-SAINT-EPOING",
      },
      {
        CITY: "LE MELE-SUR-SARTHE",
      },
      {
        CITY: "LE MENIL",
      },
      {
        CITY: "LE MERIOT",
      },
      {
        CITY: "LE MERLERAULT",
      },
      {
        CITY: "LE MESNIL-AMELOT",
      },
      {
        CITY: "LE MESNIL-EN-THELLE",
      },
      {
        CITY: "LE MESNIL-EN-VALLEE",
      },
      {
        CITY: "LE MESNIL-ESNARD",
      },
      {
        CITY: "LE MESNIL-FUGUET",
      },
      {
        CITY: "LE MESNIL-LE-ROI",
      },
      {
        CITY: "LE MESNIL-OZENNE",
      },
      {
        CITY: "LE MESNIL-VIGOT",
      },
      {
        CITY: "LE MESNIL-VILLEMAN",
      },
      {
        CITY: "LE MEUX",
      },
      {
        CITY: "LE MOLARD",
      },
      {
        CITY: "LE MOLAY",
      },
      {
        CITY: "LE MONASTIER-PIN-MORIES",
      },
      {
        CITY: "LE MONASTIER-SUR-GAZEILLE",
      },
      {
        CITY: "LE MONT-DORE",
      },
      {
        CITY: "LE MONTEIL",
      },
      {
        CITY: "LE MONTEL",
      },
      {
        CITY: "LE MUY",
      },
      {
        CITY: "LE NEUBOURG",
      },
      {
        CITY: "LE NOYER",
      },
      {
        CITY: "LE PALAIS",
      },
      {
        CITY: "LE PALLET",
      },
      {
        CITY: "LE PARC",
      },
      {
        CITY: "LE PARCQ",
      },
      {
        CITY: "LE PASSAGE",
      },
      {
        CITY: "LE PASSAGE",
      },
      {
        CITY: "LE PECQ",
      },
      {
        CITY: "LE PELLERIN",
      },
      {
        CITY: "LE PERRAY-EN-YVELINES",
      },
      {
        CITY: "LE PERREUX-SUR-MARNE",
      },
      {
        CITY: "LE PERRIER",
      },
      {
        CITY: "LE PETIT-QUEVILLY",
      },
      {
        CITY: "LE PIAN-SUR-GARONNE",
      },
      {
        CITY: "LE PIN",
      },
      {
        CITY: "LE PIN",
      },
      {
        CITY: "LE PIN",
      },
      {
        CITY: "LE PIN",
      },
      {
        CITY: "LE PIN-LA-GARENNE",
      },
      {
        CITY: "LE PIZOU",
      },
      {
        CITY: "LE PLAN-DE-LA-TOUR",
      },
      {
        CITY: "LE PLANOIS",
      },
      {
        CITY: "LE PLESSIER-SUR-BULLES",
      },
      {
        CITY: "LE PLESSIS",
      },
      {
        CITY: "LE PLESSIS-BELLEVILLE",
      },
      {
        CITY: "LE PLESSIS-BOUCHARD",
      },
      {
        CITY: "LE PLESSIS-GRAMMOIRE",
      },
      {
        CITY: "LE PLESSIS-HEBERT",
      },
      {
        CITY: "LE PLESSIS-PATE",
      },
      {
        CITY: "LE PLESSIS-ROBINSON",
      },
      {
        CITY: "LE PLESSIS-TREVISE",
      },
      {
        CITY: "LE POET",
      },
      {
        CITY: "LE POET-LAVAL",
      },
      {
        CITY: "LE POINCONNET",
      },
      {
        CITY: "LE POIRE-SUR-VELLUIRE",
      },
      {
        CITY: "LE PONT",
      },
      {
        CITY: "LE PONT",
      },
      {
        CITY: "LE PONT-DE-CLAIX",
      },
      {
        CITY: "LE PONTET",
      },
      {
        CITY: "LE PORGE",
      },
      {
        CITY: "LE PORT",
      },
      {
        CITY: "LE PORT",
      },
      {
        CITY: "LE PORT-MARLY",
      },
      {
        CITY: "LE PORTEL",
      },
      {
        CITY: "LE POUGET",
      },
      {
        CITY: "LE POULIGUEN",
      },
      {
        CITY: "LE POUT",
      },
      {
        CITY: "LE POUZIN",
      },
      {
        CITY: "LE PRADET",
      },
      {
        CITY: "LE PRE-SAINT-GERVAIS",
      },
      {
        CITY: "LE PUY",
      },
      {
        CITY: "LE PUY-EN-VELAY",
      },
      {
        CITY: "LE PUY-SAINTE-REPARADE",
      },
      {
        CITY: "LE QUESNEL",
      },
      {
        CITY: "LE QUESNOY",
      },
      {
        CITY: "LE RAINCY",
      },
      {
        CITY: "LE RELECQ-KERHUON",
      },
      {
        CITY: "LE RENOUARD",
      },
      {
        CITY: "LE REVEST-LES-EAUX",
      },
      {
        CITY: "LE RHEU",
      },
      {
        CITY: "LE RONCENAY-AUTHENAY",
      },
      {
        CITY: "LE ROUGET",
      },
      {
        CITY: "LE ROURET",
      },
      {
        CITY: "LE ROVE",
      },
      {
        CITY: "LE SACQ",
      },
      {
        CITY: "LE SAP",
      },
      {
        CITY: "LE SEGUR",
      },
      {
        CITY: "LE SOLER",
      },
      {
        CITY: "LE SOURN",
      },
      {
        CITY: "LE SYNDICAT",
      },
      {
        CITY: "LE TAILLAN-MEDOC",
      },
      {
        CITY: "LE TALLUD",
      },
      {
        CITY: "LE TEICH",
      },
      {
        CITY: "LE TEIL",
      },
      {
        CITY: "LE TEILLEUL",
      },
      {
        CITY: "LE TEMPLE-DE-BRETAGNE",
      },
      {
        CITY: "LE THEIL",
      },
      {
        CITY: "LE THEIL-DE-BRETAGNE",
      },
      {
        CITY: "LE THIEULIN",
      },
      {
        CITY: "LE THILLAY",
      },
      {
        CITY: "LE THILLOT",
      },
      {
        CITY: "LE THOLONET",
      },
      {
        CITY: "LE THOLY",
      },
      {
        CITY: "LE THOR",
      },
      {
        CITY: "LE THOU",
      },
      {
        CITY: "LE THOUREIL",
      },
      {
        CITY: "LE THUIT-SIGNOL",
      },
      {
        CITY: "LE THUIT-SIMER",
      },
      {
        CITY: "LE TIGNET",
      },
      {
        CITY: "LE TORQUESNE",
      },
      {
        CITY: "LE TOUQUET-PARIS-PLAGE",
      },
      {
        CITY: "LE TOURNE",
      },
      {
        CITY: "LE TOURNEUR",
      },
      {
        CITY: "LE TOUVET",
      },
      {
        CITY: "LE TRAIT",
      },
      {
        CITY: "LE TREPORT",
      },
      {
        CITY: "LE TREVOUX",
      },
      {
        CITY: "LE TRONCHET",
      },
      {
        CITY: "LE TRONQUAY",
      },
      {
        CITY: "LE VAL",
      },
      {
        CITY: "LE VAL-DAJOL",
      },
      {
        CITY: "LE VAUDIOUX",
      },
      {
        CITY: "LE VAUDREUIL",
      },
      {
        CITY: "LE VAUMAIN",
      },
      {
        CITY: "LE VERGER",
      },
      {
        CITY: "LE VERNET",
      },
      {
        CITY: "LE VERNET",
      },
      {
        CITY: "LE VERSOUD",
      },
      {
        CITY: "LE VESINET",
      },
      {
        CITY: "LE VIEUX",
      },
      {
        CITY: "LE VIEUX BOURG",
      },
      {
        CITY: "LE VIEUX BOURG",
      },
      {
        CITY: "LE VIGAN",
      },
      {
        CITY: "LE VIGEAN",
      },
      {
        CITY: "LE VIGEANT",
      },
      {
        CITY: "LE VIGEN",
      },
      {
        CITY: "LE VIVIER-SUR-MER",
      },
      {
        CITY: "LE VOIDE",
      },
      {
        CITY: "LEALVILLERS",
      },
      {
        CITY: "LEAZ",
      },
      {
        CITY: "LECELLES",
      },
      {
        CITY: "LECHATELET",
      },
      {
        CITY: "LECHELLE",
      },
      {
        CITY: "LECOUSSE",
      },
      {
        CITY: "LECTOURE",
      },
      {
        CITY: "LEDENON",
      },
      {
        CITY: "LEDERZEELE",
      },
      {
        CITY: "LEERS",
      },
      {
        CITY: "LEFFINCOURT",
      },
      {
        CITY: "LEFFRINCKOUCKE",
      },
      {
        CITY: "LEFOREST",
      },
      {
        CITY: "LEGE",
      },
      {
        CITY: "LEGE-CAP-FERRET",
      },
      {
        CITY: "LEGUEVIN",
      },
      {
        CITY: "LEHON",
      },
      {
        CITY: "LEIGNEUX",
      },
      {
        CITY: "LEINTREY",
      },
      {
        CITY: "LELLING",
      },
      {
        CITY: "LEMAINVILLE",
      },
      {
        CITY: "LEMBACH",
      },
      {
        CITY: "LEMBERG",
      },
      {
        CITY: "LEME",
      },
      {
        CITY: "LEMMES",
      },
      {
        CITY: "LEMPDES",
      },
      {
        CITY: "LEMPDES-SUR-ALLAGNON",
      },
      {
        CITY: "LEMPS",
      },
      {
        CITY: "LENCLOITRE",
      },
      {
        CITY: "LENNON",
      },
      {
        CITY: "LENONCOURT",
      },
      {
        CITY: "LENS",
      },
      {
        CITY: "LENS-LESTANG",
      },
      {
        CITY: "LENT",
      },
      {
        CITY: "LENTIGNY",
      },
      {
        CITY: "LENTILLY",
      },
      {
        CITY: "LENTIOL",
      },
      {
        CITY: "LEOJAC",
      },
      {
        CITY: "LEON",
      },
      {
        CITY: "LEOUVILLE",
      },
      {
        CITY: "LEPIN-LE-LAC",
      },
      {
        CITY: "LEPUIX",
      },
      {
        CITY: "LEPUY",
      },
      {
        CITY: "LERE",
      },
      {
        CITY: "LERY",
      },
      {
        CITY: "LES ABRETS",
      },
      {
        CITY: "LES AGEUX",
      },
      {
        CITY: "LES ALLEUDS",
      },
      {
        CITY: "LES ALLIES",
      },
      {
        CITY: "LES ALLUES",
      },
      {
        CITY: "LES ANCIZES-COMPS",
      },
      {
        CITY: "LES ANDELYS",
      },
      {
        CITY: "LES ANGLES",
      },
      {
        CITY: "LES ARCS",
      },
      {
        CITY: "LES ARDILLATS",
      },
      {
        CITY: "LES ARTIGUES-DE-LUSSAC",
      },
      {
        CITY: "LES ASSIONS",
      },
      {
        CITY: "LES AUBIERS",
      },
      {
        CITY: "LES AVANCHERS-VALMOREL",
      },
      {
        CITY: "LES AVENIERES",
      },
      {
        CITY: "LES BAINS",
      },
      {
        CITY: "LES BAUX DE PROVENCE",
      },
      {
        CITY: "LES BAUX-DE-BRETEUIL",
      },
      {
        CITY: "LES BILLANGES",
      },
      {
        CITY: "LES BORDES",
      },
      {
        CITY: "LES BORDES",
      },
      {
        CITY: "LES BORDES-SUR-LEZ",
      },
      {
        CITY: "LES BREVIAIRES",
      },
      {
        CITY: "LES BROUZILS",
      },
      {
        CITY: "LES CABANES",
      },
      {
        CITY: "LES CABANNES",
      },
      {
        CITY: "LES CARS",
      },
      {
        CITY: "LES CHAMPS",
      },
      {
        CITY: "LES CHAPELLES",
      },
      {
        CITY: "LES CHARMONTOIS",
      },
      {
        CITY: "LES CHERES",
      },
      {
        CITY: "LES CLAYES-SOUS-BOIS",
      },
      {
        CITY: "LES CLOUZEAUX",
      },
      {
        CITY: "LES COMBES",
      },
      {
        CITY: "LES DEUX-FAYS",
      },
      {
        CITY: "LES ECHELLES",
      },
      {
        CITY: "LES ECRENNES",
      },
      {
        CITY: "LES EGLISOTTES-ET-CHALAURES",
      },
      {
        CITY: "LES EPARRES",
      },
      {
        CITY: "LES EPESSES",
      },
      {
        CITY: "LES ESSARDS",
      },
      {
        CITY: "LES ESSARDS-TAIGNEVAUX",
      },
      {
        CITY: "LES ESSARTS",
      },
      {
        CITY: "LES ESSARTS",
      },
      {
        CITY: "LES ESSARTS",
      },
      {
        CITY: "LES ETANGS",
      },
      {
        CITY: "LES EYZIES-DE-TAYAC-SIREUIL",
      },
      {
        CITY: "LES FINS",
      },
      {
        CITY: "LES FONTAINES",
      },
      {
        CITY: "LES FONTS",
      },
      {
        CITY: "LES FORGES",
      },
      {
        CITY: "LES FORGES",
      },
      {
        CITY: "LES FOURGS",
      },
      {
        CITY: "LES GETS",
      },
      {
        CITY: "LES GOURS",
      },
      {
        CITY: "LES GRANDS-CHEZEAUX",
      },
      {
        CITY: "LES GRANGES",
      },
      {
        CITY: "LES GRANGES",
      },
      {
        CITY: "LES GRAS",
      },
      {
        CITY: "LES HALLES",
      },
      {
        CITY: "LES HAYS",
      },
      {
        CITY: "LES HERBIERS",
      },
      {
        CITY: "LES HOGUES",
      },
      {
        CITY: "LES HOPITAUX-VIEUX",
      },
      {
        CITY: "LES HOTELLERIES",
      },
      {
        CITY: "LES HOUCHES",
      },
      {
        CITY: "LES JUMEAUX",
      },
      {
        CITY: "LES LANDES",
      },
      {
        CITY: "LES LANDES-GENUSSON",
      },
      {
        CITY: "LES LAUMES",
      },
      {
        CITY: "LES LILAS",
      },
      {
        CITY: "LES LOGES",
      },
      {
        CITY: "LES LOGES",
      },
      {
        CITY: "LES LOGES-EN-JOSAS",
      },
      {
        CITY: "LES LUCS-SUR-BOULOGNE",
      },
      {
        CITY: "LES MAGES",
      },
      {
        CITY: "LES MARCHES",
      },
      {
        CITY: "LES MARTRES",
      },
      {
        CITY: "LES MATHES",
      },
      {
        CITY: "LES MAURES",
      },
      {
        CITY: "LES MEES",
      },
      {
        CITY: "LES MESNEUX",
      },
      {
        CITY: "LES MESNULS",
      },
      {
        CITY: "LES MILLES",
      },
      {
        CITY: "LES MOITIERS-EN-BAUPTOIS",
      },
      {
        CITY: "LES MOLIERES",
      },
      {
        CITY: "LES MONTILS",
      },
      {
        CITY: "LES MUREAUX",
      },
      {
        CITY: "LES NOUILLERS",
      },
      {
        CITY: "LES OLLIERES-SUR-EYRIEUX",
      },
      {
        CITY: "LES OLMES",
      },
      {
        CITY: "LES ORMES",
      },
      {
        CITY: "LES ORMES",
      },
      {
        CITY: "LES ORMES-SUR-VOULZIE",
      },
      {
        CITY: "LES PALAIS",
      },
      {
        CITY: "LES PAVILLONS-SOUS-BOIS",
      },
      {
        CITY: "LES PEINTURES",
      },
      {
        CITY: "LES PENNES-MIRABEAU",
      },
      {
        CITY: "LES PETITES LOGES",
      },
      {
        CITY: "LES PIEUX",
      },
      {
        CITY: "LES PINEAUX",
      },
      {
        CITY: "LES PINS",
      },
      {
        CITY: "LES PINS",
      },
      {
        CITY: "LES PINS",
      },
      {
        CITY: "LES PLACES",
      },
      {
        CITY: "LES RIVES",
      },
      {
        CITY: "LES RIVIERES-HENRUEL",
      },
      {
        CITY: "LES ROCHES",
      },
      {
        CITY: "LES ROSIERS",
      },
      {
        CITY: "LES ROSIERS-SUR-LOIRE",
      },
      {
        CITY: "LES ROUSSES",
      },
      {
        CITY: "LES SABLES",
      },
      {
        CITY: "LES SAUVAGES",
      },
      {
        CITY: "LES SORINIERES",
      },
      {
        CITY: "LES TAILLADES",
      },
      {
        CITY: "LES THILLIERS-EN-VEXIN",
      },
      {
        CITY: "LES TOUCHES",
      },
      {
        CITY: "LES VALLEES",
      },
      {
        CITY: "LES VANS",
      },
      {
        CITY: "LES VARENNES",
      },
      {
        CITY: "LES VIGNEAUX",
      },
      {
        CITY: "LES VILLEDIEU",
      },
      {
        CITY: "LESCAR",
      },
      {
        CITY: "LESCHERAINES",
      },
      {
        CITY: "LESCHEROLLES",
      },
      {
        CITY: "LESCHES",
      },
      {
        CITY: "LESCONIL",
      },
      {
        CITY: "LESCOUT",
      },
      {
        CITY: "LESCURE",
      },
      {
        CITY: "LESDAIN",
      },
      {
        CITY: "LESIGNY",
      },
      {
        CITY: "LESNEVEN",
      },
      {
        CITY: "LESPESSES",
      },
      {
        CITY: "LESPIELLE",
      },
      {
        CITY: "LESPIGNAN",
      },
      {
        CITY: "LESPINASSE",
      },
      {
        CITY: "LESQUIN",
      },
      {
        CITY: "LESSAY",
      },
      {
        CITY: "LESSE",
      },
      {
        CITY: "LESSY",
      },
      {
        CITY: "LESTELLE-BETHARRAM",
      },
      {
        CITY: "LESTIOU",
      },
      {
        CITY: "LESTREM",
      },
      {
        CITY: "LETANNE",
      },
      {
        CITY: "LETRICOURT",
      },
      {
        CITY: "LEUC",
      },
      {
        CITY: "LEUCATE",
      },
      {
        CITY: "LEUDEVILLE",
      },
      {
        CITY: "LEUGNY",
      },
      {
        CITY: "LEUILLY-SOUS-COUCY",
      },
      {
        CITY: "LEUVILLE-SUR-ORGE",
      },
      {
        CITY: "LEUVRIGNY",
      },
      {
        CITY: "LEVAINVILLE",
      },
      {
        CITY: "LEVAL",
      },
      {
        CITY: "LEVAL",
      },
      {
        CITY: "LEVALLOIS-PERRET",
      },
      {
        CITY: "LEVENS",
      },
      {
        CITY: "LEVERNOIS",
      },
      {
        CITY: "LEVES",
      },
      {
        CITY: "LEVET",
      },
      {
        CITY: "LEVIE",
      },
      {
        CITY: "LEVIER",
      },
      {
        CITY: "LEVIGNACQ",
      },
      {
        CITY: "LEVIS",
      },
      {
        CITY: "LEVROUX",
      },
      {
        CITY: "LEWARDE",
      },
      {
        CITY: "LEXY",
      },
      {
        CITY: "LEYME",
      },
      {
        CITY: "LEYR",
      },
      {
        CITY: "LEYRIEU",
      },
      {
        CITY: "LEYSSE",
      },
      {
        CITY: "LEZAN",
      },
      {
        CITY: "LEZARDRIEUX",
      },
      {
        CITY: "LEZAT-SUR-LEZE",
      },
      {
        CITY: "LEZAY",
      },
      {
        CITY: "LEZENNES",
      },
      {
        CITY: "LEZIGNAN-CORBIERES",
      },
      {
        CITY: "LEZIGNE",
      },
      {
        CITY: "LEZIGNEUX",
      },
      {
        CITY: "LEZOUX",
      },
      {
        CITY: "LHERAULE",
      },
      {
        CITY: "LHERM",
      },
      {
        CITY: "LHOMMAIZE",
      },
      {
        CITY: "LHUIS",
      },
      {
        CITY: "LIANCOURT",
      },
      {
        CITY: "LIANCOURT-SAINT-PIERRE",
      },
      {
        CITY: "LIART",
      },
      {
        CITY: "LIBERCOURT",
      },
      {
        CITY: "LIBOURNE",
      },
      {
        CITY: "LICQUES",
      },
      {
        CITY: "LIEPVRE",
      },
      {
        CITY: "LIERAMONT",
      },
      {
        CITY: "LIERGUES",
      },
      {
        CITY: "LIERNAIS",
      },
      {
        CITY: "LIEU-SAINT-AMAND",
      },
      {
        CITY: "LIEURAN-LES-BEZIERS",
      },
      {
        CITY: "LIEUREY",
      },
      {
        CITY: "LIEURON",
      },
      {
        CITY: "LIEUSAINT",
      },
      {
        CITY: "LIEUVILLERS",
      },
      {
        CITY: "LIFFOL-LE-GRAND",
      },
      {
        CITY: "LIFFRE",
      },
      {
        CITY: "LIGESCOURT",
      },
      {
        CITY: "LIGNAN-SUR-ORB",
      },
      {
        CITY: "LIGNE",
      },
      {
        CITY: "LIGNE",
      },
      {
        CITY: "LIGNEROLLES",
      },
      {
        CITY: "LIGNEROLLES",
      },
      {
        CITY: "LIGNIERES-CHATELAIN",
      },
      {
        CITY: "LIGNIERES-LA-CARELLE",
      },
      {
        CITY: "LIGNOL",
      },
      {
        CITY: "LIGNY-EN-BARROIS",
      },
      {
        CITY: "LIGNY-EN-CAMBRESIS",
      },
      {
        CITY: "LIGNY-LE-CHATEL",
      },
      {
        CITY: "LIGNY-LE-RIBAULT",
      },
      {
        CITY: "LIGSDORF",
      },
      {
        CITY: "LIGUEIL",
      },
      {
        CITY: "LIGUGE",
      },
      {
        CITY: "LIHONS",
      },
      {
        CITY: "LIHUS",
      },
      {
        CITY: "LILLE",
      },
      {
        CITY: "LILLEBONNE",
      },
      {
        CITY: "LILLERS",
      },
      {
        CITY: "LIMAS",
      },
      {
        CITY: "LIMAY",
      },
      {
        CITY: "LIMEIL-BREVANNES",
      },
      {
        CITY: "LIMERAY",
      },
      {
        CITY: "LIMERSHEIM",
      },
      {
        CITY: "LIMERZEL",
      },
      {
        CITY: "LIMESY",
      },
      {
        CITY: "LIMETZ-VILLEZ",
      },
      {
        CITY: "LIMOGES",
      },
      {
        CITY: "LIMOGES-FOURCHES",
      },
      {
        CITY: "LIMOGNE-EN-QUERCY",
      },
      {
        CITY: "LIMONEST",
      },
      {
        CITY: "LIMONY",
      },
      {
        CITY: "LIMOURS",
      },
      {
        CITY: "LIMOUX",
      },
      {
        CITY: "LINARS",
      },
      {
        CITY: "LINAS",
      },
      {
        CITY: "LINCEL",
      },
      {
        CITY: "LINGOLSHEIM",
      },
      {
        CITY: "LINGREVILLE",
      },
      {
        CITY: "LINSELLES",
      },
      {
        CITY: "LINXE",
      },
      {
        CITY: "LIOCOURT",
      },
      {
        CITY: "LION-EN-SULLIAS",
      },
      {
        CITY: "LION-SUR-MER",
      },
      {
        CITY: "LIOUC",
      },
      {
        CITY: "LIPSHEIM",
      },
      {
        CITY: "LIRE",
      },
      {
        CITY: "LISIEUX",
      },
      {
        CITY: "LISLE",
      },
      {
        CITY: "LISLE-DESPAGNAC",
      },
      {
        CITY: "LISLE-SUR-LE-DOUBS",
      },
      {
        CITY: "LISLE-SUR-TARN",
      },
      {
        CITY: "LISORS",
      },
      {
        CITY: "LISSES",
      },
      {
        CITY: "LISSIEU",
      },
      {
        CITY: "LISSY",
      },
      {
        CITY: "LIT-ET-MIXE",
      },
      {
        CITY: "LIVAROT",
      },
      {
        CITY: "LIVERDUN",
      },
      {
        CITY: "LIVERDY-EN-BRIE",
      },
      {
        CITY: "LIVERNON",
      },
      {
        CITY: "LIVET-ET-GAVET",
      },
      {
        CITY: "LIVET-SUR-AUTHOU",
      },
      {
        CITY: "LIVINHAC-LE-HAUT",
      },
      {
        CITY: "LIVRE-SUR-CHANGEON",
      },
      {
        CITY: "LIVRON",
      },
      {
        CITY: "LIVRON-SUR-DROME",
      },
      {
        CITY: "LIVRY-GARGAN",
      },
      {
        CITY: "LIVRY-SUR-SEINE",
      },
      {
        CITY: "LIXHAUSEN",
      },
      {
        CITY: "LIXHEIM",
      },
      {
        CITY: "LIZAC",
      },
      {
        CITY: "LIZY-SUR-OURCQ",
      },
      {
        CITY: "LLUPIA",
      },
      {
        CITY: "LOCHE",
      },
      {
        CITY: "LOCHE-SUR-INDROIS",
      },
      {
        CITY: "LOCMARIA-GRAND-CHAMP",
      },
      {
        CITY: "LOCMARIA-PLOUZANE",
      },
      {
        CITY: "LOCMARIAQUER",
      },
      {
        CITY: "LOCMINE",
      },
      {
        CITY: "LOCMIQUELIC",
      },
      {
        CITY: "LOCOAL-MENDON",
      },
      {
        CITY: "LOCON",
      },
      {
        CITY: "LOCQUEMEAU",
      },
      {
        CITY: "LOCQUIREC",
      },
      {
        CITY: "LOCTUDY",
      },
      {
        CITY: "LOGE-FOUGEREUSE",
      },
      {
        CITY: "LOGELBACH",
      },
      {
        CITY: "LOGELHEIM",
      },
      {
        CITY: "LOGNES",
      },
      {
        CITY: "LOGONNA-DAOULAS",
      },
      {
        CITY: "LOGONNA-QUIMERCH",
      },
      {
        CITY: "LOGRIAN-FLORIAN",
      },
      {
        CITY: "LOHR",
      },
      {
        CITY: "LOIRE",
      },
      {
        CITY: "LOIRE-LES-MARAIS",
      },
      {
        CITY: "LOIRE-SUR-RHONE",
      },
      {
        CITY: "LOIRON",
      },
      {
        CITY: "LOISIN",
      },
      {
        CITY: "LOISON-SOUS-LENS",
      },
      {
        CITY: "LOISY",
      },
      {
        CITY: "LOISY-SUR-MARNE",
      },
      {
        CITY: "LOIX",
      },
      {
        CITY: "LOKMARIA",
      },
      {
        CITY: "LOMBERS",
      },
      {
        CITY: "LOMBEZ",
      },
      {
        CITY: "LOMBRON",
      },
      {
        CITY: "LOMME",
      },
      {
        CITY: "LOMMERANGE",
      },
      {
        CITY: "LOMPRET",
      },
      {
        CITY: "LONDIGNY",
      },
      {
        CITY: "LONDINIERES",
      },
      {
        CITY: "LONGAGES",
      },
      {
        CITY: "LONGCHAMP",
      },
      {
        CITY: "LONGCHAMP-SOUS-CHATENOIS",
      },
      {
        CITY: "LONGEAULT",
      },
      {
        CITY: "LONGECOURT-EN-PLAINE",
      },
      {
        CITY: "LONGES",
      },
      {
        CITY: "LONGESSAIGNE",
      },
      {
        CITY: "LONGEVELLE-SUR-DOUBS",
      },
      {
        CITY: "LONGEVES",
      },
      {
        CITY: "LONGEVILLE-EN-BARROIS",
      },
      {
        CITY: "LONGEVILLE-LES-METZ",
      },
      {
        CITY: "LONGEVILLE-LES-SAINT-AVOLD",
      },
      {
        CITY: "LONGEVILLE-SUR-MER",
      },
      {
        CITY: "LONGFOSSE",
      },
      {
        CITY: "LONGJUMEAU",
      },
      {
        CITY: "LONGLAVILLE",
      },
      {
        CITY: "LONGNES",
      },
      {
        CITY: "LONGPONT-SUR-ORGE",
      },
      {
        CITY: "LONGPRE-LES-CORPS-SAINTS",
      },
      {
        CITY: "LONGUE-JUMELLES",
      },
      {
        CITY: "LONGUEAU",
      },
      {
        CITY: "LONGUEIL-ANNEL",
      },
      {
        CITY: "LONGUEIL-SAINTE-MARIE",
      },
      {
        CITY: "LONGUENESSE",
      },
      {
        CITY: "LONGUEVILLE",
      },
      {
        CITY: "LONGUEVILLE-SUR-SCIE",
      },
      {
        CITY: "LONGUYON",
      },
      {
        CITY: "LONGVIC",
      },
      {
        CITY: "LONGWY",
      },
      {
        CITY: "LONS",
      },
      {
        CITY: "LONS-LE-SAUNIER",
      },
      {
        CITY: "LOON-PLAGE",
      },
      {
        CITY: "LOOS",
      },
      {
        CITY: "LOOS-EN-GOHELLE",
      },
      {
        CITY: "LOPERHET",
      },
      {
        CITY: "LORCIERES",
      },
      {
        CITY: "LORETTE",
      },
      {
        CITY: "LOREY",
      },
      {
        CITY: "LORGIES",
      },
      {
        CITY: "LORGUES",
      },
      {
        CITY: "LORIENT",
      },
      {
        CITY: "LORIGNAC",
      },
      {
        CITY: "LORIOL-DU-COMTAT",
      },
      {
        CITY: "LORIOL-SUR-DROME",
      },
      {
        CITY: "LORLANGES",
      },
      {
        CITY: "LORMAISON",
      },
      {
        CITY: "LORMAYE",
      },
      {
        CITY: "LORMONT",
      },
      {
        CITY: "LORQUIN",
      },
      {
        CITY: "LORREZ-LE-BOCAGE-PREAUX",
      },
      {
        CITY: "LORRIS",
      },
      {
        CITY: "LORRY-MARDIGNY",
      },
      {
        CITY: "LOS MASOS",
      },
      {
        CITY: "LOSNE",
      },
      {
        CITY: "LOUAILLES",
      },
      {
        CITY: "LOUANNEC",
      },
      {
        CITY: "LOUARGAT",
      },
      {
        CITY: "LOUATRE",
      },
      {
        CITY: "LOUBARESSE",
      },
      {
        CITY: "LOUBES-BERNAC",
      },
      {
        CITY: "LOUBEYRAT",
      },
      {
        CITY: "LOUBIENG",
      },
      {
        CITY: "LOUBIGNE",
      },
      {
        CITY: "LOUBILLE",
      },
      {
        CITY: "LOUDEAC",
      },
      {
        CITY: "LOUDES",
      },
      {
        CITY: "LOUDREFING",
      },
      {
        CITY: "LOUDUN",
      },
      {
        CITY: "LOUE",
      },
      {
        CITY: "LOUER",
      },
      {
        CITY: "LOUGRES",
      },
      {
        CITY: "LOUHANS",
      },
      {
        CITY: "LOUPERSHOUSE",
      },
      {
        CITY: "LOUPES",
      },
      {
        CITY: "LOUPIAC-DE-LA-REOLE",
      },
      {
        CITY: "LOUPIAN",
      },
      {
        CITY: "LOUPLANDE",
      },
      {
        CITY: "LOURCHES",
      },
      {
        CITY: "LOURDES",
      },
      {
        CITY: "LOURENTIES",
      },
      {
        CITY: "LOURES-BAROUSSE",
      },
      {
        CITY: "LOURESSE-ROCHEMENIER",
      },
      {
        CITY: "LOURMARIN",
      },
      {
        CITY: "LOURTIES-MONBRUN",
      },
      {
        CITY: "LOURY",
      },
      {
        CITY: "LOUVECIENNES",
      },
      {
        CITY: "LOUVEMONT",
      },
      {
        CITY: "LOUVERNE",
      },
      {
        CITY: "LOUVIE-JUZON",
      },
      {
        CITY: "LOUVIERS",
      },
      {
        CITY: "LOUVIGNE-DE-BAIS",
      },
      {
        CITY: "LOUVIGNIES-QUESNOY",
      },
      {
        CITY: "LOUVIGNY",
      },
      {
        CITY: "LOUVIGNY",
      },
      {
        CITY: "LOUVIGNY",
      },
      {
        CITY: "LOUVIL",
      },
      {
        CITY: "LOUVRECHY",
      },
      {
        CITY: "LOUVRES",
      },
      {
        CITY: "LOUVROIL",
      },
      {
        CITY: "LOUZOUER",
      },
      {
        CITY: "LOUZY",
      },
      {
        CITY: "LOVAGNY",
      },
      {
        CITY: "LOYAT",
      },
      {
        CITY: "LOYES",
      },
      {
        CITY: "LOYETTES",
      },
      {
        CITY: "LOZANNE",
      },
      {
        CITY: "LOZINGHEM",
      },
      {
        CITY: "LOZON",
      },
      {
        CITY: "LUANT",
      },
      {
        CITY: "LUBERSAC",
      },
      {
        CITY: "LUC",
      },
      {
        CITY: "LUC-SUR-MER",
      },
      {
        CITY: "LUC-SUR-ORBIEU",
      },
      {
        CITY: "LUCCIANA",
      },
      {
        CITY: "LUCE",
      },
      {
        CITY: "LUCE",
      },
      {
        CITY: "LUCEAU",
      },
      {
        CITY: "LUCENAY",
      },
      {
        CITY: "LUCEY",
      },
      {
        CITY: "LUCHY",
      },
      {
        CITY: "LUCON",
      },
      {
        CITY: "LUCQ-DE-BEARN",
      },
      {
        CITY: "LUCY-SUR-CURE",
      },
      {
        CITY: "LUDON-MEDOC",
      },
      {
        CITY: "LUDRES",
      },
      {
        CITY: "LUEMSCHWILLER",
      },
      {
        CITY: "LUGNY-CHAMPAGNE",
      },
      {
        CITY: "LUGNY-LES-CHAROLLES",
      },
      {
        CITY: "LUGRIN",
      },
      {
        CITY: "LUISANT",
      },
      {
        CITY: "LUITRE",
      },
      {
        CITY: "LULLIN",
      },
      {
        CITY: "LULLY",
      },
      {
        CITY: "LUMBIN",
      },
      {
        CITY: "LUMBRES",
      },
      {
        CITY: "LUMEAU",
      },
      {
        CITY: "LUMIO",
      },
      {
        CITY: "LUNEL",
      },
      {
        CITY: "LUNEL-VIEL",
      },
      {
        CITY: "LUNERAY",
      },
      {
        CITY: "LUPE",
      },
      {
        CITY: "LUPPY",
      },
      {
        CITY: "LUPSTEIN",
      },
      {
        CITY: "LUQUET",
      },
      {
        CITY: "LURAIS",
      },
      {
        CITY: "LURAY",
      },
      {
        CITY: "LURCY",
      },
      {
        CITY: "LURCY-LEVIS",
      },
      {
        CITY: "LURE",
      },
      {
        CITY: "LURY-SUR-ARNON",
      },
      {
        CITY: "LUS-LA-CROIX-HAUTE",
      },
      {
        CITY: "LUSANGER",
      },
      {
        CITY: "LUSIGNAN",
      },
      {
        CITY: "LUSIGNAN-GRAND",
      },
      {
        CITY: "LUSIGNY",
      },
      {
        CITY: "LUSIGNY-SUR-BARSE",
      },
      {
        CITY: "LUSSAC",
      },
      {
        CITY: "LUSSAC-LES-CHATEAUX",
      },
      {
        CITY: "LUSSAC-LES-EGLISES",
      },
      {
        CITY: "LUSSANT",
      },
      {
        CITY: "LUSSAS",
      },
      {
        CITY: "LUSSAT",
      },
      {
        CITY: "LUTTANGE",
      },
      {
        CITY: "LUTTER",
      },
      {
        CITY: "LUTTERBACH",
      },
      {
        CITY: "LUTZ-EN-DUNOIS",
      },
      {
        CITY: "LUX",
      },
      {
        CITY: "LUX",
      },
      {
        CITY: "LUXE",
      },
      {
        CITY: "LUXEUIL-LES-BAINS",
      },
      {
        CITY: "LUXEY",
      },
      {
        CITY: "LUYNES",
      },
      {
        CITY: "LUYNES",
      },
      {
        CITY: "LUZ-SAINT-SAUVEUR",
      },
      {
        CITY: "LUZARCHES",
      },
      {
        CITY: "LUZE",
      },
      {
        CITY: "LUZILLE",
      },
      {
        CITY: "LUZINAY",
      },
      {
        CITY: "LUZY",
      },
      {
        CITY: "LYAUD",
      },
      {
        CITY: "LYNDE",
      },
      {
        CITY: "LYON",
      },
      {
        CITY: "MABLY",
      },
      {
        CITY: "MACAU",
      },
      {
        CITY: "MACHE",
      },
      {
        CITY: "MACHECOUL",
      },
      {
        CITY: "MACHEMONT",
      },
      {
        CITY: "MACHEREN",
      },
      {
        CITY: "MACHEZAL",
      },
      {
        CITY: "MACLAS",
      },
      {
        CITY: "MACOT-LA-PLAGNE",
      },
      {
        CITY: "MADIRAC",
      },
      {
        CITY: "MADRE",
      },
      {
        CITY: "MADRIAT",
      },
      {
        CITY: "MAEL-CARHAIX",
      },
      {
        CITY: "MAENNOLSHEIM",
      },
      {
        CITY: "MAFFLIERS",
      },
      {
        CITY: "MAGAGNOSC",
      },
      {
        CITY: "MAGALAS",
      },
      {
        CITY: "MAGENTA",
      },
      {
        CITY: "MAGESCQ",
      },
      {
        CITY: "MAGLAND",
      },
      {
        CITY: "MAGNAN",
      },
      {
        CITY: "MAGNANVILLE",
      },
      {
        CITY: "MAGNE",
      },
      {
        CITY: "MAGNEUX-HAUTE-RIVE",
      },
      {
        CITY: "MAGNIERES",
      },
      {
        CITY: "MAGNY",
      },
      {
        CITY: "MAGNY-COURS",
      },
      {
        CITY: "MAGNY-EN-VEXIN",
      },
      {
        CITY: "MAGNY-LE-DESERT",
      },
      {
        CITY: "MAGNY-LE-HONGRE",
      },
      {
        CITY: "MAGNY-LES-HAMEAUX",
      },
      {
        CITY: "MAGNY-LES-VILLERS",
      },
      {
        CITY: "MAGNY-SUR-TILLE",
      },
      {
        CITY: "MAGRIE",
      },
      {
        CITY: "MAGSTATT-LE-HAUT",
      },
      {
        CITY: "MAICHE",
      },
      {
        CITY: "MAIDIERES",
      },
      {
        CITY: "MAIGNE",
      },
      {
        CITY: "MAIGNELAY-MONTIGNY",
      },
      {
        CITY: "MAILLANE",
      },
      {
        CITY: "MAILLE",
      },
      {
        CITY: "MAILLEBOIS",
      },
      {
        CITY: "MAILLY-CHAMPAGNE",
      },
      {
        CITY: "MAILLY-LA-VILLE",
      },
      {
        CITY: "MAILLY-LE-CAMP",
      },
      {
        CITY: "MAILLY-MAILLET",
      },
      {
        CITY: "MAILLY-RAINEVAL",
      },
      {
        CITY: "MAINCY",
      },
      {
        CITY: "MAING",
      },
      {
        CITY: "MAINTENON",
      },
      {
        CITY: "MAINVILLIERS",
      },
      {
        CITY: "MAINXE",
      },
      {
        CITY: "MAIRIEUX",
      },
      {
        CITY: "MAIRY-SUR-MARNE",
      },
      {
        CITY: "MAISDON-SUR-SEVRE",
      },
      {
        CITY: "MAISNIL",
      },
      {
        CITY: "MAISNIL-LES-RUITZ",
      },
      {
        CITY: "MAISON-PONTHIEU",
      },
      {
        CITY: "MAISONS-ALFORT",
      },
      {
        CITY: "MAISONS-LAFFITTE",
      },
      {
        CITY: "MAISONSGOUTTE",
      },
      {
        CITY: "MAISONTIERS",
      },
      {
        CITY: "MAISSE",
      },
      {
        CITY: "MAIZEROY",
      },
      {
        CITY: "MAIZIERES-LES-METZ",
      },
      {
        CITY: "MALABRY",
      },
      {
        CITY: "MALAFRETAZ",
      },
      {
        CITY: "MALAIN",
      },
      {
        CITY: "MALAKOFF",
      },
      {
        CITY: "MALANCOURT-LA-MONTAGNE",
      },
      {
        CITY: "MALANGE",
      },
      {
        CITY: "MALANSAC",
      },
      {
        CITY: "MALATAVERNE",
      },
      {
        CITY: "MALAUCENE",
      },
      {
        CITY: "MALAUMONT",
      },
      {
        CITY: "MALAUNAY",
      },
      {
        CITY: "MALAUSE",
      },
      {
        CITY: "MALAUSSANNE",
      },
      {
        CITY: "MALAY-LE-GRAND",
      },
      {
        CITY: "MALEGOUDE",
      },
      {
        CITY: "MALEMORT-DU-COMTAT",
      },
      {
        CITY: "MALEMORT-SUR-CORREZE",
      },
      {
        CITY: "MALESHERBES",
      },
      {
        CITY: "MALESTROIT",
      },
      {
        CITY: "MALEVILLE",
      },
      {
        CITY: "MALGUENAC",
      },
      {
        CITY: "MALICORNE",
      },
      {
        CITY: "MALICORNE-SUR-SARTHE",
      },
      {
        CITY: "MALIJAI",
      },
      {
        CITY: "MALINCOURT",
      },
      {
        CITY: "MALINTRAT",
      },
      {
        CITY: "MALISSARD",
      },
      {
        CITY: "MALLELOY",
      },
      {
        CITY: "MALLEMOISSON",
      },
      {
        CITY: "MALLEMORT",
      },
      {
        CITY: "MALLING",
      },
      {
        CITY: "MALMERSPACH",
      },
      {
        CITY: "MALMONT",
      },
      {
        CITY: "MALPAS",
      },
      {
        CITY: "MALRAS",
      },
      {
        CITY: "MALREVERS",
      },
      {
        CITY: "MALVES-EN-MINERVOIS",
      },
      {
        CITY: "MALVILLE",
      },
      {
        CITY: "MALZEVILLE",
      },
      {
        CITY: "MAMERS",
      },
      {
        CITY: "MAMETZ",
      },
      {
        CITY: "MAMEY",
      },
      {
        CITY: "MAMIROLLE",
      },
      {
        CITY: "MANCHECOURT",
      },
      {
        CITY: "MANCIEULLES",
      },
      {
        CITY: "MANDAGOUT",
      },
      {
        CITY: "MANDELIEU-LA-NAPOULE",
      },
      {
        CITY: "MANDEURE",
      },
      {
        CITY: "MANDRES-LA-COTE",
      },
      {
        CITY: "MANDRES-LES-ROSES",
      },
      {
        CITY: "MANDUEL",
      },
      {
        CITY: "MANE",
      },
      {
        CITY: "MANEGLISE",
      },
      {
        CITY: "MANGIENNES",
      },
      {
        CITY: "MANGONVILLE",
      },
      {
        CITY: "MANICAMP",
      },
      {
        CITY: "MANINGHEN-HENNE",
      },
      {
        CITY: "MANNEVILLE-ES-PLAINS",
      },
      {
        CITY: "MANNEVILLETTE",
      },
      {
        CITY: "MANOM",
      },
      {
        CITY: "MANONCOURT-SUR-SEILLE",
      },
      {
        CITY: "MANONVILLER",
      },
      {
        CITY: "MANOSQUE",
      },
      {
        CITY: "MANOT",
      },
      {
        CITY: "MANSIGNE",
      },
      {
        CITY: "MANSLE",
      },
      {
        CITY: "MANSPACH",
      },
      {
        CITY: "MANTES-LA-JOLIE",
      },
      {
        CITY: "MANTES-LA-VILLE",
      },
      {
        CITY: "MANTHELAN",
      },
      {
        CITY: "MANTOCHE",
      },
      {
        CITY: "MANTRY",
      },
      {
        CITY: "MANZAT",
      },
      {
        CITY: "MANZIAT",
      },
      {
        CITY: "MARANGE-SILVANGE",
      },
      {
        CITY: "MARANGE-ZONDRANGE",
      },
      {
        CITY: "MARANS",
      },
      {
        CITY: "MARAUSSAN",
      },
      {
        CITY: "MARBACHE",
      },
      {
        CITY: "MARC",
      },
      {
        CITY: "MARCAMPS",
      },
      {
        CITY: "MARCAY",
      },
      {
        CITY: "MARCE-SUR-ESVES",
      },
      {
        CITY: "MARCELCAVE",
      },
      {
        CITY: "MARCELLAZ-ALBANAIS",
      },
      {
        CITY: "MARCHAINVILLE",
      },
      {
        CITY: "MARCHAUX",
      },
      {
        CITY: "MARCHE-ALLOUARDE",
      },
      {
        CITY: "MARCHEPRIME",
      },
      {
        CITY: "MARCHESIEUX",
      },
      {
        CITY: "MARCHEVILLE",
      },
      {
        CITY: "MARCHIENNES",
      },
      {
        CITY: "MARCIAC",
      },
      {
        CITY: "MARCIGNY",
      },
      {
        CITY: "MARCILHAC-SUR-CELE",
      },
      {
        CITY: "MARCILLAC",
      },
      {
        CITY: "MARCILLAC-LA-CROISILLE",
      },
      {
        CITY: "MARCILLAT-EN-COMBRAILLE",
      },
      {
        CITY: "MARCILLOLES",
      },
      {
        CITY: "MARCILLY-EN-BASSIGNY",
      },
      {
        CITY: "MARCILLY-EN-BEAUCE",
      },
      {
        CITY: "MARCILLY-EN-VILLETTE",
      },
      {
        CITY: "MARCILLY-SUR-EURE",
      },
      {
        CITY: "MARCILLY-SUR-SEINE",
      },
      {
        CITY: "MARCILLY-SUR-TILLE",
      },
      {
        CITY: "MARCILLY-SUR-VIENNE",
      },
      {
        CITY: "MARCK",
      },
      {
        CITY: "MARCKOLSHEIM",
      },
      {
        CITY: "MARCOING",
      },
      {
        CITY: "MARCOLLIN",
      },
      {
        CITY: "MARCONNELLE",
      },
      {
        CITY: "MARCOUSSIS",
      },
      {
        CITY: "MARCOUVILLE",
      },
      {
        CITY: "MARCOUX",
      },
      {
        CITY: "MARCQ",
      },
      {
        CITY: "MARCQ-EN-BAROEUL",
      },
      {
        CITY: "MARCQ-EN-OSTREVENT",
      },
      {
        CITY: "MARDEUIL",
      },
      {
        CITY: "MARDIE",
      },
      {
        CITY: "MARDYCK",
      },
      {
        CITY: "MAREAU-AUX-BOIS",
      },
      {
        CITY: "MAREAU-AUX-PRES",
      },
      {
        CITY: "MAREIL-EN-FRANCE",
      },
      {
        CITY: "MAREIL-MARLY",
      },
      {
        CITY: "MAREIL-SUR-LOIR",
      },
      {
        CITY: "MAREIL-SUR-MAULDRE",
      },
      {
        CITY: "MARENLA",
      },
      {
        CITY: "MARENNES",
      },
      {
        CITY: "MARENNES",
      },
      {
        CITY: "MAREST",
      },
      {
        CITY: "MARESTAING",
      },
      {
        CITY: "MAREUIL",
      },
      {
        CITY: "MAREUIL",
      },
      {
        CITY: "MAREUIL-CAUBERT",
      },
      {
        CITY: "MAREUIL-EN-BRIE",
      },
      {
        CITY: "MAREUIL-LE-PORT",
      },
      {
        CITY: "MAREUIL-LES-MEAUX",
      },
      {
        CITY: "MAREUIL-SUR-CHER",
      },
      {
        CITY: "MAREUIL-SUR-LAY-DISSAIS",
      },
      {
        CITY: "MAREUIL-SUR-OURCQ",
      },
      {
        CITY: "MARGAUX",
      },
      {
        CITY: "MARGENCEL",
      },
      {
        CITY: "MARGENCY",
      },
      {
        CITY: "MARGERIE-CHANTAGRET",
      },
      {
        CITY: "MARGNY",
      },
      {
        CITY: "MARGNY-LES-COMPIEGNE",
      },
      {
        CITY: "MARGON",
      },
      {
        CITY: "MARGUERITTES",
      },
      {
        CITY: "MARGUT",
      },
      {
        CITY: "MARIE",
      },
      {
        CITY: "MARIENTHAL",
      },
      {
        CITY: "MARIGNAC",
      },
      {
        CITY: "MARIGNAC-LASCLARES",
      },
      {
        CITY: "MARIGNANE",
      },
      {
        CITY: "MARIGNE",
      },
      {
        CITY: "MARIGNIER",
      },
      {
        CITY: "MARIGNY",
      },
      {
        CITY: "MARIGNY",
      },
      {
        CITY: "MARIGNY-BRIZAY",
      },
      {
        CITY: "MARIGNY-CHEMEREAU",
      },
      {
        CITY: "MARIGNY-EN-ORXOIS",
      },
      {
        CITY: "MARIGNY-LE-CHATEL",
      },
      {
        CITY: "MARIGNY-LES-USAGES",
      },
      {
        CITY: "MARIGNY-MARMANDE",
      },
      {
        CITY: "MARIN",
      },
      {
        CITY: "MARINES",
      },
      {
        CITY: "MARINGES",
      },
      {
        CITY: "MARINGUES",
      },
      {
        CITY: "MARLE",
      },
      {
        CITY: "MARLENHEIM",
      },
      {
        CITY: "MARLES-EN-BRIE",
      },
      {
        CITY: "MARLES-LES-MINES",
      },
      {
        CITY: "MARLIENS",
      },
      {
        CITY: "MARLIOZ",
      },
      {
        CITY: "MARLOTTE",
      },
      {
        CITY: "MARLY",
      },
      {
        CITY: "MARLY",
      },
      {
        CITY: "MARLY-LA-VILLE",
      },
      {
        CITY: "MARLY-LE-ROI",
      },
      {
        CITY: "MARMAGNE",
      },
      {
        CITY: "MARMANDE",
      },
      {
        CITY: "MARMANHAC",
      },
      {
        CITY: "MARMOUTIER",
      },
      {
        CITY: "MARNAND",
      },
      {
        CITY: "MARNAZ",
      },
      {
        CITY: "MARNES",
      },
      {
        CITY: "MARNES-LA-COQUETTE",
      },
      {
        CITY: "MAROEUIL",
      },
      {
        CITY: "MAROLLES",
      },
      {
        CITY: "MAROLLES-EN-BRIE",
      },
      {
        CITY: "MAROLLES-EN-HUREPOIX",
      },
      {
        CITY: "MAROLS",
      },
      {
        CITY: "MAROMME",
      },
      {
        CITY: "MARON",
      },
      {
        CITY: "MARON",
      },
      {
        CITY: "MARPENT",
      },
      {
        CITY: "MARQUAY",
      },
      {
        CITY: "MARQUEFAVE",
      },
      {
        CITY: "MARQUEGLISE",
      },
      {
        CITY: "MARQUES",
      },
      {
        CITY: "MARQUILLIES",
      },
      {
        CITY: "MARQUION",
      },
      {
        CITY: "MARQUISE",
      },
      {
        CITY: "MARQUIXANES",
      },
      {
        CITY: "MARS",
      },
      {
        CITY: "MARS",
      },
      {
        CITY: "MARS-SUR-ALLIER",
      },
      {
        CITY: "MARSAC",
      },
      {
        CITY: "MARSAC-EN-LIVRADOIS",
      },
      {
        CITY: "MARSAC-SUR-LISLE",
      },
      {
        CITY: "MARSAINVILLIERS",
      },
      {
        CITY: "MARSAIS",
      },
      {
        CITY: "MARSAIS-SAINTE-RADEGONDE",
      },
      {
        CITY: "MARSANEIX",
      },
      {
        CITY: "MARSANGY",
      },
      {
        CITY: "MARSANNAY-LE-BOIS",
      },
      {
        CITY: "MARSANNE",
      },
      {
        CITY: "MARSAT",
      },
      {
        CITY: "MARSAZ",
      },
      {
        CITY: "MARSEILLAN",
      },
      {
        CITY: "MARSEILLE",
      },
      {
        CITY: "MARSEILLE-EN-BEAUVAISIS",
      },
      {
        CITY: "MARSEILLES-LES-AUBIGNY",
      },
      {
        CITY: "MARSILLARGUES",
      },
      {
        CITY: "MARSILLY",
      },
      {
        CITY: "MARSILLY",
      },
      {
        CITY: "MARSSAC-SUR-TARN",
      },
      {
        CITY: "MARTAINVILLE-EPREVILLE",
      },
      {
        CITY: "MARTEL",
      },
      {
        CITY: "MARTHOD",
      },
      {
        CITY: "MARTIEL",
      },
      {
        CITY: "MARTIGNAS-SUR-JALLE",
      },
      {
        CITY: "MARTIGNE-BRIAND",
      },
      {
        CITY: "MARTIGNE-FERCHAUD",
      },
      {
        CITY: "MARTIGNY",
      },
      {
        CITY: "MARTIGUES",
      },
      {
        CITY: "MARTILLAC",
      },
      {
        CITY: "MARTIN-EGLISE",
      },
      {
        CITY: "MARTINET",
      },
      {
        CITY: "MARTINVAST",
      },
      {
        CITY: "MARTOT",
      },
      {
        CITY: "MARTRES-TOLOSANE",
      },
      {
        CITY: "MARVAL",
      },
      {
        CITY: "MARVEJOLS",
      },
      {
        CITY: "MARVILLE-LES-BOIS",
      },
      {
        CITY: "MARZAN",
      },
      {
        CITY: "MARZY",
      },
      {
        CITY: "MAS-GRENIER",
      },
      {
        CITY: "MAS-SAINTES-PUELLES",
      },
      {
        CITY: "MASEVAUX",
      },
      {
        CITY: "MASLACQ",
      },
      {
        CITY: "MASLIVES",
      },
      {
        CITY: "MASNIERES",
      },
      {
        CITY: "MASNY",
      },
      {
        CITY: "MASPARRAUTE",
      },
      {
        CITY: "MASSAT",
      },
      {
        CITY: "MASSERAC",
      },
      {
        CITY: "MASSERET",
      },
      {
        CITY: "MASSIAC",
      },
      {
        CITY: "MASSIEU",
      },
      {
        CITY: "MASSIEUX",
      },
      {
        CITY: "MASSINGY-LES-SEMUR",
      },
      {
        CITY: "MASSUGAS",
      },
      {
        CITY: "MASSY",
      },
      {
        CITY: "MASTAING",
      },
      {
        CITY: "MATHA",
      },
      {
        CITY: "MATHAY",
      },
      {
        CITY: "MATHENAY",
      },
      {
        CITY: "MATHIEU",
      },
      {
        CITY: "MATHIEU",
      },
      {
        CITY: "MATIGNON",
      },
      {
        CITY: "MATIGNY",
      },
      {
        CITY: "MATOUR",
      },
      {
        CITY: "MATTAINCOURT",
      },
      {
        CITY: "MATTEXEY",
      },
      {
        CITY: "MATZENHEIM",
      },
      {
        CITY: "MAUBEUGE",
      },
      {
        CITY: "MAUBOURGUET",
      },
      {
        CITY: "MAUCHAMPS",
      },
      {
        CITY: "MAUCOR",
      },
      {
        CITY: "MAUGUIO",
      },
      {
        CITY: "MAULE",
      },
      {
        CITY: "MAULEON-LICHARRE",
      },
      {
        CITY: "MAULEVRIER",
      },
      {
        CITY: "MAUPERTHUIS",
      },
      {
        CITY: "MAUPREVOIR",
      },
      {
        CITY: "MAURAN",
      },
      {
        CITY: "MAURE",
      },
      {
        CITY: "MAURE-DE-BRETAGNE",
      },
      {
        CITY: "MAURECOURT",
      },
      {
        CITY: "MAUREILHAN",
      },
      {
        CITY: "MAUREILLAS-LAS-ILLAS",
      },
      {
        CITY: "MAUREMONT",
      },
      {
        CITY: "MAURENS",
      },
      {
        CITY: "MAUREPAS",
      },
      {
        CITY: "MAURESSARGUES",
      },
      {
        CITY: "MAUREVILLE",
      },
      {
        CITY: "MAURIAC",
      },
      {
        CITY: "MAUROIS",
      },
      {
        CITY: "MAURON",
      },
      {
        CITY: "MAURRIN",
      },
      {
        CITY: "MAURS",
      },
      {
        CITY: "MAUSSANE-LES-ALPILLES",
      },
      {
        CITY: "MAUVES",
      },
      {
        CITY: "MAUVES-SUR-HUISNE",
      },
      {
        CITY: "MAUVES-SUR-LOIRE",
      },
      {
        CITY: "MAUVEZIN-DE-PRAT",
      },
      {
        CITY: "MAUX",
      },
      {
        CITY: "MAUZAC",
      },
      {
        CITY: "MAUZE-SUR-LE-MIGNON",
      },
      {
        CITY: "MAUZENS-ET-MIREMONT",
      },
      {
        CITY: "MAXENT",
      },
      {
        CITY: "MAXEVILLE",
      },
      {
        CITY: "MAXILLY-SUR-SAONE",
      },
      {
        CITY: "MAY-EN-MULTIEN",
      },
      {
        CITY: "MAY-SUR-ORNE",
      },
      {
        CITY: "MAYENNE",
      },
      {
        CITY: "MAYET",
      },
      {
        CITY: "MAYSEL",
      },
      {
        CITY: "MAZAMET",
      },
      {
        CITY: "MAZAN",
      },
      {
        CITY: "MAZANGE",
      },
      {
        CITY: "MAZE",
      },
      {
        CITY: "MAZERAY",
      },
      {
        CITY: "MAZERES-DE-NESTE",
      },
      {
        CITY: "MAZERES-SUR-SALAT",
      },
      {
        CITY: "MAZEROLLES",
      },
      {
        CITY: "MAZEROLLES-LE-SALIN",
      },
      {
        CITY: "MAZET-SAINT-VOY",
      },
      {
        CITY: "MAZEUIL",
      },
      {
        CITY: "MAZIERES-EN-GATINE",
      },
      {
        CITY: "MAZIERES-EN-MAUGES",
      },
      {
        CITY: "MAZINGARBE",
      },
      {
        CITY: "MAZION",
      },
      {
        CITY: "MEAUDRE",
      },
      {
        CITY: "MEAULTE",
      },
      {
        CITY: "MEAUX",
      },
      {
        CITY: "MEAUZAC",
      },
      {
        CITY: "MEDAN",
      },
      {
        CITY: "MEDIS",
      },
      {
        CITY: "MEDREAC",
      },
      {
        CITY: "MEES",
      },
      {
        CITY: "MEGRIT",
      },
      {
        CITY: "MEHARICOURT",
      },
      {
        CITY: "MEHUN-SUR-YEVRE",
      },
      {
        CITY: "MEIGNEUX",
      },
      {
        CITY: "MEILHAC",
      },
      {
        CITY: "MEILHAN",
      },
      {
        CITY: "MEILHAN",
      },
      {
        CITY: "MEILHAN-SUR-GARONNE",
      },
      {
        CITY: "MEILLANT",
      },
      {
        CITY: "MEILLERIE",
      },
      {
        CITY: "MEILLON",
      },
      {
        CITY: "MEILLONNAS",
      },
      {
        CITY: "MEISENTHAL",
      },
      {
        CITY: "MEJANNES-LE-CLAP",
      },
      {
        CITY: "MEJANNES-LES-ALES",
      },
      {
        CITY: "MELAY",
      },
      {
        CITY: "MELAY",
      },
      {
        CITY: "MELESSE",
      },
      {
        CITY: "MELGVEN",
      },
      {
        CITY: "MELLAC",
      },
      {
        CITY: "MELLE",
      },
      {
        CITY: "MELLECEY",
      },
      {
        CITY: "MELLERAN",
      },
      {
        CITY: "MELLERAY",
      },
      {
        CITY: "MELLIONNEC",
      },
      {
        CITY: "MELLO",
      },
      {
        CITY: "MELRAND",
      },
      {
        CITY: "MELSHEIM",
      },
      {
        CITY: "MELUN",
      },
      {
        CITY: "MEMBREY",
      },
      {
        CITY: "MENAT",
      },
      {
        CITY: "MENDE",
      },
      {
        CITY: "MENDIONDE",
      },
      {
        CITY: "MENEAC",
      },
      {
        CITY: "MENERBES",
      },
      {
        CITY: "MENESPLET",
      },
      {
        CITY: "MENESTREAU-EN-VILLETTE",
      },
      {
        CITY: "MENETOU-SALON",
      },
      {
        CITY: "MENETREOL-SUR-SAULDRE",
      },
      {
        CITY: "MENETROL",
      },
      {
        CITY: "MENIL-ERREUX",
      },
      {
        CITY: "MENIL-HERMEI",
      },
      {
        CITY: "MENIL-LA-HORGNE",
      },
      {
        CITY: "MENIL-SUR-BELVITTE",
      },
      {
        CITY: "MENILLES",
      },
      {
        CITY: "MENNECY",
      },
      {
        CITY: "MENNEVRET",
      },
      {
        CITY: "MENS",
      },
      {
        CITY: "MENSIGNAC",
      },
      {
        CITY: "MENTHON-SAINT-BERNARD",
      },
      {
        CITY: "MENTON",
      },
      {
        CITY: "MENUCOURT",
      },
      {
        CITY: "MENVILLE",
      },
      {
        CITY: "MEOUNES-LES-MONTRIEUX",
      },
      {
        CITY: "MER",
      },
      {
        CITY: "MERCENAC",
      },
      {
        CITY: "MERCKEGHEM",
      },
      {
        CITY: "MERCUES",
      },
      {
        CITY: "MERCUROL",
      },
      {
        CITY: "MERCY-LE-BAS",
      },
      {
        CITY: "MERDRIGNAC",
      },
      {
        CITY: "MERE",
      },
      {
        CITY: "MEREAU",
      },
      {
        CITY: "MERENVIELLE",
      },
      {
        CITY: "MEREVILLE",
      },
      {
        CITY: "MEREY-SOUS-MONTROND",
      },
      {
        CITY: "MERGEY",
      },
      {
        CITY: "MERICOURT",
      },
      {
        CITY: "MERIEL",
      },
      {
        CITY: "MERIGNAC",
      },
      {
        CITY: "MERIGNAS",
      },
      {
        CITY: "MERIGNAT",
      },
      {
        CITY: "MERIGNIES",
      },
      {
        CITY: "MERIGNY",
      },
      {
        CITY: "MERINDOL",
      },
      {
        CITY: "MERLAS",
      },
      {
        CITY: "MERLEBACH",
      },
      {
        CITY: "MERLEVENEZ",
      },
      {
        CITY: "MERLIMONT",
      },
      {
        CITY: "MERLINES",
      },
      {
        CITY: "MERNEL",
      },
      {
        CITY: "MEROUX",
      },
      {
        CITY: "MERRY-LA-VALLEE",
      },
      {
        CITY: "MERRY-SUR-YONNE",
      },
      {
        CITY: "MERS-LES-BAINS",
      },
      {
        CITY: "MERTZEN",
      },
      {
        CITY: "MERTZWILLER",
      },
      {
        CITY: "MERU",
      },
      {
        CITY: "MERVANS",
      },
      {
        CITY: "MERVILLE",
      },
      {
        CITY: "MERVILLE",
      },
      {
        CITY: "MERVILLE-FRANCEVILLE-PLAGE",
      },
      {
        CITY: "MERXHEIM",
      },
      {
        CITY: "MERY",
      },
      {
        CITY: "MERY-ES-BOIS",
      },
      {
        CITY: "MERY-SUR-OISE",
      },
      {
        CITY: "MERY-SUR-SEINE",
      },
      {
        CITY: "MESANGER",
      },
      {
        CITY: "MESANGUEVILLE",
      },
      {
        CITY: "MESCHERS-SUR-GIRONDE",
      },
      {
        CITY: "MESCOULES",
      },
      {
        CITY: "MESLAND",
      },
      {
        CITY: "MESLAY",
      },
      {
        CITY: "MESLAY-DU-MAINE",
      },
      {
        CITY: "MESLAY-LE-VIDAME",
      },
      {
        CITY: "MESLIN",
      },
      {
        CITY: "MESNIL-RAOUL",
      },
      {
        CITY: "MESNIL-SAINT-GEORGES",
      },
      {
        CITY: "MESNIL-SAINT-NICAISE",
      },
      {
        CITY: "MESNIL-SELLIERES",
      },
      {
        CITY: "MESNIL-SOUS-VIENNE",
      },
      {
        CITY: "MESPLEDE",
      },
      {
        CITY: "MESSAC",
      },
      {
        CITY: "MESSANGES",
      },
      {
        CITY: "MESSEI",
      },
      {
        CITY: "MESSEIN",
      },
      {
        CITY: "MESSIA-SUR-SORNE",
      },
      {
        CITY: "MESSIGNY-ET-VANTOUX",
      },
      {
        CITY: "MESSIMY",
      },
      {
        CITY: "MESSON",
      },
      {
        CITY: "MESSY",
      },
      {
        CITY: "METABIEF",
      },
      {
        CITY: "METEREN",
      },
      {
        CITY: "METTRAY",
      },
      {
        CITY: "METZ",
      },
      {
        CITY: "METZ-TESSY",
      },
      {
        CITY: "METZERAL",
      },
      {
        CITY: "METZERESCHE",
      },
      {
        CITY: "METZERVISSE",
      },
      {
        CITY: "MEUCON",
      },
      {
        CITY: "MEUDON",
      },
      {
        CITY: "MEULAN-EN-YVELINES",
      },
      {
        CITY: "MEUNG-SUR-LOIRE",
      },
      {
        CITY: "MEURCHIN",
      },
      {
        CITY: "MEURSAULT",
      },
      {
        CITY: "MEUSE",
      },
      {
        CITY: "MEUSNES",
      },
      {
        CITY: "MEUZAC",
      },
      {
        CITY: "MEVOISINS",
      },
      {
        CITY: "MEXIMIEUX",
      },
      {
        CITY: "MEXY",
      },
      {
        CITY: "MEYENHEIM",
      },
      {
        CITY: "MEYLAN",
      },
      {
        CITY: "MEYMAC",
      },
      {
        CITY: "MEYNES",
      },
      {
        CITY: "MEYRARGUES",
      },
      {
        CITY: "MEYRAS",
      },
      {
        CITY: "MEYREUIL",
      },
      {
        CITY: "MEYSSAC",
      },
      {
        CITY: "MEYTHET",
      },
      {
        CITY: "MEYZIEU",
      },
      {
        CITY: "MEZE",
      },
      {
        CITY: "MEZEL",
      },
      {
        CITY: "MEZEL",
      },
      {
        CITY: "MEZERES",
      },
      {
        CITY: "MEZERIAT",
      },
      {
        CITY: "MEZIDON-CANON",
      },
      {
        CITY: "MEZIERES-EN-DROUAIS",
      },
      {
        CITY: "MEZIERES-LEZ-CLERY",
      },
      {
        CITY: "MEZIERES-SOUS-LAVARDIN",
      },
      {
        CITY: "MEZIERES-SUR-COUESNON",
      },
      {
        CITY: "MEZIERES-SUR-SEINE",
      },
      {
        CITY: "MEZZAVIA",
      },
      {
        CITY: "MICHELBACH-LE-BAS",
      },
      {
        CITY: "MIELAN",
      },
      {
        CITY: "MIELLIN",
      },
      {
        CITY: "MIETESHEIM",
      },
      {
        CITY: "MIEUSSY",
      },
      {
        CITY: "MIGENNES",
      },
      {
        CITY: "MIGLOS",
      },
      {
        CITY: "MIGNALOUX-BEAUVOIR",
      },
      {
        CITY: "MIGNE-AUXANCES",
      },
      {
        CITY: "MIGNIERES",
      },
      {
        CITY: "MIGRON",
      },
      {
        CITY: "MILHAUD",
      },
      {
        CITY: "MILIZAC",
      },
      {
        CITY: "MILLAC",
      },
      {
        CITY: "MILLAS",
      },
      {
        CITY: "MILLAU",
      },
      {
        CITY: "MILLENCOURT",
      },
      {
        CITY: "MILLERY",
      },
      {
        CITY: "MILLERY",
      },
      {
        CITY: "MILLY",
      },
      {
        CITY: "MILLY-LA-FORET",
      },
      {
        CITY: "MILLY-LAMARTINE",
      },
      {
        CITY: "MILLY-SUR-THERAIN",
      },
      {
        CITY: "MILON-LA-CHAPELLE",
      },
      {
        CITY: "MIMBASTE",
      },
      {
        CITY: "MIMET",
      },
      {
        CITY: "MIMIZAN",
      },
      {
        CITY: "MINE DE BERT",
      },
      {
        CITY: "MINGOT",
      },
      {
        CITY: "MINIAC-MORVAN",
      },
      {
        CITY: "MINIAC-SOUS-BECHEREL",
      },
      {
        CITY: "MINIHY-TREGUIER",
      },
      {
        CITY: "MINVERSHEIM",
      },
      {
        CITY: "MINZIER",
      },
      {
        CITY: "MIONNAY",
      },
      {
        CITY: "MIONS",
      },
      {
        CITY: "MIOS",
      },
      {
        CITY: "MIRABEL-AUX-BARONNIES",
      },
      {
        CITY: "MIRADOUX",
      },
      {
        CITY: "MIRAMAS",
      },
      {
        CITY: "MIRAMBEAU",
      },
      {
        CITY: "MIRAMONT-DE-GUYENNE",
      },
      {
        CITY: "MIRAMONT-SENSACQ",
      },
      {
        CITY: "MIRANDE",
      },
      {
        CITY: "MIREBEAU",
      },
      {
        CITY: "MIREBEAU-SUR-BEZE",
      },
      {
        CITY: "MIRECOURT",
      },
      {
        CITY: "MIREMONT",
      },
      {
        CITY: "MIREPEIX",
      },
      {
        CITY: "MIREPOIX",
      },
      {
        CITY: "MIREPOIX-SUR-TARN",
      },
      {
        CITY: "MIREVAL-LAURAGAIS",
      },
      {
        CITY: "MIRIBEL",
      },
      {
        CITY: "MISEREY",
      },
      {
        CITY: "MISEREY-SALINES",
      },
      {
        CITY: "MISON",
      },
      {
        CITY: "MISSE",
      },
      {
        CITY: "MISSILLAC",
      },
      {
        CITY: "MISSIRIAC",
      },
      {
        CITY: "MISSON",
      },
      {
        CITY: "MISSY-SUR-AISNE",
      },
      {
        CITY: "MITRY-MORY",
      },
      {
        CITY: "MITTELBRONN",
      },
      {
        CITY: "MITTELHAUSBERGEN",
      },
      {
        CITY: "MITTELWIHR",
      },
      {
        CITY: "MITTERAND",
      },
      {
        CITY: "MITTERSHEIM",
      },
      {
        CITY: "MODANE",
      },
      {
        CITY: "MOELAN-SUR-MER",
      },
      {
        CITY: "MOENS",
      },
      {
        CITY: "MOERNACH",
      },
      {
        CITY: "MOESLAINS",
      },
      {
        CITY: "MOGNEVILLE",
      },
      {
        CITY: "MOGUES",
      },
      {
        CITY: "MOHON",
      },
      {
        CITY: "MOINEVILLE",
      },
      {
        CITY: "MOINVILLE-LA-JEULIN",
      },
      {
        CITY: "MOIRANS",
      },
      {
        CITY: "MOIRANS-EN-MONTAGNE",
      },
      {
        CITY: "MOIRE",
      },
      {
        CITY: "MOISDON-LA-RIVIERE",
      },
      {
        CITY: "MOISLAINS",
      },
      {
        CITY: "MOISSAC",
      },
      {
        CITY: "MOISSAT",
      },
      {
        CITY: "MOISSELLES",
      },
      {
        CITY: "MOISSIEU-SUR-DOLON",
      },
      {
        CITY: "MOISSY-CRAMAYEL",
      },
      {
        CITY: "MOISVILLE",
      },
      {
        CITY: "MOLAC",
      },
      {
        CITY: "MOLANDIER",
      },
      {
        CITY: "MOLESME",
      },
      {
        CITY: "MOLIENS",
      },
      {
        CITY: "MOLIERES-SUR-CEZE",
      },
      {
        CITY: "MOLIETS-ET-MAA",
      },
      {
        CITY: "MOLINET",
      },
      {
        CITY: "MOLINEUF",
      },
      {
        CITY: "MOLLEGES",
      },
      {
        CITY: "MOLLIENS-AU-BOIS",
      },
      {
        CITY: "MOLLKIRCH",
      },
      {
        CITY: "MOLOY",
      },
      {
        CITY: "MOLSHEIM",
      },
      {
        CITY: "MOLTIFAO",
      },
      {
        CITY: "MOMAS",
      },
      {
        CITY: "MOMBRIER",
      },
      {
        CITY: "MOMERES",
      },
      {
        CITY: "MOMMENHEIM",
      },
      {
        CITY: "MOMY",
      },
      {
        CITY: "MONBAHUS",
      },
      {
        CITY: "MONBALEN",
      },
      {
        CITY: "MONBRUN",
      },
      {
        CITY: "MONCALE",
      },
      {
        CITY: "MONCE-EN-BELIN",
      },
      {
        CITY: "MONCEAU-LE-NEUF-ET-FAUCOUZY",
      },
      {
        CITY: "MONCEAU-LE-WAAST",
      },
      {
        CITY: "MONCEAU-SAINT-WAAST",
      },
      {
        CITY: "MONCEAUX",
      },
      {
        CITY: "MONCEL-SUR-SEILLE",
      },
      {
        CITY: "MONCETZ-LONGEVAS",
      },
      {
        CITY: "MONCHEAUX-LES-FREVENT",
      },
      {
        CITY: "MONCHECOURT",
      },
      {
        CITY: "MONCHY-BRETON",
      },
      {
        CITY: "MONCHY-HUMIERES",
      },
      {
        CITY: "MONCHY-SAINT-ELOI",
      },
      {
        CITY: "MONCLAR-DE-QUERCY",
      },
      {
        CITY: "MONCONTOUR",
      },
      {
        CITY: "MONCOUTANT",
      },
      {
        CITY: "MONDELANGE",
      },
      {
        CITY: "MONDESCOURT",
      },
      {
        CITY: "MONDETOUR",
      },
      {
        CITY: "MONDEVILLE",
      },
      {
        CITY: "MONDEVILLE",
      },
      {
        CITY: "MONDON",
      },
      {
        CITY: "MONDONVILLE",
      },
      {
        CITY: "MONDRAGON",
      },
      {
        CITY: "MONDRAINVILLE",
      },
      {
        CITY: "MONDREPUIS",
      },
      {
        CITY: "MONEIN",
      },
      {
        CITY: "MONESTIER-DE-CLERMONT",
      },
      {
        CITY: "MONESTIES",
      },
      {
        CITY: "MONETAY-SUR-LOIRE",
      },
      {
        CITY: "MONETEAU",
      },
      {
        CITY: "MONFERRAN-PLAVES",
      },
      {
        CITY: "MONFERRAN-SAVES",
      },
      {
        CITY: "MONFLANQUIN",
      },
      {
        CITY: "MONISTROL-SUR-LOIRE",
      },
      {
        CITY: "MONNAIE",
      },
      {
        CITY: "MONNETIER-MORNEX",
      },
      {
        CITY: "MONNEVILLE",
      },
      {
        CITY: "MONNIERES",
      },
      {
        CITY: "MONPAZIER",
      },
      {
        CITY: "MONS",
      },
      {
        CITY: "MONS",
      },
      {
        CITY: "MONS",
      },
      {
        CITY: "MONS",
      },
      {
        CITY: "MONS-EN-BAROEUL",
      },
      {
        CITY: "MONS-EN-MONTOIS",
      },
      {
        CITY: "MONS-EN-PEVELE",
      },
      {
        CITY: "MONSAC",
      },
      {
        CITY: "MONSEGUR",
      },
      {
        CITY: "MONSEMPRON-LIBOS",
      },
      {
        CITY: "MONSIREIGNE",
      },
      {
        CITY: "MONSTEROUX-MILIEU",
      },
      {
        CITY: "MONT",
      },
      {
        CITY: "MONT",
      },
      {
        CITY: "MONT",
      },
      {
        CITY: "MONT-BONVILLERS",
      },
      {
        CITY: "MONT-CAUVAIRE",
      },
      {
        CITY: "MONT-DE-MARSAN",
      },
      {
        CITY: "MONT-NOTRE-DAME",
      },
      {
        CITY: "MONT-PRES-CHAMBORD",
      },
      {
        CITY: "MONT-SAINT-AIGNAN",
      },
      {
        CITY: "MONT-SAINT-MARTIN",
      },
      {
        CITY: "MONT-SAINT-MARTIN",
      },
      {
        CITY: "MONT-SAINT-PERE",
      },
      {
        CITY: "MONT-SAXONNEX",
      },
      {
        CITY: "MONT-SUR-MEURTHE",
      },
      {
        CITY: "MONT-SUR-MONNET",
      },
      {
        CITY: "MONTABARD",
      },
      {
        CITY: "MONTADY",
      },
      {
        CITY: "MONTAGNAC",
      },
      {
        CITY: "MONTAGNAT",
      },
      {
        CITY: "MONTAGNE",
      },
      {
        CITY: "MONTAGNEY",
      },
      {
        CITY: "MONTAGNIEU",
      },
      {
        CITY: "MONTAGNOLE",
      },
      {
        CITY: "MONTAGNY-EN-VEXIN",
      },
      {
        CITY: "MONTAGNY-LES-LANCHES",
      },
      {
        CITY: "MONTAGNY-LES-SEURRE",
      },
      {
        CITY: "MONTAGNY-SAINTE-FELICITE",
      },
      {
        CITY: "MONTAGNY-SUR-GROSNE",
      },
      {
        CITY: "MONTAGRIER",
      },
      {
        CITY: "MONTAGUT",
      },
      {
        CITY: "MONTAIGU",
      },
      {
        CITY: "MONTAIGU",
      },
      {
        CITY: "MONTAIGU",
      },
      {
        CITY: "MONTAIGU-DE-QUERCY",
      },
      {
        CITY: "MONTAIGUT-SUR-SAVE",
      },
      {
        CITY: "MONTAINVILLE",
      },
      {
        CITY: "MONTALET-LE-BOIS",
      },
      {
        CITY: "MONTALIEU-VERCIEU",
      },
      {
        CITY: "MONTAMISE",
      },
      {
        CITY: "MONTANAY",
      },
      {
        CITY: "MONTARDON",
      },
      {
        CITY: "MONTARGIS",
      },
      {
        CITY: "MONTARLOT-LES-RIOZ",
      },
      {
        CITY: "MONTARNAUD",
      },
      {
        CITY: "MONTASTRUC-DE-SALIES",
      },
      {
        CITY: "MONTASTRUC-LA-CONSEILLERE",
      },
      {
        CITY: "MONTATAIRE",
      },
      {
        CITY: "MONTAUBAN",
      },
      {
        CITY: "MONTAUBAN-DE-BRETAGNE",
      },
      {
        CITY: "MONTAUD",
      },
      {
        CITY: "MONTAUDIN",
      },
      {
        CITY: "MONTAULIEU",
      },
      {
        CITY: "MONTAURE",
      },
      {
        CITY: "MONTAUROUX",
      },
      {
        CITY: "MONTAUT",
      },
      {
        CITY: "MONTAUT",
      },
      {
        CITY: "MONTAYRAL",
      },
      {
        CITY: "MONTBARD",
      },
      {
        CITY: "MONTBARTIER",
      },
      {
        CITY: "MONTBAZIN",
      },
      {
        CITY: "MONTBAZON",
      },
      {
        CITY: "MONTBEL",
      },
      {
        CITY: "MONTBELLET",
      },
      {
        CITY: "MONTBERON",
      },
      {
        CITY: "MONTBERT",
      },
      {
        CITY: "MONTBETON",
      },
      {
        CITY: "MONTBEUGNY",
      },
      {
        CITY: "MONTBIZOT",
      },
      {
        CITY: "MONTBLANC",
      },
      {
        CITY: "MONTBOILLON",
      },
      {
        CITY: "MONTBONNOT-SAINT-MARTIN",
      },
      {
        CITY: "MONTBOUCHER-SUR-JABRON",
      },
      {
        CITY: "MONTBOUTON",
      },
      {
        CITY: "MONTBRISON",
      },
      {
        CITY: "MONTBRON",
      },
      {
        CITY: "MONTBRONN",
      },
      {
        CITY: "MONTBRUN-BOCAGE",
      },
      {
        CITY: "MONTCARET",
      },
      {
        CITY: "MONTCARRA",
      },
      {
        CITY: "MONTCEAU",
      },
      {
        CITY: "MONTCEAU-LES-MINES",
      },
      {
        CITY: "MONTCEAUX",
      },
      {
        CITY: "MONTCEAUX-LES-MEAUX",
      },
      {
        CITY: "MONTCEL",
      },
      {
        CITY: "MONTCENIS",
      },
      {
        CITY: "MONTCEY",
      },
      {
        CITY: "MONTCHAMP",
      },
      {
        CITY: "MONTCHANIN",
      },
      {
        CITY: "MONTCHAUDE",
      },
      {
        CITY: "MONTCLAR",
      },
      {
        CITY: "MONTCORNET",
      },
      {
        CITY: "MONTCOURT-FROMONVILLE",
      },
      {
        CITY: "MONTCOY",
      },
      {
        CITY: "MONTCUQ",
      },
      {
        CITY: "MONTCY-NOTRE-DAME",
      },
      {
        CITY: "MONTDIDIER",
      },
      {
        CITY: "MONTDOUMERC",
      },
      {
        CITY: "MONTDRAGON",
      },
      {
        CITY: "MONTEAUX",
      },
      {
        CITY: "MONTEBOURG",
      },
      {
        CITY: "MONTECH",
      },
      {
        CITY: "MONTECHEROUX",
      },
      {
        CITY: "MONTEGLIN",
      },
      {
        CITY: "MONTEILS",
      },
      {
        CITY: "MONTEL-DE-GELAT",
      },
      {
        CITY: "MONTELIER",
      },
      {
        CITY: "MONTEMBOEUF",
      },
      {
        CITY: "MONTENDRE",
      },
      {
        CITY: "MONTENESCOURT",
      },
      {
        CITY: "MONTENOIS",
      },
      {
        CITY: "MONTENOISON",
      },
      {
        CITY: "MONTERBLANC",
      },
      {
        CITY: "MONTEREAU-FAULT-YONNE",
      },
      {
        CITY: "MONTERFIL",
      },
      {
        CITY: "MONTESCOT",
      },
      {
        CITY: "MONTESQUIEU",
      },
      {
        CITY: "MONTESQUIEU-DES-ALBERES",
      },
      {
        CITY: "MONTESQUIEU-VOLVESTRE",
      },
      {
        CITY: "MONTESSAUX",
      },
      {
        CITY: "MONTESSON",
      },
      {
        CITY: "MONTESTRUC-SUR-GERS",
      },
      {
        CITY: "MONTESTRUCQ",
      },
      {
        CITY: "MONTEUX",
      },
      {
        CITY: "MONTEVRAIN",
      },
      {
        CITY: "MONTEYNARD",
      },
      {
        CITY: "MONTFARVILLE",
      },
      {
        CITY: "MONTFAUCON",
      },
      {
        CITY: "MONTFAUCON",
      },
      {
        CITY: "MONTFAUCON",
      },
      {
        CITY: "MONTFAUCON-EN-VELAY",
      },
      {
        CITY: "MONTFAUCON-MONTIGNE",
      },
      {
        CITY: "MONTFAVET",
      },
      {
        CITY: "MONTFERMEIL",
      },
      {
        CITY: "MONTFERMY",
      },
      {
        CITY: "MONTFERRAND-DU-PERIGORD",
      },
      {
        CITY: "MONTFERRAND-LE-CHATEAU",
      },
      {
        CITY: "MONTFERRAT",
      },
      {
        CITY: "MONTFERRIER-SUR-LEZ",
      },
      {
        CITY: "MONTFLEUR",
      },
      {
        CITY: "MONTFORT",
      },
      {
        CITY: "MONTFORT-EN-CHALOSSE",
      },
      {
        CITY: "MONTFORT-SUR-MEU",
      },
      {
        CITY: "MONTFRIN",
      },
      {
        CITY: "MONTFROC",
      },
      {
        CITY: "MONTFURON",
      },
      {
        CITY: "MONTGAILLARD",
      },
      {
        CITY: "MONTGAROULT",
      },
      {
        CITY: "MONTGERMONT",
      },
      {
        CITY: "MONTGERON",
      },
      {
        CITY: "MONTGESTY",
      },
      {
        CITY: "MONTGIROD",
      },
      {
        CITY: "MONTGISCARD",
      },
      {
        CITY: "MONTGIVRAY",
      },
      {
        CITY: "MONTHERME",
      },
      {
        CITY: "MONTHIEUX",
      },
      {
        CITY: "MONTHODON",
      },
      {
        CITY: "MONTHOU-SUR-BIEVRE",
      },
      {
        CITY: "MONTHOU-SUR-CHER",
      },
      {
        CITY: "MONTHUREUX-SUR-SAONE",
      },
      {
        CITY: "MONTHYON",
      },
      {
        CITY: "MONTICELLO",
      },
      {
        CITY: "MONTIER-EN-DER",
      },
      {
        CITY: "MONTIERCHAUME",
      },
      {
        CITY: "MONTIERS",
      },
      {
        CITY: "MONTIGNAC-DE-LAUZUN",
      },
      {
        CITY: "MONTIGNAC-LE-COQ",
      },
      {
        CITY: "MONTIGNE-LE-BRILLANT",
      },
      {
        CITY: "MONTIGNE-LES-RAIRIES",
      },
      {
        CITY: "MONTIGNY",
      },
      {
        CITY: "MONTIGNY",
      },
      {
        CITY: "MONTIGNY-EN-CAMBRESIS",
      },
      {
        CITY: "MONTIGNY-EN-GOHELLE",
      },
      {
        CITY: "MONTIGNY-LE-BRETONNEUX",
      },
      {
        CITY: "MONTIGNY-LE-CHARTIF",
      },
      {
        CITY: "MONTIGNY-LENGRAIN",
      },
      {
        CITY: "MONTIGNY-LES-CORMEILLES",
      },
      {
        CITY: "MONTIGNY-SUR-LOING",
      },
      {
        CITY: "MONTILLY",
      },
      {
        CITY: "MONTILLY-SUR-NOIREAU",
      },
      {
        CITY: "MONTIRON",
      },
      {
        CITY: "MONTIVILLIERS",
      },
      {
        CITY: "MONTJAVOULT",
      },
      {
        CITY: "MONTJEAN-SUR-LOIRE",
      },
      {
        CITY: "MONTLHERY",
      },
      {
        CITY: "MONTLIARD",
      },
      {
        CITY: "MONTLIEU-LA-GARDE",
      },
      {
        CITY: "MONTLIGNON",
      },
      {
        CITY: "MONTLIVAULT",
      },
      {
        CITY: "MONTLOUIS",
      },
      {
        CITY: "MONTLOUIS-SUR-LOIRE",
      },
      {
        CITY: "MONTLUEL",
      },
      {
        CITY: "MONTMACQ",
      },
      {
        CITY: "MONTMAGNY",
      },
      {
        CITY: "MONTMALIN",
      },
      {
        CITY: "MONTMARAULT",
      },
      {
        CITY: "MONTMARTIN",
      },
      {
        CITY: "MONTMARTIN-EN-GRAIGNES",
      },
      {
        CITY: "MONTMARTIN-SUR-MER",
      },
      {
        CITY: "MONTMELIAN",
      },
      {
        CITY: "MONTMERLE-SUR-SAONE",
      },
      {
        CITY: "MONTMEYRAN",
      },
      {
        CITY: "MONTMIRAIL",
      },
      {
        CITY: "MONTMIRAL",
      },
      {
        CITY: "MONTMIRAT",
      },
      {
        CITY: "MONTMOREAU-SAINT-CYBARD",
      },
      {
        CITY: "MONTMORENCY",
      },
      {
        CITY: "MONTMORENCY-BEAUFORT",
      },
      {
        CITY: "MONTMORILLON",
      },
      {
        CITY: "MONTMOROT",
      },
      {
        CITY: "MONTOIR-DE-BRETAGNE",
      },
      {
        CITY: "MONTOIRE-SUR-LE-LOIR",
      },
      {
        CITY: "MONTOIS-LA-MONTAGNE",
      },
      {
        CITY: "MONTOISON",
      },
      {
        CITY: "MONTOLIVET",
      },
      {
        CITY: "MONTOURNAIS",
      },
      {
        CITY: "MONTPELLIER",
      },
      {
        CITY: "MONTPEYROUX",
      },
      {
        CITY: "MONTPEYROUX",
      },
      {
        CITY: "MONTPEZAT",
      },
      {
        CITY: "MONTPEZAT-DE-QUERCY",
      },
      {
        CITY: "MONTPEZAT-SOUS-BAUZON",
      },
      {
        CITY: "MONTPINCON",
      },
      {
        CITY: "MONTPINIER",
      },
      {
        CITY: "MONTPITOL",
      },
      {
        CITY: "MONTPON-MENESTEROL",
      },
      {
        CITY: "MONTPOTHIER",
      },
      {
        CITY: "MONTRABE",
      },
      {
        CITY: "MONTREAL",
      },
      {
        CITY: "MONTREAL",
      },
      {
        CITY: "MONTREAL-LA-CLUSE",
      },
      {
        CITY: "MONTREDON-DES-CORBIERES",
      },
      {
        CITY: "MONTREDON-LABESSONNIE",
      },
      {
        CITY: "MONTREJEAU",
      },
      {
        CITY: "MONTRELAIS",
      },
      {
        CITY: "MONTREM",
      },
      {
        CITY: "MONTREQUIENNE",
      },
      {
        CITY: "MONTRESOR",
      },
      {
        CITY: "MONTRET",
      },
      {
        CITY: "MONTREUIL",
      },
      {
        CITY: "MONTREUIL",
      },
      {
        CITY: "MONTREUIL-AUX-LIONS",
      },
      {
        CITY: "MONTREUIL-BONNIN",
      },
      {
        CITY: "MONTREUIL-EN-CAUX",
      },
      {
        CITY: "MONTREUIL-SOUS-PEROUSE",
      },
      {
        CITY: "MONTREUIL-SUR-BLAISE",
      },
      {
        CITY: "MONTREUIL-SUR-BRECHE",
      },
      {
        CITY: "MONTREUIL-SUR-LOZON",
      },
      {
        CITY: "MONTREUIL-SUR-MER",
      },
      {
        CITY: "MONTREUX-JEUNE",
      },
      {
        CITY: "MONTREVEL",
      },
      {
        CITY: "MONTREVEL-EN-BRESSE",
      },
      {
        CITY: "MONTRICHARD",
      },
      {
        CITY: "MONTRICOUX",
      },
      {
        CITY: "MONTRODAT",
      },
      {
        CITY: "MONTROND",
      },
      {
        CITY: "MONTROND",
      },
      {
        CITY: "MONTROND-LES-BAINS",
      },
      {
        CITY: "MONTROUGE",
      },
      {
        CITY: "MONTROUVEAU",
      },
      {
        CITY: "MONTRY",
      },
      {
        CITY: "MONTS",
      },
      {
        CITY: "MONTS-EN-BESSIN",
      },
      {
        CITY: "MONTS-SUR-GUESNES",
      },
      {
        CITY: "MONTSALVY",
      },
      {
        CITY: "MONTSAUCHE-LES-SETTONS",
      },
      {
        CITY: "MONTSEGUR-SUR-LAUZON",
      },
      {
        CITY: "MONTSEVEROUX",
      },
      {
        CITY: "MONTSOREAU",
      },
      {
        CITY: "MONTSOULT",
      },
      {
        CITY: "MONTUSSAN",
      },
      {
        CITY: "MONTVALEN",
      },
      {
        CITY: "MONTVALENT",
      },
      {
        CITY: "MONTVENDRE",
      },
      {
        CITY: "MONTVERDUN",
      },
      {
        CITY: "MONTVILLE",
      },
      {
        CITY: "MOON-SUR-ELLE",
      },
      {
        CITY: "MOOSCH",
      },
      {
        CITY: "MORAGNE",
      },
      {
        CITY: "MORAINVILLIERS",
      },
      {
        CITY: "MORANCE",
      },
      {
        CITY: "MORANCEZ",
      },
      {
        CITY: "MORANGIS",
      },
      {
        CITY: "MORANGIS",
      },
      {
        CITY: "MORBECQUE",
      },
      {
        CITY: "MORBIER",
      },
      {
        CITY: "MORCENX",
      },
      {
        CITY: "MORDELLES",
      },
      {
        CITY: "MOREAC",
      },
      {
        CITY: "MOREE",
      },
      {
        CITY: "MORELMAISON",
      },
      {
        CITY: "MORESTEL",
      },
      {
        CITY: "MORET-SUR-LOING",
      },
      {
        CITY: "MOREUIL",
      },
      {
        CITY: "MOREZ",
      },
      {
        CITY: "MORGNY",
      },
      {
        CITY: "MORGNY-LA-POMMERAYE",
      },
      {
        CITY: "MORHANGE",
      },
      {
        CITY: "MORIENVAL",
      },
      {
        CITY: "MORIERES-LES-AVIGNON",
      },
      {
        CITY: "MORIEUX",
      },
      {
        CITY: "MORIGNY-CHAMPIGNY",
      },
      {
        CITY: "MORISEL",
      },
      {
        CITY: "MORIVILLE",
      },
      {
        CITY: "MORLAAS",
      },
      {
        CITY: "MORLAIX",
      },
      {
        CITY: "MORMANT",
      },
      {
        CITY: "MORNAC",
      },
      {
        CITY: "MORNAC-SUR-SEUDRE",
      },
      {
        CITY: "MORNANT",
      },
      {
        CITY: "MORNAY",
      },
      {
        CITY: "MORNAY-SUR-ALLIER",
      },
      {
        CITY: "MOROGES",
      },
      {
        CITY: "MOROGUES",
      },
      {
        CITY: "MORRE",
      },
      {
        CITY: "MORSAIN",
      },
      {
        CITY: "MORSANG-SUR-ORGE",
      },
      {
        CITY: "MORSBACH",
      },
      {
        CITY: "MORSBRONN-LES-BAINS",
      },
      {
        CITY: "MORSCHWILLER-LE-BAS",
      },
      {
        CITY: "MORTAGNE",
      },
      {
        CITY: "MORTAGNE-AU-PERCHE",
      },
      {
        CITY: "MORTAGNE-DU-NORD",
      },
      {
        CITY: "MORTAGNE-SUR-SEVRE",
      },
      {
        CITY: "MORTAIN",
      },
      {
        CITY: "MORTCERF",
      },
      {
        CITY: "MORTEAU",
      },
      {
        CITY: "MORTEAUX-COULIBOEUF",
      },
      {
        CITY: "MORTEFONTAINE",
      },
      {
        CITY: "MORTEFONTAINE-EN-THELLE",
      },
      {
        CITY: "MORTREE",
      },
      {
        CITY: "MORTZWILLER",
      },
      {
        CITY: "MORVILLERS-SAINT-SATURNIN",
      },
      {
        CITY: "MORVILLIERS",
      },
      {
        CITY: "MORZINE",
      },
      {
        CITY: "MOSLINS",
      },
      {
        CITY: "MOSNAC",
      },
      {
        CITY: "MOSSON",
      },
      {
        CITY: "MOTTEVILLE",
      },
      {
        CITY: "MOUANS-SARTOUX",
      },
      {
        CITY: "MOUAZE",
      },
      {
        CITY: "MOUCHIN",
      },
      {
        CITY: "MOUFLERS",
      },
      {
        CITY: "MOUGINS",
      },
      {
        CITY: "MOUGON",
      },
      {
        CITY: "MOUGUERRE",
      },
      {
        CITY: "MOUILLERON-EN-PAREDS",
      },
      {
        CITY: "MOUILLERON-LE-CAPTIF",
      },
      {
        CITY: "MOULEDOUS",
      },
      {
        CITY: "MOULES",
      },
      {
        CITY: "MOULIETS-ET-VILLEMARTIN",
      },
      {
        CITY: "MOULIN",
      },
      {
        CITY: "MOULIN-MAGE",
      },
      {
        CITY: "MOULIN-NEUF",
      },
      {
        CITY: "MOULIN-SOUS-TOUVENT",
      },
      {
        CITY: "MOULINEAUX",
      },
      {
        CITY: "MOULINES",
      },
      {
        CITY: "MOULINET",
      },
      {
        CITY: "MOULINET",
      },
      {
        CITY: "MOULINS",
      },
      {
        CITY: "MOULINS-ENGILBERT",
      },
      {
        CITY: "MOULINS-LES-METZ",
      },
      {
        CITY: "MOULINS-SUR-CEPHONS",
      },
      {
        CITY: "MOULINS-SUR-YEVRE",
      },
      {
        CITY: "MOULLE",
      },
      {
        CITY: "MOULT",
      },
      {
        CITY: "MOUMOUR",
      },
      {
        CITY: "MOURENX",
      },
      {
        CITY: "MOUREZE",
      },
      {
        CITY: "MOURIES",
      },
      {
        CITY: "MOURIOUX-VIEILLEVILLE",
      },
      {
        CITY: "MOURMELON-LE-GRAND",
      },
      {
        CITY: "MOURMELON-LE-PETIT",
      },
      {
        CITY: "MOUROUX",
      },
      {
        CITY: "MOURS",
      },
      {
        CITY: "MOURS-SAINT-EUSEBE",
      },
      {
        CITY: "MOUSSAN",
      },
      {
        CITY: "MOUSSEAUX-NEUVILLE",
      },
      {
        CITY: "MOUSSEY",
      },
      {
        CITY: "MOUSSON",
      },
      {
        CITY: "MOUSSOULENS",
      },
      {
        CITY: "MOUSSY",
      },
      {
        CITY: "MOUSSY-LE-NEUF",
      },
      {
        CITY: "MOUSSY-LE-VIEUX",
      },
      {
        CITY: "MOUSTERU",
      },
      {
        CITY: "MOUSTIER-EN-FAGNE",
      },
      {
        CITY: "MOUTHE",
      },
      {
        CITY: "MOUTHIER-EN-BRESSE",
      },
      {
        CITY: "MOUTHIERS-SUR-BOEME",
      },
      {
        CITY: "MOUTIERS",
      },
      {
        CITY: "MOUTIERS",
      },
      {
        CITY: "MOUTIERS-LES-MAUXFAITS",
      },
      {
        CITY: "MOUTIERS-SUR-LE-LAY",
      },
      {
        CITY: "MOUVAUX",
      },
      {
        CITY: "MOUXY",
      },
      {
        CITY: "MOUY",
      },
      {
        CITY: "MOUZEIL",
      },
      {
        CITY: "MOUZIEYS-TEULET",
      },
      {
        CITY: "MOUZILLON",
      },
      {
        CITY: "MOUZON",
      },
      {
        CITY: "MOYAUX",
      },
      {
        CITY: "MOYENCOURT",
      },
      {
        CITY: "MOYENMOUTIER",
      },
      {
        CITY: "MOYENNEVILLE",
      },
      {
        CITY: "MOYENNEVILLE",
      },
      {
        CITY: "MOYEUVRE-GRANDE",
      },
      {
        CITY: "MOYON",
      },
      {
        CITY: "MOYRAZES",
      },
      {
        CITY: "MOYVILLERS",
      },
      {
        CITY: "MOZAC",
      },
      {
        CITY: "MUESPACH-LE-HAUT",
      },
      {
        CITY: "MUIDES-SUR-LOIRE",
      },
      {
        CITY: "MUIDORGE",
      },
      {
        CITY: "MUIRANCOURT",
      },
      {
        CITY: "MUIZON",
      },
      {
        CITY: "MULCENT",
      },
      {
        CITY: "MULCEY",
      },
      {
        CITY: "MULHOUSE",
      },
      {
        CITY: "MULSANNE",
      },
      {
        CITY: "MUNCHHOUSE",
      },
      {
        CITY: "MUNDOLSHEIM",
      },
      {
        CITY: "MUNSTER",
      },
      {
        CITY: "MUNSTER",
      },
      {
        CITY: "MUR-DE-BRETAGNE",
      },
      {
        CITY: "MUR-DE-SOLOGNE",
      },
      {
        CITY: "MURAT",
      },
      {
        CITY: "MURES",
      },
      {
        CITY: "MURET",
      },
      {
        CITY: "MURIANETTE",
      },
      {
        CITY: "MURO",
      },
      {
        CITY: "MUROL",
      },
      {
        CITY: "MURON",
      },
      {
        CITY: "MURS-ERIGNE",
      },
      {
        CITY: "MURVIEL-LES-BEZIERS",
      },
      {
        CITY: "MURVIEL-LES-MONTPELLIER",
      },
      {
        CITY: "MUS",
      },
      {
        CITY: "MUSSIDAN",
      },
      {
        CITY: "MUSSIG",
      },
      {
        CITY: "MUTZIG",
      },
      {
        CITY: "MUZILLAC",
      },
      {
        CITY: "NADILLAC",
      },
      {
        CITY: "NAGES-ET-SOLORGUES",
      },
      {
        CITY: "NAILLOUX",
      },
      {
        CITY: "NAINTRE",
      },
      {
        CITY: "NAIZIN",
      },
      {
        CITY: "NAJAC",
      },
      {
        CITY: "NALLIERS",
      },
      {
        CITY: "NANCY",
      },
      {
        CITY: "NANDY",
      },
      {
        CITY: "NANGIS",
      },
      {
        CITY: "NANS-LES-PINS",
      },
      {
        CITY: "NANTERRE",
      },
      {
        CITY: "NANTES",
      },
      {
        CITY: "NANTEUIL-EN-VALLEE",
      },
      {
        CITY: "NANTEUIL-LE-HAUDOUIN",
      },
      {
        CITY: "NANTEUIL-LES-MEAUX",
      },
      {
        CITY: "NANTIAT",
      },
      {
        CITY: "NANTOIN",
      },
      {
        CITY: "NANTON",
      },
      {
        CITY: "NANTUA",
      },
      {
        CITY: "NAOURS",
      },
      {
        CITY: "NARBONNE",
      },
      {
        CITY: "NARBONNE-PLAGE",
      },
      {
        CITY: "NARGIS",
      },
      {
        CITY: "NARROSSE",
      },
      {
        CITY: "NATTAGES",
      },
      {
        CITY: "NAUCELLE",
      },
      {
        CITY: "NAUJAN-ET-POSTIAC",
      },
      {
        CITY: "NAUX",
      },
      {
        CITY: "NAVAILLES-ANGOS",
      },
      {
        CITY: "NAVARRENX",
      },
      {
        CITY: "NAVES",
      },
      {
        CITY: "NAY",
      },
      {
        CITY: "NAYEMONT-LES-FOSSES",
      },
      {
        CITY: "NAZELLES-NEGRON",
      },
      {
        CITY: "NEAUPHLE-LE-CHATEAU",
      },
      {
        CITY: "NEAUPHLETTE",
      },
      {
        CITY: "NEBIAN",
      },
      {
        CITY: "NEBOUZAT",
      },
      {
        CITY: "NEDONCHEL",
      },
      {
        CITY: "NEEWILLER-PRES-LAUTERBOURG",
      },
      {
        CITY: "NEFFES",
      },
      {
        CITY: "NEFFIES",
      },
      {
        CITY: "NEFIACH",
      },
      {
        CITY: "NEGREPELISSE",
      },
      {
        CITY: "NEGRON",
      },
      {
        CITY: "NEHOU",
      },
      {
        CITY: "NEMOURS",
      },
      {
        CITY: "NEOULES",
      },
      {
        CITY: "NERCILLAC",
      },
      {
        CITY: "NERE",
      },
      {
        CITY: "NERIS-LES-BAINS",
      },
      {
        CITY: "NERON",
      },
      {
        CITY: "NERONDE-SUR-DORE",
      },
      {
        CITY: "NERS",
      },
      {
        CITY: "NERSAC",
      },
      {
        CITY: "NERVILLE-LA-FORET",
      },
      {
        CITY: "NERY",
      },
      {
        CITY: "NESLE",
      },
      {
        CITY: "NESLES",
      },
      {
        CITY: "NESLES-LA-MONTAGNE",
      },
      {
        CITY: "NESLES-LA-VALLEE",
      },
      {
        CITY: "NESMY",
      },
      {
        CITY: "NESSA",
      },
      {
        CITY: "NEUF EGLISE",
      },
      {
        CITY: "NEUF-BRISACH",
      },
      {
        CITY: "NEUFCHATEAU",
      },
      {
        CITY: "NEUFCHATEL-EN-BRAY",
      },
      {
        CITY: "NEUFCHEF",
      },
      {
        CITY: "NEUFGRANGE",
      },
      {
        CITY: "NEUFLIZE",
      },
      {
        CITY: "NEUFMANIL",
      },
      {
        CITY: "NEUFMESNIL",
      },
      {
        CITY: "NEUFMOUTIERS-EN-BRIE",
      },
      {
        CITY: "NEUFVY-SUR-ARONDE",
      },
      {
        CITY: "NEUIL",
      },
      {
        CITY: "NEUILLE",
      },
      {
        CITY: "NEUILLY",
      },
      {
        CITY: "NEUILLY-EN-THELLE",
      },
      {
        CITY: "NEUILLY-LE-BISSON",
      },
      {
        CITY: "NEUILLY-LE-REAL",
      },
      {
        CITY: "NEUILLY-LES-DIJON",
      },
      {
        CITY: "NEUILLY-PLAISANCE",
      },
      {
        CITY: "NEUILLY-SAINT-FRONT",
      },
      {
        CITY: "NEUILLY-SOUS-CLERMONT",
      },
      {
        CITY: "NEUILLY-SUR-MARNE",
      },
      {
        CITY: "NEUILLY-SUR-SEINE",
      },
      {
        CITY: "NEULLIAC",
      },
      {
        CITY: "NEUNG-SUR-BEUVRON",
      },
      {
        CITY: "NEURE",
      },
      {
        CITY: "NEUSSARGUES-MOISSAC",
      },
      {
        CITY: "NEUVE-MAISON",
      },
      {
        CITY: "NEUVECELLE",
      },
      {
        CITY: "NEUVES-MAISONS",
      },
      {
        CITY: "NEUVIC",
      },
      {
        CITY: "NEUVIC-ENTIER",
      },
      {
        CITY: "NEUVICQ-LE-CHATEAU",
      },
      {
        CITY: "NEUVILLE",
      },
      {
        CITY: "NEUVILLE",
      },
      {
        CITY: "NEUVILLE-AUX-BOIS",
      },
      {
        CITY: "NEUVILLE-BOSC",
      },
      {
        CITY: "NEUVILLE-DE-POITOU",
      },
      {
        CITY: "NEUVILLE-EN-FERRAIN",
      },
      {
        CITY: "NEUVILLE-FERRIERES",
      },
      {
        CITY: "NEUVILLE-LES-DAMES",
      },
      {
        CITY: "NEUVILLE-LES-DIEPPE",
      },
      {
        CITY: "NEUVILLE-SAINT-REMY",
      },
      {
        CITY: "NEUVILLE-SAINT-VAAST",
      },
      {
        CITY: "NEUVILLE-SUR-AIN",
      },
      {
        CITY: "NEUVILLE-SUR-BRENNE",
      },
      {
        CITY: "NEUVILLE-SUR-ESCAUT",
      },
      {
        CITY: "NEUVILLE-SUR-OISE",
      },
      {
        CITY: "NEUVILLE-SUR-SAONE",
      },
      {
        CITY: "NEUVILLE-SUR-SARTHE",
      },
      {
        CITY: "NEUVILLE-SUR-SEINE",
      },
      {
        CITY: "NEUVILLER-SUR-MOSELLE",
      },
      {
        CITY: "NEUVIREUIL",
      },
      {
        CITY: "NEUVY",
      },
      {
        CITY: "NEUVY",
      },
      {
        CITY: "NEUVY-BOUIN",
      },
      {
        CITY: "NEUVY-EN-BEAUCE",
      },
      {
        CITY: "NEUVY-EN-SULLIAS",
      },
      {
        CITY: "NEUVY-LE-ROI",
      },
      {
        CITY: "NEUVY-SAINT-SEPULCHRE",
      },
      {
        CITY: "NEVERS",
      },
      {
        CITY: "NEVEZ",
      },
      {
        CITY: "NEVIAN",
      },
      {
        CITY: "NEVILLE",
      },
      {
        CITY: "NEXON",
      },
      {
        CITY: "NEYDENS",
      },
      {
        CITY: "NIBAS",
      },
      {
        CITY: "NIBELLE",
      },
      {
        CITY: "NICE",
      },
      {
        CITY: "NICOLE",
      },
      {
        CITY: "NICORPS",
      },
      {
        CITY: "NIDERHOFF",
      },
      {
        CITY: "NIDERVILLER",
      },
      {
        CITY: "NIEDERBRONN-LES-BAINS",
      },
      {
        CITY: "NIEDERBRUCK",
      },
      {
        CITY: "NIEDERENTZEN",
      },
      {
        CITY: "NIEDERHASLACH",
      },
      {
        CITY: "NIEDERHAUSBERGEN",
      },
      {
        CITY: "NIEDERMODERN",
      },
      {
        CITY: "NIEDERSCHAEFFOLSHEIM",
      },
      {
        CITY: "NIEPPE",
      },
      {
        CITY: "NIERGNIES",
      },
      {
        CITY: "NIEUIL",
      },
      {
        CITY: "NIEUL-LE-VIROUIL",
      },
      {
        CITY: "NIEUL-LES-SAINTES",
      },
      {
        CITY: "NIEUL-SUR-MER",
      },
      {
        CITY: "NIEVROZ",
      },
      {
        CITY: "NIFFER",
      },
      {
        CITY: "NIHERNE",
      },
      {
        CITY: "NILVANGE",
      },
      {
        CITY: "NIORT",
      },
      {
        CITY: "NISSAN-LEZ-ENSERUNE",
      },
      {
        CITY: "NITTING",
      },
      {
        CITY: "NIVILLAC",
      },
      {
        CITY: "NIVOLAS-VERMELLE",
      },
      {
        CITY: "NIZAS",
      },
      {
        CITY: "NOAILHAC",
      },
      {
        CITY: "NOAILLAC",
      },
      {
        CITY: "NOAILLAN",
      },
      {
        CITY: "NOAILLES",
      },
      {
        CITY: "NOAILLES",
      },
      {
        CITY: "NOE",
      },
      {
        CITY: "NOEUX-LES-AUXI",
      },
      {
        CITY: "NOEUX-LES-MINES",
      },
      {
        CITY: "NOGENT",
      },
      {
        CITY: "NOGENT-LE-PHAYE",
      },
      {
        CITY: "NOGENT-LE-ROI",
      },
      {
        CITY: "NOGENT-LE-ROTROU",
      },
      {
        CITY: "NOGENT-SUR-EURE",
      },
      {
        CITY: "NOGENT-SUR-MARNE",
      },
      {
        CITY: "NOGENT-SUR-OISE",
      },
      {
        CITY: "NOGENT-SUR-SEINE",
      },
      {
        CITY: "NOGENT-SUR-VERNISSON",
      },
      {
        CITY: "NOHANENT",
      },
      {
        CITY: "NOHIC",
      },
      {
        CITY: "NOIDANS-LE-FERROUX",
      },
      {
        CITY: "NOIDANS-LES-VESOUL",
      },
      {
        CITY: "NOILHAN",
      },
      {
        CITY: "NOINTEL",
      },
      {
        CITY: "NOINTOT",
      },
      {
        CITY: "NOIRON",
      },
      {
        CITY: "NOIRON-SOUS-GEVREY",
      },
      {
        CITY: "NOIRTERRE",
      },
      {
        CITY: "NOISEAU",
      },
      {
        CITY: "NOISIEL",
      },
      {
        CITY: "NOISSEVILLE",
      },
      {
        CITY: "NOISY-LE-GRAND",
      },
      {
        CITY: "NOISY-LE-ROI",
      },
      {
        CITY: "NOISY-LE-SEC",
      },
      {
        CITY: "NOISY-RUDIGNON",
      },
      {
        CITY: "NOISY-SUR-ECOLE",
      },
      {
        CITY: "NOISY-SUR-OISE",
      },
      {
        CITY: "NOLAY",
      },
      {
        CITY: "NOMAIN",
      },
      {
        CITY: "NOMENY",
      },
      {
        CITY: "NOMEXY",
      },
      {
        CITY: "NOMMAY",
      },
      {
        CITY: "NONANCOURT",
      },
      {
        CITY: "NONETTE",
      },
      {
        CITY: "NONNEVILLE",
      },
      {
        CITY: "NONTRON",
      },
      {
        CITY: "NONVILLE",
      },
      {
        CITY: "NONVILLIERS-GRANDHOUX",
      },
      {
        CITY: "NOORDPEENE",
      },
      {
        CITY: "NORDAUSQUES",
      },
      {
        CITY: "NORDHOUSE",
      },
      {
        CITY: "NORGES-LA-VILLE",
      },
      {
        CITY: "NORMANVILLE",
      },
      {
        CITY: "NORMIER",
      },
      {
        CITY: "NORRENT-FONTES",
      },
      {
        CITY: "NORREY-EN-AUGE",
      },
      {
        CITY: "NORROY-LE-SEC",
      },
      {
        CITY: "NORROY-LE-VENEUR",
      },
      {
        CITY: "NORROY-LES-PONT-A-MOUSSON",
      },
      {
        CITY: "NORT-SUR-ERDRE",
      },
      {
        CITY: "NOSTANG",
      },
      {
        CITY: "NOTHALTEN",
      },
      {
        CITY: "NOTRE-DAME-DE-BOISSET",
      },
      {
        CITY: "NOTRE-DAME-DE-BONDEVILLE",
      },
      {
        CITY: "NOTRE-DAME-DE-CENILLY",
      },
      {
        CITY: "NOTRE-DAME-DE-COMMIERS",
      },
      {
        CITY: "NOTRE-DAME-DE-GRAVENCHON",
      },
      {
        CITY: "NOTRE-DAME-DE-LA-ROUVIERE",
      },
      {
        CITY: "NOTRE-DAME-DE-LIVAYE",
      },
      {
        CITY: "NOTRE-DAME-DE-LIVOYE",
      },
      {
        CITY: "NOTRE-DAME-DE-MESAGE",
      },
      {
        CITY: "NOTRE-DAME-DE-RIEZ",
      },
      {
        CITY: "NOTRE-DAME-DE-SANILHAC",
      },
      {
        CITY: "NOTRE-DAME-DES-LANDES",
      },
      {
        CITY: "NOTRE-DAME-DU-PRE",
      },
      {
        CITY: "NOUAILLE-MAUPERTUIS",
      },
      {
        CITY: "NOUAINVILLE",
      },
      {
        CITY: "NOUAN-LE-FUZELIER",
      },
      {
        CITY: "NOUEILLES",
      },
      {
        CITY: "NOUILLY",
      },
      {
        CITY: "NOUSSEVILLER-LES-BITCHE",
      },
      {
        CITY: "NOUSSEVILLER-SAINT-NABOR",
      },
      {
        CITY: "NOUVELLE-EGLISE",
      },
      {
        CITY: "NOUVION-LE-COMTE",
      },
      {
        CITY: "NOUVION-LE-VINEUX",
      },
      {
        CITY: "NOUVION-SUR-MEUSE",
      },
      {
        CITY: "NOUVOITOU",
      },
      {
        CITY: "NOUZONVILLE",
      },
      {
        CITY: "NOVALAISE",
      },
      {
        CITY: "NOVEANT-SUR-MOSELLE",
      },
      {
        CITY: "NOVES",
      },
      {
        CITY: "NOVILLARD",
      },
      {
        CITY: "NOVILLARS",
      },
      {
        CITY: "NOVION-PORCIEN",
      },
      {
        CITY: "NOYAL-MUZILLAC",
      },
      {
        CITY: "NOYAL-PONTIVY",
      },
      {
        CITY: "NOYAL-SUR-VILAINE",
      },
      {
        CITY: "NOYANT-DE-TOURAINE",
      },
      {
        CITY: "NOYANT-LA-GRAVOYERE",
      },
      {
        CITY: "NOYANT-LA-PLAINE",
      },
      {
        CITY: "NOYAREY",
      },
      {
        CITY: "NOYELLES-GODAULT",
      },
      {
        CITY: "NOYELLES-SOUS-LENS",
      },
      {
        CITY: "NOYELLES-SUR-ESCAUT",
      },
      {
        CITY: "NOYEN-SUR-SARTHE",
      },
      {
        CITY: "NOYERS",
      },
      {
        CITY: "NOYERS",
      },
      {
        CITY: "NOYERS-BOCAGE",
      },
      {
        CITY: "NOYERS-SUR-CHER",
      },
      {
        CITY: "NOYON",
      },
      {
        CITY: "NOZAY",
      },
      {
        CITY: "NOZAY",
      },
      {
        CITY: "NUAILLE",
      },
      {
        CITY: "NUAILLE-SUR-BOUTONNE",
      },
      {
        CITY: "NUEIL-SUR-LAYON",
      },
      {
        CITY: "NUELLES",
      },
      {
        CITY: "NUITS-SAINT-GEORGES",
      },
      {
        CITY: "NYOISEAU",
      },
      {
        CITY: "NYONS",
      },
      {
        CITY: "OBENHEIM",
      },
      {
        CITY: "OBERBRONN",
      },
      {
        CITY: "OBERENTZEN",
      },
      {
        CITY: "OBERHASLACH",
      },
      {
        CITY: "OBERHAUSBERGEN",
      },
      {
        CITY: "OBERHERGHEIM",
      },
      {
        CITY: "OBERHOFFEN-SUR-MODER",
      },
      {
        CITY: "OBERLAUTERBACH",
      },
      {
        CITY: "OBERMODERN-ZUTZENDORF",
      },
      {
        CITY: "OBERMORSCHWIHR",
      },
      {
        CITY: "OBERMORSCHWILLER",
      },
      {
        CITY: "OBERNAI",
      },
      {
        CITY: "OBERSAASHEIM",
      },
      {
        CITY: "OBERSCHAEFFOLSHEIM",
      },
      {
        CITY: "OBJAT",
      },
      {
        CITY: "OBLINGHEM",
      },
      {
        CITY: "OBTREE",
      },
      {
        CITY: "OCHEY",
      },
      {
        CITY: "OCTEVILLE",
      },
      {
        CITY: "OCTEVILLE-SUR-MER",
      },
      {
        CITY: "ODEREN",
      },
      {
        CITY: "ODOMEZ",
      },
      {
        CITY: "ODOS",
      },
      {
        CITY: "OELLEVILLE",
      },
      {
        CITY: "OETING",
      },
      {
        CITY: "OEYRELUY",
      },
      {
        CITY: "OFFEKERQUE",
      },
      {
        CITY: "OFFEMONT",
      },
      {
        CITY: "OFFENDORF",
      },
      {
        CITY: "OFFOY",
      },
      {
        CITY: "OFFRANVILLE",
      },
      {
        CITY: "OGENNE-CAMPTORT",
      },
      {
        CITY: "OGER",
      },
      {
        CITY: "OGEU-LES-BAINS",
      },
      {
        CITY: "OGEVILLER",
      },
      {
        CITY: "OGY",
      },
      {
        CITY: "OIGNIES",
      },
      {
        CITY: "OINGT",
      },
      {
        CITY: "OINVILLE-SUR-MONTCIENT",
      },
      {
        CITY: "OIRON",
      },
      {
        CITY: "OISELAY-ET-GRACHAUX",
      },
      {
        CITY: "OISEMONT",
      },
      {
        CITY: "OISLY",
      },
      {
        CITY: "OISSEAU",
      },
      {
        CITY: "OISSEL",
      },
      {
        CITY: "OISY",
      },
      {
        CITY: "OISY-LE-VERGER",
      },
      {
        CITY: "OIZON",
      },
      {
        CITY: "OLARGUES",
      },
      {
        CITY: "OLBY",
      },
      {
        CITY: "OLEMPS",
      },
      {
        CITY: "OLETTA",
      },
      {
        CITY: "OLIVET",
      },
      {
        CITY: "OLLAINVILLE",
      },
      {
        CITY: "OLLE",
      },
      {
        CITY: "OLLEZY",
      },
      {
        CITY: "OLLIERES",
      },
      {
        CITY: "OLLIERGUES",
      },
      {
        CITY: "OLLIOULES",
      },
      {
        CITY: "OLLOIX",
      },
      {
        CITY: "OLMET",
      },
      {
        CITY: "OLONNE-SUR-MER",
      },
      {
        CITY: "OLONZAC",
      },
      {
        CITY: "OLORON-SAINTE-MARIE",
      },
      {
        CITY: "OLWISHEIM",
      },
      {
        CITY: "OMESSA",
      },
      {
        CITY: "OMONVILLE-LA-ROGUE",
      },
      {
        CITY: "ONDES",
      },
      {
        CITY: "ONDRES",
      },
      {
        CITY: "ONDREVILLE-SUR-ESSONNE",
      },
      {
        CITY: "ONET-LE-CHATEAU",
      },
      {
        CITY: "ONNAING",
      },
      {
        CITY: "ONNION",
      },
      {
        CITY: "ONS-EN-BRAY",
      },
      {
        CITY: "ONZAIN",
      },
      {
        CITY: "OPIO",
      },
      {
        CITY: "OPPEDE",
      },
      {
        CITY: "OPTEVOZ",
      },
      {
        CITY: "ORADOUR",
      },
      {
        CITY: "ORADOUR-FANAIS",
      },
      {
        CITY: "ORADOUR-SUR-GLANE",
      },
      {
        CITY: "ORAISON",
      },
      {
        CITY: "ORANGIS",
      },
      {
        CITY: "ORBAN",
      },
      {
        CITY: "ORBEC",
      },
      {
        CITY: "ORBEIL",
      },
      {
        CITY: "ORBEY",
      },
      {
        CITY: "ORCET",
      },
      {
        CITY: "ORCHAISE",
      },
      {
        CITY: "ORCHAMPS-VENNES",
      },
      {
        CITY: "ORCHES",
      },
      {
        CITY: "ORCHIES",
      },
      {
        CITY: "ORCIER",
      },
      {
        CITY: "ORCINES",
      },
      {
        CITY: "ORDAN-LARROQUE",
      },
      {
        CITY: "ORDIARP",
      },
      {
        CITY: "OREGUE",
      },
      {
        CITY: "ORESMAUX",
      },
      {
        CITY: "ORGELET",
      },
      {
        CITY: "ORGERES",
      },
      {
        CITY: "ORGERUS",
      },
      {
        CITY: "ORGEVAL",
      },
      {
        CITY: "ORGON",
      },
      {
        CITY: "ORGUEIL",
      },
      {
        CITY: "ORIGNOLLES",
      },
      {
        CITY: "ORIGNY-EN-THIERACHE",
      },
      {
        CITY: "ORIGNY-LE-SEC",
      },
      {
        CITY: "ORIGNY-SAINTE-BENOITE",
      },
      {
        CITY: "ORIOL-EN-ROYANS",
      },
      {
        CITY: "ORION",
      },
      {
        CITY: "ORLEAT",
      },
      {
        CITY: "ORLIENAS",
      },
      {
        CITY: "ORLU",
      },
      {
        CITY: "ORLY",
      },
      {
        CITY: "ORMERSVILLER",
      },
      {
        CITY: "ORMES",
      },
      {
        CITY: "ORMES-ET-VILLE",
      },
      {
        CITY: "ORMESSON-SUR-MARNE",
      },
      {
        CITY: "ORMOY",
      },
      {
        CITY: "ORMOY",
      },
      {
        CITY: "ORMOY-VILLERS",
      },
      {
        CITY: "ORNACIEUX",
      },
      {
        CITY: "ORNANS",
      },
      {
        CITY: "ORNEX",
      },
      {
        CITY: "ORNY",
      },
      {
        CITY: "ORPHIN",
      },
      {
        CITY: "ORROUER",
      },
      {
        CITY: "ORROUY",
      },
      {
        CITY: "ORRY-LA-VILLE",
      },
      {
        CITY: "ORSAN",
      },
      {
        CITY: "ORSANCO",
      },
      {
        CITY: "ORSONVILLE",
      },
      {
        CITY: "ORTHEZ",
      },
      {
        CITY: "ORTONCOURT",
      },
      {
        CITY: "ORVAL",
      },
      {
        CITY: "ORVAL",
      },
      {
        CITY: "ORVAULT",
      },
      {
        CITY: "ORVAUX",
      },
      {
        CITY: "ORVEAU-BELLESAUVE",
      },
      {
        CITY: "ORVILLERS-SOREL",
      },
      {
        CITY: "OSNE-LE-VAL",
      },
      {
        CITY: "OSNY",
      },
      {
        CITY: "OSSAGES",
      },
      {
        CITY: "OSSE",
      },
      {
        CITY: "OSSES",
      },
      {
        CITY: "OSSUN",
      },
      {
        CITY: "OSTHEIM",
      },
      {
        CITY: "OSTRICOURT",
      },
      {
        CITY: "OSTWALD",
      },
      {
        CITY: "OTA",
      },
      {
        CITY: "OTHIS",
      },
      {
        CITY: "OTTANGE",
      },
      {
        CITY: "OTTMARSHEIM",
      },
      {
        CITY: "OTTONVILLE",
      },
      {
        CITY: "OTTROTT",
      },
      {
        CITY: "OUANNE",
      },
      {
        CITY: "OUARVILLE",
      },
      {
        CITY: "OUCHAMPS",
      },
      {
        CITY: "OUCQUES",
      },
      {
        CITY: "OUDON",
      },
      {
        CITY: "OUERRE",
      },
      {
        CITY: "OUEZY",
      },
      {
        CITY: "OUFFIERES",
      },
      {
        CITY: "OUGNEY",
      },
      {
        CITY: "OUHANS",
      },
      {
        CITY: "OUILLY-LE-TESSON",
      },
      {
        CITY: "OUILLY-LE-VICOMTE",
      },
      {
        CITY: "OUISTREHAM",
      },
      {
        CITY: "OULCHY-LE-CHATEAU",
      },
      {
        CITY: "OULLINS",
      },
      {
        CITY: "OUPIA",
      },
      {
        CITY: "OURCHES",
      },
      {
        CITY: "OUROUX",
      },
      {
        CITY: "OUROUX-SUR-SAONE",
      },
      {
        CITY: "OURSBELILLE",
      },
      {
        CITY: "OUSSE",
      },
      {
        CITY: "OUSSON-SUR-LOIRE",
      },
      {
        CITY: "OUTARVILLE",
      },
      {
        CITY: "OUTILLE",
      },
      {
        CITY: "OUTINES",
      },
      {
        CITY: "OUTREAU",
      },
      {
        CITY: "OUVEILLAN",
      },
      {
        CITY: "OUVILLE",
      },
      {
        CITY: "OUVILLE",
      },
      {
        CITY: "OUZILLY",
      },
      {
        CITY: "OUZOUER-LE-MARCHE",
      },
      {
        CITY: "OUZOUER-SUR-LOIRE",
      },
      {
        CITY: "OUZOUER-SUR-TREZEE",
      },
      {
        CITY: "OVANCHES",
      },
      {
        CITY: "OVILLERS-LA-BOISSELLE",
      },
      {
        CITY: "OYE-PLAGE",
      },
      {
        CITY: "OYEU",
      },
      {
        CITY: "OYONNAX",
      },
      {
        CITY: "OYTIER-SAINT-OBLAS",
      },
      {
        CITY: "OZAN",
      },
      {
        CITY: "OZOIR-LA-FERRIERE",
      },
      {
        CITY: "OZOLLES",
      },
      {
        CITY: "OZOUER-LE-VOULGIS",
      },
      {
        CITY: "PABU",
      },
      {
        CITY: "PACE",
      },
      {
        CITY: "PACT",
      },
      {
        CITY: "PACY-SUR-EURE",
      },
      {
        CITY: "PAGEAS",
      },
      {
        CITY: "PAGNY-LA-VILLE",
      },
      {
        CITY: "PAGNY-SUR-MEUSE",
      },
      {
        CITY: "PAGNY-SUR-MOSELLE",
      },
      {
        CITY: "PAILLART",
      },
      {
        CITY: "PAILLENCOURT",
      },
      {
        CITY: "PAILLET",
      },
      {
        CITY: "PAIMBOEUF",
      },
      {
        CITY: "PAIMPOL",
      },
      {
        CITY: "PAIMPONT",
      },
      {
        CITY: "PAINBLANC",
      },
      {
        CITY: "PAIR-ET-GRANDRUPT",
      },
      {
        CITY: "PAIX",
      },
      {
        CITY: "PAIZAY-LE-CHAPT",
      },
      {
        CITY: "PAIZAY-LE-SEC",
      },
      {
        CITY: "PAIZAY-LE-TORT",
      },
      {
        CITY: "PALAISEAU",
      },
      {
        CITY: "PALAJA",
      },
      {
        CITY: "PALALDA",
      },
      {
        CITY: "PALAMINY",
      },
      {
        CITY: "PALAU-DEL-VIDRE",
      },
      {
        CITY: "PALAVAS-LES-FLOTS",
      },
      {
        CITY: "PALEYRAC",
      },
      {
        CITY: "PALINGES",
      },
      {
        CITY: "PALLEAU",
      },
      {
        CITY: "PALLUAU",
      },
      {
        CITY: "PALLUAU-SUR-INDRE",
      },
      {
        CITY: "PALLUD",
      },
      {
        CITY: "PAMFOU",
      },
      {
        CITY: "PAMIERS",
      },
      {
        CITY: "PAMPROUX",
      },
      {
        CITY: "PANASSAC",
      },
      {
        CITY: "PANAZOL",
      },
      {
        CITY: "PANISSIERES",
      },
      {
        CITY: "PANNECE",
      },
      {
        CITY: "PANNES",
      },
      {
        CITY: "PANOSSAS",
      },
      {
        CITY: "PANTIN",
      },
      {
        CITY: "PANZOULT",
      },
      {
        CITY: "PARADOU",
      },
      {
        CITY: "PARAY-LE-MONIAL",
      },
      {
        CITY: "PARAY-SOUS-BRIAILLES",
      },
      {
        CITY: "PARAY-VIEILLE-POSTE",
      },
      {
        CITY: "PARAZA",
      },
      {
        CITY: "PARCAY-MESLAY",
      },
      {
        CITY: "PARCE-SUR-SARTHE",
      },
      {
        CITY: "PARCIEUX",
      },
      {
        CITY: "PAREDS",
      },
      {
        CITY: "PAREMPUYRE",
      },
      {
        CITY: "PARENT",
      },
      {
        CITY: "PARENTIS-EN-BORN",
      },
      {
        CITY: "PARGNY-FILAIN",
      },
      {
        CITY: "PARGNY-LA-DHUYS",
      },
      {
        CITY: "PARGNY-LES-BOIS",
      },
      {
        CITY: "PARGNY-SUR-SAULX",
      },
      {
        CITY: "PARIGNE",
      },
      {
        CITY: "PARIGNE-SUR-BRAYE",
      },
      {
        CITY: "PARIGNY",
      },
      {
        CITY: "PARIS",
      },
      {
        CITY: "PARIS 17 BATIGNOLLES-MONCEAU",
      },
      {
        CITY: "PARISOT",
      },
      {
        CITY: "PARLY",
      },
      {
        CITY: "PARMAIN",
      },
      {
        CITY: "PARNANS",
      },
      {
        CITY: "PARNAY",
      },
      {
        CITY: "PARNE-SUR-ROC",
      },
      {
        CITY: "PARON",
      },
      {
        CITY: "PARSAC",
      },
      {
        CITY: "PARTHENAY",
      },
      {
        CITY: "PARTHENAY-DE-BRETAGNE",
      },
      {
        CITY: "PARZAC",
      },
      {
        CITY: "PAS-EN-ARTOIS",
      },
      {
        CITY: "PASLIERES",
      },
      {
        CITY: "PASLY",
      },
      {
        CITY: "PASQUES",
      },
      {
        CITY: "PASSEL",
      },
      {
        CITY: "PASSY",
      },
      {
        CITY: "PATAY",
      },
      {
        CITY: "PAU",
      },
      {
        CITY: "PAUILHAC",
      },
      {
        CITY: "PAUILLAC HAUT",
      },
      {
        CITY: "PAUL",
      },
      {
        CITY: "PAULHAC",
      },
      {
        CITY: "PAULHAGUET",
      },
      {
        CITY: "PAULHAN",
      },
      {
        CITY: "PAULX",
      },
      {
        CITY: "PAVIE",
      },
      {
        CITY: "PAVILLY",
      },
      {
        CITY: "PAYRAC",
      },
      {
        CITY: "PAYRIN-AUGMONTEL",
      },
      {
        CITY: "PAYROUX",
      },
      {
        CITY: "PAYZAC",
      },
      {
        CITY: "PAYZAC",
      },
      {
        CITY: "PEAUGRES",
      },
      {
        CITY: "PEAULE",
      },
      {
        CITY: "PEBRAC",
      },
      {
        CITY: "PECHABOU",
      },
      {
        CITY: "PECHBONNIEU",
      },
      {
        CITY: "PECQUENCOURT",
      },
      {
        CITY: "PECQUEUSE",
      },
      {
        CITY: "PEGOMAS",
      },
      {
        CITY: "PEILLAC",
      },
      {
        CITY: "PEILLE",
      },
      {
        CITY: "PEILLON",
      },
      {
        CITY: "PEILLONNEX",
      },
      {
        CITY: "PEIPIN",
      },
      {
        CITY: "PEISEY-NANCROIX",
      },
      {
        CITY: "PELISSANNE",
      },
      {
        CITY: "PELLEGRUE",
      },
      {
        CITY: "PELLEVOISIN",
      },
      {
        CITY: "PELLOUAILLES-LES-VIGNES",
      },
      {
        CITY: "PELOUSEY",
      },
      {
        CITY: "PELTRE",
      },
      {
        CITY: "PELUSSIN",
      },
      {
        CITY: "PENCHARD",
      },
      {
        CITY: "PENCRAN",
      },
      {
        CITY: "PENDE",
      },
      {
        CITY: "PENESTIN",
      },
      {
        CITY: "PENGUILY",
      },
      {
        CITY: "PENMARCH",
      },
      {
        CITY: "PENNAUTIER",
      },
      {
        CITY: "PENNE-DAGENAIS",
      },
      {
        CITY: "PENNEDEPIE",
      },
      {
        CITY: "PENOL",
      },
      {
        CITY: "PENTA-DI-CASINCA",
      },
      {
        CITY: "PENVENAN",
      },
      {
        CITY: "PEPIEUX",
      },
      {
        CITY: "PERAY",
      },
      {
        CITY: "PERCY",
      },
      {
        CITY: "PERE",
      },
      {
        CITY: "PERENCHIES",
      },
      {
        CITY: "PERET",
      },
      {
        CITY: "PERI",
      },
      {
        CITY: "PERIERS",
      },
      {
        CITY: "PERIGNAC",
      },
      {
        CITY: "PERIGNAT-LES-SARLIEVE",
      },
      {
        CITY: "PERIGNE",
      },
      {
        CITY: "PERIGNEUX",
      },
      {
        CITY: "PERIGNY",
      },
      {
        CITY: "PERIGNY",
      },
      {
        CITY: "PERILLOS",
      },
      {
        CITY: "PERN",
      },
      {
        CITY: "PERNAY",
      },
      {
        CITY: "PERNES",
      },
      {
        CITY: "PERNES-LES-BOULOGNE",
      },
      {
        CITY: "PERNES-LES-FONTAINES",
      },
      {
        CITY: "PEROLS",
      },
      {
        CITY: "PEROLS",
      },
      {
        CITY: "PERON",
      },
      {
        CITY: "PERONNAS",
      },
      {
        CITY: "PERONNE",
      },
      {
        CITY: "PERONNE",
      },
      {
        CITY: "PERONNE-EN-MELANTOIS",
      },
      {
        CITY: "PEROUSE",
      },
      {
        CITY: "PEROY-LES-GOMBRIES",
      },
      {
        CITY: "PERPEZAC-LE-NOIR",
      },
      {
        CITY: "PERPIGNAN",
      },
      {
        CITY: "PERRECY-LES-FORGES",
      },
      {
        CITY: "PERREUX",
      },
      {
        CITY: "PERREUX",
      },
      {
        CITY: "PERREX",
      },
      {
        CITY: "PERRIER",
      },
      {
        CITY: "PERRIERS-SUR-ANDELLE",
      },
      {
        CITY: "PERRIGNIER",
      },
      {
        CITY: "PERRIGNY",
      },
      {
        CITY: "PERRIGNY-LES-DIJON",
      },
      {
        CITY: "PERROGNEY-LES-FONTAINES",
      },
      {
        CITY: "PERROS-GUIREC",
      },
      {
        CITY: "PERROU",
      },
      {
        CITY: "PERRUEL",
      },
      {
        CITY: "PERS",
      },
      {
        CITY: "PERS-JUSSY",
      },
      {
        CITY: "PERSAN",
      },
      {
        CITY: "PERSQUEN",
      },
      {
        CITY: "PERTHES-LES-BRIENNE",
      },
      {
        CITY: "PERTHEVILLE-NERS",
      },
      {
        CITY: "PERTUIS",
      },
      {
        CITY: "PESSAC",
      },
      {
        CITY: "PESSAC-SUR-DORDOGNE",
      },
      {
        CITY: "PESSAN",
      },
      {
        CITY: "PESSANS",
      },
      {
        CITY: "PESSAT-VILLENEUVE",
      },
      {
        CITY: "PETIT CHAUMONT",
      },
      {
        CITY: "PETIT-COURONNE",
      },
      {
        CITY: "PETIT-LANDAU",
      },
      {
        CITY: "PETIT-MARS",
      },
      {
        CITY: "PETIT-PALAIS-ET-CORNEMPS",
      },
      {
        CITY: "PETITE-FORET",
      },
      {
        CITY: "PETITE-ROSSELLE",
      },
      {
        CITY: "PETIVILLE",
      },
      {
        CITY: "PETRETO-BICCHISANO",
      },
      {
        CITY: "PEUMERIT",
      },
      {
        CITY: "PEXONNE",
      },
      {
        CITY: "PEY",
      },
      {
        CITY: "PEYMEINADE",
      },
      {
        CITY: "PEYNIER",
      },
      {
        CITY: "PEYPIN",
      },
      {
        CITY: "PEYRAT-LE-CHATEAU",
      },
      {
        CITY: "PEYREHORADE",
      },
      {
        CITY: "PEYRIAC-DE-MER",
      },
      {
        CITY: "PEYRIAC-MINERVOIS",
      },
      {
        CITY: "PEYRIGNAC",
      },
      {
        CITY: "PEYRILHAC",
      },
      {
        CITY: "PEYRILLES",
      },
      {
        CITY: "PEYRINS",
      },
      {
        CITY: "PEYRISSAS",
      },
      {
        CITY: "PEYROLLES-EN-PROVENCE",
      },
      {
        CITY: "PEYRUIS",
      },
      {
        CITY: "PEYRUS",
      },
      {
        CITY: "PEYRUSSE-MASSAS",
      },
      {
        CITY: "PEZENAS",
      },
      {
        CITY: "PEZENS",
      },
      {
        CITY: "PEZILLA-LA-RIVIERE",
      },
      {
        CITY: "PEZOU",
      },
      {
        CITY: "PFAFFENHEIM",
      },
      {
        CITY: "PFAFFENHOFFEN",
      },
      {
        CITY: "PFASTATT",
      },
      {
        CITY: "PFETTISHEIM",
      },
      {
        CITY: "PFULGRIESHEIM",
      },
      {
        CITY: "PHAFFANS",
      },
      {
        CITY: "PHALEMPIN",
      },
      {
        CITY: "PHALSBOURG",
      },
      {
        CITY: "PIA",
      },
      {
        CITY: "PIAZZA",
      },
      {
        CITY: "PIBLANGE",
      },
      {
        CITY: "PIBRAC",
      },
      {
        CITY: "PICAUVILLE",
      },
      {
        CITY: "PICQUIGNY",
      },
      {
        CITY: "PIEGROS-LA-CLASTRE",
      },
      {
        CITY: "PIEGUT-PLUVIERS",
      },
      {
        CITY: "PIENNES",
      },
      {
        CITY: "PIERRE",
      },
      {
        CITY: "PIERRE-BENITE",
      },
      {
        CITY: "PIERRE-DE-BRESSE",
      },
      {
        CITY: "PIERRE-LA-TREICHE",
      },
      {
        CITY: "PIERRE-LEVEE",
      },
      {
        CITY: "PIERREFEU-DU-VAR",
      },
      {
        CITY: "PIERREFITTE-NESTALAS",
      },
      {
        CITY: "PIERREFITTE-SUR-SEINE",
      },
      {
        CITY: "PIERREFONDS",
      },
      {
        CITY: "PIERREFORT",
      },
      {
        CITY: "PIERREGOT",
      },
      {
        CITY: "PIERRELATTE",
      },
      {
        CITY: "PIERRELAYE",
      },
      {
        CITY: "PIERREMANDE",
      },
      {
        CITY: "PIERREPONT-SUR-AVRE",
      },
      {
        CITY: "PIERRERUE",
      },
      {
        CITY: "PIERRES",
      },
      {
        CITY: "PIERREVERT",
      },
      {
        CITY: "PIERREVILLERS",
      },
      {
        CITY: "PIERRIC",
      },
      {
        CITY: "PIERRY",
      },
      {
        CITY: "PIETRALBA",
      },
      {
        CITY: "PIEUSSE",
      },
      {
        CITY: "PIGNAN",
      },
      {
        CITY: "PIGNANS",
      },
      {
        CITY: "PIGNOLS",
      },
      {
        CITY: "PIHEM",
      },
      {
        CITY: "PIMBO",
      },
      {
        CITY: "PIMPREZ",
      },
      {
        CITY: "PIN",
      },
      {
        CITY: "PINET",
      },
      {
        CITY: "PINEUILH",
      },
      {
        CITY: "PINEY",
      },
      {
        CITY: "PINON",
      },
      {
        CITY: "PINSAGUEL",
      },
      {
        CITY: "PIOLENC",
      },
      {
        CITY: "PIONSAT",
      },
      {
        CITY: "PIPRIAC",
      },
      {
        CITY: "PIQUECOS",
      },
      {
        CITY: "PIRE-SUR-SEICHE",
      },
      {
        CITY: "PIREY",
      },
      {
        CITY: "PIROU",
      },
      {
        CITY: "PISCOP",
      },
      {
        CITY: "PISEUX",
      },
      {
        CITY: "PISSOS",
      },
      {
        CITY: "PISSOTTE",
      },
      {
        CITY: "PISSY-POVILLE",
      },
      {
        CITY: "PITHIVIERS",
      },
      {
        CITY: "PITRES",
      },
      {
        CITY: "PIZAY",
      },
      {
        CITY: "PLABENNEC",
      },
      {
        CITY: "PLACE",
      },
      {
        CITY: "PLAILLY",
      },
      {
        CITY: "PLAIMPIED-GIVAUDINS",
      },
      {
        CITY: "PLAINE-DE-WALSCH",
      },
      {
        CITY: "PLAINE-HAUTE",
      },
      {
        CITY: "PLAINTEL",
      },
      {
        CITY: "PLAISANCE",
      },
      {
        CITY: "PLAISANCE-DU-TOUCH",
      },
      {
        CITY: "PLAISIR",
      },
      {
        CITY: "PLAN-DE-CUQUES",
      },
      {
        CITY: "PLANAY",
      },
      {
        CITY: "PLANCHER-BAS",
      },
      {
        CITY: "PLANCHES",
      },
      {
        CITY: "PLANGUENOUAL",
      },
      {
        CITY: "PLANZOLLES",
      },
      {
        CITY: "PLAPPEVILLE",
      },
      {
        CITY: "PLASSAY",
      },
      {
        CITY: "PLATEAU",
      },
      {
        CITY: "PLAUDREN",
      },
      {
        CITY: "PLAUZAT",
      },
      {
        CITY: "PLEBOULLE",
      },
      {
        CITY: "PLECHATEL",
      },
      {
        CITY: "PLEDRAN",
      },
      {
        CITY: "PLEHEDEL",
      },
      {
        CITY: "PLELAN-LE-GRAND",
      },
      {
        CITY: "PLELAN-LE-PETIT",
      },
      {
        CITY: "PLELAUFF",
      },
      {
        CITY: "PLELO",
      },
      {
        CITY: "PLEMET",
      },
      {
        CITY: "PLENEE-JUGON",
      },
      {
        CITY: "PLENEUF-VAL-ANDRE",
      },
      {
        CITY: "PLERGUER",
      },
      {
        CITY: "PLERIN",
      },
      {
        CITY: "PLERNEUF",
      },
      {
        CITY: "PLESCOP",
      },
      {
        CITY: "PLESIDY",
      },
      {
        CITY: "PLESNOIS",
      },
      {
        CITY: "PLESSALA",
      },
      {
        CITY: "PLESSE",
      },
      {
        CITY: "PLESSIS-BARBUISE",
      },
      {
        CITY: "PLESSIS-DE-ROYE",
      },
      {
        CITY: "PLESTAN",
      },
      {
        CITY: "PLESTIN-LES-GREVES",
      },
      {
        CITY: "PLEUCADEUC",
      },
      {
        CITY: "PLEUDIHEN-SUR-RANCE",
      },
      {
        CITY: "PLEUGRIFFET",
      },
      {
        CITY: "PLEUGUENEUC",
      },
      {
        CITY: "PLEUMARTIN",
      },
      {
        CITY: "PLEUMELEUC",
      },
      {
        CITY: "PLEUMEUR-BODOU",
      },
      {
        CITY: "PLEURE",
      },
      {
        CITY: "PLEURS",
      },
      {
        CITY: "PLEURTUIT",
      },
      {
        CITY: "PLEUVEN",
      },
      {
        CITY: "PLEYBEN",
      },
      {
        CITY: "PLEYBER-CHRIST",
      },
      {
        CITY: "PLOBANNALEC-LESCONIL",
      },
      {
        CITY: "PLOBSHEIM",
      },
      {
        CITY: "PLOEMEL",
      },
      {
        CITY: "PLOEMEUR",
      },
      {
        CITY: "PLOERDUT",
      },
      {
        CITY: "PLOEREN",
      },
      {
        CITY: "PLOERMEL",
      },
      {
        CITY: "PLOEUC-SUR-LIE",
      },
      {
        CITY: "PLOEVEN",
      },
      {
        CITY: "PLOGONNEC",
      },
      {
        CITY: "PLOMB",
      },
      {
        CITY: "PLOMBIERES-LES-BAINS",
      },
      {
        CITY: "PLOMBIERES-LES-DIJON",
      },
      {
        CITY: "PLOMELIN",
      },
      {
        CITY: "PLOMEUR",
      },
      {
        CITY: "PLONEVEZ-DU-FAOU",
      },
      {
        CITY: "PLOREC-SUR-ARGUENON",
      },
      {
        CITY: "PLOTTES",
      },
      {
        CITY: "PLOUAGAT",
      },
      {
        CITY: "PLOUARET",
      },
      {
        CITY: "PLOUARZEL",
      },
      {
        CITY: "PLOUASNE",
      },
      {
        CITY: "PLOUAY",
      },
      {
        CITY: "PLOUBALAY",
      },
      {
        CITY: "PLOUBAZLANEC",
      },
      {
        CITY: "PLOUBEZRE",
      },
      {
        CITY: "PLOUDALMEZEAU",
      },
      {
        CITY: "PLOUDANIEL",
      },
      {
        CITY: "PLOUEC-DU-TRIEUX",
      },
      {
        CITY: "PLOUEDERN",
      },
      {
        CITY: "PLOUER-SUR-RANCE",
      },
      {
        CITY: "PLOUESCAT",
      },
      {
        CITY: "PLOUEZEC",
      },
      {
        CITY: "PLOUFRAGAN",
      },
      {
        CITY: "PLOUGAR",
      },
      {
        CITY: "PLOUGASNOU",
      },
      {
        CITY: "PLOUGASTEL-DAOULAS",
      },
      {
        CITY: "PLOUGONVELIN",
      },
      {
        CITY: "PLOUGONVEN",
      },
      {
        CITY: "PLOUGOULM",
      },
      {
        CITY: "PLOUGOUMELEN",
      },
      {
        CITY: "PLOUGOURVEST",
      },
      {
        CITY: "PLOUGRESCANT",
      },
      {
        CITY: "PLOUGUERNEAU",
      },
      {
        CITY: "PLOUGUIN",
      },
      {
        CITY: "PLOUHA",
      },
      {
        CITY: "PLOUHARNEL",
      },
      {
        CITY: "PLOUIGNEAU",
      },
      {
        CITY: "PLOUMAGOAR",
      },
      {
        CITY: "PLOUMILLIAU",
      },
      {
        CITY: "PLOUMOGUER",
      },
      {
        CITY: "PLOUNEOUR-MENEZ",
      },
      {
        CITY: "PLOUNEOUR-TREZ",
      },
      {
        CITY: "PLOUNERIN",
      },
      {
        CITY: "PLOUNEVEZ-LOCHRIST",
      },
      {
        CITY: "PLOUNEVEZEL",
      },
      {
        CITY: "PLOURIN",
      },
      {
        CITY: "PLOURIVO",
      },
      {
        CITY: "PLOUVAIN",
      },
      {
        CITY: "PLOUVARA",
      },
      {
        CITY: "PLOUVIEN",
      },
      {
        CITY: "PLOUVORN",
      },
      {
        CITY: "PLOUZANE",
      },
      {
        CITY: "PLOZEVET",
      },
      {
        CITY: "PLUDUAL",
      },
      {
        CITY: "PLUDUNO",
      },
      {
        CITY: "PLUGUFFAN",
      },
      {
        CITY: "PLUHERLIN",
      },
      {
        CITY: "PLUMAUGAT",
      },
      {
        CITY: "PLUMELIAU",
      },
      {
        CITY: "PLUMELIN",
      },
      {
        CITY: "PLUMERGAT",
      },
      {
        CITY: "PLUMOISON",
      },
      {
        CITY: "PLUNERET",
      },
      {
        CITY: "PLURIEN",
      },
      {
        CITY: "PLUSQUELLEC",
      },
      {
        CITY: "PLUSSULIEN",
      },
      {
        CITY: "PLUVAULT",
      },
      {
        CITY: "PLUVIGNER",
      },
      {
        CITY: "PLUZUNET",
      },
      {
        CITY: "POCE-SUR-CISSE",
      },
      {
        CITY: "PODENSAC",
      },
      {
        CITY: "POEUILLY",
      },
      {
        CITY: "POEY-DE-LESCAR",
      },
      {
        CITY: "POGGIO-DOLETTA",
      },
      {
        CITY: "POGNY",
      },
      {
        CITY: "POIGNY-LA-FORET",
      },
      {
        CITY: "POILLY-LEZ-GIEN",
      },
      {
        CITY: "POILLY-SUR-THOLON",
      },
      {
        CITY: "POINSENOT",
      },
      {
        CITY: "POINTIS-INARD",
      },
      {
        CITY: "POISAT",
      },
      {
        CITY: "POISEUL-LES-SAULX",
      },
      {
        CITY: "POISIEUX",
      },
      {
        CITY: "POISSON",
      },
      {
        CITY: "POISSONS",
      },
      {
        CITY: "POISSY",
      },
      {
        CITY: "POISY",
      },
      {
        CITY: "POITIERS",
      },
      {
        CITY: "POIX",
      },
      {
        CITY: "POIX-DE-PICARDIE",
      },
      {
        CITY: "POIX-DU-NORD",
      },
      {
        CITY: "POLIGNY",
      },
      {
        CITY: "POLIGNY",
      },
      {
        CITY: "POLLESTRES",
      },
      {
        CITY: "POLLIAT",
      },
      {
        CITY: "POMACLE",
      },
      {
        CITY: "POMAREZ",
      },
      {
        CITY: "POMEROLS",
      },
      {
        CITY: "POMEYS",
      },
      {
        CITY: "POMMARD",
      },
      {
        CITY: "POMMERET",
      },
      {
        CITY: "POMMERIT-JAUDY",
      },
      {
        CITY: "POMMERIT-LE-VICOMTE",
      },
      {
        CITY: "POMMEUSE",
      },
      {
        CITY: "POMMEVIC",
      },
      {
        CITY: "POMMIERS",
      },
      {
        CITY: "POMMIERS-LA-PLACETTE",
      },
      {
        CITY: "POMPADOUR",
      },
      {
        CITY: "POMPAIRE",
      },
      {
        CITY: "POMPERTUZAT",
      },
      {
        CITY: "POMPEY",
      },
      {
        CITY: "POMPIDOU",
      },
      {
        CITY: "POMPIGNAN",
      },
      {
        CITY: "POMPONNE",
      },
      {
        CITY: "POMPORT",
      },
      {
        CITY: "PONCEY-LES-ATHEE",
      },
      {
        CITY: "PONCHON",
      },
      {
        CITY: "PONCIN",
      },
      {
        CITY: "PONCINS",
      },
      {
        CITY: "PONS",
      },
      {
        CITY: "PONS",
      },
      {
        CITY: "PONT",
      },
      {
        CITY: "PONT-A-MARCQ",
      },
      {
        CITY: "PONT-A-VENDIN",
      },
      {
        CITY: "PONT-AUDEMER",
      },
      {
        CITY: "PONT-AVEN",
      },
      {
        CITY: "PONT-DE-BARRET",
      },
      {
        CITY: "PONT-DE-BEAUVOISIN",
      },
      {
        CITY: "PONT-DE-BRIQUES",
      },
      {
        CITY: "PONT-DE-BUIS-LES-QUIMERCH",
      },
      {
        CITY: "PONT-DE-CHERUY",
      },
      {
        CITY: "PONT-DE-LABEAUME",
      },
      {
        CITY: "PONT-DE-LARN",
      },
      {
        CITY: "PONT-DE-METZ",
      },
      {
        CITY: "PONT-DE-ROIDE",
      },
      {
        CITY: "PONT-DE-SALARS",
      },
      {
        CITY: "PONT-DE-VAUX",
      },
      {
        CITY: "PONT-DE-VEYLE",
      },
      {
        CITY: "PONT-DU-CASSE",
      },
      {
        CITY: "PONT-DU-CHATEAU",
      },
      {
        CITY: "PONT-EN-ROYANS",
      },
      {
        CITY: "PONT-EVEQUE",
      },
      {
        CITY: "PONT-HEBERT",
      },
      {
        CITY: "PONT-NOYELLES",
      },
      {
        CITY: "PONT-REMY",
      },
      {
        CITY: "PONT-SAINT-ESPRIT",
      },
      {
        CITY: "PONT-SAINT-MARD",
      },
      {
        CITY: "PONT-SAINT-MARTIN",
      },
      {
        CITY: "PONT-SAINT-PIERRE",
      },
      {
        CITY: "PONT-SAINT-VINCENT",
      },
      {
        CITY: "PONT-SAINTE-MARIE",
      },
      {
        CITY: "PONT-SAINTE-MAXENCE",
      },
      {
        CITY: "PONT-SALOMON",
      },
      {
        CITY: "PONT-SCORFF",
      },
      {
        CITY: "PONT-SUR-SAMBRE",
      },
      {
        CITY: "PONT-SUR-SEINE",
      },
      {
        CITY: "PONT-SUR-YONNE",
      },
      {
        CITY: "PONTACQ",
      },
      {
        CITY: "PONTAILLER-SUR-SAONE",
      },
      {
        CITY: "PONTARLIER",
      },
      {
        CITY: "PONTARME",
      },
      {
        CITY: "PONTAUBAULT",
      },
      {
        CITY: "PONTAULT-COMBAULT",
      },
      {
        CITY: "PONTAUMUR",
      },
      {
        CITY: "PONTAUT",
      },
      {
        CITY: "PONTAVERT",
      },
      {
        CITY: "PONTCARRE",
      },
      {
        CITY: "PONTCHARRA",
      },
      {
        CITY: "PONTCHARRA-SUR-TURDINE",
      },
      {
        CITY: "PONTCHATEAU",
      },
      {
        CITY: "PONTCIRQ",
      },
      {
        CITY: "PONTE-LECCIA",
      },
      {
        CITY: "PONTEILLA",
      },
      {
        CITY: "PONTENX-LES-FORGES",
      },
      {
        CITY: "PONTGIBAUD",
      },
      {
        CITY: "PONTGOUIN",
      },
      {
        CITY: "PONTHEVRARD",
      },
      {
        CITY: "PONTHIERRY",
      },
      {
        CITY: "PONTIGNE",
      },
      {
        CITY: "PONTIGNY",
      },
      {
        CITY: "PONTIVY",
      },
      {
        CITY: "PONTLEVOY",
      },
      {
        CITY: "PONTOISE",
      },
      {
        CITY: "PONTORSON",
      },
      {
        CITY: "PONTOURS",
      },
      {
        CITY: "PONTPOINT",
      },
      {
        CITY: "PONTRUET",
      },
      {
        CITY: "PONTS",
      },
      {
        CITY: "PONTVALLAIN",
      },
      {
        CITY: "POPIAN",
      },
      {
        CITY: "PORCELETTE",
      },
      {
        CITY: "PORCHERES",
      },
      {
        CITY: "PORCHERESSE",
      },
      {
        CITY: "PORCHEVILLE",
      },
      {
        CITY: "PORCIEU-AMBLAGNIEU",
      },
      {
        CITY: "PORDIC",
      },
      {
        CITY: "PORNIC",
      },
      {
        CITY: "PORNICHET",
      },
      {
        CITY: "PORT-BRILLET",
      },
      {
        CITY: "PORT-CROS",
      },
      {
        CITY: "PORT-DE-BOUC",
      },
      {
        CITY: "PORT-DES-BARQUES",
      },
      {
        CITY: "PORT-EN-BESSIN-HUPPAIN",
      },
      {
        CITY: "PORT-JOINVILLE",
      },
      {
        CITY: "PORT-LA-NOUVELLE",
      },
      {
        CITY: "PORT-LAUNAY",
      },
      {
        CITY: "PORT-LOUIS",
      },
      {
        CITY: "PORT-MORT",
      },
      {
        CITY: "PORT-SAINT-LOUIS-DU-RHONE",
      },
      {
        CITY: "PORT-SAINT-PERE",
      },
      {
        CITY: "PORT-SAINTE-FOY-ET-PONCHAPT",
      },
      {
        CITY: "PORT-SAINTE-MARIE",
      },
      {
        CITY: "PORT-SUR-SAONE",
      },
      {
        CITY: "PORTBAIL",
      },
      {
        CITY: "PORTEL-DES-CORBIERES",
      },
      {
        CITY: "PORTES-LES-VALENCE",
      },
      {
        CITY: "PORTET-SUR-GARONNE",
      },
      {
        CITY: "PORTETS",
      },
      {
        CITY: "PORTICCIO",
      },
      {
        CITY: "PORTIEUX",
      },
      {
        CITY: "PORTIRAGNES",
      },
      {
        CITY: "PORTO",
      },
      {
        CITY: "PORTO-VECCHIO",
      },
      {
        CITY: "PORTRIEUX",
      },
      {
        CITY: "PORTS",
      },
      {
        CITY: "PORTVENDRES",
      },
      {
        CITY: "POSES",
      },
      {
        CITY: "POTIGNY",
      },
      {
        CITY: "POUAN-LES-VALLEES",
      },
      {
        CITY: "POUANCE",
      },
      {
        CITY: "POUFFONDS",
      },
      {
        CITY: "POUGNY",
      },
      {
        CITY: "POUGUES-LES-EAUX",
      },
      {
        CITY: "POUGY",
      },
      {
        CITY: "POUILLE",
      },
      {
        CITY: "POUILLE",
      },
      {
        CITY: "POUILLENAY",
      },
      {
        CITY: "POUILLEY-FRANCAIS",
      },
      {
        CITY: "POUILLEY-LES-VIGNES",
      },
      {
        CITY: "POUILLON",
      },
      {
        CITY: "POUILLOUX",
      },
      {
        CITY: "POUILLY",
      },
      {
        CITY: "POUILLY",
      },
      {
        CITY: "POUILLY-EN-AUXOIS",
      },
      {
        CITY: "POUILLY-LE-MONIAL",
      },
      {
        CITY: "POUILLY-LES-NONAINS",
      },
      {
        CITY: "POUILLY-SOUS-CHARLIEU",
      },
      {
        CITY: "POUILLY-SUR-LOIRE",
      },
      {
        CITY: "POUILLY-SUR-SERRE",
      },
      {
        CITY: "POUILLY-SUR-VINGEANNE",
      },
      {
        CITY: "POULAINVILLE",
      },
      {
        CITY: "POULAN-POUZOLS",
      },
      {
        CITY: "POULDERGAT",
      },
      {
        CITY: "POULDREUZIC",
      },
      {
        CITY: "POULE-LES-ECHARMEAUX",
      },
      {
        CITY: "POULIGNEY-LUSANS",
      },
      {
        CITY: "POULLAN-SUR-MER",
      },
      {
        CITY: "POULLAOUEN",
      },
      {
        CITY: "POULX",
      },
      {
        CITY: "POURNOY-LA-GRASSE",
      },
      {
        CITY: "POURRAIN",
      },
      {
        CITY: "POURRIERES",
      },
      {
        CITY: "POURU-SAINT-REMY",
      },
      {
        CITY: "POUSSAN",
      },
      {
        CITY: "POUXEUX",
      },
      {
        CITY: "POUYDESSEAUX",
      },
      {
        CITY: "POUZAUGES",
      },
      {
        CITY: "POUZAY",
      },
      {
        CITY: "POUZILHAC",
      },
      {
        CITY: "POUZOLLES",
      },
      {
        CITY: "POUZOLS-MINERVOIS",
      },
      {
        CITY: "POVILLE",
      },
      {
        CITY: "PRADELLES",
      },
      {
        CITY: "PRADES",
      },
      {
        CITY: "PRADES-LE-LEZ",
      },
      {
        CITY: "PRADIERES",
      },
      {
        CITY: "PRAHECQ",
      },
      {
        CITY: "PRALOGNAN-LA-VANOISE",
      },
      {
        CITY: "PRANZAC",
      },
      {
        CITY: "PRASVILLE",
      },
      {
        CITY: "PRAT",
      },
      {
        CITY: "PRATS-DE-CARLUX",
      },
      {
        CITY: "PRAY",
      },
      {
        CITY: "PRAYSSAC",
      },
      {
        CITY: "PRAYSSAS",
      },
      {
        CITY: "PRAZ",
      },
      {
        CITY: "PRAZ-SUR-ARLY",
      },
      {
        CITY: "PRE-EN-PAIL",
      },
      {
        CITY: "PREAUX",
      },
      {
        CITY: "PRECEY",
      },
      {
        CITY: "PRECHAC",
      },
      {
        CITY: "PRECHAC",
      },
      {
        CITY: "PRECIEUX",
      },
      {
        CITY: "PRECIGNE",
      },
      {
        CITY: "PRECORBIN",
      },
      {
        CITY: "PRECY-SOUS-THIL",
      },
      {
        CITY: "PRECY-SUR-MARNE",
      },
      {
        CITY: "PRECY-SUR-OISE",
      },
      {
        CITY: "PRECY-SUR-VRIN",
      },
      {
        CITY: "PREFAILLES",
      },
      {
        CITY: "PREFONTAINES",
      },
      {
        CITY: "PREGUILLAC",
      },
      {
        CITY: "PREIGNAC",
      },
      {
        CITY: "PREIGNAN",
      },
      {
        CITY: "PREMANON",
      },
      {
        CITY: "PREMERY",
      },
      {
        CITY: "PREMIAN",
      },
      {
        CITY: "PREMONTRE",
      },
      {
        CITY: "PRESEAU",
      },
      {
        CITY: "PRESLES-EN-BRIE",
      },
      {
        CITY: "PRESLES-ET-THIERNY",
      },
      {
        CITY: "PRESSAGNY-LE-VAL",
      },
      {
        CITY: "PRESSIGNAC-VICQ",
      },
      {
        CITY: "PRESSIGNY",
      },
      {
        CITY: "PRESSIGNY-LES-PINS",
      },
      {
        CITY: "PRETOT-VICQUEMARE",
      },
      {
        CITY: "PREUSCHDORF",
      },
      {
        CITY: "PREVESSIN-MOENS",
      },
      {
        CITY: "PREY",
      },
      {
        CITY: "PRIGONRIEUX",
      },
      {
        CITY: "PRIMARETTE",
      },
      {
        CITY: "PRIMAT",
      },
      {
        CITY: "PRIN-DEYRANCON",
      },
      {
        CITY: "PRINCAY",
      },
      {
        CITY: "PRINGY",
      },
      {
        CITY: "PRINGY",
      },
      {
        CITY: "PRINQUIAU",
      },
      {
        CITY: "PRISSE",
      },
      {
        CITY: "PRIVAS",
      },
      {
        CITY: "PRIX",
      },
      {
        CITY: "PRIX-LES-MEZIERES",
      },
      {
        CITY: "PROISSANS",
      },
      {
        CITY: "PROMPSAT",
      },
      {
        CITY: "PRONVILLE",
      },
      {
        CITY: "PROPRIANO",
      },
      {
        CITY: "PROUAIS",
      },
      {
        CITY: "PROUILLY",
      },
      {
        CITY: "PROUVY",
      },
      {
        CITY: "PROUZEL",
      },
      {
        CITY: "PROVENCHERES-SUR-FAVE",
      },
      {
        CITY: "PROVENCHERES-SUR-MEUSE",
      },
      {
        CITY: "PROVEYSIEUX",
      },
      {
        CITY: "PROVILLE",
      },
      {
        CITY: "PROVIN",
      },
      {
        CITY: "PROVINS",
      },
      {
        CITY: "PROYART",
      },
      {
        CITY: "PRUDHOMAT",
      },
      {
        CITY: "PRUNAY-CASSEREAU",
      },
      {
        CITY: "PRUNAY-LE-GILLON",
      },
      {
        CITY: "PRUNAY-SUR-ESSONNE",
      },
      {
        CITY: "PRUNELLI-DI-FIUMORBO",
      },
      {
        CITY: "PRUNIERES",
      },
      {
        CITY: "PRUNIERS",
      },
      {
        CITY: "PRUZILLY",
      },
      {
        CITY: "PUBLIER",
      },
      {
        CITY: "PUCEUL",
      },
      {
        CITY: "PUCHEVILLERS",
      },
      {
        CITY: "PUGET-SUR-ARGENS",
      },
      {
        CITY: "PUGET-VILLE",
      },
      {
        CITY: "PUGEY",
      },
      {
        CITY: "PUGIEU",
      },
      {
        CITY: "PUGNAC",
      },
      {
        CITY: "PUGNY-CHATENOD",
      },
      {
        CITY: "PUICHERIC",
      },
      {
        CITY: "PUIHARDY",
      },
      {
        CITY: "PUILACHER",
      },
      {
        CITY: "PUILBOREAU",
      },
      {
        CITY: "PUIMICHEL",
      },
      {
        CITY: "PUISEAUX",
      },
      {
        CITY: "PUISEUX-EN-FRANCE",
      },
      {
        CITY: "PUISEUX-LE-HAUBERGER",
      },
      {
        CITY: "PUISIEULX",
      },
      {
        CITY: "PUISIEUX",
      },
      {
        CITY: "PUISSALICON",
      },
      {
        CITY: "PUISSEGUIN",
      },
      {
        CITY: "PUISSERGUIER",
      },
      {
        CITY: "PUJAUDRAN",
      },
      {
        CITY: "PUJAUT",
      },
      {
        CITY: "PUJO-LE-PLAN",
      },
      {
        CITY: "PUJOLS",
      },
      {
        CITY: "PULIGNY-MONTRACHET",
      },
      {
        CITY: "PULLIGNY",
      },
      {
        CITY: "PULNOY",
      },
      {
        CITY: "PULVERSHEIM",
      },
      {
        CITY: "PUSIGNAN",
      },
      {
        CITY: "PUSSAY",
      },
      {
        CITY: "PUTEAUX",
      },
      {
        CITY: "PUTOT-EN-BESSIN",
      },
      {
        CITY: "PUTTELANGE-LES-THIONVILLE",
      },
      {
        CITY: "PUY-GUILLAUME",
      },
      {
        CITY: "PUY-SAINT-ANDRE",
      },
      {
        CITY: "PUY-SAINT-MARTIN",
      },
      {
        CITY: "PUYBEGON",
      },
      {
        CITY: "PUYBRUN",
      },
      {
        CITY: "PUYCELSI",
      },
      {
        CITY: "PUYCORNET",
      },
      {
        CITY: "PUYGOUZON",
      },
      {
        CITY: "PUYGROS",
      },
      {
        CITY: "PUYLAURENS",
      },
      {
        CITY: "PUYLOUBIER",
      },
      {
        CITY: "PUYMICLAN",
      },
      {
        CITY: "PUYRAVAULT",
      },
      {
        CITY: "PUYRAVAULT",
      },
      {
        CITY: "PUYREAUX",
      },
      {
        CITY: "PUYRICARD",
      },
      {
        CITY: "QUAEDYPRE",
      },
      {
        CITY: "QUAROUBLE",
      },
      {
        CITY: "QUARRE-LES-TOMBES",
      },
      {
        CITY: "QUATREMARE",
      },
      {
        CITY: "QUEAUX",
      },
      {
        CITY: "QUEBRIAC",
      },
      {
        CITY: "QUEDILLAC",
      },
      {
        CITY: "QUEIGE",
      },
      {
        CITY: "QUELAINES-SAINT-GAULT",
      },
      {
        CITY: "QUELNEUC",
      },
      {
        CITY: "QUENOCHE",
      },
      {
        CITY: "QUERENAING",
      },
      {
        CITY: "QUERNES",
      },
      {
        CITY: "QUERQUEVILLE",
      },
      {
        CITY: "QUERRIEN",
      },
      {
        CITY: "QUERRIEU",
      },
      {
        CITY: "QUERS",
      },
      {
        CITY: "QUESNOY-SUR-DEULE",
      },
      {
        CITY: "QUESSOY",
      },
      {
        CITY: "QUESTEMBERT",
      },
      {
        CITY: "QUETIGNY",
      },
      {
        CITY: "QUETTEHOU",
      },
      {
        CITY: "QUETTREVILLE-SUR-SIENNE",
      },
      {
        CITY: "QUEVAUVILLERS",
      },
      {
        CITY: "QUEVEN",
      },
      {
        CITY: "QUEVERT",
      },
      {
        CITY: "QUEVILLON",
      },
      {
        CITY: "QUEYRAC",
      },
      {
        CITY: "QUEYRIERES",
      },
      {
        CITY: "QUEZAC",
      },
      {
        CITY: "QUIBERON",
      },
      {
        CITY: "QUIBERVILLE",
      },
      {
        CITY: "QUIBOU",
      },
      {
        CITY: "QUIERS",
      },
      {
        CITY: "QUIEVRECHAIN",
      },
      {
        CITY: "QUIEVRECOURT",
      },
      {
        CITY: "QUIEVY",
      },
      {
        CITY: "QUILLAN",
      },
      {
        CITY: "QUILLEBEUF-SUR-SEINE",
      },
      {
        CITY: "QUILLY",
      },
      {
        CITY: "QUILLY",
      },
      {
        CITY: "QUILY",
      },
      {
        CITY: "QUIMPER",
      },
      {
        CITY: "QUINCAMPOIX",
      },
      {
        CITY: "QUINCIE-EN-BEAUJOLAIS",
      },
      {
        CITY: "QUINCIEU",
      },
      {
        CITY: "QUINCY",
      },
      {
        CITY: "QUINCY-SOUS-SENART",
      },
      {
        CITY: "QUINCY-VOISINS",
      },
      {
        CITY: "QUINEVILLE",
      },
      {
        CITY: "QUINGEY",
      },
      {
        CITY: "QUINSAC",
      },
      {
        CITY: "QUINT-FONSEGRIVES",
      },
      {
        CITY: "QUINTENIC",
      },
      {
        CITY: "QUINTIN",
      },
      {
        CITY: "QUISSAC",
      },
      {
        CITY: "RABASTENS-DE-BIGORRE",
      },
      {
        CITY: "RABAT-LES-TROIS-SEIGNEURS",
      },
      {
        CITY: "RABLAY-SUR-LAYON",
      },
      {
        CITY: "RABOU",
      },
      {
        CITY: "RACHES",
      },
      {
        CITY: "RACQUINGHEM",
      },
      {
        CITY: "RACRANGE",
      },
      {
        CITY: "RADINGHEM",
      },
      {
        CITY: "RADINGHEM-EN-WEPPES",
      },
      {
        CITY: "RADON",
      },
      {
        CITY: "RADONVILLIERS",
      },
      {
        CITY: "RAEDERSHEIM",
      },
      {
        CITY: "RAFFETOT",
      },
      {
        CITY: "RAHON",
      },
      {
        CITY: "RAI",
      },
      {
        CITY: "RAIDS",
      },
      {
        CITY: "RAIMBEAUCOURT",
      },
      {
        CITY: "RAINFREVILLE",
      },
      {
        CITY: "RAINNEVILLE",
      },
      {
        CITY: "RAINVILLE",
      },
      {
        CITY: "RAINVILLERS",
      },
      {
        CITY: "RAISMES",
      },
      {
        CITY: "RAIZEUX",
      },
      {
        CITY: "RAMATUELLE",
      },
      {
        CITY: "RAMBERT",
      },
      {
        CITY: "RAMBERVILLERS",
      },
      {
        CITY: "RAMBOUILLET",
      },
      {
        CITY: "RAMMERSMATT",
      },
      {
        CITY: "RAMONCHAMP",
      },
      {
        CITY: "RAMONVILLE-SAINT-AGNE",
      },
      {
        CITY: "RANCE",
      },
      {
        CITY: "RANCOGNE",
      },
      {
        CITY: "RANDAN",
      },
      {
        CITY: "RANDAN",
      },
      {
        CITY: "RANDENS",
      },
      {
        CITY: "RANES",
      },
      {
        CITY: "RANG",
      },
      {
        CITY: "RANG-DU-FLIERS",
      },
      {
        CITY: "RANS",
      },
      {
        CITY: "RANSPACH",
      },
      {
        CITY: "RANTIGNY",
      },
      {
        CITY: "RANVILLE",
      },
      {
        CITY: "RAON-AUX-BOIS",
      },
      {
        CITY: "RAON-SUR-PLAINE",
      },
      {
        CITY: "RARECOURT",
      },
      {
        CITY: "RATIERES",
      },
      {
        CITY: "RATTE",
      },
      {
        CITY: "RAUCOURT-ET-FLABA",
      },
      {
        CITY: "RAUVILLE-LA-PLACE",
      },
      {
        CITY: "RAUWILLER",
      },
      {
        CITY: "RAUZAN",
      },
      {
        CITY: "RAVENEL",
      },
      {
        CITY: "RAVIERES",
      },
      {
        CITY: "RAY-SUR-SAONE",
      },
      {
        CITY: "RAYSSAC",
      },
      {
        CITY: "RAZAC-SUR-LISLE",
      },
      {
        CITY: "REAL",
      },
      {
        CITY: "REALMONT",
      },
      {
        CITY: "REALVILLE",
      },
      {
        CITY: "REAUMONT",
      },
      {
        CITY: "REAUMUR",
      },
      {
        CITY: "REBAIS",
      },
      {
        CITY: "REBENACQ",
      },
      {
        CITY: "REBRECHIEN",
      },
      {
        CITY: "REBREUVE-SUR-CANCHE",
      },
      {
        CITY: "REBREUVIETTE",
      },
      {
        CITY: "RECEY-SUR-OURCE",
      },
      {
        CITY: "RECHESY",
      },
      {
        CITY: "RECHICOURT-LE-CHATEAU",
      },
      {
        CITY: "RECLOSES",
      },
      {
        CITY: "RECOLOGNE",
      },
      {
        CITY: "RECOLOGNE-LES-RIOZ",
      },
      {
        CITY: "RECQUES-SUR-COURSE",
      },
      {
        CITY: "RECQUIGNIES",
      },
      {
        CITY: "REDENE",
      },
      {
        CITY: "REDESSAN",
      },
      {
        CITY: "REDING",
      },
      {
        CITY: "REDON",
      },
      {
        CITY: "REFFUVEILLE",
      },
      {
        CITY: "REGNIOWEZ",
      },
      {
        CITY: "REGUINY",
      },
      {
        CITY: "REGUISHEIM",
      },
      {
        CITY: "REGUSSE",
      },
      {
        CITY: "REHAINVILLER",
      },
      {
        CITY: "REHON",
      },
      {
        CITY: "REICHSHOFFEN",
      },
      {
        CITY: "REICHSTETT",
      },
      {
        CITY: "REIGNAC-SUR-INDRE",
      },
      {
        CITY: "REIGNIER-ESERY",
      },
      {
        CITY: "REILLANNE",
      },
      {
        CITY: "REILLY",
      },
      {
        CITY: "REIMS",
      },
      {
        CITY: "REIMS-LA-BRULEE",
      },
      {
        CITY: "REINERS",
      },
      {
        CITY: "REININGUE",
      },
      {
        CITY: "REIPERTSWILLER",
      },
      {
        CITY: "REITWILLER",
      },
      {
        CITY: "RELANGES",
      },
      {
        CITY: "REMAUCOURT",
      },
      {
        CITY: "REMECOURT",
      },
      {
        CITY: "REMELFANG",
      },
      {
        CITY: "REMELFING",
      },
      {
        CITY: "REMENOVILLE",
      },
      {
        CITY: "REMERANGLES",
      },
      {
        CITY: "REMEREVILLE",
      },
      {
        CITY: "REMERING-LES-PUTTELANGE",
      },
      {
        CITY: "REMIGNY",
      },
      {
        CITY: "REMILLY",
      },
      {
        CITY: "REMINIAC",
      },
      {
        CITY: "REMIREMONT",
      },
      {
        CITY: "REMOULINS",
      },
      {
        CITY: "REMUNGOL",
      },
      {
        CITY: "REMUZAT",
      },
      {
        CITY: "REMY",
      },
      {
        CITY: "RENAGE",
      },
      {
        CITY: "RENAISON",
      },
      {
        CITY: "RENAULT",
      },
      {
        CITY: "RENAZE",
      },
      {
        CITY: "RENE",
      },
      {
        CITY: "RENESCURE",
      },
      {
        CITY: "RENNES",
      },
      {
        CITY: "RENNEVILLE",
      },
      {
        CITY: "REPAIX",
      },
      {
        CITY: "REPARSAC",
      },
      {
        CITY: "REPLONGES",
      },
      {
        CITY: "REQUEIL",
      },
      {
        CITY: "REQUISTA",
      },
      {
        CITY: "RESSONS-LE-LONG",
      },
      {
        CITY: "RESSONS-SUR-MATZ",
      },
      {
        CITY: "RESTINCLIERES",
      },
      {
        CITY: "RETHEL",
      },
      {
        CITY: "RETHONDES",
      },
      {
        CITY: "RETIERS",
      },
      {
        CITY: "RETONFEY",
      },
      {
        CITY: "RETOURNAC",
      },
      {
        CITY: "RETSCHWILLER",
      },
      {
        CITY: "RETTEL",
      },
      {
        CITY: "RETY",
      },
      {
        CITY: "REUIL-EN-BRIE",
      },
      {
        CITY: "REUIL-SUR-BRECHE",
      },
      {
        CITY: "REUILLY",
      },
      {
        CITY: "REUILLY",
      },
      {
        CITY: "REUILLY-SAUVIGNY",
      },
      {
        CITY: "REUMONT",
      },
      {
        CITY: "REUVES",
      },
      {
        CITY: "REVEL",
      },
      {
        CITY: "REVEL-TOURDAN",
      },
      {
        CITY: "REVENTIN-VAUGRIS",
      },
      {
        CITY: "REVEST-DES-BROUSSES",
      },
      {
        CITY: "REVIERS",
      },
      {
        CITY: "REVIGNY",
      },
      {
        CITY: "REVIGNY-SUR-ORNAIN",
      },
      {
        CITY: "REVILLE",
      },
      {
        CITY: "REVIN",
      },
      {
        CITY: "REYNIES",
      },
      {
        CITY: "REYRIEUX",
      },
      {
        CITY: "REYSSOUZE",
      },
      {
        CITY: "REZONVILLE",
      },
      {
        CITY: "RHINAU",
      },
      {
        CITY: "RIAILLE",
      },
      {
        CITY: "RIANS",
      },
      {
        CITY: "RIANS",
      },
      {
        CITY: "RIANTEC",
      },
      {
        CITY: "RIBAUTE-LES-TAVERNES",
      },
      {
        CITY: "RIBECOURT-DRESLINCOURT",
      },
      {
        CITY: "RIBEMONT",
      },
      {
        CITY: "RIBEMONT-SUR-ANCRE",
      },
      {
        CITY: "RIBERAC",
      },
      {
        CITY: "RIBIERS",
      },
      {
        CITY: "RICARVILLE",
      },
      {
        CITY: "RICHARDMENIL",
      },
      {
        CITY: "RICHEBOURG",
      },
      {
        CITY: "RICHEBOURG",
      },
      {
        CITY: "RICHELIEU",
      },
      {
        CITY: "RICHELING",
      },
      {
        CITY: "RICHEMONT",
      },
      {
        CITY: "RICHEMONT",
      },
      {
        CITY: "RICHWILLER",
      },
      {
        CITY: "RICQUEBOURG",
      },
      {
        CITY: "RIEC-SUR-BELON",
      },
      {
        CITY: "RIEDISHEIM",
      },
      {
        CITY: "RIEDSELTZ",
      },
      {
        CITY: "RIENCOURT-LES-BAPAUME",
      },
      {
        CITY: "RIEULAY",
      },
      {
        CITY: "RIEUMES",
      },
      {
        CITY: "RIEUPEYROUX",
      },
      {
        CITY: "RIEUX",
      },
      {
        CITY: "RIEUX",
      },
      {
        CITY: "RIEUX",
      },
      {
        CITY: "RIEUX-DE-PELLEPORT",
      },
      {
        CITY: "RIEUX-EN-CAMBRESIS",
      },
      {
        CITY: "RIEUX-MINERVOIS",
      },
      {
        CITY: "RIEZ",
      },
      {
        CITY: "RIGNAC",
      },
      {
        CITY: "RIGNIEUX-LE-FRANC",
      },
      {
        CITY: "RILHAC-RANCON",
      },
      {
        CITY: "RILLIEUX-LA-PAPE",
      },
      {
        CITY: "RIMBAUD",
      },
      {
        CITY: "RIMOGNE",
      },
      {
        CITY: "RINGENDORF",
      },
      {
        CITY: "RINXENT",
      },
      {
        CITY: "RIOCAUD",
      },
      {
        CITY: "RIOM",
      },
      {
        CITY: "RIOM-ES-MONTAGNES",
      },
      {
        CITY: "RION-DES-LANDES",
      },
      {
        CITY: "RIONS",
      },
      {
        CITY: "RIORGES",
      },
      {
        CITY: "RIOTORD",
      },
      {
        CITY: "RIOZ",
      },
      {
        CITY: "RIS-ORANGIS",
      },
      {
        CITY: "RITTERSHOFFEN",
      },
      {
        CITY: "RIVARENNES",
      },
      {
        CITY: "RIVE-DE-GIER",
      },
      {
        CITY: "RIVECOURT",
      },
      {
        CITY: "RIVEDOUX-PLAGE",
      },
      {
        CITY: "RIVERY",
      },
      {
        CITY: "RIVESALTES",
      },
      {
        CITY: "RIVIERE",
      },
      {
        CITY: "RIVIERES",
      },
      {
        CITY: "RIVIERES",
      },
      {
        CITY: "RIVILLE",
      },
      {
        CITY: "RIXHEIM",
      },
      {
        CITY: "ROAILLAN",
      },
      {
        CITY: "ROANNE",
      },
      {
        CITY: "ROANNES-SAINT-MARY",
      },
      {
        CITY: "ROBERT",
      },
      {
        CITY: "ROBION",
      },
      {
        CITY: "ROCBARON",
      },
      {
        CITY: "ROCHE",
      },
      {
        CITY: "ROCHE",
      },
      {
        CITY: "ROCHE-LA-MOLIERE",
      },
      {
        CITY: "ROCHE-LE-PEYROUX",
      },
      {
        CITY: "ROCHE-LEZ-BEAUPRE",
      },
      {
        CITY: "ROCHECHOUART",
      },
      {
        CITY: "ROCHECORBON",
      },
      {
        CITY: "ROCHEFORT",
      },
      {
        CITY: "ROCHEFORT-DU-GARD",
      },
      {
        CITY: "ROCHEFORT-EN-TERRE",
      },
      {
        CITY: "ROCHEFORT-SUR-LOIRE",
      },
      {
        CITY: "ROCHEFORT-SUR-NENON",
      },
      {
        CITY: "ROCHEGUDE",
      },
      {
        CITY: "ROCHEMAURE",
      },
      {
        CITY: "ROCHER",
      },
      {
        CITY: "ROCHES",
      },
      {
        CITY: "ROCHES-LES-BLAMONT",
      },
      {
        CITY: "ROCHES-PREMARIE-ANDILLE",
      },
      {
        CITY: "ROCHES-SUR-MARNE",
      },
      {
        CITY: "ROCHESERVIERE",
      },
      {
        CITY: "ROCHESSON",
      },
      {
        CITY: "ROCHETOIRIN",
      },
      {
        CITY: "ROCHETREJOUX",
      },
      {
        CITY: "ROCHY-CONDE",
      },
      {
        CITY: "ROCQUEMONT",
      },
      {
        CITY: "ROCQUENCOURT",
      },
      {
        CITY: "ROCROI",
      },
      {
        CITY: "RODELINGHEM",
      },
      {
        CITY: "RODEREN",
      },
      {
        CITY: "RODERN",
      },
      {
        CITY: "RODEZ",
      },
      {
        CITY: "ROESCHWOOG",
      },
      {
        CITY: "ROEULX",
      },
      {
        CITY: "ROEUX",
      },
      {
        CITY: "ROEZE-SUR-SARTHE",
      },
      {
        CITY: "ROFFEY",
      },
      {
        CITY: "ROFFIAC",
      },
      {
        CITY: "ROGERVILLE",
      },
      {
        CITY: "ROGNAC",
      },
      {
        CITY: "ROGNAIX",
      },
      {
        CITY: "ROGNES",
      },
      {
        CITY: "ROGNONAS",
      },
      {
        CITY: "ROHAN",
      },
      {
        CITY: "ROHAN",
      },
      {
        CITY: "ROHR",
      },
      {
        CITY: "ROHRBACH-LES-BITCHE",
      },
      {
        CITY: "ROIFFIEUX",
      },
      {
        CITY: "ROISEL",
      },
      {
        CITY: "ROISSY-EN-BRIE",
      },
      {
        CITY: "ROISSY-EN-FRANCE",
      },
      {
        CITY: "ROLAMPONT",
      },
      {
        CITY: "ROLLANCOURT",
      },
      {
        CITY: "ROLLEVILLE",
      },
      {
        CITY: "ROLLOT",
      },
      {
        CITY: "ROMAGNAT",
      },
      {
        CITY: "ROMAGNE",
      },
      {
        CITY: "ROMAGNE",
      },
      {
        CITY: "ROMAGNE",
      },
      {
        CITY: "ROMAGNIEU",
      },
      {
        CITY: "ROMAIN",
      },
      {
        CITY: "ROMAIN-SUR-MEUSE",
      },
      {
        CITY: "ROMAINE",
      },
      {
        CITY: "ROMAINVILLE",
      },
      {
        CITY: "ROMANECHE-THORINS",
      },
      {
        CITY: "ROMANGE",
      },
      {
        CITY: "ROMANS",
      },
      {
        CITY: "ROMBAS",
      },
      {
        CITY: "ROMEGOUX",
      },
      {
        CITY: "ROMENY-SUR-MARNE",
      },
      {
        CITY: "ROMERIES",
      },
      {
        CITY: "ROMILLE",
      },
      {
        CITY: "ROMILLY-SUR-ANDELLE",
      },
      {
        CITY: "ROMILLY-SUR-SEINE",
      },
      {
        CITY: "ROMORANTIN-LANTHENAY",
      },
      {
        CITY: "ROMPON",
      },
      {
        CITY: "RONCEY",
      },
      {
        CITY: "RONCHIN",
      },
      {
        CITY: "RONCQ",
      },
      {
        CITY: "RONNET",
      },
      {
        CITY: "RONNO",
      },
      {
        CITY: "RONSENAC",
      },
      {
        CITY: "RONTHON",
      },
      {
        CITY: "ROOST-WARENDIN",
      },
      {
        CITY: "ROPPE",
      },
      {
        CITY: "ROPPENHEIM",
      },
      {
        CITY: "ROPPENTZWILLER",
      },
      {
        CITY: "ROQUEBILLIERE",
      },
      {
        CITY: "ROQUEBRUNE",
      },
      {
        CITY: "ROQUEBRUNE-CAP-MARTIN",
      },
      {
        CITY: "ROQUEBRUNE-SUR-ARGENS",
      },
      {
        CITY: "ROQUECOR",
      },
      {
        CITY: "ROQUECOURBE",
      },
      {
        CITY: "ROQUEFORT",
      },
      {
        CITY: "ROQUEFORT",
      },
      {
        CITY: "ROQUEFORT-DE-SAULT",
      },
      {
        CITY: "ROQUEFORT-DES-CORBIERES",
      },
      {
        CITY: "ROQUEFORT-LA-BEDOULE",
      },
      {
        CITY: "ROQUEFORT-SUR-GARONNE",
      },
      {
        CITY: "ROQUEFORT-SUR-SOULZON",
      },
      {
        CITY: "ROQUELAURE-SAINT-AUBIN",
      },
      {
        CITY: "ROQUEMAURE",
      },
      {
        CITY: "ROQUES",
      },
      {
        CITY: "ROQUESERIERE",
      },
      {
        CITY: "ROQUESTERON",
      },
      {
        CITY: "ROQUETOIRE",
      },
      {
        CITY: "ROQUETTES",
      },
      {
        CITY: "ROQUEVAIRE",
      },
      {
        CITY: "ROSAY",
      },
      {
        CITY: "ROSAY-SUR-LIEURE",
      },
      {
        CITY: "ROSCOFF",
      },
      {
        CITY: "ROSEL",
      },
      {
        CITY: "ROSENWILLER",
      },
      {
        CITY: "ROSHEIM",
      },
      {
        CITY: "ROSIERES",
      },
      {
        CITY: "ROSIERES-AUX-SALINES",
      },
      {
        CITY: "ROSIERES-EN-HAYE",
      },
      {
        CITY: "ROSIERES-EN-SANTERRE",
      },
      {
        CITY: "ROSIERES-PRES-TROYES",
      },
      {
        CITY: "ROSIERES-SUR-MANCE",
      },
      {
        CITY: "ROSNY-SOUS-BOIS",
      },
      {
        CITY: "ROSNY-SUR-SEINE",
      },
      {
        CITY: "ROSOY-EN-MULTIEN",
      },
      {
        CITY: "ROSPEZ",
      },
      {
        CITY: "ROSPORDEN",
      },
      {
        CITY: "ROSSELANGE",
      },
      {
        CITY: "ROSSFELD",
      },
      {
        CITY: "ROSTEIG",
      },
      {
        CITY: "ROSTRENEN",
      },
      {
        CITY: "ROSULT",
      },
      {
        CITY: "ROTHAU",
      },
      {
        CITY: "ROTHERENS",
      },
      {
        CITY: "ROTS",
      },
      {
        CITY: "ROTT",
      },
      {
        CITY: "ROUANS",
      },
      {
        CITY: "ROUBAIX",
      },
      {
        CITY: "ROUBIA",
      },
      {
        CITY: "ROUBION",
      },
      {
        CITY: "ROUCY",
      },
      {
        CITY: "ROUEN",
      },
      {
        CITY: "ROUFFACH",
      },
      {
        CITY: "ROUFFIAC-TOLOSAN",
      },
      {
        CITY: "ROUFFIGNAC",
      },
      {
        CITY: "ROUFFIGNAC-SAINT-CERNIN-DE-REILHAC",
      },
      {
        CITY: "ROUGE-PERRIERS",
      },
      {
        CITY: "ROUGEGOUTTE",
      },
      {
        CITY: "ROUGEMONT",
      },
      {
        CITY: "ROUGIERS",
      },
      {
        CITY: "ROUILLAC",
      },
      {
        CITY: "ROUILLE",
      },
      {
        CITY: "ROUILLON",
      },
      {
        CITY: "ROUILLY-SACEY",
      },
      {
        CITY: "ROUJAN",
      },
      {
        CITY: "ROULANS",
      },
      {
        CITY: "ROULLENS",
      },
      {
        CITY: "ROULLET-SAINT-ESTEPHE",
      },
      {
        CITY: "ROUMARE",
      },
      {
        CITY: "ROUMAZIERES",
      },
      {
        CITY: "ROUMEGOUX",
      },
      {
        CITY: "ROUMOULES",
      },
      {
        CITY: "ROUNTZENHEIM",
      },
      {
        CITY: "ROUPERROUX",
      },
      {
        CITY: "ROUSIES",
      },
      {
        CITY: "ROUSSAY",
      },
      {
        CITY: "ROUSSELOY",
      },
      {
        CITY: "ROUSSET",
      },
      {
        CITY: "ROUSSILLON",
      },
      {
        CITY: "ROUSSILLON",
      },
      {
        CITY: "ROUSSON",
      },
      {
        CITY: "ROUSSY",
      },
      {
        CITY: "ROUSSY-LE-VILLAGE",
      },
      {
        CITY: "ROUTOT",
      },
      {
        CITY: "ROUVILLE",
      },
      {
        CITY: "ROUVILLERS",
      },
      {
        CITY: "ROUVRE",
      },
      {
        CITY: "ROUVRES-LA-CHETIVE",
      },
      {
        CITY: "ROUVROY",
      },
      {
        CITY: "ROUVROY",
      },
      {
        CITY: "ROUVROY-SUR-AUDRY",
      },
      {
        CITY: "ROUVROY-SUR-MARNE",
      },
      {
        CITY: "ROUXMESNIL-BOUTEILLES",
      },
      {
        CITY: "ROUY-LE-GRAND",
      },
      {
        CITY: "ROUZIERS-DE-TOURAINE",
      },
      {
        CITY: "ROVILLE-AUX-CHENES",
      },
      {
        CITY: "ROYAN",
      },
      {
        CITY: "ROYAS",
      },
      {
        CITY: "ROYAT",
      },
      {
        CITY: "ROYBON",
      },
      {
        CITY: "ROYE",
      },
      {
        CITY: "ROYE",
      },
      {
        CITY: "ROYE-SUR-MATZ",
      },
      {
        CITY: "ROZAY-EN-BRIE",
      },
      {
        CITY: "ROZERIEULLES",
      },
      {
        CITY: "ROZIER-EN-DONZY",
      },
      {
        CITY: "ROZIERES-EN-BEAUCE",
      },
      {
        CITY: "ROZOY-BELLEVALLE",
      },
      {
        CITY: "ROZOY-SUR-SERRE",
      },
      {
        CITY: "RUAUDIN",
      },
      {
        CITY: "RUBELLES",
      },
      {
        CITY: "RUBEMPRE",
      },
      {
        CITY: "RUCH",
      },
      {
        CITY: "RUCQUEVILLE",
      },
      {
        CITY: "RUE",
      },
      {
        CITY: "RUEIL-MALMAISON",
      },
      {
        CITY: "RUELISHEIM",
      },
      {
        CITY: "RUELLE",
      },
      {
        CITY: "RUELLE-SUR-TOUVRE",
      },
      {
        CITY: "RUESNES",
      },
      {
        CITY: "RUFFEC",
      },
      {
        CITY: "RUFFEY-LES-BEAUNE",
      },
      {
        CITY: "RUFFEY-LES-ECHIREY",
      },
      {
        CITY: "RUFFEY-SUR-SEILLE",
      },
      {
        CITY: "RUFFIAC",
      },
      {
        CITY: "RUGLES",
      },
      {
        CITY: "RUILLE-FROID-FONDS",
      },
      {
        CITY: "RUILLE-LE-GRAVELAIS",
      },
      {
        CITY: "RUITZ",
      },
      {
        CITY: "RULLY",
      },
      {
        CITY: "RULLY",
      },
      {
        CITY: "RUMAUCOURT",
      },
      {
        CITY: "RUMEGIES",
      },
      {
        CITY: "RUMERSHEIM-LE-HAUT",
      },
      {
        CITY: "RUMIGNY",
      },
      {
        CITY: "RUMILLY",
      },
      {
        CITY: "RUMILLY-EN-CAMBRESIS",
      },
      {
        CITY: "RUNGIS",
      },
      {
        CITY: "RUOMS",
      },
      {
        CITY: "RUPT",
      },
      {
        CITY: "RUPT-SUR-MOSELLE",
      },
      {
        CITY: "RURANGE-LES-THIONVILLE",
      },
      {
        CITY: "RUSS",
      },
      {
        CITY: "RUY",
      },
      {
        CITY: "RY",
      },
      {
        CITY: "SAACY-SUR-MARNE",
      },
      {
        CITY: "SAALES",
      },
      {
        CITY: "SABATIER",
      },
      {
        CITY: "SABLE-SUR-SARTHE",
      },
      {
        CITY: "SABLONCEAUX",
      },
      {
        CITY: "SABLONS",
      },
      {
        CITY: "SABRES",
      },
      {
        CITY: "SACEY",
      },
      {
        CITY: "SACHIN",
      },
      {
        CITY: "SACLAS",
      },
      {
        CITY: "SACLAY",
      },
      {
        CITY: "SACQUENVILLE",
      },
      {
        CITY: "SACY",
      },
      {
        CITY: "SADIRAC",
      },
      {
        CITY: "SAESSOLSHEIM",
      },
      {
        CITY: "SAFFRE",
      },
      {
        CITY: "SAGY",
      },
      {
        CITY: "SAHURS",
      },
      {
        CITY: "SAIGNES",
      },
      {
        CITY: "SAIL-SOUS-COUZAN",
      },
      {
        CITY: "SAILLANS",
      },
      {
        CITY: "SAILLY-EN-OSTREVENT",
      },
      {
        CITY: "SAILLY-FLIBEAUCOURT",
      },
      {
        CITY: "SAILLY-LABOURSE",
      },
      {
        CITY: "SAILLY-LE-SEC",
      },
      {
        CITY: "SAILLY-LEZ-CAMBRAI",
      },
      {
        CITY: "SAILLY-SUR-LA-LYS",
      },
      {
        CITY: "SAIN-BEL",
      },
      {
        CITY: "SAINGHIN-EN-MELANTOIS",
      },
      {
        CITY: "SAINGHIN-EN-WEPPES",
      },
      {
        CITY: "SAINNEVILLE",
      },
      {
        CITY: "SAINPUITS",
      },
      {
        CITY: "SAINS-DU-NORD",
      },
      {
        CITY: "SAINS-EN-AMIENOIS",
      },
      {
        CITY: "SAINS-EN-GOHELLE",
      },
      {
        CITY: "SAINT NICOLAS LES ARRAS",
      },
      {
        CITY: "SAINT-ABIT",
      },
      {
        CITY: "SAINT-ADRIEN",
      },
      {
        CITY: "SAINT-AFFRIQUE",
      },
      {
        CITY: "SAINT-AGATHON",
      },
      {
        CITY: "SAINT-AGNANT",
      },
      {
        CITY: "SAINT-AGOULIN",
      },
      {
        CITY: "SAINT-AGREVE",
      },
      {
        CITY: "SAINT-AIGNAN-DES-NOYERS",
      },
      {
        CITY: "SAINT-AIGNAN-SUR-ROE",
      },
      {
        CITY: "SAINT-AIGULIN",
      },
      {
        CITY: "SAINT-ALBAIN",
      },
      {
        CITY: "SAINT-ALBAN-DE-ROCHE",
      },
      {
        CITY: "SAINT-ALBAN-DU-RHONE",
      },
      {
        CITY: "SAINT-ALBAN-LEYSSE",
      },
      {
        CITY: "SAINT-ALEXANDRE",
      },
      {
        CITY: "SAINT-AMANCET",
      },
      {
        CITY: "SAINT-AMAND-DE-BELVES",
      },
      {
        CITY: "SAINT-AMAND-DE-COLY",
      },
      {
        CITY: "SAINT-AMAND-EN-PUISAYE",
      },
      {
        CITY: "SAINT-AMAND-LES-EAUX",
      },
      {
        CITY: "SAINT-AMAND-MAGNAZEIX",
      },
      {
        CITY: "SAINT-AMAND-MONTROND",
      },
      {
        CITY: "SAINT-AMAND-SUR-FION",
      },
      {
        CITY: "SAINT-AMANS-SOULT",
      },
      {
        CITY: "SAINT-AMANS-VALTORET",
      },
      {
        CITY: "SAINT-AMANT-DE-BOIXE",
      },
      {
        CITY: "SAINT-AMANT-TALLENDE",
      },
      {
        CITY: "SAINT-AMARIN",
      },
      {
        CITY: "SAINT-AMBROIX",
      },
      {
        CITY: "SAINT-AMOUR",
      },
      {
        CITY: "SAINT-ANDEOL-DE-VALS",
      },
      {
        CITY: "SAINT-ANDEUX",
      },
      {
        CITY: "SAINT-ANDIOL",
      },
      {
        CITY: "SAINT-ANDRE",
      },
      {
        CITY: "SAINT-ANDRE-DE-CHALENCON",
      },
      {
        CITY: "SAINT-ANDRE-DE-CORCY",
      },
      {
        CITY: "SAINT-ANDRE-DE-MESSEI",
      },
      {
        CITY: "SAINT-ANDRE-DE-ROQUELONGUE",
      },
      {
        CITY: "SAINT-ANDRE-DE-ROSANS",
      },
      {
        CITY: "SAINT-ANDRE-DE-SANGONIS",
      },
      {
        CITY: "SAINT-ANDRE-DES-EAUX",
      },
      {
        CITY: "SAINT-ANDRE-EN-VIVARAIS",
      },
      {
        CITY: "SAINT-ANDRE-FARIVILLERS",
      },
      {
        CITY: "SAINT-ANDRE-LE-GAZ",
      },
      {
        CITY: "SAINT-ANDRE-LE-PUY",
      },
      {
        CITY: "SAINT-ANDRE-LES-ALPES",
      },
      {
        CITY: "SAINT-ANDRE-LES-VERGERS",
      },
      {
        CITY: "SAINT-ANDRE-LEZ-LILLE",
      },
      {
        CITY: "SAINT-ANDRE-SUR-ORNE",
      },
      {
        CITY: "SAINT-ANDRE-TREIZE-VOIES",
      },
      {
        CITY: "SAINT-ANDRONY",
      },
      {
        CITY: "SAINT-ANGEAU",
      },
      {
        CITY: "SAINT-ANGEL",
      },
      {
        CITY: "SAINT-ANTOINE-CUMOND",
      },
      {
        CITY: "SAINT-ANTOINE-DU-ROCHER",
      },
      {
        CITY: "SAINT-ANTOINE-LA-FORET",
      },
      {
        CITY: "SAINT-ANTONIN-SUR-BAYON",
      },
      {
        CITY: "SAINT-APOLLINAIRE",
      },
      {
        CITY: "SAINT-APPOLINARD",
      },
      {
        CITY: "SAINT-AQUILIN-DE-PACY",
      },
      {
        CITY: "SAINT-ARMEL",
      },
      {
        CITY: "SAINT-ARNOULT",
      },
      {
        CITY: "SAINT-ARNOULT",
      },
      {
        CITY: "SAINT-ARNOULT-DES-BOIS",
      },
      {
        CITY: "SAINT-ARNOULT-EN-YVELINES",
      },
      {
        CITY: "SAINT-ASTIER",
      },
      {
        CITY: "SAINT-AUBAN",
      },
      {
        CITY: "SAINT-AUBERT",
      },
      {
        CITY: "SAINT-AUBIN",
      },
      {
        CITY: "SAINT-AUBIN-DE-BAUBIGNE",
      },
      {
        CITY: "SAINT-AUBIN-DE-LANQUAIS",
      },
      {
        CITY: "SAINT-AUBIN-DE-MEDOC",
      },
      {
        CITY: "SAINT-AUBIN-DES-CHATEAUX",
      },
      {
        CITY: "SAINT-AUBIN-DU-CORMIER",
      },
      {
        CITY: "SAINT-AUBIN-DU-PLAIN",
      },
      {
        CITY: "SAINT-AUBIN-EN-BRAY",
      },
      {
        CITY: "SAINT-AUBIN-EN-CHAROLLAIS",
      },
      {
        CITY: "SAINT-AUBIN-LES-ELBEUF",
      },
      {
        CITY: "SAINT-AUBIN-LES-FORGES",
      },
      {
        CITY: "SAINT-AUBIN-SOUS-ERQUERY",
      },
      {
        CITY: "SAINT-AUBIN-SUR-GAILLON",
      },
      {
        CITY: "SAINT-AUBIN-SUR-MER",
      },
      {
        CITY: "SAINT-AUBIN-SUR-SCIE",
      },
      {
        CITY: "SAINT-AUGUSTIN",
      },
      {
        CITY: "SAINT-AUGUSTIN",
      },
      {
        CITY: "SAINT-AUGUSTIN",
      },
      {
        CITY: "SAINT-AUNES",
      },
      {
        CITY: "SAINT-AVAUGOURD-DES-LANDES",
      },
      {
        CITY: "SAINT-AVE",
      },
      {
        CITY: "SAINT-AVERTIN",
      },
      {
        CITY: "SAINT-AVIT",
      },
      {
        CITY: "SAINT-AVIT-LES-GUESPIERES",
      },
      {
        CITY: "SAINT-AVOLD",
      },
      {
        CITY: "SAINT-AVRE",
      },
      {
        CITY: "SAINT-AY",
      },
      {
        CITY: "SAINT-AYGULF",
      },
      {
        CITY: "SAINT-BALDOPH",
      },
      {
        CITY: "SAINT-BARDOUX",
      },
      {
        CITY: "SAINT-BARNABE",
      },
      {
        CITY: "SAINT-BARNABE",
      },
      {
        CITY: "SAINT-BARTHELEMY-DE-BUSSIERE",
      },
      {
        CITY: "SAINT-BARTHELEMY-DE-VALS",
      },
      {
        CITY: "SAINT-BARTHELEMY-LESTRA",
      },
      {
        CITY: "SAINT-BAUDILLE-DE-LA-TOUR",
      },
      {
        CITY: "SAINT-BAUZILLE-DE-MONTMEL",
      },
      {
        CITY: "SAINT-BAUZILLE-DE-PUTOIS",
      },
      {
        CITY: "SAINT-BEAT",
      },
      {
        CITY: "SAINT-BENEZET",
      },
      {
        CITY: "SAINT-BENIN-DAZY",
      },
      {
        CITY: "SAINT-BENOIT",
      },
      {
        CITY: "SAINT-BENOIT-DES-ONDES",
      },
      {
        CITY: "SAINT-BENOIT-SUR-LOIRE",
      },
      {
        CITY: "SAINT-BERAIN-SUR-DHEUNE",
      },
      {
        CITY: "SAINT-BERNARD",
      },
      {
        CITY: "SAINT-BERON",
      },
      {
        CITY: "SAINT-BERTHEVIN",
      },
      {
        CITY: "SAINT-BIHY",
      },
      {
        CITY: "SAINT-BLAISE-LA-ROCHE",
      },
      {
        CITY: "SAINT-BOHAIRE",
      },
      {
        CITY: "SAINT-BOIL",
      },
      {
        CITY: "SAINT-BOINGT",
      },
      {
        CITY: "SAINT-BONNET-DE-MURE",
      },
      {
        CITY: "SAINT-BONNET-LE-CHATEAU",
      },
      {
        CITY: "SAINT-BONNET-LE-TRONCY",
      },
      {
        CITY: "SAINT-BONNET-LES-ALLIER",
      },
      {
        CITY: "SAINT-BRANCHS",
      },
      {
        CITY: "SAINT-BRANDAN",
      },
      {
        CITY: "SAINT-BRES",
      },
      {
        CITY: "SAINT-BREVIN-LES-PINS",
      },
      {
        CITY: "SAINT-BRIAC-SUR-MER",
      },
      {
        CITY: "SAINT-BRICE",
      },
      {
        CITY: "SAINT-BRICE-DE-LANDELLES",
      },
      {
        CITY: "SAINT-BRICE-SOUS-FORET",
      },
      {
        CITY: "SAINT-BRIEUC",
      },
      {
        CITY: "SAINT-BRIS-LE-VINEUX",
      },
      {
        CITY: "SAINT-BRISSON-SUR-LOIRE",
      },
      {
        CITY: "SAINT-CALAIS",
      },
      {
        CITY: "SAINT-CANNAT",
      },
      {
        CITY: "SAINT-CAPRAIS-DE-BORDEAUX",
      },
      {
        CITY: "SAINT-CARNE",
      },
      {
        CITY: "SAINT-CARREUC",
      },
      {
        CITY: "SAINT-CASSIN",
      },
      {
        CITY: "SAINT-CAST-LE-GUILDO",
      },
      {
        CITY: "SAINT-CERE",
      },
      {
        CITY: "SAINT-CERGUES",
      },
      {
        CITY: "SAINT-CESAIRE",
      },
      {
        CITY: "SAINT-CEZAIRE-SUR-SIAGNE",
      },
      {
        CITY: "SAINT-CEZERT",
      },
      {
        CITY: "SAINT-CHAMASSY",
      },
      {
        CITY: "SAINT-CHAMOND",
      },
      {
        CITY: "SAINT-CHAPTES",
      },
      {
        CITY: "SAINT-CHARTRES",
      },
      {
        CITY: "SAINT-CHEF",
      },
      {
        CITY: "SAINT-CHERON-DU-CHEMIN",
      },
      {
        CITY: "SAINT-CHINIAN",
      },
      {
        CITY: "SAINT-CHRISTO-EN-JAREZ",
      },
      {
        CITY: "SAINT-CHRISTOL",
      },
      {
        CITY: "SAINT-CHRISTOL",
      },
      {
        CITY: "SAINT-CHRISTOL-LES-ALES",
      },
      {
        CITY: "SAINT-CHRISTOPHE-DE-VALAINS",
      },
      {
        CITY: "SAINT-CHRISTOPHE-DU-BOIS",
      },
      {
        CITY: "SAINT-CHRISTOPHE-DU-LIGNERON",
      },
      {
        CITY: "SAINT-CHRISTOPHE-EN-BOUCHERIE",
      },
      {
        CITY: "SAINT-CHRISTOPHE-EN-BRIONNAIS",
      },
      {
        CITY: "SAINT-CHRISTOPHE-SUR-AVRE",
      },
      {
        CITY: "SAINT-CHRISTOPHE-SUR-GUIERS",
      },
      {
        CITY: "SAINT-CHRISTOPHE-SUR-LE-NAIS",
      },
      {
        CITY: "SAINT-CHRISTOPHE-SUR-ROC",
      },
      {
        CITY: "SAINT-CIERS-SUR-GIRONDE",
      },
      {
        CITY: "SAINT-CIRGUES",
      },
      {
        CITY: "SAINT-CLAIR",
      },
      {
        CITY: "SAINT-CLAIR-DE-LA-TOUR",
      },
      {
        CITY: "SAINT-CLAIR-DU-RHONE",
      },
      {
        CITY: "SAINT-CLAIR-SUR-GALAURE",
      },
      {
        CITY: "SAINT-CLAR-DE-RIVIERE",
      },
      {
        CITY: "SAINT-CLAUD",
      },
      {
        CITY: "SAINT-CLAUDE",
      },
      {
        CITY: "SAINT-CLAUDE-DE-DIRAY",
      },
      {
        CITY: "SAINT-CLEMENT",
      },
      {
        CITY: "SAINT-CLEMENT",
      },
      {
        CITY: "SAINT-CLEMENT",
      },
      {
        CITY: "SAINT-CLEMENT-DE-LA-PLACE",
      },
      {
        CITY: "SAINT-CLEMENT-DES-LEVEES",
      },
      {
        CITY: "SAINT-CLET",
      },
      {
        CITY: "SAINT-CLOUD",
      },
      {
        CITY: "SAINT-COLOMBAN",
      },
      {
        CITY: "SAINT-CONGARD",
      },
      {
        CITY: "SAINT-CONTEST",
      },
      {
        CITY: "SAINT-CORNEILLE",
      },
      {
        CITY: "SAINT-CORNIER-DES-LANDES",
      },
      {
        CITY: "SAINT-COULITZ",
      },
      {
        CITY: "SAINT-COULOMB",
      },
      {
        CITY: "SAINT-CREAC",
      },
      {
        CITY: "SAINT-CREPIN-IBOUVILLERS",
      },
      {
        CITY: "SAINT-CRESPIN-SUR-MOINE",
      },
      {
        CITY: "SAINT-CYPRIEN",
      },
      {
        CITY: "SAINT-CYPRIEN",
      },
      {
        CITY: "SAINT-CYPRIEN-PLAGE",
      },
      {
        CITY: "SAINT-CYR-DU-GAULT",
      },
      {
        CITY: "SAINT-CYR-EN-PAIL",
      },
      {
        CITY: "SAINT-CYR-EN-VAL",
      },
      {
        CITY: "SAINT-CYR-LA-CAMPAGNE",
      },
      {
        CITY: "SAINT-CYR-LE-CHATOUX",
      },
      {
        CITY: "SAINT-CYR-LES-CHAMPAGNES",
      },
      {
        CITY: "SAINT-CYR-SUR-LE-RHONE",
      },
      {
        CITY: "SAINT-CYR-SUR-LOIRE",
      },
      {
        CITY: "SAINT-CYR-SUR-MENTHON",
      },
      {
        CITY: "SAINT-CYR-SUR-MER",
      },
      {
        CITY: "SAINT-CYR-SUR-MORIN",
      },
      {
        CITY: "SAINT-DENIS",
      },
      {
        CITY: "SAINT-DENIS-DANJOU",
      },
      {
        CITY: "SAINT-DENIS-DE-CABANNE",
      },
      {
        CITY: "SAINT-DENIS-DE-PALIN",
      },
      {
        CITY: "SAINT-DENIS-DE-PILE",
      },
      {
        CITY: "SAINT-DENIS-DE-VAUX",
      },
      {
        CITY: "SAINT-DENIS-DES-MURS",
      },
      {
        CITY: "SAINT-DENIS-EN-BUGEY",
      },
      {
        CITY: "SAINT-DENIS-EN-VAL",
      },
      {
        CITY: "SAINT-DENIS-LE-GAST",
      },
      {
        CITY: "SAINT-DENIS-LE-THIBOULT",
      },
      {
        CITY: "SAINT-DENIS-LES-REBAIS",
      },
      {
        CITY: "SAINT-DENIS-SUR-COISE",
      },
      {
        CITY: "SAINT-DENIS-SUR-SARTHON",
      },
      {
        CITY: "SAINT-DERRIEN",
      },
      {
        CITY: "SAINT-DESIR",
      },
      {
        CITY: "SAINT-DESIRAT",
      },
      {
        CITY: "SAINT-DESIRE",
      },
      {
        CITY: "SAINT-DEZERY",
      },
      {
        CITY: "SAINT-DIDIER-DE-FORMANS",
      },
      {
        CITY: "SAINT-DIDIER-DE-LA-TOUR",
      },
      {
        CITY: "SAINT-DIDIER-EN-DONJON",
      },
      {
        CITY: "SAINT-DIDIER-EN-VELAY",
      },
      {
        CITY: "SAINT-DIDIER-SOUS-RIVERIE",
      },
      {
        CITY: "SAINT-DIDIER-SUR-CHALARONNE",
      },
      {
        CITY: "SAINT-DIDIER-SUR-ROCHEFORT",
      },
      {
        CITY: "SAINT-DIE-DES-VOSGES",
      },
      {
        CITY: "SAINT-DIONISY",
      },
      {
        CITY: "SAINT-DISDIER",
      },
      {
        CITY: "SAINT-DIVY",
      },
      {
        CITY: "SAINT-DIZIER",
      },
      {
        CITY: "SAINT-DIZIER-EN-DIOIS",
      },
      {
        CITY: "SAINT-DOLAY",
      },
      {
        CITY: "SAINT-DOMET",
      },
      {
        CITY: "SAINT-DOMINEUC",
      },
      {
        CITY: "SAINT-DONAN",
      },
      {
        CITY: "SAINT-DOULCHARD",
      },
      {
        CITY: "SAINT-DREZERY",
      },
      {
        CITY: "SAINT-EBREMOND-DE-BONFOSSE",
      },
      {
        CITY: "SAINT-ELIER",
      },
      {
        CITY: "SAINT-ELIPH",
      },
      {
        CITY: "SAINT-ELIX-SEGLAN",
      },
      {
        CITY: "SAINT-ELOI",
      },
      {
        CITY: "SAINT-ELOI",
      },
      {
        CITY: "SAINT-ELOY-DE-GY",
      },
      {
        CITY: "SAINT-ELOY-LA-GLACIERE",
      },
      {
        CITY: "SAINT-ELOY-LES-MINES",
      },
      {
        CITY: "SAINT-EMILION",
      },
      {
        CITY: "SAINT-ERBLON",
      },
      {
        CITY: "SAINT-ERME-OUTRE-ET-RAMECOURT",
      },
      {
        CITY: "SAINT-ESTEVE",
      },
      {
        CITY: "SAINT-ESTEVE-JANSON",
      },
      {
        CITY: "SAINT-ETIENNE",
      },
      {
        CITY: "SAINT-ETIENNE-AU-MONT",
      },
      {
        CITY: "SAINT-ETIENNE-AU-TEMPLE",
      },
      {
        CITY: "SAINT-ETIENNE-DE-BAIGORRY",
      },
      {
        CITY: "SAINT-ETIENNE-DE-BRILLOUET",
      },
      {
        CITY: "SAINT-ETIENNE-DE-CROSSEY",
      },
      {
        CITY: "SAINT-ETIENNE-DE-CUINES",
      },
      {
        CITY: "SAINT-ETIENNE-DE-FONTBELLON",
      },
      {
        CITY: "SAINT-ETIENNE-DE-MONTLUC",
      },
      {
        CITY: "SAINT-ETIENNE-DE-SAINT-GEOIRS",
      },
      {
        CITY: "SAINT-ETIENNE-DE-TINEE",
      },
      {
        CITY: "SAINT-ETIENNE-DE-TULMONT",
      },
      {
        CITY: "SAINT-ETIENNE-DE-VALOUX",
      },
      {
        CITY: "SAINT-ETIENNE-DES-OULLIERES",
      },
      {
        CITY: "SAINT-ETIENNE-DU-BOIS",
      },
      {
        CITY: "SAINT-ETIENNE-DU-GRES",
      },
      {
        CITY: "SAINT-ETIENNE-DU-VALDONNEZ",
      },
      {
        CITY: "SAINT-ETIENNE-DU-VAUVRAY",
      },
      {
        CITY: "SAINT-ETIENNE-EN-COGLES",
      },
      {
        CITY: "SAINT-ETIENNE-LA-VARENNE",
      },
      {
        CITY: "SAINT-ETIENNE-LE-MOLARD",
      },
      {
        CITY: "SAINT-ETIENNE-LES-ORGUES",
      },
      {
        CITY: "SAINT-ETIENNE-SOUS-BAILLEUL",
      },
      {
        CITY: "SAINT-ETIENNE-SUR-CHALARONNE",
      },
      {
        CITY: "SAINT-ETIENNE-SUR-SUIPPE",
      },
      {
        CITY: "SAINT-EULIEN",
      },
      {
        CITY: "SAINT-EUSTACHE-LA-FORET",
      },
      {
        CITY: "SAINT-EVARZEC",
      },
      {
        CITY: "SAINT-FAUST",
      },
      {
        CITY: "SAINT-FELICIEN",
      },
      {
        CITY: "SAINT-FELIX-DE-RIEUTORD",
      },
      {
        CITY: "SAINT-FELIX-DE-VILLADEIX",
      },
      {
        CITY: "SAINT-FIACRE",
      },
      {
        CITY: "SAINT-FIACRE-SUR-MAINE",
      },
      {
        CITY: "SAINT-FIRMIN",
      },
      {
        CITY: "SAINT-FIRMIN-DES-PRES",
      },
      {
        CITY: "SAINT-FLORENT",
      },
      {
        CITY: "SAINT-FLORENT-LE-VIEIL",
      },
      {
        CITY: "SAINT-FLORENT-SUR-AUZONNET",
      },
      {
        CITY: "SAINT-FLORENT-SUR-CHER",
      },
      {
        CITY: "SAINT-FLORENTIN",
      },
      {
        CITY: "SAINT-FLORET",
      },
      {
        CITY: "SAINT-FLORIS",
      },
      {
        CITY: "SAINT-FLOUR",
      },
      {
        CITY: "SAINT-FLOVIER",
      },
      {
        CITY: "SAINT-FOLQUIN",
      },
      {
        CITY: "SAINT-FONS",
      },
      {
        CITY: "SAINT-FORGEUX",
      },
      {
        CITY: "SAINT-FORT",
      },
      {
        CITY: "SAINT-FREGANT",
      },
      {
        CITY: "SAINT-FROMOND",
      },
      {
        CITY: "SAINT-FRONT",
      },
      {
        CITY: "SAINT-FRONT",
      },
      {
        CITY: "SAINT-FULGENT",
      },
      {
        CITY: "SAINT-FUSCIEN",
      },
      {
        CITY: "SAINT-GALMIER",
      },
      {
        CITY: "SAINT-GATIEN-DES-BOIS",
      },
      {
        CITY: "SAINT-GAUDENS",
      },
      {
        CITY: "SAINT-GAULTIER",
      },
      {
        CITY: "SAINT-GEIN",
      },
      {
        CITY: "SAINT-GELAIS",
      },
      {
        CITY: "SAINT-GELY-DU-FESC",
      },
      {
        CITY: "SAINT-GENCE",
      },
      {
        CITY: "SAINT-GENEROUX",
      },
      {
        CITY: "SAINT-GENES-DU-RETZ",
      },
      {
        CITY: "SAINT-GENEST-LERPT",
      },
      {
        CITY: "SAINT-GENEST-MALIFAUX",
      },
      {
        CITY: "SAINT-GENGOUX-DE-SCISSE",
      },
      {
        CITY: "SAINT-GENIES-BELLEVUE",
      },
      {
        CITY: "SAINT-GENIES-DE-COMOLAS",
      },
      {
        CITY: "SAINT-GENIES-DE-MALGOIRES",
      },
      {
        CITY: "SAINT-GENIES-DES-MOURGUES",
      },
      {
        CITY: "SAINT-GENIS-LAVAL",
      },
      {
        CITY: "SAINT-GENIS-LES-OLLIERES",
      },
      {
        CITY: "SAINT-GENIS-POUILLY",
      },
      {
        CITY: "SAINT-GENIX-SUR-GUIERS",
      },
      {
        CITY: "SAINT-GENOU",
      },
      {
        CITY: "SAINT-GEOIRE-EN-VALDAINE",
      },
      {
        CITY: "SAINT-GEOIRS",
      },
      {
        CITY: "SAINT-GEORGES-DE-BAROILLE",
      },
      {
        CITY: "SAINT-GEORGES-DE-DIDONNE",
      },
      {
        CITY: "SAINT-GEORGES-DE-LUZENCON",
      },
      {
        CITY: "SAINT-GEORGES-DE-MONTAIGU",
      },
      {
        CITY: "SAINT-GEORGES-DE-POINTINDOUX",
      },
      {
        CITY: "SAINT-GEORGES-DE-POISIEUX",
      },
      {
        CITY: "SAINT-GEORGES-DE-RENEINS",
      },
      {
        CITY: "SAINT-GEORGES-DES-COTEAUX",
      },
      {
        CITY: "SAINT-GEORGES-DU-BOIS",
      },
      {
        CITY: "SAINT-GEORGES-DU-ROSAY",
      },
      {
        CITY: "SAINT-GEORGES-DU-VIEVRE",
      },
      {
        CITY: "SAINT-GEORGES-HAUTE-VILLE",
      },
      {
        CITY: "SAINT-GEORGES-LE-FLECHARD",
      },
      {
        CITY: "SAINT-GEORGES-LES-BAILLARGEAUX",
      },
      {
        CITY: "SAINT-GEORGES-MONTCOCQ",
      },
      {
        CITY: "SAINT-GEORGES-SUR-ALLIER",
      },
      {
        CITY: "SAINT-GEORGES-SUR-ARNON",
      },
      {
        CITY: "SAINT-GEORGES-SUR-BAULCHE",
      },
      {
        CITY: "SAINT-GEORGES-SUR-CHER",
      },
      {
        CITY: "SAINT-GEORGES-SUR-EURE",
      },
      {
        CITY: "SAINT-GEORGES-SUR-FONTAINE",
      },
      {
        CITY: "SAINT-GEORGES-SUR-LOIRE",
      },
      {
        CITY: "SAINT-GEORGES-SUR-RENON",
      },
      {
        CITY: "SAINT-GERAND",
      },
      {
        CITY: "SAINT-GERAND-DE-VAUX",
      },
      {
        CITY: "SAINT-GEREON",
      },
      {
        CITY: "SAINT-GERMAIN",
      },
      {
        CITY: "SAINT-GERMAIN-DE-JOUX",
      },
      {
        CITY: "SAINT-GERMAIN-DE-LA-GRANGE",
      },
      {
        CITY: "SAINT-GERMAIN-DE-PRINCAY",
      },
      {
        CITY: "SAINT-GERMAIN-DE-TOURNEBUT",
      },
      {
        CITY: "SAINT-GERMAIN-DES-FOSSES",
      },
      {
        CITY: "SAINT-GERMAIN-DES-PRES",
      },
      {
        CITY: "SAINT-GERMAIN-DU-CORBEIS",
      },
      {
        CITY: "SAINT-GERMAIN-DU-PLAIN",
      },
      {
        CITY: "SAINT-GERMAIN-DU-PUCH",
      },
      {
        CITY: "SAINT-GERMAIN-DU-PUY",
      },
      {
        CITY: "SAINT-GERMAIN-EN-LAYE",
      },
      {
        CITY: "SAINT-GERMAIN-LA-BLANCHE-HERBE",
      },
      {
        CITY: "SAINT-GERMAIN-LA-VILLE",
      },
      {
        CITY: "SAINT-GERMAIN-LANGOT",
      },
      {
        CITY: "SAINT-GERMAIN-LAPRADE",
      },
      {
        CITY: "SAINT-GERMAIN-LAXIS",
      },
      {
        CITY: "SAINT-GERMAIN-LEMBRON",
      },
      {
        CITY: "SAINT-GERMAIN-LES-ARPAJON",
      },
      {
        CITY: "SAINT-GERMAIN-LES-BELLES",
      },
      {
        CITY: "SAINT-GERMAIN-LES-CORBEIL",
      },
      {
        CITY: "SAINT-GERMAIN-LES-PAROISSES",
      },
      {
        CITY: "SAINT-GERMAIN-LES-VERGNES",
      },
      {
        CITY: "SAINT-GERMAIN-NUELLES",
      },
      {
        CITY: "SAINT-GERMAIN-SOUS-DOUE",
      },
      {
        CITY: "SAINT-GERMAIN-SUR-AVRE",
      },
      {
        CITY: "SAINT-GERMAIN-SUR-MOINE",
      },
      {
        CITY: "SAINT-GERMAIN-SUR-MORIN",
      },
      {
        CITY: "SAINT-GERMAIN-SUR-RHONE",
      },
      {
        CITY: "SAINT-GERMAIN-VILLAGE",
      },
      {
        CITY: "SAINT-GERMAINMONT",
      },
      {
        CITY: "SAINT-GERMER-DE-FLY",
      },
      {
        CITY: "SAINT-GERVAIS-EN-BELIN",
      },
      {
        CITY: "SAINT-GERVAIS-LA-FORET",
      },
      {
        CITY: "SAINT-GERVAIS-LES-BAINS",
      },
      {
        CITY: "SAINT-GERVAIS-SOUS-MEYMONT",
      },
      {
        CITY: "SAINT-GERVAIS-SUR-ROUBION",
      },
      {
        CITY: "SAINT-GERVAZY",
      },
      {
        CITY: "SAINT-GEYRAC",
      },
      {
        CITY: "SAINT-GILDAS-DES-BOIS",
      },
      {
        CITY: "SAINT-GILLES",
      },
      {
        CITY: "SAINT-GILLES-DES-MARAIS",
      },
      {
        CITY: "SAINT-GILLES-LES-BOIS",
      },
      {
        CITY: "SAINT-GINGOLPH",
      },
      {
        CITY: "SAINT-GIRONS",
      },
      {
        CITY: "SAINT-GOAZEC",
      },
      {
        CITY: "SAINT-GOBAIN",
      },
      {
        CITY: "SAINT-GONDON",
      },
      {
        CITY: "SAINT-GONDRAN",
      },
      {
        CITY: "SAINT-GONNERY",
      },
      {
        CITY: "SAINT-GRATIEN",
      },
      {
        CITY: "SAINT-GRATIEN",
      },
      {
        CITY: "SAINT-GRAVE",
      },
      {
        CITY: "SAINT-GREGOIRE",
      },
      {
        CITY: "SAINT-GREGOIRE",
      },
      {
        CITY: "SAINT-GUILHEM-LE-DESERT",
      },
      {
        CITY: "SAINT-GUINOUX",
      },
      {
        CITY: "SAINT-HEAND",
      },
      {
        CITY: "SAINT-HELEN",
      },
      {
        CITY: "SAINT-HERBLAIN",
      },
      {
        CITY: "SAINT-HERVE",
      },
      {
        CITY: "SAINT-HILAIRE-CUSSON-LA-VALMITTE",
      },
      {
        CITY: "SAINT-HILAIRE-DE-BRETHMAS",
      },
      {
        CITY: "SAINT-HILAIRE-DE-CHALEONS",
      },
      {
        CITY: "SAINT-HILAIRE-DE-COURT",
      },
      {
        CITY: "SAINT-HILAIRE-DE-LA-COTE",
      },
      {
        CITY: "SAINT-HILAIRE-DE-LOULAY",
      },
      {
        CITY: "SAINT-HILAIRE-DE-RIEZ",
      },
      {
        CITY: "SAINT-HILAIRE-DE-TALMONT",
      },
      {
        CITY: "SAINT-HILAIRE-DE-VOUST",
      },
      {
        CITY: "SAINT-HILAIRE-DU-HARCOUET",
      },
      {
        CITY: "SAINT-HILAIRE-LE-VOUHIS",
      },
      {
        CITY: "SAINT-HILAIRE-LES-ANDRESIS",
      },
      {
        CITY: "SAINT-HILAIRE-LES-COURBES",
      },
      {
        CITY: "SAINT-HILAIRE-LEZ-CAMBRAI",
      },
      {
        CITY: "SAINT-HILAIRE-PEYROUX",
      },
      {
        CITY: "SAINT-HILAIRE-SAINT-MESMIN",
      },
      {
        CITY: "SAINT-HILARION",
      },
      {
        CITY: "SAINT-HILLIERS",
      },
      {
        CITY: "SAINT-HIPPOLYTE",
      },
      {
        CITY: "SAINT-HIPPOLYTE",
      },
      {
        CITY: "SAINT-HIPPOLYTE",
      },
      {
        CITY: "SAINT-HIPPOLYTE",
      },
      {
        CITY: "SAINT-HIPPOLYTE",
      },
      {
        CITY: "SAINT-HIPPOLYTE-DE-MONTAIGU",
      },
      {
        CITY: "SAINT-HIPPOLYTE-DU-FORT",
      },
      {
        CITY: "SAINT-HONORE-LES-BAINS",
      },
      {
        CITY: "SAINT-HOSTIEN",
      },
      {
        CITY: "SAINT-IGNEUC",
      },
      {
        CITY: "SAINT-ILLIDE",
      },
      {
        CITY: "SAINT-IMOGES",
      },
      {
        CITY: "SAINT-ISMIER",
      },
      {
        CITY: "SAINT-JACQUES-DE-LA-LANDE",
      },
      {
        CITY: "SAINT-JEAN-AUX-AMOGNES",
      },
      {
        CITY: "SAINT-JEAN-BONNEFONDS",
      },
      {
        CITY: "SAINT-JEAN-BREVELAY",
      },
      {
        CITY: "SAINT-JEAN-DE-BOISEAU",
      },
      {
        CITY: "SAINT-JEAN-DE-BOURNAY",
      },
      {
        CITY: "SAINT-JEAN-DE-BRAYE",
      },
      {
        CITY: "SAINT-JEAN-DE-CHEVELU",
      },
      {
        CITY: "SAINT-JEAN-DE-CORNIES",
      },
      {
        CITY: "SAINT-JEAN-DE-GONVILLE",
      },
      {
        CITY: "SAINT-JEAN-DE-LA-RUELLE",
      },
      {
        CITY: "SAINT-JEAN-DE-LAUR",
      },
      {
        CITY: "SAINT-JEAN-DE-LIER",
      },
      {
        CITY: "SAINT-JEAN-DE-LIVERSAY",
      },
      {
        CITY: "SAINT-JEAN-DE-LOSNE",
      },
      {
        CITY: "SAINT-JEAN-DE-LUZ",
      },
      {
        CITY: "SAINT-JEAN-DE-MARCEL",
      },
      {
        CITY: "SAINT-JEAN-DE-MARSACQ",
      },
      {
        CITY: "SAINT-JEAN-DE-MAURIENNE",
      },
      {
        CITY: "SAINT-JEAN-DE-MOIRANS",
      },
      {
        CITY: "SAINT-JEAN-DE-MONTS",
      },
      {
        CITY: "SAINT-JEAN-DE-MUZOLS",
      },
      {
        CITY: "SAINT-JEAN-DE-SAUVES",
      },
      {
        CITY: "SAINT-JEAN-DE-SERRES",
      },
      {
        CITY: "SAINT-JEAN-DE-SIXT",
      },
      {
        CITY: "SAINT-JEAN-DE-SOUDAIN",
      },
      {
        CITY: "SAINT-JEAN-DE-VEDAS",
      },
      {
        CITY: "SAINT-JEAN-DES-BAISANTS",
      },
      {
        CITY: "SAINT-JEAN-DES-CHAMPS",
      },
      {
        CITY: "SAINT-JEAN-DES-MAUVRETS",
      },
      {
        CITY: "SAINT-JEAN-DES-OLLIERES",
      },
      {
        CITY: "SAINT-JEAN-DES-VIGNES",
      },
      {
        CITY: "SAINT-JEAN-DU-BRUEL",
      },
      {
        CITY: "SAINT-JEAN-DU-CARDONNAY",
      },
      {
        CITY: "SAINT-JEAN-DU-FALGA",
      },
      {
        CITY: "SAINT-JEAN-EN-ROYANS",
      },
      {
        CITY: "SAINT-JEAN-KERDANIEL",
      },
      {
        CITY: "SAINT-JEAN-KOURTZERODE",
      },
      {
        CITY: "SAINT-JEAN-LA-POTERIE",
      },
      {
        CITY: "SAINT-JEAN-LASSEILLE",
      },
      {
        CITY: "SAINT-JEAN-LE-BLANC",
      },
      {
        CITY: "SAINT-JEAN-LE-BLANC",
      },
      {
        CITY: "SAINT-JEAN-LE-CENTENIER",
      },
      {
        CITY: "SAINT-JEAN-LE-THOMAS",
      },
      {
        CITY: "SAINT-JEAN-LES-DEUX-JUMEAUX",
      },
      {
        CITY: "SAINT-JEAN-LIGOURE",
      },
      {
        CITY: "SAINT-JEAN-PIED-DE-PORT",
      },
      {
        CITY: "SAINT-JEAN-ROHRBACH",
      },
      {
        CITY: "SAINT-JEAN-SAINT-GERMAIN",
      },
      {
        CITY: "SAINT-JEAN-SUR-ERVE",
      },
      {
        CITY: "SAINT-JEAN-SUR-VILAINE",
      },
      {
        CITY: "SAINT-JEAN-TROLIMON",
      },
      {
        CITY: "SAINT-JEANNET",
      },
      {
        CITY: "SAINT-JEOIRE",
      },
      {
        CITY: "SAINT-JEOIRE-PRIEURE",
      },
      {
        CITY: "SAINT-JEURES",
      },
      {
        CITY: "SAINT-JOACHIM",
      },
      {
        CITY: "SAINT-JORIOZ",
      },
      {
        CITY: "SAINT-JORY",
      },
      {
        CITY: "SAINT-JOSEPH-DE-RIVIERE",
      },
      {
        CITY: "SAINT-JOUAN-DES-GUERETS",
      },
      {
        CITY: "SAINT-JOUVENT",
      },
      {
        CITY: "SAINT-JUDOCE",
      },
      {
        CITY: "SAINT-JULIEN",
      },
      {
        CITY: "SAINT-JULIEN-DE-CASSAGNAS",
      },
      {
        CITY: "SAINT-JULIEN-DE-CHEDON",
      },
      {
        CITY: "SAINT-JULIEN-DE-CONCELLES",
      },
      {
        CITY: "SAINT-JULIEN-DE-COPPEL",
      },
      {
        CITY: "SAINT-JULIEN-DE-LAMPON",
      },
      {
        CITY: "SAINT-JULIEN-DE-VOUVANTES",
      },
      {
        CITY: "SAINT-JULIEN-DES-LANDES",
      },
      {
        CITY: "SAINT-JULIEN-DU-PUY",
      },
      {
        CITY: "SAINT-JULIEN-DU-SAULT",
      },
      {
        CITY: "SAINT-JULIEN-EN-BORN",
      },
      {
        CITY: "SAINT-JULIEN-EN-CHAMPSAUR",
      },
      {
        CITY: "SAINT-JULIEN-EN-GENEVOIS",
      },
      {
        CITY: "SAINT-JULIEN-LE-ROUX",
      },
      {
        CITY: "SAINT-JULIEN-LE-VENDOMOIS",
      },
      {
        CITY: "SAINT-JULIEN-LES-METZ",
      },
      {
        CITY: "SAINT-JULIEN-LES-VILLAS",
      },
      {
        CITY: "SAINT-JULIEN-SUR-BIBOST",
      },
      {
        CITY: "SAINT-JULIEN-SUR-SARTHE",
      },
      {
        CITY: "SAINT-JUNIEN",
      },
      {
        CITY: "SAINT-JUST-CHALEYSSIN",
      },
      {
        CITY: "SAINT-JUST-DE-CLAIX",
      },
      {
        CITY: "SAINT-JUST-EN-BAS",
      },
      {
        CITY: "SAINT-JUST-EN-CHAUSSEE",
      },
      {
        CITY: "SAINT-JUST-EN-CHEVALET",
      },
      {
        CITY: "SAINT-JUST-LE-MARTEL",
      },
      {
        CITY: "SAINT-JUST-MALMONT",
      },
      {
        CITY: "SAINT-JUST-PRES-BRIOUDE",
      },
      {
        CITY: "SAINT-LACTENCIN",
      },
      {
        CITY: "SAINT-LAGER",
      },
      {
        CITY: "SAINT-LAGER-BRESSAC",
      },
      {
        CITY: "SAINT-LAMBERT-DU-LATTAY",
      },
      {
        CITY: "SAINT-LAMBERT-LA-POTHERIE",
      },
      {
        CITY: "SAINT-LAMBERT-SUR-DIVE",
      },
      {
        CITY: "SAINT-LANNE",
      },
      {
        CITY: "SAINT-LARY-BOUJEAN",
      },
      {
        CITY: "SAINT-LARY-SOULAN",
      },
      {
        CITY: "SAINT-LAUNEUC",
      },
      {
        CITY: "SAINT-LAURENT-BLANGY",
      },
      {
        CITY: "SAINT-LAURENT-BRETAGNE",
      },
      {
        CITY: "SAINT-LAURENT-DE-LA-PLAINE",
      },
      {
        CITY: "SAINT-LAURENT-DE-LA-PREE",
      },
      {
        CITY: "SAINT-LAURENT-DE-LA-SALANQUE",
      },
      {
        CITY: "SAINT-LAURENT-DE-LEVEZOU",
      },
      {
        CITY: "SAINT-LAURENT-DE-MURE",
      },
      {
        CITY: "SAINT-LAURENT-DE-NESTE",
      },
      {
        CITY: "SAINT-LAURENT-DES-AUTELS",
      },
      {
        CITY: "SAINT-LAURENT-DES-COMBES",
      },
      {
        CITY: "SAINT-LAURENT-DU-BOIS",
      },
      {
        CITY: "SAINT-LAURENT-DU-MOTTAY",
      },
      {
        CITY: "SAINT-LAURENT-DU-PONT",
      },
      {
        CITY: "SAINT-LAURENT-DU-VAR",
      },
      {
        CITY: "SAINT-LAURENT-EN-BEAUMONT",
      },
      {
        CITY: "SAINT-LAURENT-EN-GATINES",
      },
      {
        CITY: "SAINT-LAURENT-LA-ROCHE",
      },
      {
        CITY: "SAINT-LAURENT-ROCHEFORT",
      },
      {
        CITY: "SAINT-LAURENT-SUR-GORRE",
      },
      {
        CITY: "SAINT-LAURENT-SUR-SEVRE",
      },
      {
        CITY: "SAINT-LAURS",
      },
      {
        CITY: "SAINT-LEGER-AUX-BOIS",
      },
      {
        CITY: "SAINT-LEGER-DE-ROTES",
      },
      {
        CITY: "SAINT-LEGER-DES-VIGNES",
      },
      {
        CITY: "SAINT-LEGER-DU-BOURG-DENIS",
      },
      {
        CITY: "SAINT-LEGER-EN-BRAY",
      },
      {
        CITY: "SAINT-LEGER-LES-DOMART",
      },
      {
        CITY: "SAINT-LEGER-SOUS-BRIENNE",
      },
      {
        CITY: "SAINT-LEGER-SOUS-CHOLET",
      },
      {
        CITY: "SAINT-LEGER-SUR-DHEUNE",
      },
      {
        CITY: "SAINT-LEONARD",
      },
      {
        CITY: "SAINT-LEONARD-DE-NOBLAT",
      },
      {
        CITY: "SAINT-LEU-LA-FORET",
      },
      {
        CITY: "SAINT-LEZIN",
      },
      {
        CITY: "SAINT-LIEUX-LES-LAVAUR",
      },
      {
        CITY: "SAINT-LORMEL",
      },
      {
        CITY: "SAINT-LOUBES",
      },
      {
        CITY: "SAINT-LOUIS",
      },
      {
        CITY: "SAINT-LOUIS-DE-MONTFERRAND",
      },
      {
        CITY: "SAINT-LOUP",
      },
      {
        CITY: "SAINT-LOUP-CAMMAS",
      },
      {
        CITY: "SAINT-LOUP-HORS",
      },
      {
        CITY: "SAINT-LUBIN-DES-JONCHERETS",
      },
      {
        CITY: "SAINT-LUMIER-EN-CHAMPAGNE",
      },
      {
        CITY: "SAINT-LUMINE-DE-CLISSON",
      },
      {
        CITY: "SAINT-LUPERCE",
      },
      {
        CITY: "SAINT-LUPICIN",
      },
      {
        CITY: "SAINT-LYE",
      },
      {
        CITY: "SAINT-LYE-LA-FORET",
      },
      {
        CITY: "SAINT-LYPHARD",
      },
      {
        CITY: "SAINT-LYS",
      },
      {
        CITY: "SAINT-MACAIRE",
      },
      {
        CITY: "SAINT-MACAIRE-DU-BOIS",
      },
      {
        CITY: "SAINT-MACAIRE-EN-MAUGES",
      },
      {
        CITY: "SAINT-MAGNE-DE-CASTILLON",
      },
      {
        CITY: "SAINT-MAIGNER",
      },
      {
        CITY: "SAINT-MAIGRIN",
      },
      {
        CITY: "SAINT-MAIME",
      },
      {
        CITY: "SAINT-MAIXANT",
      },
      {
        CITY: "SAINT-MALO-DE-GUERSAC",
      },
      {
        CITY: "SAINT-MALO-DE-LA-LANDE",
      },
      {
        CITY: "SAINT-MALO-DE-PHILY",
      },
      {
        CITY: "SAINT-MALO-DU-BOIS",
      },
      {
        CITY: "SAINT-MALO-EN-DONZIOIS",
      },
      {
        CITY: "SAINT-MAMERT-DU-GARD",
      },
      {
        CITY: "SAINT-MAMET-LA-SALVETAT",
      },
      {
        CITY: "SAINT-MAMMES",
      },
      {
        CITY: "SAINT-MANDE",
      },
      {
        CITY: "SAINT-MANDRIER-SUR-MER",
      },
      {
        CITY: "SAINT-MARC-JAUMEGARDE",
      },
      {
        CITY: "SAINT-MARCEAU",
      },
      {
        CITY: "SAINT-MARCEL-BEL-ACCUEIL",
      },
      {
        CITY: "SAINT-MARCEL-LES-VALENCE",
      },
      {
        CITY: "SAINT-MARCEL-SUR-AUDE",
      },
      {
        CITY: "SAINT-MARCELLIN",
      },
      {
        CITY: "SAINT-MARCELLIN-EN-FOREZ",
      },
      {
        CITY: "SAINT-MARD",
      },
      {
        CITY: "SAINT-MARD-DE-VAUX",
      },
      {
        CITY: "SAINT-MARIENS",
      },
      {
        CITY: "SAINT-MARS-DE-COUTAIS",
      },
      {
        CITY: "SAINT-MARS-DE-LOCQUENAY",
      },
      {
        CITY: "SAINT-MARS-DU-DESERT",
      },
      {
        CITY: "SAINT-MARS-LA-JAILLE",
      },
      {
        CITY: "SAINT-MARTIAL",
      },
      {
        CITY: "SAINT-MARTIAL-DE-VITATERNE",
      },
      {
        CITY: "SAINT-MARTIN-AU-LAERT",
      },
      {
        CITY: "SAINT-MARTIN-BELLE-ROCHE",
      },
      {
        CITY: "SAINT-MARTIN-BELLEVUE",
      },
      {
        CITY: "SAINT-MARTIN-BOULOGNE",
      },
      {
        CITY: "SAINT-MARTIN-CHOQUEL",
      },
      {
        CITY: "SAINT-MARTIN-DE-BELLEVILLE",
      },
      {
        CITY: "SAINT-MARTIN-DE-BERNEGOUE",
      },
      {
        CITY: "SAINT-MARTIN-DE-BLAGNY",
      },
      {
        CITY: "SAINT-MARTIN-DE-CRAU",
      },
      {
        CITY: "SAINT-MARTIN-DE-FONTENAY",
      },
      {
        CITY: "SAINT-MARTIN-DE-LA-BRASQUE",
      },
      {
        CITY: "SAINT-MARTIN-DE-LENNE",
      },
      {
        CITY: "SAINT-MARTIN-DE-LONDRES",
      },
      {
        CITY: "SAINT-MARTIN-DE-NIGELLES",
      },
      {
        CITY: "SAINT-MARTIN-DE-RE",
      },
      {
        CITY: "SAINT-MARTIN-DE-RIBERAC",
      },
      {
        CITY: "SAINT-MARTIN-DE-SEIGNANX",
      },
      {
        CITY: "SAINT-MARTIN-DE-VALAMAS",
      },
      {
        CITY: "SAINT-MARTIN-DE-VALGALGUES",
      },
      {
        CITY: "SAINT-MARTIN-DES-BOIS",
      },
      {
        CITY: "SAINT-MARTIN-DES-CHAMPS",
      },
      {
        CITY: "SAINT-MARTIN-DES-CHAMPS",
      },
      {
        CITY: "SAINT-MARTIN-DES-NOYERS",
      },
      {
        CITY: "SAINT-MARTIN-DES-OLMES",
      },
      {
        CITY: "SAINT-MARTIN-DU-BOIS",
      },
      {
        CITY: "SAINT-MARTIN-DU-BOIS",
      },
      {
        CITY: "SAINT-MARTIN-DU-BOSC",
      },
      {
        CITY: "SAINT-MARTIN-DU-MANOIR",
      },
      {
        CITY: "SAINT-MARTIN-DU-MONT",
      },
      {
        CITY: "SAINT-MARTIN-DU-PUY",
      },
      {
        CITY: "SAINT-MARTIN-DU-TERTRE",
      },
      {
        CITY: "SAINT-MARTIN-DU-TILLEUL",
      },
      {
        CITY: "SAINT-MARTIN-DU-VAR",
      },
      {
        CITY: "SAINT-MARTIN-EN-BIERE",
      },
      {
        CITY: "SAINT-MARTIN-EN-BRESSE",
      },
      {
        CITY: "SAINT-MARTIN-EN-CAMPAGNE",
      },
      {
        CITY: "SAINT-MARTIN-EN-GATINOIS",
      },
      {
        CITY: "SAINT-MARTIN-EN-HAUT",
      },
      {
        CITY: "SAINT-MARTIN-LA-CAMPAGNE",
      },
      {
        CITY: "SAINT-MARTIN-LA-PLAINE",
      },
      {
        CITY: "SAINT-MARTIN-LACAUSSADE",
      },
      {
        CITY: "SAINT-MARTIN-LALANDE",
      },
      {
        CITY: "SAINT-MARTIN-LE-BEAU",
      },
      {
        CITY: "SAINT-MARTIN-LE-NOEUD",
      },
      {
        CITY: "SAINT-MARTIN-LE-PIN",
      },
      {
        CITY: "SAINT-MARTIN-LE-VIEIL",
      },
      {
        CITY: "SAINT-MARTIN-LE-VINOUX",
      },
      {
        CITY: "SAINT-MARTIN-LES-MELLE",
      },
      {
        CITY: "SAINT-MARTIN-LONGUEAU",
      },
      {
        CITY: "SAINT-MARTIN-OSMONVILLE",
      },
      {
        CITY: "SAINT-MARTIN-SAINTE-CATHERINE",
      },
      {
        CITY: "SAINT-MARTIN-SUR-ARVE",
      },
      {
        CITY: "SAINT-MARTIN-SUR-LE-PRE",
      },
      {
        CITY: "SAINT-MARTIN-SUR-NOHAIN",
      },
      {
        CITY: "SAINT-MARTIN-SUR-OCRE",
      },
      {
        CITY: "SAINT-MARTIN-TERRESSUS",
      },
      {
        CITY: "SAINT-MARTIN-VALMEROUX",
      },
      {
        CITY: "SAINT-MARY",
      },
      {
        CITY: "SAINT-MATHIEU-DE-TREVIERS",
      },
      {
        CITY: "SAINT-MATHURIN",
      },
      {
        CITY: "SAINT-MAUDAN",
      },
      {
        CITY: "SAINT-MAULVIS",
      },
      {
        CITY: "SAINT-MAURICE",
      },
      {
        CITY: "SAINT-MAURICE-DE-CAZEVIEILLE",
      },
      {
        CITY: "SAINT-MAURICE-DE-GOURDANS",
      },
      {
        CITY: "SAINT-MAURICE-DE-LIGNON",
      },
      {
        CITY: "SAINT-MAURICE-DE-REMENS",
      },
      {
        CITY: "SAINT-MAURICE-LA-CLOUERE",
      },
      {
        CITY: "SAINT-MAURICE-LA-SOUTERRAINE",
      },
      {
        CITY: "SAINT-MAURICE-LES-BROUSSES",
      },
      {
        CITY: "SAINT-MAURICE-MONTCOURONNE",
      },
      {
        CITY: "SAINT-MAURICE-SUR-AVEYRON",
      },
      {
        CITY: "SAINT-MAURICE-SUR-DARGOIRE",
      },
      {
        CITY: "SAINT-MAURICE-SUR-EYGUES",
      },
      {
        CITY: "SAINT-MAURICE-SUR-FESSARD",
      },
      {
        CITY: "SAINT-MAURICE-SUR-MOSELLE",
      },
      {
        CITY: "SAINT-MAX",
      },
      {
        CITY: "SAINT-MAXENT",
      },
      {
        CITY: "SAINT-MAXIMIN",
      },
      {
        CITY: "SAINT-MAXIMIN",
      },
      {
        CITY: "SAINT-MAXIMIN",
      },
      {
        CITY: "SAINT-MAXIMIN-LA-SAINTE-BAUME",
      },
      {
        CITY: "SAINT-MAXIRE",
      },
      {
        CITY: "SAINT-MEARD-DE-GURCON",
      },
      {
        CITY: "SAINT-MEDARD-DE-GUIZIERES",
      },
      {
        CITY: "SAINT-MEDARD-EN-FOREZ",
      },
      {
        CITY: "SAINT-MEEN-LE-GRAND",
      },
      {
        CITY: "SAINT-MELAINE-SUR-AUBANCE",
      },
      {
        CITY: "SAINT-MELOIR-DES-ONDES",
      },
      {
        CITY: "SAINT-MEME-LES-CARRIERES",
      },
      {
        CITY: "SAINT-MEMMIE",
      },
      {
        CITY: "SAINT-MICHEL",
      },
      {
        CITY: "SAINT-MICHEL",
      },
      {
        CITY: "SAINT-MICHEL-CHEF-CHEF",
      },
      {
        CITY: "SAINT-MICHEL-DE-CHABRILLANOUX",
      },
      {
        CITY: "SAINT-MICHEL-DE-MAURIENNE",
      },
      {
        CITY: "SAINT-MICHEL-DE-RIEUFRET",
      },
      {
        CITY: "SAINT-MICHEL-DE-VOLANGIS",
      },
      {
        CITY: "SAINT-MICHEL-DES-ANDAINES",
      },
      {
        CITY: "SAINT-MICHEL-LE-CLOUCQ",
      },
      {
        CITY: "SAINT-MICHEL-LOBSERVATOIRE",
      },
      {
        CITY: "SAINT-MICHEL-MONT-MERCURE",
      },
      {
        CITY: "SAINT-MICHEL-SOUS-BOIS",
      },
      {
        CITY: "SAINT-MICHEL-SUR-MEURTHE",
      },
      {
        CITY: "SAINT-MICHEL-SUR-ORGE",
      },
      {
        CITY: "SAINT-MICHEL-SUR-SAVASSE",
      },
      {
        CITY: "SAINT-MICHEL-SUR-TERNOISE",
      },
      {
        CITY: "SAINT-MICHEL-TREVE",
      },
      {
        CITY: "SAINT-MIHIEL",
      },
      {
        CITY: "SAINT-MITRE-LES-REMPARTS",
      },
      {
        CITY: "SAINT-MORILLON",
      },
      {
        CITY: "SAINT-NABORD",
      },
      {
        CITY: "SAINT-NAUPHARY",
      },
      {
        CITY: "SAINT-NAZAIRE",
      },
      {
        CITY: "SAINT-NAZAIRE",
      },
      {
        CITY: "SAINT-NAZAIRE-DE-VALENTANE",
      },
      {
        CITY: "SAINT-NECTAIRE",
      },
      {
        CITY: "SAINT-NICOLAS-DE-BLIQUETUIT",
      },
      {
        CITY: "SAINT-NICOLAS-DE-LA-GRAVE",
      },
      {
        CITY: "SAINT-NICOLAS-DE-PORT",
      },
      {
        CITY: "SAINT-NICOLAS-DE-REDON",
      },
      {
        CITY: "SAINT-NICOLAS-DU-PELEM",
      },
      {
        CITY: "SAINT-NOLFF",
      },
      {
        CITY: "SAINT-NOM-LA-BRETECHE",
      },
      {
        CITY: "SAINT-OFFENGE-DESSOUS",
      },
      {
        CITY: "SAINT-OFFENGE-DESSUS",
      },
      {
        CITY: "SAINT-OMER",
      },
      {
        CITY: "SAINT-OMER-EN-CHAUSSEE",
      },
      {
        CITY: "SAINT-ORENS-DE-GAMEVILLE",
      },
      {
        CITY: "SAINT-ORENS-POUY-PETIT",
      },
      {
        CITY: "SAINT-OUEN",
      },
      {
        CITY: "SAINT-OUEN",
      },
      {
        CITY: "SAINT-OUEN-DE-THOUBERVILLE",
      },
      {
        CITY: "SAINT-OUEN-DES-BESACES",
      },
      {
        CITY: "SAINT-OUEN-DES-TOITS",
      },
      {
        CITY: "SAINT-OUEN-DU-BREUIL",
      },
      {
        CITY: "SAINT-OUEN-DU-MESNIL-OGER",
      },
      {
        CITY: "SAINT-OUEN-DU-TILLEUL",
      },
      {
        CITY: "SAINT-OUEN-EN-BRIE",
      },
      {
        CITY: "SAINT-OUEN-LES-PAREY",
      },
      {
        CITY: "SAINT-OUEN-SUR-ITON",
      },
      {
        CITY: "SAINT-OUEN-SUR-MORIN",
      },
      {
        CITY: "SAINT-OULPH",
      },
      {
        CITY: "SAINT-OURS",
      },
      {
        CITY: "SAINT-PABU",
      },
      {
        CITY: "SAINT-PAIR-DU-MONT",
      },
      {
        CITY: "SAINT-PAIR-SUR-MER",
      },
      {
        CITY: "SAINT-PAL-DE-MONS",
      },
      {
        CITY: "SAINT-PALAIS",
      },
      {
        CITY: "SAINT-PANCRE",
      },
      {
        CITY: "SAINT-PANDELON",
      },
      {
        CITY: "SAINT-PANTALEON-DE-LARCHE",
      },
      {
        CITY: "SAINT-PAPOUL",
      },
      {
        CITY: "SAINT-PARDOUX-DU-BREUIL",
      },
      {
        CITY: "SAINT-PARGOIRE",
      },
      {
        CITY: "SAINT-PARIZE-LE-CHATEL",
      },
      {
        CITY: "SAINT-PARRES-AUX-TERTRES",
      },
      {
        CITY: "SAINT-PARRES-LES-VAUDES",
      },
      {
        CITY: "SAINT-PATERNE",
      },
      {
        CITY: "SAINT-PATERNE-RACAN",
      },
      {
        CITY: "SAINT-PATHUS",
      },
      {
        CITY: "SAINT-PATRICE-DE-CLAIDS",
      },
      {
        CITY: "SAINT-PAUL",
      },
      {
        CITY: "SAINT-PAUL",
      },
      {
        CITY: "SAINT-PAUL-DE-JARRAT",
      },
      {
        CITY: "SAINT-PAUL-DE-VARCES",
      },
      {
        CITY: "SAINT-PAUL-DE-VENCE",
      },
      {
        CITY: "SAINT-PAUL-DE-VEZELIN",
      },
      {
        CITY: "SAINT-PAUL-DU-VERNAY",
      },
      {
        CITY: "SAINT-PAUL-EN-CHABLAIS",
      },
      {
        CITY: "SAINT-PAUL-EN-CORNILLON",
      },
      {
        CITY: "SAINT-PAUL-EN-FORET",
      },
      {
        CITY: "SAINT-PAUL-EN-JAREZ",
      },
      {
        CITY: "SAINT-PAUL-EN-PAREDS",
      },
      {
        CITY: "SAINT-PAUL-LES-DAX",
      },
      {
        CITY: "SAINT-PAUL-LES-DURANCE",
      },
      {
        CITY: "SAINT-PAUL-LES-ROMANS",
      },
      {
        CITY: "SAINT-PAUL-TROIS-CHATEAUX",
      },
      {
        CITY: "SAINT-PAULET-DE-CAISSON",
      },
      {
        CITY: "SAINT-PEE-SUR-NIVELLE",
      },
      {
        CITY: "SAINT-PELLERIN",
      },
      {
        CITY: "SAINT-PERAY",
      },
      {
        CITY: "SAINT-PERDON",
      },
      {
        CITY: "SAINT-PERE",
      },
      {
        CITY: "SAINT-PERE-EN-RETZ",
      },
      {
        CITY: "SAINT-PERN",
      },
      {
        CITY: "SAINT-PHILBERT-DE-BOUAINE",
      },
      {
        CITY: "SAINT-PHILBERT-DE-GRAND-LIEU",
      },
      {
        CITY: "SAINT-PHILBERT-DU-PONT-CHARRAULT",
      },
      {
        CITY: "SAINT-PHILIBERT",
      },
      {
        CITY: "SAINT-PIAT",
      },
      {
        CITY: "SAINT-PIERRE-BOIS",
      },
      {
        CITY: "SAINT-PIERRE-BROUCK",
      },
      {
        CITY: "SAINT-PIERRE-DALLEVARD",
      },
      {
        CITY: "SAINT-PIERRE-DE-BOEUF",
      },
      {
        CITY: "SAINT-PIERRE-DE-BRESSIEUX",
      },
      {
        CITY: "SAINT-PIERRE-DE-CHANDIEU",
      },
      {
        CITY: "SAINT-PIERRE-DE-JARDS",
      },
      {
        CITY: "SAINT-PIERRE-DE-LAGES",
      },
      {
        CITY: "SAINT-PIERRE-DE-MAILLE",
      },
      {
        CITY: "SAINT-PIERRE-DE-MONS",
      },
      {
        CITY: "SAINT-PIERRE-DE-PLESGUEN",
      },
      {
        CITY: "SAINT-PIERRE-DE-TRIVISY",
      },
      {
        CITY: "SAINT-PIERRE-DE-VARENGEVILLE",
      },
      {
        CITY: "SAINT-PIERRE-DE-VARENNES",
      },
      {
        CITY: "SAINT-PIERRE-DES-CORPS",
      },
      {
        CITY: "SAINT-PIERRE-DES-ECHAUBROGNES",
      },
      {
        CITY: "SAINT-PIERRE-DES-FLEURS",
      },
      {
        CITY: "SAINT-PIERRE-DU-CHEMIN",
      },
      {
        CITY: "SAINT-PIERRE-DU-MONT",
      },
      {
        CITY: "SAINT-PIERRE-DU-PALAIS",
      },
      {
        CITY: "SAINT-PIERRE-DU-PERRAY",
      },
      {
        CITY: "SAINT-PIERRE-DU-REGARD",
      },
      {
        CITY: "SAINT-PIERRE-DU-VAUVRAY",
      },
      {
        CITY: "SAINT-PIERRE-EN-PORT",
      },
      {
        CITY: "SAINT-PIERRE-EN-VAL",
      },
      {
        CITY: "SAINT-PIERRE-LA-BOURLHONNE",
      },
      {
        CITY: "SAINT-PIERRE-LA-COUR",
      },
      {
        CITY: "SAINT-PIERRE-LA-GARENNE",
      },
      {
        CITY: "SAINT-PIERRE-LA-PALUD",
      },
      {
        CITY: "SAINT-PIERRE-LAVIS",
      },
      {
        CITY: "SAINT-PIERRE-LE-CHASTEL",
      },
      {
        CITY: "SAINT-PIERRE-LE-MOUTIER",
      },
      {
        CITY: "SAINT-PIERRE-LE-VIEUX",
      },
      {
        CITY: "SAINT-PIERRE-LE-VIEUX",
      },
      {
        CITY: "SAINT-PIERRE-LES-ELBEUF",
      },
      {
        CITY: "SAINT-PIERRE-MONTLIMART",
      },
      {
        CITY: "SAINT-PIERRE-SUR-DIVES",
      },
      {
        CITY: "SAINT-PIERRE-TARENTAINE",
      },
      {
        CITY: "SAINT-PIERREVILLE",
      },
      {
        CITY: "SAINT-POL-SUR-MER",
      },
      {
        CITY: "SAINT-POL-SUR-TERNOISE",
      },
      {
        CITY: "SAINT-POMPONT",
      },
      {
        CITY: "SAINT-PONT",
      },
      {
        CITY: "SAINT-PORCHAIRE",
      },
      {
        CITY: "SAINT-PORQUIER",
      },
      {
        CITY: "SAINT-POUANGE",
      },
      {
        CITY: "SAINT-POURCAIN-SUR-SIOULE",
      },
      {
        CITY: "SAINT-PREST",
      },
      {
        CITY: "SAINT-PRIEST-DES-CHAMPS",
      },
      {
        CITY: "SAINT-PRIEST-EN-JAREZ",
      },
      {
        CITY: "SAINT-PRIEST-LA-ROCHE",
      },
      {
        CITY: "SAINT-PRIEST-LA-VETRE",
      },
      {
        CITY: "SAINT-PRIEST-TAURION",
      },
      {
        CITY: "SAINT-PRIM",
      },
      {
        CITY: "SAINT-PRIVAT",
      },
      {
        CITY: "SAINT-PRIVAT",
      },
      {
        CITY: "SAINT-PRIVAT-DE-VALLONGUE",
      },
      {
        CITY: "SAINT-PRIVAT-LA-MONTAGNE",
      },
      {
        CITY: "SAINT-PRIX",
      },
      {
        CITY: "SAINT-PROJET",
      },
      {
        CITY: "SAINT-PROUANT",
      },
      {
        CITY: "SAINT-PRYVE-SAINT-MESMIN",
      },
      {
        CITY: "SAINT-PYTHON",
      },
      {
        CITY: "SAINT-QUAY-PERROS",
      },
      {
        CITY: "SAINT-QUAY-PORTRIEUX",
      },
      {
        CITY: "SAINT-QUENTIN",
      },
      {
        CITY: "SAINT-QUENTIN-DE-BARON",
      },
      {
        CITY: "SAINT-QUENTIN-DU-DROPT",
      },
      {
        CITY: "SAINT-QUENTIN-EN-MAUGES",
      },
      {
        CITY: "SAINT-QUENTIN-FALLAVIER",
      },
      {
        CITY: "SAINT-QUENTIN-LA-POTERIE",
      },
      {
        CITY: "SAINT-QUENTIN-LE-VERGER",
      },
      {
        CITY: "SAINT-QUENTIN-LES-CHARDONNETS",
      },
      {
        CITY: "SAINT-QUENTIN-SUR-INDROIS",
      },
      {
        CITY: "SAINT-QUENTIN-SUR-LE-HOMME",
      },
      {
        CITY: "SAINT-RAMBERT-EN-BUGEY",
      },
      {
        CITY: "SAINT-REGLE",
      },
      {
        CITY: "SAINT-REMEZE",
      },
      {
        CITY: "SAINT-REMIMONT",
      },
      {
        CITY: "SAINT-REMY",
      },
      {
        CITY: "SAINT-REMY",
      },
      {
        CITY: "SAINT-REMY",
      },
      {
        CITY: "SAINT-REMY-DU-NORD",
      },
      {
        CITY: "SAINT-REMY-EN-MAUGES",
      },
      {
        CITY: "SAINT-REMY-EN-ROLLAT",
      },
      {
        CITY: "SAINT-REMY-LA-VANNE",
      },
      {
        CITY: "SAINT-REMY-LA-VARENNE",
      },
      {
        CITY: "SAINT-REMY-LES-CHEVREUSE",
      },
      {
        CITY: "SAINT-REMY-SUR-AVRE",
      },
      {
        CITY: "SAINT-REMY-SUR-DUROLLE",
      },
      {
        CITY: "SAINT-RENAN",
      },
      {
        CITY: "SAINT-REVERIEN",
      },
      {
        CITY: "SAINT-RIQUIER",
      },
      {
        CITY: "SAINT-RIRAND",
      },
      {
        CITY: "SAINT-ROBERT",
      },
      {
        CITY: "SAINT-ROCH",
      },
      {
        CITY: "SAINT-ROGATIEN",
      },
      {
        CITY: "SAINT-ROMAIN-DE-COLBOSC",
      },
      {
        CITY: "SAINT-ROMAIN-DE-LERPS",
      },
      {
        CITY: "SAINT-ROMAIN-EN-GAL",
      },
      {
        CITY: "SAINT-ROMAIN-EN-GIER",
      },
      {
        CITY: "SAINT-ROMAIN-LA-MOTTE",
      },
      {
        CITY: "SAINT-ROMAIN-LA-VIRVEE",
      },
      {
        CITY: "SAINT-ROMAIN-LACHALM",
      },
      {
        CITY: "SAINT-ROMAIN-LE-PREUX",
      },
      {
        CITY: "SAINT-ROMAIN-LE-PUY",
      },
      {
        CITY: "SAINT-ROMAIN-LES-ATHEUX",
      },
      {
        CITY: "SAINT-ROME-DE-TARN",
      },
      {
        CITY: "SAINT-SAENS",
      },
      {
        CITY: "SAINT-SAIRE",
      },
      {
        CITY: "SAINT-SAMSON-SUR-RANCE",
      },
      {
        CITY: "SAINT-SANDOUX",
      },
      {
        CITY: "SAINT-SARDOS",
      },
      {
        CITY: "SAINT-SATURNIN-DU-LIMET",
      },
      {
        CITY: "SAINT-SATURNIN-LES-APT",
      },
      {
        CITY: "SAINT-SATURNIN-LES-AVIGNON",
      },
      {
        CITY: "SAINT-SATURNIN-SUR-LOIRE",
      },
      {
        CITY: "SAINT-SAULGE",
      },
      {
        CITY: "SAINT-SAULVE",
      },
      {
        CITY: "SAINT-SAUVANT",
      },
      {
        CITY: "SAINT-SAUVEUR-DAUNIS",
      },
      {
        CITY: "SAINT-SAUVEUR-DE-MONTAGUT",
      },
      {
        CITY: "SAINT-SAUVEUR-GOUVERNET",
      },
      {
        CITY: "SAINT-SAUVEUR-LA-SAGNE",
      },
      {
        CITY: "SAINT-SAUVEUR-LE-VICOMTE",
      },
      {
        CITY: "SAINT-SAUVEUR-LENDELIN",
      },
      {
        CITY: "SAINT-SAVIN",
      },
      {
        CITY: "SAINT-SAVIN",
      },
      {
        CITY: "SAINT-SAVIN",
      },
      {
        CITY: "SAINT-SAVINIEN",
      },
      {
        CITY: "SAINT-SAVIOL",
      },
      {
        CITY: "SAINT-SAVOURNIN",
      },
      {
        CITY: "SAINT-SEBASTIEN",
      },
      {
        CITY: "SAINT-SEBASTIEN",
      },
      {
        CITY: "SAINT-SEBASTIEN-DE-MORSENT",
      },
      {
        CITY: "SAINT-SECONDIN",
      },
      {
        CITY: "SAINT-SEGAL",
      },
      {
        CITY: "SAINT-SEGLIN",
      },
      {
        CITY: "SAINT-SEINE-SUR-VINGEANNE",
      },
      {
        CITY: "SAINT-SELVE",
      },
      {
        CITY: "SAINT-SENOCH",
      },
      {
        CITY: "SAINT-SENOUX",
      },
      {
        CITY: "SAINT-SERIES",
      },
      {
        CITY: "SAINT-SERNIN",
      },
      {
        CITY: "SAINT-SERNIN-DU-BOIS",
      },
      {
        CITY: "SAINT-SERVANT",
      },
      {
        CITY: "SAINT-SEURIN-DE-CADOURNE",
      },
      {
        CITY: "SAINT-SEURIN-DE-CURSAC",
      },
      {
        CITY: "SAINT-SEURIN-DE-PRATS",
      },
      {
        CITY: "SAINT-SEVER",
      },
      {
        CITY: "SAINT-SEVER-CALVADOS",
      },
      {
        CITY: "SAINT-SIFFRET",
      },
      {
        CITY: "SAINT-SIGISMOND",
      },
      {
        CITY: "SAINT-SIMEON-DE-BRESSIEUX",
      },
      {
        CITY: "SAINT-SIMON",
      },
      {
        CITY: "SAINT-SIMON-DE-PELLOUAILLE",
      },
      {
        CITY: "SAINT-SIXT",
      },
      {
        CITY: "SAINT-SIXTE",
      },
      {
        CITY: "SAINT-SORLIN-EN-VALLOIRE",
      },
      {
        CITY: "SAINT-SOUPLET",
      },
      {
        CITY: "SAINT-SOUPPLETS",
      },
      {
        CITY: "SAINT-SULIAC",
      },
      {
        CITY: "SAINT-SULPICE-DE-FALEYRENS",
      },
      {
        CITY: "SAINT-SULPICE-DE-ROYAN",
      },
      {
        CITY: "SAINT-SULPICE-ET-CAMEYRAC",
      },
      {
        CITY: "SAINT-SULPICE-LA-FORET",
      },
      {
        CITY: "SAINT-SULPICE-LE-VIEUX",
      },
      {
        CITY: "SAINT-SULPICE-LES-FEUILLES",
      },
      {
        CITY: "SAINT-SULPICE-SUR-LEZE",
      },
      {
        CITY: "SAINT-SULPICE-SUR-RISLE",
      },
      {
        CITY: "SAINT-SYLVAIN",
      },
      {
        CITY: "SAINT-SYLVESTRE-SUR-LOT",
      },
      {
        CITY: "SAINT-SYMPHORIEN-DE-LAY",
      },
      {
        CITY: "SAINT-SYMPHORIEN-DOZON",
      },
      {
        CITY: "SAINT-SYMPHORIEN-SUR-COISE",
      },
      {
        CITY: "SAINT-SYMPHORIEN-SUR-SAONE",
      },
      {
        CITY: "SAINT-THEODORIT",
      },
      {
        CITY: "SAINT-THIBAUD-DE-COUZ",
      },
      {
        CITY: "SAINT-THIBAULT",
      },
      {
        CITY: "SAINT-THIBAULT-DES-VIGNES",
      },
      {
        CITY: "SAINT-THIBERY",
      },
      {
        CITY: "SAINT-THIERRY",
      },
      {
        CITY: "SAINT-THURIAL",
      },
      {
        CITY: "SAINT-TRIVIER-DE-COURTES",
      },
      {
        CITY: "SAINT-TRIVIER-SUR-MOIGNANS",
      },
      {
        CITY: "SAINT-TROJAN-LES-BAINS",
      },
      {
        CITY: "SAINT-TROPEZ",
      },
      {
        CITY: "SAINT-TUGDUAL",
      },
      {
        CITY: "SAINT-URBAIN",
      },
      {
        CITY: "SAINT-URBAIN",
      },
      {
        CITY: "SAINT-URBAIN-MACONCOURT",
      },
      {
        CITY: "SAINT-USAGE",
      },
      {
        CITY: "SAINT-UZE",
      },
      {
        CITY: "SAINT-VAAST-DU-VAL",
      },
      {
        CITY: "SAINT-VAAST-EN-AUGE",
      },
      {
        CITY: "SAINT-VAAST-LA-HOUGUE",
      },
      {
        CITY: "SAINT-VAAST-LES-MELLO",
      },
      {
        CITY: "SAINT-VALERY-EN-CAUX",
      },
      {
        CITY: "SAINT-VALERY-SUR-SOMME",
      },
      {
        CITY: "SAINT-VALLIER",
      },
      {
        CITY: "SAINT-VALLIER",
      },
      {
        CITY: "SAINT-VALLIER-DE-THIEY",
      },
      {
        CITY: "SAINT-VARENT",
      },
      {
        CITY: "SAINT-VAURY",
      },
      {
        CITY: "SAINT-VENANT",
      },
      {
        CITY: "SAINT-VERAND",
      },
      {
        CITY: "SAINT-VIANCE",
      },
      {
        CITY: "SAINT-VIATRE",
      },
      {
        CITY: "SAINT-VIAUD",
      },
      {
        CITY: "SAINT-VICTOR-DE-CESSIEU",
      },
      {
        CITY: "SAINT-VICTOR-LA-COSTE",
      },
      {
        CITY: "SAINT-VICTOR-SUR-ARLANC",
      },
      {
        CITY: "SAINT-VICTOR-SUR-LOIRE",
      },
      {
        CITY: "SAINT-VICTOR-SUR-RHINS",
      },
      {
        CITY: "SAINT-VICTORET",
      },
      {
        CITY: "SAINT-VICTURNIEN",
      },
      {
        CITY: "SAINT-VIGOR",
      },
      {
        CITY: "SAINT-VINCENT-DE-CONNEZAC",
      },
      {
        CITY: "SAINT-VINCENT-DE-DURFORT",
      },
      {
        CITY: "SAINT-VINCENT-DE-PAUL",
      },
      {
        CITY: "SAINT-VINCENT-DE-TYROSSE",
      },
      {
        CITY: "SAINT-VINCENT-DES-LANDES",
      },
      {
        CITY: "SAINT-VINCENT-SUR-GRAON",
      },
      {
        CITY: "SAINT-VIT",
      },
      {
        CITY: "SAINT-VITAL",
      },
      {
        CITY: "SAINT-VITE",
      },
      {
        CITY: "SAINT-VIVIEN",
      },
      {
        CITY: "SAINT-VIVIEN-DE-MEDOC",
      },
      {
        CITY: "SAINT-VRAIN",
      },
      {
        CITY: "SAINT-VULBAS",
      },
      {
        CITY: "SAINT-WITZ",
      },
      {
        CITY: "SAINT-XANDRE",
      },
      {
        CITY: "SAINT-YBARS",
      },
      {
        CITY: "SAINT-YORRE",
      },
      {
        CITY: "SAINT-YRIEIX-LA-PERCHE",
      },
      {
        CITY: "SAINT-YRIEIX-SUR-CHARENTE",
      },
      {
        CITY: "SAINT-YVI",
      },
      {
        CITY: "SAINT-YZAN-DE-SOUDIAC",
      },
      {
        CITY: "SAINT-ZACHARIE",
      },
      {
        CITY: "SAINTE-ADRESSE",
      },
      {
        CITY: "SAINTE-ANASTASIE-SUR-ISSOLE",
      },
      {
        CITY: "SAINTE-ANNE-SAINT-PRIEST",
      },
      {
        CITY: "SAINTE-ANNE-SUR-VILAINE",
      },
      {
        CITY: "SAINTE-AUSTREBERTHE",
      },
      {
        CITY: "SAINTE-BAZEILLE",
      },
      {
        CITY: "SAINTE-BLANDINE",
      },
      {
        CITY: "SAINTE-CATHERINE",
      },
      {
        CITY: "SAINTE-CATHERINE",
      },
      {
        CITY: "SAINTE-CECILE",
      },
      {
        CITY: "SAINTE-CECILE",
      },
      {
        CITY: "SAINTE-COLOMBE-DE-DURAS",
      },
      {
        CITY: "SAINTE-COLOMBE-SUR-GAND",
      },
      {
        CITY: "SAINTE-COLOMBE-SUR-GUETTE",
      },
      {
        CITY: "SAINTE-CONSORCE",
      },
      {
        CITY: "SAINTE-CROIX",
      },
      {
        CITY: "SAINTE-CROIX-AUX-MINES",
      },
      {
        CITY: "SAINTE-CROIX-EN-JAREZ",
      },
      {
        CITY: "SAINTE-CROIX-EN-PLAINE",
      },
      {
        CITY: "SAINTE-CROIX-GRAND-TONNE",
      },
      {
        CITY: "SAINTE-CROIX-HAGUE",
      },
      {
        CITY: "SAINTE-CROIX-VOLVESTRE",
      },
      {
        CITY: "SAINTE-EANNE",
      },
      {
        CITY: "SAINTE-ENIMIE",
      },
      {
        CITY: "SAINTE-EULALIE-EN-BORN",
      },
      {
        CITY: "SAINTE-EUSOYE",
      },
      {
        CITY: "SAINTE-FAUSTE",
      },
      {
        CITY: "SAINTE-FEREOLE",
      },
      {
        CITY: "SAINTE-FLAIVE-DES-LOUPS",
      },
      {
        CITY: "SAINTE-FLORENCE",
      },
      {
        CITY: "SAINTE-FLORINE",
      },
      {
        CITY: "SAINTE-FORTUNADE",
      },
      {
        CITY: "SAINTE-FOY-DE-LONGAS",
      },
      {
        CITY: "SAINTE-FOY-DE-PEYROLIERES",
      },
      {
        CITY: "SAINTE-FOY-LA-GRANDE",
      },
      {
        CITY: "SAINTE-FOY-LES-LYON",
      },
      {
        CITY: "SAINTE-GEMME-LA-PLAINE",
      },
      {
        CITY: "SAINTE-GEMME-MARTAILLAC",
      },
      {
        CITY: "SAINTE-GENEVIEVE",
      },
      {
        CITY: "SAINTE-GENEVIEVE",
      },
      {
        CITY: "SAINTE-HELENE",
      },
      {
        CITY: "SAINTE-HERMINE",
      },
      {
        CITY: "SAINTE-HONORINE-DU-FAY",
      },
      {
        CITY: "SAINTE-LIVRADE-SUR-LOT",
      },
      {
        CITY: "SAINTE-LUCE-SUR-LOIRE",
      },
      {
        CITY: "SAINTE-LUCIE DE PORTO-VECCHIO",
      },
      {
        CITY: "SAINTE-LUCIE-DE-TALLANO",
      },
      {
        CITY: "SAINTE-MARGUERITE",
      },
      {
        CITY: "SAINTE-MARGUERITE-DES-LOGES",
      },
      {
        CITY: "SAINTE-MARIE",
      },
      {
        CITY: "SAINTE-MARIE-AUX-CHENES",
      },
      {
        CITY: "SAINTE-MARIE-AUX-MINES",
      },
      {
        CITY: "SAINTE-MARIE-CAPPEL",
      },
      {
        CITY: "SAINTE-MARIE-DE-CHIGNAC",
      },
      {
        CITY: "SAINTE-MARIE-DE-CUINES",
      },
      {
        CITY: "SAINTE-MARIE-DE-RE",
      },
      {
        CITY: "SAINTE-MARIE-DU-MONT",
      },
      {
        CITY: "SAINTE-MARIE-KERQUE",
      },
      {
        CITY: "SAINTE-MARIE-LAUMONT",
      },
      {
        CITY: "SAINTE-MAURE-DE-TOURAINE",
      },
      {
        CITY: "SAINTE-MAXIME",
      },
      {
        CITY: "SAINTE-MENEHOULD",
      },
      {
        CITY: "SAINTE-MERE-EGLISE",
      },
      {
        CITY: "SAINTE-NEOMAYE",
      },
      {
        CITY: "SAINTE-OPPORTUNE-DU-BOSC",
      },
      {
        CITY: "SAINTE-OUENNE",
      },
      {
        CITY: "SAINTE-PAZANNE",
      },
      {
        CITY: "SAINTE-PREUVE",
      },
      {
        CITY: "SAINTE-RADEGONDE",
      },
      {
        CITY: "SAINTE-REINE-DE-BRETAGNE",
      },
      {
        CITY: "SAINTE-SABINE-SUR-LONGEVE",
      },
      {
        CITY: "SAINTE-SAVINE",
      },
      {
        CITY: "SAINTE-SEVE",
      },
      {
        CITY: "SAINTE-SIGOLENE",
      },
      {
        CITY: "SAINTE-SOLANGE",
      },
      {
        CITY: "SAINTE-SOULLE",
      },
      {
        CITY: "SAINTE-SUZANNE",
      },
      {
        CITY: "SAINTE-TERRE",
      },
      {
        CITY: "SAINTE-TULLE",
      },
      {
        CITY: "SAINTE-VALIERE",
      },
      {
        CITY: "SAINTE-VERGE",
      },
      {
        CITY: "SAINTES",
      },
      {
        CITY: "SAINTES-MARIES-DE-LA-MER",
      },
      {
        CITY: "SAINTRY-SUR-SEINE",
      },
      {
        CITY: "SAINTS",
      },
      {
        CITY: "SAINTS-GEOSMES",
      },
      {
        CITY: "SAINVILLE",
      },
      {
        CITY: "SAISSAC",
      },
      {
        CITY: "SAISSEVAL",
      },
      {
        CITY: "SAIVRES",
      },
      {
        CITY: "SAIX",
      },
      {
        CITY: "SALAGNON",
      },
      {
        CITY: "SALAISE-SUR-SANNE",
      },
      {
        CITY: "SALANS",
      },
      {
        CITY: "SALBERT",
      },
      {
        CITY: "SALBRIS",
      },
      {
        CITY: "SALEICH",
      },
      {
        CITY: "SALEILLES",
      },
      {
        CITY: "SALERNES",
      },
      {
        CITY: "SALERS",
      },
      {
        CITY: "SALES",
      },
      {
        CITY: "SALEUX",
      },
      {
        CITY: "SALIES-DE-BEARN",
      },
      {
        CITY: "SALIES-DU-SALAT",
      },
      {
        CITY: "SALIGNY",
      },
      {
        CITY: "SALIGNY-LE-VIF",
      },
      {
        CITY: "SALIGNY-SUR-ROUDON",
      },
      {
        CITY: "SALIN-DE-GIRAUD",
      },
      {
        CITY: "SALINDRES",
      },
      {
        CITY: "SALINS",
      },
      {
        CITY: "SALINS-LES-BAINS",
      },
      {
        CITY: "SALINS-LES-THERMES",
      },
      {
        CITY: "SALLANCHES",
      },
      {
        CITY: "SALLAUMINES",
      },
      {
        CITY: "SALLEBOEUF",
      },
      {
        CITY: "SALLERTAINE",
      },
      {
        CITY: "SALLES-DE-BARBEZIEUX",
      },
      {
        CITY: "SALLES-LA-SOURCE",
      },
      {
        CITY: "SALLES-MONGISCARD",
      },
      {
        CITY: "SALLES-SUR-MER",
      },
      {
        CITY: "SALOME",
      },
      {
        CITY: "SALON-DE-PROVENCE",
      },
      {
        CITY: "SALOUEL",
      },
      {
        CITY: "SALSES-LE-CHATEAU",
      },
      {
        CITY: "SALVAGNAC",
      },
      {
        CITY: "SALVIAC",
      },
      {
        CITY: "SALVIZINET",
      },
      {
        CITY: "SALZUIT",
      },
      {
        CITY: "SAMADET",
      },
      {
        CITY: "SAMATAN",
      },
      {
        CITY: "SAMAZAN",
      },
      {
        CITY: "SAMBIN",
      },
      {
        CITY: "SAMEON",
      },
      {
        CITY: "SAMER",
      },
      {
        CITY: "SAMOGNAT",
      },
      {
        CITY: "SAMOIS-SUR-SEINE",
      },
      {
        CITY: "SAMOREAU",
      },
      {
        CITY: "SAMPIGNY-LES-MARANGES",
      },
      {
        CITY: "SAMSON",
      },
      {
        CITY: "SAN-GIULIANO",
      },
      {
        CITY: "SAN-NICOLAO",
      },
      {
        CITY: "SANARY-SUR-MER",
      },
      {
        CITY: "SANCERRE",
      },
      {
        CITY: "SANCHEY",
      },
      {
        CITY: "SANCOURT",
      },
      {
        CITY: "SAND",
      },
      {
        CITY: "SANDILLON",
      },
      {
        CITY: "SANDOUVILLE",
      },
      {
        CITY: "SANDRANS",
      },
      {
        CITY: "SANGATTE",
      },
      {
        CITY: "SANGUINET",
      },
      {
        CITY: "SANNERVILLE",
      },
      {
        CITY: "SANNOIS",
      },
      {
        CITY: "SANSAIS",
      },
      {
        CITY: "SANT ANDREU DE SUREDA",
      },
      {
        CITY: "SANT GENIS DE FONTANES",
      },
      {
        CITY: "SANT JOAN DE PLADECORTS",
      },
      {
        CITY: "SANTA-MARIA-POGGIO",
      },
      {
        CITY: "SANTENY",
      },
      {
        CITY: "SANTES",
      },
      {
        CITY: "SANTEUIL",
      },
      {
        CITY: "SANTRANGES",
      },
      {
        CITY: "SANVIGNES-LES-MINES",
      },
      {
        CITY: "SANXAY",
      },
      {
        CITY: "SAONE",
      },
      {
        CITY: "SAPOGNE-ET-FEUCHERES",
      },
      {
        CITY: "SAPOGNE-SUR-MARCHE",
      },
      {
        CITY: "SARAMON",
      },
      {
        CITY: "SARAN",
      },
      {
        CITY: "SARCELLES",
      },
      {
        CITY: "SARCEY",
      },
      {
        CITY: "SARCICOURT",
      },
      {
        CITY: "SARDAN",
      },
      {
        CITY: "SARDIEU",
      },
      {
        CITY: "SARDON",
      },
      {
        CITY: "SARE",
      },
      {
        CITY: "SARGE-SUR-BRAYE",
      },
      {
        CITY: "SARLIAC-SUR-LISLE",
      },
      {
        CITY: "SARRALBE",
      },
      {
        CITY: "SARRALTROFF",
      },
      {
        CITY: "SARRANT",
      },
      {
        CITY: "SARRAS",
      },
      {
        CITY: "SARRAZAC",
      },
      {
        CITY: "SARRE",
      },
      {
        CITY: "SARRE-UNION",
      },
      {
        CITY: "SARREBOURG",
      },
      {
        CITY: "SARREGUEMINES",
      },
      {
        CITY: "SARREY",
      },
      {
        CITY: "SARRIANS",
      },
      {
        CITY: "SARROLA-CARCOPINO",
      },
      {
        CITY: "SARRY",
      },
      {
        CITY: "SARS-POTERIES",
      },
      {
        CITY: "SARTROUVILLE",
      },
      {
        CITY: "SARZEAU",
      },
      {
        CITY: "SASSAY",
      },
      {
        CITY: "SASSENAGE",
      },
      {
        CITY: "SASSENAY",
      },
      {
        CITY: "SASSEY-SUR-MEUSE",
      },
      {
        CITY: "SATHONAY-CAMP",
      },
      {
        CITY: "SATHONAY-VILLAGE",
      },
      {
        CITY: "SATILLIEU",
      },
      {
        CITY: "SATOLAS-ET-BONCE",
      },
      {
        CITY: "SAUBENS",
      },
      {
        CITY: "SAUBION",
      },
      {
        CITY: "SAUBRIGUES",
      },
      {
        CITY: "SAUBUSSE",
      },
      {
        CITY: "SAUCATS",
      },
      {
        CITY: "SAUDOY",
      },
      {
        CITY: "SAUGON",
      },
      {
        CITY: "SAUGUES",
      },
      {
        CITY: "SAUJON",
      },
      {
        CITY: "SAULCES-MONCLIN",
      },
      {
        CITY: "SAULCY-SUR-MEURTHE",
      },
      {
        CITY: "SAULES",
      },
      {
        CITY: "SAULGE",
      },
      {
        CITY: "SAULIEU",
      },
      {
        CITY: "SAULNES",
      },
      {
        CITY: "SAULNIERES",
      },
      {
        CITY: "SAULNOT",
      },
      {
        CITY: "SAULNY",
      },
      {
        CITY: "SAULON-LA-CHAPELLE",
      },
      {
        CITY: "SAULON-LA-RUE",
      },
      {
        CITY: "SAULT",
      },
      {
        CITY: "SAULT-BRENAZ",
      },
      {
        CITY: "SAULT-LES-RETHEL",
      },
      {
        CITY: "SAULT-SAINT-REMY",
      },
      {
        CITY: "SAULTAIN",
      },
      {
        CITY: "SAULX-LES-CHARTREUX",
      },
      {
        CITY: "SAULX-MARCHAIS",
      },
      {
        CITY: "SAULXURES",
      },
      {
        CITY: "SAULXURES-LES-NANCY",
      },
      {
        CITY: "SAULXURES-SUR-MOSELOTTE",
      },
      {
        CITY: "SAULZOIR",
      },
      {
        CITY: "SAUMANE-DE-VAUCLUSE",
      },
      {
        CITY: "SAUMERAY",
      },
      {
        CITY: "SAUMUR",
      },
      {
        CITY: "SAURAT",
      },
      {
        CITY: "SAURET-BESSERVE",
      },
      {
        CITY: "SAUSHEIM",
      },
      {
        CITY: "SAUSSAN",
      },
      {
        CITY: "SAUSSAY-LA-CAMPAGNE",
      },
      {
        CITY: "SAUSSEMESNIL",
      },
      {
        CITY: "SAUSSENAC",
      },
      {
        CITY: "SAUSSET-LES-PINS",
      },
      {
        CITY: "SAUSSEY",
      },
      {
        CITY: "SAUSSINES",
      },
      {
        CITY: "SAUTEL",
      },
      {
        CITY: "SAUTEYRARGUES",
      },
      {
        CITY: "SAUTRON",
      },
      {
        CITY: "SAUVAGE",
      },
      {
        CITY: "SAUVAGNEY",
      },
      {
        CITY: "SAUVAGNON",
      },
      {
        CITY: "SAUVAT",
      },
      {
        CITY: "SAUVE",
      },
      {
        CITY: "SAUVERNY",
      },
      {
        CITY: "SAUVESSANGES",
      },
      {
        CITY: "SAUVETERRE",
      },
      {
        CITY: "SAUVETERRE-DE-BEARN",
      },
      {
        CITY: "SAUVETERRE-DE-COMMINGES",
      },
      {
        CITY: "SAUVETERRE-DE-GUYENNE",
      },
      {
        CITY: "SAUVETERRE-LA-LEMANCE",
      },
      {
        CITY: "SAUVIAN",
      },
      {
        CITY: "SAUVIGNEY-LES-PESMES",
      },
      {
        CITY: "SAUVIGNY-LES-BOIS",
      },
      {
        CITY: "SAUXILLANGES",
      },
      {
        CITY: "SAUZE",
      },
      {
        CITY: "SAUZE-VAUSSAIS",
      },
      {
        CITY: "SAUZELLES",
      },
      {
        CITY: "SAUZET",
      },
      {
        CITY: "SAUZET",
      },
      {
        CITY: "SAUZON",
      },
      {
        CITY: "SAVASSE",
      },
      {
        CITY: "SAVENAY",
      },
      {
        CITY: "SAVENNIERES",
      },
      {
        CITY: "SAVERDUN",
      },
      {
        CITY: "SAVERNE",
      },
      {
        CITY: "SAVIERES",
      },
      {
        CITY: "SAVIGNAC-LES-EGLISES",
      },
      {
        CITY: "SAVIGNAC-SUR-LEYZE",
      },
      {
        CITY: "SAVIGNE",
      },
      {
        CITY: "SAVIGNE-SOUS-LE-LUDE",
      },
      {
        CITY: "SAVIGNEUX",
      },
      {
        CITY: "SAVIGNY",
      },
      {
        CITY: "SAVIGNY-EN-REVERMONT",
      },
      {
        CITY: "SAVIGNY-EN-SEPTAINE",
      },
      {
        CITY: "SAVIGNY-LE-SEC",
      },
      {
        CITY: "SAVIGNY-LE-TEMPLE",
      },
      {
        CITY: "SAVIGNY-LES-BEAUNE",
      },
      {
        CITY: "SAVIGNY-SUR-BRAYE",
      },
      {
        CITY: "SAVIGNY-SUR-CLAIRIS",
      },
      {
        CITY: "SAVIGNY-SUR-ORGE",
      },
      {
        CITY: "SAVOIE",
      },
      {
        CITY: "SAVONNIERES",
      },
      {
        CITY: "SAVOUGES",
      },
      {
        CITY: "SAVOYEUX",
      },
      {
        CITY: "SAVY",
      },
      {
        CITY: "SAVY-BERLETTE",
      },
      {
        CITY: "SAYAT",
      },
      {
        CITY: "SCAER",
      },
      {
        CITY: "SCEAUX",
      },
      {
        CITY: "SCEAUX-DU-GATINAIS",
      },
      {
        CITY: "SCEAUX-SUR-HUISNE",
      },
      {
        CITY: "SCHARRACHBERGHEIM-IRMSTETT",
      },
      {
        CITY: "SCHERWILLER",
      },
      {
        CITY: "SCHILLERSDORF",
      },
      {
        CITY: "SCHILTIGHEIM",
      },
      {
        CITY: "SCHIRMECK",
      },
      {
        CITY: "SCHIRRHEIN",
      },
      {
        CITY: "SCHMITTVILLER",
      },
      {
        CITY: "SCHNERSHEIM",
      },
      {
        CITY: "SCHOENECK",
      },
      {
        CITY: "SCHOPPERTEN",
      },
      {
        CITY: "SCHORBACH",
      },
      {
        CITY: "SCHWEIGHOUSE-THANN",
      },
      {
        CITY: "SCHWENHEIM",
      },
      {
        CITY: "SCIENTRIER",
      },
      {
        CITY: "SCIEZ",
      },
      {
        CITY: "SCIONZIER",
      },
      {
        CITY: "SCORBE-CLAIRVAUX",
      },
      {
        CITY: "SCRIGNAC",
      },
      {
        CITY: "SCY-CHAZELLES",
      },
      {
        CITY: "SEBAZAC-CONCOURES",
      },
      {
        CITY: "SEBECOURT",
      },
      {
        CITY: "SEBOURG",
      },
      {
        CITY: "SECHEVAL",
      },
      {
        CITY: "SECLIN",
      },
      {
        CITY: "SECONDIGNY",
      },
      {
        CITY: "SEDAN",
      },
      {
        CITY: "SEDZERE",
      },
      {
        CITY: "SEES",
      },
      {
        CITY: "SEEZ",
      },
      {
        CITY: "SEGALAS",
      },
      {
        CITY: "SEGNY",
      },
      {
        CITY: "SEGONZAC",
      },
      {
        CITY: "SEGONZAT",
      },
      {
        CITY: "SEGRE",
      },
      {
        CITY: "SEGRIE",
      },
      {
        CITY: "SEGRY",
      },
      {
        CITY: "SEICHAMPS",
      },
      {
        CITY: "SEICHES-SUR-LE-LOIR",
      },
      {
        CITY: "SEIGNALENS",
      },
      {
        CITY: "SEIGNELAY",
      },
      {
        CITY: "SEIGNOSSE",
      },
      {
        CITY: "SEILH",
      },
      {
        CITY: "SEILHAC",
      },
      {
        CITY: "SEINE-PORT",
      },
      {
        CITY: "SEINGBOUSE",
      },
      {
        CITY: "SEISSAN",
      },
      {
        CITY: "SELLE",
      },
      {
        CITY: "SELLES",
      },
      {
        CITY: "SELLES-SAINT-DENIS",
      },
      {
        CITY: "SELLES-SUR-CHER",
      },
      {
        CITY: "SELLES-SUR-NAHON",
      },
      {
        CITY: "SELLIERES",
      },
      {
        CITY: "SELOMMES",
      },
      {
        CITY: "SELONCOURT",
      },
      {
        CITY: "SELONGEY",
      },
      {
        CITY: "SELTZ",
      },
      {
        CITY: "SEMALENS",
      },
      {
        CITY: "SEMBLANCAY",
      },
      {
        CITY: "SEMEAC",
      },
      {
        CITY: "SEMEACQ-BLACHON",
      },
      {
        CITY: "SEMECOURT",
      },
      {
        CITY: "SEMOINE",
      },
      {
        CITY: "SEMOUTIERS-MONTSAON",
      },
      {
        CITY: "SEMOY",
      },
      {
        CITY: "SEMPIGNY",
      },
      {
        CITY: "SEMUR-EN-BRIONNAIS",
      },
      {
        CITY: "SENAN",
      },
      {
        CITY: "SENAS",
      },
      {
        CITY: "SENDETS",
      },
      {
        CITY: "SENE",
      },
      {
        CITY: "SENLECQUES",
      },
      {
        CITY: "SENLIS",
      },
      {
        CITY: "SENNECE-LES-MACON",
      },
      {
        CITY: "SENNECEY-LE-GRAND",
      },
      {
        CITY: "SENNELY",
      },
      {
        CITY: "SENNEVILLE-SUR-FECAMP",
      },
      {
        CITY: "SENONCHES",
      },
      {
        CITY: "SENONES",
      },
      {
        CITY: "SENONGES",
      },
      {
        CITY: "SENOZAN",
      },
      {
        CITY: "SENS",
      },
      {
        CITY: "SENS-DE-BRETAGNE",
      },
      {
        CITY: "SENTHEIM",
      },
      {
        CITY: "SENUC",
      },
      {
        CITY: "SEPMERIES",
      },
      {
        CITY: "SEPPOIS-LE-BAS",
      },
      {
        CITY: "SEPPOIS-LE-HAUT",
      },
      {
        CITY: "SEPT-FORGES",
      },
      {
        CITY: "SEPT-SAULX",
      },
      {
        CITY: "SEPTEME",
      },
      {
        CITY: "SEPTEMES-LES-VALLONS",
      },
      {
        CITY: "SEPTEUIL",
      },
      {
        CITY: "SEPTFONDS",
      },
      {
        CITY: "SEPTVAUX",
      },
      {
        CITY: "SEQUEDIN",
      },
      {
        CITY: "SERAINCOURT",
      },
      {
        CITY: "SERANS",
      },
      {
        CITY: "SERAUCOURT-LE-GRAND",
      },
      {
        CITY: "SERBANNES",
      },
      {
        CITY: "SERDINYA",
      },
      {
        CITY: "SEREILHAC",
      },
      {
        CITY: "SEREIN",
      },
      {
        CITY: "SEREMANGE-ERZANGE",
      },
      {
        CITY: "SERENT",
      },
      {
        CITY: "SEREZIN-DE-LA-TOUR",
      },
      {
        CITY: "SEREZIN-DU-RHONE",
      },
      {
        CITY: "SERGEAC",
      },
      {
        CITY: "SERGY",
      },
      {
        CITY: "SERIFONTAINE",
      },
      {
        CITY: "SERIGNAN",
      },
      {
        CITY: "SERIGNAN-DU-COMTAT",
      },
      {
        CITY: "SERIGNE",
      },
      {
        CITY: "SERMAISES",
      },
      {
        CITY: "SERMAMAGNY",
      },
      {
        CITY: "SERMERIEU",
      },
      {
        CITY: "SERMOISE",
      },
      {
        CITY: "SERMOYER",
      },
      {
        CITY: "SERNHAC",
      },
      {
        CITY: "SEROCOURT",
      },
      {
        CITY: "SERONVILLE",
      },
      {
        CITY: "SERQUES",
      },
      {
        CITY: "SERQUIGNY",
      },
      {
        CITY: "SERRE-LES-SAPINS",
      },
      {
        CITY: "SERRES",
      },
      {
        CITY: "SERRES-CASTET",
      },
      {
        CITY: "SERRES-GASTON",
      },
      {
        CITY: "SERRES-MORLAAS",
      },
      {
        CITY: "SERRES-SAINTE-MARIE",
      },
      {
        CITY: "SERRES-SUR-ARGET",
      },
      {
        CITY: "SERRIERES",
      },
      {
        CITY: "SERRIERES-DE-BRIORD",
      },
      {
        CITY: "SERRIERES-EN-CHAUTAGNE",
      },
      {
        CITY: "SERRIS",
      },
      {
        CITY: "SERROUVILLE",
      },
      {
        CITY: "SERS",
      },
      {
        CITY: "SERVAIS",
      },
      {
        CITY: "SERVANCE",
      },
      {
        CITY: "SERVANT",
      },
      {
        CITY: "SERVAS",
      },
      {
        CITY: "SERVAVILLE-SALMONVILLE",
      },
      {
        CITY: "SERVIAN",
      },
      {
        CITY: "SERVON",
      },
      {
        CITY: "SERVON-SUR-VILAINE",
      },
      {
        CITY: "SERY",
      },
      {
        CITY: "SERY-LES-MEZIERES",
      },
      {
        CITY: "SESSENHEIM",
      },
      {
        CITY: "SEUGY",
      },
      {
        CITY: "SEUIL",
      },
      {
        CITY: "SEUILLET",
      },
      {
        CITY: "SEURRE",
      },
      {
        CITY: "SEVELINGES",
      },
      {
        CITY: "SEVERAC",
      },
      {
        CITY: "SEVERAC-LE-CHATEAU",
      },
      {
        CITY: "SEVEUX",
      },
      {
        CITY: "SEVRAN",
      },
      {
        CITY: "SEVRES-ANXAUMONT",
      },
      {
        CITY: "SEVREY",
      },
      {
        CITY: "SEVRIER",
      },
      {
        CITY: "SEWEN",
      },
      {
        CITY: "SEXEY-AUX-FORGES",
      },
      {
        CITY: "SEYCHES",
      },
      {
        CITY: "SEYNE-LES-ALPES",
      },
      {
        CITY: "SEYNOD",
      },
      {
        CITY: "SEYRE",
      },
      {
        CITY: "SEYSSEL",
      },
      {
        CITY: "SEYSSES",
      },
      {
        CITY: "SEYSSINET",
      },
      {
        CITY: "SEYSSINET-PARISET",
      },
      {
        CITY: "SEYSSINS",
      },
      {
        CITY: "SEYSSUEL",
      },
      {
        CITY: "SEZANNE",
      },
      {
        CITY: "SIBIRIL",
      },
      {
        CITY: "SICCIEU-SAINT-JULIEN-ET-CARISIEU",
      },
      {
        CITY: "SIEGEN",
      },
      {
        CITY: "SIERCK-LES-BAINS",
      },
      {
        CITY: "SIERENTZ",
      },
      {
        CITY: "SIERVILLE",
      },
      {
        CITY: "SIGEAN",
      },
      {
        CITY: "SIGLOY",
      },
      {
        CITY: "SIGNES",
      },
      {
        CITY: "SIGNY",
      },
      {
        CITY: "SIGNY-LE-PETIT",
      },
      {
        CITY: "SIGNY-SIGNETS",
      },
      {
        CITY: "SIGOGNE",
      },
      {
        CITY: "SIGOULES",
      },
      {
        CITY: "SIGOURNAIS",
      },
      {
        CITY: "SIGY-EN-BRAY",
      },
      {
        CITY: "SILFIAC",
      },
      {
        CITY: "SILLANS",
      },
      {
        CITY: "SILLE-LE-GUILLAUME",
      },
      {
        CITY: "SILLERY",
      },
      {
        CITY: "SILLINGY",
      },
      {
        CITY: "SILLY-LA-POTERIE",
      },
      {
        CITY: "SILLY-LE-LONG",
      },
      {
        CITY: "SILLY-SUR-NIED",
      },
      {
        CITY: "SILLY-TILLARD",
      },
      {
        CITY: "SIMANDRE",
      },
      {
        CITY: "SIMANDRES",
      },
      {
        CITY: "SIMIANE-COLLONGUE",
      },
      {
        CITY: "SIMORRE",
      },
      {
        CITY: "SIN-LE-NOBLE",
      },
      {
        CITY: "SINARD",
      },
      {
        CITY: "SINCENY",
      },
      {
        CITY: "SION-LES-MINES",
      },
      {
        CITY: "SIRAN",
      },
      {
        CITY: "SIREUIL",
      },
      {
        CITY: "SIROS",
      },
      {
        CITY: "SISCO",
      },
      {
        CITY: "SISSONNE",
      },
      {
        CITY: "SISSY",
      },
      {
        CITY: "SISTERON",
      },
      {
        CITY: "SIVRY-COURTRY",
      },
      {
        CITY: "SIVRY-SUR-MEUSE",
      },
      {
        CITY: "SIX-FOURS-LES-PLAGES",
      },
      {
        CITY: "SIZUN",
      },
      {
        CITY: "SMARVES",
      },
      {
        CITY: "SOCHAUX",
      },
      {
        CITY: "SOIGNOLLES-EN-BRIE",
      },
      {
        CITY: "SOING-CUBRY-CHARENTENAY",
      },
      {
        CITY: "SOISSONS",
      },
      {
        CITY: "SOISSONS-SUR-NACEY",
      },
      {
        CITY: "SOISY-SOUS-MONTMORENCY",
      },
      {
        CITY: "SOISY-SUR-ECOLE",
      },
      {
        CITY: "SOISY-SUR-SEINE",
      },
      {
        CITY: "SOIZE",
      },
      {
        CITY: "SOLAIZE",
      },
      {
        CITY: "SOLARO",
      },
      {
        CITY: "SOLERS",
      },
      {
        CITY: "SOLESMES",
      },
      {
        CITY: "SOLESMES",
      },
      {
        CITY: "SOLEYMIEUX",
      },
      {
        CITY: "SOLFERINO",
      },
      {
        CITY: "SOLGNE",
      },
      {
        CITY: "SOLIERS",
      },
      {
        CITY: "SOLIGNAC",
      },
      {
        CITY: "SOLIGNAC-SOUS-ROCHE",
      },
      {
        CITY: "SOLIGNAC-SUR-LOIRE",
      },
      {
        CITY: "SOLIGNY-LA-TRAPPE",
      },
      {
        CITY: "SOLLIES-PONT",
      },
      {
        CITY: "SOLLIES-TOUCAS",
      },
      {
        CITY: "SOLLIES-VILLE",
      },
      {
        CITY: "SOLOGNY",
      },
      {
        CITY: "SOMAIN",
      },
      {
        CITY: "SOMBACOUR",
      },
      {
        CITY: "SOMBERNON",
      },
      {
        CITY: "SOMLOIRE",
      },
      {
        CITY: "SOMMAING",
      },
      {
        CITY: "SOMME",
      },
      {
        CITY: "SOMMECAISE",
      },
      {
        CITY: "SOMMEDIEUE",
      },
      {
        CITY: "SOMMELONNE",
      },
      {
        CITY: "SOMMERANCE",
      },
      {
        CITY: "SOMMERVIEU",
      },
      {
        CITY: "SOMMERVILLER",
      },
      {
        CITY: "SOMMIERES",
      },
      {
        CITY: "SOMMIERES-DU-CLAIN",
      },
      {
        CITY: "SONCHAMP",
      },
      {
        CITY: "SONDERNACH",
      },
      {
        CITY: "SONGEONS",
      },
      {
        CITY: "SONNAY",
      },
      {
        CITY: "SONNAZ",
      },
      {
        CITY: "SONZAY",
      },
      {
        CITY: "SOORTS-HOSSEGOR",
      },
      {
        CITY: "SORBEY",
      },
      {
        CITY: "SORBIERS",
      },
      {
        CITY: "SORCY-SAINT-MARTIN",
      },
      {
        CITY: "SORE",
      },
      {
        CITY: "SOREDE",
      },
      {
        CITY: "SOREL-EN-VIMEU",
      },
      {
        CITY: "SOREL-MOUSSEL",
      },
      {
        CITY: "SOREZE",
      },
      {
        CITY: "SORGES",
      },
      {
        CITY: "SORGUES",
      },
      {
        CITY: "SORIGNY",
      },
      {
        CITY: "SORMONNE",
      },
      {
        CITY: "SORNAY",
      },
      {
        CITY: "SORQUAINVILLE",
      },
      {
        CITY: "SORRUS",
      },
      {
        CITY: "SORT-EN-CHALOSSE",
      },
      {
        CITY: "SOSPEL",
      },
      {
        CITY: "SOTTEVAST",
      },
      {
        CITY: "SOTTEVILLE",
      },
      {
        CITY: "SOTTEVILLE-SUR-MER",
      },
      {
        CITY: "SOUASTRE",
      },
      {
        CITY: "SOUBISE",
      },
      {
        CITY: "SOUBLECAUSE",
      },
      {
        CITY: "SOUCHEZ",
      },
      {
        CITY: "SOUCHT",
      },
      {
        CITY: "SOUCIEU-EN-JARREST",
      },
      {
        CITY: "SOUCY",
      },
      {
        CITY: "SOUDAN",
      },
      {
        CITY: "SOUES",
      },
      {
        CITY: "SOUESMES",
      },
      {
        CITY: "SOUFFELWEYERSHEIM",
      },
      {
        CITY: "SOUFFLENHEIM",
      },
      {
        CITY: "SOUGY",
      },
      {
        CITY: "SOUGY-SUR-LOIRE",
      },
      {
        CITY: "SOUILHANELS",
      },
      {
        CITY: "SOUILLAC",
      },
      {
        CITY: "SOULAC-SUR-MER",
      },
      {
        CITY: "SOULAINCOURT",
      },
      {
        CITY: "SOULAINES-DHUYS",
      },
      {
        CITY: "SOULAINES-SUR-AUBANCE",
      },
      {
        CITY: "SOULAIRE-ET-BOURG",
      },
      {
        CITY: "SOULANGES",
      },
      {
        CITY: "SOULANGIS",
      },
      {
        CITY: "SOULGE-SUR-OUETTE",
      },
      {
        CITY: "SOULIERES",
      },
      {
        CITY: "SOULIGNE-SOUS-BALLON",
      },
      {
        CITY: "SOULITRE",
      },
      {
        CITY: "SOULLANS",
      },
      {
        CITY: "SOULTZ-HAUT-RHIN",
      },
      {
        CITY: "SOULTZ-SOUS-FORETS",
      },
      {
        CITY: "SOULTZEREN",
      },
      {
        CITY: "SOULTZMATT",
      },
      {
        CITY: "SOULVACHE",
      },
      {
        CITY: "SOUMERAS",
      },
      {
        CITY: "SOUMOULOU",
      },
      {
        CITY: "SOUPPES-SUR-LOING",
      },
      {
        CITY: "SOUPROSSE",
      },
      {
        CITY: "SOURAIDE",
      },
      {
        CITY: "SOURCIEUX-LES-MINES",
      },
      {
        CITY: "SOURDEVAL",
      },
      {
        CITY: "SOURDON",
      },
      {
        CITY: "SOURDUN",
      },
      {
        CITY: "SOURRIBES",
      },
      {
        CITY: "SOURS",
      },
      {
        CITY: "SOUSPIERRE",
      },
      {
        CITY: "SOUSTONS",
      },
      {
        CITY: "SOUVANS",
      },
      {
        CITY: "SOUVIGNE",
      },
      {
        CITY: "SOUVIGNE-SUR-SARTHE",
      },
      {
        CITY: "SOUVIGNY",
      },
      {
        CITY: "SOUVIGNY-EN-SOLOGNE",
      },
      {
        CITY: "SOUZAY-CHAMPIGNY",
      },
      {
        CITY: "SOUZY",
      },
      {
        CITY: "SOUZY-LA-BRICHE",
      },
      {
        CITY: "SOYAUX",
      },
      {
        CITY: "SOYERS",
      },
      {
        CITY: "SOYONS",
      },
      {
        CITY: "SPAY",
      },
      {
        CITY: "SPECHBACH-LE-BAS",
      },
      {
        CITY: "SPECHBACH-LE-HAUT",
      },
      {
        CITY: "SPERACEDES",
      },
      {
        CITY: "SPEZET",
      },
      {
        CITY: "SPICHEREN",
      },
      {
        CITY: "SPINCOURT",
      },
      {
        CITY: "SPONVILLE",
      },
      {
        CITY: "SPYCKER",
      },
      {
        CITY: "SQUIFFIEC",
      },
      {
        CITY: "ST CHAMAS",
      },
      {
        CITY: "ST LAURENT DES ARBRES",
      },
      {
        CITY: "ST-MALO",
      },
      {
        CITY: "STAFFELFELDEN",
      },
      {
        CITY: "STAINS",
      },
      {
        CITY: "STEENBECQUE",
      },
      {
        CITY: "STEENVOORDE",
      },
      {
        CITY: "STEENWERCK",
      },
      {
        CITY: "STEIGE",
      },
      {
        CITY: "STEINBACH",
      },
      {
        CITY: "STEINBOURG",
      },
      {
        CITY: "STELLA-PLAGE",
      },
      {
        CITY: "STENAY",
      },
      {
        CITY: "STILL",
      },
      {
        CITY: "STIRING-WENDEL",
      },
      {
        CITY: "STOSSWIHR",
      },
      {
        CITY: "STRASBOURG",
      },
      {
        CITY: "STRAZEELE",
      },
      {
        CITY: "STRUETH",
      },
      {
        CITY: "SUCCIEU",
      },
      {
        CITY: "SUCE-SUR-ERDRE",
      },
      {
        CITY: "SUCY-EN-BRIE",
      },
      {
        CITY: "SUHESCUN",
      },
      {
        CITY: "SUIN",
      },
      {
        CITY: "SUIPPES",
      },
      {
        CITY: "SULLY-LA-CHAPELLE",
      },
      {
        CITY: "SULLY-SUR-LOIRE",
      },
      {
        CITY: "SULNIAC",
      },
      {
        CITY: "SUNDHOFFEN",
      },
      {
        CITY: "SUPT",
      },
      {
        CITY: "SURAT",
      },
      {
        CITY: "SURBA",
      },
      {
        CITY: "SURESNES",
      },
      {
        CITY: "SURGERES",
      },
      {
        CITY: "SURGY",
      },
      {
        CITY: "SURIN",
      },
      {
        CITY: "SURQUES",
      },
      {
        CITY: "SURTAINVILLE",
      },
      {
        CITY: "SURVILLE",
      },
      {
        CITY: "SURVILLE",
      },
      {
        CITY: "SURVILLIERS",
      },
      {
        CITY: "SURY-EN-VAUX",
      },
      {
        CITY: "SURY-LE-COMTAL",
      },
      {
        CITY: "SURZUR",
      },
      {
        CITY: "SUS",
      },
      {
        CITY: "SUSSARGUES",
      },
      {
        CITY: "SUZANNE",
      },
      {
        CITY: "SUZE-LA-ROUSSE",
      },
      {
        CITY: "TABANAC",
      },
      {
        CITY: "TACOIGNIERES",
      },
      {
        CITY: "TADEN",
      },
      {
        CITY: "TAGLIO-ISOLACCIO",
      },
      {
        CITY: "TAGNON",
      },
      {
        CITY: "TAILLADES",
      },
      {
        CITY: "TAILLEBOURG",
      },
      {
        CITY: "TAILLIS",
      },
      {
        CITY: "TAILLY",
      },
      {
        CITY: "TAINTRUX",
      },
      {
        CITY: "TAISSY",
      },
      {
        CITY: "TAIZE-AIZIE",
      },
      {
        CITY: "TAJAN",
      },
      {
        CITY: "TALANGE",
      },
      {
        CITY: "TALANT",
      },
      {
        CITY: "TALENCE",
      },
      {
        CITY: "TALENSAC",
      },
      {
        CITY: "TALLARD",
      },
      {
        CITY: "TALLENDE",
      },
      {
        CITY: "TALLER",
      },
      {
        CITY: "TALLOIRES",
      },
      {
        CITY: "TALLUD-SAINTE-GEMME",
      },
      {
        CITY: "TALON",
      },
      {
        CITY: "TALUYERS",
      },
      {
        CITY: "TAMNAY-EN-BAZOIS",
      },
      {
        CITY: "TANCROU",
      },
      {
        CITY: "TANINGES",
      },
      {
        CITY: "TANNERON",
      },
      {
        CITY: "TARADEAU",
      },
      {
        CITY: "TARARE",
      },
      {
        CITY: "TARASCON",
      },
      {
        CITY: "TARASCON-SUR-ARIEGE",
      },
      {
        CITY: "TARBES",
      },
      {
        CITY: "TARCENAY",
      },
      {
        CITY: "TARENTAISE",
      },
      {
        CITY: "TARGASSONNE",
      },
      {
        CITY: "TARGON",
      },
      {
        CITY: "TARNES",
      },
      {
        CITY: "TARNOS",
      },
      {
        CITY: "TARTARAS",
      },
      {
        CITY: "TARTAS",
      },
      {
        CITY: "TARTIGNY",
      },
      {
        CITY: "TASSILLE",
      },
      {
        CITY: "TASSIN-LA-DEMI-LUNE",
      },
      {
        CITY: "TATINGHEM",
      },
      {
        CITY: "TAULE",
      },
      {
        CITY: "TAULIGNAN",
      },
      {
        CITY: "TAUPONT",
      },
      {
        CITY: "TAURIAC",
      },
      {
        CITY: "TAURIAC-DE-CAMARES",
      },
      {
        CITY: "TAUTAVEL",
      },
      {
        CITY: "TAUXIERES-MUTRY",
      },
      {
        CITY: "TAUXIGNY",
      },
      {
        CITY: "TAVAUX",
      },
      {
        CITY: "TAVEL",
      },
      {
        CITY: "TAVERA",
      },
      {
        CITY: "TAVERNES",
      },
      {
        CITY: "TAVERNY",
      },
      {
        CITY: "TAVERS",
      },
      {
        CITY: "TAVEY",
      },
      {
        CITY: "TAYRAC",
      },
      {
        CITY: "TECOU",
      },
      {
        CITY: "TEILLET-ARGENTY",
      },
      {
        CITY: "TEISSIERES-DE-CORNET",
      },
      {
        CITY: "TELGRUC-SUR-MER",
      },
      {
        CITY: "TELOCHE",
      },
      {
        CITY: "TEMPLEMARS",
      },
      {
        CITY: "TEMPLEUVE",
      },
      {
        CITY: "TENCE",
      },
      {
        CITY: "TENDE",
      },
      {
        CITY: "TENDON",
      },
      {
        CITY: "TERCE",
      },
      {
        CITY: "TERCIS-LES-BAINS",
      },
      {
        CITY: "TERDEGHEM",
      },
      {
        CITY: "TERGNIER",
      },
      {
        CITY: "TERNAND",
      },
      {
        CITY: "TERNAY",
      },
      {
        CITY: "TERNAY",
      },
      {
        CITY: "TERRASSON-LAVILLEDIEU",
      },
      {
        CITY: "TERRAUBE",
      },
      {
        CITY: "TERREBASSE",
      },
      {
        CITY: "TERSSAC",
      },
      {
        CITY: "TERVES",
      },
      {
        CITY: "TERVILLE",
      },
      {
        CITY: "TESSY-SUR-VIRE",
      },
      {
        CITY: "TETEGHEM",
      },
      {
        CITY: "TEUILLAC",
      },
      {
        CITY: "TEURTHEVILLE-HAGUE",
      },
      {
        CITY: "TEYJAT",
      },
      {
        CITY: "TEYRAN",
      },
      {
        CITY: "THAIMS",
      },
      {
        CITY: "THAIRE",
      },
      {
        CITY: "THAL-DRULINGEN",
      },
      {
        CITY: "THANN",
      },
      {
        CITY: "THAON",
      },
      {
        CITY: "THAON-LES-VOSGES",
      },
      {
        CITY: "THAURON",
      },
      {
        CITY: "THEDING",
      },
      {
        CITY: "THEGRA",
      },
      {
        CITY: "THEIL-SUR-VANNE",
      },
      {
        CITY: "THEILLAY",
      },
      {
        CITY: "THEIX",
      },
      {
        CITY: "THEIZE",
      },
      {
        CITY: "THELUS",
      },
      {
        CITY: "THEMERICOURT",
      },
      {
        CITY: "THENAY",
      },
      {
        CITY: "THENELLES",
      },
      {
        CITY: "THENEZAY",
      },
      {
        CITY: "THENISSEY",
      },
      {
        CITY: "THENNELIERES",
      },
      {
        CITY: "THENNES",
      },
      {
        CITY: "THENON",
      },
      {
        CITY: "THEOULE-SUR-MER",
      },
      {
        CITY: "THERDONNE",
      },
      {
        CITY: "THEROUANNE",
      },
      {
        CITY: "THEUVILLE",
      },
      {
        CITY: "THEUVILLE-AUX-MAILLOTS",
      },
      {
        CITY: "THEYS",
      },
      {
        CITY: "THEZAN-LES-BEZIERS",
      },
      {
        CITY: "THEZE",
      },
      {
        CITY: "THEZIERS",
      },
      {
        CITY: "THEZY-GLIMONT",
      },
      {
        CITY: "THIAIS",
      },
      {
        CITY: "THIANT",
      },
      {
        CITY: "THIBERVILLE",
      },
      {
        CITY: "THIEFOSSE",
      },
      {
        CITY: "THIERGEVILLE",
      },
      {
        CITY: "THIERS",
      },
      {
        CITY: "THIERS-SUR-THEVE",
      },
      {
        CITY: "THIERVILLE",
      },
      {
        CITY: "THIERVILLE-SUR-MEUSE",
      },
      {
        CITY: "THIETREVILLE",
      },
      {
        CITY: "THIEULOY-SAINT-ANTOINE",
      },
      {
        CITY: "THIEVILLE",
      },
      {
        CITY: "THIL",
      },
      {
        CITY: "THIL",
      },
      {
        CITY: "THILAY",
      },
      {
        CITY: "THILOUZE",
      },
      {
        CITY: "THIMERT-GATELLES",
      },
      {
        CITY: "THIMORY",
      },
      {
        CITY: "THIN-LE-MOUTIER",
      },
      {
        CITY: "THIONVILLE",
      },
      {
        CITY: "THISE",
      },
      {
        CITY: "THIVARS",
      },
      {
        CITY: "THIVENCELLE",
      },
      {
        CITY: "THIVERVAL-GRIGNON",
      },
      {
        CITY: "THIVIERS",
      },
      {
        CITY: "THIZY-LES-BOURGS",
      },
      {
        CITY: "THODURE",
      },
      {
        CITY: "THOIRETTE",
      },
      {
        CITY: "THOIRY",
      },
      {
        CITY: "THOIRY",
      },
      {
        CITY: "THOISSEY",
      },
      {
        CITY: "THOMERY",
      },
      {
        CITY: "THONES",
      },
      {
        CITY: "THONNANCE-LES-JOINVILLE",
      },
      {
        CITY: "THONON-LES-BAINS",
      },
      {
        CITY: "THORAISE",
      },
      {
        CITY: "THORENS-GLIERES",
      },
      {
        CITY: "THORIGNY",
      },
      {
        CITY: "THORIGNY-SUR-MARNE",
      },
      {
        CITY: "THOU",
      },
      {
        CITY: "THOUARE-SUR-LOIRE",
      },
      {
        CITY: "THOUARS",
      },
      {
        CITY: "THOUROTTE",
      },
      {
        CITY: "THOUX",
      },
      {
        CITY: "THUIR",
      },
      {
        CITY: "THUIT-HEBERT",
      },
      {
        CITY: "THULAY",
      },
      {
        CITY: "THUMERIES",
      },
      {
        CITY: "THUN-SAINT-AMAND",
      },
      {
        CITY: "THURAGEAU",
      },
      {
        CITY: "THURE",
      },
      {
        CITY: "THURET",
      },
      {
        CITY: "THURINS",
      },
      {
        CITY: "THURY-HARCOURT",
      },
      {
        CITY: "THUSY",
      },
      {
        CITY: "THYEZ",
      },
      {
        CITY: "TIERCE",
      },
      {
        CITY: "TIERCELET",
      },
      {
        CITY: "TIGERY",
      },
      {
        CITY: "TIGNES",
      },
      {
        CITY: "TIGNIEU-JAMEYZIEU",
      },
      {
        CITY: "TIGY",
      },
      {
        CITY: "TIL-CHATEL",
      },
      {
        CITY: "TILLAY-LE-PENEUX",
      },
      {
        CITY: "TILLE",
      },
      {
        CITY: "TILLENAY",
      },
      {
        CITY: "TILLIERES",
      },
      {
        CITY: "TILLIERES-SUR-AVRE",
      },
      {
        CITY: "TILLOY-ET-BELLAY",
      },
      {
        CITY: "TILLOY-LEZ-CAMBRAI",
      },
      {
        CITY: "TILLOY-LEZ-MARCHIENNES",
      },
      {
        CITY: "TILLY-SUR-MEUSE",
      },
      {
        CITY: "TILQUES",
      },
      {
        CITY: "TINCHEBRAY",
      },
      {
        CITY: "TINCQUES",
      },
      {
        CITY: "TINCRY",
      },
      {
        CITY: "TINQUEUX",
      },
      {
        CITY: "TINTENIAC",
      },
      {
        CITY: "TIRANGES",
      },
      {
        CITY: "TIREPIED",
      },
      {
        CITY: "TIVERNON",
      },
      {
        CITY: "TOCQUEVILLE",
      },
      {
        CITY: "TOCQUEVILLE",
      },
      {
        CITY: "TOCQUEVILLE-EN-CAUX",
      },
      {
        CITY: "TOLLEVAST",
      },
      {
        CITY: "TOMBEBOEUF",
      },
      {
        CITY: "TOMBLAINE",
      },
      {
        CITY: "TONNAY-BOUTONNE",
      },
      {
        CITY: "TONNAY-CHARENTE",
      },
      {
        CITY: "TONNEINS",
      },
      {
        CITY: "TONNERRE",
      },
      {
        CITY: "TONNOY",
      },
      {
        CITY: "TORCE-EN-VALLEE",
      },
      {
        CITY: "TORCIEU",
      },
      {
        CITY: "TORCY",
      },
      {
        CITY: "TORCY",
      },
      {
        CITY: "TORCY-LE-GRAND",
      },
      {
        CITY: "TORDERES",
      },
      {
        CITY: "TORFOU",
      },
      {
        CITY: "TORIGNI-SUR-VIRE",
      },
      {
        CITY: "TORPES",
      },
      {
        CITY: "TORREILLES",
      },
      {
        CITY: "TORSAC",
      },
      {
        CITY: "TORVILLIERS",
      },
      {
        CITY: "TORXE",
      },
      {
        CITY: "TOSSE",
      },
      {
        CITY: "TOSSIAT",
      },
      {
        CITY: "TOTES",
      },
      {
        CITY: "TOUCHAY",
      },
      {
        CITY: "TOUCY",
      },
      {
        CITY: "TOUDON",
      },
      {
        CITY: "TOUFFLERS",
      },
      {
        CITY: "TOUL",
      },
      {
        CITY: "TOULAUD",
      },
      {
        CITY: "TOULENNE",
      },
      {
        CITY: "TOULON",
      },
      {
        CITY: "TOULON-SUR-ARROUX",
      },
      {
        CITY: "TOULOUGES",
      },
      {
        CITY: "TOULOUSE",
      },
      {
        CITY: "TOULOUZETTE",
      },
      {
        CITY: "TOULX-SAINTE-CROIX",
      },
      {
        CITY: "TOUQUES",
      },
      {
        CITY: "TOUQUIN",
      },
      {
        CITY: "TOUR-EN-SOLOGNE",
      },
      {
        CITY: "TOURBES",
      },
      {
        CITY: "TOURCOING",
      },
      {
        CITY: "TOURGEVILLE",
      },
      {
        CITY: "TOURLAVILLE",
      },
      {
        CITY: "TOURLY",
      },
      {
        CITY: "TOURNAN-EN-BRIE",
      },
      {
        CITY: "TOURNAY-SUR-ODON",
      },
      {
        CITY: "TOURNECOUPE",
      },
      {
        CITY: "TOURNEDOS-BOIS-HUBERT",
      },
      {
        CITY: "TOURNEFEUILLE",
      },
      {
        CITY: "TOURNEHEM-SUR-LA-HEM",
      },
      {
        CITY: "TOURNIERES",
      },
      {
        CITY: "TOURNOISIS",
      },
      {
        CITY: "TOURNON-SAINT-MARTIN",
      },
      {
        CITY: "TOURNUS",
      },
      {
        CITY: "TOUROUVRE",
      },
      {
        CITY: "TOURRETTE-LEVENS",
      },
      {
        CITY: "TOURRETTES-SUR-LOUP",
      },
      {
        CITY: "TOURRIERS",
      },
      {
        CITY: "TOURS",
      },
      {
        CITY: "TOURS-EN-SAVOIE",
      },
      {
        CITY: "TOURS-SUR-MARNE",
      },
      {
        CITY: "TOURS-SUR-MEYMONT",
      },
      {
        CITY: "TOURTROL",
      },
      {
        CITY: "TOURVES",
      },
      {
        CITY: "TOURVILLE-EN-AUGE",
      },
      {
        CITY: "TOURVILLE-LA-CAMPAGNE",
      },
      {
        CITY: "TOURVILLE-LA-CHAPELLE",
      },
      {
        CITY: "TOURVILLE-LA-RIVIERE",
      },
      {
        CITY: "TOURVILLE-LES-IFS",
      },
      {
        CITY: "TOURVILLE-SUR-ODON",
      },
      {
        CITY: "TOURVILLE-SUR-SIENNE",
      },
      {
        CITY: "TOURY",
      },
      {
        CITY: "TOUSSAINT",
      },
      {
        CITY: "TOUSSIEU",
      },
      {
        CITY: "TOUSSIEUX",
      },
      {
        CITY: "TOUSSUS-LE-NOBLE",
      },
      {
        CITY: "TOUTLEMONDE",
      },
      {
        CITY: "TOUVILLE",
      },
      {
        CITY: "TOUVOIS",
      },
      {
        CITY: "TOUVRE",
      },
      {
        CITY: "TOUZAC",
      },
      {
        CITY: "TRACY-LE-MONT",
      },
      {
        CITY: "TRACY-LE-VAL",
      },
      {
        CITY: "TRACY-SUR-LOIRE",
      },
      {
        CITY: "TRAENHEIM",
      },
      {
        CITY: "TRAINEL",
      },
      {
        CITY: "TRAINOU",
      },
      {
        CITY: "TRAMOLE",
      },
      {
        CITY: "TRAMOYES",
      },
      {
        CITY: "TRANGE",
      },
      {
        CITY: "TRANS-EN-PROVENCE",
      },
      {
        CITY: "TRAPPES",
      },
      {
        CITY: "TRAUBACH-LE-BAS",
      },
      {
        CITY: "TRAUSSE",
      },
      {
        CITY: "TRAVECY",
      },
      {
        CITY: "TREAUVILLE",
      },
      {
        CITY: "TREBES",
      },
      {
        CITY: "TREBEURDEN",
      },
      {
        CITY: "TREBRY",
      },
      {
        CITY: "TRECLUN",
      },
      {
        CITY: "TREDARZEC",
      },
      {
        CITY: "TREDUDER",
      },
      {
        CITY: "TREFFENDEL",
      },
      {
        CITY: "TREFFIEUX",
      },
      {
        CITY: "TREFFLEAN",
      },
      {
        CITY: "TREFFORT",
      },
      {
        CITY: "TREFFRIN",
      },
      {
        CITY: "TREFLEZ",
      },
      {
        CITY: "TREGARANTEC",
      },
      {
        CITY: "TREGASTEL",
      },
      {
        CITY: "TREGLAMUS",
      },
      {
        CITY: "TREGOUREZ",
      },
      {
        CITY: "TREGUIDEL",
      },
      {
        CITY: "TREGUNC",
      },
      {
        CITY: "TREIGNAC",
      },
      {
        CITY: "TREILLIERES",
      },
      {
        CITY: "TREIZE-SEPTIERS",
      },
      {
        CITY: "TRELAZE",
      },
      {
        CITY: "TRELEVERN",
      },
      {
        CITY: "TRELISSAC",
      },
      {
        CITY: "TRELIVAN",
      },
      {
        CITY: "TRELLY",
      },
      {
        CITY: "TRELON",
      },
      {
        CITY: "TREMBLAY",
      },
      {
        CITY: "TREMBLAY",
      },
      {
        CITY: "TREMEL",
      },
      {
        CITY: "TREMENTINES",
      },
      {
        CITY: "TREMERY",
      },
      {
        CITY: "TREMEVEN",
      },
      {
        CITY: "TREMILLY",
      },
      {
        CITY: "TREMONS",
      },
      {
        CITY: "TREMONT-SUR-SAULX",
      },
      {
        CITY: "TREMUSON",
      },
      {
        CITY: "TREON",
      },
      {
        CITY: "TREPIED",
      },
      {
        CITY: "TREPT",
      },
      {
        CITY: "TRESILLEY",
      },
      {
        CITY: "TRESQUES",
      },
      {
        CITY: "TRESSAN",
      },
      {
        CITY: "TRESSANGE",
      },
      {
        CITY: "TRESSERVE",
      },
      {
        CITY: "TRESSES",
      },
      {
        CITY: "TRETS",
      },
      {
        CITY: "TREUZY-LEVELAY",
      },
      {
        CITY: "TREVE",
      },
      {
        CITY: "TREVENEUC",
      },
      {
        CITY: "TREVERAY",
      },
      {
        CITY: "TREVERIEN",
      },
      {
        CITY: "TREVES",
      },
      {
        CITY: "TREVIEN",
      },
      {
        CITY: "TREVIGNIN",
      },
      {
        CITY: "TREVOUX",
      },
      {
        CITY: "TREZIOUX",
      },
      {
        CITY: "TRIAIZE",
      },
      {
        CITY: "TRICOT",
      },
      {
        CITY: "TRIE-CHATEAU",
      },
      {
        CITY: "TRIE-SUR-BAISE",
      },
      {
        CITY: "TRIEL-SUR-SEINE",
      },
      {
        CITY: "TRIEUX",
      },
      {
        CITY: "TRIGAVOU",
      },
      {
        CITY: "TRIGNAC",
      },
      {
        CITY: "TRIGUERES",
      },
      {
        CITY: "TRILBARDOU",
      },
      {
        CITY: "TRILPORT",
      },
      {
        CITY: "TRIORS",
      },
      {
        CITY: "TRITH-SAINT-LEGER",
      },
      {
        CITY: "TRIZAC",
      },
      {
        CITY: "TRIZAY",
      },
      {
        CITY: "TROARN",
      },
      {
        CITY: "TROCHE",
      },
      {
        CITY: "TROGUES",
      },
      {
        CITY: "TROISSEREUX",
      },
      {
        CITY: "TROISSY",
      },
      {
        CITY: "TROISVILLES",
      },
      {
        CITY: "TRONVILLE-EN-BARROIS",
      },
      {
        CITY: "TROSLY-BREUIL",
      },
      {
        CITY: "TROUHANS",
      },
      {
        CITY: "TROUILLAS",
      },
      {
        CITY: "TROULEY-LABARTHE",
      },
      {
        CITY: "TROUSSENCOURT",
      },
      {
        CITY: "TROUSSEY",
      },
      {
        CITY: "TROUVILLE",
      },
      {
        CITY: "TROUVILLE-LA-HAULE",
      },
      {
        CITY: "TROUVILLE-SUR-MER",
      },
      {
        CITY: "TROUY",
      },
      {
        CITY: "TROYES",
      },
      {
        CITY: "TROYON",
      },
      {
        CITY: "TRUCHTERSHEIM",
      },
      {
        CITY: "TRUMILLY",
      },
      {
        CITY: "TRUYES",
      },
      {
        CITY: "TUBERSENT",
      },
      {
        CITY: "TUCHAN",
      },
      {
        CITY: "TUCQUEGNIEUX",
      },
      {
        CITY: "TUFFE",
      },
      {
        CITY: "TULLE",
      },
      {
        CITY: "TULLINS",
      },
      {
        CITY: "TUPIGNY",
      },
      {
        CITY: "TURCKHEIM",
      },
      {
        CITY: "TURQUANT",
      },
      {
        CITY: "TURSAC",
      },
      {
        CITY: "TUZAGUET",
      },
      {
        CITY: "UBERACH",
      },
      {
        CITY: "UCHACQ-ET-PARENTIS",
      },
      {
        CITY: "UCHAUD",
      },
      {
        CITY: "UCHAUX",
      },
      {
        CITY: "UCKANGE",
      },
      {
        CITY: "UEBERSTRASS",
      },
      {
        CITY: "UGINE",
      },
      {
        CITY: "UGNY-SUR-MEUSE",
      },
      {
        CITY: "UHRWILLER",
      },
      {
        CITY: "ULLY-SAINT-GEORGES",
      },
      {
        CITY: "UMPEAU",
      },
      {
        CITY: "UNDUREIN",
      },
      {
        CITY: "UNGERSHEIM",
      },
      {
        CITY: "UNIEUX",
      },
      {
        CITY: "UR",
      },
      {
        CITY: "URBEIS",
      },
      {
        CITY: "URCAY",
      },
      {
        CITY: "URCEL",
      },
      {
        CITY: "URCUIT",
      },
      {
        CITY: "URDOS",
      },
      {
        CITY: "URIAGE-LES-BAINS",
      },
      {
        CITY: "URMATT",
      },
      {
        CITY: "UROU-ET-CRENNES",
      },
      {
        CITY: "URRUGNE",
      },
      {
        CITY: "URT",
      },
      {
        CITY: "URUFFE",
      },
      {
        CITY: "URVAL",
      },
      {
        CITY: "URVILLE-NACQUEVILLE",
      },
      {
        CITY: "URY",
      },
      {
        CITY: "URZY",
      },
      {
        CITY: "US",
      },
      {
        CITY: "USSAC",
      },
      {
        CITY: "USSEAU",
      },
      {
        CITY: "USSEL",
      },
      {
        CITY: "USSON-DU-POITOU",
      },
      {
        CITY: "USSY",
      },
      {
        CITY: "USTARITZ",
      },
      {
        CITY: "UTELLE",
      },
      {
        CITY: "UVERNET-FOURS",
      },
      {
        CITY: "UXEGNEY",
      },
      {
        CITY: "UXEM",
      },
      {
        CITY: "UZEIN",
      },
      {
        CITY: "UZERCHE",
      },
      {
        CITY: "UZOS",
      },
      {
        CITY: "VAAS",
      },
      {
        CITY: "VACHERESSE",
      },
      {
        CITY: "VACOGNES-NEUILLY",
      },
      {
        CITY: "VACON",
      },
      {
        CITY: "VACQUIERES",
      },
      {
        CITY: "VACQUIERS",
      },
      {
        CITY: "VADENAY",
      },
      {
        CITY: "VADENCOURT",
      },
      {
        CITY: "VAGNEY",
      },
      {
        CITY: "VAHL-LES-BENESTROFF",
      },
      {
        CITY: "VAILHAUQUES",
      },
      {
        CITY: "VAILLANT",
      },
      {
        CITY: "VAILLY",
      },
      {
        CITY: "VAILLY",
      },
      {
        CITY: "VAILLY-SUR-AISNE",
      },
      {
        CITY: "VAILLY-SUR-SAULDRE",
      },
      {
        CITY: "VAIRE",
      },
      {
        CITY: "VAIRE-SOUS-CORBIE",
      },
      {
        CITY: "VAIRES-SUR-MARNE",
      },
      {
        CITY: "VAISON-LA-ROMAINE",
      },
      {
        CITY: "VAISSAC",
      },
      {
        CITY: "VAIVRE-ET-MONTOILLE",
      },
      {
        CITY: "VAL-DE-FIER",
      },
      {
        CITY: "VAL-DE-LA-HAYE",
      },
      {
        CITY: "VAL-ET-CHATILLON",
      },
      {
        CITY: "VALAIRE",
      },
      {
        CITY: "VALAY",
      },
      {
        CITY: "VALBERG",
      },
      {
        CITY: "VALBONNE",
      },
      {
        CITY: "VALCOURT",
      },
      {
        CITY: "VALDAHON",
      },
      {
        CITY: "VALDAMPIERRE",
      },
      {
        CITY: "VALDOIE",
      },
      {
        CITY: "VALDURENQUE",
      },
      {
        CITY: "VALENCAY",
      },
      {
        CITY: "VALENCE",
      },
      {
        CITY: "VALENCE",
      },
      {
        CITY: "VALENCE-SUR-BAISE",
      },
      {
        CITY: "VALENCIENNES",
      },
      {
        CITY: "VALENCIN",
      },
      {
        CITY: "VALENCOGNE",
      },
      {
        CITY: "VALENSOLE",
      },
      {
        CITY: "VALENTIGNEY",
      },
      {
        CITY: "VALENTON",
      },
      {
        CITY: "VALESCOURT",
      },
      {
        CITY: "VALFF",
      },
      {
        CITY: "VALFLAUNES",
      },
      {
        CITY: "VALINES",
      },
      {
        CITY: "VALLANGOUJARD",
      },
      {
        CITY: "VALLANS",
      },
      {
        CITY: "VALLANT-SAINT-GEORGES",
      },
      {
        CITY: "VALLAURIS",
      },
      {
        CITY: "VALLE-DI-MEZZANA",
      },
      {
        CITY: "VALLEGUE",
      },
      {
        CITY: "VALLEIRY",
      },
      {
        CITY: "VALLERAUGUE",
      },
      {
        CITY: "VALLERES",
      },
      {
        CITY: "VALLEROIS-LORIOZ",
      },
      {
        CITY: "VALLEROY-LE-SEC",
      },
      {
        CITY: "VALLESVILLES",
      },
      {
        CITY: "VALLET",
      },
      {
        CITY: "VALLIER",
      },
      {
        CITY: "VALLIGUIERES",
      },
      {
        CITY: "VALLIQUERVILLE",
      },
      {
        CITY: "VALLON-EN-SULLY",
      },
      {
        CITY: "VALLOUISE",
      },
      {
        CITY: "VALMESTROFF",
      },
      {
        CITY: "VALMONDOIS",
      },
      {
        CITY: "VALMONT",
      },
      {
        CITY: "VALMONT",
      },
      {
        CITY: "VALMY",
      },
      {
        CITY: "VALOGNES",
      },
      {
        CITY: "VALPUISEAUX",
      },
      {
        CITY: "VALRAS-PLAGE",
      },
      {
        CITY: "VALREAS",
      },
      {
        CITY: "VALROS",
      },
      {
        CITY: "VALS-LES-BAINS",
      },
      {
        CITY: "VALS-PRES-LE-PUY",
      },
      {
        CITY: "VANCLANS",
      },
      {
        CITY: "VANDIERES",
      },
      {
        CITY: "VANDOEUVRE-LES-NANCY",
      },
      {
        CITY: "VANDONCOURT",
      },
      {
        CITY: "VANDRE",
      },
      {
        CITY: "VANDRIMARE",
      },
      {
        CITY: "VANDY",
      },
      {
        CITY: "VANNES",
      },
      {
        CITY: "VANOSC",
      },
      {
        CITY: "VANTOUX",
      },
      {
        CITY: "VANVES",
      },
      {
        CITY: "VANVILLE",
      },
      {
        CITY: "VANZAC",
      },
      {
        CITY: "VARADES",
      },
      {
        CITY: "VARAGES",
      },
      {
        CITY: "VARAIGNES",
      },
      {
        CITY: "VARAIZE",
      },
      {
        CITY: "VARANGES",
      },
      {
        CITY: "VARANGEVILLE",
      },
      {
        CITY: "VARAVILLE",
      },
      {
        CITY: "VARCES-ALLIERES-ET-RISSET",
      },
      {
        CITY: "VAREN",
      },
      {
        CITY: "VARENNES",
      },
      {
        CITY: "VARENNES",
      },
      {
        CITY: "VARENNES-EN-ARGONNE",
      },
      {
        CITY: "VARENNES-JARCY",
      },
      {
        CITY: "VARENNES-LE-GRAND",
      },
      {
        CITY: "VARENNES-LES-MACON",
      },
      {
        CITY: "VARENNES-SOUS-DUN",
      },
      {
        CITY: "VARENNES-SUR-ALLIER",
      },
      {
        CITY: "VARENNES-SUR-LOIRE",
      },
      {
        CITY: "VARENNES-SUR-SEINE",
      },
      {
        CITY: "VARENNES-VAUZELLES",
      },
      {
        CITY: "VARESNES",
      },
      {
        CITY: "VARETZ",
      },
      {
        CITY: "VARILHES",
      },
      {
        CITY: "VARINFROY",
      },
      {
        CITY: "VAROIS-ET-CHAIGNOT",
      },
      {
        CITY: "VARRAINS",
      },
      {
        CITY: "VARREDDES",
      },
      {
        CITY: "VARS",
      },
      {
        CITY: "VARS",
      },
      {
        CITY: "VARSBERG",
      },
      {
        CITY: "VARZAY",
      },
      {
        CITY: "VARZY",
      },
      {
        CITY: "VASLES",
      },
      {
        CITY: "VASSEL",
      },
      {
        CITY: "VASSELAY",
      },
      {
        CITY: "VASSY",
      },
      {
        CITY: "VASTEVILLE",
      },
      {
        CITY: "VATAN",
      },
      {
        CITY: "VATHIMENIL",
      },
      {
        CITY: "VATIMESNIL",
      },
      {
        CITY: "VAUBAN",
      },
      {
        CITY: "VAUBECOURT",
      },
      {
        CITY: "VAUCHAMPS",
      },
      {
        CITY: "VAUCHELLES",
      },
      {
        CITY: "VAUCHELLES-LES-DOMART",
      },
      {
        CITY: "VAUCHELLES-LES-QUESNOY",
      },
      {
        CITY: "VAUCHONVILLIERS",
      },
      {
        CITY: "VAUCOULEURS",
      },
      {
        CITY: "VAUCRESSON",
      },
      {
        CITY: "VAUDEBARRIER",
      },
      {
        CITY: "VAUDEMANGE",
      },
      {
        CITY: "VAUDESSON",
      },
      {
        CITY: "VAUDEVILLE",
      },
      {
        CITY: "VAUDOY-EN-BRIE",
      },
      {
        CITY: "VAUDRECHING",
      },
      {
        CITY: "VAUDREY",
      },
      {
        CITY: "VAUGNERAY",
      },
      {
        CITY: "VAUGRIGNEUSE",
      },
      {
        CITY: "VAUHALLAN",
      },
      {
        CITY: "VAUJOURS",
      },
      {
        CITY: "VAULRY",
      },
      {
        CITY: "VAULT-DE-LUGNY",
      },
      {
        CITY: "VAULX-EN-VELIN",
      },
      {
        CITY: "VAULX-MILIEU",
      },
      {
        CITY: "VAUMAS",
      },
      {
        CITY: "VAUMOISE",
      },
      {
        CITY: "VAUMORT",
      },
      {
        CITY: "VAUREAL",
      },
      {
        CITY: "VAUREILLES",
      },
      {
        CITY: "VAUVERT",
      },
      {
        CITY: "VAUVILLE",
      },
      {
        CITY: "VAUVILLERS",
      },
      {
        CITY: "VAUX-EN-BUGEY",
      },
      {
        CITY: "VAUX-LA-PETITE",
      },
      {
        CITY: "VAUX-LE-PENIL",
      },
      {
        CITY: "VAUX-LES-PRES",
      },
      {
        CITY: "VAUX-LES-SAINT-CLAUDE",
      },
      {
        CITY: "VAUX-SUR-AURE",
      },
      {
        CITY: "VAUX-SUR-BLAISE",
      },
      {
        CITY: "VAUX-SUR-EURE",
      },
      {
        CITY: "VAUX-SUR-MER",
      },
      {
        CITY: "VAUX-SUR-POLIGNY",
      },
      {
        CITY: "VAUX-SUR-SEINE",
      },
      {
        CITY: "VAUXBUIN",
      },
      {
        CITY: "VAUXTIN",
      },
      {
        CITY: "VAXONCOURT",
      },
      {
        CITY: "VAY",
      },
      {
        CITY: "VAYRAC",
      },
      {
        CITY: "VAYRES",
      },
      {
        CITY: "VEAUCHE",
      },
      {
        CITY: "VEAUGUES",
      },
      {
        CITY: "VECOUX",
      },
      {
        CITY: "VECQUEMONT",
      },
      {
        CITY: "VECQUEVILLE",
      },
      {
        CITY: "VEDENE",
      },
      {
        CITY: "VEHO",
      },
      {
        CITY: "VEIGNE",
      },
      {
        CITY: "VEIGY-FONCENEX",
      },
      {
        CITY: "VELAINE-EN-HAYE",
      },
      {
        CITY: "VELAINES",
      },
      {
        CITY: "VELARS-SUR-OUCHE",
      },
      {
        CITY: "VELAUX",
      },
      {
        CITY: "VELINES",
      },
      {
        CITY: "VELLE-LE-CHATEL",
      },
      {
        CITY: "VELLECHES",
      },
      {
        CITY: "VELLEMINFROY",
      },
      {
        CITY: "VELLERON",
      },
      {
        CITY: "VELOTTE-ET-TATIGNECOURT",
      },
      {
        CITY: "VELYE",
      },
      {
        CITY: "VELZIC",
      },
      {
        CITY: "VEMARS",
      },
      {
        CITY: "VENABLES",
      },
      {
        CITY: "VENANSAULT",
      },
      {
        CITY: "VENANT",
      },
      {
        CITY: "VENAREY-LES-LAUMES",
      },
      {
        CITY: "VENASQUE",
      },
      {
        CITY: "VENCE",
      },
      {
        CITY: "VENDARGUES",
      },
      {
        CITY: "VENDAT",
      },
      {
        CITY: "VENDAYS-MONTALIVET",
      },
      {
        CITY: "VENDEGIES-SUR-ECAILLON",
      },
      {
        CITY: "VENDEMIAN",
      },
      {
        CITY: "VENDENHEIM",
      },
      {
        CITY: "VENDEUIL-CAPLY",
      },
      {
        CITY: "VENDEUVRE-DU-POITOU",
      },
      {
        CITY: "VENDEVILLE",
      },
      {
        CITY: "VENDIN-LE-VIEIL",
      },
      {
        CITY: "VENDIN-LES-BETHUNE",
      },
      {
        CITY: "VENDOME",
      },
      {
        CITY: "VENDRENNES",
      },
      {
        CITY: "VENDRES",
      },
      {
        CITY: "VENEJAN",
      },
      {
        CITY: "VENELLES",
      },
      {
        CITY: "VENERAND",
      },
      {
        CITY: "VENERQUE",
      },
      {
        CITY: "VENETTE",
      },
      {
        CITY: "VENEUX-LES-SABLONS",
      },
      {
        CITY: "VENIZEL",
      },
      {
        CITY: "VENIZY",
      },
      {
        CITY: "VENNECY",
      },
      {
        CITY: "VENON",
      },
      {
        CITY: "VENOY",
      },
      {
        CITY: "VENTABREN",
      },
      {
        CITY: "VENTES-SAINT-REMY",
      },
      {
        CITY: "VENTEUIL",
      },
      {
        CITY: "VENTHON",
      },
      {
        CITY: "VENTISERI",
      },
      {
        CITY: "VER-SUR-LAUNETTE",
      },
      {
        CITY: "VERAC",
      },
      {
        CITY: "VERARGUES",
      },
      {
        CITY: "VERBERIE",
      },
      {
        CITY: "VERCHAIX",
      },
      {
        CITY: "VERCHAMP",
      },
      {
        CITY: "VERCHIN",
      },
      {
        CITY: "VERDELAIS",
      },
      {
        CITY: "VERDERONNE",
      },
      {
        CITY: "VERDON",
      },
      {
        CITY: "VERDUN",
      },
      {
        CITY: "VERDUN-EN-LAURAGAIS",
      },
      {
        CITY: "VERDUN-SUR-GARONNE",
      },
      {
        CITY: "VERDUN-SUR-LE-DOUBS",
      },
      {
        CITY: "VERETZ",
      },
      {
        CITY: "VERFEIL",
      },
      {
        CITY: "VERGEZE",
      },
      {
        CITY: "VERGONGHEON",
      },
      {
        CITY: "VERGT",
      },
      {
        CITY: "VERIA",
      },
      {
        CITY: "VERIGNY",
      },
      {
        CITY: "VERINES",
      },
      {
        CITY: "VERJUX",
      },
      {
        CITY: "VERLINGHEM",
      },
      {
        CITY: "VERMAND",
      },
      {
        CITY: "VERMELLES",
      },
      {
        CITY: "VERMENTON",
      },
      {
        CITY: "VERN-SUR-SEICHE",
      },
      {
        CITY: "VERNAISON",
      },
      {
        CITY: "VERNE",
      },
      {
        CITY: "VERNE",
      },
      {
        CITY: "VERNEGUES",
      },
      {
        CITY: "VERNEIL-LE-CHETIF",
      },
      {
        CITY: "VERNET-LES-BAINS",
      },
      {
        CITY: "VERNEUGHEOL",
      },
      {
        CITY: "VERNEUIL",
      },
      {
        CITY: "VERNEUIL-EN-BOURBONNAIS",
      },
      {
        CITY: "VERNEUIL-EN-HALATTE",
      },
      {
        CITY: "VERNEUIL-SOUS-COUCY",
      },
      {
        CITY: "VERNEUIL-SUR-AVRE",
      },
      {
        CITY: "VERNEUIL-SUR-SEINE",
      },
      {
        CITY: "VERNEUIL-SUR-VIENNE",
      },
      {
        CITY: "VERNIERFONTAINE",
      },
      {
        CITY: "VERNINES",
      },
      {
        CITY: "VERNIOLLE",
      },
      {
        CITY: "VERNIOZ",
      },
      {
        CITY: "VERNON",
      },
      {
        CITY: "VERNON",
      },
      {
        CITY: "VERNOSC-LES-ANNONAY",
      },
      {
        CITY: "VERNOU-SUR-BRENNE",
      },
      {
        CITY: "VERNOUILLET",
      },
      {
        CITY: "VERNOUILLET",
      },
      {
        CITY: "VERNOUX-EN-VIVARAIS",
      },
      {
        CITY: "VERNY",
      },
      {
        CITY: "VERON",
      },
      {
        CITY: "VERPILLIERES",
      },
      {
        CITY: "VERQUIERES",
      },
      {
        CITY: "VERQUIGNEUL",
      },
      {
        CITY: "VERQUIN",
      },
      {
        CITY: "VERREY-SOUS-SALMAISE",
      },
      {
        CITY: "VERRIERES",
      },
      {
        CITY: "VERRIERES",
      },
      {
        CITY: "VERRIERES-LE-BUISSON",
      },
      {
        CITY: "VERS",
      },
      {
        CITY: "VERS-EN-MONTAGNE",
      },
      {
        CITY: "VERS-PONT-DU-GARD",
      },
      {
        CITY: "VERS-SUR-SELLES",
      },
      {
        CITY: "VERSAILLES",
      },
      {
        CITY: "VERSAILLEUX",
      },
      {
        CITY: "VERSIGNY",
      },
      {
        CITY: "VERSON",
      },
      {
        CITY: "VERSONNEX",
      },
      {
        CITY: "VERT",
      },
      {
        CITY: "VERT-EN-DROUAIS",
      },
      {
        CITY: "VERT-LE-GRAND",
      },
      {
        CITY: "VERT-LE-PETIT",
      },
      {
        CITY: "VERT-SAINT-DENIS",
      },
      {
        CITY: "VERTAIZON",
      },
      {
        CITY: "VERTHEMEX",
      },
      {
        CITY: "VERTOLAYE",
      },
      {
        CITY: "VERTON",
      },
      {
        CITY: "VERTOU",
      },
      {
        CITY: "VERTRIEU",
      },
      {
        CITY: "VERTUS",
      },
      {
        CITY: "VERVEZELLE",
      },
      {
        CITY: "VERVINS",
      },
      {
        CITY: "VERY",
      },
      {
        CITY: "VERZE",
      },
      {
        CITY: "VERZEILLE",
      },
      {
        CITY: "VERZENAY",
      },
      {
        CITY: "VESCOVATO",
      },
      {
        CITY: "VESIGNEUL-SUR-COOLE",
      },
      {
        CITY: "VESLY",
      },
      {
        CITY: "VESOUL",
      },
      {
        CITY: "VESTRIC-ET-CANDIAC",
      },
      {
        CITY: "VETHEUIL",
      },
      {
        CITY: "VETRAZ-MONTHOUX",
      },
      {
        CITY: "VEUIL",
      },
      {
        CITY: "VEUREY-VOROIZE",
      },
      {
        CITY: "VEYNES",
      },
      {
        CITY: "VEYRAC",
      },
      {
        CITY: "VEYRAS",
      },
      {
        CITY: "VEYRE-MONTON",
      },
      {
        CITY: "VEYRIER-DU-LAC",
      },
      {
        CITY: "VEYRIGNAC",
      },
      {
        CITY: "VEZAC",
      },
      {
        CITY: "VEZELISE",
      },
      {
        CITY: "VEZENOBRES",
      },
      {
        CITY: "VEZEZOUX",
      },
      {
        CITY: "VEZIN-LE-COQUET",
      },
      {
        CITY: "VEZINS",
      },
      {
        CITY: "VIABON",
      },
      {
        CITY: "VIALAS",
      },
      {
        CITY: "VIANNE",
      },
      {
        CITY: "VIARMES",
      },
      {
        CITY: "VIAS",
      },
      {
        CITY: "VIBERSVILLER",
      },
      {
        CITY: "VIBRAYE",
      },
      {
        CITY: "VIC-EN-BIGORRE",
      },
      {
        CITY: "VIC-FEZENSAC",
      },
      {
        CITY: "VIC-LA-GARDIOLE",
      },
      {
        CITY: "VIC-LE-COMTE",
      },
      {
        CITY: "VIC-LE-FESQ",
      },
      {
        CITY: "VIC-SUR-AISNE",
      },
      {
        CITY: "VIC-SUR-CERE",
      },
      {
        CITY: "VIC-SUR-SEILLE",
      },
      {
        CITY: "VICHY",
      },
      {
        CITY: "VICO",
      },
      {
        CITY: "VICQ",
      },
      {
        CITY: "VIDAUBAN",
      },
      {
        CITY: "VIDEIX",
      },
      {
        CITY: "VIEILLE-BRIOUDE",
      },
      {
        CITY: "VIEILLE-EGLISE",
      },
      {
        CITY: "VIEILLE-EGLISE-EN-YVELINES",
      },
      {
        CITY: "VIEILLEVIGNE",
      },
      {
        CITY: "VIEILLEVIGNE",
      },
      {
        CITY: "VIEILLEY",
      },
      {
        CITY: "VIELLE-SAINT-GIRONS",
      },
      {
        CITY: "VIELLE-TURSAN",
      },
      {
        CITY: "VIELLESPEZE",
      },
      {
        CITY: "VIELMUR-SUR-AGOUT",
      },
      {
        CITY: "VIELS-MAISONS",
      },
      {
        CITY: "VIENNE",
      },
      {
        CITY: "VIENVILLE",
      },
      {
        CITY: "VIERVILLE",
      },
      {
        CITY: "VIERZON",
      },
      {
        CITY: "VIESLY",
      },
      {
        CITY: "VIESSOIX",
      },
      {
        CITY: "VIEUX",
      },
      {
        CITY: "VIEUX",
      },
      {
        CITY: "VIEUX CONDE",
      },
      {
        CITY: "VIEUX-BERQUIN",
      },
      {
        CITY: "VIEUX-BOUCAU-LES-BAINS",
      },
      {
        CITY: "VIEUX-CHARMONT",
      },
      {
        CITY: "VIEUX-CONDE",
      },
      {
        CITY: "VIEUX-FERRETTE",
      },
      {
        CITY: "VIEUX-FUME",
      },
      {
        CITY: "VIEUX-MANOIR",
      },
      {
        CITY: "VIEUX-MOULIN",
      },
      {
        CITY: "VIEUX-ROUEN-SUR-BRESLE",
      },
      {
        CITY: "VIEUX-THANN",
      },
      {
        CITY: "VIF",
      },
      {
        CITY: "VIGEOIS",
      },
      {
        CITY: "VIGNACOURT",
      },
      {
        CITY: "VIGNES",
      },
      {
        CITY: "VIGNES",
      },
      {
        CITY: "VIGNEULLES",
      },
      {
        CITY: "VIGNEULLES-LES-HATTONCHATEL",
      },
      {
        CITY: "VIGNEUX-DE-BRETAGNE",
      },
      {
        CITY: "VIGNEUX-SUR-SEINE",
      },
      {
        CITY: "VIGNOC",
      },
      {
        CITY: "VIGNOLLES",
      },
      {
        CITY: "VIGNOLS",
      },
      {
        CITY: "VIGNONET",
      },
      {
        CITY: "VIGNOT",
      },
      {
        CITY: "VIGNY",
      },
      {
        CITY: "VIGNY",
      },
      {
        CITY: "VIGNY",
      },
      {
        CITY: "VIGOULET-AUZIL",
      },
      {
        CITY: "VIGOUROUX",
      },
      {
        CITY: "VIGUERON",
      },
      {
        CITY: "VIGY",
      },
      {
        CITY: "VIHIERS",
      },
      {
        CITY: "VILALLONGA DELS MONTS",
      },
      {
        CITY: "VILLABE",
      },
      {
        CITY: "VILLABON",
      },
      {
        CITY: "VILLACOURT",
      },
      {
        CITY: "VILLAGE-NEUF",
      },
      {
        CITY: "VILLAINES-LA-JUHEL",
      },
      {
        CITY: "VILLAINES-LES-ROCHERS",
      },
      {
        CITY: "VILLAINES-SOUS-BOIS",
      },
      {
        CITY: "VILLAINES-SOUS-LUCE",
      },
      {
        CITY: "VILLAINES-SOUS-MALICORNE",
      },
      {
        CITY: "VILLAMBLARD",
      },
      {
        CITY: "VILLAMEE",
      },
      {
        CITY: "VILLARD",
      },
      {
        CITY: "VILLARD-BONNOT",
      },
      {
        CITY: "VILLARD-DE-LANS",
      },
      {
        CITY: "VILLARD-SAINT-CHRISTOPHE",
      },
      {
        CITY: "VILLARD-SUR-DORON",
      },
      {
        CITY: "VILLARDS",
      },
      {
        CITY: "VILLARGONDRAN",
      },
      {
        CITY: "VILLARIES",
      },
      {
        CITY: "VILLARLURIN",
      },
      {
        CITY: "VILLARS",
      },
      {
        CITY: "VILLARS-BRANDIS",
      },
      {
        CITY: "VILLARS-COLMARS",
      },
      {
        CITY: "VILLARS-EN-PONS",
      },
      {
        CITY: "VILLARS-ET-VILLENOTTE",
      },
      {
        CITY: "VILLARS-LES-DOMBES",
      },
      {
        CITY: "VILLARS-SUR-VAR",
      },
      {
        CITY: "VILLARZEL-CABARDES",
      },
      {
        CITY: "VILLASAVARY",
      },
      {
        CITY: "VILLAZ",
      },
      {
        CITY: "VILLE",
      },
      {
        CITY: "VILLE",
      },
      {
        CITY: "VILLE-DU-PONT",
      },
      {
        CITY: "VILLE-EN-TARDENOIS",
      },
      {
        CITY: "VILLE-LA-GRAND",
      },
      {
        CITY: "VILLE-SOUS-ANJOU",
      },
      {
        CITY: "VILLE-SUR-COUSANCES",
      },
      {
        CITY: "VILLE-SUR-JARNIOUX",
      },
      {
        CITY: "VILLE-SUR-LUMES",
      },
      {
        CITY: "VILLE-SUR-TOURBE",
      },
      {
        CITY: "VILLEBAROU",
      },
      {
        CITY: "VILLEBERNIER",
      },
      {
        CITY: "VILLEBLEVIN",
      },
      {
        CITY: "VILLEBOIS-LAVALETTE",
      },
      {
        CITY: "VILLEBON-SUR-YVETTE",
      },
      {
        CITY: "VILLEBRET",
      },
      {
        CITY: "VILLEBRUMIER",
      },
      {
        CITY: "VILLECERF",
      },
      {
        CITY: "VILLECONIN",
      },
      {
        CITY: "VILLECRESNES",
      },
      {
        CITY: "VILLECROZE",
      },
      {
        CITY: "VILLEDIEU",
      },
      {
        CITY: "VILLEDIEU",
      },
      {
        CITY: "VILLEDIEU-LES-POELES",
      },
      {
        CITY: "VILLEDOMER",
      },
      {
        CITY: "VILLEDOUX",
      },
      {
        CITY: "VILLEFAGNAN",
      },
      {
        CITY: "VILLEFONTAINE",
      },
      {
        CITY: "VILLEFRANCHE-DE-LAURAGAIS",
      },
      {
        CITY: "VILLEFRANCHE-DE-ROUERGUE",
      },
      {
        CITY: "VILLEFRANCHE-DU-QUEYRAN",
      },
      {
        CITY: "VILLEFRANCHE-LE-CHATEAU",
      },
      {
        CITY: "VILLEFRANCHE-SUR-CHER",
      },
      {
        CITY: "VILLEFRANCHE-SUR-MER",
      },
      {
        CITY: "VILLEFRANQUE",
      },
      {
        CITY: "VILLEGAILHENC",
      },
      {
        CITY: "VILLEGATS",
      },
      {
        CITY: "VILLEGOUGE",
      },
      {
        CITY: "VILLEJESUS",
      },
      {
        CITY: "VILLEJOUBERT",
      },
      {
        CITY: "VILLEJUIF",
      },
      {
        CITY: "VILLEJUST",
      },
      {
        CITY: "VILLELAURE",
      },
      {
        CITY: "VILLELONGUE-DE-LA-SALANQUE",
      },
      {
        CITY: "VILLEMANDEUR",
      },
      {
        CITY: "VILLEMARECHAL",
      },
      {
        CITY: "VILLEMBRAY",
      },
      {
        CITY: "VILLEMEUX-SUR-EURE",
      },
      {
        CITY: "VILLEMOIRIEU",
      },
      {
        CITY: "VILLEMOISSON-SUR-ORGE",
      },
      {
        CITY: "VILLEMOLAQUE",
      },
      {
        CITY: "VILLEMOMBLE",
      },
      {
        CITY: "VILLEMORT",
      },
      {
        CITY: "VILLEMOTIER",
      },
      {
        CITY: "VILLEMOUSTAUSSOU",
      },
      {
        CITY: "VILLEMOYENNE",
      },
      {
        CITY: "VILLEMUR",
      },
      {
        CITY: "VILLEMUR-SUR-TARN",
      },
      {
        CITY: "VILLEMURLIN",
      },
      {
        CITY: "VILLENAUXE-LA-GRANDE",
      },
      {
        CITY: "VILLENAVE",
      },
      {
        CITY: "VILLENEUVE",
      },
      {
        CITY: "VILLENEUVE",
      },
      {
        CITY: "VILLENEUVE LES BEZIERS",
      },
      {
        CITY: "VILLENEUVE-AU-CHEMIN",
      },
      {
        CITY: "VILLENEUVE-DE-BERG",
      },
      {
        CITY: "VILLENEUVE-DE-DURAS",
      },
      {
        CITY: "VILLENEUVE-DE-LA-RAHO",
      },
      {
        CITY: "VILLENEUVE-DE-MARC",
      },
      {
        CITY: "VILLENEUVE-DE-MARSAN",
      },
      {
        CITY: "VILLENEUVE-DES-ESCALDES",
      },
      {
        CITY: "VILLENEUVE-FROUVILLE",
      },
      {
        CITY: "VILLENEUVE-LA-GARENNE",
      },
      {
        CITY: "VILLENEUVE-LA-GUYARD",
      },
      {
        CITY: "VILLENEUVE-LA-RIVIERE",
      },
      {
        CITY: "VILLENEUVE-LE-COMTE",
      },
      {
        CITY: "VILLENEUVE-LE-ROI",
      },
      {
        CITY: "VILLENEUVE-LECUSSAN",
      },
      {
        CITY: "VILLENEUVE-LES-AVIGNON",
      },
      {
        CITY: "VILLENEUVE-LES-BOULOC",
      },
      {
        CITY: "VILLENEUVE-LES-GENETS",
      },
      {
        CITY: "VILLENEUVE-LES-MAGUELONE",
      },
      {
        CITY: "VILLENEUVE-LES-SABLONS",
      },
      {
        CITY: "VILLENEUVE-LOUBET",
      },
      {
        CITY: "VILLENEUVE-MINERVOIS",
      },
      {
        CITY: "VILLENEUVE-SAINT-GEORGES",
      },
      {
        CITY: "VILLENEUVE-SAINT-GERMAIN",
      },
      {
        CITY: "VILLENEUVE-SUR-ALLIER",
      },
      {
        CITY: "VILLENEUVE-SUR-FERE",
      },
      {
        CITY: "VILLENEUVE-SUR-LOT",
      },
      {
        CITY: "VILLENEUVE-SUR-YONNE",
      },
      {
        CITY: "VILLENEUVE-TOLOSANE",
      },
      {
        CITY: "VILLENNES-SUR-SEINE",
      },
      {
        CITY: "VILLENOUVELLE",
      },
      {
        CITY: "VILLENOY",
      },
      {
        CITY: "VILLENY",
      },
      {
        CITY: "VILLEPARISIS",
      },
      {
        CITY: "VILLEPERDUE",
      },
      {
        CITY: "VILLEPINTE",
      },
      {
        CITY: "VILLEPINTE",
      },
      {
        CITY: "VILLEPREUX",
      },
      {
        CITY: "VILLEQUIER-AUMONT",
      },
      {
        CITY: "VILLERABLE",
      },
      {
        CITY: "VILLEREST",
      },
      {
        CITY: "VILLERMAIN",
      },
      {
        CITY: "VILLERON",
      },
      {
        CITY: "VILLERS",
      },
      {
        CITY: "VILLERS",
      },
      {
        CITY: "VILLERS-ALLERAND",
      },
      {
        CITY: "VILLERS-AU-BOIS",
      },
      {
        CITY: "VILLERS-AU-TERTRE",
      },
      {
        CITY: "VILLERS-AUX-VENTS",
      },
      {
        CITY: "VILLERS-BOCAGE",
      },
      {
        CITY: "VILLERS-BOCAGE",
      },
      {
        CITY: "VILLERS-BRETONNEUX",
      },
      {
        CITY: "VILLERS-CANIVET",
      },
      {
        CITY: "VILLERS-FARLAY",
      },
      {
        CITY: "VILLERS-HELON",
      },
      {
        CITY: "VILLERS-LA-MONTAGNE",
      },
      {
        CITY: "VILLERS-LE-LAC",
      },
      {
        CITY: "VILLERS-LE-SEC",
      },
      {
        CITY: "VILLERS-LES-NANCY",
      },
      {
        CITY: "VILLERS-LES-ORMES",
      },
      {
        CITY: "VILLERS-LES-POTS",
      },
      {
        CITY: "VILLERS-LES-ROYE",
      },
      {
        CITY: "VILLERS-OUTREAUX",
      },
      {
        CITY: "VILLERS-PLOUICH",
      },
      {
        CITY: "VILLERS-POL",
      },
      {
        CITY: "VILLERS-SAINT-FRAMBOURG",
      },
      {
        CITY: "VILLERS-SAINT-GENEST",
      },
      {
        CITY: "VILLERS-SAINT-PAUL",
      },
      {
        CITY: "VILLERS-SAINT-SEPULCRE",
      },
      {
        CITY: "VILLERS-SEMEUSE",
      },
      {
        CITY: "VILLERS-SIRE-NICOLE",
      },
      {
        CITY: "VILLERS-SOUS-PRENY",
      },
      {
        CITY: "VILLERS-SOUS-SAINT-LEU",
      },
      {
        CITY: "VILLERS-SUR-FERE",
      },
      {
        CITY: "VILLERS-SUR-LE-MONT",
      },
      {
        CITY: "VILLERS-SUR-MER",
      },
      {
        CITY: "VILLERS-SUR-MEUSE",
      },
      {
        CITY: "VILLERS-SUR-PORT",
      },
      {
        CITY: "VILLERS-SUR-SAULNOT",
      },
      {
        CITY: "VILLERSEXEL",
      },
      {
        CITY: "VILLERUPT",
      },
      {
        CITY: "VILLERVILLE",
      },
      {
        CITY: "VILLES-SUR-AUZON",
      },
      {
        CITY: "VILLESEQUELANDE",
      },
      {
        CITY: "VILLESISCLE",
      },
      {
        CITY: "VILLETANEUSE",
      },
      {
        CITY: "VILLETELLE",
      },
      {
        CITY: "VILLETON",
      },
      {
        CITY: "VILLETRUN",
      },
      {
        CITY: "VILLETTE",
      },
      {
        CITY: "VILLETTES",
      },
      {
        CITY: "VILLEURBANNE",
      },
      {
        CITY: "VILLEVALLIER",
      },
      {
        CITY: "VILLEVAUDE",
      },
      {
        CITY: "VILLEVE",
      },
      {
        CITY: "VILLEVEQUE",
      },
      {
        CITY: "VILLEVEYRAC",
      },
      {
        CITY: "VILLEVIEUX",
      },
      {
        CITY: "VILLEVILLON",
      },
      {
        CITY: "VILLEVOCANCE",
      },
      {
        CITY: "VILLEXAVIER",
      },
      {
        CITY: "VILLEY-SAINT-ETIENNE",
      },
      {
        CITY: "VILLEY-SUR-TILLE",
      },
      {
        CITY: "VILLEZ-SOUS-BAILLEUL",
      },
      {
        CITY: "VILLIE-MORGON",
      },
      {
        CITY: "VILLIERS-ADAM",
      },
      {
        CITY: "VILLIERS-AU-BOUIN",
      },
      {
        CITY: "VILLIERS-COUTURE",
      },
      {
        CITY: "VILLIERS-EN-DESOEUVRE",
      },
      {
        CITY: "VILLIERS-EN-PLAINE",
      },
      {
        CITY: "VILLIERS-LE-BACLE",
      },
      {
        CITY: "VILLIERS-LE-BEL",
      },
      {
        CITY: "VILLIERS-LE-MAHIEU",
      },
      {
        CITY: "VILLIERS-SAINT-GEORGES",
      },
      {
        CITY: "VILLIERS-SOUS-GREZ",
      },
      {
        CITY: "VILLIERS-SUR-CHIZE",
      },
      {
        CITY: "VILLIERS-SUR-MARNE",
      },
      {
        CITY: "VILLIERS-SUR-MORIN",
      },
      {
        CITY: "VILLIERS-SUR-ORGE",
      },
      {
        CITY: "VILLIERS-SUR-SEINE",
      },
      {
        CITY: "VILLIERS-SUR-THOLON",
      },
      {
        CITY: "VILLIEU-LOYES-MOLLON",
      },
      {
        CITY: "VILLING",
      },
      {
        CITY: "VILLONS-LES-BUISSONS",
      },
      {
        CITY: "VILLORCEAU",
      },
      {
        CITY: "VILLOTTE-SUR-AIRE",
      },
      {
        CITY: "VILLY-BOCAGE",
      },
      {
        CITY: "VILLY-LE-BOUVERET",
      },
      {
        CITY: "VILLY-LE-PELLOUX",
      },
      {
        CITY: "VIMINES",
      },
      {
        CITY: "VIMORY",
      },
      {
        CITY: "VIMOUTIERS",
      },
      {
        CITY: "VIMY",
      },
      {
        CITY: "VINASSAN",
      },
      {
        CITY: "VINAY",
      },
      {
        CITY: "VINCA",
      },
      {
        CITY: "VINCELLES",
      },
      {
        CITY: "VINCELLES",
      },
      {
        CITY: "VINCENNES",
      },
      {
        CITY: "VINCENT",
      },
      {
        CITY: "VINCEY",
      },
      {
        CITY: "VINCLY",
      },
      {
        CITY: "VINDELLE",
      },
      {
        CITY: "VINEUIL",
      },
      {
        CITY: "VINEUIL",
      },
      {
        CITY: "VINEUIL-SAINT-FIRMIN",
      },
      {
        CITY: "VINNEUF",
      },
      {
        CITY: "VINON-SUR-VERDON",
      },
      {
        CITY: "VINZELLES",
      },
      {
        CITY: "VINZIER",
      },
      {
        CITY: "VIODOS-ABENSE-DE-BAS",
      },
      {
        CITY: "VIOLAINES",
      },
      {
        CITY: "VIOLAY",
      },
      {
        CITY: "VIOMENIL",
      },
      {
        CITY: "VION",
      },
      {
        CITY: "VION",
      },
      {
        CITY: "VIPLAIX",
      },
      {
        CITY: "VIRE",
      },
      {
        CITY: "VIRE",
      },
      {
        CITY: "VIRELADE",
      },
      {
        CITY: "VIREUX-MOLHAIN",
      },
      {
        CITY: "VIREUX-WALLERAND",
      },
      {
        CITY: "VIREY-LE-GRAND",
      },
      {
        CITY: "VIRIAT",
      },
      {
        CITY: "VIRIEU-LE-GRAND",
      },
      {
        CITY: "VIRIGNIN",
      },
      {
        CITY: "VIRIVILLE",
      },
      {
        CITY: "VIROFLAY",
      },
      {
        CITY: "VIRVILLE",
      },
      {
        CITY: "VIRY",
      },
      {
        CITY: "VIRY",
      },
      {
        CITY: "VIRY-NOUREUIL",
      },
      {
        CITY: "VITRAC",
      },
      {
        CITY: "VITRAC-SAINT-VINCENT",
      },
      {
        CITY: "VITREUX",
      },
      {
        CITY: "VITRIMONT",
      },
      {
        CITY: "VITROLLES",
      },
      {
        CITY: "VITRY-AUX-LOGES",
      },
      {
        CITY: "VITRY-EN-ARTOIS",
      },
      {
        CITY: "VITRY-EN-PERTHOIS",
      },
      {
        CITY: "VITRY-LA-VILLE",
      },
      {
        CITY: "VITRY-SUR-ORNE",
      },
      {
        CITY: "VITRY-SUR-SEINE",
      },
      {
        CITY: "VITTEAUX",
      },
      {
        CITY: "VITTEL",
      },
      {
        CITY: "VITTERSBOURG",
      },
      {
        CITY: "VIUZ-EN-SALLAZ",
      },
      {
        CITY: "VIVEN",
      },
      {
        CITY: "VIVIER",
      },
      {
        CITY: "VIVIER-AU-COURT",
      },
      {
        CITY: "VIVIERES",
      },
      {
        CITY: "VIVIERS",
      },
      {
        CITY: "VIVIERS-DU-LAC",
      },
      {
        CITY: "VIVIERS-LES-MONTAGNES",
      },
      {
        CITY: "VIVIEZ",
      },
      {
        CITY: "VIVONNE",
      },
      {
        CITY: "VIVY",
      },
      {
        CITY: "VIX",
      },
      {
        CITY: "VIZILLE",
      },
      {
        CITY: "VOCANCE",
      },
      {
        CITY: "VOGLANS",
      },
      {
        CITY: "VOILLECOMTE",
      },
      {
        CITY: "VOIRON",
      },
      {
        CITY: "VOISIN",
      },
      {
        CITY: "VOISINS-LE-BRETONNEUX",
      },
      {
        CITY: "VOISSANT",
      },
      {
        CITY: "VOLLORE-VILLE",
      },
      {
        CITY: "VOLMERANGE-LES-BOULAY",
      },
      {
        CITY: "VOLMERANGE-LES-MINES",
      },
      {
        CITY: "VOLONNE",
      },
      {
        CITY: "VOLVIC",
      },
      {
        CITY: "VOLX",
      },
      {
        CITY: "VONNAS",
      },
      {
        CITY: "VOREPPE",
      },
      {
        CITY: "VOREY",
      },
      {
        CITY: "VORGES",
      },
      {
        CITY: "VOUEL",
      },
      {
        CITY: "VOUHE",
      },
      {
        CITY: "VOUILLE",
      },
      {
        CITY: "VOUILLE-LES-MARAIS",
      },
      {
        CITY: "VOUILLERS",
      },
      {
        CITY: "VOUJEAUCOURT",
      },
      {
        CITY: "VOULANGIS",
      },
      {
        CITY: "VOULTEGON",
      },
      {
        CITY: "VOULTON",
      },
      {
        CITY: "VOULX",
      },
      {
        CITY: "VOUNEUIL-SOUS-BIARD",
      },
      {
        CITY: "VOUNEUIL-SUR-VIENNE",
      },
      {
        CITY: "VOUREY",
      },
      {
        CITY: "VOURLES",
      },
      {
        CITY: "VOUTEZAC",
      },
      {
        CITY: "VOUTRE",
      },
      {
        CITY: "VOUVANT",
      },
      {
        CITY: "VOUVRAY",
      },
      {
        CITY: "VOUZAN",
      },
      {
        CITY: "VOUZERON",
      },
      {
        CITY: "VOUZIERS",
      },
      {
        CITY: "VOUZON",
      },
      {
        CITY: "VOUZY",
      },
      {
        CITY: "VOVES",
      },
      {
        CITY: "VRAIVILLE",
      },
      {
        CITY: "VRAUX",
      },
      {
        CITY: "VRED",
      },
      {
        CITY: "VRIANGE",
      },
      {
        CITY: "VRIGNE-AUX-BOIS",
      },
      {
        CITY: "VRIGNY",
      },
      {
        CITY: "VRITZ",
      },
      {
        CITY: "VUE",
      },
      {
        CITY: "VULAINES-SUR-SEINE",
      },
      {
        CITY: "VULBENS",
      },
      {
        CITY: "WAHAGNIES",
      },
      {
        CITY: "WAHLENHEIM",
      },
      {
        CITY: "WAIL",
      },
      {
        CITY: "WAILLY",
      },
      {
        CITY: "WAILLY-BEAUCAMP",
      },
      {
        CITY: "WALDHOUSE",
      },
      {
        CITY: "WALDIGHOFEN",
      },
      {
        CITY: "WALDWEISTROFF",
      },
      {
        CITY: "WALDWISSE",
      },
      {
        CITY: "WALHEIM",
      },
      {
        CITY: "WALLERS",
      },
      {
        CITY: "WALSCHEID",
      },
      {
        CITY: "WAMBAIX",
      },
      {
        CITY: "WAMBERCOURT",
      },
      {
        CITY: "WAMBRECHIES",
      },
      {
        CITY: "WANDIGNIES-HAMAGE",
      },
      {
        CITY: "WANGEN",
      },
      {
        CITY: "WANGENBOURG-ENGENTHAL",
      },
      {
        CITY: "WANQUETIN",
      },
      {
        CITY: "WARCQ",
      },
      {
        CITY: "WARENDIN",
      },
      {
        CITY: "WARGNIES-LE-PETIT",
      },
      {
        CITY: "WARHEM",
      },
      {
        CITY: "WARLAING",
      },
      {
        CITY: "WARLUS",
      },
      {
        CITY: "WARLUZEL",
      },
      {
        CITY: "WARMERIVILLE",
      },
      {
        CITY: "WARNECOURT",
      },
      {
        CITY: "WASNES-AU-BAC",
      },
      {
        CITY: "WASQUEHAL",
      },
      {
        CITY: "WASSELONNE",
      },
      {
        CITY: "WASSY",
      },
      {
        CITY: "WATTEN",
      },
      {
        CITY: "WATTIGNIES",
      },
      {
        CITY: "WATTRELOS",
      },
      {
        CITY: "WATTWILLER",
      },
      {
        CITY: "WAVIGNIES",
      },
      {
        CITY: "WAVILLE",
      },
      {
        CITY: "WAVRECHAIN-SOUS-DENAIN",
      },
      {
        CITY: "WAVRECHAIN-SOUS-FAULX",
      },
      {
        CITY: "WAVRIN",
      },
      {
        CITY: "WAZIERS",
      },
      {
        CITY: "WEISLINGEN",
      },
      {
        CITY: "WEITBRUCH",
      },
      {
        CITY: "WENTZWILLER",
      },
      {
        CITY: "WERENTZHOUSE",
      },
      {
        CITY: "WERVICQ-SUD",
      },
      {
        CITY: "WESTBECOURT",
      },
      {
        CITY: "WESTHOFFEN",
      },
      {
        CITY: "WESTHOUSE",
      },
      {
        CITY: "WEYERSHEIM",
      },
      {
        CITY: "WICKERSCHWIHR",
      },
      {
        CITY: "WIDENSOLEN",
      },
      {
        CITY: "WIERRE-EFFROY",
      },
      {
        CITY: "WIGNEHIES",
      },
      {
        CITY: "WIHR-AU-VAL",
      },
      {
        CITY: "WILDERSBACH",
      },
      {
        CITY: "WILLEMS",
      },
      {
        CITY: "WILLENCOURT",
      },
      {
        CITY: "WILLER-SUR-THUR",
      },
      {
        CITY: "WILLERWALD",
      },
      {
        CITY: "WILLGOTTHEIM",
      },
      {
        CITY: "WIMEREUX",
      },
      {
        CITY: "WIMILLE",
      },
      {
        CITY: "WINGEN-SUR-MODER",
      },
      {
        CITY: "WINGERSHEIM",
      },
      {
        CITY: "WINGLES",
      },
      {
        CITY: "WINNEZEELE",
      },
      {
        CITY: "WINTERSBOURG",
      },
      {
        CITY: "WINTZENBACH",
      },
      {
        CITY: "WINTZENHEIM",
      },
      {
        CITY: "WINTZFELDEN",
      },
      {
        CITY: "WIRWIGNES",
      },
      {
        CITY: "WISSANT",
      },
      {
        CITY: "WISSEMBOURG",
      },
      {
        CITY: "WISSIGNICOURT",
      },
      {
        CITY: "WISSOUS",
      },
      {
        CITY: "WITRY-LES-REIMS",
      },
      {
        CITY: "WITTELSHEIM",
      },
      {
        CITY: "WITTENHEIM",
      },
      {
        CITY: "WITTERNHEIM",
      },
      {
        CITY: "WITTERSDORF",
      },
      {
        CITY: "WITTERSHEIM",
      },
      {
        CITY: "WITTES",
      },
      {
        CITY: "WITTISHEIM",
      },
      {
        CITY: "WIWERSHEIM",
      },
      {
        CITY: "WIZERNES",
      },
      {
        CITY: "WOERTH",
      },
      {
        CITY: "WOINCOURT",
      },
      {
        CITY: "WOIPPY",
      },
      {
        CITY: "WOLFGANTZEN",
      },
      {
        CITY: "WOLFISHEIM",
      },
      {
        CITY: "WOLSCHHEIM",
      },
      {
        CITY: "WOLXHEIM",
      },
      {
        CITY: "WORMHOUT",
      },
      {
        CITY: "WOUSTVILLER",
      },
      {
        CITY: "WUENHEIM",
      },
      {
        CITY: "XAINTRAY",
      },
      {
        CITY: "XANTON-CHASSENON",
      },
      {
        CITY: "XERTIGNY",
      },
      {
        CITY: "XURES",
      },
      {
        CITY: "YAUCOURT-BUSSUS",
      },
      {
        CITY: "YCHOUX",
      },
      {
        CITY: "YDES",
      },
      {
        CITY: "YEBLERON",
      },
      {
        CITY: "YEBLES",
      },
      {
        CITY: "YENNE",
      },
      {
        CITY: "YERMENONVILLE",
      },
      {
        CITY: "YERRES",
      },
      {
        CITY: "YERVILLE",
      },
      {
        CITY: "YEVRE-LA-VILLE",
      },
      {
        CITY: "YFFINIAC",
      },
      {
        CITY: "YGOS-SAINT-SATURNIN",
      },
      {
        CITY: "YGRANDE",
      },
      {
        CITY: "YMARE",
      },
      {
        CITY: "YOUX",
      },
      {
        CITY: "YPORT",
      },
      {
        CITY: "YPREVILLE-BIVILLE",
      },
      {
        CITY: "YRONDE-ET-BURON",
      },
      {
        CITY: "YSSAC-LA-TOURETTE",
      },
      {
        CITY: "YSSANDON",
      },
      {
        CITY: "YSSINGEAUX",
      },
      {
        CITY: "YTRAC",
      },
      {
        CITY: "YVERSAY",
      },
      {
        CITY: "YVES",
      },
      {
        CITY: "YVETOT",
      },
      {
        CITY: "YVETTE",
      },
      {
        CITY: "YVIAS",
      },
      {
        CITY: "YVOIRE",
      },
      {
        CITY: "YVRAC",
      },
      {
        CITY: "YVRAC-ET-MALLEYRAND",
      },
      {
        CITY: "YZERNAY",
      },
      {
        CITY: "YZEURE",
      },
      {
        CITY: "YZEURES-SUR-CREUSE",
      },
      {
        CITY: "YZOSSE",
      },
      {
        CITY: "ZELLWILLER",
      },
      {
        CITY: "ZILLISHEIM",
      },
      {
        CITY: "ZIMMERSHEIM",
      },
      {
        CITY: "ZIMMING",
      },
      {
        CITY: "ZINSWILLER",
      },
      {
        CITY: "ZITTERSHEIM",
      },
      {
        CITY: "ZOUAFQUES",
      },
      {
        CITY: "ZUTZENDORF",
      },
    ],
  },
  {
    COUNTRY: "FRENCH POLYNESIA",
    CODE: "PF",
    CITIES: [
      {
        CITY: "ARUE",
      },
      {
        CITY: "FAAA",
      },
      {
        CITY: "MAHINA",
      },
      {
        CITY: "PAEAU",
      },
      {
        CITY: "PAPEETE",
      },
      {
        CITY: "PIRAE",
      },
      {
        CITY: "PUNAAUIA",
      },
    ],
  },
  {
    COUNTRY: "GABON",
    CODE: "GA",
    CITIES: [
      {
        CITY: "GAMBA",
      },
      {
        CITY: "LIBREVILLE",
      },
      {
        CITY: "MAMAGNIA",
      },
      {
        CITY: "MOANDA",
      },
      {
        CITY: "PORT-GENTIL",
      },
    ],
  },
  {
    COUNTRY: "GEORGIA",
    CODE: "GE",
    CITIES: [
      {
        CITY: "GOGOLESUBANI",
      },
      {
        CITY: "KUTAISI",
      },
      {
        CITY: "LENTEKHI",
      },
      {
        CITY: "QAZBEGI",
      },
      {
        CITY: "SAMTREDIA",
      },
      {
        CITY: "SUKHUMI",
      },
      {
        CITY: "TBILISI",
      },
      {
        CITY: "ZEMO-AVCHALA",
      },
      {
        CITY: "ZUGDIDI",
      },
    ],
  },
  {
    COUNTRY: "HONG KONG",
    CODE: "HK",
    CITIES: [
      {
        CITY: "ABERDEEN",
      },
      {
        CITY: "CAUSEWAY BAY",
      },
      {
        CITY: "CENTRAL DISTRICT",
      },
      {
        CITY: "CHA KWO LING",
      },
      {
        CITY: "CHAI WAN KOK",
      },
      {
        CITY: "CHEK CHUE",
      },
      {
        CITY: "CHEUNG KONG",
      },
      {
        CITY: "CHEUNG SHA WAN",
      },
      {
        CITY: "CHUEN LUNG",
      },
      {
        CITY: "CHUNG HAU",
      },
      {
        CITY: "FA YUEN",
      },
      {
        CITY: "FANLING",
      },
      {
        CITY: "FO TAN",
      },
      {
        CITY: "HAPPY VALLEY",
      },
      {
        CITY: "HO MAN TIN",
      },
      {
        CITY: "HONG KONG",
      },
      {
        CITY: "HUNG HOM",
      },
      {
        CITY: "KAT O SHEUNG WAI",
      },
      {
        CITY: "KENNEDY TOWN",
      },
      {
        CITY: "KOWLOON",
      },
      {
        CITY: "KOWLOON TONG",
      },
      {
        CITY: "KWAI CHUNG",
      },
      {
        CITY: "KWUN HANG",
      },
      {
        CITY: "LAI CHI WO",
      },
      {
        CITY: "LAM TEI",
      },
      {
        CITY: "LAM TIN",
      },
      {
        CITY: "LIN FA TEI",
      },
      {
        CITY: "LO SO SHING",
      },
      {
        CITY: "MA ON SHAN TSUEN",
      },
      {
        CITY: "MA WAN",
      },
      {
        CITY: "MA YAU TONG",
      },
      {
        CITY: "MAU PING",
      },
      {
        CITY: "MID LEVELS",
      },
      {
        CITY: "MONG KOK",
      },
      {
        CITY: "NAM A",
      },
      {
        CITY: "NGAU CHI WAN",
      },
      {
        CITY: "NGAU TAU KOK",
      },
      {
        CITY: "NORTH POINT",
      },
      {
        CITY: "PAK NGAN HEUNG",
      },
      {
        CITY: "PENG CHAU",
      },
      {
        CITY: "PING YEUNG",
      },
      {
        CITY: "QUARRY BAY",
      },
      {
        CITY: "REPULSE BAY",
      },
      {
        CITY: "SAI KENG",
      },
      {
        CITY: "SAN TSUEN",
      },
      {
        CITY: "SAN TUNG CHUNG HANG",
      },
      {
        CITY: "SHA PO KONG",
      },
      {
        CITY: "SHA TAU KOK",
      },
      {
        CITY: "SHA TIN WAI",
      },
      {
        CITY: "SHAM SHUI PO",
      },
      {
        CITY: "SHAM TSENG",
      },
      {
        CITY: "SHATIN",
      },
      {
        CITY: "SHAU KEI WAN",
      },
      {
        CITY: "SHEK KIP MEI",
      },
      {
        CITY: "SHEK TONG TSUI",
      },
      {
        CITY: "SHEUNG SHUI",
      },
      {
        CITY: "SHEUNG TSUEN",
      },
      {
        CITY: "SHUEN WAN",
      },
      {
        CITY: "TAI CHAU TO",
      },
      {
        CITY: "TAI HANG",
      },
      {
        CITY: "TAI KOK TSUI",
      },
      {
        CITY: "TAI LIN PAI",
      },
      {
        CITY: "TAI PO",
      },
      {
        CITY: "TAI TAN",
      },
      {
        CITY: "TAI WAI",
      },
      {
        CITY: "TAI WAN TO",
      },
      {
        CITY: "TIN SHUI WAI",
      },
      {
        CITY: "TIN WAN RESETTLEMENT ESTATE",
      },
      {
        CITY: "TING KAU",
      },
      {
        CITY: "TO KWA WAN",
      },
      {
        CITY: "TSEUNG KWAN O",
      },
      {
        CITY: "TSIMSHATSUI",
      },
      {
        CITY: "TSING LUNG TAU",
      },
      {
        CITY: "TSZ WAN SHAN",
      },
      {
        CITY: "TUEN MUN SAN HUI",
      },
      {
        CITY: "WAN TSAI",
      },
      {
        CITY: "WO CHE",
      },
      {
        CITY: "WONG CHUK HANG",
      },
      {
        CITY: "WONG TAI SIN",
      },
      {
        CITY: "YAU MA TEI",
      },
      {
        CITY: "YING PUN",
      },
      {
        CITY: "YUEN LONG SAN HUI",
      },
    ],
  },
  {
    COUNTRY: "GERMANY",
    CODE: "DE",
    CITIES: [
      {
        CITY: "AACH",
      },
      {
        CITY: "AACH",
      },
      {
        CITY: "AACHEN",
      },
      {
        CITY: "AALEN",
      },
      {
        CITY: "ABBENSEN",
      },
      {
        CITY: "ABBERODE",
      },
      {
        CITY: "ABENBERG",
      },
      {
        CITY: "ABENSBERG",
      },
      {
        CITY: "ABSTATT",
      },
      {
        CITY: "ABTSBESSINGEN",
      },
      {
        CITY: "ABTSGMUEND",
      },
      {
        CITY: "ABTSWIND",
      },
      {
        CITY: "ABTWEILER",
      },
      {
        CITY: "ACHERN",
      },
      {
        CITY: "ACHIM",
      },
      {
        CITY: "ACHSTETTEN",
      },
      {
        CITY: "ADELEBSEN",
      },
      {
        CITY: "ADELHEIDSDORF",
      },
      {
        CITY: "ADELMANNSFELDEN",
      },
      {
        CITY: "ADELSCHLAG",
      },
      {
        CITY: "ADELSDORF",
      },
      {
        CITY: "ADELSDORF",
      },
      {
        CITY: "ADELSHEIM",
      },
      {
        CITY: "ADELSHOFEN",
      },
      {
        CITY: "ADELSRIED",
      },
      {
        CITY: "ADENAU",
      },
      {
        CITY: "ADENBUETTEL",
      },
      {
        CITY: "ADENDORF",
      },
      {
        CITY: "ADLKOFEN",
      },
      {
        CITY: "ADLUM",
      },
      {
        CITY: "ADORF",
      },
      {
        CITY: "AERZEN",
      },
      {
        CITY: "AFFALTERBACH",
      },
      {
        CITY: "AFFALTERN",
      },
      {
        CITY: "AFFING",
      },
      {
        CITY: "AFFINGHAUSEN",
      },
      {
        CITY: "AFTHOLDERBERG",
      },
      {
        CITY: "AGLASTERHAUSEN",
      },
      {
        CITY: "AHAUS",
      },
      {
        CITY: "AHAUSEN",
      },
      {
        CITY: "AHLEN",
      },
      {
        CITY: "AHLHORN",
      },
      {
        CITY: "AHOLFING",
      },
      {
        CITY: "AHOLMING",
      },
      {
        CITY: "AHORN",
      },
      {
        CITY: "AHRBRUCK",
      },
      {
        CITY: "AHRENSBOEK",
      },
      {
        CITY: "AHRENSBOK",
      },
      {
        CITY: "AHRENSBURG",
      },
      {
        CITY: "AHRENSFELDE",
      },
      {
        CITY: "AHRENSHAGEN",
      },
      {
        CITY: "AHRENSMOOR",
      },
      {
        CITY: "AHSTEDT",
      },
      {
        CITY: "AICHA VORM WALD",
      },
      {
        CITY: "AICHACH",
      },
      {
        CITY: "AICHELBERG",
      },
      {
        CITY: "AICHHALDEN",
      },
      {
        CITY: "AICHSTETTEN",
      },
      {
        CITY: "AICHTAL",
      },
      {
        CITY: "AICHWALD",
      },
      {
        CITY: "AIDENBACH",
      },
      {
        CITY: "AIDLINGEN",
      },
      {
        CITY: "AINDLING",
      },
      {
        CITY: "AINDORF",
      },
      {
        CITY: "AINRING",
      },
      {
        CITY: "AISLINGEN",
      },
      {
        CITY: "AITRACH",
      },
      {
        CITY: "AITRANG",
      },
      {
        CITY: "AKEN",
      },
      {
        CITY: "ALBACHING",
      },
      {
        CITY: "ALBBRUCK",
      },
      {
        CITY: "ALBECK",
      },
      {
        CITY: "ALBERSDORF",
      },
      {
        CITY: "ALBERSHAUSEN",
      },
      {
        CITY: "ALBERSWEILER",
      },
      {
        CITY: "ALBERTSHAUSEN",
      },
      {
        CITY: "ALBIG",
      },
      {
        CITY: "ALBISHEIM",
      },
      {
        CITY: "ALBRECHTS",
      },
      {
        CITY: "ALBSTADT",
      },
      {
        CITY: "ALDEKERK",
      },
      {
        CITY: "ALDENHOVEN",
      },
      {
        CITY: "ALDERSBACH",
      },
      {
        CITY: "ALDINGEN",
      },
      {
        CITY: "ALESHEIM",
      },
      {
        CITY: "ALETSHAUSEN",
      },
      {
        CITY: "ALEXANDER",
      },
      {
        CITY: "ALFDORF",
      },
      {
        CITY: "ALFELD",
      },
      {
        CITY: "ALFELD",
      },
      {
        CITY: "ALFHAUSEN",
      },
      {
        CITY: "ALFSTEDT",
      },
      {
        CITY: "ALFTER",
      },
      {
        CITY: "ALGERMISSEN",
      },
      {
        CITY: "ALHEIM",
      },
      {
        CITY: "ALLEE",
      },
      {
        CITY: "ALLENDORF",
      },
      {
        CITY: "ALLENDORF",
      },
      {
        CITY: "ALLENSBACH",
      },
      {
        CITY: "ALLERSBERG",
      },
      {
        CITY: "ALLERSHAUSEN",
      },
      {
        CITY: "ALLESHAUSEN",
      },
      {
        CITY: "ALLING",
      },
      {
        CITY: "ALLMANNSHOFEN",
      },
      {
        CITY: "ALLMENDINGEN",
      },
      {
        CITY: "ALLMERSBACH IM TAL",
      },
      {
        CITY: "ALLSTEDT",
      },
      {
        CITY: "ALMSTEDT",
      },
      {
        CITY: "ALPEN",
      },
      {
        CITY: "ALPENROD",
      },
      {
        CITY: "ALPERSTEDT",
      },
      {
        CITY: "ALPIRSBACH",
      },
      {
        CITY: "ALSBACH",
      },
      {
        CITY: "ALSBACH-HAHNLEIN",
      },
      {
        CITY: "ALSDORF",
      },
      {
        CITY: "ALSDORF",
      },
      {
        CITY: "ALSFELD",
      },
      {
        CITY: "ALSHEIM",
      },
      {
        CITY: "ALT BUCHHORST",
      },
      {
        CITY: "ALT BUKOW",
      },
      {
        CITY: "ALT GOLM",
      },
      {
        CITY: "ALT RUPPIN",
      },
      {
        CITY: "ALT SCHWERIN",
      },
      {
        CITY: "ALTBACH",
      },
      {
        CITY: "ALTBELGERN",
      },
      {
        CITY: "ALTDOBERN",
      },
      {
        CITY: "ALTDORF",
      },
      {
        CITY: "ALTDORF",
      },
      {
        CITY: "ALTDORF",
      },
      {
        CITY: "ALTEGLOFSHEIM",
      },
      {
        CITY: "ALTEN",
      },
      {
        CITY: "ALTENA",
      },
      {
        CITY: "ALTENAHR",
      },
      {
        CITY: "ALTENAU",
      },
      {
        CITY: "ALTENBEKEN",
      },
      {
        CITY: "ALTENBERG",
      },
      {
        CITY: "ALTENBERGE",
      },
      {
        CITY: "ALTENBURG",
      },
      {
        CITY: "ALTENDIEZ",
      },
      {
        CITY: "ALTENGLAN",
      },
      {
        CITY: "ALTENGOTTERN",
      },
      {
        CITY: "ALTENGRONAU",
      },
      {
        CITY: "ALTENHASSLAU",
      },
      {
        CITY: "ALTENHOLZ",
      },
      {
        CITY: "ALTENKIRCHEN",
      },
      {
        CITY: "ALTENKIRCHEN",
      },
      {
        CITY: "ALTENKIRCHEN",
      },
      {
        CITY: "ALTENKREMPE",
      },
      {
        CITY: "ALTENKUNSTADT",
      },
      {
        CITY: "ALTENMARKT",
      },
      {
        CITY: "ALTENMEDINGEN",
      },
      {
        CITY: "ALTENMUNSTER",
      },
      {
        CITY: "ALTENRIET",
      },
      {
        CITY: "ALTENSTADT",
      },
      {
        CITY: "ALTENSTADT",
      },
      {
        CITY: "ALTENSTADT",
      },
      {
        CITY: "ALTENSTADT",
      },
      {
        CITY: "ALTENSTEIG",
      },
      {
        CITY: "ALTENTHANN",
      },
      {
        CITY: "ALTENTREPTOW",
      },
      {
        CITY: "ALTERHOFEN",
      },
      {
        CITY: "ALTERSBACH",
      },
      {
        CITY: "ALTFRAUNHOFEN",
      },
      {
        CITY: "ALTHEGNENBERG",
      },
      {
        CITY: "ALTHEIM",
      },
      {
        CITY: "ALTHEIM",
      },
      {
        CITY: "ALTHENGSTETT",
      },
      {
        CITY: "ALTHUTTE",
      },
      {
        CITY: "ALTINGEN",
      },
      {
        CITY: "ALTKIRCHEN",
      },
      {
        CITY: "ALTLANDSBERG",
      },
      {
        CITY: "ALTLEININGEN",
      },
      {
        CITY: "ALTLUSSHEIM",
      },
      {
        CITY: "ALTMANNSTEIN",
      },
      {
        CITY: "ALTOETTING",
      },
      {
        CITY: "ALTOMUNSTER",
      },
      {
        CITY: "ALTRIP",
      },
      {
        CITY: "ALTSHAUSEN",
      },
      {
        CITY: "ALTSTADT",
      },
      {
        CITY: "ALTSTADT",
      },
      {
        CITY: "ALTTECHAU",
      },
      {
        CITY: "ALTUSRIED",
      },
      {
        CITY: "ALTWITTENBEK",
      },
      {
        CITY: "ALVESLOHE",
      },
      {
        CITY: "ALZENAU IN UNTERFRANKEN",
      },
      {
        CITY: "ALZEY",
      },
      {
        CITY: "AM MELLENSEE",
      },
      {
        CITY: "AM SEE",
      },
      {
        CITY: "AMBERG",
      },
      {
        CITY: "AMELINGHAUSEN",
      },
      {
        CITY: "AMERANG",
      },
      {
        CITY: "AMMERN",
      },
      {
        CITY: "AMMERNDORF",
      },
      {
        CITY: "AMONEBURG",
      },
      {
        CITY: "AMORBACH",
      },
      {
        CITY: "AMPERMOCHING",
      },
      {
        CITY: "AMPFING",
      },
      {
        CITY: "AMSTETTEN",
      },
      {
        CITY: "AMTZELL",
      },
      {
        CITY: "ANDECHS",
      },
      {
        CITY: "ANDERLINGEN",
      },
      {
        CITY: "ANDERNACH",
      },
      {
        CITY: "ANDREASBERG",
      },
      {
        CITY: "ANGER",
      },
      {
        CITY: "ANGERMUNDE",
      },
      {
        CITY: "ANGERN",
      },
      {
        CITY: "ANKER",
      },
      {
        CITY: "ANKERSHAGEN",
      },
      {
        CITY: "ANKLAM",
      },
      {
        CITY: "ANKUM",
      },
      {
        CITY: "ANNABERG-BUCHHOLZ",
      },
      {
        CITY: "ANNABURG",
      },
      {
        CITY: "ANNEROD",
      },
      {
        CITY: "ANNWEILER AM TRIFELS",
      },
      {
        CITY: "ANRATH",
      },
      {
        CITY: "ANROCHTE",
      },
      {
        CITY: "ANSBACH",
      },
      {
        CITY: "ANTDORF",
      },
      {
        CITY: "ANZING",
      },
      {
        CITY: "ANZING",
      },
      {
        CITY: "APEN",
      },
      {
        CITY: "APENBURG",
      },
      {
        CITY: "APENSEN",
      },
      {
        CITY: "APFELDORF",
      },
      {
        CITY: "APFELSTADT",
      },
      {
        CITY: "APOLDA",
      },
      {
        CITY: "APPEL",
      },
      {
        CITY: "APPEN",
      },
      {
        CITY: "APPENWEIER",
      },
      {
        CITY: "ARBERG",
      },
      {
        CITY: "ARENRATH",
      },
      {
        CITY: "ARENSDORF",
      },
      {
        CITY: "ARENSHAUSEN",
      },
      {
        CITY: "ARESING",
      },
      {
        CITY: "ARFT",
      },
      {
        CITY: "ARGENTHAL",
      },
      {
        CITY: "ARLACHING",
      },
      {
        CITY: "ARLEWATT",
      },
      {
        CITY: "ARMSHEIM",
      },
      {
        CITY: "ARMSTORF",
      },
      {
        CITY: "ARNBACH",
      },
      {
        CITY: "ARNBRUCK",
      },
      {
        CITY: "ARNSBERG",
      },
      {
        CITY: "ARNSCHWANG",
      },
      {
        CITY: "ARNSDORF",
      },
      {
        CITY: "ARNSDORF",
      },
      {
        CITY: "ARNSTADT",
      },
      {
        CITY: "ARNSTEIN",
      },
      {
        CITY: "ARNSTORF",
      },
      {
        CITY: "ARRACH",
      },
      {
        CITY: "ARTERN",
      },
      {
        CITY: "ARTLENBURG",
      },
      {
        CITY: "ARZBACH",
      },
      {
        CITY: "ARZBACH",
      },
      {
        CITY: "ARZBERG",
      },
      {
        CITY: "ASBACH",
      },
      {
        CITY: "ASBACH",
      },
      {
        CITY: "ASBACH",
      },
      {
        CITY: "ASBACH-BAUMENHEIM",
      },
      {
        CITY: "ASCHA",
      },
      {
        CITY: "ASCHAFFENBURG",
      },
      {
        CITY: "ASCHAU",
      },
      {
        CITY: "ASCHAU",
      },
      {
        CITY: "ASCHEBERG",
      },
      {
        CITY: "ASCHEBERG",
      },
      {
        CITY: "ASCHEFFEL",
      },
      {
        CITY: "ASCHENDORF",
      },
      {
        CITY: "ASCHERSLEBEN",
      },
      {
        CITY: "ASCHHEIM",
      },
      {
        CITY: "ASENDORF",
      },
      {
        CITY: "ASPACH",
      },
      {
        CITY: "ASPACH",
      },
      {
        CITY: "ASPERG",
      },
      {
        CITY: "ASPISHEIM",
      },
      {
        CITY: "ASSEL",
      },
      {
        CITY: "ASSENHEIM",
      },
      {
        CITY: "ASSLAR",
      },
      {
        CITY: "ASSLING",
      },
      {
        CITY: "ASTFELD",
      },
      {
        CITY: "ATTELN",
      },
      {
        CITY: "ATTENDORN",
      },
      {
        CITY: "ATTENKIRCHEN",
      },
      {
        CITY: "ATTENWEILER",
      },
      {
        CITY: "ATTING",
      },
      {
        CITY: "ATZENDORF",
      },
      {
        CITY: "AU",
      },
      {
        CITY: "AU AM RHEIN",
      },
      {
        CITY: "AU IN DER HALLERTAU",
      },
      {
        CITY: "AUB",
      },
      {
        CITY: "AUE",
      },
      {
        CITY: "AUEN",
      },
      {
        CITY: "AUENSTEIN",
      },
      {
        CITY: "AUERBACH",
      },
      {
        CITY: "AUERBACH",
      },
      {
        CITY: "AUERBACH",
      },
      {
        CITY: "AUFHAUSEN",
      },
      {
        CITY: "AUGGEN",
      },
      {
        CITY: "AUGSBURG",
      },
      {
        CITY: "AUGUSTDORF",
      },
      {
        CITY: "AUGUSTFEHN",
      },
      {
        CITY: "AUGUSTUSBURG",
      },
      {
        CITY: "AUKRUG",
      },
      {
        CITY: "AULENDORF",
      },
      {
        CITY: "AULL",
      },
      {
        CITY: "AUMA",
      },
      {
        CITY: "AUMUHLE",
      },
      {
        CITY: "AURACH",
      },
      {
        CITY: "AURACHTAL",
      },
      {
        CITY: "AURICH",
      },
      {
        CITY: "AUTENZELL",
      },
      {
        CITY: "AUW",
      },
      {
        CITY: "AXSTEDT",
      },
      {
        CITY: "AYING",
      },
      {
        CITY: "AYL",
      },
      {
        CITY: "BAAR-EBENHAUSEN",
      },
      {
        CITY: "BAASDORF",
      },
      {
        CITY: "BAASEM",
      },
      {
        CITY: "BABENHAUSEN",
      },
      {
        CITY: "BABENHAUSEN",
      },
      {
        CITY: "BABENSHAM",
      },
      {
        CITY: "BABOW",
      },
      {
        CITY: "BACH",
      },
      {
        CITY: "BACH",
      },
      {
        CITY: "BACH AN DER DONAU",
      },
      {
        CITY: "BACHARACH",
      },
      {
        CITY: "BACHHAGEL",
      },
      {
        CITY: "BACHINGEN AN DER BRENZ",
      },
      {
        CITY: "BACKNANG",
      },
      {
        CITY: "BAD ABBACH",
      },
      {
        CITY: "BAD AIBLING",
      },
      {
        CITY: "BAD ALEXANDERSBAD",
      },
      {
        CITY: "BAD AROLSEN",
      },
      {
        CITY: "BAD BELLINGEN",
      },
      {
        CITY: "BAD BENTHEIM",
      },
      {
        CITY: "BAD BERGZABERN",
      },
      {
        CITY: "BAD BERKA",
      },
      {
        CITY: "BAD BERLEBURG",
      },
      {
        CITY: "BAD BERNECK IM FICHTELGEBIRGE",
      },
      {
        CITY: "BAD BEVENSEN",
      },
      {
        CITY: "BAD BIBRA",
      },
      {
        CITY: "BAD BLANKENBURG",
      },
      {
        CITY: "BAD BOCKLET",
      },
      {
        CITY: "BAD BOLL",
      },
      {
        CITY: "BAD BRAMSTEDT",
      },
      {
        CITY: "BAD BREISIG",
      },
      {
        CITY: "BAD BUCHAU",
      },
      {
        CITY: "BAD CAMBERG",
      },
      {
        CITY: "BAD DITZENBACH",
      },
      {
        CITY: "BAD DOBERAN",
      },
      {
        CITY: "BAD DRIBURG",
      },
      {
        CITY: "BAD DUBEN",
      },
      {
        CITY: "BAD DUERKHEIM",
      },
      {
        CITY: "BAD DURRENBERG",
      },
      {
        CITY: "BAD DURRHEIM",
      },
      {
        CITY: "BAD EILSEN",
      },
      {
        CITY: "BAD ELSTER",
      },
      {
        CITY: "BAD EMS",
      },
      {
        CITY: "BAD ENDBACH",
      },
      {
        CITY: "BAD ENDORF",
      },
      {
        CITY: "BAD ESSEN",
      },
      {
        CITY: "BAD FALLINGBOSTEL",
      },
      {
        CITY: "BAD FEILNBACH",
      },
      {
        CITY: "BAD FRANKENHAUSEN",
      },
      {
        CITY: "BAD FREIENWALDE",
      },
      {
        CITY: "BAD FUSSING",
      },
      {
        CITY: "BAD GANDERSHEIM",
      },
      {
        CITY: "BAD GODESBERG",
      },
      {
        CITY: "BAD GOGGING",
      },
      {
        CITY: "BAD GOTTLEUBA",
      },
      {
        CITY: "BAD GRIESBACH",
      },
      {
        CITY: "BAD GRUND",
      },
      {
        CITY: "BAD HARZBURG",
      },
      {
        CITY: "BAD HEILBRUNN",
      },
      {
        CITY: "BAD HERRENALB",
      },
      {
        CITY: "BAD HERSFELD",
      },
      {
        CITY: "BAD HINDELANG",
      },
      {
        CITY: "BAD HOMBURG",
      },
      {
        CITY: "BAD HONNEF",
      },
      {
        CITY: "BAD HONNINGEN",
      },
      {
        CITY: "BAD IBURG",
      },
      {
        CITY: "BAD KARLSHAFEN",
      },
      {
        CITY: "BAD KISSINGEN",
      },
      {
        CITY: "BAD KLEINEN",
      },
      {
        CITY: "BAD KLOSTERLAUSNITZ",
      },
      {
        CITY: "BAD KOHLGRUB",
      },
      {
        CITY: "BAD KONIG",
      },
      {
        CITY: "BAD KONIGSHOFEN IM GRABFELD",
      },
      {
        CITY: "BAD KOSEN",
      },
      {
        CITY: "BAD KOSTRITZ",
      },
      {
        CITY: "BAD KREUZNACH",
      },
      {
        CITY: "BAD KROZINGEN",
      },
      {
        CITY: "BAD LAASPHE",
      },
      {
        CITY: "BAD LAER",
      },
      {
        CITY: "BAD LANGENSALZA",
      },
      {
        CITY: "BAD LAUCHSTADT",
      },
      {
        CITY: "BAD LAUSICK",
      },
      {
        CITY: "BAD LAUTERBERG IM HARZ",
      },
      {
        CITY: "BAD LIEBENSTEIN",
      },
      {
        CITY: "BAD LIEBENWERDA",
      },
      {
        CITY: "BAD LIEBENZELL",
      },
      {
        CITY: "BAD LIPPSPRINGE",
      },
      {
        CITY: "BAD LOBENSTEIN",
      },
      {
        CITY: "BAD MARIENBERG",
      },
      {
        CITY: "BAD MERGENTHEIM",
      },
      {
        CITY: "BAD MUNDER AM DEISTER",
      },
      {
        CITY: "BAD MUNSTER AM STEIN-EBERNBURG",
      },
      {
        CITY: "BAD MUNSTEREIFEL",
      },
      {
        CITY: "BAD MUSKAU",
      },
      {
        CITY: "BAD NAUHEIM",
      },
      {
        CITY: "BAD NENNDORF",
      },
      {
        CITY: "BAD NEUENAHR-AHRWEILER",
      },
      {
        CITY: "BAD NEUSTADT AN DER SAALE",
      },
      {
        CITY: "BAD OEYNHAUSEN",
      },
      {
        CITY: "BAD OLDESLOE",
      },
      {
        CITY: "BAD ORB",
      },
      {
        CITY: "BAD PETERSTAL-GRIESBACH",
      },
      {
        CITY: "BAD PYRMONT",
      },
      {
        CITY: "BAD RAPPENAU",
      },
      {
        CITY: "BAD REICHENHALL",
      },
      {
        CITY: "BAD RIPPOLDSAU-SCHAPBACH",
      },
      {
        CITY: "BAD ROTHENFELDE",
      },
      {
        CITY: "BAD SAAROW",
      },
      {
        CITY: "BAD SACHSA",
      },
      {
        CITY: "BAD SACKINGEN",
      },
      {
        CITY: "BAD SALZDETFURTH",
      },
      {
        CITY: "BAD SALZSCHLIRF",
      },
      {
        CITY: "BAD SALZUFLEN",
      },
      {
        CITY: "BAD SALZUNGEN",
      },
      {
        CITY: "BAD SASSENDORF",
      },
      {
        CITY: "BAD SCHACHEN",
      },
      {
        CITY: "BAD SCHANDAU",
      },
      {
        CITY: "BAD SCHMIEDEBERG",
      },
      {
        CITY: "BAD SCHUSSENRIED",
      },
      {
        CITY: "BAD SCHWALBACH",
      },
      {
        CITY: "BAD SCHWARTAU",
      },
      {
        CITY: "BAD SEGEBERG",
      },
      {
        CITY: "BAD SODEN AM TAUNUS",
      },
      {
        CITY: "BAD SODEN-SALMUNSTER",
      },
      {
        CITY: "BAD SOODEN-ALLENDORF",
      },
      {
        CITY: "BAD STAFFELSTEIN",
      },
      {
        CITY: "BAD STEBEN",
      },
      {
        CITY: "BAD SULZA",
      },
      {
        CITY: "BAD TEINACH-ZAVELSTEIN",
      },
      {
        CITY: "BAD TENNSTEDT",
      },
      {
        CITY: "BAD TOELZ",
      },
      {
        CITY: "BAD UBERKINGEN",
      },
      {
        CITY: "BAD URACH",
      },
      {
        CITY: "BAD VILBEL",
      },
      {
        CITY: "BAD WALDSEE",
      },
      {
        CITY: "BAD WIESSEE",
      },
      {
        CITY: "BAD WILDBAD IM SCHWARZWALD",
      },
      {
        CITY: "BAD WILDUNGEN",
      },
      {
        CITY: "BAD WILSNACK",
      },
      {
        CITY: "BAD WIMPFEN",
      },
      {
        CITY: "BAD WINDSHEIM",
      },
      {
        CITY: "BAD WORISHOFEN",
      },
      {
        CITY: "BAD WURZACH",
      },
      {
        CITY: "BAD ZWISCHENAHN",
      },
      {
        CITY: "BADBERGEN",
      },
      {
        CITY: "BADDECKENSTEDT",
      },
      {
        CITY: "BADEM",
      },
      {
        CITY: "BADEN-BADEN",
      },
      {
        CITY: "BADENHAUSEN",
      },
      {
        CITY: "BADENWEILER",
      },
      {
        CITY: "BADOW",
      },
      {
        CITY: "BADRA",
      },
      {
        CITY: "BAESWEILER",
      },
      {
        CITY: "BAHLINGEN",
      },
      {
        CITY: "BAHRATAL",
      },
      {
        CITY: "BAHRDORF",
      },
      {
        CITY: "BAHRENFLETH",
      },
      {
        CITY: "BAIENFURT",
      },
      {
        CITY: "BAIERBRUNN",
      },
      {
        CITY: "BAIERN",
      },
      {
        CITY: "BAIERSBRONN",
      },
      {
        CITY: "BAIERSDORF",
      },
      {
        CITY: "BAINDT",
      },
      {
        CITY: "BAKUM",
      },
      {
        CITY: "BALDHAM",
      },
      {
        CITY: "BALGHEIM",
      },
      {
        CITY: "BALINGEN",
      },
      {
        CITY: "BALLENDORF",
      },
      {
        CITY: "BALLENSTEDT",
      },
      {
        CITY: "BALLERSBACH",
      },
      {
        CITY: "BALLRECHTEN",
      },
      {
        CITY: "BALLSTADT",
      },
      {
        CITY: "BALTMANNSWEILER",
      },
      {
        CITY: "BALTRINGEN",
      },
      {
        CITY: "BALTRUM",
      },
      {
        CITY: "BALVE",
      },
      {
        CITY: "BAMBERG",
      },
      {
        CITY: "BAMLACH",
      },
      {
        CITY: "BAMMENTAL",
      },
      {
        CITY: "BANK",
      },
      {
        CITY: "BANKEWITZ",
      },
      {
        CITY: "BANN",
      },
      {
        CITY: "BANNBERSCHEID",
      },
      {
        CITY: "BANNEMIN",
      },
      {
        CITY: "BANNEWITZ",
      },
      {
        CITY: "BANTELN",
      },
      {
        CITY: "BANZKOW",
      },
      {
        CITY: "BARBELROTH",
      },
      {
        CITY: "BARBING",
      },
      {
        CITY: "BARBY",
      },
      {
        CITY: "BARCHFELD",
      },
      {
        CITY: "BARDENBERG",
      },
      {
        CITY: "BARDOWICK",
      },
      {
        CITY: "BARENDORF",
      },
      {
        CITY: "BARENSTEIN",
      },
      {
        CITY: "BARENTHAL",
      },
      {
        CITY: "BARGENSTEDT",
      },
      {
        CITY: "BARGFELD-STEGEN",
      },
      {
        CITY: "BARGHORST",
      },
      {
        CITY: "BARGSTEDT",
      },
      {
        CITY: "BARGTEHEIDE",
      },
      {
        CITY: "BARGUM",
      },
      {
        CITY: "BARKELSBY",
      },
      {
        CITY: "BARKENHOLM",
      },
      {
        CITY: "BARKOW",
      },
      {
        CITY: "BARLEBEN",
      },
      {
        CITY: "BARMSTEDT",
      },
      {
        CITY: "BARNAU",
      },
      {
        CITY: "BARNSTORF",
      },
      {
        CITY: "BARNTRUP",
      },
      {
        CITY: "BARSBUETTEL",
      },
      {
        CITY: "BARSINGHAUSEN",
      },
      {
        CITY: "BARSSEL",
      },
      {
        CITY: "BARSTADT",
      },
      {
        CITY: "BARTH",
      },
      {
        CITY: "BARTHOLOMA",
      },
      {
        CITY: "BARTOW",
      },
      {
        CITY: "BARUM",
      },
      {
        CITY: "BARUTH",
      },
      {
        CITY: "BARWEDEL",
      },
      {
        CITY: "BASEL",
      },
      {
        CITY: "BASSENHEIM",
      },
      {
        CITY: "BASSUM",
      },
      {
        CITY: "BASTHEIM",
      },
      {
        CITY: "BASTORF",
      },
      {
        CITY: "BATTENBERG",
      },
      {
        CITY: "BATTENBERG",
      },
      {
        CITY: "BATZHAUSEN",
      },
      {
        CITY: "BAUDENBACH",
      },
      {
        CITY: "BAUMHOLDER",
      },
      {
        CITY: "BAUNACH",
      },
      {
        CITY: "BAUNATAL",
      },
      {
        CITY: "BAUSENDORF",
      },
      {
        CITY: "BAUTZEN",
      },
      {
        CITY: "BAWINKEL",
      },
      {
        CITY: "BAYENTHAL",
      },
      {
        CITY: "BAYERBACH",
      },
      {
        CITY: "BAYERISCH EISENSTEIN",
      },
      {
        CITY: "BAYERISCH GMAIN",
      },
      {
        CITY: "BAYERN",
      },
      {
        CITY: "BAYERSOIEN",
      },
      {
        CITY: "BAYREUTH",
      },
      {
        CITY: "BAYRISCHZELL",
      },
      {
        CITY: "BEAUMARAIS",
      },
      {
        CITY: "BEBRA",
      },
      {
        CITY: "BECHELN",
      },
      {
        CITY: "BECHERBACH",
      },
      {
        CITY: "BECHHOFEN",
      },
      {
        CITY: "BECHTOLSHEIM",
      },
      {
        CITY: "BECKDORF",
      },
      {
        CITY: "BECKEDORF",
      },
      {
        CITY: "BECKELN",
      },
      {
        CITY: "BECKINGEN",
      },
      {
        CITY: "BECKSTEDT",
      },
      {
        CITY: "BECKUM",
      },
      {
        CITY: "BEDBURDYCK",
      },
      {
        CITY: "BEDBURG",
      },
      {
        CITY: "BEDERKESA",
      },
      {
        CITY: "BEEDENBOSTEL",
      },
      {
        CITY: "BEELEN",
      },
      {
        CITY: "BEELITZ",
      },
      {
        CITY: "BEERFELDEN",
      },
      {
        CITY: "BEESKOW",
      },
      {
        CITY: "BEESTEN",
      },
      {
        CITY: "BEETZENDORF",
      },
      {
        CITY: "BEETZSEE",
      },
      {
        CITY: "BEHRENDORF",
      },
      {
        CITY: "BEHRENHOFF",
      },
      {
        CITY: "BEHRINGEN",
      },
      {
        CITY: "BEIERFELD",
      },
      {
        CITY: "BEIERSDORF",
      },
      {
        CITY: "BEIERSTEDT",
      },
      {
        CITY: "BEILNGRIES",
      },
      {
        CITY: "BEILRODE",
      },
      {
        CITY: "BEILSTEIN",
      },
      {
        CITY: "BEIM KRONSBERG",
      },
      {
        CITY: "BEIMERSTETTEN",
      },
      {
        CITY: "BEINDERSHEIM",
      },
      {
        CITY: "BEINERSTADT",
      },
      {
        CITY: "BEKOND",
      },
      {
        CITY: "BELECKE",
      },
      {
        CITY: "BELGERN",
      },
      {
        CITY: "BELGERSHAIN",
      },
      {
        CITY: "BELL",
      },
      {
        CITY: "BELL",
      },
      {
        CITY: "BELLAMONT",
      },
      {
        CITY: "BELLENBERG",
      },
      {
        CITY: "BELLENBERG",
      },
      {
        CITY: "BELLERSHAUSEN",
      },
      {
        CITY: "BELLHEIM",
      },
      {
        CITY: "BELM",
      },
      {
        CITY: "BELTHEIM",
      },
      {
        CITY: "BELUM",
      },
      {
        CITY: "BELZIG",
      },
      {
        CITY: "BEMERODE",
      },
      {
        CITY: "BEMPFLINGEN",
      },
      {
        CITY: "BENDELEBEN",
      },
      {
        CITY: "BENDESTORF",
      },
      {
        CITY: "BENDFELD",
      },
      {
        CITY: "BENDORF",
      },
      {
        CITY: "BENEDIKTBEUERN",
      },
      {
        CITY: "BENNECKENSTEIN",
      },
      {
        CITY: "BENNINGEN",
      },
      {
        CITY: "BENNINGEN AM NECKAR",
      },
      {
        CITY: "BENNUNGEN",
      },
      {
        CITY: "BENSBERG",
      },
      {
        CITY: "BENSHAUSEN",
      },
      {
        CITY: "BENSHEIM",
      },
      {
        CITY: "BENTFELD",
      },
      {
        CITY: "BENTWISCH",
      },
      {
        CITY: "BENZ",
      },
      {
        CITY: "BENZINGERODE",
      },
      {
        CITY: "BERATZHAUSEN",
      },
      {
        CITY: "BERCHING",
      },
      {
        CITY: "BERCHTESGADEN",
      },
      {
        CITY: "BERG",
      },
      {
        CITY: "BERG",
      },
      {
        CITY: "BERG",
      },
      {
        CITY: "BERG",
      },
      {
        CITY: "BERGA",
      },
      {
        CITY: "BERGA",
      },
      {
        CITY: "BERGBAU",
      },
      {
        CITY: "BERGE",
      },
      {
        CITY: "BERGEN",
      },
      {
        CITY: "BERGEN",
      },
      {
        CITY: "BERGEN",
      },
      {
        CITY: "BERGEN AUF RUEGEN",
      },
      {
        CITY: "BERGFELDE",
      },
      {
        CITY: "BERGGIESSHUBEL",
      },
      {
        CITY: "BERGHAUPTEN",
      },
      {
        CITY: "BERGHAUSEN",
      },
      {
        CITY: "BERGHEIM",
      },
      {
        CITY: "BERGHULEN",
      },
      {
        CITY: "BERGISCH GLADBACH",
      },
      {
        CITY: "BERGKAMEN",
      },
      {
        CITY: "BERGKIRCHEN",
      },
      {
        CITY: "BERGLERN",
      },
      {
        CITY: "BERGLICHT",
      },
      {
        CITY: "BERGNEUSTADT",
      },
      {
        CITY: "BERGRHEINFELD",
      },
      {
        CITY: "BERGTHEIM",
      },
      {
        CITY: "BERGWEILER",
      },
      {
        CITY: "BERKA",
      },
      {
        CITY: "BERKENTHIN",
      },
      {
        CITY: "BERKHEIM",
      },
      {
        CITY: "BERLIN",
      },
      {
        CITY: "BERLIN",
      },
      {
        CITY: "BERLINGERODE",
      },
      {
        CITY: "BERMATINGEN",
      },
      {
        CITY: "BERMBACH",
      },
      {
        CITY: "BERMERSHEIM",
      },
      {
        CITY: "BERMUTHSHAIN",
      },
      {
        CITY: "BERNAU",
      },
      {
        CITY: "BERNAU AM CHIEMSEE",
      },
      {
        CITY: "BERNAU BEI BERLIN",
      },
      {
        CITY: "BERNBURG",
      },
      {
        CITY: "BERNE",
      },
      {
        CITY: "BERNGAU",
      },
      {
        CITY: "BERNHARDSWALD",
      },
      {
        CITY: "BERNKASTEL-KUES",
      },
      {
        CITY: "BERNRIED",
      },
      {
        CITY: "BERNSBACH",
      },
      {
        CITY: "BERNSDORF",
      },
      {
        CITY: "BERNSDORF",
      },
      {
        CITY: "BERNSTADT",
      },
      {
        CITY: "BERNSTADT",
      },
      {
        CITY: "BERNTERODE",
      },
      {
        CITY: "BEROD BEI WALLMEROD",
      },
      {
        CITY: "BERSENBRUCK",
      },
      {
        CITY: "BERUMBUR",
      },
      {
        CITY: "BERZDORF",
      },
      {
        CITY: "BESCHEID",
      },
      {
        CITY: "BESIGHEIM",
      },
      {
        CITY: "BESITZ",
      },
      {
        CITY: "BESTENSEE",
      },
      {
        CITY: "BESTWIG",
      },
      {
        CITY: "BETHELN",
      },
      {
        CITY: "BETTINGEN",
      },
      {
        CITY: "BETZDORF",
      },
      {
        CITY: "BETZENDORF",
      },
      {
        CITY: "BETZENSTEIN",
      },
      {
        CITY: "BETZIGAU",
      },
      {
        CITY: "BEUCHA",
      },
      {
        CITY: "BEUERN",
      },
      {
        CITY: "BEUREN",
      },
      {
        CITY: "BEUREN",
      },
      {
        CITY: "BEVERN",
      },
      {
        CITY: "BEVERSTEDT",
      },
      {
        CITY: "BEVERUNGEN",
      },
      {
        CITY: "BEXBACH",
      },
      {
        CITY: "BEXHOVEDE",
      },
      {
        CITY: "BIBERACH AN DER RISS",
      },
      {
        CITY: "BIBERBACH",
      },
      {
        CITY: "BIBLIS",
      },
      {
        CITY: "BIBURG",
      },
      {
        CITY: "BICHL",
      },
      {
        CITY: "BICKENBACH",
      },
      {
        CITY: "BIEBELSHEIM",
      },
      {
        CITY: "BIEBER",
      },
      {
        CITY: "BIEBESHEIM",
      },
      {
        CITY: "BIEDENKOPF",
      },
      {
        CITY: "BIEDERITZ",
      },
      {
        CITY: "BIELEFELD",
      },
      {
        CITY: "BIENENBUTTEL",
      },
      {
        CITY: "BIENSTADT",
      },
      {
        CITY: "BIERE",
      },
      {
        CITY: "BIERLINGEN",
      },
      {
        CITY: "BIESENTHAL",
      },
      {
        CITY: "BIESINGEN",
      },
      {
        CITY: "BIESSENHOFEN",
      },
      {
        CITY: "BIETIGHEIM",
      },
      {
        CITY: "BIETIGHEIM-BISSINGEN",
      },
      {
        CITY: "BIETINGEN",
      },
      {
        CITY: "BILK",
      },
      {
        CITY: "BILLERBECK",
      },
      {
        CITY: "BILLIGHEIM",
      },
      {
        CITY: "BILLIGHEIM-INGENHEIM",
      },
      {
        CITY: "BILLINGS",
      },
      {
        CITY: "BILLINGSHAUSEN",
      },
      {
        CITY: "BIMOHLEN",
      },
      {
        CITY: "BINAU",
      },
      {
        CITY: "BINDLACH",
      },
      {
        CITY: "BINGEN",
      },
      {
        CITY: "BINGEN AM RHEIN",
      },
      {
        CITY: "BINSFELD",
      },
      {
        CITY: "BINZEN",
      },
      {
        CITY: "BIPPEN",
      },
      {
        CITY: "BIRENBACH",
      },
      {
        CITY: "BIRKENAU",
      },
      {
        CITY: "BIRKENFELD",
      },
      {
        CITY: "BIRKENFELD",
      },
      {
        CITY: "BIRKENFELD",
      },
      {
        CITY: "BIRKENHEIDE",
      },
      {
        CITY: "BIRKENHOF",
      },
      {
        CITY: "BIRKENLOHE",
      },
      {
        CITY: "BIRKENWERDER",
      },
      {
        CITY: "BIRKHEIM",
      },
      {
        CITY: "BIRLENBACH",
      },
      {
        CITY: "BIRLINGHOVEN",
      },
      {
        CITY: "BIRNBACH",
      },
      {
        CITY: "BIRNBACH",
      },
      {
        CITY: "BIRSTEIN",
      },
      {
        CITY: "BISCHBERG",
      },
      {
        CITY: "BISCHHEIM",
      },
      {
        CITY: "BISCHOFFEN",
      },
      {
        CITY: "BISCHOFFERODE",
      },
      {
        CITY: "BISCHOFSGRUN",
      },
      {
        CITY: "BISCHOFSHEIM",
      },
      {
        CITY: "BISCHOFSHEIM AN DER RHON",
      },
      {
        CITY: "BISCHOFSMAIS",
      },
      {
        CITY: "BISCHOFSWERDA",
      },
      {
        CITY: "BISCHOFSWIESEN",
      },
      {
        CITY: "BISCHWEIER",
      },
      {
        CITY: "BISINGEN",
      },
      {
        CITY: "BISMARK",
      },
      {
        CITY: "BISMARK",
      },
      {
        CITY: "BISPINGEN",
      },
      {
        CITY: "BISSENDORF",
      },
      {
        CITY: "BISSINGEN",
      },
      {
        CITY: "BISSINGEN AN DER TECK",
      },
      {
        CITY: "BISTENSEE",
      },
      {
        CITY: "BITBURG",
      },
      {
        CITY: "BITTERFELD-WOLFEN",
      },
      {
        CITY: "BITZ",
      },
      {
        CITY: "BLAIBACH",
      },
      {
        CITY: "BLAICHACH",
      },
      {
        CITY: "BLANKENBURG",
      },
      {
        CITY: "BLANKENFELDE",
      },
      {
        CITY: "BLANKENHAGEN",
      },
      {
        CITY: "BLANKENHAIN",
      },
      {
        CITY: "BLANKENHAIN",
      },
      {
        CITY: "BLANKENHEIM",
      },
      {
        CITY: "BLANKENLOCH",
      },
      {
        CITY: "BLANKENRATH",
      },
      {
        CITY: "BLANKENSEE",
      },
      {
        CITY: "BLANKENSTEIN",
      },
      {
        CITY: "BLATZHEIM",
      },
      {
        CITY: "BLAUBEUREN ABBEY",
      },
      {
        CITY: "BLECHHAMMER",
      },
      {
        CITY: "BLECKEDE",
      },
      {
        CITY: "BLECKENRODE",
      },
      {
        CITY: "BLEIALF",
      },
      {
        CITY: "BLEICHERODE",
      },
      {
        CITY: "BLENDER",
      },
      {
        CITY: "BLIESDORF",
      },
      {
        CITY: "BLIESKASTEL",
      },
      {
        CITY: "BLIESMENGEN-BOLCHEN",
      },
      {
        CITY: "BLIEVENSTORF",
      },
      {
        CITY: "BLITZENREUTE",
      },
      {
        CITY: "BLOMBERG",
      },
      {
        CITY: "BLOMBERG",
      },
      {
        CITY: "BLOMESCHE WILDNIS",
      },
      {
        CITY: "BLUMBERG",
      },
      {
        CITY: "BLUME",
      },
      {
        CITY: "BLUMENTHAL",
      },
      {
        CITY: "BOBBAU",
      },
      {
        CITY: "BOBENHEIM AM BERG",
      },
      {
        CITY: "BOBENHEIM-ROXHEIM",
      },
      {
        CITY: "BOBINGEN",
      },
      {
        CITY: "BOBRACH",
      },
      {
        CITY: "BOCHOLT",
      },
      {
        CITY: "BOCHUM",
      },
      {
        CITY: "BOCKA",
      },
      {
        CITY: "BOCKAU",
      },
      {
        CITY: "BOCKELWITZ",
      },
      {
        CITY: "BOCKENAU",
      },
      {
        CITY: "BOCKENEM",
      },
      {
        CITY: "BOCKENHEIM",
      },
      {
        CITY: "BOCKHORN",
      },
      {
        CITY: "BOCKHORN",
      },
      {
        CITY: "BOCKHORST",
      },
      {
        CITY: "BODDENSTEDT",
      },
      {
        CITY: "BODDIN",
      },
      {
        CITY: "BODELSHAUSEN",
      },
      {
        CITY: "BODEN",
      },
      {
        CITY: "BODENFELDE",
      },
      {
        CITY: "BODENHEIM",
      },
      {
        CITY: "BODENKIRCHEN",
      },
      {
        CITY: "BODENMAIS",
      },
      {
        CITY: "BODENSEE",
      },
      {
        CITY: "BODENTEICH",
      },
      {
        CITY: "BODENWERDER",
      },
      {
        CITY: "BODENWOHR",
      },
      {
        CITY: "BODMAN-LUDWIGSHAFEN",
      },
      {
        CITY: "BODOLZ",
      },
      {
        CITY: "BOEL",
      },
      {
        CITY: "BOFFZEN",
      },
      {
        CITY: "BOFINGEN",
      },
      {
        CITY: "BOGEN",
      },
      {
        CITY: "BOGENHAUSEN",
      },
      {
        CITY: "BOHL-IGGELHEIM",
      },
      {
        CITY: "BOHLEN",
      },
      {
        CITY: "BOHMENKIRCH",
      },
      {
        CITY: "BOHMSTEDT",
      },
      {
        CITY: "BOHMTE",
      },
      {
        CITY: "BOHRINGEN",
      },
      {
        CITY: "BOICH",
      },
      {
        CITY: "BOIZENBURG",
      },
      {
        CITY: "BOKLUND",
      },
      {
        CITY: "BOLANDEN",
      },
      {
        CITY: "BOLDEBUCK",
      },
      {
        CITY: "BOLDELA",
      },
      {
        CITY: "BOLLENDORF",
      },
      {
        CITY: "BOLLINGSTEDT",
      },
      {
        CITY: "BOLLSCHWEIL",
      },
      {
        CITY: "BOLLSTEIN",
      },
      {
        CITY: "BOLSTERLANG",
      },
      {
        CITY: "BOMLITZ",
      },
      {
        CITY: "BONDORF",
      },
      {
        CITY: "BONEBUTTEL",
      },
      {
        CITY: "BONEFELD",
      },
      {
        CITY: "BONEN",
      },
      {
        CITY: "BONN",
      },
      {
        CITY: "BONNDORF IM SCHWARZWALD",
      },
      {
        CITY: "BONNIGHEIM",
      },
      {
        CITY: "BONNINGSTEDT",
      },
      {
        CITY: "BONSTETTEN",
      },
      {
        CITY: "BOOS",
      },
      {
        CITY: "BOOS",
      },
      {
        CITY: "BOOSTEDT",
      },
      {
        CITY: "BOPFINGEN",
      },
      {
        CITY: "BOPPARD",
      },
      {
        CITY: "BORDELUM",
      },
      {
        CITY: "BORDESHOLM",
      },
      {
        CITY: "BORGDORF-SEEDORF",
      },
      {
        CITY: "BORGENTREICH",
      },
      {
        CITY: "BORGER",
      },
      {
        CITY: "BORGHOLZHAUSEN",
      },
      {
        CITY: "BORGSDORF",
      },
      {
        CITY: "BORGSTEDT",
      },
      {
        CITY: "BORGSUM",
      },
      {
        CITY: "BORKEN",
      },
      {
        CITY: "BORKEN",
      },
      {
        CITY: "BORKHEIDE",
      },
      {
        CITY: "BORKUM",
      },
      {
        CITY: "BORKWALDE",
      },
      {
        CITY: "BORN",
      },
      {
        CITY: "BORNA",
      },
      {
        CITY: "BORNE",
      },
      {
        CITY: "BORNHEIM",
      },
      {
        CITY: "BORNHEIM",
      },
      {
        CITY: "BORNHOVED",
      },
      {
        CITY: "BORNICH",
      },
      {
        CITY: "BORNITZ",
      },
      {
        CITY: "BORNSEN",
      },
      {
        CITY: "BORNSTEIN",
      },
      {
        CITY: "BORSDORF",
      },
      {
        CITY: "BORSFLETH",
      },
      {
        CITY: "BORSTEL",
      },
      {
        CITY: "BORSTEL-HOHENRADEN",
      },
      {
        CITY: "BORTLINGEN",
      },
      {
        CITY: "BOSAU",
      },
      {
        CITY: "BOSEL",
      },
      {
        CITY: "BOSINGEN",
      },
      {
        CITY: "BOSLEBEN-WULLERSLEBEN",
      },
      {
        CITY: "BOTERSEN",
      },
      {
        CITY: "BOTHKAMP",
      },
      {
        CITY: "BOTTROP",
      },
      {
        CITY: "BOTZINGEN",
      },
      {
        CITY: "BOUS",
      },
      {
        CITY: "BOVENDEN",
      },
      {
        CITY: "BOXBERG",
      },
      {
        CITY: "BRACHBACH",
      },
      {
        CITY: "BRACKEL",
      },
      {
        CITY: "BRACKENHEIM",
      },
      {
        CITY: "BRAKE (UNTERWESER)",
      },
      {
        CITY: "BRAKEL",
      },
      {
        CITY: "BRAMSCHE",
      },
      {
        CITY: "BRAMSTEDT",
      },
      {
        CITY: "BRAND",
      },
      {
        CITY: "BRAND-ERBISDORF",
      },
      {
        CITY: "BRANDE-HORNERKIRCHEN",
      },
      {
        CITY: "BRANDENBURG",
      },
      {
        CITY: "BRANDIS",
      },
      {
        CITY: "BRANDL",
      },
      {
        CITY: "BRANDSHAGEN",
      },
      {
        CITY: "BRANNENBURG",
      },
      {
        CITY: "BRAUBACH",
      },
      {
        CITY: "BRAUNFELS",
      },
      {
        CITY: "BRAUNLAGE",
      },
      {
        CITY: "BRAUNLINGEN",
      },
      {
        CITY: "BRAUNSBACH",
      },
      {
        CITY: "BRAUNSBEDRA",
      },
      {
        CITY: "BRAUNSCHWEIG",
      },
      {
        CITY: "BRAUWEILER",
      },
      {
        CITY: "BREBEL",
      },
      {
        CITY: "BRECKERFELD",
      },
      {
        CITY: "BREDDENBERG",
      },
      {
        CITY: "BREDDORF",
      },
      {
        CITY: "BREDENBEK",
      },
      {
        CITY: "BREDENEY",
      },
      {
        CITY: "BREDSTEDT",
      },
      {
        CITY: "BREESEN",
      },
      {
        CITY: "BREEST",
      },
      {
        CITY: "BREGENSTEDT",
      },
      {
        CITY: "BREIDENBACH",
      },
      {
        CITY: "BREIHOLZ",
      },
      {
        CITY: "BREISACH",
      },
      {
        CITY: "BREIT",
      },
      {
        CITY: "BREITBRUNN AM CHIEMSEE",
      },
      {
        CITY: "BREITENAU",
      },
      {
        CITY: "BREITENBACH",
      },
      {
        CITY: "BREITENBACH",
      },
      {
        CITY: "BREITENBACH",
      },
      {
        CITY: "BREITENBACH AM HERZBERG",
      },
      {
        CITY: "BREITENBERG",
      },
      {
        CITY: "BREITENBRUNN",
      },
      {
        CITY: "BREITENBRUNN",
      },
      {
        CITY: "BREITENBURG",
      },
      {
        CITY: "BREITENFELDE",
      },
      {
        CITY: "BREITENGUSSBACH",
      },
      {
        CITY: "BREITENWORBIS",
      },
      {
        CITY: "BREITNAU",
      },
      {
        CITY: "BREITSCHEID",
      },
      {
        CITY: "BREITSCHEID",
      },
      {
        CITY: "BREITSCHEID",
      },
      {
        CITY: "BREITSCHEIDT",
      },
      {
        CITY: "BREITUNGEN",
      },
      {
        CITY: "BREKENDORF",
      },
      {
        CITY: "BREMEN",
      },
      {
        CITY: "BREMERHAVEN",
      },
      {
        CITY: "BREMERVORDE",
      },
      {
        CITY: "BRENNBERG",
      },
      {
        CITY: "BRENSBACH",
      },
      {
        CITY: "BRENZ",
      },
      {
        CITY: "BRETTEN",
      },
      {
        CITY: "BRETTHAUSEN",
      },
      {
        CITY: "BRETZFELD",
      },
      {
        CITY: "BREUNA",
      },
      {
        CITY: "BREY",
      },
      {
        CITY: "BRICKELN",
      },
      {
        CITY: "BRIESELANG",
      },
      {
        CITY: "BRIESKE",
      },
      {
        CITY: "BRIESKOW-FINKENHEERD",
      },
      {
        CITY: "BRIETLINGEN",
      },
      {
        CITY: "BRILON",
      },
      {
        CITY: "BRITZ",
      },
      {
        CITY: "BROCKUM",
      },
      {
        CITY: "BRODERSBY",
      },
      {
        CITY: "BRODERSTORF",
      },
      {
        CITY: "BRODHAGEN",
      },
      {
        CITY: "BROECKEL",
      },
      {
        CITY: "BROKDORF",
      },
      {
        CITY: "BROKSTEDT",
      },
      {
        CITY: "BROME",
      },
      {
        CITY: "BROMSKIRCHEN",
      },
      {
        CITY: "BROTTERODE",
      },
      {
        CITY: "BRUCH",
      },
      {
        CITY: "BRUCHHAUSEN",
      },
      {
        CITY: "BRUCHHAUSEN-VILSEN",
      },
      {
        CITY: "BRUCHKOBEL",
      },
      {
        CITY: "BRUCHMUHLBACH-MIESAU",
      },
      {
        CITY: "BRUCHSAL",
      },
      {
        CITY: "BRUCHWEILER",
      },
      {
        CITY: "BRUCK",
      },
      {
        CITY: "BRUCK IN DER OBERPFALZ",
      },
      {
        CITY: "BRUCKBERG",
      },
      {
        CITY: "BRUCKEN",
      },
      {
        CITY: "BRUCKMUHL",
      },
      {
        CITY: "BRUCKTAL",
      },
      {
        CITY: "BRUEL",
      },
      {
        CITY: "BRUGGEN",
      },
      {
        CITY: "BRUGGEN",
      },
      {
        CITY: "BRUHL",
      },
      {
        CITY: "BRUHL",
      },
      {
        CITY: "BRUNN",
      },
      {
        CITY: "BRUNNTHAL",
      },
      {
        CITY: "BRUNSBUTTEL",
      },
      {
        CITY: "BRUNSTORF",
      },
      {
        CITY: "BRUSEWITZ",
      },
      {
        CITY: "BRUSSOW",
      },
      {
        CITY: "BUBENHEIM",
      },
      {
        CITY: "BUBENREUTH",
      },
      {
        CITY: "BUBESHEIM",
      },
      {
        CITY: "BUBORN",
      },
      {
        CITY: "BUCH",
      },
      {
        CITY: "BUCH",
      },
      {
        CITY: "BUCH",
      },
      {
        CITY: "BUCH AM BUCHRAIN",
      },
      {
        CITY: "BUCH AM ERLBACH",
      },
      {
        CITY: "BUCHA",
      },
      {
        CITY: "BUCHA",
      },
      {
        CITY: "BUCHBACH",
      },
      {
        CITY: "BUCHBACH",
      },
      {
        CITY: "BUCHBERG",
      },
      {
        CITY: "BUCHEL",
      },
      {
        CITY: "BUCHEN",
      },
      {
        CITY: "BUCHEN",
      },
      {
        CITY: "BUCHENBACH",
      },
      {
        CITY: "BUCHENBACH",
      },
      {
        CITY: "BUCHENBERG",
      },
      {
        CITY: "BUCHFART",
      },
      {
        CITY: "BUCHHOLZ",
      },
      {
        CITY: "BUCHHOLZ",
      },
      {
        CITY: "BUCHHOLZ IN DER NORDHEIDE",
      },
      {
        CITY: "BUCHLBERG",
      },
      {
        CITY: "BUCHLOE",
      },
      {
        CITY: "BUCHWALDCHEN",
      },
      {
        CITY: "BUCHWALDE",
      },
      {
        CITY: "BUCKEBURG",
      },
      {
        CITY: "BUCKENHOF",
      },
      {
        CITY: "BUCKOW",
      },
      {
        CITY: "BUDEN",
      },
      {
        CITY: "BUDENHEIM",
      },
      {
        CITY: "BUDINGEN",
      },
      {
        CITY: "BUEDDENSTEDT",
      },
      {
        CITY: "BUEDELSDORF",
      },
      {
        CITY: "BUENDE",
      },
      {
        CITY: "BUGGINGEN",
      },
      {
        CITY: "BUHL",
      },
      {
        CITY: "BUHLEN",
      },
      {
        CITY: "BUHLER",
      },
      {
        CITY: "BUHLERHOHE",
      },
      {
        CITY: "BUHLERTAL",
      },
      {
        CITY: "BUHLERTANN",
      },
      {
        CITY: "BUHLERZELL",
      },
      {
        CITY: "BULKAU",
      },
      {
        CITY: "BULLAY",
      },
      {
        CITY: "BULOW",
      },
      {
        CITY: "BULOWERBURG",
      },
      {
        CITY: "BUNDE",
      },
      {
        CITY: "BUNDENTHAL",
      },
      {
        CITY: "BUNSOH",
      },
      {
        CITY: "BURBACH",
      },
      {
        CITY: "BURBACH",
      },
      {
        CITY: "BUREN",
      },
      {
        CITY: "BURG",
      },
      {
        CITY: "BURG",
      },
      {
        CITY: "BURG AUF FEHMARN",
      },
      {
        CITY: "BURG BEI MAGDEBURG",
      },
      {
        CITY: "BURG STARGARD",
      },
      {
        CITY: "BURG-GRAMBKE",
      },
      {
        CITY: "BURGAU",
      },
      {
        CITY: "BURGBERG",
      },
      {
        CITY: "BURGBERNHEIM",
      },
      {
        CITY: "BURGBROHL",
      },
      {
        CITY: "BURGDORF HANOVER",
      },
      {
        CITY: "BURGEBRACH",
      },
      {
        CITY: "BURGEN",
      },
      {
        CITY: "BURGERHOF",
      },
      {
        CITY: "BURGHASLACH",
      },
      {
        CITY: "BURGHAUN",
      },
      {
        CITY: "BURGHAUSEN",
      },
      {
        CITY: "BURGHEIM",
      },
      {
        CITY: "BURGKIRCHEN AN DER ALZ",
      },
      {
        CITY: "BURGKUNSTADT",
      },
      {
        CITY: "BURGLAUER",
      },
      {
        CITY: "BURGLENGENFELD",
      },
      {
        CITY: "BURGOBERBACH",
      },
      {
        CITY: "BURGRIEDEN",
      },
      {
        CITY: "BURGSCHWALBACH",
      },
      {
        CITY: "BURGSINN",
      },
      {
        CITY: "BURGSPONHEIM",
      },
      {
        CITY: "BURGSTADT",
      },
      {
        CITY: "BURGSTADT",
      },
      {
        CITY: "BURGSTALL",
      },
      {
        CITY: "BURGSTETTEN",
      },
      {
        CITY: "BURGTHANN",
      },
      {
        CITY: "BURGWEDEL",
      },
      {
        CITY: "BURGWINDHEIM",
      },
      {
        CITY: "BURK",
      },
      {
        CITY: "BURKARDROTH",
      },
      {
        CITY: "BURKAU",
      },
      {
        CITY: "BURKHARDTSDORF",
      },
      {
        CITY: "BURLADINGEN",
      },
      {
        CITY: "BURSCHEID",
      },
      {
        CITY: "BURSTADT",
      },
      {
        CITY: "BURTENBACH",
      },
      {
        CITY: "BURWEG",
      },
      {
        CITY: "BUSCHBELL",
      },
      {
        CITY: "BUSCHHAUSEN",
      },
      {
        CITY: "BUSDORF",
      },
      {
        CITY: "BUSENBERG",
      },
      {
        CITY: "BUTJADINGEN",
      },
      {
        CITY: "BUTTELBORN",
      },
      {
        CITY: "BUTTELSTEDT",
      },
      {
        CITY: "BUTTENHEIM",
      },
      {
        CITY: "BUTTENWIESEN",
      },
      {
        CITY: "BUTTHARD",
      },
      {
        CITY: "BUTTSTEDT",
      },
      {
        CITY: "BUTZBACH",
      },
      {
        CITY: "BUTZOW",
      },
      {
        CITY: "BUXHEIM",
      },
      {
        CITY: "BUXTEHUDE",
      },
      {
        CITY: "CADENBERGE",
      },
      {
        CITY: "CADOLZBURG",
      },
      {
        CITY: "CALAU",
      },
      {
        CITY: "CALBE",
      },
      {
        CITY: "CALBERLAH",
      },
      {
        CITY: "CALDEN",
      },
      {
        CITY: "CALLBACH",
      },
      {
        CITY: "CALLENBERG",
      },
      {
        CITY: "CALVORDE",
      },
      {
        CITY: "CALW",
      },
      {
        CITY: "CAMBS",
      },
      {
        CITY: "CAMIN",
      },
      {
        CITY: "CANNEWITZ",
      },
      {
        CITY: "CAPPEL",
      },
      {
        CITY: "CAPPELN",
      },
      {
        CITY: "CAPUTH",
      },
      {
        CITY: "CARLOW",
      },
      {
        CITY: "CARLSBERG",
      },
      {
        CITY: "CARMZOW",
      },
      {
        CITY: "CAROLINENSIEL",
      },
      {
        CITY: "CASTROP-RAUXEL",
      },
      {
        CITY: "CATTERFELD",
      },
      {
        CITY: "CELLE",
      },
      {
        CITY: "CHAM",
      },
      {
        CITY: "CHAMERAU",
      },
      {
        CITY: "CHEMNITZ",
      },
      {
        CITY: "CHIEMING",
      },
      {
        CITY: "CHIEMSEE",
      },
      {
        CITY: "CHOSTLARN",
      },
      {
        CITY: "CLAUSEN",
      },
      {
        CITY: "CLAUSSNITZ",
      },
      {
        CITY: "CLAUSTHAL",
      },
      {
        CITY: "CLAUSTHAL-ZELLERFELD",
      },
      {
        CITY: "CLEEBRONN",
      },
      {
        CITY: "CLOPPENBURG",
      },
      {
        CITY: "COBURG",
      },
      {
        CITY: "COCHEM",
      },
      {
        CITY: "COESFELD",
      },
      {
        CITY: "COLBE",
      },
      {
        CITY: "COLBITZ",
      },
      {
        CITY: "COLDITZ",
      },
      {
        CITY: "COLMNITZ",
      },
      {
        CITY: "COLNRADE",
      },
      {
        CITY: "COLOGNE",
      },
      {
        CITY: "CONTWIG",
      },
      {
        CITY: "COPPENBRUGGE",
      },
      {
        CITY: "COPPENGRAVE",
      },
      {
        CITY: "CORNBERG",
      },
      {
        CITY: "COSWIG",
      },
      {
        CITY: "COSWIG",
      },
      {
        CITY: "COTTBUS",
      },
      {
        CITY: "CRAILSHEIM",
      },
      {
        CITY: "CRAMONSHAGEN",
      },
      {
        CITY: "CRANZ",
      },
      {
        CITY: "CREMLINGEN",
      },
      {
        CITY: "CREUSSEN",
      },
      {
        CITY: "CREUZBURG",
      },
      {
        CITY: "CRIMMITSCHAU",
      },
      {
        CITY: "CRINITZ",
      },
      {
        CITY: "CRIVITZ",
      },
      {
        CITY: "CROSSEN",
      },
      {
        CITY: "CROSTAU",
      },
      {
        CITY: "CROTTENDORF",
      },
      {
        CITY: "CUNEWALDE",
      },
      {
        CITY: "CUXHAVEN",
      },
      {
        CITY: "DAADEN",
      },
      {
        CITY: "DABEL",
      },
      {
        CITY: "DABELOW",
      },
      {
        CITY: "DABENDORF",
      },
      {
        CITY: "DABERGOTZ",
      },
      {
        CITY: "DACHAU",
      },
      {
        CITY: "DACHWIG",
      },
      {
        CITY: "DACKSCHEID",
      },
      {
        CITY: "DAGERSHEIM",
      },
      {
        CITY: "DAHLEM",
      },
      {
        CITY: "DAHLEN",
      },
      {
        CITY: "DAHLENBURG",
      },
      {
        CITY: "DAHLENWARSLEBEN",
      },
      {
        CITY: "DAHLEWITZ",
      },
      {
        CITY: "DAHME",
      },
      {
        CITY: "DAHME",
      },
      {
        CITY: "DAHN",
      },
      {
        CITY: "DAHNSDORF",
      },
      {
        CITY: "DALLGOW-DOEBERITZ",
      },
      {
        CITY: "DALUM",
      },
      {
        CITY: "DAMBECK",
      },
      {
        CITY: "DAMLOS",
      },
      {
        CITY: "DAMME",
      },
      {
        CITY: "DAMP",
      },
      {
        CITY: "DANISCHENHAGEN",
      },
      {
        CITY: "DANKENFELD",
      },
      {
        CITY: "DANKMARSHAUSEN",
      },
      {
        CITY: "DANNDORF",
      },
      {
        CITY: "DANNENBERG",
      },
      {
        CITY: "DANNSTADT-SCHAUERNHEIM",
      },
      {
        CITY: "DARDESHEIM",
      },
      {
        CITY: "DARMSTADT",
      },
      {
        CITY: "DARSTEIN",
      },
      {
        CITY: "DASING",
      },
      {
        CITY: "DASSEL",
      },
      {
        CITY: "DASSENDORF",
      },
      {
        CITY: "DASSOW",
      },
      {
        CITY: "DATTELN",
      },
      {
        CITY: "DATTENBERG",
      },
      {
        CITY: "DAUBORN",
      },
      {
        CITY: "DAUCHINGEN",
      },
      {
        CITY: "DAUN",
      },
      {
        CITY: "DAUSENAU",
      },
      {
        CITY: "DECKENPFRONN",
      },
      {
        CITY: "DEDELEBEN",
      },
      {
        CITY: "DEDELSTORF",
      },
      {
        CITY: "DEDERSTEDT",
      },
      {
        CITY: "DEGERLOCH",
      },
      {
        CITY: "DEGGENDORF",
      },
      {
        CITY: "DEGGENHAUSEN",
      },
      {
        CITY: "DEGGINGEN",
      },
      {
        CITY: "DEIDESHEIM",
      },
      {
        CITY: "DEILINGEN",
      },
      {
        CITY: "DEINING",
      },
      {
        CITY: "DEININGEN",
      },
      {
        CITY: "DEINSTE",
      },
      {
        CITY: "DEISENHOFEN",
      },
      {
        CITY: "DEISSLINGEN",
      },
      {
        CITY: "DEIZISAU",
      },
      {
        CITY: "DELBRUCK",
      },
      {
        CITY: "DELINGSDORF",
      },
      {
        CITY: "DELITZSCH",
      },
      {
        CITY: "DELLFELD",
      },
      {
        CITY: "DELLIGSEN",
      },
      {
        CITY: "DELLMENSINGEN",
      },
      {
        CITY: "DELMENHORST",
      },
      {
        CITY: "DELVE",
      },
      {
        CITY: "DEMITZ-THUMITZ",
      },
      {
        CITY: "DEMMIN",
      },
      {
        CITY: "DENKENDORF",
      },
      {
        CITY: "DENKENDORF",
      },
      {
        CITY: "DENKINGEN",
      },
      {
        CITY: "DENKLINGEN",
      },
      {
        CITY: "DENSBORN",
      },
      {
        CITY: "DENTLEIN AM FORST",
      },
      {
        CITY: "DENZLINGEN",
      },
      {
        CITY: "DERBEN",
      },
      {
        CITY: "DERENBURG",
      },
      {
        CITY: "DERMBACH",
      },
      {
        CITY: "DERNAU",
      },
      {
        CITY: "DERNBACH",
      },
      {
        CITY: "DERSCHEN",
      },
      {
        CITY: "DERSUM",
      },
      {
        CITY: "DESSAU",
      },
      {
        CITY: "DESSOW",
      },
      {
        CITY: "DESTEDT",
      },
      {
        CITY: "DETERN",
      },
      {
        CITY: "DETMOLD",
      },
      {
        CITY: "DETTELBACH",
      },
      {
        CITY: "DETTENHAUSEN",
      },
      {
        CITY: "DETTIGHOFEN",
      },
      {
        CITY: "DETTINGEN AN DER ERMS",
      },
      {
        CITY: "DETTINGEN AN DER ILLER",
      },
      {
        CITY: "DETTINGEN UNTER TECK",
      },
      {
        CITY: "DETTMANNSDORF",
      },
      {
        CITY: "DEUTSCH",
      },
      {
        CITY: "DEUTSCH EVERN",
      },
      {
        CITY: "DEUTSCHES HAUS",
      },
      {
        CITY: "DEUTZ",
      },
      {
        CITY: "DEUTZEN",
      },
      {
        CITY: "DEXHEIM",
      },
      {
        CITY: "DIANA",
      },
      {
        CITY: "DIBBERSEN",
      },
      {
        CITY: "DIEBACH",
      },
      {
        CITY: "DIEBLICH",
      },
      {
        CITY: "DIEBURG",
      },
      {
        CITY: "DIEDERSDORF",
      },
      {
        CITY: "DIEDORF",
      },
      {
        CITY: "DIEDORF",
      },
      {
        CITY: "DIEFFLEN",
      },
      {
        CITY: "DIEKHOLZEN",
      },
      {
        CITY: "DIELHEIM",
      },
      {
        CITY: "DIELINGEN",
      },
      {
        CITY: "DIENHEIM",
      },
      {
        CITY: "DIENSTWEILER",
      },
      {
        CITY: "DIEPENAU",
      },
      {
        CITY: "DIEPERSDORF",
      },
      {
        CITY: "DIEPHOLZ",
      },
      {
        CITY: "DIERDORF",
      },
      {
        CITY: "DIERKOW",
      },
      {
        CITY: "DIESKAU",
      },
      {
        CITY: "DIESSEN AM AMMERSEE",
      },
      {
        CITY: "DIETENHEIM",
      },
      {
        CITY: "DIETENHOFEN",
      },
      {
        CITY: "DIETERSBURG",
      },
      {
        CITY: "DIETERSHEIM",
      },
      {
        CITY: "DIETFURT",
      },
      {
        CITY: "DIETINGEN",
      },
      {
        CITY: "DIETLINGEN",
      },
      {
        CITY: "DIETMANNSRIED",
      },
      {
        CITY: "DIETRAMSZELL",
      },
      {
        CITY: "DIETRICHINGEN",
      },
      {
        CITY: "DIETZENBACH",
      },
      {
        CITY: "DIEZ",
      },
      {
        CITY: "DILLENBURG",
      },
      {
        CITY: "DILLINGEN",
      },
      {
        CITY: "DILLINGEN AN DER DONAU",
      },
      {
        CITY: "DINGELSTADT",
      },
      {
        CITY: "DINGELSTEDT",
      },
      {
        CITY: "DINGOLFING",
      },
      {
        CITY: "DINGOLSHAUSEN",
      },
      {
        CITY: "DINGSTEDE",
      },
      {
        CITY: "DINKELSCHERBEN",
      },
      {
        CITY: "DINKLAGE",
      },
      {
        CITY: "DINSLAKEN",
      },
      {
        CITY: "DINTESHEIM",
      },
      {
        CITY: "DIPBACH",
      },
      {
        CITY: "DIPPACH",
      },
      {
        CITY: "DIPPERZ",
      },
      {
        CITY: "DIPPOLDISWALDE",
      },
      {
        CITY: "DIRLEWANG",
      },
      {
        CITY: "DIRMINGEN",
      },
      {
        CITY: "DIRMSTEIN",
      },
      {
        CITY: "DISCHINGEN",
      },
      {
        CITY: "DISSEN",
      },
      {
        CITY: "DITFURT",
      },
      {
        CITY: "DITTELBRUNN",
      },
      {
        CITY: "DITTELSDORF",
      },
      {
        CITY: "DITTELSHEIM-HESSLOCH",
      },
      {
        CITY: "DITTENHEIM",
      },
      {
        CITY: "DITTWEILER",
      },
      {
        CITY: "DITZINGEN",
      },
      {
        CITY: "DOBEL",
      },
      {
        CITY: "DOBELN",
      },
      {
        CITY: "DOBERLUG-KIRCHHAIN",
      },
      {
        CITY: "DOBERSCHAU",
      },
      {
        CITY: "DOBERSCHUTZ",
      },
      {
        CITY: "DOBITSCHEN",
      },
      {
        CITY: "DOBLER",
      },
      {
        CITY: "DOBRA",
      },
      {
        CITY: "DOCKINGEN",
      },
      {
        CITY: "DOGERN",
      },
      {
        CITY: "DOHLAU",
      },
      {
        CITY: "DOHLE",
      },
      {
        CITY: "DOHNA",
      },
      {
        CITY: "DOHREN",
      },
      {
        CITY: "DOLGESHEIM",
      },
      {
        CITY: "DOLLERN",
      },
      {
        CITY: "DOLLNSTEIN",
      },
      {
        CITY: "DOMBUHL",
      },
      {
        CITY: "DOMITZ",
      },
      {
        CITY: "DOMMITZSCH",
      },
      {
        CITY: "DOMSDORF",
      },
      {
        CITY: "DOMSUHL",
      },
      {
        CITY: "DONAUESCHINGEN",
      },
      {
        CITY: "DONAUSTAUF",
      },
      {
        CITY: "DONNDORF",
      },
      {
        CITY: "DONNERSDORF",
      },
      {
        CITY: "DONSIEDERS",
      },
      {
        CITY: "DONZDORF",
      },
      {
        CITY: "DORENTRUP",
      },
      {
        CITY: "DORF",
      },
      {
        CITY: "DORF DOBERITZ",
      },
      {
        CITY: "DORFEN",
      },
      {
        CITY: "DORFLES",
      },
      {
        CITY: "DORFPROZELTEN",
      },
      {
        CITY: "DORMAGEN",
      },
      {
        CITY: "DORMETTINGEN",
      },
      {
        CITY: "DORNBURG",
      },
      {
        CITY: "DORNDORF",
      },
      {
        CITY: "DORNHAN",
      },
      {
        CITY: "DORNHEIM",
      },
      {
        CITY: "DORNSTADT",
      },
      {
        CITY: "DORNSTETTEN",
      },
      {
        CITY: "DORNUM",
      },
      {
        CITY: "DORPEN",
      },
      {
        CITY: "DORPSTEDT",
      },
      {
        CITY: "DORSTADT",
      },
      {
        CITY: "DORSTEN",
      },
      {
        CITY: "DORTH",
      },
      {
        CITY: "DORTMUND",
      },
      {
        CITY: "DORUM",
      },
      {
        CITY: "DORVERDEN",
      },
      {
        CITY: "DORZBACH",
      },
      {
        CITY: "DOSSENHEIM",
      },
      {
        CITY: "DOTLINGEN",
      },
      {
        CITY: "DOTTERNHAUSEN",
      },
      {
        CITY: "DRACHSELSRIED",
      },
      {
        CITY: "DRAGE",
      },
      {
        CITY: "DRANSFELD",
      },
      {
        CITY: "DRANSKE",
      },
      {
        CITY: "DREBACH",
      },
      {
        CITY: "DREBKAU",
      },
      {
        CITY: "DREETZ",
      },
      {
        CITY: "DREIEICH",
      },
      {
        CITY: "DREIKIRCHEN",
      },
      {
        CITY: "DREIS",
      },
      {
        CITY: "DREISBACH",
      },
      {
        CITY: "DRELSDORF",
      },
      {
        CITY: "DRENSTEINFURT",
      },
      {
        CITY: "DRESDEN",
      },
      {
        CITY: "DRESTEDT",
      },
      {
        CITY: "DREVESKIRCHEN",
      },
      {
        CITY: "DRIEDORF",
      },
      {
        CITY: "DRIFTSETHE",
      },
      {
        CITY: "DROCHTERSEN",
      },
      {
        CITY: "DROLSHAGEN",
      },
      {
        CITY: "DROYSSIG",
      },
      {
        CITY: "DRUBECK",
      },
      {
        CITY: "DUCHELSDORF",
      },
      {
        CITY: "DUCHEROW",
      },
      {
        CITY: "DUCHROTH",
      },
      {
        CITY: "DUDENHOFEN",
      },
      {
        CITY: "DUDERODE",
      },
      {
        CITY: "DUDERSTADT",
      },
      {
        CITY: "DUGGENDORF",
      },
      {
        CITY: "DUINGEN",
      },
      {
        CITY: "DUISBURG",
      },
      {
        CITY: "DULMEN",
      },
      {
        CITY: "DUMMERSTORF",
      },
      {
        CITY: "DUNGENHEIM",
      },
      {
        CITY: "DUNNINGEN",
      },
      {
        CITY: "DUNNWALD",
      },
      {
        CITY: "DUNZWEILER",
      },
      {
        CITY: "DURACH",
      },
      {
        CITY: "DURBACH",
      },
      {
        CITY: "DURBHEIM",
      },
      {
        CITY: "DURCHHAUSEN",
      },
      {
        CITY: "DURLANGEN",
      },
      {
        CITY: "DURMENTINGEN",
      },
      {
        CITY: "DURMERSHEIM",
      },
      {
        CITY: "DURNAU",
      },
      {
        CITY: "DURRBRUNN",
      },
      {
        CITY: "DUSSELDORF",
      },
      {
        CITY: "DUSSLINGEN",
      },
      {
        CITY: "EBELEBEN",
      },
      {
        CITY: "EBELSBACH",
      },
      {
        CITY: "EBENDORF",
      },
      {
        CITY: "EBENSFELD",
      },
      {
        CITY: "EBENWEILER",
      },
      {
        CITY: "EBERBACH",
      },
      {
        CITY: "EBERDINGEN",
      },
      {
        CITY: "EBERHARDZELL",
      },
      {
        CITY: "EBERMANNSDORF",
      },
      {
        CITY: "EBERMANNSTADT",
      },
      {
        CITY: "EBERN",
      },
      {
        CITY: "EBERNHAHN",
      },
      {
        CITY: "EBERSBACH",
      },
      {
        CITY: "EBERSBACH AN DER FILS",
      },
      {
        CITY: "EBERSBERG",
      },
      {
        CITY: "EBERSDORF",
      },
      {
        CITY: "EBERSHAUSEN",
      },
      {
        CITY: "EBERSTADT",
      },
      {
        CITY: "EBERSTADT",
      },
      {
        CITY: "EBERSWALDE",
      },
      {
        CITY: "EBERTSHEIM",
      },
      {
        CITY: "EBHAUSEN",
      },
      {
        CITY: "EBNAT",
      },
      {
        CITY: "EBRACH",
      },
      {
        CITY: "EBRINGEN",
      },
      {
        CITY: "EBSTORF",
      },
      {
        CITY: "ECHING",
      },
      {
        CITY: "ECHTERDINGEN",
      },
      {
        CITY: "ECHZELL",
      },
      {
        CITY: "ECKARTSBERG",
      },
      {
        CITY: "ECKARTSBERGA",
      },
      {
        CITY: "ECKERNFORDE",
      },
      {
        CITY: "ECKERSDORF",
      },
      {
        CITY: "ECKFELD",
      },
      {
        CITY: "ECKLINGERODE",
      },
      {
        CITY: "EDDELAK",
      },
      {
        CITY: "EDELSFELD",
      },
      {
        CITY: "EDEMISSEN",
      },
      {
        CITY: "EDEN",
      },
      {
        CITY: "EDENKOBEN",
      },
      {
        CITY: "EDERHEIM",
      },
      {
        CITY: "EDESHEIM",
      },
      {
        CITY: "EDEWECHT",
      },
      {
        CITY: "EDINGEN-NECKARHAUSEN",
      },
      {
        CITY: "EDLING",
      },
      {
        CITY: "EFFELDER",
      },
      {
        CITY: "EFFELTRICH",
      },
      {
        CITY: "EFRINGEN-KIRCHEN",
      },
      {
        CITY: "EGELN",
      },
      {
        CITY: "EGELSBACH",
      },
      {
        CITY: "EGENHAUSEN",
      },
      {
        CITY: "EGENHAUSEN",
      },
      {
        CITY: "EGENHOFEN",
      },
      {
        CITY: "EGESHEIM",
      },
      {
        CITY: "EGESTORF",
      },
      {
        CITY: "EGG AN DER GUNZ",
      },
      {
        CITY: "EGGEBEK",
      },
      {
        CITY: "EGGENFELDEN",
      },
      {
        CITY: "EGGENSTEIN-LEOPOLDSHAFEN",
      },
      {
        CITY: "EGGENTHAL",
      },
      {
        CITY: "EGGERSDORF",
      },
      {
        CITY: "EGGERSDORF",
      },
      {
        CITY: "EGGESIN",
      },
      {
        CITY: "EGGINGEN",
      },
      {
        CITY: "EGGLHAM",
      },
      {
        CITY: "EGGOLSHEIM",
      },
      {
        CITY: "EGGSTATT",
      },
      {
        CITY: "EGING",
      },
      {
        CITY: "EGLING",
      },
      {
        CITY: "EGLOFFSTEIN",
      },
      {
        CITY: "EGMATING",
      },
      {
        CITY: "EHEKIRCHEN",
      },
      {
        CITY: "EHINGEN",
      },
      {
        CITY: "EHINGEN",
      },
      {
        CITY: "EHINGEN",
      },
      {
        CITY: "EHLSCHEID",
      },
      {
        CITY: "EHNDORF",
      },
      {
        CITY: "EHNINGEN",
      },
      {
        CITY: "EHRENBERG",
      },
      {
        CITY: "EHRENBURG",
      },
      {
        CITY: "EHRENFELD",
      },
      {
        CITY: "EHRENFRIEDERSDORF",
      },
      {
        CITY: "EHRINGSHAUSEN",
      },
      {
        CITY: "EIBAU",
      },
      {
        CITY: "EIBELSHAUSEN",
      },
      {
        CITY: "EIBELSTADT",
      },
      {
        CITY: "EIBENSTOCK",
      },
      {
        CITY: "EIBSEE",
      },
      {
        CITY: "EICH",
      },
      {
        CITY: "EICHELGARTEN",
      },
      {
        CITY: "EICHEN",
      },
      {
        CITY: "EICHEN",
      },
      {
        CITY: "EICHENAU",
      },
      {
        CITY: "EICHENBARLEBEN",
      },
      {
        CITY: "EICHENBERG",
      },
      {
        CITY: "EICHENBUHL",
      },
      {
        CITY: "EICHENDORF",
      },
      {
        CITY: "EICHENZELL",
      },
      {
        CITY: "EICHERLOH",
      },
      {
        CITY: "EICHSTAETT",
      },
      {
        CITY: "EICHSTEDT",
      },
      {
        CITY: "EICHSTETTEN",
      },
      {
        CITY: "EICHTERSHEIM",
      },
      {
        CITY: "EICHWALDE",
      },
      {
        CITY: "EICKLINGEN",
      },
      {
        CITY: "EIGELTINGEN",
      },
      {
        CITY: "EILENBURG",
      },
      {
        CITY: "EILENBURG BERG",
      },
      {
        CITY: "EILSLEBEN",
      },
      {
        CITY: "EIME",
      },
      {
        CITY: "EIMELDINGEN",
      },
      {
        CITY: "EIMSHEIM",
      },
      {
        CITY: "EINBECK",
      },
      {
        CITY: "EINHAUS",
      },
      {
        CITY: "EINHAUSEN",
      },
      {
        CITY: "EINHAUSEN",
      },
      {
        CITY: "EINSBACH",
      },
      {
        CITY: "EINSELTHUM",
      },
      {
        CITY: "EISELFING",
      },
      {
        CITY: "EISENACH",
      },
      {
        CITY: "EISENBACH",
      },
      {
        CITY: "EISENBACH",
      },
      {
        CITY: "EISENBACH",
      },
      {
        CITY: "EISENBERG",
      },
      {
        CITY: "EISENBERG",
      },
      {
        CITY: "EISENBERG",
      },
      {
        CITY: "EISFELD",
      },
      {
        CITY: "EISINGEN",
      },
      {
        CITY: "EISINGEN",
      },
      {
        CITY: "EISLINGEN",
      },
      {
        CITY: "EITELBORN",
      },
      {
        CITY: "EITENSHEIM",
      },
      {
        CITY: "EITERFELD",
      },
      {
        CITY: "EITORF",
      },
      {
        CITY: "EITTING",
      },
      {
        CITY: "ELBE",
      },
      {
        CITY: "ELBINGERODE",
      },
      {
        CITY: "ELDENA",
      },
      {
        CITY: "ELDINGEN",
      },
      {
        CITY: "ELEND",
      },
      {
        CITY: "ELFERSHAUSEN",
      },
      {
        CITY: "ELGERSBURG",
      },
      {
        CITY: "ELISABETH-SOPHIEN-KOOG",
      },
      {
        CITY: "ELKENROTH",
      },
      {
        CITY: "ELLEFELD",
      },
      {
        CITY: "ELLENBERG",
      },
      {
        CITY: "ELLERAU",
      },
      {
        CITY: "ELLERBEK",
      },
      {
        CITY: "ELLERDORF",
      },
      {
        CITY: "ELLERHOOP",
      },
      {
        CITY: "ELLERSTADT",
      },
      {
        CITY: "ELLGAU",
      },
      {
        CITY: "ELLHOFEN",
      },
      {
        CITY: "ELLINGEN",
      },
      {
        CITY: "ELLRICH",
      },
      {
        CITY: "ELLWANGEN",
      },
      {
        CITY: "ELLZEE",
      },
      {
        CITY: "ELM",
      },
      {
        CITY: "ELMENHORST",
      },
      {
        CITY: "ELMLOHE",
      },
      {
        CITY: "ELMPT",
      },
      {
        CITY: "ELMSHORN",
      },
      {
        CITY: "ELMSTEIN",
      },
      {
        CITY: "ELSDORF",
      },
      {
        CITY: "ELSDORF-WESTERMUHLEN",
      },
      {
        CITY: "ELSENDORF",
      },
      {
        CITY: "ELSENFELD",
      },
      {
        CITY: "ELSFLETH",
      },
      {
        CITY: "ELSTER",
      },
      {
        CITY: "ELSTERBERG",
      },
      {
        CITY: "ELSTERWERDA",
      },
      {
        CITY: "ELSTORF",
      },
      {
        CITY: "ELSTRA",
      },
      {
        CITY: "ELTERLEIN",
      },
      {
        CITY: "ELTMANN",
      },
      {
        CITY: "ELTVILLE",
      },
      {
        CITY: "ELXLEBEN",
      },
      {
        CITY: "ELZ",
      },
      {
        CITY: "ELZACH",
      },
      {
        CITY: "ELZE",
      },
      {
        CITY: "EMBSEN",
      },
      {
        CITY: "EMDEN",
      },
      {
        CITY: "EMERKINGEN",
      },
      {
        CITY: "EMLICHHEIM",
      },
      {
        CITY: "EMMELSHAUSEN",
      },
      {
        CITY: "EMMENDORF",
      },
      {
        CITY: "EMMERICH",
      },
      {
        CITY: "EMMERING",
      },
      {
        CITY: "EMMERING",
      },
      {
        CITY: "EMMERZHAUSEN",
      },
      {
        CITY: "EMMINGEN-LIPTINGEN",
      },
      {
        CITY: "EMPFERTSHAUSEN",
      },
      {
        CITY: "EMPFINGEN",
      },
      {
        CITY: "EMSBUREN",
      },
      {
        CITY: "EMSDETTEN",
      },
      {
        CITY: "EMSKIRCHEN",
      },
      {
        CITY: "EMSTAL",
      },
      {
        CITY: "EMSTEK",
      },
      {
        CITY: "EMTINGHAUSEN",
      },
      {
        CITY: "EMTMANNSBERG",
      },
      {
        CITY: "ENDINGEN",
      },
      {
        CITY: "ENGDEN",
      },
      {
        CITY: "ENGELSBERG",
      },
      {
        CITY: "ENGELSBRAND",
      },
      {
        CITY: "ENGELSBURG",
      },
      {
        CITY: "ENGELSHOF",
      },
      {
        CITY: "ENGELSKIRCHEN",
      },
      {
        CITY: "ENGELSTADT",
      },
      {
        CITY: "ENGELTHAL",
      },
      {
        CITY: "ENGEN",
      },
      {
        CITY: "ENGER",
      },
      {
        CITY: "ENINGEN UNTER ACHALM",
      },
      {
        CITY: "ENKENBACH-ALSENBORN",
      },
      {
        CITY: "ENKIRCH",
      },
      {
        CITY: "ENNEPETAL",
      },
      {
        CITY: "ENNIGERLOH",
      },
      {
        CITY: "ENSDORF",
      },
      {
        CITY: "ENSDORF",
      },
      {
        CITY: "ENSEN",
      },
      {
        CITY: "ENTRINGEN",
      },
      {
        CITY: "EPFENBACH",
      },
      {
        CITY: "EPFENDORF",
      },
      {
        CITY: "EPPELBORN",
      },
      {
        CITY: "EPPELHEIM",
      },
      {
        CITY: "EPPELSHEIM",
      },
      {
        CITY: "EPPENBRUNN",
      },
      {
        CITY: "EPPENDORF",
      },
      {
        CITY: "EPPENROD",
      },
      {
        CITY: "EPPERTSHAUSEN",
      },
      {
        CITY: "EPPINGEN",
      },
      {
        CITY: "EPPISHAUSEN",
      },
      {
        CITY: "EPPSTEIN",
      },
      {
        CITY: "ERBACH",
      },
      {
        CITY: "ERBACH IM ODENWALD",
      },
      {
        CITY: "ERBENDORF",
      },
      {
        CITY: "ERBES-BUDESHEIM",
      },
      {
        CITY: "ERDA",
      },
      {
        CITY: "ERDEBORN",
      },
      {
        CITY: "ERDING",
      },
      {
        CITY: "ERDMANNHAUSEN",
      },
      {
        CITY: "ERDWEG",
      },
      {
        CITY: "ERESING",
      },
      {
        CITY: "ERFDE",
      },
      {
        CITY: "ERFELDEN",
      },
      {
        CITY: "ERFTSTADT",
      },
      {
        CITY: "ERFURT",
      },
      {
        CITY: "ERGERSHEIM",
      },
      {
        CITY: "ERGOLDING",
      },
      {
        CITY: "ERGOLDSBACH",
      },
      {
        CITY: "ERIKA",
      },
      {
        CITY: "ERING",
      },
      {
        CITY: "ERISKIRCH",
      },
      {
        CITY: "ERKELENZ",
      },
      {
        CITY: "ERKHEIM",
      },
      {
        CITY: "ERKNER",
      },
      {
        CITY: "ERKRATH",
      },
      {
        CITY: "ERLABRUNN",
      },
      {
        CITY: "ERLACH",
      },
      {
        CITY: "ERLANGEN",
      },
      {
        CITY: "ERLAU",
      },
      {
        CITY: "ERLBACH",
      },
      {
        CITY: "ERLENBACH",
      },
      {
        CITY: "ERLENBACH AM MAIN",
      },
      {
        CITY: "ERLENBACH BEI MARKTHEIDENFELD",
      },
      {
        CITY: "ERLENMOOS",
      },
      {
        CITY: "ERLENSEE",
      },
      {
        CITY: "ERLIGHEIM",
      },
      {
        CITY: "ERMERSHAUSEN",
      },
      {
        CITY: "ERMKE",
      },
      {
        CITY: "ERNDTEBRUCK",
      },
      {
        CITY: "ERNSGADEN",
      },
      {
        CITY: "ERNST",
      },
      {
        CITY: "ERNSTHOF",
      },
      {
        CITY: "ERNSTRODA",
      },
      {
        CITY: "ERNSTTHAL",
      },
      {
        CITY: "EROLZHEIM",
      },
      {
        CITY: "ERPEL",
      },
      {
        CITY: "ERPOLZHEIM",
      },
      {
        CITY: "ERSINGEN",
      },
      {
        CITY: "ERTINGEN",
      },
      {
        CITY: "ERWITTE",
      },
      {
        CITY: "ERZENHAUSEN",
      },
      {
        CITY: "ERZHAUSEN",
      },
      {
        CITY: "ERZINGEN",
      },
      {
        CITY: "ESCHACH",
      },
      {
        CITY: "ESCHAU",
      },
      {
        CITY: "ESCHBACH",
      },
      {
        CITY: "ESCHBORN",
      },
      {
        CITY: "ESCHEBURG",
      },
      {
        CITY: "ESCHEDE",
      },
      {
        CITY: "ESCHELBRONN",
      },
      {
        CITY: "ESCHENBACH",
      },
      {
        CITY: "ESCHENBACH",
      },
      {
        CITY: "ESCHENBACH",
      },
      {
        CITY: "ESCHENLOHE",
      },
      {
        CITY: "ESCHERSHAUSEN",
      },
      {
        CITY: "ESCHFELD",
      },
      {
        CITY: "ESCHLKAM",
      },
      {
        CITY: "ESCHWEGE",
      },
      {
        CITY: "ESCHWEILER",
      },
      {
        CITY: "ESENS",
      },
      {
        CITY: "ESGRUS",
      },
      {
        CITY: "ESLARN",
      },
      {
        CITY: "ESLOHE",
      },
      {
        CITY: "ESPELKAMP",
      },
      {
        CITY: "ESPELKAMP-MITTWALD",
      },
      {
        CITY: "ESPENAU",
      },
      {
        CITY: "ESPENHAIN",
      },
      {
        CITY: "ESSEL",
      },
      {
        CITY: "ESSELBORN",
      },
      {
        CITY: "ESSEN",
      },
      {
        CITY: "ESSEN",
      },
      {
        CITY: "ESSENBACH",
      },
      {
        CITY: "ESSENHEIM",
      },
      {
        CITY: "ESSINGEN",
      },
      {
        CITY: "ESSLINGEN AM NECKAR",
      },
      {
        CITY: "ESTENFELD",
      },
      {
        CITY: "ESTERWEGEN",
      },
      {
        CITY: "ESTHAL",
      },
      {
        CITY: "ESTORF",
      },
      {
        CITY: "ETSCHBERG",
      },
      {
        CITY: "ETTELN",
      },
      {
        CITY: "ETTENHEIM",
      },
      {
        CITY: "ETTENSTATT",
      },
      {
        CITY: "ETTERSBURG",
      },
      {
        CITY: "ETTERSCHLAG",
      },
      {
        CITY: "ETTLEBEN",
      },
      {
        CITY: "ETTLINGEN",
      },
      {
        CITY: "ETTRINGEN",
      },
      {
        CITY: "ETZELWANG",
      },
      {
        CITY: "ETZENRICHT",
      },
      {
        CITY: "EUERBACH",
      },
      {
        CITY: "EUERDORF",
      },
      {
        CITY: "EURASBURG",
      },
      {
        CITY: "EUSKIRCHEN",
      },
      {
        CITY: "EUSSENHEIM",
      },
      {
        CITY: "EUTIN",
      },
      {
        CITY: "EUTINGEN",
      },
      {
        CITY: "EVERODE",
      },
      {
        CITY: "EVERSMEER",
      },
      {
        CITY: "EVERSWINKEL",
      },
      {
        CITY: "EVESSEN",
      },
      {
        CITY: "EWERSBACH",
      },
      {
        CITY: "EYDELSTEDT",
      },
      {
        CITY: "EYENDORF",
      },
      {
        CITY: "EYSTRUP",
      },
      {
        CITY: "FACHBACH",
      },
      {
        CITY: "FAHRDORF",
      },
      {
        CITY: "FAHRENBACH",
      },
      {
        CITY: "FAHRENBACH",
      },
      {
        CITY: "FAHRENDORF",
      },
      {
        CITY: "FAHRENKRUG",
      },
      {
        CITY: "FAHRENZHAUSEN",
      },
      {
        CITY: "FAHRLAND",
      },
      {
        CITY: "FAID",
      },
      {
        CITY: "FALKENBERG",
      },
      {
        CITY: "FALKENBERG",
      },
      {
        CITY: "FALKENDORF",
      },
      {
        CITY: "FALKENFELS",
      },
      {
        CITY: "FALKENSEE",
      },
      {
        CITY: "FALKENSTEIN",
      },
      {
        CITY: "FALLERSLEBEN",
      },
      {
        CITY: "FALLS",
      },
      {
        CITY: "FAMBACH",
      },
      {
        CITY: "FARCHANT",
      },
      {
        CITY: "FARGAU-PRATJAU",
      },
      {
        CITY: "FARNRODA",
      },
      {
        CITY: "FARNSTADT",
      },
      {
        CITY: "FASSBERG",
      },
      {
        CITY: "FATSCHENBRUNN",
      },
      {
        CITY: "FAULBACH",
      },
      {
        CITY: "FEHL-RITZHAUSEN",
      },
      {
        CITY: "FEHMARN",
      },
      {
        CITY: "FEHRBELLIN",
      },
      {
        CITY: "FEILBINGERT",
      },
      {
        CITY: "FEILITZSCH",
      },
      {
        CITY: "FELDAFING",
      },
      {
        CITY: "FELDBERG",
      },
      {
        CITY: "FELDBERG-ORT",
      },
      {
        CITY: "FELDE",
      },
      {
        CITY: "FELDKIRCHEN",
      },
      {
        CITY: "FELDKIRCHEN",
      },
      {
        CITY: "FELDKIRCHEN-WESTERHAM",
      },
      {
        CITY: "FELDRENNACH",
      },
      {
        CITY: "FELL",
      },
      {
        CITY: "FELLBACH",
      },
      {
        CITY: "FELM",
      },
      {
        CITY: "FELSBERG",
      },
      {
        CITY: "FERCHESAR",
      },
      {
        CITY: "FERNTHAL",
      },
      {
        CITY: "FESTENBURG",
      },
      {
        CITY: "FEUCHT",
      },
      {
        CITY: "FEUCHTWANGEN",
      },
      {
        CITY: "FEUSDORF",
      },
      {
        CITY: "FICHTELBERG",
      },
      {
        CITY: "FICHTENBERG",
      },
      {
        CITY: "FICHTENBERG",
      },
      {
        CITY: "FICHTENWALDE",
      },
      {
        CITY: "FILDERSTADT",
      },
      {
        CITY: "FILSEN",
      },
      {
        CITY: "FILSUM",
      },
      {
        CITY: "FILZEN",
      },
      {
        CITY: "FINCKEN",
      },
      {
        CITY: "FINKENBACH",
      },
      {
        CITY: "FINNENTROP",
      },
      {
        CITY: "FINNINGEN",
      },
      {
        CITY: "FINOWFURT",
      },
      {
        CITY: "FINSING",
      },
      {
        CITY: "FINSTERWALDE",
      },
      {
        CITY: "FINTEL",
      },
      {
        CITY: "FIRREL",
      },
      {
        CITY: "FISCHACH",
      },
      {
        CITY: "FISCHBACH",
      },
      {
        CITY: "FISCHBACH",
      },
      {
        CITY: "FISCHBACHAU",
      },
      {
        CITY: "FISCHEN",
      },
      {
        CITY: "FISCHERBACH",
      },
      {
        CITY: "FISCHERHUDE",
      },
      {
        CITY: "FISCHINGEN",
      },
      {
        CITY: "FLACHSLANDEN",
      },
      {
        CITY: "FLACHT",
      },
      {
        CITY: "FLAMMERSFELD",
      },
      {
        CITY: "FLECHTINGEN",
      },
      {
        CITY: "FLECKEBY",
      },
      {
        CITY: "FLECKEN ZECHLIN",
      },
      {
        CITY: "FLEIN",
      },
      {
        CITY: "FLEISBACH",
      },
      {
        CITY: "FLENSBURG",
      },
      {
        CITY: "FLIEDEN",
      },
      {
        CITY: "FLINSBACH",
      },
      {
        CITY: "FLINTBEK",
      },
      {
        CITY: "FLINTSBACH",
      },
      {
        CITY: "FLOHA",
      },
      {
        CITY: "FLOMBORN",
      },
      {
        CITY: "FLONHEIM",
      },
      {
        CITY: "FLORSHEIM",
      },
      {
        CITY: "FLORSTADT",
      },
      {
        CITY: "FLOSS",
      },
      {
        CITY: "FLOTHE",
      },
      {
        CITY: "FLURSTEDT",
      },
      {
        CITY: "FOCKBEK",
      },
      {
        CITY: "FOHREN",
      },
      {
        CITY: "FOHREN-LINDEN",
      },
      {
        CITY: "FORBACH",
      },
      {
        CITY: "FORCHHEIM",
      },
      {
        CITY: "FORCHTENBERG",
      },
      {
        CITY: "FORHEIM",
      },
      {
        CITY: "FORST",
      },
      {
        CITY: "FORST",
      },
      {
        CITY: "FORST",
      },
      {
        CITY: "FORSTERN",
      },
      {
        CITY: "FORSTHAUS",
      },
      {
        CITY: "FORSTINNING",
      },
      {
        CITY: "FRAMMERSBACH",
      },
      {
        CITY: "FRANKENAU",
      },
      {
        CITY: "FRANKENBERG",
      },
      {
        CITY: "FRANKENBERG",
      },
      {
        CITY: "FRANKENHEIM",
      },
      {
        CITY: "FRANKENSTEIN",
      },
      {
        CITY: "FRANKENTHAL",
      },
      {
        CITY: "FRANKENWINHEIM",
      },
      {
        CITY: "FRANKFURT (ODER)",
      },
      {
        CITY: "FRANKFURT AM MAIN",
      },
      {
        CITY: "FRANKISCH-CRUMBACH",
      },
      {
        CITY: "FRANKWEILER",
      },
      {
        CITY: "FRANZENHEIM",
      },
      {
        CITY: "FRASDORF",
      },
      {
        CITY: "FRAUENAU",
      },
      {
        CITY: "FRAUENDORF",
      },
      {
        CITY: "FRAUENSTEIN",
      },
      {
        CITY: "FRAUNBERG",
      },
      {
        CITY: "FRAUREUTH",
      },
      {
        CITY: "FRECHEN",
      },
      {
        CITY: "FRECKENFELD",
      },
      {
        CITY: "FRECKENHORST",
      },
      {
        CITY: "FREDELSLOH",
      },
      {
        CITY: "FREDEN",
      },
      {
        CITY: "FREDERSDORF",
      },
      {
        CITY: "FREIBERG",
      },
      {
        CITY: "FREIBERG AM NECKAR",
      },
      {
        CITY: "FREIBURG",
      },
      {
        CITY: "FREIBURG",
      },
      {
        CITY: "FREIHUNG",
      },
      {
        CITY: "FREILASSING",
      },
      {
        CITY: "FREIMERSHEIM",
      },
      {
        CITY: "FREINSHEIM",
      },
      {
        CITY: "FREIRACHDORF",
      },
      {
        CITY: "FREISBACH",
      },
      {
        CITY: "FREISEN",
      },
      {
        CITY: "FREISING",
      },
      {
        CITY: "FREITAL",
      },
      {
        CITY: "FREIWALDE",
      },
      {
        CITY: "FREMDINGEN",
      },
      {
        CITY: "FRENSDORF",
      },
      {
        CITY: "FREREN",
      },
      {
        CITY: "FRESENBURG",
      },
      {
        CITY: "FREUDENBERG",
      },
      {
        CITY: "FREUDENBERG",
      },
      {
        CITY: "FREUDENBERG",
      },
      {
        CITY: "FREUDENBERG",
      },
      {
        CITY: "FREUDENBURG",
      },
      {
        CITY: "FREUDENSTADT",
      },
      {
        CITY: "FREUDENTAL",
      },
      {
        CITY: "FREUDENWEILER",
      },
      {
        CITY: "FREUND",
      },
      {
        CITY: "FREYBURG",
      },
      {
        CITY: "FREYSTADT",
      },
      {
        CITY: "FREYUNG",
      },
      {
        CITY: "FRICKENHAUSEN",
      },
      {
        CITY: "FRICKINGEN",
      },
      {
        CITY: "FRIDINGEN AN DER DONAU",
      },
      {
        CITY: "FRIDOLFING",
      },
      {
        CITY: "FRIEDBERG",
      },
      {
        CITY: "FRIEDBERG",
      },
      {
        CITY: "FRIEDEBURG",
      },
      {
        CITY: "FRIEDELSHEIM",
      },
      {
        CITY: "FRIEDENSAU",
      },
      {
        CITY: "FRIEDENSDORF",
      },
      {
        CITY: "FRIEDENWEILER",
      },
      {
        CITY: "FRIEDERSDORF",
      },
      {
        CITY: "FRIEDEWALD",
      },
      {
        CITY: "FRIEDLAND",
      },
      {
        CITY: "FRIEDLAND",
      },
      {
        CITY: "FRIEDRICHRODA",
      },
      {
        CITY: "FRIEDRICHSDORF",
      },
      {
        CITY: "FRIEDRICHSHAFEN",
      },
      {
        CITY: "FRIEDRICHSHOLM",
      },
      {
        CITY: "FRIEDRICHSKOOG",
      },
      {
        CITY: "FRIEDRICHSORT",
      },
      {
        CITY: "FRIEDRICHSTADT",
      },
      {
        CITY: "FRIEDRICHSTHAL",
      },
      {
        CITY: "FRIEDRICHSWERTH",
      },
      {
        CITY: "FRIELENDORF",
      },
      {
        CITY: "FRIEMAR",
      },
      {
        CITY: "FRIESACK",
      },
      {
        CITY: "FRIESENHAGEN",
      },
      {
        CITY: "FRIESENHEIM",
      },
      {
        CITY: "FRIESENHEIM",
      },
      {
        CITY: "FRIESENRIED",
      },
      {
        CITY: "FRIESOYTHE",
      },
      {
        CITY: "FRIOLZHEIM",
      },
      {
        CITY: "FRITTLINGEN",
      },
      {
        CITY: "FRITZLAR",
      },
      {
        CITY: "FROHBURG",
      },
      {
        CITY: "FROMMERN",
      },
      {
        CITY: "FRONDENBERG",
      },
      {
        CITY: "FRONHAUSEN",
      },
      {
        CITY: "FRONHOFEN",
      },
      {
        CITY: "FRONTENHAUSEN",
      },
      {
        CITY: "FROSE",
      },
      {
        CITY: "FUCHSHAIN",
      },
      {
        CITY: "FUCHSSTADT",
      },
      {
        CITY: "FULDA",
      },
      {
        CITY: "FULDABRUCK",
      },
      {
        CITY: "FULDATAL",
      },
      {
        CITY: "FUNFEICHEN",
      },
      {
        CITY: "FUNFSTETTEN",
      },
      {
        CITY: "FURHOLZEN",
      },
      {
        CITY: "FURSTENAU",
      },
      {
        CITY: "FURSTENBERG",
      },
      {
        CITY: "FURSTENBERG",
      },
      {
        CITY: "FURSTENECK",
      },
      {
        CITY: "FURSTENSTEIN",
      },
      {
        CITY: "FURSTENWALD",
      },
      {
        CITY: "FURSTENWALDE",
      },
      {
        CITY: "FURSTENZELL",
      },
      {
        CITY: "FURTH",
      },
      {
        CITY: "FURTH IM WALD",
      },
      {
        CITY: "FURTHEN",
      },
      {
        CITY: "FURTWANGEN IM SCHWARZWALD",
      },
      {
        CITY: "FUSTERNBERG",
      },
      {
        CITY: "GABLENZ",
      },
      {
        CITY: "GABLINGEN",
      },
      {
        CITY: "GACHENBACH",
      },
      {
        CITY: "GADEBUSCH",
      },
      {
        CITY: "GADEGAST",
      },
      {
        CITY: "GAGERN",
      },
      {
        CITY: "GAGGENAU",
      },
      {
        CITY: "GAHLKOW",
      },
      {
        CITY: "GAIBERG",
      },
      {
        CITY: "GAIENHOFEN",
      },
      {
        CITY: "GAILDORF",
      },
      {
        CITY: "GAILINGEN",
      },
      {
        CITY: "GAIMERSHEIM",
      },
      {
        CITY: "GAISHOFEN",
      },
      {
        CITY: "GAISSACH",
      },
      {
        CITY: "GALENBECK",
      },
      {
        CITY: "GAMMELLUND",
      },
      {
        CITY: "GAMMELSDORF",
      },
      {
        CITY: "GAMMELSHAUSEN",
      },
      {
        CITY: "GAMMERTINGEN",
      },
      {
        CITY: "GANDERKESEE",
      },
      {
        CITY: "GANGELT",
      },
      {
        CITY: "GANGKOFEN",
      },
      {
        CITY: "GANGLOFF",
      },
      {
        CITY: "GARBEK",
      },
      {
        CITY: "GARBSEN",
      },
      {
        CITY: "GARCHING AN DER ALZ",
      },
      {
        CITY: "GARCHING BEI MUNCHEN",
      },
      {
        CITY: "GARDELEGEN",
      },
      {
        CITY: "GARDEN",
      },
      {
        CITY: "GARLSTORF",
      },
      {
        CITY: "GARMISCH-PARTENKIRCHEN",
      },
      {
        CITY: "GARREL",
      },
      {
        CITY: "GARS",
      },
      {
        CITY: "GARTOW",
      },
      {
        CITY: "GARTRINGEN",
      },
      {
        CITY: "GARZ",
      },
      {
        CITY: "GARZ",
      },
      {
        CITY: "GATERSLEBEN",
      },
      {
        CITY: "GATTENDORF",
      },
      {
        CITY: "GAU-ALGESHEIM",
      },
      {
        CITY: "GAU-BICKELHEIM",
      },
      {
        CITY: "GAU-BISCHOFSHEIM",
      },
      {
        CITY: "GAU-ODERNHEIM",
      },
      {
        CITY: "GAUKONIGSHOFEN",
      },
      {
        CITY: "GAUTING",
      },
      {
        CITY: "GEBENBACH",
      },
      {
        CITY: "GEBESEE",
      },
      {
        CITY: "GEBHARDSHAIN",
      },
      {
        CITY: "GECHINGEN",
      },
      {
        CITY: "GEDERN",
      },
      {
        CITY: "GEESTE",
      },
      {
        CITY: "GEESTHACHT",
      },
      {
        CITY: "GEFELL",
      },
      {
        CITY: "GEFREES",
      },
      {
        CITY: "GEHAUS",
      },
      {
        CITY: "GEHLENBECK",
      },
      {
        CITY: "GEHOFEN",
      },
      {
        CITY: "GEHRDE",
      },
      {
        CITY: "GEHRDEN",
      },
      {
        CITY: "GEHRWEILER",
      },
      {
        CITY: "GEIERSTHAL",
      },
      {
        CITY: "GEILENKIRCHEN",
      },
      {
        CITY: "GEINSHEIM",
      },
      {
        CITY: "GEINSHEIM",
      },
      {
        CITY: "GEISELBACH",
      },
      {
        CITY: "GEISELHORING",
      },
      {
        CITY: "GEISELWIND",
      },
      {
        CITY: "GEISENFELD",
      },
      {
        CITY: "GEISENHAIN",
      },
      {
        CITY: "GEISENHAUSEN",
      },
      {
        CITY: "GEISENHEIM",
      },
      {
        CITY: "GEISFELD",
      },
      {
        CITY: "GEISIG",
      },
      {
        CITY: "GEISING",
      },
      {
        CITY: "GEISINGEN",
      },
      {
        CITY: "GEISLING",
      },
      {
        CITY: "GEISLINGEN",
      },
      {
        CITY: "GEISLINGEN AN DER STEIGE",
      },
      {
        CITY: "GEITHAIN",
      },
      {
        CITY: "GELBENSANDE",
      },
      {
        CITY: "GELDERN",
      },
      {
        CITY: "GELDERSHEIM",
      },
      {
        CITY: "GELENAU",
      },
      {
        CITY: "GELNHAUSEN",
      },
      {
        CITY: "GELSENKIRCHEN",
      },
      {
        CITY: "GELTENDORF",
      },
      {
        CITY: "GELTING",
      },
      {
        CITY: "GEMMINGEN",
      },
      {
        CITY: "GEMMRIGHEIM",
      },
      {
        CITY: "GEMUNDEN",
      },
      {
        CITY: "GEMUNDEN AN DER WOHRA",
      },
      {
        CITY: "GENDERKINGEN",
      },
      {
        CITY: "GENDORF",
      },
      {
        CITY: "GENGENBACH",
      },
      {
        CITY: "GENKINGEN",
      },
      {
        CITY: "GENSINGEN",
      },
      {
        CITY: "GENTHIN",
      },
      {
        CITY: "GEORGENSGMUND",
      },
      {
        CITY: "GEORGENTHAL",
      },
      {
        CITY: "GEORGSDORF",
      },
      {
        CITY: "GEORGSMARIENHUTTE",
      },
      {
        CITY: "GERA",
      },
      {
        CITY: "GERABERG",
      },
      {
        CITY: "GERABRONN",
      },
      {
        CITY: "GERACH",
      },
      {
        CITY: "GERATSKIRCHEN",
      },
      {
        CITY: "GERAU",
      },
      {
        CITY: "GERBERSHAUSEN",
      },
      {
        CITY: "GERBRUNN",
      },
      {
        CITY: "GERBSTEDT",
      },
      {
        CITY: "GERDAU",
      },
      {
        CITY: "GERETSRIED",
      },
      {
        CITY: "GERHARDSHOFEN",
      },
      {
        CITY: "GERICHSHAIN",
      },
      {
        CITY: "GERINGSWALDE",
      },
      {
        CITY: "GERLACHSHEIM",
      },
      {
        CITY: "GERLFANGEN",
      },
      {
        CITY: "GERLINGEN",
      },
      {
        CITY: "GERMERING",
      },
      {
        CITY: "GERMERSHEIM",
      },
      {
        CITY: "GERNRODE",
      },
      {
        CITY: "GERNRODE",
      },
      {
        CITY: "GERNSBACH",
      },
      {
        CITY: "GERNSHEIM",
      },
      {
        CITY: "GEROLFINGEN",
      },
      {
        CITY: "GEROLSBACH",
      },
      {
        CITY: "GEROLSHEIM",
      },
      {
        CITY: "GEROLSTEIN",
      },
      {
        CITY: "GEROLZHOFEN",
      },
      {
        CITY: "GERSDORF",
      },
      {
        CITY: "GERSFELD",
      },
      {
        CITY: "GERSHASEN",
      },
      {
        CITY: "GERSHEIM",
      },
      {
        CITY: "GERSTETTEN",
      },
      {
        CITY: "GERSTHOFEN",
      },
      {
        CITY: "GERSTUNGEN",
      },
      {
        CITY: "GERWISCH",
      },
      {
        CITY: "GERZEN",
      },
      {
        CITY: "GESCHER",
      },
      {
        CITY: "GESCHWENDA",
      },
      {
        CITY: "GESEKE",
      },
      {
        CITY: "GESSERTSHAUSEN",
      },
      {
        CITY: "GESTUNGSHAUSEN",
      },
      {
        CITY: "GETELO",
      },
      {
        CITY: "GETTORF",
      },
      {
        CITY: "GEVELSBERG",
      },
      {
        CITY: "GEVENSLEBEN",
      },
      {
        CITY: "GEVERSDORF",
      },
      {
        CITY: "GEYER",
      },
      {
        CITY: "GIEBELSTADT",
      },
      {
        CITY: "GIEBOLDEHAUSEN",
      },
      {
        CITY: "GIELDE",
      },
      {
        CITY: "GIELEROTH",
      },
      {
        CITY: "GIENGEN AN DER BRENZ",
      },
      {
        CITY: "GIERSTADT",
      },
      {
        CITY: "GIESEN",
      },
      {
        CITY: "GIESENKIRCHEN",
      },
      {
        CITY: "GIESSEN",
      },
      {
        CITY: "GIFHORN",
      },
      {
        CITY: "GIGGENHAUSEN",
      },
      {
        CITY: "GILCHING",
      },
      {
        CITY: "GILLENFELD",
      },
      {
        CITY: "GILLERSDORF",
      },
      {
        CITY: "GILSERBERG",
      },
      {
        CITY: "GIMBSHEIM",
      },
      {
        CITY: "GINGEN AN DER FILS",
      },
      {
        CITY: "GINGST",
      },
      {
        CITY: "GINSHEIM-GUSTAVSBURG",
      },
      {
        CITY: "GIROD",
      },
      {
        CITY: "GITTELDE",
      },
      {
        CITY: "GLADBACH",
      },
      {
        CITY: "GLADBECK",
      },
      {
        CITY: "GLADENBACH",
      },
      {
        CITY: "GLAN-MUNCHWEILER",
      },
      {
        CITY: "GLANDORF",
      },
      {
        CITY: "GLASEWITZ",
      },
      {
        CITY: "GLASHAGEN",
      },
      {
        CITY: "GLASHUTTE",
      },
      {
        CITY: "GLASHUTTEN",
      },
      {
        CITY: "GLASHUTTEN",
      },
      {
        CITY: "GLATTBACH",
      },
      {
        CITY: "GLATTEN",
      },
      {
        CITY: "GLAUBURG",
      },
      {
        CITY: "GLAUCHAU",
      },
      {
        CITY: "GLEICHEN",
      },
      {
        CITY: "GLEIRITSCH",
      },
      {
        CITY: "GLEISSENBERG",
      },
      {
        CITY: "GLEISWEILER",
      },
      {
        CITY: "GLESCHENDORF",
      },
      {
        CITY: "GLIEN",
      },
      {
        CITY: "GLIENICKE",
      },
      {
        CITY: "GLINDE",
      },
      {
        CITY: "GLINDENBERG",
      },
      {
        CITY: "GLONN",
      },
      {
        CITY: "GLUCKSTADT",
      },
      {
        CITY: "GMAIN BEI WEIDACH",
      },
      {
        CITY: "GMUND AM TEGERNSEE",
      },
      {
        CITY: "GNADAU",
      },
      {
        CITY: "GNARRENBURG",
      },
      {
        CITY: "GNASCHWITZ",
      },
      {
        CITY: "GNOIEN",
      },
      {
        CITY: "GNOTZHEIM",
      },
      {
        CITY: "GNUTZ",
      },
      {
        CITY: "GOCH",
      },
      {
        CITY: "GOCHSHEIM",
      },
      {
        CITY: "GODA",
      },
      {
        CITY: "GOGGINGEN",
      },
      {
        CITY: "GOHRDE",
      },
      {
        CITY: "GOHREN",
      },
      {
        CITY: "GOLDBACH",
      },
      {
        CITY: "GOLDBERG",
      },
      {
        CITY: "GOLDELUND",
      },
      {
        CITY: "GOLDENSTEDT",
      },
      {
        CITY: "GOLDEWIN",
      },
      {
        CITY: "GOLDKRONACH",
      },
      {
        CITY: "GOLLHEIM",
      },
      {
        CITY: "GOLLHOFEN",
      },
      {
        CITY: "GOLM",
      },
      {
        CITY: "GOLMBACH",
      },
      {
        CITY: "GOLSSEN",
      },
      {
        CITY: "GOLZOW",
      },
      {
        CITY: "GOMADINGEN",
      },
      {
        CITY: "GOMARINGEN",
      },
      {
        CITY: "GOMMERN",
      },
      {
        CITY: "GOMMERSHEIM",
      },
      {
        CITY: "GONDELSHEIM",
      },
      {
        CITY: "GONDENBRETT",
      },
      {
        CITY: "GONNHEIM",
      },
      {
        CITY: "GORENZEN",
      },
      {
        CITY: "GORITZ",
      },
      {
        CITY: "GORKWITZ",
      },
      {
        CITY: "GORNSDORF",
      },
      {
        CITY: "GORSCHENDORF",
      },
      {
        CITY: "GORWIHL",
      },
      {
        CITY: "GOSECK",
      },
      {
        CITY: "GOSHEIM",
      },
      {
        CITY: "GOSLAR",
      },
      {
        CITY: "GOSSEL",
      },
      {
        CITY: "GOSSEN",
      },
      {
        CITY: "GOSSERSWEILER-STEIN",
      },
      {
        CITY: "GOSSNITZ",
      },
      {
        CITY: "GOSSWEINSTEIN",
      },
      {
        CITY: "GOTHA",
      },
      {
        CITY: "GOTTESZELL",
      },
      {
        CITY: "GOTTFRIEDING",
      },
      {
        CITY: "GOTTMADINGEN",
      },
      {
        CITY: "GRAACH",
      },
      {
        CITY: "GRABAU",
      },
      {
        CITY: "GRABEN",
      },
      {
        CITY: "GRABEN-NEUDORF",
      },
      {
        CITY: "GRABENSTATT",
      },
      {
        CITY: "GRABENSTETTEN",
      },
      {
        CITY: "GRABOW",
      },
      {
        CITY: "GRAFELFING",
      },
      {
        CITY: "GRAFENAU",
      },
      {
        CITY: "GRAFENBERG",
      },
      {
        CITY: "GRAFENGEHAIG",
      },
      {
        CITY: "GRAFENHAINICHEN",
      },
      {
        CITY: "GRAFENHAUSEN",
      },
      {
        CITY: "GRAFENRHEINFELD",
      },
      {
        CITY: "GRAFENRODA",
      },
      {
        CITY: "GRAFENTHAL",
      },
      {
        CITY: "GRAFENWOHR",
      },
      {
        CITY: "GRAFHORST",
      },
      {
        CITY: "GRAFING BEI MUNCHEN",
      },
      {
        CITY: "GRAFRATH",
      },
      {
        CITY: "GRAFSCHAFT",
      },
      {
        CITY: "GRAINAU",
      },
      {
        CITY: "GRAITSCHEN",
      },
      {
        CITY: "GRAMBIN",
      },
      {
        CITY: "GRAMBOW",
      },
      {
        CITY: "GRAMMOW",
      },
      {
        CITY: "GRAMZOW",
      },
      {
        CITY: "GRANSEE",
      },
      {
        CITY: "GRASBERG",
      },
      {
        CITY: "GRASBRUNN",
      },
      {
        CITY: "GRASSAU",
      },
      {
        CITY: "GRAVENBRUCH",
      },
      {
        CITY: "GRAVENECK",
      },
      {
        CITY: "GRAVENWIESBACH",
      },
      {
        CITY: "GREBBIN",
      },
      {
        CITY: "GREBENAU",
      },
      {
        CITY: "GREBENHAIN",
      },
      {
        CITY: "GREBENSTEIN",
      },
      {
        CITY: "GREDING",
      },
      {
        CITY: "GREEN",
      },
      {
        CITY: "GREFRATH",
      },
      {
        CITY: "GREIFENBERG",
      },
      {
        CITY: "GREIFENSTEIN",
      },
      {
        CITY: "GREIFSWALD",
      },
      {
        CITY: "GREILING",
      },
      {
        CITY: "GREIZ",
      },
      {
        CITY: "GREMERSDORF",
      },
      {
        CITY: "GREMSDORF",
      },
      {
        CITY: "GRENZACH-WYHLEN",
      },
      {
        CITY: "GRENZAU",
      },
      {
        CITY: "GREPPIN",
      },
      {
        CITY: "GRETHEM",
      },
      {
        CITY: "GRETHEN",
      },
      {
        CITY: "GRETTSTADT",
      },
      {
        CITY: "GREUSSEN",
      },
      {
        CITY: "GREUSSENHEIM",
      },
      {
        CITY: "GREVEN",
      },
      {
        CITY: "GREVENBROICH",
      },
      {
        CITY: "GREVESMUHLEN",
      },
      {
        CITY: "GRIBOW",
      },
      {
        CITY: "GRIES",
      },
      {
        CITY: "GRIESHEIM",
      },
      {
        CITY: "GRIESINGEN",
      },
      {
        CITY: "GRIESSTATT",
      },
      {
        CITY: "GRIMMA",
      },
      {
        CITY: "GRIMMEN",
      },
      {
        CITY: "GROBENZELL",
      },
      {
        CITY: "GROBZIG",
      },
      {
        CITY: "GRODITZ",
      },
      {
        CITY: "GRODITZ",
      },
      {
        CITY: "GROENINGEN",
      },
      {
        CITY: "GROITZSCH",
      },
      {
        CITY: "GROLSHEIM",
      },
      {
        CITY: "GROMBACH",
      },
      {
        CITY: "GROMITZ",
      },
      {
        CITY: "GRONAU",
      },
      {
        CITY: "GRONAU",
      },
      {
        CITY: "GRONAU",
      },
      {
        CITY: "GRONENBACH",
      },
      {
        CITY: "GRONWOHLD",
      },
      {
        CITY: "GROSS BENGERSTORF",
      },
      {
        CITY: "GROSS GRONAU",
      },
      {
        CITY: "GROSS KIESOW",
      },
      {
        CITY: "GROSS KORIS",
      },
      {
        CITY: "GROSS KREUTZ",
      },
      {
        CITY: "GROSS KUMMERFELD",
      },
      {
        CITY: "GROSS LINDOW",
      },
      {
        CITY: "GROSS MILTZOW",
      },
      {
        CITY: "GROSS OESINGEN",
      },
      {
        CITY: "GROSS RHEIDE",
      },
      {
        CITY: "GROSS SANTERSLEBEN",
      },
      {
        CITY: "GROSS TWULPSTEDT",
      },
      {
        CITY: "GROSS WITTENSEE",
      },
      {
        CITY: "GROSS-BIEBERAU",
      },
      {
        CITY: "GROSS-GERAU",
      },
      {
        CITY: "GROSS-ROHRHEIM",
      },
      {
        CITY: "GROSS-UMSTADT",
      },
      {
        CITY: "GROSS-ZIMMERN",
      },
      {
        CITY: "GROSSAITINGEN",
      },
      {
        CITY: "GROSSALMERODE",
      },
      {
        CITY: "GROSSBARDORF",
      },
      {
        CITY: "GROSSBEEREN",
      },
      {
        CITY: "GROSSBETTLINGEN",
      },
      {
        CITY: "GROSSBOTTWAR",
      },
      {
        CITY: "GROSSBREITENBACH",
      },
      {
        CITY: "GROSSELFINGEN",
      },
      {
        CITY: "GROSSENASPE",
      },
      {
        CITY: "GROSSENGOTTERN",
      },
      {
        CITY: "GROSSENHAIN",
      },
      {
        CITY: "GROSSENKNETEN",
      },
      {
        CITY: "GROSSENLUDER",
      },
      {
        CITY: "GROSSENSEE",
      },
      {
        CITY: "GROSSENSEEBACH",
      },
      {
        CITY: "GROSSENWIEHE",
      },
      {
        CITY: "GROSSERLACH",
      },
      {
        CITY: "GROSSHABERSDORF",
      },
      {
        CITY: "GROSSHANSDORF",
      },
      {
        CITY: "GROSSHARTHAU",
      },
      {
        CITY: "GROSSHEIDE",
      },
      {
        CITY: "GROSSHEIRATH",
      },
      {
        CITY: "GROSSHEUBACH",
      },
      {
        CITY: "GROSSKAROLINENFELD",
      },
      {
        CITY: "GROSSKORBETHA",
      },
      {
        CITY: "GROSSKROTZENBURG",
      },
      {
        CITY: "GROSSKUGEL",
      },
      {
        CITY: "GROSSLITTGEN",
      },
      {
        CITY: "GROSSLOBICHAU",
      },
      {
        CITY: "GROSSMAISCHEID",
      },
      {
        CITY: "GROSSMEHRING",
      },
      {
        CITY: "GROSSMONRA",
      },
      {
        CITY: "GROSSOSTHEIM",
      },
      {
        CITY: "GROSSPOSNA",
      },
      {
        CITY: "GROSSRASCHEN",
      },
      {
        CITY: "GROSSRINDERFELD",
      },
      {
        CITY: "GROSSROHRSDORF",
      },
      {
        CITY: "GROSSROSSELN",
      },
      {
        CITY: "GROSSRUCKERSWALDE",
      },
      {
        CITY: "GROSSSCHIRMA",
      },
      {
        CITY: "GROSSSCHONAU",
      },
      {
        CITY: "GROSSSOLT",
      },
      {
        CITY: "GROSSWALLSTADT",
      },
      {
        CITY: "GROSSWECHSUNGEN",
      },
      {
        CITY: "GROVE",
      },
      {
        CITY: "GRUIBINGEN",
      },
      {
        CITY: "GRUNA",
      },
      {
        CITY: "GRUNBERG",
      },
      {
        CITY: "GRUNDAU",
      },
      {
        CITY: "GRUNDHOF",
      },
      {
        CITY: "GRUNENBERG",
      },
      {
        CITY: "GRUNENPLAN",
      },
      {
        CITY: "GRUNENTHAL",
      },
      {
        CITY: "GRUNHAINICHEN",
      },
      {
        CITY: "GRUNHEIDE",
      },
      {
        CITY: "GRUNHOF",
      },
      {
        CITY: "GRUNKRAUT",
      },
      {
        CITY: "GRUNSTADT",
      },
      {
        CITY: "GRUNWALD",
      },
      {
        CITY: "GSCHWEND",
      },
      {
        CITY: "GUBEN",
      },
      {
        CITY: "GUCKHEIM",
      },
      {
        CITY: "GUDENSBERG",
      },
      {
        CITY: "GUENZBURG",
      },
      {
        CITY: "GUESTROW",
      },
      {
        CITY: "GUGLINGEN",
      },
      {
        CITY: "GULZOW",
      },
      {
        CITY: "GULZOW",
      },
      {
        CITY: "GULZOWSHOF",
      },
      {
        CITY: "GUMMERSBACH",
      },
      {
        CITY: "GUNDELFINGEN",
      },
      {
        CITY: "GUNDELFINGEN",
      },
      {
        CITY: "GUNDELSHEIM",
      },
      {
        CITY: "GUNDELSHEIM",
      },
      {
        CITY: "GUNDERNHAUSEN",
      },
      {
        CITY: "GUNDERSHEIM",
      },
      {
        CITY: "GUNDREMMINGEN",
      },
      {
        CITY: "GUNNINGEN",
      },
      {
        CITY: "GUNTERSBLUM",
      },
      {
        CITY: "GUNTERSLEBEN",
      },
      {
        CITY: "GUNTHERSLEBEN",
      },
      {
        CITY: "GUNZENHAUSEN",
      },
      {
        CITY: "GURTWEIL",
      },
      {
        CITY: "GUSTAVEL",
      },
      {
        CITY: "GUSTEN",
      },
      {
        CITY: "GUSTERATH",
      },
      {
        CITY: "GUTACH (SCHWARZWALDBAHN)",
      },
      {
        CITY: "GUTACH IM BREISGAU",
      },
      {
        CITY: "GUTENACKER",
      },
      {
        CITY: "GUTENBERG",
      },
      {
        CITY: "GUTENSTETTEN",
      },
      {
        CITY: "GUTENZELL-HURBEL",
      },
      {
        CITY: "GUTMADINGEN",
      },
      {
        CITY: "GUTOW",
      },
      {
        CITY: "GUTZKOW",
      },
      {
        CITY: "GUXHAGEN",
      },
      {
        CITY: "GYHUM",
      },
      {
        CITY: "HAAG",
      },
      {
        CITY: "HAAG AN DER AMPER",
      },
      {
        CITY: "HAAN",
      },
      {
        CITY: "HAAR",
      },
      {
        CITY: "HAARBACH",
      },
      {
        CITY: "HAAREN",
      },
      {
        CITY: "HABACH",
      },
      {
        CITY: "HABENHAUSEN",
      },
      {
        CITY: "HABERSDORF",
      },
      {
        CITY: "HACHENBURG",
      },
      {
        CITY: "HACKENHEIM",
      },
      {
        CITY: "HADAMAR",
      },
      {
        CITY: "HADEMSTORF",
      },
      {
        CITY: "HAENIGSEN",
      },
      {
        CITY: "HAFENLOHR",
      },
      {
        CITY: "HAFFKRUG",
      },
      {
        CITY: "HAGE",
      },
      {
        CITY: "HAGELSTADT",
      },
      {
        CITY: "HAGEN",
      },
      {
        CITY: "HAGEN",
      },
      {
        CITY: "HAGENBACH",
      },
      {
        CITY: "HAGENBURG",
      },
      {
        CITY: "HAGENOW",
      },
      {
        CITY: "HAGNAU",
      },
      {
        CITY: "HAHN",
      },
      {
        CITY: "HAHNBACH",
      },
      {
        CITY: "HAHNENBACH",
      },
      {
        CITY: "HAHNHEIM",
      },
      {
        CITY: "HAHNSTATTEN",
      },
      {
        CITY: "HAIBACH",
      },
      {
        CITY: "HAIDE",
      },
      {
        CITY: "HAIDHOF",
      },
      {
        CITY: "HAIDMUHLE",
      },
      {
        CITY: "HAIGER",
      },
      {
        CITY: "HAIGERLOCH",
      },
      {
        CITY: "HAIMHAUSEN",
      },
      {
        CITY: "HAIMING",
      },
      {
        CITY: "HAINA",
      },
      {
        CITY: "HAINEWALDE",
      },
      {
        CITY: "HAINICHEN",
      },
      {
        CITY: "HAINSFARTH",
      },
      {
        CITY: "HAITERBACH",
      },
      {
        CITY: "HALBE",
      },
      {
        CITY: "HALBERG",
      },
      {
        CITY: "HALBERSTADT",
      },
      {
        CITY: "HALBLECH",
      },
      {
        CITY: "HALBS",
      },
      {
        CITY: "HALDENSLEBEN I",
      },
      {
        CITY: "HALDENWANG",
      },
      {
        CITY: "HALFING",
      },
      {
        CITY: "HALLBERGMOOS",
      },
      {
        CITY: "HALLE",
      },
      {
        CITY: "HALLE",
      },
      {
        CITY: "HALLE",
      },
      {
        CITY: "HALLE NEUSTADT",
      },
      {
        CITY: "HALLENBERG",
      },
      {
        CITY: "HALLERNDORF",
      },
      {
        CITY: "HALLNBERG",
      },
      {
        CITY: "HALLSCHLAG",
      },
      {
        CITY: "HALLSTADT",
      },
      {
        CITY: "HALSBACH",
      },
      {
        CITY: "HALSBRUCKE",
      },
      {
        CITY: "HALSENBACH",
      },
      {
        CITY: "HALSTENBEK",
      },
      {
        CITY: "HALTERN",
      },
      {
        CITY: "HALVER",
      },
      {
        CITY: "HALVESBOSTEL",
      },
      {
        CITY: "HAMB",
      },
      {
        CITY: "HAMBERGE",
      },
      {
        CITY: "HAMBERGEN",
      },
      {
        CITY: "HAMBRUCKEN",
      },
      {
        CITY: "HAMBUHREN",
      },
      {
        CITY: "HAMBURG",
      },
      {
        CITY: "HAMDORF",
      },
      {
        CITY: "HAMELIN",
      },
      {
        CITY: "HAMM",
      },
      {
        CITY: "HAMMAH",
      },
      {
        CITY: "HAMMELBURG",
      },
      {
        CITY: "HAMMERSBACH",
      },
      {
        CITY: "HAMMINKELN",
      },
      {
        CITY: "HAMWARDE",
      },
      {
        CITY: "HANAU",
      },
      {
        CITY: "HANDELOH",
      },
      {
        CITY: "HANDEWITT",
      },
      {
        CITY: "HANERAU-HADEMARSCHEN",
      },
      {
        CITY: "HANHOFEN",
      },
      {
        CITY: "HANKENSBUTTEL",
      },
      {
        CITY: "HANNOVER",
      },
      {
        CITY: "HANOVER",
      },
      {
        CITY: "HANSTEDT",
      },
      {
        CITY: "HAPPURG",
      },
      {
        CITY: "HARBURG",
      },
      {
        CITY: "HARD",
      },
      {
        CITY: "HARDEGSEN",
      },
      {
        CITY: "HARDENBERG",
      },
      {
        CITY: "HARDHEIM",
      },
      {
        CITY: "HARDT",
      },
      {
        CITY: "HAREN",
      },
      {
        CITY: "HARGESHEIM",
      },
      {
        CITY: "HARLACHEN",
      },
      {
        CITY: "HARMSDORF",
      },
      {
        CITY: "HARMSTORF",
      },
      {
        CITY: "HARPSTEDT",
      },
      {
        CITY: "HARRA",
      },
      {
        CITY: "HARRISLEE",
      },
      {
        CITY: "HARSCHBACH",
      },
      {
        CITY: "HARSEFELD",
      },
      {
        CITY: "HARSEWINKEL",
      },
      {
        CITY: "HARSLEBEN",
      },
      {
        CITY: "HARSTE",
      },
      {
        CITY: "HARSUM",
      },
      {
        CITY: "HARTENFELS",
      },
      {
        CITY: "HARTENHOLM",
      },
      {
        CITY: "HARTENSTEIN",
      },
      {
        CITY: "HARTENSTEIN",
      },
      {
        CITY: "HARTHA",
      },
      {
        CITY: "HARTHAUSEN",
      },
      {
        CITY: "HARTHEIM",
      },
      {
        CITY: "HARTMANNSDORF",
      },
      {
        CITY: "HARTMANNSDORF",
      },
      {
        CITY: "HARXHEIM",
      },
      {
        CITY: "HARZGERODE",
      },
      {
        CITY: "HASBERGEN",
      },
      {
        CITY: "HASCHBACH AN DER GLAN",
      },
      {
        CITY: "HASEL",
      },
      {
        CITY: "HASELBACH",
      },
      {
        CITY: "HASELDORF",
      },
      {
        CITY: "HASELUNNE",
      },
      {
        CITY: "HASENMOOR",
      },
      {
        CITY: "HASLACH IM KINZIGTAL",
      },
      {
        CITY: "HASLOCH",
      },
      {
        CITY: "HASLOH",
      },
      {
        CITY: "HASSEL",
      },
      {
        CITY: "HASSEL",
      },
      {
        CITY: "HASSELBACH",
      },
      {
        CITY: "HASSELBERG",
      },
      {
        CITY: "HASSELFELDE",
      },
      {
        CITY: "HASSFURT",
      },
      {
        CITY: "HASSLOCH",
      },
      {
        CITY: "HASSMERSHEIM",
      },
      {
        CITY: "HASTE",
      },
      {
        CITY: "HASTENRATH",
      },
      {
        CITY: "HASWEDE",
      },
      {
        CITY: "HATTEN",
      },
      {
        CITY: "HATTENHOFEN",
      },
      {
        CITY: "HATTENHOFEN",
      },
      {
        CITY: "HATTERSHEIM",
      },
      {
        CITY: "HATTERT",
      },
      {
        CITY: "HATTINGEN",
      },
      {
        CITY: "HATTORF",
      },
      {
        CITY: "HATTSTEDT",
      },
      {
        CITY: "HATZENBUHL",
      },
      {
        CITY: "HATZFELD",
      },
      {
        CITY: "HAUS",
      },
      {
        CITY: "HAUSACH",
      },
      {
        CITY: "HAUSEN",
      },
      {
        CITY: "HAUSEN",
      },
      {
        CITY: "HAUSEN",
      },
      {
        CITY: "HAUSEN AM TANN",
      },
      {
        CITY: "HAUSEN OB VERENA",
      },
      {
        CITY: "HAUSEN UBER AAR",
      },
      {
        CITY: "HAUSER",
      },
      {
        CITY: "HAUSERN",
      },
      {
        CITY: "HAUSHAM",
      },
      {
        CITY: "HAUSTEN",
      },
      {
        CITY: "HAUTERODA",
      },
      {
        CITY: "HAUZENBERG",
      },
      {
        CITY: "HAVELBERG",
      },
      {
        CITY: "HAVERKAMP",
      },
      {
        CITY: "HAVIXBECK",
      },
      {
        CITY: "HAWANGEN",
      },
      {
        CITY: "HAYINGEN",
      },
      {
        CITY: "HEBERTSFELDEN",
      },
      {
        CITY: "HEBERTSHAUSEN",
      },
      {
        CITY: "HECHINGEN",
      },
      {
        CITY: "HECHTHAUSEN",
      },
      {
        CITY: "HECKLINGEN",
      },
      {
        CITY: "HEDDESHEIM",
      },
      {
        CITY: "HEEDE",
      },
      {
        CITY: "HEEG",
      },
      {
        CITY: "HEEK",
      },
      {
        CITY: "HEESLINGEN",
      },
      {
        CITY: "HEFERSWEILER",
      },
      {
        CITY: "HEGGE",
      },
      {
        CITY: "HEHLEN",
      },
      {
        CITY: "HEIDE",
      },
      {
        CITY: "HEIDE",
      },
      {
        CITY: "HEIDECK",
      },
      {
        CITY: "HEIDEHOF",
      },
      {
        CITY: "HEIDEKAMP",
      },
      {
        CITY: "HEIDELBERG",
      },
      {
        CITY: "HEIDEN",
      },
      {
        CITY: "HEIDENAU",
      },
      {
        CITY: "HEIDENAU",
      },
      {
        CITY: "HEIDENBURG",
      },
      {
        CITY: "HEIDENHEIM",
      },
      {
        CITY: "HEIDESHEIM",
      },
      {
        CITY: "HEIGENBRUCKEN",
      },
      {
        CITY: "HEIKENDORF",
      },
      {
        CITY: "HEILBAD HEILIGENSTADT",
      },
      {
        CITY: "HEILBRONN",
      },
      {
        CITY: "HEILIGENGRABE",
      },
      {
        CITY: "HEILIGENHAFEN",
      },
      {
        CITY: "HEILIGENHAGEN",
      },
      {
        CITY: "HEILIGENHAUS",
      },
      {
        CITY: "HEILIGENROTH",
      },
      {
        CITY: "HEILIGENSTADT",
      },
      {
        CITY: "HEILIGENSTEDTENERKAMP",
      },
      {
        CITY: "HEILIGENTHAL",
      },
      {
        CITY: "HEILIGKREUZSTEINACH",
      },
      {
        CITY: "HEILINGEN",
      },
      {
        CITY: "HEILSBRONN",
      },
      {
        CITY: "HEIMBACH",
      },
      {
        CITY: "HEIMBACH",
      },
      {
        CITY: "HEIMBUCHENTHAL",
      },
      {
        CITY: "HEIMENKIRCH",
      },
      {
        CITY: "HEIMERTINGEN",
      },
      {
        CITY: "HEIMERZHEIM",
      },
      {
        CITY: "HEIMSHEIM",
      },
      {
        CITY: "HEIMSTETTEN",
      },
      {
        CITY: "HEINADE",
      },
      {
        CITY: "HEINBOCKEL",
      },
      {
        CITY: "HEINERSREUTH",
      },
      {
        CITY: "HEININGEN",
      },
      {
        CITY: "HEININGEN",
      },
      {
        CITY: "HEINKENBORSTEL",
      },
      {
        CITY: "HEINRICHSTHAL",
      },
      {
        CITY: "HEINSBERG",
      },
      {
        CITY: "HEINSDORF",
      },
      {
        CITY: "HEINZ",
      },
      {
        CITY: "HEINZENBERG",
      },
      {
        CITY: "HEINZENHAUSEN",
      },
      {
        CITY: "HEISEDE",
      },
      {
        CITY: "HEIST",
      },
      {
        CITY: "HEITERSHEIM",
      },
      {
        CITY: "HELBRA",
      },
      {
        CITY: "HELDRUNGEN",
      },
      {
        CITY: "HELFANT",
      },
      {
        CITY: "HELFERSKIRCHEN",
      },
      {
        CITY: "HELLENHAHN-SCHELLENBERG",
      },
      {
        CITY: "HELLENTHAL",
      },
      {
        CITY: "HELLSTEIN",
      },
      {
        CITY: "HELLWEG",
      },
      {
        CITY: "HELMBRECHTS",
      },
      {
        CITY: "HELMENZEN",
      },
      {
        CITY: "HELMERN",
      },
      {
        CITY: "HELMERSHAUSEN",
      },
      {
        CITY: "HELMSTADT",
      },
      {
        CITY: "HELMSTE",
      },
      {
        CITY: "HELMSTEDT",
      },
      {
        CITY: "HELPSEN",
      },
      {
        CITY: "HELSA",
      },
      {
        CITY: "HELTERSBERG",
      },
      {
        CITY: "HELVESIEK",
      },
      {
        CITY: "HEMAU",
      },
      {
        CITY: "HEMDINGEN",
      },
      {
        CITY: "HEMELINGEN",
      },
      {
        CITY: "HEMER",
      },
      {
        CITY: "HEMHOFEN",
      },
      {
        CITY: "HEMMELZEN",
      },
      {
        CITY: "HEMMERSDORF",
      },
      {
        CITY: "HEMMINGEN",
      },
      {
        CITY: "HEMMINGEN",
      },
      {
        CITY: "HEMMINGSTEDT",
      },
      {
        CITY: "HEMMOOR",
      },
      {
        CITY: "HEMSBACH",
      },
      {
        CITY: "HEMSBUNDE",
      },
      {
        CITY: "HEMSLINGEN",
      },
      {
        CITY: "HENFENFELD",
      },
      {
        CITY: "HENGERSBERG",
      },
      {
        CITY: "HENNEF",
      },
      {
        CITY: "HENNICKENDORF",
      },
      {
        CITY: "HENNIGSDORF",
      },
      {
        CITY: "HENNSTEDT",
      },
      {
        CITY: "HENNWEILER",
      },
      {
        CITY: "HENSTEDT-ULZBURG",
      },
      {
        CITY: "HEPBERG",
      },
      {
        CITY: "HEPPDIEL",
      },
      {
        CITY: "HEPPENHEIM AN DER BERGSTRASSE",
      },
      {
        CITY: "HEPSTEDT",
      },
      {
        CITY: "HERBERTINGEN",
      },
      {
        CITY: "HERBOLZHEIM",
      },
      {
        CITY: "HERBORN",
      },
      {
        CITY: "HERBORN",
      },
      {
        CITY: "HERBRECHTINGEN",
      },
      {
        CITY: "HERBSLEBEN",
      },
      {
        CITY: "HERBSTEIN",
      },
      {
        CITY: "HERDECKE",
      },
      {
        CITY: "HERDORF",
      },
      {
        CITY: "HERDWANGEN-SCHONACH",
      },
      {
        CITY: "HERFATZ",
      },
      {
        CITY: "HERFORD",
      },
      {
        CITY: "HERFORST",
      },
      {
        CITY: "HERGENFELD",
      },
      {
        CITY: "HERGISDORF",
      },
      {
        CITY: "HERGOLDING",
      },
      {
        CITY: "HERGOLSHAUSEN",
      },
      {
        CITY: "HERINGEN",
      },
      {
        CITY: "HERINGEN",
      },
      {
        CITY: "HERINGSDORF",
      },
      {
        CITY: "HERINGSDORF",
      },
      {
        CITY: "HERLESHAUSEN",
      },
      {
        CITY: "HERMANNSBURG",
      },
      {
        CITY: "HERMARINGEN",
      },
      {
        CITY: "HERMERSBERG",
      },
      {
        CITY: "HERMESKEIL",
      },
      {
        CITY: "HERMSDORF",
      },
      {
        CITY: "HERNE",
      },
      {
        CITY: "HEROLDSBACH",
      },
      {
        CITY: "HEROLDSBERG",
      },
      {
        CITY: "HERREN STEINFELD",
      },
      {
        CITY: "HERREN-SULZBACH",
      },
      {
        CITY: "HERRENBERG",
      },
      {
        CITY: "HERRESBACH",
      },
      {
        CITY: "HERRIEDEN",
      },
      {
        CITY: "HERRINGEN",
      },
      {
        CITY: "HERRISCHRIED",
      },
      {
        CITY: "HERRNBURG",
      },
      {
        CITY: "HERRNHUT",
      },
      {
        CITY: "HERRSCHING AM AMMERSEE",
      },
      {
        CITY: "HERRSTEIN",
      },
      {
        CITY: "HERSBRUCK",
      },
      {
        CITY: "HERSCHBACH",
      },
      {
        CITY: "HERSCHBERG",
      },
      {
        CITY: "HERSCHEID",
      },
      {
        CITY: "HERSCHWEILER-PETTERSHEIM",
      },
      {
        CITY: "HERTEN",
      },
      {
        CITY: "HERXHEIM",
      },
      {
        CITY: "HERZBERG",
      },
      {
        CITY: "HERZBERG AM HARZ",
      },
      {
        CITY: "HERZEBROCK",
      },
      {
        CITY: "HERZFELD",
      },
      {
        CITY: "HERZLAKE",
      },
      {
        CITY: "HERZOGENAURACH",
      },
      {
        CITY: "HERZOGENRATH",
      },
      {
        CITY: "HERZOGSWEILER",
      },
      {
        CITY: "HESEDORF",
      },
      {
        CITY: "HESEL",
      },
      {
        CITY: "HESPE",
      },
      {
        CITY: "HESSDORF",
      },
      {
        CITY: "HESSELBACH",
      },
      {
        CITY: "HESSEN",
      },
      {
        CITY: "HESSHEIM",
      },
      {
        CITY: "HESSIGHEIM",
      },
      {
        CITY: "HESSISCH LICHTENAU",
      },
      {
        CITY: "HESSISCH OLDENDORF",
      },
      {
        CITY: "HETSCHBURG",
      },
      {
        CITY: "HETTENLEIDELHEIM",
      },
      {
        CITY: "HETTENRODT",
      },
      {
        CITY: "HETTSTADT",
      },
      {
        CITY: "HETTSTEDT",
      },
      {
        CITY: "HETZENHAUSEN",
      },
      {
        CITY: "HETZERATH",
      },
      {
        CITY: "HETZLINSHOFEN",
      },
      {
        CITY: "HETZWEGE",
      },
      {
        CITY: "HEUBACH",
      },
      {
        CITY: "HEUBACH",
      },
      {
        CITY: "HEUCHELHEIM",
      },
      {
        CITY: "HEUCHLINGEN",
      },
      {
        CITY: "HEUDEBER",
      },
      {
        CITY: "HEUDORF",
      },
      {
        CITY: "HEUERSSEN",
      },
      {
        CITY: "HEUSENSTAMM",
      },
      {
        CITY: "HEUSTREU",
      },
      {
        CITY: "HEUSWEILER",
      },
      {
        CITY: "HEUWEILER",
      },
      {
        CITY: "HEYERODE",
      },
      {
        CITY: "HIDDENHAUSEN",
      },
      {
        CITY: "HIDDENSEE",
      },
      {
        CITY: "HILBERSDORF",
      },
      {
        CITY: "HILCHENBACH",
      },
      {
        CITY: "HILDBURGHAUSEN",
      },
      {
        CITY: "HILDEBRANDSHAUSEN",
      },
      {
        CITY: "HILDEN",
      },
      {
        CITY: "HILDERS",
      },
      {
        CITY: "HILDESHEIM",
      },
      {
        CITY: "HILDRIZHAUSEN",
      },
      {
        CITY: "HILGERMISSEN",
      },
      {
        CITY: "HILGERT",
      },
      {
        CITY: "HILGERTSHAUSEN-TANDERN",
      },
      {
        CITY: "HILLE",
      },
      {
        CITY: "HILLERSLEBEN",
      },
      {
        CITY: "HILLESHEIM",
      },
      {
        CITY: "HILLSCHEID",
      },
      {
        CITY: "HILPOLTSTEIN",
      },
      {
        CITY: "HILSCHEID",
      },
      {
        CITY: "HILTER",
      },
      {
        CITY: "HILTPOLTSTEIN",
      },
      {
        CITY: "HILTRUP",
      },
      {
        CITY: "HILWARTSHAUSEN",
      },
      {
        CITY: "HILZINGEN",
      },
      {
        CITY: "HIMBERGEN",
      },
      {
        CITY: "HIMMELKRON",
      },
      {
        CITY: "HIMMELPFORTEN",
      },
      {
        CITY: "HIMMELSTADT",
      },
      {
        CITY: "HINRICHSHAGEN",
      },
      {
        CITY: "HINTE",
      },
      {
        CITY: "HINTERHERMSDORF",
      },
      {
        CITY: "HINTERM HORN",
      },
      {
        CITY: "HINTERSCHMIDING",
      },
      {
        CITY: "HINTERZARTEN",
      },
      {
        CITY: "HIRRLINGEN",
      },
      {
        CITY: "HIRSCHAID",
      },
      {
        CITY: "HIRSCHAU",
      },
      {
        CITY: "HIRSCHBERG",
      },
      {
        CITY: "HIRSCHFELDE",
      },
      {
        CITY: "HIRSCHHORN",
      },
      {
        CITY: "HIRSCHHORN",
      },
      {
        CITY: "HIRSCHSTEIN",
      },
      {
        CITY: "HIRSTEIN",
      },
      {
        CITY: "HIRZENHAIN",
      },
      {
        CITY: "HIRZLEI",
      },
      {
        CITY: "HITTBERGEN",
      },
      {
        CITY: "HITZACKER",
      },
      {
        CITY: "HITZHOFEN",
      },
      {
        CITY: "HOBBERSDORF",
      },
      {
        CITY: "HOBECK",
      },
      {
        CITY: "HOCHBERG",
      },
      {
        CITY: "HOCHBORN",
      },
      {
        CITY: "HOCHDONN",
      },
      {
        CITY: "HOCHDORF",
      },
      {
        CITY: "HOCHDORF",
      },
      {
        CITY: "HOCHDORF-ASSENHEIM",
      },
      {
        CITY: "HOCHENSCHWAND",
      },
      {
        CITY: "HOCHHEIM AM MAIN",
      },
      {
        CITY: "HOCHKIRCH",
      },
      {
        CITY: "HOCHSCHEID",
      },
      {
        CITY: "HOCHSPEYER",
      },
      {
        CITY: "HOCHST IM ODENWALD",
      },
      {
        CITY: "HOCHSTADT",
      },
      {
        CITY: "HOCHSTADT AN DER AISCH",
      },
      {
        CITY: "HOCHSTADTEN",
      },
      {
        CITY: "HOCHSTENBACH",
      },
      {
        CITY: "HOCHSTETTEN-DHAUN",
      },
      {
        CITY: "HOCHSTRASSE",
      },
      {
        CITY: "HOCHWANG",
      },
      {
        CITY: "HOCKENDORF",
      },
      {
        CITY: "HOCKENHEIM",
      },
      {
        CITY: "HODENHAGEN",
      },
      {
        CITY: "HOF",
      },
      {
        CITY: "HOF",
      },
      {
        CITY: "HOF",
      },
      {
        CITY: "HOFBIEBER",
      },
      {
        CITY: "HOFEN",
      },
      {
        CITY: "HOFEN AN DER ENZ",
      },
      {
        CITY: "HOFER",
      },
      {
        CITY: "HOFGARTEN",
      },
      {
        CITY: "HOFGEISMAR",
      },
      {
        CITY: "HOFHEIM",
      },
      {
        CITY: "HOFHEIM AM TAUNUS",
      },
      {
        CITY: "HOFKIRCHEN",
      },
      {
        CITY: "HOFSTETTEN",
      },
      {
        CITY: "HOHBERG",
      },
      {
        CITY: "HOHE",
      },
      {
        CITY: "HOHEINOD",
      },
      {
        CITY: "HOHEN",
      },
      {
        CITY: "HOHEN",
      },
      {
        CITY: "HOHEN NEUENDORF",
      },
      {
        CITY: "HOHEN SCHWARFS",
      },
      {
        CITY: "HOHEN WANGELIN",
      },
      {
        CITY: "HOHENASPE",
      },
      {
        CITY: "HOHENAU",
      },
      {
        CITY: "HOHENBERG",
      },
      {
        CITY: "HOHENBERG",
      },
      {
        CITY: "HOHENBERGHAM",
      },
      {
        CITY: "HOHENBOCKA",
      },
      {
        CITY: "HOHENBRUNN",
      },
      {
        CITY: "HOHENDODELEBEN",
      },
      {
        CITY: "HOHENEGGELSEN",
      },
      {
        CITY: "HOHENERXLEBEN",
      },
      {
        CITY: "HOHENFELDE",
      },
      {
        CITY: "HOHENFELDE",
      },
      {
        CITY: "HOHENFELS",
      },
      {
        CITY: "HOHENFINOW",
      },
      {
        CITY: "HOHENFURCH",
      },
      {
        CITY: "HOHENHAMELN",
      },
      {
        CITY: "HOHENHAUS",
      },
      {
        CITY: "HOHENHORN",
      },
      {
        CITY: "HOHENKAMMER",
      },
      {
        CITY: "HOHENKIRCHEN-SIEGERTSBRUNN",
      },
      {
        CITY: "HOHENLEIMBACH",
      },
      {
        CITY: "HOHENLEIPISCH",
      },
      {
        CITY: "HOHENLEUBEN",
      },
      {
        CITY: "HOHENLIMBURG",
      },
      {
        CITY: "HOHENLINDEN",
      },
      {
        CITY: "HOHENLOCKSTEDT",
      },
      {
        CITY: "HOHENMOLSEN",
      },
      {
        CITY: "HOHENOLLEN",
      },
      {
        CITY: "HOHENPEISSENBERG",
      },
      {
        CITY: "HOHENRODA",
      },
      {
        CITY: "HOHENROTH",
      },
      {
        CITY: "HOHENSCHAFTLARN",
      },
      {
        CITY: "HOHENSTEIN",
      },
      {
        CITY: "HOHENSTEIN",
      },
      {
        CITY: "HOHENSTEIN-ERNSTTHAL",
      },
      {
        CITY: "HOHENTENGEN AM HOCHRHEIN",
      },
      {
        CITY: "HOHENTHANN",
      },
      {
        CITY: "HOHENWART",
      },
      {
        CITY: "HOHENWARTHE",
      },
      {
        CITY: "HOHENWESTEDT",
      },
      {
        CITY: "HOHN",
      },
      {
        CITY: "HOHN",
      },
      {
        CITY: "HOHNDORF",
      },
      {
        CITY: "HOHNE",
      },
      {
        CITY: "HOHNHORST",
      },
      {
        CITY: "HOHNSTEIN",
      },
      {
        CITY: "HOHNSTORF",
      },
      {
        CITY: "HOHR-GRENZHAUSEN",
      },
      {
        CITY: "HOISDORF",
      },
      {
        CITY: "HOITLINGEN",
      },
      {
        CITY: "HOLDORF",
      },
      {
        CITY: "HOLLE",
      },
      {
        CITY: "HOLLENBACH",
      },
      {
        CITY: "HOLLENSTEDT",
      },
      {
        CITY: "HOLLER",
      },
      {
        CITY: "HOLLERN",
      },
      {
        CITY: "HOLLFELD",
      },
      {
        CITY: "HOLLINGSTEDT",
      },
      {
        CITY: "HOLLSTADT",
      },
      {
        CITY: "HOLM",
      },
      {
        CITY: "HOLTE",
      },
      {
        CITY: "HOLTHAUSEN",
      },
      {
        CITY: "HOLTINGHAUSEN",
      },
      {
        CITY: "HOLTLAND",
      },
      {
        CITY: "HOLTSEE",
      },
      {
        CITY: "HOLZBACH",
      },
      {
        CITY: "HOLZENGEL",
      },
      {
        CITY: "HOLZGERLINGEN",
      },
      {
        CITY: "HOLZGUNZ",
      },
      {
        CITY: "HOLZHAUSEN",
      },
      {
        CITY: "HOLZHAUSEN AN DER HAIDE",
      },
      {
        CITY: "HOLZHEIM",
      },
      {
        CITY: "HOLZHEIM",
      },
      {
        CITY: "HOLZKIRCHEN",
      },
      {
        CITY: "HOLZMADEN",
      },
      {
        CITY: "HOLZMINDEN",
      },
      {
        CITY: "HOLZWEISSIG",
      },
      {
        CITY: "HOLZWICKEDE",
      },
      {
        CITY: "HOMBERG",
      },
      {
        CITY: "HOMBERG",
      },
      {
        CITY: "HOMBURG",
      },
      {
        CITY: "HONEBACH",
      },
      {
        CITY: "HOOGSTEDE",
      },
      {
        CITY: "HOPFERAU",
      },
      {
        CITY: "HOPFINGEN",
      },
      {
        CITY: "HOPPEGARTEN",
      },
      {
        CITY: "HOPPEGARTEN",
      },
      {
        CITY: "HOPPSTADTEN-WEIERSBACH",
      },
      {
        CITY: "HOPSTEN",
      },
      {
        CITY: "HORATH",
      },
      {
        CITY: "HORB AM NECKAR",
      },
      {
        CITY: "HORBACH",
      },
      {
        CITY: "HORBRUCH",
      },
      {
        CITY: "HORDT",
      },
      {
        CITY: "HORGAU",
      },
      {
        CITY: "HORGERTSHAUSEN",
      },
      {
        CITY: "HORHAUSEN",
      },
      {
        CITY: "HORMERSDORF",
      },
      {
        CITY: "HORN-BAD MEINBERG",
      },
      {
        CITY: "HORNBACH",
      },
      {
        CITY: "HORNBERG",
      },
      {
        CITY: "HORNBURG",
      },
      {
        CITY: "HORNEBURG",
      },
      {
        CITY: "HORPERATH",
      },
      {
        CITY: "HORRESSEN",
      },
      {
        CITY: "HORRWEILER",
      },
      {
        CITY: "HORSELGAU",
      },
      {
        CITY: "HORST",
      },
      {
        CITY: "HORST",
      },
      {
        CITY: "HORST",
      },
      {
        CITY: "HORSTDORF",
      },
      {
        CITY: "HORSTEDT",
      },
      {
        CITY: "HORSTEL",
      },
      {
        CITY: "HORSTMAR",
      },
      {
        CITY: "HOSBACH",
      },
      {
        CITY: "HOSENFELD",
      },
      {
        CITY: "HOSLWANG",
      },
      {
        CITY: "HOSSKIRCH",
      },
      {
        CITY: "HOST",
      },
      {
        CITY: "HOTENSLEBEN",
      },
      {
        CITY: "HOVELHOF",
      },
      {
        CITY: "HOYA",
      },
      {
        CITY: "HOYERSWERDA",
      },
      {
        CITY: "HOYM",
      },
      {
        CITY: "HUCKELHOVEN",
      },
      {
        CITY: "HUCKESWAGEN",
      },
      {
        CITY: "HUDE",
      },
      {
        CITY: "HUELLHORST",
      },
      {
        CITY: "HUFFELSHEIM",
      },
      {
        CITY: "HUFFENHARDT",
      },
      {
        CITY: "HUFINGEN",
      },
      {
        CITY: "HUGELSHEIM",
      },
      {
        CITY: "HUGLFING",
      },
      {
        CITY: "HUHNERHOF",
      },
      {
        CITY: "HUJE",
      },
      {
        CITY: "HULBEN",
      },
      {
        CITY: "HUMPTRUP",
      },
      {
        CITY: "HUNDERDORF",
      },
      {
        CITY: "HUNDHAUPTEN",
      },
      {
        CITY: "HUNDISBURG",
      },
      {
        CITY: "HUNDSANGEN",
      },
      {
        CITY: "HUNDSDORF",
      },
      {
        CITY: "HUNFELD",
      },
      {
        CITY: "HUNGEN",
      },
      {
        CITY: "HUNGENROTH",
      },
      {
        CITY: "HUNXE",
      },
      {
        CITY: "HUNZEL",
      },
      {
        CITY: "HURLACH",
      },
      {
        CITY: "HURTGENWALD",
      },
      {
        CITY: "HUSBY",
      },
      {
        CITY: "HUSUM",
      },
      {
        CITY: "HUSUM",
      },
      {
        CITY: "HUTSCHENHAUSEN",
      },
      {
        CITY: "HUTTBLEK",
      },
      {
        CITY: "HUTTENBERG",
      },
      {
        CITY: "HUTTHURM",
      },
      {
        CITY: "HUTTISHEIM",
      },
      {
        CITY: "HUTTLINGEN",
      },
      {
        CITY: "IBACH",
      },
      {
        CITY: "IBBENBUEREN",
      },
      {
        CITY: "ICHENHAUSEN",
      },
      {
        CITY: "ICHTERSHAUSEN",
      },
      {
        CITY: "ICKER",
      },
      {
        CITY: "ICKING",
      },
      {
        CITY: "IDAR-OBERSTEIN",
      },
      {
        CITY: "IDENHEIM",
      },
      {
        CITY: "IDSTEIN",
      },
      {
        CITY: "IFFELDORF",
      },
      {
        CITY: "IFFEZHEIM",
      },
      {
        CITY: "IGEL",
      },
      {
        CITY: "IGENSDORF",
      },
      {
        CITY: "IGERSHEIM",
      },
      {
        CITY: "IGGENSBACH",
      },
      {
        CITY: "IGGINGEN",
      },
      {
        CITY: "IHLOW",
      },
      {
        CITY: "IHRINGEN",
      },
      {
        CITY: "IHRLERSTEIN",
      },
      {
        CITY: "ILBESHEIM",
      },
      {
        CITY: "ILFELD",
      },
      {
        CITY: "ILLERRIEDEN",
      },
      {
        CITY: "ILLERTISSEN",
      },
      {
        CITY: "ILLINGEN",
      },
      {
        CITY: "ILLINGEN",
      },
      {
        CITY: "ILLSCHWANG",
      },
      {
        CITY: "ILMENAU",
      },
      {
        CITY: "ILMMUNSTER",
      },
      {
        CITY: "ILSEDE",
      },
      {
        CITY: "ILSENBURG",
      },
      {
        CITY: "ILSFELD",
      },
      {
        CITY: "ILSHOFEN",
      },
      {
        CITY: "ILVESHEIM",
      },
      {
        CITY: "IM LOH",
      },
      {
        CITY: "IMMENDINGEN",
      },
      {
        CITY: "IMMENHAUSEN",
      },
      {
        CITY: "IMMENREUTH",
      },
      {
        CITY: "IMMENSTAAD AM BODENSEE",
      },
      {
        CITY: "IMMENSTADT IM ALLGAU",
      },
      {
        CITY: "IMSBACH",
      },
      {
        CITY: "IN DER MEER",
      },
      {
        CITY: "INCHENHOFEN",
      },
      {
        CITY: "INDEN",
      },
      {
        CITY: "INGELFINGEN",
      },
      {
        CITY: "INGELHEIM AM RHEIN",
      },
      {
        CITY: "INGERSLEBEN",
      },
      {
        CITY: "INGOLDINGEN",
      },
      {
        CITY: "INGOLSTADT",
      },
      {
        CITY: "INNERNZELL",
      },
      {
        CITY: "INNING AM AMMERSEE",
      },
      {
        CITY: "INNING AM HOLZ",
      },
      {
        CITY: "INSEL",
      },
      {
        CITY: "INSHEIM",
      },
      {
        CITY: "INSINGEN",
      },
      {
        CITY: "INSUL",
      },
      {
        CITY: "INZELL",
      },
      {
        CITY: "INZIGKOFEN",
      },
      {
        CITY: "INZLINGEN",
      },
      {
        CITY: "INZMUHLEN",
      },
      {
        CITY: "IPHOFEN",
      },
      {
        CITY: "IPPESHEIM",
      },
      {
        CITY: "IPSHEIM",
      },
      {
        CITY: "IRLBACH",
      },
      {
        CITY: "IRMENACH",
      },
      {
        CITY: "IRREL",
      },
      {
        CITY: "IRSCHENBERG",
      },
      {
        CITY: "IRSEE",
      },
      {
        CITY: "IRXLEBEN",
      },
      {
        CITY: "ISEN",
      },
      {
        CITY: "ISENBURG",
      },
      {
        CITY: "ISENBURG",
      },
      {
        CITY: "ISENBUTTEL",
      },
      {
        CITY: "ISERLOHN",
      },
      {
        CITY: "ISMANING",
      },
      {
        CITY: "ISNY IM ALLGAU",
      },
      {
        CITY: "ISPRINGEN",
      },
      {
        CITY: "ISSELBURG",
      },
      {
        CITY: "ISSERODA",
      },
      {
        CITY: "ISSERSTEDT",
      },
      {
        CITY: "ISSIGAU",
      },
      {
        CITY: "ISSUM",
      },
      {
        CITY: "ISTEIN",
      },
      {
        CITY: "ITTERSBACH",
      },
      {
        CITY: "ITTLINGEN",
      },
      {
        CITY: "ITZEHOE",
      },
      {
        CITY: "ITZSTEDT",
      },
      {
        CITY: "IVENACK",
      },
      {
        CITY: "JACOBSDORF",
      },
      {
        CITY: "JADERBERG",
      },
      {
        CITY: "JAGEL",
      },
      {
        CITY: "JAGERHOF",
      },
      {
        CITY: "JAGSTHAUSEN",
      },
      {
        CITY: "JAGSTZELL",
      },
      {
        CITY: "JAHNSDORF",
      },
      {
        CITY: "JAHNSHOF",
      },
      {
        CITY: "JAMELN",
      },
      {
        CITY: "JARMEN",
      },
      {
        CITY: "JEBENHAUSEN",
      },
      {
        CITY: "JEDDELOH EINS",
      },
      {
        CITY: "JELMSTORF",
      },
      {
        CITY: "JEMBKE",
      },
      {
        CITY: "JEMGUM",
      },
      {
        CITY: "JENA",
      },
      {
        CITY: "JENGEN",
      },
      {
        CITY: "JERICHOW",
      },
      {
        CITY: "JERSBEK",
      },
      {
        CITY: "JERSLEBEN",
      },
      {
        CITY: "JESBERG",
      },
      {
        CITY: "JESENDORF",
      },
      {
        CITY: "JESSEN",
      },
      {
        CITY: "JESSNITZ",
      },
      {
        CITY: "JESTADT",
      },
      {
        CITY: "JESTEBURG",
      },
      {
        CITY: "JESTETTEN",
      },
      {
        CITY: "JETTENBACH",
      },
      {
        CITY: "JETTINGEN-SCHEPPACH",
      },
      {
        CITY: "JETZENDORF",
      },
      {
        CITY: "JEVENSTEDT",
      },
      {
        CITY: "JEVER",
      },
      {
        CITY: "JHERINGSFEHN",
      },
      {
        CITY: "JOACHIMSTHAL",
      },
      {
        CITY: "JOCKGRIM",
      },
      {
        CITY: "JOEHLINGEN",
      },
      {
        CITY: "JOHANNESBERG",
      },
      {
        CITY: "JOHANNGEORGENSTADT",
      },
      {
        CITY: "JOHSTADT",
      },
      {
        CITY: "JORK",
      },
      {
        CITY: "JORL",
      },
      {
        CITY: "JUBEK",
      },
      {
        CITY: "JUCHEN",
      },
      {
        CITY: "JUGENHEIM",
      },
      {
        CITY: "JUIST",
      },
      {
        CITY: "JULBACH",
      },
      {
        CITY: "JULICH",
      },
      {
        CITY: "JULIUSBURG",
      },
      {
        CITY: "JUNGINGEN",
      },
      {
        CITY: "JUNKERATH",
      },
      {
        CITY: "JUTERBOG",
      },
      {
        CITY: "KAAKS",
      },
      {
        CITY: "KAARST",
      },
      {
        CITY: "KADELBURG",
      },
      {
        CITY: "KADEN",
      },
      {
        CITY: "KAGSDORF",
      },
      {
        CITY: "KAHL AM MAIN",
      },
      {
        CITY: "KAHLA",
      },
      {
        CITY: "KAISERSBACH",
      },
      {
        CITY: "KAISERSESCH",
      },
      {
        CITY: "KAISERSLAUTERN",
      },
      {
        CITY: "KAISHEIM",
      },
      {
        CITY: "KAKENSTORF",
      },
      {
        CITY: "KALBACH",
      },
      {
        CITY: "KALDENKIRCHEN",
      },
      {
        CITY: "KALEFELD",
      },
      {
        CITY: "KALENBORN-SCHEUERN",
      },
      {
        CITY: "KALKAR",
      },
      {
        CITY: "KALKREUTH",
      },
      {
        CITY: "KALL",
      },
      {
        CITY: "KALLMUNZ",
      },
      {
        CITY: "KALLSTADT",
      },
      {
        CITY: "KALTENENGERS",
      },
      {
        CITY: "KALTENKIRCHEN",
      },
      {
        CITY: "KALTENNORDHEIM",
      },
      {
        CITY: "KALTENWESTHEIM",
      },
      {
        CITY: "KAMEN",
      },
      {
        CITY: "KAMENZ",
      },
      {
        CITY: "KAMMERFORST",
      },
      {
        CITY: "KAMMERFORST",
      },
      {
        CITY: "KAMMERSTEIN",
      },
      {
        CITY: "KAMP-BORNHOFEN",
      },
      {
        CITY: "KAMP-LINTFORT",
      },
      {
        CITY: "KAMPEN",
      },
      {
        CITY: "KAMSDORF",
      },
      {
        CITY: "KANDEL",
      },
      {
        CITY: "KANDERN",
      },
      {
        CITY: "KANZLEI",
      },
      {
        CITY: "KAPELLEN-DRUSWEILER",
      },
      {
        CITY: "KAPELLENBERG",
      },
      {
        CITY: "KAPPEL-GRAFENHAUSEN",
      },
      {
        CITY: "KAPPELN",
      },
      {
        CITY: "KAPPELRODECK",
      },
      {
        CITY: "KAPSWEYER",
      },
      {
        CITY: "KARBACH",
      },
      {
        CITY: "KARBEN",
      },
      {
        CITY: "KARIN",
      },
      {
        CITY: "KARL",
      },
      {
        CITY: "KARLSBAD",
      },
      {
        CITY: "KARLSDORF-NEUTHARD",
      },
      {
        CITY: "KARLSFELD",
      },
      {
        CITY: "KARLSHAGEN",
      },
      {
        CITY: "KARLSHAUSEN",
      },
      {
        CITY: "KARLSHULD",
      },
      {
        CITY: "KARLSKRON",
      },
      {
        CITY: "KARLSRUHE",
      },
      {
        CITY: "KARLSTADT AM MAIN",
      },
      {
        CITY: "KARLSTEIN",
      },
      {
        CITY: "KARLUM",
      },
      {
        CITY: "KARSBACH",
      },
      {
        CITY: "KARSDORF",
      },
      {
        CITY: "KARSTADT",
      },
      {
        CITY: "KARWEILER",
      },
      {
        CITY: "KASBACH-OHLENBERG",
      },
      {
        CITY: "KASDORF",
      },
      {
        CITY: "KASEL-GOLZIG",
      },
      {
        CITY: "KASENDORF",
      },
      {
        CITY: "KASSEEDORF",
      },
      {
        CITY: "KASSEL",
      },
      {
        CITY: "KASSOW",
      },
      {
        CITY: "KASTEL",
      },
      {
        CITY: "KASTELLAUN",
      },
      {
        CITY: "KASTL",
      },
      {
        CITY: "KASTORF",
      },
      {
        CITY: "KATLENBURG-LINDAU",
      },
      {
        CITY: "KATZENELNBOGEN",
      },
      {
        CITY: "KATZENMOOS",
      },
      {
        CITY: "KATZENSTEIN",
      },
      {
        CITY: "KATZOW",
      },
      {
        CITY: "KATZWANG",
      },
      {
        CITY: "KATZWINKEL",
      },
      {
        CITY: "KAUB",
      },
      {
        CITY: "KAUFBEUREN",
      },
      {
        CITY: "KAUFERING",
      },
      {
        CITY: "KAUFUNGEN",
      },
      {
        CITY: "KAULSDORF",
      },
      {
        CITY: "KAYHUDE",
      },
      {
        CITY: "KAYNA",
      },
      {
        CITY: "KEFENROD",
      },
      {
        CITY: "KEFFERHAUSEN",
      },
      {
        CITY: "KEHL",
      },
      {
        CITY: "KEIDELHEIM",
      },
      {
        CITY: "KEITUM",
      },
      {
        CITY: "KELBERG",
      },
      {
        CITY: "KELBRA",
      },
      {
        CITY: "KELHEIM",
      },
      {
        CITY: "KELKHEIM (TAUNUS)",
      },
      {
        CITY: "KELL",
      },
      {
        CITY: "KELLENHUSEN",
      },
      {
        CITY: "KELLER",
      },
      {
        CITY: "KELLINGHUSEN",
      },
      {
        CITY: "KELSTERBACH",
      },
      {
        CITY: "KEMBERG",
      },
      {
        CITY: "KEMERTING",
      },
      {
        CITY: "KEMMERN",
      },
      {
        CITY: "KEMNATH",
      },
      {
        CITY: "KEMPEN",
      },
      {
        CITY: "KEMPENICH",
      },
      {
        CITY: "KEMPFELD",
      },
      {
        CITY: "KEMPTEN (ALLGAEU)",
      },
      {
        CITY: "KENDENICH",
      },
      {
        CITY: "KENN",
      },
      {
        CITY: "KENNENBURG",
      },
      {
        CITY: "KENZINGEN",
      },
      {
        CITY: "KERLINGEN",
      },
      {
        CITY: "KERPEN",
      },
      {
        CITY: "KERVENHEIM",
      },
      {
        CITY: "KERZENHEIM",
      },
      {
        CITY: "KESSELBACH",
      },
      {
        CITY: "KESSELSDORF",
      },
      {
        CITY: "KESTERT",
      },
      {
        CITY: "KETSCH",
      },
      {
        CITY: "KETTENKAMP",
      },
      {
        CITY: "KETTERSHAUSEN",
      },
      {
        CITY: "KETTIG",
      },
      {
        CITY: "KETZIN",
      },
      {
        CITY: "KEVELAER",
      },
      {
        CITY: "KIEDRICH",
      },
      {
        CITY: "KIEFERSFELDEN",
      },
      {
        CITY: "KIEL",
      },
      {
        CITY: "KIENBERG",
      },
      {
        CITY: "KIERSPE",
      },
      {
        CITY: "KIESELBACH",
      },
      {
        CITY: "KIESELBRONN",
      },
      {
        CITY: "KIEZ",
      },
      {
        CITY: "KINDELBRUCK",
      },
      {
        CITY: "KINDING",
      },
      {
        CITY: "KINDSBACH",
      },
      {
        CITY: "KINHEIM",
      },
      {
        CITY: "KINSAU",
      },
      {
        CITY: "KIPFENBERG",
      },
      {
        CITY: "KIPPENHEIM",
      },
      {
        CITY: "KIRBERG",
      },
      {
        CITY: "KIRBURG",
      },
      {
        CITY: "KIRCHARDT",
      },
      {
        CITY: "KIRCHBERG",
      },
      {
        CITY: "KIRCHBERG",
      },
      {
        CITY: "KIRCHBERG",
      },
      {
        CITY: "KIRCHBERG",
      },
      {
        CITY: "KIRCHDORF",
      },
      {
        CITY: "KIRCHDORF",
      },
      {
        CITY: "KIRCHDORF AM INN",
      },
      {
        CITY: "KIRCHDORF IM WALD",
      },
      {
        CITY: "KIRCHEHRENBACH",
      },
      {
        CITY: "KIRCHEN",
      },
      {
        CITY: "KIRCHENDEMENREUTH",
      },
      {
        CITY: "KIRCHENLAMITZ",
      },
      {
        CITY: "KIRCHENTELLINSFURT",
      },
      {
        CITY: "KIRCHENTHUMBACH",
      },
      {
        CITY: "KIRCHGELLERSEN",
      },
      {
        CITY: "KIRCHHAIN",
      },
      {
        CITY: "KIRCHHAM",
      },
      {
        CITY: "KIRCHHASEL",
      },
      {
        CITY: "KIRCHHASLACH",
      },
      {
        CITY: "KIRCHHEILINGEN",
      },
      {
        CITY: "KIRCHHEIM",
      },
      {
        CITY: "KIRCHHEIM",
      },
      {
        CITY: "KIRCHHEIM AM RIES",
      },
      {
        CITY: "KIRCHHEIM AN DER WEINSTRASSE",
      },
      {
        CITY: "KIRCHHEIM UNTER TECK",
      },
      {
        CITY: "KIRCHHEIMBOLANDEN",
      },
      {
        CITY: "KIRCHHELLEN",
      },
      {
        CITY: "KIRCHHUNDEM",
      },
      {
        CITY: "KIRCHLAUTER",
      },
      {
        CITY: "KIRCHLENGERN",
      },
      {
        CITY: "KIRCHLINTELN",
      },
      {
        CITY: "KIRCHREIT",
      },
      {
        CITY: "KIRCHROTH",
      },
      {
        CITY: "KIRCHSAHR",
      },
      {
        CITY: "KIRCHSEELTE",
      },
      {
        CITY: "KIRCHSEEON",
      },
      {
        CITY: "KIRCHTIMKE",
      },
      {
        CITY: "KIRCHWEG",
      },
      {
        CITY: "KIRCHWEIDACH",
      },
      {
        CITY: "KIRCHWEYHE",
      },
      {
        CITY: "KIRCHWORBIS",
      },
      {
        CITY: "KIRCHZARTEN",
      },
      {
        CITY: "KIRCHZELL",
      },
      {
        CITY: "KIRKEL",
      },
      {
        CITY: "KIRN",
      },
      {
        CITY: "KIRRWEILER",
      },
      {
        CITY: "KIRSCHAU",
      },
      {
        CITY: "KIRSCHFURT",
      },
      {
        CITY: "KIRSCHHOFEN",
      },
      {
        CITY: "KIRSPENICH",
      },
      {
        CITY: "KIRTORF",
      },
      {
        CITY: "KISDORF",
      },
      {
        CITY: "KISSELBACH",
      },
      {
        CITY: "KISSENBRUCK",
      },
      {
        CITY: "KISSING",
      },
      {
        CITY: "KISSLEGG",
      },
      {
        CITY: "KIST",
      },
      {
        CITY: "KITZINGEN",
      },
      {
        CITY: "KITZSCHER",
      },
      {
        CITY: "KLADEN",
      },
      {
        CITY: "KLAIS",
      },
      {
        CITY: "KLANXBULL",
      },
      {
        CITY: "KLAUS",
      },
      {
        CITY: "KLAUSDORF",
      },
      {
        CITY: "KLAUSEN",
      },
      {
        CITY: "KLAUSHEIDE",
      },
      {
        CITY: "KLEIN AMMENSLEBEN",
      },
      {
        CITY: "KLEIN BERSSEN",
      },
      {
        CITY: "KLEIN KEDINGSHAGEN",
      },
      {
        CITY: "KLEIN LICHTENHAGEN",
      },
      {
        CITY: "KLEIN MECKELSEN",
      },
      {
        CITY: "KLEIN NORDENDE",
      },
      {
        CITY: "KLEIN OFFENSETH-SPARRIESHOOP",
      },
      {
        CITY: "KLEIN ROGAHN",
      },
      {
        CITY: "KLEIN RONNAU",
      },
      {
        CITY: "KLEIN WESENBERG",
      },
      {
        CITY: "KLEIN WITTENSEE",
      },
      {
        CITY: "KLEIN-GERAU",
      },
      {
        CITY: "KLEIN-SCHLAMIN",
      },
      {
        CITY: "KLEIN-WINTERNHEIM",
      },
      {
        CITY: "KLEIN-ZIMMERN",
      },
      {
        CITY: "KLEINBEEREN",
      },
      {
        CITY: "KLEINBLITTERSDORF",
      },
      {
        CITY: "KLEINBUNDENBACH",
      },
      {
        CITY: "KLEINFAHNER",
      },
      {
        CITY: "KLEINFURRA",
      },
      {
        CITY: "KLEINHEUBACH",
      },
      {
        CITY: "KLEINKAHL",
      },
      {
        CITY: "KLEINMACHNOW",
      },
      {
        CITY: "KLEINMAISCHEID",
      },
      {
        CITY: "KLEINOSTHEIM",
      },
      {
        CITY: "KLEINRINDERFELD",
      },
      {
        CITY: "KLEINSCHWABHAUSEN",
      },
      {
        CITY: "KLEINSENDELBACH",
      },
      {
        CITY: "KLEINSTEINHAUSEN",
      },
      {
        CITY: "KLEINWALLSTADT",
      },
      {
        CITY: "KLEINWOLMSDORF",
      },
      {
        CITY: "KLETTWITZ",
      },
      {
        CITY: "KLEVE",
      },
      {
        CITY: "KLINGE",
      },
      {
        CITY: "KLINGENBERG",
      },
      {
        CITY: "KLINGENBERG AM MAIN",
      },
      {
        CITY: "KLINGENMUNSTER",
      },
      {
        CITY: "KLINGENTHAL",
      },
      {
        CITY: "KLINK",
      },
      {
        CITY: "KLIPPHAUSEN",
      },
      {
        CITY: "KLOSTER",
      },
      {
        CITY: "KLOSTERHAUSER",
      },
      {
        CITY: "KLOSTERLECHFELD",
      },
      {
        CITY: "KLOSTERMANSFELD",
      },
      {
        CITY: "KLOSTERSEE",
      },
      {
        CITY: "KLOTTEN",
      },
      {
        CITY: "KLOTZE",
      },
      {
        CITY: "KLUIS",
      },
      {
        CITY: "KLUTZ",
      },
      {
        CITY: "KNESEBECK",
      },
      {
        CITY: "KNETZGAU",
      },
      {
        CITY: "KNITTELSHEIM",
      },
      {
        CITY: "KNITTLINGEN",
      },
      {
        CITY: "KNOBELSDORF",
      },
      {
        CITY: "KNORINGEN",
      },
      {
        CITY: "KOBERN-GONDORF",
      },
      {
        CITY: "KOBLENZ",
      },
      {
        CITY: "KOCHEL",
      },
      {
        CITY: "KODERSDORF",
      },
      {
        CITY: "KODITZ",
      },
      {
        CITY: "KODNITZ",
      },
      {
        CITY: "KOFERING",
      },
      {
        CITY: "KOHLBERG",
      },
      {
        CITY: "KOHRA",
      },
      {
        CITY: "KOHREN-SAHLIS",
      },
      {
        CITY: "KOLBERMOOR",
      },
      {
        CITY: "KOLBINGEN",
      },
      {
        CITY: "KOLITZHEIM",
      },
      {
        CITY: "KOLKWITZ",
      },
      {
        CITY: "KOLLEDA",
      },
      {
        CITY: "KOLLERBACH",
      },
      {
        CITY: "KOLLMAR",
      },
      {
        CITY: "KOLLN-REISIEK",
      },
      {
        CITY: "KOLLNBURG",
      },
      {
        CITY: "KOLPIEN",
      },
      {
        CITY: "KOMP",
      },
      {
        CITY: "KONGEN",
      },
      {
        CITY: "KONIGHEIM",
      },
      {
        CITY: "KONIGS WUSTERHAUSEN",
      },
      {
        CITY: "KONIGSBACH-STEIN",
      },
      {
        CITY: "KONIGSBERG IN BAYERN",
      },
      {
        CITY: "KONIGSBRONN",
      },
      {
        CITY: "KONIGSBRUCK",
      },
      {
        CITY: "KONIGSBRUNN",
      },
      {
        CITY: "KONIGSBRUNN",
      },
      {
        CITY: "KONIGSDORF",
      },
      {
        CITY: "KONIGSDORF",
      },
      {
        CITY: "KONIGSEE",
      },
      {
        CITY: "KONIGSEGGWALD",
      },
      {
        CITY: "KONIGSFELD",
      },
      {
        CITY: "KONIGSFELD IM SCHWARZWALD",
      },
      {
        CITY: "KONIGSHEIM",
      },
      {
        CITY: "KONIGSLUTTER AM ELM",
      },
      {
        CITY: "KONIGSTEIN",
      },
      {
        CITY: "KONIGSTEIN",
      },
      {
        CITY: "KONIGSTEIN IM TAUNUS",
      },
      {
        CITY: "KONIGSWARTHA",
      },
      {
        CITY: "KONIGSWINTER",
      },
      {
        CITY: "KONKEN",
      },
      {
        CITY: "KONNERN",
      },
      {
        CITY: "KONRADSREUTH",
      },
      {
        CITY: "KONSTANZ",
      },
      {
        CITY: "KONZ",
      },
      {
        CITY: "KONZELL",
      },
      {
        CITY: "KORB",
      },
      {
        CITY: "KORBACH",
      },
      {
        CITY: "KORCHOW",
      },
      {
        CITY: "KORDEL",
      },
      {
        CITY: "KORLE",
      },
      {
        CITY: "KORNTAL",
      },
      {
        CITY: "KORNWESTHEIM",
      },
      {
        CITY: "KORPERICH",
      },
      {
        CITY: "KORSCHENBROICH",
      },
      {
        CITY: "KOSCHING",
      },
      {
        CITY: "KOSSA",
      },
      {
        CITY: "KOTHEL",
      },
      {
        CITY: "KOTTENHEIM",
      },
      {
        CITY: "KOTTERICHEN",
      },
      {
        CITY: "KOTTGEISERING",
      },
      {
        CITY: "KOTTWEILER-SCHWANDEN",
      },
      {
        CITY: "KOTZTING",
      },
      {
        CITY: "KOXHAUSEN",
      },
      {
        CITY: "KRAFT",
      },
      {
        CITY: "KRAFTISRIED",
      },
      {
        CITY: "KRAIBURG AM INN",
      },
      {
        CITY: "KRAILLING",
      },
      {
        CITY: "KRAKOW AM SEE",
      },
      {
        CITY: "KRAMERHOF",
      },
      {
        CITY: "KRANENBURG",
      },
      {
        CITY: "KRANICHFELD",
      },
      {
        CITY: "KRANZBERG",
      },
      {
        CITY: "KRAUCHENWIES",
      },
      {
        CITY: "KRAUPA",
      },
      {
        CITY: "KRAUSCHWITZ",
      },
      {
        CITY: "KRAUSCHWITZ",
      },
      {
        CITY: "KRAUTHEIM",
      },
      {
        CITY: "KRAUTHEIM",
      },
      {
        CITY: "KREFELD",
      },
      {
        CITY: "KREIENSEN",
      },
      {
        CITY: "KREISCHA",
      },
      {
        CITY: "KREMMEN",
      },
      {
        CITY: "KREMPE",
      },
      {
        CITY: "KREMPERHEIDE",
      },
      {
        CITY: "KRESSBRONN AM BODENSEE",
      },
      {
        CITY: "KRETTNACH",
      },
      {
        CITY: "KRETZSCHAU",
      },
      {
        CITY: "KREUZ",
      },
      {
        CITY: "KREUZAU",
      },
      {
        CITY: "KREUZTAL",
      },
      {
        CITY: "KREUZWERTHEIM",
      },
      {
        CITY: "KRICKENBACH",
      },
      {
        CITY: "KRIEBITZSCH",
      },
      {
        CITY: "KRIEBSTEIN",
      },
      {
        CITY: "KRIFTEL",
      },
      {
        CITY: "KRITZMOW",
      },
      {
        CITY: "KRITZOW",
      },
      {
        CITY: "KROLPA",
      },
      {
        CITY: "KROMBACH",
      },
      {
        CITY: "KRONACH",
      },
      {
        CITY: "KRONAU",
      },
      {
        CITY: "KRONBERG",
      },
      {
        CITY: "KRONSHAGEN",
      },
      {
        CITY: "KROPELIN",
      },
      {
        CITY: "KROPP",
      },
      {
        CITY: "KROPPELSHAGEN-FAHRENDORF",
      },
      {
        CITY: "KROPPENSTEDT",
      },
      {
        CITY: "KROSLIN",
      },
      {
        CITY: "KROSTITZ",
      },
      {
        CITY: "KROV",
      },
      {
        CITY: "KRUDEN",
      },
      {
        CITY: "KRUFT",
      },
      {
        CITY: "KRUGSDORF",
      },
      {
        CITY: "KRUGZELL",
      },
      {
        CITY: "KRUMBACH",
      },
      {
        CITY: "KRUMHERMERSDORF",
      },
      {
        CITY: "KRUMMENSEE",
      },
      {
        CITY: "KRUMMESSE",
      },
      {
        CITY: "KRUMPA",
      },
      {
        CITY: "KRUMSTEDT",
      },
      {
        CITY: "KRUN",
      },
      {
        CITY: "KUBSCHUTZ",
      },
      {
        CITY: "KUCHEN",
      },
      {
        CITY: "KUDDEWORDE",
      },
      {
        CITY: "KUENZELSAU",
      },
      {
        CITY: "KUEPS OBERFRANKEN",
      },
      {
        CITY: "KUHARDT",
      },
      {
        CITY: "KUHBACH",
      },
      {
        CITY: "KUHN",
      },
      {
        CITY: "KUHNDORF",
      },
      {
        CITY: "KUHSTEDT",
      },
      {
        CITY: "KUKELS",
      },
      {
        CITY: "KULMBACH",
      },
      {
        CITY: "KULSHEIM",
      },
      {
        CITY: "KULZ",
      },
      {
        CITY: "KUMHAUSEN",
      },
      {
        CITY: "KUMMERFELD",
      },
      {
        CITY: "KUMMERSBRUCK",
      },
      {
        CITY: "KUNREUTH",
      },
      {
        CITY: "KUNZELL",
      },
      {
        CITY: "KUNZING",
      },
      {
        CITY: "KUPFERBERG",
      },
      {
        CITY: "KUPFERZELL",
      },
      {
        CITY: "KUPPENHEIM",
      },
      {
        CITY: "KURNACH",
      },
      {
        CITY: "KURNBACH",
      },
      {
        CITY: "KURORT HARTHA",
      },
      {
        CITY: "KURORT STEINBACH-HALLENBERG",
      },
      {
        CITY: "KURTEN",
      },
      {
        CITY: "KURTSCHEID",
      },
      {
        CITY: "KUSEL",
      },
      {
        CITY: "KUSTERDINGEN",
      },
      {
        CITY: "KUTENHOLZ",
      },
      {
        CITY: "KUTZENHAUSEN",
      },
      {
        CITY: "KYRITZ",
      },
      {
        CITY: "LAABER",
      },
      {
        CITY: "LAAGE",
      },
      {
        CITY: "LAASDORF",
      },
      {
        CITY: "LAATZEN",
      },
      {
        CITY: "LABEJUM",
      },
      {
        CITY: "LABENZ",
      },
      {
        CITY: "LABOE",
      },
      {
        CITY: "LACHEN",
      },
      {
        CITY: "LACHENDORF",
      },
      {
        CITY: "LADBERGEN",
      },
      {
        CITY: "LADENBURG",
      },
      {
        CITY: "LAER",
      },
      {
        CITY: "LAGE",
      },
      {
        CITY: "LAGE",
      },
      {
        CITY: "LAGERDORF",
      },
      {
        CITY: "LAGESBUTTEL",
      },
      {
        CITY: "LAHDEN",
      },
      {
        CITY: "LAHM",
      },
      {
        CITY: "LAHNSTEIN",
      },
      {
        CITY: "LAHR",
      },
      {
        CITY: "LAHR",
      },
      {
        CITY: "LAHRBACH",
      },
      {
        CITY: "LAICHINGEN",
      },
      {
        CITY: "LAIN",
      },
      {
        CITY: "LALENDORF",
      },
      {
        CITY: "LAM",
      },
      {
        CITY: "LAMBERTSBERG",
      },
      {
        CITY: "LAMBRECHT",
      },
      {
        CITY: "LAMBSHEIM",
      },
      {
        CITY: "LAMERDINGEN",
      },
      {
        CITY: "LAMPERTHEIM",
      },
      {
        CITY: "LAMPERTSWALDE",
      },
      {
        CITY: "LAMSPRINGE",
      },
      {
        CITY: "LAMSTEDT",
      },
      {
        CITY: "LANDAU",
      },
      {
        CITY: "LANDAU AN DER ISAR",
      },
      {
        CITY: "LANDENSBERG",
      },
      {
        CITY: "LANDESBERGEN",
      },
      {
        CITY: "LANDRECHT",
      },
      {
        CITY: "LANDSBERG",
      },
      {
        CITY: "LANDSBERG AM LECH",
      },
      {
        CITY: "LANDSCHEID",
      },
      {
        CITY: "LANDSCHEIDE",
      },
      {
        CITY: "LANDSHUT",
      },
      {
        CITY: "LANDSTUHL",
      },
      {
        CITY: "LANGBALLIG",
      },
      {
        CITY: "LANGEBRUCK",
      },
      {
        CITY: "LANGELN",
      },
      {
        CITY: "LANGELSHEIM",
      },
      {
        CITY: "LANGEN",
      },
      {
        CITY: "LANGEN",
      },
      {
        CITY: "LANGENARGEN",
      },
      {
        CITY: "LANGENAU",
      },
      {
        CITY: "LANGENBACH",
      },
      {
        CITY: "LANGENBACH",
      },
      {
        CITY: "LANGENBACH",
      },
      {
        CITY: "LANGENBERG",
      },
      {
        CITY: "LANGENBERNSDORF",
      },
      {
        CITY: "LANGENDORF",
      },
      {
        CITY: "LANGENDORF",
      },
      {
        CITY: "LANGENENSLINGEN",
      },
      {
        CITY: "LANGENFELD",
      },
      {
        CITY: "LANGENFELD",
      },
      {
        CITY: "LANGENHAGEN",
      },
      {
        CITY: "LANGENHORN",
      },
      {
        CITY: "LANGENLONSHEIM",
      },
      {
        CITY: "LANGENMOSEN",
      },
      {
        CITY: "LANGENNEUFNACH",
      },
      {
        CITY: "LANGENPREISING",
      },
      {
        CITY: "LANGENSELBOLD",
      },
      {
        CITY: "LANGENSENDELBACH",
      },
      {
        CITY: "LANGENWEDDINGEN",
      },
      {
        CITY: "LANGENWETZENDORF",
      },
      {
        CITY: "LANGENWOLMSDORF",
      },
      {
        CITY: "LANGENZENN",
      },
      {
        CITY: "LANGEOOG",
      },
      {
        CITY: "LANGERRINGEN",
      },
      {
        CITY: "LANGERWEHE",
      },
      {
        CITY: "LANGEWIESEN",
      },
      {
        CITY: "LANGFURTH",
      },
      {
        CITY: "LANGGONS",
      },
      {
        CITY: "LANGLINGEN",
      },
      {
        CITY: "LANGQUAID",
      },
      {
        CITY: "LANGSTEDT",
      },
      {
        CITY: "LANGSUR",
      },
      {
        CITY: "LANGWASSER",
      },
      {
        CITY: "LANGWEDEL",
      },
      {
        CITY: "LANGWEID",
      },
      {
        CITY: "LANGWEILER",
      },
      {
        CITY: "LANGWIEDEN",
      },
      {
        CITY: "LANGWITZ",
      },
      {
        CITY: "LAPPERSDORF",
      },
      {
        CITY: "LASBEK",
      },
      {
        CITY: "LASSAN",
      },
      {
        CITY: "LASSENTIN",
      },
      {
        CITY: "LASTRUP",
      },
      {
        CITY: "LATHEN",
      },
      {
        CITY: "LAUBACH",
      },
      {
        CITY: "LAUBEN",
      },
      {
        CITY: "LAUBENHEIM",
      },
      {
        CITY: "LAUBUSCH",
      },
      {
        CITY: "LAUCHA",
      },
      {
        CITY: "LAUCHHAMMER",
      },
      {
        CITY: "LAUCHHEIM",
      },
      {
        CITY: "LAUCHRINGEN",
      },
      {
        CITY: "LAUDA-KONIGSHOFEN",
      },
      {
        CITY: "LAUDENBACH",
      },
      {
        CITY: "LAUDERT",
      },
      {
        CITY: "LAUENAU",
      },
      {
        CITY: "LAUENBRUCK",
      },
      {
        CITY: "LAUENBURG",
      },
      {
        CITY: "LAUENHAGEN",
      },
      {
        CITY: "LAUF",
      },
      {
        CITY: "LAUF AN DER PEGNITZ",
      },
      {
        CITY: "LAUFACH",
      },
      {
        CITY: "LAUFEN",
      },
      {
        CITY: "LAUFENBURG",
      },
      {
        CITY: "LAUFFEN AM NECKAR",
      },
      {
        CITY: "LAUGNA",
      },
      {
        CITY: "LAUINGEN",
      },
      {
        CITY: "LAUMERSHEIM",
      },
      {
        CITY: "LAUPHEIM",
      },
      {
        CITY: "LAURENBURG",
      },
      {
        CITY: "LAUSCHA",
      },
      {
        CITY: "LAUSCHIED",
      },
      {
        CITY: "LAUSITZ",
      },
      {
        CITY: "LAUSSNITZ",
      },
      {
        CITY: "LAUTA",
      },
      {
        CITY: "LAUTA",
      },
      {
        CITY: "LAUTENBACH",
      },
      {
        CITY: "LAUTENTHAL",
      },
      {
        CITY: "LAUTER",
      },
      {
        CITY: "LAUTERACH",
      },
      {
        CITY: "LAUTERBACH",
      },
      {
        CITY: "LAUTERBACH/SCHWARZWALD",
      },
      {
        CITY: "LAUTERBURG",
      },
      {
        CITY: "LAUTERECKEN",
      },
      {
        CITY: "LAUTERHOFEN",
      },
      {
        CITY: "LAUTERN",
      },
      {
        CITY: "LAUTERSHEIM",
      },
      {
        CITY: "LAUTERTAL",
      },
      {
        CITY: "LAUTERTAL",
      },
      {
        CITY: "LAUTRACH",
      },
      {
        CITY: "LAUTZENHAUSEN",
      },
      {
        CITY: "LAWALDE",
      },
      {
        CITY: "LEBACH",
      },
      {
        CITY: "LEBRADE",
      },
      {
        CITY: "LEBUS",
      },
      {
        CITY: "LEBUSA",
      },
      {
        CITY: "LECHBRUCK",
      },
      {
        CITY: "LECK",
      },
      {
        CITY: "LEDERHOSE",
      },
      {
        CITY: "LEEGEBRUCH",
      },
      {
        CITY: "LEEHEIM",
      },
      {
        CITY: "LEER",
      },
      {
        CITY: "LEER",
      },
      {
        CITY: "LEEZDORF",
      },
      {
        CITY: "LEEZEN",
      },
      {
        CITY: "LEEZEN",
      },
      {
        CITY: "LEGAU",
      },
      {
        CITY: "LEGDEN",
      },
      {
        CITY: "LEHEN",
      },
      {
        CITY: "LEHESTEN",
      },
      {
        CITY: "LEHMEN",
      },
      {
        CITY: "LEHMKE",
      },
      {
        CITY: "LEHNDORF",
      },
      {
        CITY: "LEHRBERG",
      },
      {
        CITY: "LEHRE",
      },
      {
        CITY: "LEHRENSTEINSFELD",
      },
      {
        CITY: "LEHRTE",
      },
      {
        CITY: "LEIBELBACH",
      },
      {
        CITY: "LEIBERTINGEN",
      },
      {
        CITY: "LEIBLFING",
      },
      {
        CITY: "LEICHLINGEN",
      },
      {
        CITY: "LEIDERSBACH",
      },
      {
        CITY: "LEIFERDE",
      },
      {
        CITY: "LEIMBACH",
      },
      {
        CITY: "LEIMBACH",
      },
      {
        CITY: "LEIMEN",
      },
      {
        CITY: "LEIMEN",
      },
      {
        CITY: "LEIMERSHEIM",
      },
      {
        CITY: "LEINACH",
      },
      {
        CITY: "LEINBURG",
      },
      {
        CITY: "LEINEFELDE",
      },
      {
        CITY: "LEINFELDEN-ECHTERDINGEN",
      },
      {
        CITY: "LEINGARTEN",
      },
      {
        CITY: "LEINSWEILER",
      },
      {
        CITY: "LEIPHEIM",
      },
      {
        CITY: "LEIPZIG",
      },
      {
        CITY: "LEISNIG",
      },
      {
        CITY: "LEITERSHOFEN",
      },
      {
        CITY: "LEITZKAU",
      },
      {
        CITY: "LEIWEN",
      },
      {
        CITY: "LEIZEN",
      },
      {
        CITY: "LEMBERG",
      },
      {
        CITY: "LEMBRUCH",
      },
      {
        CITY: "LEMFORDE",
      },
      {
        CITY: "LEMGO",
      },
      {
        CITY: "LEMWERDER",
      },
      {
        CITY: "LENGDORF",
      },
      {
        CITY: "LENGEDE",
      },
      {
        CITY: "LENGENFELD",
      },
      {
        CITY: "LENGENFELD",
      },
      {
        CITY: "LENGERICH",
      },
      {
        CITY: "LENGERICH",
      },
      {
        CITY: "LENGGRIES",
      },
      {
        CITY: "LENGTHAL",
      },
      {
        CITY: "LENNESTADT",
      },
      {
        CITY: "LENSAHN",
      },
      {
        CITY: "LENTFOHRDEN",
      },
      {
        CITY: "LENTING",
      },
      {
        CITY: "LENZKIRCH",
      },
      {
        CITY: "LEONBERG",
      },
      {
        CITY: "LEONBERG",
      },
      {
        CITY: "LEOPOLDSHAGEN",
      },
      {
        CITY: "LEOPOLDSHOHE",
      },
      {
        CITY: "LERBACH",
      },
      {
        CITY: "LESSIEN",
      },
      {
        CITY: "LETTENREUTH",
      },
      {
        CITY: "LEUBSDORF",
      },
      {
        CITY: "LEUBSDORF",
      },
      {
        CITY: "LEUKERSDORF",
      },
      {
        CITY: "LEUN",
      },
      {
        CITY: "LEUNA",
      },
      {
        CITY: "LEUTENBACH",
      },
      {
        CITY: "LEUTENBACH",
      },
      {
        CITY: "LEUTENBERG",
      },
      {
        CITY: "LEUTERSHAUSEN",
      },
      {
        CITY: "LEUTHEN",
      },
      {
        CITY: "LEUTKIRCH IM ALLGAU",
      },
      {
        CITY: "LEVERKUSEN",
      },
      {
        CITY: "LICH",
      },
      {
        CITY: "LICHTE",
      },
      {
        CITY: "LICHTENAU",
      },
      {
        CITY: "LICHTENAU",
      },
      {
        CITY: "LICHTENAU",
      },
      {
        CITY: "LICHTENAU",
      },
      {
        CITY: "LICHTENAU",
      },
      {
        CITY: "LICHTENBERG",
      },
      {
        CITY: "LICHTENBERG",
      },
      {
        CITY: "LICHTENBROICH",
      },
      {
        CITY: "LICHTENFELS",
      },
      {
        CITY: "LICHTENSTEIN",
      },
      {
        CITY: "LICHTENSTEIN",
      },
      {
        CITY: "LICHTENTANNE",
      },
      {
        CITY: "LICHTENWALD",
      },
      {
        CITY: "LIEBENAU",
      },
      {
        CITY: "LIEBENAU",
      },
      {
        CITY: "LIEBENBURG",
      },
      {
        CITY: "LIEBENSCHEID",
      },
      {
        CITY: "LIEBENSTEIN",
      },
      {
        CITY: "LIEBENWALDE",
      },
      {
        CITY: "LIEBEROSE",
      },
      {
        CITY: "LIEDERBACH",
      },
      {
        CITY: "LIENEN",
      },
      {
        CITY: "LIEPGARTEN",
      },
      {
        CITY: "LIESER",
      },
      {
        CITY: "LIESKAU",
      },
      {
        CITY: "LIETZEN",
      },
      {
        CITY: "LILIENTHAL",
      },
      {
        CITY: "LIMBACH",
      },
      {
        CITY: "LIMBACH",
      },
      {
        CITY: "LIMBACH-OBERFROHNA",
      },
      {
        CITY: "LIMBURG AN DER LAHN",
      },
      {
        CITY: "LIMBURGERHOF",
      },
      {
        CITY: "LINDAU",
      },
      {
        CITY: "LINDE",
      },
      {
        CITY: "LINDEN",
      },
      {
        CITY: "LINDEN",
      },
      {
        CITY: "LINDEN",
      },
      {
        CITY: "LINDEN",
      },
      {
        CITY: "LINDEN",
      },
      {
        CITY: "LINDEN",
      },
      {
        CITY: "LINDENAU",
      },
      {
        CITY: "LINDENBERG IM ALLGAU",
      },
      {
        CITY: "LINDENFELS",
      },
      {
        CITY: "LINDENTHAL",
      },
      {
        CITY: "LINDERN",
      },
      {
        CITY: "LINDEWITT",
      },
      {
        CITY: "LINDHORST",
      },
      {
        CITY: "LINDHORST",
      },
      {
        CITY: "LINDIGSHOF",
      },
      {
        CITY: "LINDLAR",
      },
      {
        CITY: "LINDWEDEL",
      },
      {
        CITY: "LINGEN",
      },
      {
        CITY: "LINGENFELD",
      },
      {
        CITY: "LINGERHAHN",
      },
      {
        CITY: "LINKENBACH",
      },
      {
        CITY: "LINKENHEIM-HOCHSTETTEN",
      },
      {
        CITY: "LINNICH",
      },
      {
        CITY: "LINOW",
      },
      {
        CITY: "LINSBURG",
      },
      {
        CITY: "LINTIG",
      },
      {
        CITY: "LINZ AM RHEIN",
      },
      {
        CITY: "LIPPE",
      },
      {
        CITY: "LIPPORN",
      },
      {
        CITY: "LIPPSTADT",
      },
      {
        CITY: "LISSENDORF",
      },
      {
        CITY: "LISTERFEHRDA",
      },
      {
        CITY: "LITZENDORF",
      },
      {
        CITY: "LOBAU",
      },
      {
        CITY: "LOCH",
      },
      {
        CITY: "LOCHAU",
      },
      {
        CITY: "LOCHGAU",
      },
      {
        CITY: "LOCHHAUSEN",
      },
      {
        CITY: "LOCHUM",
      },
      {
        CITY: "LODDIN",
      },
      {
        CITY: "LODERBURG",
      },
      {
        CITY: "LOFFENAU",
      },
      {
        CITY: "LOFFINGEN",
      },
      {
        CITY: "LOHBERG",
      },
      {
        CITY: "LOHE-RICKELSHOF",
      },
      {
        CITY: "LOHEIDE",
      },
      {
        CITY: "LOHFELDEN",
      },
      {
        CITY: "LOHMAR",
      },
      {
        CITY: "LOHNBERG",
      },
      {
        CITY: "LOHNE",
      },
      {
        CITY: "LOHNE",
      },
      {
        CITY: "LOHNSFELD",
      },
      {
        CITY: "LOHR A. MAIN",
      },
      {
        CITY: "LOHRA",
      },
      {
        CITY: "LOHSA",
      },
      {
        CITY: "LOICHING",
      },
      {
        CITY: "LOITZ",
      },
      {
        CITY: "LOLLAR",
      },
      {
        CITY: "LOLLBACH",
      },
      {
        CITY: "LOMMATZSCH",
      },
      {
        CITY: "LONGKAMP",
      },
      {
        CITY: "LONGUICH",
      },
      {
        CITY: "LONINGEN",
      },
      {
        CITY: "LONNERSTADT",
      },
      {
        CITY: "LONNIG",
      },
      {
        CITY: "LONSEE",
      },
      {
        CITY: "LONSINGEN",
      },
      {
        CITY: "LOOSE",
      },
      {
        CITY: "LORCH",
      },
      {
        CITY: "LORSCH",
      },
      {
        CITY: "LORSCHEID",
      },
      {
        CITY: "LORUP",
      },
      {
        CITY: "LOSHEIM",
      },
      {
        CITY: "LOSHEIM",
      },
      {
        CITY: "LOSSBURG",
      },
      {
        CITY: "LOSSNITZ",
      },
      {
        CITY: "LOSTAU",
      },
      {
        CITY: "LOTTE",
      },
      {
        CITY: "LOTTSTETTEN",
      },
      {
        CITY: "LOTZBEUREN",
      },
      {
        CITY: "LOWENSTEIN",
      },
      {
        CITY: "LOXSTEDT",
      },
      {
        CITY: "LUBBECKE",
      },
      {
        CITY: "LUBBENAU",
      },
      {
        CITY: "LUBESSE",
      },
      {
        CITY: "LUBMIN",
      },
      {
        CITY: "LUBSTORF",
      },
      {
        CITY: "LUBTHEEN",
      },
      {
        CITY: "LUBZ",
      },
      {
        CITY: "LUCHEM",
      },
      {
        CITY: "LUCKA",
      },
      {
        CITY: "LUCKAU",
      },
      {
        CITY: "LUCKENBACH",
      },
      {
        CITY: "LUCKENWALDE",
      },
      {
        CITY: "LUDERSDORF",
      },
      {
        CITY: "LUDERSFELD",
      },
      {
        CITY: "LUDINGHAUSEN",
      },
      {
        CITY: "LUDWIGSBURG",
      },
      {
        CITY: "LUDWIGSFELDE",
      },
      {
        CITY: "LUDWIGSHAFEN",
      },
      {
        CITY: "LUDWIGSHAFEN AM RHEIN",
      },
      {
        CITY: "LUDWIGSHOHE",
      },
      {
        CITY: "LUDWIGSLUST",
      },
      {
        CITY: "LUDWIGSSTADT",
      },
      {
        CITY: "LUEBBEN",
      },
      {
        CITY: "LUECHOW",
      },
      {
        CITY: "LUERDISSEN",
      },
      {
        CITY: "LUFT",
      },
      {
        CITY: "LUFTKURORT ARENDSEE",
      },
      {
        CITY: "LUGAU",
      },
      {
        CITY: "LUGDE",
      },
      {
        CITY: "LUHDEN",
      },
      {
        CITY: "LUHE-WILDENAU",
      },
      {
        CITY: "LUNNE",
      },
      {
        CITY: "LUNZENAU",
      },
      {
        CITY: "LUPBURG",
      },
      {
        CITY: "LURSCHAU",
      },
      {
        CITY: "LUTAU",
      },
      {
        CITY: "LUTJENBURG",
      },
      {
        CITY: "LUTJENSEE",
      },
      {
        CITY: "LUTTER AM BARENBERGE",
      },
      {
        CITY: "LUTTGENRODE",
      },
      {
        CITY: "LUTTUM",
      },
      {
        CITY: "LUTZ",
      },
      {
        CITY: "LUTZELBACH",
      },
      {
        CITY: "LUTZEN",
      },
      {
        CITY: "LUTZERATH",
      },
      {
        CITY: "LUTZINGEN",
      },
      {
        CITY: "LYCHEN",
      },
      {
        CITY: "LYNOW",
      },
      {
        CITY: "MAASHOLM",
      },
      {
        CITY: "MACHERN",
      },
      {
        CITY: "MACHTOLSHEIM",
      },
      {
        CITY: "MACKEN",
      },
      {
        CITY: "MACKENBACH",
      },
      {
        CITY: "MAGDALA",
      },
      {
        CITY: "MAGDEBURG",
      },
      {
        CITY: "MAGSTADT",
      },
      {
        CITY: "MAHLBERG",
      },
      {
        CITY: "MAHLOW",
      },
      {
        CITY: "MAHNDORF",
      },
      {
        CITY: "MAIERHOFEN",
      },
      {
        CITY: "MAIKAMMER",
      },
      {
        CITY: "MAILING",
      },
      {
        CITY: "MAINASCHAFF",
      },
      {
        CITY: "MAINBERNHEIM",
      },
      {
        CITY: "MAINBURG",
      },
      {
        CITY: "MAINHARDT",
      },
      {
        CITY: "MAINLEUS",
      },
      {
        CITY: "MAINSTOCKHEIM",
      },
      {
        CITY: "MAINTAL",
      },
      {
        CITY: "MAINZ",
      },
      {
        CITY: "MAISACH",
      },
      {
        CITY: "MAITENBETH",
      },
      {
        CITY: "MALBERG",
      },
      {
        CITY: "MALBORN",
      },
      {
        CITY: "MALCHIN",
      },
      {
        CITY: "MALCHOW",
      },
      {
        CITY: "MALENTE",
      },
      {
        CITY: "MALGERSDORF",
      },
      {
        CITY: "MALLERSDORF-PFAFFENBERG",
      },
      {
        CITY: "MALLIN",
      },
      {
        CITY: "MALSBURG",
      },
      {
        CITY: "MALSCH",
      },
      {
        CITY: "MALSCH",
      },
      {
        CITY: "MALSCHWITZ",
      },
      {
        CITY: "MALSFELD",
      },
      {
        CITY: "MALTERDINGEN",
      },
      {
        CITY: "MAMMENDORF",
      },
      {
        CITY: "MAMMING",
      },
      {
        CITY: "MANCHING",
      },
      {
        CITY: "MANDEL",
      },
      {
        CITY: "MANDERN",
      },
      {
        CITY: "MANNEBACH",
      },
      {
        CITY: "MANNHEIM",
      },
      {
        CITY: "MANNICHSWALDE",
      },
      {
        CITY: "MANSFELD",
      },
      {
        CITY: "MANTEL",
      },
      {
        CITY: "MARBACH AM NECKAR",
      },
      {
        CITY: "MARBURG",
      },
      {
        CITY: "MARCH",
      },
      {
        CITY: "MARGARETHEN",
      },
      {
        CITY: "MARGERTSHAUSEN",
      },
      {
        CITY: "MARGETSHOCHHEIM",
      },
      {
        CITY: "MARIAPOSCHING",
      },
      {
        CITY: "MARIENBERG",
      },
      {
        CITY: "MARIENBURG",
      },
      {
        CITY: "MARIENFELD",
      },
      {
        CITY: "MARIENHAFE",
      },
      {
        CITY: "MARIENHAGEN",
      },
      {
        CITY: "MARIENHAUSEN",
      },
      {
        CITY: "MARIENHEIDE",
      },
      {
        CITY: "MARIENRACHDORF",
      },
      {
        CITY: "MARIENSTEIN",
      },
      {
        CITY: "MARIENTAL",
      },
      {
        CITY: "MARING-NOVIAND",
      },
      {
        CITY: "MARK",
      },
      {
        CITY: "MARKDORF",
      },
      {
        CITY: "MARKERSBACH",
      },
      {
        CITY: "MARKGRONINGEN",
      },
      {
        CITY: "MARKKLEEBERG",
      },
      {
        CITY: "MARKLKOFEN",
      },
      {
        CITY: "MARKLOHE",
      },
      {
        CITY: "MARKNEUKIRCHEN",
      },
      {
        CITY: "MARKRANSTADT",
      },
      {
        CITY: "MARKSUHL",
      },
      {
        CITY: "MARKT",
      },
      {
        CITY: "MARKT",
      },
      {
        CITY: "MARKT EINERSHEIM",
      },
      {
        CITY: "MARKT ERLBACH",
      },
      {
        CITY: "MARKT INDERSDORF",
      },
      {
        CITY: "MARKT RETTENBACH",
      },
      {
        CITY: "MARKT SCHWABEN",
      },
      {
        CITY: "MARKT WALD",
      },
      {
        CITY: "MARKTBREIT",
      },
      {
        CITY: "MARKTGRAITZ",
      },
      {
        CITY: "MARKTHEIDENFELD",
      },
      {
        CITY: "MARKTL",
      },
      {
        CITY: "MARKTLEUGAST",
      },
      {
        CITY: "MARKTLEUTHEN",
      },
      {
        CITY: "MARKTOBERDORF",
      },
      {
        CITY: "MARKTREDWITZ",
      },
      {
        CITY: "MARKTRODACH",
      },
      {
        CITY: "MARKTSCHELLENBERG",
      },
      {
        CITY: "MARKTSCHORGAST",
      },
      {
        CITY: "MARL",
      },
      {
        CITY: "MARLOW",
      },
      {
        CITY: "MARNE",
      },
      {
        CITY: "MARNHEIM",
      },
      {
        CITY: "MARPINGEN",
      },
      {
        CITY: "MARQUARTSTEIN",
      },
      {
        CITY: "MARSBERG",
      },
      {
        CITY: "MARSCHACHT",
      },
      {
        CITY: "MARSCHALL",
      },
      {
        CITY: "MARSDORF",
      },
      {
        CITY: "MARTENSRADE",
      },
      {
        CITY: "MARTFELD",
      },
      {
        CITY: "MARTINSRIED",
      },
      {
        CITY: "MARWITZ",
      },
      {
        CITY: "MARXEN",
      },
      {
        CITY: "MARXGRUN",
      },
      {
        CITY: "MARXHEIM",
      },
      {
        CITY: "MARZLING",
      },
      {
        CITY: "MASCHEN",
      },
      {
        CITY: "MASELHEIM",
      },
      {
        CITY: "MASSBACH",
      },
      {
        CITY: "MASSENBACHHAUSEN",
      },
      {
        CITY: "MASSENHAUSEN",
      },
      {
        CITY: "MASSENHEIM",
      },
      {
        CITY: "MASSING",
      },
      {
        CITY: "MASTERSHAUSEN",
      },
      {
        CITY: "MASTHOLTE",
      },
      {
        CITY: "MATTSTEDT",
      },
      {
        CITY: "MAUER",
      },
      {
        CITY: "MAUER",
      },
      {
        CITY: "MAUERSTETTEN",
      },
      {
        CITY: "MAULBRONN",
      },
      {
        CITY: "MAULBURG",
      },
      {
        CITY: "MAUTH",
      },
      {
        CITY: "MAXDORF",
      },
      {
        CITY: "MAXHUTTE-HAIDHOF",
      },
      {
        CITY: "MAXIMILIAN",
      },
      {
        CITY: "MAYEN",
      },
      {
        CITY: "MECHELGRUN",
      },
      {
        CITY: "MECHELRODA",
      },
      {
        CITY: "MECHENRIED",
      },
      {
        CITY: "MECHERNICH",
      },
      {
        CITY: "MECKENBACH",
      },
      {
        CITY: "MECKENBEUREN",
      },
      {
        CITY: "MECKENHEIM",
      },
      {
        CITY: "MECKENHEIM",
      },
      {
        CITY: "MECKESHEIM",
      },
      {
        CITY: "MECKLENBURG",
      },
      {
        CITY: "MEDARD",
      },
      {
        CITY: "MEDEBACH",
      },
      {
        CITY: "MEDELBY",
      },
      {
        CITY: "MEDOW",
      },
      {
        CITY: "MEEDER",
      },
      {
        CITY: "MEERANE",
      },
      {
        CITY: "MEERBECK",
      },
      {
        CITY: "MEERBUSCH",
      },
      {
        CITY: "MEERSBURG",
      },
      {
        CITY: "MEESIGER",
      },
      {
        CITY: "MEGESHEIM",
      },
      {
        CITY: "MEHLBACH",
      },
      {
        CITY: "MEHLINGEN",
      },
      {
        CITY: "MEHLMEISEL",
      },
      {
        CITY: "MEHMELS",
      },
      {
        CITY: "MEHR",
      },
      {
        CITY: "MEHRING",
      },
      {
        CITY: "MEHRING",
      },
      {
        CITY: "MEINE",
      },
      {
        CITY: "MEINERSEN",
      },
      {
        CITY: "MEINERZHAGEN",
      },
      {
        CITY: "MEININGEN",
      },
      {
        CITY: "MEINSDORF",
      },
      {
        CITY: "MEISENHEIM",
      },
      {
        CITY: "MEISSEN",
      },
      {
        CITY: "MEISSENHEIM",
      },
      {
        CITY: "MEITINGEN",
      },
      {
        CITY: "MELBECK",
      },
      {
        CITY: "MELCHOW",
      },
      {
        CITY: "MELDORF",
      },
      {
        CITY: "MELLE",
      },
      {
        CITY: "MELLENBACH-GLASBACH",
      },
      {
        CITY: "MELLINGEN",
      },
      {
        CITY: "MELLRICHSTADT",
      },
      {
        CITY: "MELSBACH",
      },
      {
        CITY: "MELSDORF",
      },
      {
        CITY: "MELSUNGEN",
      },
      {
        CITY: "MEMMELSDORF",
      },
      {
        CITY: "MEMMINGEN",
      },
      {
        CITY: "MEMMINGERBERG",
      },
      {
        CITY: "MENDEN",
      },
      {
        CITY: "MENDIG",
      },
      {
        CITY: "MENGEN",
      },
      {
        CITY: "MENGERSCHIED",
      },
      {
        CITY: "MENGERSGEREUTH-HAMMERN",
      },
      {
        CITY: "MENGERSKIRCHEN",
      },
      {
        CITY: "MENGKOFEN",
      },
      {
        CITY: "MENSLAGE",
      },
      {
        CITY: "MEPPEN",
      },
      {
        CITY: "MERCHING",
      },
      {
        CITY: "MERCHWEILER",
      },
      {
        CITY: "MERDINGEN",
      },
      {
        CITY: "MERENBERG",
      },
      {
        CITY: "MERING",
      },
      {
        CITY: "MERKELBACH",
      },
      {
        CITY: "MERKENDORF",
      },
      {
        CITY: "MERKERS",
      },
      {
        CITY: "MERKLINGEN",
      },
      {
        CITY: "MERSCHWITZ",
      },
      {
        CITY: "MERSEBURG",
      },
      {
        CITY: "MERSHEIM",
      },
      {
        CITY: "MERTENDORF",
      },
      {
        CITY: "MERTINGEN",
      },
      {
        CITY: "MERTLOCH",
      },
      {
        CITY: "MERXHEIM",
      },
      {
        CITY: "MERZALBEN",
      },
      {
        CITY: "MERZEN",
      },
      {
        CITY: "MERZENICH",
      },
      {
        CITY: "MERZHAUSEN",
      },
      {
        CITY: "MERZIG",
      },
      {
        CITY: "MESCHEDE",
      },
      {
        CITY: "MESSEL",
      },
      {
        CITY: "MESSKIRCH",
      },
      {
        CITY: "MESSSTETTEN",
      },
      {
        CITY: "METELEN",
      },
      {
        CITY: "METJENDORF",
      },
      {
        CITY: "METTEN",
      },
      {
        CITY: "METTENHEIM",
      },
      {
        CITY: "METTERICH",
      },
      {
        CITY: "METTINGEN",
      },
      {
        CITY: "METTLACH",
      },
      {
        CITY: "METTMANN",
      },
      {
        CITY: "METZINGEN",
      },
      {
        CITY: "METZLOS-GEHAG",
      },
      {
        CITY: "MEUDT",
      },
      {
        CITY: "MEURO",
      },
      {
        CITY: "MEUSELWITZ",
      },
      {
        CITY: "MEYN",
      },
      {
        CITY: "MICHELAU",
      },
      {
        CITY: "MICHELBACH AN DER BILZ",
      },
      {
        CITY: "MICHELFELD",
      },
      {
        CITY: "MICHELSTADT",
      },
      {
        CITY: "MICHENDORF",
      },
      {
        CITY: "MICKHAUSEN",
      },
      {
        CITY: "MIDLUM",
      },
      {
        CITY: "MIEHLEN",
      },
      {
        CITY: "MIELENHAUSEN",
      },
      {
        CITY: "MIELKENDORF",
      },
      {
        CITY: "MIELLEN",
      },
      {
        CITY: "MIESBACH",
      },
      {
        CITY: "MIESTE",
      },
      {
        CITY: "MIETINGEN",
      },
      {
        CITY: "MIHLA",
      },
      {
        CITY: "MILBERTSHOFEN",
      },
      {
        CITY: "MILDENAU",
      },
      {
        CITY: "MILDSTEDT",
      },
      {
        CITY: "MILOW",
      },
      {
        CITY: "MILTENBERG",
      },
      {
        CITY: "MILZAU",
      },
      {
        CITY: "MINDELHEIM",
      },
      {
        CITY: "MINDELSTETTEN",
      },
      {
        CITY: "MINDEN",
      },
      {
        CITY: "MINFELD",
      },
      {
        CITY: "MINSEN",
      },
      {
        CITY: "MINTRACHING",
      },
      {
        CITY: "MIROW",
      },
      {
        CITY: "MISSEN-WILHAMS",
      },
      {
        CITY: "MISTELBACH",
      },
      {
        CITY: "MISTELGAU",
      },
      {
        CITY: "MITTBACH",
      },
      {
        CITY: "MITTEGROSSEFEHN",
      },
      {
        CITY: "MITTELBACH",
      },
      {
        CITY: "MITTELBERG",
      },
      {
        CITY: "MITTELBIBERACH",
      },
      {
        CITY: "MITTELESCHENBACH",
      },
      {
        CITY: "MITTELHERWIGSDORF",
      },
      {
        CITY: "MITTELNEUFNACH",
      },
      {
        CITY: "MITTELNKIRCHEN",
      },
      {
        CITY: "MITTELROT",
      },
      {
        CITY: "MITTELSAIDA",
      },
      {
        CITY: "MITTELSTENWEILER",
      },
      {
        CITY: "MITTELSTETTEN",
      },
      {
        CITY: "MITTELSTREU",
      },
      {
        CITY: "MITTENAAR",
      },
      {
        CITY: "MITTENWALD",
      },
      {
        CITY: "MITTENWALDE",
      },
      {
        CITY: "MITTERFELDEN",
      },
      {
        CITY: "MITTERFELS",
      },
      {
        CITY: "MITTERSTETTEN",
      },
      {
        CITY: "MITTERTEICH",
      },
      {
        CITY: "MITTWEIDA",
      },
      {
        CITY: "MITWITZ",
      },
      {
        CITY: "MIXDORF",
      },
      {
        CITY: "MOCHAU",
      },
      {
        CITY: "MOCHENWANGEN",
      },
      {
        CITY: "MOCKERN",
      },
      {
        CITY: "MOCKMUHL",
      },
      {
        CITY: "MOCKREHNA",
      },
      {
        CITY: "MOERS",
      },
      {
        CITY: "MOGENDORF",
      },
      {
        CITY: "MOGGLINGEN",
      },
      {
        CITY: "MOGLINGEN",
      },
      {
        CITY: "MOHLSDORF",
      },
      {
        CITY: "MOHNESEE",
      },
      {
        CITY: "MOHREN",
      },
      {
        CITY: "MOHRENDORF",
      },
      {
        CITY: "MOHRKIRCH",
      },
      {
        CITY: "MOISBURG",
      },
      {
        CITY: "MOLBERGEN",
      },
      {
        CITY: "MOLFSEE",
      },
      {
        CITY: "MOLLENHAGEN",
      },
      {
        CITY: "MOLLN",
      },
      {
        CITY: "MOLSCHOW",
      },
      {
        CITY: "MOLSHEIM",
      },
      {
        CITY: "MOMBRIS",
      },
      {
        CITY: "MOMLINGEN",
      },
      {
        CITY: "MOMMENHEIM",
      },
      {
        CITY: "MONCHBERG",
      },
      {
        CITY: "MONCHSDEGGINGEN",
      },
      {
        CITY: "MONCHSROTH",
      },
      {
        CITY: "MONCHWEILER",
      },
      {
        CITY: "MONHEIM",
      },
      {
        CITY: "MONHEIM AM RHEIN",
      },
      {
        CITY: "MONKEBERG",
      },
      {
        CITY: "MONREAL",
      },
      {
        CITY: "MONSCHAU",
      },
      {
        CITY: "MONSHEIM",
      },
      {
        CITY: "MONSHEIM",
      },
      {
        CITY: "MONTABAUR",
      },
      {
        CITY: "MONZELFELD",
      },
      {
        CITY: "MONZINGEN",
      },
      {
        CITY: "MOOR",
      },
      {
        CITY: "MOORENWEIS",
      },
      {
        CITY: "MOOS",
      },
      {
        CITY: "MOOS",
      },
      {
        CITY: "MOOSACH",
      },
      {
        CITY: "MOOSBURG",
      },
      {
        CITY: "MOOSINNING",
      },
      {
        CITY: "MOOSTHENNING",
      },
      {
        CITY: "MORBACH",
      },
      {
        CITY: "MORENHOVEN",
      },
      {
        CITY: "MORFELDEN-WALLDORF",
      },
      {
        CITY: "MORINGEN",
      },
      {
        CITY: "MORITZ",
      },
      {
        CITY: "MORITZBURG",
      },
      {
        CITY: "MORLENBACH",
      },
      {
        CITY: "MORSBACH",
      },
      {
        CITY: "MORSCHEID",
      },
      {
        CITY: "MORSCHHEIM",
      },
      {
        CITY: "MORSCHIED",
      },
      {
        CITY: "MORSFELD",
      },
      {
        CITY: "MORSHAUSEN",
      },
      {
        CITY: "MORSLEBEN",
      },
      {
        CITY: "MORSLINGEN",
      },
      {
        CITY: "MOSBACH",
      },
      {
        CITY: "MOSCHENFELD",
      },
      {
        CITY: "MOSER",
      },
      {
        CITY: "MOSSINGEN",
      },
      {
        CITY: "MOTTGERS",
      },
      {
        CITY: "MOTTINGEN",
      },
      {
        CITY: "MOTZINGEN",
      },
      {
        CITY: "MOYLAND",
      },
      {
        CITY: "MUCH",
      },
      {
        CITY: "MUCHELN",
      },
      {
        CITY: "MUDAU",
      },
      {
        CITY: "MUDEN",
      },
      {
        CITY: "MUDERSBACH",
      },
      {
        CITY: "MUDERSHAUSEN",
      },
      {
        CITY: "MUEHLDORF",
      },
      {
        CITY: "MUEHLHEIM AM MAIN",
      },
      {
        CITY: "MUGELN",
      },
      {
        CITY: "MUGGENDORF",
      },
      {
        CITY: "MUGGENSTURM",
      },
      {
        CITY: "MUHL ROSIN",
      },
      {
        CITY: "MUHLACKER",
      },
      {
        CITY: "MUHLAU",
      },
      {
        CITY: "MUHLE",
      },
      {
        CITY: "MUHLEN",
      },
      {
        CITY: "MUHLHAUSEN",
      },
      {
        CITY: "MUHLHAUSEN",
      },
      {
        CITY: "MUHLHAUSEN",
      },
      {
        CITY: "MUHLHAUSEN-EHINGEN",
      },
      {
        CITY: "MUHLHEIM AN DER DONAU",
      },
      {
        CITY: "MUHLTAL",
      },
      {
        CITY: "MUHLTROFF",
      },
      {
        CITY: "MUHR AM SEE",
      },
      {
        CITY: "MULDA",
      },
      {
        CITY: "MULDENHAMMER",
      },
      {
        CITY: "MULDENSTEIN",
      },
      {
        CITY: "MULFINGEN",
      },
      {
        CITY: "MULHEIM-KARLICH",
      },
      {
        CITY: "MULLROSE",
      },
      {
        CITY: "MULSEN",
      },
      {
        CITY: "MULSUM",
      },
      {
        CITY: "MUNCHAURACH",
      },
      {
        CITY: "MUNCHBERG",
      },
      {
        CITY: "MUNCHEBERG",
      },
      {
        CITY: "MUNCHENBERNSDORF",
      },
      {
        CITY: "MUNCHHAUSEN",
      },
      {
        CITY: "MUNCHHAUSEN",
      },
      {
        CITY: "MUNCHSMUNSTER",
      },
      {
        CITY: "MUNCHSTEINACH",
      },
      {
        CITY: "MUNCHWEILER AM KLINGBACH",
      },
      {
        CITY: "MUNCHWEILER AN DER ALSENZ",
      },
      {
        CITY: "MUNDERKINGEN",
      },
      {
        CITY: "MUNDERSBACH",
      },
      {
        CITY: "MUNICH",
      },
      {
        CITY: "MUNICH",
      },
      {
        CITY: "MUNNERSTADT",
      },
      {
        CITY: "MUNSING",
      },
      {
        CITY: "MUNSINGEN",
      },
      {
        CITY: "MUNSTER",
      },
      {
        CITY: "MUNSTER",
      },
      {
        CITY: "MUNSTER",
      },
      {
        CITY: "MUNSTER-SARMSHEIM",
      },
      {
        CITY: "MUNSTERDORF",
      },
      {
        CITY: "MUNSTERMAIFELD",
      },
      {
        CITY: "MUNZENBERG",
      },
      {
        CITY: "MUNZINGEN",
      },
      {
        CITY: "MURCHIN",
      },
      {
        CITY: "MURG",
      },
      {
        CITY: "MURLENBACH",
      },
      {
        CITY: "MURNAU AM STAFFELSEE",
      },
      {
        CITY: "MURR",
      },
      {
        CITY: "MURRHARDT",
      },
      {
        CITY: "MUSCHENBACH",
      },
      {
        CITY: "MUTLANGEN",
      },
      {
        CITY: "MUTTERSCHIED",
      },
      {
        CITY: "MUTTERSTADT",
      },
      {
        CITY: "MUTZENICH",
      },
      {
        CITY: "MUTZSCHEN",
      },
      {
        CITY: "MYLAU",
      },
      {
        CITY: "NABBURG",
      },
      {
        CITY: "NACHRODT-WIBLINGWERDE",
      },
      {
        CITY: "NACHTERSTEDT",
      },
      {
        CITY: "NACHTSHEIM",
      },
      {
        CITY: "NACKENHEIM",
      },
      {
        CITY: "NAGEL",
      },
      {
        CITY: "NAGOLD",
      },
      {
        CITY: "NAHE",
      },
      {
        CITY: "NAHRENDORF",
      },
      {
        CITY: "NAILA",
      },
      {
        CITY: "NAKENSDORF",
      },
      {
        CITY: "NALBACH",
      },
      {
        CITY: "NAMBORN",
      },
      {
        CITY: "NANDLSTADT",
      },
      {
        CITY: "NANZWEILER",
      },
      {
        CITY: "NASSAU",
      },
      {
        CITY: "NASSENFELS",
      },
      {
        CITY: "NASTATTEN",
      },
      {
        CITY: "NATTENHEIM",
      },
      {
        CITY: "NATTHEIM",
      },
      {
        CITY: "NAUEN",
      },
      {
        CITY: "NAUENDORF",
      },
      {
        CITY: "NAUHEIM",
      },
      {
        CITY: "NAUMBURG",
      },
      {
        CITY: "NAUMBURG",
      },
      {
        CITY: "NAUNHEIM",
      },
      {
        CITY: "NAUNHOF",
      },
      {
        CITY: "NAUORT",
      },
      {
        CITY: "NAURATH",
      },
      {
        CITY: "NEBRA",
      },
      {
        CITY: "NEBRINGEN",
      },
      {
        CITY: "NECKARBISCHOFSHEIM",
      },
      {
        CITY: "NECKARGEMUND",
      },
      {
        CITY: "NECKARGERACH",
      },
      {
        CITY: "NECKARREMS",
      },
      {
        CITY: "NECKARSTEINACH",
      },
      {
        CITY: "NECKARSULM",
      },
      {
        CITY: "NECKARTAILFINGEN",
      },
      {
        CITY: "NECKARTENZLINGEN",
      },
      {
        CITY: "NECKARWEIHINGEN",
      },
      {
        CITY: "NECKARWESTHEIM",
      },
      {
        CITY: "NECKARZIMMERN",
      },
      {
        CITY: "NEEHAUSEN",
      },
      {
        CITY: "NEENSTETTEN",
      },
      {
        CITY: "NEERMOOR",
      },
      {
        CITY: "NEETZE",
      },
      {
        CITY: "NEGAST",
      },
      {
        CITY: "NEHREN",
      },
      {
        CITY: "NEIDENFELS",
      },
      {
        CITY: "NEIDENSTEIN",
      },
      {
        CITY: "NEIDLINGEN",
      },
      {
        CITY: "NEITERSEN",
      },
      {
        CITY: "NELLINGEN",
      },
      {
        CITY: "NENNHAUSEN",
      },
      {
        CITY: "NENNIG",
      },
      {
        CITY: "NENNSLINGEN",
      },
      {
        CITY: "NENTERSHAUSEN",
      },
      {
        CITY: "NERCHAU",
      },
      {
        CITY: "NERESHEIM",
      },
      {
        CITY: "NEROTH",
      },
      {
        CITY: "NERSINGEN",
      },
      {
        CITY: "NESSELWANG",
      },
      {
        CITY: "NETPHEN",
      },
      {
        CITY: "NETTERSHEIM",
      },
      {
        CITY: "NETTETAL",
      },
      {
        CITY: "NETZE",
      },
      {
        CITY: "NETZSCHKAU",
      },
      {
        CITY: "NEU DARCHAU",
      },
      {
        CITY: "NEU DUVENSTEDT",
      },
      {
        CITY: "NEU ISENBURG",
      },
      {
        CITY: "NEU KALISS",
      },
      {
        CITY: "NEU SANITZ",
      },
      {
        CITY: "NEU VITENSE",
      },
      {
        CITY: "NEU WULMSTORF",
      },
      {
        CITY: "NEU ZAUCHE",
      },
      {
        CITY: "NEU-ANSPACH",
      },
      {
        CITY: "NEU-ULM",
      },
      {
        CITY: "NEUBECKUM",
      },
      {
        CITY: "NEUBERG",
      },
      {
        CITY: "NEUBERG",
      },
      {
        CITY: "NEUBEUERN",
      },
      {
        CITY: "NEUBIBERG",
      },
      {
        CITY: "NEUBORGER",
      },
      {
        CITY: "NEUBRANDENBURG",
      },
      {
        CITY: "NEUBRUNN",
      },
      {
        CITY: "NEUBUKOW",
      },
      {
        CITY: "NEUBULACH",
      },
      {
        CITY: "NEUBURG",
      },
      {
        CITY: "NEUBURG",
      },
      {
        CITY: "NEUBURG AN DER DONAU",
      },
      {
        CITY: "NEUDIETENDORF",
      },
      {
        CITY: "NEUDORF",
      },
      {
        CITY: "NEUDROSSENFELD",
      },
      {
        CITY: "NEUENBROOK",
      },
      {
        CITY: "NEUENBURG AM RHEIN",
      },
      {
        CITY: "NEUENDETTELSAU",
      },
      {
        CITY: "NEUENDORF",
      },
      {
        CITY: "NEUENDORF",
      },
      {
        CITY: "NEUENDORF",
      },
      {
        CITY: "NEUENGORS",
      },
      {
        CITY: "NEUENHAGEN",
      },
      {
        CITY: "NEUENHAUS",
      },
      {
        CITY: "NEUENKIRCHEN",
      },
      {
        CITY: "NEUENKIRCHEN",
      },
      {
        CITY: "NEUENKIRCHEN",
      },
      {
        CITY: "NEUENKIRCHEN",
      },
      {
        CITY: "NEUENKIRCHEN",
      },
      {
        CITY: "NEUENKIRCHEN",
      },
      {
        CITY: "NEUENMARKT",
      },
      {
        CITY: "NEUENRADE",
      },
      {
        CITY: "NEUENSTADT AM KOCHER",
      },
      {
        CITY: "NEUENSTEIN",
      },
      {
        CITY: "NEUENTAL",
      },
      {
        CITY: "NEUERBURG",
      },
      {
        CITY: "NEUFAHRN BEI FREISING",
      },
      {
        CITY: "NEUFARN",
      },
      {
        CITY: "NEUFFEN",
      },
      {
        CITY: "NEUFRA",
      },
      {
        CITY: "NEUFRACH",
      },
      {
        CITY: "NEUFRAUNHOFEN",
      },
      {
        CITY: "NEUGERSDORF",
      },
      {
        CITY: "NEUHARDENBERG",
      },
      {
        CITY: "NEUHAUS",
      },
      {
        CITY: "NEUHAUS",
      },
      {
        CITY: "NEUHAUS AM INN",
      },
      {
        CITY: "NEUHAUS AM RENNWEG",
      },
      {
        CITY: "NEUHAUS-SCHIERSCHNITZ",
      },
      {
        CITY: "NEUHAUSEL",
      },
      {
        CITY: "NEUHAUSEN",
      },
      {
        CITY: "NEUHAUSEN",
      },
      {
        CITY: "NEUHAUSEN",
      },
      {
        CITY: "NEUHAUSEN AUF DEN FILDERN",
      },
      {
        CITY: "NEUHAUSEN OB ECK",
      },
      {
        CITY: "NEUHEMSBACH",
      },
      {
        CITY: "NEUHOF",
      },
      {
        CITY: "NEUHOF",
      },
      {
        CITY: "NEUHOF",
      },
      {
        CITY: "NEUHOFEN",
      },
      {
        CITY: "NEUHUTTEN",
      },
      {
        CITY: "NEUHUTTEN",
      },
      {
        CITY: "NEUKAMPERFEHN",
      },
      {
        CITY: "NEUKEFERLOH",
      },
      {
        CITY: "NEUKIERITZSCH",
      },
      {
        CITY: "NEUKIRCH",
      },
      {
        CITY: "NEUKIRCH/LAUSITZ",
      },
      {
        CITY: "NEUKIRCHEN",
      },
      {
        CITY: "NEUKIRCHEN",
      },
      {
        CITY: "NEUKIRCHEN",
      },
      {
        CITY: "NEUKIRCHEN AM TEISENBERG",
      },
      {
        CITY: "NEUKIRCHEN-VLUYN",
      },
      {
        CITY: "NEUKLOSTER",
      },
      {
        CITY: "NEULEININGEN",
      },
      {
        CITY: "NEULUSSHEIM",
      },
      {
        CITY: "NEUMAGEN-DHRON",
      },
      {
        CITY: "NEUMARK",
      },
      {
        CITY: "NEUMARKT IN DER OBERPFALZ",
      },
      {
        CITY: "NEUMARKT-SANKT VEIT",
      },
      {
        CITY: "NEUNBURG VORM WALD",
      },
      {
        CITY: "NEUNDORF",
      },
      {
        CITY: "NEUNDORF",
      },
      {
        CITY: "NEUNHEILINGEN",
      },
      {
        CITY: "NEUNKIRCHEN",
      },
      {
        CITY: "NEUNKIRCHEN",
      },
      {
        CITY: "NEUNKIRCHEN",
      },
      {
        CITY: "NEUNKIRCHEN",
      },
      {
        CITY: "NEUNKIRCHEN",
      },
      {
        CITY: "NEUNKIRCHEN AM SAND",
      },
      {
        CITY: "NEUOTTING",
      },
      {
        CITY: "NEUPETERSHAIN",
      },
      {
        CITY: "NEUPOTZ",
      },
      {
        CITY: "NEURAVENSBURG",
      },
      {
        CITY: "NEUREICHENAU",
      },
      {
        CITY: "NEURIED",
      },
      {
        CITY: "NEURIED",
      },
      {
        CITY: "NEURUPPIN",
      },
      {
        CITY: "NEUSALZA-SPREMBERG",
      },
      {
        CITY: "NEUSASS",
      },
      {
        CITY: "NEUSCHONAU",
      },
      {
        CITY: "NEUSITZ",
      },
      {
        CITY: "NEUSORG",
      },
      {
        CITY: "NEUSS",
      },
      {
        CITY: "NEUSTADT",
      },
      {
        CITY: "NEUSTADT",
      },
      {
        CITY: "NEUSTADT",
      },
      {
        CITY: "NEUSTADT (ORLA)",
      },
      {
        CITY: "NEUSTADT AM RUBENBERGE",
      },
      {
        CITY: "NEUSTADT AN DER AISCH",
      },
      {
        CITY: "NEUSTADT AN DER ORLA",
      },
      {
        CITY: "NEUSTADT AN DER WALDNAAB",
      },
      {
        CITY: "NEUSTADT BEI COBURG",
      },
      {
        CITY: "NEUSTADT IN HOLSTEIN",
      },
      {
        CITY: "NEUSTADT IN SACHSEN",
      },
      {
        CITY: "NEUSTADT-GLEWE",
      },
      {
        CITY: "NEUSTADT/WESTERWALD",
      },
      {
        CITY: "NEUSTRELITZ",
      },
      {
        CITY: "NEUTRAUBLING",
      },
      {
        CITY: "NEUTREBBIN",
      },
      {
        CITY: "NEUTZ-LETTEWITZ",
      },
      {
        CITY: "NEUWEILER",
      },
      {
        CITY: "NEUWERK",
      },
      {
        CITY: "NEUWIED",
      },
      {
        CITY: "NEUWITTENBEK",
      },
      {
        CITY: "NEUZELLE",
      },
      {
        CITY: "NEVERIN",
      },
      {
        CITY: "NEVERSDORF",
      },
      {
        CITY: "NEWEL",
      },
      {
        CITY: "NICKENICH",
      },
      {
        CITY: "NIDDA",
      },
      {
        CITY: "NIDDERAU",
      },
      {
        CITY: "NIDEGGEN",
      },
      {
        CITY: "NIEBULL",
      },
      {
        CITY: "NIEDENSTEIN",
      },
      {
        CITY: "NIEDER OHMEN",
      },
      {
        CITY: "NIEDER-BEERBACH",
      },
      {
        CITY: "NIEDER-OLM",
      },
      {
        CITY: "NIEDERAICHBACH",
      },
      {
        CITY: "NIEDERALBEN",
      },
      {
        CITY: "NIEDERALSEN",
      },
      {
        CITY: "NIEDERALTEICH",
      },
      {
        CITY: "NIEDERAU",
      },
      {
        CITY: "NIEDERAU",
      },
      {
        CITY: "NIEDERAULA",
      },
      {
        CITY: "NIEDERBRECHEN",
      },
      {
        CITY: "NIEDERBREITBACH",
      },
      {
        CITY: "NIEDERBURG",
      },
      {
        CITY: "NIEDERDING",
      },
      {
        CITY: "NIEDERDORF",
      },
      {
        CITY: "NIEDERDORF",
      },
      {
        CITY: "NIEDERDORFELDEN",
      },
      {
        CITY: "NIEDERDORLA",
      },
      {
        CITY: "NIEDEREISENHAUSEN",
      },
      {
        CITY: "NIEDERESCHACH",
      },
      {
        CITY: "NIEDERFELL",
      },
      {
        CITY: "NIEDERFISCHBACH",
      },
      {
        CITY: "NIEDERFROHNA",
      },
      {
        CITY: "NIEDERHEIMBACH",
      },
      {
        CITY: "NIEDERKASSEL",
      },
      {
        CITY: "NIEDERKIRCHEN",
      },
      {
        CITY: "NIEDERKIRCHEN BEI DEIDESHEIM",
      },
      {
        CITY: "NIEDERKRUCHTEN",
      },
      {
        CITY: "NIEDERLANGEN",
      },
      {
        CITY: "NIEDERLINDHART",
      },
      {
        CITY: "NIEDERMURACH",
      },
      {
        CITY: "NIEDERNBERG",
      },
      {
        CITY: "NIEDERNDODELEBEN",
      },
      {
        CITY: "NIEDERNEISEN",
      },
      {
        CITY: "NIEDERNEUCHING",
      },
      {
        CITY: "NIEDERNHALL",
      },
      {
        CITY: "NIEDERNHAUSEN",
      },
      {
        CITY: "NIEDERNWOHREN",
      },
      {
        CITY: "NIEDERORSCHEL",
      },
      {
        CITY: "NIEDERPOLLNITZ",
      },
      {
        CITY: "NIEDERQUEMBACH",
      },
      {
        CITY: "NIEDERRIEDEN",
      },
      {
        CITY: "NIEDERSACHSWERFEN",
      },
      {
        CITY: "NIEDERSCHONENFELD",
      },
      {
        CITY: "NIEDERSELTERS",
      },
      {
        CITY: "NIEDERSPIER",
      },
      {
        CITY: "NIEDERSTADTFELD",
      },
      {
        CITY: "NIEDERSTAUFENBACH",
      },
      {
        CITY: "NIEDERSTETTEN",
      },
      {
        CITY: "NIEDERSTOTZINGEN",
      },
      {
        CITY: "NIEDERVIEHBACH",
      },
      {
        CITY: "NIEDERWAMBACH",
      },
      {
        CITY: "NIEDERWEIDBACH",
      },
      {
        CITY: "NIEDERWERRN",
      },
      {
        CITY: "NIEDERWIESA",
      },
      {
        CITY: "NIEDERWINKLING",
      },
      {
        CITY: "NIEDERWUERSCHNITZ",
      },
      {
        CITY: "NIEDERZEUZHEIM",
      },
      {
        CITY: "NIEDERZIER",
      },
      {
        CITY: "NIEDERZISSEN",
      },
      {
        CITY: "NIEFERN-OSCHELBRONN",
      },
      {
        CITY: "NIEHEIM",
      },
      {
        CITY: "NIEMBERG",
      },
      {
        CITY: "NIEMEGK",
      },
      {
        CITY: "NIENBORSTEL",
      },
      {
        CITY: "NIENBURG",
      },
      {
        CITY: "NIENBURG/SAALE",
      },
      {
        CITY: "NIENDORF",
      },
      {
        CITY: "NIENDORF",
      },
      {
        CITY: "NIENHAGEN",
      },
      {
        CITY: "NIENHAGEN",
      },
      {
        CITY: "NIENSTADT",
      },
      {
        CITY: "NIEPARS",
      },
      {
        CITY: "NIERSTEIN",
      },
      {
        CITY: "NIESGRAU",
      },
      {
        CITY: "NIESKY",
      },
      {
        CITY: "NIESTE",
      },
      {
        CITY: "NINDORF",
      },
      {
        CITY: "NITTEL",
      },
      {
        CITY: "NITTENAU",
      },
      {
        CITY: "NITTENDORF",
      },
      {
        CITY: "NITZ",
      },
      {
        CITY: "NOBITZ",
      },
      {
        CITY: "NODA",
      },
      {
        CITY: "NOHFELDEN",
      },
      {
        CITY: "NOHRA",
      },
      {
        CITY: "NONNENHORN",
      },
      {
        CITY: "NONNWEILER",
      },
      {
        CITY: "NORDDEICH",
      },
      {
        CITY: "NORDEN",
      },
      {
        CITY: "NORDENDORF",
      },
      {
        CITY: "NORDENHAM",
      },
      {
        CITY: "NORDERNEY",
      },
      {
        CITY: "NORDERSTAPEL",
      },
      {
        CITY: "NORDERSTEDT",
      },
      {
        CITY: "NORDHALBEN",
      },
      {
        CITY: "NORDHASTEDT",
      },
      {
        CITY: "NORDHAUSEN",
      },
      {
        CITY: "NORDHEIM",
      },
      {
        CITY: "NORDHOFEN",
      },
      {
        CITY: "NORDHOLZ",
      },
      {
        CITY: "NORDHOLZ",
      },
      {
        CITY: "NORDHORN",
      },
      {
        CITY: "NORDKIRCHEN",
      },
      {
        CITY: "NORDRACH",
      },
      {
        CITY: "NORDSTEMMEN",
      },
      {
        CITY: "NORDWALDE",
      },
      {
        CITY: "NORHEIM",
      },
      {
        CITY: "NORKEN",
      },
      {
        CITY: "NORSINGEN",
      },
      {
        CITY: "NORTEN-HARDENBERG",
      },
      {
        CITY: "NORTHEIM",
      },
      {
        CITY: "NORTMOOR",
      },
      {
        CITY: "NORTORF",
      },
      {
        CITY: "NORTRUP",
      },
      {
        CITY: "NORVENICH",
      },
      {
        CITY: "NOSSEN",
      },
      {
        CITY: "NOTTULN",
      },
      {
        CITY: "NOTZINGEN",
      },
      {
        CITY: "NUBBEL",
      },
      {
        CITY: "NUDLINGEN",
      },
      {
        CITY: "NUFRINGEN",
      },
      {
        CITY: "NUMBRECHT",
      },
      {
        CITY: "NUNCHRITZ",
      },
      {
        CITY: "NUREMBERG",
      },
      {
        CITY: "NURTINGEN",
      },
      {
        CITY: "NUSPLINGEN",
      },
      {
        CITY: "NUSSDORF AM INN",
      },
      {
        CITY: "NUSSE",
      },
      {
        CITY: "NUSSLOCH",
      },
      {
        CITY: "OBER-ERLENBACH",
      },
      {
        CITY: "OBER-HILBERSHEIM",
      },
      {
        CITY: "OBER-MORLEN",
      },
      {
        CITY: "OBER-OLM",
      },
      {
        CITY: "OBER-RAMSTADT",
      },
      {
        CITY: "OBER-RODEN",
      },
      {
        CITY: "OBERAMMERGAU",
      },
      {
        CITY: "OBERASBACH",
      },
      {
        CITY: "OBERAU",
      },
      {
        CITY: "OBERAUDORF",
      },
      {
        CITY: "OBERAULA",
      },
      {
        CITY: "OBERBERG",
      },
      {
        CITY: "OBERBILLIG",
      },
      {
        CITY: "OBERBODNITZ",
      },
      {
        CITY: "OBERBOIHINGEN",
      },
      {
        CITY: "OBERDERDINGEN",
      },
      {
        CITY: "OBERDIEBACH",
      },
      {
        CITY: "OBERDING",
      },
      {
        CITY: "OBERDISCHINGEN",
      },
      {
        CITY: "OBERDOLLING",
      },
      {
        CITY: "OBERDORLA",
      },
      {
        CITY: "OBERELBERT",
      },
      {
        CITY: "OBERELSBACH",
      },
      {
        CITY: "OBERFELL",
      },
      {
        CITY: "OBERGEBRA",
      },
      {
        CITY: "OBERGRIESBACH",
      },
      {
        CITY: "OBERGUNZBURG",
      },
      {
        CITY: "OBERGURIG",
      },
      {
        CITY: "OBERHACHING",
      },
      {
        CITY: "OBERHAID",
      },
      {
        CITY: "OBERHARMERSBACH",
      },
      {
        CITY: "OBERHAUSEN",
      },
      {
        CITY: "OBERHAUSEN",
      },
      {
        CITY: "OBERHAUSEN",
      },
      {
        CITY: "OBERHAUSEN-RHEINHAUSEN",
      },
      {
        CITY: "OBERHERMSDORF",
      },
      {
        CITY: "OBERHOF",
      },
      {
        CITY: "OBERHOF",
      },
      {
        CITY: "OBERHONNEFELD-GIEREND",
      },
      {
        CITY: "OBERHUNDEM",
      },
      {
        CITY: "OBERICKELSHEIM",
      },
      {
        CITY: "OBERIRSEN",
      },
      {
        CITY: "OBERKAIL",
      },
      {
        CITY: "OBERKAMMLACH",
      },
      {
        CITY: "OBERKIRCH",
      },
      {
        CITY: "OBERKIRCHEN",
      },
      {
        CITY: "OBERKOCHEN",
      },
      {
        CITY: "OBERKOTZAU",
      },
      {
        CITY: "OBERLAHR",
      },
      {
        CITY: "OBERLAINDERN",
      },
      {
        CITY: "OBERLEICHTERSBACH",
      },
      {
        CITY: "OBERLUNGWITZ",
      },
      {
        CITY: "OBERMARCHTAL",
      },
      {
        CITY: "OBERMEILINGEN",
      },
      {
        CITY: "OBERMEITINGEN",
      },
      {
        CITY: "OBERMICHELBACH",
      },
      {
        CITY: "OBERMOHR",
      },
      {
        CITY: "OBERMOSCHEL",
      },
      {
        CITY: "OBERNBREIT",
      },
      {
        CITY: "OBERNBURG AM MAIN",
      },
      {
        CITY: "OBERNDORF",
      },
      {
        CITY: "OBERNDORF",
      },
      {
        CITY: "OBERNDORF",
      },
      {
        CITY: "OBERNDORF",
      },
      {
        CITY: "OBERNDORF",
      },
      {
        CITY: "OBERNEUKIRCHEN",
      },
      {
        CITY: "OBERNEUSES",
      },
      {
        CITY: "OBERNFELD",
      },
      {
        CITY: "OBERNHEIM-KIRCHENARNBACH",
      },
      {
        CITY: "OBERNKIRCHEN",
      },
      {
        CITY: "OBERNZELL",
      },
      {
        CITY: "OBERNZENN",
      },
      {
        CITY: "OBEROPFINGEN",
      },
      {
        CITY: "OBERORNAU",
      },
      {
        CITY: "OBEROTTERBACH",
      },
      {
        CITY: "OBEROTTMARSHAUSEN",
      },
      {
        CITY: "OBERPFRAMMERN",
      },
      {
        CITY: "OBERPIERSCHEID",
      },
      {
        CITY: "OBERPLEICHFELD",
      },
      {
        CITY: "OBERPLEIS",
      },
      {
        CITY: "OBERPORING",
      },
      {
        CITY: "OBERPORLITZ",
      },
      {
        CITY: "OBERRADEN",
      },
      {
        CITY: "OBERREICHENBACH",
      },
      {
        CITY: "OBERREICHENBACH",
      },
      {
        CITY: "OBERREICHENBACH",
      },
      {
        CITY: "OBERRIED",
      },
      {
        CITY: "OBERRIEDEN",
      },
      {
        CITY: "OBERRIEXINGEN",
      },
      {
        CITY: "OBERROD",
      },
      {
        CITY: "OBERROT",
      },
      {
        CITY: "OBERSCHEFFLENZ",
      },
      {
        CITY: "OBERSCHEINFELD",
      },
      {
        CITY: "OBERSCHLEISSHEIM",
      },
      {
        CITY: "OBERSCHNEIDING",
      },
      {
        CITY: "OBERSCHONA",
      },
      {
        CITY: "OBERSCHWEINBACH",
      },
      {
        CITY: "OBERSEIFERSDORF",
      },
      {
        CITY: "OBERSONTHEIM",
      },
      {
        CITY: "OBERSTADION",
      },
      {
        CITY: "OBERSTADTFELD",
      },
      {
        CITY: "OBERSTAUFEN",
      },
      {
        CITY: "OBERSTDORF",
      },
      {
        CITY: "OBERSTENFELD",
      },
      {
        CITY: "OBERSTREU",
      },
      {
        CITY: "OBERSUHL",
      },
      {
        CITY: "OBERTAUFKIRCHEN",
      },
      {
        CITY: "OBERTEURINGEN",
      },
      {
        CITY: "OBERTHAL",
      },
      {
        CITY: "OBERTHULBA",
      },
      {
        CITY: "OBERTRAUBLING",
      },
      {
        CITY: "OBERTRUBACH",
      },
      {
        CITY: "OBERTSHAUSEN",
      },
      {
        CITY: "OBERURSEL",
      },
      {
        CITY: "OBERVIECHTACH",
      },
      {
        CITY: "OBERWEIMAR",
      },
      {
        CITY: "OBERWEIS",
      },
      {
        CITY: "OBERWEISSBACH",
      },
      {
        CITY: "OBERWESEL",
      },
      {
        CITY: "OBERWIESENTHAL",
      },
      {
        CITY: "OBERWINTER",
      },
      {
        CITY: "OBERWOLFACH",
      },
      {
        CITY: "OBERZISSEN",
      },
      {
        CITY: "OBING",
      },
      {
        CITY: "OBRIGHEIM",
      },
      {
        CITY: "OBRIGHEIM",
      },
      {
        CITY: "OBRISTFELD",
      },
      {
        CITY: "OCHSENFURT",
      },
      {
        CITY: "OCHSENHAUSEN",
      },
      {
        CITY: "OCHTENDUNG",
      },
      {
        CITY: "OCHTMERSLEBEN",
      },
      {
        CITY: "OCHTRUP",
      },
      {
        CITY: "OCKENFELS",
      },
      {
        CITY: "ODELZHAUSEN",
      },
      {
        CITY: "ODENDORF",
      },
      {
        CITY: "ODENHEIM",
      },
      {
        CITY: "ODENTHAL",
      },
      {
        CITY: "ODERBERG",
      },
      {
        CITY: "ODERNHEIM",
      },
      {
        CITY: "OEBISFELDE",
      },
      {
        CITY: "OECHSEN",
      },
      {
        CITY: "OEDERAN",
      },
      {
        CITY: "OEDHEIM",
      },
      {
        CITY: "OELDE",
      },
      {
        CITY: "OELERSE",
      },
      {
        CITY: "OELIXDORF",
      },
      {
        CITY: "OELSBERG",
      },
      {
        CITY: "OELSEN",
      },
      {
        CITY: "OELSNITZ",
      },
      {
        CITY: "OELSNITZ",
      },
      {
        CITY: "OELZSCHAU",
      },
      {
        CITY: "OER-ERKENSCHWICK",
      },
      {
        CITY: "OERLENBACH",
      },
      {
        CITY: "OERLINGHAUSEN",
      },
      {
        CITY: "OERSDORF",
      },
      {
        CITY: "OESTERDEICHSTRICH",
      },
      {
        CITY: "OESTRICH-WINKEL",
      },
      {
        CITY: "OETTERSDORF",
      },
      {
        CITY: "OETTINGEN IN BAYERN",
      },
      {
        CITY: "OEVERSEE",
      },
      {
        CITY: "OFFENAU",
      },
      {
        CITY: "OFFENBACH",
      },
      {
        CITY: "OFFENBACH AN DER QUEICH",
      },
      {
        CITY: "OFFENBACH-HUNDHEIM",
      },
      {
        CITY: "OFFENBERG",
      },
      {
        CITY: "OFFENBURG",
      },
      {
        CITY: "OFFENHAUSEN",
      },
      {
        CITY: "OFFINGEN",
      },
      {
        CITY: "OFFSTEIN",
      },
      {
        CITY: "OFTERDINGEN",
      },
      {
        CITY: "OFTERSHEIM",
      },
      {
        CITY: "OGGELSHAUSEN",
      },
      {
        CITY: "OHLSBACH",
      },
      {
        CITY: "OHLSDORF",
      },
      {
        CITY: "OHLSTADT",
      },
      {
        CITY: "OHMDEN",
      },
      {
        CITY: "OHNINGEN",
      },
      {
        CITY: "OHORN",
      },
      {
        CITY: "OHRDRUF",
      },
      {
        CITY: "OHREN",
      },
      {
        CITY: "OHRENSEN",
      },
      {
        CITY: "OHRINGEN",
      },
      {
        CITY: "OLBERNHAU",
      },
      {
        CITY: "OLBERSDORF",
      },
      {
        CITY: "OLBERSLEBEN",
      },
      {
        CITY: "OLBRONN-DURRN",
      },
      {
        CITY: "OLCHING",
      },
      {
        CITY: "OLDENBURG",
      },
      {
        CITY: "OLDENBURG",
      },
      {
        CITY: "OLDENBURG IN HOLSTEIN",
      },
      {
        CITY: "OLDENDORF",
      },
      {
        CITY: "OLDENDORF",
      },
      {
        CITY: "OLDENDORF",
      },
      {
        CITY: "OLDENSWORT",
      },
      {
        CITY: "OLDERSUM",
      },
      {
        CITY: "OLDERUP",
      },
      {
        CITY: "OLDISLEBEN",
      },
      {
        CITY: "OLFEN",
      },
      {
        CITY: "OLPE",
      },
      {
        CITY: "OLSBERG",
      },
      {
        CITY: "OLSBRUCKEN",
      },
      {
        CITY: "OLZHEIM",
      },
      {
        CITY: "OPFENBACH",
      },
      {
        CITY: "OPFINGEN",
      },
      {
        CITY: "OPPACH",
      },
      {
        CITY: "OPPENAU",
      },
      {
        CITY: "OPPENDORF",
      },
      {
        CITY: "OPPENHEIM",
      },
      {
        CITY: "OPPENWEILER",
      },
      {
        CITY: "OPPURG",
      },
      {
        CITY: "ORANIENBAUM",
      },
      {
        CITY: "ORANIENBURG",
      },
      {
        CITY: "ORBIS",
      },
      {
        CITY: "ORING",
      },
      {
        CITY: "ORNBAU",
      },
      {
        CITY: "ORSINGEN-NENZINGEN",
      },
      {
        CITY: "ORTENBERG",
      },
      {
        CITY: "ORTENBERG",
      },
      {
        CITY: "ORTENBURG",
      },
      {
        CITY: "ORTRAND",
      },
      {
        CITY: "OSANN-MONZEL",
      },
      {
        CITY: "OSBURG",
      },
      {
        CITY: "OSCH",
      },
      {
        CITY: "OSCHATZ",
      },
      {
        CITY: "OSCHERSLEBEN",
      },
      {
        CITY: "OSCHINGEN",
      },
      {
        CITY: "OSDORF",
      },
      {
        CITY: "OSEDE",
      },
      {
        CITY: "OSINGEN",
      },
      {
        CITY: "OSLOSS",
      },
      {
        CITY: "OSTBEVERN",
      },
      {
        CITY: "OSTELSHEIM",
      },
      {
        CITY: "OSTEN",
      },
      {
        CITY: "OSTENFELD",
      },
      {
        CITY: "OSTERBERG",
      },
      {
        CITY: "OSTERBROCK",
      },
      {
        CITY: "OSTERBURG",
      },
      {
        CITY: "OSTERBURKEN",
      },
      {
        CITY: "OSTERCAPPELN",
      },
      {
        CITY: "OSTERFELD",
      },
      {
        CITY: "OSTERHAUSEN",
      },
      {
        CITY: "OSTERHOFEN",
      },
      {
        CITY: "OSTERHOLZ-SCHARMBECK",
      },
      {
        CITY: "OSTERNIENBURG",
      },
      {
        CITY: "OSTERODE",
      },
      {
        CITY: "OSTERODE AM HARZ",
      },
      {
        CITY: "OSTERRADE",
      },
      {
        CITY: "OSTERRONFELD",
      },
      {
        CITY: "OSTERSPAI",
      },
      {
        CITY: "OSTERSTEDT",
      },
      {
        CITY: "OSTERWALD",
      },
      {
        CITY: "OSTERWIECK",
      },
      {
        CITY: "OSTFILDERN",
      },
      {
        CITY: "OSTHEIM",
      },
      {
        CITY: "OSTHOFEN",
      },
      {
        CITY: "OSTRACH",
      },
      {
        CITY: "OSTRAMONDRA",
      },
      {
        CITY: "OSTRHAUDERFEHN",
      },
      {
        CITY: "OSTRINGEN",
      },
      {
        CITY: "OSTRITZ",
      },
      {
        CITY: "OSTSEEBAD AHRENSHOOP",
      },
      {
        CITY: "OSTSEEBAD BAABE",
      },
      {
        CITY: "OSTSEEBAD BINZ",
      },
      {
        CITY: "OSTSEEBAD BOLTENHAGEN",
      },
      {
        CITY: "OSTSEEBAD DIERHAGEN",
      },
      {
        CITY: "OSTSEEBAD GOHREN",
      },
      {
        CITY: "OSTSEEBAD KUHLUNGSBORN",
      },
      {
        CITY: "OSTSEEBAD NIENHAGEN",
      },
      {
        CITY: "OSTSEEBAD PREROW",
      },
      {
        CITY: "OSTSEEBAD SELLIN",
      },
      {
        CITY: "OSTSEEBAD ZINNOWITZ",
      },
      {
        CITY: "OSTSTEINBEK",
      },
      {
        CITY: "OTHENSTORF",
      },
      {
        CITY: "OTIGHEIM",
      },
      {
        CITY: "OTISHEIM",
      },
      {
        CITY: "OTTENBACH",
      },
      {
        CITY: "OTTENDORF-OKRILLA",
      },
      {
        CITY: "OTTENHOFEN",
      },
      {
        CITY: "OTTENHOFEN",
      },
      {
        CITY: "OTTENSOOS",
      },
      {
        CITY: "OTTER",
      },
      {
        CITY: "OTTERBACH",
      },
      {
        CITY: "OTTERBACH",
      },
      {
        CITY: "OTTERBERG",
      },
      {
        CITY: "OTTERFING",
      },
      {
        CITY: "OTTERLOH",
      },
      {
        CITY: "OTTERNDORF",
      },
      {
        CITY: "OTTERSBERG",
      },
      {
        CITY: "OTTERSHEIM",
      },
      {
        CITY: "OTTERSTADT",
      },
      {
        CITY: "OTTERSWEIER",
      },
      {
        CITY: "OTTERWISCH",
      },
      {
        CITY: "OTTOBEUREN",
      },
      {
        CITY: "OTTOBRUNN",
      },
      {
        CITY: "OTTRAU",
      },
      {
        CITY: "OTTWEILER",
      },
      {
        CITY: "OTZE",
      },
      {
        CITY: "OTZING",
      },
      {
        CITY: "OVELGOENNE",
      },
      {
        CITY: "OVELGONNE",
      },
      {
        CITY: "OVERATH",
      },
      {
        CITY: "OWEN",
      },
      {
        CITY: "OWINGEN",
      },
      {
        CITY: "OWSCHLAG",
      },
      {
        CITY: "OY-MITTELBERG",
      },
      {
        CITY: "OYTEN",
      },
      {
        CITY: "PADELUGGE",
      },
      {
        CITY: "PADENSTEDT",
      },
      {
        CITY: "PADERBORN",
      },
      {
        CITY: "PAHL",
      },
      {
        CITY: "PAINTEN",
      },
      {
        CITY: "PALLING",
      },
      {
        CITY: "PANSCHWITZ-KUCKAU",
      },
      {
        CITY: "PANSDORF",
      },
      {
        CITY: "PANTELITZ",
      },
      {
        CITY: "PAPENBURG",
      },
      {
        CITY: "PAPENDORF",
      },
      {
        CITY: "PAPPENHEIM",
      },
      {
        CITY: "PARADIES",
      },
      {
        CITY: "PARCHIM",
      },
      {
        CITY: "PAREY",
      },
      {
        CITY: "PARKSTEIN",
      },
      {
        CITY: "PARKSTETTEN",
      },
      {
        CITY: "PARNSBERG",
      },
      {
        CITY: "PARSAU",
      },
      {
        CITY: "PARSDORF",
      },
      {
        CITY: "PARTENHEIM",
      },
      {
        CITY: "PARTENSTEIN",
      },
      {
        CITY: "PARUM",
      },
      {
        CITY: "PASEWALK",
      },
      {
        CITY: "PASSAU",
      },
      {
        CITY: "PASSOW",
      },
      {
        CITY: "PASTETTEN",
      },
      {
        CITY: "PATTENSEN",
      },
      {
        CITY: "PATZIG",
      },
      {
        CITY: "PAULINENAUE",
      },
      {
        CITY: "PAUNZHAUSEN",
      },
      {
        CITY: "PAUSA",
      },
      {
        CITY: "PAUSCHA",
      },
      {
        CITY: "PECHBRUNN",
      },
      {
        CITY: "PECKELSHEIM",
      },
      {
        CITY: "PEETSCH",
      },
      {
        CITY: "PEGAU",
      },
      {
        CITY: "PEGNITZ",
      },
      {
        CITY: "PEINE",
      },
      {
        CITY: "PEISSENBERG",
      },
      {
        CITY: "PEITING",
      },
      {
        CITY: "PEITZ",
      },
      {
        CITY: "PELLINGEN",
      },
      {
        CITY: "PELLWORM",
      },
      {
        CITY: "PELM",
      },
      {
        CITY: "PEMFLING",
      },
      {
        CITY: "PENIG",
      },
      {
        CITY: "PENTENRIED",
      },
      {
        CITY: "PENTLING",
      },
      {
        CITY: "PENZBERG",
      },
      {
        CITY: "PENZING",
      },
      {
        CITY: "PERACH",
      },
      {
        CITY: "PERKAM",
      },
      {
        CITY: "PERL",
      },
      {
        CITY: "PERLEBERG",
      },
      {
        CITY: "PERLESREUT",
      },
      {
        CITY: "PESCH",
      },
      {
        CITY: "PESSENBURGHEIM",
      },
      {
        CITY: "PESTERWITZ",
      },
      {
        CITY: "PETERSAURACH",
      },
      {
        CITY: "PETERSBERG",
      },
      {
        CITY: "PETERSBERG",
      },
      {
        CITY: "PETERSBERG",
      },
      {
        CITY: "PETERSDORF",
      },
      {
        CITY: "PETERSDORF",
      },
      {
        CITY: "PETERSHAGEN",
      },
      {
        CITY: "PETERSHAGEN",
      },
      {
        CITY: "PETERSHAUSEN",
      },
      {
        CITY: "PETRIRODA",
      },
      {
        CITY: "PETTING",
      },
      {
        CITY: "PETTSTADT",
      },
      {
        CITY: "PEWSUM",
      },
      {
        CITY: "PFAFFEN-SCHWABENHEIM",
      },
      {
        CITY: "PFAFFENHAUSEN",
      },
      {
        CITY: "PFAFFENHAUSEN",
      },
      {
        CITY: "PFAFFENHOFEN",
      },
      {
        CITY: "PFAFFENHOFEN AN DER ILM",
      },
      {
        CITY: "PFAFFENHOFEN AN DER ROTH",
      },
      {
        CITY: "PFAFFENWEILER",
      },
      {
        CITY: "PFAFFING",
      },
      {
        CITY: "PFAFFRODA",
      },
      {
        CITY: "PFALZFELD",
      },
      {
        CITY: "PFALZGRAFENWEILER",
      },
      {
        CITY: "PFARRKIRCHEN",
      },
      {
        CITY: "PFARRWEISACH",
      },
      {
        CITY: "PFATTER",
      },
      {
        CITY: "PFEDELBACH",
      },
      {
        CITY: "PFEFFELBACH",
      },
      {
        CITY: "PFEFFENHAUSEN",
      },
      {
        CITY: "PFLUMMERN",
      },
      {
        CITY: "PFORRING",
      },
      {
        CITY: "PFORZEN",
      },
      {
        CITY: "PFORZHEIM",
      },
      {
        CITY: "PFREIMD",
      },
      {
        CITY: "PFRONTEN",
      },
      {
        CITY: "PFULLENDORF",
      },
      {
        CITY: "PFULLINGEN",
      },
      {
        CITY: "PFUNGSTADT",
      },
      {
        CITY: "PHILIPPSBURG",
      },
      {
        CITY: "PHILIPPSTHAL",
      },
      {
        CITY: "PICKLIESSEM",
      },
      {
        CITY: "PIDING",
      },
      {
        CITY: "PIESPORT",
      },
      {
        CITY: "PILSACH",
      },
      {
        CITY: "PILSTING",
      },
      {
        CITY: "PINNEBERG",
      },
      {
        CITY: "PINNOW",
      },
      {
        CITY: "PINNOW",
      },
      {
        CITY: "PIRK",
      },
      {
        CITY: "PIRMASENS",
      },
      {
        CITY: "PIRNA",
      },
      {
        CITY: "PITTENHART",
      },
      {
        CITY: "PIXEL",
      },
      {
        CITY: "PLAIDT",
      },
      {
        CITY: "PLAN",
      },
      {
        CITY: "PLANEGG",
      },
      {
        CITY: "PLANKSTADT",
      },
      {
        CITY: "PLATE",
      },
      {
        CITY: "PLATKOW",
      },
      {
        CITY: "PLATTEN",
      },
      {
        CITY: "PLATTENBURG",
      },
      {
        CITY: "PLATTLING",
      },
      {
        CITY: "PLAU AM SEE",
      },
      {
        CITY: "PLAUE",
      },
      {
        CITY: "PLAUEN",
      },
      {
        CITY: "PLECH",
      },
      {
        CITY: "PLECKHAUSEN",
      },
      {
        CITY: "PLEIDELSHEIM",
      },
      {
        CITY: "PLEIN",
      },
      {
        CITY: "PLEINFELD",
      },
      {
        CITY: "PLEISKIRCHEN",
      },
      {
        CITY: "PLEISWEILER-OBERHOFEN",
      },
      {
        CITY: "PLEIZENHAUSEN",
      },
      {
        CITY: "PLESSA",
      },
      {
        CITY: "PLETTENBERG",
      },
      {
        CITY: "PLEYSTEIN",
      },
      {
        CITY: "PLIENING",
      },
      {
        CITY: "PLIEZHAUSEN",
      },
      {
        CITY: "PLOCHINGEN",
      },
      {
        CITY: "PLODDA",
      },
      {
        CITY: "PLOEN",
      },
      {
        CITY: "PLOSSBERG",
      },
      {
        CITY: "PLUDERHAUSEN",
      },
      {
        CITY: "PLUWIG",
      },
      {
        CITY: "POCKAU",
      },
      {
        CITY: "POCKING",
      },
      {
        CITY: "PODELDORF",
      },
      {
        CITY: "POGGENSEE",
      },
      {
        CITY: "POHL",
      },
      {
        CITY: "POHL",
      },
      {
        CITY: "POHNSTORF",
      },
      {
        CITY: "POING",
      },
      {
        CITY: "POINT",
      },
      {
        CITY: "POLCH",
      },
      {
        CITY: "POLITZ",
      },
      {
        CITY: "POLLENFELD",
      },
      {
        CITY: "POLLHAGEN",
      },
      {
        CITY: "POLLING",
      },
      {
        CITY: "POMMELSBRUNN",
      },
      {
        CITY: "POMMERBY",
      },
      {
        CITY: "POMSTER",
      },
      {
        CITY: "POPPENHAUSEN",
      },
      {
        CITY: "POPPENHAUSEN",
      },
      {
        CITY: "POPPENLAUER",
      },
      {
        CITY: "POPPENRICHT",
      },
      {
        CITY: "PORNBACH",
      },
      {
        CITY: "PORTA WESTFALICA",
      },
      {
        CITY: "POSING",
      },
      {
        CITY: "POSSENDORF",
      },
      {
        CITY: "POSSENDORF",
      },
      {
        CITY: "POSSENHEIM",
      },
      {
        CITY: "POSSNECK",
      },
      {
        CITY: "POSTBAUER-HENG",
      },
      {
        CITY: "POSTMUNSTER",
      },
      {
        CITY: "POTSDAM",
      },
      {
        CITY: "POTTENSTEIN",
      },
      {
        CITY: "POTTMES",
      },
      {
        CITY: "POUCH",
      },
      {
        CITY: "POXDORF",
      },
      {
        CITY: "PRACHT",
      },
      {
        CITY: "PRACKENBACH",
      },
      {
        CITY: "PRANGENDORF",
      },
      {
        CITY: "PREBBEREDE",
      },
      {
        CITY: "PREBITZ",
      },
      {
        CITY: "PREETZ",
      },
      {
        CITY: "PREITH",
      },
      {
        CITY: "PREMNITZ",
      },
      {
        CITY: "PRENZLAU",
      },
      {
        CITY: "PRESSATH",
      },
      {
        CITY: "PRESSEN",
      },
      {
        CITY: "PRESSIG",
      },
      {
        CITY: "PRESTEWITZ",
      },
      {
        CITY: "PRETZFELD",
      },
      {
        CITY: "PREUSSISCH OLDENDORF",
      },
      {
        CITY: "PRICHSENSTADT",
      },
      {
        CITY: "PRIEN AM CHIEMSEE",
      },
      {
        CITY: "PRIESENDORF",
      },
      {
        CITY: "PRIESTEWITZ",
      },
      {
        CITY: "PRIETITZ",
      },
      {
        CITY: "PRISDORF",
      },
      {
        CITY: "PRITTRICHING",
      },
      {
        CITY: "PRITZWALK",
      },
      {
        CITY: "PROBSTEIERHAGEN",
      },
      {
        CITY: "PROHN",
      },
      {
        CITY: "PROSEKEN",
      },
      {
        CITY: "PROTZEN",
      },
      {
        CITY: "PRUCHTEN",
      },
      {
        CITY: "PRUM",
      },
      {
        CITY: "PRUMZURLAY",
      },
      {
        CITY: "PRUTTING",
      },
      {
        CITY: "PUCHERSREUTH",
      },
      {
        CITY: "PUCHHEIM",
      },
      {
        CITY: "PUDERBACH",
      },
      {
        CITY: "PULHEIM",
      },
      {
        CITY: "PULLACH IM ISARTAL",
      },
      {
        CITY: "PULLENREUTH",
      },
      {
        CITY: "PULLHAUSEN",
      },
      {
        CITY: "PULS",
      },
      {
        CITY: "PULSNITZ",
      },
      {
        CITY: "PURGEN",
      },
      {
        CITY: "PUSCHENDORF",
      },
      {
        CITY: "PUTBUS",
      },
      {
        CITY: "PUTLITZ",
      },
      {
        CITY: "PUTTLINGEN",
      },
      {
        CITY: "PUTZBRUNN",
      },
      {
        CITY: "PYRBAUM",
      },
      {
        CITY: "QUAAL",
      },
      {
        CITY: "QUAKENBRUCK",
      },
      {
        CITY: "QUARNBEK",
      },
      {
        CITY: "QUARNSTEDT",
      },
      {
        CITY: "QUEDLINBURG",
      },
      {
        CITY: "QUEIDERSBACH",
      },
      {
        CITY: "QUERFURT",
      },
      {
        CITY: "QUERUM",
      },
      {
        CITY: "QUICKBORN",
      },
      {
        CITY: "QUIERSCHIED",
      },
      {
        CITY: "QUITZOW",
      },
      {
        CITY: "RABEN",
      },
      {
        CITY: "RABENAU",
      },
      {
        CITY: "RABENHOLZ",
      },
      {
        CITY: "RABENKIRCHEN-FAULUCK",
      },
      {
        CITY: "RABKE",
      },
      {
        CITY: "RACHELSBACH",
      },
      {
        CITY: "RACKITH",
      },
      {
        CITY: "RACKWITZ",
      },
      {
        CITY: "RADBRUCH",
      },
      {
        CITY: "RADEBERG",
      },
      {
        CITY: "RADEBEUL",
      },
      {
        CITY: "RADEBURG",
      },
      {
        CITY: "RADEFELD",
      },
      {
        CITY: "RADEGAST",
      },
      {
        CITY: "RADEVORMWALD",
      },
      {
        CITY: "RADIBOR",
      },
      {
        CITY: "RADOLFZELL",
      },
      {
        CITY: "RAESFELD",
      },
      {
        CITY: "RAGUHN",
      },
      {
        CITY: "RAHDEN",
      },
      {
        CITY: "RAIBACH",
      },
      {
        CITY: "RAIN",
      },
      {
        CITY: "RAISDORF",
      },
      {
        CITY: "RAISTING",
      },
      {
        CITY: "RALINGEN",
      },
      {
        CITY: "RAMBIN",
      },
      {
        CITY: "RAMERBERG",
      },
      {
        CITY: "RAMMELSBACH",
      },
      {
        CITY: "RAMMENAU",
      },
      {
        CITY: "RAMMINGEN",
      },
      {
        CITY: "RAMSAU",
      },
      {
        CITY: "RAMSEN",
      },
      {
        CITY: "RAMSLA",
      },
      {
        CITY: "RAMSLOH",
      },
      {
        CITY: "RAMSTEIN-MIESENBACH",
      },
      {
        CITY: "RAMSTHAL",
      },
      {
        CITY: "RANDERSACKER",
      },
      {
        CITY: "RANGENDINGEN",
      },
      {
        CITY: "RANGSDORF",
      },
      {
        CITY: "RANIS",
      },
      {
        CITY: "RANSBACH-BAUMBACH",
      },
      {
        CITY: "RANSTADT",
      },
      {
        CITY: "RANSWEILER",
      },
      {
        CITY: "RANTUM",
      },
      {
        CITY: "RANTZAU",
      },
      {
        CITY: "RAPPENAU",
      },
      {
        CITY: "RASCHAU",
      },
      {
        CITY: "RASDORF",
      },
      {
        CITY: "RASSELSTEIN",
      },
      {
        CITY: "RASTATT",
      },
      {
        CITY: "RASTDORF",
      },
      {
        CITY: "RASTEDE",
      },
      {
        CITY: "RASTENBERG",
      },
      {
        CITY: "RASTOW",
      },
      {
        CITY: "RATEKAU",
      },
      {
        CITY: "RATH",
      },
      {
        CITY: "RATHENOW",
      },
      {
        CITY: "RATINGEN",
      },
      {
        CITY: "RATTELSDORF",
      },
      {
        CITY: "RATTENBERG",
      },
      {
        CITY: "RATTENKIRCHEN",
      },
      {
        CITY: "RATZEBURG",
      },
      {
        CITY: "RAUBACH",
      },
      {
        CITY: "RAUBLING",
      },
      {
        CITY: "RAUDA",
      },
      {
        CITY: "RAUEN",
      },
      {
        CITY: "RAUENBERG",
      },
      {
        CITY: "RAUENSTEIN",
      },
      {
        CITY: "RAUNHEIM",
      },
      {
        CITY: "RAUSCHENBERG",
      },
      {
        CITY: "RAUSDORF",
      },
      {
        CITY: "RAUSDORF",
      },
      {
        CITY: "RAVENSBURG",
      },
      {
        CITY: "RAVENSTEIN",
      },
      {
        CITY: "RECHBERG",
      },
      {
        CITY: "RECHBERG",
      },
      {
        CITY: "RECHBERGHAUSEN",
      },
      {
        CITY: "RECHENBERG-BIENENMUHLE",
      },
      {
        CITY: "RECHTENBACH",
      },
      {
        CITY: "RECHTENFLETH",
      },
      {
        CITY: "RECHTENSTEIN",
      },
      {
        CITY: "RECHTMEHRING",
      },
      {
        CITY: "RECHTSUPWEG",
      },
      {
        CITY: "RECKE",
      },
      {
        CITY: "RECKLINGHAUSEN",
      },
      {
        CITY: "REDDELICH",
      },
      {
        CITY: "REDEFIN",
      },
      {
        CITY: "REDEKIN",
      },
      {
        CITY: "REDNITZHEMBACH",
      },
      {
        CITY: "REDWITZ AN DER RODACH",
      },
      {
        CITY: "REES",
      },
      {
        CITY: "REGEN",
      },
      {
        CITY: "REGENSBURG",
      },
      {
        CITY: "REGENSTAUF",
      },
      {
        CITY: "REGIS-BREITINGEN",
      },
      {
        CITY: "REGNITZLOSAU",
      },
      {
        CITY: "REHAU",
      },
      {
        CITY: "REHBURG-LOCCUM",
      },
      {
        CITY: "REHDEN",
      },
      {
        CITY: "REHER",
      },
      {
        CITY: "REHFELDE",
      },
      {
        CITY: "REHHORST",
      },
      {
        CITY: "REHLING",
      },
      {
        CITY: "REHLINGEN-SIERSBURG",
      },
      {
        CITY: "REHNA",
      },
      {
        CITY: "REICH",
      },
      {
        CITY: "REICHARDTSWERBEN",
      },
      {
        CITY: "REICHARTSHAUSEN",
      },
      {
        CITY: "REICHELSHEIM",
      },
      {
        CITY: "REICHELSHEIM",
      },
      {
        CITY: "REICHENBACH",
      },
      {
        CITY: "REICHENBACH",
      },
      {
        CITY: "REICHENBACH IM TALE",
      },
      {
        CITY: "REICHENBACH-STEEGEN",
      },
      {
        CITY: "REICHENBACH/VOGTLAND",
      },
      {
        CITY: "REICHENSCHWAND",
      },
      {
        CITY: "REICHERSBEUERN",
      },
      {
        CITY: "REICHERTSHAUSEN",
      },
      {
        CITY: "REICHERTSHAUSEN",
      },
      {
        CITY: "REICHERTSHOFEN",
      },
      {
        CITY: "REILINGEN",
      },
      {
        CITY: "REIMLINGEN",
      },
      {
        CITY: "REIMSBACH",
      },
      {
        CITY: "REINBEK",
      },
      {
        CITY: "REINFELD",
      },
      {
        CITY: "REINHARTSHAUSEN",
      },
      {
        CITY: "REINHEIM",
      },
      {
        CITY: "REINSBERG",
      },
      {
        CITY: "REINSDORF",
      },
      {
        CITY: "REINSTADT",
      },
      {
        CITY: "REIPOLTSKIRCHEN",
      },
      {
        CITY: "REISBACH",
      },
      {
        CITY: "REISCHACH",
      },
      {
        CITY: "REISKIRCHEN",
      },
      {
        CITY: "REIT IM WINKL",
      },
      {
        CITY: "REITHOFEN",
      },
      {
        CITY: "REITMEHRING",
      },
      {
        CITY: "REITSCH",
      },
      {
        CITY: "RELLINGEN",
      },
      {
        CITY: "REMAGEN",
      },
      {
        CITY: "REMELS",
      },
      {
        CITY: "REMLINGEN",
      },
      {
        CITY: "REMMESWEILER",
      },
      {
        CITY: "REMPTENDORF",
      },
      {
        CITY: "REMSCHEID",
      },
      {
        CITY: "RENCHEN",
      },
      {
        CITY: "RENDSBURG",
      },
      {
        CITY: "RENGSDORF",
      },
      {
        CITY: "RENNEROD",
      },
      {
        CITY: "RENNERSDORF",
      },
      {
        CITY: "RENNERTSHOFEN",
      },
      {
        CITY: "RENNINGEN",
      },
      {
        CITY: "RENQUISHAUSEN",
      },
      {
        CITY: "RENTRISCH",
      },
      {
        CITY: "RENTWEINSDORF",
      },
      {
        CITY: "REPPENSTEDT",
      },
      {
        CITY: "RETGENDORF",
      },
      {
        CITY: "RETHWISCH",
      },
      {
        CITY: "RETTENBERG",
      },
      {
        CITY: "RETTERSEN",
      },
      {
        CITY: "RETTERT",
      },
      {
        CITY: "RETTIGHEIM",
      },
      {
        CITY: "RETTIN",
      },
      {
        CITY: "RETZSTADT",
      },
      {
        CITY: "REUTE",
      },
      {
        CITY: "REUTH",
      },
      {
        CITY: "REUTLINGEN",
      },
      {
        CITY: "REUTLINGENDORF",
      },
      {
        CITY: "RHADE",
      },
      {
        CITY: "RHADEREISTEDT",
      },
      {
        CITY: "RHAUNEN",
      },
      {
        CITY: "RHEDA-WIEDENBRUCK",
      },
      {
        CITY: "RHEDE",
      },
      {
        CITY: "RHEDE",
      },
      {
        CITY: "RHEDEN",
      },
      {
        CITY: "RHEIN",
      },
      {
        CITY: "RHEIN",
      },
      {
        CITY: "RHEINAU",
      },
      {
        CITY: "RHEINAU",
      },
      {
        CITY: "RHEINBACH",
      },
      {
        CITY: "RHEINBERG",
      },
      {
        CITY: "RHEINBOLLEN",
      },
      {
        CITY: "RHEINBREITBACH",
      },
      {
        CITY: "RHEINBROHL",
      },
      {
        CITY: "RHEINE",
      },
      {
        CITY: "RHEINFELDEN",
      },
      {
        CITY: "RHEINSBERG",
      },
      {
        CITY: "RHEINZABERN",
      },
      {
        CITY: "RHEURDT",
      },
      {
        CITY: "RHINOW",
      },
      {
        CITY: "RHUMSPRINGE",
      },
      {
        CITY: "RIBBECK",
      },
      {
        CITY: "RIBBESBUTTEL",
      },
      {
        CITY: "RIBNITZ",
      },
      {
        CITY: "RIBNITZ-DAMGARTEN",
      },
      {
        CITY: "RICHEN",
      },
      {
        CITY: "RICKENBACH",
      },
      {
        CITY: "RICKERT",
      },
      {
        CITY: "RIDING",
      },
      {
        CITY: "RIEDBACH",
      },
      {
        CITY: "RIEDBACH",
      },
      {
        CITY: "RIEDE",
      },
      {
        CITY: "RIEDEN",
      },
      {
        CITY: "RIEDEN",
      },
      {
        CITY: "RIEDEN",
      },
      {
        CITY: "RIEDEN",
      },
      {
        CITY: "RIEDENBERG",
      },
      {
        CITY: "RIEDENBURG",
      },
      {
        CITY: "RIEDENHEIM",
      },
      {
        CITY: "RIEDER",
      },
      {
        CITY: "RIEDERICH",
      },
      {
        CITY: "RIEDERING",
      },
      {
        CITY: "RIEDHAUSEN",
      },
      {
        CITY: "RIEDLINGEN",
      },
      {
        CITY: "RIEDMOOS",
      },
      {
        CITY: "RIEGEL",
      },
      {
        CITY: "RIEGELSBERG",
      },
      {
        CITY: "RIEGSEE",
      },
      {
        CITY: "RIELASINGEN-WORBLINGEN",
      },
      {
        CITY: "RIEMERLING",
      },
      {
        CITY: "RIENECK",
      },
      {
        CITY: "RIEPSDORF",
      },
      {
        CITY: "RIESA",
      },
      {
        CITY: "RIESEBY",
      },
      {
        CITY: "RIESTE",
      },
      {
        CITY: "RIETBERG",
      },
      {
        CITY: "RIETHEIM",
      },
      {
        CITY: "RIETHNORDHAUSEN",
      },
      {
        CITY: "RIETSCHEN",
      },
      {
        CITY: "RIMBACH",
      },
      {
        CITY: "RIMBACH",
      },
      {
        CITY: "RIMHORN",
      },
      {
        CITY: "RIMPAR",
      },
      {
        CITY: "RIMSCHWEILER",
      },
      {
        CITY: "RIMSTING",
      },
      {
        CITY: "RINCHNACH",
      },
      {
        CITY: "RINGENWALDE",
      },
      {
        CITY: "RINGSBERG",
      },
      {
        CITY: "RINGSHEIM",
      },
      {
        CITY: "RINTELN",
      },
      {
        CITY: "RINZENBERG",
      },
      {
        CITY: "RIOL",
      },
      {
        CITY: "RITSCHENHAUSEN",
      },
      {
        CITY: "RITTERHUDE",
      },
      {
        CITY: "RITTERSDORF",
      },
      {
        CITY: "ROBEL",
      },
      {
        CITY: "ROCHLITZ",
      },
      {
        CITY: "ROCKENBERG",
      },
      {
        CITY: "ROCKENHAUSEN",
      },
      {
        CITY: "RODACH",
      },
      {
        CITY: "RODALBEN",
      },
      {
        CITY: "RODEN",
      },
      {
        CITY: "RODENBACH",
      },
      {
        CITY: "RODENBACH",
      },
      {
        CITY: "RODENBERG",
      },
      {
        CITY: "RODENTAL",
      },
      {
        CITY: "RODERSHEIM-GRONAU",
      },
      {
        CITY: "RODEWISCH",
      },
      {
        CITY: "RODGAU",
      },
      {
        CITY: "RODHEIM",
      },
      {
        CITY: "RODING",
      },
      {
        CITY: "RODINGHAUSEN",
      },
      {
        CITY: "ROEHRNBACH",
      },
      {
        CITY: "ROES",
      },
      {
        CITY: "ROETGEN",
      },
      {
        CITY: "ROETZ",
      },
      {
        CITY: "ROFINGEN",
      },
      {
        CITY: "ROGGENBURG",
      },
      {
        CITY: "ROGGENTIN",
      },
      {
        CITY: "ROGLING",
      },
      {
        CITY: "ROHR",
      },
      {
        CITY: "ROHR",
      },
      {
        CITY: "ROHRBACH",
      },
      {
        CITY: "ROHRBACH",
      },
      {
        CITY: "ROHRBACH",
      },
      {
        CITY: "ROHRDORF",
      },
      {
        CITY: "ROHRDORF",
      },
      {
        CITY: "ROHRENFELS",
      },
      {
        CITY: "ROHRMOOS",
      },
      {
        CITY: "ROHRSDORF",
      },
      {
        CITY: "ROIGHEIM",
      },
      {
        CITY: "ROKLUM",
      },
      {
        CITY: "ROLLBACH",
      },
      {
        CITY: "ROLLSHAUSEN",
      },
      {
        CITY: "ROMHILD",
      },
      {
        CITY: "ROMMERSKIRCHEN",
      },
      {
        CITY: "ROMROD",
      },
      {
        CITY: "ROMSTHAL",
      },
      {
        CITY: "RONDESHAGEN",
      },
      {
        CITY: "RONNEBURG",
      },
      {
        CITY: "RONNENBERG",
      },
      {
        CITY: "RONSBERG",
      },
      {
        CITY: "RORICHUM",
      },
      {
        CITY: "ROSBACH VOR DER HOHE",
      },
      {
        CITY: "ROSCHBACH",
      },
      {
        CITY: "ROSDORF",
      },
      {
        CITY: "ROSDORF",
      },
      {
        CITY: "ROSEBURG",
      },
      {
        CITY: "ROSENBURG",
      },
      {
        CITY: "ROSENDAHL",
      },
      {
        CITY: "ROSENDAHL",
      },
      {
        CITY: "ROSENFELD",
      },
      {
        CITY: "ROSENGARTEN",
      },
      {
        CITY: "ROSENGARTEN",
      },
      {
        CITY: "ROSENGARTEN",
      },
      {
        CITY: "ROSENHEIM",
      },
      {
        CITY: "ROSENKOPF",
      },
      {
        CITY: "ROSENTHAL",
      },
      {
        CITY: "ROSITZ",
      },
      {
        CITY: "ROSLAU",
      },
      {
        CITY: "ROSRATH",
      },
      {
        CITY: "ROSSAU",
      },
      {
        CITY: "ROSSDORF",
      },
      {
        CITY: "ROSSHAUPTEN",
      },
      {
        CITY: "ROSSLAU",
      },
      {
        CITY: "ROSSTAL",
      },
      {
        CITY: "ROSSWEIN",
      },
      {
        CITY: "ROSTOCK",
      },
      {
        CITY: "ROT AM SEE",
      },
      {
        CITY: "ROTENBURG",
      },
      {
        CITY: "ROTENBURG AN DER FULDA",
      },
      {
        CITY: "ROTENHAIN",
      },
      {
        CITY: "ROTH",
      },
      {
        CITY: "ROTHA",
      },
      {
        CITY: "ROTHENBACH",
      },
      {
        CITY: "ROTHENBACH AN DER PEGNITZ",
      },
      {
        CITY: "ROTHENBUCH",
      },
      {
        CITY: "ROTHENBURG",
      },
      {
        CITY: "ROTHENBURG",
      },
      {
        CITY: "ROTHENFELS",
      },
      {
        CITY: "ROTHENSTEIN",
      },
      {
        CITY: "ROTHHAUSEN",
      },
      {
        CITY: "ROTHLEIN",
      },
      {
        CITY: "ROTHOF",
      },
      {
        CITY: "ROTT AM INN",
      },
      {
        CITY: "ROTTACH",
      },
      {
        CITY: "ROTTACH-EGERN",
      },
      {
        CITY: "ROTTENACKER",
      },
      {
        CITY: "ROTTENBACH",
      },
      {
        CITY: "ROTTENBUCH",
      },
      {
        CITY: "ROTTENBURG",
      },
      {
        CITY: "ROTTENBURG AN DER LAABER",
      },
      {
        CITY: "ROTTENDORF",
      },
      {
        CITY: "ROTTENSTUBEN",
      },
      {
        CITY: "ROTTERODE",
      },
      {
        CITY: "ROTTHALMUNSTER",
      },
      {
        CITY: "ROTTINGEN",
      },
      {
        CITY: "ROTTLEBERODE",
      },
      {
        CITY: "ROTTWEIL",
      },
      {
        CITY: "ROVERSHAGEN",
      },
      {
        CITY: "ROXEL",
      },
      {
        CITY: "RUBKE",
      },
      {
        CITY: "RUCHHEIM",
      },
      {
        CITY: "RUCHSEN",
      },
      {
        CITY: "RUCKEROTH",
      },
      {
        CITY: "RUCKERSDORF",
      },
      {
        CITY: "RUCKERSDORF",
      },
      {
        CITY: "RUCKHOLZ",
      },
      {
        CITY: "RUDELZHAUSEN",
      },
      {
        CITY: "RUDERATSHOFEN",
      },
      {
        CITY: "RUDERSBERG",
      },
      {
        CITY: "RUDERSDORF",
      },
      {
        CITY: "RUDERSDORF",
      },
      {
        CITY: "RUDERTING",
      },
      {
        CITY: "RUDESHEIM",
      },
      {
        CITY: "RUDESHEIM AM RHEIN",
      },
      {
        CITY: "RUDOLSTADT",
      },
      {
        CITY: "RUGENDORF",
      },
      {
        CITY: "RUGHEIM",
      },
      {
        CITY: "RUGLAND",
      },
      {
        CITY: "RUHEN",
      },
      {
        CITY: "RUHLA",
      },
      {
        CITY: "RUHLAND",
      },
      {
        CITY: "RUHPOLDING",
      },
      {
        CITY: "RUHSTORF",
      },
      {
        CITY: "RUIT",
      },
      {
        CITY: "RULLSTORF",
      },
      {
        CITY: "RULZHEIM",
      },
      {
        CITY: "RUMELTSHAUSEN",
      },
      {
        CITY: "RUMMER",
      },
      {
        CITY: "RUMMINGEN",
      },
      {
        CITY: "RUMOHR",
      },
      {
        CITY: "RUNDING",
      },
      {
        CITY: "RUNKEL",
      },
      {
        CITY: "RUNTHE",
      },
      {
        CITY: "RUPPACH-GOLDHAUSEN",
      },
      {
        CITY: "RUPPERTSBERG",
      },
      {
        CITY: "RUPPERTSHOFEN",
      },
      {
        CITY: "RUPPERTSWEILER",
      },
      {
        CITY: "RUPPICHTEROTH",
      },
      {
        CITY: "RUSCHBERG",
      },
      {
        CITY: "RUSCHEID",
      },
      {
        CITY: "RUSPEL",
      },
      {
        CITY: "RUST",
      },
      {
        CITY: "RUTESHEIM",
      },
      {
        CITY: "RUTHEN",
      },
      {
        CITY: "RUTHNICK",
      },
      {
        CITY: "RUTSWEILER AN DER LAUTER",
      },
      {
        CITY: "SAAL",
      },
      {
        CITY: "SAAL",
      },
      {
        CITY: "SAALDORF",
      },
      {
        CITY: "SAALFELD",
      },
      {
        CITY: "SAALSTADT",
      },
      {
        CITY: "SAARA",
      },
      {
        CITY: "SAARBURG",
      },
      {
        CITY: "SAARLOUIS",
      },
      {
        CITY: "SAARWELLINGEN",
      },
      {
        CITY: "SACHSEN",
      },
      {
        CITY: "SACHSENHAGEN",
      },
      {
        CITY: "SACHSENHEIM",
      },
      {
        CITY: "SACHSENKAM",
      },
      {
        CITY: "SACK",
      },
      {
        CITY: "SAERBECK",
      },
      {
        CITY: "SAFFIG",
      },
      {
        CITY: "SAGARD",
      },
      {
        CITY: "SAILAUF",
      },
      {
        CITY: "SAINT EGIDIEN",
      },
      {
        CITY: "SAINT MICHAELISDONN",
      },
      {
        CITY: "SALACH",
      },
      {
        CITY: "SALCHING",
      },
      {
        CITY: "SALDENBURG",
      },
      {
        CITY: "SALEM",
      },
      {
        CITY: "SALGEN",
      },
      {
        CITY: "SALM",
      },
      {
        CITY: "SALMTAL",
      },
      {
        CITY: "SALZ",
      },
      {
        CITY: "SALZBERGEN",
      },
      {
        CITY: "SALZBURG",
      },
      {
        CITY: "SALZGITTER",
      },
      {
        CITY: "SALZGITTER-BAD",
      },
      {
        CITY: "SALZHAUSEN",
      },
      {
        CITY: "SALZHEMMENDORF",
      },
      {
        CITY: "SALZKOTTEN",
      },
      {
        CITY: "SALZSTETTEN",
      },
      {
        CITY: "SALZWEDEL",
      },
      {
        CITY: "SALZWEG",
      },
      {
        CITY: "SAMERBERG",
      },
      {
        CITY: "SAMERN",
      },
      {
        CITY: "SAMSWEGEN",
      },
      {
        CITY: "SAMTENS",
      },
      {
        CITY: "SAND",
      },
      {
        CITY: "SANDBERG",
      },
      {
        CITY: "SANDBOSTEL",
      },
      {
        CITY: "SANDE",
      },
      {
        CITY: "SANDERSDORF",
      },
      {
        CITY: "SANDERSLEBEN",
      },
      {
        CITY: "SANDESNEBEN",
      },
      {
        CITY: "SANDHAUSEN",
      },
      {
        CITY: "SANDKRUG",
      },
      {
        CITY: "SANGERHAUSEN",
      },
      {
        CITY: "SANKT ANDREASBERG",
      },
      {
        CITY: "SANKT AUGUSTIN",
      },
      {
        CITY: "SANKT ENGLMAR",
      },
      {
        CITY: "SANKT GEORGEN IM SCHWARZWALD",
      },
      {
        CITY: "SANKT INGBERT",
      },
      {
        CITY: "SANKT KATHARINEN",
      },
      {
        CITY: "SANKT LEON-ROT",
      },
      {
        CITY: "SANKT PETER-ORDING",
      },
      {
        CITY: "SANKT WENDEL",
      },
      {
        CITY: "SANKT WOLFGANG",
      },
      {
        CITY: "SANSSOUCI",
      },
      {
        CITY: "SARGENROTH",
      },
      {
        CITY: "SARKWITZ",
      },
      {
        CITY: "SARLHUSEN",
      },
      {
        CITY: "SARSTEDT",
      },
      {
        CITY: "SASBACH",
      },
      {
        CITY: "SASBACH",
      },
      {
        CITY: "SASBACHWALDEN",
      },
      {
        CITY: "SASSENBERG",
      },
      {
        CITY: "SASSENBURG",
      },
      {
        CITY: "SASSNITZ",
      },
      {
        CITY: "SATJENDORF",
      },
      {
        CITY: "SATRUP",
      },
      {
        CITY: "SATTELDORF",
      },
      {
        CITY: "SATTELSTADT",
      },
      {
        CITY: "SATZVEY",
      },
      {
        CITY: "SAUENSIEK",
      },
      {
        CITY: "SAUERLACH",
      },
      {
        CITY: "SAULDORF",
      },
      {
        CITY: "SAULGAU",
      },
      {
        CITY: "SCHAAFHEIM",
      },
      {
        CITY: "SCHAALBY",
      },
      {
        CITY: "SCHACHT-AUDORF",
      },
      {
        CITY: "SCHACKENDORF",
      },
      {
        CITY: "SCHACKSDORF",
      },
      {
        CITY: "SCHADELEBEN",
      },
      {
        CITY: "SCHAFFLUND",
      },
      {
        CITY: "SCHAFSTEDT",
      },
      {
        CITY: "SCHALKAU",
      },
      {
        CITY: "SCHALKSMUHLE",
      },
      {
        CITY: "SCHALLSTADT",
      },
      {
        CITY: "SCHAMMELSDORF",
      },
      {
        CITY: "SCHARBEUTZ",
      },
      {
        CITY: "SCHARNEBECK",
      },
      {
        CITY: "SCHARNHORST",
      },
      {
        CITY: "SCHARREL",
      },
      {
        CITY: "SCHAUENSTEIN",
      },
      {
        CITY: "SCHAUERBERG",
      },
      {
        CITY: "SCHAULINGS",
      },
      {
        CITY: "SCHAUMBURG",
      },
      {
        CITY: "SCHECHEN",
      },
      {
        CITY: "SCHECHINGEN",
      },
      {
        CITY: "SCHEDEN",
      },
      {
        CITY: "SCHEER",
      },
      {
        CITY: "SCHEESSEL",
      },
      {
        CITY: "SCHEIDEGG",
      },
      {
        CITY: "SCHEINFELD",
      },
      {
        CITY: "SCHELKLINGEN",
      },
      {
        CITY: "SCHELLERTEN",
      },
      {
        CITY: "SCHELLHORN",
      },
      {
        CITY: "SCHELLWEILER",
      },
      {
        CITY: "SCHENEFELD",
      },
      {
        CITY: "SCHENEFELD",
      },
      {
        CITY: "SCHENEFELD",
      },
      {
        CITY: "SCHENKENHORST",
      },
      {
        CITY: "SCHENKENZELL",
      },
      {
        CITY: "SCHENKLENGSFELD",
      },
      {
        CITY: "SCHERENBOSTEL",
      },
      {
        CITY: "SCHERMBECK",
      },
      {
        CITY: "SCHERMEN",
      },
      {
        CITY: "SCHERSTETTEN",
      },
      {
        CITY: "SCHESSLITZ",
      },
      {
        CITY: "SCHEUERFELD",
      },
      {
        CITY: "SCHEUERHALDEN",
      },
      {
        CITY: "SCHEURING",
      },
      {
        CITY: "SCHEYERN",
      },
      {
        CITY: "SCHIEDER-SCHWALENBERG",
      },
      {
        CITY: "SCHIERLING",
      },
      {
        CITY: "SCHIFFDORF",
      },
      {
        CITY: "SCHIFFERSTADT",
      },
      {
        CITY: "SCHIFFWEILER",
      },
      {
        CITY: "SCHILDAU",
      },
      {
        CITY: "SCHILDOW",
      },
      {
        CITY: "SCHILLINGSFURST",
      },
      {
        CITY: "SCHILLINGSHOF",
      },
      {
        CITY: "SCHILTACH",
      },
      {
        CITY: "SCHILTBERG",
      },
      {
        CITY: "SCHINDHARD",
      },
      {
        CITY: "SCHIPHORST",
      },
      {
        CITY: "SCHIRGISWALDE",
      },
      {
        CITY: "SCHIRMITZ",
      },
      {
        CITY: "SCHIRNDING",
      },
      {
        CITY: "SCHKEUDITZ",
      },
      {
        CITY: "SCHKOLEN",
      },
      {
        CITY: "SCHKOPAU",
      },
      {
        CITY: "SCHLADEN",
      },
      {
        CITY: "SCHLAITDORF",
      },
      {
        CITY: "SCHLAMMERSDORF",
      },
      {
        CITY: "SCHLANGEN",
      },
      {
        CITY: "SCHLANGENBAD",
      },
      {
        CITY: "SCHLANSTEDT",
      },
      {
        CITY: "SCHLAT",
      },
      {
        CITY: "SCHLECHING",
      },
      {
        CITY: "SCHLEGEL",
      },
      {
        CITY: "SCHLEHDORF",
      },
      {
        CITY: "SCHLEID",
      },
      {
        CITY: "SCHLEID",
      },
      {
        CITY: "SCHLEIDEN",
      },
      {
        CITY: "SCHLEIFE",
      },
      {
        CITY: "SCHLEIZ",
      },
      {
        CITY: "SCHLEMA",
      },
      {
        CITY: "SCHLEPZIG",
      },
      {
        CITY: "SCHLESWIG",
      },
      {
        CITY: "SCHLETTAU",
      },
      {
        CITY: "SCHLEUSINGEN",
      },
      {
        CITY: "SCHLIEBEN",
      },
      {
        CITY: "SCHLIENGEN",
      },
      {
        CITY: "SCHLIER",
      },
      {
        CITY: "SCHLIERBACH",
      },
      {
        CITY: "SCHLIERSEE",
      },
      {
        CITY: "SCHLITZ",
      },
      {
        CITY: "SCHLOSS",
      },
      {
        CITY: "SCHLOSS HOLTE-STUKENBROCK",
      },
      {
        CITY: "SCHLOSSBERG",
      },
      {
        CITY: "SCHLOTFELD",
      },
      {
        CITY: "SCHLOTHEIM",
      },
      {
        CITY: "SCHLUCHSEE",
      },
      {
        CITY: "SCHLUCHTERN",
      },
      {
        CITY: "SCHLUSSELFELD",
      },
      {
        CITY: "SCHMALENBERG",
      },
      {
        CITY: "SCHMALENSEE",
      },
      {
        CITY: "SCHMALKALDEN",
      },
      {
        CITY: "SCHMALLENBERG",
      },
      {
        CITY: "SCHMELZ",
      },
      {
        CITY: "SCHMERZ",
      },
      {
        CITY: "SCHMIDGADEN",
      },
      {
        CITY: "SCHMIDMUHLEN",
      },
      {
        CITY: "SCHMIDT",
      },
      {
        CITY: "SCHMIDTHACHENBACH",
      },
      {
        CITY: "SCHMIECHEN",
      },
      {
        CITY: "SCHMIECHEN",
      },
      {
        CITY: "SCHMIEDEFELD AM RENNSTEIG",
      },
      {
        CITY: "SCHMILAU",
      },
      {
        CITY: "SCHMITTEN",
      },
      {
        CITY: "SCHMITTWEILER",
      },
      {
        CITY: "SCHMOLLN",
      },
      {
        CITY: "SCHNABELWAID",
      },
      {
        CITY: "SCHNACKENBURG",
      },
      {
        CITY: "SCHNAITSEE",
      },
      {
        CITY: "SCHNAITTACH",
      },
      {
        CITY: "SCHNAITTENBACH",
      },
      {
        CITY: "SCHNECKENHAUSEN",
      },
      {
        CITY: "SCHNEE",
      },
      {
        CITY: "SCHNEEBERG",
      },
      {
        CITY: "SCHNEEBERG",
      },
      {
        CITY: "SCHNEGA",
      },
      {
        CITY: "SCHNEIZLREUTH",
      },
      {
        CITY: "SCHNELLBACH",
      },
      {
        CITY: "SCHNELLDORF",
      },
      {
        CITY: "SCHNELLMANNSHAUSEN",
      },
      {
        CITY: "SCHNEPPENBACH",
      },
      {
        CITY: "SCHNEVERDINGEN",
      },
      {
        CITY: "SCHNEY",
      },
      {
        CITY: "SCHNURPFLINGEN",
      },
      {
        CITY: "SCHOEFFENGRUND",
      },
      {
        CITY: "SCHOENBERG",
      },
      {
        CITY: "SCHOENHEIDE",
      },
      {
        CITY: "SCHOLLBRUNN",
      },
      {
        CITY: "SCHOLLENE",
      },
      {
        CITY: "SCHOLLKRIPPEN",
      },
      {
        CITY: "SCHOLLNACH",
      },
      {
        CITY: "SCHOMBERG",
      },
      {
        CITY: "SCHOMBERG",
      },
      {
        CITY: "SCHONACH IM SCHWARZWALD",
      },
      {
        CITY: "SCHONAICH",
      },
      {
        CITY: "SCHONAU",
      },
      {
        CITY: "SCHONAU",
      },
      {
        CITY: "SCHONAU IM SCHWARZWALD",
      },
      {
        CITY: "SCHONBERG",
      },
      {
        CITY: "SCHONBERG",
      },
      {
        CITY: "SCHONBERG",
      },
      {
        CITY: "SCHONBERG",
      },
      {
        CITY: "SCHONBORN",
      },
      {
        CITY: "SCHONBRUNN",
      },
      {
        CITY: "SCHONDORF AM AMMERSEE",
      },
      {
        CITY: "SCHONDRA",
      },
      {
        CITY: "SCHONEBECK",
      },
      {
        CITY: "SCHONEBERG",
      },
      {
        CITY: "SCHONECK",
      },
      {
        CITY: "SCHONECKEN",
      },
      {
        CITY: "SCHONEFELD",
      },
      {
        CITY: "SCHONEICHE",
      },
      {
        CITY: "SCHONENBERG",
      },
      {
        CITY: "SCHONENBERG-KUBELBERG",
      },
      {
        CITY: "SCHONEWALDE",
      },
      {
        CITY: "SCHONEWORDE",
      },
      {
        CITY: "SCHONFELD",
      },
      {
        CITY: "SCHONFLIESS",
      },
      {
        CITY: "SCHONGAU",
      },
      {
        CITY: "SCHONGEISING",
      },
      {
        CITY: "SCHONINGEN",
      },
      {
        CITY: "SCHONKIRCHEN",
      },
      {
        CITY: "SCHONSEE",
      },
      {
        CITY: "SCHONSTETT",
      },
      {
        CITY: "SCHONTHAL",
      },
      {
        CITY: "SCHONUNGEN",
      },
      {
        CITY: "SCHONWALD",
      },
      {
        CITY: "SCHONWALD IM SCHWARZWALD",
      },
      {
        CITY: "SCHONWALDE",
      },
      {
        CITY: "SCHONWALDE",
      },
      {
        CITY: "SCHOPFHEIM",
      },
      {
        CITY: "SCHOPFLOCH",
      },
      {
        CITY: "SCHOPFLOCH",
      },
      {
        CITY: "SCHOPPENSTEDT",
      },
      {
        CITY: "SCHOPPINGEN",
      },
      {
        CITY: "SCHOPSDORF",
      },
      {
        CITY: "SCHORNDORF",
      },
      {
        CITY: "SCHORNSHEIM",
      },
      {
        CITY: "SCHORTENS",
      },
      {
        CITY: "SCHOSSIN",
      },
      {
        CITY: "SCHOTTEN",
      },
      {
        CITY: "SCHRAMBERG",
      },
      {
        CITY: "SCHRIESHEIM",
      },
      {
        CITY: "SCHROBENHAUSEN",
      },
      {
        CITY: "SCHROZBERG",
      },
      {
        CITY: "SCHUBY",
      },
      {
        CITY: "SCHULD",
      },
      {
        CITY: "SCHULENDORF",
      },
      {
        CITY: "SCHULLDORF",
      },
      {
        CITY: "SCHULLER",
      },
      {
        CITY: "SCHULZENDORF",
      },
      {
        CITY: "SCHUTTERTAL",
      },
      {
        CITY: "SCHUTTERWALD",
      },
      {
        CITY: "SCHUTTORF",
      },
      {
        CITY: "SCHUTZ",
      },
      {
        CITY: "SCHUTZBACH",
      },
      {
        CITY: "SCHUTZENHOF",
      },
      {
        CITY: "SCHWAAN",
      },
      {
        CITY: "SCHWABACH",
      },
      {
        CITY: "SCHWABEN",
      },
      {
        CITY: "SCHWABENHEIM",
      },
      {
        CITY: "SCHWABHAUSEN",
      },
      {
        CITY: "SCHWABHAUSEN",
      },
      {
        CITY: "SCHWABMUNCHEN",
      },
      {
        CITY: "SCHWABNIEDERHOFEN",
      },
      {
        CITY: "SCHWABSTEDT",
      },
      {
        CITY: "SCHWAIBACH",
      },
      {
        CITY: "SCHWAIG",
      },
      {
        CITY: "SCHWAIG",
      },
      {
        CITY: "SCHWAIGERN",
      },
      {
        CITY: "SCHWAIKHEIM",
      },
      {
        CITY: "SCHWALBACH",
      },
      {
        CITY: "SCHWALBACH AM TAUNUS",
      },
      {
        CITY: "SCHWALINGEN",
      },
      {
        CITY: "SCHWALLUNGEN",
      },
      {
        CITY: "SCHWALMSTADT",
      },
      {
        CITY: "SCHWALMTAL",
      },
      {
        CITY: "SCHWALMTAL",
      },
      {
        CITY: "SCHWANDORF IN BAYERN",
      },
      {
        CITY: "SCHWANEBECK",
      },
      {
        CITY: "SCHWANEBERG",
      },
      {
        CITY: "SCHWANEWEDE",
      },
      {
        CITY: "SCHWANFELD",
      },
      {
        CITY: "SCHWANGAU",
      },
      {
        CITY: "SCHWANSEE",
      },
      {
        CITY: "SCHWARME",
      },
      {
        CITY: "SCHWARMSTEDT",
      },
      {
        CITY: "SCHWARZ",
      },
      {
        CITY: "SCHWARZACH",
      },
      {
        CITY: "SCHWARZACH",
      },
      {
        CITY: "SCHWARZBACH",
      },
      {
        CITY: "SCHWARZENBACH",
      },
      {
        CITY: "SCHWARZENBACH AM WALD",
      },
      {
        CITY: "SCHWARZENBACH AN DER SAALE",
      },
      {
        CITY: "SCHWARZENBEK",
      },
      {
        CITY: "SCHWARZENBERG",
      },
      {
        CITY: "SCHWARZENBORN",
      },
      {
        CITY: "SCHWARZENBRUCK",
      },
      {
        CITY: "SCHWARZENFELD",
      },
      {
        CITY: "SCHWARZHAUSEN",
      },
      {
        CITY: "SCHWARZHEIDE",
      },
      {
        CITY: "SCHWARZHOLZ",
      },
      {
        CITY: "SCHWARZWALD",
      },
      {
        CITY: "SCHWEBHEIM",
      },
      {
        CITY: "SCHWEDELBACH",
      },
      {
        CITY: "SCHWEDT (ODER)",
      },
      {
        CITY: "SCHWEGENHEIM",
      },
      {
        CITY: "SCHWEIBURG",
      },
      {
        CITY: "SCHWEICH",
      },
      {
        CITY: "SCHWEIGEN-RECHTENBACH",
      },
      {
        CITY: "SCHWEINFURT",
      },
      {
        CITY: "SCHWEITENKIRCHEN",
      },
      {
        CITY: "SCHWEIZERHOF",
      },
      {
        CITY: "SCHWELM",
      },
      {
        CITY: "SCHWENDI",
      },
      {
        CITY: "SCHWENNINGEN",
      },
      {
        CITY: "SCHWERIN",
      },
      {
        CITY: "SCHWERINGEN",
      },
      {
        CITY: "SCHWERSTEDT",
      },
      {
        CITY: "SCHWERTE",
      },
      {
        CITY: "SCHWESING",
      },
      {
        CITY: "SCHWETZINGEN",
      },
      {
        CITY: "SCHWIEBERDINGEN",
      },
      {
        CITY: "SCHWIENAU",
      },
      {
        CITY: "SCHWIFTING",
      },
      {
        CITY: "SCHWINDEGG",
      },
      {
        CITY: "SCHWIRZHEIM",
      },
      {
        CITY: "SCHWOLLEN",
      },
      {
        CITY: "SCHWORSTADT",
      },
      {
        CITY: "SEBNITZ",
      },
      {
        CITY: "SECK",
      },
      {
        CITY: "SECKACH",
      },
      {
        CITY: "SEEBACH",
      },
      {
        CITY: "SEEBAD AHLBECK",
      },
      {
        CITY: "SEEBAD BANSIN",
      },
      {
        CITY: "SEEBAD HERINGSDORF",
      },
      {
        CITY: "SEEBERGEN",
      },
      {
        CITY: "SEEBRUCK",
      },
      {
        CITY: "SEEBURG",
      },
      {
        CITY: "SEEBURG",
      },
      {
        CITY: "SEEDORF",
      },
      {
        CITY: "SEEFELD",
      },
      {
        CITY: "SEEFELD",
      },
      {
        CITY: "SEEFELD",
      },
      {
        CITY: "SEEG",
      },
      {
        CITY: "SEEGA",
      },
      {
        CITY: "SEEHAUS",
      },
      {
        CITY: "SEEHAUSEN",
      },
      {
        CITY: "SEEHEILBAD GRAAL-MURITZ",
      },
      {
        CITY: "SEEHEIM-JUGENHEIM",
      },
      {
        CITY: "SEEHOF",
      },
      {
        CITY: "SEEHOF",
      },
      {
        CITY: "SEELAND",
      },
      {
        CITY: "SEELBACH",
      },
      {
        CITY: "SEELITZ",
      },
      {
        CITY: "SEELOW",
      },
      {
        CITY: "SEELSCHEID",
      },
      {
        CITY: "SEELZE",
      },
      {
        CITY: "SEEON-SEEBRUCK",
      },
      {
        CITY: "SEESBACH",
      },
      {
        CITY: "SEESEN",
      },
      {
        CITY: "SEESHAUPT",
      },
      {
        CITY: "SEESTER",
      },
      {
        CITY: "SEEVETAL",
      },
      {
        CITY: "SEFFERN",
      },
      {
        CITY: "SEFFERWEICH",
      },
      {
        CITY: "SEHLDE",
      },
      {
        CITY: "SEHLEM",
      },
      {
        CITY: "SEHLEN",
      },
      {
        CITY: "SEHMA",
      },
      {
        CITY: "SEHNDE",
      },
      {
        CITY: "SEIFHENNERSDORF",
      },
      {
        CITY: "SELB",
      },
      {
        CITY: "SELBACH",
      },
      {
        CITY: "SELBITZ",
      },
      {
        CITY: "SELENT",
      },
      {
        CITY: "SELIGENSTADT",
      },
      {
        CITY: "SELIGENSTADT",
      },
      {
        CITY: "SELIGENTHAL",
      },
      {
        CITY: "SELLERICH",
      },
      {
        CITY: "SELLIN",
      },
      {
        CITY: "SELM",
      },
      {
        CITY: "SELMSDORF",
      },
      {
        CITY: "SELSINGEN",
      },
      {
        CITY: "SELTERS",
      },
      {
        CITY: "SELTERS",
      },
      {
        CITY: "SELZEN",
      },
      {
        CITY: "SEMBACH",
      },
      {
        CITY: "SENDEN",
      },
      {
        CITY: "SENDEN",
      },
      {
        CITY: "SENDENHORST",
      },
      {
        CITY: "SENFTENBERG",
      },
      {
        CITY: "SENGENTHAL",
      },
      {
        CITY: "SENNFELD",
      },
      {
        CITY: "SENSWEILER",
      },
      {
        CITY: "SENZIG",
      },
      {
        CITY: "SEREETZ",
      },
      {
        CITY: "SERRIG",
      },
      {
        CITY: "SERSHEIM",
      },
      {
        CITY: "SESSLACH",
      },
      {
        CITY: "SETH",
      },
      {
        CITY: "SEUBERSDORF",
      },
      {
        CITY: "SEUKENDORF",
      },
      {
        CITY: "SEXAU",
      },
      {
        CITY: "SIBBESSE",
      },
      {
        CITY: "SICKTE",
      },
      {
        CITY: "SIEBENBAUMEN",
      },
      {
        CITY: "SIEDENBRUNZOW",
      },
      {
        CITY: "SIEDENBURG",
      },
      {
        CITY: "SIEGBURG",
      },
      {
        CITY: "SIEGELSBACH",
      },
      {
        CITY: "SIEGEN",
      },
      {
        CITY: "SIEGENBURG",
      },
      {
        CITY: "SIEGSDORF",
      },
      {
        CITY: "SIELENBACH",
      },
      {
        CITY: "SIERKSDORF",
      },
      {
        CITY: "SIERSHAHN",
      },
      {
        CITY: "SIESBACH",
      },
      {
        CITY: "SIEVERSHUTTEN",
      },
      {
        CITY: "SIEVERSTEDT",
      },
      {
        CITY: "SIGMARINGEN",
      },
      {
        CITY: "SIGMARINGENDORF",
      },
      {
        CITY: "SIGMARSZELL",
      },
      {
        CITY: "SILLING",
      },
      {
        CITY: "SIMBACH",
      },
      {
        CITY: "SIMBACH AM INN",
      },
      {
        CITY: "SIMMELSDORF",
      },
      {
        CITY: "SIMMERATH",
      },
      {
        CITY: "SIMMERN",
      },
      {
        CITY: "SIMMERSFELD",
      },
      {
        CITY: "SIMMERTAL",
      },
      {
        CITY: "SIMONSBERG",
      },
      {
        CITY: "SINDELFINGEN",
      },
      {
        CITY: "SINDORF",
      },
      {
        CITY: "SINDRINGEN",
      },
      {
        CITY: "SINGEN",
      },
      {
        CITY: "SINGENBACH",
      },
      {
        CITY: "SINGHOFEN",
      },
      {
        CITY: "SINN",
      },
      {
        CITY: "SINSHEIM",
      },
      {
        CITY: "SINZHEIM",
      },
      {
        CITY: "SINZIG",
      },
      {
        CITY: "SINZING",
      },
      {
        CITY: "SIPPERSFELD",
      },
      {
        CITY: "SIPPLINGEN",
      },
      {
        CITY: "SITTENSEN",
      },
      {
        CITY: "SOCHTENAU",
      },
      {
        CITY: "SOEMMERDA",
      },
      {
        CITY: "SOEST",
      },
      {
        CITY: "SOGEL",
      },
      {
        CITY: "SOHLAND",
      },
      {
        CITY: "SOHLDE",
      },
      {
        CITY: "SOHREN",
      },
      {
        CITY: "SOLDEN",
      },
      {
        CITY: "SOLINGEN",
      },
      {
        CITY: "SOLKWITZ",
      },
      {
        CITY: "SOLLER",
      },
      {
        CITY: "SOLLERUP",
      },
      {
        CITY: "SOLLSTEDT",
      },
      {
        CITY: "SOLMS",
      },
      {
        CITY: "SOLTAU",
      },
      {
        CITY: "SOLTENDIECK",
      },
      {
        CITY: "SOMBORN",
      },
      {
        CITY: "SOMMERLAND",
      },
      {
        CITY: "SOMMERLOCH",
      },
      {
        CITY: "SONDERSHAUSEN",
      },
      {
        CITY: "SONDHEIM",
      },
      {
        CITY: "SONNEBERG",
      },
      {
        CITY: "SONNEFELD",
      },
      {
        CITY: "SONNSCHIED",
      },
      {
        CITY: "SONSBECK",
      },
      {
        CITY: "SONTHEIM",
      },
      {
        CITY: "SONTHEIM AN DER BRENZ",
      },
      {
        CITY: "SONTHOFEN",
      },
      {
        CITY: "SONTRA",
      },
      {
        CITY: "SORTH",
      },
      {
        CITY: "SORUP",
      },
      {
        CITY: "SOSA",
      },
      {
        CITY: "SOTTERHAUSEN",
      },
      {
        CITY: "SOTTRUM",
      },
      {
        CITY: "SOYEN",
      },
      {
        CITY: "SPAICHINGEN",
      },
      {
        CITY: "SPALT",
      },
      {
        CITY: "SPANGDAHLEM",
      },
      {
        CITY: "SPANGENBERG",
      },
      {
        CITY: "SPARDORF",
      },
      {
        CITY: "SPATZENHAUSEN",
      },
      {
        CITY: "SPAY",
      },
      {
        CITY: "SPECHTSBRUNN",
      },
      {
        CITY: "SPECHTSHAUSEN",
      },
      {
        CITY: "SPEICHER",
      },
      {
        CITY: "SPEICHERSDORF",
      },
      {
        CITY: "SPEINSHART",
      },
      {
        CITY: "SPELLE",
      },
      {
        CITY: "SPENGE",
      },
      {
        CITY: "SPESSART",
      },
      {
        CITY: "SPETZERFEHN",
      },
      {
        CITY: "SPEYER",
      },
      {
        CITY: "SPIEGELAU",
      },
      {
        CITY: "SPIEGELBERG",
      },
      {
        CITY: "SPIEGELSBERGE",
      },
      {
        CITY: "SPIELBERG",
      },
      {
        CITY: "SPIESEN-ELVERSBERG",
      },
      {
        CITY: "SPITZKUNNERSDORF",
      },
      {
        CITY: "SPLIETSDORF",
      },
      {
        CITY: "SPONHEIM",
      },
      {
        CITY: "SPORA",
      },
      {
        CITY: "SPORNITZ",
      },
      {
        CITY: "SPRAITBACH",
      },
      {
        CITY: "SPREE",
      },
      {
        CITY: "SPREENHAGEN",
      },
      {
        CITY: "SPREMBERG",
      },
      {
        CITY: "SPREMBERG",
      },
      {
        CITY: "SPRENDLINGEN",
      },
      {
        CITY: "SPRENGEL",
      },
      {
        CITY: "SPRINGE",
      },
      {
        CITY: "SPRINGSTILLE",
      },
      {
        CITY: "SPROCKHOVEL",
      },
      {
        CITY: "STABELOW",
      },
      {
        CITY: "STADE",
      },
      {
        CITY: "STADECKEN-ELSHEIM",
      },
      {
        CITY: "STADELHOFEN",
      },
      {
        CITY: "STADT",
      },
      {
        CITY: "STADT WEHLEN",
      },
      {
        CITY: "STADTALLENDORF",
      },
      {
        CITY: "STADTBERGEN",
      },
      {
        CITY: "STADTHAGEN",
      },
      {
        CITY: "STADTILM",
      },
      {
        CITY: "STADTKYLL",
      },
      {
        CITY: "STADTLAURINGEN",
      },
      {
        CITY: "STADTLOHN",
      },
      {
        CITY: "STADTOLDENDORF",
      },
      {
        CITY: "STADTPROZELTEN",
      },
      {
        CITY: "STADTRODA",
      },
      {
        CITY: "STADTSTEINACH",
      },
      {
        CITY: "STAHL",
      },
      {
        CITY: "STAHLHOFEN",
      },
      {
        CITY: "STAHNSDORF",
      },
      {
        CITY: "STAIG",
      },
      {
        CITY: "STAMMBACH",
      },
      {
        CITY: "STAMMHEIM",
      },
      {
        CITY: "STAMSRIED",
      },
      {
        CITY: "STANGERODE",
      },
      {
        CITY: "STAPELBURG",
      },
      {
        CITY: "STAPELFELD",
      },
      {
        CITY: "STARNBERG",
      },
      {
        CITY: "STASSFURT",
      },
      {
        CITY: "STAUCHITZ",
      },
      {
        CITY: "STAUDT",
      },
      {
        CITY: "STAUFEN IM BREISGAU",
      },
      {
        CITY: "STAUFENBERG",
      },
      {
        CITY: "STEGAURACH",
      },
      {
        CITY: "STEGEN",
      },
      {
        CITY: "STEGLITZ BEZIRK",
      },
      {
        CITY: "STEIGRA",
      },
      {
        CITY: "STEIMBKE",
      },
      {
        CITY: "STEIMEL",
      },
      {
        CITY: "STEIN",
      },
      {
        CITY: "STEIN AN DER TRAUN",
      },
      {
        CITY: "STEIN-NEUKIRCH",
      },
      {
        CITY: "STEINACH",
      },
      {
        CITY: "STEINACH",
      },
      {
        CITY: "STEINAU AN DER STRASSE",
      },
      {
        CITY: "STEINBACH AM TAUNUS",
      },
      {
        CITY: "STEINBURG",
      },
      {
        CITY: "STEINEBERG",
      },
      {
        CITY: "STEINEGG",
      },
      {
        CITY: "STEINEN",
      },
      {
        CITY: "STEINEN",
      },
      {
        CITY: "STEINENBRONN",
      },
      {
        CITY: "STEINEROTH",
      },
      {
        CITY: "STEINFELD",
      },
      {
        CITY: "STEINFELD",
      },
      {
        CITY: "STEINFURT",
      },
      {
        CITY: "STEINGADEN",
      },
      {
        CITY: "STEINHAGEN",
      },
      {
        CITY: "STEINHAGEN",
      },
      {
        CITY: "STEINHAUSEN",
      },
      {
        CITY: "STEINHEIM",
      },
      {
        CITY: "STEINHEIM AM ALBUCH",
      },
      {
        CITY: "STEINHEIM AM DER MURR",
      },
      {
        CITY: "STEINHORING",
      },
      {
        CITY: "STEINHORST",
      },
      {
        CITY: "STEINIGTWOLMSDORF",
      },
      {
        CITY: "STEINLAH",
      },
      {
        CITY: "STEINMAUERN",
      },
      {
        CITY: "STEINTOCH",
      },
      {
        CITY: "STEINWEILER",
      },
      {
        CITY: "STEINWEILER",
      },
      {
        CITY: "STEINWENDEN",
      },
      {
        CITY: "STEINWIESEN",
      },
      {
        CITY: "STEISSLINGEN",
      },
      {
        CITY: "STELLE",
      },
      {
        CITY: "STELLSHAGEN",
      },
      {
        CITY: "STELZENBERG",
      },
      {
        CITY: "STENDAL",
      },
      {
        CITY: "STEPFERSHAUSEN",
      },
      {
        CITY: "STEPHANSKIRCHEN",
      },
      {
        CITY: "STERBFRITZ",
      },
      {
        CITY: "STERNBERG",
      },
      {
        CITY: "STERNBERG",
      },
      {
        CITY: "STERNENFELS",
      },
      {
        CITY: "STERUP",
      },
      {
        CITY: "STERZHAUSEN",
      },
      {
        CITY: "STETTEN",
      },
      {
        CITY: "STETTEN",
      },
      {
        CITY: "STETTFELD",
      },
      {
        CITY: "STEUDEN",
      },
      {
        CITY: "STEUDNITZ",
      },
      {
        CITY: "STEYERBERG",
      },
      {
        CITY: "STIEGE",
      },
      {
        CITY: "STIMPFACH",
      },
      {
        CITY: "STINSTEDT",
      },
      {
        CITY: "STOCKACH",
      },
      {
        CITY: "STOCKELSDORF",
      },
      {
        CITY: "STOCKHAUSEN",
      },
      {
        CITY: "STOCKHAUSEN-ILLFURTH",
      },
      {
        CITY: "STOCKHEIM",
      },
      {
        CITY: "STOCKHEIM",
      },
      {
        CITY: "STOCKSE",
      },
      {
        CITY: "STOCKSEE",
      },
      {
        CITY: "STOCKSTADT AM MAIN",
      },
      {
        CITY: "STOCKSTADT AM RHEIN",
      },
      {
        CITY: "STOETZE",
      },
      {
        CITY: "STOLBERG",
      },
      {
        CITY: "STOLK",
      },
      {
        CITY: "STOLLBERG",
      },
      {
        CITY: "STOLPEN",
      },
      {
        CITY: "STOLZENAU",
      },
      {
        CITY: "STOLZENHAGEN",
      },
      {
        CITY: "STORKOW",
      },
      {
        CITY: "STORNSTEIN",
      },
      {
        CITY: "STOTTEN",
      },
      {
        CITY: "STOTTERNHEIM",
      },
      {
        CITY: "STOTTWANG",
      },
      {
        CITY: "STRAELEN",
      },
      {
        CITY: "STRAHLUNGEN",
      },
      {
        CITY: "STRALSUND",
      },
      {
        CITY: "STRANDE",
      },
      {
        CITY: "STRASSBERG",
      },
      {
        CITY: "STRASSKIRCHEN",
      },
      {
        CITY: "STRASSLACH-DINGHARTING",
      },
      {
        CITY: "STRAUBING",
      },
      {
        CITY: "STRAUPITZ",
      },
      {
        CITY: "STRAUSBERG",
      },
      {
        CITY: "STRAUSSFURT",
      },
      {
        CITY: "STREICH",
      },
      {
        CITY: "STREITHAUSEN",
      },
      {
        CITY: "STRICKSCHEID",
      },
      {
        CITY: "STROHKIRCHEN",
      },
      {
        CITY: "STROHN",
      },
      {
        CITY: "STROMBERG",
      },
      {
        CITY: "STROMBERG",
      },
      {
        CITY: "STRUCKLINGEN",
      },
      {
        CITY: "STRULLENDORF",
      },
      {
        CITY: "STRUXDORF",
      },
      {
        CITY: "STUBENBERG",
      },
      {
        CITY: "STUHLINGEN",
      },
      {
        CITY: "STUHR",
      },
      {
        CITY: "STUKENBROCK",
      },
      {
        CITY: "STULLN",
      },
      {
        CITY: "STUTTGART",
      },
      {
        CITY: "STUTZENGRUN",
      },
      {
        CITY: "STUTZERBACH",
      },
      {
        CITY: "STYRUM",
      },
      {
        CITY: "SUCKOW",
      },
      {
        CITY: "SUDDENDORF",
      },
      {
        CITY: "SUDERAU",
      },
      {
        CITY: "SUDERBRARUP",
      },
      {
        CITY: "SUDERBURG",
      },
      {
        CITY: "SUDERHOLZ",
      },
      {
        CITY: "SUDERLUGUM",
      },
      {
        CITY: "SUDERSTAPEL",
      },
      {
        CITY: "SUDHEIDE",
      },
      {
        CITY: "SUDLOHN",
      },
      {
        CITY: "SUGENHEIM",
      },
      {
        CITY: "SUHL",
      },
      {
        CITY: "SUHLENDORF",
      },
      {
        CITY: "SULFELD",
      },
      {
        CITY: "SULINGEN",
      },
      {
        CITY: "SULZ AM NECKAR",
      },
      {
        CITY: "SULZBACH",
      },
      {
        CITY: "SULZBACH",
      },
      {
        CITY: "SULZBACH",
      },
      {
        CITY: "SULZBACH AM MAIN",
      },
      {
        CITY: "SULZBACH-ROSENBERG",
      },
      {
        CITY: "SULZBERG",
      },
      {
        CITY: "SULZEMOOS",
      },
      {
        CITY: "SULZFELD",
      },
      {
        CITY: "SULZFELD",
      },
      {
        CITY: "SULZGRIES",
      },
      {
        CITY: "SULZHEIM",
      },
      {
        CITY: "SULZHEIM",
      },
      {
        CITY: "SULZTHAL",
      },
      {
        CITY: "SUNCHING",
      },
      {
        CITY: "SUNDERN",
      },
      {
        CITY: "SUNDHAUSEN",
      },
      {
        CITY: "SUPPLINGEN",
      },
      {
        CITY: "SURHEIM",
      },
      {
        CITY: "SUSEL",
      },
      {
        CITY: "SUSSEN",
      },
      {
        CITY: "SYKE",
      },
      {
        CITY: "SYRAU",
      },
      {
        CITY: "TAARSTEDT",
      },
      {
        CITY: "TABARZ",
      },
      {
        CITY: "TACHERTING",
      },
      {
        CITY: "TACHING AM SEE",
      },
      {
        CITY: "TAILFINGEN",
      },
      {
        CITY: "TALHEIM",
      },
      {
        CITY: "TALKAU",
      },
      {
        CITY: "TAMM",
      },
      {
        CITY: "TANGENDORF",
      },
      {
        CITY: "TANGERHUTTE",
      },
      {
        CITY: "TANGERMUNDE",
      },
      {
        CITY: "TANGSTEDT",
      },
      {
        CITY: "TANGSTEDT",
      },
      {
        CITY: "TANN",
      },
      {
        CITY: "TANN",
      },
      {
        CITY: "TANNA",
      },
      {
        CITY: "TANNESBERG",
      },
      {
        CITY: "TANNHAUSEN",
      },
      {
        CITY: "TANTOW",
      },
      {
        CITY: "TAPFHEIM",
      },
      {
        CITY: "TAPPENBECK",
      },
      {
        CITY: "TARBEK",
      },
      {
        CITY: "TARMOW",
      },
      {
        CITY: "TARMSTEDT",
      },
      {
        CITY: "TARP",
      },
      {
        CITY: "TATING",
      },
      {
        CITY: "TAUBERBISCHOFSHEIM",
      },
      {
        CITY: "TAUBERFELD",
      },
      {
        CITY: "TAUBERRETTERSHEIM",
      },
      {
        CITY: "TAUCHA",
      },
      {
        CITY: "TAUCHE",
      },
      {
        CITY: "TAUFKIRCHEN",
      },
      {
        CITY: "TAUFKIRCHEN",
      },
      {
        CITY: "TAUNUSSTEIN",
      },
      {
        CITY: "TAURA",
      },
      {
        CITY: "TAWERN",
      },
      {
        CITY: "TECKLENBURG",
      },
      {
        CITY: "TEGERNHEIM",
      },
      {
        CITY: "TEGERNSEE",
      },
      {
        CITY: "TEICHWOLFRAMSDORF",
      },
      {
        CITY: "TEISENDORF",
      },
      {
        CITY: "TEISING",
      },
      {
        CITY: "TEISNACH",
      },
      {
        CITY: "TEISTUNGENBURG",
      },
      {
        CITY: "TELGTE",
      },
      {
        CITY: "TELLINGSTEDT",
      },
      {
        CITY: "TELTOW",
      },
      {
        CITY: "TEMMELS",
      },
      {
        CITY: "TEMPLIN",
      },
      {
        CITY: "TENGEN",
      },
      {
        CITY: "TENINGEN",
      },
      {
        CITY: "TENNENBRONN",
      },
      {
        CITY: "TERBORG",
      },
      {
        CITY: "TESCHOW",
      },
      {
        CITY: "TESPE",
      },
      {
        CITY: "TESSIN",
      },
      {
        CITY: "TETENBULL",
      },
      {
        CITY: "TETENHUSEN",
      },
      {
        CITY: "TETEROW",
      },
      {
        CITY: "TETTAU",
      },
      {
        CITY: "TETTAU",
      },
      {
        CITY: "TETTENWANG",
      },
      {
        CITY: "TETTNANG CASTLE",
      },
      {
        CITY: "TEUBLITZ",
      },
      {
        CITY: "TEUCHERN",
      },
      {
        CITY: "TEUGN",
      },
      {
        CITY: "TEUSCHNITZ",
      },
      {
        CITY: "TEUTSCHENTHAL",
      },
      {
        CITY: "THADEN",
      },
      {
        CITY: "THALE",
      },
      {
        CITY: "THALEISCHWEILER-FROSCHEN",
      },
      {
        CITY: "THALFANG",
      },
      {
        CITY: "THALHAUSEN",
      },
      {
        CITY: "THALHEIM",
      },
      {
        CITY: "THALHEIM",
      },
      {
        CITY: "THALLICHTENBERG",
      },
      {
        CITY: "THALLWITZ",
      },
      {
        CITY: "THALMASSING",
      },
      {
        CITY: "THALMASSING",
      },
      {
        CITY: "THALWENDEN",
      },
      {
        CITY: "THANNBERG",
      },
      {
        CITY: "THANNHAUSEN",
      },
      {
        CITY: "THARANDT",
      },
      {
        CITY: "THEDINGHAUSEN",
      },
      {
        CITY: "THEILENHOFEN",
      },
      {
        CITY: "THEILHEIM",
      },
      {
        CITY: "THEISSEN",
      },
      {
        CITY: "THELEY",
      },
      {
        CITY: "THEMAR",
      },
      {
        CITY: "THERMALBAD WIESENBAD",
      },
      {
        CITY: "THIENDORF",
      },
      {
        CITY: "THIERHAUPTEN",
      },
      {
        CITY: "THIERSTEIN",
      },
      {
        CITY: "THOLEY",
      },
      {
        CITY: "THOMA",
      },
      {
        CITY: "THOMASBURG",
      },
      {
        CITY: "THOMM",
      },
      {
        CITY: "THUINE",
      },
      {
        CITY: "THULBA",
      },
      {
        CITY: "THUM",
      },
      {
        CITY: "THUNGEN",
      },
      {
        CITY: "THUNGERSHEIM",
      },
      {
        CITY: "THUR",
      },
      {
        CITY: "THURKOW",
      },
      {
        CITY: "THURNAU",
      },
      {
        CITY: "THYRNAU",
      },
      {
        CITY: "THYROW",
      },
      {
        CITY: "TIDDISCHE",
      },
      {
        CITY: "TIEFENBACH",
      },
      {
        CITY: "TIEFENBACH",
      },
      {
        CITY: "TIEFENBRONN",
      },
      {
        CITY: "TIEFENORT",
      },
      {
        CITY: "TIEFENTHAL",
      },
      {
        CITY: "TIEPLITZ",
      },
      {
        CITY: "TIERINGEN",
      },
      {
        CITY: "TILLEDA",
      },
      {
        CITY: "TIMMASPE",
      },
      {
        CITY: "TIMMENDORF",
      },
      {
        CITY: "TIMMENDORF BEACH",
      },
      {
        CITY: "TIMMENRODE",
      },
      {
        CITY: "TINNUM",
      },
      {
        CITY: "TIRSCHENREUTH",
      },
      {
        CITY: "TITISEE-NEUSTADT",
      },
      {
        CITY: "TITTING",
      },
      {
        CITY: "TITTLING",
      },
      {
        CITY: "TITTMONING",
      },
      {
        CITY: "TITZ",
      },
      {
        CITY: "TOBERTITZ",
      },
      {
        CITY: "TODENBUTTEL",
      },
      {
        CITY: "TODENDORF",
      },
      {
        CITY: "TODESFELDE",
      },
      {
        CITY: "TODTENRIED",
      },
      {
        CITY: "TODTENWEIS",
      },
      {
        CITY: "TODTMOOS",
      },
      {
        CITY: "TODTNAU",
      },
      {
        CITY: "TODTNAUBERG",
      },
      {
        CITY: "TOGING AM INN",
      },
      {
        CITY: "TOMERDINGEN",
      },
      {
        CITY: "TONBACH",
      },
      {
        CITY: "TONISVORST",
      },
      {
        CITY: "TONNDORF",
      },
      {
        CITY: "TONNING",
      },
      {
        CITY: "TOPEN",
      },
      {
        CITY: "TOPPENSTEDT",
      },
      {
        CITY: "TORGAU",
      },
      {
        CITY: "TORGELOW",
      },
      {
        CITY: "TORNESCH",
      },
      {
        CITY: "TOSTEDT",
      },
      {
        CITY: "TRABEN-TRARBACH",
      },
      {
        CITY: "TRABITZ",
      },
      {
        CITY: "TRAISEN",
      },
      {
        CITY: "TRAITSCHING",
      },
      {
        CITY: "TRAPPENKAMP",
      },
      {
        CITY: "TRASSEM",
      },
      {
        CITY: "TRAUBING",
      },
      {
        CITY: "TRAUNREUT",
      },
      {
        CITY: "TRAUNSTEIN",
      },
      {
        CITY: "TRAUNWALCHEN",
      },
      {
        CITY: "TRAUTSKIRCHEN",
      },
      {
        CITY: "TREBBIN",
      },
      {
        CITY: "TREBEN",
      },
      {
        CITY: "TREBENDORF",
      },
      {
        CITY: "TREBSEN",
      },
      {
        CITY: "TREBUR",
      },
      {
        CITY: "TREFFELHAUSEN",
      },
      {
        CITY: "TREFFELSTEIN",
      },
      {
        CITY: "TREIA",
      },
      {
        CITY: "TREIS-KARDEN",
      },
      {
        CITY: "TREMSBUTTEL",
      },
      {
        CITY: "TRENDELBURG",
      },
      {
        CITY: "TRENT",
      },
      {
        CITY: "TREUCHTLINGEN",
      },
      {
        CITY: "TREUDELBERG",
      },
      {
        CITY: "TREUEN",
      },
      {
        CITY: "TREUENBRIETZEN",
      },
      {
        CITY: "TRIBERG IM SCHWARZWALD",
      },
      {
        CITY: "TRIER",
      },
      {
        CITY: "TRIERWEILER",
      },
      {
        CITY: "TRIFTERN",
      },
      {
        CITY: "TRIMBS",
      },
      {
        CITY: "TRIPPSTADT",
      },
      {
        CITY: "TRIPTIS",
      },
      {
        CITY: "TRITTAU",
      },
      {
        CITY: "TROCHTELFINGEN",
      },
      {
        CITY: "TROGEN",
      },
      {
        CITY: "TROISDORF",
      },
      {
        CITY: "TROLLENHAGEN",
      },
      {
        CITY: "TROSSINGEN",
      },
      {
        CITY: "TROSTAU",
      },
      {
        CITY: "TROSTBERG AN DER ALZ",
      },
      {
        CITY: "TRUCHTLACHING",
      },
      {
        CITY: "TRULBEN",
      },
      {
        CITY: "TRUNKELSBERG",
      },
      {
        CITY: "TRUSETAL",
      },
      {
        CITY: "TSCHERNITZ",
      },
      {
        CITY: "TUERKENFELD",
      },
      {
        CITY: "TULAU",
      },
      {
        CITY: "TUNINGEN",
      },
      {
        CITY: "TUNTENHAUSEN",
      },
      {
        CITY: "TURKHEIM",
      },
      {
        CITY: "TUSSENHAUSEN",
      },
      {
        CITY: "TUSZSHING",
      },
      {
        CITY: "TUTOW",
      },
      {
        CITY: "TUTTLINGEN",
      },
      {
        CITY: "TUTZING",
      },
      {
        CITY: "TUTZPATZ",
      },
      {
        CITY: "TWIELENFLETH",
      },
      {
        CITY: "TWIST",
      },
      {
        CITY: "TWISTE",
      },
      {
        CITY: "TWISTRINGEN",
      },
      {
        CITY: "UBACH-PALENBERG",
      },
      {
        CITY: "UBERFELD",
      },
      {
        CITY: "UBERHERRN",
      },
      {
        CITY: "UBERLINGEN",
      },
      {
        CITY: "UBERSEE",
      },
      {
        CITY: "UBSTADT-WEIHER",
      },
      {
        CITY: "UCHTE",
      },
      {
        CITY: "UCHTELHAUSEN",
      },
      {
        CITY: "UCKENDORF",
      },
      {
        CITY: "UDENHEIM",
      },
      {
        CITY: "UDER",
      },
      {
        CITY: "UEBIGAU",
      },
      {
        CITY: "UECKERMUNDE",
      },
      {
        CITY: "UEDEM",
      },
      {
        CITY: "UEHLFELD",
      },
      {
        CITY: "UELITZ",
      },
      {
        CITY: "UELSEN",
      },
      {
        CITY: "UELVESBULL",
      },
      {
        CITY: "UELZEN",
      },
      {
        CITY: "UERSFELD",
      },
      {
        CITY: "UESS",
      },
      {
        CITY: "UETERLANDE",
      },
      {
        CITY: "UETERSEN",
      },
      {
        CITY: "UETTINGEN",
      },
      {
        CITY: "UETZE",
      },
      {
        CITY: "UFFENHEIM",
      },
      {
        CITY: "UFFING",
      },
      {
        CITY: "UHINGEN",
      },
      {
        CITY: "UHLDINGEN-MUHLHOFEN",
      },
      {
        CITY: "UHLER",
      },
      {
        CITY: "UHLINGEN-BIRKENDORF",
      },
      {
        CITY: "ULBERSDORF",
      },
      {
        CITY: "ULM",
      },
      {
        CITY: "ULMEN",
      },
      {
        CITY: "ULMET",
      },
      {
        CITY: "ULRICHSTEIN",
      },
      {
        CITY: "ULSNIS",
      },
      {
        CITY: "UMKIRCH",
      },
      {
        CITY: "UMMENDORF",
      },
      {
        CITY: "UMPFENBACH",
      },
      {
        CITY: "UNKEL",
      },
      {
        CITY: "UNLINGEN",
      },
      {
        CITY: "UNNA",
      },
      {
        CITY: "UNNAU",
      },
      {
        CITY: "UNTERAMMERGAU",
      },
      {
        CITY: "UNTERBALDINGEN",
      },
      {
        CITY: "UNTERDIESSEN",
      },
      {
        CITY: "UNTERDIETFURT",
      },
      {
        CITY: "UNTEREGLFING",
      },
      {
        CITY: "UNTEREISESHEIM",
      },
      {
        CITY: "UNTERENSINGEN",
      },
      {
        CITY: "UNTEREUERHEIM",
      },
      {
        CITY: "UNTERFOEHRING",
      },
      {
        CITY: "UNTERGIMPERN",
      },
      {
        CITY: "UNTERGRIESBACH",
      },
      {
        CITY: "UNTERGRUPPENBACH",
      },
      {
        CITY: "UNTERHACHING",
      },
      {
        CITY: "UNTERHAUSEN",
      },
      {
        CITY: "UNTERKIRNACH",
      },
      {
        CITY: "UNTERLIEZHEIM",
      },
      {
        CITY: "UNTERLUSS",
      },
      {
        CITY: "UNTERMEITINGEN",
      },
      {
        CITY: "UNTERMUNKHEIM",
      },
      {
        CITY: "UNTERNEUKIRCHEN",
      },
      {
        CITY: "UNTERPLEICHFELD",
      },
      {
        CITY: "UNTERREICHENBACH",
      },
      {
        CITY: "UNTERREIT",
      },
      {
        CITY: "UNTERROTH",
      },
      {
        CITY: "UNTERSCHLEICHACH",
      },
      {
        CITY: "UNTERSCHLEISSHEIM",
      },
      {
        CITY: "UNTERSCHNEIDHEIM",
      },
      {
        CITY: "UNTERSIEMAU",
      },
      {
        CITY: "UNTERSTEINACH",
      },
      {
        CITY: "UNTERTHINGAU",
      },
      {
        CITY: "UNTERUHLDINGEN",
      },
      {
        CITY: "UNTERWELLENBORN",
      },
      {
        CITY: "UNTERWOSSEN",
      },
      {
        CITY: "UNTRASRIED",
      },
      {
        CITY: "UPGANT-SCHOTT",
      },
      {
        CITY: "URBACH",
      },
      {
        CITY: "URBACH",
      },
      {
        CITY: "URBAR",
      },
      {
        CITY: "UREXWEILER",
      },
      {
        CITY: "URMITZ",
      },
      {
        CITY: "URSENSOLLEN",
      },
      {
        CITY: "URSPRINGEN",
      },
      {
        CITY: "URWEILER",
      },
      {
        CITY: "URZIG",
      },
      {
        CITY: "USEDOM",
      },
      {
        CITY: "USINGEN",
      },
      {
        CITY: "USLAR",
      },
      {
        CITY: "USTERSBACH",
      },
      {
        CITY: "UTTENREUTH",
      },
      {
        CITY: "UTTENWEILER",
      },
      {
        CITY: "UTTING",
      },
      {
        CITY: "UTZENFELD",
      },
      {
        CITY: "UXHEIM",
      },
      {
        CITY: "VACHA",
      },
      {
        CITY: "VACHENDORF",
      },
      {
        CITY: "VAHLBRUCH",
      },
      {
        CITY: "VAHLDORF",
      },
      {
        CITY: "VAIHINGEN AN DER ENZ",
      },
      {
        CITY: "VALLENDAR",
      },
      {
        CITY: "VALLEY",
      },
      {
        CITY: "VAREL",
      },
      {
        CITY: "VASTORF",
      },
      {
        CITY: "VATERSTETTEN",
      },
      {
        CITY: "VECHELDE",
      },
      {
        CITY: "VECHTA",
      },
      {
        CITY: "VECKENSTEDT",
      },
      {
        CITY: "VEGESACK",
      },
      {
        CITY: "VEHLOW",
      },
      {
        CITY: "VEILSDORF",
      },
      {
        CITY: "VEITSBRONN",
      },
      {
        CITY: "VEITSHOCHHEIM",
      },
      {
        CITY: "VELBERT",
      },
      {
        CITY: "VELBURG",
      },
      {
        CITY: "VELDEN",
      },
      {
        CITY: "VELDENZ",
      },
      {
        CITY: "VELEN",
      },
      {
        CITY: "VELLAHN",
      },
      {
        CITY: "VELLBERG",
      },
      {
        CITY: "VELLMAR",
      },
      {
        CITY: "VELPKE",
      },
      {
        CITY: "VELTEN",
      },
      {
        CITY: "VELTHEIM",
      },
      {
        CITY: "VELTHEIM",
      },
      {
        CITY: "VENDERSHEIM",
      },
      {
        CITY: "VENNE",
      },
      {
        CITY: "VERCHEN",
      },
      {
        CITY: "VERDEN AN DER ALLER",
      },
      {
        CITY: "VERINGENDORF",
      },
      {
        CITY: "VERINGENSTADT",
      },
      {
        CITY: "VERL",
      },
      {
        CITY: "VERSMOLD",
      },
      {
        CITY: "VESSER",
      },
      {
        CITY: "VESTENBERG",
      },
      {
        CITY: "VESTENBERGSGREUTH",
      },
      {
        CITY: "VETSCHAU",
      },
      {
        CITY: "VETTELSCHOSS",
      },
      {
        CITY: "VETTWEISS",
      },
      {
        CITY: "VIECHELN",
      },
      {
        CITY: "VIECHTACH",
      },
      {
        CITY: "VIELBACH",
      },
      {
        CITY: "VIENENBURG",
      },
      {
        CITY: "VIER",
      },
      {
        CITY: "VIERDEN",
      },
      {
        CITY: "VIERETH-TRUNSTADT",
      },
      {
        CITY: "VIERHOFEN",
      },
      {
        CITY: "VIERKIRCHEN",
      },
      {
        CITY: "VIERNAU",
      },
      {
        CITY: "VIERNHEIM",
      },
      {
        CITY: "VIERSEN",
      },
      {
        CITY: "VIESELBACH",
      },
      {
        CITY: "VIETGEST",
      },
      {
        CITY: "VILLENBACH",
      },
      {
        CITY: "VILLINGEN",
      },
      {
        CITY: "VILLINGEN-SCHWENNINGEN",
      },
      {
        CITY: "VILLINGENDORF",
      },
      {
        CITY: "VILLMAR",
      },
      {
        CITY: "VILSBIBURG",
      },
      {
        CITY: "VILSECK",
      },
      {
        CITY: "VILSHEIM",
      },
      {
        CITY: "VILSHOFEN",
      },
      {
        CITY: "VILSHOVEN",
      },
      {
        CITY: "VISBEK",
      },
      {
        CITY: "VISSELHOVEDE",
      },
      {
        CITY: "VITTE",
      },
      {
        CITY: "VLOTHO",
      },
      {
        CITY: "VLUYN",
      },
      {
        CITY: "VOERDE",
      },
      {
        CITY: "VOGELSDORF",
      },
      {
        CITY: "VOGELSEN",
      },
      {
        CITY: "VOGT",
      },
      {
        CITY: "VOGTAREUTH",
      },
      {
        CITY: "VOGTSBURG",
      },
      {
        CITY: "VOHBURG AN DER DONAU",
      },
      {
        CITY: "VOHENSTRAUSS",
      },
      {
        CITY: "VOHL",
      },
      {
        CITY: "VOHRENBACH",
      },
      {
        CITY: "VOHRINGEN",
      },
      {
        CITY: "VOHRINGEN",
      },
      {
        CITY: "VOLKACH",
      },
      {
        CITY: "VOLKENSCHWAND",
      },
      {
        CITY: "VOLKENSHAGEN",
      },
      {
        CITY: "VOLKERSWEILER",
      },
      {
        CITY: "VOLKERTSHAUSEN",
      },
      {
        CITY: "VOLKLINGEN",
      },
      {
        CITY: "VOLKMARSEN",
      },
      {
        CITY: "VOLLERSODE",
      },
      {
        CITY: "VOLLERSRODA",
      },
      {
        CITY: "VOLLKOFEN",
      },
      {
        CITY: "VOLLRATHSRUHE",
      },
      {
        CITY: "VOLTLAGE",
      },
      {
        CITY: "VOLXHEIM",
      },
      {
        CITY: "VORBACH",
      },
      {
        CITY: "VORBACH",
      },
      {
        CITY: "VORDEN",
      },
      {
        CITY: "VORDORF",
      },
      {
        CITY: "VORLAND",
      },
      {
        CITY: "VORRA",
      },
      {
        CITY: "VORSTETTEN",
      },
      {
        CITY: "VORWERK",
      },
      {
        CITY: "VOSLAPP",
      },
      {
        CITY: "VREDEN",
      },
      {
        CITY: "WAAKIRCHEN",
      },
      {
        CITY: "WAAL",
      },
      {
        CITY: "WABERN",
      },
      {
        CITY: "WACHAU",
      },
      {
        CITY: "WACHENHEIM AN DER WEINSTRASSE",
      },
      {
        CITY: "WACHENROTH",
      },
      {
        CITY: "WACHSENBURG",
      },
      {
        CITY: "WACHTBERG",
      },
      {
        CITY: "WACHTENDONK",
      },
      {
        CITY: "WACHTERSBACH",
      },
      {
        CITY: "WACHTUM",
      },
      {
        CITY: "WACKEN",
      },
      {
        CITY: "WACKERNHEIM",
      },
      {
        CITY: "WACKEROW",
      },
      {
        CITY: "WACKERSDORF",
      },
      {
        CITY: "WADELSDORF",
      },
      {
        CITY: "WADERN",
      },
      {
        CITY: "WADERSLOH",
      },
      {
        CITY: "WADGASSEN",
      },
      {
        CITY: "WAFFENBRUNN",
      },
      {
        CITY: "WAGENFELD",
      },
      {
        CITY: "WAGENHOFF",
      },
      {
        CITY: "WAGERSROTT",
      },
      {
        CITY: "WAGHAUSEL",
      },
      {
        CITY: "WAGING AM SEE",
      },
      {
        CITY: "WAHLSTEDT",
      },
      {
        CITY: "WAHNWEGEN",
      },
      {
        CITY: "WAHRENHOLZ",
      },
      {
        CITY: "WAIBLINGEN",
      },
      {
        CITY: "WAIBSTADT",
      },
      {
        CITY: "WAIDHAUS",
      },
      {
        CITY: "WAIN",
      },
      {
        CITY: "WAISCHENFELD",
      },
      {
        CITY: "WAKENDORF",
      },
      {
        CITY: "WALCHUM",
      },
      {
        CITY: "WALD",
      },
      {
        CITY: "WALD",
      },
      {
        CITY: "WALD-MICHELBACH",
      },
      {
        CITY: "WALDALGESHEIM",
      },
      {
        CITY: "WALDASCHAFF",
      },
      {
        CITY: "WALDAU",
      },
      {
        CITY: "WALDBOCKELHEIM",
      },
      {
        CITY: "WALDBREITBACH",
      },
      {
        CITY: "WALDBROL",
      },
      {
        CITY: "WALDBRUNN",
      },
      {
        CITY: "WALDBURG",
      },
      {
        CITY: "WALDBUTTELBRUNN",
      },
      {
        CITY: "WALDECK",
      },
      {
        CITY: "WALDEMS",
      },
      {
        CITY: "WALDENBUCH",
      },
      {
        CITY: "WALDENBURG",
      },
      {
        CITY: "WALDENBURG",
      },
      {
        CITY: "WALDERBACH",
      },
      {
        CITY: "WALDERSHOF",
      },
      {
        CITY: "WALDESCH",
      },
      {
        CITY: "WALDFEUCHT",
      },
      {
        CITY: "WALDFISCHBACH-BURGALBEN",
      },
      {
        CITY: "WALDHAMBACH",
      },
      {
        CITY: "WALDHEIM",
      },
      {
        CITY: "WALDKAPPEL",
      },
      {
        CITY: "WALDKIRCH",
      },
      {
        CITY: "WALDKIRCHEN",
      },
      {
        CITY: "WALDKRAIBURG",
      },
      {
        CITY: "WALDLAUBERSHEIM",
      },
      {
        CITY: "WALDMOHR",
      },
      {
        CITY: "WALDMUNCHEN",
      },
      {
        CITY: "WALDORF",
      },
      {
        CITY: "WALDSASSEN",
      },
      {
        CITY: "WALDSCHLOSSCHEN",
      },
      {
        CITY: "WALDSEE",
      },
      {
        CITY: "WALDSHUT",
      },
      {
        CITY: "WALDSHUT-TIENGEN",
      },
      {
        CITY: "WALDSIEVERSDORF",
      },
      {
        CITY: "WALDSTETTEN",
      },
      {
        CITY: "WALHAUSEN",
      },
      {
        CITY: "WALHEIM",
      },
      {
        CITY: "WALKENRIED",
      },
      {
        CITY: "WALL",
      },
      {
        CITY: "WALLDORF",
      },
      {
        CITY: "WALLDORF",
      },
      {
        CITY: "WALLDURN",
      },
      {
        CITY: "WALLE",
      },
      {
        CITY: "WALLENDORF",
      },
      {
        CITY: "WALLENFELS",
      },
      {
        CITY: "WALLENHORST",
      },
      {
        CITY: "WALLERFANGEN",
      },
      {
        CITY: "WALLERSDORF",
      },
      {
        CITY: "WALLERSTEIN",
      },
      {
        CITY: "WALLERTHEIM",
      },
      {
        CITY: "WALLESHAUSEN",
      },
      {
        CITY: "WALLHAUSEN",
      },
      {
        CITY: "WALLHAUSEN",
      },
      {
        CITY: "WALLHAUSEN",
      },
      {
        CITY: "WALLMEROD",
      },
      {
        CITY: "WALLUF",
      },
      {
        CITY: "WALPERTSHOFEN",
      },
      {
        CITY: "WALPERTSKIRCHEN",
      },
      {
        CITY: "WALSDORF",
      },
      {
        CITY: "WALSHAUSEN",
      },
      {
        CITY: "WALSLEBEN",
      },
      {
        CITY: "WALSRODE",
      },
      {
        CITY: "WALTENHOFEN",
      },
      {
        CITY: "WALTERSDORF",
      },
      {
        CITY: "WALTERSHAUSEN",
      },
      {
        CITY: "WALTING",
      },
      {
        CITY: "WALTROP",
      },
      {
        CITY: "WANDLITZ",
      },
      {
        CITY: "WANFRIED",
      },
      {
        CITY: "WANG",
      },
      {
        CITY: "WANGEN",
      },
      {
        CITY: "WANGEN",
      },
      {
        CITY: "WANGEN IM ALLGAU",
      },
      {
        CITY: "WANGEROOGE",
      },
      {
        CITY: "WANKENDORF",
      },
      {
        CITY: "WANNA",
      },
      {
        CITY: "WANNE-EICKEL",
      },
      {
        CITY: "WANNWEIL",
      },
      {
        CITY: "WANSLEBEN",
      },
      {
        CITY: "WANZLEBEN",
      },
      {
        CITY: "WARBERG",
      },
      {
        CITY: "WARBURG",
      },
      {
        CITY: "WARDENBURG",
      },
      {
        CITY: "WARDOW",
      },
      {
        CITY: "WAREN",
      },
      {
        CITY: "WARENDORF",
      },
      {
        CITY: "WARIN",
      },
      {
        CITY: "WARMENSTEINACH",
      },
      {
        CITY: "WARMSEN",
      },
      {
        CITY: "WARNAU",
      },
      {
        CITY: "WARNGAU",
      },
      {
        CITY: "WARNOW",
      },
      {
        CITY: "WARRENZIN",
      },
      {
        CITY: "WARSTEIN",
      },
      {
        CITY: "WARTENBERG",
      },
      {
        CITY: "WARTENBERG-ROHRBACH",
      },
      {
        CITY: "WARTHAUSEN",
      },
      {
        CITY: "WARZA",
      },
      {
        CITY: "WASBEK",
      },
      {
        CITY: "WASBUTTEL",
      },
      {
        CITY: "WASCHENBACH",
      },
      {
        CITY: "WASCHENBEUREN",
      },
      {
        CITY: "WASENWEILER",
      },
      {
        CITY: "WASSENACH",
      },
      {
        CITY: "WASSENBERG",
      },
      {
        CITY: "WASSER",
      },
      {
        CITY: "WASSER",
      },
      {
        CITY: "WASSERBURG AM INN",
      },
      {
        CITY: "WASSERLEBEN",
      },
      {
        CITY: "WASSERLIESCH",
      },
      {
        CITY: "WASSERLOSEN",
      },
      {
        CITY: "WASSERMUNGENAU",
      },
      {
        CITY: "WASSERSLEBEN",
      },
      {
        CITY: "WASSERTRUDINGEN",
      },
      {
        CITY: "WASUNGEN",
      },
      {
        CITY: "WATHLINGEN",
      },
      {
        CITY: "WATTENBEK",
      },
      {
        CITY: "WATTENHEIM",
      },
      {
        CITY: "WATTMANNSHAGEN",
      },
      {
        CITY: "WATZENBORN",
      },
      {
        CITY: "WAXWEILER",
      },
      {
        CITY: "WECHINGEN",
      },
      {
        CITY: "WECHMAR",
      },
      {
        CITY: "WECHSELBURG",
      },
      {
        CITY: "WEDDEL",
      },
      {
        CITY: "WEDDELBROOK",
      },
      {
        CITY: "WEDDINGSTEDT",
      },
      {
        CITY: "WEDEL",
      },
      {
        CITY: "WEDRINGEN",
      },
      {
        CITY: "WEEDE",
      },
      {
        CITY: "WEENER",
      },
      {
        CITY: "WEENZEN",
      },
      {
        CITY: "WEES",
      },
      {
        CITY: "WEEZE",
      },
      {
        CITY: "WEFENSLEBEN",
      },
      {
        CITY: "WEFERLINGEN",
      },
      {
        CITY: "WEGBERG",
      },
      {
        CITY: "WEGELEBEN",
      },
      {
        CITY: "WEGSCHEID",
      },
      {
        CITY: "WEHINGEN",
      },
      {
        CITY: "WEHLHEIDEN",
      },
      {
        CITY: "WEHNRATH",
      },
      {
        CITY: "WEHR",
      },
      {
        CITY: "WEHRBLECK",
      },
      {
        CITY: "WEHRHEIM",
      },
      {
        CITY: "WEIBERG",
      },
      {
        CITY: "WEIBERN",
      },
      {
        CITY: "WEIBERSBRUNN",
      },
      {
        CITY: "WEICHERING",
      },
      {
        CITY: "WEICHS",
      },
      {
        CITY: "WEIDA",
      },
      {
        CITY: "WEIDEN",
      },
      {
        CITY: "WEIDEN",
      },
      {
        CITY: "WEIDEN",
      },
      {
        CITY: "WEIDEN",
      },
      {
        CITY: "WEIDENBACH",
      },
      {
        CITY: "WEIDENBERG",
      },
      {
        CITY: "WEIDENHAHN",
      },
      {
        CITY: "WEIDENHOF",
      },
      {
        CITY: "WEIDENTHAL",
      },
      {
        CITY: "WEIDHAUSEN",
      },
      {
        CITY: "WEIDING",
      },
      {
        CITY: "WEIGENDORF",
      },
      {
        CITY: "WEIHENZELL",
      },
      {
        CITY: "WEIHERHAMMER",
      },
      {
        CITY: "WEIHMICHL",
      },
      {
        CITY: "WEIKERSHEIM",
      },
      {
        CITY: "WEIL",
      },
      {
        CITY: "WEIL AM RHEIN",
      },
      {
        CITY: "WEIL DER STADT",
      },
      {
        CITY: "WEIL IM SCHONBUCH",
      },
      {
        CITY: "WEILAR",
      },
      {
        CITY: "WEILBACH",
      },
      {
        CITY: "WEILBACH",
      },
      {
        CITY: "WEILBURG",
      },
      {
        CITY: "WEILER",
      },
      {
        CITY: "WEILER-SIMMERBERG",
      },
      {
        CITY: "WEILERBACH",
      },
      {
        CITY: "WEILERSBACH",
      },
      {
        CITY: "WEILERSWIST",
      },
      {
        CITY: "WEILHEIM",
      },
      {
        CITY: "WEILHEIM",
      },
      {
        CITY: "WEILHEIM AN DER TECK",
      },
      {
        CITY: "WEILMUNSTER",
      },
      {
        CITY: "WEILTINGEN",
      },
      {
        CITY: "WEIMAR",
      },
      {
        CITY: "WEIMAR",
      },
      {
        CITY: "WEINAHR",
      },
      {
        CITY: "WEINBACH",
      },
      {
        CITY: "WEINBOHLA",
      },
      {
        CITY: "WEINGARTEN",
      },
      {
        CITY: "WEINGARTEN",
      },
      {
        CITY: "WEINHEIM",
      },
      {
        CITY: "WEINSBERG",
      },
      {
        CITY: "WEINSFELD",
      },
      {
        CITY: "WEINSHEIM",
      },
      {
        CITY: "WEINSTADT-ENDERSBACH",
      },
      {
        CITY: "WEISCHLITZ",
      },
      {
        CITY: "WEISEL",
      },
      {
        CITY: "WEISEN",
      },
      {
        CITY: "WEISENBACH",
      },
      {
        CITY: "WEISENDORF",
      },
      {
        CITY: "WEISENHEIM AM BERG",
      },
      {
        CITY: "WEISENHEIM AM SAND",
      },
      {
        CITY: "WEISKIRCHEN",
      },
      {
        CITY: "WEISMAIN",
      },
      {
        CITY: "WEISSACH",
      },
      {
        CITY: "WEISSANDT-GOLZAU",
      },
      {
        CITY: "WEISSBACH",
      },
      {
        CITY: "WEISSDORF",
      },
      {
        CITY: "WEISSEN",
      },
      {
        CITY: "WEISSENBORN",
      },
      {
        CITY: "WEISSENBRUNN",
      },
      {
        CITY: "WEISSENBURG",
      },
      {
        CITY: "WEISSENFELD",
      },
      {
        CITY: "WEISSENFELS",
      },
      {
        CITY: "WEISSENHORN",
      },
      {
        CITY: "WEISSENOHE",
      },
      {
        CITY: "WEISSENSEE",
      },
      {
        CITY: "WEISSENSTADT",
      },
      {
        CITY: "WEISSENTHURM",
      },
      {
        CITY: "WEISSWASSER",
      },
      {
        CITY: "WEISWEIL",
      },
      {
        CITY: "WEITEFELD",
      },
      {
        CITY: "WEITENHAGEN",
      },
      {
        CITY: "WEITERSBORN",
      },
      {
        CITY: "WEITERSBURG",
      },
      {
        CITY: "WEITERSTADT",
      },
      {
        CITY: "WEITERSWEILER",
      },
      {
        CITY: "WEITNAU",
      },
      {
        CITY: "WEITRAMSDORF",
      },
      {
        CITY: "WEITZSCHEN",
      },
      {
        CITY: "WEIXERAU",
      },
      {
        CITY: "WELBSLEBEN",
      },
      {
        CITY: "WELDEN",
      },
      {
        CITY: "WELDEN",
      },
      {
        CITY: "WELGESHEIM",
      },
      {
        CITY: "WELKENBACH",
      },
      {
        CITY: "WELLE",
      },
      {
        CITY: "WELLHEIM",
      },
      {
        CITY: "WELSCHBILLIG",
      },
      {
        CITY: "WELSHOFEN",
      },
      {
        CITY: "WELSLEBEN",
      },
      {
        CITY: "WELTEROD",
      },
      {
        CITY: "WELVER",
      },
      {
        CITY: "WELZHEIM",
      },
      {
        CITY: "WELZOW",
      },
      {
        CITY: "WEMDING",
      },
      {
        CITY: "WENDDORF",
      },
      {
        CITY: "WENDEBURG",
      },
      {
        CITY: "WENDELSTEIN",
      },
      {
        CITY: "WENDEN",
      },
      {
        CITY: "WENDHAUSEN",
      },
      {
        CITY: "WENDISCH EVERN",
      },
      {
        CITY: "WENDISCH RIETZ",
      },
      {
        CITY: "WENDLINGEN AM NECKAR",
      },
      {
        CITY: "WENDTORF",
      },
      {
        CITY: "WENGELSDORF",
      },
      {
        CITY: "WENNIGSEN",
      },
      {
        CITY: "WENTORF",
      },
      {
        CITY: "WENTORF BEI HAMBURG",
      },
      {
        CITY: "WENZENBACH",
      },
      {
        CITY: "WENZENDORF",
      },
      {
        CITY: "WENZINGEN",
      },
      {
        CITY: "WENZLOW",
      },
      {
        CITY: "WERBEN",
      },
      {
        CITY: "WERDA",
      },
      {
        CITY: "WERDAU",
      },
      {
        CITY: "WERDER",
      },
      {
        CITY: "WERDOHL",
      },
      {
        CITY: "WERL",
      },
      {
        CITY: "WERLABURGDORF",
      },
      {
        CITY: "WERLAU",
      },
      {
        CITY: "WERLTE",
      },
      {
        CITY: "WERMELSKIRCHEN",
      },
      {
        CITY: "WERMSDORF",
      },
      {
        CITY: "WERNAU",
      },
      {
        CITY: "WERNBERG-KOBLITZ",
      },
      {
        CITY: "WERNE",
      },
      {
        CITY: "WERNECK",
      },
      {
        CITY: "WERNERSBERG",
      },
      {
        CITY: "WERNEUCHEN",
      },
      {
        CITY: "WERNIGERODE",
      },
      {
        CITY: "WERNINGSHAUSEN",
      },
      {
        CITY: "WERNRODE",
      },
      {
        CITY: "WERPELOH",
      },
      {
        CITY: "WERSTE",
      },
      {
        CITY: "WERTACH",
      },
      {
        CITY: "WERTHEIM AM MAIN",
      },
      {
        CITY: "WERTHER",
      },
      {
        CITY: "WERTINGEN",
      },
      {
        CITY: "WESEL",
      },
      {
        CITY: "WESELBERG",
      },
      {
        CITY: "WESENBERG",
      },
      {
        CITY: "WESENDORF",
      },
      {
        CITY: "WESSELBUREN",
      },
      {
        CITY: "WESSELING",
      },
      {
        CITY: "WESSELN",
      },
      {
        CITY: "WESSISZELL",
      },
      {
        CITY: "WESSLING",
      },
      {
        CITY: "WESSOBRUNN ABBEY",
      },
      {
        CITY: "WESTE",
      },
      {
        CITY: "WESTENDORF",
      },
      {
        CITY: "WESTENSEE",
      },
      {
        CITY: "WESTER-OHRSTEDT",
      },
      {
        CITY: "WESTERBURG",
      },
      {
        CITY: "WESTERDEICHSTRICH",
      },
      {
        CITY: "WESTEREGELN",
      },
      {
        CITY: "WESTERGELLERSEN",
      },
      {
        CITY: "WESTERHEIM",
      },
      {
        CITY: "WESTERHEIM",
      },
      {
        CITY: "WESTERHOLT",
      },
      {
        CITY: "WESTERHORN",
      },
      {
        CITY: "WESTERKAPPELN",
      },
      {
        CITY: "WESTERLAND",
      },
      {
        CITY: "WESTERRONFELD",
      },
      {
        CITY: "WESTERSTEDE",
      },
      {
        CITY: "WESTERSTETTEN",
      },
      {
        CITY: "WESTFELD",
      },
      {
        CITY: "WESTHAUSEN",
      },
      {
        CITY: "WESTHAUSEN",
      },
      {
        CITY: "WESTHEIM",
      },
      {
        CITY: "WESTHEIM",
      },
      {
        CITY: "WESTHOFEN",
      },
      {
        CITY: "WESTKIRCHEN",
      },
      {
        CITY: "WESTRHAUDERFEHN",
      },
      {
        CITY: "WETSCHEN",
      },
      {
        CITY: "WETTELSHEIM",
      },
      {
        CITY: "WETTENBERG",
      },
      {
        CITY: "WETTER",
      },
      {
        CITY: "WETTER (RUHR)",
      },
      {
        CITY: "WETTERZEUBE",
      },
      {
        CITY: "WETTIN",
      },
      {
        CITY: "WETTRINGEN",
      },
      {
        CITY: "WETTSTETTEN",
      },
      {
        CITY: "WETZLAR",
      },
      {
        CITY: "WEYARN",
      },
      {
        CITY: "WEYERBUSCH",
      },
      {
        CITY: "WEYHAUSEN",
      },
      {
        CITY: "WEYHE",
      },
      {
        CITY: "WICHTE",
      },
      {
        CITY: "WICKEDE",
      },
      {
        CITY: "WICKLESGREUTH",
      },
      {
        CITY: "WIEBELSHEIM",
      },
      {
        CITY: "WIEDEMAR",
      },
      {
        CITY: "WIEDERAU",
      },
      {
        CITY: "WIEDERGELTINGEN",
      },
      {
        CITY: "WIEFELSTEDE",
      },
      {
        CITY: "WIEHE",
      },
      {
        CITY: "WIEHL",
      },
      {
        CITY: "WIEK",
      },
      {
        CITY: "WIELENBACH",
      },
      {
        CITY: "WIENHAUSEN",
      },
      {
        CITY: "WIENRODE",
      },
      {
        CITY: "WIERNSHEIM",
      },
      {
        CITY: "WIESAU",
      },
      {
        CITY: "WIESBACH",
      },
      {
        CITY: "WIESBACH",
      },
      {
        CITY: "WIESBADEN",
      },
      {
        CITY: "WIESECK",
      },
      {
        CITY: "WIESELBACH",
      },
      {
        CITY: "WIESENBACH",
      },
      {
        CITY: "WIESENBACH",
      },
      {
        CITY: "WIESENBURG",
      },
      {
        CITY: "WIESENFELD",
      },
      {
        CITY: "WIESENFELDEN",
      },
      {
        CITY: "WIESENSTEIG",
      },
      {
        CITY: "WIESENT",
      },
      {
        CITY: "WIESENTHEID",
      },
      {
        CITY: "WIESKE",
      },
      {
        CITY: "WIESLOCH",
      },
      {
        CITY: "WIESMOOR",
      },
      {
        CITY: "WIESTHAL",
      },
      {
        CITY: "WIETMARSCHEN",
      },
      {
        CITY: "WIETZE",
      },
      {
        CITY: "WIETZEN",
      },
      {
        CITY: "WIETZENDORF",
      },
      {
        CITY: "WIGGENSBACH",
      },
      {
        CITY: "WILBURGSTETTEN",
      },
      {
        CITY: "WILDAU",
      },
      {
        CITY: "WILDBERG",
      },
      {
        CITY: "WILDBERGERHUTTE",
      },
      {
        CITY: "WILDENBERG",
      },
      {
        CITY: "WILDENFELS",
      },
      {
        CITY: "WILDESHAUSEN",
      },
      {
        CITY: "WILDPOLDSRIED",
      },
      {
        CITY: "WILFERDINGEN",
      },
      {
        CITY: "WILGARTSWIESEN",
      },
      {
        CITY: "WILHELM",
      },
      {
        CITY: "WILHELMSBURG",
      },
      {
        CITY: "WILHELMSDORF",
      },
      {
        CITY: "WILHELMSDORF",
      },
      {
        CITY: "WILHELMSDORF",
      },
      {
        CITY: "WILHELMSFELD",
      },
      {
        CITY: "WILHELMSHAVEN",
      },
      {
        CITY: "WILHELMSHEIM",
      },
      {
        CITY: "WILHELMSTHAL",
      },
      {
        CITY: "WILHELMSTHAL",
      },
      {
        CITY: "WILHERMSDORF",
      },
      {
        CITY: "WILKAU-HASSLAU",
      },
      {
        CITY: "WILLEBADESSEN",
      },
      {
        CITY: "WILLENSEN",
      },
      {
        CITY: "WILLICH",
      },
      {
        CITY: "WILLINGEN",
      },
      {
        CITY: "WILLMARS",
      },
      {
        CITY: "WILLMENROD",
      },
      {
        CITY: "WILLMERSDORF",
      },
      {
        CITY: "WILLROTH",
      },
      {
        CITY: "WILLSTATT",
      },
      {
        CITY: "WILNSDORF",
      },
      {
        CITY: "WILSDRUFF",
      },
      {
        CITY: "WILSTEDT",
      },
      {
        CITY: "WILSTER",
      },
      {
        CITY: "WILSUM",
      },
      {
        CITY: "WILTHEN",
      },
      {
        CITY: "WILTINGEN",
      },
      {
        CITY: "WIMMELBURG",
      },
      {
        CITY: "WIMSHEIM",
      },
      {
        CITY: "WINCHERINGEN",
      },
      {
        CITY: "WINDACH",
      },
      {
        CITY: "WINDBERG",
      },
      {
        CITY: "WINDEBY",
      },
      {
        CITY: "WINDEHAUSEN",
      },
      {
        CITY: "WINDEN",
      },
      {
        CITY: "WINDEN",
      },
      {
        CITY: "WINDESHEIM",
      },
      {
        CITY: "WINDHAGEN",
      },
      {
        CITY: "WINDISCHESCHENBACH",
      },
      {
        CITY: "WINDISCHLEUBA",
      },
      {
        CITY: "WINDORF",
      },
      {
        CITY: "WINDSBACH",
      },
      {
        CITY: "WINGST",
      },
      {
        CITY: "WINHORING",
      },
      {
        CITY: "WINKELHAID",
      },
      {
        CITY: "WINKELHAID",
      },
      {
        CITY: "WINKWITZ",
      },
      {
        CITY: "WINNEN",
      },
      {
        CITY: "WINNENDEN",
      },
      {
        CITY: "WINNIGSTEDT",
      },
      {
        CITY: "WINNINGEN",
      },
      {
        CITY: "WINNWEILER",
      },
      {
        CITY: "WINSEN",
      },
      {
        CITY: "WINSEN",
      },
      {
        CITY: "WINSEN",
      },
      {
        CITY: "WINTERBACH",
      },
      {
        CITY: "WINTERBACH",
      },
      {
        CITY: "WINTERBERG",
      },
      {
        CITY: "WINTERHAUSEN",
      },
      {
        CITY: "WINTERLINGEN",
      },
      {
        CITY: "WINTERRIEDEN",
      },
      {
        CITY: "WINTERSDORF",
      },
      {
        CITY: "WINTRICH",
      },
      {
        CITY: "WINZELN",
      },
      {
        CITY: "WINZER",
      },
      {
        CITY: "WIPFELD",
      },
      {
        CITY: "WIPPERSHAIN",
      },
      {
        CITY: "WIRDUM",
      },
      {
        CITY: "WIRGES",
      },
      {
        CITY: "WIRSBERG",
      },
      {
        CITY: "WISCH",
      },
      {
        CITY: "WISCHHAFEN",
      },
      {
        CITY: "WISMAR",
      },
      {
        CITY: "WISPER",
      },
      {
        CITY: "WISSEN",
      },
      {
        CITY: "WISTEDT",
      },
      {
        CITY: "WITTDUN",
      },
      {
        CITY: "WITTEKINDSHOF",
      },
      {
        CITY: "WITTELSHOFEN",
      },
      {
        CITY: "WITTEN",
      },
      {
        CITY: "WITTENBECK",
      },
      {
        CITY: "WITTENBERG",
      },
      {
        CITY: "WITTENBERG",
      },
      {
        CITY: "WITTENBERGE",
      },
      {
        CITY: "WITTENBORN",
      },
      {
        CITY: "WITTENBURG",
      },
      {
        CITY: "WITTENFORDEN",
      },
      {
        CITY: "WITTGENDORF",
      },
      {
        CITY: "WITTGENSDORF",
      },
      {
        CITY: "WITTGERT",
      },
      {
        CITY: "WITTICHENAU",
      },
      {
        CITY: "WITTIGHAUSEN",
      },
      {
        CITY: "WITTINGEN",
      },
      {
        CITY: "WITTISLINGEN",
      },
      {
        CITY: "WITTLICH",
      },
      {
        CITY: "WITTMUND",
      },
      {
        CITY: "WITTNAU",
      },
      {
        CITY: "WITTORF",
      },
      {
        CITY: "WITTSTOCK",
      },
      {
        CITY: "WITZENHAUSEN",
      },
      {
        CITY: "WITZHAVE",
      },
      {
        CITY: "WITZIN",
      },
      {
        CITY: "WITZWORT",
      },
      {
        CITY: "WOFFLEBEN",
      },
      {
        CITY: "WOHLSBORN",
      },
      {
        CITY: "WOHLTORF",
      },
      {
        CITY: "WOHNBACH",
      },
      {
        CITY: "WOHNROD",
      },
      {
        CITY: "WOHRA",
      },
      {
        CITY: "WOHRDEN",
      },
      {
        CITY: "WOLDEGK",
      },
      {
        CITY: "WOLDERT",
      },
      {
        CITY: "WOLFACH",
      },
      {
        CITY: "WOLFEGG",
      },
      {
        CITY: "WOLFEN",
      },
      {
        CITY: "WOLFERLINGEN",
      },
      {
        CITY: "WOLFERODE",
      },
      {
        CITY: "WOLFERSDORF",
      },
      {
        CITY: "WOLFERSHEIM",
      },
      {
        CITY: "WOLFGANG",
      },
      {
        CITY: "WOLFHAGEN",
      },
      {
        CITY: "WOLFIS",
      },
      {
        CITY: "WOLFMANNSHAUSEN",
      },
      {
        CITY: "WOLFRAMS-ESCHENBACH",
      },
      {
        CITY: "WOLFRATSHAUSEN",
      },
      {
        CITY: "WOLFSBURG",
      },
      {
        CITY: "WOLFSCHLUGEN",
      },
      {
        CITY: "WOLFSEGG",
      },
      {
        CITY: "WOLFSHEIM",
      },
      {
        CITY: "WOLGAST",
      },
      {
        CITY: "WOLKENSTEIN",
      },
      {
        CITY: "WOLLBRANDSHAUSEN",
      },
      {
        CITY: "WOLLSTEIN",
      },
      {
        CITY: "WOLMIRSTEDT",
      },
      {
        CITY: "WOLNZACH",
      },
      {
        CITY: "WOLPERTSHAUSEN",
      },
      {
        CITY: "WOLPERTSWENDE",
      },
      {
        CITY: "WOLPINGHAUSEN",
      },
      {
        CITY: "WOLSDORF",
      },
      {
        CITY: "WOLSENDORF",
      },
      {
        CITY: "WOLSFELD",
      },
      {
        CITY: "WOLTERSDORF",
      },
      {
        CITY: "WOLTERSDORF",
      },
      {
        CITY: "WOLTERSDORF",
      },
      {
        CITY: "WOLTERSHAUSEN",
      },
      {
        CITY: "WOLTINGERODE",
      },
      {
        CITY: "WOMRATH",
      },
      {
        CITY: "WONFURT",
      },
      {
        CITY: "WONSEES",
      },
      {
        CITY: "WORBIS",
      },
      {
        CITY: "WORFELDEN",
      },
      {
        CITY: "WORMS",
      },
      {
        CITY: "WORMSTEDT",
      },
      {
        CITY: "WORPSWEDE",
      },
      {
        CITY: "WORRSTADT",
      },
      {
        CITY: "WORTH",
      },
      {
        CITY: "WORTH AM MAIN",
      },
      {
        CITY: "WORTH AM RHEIN",
      },
      {
        CITY: "WORTHSEE",
      },
      {
        CITY: "WOTERSEN",
      },
      {
        CITY: "WREDENHAGEN",
      },
      {
        CITY: "WREMEN",
      },
      {
        CITY: "WRESTEDT",
      },
      {
        CITY: "WRIEDEL",
      },
      {
        CITY: "WRIEZEN",
      },
      {
        CITY: "WRIST",
      },
      {
        CITY: "WRIXUM",
      },
      {
        CITY: "WUELFRATH",
      },
      {
        CITY: "WULFEN",
      },
      {
        CITY: "WULFEN",
      },
      {
        CITY: "WULFTEN",
      },
      {
        CITY: "WUMBACH",
      },
      {
        CITY: "WUNDERSLEBEN",
      },
      {
        CITY: "WUNNENBERG",
      },
      {
        CITY: "WUNSIEDEL",
      },
      {
        CITY: "WUNSTORF",
      },
      {
        CITY: "WUPPERTAL",
      },
      {
        CITY: "WURMANNSQUICK",
      },
      {
        CITY: "WURMBERG",
      },
      {
        CITY: "WURMLINGEN",
      },
      {
        CITY: "WURMSHAM",
      },
      {
        CITY: "WURSELEN",
      },
      {
        CITY: "WURZBACH",
      },
      {
        CITY: "WURZBURG",
      },
      {
        CITY: "WURZEN",
      },
      {
        CITY: "WUSTENRIET",
      },
      {
        CITY: "WUSTENROT",
      },
      {
        CITY: "WUSTERHAUSEN",
      },
      {
        CITY: "WUSTERHUSEN",
      },
      {
        CITY: "WUSTERMARK",
      },
      {
        CITY: "WUSTERWITZ",
      },
      {
        CITY: "WUSTHEUTERODE",
      },
      {
        CITY: "WUSTWEILER",
      },
      {
        CITY: "WUTOSCHINGEN",
      },
      {
        CITY: "WYHL",
      },
      {
        CITY: "WYK AUF FOHR",
      },
      {
        CITY: "XANTEN",
      },
      {
        CITY: "ZABERFELD",
      },
      {
        CITY: "ZAHNA",
      },
      {
        CITY: "ZAININGEN",
      },
      {
        CITY: "ZAISENHAUSEN",
      },
      {
        CITY: "ZAISERTSHOFEN",
      },
      {
        CITY: "ZAPEL",
      },
      {
        CITY: "ZAPFENDORF",
      },
      {
        CITY: "ZARPEN",
      },
      {
        CITY: "ZARRENDORF",
      },
      {
        CITY: "ZARRENTIN",
      },
      {
        CITY: "ZAULSDORF",
      },
      {
        CITY: "ZECHLIN DORF",
      },
      {
        CITY: "ZEHDENICK",
      },
      {
        CITY: "ZEIL",
      },
      {
        CITY: "ZEISKAM",
      },
      {
        CITY: "ZEITHAIN",
      },
      {
        CITY: "ZEITLOFS",
      },
      {
        CITY: "ZEITZ",
      },
      {
        CITY: "ZELL",
      },
      {
        CITY: "ZELL",
      },
      {
        CITY: "ZELL",
      },
      {
        CITY: "ZELL AM HARMERSBACH",
      },
      {
        CITY: "ZELL AM MAIN",
      },
      {
        CITY: "ZELL IM WIESENTAL",
      },
      {
        CITY: "ZELL UNTER AICHELBERG",
      },
      {
        CITY: "ZELLA-MEHLIS",
      },
      {
        CITY: "ZELLINGEN",
      },
      {
        CITY: "ZELTINGEN-RACHTIG",
      },
      {
        CITY: "ZEMMER",
      },
      {
        CITY: "ZEMPIN",
      },
      {
        CITY: "ZEPPERNICK",
      },
      {
        CITY: "ZERBEN",
      },
      {
        CITY: "ZERBST",
      },
      {
        CITY: "ZERF",
      },
      {
        CITY: "ZERNIEN",
      },
      {
        CITY: "ZERRE",
      },
      {
        CITY: "ZERRENTHIN",
      },
      {
        CITY: "ZETEL",
      },
      {
        CITY: "ZEULENRODA",
      },
      {
        CITY: "ZEUTHEN",
      },
      {
        CITY: "ZEVEN",
      },
      {
        CITY: "ZIEGENDORF",
      },
      {
        CITY: "ZIELITZ",
      },
      {
        CITY: "ZIEMETSHAUSEN",
      },
      {
        CITY: "ZIERENBERG",
      },
      {
        CITY: "ZIESAR",
      },
      {
        CITY: "ZIESENDORF",
      },
      {
        CITY: "ZILTENDORF",
      },
      {
        CITY: "ZIMMERN",
      },
      {
        CITY: "ZIMMERN",
      },
      {
        CITY: "ZIMMERN OB ROTTWEIL",
      },
      {
        CITY: "ZINGST",
      },
      {
        CITY: "ZIPPLINGEN",
      },
      {
        CITY: "ZIRKOW",
      },
      {
        CITY: "ZIRNDORF",
      },
      {
        CITY: "ZITTAU",
      },
      {
        CITY: "ZOLLING",
      },
      {
        CITY: "ZOLLSTOCK",
      },
      {
        CITY: "ZORBIG",
      },
      {
        CITY: "ZORGE",
      },
      {
        CITY: "ZORNEDING",
      },
      {
        CITY: "ZORNHEIM",
      },
      {
        CITY: "ZOSCHINGEN",
      },
      {
        CITY: "ZOSSEN",
      },
      {
        CITY: "ZOTTELSTEDT",
      },
      {
        CITY: "ZSCHOPAU",
      },
      {
        CITY: "ZSCHORLAU",
      },
      {
        CITY: "ZSCHORNEWITZ",
      },
      {
        CITY: "ZSCHORTAU",
      },
      {
        CITY: "ZUCHERING",
      },
      {
        CITY: "ZUFFENHAUSEN",
      },
      {
        CITY: "ZUHLSDORF",
      },
      {
        CITY: "ZULPICH",
      },
      {
        CITY: "ZUSAMALTHEIM",
      },
      {
        CITY: "ZUSMARSHAUSEN",
      },
      {
        CITY: "ZUSSOW",
      },
      {
        CITY: "ZUZENHAUSEN",
      },
      {
        CITY: "ZWEIFLINGEN",
      },
      {
        CITY: "ZWENKAU",
      },
      {
        CITY: "ZWESTEN",
      },
      {
        CITY: "ZWICKAU",
      },
      {
        CITY: "ZWIEFALTEN",
      },
      {
        CITY: "ZWIESEL",
      },
      {
        CITY: "ZWINGENBERG",
      },
      {
        CITY: "ZWINGENBERG",
      },
      {
        CITY: "ZWONITZ",
      },
    ],
  },
  {
    COUNTRY: "GHANA",
    CODE: "GH",
    CITIES: [
      {
        CITY: "ACCRA",
      },
      {
        CITY: "BAWKU",
      },
      {
        CITY: "BEREKUM",
      },
      {
        CITY: "BOLGATANGA",
      },
      {
        CITY: "CAPE COAST",
      },
      {
        CITY: "HOME",
      },
      {
        CITY: "KOFORIDUA",
      },
      {
        CITY: "KUMASI",
      },
      {
        CITY: "LEGON",
      },
      {
        CITY: "MAMPONG",
      },
      {
        CITY: "NAVRONGO",
      },
      {
        CITY: "SUNYANI",
      },
      {
        CITY: "TAKORADI",
      },
      {
        CITY: "TEMA",
      },
      {
        CITY: "WA",
      },
      {
        CITY: "WINNEBA",
      },
    ],
  },
  {
    COUNTRY: "GUATEMALA",
    CODE: "GT",
    CITIES: [
      {
        CITY: "ANTIGUA GUATEMALA",
      },
      {
        CITY: "CAMBOTE",
      },
      {
        CITY: "CATARINA",
      },
      {
        CITY: "CENTRAL",
      },
      {
        CITY: "CHIMALTENANGO",
      },
      {
        CITY: "CHIQUIMULA",
      },
      {
        CITY: "CIUDAD VIEJA",
      },
      {
        CITY: "COBAN",
      },
      {
        CITY: "EL LIMON",
      },
      {
        CITY: "EL NARANJO",
      },
      {
        CITY: "EL SALVADOR",
      },
      {
        CITY: "ESCUINTLA",
      },
      {
        CITY: "ESQUIPULAS",
      },
      {
        CITY: "FLORES",
      },
      {
        CITY: "GUATEMALA CITY",
      },
      {
        CITY: "HUEHUETENANGO",
      },
      {
        CITY: "JOCOTENANGO",
      },
      {
        CITY: "LA REFORMA",
      },
      {
        CITY: "LA REFORMA",
      },
      {
        CITY: "MAZATENANGO",
      },
      {
        CITY: "MELCHOR DE MENCOS",
      },
      {
        CITY: "MIXCO",
      },
      {
        CITY: "PALIN",
      },
      {
        CITY: "PANAJACHEL",
      },
      {
        CITY: "PETAPA",
      },
      {
        CITY: "PUERTO BARRIOS",
      },
      {
        CITY: "QUESADA",
      },
      {
        CITY: "QUETZALTENANGO",
      },
      {
        CITY: "RETALHULEU",
      },
      {
        CITY: "SAN ANTONIO MIRAMAR",
      },
      {
        CITY: "SAN JOSE PINULA",
      },
      {
        CITY: "SAN JUAN",
      },
      {
        CITY: "SAN MARCOS",
      },
      {
        CITY: "SAN PEDRO SACATEPEQUEZ",
      },
      {
        CITY: "SANTA CATARINA PINULA",
      },
      {
        CITY: "SANTA CRUZ LA LAGUNA",
      },
      {
        CITY: "SANTA CRUZ NARANJO",
      },
      {
        CITY: "SANTA LUCIA COTZUMALGUAPA",
      },
      {
        CITY: "SANTA ROSA",
      },
      {
        CITY: "SOLOLA",
      },
      {
        CITY: "VILLA NUEVA",
      },
      {
        CITY: "VISTA HERMOSA",
      },
      {
        CITY: "ZACAPA",
      },
    ],
  },
  {
    COUNTRY: "HAITI",
    CODE: "HT",
    CITIES: [
      {
        CITY: "CARREFOUR",
      },
      {
        CITY: "DELMAR",
      },
      {
        CITY: "DUVERGER",
      },
      {
        CITY: "JACMEL",
      },
      {
        CITY: "MASSEAU",
      },
      {
        CITY: "MOISE",
      },
      {
        CITY: "PETIONVILLE",
      },
      {
        CITY: "PORT-AU-PRINCE",
      },
      {
        CITY: "PRINCE",
      },
      {
        CITY: "TURGEAU",
      },
    ],
  },
  {
    COUNTRY: "HONDURAS",
    CODE: "HN",
    CITIES: [
      {
        CITY: "CHOLOMA",
      },
      {
        CITY: "COMAYAGUA",
      },
      {
        CITY: "COMAYAGUELA",
      },
      {
        CITY: "COXEN HOLE",
      },
      {
        CITY: "EL BARRO",
      },
      {
        CITY: "EL PARAISO",
      },
      {
        CITY: "EL PROGRESO",
      },
      {
        CITY: "LA CEIBA",
      },
      {
        CITY: "LA HACIENDA",
      },
      {
        CITY: "MORAZAN",
      },
      {
        CITY: "NACAOME",
      },
      {
        CITY: "PINALEJO",
      },
      {
        CITY: "PIRAERA",
      },
      {
        CITY: "PUERTO LEMPIRA",
      },
      {
        CITY: "SAN ANTONIO DE FLORES",
      },
      {
        CITY: "SAN PEDRO SULA",
      },
      {
        CITY: "SANTA BARBARA",
      },
      {
        CITY: "SULA",
      },
      {
        CITY: "TEGUCIGALPA",
      },
    ],
  },
  {
    COUNTRY: "GREENLAND",
    CODE: "GL",
    CITIES: [
      {
        CITY: "AASIAAT",
      },
      {
        CITY: "ILULISSAT",
      },
      {
        CITY: "KAPISILLIT",
      },
      {
        CITY: "MANIITSOQ",
      },
      {
        CITY: "NARSAQ",
      },
      {
        CITY: "NARSARSUAQ",
      },
      {
        CITY: "NUUK",
      },
      {
        CITY: "NUUSSUAQ",
      },
      {
        CITY: "PAAMIUT",
      },
      {
        CITY: "QAQORTOQ",
      },
      {
        CITY: "QASIGIANNGUIT",
      },
      {
        CITY: "QEQERTARSUAQ",
      },
      {
        CITY: "QEQERTAT",
      },
      {
        CITY: "SISIMIUT",
      },
      {
        CITY: "TASIILAQ",
      },
      {
        CITY: "UPERNAVIK",
      },
      {
        CITY: "UUMMANNAQ KOMMUNE",
      },
    ],
  },
  {
    COUNTRY: "GUAM",
    CODE: "GU",
    CITIES: [
      {
        CITY: "BARRIGADA VILLAGE",
      },
      {
        CITY: "DEDEDO VILLAGE",
      },
      {
        CITY: "INARAJAN VILLAGE",
      },
      {
        CITY: "SANTA RITA",
      },
      {
        CITY: "TAMUNING-TUMON-HARMON VILLAGE",
      },
      {
        CITY: "YIGO VILLAGE",
      },
    ],
  },
  {
    COUNTRY: "GREECE",
    CODE: "GR",
    CITIES: [
      {
        CITY: "AEGINA",
      },
      {
        CITY: "AGIOI ANARGYROI",
      },
      {
        CITY: "AGIOS NIKOLAOS",
      },
      {
        CITY: "AGRINIO",
      },
      {
        CITY: "AIGALEO",
      },
      {
        CITY: "AIGIO",
      },
      {
        CITY: "ALEXANDREIA",
      },
      {
        CITY: "ALEXANDROUPOLI",
      },
      {
        CITY: "ALIARTOS",
      },
      {
        CITY: "ALIMOS",
      },
      {
        CITY: "AMALIADA",
      },
      {
        CITY: "ANAVYSSOS",
      },
      {
        CITY: "ANDRAVIDA",
      },
      {
        CITY: "ANO LIOSIA",
      },
      {
        CITY: "ARCHAIA NEMEA",
      },
      {
        CITY: "ARGOS",
      },
      {
        CITY: "ARGOSTOLI",
      },
      {
        CITY: "ARGYROUPOLI",
      },
      {
        CITY: "ARTA",
      },
      {
        CITY: "ASPROPYRGOS",
      },
      {
        CITY: "ATHENS",
      },
      {
        CITY: "ATTICA",
      },
      {
        CITY: "AYIOS STEFANOS",
      },
      {
        CITY: "CHALCIS",
      },
      {
        CITY: "CHANIA",
      },
      {
        CITY: "CHANIA",
      },
      {
        CITY: "CHIOS",
      },
      {
        CITY: "CHOLARGOS",
      },
      {
        CITY: "CORFU",
      },
      {
        CITY: "CORINTH",
      },
      {
        CITY: "DAFNI",
      },
      {
        CITY: "DIONYSOS",
      },
      {
        CITY: "DRAMA",
      },
      {
        CITY: "EKALI",
      },
      {
        CITY: "ELASSONA",
      },
      {
        CITY: "ELEFSINA",
      },
      {
        CITY: "ELLINIKO",
      },
      {
        CITY: "ERETRIA",
      },
      {
        CITY: "FARSALA",
      },
      {
        CITY: "FILIPPIADA",
      },
      {
        CITY: "FILOTHEI",
      },
      {
        CITY: "FLORINA",
      },
      {
        CITY: "GALATSI",
      },
      {
        CITY: "GIANNITSA",
      },
      {
        CITY: "GLYFADA",
      },
      {
        CITY: "GONNOI",
      },
      {
        CITY: "GOUMENISSA",
      },
      {
        CITY: "HERAKLION",
      },
      {
        CITY: "HYDRA",
      },
      {
        CITY: "IALYSOS",
      },
      {
        CITY: "IERAPETRA",
      },
      {
        CITY: "IGOUMENITSA",
      },
      {
        CITY: "IOANNINA",
      },
      {
        CITY: "KAISARIANI",
      },
      {
        CITY: "KALAMARIA",
      },
      {
        CITY: "KALAMATA",
      },
      {
        CITY: "KALAMOS",
      },
      {
        CITY: "KALLITHEA",
      },
      {
        CITY: "KALYMNOS",
      },
      {
        CITY: "KAMATERO",
      },
      {
        CITY: "KARDITSA",
      },
      {
        CITY: "KASSIOPI",
      },
      {
        CITY: "KASTORIA",
      },
      {
        CITY: "KATERINI",
      },
      {
        CITY: "KAVALA",
      },
      {
        CITY: "KERATEA",
      },
      {
        CITY: "KERATSINI",
      },
      {
        CITY: "KIFISSIA",
      },
      {
        CITY: "KILKIS",
      },
      {
        CITY: "KOMOTINI",
      },
      {
        CITY: "KOROPI",
      },
      {
        CITY: "KOS",
      },
      {
        CITY: "KOUVARAS",
      },
      {
        CITY: "KOZANI",
      },
      {
        CITY: "KRANIDI",
      },
      {
        CITY: "KRYONERION",
      },
      {
        CITY: "KYMI",
      },
      {
        CITY: "LAMIA",
      },
      {
        CITY: "LARISA",
      },
      {
        CITY: "LEFKADA",
      },
      {
        CITY: "LEFKIMMI",
      },
      {
        CITY: "LEONTARIO",
      },
      {
        CITY: "LITOCHORO",
      },
      {
        CITY: "LIXOURI",
      },
      {
        CITY: "LOUTRAKI",
      },
      {
        CITY: "LYKOVRYSI",
      },
      {
        CITY: "MAGOULA",
      },
      {
        CITY: "MAKRYGIALOS",
      },
      {
        CITY: "MANDRA",
      },
      {
        CITY: "MARATHON",
      },
      {
        CITY: "MARKOPOULO MESOGAIAS",
      },
      {
        CITY: "MEGALOCHARI",
      },
      {
        CITY: "MEGARA",
      },
      {
        CITY: "MELISSIA",
      },
      {
        CITY: "MESAGROS",
      },
      {
        CITY: "MESSOLONGHI",
      },
      {
        CITY: "METAXADES",
      },
      {
        CITY: "MOIRES",
      },
      {
        CITY: "MYTILENE",
      },
      {
        CITY: "NAFPAKTOS",
      },
      {
        CITY: "NAFPLION",
      },
      {
        CITY: "NAOUSA",
      },
      {
        CITY: "NAXOS",
      },
      {
        CITY: "NEA ALIKARNASSOS",
      },
      {
        CITY: "NEA FILADELFEIA",
      },
      {
        CITY: "NEA IONIA",
      },
      {
        CITY: "NEA KIOS",
      },
      {
        CITY: "NEA MAKRI",
      },
      {
        CITY: "NEA PERAMOS",
      },
      {
        CITY: "NEA SMYRNI",
      },
      {
        CITY: "NIKAIA",
      },
      {
        CITY: "ORESTIADA",
      },
      {
        CITY: "ORMYLIA",
      },
      {
        CITY: "OROPOS",
      },
      {
        CITY: "PAIANIA",
      },
      {
        CITY: "PALLINI",
      },
      {
        CITY: "PALOUKIA",
      },
      {
        CITY: "PANORAMA",
      },
      {
        CITY: "PARGA",
      },
      {
        CITY: "PATMOS",
      },
      {
        CITY: "PEFKI",
      },
      {
        CITY: "PELLA",
      },
      {
        CITY: "PERAMA",
      },
      {
        CITY: "PERISTERI",
      },
      {
        CITY: "PERISTERI",
      },
      {
        CITY: "PIKERMI",
      },
      {
        CITY: "PIRAEUS",
      },
      {
        CITY: "PLATY",
      },
      {
        CITY: "POLICHNI",
      },
      {
        CITY: "PORTO RAFTI",
      },
      {
        CITY: "PREVEZA",
      },
      {
        CITY: "PSYCHIKO",
      },
      {
        CITY: "PTOLEMAIDA",
      },
      {
        CITY: "PYLAIA",
      },
      {
        CITY: "PYRGOS",
      },
      {
        CITY: "RAFINA",
      },
      {
        CITY: "RETHYMNO",
      },
      {
        CITY: "RHODES",
      },
      {
        CITY: "RIO",
      },
      {
        CITY: "SALAMINA",
      },
      {
        CITY: "SAMOTHRAKI",
      },
      {
        CITY: "SERRES",
      },
      {
        CITY: "SERVIA",
      },
      {
        CITY: "SINDOS",
      },
      {
        CITY: "SKALA",
      },
      {
        CITY: "SKALA OROPOU",
      },
      {
        CITY: "SKIATHOS",
      },
      {
        CITY: "SPARTA",
      },
      {
        CITY: "SPATA",
      },
      {
        CITY: "SYMI",
      },
      {
        CITY: "TAVROS",
      },
      {
        CITY: "THEBES",
      },
      {
        CITY: "THERMI",
      },
      {
        CITY: "THESSALONIKI",
      },
      {
        CITY: "TINOS",
      },
      {
        CITY: "TRIKALA",
      },
      {
        CITY: "TRIPOLI",
      },
      {
        CITY: "VARI",
      },
      {
        CITY: "VARKIZA",
      },
      {
        CITY: "VERGINA",
      },
      {
        CITY: "VEROIA",
      },
      {
        CITY: "VOLOS",
      },
      {
        CITY: "VOULA",
      },
      {
        CITY: "VOULIAGMENI",
      },
      {
        CITY: "XANTHI",
      },
      {
        CITY: "XYLOKASTRO",
      },
      {
        CITY: "ZAKYNTHOS",
      },
    ],
  },
  {
    COUNTRY: "GUADELOUPE",
    CODE: "GP",
    CITIES: [
      {
        CITY: "ANSE-BERTRAND",
      },
      {
        CITY: "BAIE MAHAULT",
      },
      {
        CITY: "BAIE-MAHAULT",
      },
      {
        CITY: "BAILLIF",
      },
      {
        CITY: "BASSE-TERRE",
      },
      {
        CITY: "CAPESTERRE-BELLE-EAU",
      },
      {
        CITY: "CAPESTERRE-DE-MARIE-GALANTE",
      },
      {
        CITY: "DESHAIES",
      },
      {
        CITY: "GOURBEYRE",
      },
      {
        CITY: "GOYAVE",
      },
      {
        CITY: "GRAND-BOURG",
      },
      {
        CITY: "LAMENTIN",
      },
      {
        CITY: "LE GOSIER",
      },
      {
        CITY: "LE MOULE",
      },
      {
        CITY: "LES ABYMES",
      },
      {
        CITY: "PETIT-BOURG",
      },
      {
        CITY: "PETIT-CANAL",
      },
      {
        CITY: "POINTE-NOIRE",
      },
      {
        CITY: "PORT-LOUIS",
      },
      {
        CITY: "SAINT-CLAUDE",
      },
      {
        CITY: "SAINT-FRANCOIS",
      },
      {
        CITY: "SAINT-LOUIS",
      },
      {
        CITY: "SAINTE-ANNE",
      },
      {
        CITY: "SAINTE-ROSE",
      },
      {
        CITY: "TERRE-DE-BAS",
      },
      {
        CITY: "TERRE-DE-HAUT",
      },
      {
        CITY: "TROIS-RIVIERES",
      },
      {
        CITY: "VILLAGE",
      },
    ],
  },
  {
    COUNTRY: "HUNGARY",
    CODE: "HU",
    CITIES: [
      {
        CITY: "ABAUJSZANTO",
      },
      {
        CITY: "ABDA",
      },
      {
        CITY: "ABONY",
      },
      {
        CITY: "ACS",
      },
      {
        CITY: "ACSA",
      },
      {
        CITY: "ADACS",
      },
      {
        CITY: "ADONY",
      },
      {
        CITY: "AGARD",
      },
      {
        CITY: "AJAK",
      },
      {
        CITY: "AJKA",
      },
      {
        CITY: "ALAP",
      },
      {
        CITY: "ALBERTIRSA",
      },
      {
        CITY: "ALMASFUZITO",
      },
      {
        CITY: "ALMASKAMARAS",
      },
      {
        CITY: "ALMOSD",
      },
      {
        CITY: "ALSOGALLA",
      },
      {
        CITY: "ALSONEMEDI",
      },
      {
        CITY: "ALSOPAHOK",
      },
      {
        CITY: "ALSOSZENTIVAN",
      },
      {
        CITY: "ALSOTOLD",
      },
      {
        CITY: "ALSOZSOLCA",
      },
      {
        CITY: "APARHANT",
      },
      {
        CITY: "APATFALVA",
      },
      {
        CITY: "APC",
      },
      {
        CITY: "APOSTAG",
      },
      {
        CITY: "ARNOT",
      },
      {
        CITY: "ASOTTHALOM",
      },
      {
        CITY: "ASZALO",
      },
      {
        CITY: "ASZAR",
      },
      {
        CITY: "ASZOD",
      },
      {
        CITY: "ATKAR",
      },
      {
        CITY: "ATTALA",
      },
      {
        CITY: "BABOLNA",
      },
      {
        CITY: "BACSALMAS",
      },
      {
        CITY: "BADACSONYTOMAJ",
      },
      {
        CITY: "BADACSONYTORDEMIC",
      },
      {
        CITY: "BAG",
      },
      {
        CITY: "BAGYOGSZOVAT",
      },
      {
        CITY: "BAJA",
      },
      {
        CITY: "BAJNA",
      },
      {
        CITY: "BAKONYBEL",
      },
      {
        CITY: "BAKONYCSERNYE",
      },
      {
        CITY: "BAKONYSZENTLASZLO",
      },
      {
        CITY: "BAKONYSZOMBATHELY",
      },
      {
        CITY: "BALASSAGYARMAT",
      },
      {
        CITY: "BALATON",
      },
      {
        CITY: "BALATONALMADI",
      },
      {
        CITY: "BALATONBOGLAR",
      },
      {
        CITY: "BALATONFOKAJAR",
      },
      {
        CITY: "BALATONFURED",
      },
      {
        CITY: "BALATONFUZFO",
      },
      {
        CITY: "BALATONKENESE",
      },
      {
        CITY: "BALATONLELLE",
      },
      {
        CITY: "BALATONSZABADI",
      },
      {
        CITY: "BALATONSZARSZO",
      },
      {
        CITY: "BALKANY",
      },
      {
        CITY: "BALLOSZOG",
      },
      {
        CITY: "BALMAZUJVAROS",
      },
      {
        CITY: "BANHORVATI",
      },
      {
        CITY: "BARACS",
      },
      {
        CITY: "BARACSKA",
      },
      {
        CITY: "BARAND",
      },
      {
        CITY: "BARCS",
      },
      {
        CITY: "BATASZEK",
      },
      {
        CITY: "BATONYTERENYE",
      },
      {
        CITY: "BAZSI",
      },
      {
        CITY: "BECSEHELY",
      },
      {
        CITY: "BEKASMEGYER",
      },
      {
        CITY: "BEKESSZENTANDRAS",
      },
      {
        CITY: "BELAPATFALVA",
      },
      {
        CITY: "BELED",
      },
      {
        CITY: "BELOIANNISZ",
      },
      {
        CITY: "BENYE",
      },
      {
        CITY: "BEREKFURDO",
      },
      {
        CITY: "BERETTYOUJFALU",
      },
      {
        CITY: "BERHIDA",
      },
      {
        CITY: "BERKESD",
      },
      {
        CITY: "BESNYO",
      },
      {
        CITY: "BIATORBAGY",
      },
      {
        CITY: "BICSKE",
      },
      {
        CITY: "BIHARKERESZTES",
      },
      {
        CITY: "BIKAL",
      },
      {
        CITY: "BOCS",
      },
      {
        CITY: "BODAJK",
      },
      {
        CITY: "BODASZOLO",
      },
      {
        CITY: "BODROGKISFALUD",
      },
      {
        CITY: "BOGYISZLO",
      },
      {
        CITY: "BOHONYE",
      },
      {
        CITY: "BOKOD",
      },
      {
        CITY: "BOLDOG",
      },
      {
        CITY: "BOLDVA",
      },
      {
        CITY: "BOLY",
      },
      {
        CITY: "BONCODFOLDE",
      },
      {
        CITY: "BONYHAD",
      },
      {
        CITY: "BORCS",
      },
      {
        CITY: "BORSODSZIRAK",
      },
      {
        CITY: "BUDAJENO",
      },
      {
        CITY: "BUDAKALASZ",
      },
      {
        CITY: "BUDAKESZI",
      },
      {
        CITY: "BUDAORS",
      },
      {
        CITY: "BUDAPEST",
      },
      {
        CITY: "BUGAC",
      },
      {
        CITY: "BUGYI",
      },
      {
        CITY: "BUK",
      },
      {
        CITY: "BUKKARANYOS",
      },
      {
        CITY: "BUZSAK",
      },
      {
        CITY: "CEGLED",
      },
      {
        CITY: "CEGLEDBERCEL",
      },
      {
        CITY: "CELLDOMOLK",
      },
      {
        CITY: "CENTER",
      },
      {
        CITY: "CERED",
      },
      {
        CITY: "CIKO",
      },
      {
        CITY: "CIRAK",
      },
      {
        CITY: "CSABACSUD",
      },
      {
        CITY: "CSAJAG",
      },
      {
        CITY: "CSAKVAR",
      },
      {
        CITY: "CSANADPALOTA",
      },
      {
        CITY: "CSANYTELEK",
      },
      {
        CITY: "CSECSE",
      },
      {
        CITY: "CSEMO",
      },
      {
        CITY: "CSEPA",
      },
      {
        CITY: "CSERENFA",
      },
      {
        CITY: "CSEREPFALU",
      },
      {
        CITY: "CSERSZEGTOMAJ",
      },
      {
        CITY: "CSOBAD",
      },
      {
        CITY: "CSOBANKA",
      },
      {
        CITY: "CSOEROEG",
      },
      {
        CITY: "CSOGLE",
      },
      {
        CITY: "CSOLYOSPALOS",
      },
      {
        CITY: "CSOMAD",
      },
      {
        CITY: "CSOMOR",
      },
      {
        CITY: "CSONGRAD",
      },
      {
        CITY: "CSOPAK",
      },
      {
        CITY: "CSORNA",
      },
      {
        CITY: "CSORVAS",
      },
      {
        CITY: "CSOVAR",
      },
      {
        CITY: "DABAS",
      },
      {
        CITY: "DAD",
      },
      {
        CITY: "DANSZENTMIKLOS",
      },
      {
        CITY: "DANY",
      },
      {
        CITY: "DEBRECEN",
      },
      {
        CITY: "DECS",
      },
      {
        CITY: "DEDESTAPOLCSANY",
      },
      {
        CITY: "DEG",
      },
      {
        CITY: "DELEGYHAZA",
      },
      {
        CITY: "DEMJEN",
      },
      {
        CITY: "DERECSKE",
      },
      {
        CITY: "DESZK",
      },
      {
        CITY: "DEVAVANYA",
      },
      {
        CITY: "DEVECSER",
      },
      {
        CITY: "DIOSD",
      },
      {
        CITY: "DOBROKOZ",
      },
      {
        CITY: "DOMASZEK",
      },
      {
        CITY: "DOMBOVAR",
      },
      {
        CITY: "DOMBRAD",
      },
      {
        CITY: "DOMOSZLO",
      },
      {
        CITY: "DOMSOD",
      },
      {
        CITY: "DONAT",
      },
      {
        CITY: "DOROG",
      },
      {
        CITY: "DOROGHAZA",
      },
      {
        CITY: "DUDAR",
      },
      {
        CITY: "DUNABOGDANY",
      },
      {
        CITY: "DUNAFOLDVAR",
      },
      {
        CITY: "DUNAHARASZTI",
      },
      {
        CITY: "DUNAKESZI",
      },
      {
        CITY: "DUNASZEG",
      },
      {
        CITY: "DUNASZENTGYORGY",
      },
      {
        CITY: "DUNAVARSANY",
      },
      {
        CITY: "DUNAVECSE",
      },
      {
        CITY: "DUSNOK",
      },
      {
        CITY: "EBES",
      },
      {
        CITY: "ECS",
      },
      {
        CITY: "ECSER",
      },
      {
        CITY: "EDELENY",
      },
      {
        CITY: "EGER",
      },
      {
        CITY: "EGERSZOLAT",
      },
      {
        CITY: "EGYEK",
      },
      {
        CITY: "EGYHAZASDENGELEG",
      },
      {
        CITY: "EGYHAZASHETYE",
      },
      {
        CITY: "EMOD",
      },
      {
        CITY: "ENCS",
      },
      {
        CITY: "EPERJESKE",
      },
      {
        CITY: "ERCSI",
      },
      {
        CITY: "ERDOKERTES",
      },
      {
        CITY: "ESZTERGOM",
      },
      {
        CITY: "FARMOS",
      },
      {
        CITY: "FEGYVERNEK",
      },
      {
        CITY: "FEHERGYARMAT",
      },
      {
        CITY: "FELSOPAKONY",
      },
      {
        CITY: "FELSOSZENTIVAN",
      },
      {
        CITY: "FELSOTARKANY",
      },
      {
        CITY: "FELSOZSOLCA",
      },
      {
        CITY: "FERTOD",
      },
      {
        CITY: "FERTOSZENTMIKLOS",
      },
      {
        CITY: "FOT",
      },
      {
        CITY: "FUZESABONY",
      },
      {
        CITY: "FUZESGYARMAT",
      },
      {
        CITY: "GALAMBOK",
      },
      {
        CITY: "GALGAMACSA",
      },
      {
        CITY: "GARAB",
      },
      {
        CITY: "GARDONY",
      },
      {
        CITY: "GASZTONY",
      },
      {
        CITY: "GAVAVENCSELLO",
      },
      {
        CITY: "GELSE",
      },
      {
        CITY: "GEMZSE",
      },
      {
        CITY: "GENCSAPATI",
      },
      {
        CITY: "GERJEN",
      },
      {
        CITY: "GOD",
      },
      {
        CITY: "GOLLE",
      },
      {
        CITY: "GONYU",
      },
      {
        CITY: "GORBEHAZA",
      },
      {
        CITY: "GORGETEG",
      },
      {
        CITY: "GYAL",
      },
      {
        CITY: "GYAR",
      },
      {
        CITY: "GYENESDIAS",
      },
      {
        CITY: "GYOMAENDROD",
      },
      {
        CITY: "GYOMRO",
      },
      {
        CITY: "GYONGYOS",
      },
      {
        CITY: "GYONGYOSSOLYMOS",
      },
      {
        CITY: "GYONGYOSTARJAN",
      },
      {
        CITY: "GYORKONY",
      },
      {
        CITY: "GYORLADAMER",
      },
      {
        CITY: "GYORSAG",
      },
      {
        CITY: "GYORUJBARAT",
      },
      {
        CITY: "GYORZAMOLY",
      },
      {
        CITY: "GYULA",
      },
      {
        CITY: "GYULAHAZA",
      },
      {
        CITY: "HAJDUBOSZORMENY",
      },
      {
        CITY: "HAJDUDOROG",
      },
      {
        CITY: "HAJDUHADHAZ",
      },
      {
        CITY: "HAJDUNANAS",
      },
      {
        CITY: "HAJDUSAMSON",
      },
      {
        CITY: "HAJDUSZOBOSZLO",
      },
      {
        CITY: "HAJMASKER",
      },
      {
        CITY: "HALASZI",
      },
      {
        CITY: "HALASZTELEK",
      },
      {
        CITY: "HALIMBA",
      },
      {
        CITY: "HARKANY",
      },
      {
        CITY: "HARTA",
      },
      {
        CITY: "HATVAN",
      },
      {
        CITY: "HEGYESHALOM",
      },
      {
        CITY: "HEGYFALU",
      },
      {
        CITY: "HEGYHATMAROC",
      },
      {
        CITY: "HEGYHATSZENTMARTON",
      },
      {
        CITY: "HEHALOM",
      },
      {
        CITY: "HELVECIA",
      },
      {
        CITY: "HERCEGHALOM",
      },
      {
        CITY: "HERCEGKUT",
      },
      {
        CITY: "HERCEGSZANTO",
      },
      {
        CITY: "HERED",
      },
      {
        CITY: "HEREND",
      },
      {
        CITY: "HERNAD",
      },
      {
        CITY: "HERNADKAK",
      },
      {
        CITY: "HERNADNEMETI",
      },
      {
        CITY: "HEVES",
      },
      {
        CITY: "HEVESARANYOS",
      },
      {
        CITY: "HEVIZGYOERK",
      },
      {
        CITY: "HIDAS",
      },
      {
        CITY: "HODMEZOVASARHELY",
      },
      {
        CITY: "HOMROGD",
      },
      {
        CITY: "HONT",
      },
      {
        CITY: "HORT",
      },
      {
        CITY: "HOSSZUHETENY",
      },
      {
        CITY: "IBRANY",
      },
      {
        CITY: "IKRENY",
      },
      {
        CITY: "INANCS",
      },
      {
        CITY: "INARCS",
      },
      {
        CITY: "IREGSZEMCSE",
      },
      {
        CITY: "ISASZEG",
      },
      {
        CITY: "IVANCSA",
      },
      {
        CITY: "IZMENY",
      },
      {
        CITY: "JANOSHALMA",
      },
      {
        CITY: "JANOSHIDA",
      },
      {
        CITY: "JANOSSOMORJA",
      },
      {
        CITY: "JASZAPATI",
      },
      {
        CITY: "JASZBERENY",
      },
      {
        CITY: "JASZFELSOSZENTGYORGY",
      },
      {
        CITY: "JASZFENYSZARU",
      },
      {
        CITY: "JASZKISER",
      },
      {
        CITY: "JASZLADANY",
      },
      {
        CITY: "JUTA",
      },
      {
        CITY: "KACSOTA",
      },
      {
        CITY: "KADARKUT",
      },
      {
        CITY: "KAJASZO",
      },
      {
        CITY: "KALLO",
      },
      {
        CITY: "KALOCSA",
      },
      {
        CITY: "KALOZ",
      },
      {
        CITY: "KAPOLNASNYEK",
      },
      {
        CITY: "KAPOSMERO",
      },
      {
        CITY: "KAPUVAR",
      },
      {
        CITY: "KARANCSKESZI",
      },
      {
        CITY: "KARANCSLAPUJTO",
      },
      {
        CITY: "KARANCSSAG",
      },
      {
        CITY: "KARCAG",
      },
      {
        CITY: "KARTAL",
      },
      {
        CITY: "KAZINCBARCIKA",
      },
      {
        CITY: "KECEL",
      },
      {
        CITY: "KEMECSE",
      },
      {
        CITY: "KEMENCE",
      },
      {
        CITY: "KERECSEND",
      },
      {
        CITY: "KEREKEGYHAZA",
      },
      {
        CITY: "KEREKHARASZT",
      },
      {
        CITY: "KEREPES",
      },
      {
        CITY: "KESZNYETEN",
      },
      {
        CITY: "KESZTHELY",
      },
      {
        CITY: "KESZU",
      },
      {
        CITY: "KETEGYHAZA",
      },
      {
        CITY: "KEVERMES",
      },
      {
        CITY: "KINCSESBANYA",
      },
      {
        CITY: "KISAR",
      },
      {
        CITY: "KISBER",
      },
      {
        CITY: "KISDOROG",
      },
      {
        CITY: "KISGYOR",
      },
      {
        CITY: "KISKINIZS",
      },
      {
        CITY: "KISKOROS",
      },
      {
        CITY: "KISKUNFELEGYHAZA",
      },
      {
        CITY: "KISKUNHALAS",
      },
      {
        CITY: "KISKUNLACHAZA",
      },
      {
        CITY: "KISLANG",
      },
      {
        CITY: "KISMANYOK",
      },
      {
        CITY: "KISMAROS",
      },
      {
        CITY: "KISNEMEDI",
      },
      {
        CITY: "KISPEST",
      },
      {
        CITY: "KISTAPOLCA",
      },
      {
        CITY: "KISTARCSA",
      },
      {
        CITY: "KISTELEK",
      },
      {
        CITY: "KISTOKAJ",
      },
      {
        CITY: "KISTORMAS",
      },
      {
        CITY: "KISUJSZALLAS",
      },
      {
        CITY: "KISVARDA",
      },
      {
        CITY: "KISVASZAR",
      },
      {
        CITY: "KLARAFALVA",
      },
      {
        CITY: "KOKA",
      },
      {
        CITY: "KOLONTAR",
      },
      {
        CITY: "KOMLO",
      },
      {
        CITY: "KOMLOD",
      },
      {
        CITY: "KOMPOLT",
      },
      {
        CITY: "KONY",
      },
      {
        CITY: "KOPHAZA",
      },
      {
        CITY: "KORMEND",
      },
      {
        CITY: "KORNYE",
      },
      {
        CITY: "KOROSLADANY",
      },
      {
        CITY: "KOSZARHEGY",
      },
      {
        CITY: "KOSZEG",
      },
      {
        CITY: "KOTAJ",
      },
      {
        CITY: "KOTELEK",
      },
      {
        CITY: "KUBEKHAZA",
      },
      {
        CITY: "KULCS",
      },
      {
        CITY: "KULSOBARAND",
      },
      {
        CITY: "KUNAGOTA",
      },
      {
        CITY: "KUNBAJA",
      },
      {
        CITY: "KUNHEGYES",
      },
      {
        CITY: "KUNSZENTMARTON",
      },
      {
        CITY: "KUNSZENTMIKLOS",
      },
      {
        CITY: "KUNSZIGET",
      },
      {
        CITY: "KURD",
      },
      {
        CITY: "KURITYAN",
      },
      {
        CITY: "LABATLAN",
      },
      {
        CITY: "LABOD",
      },
      {
        CITY: "LADBESENYO",
      },
      {
        CITY: "LAJOSKOMAROM",
      },
      {
        CITY: "LAJOSMIZSE",
      },
      {
        CITY: "LAZI",
      },
      {
        CITY: "LEANYFALU",
      },
      {
        CITY: "LEBENY",
      },
      {
        CITY: "LENTI",
      },
      {
        CITY: "LETENYE",
      },
      {
        CITY: "LEVELEK",
      },
      {
        CITY: "LOVAS",
      },
      {
        CITY: "LOVASZI",
      },
      {
        CITY: "MADOCSA",
      },
      {
        CITY: "MAGLOD",
      },
      {
        CITY: "MAGY",
      },
      {
        CITY: "MAGYAREGRES",
      },
      {
        CITY: "MAGYARHERTELEND",
      },
      {
        CITY: "MAGYARNANDOR",
      },
      {
        CITY: "MAJOSHAZA",
      },
      {
        CITY: "MAKAD",
      },
      {
        CITY: "MAKKOSHOTYKA",
      },
      {
        CITY: "MAKLAR",
      },
      {
        CITY: "MAKO",
      },
      {
        CITY: "MALYI",
      },
      {
        CITY: "MANDOK",
      },
      {
        CITY: "MANY",
      },
      {
        CITY: "MARCALI",
      },
      {
        CITY: "MARIAPOCS",
      },
      {
        CITY: "MARKAZ",
      },
      {
        CITY: "MAROKPAPI",
      },
      {
        CITY: "MARTELY",
      },
      {
        CITY: "MARTFU",
      },
      {
        CITY: "MARTONVASAR",
      },
      {
        CITY: "MATESZALKA",
      },
      {
        CITY: "MATRABALLA",
      },
      {
        CITY: "MATRASZOLOS",
      },
      {
        CITY: "MECSEKNADASD",
      },
      {
        CITY: "MEDGYESEGYHAZA",
      },
      {
        CITY: "MELYKUT",
      },
      {
        CITY: "MENDE",
      },
      {
        CITY: "MERNYE",
      },
      {
        CITY: "MESZTEGNYO",
      },
      {
        CITY: "MEZOBERENY",
      },
      {
        CITY: "MEZOFALVA",
      },
      {
        CITY: "MEZOHEGYES",
      },
      {
        CITY: "MEZOKOVESD",
      },
      {
        CITY: "MEZONYARAD",
      },
      {
        CITY: "MEZOSZILAS",
      },
      {
        CITY: "MEZOTUR",
      },
      {
        CITY: "MINDSZENT",
      },
      {
        CITY: "MISEFA",
      },
      {
        CITY: "MISKOLC",
      },
      {
        CITY: "MOGYOROD",
      },
      {
        CITY: "MONOR",
      },
      {
        CITY: "MONORIERDO",
      },
      {
        CITY: "MOR",
      },
      {
        CITY: "MORAHALOM",
      },
      {
        CITY: "MOSONSZENTMIKLOS",
      },
      {
        CITY: "MOSONSZOLNOK",
      },
      {
        CITY: "MUCSONY",
      },
      {
        CITY: "NAGYATAD",
      },
      {
        CITY: "NAGYBARCA",
      },
      {
        CITY: "NAGYBERKI",
      },
      {
        CITY: "NAGYCENK",
      },
      {
        CITY: "NAGYCSERKESZ",
      },
      {
        CITY: "NAGYDOBSZA",
      },
      {
        CITY: "NAGYHALASZ",
      },
      {
        CITY: "NAGYHEGYES",
      },
      {
        CITY: "NAGYIGMAND",
      },
      {
        CITY: "NAGYKALLO",
      },
      {
        CITY: "NAGYKANIZSA",
      },
      {
        CITY: "NAGYKAPORNAK",
      },
      {
        CITY: "NAGYKATA",
      },
      {
        CITY: "NAGYKEREKI",
      },
      {
        CITY: "NAGYKERESZTUR",
      },
      {
        CITY: "NAGYKOROS",
      },
      {
        CITY: "NAGYKORU",
      },
      {
        CITY: "NAGYKOVACSI",
      },
      {
        CITY: "NAGYKOZAR",
      },
      {
        CITY: "NAGYMAGOCS",
      },
      {
        CITY: "NAGYMANYOK",
      },
      {
        CITY: "NAGYNYARAD",
      },
      {
        CITY: "NAGYPALI",
      },
      {
        CITY: "NAGYSAP",
      },
      {
        CITY: "NAGYSZEKERES",
      },
      {
        CITY: "NAGYSZENAS",
      },
      {
        CITY: "NAGYSZOKOLY",
      },
      {
        CITY: "NAGYTARCSA",
      },
      {
        CITY: "NAGYTOTFALU",
      },
      {
        CITY: "NAGYVAZSONY",
      },
      {
        CITY: "NAGYVENYIM",
      },
      {
        CITY: "NASZALY",
      },
      {
        CITY: "NEMESBOD",
      },
      {
        CITY: "NEMESBUEK",
      },
      {
        CITY: "NEMESVID",
      },
      {
        CITY: "NEMESVITA",
      },
      {
        CITY: "NEMETKER",
      },
      {
        CITY: "NESZMELY",
      },
      {
        CITY: "NEZSA",
      },
      {
        CITY: "NOGRADSAP",
      },
      {
        CITY: "NOGRADSIPEK",
      },
      {
        CITY: "NYEKLADHAZA",
      },
      {
        CITY: "NYERGESUJFALU",
      },
      {
        CITY: "NYIRADONY",
      },
      {
        CITY: "NYIRBATOR",
      },
      {
        CITY: "NYIREGYHAZA",
      },
      {
        CITY: "NYIRMARTONFALVA",
      },
      {
        CITY: "NYIRTASS",
      },
      {
        CITY: "NYUL",
      },
      {
        CITY: "OCSA",
      },
      {
        CITY: "OCSENY",
      },
      {
        CITY: "OCSOD",
      },
      {
        CITY: "OFEHERTO",
      },
      {
        CITY: "ONGA",
      },
      {
        CITY: "ORBOTTYAN",
      },
      {
        CITY: "OROSHAZA",
      },
      {
        CITY: "OROSZLANY",
      },
      {
        CITY: "OSTOROS",
      },
      {
        CITY: "OTTEVENY",
      },
      {
        CITY: "OZD",
      },
      {
        CITY: "PAKA",
      },
      {
        CITY: "PAKOD",
      },
      {
        CITY: "PAKOZD",
      },
      {
        CITY: "PAKS",
      },
      {
        CITY: "PALOTABOZSOK",
      },
      {
        CITY: "PAPA",
      },
      {
        CITY: "PART",
      },
      {
        CITY: "PASZTO",
      },
      {
        CITY: "PATROHA",
      },
      {
        CITY: "PATVARC",
      },
      {
        CITY: "PATY",
      },
      {
        CITY: "PAZMAND",
      },
      {
        CITY: "PECEL",
      },
      {
        CITY: "PECOL",
      },
      {
        CITY: "PELLERD",
      },
      {
        CITY: "PER",
      },
      {
        CITY: "PERBAL",
      },
      {
        CITY: "PERENYE",
      },
      {
        CITY: "PETERI",
      },
      {
        CITY: "PETFUERDO",
      },
      {
        CITY: "PETOFIBANYA",
      },
      {
        CITY: "PETOHAZA",
      },
      {
        CITY: "PILIS",
      },
      {
        CITY: "PILISCSABA",
      },
      {
        CITY: "PILISMAROT",
      },
      {
        CITY: "PILISSZANTO",
      },
      {
        CITY: "PILISSZENTIVAN",
      },
      {
        CITY: "PILISSZENTKERESZT",
      },
      {
        CITY: "PILISVOROSVAR",
      },
      {
        CITY: "POLGAR",
      },
      {
        CITY: "POLGARDI",
      },
      {
        CITY: "POMAZ",
      },
      {
        CITY: "POSFA",
      },
      {
        CITY: "PUSPOKLADANY",
      },
      {
        CITY: "PUSZTASZABOLCS",
      },
      {
        CITY: "PUSZTASZEMES",
      },
      {
        CITY: "PUSZTAVACS",
      },
      {
        CITY: "PUSZTAVAM",
      },
      {
        CITY: "PUSZTAZAMOR",
      },
      {
        CITY: "RABAPATONA",
      },
      {
        CITY: "RABAPATY",
      },
      {
        CITY: "RACALMAS",
      },
      {
        CITY: "RACKERESZTUR",
      },
      {
        CITY: "RACKEVE",
      },
      {
        CITY: "RAD",
      },
      {
        CITY: "RAJKA",
      },
      {
        CITY: "RAKOCZIFALVA",
      },
      {
        CITY: "RAKOCZIUJFALU",
      },
      {
        CITY: "REPCELAK",
      },
      {
        CITY: "REPCESZEMERE",
      },
      {
        CITY: "RETSAG",
      },
      {
        CITY: "REVFUELOEP",
      },
      {
        CITY: "RIMOC",
      },
      {
        CITY: "ROSZKE",
      },
      {
        CITY: "RUDABANYA",
      },
      {
        CITY: "SAJOKAPOLNA",
      },
      {
        CITY: "SAJOKERESZTUR",
      },
      {
        CITY: "SAJOOEROES",
      },
      {
        CITY: "SAJOPALFALA",
      },
      {
        CITY: "SAJOSZENTPETER",
      },
      {
        CITY: "SAJOVAMOS",
      },
      {
        CITY: "SALFOLD",
      },
      {
        CITY: "SALGOTARJAN",
      },
      {
        CITY: "SANDORFALVA",
      },
      {
        CITY: "SARBOGARD",
      },
      {
        CITY: "SARISAP",
      },
      {
        CITY: "SARKAD",
      },
      {
        CITY: "SARMELLEK",
      },
      {
        CITY: "SAROSD",
      },
      {
        CITY: "SAROSPATAK",
      },
      {
        CITY: "SARPENTELE",
      },
      {
        CITY: "SARPILIS",
      },
      {
        CITY: "SARSZENTAGOTA",
      },
      {
        CITY: "SARSZENTLORINC",
      },
      {
        CITY: "SARSZENTMIHALY",
      },
      {
        CITY: "SARVAR",
      },
      {
        CITY: "SASD",
      },
      {
        CITY: "SASKA",
      },
      {
        CITY: "SE",
      },
      {
        CITY: "SELYEB",
      },
      {
        CITY: "SENYO",
      },
      {
        CITY: "SEREGELYES",
      },
      {
        CITY: "SIKLOS",
      },
      {
        CITY: "SIMONTORNYA",
      },
      {
        CITY: "SIROK",
      },
      {
        CITY: "SOLT",
      },
      {
        CITY: "SOLTSZENTIMRE",
      },
      {
        CITY: "SOLYMAR",
      },
      {
        CITY: "SOMOGYSARD",
      },
      {
        CITY: "SOMOGYUDVARHELY",
      },
      {
        CITY: "SOPRON",
      },
      {
        CITY: "SOPRONKOVESD",
      },
      {
        CITY: "SORED",
      },
      {
        CITY: "SOSKUT",
      },
      {
        CITY: "SUELYSAP",
      },
      {
        CITY: "SUKORO",
      },
      {
        CITY: "SUMEG",
      },
      {
        CITY: "SZABADBATTYAN",
      },
      {
        CITY: "SZABADSZALLAS",
      },
      {
        CITY: "SZABADSZENTKIRALY",
      },
      {
        CITY: "SZABOLCS",
      },
      {
        CITY: "SZABOLCSVERESMART",
      },
      {
        CITY: "SZAJK",
      },
      {
        CITY: "SZAJOL",
      },
      {
        CITY: "SZALASZEND",
      },
      {
        CITY: "SZANY",
      },
      {
        CITY: "SZAR",
      },
      {
        CITY: "SZARVAS",
      },
      {
        CITY: "SZAZHALOMBATTA",
      },
      {
        CITY: "SZECSENY",
      },
      {
        CITY: "SZECSENYFELFALU",
      },
      {
        CITY: "SZEGED",
      },
      {
        CITY: "SZEGHALOM",
      },
      {
        CITY: "SZEGVAR",
      },
      {
        CITY: "SZEMELY",
      },
      {
        CITY: "SZENDEHELY",
      },
      {
        CITY: "SZENTENDRE",
      },
      {
        CITY: "SZENTES",
      },
      {
        CITY: "SZENTGOTTHARD",
      },
      {
        CITY: "SZENTISTVAN",
      },
      {
        CITY: "SZENTKATALIN",
      },
      {
        CITY: "SZENTKIRALYSZABADJA",
      },
      {
        CITY: "SZENTLORINC",
      },
      {
        CITY: "SZENTMARTONKATA",
      },
      {
        CITY: "SZEPETNEK",
      },
      {
        CITY: "SZEREMLE",
      },
      {
        CITY: "SZERENCS",
      },
      {
        CITY: "SZIGETCSEP",
      },
      {
        CITY: "SZIGETHALOM",
      },
      {
        CITY: "SZIGETSZENTMARTON",
      },
      {
        CITY: "SZIGETSZENTMIKLOS",
      },
      {
        CITY: "SZIGETVAR",
      },
      {
        CITY: "SZIGLIGET",
      },
      {
        CITY: "SZIKSZO",
      },
      {
        CITY: "SZILSARKANY",
      },
      {
        CITY: "SZIRMABESENYO",
      },
      {
        CITY: "SZOD",
      },
      {
        CITY: "SZODLIGET",
      },
      {
        CITY: "SZOGLIGET",
      },
      {
        CITY: "SZOKOLYA",
      },
      {
        CITY: "SZOLNOK",
      },
      {
        CITY: "SZOMBATHELY",
      },
      {
        CITY: "SZOMOD",
      },
      {
        CITY: "SZOMOR",
      },
      {
        CITY: "SZUHAKALLO",
      },
      {
        CITY: "TAB",
      },
      {
        CITY: "TABAJD",
      },
      {
        CITY: "TABORFALVA",
      },
      {
        CITY: "TAKSONY",
      },
      {
        CITY: "TAKTASZADA",
      },
      {
        CITY: "TAMASI",
      },
      {
        CITY: "TAPIOBICSKE",
      },
      {
        CITY: "TAPIOGYORGYE",
      },
      {
        CITY: "TAPIOSAG",
      },
      {
        CITY: "TAPIOSZECSO",
      },
      {
        CITY: "TAPIOSZELE",
      },
      {
        CITY: "TAPLANSZENTKERESZT",
      },
      {
        CITY: "TAPOLCA",
      },
      {
        CITY: "TAR",
      },
      {
        CITY: "TARCAL",
      },
      {
        CITY: "TARJAN",
      },
      {
        CITY: "TARNOK",
      },
      {
        CITY: "TARPA",
      },
      {
        CITY: "TASS",
      },
      {
        CITY: "TASZAR",
      },
      {
        CITY: "TAT",
      },
      {
        CITY: "TATA",
      },
      {
        CITY: "TEGLAS",
      },
      {
        CITY: "TELEKES",
      },
      {
        CITY: "TELEP",
      },
      {
        CITY: "TELKI",
      },
      {
        CITY: "TEPE",
      },
      {
        CITY: "TEREM",
      },
      {
        CITY: "TET",
      },
      {
        CITY: "TETETLEN",
      },
      {
        CITY: "TEVEL",
      },
      {
        CITY: "TINNYE",
      },
      {
        CITY: "TISZAADONY",
      },
      {
        CITY: "TISZABO",
      },
      {
        CITY: "TISZACSEGE",
      },
      {
        CITY: "TISZAFOLDVAR",
      },
      {
        CITY: "TISZAFURED",
      },
      {
        CITY: "TISZALOK",
      },
      {
        CITY: "TISZALUC",
      },
      {
        CITY: "TISZAPALKONYA",
      },
      {
        CITY: "TISZASZALKA",
      },
      {
        CITY: "TISZAUJVAROS",
      },
      {
        CITY: "TISZAVALK",
      },
      {
        CITY: "TISZAVASVARI",
      },
      {
        CITY: "TOEKOEL",
      },
      {
        CITY: "TOEROEKBALINT",
      },
      {
        CITY: "TOKODALTARO",
      },
      {
        CITY: "TOLNA",
      },
      {
        CITY: "TOMORKENY",
      },
      {
        CITY: "TOMPA",
      },
      {
        CITY: "TORDAS",
      },
      {
        CITY: "TOROKSZENTMIKLOS",
      },
      {
        CITY: "TORONY",
      },
      {
        CITY: "TOSZEG",
      },
      {
        CITY: "TOTKOMLOS",
      },
      {
        CITY: "TOTSZERDAHELY",
      },
      {
        CITY: "TURA",
      },
      {
        CITY: "TURKEVE",
      },
      {
        CITY: "UJFEHERTO",
      },
      {
        CITY: "UJKENEZ",
      },
      {
        CITY: "UJKIGYOS",
      },
      {
        CITY: "UJLENGYEL",
      },
      {
        CITY: "UJSZASZ",
      },
      {
        CITY: "UJSZENTMARGITA",
      },
      {
        CITY: "UJSZILVAS",
      },
      {
        CITY: "UJUDVAR",
      },
      {
        CITY: "ULLO",
      },
      {
        CITY: "URAIUJFALU",
      },
      {
        CITY: "URI",
      },
      {
        CITY: "UROM",
      },
      {
        CITY: "VACDUKA",
      },
      {
        CITY: "VACRATOT",
      },
      {
        CITY: "VACSZENTLASZLO",
      },
      {
        CITY: "VADNA",
      },
      {
        CITY: "VAJA",
      },
      {
        CITY: "VAJSZLO",
      },
      {
        CITY: "VALKO",
      },
      {
        CITY: "VALKONYA",
      },
      {
        CITY: "VAMOSPERCS",
      },
      {
        CITY: "VAMOSSZABADI",
      },
      {
        CITY: "VARALJA",
      },
      {
        CITY: "VARBALOG",
      },
      {
        CITY: "VARBO",
      },
      {
        CITY: "VARDOMB",
      },
      {
        CITY: "VAROSFOELD",
      },
      {
        CITY: "VARPALOTA",
      },
      {
        CITY: "VASAROSDOMBO",
      },
      {
        CITY: "VASVAR",
      },
      {
        CITY: "VECSES",
      },
      {
        CITY: "VEGEGYHAZA",
      },
      {
        CITY: "VELENCE",
      },
      {
        CITY: "VEP",
      },
      {
        CITY: "VERESEGYHAZ",
      },
      {
        CITY: "VEROCE",
      },
      {
        CITY: "VERSEG",
      },
      {
        CITY: "VERTESSOMLO",
      },
      {
        CITY: "VERTESSZOLOS",
      },
      {
        CITY: "VERTESTOLNA",
      },
      {
        CITY: "VEZSENY",
      },
      {
        CITY: "VISZNEK",
      },
      {
        CITY: "VITNYED",
      },
      {
        CITY: "VIZSLAS",
      },
      {
        CITY: "ZAGYVASZANTO",
      },
      {
        CITY: "ZAHONY",
      },
      {
        CITY: "ZALAEGERSZEG",
      },
      {
        CITY: "ZALAKOMAR",
      },
      {
        CITY: "ZALALOVO",
      },
      {
        CITY: "ZALASZENTGROT",
      },
      {
        CITY: "ZAMOLY",
      },
      {
        CITY: "ZEBECKE",
      },
      {
        CITY: "ZEBEGENY",
      },
      {
        CITY: "ZICHYUJFALU",
      },
      {
        CITY: "ZIRC",
      },
      {
        CITY: "ZSAMBEK",
      },
      {
        CITY: "ZSAMBOK",
      },
      {
        CITY: "ZSOMBO",
      },
    ],
  },
  {
    COUNTRY: "GUINEA",
    CODE: "GN",
    CITIES: [
      {
        CITY: "CONAKRY",
      },
      {
        CITY: "DABOLA",
      },
      {
        CITY: "KALIA",
      },
      {
        CITY: "KANKAN",
      },
      {
        CITY: "LOLA",
      },
      {
        CITY: "MAMOU",
      },
      {
        CITY: "PORT KAMSAR",
      },
      {
        CITY: "SANGAREDI",
      },
    ],
  },
  {
    COUNTRY: "INDIA",
    CODE: "IN",
    CITIES: [
      {
        CITY: "ABDUL",
      },
      {
        CITY: "ADILABAD",
      },
      {
        CITY: "ADWANI",
      },
      {
        CITY: "AGARTALA",
      },
      {
        CITY: "AGRA",
      },
      {
        CITY: "AHMEDABAD",
      },
      {
        CITY: "AHMEDNAGAR",
      },
      {
        CITY: "AIZAWL",
      },
      {
        CITY: "AJABPUR",
      },
      {
        CITY: "AJMER",
      },
      {
        CITY: "AKIVIDU",
      },
      {
        CITY: "AKOLA",
      },
      {
        CITY: "ALANALLUR",
      },
      {
        CITY: "ALANGULAM",
      },
      {
        CITY: "ALAPPUZHA",
      },
      {
        CITY: "ALDONA",
      },
      {
        CITY: "ALIBAG",
      },
      {
        CITY: "ALIGARH",
      },
      {
        CITY: "ALIPUR",
      },
      {
        CITY: "ALLAHABAD",
      },
      {
        CITY: "ALMORA",
      },
      {
        CITY: "ALUVA",
      },
      {
        CITY: "ALWAR",
      },
      {
        CITY: "AMAL",
      },
      {
        CITY: "AMALAPURAM",
      },
      {
        CITY: "AMBAD",
      },
      {
        CITY: "AMBAH",
      },
      {
        CITY: "AMBALA",
      },
      {
        CITY: "AMBARNATH",
      },
      {
        CITY: "AMBEJOGAI",
      },
      {
        CITY: "AMBIKAPUR",
      },
      {
        CITY: "AMBUR",
      },
      {
        CITY: "AMER",
      },
      {
        CITY: "AMET",
      },
      {
        CITY: "AMRAVATI",
      },
      {
        CITY: "AMRELI",
      },
      {
        CITY: "AMRITSAR",
      },
      {
        CITY: "ANAND",
      },
      {
        CITY: "ANANTAPUR",
      },
      {
        CITY: "ANANTNAG",
      },
      {
        CITY: "ANANTPUR",
      },
      {
        CITY: "ANCHAL",
      },
      {
        CITY: "ANDHERI",
      },
      {
        CITY: "ANDRA",
      },
      {
        CITY: "ANGADIPURAM",
      },
      {
        CITY: "ANGUL",
      },
      {
        CITY: "ANKLESHWAR",
      },
      {
        CITY: "ANNAMALAINAGAR",
      },
      {
        CITY: "ANTAPUR",
      },
      {
        CITY: "ARAKKONAM",
      },
      {
        CITY: "ARANI",
      },
      {
        CITY: "ARANMULA",
      },
      {
        CITY: "ARCH",
      },
      {
        CITY: "ARIYALUR",
      },
      {
        CITY: "ARORA",
      },
      {
        CITY: "ARPORA",
      },
      {
        CITY: "ARUNACHAL",
      },
      {
        CITY: "ARVI",
      },
      {
        CITY: "ASANSOL",
      },
      {
        CITY: "ASSAGAO",
      },
      {
        CITY: "ATTINGAL",
      },
      {
        CITY: "ATTUR",
      },
      {
        CITY: "AUNDH",
      },
      {
        CITY: "AURANGABAD",
      },
      {
        CITY: "AURANGABAD",
      },
      {
        CITY: "AVANIGADDA",
      },
      {
        CITY: "AZAMGARH",
      },
      {
        CITY: "BADDI",
      },
      {
        CITY: "BADLAPUR",
      },
      {
        CITY: "BAGALKOT",
      },
      {
        CITY: "BAGH",
      },
      {
        CITY: "BAGPAT",
      },
      {
        CITY: "BAHADURGARH",
      },
      {
        CITY: "BAHARAMPUR",
      },
      {
        CITY: "BAIDYABATI",
      },
      {
        CITY: "BALA",
      },
      {
        CITY: "BALAGHAT",
      },
      {
        CITY: "BALANA",
      },
      {
        CITY: "BALANAGAR",
      },
      {
        CITY: "BALANGIR",
      },
      {
        CITY: "BALASORE",
      },
      {
        CITY: "BALI",
      },
      {
        CITY: "BALI",
      },
      {
        CITY: "BALLABGARH",
      },
      {
        CITY: "BALU",
      },
      {
        CITY: "BALURGHAT",
      },
      {
        CITY: "BAMBOLIM",
      },
      {
        CITY: "BANDA",
      },
      {
        CITY: "BANDRA",
      },
      {
        CITY: "BANGA",
      },
      {
        CITY: "BENGALURU",
      },
      {
        CITY: "BANGAON",
      },
      {
        CITY: "BANK",
      },
      {
        CITY: "BANKA",
      },
      {
        CITY: "BANKURA",
      },
      {
        CITY: "BANSWARA",
      },
      {
        CITY: "BAPATLA",
      },
      {
        CITY: "BARAKPUR",
      },
      {
        CITY: "BARAMATI",
      },
      {
        CITY: "BARDDHAMAN",
      },
      {
        CITY: "BARDOLI",
      },
      {
        CITY: "BAREILLY",
      },
      {
        CITY: "BARGARH",
      },
      {
        CITY: "BARMER",
      },
      {
        CITY: "BARNALA",
      },
      {
        CITY: "BARODA",
      },
      {
        CITY: "BARPALI",
      },
      {
        CITY: "BARPETA",
      },
      {
        CITY: "BASIRHAT",
      },
      {
        CITY: "BASTI",
      },
      {
        CITY: "BASU",
      },
      {
        CITY: "BATALA",
      },
      {
        CITY: "BAWAN",
      },
      {
        CITY: "BAWANA",
      },
      {
        CITY: "BEAWAR",
      },
      {
        CITY: "BEGUSARAI",
      },
      {
        CITY: "BEHALA",
      },
      {
        CITY: "BELA",
      },
      {
        CITY: "BELAPUR",
      },
      {
        CITY: "BELGAUM",
      },
      {
        CITY: "BELGHARIA",
      },
      {
        CITY: "BELLARE",
      },
      {
        CITY: "BELLARY",
      },
      {
        CITY: "BEMETARA",
      },
      {
        CITY: "BERASIA",
      },
      {
        CITY: "BETALBATIM",
      },
      {
        CITY: "BETIM",
      },
      {
        CITY: "BETUL",
      },
      {
        CITY: "BHADATH",
      },
      {
        CITY: "BHADOHI",
      },
      {
        CITY: "BHADRAVATI",
      },
      {
        CITY: "BHAGALPUR",
      },
      {
        CITY: "BHAGWAN",
      },
      {
        CITY: "BHANDARI",
      },
      {
        CITY: "BHANDUP",
      },
      {
        CITY: "BHARATPUR",
      },
      {
        CITY: "BHARUCH",
      },
      {
        CITY: "BHATAPARA",
      },
      {
        CITY: "BHATINDA",
      },
      {
        CITY: "BHATKAL",
      },
      {
        CITY: "BHAVNAGAR",
      },
      {
        CITY: "BHAWAN",
      },
      {
        CITY: "BHILAI",
      },
      {
        CITY: "BHILWARA",
      },
      {
        CITY: "BHIMAVARAM",
      },
      {
        CITY: "BHIWANDI",
      },
      {
        CITY: "BHIWANI",
      },
      {
        CITY: "BHOJ",
      },
      {
        CITY: "BHONGIR",
      },
      {
        CITY: "BHOPAL",
      },
      {
        CITY: "BHUBANESWAR",
      },
      {
        CITY: "BHUJ",
      },
      {
        CITY: "BHUSAWAL",
      },
      {
        CITY: "BICHPURI",
      },
      {
        CITY: "BIDAR",
      },
      {
        CITY: "BIHAR SHARIF",
      },
      {
        CITY: "BIJAPUR",
      },
      {
        CITY: "BIKANER",
      },
      {
        CITY: "BILASPUR",
      },
      {
        CITY: "BILASPUR",
      },
      {
        CITY: "BILIMORA",
      },
      {
        CITY: "BINAVAS",
      },
      {
        CITY: "BINNAGURI",
      },
      {
        CITY: "BISHNUPUR",
      },
      {
        CITY: "BOBBILI",
      },
      {
        CITY: "BODHAN",
      },
      {
        CITY: "BODINAYAKKANUR",
      },
      {
        CITY: "BOISAR",
      },
      {
        CITY: "BOKARO",
      },
      {
        CITY: "BOLPUR",
      },
      {
        CITY: "BOTAD",
      },
      {
        CITY: "BRAHMAPUR",
      },
      {
        CITY: "BUDAUN",
      },
      {
        CITY: "BUDBUD",
      },
      {
        CITY: "BUDHA",
      },
      {
        CITY: "BULANDSHAHR",
      },
      {
        CITY: "BUNDI",
      },
      {
        CITY: "CALANGUTE",
      },
      {
        CITY: "CANDOLIM",
      },
      {
        CITY: "CANNING",
      },
      {
        CITY: "CARANZALEM",
      },
      {
        CITY: "CHAKAN",
      },
      {
        CITY: "CHAKRA",
      },
      {
        CITY: "CHALISGAON",
      },
      {
        CITY: "CHAMBA",
      },
      {
        CITY: "CHAMPA",
      },
      {
        CITY: "CHAND",
      },
      {
        CITY: "CHANDAN",
      },
      {
        CITY: "CHANDANNAGAR",
      },
      {
        CITY: "CHANDAULI",
      },
      {
        CITY: "CHANDAUSI",
      },
      {
        CITY: "CHANDIGARH",
      },
      {
        CITY: "CHANDRAPUR",
      },
      {
        CITY: "CHANGANACHERI",
      },
      {
        CITY: "CHANNAPATNA",
      },
      {
        CITY: "CHARAN",
      },
      {
        CITY: "CHARU",
      },
      {
        CITY: "CHEN",
      },
      {
        CITY: "CHENGANNUR",
      },
      {
        CITY: "CHENNAI",
      },
      {
        CITY: "CHETAN",
      },
      {
        CITY: "CHEYYAR",
      },
      {
        CITY: "CHHABRA",
      },
      {
        CITY: "CHHACHHRAULI",
      },
      {
        CITY: "CHHOTA UDEPUR",
      },
      {
        CITY: "CHICALIM",
      },
      {
        CITY: "CHIDAMBARAM",
      },
      {
        CITY: "CHIKODI",
      },
      {
        CITY: "CHINCHVAD",
      },
      {
        CITY: "CHINTAMANI",
      },
      {
        CITY: "CHIPLUN",
      },
      {
        CITY: "CHIRALA",
      },
      {
        CITY: "CHITRA",
      },
      {
        CITY: "CHITRADURGA",
      },
      {
        CITY: "CHITTOOR",
      },
      {
        CITY: "CHITTUR",
      },
      {
        CITY: "CHOOLAI",
      },
      {
        CITY: "CHOPDA",
      },
      {
        CITY: "CHOPRA",
      },
      {
        CITY: "CHURACHANDPUR",
      },
      {
        CITY: "COIMBATORE",
      },
      {
        CITY: "COLABA",
      },
      {
        CITY: "CONNAUGHT PLACE",
      },
      {
        CITY: "COONOOR",
      },
      {
        CITY: "CUDDALORE",
      },
      {
        CITY: "CUMBUM",
      },
      {
        CITY: "CUNCOLIM",
      },
      {
        CITY: "CURCHOREM",
      },
      {
        CITY: "CUTTACK",
      },
      {
        CITY: "DADRI",
      },
      {
        CITY: "DAHANU",
      },
      {
        CITY: "DAHOD",
      },
      {
        CITY: "DAM DAM",
      },
      {
        CITY: "DAMAN",
      },
      {
        CITY: "DAMOH",
      },
      {
        CITY: "DANG",
      },
      {
        CITY: "DANGI",
      },
      {
        CITY: "DARBHANGA",
      },
      {
        CITY: "DARJEELING",
      },
      {
        CITY: "DARSI",
      },
      {
        CITY: "DASNA",
      },
      {
        CITY: "DASUA",
      },
      {
        CITY: "DAVANGERE",
      },
      {
        CITY: "DEHRADUN",
      },
      {
        CITY: "DELHI",
      },
      {
        CITY: "DEOLALI",
      },
      {
        CITY: "DEORIA",
      },
      {
        CITY: "DEVGARH",
      },
      {
        CITY: "DEVIPATTINAM",
      },
      {
        CITY: "DEWAS",
      },
      {
        CITY: "DHAKA",
      },
      {
        CITY: "DHAMTARI",
      },
      {
        CITY: "DHANBAD",
      },
      {
        CITY: "DHANSURA",
      },
      {
        CITY: "DHAR",
      },
      {
        CITY: "DHARAMSALA",
      },
      {
        CITY: "DHARAPURAM",
      },
      {
        CITY: "DHARAVI",
      },
      {
        CITY: "DHARIWAL",
      },
      {
        CITY: "DHARMAPURI",
      },
      {
        CITY: "DHARWAD",
      },
      {
        CITY: "DHENKANAL",
      },
      {
        CITY: "DHONE",
      },
      {
        CITY: "DHROL",
      },
      {
        CITY: "DHUBRI",
      },
      {
        CITY: "DHULE",
      },
      {
        CITY: "DHURI",
      },
      {
        CITY: "DIBRUGARH",
      },
      {
        CITY: "DICHOLI",
      },
      {
        CITY: "DIMAPUR",
      },
      {
        CITY: "DINANAGAR",
      },
      {
        CITY: "DINDIGUL",
      },
      {
        CITY: "DINDORI",
      },
      {
        CITY: "DIPAS",
      },
      {
        CITY: "DOGADDA",
      },
      {
        CITY: "DONA PAULA",
      },
      {
        CITY: "DUMKA",
      },
      {
        CITY: "DURG",
      },
      {
        CITY: "DURGAPUR",
      },
      {
        CITY: "DWARAHAT",
      },
      {
        CITY: "DWARKA",
      },
      {
        CITY: "EDAVANNA",
      },
      {
        CITY: "EKKATTUTHANGAL",
      },
      {
        CITY: "ELLORA CAVES",
      },
      {
        CITY: "ELURU",
      },
      {
        CITY: "ERAL",
      },
      {
        CITY: "ERNAKULAM",
      },
      {
        CITY: "ERODE",
      },
      {
        CITY: "ETAWAH",
      },
      {
        CITY: "FAIZABAD",
      },
      {
        CITY: "FARAKKA",
      },
      {
        CITY: "FARIDABAD",
      },
      {
        CITY: "FARIDKOT",
      },
      {
        CITY: "FATEHABAD",
      },
      {
        CITY: "FATEHGARH",
      },
      {
        CITY: "FATEHPUR",
      },
      {
        CITY: "FIROZABAD",
      },
      {
        CITY: "FIROZPUR",
      },
      {
        CITY: "FORT",
      },
      {
        CITY: "GADAG",
      },
      {
        CITY: "GAMPALAGUDEM",
      },
      {
        CITY: "GANDHIDHAM",
      },
      {
        CITY: "GANDHIGRAM",
      },
      {
        CITY: "GANDHINAGAR",
      },
      {
        CITY: "GANGA",
      },
      {
        CITY: "GANGANAGAR",
      },
      {
        CITY: "GANGAPUR",
      },
      {
        CITY: "GANGRAR",
      },
      {
        CITY: "GANGTOK",
      },
      {
        CITY: "GANNAVARAM",
      },
      {
        CITY: "GANPAT",
      },
      {
        CITY: "GARGOTI",
      },
      {
        CITY: "GARHSHANKAR",
      },
      {
        CITY: "GAYA",
      },
      {
        CITY: "GHANA",
      },
      {
        CITY: "GHATAL",
      },
      {
        CITY: "GHATKOPAR",
      },
      {
        CITY: "GHAZIABAD",
      },
      {
        CITY: "GOA",
      },
      {
        CITY: "GOBICHETTIPALAYAM",
      },
      {
        CITY: "GODHRA",
      },
      {
        CITY: "GOHANA",
      },
      {
        CITY: "GOLAGHAT",
      },
      {
        CITY: "GOLD",
      },
      {
        CITY: "GONDA",
      },
      {
        CITY: "GORAKHPUR",
      },
      {
        CITY: "GOREGAON",
      },
      {
        CITY: "GOSHAINGAON",
      },
      {
        CITY: "GUDIVADA",
      },
      {
        CITY: "GUDUR",
      },
      {
        CITY: "GUINDY",
      },
      {
        CITY: "GUJRAT",
      },
      {
        CITY: "GULBARGA",
      },
      {
        CITY: "GUNA",
      },
      {
        CITY: "GUNTUR",
      },
      {
        CITY: "GURDASPUR",
      },
      {
        CITY: "GURGAON",
      },
      {
        CITY: "GURUVAYUR",
      },
      {
        CITY: "GUWAHATI",
      },
      {
        CITY: "GWALIOR",
      },
      {
        CITY: "HABRA",
      },
      {
        CITY: "HADADI",
      },
      {
        CITY: "HALDIA",
      },
      {
        CITY: "HALDWANI",
      },
      {
        CITY: "HAMIRPUR",
      },
      {
        CITY: "HAMIRPUR",
      },
      {
        CITY: "HANSI",
      },
      {
        CITY: "HAPUR",
      },
      {
        CITY: "HARI",
      },
      {
        CITY: "HARIDWAR",
      },
      {
        CITY: "HARIPAD",
      },
      {
        CITY: "HARIPUR",
      },
      {
        CITY: "HARYANA",
      },
      {
        CITY: "HASSAN",
      },
      {
        CITY: "HAVERI",
      },
      {
        CITY: "HAZARIBAGH",
      },
      {
        CITY: "HIMATNAGAR",
      },
      {
        CITY: "HINGANGHAT",
      },
      {
        CITY: "HINGOLI",
      },
      {
        CITY: "HIRA",
      },
      {
        CITY: "HIRIYUR",
      },
      {
        CITY: "HISAR",
      },
      {
        CITY: "HONAVAR",
      },
      {
        CITY: "HONG",
      },
      {
        CITY: "HOSHANGABAD",
      },
      {
        CITY: "HOSHIARPUR",
      },
      {
        CITY: "HOSUR",
      },
      {
        CITY: "HOWRAH",
      },
      {
        CITY: "HUBLI",
      },
      {
        CITY: "HUGLI",
      },
      {
        CITY: "HYDERABAD",
      },
      {
        CITY: "ICHALKARANJI",
      },
      {
        CITY: "IDUKKI",
      },
      {
        CITY: "IGATPURI",
      },
      {
        CITY: "IGLAS",
      },
      {
        CITY: "IMPHAL",
      },
      {
        CITY: "INDORE",
      },
      {
        CITY: "INDRAPRAST",
      },
      {
        CITY: "IRINJALAKUDA",
      },
      {
        CITY: "ITANAGAR",
      },
      {
        CITY: "JABALPUR",
      },
      {
        CITY: "JADABPUR",
      },
      {
        CITY: "JAGDALPUR",
      },
      {
        CITY: "JAGRAON",
      },
      {
        CITY: "JAIPUR",
      },
      {
        CITY: "JAISALMER",
      },
      {
        CITY: "JAJPUR",
      },
      {
        CITY: "JALALABAD",
      },
      {
        CITY: "JALALPUR",
      },
      {
        CITY: "JALANDHAR",
      },
      {
        CITY: "JALESAR",
      },
      {
        CITY: "JALGAON JAMOD",
      },
      {
        CITY: "JALNA",
      },
      {
        CITY: "JALPAIGURI",
      },
      {
        CITY: "JAMAL",
      },
      {
        CITY: "JAMMU",
      },
      {
        CITY: "JAMNAGAR",
      },
      {
        CITY: "JAMSHEDPUR",
      },
      {
        CITY: "JANJGIR",
      },
      {
        CITY: "JASPUR",
      },
      {
        CITY: "JATANI",
      },
      {
        CITY: "JAUNPUR",
      },
      {
        CITY: "JAYANTI",
      },
      {
        CITY: "JAYNAGAR",
      },
      {
        CITY: "JAYPUR",
      },
      {
        CITY: "JHA JHA",
      },
      {
        CITY: "JHAJJAR",
      },
      {
        CITY: "JHALAWAR",
      },
      {
        CITY: "JHANSI",
      },
      {
        CITY: "JHARGRAM",
      },
      {
        CITY: "JHARSUGUDA",
      },
      {
        CITY: "JHUNJHUNUN",
      },
      {
        CITY: "JIND",
      },
      {
        CITY: "JODHPUR",
      },
      {
        CITY: "JORHAT",
      },
      {
        CITY: "JUNAGADH",
      },
      {
        CITY: "KADAPA",
      },
      {
        CITY: "KAGAL",
      },
      {
        CITY: "KAILARAS",
      },
      {
        CITY: "KAIMGANJ",
      },
      {
        CITY: "KAITHAL",
      },
      {
        CITY: "KAKDWIP",
      },
      {
        CITY: "KAKINADA",
      },
      {
        CITY: "KALADI",
      },
      {
        CITY: "KALAM",
      },
      {
        CITY: "KALAMBOLI",
      },
      {
        CITY: "KALAN",
      },
      {
        CITY: "KALINGA",
      },
      {
        CITY: "KALKA",
      },
      {
        CITY: "KALKAJI DEVI",
      },
      {
        CITY: "KALOL",
      },
      {
        CITY: "KALPAKKAM",
      },
      {
        CITY: "KALPETTA",
      },
      {
        CITY: "KALRA",
      },
      {
        CITY: "KALYAN",
      },
      {
        CITY: "KALYANI",
      },
      {
        CITY: "KAMALPUR",
      },
      {
        CITY: "KAMALPURA",
      },
      {
        CITY: "KAMAT",
      },
      {
        CITY: "KANAKPURA",
      },
      {
        CITY: "KANCHIPURAM",
      },
      {
        CITY: "KANCHRAPARA",
      },
      {
        CITY: "KANDI",
      },
      {
        CITY: "KANGAYAM",
      },
      {
        CITY: "KANGRA",
      },
      {
        CITY: "KANHANGAD",
      },
      {
        CITY: "KANIGIRI",
      },
      {
        CITY: "KANIYAMBADI",
      },
      {
        CITY: "KANKAULI",
      },
      {
        CITY: "KANNIYAKUMARI",
      },
      {
        CITY: "KANNUR",
      },
      {
        CITY: "KANPUR",
      },
      {
        CITY: "KAPURTHALA TOWN",
      },
      {
        CITY: "KARAD",
      },
      {
        CITY: "KARAIKAL",
      },
      {
        CITY: "KARAIKUDI",
      },
      {
        CITY: "KARAMADAI",
      },
      {
        CITY: "KARAMSAD",
      },
      {
        CITY: "KARANJA",
      },
      {
        CITY: "KARARI",
      },
      {
        CITY: "KARGIL",
      },
      {
        CITY: "KARIMGANJ",
      },
      {
        CITY: "KARIMNAGAR",
      },
      {
        CITY: "KARJAT",
      },
      {
        CITY: "KARNAL",
      },
      {
        CITY: "KARSIYANG",
      },
      {
        CITY: "KARUR",
      },
      {
        CITY: "KARWAR",
      },
      {
        CITY: "KASAL",
      },
      {
        CITY: "KASARAGOD",
      },
      {
        CITY: "KASGANJ",
      },
      {
        CITY: "KASHIPUR",
      },
      {
        CITY: "KASIA",
      },
      {
        CITY: "KATARIA",
      },
      {
        CITY: "KATHUA",
      },
      {
        CITY: "KATNI",
      },
      {
        CITY: "KATOYA",
      },
      {
        CITY: "KATRA",
      },
      {
        CITY: "KAUL",
      },
      {
        CITY: "KAVALI",
      },
      {
        CITY: "KAVARATTI",
      },
      {
        CITY: "KAYAMKULAM",
      },
      {
        CITY: "KESHOD",
      },
      {
        CITY: "KHAJURAHO GROUP OF MONUMENTS",
      },
      {
        CITY: "KHALAPUR",
      },
      {
        CITY: "KHAMBHAT",
      },
      {
        CITY: "KHAMMAM",
      },
      {
        CITY: "KHAN",
      },
      {
        CITY: "KHANNA",
      },
      {
        CITY: "KHARAGPUR",
      },
      {
        CITY: "KHARAR",
      },
      {
        CITY: "KHARGONE",
      },
      {
        CITY: "KHATAULI",
      },
      {
        CITY: "KHEDA",
      },
      {
        CITY: "KHERGAM",
      },
      {
        CITY: "KHERI",
      },
      {
        CITY: "KHINWARA",
      },
      {
        CITY: "KHOPOLI",
      },
      {
        CITY: "KHURDA",
      },
      {
        CITY: "KHURJA",
      },
      {
        CITY: "KISHANGARH",
      },
      {
        CITY: "KOCH BIHAR",
      },
      {
        CITY: "KOCHI",
      },
      {
        CITY: "KODAIKANAL",
      },
      {
        CITY: "KODUNGALLUR",
      },
      {
        CITY: "KOHIMA",
      },
      {
        CITY: "KOKRAJHAR",
      },
      {
        CITY: "KOLAR",
      },
      {
        CITY: "KOLAYAT",
      },
      {
        CITY: "KOLHAPUR",
      },
      {
        CITY: "KOLKATA",
      },
      {
        CITY: "KOLLAM",
      },
      {
        CITY: "KOLLEGAL",
      },
      {
        CITY: "KONI",
      },
      {
        CITY: "KONI",
      },
      {
        CITY: "KONNAGAR",
      },
      {
        CITY: "KOOTHANALLUR",
      },
      {
        CITY: "KOPPAL",
      },
      {
        CITY: "KORAPUT",
      },
      {
        CITY: "KORBA",
      },
      {
        CITY: "KOSAMBA",
      },
      {
        CITY: "KOT ISA KHAN",
      },
      {
        CITY: "KOTA",
      },
      {
        CITY: "KOTIAN",
      },
      {
        CITY: "KOTTAGUDEM",
      },
      {
        CITY: "KOTTAKKAL",
      },
      {
        CITY: "KOTTARAKARA",
      },
      {
        CITY: "KOTTAYAM",
      },
      {
        CITY: "KOVILPATTI",
      },
      {
        CITY: "KOVVUR",
      },
      {
        CITY: "KOZHIKODE",
      },
      {
        CITY: "KRISHNAGIRI",
      },
      {
        CITY: "KULTI",
      },
      {
        CITY: "KUMAR",
      },
      {
        CITY: "KUMBAKONAM",
      },
      {
        CITY: "KUMHARI",
      },
      {
        CITY: "KUNDAN",
      },
      {
        CITY: "KUNWAR",
      },
      {
        CITY: "KUPPAM",
      },
      {
        CITY: "KURALI",
      },
      {
        CITY: "KURNOOL",
      },
      {
        CITY: "KUSHALNAGAR",
      },
      {
        CITY: "KUZHITHURAI",
      },
      {
        CITY: "LADWA",
      },
      {
        CITY: "LAKHIMPUR",
      },
      {
        CITY: "LALA",
      },
      {
        CITY: "LALGUDI",
      },
      {
        CITY: "LAMBA HARISINGH",
      },
      {
        CITY: "LANKA",
      },
      {
        CITY: "LATUR",
      },
      {
        CITY: "LILUAH",
      },
      {
        CITY: "LOHAGHAT",
      },
      {
        CITY: "LUCKNOW",
      },
      {
        CITY: "LUDHIANA",
      },
      {
        CITY: "MACHHIWARA",
      },
      {
        CITY: "MACHILIPATNAM",
      },
      {
        CITY: "MADANAPALLE",
      },
      {
        CITY: "MADGAON",
      },
      {
        CITY: "MADHOGANJ",
      },
      {
        CITY: "MADIKERI",
      },
      {
        CITY: "MADURAI",
      },
      {
        CITY: "MADURANTAKAM",
      },
      {
        CITY: "MAHABALIPURAM",
      },
      {
        CITY: "MAHAD",
      },
      {
        CITY: "MAHAJAN",
      },
      {
        CITY: "MAHAL",
      },
      {
        CITY: "MAHARAJ",
      },
      {
        CITY: "MAHATMA",
      },
      {
        CITY: "MAHESANA",
      },
      {
        CITY: "MAHESH",
      },
      {
        CITY: "MAHIM",
      },
      {
        CITY: "MAHULIA",
      },
      {
        CITY: "MALAPPURAM",
      },
      {
        CITY: "MALDAH",
      },
      {
        CITY: "MALPUR",
      },
      {
        CITY: "MANALI",
      },
      {
        CITY: "MANCHERIAL",
      },
      {
        CITY: "MANDAL",
      },
      {
        CITY: "MANDAPETA",
      },
      {
        CITY: "MANDI",
      },
      {
        CITY: "MANDLA",
      },
      {
        CITY: "MANDSAUR",
      },
      {
        CITY: "MANDVI",
      },
      {
        CITY: "MANDYA",
      },
      {
        CITY: "MANGALAGIRI",
      },
      {
        CITY: "MANGALORE",
      },
      {
        CITY: "MANGAON",
      },
      {
        CITY: "MANIPALA",
      },
      {
        CITY: "MANIPUR",
      },
      {
        CITY: "MANJERI",
      },
      {
        CITY: "MANNA",
      },
      {
        CITY: "MANNARGUDI",
      },
      {
        CITY: "MANOR",
      },
      {
        CITY: "MANSA",
      },
      {
        CITY: "MANU",
      },
      {
        CITY: "MARKAL",
      },
      {
        CITY: "MARKAPUR",
      },
      {
        CITY: "MARMAGAO",
      },
      {
        CITY: "MARU",
      },
      {
        CITY: "MASHOBRA",
      },
      {
        CITY: "MATAR",
      },
      {
        CITY: "MATHAN",
      },
      {
        CITY: "MATHURA",
      },
      {
        CITY: "MATTANUR",
      },
      {
        CITY: "MAVELIKARA",
      },
      {
        CITY: "MAWANA",
      },
      {
        CITY: "MAYAPUR",
      },
      {
        CITY: "MEDAK",
      },
      {
        CITY: "MEDARAMETLA",
      },
      {
        CITY: "MEDCHAL",
      },
      {
        CITY: "MEDINIPUR",
      },
      {
        CITY: "MEERUT",
      },
      {
        CITY: "MEHRA",
      },
      {
        CITY: "METTUR",
      },
      {
        CITY: "MHOW",
      },
      {
        CITY: "MILL",
      },
      {
        CITY: "MIRAJ",
      },
      {
        CITY: "MIRZA MURAD",
      },
      {
        CITY: "MIRZAPUR",
      },
      {
        CITY: "MITHAPUR",
      },
      {
        CITY: "MODASA",
      },
      {
        CITY: "MOGA",
      },
      {
        CITY: "MOHALA",
      },
      {
        CITY: "MOHALI",
      },
      {
        CITY: "MOHAN",
      },
      {
        CITY: "MORADABAD",
      },
      {
        CITY: "MORENA",
      },
      {
        CITY: "MORINDA",
      },
      {
        CITY: "MORVI",
      },
      {
        CITY: "MOTIHARI",
      },
      {
        CITY: "MOUNT ABU",
      },
      {
        CITY: "MUDDANURU",
      },
      {
        CITY: "MUKERIAN",
      },
      {
        CITY: "MUKTSAR",
      },
      {
        CITY: "MULTI",
      },
      {
        CITY: "MUMBAI",
      },
      {
        CITY: "MUNDGOD",
      },
      {
        CITY: "MUNDRA",
      },
      {
        CITY: "MUNGER",
      },
      {
        CITY: "MURSHIDABAD",
      },
      {
        CITY: "MUSSOORIE",
      },
      {
        CITY: "MUZAFFARNAGAR",
      },
      {
        CITY: "MUZAFFARPUR",
      },
      {
        CITY: "MYLAPORE",
      },
      {
        CITY: "MYSORE",
      },
      {
        CITY: "NABADWIP",
      },
      {
        CITY: "NABHA",
      },
      {
        CITY: "NADGAON",
      },
      {
        CITY: "NADIA",
      },
      {
        CITY: "NADIAD",
      },
      {
        CITY: "NAGAL",
      },
      {
        CITY: "NAGAPATTINAM",
      },
      {
        CITY: "NAGAR",
      },
      {
        CITY: "NAGARA",
      },
      {
        CITY: "NAGARI",
      },
      {
        CITY: "NAGAUR",
      },
      {
        CITY: "NAGERCOIL",
      },
      {
        CITY: "NAGPUR",
      },
      {
        CITY: "NAGWA",
      },
      {
        CITY: "NAINI",
      },
      {
        CITY: "NALAGARH",
      },
      {
        CITY: "NALBARI",
      },
      {
        CITY: "NALGONDA",
      },
      {
        CITY: "NAMAKKAL",
      },
      {
        CITY: "NAMRUP",
      },
      {
        CITY: "NANDA",
      },
      {
        CITY: "NANDED",
      },
      {
        CITY: "NANDI",
      },
      {
        CITY: "NANDIGAMA",
      },
      {
        CITY: "NANDURBAR",
      },
      {
        CITY: "NANDYAL",
      },
      {
        CITY: "NARAINA",
      },
      {
        CITY: "NARASARAOPET",
      },
      {
        CITY: "NARAYANGAON",
      },
      {
        CITY: "NARELA",
      },
      {
        CITY: "NARNAUL",
      },
      {
        CITY: "NARSAPUR",
      },
      {
        CITY: "NASHIK",
      },
      {
        CITY: "NATHDWARA",
      },
      {
        CITY: "NAVELIM",
      },
      {
        CITY: "NAVSARI",
      },
      {
        CITY: "NAYAGARH",
      },
      {
        CITY: "NAZIRA",
      },
      {
        CITY: "NEHRA",
      },
      {
        CITY: "NELLORE",
      },
      {
        CITY: "NERAL",
      },
      {
        CITY: "NERI",
      },
      {
        CITY: "NEW DELHI",
      },
      {
        CITY: "NEYVELI",
      },
      {
        CITY: "NILA",
      },
      {
        CITY: "NILAMBUR",
      },
      {
        CITY: "NILOKHERI",
      },
      {
        CITY: "NIZAMABAD",
      },
      {
        CITY: "NOIDA",
      },
      {
        CITY: "NONGPOH",
      },
      {
        CITY: "NONGSTOIN",
      },
      {
        CITY: "NORTH LAKHIMPUR",
      },
      {
        CITY: "NURPUR",
      },
      {
        CITY: "NUZVID",
      },
      {
        CITY: "ODHAN",
      },
      {
        CITY: "OMALUR",
      },
      {
        CITY: "ONGOLE",
      },
      {
        CITY: "OOTY",
      },
      {
        CITY: "ORAI",
      },
      {
        CITY: "OSMANABAD",
      },
      {
        CITY: "OTTAPPALAM",
      },
      {
        CITY: "PACHMARHI",
      },
      {
        CITY: "PADRAUNA",
      },
      {
        CITY: "PAHALGAM",
      },
      {
        CITY: "PAKALA",
      },
      {
        CITY: "PALA",
      },
      {
        CITY: "PALAKKAD",
      },
      {
        CITY: "PALAMPUR",
      },
      {
        CITY: "PALANI",
      },
      {
        CITY: "PALAYAM",
      },
      {
        CITY: "PALGHAR",
      },
      {
        CITY: "PALI",
      },
      {
        CITY: "PALLADAM",
      },
      {
        CITY: "PALONCHA",
      },
      {
        CITY: "PALUS",
      },
      {
        CITY: "PALWAL",
      },
      {
        CITY: "PANCHAL",
      },
      {
        CITY: "PANCHGANI",
      },
      {
        CITY: "PANDHARPUR",
      },
      {
        CITY: "PANIPAT",
      },
      {
        CITY: "PANJIM",
      },
      {
        CITY: "PANRUTI",
      },
      {
        CITY: "PANTNAGAR",
      },
      {
        CITY: "PANVEL",
      },
      {
        CITY: "PAONTA SAHIB",
      },
      {
        CITY: "PARAPPANANGADI",
      },
      {
        CITY: "PARAVUR",
      },
      {
        CITY: "PARBHANI",
      },
      {
        CITY: "PAREL",
      },
      {
        CITY: "PARRA",
      },
      {
        CITY: "PATAN",
      },
      {
        CITY: "PATANCHERU",
      },
      {
        CITY: "PATEL",
      },
      {
        CITY: "PATELGUDA",
      },
      {
        CITY: "PATHANAMTHITTA",
      },
      {
        CITY: "PATHANKOT",
      },
      {
        CITY: "PATIALA",
      },
      {
        CITY: "PATNA",
      },
      {
        CITY: "PATTAMBI",
      },
      {
        CITY: "PATTUKKOTTAI",
      },
      {
        CITY: "PAURI",
      },
      {
        CITY: "PAYYANUR",
      },
      {
        CITY: "PEDDAPURAM",
      },
      {
        CITY: "PEHOWA",
      },
      {
        CITY: "PERAMBALUR",
      },
      {
        CITY: "PERANAMPATTU",
      },
      {
        CITY: "PERUNDURAI",
      },
      {
        CITY: "PETLAD",
      },
      {
        CITY: "PHAGWARA",
      },
      {
        CITY: "PHAPHAMAU",
      },
      {
        CITY: "PIDUGURALLA",
      },
      {
        CITY: "PILANI",
      },
      {
        CITY: "PILERU",
      },
      {
        CITY: "PILKHUWA",
      },
      {
        CITY: "PIMPRI",
      },
      {
        CITY: "PITAMPURA",
      },
      {
        CITY: "PITHAPURAM",
      },
      {
        CITY: "PITHORAGARH",
      },
      {
        CITY: "POCHAMPALLI",
      },
      {
        CITY: "POLLACHI",
      },
      {
        CITY: "PONDA",
      },
      {
        CITY: "PONNANI",
      },
      {
        CITY: "PONNERI",
      },
      {
        CITY: "PORBANDAR",
      },
      {
        CITY: "PORT BLAIR",
      },
      {
        CITY: "POTTI",
      },
      {
        CITY: "POWAI",
      },
      {
        CITY: "PRODDATUR",
      },
      {
        CITY: "PUDUCHERRY",
      },
      {
        CITY: "PUDUKKOTTAI",
      },
      {
        CITY: "PULIYUR",
      },
      {
        CITY: "PUNALUR",
      },
      {
        CITY: "PUNE",
      },
      {
        CITY: "PURAS",
      },
      {
        CITY: "PURI",
      },
      {
        CITY: "PURNEA",
      },
      {
        CITY: "PURULIYA",
      },
      {
        CITY: "PUSA",
      },
      {
        CITY: "PUSHKAR",
      },
      {
        CITY: "PUTTUR",
      },
      {
        CITY: "PUTTUR",
      },
      {
        CITY: "QUEPEM",
      },
      {
        CITY: "RAICHUR",
      },
      {
        CITY: "RAIGARH",
      },
      {
        CITY: "RAIPUR",
      },
      {
        CITY: "RAIPUR",
      },
      {
        CITY: "RAJAHMUNDRY",
      },
      {
        CITY: "RAJAPALAIYAM",
      },
      {
        CITY: "RAJAPUR",
      },
      {
        CITY: "RAJKOT",
      },
      {
        CITY: "RAJPUR",
      },
      {
        CITY: "RAJPURA",
      },
      {
        CITY: "RAJU",
      },
      {
        CITY: "RAMA",
      },
      {
        CITY: "RAMANAGARAM",
      },
      {
        CITY: "RAMANATHAPURAM",
      },
      {
        CITY: "RAMAPURAM",
      },
      {
        CITY: "RAMAVARAM",
      },
      {
        CITY: "RAMGARH",
      },
      {
        CITY: "RAMNAGAR",
      },
      {
        CITY: "RAMPUR",
      },
      {
        CITY: "RANA",
      },
      {
        CITY: "RANAGHAT",
      },
      {
        CITY: "RANCHI",
      },
      {
        CITY: "RANDER",
      },
      {
        CITY: "RANIGANJ",
      },
      {
        CITY: "RANIPPETTAI",
      },
      {
        CITY: "RANJAN",
      },
      {
        CITY: "RATLAM",
      },
      {
        CITY: "RATNAGIRI",
      },
      {
        CITY: "RAURKELA",
      },
      {
        CITY: "RAWAL",
      },
      {
        CITY: "RAXAUL",
      },
      {
        CITY: "RAYAGADA",
      },
      {
        CITY: "REWA",
      },
      {
        CITY: "REWARI",
      },
      {
        CITY: "RING",
      },
      {
        CITY: "RISHIKESH",
      },
      {
        CITY: "ROHTAK",
      },
      {
        CITY: "ROORKEE",
      },
      {
        CITY: "ROSHAN",
      },
      {
        CITY: "RUDRAPUR",
      },
      {
        CITY: "RUPNAGAR",
      },
      {
        CITY: "RUPNARAYANPUR",
      },
      {
        CITY: "SACHIN",
      },
      {
        CITY: "SAGAR",
      },
      {
        CITY: "SAGAR",
      },
      {
        CITY: "SAHA",
      },
      {
        CITY: "SAHARANPUR",
      },
      {
        CITY: "SAHIBABAD",
      },
      {
        CITY: "SAKRI",
      },
      {
        CITY: "SAKRI",
      },
      {
        CITY: "SALEM",
      },
      {
        CITY: "SALIGAO",
      },
      {
        CITY: "SALT LAKE CITY",
      },
      {
        CITY: "SAMASTIPUR",
      },
      {
        CITY: "SAMBALPUR",
      },
      {
        CITY: "SANAND",
      },
      {
        CITY: "SANDUR",
      },
      {
        CITY: "SANGAM",
      },
      {
        CITY: "SANGAMNER",
      },
      {
        CITY: "SANGLI",
      },
      {
        CITY: "SANGOLA",
      },
      {
        CITY: "SANGRUR",
      },
      {
        CITY: "SANQUELIM",
      },
      {
        CITY: "SARANGA",
      },
      {
        CITY: "SARANGI",
      },
      {
        CITY: "SARWAR",
      },
      {
        CITY: "SATARA",
      },
      {
        CITY: "SATNA",
      },
      {
        CITY: "SATTUR",
      },
      {
        CITY: "SAWI",
      },
      {
        CITY: "SECUNDERABAD",
      },
      {
        CITY: "SEHORE",
      },
      {
        CITY: "SENDHWA",
      },
      {
        CITY: "SERAMPORE",
      },
      {
        CITY: "SHADNAGAR",
      },
      {
        CITY: "SHAHABAD",
      },
      {
        CITY: "SHAHAPUR",
      },
      {
        CITY: "SHAHDARA",
      },
      {
        CITY: "SHAHDOL",
      },
      {
        CITY: "SHAHJAHANPUR",
      },
      {
        CITY: "SHAHKOT",
      },
      {
        CITY: "SHAMSABAD",
      },
      {
        CITY: "SHANTI GRAMA",
      },
      {
        CITY: "SHILLONG",
      },
      {
        CITY: "SHIMLA",
      },
      {
        CITY: "SHIMOGA",
      },
      {
        CITY: "SHIRGAON",
      },
      {
        CITY: "SHIV",
      },
      {
        CITY: "SHOLAVANDAN",
      },
      {
        CITY: "SHORANUR",
      },
      {
        CITY: "SHRIGONDA",
      },
      {
        CITY: "SHYAMNAGAR",
      },
      {
        CITY: "SIBSAGAR",
      },
      {
        CITY: "SIDHI",
      },
      {
        CITY: "SIDHPUR",
      },
      {
        CITY: "SIKAR",
      },
      {
        CITY: "SIKKA",
      },
      {
        CITY: "SILCHAR",
      },
      {
        CITY: "SILIGURI",
      },
      {
        CITY: "SILVASSA",
      },
      {
        CITY: "SINGARAYAKONDA",
      },
      {
        CITY: "SINGTAM",
      },
      {
        CITY: "SINNAR",
      },
      {
        CITY: "SION",
      },
      {
        CITY: "SIRHIND",
      },
      {
        CITY: "SIRKAZHI",
      },
      {
        CITY: "SIROHI",
      },
      {
        CITY: "SIRSA",
      },
      {
        CITY: "SIRSI",
      },
      {
        CITY: "SIRUGUPPA",
      },
      {
        CITY: "SIRUSERI",
      },
      {
        CITY: "SIRWANI",
      },
      {
        CITY: "SITAPUR",
      },
      {
        CITY: "SIURI",
      },
      {
        CITY: "SIVAGANGA",
      },
      {
        CITY: "SIVAKASI",
      },
      {
        CITY: "SODHI",
      },
      {
        CITY: "SOJAT",
      },
      {
        CITY: "SOLAN",
      },
      {
        CITY: "SOLAPUR",
      },
      {
        CITY: "SOLIM",
      },
      {
        CITY: "SOMNATH",
      },
      {
        CITY: "SONI",
      },
      {
        CITY: "SONIPAT",
      },
      {
        CITY: "SOPARA",
      },
      {
        CITY: "SRIKAKULAM",
      },
      {
        CITY: "SRIKALAHASTI",
      },
      {
        CITY: "SRINAGAR",
      },
      {
        CITY: "SRIPERUMBUDUR",
      },
      {
        CITY: "SRIRANGAM",
      },
      {
        CITY: "SRIVILLIPUTHUR",
      },
      {
        CITY: "SUKMA",
      },
      {
        CITY: "SULTAN",
      },
      {
        CITY: "SULTANPUR",
      },
      {
        CITY: "SULTANS BATTERY",
      },
      {
        CITY: "SUMAN",
      },
      {
        CITY: "SUNAM",
      },
      {
        CITY: "SUNDARGARH",
      },
      {
        CITY: "SURANA",
      },
      {
        CITY: "SURATGARH",
      },
      {
        CITY: "SURENDRANAGAR",
      },
      {
        CITY: "SURIAPET",
      },
      {
        CITY: "TADEPALLEGUDEM",
      },
      {
        CITY: "TALA",
      },
      {
        CITY: "TALCHER",
      },
      {
        CITY: "TALEGAON DABHADE",
      },
      {
        CITY: "TALWANDI SABO",
      },
      {
        CITY: "TAMBARAM",
      },
      {
        CITY: "TANDA",
      },
      {
        CITY: "TANUKU",
      },
      {
        CITY: "TARN TARAN",
      },
      {
        CITY: "TERI",
      },
      {
        CITY: "TEZPUR",
      },
      {
        CITY: "THALASSERY",
      },
      {
        CITY: "THANE",
      },
      {
        CITY: "THANJAVUR",
      },
      {
        CITY: "THASRA",
      },
      {
        CITY: "THENALI",
      },
      {
        CITY: "THENKASI",
      },
      {
        CITY: "THIRUMANGALAM",
      },
      {
        CITY: "THIRUTHANI",
      },
      {
        CITY: "THIRUVANANTHAPURAM",
      },
      {
        CITY: "THIRUVARUR",
      },
      {
        CITY: "THOOTHUKUDI",
      },
      {
        CITY: "THRISSUR",
      },
      {
        CITY: "TIKAMGARH",
      },
      {
        CITY: "TINDIVANAM",
      },
      {
        CITY: "TINSUKIA",
      },
      {
        CITY: "TIPTUR",
      },
      {
        CITY: "TIRUCHCHENDUR",
      },
      {
        CITY: "TIRUCHI",
      },
      {
        CITY: "TIRUMALA",
      },
      {
        CITY: "TIRUMALA - TIRUPATI",
      },
      {
        CITY: "TIRUNELVELI",
      },
      {
        CITY: "TIRUPPUR",
      },
      {
        CITY: "TIRUR",
      },
      {
        CITY: "TIRUVALLA",
      },
      {
        CITY: "TIRUVALLUR",
      },
      {
        CITY: "TIRUVANNAMALAI",
      },
      {
        CITY: "TOHANA",
      },
      {
        CITY: "TONK",
      },
      {
        CITY: "TRIMBAK",
      },
      {
        CITY: "TULJAPUR",
      },
      {
        CITY: "TURAIYUR",
      },
      {
        CITY: "UDAIGIRI",
      },
      {
        CITY: "UDAIPUR",
      },
      {
        CITY: "UDUPI",
      },
      {
        CITY: "UJJAIN",
      },
      {
        CITY: "ULHASNAGAR",
      },
      {
        CITY: "ULUBARI",
      },
      {
        CITY: "UMRED",
      },
      {
        CITY: "UNNAO",
      },
      {
        CITY: "UPPAL",
      },
      {
        CITY: "UTTARKASHI",
      },
      {
        CITY: "VADAMADURAI",
      },
      {
        CITY: "VADNER",
      },
      {
        CITY: "VADODARA",
      },
      {
        CITY: "VAIKAM",
      },
      {
        CITY: "VAINGUINIM",
      },
      {
        CITY: "VALSAD",
      },
      {
        CITY: "VANDALUR",
      },
      {
        CITY: "VANDAVASI",
      },
      {
        CITY: "VANIYAMBADI",
      },
      {
        CITY: "VAPI",
      },
      {
        CITY: "VARANASI",
      },
      {
        CITY: "VASAI",
      },
      {
        CITY: "VASCO",
      },
      {
        CITY: "VASHI",
      },
      {
        CITY: "VAZHAKULAM",
      },
      {
        CITY: "VELLORE",
      },
      {
        CITY: "VERNA",
      },
      {
        CITY: "VIDISHA",
      },
      {
        CITY: "VIJAPUR",
      },
      {
        CITY: "VIJAYAWADA",
      },
      {
        CITY: "VIKARABAD",
      },
      {
        CITY: "VIKASNAGAR",
      },
      {
        CITY: "VILLUPURAM",
      },
      {
        CITY: "VINUKONDA",
      },
      {
        CITY: "VIRAR",
      },
      {
        CITY: "VISAKHAPATNAM",
      },
      {
        CITY: "VISNAGAR",
      },
      {
        CITY: "VIZIANAGARAM",
      },
      {
        CITY: "WAI",
      },
      {
        CITY: "WARANGAL",
      },
      {
        CITY: "WARDHA",
      },
      {
        CITY: "WELLINGTON",
      },
      {
        CITY: "YADGIR",
      },
      {
        CITY: "YAMUNANAGAR",
      },
      {
        CITY: "YANAM",
      },
      {
        CITY: "YAVATMAL",
      },
      {
        CITY: "YEOLA",
      },
      {
        CITY: "YERCAUD",
      },
    ],
  },
  {
    COUNTRY: "ICELAND",
    CODE: "IS",
    CITIES: [
      {
        CITY: "AKRANES",
      },
      {
        CITY: "AKUREYRI",
      },
      {
        CITY: "BORGARNES",
      },
      {
        CITY: "DALVIK",
      },
      {
        CITY: "GRINDAVIK",
      },
      {
        CITY: "HELLA",
      },
      {
        CITY: "HOLMAVIK",
      },
      {
        CITY: "HUSAVIK",
      },
      {
        CITY: "HVAMMSTANGI",
      },
      {
        CITY: "HVERAGERDI",
      },
      {
        CITY: "HVOLSVOLLUR",
      },
      {
        CITY: "KOPAVOGUR",
      },
      {
        CITY: "REYKJAVIK",
      },
      {
        CITY: "SELFOSS",
      },
      {
        CITY: "SKAGASTROND",
      },
      {
        CITY: "STOKKSEYRI",
      },
      {
        CITY: "VESTMANNAEYJAR",
      },
      {
        CITY: "VOGAR",
      },
    ],
  },
  {
    COUNTRY: "ISRAEL",
    CODE: "IL",
    CITIES: [
      {
        CITY: "`ALMA",
      },
      {
        CITY: "`AMIR",
      },
      {
        CITY: "`ARUGOT",
      },
      {
        CITY: "`ASERET",
      },
      {
        CITY: "`EN AYYALA",
      },
      {
        CITY: "`EN HASHELOSHA",
      },
      {
        CITY: "`EVRON",
      },
      {
        CITY: "ACRE",
      },
      {
        CITY: "AFIQIM",
      },
      {
        CITY: "AHITUV",
      },
      {
        CITY: "ALLONIM",
      },
      {
        CITY: "ASHDOD",
      },
      {
        CITY: "ASHQELON",
      },
      {
        CITY: "AZOR",
      },
      {
        CITY: "BAHAN",
      },
      {
        CITY: "BARAQ",
      },
      {
        CITY: "BAREQET",
      },
      {
        CITY: "BAT HADAR",
      },
      {
        CITY: "BAT HEFER",
      },
      {
        CITY: "BAT YAM",
      },
      {
        CITY: "BEERSHEBA",
      },
      {
        CITY: "BEN SHEMEN-KEFAR HANO`AR",
      },
      {
        CITY: "BENE ZIYYON",
      },
      {
        CITY: "BET ALFA",
      },
      {
        CITY: "BET DAGAN",
      },
      {
        CITY: "BET OREN",
      },
      {
        CITY: "BET SHEMESH",
      },
      {
        CITY: "BINYAMINA",
      },
      {
        CITY: "BIRIYYA",
      },
      {
        CITY: "DABBURIYA",
      },
      {
        CITY: "DIMONA",
      },
      {
        CITY: "EILAT",
      },
      {
        CITY: "ELYAKHIN",
      },
      {
        CITY: "ELYAQIM",
      },
      {
        CITY: "EMUNIM",
      },
      {
        CITY: "ET TAIYIBA",
      },
      {
        CITY: "EVEN YEHUDA",
      },
      {
        CITY: "GAN HAYYIM",
      },
      {
        CITY: "GAN YAVNE",
      },
      {
        CITY: "GANNE TIQWA",
      },
      {
        CITY: "GEDERA",
      },
      {
        CITY: "GIBBETON",
      },
      {
        CITY: "GIMZO",
      },
      {
        CITY: "GINNOSAR",
      },
      {
        CITY: "GIV`AT HAYYIM",
      },
      {
        CITY: "HADAR `AM",
      },
      {
        CITY: "HADAR RAMATAYIM",
      },
      {
        CITY: "HADERA",
      },
      {
        CITY: "HADID",
      },
      {
        CITY: "HAIFA",
      },
      {
        CITY: "HAKARMEL",
      },
      {
        CITY: "HALUZ",
      },
      {
        CITY: "HAZAV",
      },
      {
        CITY: "HAZOR ASHDOD",
      },
      {
        CITY: "HAZOR HAGELILIT",
      },
      {
        CITY: "HERUT",
      },
      {
        CITY: "HERZLIYA",
      },
      {
        CITY: "HEVER",
      },
      {
        CITY: "HOD HASHARON",
      },
      {
        CITY: "HOLON",
      },
      {
        CITY: "HURFEISH",
      },
      {
        CITY: "JAFFA",
      },
      {
        CITY: "JERUSALEM",
      },
      {
        CITY: "KADIMA",
      },
      {
        CITY: "KARKUR",
      },
      {
        CITY: "KEFAR DANIYYEL",
      },
      {
        CITY: "KEFAR NETTER",
      },
      {
        CITY: "KEFAR WITQIN",
      },
      {
        CITY: "KEFAR YONA",
      },
      {
        CITY: "KFAR SABA",
      },
      {
        CITY: "LIMAN",
      },
      {
        CITY: "LOD",
      },
      {
        CITY: "MAALOT TARSHIHA",
      },
      {
        CITY: "MAGEN",
      },
      {
        CITY: "MAGHAR",
      },
      {
        CITY: "MAZKERET BATYA",
      },
      {
        CITY: "MAZLIAH",
      },
      {
        CITY: "MAZOR",
      },
      {
        CITY: "MESILLAT ZIYYON",
      },
      {
        CITY: "MIGDAL",
      },
      {
        CITY: "MIKHMORET",
      },
      {
        CITY: "MISGAV REGIONAL COUNCIL",
      },
      {
        CITY: "MIZPE NETOFA",
      },
      {
        CITY: "MODIIN",
      },
      {
        CITY: "MORAN",
      },
      {
        CITY: "NAHAM",
      },
      {
        CITY: "NAHARIYA",
      },
      {
        CITY: "NAZARETH",
      },
      {
        CITY: "NAZERAT `ILLIT",
      },
      {
        CITY: "NESHER",
      },
      {
        CITY: "NESS ZIONA",
      },
      {
        CITY: "NETANYA",
      },
      {
        CITY: "NETIVOT",
      },
      {
        CITY: "NEWE EFRAYIM",
      },
      {
        CITY: "NEWE YAMIN",
      },
      {
        CITY: "NIR ZEVI",
      },
      {
        CITY: "NIRIM",
      },
      {
        CITY: "NORDIYYA",
      },
      {
        CITY: "OFAQIM",
      },
      {
        CITY: "OR `AQIVA",
      },
      {
        CITY: "OR YEHUDA",
      },
      {
        CITY: "PARDES HANNA KARKUR",
      },
      {
        CITY: "PARDESIYYA",
      },
      {
        CITY: "PASUTA",
      },
      {
        CITY: "PETAH TIKWAH",
      },
      {
        CITY: "QIRYAT ATA",
      },
      {
        CITY: "QIRYAT BIALIK",
      },
      {
        CITY: "QIRYAT GAT",
      },
      {
        CITY: "QIRYAT HAYYIM",
      },
      {
        CITY: "QIRYAT MOTZKIN",
      },
      {
        CITY: "QIRYAT ONO",
      },
      {
        CITY: "QIRYAT TIV`ON",
      },
      {
        CITY: "QIRYAT YAM",
      },
      {
        CITY: "RAMAT AVIV",
      },
      {
        CITY: "RAMAT DAWID",
      },
      {
        CITY: "RAMAT EF`AL",
      },
      {
        CITY: "RAMAT GAN",
      },
      {
        CITY: "RAMAT HASHARON",
      },
      {
        CITY: "RAMAT POLEG",
      },
      {
        CITY: "RAMAT YISHAY",
      },
      {
        CITY: "RAMLA",
      },
      {
        CITY: "RAMOT NAFTALI",
      },
      {
        CITY: "REHOVOT",
      },
      {
        CITY: "RINNATYA",
      },
      {
        CITY: "RISHON LEZION",
      },
      {
        CITY: "RISHPON",
      },
      {
        CITY: "SAFED",
      },
      {
        CITY: "SARID",
      },
      {
        CITY: "SAVYON",
      },
      {
        CITY: "SDE BOKER",
      },
      {
        CITY: "SDE WARBURG",
      },
      {
        CITY: "SDEROT",
      },
      {
        CITY: "SEDOT YAM",
      },
      {
        CITY: "SHAMIR",
      },
      {
        CITY: "SHAVE ZIYYON",
      },
      {
        CITY: "SHEFAYIM",
      },
      {
        CITY: "SHELOMI",
      },
      {
        CITY: "SHETULIM",
      },
      {
        CITY: "SHOVAL",
      },
      {
        CITY: "TALME MENASHE",
      },
      {
        CITY: "TEL AVIV",
      },
      {
        CITY: "TEL MOND",
      },
      {
        CITY: "TIBERIAS",
      },
      {
        CITY: "TIMRAT",
      },
      {
        CITY: "TIRAT KARMEL",
      },
      {
        CITY: "TIRAT YEHUDA",
      },
      {
        CITY: "URIM",
      },
      {
        CITY: "YAQUM",
      },
      {
        CITY: "YAVNE",
      },
      {
        CITY: "YEHUD",
      },
      {
        CITY: "ZORAN",
      },
    ],
  },
  {
    COUNTRY: "LIBYA",
    CODE: "LY",
    CITIES: [
      {
        CITY: "BENGHAZI",
      },
      {
        CITY: "MISRATAH",
      },
      {
        CITY: "SABHA",
      },
      {
        CITY: "TRIPOLI",
      },
      {
        CITY: "ZLITEN",
      },
    ],
  },
  {
    COUNTRY: "ISLE OF MAN",
    CODE: "IM",
    CITIES: [
      {
        CITY: "BALLASALLA",
      },
      {
        CITY: "CASTLETOWN",
      },
      {
        CITY: "CROSBY",
      },
      {
        CITY: "DALBY",
      },
      {
        CITY: "DOUGLAS",
      },
      {
        CITY: "FOXDALE",
      },
      {
        CITY: "LAXEY",
      },
      {
        CITY: "ONCHAN",
      },
      {
        CITY: "PEEL",
      },
      {
        CITY: "PORT ERIN",
      },
      {
        CITY: "PORT SAINT MARY",
      },
      {
        CITY: "RAMSEY",
      },
      {
        CITY: "SAINT JOHNS",
      },
    ],
  },
  {
    COUNTRY: "JAMAICA",
    CODE: "JM",
    CITIES: [
      {
        CITY: "BLACK RIVER",
      },
      {
        CITY: "BROWNS TOWN",
      },
      {
        CITY: "GORDON TOWN",
      },
      {
        CITY: "GREGORY PARK",
      },
      {
        CITY: "KINGSTON",
      },
      {
        CITY: "MANDEVILLE",
      },
      {
        CITY: "MAY PEN",
      },
      {
        CITY: "MONEAGUE",
      },
      {
        CITY: "MONTEGO BAY",
      },
      {
        CITY: "NEGRIL",
      },
      {
        CITY: "OCHO RIOS",
      },
      {
        CITY: "OLD HARBOUR",
      },
      {
        CITY: "PORT MARIA",
      },
      {
        CITY: "PORTLAND COTTAGE",
      },
      {
        CITY: "PORTMORE",
      },
      {
        CITY: "RUNAWAY BAY",
      },
      {
        CITY: "SPANISH TOWN",
      },
    ],
  },
  {
    COUNTRY: "IRAN",
    CODE: "IR",
    CITIES: [
      {
        CITY: "`ALIABAD",
      },
      {
        CITY: "`ALIABAD-E AQ HESAR",
      },
      {
        CITY: "`ORYAN",
      },
      {
        CITY: "ABADAN",
      },
      {
        CITY: "ABOL",
      },
      {
        CITY: "AHVAZ",
      },
      {
        CITY: "AMLASH",
      },
      {
        CITY: "AMOL",
      },
      {
        CITY: "ARAK",
      },
      {
        CITY: "ARDABIL",
      },
      {
        CITY: "ARDAKAN",
      },
      {
        CITY: "ARNAN",
      },
      {
        CITY: "ARSANJAN",
      },
      {
        CITY: "ASADABAD",
      },
      {
        CITY: "ASHAN",
      },
      {
        CITY: "ASHNA",
      },
      {
        CITY: "ASHTIAN",
      },
      {
        CITY: "ASTARA",
      },
      {
        CITY: "AZAD",
      },
      {
        CITY: "AZADI",
      },
      {
        CITY: "AZARAN",
      },
      {
        CITY: "BABOL",
      },
      {
        CITY: "BADR",
      },
      {
        CITY: "BAFT",
      },
      {
        CITY: "BAGHIN",
      },
      {
        CITY: "BAHARAN",
      },
      {
        CITY: "BAKHTIARI",
      },
      {
        CITY: "BAKHTIARUYEH",
      },
      {
        CITY: "BALADEH",
      },
      {
        CITY: "BANAK",
      },
      {
        CITY: "BAND",
      },
      {
        CITY: "BANDAR-E KHAMIR",
      },
      {
        CITY: "BANDAR-E MAHSHAHR",
      },
      {
        CITY: "BARBUN",
      },
      {
        CITY: "BARDSIR",
      },
      {
        CITY: "BASERI HADI",
      },
      {
        CITY: "BASTAK",
      },
      {
        CITY: "BEHBAHAN",
      },
      {
        CITY: "BEHDASHT",
      },
      {
        CITY: "BEHJAN",
      },
      {
        CITY: "BEHSHAHR",
      },
      {
        CITY: "BIDAK",
      },
      {
        CITY: "BIRIZG",
      },
      {
        CITY: "BIRJAND",
      },
      {
        CITY: "BONAB",
      },
      {
        CITY: "BORRAN-E BALA",
      },
      {
        CITY: "BOSTAN",
      },
      {
        CITY: "BUKAN",
      },
      {
        CITY: "CHABAHAR",
      },
      {
        CITY: "CHAH DERAZ",
      },
      {
        CITY: "CHAPAR",
      },
      {
        CITY: "DADEH",
      },
      {
        CITY: "DAMAVAND",
      },
      {
        CITY: "DAMGHAN",
      },
      {
        CITY: "DARMIAN",
      },
      {
        CITY: "DEZFUL",
      },
      {
        CITY: "DORUD",
      },
      {
        CITY: "EMAM",
      },
      {
        CITY: "ESFARAYEN",
      },
      {
        CITY: "ESLAMSHAHR",
      },
      {
        CITY: "ESTAHBAN",
      },
      {
        CITY: "EVIN",
      },
      {
        CITY: "FALAVARJAN",
      },
      {
        CITY: "FARDIS",
      },
      {
        CITY: "FARSI",
      },
      {
        CITY: "FASA",
      },
      {
        CITY: "FORDU",
      },
      {
        CITY: "GARMSAR",
      },
      {
        CITY: "GAZAN-E BALA",
      },
      {
        CITY: "GERASH",
      },
      {
        CITY: "GERMI",
      },
      {
        CITY: "GHARBI",
      },
      {
        CITY: "GILAN-E GHARB",
      },
      {
        CITY: "GILAS",
      },
      {
        CITY: "GOLESTAN",
      },
      {
        CITY: "GOLPAYEGAN",
      },
      {
        CITY: "GOLRIZ",
      },
      {
        CITY: "GORGAN",
      },
      {
        CITY: "GOSTAR",
      },
      {
        CITY: "HADISHAHR",
      },
      {
        CITY: "HAMADAN",
      },
      {
        CITY: "HASANABAD-E QADAMGAH",
      },
      {
        CITY: "HASHTGERD",
      },
      {
        CITY: "HASHTPAR",
      },
      {
        CITY: "HIRAD",
      },
      {
        CITY: "HOMA",
      },
      {
        CITY: "HORMOZABAD",
      },
      {
        CITY: "HUSH",
      },
      {
        CITY: "IDAH",
      },
      {
        CITY: "ILAM",
      },
      {
        CITY: "IRANSHAHR",
      },
      {
        CITY: "ISFAHAN",
      },
      {
        CITY: "JADIR",
      },
      {
        CITY: "JAHAN",
      },
      {
        CITY: "JAHAN NAMA",
      },
      {
        CITY: "JAHROM",
      },
      {
        CITY: "JIROFT",
      },
      {
        CITY: "JULFA",
      },
      {
        CITY: "KABIR",
      },
      {
        CITY: "KAMAN",
      },
      {
        CITY: "KARAJ",
      },
      {
        CITY: "KARIMKHAN",
      },
      {
        CITY: "KASHAN",
      },
      {
        CITY: "KELARABAD",
      },
      {
        CITY: "KERMAN",
      },
      {
        CITY: "KERMANSHAH",
      },
      {
        CITY: "KHALKHAL",
      },
      {
        CITY: "KHAMENEH",
      },
      {
        CITY: "KHASH",
      },
      {
        CITY: "KHOMEYN",
      },
      {
        CITY: "KHOMEYNI SHAHR",
      },
      {
        CITY: "KHONG",
      },
      {
        CITY: "KHORASAN",
      },
      {
        CITY: "KHOWRASGAN",
      },
      {
        CITY: "KHOWRSHID",
      },
      {
        CITY: "KHVAJEH",
      },
      {
        CITY: "KHVOY",
      },
      {
        CITY: "KIAN",
      },
      {
        CITY: "KISH",
      },
      {
        CITY: "KORD KANDI",
      },
      {
        CITY: "KORDESTAN-E `OLYA",
      },
      {
        CITY: "KORDOVAN-E SOFLA",
      },
      {
        CITY: "LAHIJAN",
      },
      {
        CITY: "LAMERD",
      },
      {
        CITY: "MAHABAD",
      },
      {
        CITY: "MAHALLAT",
      },
      {
        CITY: "MAHESTAN",
      },
      {
        CITY: "MALAYER",
      },
      {
        CITY: "MALEK TALESH",
      },
      {
        CITY: "MALEKAN",
      },
      {
        CITY: "MAMASANI",
      },
      {
        CITY: "MANJIL",
      },
      {
        CITY: "MARAGHEH",
      },
      {
        CITY: "MARAND",
      },
      {
        CITY: "MASHHAD",
      },
      {
        CITY: "MEHIN",
      },
      {
        CITY: "MEHREGAN",
      },
      {
        CITY: "MESHGIN SHAHR",
      },
      {
        CITY: "MEYBOD",
      },
      {
        CITY: "MIANDOAB",
      },
      {
        CITY: "MIANEJ",
      },
      {
        CITY: "MOBARAKEH",
      },
      {
        CITY: "MOGHAN",
      },
      {
        CITY: "MOHR",
      },
      {
        CITY: "NAHAVAND",
      },
      {
        CITY: "NAJAFABAD",
      },
      {
        CITY: "NAJI",
      },
      {
        CITY: "NASIR",
      },
      {
        CITY: "NEGAR",
      },
      {
        CITY: "NEHBANDAN",
      },
      {
        CITY: "NEKA",
      },
      {
        CITY: "NEYRIZ",
      },
      {
        CITY: "NEYSHABUR",
      },
      {
        CITY: "NOVIN",
      },
      {
        CITY: "ONAR",
      },
      {
        CITY: "PARPIS",
      },
      {
        CITY: "PARSIAN",
      },
      {
        CITY: "PASARGAD",
      },
      {
        CITY: "PAYAM",
      },
      {
        CITY: "PESHGOMAN",
      },
      {
        CITY: "PEYK",
      },
      {
        CITY: "PIRUZ",
      },
      {
        CITY: "POST",
      },
      {
        CITY: "QASR OD DASHT",
      },
      {
        CITY: "QAZVIN",
      },
      {
        CITY: "QESHM",
      },
      {
        CITY: "QUCHAN",
      },
      {
        CITY: "RABOR",
      },
      {
        CITY: "RAFSANJAN",
      },
      {
        CITY: "RASAK",
      },
      {
        CITY: "RASHT",
      },
      {
        CITY: "RAVAND",
      },
      {
        CITY: "RAVANSAR",
      },
      {
        CITY: "RAVAR",
      },
      {
        CITY: "RAYEN",
      },
      {
        CITY: "RAZAN",
      },
      {
        CITY: "RAZAVIYEH",
      },
      {
        CITY: "RAZI",
      },
      {
        CITY: "RIGAN",
      },
      {
        CITY: "SABZ",
      },
      {
        CITY: "SABZEVAR",
      },
      {
        CITY: "SAHAND",
      },
      {
        CITY: "SALMAS",
      },
      {
        CITY: "SAMAN",
      },
      {
        CITY: "SAMEN",
      },
      {
        CITY: "SANANDAJ",
      },
      {
        CITY: "SARAVAN",
      },
      {
        CITY: "SARI",
      },
      {
        CITY: "SAVEH",
      },
      {
        CITY: "SEMIROM",
      },
      {
        CITY: "SEMNAN",
      },
      {
        CITY: "SENA",
      },
      {
        CITY: "SERVAN",
      },
      {
        CITY: "SHADMAN",
      },
      {
        CITY: "SHAHID",
      },
      {
        CITY: "SHAHID CHAMRAN",
      },
      {
        CITY: "SHAHID MADANI",
      },
      {
        CITY: "SHAHR",
      },
      {
        CITY: "SHAHRAK",
      },
      {
        CITY: "SHAHRAK-E DIREH",
      },
      {
        CITY: "SHAHRAK-E MILAD",
      },
      {
        CITY: "SHAHRAK-E PARS",
      },
      {
        CITY: "SHAHREZA",
      },
      {
        CITY: "SHAHRIAR",
      },
      {
        CITY: "SHAHRUD",
      },
      {
        CITY: "SHARIF",
      },
      {
        CITY: "SHAZAND",
      },
      {
        CITY: "SHIRAZ",
      },
      {
        CITY: "SHIRVAN",
      },
      {
        CITY: "SIAHKAL",
      },
      {
        CITY: "SIRJAN",
      },
      {
        CITY: "SISTAN-E `OLYA",
      },
      {
        CITY: "SOLTANABAD",
      },
      {
        CITY: "TABRIZ",
      },
      {
        CITY: "TAHA",
      },
      {
        CITY: "TAKESTAN",
      },
      {
        CITY: "TIRAN",
      },
      {
        CITY: "TONEKABON",
      },
      {
        CITY: "TORKAN",
      },
      {
        CITY: "URMIA",
      },
      {
        CITY: "VAJ",
      },
      {
        CITY: "VALILU",
      },
      {
        CITY: "VANDAR",
      },
      {
        CITY: "VARAMIN",
      },
      {
        CITY: "WAHNA",
      },
      {
        CITY: "YASUJ",
      },
      {
        CITY: "YAZD",
      },
      {
        CITY: "ZABOL",
      },
      {
        CITY: "ZAHEDAN",
      },
      {
        CITY: "ZAHRA",
      },
      {
        CITY: "ZANGIABAD",
      },
      {
        CITY: "ZANJAN",
      },
      {
        CITY: "ZARAND",
      },
    ],
  },
  {
    COUNTRY: "IRELAND",
    CODE: "IE",
    CITIES: [
      {
        CITY: "ABBEYFEALE",
      },
      {
        CITY: "ABBEYLEIX",
      },
      {
        CITY: "ARDEE",
      },
      {
        CITY: "ARKLOW",
      },
      {
        CITY: "ARTANE",
      },
      {
        CITY: "ASHBOURNE",
      },
      {
        CITY: "ATHBOY",
      },
      {
        CITY: "ATHENRY",
      },
      {
        CITY: "ATHLONE",
      },
      {
        CITY: "ATHY",
      },
      {
        CITY: "BAGENALSTOWN",
      },
      {
        CITY: "BAILIEBOROUGH",
      },
      {
        CITY: "BALBRIGGAN",
      },
      {
        CITY: "BALDOYLE",
      },
      {
        CITY: "BALGRIFFIN",
      },
      {
        CITY: "BALLINA",
      },
      {
        CITY: "BALLINADEE",
      },
      {
        CITY: "BALLINASLOE",
      },
      {
        CITY: "BALLINCOLLIG",
      },
      {
        CITY: "BALLINEEN",
      },
      {
        CITY: "BALLINROBE",
      },
      {
        CITY: "BALLINTOBER",
      },
      {
        CITY: "BALLIVOR",
      },
      {
        CITY: "BALLON",
      },
      {
        CITY: "BALLSBRIDGE",
      },
      {
        CITY: "BALLYBRACK",
      },
      {
        CITY: "BALLYBRIT",
      },
      {
        CITY: "BALLYCULLEN",
      },
      {
        CITY: "BALLYFERMOT",
      },
      {
        CITY: "BALLYGARVAN",
      },
      {
        CITY: "BALLYHOOLY",
      },
      {
        CITY: "BALLYMAHON",
      },
      {
        CITY: "BALLYMOTE",
      },
      {
        CITY: "BALLYMOUNT",
      },
      {
        CITY: "BALLYMUN",
      },
      {
        CITY: "BALLYPHILIP",
      },
      {
        CITY: "BALLYRAGGET",
      },
      {
        CITY: "BALLYROE",
      },
      {
        CITY: "BALLYVAGHAN",
      },
      {
        CITY: "BANDON",
      },
      {
        CITY: "BANTRY",
      },
      {
        CITY: "BERRINGS",
      },
      {
        CITY: "BETTYSTOWN",
      },
      {
        CITY: "BIRR",
      },
      {
        CITY: "BLACKROCK",
      },
      {
        CITY: "BLANCHARDSTOWN",
      },
      {
        CITY: "BLESSINGTON",
      },
      {
        CITY: "BODYKE",
      },
      {
        CITY: "BOHERBUE",
      },
      {
        CITY: "BRAY",
      },
      {
        CITY: "BROADFORD",
      },
      {
        CITY: "BUNCRANA",
      },
      {
        CITY: "BUNDORAN",
      },
      {
        CITY: "CABINTEELY",
      },
      {
        CITY: "CABRA",
      },
      {
        CITY: "CAHER",
      },
      {
        CITY: "CAHERSIVEEN",
      },
      {
        CITY: "CALLAN",
      },
      {
        CITY: "CARBURY",
      },
      {
        CITY: "CARLOW",
      },
      {
        CITY: "CARNEW",
      },
      {
        CITY: "CARRAROE",
      },
      {
        CITY: "CARRICK",
      },
      {
        CITY: "CARRICK ON SHANNON",
      },
      {
        CITY: "CARRICK-ON-SUIR",
      },
      {
        CITY: "CARRICKMACROSS",
      },
      {
        CITY: "CARRICKMINES",
      },
      {
        CITY: "CARRIGALINE",
      },
      {
        CITY: "CARRIGTOHILL",
      },
      {
        CITY: "CASHEL",
      },
      {
        CITY: "CASTLEBAR",
      },
      {
        CITY: "CASTLEBLAYNEY",
      },
      {
        CITY: "CASTLEBRIDGE",
      },
      {
        CITY: "CASTLEKNOCK",
      },
      {
        CITY: "CASTLEMAINE",
      },
      {
        CITY: "CASTLEREA",
      },
      {
        CITY: "CAVAN",
      },
      {
        CITY: "CELBRIDGE",
      },
      {
        CITY: "CLANE",
      },
      {
        CITY: "CLARECASTLE",
      },
      {
        CITY: "CLAREGALWAY",
      },
      {
        CITY: "CLAREMORRIS",
      },
      {
        CITY: "CLOGHERHEAD",
      },
      {
        CITY: "CLONAKILTY",
      },
      {
        CITY: "CLONDALKIN",
      },
      {
        CITY: "CLONEE",
      },
      {
        CITY: "CLONES",
      },
      {
        CITY: "CLONMEL",
      },
      {
        CITY: "CLONSILLA",
      },
      {
        CITY: "CLONTARF",
      },
      {
        CITY: "COACHFORD",
      },
      {
        CITY: "COBH",
      },
      {
        CITY: "COOLOCK",
      },
      {
        CITY: "CORK",
      },
      {
        CITY: "COUNTY GALWAY",
      },
      {
        CITY: "COUNTY WEXFORD",
      },
      {
        CITY: "COURTOWN",
      },
      {
        CITY: "CROOKHAVEN",
      },
      {
        CITY: "CROSS",
      },
      {
        CITY: "CROSSHAVEN",
      },
      {
        CITY: "CRUMLIN",
      },
      {
        CITY: "CRUSHEEN",
      },
      {
        CITY: "DALKEY",
      },
      {
        CITY: "DELGANY",
      },
      {
        CITY: "DONABATE",
      },
      {
        CITY: "DONEGAL",
      },
      {
        CITY: "DONNYBROOK",
      },
      {
        CITY: "DOOLIN",
      },
      {
        CITY: "DOUGHISKA",
      },
      {
        CITY: "DOUGLAS",
      },
      {
        CITY: "DROGHEDA",
      },
      {
        CITY: "DROICHEAD NUA",
      },
      {
        CITY: "DUBLIN",
      },
      {
        CITY: "DULEEK",
      },
      {
        CITY: "DUNBOYNE",
      },
      {
        CITY: "DUNDALK",
      },
      {
        CITY: "DUNDRUM",
      },
      {
        CITY: "DUNDRUM",
      },
      {
        CITY: "DUNGARVAN",
      },
      {
        CITY: "DUNLAVIN",
      },
      {
        CITY: "DUNLEER",
      },
      {
        CITY: "DUNSHAUGHLIN",
      },
      {
        CITY: "EDENDERRY",
      },
      {
        CITY: "ENFIELD",
      },
      {
        CITY: "ENNIS",
      },
      {
        CITY: "ENNISCORTHY",
      },
      {
        CITY: "ENNISKERRY",
      },
      {
        CITY: "FEAKLE",
      },
      {
        CITY: "FERBANE",
      },
      {
        CITY: "FERMOY",
      },
      {
        CITY: "FINGLAS",
      },
      {
        CITY: "FIRHOUSE",
      },
      {
        CITY: "FOXFORD",
      },
      {
        CITY: "FOXROCK",
      },
      {
        CITY: "FOYNES",
      },
      {
        CITY: "GALWAY",
      },
      {
        CITY: "GARRISTOWN",
      },
      {
        CITY: "GEEVAGH",
      },
      {
        CITY: "GLANMIRE",
      },
      {
        CITY: "GLASNEVIN",
      },
      {
        CITY: "GLEN",
      },
      {
        CITY: "GLENEALY",
      },
      {
        CITY: "GLENGARRIFF",
      },
      {
        CITY: "GLENTIES",
      },
      {
        CITY: "GOREY",
      },
      {
        CITY: "GORT",
      },
      {
        CITY: "GRANGE",
      },
      {
        CITY: "GREYSTONES",
      },
      {
        CITY: "HEADFORD",
      },
      {
        CITY: "HOSPITAL",
      },
      {
        CITY: "INCHICORE",
      },
      {
        CITY: "IRISHTOWN",
      },
      {
        CITY: "ISLAND",
      },
      {
        CITY: "JAMESTOWN",
      },
      {
        CITY: "KANTURK",
      },
      {
        CITY: "KELLS",
      },
      {
        CITY: "KENMARE",
      },
      {
        CITY: "KILBRIDE CROSS ROADS",
      },
      {
        CITY: "KILCOCK",
      },
      {
        CITY: "KILCOOLE",
      },
      {
        CITY: "KILCULLEN",
      },
      {
        CITY: "KILDALKEY",
      },
      {
        CITY: "KILDARE",
      },
      {
        CITY: "KILFINANE",
      },
      {
        CITY: "KILKENNY",
      },
      {
        CITY: "KILLALA",
      },
      {
        CITY: "KILLALOE",
      },
      {
        CITY: "KILLARNEY",
      },
      {
        CITY: "KILLINEY",
      },
      {
        CITY: "KILLINICK",
      },
      {
        CITY: "KILLORGLIN",
      },
      {
        CITY: "KILLURIN",
      },
      {
        CITY: "KILLYBEGS",
      },
      {
        CITY: "KILLYGORDON",
      },
      {
        CITY: "KILMAINHAM",
      },
      {
        CITY: "KILMICHAEL",
      },
      {
        CITY: "KILMORE",
      },
      {
        CITY: "KILRUSH",
      },
      {
        CITY: "KILTAMAGH",
      },
      {
        CITY: "KINGSCOURT",
      },
      {
        CITY: "KINGSWOOD",
      },
      {
        CITY: "KINSALE",
      },
      {
        CITY: "KINVARRA",
      },
      {
        CITY: "LEAMLARA",
      },
      {
        CITY: "LEIXLIP",
      },
      {
        CITY: "LEOPARDSTOWN",
      },
      {
        CITY: "LETTERKENNY",
      },
      {
        CITY: "LIMERICK",
      },
      {
        CITY: "LISSELTON",
      },
      {
        CITY: "LISTOWEL",
      },
      {
        CITY: "LONGFORD",
      },
      {
        CITY: "LONGWOOD",
      },
      {
        CITY: "LOUGHREA",
      },
      {
        CITY: "LOUTH",
      },
      {
        CITY: "LUCAN",
      },
      {
        CITY: "LUSK",
      },
      {
        CITY: "MACROOM",
      },
      {
        CITY: "MALAHIDE",
      },
      {
        CITY: "MALLOW",
      },
      {
        CITY: "MANORHAMILTON",
      },
      {
        CITY: "MARINO",
      },
      {
        CITY: "MAYNOOTH",
      },
      {
        CITY: "MAYO",
      },
      {
        CITY: "MIDLETON",
      },
      {
        CITY: "MILLTOWN",
      },
      {
        CITY: "MITCHELSTOWN",
      },
      {
        CITY: "MONAGHAN",
      },
      {
        CITY: "MONASTEREVIN",
      },
      {
        CITY: "MONKSTOWN",
      },
      {
        CITY: "MORNINGTON",
      },
      {
        CITY: "MOUNT MERRION",
      },
      {
        CITY: "MOUNTRATH",
      },
      {
        CITY: "MOYCULLEN",
      },
      {
        CITY: "MULLINAVAT",
      },
      {
        CITY: "MULLINGAR",
      },
      {
        CITY: "NAAS",
      },
      {
        CITY: "NAUL",
      },
      {
        CITY: "NAVAN",
      },
      {
        CITY: "NENAGH",
      },
      {
        CITY: "NEW ROSS",
      },
      {
        CITY: "NEWCASTLE WEST",
      },
      {
        CITY: "NEWMARKET",
      },
      {
        CITY: "NEWPORT",
      },
      {
        CITY: "ORANMORE",
      },
      {
        CITY: "OUGHTERARD",
      },
      {
        CITY: "OYSTERHAVEN",
      },
      {
        CITY: "PASSAGE WEST",
      },
      {
        CITY: "PATRICKSWELL",
      },
      {
        CITY: "PORTARLINGTON",
      },
      {
        CITY: "PORTERSTOWN",
      },
      {
        CITY: "PORTMARNOCK",
      },
      {
        CITY: "PORTUMNA",
      },
      {
        CITY: "PROSPEROUS",
      },
      {
        CITY: "RAHENY",
      },
      {
        CITY: "RATHCOOLE",
      },
      {
        CITY: "RATHFARNHAM",
      },
      {
        CITY: "RATHGAR",
      },
      {
        CITY: "RATHMINES",
      },
      {
        CITY: "RATHMOLYON",
      },
      {
        CITY: "RATHOWEN",
      },
      {
        CITY: "RATOATH",
      },
      {
        CITY: "RINGASKIDDY",
      },
      {
        CITY: "RIVER",
      },
      {
        CITY: "ROSCOMMON",
      },
      {
        CITY: "ROSCREA",
      },
      {
        CITY: "RUSH",
      },
      {
        CITY: "SAGGART",
      },
      {
        CITY: "SAINT MULLINS",
      },
      {
        CITY: "SALLINS",
      },
      {
        CITY: "SALLYNOGGIN",
      },
      {
        CITY: "SANDYFORD",
      },
      {
        CITY: "SANDYMOUNT",
      },
      {
        CITY: "SANTRY",
      },
      {
        CITY: "SEAFIELD",
      },
      {
        CITY: "SHANKILL",
      },
      {
        CITY: "SHANNON",
      },
      {
        CITY: "SHRULE",
      },
      {
        CITY: "SIXMILEBRIDGE",
      },
      {
        CITY: "SKERRIES",
      },
      {
        CITY: "SKIBBEREEN",
      },
      {
        CITY: "SLIEVE",
      },
      {
        CITY: "SLIGO",
      },
      {
        CITY: "SPIDDAL",
      },
      {
        CITY: "STEPASIDE",
      },
      {
        CITY: "STILLORGAN",
      },
      {
        CITY: "STRADBALLY",
      },
      {
        CITY: "STRAFFAN",
      },
      {
        CITY: "SUMMERHILL",
      },
      {
        CITY: "SUTTON",
      },
      {
        CITY: "SWINFORD",
      },
      {
        CITY: "SWORDS",
      },
      {
        CITY: "TALLAGHT",
      },
      {
        CITY: "TEMPLEOGUE",
      },
      {
        CITY: "TERENURE",
      },
      {
        CITY: "THOMASTOWN",
      },
      {
        CITY: "THURLES",
      },
      {
        CITY: "TIPPERARY",
      },
      {
        CITY: "TRALEE",
      },
      {
        CITY: "TRIM",
      },
      {
        CITY: "TUAM",
      },
      {
        CITY: "TULLAMORE",
      },
      {
        CITY: "TULLOW",
      },
      {
        CITY: "TYRRELLSPASS",
      },
      {
        CITY: "VIRGINIA",
      },
      {
        CITY: "WALKINSTOWN",
      },
      {
        CITY: "WATERFORD",
      },
      {
        CITY: "WESTPORT",
      },
      {
        CITY: "WEXFORD",
      },
      {
        CITY: "WICKLOW",
      },
      {
        CITY: "WILLIAMSTOWN",
      },
      {
        CITY: "WINDY ARBOUR",
      },
      {
        CITY: "YOUGHAL",
      },
    ],
  },
  {
    COUNTRY: "KAZAKHSTAN",
    CODE: "KZ",
    CITIES: [
      {
        CITY: "AKSAY",
      },
      {
        CITY: "AKSORAN",
      },
      {
        CITY: "ALMATY",
      },
      {
        CITY: "AQTAS",
      },
      {
        CITY: "AQTAU",
      },
      {
        CITY: "ASTANA",
      },
      {
        CITY: "ATYRAU",
      },
      {
        CITY: "BAIKONUR",
      },
      {
        CITY: "DOSTYK",
      },
      {
        CITY: "DZHEZKAZGAN",
      },
      {
        CITY: "EKIBASTUZ",
      },
      {
        CITY: "ESIL",
      },
      {
        CITY: "KARAGANDY",
      },
      {
        CITY: "KARAGANDY",
      },
      {
        CITY: "KAZAKH",
      },
      {
        CITY: "KENTAU",
      },
      {
        CITY: "KOKTEM",
      },
      {
        CITY: "KOSTANAY",
      },
      {
        CITY: "KYZYL",
      },
      {
        CITY: "KYZYLORDA",
      },
      {
        CITY: "LOMONOSOVKA",
      },
      {
        CITY: "ORAL",
      },
      {
        CITY: "PAVLODAR",
      },
      {
        CITY: "PETROPAVL",
      },
      {
        CITY: "QARAGHANDY",
      },
      {
        CITY: "QASKELENG",
      },
      {
        CITY: "RIDDER",
      },
      {
        CITY: "RUDNYY",
      },
      {
        CITY: "SEMEY",
      },
      {
        CITY: "SEREBRYANSK",
      },
      {
        CITY: "SHYMKENT",
      },
      {
        CITY: "SONALY",
      },
      {
        CITY: "STEPNOGORSK",
      },
      {
        CITY: "TALDYK",
      },
      {
        CITY: "TALDYKORGAN",
      },
      {
        CITY: "TALGHAR",
      },
      {
        CITY: "TARAZ",
      },
      {
        CITY: "TEMIRTAU",
      },
      {
        CITY: "TURKESTAN",
      },
      {
        CITY: "URITSKIY",
      },
      {
        CITY: "UST-KAMENOGORSK",
      },
      {
        CITY: "VOSTOK",
      },
      {
        CITY: "ZYRYANOVSK",
      },
    ],
  },
  {
    COUNTRY: "LEBANON",
    CODE: "LB",
    CITIES: [
      {
        CITY: "AALEY",
      },
      {
        CITY: "ADMA",
      },
      {
        CITY: "ASHRAFIYE",
      },
      {
        CITY: "BAABDA",
      },
      {
        CITY: "BAALBEK",
      },
      {
        CITY: "BEIRUT",
      },
      {
        CITY: "BROUMMANA",
      },
      {
        CITY: "BSALIM",
      },
      {
        CITY: "CHEKKA",
      },
      {
        CITY: "DBAIYE",
      },
      {
        CITY: "DIK EL MEHDI",
      },
      {
        CITY: "HALBA",
      },
      {
        CITY: "HBOUB",
      },
      {
        CITY: "SARBA",
      },
      {
        CITY: "SIDON",
      },
      {
        CITY: "TRIPOLI",
      },
      {
        CITY: "YANAR",
      },
      {
        CITY: "ZGHARTA",
      },
    ],
  },
  {
    COUNTRY: "INDONESIA",
    CODE: "ID",
    CITIES: [
      {
        CITY: "ABADI",
      },
      {
        CITY: "ADIANTOROP",
      },
      {
        CITY: "AIRMADIDI",
      },
      {
        CITY: "AMBARAWA",
      },
      {
        CITY: "AMBON CITY",
      },
      {
        CITY: "AMLAPURA",
      },
      {
        CITY: "ANGGREK",
      },
      {
        CITY: "ANGKASA",
      },
      {
        CITY: "AREA",
      },
      {
        CITY: "BADUNG",
      },
      {
        CITY: "BADUNG",
      },
      {
        CITY: "BALI",
      },
      {
        CITY: "BALIGE",
      },
      {
        CITY: "BALIKPAPAN",
      },
      {
        CITY: "BANDA ACEH",
      },
      {
        CITY: "BANDAR",
      },
      {
        CITY: "BANDAR LAMPUNG",
      },
      {
        CITY: "BANDUNG",
      },
      {
        CITY: "BANGKALAN",
      },
      {
        CITY: "BANGKINANG",
      },
      {
        CITY: "BANGLI",
      },
      {
        CITY: "BANJAR",
      },
      {
        CITY: "BANJARBARU",
      },
      {
        CITY: "BANJARMASIN",
      },
      {
        CITY: "BANJARNEGARA",
      },
      {
        CITY: "BANTAENG",
      },
      {
        CITY: "BANTAN",
      },
      {
        CITY: "BANTUL",
      },
      {
        CITY: "BANYUMAS",
      },
      {
        CITY: "BANYUWANGI",
      },
      {
        CITY: "BARATJAYA",
      },
      {
        CITY: "BATANG",
      },
      {
        CITY: "BATU",
      },
      {
        CITY: "BATURAJA",
      },
      {
        CITY: "BAUBAU",
      },
      {
        CITY: "BEKASI",
      },
      {
        CITY: "BENGKALIS",
      },
      {
        CITY: "BENGKULU",
      },
      {
        CITY: "BIMA",
      },
      {
        CITY: "BINJAI",
      },
      {
        CITY: "BITUNG",
      },
      {
        CITY: "BLITAR",
      },
      {
        CITY: "BLORA",
      },
      {
        CITY: "BOGOR",
      },
      {
        CITY: "BOJONEGORO",
      },
      {
        CITY: "BONDOWOSO",
      },
      {
        CITY: "BONTANG",
      },
      {
        CITY: "BOYOLALI",
      },
      {
        CITY: "BUKIT TINGGI",
      },
      {
        CITY: "BULUKUMBA",
      },
      {
        CITY: "BUNTOK",
      },
      {
        CITY: "CAKRAWALA",
      },
      {
        CITY: "CEMPAKA",
      },
      {
        CITY: "CENGKARENG",
      },
      {
        CITY: "CIAMIS",
      },
      {
        CITY: "CIANJUR",
      },
      {
        CITY: "CIBITUNG",
      },
      {
        CITY: "CIBUBUR",
      },
      {
        CITY: "CIHAMPELAS",
      },
      {
        CITY: "CIKARANG",
      },
      {
        CITY: "CIKINI",
      },
      {
        CITY: "CILACAP",
      },
      {
        CITY: "CILEGON",
      },
      {
        CITY: "CILEGON",
      },
      {
        CITY: "CILINCING",
      },
      {
        CITY: "CIMAHI",
      },
      {
        CITY: "CIMANGGIS",
      },
      {
        CITY: "CIPINANGLATIHAN",
      },
      {
        CITY: "CIPUTAT",
      },
      {
        CITY: "CIREBON",
      },
      {
        CITY: "CITEUREUP",
      },
      {
        CITY: "DARMAGA",
      },
      {
        CITY: "DARUSSALAM",
      },
      {
        CITY: "DEMAK",
      },
      {
        CITY: "DENPASAR",
      },
      {
        CITY: "DEPOK",
      },
      {
        CITY: "DEPOK JAYA",
      },
      {
        CITY: "DUMAI",
      },
      {
        CITY: "DUREN",
      },
      {
        CITY: "DURI",
      },
      {
        CITY: "GANDUL",
      },
      {
        CITY: "GARUT",
      },
      {
        CITY: "GEDUNG",
      },
      {
        CITY: "GIANYAR",
      },
      {
        CITY: "GORONTALO",
      },
      {
        CITY: "GRESIK",
      },
      {
        CITY: "GUNTUNG",
      },
      {
        CITY: "GUNUNGSITOLI",
      },
      {
        CITY: "HOLIS",
      },
      {
        CITY: "INDO",
      },
      {
        CITY: "INDRAMAYU",
      },
      {
        CITY: "JAGAKARSA",
      },
      {
        CITY: "JAKARTA",
      },
      {
        CITY: "JAMBI CITY",
      },
      {
        CITY: "JAYAPURA",
      },
      {
        CITY: "JEMBER",
      },
      {
        CITY: "JEPARA",
      },
      {
        CITY: "JIMBARAN",
      },
      {
        CITY: "JOMBANG",
      },
      {
        CITY: "KABANJAHE",
      },
      {
        CITY: "KALIDERES",
      },
      {
        CITY: "KALIMANTAN",
      },
      {
        CITY: "KANDANGAN",
      },
      {
        CITY: "KARANGANYAR",
      },
      {
        CITY: "KARAWANG",
      },
      {
        CITY: "KARTASURA",
      },
      {
        CITY: "KEBAYORAN BARU",
      },
      {
        CITY: "KEBAYORAN LAMA SELATAN",
      },
      {
        CITY: "KEBUMEN",
      },
      {
        CITY: "KEDIRI",
      },
      {
        CITY: "KELAPA DUA",
      },
      {
        CITY: "KEMANG",
      },
      {
        CITY: "KENDAL",
      },
      {
        CITY: "KEPANJEN",
      },
      {
        CITY: "KERINCI",
      },
      {
        CITY: "KEROBOKAN",
      },
      {
        CITY: "KLATEN",
      },
      {
        CITY: "KOPENG",
      },
      {
        CITY: "KOTA",
      },
      {
        CITY: "KUDUS",
      },
      {
        CITY: "KULON",
      },
      {
        CITY: "KUNINGAN",
      },
      {
        CITY: "KUPANG",
      },
      {
        CITY: "KUTA",
      },
      {
        CITY: "LAMONGAN",
      },
      {
        CITY: "LAMPEONG",
      },
      {
        CITY: "LANGSA",
      },
      {
        CITY: "LAPAN",
      },
      {
        CITY: "LAWANG",
      },
      {
        CITY: "LESTARI",
      },
      {
        CITY: "LHOKSEUMAWE",
      },
      {
        CITY: "LUBUK PAKAM",
      },
      {
        CITY: "LUMAJANG",
      },
      {
        CITY: "MADIUN",
      },
      {
        CITY: "MAGELANG",
      },
      {
        CITY: "MAGETAN",
      },
      {
        CITY: "MAIL",
      },
      {
        CITY: "MAJALENGKA",
      },
      {
        CITY: "MAKASSAR",
      },
      {
        CITY: "MALANG",
      },
      {
        CITY: "MAMUJU",
      },
      {
        CITY: "MANADO",
      },
      {
        CITY: "MAROS",
      },
      {
        CITY: "MATARAM",
      },
      {
        CITY: "MEDAN",
      },
      {
        CITY: "MEGA",
      },
      {
        CITY: "MENARA",
      },
      {
        CITY: "MENTENG",
      },
      {
        CITY: "MOJOAGUNG",
      },
      {
        CITY: "MOJOKERTO",
      },
      {
        CITY: "MUNTILAN",
      },
      {
        CITY: "NEGARA",
      },
      {
        CITY: "NEGERIBESAR",
      },
      {
        CITY: "NGANJUK",
      },
      {
        CITY: "NGAWI",
      },
      {
        CITY: "NUSA",
      },
      {
        CITY: "NUSA DUA",
      },
      {
        CITY: "NUSANTARA",
      },
      {
        CITY: "PACITAN",
      },
      {
        CITY: "PADANG",
      },
      {
        CITY: "PALAIHARI",
      },
      {
        CITY: "PALANGKA",
      },
      {
        CITY: "PALANGKARAYA",
      },
      {
        CITY: "PALEMBANG",
      },
      {
        CITY: "PALU",
      },
      {
        CITY: "PAMEKASAN",
      },
      {
        CITY: "PAMULANG",
      },
      {
        CITY: "PANASUAN",
      },
      {
        CITY: "PANDEGLANG",
      },
      {
        CITY: "PANGATURAN",
      },
      {
        CITY: "PARAKAN",
      },
      {
        CITY: "PARE",
      },
      {
        CITY: "PARMAN",
      },
      {
        CITY: "PASURUAN",
      },
      {
        CITY: "PATAM",
      },
      {
        CITY: "PATI",
      },
      {
        CITY: "PAYAKUMBUH",
      },
      {
        CITY: "PEKALONGAN",
      },
      {
        CITY: "PEKAN",
      },
      {
        CITY: "PEKANBARU",
      },
      {
        CITY: "PEMALANG",
      },
      {
        CITY: "PEMATANGSIANTAR",
      },
      {
        CITY: "POLEREJO",
      },
      {
        CITY: "PONDOK",
      },
      {
        CITY: "PONOROGO",
      },
      {
        CITY: "PONTIANAK",
      },
      {
        CITY: "PORSEA",
      },
      {
        CITY: "POSO",
      },
      {
        CITY: "PROBOLINGGO",
      },
      {
        CITY: "PURBALINGGA",
      },
      {
        CITY: "PURWAKARTA",
      },
      {
        CITY: "PURWODADI GROBOGAN",
      },
      {
        CITY: "PURWOKERTO",
      },
      {
        CITY: "PURWOREJO",
      },
      {
        CITY: "PUSAT",
      },
      {
        CITY: "RIAU",
      },
      {
        CITY: "SALATIGA",
      },
      {
        CITY: "SAMARINDA",
      },
      {
        CITY: "SAMPANG",
      },
      {
        CITY: "SAMPIT",
      },
      {
        CITY: "SANGERENG",
      },
      {
        CITY: "SANUR",
      },
      {
        CITY: "SEJAHTERA",
      },
      {
        CITY: "SEKUPANG",
      },
      {
        CITY: "SELATAN",
      },
      {
        CITY: "SELONG",
      },
      {
        CITY: "SEMARANG",
      },
      {
        CITY: "SENGKANG",
      },
      {
        CITY: "SENTUL",
      },
      {
        CITY: "SERANG",
      },
      {
        CITY: "SERANG",
      },
      {
        CITY: "SERDANG",
      },
      {
        CITY: "SERPONG",
      },
      {
        CITY: "SIDOARJO",
      },
      {
        CITY: "SIGLI",
      },
      {
        CITY: "SIJUNJUNG",
      },
      {
        CITY: "SIMPANG",
      },
      {
        CITY: "SINGARAJA",
      },
      {
        CITY: "SINGKAWANG",
      },
      {
        CITY: "SITUBONDO",
      },
      {
        CITY: "SLEMAN",
      },
      {
        CITY: "SOASIO",
      },
      {
        CITY: "SOE",
      },
      {
        CITY: "SOHO",
      },
      {
        CITY: "SOLO",
      },
      {
        CITY: "SRAGEN",
      },
      {
        CITY: "STABAT",
      },
      {
        CITY: "SUBANG",
      },
      {
        CITY: "SUKABUMI",
      },
      {
        CITY: "SUKOHARJO",
      },
      {
        CITY: "SUMEDANG",
      },
      {
        CITY: "SUNGAILIAT",
      },
      {
        CITY: "SUNGGAL",
      },
      {
        CITY: "SUNGGUMINASA",
      },
      {
        CITY: "SURABAYA",
      },
      {
        CITY: "SURABAYAN",
      },
      {
        CITY: "SURAKARTA",
      },
      {
        CITY: "TABANAN",
      },
      {
        CITY: "TANGSEL",
      },
      {
        CITY: "TANJUNG",
      },
      {
        CITY: "TANJUNG",
      },
      {
        CITY: "TANJUNG BALAI",
      },
      {
        CITY: "TANJUNGPINANG",
      },
      {
        CITY: "TARAKAN",
      },
      {
        CITY: "TASIKMALAYA",
      },
      {
        CITY: "TEBINGTINGGI",
      },
      {
        CITY: "TEGAL",
      },
      {
        CITY: "TEMANGGUNG",
      },
      {
        CITY: "TEMBAGAPURA",
      },
      {
        CITY: "TENGAH",
      },
      {
        CITY: "TENGGARA",
      },
      {
        CITY: "TENGGARONG",
      },
      {
        CITY: "TIGARAKSA",
      },
      {
        CITY: "TIGARASA",
      },
      {
        CITY: "TIMUR",
      },
      {
        CITY: "TIPAR TIMUR",
      },
      {
        CITY: "TIRTAGANGGA",
      },
      {
        CITY: "TOMOHON",
      },
      {
        CITY: "TONDANO",
      },
      {
        CITY: "TRENGGALEK",
      },
      {
        CITY: "TUBAN",
      },
      {
        CITY: "TULUNGAGUNG",
      },
      {
        CITY: "UBUD",
      },
      {
        CITY: "UDAYANA",
      },
      {
        CITY: "UNGARAN",
      },
      {
        CITY: "UTAMA",
      },
      {
        CITY: "UTARA",
      },
      {
        CITY: "VETERAN",
      },
      {
        CITY: "WILAYAH",
      },
      {
        CITY: "WONOGIRI",
      },
      {
        CITY: "WONOSARI",
      },
      {
        CITY: "WONOSOBO",
      },
      {
        CITY: "YOGYAKARTA",
      },
    ],
  },
  {
    COUNTRY: "IRAQ",
    CODE: "IQ",
    CITIES: [
      {
        CITY: "AL `AMARAH",
      },
      {
        CITY: "AL HILLAH",
      },
      {
        CITY: "BAGHDAD",
      },
      {
        CITY: "BAHR",
      },
      {
        CITY: "BASERE",
      },
      {
        CITY: "BASRA",
      },
      {
        CITY: "ERBIL",
      },
      {
        CITY: "HAJI HASAN",
      },
      {
        CITY: "HAYAT",
      },
      {
        CITY: "KARKH",
      },
      {
        CITY: "KIRKUK",
      },
      {
        CITY: "MANAWI",
      },
      {
        CITY: "MOSUL",
      },
      {
        CITY: "NAJAF",
      },
      {
        CITY: "SULAYMANIYAH",
      },
      {
        CITY: "TIKRIT",
      },
    ],
  },
  {
    COUNTRY: "ITALY",
    CODE: "IT",
    CITIES: [
      {
        CITY: "ABANO TERME",
      },
      {
        CITY: "ABBADIA LARIANA",
      },
      {
        CITY: "ABBADIA SAN SALVATORE",
      },
      {
        CITY: "ABBASANTA",
      },
      {
        CITY: "ABBIATEGRASSO",
      },
      {
        CITY: "ABETONE",
      },
      {
        CITY: "ACATE",
      },
      {
        CITY: "ACERNO",
      },
      {
        CITY: "ACERRA",
      },
      {
        CITY: "ACI CASTELLO",
      },
      {
        CITY: "ACI CATENA",
      },
      {
        CITY: "ACIREALE",
      },
      {
        CITY: "ACQUAFREDDA",
      },
      {
        CITY: "ACQUAFREDDA INFERIORE",
      },
      {
        CITY: "ACQUAPENDENTE",
      },
      {
        CITY: "ACQUAPPESA",
      },
      {
        CITY: "ACQUARO",
      },
      {
        CITY: "ACQUASPARTA",
      },
      {
        CITY: "ACQUAVIVA",
      },
      {
        CITY: "ACQUAVIVA DELLE FONTI",
      },
      {
        CITY: "ACQUAVIVA PICENA",
      },
      {
        CITY: "ACQUI TERME",
      },
      {
        CITY: "ACRI",
      },
      {
        CITY: "ADELFIA",
      },
      {
        CITY: "ADRANO",
      },
      {
        CITY: "ADRIA",
      },
      {
        CITY: "ADRO",
      },
      {
        CITY: "AFFI",
      },
      {
        CITY: "AFRAGOLA",
      },
      {
        CITY: "AFRICO NUOVO",
      },
      {
        CITY: "AGAZZANO",
      },
      {
        CITY: "AGEROLA",
      },
      {
        CITY: "AGIRA",
      },
      {
        CITY: "AGLIANA",
      },
      {
        CITY: "AGLIANO",
      },
      {
        CITY: "AGLIENTU",
      },
      {
        CITY: "AGNA",
      },
      {
        CITY: "AGNADELLO",
      },
      {
        CITY: "AGNONE",
      },
      {
        CITY: "AGORDO",
      },
      {
        CITY: "AGRANO",
      },
      {
        CITY: "AGRATE BRIANZA",
      },
      {
        CITY: "AGRIGENTO",
      },
      {
        CITY: "AGRO",
      },
      {
        CITY: "AGROPOLI",
      },
      {
        CITY: "AGUGLIANO",
      },
      {
        CITY: "AGUGLIARO",
      },
      {
        CITY: "AI PALAZZI",
      },
      {
        CITY: "AIELLO",
      },
      {
        CITY: "AIELLO DEL SABATO",
      },
      {
        CITY: "AILANO",
      },
      {
        CITY: "AILOCHE",
      },
      {
        CITY: "AIRASCA",
      },
      {
        CITY: "AIROLA",
      },
      {
        CITY: "AIROLE",
      },
      {
        CITY: "AIRUNO",
      },
      {
        CITY: "ALA",
      },
      {
        CITY: "ALA DI STURA",
      },
      {
        CITY: "ALANNO",
      },
      {
        CITY: "ALASSIO",
      },
      {
        CITY: "ALATRI",
      },
      {
        CITY: "ALBA",
      },
      {
        CITY: "ALBANELLA",
      },
      {
        CITY: "ALBANO DI LUCANIA",
      },
      {
        CITY: "ALBANO LAZIALE",
      },
      {
        CITY: "ALBANO VERCELLESE",
      },
      {
        CITY: "ALBAREDO",
      },
      {
        CITY: "ALBARETO",
      },
      {
        CITY: "ALBENGA",
      },
      {
        CITY: "ALBERGO",
      },
      {
        CITY: "ALBEROBELLO",
      },
      {
        CITY: "ALBERONI",
      },
      {
        CITY: "ALBETTONE",
      },
      {
        CITY: "ALBIATE",
      },
      {
        CITY: "ALBIGNASEGO",
      },
      {
        CITY: "ALBINA",
      },
      {
        CITY: "ALBINEA",
      },
      {
        CITY: "ALBINO",
      },
      {
        CITY: "ALBISSOLA MARINA",
      },
      {
        CITY: "ALBIZZATE",
      },
      {
        CITY: "ALBUZZANO",
      },
      {
        CITY: "ALCAMO",
      },
      {
        CITY: "ALCARA LI FUSI",
      },
      {
        CITY: "ALDENO",
      },
      {
        CITY: "ALES",
      },
      {
        CITY: "ALESSANDRIA",
      },
      {
        CITY: "ALESSANDRIA DELLA ROCCA",
      },
      {
        CITY: "ALESSANO",
      },
      {
        CITY: "ALEZIO",
      },
      {
        CITY: "ALFONSINE",
      },
      {
        CITY: "ALGHERO",
      },
      {
        CITY: "ALGUND",
      },
      {
        CITY: "ALICE CASTELLO",
      },
      {
        CITY: "ALIFE",
      },
      {
        CITY: "ALIMENA",
      },
      {
        CITY: "ALLEGHE",
      },
      {
        CITY: "ALLERONA",
      },
      {
        CITY: "ALLISTE",
      },
      {
        CITY: "ALLUMIERE",
      },
      {
        CITY: "ALMENNO SAN BARTOLOMEO",
      },
      {
        CITY: "ALMESE",
      },
      {
        CITY: "ALPETTE",
      },
      {
        CITY: "ALPIGNANO",
      },
      {
        CITY: "ALTAMURA",
      },
      {
        CITY: "ALTARE",
      },
      {
        CITY: "ALTAVILLA",
      },
      {
        CITY: "ALTAVILLA IRPINA",
      },
      {
        CITY: "ALTAVILLA MILICIA",
      },
      {
        CITY: "ALTAVILLA SILENTINA",
      },
      {
        CITY: "ALTAVILLA VICENTINA",
      },
      {
        CITY: "ALTEDO",
      },
      {
        CITY: "ALTESSANO",
      },
      {
        CITY: "ALTIDONA",
      },
      {
        CITY: "ALTINO",
      },
      {
        CITY: "ALTOFONTE",
      },
      {
        CITY: "ALTOMONTE",
      },
      {
        CITY: "ALTOPASCIO",
      },
      {
        CITY: "ALVIANO",
      },
      {
        CITY: "ALVIGNANELLO",
      },
      {
        CITY: "ALVIGNANO",
      },
      {
        CITY: "ALVITO",
      },
      {
        CITY: "ALZANO LOMBARDO",
      },
      {
        CITY: "AMALFI",
      },
      {
        CITY: "AMANDOLA",
      },
      {
        CITY: "AMANTEA",
      },
      {
        CITY: "AMARO",
      },
      {
        CITY: "AMATO",
      },
      {
        CITY: "AMBRA",
      },
      {
        CITY: "AMBROGIO",
      },
      {
        CITY: "AMEGLIA",
      },
      {
        CITY: "AMELIA",
      },
      {
        CITY: "AMENO",
      },
      {
        CITY: "AMOROSI",
      },
      {
        CITY: "AMPEZZO",
      },
      {
        CITY: "ANACAPRI",
      },
      {
        CITY: "ANAGNI",
      },
      {
        CITY: "ANCARANO",
      },
      {
        CITY: "ANCONA",
      },
      {
        CITY: "ANDEZENO",
      },
      {
        CITY: "ANDORNO MICCA",
      },
      {
        CITY: "ANDRANO",
      },
      {
        CITY: "ANDRIA",
      },
      {
        CITY: "ANFO",
      },
      {
        CITY: "ANGERA",
      },
      {
        CITY: "ANGHIARI",
      },
      {
        CITY: "ANGOLO TERME",
      },
      {
        CITY: "ANGRI",
      },
      {
        CITY: "ANGUILLARA",
      },
      {
        CITY: "ANGUILLARA VENETA",
      },
      {
        CITY: "ANNA",
      },
      {
        CITY: "ANNICCO",
      },
      {
        CITY: "ANNONE VENETO",
      },
      {
        CITY: "ANTEA",
      },
      {
        CITY: "ANTELLA",
      },
      {
        CITY: "ANTERSELVA DI MEZZO",
      },
      {
        CITY: "ANTICO DI MAIOLO",
      },
      {
        CITY: "ANTIGNANO",
      },
      {
        CITY: "ANTONIMINA",
      },
      {
        CITY: "ANTRODOCO",
      },
      {
        CITY: "ANZANO DEL PARCO",
      },
      {
        CITY: "ANZIO",
      },
      {
        CITY: "AOSTA",
      },
      {
        CITY: "APICE",
      },
      {
        CITY: "APIRO",
      },
      {
        CITY: "APPIANO GENTILE",
      },
      {
        CITY: "APPIANO SULLA STRADA DEL VINO",
      },
      {
        CITY: "APPIGNANO",
      },
      {
        CITY: "APPIGNANO DEL TRONTO",
      },
      {
        CITY: "APRICENA",
      },
      {
        CITY: "APRILIA",
      },
      {
        CITY: "AQUILEIA",
      },
      {
        CITY: "AQUILONIA",
      },
      {
        CITY: "AQUINO",
      },
      {
        CITY: "ARABBA",
      },
      {
        CITY: "ARADEO",
      },
      {
        CITY: "ARAGONA",
      },
      {
        CITY: "ARBOREA",
      },
      {
        CITY: "ARBORIO",
      },
      {
        CITY: "ARBUS",
      },
      {
        CITY: "ARCADE",
      },
      {
        CITY: "ARCE",
      },
      {
        CITY: "ARCENE",
      },
      {
        CITY: "ARCEVIA",
      },
      {
        CITY: "ARCIDOSSO",
      },
      {
        CITY: "ARCO",
      },
      {
        CITY: "ARCOLA",
      },
      {
        CITY: "ARCOLE",
      },
      {
        CITY: "ARCONATE",
      },
      {
        CITY: "ARCORE",
      },
      {
        CITY: "ARCUGNANO",
      },
      {
        CITY: "ARDEA",
      },
      {
        CITY: "ARDESIO",
      },
      {
        CITY: "ARENZANO",
      },
      {
        CITY: "ARESE",
      },
      {
        CITY: "AREZZO",
      },
      {
        CITY: "ARGELATO",
      },
      {
        CITY: "ARGENTA",
      },
      {
        CITY: "ARIANO FERRARESE",
      },
      {
        CITY: "ARIANO IRPINO",
      },
      {
        CITY: "ARIANO NEL POLESINE",
      },
      {
        CITY: "ARICCIA",
      },
      {
        CITY: "ARIELLI",
      },
      {
        CITY: "ARIENZO",
      },
      {
        CITY: "ARIGNANO",
      },
      {
        CITY: "ARIZZANO",
      },
      {
        CITY: "ARLUNO",
      },
      {
        CITY: "ARMENO",
      },
      {
        CITY: "ARMENTO",
      },
      {
        CITY: "ARNESANO",
      },
      {
        CITY: "ARONA",
      },
      {
        CITY: "AROSIO",
      },
      {
        CITY: "ARPAIA",
      },
      {
        CITY: "ARPINO",
      },
      {
        CITY: "ARQUA POLESINE",
      },
      {
        CITY: "ARQUATA SCRIVIA",
      },
      {
        CITY: "ARRE",
      },
      {
        CITY: "ARRONE",
      },
      {
        CITY: "ARSAGO SEPRIO",
      },
      {
        CITY: "ARSITA",
      },
      {
        CITY: "ARTA TERME",
      },
      {
        CITY: "ARTENA",
      },
      {
        CITY: "ARTOGNE",
      },
      {
        CITY: "ARZACHENA",
      },
      {
        CITY: "ARZANO",
      },
      {
        CITY: "ARZENE",
      },
      {
        CITY: "ARZIGNANO",
      },
      {
        CITY: "ASCIANO",
      },
      {
        CITY: "ASCOLI PICENO",
      },
      {
        CITY: "ASCOLI SATRIANO",
      },
      {
        CITY: "ASIAGO",
      },
      {
        CITY: "ASOLA",
      },
      {
        CITY: "ASOLO",
      },
      {
        CITY: "ASSEMINI",
      },
      {
        CITY: "ASSISI",
      },
      {
        CITY: "ASSO",
      },
      {
        CITY: "ASSORO",
      },
      {
        CITY: "ASTI",
      },
      {
        CITY: "ATELLA",
      },
      {
        CITY: "ATENA LUCANA",
      },
      {
        CITY: "ATESSA",
      },
      {
        CITY: "ATINA",
      },
      {
        CITY: "ATRI",
      },
      {
        CITY: "ATRIPALDA",
      },
      {
        CITY: "ATTIGLIANO",
      },
      {
        CITY: "ATTIMIS",
      },
      {
        CITY: "ATZARA",
      },
      {
        CITY: "AUGUSTA",
      },
      {
        CITY: "AULETTA",
      },
      {
        CITY: "AULLA",
      },
      {
        CITY: "AURELIA",
      },
      {
        CITY: "AURONZO DI CADORE",
      },
      {
        CITY: "AVELLA",
      },
      {
        CITY: "AVELLINO",
      },
      {
        CITY: "AVERSA",
      },
      {
        CITY: "AVEZZANO",
      },
      {
        CITY: "AVIANO",
      },
      {
        CITY: "AVIGLIANA",
      },
      {
        CITY: "AVIGLIANO",
      },
      {
        CITY: "AVIGLIANO UMBRO",
      },
      {
        CITY: "AVIO",
      },
      {
        CITY: "AVOLA",
      },
      {
        CITY: "AZEGLIO",
      },
      {
        CITY: "AZZANO",
      },
      {
        CITY: "AZZANO DECIMO",
      },
      {
        CITY: "AZZANO SAN PAOLO",
      },
      {
        CITY: "AZZATE",
      },
      {
        CITY: "BACOLI",
      },
      {
        CITY: "BADIA",
      },
      {
        CITY: "BADIA",
      },
      {
        CITY: "BADIA CALAVENA",
      },
      {
        CITY: "BADIA POLESINE",
      },
      {
        CITY: "BADOLATO",
      },
      {
        CITY: "BAGHERIA",
      },
      {
        CITY: "BAGLIO RIZZO",
      },
      {
        CITY: "BAGNACAVALLO",
      },
      {
        CITY: "BAGNARA",
      },
      {
        CITY: "BAGNARA CALABRA",
      },
      {
        CITY: "BAGNARA DI ROMAGNA",
      },
      {
        CITY: "BAGNASCO",
      },
      {
        CITY: "BAGNI",
      },
      {
        CITY: "BAGNI DI LUCCA",
      },
      {
        CITY: "BAGNO A RIPOLI",
      },
      {
        CITY: "BAGNO DI ROMAGNA",
      },
      {
        CITY: "BAGNOLI DEL TRIGNO",
      },
      {
        CITY: "BAGNOLI DI SOPRA",
      },
      {
        CITY: "BAGNOLI IRPINO",
      },
      {
        CITY: "BAGNOLO",
      },
      {
        CITY: "BAGNOLO CREMASCO",
      },
      {
        CITY: "BAGNOLO IN PIANO",
      },
      {
        CITY: "BAGNOLO MELLA",
      },
      {
        CITY: "BAGNOLO PIEMONTE",
      },
      {
        CITY: "BAGNOLO SAN VITO",
      },
      {
        CITY: "BAIA",
      },
      {
        CITY: "BAIANO",
      },
      {
        CITY: "BAISO",
      },
      {
        CITY: "BALANGERO",
      },
      {
        CITY: "BALDISSERO TORINESE",
      },
      {
        CITY: "BALESTRATE-FOCE",
      },
      {
        CITY: "BALLABIO",
      },
      {
        CITY: "BALUELLO",
      },
      {
        CITY: "BALVANO",
      },
      {
        CITY: "BALZOLA",
      },
      {
        CITY: "BARAGIANO",
      },
      {
        CITY: "BARANZATE",
      },
      {
        CITY: "BARATTE",
      },
      {
        CITY: "BARBARANO VICENTINO",
      },
      {
        CITY: "BARBARASCO",
      },
      {
        CITY: "BARBERINO DI MUGELLO",
      },
      {
        CITY: "BARBIANO",
      },
      {
        CITY: "BARDOLINO",
      },
      {
        CITY: "BARDONECCHIA",
      },
      {
        CITY: "BAREGGIO",
      },
      {
        CITY: "BARGA",
      },
      {
        CITY: "BARGAGLI",
      },
      {
        CITY: "BARGE",
      },
      {
        CITY: "BARI",
      },
      {
        CITY: "BARI SARDO",
      },
      {
        CITY: "BARICELLA",
      },
      {
        CITY: "BARILE",
      },
      {
        CITY: "BARISCIANO",
      },
      {
        CITY: "BARLASSINA",
      },
      {
        CITY: "BARLETTA",
      },
      {
        CITY: "BARNI",
      },
      {
        CITY: "BARONISSI",
      },
      {
        CITY: "BARRA",
      },
      {
        CITY: "BARRAFRANCA",
      },
      {
        CITY: "BARRALI",
      },
      {
        CITY: "BARUCHELLA",
      },
      {
        CITY: "BARUMINI",
      },
      {
        CITY: "BARZANA",
      },
      {
        CITY: "BASALDELLA",
      },
      {
        CITY: "BASCHI",
      },
      {
        CITY: "BASCIANO",
      },
      {
        CITY: "BASELICE",
      },
      {
        CITY: "BASILIANO",
      },
      {
        CITY: "BASILICANOVA",
      },
      {
        CITY: "BASSANO BRESCIANO",
      },
      {
        CITY: "BASSANO DEL GRAPPA",
      },
      {
        CITY: "BASSANO IN TEVERINA",
      },
      {
        CITY: "BASSANO ROMANO",
      },
      {
        CITY: "BASTIA UMBRA",
      },
      {
        CITY: "BASTIGLIA",
      },
      {
        CITY: "BATTAGLIA TERME",
      },
      {
        CITY: "BATTIPAGLIA",
      },
      {
        CITY: "BAUNEI",
      },
      {
        CITY: "BAVENO",
      },
      {
        CITY: "BAZZANO",
      },
      {
        CITY: "BEDOLLO",
      },
      {
        CITY: "BEGLIANO",
      },
      {
        CITY: "BEINASCO",
      },
      {
        CITY: "BEINETTE",
      },
      {
        CITY: "BELFIORE",
      },
      {
        CITY: "BELFORTE",
      },
      {
        CITY: "BELGIOIOSO",
      },
      {
        CITY: "BELLA",
      },
      {
        CITY: "BELLAGIO",
      },
      {
        CITY: "BELLANO",
      },
      {
        CITY: "BELLANTE",
      },
      {
        CITY: "BELLARIA-IGEA MARINA",
      },
      {
        CITY: "BELLARIVA",
      },
      {
        CITY: "BELLINZAGO NOVARESE",
      },
      {
        CITY: "BELLOCCHI",
      },
      {
        CITY: "BELLONA",
      },
      {
        CITY: "BELLUNO",
      },
      {
        CITY: "BELMONTE MEZZAGNO",
      },
      {
        CITY: "BELMONTE PICENO",
      },
      {
        CITY: "BELPASSO",
      },
      {
        CITY: "BELSITO",
      },
      {
        CITY: "BELVEDERE",
      },
      {
        CITY: "BELVEDERE DI SPINELLO",
      },
      {
        CITY: "BELVEDERE MARITTIMO",
      },
      {
        CITY: "BELVEDERE OSTRENSE",
      },
      {
        CITY: "BENESTARE",
      },
      {
        CITY: "BENETUTTI",
      },
      {
        CITY: "BENEVELLO",
      },
      {
        CITY: "BENEVENTO",
      },
      {
        CITY: "BENNA",
      },
      {
        CITY: "BENTIVOGLIO",
      },
      {
        CITY: "BERBENNO DI VALTELLINA",
      },
      {
        CITY: "BERCETO",
      },
      {
        CITY: "BERGAMO",
      },
      {
        CITY: "BERLINGO",
      },
      {
        CITY: "BERNALDA",
      },
      {
        CITY: "BERNATE TICINO",
      },
      {
        CITY: "BERTINORO",
      },
      {
        CITY: "BERTONICO",
      },
      {
        CITY: "BERZO DEMO",
      },
      {
        CITY: "BESANA IN BRIANZA",
      },
      {
        CITY: "BESANO",
      },
      {
        CITY: "BESNATE",
      },
      {
        CITY: "BESOZZO",
      },
      {
        CITY: "BETTOLA",
      },
      {
        CITY: "BETTOLLE",
      },
      {
        CITY: "BETTONA",
      },
      {
        CITY: "BEURA-CARDEZZA",
      },
      {
        CITY: "BEVILACQUA",
      },
      {
        CITY: "BIANCADE",
      },
      {
        CITY: "BIANCANO",
      },
      {
        CITY: "BIANCAVILLA",
      },
      {
        CITY: "BIANCO",
      },
      {
        CITY: "BIANCONESE",
      },
      {
        CITY: "BIANDRONNO",
      },
      {
        CITY: "BIANZONE",
      },
      {
        CITY: "BIASSONO",
      },
      {
        CITY: "BIBBIANO",
      },
      {
        CITY: "BIBBIENA",
      },
      {
        CITY: "BIBBONA",
      },
      {
        CITY: "BIBIONE",
      },
      {
        CITY: "BIELLA",
      },
      {
        CITY: "BIENNO",
      },
      {
        CITY: "BIENTINA",
      },
      {
        CITY: "BINANUOVA",
      },
      {
        CITY: "BINASCO",
      },
      {
        CITY: "BISACCIA",
      },
      {
        CITY: "BISACQUINO",
      },
      {
        CITY: "BISCEGLIE",
      },
      {
        CITY: "BITETTO",
      },
      {
        CITY: "BITONTO",
      },
      {
        CITY: "BITRITTO",
      },
      {
        CITY: "BITTI",
      },
      {
        CITY: "BIVONGI",
      },
      {
        CITY: "BLERA",
      },
      {
        CITY: "BOARA",
      },
      {
        CITY: "BOBBIO",
      },
      {
        CITY: "BOCCHEGGIANO",
      },
      {
        CITY: "BOCCHIGNANO",
      },
      {
        CITY: "BOFFALORA SOPRA TICINO",
      },
      {
        CITY: "BOGLIASCO",
      },
      {
        CITY: "BOJANO",
      },
      {
        CITY: "BOLANO",
      },
      {
        CITY: "BOLLATE",
      },
      {
        CITY: "BOLLENGO",
      },
      {
        CITY: "BOLOGNA",
      },
      {
        CITY: "BOLZANO",
      },
      {
        CITY: "BOLZANO",
      },
      {
        CITY: "BOLZANO VICENTINO",
      },
      {
        CITY: "BOMPIETRO",
      },
      {
        CITY: "BOMPORTO",
      },
      {
        CITY: "BONARCADO",
      },
      {
        CITY: "BONASSOLA",
      },
      {
        CITY: "BONAVICINA",
      },
      {
        CITY: "BONDENO",
      },
      {
        CITY: "BONEA",
      },
      {
        CITY: "BONORVA",
      },
      {
        CITY: "BORDIGHERA",
      },
      {
        CITY: "BORETTO",
      },
      {
        CITY: "BORGARO TORINESE",
      },
      {
        CITY: "BORGETTO",
      },
      {
        CITY: "BORGHETTO",
      },
      {
        CITY: "BORGHETTO DI VARA",
      },
      {
        CITY: "BORGHETTO SANTO SPIRITO",
      },
      {
        CITY: "BORGIA",
      },
      {
        CITY: "BORGO",
      },
      {
        CITY: "BORGO",
      },
      {
        CITY: "BORGO A MOZZANO",
      },
      {
        CITY: "BORGO DI TERZO",
      },
      {
        CITY: "BORGO PRIOLO",
      },
      {
        CITY: "BORGO SABOTINO-FOCE VERDE",
      },
      {
        CITY: "BORGO SAN DALMAZZO",
      },
      {
        CITY: "BORGO SAN GIACOMO",
      },
      {
        CITY: "BORGO SAN LORENZO",
      },
      {
        CITY: "BORGO TICINO",
      },
      {
        CITY: "BORGO TOSSIGNANO",
      },
      {
        CITY: "BORGO VAL DI TARO",
      },
      {
        CITY: "BORGO VERCELLI",
      },
      {
        CITY: "BORGOMANERO",
      },
      {
        CITY: "BORGONE",
      },
      {
        CITY: "BORGONOVO",
      },
      {
        CITY: "BORGONOVO VAL TIDONE",
      },
      {
        CITY: "BORGORICCO",
      },
      {
        CITY: "BORGOROSE",
      },
      {
        CITY: "BORGOSATOLLO",
      },
      {
        CITY: "BORGOSESIA",
      },
      {
        CITY: "BORNASCO",
      },
      {
        CITY: "BORNO",
      },
      {
        CITY: "BORRELLO",
      },
      {
        CITY: "BORZONASCA",
      },
      {
        CITY: "BOSA",
      },
      {
        CITY: "BOSCHETTO",
      },
      {
        CITY: "BOSCO",
      },
      {
        CITY: "BOSCO",
      },
      {
        CITY: "BOSCO CHIESANUOVA",
      },
      {
        CITY: "BOSCONERO",
      },
      {
        CITY: "BOSCOREALE",
      },
      {
        CITY: "BOSCOTRECASE",
      },
      {
        CITY: "BOSISIO PARINI",
      },
      {
        CITY: "BOSSOLASCO",
      },
      {
        CITY: "BOTTANUCO",
      },
      {
        CITY: "BOVA MARINA",
      },
      {
        CITY: "BOVALINO",
      },
      {
        CITY: "BOVEGNO",
      },
      {
        CITY: "BOVES",
      },
      {
        CITY: "BOVEZZO",
      },
      {
        CITY: "BOVILLE ERNICA",
      },
      {
        CITY: "BOVINO",
      },
      {
        CITY: "BOVISIO-MASCIAGO",
      },
      {
        CITY: "BOVOLENTA",
      },
      {
        CITY: "BOVOLONE",
      },
      {
        CITY: "BOZZOLO",
      },
      {
        CITY: "BRA",
      },
      {
        CITY: "BRACCAGNI",
      },
      {
        CITY: "BRACCIANO",
      },
      {
        CITY: "BRACIGLIANO",
      },
      {
        CITY: "BRANCALEONE",
      },
      {
        CITY: "BRANDIZZO",
      },
      {
        CITY: "BRAONE",
      },
      {
        CITY: "BREBBIA",
      },
      {
        CITY: "BREDA DI PIAVE",
      },
      {
        CITY: "BREGANZE",
      },
      {
        CITY: "BREGUZZO",
      },
      {
        CITY: "BREMBATE",
      },
      {
        CITY: "BREMBILLA",
      },
      {
        CITY: "BRENDOLA",
      },
      {
        CITY: "BRENNER",
      },
      {
        CITY: "BRENO",
      },
      {
        CITY: "BRESCELLO",
      },
      {
        CITY: "BRESCIA",
      },
      {
        CITY: "BRESSANA BOTTARONE",
      },
      {
        CITY: "BRESSANVIDO",
      },
      {
        CITY: "BRESSO",
      },
      {
        CITY: "BRIATICO",
      },
      {
        CITY: "BRICHERASIO",
      },
      {
        CITY: "BRIGA NOVARESE",
      },
      {
        CITY: "BRIGNANO",
      },
      {
        CITY: "BRINDISI",
      },
      {
        CITY: "BRISSAGO-VALTRAVAGLIA",
      },
      {
        CITY: "BRIVIO",
      },
      {
        CITY: "BRIXEN",
      },
      {
        CITY: "BROLO",
      },
      {
        CITY: "BRONI",
      },
      {
        CITY: "BRONTE",
      },
      {
        CITY: "BRONZOLO",
      },
      {
        CITY: "BROSSASCO",
      },
      {
        CITY: "BROSSO",
      },
      {
        CITY: "BRUGHERIO",
      },
      {
        CITY: "BRUGINE",
      },
      {
        CITY: "BRUGNERA",
      },
      {
        CITY: "BRUINO",
      },
      {
        CITY: "BRUNATE",
      },
      {
        CITY: "BRUNECK",
      },
      {
        CITY: "BRUNELLA",
      },
      {
        CITY: "BRUNO",
      },
      {
        CITY: "BRUSCIANO",
      },
      {
        CITY: "BRUSNENGO",
      },
      {
        CITY: "BUBBIO",
      },
      {
        CITY: "BUCCHIANICO",
      },
      {
        CITY: "BUCCIANO",
      },
      {
        CITY: "BUCCINO",
      },
      {
        CITY: "BUCINE",
      },
      {
        CITY: "BUDRIO",
      },
      {
        CITY: "BUGGIANO",
      },
      {
        CITY: "BUIA",
      },
      {
        CITY: "BUONABITACOLO",
      },
      {
        CITY: "BURANO",
      },
      {
        CITY: "BURIASCO",
      },
      {
        CITY: "BUROLO",
      },
      {
        CITY: "BURONZO",
      },
      {
        CITY: "BUSALLA",
      },
      {
        CITY: "BUSCA",
      },
      {
        CITY: "BUSCATE",
      },
      {
        CITY: "BUSCOLDO",
      },
      {
        CITY: "BUSSETO",
      },
      {
        CITY: "BUSSI SUL TIRINO",
      },
      {
        CITY: "BUSSOLENGO",
      },
      {
        CITY: "BUSSOLENO",
      },
      {
        CITY: "BUSTO ARSIZIO",
      },
      {
        CITY: "BUSTO GAROLFO",
      },
      {
        CITY: "BUTERA",
      },
      {
        CITY: "BUTI",
      },
      {
        CITY: "BUTTAPIETRA",
      },
      {
        CITY: "BUTTIGLIERA ALTA",
      },
      {
        CITY: "BUTTRIO",
      },
      {
        CITY: "CABRAS",
      },
      {
        CITY: "CACCAMO",
      },
      {
        CITY: "CACCURI",
      },
      {
        CITY: "CADELBOSCO DI SOPRA",
      },
      {
        CITY: "CADEO",
      },
      {
        CITY: "CADONEGHE",
      },
      {
        CITY: "CAERANO DI SAN MARCO",
      },
      {
        CITY: "CAGGIANO",
      },
      {
        CITY: "CAGLI",
      },
      {
        CITY: "CAGLIARI",
      },
      {
        CITY: "CAGLIARI",
      },
      {
        CITY: "CAGNANO VARANO",
      },
      {
        CITY: "CAIANELLO",
      },
      {
        CITY: "CAIATIA",
      },
      {
        CITY: "CAIRATE",
      },
      {
        CITY: "CAIRO MONTENOTTE",
      },
      {
        CITY: "CAIVANO",
      },
      {
        CITY: "CALALZO DI CADORE",
      },
      {
        CITY: "CALAMANDRANA",
      },
      {
        CITY: "CALANGIANUS",
      },
      {
        CITY: "CALASCIBETTA",
      },
      {
        CITY: "CALATABIANO",
      },
      {
        CITY: "CALATAFIMI",
      },
      {
        CITY: "CALCARA",
      },
      {
        CITY: "CALCATA",
      },
      {
        CITY: "CALCI",
      },
      {
        CITY: "CALCINAIA",
      },
      {
        CITY: "CALCINATE",
      },
      {
        CITY: "CALCINATO",
      },
      {
        CITY: "CALCINELLI",
      },
      {
        CITY: "CALCIO",
      },
      {
        CITY: "CALDARO SULLA STRADA DEL VINO",
      },
      {
        CITY: "CALDAROLA",
      },
      {
        CITY: "CALDERARA DI RENO",
      },
      {
        CITY: "CALDOGNO",
      },
      {
        CITY: "CALDONAZZO",
      },
      {
        CITY: "CALENDASCO",
      },
      {
        CITY: "CALENZANO",
      },
      {
        CITY: "CALICE AL CORNOVIGLIO",
      },
      {
        CITY: "CALIMERA",
      },
      {
        CITY: "CALITRI",
      },
      {
        CITY: "CALIZZANO",
      },
      {
        CITY: "CALLIANO",
      },
      {
        CITY: "CALOLZIOCORTE",
      },
      {
        CITY: "CALTAGIRONE",
      },
      {
        CITY: "CALTANISSETTA",
      },
      {
        CITY: "CALTAVUTURO",
      },
      {
        CITY: "CALUSO",
      },
      {
        CITY: "CALVANICO",
      },
      {
        CITY: "CALVATONE",
      },
      {
        CITY: "CALVENZANO",
      },
      {
        CITY: "CALVISANO",
      },
      {
        CITY: "CALVIZZANO",
      },
      {
        CITY: "CAMAIORE",
      },
      {
        CITY: "CAMBIAGO",
      },
      {
        CITY: "CAMBIANO",
      },
      {
        CITY: "CAMBURZANO",
      },
      {
        CITY: "CAMERANO",
      },
      {
        CITY: "CAMERATA PICENA",
      },
      {
        CITY: "CAMERI",
      },
      {
        CITY: "CAMERIANO",
      },
      {
        CITY: "CAMERINO",
      },
      {
        CITY: "CAMERLONA",
      },
      {
        CITY: "CAMINETTO",
      },
      {
        CITY: "CAMINO",
      },
      {
        CITY: "CAMINO",
      },
      {
        CITY: "CAMISA",
      },
      {
        CITY: "CAMISANO",
      },
      {
        CITY: "CAMISANO VICENTINO",
      },
      {
        CITY: "CAMOGLI",
      },
      {
        CITY: "CAMPAGNA",
      },
      {
        CITY: "CAMPAGNANO DI ROMA",
      },
      {
        CITY: "CAMPAGNOLA",
      },
      {
        CITY: "CAMPAGNOLA EMILIA",
      },
      {
        CITY: "CAMPARADA",
      },
      {
        CITY: "CAMPEGINE",
      },
      {
        CITY: "CAMPERTOGNO",
      },
      {
        CITY: "CAMPI",
      },
      {
        CITY: "CAMPI BISENZIO",
      },
      {
        CITY: "CAMPI SALENTINA",
      },
      {
        CITY: "CAMPIGLIA MARITTIMA",
      },
      {
        CITY: "CAMPIGLIO",
      },
      {
        CITY: "CAMPIONE",
      },
      {
        CITY: "CAMPLI",
      },
      {
        CITY: "CAMPO",
      },
      {
        CITY: "CAMPO LIGURE",
      },
      {
        CITY: "CAMPO SAN MARTINO",
      },
      {
        CITY: "CAMPO TIZZORO",
      },
      {
        CITY: "CAMPOBASSO",
      },
      {
        CITY: "CAMPOBELLO DI LICATA",
      },
      {
        CITY: "CAMPOBELLO DI MAZARA",
      },
      {
        CITY: "CAMPODARSEGO",
      },
      {
        CITY: "CAMPODENNO",
      },
      {
        CITY: "CAMPODIMELE",
      },
      {
        CITY: "CAMPODIPIETRA",
      },
      {
        CITY: "CAMPODORO",
      },
      {
        CITY: "CAMPOFELICE DI ROCCELLA",
      },
      {
        CITY: "CAMPOFILONE",
      },
      {
        CITY: "CAMPOFORMIDO",
      },
      {
        CITY: "CAMPOFRANCO",
      },
      {
        CITY: "CAMPOGALLIANO",
      },
      {
        CITY: "CAMPOLEONE",
      },
      {
        CITY: "CAMPOLI APPENNINO",
      },
      {
        CITY: "CAMPOLIETO",
      },
      {
        CITY: "CAMPOLONGO MAGGIORE",
      },
      {
        CITY: "CAMPOMARINO",
      },
      {
        CITY: "CAMPOMORONE",
      },
      {
        CITY: "CAMPONOGARA",
      },
      {
        CITY: "CAMPORA",
      },
      {
        CITY: "CAMPOREALE",
      },
      {
        CITY: "CAMPOROSSO",
      },
      {
        CITY: "CAMPOSAMPIERO",
      },
      {
        CITY: "CAMPOSANO",
      },
      {
        CITY: "CANALE",
      },
      {
        CITY: "CANARO",
      },
      {
        CITY: "CANAZEI",
      },
      {
        CITY: "CANCELLO",
      },
      {
        CITY: "CANCELLO ED ARNONE",
      },
      {
        CITY: "CANDELO",
      },
      {
        CITY: "CANDIA CANAVESE",
      },
      {
        CITY: "CANDIA LOMELLINA",
      },
      {
        CITY: "CANDIANA",
      },
      {
        CITY: "CANDIOLO",
      },
      {
        CITY: "CANEGRATE",
      },
      {
        CITY: "CANELLI",
      },
      {
        CITY: "CANICATTINI BAGNI",
      },
      {
        CITY: "CANINO",
      },
      {
        CITY: "CANISTRO",
      },
      {
        CITY: "CANNARA",
      },
      {
        CITY: "CANNELLA",
      },
      {
        CITY: "CANNERO RIVIERA",
      },
      {
        CITY: "CANNETO PAVESE",
      },
      {
        CITY: "CANNOBIO",
      },
      {
        CITY: "CANONICA",
      },
      {
        CITY: "CANOSA DI PUGLIA",
      },
      {
        CITY: "CANOSA SANNITA",
      },
      {
        CITY: "CANOSSA",
      },
      {
        CITY: "CANTAGALLO",
      },
      {
        CITY: "CANTALICE",
      },
      {
        CITY: "CANTALUPO IN SABINA",
      },
      {
        CITY: "CANTALUPO LIGURE",
      },
      {
        CITY: "CANTARANA",
      },
      {
        CITY: "CANTU",
      },
      {
        CITY: "CANZO",
      },
      {
        CITY: "CAORLE",
      },
      {
        CITY: "CAORSO",
      },
      {
        CITY: "CAPACCIO",
      },
      {
        CITY: "CAPACI",
      },
      {
        CITY: "CAPALBIO",
      },
      {
        CITY: "CAPANNELLE",
      },
      {
        CITY: "CAPANNOLI",
      },
      {
        CITY: "CAPANNORI",
      },
      {
        CITY: "CAPENA",
      },
      {
        CITY: "CAPERGNANICA",
      },
      {
        CITY: "CAPISTRANO",
      },
      {
        CITY: "CAPISTRELLO",
      },
      {
        CITY: "CAPITIGNANO",
      },
      {
        CITY: "CAPO RIZZUTO",
      },
      {
        CITY: "CAPODIMONTE",
      },
      {
        CITY: "CAPODRISE",
      },
      {
        CITY: "CAPOLIVERI",
      },
      {
        CITY: "CAPOTERRA",
      },
      {
        CITY: "CAPPELLA MAGGIORE",
      },
      {
        CITY: "CAPPELLE SUL TAVO",
      },
      {
        CITY: "CAPRALBA",
      },
      {
        CITY: "CAPRANICA",
      },
      {
        CITY: "CAPRESE MICHELANGELO",
      },
      {
        CITY: "CAPRI",
      },
      {
        CITY: "CAPRIATE SAN GERVASIO",
      },
      {
        CITY: "CAPRIE",
      },
      {
        CITY: "CAPRINO BERGAMASCO",
      },
      {
        CITY: "CAPRINO VERONESE",
      },
      {
        CITY: "CAPRIOLO",
      },
      {
        CITY: "CAPRIVA DEL FRIULI",
      },
      {
        CITY: "CAPUA",
      },
      {
        CITY: "CAPURSO",
      },
      {
        CITY: "CARAGLIO",
      },
      {
        CITY: "CARAPELLE",
      },
      {
        CITY: "CARASCO",
      },
      {
        CITY: "CARATE BRIANZA",
      },
      {
        CITY: "CARAVAGGIO",
      },
      {
        CITY: "CARAVATE",
      },
      {
        CITY: "CARAVINO",
      },
      {
        CITY: "CARAVONICA",
      },
      {
        CITY: "CARBONARA AL TICINO",
      },
      {
        CITY: "CARBONARA DI BARI",
      },
      {
        CITY: "CARBONARA DI PO",
      },
      {
        CITY: "CARBONERA",
      },
      {
        CITY: "CARBONIA",
      },
      {
        CITY: "CARCARE",
      },
      {
        CITY: "CARCERERI",
      },
      {
        CITY: "CARCERI",
      },
      {
        CITY: "CARDANO",
      },
      {
        CITY: "CARDITO",
      },
      {
        CITY: "CAREMA",
      },
      {
        CITY: "CARENNO",
      },
      {
        CITY: "CARENTINO",
      },
      {
        CITY: "CARESANA",
      },
      {
        CITY: "CARIATI",
      },
      {
        CITY: "CARIGNANO",
      },
      {
        CITY: "CARINI",
      },
      {
        CITY: "CARINOLA",
      },
      {
        CITY: "CARLAZZO",
      },
      {
        CITY: "CARLENTINI",
      },
      {
        CITY: "CARLINO",
      },
      {
        CITY: "CARLOFORTE",
      },
      {
        CITY: "CARMAGNOLA",
      },
      {
        CITY: "CARMIANO",
      },
      {
        CITY: "CARMIGNANO",
      },
      {
        CITY: "CARMIGNANO DI BRENTA",
      },
      {
        CITY: "CARNAGO",
      },
      {
        CITY: "CARNATE",
      },
      {
        CITY: "CAROBBIO",
      },
      {
        CITY: "CAROLEI",
      },
      {
        CITY: "CARONIA",
      },
      {
        CITY: "CAROSINO",
      },
      {
        CITY: "CAROVIGNO",
      },
      {
        CITY: "CARPANETO PIACENTINO",
      },
      {
        CITY: "CARPEGNA",
      },
      {
        CITY: "CARPENEDOLO",
      },
      {
        CITY: "CARPENETO",
      },
      {
        CITY: "CARPI",
      },
      {
        CITY: "CARPIANO",
      },
      {
        CITY: "CARPIGNANO SESIA",
      },
      {
        CITY: "CARPINELLO",
      },
      {
        CITY: "CARPINETI",
      },
      {
        CITY: "CARPINETO ROMANO",
      },
      {
        CITY: "CARRARA",
      },
      {
        CITY: "CARSOLI",
      },
      {
        CITY: "CARTOCETO",
      },
      {
        CITY: "CARTURA",
      },
      {
        CITY: "CARUGATE",
      },
      {
        CITY: "CARVICO",
      },
      {
        CITY: "CASA BARTOLACELLI-STELLA",
      },
      {
        CITY: "CASA GHERARDI",
      },
      {
        CITY: "CASA PACE",
      },
      {
        CITY: "CASA PARADISO",
      },
      {
        CITY: "CASA ZOLA",
      },
      {
        CITY: "CASACALENDA",
      },
      {
        CITY: "CASACANDITELLA",
      },
      {
        CITY: "CASACCIA II",
      },
      {
        CITY: "CASAGLIA",
      },
      {
        CITY: "CASAL DI PRINCIPE",
      },
      {
        CITY: "CASAL VELINO",
      },
      {
        CITY: "CASALBORDINO",
      },
      {
        CITY: "CASALBORGONE",
      },
      {
        CITY: "CASALBUTTANO ED UNITI",
      },
      {
        CITY: "CASALE",
      },
      {
        CITY: "CASALE CORTE CERRO",
      },
      {
        CITY: "CASALE DI MEZZANI",
      },
      {
        CITY: "CASALE DI SCODOSIA",
      },
      {
        CITY: "CASALE MONFERRATO",
      },
      {
        CITY: "CASALE SUL SILE",
      },
      {
        CITY: "CASALECCHIO DI RENO",
      },
      {
        CITY: "CASALEGGIO NOVARA",
      },
      {
        CITY: "CASALETTO SPARTANO",
      },
      {
        CITY: "CASALGRANDE",
      },
      {
        CITY: "CASALGRASSO",
      },
      {
        CITY: "CASALI",
      },
      {
        CITY: "CASALMAGGIORE",
      },
      {
        CITY: "CASALMAIOCCO",
      },
      {
        CITY: "CASALMORANO",
      },
      {
        CITY: "CASALNUOVO DI NAPOLI",
      },
      {
        CITY: "CASALOLDO",
      },
      {
        CITY: "CASALPUSTERLENGO",
      },
      {
        CITY: "CASALROMANO",
      },
      {
        CITY: "CASALSERUGO",
      },
      {
        CITY: "CASALUCE",
      },
      {
        CITY: "CASALVECCHIO SICULO",
      },
      {
        CITY: "CASALVIERI",
      },
      {
        CITY: "CASALVOLONE",
      },
      {
        CITY: "CASALZUIGNO",
      },
      {
        CITY: "CASAMARCIANO",
      },
      {
        CITY: "CASAMASSIMA",
      },
      {
        CITY: "CASANDRINO",
      },
      {
        CITY: "CASANOVA",
      },
      {
        CITY: "CASAPULLA",
      },
      {
        CITY: "CASARANO",
      },
      {
        CITY: "CASARATTA",
      },
      {
        CITY: "CASARGO",
      },
      {
        CITY: "CASARILE",
      },
      {
        CITY: "CASARSA DELLA DELIZIA",
      },
      {
        CITY: "CASATE RAFFA",
      },
      {
        CITY: "CASATEIA",
      },
      {
        CITY: "CASATENOVO",
      },
      {
        CITY: "CASAVECCHIA",
      },
      {
        CITY: "CASAZZA",
      },
      {
        CITY: "CASCIA",
      },
      {
        CITY: "CASCIANA TERME",
      },
      {
        CITY: "CASCINA",
      },
      {
        CITY: "CASCINE MAGGIO",
      },
      {
        CITY: "CASE",
      },
      {
        CITY: "CASE CATANIA",
      },
      {
        CITY: "CASE FRANCHINI",
      },
      {
        CITY: "CASEI GEROLA",
      },
      {
        CITY: "CASELETTE",
      },
      {
        CITY: "CASELLE",
      },
      {
        CITY: "CASELLE",
      },
      {
        CITY: "CASELLE LURANI",
      },
      {
        CITY: "CASELLE TORINESE",
      },
      {
        CITY: "CASERTA",
      },
      {
        CITY: "CASIER",
      },
      {
        CITY: "CASIGNANA",
      },
      {
        CITY: "CASINA",
      },
      {
        CITY: "CASINALBO",
      },
      {
        CITY: "CASNIGO",
      },
      {
        CITY: "CASOLA DI NAPOLI",
      },
      {
        CITY: "CASOLI",
      },
      {
        CITY: "CASORATE PRIMO",
      },
      {
        CITY: "CASORATE SEMPIONE",
      },
      {
        CITY: "CASOREZZO",
      },
      {
        CITY: "CASORIA",
      },
      {
        CITY: "CASPERIA",
      },
      {
        CITY: "CASPOGGIO",
      },
      {
        CITY: "CASSACCO",
      },
      {
        CITY: "CASSANO",
      },
      {
        CITY: "CASSANO DELLE MURGE",
      },
      {
        CITY: "CASSANO SPINOLA",
      },
      {
        CITY: "CASSINE SUPERIORE",
      },
      {
        CITY: "CASSINO",
      },
      {
        CITY: "CASSOLNOVO",
      },
      {
        CITY: "CASTAGNARO",
      },
      {
        CITY: "CASTAGNETO CARDUCCI",
      },
      {
        CITY: "CASTAGNETO PO",
      },
      {
        CITY: "CASTAGNOLE",
      },
      {
        CITY: "CASTANO PRIMO",
      },
      {
        CITY: "CASTEGGIO",
      },
      {
        CITY: "CASTEGNATO",
      },
      {
        CITY: "CASTEL",
      },
      {
        CITY: "CASTEL BOLOGNESE",
      },
      {
        CITY: "CASTEL COLONNA",
      },
      {
        CITY: "CASTEL DEL PIANO",
      },
      {
        CITY: "CASTEL DI CASIO",
      },
      {
        CITY: "CASTEL DI JUDICA",
      },
      {
        CITY: "CASTEL FOCOGNANO",
      },
      {
        CITY: "CASTEL FRENTANO",
      },
      {
        CITY: "CASTEL GABBIANO",
      },
      {
        CITY: "CASTEL GANDOLFO",
      },
      {
        CITY: "CASTEL GIORGIO",
      },
      {
        CITY: "CASTEL GOFFREDO",
      },
      {
        CITY: "CASTEL GUELFO DI BOLOGNA",
      },
      {
        CITY: "CASTEL MADAMA",
      },
      {
        CITY: "CASTEL MAGGIORE",
      },
      {
        CITY: "CASTEL MELLA",
      },
      {
        CITY: "CASTEL SAN GIOVANNI",
      },
      {
        CITY: "CASTEL SAN LORENZO",
      },
      {
        CITY: "CASTEL SAN PIETRO ROMANO",
      },
      {
        CITY: "CASTEL SAN PIETRO TERME",
      },
      {
        CITY: "CASTEL VISCARDO",
      },
      {
        CITY: "CASTEL VOLTURNO",
      },
      {
        CITY: "CASTELBELLINO",
      },
      {
        CITY: "CASTELBUONO",
      },
      {
        CITY: "CASTELCUCCO",
      },
      {
        CITY: "CASTELDACCIA",
      },
      {
        CITY: "CASTELFIDARDO",
      },
      {
        CITY: "CASTELFIORENTINO",
      },
      {
        CITY: "CASTELFRANCI",
      },
      {
        CITY: "CASTELFRANCO DI SOPRA",
      },
      {
        CITY: "CASTELFRANCO DI SOTTO",
      },
      {
        CITY: "CASTELFRANCO EMILIA",
      },
      {
        CITY: "CASTELFRANCO VENETO",
      },
      {
        CITY: "CASTELGOMBERTO",
      },
      {
        CITY: "CASTELGRANDE",
      },
      {
        CITY: "CASTELLABATE",
      },
      {
        CITY: "CASTELLALTO",
      },
      {
        CITY: "CASTELLAMMARE DEL GOLFO",
      },
      {
        CITY: "CASTELLAMMARE DI STABIA",
      },
      {
        CITY: "CASTELLAMONTE",
      },
      {
        CITY: "CASTELLANA GROTTE",
      },
      {
        CITY: "CASTELLANA SICULA",
      },
      {
        CITY: "CASTELLANETA",
      },
      {
        CITY: "CASTELLANZA",
      },
      {
        CITY: "CASTELLAR GUIDOBONO",
      },
      {
        CITY: "CASTELLARANO",
      },
      {
        CITY: "CASTELLARO",
      },
      {
        CITY: "CASTELLAZZO BORMIDA",
      },
      {
        CITY: "CASTELLENGO",
      },
      {
        CITY: "CASTELLEONE",
      },
      {
        CITY: "CASTELLETTO",
      },
      {
        CITY: "CASTELLETTO",
      },
      {
        CITY: "CASTELLETTO SOPRA TICINO",
      },
      {
        CITY: "CASTELLI",
      },
      {
        CITY: "CASTELLI CALEPIO",
      },
      {
        CITY: "CASTELLINA IN CHIANTI",
      },
      {
        CITY: "CASTELLINA MARITTIMA",
      },
      {
        CITY: "CASTELLIRI",
      },
      {
        CITY: "CASTELLO",
      },
      {
        CITY: "CASTELLO",
      },
      {
        CITY: "CASTELLO",
      },
      {
        CITY: "CASTELLO",
      },
      {
        CITY: "CASTELLO",
      },
      {
        CITY: "CASTELLO",
      },
      {
        CITY: "CASTELLO",
      },
      {
        CITY: "CASTELLO",
      },
      {
        CITY: "CASTELLO",
      },
      {
        CITY: "CASTELLO DI ANNONE",
      },
      {
        CITY: "CASTELLO DI GODEGO",
      },
      {
        CITY: "CASTELLO DI SERRAVALLE",
      },
      {
        CITY: "CASTELLUCCHIO",
      },
      {
        CITY: "CASTELLUCCIO INFERIORE",
      },
      {
        CITY: "CASTELMASSA",
      },
      {
        CITY: "CASTELNOVO DI SOTTO",
      },
      {
        CITY: "CASTELNUOVO",
      },
      {
        CITY: "CASTELNUOVO",
      },
      {
        CITY: "CASTELNUOVO",
      },
      {
        CITY: "CASTELNUOVO",
      },
      {
        CITY: "CASTELNUOVO",
      },
      {
        CITY: "CASTELNUOVO BERARDENGA",
      },
      {
        CITY: "CASTELNUOVO DEI SABBIONI",
      },
      {
        CITY: "CASTELNUOVO DELLA DAUNIA",
      },
      {
        CITY: "CASTELNUOVO DI GARFAGNANA",
      },
      {
        CITY: "CASTELNUOVO DI PORTO",
      },
      {
        CITY: "CASTELNUOVO DI VAL DI CECINA",
      },
      {
        CITY: "CASTELNUOVO DI VERONA",
      },
      {
        CITY: "CASTELNUOVO DON BOSCO",
      },
      {
        CITY: "CASTELNUOVO MAGRA",
      },
      {
        CITY: "CASTELNUOVO NIGRA",
      },
      {
        CITY: "CASTELNUOVO RANGONE",
      },
      {
        CITY: "CASTELNUOVO SCRIVIA",
      },
      {
        CITY: "CASTELPAGANO",
      },
      {
        CITY: "CASTELPLANIO",
      },
      {
        CITY: "CASTELPOTO",
      },
      {
        CITY: "CASTELRAIMONDO",
      },
      {
        CITY: "CASTELSEPRIO",
      },
      {
        CITY: "CASTELTERMINI",
      },
      {
        CITY: "CASTELVECCHIO",
      },
      {
        CITY: "CASTELVECCHIO PASCOLI",
      },
      {
        CITY: "CASTELVENERE",
      },
      {
        CITY: "CASTELVERDE",
      },
      {
        CITY: "CASTELVETRANO",
      },
      {
        CITY: "CASTELVETRO DI MODENA",
      },
      {
        CITY: "CASTENASO",
      },
      {
        CITY: "CASTENEDOLO",
      },
      {
        CITY: "CASTIADAS",
      },
      {
        CITY: "CASTIGLION FIBOCCHI",
      },
      {
        CITY: "CASTIGLION FIORENTINO",
      },
      {
        CITY: "CASTIGLIONE",
      },
      {
        CITY: "CASTIGLIONE",
      },
      {
        CITY: "CASTIGLIONE",
      },
      {
        CITY: "CASTIGLIONE CHIAVARESE",
      },
      {
        CITY: "CASTIGLIONE COSENTINO",
      },
      {
        CITY: "CASTIGLIONE DEI PEPOLI",
      },
      {
        CITY: "CASTIGLIONE DEL LAGO",
      },
      {
        CITY: "CASTIGLIONE DELLA PESCAIA",
      },
      {
        CITY: "CASTIGLIONE DELLE STIVIERE",
      },
      {
        CITY: "CASTIGLIONE DI CERVIA",
      },
      {
        CITY: "CASTIGLIONE DI GARFAGNANA",
      },
      {
        CITY: "CASTIGLIONE DI SICILIA",
      },
      {
        CITY: "CASTIGLIONE MESSER RAIMONDO",
      },
      {
        CITY: "CASTIGLIONE OLONA",
      },
      {
        CITY: "CASTIGNANO",
      },
      {
        CITY: "CASTILENTI",
      },
      {
        CITY: "CASTIONE DELLA PRESOLANA",
      },
      {
        CITY: "CASTIONS DI STRADA",
      },
      {
        CITY: "CASTO",
      },
      {
        CITY: "CASTORANO",
      },
      {
        CITY: "CASTRI DI LECCE",
      },
      {
        CITY: "CASTRIGNANO DEL CAPO",
      },
      {
        CITY: "CASTRO DEI VOLSCI",
      },
      {
        CITY: "CASTROCARO TERME",
      },
      {
        CITY: "CASTROFILIPPO",
      },
      {
        CITY: "CASTROLIBERO",
      },
      {
        CITY: "CASTRONNO",
      },
      {
        CITY: "CASTROREALE",
      },
      {
        CITY: "CASTROVILLARI",
      },
      {
        CITY: "CATANIA",
      },
      {
        CITY: "CATANZARO",
      },
      {
        CITY: "CATENANUOVA",
      },
      {
        CITY: "CATIGNANO",
      },
      {
        CITY: "CATTOLICA",
      },
      {
        CITY: "CATTOLICA ERACLEA",
      },
      {
        CITY: "CAULONIA",
      },
      {
        CITY: "CAVA MANARA",
      },
      {
        CITY: "CAVACURTA",
      },
      {
        CITY: "CAVAGNOLO",
      },
      {
        CITY: "CAVAION VERONESE",
      },
      {
        CITY: "CAVALESE",
      },
      {
        CITY: "CAVALLERMAGGIORE",
      },
      {
        CITY: "CAVALLINO",
      },
      {
        CITY: "CAVALLINO",
      },
      {
        CITY: "CAVALLIRIO",
      },
      {
        CITY: "CAVARENO",
      },
      {
        CITY: "CAVARIA CON PREMEZZO",
      },
      {
        CITY: "CAVARZERE",
      },
      {
        CITY: "CAVE",
      },
      {
        CITY: "CAVENAGO DI BRIANZA",
      },
      {
        CITY: "CAVEZZO",
      },
      {
        CITY: "CAVOUR",
      },
      {
        CITY: "CAVRIAGO",
      },
      {
        CITY: "CAVRIANA",
      },
      {
        CITY: "CAVRIGLIA",
      },
      {
        CITY: "CAZZAGO SAN MARTINO",
      },
      {
        CITY: "CECCANO",
      },
      {
        CITY: "CECCHINI",
      },
      {
        CITY: "CECINA",
      },
      {
        CITY: "CEDESSANO",
      },
      {
        CITY: "CEDRASCO",
      },
      {
        CITY: "CEGGIA",
      },
      {
        CITY: "CEGLIE MESSAPICA",
      },
      {
        CITY: "CELANO",
      },
      {
        CITY: "CELENZA VALFORTORE",
      },
      {
        CITY: "CELICO",
      },
      {
        CITY: "CELLAMARE",
      },
      {
        CITY: "CELLE LIGURE",
      },
      {
        CITY: "CELLINO ATTANASIO",
      },
      {
        CITY: "CELLINO SAN MARCO",
      },
      {
        CITY: "CELLIO",
      },
      {
        CITY: "CELLOLE",
      },
      {
        CITY: "CEMBRA",
      },
      {
        CITY: "CENAIA",
      },
      {
        CITY: "CENCENIGHE AGORDINO",
      },
      {
        CITY: "CENE",
      },
      {
        CITY: "CENGIO",
      },
      {
        CITY: "CENTALLO",
      },
      {
        CITY: "CENTO",
      },
      {
        CITY: "CENTOLA",
      },
      {
        CITY: "CENTRALE",
      },
      {
        CITY: "CENTURIPE",
      },
      {
        CITY: "CEPAGATTI",
      },
      {
        CITY: "CEPPALONI",
      },
      {
        CITY: "CEPRANO",
      },
      {
        CITY: "CERANO",
      },
      {
        CITY: "CERASOLO",
      },
      {
        CITY: "CERCEPICCOLA",
      },
      {
        CITY: "CERCOLA",
      },
      {
        CITY: "CEREA",
      },
      {
        CITY: "CEREGNANO",
      },
      {
        CITY: "CERES",
      },
      {
        CITY: "CERESARA",
      },
      {
        CITY: "CERETE",
      },
      {
        CITY: "CERIALE",
      },
      {
        CITY: "CERIANO LAGHETTO",
      },
      {
        CITY: "CERIGNOLA",
      },
      {
        CITY: "CERMENATE",
      },
      {
        CITY: "CERNOBBIO",
      },
      {
        CITY: "CERNUSCO SUL NAVIGLIO",
      },
      {
        CITY: "CERREDOLO",
      },
      {
        CITY: "CERRETO GUIDI",
      },
      {
        CITY: "CERRETO LAZIALE",
      },
      {
        CITY: "CERRETO SANNITA",
      },
      {
        CITY: "CERRIONE",
      },
      {
        CITY: "CERRO MAGGIORE",
      },
      {
        CITY: "CERTALDO",
      },
      {
        CITY: "CERTOSA",
      },
      {
        CITY: "CERTOSA DI PAVIA",
      },
      {
        CITY: "CERVARO",
      },
      {
        CITY: "CERVENO",
      },
      {
        CITY: "CERVERE",
      },
      {
        CITY: "CERVETERI",
      },
      {
        CITY: "CERVIA",
      },
      {
        CITY: "CERVIGNANO DEL FRIULI",
      },
      {
        CITY: "CERVINO",
      },
      {
        CITY: "CERVO",
      },
      {
        CITY: "CESA",
      },
      {
        CITY: "CESANA TORINESE",
      },
      {
        CITY: "CESANO MADERNO",
      },
      {
        CITY: "CESARA",
      },
      {
        CITY: "CESE",
      },
      {
        CITY: "CESENA",
      },
      {
        CITY: "CESENATICO",
      },
      {
        CITY: "CESIOMAGGIORE",
      },
      {
        CITY: "CETO",
      },
      {
        CITY: "CETRARO",
      },
      {
        CITY: "CEVA",
      },
      {
        CITY: "CHAMBAVE",
      },
      {
        CITY: "CHAMPDEPRAZ",
      },
      {
        CITY: "CHARVENSOD",
      },
      {
        CITY: "CHERASCO",
      },
      {
        CITY: "CHIAMPO",
      },
      {
        CITY: "CHIANCIANO TERME",
      },
      {
        CITY: "CHIARA",
      },
      {
        CITY: "CHIARAMONTE GULFI",
      },
      {
        CITY: "CHIARANO",
      },
      {
        CITY: "CHIARAVALLE",
      },
      {
        CITY: "CHIARAVALLE CENTRALE",
      },
      {
        CITY: "CHIARI",
      },
      {
        CITY: "CHIAVARI",
      },
      {
        CITY: "CHIAVAZZA",
      },
      {
        CITY: "CHIAVENNA",
      },
      {
        CITY: "CHIERI",
      },
      {
        CITY: "CHIESINA UZZANESE",
      },
      {
        CITY: "CHIETI",
      },
      {
        CITY: "CHIOGGIA",
      },
      {
        CITY: "CHIOMONTE",
      },
      {
        CITY: "CHIONS",
      },
      {
        CITY: "CHITIGNANO",
      },
      {
        CITY: "CHIUSANICO",
      },
      {
        CITY: "CHIUSANO DI SAN DOMENICO",
      },
      {
        CITY: "CHIUSAVECCHIA",
      },
      {
        CITY: "CHIUSI",
      },
      {
        CITY: "CHIUSI DELLA VERNA",
      },
      {
        CITY: "CHIVASSO",
      },
      {
        CITY: "CICAGNA",
      },
      {
        CITY: "CICCIANO",
      },
      {
        CITY: "CICILIANO",
      },
      {
        CITY: "CICOGNOLO",
      },
      {
        CITY: "CIGLIANO",
      },
      {
        CITY: "CIGNONE",
      },
      {
        CITY: "CILAVEGNA",
      },
      {
        CITY: "CIMADOLMO",
      },
      {
        CITY: "CIMEGO",
      },
      {
        CITY: "CIMINNA",
      },
      {
        CITY: "CIMITILE",
      },
      {
        CITY: "CINECITTA",
      },
      {
        CITY: "CINGOLI",
      },
      {
        CITY: "CINISELLO BALSAMO",
      },
      {
        CITY: "CINISI",
      },
      {
        CITY: "CINQUEFRONDI",
      },
      {
        CITY: "CINTANO",
      },
      {
        CITY: "CINTO CAOMAGGIORE",
      },
      {
        CITY: "CIRCELLO",
      },
      {
        CITY: "CIRIMIDO",
      },
      {
        CITY: "CISANO BERGAMASCO",
      },
      {
        CITY: "CISANO SUL NEVA",
      },
      {
        CITY: "CISERANO",
      },
      {
        CITY: "CISLAGO",
      },
      {
        CITY: "CISLIANO",
      },
      {
        CITY: "CISON DI VALMARINO",
      },
      {
        CITY: "CISTERNA DI LATINA",
      },
      {
        CITY: "CISTERNINO",
      },
      {
        CITY: "CITTADELLA",
      },
      {
        CITY: "CITTADUCALE",
      },
      {
        CITY: "CITTANOVA",
      },
      {
        CITY: "CITTIGLIO",
      },
      {
        CITY: "CIVEZZANO",
      },
      {
        CITY: "CIVIDALE DEL FRIULI",
      },
      {
        CITY: "CIVIDATE CAMUNO",
      },
      {
        CITY: "CIVITA CASTELLANA",
      },
      {
        CITY: "CIVITANOVA ALTA",
      },
      {
        CITY: "CIVITANOVA MARCHE",
      },
      {
        CITY: "CIVITAVECCHIA",
      },
      {
        CITY: "CIVITELLA DEL TRONTO",
      },
      {
        CITY: "CIVITELLA DI ROMAGNA",
      },
      {
        CITY: "CIVITELLA IN VAL DI CHIANA",
      },
      {
        CITY: "CIVITELLA MARITTIMA",
      },
      {
        CITY: "CIVITELLA ROVETO",
      },
      {
        CITY: "CIVITELLA SAN PAOLO",
      },
      {
        CITY: "CIZZAGO-COMEZZANO",
      },
      {
        CITY: "CLES",
      },
      {
        CITY: "CLUSONE",
      },
      {
        CITY: "COCCAGLIO",
      },
      {
        CITY: "COCCONATO",
      },
      {
        CITY: "COCQUIO-TREVISAGO",
      },
      {
        CITY: "CODARUINA",
      },
      {
        CITY: "CODEVIGO",
      },
      {
        CITY: "CODIGORO",
      },
      {
        CITY: "CODOGNO",
      },
      {
        CITY: "CODROIPO",
      },
      {
        CITY: "CODRONGIANOS",
      },
      {
        CITY: "COGGIOLA",
      },
      {
        CITY: "COGNE",
      },
      {
        CITY: "COGOLETO",
      },
      {
        CITY: "COGORNO",
      },
      {
        CITY: "COLA",
      },
      {
        CITY: "COLBORDOLO",
      },
      {
        CITY: "COLERE",
      },
      {
        CITY: "COLICO",
      },
      {
        CITY: "COLLALTO",
      },
      {
        CITY: "COLLAZZONE",
      },
      {
        CITY: "COLLE",
      },
      {
        CITY: "COLLE",
      },
      {
        CITY: "COLLE UMBERTO",
      },
      {
        CITY: "COLLECCHIO",
      },
      {
        CITY: "COLLECORVINO",
      },
      {
        CITY: "COLLEDARA",
      },
      {
        CITY: "COLLEDIMEZZO",
      },
      {
        CITY: "COLLEFERRO",
      },
      {
        CITY: "COLLEFONTANA-FONTANA LIRI INFERIORE",
      },
      {
        CITY: "COLLEGNO",
      },
      {
        CITY: "COLLELUNGO",
      },
      {
        CITY: "COLLEPASSO",
      },
      {
        CITY: "COLLESANO",
      },
      {
        CITY: "COLLI DEL TRONTO",
      },
      {
        CITY: "COLLIANO",
      },
      {
        CITY: "COLLINAS",
      },
      {
        CITY: "COLLOREDO DI PRATO",
      },
      {
        CITY: "COLMURANO",
      },
      {
        CITY: "COLOGNA",
      },
      {
        CITY: "COLOGNA VENETA",
      },
      {
        CITY: "COLOGNE",
      },
      {
        CITY: "COLOGNO AL SERIO",
      },
      {
        CITY: "COLOGNO MONZESE",
      },
      {
        CITY: "COLOMBARO",
      },
      {
        CITY: "COLONNA",
      },
      {
        CITY: "COLONNELLA",
      },
      {
        CITY: "COLORNO",
      },
      {
        CITY: "COLTURA",
      },
      {
        CITY: "COMACCHIO",
      },
      {
        CITY: "COMANO",
      },
      {
        CITY: "COMAZZO",
      },
      {
        CITY: "COMERIO",
      },
      {
        CITY: "COMISO",
      },
      {
        CITY: "COMO",
      },
      {
        CITY: "COMPIANO",
      },
      {
        CITY: "COMUN NUOVO",
      },
      {
        CITY: "CONA",
      },
      {
        CITY: "CONCO",
      },
      {
        CITY: "CONCORDIA SAGITTARIA",
      },
      {
        CITY: "CONCORDIA SULLA SECCHIA",
      },
      {
        CITY: "CONCOREZZO",
      },
      {
        CITY: "CONDOFURI",
      },
      {
        CITY: "CONDOVE",
      },
      {
        CITY: "CONEGLIANO",
      },
      {
        CITY: "CONFIENZA",
      },
      {
        CITY: "CONSELICE",
      },
      {
        CITY: "CONSELVE",
      },
      {
        CITY: "CONTIGLIANO",
      },
      {
        CITY: "CONTRADA",
      },
      {
        CITY: "CONTROGUERRA",
      },
      {
        CITY: "CONTURSI TERME",
      },
      {
        CITY: "CONVERSANO",
      },
      {
        CITY: "COPERTINO",
      },
      {
        CITY: "COPIANO",
      },
      {
        CITY: "COPPARO",
      },
      {
        CITY: "COPPE",
      },
      {
        CITY: "COPPITO",
      },
      {
        CITY: "CORATO",
      },
      {
        CITY: "CORBETTA",
      },
      {
        CITY: "CORBOLA",
      },
      {
        CITY: "CORCHIANO",
      },
      {
        CITY: "CORCIANO",
      },
      {
        CITY: "CORDENONS",
      },
      {
        CITY: "CORDIGNANO",
      },
      {
        CITY: "CORDOVADO",
      },
      {
        CITY: "COREA CASTELLUCCIA SAN PAOLO",
      },
      {
        CITY: "COREDO",
      },
      {
        CITY: "CORFINIO",
      },
      {
        CITY: "CORI",
      },
      {
        CITY: "CORIANO",
      },
      {
        CITY: "CORIGLIANO CALABRO",
      },
      {
        CITY: "CORINALDO",
      },
      {
        CITY: "CORIO",
      },
      {
        CITY: "CORLEONE",
      },
      {
        CITY: "CORLETO PERTICARA",
      },
      {
        CITY: "CORMONS",
      },
      {
        CITY: "CORNAIANO",
      },
      {
        CITY: "CORNAREDO",
      },
      {
        CITY: "CORNEDO VICENTINO",
      },
      {
        CITY: "CORNO",
      },
      {
        CITY: "CORNO DI ROSAZZO",
      },
      {
        CITY: "CORNO GIOVINE",
      },
      {
        CITY: "CORNUDA",
      },
      {
        CITY: "CORONA",
      },
      {
        CITY: "CORREGGIO",
      },
      {
        CITY: "CORREZZOLA",
      },
      {
        CITY: "CORRIDONIA",
      },
      {
        CITY: "CORROPOLI",
      },
      {
        CITY: "CORSANO",
      },
      {
        CITY: "CORSICO",
      },
      {
        CITY: "CORTANDONE",
      },
      {
        CITY: "CORTE",
      },
      {
        CITY: "CORTE FRANCA",
      },
      {
        CITY: "CORTEMAGGIORE",
      },
      {
        CITY: "CORTENOVA",
      },
      {
        CITY: "CORTEOLONA",
      },
      {
        CITY: "CORTI",
      },
      {
        CITY: "CORTINA",
      },
      {
        CITY: "CORTONA",
      },
      {
        CITY: "CORVARA",
      },
      {
        CITY: "CORZANO",
      },
      {
        CITY: "COSEANO",
      },
      {
        CITY: "COSENZA",
      },
      {
        CITY: "COSSATO",
      },
      {
        CITY: "COSTA",
      },
      {
        CITY: "COSTA",
      },
      {
        CITY: "COSTA DI ROVIGO",
      },
      {
        CITY: "COSTA VOLPINO",
      },
      {
        CITY: "COSTABISSARA",
      },
      {
        CITY: "COSTARAINERA",
      },
      {
        CITY: "COSTERMANO",
      },
      {
        CITY: "COTIGNOLA",
      },
      {
        CITY: "COTRONEI",
      },
      {
        CITY: "COURMAYEUR",
      },
      {
        CITY: "COVO",
      },
      {
        CITY: "COZZO",
      },
      {
        CITY: "CREAZZO",
      },
      {
        CITY: "CREMA",
      },
      {
        CITY: "CREMOLINO",
      },
      {
        CITY: "CREMONA",
      },
      {
        CITY: "CRESCENTINO",
      },
      {
        CITY: "CRESPANO DEL GRAPPA",
      },
      {
        CITY: "CRESPELLANO",
      },
      {
        CITY: "CRESPIATICA",
      },
      {
        CITY: "CRESPINA",
      },
      {
        CITY: "CRESPINO",
      },
      {
        CITY: "CRESSA",
      },
      {
        CITY: "CREVACUORE",
      },
      {
        CITY: "CREVALCORE",
      },
      {
        CITY: "CRISPIANO",
      },
      {
        CITY: "CROCE",
      },
      {
        CITY: "CROCETTA DEL MONTELLO",
      },
      {
        CITY: "CRODO",
      },
      {
        CITY: "CROPANI",
      },
      {
        CITY: "CROSIA",
      },
      {
        CITY: "CROTONE",
      },
      {
        CITY: "CUCEGLIO",
      },
      {
        CITY: "CUGGIONO",
      },
      {
        CITY: "CUGLIERI",
      },
      {
        CITY: "CUGNOLI",
      },
      {
        CITY: "CUMIANA",
      },
      {
        CITY: "CUMIGNANO SUL NAVIGLIO",
      },
      {
        CITY: "CUNEO",
      },
      {
        CITY: "CUNICO",
      },
      {
        CITY: "CUPELLO",
      },
      {
        CITY: "CUPRA MARITTIMA",
      },
      {
        CITY: "CUPRAMONTANA",
      },
      {
        CITY: "CURCURIS",
      },
      {
        CITY: "CURINGA",
      },
      {
        CITY: "CURNO",
      },
      {
        CITY: "CURTAROLO",
      },
      {
        CITY: "CUSANO MILANINO",
      },
      {
        CITY: "CUSANO MUTRI",
      },
      {
        CITY: "CUSINO",
      },
      {
        CITY: "CUSTONACI",
      },
      {
        CITY: "CUTROFIANO",
      },
      {
        CITY: "DAIRAGO",
      },
      {
        CITY: "DALMINE",
      },
      {
        CITY: "DARFO",
      },
      {
        CITY: "DAVOLI",
      },
      {
        CITY: "DECIMA",
      },
      {
        CITY: "DECOLLATURA",
      },
      {
        CITY: "DEGGIANO",
      },
      {
        CITY: "DEGO",
      },
      {
        CITY: "DEIVA MARINA",
      },
      {
        CITY: "DELIA",
      },
      {
        CITY: "DELIANUOVA",
      },
      {
        CITY: "DELLO",
      },
      {
        CITY: "DEMONTE",
      },
      {
        CITY: "DERUTA",
      },
      {
        CITY: "DERVIO",
      },
      {
        CITY: "DESENZANO DEL GARDA",
      },
      {
        CITY: "DESIO",
      },
      {
        CITY: "DEUTSCHNOFEN",
      },
      {
        CITY: "DIAMANTE",
      },
      {
        CITY: "DIANO MARINA",
      },
      {
        CITY: "DIANO SAN PIETRO",
      },
      {
        CITY: "DICOMANO",
      },
      {
        CITY: "DIECIMO",
      },
      {
        CITY: "DIGNANO",
      },
      {
        CITY: "DIMARO",
      },
      {
        CITY: "DIPIGNANO",
      },
      {
        CITY: "DOGLIANI",
      },
      {
        CITY: "DOLCEACQUA",
      },
      {
        CITY: "DOLIANOVA",
      },
      {
        CITY: "DOLO",
      },
      {
        CITY: "DOMANICO",
      },
      {
        CITY: "DOMASO",
      },
      {
        CITY: "DOMEGLIARA",
      },
      {
        CITY: "DOMODOSSOLA",
      },
      {
        CITY: "DOMUSNOVAS",
      },
      {
        CITY: "DONGO",
      },
      {
        CITY: "DORIA",
      },
      {
        CITY: "DORNO",
      },
      {
        CITY: "DOSOLO",
      },
      {
        CITY: "DOSSOBUONO",
      },
      {
        CITY: "DOSSON",
      },
      {
        CITY: "DOVADOLA",
      },
      {
        CITY: "DOZZA",
      },
      {
        CITY: "DOZZA",
      },
      {
        CITY: "DRAPIA",
      },
      {
        CITY: "DRO",
      },
      {
        CITY: "DRONERO",
      },
      {
        CITY: "DRUENTO",
      },
      {
        CITY: "DRUOGNO",
      },
      {
        CITY: "DUBINO",
      },
      {
        CITY: "DUEVILLE",
      },
      {
        CITY: "DUGENTA",
      },
      {
        CITY: "DUINO-AURISINA",
      },
      {
        CITY: "EBOLI",
      },
      {
        CITY: "EDOLO",
      },
      {
        CITY: "ELMAS",
      },
      {
        CITY: "EMPOLI",
      },
      {
        CITY: "ENDINE GAIANO",
      },
      {
        CITY: "ENEMONZO-QUINIS",
      },
      {
        CITY: "ENNA",
      },
      {
        CITY: "ENVIE",
      },
      {
        CITY: "ERACLEA",
      },
      {
        CITY: "ERBA",
      },
      {
        CITY: "ERCHIE",
      },
      {
        CITY: "ERCOLANO",
      },
      {
        CITY: "ERICE",
      },
      {
        CITY: "ERVE",
      },
      {
        CITY: "ESCALAPLANO",
      },
      {
        CITY: "ESCOLCA",
      },
      {
        CITY: "ESINE",
      },
      {
        CITY: "ESINO LARIO",
      },
      {
        CITY: "ESPERIA",
      },
      {
        CITY: "ESTE",
      },
      {
        CITY: "ETROUBLES",
      },
      {
        CITY: "FABBRI",
      },
      {
        CITY: "FABBRICA",
      },
      {
        CITY: "FABBRICO",
      },
      {
        CITY: "FABRIANO",
      },
      {
        CITY: "FABRICA DI ROMA",
      },
      {
        CITY: "FAEDIS",
      },
      {
        CITY: "FAENZA",
      },
      {
        CITY: "FAGAGNA",
      },
      {
        CITY: "FAGGIANO",
      },
      {
        CITY: "FAGNANO OLONA",
      },
      {
        CITY: "FAIANO",
      },
      {
        CITY: "FALCIANO",
      },
      {
        CITY: "FALCONARA",
      },
      {
        CITY: "FALCONARA ALBANESE",
      },
      {
        CITY: "FALCONARA MARITTIMA",
      },
      {
        CITY: "FALCONE",
      },
      {
        CITY: "FALERNA",
      },
      {
        CITY: "FALERONE",
      },
      {
        CITY: "FALZE-SIGNORESSA",
      },
      {
        CITY: "FANANO",
      },
      {
        CITY: "FANNA",
      },
      {
        CITY: "FANO",
      },
      {
        CITY: "FANTINA",
      },
      {
        CITY: "FARA FILIORUM PETRI",
      },
      {
        CITY: "FARA IN SABINA",
      },
      {
        CITY: "FARA NOVARESE",
      },
      {
        CITY: "FARNESE",
      },
      {
        CITY: "FARRA DI SOLIGO",
      },
      {
        CITY: "FASANO",
      },
      {
        CITY: "FASTELLO",
      },
      {
        CITY: "FAUGLIA",
      },
      {
        CITY: "FAVARA",
      },
      {
        CITY: "FAVARO VENETO",
      },
      {
        CITY: "FAVRIA",
      },
      {
        CITY: "FELDTHURNS",
      },
      {
        CITY: "FELEGARA",
      },
      {
        CITY: "FELETTO",
      },
      {
        CITY: "FELETTO UMBERTO",
      },
      {
        CITY: "FELINO",
      },
      {
        CITY: "FELITTO",
      },
      {
        CITY: "FELTRE",
      },
      {
        CITY: "FENILE",
      },
      {
        CITY: "FERENTINO",
      },
      {
        CITY: "FERMIGNANO",
      },
      {
        CITY: "FERMO",
      },
      {
        CITY: "FERNO",
      },
      {
        CITY: "FEROLETO DELLA CHIESA",
      },
      {
        CITY: "FERRANDINA",
      },
      {
        CITY: "FERRARA",
      },
      {
        CITY: "FERRARA",
      },
      {
        CITY: "FERRARI",
      },
      {
        CITY: "FERRAZZANO",
      },
      {
        CITY: "FERRERO",
      },
      {
        CITY: "FERRIERE",
      },
      {
        CITY: "FIANO",
      },
      {
        CITY: "FIANO ROMANO",
      },
      {
        CITY: "FICARAZZI",
      },
      {
        CITY: "FICAROLO",
      },
      {
        CITY: "FICARRA",
      },
      {
        CITY: "FICULLE",
      },
      {
        CITY: "FIDENZA",
      },
      {
        CITY: "FIELD",
      },
      {
        CITY: "FIERA",
      },
      {
        CITY: "FIESOLE",
      },
      {
        CITY: "FIESSO UMBERTIANO",
      },
      {
        CITY: "FIGINO",
      },
      {
        CITY: "FIGLINE VALDARNO",
      },
      {
        CITY: "FILADELFIA",
      },
      {
        CITY: "FILETTINO",
      },
      {
        CITY: "FILETTO",
      },
      {
        CITY: "FILIANO",
      },
      {
        CITY: "FILIGHERA",
      },
      {
        CITY: "FILO",
      },
      {
        CITY: "FILOTTRANO",
      },
      {
        CITY: "FINALE EMILIA",
      },
      {
        CITY: "FINALE LIGURE",
      },
      {
        CITY: "FINO MORNASCO",
      },
      {
        CITY: "FIORANO MODENESE",
      },
      {
        CITY: "FIORE",
      },
      {
        CITY: "FISCIANO",
      },
      {
        CITY: "FIUGGI",
      },
      {
        CITY: "FIUME VENETO",
      },
      {
        CITY: "FIUMEFREDDO DI SICILIA",
      },
      {
        CITY: "FIUMICELLO",
      },
      {
        CITY: "FIUMICINO",
      },
      {
        CITY: "FLAIBANO",
      },
      {
        CITY: "FLERO",
      },
      {
        CITY: "FLORENCE",
      },
      {
        CITY: "FLORIDIA",
      },
      {
        CITY: "FLUMINIMAGGIORE",
      },
      {
        CITY: "FLUSSIO",
      },
      {
        CITY: "FOGGIA",
      },
      {
        CITY: "FOGLIANISE",
      },
      {
        CITY: "FOGLIANO REDIPUGLIA",
      },
      {
        CITY: "FOGLIZZO",
      },
      {
        CITY: "FOIANO DELLA CHIANA",
      },
      {
        CITY: "FOIANO DI VAL FORTORE",
      },
      {
        CITY: "FOLGARIA",
      },
      {
        CITY: "FOLIGNANO",
      },
      {
        CITY: "FOLIGNO",
      },
      {
        CITY: "FOLLINA",
      },
      {
        CITY: "FOLLONICA",
      },
      {
        CITY: "FOMBIO",
      },
      {
        CITY: "FONDI",
      },
      {
        CITY: "FONNI",
      },
      {
        CITY: "FONTANA",
      },
      {
        CITY: "FONTANA LIRI",
      },
      {
        CITY: "FONTANAFREDDA",
      },
      {
        CITY: "FONTANAROSA",
      },
      {
        CITY: "FONTANELICE",
      },
      {
        CITY: "FONTANELLA",
      },
      {
        CITY: "FONTANELLA",
      },
      {
        CITY: "FONTANELLATO",
      },
      {
        CITY: "FONTANELLE",
      },
      {
        CITY: "FONTANELLE",
      },
      {
        CITY: "FONTANIVA",
      },
      {
        CITY: "FONTEVIVO",
      },
      {
        CITY: "FONZASO",
      },
      {
        CITY: "FORANO",
      },
      {
        CITY: "FORENZA",
      },
      {
        CITY: "FORESTO",
      },
      {
        CITY: "FORINO",
      },
      {
        CITY: "FORIO",
      },
      {
        CITY: "FORLIMPOPOLI",
      },
      {
        CITY: "FORMELLO",
      },
      {
        CITY: "FORMIA",
      },
      {
        CITY: "FORMICOLA",
      },
      {
        CITY: "FORMIGINE",
      },
      {
        CITY: "FORNACE DI MIRAMARE",
      },
      {
        CITY: "FORNACI",
      },
      {
        CITY: "FORNACI DI BARGA",
      },
      {
        CITY: "FORNO",
      },
      {
        CITY: "FORNO ALPI GRAIE",
      },
      {
        CITY: "FORNO CANAVESE",
      },
      {
        CITY: "FORNOVO DI TARO",
      },
      {
        CITY: "FORTE DEI MARMI",
      },
      {
        CITY: "FORTUNAGO",
      },
      {
        CITY: "FOSCO",
      },
      {
        CITY: "FOSDINOVO",
      },
      {
        CITY: "FOSSA",
      },
      {
        CITY: "FOSSACESIA",
      },
      {
        CITY: "FOSSALTA DI PIAVE",
      },
      {
        CITY: "FOSSALTA DI PORTOGRUARO",
      },
      {
        CITY: "FOSSANO",
      },
      {
        CITY: "FOSSATO DI VICO",
      },
      {
        CITY: "FOSSOMBRONE",
      },
      {
        CITY: "FRABOSA SOTTANA",
      },
      {
        CITY: "FRAGAGNANO",
      },
      {
        CITY: "FRANCAVILLA AL MARE",
      },
      {
        CITY: "FRANCAVILLA DI SICILIA",
      },
      {
        CITY: "FRANCAVILLA FONTANA",
      },
      {
        CITY: "FRANCAVILLA IN SINNI",
      },
      {
        CITY: "FRANCENIGO",
      },
      {
        CITY: "FRANCOFONTE",
      },
      {
        CITY: "FRANCOLISE",
      },
      {
        CITY: "FRASCARO",
      },
      {
        CITY: "FRASCATI",
      },
      {
        CITY: "FRASCINETO",
      },
      {
        CITY: "FRASSINETO PO",
      },
      {
        CITY: "FRASSO TELESINO",
      },
      {
        CITY: "FRATTAMAGGIORE",
      },
      {
        CITY: "FRATTAMINORE",
      },
      {
        CITY: "FRATTOCCHIE",
      },
      {
        CITY: "FREGENE",
      },
      {
        CITY: "FRIGENTO",
      },
      {
        CITY: "FRISA",
      },
      {
        CITY: "FRONT",
      },
      {
        CITY: "FRONTONE",
      },
      {
        CITY: "FROSINONE",
      },
      {
        CITY: "FRUGAROLO",
      },
      {
        CITY: "FUCECCHIO",
      },
      {
        CITY: "FUMANE",
      },
      {
        CITY: "FUMONE",
      },
      {
        CITY: "FURCI SICULO",
      },
      {
        CITY: "FURORE",
      },
      {
        CITY: "FUSCALDO",
      },
      {
        CITY: "FUSIGNANO",
      },
      {
        CITY: "FUTANI",
      },
      {
        CITY: "GABBIANO",
      },
      {
        CITY: "GABBRO",
      },
      {
        CITY: "GABICCE MARE",
      },
      {
        CITY: "GADONI",
      },
      {
        CITY: "GAETA",
      },
      {
        CITY: "GAGGI",
      },
      {
        CITY: "GAGGIANO",
      },
      {
        CITY: "GAGGIO MONTANO",
      },
      {
        CITY: "GAGLIANO DEL CAPO",
      },
      {
        CITY: "GAIARINE",
      },
      {
        CITY: "GAIOLE IN CHIANTI",
      },
      {
        CITY: "GAIS",
      },
      {
        CITY: "GALATINA",
      },
      {
        CITY: "GALATONE",
      },
      {
        CITY: "GALBIATE",
      },
      {
        CITY: "GALDO",
      },
      {
        CITY: "GALEATA",
      },
      {
        CITY: "GALLARATE",
      },
      {
        CITY: "GALLESE",
      },
      {
        CITY: "GALLIATE",
      },
      {
        CITY: "GALLICANO",
      },
      {
        CITY: "GALLICANO NEL LAZIO",
      },
      {
        CITY: "GALLIERA",
      },
      {
        CITY: "GALLIERA VENETA",
      },
      {
        CITY: "GALLIGNANO",
      },
      {
        CITY: "GALLIO",
      },
      {
        CITY: "GALLIPOLI",
      },
      {
        CITY: "GALLO",
      },
      {
        CITY: "GAMBARA",
      },
      {
        CITY: "GAMBELLARA",
      },
      {
        CITY: "GAMBETTOLA",
      },
      {
        CITY: "GANDINO",
      },
      {
        CITY: "GANGI",
      },
      {
        CITY: "GARBAGNATE MILANESE",
      },
      {
        CITY: "GARDA",
      },
      {
        CITY: "GARDONE RIVIERA",
      },
      {
        CITY: "GARDONE VAL TROMPIA",
      },
      {
        CITY: "GARESSIO",
      },
      {
        CITY: "GARGNANO",
      },
      {
        CITY: "GARIBALDI",
      },
      {
        CITY: "GARLASCO",
      },
      {
        CITY: "GARLATE",
      },
      {
        CITY: "GARLENDA",
      },
      {
        CITY: "GARRUFO",
      },
      {
        CITY: "GASSINO TORINESE",
      },
      {
        CITY: "GATTATICO",
      },
      {
        CITY: "GATTEO",
      },
      {
        CITY: "GATTEO A MARE",
      },
      {
        CITY: "GATTINARA",
      },
      {
        CITY: "GAVARDO",
      },
      {
        CITY: "GAVI",
      },
      {
        CITY: "GAVINANA",
      },
      {
        CITY: "GAVIRATE",
      },
      {
        CITY: "GAVOI",
      },
      {
        CITY: "GAZOLDO DEGLI IPPOLITI",
      },
      {
        CITY: "GAZZADA SCHIANNO",
      },
      {
        CITY: "GAZZANIGA",
      },
      {
        CITY: "GAZZO",
      },
      {
        CITY: "GAZZO VERONESE",
      },
      {
        CITY: "GAZZOLA",
      },
      {
        CITY: "GAZZUOLO",
      },
      {
        CITY: "GELA",
      },
      {
        CITY: "GEMONA",
      },
      {
        CITY: "GENAZZANO",
      },
      {
        CITY: "GENIVOLTA",
      },
      {
        CITY: "GENOA",
      },
      {
        CITY: "GENZANO DI ROMA",
      },
      {
        CITY: "GERA LARIO",
      },
      {
        CITY: "GERANO",
      },
      {
        CITY: "GERENZAGO",
      },
      {
        CITY: "GERGEI",
      },
      {
        CITY: "GERMAGNANO",
      },
      {
        CITY: "GESSATE",
      },
      {
        CITY: "GESSOPALENA",
      },
      {
        CITY: "GESUALDO",
      },
      {
        CITY: "GHEDI",
      },
      {
        CITY: "GHEMME",
      },
      {
        CITY: "GHIFFA",
      },
      {
        CITY: "GHILARZA",
      },
      {
        CITY: "GHISALBA",
      },
      {
        CITY: "GIANO VETUSTO",
      },
      {
        CITY: "GIARDINELLO",
      },
      {
        CITY: "GIARDINETTO VECCHIO",
      },
      {
        CITY: "GIARDINI",
      },
      {
        CITY: "GIARDINI-NAXOS",
      },
      {
        CITY: "GIARRATANA",
      },
      {
        CITY: "GIARRE",
      },
      {
        CITY: "GIAVENO",
      },
      {
        CITY: "GIAVERA DEL MONTELLO",
      },
      {
        CITY: "GIBELLINA",
      },
      {
        CITY: "GIFFONI VALLE PIANA",
      },
      {
        CITY: "GIGNESE",
      },
      {
        CITY: "GIMIGLIANO",
      },
      {
        CITY: "GINOSA",
      },
      {
        CITY: "GIOIA",
      },
      {
        CITY: "GIOIA DEL COLLE",
      },
      {
        CITY: "GIOIA TAURO",
      },
      {
        CITY: "GIOIOSA IONICA",
      },
      {
        CITY: "GIOIOSA MAREA",
      },
      {
        CITY: "GIOVE",
      },
      {
        CITY: "GIOVE",
      },
      {
        CITY: "GIOVINAZZO",
      },
      {
        CITY: "GIRIFALCO",
      },
      {
        CITY: "GISSI",
      },
      {
        CITY: "GIUGLIANO IN CAMPANIA",
      },
      {
        CITY: "GIULIANA",
      },
      {
        CITY: "GIULIANO",
      },
      {
        CITY: "GIULIANO TEATINO",
      },
      {
        CITY: "GIULIANOVA",
      },
      {
        CITY: "GIUNGANO",
      },
      {
        CITY: "GIUSSAGO",
      },
      {
        CITY: "GIUSSANO",
      },
      {
        CITY: "GODIASCO",
      },
      {
        CITY: "GODO",
      },
      {
        CITY: "GOITO",
      },
      {
        CITY: "GOLASECCA",
      },
      {
        CITY: "GOLFO ARANCI",
      },
      {
        CITY: "GONARS",
      },
      {
        CITY: "GONNESA",
      },
      {
        CITY: "GONNOSFANADIGA",
      },
      {
        CITY: "GONNOSTRAMATZA",
      },
      {
        CITY: "GONZAGA",
      },
      {
        CITY: "GORDONA",
      },
      {
        CITY: "GORGO AL MONTICANO",
      },
      {
        CITY: "GORGONZOLA",
      },
      {
        CITY: "GORIZIA",
      },
      {
        CITY: "GORLA MAGGIORE",
      },
      {
        CITY: "GORLE",
      },
      {
        CITY: "GORNO",
      },
      {
        CITY: "GOSSOLENGO",
      },
      {
        CITY: "GOTTOLENGO",
      },
      {
        CITY: "GOZZANO",
      },
      {
        CITY: "GRADARA",
      },
      {
        CITY: "GRADO",
      },
      {
        CITY: "GRADOLI",
      },
      {
        CITY: "GRAGLIA",
      },
      {
        CITY: "GRAGNANO",
      },
      {
        CITY: "GRAGNANO TREBBIENSE",
      },
      {
        CITY: "GRAMMICHELE",
      },
      {
        CITY: "GRANDATE",
      },
      {
        CITY: "GRANITOLA TORRETTA",
      },
      {
        CITY: "GRANTORTO",
      },
      {
        CITY: "GRASSANO",
      },
      {
        CITY: "GRAVEDONA",
      },
      {
        CITY: "GRAVELLONA TOCE",
      },
      {
        CITY: "GRAVINA IN PUGLIA",
      },
      {
        CITY: "GRAZZANISE",
      },
      {
        CITY: "GRAZZANO BADOGLIO",
      },
      {
        CITY: "GRECI",
      },
      {
        CITY: "GRESSONEY-SAINT-JEAN",
      },
      {
        CITY: "GREVE IN CHIANTI",
      },
      {
        CITY: "GREZZANA",
      },
      {
        CITY: "GRIGNASCO",
      },
      {
        CITY: "GRIGNO",
      },
      {
        CITY: "GRIMALDI",
      },
      {
        CITY: "GRISIGNANO DI ZOCCO",
      },
      {
        CITY: "GROPELLO CAIROLI",
      },
      {
        CITY: "GROSIO",
      },
      {
        CITY: "GROSSETO",
      },
      {
        CITY: "GROTTA",
      },
      {
        CITY: "GROTTAFERRATA",
      },
      {
        CITY: "GROTTAGLIE",
      },
      {
        CITY: "GROTTAMINARDA",
      },
      {
        CITY: "GROTTAMMARE",
      },
      {
        CITY: "GROTTAZZOLINA",
      },
      {
        CITY: "GROTTE",
      },
      {
        CITY: "GROTTE DI CASTRO",
      },
      {
        CITY: "GROTTOLELLA",
      },
      {
        CITY: "GRUARO",
      },
      {
        CITY: "GRUGLIASCO",
      },
      {
        CITY: "GRUMELLO CREMONESE",
      },
      {
        CITY: "GRUMELLO DEL MONTE",
      },
      {
        CITY: "GRUMENTO NOVA",
      },
      {
        CITY: "GRUMO APPULA",
      },
      {
        CITY: "GRUMO NEVANO",
      },
      {
        CITY: "GRUMOLO DELLE ABBADESSE",
      },
      {
        CITY: "GUAGNANO",
      },
      {
        CITY: "GUALDO CATTANEO",
      },
      {
        CITY: "GUALDO TADINO",
      },
      {
        CITY: "GUALTIERI",
      },
      {
        CITY: "GUAMAGGIORE",
      },
      {
        CITY: "GUARDA VENETA",
      },
      {
        CITY: "GUARDAMIGLIO",
      },
      {
        CITY: "GUARDAVALLE",
      },
      {
        CITY: "GUARDIA PIEMONTESE",
      },
      {
        CITY: "GUARDIA SANFRAMONDI",
      },
      {
        CITY: "GUARDIAGRELE",
      },
      {
        CITY: "GUARDIALFIERA",
      },
      {
        CITY: "GUARRATO",
      },
      {
        CITY: "GUASILA",
      },
      {
        CITY: "GUASTALLA",
      },
      {
        CITY: "GUBBIO",
      },
      {
        CITY: "GUIDIZZOLO",
      },
      {
        CITY: "GUIDO",
      },
      {
        CITY: "GUIDONIA",
      },
      {
        CITY: "GUIGLIA",
      },
      {
        CITY: "GURRO",
      },
      {
        CITY: "GUSPINI",
      },
      {
        CITY: "GUSSAGO",
      },
      {
        CITY: "GUSTI",
      },
      {
        CITY: "IESI",
      },
      {
        CITY: "IGLESIAS",
      },
      {
        CITY: "IL CIOCCO",
      },
      {
        CITY: "ILLASI",
      },
      {
        CITY: "IMOLA",
      },
      {
        CITY: "IMPERIA",
      },
      {
        CITY: "IMPRUNETA",
      },
      {
        CITY: "INDUNO OLONA",
      },
      {
        CITY: "INNICHEN",
      },
      {
        CITY: "INVERIGO",
      },
      {
        CITY: "INVERUNO",
      },
      {
        CITY: "INZAGO",
      },
      {
        CITY: "ISCHIA",
      },
      {
        CITY: "ISCHIA DI CASTRO",
      },
      {
        CITY: "ISCHITELLA",
      },
      {
        CITY: "ISEO",
      },
      {
        CITY: "ISERNIA",
      },
      {
        CITY: "ISILI",
      },
      {
        CITY: "ISOLA",
      },
      {
        CITY: "ISOLA DEL CANTONE",
      },
      {
        CITY: "ISOLA DEL GIGLIO",
      },
      {
        CITY: "ISOLA DEL GRAN SASSO",
      },
      {
        CITY: "ISOLA DEL LIRI",
      },
      {
        CITY: "ISOLA DELLA SCALA",
      },
      {
        CITY: "ISOLA DELLE FEMMINE",
      },
      {
        CITY: "ISOLA DOVARESE",
      },
      {
        CITY: "ISOLA VICENTINA",
      },
      {
        CITY: "ISORELLA",
      },
      {
        CITY: "ISPICA",
      },
      {
        CITY: "ISPRA",
      },
      {
        CITY: "ISSIME",
      },
      {
        CITY: "ISTRANA",
      },
      {
        CITY: "ITALA",
      },
      {
        CITY: "ITRI",
      },
      {
        CITY: "ITTIRI",
      },
      {
        CITY: "IVREA",
      },
      {
        CITY: "IZANO",
      },
      {
        CITY: "JESOLO",
      },
      {
        CITY: "JOLANDA DI SAVOIA",
      },
      {
        CITY: "KLAUSEN",
      },
      {
        CITY: "LA GALA",
      },
      {
        CITY: "LA LOGGIA",
      },
      {
        CITY: "LA MADDALENA",
      },
      {
        CITY: "LA MORRA",
      },
      {
        CITY: "LA SALLE",
      },
      {
        CITY: "LA SALUTE DI LIVENZA",
      },
      {
        CITY: "LA SPEZIA",
      },
      {
        CITY: "LA VALLE AGORDINA",
      },
      {
        CITY: "LA VIA",
      },
      {
        CITY: "LA VILLA",
      },
      {
        CITY: "LABICO",
      },
      {
        CITY: "LACCHIARELLA",
      },
      {
        CITY: "LACCO AMENO",
      },
      {
        CITY: "LADISPOLI",
      },
      {
        CITY: "LAGO",
      },
      {
        CITY: "LAGO",
      },
      {
        CITY: "LAGO",
      },
      {
        CITY: "LAGOSANTO",
      },
      {
        CITY: "LAIGUEGLIA",
      },
      {
        CITY: "LAINATE",
      },
      {
        CITY: "LAINO BORGO",
      },
      {
        CITY: "LAIVES",
      },
      {
        CITY: "LAJATICO",
      },
      {
        CITY: "LAJEN",
      },
      {
        CITY: "LAKE BOLSENA",
      },
      {
        CITY: "LALLIO",
      },
      {
        CITY: "LAMA MOCOGNO",
      },
      {
        CITY: "LAMMARI",
      },
      {
        CITY: "LAMON",
      },
      {
        CITY: "LAMPEDUSA",
      },
      {
        CITY: "LAMPORECCHIO",
      },
      {
        CITY: "LANA",
      },
      {
        CITY: "LANCENIGO",
      },
      {
        CITY: "LANCENIGO-VILLORBA",
      },
      {
        CITY: "LANCIANO",
      },
      {
        CITY: "LANDRIANO",
      },
      {
        CITY: "LANGHIRANO",
      },
      {
        CITY: "LANUSEI",
      },
      {
        CITY: "LANUVIO",
      },
      {
        CITY: "LANZO TORINESE",
      },
      {
        CITY: "LAPIO",
      },
      {
        CITY: "LAPPATO",
      },
      {
        CITY: "LARCIANO",
      },
      {
        CITY: "LARDERELLO",
      },
      {
        CITY: "LARDIRAGO",
      },
      {
        CITY: "LARI",
      },
      {
        CITY: "LARIANO",
      },
      {
        CITY: "LARINO",
      },
      {
        CITY: "LASINO",
      },
      {
        CITY: "LASTRA",
      },
      {
        CITY: "LASTRA A SIGNA",
      },
      {
        CITY: "LATERINA",
      },
      {
        CITY: "LATERZA",
      },
      {
        CITY: "LATIANO",
      },
      {
        CITY: "LATINA",
      },
      {
        CITY: "LATISANA",
      },
      {
        CITY: "LATRONICO",
      },
      {
        CITY: "LAUREANA DI BORRELLO",
      },
      {
        CITY: "LAURIA",
      },
      {
        CITY: "LAURO",
      },
      {
        CITY: "LAVAGNA",
      },
      {
        CITY: "LAVELLO",
      },
      {
        CITY: "LAVENA PONTE TRESA",
      },
      {
        CITY: "LAVENO-MOMBELLO",
      },
      {
        CITY: "LAVIS",
      },
      {
        CITY: "LAZISE",
      },
      {
        CITY: "LAZZARO",
      },
      {
        CITY: "LE CASTELLA",
      },
      {
        CITY: "LECCE",
      },
      {
        CITY: "LECCO",
      },
      {
        CITY: "LEFFE",
      },
      {
        CITY: "LEGNAGO",
      },
      {
        CITY: "LEGNANO",
      },
      {
        CITY: "LEGNARO",
      },
      {
        CITY: "LENDINARA",
      },
      {
        CITY: "LENNA",
      },
      {
        CITY: "LENNO",
      },
      {
        CITY: "LENO",
      },
      {
        CITY: "LENOLA",
      },
      {
        CITY: "LENTATE SUL SEVESO",
      },
      {
        CITY: "LENTIAI",
      },
      {
        CITY: "LENTINI",
      },
      {
        CITY: "LEONESSA",
      },
      {
        CITY: "LEONFORTE",
      },
      {
        CITY: "LEPORANO",
      },
      {
        CITY: "LERCARA FRIDDI",
      },
      {
        CITY: "LERICI",
      },
      {
        CITY: "LERMA",
      },
      {
        CITY: "LESA",
      },
      {
        CITY: "LESEGNO",
      },
      {
        CITY: "LESINA",
      },
      {
        CITY: "LESSONA",
      },
      {
        CITY: "LESTIZZA",
      },
      {
        CITY: "LETTOMANOPPELLO",
      },
      {
        CITY: "LETTOPALENA",
      },
      {
        CITY: "LEVANE",
      },
      {
        CITY: "LEVANTO",
      },
      {
        CITY: "LEVATA",
      },
      {
        CITY: "LEVERANO",
      },
      {
        CITY: "LEVICO TERME",
      },
      {
        CITY: "LEZZENO",
      },
      {
        CITY: "LIBRIZZI",
      },
      {
        CITY: "LICATA",
      },
      {
        CITY: "LICCIANA NARDI",
      },
      {
        CITY: "LIDO",
      },
      {
        CITY: "LIDO",
      },
      {
        CITY: "LIDO DI CAMAIORE",
      },
      {
        CITY: "LIDO DI OSTIA",
      },
      {
        CITY: "LIERNA",
      },
      {
        CITY: "LIGNANO SABBIADORO",
      },
      {
        CITY: "LIMANA",
      },
      {
        CITY: "LIMATOLA",
      },
      {
        CITY: "LIMBIATE",
      },
      {
        CITY: "LIMENA",
      },
      {
        CITY: "LIMITE",
      },
      {
        CITY: "LIMITI DI GRECCIO",
      },
      {
        CITY: "LIMONE PIEMONTE",
      },
      {
        CITY: "LINGOTTO",
      },
      {
        CITY: "LIONI",
      },
      {
        CITY: "LIPARI",
      },
      {
        CITY: "LISCATE",
      },
      {
        CITY: "LISSONE",
      },
      {
        CITY: "LIVERI",
      },
      {
        CITY: "LIVIGNO",
      },
      {
        CITY: "LIVINALLONGO DEL COL DI LANA",
      },
      {
        CITY: "LIVORNO",
      },
      {
        CITY: "LIVORNO FERRARIS",
      },
      {
        CITY: "LIZZANELLO",
      },
      {
        CITY: "LIZZANO",
      },
      {
        CITY: "LIZZOLA",
      },
      {
        CITY: "LOANO",
      },
      {
        CITY: "LOCALITA DELLA CHIESA",
      },
      {
        CITY: "LOCATE DI TRIULZI",
      },
      {
        CITY: "LOCATI",
      },
      {
        CITY: "LOCOROTONDO",
      },
      {
        CITY: "LOCRI",
      },
      {
        CITY: "LODI",
      },
      {
        CITY: "LODI VECCHIO",
      },
      {
        CITY: "LOGRATO",
      },
      {
        CITY: "LOIANO",
      },
      {
        CITY: "LOMAGNA",
      },
      {
        CITY: "LOMAZZO",
      },
      {
        CITY: "LOMBARDORE",
      },
      {
        CITY: "LOMELLO",
      },
      {
        CITY: "LONATE CEPPINO",
      },
      {
        CITY: "LONATE POZZOLO",
      },
      {
        CITY: "LONATO",
      },
      {
        CITY: "LONGARE",
      },
      {
        CITY: "LONGARONE",
      },
      {
        CITY: "LONGIANO",
      },
      {
        CITY: "LONGOBARDI",
      },
      {
        CITY: "LONIGO",
      },
      {
        CITY: "LOREGGIA",
      },
      {
        CITY: "LOREO",
      },
      {
        CITY: "LORETO",
      },
      {
        CITY: "LORETO APRUTINO",
      },
      {
        CITY: "LORIA",
      },
      {
        CITY: "LORO CIUFFENNA",
      },
      {
        CITY: "LORO PICENO",
      },
      {
        CITY: "LOVERE",
      },
      {
        CITY: "LOZZO ATESTINO",
      },
      {
        CITY: "LOZZO DI CADORE",
      },
      {
        CITY: "LUCA",
      },
      {
        CITY: "LUCCA",
      },
      {
        CITY: "LUCERA",
      },
      {
        CITY: "LUCIANA",
      },
      {
        CITY: "LUCIANO",
      },
      {
        CITY: "LUCINO",
      },
      {
        CITY: "LUCITO",
      },
      {
        CITY: "LUCO DEI MARSI",
      },
      {
        CITY: "LUCUGNANO",
      },
      {
        CITY: "LUGAGNANO",
      },
      {
        CITY: "LUGNANO",
      },
      {
        CITY: "LUGNANO IN TEVERINA",
      },
      {
        CITY: "LUGO",
      },
      {
        CITY: "LUINO",
      },
      {
        CITY: "LUNAMATRONA",
      },
      {
        CITY: "LUNGAVILLA",
      },
      {
        CITY: "LUNGRO",
      },
      {
        CITY: "LURAGO MARINONE",
      },
      {
        CITY: "LURATE CACCIVIO",
      },
      {
        CITY: "LUSTRA",
      },
      {
        CITY: "LUTAGO",
      },
      {
        CITY: "LUZZANO",
      },
      {
        CITY: "LUZZI",
      },
      {
        CITY: "MACCAGNO",
      },
      {
        CITY: "MACCARESE",
      },
      {
        CITY: "MACELLO",
      },
      {
        CITY: "MACERATA",
      },
      {
        CITY: "MACERATA CAMPANIA",
      },
      {
        CITY: "MACOMER",
      },
      {
        CITY: "MADDALENA DI CAZZANO",
      },
      {
        CITY: "MADDALONI",
      },
      {
        CITY: "MADERNO",
      },
      {
        CITY: "MADIGNANO",
      },
      {
        CITY: "MADONNA",
      },
      {
        CITY: "MADONNA DI CAMPIGLIO",
      },
      {
        CITY: "MAERNE",
      },
      {
        CITY: "MAFALDA",
      },
      {
        CITY: "MAGENTA",
      },
      {
        CITY: "MAGGIO",
      },
      {
        CITY: "MAGIONE",
      },
      {
        CITY: "MAGLIANO",
      },
      {
        CITY: "MAGLIANO",
      },
      {
        CITY: "MAGLIANO ALPI",
      },
      {
        CITY: "MAGLIANO DI TENNA",
      },
      {
        CITY: "MAGLIANO IN TOSCANA",
      },
      {
        CITY: "MAGLIANO SABINA",
      },
      {
        CITY: "MAGLIE",
      },
      {
        CITY: "MAGNAGO",
      },
      {
        CITY: "MAGNO",
      },
      {
        CITY: "MAGRETA",
      },
      {
        CITY: "MAIERATO",
      },
      {
        CITY: "MAIOLATI SPONTINI",
      },
      {
        CITY: "MAIRANO",
      },
      {
        CITY: "MALALBERGO",
      },
      {
        CITY: "MALCESINE",
      },
      {
        CITY: "MALCONTENTA",
      },
      {
        CITY: "MALETTO",
      },
      {
        CITY: "MALGRATE",
      },
      {
        CITY: "MALNATE",
      },
      {
        CITY: "MALO",
      },
      {
        CITY: "MALONNO",
      },
      {
        CITY: "MALS",
      },
      {
        CITY: "MALTIGNANO",
      },
      {
        CITY: "MAMMOLA",
      },
      {
        CITY: "MANDAS",
      },
      {
        CITY: "MANDELLO DEL LARIO",
      },
      {
        CITY: "MANDURIA",
      },
      {
        CITY: "MANERBIO",
      },
      {
        CITY: "MANFREDONIA",
      },
      {
        CITY: "MANIAGO",
      },
      {
        CITY: "MANOCALZATI",
      },
      {
        CITY: "MANOPPELLO",
      },
      {
        CITY: "MANTA",
      },
      {
        CITY: "MANTOVA",
      },
      {
        CITY: "MANTOVANA",
      },
      {
        CITY: "MANZANO",
      },
      {
        CITY: "MANZIANA",
      },
      {
        CITY: "MANZONI",
      },
      {
        CITY: "MAPELLO",
      },
      {
        CITY: "MARANELLO",
      },
      {
        CITY: "MARANO DI NAPOLI",
      },
      {
        CITY: "MARANO TICINO",
      },
      {
        CITY: "MARANO VICENTINO",
      },
      {
        CITY: "MARATEA",
      },
      {
        CITY: "MARCALLO CON CASONE",
      },
      {
        CITY: "MARCARIA",
      },
      {
        CITY: "MARCELLINA",
      },
      {
        CITY: "MARCHE",
      },
      {
        CITY: "MARCIANA MARINA",
      },
      {
        CITY: "MARCIANISE",
      },
      {
        CITY: "MARCO",
      },
      {
        CITY: "MARCON",
      },
      {
        CITY: "MARENO DI PIAVE",
      },
      {
        CITY: "MAREO",
      },
      {
        CITY: "MARESCA",
      },
      {
        CITY: "MARGARITA",
      },
      {
        CITY: "MARGHERA",
      },
      {
        CITY: "MARGHERITA DI SAVOIA",
      },
      {
        CITY: "MARIAE",
      },
      {
        CITY: "MARIANO",
      },
      {
        CITY: "MARIANO COMENSE",
      },
      {
        CITY: "MARIGLIANELLA",
      },
      {
        CITY: "MARIGLIANO",
      },
      {
        CITY: "MARIGNANO",
      },
      {
        CITY: "MARINA",
      },
      {
        CITY: "MARINA DI ANDORA",
      },
      {
        CITY: "MARINA DI CAMPO",
      },
      {
        CITY: "MARINA DI CARRARA",
      },
      {
        CITY: "MARINA DI CASTAGNETO CARDUCCI",
      },
      {
        CITY: "MARINA DI CERVETERI",
      },
      {
        CITY: "MARINA DI GINOSA",
      },
      {
        CITY: "MARINA DI GIOIOSA IONICA",
      },
      {
        CITY: "MARINA DI RAGUSA",
      },
      {
        CITY: "MARINEO",
      },
      {
        CITY: "MARINI",
      },
      {
        CITY: "MARINO",
      },
      {
        CITY: "MARLIA",
      },
      {
        CITY: "MARMIROLO",
      },
      {
        CITY: "MARNATE",
      },
      {
        CITY: "MAROLA",
      },
      {
        CITY: "MARONE",
      },
      {
        CITY: "MAROSTICA",
      },
      {
        CITY: "MAROTTA",
      },
      {
        CITY: "MARRUBIU",
      },
      {
        CITY: "MARSALA",
      },
      {
        CITY: "MARSCIANO",
      },
      {
        CITY: "MARSICO NUOVO",
      },
      {
        CITY: "MARTA",
      },
      {
        CITY: "MARTANO",
      },
      {
        CITY: "MARTELLAGO",
      },
      {
        CITY: "MARTIGNACCO",
      },
      {
        CITY: "MARTIGNANO",
      },
      {
        CITY: "MARTINA FRANCA",
      },
      {
        CITY: "MARTINENGO",
      },
      {
        CITY: "MARTINIANA PO",
      },
      {
        CITY: "MARTINSICURO",
      },
      {
        CITY: "MARTIRANO",
      },
      {
        CITY: "MARUGGIO",
      },
      {
        CITY: "MARZABOTTO",
      },
      {
        CITY: "MARZANO",
      },
      {
        CITY: "MARZANO DI NOLA",
      },
      {
        CITY: "MAS",
      },
      {
        CITY: "MASAINAS",
      },
      {
        CITY: "MASCALI",
      },
      {
        CITY: "MASCALUCIA",
      },
      {
        CITY: "MASER",
      },
      {
        CITY: "MASERA",
      },
      {
        CITY: "MASERADA SUL PIAVE",
      },
      {
        CITY: "MASI TORELLO",
      },
      {
        CITY: "MASLIANICO",
      },
      {
        CITY: "MASON",
      },
      {
        CITY: "MASSA",
      },
      {
        CITY: "MASSA",
      },
      {
        CITY: "MASSA",
      },
      {
        CITY: "MASSA",
      },
      {
        CITY: "MASSA E COZZILE",
      },
      {
        CITY: "MASSA FISCAGLIA",
      },
      {
        CITY: "MASSA LOMBARDA",
      },
      {
        CITY: "MASSA MARITTIMA",
      },
      {
        CITY: "MASSA MARTANA",
      },
      {
        CITY: "MASSAFRA",
      },
      {
        CITY: "MASSAROSA",
      },
      {
        CITY: "MASSERANO",
      },
      {
        CITY: "MASSIGNANO",
      },
      {
        CITY: "MASULLAS",
      },
      {
        CITY: "MATELICA",
      },
      {
        CITY: "MATERA",
      },
      {
        CITY: "MATHI",
      },
      {
        CITY: "MATINO",
      },
      {
        CITY: "MAURO",
      },
      {
        CITY: "MAZARA DEL VALLO",
      },
      {
        CITY: "MAZZANO ROMANO",
      },
      {
        CITY: "MAZZARINO",
      },
      {
        CITY: "MAZZO DI VALTELLINA",
      },
      {
        CITY: "MEANA SARDO",
      },
      {
        CITY: "MEANO",
      },
      {
        CITY: "MEDA",
      },
      {
        CITY: "MEDE",
      },
      {
        CITY: "MEDEA",
      },
      {
        CITY: "MEDESANO",
      },
      {
        CITY: "MEDICINA",
      },
      {
        CITY: "MEDOLAGO",
      },
      {
        CITY: "MEDOLE",
      },
      {
        CITY: "MEDOLLA",
      },
      {
        CITY: "MEDUNA DI LIVENZA",
      },
      {
        CITY: "MEL",
      },
      {
        CITY: "MELARA",
      },
      {
        CITY: "MELDOLA",
      },
      {
        CITY: "MELEGNANO",
      },
      {
        CITY: "MELENDUGNO",
      },
      {
        CITY: "MELFI",
      },
      {
        CITY: "MELICUCCO",
      },
      {
        CITY: "MELILLI",
      },
      {
        CITY: "MELISSANO",
      },
      {
        CITY: "MELITO DI PORTO SALVO",
      },
      {
        CITY: "MELIZZANO",
      },
      {
        CITY: "MELZO",
      },
      {
        CITY: "MENAGGIO",
      },
      {
        CITY: "MENDICINO",
      },
      {
        CITY: "MENFI",
      },
      {
        CITY: "MENTANA",
      },
      {
        CITY: "MEOLO",
      },
      {
        CITY: "MERAN",
      },
      {
        CITY: "MERANO",
      },
      {
        CITY: "MERATE",
      },
      {
        CITY: "MERCATINO CONCA",
      },
      {
        CITY: "MERCATO",
      },
      {
        CITY: "MERCATO SAN SEVERINO",
      },
      {
        CITY: "MERCATO SARACENO",
      },
      {
        CITY: "MERCENASCO",
      },
      {
        CITY: "MERCOGLIANO",
      },
      {
        CITY: "MERETO DI TOMBA",
      },
      {
        CITY: "MERGO",
      },
      {
        CITY: "MERGOZZO",
      },
      {
        CITY: "MESAGNE",
      },
      {
        CITY: "MESOLA",
      },
      {
        CITY: "MESORACA",
      },
      {
        CITY: "MESSINA",
      },
      {
        CITY: "MESTRE",
      },
      {
        CITY: "MESTRINO",
      },
      {
        CITY: "META",
      },
      {
        CITY: "METAPONTO",
      },
      {
        CITY: "MEZZANEGO",
      },
      {
        CITY: "MEZZANO",
      },
      {
        CITY: "MEZZOCORONA",
      },
      {
        CITY: "MEZZOLARA",
      },
      {
        CITY: "MEZZOLOMBARDO",
      },
      {
        CITY: "MEZZOMERICO",
      },
      {
        CITY: "MIGLIANICO",
      },
      {
        CITY: "MIGLIARINO",
      },
      {
        CITY: "MIGLIARINO",
      },
      {
        CITY: "MIGLIARO",
      },
      {
        CITY: "MIGLIONICO",
      },
      {
        CITY: "MIGNANEGO",
      },
      {
        CITY: "MIGNANO MONTE LUNGO",
      },
      {
        CITY: "MILAN",
      },
      {
        CITY: "MILANESI",
      },
      {
        CITY: "MILANO",
      },
      {
        CITY: "MILANO MARITTIMA",
      },
      {
        CITY: "MILAZZO",
      },
      {
        CITY: "MILENA",
      },
      {
        CITY: "MILITELLO IN VAL DI CATANIA",
      },
      {
        CITY: "MILITELLO ROSMARINO",
      },
      {
        CITY: "MILLESIMO",
      },
      {
        CITY: "MILO",
      },
      {
        CITY: "MINEO",
      },
      {
        CITY: "MINERBE",
      },
      {
        CITY: "MINERBIO",
      },
      {
        CITY: "MINERVINO DI LECCE",
      },
      {
        CITY: "MINERVINO MURGE",
      },
      {
        CITY: "MINORI",
      },
      {
        CITY: "MINTURNO",
      },
      {
        CITY: "MIRA",
      },
      {
        CITY: "MIRABELLA",
      },
      {
        CITY: "MIRABELLA ECLANO",
      },
      {
        CITY: "MIRABELLA IMBACCARI",
      },
      {
        CITY: "MIRABELLO",
      },
      {
        CITY: "MIRABELLO MONFERRATO",
      },
      {
        CITY: "MIRADOLO TERME",
      },
      {
        CITY: "MIRANDOLA",
      },
      {
        CITY: "MIRANO",
      },
      {
        CITY: "MIRAVALLE",
      },
      {
        CITY: "MISANO ADRIATICO",
      },
      {
        CITY: "MISILMERI",
      },
      {
        CITY: "MISINTO",
      },
      {
        CITY: "MISSAGLIA",
      },
      {
        CITY: "MISTERBIANCO",
      },
      {
        CITY: "MISTRETTA",
      },
      {
        CITY: "MODENA",
      },
      {
        CITY: "MODICA",
      },
      {
        CITY: "MODIGLIANA",
      },
      {
        CITY: "MODUGNO",
      },
      {
        CITY: "MOENA",
      },
      {
        CITY: "MOGLIA",
      },
      {
        CITY: "MOGLIANO",
      },
      {
        CITY: "MOGLIANO VENETO",
      },
      {
        CITY: "MOGORO",
      },
      {
        CITY: "MOIANO",
      },
      {
        CITY: "MOIMACCO",
      },
      {
        CITY: "MOIO DELLA CIVITELLA",
      },
      {
        CITY: "MOJO ALCANTARA",
      },
      {
        CITY: "MOLA DI BARI",
      },
      {
        CITY: "MOLAZZANA",
      },
      {
        CITY: "MOLFETTA",
      },
      {
        CITY: "MOLINARI",
      },
      {
        CITY: "MOLINELLA",
      },
      {
        CITY: "MOLINO",
      },
      {
        CITY: "MOLISE",
      },
      {
        CITY: "MOLITERNO",
      },
      {
        CITY: "MOMBERCELLI",
      },
      {
        CITY: "MOMO",
      },
      {
        CITY: "MONACO",
      },
      {
        CITY: "MONASTERACE",
      },
      {
        CITY: "MONASTERO",
      },
      {
        CITY: "MONASTEROLO DEL CASTELLO",
      },
      {
        CITY: "MONASTIR",
      },
      {
        CITY: "MONCALIERI",
      },
      {
        CITY: "MONCALVO",
      },
      {
        CITY: "MONDAVIO",
      },
      {
        CITY: "MONDOLFO",
      },
      {
        CITY: "MONDRAGONE",
      },
      {
        CITY: "MONEGLIA",
      },
      {
        CITY: "MONFALCONE",
      },
      {
        CITY: "MONFORTE SAN GIORGIO",
      },
      {
        CITY: "MONGHIDORO",
      },
      {
        CITY: "MONGRANDO",
      },
      {
        CITY: "MONIGA DEL GARDA",
      },
      {
        CITY: "MONOPOLI",
      },
      {
        CITY: "MONREALE",
      },
      {
        CITY: "MONSAMPIETRO MORICO",
      },
      {
        CITY: "MONSAMPOLO DEL TRONTO",
      },
      {
        CITY: "MONSANO",
      },
      {
        CITY: "MONSELICE",
      },
      {
        CITY: "MONSERRATO",
      },
      {
        CITY: "MONSUMMANO TERME",
      },
      {
        CITY: "MONTAFIA",
      },
      {
        CITY: "MONTAGNANA",
      },
      {
        CITY: "MONTALBANO",
      },
      {
        CITY: "MONTALBANO JONICO",
      },
      {
        CITY: "MONTALCINO",
      },
      {
        CITY: "MONTALDO",
      },
      {
        CITY: "MONTALE",
      },
      {
        CITY: "MONTALENGHE",
      },
      {
        CITY: "MONTALTO DELLE MARCHE",
      },
      {
        CITY: "MONTALTO DI CASTRO",
      },
      {
        CITY: "MONTALTO DORA",
      },
      {
        CITY: "MONTALTO UFFUGO",
      },
      {
        CITY: "MONTANARA",
      },
      {
        CITY: "MONTANARO",
      },
      {
        CITY: "MONTANASO LOMBARDO",
      },
      {
        CITY: "MONTAPPONE",
      },
      {
        CITY: "MONTE",
      },
      {
        CITY: "MONTE",
      },
      {
        CITY: "MONTE CASTELLO DI VIBIO",
      },
      {
        CITY: "MONTE COMPATRI",
      },
      {
        CITY: "MONTE DI MALO",
      },
      {
        CITY: "MONTE DI PROCIDA",
      },
      {
        CITY: "MONTE GIBERTO",
      },
      {
        CITY: "MONTE PORZIO CATONE",
      },
      {
        CITY: "MONTE ROBERTO",
      },
      {
        CITY: "MONTE SAN GIOVANNI CAMPANO",
      },
      {
        CITY: "MONTE SAN GIUSTO",
      },
      {
        CITY: "MONTE SAN PIETRO",
      },
      {
        CITY: "MONTE SAN SAVINO",
      },
      {
        CITY: "MONTE SAN VITO",
      },
      {
        CITY: "MONTE URANO",
      },
      {
        CITY: "MONTE VIDON CORRADO",
      },
      {
        CITY: "MONTEBELLO",
      },
      {
        CITY: "MONTEBELLO VICENTINO",
      },
      {
        CITY: "MONTEBELLUNA",
      },
      {
        CITY: "MONTECALVOLI",
      },
      {
        CITY: "MONTECARLO",
      },
      {
        CITY: "MONTECAROTTO",
      },
      {
        CITY: "MONTECASSIANO",
      },
      {
        CITY: "MONTECASTRILLI",
      },
      {
        CITY: "MONTECATINI TERME",
      },
      {
        CITY: "MONTECCHIA DI CROSARA",
      },
      {
        CITY: "MONTECCHIO",
      },
      {
        CITY: "MONTECCHIO",
      },
      {
        CITY: "MONTECCHIO",
      },
      {
        CITY: "MONTECCHIO EMILIA",
      },
      {
        CITY: "MONTECCHIO MAGGIORE",
      },
      {
        CITY: "MONTECHIARUGOLO",
      },
      {
        CITY: "MONTECORICE",
      },
      {
        CITY: "MONTECORVINO ROVELLA",
      },
      {
        CITY: "MONTECOSARO",
      },
      {
        CITY: "MONTEFALCIONE",
      },
      {
        CITY: "MONTEFANO",
      },
      {
        CITY: "MONTEFIASCONE",
      },
      {
        CITY: "MONTEFIORE CONCA",
      },
      {
        CITY: "MONTEFIORINO",
      },
      {
        CITY: "MONTEFORTE IRPINO",
      },
      {
        CITY: "MONTEFORTINO",
      },
      {
        CITY: "MONTEFRANCO",
      },
      {
        CITY: "MONTEFREDANE",
      },
      {
        CITY: "MONTEGALDELLA",
      },
      {
        CITY: "MONTEGIORGIO",
      },
      {
        CITY: "MONTEGRANARO",
      },
      {
        CITY: "MONTEGROTTO TERME",
      },
      {
        CITY: "MONTEIASI",
      },
      {
        CITY: "MONTELABBATE",
      },
      {
        CITY: "MONTELANICO",
      },
      {
        CITY: "MONTELEPRE",
      },
      {
        CITY: "MONTELPARO",
      },
      {
        CITY: "MONTELUPO FIORENTINO",
      },
      {
        CITY: "MONTEMAGGIORE AL METAURO",
      },
      {
        CITY: "MONTEMARANO",
      },
      {
        CITY: "MONTEMARCIANO",
      },
      {
        CITY: "MONTEMASSI",
      },
      {
        CITY: "MONTEMILETTO",
      },
      {
        CITY: "MONTEMURLO",
      },
      {
        CITY: "MONTEMURRO",
      },
      {
        CITY: "MONTENERO DI BISACCIA",
      },
      {
        CITY: "MONTENERODOMO",
      },
      {
        CITY: "MONTEODORISIO",
      },
      {
        CITY: "MONTEPAONE",
      },
      {
        CITY: "MONTEPIANO",
      },
      {
        CITY: "MONTEPRANDONE",
      },
      {
        CITY: "MONTEPULCIANO",
      },
      {
        CITY: "MONTEPULCIANO STAZIONE",
      },
      {
        CITY: "MONTERCHI",
      },
      {
        CITY: "MONTEREALE",
      },
      {
        CITY: "MONTEREALE VALCELLINA",
      },
      {
        CITY: "MONTERENZIO",
      },
      {
        CITY: "MONTERIGGIONI",
      },
      {
        CITY: "MONTERODUNI",
      },
      {
        CITY: "MONTERONI DI LECCE",
      },
      {
        CITY: "MONTEROSI",
      },
      {
        CITY: "MONTEROSSO AL MARE",
      },
      {
        CITY: "MONTEROSSO CALABRO",
      },
      {
        CITY: "MONTEROTONDO",
      },
      {
        CITY: "MONTERUBBIANO",
      },
      {
        CITY: "MONTESANO SALENTINO",
      },
      {
        CITY: "MONTESANO SULLA MARCELLANA",
      },
      {
        CITY: "MONTESARCHIO",
      },
      {
        CITY: "MONTESCAGLIOSO",
      },
      {
        CITY: "MONTESCUDO",
      },
      {
        CITY: "MONTESE",
      },
      {
        CITY: "MONTESILVANO COLLE",
      },
      {
        CITY: "MONTESPERTOLI",
      },
      {
        CITY: "MONTEVAGO",
      },
      {
        CITY: "MONTEVARCHI",
      },
      {
        CITY: "MONTEVEGLIO",
      },
      {
        CITY: "MONTEVERDE",
      },
      {
        CITY: "MONTEVIALE",
      },
      {
        CITY: "MONTICELLI",
      },
      {
        CITY: "MONTICELLI",
      },
      {
        CITY: "MONTICELLO",
      },
      {
        CITY: "MONTICELLO CONTE OTTO",
      },
      {
        CITY: "MONTICHIARI",
      },
      {
        CITY: "MONTICIANO",
      },
      {
        CITY: "MONTIGNOSO",
      },
      {
        CITY: "MONTODINE",
      },
      {
        CITY: "MONTOGGIO",
      },
      {
        CITY: "MONTORIO AL VOMANO",
      },
      {
        CITY: "MONTORO",
      },
      {
        CITY: "MONTORSO VICENTINO",
      },
      {
        CITY: "MONZA",
      },
      {
        CITY: "MONZAMBANO",
      },
      {
        CITY: "MONZUNO",
      },
      {
        CITY: "MORA",
      },
      {
        CITY: "MORANO CALABRO",
      },
      {
        CITY: "MORBEGNO",
      },
      {
        CITY: "MORCIANO DI LEUCA",
      },
      {
        CITY: "MORCIANO DI ROMAGNA",
      },
      {
        CITY: "MORCONE",
      },
      {
        CITY: "MORDANO",
      },
      {
        CITY: "MORETTA",
      },
      {
        CITY: "MORGANO",
      },
      {
        CITY: "MORGEX",
      },
      {
        CITY: "MORI",
      },
      {
        CITY: "MORICONE",
      },
      {
        CITY: "MORLUPO",
      },
      {
        CITY: "MORNAGO",
      },
      {
        CITY: "MOROLO",
      },
      {
        CITY: "MOROZZO",
      },
      {
        CITY: "MORRONE",
      },
      {
        CITY: "MORROVALLE",
      },
      {
        CITY: "MORTARA",
      },
      {
        CITY: "MORTEGLIANO",
      },
      {
        CITY: "MORTER",
      },
      {
        CITY: "MOSCUFO",
      },
      {
        CITY: "MOSO",
      },
      {
        CITY: "MOSSA",
      },
      {
        CITY: "MOTTA DI LIVENZA",
      },
      {
        CITY: "MOTTA SAN GIOVANNI",
      },
      {
        CITY: "MOTTA SAN GUGLIELMO",
      },
      {
        CITY: "MOTTA VISCONTI",
      },
      {
        CITY: "MOTTAFOLLONE",
      },
      {
        CITY: "MOTTALCIATA",
      },
      {
        CITY: "MOTTOLA",
      },
      {
        CITY: "MOZZAGROGNA",
      },
      {
        CITY: "MOZZANICA",
      },
      {
        CITY: "MOZZATE",
      },
      {
        CITY: "MOZZECANE",
      },
      {
        CITY: "MUGGIA",
      },
      {
        CITY: "MUGNANO",
      },
      {
        CITY: "MUGNANO DEL CARDINALE",
      },
      {
        CITY: "MULAZZANO",
      },
      {
        CITY: "MURANO",
      },
      {
        CITY: "MURAVERA",
      },
      {
        CITY: "MURLO",
      },
      {
        CITY: "MURO LECCESE",
      },
      {
        CITY: "MURO LUCANO",
      },
      {
        CITY: "MUROS",
      },
      {
        CITY: "MUSEI",
      },
      {
        CITY: "MUSILE DI PIAVE",
      },
      {
        CITY: "MUSSOMELI",
      },
      {
        CITY: "NANTO",
      },
      {
        CITY: "NAPLES",
      },
      {
        CITY: "NARBOLIA",
      },
      {
        CITY: "NARCAO",
      },
      {
        CITY: "NARNI",
      },
      {
        CITY: "NARNI SCALO",
      },
      {
        CITY: "NARO",
      },
      {
        CITY: "NATURNS",
      },
      {
        CITY: "NATZ-SCHABS",
      },
      {
        CITY: "NAVACCHIO",
      },
      {
        CITY: "NAVE",
      },
      {
        CITY: "NAVE",
      },
      {
        CITY: "NAZZANO",
      },
      {
        CITY: "NEGRAR",
      },
      {
        CITY: "NEIRONE",
      },
      {
        CITY: "NEIVE",
      },
      {
        CITY: "NEMBRO",
      },
      {
        CITY: "NEPI",
      },
      {
        CITY: "NERETO",
      },
      {
        CITY: "NERVESA DELLA BATTAGLIA",
      },
      {
        CITY: "NERVIANO",
      },
      {
        CITY: "NETTUNO",
      },
      {
        CITY: "NEUMARKT",
      },
      {
        CITY: "NEVIANO",
      },
      {
        CITY: "NEVIANO DEGLI ARDUINI",
      },
      {
        CITY: "NICHELINO",
      },
      {
        CITY: "NICOLA",
      },
      {
        CITY: "NICOLOSI",
      },
      {
        CITY: "NICOSIA",
      },
      {
        CITY: "NICOTERA",
      },
      {
        CITY: "NIEDERDORF",
      },
      {
        CITY: "NIMIS",
      },
      {
        CITY: "NISCEMI",
      },
      {
        CITY: "NIZZA DI SICILIA",
      },
      {
        CITY: "NIZZA MONFERRATO",
      },
      {
        CITY: "NOALE",
      },
      {
        CITY: "NOCCIANO",
      },
      {
        CITY: "NOCELLETO",
      },
      {
        CITY: "NOCERA INFERIORE",
      },
      {
        CITY: "NOCERA SUPERIORE",
      },
      {
        CITY: "NOCERA TERINESE",
      },
      {
        CITY: "NOCETO",
      },
      {
        CITY: "NOCI",
      },
      {
        CITY: "NOCIGLIA",
      },
      {
        CITY: "NOGARA",
      },
      {
        CITY: "NOGAREDO AL TORRE",
      },
      {
        CITY: "NOGAROLE ROCCA",
      },
      {
        CITY: "NOICATTARO",
      },
      {
        CITY: "NOLA",
      },
      {
        CITY: "NOLI",
      },
      {
        CITY: "NONANTOLA",
      },
      {
        CITY: "NONE",
      },
      {
        CITY: "NORCIA",
      },
      {
        CITY: "NORMA",
      },
      {
        CITY: "NOTARESCO",
      },
      {
        CITY: "NOTO",
      },
      {
        CITY: "NOVA MILANESE",
      },
      {
        CITY: "NOVA SIRI",
      },
      {
        CITY: "NOVAFELTRIA",
      },
      {
        CITY: "NOVALESA",
      },
      {
        CITY: "NOVARA",
      },
      {
        CITY: "NOVATE MEZZOLA",
      },
      {
        CITY: "NOVATE MILANESE",
      },
      {
        CITY: "NOVE",
      },
      {
        CITY: "NOVELLARA",
      },
      {
        CITY: "NOVELLO",
      },
      {
        CITY: "NOVENTA DI PIAVE",
      },
      {
        CITY: "NOVENTA VICENTINA",
      },
      {
        CITY: "NOVI DI MODENA",
      },
      {
        CITY: "NOVI LIGURE",
      },
      {
        CITY: "NOVI VELIA",
      },
      {
        CITY: "NOVOLI",
      },
      {
        CITY: "NUMANA",
      },
      {
        CITY: "NUORO",
      },
      {
        CITY: "NURACHI",
      },
      {
        CITY: "NURALLAO",
      },
      {
        CITY: "NURAMINIS",
      },
      {
        CITY: "NURRI",
      },
      {
        CITY: "NUSCO",
      },
      {
        CITY: "NUVOLENTO",
      },
      {
        CITY: "OCCHIEPPO INFERIORE",
      },
      {
        CITY: "OCCHIEPPO SUPERIORE",
      },
      {
        CITY: "OCCHIOBELLO",
      },
      {
        CITY: "OCRE",
      },
      {
        CITY: "ODERZO",
      },
      {
        CITY: "OFFAGNA",
      },
      {
        CITY: "OFFANENGO",
      },
      {
        CITY: "OFFIDA",
      },
      {
        CITY: "OFFLAGA",
      },
      {
        CITY: "OGGIONO",
      },
      {
        CITY: "OGLIANICO",
      },
      {
        CITY: "OLBIA",
      },
      {
        CITY: "OLEGGIO",
      },
      {
        CITY: "OLEVANO ROMANO",
      },
      {
        CITY: "OLEVANO SUL TUSCIANO",
      },
      {
        CITY: "OLGIATE COMASCO",
      },
      {
        CITY: "OLGIATE MOLGORA",
      },
      {
        CITY: "OLGIATE OLONA",
      },
      {
        CITY: "OLGINATE",
      },
      {
        CITY: "OLIENA",
      },
      {
        CITY: "OLIVA",
      },
      {
        CITY: "OLIVERI",
      },
      {
        CITY: "OLIVETO",
      },
      {
        CITY: "OLIVETO CITRA",
      },
      {
        CITY: "OLIVETO LARIO",
      },
      {
        CITY: "OLIVETTA",
      },
      {
        CITY: "OLMEDO",
      },
      {
        CITY: "OMEGNA",
      },
      {
        CITY: "OMIGNANO",
      },
      {
        CITY: "OPERA",
      },
      {
        CITY: "OPPEANO",
      },
      {
        CITY: "OPPIDO LUCANO",
      },
      {
        CITY: "ORANI",
      },
      {
        CITY: "ORATINO",
      },
      {
        CITY: "ORBASSANO",
      },
      {
        CITY: "ORBETELLO",
      },
      {
        CITY: "ORCIANO DI PESARO",
      },
      {
        CITY: "ORGIANO",
      },
      {
        CITY: "ORGOSOLO",
      },
      {
        CITY: "ORIA",
      },
      {
        CITY: "ORIGGIO",
      },
      {
        CITY: "ORIO AL SERIO",
      },
      {
        CITY: "ORIO LITTA",
      },
      {
        CITY: "ORIOLO ROMANO",
      },
      {
        CITY: "ORISTANO",
      },
      {
        CITY: "ORMEA",
      },
      {
        CITY: "ORMELLE",
      },
      {
        CITY: "ORNAGO",
      },
      {
        CITY: "ORNAVASSO",
      },
      {
        CITY: "OROSEI",
      },
      {
        CITY: "OROTELLI",
      },
      {
        CITY: "ORSENIGO",
      },
      {
        CITY: "ORSOGNA",
      },
      {
        CITY: "ORTA NOVA",
      },
      {
        CITY: "ORTA SAN GIULIO",
      },
      {
        CITY: "ORTE",
      },
      {
        CITY: "ORTELLE",
      },
      {
        CITY: "ORTONA",
      },
      {
        CITY: "ORTONOVO",
      },
      {
        CITY: "ORTOVERO",
      },
      {
        CITY: "ORVIETO",
      },
      {
        CITY: "ORZINUOVI",
      },
      {
        CITY: "ORZIVECCHI",
      },
      {
        CITY: "OSIGLIA",
      },
      {
        CITY: "OSIMO",
      },
      {
        CITY: "OSNAGO",
      },
      {
        CITY: "OSOPPO",
      },
      {
        CITY: "OSPEDALETTI",
      },
      {
        CITY: "OSPEDALETTO",
      },
      {
        CITY: "OSPEDALETTO EUGANEO",
      },
      {
        CITY: "OSPEDALETTO-EXPOPISA",
      },
      {
        CITY: "OSPITALETTO",
      },
      {
        CITY: "OSSAGO LODIGIANO",
      },
      {
        CITY: "OSSI",
      },
      {
        CITY: "OSSONA",
      },
      {
        CITY: "OSTIANO",
      },
      {
        CITY: "OSTIGLIA",
      },
      {
        CITY: "OSTIGLIANO",
      },
      {
        CITY: "OSTRA",
      },
      {
        CITY: "OSTRA VETERE",
      },
      {
        CITY: "OSTUNI",
      },
      {
        CITY: "OTRANTO",
      },
      {
        CITY: "OTRICOLI",
      },
      {
        CITY: "OTTAVIANO",
      },
      {
        CITY: "OTTIGLIO",
      },
      {
        CITY: "OULX",
      },
      {
        CITY: "OVADA",
      },
      {
        CITY: "OVARO",
      },
      {
        CITY: "OZEGNA",
      },
      {
        CITY: "OZIERI",
      },
      {
        CITY: "OZZANO MONFERRATO",
      },
      {
        CITY: "OZZERO",
      },
      {
        CITY: "PACE",
      },
      {
        CITY: "PACECO",
      },
      {
        CITY: "PACENTRO",
      },
      {
        CITY: "PACHINO",
      },
      {
        CITY: "PACIANO",
      },
      {
        CITY: "PADERNO",
      },
      {
        CITY: "PADERNO DUGNANO",
      },
      {
        CITY: "PADERNO FRANCIACORTA",
      },
      {
        CITY: "PADERNO PONCHIELLI",
      },
      {
        CITY: "PADUA",
      },
      {
        CITY: "PADULA",
      },
      {
        CITY: "PAESANA",
      },
      {
        CITY: "PAESE",
      },
      {
        CITY: "PAGANI",
      },
      {
        CITY: "PAGANICA",
      },
      {
        CITY: "PAGAZZANO",
      },
      {
        CITY: "PAGLIETA",
      },
      {
        CITY: "PAGO VEIANO",
      },
      {
        CITY: "PALAGIANELLO",
      },
      {
        CITY: "PALAGIANO",
      },
      {
        CITY: "PALAGONIA",
      },
      {
        CITY: "PALAIA",
      },
      {
        CITY: "PALAU",
      },
      {
        CITY: "PALAZZINA",
      },
      {
        CITY: "PALAZZO",
      },
      {
        CITY: "PALAZZO",
      },
      {
        CITY: "PALAZZO CANAVESE",
      },
      {
        CITY: "PALAZZOLO",
      },
      {
        CITY: "PALAZZOLO ACREIDE",
      },
      {
        CITY: "PALAZZOLO DELLO STELLA",
      },
      {
        CITY: "PALERMO",
      },
      {
        CITY: "PALESTRINA",
      },
      {
        CITY: "PALIANO",
      },
      {
        CITY: "PALIZZI MARINA",
      },
      {
        CITY: "PALLADIO",
      },
      {
        CITY: "PALLANZA-INTRA-SUNA",
      },
      {
        CITY: "PALLANZENO",
      },
      {
        CITY: "PALLERONE",
      },
      {
        CITY: "PALMA CAMPANIA",
      },
      {
        CITY: "PALMA DI MONTECHIARO",
      },
      {
        CITY: "PALMANOVA",
      },
      {
        CITY: "PALMARIGGI",
      },
      {
        CITY: "PALMAS ARBOREA",
      },
      {
        CITY: "PALMI",
      },
      {
        CITY: "PALO DEL COLLE",
      },
      {
        CITY: "PALOMBARA SABINA",
      },
      {
        CITY: "PALOSCO",
      },
      {
        CITY: "PALUZZA",
      },
      {
        CITY: "PANARO",
      },
      {
        CITY: "PANCALIERI",
      },
      {
        CITY: "PANDINO",
      },
      {
        CITY: "PANICALE",
      },
      {
        CITY: "PANNARANO",
      },
      {
        CITY: "PANTANO",
      },
      {
        CITY: "PANTIGLIATE",
      },
      {
        CITY: "PANZANO",
      },
      {
        CITY: "PAOLA",
      },
      {
        CITY: "PARABIAGO",
      },
      {
        CITY: "PARABITA",
      },
      {
        CITY: "PARATICO",
      },
      {
        CITY: "PARGHELIA",
      },
      {
        CITY: "PARIGI",
      },
      {
        CITY: "PARMA",
      },
      {
        CITY: "PARRE",
      },
      {
        CITY: "PARTANNA",
      },
      {
        CITY: "PARTINICO",
      },
      {
        CITY: "PARUZZARO",
      },
      {
        CITY: "PASIAN DI PRATO",
      },
      {
        CITY: "PASSARELLA",
      },
      {
        CITY: "PASSETTO",
      },
      {
        CITY: "PASSIGNANO SUL TRASIMENO",
      },
      {
        CITY: "PASSIRANO",
      },
      {
        CITY: "PASSO",
      },
      {
        CITY: "PASSO CORESE",
      },
      {
        CITY: "PASSO DI TREIA",
      },
      {
        CITY: "PASTENE",
      },
      {
        CITY: "PASTRENGO",
      },
      {
        CITY: "PASTURO",
      },
      {
        CITY: "PATERNO",
      },
      {
        CITY: "PATERNOPOLI",
      },
      {
        CITY: "PATRICA",
      },
      {
        CITY: "PATTI",
      },
      {
        CITY: "PAULI ARBAREI",
      },
      {
        CITY: "PAULILATINO",
      },
      {
        CITY: "PAULLO",
      },
      {
        CITY: "PAVIA",
      },
      {
        CITY: "PAVIA DI UDINE",
      },
      {
        CITY: "PAVONA",
      },
      {
        CITY: "PAVONE CANAVESE",
      },
      {
        CITY: "PAVULLO NEL FRIGNANO",
      },
      {
        CITY: "PECCIOLI",
      },
      {
        CITY: "PECETTO",
      },
      {
        CITY: "PEDARA",
      },
      {
        CITY: "PEDASO",
      },
      {
        CITY: "PEDAVENA",
      },
      {
        CITY: "PEDEMONTE",
      },
      {
        CITY: "PEDEROBBA",
      },
      {
        CITY: "PEGLIO",
      },
      {
        CITY: "PEGOGNAGA",
      },
      {
        CITY: "PELAGO",
      },
      {
        CITY: "PELLEGRINI",
      },
      {
        CITY: "PELLEGRINO",
      },
      {
        CITY: "PELLEGRINO PARMENSE",
      },
      {
        CITY: "PELLESTRINA",
      },
      {
        CITY: "PELLEZZANO",
      },
      {
        CITY: "PENNABILLI",
      },
      {
        CITY: "PENNE",
      },
      {
        CITY: "PERDASDEFOGU",
      },
      {
        CITY: "PERDAXIUS",
      },
      {
        CITY: "PERDIFUMO",
      },
      {
        CITY: "PERFUGAS",
      },
      {
        CITY: "PERGINE VALSUGANA",
      },
      {
        CITY: "PERGOLA",
      },
      {
        CITY: "PERIGNANO",
      },
      {
        CITY: "PERINALDO",
      },
      {
        CITY: "PERNUMIA",
      },
      {
        CITY: "PERO",
      },
      {
        CITY: "PERO",
      },
      {
        CITY: "PEROSA ARGENTINA",
      },
      {
        CITY: "PERTENGO",
      },
      {
        CITY: "PERTEOLE",
      },
      {
        CITY: "PERTOSA",
      },
      {
        CITY: "PERUGIA",
      },
      {
        CITY: "PESARO",
      },
      {
        CITY: "PESCANTINA",
      },
      {
        CITY: "PESCARA",
      },
      {
        CITY: "PESCAROLO ED UNITI",
      },
      {
        CITY: "PESCHICI",
      },
      {
        CITY: "PESCHIERA BORROMEO",
      },
      {
        CITY: "PESCHIERA DEL GARDA",
      },
      {
        CITY: "PESCIA",
      },
      {
        CITY: "PESCINA",
      },
      {
        CITY: "PESCOCOSTANZO",
      },
      {
        CITY: "PESSINETTO",
      },
      {
        CITY: "PESSIONE",
      },
      {
        CITY: "PETACCIATO",
      },
      {
        CITY: "PETILIA POLICASTRO",
      },
      {
        CITY: "PETRIANO",
      },
      {
        CITY: "PETRITOLI",
      },
      {
        CITY: "PETROSINO",
      },
      {
        CITY: "PETRURO",
      },
      {
        CITY: "PETTINENGO",
      },
      {
        CITY: "PETTORANELLO DEL MOLISE",
      },
      {
        CITY: "PETTORAZZA GRIMANI",
      },
      {
        CITY: "PEVERAGNO",
      },
      {
        CITY: "PEZZANA",
      },
      {
        CITY: "PEZZE DI GRECO",
      },
      {
        CITY: "PIACENZA",
      },
      {
        CITY: "PIADENA",
      },
      {
        CITY: "PIAGGINE",
      },
      {
        CITY: "PIAN CAMUNO",
      },
      {
        CITY: "PIAN-FOSSAL",
      },
      {
        CITY: "PIANA",
      },
      {
        CITY: "PIANA DEGLI ALBANESI",
      },
      {
        CITY: "PIANCASTAGNAIO",
      },
      {
        CITY: "PIANCOGNO",
      },
      {
        CITY: "PIANDIMELETO",
      },
      {
        CITY: "PIANE CRATI",
      },
      {
        CITY: "PIANELLA",
      },
      {
        CITY: "PIANELLA",
      },
      {
        CITY: "PIANELLO VAL TIDONE",
      },
      {
        CITY: "PIANEZZA",
      },
      {
        CITY: "PIANEZZE",
      },
      {
        CITY: "PIANGIPANE",
      },
      {
        CITY: "PIANIGA",
      },
      {
        CITY: "PIANO",
      },
      {
        CITY: "PIANO",
      },
      {
        CITY: "PIANO DI COREGLIA-GHIVIZZANO",
      },
      {
        CITY: "PIANO DI SORRENTO",
      },
      {
        CITY: "PIANO-VETRALE",
      },
      {
        CITY: "PIANORO",
      },
      {
        CITY: "PIASCO",
      },
      {
        CITY: "PIATEDA",
      },
      {
        CITY: "PIAVON",
      },
      {
        CITY: "PIAZZA",
      },
      {
        CITY: "PIAZZA AL SERCHIO",
      },
      {
        CITY: "PIAZZA ARMERINA",
      },
      {
        CITY: "PIAZZA BREMBANA",
      },
      {
        CITY: "PIAZZA ROMA",
      },
      {
        CITY: "PIAZZOLA SUL BRENTA",
      },
      {
        CITY: "PICERNO",
      },
      {
        CITY: "PICO",
      },
      {
        CITY: "PIEDIMONTE ETNEO",
      },
      {
        CITY: "PIEDIMONTE MATESE",
      },
      {
        CITY: "PIEDIMONTE SAN GERMANO",
      },
      {
        CITY: "PIEGARO",
      },
      {
        CITY: "PIEMONTE",
      },
      {
        CITY: "PIERIS",
      },
      {
        CITY: "PIETRA",
      },
      {
        CITY: "PIETRA LIGURE",
      },
      {
        CITY: "PIETRABBONDANTE",
      },
      {
        CITY: "PIETRACUTA",
      },
      {
        CITY: "PIETRAPAOLA",
      },
      {
        CITY: "PIETRAPERZIA",
      },
      {
        CITY: "PIETRASANTA",
      },
      {
        CITY: "PIEVE",
      },
      {
        CITY: "PIEVE",
      },
      {
        CITY: "PIEVE ALBIGNOLA",
      },
      {
        CITY: "PIEVE DEL CAIRO",
      },
      {
        CITY: "PIEVE DI CADORE",
      },
      {
        CITY: "PIEVE DI CENTO",
      },
      {
        CITY: "PIEVE DI SOLIGO",
      },
      {
        CITY: "PIEVE FOSCIANA",
      },
      {
        CITY: "PIEVE LIGURE",
      },
      {
        CITY: "PIEVE SAN GIACOMO",
      },
      {
        CITY: "PIEVE VECCHIA",
      },
      {
        CITY: "PIEVEBOVIGLIANA",
      },
      {
        CITY: "PIEVEPELAGO",
      },
      {
        CITY: "PIGLIO",
      },
      {
        CITY: "PIGNATARO INTERAMNA",
      },
      {
        CITY: "PIGNATARO MAGGIORE",
      },
      {
        CITY: "PIGNOLA",
      },
      {
        CITY: "PIGNONE",
      },
      {
        CITY: "PILCANTE",
      },
      {
        CITY: "PIMONTE",
      },
      {
        CITY: "PINARELLA",
      },
      {
        CITY: "PINEROLO",
      },
      {
        CITY: "PINETA",
      },
      {
        CITY: "PINETO",
      },
      {
        CITY: "PINZANO AL TAGLIAMENTO",
      },
      {
        CITY: "PINZOLO",
      },
      {
        CITY: "PIOBBICO",
      },
      {
        CITY: "PIOBESI TORINESE",
      },
      {
        CITY: "PIOLTELLO",
      },
      {
        CITY: "PIOMBINO",
      },
      {
        CITY: "PIOMBINO DESE",
      },
      {
        CITY: "PIORACO",
      },
      {
        CITY: "PIOSSASCO",
      },
      {
        CITY: "PIOVE DI SACCO",
      },
      {
        CITY: "PIOVENE ROCCHETTE",
      },
      {
        CITY: "PIRAINO",
      },
      {
        CITY: "PIRRI",
      },
      {
        CITY: "PISA",
      },
      {
        CITY: "PISCIOTTA",
      },
      {
        CITY: "PISOGNE",
      },
      {
        CITY: "PISTICCI",
      },
      {
        CITY: "PISTOIA",
      },
      {
        CITY: "PISTRINO",
      },
      {
        CITY: "PITIGLIANO",
      },
      {
        CITY: "PIUMAZZO",
      },
      {
        CITY: "PIZZIGHETTONE",
      },
      {
        CITY: "PIZZO",
      },
      {
        CITY: "PLAN DA TIEJA",
      },
      {
        CITY: "PLOAGHE",
      },
      {
        CITY: "POCENIA",
      },
      {
        CITY: "PODENZANA",
      },
      {
        CITY: "PODENZANO",
      },
      {
        CITY: "POFI",
      },
      {
        CITY: "POGGIARDO",
      },
      {
        CITY: "POGGIBONSI",
      },
      {
        CITY: "POGGIO",
      },
      {
        CITY: "POGGIO A CAIANO",
      },
      {
        CITY: "POGGIO BERNI",
      },
      {
        CITY: "POGGIO DI ROIO",
      },
      {
        CITY: "POGGIO MIRTETO",
      },
      {
        CITY: "POGGIO MOIANO",
      },
      {
        CITY: "POGGIO NATIVO",
      },
      {
        CITY: "POGGIO RENATICO",
      },
      {
        CITY: "POGGIO RUSCO",
      },
      {
        CITY: "POGGIO SAN LORENZO",
      },
      {
        CITY: "POGGIOMARINO",
      },
      {
        CITY: "POGLIANO",
      },
      {
        CITY: "POGLIANO MILANESE",
      },
      {
        CITY: "POGNO",
      },
      {
        CITY: "POIANA MAGGIORE",
      },
      {
        CITY: "POIRINO",
      },
      {
        CITY: "POLESELLA",
      },
      {
        CITY: "POLESINE PARMENSE",
      },
      {
        CITY: "POLI",
      },
      {
        CITY: "POLICORO",
      },
      {
        CITY: "POLIGNANO A MARE",
      },
      {
        CITY: "POLINAGO",
      },
      {
        CITY: "POLISTENA",
      },
      {
        CITY: "POLLA",
      },
      {
        CITY: "POLLEIN",
      },
      {
        CITY: "POLLENZA",
      },
      {
        CITY: "POLLICA",
      },
      {
        CITY: "POLLINA",
      },
      {
        CITY: "POLVERARA",
      },
      {
        CITY: "POLVERIGI",
      },
      {
        CITY: "POMARANCE",
      },
      {
        CITY: "POMBIA",
      },
      {
        CITY: "POMEZIA",
      },
      {
        CITY: "POMPEI",
      },
      {
        CITY: "POMPIANO",
      },
      {
        CITY: "POMPONESCO",
      },
      {
        CITY: "PONCARALE",
      },
      {
        CITY: "PONSACCO",
      },
      {
        CITY: "PONSO",
      },
      {
        CITY: "PONT",
      },
      {
        CITY: "PONT-CANAVESE",
      },
      {
        CITY: "PONT-SAINT-MARTIN",
      },
      {
        CITY: "PONTASSIEVE",
      },
      {
        CITY: "PONTE",
      },
      {
        CITY: "PONTE",
      },
      {
        CITY: "PONTE",
      },
      {
        CITY: "PONTE A EVOLA",
      },
      {
        CITY: "PONTE A MORIANO",
      },
      {
        CITY: "PONTE BASSO",
      },
      {
        CITY: "PONTE BUGGIANESE",
      },
      {
        CITY: "PONTE DI BARBARANO",
      },
      {
        CITY: "PONTE DI PIAVE",
      },
      {
        CITY: "PONTE GALERIA-LA PISANA",
      },
      {
        CITY: "PONTE LAMBRO",
      },
      {
        CITY: "PONTE NELLE ALPI",
      },
      {
        CITY: "PONTE NOSSA",
      },
      {
        CITY: "PONTE SAN GIOVANNI",
      },
      {
        CITY: "PONTE SAN NICOLO",
      },
      {
        CITY: "PONTE SAN PIETRO",
      },
      {
        CITY: "PONTECAGNANO",
      },
      {
        CITY: "PONTECCHIO",
      },
      {
        CITY: "PONTECCHIO POLESINE",
      },
      {
        CITY: "PONTECORVO",
      },
      {
        CITY: "PONTECURONE",
      },
      {
        CITY: "PONTEDASSIO",
      },
      {
        CITY: "PONTEDERA",
      },
      {
        CITY: "PONTELONGO",
      },
      {
        CITY: "PONTENURE",
      },
      {
        CITY: "PONTESTURA",
      },
      {
        CITY: "PONTEVICO",
      },
      {
        CITY: "PONTI",
      },
      {
        CITY: "PONTICELLI",
      },
      {
        CITY: "PONTIDA",
      },
      {
        CITY: "PONTINIA",
      },
      {
        CITY: "PONTREMOLI",
      },
      {
        CITY: "PONZA",
      },
      {
        CITY: "PONZANO",
      },
      {
        CITY: "PONZANO VENETO",
      },
      {
        CITY: "PONZONE",
      },
      {
        CITY: "POPOLI",
      },
      {
        CITY: "POPPI",
      },
      {
        CITY: "PORANO",
      },
      {
        CITY: "PORCARI",
      },
      {
        CITY: "PORCIA",
      },
      {
        CITY: "PORDENONE",
      },
      {
        CITY: "PORLEZZA",
      },
      {
        CITY: "PORPETTO",
      },
      {
        CITY: "PORRETTA TERME",
      },
      {
        CITY: "PORTA",
      },
      {
        CITY: "PORTICI",
      },
      {
        CITY: "PORTICO DI CASERTA",
      },
      {
        CITY: "PORTO",
      },
      {
        CITY: "PORTO",
      },
      {
        CITY: "PORTO AZZURRO",
      },
      {
        CITY: "PORTO EMPEDOCLE",
      },
      {
        CITY: "PORTO ERCOLE",
      },
      {
        CITY: "PORTO GARIBALDI",
      },
      {
        CITY: "PORTO POTENZA PICENA",
      },
      {
        CITY: "PORTO RECANATI",
      },
      {
        CITY: "PORTO SAN GIORGIO",
      },
      {
        CITY: "PORTO TOLLE",
      },
      {
        CITY: "PORTO TORRES",
      },
      {
        CITY: "PORTO VIRO",
      },
      {
        CITY: "PORTOFERRAIO",
      },
      {
        CITY: "PORTOFINO",
      },
      {
        CITY: "PORTOGRUARO",
      },
      {
        CITY: "PORTOMAGGIORE",
      },
      {
        CITY: "PORTOSCUSO",
      },
      {
        CITY: "PORTOVENERE",
      },
      {
        CITY: "POSITANO",
      },
      {
        CITY: "POSTA FIBRENO",
      },
      {
        CITY: "POSTA VECCHIA",
      },
      {
        CITY: "POSTIGLIONE",
      },
      {
        CITY: "POSTIOMA",
      },
      {
        CITY: "POTENZA",
      },
      {
        CITY: "POTENZA PICENA",
      },
      {
        CITY: "POVEGLIANO",
      },
      {
        CITY: "POVEGLIANO VERONESE",
      },
      {
        CITY: "POVIGLIO",
      },
      {
        CITY: "POZZA DI FASSA",
      },
      {
        CITY: "POZZA-SAN MARCO",
      },
      {
        CITY: "POZZALLO",
      },
      {
        CITY: "POZZILLI",
      },
      {
        CITY: "POZZO",
      },
      {
        CITY: "POZZO DI GOTTO",
      },
      {
        CITY: "POZZOLENGO",
      },
      {
        CITY: "POZZOLEONE",
      },
      {
        CITY: "POZZOLO",
      },
      {
        CITY: "POZZOLO FORMIGARO",
      },
      {
        CITY: "POZZONOVO",
      },
      {
        CITY: "POZZUOLI",
      },
      {
        CITY: "POZZUOLO DEL FRIULI",
      },
      {
        CITY: "PRAD AM STILFSER JOCH",
      },
      {
        CITY: "PRADAMANO",
      },
      {
        CITY: "PRAIA A MARE",
      },
      {
        CITY: "PRAIANO",
      },
      {
        CITY: "PRALORMO",
      },
      {
        CITY: "PRALUNGO",
      },
      {
        CITY: "PRAMAGGIORE",
      },
      {
        CITY: "PRAROLO",
      },
      {
        CITY: "PRATA",
      },
      {
        CITY: "PRATA CAMPORTACCIO",
      },
      {
        CITY: "PRATA DI PORDENONE",
      },
      {
        CITY: "PRATA SANNITA",
      },
      {
        CITY: "PRATO",
      },
      {
        CITY: "PRATO SESIA",
      },
      {
        CITY: "PRATOLA PELIGNA",
      },
      {
        CITY: "PRATOLA SERRA",
      },
      {
        CITY: "PRATOVECCHIO",
      },
      {
        CITY: "PRAVISDOMINI",
      },
      {
        CITY: "PRAY",
      },
      {
        CITY: "PREDAZZO",
      },
      {
        CITY: "PREDORE",
      },
      {
        CITY: "PREDOSA",
      },
      {
        CITY: "PREGANZIOL",
      },
      {
        CITY: "PREMANA",
      },
      {
        CITY: "PREMARIACCO",
      },
      {
        CITY: "PREMOSELLO-CHIOVENDA",
      },
      {
        CITY: "PRESICCE",
      },
      {
        CITY: "PRESSANA",
      },
      {
        CITY: "PRETORO",
      },
      {
        CITY: "PREVALLE",
      },
      {
        CITY: "PREZZA",
      },
      {
        CITY: "PRIOLO GARGALLO",
      },
      {
        CITY: "PRIVERNO",
      },
      {
        CITY: "PRIZZI",
      },
      {
        CITY: "PROCIDA",
      },
      {
        CITY: "PROVESANO",
      },
      {
        CITY: "PROVINCE OF MESSINA",
      },
      {
        CITY: "PUGLIANELLO",
      },
      {
        CITY: "PUGLIANO",
      },
      {
        CITY: "PUIANELLO",
      },
      {
        CITY: "PULA",
      },
      {
        CITY: "PULSANO",
      },
      {
        CITY: "PUNTA MARINA",
      },
      {
        CITY: "PUTIGNANO",
      },
      {
        CITY: "QUADRI",
      },
      {
        CITY: "QUAGLIUZZO",
      },
      {
        CITY: "QUALIANO",
      },
      {
        CITY: "QUALSO",
      },
      {
        CITY: "QUAREGNA",
      },
      {
        CITY: "QUARGNENTO",
      },
      {
        CITY: "QUARONA",
      },
      {
        CITY: "QUARRATA",
      },
      {
        CITY: "QUART",
      },
      {
        CITY: "QUARTO",
      },
      {
        CITY: "QUARTO INFERIORE",
      },
      {
        CITY: "QUARTUCCIU",
      },
      {
        CITY: "QUATTORDIO",
      },
      {
        CITY: "QUATTRO CASTELLA",
      },
      {
        CITY: "QUERO",
      },
      {
        CITY: "QUILIANO",
      },
      {
        CITY: "QUINDICI",
      },
      {
        CITY: "QUINTO DI TREVISO",
      },
      {
        CITY: "QUINTO VERCELLESE",
      },
      {
        CITY: "QUINTO VICENTINO",
      },
      {
        CITY: "QUISTELLO",
      },
      {
        CITY: "RACALE",
      },
      {
        CITY: "RACALMUTO",
      },
      {
        CITY: "RACCONIGI",
      },
      {
        CITY: "RADDA IN CHIANTI",
      },
      {
        CITY: "RAFFADALI",
      },
      {
        CITY: "RAGOGNA",
      },
      {
        CITY: "RAGUSA",
      },
      {
        CITY: "RAIANO",
      },
      {
        CITY: "RALDON",
      },
      {
        CITY: "RAMACCA",
      },
      {
        CITY: "RAMISETO",
      },
      {
        CITY: "RAMON",
      },
      {
        CITY: "RAMPONIO",
      },
      {
        CITY: "RANCIO VALCUVIA",
      },
      {
        CITY: "RANDAZZO",
      },
      {
        CITY: "RANIERI",
      },
      {
        CITY: "RAPAGNANO",
      },
      {
        CITY: "RAPALLO",
      },
      {
        CITY: "RAPOLLA",
      },
      {
        CITY: "RASSINA",
      },
      {
        CITY: "RATSCHINGS",
      },
      {
        CITY: "RAVANUSA",
      },
      {
        CITY: "RAVARINO",
      },
      {
        CITY: "RAVELLO",
      },
      {
        CITY: "RAVENNA",
      },
      {
        CITY: "RAVI",
      },
      {
        CITY: "RECALE",
      },
      {
        CITY: "RECANATI",
      },
      {
        CITY: "RECCO",
      },
      {
        CITY: "RECOARO TERME",
      },
      {
        CITY: "REGALBUTO",
      },
      {
        CITY: "REGGELLO",
      },
      {
        CITY: "REGGIO CALABRIA",
      },
      {
        CITY: "REGGIOLO",
      },
      {
        CITY: "REGINA",
      },
      {
        CITY: "REGINA MARGHERITA",
      },
      {
        CITY: "REMANZACCO",
      },
      {
        CITY: "RENAZZO",
      },
      {
        CITY: "RENDE",
      },
      {
        CITY: "RENO",
      },
      {
        CITY: "RESANA",
      },
      {
        CITY: "RESCALDINA",
      },
      {
        CITY: "RESIA",
      },
      {
        CITY: "REVELLO",
      },
      {
        CITY: "REVERE",
      },
      {
        CITY: "REVIGLIASCO",
      },
      {
        CITY: "REVINE",
      },
      {
        CITY: "REZZATO",
      },
      {
        CITY: "RHO",
      },
      {
        CITY: "RIALE",
      },
      {
        CITY: "RIANO",
      },
      {
        CITY: "RIBERA",
      },
      {
        CITY: "RIBOLLA",
      },
      {
        CITY: "RICADI",
      },
      {
        CITY: "RICCIA",
      },
      {
        CITY: "RICCIONE",
      },
      {
        CITY: "RICCOVOLTO",
      },
      {
        CITY: "RIESE PIO X",
      },
      {
        CITY: "RIESI",
      },
      {
        CITY: "RIETI",
      },
      {
        CITY: "RIGNANO FLAMINIO",
      },
      {
        CITY: "RIGNANO GARGANICO",
      },
      {
        CITY: "RIMINI",
      },
      {
        CITY: "RINO",
      },
      {
        CITY: "RIO MARINA",
      },
      {
        CITY: "RIO SALICETO",
      },
      {
        CITY: "RIOLA SARDO",
      },
      {
        CITY: "RIOLO TERME",
      },
      {
        CITY: "RIOMAGGIORE",
      },
      {
        CITY: "RIONERO IN VULTURE",
      },
      {
        CITY: "RIPA",
      },
      {
        CITY: "RIPA TEATINA",
      },
      {
        CITY: "RIPA-POZZI-QUERCETA-PONTEROSSO",
      },
      {
        CITY: "RIPAFRATTA-FARNETA",
      },
      {
        CITY: "RIPALTA CREMASCA",
      },
      {
        CITY: "RIPARBELLA",
      },
      {
        CITY: "RIPATRANSONE",
      },
      {
        CITY: "RIPE",
      },
      {
        CITY: "RIPE SAN GINESIO",
      },
      {
        CITY: "RIPI",
      },
      {
        CITY: "RIPOLI",
      },
      {
        CITY: "RIPOSTO",
      },
      {
        CITY: "RIVA",
      },
      {
        CITY: "RIVA",
      },
      {
        CITY: "RIVA DEL GARDA",
      },
      {
        CITY: "RIVA LIGURE",
      },
      {
        CITY: "RIVA PRESSO CHIERI",
      },
      {
        CITY: "RIVALBA",
      },
      {
        CITY: "RIVALTA",
      },
      {
        CITY: "RIVALTA DI TORINO",
      },
      {
        CITY: "RIVANAZZANO",
      },
      {
        CITY: "RIVARA",
      },
      {
        CITY: "RIVAROLO DEL RE ED UNITI",
      },
      {
        CITY: "RIVAROLO LIGURE",
      },
      {
        CITY: "RIVAROLO MANTOVANO",
      },
      {
        CITY: "RIVELLO",
      },
      {
        CITY: "RIVERGARO",
      },
      {
        CITY: "RIVIERA",
      },
      {
        CITY: "RIVIGNANO",
      },
      {
        CITY: "RIVODUTRI",
      },
      {
        CITY: "RIVOLI",
      },
      {
        CITY: "RIZZI",
      },
      {
        CITY: "RIZZICONI",
      },
      {
        CITY: "ROASIO",
      },
      {
        CITY: "ROBASSOMERO",
      },
      {
        CITY: "ROBBIATE",
      },
      {
        CITY: "ROBBIO",
      },
      {
        CITY: "ROBECCHETTO CON INDUNO",
      },
      {
        CITY: "ROBECCO SUL NAVIGLIO",
      },
      {
        CITY: "ROCCA",
      },
      {
        CITY: "ROCCA CANAVESE",
      },
      {
        CITY: "ROCCA DI PAPA",
      },
      {
        CITY: "ROCCA MASSIMA",
      },
      {
        CITY: "ROCCA PRIORA",
      },
      {
        CITY: "ROCCA SAN CASCIANO",
      },
      {
        CITY: "ROCCABERNARDA",
      },
      {
        CITY: "ROCCABIANCA",
      },
      {
        CITY: "ROCCADASPIDE",
      },
      {
        CITY: "ROCCAFRANCA",
      },
      {
        CITY: "ROCCALUMERA",
      },
      {
        CITY: "ROCCAMONFINA",
      },
      {
        CITY: "ROCCAPIEMONTE",
      },
      {
        CITY: "ROCCARAINOLA",
      },
      {
        CITY: "ROCCAROMANA",
      },
      {
        CITY: "ROCCASECCA",
      },
      {
        CITY: "ROCCASTRADA",
      },
      {
        CITY: "ROCCAVIONE",
      },
      {
        CITY: "ROCCAVIVARA",
      },
      {
        CITY: "ROCCAVIVI",
      },
      {
        CITY: "ROCCELLA",
      },
      {
        CITY: "ROCCELLA IONICA",
      },
      {
        CITY: "ROCCHETTA DI VARA",
      },
      {
        CITY: "ROCCO",
      },
      {
        CITY: "RODANO",
      },
      {
        CITY: "RODENECK-RODENGO",
      },
      {
        CITY: "RODI GARGANICO",
      },
      {
        CITY: "RODIGO",
      },
      {
        CITY: "ROGGIANO GRAVINA",
      },
      {
        CITY: "ROGLIANO",
      },
      {
        CITY: "ROGNANO",
      },
      {
        CITY: "ROIANO",
      },
      {
        CITY: "ROLO",
      },
      {
        CITY: "ROMAGNANO SESIA",
      },
      {
        CITY: "ROMANA",
      },
      {
        CITY: "ROMANENGO",
      },
      {
        CITY: "ROMANO CANAVESE",
      },
      {
        CITY: "ROMANO DI LOMBARDIA",
      },
      {
        CITY: "ROME",
      },
      {
        CITY: "ROMENTINO",
      },
      {
        CITY: "ROMETTA",
      },
      {
        CITY: "RONCADE",
      },
      {
        CITY: "RONCADELLE",
      },
      {
        CITY: "RONCALCECI",
      },
      {
        CITY: "RONCEGNO",
      },
      {
        CITY: "RONCHI",
      },
      {
        CITY: "RONCHI DEI LEGIONARI",
      },
      {
        CITY: "RONCHIS",
      },
      {
        CITY: "RONCIGLIONE",
      },
      {
        CITY: "RONCO",
      },
      {
        CITY: "RONCO SCRIVIA",
      },
      {
        CITY: "RONCO-CHIESA",
      },
      {
        CITY: "RONCOFERRARO",
      },
      {
        CITY: "RONCONE",
      },
      {
        CITY: "RONDISSONE",
      },
      {
        CITY: "ROSA",
      },
      {
        CITY: "ROSATE",
      },
      {
        CITY: "ROSCIANO",
      },
      {
        CITY: "ROSE",
      },
      {
        CITY: "ROSETO CAPO SPULICO",
      },
      {
        CITY: "ROSETO DEGLI ABRUZZI",
      },
      {
        CITY: "ROSETO VALFORTORE",
      },
      {
        CITY: "ROSIGNANO MARITTIMO",
      },
      {
        CITY: "ROSIGNANO SOLVAY-CASTIGLIONCELLO",
      },
      {
        CITY: "ROSOLINA",
      },
      {
        CITY: "ROSOLINI",
      },
      {
        CITY: "ROSSANO",
      },
      {
        CITY: "ROSSANO VENETO",
      },
      {
        CITY: "ROSSI",
      },
      {
        CITY: "ROSSIGLIONE",
      },
      {
        CITY: "ROTA GRECA",
      },
      {
        CITY: "ROTELLA",
      },
      {
        CITY: "ROTELLO",
      },
      {
        CITY: "ROTONDI",
      },
      {
        CITY: "ROTTOFRENO",
      },
      {
        CITY: "ROVAGNATE",
      },
      {
        CITY: "ROVATO",
      },
      {
        CITY: "ROVELLASCA",
      },
      {
        CITY: "ROVELLO PORRO",
      },
      {
        CITY: "ROVERBELLA",
      },
      {
        CITY: "ROVERE",
      },
      {
        CITY: "ROVEREDO",
      },
      {
        CITY: "ROVEREDO IN PIANO",
      },
      {
        CITY: "ROVERETO",
      },
      {
        CITY: "ROVETTA",
      },
      {
        CITY: "ROVIANO",
      },
      {
        CITY: "ROVIGO",
      },
      {
        CITY: "ROVITO",
      },
      {
        CITY: "ROZZANO",
      },
      {
        CITY: "RUBANO",
      },
      {
        CITY: "RUBBIANO",
      },
      {
        CITY: "RUBIANA",
      },
      {
        CITY: "RUBIERA",
      },
      {
        CITY: "RUDA",
      },
      {
        CITY: "RUDIANO",
      },
      {
        CITY: "RUFFANO",
      },
      {
        CITY: "RUFINA",
      },
      {
        CITY: "RUOTI",
      },
      {
        CITY: "RUSSI",
      },
      {
        CITY: "RUSSO",
      },
      {
        CITY: "RUTIGLIANO",
      },
      {
        CITY: "RUVIANO",
      },
      {
        CITY: "RUVO DI PUGLIA",
      },
      {
        CITY: "SABATINO",
      },
      {
        CITY: "SABAUDIA",
      },
      {
        CITY: "SABBIO CHIESE",
      },
      {
        CITY: "SABBIONETA",
      },
      {
        CITY: "SACCO",
      },
      {
        CITY: "SACCOLONGO",
      },
      {
        CITY: "SACILE",
      },
      {
        CITY: "SACROFANO",
      },
      {
        CITY: "SAGRADO",
      },
      {
        CITY: "SAINT-PIERRE",
      },
      {
        CITY: "SAINT-VINCENT",
      },
      {
        CITY: "SALA BAGANZA",
      },
      {
        CITY: "SALA BOLOGNESE",
      },
      {
        CITY: "SALA CONSILINA",
      },
      {
        CITY: "SALARA",
      },
      {
        CITY: "SALASSA",
      },
      {
        CITY: "SALE",
      },
      {
        CITY: "SALE MARASINO",
      },
      {
        CITY: "SALEMI",
      },
      {
        CITY: "SALENTO",
      },
      {
        CITY: "SALERNO",
      },
      {
        CITY: "SALETTO",
      },
      {
        CITY: "SALICE SALENTINO",
      },
      {
        CITY: "SALICETO",
      },
      {
        CITY: "SALINE",
      },
      {
        CITY: "SALMOUR",
      },
      {
        CITY: "SALORNO",
      },
      {
        CITY: "SALSOMAGGIORE TERME",
      },
      {
        CITY: "SALTOCCHIO",
      },
      {
        CITY: "SALUDECIO",
      },
      {
        CITY: "SALUGGIA",
      },
      {
        CITY: "SALUZZO",
      },
      {
        CITY: "SALVATERRA",
      },
      {
        CITY: "SALVE",
      },
      {
        CITY: "SALVIROLA",
      },
      {
        CITY: "SALZANO",
      },
      {
        CITY: "SAMARATE",
      },
      {
        CITY: "SAMASSI",
      },
      {
        CITY: "SAMBRUSON",
      },
      {
        CITY: "SAMBUCETO",
      },
      {
        CITY: "SAMMICHELE DI BARI",
      },
      {
        CITY: "SAMONE",
      },
      {
        CITY: "SAN BARTOLOMEO",
      },
      {
        CITY: "SAN BASSANO",
      },
      {
        CITY: "SAN BENEDETTO DEI MARSI",
      },
      {
        CITY: "SAN BENEDETTO DEL TRONTO",
      },
      {
        CITY: "SAN BENEDETTO PO",
      },
      {
        CITY: "SAN BENEDETTO VAL DI SAMBRO",
      },
      {
        CITY: "SAN BENIGNO CANAVESE",
      },
      {
        CITY: "SAN BERNARDINO",
      },
      {
        CITY: "SAN BIAGIO",
      },
      {
        CITY: "SAN BIAGIO DI CALLALTA",
      },
      {
        CITY: "SAN BONIFACIO",
      },
      {
        CITY: "SAN CARLO",
      },
      {
        CITY: "SAN CARLO",
      },
      {
        CITY: "SAN CASCIANO DEI BAGNI",
      },
      {
        CITY: "SAN CASCIANO IN VAL DI PESA",
      },
      {
        CITY: "SAN CASSIANO",
      },
      {
        CITY: "SAN CATALDO",
      },
      {
        CITY: "SAN CESAREO",
      },
      {
        CITY: "SAN CESARIO DI LECCE",
      },
      {
        CITY: "SAN CESARIO SUL PANARO",
      },
      {
        CITY: "SAN CHIRICO RAPARO",
      },
      {
        CITY: "SAN CIPIRELLO",
      },
      {
        CITY: "SAN CIPRIANO PICENTINO",
      },
      {
        CITY: "SAN COLOMBANO AL LAMBRO",
      },
      {
        CITY: "SAN COSTANZO",
      },
      {
        CITY: "SAN DANIELE DEL FRIULI",
      },
      {
        CITY: "SAN DEMETRIO CORONE",
      },
      {
        CITY: "SAN DONA",
      },
      {
        CITY: "SAN DONACI",
      },
      {
        CITY: "SAN DONATO",
      },
      {
        CITY: "SAN DONATO",
      },
      {
        CITY: "SAN DONATO DI LECCE",
      },
      {
        CITY: "SAN DONATO MILANESE",
      },
      {
        CITY: "SAN DORLIGO DELLA VALLE",
      },
      {
        CITY: "SAN FEDELE",
      },
      {
        CITY: "SAN FELICE CIRCEO",
      },
      {
        CITY: "SAN FELICE SUL PANARO",
      },
      {
        CITY: "SAN FERDINANDO",
      },
      {
        CITY: "SAN FERDINANDO DI PUGLIA",
      },
      {
        CITY: "SAN FILI",
      },
      {
        CITY: "SAN FILIPPO",
      },
      {
        CITY: "SAN FILIPPO",
      },
      {
        CITY: "SAN FIOR",
      },
      {
        CITY: "SAN FIORANO",
      },
      {
        CITY: "SAN FRANCESCO",
      },
      {
        CITY: "SAN FRANCESCO",
      },
      {
        CITY: "SAN FRANCESCO AL CAMPO",
      },
      {
        CITY: "SAN FRATELLO",
      },
      {
        CITY: "SAN GAVINO MONREALE",
      },
      {
        CITY: "SAN GEMINI",
      },
      {
        CITY: "SAN GENESIO ED UNITI",
      },
      {
        CITY: "SAN GENNARO VESUVIANO",
      },
      {
        CITY: "SAN GERMANO VERCELLESE",
      },
      {
        CITY: "SAN GIACOMO",
      },
      {
        CITY: "SAN GIACOMO DEGLI SCHIAVONI",
      },
      {
        CITY: "SAN GILLIO",
      },
      {
        CITY: "SAN GIMIGNANO",
      },
      {
        CITY: "SAN GINESIO",
      },
      {
        CITY: "SAN GIORGIO",
      },
      {
        CITY: "SAN GIORGIO",
      },
      {
        CITY: "SAN GIORGIO",
      },
      {
        CITY: "SAN GIORGIO",
      },
      {
        CITY: "SAN GIORGIO A CREMANO",
      },
      {
        CITY: "SAN GIORGIO A LIRI",
      },
      {
        CITY: "SAN GIORGIO CANAVESE",
      },
      {
        CITY: "SAN GIORGIO DEL SANNIO",
      },
      {
        CITY: "SAN GIORGIO DELLA RICHINVELDA",
      },
      {
        CITY: "SAN GIORGIO DI LOMELLINA",
      },
      {
        CITY: "SAN GIORGIO DI NOGARO",
      },
      {
        CITY: "SAN GIORGIO DI PIANO",
      },
      {
        CITY: "SAN GIORGIO IN BOSCO",
      },
      {
        CITY: "SAN GIORGIO IONICO",
      },
      {
        CITY: "SAN GIORGIO LA MOLARA",
      },
      {
        CITY: "SAN GIORGIO PIACENTINO",
      },
      {
        CITY: "SAN GIOVANNI",
      },
      {
        CITY: "SAN GIOVANNI",
      },
      {
        CITY: "SAN GIOVANNI",
      },
      {
        CITY: "SAN GIOVANNI",
      },
      {
        CITY: "SAN GIOVANNI A PIRO",
      },
      {
        CITY: "SAN GIOVANNI AL NATISONE",
      },
      {
        CITY: "SAN GIOVANNI BIANCO",
      },
      {
        CITY: "SAN GIOVANNI GEMINI",
      },
      {
        CITY: "SAN GIOVANNI IN CROCE",
      },
      {
        CITY: "SAN GIOVANNI IN FIORE",
      },
      {
        CITY: "SAN GIOVANNI IN MARIGNANO",
      },
      {
        CITY: "SAN GIOVANNI IN PERSICETO",
      },
      {
        CITY: "SAN GIOVANNI LA PUNTA",
      },
      {
        CITY: "SAN GIOVANNI LUPATOTO",
      },
      {
        CITY: "SAN GIOVANNI ROTONDO",
      },
      {
        CITY: "SAN GIOVANNI SUERGIU",
      },
      {
        CITY: "SAN GIOVANNI VALDARNO",
      },
      {
        CITY: "SAN GIULIANO MILANESE",
      },
      {
        CITY: "SAN GIULIANO TERME",
      },
      {
        CITY: "SAN GIUSEPPE",
      },
      {
        CITY: "SAN GIUSEPPE JATO",
      },
      {
        CITY: "SAN GIUSEPPE VESUVIANO",
      },
      {
        CITY: "SAN GIUSTINO",
      },
      {
        CITY: "SAN GIUSTO CANAVESE",
      },
      {
        CITY: "SAN GREGORIO",
      },
      {
        CITY: "SAN GREGORIO",
      },
      {
        CITY: "SAN GREGORIO DA SASSOLA",
      },
      {
        CITY: "SAN GREGORIO DI CATANIA",
      },
      {
        CITY: "SAN LAZZARO DEGLI ARMENI",
      },
      {
        CITY: "SAN LAZZARO DI SAVENA",
      },
      {
        CITY: "SAN LEO",
      },
      {
        CITY: "SAN LORENZELLO",
      },
      {
        CITY: "SAN LORENZO",
      },
      {
        CITY: "SAN LORENZO",
      },
      {
        CITY: "SAN LORENZO",
      },
      {
        CITY: "SAN LORENZO AL MARE",
      },
      {
        CITY: "SAN LORENZO DEL VALLO",
      },
      {
        CITY: "SAN LORENZO IN CAMPO",
      },
      {
        CITY: "SAN LORENZO ISONTINO",
      },
      {
        CITY: "SAN LUCA",
      },
      {
        CITY: "SAN LUCIDO",
      },
      {
        CITY: "SAN MANGO",
      },
      {
        CITY: "SAN MANGO PIEMONTE",
      },
      {
        CITY: "SAN MARCELLO",
      },
      {
        CITY: "SAN MARCELLO PISTOIESE",
      },
      {
        CITY: "SAN MARCO",
      },
      {
        CITY: "SAN MARCO",
      },
      {
        CITY: "SAN MARCO ARGENTANO",
      },
      {
        CITY: "SAN MARCO DEI CAVOTI",
      },
      {
        CITY: "SAN MARCO EVANGELISTA",
      },
      {
        CITY: "SAN MARCO IN LAMIS",
      },
      {
        CITY: "SAN MARIANO",
      },
      {
        CITY: "SAN MARTINO",
      },
      {
        CITY: "SAN MARTINO",
      },
      {
        CITY: "SAN MARTINO",
      },
      {
        CITY: "SAN MARTINO",
      },
      {
        CITY: "SAN MARTINO",
      },
      {
        CITY: "SAN MARTINO AL TAGLIAMENTO",
      },
      {
        CITY: "SAN MARTINO BUON ALBERGO",
      },
      {
        CITY: "SAN MARTINO CANAVESE",
      },
      {
        CITY: "SAN MARTINO DI LUPARI",
      },
      {
        CITY: "SAN MARTINO IN PENSILIS",
      },
      {
        CITY: "SAN MARTINO IN RIO",
      },
      {
        CITY: "SAN MARTINO IN STRADA",
      },
      {
        CITY: "SAN MARTINO SANNITA",
      },
      {
        CITY: "SAN MARTINO SICCOMARIO",
      },
      {
        CITY: "SAN MARZANO DI SAN GIUSEPPE",
      },
      {
        CITY: "SAN MARZANO SUL SARNO",
      },
      {
        CITY: "SAN MAURIZIO",
      },
      {
        CITY: "SAN MAURIZIO CANAVESE",
      },
      {
        CITY: "SAN MAURO",
      },
      {
        CITY: "SAN MAURO PASCOLI",
      },
      {
        CITY: "SAN MAURO TORINESE",
      },
      {
        CITY: "SAN MICHELE AL TAGLIAMENTO",
      },
      {
        CITY: "SAN MICHELE PRAZZO",
      },
      {
        CITY: "SAN MICHELE SALENTINO",
      },
      {
        CITY: "SAN MINIATO",
      },
      {
        CITY: "SAN MINIATO BASSO",
      },
      {
        CITY: "SAN NAZARIO",
      },
      {
        CITY: "SAN NICCOLO",
      },
      {
        CITY: "SAN NICOLA",
      },
      {
        CITY: "SAN NICOLA",
      },
      {
        CITY: "SAN NICOLA ARCELLA",
      },
      {
        CITY: "SAN NICOLO",
      },
      {
        CITY: "SAN PANCRAZIO SALENTINO",
      },
      {
        CITY: "SAN PAOLO",
      },
      {
        CITY: "SAN PELLEGRINO TERME",
      },
      {
        CITY: "SAN PIERO A SIEVE",
      },
      {
        CITY: "SAN PIERO IN BAGNO",
      },
      {
        CITY: "SAN PIERO PATTI",
      },
      {
        CITY: "SAN PIETRO",
      },
      {
        CITY: "SAN PIETRO",
      },
      {
        CITY: "SAN PIETRO",
      },
      {
        CITY: "SAN PIETRO",
      },
      {
        CITY: "SAN PIETRO",
      },
      {
        CITY: "SAN PIETRO A MAIDA",
      },
      {
        CITY: "SAN PIETRO DI CADORE",
      },
      {
        CITY: "SAN PIETRO DI FELETTO",
      },
      {
        CITY: "SAN PIETRO IN CASALE",
      },
      {
        CITY: "SAN PIETRO IN GU",
      },
      {
        CITY: "SAN PIETRO IN LAMA",
      },
      {
        CITY: "SAN PIETRO MOSEZZO",
      },
      {
        CITY: "SAN PIETRO VERNOTICO",
      },
      {
        CITY: "SAN PIETRO VIMINARIO",
      },
      {
        CITY: "SAN POLO",
      },
      {
        CITY: "SAN POLO",
      },
      {
        CITY: "SAN POLO DI PIAVE",
      },
      {
        CITY: "SAN PRISCO",
      },
      {
        CITY: "SAN PROSPERO",
      },
      {
        CITY: "SAN QUIRINO",
      },
      {
        CITY: "SAN RAINERI",
      },
      {
        CITY: "SAN ROCCO",
      },
      {
        CITY: "SAN RUFO",
      },
      {
        CITY: "SAN SALVATORE",
      },
      {
        CITY: "SAN SALVATORE",
      },
      {
        CITY: "SAN SALVATORE MONFERRATO",
      },
      {
        CITY: "SAN SALVATORE TELESINO",
      },
      {
        CITY: "SAN SALVO",
      },
      {
        CITY: "SAN SEBASTIANO AL VESUVIO",
      },
      {
        CITY: "SAN SECONDO",
      },
      {
        CITY: "SAN SECONDO PARMENSE",
      },
      {
        CITY: "SAN SEVERINO",
      },
      {
        CITY: "SAN SEVERINO MARCHE",
      },
      {
        CITY: "SAN SEVERO",
      },
      {
        CITY: "SAN SISTO",
      },
      {
        CITY: "SAN SOSSIO BARONIA",
      },
      {
        CITY: "SAN SOSTI",
      },
      {
        CITY: "SAN SPERATE",
      },
      {
        CITY: "SAN TAMMARO",
      },
      {
        CITY: "SAN TEODORO",
      },
      {
        CITY: "SAN VALENTINO TORIO",
      },
      {
        CITY: "SAN VENANZIO",
      },
      {
        CITY: "SAN VENANZO",
      },
      {
        CITY: "SAN VENDEMIANO",
      },
      {
        CITY: "SAN VERO MILIS",
      },
      {
        CITY: "SAN VINCENZO",
      },
      {
        CITY: "SAN VITO",
      },
      {
        CITY: "SAN VITO AL TAGLIAMENTO",
      },
      {
        CITY: "SAN VITO CHIETINO",
      },
      {
        CITY: "SAN VITO DEI NORMANNI",
      },
      {
        CITY: "SAN VITO DI CADORE",
      },
      {
        CITY: "SAN VITO LO CAPO",
      },
      {
        CITY: "SAN VITO ROMANO",
      },
      {
        CITY: "SAN VITTORE",
      },
      {
        CITY: "SAN ZACCARIA",
      },
      {
        CITY: "SAN ZENO",
      },
      {
        CITY: "SAN ZENONE AL LAMBRO",
      },
      {
        CITY: "SAN ZENONE AL PO",
      },
      {
        CITY: "SAN ZENONE DEGLI EZZELINI",
      },
      {
        CITY: "SAND IN TAUFERS",
      },
      {
        CITY: "SANDIGLIANO",
      },
      {
        CITY: "SANDON",
      },
      {
        CITY: "SANDRIGO",
      },
      {
        CITY: "SANGANO",
      },
      {
        CITY: "SANGUINETTO",
      },
      {
        CITY: "SANLURI",
      },
      {
        CITY: "SANNICANDRO DI BARI",
      },
      {
        CITY: "SANNICANDRO GARGANICO",
      },
      {
        CITY: "SANNICOLA",
      },
      {
        CITY: "SANREMO",
      },
      {
        CITY: "SANSEPOLCRO",
      },
      {
        CITY: "SANT ANGELO",
      },
      {
        CITY: "SANTA BARBARA",
      },
      {
        CITY: "SANTA CATERINA DELLO IONIO",
      },
      {
        CITY: "SANTA CATERINA VILLARMOSA",
      },
      {
        CITY: "SANTA CRISTINA",
      },
      {
        CITY: "SANTA CROCE",
      },
      {
        CITY: "SANTA CROCE CAMERINA",
      },
      {
        CITY: "SANTA ELISABETTA",
      },
      {
        CITY: "SANTA EUFEMIA LAMEZIA",
      },
      {
        CITY: "SANTA FIORA",
      },
      {
        CITY: "SANTA FLAVIA",
      },
      {
        CITY: "SANTA GIULETTA",
      },
      {
        CITY: "SANTA GIUSTA",
      },
      {
        CITY: "SANTA GIUSTINA",
      },
      {
        CITY: "SANTA GIUSTINA IN COLLE",
      },
      {
        CITY: "SANTA LUCIA",
      },
      {
        CITY: "SANTA LUCIA",
      },
      {
        CITY: "SANTA LUCIA DI PIAVE",
      },
      {
        CITY: "SANTA MARGHERITA DI BELICE",
      },
      {
        CITY: "SANTA MARGHERITA LIGURE",
      },
      {
        CITY: "SANTA MARIA",
      },
      {
        CITY: "SANTA MARIA",
      },
      {
        CITY: "SANTA MARIA",
      },
      {
        CITY: "SANTA MARIA",
      },
      {
        CITY: "SANTA MARIA",
      },
      {
        CITY: "SANTA MARIA A MONTE",
      },
      {
        CITY: "SANTA MARIA A VICO",
      },
      {
        CITY: "SANTA MARIA CAPUA VETERE",
      },
      {
        CITY: "SANTA MARIA CODIFIUME",
      },
      {
        CITY: "SANTA MARIA DEGLI ANGELI",
      },
      {
        CITY: "SANTA MARIA DI LICODIA",
      },
      {
        CITY: "SANTA MARIA DI SALA",
      },
      {
        CITY: "SANTA MARIA IMBARO",
      },
      {
        CITY: "SANTA MARIA NUOVA",
      },
      {
        CITY: "SANTA MARINELLA",
      },
      {
        CITY: "SANTA NINFA",
      },
      {
        CITY: "SANTA SEVERA",
      },
      {
        CITY: "SANTA SOFIA",
      },
      {
        CITY: "SANTA TERESA DI RIVA",
      },
      {
        CITY: "SANTA TERESA GALLURA",
      },
      {
        CITY: "SANTA VENERINA",
      },
      {
        CITY: "SANTA VITTORIA DI LIBIOLA",
      },
      {
        CITY: "SANTA VITTORIA IN MATENANO",
      },
      {
        CITY: "SANTADI",
      },
      {
        CITY: "SANTARCANGELO DI ROMAGNA",
      },
      {
        CITY: "SANTERAMO IN COLLE",
      },
      {
        CITY: "SANTI",
      },
      {
        CITY: "SANTI COSMA E DAMIANO",
      },
      {
        CITY: "SANTO PIETRO",
      },
      {
        CITY: "SANTO SPIRITO",
      },
      {
        CITY: "SANTO STEFANO",
      },
      {
        CITY: "SANTO STEFANO AL MARE",
      },
      {
        CITY: "SANTO STEFANO BELBO",
      },
      {
        CITY: "SANTO STEFANO DI BRIGA",
      },
      {
        CITY: "SANTO STEFANO DI CADORE",
      },
      {
        CITY: "SANTO STEFANO DI MAGRA",
      },
      {
        CITY: "SANTO STEFANO DI ROGLIANO",
      },
      {
        CITY: "SANTO STEFANO QUISQUINA",
      },
      {
        CITY: "SANTO STEFANO TICINO",
      },
      {
        CITY: "SANTO STINO DI LIVENZA",
      },
      {
        CITY: "SANTORSO",
      },
      {
        CITY: "SANTU LUSSURGIU",
      },
      {
        CITY: "SAPONARA",
      },
      {
        CITY: "SAPRI",
      },
      {
        CITY: "SARCEDO",
      },
      {
        CITY: "SAREZZO",
      },
      {
        CITY: "SARMEDE",
      },
      {
        CITY: "SARNANO",
      },
      {
        CITY: "SARNICO",
      },
      {
        CITY: "SARNO",
      },
      {
        CITY: "SARNTHEIN",
      },
      {
        CITY: "SARONNO",
      },
      {
        CITY: "SARROCH",
      },
      {
        CITY: "SARSINA",
      },
      {
        CITY: "SARTEANO",
      },
      {
        CITY: "SARTIRANA LOMELLINA",
      },
      {
        CITY: "SARZANA",
      },
      {
        CITY: "SASSA",
      },
      {
        CITY: "SASSARI",
      },
      {
        CITY: "SASSELLO",
      },
      {
        CITY: "SASSETTA",
      },
      {
        CITY: "SASSINORO",
      },
      {
        CITY: "SASSO",
      },
      {
        CITY: "SASSO MARCONI",
      },
      {
        CITY: "SASSOFERRATO",
      },
      {
        CITY: "SASSUOLO",
      },
      {
        CITY: "SATURNIA",
      },
      {
        CITY: "SAVA",
      },
      {
        CITY: "SAVIANO",
      },
      {
        CITY: "SAVIGLIANO",
      },
      {
        CITY: "SAVIGNANO SUL PANARO",
      },
      {
        CITY: "SAVIGNANO SUL RUBICONE",
      },
      {
        CITY: "SAVIO",
      },
      {
        CITY: "SAVONA",
      },
      {
        CITY: "SCAFA",
      },
      {
        CITY: "SCAFATI",
      },
      {
        CITY: "SCALA",
      },
      {
        CITY: "SCALDASOLE",
      },
      {
        CITY: "SCALEA",
      },
      {
        CITY: "SCALENGHE",
      },
      {
        CITY: "SCALETTA ZANCLEA",
      },
      {
        CITY: "SCANDIANO",
      },
      {
        CITY: "SCANDICCI",
      },
      {
        CITY: "SCANNO",
      },
      {
        CITY: "SCANSANO",
      },
      {
        CITY: "SCANZANO",
      },
      {
        CITY: "SCANZANO JONICO",
      },
      {
        CITY: "SCARLINO",
      },
      {
        CITY: "SCARMAGNO",
      },
      {
        CITY: "SCARPERIA",
      },
      {
        CITY: "SCAURI",
      },
      {
        CITY: "SCERNI",
      },
      {
        CITY: "SCHIAVON",
      },
      {
        CITY: "SCHIO",
      },
      {
        CITY: "SCHLANDERS",
      },
      {
        CITY: "SCIACCA",
      },
      {
        CITY: "SCICLI",
      },
      {
        CITY: "SCILLA",
      },
      {
        CITY: "SCOPPITO",
      },
      {
        CITY: "SCORDIA",
      },
      {
        CITY: "SCORRANO",
      },
      {
        CITY: "SCUOLA",
      },
      {
        CITY: "SEANO",
      },
      {
        CITY: "SEBORGA",
      },
      {
        CITY: "SECONDIGLIANO",
      },
      {
        CITY: "SEDEGLIANO",
      },
      {
        CITY: "SEDICO",
      },
      {
        CITY: "SEDILO",
      },
      {
        CITY: "SEDRIANO",
      },
      {
        CITY: "SEGARIU",
      },
      {
        CITY: "SEGLIA SAN BERNARDO",
      },
      {
        CITY: "SEGNI",
      },
      {
        CITY: "SEGRATE",
      },
      {
        CITY: "SELARGIUS",
      },
      {
        CITY: "SELCI",
      },
      {
        CITY: "SELEGAS",
      },
      {
        CITY: "SELLA",
      },
      {
        CITY: "SELVA",
      },
      {
        CITY: "SELVAZZANO DENTRO",
      },
      {
        CITY: "SELVINO",
      },
      {
        CITY: "SEMINARA",
      },
      {
        CITY: "SENAGO",
      },
      {
        CITY: "SENERCHIA",
      },
      {
        CITY: "SENIGA",
      },
      {
        CITY: "SENIGALLIA",
      },
      {
        CITY: "SENISE",
      },
      {
        CITY: "SENNORI",
      },
      {
        CITY: "SERAVEZZA",
      },
      {
        CITY: "SEREGNO",
      },
      {
        CITY: "SERGNANO",
      },
      {
        CITY: "SERIATE",
      },
      {
        CITY: "SERINO",
      },
      {
        CITY: "SERLE",
      },
      {
        CITY: "SERMIDE",
      },
      {
        CITY: "SERMONETA",
      },
      {
        CITY: "SERNAGLIA DELLA BATTAGLIA",
      },
      {
        CITY: "SERRA",
      },
      {
        CITY: "SERRA PEDACE",
      },
      {
        CITY: "SERRA SAN BRUNO",
      },
      {
        CITY: "SERRA SAN QUIRICO",
      },
      {
        CITY: "SERRACAPRIOLA",
      },
      {
        CITY: "SERRADIFALCO",
      },
      {
        CITY: "SERRAGLIO",
      },
      {
        CITY: "SERRAMANNA",
      },
      {
        CITY: "SERRAMAZZONI",
      },
      {
        CITY: "SERRAMEZZANA",
      },
      {
        CITY: "SERRANO",
      },
      {
        CITY: "SERRARA FONTANA",
      },
      {
        CITY: "SERRAVALLE PISTOIESE",
      },
      {
        CITY: "SERRAVALLE SCRIVIA",
      },
      {
        CITY: "SERRAVALLE SESIA",
      },
      {
        CITY: "SERRE",
      },
      {
        CITY: "SERRENTI",
      },
      {
        CITY: "SERSALE",
      },
      {
        CITY: "SERVIGLIANO",
      },
      {
        CITY: "SESSA AURUNCA",
      },
      {
        CITY: "SESTO AL REGHENA",
      },
      {
        CITY: "SESTO CALENDE",
      },
      {
        CITY: "SESTO FIORENTINO",
      },
      {
        CITY: "SESTO SAN GIOVANNI",
      },
      {
        CITY: "SESTRI LEVANTE",
      },
      {
        CITY: "SESTRIERE",
      },
      {
        CITY: "SESTU",
      },
      {
        CITY: "SETTIMO",
      },
      {
        CITY: "SETTIMO SAN PIETRO",
      },
      {
        CITY: "SETTIMO TORINESE",
      },
      {
        CITY: "SETTIMO VITTONE",
      },
      {
        CITY: "SEVESO",
      },
      {
        CITY: "SEXTEN",
      },
      {
        CITY: "SEZZE",
      },
      {
        CITY: "SGONICO",
      },
      {
        CITY: "SGURGOLA",
      },
      {
        CITY: "SIAMAGGIORE",
      },
      {
        CITY: "SIAMANNA",
      },
      {
        CITY: "SIANO",
      },
      {
        CITY: "SIAPICCIA",
      },
      {
        CITY: "SIBARI",
      },
      {
        CITY: "SICIGNANO DEGLI ALBURNI",
      },
      {
        CITY: "SICULIANA",
      },
      {
        CITY: "SIDERNO",
      },
      {
        CITY: "SIENA",
      },
      {
        CITY: "SIGILLO",
      },
      {
        CITY: "SIGNA",
      },
      {
        CITY: "SILANUS",
      },
      {
        CITY: "SILEA",
      },
      {
        CITY: "SILIQUA",
      },
      {
        CITY: "SILLAVENGO",
      },
      {
        CITY: "SILVI",
      },
      {
        CITY: "SILVI PAESE",
      },
      {
        CITY: "SIMAXIS",
      },
      {
        CITY: "SIMONE",
      },
      {
        CITY: "SINALUNGA",
      },
      {
        CITY: "SINDIA",
      },
      {
        CITY: "SINISCOLA",
      },
      {
        CITY: "SINNAI",
      },
      {
        CITY: "SINOPOLI",
      },
      {
        CITY: "SIRIGNANO",
      },
      {
        CITY: "SIRMIONE",
      },
      {
        CITY: "SIZIANO",
      },
      {
        CITY: "SIZZANO",
      },
      {
        CITY: "SOAVE",
      },
      {
        CITY: "SOAVE",
      },
      {
        CITY: "SOCI",
      },
      {
        CITY: "SOGLIANO CAVOUR",
      },
      {
        CITY: "SOLAGNA",
      },
      {
        CITY: "SOLARINO",
      },
      {
        CITY: "SOLARO",
      },
      {
        CITY: "SOLAROLO",
      },
      {
        CITY: "SOLAROLO",
      },
      {
        CITY: "SOLATO",
      },
      {
        CITY: "SOLBIATE ARNO",
      },
      {
        CITY: "SOLBIATE OLONA",
      },
      {
        CITY: "SOLDA",
      },
      {
        CITY: "SOLDANO",
      },
      {
        CITY: "SOLEMINIS",
      },
      {
        CITY: "SOLESINO",
      },
      {
        CITY: "SOLETO",
      },
      {
        CITY: "SOLIERA",
      },
      {
        CITY: "SOLIGNANO NUOVO",
      },
      {
        CITY: "SOLOFRA",
      },
      {
        CITY: "SOLOPACA",
      },
      {
        CITY: "SOLTO COLLINA",
      },
      {
        CITY: "SOMMA LOMBARDO",
      },
      {
        CITY: "SOMMA VESUVIANA",
      },
      {
        CITY: "SOMMACAMPAGNA",
      },
      {
        CITY: "SOMMARIVA DEL BOSCO",
      },
      {
        CITY: "SOMMARIVA PERNO",
      },
      {
        CITY: "SOMMATINO",
      },
      {
        CITY: "SONA",
      },
      {
        CITY: "SONCINO",
      },
      {
        CITY: "SONDALO",
      },
      {
        CITY: "SONDRIO",
      },
      {
        CITY: "SONNINO",
      },
      {
        CITY: "SORA",
      },
      {
        CITY: "SORAGNA",
      },
      {
        CITY: "SORBOLO",
      },
      {
        CITY: "SORDEVOLO",
      },
      {
        CITY: "SORESINA",
      },
      {
        CITY: "SORGONO",
      },
      {
        CITY: "SORI",
      },
      {
        CITY: "SORIANO NEL CIMINO",
      },
      {
        CITY: "SORISOLE",
      },
      {
        CITY: "SORRENTO",
      },
      {
        CITY: "SORSO",
      },
      {
        CITY: "SORTINO",
      },
      {
        CITY: "SOSPIRO",
      },
      {
        CITY: "SOSPIROLO",
      },
      {
        CITY: "SOSSANO",
      },
      {
        CITY: "SOTTO CASTELLO",
      },
      {
        CITY: "SOTTO IL MONTE GIOVANNI XXIII",
      },
      {
        CITY: "SOTTOMARINA",
      },
      {
        CITY: "SOVERATO MARINA",
      },
      {
        CITY: "SOVERE",
      },
      {
        CITY: "SOVERIA MANNELLI",
      },
      {
        CITY: "SOVICILLE",
      },
      {
        CITY: "SPADAFORA",
      },
      {
        CITY: "SPARANISE",
      },
      {
        CITY: "SPARONE",
      },
      {
        CITY: "SPECCHIA",
      },
      {
        CITY: "SPELLO",
      },
      {
        CITY: "SPERLONGA",
      },
      {
        CITY: "SPERONE",
      },
      {
        CITY: "SPEZZANO ALBANESE",
      },
      {
        CITY: "SPEZZANO DELLA SILA",
      },
      {
        CITY: "SPIGNO SATURNIA",
      },
      {
        CITY: "SPILAMBERTO",
      },
      {
        CITY: "SPILIMBERGO",
      },
      {
        CITY: "SPIN",
      },
      {
        CITY: "SPINA",
      },
      {
        CITY: "SPINAZZOLA",
      },
      {
        CITY: "SPINEA",
      },
      {
        CITY: "SPINEDA",
      },
      {
        CITY: "SPINETE",
      },
      {
        CITY: "SPINETO",
      },
      {
        CITY: "SPINETOLI",
      },
      {
        CITY: "SPINETTA MARENGO",
      },
      {
        CITY: "SPIRANO",
      },
      {
        CITY: "SPOLETO",
      },
      {
        CITY: "SPOLTORE",
      },
      {
        CITY: "SPOTORNO",
      },
      {
        CITY: "SPRESIANO",
      },
      {
        CITY: "SQUILLACE",
      },
      {
        CITY: "SQUINZANO",
      },
      {
        CITY: "STAFFOLO",
      },
      {
        CITY: "STAGNO",
      },
      {
        CITY: "STANGHELLA",
      },
      {
        CITY: "STARANZANO",
      },
      {
        CITY: "STATTE",
      },
      {
        CITY: "STAZIONE CASTELGUELFO",
      },
      {
        CITY: "STAZIONE PONTE RIO",
      },
      {
        CITY: "STAZZANO",
      },
      {
        CITY: "STEFANIA",
      },
      {
        CITY: "STELLA CILENTO",
      },
      {
        CITY: "STERZING",
      },
      {
        CITY: "STEZZANO",
      },
      {
        CITY: "STIA",
      },
      {
        CITY: "STIENTA",
      },
      {
        CITY: "STIGLIANO",
      },
      {
        CITY: "STIMIGLIANO",
      },
      {
        CITY: "STORNARELLA",
      },
      {
        CITY: "STORO",
      },
      {
        CITY: "STRA",
      },
      {
        CITY: "STRADA SAN ZENO",
      },
      {
        CITY: "STRADELLA",
      },
      {
        CITY: "STRAMBINO",
      },
      {
        CITY: "STRESA",
      },
      {
        CITY: "STRETTI",
      },
      {
        CITY: "STREVI",
      },
      {
        CITY: "STRIANO",
      },
      {
        CITY: "STRIGNO",
      },
      {
        CITY: "STRONA",
      },
      {
        CITY: "STRONCONE",
      },
      {
        CITY: "STRONGOLI",
      },
      {
        CITY: "STROPPIANA",
      },
      {
        CITY: "STURNO",
      },
      {
        CITY: "SUARDI",
      },
      {
        CITY: "SUBBIANO",
      },
      {
        CITY: "SUBIACO",
      },
      {
        CITY: "SULMONA",
      },
      {
        CITY: "SULZANO",
      },
      {
        CITY: "SUMIRAGO",
      },
      {
        CITY: "SUNO",
      },
      {
        CITY: "SUPINO",
      },
      {
        CITY: "SURBO",
      },
      {
        CITY: "SUSA",
      },
      {
        CITY: "SUSEGANA",
      },
      {
        CITY: "SUTRI",
      },
      {
        CITY: "SUTRIO",
      },
      {
        CITY: "SUZZARA",
      },
      {
        CITY: "SYRACUSE",
      },
      {
        CITY: "TAGGIA",
      },
      {
        CITY: "TAGLIACOZZO",
      },
      {
        CITY: "TAGLIO DI PO",
      },
      {
        CITY: "TAIBON AGORDINO",
      },
      {
        CITY: "TAINO",
      },
      {
        CITY: "TAIO",
      },
      {
        CITY: "TALSANO",
      },
      {
        CITY: "TAORMINA",
      },
      {
        CITY: "TARANTA PELIGNA",
      },
      {
        CITY: "TARANTASCA",
      },
      {
        CITY: "TARANTO",
      },
      {
        CITY: "TARCENTO",
      },
      {
        CITY: "TARQUINIA",
      },
      {
        CITY: "TARVISIO",
      },
      {
        CITY: "TARZO",
      },
      {
        CITY: "TASSULLO",
      },
      {
        CITY: "TAURANO",
      },
      {
        CITY: "TAURIANOVA",
      },
      {
        CITY: "TAVAGNACCO",
      },
      {
        CITY: "TAVARNELLE VAL DI PESA",
      },
      {
        CITY: "TAVARNUZZE",
      },
      {
        CITY: "TAVERNA",
      },
      {
        CITY: "TAVERNOLA BERGAMASCA",
      },
      {
        CITY: "TAVIANO",
      },
      {
        CITY: "TAVULLIA",
      },
      {
        CITY: "TEGGIANO",
      },
      {
        CITY: "TEGLIO",
      },
      {
        CITY: "TEGLIO VENETO",
      },
      {
        CITY: "TELESE",
      },
      {
        CITY: "TELGATE",
      },
      {
        CITY: "TEMPIO PAUSANIA",
      },
      {
        CITY: "TENNO",
      },
      {
        CITY: "TEOLO",
      },
      {
        CITY: "TERAMO",
      },
      {
        CITY: "TERENTEN",
      },
      {
        CITY: "TERENZO",
      },
      {
        CITY: "TERLAN",
      },
      {
        CITY: "TERLIZZI",
      },
      {
        CITY: "TERMENO",
      },
      {
        CITY: "TERMINI IMERESE",
      },
      {
        CITY: "TERMOLI",
      },
      {
        CITY: "TERNATE",
      },
      {
        CITY: "TERNI",
      },
      {
        CITY: "TERRACINA",
      },
      {
        CITY: "TERRALBA",
      },
      {
        CITY: "TERRANOVA DA SIBARI",
      },
      {
        CITY: "TERRANUOVA BRACCIOLINI",
      },
      {
        CITY: "TERRAROSSA",
      },
      {
        CITY: "TERRASINI",
      },
      {
        CITY: "TERRICCIOLA",
      },
      {
        CITY: "TERRUGGIA",
      },
      {
        CITY: "TERTENIA",
      },
      {
        CITY: "TERZIGNO",
      },
      {
        CITY: "TERZO",
      },
      {
        CITY: "TERZORIO",
      },
      {
        CITY: "TESSERA",
      },
      {
        CITY: "TEZZE SUL BRENTA",
      },
      {
        CITY: "THIENE",
      },
      {
        CITY: "THIESI",
      },
      {
        CITY: "TICENGO",
      },
      {
        CITY: "TINA",
      },
      {
        CITY: "TIONE DI TRENTO",
      },
      {
        CITY: "TIRANO",
      },
      {
        CITY: "TIRIOLO",
      },
      {
        CITY: "TIRRENIA",
      },
      {
        CITY: "TITO",
      },
      {
        CITY: "TIVOLI",
      },
      {
        CITY: "TOBLACH",
      },
      {
        CITY: "TODI",
      },
      {
        CITY: "TOFFIA",
      },
      {
        CITY: "TOIRANO",
      },
      {
        CITY: "TOLENTINO",
      },
      {
        CITY: "TOLFA",
      },
      {
        CITY: "TOLLEGNO",
      },
      {
        CITY: "TOLMEZZO",
      },
      {
        CITY: "TOLVE",
      },
      {
        CITY: "TOMBA",
      },
      {
        CITY: "TOMBOLO",
      },
      {
        CITY: "TONEZZA DEL CIMONE",
      },
      {
        CITY: "TORANO CASTELLO",
      },
      {
        CITY: "TORCHIAROLO",
      },
      {
        CITY: "TORELLA DEI LOMBARDI",
      },
      {
        CITY: "TORGIANO",
      },
      {
        CITY: "TORGNON",
      },
      {
        CITY: "TORITTO",
      },
      {
        CITY: "TORNACO",
      },
      {
        CITY: "TORNARECCIO",
      },
      {
        CITY: "TORO",
      },
      {
        CITY: "TORRAZZA",
      },
      {
        CITY: "TORRE",
      },
      {
        CITY: "TORRE",
      },
      {
        CITY: "TORRE A MARE",
      },
      {
        CITY: "TORRE ANNUNZIATA",
      },
      {
        CITY: "TORRE BOLDONE",
      },
      {
        CITY: "TORRE DEL GRECO",
      },
      {
        CITY: "TORRE DEL LAGO PUCCINI",
      },
      {
        CITY: "TORRE DI RUGGIERO",
      },
      {
        CITY: "TORRE ORSAIA",
      },
      {
        CITY: "TORRE PELLICE",
      },
      {
        CITY: "TORRE SAN PATRIZIO",
      },
      {
        CITY: "TORRE SANTA SUSANNA",
      },
      {
        CITY: "TORREBELVICINO",
      },
      {
        CITY: "TORRECUSO",
      },
      {
        CITY: "TORREGLIA",
      },
      {
        CITY: "TORREGROTTA",
      },
      {
        CITY: "TORRENOVA",
      },
      {
        CITY: "TORRETTA",
      },
      {
        CITY: "TORREVECCHIA TEATINA",
      },
      {
        CITY: "TORRI",
      },
      {
        CITY: "TORRI DEL BENACO",
      },
      {
        CITY: "TORRI DI QUARTESOLO",
      },
      {
        CITY: "TORRI IN SABINA",
      },
      {
        CITY: "TORRIANA",
      },
      {
        CITY: "TORRICE",
      },
      {
        CITY: "TORRICELLA",
      },
      {
        CITY: "TORRICELLA",
      },
      {
        CITY: "TORRILE",
      },
      {
        CITY: "TORRITA DI SIENA",
      },
      {
        CITY: "TORRITA TIBERINA",
      },
      {
        CITY: "TORTONA",
      },
      {
        CITY: "TORTORA",
      },
      {
        CITY: "TORTORETO",
      },
      {
        CITY: "TORVISCOSA",
      },
      {
        CITY: "TOSCOLANO-MADERNO",
      },
      {
        CITY: "TOSSICIA",
      },
      {
        CITY: "TRABIA",
      },
      {
        CITY: "TRADATE",
      },
      {
        CITY: "TRAMONTI",
      },
      {
        CITY: "TRAMUTOLA",
      },
      {
        CITY: "TRANA",
      },
      {
        CITY: "TRANI",
      },
      {
        CITY: "TRANSACQUA",
      },
      {
        CITY: "TRAONA",
      },
      {
        CITY: "TRAPANI",
      },
      {
        CITY: "TRAPPETO",
      },
      {
        CITY: "TRASACCO",
      },
      {
        CITY: "TRATALIAS",
      },
      {
        CITY: "TRAVAGLIATO",
      },
      {
        CITY: "TRAVEDONA MONATE",
      },
      {
        CITY: "TRAVERSETOLO",
      },
      {
        CITY: "TRAVO",
      },
      {
        CITY: "TREBASELEGHE",
      },
      {
        CITY: "TREBISACCE",
      },
      {
        CITY: "TRECASTAGNI",
      },
      {
        CITY: "TRECATE",
      },
      {
        CITY: "TRECENTA",
      },
      {
        CITY: "TREDOZIO",
      },
      {
        CITY: "TREGLIO",
      },
      {
        CITY: "TREGNAGO",
      },
      {
        CITY: "TREIA",
      },
      {
        CITY: "TRENTO",
      },
      {
        CITY: "TRENTOLA-DUCENTA",
      },
      {
        CITY: "TREPUZZI",
      },
      {
        CITY: "TREQUANDA",
      },
      {
        CITY: "TRES",
      },
      {
        CITY: "TRESCORE BALNEARIO",
      },
      {
        CITY: "TRESCORE CREMASCO",
      },
      {
        CITY: "TRESENDA",
      },
      {
        CITY: "TREVENZUOLO",
      },
      {
        CITY: "TREVI",
      },
      {
        CITY: "TREVICO",
      },
      {
        CITY: "TREVIGLIO",
      },
      {
        CITY: "TREVIGNANO",
      },
      {
        CITY: "TREVIGNANO ROMANO",
      },
      {
        CITY: "TREVISO",
      },
      {
        CITY: "TREZZANO ROSA",
      },
      {
        CITY: "TREZZANO SUL NAVIGLIO",
      },
      {
        CITY: "TRIBIANO",
      },
      {
        CITY: "TRICARICO",
      },
      {
        CITY: "TRICASE",
      },
      {
        CITY: "TRICESIMO",
      },
      {
        CITY: "TRICHIANA",
      },
      {
        CITY: "TRIESTE",
      },
      {
        CITY: "TRIGGIANELLO",
      },
      {
        CITY: "TRIGGIANO",
      },
      {
        CITY: "TRIGOLO",
      },
      {
        CITY: "TRINITAPOLI",
      },
      {
        CITY: "TRINO",
      },
      {
        CITY: "TRISSINO",
      },
      {
        CITY: "TRIUGGIO",
      },
      {
        CITY: "TRIVENTO",
      },
      {
        CITY: "TRIVERO",
      },
      {
        CITY: "TRIVIGNANO",
      },
      {
        CITY: "TRIVIGNANO UDINESE",
      },
      {
        CITY: "TROFARELLO",
      },
      {
        CITY: "TROIA",
      },
      {
        CITY: "TROINA",
      },
      {
        CITY: "TROMELLO",
      },
      {
        CITY: "TRONZANO VERCELLESE",
      },
      {
        CITY: "TROPEA",
      },
      {
        CITY: "TROVO",
      },
      {
        CITY: "TRUCCAZZANO",
      },
      {
        CITY: "TUFINO",
      },
      {
        CITY: "TUGLIE",
      },
      {
        CITY: "TUILI",
      },
      {
        CITY: "TUORO SUL TRASIMENO",
      },
      {
        CITY: "TURBIGO",
      },
      {
        CITY: "TURI",
      },
      {
        CITY: "TURIN",
      },
      {
        CITY: "TURRIACO",
      },
      {
        CITY: "TURSI",
      },
      {
        CITY: "TUSA",
      },
      {
        CITY: "TUSCANIA",
      },
      {
        CITY: "UDINE",
      },
      {
        CITY: "UGENTO",
      },
      {
        CITY: "UGGIATE TREVANO",
      },
      {
        CITY: "UMBERTIDE",
      },
      {
        CITY: "URBANIA",
      },
      {
        CITY: "URBINO",
      },
      {
        CITY: "URBISAGLIA",
      },
      {
        CITY: "URGNANO",
      },
      {
        CITY: "URURI",
      },
      {
        CITY: "USCIO",
      },
      {
        CITY: "USELLUS",
      },
      {
        CITY: "USINI",
      },
      {
        CITY: "USMATE VELATE",
      },
      {
        CITY: "USSANA",
      },
      {
        CITY: "UTA",
      },
      {
        CITY: "UZZANO",
      },
      {
        CITY: "VACRI",
      },
      {
        CITY: "VADENA",
      },
      {
        CITY: "VADO",
      },
      {
        CITY: "VADO LIGURE",
      },
      {
        CITY: "VAGLIA",
      },
      {
        CITY: "VAGLIO BASILICATA",
      },
      {
        CITY: "VAHRN",
      },
      {
        CITY: "VAIANO",
      },
      {
        CITY: "VAIANO CREMASCO",
      },
      {
        CITY: "VAILATE",
      },
      {
        CITY: "VAIRANO PATENORA",
      },
      {
        CITY: "VAJONT",
      },
      {
        CITY: "VALDAGNO",
      },
      {
        CITY: "VALDENGO",
      },
      {
        CITY: "VALDIERI",
      },
      {
        CITY: "VALDOBBIADENE",
      },
      {
        CITY: "VALDUGGIA",
      },
      {
        CITY: "VALEGGIO SUL MINCIO",
      },
      {
        CITY: "VALENTANO",
      },
      {
        CITY: "VALENTINA",
      },
      {
        CITY: "VALENZA",
      },
      {
        CITY: "VALENZANO",
      },
      {
        CITY: "VALFABBRICA",
      },
      {
        CITY: "VALFENERA",
      },
      {
        CITY: "VALGANNA",
      },
      {
        CITY: "VALGUARNERA CAROPEPE",
      },
      {
        CITY: "VALLATA",
      },
      {
        CITY: "VALLE",
      },
      {
        CITY: "VALLE",
      },
      {
        CITY: "VALLE",
      },
      {
        CITY: "VALLE AGRICOLA",
      },
      {
        CITY: "VALLE DI CADORE",
      },
      {
        CITY: "VALLE LOMELLINA",
      },
      {
        CITY: "VALLE MOSSO",
      },
      {
        CITY: "VALLECROSIA",
      },
      {
        CITY: "VALLEFIORITA",
      },
      {
        CITY: "VALLERANO",
      },
      {
        CITY: "VALLI DEL PASUBIO",
      },
      {
        CITY: "VALLO",
      },
      {
        CITY: "VALLO DELLA LUCANIA",
      },
      {
        CITY: "VALLOMBROSA",
      },
      {
        CITY: "VALMADRERA",
      },
      {
        CITY: "VALMONTONE",
      },
      {
        CITY: "VALPERGA",
      },
      {
        CITY: "VALVERDE",
      },
      {
        CITY: "VANZAGHELLO",
      },
      {
        CITY: "VANZAGO",
      },
      {
        CITY: "VARALLO POMBIA",
      },
      {
        CITY: "VARALLO SESIA",
      },
      {
        CITY: "VARANO",
      },
      {
        CITY: "VARAPODIO",
      },
      {
        CITY: "VARAZZE",
      },
      {
        CITY: "VAREDO",
      },
      {
        CITY: "VARESE",
      },
      {
        CITY: "VARESE LIGURE",
      },
      {
        CITY: "VARZO",
      },
      {
        CITY: "VAS",
      },
      {
        CITY: "VASANELLO",
      },
      {
        CITY: "VASCON",
      },
      {
        CITY: "VASTO",
      },
      {
        CITY: "VATTARO",
      },
      {
        CITY: "VECCHIANO-NODICA",
      },
      {
        CITY: "VEDANO OLONA",
      },
      {
        CITY: "VEDELAGO",
      },
      {
        CITY: "VEGLIE",
      },
      {
        CITY: "VELLETRI",
      },
      {
        CITY: "VELLEZZO BELLINI",
      },
      {
        CITY: "VENA DI MAIDA",
      },
      {
        CITY: "VENAFRO",
      },
      {
        CITY: "VENARIA REALE",
      },
      {
        CITY: "VENAROTTA",
      },
      {
        CITY: "VENAUS",
      },
      {
        CITY: "VENEGONO INFERIORE",
      },
      {
        CITY: "VENEGONO SUPERIORE",
      },
      {
        CITY: "VENETICO",
      },
      {
        CITY: "VENETO",
      },
      {
        CITY: "VENICE",
      },
      {
        CITY: "VENOSA",
      },
      {
        CITY: "VENTIMIGLIA",
      },
      {
        CITY: "VENTIMIGLIA DI SICILIA",
      },
      {
        CITY: "VENTURINA",
      },
      {
        CITY: "VERBICARO",
      },
      {
        CITY: "VERCELLI",
      },
      {
        CITY: "VERCURAGO",
      },
      {
        CITY: "VERDELLINO",
      },
      {
        CITY: "VERDELLO",
      },
      {
        CITY: "VERGANO-VILLA",
      },
      {
        CITY: "VERGATO",
      },
      {
        CITY: "VERGIATE",
      },
      {
        CITY: "VERMEZZO",
      },
      {
        CITY: "VERNATE",
      },
      {
        CITY: "VERNIO",
      },
      {
        CITY: "VERNOLE",
      },
      {
        CITY: "VEROLANUOVA",
      },
      {
        CITY: "VEROLAVECCHIA",
      },
      {
        CITY: "VEROLENGO",
      },
      {
        CITY: "VEROLI",
      },
      {
        CITY: "VERONA",
      },
      {
        CITY: "VERONELLA",
      },
      {
        CITY: "VERRONE",
      },
      {
        CITY: "VERTOVA",
      },
      {
        CITY: "VERUCCHIO",
      },
      {
        CITY: "VERZUOLO",
      },
      {
        CITY: "VESCOVANA",
      },
      {
        CITY: "VESCOVATO",
      },
      {
        CITY: "VESPOLATE",
      },
      {
        CITY: "VESTONE",
      },
      {
        CITY: "VETRALLA",
      },
      {
        CITY: "VEZZANO LIGURE",
      },
      {
        CITY: "VEZZANO SUL CROSTOLO",
      },
      {
        CITY: "VIADANA",
      },
      {
        CITY: "VIAGRANDE",
      },
      {
        CITY: "VIANO",
      },
      {
        CITY: "VIAREGGIO",
      },
      {
        CITY: "VIBO VALENTIA",
      },
      {
        CITY: "VICARELLO",
      },
      {
        CITY: "VICARI",
      },
      {
        CITY: "VICCHIO",
      },
      {
        CITY: "VICENZA",
      },
      {
        CITY: "VICO EQUENSE",
      },
      {
        CITY: "VICOFORTE",
      },
      {
        CITY: "VICOLUNGO",
      },
      {
        CITY: "VICOPISANO",
      },
      {
        CITY: "VICOVARO",
      },
      {
        CITY: "VIDARDO",
      },
      {
        CITY: "VIDICIATICO",
      },
      {
        CITY: "VIDIGULFO",
      },
      {
        CITY: "VIESTE",
      },
      {
        CITY: "VIETRI SUL MARE",
      },
      {
        CITY: "VIGARANO MAINARDA",
      },
      {
        CITY: "VIGARANO PIEVE",
      },
      {
        CITY: "VIGASIO",
      },
      {
        CITY: "VIGEVANO",
      },
      {
        CITY: "VIGLIANO BIELLESE",
      },
      {
        CITY: "VIGNACASTRISI",
      },
      {
        CITY: "VIGNALE MONFERRATO",
      },
      {
        CITY: "VIGNANELLO",
      },
      {
        CITY: "VIGNATE",
      },
      {
        CITY: "VIGNOLA",
      },
      {
        CITY: "VIGO DI CADORE",
      },
      {
        CITY: "VIGO DI FASSA",
      },
      {
        CITY: "VIGO RENDENA",
      },
      {
        CITY: "VIGODARZERE",
      },
      {
        CITY: "VIGOLO VATTARO",
      },
      {
        CITY: "VIGOLZONE",
      },
      {
        CITY: "VIGONE",
      },
      {
        CITY: "VIGONOVO",
      },
      {
        CITY: "VIGONZA",
      },
      {
        CITY: "VIGUZZOLO",
      },
      {
        CITY: "VILLA",
      },
      {
        CITY: "VILLA",
      },
      {
        CITY: "VILLA BARTOLOMEA",
      },
      {
        CITY: "VILLA BASILICA",
      },
      {
        CITY: "VILLA CASTELLI",
      },
      {
        CITY: "VILLA CORTESE",
      },
      {
        CITY: "VILLA DEL CONTE",
      },
      {
        CITY: "VILLA DI SERIO",
      },
      {
        CITY: "VILLA DI TIRANO",
      },
      {
        CITY: "VILLA ESTENSE",
      },
      {
        CITY: "VILLA FARALDI",
      },
      {
        CITY: "VILLA GUARDIA",
      },
      {
        CITY: "VILLA LAGARINA",
      },
      {
        CITY: "VILLA LITERNO",
      },
      {
        CITY: "VILLA MARINA",
      },
      {
        CITY: "VILLA MINOZZO",
      },
      {
        CITY: "VILLA MORELLI",
      },
      {
        CITY: "VILLA OTTONE",
      },
      {
        CITY: "VILLA SAN GIOVANNI",
      },
      {
        CITY: "VILLA SAN PIETRO",
      },
      {
        CITY: "VILLA SAN SEBASTIANO",
      },
      {
        CITY: "VILLA SANTA LUCIA",
      },
      {
        CITY: "VILLA VERUCCHIO",
      },
      {
        CITY: "VILLA VICENTINA",
      },
      {
        CITY: "VILLABATE",
      },
      {
        CITY: "VILLACIDRO",
      },
      {
        CITY: "VILLADOSE",
      },
      {
        CITY: "VILLAFRANCA DI FORLI",
      },
      {
        CITY: "VILLAFRANCA DI VERONA",
      },
      {
        CITY: "VILLAFRANCA IN LUNIGIANA",
      },
      {
        CITY: "VILLAFRANCA PIEMONTE",
      },
      {
        CITY: "VILLAFRANCA TIRRENA",
      },
      {
        CITY: "VILLAFRATI",
      },
      {
        CITY: "VILLAGGIO",
      },
      {
        CITY: "VILLAGGIO MONTEGRAPPA",
      },
      {
        CITY: "VILLAGRAZIA",
      },
      {
        CITY: "VILLALAGO",
      },
      {
        CITY: "VILLAMAGNA",
      },
      {
        CITY: "VILLAMAR",
      },
      {
        CITY: "VILLAMASSARGIA",
      },
      {
        CITY: "VILLANOVA",
      },
      {
        CITY: "VILLANOVA",
      },
      {
        CITY: "VILLANOVA",
      },
      {
        CITY: "VILLANOVA DEL GHEBBO-VALDENTRO",
      },
      {
        CITY: "VILLANOVA MONFERRATO",
      },
      {
        CITY: "VILLANOVAFRANCA",
      },
      {
        CITY: "VILLANTERIO",
      },
      {
        CITY: "VILLANUOVA SUL CLISI",
      },
      {
        CITY: "VILLAPIANA",
      },
      {
        CITY: "VILLAPUTZU",
      },
      {
        CITY: "VILLAR",
      },
      {
        CITY: "VILLARICCA",
      },
      {
        CITY: "VILLAROSA",
      },
      {
        CITY: "VILLASALTO",
      },
      {
        CITY: "VILLASANTA",
      },
      {
        CITY: "VILLASMUNDO",
      },
      {
        CITY: "VILLASOR",
      },
      {
        CITY: "VILLASPECIOSA",
      },
      {
        CITY: "VILLASTELLONE",
      },
      {
        CITY: "VILLASTRADA",
      },
      {
        CITY: "VILLAURBANA",
      },
      {
        CITY: "VILLAVERLA",
      },
      {
        CITY: "VILLAVESCO",
      },
      {
        CITY: "VILLE",
      },
      {
        CITY: "VILLETTA",
      },
      {
        CITY: "VILLETTA",
      },
      {
        CITY: "VIMERCATE",
      },
      {
        CITY: "VIMODRONE",
      },
      {
        CITY: "VINCI",
      },
      {
        CITY: "VIOLA",
      },
      {
        CITY: "VIRGILIO",
      },
      {
        CITY: "VISCONE",
      },
      {
        CITY: "VISERBA",
      },
      {
        CITY: "VISINALE",
      },
      {
        CITY: "VISTRORIO",
      },
      {
        CITY: "VITERBO",
      },
      {
        CITY: "VITIGLIANO",
      },
      {
        CITY: "VITORCHIANO",
      },
      {
        CITY: "VITTORIA",
      },
      {
        CITY: "VITTORIO VENETO",
      },
      {
        CITY: "VITTUONE",
      },
      {
        CITY: "VITULANO",
      },
      {
        CITY: "VITULAZIO",
      },
      {
        CITY: "VIVARO",
      },
      {
        CITY: "VIZZINI",
      },
      {
        CITY: "VO",
      },
      {
        CITY: "VOBARNO",
      },
      {
        CITY: "VOGHENZA",
      },
      {
        CITY: "VOGHERA",
      },
      {
        CITY: "VOGOGNA",
      },
      {
        CITY: "VOLANO",
      },
      {
        CITY: "VOLLA",
      },
      {
        CITY: "VOLPAGO DEL MONTELLO",
      },
      {
        CITY: "VOLPEDO",
      },
      {
        CITY: "VOLPIANO",
      },
      {
        CITY: "VOLTA MANTOVANA",
      },
      {
        CITY: "VOLTAGO",
      },
      {
        CITY: "VOLTERRA",
      },
      {
        CITY: "VOLTURARA IRPINA",
      },
      {
        CITY: "VOLTURINO",
      },
      {
        CITY: "VOLVERA",
      },
      {
        CITY: "WELSBERG-TAISTEN",
      },
      {
        CITY: "WELSCHNOFEN",
      },
      {
        CITY: "ZAFFERANA ETNEA",
      },
      {
        CITY: "ZAGAROLO",
      },
      {
        CITY: "ZAMBANA",
      },
      {
        CITY: "ZAMBRONE",
      },
      {
        CITY: "ZANDOBBIO",
      },
      {
        CITY: "ZANETTI",
      },
      {
        CITY: "ZANGARONA",
      },
      {
        CITY: "ZANICA",
      },
      {
        CITY: "ZELARINO",
      },
      {
        CITY: "ZELO",
      },
      {
        CITY: "ZELO BUON PERSICO",
      },
      {
        CITY: "ZEME",
      },
      {
        CITY: "ZERBO",
      },
      {
        CITY: "ZERFALIU",
      },
      {
        CITY: "ZERMEGHEDO",
      },
      {
        CITY: "ZERO BRANCO",
      },
      {
        CITY: "ZEVIO",
      },
      {
        CITY: "ZIBIDO SAN GIACOMO",
      },
      {
        CITY: "ZIMELLA",
      },
      {
        CITY: "ZIMONE",
      },
      {
        CITY: "ZINASCO",
      },
      {
        CITY: "ZOAGLI",
      },
      {
        CITY: "ZOCCA",
      },
      {
        CITY: "ZOGNO",
      },
      {
        CITY: "ZOLA PREDOSA",
      },
      {
        CITY: "ZOLDO ALTO",
      },
      {
        CITY: "ZOLLINO",
      },
      {
        CITY: "ZOPPOLA",
      },
      {
        CITY: "ZUMAGLIA",
      },
    ],
  },
  {
    COUNTRY: "LATVIA",
    CODE: "LV",
    CITIES: [
      {
        CITY: "ADAZI",
      },
      {
        CITY: "AGENSKALNS",
      },
      {
        CITY: "AIZKRAUKLE",
      },
      {
        CITY: "AIZPUTE",
      },
      {
        CITY: "BALDONE",
      },
      {
        CITY: "BALVI",
      },
      {
        CITY: "BAUSKA",
      },
      {
        CITY: "BRANKAS",
      },
      {
        CITY: "CARNIKAVA",
      },
      {
        CITY: "CENTRS",
      },
      {
        CITY: "DAUGAVPILS",
      },
      {
        CITY: "DOBELE",
      },
      {
        CITY: "DURBE",
      },
      {
        CITY: "GULBENE",
      },
      {
        CITY: "ILGUCIEMS",
      },
      {
        CITY: "IZVALTA",
      },
      {
        CITY: "JAUNOLAINE",
      },
      {
        CITY: "JELGAVA",
      },
      {
        CITY: "KANDAVA",
      },
      {
        CITY: "KOLKA",
      },
      {
        CITY: "LAPMEZCIEMS",
      },
      {
        CITY: "LIEPA",
      },
      {
        CITY: "LUDZA",
      },
      {
        CITY: "MADONA",
      },
      {
        CITY: "MALPILS",
      },
      {
        CITY: "MALTA",
      },
      {
        CITY: "MARUPE",
      },
      {
        CITY: "MAZSALACA",
      },
      {
        CITY: "OGRE",
      },
      {
        CITY: "OLAINE",
      },
      {
        CITY: "OZOLNIEKI",
      },
      {
        CITY: "PILSRUNDALE",
      },
      {
        CITY: "PRIEDKALNE",
      },
      {
        CITY: "PURE",
      },
      {
        CITY: "RIGA",
      },
      {
        CITY: "SALASPILS",
      },
      {
        CITY: "SALDUS",
      },
      {
        CITY: "SIGULDA",
      },
      {
        CITY: "SMILTENE",
      },
      {
        CITY: "STALGENE",
      },
      {
        CITY: "TALSI",
      },
      {
        CITY: "TUKUMS",
      },
      {
        CITY: "VALKA",
      },
      {
        CITY: "VALMIERA",
      },
      {
        CITY: "VARSAVA",
      },
      {
        CITY: "VENTSPILS",
      },
      {
        CITY: "ZASA",
      },
      {
        CITY: "ZILUPE",
      },
    ],
  },
  {
    COUNTRY: "LIECHTENSTEIN",
    CODE: "LI",
    CITIES: [
      {
        CITY: "BALZERS",
      },
      {
        CITY: "BENDERN",
      },
      {
        CITY: "ESCHEN",
      },
      {
        CITY: "GAMPRIN",
      },
      {
        CITY: "MAUREN",
      },
      {
        CITY: "NENDELN",
      },
      {
        CITY: "PLANKEN",
      },
      {
        CITY: "RUGGELL",
      },
      {
        CITY: "SCHAAN",
      },
      {
        CITY: "SCHAANWALD",
      },
      {
        CITY: "SCHELLENBERG",
      },
      {
        CITY: "TRIESEN",
      },
      {
        CITY: "TRIESENBERG",
      },
      {
        CITY: "VADUZ",
      },
    ],
  },
  {
    COUNTRY: "KUWAIT",
    CODE: "KW",
    CITIES: [
      {
        CITY: "ABRAQ KHAYTAN",
      },
      {
        CITY: "AD DASMAH",
      },
      {
        CITY: "AD DAWHAH",
      },
      {
        CITY: "AL AHMADI",
      },
      {
        CITY: "AL FARWANIYAH",
      },
      {
        CITY: "AL SHAMIYA",
      },
      {
        CITY: "AR RAWDAH",
      },
      {
        CITY: "AS SALIMIYAH",
      },
      {
        CITY: "ASH SHU`AYBAH",
      },
      {
        CITY: "ASH SHUWAYKH",
      },
      {
        CITY: "BAYAN",
      },
      {
        CITY: "HAWALLI",
      },
      {
        CITY: "JANUB AS SURRAH",
      },
      {
        CITY: "KAYFAN",
      },
      {
        CITY: "KUWAIT CITY",
      },
      {
        CITY: "SALWA",
      },
    ],
  },
  {
    COUNTRY: "KENYA",
    CODE: "KE",
    CITIES: [
      {
        CITY: "BONDO",
      },
      {
        CITY: "CHUKA",
      },
      {
        CITY: "ELDORET",
      },
      {
        CITY: "KABETE",
      },
      {
        CITY: "KAIBOI",
      },
      {
        CITY: "KARATINA",
      },
      {
        CITY: "KIAMBU",
      },
      {
        CITY: "KIKUYU",
      },
      {
        CITY: "KISII",
      },
      {
        CITY: "KISUMU",
      },
      {
        CITY: "KITALE",
      },
      {
        CITY: "KITUI",
      },
      {
        CITY: "MACHAKOS",
      },
      {
        CITY: "MASENO",
      },
      {
        CITY: "MBITA",
      },
      {
        CITY: "MOMBASA",
      },
      {
        CITY: "NAIROBI",
      },
      {
        CITY: "NAKURU",
      },
      {
        CITY: "SAWA SAWA",
      },
      {
        CITY: "SIAYA",
      },
      {
        CITY: "THIKA",
      },
      {
        CITY: "TURBO",
      },
      {
        CITY: "WOTE",
      },
    ],
  },
  {
    COUNTRY: "KOSOVO",
    CODE: "XK",
    CITIES: [
      {
        CITY: "DUNAVO",
      },
      {
        CITY: "FERIZAJ",
      },
      {
        CITY: "MITROVICA",
      },
      {
        CITY: "PRISTINA",
      },
      {
        CITY: "PRIZREN",
      },
    ],
  },
  {
    COUNTRY: "LUXEMBOURG",
    CODE: "LU",
    CITIES: [
      {
        CITY: "AHN",
      },
      {
        CITY: "ALZINGEN",
      },
      {
        CITY: "BASCHARAGE",
      },
      {
        CITY: "BEAUFORT",
      },
      {
        CITY: "BECKERICH",
      },
      {
        CITY: "BEGGEN",
      },
      {
        CITY: "BEIDWEILER",
      },
      {
        CITY: "BELVAUX",
      },
      {
        CITY: "BERCHEM",
      },
      {
        CITY: "BERELDANGE",
      },
      {
        CITY: "BERGEM",
      },
      {
        CITY: "BERTRANGE",
      },
      {
        CITY: "BETTEMBOURG",
      },
      {
        CITY: "BETZDORF",
      },
      {
        CITY: "BIGONVILLE",
      },
      {
        CITY: "BISSEN",
      },
      {
        CITY: "BIVANGE",
      },
      {
        CITY: "BOFFERDANGE",
      },
      {
        CITY: "BONNEVOIE",
      },
      {
        CITY: "BOURGLINSTER",
      },
      {
        CITY: "BRIDEL",
      },
      {
        CITY: "BURMERANGE",
      },
      {
        CITY: "CANACH",
      },
      {
        CITY: "CAP",
      },
      {
        CITY: "CAPELLEN",
      },
      {
        CITY: "COLMAR",
      },
      {
        CITY: "CONTERN",
      },
      {
        CITY: "CRAUTHEM",
      },
      {
        CITY: "DALHEIM",
      },
      {
        CITY: "DIFFERDANGE",
      },
      {
        CITY: "DIPPACH",
      },
      {
        CITY: "DUDELANGE",
      },
      {
        CITY: "ECHTERNACH",
      },
      {
        CITY: "EHNEN",
      },
      {
        CITY: "ERNSTER",
      },
      {
        CITY: "ERPELDANGE-LEZ-BOUS",
      },
      {
        CITY: "ESCH-SUR-ALZETTE",
      },
      {
        CITY: "ESCH-SUR-SURE",
      },
      {
        CITY: "ETTELBRUCK",
      },
      {
        CITY: "FOETZ",
      },
      {
        CITY: "FRISANGE",
      },
      {
        CITY: "GODBRANGE",
      },
      {
        CITY: "GOETZINGEN",
      },
      {
        CITY: "GONDERANGE",
      },
      {
        CITY: "GOSSELDANGE",
      },
      {
        CITY: "GOSTINGEN",
      },
      {
        CITY: "GREVENKNAPP",
      },
      {
        CITY: "GREVENMACHER",
      },
      {
        CITY: "GROSBOUS",
      },
      {
        CITY: "HAGEN",
      },
      {
        CITY: "HAUTCHARAGE",
      },
      {
        CITY: "HEFFINGEN",
      },
      {
        CITY: "HEISDORF-SUR-ALZETTE",
      },
      {
        CITY: "HELMDANGE",
      },
      {
        CITY: "HELMSANGE",
      },
      {
        CITY: "HERSBERG",
      },
      {
        CITY: "HESPERANGE",
      },
      {
        CITY: "HOBSCHEID",
      },
      {
        CITY: "HOLLERICH",
      },
      {
        CITY: "HOLZEM",
      },
      {
        CITY: "HUNCHERANGE",
      },
      {
        CITY: "HUNSDORF",
      },
      {
        CITY: "ITZIG",
      },
      {
        CITY: "JUNGLINSTER",
      },
      {
        CITY: "KAYL",
      },
      {
        CITY: "KEHLEN",
      },
      {
        CITY: "KLEINBETTINGEN",
      },
      {
        CITY: "KOETSCHETTE",
      },
      {
        CITY: "LAMADELAINE",
      },
      {
        CITY: "LAROCHETTE",
      },
      {
        CITY: "LEUDELANGE",
      },
      {
        CITY: "LIMPERTSBERG",
      },
      {
        CITY: "LINTGEN",
      },
      {
        CITY: "LORENTZWEILER",
      },
      {
        CITY: "LUXEMBOURG",
      },
      {
        CITY: "LUXEMBOURG-KIRCHBERG",
      },
      {
        CITY: "MAMER",
      },
      {
        CITY: "MEDERNACH",
      },
      {
        CITY: "MERL",
      },
      {
        CITY: "MERSCH",
      },
      {
        CITY: "MERTERT",
      },
      {
        CITY: "MONDERCANGE",
      },
      {
        CITY: "MONDORF-LES-BAINS",
      },
      {
        CITY: "MOUTFORT",
      },
      {
        CITY: "NIEDERCORN",
      },
      {
        CITY: "NOERDANGE",
      },
      {
        CITY: "NOSPELT",
      },
      {
        CITY: "OBERANVEN",
      },
      {
        CITY: "OETRANGE",
      },
      {
        CITY: "OLM",
      },
      {
        CITY: "PONTPIERRE",
      },
      {
        CITY: "REMERSCHEN",
      },
      {
        CITY: "REMICH",
      },
      {
        CITY: "RODANGE",
      },
      {
        CITY: "ROESER",
      },
      {
        CITY: "ROODT-SUR-SYRE",
      },
      {
        CITY: "RUMELANGE",
      },
      {
        CITY: "SANDWEILER",
      },
      {
        CITY: "SANEM",
      },
      {
        CITY: "SCHIEREN",
      },
      {
        CITY: "SCHIFFLANGE",
      },
      {
        CITY: "SCHOOS",
      },
      {
        CITY: "SCHOUWEILER",
      },
      {
        CITY: "SCHUTTRANGE",
      },
      {
        CITY: "SOLEUVRE",
      },
      {
        CITY: "STEINFORT",
      },
      {
        CITY: "STEINSEL",
      },
      {
        CITY: "STRASSEN",
      },
      {
        CITY: "SYREN",
      },
      {
        CITY: "TETANGE",
      },
      {
        CITY: "TUNTANGE",
      },
      {
        CITY: "UEBERSYREN",
      },
      {
        CITY: "WALFERDANGE",
      },
      {
        CITY: "WASSERBILLIG",
      },
      {
        CITY: "WOLPERT",
      },
      {
        CITY: "WORMELDANGE",
      },
    ],
  },
  {
    COUNTRY: "NICARAGUA",
    CODE: "NI",
    CITIES: [
      {
        CITY: "BLUEFIELDS",
      },
      {
        CITY: "CHINANDEGA",
      },
      {
        CITY: "EL PANAMA",
      },
      {
        CITY: "ESTELI",
      },
      {
        CITY: "GRANADA",
      },
      {
        CITY: "JINOTEGA",
      },
      {
        CITY: "LOS ARADOS",
      },
      {
        CITY: "MANAGUA",
      },
      {
        CITY: "MASAYA",
      },
      {
        CITY: "MATAGALPA",
      },
      {
        CITY: "OCOTAL",
      },
      {
        CITY: "RIVAS",
      },
      {
        CITY: "SAN JUAN DEL SUR",
      },
    ],
  },
  {
    COUNTRY: "NETHERLANDS",
    CODE: "NL",
    CITIES: [
      {
        CITY: "AAGTEKERKE",
      },
      {
        CITY: "AALBURG",
      },
      {
        CITY: "AALDEN",
      },
      {
        CITY: "AALSMEER",
      },
      {
        CITY: "AALSMEERDERBRUG",
      },
      {
        CITY: "AALST",
      },
      {
        CITY: "AALTEN",
      },
      {
        CITY: "AARDENBURG",
      },
      {
        CITY: "ABBEKERK",
      },
      {
        CITY: "ABBENES",
      },
      {
        CITY: "ABCOUDE",
      },
      {
        CITY: "ACHTERBERG",
      },
      {
        CITY: "ACHTERVELD",
      },
      {
        CITY: "ACHTHUIZEN",
      },
      {
        CITY: "ACHTMAAL",
      },
      {
        CITY: "ADUARD",
      },
      {
        CITY: "AERDENHOUT",
      },
      {
        CITY: "AERDT",
      },
      {
        CITY: "AFFERDEN",
      },
      {
        CITY: "AKERSLOOT",
      },
      {
        CITY: "AKKRUM",
      },
      {
        CITY: "ALBERGEN",
      },
      {
        CITY: "ALBLASSERDAM",
      },
      {
        CITY: "ALDE LEIE",
      },
      {
        CITY: "ALEM",
      },
      {
        CITY: "ALKMAAR",
      },
      {
        CITY: "ALMELO",
      },
      {
        CITY: "ALMERE",
      },
      {
        CITY: "ALMERE HAVEN",
      },
      {
        CITY: "ALMKERK",
      },
      {
        CITY: "ALPHEN",
      },
      {
        CITY: "ALPHEN AAN DEN RIJN",
      },
      {
        CITY: "AMEIDE",
      },
      {
        CITY: "AMERICA",
      },
      {
        CITY: "AMERONGEN",
      },
      {
        CITY: "AMERSFOORT",
      },
      {
        CITY: "AMMERSTOL",
      },
      {
        CITY: "AMMERZODEN",
      },
      {
        CITY: "AMSTELHOEK",
      },
      {
        CITY: "AMSTELVEEN",
      },
      {
        CITY: "AMSTENRADE",
      },
      {
        CITY: "AMSTERDAM",
      },
      {
        CITY: "ANDEREN",
      },
      {
        CITY: "ANDIJK",
      },
      {
        CITY: "ANE",
      },
      {
        CITY: "ANGEREN",
      },
      {
        CITY: "ANJUM",
      },
      {
        CITY: "ANKEVEEN",
      },
      {
        CITY: "ANLOO",
      },
      {
        CITY: "ANNA PAULOWNA",
      },
      {
        CITY: "ANNEN",
      },
      {
        CITY: "APELDOORN",
      },
      {
        CITY: "APPELSCHA",
      },
      {
        CITY: "APPELTERN",
      },
      {
        CITY: "APPINGEDAM",
      },
      {
        CITY: "ARCEN",
      },
      {
        CITY: "ARKEL",
      },
      {
        CITY: "ARNEMUIDEN",
      },
      {
        CITY: "ARNHEM",
      },
      {
        CITY: "ASPEREN",
      },
      {
        CITY: "ASSEN",
      },
      {
        CITY: "ASSENDELFT",
      },
      {
        CITY: "ASTEN",
      },
      {
        CITY: "AUGUSTINUSGA",
      },
      {
        CITY: "AVENHORN",
      },
      {
        CITY: "AXEL",
      },
      {
        CITY: "BAAMBRUGGE",
      },
      {
        CITY: "BAARLAND",
      },
      {
        CITY: "BAARLE-NASSAU",
      },
      {
        CITY: "BAARLO",
      },
      {
        CITY: "BAARN",
      },
      {
        CITY: "BAARS",
      },
      {
        CITY: "BABBERICH",
      },
      {
        CITY: "BADHOEVEDORP",
      },
      {
        CITY: "BAEXEM",
      },
      {
        CITY: "BAKEL",
      },
      {
        CITY: "BAKEN",
      },
      {
        CITY: "BAKHUIZEN",
      },
      {
        CITY: "BALGOIJ",
      },
      {
        CITY: "BALK",
      },
      {
        CITY: "BALLUM",
      },
      {
        CITY: "BANHOLT",
      },
      {
        CITY: "BARCHEM",
      },
      {
        CITY: "BARENDRECHT",
      },
      {
        CITY: "BARNEVELD",
      },
      {
        CITY: "BATENBURG",
      },
      {
        CITY: "BATHMEN",
      },
      {
        CITY: "BAVEL",
      },
      {
        CITY: "BEDUM",
      },
      {
        CITY: "BEEGDEN",
      },
      {
        CITY: "BEEK",
      },
      {
        CITY: "BEEK",
      },
      {
        CITY: "BEEK",
      },
      {
        CITY: "BEEKBERGEN",
      },
      {
        CITY: "BEERTA",
      },
      {
        CITY: "BEERZERVELD",
      },
      {
        CITY: "BEESD",
      },
      {
        CITY: "BEESEL",
      },
      {
        CITY: "BEETS",
      },
      {
        CITY: "BEETSTERZWAAG",
      },
      {
        CITY: "BEILEN",
      },
      {
        CITY: "BEINSDORP",
      },
      {
        CITY: "BELFELD",
      },
      {
        CITY: "BELLINGWOLDE",
      },
      {
        CITY: "BEMELEN",
      },
      {
        CITY: "BEMMEL",
      },
      {
        CITY: "BENEDEN-LEEUWEN",
      },
      {
        CITY: "BENNEBROEK",
      },
      {
        CITY: "BENNEKOM",
      },
      {
        CITY: "BENNINGBROEK",
      },
      {
        CITY: "BENTHUIZEN",
      },
      {
        CITY: "BERG",
      },
      {
        CITY: "BERG EN DAL",
      },
      {
        CITY: "BERGAMBACHT",
      },
      {
        CITY: "BERGEIJK",
      },
      {
        CITY: "BERGEN",
      },
      {
        CITY: "BERGEN",
      },
      {
        CITY: "BERGEN OP ZOOM",
      },
      {
        CITY: "BERGENTHEIM",
      },
      {
        CITY: "BERGHAREN",
      },
      {
        CITY: "BERGHEM",
      },
      {
        CITY: "BERGSCHENHOEK",
      },
      {
        CITY: "BERINGE",
      },
      {
        CITY: "BERKENWOUDE",
      },
      {
        CITY: "BERKHOUT",
      },
      {
        CITY: "BERLICUM",
      },
      {
        CITY: "BEST",
      },
      {
        CITY: "BEUGEN",
      },
      {
        CITY: "BEUNINGEN",
      },
      {
        CITY: "BEUSICHEM",
      },
      {
        CITY: "BEVERWIJK",
      },
      {
        CITY: "BIDDINGHUIZEN",
      },
      {
        CITY: "BIERUM",
      },
      {
        CITY: "BIERVLIET",
      },
      {
        CITY: "BIEST",
      },
      {
        CITY: "BILTHOVEN",
      },
      {
        CITY: "BITGUM",
      },
      {
        CITY: "BLADEL",
      },
      {
        CITY: "BLARICUM",
      },
      {
        CITY: "BLEISWIJK",
      },
      {
        CITY: "BLERICK",
      },
      {
        CITY: "BLESKENSGRAAF",
      },
      {
        CITY: "BLITTERSWIJCK",
      },
      {
        CITY: "BLOEMENDAAL",
      },
      {
        CITY: "BLOKKER",
      },
      {
        CITY: "BLOKZIJL",
      },
      {
        CITY: "BOCHOLTZ",
      },
      {
        CITY: "BODEGRAVEN",
      },
      {
        CITY: "BOEICOP",
      },
      {
        CITY: "BOEKEL",
      },
      {
        CITY: "BOELENSLAAN",
      },
      {
        CITY: "BOERAKKER",
      },
      {
        CITY: "BOESINGHELIEDE",
      },
      {
        CITY: "BOLSWARD",
      },
      {
        CITY: "BORCULO",
      },
      {
        CITY: "BORGER",
      },
      {
        CITY: "BORN",
      },
      {
        CITY: "BORNE",
      },
      {
        CITY: "BORSELE",
      },
      {
        CITY: "BOSCH EN DUIN",
      },
      {
        CITY: "BOSKOOP",
      },
      {
        CITY: "BOSSCHENHOOFD",
      },
      {
        CITY: "BOURTANGE",
      },
      {
        CITY: "BOVEN-HARDINXVELD",
      },
      {
        CITY: "BOVEN-LEEUWEN",
      },
      {
        CITY: "BOVENKARSPEL",
      },
      {
        CITY: "BOVENSMILDE",
      },
      {
        CITY: "BOXMEER",
      },
      {
        CITY: "BOXTEL",
      },
      {
        CITY: "BRAKEL",
      },
      {
        CITY: "BREDA",
      },
      {
        CITY: "BREDEVOORT",
      },
      {
        CITY: "BREEZAND",
      },
      {
        CITY: "BRESKENS",
      },
      {
        CITY: "BREUGEL",
      },
      {
        CITY: "BREUKELEN",
      },
      {
        CITY: "BREUKELEVEEN",
      },
      {
        CITY: "BRIELLE",
      },
      {
        CITY: "BRILTIL",
      },
      {
        CITY: "BRITSUM",
      },
      {
        CITY: "BROEK IN WATERLAND",
      },
      {
        CITY: "BROEK OP LANGEDIJK",
      },
      {
        CITY: "BROEKHIN",
      },
      {
        CITY: "BROEKHUIZEN",
      },
      {
        CITY: "BROUWERSHAVEN",
      },
      {
        CITY: "BRUCHEM",
      },
      {
        CITY: "BRUCHT",
      },
      {
        CITY: "BRUINISSE",
      },
      {
        CITY: "BRUMMEN",
      },
      {
        CITY: "BRUNSSUM",
      },
      {
        CITY: "BUCHTEN",
      },
      {
        CITY: "BUDEL",
      },
      {
        CITY: "BUDEL-DORPLEIN",
      },
      {
        CITY: "BUDEL-SCHOOT",
      },
      {
        CITY: "BUINERVEEN",
      },
      {
        CITY: "BUITENKAAG",
      },
      {
        CITY: "BUITENPOST",
      },
      {
        CITY: "BUNDE",
      },
      {
        CITY: "BUNNIK",
      },
      {
        CITY: "BUNSCHOTEN",
      },
      {
        CITY: "BUREN",
      },
      {
        CITY: "BUREN",
      },
      {
        CITY: "BURGERVEEN",
      },
      {
        CITY: "BURGUM",
      },
      {
        CITY: "BURGWERD",
      },
      {
        CITY: "BURUM",
      },
      {
        CITY: "BUSSUM",
      },
      {
        CITY: "BUURSE",
      },
      {
        CITY: "CADIER EN KEER",
      },
      {
        CITY: "CADZAND",
      },
      {
        CITY: "CALLANTSOOG",
      },
      {
        CITY: "CAPELLE AAN DEN IJSSEL",
      },
      {
        CITY: "CASTRICUM",
      },
      {
        CITY: "CENTRUM",
      },
      {
        CITY: "CHAAM",
      },
      {
        CITY: "CLINGE",
      },
      {
        CITY: "COEVORDEN",
      },
      {
        CITY: "COLIJNSPLAAT",
      },
      {
        CITY: "COLMSCHATE",
      },
      {
        CITY: "CRAAILO",
      },
      {
        CITY: "CROMVOIRT",
      },
      {
        CITY: "CRUQUIUS",
      },
      {
        CITY: "CUIJK",
      },
      {
        CITY: "CULEMBORG",
      },
      {
        CITY: "DAARLE",
      },
      {
        CITY: "DALEN",
      },
      {
        CITY: "DALFSEN",
      },
      {
        CITY: "DAMWALD",
      },
      {
        CITY: "DE BILT",
      },
      {
        CITY: "DE BLESSE",
      },
      {
        CITY: "DE COCKSDORP",
      },
      {
        CITY: "DE FALOM",
      },
      {
        CITY: "DE GLIND",
      },
      {
        CITY: "DE GOORN",
      },
      {
        CITY: "DE HOEF",
      },
      {
        CITY: "DE HORST",
      },
      {
        CITY: "DE KLOMP",
      },
      {
        CITY: "DE KOOG",
      },
      {
        CITY: "DE KWAKEL",
      },
      {
        CITY: "DE LIER",
      },
      {
        CITY: "DE LUTTE",
      },
      {
        CITY: "DE MEERN",
      },
      {
        CITY: "DE MOER",
      },
      {
        CITY: "DE PUNT",
      },
      {
        CITY: "DE RIJP",
      },
      {
        CITY: "DE STEEG",
      },
      {
        CITY: "DE WAAL",
      },
      {
        CITY: "DE WESTEREEN",
      },
      {
        CITY: "DE WIJK",
      },
      {
        CITY: "DE WILP",
      },
      {
        CITY: "DE ZILK",
      },
      {
        CITY: "DEDEMSVAART",
      },
      {
        CITY: "DEEST",
      },
      {
        CITY: "DELDEN",
      },
      {
        CITY: "DELDEN",
      },
      {
        CITY: "DELFGAUW",
      },
      {
        CITY: "DELFT",
      },
      {
        CITY: "DELFZIJL",
      },
      {
        CITY: "DEN BOMMEL",
      },
      {
        CITY: "DEN BURG",
      },
      {
        CITY: "DEN DEIJL",
      },
      {
        CITY: "DEN DOLDER",
      },
      {
        CITY: "DEN DUNGEN",
      },
      {
        CITY: "DEN HELDER",
      },
      {
        CITY: "DEN HOORN",
      },
      {
        CITY: "DEN HOUT",
      },
      {
        CITY: "DEN ILP",
      },
      {
        CITY: "DEN OEVER",
      },
      {
        CITY: "DENEKAMP",
      },
      {
        CITY: "DEURNE",
      },
      {
        CITY: "DEVENTER",
      },
      {
        CITY: "DIDAM",
      },
      {
        CITY: "DIEMEN",
      },
      {
        CITY: "DIEPENHEIM",
      },
      {
        CITY: "DIEPENVEEN",
      },
      {
        CITY: "DIEREN",
      },
      {
        CITY: "DIESSEN",
      },
      {
        CITY: "DIETEREN",
      },
      {
        CITY: "DIEVER",
      },
      {
        CITY: "DIJK",
      },
      {
        CITY: "DINTELOORD",
      },
      {
        CITY: "DINXPERLO",
      },
      {
        CITY: "DIRKSHORN",
      },
      {
        CITY: "DIRKSLAND",
      },
      {
        CITY: "DODEWAARD",
      },
      {
        CITY: "DOENRADE",
      },
      {
        CITY: "DOESBURG",
      },
      {
        CITY: "DOETINCHEM",
      },
      {
        CITY: "DOKKUM",
      },
      {
        CITY: "DOMBURG",
      },
      {
        CITY: "DONGEN",
      },
      {
        CITY: "DOORN",
      },
      {
        CITY: "DOORNENBURG",
      },
      {
        CITY: "DOORNSPIJK",
      },
      {
        CITY: "DOORWERTH",
      },
      {
        CITY: "DORDRECHT",
      },
      {
        CITY: "DORP",
      },
      {
        CITY: "DORST",
      },
      {
        CITY: "DRACHTEN",
      },
      {
        CITY: "DREMPT",
      },
      {
        CITY: "DREUMEL",
      },
      {
        CITY: "DRIEBERGEN",
      },
      {
        CITY: "DRIEBRUGGEN",
      },
      {
        CITY: "DRIEHUIS",
      },
      {
        CITY: "DRIEL",
      },
      {
        CITY: "DRIEMOND",
      },
      {
        CITY: "DROGEHAM",
      },
      {
        CITY: "DRONRYP",
      },
      {
        CITY: "DRONTEN",
      },
      {
        CITY: "DRUNEN",
      },
      {
        CITY: "DRUTEN",
      },
      {
        CITY: "DUIN",
      },
      {
        CITY: "DUIVEN",
      },
      {
        CITY: "DUIVENDRECHT",
      },
      {
        CITY: "DWINGELOO",
      },
      {
        CITY: "EARNEWALD",
      },
      {
        CITY: "ECHT",
      },
      {
        CITY: "ECHTEN",
      },
      {
        CITY: "ECHTENERBRUG",
      },
      {
        CITY: "ECKART",
      },
      {
        CITY: "EDAM",
      },
      {
        CITY: "EDE",
      },
      {
        CITY: "EDERVEEN",
      },
      {
        CITY: "EEDE",
      },
      {
        CITY: "EEFDE",
      },
      {
        CITY: "EELDE-PATERSWOLDE",
      },
      {
        CITY: "EELDERWOLDE",
      },
      {
        CITY: "EEMNES",
      },
      {
        CITY: "EENRUM",
      },
      {
        CITY: "EERBEEK",
      },
      {
        CITY: "EERSEL",
      },
      {
        CITY: "EETHEN",
      },
      {
        CITY: "EEXT",
      },
      {
        CITY: "EEXTERZANDVOORT",
      },
      {
        CITY: "EGCHEL",
      },
      {
        CITY: "EGMOND AAN ZEE",
      },
      {
        CITY: "EGMOND-BINNEN",
      },
      {
        CITY: "EIBERGEN",
      },
      {
        CITY: "EIJSDEN",
      },
      {
        CITY: "EINDHOVEN",
      },
      {
        CITY: "EINIGHAUSEN",
      },
      {
        CITY: "ELBURG",
      },
      {
        CITY: "ELL",
      },
      {
        CITY: "ELLECOM",
      },
      {
        CITY: "ELSENDORP",
      },
      {
        CITY: "ELSLOO",
      },
      {
        CITY: "ELST",
      },
      {
        CITY: "ELST",
      },
      {
        CITY: "EMMELOORD",
      },
      {
        CITY: "EMMEN",
      },
      {
        CITY: "EMMER-COMPASCUUM",
      },
      {
        CITY: "EMST",
      },
      {
        CITY: "ENGELEN",
      },
      {
        CITY: "ENKHUIZEN",
      },
      {
        CITY: "ENS",
      },
      {
        CITY: "ENSCHEDE",
      },
      {
        CITY: "ENSCHOT",
      },
      {
        CITY: "ENSPIJK",
      },
      {
        CITY: "ENTER",
      },
      {
        CITY: "ENUMATIL",
      },
      {
        CITY: "EPE",
      },
      {
        CITY: "EPEN",
      },
      {
        CITY: "EPSE",
      },
      {
        CITY: "ERICA",
      },
      {
        CITY: "ERICHEM",
      },
      {
        CITY: "ERM",
      },
      {
        CITY: "ERMELO",
      },
      {
        CITY: "ERP",
      },
      {
        CITY: "ESCHAREN",
      },
      {
        CITY: "ESPEL",
      },
      {
        CITY: "ETTEN",
      },
      {
        CITY: "EVERDINGEN",
      },
      {
        CITY: "EWIJK",
      },
      {
        CITY: "EXLOO",
      },
      {
        CITY: "EYGELSHOVEN",
      },
      {
        CITY: "EYS",
      },
      {
        CITY: "EZINGE",
      },
      {
        CITY: "FARMSUM",
      },
      {
        CITY: "FEANWALDEN",
      },
      {
        CITY: "FERWERT",
      },
      {
        CITY: "FIJNAART",
      },
      {
        CITY: "FINSTERWOLDE",
      },
      {
        CITY: "FLERINGEN",
      },
      {
        CITY: "FLUITENBERG",
      },
      {
        CITY: "FLUSHING",
      },
      {
        CITY: "FOXHOL",
      },
      {
        CITY: "FRANEKER",
      },
      {
        CITY: "FREDERIKSOORD",
      },
      {
        CITY: "GAANDEREN",
      },
      {
        CITY: "GALDER",
      },
      {
        CITY: "GAMEREN",
      },
      {
        CITY: "GAPINGE",
      },
      {
        CITY: "GARDEREN",
      },
      {
        CITY: "GARMERWOLDE",
      },
      {
        CITY: "GARSTHUIZEN",
      },
      {
        CITY: "GARYP",
      },
      {
        CITY: "GASSELTE",
      },
      {
        CITY: "GASSELTERNIJVEEN",
      },
      {
        CITY: "GEERTRUIDENBERG",
      },
      {
        CITY: "GEERVLIET",
      },
      {
        CITY: "GEES",
      },
      {
        CITY: "GEESTEREN",
      },
      {
        CITY: "GEESTEREN",
      },
      {
        CITY: "GELDERMALSEN",
      },
      {
        CITY: "GELDROP",
      },
      {
        CITY: "GELEEN",
      },
      {
        CITY: "GELSELAAR",
      },
      {
        CITY: "GEMEENTE ROTTERDAM",
      },
      {
        CITY: "GEMERT",
      },
      {
        CITY: "GEMONDE",
      },
      {
        CITY: "GENDEREN",
      },
      {
        CITY: "GENDRINGEN",
      },
      {
        CITY: "GENDT",
      },
      {
        CITY: "GENEMUIDEN",
      },
      {
        CITY: "GENNEP",
      },
      {
        CITY: "GIESBEEK",
      },
      {
        CITY: "GIESSENBURG",
      },
      {
        CITY: "GIESSENDAM",
      },
      {
        CITY: "GIETEN",
      },
      {
        CITY: "GIETERVEEN",
      },
      {
        CITY: "GIETHOORN",
      },
      {
        CITY: "GILZE",
      },
      {
        CITY: "GLIMMEN",
      },
      {
        CITY: "GOES",
      },
      {
        CITY: "GOIRLE",
      },
      {
        CITY: "GOOR",
      },
      {
        CITY: "GORINCHEM",
      },
      {
        CITY: "GORREDIJK",
      },
      {
        CITY: "GORSSEL",
      },
      {
        CITY: "GOUDA",
      },
      {
        CITY: "GOUDERAK",
      },
      {
        CITY: "GOUDSWAARD",
      },
      {
        CITY: "GOUTUM",
      },
      {
        CITY: "GRAMSBERGEN",
      },
      {
        CITY: "GRASHOEK",
      },
      {
        CITY: "GRATHEM",
      },
      {
        CITY: "GRAVE",
      },
      {
        CITY: "GREUP",
      },
      {
        CITY: "GRIJPSKERK",
      },
      {
        CITY: "GROENINGEN",
      },
      {
        CITY: "GROENLO",
      },
      {
        CITY: "GROENVELD",
      },
      {
        CITY: "GROESBEEK",
      },
      {
        CITY: "GROESSEN",
      },
      {
        CITY: "GROET",
      },
      {
        CITY: "GRONINGEN",
      },
      {
        CITY: "GRONSVELD",
      },
      {
        CITY: "GROOT-AGELO",
      },
      {
        CITY: "GROOT-AMMERS",
      },
      {
        CITY: "GROOTEBROEK",
      },
      {
        CITY: "GROOTEGAST",
      },
      {
        CITY: "GROU",
      },
      {
        CITY: "GRUBBENVORST",
      },
      {
        CITY: "GULPEN",
      },
      {
        CITY: "GUTTECOVEN",
      },
      {
        CITY: "GYTSJERK",
      },
      {
        CITY: "HAAFTEN",
      },
      {
        CITY: "HAAKSBERGEN",
      },
      {
        CITY: "HAALDEREN",
      },
      {
        CITY: "HAAMSTEDE",
      },
      {
        CITY: "HAAREN",
      },
      {
        CITY: "HAARLE",
      },
      {
        CITY: "HAARLEM",
      },
      {
        CITY: "HAARLEMMERLIEDE",
      },
      {
        CITY: "HAELEN",
      },
      {
        CITY: "HALFWEG",
      },
      {
        CITY: "HALLE",
      },
      {
        CITY: "HALLUM",
      },
      {
        CITY: "HALSTEREN",
      },
      {
        CITY: "HANK",
      },
      {
        CITY: "HANTUM",
      },
      {
        CITY: "HANTUMHUIZEN",
      },
      {
        CITY: "HAPERT",
      },
      {
        CITY: "HAPS",
      },
      {
        CITY: "HARDENBERG",
      },
      {
        CITY: "HARDERWIJK",
      },
      {
        CITY: "HAREN",
      },
      {
        CITY: "HAREN",
      },
      {
        CITY: "HARICH",
      },
      {
        CITY: "HARKEMA",
      },
      {
        CITY: "HARKSTEDE",
      },
      {
        CITY: "HARLINGEN",
      },
      {
        CITY: "HARMELEN",
      },
      {
        CITY: "HARTWERD",
      },
      {
        CITY: "HASSELT",
      },
      {
        CITY: "HATTEM",
      },
      {
        CITY: "HAULE",
      },
      {
        CITY: "HAULERWIJK",
      },
      {
        CITY: "HAUWERT",
      },
      {
        CITY: "HAVELTE",
      },
      {
        CITY: "HAZERSWOUDE-DORP",
      },
      {
        CITY: "HAZERSWOUDE-RIJNDIJK",
      },
      {
        CITY: "HEDEL",
      },
      {
        CITY: "HEDEL",
      },
      {
        CITY: "HEEG",
      },
      {
        CITY: "HEELSUM",
      },
      {
        CITY: "HEEMSKERK",
      },
      {
        CITY: "HEEMSTEDE",
      },
      {
        CITY: "HEENVLIET",
      },
      {
        CITY: "HEERDE",
      },
      {
        CITY: "HEERENVEEN",
      },
      {
        CITY: "HEERHUGOWAARD",
      },
      {
        CITY: "HEERJANSDAM",
      },
      {
        CITY: "HEERLE",
      },
      {
        CITY: "HEERLEN",
      },
      {
        CITY: "HEESCH",
      },
      {
        CITY: "HEESWIJK",
      },
      {
        CITY: "HEESWIJK-DINTHER",
      },
      {
        CITY: "HEETEN",
      },
      {
        CITY: "HEEZE",
      },
      {
        CITY: "HEIJEN",
      },
      {
        CITY: "HEIJNINGEN",
      },
      {
        CITY: "HEIKANT",
      },
      {
        CITY: "HEILIG LANDSTICHTING",
      },
      {
        CITY: "HEILOO",
      },
      {
        CITY: "HEINENOORD",
      },
      {
        CITY: "HEINO",
      },
      {
        CITY: "HEKELINGEN",
      },
      {
        CITY: "HELDEN",
      },
      {
        CITY: "HELENAVEEN",
      },
      {
        CITY: "HELLENDOORN",
      },
      {
        CITY: "HELLEVOETSLUIS",
      },
      {
        CITY: "HELLOUW",
      },
      {
        CITY: "HELMOND",
      },
      {
        CITY: "HELVOIRT",
      },
      {
        CITY: "HEM",
      },
      {
        CITY: "HEMELUM",
      },
      {
        CITY: "HENDRIK-IDO-AMBACHT",
      },
      {
        CITY: "HENGELO",
      },
      {
        CITY: "HENGELO",
      },
      {
        CITY: "HENGEVELDE",
      },
      {
        CITY: "HENNAARD",
      },
      {
        CITY: "HENSBROEK",
      },
      {
        CITY: "HERBAIJUM",
      },
      {
        CITY: "HERKENBOSCH",
      },
      {
        CITY: "HERKINGEN",
      },
      {
        CITY: "HERNEN",
      },
      {
        CITY: "HERPEN",
      },
      {
        CITY: "HERTEN",
      },
      {
        CITY: "HERVELD",
      },
      {
        CITY: "HERWIJNEN",
      },
      {
        CITY: "HETEREN",
      },
      {
        CITY: "HEUKELUM",
      },
      {
        CITY: "HEUSDEN",
      },
      {
        CITY: "HEVEADORP",
      },
      {
        CITY: "HEYTHUYSEN",
      },
      {
        CITY: "HIERDEN",
      },
      {
        CITY: "HIJKEN",
      },
      {
        CITY: "HILLEGOM",
      },
      {
        CITY: "HILVARENBEEK",
      },
      {
        CITY: "HILVERSUM",
      },
      {
        CITY: "HINDELOOPEN",
      },
      {
        CITY: "HIPPOLYTUSHOEF",
      },
      {
        CITY: "HOEDEKENSKERKE",
      },
      {
        CITY: "HOEK",
      },
      {
        CITY: "HOEK VAN HOLLAND",
      },
      {
        CITY: "HOENDERLOO",
      },
      {
        CITY: "HOENSBROEK",
      },
      {
        CITY: "HOEVELAKEN",
      },
      {
        CITY: "HOGE DONK",
      },
      {
        CITY: "HOLLANDSCHE RADING",
      },
      {
        CITY: "HOLLANDSCHEVELD",
      },
      {
        CITY: "HOLLUM",
      },
      {
        CITY: "HOLTEN",
      },
      {
        CITY: "HOLTHEES",
      },
      {
        CITY: "HOLTHONE",
      },
      {
        CITY: "HOLTUM",
      },
      {
        CITY: "HOLWIERDE",
      },
      {
        CITY: "HOMOET",
      },
      {
        CITY: "HONSELERSDIJK",
      },
      {
        CITY: "HOOFDDORP",
      },
      {
        CITY: "HOOFDPLAAT",
      },
      {
        CITY: "HOOGBLOKLAND",
      },
      {
        CITY: "HOOGE ZWALUWE",
      },
      {
        CITY: "HOOGELOON",
      },
      {
        CITY: "HOOGERHEIDE",
      },
      {
        CITY: "HOOGERSMILDE",
      },
      {
        CITY: "HOOGEVEEN",
      },
      {
        CITY: "HOOGEZAND",
      },
      {
        CITY: "HOOGHALEN",
      },
      {
        CITY: "HOOGKARSPEL",
      },
      {
        CITY: "HOOGKERK",
      },
      {
        CITY: "HOOGLAND",
      },
      {
        CITY: "HOOGLANDERVEEN",
      },
      {
        CITY: "HOOGMADE",
      },
      {
        CITY: "HOOGVLIET",
      },
      {
        CITY: "HOOGWOUD",
      },
      {
        CITY: "HOORN",
      },
      {
        CITY: "HOORN",
      },
      {
        CITY: "HOORNAAR",
      },
      {
        CITY: "HORN",
      },
      {
        CITY: "HORST",
      },
      {
        CITY: "HOUT",
      },
      {
        CITY: "HOUTAKKER",
      },
      {
        CITY: "HOUTEN",
      },
      {
        CITY: "HUIJBERGEN",
      },
      {
        CITY: "HUIS TER HEIDE",
      },
      {
        CITY: "HUISSEN",
      },
      {
        CITY: "HUIZEN",
      },
      {
        CITY: "HULSBERG",
      },
      {
        CITY: "HULSHORST",
      },
      {
        CITY: "HULST",
      },
      {
        CITY: "HUMMELO",
      },
      {
        CITY: "HURDEGARYP",
      },
      {
        CITY: "IJHORST",
      },
      {
        CITY: "IJLST",
      },
      {
        CITY: "IJMUIDEN",
      },
      {
        CITY: "IJSSELMUIDEN",
      },
      {
        CITY: "IJSSELSTEIN",
      },
      {
        CITY: "IJZENDIJKE",
      },
      {
        CITY: "IJZENDOORN",
      },
      {
        CITY: "ILPENDAM",
      },
      {
        CITY: "INGELUM",
      },
      {
        CITY: "INGEN",
      },
      {
        CITY: "ITTERVOORT",
      },
      {
        CITY: "JABEEK",
      },
      {
        CITY: "JISP",
      },
      {
        CITY: "JOPPE",
      },
      {
        CITY: "JOURE",
      },
      {
        CITY: "JUBBEGA",
      },
      {
        CITY: "JULIANADORP",
      },
      {
        CITY: "JUTRIJP",
      },
      {
        CITY: "KAAG",
      },
      {
        CITY: "KAATSHEUVEL",
      },
      {
        CITY: "KALENBERG",
      },
      {
        CITY: "KAMERIK",
      },
      {
        CITY: "KAMPEN",
      },
      {
        CITY: "KAMPERLAND",
      },
      {
        CITY: "KANTENS",
      },
      {
        CITY: "KAPELLE",
      },
      {
        CITY: "KATS",
      },
      {
        CITY: "KATWIJK",
      },
      {
        CITY: "KATWIJK AAN ZEE",
      },
      {
        CITY: "KATWOUDE",
      },
      {
        CITY: "KEIJENBORG",
      },
      {
        CITY: "KEKERDOM",
      },
      {
        CITY: "KELDONK",
      },
      {
        CITY: "KERK-AVEZAATH",
      },
      {
        CITY: "KERKDRIEL",
      },
      {
        CITY: "KERKENVELD",
      },
      {
        CITY: "KERKRADE",
      },
      {
        CITY: "KESSEL",
      },
      {
        CITY: "KESTEREN",
      },
      {
        CITY: "KIMSWERD",
      },
      {
        CITY: "KINDERDIJK",
      },
      {
        CITY: "KLAASWAAL",
      },
      {
        CITY: "KLAZIENAVEEN",
      },
      {
        CITY: "KLIMMEN",
      },
      {
        CITY: "KLOETINGE",
      },
      {
        CITY: "KLOOSTERHAAR",
      },
      {
        CITY: "KLUNDERT",
      },
      {
        CITY: "KOCKENGEN",
      },
      {
        CITY: "KOEDIJK",
      },
      {
        CITY: "KOEKANGE",
      },
      {
        CITY: "KOEWACHT",
      },
      {
        CITY: "KOLHAM",
      },
      {
        CITY: "KOLHORN",
      },
      {
        CITY: "KOLLUM",
      },
      {
        CITY: "KOMMERZIJL",
      },
      {
        CITY: "KOOG AAN DE ZAAN",
      },
      {
        CITY: "KOOTSTERTILLE",
      },
      {
        CITY: "KOOTWIJKERBROEK",
      },
      {
        CITY: "KORTENHOEF",
      },
      {
        CITY: "KORTGENE",
      },
      {
        CITY: "KOUDEKERK AAN DEN RIJN",
      },
      {
        CITY: "KOUDEKERKE",
      },
      {
        CITY: "KOUDUM",
      },
      {
        CITY: "KRAGGENBURG",
      },
      {
        CITY: "KRIMPEN AAN DE LEK",
      },
      {
        CITY: "KRIMPEN AAN DEN IJSSEL",
      },
      {
        CITY: "KROMMENIE",
      },
      {
        CITY: "KROPSWOLDE",
      },
      {
        CITY: "KRUININGEN",
      },
      {
        CITY: "KRUISLAND",
      },
      {
        CITY: "KUDELSTAART",
      },
      {
        CITY: "KUITAART",
      },
      {
        CITY: "KWINTSHEUL",
      },
      {
        CITY: "LAAG-SOEREN",
      },
      {
        CITY: "LAGE MIERDE",
      },
      {
        CITY: "LAGE ZWALUWE",
      },
      {
        CITY: "LANDSMEER",
      },
      {
        CITY: "LANGEDIJK",
      },
      {
        CITY: "LANGEZWAAG",
      },
      {
        CITY: "LAREN",
      },
      {
        CITY: "LATHUM",
      },
      {
        CITY: "LEEK",
      },
      {
        CITY: "LEERDAM",
      },
      {
        CITY: "LEERSUM",
      },
      {
        CITY: "LEEUWARDEN",
      },
      {
        CITY: "LEGEMEER",
      },
      {
        CITY: "LEIDEN",
      },
      {
        CITY: "LEIDERDORP",
      },
      {
        CITY: "LEIDSCHENDAM",
      },
      {
        CITY: "LEIMUIDEN",
      },
      {
        CITY: "LEKKERKERK",
      },
      {
        CITY: "LELYSTAD",
      },
      {
        CITY: "LEMELE",
      },
      {
        CITY: "LEMELERVELD",
      },
      {
        CITY: "LEMIERS",
      },
      {
        CITY: "LEMMER",
      },
      {
        CITY: "LENT",
      },
      {
        CITY: "LETTELE",
      },
      {
        CITY: "LEUSDEN",
      },
      {
        CITY: "LEUTH",
      },
      {
        CITY: "LEWENBORG",
      },
      {
        CITY: "LEXMOND",
      },
      {
        CITY: "LICHTENVOORDE",
      },
      {
        CITY: "LIEMPDE",
      },
      {
        CITY: "LIENDEN",
      },
      {
        CITY: "LIENDEN",
      },
      {
        CITY: "LIES",
      },
      {
        CITY: "LIESHOUT",
      },
      {
        CITY: "LIESSEL",
      },
      {
        CITY: "LIEVELDE",
      },
      {
        CITY: "LIJNDEN",
      },
      {
        CITY: "LIMBRICHT",
      },
      {
        CITY: "LIMMEL",
      },
      {
        CITY: "LIMMEN",
      },
      {
        CITY: "LINNE",
      },
      {
        CITY: "LINSCHOTEN",
      },
      {
        CITY: "LIPPENHUIZEN",
      },
      {
        CITY: "LISSE",
      },
      {
        CITY: "LITHOIJEN",
      },
      {
        CITY: "LOBITH",
      },
      {
        CITY: "LOCHEM",
      },
      {
        CITY: "LOENEN",
      },
      {
        CITY: "LOON OP ZAND",
      },
      {
        CITY: "LOOSDRECHT",
      },
      {
        CITY: "LOOZEN",
      },
      {
        CITY: "LOPIK",
      },
      {
        CITY: "LOPPERSUM",
      },
      {
        CITY: "LOSSER",
      },
      {
        CITY: "LOTTUM",
      },
      {
        CITY: "LUNTEREN",
      },
      {
        CITY: "LUTJEBROEK",
      },
      {
        CITY: "LUTJEWINKEL",
      },
      {
        CITY: "LUTTEN",
      },
      {
        CITY: "LUYKSGESTEL",
      },
      {
        CITY: "MAARHEEZE",
      },
      {
        CITY: "MAARN",
      },
      {
        CITY: "MAARSSEN",
      },
      {
        CITY: "MAARSSENBROEK",
      },
      {
        CITY: "MAARTENSDIJK",
      },
      {
        CITY: "MAASBOMMEL",
      },
      {
        CITY: "MAASBRACHT",
      },
      {
        CITY: "MAASBREE",
      },
      {
        CITY: "MAASDAM",
      },
      {
        CITY: "MAASDIJK",
      },
      {
        CITY: "MAASHEES",
      },
      {
        CITY: "MAASKANTJE",
      },
      {
        CITY: "MAASLAND",
      },
      {
        CITY: "MAASSLUIS",
      },
      {
        CITY: "MAASTRICHT",
      },
      {
        CITY: "MADE",
      },
      {
        CITY: "MAKKUM",
      },
      {
        CITY: "MALDEN",
      },
      {
        CITY: "MANDERVEEN",
      },
      {
        CITY: "MARGRATEN",
      },
      {
        CITY: "MARIENBERG",
      },
      {
        CITY: "MARKELO",
      },
      {
        CITY: "MARKNESSE",
      },
      {
        CITY: "MARRUM",
      },
      {
        CITY: "MARUM",
      },
      {
        CITY: "MASTENBROEK",
      },
      {
        CITY: "MAURIK",
      },
      {
        CITY: "MECHELEN",
      },
      {
        CITY: "MEDEMBLIK",
      },
      {
        CITY: "MEEDEN",
      },
      {
        CITY: "MEER",
      },
      {
        CITY: "MEERKERK",
      },
      {
        CITY: "MEERLO",
      },
      {
        CITY: "MEERSSEN",
      },
      {
        CITY: "MEEUWEN",
      },
      {
        CITY: "MEGCHELEN",
      },
      {
        CITY: "MEIJEL",
      },
      {
        CITY: "MELICK",
      },
      {
        CITY: "MEPPEL",
      },
      {
        CITY: "MERKELBEEK",
      },
      {
        CITY: "MERSELO",
      },
      {
        CITY: "MIDDELBEERS",
      },
      {
        CITY: "MIDDELBURG",
      },
      {
        CITY: "MIDDELHARNIS",
      },
      {
        CITY: "MIDDENBEEMSTER",
      },
      {
        CITY: "MIDDENMEER",
      },
      {
        CITY: "MIDLUM",
      },
      {
        CITY: "MIERLO",
      },
      {
        CITY: "MIJDRECHT",
      },
      {
        CITY: "MIJNSHEERENLAND",
      },
      {
        CITY: "MILDAM",
      },
      {
        CITY: "MILHEEZE",
      },
      {
        CITY: "MILL",
      },
      {
        CITY: "MILLINGEN",
      },
      {
        CITY: "MILLINGEN AAN DE RIJN",
      },
      {
        CITY: "MILSBEEK",
      },
      {
        CITY: "MOERDIJK",
      },
      {
        CITY: "MOERGESTEL",
      },
      {
        CITY: "MOERKAPELLE",
      },
      {
        CITY: "MOLENAARSGRAAF",
      },
      {
        CITY: "MOLENHOEK",
      },
      {
        CITY: "MOLENHOEK",
      },
      {
        CITY: "MOLENSCHOT",
      },
      {
        CITY: "MONNICKENDAM",
      },
      {
        CITY: "MONSTER",
      },
      {
        CITY: "MONTFOORT",
      },
      {
        CITY: "MONTFORT",
      },
      {
        CITY: "MOOK",
      },
      {
        CITY: "MOOKHOEK",
      },
      {
        CITY: "MOORDRECHT",
      },
      {
        CITY: "MORTEL",
      },
      {
        CITY: "MUIDEN",
      },
      {
        CITY: "MUIDERBERG",
      },
      {
        CITY: "MUNSTERGELEEN",
      },
      {
        CITY: "MUNTENDAM",
      },
      {
        CITY: "MUSSEL",
      },
      {
        CITY: "NAALDWIJK",
      },
      {
        CITY: "NAARDEN",
      },
      {
        CITY: "NEDERASSELT",
      },
      {
        CITY: "NEDERHEMERT",
      },
      {
        CITY: "NEDERHORST DEN BERG",
      },
      {
        CITY: "NEDERWEERT",
      },
      {
        CITY: "NEEDE",
      },
      {
        CITY: "NEER",
      },
      {
        CITY: "NEER-ANDEL",
      },
      {
        CITY: "NES",
      },
      {
        CITY: "NETTERDEN",
      },
      {
        CITY: "NIBBIXWOUD",
      },
      {
        CITY: "NIEUW-AMSTERDAM",
      },
      {
        CITY: "NIEUW-BALINGE",
      },
      {
        CITY: "NIEUW-BEIJERLAND",
      },
      {
        CITY: "NIEUW-BERGEN",
      },
      {
        CITY: "NIEUW-BUINEN",
      },
      {
        CITY: "NIEUW-DORDRECHT",
      },
      {
        CITY: "NIEUW-LEKKERLAND",
      },
      {
        CITY: "NIEUW-NAMEN",
      },
      {
        CITY: "NIEUW-SCHOONEBEEK",
      },
      {
        CITY: "NIEUW-VENNEP",
      },
      {
        CITY: "NIEUW-VOSSEMEER",
      },
      {
        CITY: "NIEUWDORP",
      },
      {
        CITY: "NIEUWE PEKELA",
      },
      {
        CITY: "NIEUWE WETERING",
      },
      {
        CITY: "NIEUWE-NIEDORP",
      },
      {
        CITY: "NIEUWE-TONGE",
      },
      {
        CITY: "NIEUWEGEIN",
      },
      {
        CITY: "NIEUWEHORNE",
      },
      {
        CITY: "NIEUWENDIJK",
      },
      {
        CITY: "NIEUWER-TER-AA",
      },
      {
        CITY: "NIEUWERBRUG",
      },
      {
        CITY: "NIEUWERKERK AAN DEN IJSSEL",
      },
      {
        CITY: "NIEUWESCHOOT",
      },
      {
        CITY: "NIEUWKOOP",
      },
      {
        CITY: "NIEUWKUIJK",
      },
      {
        CITY: "NIEUWLANDE",
      },
      {
        CITY: "NIEUWLEUSEN",
      },
      {
        CITY: "NIEUWOLDA",
      },
      {
        CITY: "NIEUWPOORT",
      },
      {
        CITY: "NIEUWSTADT",
      },
      {
        CITY: "NIEUWVEEN",
      },
      {
        CITY: "NIGTEVECHT",
      },
      {
        CITY: "NIJBROEK",
      },
      {
        CITY: "NIJHUIZUM",
      },
      {
        CITY: "NIJKERK",
      },
      {
        CITY: "NIJKERKERVEEN",
      },
      {
        CITY: "NIJLAND",
      },
      {
        CITY: "NIJMEGEN",
      },
      {
        CITY: "NIJNSEL",
      },
      {
        CITY: "NIJVERDAL",
      },
      {
        CITY: "NISTELRODE",
      },
      {
        CITY: "NOARDBURGUM",
      },
      {
        CITY: "NOORBEEK",
      },
      {
        CITY: "NOORD-SCHARWOUDE",
      },
      {
        CITY: "NOORD-SLEEN",
      },
      {
        CITY: "NOORDBROEK",
      },
      {
        CITY: "NOORDELOOS",
      },
      {
        CITY: "NOORDEN",
      },
      {
        CITY: "NOORDGOUWE",
      },
      {
        CITY: "NOORDHORN",
      },
      {
        CITY: "NOORDLAREN",
      },
      {
        CITY: "NOORDSCHESCHUT",
      },
      {
        CITY: "NOORDWELLE",
      },
      {
        CITY: "NOORDWIJK AAN ZEE",
      },
      {
        CITY: "NOORDWIJK-BINNEN",
      },
      {
        CITY: "NOORDWIJKERHOUT",
      },
      {
        CITY: "NOORDWOLDE",
      },
      {
        CITY: "NOOTDORP",
      },
      {
        CITY: "NORG",
      },
      {
        CITY: "NUENEN",
      },
      {
        CITY: "NUIS",
      },
      {
        CITY: "NULAND",
      },
      {
        CITY: "NUMANSDORP",
      },
      {
        CITY: "NUNHEM",
      },
      {
        CITY: "NUNSPEET",
      },
      {
        CITY: "NUTH",
      },
      {
        CITY: "OBBICHT",
      },
      {
        CITY: "OBDAM",
      },
      {
        CITY: "OCHTEN",
      },
      {
        CITY: "ODIJK",
      },
      {
        CITY: "OEFFELT",
      },
      {
        CITY: "OEGSTGEEST",
      },
      {
        CITY: "OENE",
      },
      {
        CITY: "OIJEN",
      },
      {
        CITY: "OIRSBEEK",
      },
      {
        CITY: "OIRSCHOT",
      },
      {
        CITY: "OISTERWIJK",
      },
      {
        CITY: "OLDEBERKOOP",
      },
      {
        CITY: "OLDEBROEK",
      },
      {
        CITY: "OLDEHOLTPADE",
      },
      {
        CITY: "OLDEHOVE",
      },
      {
        CITY: "OLDEKERK",
      },
      {
        CITY: "OLDEMARKT",
      },
      {
        CITY: "OLDENZAAL",
      },
      {
        CITY: "OLST",
      },
      {
        CITY: "OMMEN",
      },
      {
        CITY: "ONNEN",
      },
      {
        CITY: "OOIJ",
      },
      {
        CITY: "OOLTGENSPLAAT",
      },
      {
        CITY: "OOST-SOUBURG",
      },
      {
        CITY: "OOSTBURG",
      },
      {
        CITY: "OOSTENDAM",
      },
      {
        CITY: "OOSTERBEEK",
      },
      {
        CITY: "OOSTERBIERUM",
      },
      {
        CITY: "OOSTEREND",
      },
      {
        CITY: "OOSTERHESSELEN",
      },
      {
        CITY: "OOSTERHOUT",
      },
      {
        CITY: "OOSTERLAND",
      },
      {
        CITY: "OOSTERNIJKERK",
      },
      {
        CITY: "OOSTERWOLDE",
      },
      {
        CITY: "OOSTERWOLDE",
      },
      {
        CITY: "OOSTERZEE",
      },
      {
        CITY: "OOSTHUIZEN",
      },
      {
        CITY: "OOSTKAPELLE",
      },
      {
        CITY: "OOSTVOORNE",
      },
      {
        CITY: "OOSTWOLD",
      },
      {
        CITY: "OOSTWOUD",
      },
      {
        CITY: "OOSTZAAN",
      },
      {
        CITY: "OOTMARSUM",
      },
      {
        CITY: "OP DEN BOSCH",
      },
      {
        CITY: "OPEINDE",
      },
      {
        CITY: "OPHEMERT",
      },
      {
        CITY: "OPHEUSDEN",
      },
      {
        CITY: "OPMEER",
      },
      {
        CITY: "ORANJE",
      },
      {
        CITY: "ORANJEWOUD",
      },
      {
        CITY: "OSPEL",
      },
      {
        CITY: "OSS",
      },
      {
        CITY: "OSSENDRECHT",
      },
      {
        CITY: "OTERLEEK",
      },
      {
        CITY: "OTTERLO",
      },
      {
        CITY: "OTTERSUM",
      },
      {
        CITY: "OUD-ADE",
      },
      {
        CITY: "OUD-ALBLAS",
      },
      {
        CITY: "OUD-BEIJERLAND",
      },
      {
        CITY: "OUD-GASTEL",
      },
      {
        CITY: "OUD-ZUILEN",
      },
      {
        CITY: "OUDDORP",
      },
      {
        CITY: "OUDE PEKELA",
      },
      {
        CITY: "OUDE WETERING",
      },
      {
        CITY: "OUDE-TONGE",
      },
      {
        CITY: "OUDEGA",
      },
      {
        CITY: "OUDEHASKE",
      },
      {
        CITY: "OUDEHORNE",
      },
      {
        CITY: "OUDELANDE",
      },
      {
        CITY: "OUDEMIRDUM",
      },
      {
        CITY: "OUDEMOLEN",
      },
      {
        CITY: "OUDENBOSCH",
      },
      {
        CITY: "OUDENDIJK",
      },
      {
        CITY: "OUDERKERK AAN DE AMSTEL",
      },
      {
        CITY: "OUDESCHILD",
      },
      {
        CITY: "OUDESLUIS",
      },
      {
        CITY: "OUDEWATER",
      },
      {
        CITY: "OUDKARSPEL",
      },
      {
        CITY: "OUDORP",
      },
      {
        CITY: "OUDWOUDE",
      },
      {
        CITY: "OVERASSELT",
      },
      {
        CITY: "OVERBERG",
      },
      {
        CITY: "OVERDINKEL",
      },
      {
        CITY: "OVERLOON",
      },
      {
        CITY: "OVERVEEN",
      },
      {
        CITY: "OVEZANDE",
      },
      {
        CITY: "PALEMIG",
      },
      {
        CITY: "PANNERDEN",
      },
      {
        CITY: "PANNINGEN",
      },
      {
        CITY: "PAPEKOP",
      },
      {
        CITY: "PAPENDRECHT",
      },
      {
        CITY: "PARTIJ",
      },
      {
        CITY: "PATERSWOLDE",
      },
      {
        CITY: "PEIZE",
      },
      {
        CITY: "PERNIS",
      },
      {
        CITY: "PETTEN",
      },
      {
        CITY: "PHILIPPINE",
      },
      {
        CITY: "PIERSHIL",
      },
      {
        CITY: "PIETERBUREN",
      },
      {
        CITY: "PIETERZIJL",
      },
      {
        CITY: "PIJNACKER",
      },
      {
        CITY: "PINGJUM",
      },
      {
        CITY: "PLASMOLEN",
      },
      {
        CITY: "POELDIJK",
      },
      {
        CITY: "POORTUGAAL",
      },
      {
        CITY: "POORTVLIET",
      },
      {
        CITY: "POSTERHOLT",
      },
      {
        CITY: "PRINCENHOF",
      },
      {
        CITY: "PRINSENBEEK",
      },
      {
        CITY: "PUIFLIJK",
      },
      {
        CITY: "PURMEREND",
      },
      {
        CITY: "PURMERLAND",
      },
      {
        CITY: "PUTH",
      },
      {
        CITY: "PUTTE",
      },
      {
        CITY: "PUTTEN",
      },
      {
        CITY: "PUTTERSHOEK",
      },
      {
        CITY: "RAALTE",
      },
      {
        CITY: "RAAMSDONKSVEER",
      },
      {
        CITY: "RANDWIJK",
      },
      {
        CITY: "RAVENSTEIN",
      },
      {
        CITY: "RAVENSWOUD",
      },
      {
        CITY: "REEUWIJK",
      },
      {
        CITY: "REKKEN",
      },
      {
        CITY: "RENESSE",
      },
      {
        CITY: "RENKUM",
      },
      {
        CITY: "RENSWOUDE",
      },
      {
        CITY: "RESSEN",
      },
      {
        CITY: "RETRANCHEMENT",
      },
      {
        CITY: "REUSEL",
      },
      {
        CITY: "REUVER",
      },
      {
        CITY: "RHEDEN",
      },
      {
        CITY: "RHENEN",
      },
      {
        CITY: "RHENOY",
      },
      {
        CITY: "RHOON",
      },
      {
        CITY: "RIDDERKERK",
      },
      {
        CITY: "RIETHOVEN",
      },
      {
        CITY: "RIETVELD",
      },
      {
        CITY: "RIJCKHOLT",
      },
      {
        CITY: "RIJEN",
      },
      {
        CITY: "RIJKEVOORT",
      },
      {
        CITY: "RIJNSBURG",
      },
      {
        CITY: "RIJPWETERING",
      },
      {
        CITY: "RIJSBERGEN",
      },
      {
        CITY: "RIJSENHOUT",
      },
      {
        CITY: "RIJSSEN",
      },
      {
        CITY: "RIJSWIJK",
      },
      {
        CITY: "RIJSWIJK",
      },
      {
        CITY: "RILLAND",
      },
      {
        CITY: "RINSUMAGEAST",
      },
      {
        CITY: "RIPS",
      },
      {
        CITY: "RIVIERENWIJK",
      },
      {
        CITY: "RIXTEL",
      },
      {
        CITY: "ROCKANJE",
      },
      {
        CITY: "RODEN",
      },
      {
        CITY: "RODENRIJS",
      },
      {
        CITY: "ROELOFARENDSVEEN",
      },
      {
        CITY: "ROERMOND",
      },
      {
        CITY: "ROGGEL",
      },
      {
        CITY: "ROLDE",
      },
      {
        CITY: "ROODESCHOOL",
      },
      {
        CITY: "ROOSENDAAL",
      },
      {
        CITY: "ROOSTEREN",
      },
      {
        CITY: "ROOTH",
      },
      {
        CITY: "ROSMALEN",
      },
      {
        CITY: "ROSSUM",
      },
      {
        CITY: "ROSSUM",
      },
      {
        CITY: "ROTTERDAM",
      },
      {
        CITY: "ROTTUM",
      },
      {
        CITY: "ROTTUM",
      },
      {
        CITY: "ROZENBURG",
      },
      {
        CITY: "ROZENBURG",
      },
      {
        CITY: "ROZENDAAL",
      },
      {
        CITY: "RUCPHEN",
      },
      {
        CITY: "RUINEN",
      },
      {
        CITY: "RUINERWOLD",
      },
      {
        CITY: "RUTTEN",
      },
      {
        CITY: "RUURLO",
      },
      {
        CITY: "S-HEERENBERG",
      },
      {
        CITY: "SAASVELD",
      },
      {
        CITY: "SAMBEEK",
      },
      {
        CITY: "SANTPOORT-NOORD",
      },
      {
        CITY: "SAPPEMEER",
      },
      {
        CITY: "SAS VAN GENT",
      },
      {
        CITY: "SASSENHEIM",
      },
      {
        CITY: "SCHAGEN",
      },
      {
        CITY: "SCHAGERBRUG",
      },
      {
        CITY: "SCHAIJK",
      },
      {
        CITY: "SCHALKHAAR",
      },
      {
        CITY: "SCHARMER",
      },
      {
        CITY: "SCHEEMDA",
      },
      {
        CITY: "SCHELLINKHOUT",
      },
      {
        CITY: "SCHERMERHORN",
      },
      {
        CITY: "SCHERPENISSE",
      },
      {
        CITY: "SCHERPENZEEL",
      },
      {
        CITY: "SCHIEDAM",
      },
      {
        CITY: "SCHIERMONNIKOOG",
      },
      {
        CITY: "SCHIJNDEL",
      },
      {
        CITY: "SCHILDWOLDE",
      },
      {
        CITY: "SCHIMMERT",
      },
      {
        CITY: "SCHIN OP GEUL",
      },
      {
        CITY: "SCHINNEN",
      },
      {
        CITY: "SCHINVELD",
      },
      {
        CITY: "SCHIPBORG",
      },
      {
        CITY: "SCHIPLUIDEN",
      },
      {
        CITY: "SCHOONDIJKE",
      },
      {
        CITY: "SCHOONEBEEK",
      },
      {
        CITY: "SCHOONHOVEN",
      },
      {
        CITY: "SCHOORL",
      },
      {
        CITY: "SCHORE",
      },
      {
        CITY: "SELLINGEN",
      },
      {
        CITY: "SEROOSKERKE",
      },
      {
        CITY: "SEVENUM",
      },
      {
        CITY: "SIBCULO",
      },
      {
        CITY: "SIDDEBUREN",
      },
      {
        CITY: "SIEBENGEWALD",
      },
      {
        CITY: "SILVOLDE",
      },
      {
        CITY: "SIMPELVELD",
      },
      {
        CITY: "SINT AGATHA",
      },
      {
        CITY: "SINT ANNALAND",
      },
      {
        CITY: "SINT ANNAPAROCHIE",
      },
      {
        CITY: "SINT ANTHONIS",
      },
      {
        CITY: "SINT HUBERT",
      },
      {
        CITY: "SINT JANSTEEN",
      },
      {
        CITY: "SINT JOOST",
      },
      {
        CITY: "SINT MAARTEN",
      },
      {
        CITY: "SINT MAARTENSDIJK",
      },
      {
        CITY: "SINT NICOLAASGA",
      },
      {
        CITY: "SINT ODILIENBERG",
      },
      {
        CITY: "SINT PANCRAS",
      },
      {
        CITY: "SINT PHILIPSLAND",
      },
      {
        CITY: "SINT WILLEBRORD",
      },
      {
        CITY: "SINT-MICHIELSGESTEL",
      },
      {
        CITY: "SINT-OEDENRODE",
      },
      {
        CITY: "SINTJOHANNESGA",
      },
      {
        CITY: "SITTARD",
      },
      {
        CITY: "SLAGHAREN",
      },
      {
        CITY: "SLEEN",
      },
      {
        CITY: "SLEEUWIJK",
      },
      {
        CITY: "SLENAKEN",
      },
      {
        CITY: "SLIEDRECHT",
      },
      {
        CITY: "SLIJK-EWIJK",
      },
      {
        CITY: "SLOCHTEREN",
      },
      {
        CITY: "SLOOTDORP",
      },
      {
        CITY: "SLOTERDIJK",
      },
      {
        CITY: "SLUIS",
      },
      {
        CITY: "SLUISKIL",
      },
      {
        CITY: "SMILDE",
      },
      {
        CITY: "SNEEK",
      },
      {
        CITY: "SOERENDONK",
      },
      {
        CITY: "SOEST",
      },
      {
        CITY: "SOESTERBERG",
      },
      {
        CITY: "SOMEREN",
      },
      {
        CITY: "SOMMELSDIJK",
      },
      {
        CITY: "SON EN BREUGEL",
      },
      {
        CITY: "SPAARNDAM",
      },
      {
        CITY: "SPAKENBURG",
      },
      {
        CITY: "SPANBROEK",
      },
      {
        CITY: "SPAUBEEK",
      },
      {
        CITY: "SPIERDIJK",
      },
      {
        CITY: "SPIJK",
      },
      {
        CITY: "SPIJKENISSE",
      },
      {
        CITY: "SPRANG",
      },
      {
        CITY: "SPRUNDEL",
      },
      {
        CITY: "STADSKANAAL",
      },
      {
        CITY: "STAMPERSGAT",
      },
      {
        CITY: "STAPHORST",
      },
      {
        CITY: "STATIONSBUURT",
      },
      {
        CITY: "STEDUM",
      },
      {
        CITY: "STEENBERGEN",
      },
      {
        CITY: "STEENSEL",
      },
      {
        CITY: "STEENWIJK",
      },
      {
        CITY: "STEGGERDA",
      },
      {
        CITY: "STEIJL",
      },
      {
        CITY: "STEIN",
      },
      {
        CITY: "STELLENDAM",
      },
      {
        CITY: "STERKSEL",
      },
      {
        CITY: "STEVENSBEEK",
      },
      {
        CITY: "STEVENSWEERT",
      },
      {
        CITY: "STIENS",
      },
      {
        CITY: "STOLWIJK",
      },
      {
        CITY: "STOMPETOREN",
      },
      {
        CITY: "STOMPWIJK",
      },
      {
        CITY: "STOUTENBURG",
      },
      {
        CITY: "STRIJEN",
      },
      {
        CITY: "STRIJENSAS",
      },
      {
        CITY: "STROE",
      },
      {
        CITY: "STUIFZAND",
      },
      {
        CITY: "SUMAR",
      },
      {
        CITY: "SURHUISTERVEEN",
      },
      {
        CITY: "SURHUIZUM",
      },
      {
        CITY: "SUSTEREN",
      },
      {
        CITY: "SUWALD",
      },
      {
        CITY: "SWALMEN",
      },
      {
        CITY: "SWEIKHUIZEN",
      },
      {
        CITY: "SWIFTERBANT",
      },
      {
        CITY: "TAARLO",
      },
      {
        CITY: "TEGELEN",
      },
      {
        CITY: "TEN BOER",
      },
      {
        CITY: "TEN POST",
      },
      {
        CITY: "TER AAR",
      },
      {
        CITY: "TER APEL",
      },
      {
        CITY: "TER APELKANAAL",
      },
      {
        CITY: "TERBLIJT",
      },
      {
        CITY: "TERBORG",
      },
      {
        CITY: "TERHEIJDEN",
      },
      {
        CITY: "TERNEUZEN",
      },
      {
        CITY: "TERSCHUUR",
      },
      {
        CITY: "TETERINGEN",
      },
      {
        CITY: "THE HAGUE",
      },
      {
        CITY: "THOLEN",
      },
      {
        CITY: "TIEL",
      },
      {
        CITY: "TIENHOVEN",
      },
      {
        CITY: "TIENRAIJ",
      },
      {
        CITY: "TIJNJE",
      },
      {
        CITY: "TILBURG",
      },
      {
        CITY: "TILLIGTE",
      },
      {
        CITY: "TINTE",
      },
      {
        CITY: "TJERKWERD",
      },
      {
        CITY: "TOLBERT",
      },
      {
        CITY: "TOLDIJK",
      },
      {
        CITY: "TOLKAMER",
      },
      {
        CITY: "TRICHT",
      },
      {
        CITY: "TUBBERGEN",
      },
      {
        CITY: "TUITJENHORN",
      },
      {
        CITY: "TULL",
      },
      {
        CITY: "TWEEDE EXLOERMOND",
      },
      {
        CITY: "TWEEDE VALTHERMOND",
      },
      {
        CITY: "TWELLO",
      },
      {
        CITY: "TWIJZELERHEIDE",
      },
      {
        CITY: "TWISK",
      },
      {
        CITY: "TYNAARLO",
      },
      {
        CITY: "TYTSJERK",
      },
      {
        CITY: "TZUMMARUM",
      },
      {
        CITY: "UDDEL",
      },
      {
        CITY: "UDEN",
      },
      {
        CITY: "UDENHOUT",
      },
      {
        CITY: "UGCHELEN",
      },
      {
        CITY: "UITDAM",
      },
      {
        CITY: "UITGEEST",
      },
      {
        CITY: "UITHOORN",
      },
      {
        CITY: "UITHUIZEN",
      },
      {
        CITY: "UITHUIZERMEEDEN",
      },
      {
        CITY: "UITWELLINGERGA",
      },
      {
        CITY: "ULESTRATEN",
      },
      {
        CITY: "ULFT",
      },
      {
        CITY: "ULICOTEN",
      },
      {
        CITY: "ULRUM",
      },
      {
        CITY: "ULVENHOUT",
      },
      {
        CITY: "URETERP",
      },
      {
        CITY: "URK",
      },
      {
        CITY: "URMOND",
      },
      {
        CITY: "URSEM",
      },
      {
        CITY: "UTRECHT",
      },
      {
        CITY: "VAALS",
      },
      {
        CITY: "VAASSEN",
      },
      {
        CITY: "VALBURG",
      },
      {
        CITY: "VALKENBURG",
      },
      {
        CITY: "VALKENBURG",
      },
      {
        CITY: "VALKENSWAARD",
      },
      {
        CITY: "VALTHE",
      },
      {
        CITY: "VALTHERMOND",
      },
      {
        CITY: "VARIK",
      },
      {
        CITY: "VARSSEVELD",
      },
      {
        CITY: "VASSE",
      },
      {
        CITY: "VEEN",
      },
      {
        CITY: "VEENDAM",
      },
      {
        CITY: "VEENENDAAL",
      },
      {
        CITY: "VEENHUIZEN",
      },
      {
        CITY: "VEENOORD",
      },
      {
        CITY: "VEGHEL",
      },
      {
        CITY: "VELDDRIEL",
      },
      {
        CITY: "VELDEN",
      },
      {
        CITY: "VELDHOVEN",
      },
      {
        CITY: "VELP",
      },
      {
        CITY: "VELSEN",
      },
      {
        CITY: "VELSEN-NOORD",
      },
      {
        CITY: "VEN-ZELDERHEIDE",
      },
      {
        CITY: "VENHORST",
      },
      {
        CITY: "VENHUIZEN",
      },
      {
        CITY: "VENLO",
      },
      {
        CITY: "VENRAY",
      },
      {
        CITY: "VIANEN",
      },
      {
        CITY: "VIERHOUTEN",
      },
      {
        CITY: "VIERLINGSBEEK",
      },
      {
        CITY: "VIERPOLDERS",
      },
      {
        CITY: "VIJFHUIZEN",
      },
      {
        CITY: "VILT",
      },
      {
        CITY: "VINKEVEEN",
      },
      {
        CITY: "VLAARDINGEN",
      },
      {
        CITY: "VLAGTWEDDE",
      },
      {
        CITY: "VLEDDER",
      },
      {
        CITY: "VLEUTEN",
      },
      {
        CITY: "VLIELAND",
      },
      {
        CITY: "VLIJMEN",
      },
      {
        CITY: "VLODROP",
      },
      {
        CITY: "VOERENDAAL",
      },
      {
        CITY: "VOGELENZANG",
      },
      {
        CITY: "VOGELWAARDE",
      },
      {
        CITY: "VOLENDAM",
      },
      {
        CITY: "VOLKEL",
      },
      {
        CITY: "VOORBURG",
      },
      {
        CITY: "VOORHOUT",
      },
      {
        CITY: "VOORSCHOTEN",
      },
      {
        CITY: "VOORST",
      },
      {
        CITY: "VOORTHUIZEN",
      },
      {
        CITY: "VORDEN",
      },
      {
        CITY: "VORSTENBOSCH",
      },
      {
        CITY: "VORTUM-MULLEM",
      },
      {
        CITY: "VRAGENDER",
      },
      {
        CITY: "VREELAND",
      },
      {
        CITY: "VRIES",
      },
      {
        CITY: "VRIEZENVEEN",
      },
      {
        CITY: "VROOMSHOOP",
      },
      {
        CITY: "VROUWENPOLDER",
      },
      {
        CITY: "VUGHT",
      },
      {
        CITY: "VUREN",
      },
      {
        CITY: "WAALRE",
      },
      {
        CITY: "WAALWIJK",
      },
      {
        CITY: "WAARDENBURG",
      },
      {
        CITY: "WAARDER",
      },
      {
        CITY: "WAARLAND",
      },
      {
        CITY: "WADDINXVEEN",
      },
      {
        CITY: "WAGENBERG",
      },
      {
        CITY: "WAGENBORGEN",
      },
      {
        CITY: "WAGENINGEN",
      },
      {
        CITY: "WALTERSWALD",
      },
      {
        CITY: "WAMEL",
      },
      {
        CITY: "WANROIJ",
      },
      {
        CITY: "WANSSUM",
      },
      {
        CITY: "WAPENVELD",
      },
      {
        CITY: "WARDER",
      },
      {
        CITY: "WARFFUM",
      },
      {
        CITY: "WARMENHUIZEN",
      },
      {
        CITY: "WARMOND",
      },
      {
        CITY: "WARNSVELD",
      },
      {
        CITY: "WASPIK",
      },
      {
        CITY: "WASSENAAR",
      },
      {
        CITY: "WATERINGEN",
      },
      {
        CITY: "WATERLAND",
      },
      {
        CITY: "WEERSELO",
      },
      {
        CITY: "WEERT",
      },
      {
        CITY: "WEESP",
      },
      {
        CITY: "WEHL",
      },
      {
        CITY: "WEIDUM",
      },
      {
        CITY: "WELL",
      },
      {
        CITY: "WELLERLOOI",
      },
      {
        CITY: "WEMELDINGE",
      },
      {
        CITY: "WERKENDAM",
      },
      {
        CITY: "WERKHOVEN",
      },
      {
        CITY: "WERVERSHOOF",
      },
      {
        CITY: "WESSEM",
      },
      {
        CITY: "WEST-KNOLLENDAM",
      },
      {
        CITY: "WEST-TERSCHELLING",
      },
      {
        CITY: "WESTBEEMSTER",
      },
      {
        CITY: "WESTBROEK",
      },
      {
        CITY: "WESTDORPE",
      },
      {
        CITY: "WESTERBORK",
      },
      {
        CITY: "WESTERHAAR-VRIEZENVEENSEWIJK",
      },
      {
        CITY: "WESTERHOVEN",
      },
      {
        CITY: "WESTERVOORT",
      },
      {
        CITY: "WESTERWIJTWERD",
      },
      {
        CITY: "WESTKAPELLE",
      },
      {
        CITY: "WESTMAAS",
      },
      {
        CITY: "WESTZAAN",
      },
      {
        CITY: "WEURT",
      },
      {
        CITY: "WEZEP",
      },
      {
        CITY: "WIERDEN",
      },
      {
        CITY: "WIERINGERWAARD",
      },
      {
        CITY: "WIERINGERWERF",
      },
      {
        CITY: "WIERUM",
      },
      {
        CITY: "WIJCHEN",
      },
      {
        CITY: "WIJCKEL",
      },
      {
        CITY: "WIJDENES",
      },
      {
        CITY: "WIJDEWORMER",
      },
      {
        CITY: "WIJHE",
      },
      {
        CITY: "WIJK AAN ZEE",
      },
      {
        CITY: "WIJK BIJ DUURSTEDE",
      },
      {
        CITY: "WIJLRE",
      },
      {
        CITY: "WIJNALDUM",
      },
      {
        CITY: "WIJNANDSRADE",
      },
      {
        CITY: "WIJNGAARDEN",
      },
      {
        CITY: "WIJSTER",
      },
      {
        CITY: "WILDERVANK",
      },
      {
        CITY: "WILLEMSTAD",
      },
      {
        CITY: "WILNIS",
      },
      {
        CITY: "WILP",
      },
      {
        CITY: "WINKEL",
      },
      {
        CITY: "WINSCHOTEN",
      },
      {
        CITY: "WINSSEN",
      },
      {
        CITY: "WINSUM",
      },
      {
        CITY: "WINTELRE",
      },
      {
        CITY: "WINTERSWIJK",
      },
      {
        CITY: "WISSENKERKE",
      },
      {
        CITY: "WITHAREN",
      },
      {
        CITY: "WITTEM",
      },
      {
        CITY: "WITTEVEEN",
      },
      {
        CITY: "WOENSDRECHT",
      },
      {
        CITY: "WOERDEN",
      },
      {
        CITY: "WOERDENSE VERLAAT",
      },
      {
        CITY: "WOGNUM",
      },
      {
        CITY: "WOLFHEZE",
      },
      {
        CITY: "WOLPHAARTSDIJK",
      },
      {
        CITY: "WOLTERSUM",
      },
      {
        CITY: "WOLVEGA",
      },
      {
        CITY: "WOMMELS",
      },
      {
        CITY: "WONS",
      },
      {
        CITY: "WORKUM",
      },
      {
        CITY: "WORMER",
      },
      {
        CITY: "WORMERVEER",
      },
      {
        CITY: "WOUBRUGGE",
      },
      {
        CITY: "WOUDENBERG",
      },
      {
        CITY: "WOUDRICHEM",
      },
      {
        CITY: "WOUDSEND",
      },
      {
        CITY: "WOUWSCHE PLANTAGE",
      },
      {
        CITY: "YDE",
      },
      {
        CITY: "YERSEKE",
      },
      {
        CITY: "YSBRECHTUM",
      },
      {
        CITY: "ZAAMSLAG",
      },
      {
        CITY: "ZAANDAM",
      },
      {
        CITY: "ZAANDIJK",
      },
      {
        CITY: "ZAANSTAD",
      },
      {
        CITY: "ZALK",
      },
      {
        CITY: "ZALTBOMMEL",
      },
      {
        CITY: "ZANDEWEER",
      },
      {
        CITY: "ZANDVOORT",
      },
      {
        CITY: "ZEDDAM",
      },
      {
        CITY: "ZEELAND",
      },
      {
        CITY: "ZEERIJP",
      },
      {
        CITY: "ZEEWOLDE",
      },
      {
        CITY: "ZEIST",
      },
      {
        CITY: "ZELHEM",
      },
      {
        CITY: "ZETTEN",
      },
      {
        CITY: "ZEVENAAR",
      },
      {
        CITY: "ZEVENBERGEN",
      },
      {
        CITY: "ZEVENBERGSCHEN HOEK",
      },
      {
        CITY: "ZEVENHOVEN",
      },
      {
        CITY: "ZEVENHUIZEN",
      },
      {
        CITY: "ZIERIKZEE",
      },
      {
        CITY: "ZIEUWENT",
      },
      {
        CITY: "ZIJDERVELD",
      },
      {
        CITY: "ZOETERMEER",
      },
      {
        CITY: "ZOETERWOUDE",
      },
      {
        CITY: "ZONNEMAIRE",
      },
      {
        CITY: "ZORGVLIED",
      },
      {
        CITY: "ZOUTELANDE",
      },
      {
        CITY: "ZOUTKAMP",
      },
      {
        CITY: "ZUID-BEIJERLAND",
      },
      {
        CITY: "ZUID-SCHARWOUDE",
      },
      {
        CITY: "ZUIDBROEK",
      },
      {
        CITY: "ZUIDERMEER",
      },
      {
        CITY: "ZUIDERPARK",
      },
      {
        CITY: "ZUIDHORN",
      },
      {
        CITY: "ZUIDLAND",
      },
      {
        CITY: "ZUIDLAREN",
      },
      {
        CITY: "ZUIDOOSTBEEMSTER",
      },
      {
        CITY: "ZUIDWOLDE",
      },
      {
        CITY: "ZUIDZANDE",
      },
      {
        CITY: "ZUILICHEM",
      },
      {
        CITY: "ZUNDERT",
      },
      {
        CITY: "ZUTPHEN",
      },
      {
        CITY: "ZWAAG",
      },
      {
        CITY: "ZWAAGDIJK-OOST",
      },
      {
        CITY: "ZWAANSHOEK",
      },
      {
        CITY: "ZWANENBURG",
      },
      {
        CITY: "ZWARTEBROEK",
      },
      {
        CITY: "ZWARTEMEER",
      },
      {
        CITY: "ZWARTEWAAL",
      },
      {
        CITY: "ZWARTSLUIS",
      },
      {
        CITY: "ZWIJNDRECHT",
      },
      {
        CITY: "ZWINDEREN",
      },
      {
        CITY: "ZWOLLE",
      },
    ],
  },
  {
    COUNTRY: "NIGERIA",
    CODE: "NG",
    CITIES: [
      {
        CITY: "ABA",
      },
      {
        CITY: "ABAKALIKI",
      },
      {
        CITY: "ABEOKUTA",
      },
      {
        CITY: "ABRAKA",
      },
      {
        CITY: "ABRAKA",
      },
      {
        CITY: "ABUJA",
      },
      {
        CITY: "ADO-EKITI",
      },
      {
        CITY: "ADODO",
      },
      {
        CITY: "AGANGA",
      },
      {
        CITY: "AGEGE",
      },
      {
        CITY: "AGIDINGBI",
      },
      {
        CITY: "AJEGUNLE",
      },
      {
        CITY: "AJUWON",
      },
      {
        CITY: "AKURE",
      },
      {
        CITY: "ALIMOSHO",
      },
      {
        CITY: "ANAMBRA",
      },
      {
        CITY: "APAPA",
      },
      {
        CITY: "AYOBO",
      },
      {
        CITY: "BENIN CITY",
      },
      {
        CITY: "BIRNIN KEBBI",
      },
      {
        CITY: "BONNY",
      },
      {
        CITY: "BURUTU",
      },
      {
        CITY: "BWARI",
      },
      {
        CITY: "CALABAR",
      },
      {
        CITY: "CHAFE",
      },
      {
        CITY: "DAMATURU",
      },
      {
        CITY: "EGBEDA",
      },
      {
        CITY: "EKPOMA",
      },
      {
        CITY: "ENUGU",
      },
      {
        CITY: "FORUM",
      },
      {
        CITY: "FUNTUA",
      },
      {
        CITY: "IBADAN",
      },
      {
        CITY: "IDO",
      },
      {
        CITY: "IFAKO",
      },
      {
        CITY: "IGANDO",
      },
      {
        CITY: "IGUEBEN",
      },
      {
        CITY: "IKEJA",
      },
      {
        CITY: "IKORODU",
      },
      {
        CITY: "IKOTUN",
      },
      {
        CITY: "ILE-IFE",
      },
      {
        CITY: "ILESA",
      },
      {
        CITY: "ILORIN",
      },
      {
        CITY: "IPAJA",
      },
      {
        CITY: "ISERI-OKE",
      },
      {
        CITY: "ISOLO",
      },
      {
        CITY: "JALINGO",
      },
      {
        CITY: "JOS",
      },
      {
        CITY: "KADUNA",
      },
      {
        CITY: "KANO",
      },
      {
        CITY: "KEBBI",
      },
      {
        CITY: "LAGOS",
      },
      {
        CITY: "LEKKI",
      },
      {
        CITY: "LOKOJA",
      },
      {
        CITY: "MAGODO",
      },
      {
        CITY: "MAKURDI",
      },
      {
        CITY: "MARYLAND",
      },
      {
        CITY: "MINNA",
      },
      {
        CITY: "MOGHO",
      },
      {
        CITY: "MOWE",
      },
      {
        CITY: "MUSHIN",
      },
      {
        CITY: "NSUKKA",
      },
      {
        CITY: "OBAFEMI",
      },
      {
        CITY: "OBUDU",
      },
      {
        CITY: "ODAU",
      },
      {
        CITY: "OJO",
      },
      {
        CITY: "OJOTA",
      },
      {
        CITY: "ONDO",
      },
      {
        CITY: "ONIGBONGBO",
      },
      {
        CITY: "ORILE OSHODI",
      },
      {
        CITY: "OSHODI",
      },
      {
        CITY: "OSOGBO",
      },
      {
        CITY: "OTA",
      },
      {
        CITY: "OWERRI",
      },
      {
        CITY: "OWORONSOKI",
      },
      {
        CITY: "PORT HARCOURT",
      },
      {
        CITY: "SHOMOLU",
      },
      {
        CITY: "SULEJA",
      },
      {
        CITY: "SURU-LERE",
      },
      {
        CITY: "TARA",
      },
      {
        CITY: "UGHELLI",
      },
      {
        CITY: "UNGWAN MADAKI",
      },
      {
        CITY: "UYO",
      },
      {
        CITY: "WARRI",
      },
      {
        CITY: "WARRI",
      },
      {
        CITY: "YABA",
      },
      {
        CITY: "YOLA",
      },
      {
        CITY: "ZARIA",
      },
    ],
  },
  {
    COUNTRY: "PAKISTAN",
    CODE: "PK",
    CITIES: [
      {
        CITY: "ABBOTTABAD",
      },
      {
        CITY: "ATTOCK",
      },
      {
        CITY: "BATGRAM",
      },
      {
        CITY: "BHIMBAR",
      },
      {
        CITY: "BUREWALA",
      },
      {
        CITY: "CANTT",
      },
      {
        CITY: "CHAKWAL",
      },
      {
        CITY: "CLIFTON",
      },
      {
        CITY: "DASKA",
      },
      {
        CITY: "DAUD KHEL",
      },
      {
        CITY: "DERA GHAZI KHAN",
      },
      {
        CITY: "FAISALABAD",
      },
      {
        CITY: "FAZAL",
      },
      {
        CITY: "GILGIT",
      },
      {
        CITY: "GOTH ABAD MAGSI",
      },
      {
        CITY: "GUJAR KHAN",
      },
      {
        CITY: "GUJRANWALA",
      },
      {
        CITY: "GUJRAT",
      },
      {
        CITY: "GULBERG",
      },
      {
        CITY: "GULSHAN-E-IQBAL",
      },
      {
        CITY: "HABIB BAIHK",
      },
      {
        CITY: "HARIPUR",
      },
      {
        CITY: "HAVELIAN",
      },
      {
        CITY: "HYDERABAD",
      },
      {
        CITY: "ISLAMABAD",
      },
      {
        CITY: "JHANG CITY",
      },
      {
        CITY: "JHANG SADR",
      },
      {
        CITY: "JHELUM",
      },
      {
        CITY: "JHUMRA",
      },
      {
        CITY: "KABIRWALA",
      },
      {
        CITY: "KARACHI",
      },
      {
        CITY: "KASUR",
      },
      {
        CITY: "KHAN",
      },
      {
        CITY: "KHANEWAL",
      },
      {
        CITY: "KHANPUR",
      },
      {
        CITY: "KOHAT",
      },
      {
        CITY: "LAHORE",
      },
      {
        CITY: "MANDI",
      },
      {
        CITY: "MANDI BAHAUDDIN",
      },
      {
        CITY: "MANSEHRA",
      },
      {
        CITY: "MARDAN",
      },
      {
        CITY: "MIAN CHANNU",
      },
      {
        CITY: "MIANWALI",
      },
      {
        CITY: "MIRAN SHAH",
      },
      {
        CITY: "MULTAN",
      },
      {
        CITY: "MUZAFFARABAD",
      },
      {
        CITY: "NANGAR",
      },
      {
        CITY: "NANKANA SAHIB",
      },
      {
        CITY: "NAROWAL",
      },
      {
        CITY: "NEW MIRPUR",
      },
      {
        CITY: "NOWSHERA",
      },
      {
        CITY: "OKARA",
      },
      {
        CITY: "PESHAWAR",
      },
      {
        CITY: "PINDI",
      },
      {
        CITY: "PLOT",
      },
      {
        CITY: "QUETTA",
      },
      {
        CITY: "RAWALPINDI",
      },
      {
        CITY: "RAWLAKOT",
      },
      {
        CITY: "SADDAR",
      },
      {
        CITY: "SAHIWAL",
      },
      {
        CITY: "SARAI SIDHU",
      },
      {
        CITY: "SARGODHA",
      },
      {
        CITY: "SARWAR",
      },
      {
        CITY: "SHEIKHUPURA",
      },
      {
        CITY: "SIALKOT",
      },
      {
        CITY: "SUKKUR",
      },
      {
        CITY: "TOBA TEK SINGH",
      },
      {
        CITY: "USMAN",
      },
      {
        CITY: "WAZIRABAD",
      },
      {
        CITY: "ZIAUDDIN",
      },
    ],
  },
  {
    COUNTRY: "MONGOLIA",
    CODE: "MN",
    CITIES: [
      {
        CITY: "ALTAI",
      },
      {
        CITY: "ARVAYHEER",
      },
      {
        CITY: "BARUUN-URT",
      },
      {
        CITY: "BAYANGOL",
      },
      {
        CITY: "BAYANHONGOR",
      },
      {
        CITY: "CECERLEG",
      },
      {
        CITY: "CHIHERTEY",
      },
      {
        CITY: "CHOYR",
      },
      {
        CITY: "DALANDZADGAD",
      },
      {
        CITY: "DARHAN",
      },
      {
        CITY: "HAN-UUL",
      },
      {
        CITY: "JAVHLANT",
      },
      {
        CITY: "KHOVD",
      },
      {
        CITY: "MANDALGOVI",
      },
      {
        CITY: "OVOOT",
      },
      {
        CITY: "SAYNSHAND",
      },
      {
        CITY: "TOYRIM",
      },
      {
        CITY: "ULAAN-UUL",
      },
      {
        CITY: "ULAN BATOR",
      },
      {
        CITY: "ULIASTAY",
      },
      {
        CITY: "UNDURKHAAN",
      },
    ],
  },
  {
    COUNTRY: "MALAYSIA",
    CODE: "MY",
    CITIES: [
      {
        CITY: "ALOR GAJAH",
      },
      {
        CITY: "ALOR STAR",
      },
      {
        CITY: "AMPANG",
      },
      {
        CITY: "AYER ITAM",
      },
      {
        CITY: "AYER TAWAR",
      },
      {
        CITY: "BACHOK",
      },
      {
        CITY: "BAGAN SERAI",
      },
      {
        CITY: "BAHAU",
      },
      {
        CITY: "BALIK PULAU",
      },
      {
        CITY: "BANDAR",
      },
      {
        CITY: "BANDAR BARU BANGI",
      },
      {
        CITY: "BANDAR TENGGARA",
      },
      {
        CITY: "BANDAR TUN RAZAK",
      },
      {
        CITY: "BANGI",
      },
      {
        CITY: "BANTING",
      },
      {
        CITY: "BARU",
      },
      {
        CITY: "BATANG BERJUNTAI",
      },
      {
        CITY: "BATANG KALI",
      },
      {
        CITY: "BATU",
      },
      {
        CITY: "BATU ARANG",
      },
      {
        CITY: "BATU BERENDAM",
      },
      {
        CITY: "BATU CAVES",
      },
      {
        CITY: "BATU FERINGGI",
      },
      {
        CITY: "BATU GAJAH",
      },
      {
        CITY: "BATU PAHAT",
      },
      {
        CITY: "BAU",
      },
      {
        CITY: "BAYAN LEPAS",
      },
      {
        CITY: "BEAUFORT",
      },
      {
        CITY: "BEDONG",
      },
      {
        CITY: "BENTUNG",
      },
      {
        CITY: "BENUT",
      },
      {
        CITY: "BIDUR",
      },
      {
        CITY: "BINTULU",
      },
      {
        CITY: "BONGAWAN",
      },
      {
        CITY: "BROGA",
      },
      {
        CITY: "BUKIT ASAHAN",
      },
      {
        CITY: "BUKIT GAMBIR",
      },
      {
        CITY: "BUKIT KAYU HITAM",
      },
      {
        CITY: "BUKIT MERTAJAM",
      },
      {
        CITY: "BUKIT PASIR",
      },
      {
        CITY: "BUKIT ROTAN",
      },
      {
        CITY: "BUTTERWORTH",
      },
      {
        CITY: "CAMERON HIGHLANDS",
      },
      {
        CITY: "CHANGLOON",
      },
      {
        CITY: "CHEMOR",
      },
      {
        CITY: "CHERAS",
      },
      {
        CITY: "CUKAI",
      },
      {
        CITY: "CYBERJAYA",
      },
      {
        CITY: "DAMANSARA",
      },
      {
        CITY: "DENGKIL",
      },
      {
        CITY: "DUNGUN",
      },
      {
        CITY: "DURIAN TUNGGAL",
      },
      {
        CITY: "GEBENG",
      },
      {
        CITY: "GELANG PATAH",
      },
      {
        CITY: "GEMAS",
      },
      {
        CITY: "GENTING",
      },
      {
        CITY: "GEORGE TOWN",
      },
      {
        CITY: "GERIK",
      },
      {
        CITY: "GOPENG",
      },
      {
        CITY: "GUA MUSANG",
      },
      {
        CITY: "GURUN",
      },
      {
        CITY: "HULU LANGAT",
      },
      {
        CITY: "HUTAN MELINTANG",
      },
      {
        CITY: "INANAM",
      },
      {
        CITY: "IPOH",
      },
      {
        CITY: "ITAM",
      },
      {
        CITY: "JASIN",
      },
      {
        CITY: "JAWI",
      },
      {
        CITY: "JELUTONG",
      },
      {
        CITY: "JENJARUM",
      },
      {
        CITY: "JERAM",
      },
      {
        CITY: "JERANTUT",
      },
      {
        CITY: "JERTIH",
      },
      {
        CITY: "JINJANG",
      },
      {
        CITY: "JITRA",
      },
      {
        CITY: "JOHOR BAHRU",
      },
      {
        CITY: "JUASSEH",
      },
      {
        CITY: "KAJANG",
      },
      {
        CITY: "KAMPAR",
      },
      {
        CITY: "KAMPONG CHANGKAT",
      },
      {
        CITY: "KAMPONG KAHANG BATU TWENTY-EIGHT",
      },
      {
        CITY: "KAMPONG KEMAMAN",
      },
      {
        CITY: "KAMPONG PASIR SEGAMBUT",
      },
      {
        CITY: "KAMPUNG AYER KEROH",
      },
      {
        CITY: "KAMPUNG BAHARU NILAI",
      },
      {
        CITY: "KAMPUNG BATU KIKIR",
      },
      {
        CITY: "KAMPUNG BATU UBAN",
      },
      {
        CITY: "KAMPUNG BOTA KIRI",
      },
      {
        CITY: "KAMPUNG LIKAS",
      },
      {
        CITY: "KAMPUNG PAYA SIMPANG EMPAT GENTING",
      },
      {
        CITY: "KAMPUNG PEKAN KECHIL BERANANG",
      },
      {
        CITY: "KAMPUNG TANJUNG ARU",
      },
      {
        CITY: "KAMPUNG TASEK",
      },
      {
        CITY: "KAMPUNG TELUK BARU",
      },
      {
        CITY: "KAMPUNG TERIANG",
      },
      {
        CITY: "KAMUNTING",
      },
      {
        CITY: "KANGAR",
      },
      {
        CITY: "KAPAR",
      },
      {
        CITY: "KARAK",
      },
      {
        CITY: "KENINGAU",
      },
      {
        CITY: "KEPALA BATAS",
      },
      {
        CITY: "KEPALA BATAS",
      },
      {
        CITY: "KEPONG",
      },
      {
        CITY: "KERAMAT PULAI",
      },
      {
        CITY: "KERLING",
      },
      {
        CITY: "KERTIH",
      },
      {
        CITY: "KETEREH",
      },
      {
        CITY: "KLANG",
      },
      {
        CITY: "KLUANG",
      },
      {
        CITY: "KOMPLEKS SUKAN TENOM",
      },
      {
        CITY: "KOTA",
      },
      {
        CITY: "KOTA BAHARU",
      },
      {
        CITY: "KOTA BELUD",
      },
      {
        CITY: "KOTA BHARU",
      },
      {
        CITY: "KOTA KINABALU",
      },
      {
        CITY: "KOTA TINGGI",
      },
      {
        CITY: "KUAH",
      },
      {
        CITY: "KUALA",
      },
      {
        CITY: "KUALA BERANG",
      },
      {
        CITY: "KUALA KANGSAR",
      },
      {
        CITY: "KUALA KETIL",
      },
      {
        CITY: "KUALA KLAWANG",
      },
      {
        CITY: "KUALA KUBU BAHARU",
      },
      {
        CITY: "KUALA LIPIS",
      },
      {
        CITY: "KUALA LUMPUR",
      },
      {
        CITY: "KUALA NERANG",
      },
      {
        CITY: "KUALA PAHANG",
      },
      {
        CITY: "KUALA PENYU",
      },
      {
        CITY: "KUALA PILAH",
      },
      {
        CITY: "KUALA SELANGOR",
      },
      {
        CITY: "KUALA TERENGGANU",
      },
      {
        CITY: "KUANG",
      },
      {
        CITY: "KUANTAN",
      },
      {
        CITY: "KUBANG KERIAN",
      },
      {
        CITY: "KUCHING",
      },
      {
        CITY: "KULAI",
      },
      {
        CITY: "KULIM",
      },
      {
        CITY: "LABIS",
      },
      {
        CITY: "LABU",
      },
      {
        CITY: "LAHAD DATU",
      },
      {
        CITY: "LAHAT",
      },
      {
        CITY: "LANGAT",
      },
      {
        CITY: "LAYANG LAYANG",
      },
      {
        CITY: "LENGGENG",
      },
      {
        CITY: "LINTANG",
      },
      {
        CITY: "LUKUT",
      },
      {
        CITY: "LUMUT",
      },
      {
        CITY: "LUNAS",
      },
      {
        CITY: "MACHANG",
      },
      {
        CITY: "MALACCA",
      },
      {
        CITY: "MANTIN",
      },
      {
        CITY: "MARANG",
      },
      {
        CITY: "MASAI",
      },
      {
        CITY: "MASJID TANAH",
      },
      {
        CITY: "MELAKA TENGAH",
      },
      {
        CITY: "MELOR",
      },
      {
        CITY: "MEMBAKUT",
      },
      {
        CITY: "MENGGATAL",
      },
      {
        CITY: "MENGLEMBU",
      },
      {
        CITY: "MENTEKAB",
      },
      {
        CITY: "MERBOK",
      },
      {
        CITY: "MERLIMAU",
      },
      {
        CITY: "MIRI",
      },
      {
        CITY: "MUADZAM SHAH",
      },
      {
        CITY: "MUAR TOWN",
      },
      {
        CITY: "MUKAH",
      },
      {
        CITY: "NIBONG TEBAL",
      },
      {
        CITY: "PADANG RENGAS",
      },
      {
        CITY: "PADANG SERAI",
      },
      {
        CITY: "PANDAN",
      },
      {
        CITY: "PANTAI",
      },
      {
        CITY: "PANTAI REMIS",
      },
      {
        CITY: "PAPAR",
      },
      {
        CITY: "PARIT",
      },
      {
        CITY: "PARIT BUNTAR",
      },
      {
        CITY: "PARIT RAJA",
      },
      {
        CITY: "PASIR GUDANG",
      },
      {
        CITY: "PASIR MAS",
      },
      {
        CITY: "PASIR PUTEH",
      },
      {
        CITY: "PEDAS",
      },
      {
        CITY: "PEKAN",
      },
      {
        CITY: "PENAGA",
      },
      {
        CITY: "PENAMPANG",
      },
      {
        CITY: "PENDANG",
      },
      {
        CITY: "PENGERANG",
      },
      {
        CITY: "PENGKALAN CHEPA",
      },
      {
        CITY: "PENGKALAN KEMPAS",
      },
      {
        CITY: "PERAI",
      },
      {
        CITY: "PERMATANG PAUH",
      },
      {
        CITY: "PETALING JAYA",
      },
      {
        CITY: "PONTIAN KECHIL",
      },
      {
        CITY: "PORT DICKSON",
      },
      {
        CITY: "PORT KLANG",
      },
      {
        CITY: "PUCHONG BATU DUA BELAS",
      },
      {
        CITY: "PUDU",
      },
      {
        CITY: "PUDU ULU",
      },
      {
        CITY: "PULAU PINANG",
      },
      {
        CITY: "PUSING",
      },
      {
        CITY: "PUTRAJAYA",
      },
      {
        CITY: "RANAU",
      },
      {
        CITY: "RAUB",
      },
      {
        CITY: "RAWANG",
      },
      {
        CITY: "REMBAU",
      },
      {
        CITY: "RENGAM",
      },
      {
        CITY: "RENGIT",
      },
      {
        CITY: "SABAK BERNAM",
      },
      {
        CITY: "SANDAKAN",
      },
      {
        CITY: "SARIKEI",
      },
      {
        CITY: "SEBERANG JAYA",
      },
      {
        CITY: "SEGAMAT",
      },
      {
        CITY: "SELAMA",
      },
      {
        CITY: "SEMENYIH",
      },
      {
        CITY: "SEMERAH",
      },
      {
        CITY: "SENGGARANG",
      },
      {
        CITY: "SENTUL",
      },
      {
        CITY: "SEPANG",
      },
      {
        CITY: "SERDANG",
      },
      {
        CITY: "SERDANG",
      },
      {
        CITY: "SEREMBAN",
      },
      {
        CITY: "SERENDAH",
      },
      {
        CITY: "SERI KEMBANGAN",
      },
      {
        CITY: "SERI MANJUNG",
      },
      {
        CITY: "SETAPAK",
      },
      {
        CITY: "SHAH ALAM",
      },
      {
        CITY: "SIBU",
      },
      {
        CITY: "SIMPANG EMPAT",
      },
      {
        CITY: "SIMPANG PERTANG",
      },
      {
        CITY: "SIMPANG RENGGAM",
      },
      {
        CITY: "SIMPANG TIGA",
      },
      {
        CITY: "SIMUNJAN",
      },
      {
        CITY: "SITIAWAN",
      },
      {
        CITY: "SLIM RIVER",
      },
      {
        CITY: "SUNGAI BAKAP",
      },
      {
        CITY: "SUNGAI BESAR",
      },
      {
        CITY: "SUNGAI BESI",
      },
      {
        CITY: "SUNGAI BULUH",
      },
      {
        CITY: "SUNGAI PELEK",
      },
      {
        CITY: "SUNGAI PETANI",
      },
      {
        CITY: "SUNGAI PUYU",
      },
      {
        CITY: "SUNGAI UDANG",
      },
      {
        CITY: "TAIPING",
      },
      {
        CITY: "TAMAN BUKIT SKUDAI",
      },
      {
        CITY: "TAMAN DESA",
      },
      {
        CITY: "TAMAN KOTA JAYA",
      },
      {
        CITY: "TAMAN MEGAH RIA",
      },
      {
        CITY: "TAMAN PRAI",
      },
      {
        CITY: "TAMAN SENAI",
      },
      {
        CITY: "TAMAN SENAWANG INDAH",
      },
      {
        CITY: "TAMAN SEREMBAN JAYA",
      },
      {
        CITY: "TAMAN SRI AMPANG",
      },
      {
        CITY: "TAMBUNAN",
      },
      {
        CITY: "TAMPARULI",
      },
      {
        CITY: "TAMPIN",
      },
      {
        CITY: "TAMPOI",
      },
      {
        CITY: "TANAH MERAH",
      },
      {
        CITY: "TANAH MERAH NEW VILLAGE SITE C",
      },
      {
        CITY: "TANGKAK",
      },
      {
        CITY: "TANJONG",
      },
      {
        CITY: "TANJONG KARANG",
      },
      {
        CITY: "TANJONG MALIM",
      },
      {
        CITY: "TANJUNG",
      },
      {
        CITY: "TANJUNG BUNGA",
      },
      {
        CITY: "TANJUNG SEPAT",
      },
      {
        CITY: "TAPAH ROAD",
      },
      {
        CITY: "TASEK GLUGOR",
      },
      {
        CITY: "TATAU",
      },
      {
        CITY: "TAWAU",
      },
      {
        CITY: "TELUK INTAN",
      },
      {
        CITY: "TELUK PANGLIMA GARANG",
      },
      {
        CITY: "TEMERLUH",
      },
      {
        CITY: "TEMOH",
      },
      {
        CITY: "TERIANG",
      },
      {
        CITY: "TERONOH",
      },
      {
        CITY: "TUMPAT",
      },
      {
        CITY: "ULU TIRAM",
      },
      {
        CITY: "VICTORIA",
      },
    ],
  },
  {
    COUNTRY: "MOROCCO",
    CODE: "MA",
    CITIES: [
      {
        CITY: "AFOURER",
      },
      {
        CITY: "AGADIR",
      },
      {
        CITY: "AIT MELLOUL",
      },
      {
        CITY: "AL HOCEIMA",
      },
      {
        CITY: "ASSA",
      },
      {
        CITY: "BENGUERIR",
      },
      {
        CITY: "BENI MELLAL",
      },
      {
        CITY: "BERRECHID",
      },
      {
        CITY: "CASABLANCA",
      },
      {
        CITY: "DEROUA",
      },
      {
        CITY: "EL GARA",
      },
      {
        CITY: "EL HAJEB",
      },
      {
        CITY: "EL JADIDA",
      },
      {
        CITY: "ERFOUD",
      },
      {
        CITY: "FES",
      },
      {
        CITY: "FKIH BEN SALAH",
      },
      {
        CITY: "KENITRA",
      },
      {
        CITY: "KHEMISSET",
      },
      {
        CITY: "KHOURIBGA",
      },
      {
        CITY: "KSAR EL KEBIR",
      },
      {
        CITY: "LARACHE",
      },
      {
        CITY: "MANSOUR",
      },
      {
        CITY: "MARRAKESH",
      },
      {
        CITY: "MEHEDIYAH",
      },
      {
        CITY: "MEKNES",
      },
      {
        CITY: "MOHAMMEDIA",
      },
      {
        CITY: "NADOR",
      },
      {
        CITY: "OUAZZANE",
      },
      {
        CITY: "OUED ZEM",
      },
      {
        CITY: "OUJDA",
      },
      {
        CITY: "OULAD TEIMA",
      },
      {
        CITY: "RABAT",
      },
      {
        CITY: "SAFI",
      },
      {
        CITY: "SEFROU",
      },
      {
        CITY: "SETTAT",
      },
      {
        CITY: "SIDI BENNOUR",
      },
      {
        CITY: "SIDI SLIMANE",
      },
      {
        CITY: "SKHIRAT",
      },
      {
        CITY: "TAHALA",
      },
      {
        CITY: "TAN-TAN",
      },
      {
        CITY: "TANGIER",
      },
      {
        CITY: "TARFAYA",
      },
      {
        CITY: "TAZA",
      },
      {
        CITY: "TEMARA",
      },
      {
        CITY: "TIFLET",
      },
      {
        CITY: "TIZNIT",
      },
      {
        CITY: "TOUISSITE",
      },
    ],
  },
  {
    COUNTRY: "NAMIBIA",
    CODE: "NA",
    CITIES: [
      {
        CITY: "ETUNDA",
      },
      {
        CITY: "GROOTFONTEIN",
      },
      {
        CITY: "KATIMA MULILO",
      },
      {
        CITY: "KEETMANSHOOP",
      },
      {
        CITY: "MPAPUKA",
      },
      {
        CITY: "OLYMPIA",
      },
      {
        CITY: "ONDANGWA",
      },
      {
        CITY: "ONGWEDIVA",
      },
      {
        CITY: "ORANJEMUND",
      },
      {
        CITY: "OSHAKATI",
      },
      {
        CITY: "OTJIWARONGO",
      },
      {
        CITY: "OUTAPI",
      },
      {
        CITY: "SWAKOPMUND",
      },
      {
        CITY: "TSUMEB",
      },
      {
        CITY: "WALVIS BAY",
      },
      {
        CITY: "WINDHOEK",
      },
    ],
  },
  {
    COUNTRY: "NEW CALEDONIA",
    CODE: "NC",
    CITIES: [
      {
        CITY: "DUMBEA",
      },
      {
        CITY: "MONT-DORE",
      },
      {
        CITY: "NOUMEA",
      },
      {
        CITY: "NOUVILLE",
      },
      {
        CITY: "PAITA",
      },
    ],
  },
  {
    COUNTRY: "MADAGASCAR",
    CODE: "MG",
    CITIES: [
      {
        CITY: "AMBANJA",
      },
      {
        CITY: "ANTANANARIVO",
      },
      {
        CITY: "ANTSIRABE",
      },
      {
        CITY: "ANTSIRANANA",
      },
      {
        CITY: "FIANARANTSOA",
      },
      {
        CITY: "TOAMASINA",
      },
      {
        CITY: "TOLIARA",
      },
    ],
  },
  {
    COUNTRY: "MOZAMBIQUE",
    CODE: "MZ",
    CITIES: [
      {
        CITY: "BEIRA",
      },
      {
        CITY: "MAPUTO",
      },
      {
        CITY: "MATOLA",
      },
      {
        CITY: "MOZAMBIQUE",
      },
      {
        CITY: "NAMPULA",
      },
      {
        CITY: "PEMBA",
      },
      {
        CITY: "QUELIMANE",
      },
      {
        CITY: "TETE",
      },
    ],
  },
  {
    COUNTRY: "MALTA",
    CODE: "MT",
    CITIES: [
      {
        CITY: "ATTARD",
      },
      {
        CITY: "BALZAN",
      },
      {
        CITY: "BINGEMMA",
      },
      {
        CITY: "BIRGU",
      },
      {
        CITY: "BIRKIRKARA",
      },
      {
        CITY: "BUGIBBA",
      },
      {
        CITY: "COSPICUA",
      },
      {
        CITY: "DINGLI",
      },
      {
        CITY: "FGURA",
      },
      {
        CITY: "FLORIANA",
      },
      {
        CITY: "FONTANA",
      },
      {
        CITY: "GHAJNSIELEM",
      },
      {
        CITY: "GHARB",
      },
      {
        CITY: "GHASRI",
      },
      {
        CITY: "GHAXAQ",
      },
      {
        CITY: "GUDJA",
      },
      {
        CITY: "GZIRA",
      },
      {
        CITY: "HAL GHARGHUR",
      },
      {
        CITY: "HAMRUN",
      },
      {
        CITY: "HAZ-ZEBBUG",
      },
      {
        CITY: "IL-MADLIENA",
      },
      {
        CITY: "IL-PERGLA",
      },
      {
        CITY: "IMSIDA",
      },
      {
        CITY: "IMTARFA",
      },
      {
        CITY: "IZ-ZEBBIEGH",
      },
      {
        CITY: "KIRKOP",
      },
      {
        CITY: "L-IKLIN",
      },
      {
        CITY: "L-ISKORVIT",
      },
      {
        CITY: "LIJA",
      },
      {
        CITY: "LUQA",
      },
      {
        CITY: "MANIKATA",
      },
      {
        CITY: "MARSA",
      },
      {
        CITY: "MARSALFORN",
      },
      {
        CITY: "MARSASKALA",
      },
      {
        CITY: "MARSAXLOKK",
      },
      {
        CITY: "MDINA",
      },
      {
        CITY: "MELLIEHA",
      },
      {
        CITY: "MOSTA",
      },
      {
        CITY: "MQABBA",
      },
      {
        CITY: "MUNXAR",
      },
      {
        CITY: "NADUR",
      },
      {
        CITY: "NAXXAR",
      },
      {
        CITY: "PAOLA",
      },
      {
        CITY: "PEMBROKE",
      },
      {
        CITY: "QORMI",
      },
      {
        CITY: "QRENDI",
      },
      {
        CITY: "RABAT",
      },
      {
        CITY: "SAFI",
      },
      {
        CITY: "SAINT JOHN",
      },
      {
        CITY: "SAINT LAWRENCE",
      },
      {
        CITY: "SAINT LUCIA",
      },
      {
        CITY: "SAINT VENERA",
      },
      {
        CITY: "SAN PAWL IL-BAHAR",
      },
      {
        CITY: "SANNAT",
      },
      {
        CITY: "SENGLEA",
      },
      {
        CITY: "SIGGIEWI",
      },
      {
        CITY: "SLIEMA",
      },
      {
        CITY: "SWIEQI",
      },
      {
        CITY: "TARXIEN",
      },
      {
        CITY: "VALLETTA",
      },
      {
        CITY: "VICTORIA",
      },
      {
        CITY: "XAGHRA",
      },
      {
        CITY: "XEWKIJA",
      },
      {
        CITY: "ZABBAR",
      },
      {
        CITY: "ZEJTUN",
      },
      {
        CITY: "ZURRIEQ",
      },
    ],
  },
  {
    COUNTRY: "MAURITIUS",
    CODE: "MU",
    CITIES: [
      {
        CITY: "BEAU BASSIN",
      },
      {
        CITY: "CHEMIN GRENIER",
      },
      {
        CITY: "EBENE CYBERCITY",
      },
      {
        CITY: "FLOREAL",
      },
      {
        CITY: "GOODLANDS",
      },
      {
        CITY: "LE REDUIT",
      },
      {
        CITY: "PORT LOUIS",
      },
      {
        CITY: "PORT MATHURIN",
      },
      {
        CITY: "QUATRE BORNES",
      },
      {
        CITY: "ROSE HILL",
      },
      {
        CITY: "SAINT JEAN",
      },
      {
        CITY: "TAMARIN",
      },
      {
        CITY: "VACOAS",
      },
    ],
  },
  {
    COUNTRY: "MYANMAR ",
    CODE: "MM",
    CITIES: [
      {
        CITY: "HLAING",
      },
      {
        CITY: "INYA",
      },
      {
        CITY: "KYAUKTADA",
      },
      {
        CITY: "MANDALAY",
      },
      {
        CITY: "WAGAN",
      },
      {
        CITY: "YANGON",
      },
    ],
  },
  {
    COUNTRY: "NEPAL",
    CODE: "NP",
    CITIES: [
      {
        CITY: "BHARATPUR",
      },
      {
        CITY: "JAWLAKHEL",
      },
      {
        CITY: "KATHMANDU",
      },
      {
        CITY: "LUMBINI",
      },
      {
        CITY: "PALPA",
      },
      {
        CITY: "PATAN",
      },
    ],
  },
  {
    COUNTRY: "NORWAY",
    CODE: "NO",
    CITIES: [
      {
        CITY: "ABELVAER",
      },
      {
        CITY: "ADALSBRUK",
      },
      {
        CITY: "ADLAND",
      },
      {
        CITY: "AGOTNES",
      },
      {
        CITY: "AGSKARDET",
      },
      {
        CITY: "AKER",
      },
      {
        CITY: "AKKARFJORD",
      },
      {
        CITY: "AKREHAMN",
      },
      {
        CITY: "AL",
      },
      {
        CITY: "ALEN",
      },
      {
        CITY: "ALGARD",
      },
      {
        CITY: "ALMAS",
      },
      {
        CITY: "ALTA",
      },
      {
        CITY: "ALVDAL",
      },
      {
        CITY: "AMLI",
      },
      {
        CITY: "AMLI",
      },
      {
        CITY: "AMOT",
      },
      {
        CITY: "AMOT",
      },
      {
        CITY: "ANA-SIRA",
      },
      {
        CITY: "ANDALSNES",
      },
      {
        CITY: "ANDENES",
      },
      {
        CITY: "ANGVIKA",
      },
      {
        CITY: "ANKENES",
      },
      {
        CITY: "ANNSTAD",
      },
      {
        CITY: "ARDAL",
      },
      {
        CITY: "ARDALSTANGEN",
      },
      {
        CITY: "ARENDAL",
      },
      {
        CITY: "ARLAND",
      },
      {
        CITY: "ARNEBERG",
      },
      {
        CITY: "ARNES",
      },
      {
        CITY: "AROS",
      },
      {
        CITY: "AS",
      },
      {
        CITY: "ASEN",
      },
      {
        CITY: "ASERAL",
      },
      {
        CITY: "ASGARDSTRAND",
      },
      {
        CITY: "ASK",
      },
      {
        CITY: "ASK",
      },
      {
        CITY: "ASKER",
      },
      {
        CITY: "ASKIM",
      },
      {
        CITY: "AUKRA",
      },
      {
        CITY: "AULI",
      },
      {
        CITY: "AURDAL",
      },
      {
        CITY: "AURE",
      },
      {
        CITY: "AURSMOEN",
      },
      {
        CITY: "AUSTBO",
      },
      {
        CITY: "AUSTBYGDI",
      },
      {
        CITY: "AUSTEVOLL",
      },
      {
        CITY: "AUSTMARKA",
      },
      {
        CITY: "BAERUMS VERK",
      },
      {
        CITY: "BAGN",
      },
      {
        CITY: "BALESTRAND",
      },
      {
        CITY: "BALLANGEN",
      },
      {
        CITY: "BALLSTAD",
      },
      {
        CITY: "BANGSUND",
      },
      {
        CITY: "BARKAKER",
      },
      {
        CITY: "BARSTADVIK",
      },
      {
        CITY: "BATNFJORDSORA",
      },
      {
        CITY: "BATSTO",
      },
      {
        CITY: "BEISFJORD",
      },
      {
        CITY: "BEITOSTOLEN",
      },
      {
        CITY: "BEKKJARVIK",
      },
      {
        CITY: "BERGE",
      },
      {
        CITY: "BERGE",
      },
      {
        CITY: "BERGEN",
      },
      {
        CITY: "BERGER",
      },
      {
        CITY: "BERKAK",
      },
      {
        CITY: "BIRKELAND",
      },
      {
        CITY: "BIRTAVARRE",
      },
      {
        CITY: "BJALAND",
      },
      {
        CITY: "BJERKA",
      },
      {
        CITY: "BJERKVIK",
      },
      {
        CITY: "BJONEROA",
      },
      {
        CITY: "BJORDAL",
      },
      {
        CITY: "BJORKE",
      },
      {
        CITY: "BJORKELANGEN",
      },
      {
        CITY: "BJORNEVATN",
      },
      {
        CITY: "BLAKER",
      },
      {
        CITY: "BLAKSET",
      },
      {
        CITY: "BLEIKVASSLIA",
      },
      {
        CITY: "BO",
      },
      {
        CITY: "BO",
      },
      {
        CITY: "BO",
      },
      {
        CITY: "BOMLO",
      },
      {
        CITY: "BONES",
      },
      {
        CITY: "BORGE",
      },
      {
        CITY: "BORGEN",
      },
      {
        CITY: "BORHAUG",
      },
      {
        CITY: "BORKENES",
      },
      {
        CITY: "BORREGARD",
      },
      {
        CITY: "BOSTAD",
      },
      {
        CITY: "BOVAGEN",
      },
      {
        CITY: "BOVERFJORDEN",
      },
      {
        CITY: "BRANDBU",
      },
      {
        CITY: "BRANDVAL",
      },
      {
        CITY: "BRATTHOLMEN",
      },
      {
        CITY: "BRATTVAG",
      },
      {
        CITY: "BREKKE",
      },
      {
        CITY: "BREKSTAD",
      },
      {
        CITY: "BRENNASEN",
      },
      {
        CITY: "BREVIK",
      },
      {
        CITY: "BRONNOYSUND",
      },
      {
        CITY: "BRU",
      },
      {
        CITY: "BRUFLAT",
      },
      {
        CITY: "BRUMUNDDAL",
      },
      {
        CITY: "BRUSAND",
      },
      {
        CITY: "BRUVIK",
      },
      {
        CITY: "BRYNE",
      },
      {
        CITY: "BUD",
      },
      {
        CITY: "BURFJORD",
      },
      {
        CITY: "BUSKERUD",
      },
      {
        CITY: "BUVIKA",
      },
      {
        CITY: "BYGLANDSFJORD",
      },
      {
        CITY: "BYGSTAD",
      },
      {
        CITY: "BYKLE",
      },
      {
        CITY: "BYRKNES NORDRE",
      },
      {
        CITY: "CAVKKUS",
      },
      {
        CITY: "DAL",
      },
      {
        CITY: "DALE",
      },
      {
        CITY: "DALEN",
      },
      {
        CITY: "DAVIK",
      },
      {
        CITY: "DEKNEPOLLEN",
      },
      {
        CITY: "DIGERMULEN",
      },
      {
        CITY: "DILLING",
      },
      {
        CITY: "DIMMELSVIK",
      },
      {
        CITY: "DIRDAL",
      },
      {
        CITY: "DISENA",
      },
      {
        CITY: "DOKKA",
      },
      {
        CITY: "DOLEMO",
      },
      {
        CITY: "DOVRE",
      },
      {
        CITY: "DRAG",
      },
      {
        CITY: "DRAMMEN",
      },
      {
        CITY: "DRANGEDAL",
      },
      {
        CITY: "DROBAK",
      },
      {
        CITY: "DVERBERG",
      },
      {
        CITY: "DYRVIKA",
      },
      {
        CITY: "EBRU",
      },
      {
        CITY: "EGERSUND",
      },
      {
        CITY: "EGGEDAL",
      },
      {
        CITY: "EGGKLEIVA",
      },
      {
        CITY: "EIDE",
      },
      {
        CITY: "EIDE",
      },
      {
        CITY: "EIDFJORD",
      },
      {
        CITY: "EIDSA",
      },
      {
        CITY: "EIDSBERG",
      },
      {
        CITY: "EIDSDAL",
      },
      {
        CITY: "EIDSFOSS",
      },
      {
        CITY: "EIDSNES",
      },
      {
        CITY: "EIDSVAG",
      },
      {
        CITY: "EIDSVAG",
      },
      {
        CITY: "EIDSVOLL",
      },
      {
        CITY: "EIDSVOLL VERK",
      },
      {
        CITY: "EIKANGER",
      },
      {
        CITY: "EIKELANDSOSEN",
      },
      {
        CITY: "EIKEN",
      },
      {
        CITY: "EINA",
      },
      {
        CITY: "EIVINDVIK",
      },
      {
        CITY: "ELVERUM",
      },
      {
        CITY: "ENEBAKKNESET",
      },
      {
        CITY: "ENGA",
      },
      {
        CITY: "ENGALSVIK",
      },
      {
        CITY: "ERDAL",
      },
      {
        CITY: "ERFJORD",
      },
      {
        CITY: "ERVIK",
      },
      {
        CITY: "ESPELAND",
      },
      {
        CITY: "ETNE",
      },
      {
        CITY: "EVANGER",
      },
      {
        CITY: "EVENSKJER",
      },
      {
        CITY: "EVJE",
      },
      {
        CITY: "EYDEHAVN",
      },
      {
        CITY: "FABERG",
      },
      {
        CITY: "FAERVIK",
      },
      {
        CITY: "FAGERNES",
      },
      {
        CITY: "FAGERSTRAND",
      },
      {
        CITY: "FALL",
      },
      {
        CITY: "FARDAL",
      },
      {
        CITY: "FARSUND",
      },
      {
        CITY: "FAUSKE",
      },
      {
        CITY: "FEDA",
      },
      {
        CITY: "FEDJE",
      },
      {
        CITY: "FEIRING",
      },
      {
        CITY: "FELLE",
      },
      {
        CITY: "FENSTAD",
      },
      {
        CITY: "FETSUND",
      },
      {
        CITY: "FEVIK",
      },
      {
        CITY: "FIGGJO",
      },
      {
        CITY: "FINNOY",
      },
      {
        CITY: "FINNSNES",
      },
      {
        CITY: "FINSAND",
      },
      {
        CITY: "FISKA",
      },
      {
        CITY: "FISKUM",
      },
      {
        CITY: "FISTER",
      },
      {
        CITY: "FITJAR",
      },
      {
        CITY: "FJELLSTRAND",
      },
      {
        CITY: "FLA",
      },
      {
        CITY: "FLAM",
      },
      {
        CITY: "FLATEBY",
      },
      {
        CITY: "FLEKKE",
      },
      {
        CITY: "FLEKKEFJORD",
      },
      {
        CITY: "FLEMMA",
      },
      {
        CITY: "FLESBERG",
      },
      {
        CITY: "FLESNES",
      },
      {
        CITY: "FLORO",
      },
      {
        CITY: "FLORVAG",
      },
      {
        CITY: "FOLDEREID",
      },
      {
        CITY: "FOLDEROY",
      },
      {
        CITY: "FOLKESTAD",
      },
      {
        CITY: "FOLLAFOSS",
      },
      {
        CITY: "FOLLEBU",
      },
      {
        CITY: "FOLLESE",
      },
      {
        CITY: "FONNES",
      },
      {
        CITY: "FORDE",
      },
      {
        CITY: "FORDE",
      },
      {
        CITY: "FORNEBU",
      },
      {
        CITY: "FOSNAVAG",
      },
      {
        CITY: "FOSSDALEN",
      },
      {
        CITY: "FOSSER",
      },
      {
        CITY: "FOTLANDSVAG",
      },
      {
        CITY: "FREDRIKSTAD",
      },
      {
        CITY: "FREKHAUG",
      },
      {
        CITY: "FRESVIK",
      },
      {
        CITY: "FROGNER",
      },
      {
        CITY: "FROLAND",
      },
      {
        CITY: "FROM",
      },
      {
        CITY: "FURNES",
      },
      {
        CITY: "FYRDE",
      },
      {
        CITY: "FYRESDAL",
      },
      {
        CITY: "GAN",
      },
      {
        CITY: "GARDERMOEN",
      },
      {
        CITY: "GARGAN",
      },
      {
        CITY: "GARNES",
      },
      {
        CITY: "GASBAKKEN",
      },
      {
        CITY: "GAUPEN",
      },
      {
        CITY: "GEILO",
      },
      {
        CITY: "GEITHUS",
      },
      {
        CITY: "GJERDRUM",
      },
      {
        CITY: "GJERSTAD",
      },
      {
        CITY: "GJOLME",
      },
      {
        CITY: "GLESVAER",
      },
      {
        CITY: "GLOMFJORD",
      },
      {
        CITY: "GODOY",
      },
      {
        CITY: "GODVIK",
      },
      {
        CITY: "GOL",
      },
      {
        CITY: "GRAN",
      },
      {
        CITY: "GRANSHERAD",
      },
      {
        CITY: "GRANVIN",
      },
      {
        CITY: "GRATANGEN",
      },
      {
        CITY: "GRAVDAL",
      },
      {
        CITY: "GREAKER",
      },
      {
        CITY: "GRENDI",
      },
      {
        CITY: "GRESSVIK",
      },
      {
        CITY: "GRIMSTAD",
      },
      {
        CITY: "GROA",
      },
      {
        CITY: "GRONG",
      },
      {
        CITY: "GRUA",
      },
      {
        CITY: "GULLAUG",
      },
      {
        CITY: "GVARV",
      },
      {
        CITY: "HADDAL",
      },
      {
        CITY: "HAEGELAND",
      },
      {
        CITY: "HAERLAND",
      },
      {
        CITY: "HAGAN",
      },
      {
        CITY: "HAGAVIK",
      },
      {
        CITY: "HAKADAL",
      },
      {
        CITY: "HALDEN",
      },
      {
        CITY: "HALLINGBY",
      },
      {
        CITY: "HALSA",
      },
      {
        CITY: "HALTDALEN",
      },
      {
        CITY: "HAMAR",
      },
      {
        CITY: "HAMARVIK",
      },
      {
        CITY: "HAMMERFEST",
      },
      {
        CITY: "HANSNES",
      },
      {
        CITY: "HARAM",
      },
      {
        CITY: "HAREID",
      },
      {
        CITY: "HARSTAD",
      },
      {
        CITY: "HASLUM",
      },
      {
        CITY: "HASVIK",
      },
      {
        CITY: "HATLESTRANDA",
      },
      {
        CITY: "HAUGE",
      },
      {
        CITY: "HAUGESUND",
      },
      {
        CITY: "HAUKELAND",
      },
      {
        CITY: "HAVIK",
      },
      {
        CITY: "HAVIK",
      },
      {
        CITY: "HEBNES",
      },
      {
        CITY: "HEDAL",
      },
      {
        CITY: "HEGGEDAL",
      },
      {
        CITY: "HEGGENES",
      },
      {
        CITY: "HEGRA",
      },
      {
        CITY: "HEIMDAL",
      },
      {
        CITY: "HELGELAND",
      },
      {
        CITY: "HELGEROA",
      },
      {
        CITY: "HELL",
      },
      {
        CITY: "HELLANDSJOEN",
      },
      {
        CITY: "HELLELAND",
      },
      {
        CITY: "HELLESYLT",
      },
      {
        CITY: "HELLVIK",
      },
      {
        CITY: "HEMNES",
      },
      {
        CITY: "HEMNESBERGET",
      },
      {
        CITY: "HEMNSKJELA",
      },
      {
        CITY: "HEMSEDAL",
      },
      {
        CITY: "HENNINGSVAER",
      },
      {
        CITY: "HERAND",
      },
      {
        CITY: "HEROYSUND",
      },
      {
        CITY: "HERRE",
      },
      {
        CITY: "HERSAETER",
      },
      {
        CITY: "HESTVIKA",
      },
      {
        CITY: "HETLEVIK",
      },
      {
        CITY: "HILDRE",
      },
      {
        CITY: "HITRA",
      },
      {
        CITY: "HJELLESTAD",
      },
      {
        CITY: "HJELMAS",
      },
      {
        CITY: "HJELSET",
      },
      {
        CITY: "HJORUNGAVAG",
      },
      {
        CITY: "HOF",
      },
      {
        CITY: "HOKKASEN",
      },
      {
        CITY: "HOKKSUND",
      },
      {
        CITY: "HOL",
      },
      {
        CITY: "HOLE",
      },
      {
        CITY: "HOLEN",
      },
      {
        CITY: "HOLMEFJORD",
      },
      {
        CITY: "HOLMEN",
      },
      {
        CITY: "HOLMENKOLLEN",
      },
      {
        CITY: "HOLMESTRAND",
      },
      {
        CITY: "HOLSEN",
      },
      {
        CITY: "HOLTER",
      },
      {
        CITY: "HOMMELVIK",
      },
      {
        CITY: "HOMMERSAK",
      },
      {
        CITY: "HONEFOSS",
      },
      {
        CITY: "HORDVIK",
      },
      {
        CITY: "HORNNES",
      },
      {
        CITY: "HORTE",
      },
      {
        CITY: "HORTEN",
      },
      {
        CITY: "HOV",
      },
      {
        CITY: "HOVAG",
      },
      {
        CITY: "HOVDEN",
      },
      {
        CITY: "HOVET",
      },
      {
        CITY: "HOVIK VERK",
      },
      {
        CITY: "HOVIN",
      },
      {
        CITY: "HOYANGER",
      },
      {
        CITY: "HUNDVEN",
      },
      {
        CITY: "HUNNDALEN",
      },
      {
        CITY: "HUSOY",
      },
      {
        CITY: "HUSTAD",
      },
      {
        CITY: "HVALSTAD",
      },
      {
        CITY: "HVAM",
      },
      {
        CITY: "HVITSTEN",
      },
      {
        CITY: "HVITTINGFOSS",
      },
      {
        CITY: "HYGGEN",
      },
      {
        CITY: "HYLKJE",
      },
      {
        CITY: "HYLLESTAD",
      },
      {
        CITY: "IKORNNES",
      },
      {
        CITY: "INDRE ARNA",
      },
      {
        CITY: "INDRE BILLEFJORD",
      },
      {
        CITY: "INDRE KLUBBEN",
      },
      {
        CITY: "INDRE ULVSVAG",
      },
      {
        CITY: "INDREBY",
      },
      {
        CITY: "INNBYGDA",
      },
      {
        CITY: "INNDYR",
      },
      {
        CITY: "INNVIK",
      },
      {
        CITY: "ISDALSTO",
      },
      {
        CITY: "ISE",
      },
      {
        CITY: "IVGOBAHTA",
      },
      {
        CITY: "JAKOBSELV",
      },
      {
        CITY: "JAR",
      },
      {
        CITY: "JAREN",
      },
      {
        CITY: "JESSHEIM",
      },
      {
        CITY: "JEVNAKER",
      },
      {
        CITY: "JOMNA",
      },
      {
        CITY: "JORPELAND",
      },
      {
        CITY: "KABELVAG",
      },
      {
        CITY: "KALDFARNES",
      },
      {
        CITY: "KALVAG",
      },
      {
        CITY: "KAMBEN",
      },
      {
        CITY: "KARASJOK",
      },
      {
        CITY: "KARLSHUS",
      },
      {
        CITY: "KARLSHUS",
      },
      {
        CITY: "KAUPANGER",
      },
      {
        CITY: "KAUTOKEINO",
      },
      {
        CITY: "KIRKENAER",
      },
      {
        CITY: "KIRKENES",
      },
      {
        CITY: "KJELLER",
      },
      {
        CITY: "KJELLMYRA",
      },
      {
        CITY: "KJERSTAD",
      },
      {
        CITY: "KJOLLEFJORD",
      },
      {
        CITY: "KJOPSVIK",
      },
      {
        CITY: "KLEIVE",
      },
      {
        CITY: "KLEPP",
      },
      {
        CITY: "KLEPPE",
      },
      {
        CITY: "KLEPPESTO",
      },
      {
        CITY: "KLEPPSTAD",
      },
      {
        CITY: "KLOFTA",
      },
      {
        CITY: "KLOKKARVIK",
      },
      {
        CITY: "KNAPPER",
      },
      {
        CITY: "KNAPPSTAD",
      },
      {
        CITY: "KNARREVIK",
      },
      {
        CITY: "KNARRLAGET",
      },
      {
        CITY: "KOLBJORNSVIK",
      },
      {
        CITY: "KOLBOTN",
      },
      {
        CITY: "KOLBU",
      },
      {
        CITY: "KOLLTVEIT",
      },
      {
        CITY: "KOLNES",
      },
      {
        CITY: "KOLSAS",
      },
      {
        CITY: "KOLVEREID",
      },
      {
        CITY: "KONGSBERG",
      },
      {
        CITY: "KONGSHAMN",
      },
      {
        CITY: "KONGSVIKA",
      },
      {
        CITY: "KONGSVINGER",
      },
      {
        CITY: "KONSMO",
      },
      {
        CITY: "KONSVIKOSEN",
      },
      {
        CITY: "KOPERVIK",
      },
      {
        CITY: "KOPPANG",
      },
      {
        CITY: "KORGEN",
      },
      {
        CITY: "KORNSJO",
      },
      {
        CITY: "KORSVEGEN",
      },
      {
        CITY: "KRAGERO",
      },
      {
        CITY: "KRAKEROY",
      },
      {
        CITY: "KRAKSTAD",
      },
      {
        CITY: "KRISTIANSAND",
      },
      {
        CITY: "KRISTIANSUND",
      },
      {
        CITY: "KRODEREN",
      },
      {
        CITY: "KROKSTADELVA",
      },
      {
        CITY: "KVAL",
      },
      {
        CITY: "KVALSUND",
      },
      {
        CITY: "KVAM",
      },
      {
        CITY: "KVAMMEN",
      },
      {
        CITY: "KVANNE",
      },
      {
        CITY: "KVELDE",
      },
      {
        CITY: "KVINESDAL",
      },
      {
        CITY: "KVINLOG",
      },
      {
        CITY: "KVISVIK",
      },
      {
        CITY: "KVITESEID",
      },
      {
        CITY: "KYRKJEBO",
      },
      {
        CITY: "KYRKSAETERORA",
      },
      {
        CITY: "LAKSELV",
      },
      {
        CITY: "LAKSEVAG",
      },
      {
        CITY: "LAKSVATN",
      },
      {
        CITY: "LALM",
      },
      {
        CITY: "LAND",
      },
      {
        CITY: "LANGANGEN",
      },
      {
        CITY: "LANGESUND",
      },
      {
        CITY: "LANGEVAG",
      },
      {
        CITY: "LANGFJORDBOTN",
      },
      {
        CITY: "LANGHUS",
      },
      {
        CITY: "LARKOLLEN",
      },
      {
        CITY: "LARVIK",
      },
      {
        CITY: "LAUKVIK",
      },
      {
        CITY: "LAUVSNES",
      },
      {
        CITY: "LAUVSTAD",
      },
      {
        CITY: "LEIKANG",
      },
      {
        CITY: "LEINES",
      },
      {
        CITY: "LEIRA",
      },
      {
        CITY: "LEIRFJORD",
      },
      {
        CITY: "LEIRSUND",
      },
      {
        CITY: "LEIRVIK",
      },
      {
        CITY: "LEKNES",
      },
      {
        CITY: "LENA",
      },
      {
        CITY: "LENSVIK",
      },
      {
        CITY: "LENVIK",
      },
      {
        CITY: "LEPSOY",
      },
      {
        CITY: "LEVANGER",
      },
      {
        CITY: "LIDALADDI",
      },
      {
        CITY: "LIER",
      },
      {
        CITY: "LILLEHAMMER",
      },
      {
        CITY: "LILLESAND",
      },
      {
        CITY: "LINDAS",
      },
      {
        CITY: "LODDEFJORD",
      },
      {
        CITY: "LODINGEN",
      },
      {
        CITY: "LOEN",
      },
      {
        CITY: "LOFTHUS",
      },
      {
        CITY: "LOKEN",
      },
      {
        CITY: "LOKKEN VERK",
      },
      {
        CITY: "LOM",
      },
      {
        CITY: "LONEVAG",
      },
      {
        CITY: "LONGVA",
      },
      {
        CITY: "LORENFALLET",
      },
      {
        CITY: "LOTEN",
      },
      {
        CITY: "LOVUND",
      },
      {
        CITY: "LUNDAMO",
      },
      {
        CITY: "LUNDE",
      },
      {
        CITY: "LUNNER",
      },
      {
        CITY: "LYNGDAL",
      },
      {
        CITY: "LYNGSEIDET",
      },
      {
        CITY: "LYNGSTAD",
      },
      {
        CITY: "LYSAKER",
      },
      {
        CITY: "LYSOYSUNDET",
      },
      {
        CITY: "MAGNOR",
      },
      {
        CITY: "MALM",
      },
      {
        CITY: "MALOY",
      },
      {
        CITY: "MALVIK",
      },
      {
        CITY: "MANDAL",
      },
      {
        CITY: "MANGER",
      },
      {
        CITY: "MANNDALEN",
      },
      {
        CITY: "MARHEIM",
      },
      {
        CITY: "MASFJORDEN",
      },
      {
        CITY: "MATHOPEN",
      },
      {
        CITY: "MAURA",
      },
      {
        CITY: "MEHAMN",
      },
      {
        CITY: "MEISINGSET",
      },
      {
        CITY: "MELBU",
      },
      {
        CITY: "MELDAL",
      },
      {
        CITY: "MELHUS",
      },
      {
        CITY: "MELSOMVIK",
      },
      {
        CITY: "MERAKER",
      },
      {
        CITY: "MESTERVIK",
      },
      {
        CITY: "MIDSUND",
      },
      {
        CITY: "MILAND",
      },
      {
        CITY: "MINNESUND",
      },
      {
        CITY: "MIRZA RAFI SAUDA",
      },
      {
        CITY: "MISJE",
      },
      {
        CITY: "MISVAER",
      },
      {
        CITY: "MJOLKERAEN",
      },
      {
        CITY: "MJONDALEN",
      },
      {
        CITY: "MO",
      },
      {
        CITY: "MO I RANA",
      },
      {
        CITY: "MODALEN",
      },
      {
        CITY: "MOELV",
      },
      {
        CITY: "MOEN",
      },
      {
        CITY: "MOEN",
      },
      {
        CITY: "MOI",
      },
      {
        CITY: "MOLDE",
      },
      {
        CITY: "MOLDJORD",
      },
      {
        CITY: "MORGEDAL",
      },
      {
        CITY: "MOSBY",
      },
      {
        CITY: "MOSJOEN",
      },
      {
        CITY: "MOSS",
      },
      {
        CITY: "MOVIK",
      },
      {
        CITY: "MYKING",
      },
      {
        CITY: "MYRE",
      },
      {
        CITY: "MYRE",
      },
      {
        CITY: "MYSEN",
      },
      {
        CITY: "NA",
      },
      {
        CITY: "NAERBO",
      },
      {
        CITY: "NAERSNES",
      },
      {
        CITY: "NAMSOS",
      },
      {
        CITY: "NAMSSKOGAN",
      },
      {
        CITY: "NARVIK",
      },
      {
        CITY: "NAUSTDAL",
      },
      {
        CITY: "NEDENES",
      },
      {
        CITY: "NEDRE FREI",
      },
      {
        CITY: "NESBRU",
      },
      {
        CITY: "NESBYEN",
      },
      {
        CITY: "NESGRENDA",
      },
      {
        CITY: "NESNA",
      },
      {
        CITY: "NESODDTANGEN",
      },
      {
        CITY: "NESTTUN",
      },
      {
        CITY: "NEVERDAL",
      },
      {
        CITY: "NEVLUNGHAMN",
      },
      {
        CITY: "NODELAND",
      },
      {
        CITY: "NORDBY BRUK",
      },
      {
        CITY: "NORDFJORDEID",
      },
      {
        CITY: "NORDKISA",
      },
      {
        CITY: "NORDLAND",
      },
      {
        CITY: "NORDSTRONO",
      },
      {
        CITY: "NORESUND",
      },
      {
        CITY: "NORHEIMSUND",
      },
      {
        CITY: "NOTODDEN",
      },
      {
        CITY: "NYBERGSUND",
      },
      {
        CITY: "NYBORG",
      },
      {
        CITY: "NYDALEN",
      },
      {
        CITY: "NYGARDSJOEN",
      },
      {
        CITY: "NYHUS",
      },
      {
        CITY: "NYKIRKE",
      },
      {
        CITY: "ODDA",
      },
      {
        CITY: "ODNES",
      },
      {
        CITY: "OKSFJORD",
      },
      {
        CITY: "OKSVOLL",
      },
      {
        CITY: "OLDEN",
      },
      {
        CITY: "OLDERDALEN",
      },
      {
        CITY: "OLEN",
      },
      {
        CITY: "OLTEDAL",
      },
      {
        CITY: "OMA",
      },
      {
        CITY: "ONARHEIM",
      },
      {
        CITY: "OPPDAL",
      },
      {
        CITY: "OPPEGARD",
      },
      {
        CITY: "OPPHAUG",
      },
      {
        CITY: "ORESVIKA",
      },
      {
        CITY: "ORJE",
      },
      {
        CITY: "ORKANGER",
      },
      {
        CITY: "ORNES",
      },
      {
        CITY: "ORRE",
      },
      {
        CITY: "OS",
      },
      {
        CITY: "OS",
      },
      {
        CITY: "OSLO",
      },
      {
        CITY: "OTTA",
      },
      {
        CITY: "OTTEROY",
      },
      {
        CITY: "OTTESTAD",
      },
      {
        CITY: "OVELAND",
      },
      {
        CITY: "OVRE ARDAL",
      },
      {
        CITY: "OVREBO",
      },
      {
        CITY: "OYEREN",
      },
      {
        CITY: "OYSTESE",
      },
      {
        CITY: "PORSGRUNN",
      },
      {
        CITY: "PRESTFOSS",
      },
      {
        CITY: "RAHOLT",
      },
      {
        CITY: "RAKKESTAD",
      },
      {
        CITY: "RAMBERG",
      },
      {
        CITY: "RAMFJORDBOTN",
      },
      {
        CITY: "RAMNES",
      },
      {
        CITY: "RANA",
      },
      {
        CITY: "RANASFOSS",
      },
      {
        CITY: "RANDABERG",
      },
      {
        CITY: "RANHEIM",
      },
      {
        CITY: "RAUDEBERG",
      },
      {
        CITY: "RAUDSAND",
      },
      {
        CITY: "RAUFOSS",
      },
      {
        CITY: "RAULAND",
      },
      {
        CITY: "RE",
      },
      {
        CITY: "RE",
      },
      {
        CITY: "REINE",
      },
      {
        CITY: "REINSVOLL",
      },
      {
        CITY: "REIPA",
      },
      {
        CITY: "REISTAD",
      },
      {
        CITY: "REITAN",
      },
      {
        CITY: "RENA",
      },
      {
        CITY: "RENNEBU",
      },
      {
        CITY: "RINDAL",
      },
      {
        CITY: "RINGEBU",
      },
      {
        CITY: "RINGSAKER",
      },
      {
        CITY: "RINGSTAD",
      },
      {
        CITY: "RISOYHAMN",
      },
      {
        CITY: "RJUKAN",
      },
      {
        CITY: "ROA",
      },
      {
        CITY: "RODBERG",
      },
      {
        CITY: "RODOY",
      },
      {
        CITY: "ROGNAN",
      },
      {
        CITY: "ROGNE",
      },
      {
        CITY: "ROKLAND",
      },
      {
        CITY: "ROLDAL",
      },
      {
        CITY: "ROLLAG",
      },
      {
        CITY: "ROLVSOY",
      },
      {
        CITY: "ROMEDAL",
      },
      {
        CITY: "RONG",
      },
      {
        CITY: "ROROS",
      },
      {
        CITY: "RORVIK",
      },
      {
        CITY: "ROSENDAL",
      },
      {
        CITY: "ROSSLAND",
      },
      {
        CITY: "ROST",
      },
      {
        CITY: "ROVDE",
      },
      {
        CITY: "ROVERUD",
      },
      {
        CITY: "ROYKEN",
      },
      {
        CITY: "ROYNEBERG",
      },
      {
        CITY: "RUBBESTADNESET",
      },
      {
        CITY: "RUD",
      },
      {
        CITY: "RYGGE",
      },
      {
        CITY: "RYKENE",
      },
      {
        CITY: "RYPEFJORD",
      },
      {
        CITY: "SAEBO",
      },
      {
        CITY: "SAEBOVIK",
      },
      {
        CITY: "SAETRE",
      },
      {
        CITY: "SAEVAREID",
      },
      {
        CITY: "SAEVELAND",
      },
      {
        CITY: "SAGVAG",
      },
      {
        CITY: "SALHUS",
      },
      {
        CITY: "SALSBRUKET",
      },
      {
        CITY: "SALSNES",
      },
      {
        CITY: "SALTNES",
      },
      {
        CITY: "SAMUELSBERG",
      },
      {
        CITY: "SAND",
      },
      {
        CITY: "SAND",
      },
      {
        CITY: "SANDANE",
      },
      {
        CITY: "SANDE",
      },
      {
        CITY: "SANDE",
      },
      {
        CITY: "SANDEFJORD",
      },
      {
        CITY: "SANDEID",
      },
      {
        CITY: "SANDER",
      },
      {
        CITY: "SANDNES",
      },
      {
        CITY: "SANDNES",
      },
      {
        CITY: "SANDNESSJOEN",
      },
      {
        CITY: "SANDSHAMN",
      },
      {
        CITY: "SANDSTAD",
      },
      {
        CITY: "SANDTORG",
      },
      {
        CITY: "SANDVIKA",
      },
      {
        CITY: "SANDVOLL",
      },
      {
        CITY: "SANNIDAL",
      },
      {
        CITY: "SARPSBORG",
      },
      {
        CITY: "SAUPSTAD",
      },
      {
        CITY: "SELASVATN",
      },
      {
        CITY: "SELJE",
      },
      {
        CITY: "SELJORD",
      },
      {
        CITY: "SELLEBAKK",
      },
      {
        CITY: "SELVA",
      },
      {
        CITY: "SELVAER",
      },
      {
        CITY: "SEM",
      },
      {
        CITY: "SETERMOEN",
      },
      {
        CITY: "SIGGERUD",
      },
      {
        CITY: "SILJAN",
      },
      {
        CITY: "SILSAND",
      },
      {
        CITY: "SINGSAS",
      },
      {
        CITY: "SIRA",
      },
      {
        CITY: "SIREVAG",
      },
      {
        CITY: "SISTRANDA",
      },
      {
        CITY: "SJOVEGAN",
      },
      {
        CITY: "SKABU",
      },
      {
        CITY: "SKAGE",
      },
      {
        CITY: "SKANEVIK",
      },
      {
        CITY: "SKARER",
      },
      {
        CITY: "SKARNES",
      },
      {
        CITY: "SKATOY",
      },
      {
        CITY: "SKAUN",
      },
      {
        CITY: "SKEDSMOKORSET",
      },
      {
        CITY: "SKEIE",
      },
      {
        CITY: "SKI",
      },
      {
        CITY: "SKIEN",
      },
      {
        CITY: "SKJEBERG",
      },
      {
        CITY: "SKJERSTAD",
      },
      {
        CITY: "SKJERVOY",
      },
      {
        CITY: "SKJOLD",
      },
      {
        CITY: "SKJOLDASTRAUMEN",
      },
      {
        CITY: "SKJOLDEN",
      },
      {
        CITY: "SKODJE",
      },
      {
        CITY: "SKOGN",
      },
      {
        CITY: "SKOGN",
      },
      {
        CITY: "SKOPPUM",
      },
      {
        CITY: "SKOTBU",
      },
      {
        CITY: "SKOTTERUD",
      },
      {
        CITY: "SKREIA",
      },
      {
        CITY: "SKUDENESHAVN",
      },
      {
        CITY: "SKULSFJORD",
      },
      {
        CITY: "SKUTVIKA",
      },
      {
        CITY: "SLASTAD",
      },
      {
        CITY: "SLATTUM",
      },
      {
        CITY: "SLEMDAL",
      },
      {
        CITY: "SLEMMESTAD",
      },
      {
        CITY: "SLETTA",
      },
      {
        CITY: "SNAASE",
      },
      {
        CITY: "SNILLFJORD",
      },
      {
        CITY: "SOGN",
      },
      {
        CITY: "SOKNA",
      },
      {
        CITY: "SOKNDAL",
      },
      {
        CITY: "SOKNEDAL",
      },
      {
        CITY: "SOLA",
      },
      {
        CITY: "SOLBERGELVA",
      },
      {
        CITY: "SOLVORN",
      },
      {
        CITY: "SOMMAROY",
      },
      {
        CITY: "SOMNA",
      },
      {
        CITY: "SON",
      },
      {
        CITY: "SONDELED",
      },
      {
        CITY: "SOR-FRON",
      },
      {
        CITY: "SORBO",
      },
      {
        CITY: "SOREIDGRENDA",
      },
      {
        CITY: "SORLI",
      },
      {
        CITY: "SORTLAND",
      },
      {
        CITY: "SORUM",
      },
      {
        CITY: "SORUMSAND",
      },
      {
        CITY: "SORVAER",
      },
      {
        CITY: "SORVAGEN",
      },
      {
        CITY: "SORVIK",
      },
      {
        CITY: "SPANGEREID",
      },
      {
        CITY: "SPARBU",
      },
      {
        CITY: "SPERREBOTN",
      },
      {
        CITY: "SPILLUM",
      },
      {
        CITY: "SPYDEBERG",
      },
      {
        CITY: "STABBESTAD",
      },
      {
        CITY: "STABEKK",
      },
      {
        CITY: "STAMNES",
      },
      {
        CITY: "STAMSUND",
      },
      {
        CITY: "STANGE",
      },
      {
        CITY: "STATHELLE",
      },
      {
        CITY: "STAUBO",
      },
      {
        CITY: "STAVANGER",
      },
      {
        CITY: "STAVERN",
      },
      {
        CITY: "STAVERN",
      },
      {
        CITY: "STEIGEN",
      },
      {
        CITY: "STEINBERG",
      },
      {
        CITY: "STEINKJER",
      },
      {
        CITY: "STEINSDALEN",
      },
      {
        CITY: "STO",
      },
      {
        CITY: "STOKKE",
      },
      {
        CITY: "STOKMARKNES",
      },
      {
        CITY: "STOL",
      },
      {
        CITY: "STORAS",
      },
      {
        CITY: "STORDAL",
      },
      {
        CITY: "STOREBO",
      },
      {
        CITY: "STORFORSHEI",
      },
      {
        CITY: "STORSLETT",
      },
      {
        CITY: "STORSTEINNES",
      },
      {
        CITY: "STRANDA",
      },
      {
        CITY: "STRAUME",
      },
      {
        CITY: "STRAUMEN",
      },
      {
        CITY: "STROMMEN",
      },
      {
        CITY: "STRONSTAD",
      },
      {
        CITY: "STRUSSHAMN",
      },
      {
        CITY: "STRYN",
      },
      {
        CITY: "SULDALSOSEN",
      },
      {
        CITY: "SULISJIELMMA",
      },
      {
        CITY: "SUND",
      },
      {
        CITY: "SUNDAL",
      },
      {
        CITY: "SUNDE",
      },
      {
        CITY: "SUNNDALSORA",
      },
      {
        CITY: "SURNADALSORA",
      },
      {
        CITY: "SVARSTAD",
      },
      {
        CITY: "SVARTSKOG",
      },
      {
        CITY: "SVEIO",
      },
      {
        CITY: "SVELGEN",
      },
      {
        CITY: "SVELVIK",
      },
      {
        CITY: "SVENE",
      },
      {
        CITY: "SVORTLAND",
      },
      {
        CITY: "SYLLING",
      },
      {
        CITY: "SYVIK",
      },
      {
        CITY: "TAFJORD",
      },
      {
        CITY: "TALVIK",
      },
      {
        CITY: "TANANGER",
      },
      {
        CITY: "TANEM",
      },
      {
        CITY: "TANGEN",
      },
      {
        CITY: "TAU",
      },
      {
        CITY: "TENNEVOLL",
      },
      {
        CITY: "TENNFJORD",
      },
      {
        CITY: "TERTNES",
      },
      {
        CITY: "TILLER",
      },
      {
        CITY: "TINGVOLL",
      },
      {
        CITY: "TISTEDAL",
      },
      {
        CITY: "TJELDSTO",
      },
      {
        CITY: "TJELTA",
      },
      {
        CITY: "TJONG",
      },
      {
        CITY: "TJORVAG",
      },
      {
        CITY: "TJOTTA",
      },
      {
        CITY: "TOFTE",
      },
      {
        CITY: "TOLGA",
      },
      {
        CITY: "TOMASJORDA",
      },
      {
        CITY: "TOMTER",
      },
      {
        CITY: "TONSTAD",
      },
      {
        CITY: "TORNES",
      },
      {
        CITY: "TOROD",
      },
      {
        CITY: "TORP",
      },
      {
        CITY: "TORPO",
      },
      {
        CITY: "TOVIK",
      },
      {
        CITY: "TRANA",
      },
      {
        CITY: "TRANBY",
      },
      {
        CITY: "TRENGEREID",
      },
      {
        CITY: "TRETTEN",
      },
      {
        CITY: "TREUNGEN",
      },
      {
        CITY: "TROFORS",
      },
      {
        CITY: "TROLLFJORDEN",
      },
      {
        CITY: "TROMSDALEN",
      },
      {
        CITY: "TRONDHEIM",
      },
      {
        CITY: "TRONES",
      },
      {
        CITY: "TUROY",
      },
      {
        CITY: "TVEDESTRAND",
      },
      {
        CITY: "TVEIT",
      },
      {
        CITY: "TYNSET",
      },
      {
        CITY: "TYRISTRAND",
      },
      {
        CITY: "TYSNES",
      },
      {
        CITY: "TYSSE",
      },
      {
        CITY: "TYSSEDAL",
      },
      {
        CITY: "UGGDAL",
      },
      {
        CITY: "ULEFOSS",
      },
      {
        CITY: "ULSTEIN",
      },
      {
        CITY: "ULSTEINVIK",
      },
      {
        CITY: "ULVAGEN",
      },
      {
        CITY: "ULVIK",
      },
      {
        CITY: "UNDEIM",
      },
      {
        CITY: "USKEDALEN",
      },
      {
        CITY: "UTSIRA",
      },
      {
        CITY: "UTSKARPEN",
      },
      {
        CITY: "UVDAL",
      },
      {
        CITY: "VADHEIM",
      },
      {
        CITY: "VAGE",
      },
      {
        CITY: "VAGLAND",
      },
      {
        CITY: "VAKSDAL",
      },
      {
        CITY: "VALE",
      },
      {
        CITY: "VALEN",
      },
      {
        CITY: "VALER",
      },
      {
        CITY: "VALER",
      },
      {
        CITY: "VALESTRAND",
      },
      {
        CITY: "VALESTRANDFOSSEN",
      },
      {
        CITY: "VALLDAL",
      },
      {
        CITY: "VALLE",
      },
      {
        CITY: "VALLE",
      },
      {
        CITY: "VALSOYFJORD",
      },
      {
        CITY: "VANGSVIKA",
      },
      {
        CITY: "VANNVAG",
      },
      {
        CITY: "VANSE",
      },
      {
        CITY: "VARANGERBOTN",
      },
      {
        CITY: "VARHAUG",
      },
      {
        CITY: "VASSENDEN",
      },
      {
        CITY: "VATNE",
      },
      {
        CITY: "VEDAVAGEN",
      },
      {
        CITY: "VEGARSHEI",
      },
      {
        CITY: "VEGGLI",
      },
      {
        CITY: "VENABYGD",
      },
      {
        CITY: "VENNESLA",
      },
      {
        CITY: "VERDAL",
      },
      {
        CITY: "VESTBY",
      },
      {
        CITY: "VESTFOSSEN",
      },
      {
        CITY: "VESTNES",
      },
      {
        CITY: "VESTRA MOSTERHAMN",
      },
      {
        CITY: "VESTRE GAUSDAL",
      },
      {
        CITY: "VEVANG",
      },
      {
        CITY: "VEVELSTAD",
      },
      {
        CITY: "VIGRESTAD",
      },
      {
        CITY: "VIKEBYGD",
      },
      {
        CITY: "VIKEDAL",
      },
      {
        CITY: "VIKERSUND",
      },
      {
        CITY: "VIKESA",
      },
      {
        CITY: "VIKRAN",
      },
      {
        CITY: "VINGELEN",
      },
      {
        CITY: "VINJE",
      },
      {
        CITY: "VINSTRA",
      },
      {
        CITY: "VOKSA",
      },
      {
        CITY: "VOLDA",
      },
      {
        CITY: "VOLLEN",
      },
      {
        CITY: "VORMEDAL",
      },
      {
        CITY: "VORMSUND",
      },
      {
        CITY: "VOSS",
      },
      {
        CITY: "VOSSESTRAND",
      },
      {
        CITY: "VRALIOSEN",
      },
      {
        CITY: "YTRE ALVIK",
      },
    ],
  },
  {
    COUNTRY: "NEW ZEALAND",
    CODE: "NZ",
    CITIES: [
      {
        CITY: "AHAURA",
      },
      {
        CITY: "ALBANY",
      },
      {
        CITY: "AMBERLEY",
      },
      {
        CITY: "ASHHURST",
      },
      {
        CITY: "AUCKLAND",
      },
      {
        CITY: "AVONDALE",
      },
      {
        CITY: "AWANUI",
      },
      {
        CITY: "BALCLUTHA",
      },
      {
        CITY: "BALFOUR",
      },
      {
        CITY: "BEACHLANDS",
      },
      {
        CITY: "BELMONT",
      },
      {
        CITY: "BETHLEHEM",
      },
      {
        CITY: "BLACKBURN",
      },
      {
        CITY: "BOMBAY",
      },
      {
        CITY: "BRIGHTWATER",
      },
      {
        CITY: "BROWNS BAY",
      },
      {
        CITY: "BULLS",
      },
      {
        CITY: "CAMPBELLS BAY",
      },
      {
        CITY: "CASHMERE HILLS",
      },
      {
        CITY: "CAVE",
      },
      {
        CITY: "CHEVIOT",
      },
      {
        CITY: "CHRISTCHURCH",
      },
      {
        CITY: "CLARKS",
      },
      {
        CITY: "CLEVEDON",
      },
      {
        CITY: "CLINTON",
      },
      {
        CITY: "CLIVE",
      },
      {
        CITY: "CLYDE",
      },
      {
        CITY: "COALGATE",
      },
      {
        CITY: "COATESVILLE",
      },
      {
        CITY: "COLLINGWOOD",
      },
      {
        CITY: "COLVILLE",
      },
      {
        CITY: "COROMANDEL",
      },
      {
        CITY: "DARFIELD",
      },
      {
        CITY: "DARGAVILLE",
      },
      {
        CITY: "DOUGLAS",
      },
      {
        CITY: "DOYLESTON",
      },
      {
        CITY: "DRURY",
      },
      {
        CITY: "DUNEDIN",
      },
      {
        CITY: "DUVAUCHELLE",
      },
      {
        CITY: "EAST TAMAKI",
      },
      {
        CITY: "EASTBOURNE",
      },
      {
        CITY: "EDENDALE",
      },
      {
        CITY: "ELTHAM",
      },
      {
        CITY: "FAIRLIE",
      },
      {
        CITY: "FAVONA",
      },
      {
        CITY: "FOXTON",
      },
      {
        CITY: "FOXTON BEACH",
      },
      {
        CITY: "FRANZ JOSEF",
      },
      {
        CITY: "GISBORNE",
      },
      {
        CITY: "GLEN EDEN",
      },
      {
        CITY: "GLENBROOK",
      },
      {
        CITY: "GREENHITHE",
      },
      {
        CITY: "GREERTON",
      },
      {
        CITY: "GREYMOUTH",
      },
      {
        CITY: "HAAST",
      },
      {
        CITY: "HALSWELL",
      },
      {
        CITY: "HAMILTON",
      },
      {
        CITY: "HASTINGS",
      },
      {
        CITY: "HAVELOCK NORTH",
      },
      {
        CITY: "HAWARDEN",
      },
      {
        CITY: "HAWERA",
      },
      {
        CITY: "HERNE BAY",
      },
      {
        CITY: "HIKUAI",
      },
      {
        CITY: "HOKITIKA",
      },
      {
        CITY: "HOWICK",
      },
      {
        CITY: "HUNTERVILLE",
      },
      {
        CITY: "INVERCARGILL",
      },
      {
        CITY: "JOHNSONVILLE",
      },
      {
        CITY: "KAEO",
      },
      {
        CITY: "KAIATA",
      },
      {
        CITY: "KAIKOURA",
      },
      {
        CITY: "KAITANGATA",
      },
      {
        CITY: "KAIWAKA",
      },
      {
        CITY: "KAIWHARAWHARA",
      },
      {
        CITY: "KAMO",
      },
      {
        CITY: "KARAMEA",
      },
      {
        CITY: "KATIKATI",
      },
      {
        CITY: "KAWAKAWA",
      },
      {
        CITY: "KAWERAU",
      },
      {
        CITY: "KAWHIA",
      },
      {
        CITY: "KERIKERI",
      },
      {
        CITY: "KHANDALLAH",
      },
      {
        CITY: "KIMBOLTON",
      },
      {
        CITY: "KIRWEE",
      },
      {
        CITY: "KOHUKOHU",
      },
      {
        CITY: "KUMEU",
      },
      {
        CITY: "KUROW",
      },
      {
        CITY: "LAKE TEKAPO",
      },
      {
        CITY: "LEAMINGTON",
      },
      {
        CITY: "LEESTON",
      },
      {
        CITY: "LEVELS",
      },
      {
        CITY: "LEVIN",
      },
      {
        CITY: "LINCOLN",
      },
      {
        CITY: "LINWOOD",
      },
      {
        CITY: "LONGBURN",
      },
      {
        CITY: "LOWER HUTT",
      },
      {
        CITY: "LUMSDEN",
      },
      {
        CITY: "MANGAROA",
      },
      {
        CITY: "MANGAWHAI HEADS",
      },
      {
        CITY: "MANGERE",
      },
      {
        CITY: "MANGONUI",
      },
      {
        CITY: "MANUKAU",
      },
      {
        CITY: "MANUKAU",
      },
      {
        CITY: "MANUNUI",
      },
      {
        CITY: "MARUA",
      },
      {
        CITY: "MASTERTON",
      },
      {
        CITY: "MATAKANA",
      },
      {
        CITY: "MAUNGATAPERE",
      },
      {
        CITY: "MAUNGATUROTO",
      },
      {
        CITY: "MENZIES FERRY",
      },
      {
        CITY: "METHVEN",
      },
      {
        CITY: "MILTON",
      },
      {
        CITY: "MISSION BAY",
      },
      {
        CITY: "MOEREWA",
      },
      {
        CITY: "MOSGIEL",
      },
      {
        CITY: "MOSSBURN",
      },
      {
        CITY: "MOUNT MAUNGANUI",
      },
      {
        CITY: "MURUPARA",
      },
      {
        CITY: "MYROSS BUSH",
      },
      {
        CITY: "NAPIER CITY",
      },
      {
        CITY: "NELSON",
      },
      {
        CITY: "NEW PLYMOUTH",
      },
      {
        CITY: "NGAIO",
      },
      {
        CITY: "NGATEA",
      },
      {
        CITY: "NORTH SHORE",
      },
      {
        CITY: "NORTHCOTE POINT",
      },
      {
        CITY: "NORTHLAND",
      },
      {
        CITY: "OAKURA",
      },
      {
        CITY: "OAMARU",
      },
      {
        CITY: "OBAN",
      },
      {
        CITY: "OHAUPO",
      },
      {
        CITY: "OHURA",
      },
      {
        CITY: "OKAIAWA",
      },
      {
        CITY: "OKAIHAU",
      },
      {
        CITY: "OKATO",
      },
      {
        CITY: "ONGA ONGA",
      },
      {
        CITY: "OPAHEKE",
      },
      {
        CITY: "OPOTIKI",
      },
      {
        CITY: "OPUNAKE",
      },
      {
        CITY: "ORAKEI",
      },
      {
        CITY: "ORATIA",
      },
      {
        CITY: "OREWA",
      },
      {
        CITY: "OTAKI",
      },
      {
        CITY: "OTAKI BEACH",
      },
      {
        CITY: "OTANE",
      },
      {
        CITY: "OTAUTAU",
      },
      {
        CITY: "OTEMATATA",
      },
      {
        CITY: "OTOROHANGA",
      },
      {
        CITY: "OTUREHUA",
      },
      {
        CITY: "OUTRAM",
      },
      {
        CITY: "OWAKA",
      },
      {
        CITY: "OXFORD",
      },
      {
        CITY: "PAEKAKARIKI",
      },
      {
        CITY: "PAEROA",
      },
      {
        CITY: "PAIHIA",
      },
      {
        CITY: "PAKURANGA",
      },
      {
        CITY: "PALMERSTON NORTH",
      },
      {
        CITY: "PANMURE",
      },
      {
        CITY: "PAPAKURA",
      },
      {
        CITY: "PAPAMOA",
      },
      {
        CITY: "PARADISE",
      },
      {
        CITY: "PARAPARAUMU",
      },
      {
        CITY: "PARAPARAUMU BEACH",
      },
      {
        CITY: "PAREMATA",
      },
      {
        CITY: "PARNELL",
      },
      {
        CITY: "PENROSE",
      },
      {
        CITY: "PETONE",
      },
      {
        CITY: "PIOPIO",
      },
      {
        CITY: "PIRONGIA",
      },
      {
        CITY: "PLEASANT POINT",
      },
      {
        CITY: "POKENO",
      },
      {
        CITY: "PONSONBY",
      },
      {
        CITY: "PORANGAHAU",
      },
      {
        CITY: "PORIRUA",
      },
      {
        CITY: "PORT CHALMERS",
      },
      {
        CITY: "PORTLAND",
      },
      {
        CITY: "PUHOI",
      },
      {
        CITY: "PUKEATUA",
      },
      {
        CITY: "PUNAKAIKI",
      },
      {
        CITY: "QUEENSTOWN",
      },
      {
        CITY: "RAGLAN",
      },
      {
        CITY: "RAKAIA",
      },
      {
        CITY: "RAKAIA GORGE",
      },
      {
        CITY: "RANFURLY",
      },
      {
        CITY: "RED BEACH",
      },
      {
        CITY: "RENWICK",
      },
      {
        CITY: "REPOROA",
      },
      {
        CITY: "RIVERHEAD",
      },
      {
        CITY: "RIVERSDALE",
      },
      {
        CITY: "RIVERTON",
      },
      {
        CITY: "ROLLESTON",
      },
      {
        CITY: "ROTHERHAM",
      },
      {
        CITY: "ROTORUA",
      },
      {
        CITY: "RUAWAI",
      },
      {
        CITY: "RUSSELL",
      },
      {
        CITY: "SAINT HELIERS",
      },
      {
        CITY: "SAWYERS BAY",
      },
      {
        CITY: "SEFTON",
      },
      {
        CITY: "SILVERDALE",
      },
      {
        CITY: "SPRINGS JUNCTION",
      },
      {
        CITY: "STANMORE BAY",
      },
      {
        CITY: "SWANSON",
      },
      {
        CITY: "TAHORAITI",
      },
      {
        CITY: "TAKAKA",
      },
      {
        CITY: "TAKANINI",
      },
      {
        CITY: "TAKAPAU",
      },
      {
        CITY: "TAKAPUNA",
      },
      {
        CITY: "TAMAHERE",
      },
      {
        CITY: "TARADALE",
      },
      {
        CITY: "TASMAN",
      },
      {
        CITY: "TAUPIRI",
      },
      {
        CITY: "TAUPO",
      },
      {
        CITY: "TAURANGA",
      },
      {
        CITY: "TE ANAU",
      },
      {
        CITY: "TE ATATU",
      },
      {
        CITY: "TE KAUWHATA",
      },
      {
        CITY: "TE ROTI",
      },
      {
        CITY: "TEMPLETON",
      },
      {
        CITY: "THAMES",
      },
      {
        CITY: "TIMARU",
      },
      {
        CITY: "TINWALD",
      },
      {
        CITY: "TIRAU",
      },
      {
        CITY: "TITIRANGI NORTH",
      },
      {
        CITY: "TOKOMARU",
      },
      {
        CITY: "TOKOROA",
      },
      {
        CITY: "TORBAY",
      },
      {
        CITY: "TUAKAU",
      },
      {
        CITY: "TUATAPERE",
      },
      {
        CITY: "TURANGI",
      },
      {
        CITY: "UPPER MOUTERE",
      },
      {
        CITY: "URENUI",
      },
      {
        CITY: "WAIAU",
      },
      {
        CITY: "WAIAU PA",
      },
      {
        CITY: "WAIHI",
      },
      {
        CITY: "WAIHI BEACH",
      },
      {
        CITY: "WAIKANAE",
      },
      {
        CITY: "WAIKARI",
      },
      {
        CITY: "WAIKAWA",
      },
      {
        CITY: "WAIKUKU",
      },
      {
        CITY: "WAIKUKU BEACH",
      },
      {
        CITY: "WAIMANA",
      },
      {
        CITY: "WAIMAUKU",
      },
      {
        CITY: "WAINUIOMATA",
      },
      {
        CITY: "WAIOURU",
      },
      {
        CITY: "WAIPARA",
      },
      {
        CITY: "WAIPAWA",
      },
      {
        CITY: "WAIPU",
      },
      {
        CITY: "WAIROA",
      },
      {
        CITY: "WAIROA",
      },
      {
        CITY: "WAITAKERE CITY",
      },
      {
        CITY: "WAITANGI",
      },
      {
        CITY: "WAITARA",
      },
      {
        CITY: "WAITATI",
      },
      {
        CITY: "WAITOA",
      },
      {
        CITY: "WAIUKU",
      },
      {
        CITY: "WAKEFIELD",
      },
      {
        CITY: "WALTON",
      },
      {
        CITY: "WANAKA",
      },
      {
        CITY: "WANGANUI",
      },
      {
        CITY: "WARKWORTH",
      },
      {
        CITY: "WAVERLEY",
      },
      {
        CITY: "WELLINGTON",
      },
      {
        CITY: "WELLSFORD",
      },
      {
        CITY: "WENDON VALLEY",
      },
      {
        CITY: "WEST MELTON",
      },
      {
        CITY: "WESTON",
      },
      {
        CITY: "WESTPORT",
      },
      {
        CITY: "WEYMOUTH",
      },
      {
        CITY: "WHAKATANE",
      },
      {
        CITY: "WHANGAMATA",
      },
      {
        CITY: "WHANGAPARAOA",
      },
      {
        CITY: "WHANGAREI",
      },
      {
        CITY: "WHITIANGA",
      },
      {
        CITY: "WINCHESTER",
      },
      {
        CITY: "WINDERMERE",
      },
      {
        CITY: "WINTON",
      },
      {
        CITY: "WOODEND",
      },
      {
        CITY: "WOOLSTON",
      },
      {
        CITY: "WYNDHAM",
      },
      {
        CITY: "YALDHURST",
      },
    ],
  },
  {
    COUNTRY: "MARTINIQUE",
    CODE: "MQ",
    CITIES: [
      {
        CITY: "CASE-PILOTE",
      },
      {
        CITY: "DUCOS",
      },
      {
        CITY: "FORT-DE-FRANCE",
      },
      {
        CITY: "LE CARBET",
      },
      {
        CITY: "LE DIAMANT",
      },
      {
        CITY: "LE FRANCOIS",
      },
      {
        CITY: "LE GROS-MORNE",
      },
      {
        CITY: "LE LAMENTIN",
      },
      {
        CITY: "LE MORNE-ROUGE",
      },
      {
        CITY: "LE ROBERT",
      },
      {
        CITY: "LES TROIS-ILETS",
      },
      {
        CITY: "RIVIERE-SALEE",
      },
      {
        CITY: "SAINT-ESPRIT",
      },
      {
        CITY: "SAINT-JOSEPH",
      },
      {
        CITY: "SAINTE-ANNE",
      },
      {
        CITY: "SAINTE-LUCE",
      },
      {
        CITY: "SAINTE-MARIE",
      },
      {
        CITY: "SCHOELCHER",
      },
    ],
  },
  {
    COUNTRY: "MAYOTTE",
    CODE: "YT",
    CITIES: [
      {
        CITY: "BANDABOA",
      },
      {
        CITY: "CHICONI",
      },
      {
        CITY: "COMBANI",
      },
      {
        CITY: "DZAOUDZI",
      },
      {
        CITY: "DZOUMONYE",
      },
      {
        CITY: "KOUNGOU",
      },
      {
        CITY: "MAMOUDZOU",
      },
      {
        CITY: "OUANGANI",
      },
      {
        CITY: "PAMANDZI",
      },
      {
        CITY: "SADA",
      },
      {
        CITY: "TSINGONI",
      },
      {
        CITY: "TSOUNDZOU 1",
      },
    ],
  },
  {
    COUNTRY: "MEXICO",
    CODE: "MX",
    CITIES: [
      {
        CITY: "ABASOLO",
      },
      {
        CITY: "ACAMBARO",
      },
      {
        CITY: "ACAMBAY",
      },
      {
        CITY: "ACAPULCO",
      },
      {
        CITY: "ACATIC",
      },
      {
        CITY: "ACATLAN DE PEREZ FIGUEROA",
      },
      {
        CITY: "ACATZINGO DE HIDALGO",
      },
      {
        CITY: "ACOLMAN",
      },
      {
        CITY: "ACTOPAN",
      },
      {
        CITY: "ACUNA",
      },
      {
        CITY: "ADOLFO LOPEZ MATEOS",
      },
      {
        CITY: "ADOLFO RUIZ CORTINES",
      },
      {
        CITY: "AGUA AMARGA",
      },
      {
        CITY: "AGUA AZUL",
      },
      {
        CITY: "AGUA DULCE",
      },
      {
        CITY: "AGUA PRIETA",
      },
      {
        CITY: "AGUASCALIENTES",
      },
      {
        CITY: "AHOME",
      },
      {
        CITY: "AHUALULCO DE MERCADO",
      },
      {
        CITY: "AJIJIC",
      },
      {
        CITY: "ALAMO",
      },
      {
        CITY: "ALAMOS",
      },
      {
        CITY: "ALDAMA",
      },
      {
        CITY: "ALLENDE",
      },
      {
        CITY: "ALMADA",
      },
      {
        CITY: "ALMOLOYA",
      },
      {
        CITY: "ALTAMIRA",
      },
      {
        CITY: "ALTOTONGA",
      },
      {
        CITY: "ALVARO OBREGON BOROUGH",
      },
      {
        CITY: "AMADO NERVO",
      },
      {
        CITY: "AMECA",
      },
      {
        CITY: "AMECAMECA DE JUAREZ",
      },
      {
        CITY: "ANAHUAC",
      },
      {
        CITY: "ANAHUAC",
      },
      {
        CITY: "ANGAMACUTIRO DE LA UNION",
      },
      {
        CITY: "APAN",
      },
      {
        CITY: "APASEO EL ALTO",
      },
      {
        CITY: "APASEO EL GRANDE",
      },
      {
        CITY: "APATZINGAN",
      },
      {
        CITY: "APAXCO DE OCAMPO",
      },
      {
        CITY: "APIZACO",
      },
      {
        CITY: "APODACA",
      },
      {
        CITY: "ARANDAS",
      },
      {
        CITY: "ARCELIA",
      },
      {
        CITY: "ARIZPE",
      },
      {
        CITY: "ARRIAGA",
      },
      {
        CITY: "ASUNCION IXTALTEPEC",
      },
      {
        CITY: "ASUNCION NOCHIXTLAN",
      },
      {
        CITY: "ATEQUIZA",
      },
      {
        CITY: "ATIZAPAN",
      },
      {
        CITY: "ATLACHOLOAYA",
      },
      {
        CITY: "ATLACOMULCO",
      },
      {
        CITY: "ATLATLAHUCAN",
      },
      {
        CITY: "ATLIXCO",
      },
      {
        CITY: "ATLZAYANCA",
      },
      {
        CITY: "ATOTONILCO EL ALTO",
      },
      {
        CITY: "ATOYAC DE ALVAREZ",
      },
      {
        CITY: "AUTLAN DE NAVARRO",
      },
      {
        CITY: "AUTOPISTA PUEBLA ORIZABA 185 (LA CONDUSA)",
      },
      {
        CITY: "AXOCHIAPAN",
      },
      {
        CITY: "AZCAPOTZALCO",
      },
      {
        CITY: "AZTECA",
      },
      {
        CITY: "BADIRAGUATO",
      },
      {
        CITY: "BAHIA DE KINO",
      },
      {
        CITY: "BAJA CALIFORNIA",
      },
      {
        CITY: "BALANCAN DE DOMINGUEZ",
      },
      {
        CITY: "BAMOA",
      },
      {
        CITY: "BANDERAS DEL AGUILA",
      },
      {
        CITY: "BANDERILLA",
      },
      {
        CITY: "BARRA DE NAVIDAD",
      },
      {
        CITY: "BARRANCA DE SANTA CLARA",
      },
      {
        CITY: "BARRIO DE MEXICO",
      },
      {
        CITY: "BENITO JUAREZ",
      },
      {
        CITY: "BENITO JUAREZ",
      },
      {
        CITY: "BENITO JUAREZ",
      },
      {
        CITY: "BENITO JUAREZ (SANTA ELENA)",
      },
      {
        CITY: "BERRIOZABAL",
      },
      {
        CITY: "BOCA DEL RIO",
      },
      {
        CITY: "BRISAS BARRA DE SUCHIATE",
      },
      {
        CITY: "BUCERIAS",
      },
      {
        CITY: "CABO SAN LUCAS",
      },
      {
        CITY: "CABOS",
      },
      {
        CITY: "CADEREYTA",
      },
      {
        CITY: "CADEREYTA DE MONTES",
      },
      {
        CITY: "CADEREYTA JIMENEZ",
      },
      {
        CITY: "CAJEME",
      },
      {
        CITY: "CAJITITLAN",
      },
      {
        CITY: "CAMARGO",
      },
      {
        CITY: "CAMPECHE",
      },
      {
        CITY: "CAMPESTRE TARIMBARO",
      },
      {
        CITY: "CANANEA",
      },
      {
        CITY: "CANATLAN",
      },
      {
        CITY: "CANDELARIA",
      },
      {
        CITY: "CARDENAS",
      },
      {
        CITY: "CARDONAL",
      },
      {
        CITY: "CATEMACO",
      },
      {
        CITY: "CAZONES DE HERRERA",
      },
      {
        CITY: "CELAYA",
      },
      {
        CITY: "CENTRO",
      },
      {
        CITY: "CENTRO",
      },
      {
        CITY: "CERRITOS",
      },
      {
        CITY: "CHALCO DE DIAZ COVARRUBIAS",
      },
      {
        CITY: "CHAPALA",
      },
      {
        CITY: "CHETUMAL",
      },
      {
        CITY: "CHIAPA DE CORZO",
      },
      {
        CITY: "CHIAPAS",
      },
      {
        CITY: "CHIAUTEMPAN",
      },
      {
        CITY: "CHIAUTLA",
      },
      {
        CITY: "CHICAGO",
      },
      {
        CITY: "CHICONCUAC",
      },
      {
        CITY: "CHIGNAHUAPAN",
      },
      {
        CITY: "CHIHUAHUA",
      },
      {
        CITY: "CHIHUAHUA",
      },
      {
        CITY: "CHIHUAHUA",
      },
      {
        CITY: "CHILPANCINGO",
      },
      {
        CITY: "CHOLULA",
      },
      {
        CITY: "CIUDAD AYALA",
      },
      {
        CITY: "CIUDAD CONSTITUCION",
      },
      {
        CITY: "CIUDAD DE HUAJUAPAM DE LEON",
      },
      {
        CITY: "CIUDAD DE VILLA DE ALVAREZ",
      },
      {
        CITY: "CIUDAD DEL CARMEN",
      },
      {
        CITY: "CIUDAD HIDALGO",
      },
      {
        CITY: "CIUDAD LOPEZ MATEOS",
      },
      {
        CITY: "CIUDAD MADERO",
      },
      {
        CITY: "CIUDAD MENDOZA",
      },
      {
        CITY: "CIUDAD NEZAHUALCOYOTL",
      },
      {
        CITY: "CIUDAD SABINAS",
      },
      {
        CITY: "CIUDAD SAHAGUN",
      },
      {
        CITY: "CIUDAD VALLES",
      },
      {
        CITY: "CIUDAD VICTORIA",
      },
      {
        CITY: "COACALCO",
      },
      {
        CITY: "COAHUILA",
      },
      {
        CITY: "COATEPEC",
      },
      {
        CITY: "COATZACOALCOS",
      },
      {
        CITY: "COATZINTLA",
      },
      {
        CITY: "COCOYOC",
      },
      {
        CITY: "COCULA",
      },
      {
        CITY: "COLIMA",
      },
      {
        CITY: "COLIMAS",
      },
      {
        CITY: "COLON",
      },
      {
        CITY: "COLONIA",
      },
      {
        CITY: "COLONIA BENITO JUAREZ",
      },
      {
        CITY: "COLONIA CUAUHTEMOC",
      },
      {
        CITY: "COLONIA DE VENADO",
      },
      {
        CITY: "COLONIA EMILIANO ZAPATA (EJIDO SANTIAGO TEMOAYA)",
      },
      {
        CITY: "COLONIA GUERRERO (LOS GUAJES)",
      },
      {
        CITY: "COLONIA HIDALGO",
      },
      {
        CITY: "COLONIA LA PRESA (EJIDO DE CHAPULTEPEC)",
      },
      {
        CITY: "COLONIA LOPEZ NELIO (LAS PALMAS)",
      },
      {
        CITY: "COLONIA MADERO (MADERO)",
      },
      {
        CITY: "COLONIA MEXICO",
      },
      {
        CITY: "COLONIA MIGUEL ALEMAN VALDEZ",
      },
      {
        CITY: "COLONIA VERACRUZ",
      },
      {
        CITY: "COMALCALCO",
      },
      {
        CITY: "COMPOSTELA",
      },
      {
        CITY: "CORREGIDORA",
      },
      {
        CITY: "CORTAZAR",
      },
      {
        CITY: "COSAMALOAPAN DE CARPIO",
      },
      {
        CITY: "COSOLEACAQUE",
      },
      {
        CITY: "COYOACAN",
      },
      {
        CITY: "COYUCA DE CATALAN",
      },
      {
        CITY: "CUAJIMALPA",
      },
      {
        CITY: "CUARTA BRIGADA",
      },
      {
        CITY: "CUAUHTEMOC",
      },
      {
        CITY: "CUAUHTEMOC",
      },
      {
        CITY: "CUAUHTEMOC",
      },
      {
        CITY: "CUAUTEPEC",
      },
      {
        CITY: "CUAUTEPEC DE HINOJOSA",
      },
      {
        CITY: "CUAUTITLAN",
      },
      {
        CITY: "CUAUTLA",
      },
      {
        CITY: "CUAUTLANCINGO",
      },
      {
        CITY: "CUERNAVACA",
      },
      {
        CITY: "CUITLAHUAC",
      },
      {
        CITY: "CUNDUACAN",
      },
      {
        CITY: "CUTZAMALA DE PINZON",
      },
      {
        CITY: "CUYOACO",
      },
      {
        CITY: "DOLORES HIDALGO CUNA DE LA INDEPENDENCIA NACIONAL",
      },
      {
        CITY: "DURANGO",
      },
      {
        CITY: "ECATEPEC DE MORELOS",
      },
      {
        CITY: "EJIDO DE OTUMBA (LOMA BONITA)",
      },
      {
        CITY: "EJIDO DISTRITO FEDERAL",
      },
      {
        CITY: "EJIDO SANTA MARIA TIANGUISTENCO (EJIDO EL ROSARIO)",
      },
      {
        CITY: "EL ALTO CULIACAN",
      },
      {
        CITY: "EL CARMEN",
      },
      {
        CITY: "EL COLLI",
      },
      {
        CITY: "EL FUERTE DE MONTES CLAROS",
      },
      {
        CITY: "EL GRULLO",
      },
      {
        CITY: "EL LEON",
      },
      {
        CITY: "EL MIRADOR",
      },
      {
        CITY: "EL OCOTITO",
      },
      {
        CITY: "EL PARQUE",
      },
      {
        CITY: "EL POTRERO",
      },
      {
        CITY: "EL RIO",
      },
      {
        CITY: "EL SABINAL (SAN PEDRO)",
      },
      {
        CITY: "EL SALTO",
      },
      {
        CITY: "EL SALTO",
      },
      {
        CITY: "EL SAUZ",
      },
      {
        CITY: "EL TECUAN",
      },
      {
        CITY: "EL TORREON",
      },
      {
        CITY: "EMILIANO ZAPATA",
      },
      {
        CITY: "EMILIANO ZAPATA",
      },
      {
        CITY: "EMPALME",
      },
      {
        CITY: "ENSENADA",
      },
      {
        CITY: "EPAZOYUCAN",
      },
      {
        CITY: "ERONGARICUARO",
      },
      {
        CITY: "ESCOBEDO",
      },
      {
        CITY: "ESCOBEDO",
      },
      {
        CITY: "ESCONDIDA DE LO VERDE",
      },
      {
        CITY: "ESCUINAPA DE HIDALGO",
      },
      {
        CITY: "ESCUINTLA",
      },
      {
        CITY: "ESTADO DE MEXICO",
      },
      {
        CITY: "ESTADOS UNIDOS",
      },
      {
        CITY: "EX-HACIENDA PANTITLAN",
      },
      {
        CITY: "EZEQUIEL MONTES",
      },
      {
        CITY: "FLORIDA",
      },
      {
        CITY: "FORTIN DE LAS FLORES",
      },
      {
        CITY: "FRACCIONAMIENTO IBERICA CALIMAYA",
      },
      {
        CITY: "FRANCISCO CASTELLANOS",
      },
      {
        CITY: "FRANCISCO I. MADERO",
      },
      {
        CITY: "FRESNILLO",
      },
      {
        CITY: "FRONTERA",
      },
      {
        CITY: "GARCIA",
      },
      {
        CITY: "GARZA GARCIA",
      },
      {
        CITY: "GENERAL ESCOBEDO",
      },
      {
        CITY: "GENERAL TERAN",
      },
      {
        CITY: "GOMEZ PALACIO",
      },
      {
        CITY: "GRACIANO SANCHEZ",
      },
      {
        CITY: "GRANJAS",
      },
      {
        CITY: "GUADALAJARA",
      },
      {
        CITY: "GUADALAJARA",
      },
      {
        CITY: "GUADALUPE",
      },
      {
        CITY: "GUADALUPE",
      },
      {
        CITY: "GUADALUPE",
      },
      {
        CITY: "GUADALUPE",
      },
      {
        CITY: "GUADALUPE VICTORIA",
      },
      {
        CITY: "GUADALUPITO",
      },
      {
        CITY: "GUAMUCHIL",
      },
      {
        CITY: "GUANAJUATO",
      },
      {
        CITY: "GUASAVE",
      },
      {
        CITY: "GUAYMAS",
      },
      {
        CITY: "GUERAMARO",
      },
      {
        CITY: "GUSTAVO A. MADERO BOROUGH",
      },
      {
        CITY: "GUTIERREZ ZAMORA",
      },
      {
        CITY: "HERMOSILLO",
      },
      {
        CITY: "HEROICA ALVARADO",
      },
      {
        CITY: "HEROICA CABORCA",
      },
      {
        CITY: "HEROICA COSCOMATEPEC DE BRAVO",
      },
      {
        CITY: "HEROICA MATAMOROS",
      },
      {
        CITY: "HEROICA ZITACUARO",
      },
      {
        CITY: "HUANDACAREO",
      },
      {
        CITY: "HUANIMARO",
      },
      {
        CITY: "HUATABAMPO",
      },
      {
        CITY: "HUAUCHINANGO",
      },
      {
        CITY: "HUEHUETOCA",
      },
      {
        CITY: "HUEJOTZINGO",
      },
      {
        CITY: "HUEJUCAR",
      },
      {
        CITY: "HUICHAPAN",
      },
      {
        CITY: "HUIMANGUILLO",
      },
      {
        CITY: "HUIMILPAN",
      },
      {
        CITY: "HUIXQUILUCAN",
      },
      {
        CITY: "HUIXTLA",
      },
      {
        CITY: "HUNUCMA",
      },
      {
        CITY: "IGLESIA VIEJA",
      },
      {
        CITY: "IGNACIO ALLENDE",
      },
      {
        CITY: "IGUALA DE LA INDEPENDENCIA",
      },
      {
        CITY: "INSURGENTES",
      },
      {
        CITY: "IRAPUATO",
      },
      {
        CITY: "ISLA HOLBOX",
      },
      {
        CITY: "ISLA MUJERES",
      },
      {
        CITY: "IXMIQUILPAN",
      },
      {
        CITY: "IXTAPA",
      },
      {
        CITY: "IXTAPA",
      },
      {
        CITY: "IXTAPA-ZIHUATANEJO",
      },
      {
        CITY: "IXTAPALUCA",
      },
      {
        CITY: "IXTAPAN DE LA SAL",
      },
      {
        CITY: "IXTLAHUACA DE VILLADA",
      },
      {
        CITY: "IXTLAHUACAN DEL RIO",
      },
      {
        CITY: "IXTLAN DEL RIO",
      },
      {
        CITY: "IZAMAL",
      },
      {
        CITY: "IZTACALCO",
      },
      {
        CITY: "IZTAPALAPA",
      },
      {
        CITY: "IZUCAR DE MATAMOROS",
      },
      {
        CITY: "JACOBO",
      },
      {
        CITY: "JACONA DE PLANCARTE",
      },
      {
        CITY: "JALA",
      },
      {
        CITY: "JALAPA",
      },
      {
        CITY: "JALISCO",
      },
      {
        CITY: "JALOSTOTITLAN",
      },
      {
        CITY: "JALPA",
      },
      {
        CITY: "JALTIPAN DE MORELOS",
      },
      {
        CITY: "JALUPA",
      },
      {
        CITY: "JAUMAVE",
      },
      {
        CITY: "JEREZ DE GARCIA SALINAS",
      },
      {
        CITY: "JESUS CARRANZA",
      },
      {
        CITY: "JESUS DEL MONTE",
      },
      {
        CITY: "JESUS MARIA",
      },
      {
        CITY: "JESUS MARIA",
      },
      {
        CITY: "JILOTEPEC",
      },
      {
        CITY: "JIMENEZ",
      },
      {
        CITY: "JIMENEZ",
      },
      {
        CITY: "JIQUILPAN DE JUAREZ",
      },
      {
        CITY: "JIQUIPILCO",
      },
      {
        CITY: "JIUTEPEC",
      },
      {
        CITY: "JOCOTEPEC",
      },
      {
        CITY: "JOCOTITLAN",
      },
      {
        CITY: "JOJUTLA",
      },
      {
        CITY: "JONACATEPEC DE LEANDRO VALLE",
      },
      {
        CITY: "JOSE CARDEL",
      },
      {
        CITY: "JUAN",
      },
      {
        CITY: "JUAREZ",
      },
      {
        CITY: "JUAREZ",
      },
      {
        CITY: "JUAREZ",
      },
      {
        CITY: "JUAREZ",
      },
      {
        CITY: "KANASIN",
      },
      {
        CITY: "KILOMETRO 32 TEXCOCO LECHERIA",
      },
      {
        CITY: "LA BARCA",
      },
      {
        CITY: "LA CANADA",
      },
      {
        CITY: "LA CIUDAD",
      },
      {
        CITY: "LA CRUZ",
      },
      {
        CITY: "LA FLORIDA",
      },
      {
        CITY: "LA HABANA",
      },
      {
        CITY: "LA HERRADURA",
      },
      {
        CITY: "LA ISLA",
      },
      {
        CITY: "LA JOYA",
      },
      {
        CITY: "LA MONCADA",
      },
      {
        CITY: "LA PALETENA",
      },
      {
        CITY: "LA PAZ",
      },
      {
        CITY: "LA PIEDAD",
      },
      {
        CITY: "LA PISTA DE TIZAYUCA",
      },
      {
        CITY: "LA REFORMA",
      },
      {
        CITY: "LA TAPACHULA",
      },
      {
        CITY: "LA UNION",
      },
      {
        CITY: "LA VICTORIA",
      },
      {
        CITY: "LAGOS DE MORENO",
      },
      {
        CITY: "LAREDO",
      },
      {
        CITY: "LAS AMERICAS",
      },
      {
        CITY: "LAS CABANAS (COLINAS DE LOS ANGELES)",
      },
      {
        CITY: "LAS CANORAS (ALTIVA)",
      },
      {
        CITY: "LAS CHOAPAS",
      },
      {
        CITY: "LAS DELICIAS",
      },
      {
        CITY: "LAS DELICIAS",
      },
      {
        CITY: "LEON",
      },
      {
        CITY: "LERDO",
      },
      {
        CITY: "LERMA",
      },
      {
        CITY: "LEYVA SOLANO",
      },
      {
        CITY: "LIBRAMIENTO LEON-SALAMANCA",
      },
      {
        CITY: "LIBRES",
      },
      {
        CITY: "LINARES",
      },
      {
        CITY: "LOMA DE LOS CAVAZOS (MARIA DE LA LUZ)",
      },
      {
        CITY: "LORETO",
      },
      {
        CITY: "LORETO",
      },
      {
        CITY: "LOS GARZAS",
      },
      {
        CITY: "LOS MOCHIS",
      },
      {
        CITY: "LOS REYES",
      },
      {
        CITY: "LOS REYES",
      },
      {
        CITY: "LOS REYES DE SALGADO",
      },
      {
        CITY: "LOS SABINOS",
      },
      {
        CITY: "LUVIANOS",
      },
      {
        CITY: "MACUSPANA",
      },
      {
        CITY: "MADERO",
      },
      {
        CITY: "MAGDALENA DE KINO",
      },
      {
        CITY: "MAGDALENA PETLACALCO",
      },
      {
        CITY: "MAGISCATZIN",
      },
      {
        CITY: "MALINALCO",
      },
      {
        CITY: "MANUEL AVILA CAMACHO",
      },
      {
        CITY: "MANZANILLO",
      },
      {
        CITY: "MARIANO MATAMOROS",
      },
      {
        CITY: "MARQUELIA",
      },
      {
        CITY: "MARTINEZ DE LA TORRE",
      },
      {
        CITY: "MAZATECOCHCO",
      },
      {
        CITY: "MAZATEPEC",
      },
      {
        CITY: "MAZATLAN",
      },
      {
        CITY: "MEDELLIN DE BRAVO",
      },
      {
        CITY: "MELCHOR",
      },
      {
        CITY: "MELCHOR MUZQUIZ",
      },
      {
        CITY: "MENDOZA",
      },
      {
        CITY: "MEXICALI",
      },
      {
        CITY: "MEXICO CITY",
      },
      {
        CITY: "MEXTEPEC",
      },
      {
        CITY: "MIER",
      },
      {
        CITY: "MIGUEL ALEMAN",
      },
      {
        CITY: "MIGUEL HIDALGO",
      },
      {
        CITY: "MINA MEXICO",
      },
      {
        CITY: "MINERAL",
      },
      {
        CITY: "MINERAL DE LA REFORMA",
      },
      {
        CITY: "MINERAL DEL CHICO",
      },
      {
        CITY: "MINERAL DEL MONTE",
      },
      {
        CITY: "MIXQUIAHUALA DE JUAREZ",
      },
      {
        CITY: "MOCTEZUMA",
      },
      {
        CITY: "MONCLOVA",
      },
      {
        CITY: "MONCLOVA",
      },
      {
        CITY: "MONTE LEON",
      },
      {
        CITY: "MONTEMORELOS",
      },
      {
        CITY: "MONTERREY",
      },
      {
        CITY: "MONTERREY",
      },
      {
        CITY: "MORELIA",
      },
      {
        CITY: "MORELOS",
      },
      {
        CITY: "MORELOS",
      },
      {
        CITY: "MORELOS",
      },
      {
        CITY: "MOROLEON",
      },
      {
        CITY: "MOTOZINTLA DE MENDOZA",
      },
      {
        CITY: "MUNA",
      },
      {
        CITY: "NACOZARI VIEJO",
      },
      {
        CITY: "NAUCALPAN",
      },
      {
        CITY: "NAVOJOA",
      },
      {
        CITY: "NAVOLATO",
      },
      {
        CITY: "NETZAHUALCOYOTL",
      },
      {
        CITY: "NEXTLALPAN",
      },
      {
        CITY: "NICOLAS ROMERO",
      },
      {
        CITY: "NOGALES",
      },
      {
        CITY: "NOXTEPEC DE ZARAGOZA (EL AGUACATE)",
      },
      {
        CITY: "NUEVA ITALIA DE RUIZ",
      },
      {
        CITY: "NUEVO CASAS GRANDES",
      },
      {
        CITY: "NUEVO COAHUILA",
      },
      {
        CITY: "NUEVO IDEAL",
      },
      {
        CITY: "NUEVO LAREDO",
      },
      {
        CITY: "NUEVO LEON",
      },
      {
        CITY: "OAXACA",
      },
      {
        CITY: "OAXTEPEC",
      },
      {
        CITY: "OCOTLAN",
      },
      {
        CITY: "OCOYOACAC",
      },
      {
        CITY: "OCOZOCOAUTLA DE ESPINOSA",
      },
      {
        CITY: "OJINAGA",
      },
      {
        CITY: "OJO DE AGUA",
      },
      {
        CITY: "OJUELOS DE JALISCO",
      },
      {
        CITY: "ORIZABA",
      },
      {
        CITY: "OXKUTZKAB",
      },
      {
        CITY: "PABELLON",
      },
      {
        CITY: "PABELLON DE ARTEAGA",
      },
      {
        CITY: "PACHUCA",
      },
      {
        CITY: "PACHUCA DE SOTO",
      },
      {
        CITY: "PANOTLA",
      },
      {
        CITY: "PANTANAL",
      },
      {
        CITY: "PARAISO",
      },
      {
        CITY: "PARAJE NUEVO",
      },
      {
        CITY: "PARRAL",
      },
      {
        CITY: "PASEOS DE TECAMAC",
      },
      {
        CITY: "PASO REAL",
      },
      {
        CITY: "PASTOR ORTIZ",
      },
      {
        CITY: "PATZCUARO",
      },
      {
        CITY: "PEDREGAL DE JERICO",
      },
      {
        CITY: "PEDRO ESCOBEDO",
      },
      {
        CITY: "PEDRO MEOQUI",
      },
      {
        CITY: "PERICOS",
      },
      {
        CITY: "PEROTE",
      },
      {
        CITY: "PESQUERIA",
      },
      {
        CITY: "PETAQUILLAS",
      },
      {
        CITY: "PETATLAN",
      },
      {
        CITY: "PICHUCALCO",
      },
      {
        CITY: "PIEDRAS NEGRAS",
      },
      {
        CITY: "PIEDRAS NEGRAS",
      },
      {
        CITY: "PLAYA DEL CARMEN",
      },
      {
        CITY: "POLANCO",
      },
      {
        CITY: "PONCITLAN",
      },
      {
        CITY: "POZA RICA CHACAS",
      },
      {
        CITY: "POZA RICA DE HIDALGO",
      },
      {
        CITY: "PRIMERO DE MAYO",
      },
      {
        CITY: "PROGRESO",
      },
      {
        CITY: "PROLONGACION AVENIDA MEXICO NORTE",
      },
      {
        CITY: "PUEBLA",
      },
      {
        CITY: "PUEBLO HIDALGO",
      },
      {
        CITY: "PUEBLO JUAREZ (LA MAGDALENA)",
      },
      {
        CITY: "PUEBLO VIEJO",
      },
      {
        CITY: "PUEBLO YAQUI",
      },
      {
        CITY: "PUENTE DE IXTLA",
      },
      {
        CITY: "PUENTE GRANDE",
      },
      {
        CITY: "PUERTO ESCONDIDO",
      },
      {
        CITY: "PUERTO MEXICO",
      },
      {
        CITY: "PUERTO MORELOS",
      },
      {
        CITY: "PUERTO VALLARTA",
      },
      {
        CITY: "PURISIMA DE BUSTOS",
      },
      {
        CITY: "PURISIMA DEL ZAPOTE",
      },
      {
        CITY: "PURUANDIRO",
      },
      {
        CITY: "QUESERIA",
      },
      {
        CITY: "QUINTANA ROO",
      },
      {
        CITY: "QUINTANA ROO",
      },
      {
        CITY: "QUIROGA",
      },
      {
        CITY: "RAMOS ARIZPE",
      },
      {
        CITY: "RANCHO XALNENE",
      },
      {
        CITY: "RAYON",
      },
      {
        CITY: "RAYON",
      },
      {
        CITY: "REFORMA (MADRIGAL 2DA. SECCION)",
      },
      {
        CITY: "REFRACTARIOS GUADALAJARA",
      },
      {
        CITY: "REYES DE VALLARTA",
      },
      {
        CITY: "REYNOSA",
      },
      {
        CITY: "RINCON DE TAMAYO",
      },
      {
        CITY: "RIO BLANCO",
      },
      {
        CITY: "RIO BRAVO",
      },
      {
        CITY: "ROMA",
      },
      {
        CITY: "ROSARITO",
      },
      {
        CITY: "SAHUAYO DE MORELOS",
      },
      {
        CITY: "SALAMANCA",
      },
      {
        CITY: "SALINA CRUZ",
      },
      {
        CITY: "SALINAS DE HIDALGO",
      },
      {
        CITY: "SALTILLO",
      },
      {
        CITY: "SALVATIERRA",
      },
      {
        CITY: "SAN AGUSTIN",
      },
      {
        CITY: "SAN AGUSTIN (SALVADOR ALVARADO)",
      },
      {
        CITY: "SAN AGUSTIN ALTAMIRANO",
      },
      {
        CITY: "SAN AGUSTIN TLAXIACA",
      },
      {
        CITY: "SAN ANDRES CHOLULA",
      },
      {
        CITY: "SAN ANDRES TUXTLA",
      },
      {
        CITY: "SAN ANGEL INN",
      },
      {
        CITY: "SAN ANTONIO",
      },
      {
        CITY: "SAN ANTONIO LA ISLA",
      },
      {
        CITY: "SAN ANTONIO VIRREYES",
      },
      {
        CITY: "SAN BERNARDO",
      },
      {
        CITY: "SAN CRISTOBAL",
      },
      {
        CITY: "SAN CRISTOBAL",
      },
      {
        CITY: "SAN FELIPE",
      },
      {
        CITY: "SAN FELIPE TORRES MOCHAS [GANADERIA]",
      },
      {
        CITY: "SAN FERNANDO",
      },
      {
        CITY: "SAN FERNANDO",
      },
      {
        CITY: "SAN FRANCISCO",
      },
      {
        CITY: "SAN FRANCISCO",
      },
      {
        CITY: "SAN FRANCISCO JAVIER",
      },
      {
        CITY: "SAN GABRIEL",
      },
      {
        CITY: "SAN JERONIMO DE JUAREZ",
      },
      {
        CITY: "SAN JOSE DEL VALLE",
      },
      {
        CITY: "SAN JOSE ITURBIDE",
      },
      {
        CITY: "SAN JUAN",
      },
      {
        CITY: "SAN JUAN",
      },
      {
        CITY: "SAN JUAN BAUTISTA TUXTLA",
      },
      {
        CITY: "SAN JUAN DE ARAGON",
      },
      {
        CITY: "SAN JUAN DEL RIO",
      },
      {
        CITY: "SAN LUIS",
      },
      {
        CITY: "SAN LUIS",
      },
      {
        CITY: "SAN LUIS DE LA PAZ",
      },
      {
        CITY: "SAN LUIS SOYATLAN",
      },
      {
        CITY: "SAN MARTIN DE CAMARGO",
      },
      {
        CITY: "SAN MARTIN DURAZNOS",
      },
      {
        CITY: "SAN MARTIN HIDALGO",
      },
      {
        CITY: "SAN MARTIN NOTARIO",
      },
      {
        CITY: "SAN MARTIN TEXMELUCAN DE LABASTIDA",
      },
      {
        CITY: "SAN MATEO",
      },
      {
        CITY: "SAN MATEO ATENCO",
      },
      {
        CITY: "SAN MATEO MEXICALTZINGO",
      },
      {
        CITY: "SAN MIGUEL",
      },
      {
        CITY: "SAN MIGUEL",
      },
      {
        CITY: "SAN MIGUEL BALDERAS",
      },
      {
        CITY: "SAN MIGUEL DE ALLENDE",
      },
      {
        CITY: "SAN MIGUEL DE ATOTONILCO",
      },
      {
        CITY: "SAN MIGUEL DE COZUMEL",
      },
      {
        CITY: "SAN MIGUEL DE PAPASQUIARO",
      },
      {
        CITY: "SAN MIGUEL EL ALTO",
      },
      {
        CITY: "SAN MIGUEL EL ALTO (EL PUERTO)",
      },
      {
        CITY: "SAN MIGUEL ZINACANTEPEC",
      },
      {
        CITY: "SAN NICOLAS",
      },
      {
        CITY: "SAN NICOLAS",
      },
      {
        CITY: "SAN NICOLAS",
      },
      {
        CITY: "SAN NICOLAS TOTOLAPAN",
      },
      {
        CITY: "SAN PABLO HIDALGO",
      },
      {
        CITY: "SAN PATRICIO",
      },
      {
        CITY: "SAN PEDRO",
      },
      {
        CITY: "SAN PEDRO",
      },
      {
        CITY: "SAN PEDRO",
      },
      {
        CITY: "SAN PEDRO CHOLULA",
      },
      {
        CITY: "SAN PEDRO DE LAS COLONIAS",
      },
      {
        CITY: "SAN PEDRO DE NOLASCO",
      },
      {
        CITY: "SAN PEDRO PIEDRA GORDA",
      },
      {
        CITY: "SAN PEDRO POCHUTLA",
      },
      {
        CITY: "SAN RAFAEL",
      },
      {
        CITY: "SAN SALVADOR ATENCO",
      },
      {
        CITY: "SAN SALVADOR HUIXCOLOTLA",
      },
      {
        CITY: "SAN SALVADOR TZOMPANTEPEC",
      },
      {
        CITY: "SAN SEBASTIAN EL GRANDE",
      },
      {
        CITY: "SAN SEBASTIAN TUTLA",
      },
      {
        CITY: "SAN SEBASTIAN XHALA",
      },
      {
        CITY: "SAN SIMON DE GUERRERO",
      },
      {
        CITY: "SAN VICENTE",
      },
      {
        CITY: "SAN VICENTE CHICOLOAPAN",
      },
      {
        CITY: "SANTA ANITA",
      },
      {
        CITY: "SANTA APOLONIA",
      },
      {
        CITY: "SANTA CATALINA",
      },
      {
        CITY: "SANTA CATARINA",
      },
      {
        CITY: "SANTA CATARINA JUQUILA",
      },
      {
        CITY: "SANTA FE",
      },
      {
        CITY: "SANTA ISABEL TLANEPANTLA",
      },
      {
        CITY: "SANTA LUCIA",
      },
      {
        CITY: "SANTA MARIA CHIMALHUACAN",
      },
      {
        CITY: "SANTIAGO",
      },
      {
        CITY: "SANTIAGO DE QUERETARO",
      },
      {
        CITY: "SANTIAGO IXCUINTLA",
      },
      {
        CITY: "SANTIAGO TIANGUISTENCO",
      },
      {
        CITY: "SATELITE",
      },
      {
        CITY: "SECCION PRIMERA SANTIAGO TETLA",
      },
      {
        CITY: "SELENE",
      },
      {
        CITY: "SIERRA MADRE",
      },
      {
        CITY: "SILAO",
      },
      {
        CITY: "SINALOA DE LEYVA",
      },
      {
        CITY: "SOMBRERETE",
      },
      {
        CITY: "SONORA",
      },
      {
        CITY: "SOTO LA MARINA",
      },
      {
        CITY: "SUCHIAPA",
      },
      {
        CITY: "TABASCO",
      },
      {
        CITY: "TABLON GRANDE (BOMBAS DE APAXCO)",
      },
      {
        CITY: "TACAMBARO DE CODALLOS",
      },
      {
        CITY: "TACOTALPA",
      },
      {
        CITY: "TALA",
      },
      {
        CITY: "TAMAULIPAS",
      },
      {
        CITY: "TAMAZULA DE GORDIANO",
      },
      {
        CITY: "TAMPICO",
      },
      {
        CITY: "TAMPICO ALTO",
      },
      {
        CITY: "TANGANCICUARO DE ARISTA",
      },
      {
        CITY: "TAPACHULA",
      },
      {
        CITY: "TAPALPA",
      },
      {
        CITY: "TAXCO",
      },
      {
        CITY: "TEAPA",
      },
      {
        CITY: "TECALITLAN",
      },
      {
        CITY: "TECAMACHALCO",
      },
      {
        CITY: "TECATE",
      },
      {
        CITY: "TECAX",
      },
      {
        CITY: "TECOMAN",
      },
      {
        CITY: "TECUALA",
      },
      {
        CITY: "TEJUPILCO DE HIDALGO",
      },
      {
        CITY: "TEKOH",
      },
      {
        CITY: "TELOLOAPAN",
      },
      {
        CITY: "TEMASCALCINGO",
      },
      {
        CITY: "TEMASCALTEPEC DE GONZALEZ",
      },
      {
        CITY: "TEMIXCO",
      },
      {
        CITY: "TENAMAXTLAN",
      },
      {
        CITY: "TENANCINGO DE DEGOLLADO",
      },
      {
        CITY: "TENOSIQUE DE PINO SUAREZ",
      },
      {
        CITY: "TEOLOYUCAN",
      },
      {
        CITY: "TEOTIHUACAN DE ARISTA",
      },
      {
        CITY: "TEPALCATEPEC",
      },
      {
        CITY: "TEPEACA",
      },
      {
        CITY: "TEPEAPULCO",
      },
      {
        CITY: "TEPEJI DE OCAMPO",
      },
      {
        CITY: "TEPETLACOLCO",
      },
      {
        CITY: "TEPETLAPA",
      },
      {
        CITY: "TEPIC",
      },
      {
        CITY: "TEPOTZOTLAN",
      },
      {
        CITY: "TEPOZTLAN",
      },
      {
        CITY: "TEQUILA",
      },
      {
        CITY: "TEQUISISTLAN",
      },
      {
        CITY: "TEQUISQUIAPAN",
      },
      {
        CITY: "TEQUIXQUIAC",
      },
      {
        CITY: "TEXCOCO",
      },
      {
        CITY: "TEZONAPA",
      },
      {
        CITY: "TEZONTEPEC",
      },
      {
        CITY: "TEZONTEPEC DE ALDAMA",
      },
      {
        CITY: "TEZOYUCA",
      },
      {
        CITY: "TEZOYUCA",
      },
      {
        CITY: "TIERRA BLANCA",
      },
      {
        CITY: "TIERRA COLORADA",
      },
      {
        CITY: "TIJUANA",
      },
      {
        CITY: "TILZAPOTLA",
      },
      {
        CITY: "TIRINDARO",
      },
      {
        CITY: "TIZAPAN EL ALTO",
      },
      {
        CITY: "TLACOLULA DE MATAMOROS",
      },
      {
        CITY: "TLACOTALPAN",
      },
      {
        CITY: "TLAHUAC",
      },
      {
        CITY: "TLAHUELILPAN",
      },
      {
        CITY: "TLAJOMULCO DE ZUNIGA",
      },
      {
        CITY: "TLALMANALCO DE VELAZQUEZ",
      },
      {
        CITY: "TLALNEPANTLA",
      },
      {
        CITY: "TLALNEPANTLA",
      },
      {
        CITY: "TLALPAN",
      },
      {
        CITY: "TLALTIZAPAN",
      },
      {
        CITY: "TLANALAPA",
      },
      {
        CITY: "TLAPA DE COMONFORT",
      },
      {
        CITY: "TLAPACOYAN",
      },
      {
        CITY: "TLAQUEPAQUE",
      },
      {
        CITY: "TLAQUILTENANGO",
      },
      {
        CITY: "TLATLAUQUITEPEC",
      },
      {
        CITY: "TLATLAYA",
      },
      {
        CITY: "TLAXCALA",
      },
      {
        CITY: "TLAXCALANCINGO",
      },
      {
        CITY: "TOCATLAN",
      },
      {
        CITY: "TOCUMBO",
      },
      {
        CITY: "TODOS SANTOS",
      },
      {
        CITY: "TOLCAYUCA",
      },
      {
        CITY: "TOLUCA",
      },
      {
        CITY: "TONALA",
      },
      {
        CITY: "TONALA",
      },
      {
        CITY: "TONATICO",
      },
      {
        CITY: "TOPOLOBAMPO",
      },
      {
        CITY: "TULA DE ALLENDE",
      },
      {
        CITY: "TULANCINGO",
      },
      {
        CITY: "TULTEPEC",
      },
      {
        CITY: "TULTITLAN",
      },
      {
        CITY: "TULUM",
      },
      {
        CITY: "TUXPAN",
      },
      {
        CITY: "TUXPAN",
      },
      {
        CITY: "TUXPAN DE RODRIGUEZ CANO",
      },
      {
        CITY: "TUXTLA",
      },
      {
        CITY: "UMAN",
      },
      {
        CITY: "UNION DE TULA",
      },
      {
        CITY: "URES",
      },
      {
        CITY: "URIANGATO",
      },
      {
        CITY: "URSULO GALVAN",
      },
      {
        CITY: "URUAPAN",
      },
      {
        CITY: "VALLADOLID",
      },
      {
        CITY: "VALLE DE BRAVO",
      },
      {
        CITY: "VALLE DE SANTIAGO",
      },
      {
        CITY: "VALLE HERMOSO",
      },
      {
        CITY: "VALLES",
      },
      {
        CITY: "VENUSTIANO CARRANZA",
      },
      {
        CITY: "VENUSTIANO CARRANZA",
      },
      {
        CITY: "VERACRUZ",
      },
      {
        CITY: "VICENTE GUERRERO",
      },
      {
        CITY: "VICENTE GUERRERO",
      },
      {
        CITY: "VICTORIA",
      },
      {
        CITY: "VICTORIA",
      },
      {
        CITY: "VILLA CUAUHTEMOC OTZOLOTEPEC",
      },
      {
        CITY: "VILLA DE ALMOLOYA DE JUAREZ",
      },
      {
        CITY: "VILLA DE COSTA RICA",
      },
      {
        CITY: "VILLA GARCIA",
      },
      {
        CITY: "VILLA HIDALGO",
      },
      {
        CITY: "VILLA NANCHITAL",
      },
      {
        CITY: "VILLADIEGO",
      },
      {
        CITY: "VILLAGRAN",
      },
      {
        CITY: "VILLAHERMOSA",
      },
      {
        CITY: "VISITACION",
      },
      {
        CITY: "VISTAHERMOSA DE NEGRETE",
      },
      {
        CITY: "XALAPA",
      },
      {
        CITY: "XALISCO",
      },
      {
        CITY: "XOCHIMILCO",
      },
      {
        CITY: "XOCHIMILCO",
      },
      {
        CITY: "XOCHITEPEC",
      },
      {
        CITY: "XONACATLAN",
      },
      {
        CITY: "YANGA",
      },
      {
        CITY: "YAUTEPEC",
      },
      {
        CITY: "YECAPIXTLA",
      },
      {
        CITY: "YUCATAN",
      },
      {
        CITY: "YURECUARO",
      },
      {
        CITY: "YURIRIA",
      },
      {
        CITY: "ZACAPOAXTLA",
      },
      {
        CITY: "ZACAPU",
      },
      {
        CITY: "ZACATECAS",
      },
      {
        CITY: "ZACATELCO",
      },
      {
        CITY: "ZACATEPEC",
      },
      {
        CITY: "ZACATLAN",
      },
      {
        CITY: "ZACUALPAN",
      },
      {
        CITY: "ZACUALPAN",
      },
      {
        CITY: "ZACUALTIPAN",
      },
      {
        CITY: "ZAMORA",
      },
      {
        CITY: "ZAPOPAN",
      },
      {
        CITY: "ZAPOTITLAN DE VADILLO",
      },
      {
        CITY: "ZAPOTLAN DE JUAREZ",
      },
      {
        CITY: "ZAPOTLANEJO",
      },
      {
        CITY: "ZAPOTLANEJO",
      },
      {
        CITY: "ZARAGOZA",
      },
      {
        CITY: "ZINAPECUARO",
      },
      {
        CITY: "ZITLALA",
      },
      {
        CITY: "ZONGOLICA",
      },
      {
        CITY: "ZUMPANGO",
      },
    ],
  },
  {
    COUNTRY: "MONTENEGRO",
    CODE: "ME",
    CITIES: [
      {
        CITY: "BUDVA",
      },
      {
        CITY: "CRNA GORA",
      },
      {
        CITY: "HERCEG NOVI",
      },
      {
        CITY: "IGALO",
      },
      {
        CITY: "KOTOR",
      },
      {
        CITY: "NIKSIC",
      },
      {
        CITY: "PLJEVLJA",
      },
      {
        CITY: "PODGORICA",
      },
      {
        CITY: "STARI BAR",
      },
      {
        CITY: "ULCINJ",
      },
    ],
  },
  {
    COUNTRY: "OMAN",
    CODE: "OM",
    CITIES: [
      {
        CITY: "AL SOHAR",
      },
      {
        CITY: "MUSCAT",
      },
      {
        CITY: "NIZWA",
      },
      {
        CITY: "RUWI",
      },
      {
        CITY: "SAHAM",
      },
      {
        CITY: "SALALAH",
      },
      {
        CITY: "SAMAD",
      },
    ],
  },
  {
    COUNTRY: "PHILIPPINES",
    CODE: "PH",
    CITIES: [
      {
        CITY: "ABUCAY",
      },
      {
        CITY: "ACACIA",
      },
      {
        CITY: "AGUILAR",
      },
      {
        CITY: "AGUSAN PEQUENO",
      },
      {
        CITY: "ALABANG",
      },
      {
        CITY: "ALAMINOS",
      },
      {
        CITY: "ALAMINOS",
      },
      {
        CITY: "ALCALA",
      },
      {
        CITY: "ALFONSO",
      },
      {
        CITY: "ALITAGTAG",
      },
      {
        CITY: "AMADEO",
      },
      {
        CITY: "ANGAT",
      },
      {
        CITY: "ANGELES CITY",
      },
      {
        CITY: "ANGELES CITY",
      },
      {
        CITY: "ANGONO",
      },
      {
        CITY: "ANTIPOLO",
      },
      {
        CITY: "ANTIPOLO",
      },
      {
        CITY: "APALIT",
      },
      {
        CITY: "AQUINO",
      },
      {
        CITY: "ARAYAT",
      },
      {
        CITY: "ASIA",
      },
      {
        CITY: "AURORA",
      },
      {
        CITY: "AYALA",
      },
      {
        CITY: "BAAO",
      },
      {
        CITY: "BACLARAN",
      },
      {
        CITY: "BACOLOD CITY",
      },
      {
        CITY: "BACOOR",
      },
      {
        CITY: "BAGAC",
      },
      {
        CITY: "BAGO CITY",
      },
      {
        CITY: "BAGONG NAYON",
      },
      {
        CITY: "BAGUIO CITY",
      },
      {
        CITY: "BAGUMBAYAN",
      },
      {
        CITY: "BALABAG",
      },
      {
        CITY: "BALAGTAS",
      },
      {
        CITY: "BALAMBAN",
      },
      {
        CITY: "BALANGA",
      },
      {
        CITY: "BALAYAN",
      },
      {
        CITY: "BALIUAG",
      },
      {
        CITY: "BALUNGAO",
      },
      {
        CITY: "BARBAR",
      },
      {
        CITY: "BATAAN",
      },
      {
        CITY: "BATAAN",
      },
      {
        CITY: "BATANGAS",
      },
      {
        CITY: "BATES",
      },
      {
        CITY: "BATIS",
      },
      {
        CITY: "BAUAN",
      },
      {
        CITY: "BAUAN",
      },
      {
        CITY: "BAUTISTA",
      },
      {
        CITY: "BAY",
      },
      {
        CITY: "BAYAN",
      },
      {
        CITY: "BAYAWAN",
      },
      {
        CITY: "BINAN",
      },
      {
        CITY: "BINANGONAN",
      },
      {
        CITY: "BINONDO",
      },
      {
        CITY: "BLUMENTRITT",
      },
      {
        CITY: "BOAC",
      },
      {
        CITY: "BOCAUE",
      },
      {
        CITY: "BONIFACIO",
      },
      {
        CITY: "BOOL",
      },
      {
        CITY: "BUKIDNON",
      },
      {
        CITY: "BULACAN",
      },
      {
        CITY: "BULACAN",
      },
      {
        CITY: "BURGOS",
      },
      {
        CITY: "BUSTOS",
      },
      {
        CITY: "BUTING",
      },
      {
        CITY: "BUTUAN",
      },
      {
        CITY: "CABANATUAN CITY",
      },
      {
        CITY: "CABATUAN",
      },
      {
        CITY: "CABUGAO",
      },
      {
        CITY: "CABUYAO",
      },
      {
        CITY: "CAFE",
      },
      {
        CITY: "CAGAYAN",
      },
      {
        CITY: "CAGAYAN DE ORO",
      },
      {
        CITY: "CAINTA",
      },
      {
        CITY: "CALACA",
      },
      {
        CITY: "CALAMBA",
      },
      {
        CITY: "CALAMBA CITY",
      },
      {
        CITY: "CALAPAN",
      },
      {
        CITY: "CALASIAO",
      },
      {
        CITY: "CALBAYOG CITY",
      },
      {
        CITY: "CALINAN",
      },
      {
        CITY: "CALOOCAN",
      },
      {
        CITY: "CALOOCAN",
      },
      {
        CITY: "CALOOCAN CITY",
      },
      {
        CITY: "CAMPUS",
      },
      {
        CITY: "CANDELARIA",
      },
      {
        CITY: "CANDON",
      },
      {
        CITY: "CANLUBANG",
      },
      {
        CITY: "CAPITOL",
      },
      {
        CITY: "CARDONA",
      },
      {
        CITY: "CARLATAN",
      },
      {
        CITY: "CARLOS",
      },
      {
        CITY: "CARMONA",
      },
      {
        CITY: "CAROLINAS",
      },
      {
        CITY: "CAUAYAN",
      },
      {
        CITY: "CAVINTI",
      },
      {
        CITY: "CAVITE CITY",
      },
      {
        CITY: "CEBU CITY",
      },
      {
        CITY: "CENTRAL",
      },
      {
        CITY: "CENTRE",
      },
      {
        CITY: "CENTRO",
      },
      {
        CITY: "CHINA",
      },
      {
        CITY: "CINCO",
      },
      {
        CITY: "CITY OF BATAC",
      },
      {
        CITY: "COGAN",
      },
      {
        CITY: "CONCEPCION",
      },
      {
        CITY: "CONCEPCION",
      },
      {
        CITY: "COTABATO CITY",
      },
      {
        CITY: "COTABATO CITY",
      },
      {
        CITY: "CRUZ",
      },
      {
        CITY: "CUBAO",
      },
      {
        CITY: "DAGUPAN",
      },
      {
        CITY: "DAGUPAN",
      },
      {
        CITY: "DANAO",
      },
      {
        CITY: "DAPITAN",
      },
      {
        CITY: "DARAGA",
      },
      {
        CITY: "DASMARINAS",
      },
      {
        CITY: "DAVAO CITY",
      },
      {
        CITY: "DEL MONTE",
      },
      {
        CITY: "DEL PILAR",
      },
      {
        CITY: "DIGOS",
      },
      {
        CITY: "DILIMAN PRIMERO",
      },
      {
        CITY: "DINAS",
      },
      {
        CITY: "DINGRAS",
      },
      {
        CITY: "DIPOLOG CITY",
      },
      {
        CITY: "DOLORES",
      },
      {
        CITY: "DOMINGO",
      },
      {
        CITY: "DON BOSCO EXECUTIVE VILLAGE",
      },
      {
        CITY: "DON JOSE",
      },
      {
        CITY: "DUMAGUETE",
      },
      {
        CITY: "FAIRVIEW",
      },
      {
        CITY: "FELICIANO",
      },
      {
        CITY: "FERNANDEZ",
      },
      {
        CITY: "FERNANDO",
      },
      {
        CITY: "FORTUNA",
      },
      {
        CITY: "GALLEGO",
      },
      {
        CITY: "GENERAL",
      },
      {
        CITY: "GENERAL SANTOS",
      },
      {
        CITY: "GENERAL TRIAS",
      },
      {
        CITY: "GITAGUM",
      },
      {
        CITY: "GLORIA",
      },
      {
        CITY: "GUAGUA",
      },
      {
        CITY: "HAGONOY",
      },
      {
        CITY: "HILONGOS",
      },
      {
        CITY: "HIMAMAYLAN",
      },
      {
        CITY: "ILAGAN",
      },
      {
        CITY: "ILIGAN",
      },
      {
        CITY: "ILOG",
      },
      {
        CITY: "ILOILO CITY",
      },
      {
        CITY: "ILOILO CITY",
      },
      {
        CITY: "IMUS",
      },
      {
        CITY: "IMUS",
      },
      {
        CITY: "INTERIOR",
      },
      {
        CITY: "IRIGA CITY",
      },
      {
        CITY: "ISABELA",
      },
      {
        CITY: "ISLAND PARK",
      },
      {
        CITY: "JAGNA",
      },
      {
        CITY: "KALIBO",
      },
      {
        CITY: "KAPATAGAN",
      },
      {
        CITY: "KAWIT",
      },
      {
        CITY: "KIDAPAWAN",
      },
      {
        CITY: "KORONADAL",
      },
      {
        CITY: "LA SALETTE",
      },
      {
        CITY: "LA TRINIDAD",
      },
      {
        CITY: "LA UNION",
      },
      {
        CITY: "LAGUERTA",
      },
      {
        CITY: "LAGUNA",
      },
      {
        CITY: "LAGUNA",
      },
      {
        CITY: "LAHUG",
      },
      {
        CITY: "LAMITAN",
      },
      {
        CITY: "LAOAG",
      },
      {
        CITY: "LAPU-LAPU CITY",
      },
      {
        CITY: "LAS PINAS",
      },
      {
        CITY: "LAWIS",
      },
      {
        CITY: "LEGASPI",
      },
      {
        CITY: "LEON",
      },
      {
        CITY: "LEYTE",
      },
      {
        CITY: "LIBERTY",
      },
      {
        CITY: "LIBIS",
      },
      {
        CITY: "LILIO",
      },
      {
        CITY: "LIMAY",
      },
      {
        CITY: "LIPA CITY",
      },
      {
        CITY: "LLANERA",
      },
      {
        CITY: "LOOC",
      },
      {
        CITY: "LOS BANOS",
      },
      {
        CITY: "LOS MARTIRES",
      },
      {
        CITY: "LOURDES",
      },
      {
        CITY: "LUCENA CITY",
      },
      {
        CITY: "LUNA",
      },
      {
        CITY: "LUNA",
      },
      {
        CITY: "LUZON",
      },
      {
        CITY: "MABALACAT",
      },
      {
        CITY: "MABINI",
      },
      {
        CITY: "MACABEBE",
      },
      {
        CITY: "MAGSAYSAY",
      },
      {
        CITY: "MAKATI CITY",
      },
      {
        CITY: "MALABON",
      },
      {
        CITY: "MALABON",
      },
      {
        CITY: "MALASIQUI",
      },
      {
        CITY: "MALATE",
      },
      {
        CITY: "MALOLOS",
      },
      {
        CITY: "MALVAR",
      },
      {
        CITY: "MAMUNGAN",
      },
      {
        CITY: "MANAOAG",
      },
      {
        CITY: "MANDALUYONG CITY",
      },
      {
        CITY: "MANDAUE CITY",
      },
      {
        CITY: "MANDAUE CITY",
      },
      {
        CITY: "MANGALDAN",
      },
      {
        CITY: "MANGROVE",
      },
      {
        CITY: "MANILA",
      },
      {
        CITY: "MANILA",
      },
      {
        CITY: "MAPUA",
      },
      {
        CITY: "MAQUILING",
      },
      {
        CITY: "MARCOS",
      },
      {
        CITY: "MARIA",
      },
      {
        CITY: "MARIKINA CITY",
      },
      {
        CITY: "MARILAG",
      },
      {
        CITY: "MARILAO",
      },
      {
        CITY: "MARINA",
      },
      {
        CITY: "MARIVELES",
      },
      {
        CITY: "MASBATE",
      },
      {
        CITY: "MATALAM",
      },
      {
        CITY: "MATANDANG BALARA",
      },
      {
        CITY: "MAUBAN",
      },
      {
        CITY: "MAWAB",
      },
      {
        CITY: "MERVILLE SUBDIVISION",
      },
      {
        CITY: "MEYCAUAYAN",
      },
      {
        CITY: "MIAGAO",
      },
      {
        CITY: "MINALIN",
      },
      {
        CITY: "MINDANAW",
      },
      {
        CITY: "MINGLANILLA",
      },
      {
        CITY: "MISAMIS",
      },
      {
        CITY: "MOLAVE",
      },
      {
        CITY: "MUNOZ EAST",
      },
      {
        CITY: "MUNTINLUPA",
      },
      {
        CITY: "NAGA",
      },
      {
        CITY: "NAGA CITY",
      },
      {
        CITY: "NAGUILIAN",
      },
      {
        CITY: "NAVOTAS",
      },
      {
        CITY: "NAVOTAS",
      },
      {
        CITY: "NAVOTAS",
      },
      {
        CITY: "NEW MANILA",
      },
      {
        CITY: "NORTE",
      },
      {
        CITY: "NOVALICHES",
      },
      {
        CITY: "NUEVA",
      },
      {
        CITY: "OARA",
      },
      {
        CITY: "OBANDO",
      },
      {
        CITY: "OCCIDENTAL",
      },
      {
        CITY: "OLONGAPO CITY",
      },
      {
        CITY: "OLONGAPO CITY",
      },
      {
        CITY: "ORANI",
      },
      {
        CITY: "ORION",
      },
      {
        CITY: "OSMENA",
      },
      {
        CITY: "OZAMIZ CITY",
      },
      {
        CITY: "PACITA",
      },
      {
        CITY: "PADRE GARCIA",
      },
      {
        CITY: "PAETE",
      },
      {
        CITY: "PAGADIAN",
      },
      {
        CITY: "PAGASINAN",
      },
      {
        CITY: "PAGSANJAN",
      },
      {
        CITY: "PALMA GIL",
      },
      {
        CITY: "PALO",
      },
      {
        CITY: "PAMPANGA",
      },
      {
        CITY: "PANABO",
      },
      {
        CITY: "PANDI",
      },
      {
        CITY: "PANGIL",
      },
      {
        CITY: "PANIQUI",
      },
      {
        CITY: "PARANAQUE CITY",
      },
      {
        CITY: "PARDO",
      },
      {
        CITY: "PASAY",
      },
      {
        CITY: "PASIG",
      },
      {
        CITY: "PASIG",
      },
      {
        CITY: "PASONG TAMO",
      },
      {
        CITY: "PATEROS",
      },
      {
        CITY: "PAUL",
      },
      {
        CITY: "PHILIPPINE",
      },
      {
        CITY: "PIAS",
      },
      {
        CITY: "PIKIT",
      },
      {
        CITY: "PINAGLABANAN",
      },
      {
        CITY: "PLARIDEL",
      },
      {
        CITY: "PLARIDEL",
      },
      {
        CITY: "PLAZA",
      },
      {
        CITY: "POBLACION",
      },
      {
        CITY: "POBLACION SAN FELIPE",
      },
      {
        CITY: "PRINCESA",
      },
      {
        CITY: "PROVINCE OF CEBU",
      },
      {
        CITY: "PROVINCE OF LAGUNA",
      },
      {
        CITY: "PROVINCE OF PAMPANGA",
      },
      {
        CITY: "PROVINCE OF PANGASINAN",
      },
      {
        CITY: "PUERTO PRINCESA CITY",
      },
      {
        CITY: "PULILAN",
      },
      {
        CITY: "PUTATAN",
      },
      {
        CITY: "QUEZON",
      },
      {
        CITY: "QUEZON",
      },
      {
        CITY: "QUEZON CITY",
      },
      {
        CITY: "RAMON",
      },
      {
        CITY: "REAL",
      },
      {
        CITY: "RIZAL",
      },
      {
        CITY: "RIZAL",
      },
      {
        CITY: "RODRIGUEZ",
      },
      {
        CITY: "ROOSEVELT",
      },
      {
        CITY: "ROQUE",
      },
      {
        CITY: "ROSALES",
      },
      {
        CITY: "ROSARIO WEST",
      },
      {
        CITY: "ROXAS CITY",
      },
      {
        CITY: "SALCEDO",
      },
      {
        CITY: "SALINAS",
      },
      {
        CITY: "SALLE",
      },
      {
        CITY: "SAMAL",
      },
      {
        CITY: "SAMPALOC",
      },
      {
        CITY: "SAN AGUSTIN",
      },
      {
        CITY: "SAN ANDRES",
      },
      {
        CITY: "SAN ANTONIO",
      },
      {
        CITY: "SAN CARLOS CITY",
      },
      {
        CITY: "SAN FABIAN",
      },
      {
        CITY: "SAN FERNANDO",
      },
      {
        CITY: "SAN FERNANDO",
      },
      {
        CITY: "SAN FRANCISCO",
      },
      {
        CITY: "SAN JOSE",
      },
      {
        CITY: "SAN JOSE",
      },
      {
        CITY: "SAN JOSE DEL MONTE",
      },
      {
        CITY: "SAN JUAN",
      },
      {
        CITY: "SAN MATEO",
      },
      {
        CITY: "SAN MIGUEL",
      },
      {
        CITY: "SAN MIGUEL",
      },
      {
        CITY: "SAN PABLO CITY",
      },
      {
        CITY: "SAN PEDRO",
      },
      {
        CITY: "SAN PEDRO",
      },
      {
        CITY: "SAN VICENTE",
      },
      {
        CITY: "SANTA CRUZ",
      },
      {
        CITY: "SANTA ROSA",
      },
      {
        CITY: "SANTIAGO",
      },
      {
        CITY: "SANTO",
      },
      {
        CITY: "SANTO TOMAS",
      },
      {
        CITY: "SANTOS",
      },
      {
        CITY: "SARIAYA",
      },
      {
        CITY: "SILANG",
      },
      {
        CITY: "SILANG",
      },
      {
        CITY: "SILAY",
      },
      {
        CITY: "SINILOAN",
      },
      {
        CITY: "SMART",
      },
      {
        CITY: "SORSOGON",
      },
      {
        CITY: "STA CRUZ",
      },
      {
        CITY: "STO NINO",
      },
      {
        CITY: "SUBIC",
      },
      {
        CITY: "SUBIC",
      },
      {
        CITY: "SUBIC",
      },
      {
        CITY: "SUCAT",
      },
      {
        CITY: "SULO",
      },
      {
        CITY: "SULTAN KUDARAT",
      },
      {
        CITY: "SUMMIT",
      },
      {
        CITY: "SUYO",
      },
      {
        CITY: "TAAL",
      },
      {
        CITY: "TACLOBAN CITY",
      },
      {
        CITY: "TACURONG",
      },
      {
        CITY: "TAFT",
      },
      {
        CITY: "TAGAYTAY",
      },
      {
        CITY: "TAGBILARAN CITY",
      },
      {
        CITY: "TAGUIG",
      },
      {
        CITY: "TAGUM",
      },
      {
        CITY: "TALISAY CITY",
      },
      {
        CITY: "TALON",
      },
      {
        CITY: "TAMAG",
      },
      {
        CITY: "TAMBLER",
      },
      {
        CITY: "TAMBO",
      },
      {
        CITY: "TANAUAN",
      },
      {
        CITY: "TANAUAN",
      },
      {
        CITY: "TANAY",
      },
      {
        CITY: "TANDANG SORA",
      },
      {
        CITY: "TANZA",
      },
      {
        CITY: "TARLAC CITY",
      },
      {
        CITY: "TAYABAS",
      },
      {
        CITY: "TAYTAY",
      },
      {
        CITY: "TAYUG",
      },
      {
        CITY: "TEJEROS CONVENTION",
      },
      {
        CITY: "TIGBAUAN",
      },
      {
        CITY: "TOLEDO CITY",
      },
      {
        CITY: "TRECE MARTIRES CITY",
      },
      {
        CITY: "TRINIDAD",
      },
      {
        CITY: "TUGUEGARAO CITY",
      },
      {
        CITY: "TUKTUKAN",
      },
      {
        CITY: "UGONG NORTE",
      },
      {
        CITY: "UPPER BICUTAN",
      },
      {
        CITY: "URDANETA",
      },
      {
        CITY: "VALENCIA",
      },
      {
        CITY: "VALENCIA",
      },
      {
        CITY: "VALENZUELA",
      },
      {
        CITY: "VARGAS",
      },
      {
        CITY: "VENTURA",
      },
      {
        CITY: "VERONICA",
      },
      {
        CITY: "VICTORIAS CITY",
      },
      {
        CITY: "VICTORY",
      },
      {
        CITY: "VIGAN",
      },
      {
        CITY: "VILLA",
      },
      {
        CITY: "VILLANUEVA",
      },
      {
        CITY: "VITO",
      },
      {
        CITY: "WEST",
      },
      {
        CITY: "WEST TRIANGLE",
      },
      {
        CITY: "WINES",
      },
      {
        CITY: "ZAMBOANGA CITY",
      },
      {
        CITY: "ZAMBOANGA CITY",
      },
    ],
  },
  {
    COUNTRY: "PAPUA NEW GUINEA",
    CODE: "PG",
    CITIES: [
      {
        CITY: "AITAPE",
      },
      {
        CITY: "ARAWA",
      },
      {
        CITY: "DARU",
      },
      {
        CITY: "GOROKA",
      },
      {
        CITY: "KAVIENG",
      },
      {
        CITY: "KEREMA",
      },
      {
        CITY: "KIKORI",
      },
      {
        CITY: "KIMBE",
      },
      {
        CITY: "KIUNGA",
      },
      {
        CITY: "KOKOPO",
      },
      {
        CITY: "KUNDIAWA",
      },
      {
        CITY: "KUPANO",
      },
      {
        CITY: "LAE",
      },
      {
        CITY: "LORENGAU",
      },
      {
        CITY: "MADANG",
      },
      {
        CITY: "MENDI",
      },
      {
        CITY: "MOUNT HAGEN",
      },
      {
        CITY: "NAMATANAI",
      },
      {
        CITY: "NAMBAGA",
      },
      {
        CITY: "PAIVARA",
      },
      {
        CITY: "PONGANI",
      },
      {
        CITY: "POPONDETTA",
      },
      {
        CITY: "PORT MORESBY",
      },
      {
        CITY: "VANIMO",
      },
      {
        CITY: "WABAG",
      },
      {
        CITY: "WAIGANI",
      },
      {
        CITY: "WEWAK",
      },
    ],
  },
  {
    COUNTRY: "PUERTO RICO",
    CODE: "PR",
    CITIES: [
      {
        CITY: "ADJUNTAS",
      },
      {
        CITY: "AGUADA",
      },
      {
        CITY: "AGUADILLA",
      },
      {
        CITY: "AGUAS BUENAS",
      },
      {
        CITY: "AIBONITO",
      },
      {
        CITY: "ANASCO",
      },
      {
        CITY: "ARECIBO",
      },
      {
        CITY: "ARROYO",
      },
      {
        CITY: "BAJADERO",
      },
      {
        CITY: "BARCELONETA",
      },
      {
        CITY: "BARRANQUITAS",
      },
      {
        CITY: "BOQUERON",
      },
      {
        CITY: "CABO ROJO",
      },
      {
        CITY: "CAGUAS",
      },
      {
        CITY: "CAMUY",
      },
      {
        CITY: "CANOVANAS",
      },
      {
        CITY: "CAROLINA",
      },
      {
        CITY: "CATANO",
      },
      {
        CITY: "CAYEY",
      },
      {
        CITY: "CEIBA",
      },
      {
        CITY: "CIALES",
      },
      {
        CITY: "CIDRA",
      },
      {
        CITY: "COAMO",
      },
      {
        CITY: "COROZAL",
      },
      {
        CITY: "COTO LAUREL",
      },
      {
        CITY: "CULEBRA",
      },
      {
        CITY: "DORADO",
      },
      {
        CITY: "ENSENADA",
      },
      {
        CITY: "FAJARDO",
      },
      {
        CITY: "FLORIDA",
      },
      {
        CITY: "GARROCHALES",
      },
      {
        CITY: "GUANICA",
      },
      {
        CITY: "GUAYAMA",
      },
      {
        CITY: "GUAYANILLA",
      },
      {
        CITY: "GUAYNABO",
      },
      {
        CITY: "GURABO",
      },
      {
        CITY: "HATILLO",
      },
      {
        CITY: "HORMIGUEROS",
      },
      {
        CITY: "HUMACAO",
      },
      {
        CITY: "ISABELA",
      },
      {
        CITY: "JAYUYA",
      },
      {
        CITY: "JUANA DIAZ",
      },
      {
        CITY: "JUNCOS",
      },
      {
        CITY: "LAJAS",
      },
      {
        CITY: "LARES",
      },
      {
        CITY: "LAS PIEDRAS",
      },
      {
        CITY: "LOIZA",
      },
      {
        CITY: "LUQUILLO",
      },
      {
        CITY: "MANATI",
      },
      {
        CITY: "MARICAO",
      },
      {
        CITY: "MAUNABO",
      },
      {
        CITY: "MAYAGUEZ",
      },
      {
        CITY: "MERCEDITA",
      },
      {
        CITY: "MOCA",
      },
      {
        CITY: "MOROVIS",
      },
      {
        CITY: "NAGUABO",
      },
      {
        CITY: "NARANJITO",
      },
      {
        CITY: "OROCOVIS",
      },
      {
        CITY: "PALMER",
      },
      {
        CITY: "PATILLAS",
      },
      {
        CITY: "PENUELAS",
      },
      {
        CITY: "PONCE",
      },
      {
        CITY: "PUNTA SANTIAGO",
      },
      {
        CITY: "QUEBRADILLAS",
      },
      {
        CITY: "RESIDENCIAL PUERTO REAL",
      },
      {
        CITY: "RINCON",
      },
      {
        CITY: "RIO GRANDE",
      },
      {
        CITY: "SABANA GRANDE",
      },
      {
        CITY: "SABANA SECA",
      },
      {
        CITY: "SAINT JUST",
      },
      {
        CITY: "SALINAS",
      },
      {
        CITY: "SAN ANTONIO",
      },
      {
        CITY: "SAN GERMAN",
      },
      {
        CITY: "SAN JUAN",
      },
      {
        CITY: "SAN LORENZO",
      },
      {
        CITY: "SAN SEBASTIAN",
      },
      {
        CITY: "SANTA ISABEL",
      },
      {
        CITY: "TOA ALTA",
      },
      {
        CITY: "TOA BAJA",
      },
      {
        CITY: "TRUJILLO ALTO",
      },
      {
        CITY: "UTUADO",
      },
      {
        CITY: "VEGA ALTA",
      },
      {
        CITY: "VEGA BAJA",
      },
      {
        CITY: "VIEQUES",
      },
      {
        CITY: "VILLALBA",
      },
      {
        CITY: "YABUCOA",
      },
      {
        CITY: "YAUCO",
      },
    ],
  },
  {
    COUNTRY: "SWAZILAND",
    CODE: "SZ",
    CITIES: [
      {
        CITY: "KWALUSENI",
      },
      {
        CITY: "LOBAMBA",
      },
      {
        CITY: "MANZINI",
      },
      {
        CITY: "MBABANE",
      },
      {
        CITY: "PIGGS PEAK",
      },
    ],
  },
  {
    COUNTRY: "PERU",
    CODE: "PE",
    CITIES: [
      {
        CITY: "ABANCAY",
      },
      {
        CITY: "AREQUIPA",
      },
      {
        CITY: "ATE",
      },
      {
        CITY: "AYACUCHO",
      },
      {
        CITY: "BAGUA",
      },
      {
        CITY: "BARRANCA",
      },
      {
        CITY: "BARRANCO",
      },
      {
        CITY: "BELLAVISTA",
      },
      {
        CITY: "BOLIVAR",
      },
      {
        CITY: "CAJAMARCA",
      },
      {
        CITY: "CALLAO",
      },
      {
        CITY: "CALLE",
      },
      {
        CITY: "CARAS",
      },
      {
        CITY: "CERRO DE PASCO",
      },
      {
        CITY: "CHACHAPOYAS",
      },
      {
        CITY: "CHICLAYO",
      },
      {
        CITY: "CHIMBOTE",
      },
      {
        CITY: "CHINCHA",
      },
      {
        CITY: "CUSCO",
      },
      {
        CITY: "CUZCO",
      },
      {
        CITY: "EL AGUSTINO",
      },
      {
        CITY: "HACIENDA LA PALMA",
      },
      {
        CITY: "HUACHO",
      },
      {
        CITY: "HUANCAVELICA",
      },
      {
        CITY: "HUANCAYO",
      },
      {
        CITY: "HUARAL",
      },
      {
        CITY: "HUARAZ",
      },
      {
        CITY: "HUAYTARA",
      },
      {
        CITY: "ICA",
      },
      {
        CITY: "ILO",
      },
      {
        CITY: "IMPERIAL",
      },
      {
        CITY: "IQUITOS",
      },
      {
        CITY: "JAEN",
      },
      {
        CITY: "JESUS MARIA",
      },
      {
        CITY: "JOSE OLAYA",
      },
      {
        CITY: "JULIACA",
      },
      {
        CITY: "JUNIN",
      },
      {
        CITY: "LA MOLINA",
      },
      {
        CITY: "LA PERLA",
      },
      {
        CITY: "LA PUNTA",
      },
      {
        CITY: "LAMBAYEQUE",
      },
      {
        CITY: "LIMA",
      },
      {
        CITY: "LINCE",
      },
      {
        CITY: "LORETO",
      },
      {
        CITY: "LURIGANCHO",
      },
      {
        CITY: "MACHU PICCHU",
      },
      {
        CITY: "MALDONADO",
      },
      {
        CITY: "MINAS DE MARCONA",
      },
      {
        CITY: "MIRAFLORES",
      },
      {
        CITY: "MOQUEGUA",
      },
      {
        CITY: "MOYOBAMBA",
      },
      {
        CITY: "PASCO",
      },
      {
        CITY: "PAUCARPATA",
      },
      {
        CITY: "PIMENTEL",
      },
      {
        CITY: "PISCO",
      },
      {
        CITY: "PIURA",
      },
      {
        CITY: "PROGRESO",
      },
      {
        CITY: "PUCALLPA",
      },
      {
        CITY: "PUERTO INCA",
      },
      {
        CITY: "PUERTO MALDONADO",
      },
      {
        CITY: "PUNO",
      },
      {
        CITY: "RIMAC",
      },
      {
        CITY: "RIMAC",
      },
      {
        CITY: "RIOJA",
      },
      {
        CITY: "SAN BORJA",
      },
      {
        CITY: "SAN ISIDRO",
      },
      {
        CITY: "SAN ISIDRO",
      },
      {
        CITY: "SAN JUAN BAUTISTA",
      },
      {
        CITY: "SAN MARTIN",
      },
      {
        CITY: "SAN MARTIN",
      },
      {
        CITY: "SAN MIGUEL",
      },
      {
        CITY: "SANTA",
      },
      {
        CITY: "SANTIAGO",
      },
      {
        CITY: "SANTIAGO DE SURCO",
      },
      {
        CITY: "SULLANA",
      },
      {
        CITY: "SURCO",
      },
      {
        CITY: "SURQUILLO",
      },
      {
        CITY: "TACNA",
      },
      {
        CITY: "TALARA",
      },
      {
        CITY: "TARAPOTO",
      },
      {
        CITY: "TINGO MARIA",
      },
      {
        CITY: "TRUJILLO",
      },
      {
        CITY: "TUMBES",
      },
      {
        CITY: "VENTANILLA",
      },
      {
        CITY: "VICTORIA",
      },
    ],
  },
  {
    COUNTRY: "REPUBLIC OF KOREA",
    CODE: "KR",
    CITIES: [
      {
        CITY: "ANDONG",
      },
      {
        CITY: "ANSAN-SI",
      },
      {
        CITY: "ANSEONG",
      },
      {
        CITY: "ANYANG-SI",
      },
      {
        CITY: "ASAN",
      },
      {
        CITY: "BUCHEON-SI",
      },
      {
        CITY: "BUSAN",
      },
      {
        CITY: "CHANGWON",
      },
      {
        CITY: "CHEONAN",
      },
      {
        CITY: "CHEONGJU-SI",
      },
      {
        CITY: "CHILGOK",
      },
      {
        CITY: "CHUNCHEON",
      },
      {
        CITY: "CHUNGJU",
      },
      {
        CITY: "CHUNGNIM",
      },
      {
        CITY: "DAEGU",
      },
      {
        CITY: "DAEJEON",
      },
      {
        CITY: "DEOKJIN",
      },
      {
        CITY: "DUCHON",
      },
      {
        CITY: "GANGNEUNG",
      },
      {
        CITY: "GIMHAE",
      },
      {
        CITY: "GONGJU",
      },
      {
        CITY: "GUMI",
      },
      {
        CITY: "GUNNAE",
      },
      {
        CITY: "GWANGJU",
      },
      {
        CITY: "GWANGMYEONG",
      },
      {
        CITY: "GYEONGJU",
      },
      {
        CITY: "GYEONGSAN-SI",
      },
      {
        CITY: "HADONG",
      },
      {
        CITY: "HAESAN",
      },
      {
        CITY: "HAEUNDAE",
      },
      {
        CITY: "HANAM",
      },
      {
        CITY: "HANSOL",
      },
      {
        CITY: "HYANGYANG",
      },
      {
        CITY: "HYOJA-DONG",
      },
      {
        CITY: "ICHEON-SI",
      },
      {
        CITY: "IKSAN",
      },
      {
        CITY: "ILSAN-DONG",
      },
      {
        CITY: "INCHEON",
      },
      {
        CITY: "JANGHOWON",
      },
      {
        CITY: "JECHEON",
      },
      {
        CITY: "JEJU-SI",
      },
      {
        CITY: "JEONJU",
      },
      {
        CITY: "JINJU",
      },
      {
        CITY: "KA-RI",
      },
      {
        CITY: "KIMSO",
      },
      {
        CITY: "KISA",
      },
      {
        CITY: "KOYANG-DONG",
      },
      {
        CITY: "KWANGHUI-DONG",
      },
      {
        CITY: "MAPO-DONG",
      },
      {
        CITY: "MIRYANG",
      },
      {
        CITY: "MOPPO",
      },
      {
        CITY: "NAE-RI",
      },
      {
        CITY: "NAJU",
      },
      {
        CITY: "NAMHYANG-DONG",
      },
      {
        CITY: "NAMYANG",
      },
      {
        CITY: "NAMYANGJU",
      },
      {
        CITY: "NONSAN",
      },
      {
        CITY: "OKPO-DONG",
      },
      {
        CITY: "OSAN",
      },
      {
        CITY: "PAJU",
      },
      {
        CITY: "POHANG",
      },
      {
        CITY: "PON-DONG",
      },
      {
        CITY: "PYONG-GOL",
      },
      {
        CITY: "SAMJUNG-NI",
      },
      {
        CITY: "SAMSUNG",
      },
      {
        CITY: "SANGDO-DONG",
      },
      {
        CITY: "SASANG",
      },
      {
        CITY: "SE-RI",
      },
      {
        CITY: "SEOCHO",
      },
      {
        CITY: "SEONG-DONG",
      },
      {
        CITY: "SEONGNAM-SI",
      },
      {
        CITY: "SEOSAN CITY",
      },
      {
        CITY: "SEOUL",
      },
      {
        CITY: "SONGAM",
      },
      {
        CITY: "SONGANG",
      },
      {
        CITY: "SONGJEONG",
      },
      {
        CITY: "SONGNIM",
      },
      {
        CITY: "SUNCHEON",
      },
      {
        CITY: "SUWON-SI",
      },
      {
        CITY: "TAEBUK",
      },
      {
        CITY: "TANG-NI",
      },
      {
        CITY: "TONGAN",
      },
      {
        CITY: "UIWANG",
      },
      {
        CITY: "ULCHIN",
      },
      {
        CITY: "ULSAN",
      },
      {
        CITY: "UNPO",
      },
      {
        CITY: "WONJU",
      },
      {
        CITY: "WONMI-GU",
      },
      {
        CITY: "YANGSAN",
      },
      {
        CITY: "YEOJU",
      },
      {
        CITY: "YEOSU",
      },
      {
        CITY: "YONGIN",
      },
      {
        CITY: "YONGSAN-DONG",
      },
    ],
  },
  {
    COUNTRY: "SOUTH AFRICA",
    CODE: "ZA",
    CITIES: [
      {
        CITY: "ALBERTON",
      },
      {
        CITY: "ALICE",
      },
      {
        CITY: "ALRODE",
      },
      {
        CITY: "AMANZIMTOTI",
      },
      {
        CITY: "ASHTON",
      },
      {
        CITY: "ATLANTIS",
      },
      {
        CITY: "BALFOUR",
      },
      {
        CITY: "BATHURST",
      },
      {
        CITY: "BEAUFORT WEST",
      },
      {
        CITY: "BEDFORDVIEW",
      },
      {
        CITY: "BELHAR",
      },
      {
        CITY: "BELLVILLE",
      },
      {
        CITY: "BENONI",
      },
      {
        CITY: "BERGVLIET",
      },
      {
        CITY: "BETHAL",
      },
      {
        CITY: "BETHLEHEM",
      },
      {
        CITY: "BLOEMFONTEIN",
      },
      {
        CITY: "BOKSBURG",
      },
      {
        CITY: "BOTHASIG",
      },
      {
        CITY: "BOTSHABELO",
      },
      {
        CITY: "BRAAMFONTEIN",
      },
      {
        CITY: "BRACKENFELL",
      },
      {
        CITY: "BRAKPAN",
      },
      {
        CITY: "BREDASDORP",
      },
      {
        CITY: "BRITS",
      },
      {
        CITY: "BRONKHORSTSPRUIT",
      },
      {
        CITY: "BROOKLYN",
      },
      {
        CITY: "BRYANSTON",
      },
      {
        CITY: "BUFFALO",
      },
      {
        CITY: "BUTTERWORTH",
      },
      {
        CITY: "CAPE TOWN",
      },
      {
        CITY: "CAPITAL PARK",
      },
      {
        CITY: "CARLETONVILLE",
      },
      {
        CITY: "CAROLINA",
      },
      {
        CITY: "CENTURION",
      },
      {
        CITY: "CITY OF CAPE TOWN",
      },
      {
        CITY: "CLANWILLIAM",
      },
      {
        CITY: "CLAREMONT",
      },
      {
        CITY: "COEGA",
      },
      {
        CITY: "COLIGNY",
      },
      {
        CITY: "CONSTANTIA",
      },
      {
        CITY: "CRAIGHALL",
      },
      {
        CITY: "CULLINAN",
      },
      {
        CITY: "DE AAR",
      },
      {
        CITY: "DELMAS",
      },
      {
        CITY: "DENEYSVILLE",
      },
      {
        CITY: "DESPATCH",
      },
      {
        CITY: "DOORNFONTEIN",
      },
      {
        CITY: "DUBE LOCATION",
      },
      {
        CITY: "DUIWELSKLOOF",
      },
      {
        CITY: "DULLSTROOM",
      },
      {
        CITY: "DUNDEE",
      },
      {
        CITY: "DURBAN",
      },
      {
        CITY: "DURBANVILLE",
      },
      {
        CITY: "EAST LONDON",
      },
      {
        CITY: "EDENBURG",
      },
      {
        CITY: "EDENVALE",
      },
      {
        CITY: "EERSTERIVIER",
      },
      {
        CITY: "ELANDSFONTEIN",
      },
      {
        CITY: "ELARDUSPARK",
      },
      {
        CITY: "ELOFF",
      },
      {
        CITY: "ELSBURG",
      },
      {
        CITY: "ELSENBURG",
      },
      {
        CITY: "EMERALD HILL",
      },
      {
        CITY: "EMNAMBITHI-LADYSMITH",
      },
      {
        CITY: "EMPANGENI",
      },
      {
        CITY: "ERASMIA",
      },
      {
        CITY: "ERMELO",
      },
      {
        CITY: "ESHOWE",
      },
      {
        CITY: "EVANDER",
      },
      {
        CITY: "FAURE",
      },
      {
        CITY: "FLORIDA GLEN",
      },
      {
        CITY: "FOCHVILLE",
      },
      {
        CITY: "FORDSBURG",
      },
      {
        CITY: "FORT COX",
      },
      {
        CITY: "FRANSCHHOEK",
      },
      {
        CITY: "GANSBAAI",
      },
      {
        CITY: "GANSPAN",
      },
      {
        CITY: "GEORGE",
      },
      {
        CITY: "GERMISTON",
      },
      {
        CITY: "GEZINA",
      },
      {
        CITY: "GILLITTS",
      },
      {
        CITY: "GINGINDLOVU",
      },
      {
        CITY: "GLEN AUSTIN",
      },
      {
        CITY: "GOODWOOD",
      },
      {
        CITY: "GRAAFF REINET",
      },
      {
        CITY: "GRABOUW",
      },
      {
        CITY: "GRAHAMSTOWN",
      },
      {
        CITY: "GREYTOWN",
      },
      {
        CITY: "GROBLERSDAL",
      },
      {
        CITY: "HAENERTSBURG",
      },
      {
        CITY: "HARRISMITH",
      },
      {
        CITY: "HARTBEESPOORT",
      },
      {
        CITY: "HARTENBOS",
      },
      {
        CITY: "HARTSWATER",
      },
      {
        CITY: "HATFIELD",
      },
      {
        CITY: "HAWSTON",
      },
      {
        CITY: "HAZYVIEW",
      },
      {
        CITY: "HEIDELBERG",
      },
      {
        CITY: "HEKPOORT",
      },
      {
        CITY: "HENLEY ON KLIP",
      },
      {
        CITY: "HENNENMAN",
      },
      {
        CITY: "HERMANUS",
      },
      {
        CITY: "HILLCREST",
      },
      {
        CITY: "HILTON",
      },
      {
        CITY: "HIMEVILLE",
      },
      {
        CITY: "HLUHLUWE",
      },
      {
        CITY: "HOEDSPRUIT",
      },
      {
        CITY: "HOGSBACK",
      },
      {
        CITY: "HOUGHTON ESTATE",
      },
      {
        CITY: "HOUT BAY",
      },
      {
        CITY: "HOWICK",
      },
      {
        CITY: "HUMANSDORP",
      },
      {
        CITY: "HYDE PARK",
      },
      {
        CITY: "IRENE",
      },
      {
        CITY: "ISANDO",
      },
      {
        CITY: "IXOPO",
      },
      {
        CITY: "JOHANNESBURG",
      },
      {
        CITY: "KALKBAAI",
      },
      {
        CITY: "KATLEHONG",
      },
      {
        CITY: "KEMPTON PARK",
      },
      {
        CITY: "KIMBERLEY",
      },
      {
        CITY: "KINGSBOROUGH",
      },
      {
        CITY: "KINGSLEY",
      },
      {
        CITY: "KINGSMEAD",
      },
      {
        CITY: "KINROSS",
      },
      {
        CITY: "KLEINMOND",
      },
      {
        CITY: "KLERKSDORP",
      },
      {
        CITY: "KLOOF",
      },
      {
        CITY: "KNYSNA",
      },
      {
        CITY: "KOKSTAD",
      },
      {
        CITY: "KRAAIFONTEIN",
      },
      {
        CITY: "KRANSKOP",
      },
      {
        CITY: "KRIEL",
      },
      {
        CITY: "KROONSTAD",
      },
      {
        CITY: "KRUGERSDORP",
      },
      {
        CITY: "KUILS RIVER",
      },
      {
        CITY: "KWADUKUZA",
      },
      {
        CITY: "LA LUCIA",
      },
      {
        CITY: "LA MOTTE",
      },
      {
        CITY: "LADYBRAND",
      },
      {
        CITY: "LANSDOWNE",
      },
      {
        CITY: "LAUDIUM",
      },
      {
        CITY: "LEPHALALE",
      },
      {
        CITY: "LICHTENBURG",
      },
      {
        CITY: "LOMBARDY EAST",
      },
      {
        CITY: "LONDON",
      },
      {
        CITY: "LOUIS TRICHARDT",
      },
      {
        CITY: "LUTZVILLE",
      },
      {
        CITY: "LYDENBURG",
      },
      {
        CITY: "LYNDHURST",
      },
      {
        CITY: "LYNNWOOD",
      },
      {
        CITY: "LYTTELTON",
      },
      {
        CITY: "MACHADODORP",
      },
      {
        CITY: "MAFIKENG",
      },
      {
        CITY: "MAIDSTONE",
      },
      {
        CITY: "MAJUBA",
      },
      {
        CITY: "MALMESBURY",
      },
      {
        CITY: "MALVERN",
      },
      {
        CITY: "MARAISBURG",
      },
      {
        CITY: "MARGATE",
      },
      {
        CITY: "MARSHALLTOWN",
      },
      {
        CITY: "MEADOWRIDGE",
      },
      {
        CITY: "MEDUNSA",
      },
      {
        CITY: "MELKBOSSTRAND",
      },
      {
        CITY: "MERRIVALE",
      },
      {
        CITY: "MESSINA",
      },
      {
        CITY: "MEYERTON",
      },
      {
        CITY: "MIDDELBURG",
      },
      {
        CITY: "MIDDELBURG",
      },
      {
        CITY: "MIDRAND",
      },
      {
        CITY: "MILNERTON",
      },
      {
        CITY: "MMABATHO",
      },
      {
        CITY: "MOBENI",
      },
      {
        CITY: "MODDERFONTEIN",
      },
      {
        CITY: "MOKOPANE",
      },
      {
        CITY: "MONTAGU",
      },
      {
        CITY: "MONUMENT PARK",
      },
      {
        CITY: "MOOI RIVER",
      },
      {
        CITY: "MOOKGOPHONG",
      },
      {
        CITY: "MOORREESBURG",
      },
      {
        CITY: "MORNINGSIDE MANOR",
      },
      {
        CITY: "MOSSEL BAY",
      },
      {
        CITY: "MOUNT EDGECOMBE",
      },
      {
        CITY: "MTHATHA",
      },
      {
        CITY: "MUIZENBERG",
      },
      {
        CITY: "NAPIER",
      },
      {
        CITY: "NELSPRUIT",
      },
      {
        CITY: "NEW GERMANY",
      },
      {
        CITY: "NEWCASTLE",
      },
      {
        CITY: "NEWLANDS",
      },
      {
        CITY: "NIGEL",
      },
      {
        CITY: "NORTHCLIFF",
      },
      {
        CITY: "ODENDAALSRUS",
      },
      {
        CITY: "OGIES",
      },
      {
        CITY: "OLIFANTSFONTEIN",
      },
      {
        CITY: "ONDERSTEPOORT",
      },
      {
        CITY: "ONVERWACHT",
      },
      {
        CITY: "ORANJEVILLE",
      },
      {
        CITY: "ORANJEZICHT",
      },
      {
        CITY: "OTTOSDAL",
      },
      {
        CITY: "OUDTSHOORN",
      },
      {
        CITY: "OVERPORT",
      },
      {
        CITY: "PAARL",
      },
      {
        CITY: "PANORAMA",
      },
      {
        CITY: "PARKTOWN",
      },
      {
        CITY: "PARKVIEW",
      },
      {
        CITY: "PAROW",
      },
      {
        CITY: "PENINSULA",
      },
      {
        CITY: "PHALABORWA",
      },
      {
        CITY: "PHILADELPHIA",
      },
      {
        CITY: "PHILIPSTOWN",
      },
      {
        CITY: "PHOENIX",
      },
      {
        CITY: "PHOKENG",
      },
      {
        CITY: "PIETERMARITZBURG",
      },
      {
        CITY: "PINELANDS",
      },
      {
        CITY: "PINETOWN",
      },
      {
        CITY: "PLETTENBERG BAY",
      },
      {
        CITY: "PLUMSTEAD",
      },
      {
        CITY: "POLOKWANE",
      },
      {
        CITY: "PONGOLA",
      },
      {
        CITY: "PORT ALFRED",
      },
      {
        CITY: "PORT ELIZABETH",
      },
      {
        CITY: "PORT SHEPSTONE",
      },
      {
        CITY: "POTCHEFSTROOM",
      },
      {
        CITY: "PRETORIA",
      },
      {
        CITY: "PRIESKA",
      },
      {
        CITY: "PRIMROSE",
      },
      {
        CITY: "PRINGLE BAY",
      },
      {
        CITY: "QUEENSBURGH",
      },
      {
        CITY: "QUEENSTOWN",
      },
      {
        CITY: "QUEENSWOOD",
      },
      {
        CITY: "RANDBURG",
      },
      {
        CITY: "RANDFONTEIN",
      },
      {
        CITY: "RAWSONVILLE",
      },
      {
        CITY: "RHODES",
      },
      {
        CITY: "RICHARDS BAY",
      },
      {
        CITY: "RICHMOND",
      },
      {
        CITY: "RIEBEEK-KASTEEL",
      },
      {
        CITY: "RIVONIA",
      },
      {
        CITY: "ROGGEBAAI",
      },
      {
        CITY: "ROODEPOORT",
      },
      {
        CITY: "ROOIGROND",
      },
      {
        CITY: "ROOIHUISKRAAL",
      },
      {
        CITY: "ROSEBANK",
      },
      {
        CITY: "ROSETTENVILLE",
      },
      {
        CITY: "ROSSLYN",
      },
      {
        CITY: "RUSTENBURG",
      },
      {
        CITY: "SABIE",
      },
      {
        CITY: "SAINT HELENA BAY",
      },
      {
        CITY: "SAINT JAMES",
      },
      {
        CITY: "SALDANHA",
      },
      {
        CITY: "SALT ROCK",
      },
      {
        CITY: "SANDOWN",
      },
      {
        CITY: "SANDTON",
      },
      {
        CITY: "SASOLBURG",
      },
      {
        CITY: "SCHWEIZER-RENEKE",
      },
      {
        CITY: "SCOTTBURGH",
      },
      {
        CITY: "SEBOKENG",
      },
      {
        CITY: "SECUNDA",
      },
      {
        CITY: "SEDGEFIELD",
      },
      {
        CITY: "SENEKAL",
      },
      {
        CITY: "SHELLY BEACH",
      },
      {
        CITY: "SILVERTON",
      },
      {
        CITY: "SINOVILLE",
      },
      {
        CITY: "SOMERSET WEST",
      },
      {
        CITY: "SOSHANGUVE",
      },
      {
        CITY: "SOWETO",
      },
      {
        CITY: "SPRINGS",
      },
      {
        CITY: "STANDERTON",
      },
      {
        CITY: "STANFORD",
      },
      {
        CITY: "STELLENBOSCH",
      },
      {
        CITY: "STILFONTEIN",
      },
      {
        CITY: "STRAND",
      },
      {
        CITY: "SUN VALLEY",
      },
      {
        CITY: "SWARTKOPS",
      },
      {
        CITY: "TEMBA",
      },
      {
        CITY: "TEMBISA",
      },
      {
        CITY: "THABAZIMBI",
      },
      {
        CITY: "THE REST",
      },
      {
        CITY: "THOHOYANDOU",
      },
      {
        CITY: "THREE ANCHOR BAY",
      },
      {
        CITY: "THREE RIVERS",
      },
      {
        CITY: "TOKAI",
      },
      {
        CITY: "TONGAAT",
      },
      {
        CITY: "TZANEEN",
      },
      {
        CITY: "UITENHAGE",
      },
      {
        CITY: "UMBOGINTWINI",
      },
      {
        CITY: "UMDLOTI",
      },
      {
        CITY: "UMHLANGA",
      },
      {
        CITY: "UMKOMAAS",
      },
      {
        CITY: "UMTENTWENI",
      },
      {
        CITY: "UPINGTON",
      },
      {
        CITY: "VAALBANK",
      },
      {
        CITY: "VAALPARK",
      },
      {
        CITY: "VANDERBIJLPARK",
      },
      {
        CITY: "VEREENIGING",
      },
      {
        CITY: "VERULAM",
      },
      {
        CITY: "VILLIERSDORP",
      },
      {
        CITY: "VIRGINIA",
      },
      {
        CITY: "VOSLOORUS",
      },
      {
        CITY: "VREDEDORP",
      },
      {
        CITY: "VREDENBURG",
      },
      {
        CITY: "VRYHEID",
      },
      {
        CITY: "WALKERVILLE",
      },
      {
        CITY: "WALMER HEIGHTS",
      },
      {
        CITY: "WARNER BEACH",
      },
      {
        CITY: "WARTBURG",
      },
      {
        CITY: "WAVERLEY",
      },
      {
        CITY: "WAVERLEY",
      },
      {
        CITY: "WELGELEGEN",
      },
      {
        CITY: "WELGEMOED",
      },
      {
        CITY: "WELKOM",
      },
      {
        CITY: "WELKOM",
      },
      {
        CITY: "WELLINGTON",
      },
      {
        CITY: "WELTEVREDEN",
      },
      {
        CITY: "WELTEVREEDENPARK",
      },
      {
        CITY: "WESTONARIA",
      },
      {
        CITY: "WESTVILLE",
      },
      {
        CITY: "WHITE RIVER",
      },
      {
        CITY: "WILDERNESS",
      },
      {
        CITY: "WINTERTON",
      },
      {
        CITY: "WITBANK",
      },
      {
        CITY: "WITPOORTJIE",
      },
      {
        CITY: "WONDERBOOM",
      },
      {
        CITY: "WORCESTER",
      },
      {
        CITY: "WYNBERG",
      },
      {
        CITY: "ZEERUST",
      },
    ],
  },
  {
    COUNTRY: "REPUBLIC OF MOLDOVA",
    CODE: "MD",
    CITIES: [
      {
        CITY: "ANENII NOI",
      },
      {
        CITY: "BRICENI",
      },
      {
        CITY: "CAHUL",
      },
      {
        CITY: "CALARASI",
      },
      {
        CITY: "CIORESCU",
      },
      {
        CITY: "COJUSNA",
      },
      {
        CITY: "COMRAT",
      },
      {
        CITY: "DROCHIA",
      },
      {
        CITY: "DURLESTI",
      },
      {
        CITY: "IALOVENI",
      },
      {
        CITY: "MAGDACESTI",
      },
      {
        CITY: "NISPORENI",
      },
      {
        CITY: "ORHEI",
      },
      {
        CITY: "SOROCA",
      },
      {
        CITY: "STRASENI",
      },
      {
        CITY: "TIGHINA",
      },
      {
        CITY: "TIRASPOL",
      },
      {
        CITY: "VOINESCU",
      },
      {
        CITY: "VULCANESTI",
      },
    ],
  },
  {
    COUNTRY: "SAINT LUCIA",
    CODE: "LC",
    CITIES: [
      {
        CITY: "ANSE LA RAYE",
      },
      {
        CITY: "CASTRIES",
      },
      {
        CITY: "CHOISEUL",
      },
      {
        CITY: "DAUPHIN",
      },
      {
        CITY: "GROS ISLET",
      },
      {
        CITY: "VIEUX FORT",
      },
    ],
  },
  {
    COUNTRY: "SPAIN",
    CODE: "ES",
    CITIES: [
      {
        CITY: "A CIDADE",
      },
      {
        CITY: "A ESTRADA",
      },
      {
        CITY: "A POBRA DO CARAMINAL",
      },
      {
        CITY: "ABADINO",
      },
      {
        CITY: "ABANILLA",
      },
      {
        CITY: "ABANTO",
      },
      {
        CITY: "ABARAN",
      },
      {
        CITY: "ABEGONDO",
      },
      {
        CITY: "ABRERA",
      },
      {
        CITY: "ACALA DEL RIO",
      },
      {
        CITY: "ACEUCHAL",
      },
      {
        CITY: "ADEJE",
      },
      {
        CITY: "ADOR",
      },
      {
        CITY: "ADRA",
      },
      {
        CITY: "ADRALL",
      },
      {
        CITY: "ADUNA",
      },
      {
        CITY: "AGAETE",
      },
      {
        CITY: "AGONCILLO",
      },
      {
        CITY: "AGOST",
      },
      {
        CITY: "AGRAMUNT",
      },
      {
        CITY: "AGREDA",
      },
      {
        CITY: "AGUADULCE",
      },
      {
        CITY: "AGUILAFUENTE",
      },
      {
        CITY: "AGUILAR",
      },
      {
        CITY: "AGUILAR DE CAMPOO",
      },
      {
        CITY: "AGUILAR DEL RIO ALHAMA",
      },
      {
        CITY: "AGULLANA",
      },
      {
        CITY: "AGULLENT",
      },
      {
        CITY: "AHIGAL",
      },
      {
        CITY: "AIELO DE MALFERIT",
      },
      {
        CITY: "AIGUAFREDA",
      },
      {
        CITY: "AIZARNAZABAL",
      },
      {
        CITY: "AIZOAIN",
      },
      {
        CITY: "AJALVIR",
      },
      {
        CITY: "AJO",
      },
      {
        CITY: "AJOFRIN",
      },
      {
        CITY: "ALAEJOS",
      },
      {
        CITY: "ALAGON",
      },
      {
        CITY: "ALAMEDA",
      },
      {
        CITY: "ALANIS",
      },
      {
        CITY: "ALAVA",
      },
      {
        CITY: "ALBA DE TORMES",
      },
      {
        CITY: "ALBACETE",
      },
      {
        CITY: "ALBAIDA",
      },
      {
        CITY: "ALBAL",
      },
      {
        CITY: "ALBALAT DELS SORELLS",
      },
      {
        CITY: "ALBALAT DELS TARONGERS",
      },
      {
        CITY: "ALBALATE DE CINCA",
      },
      {
        CITY: "ALBARREAL DE TAJO",
      },
      {
        CITY: "ALBATERA",
      },
      {
        CITY: "ALBELDA DE IREGUA",
      },
      {
        CITY: "ALBENDIN",
      },
      {
        CITY: "ALBERIC",
      },
      {
        CITY: "ALBERITE",
      },
      {
        CITY: "ALBOLOTE",
      },
      {
        CITY: "ALBORAYA",
      },
      {
        CITY: "ALBOX",
      },
      {
        CITY: "ALBUIXECH",
      },
      {
        CITY: "ALBUJON",
      },
      {
        CITY: "ALBURQUERQUE",
      },
      {
        CITY: "ALCALA DE XIVERT",
      },
      {
        CITY: "ALCALA DEL OBISPO",
      },
      {
        CITY: "ALCALA LA REAL",
      },
      {
        CITY: "ALCANADRE",
      },
      {
        CITY: "ALCANAR",
      },
      {
        CITY: "ALCANTARA",
      },
      {
        CITY: "ALCANTARILLA",
      },
      {
        CITY: "ALCARRAS",
      },
      {
        CITY: "ALCAZAR DE SAN JUAN",
      },
      {
        CITY: "ALCOBENDAS",
      },
      {
        CITY: "ALCOLETGE",
      },
      {
        CITY: "ALCORISA",
      },
      {
        CITY: "ALCOSSEBRE",
      },
      {
        CITY: "ALCOVER",
      },
      {
        CITY: "ALCOY",
      },
      {
        CITY: "ALDAIA",
      },
      {
        CITY: "ALDEALSENOR",
      },
      {
        CITY: "ALDEAMAYOR DE SAN MARTIN",
      },
      {
        CITY: "ALDEANUEVA",
      },
      {
        CITY: "ALDEANUEVA DE EBRO",
      },
      {
        CITY: "ALDEASECA DE ARMUNA",
      },
      {
        CITY: "ALDEATEJADA",
      },
      {
        CITY: "ALEGRIA",
      },
      {
        CITY: "ALELLA",
      },
      {
        CITY: "ALFAFAR",
      },
      {
        CITY: "ALFAJARIN",
      },
      {
        CITY: "ALFAMBRA",
      },
      {
        CITY: "ALFARA DE ALGIMIA",
      },
      {
        CITY: "ALFARA DEL PATRIARCA",
      },
      {
        CITY: "ALFARO",
      },
      {
        CITY: "ALFARRASI",
      },
      {
        CITY: "ALFONDEGUILLA",
      },
      {
        CITY: "ALFORJA",
      },
      {
        CITY: "ALGADEFE",
      },
      {
        CITY: "ALGAIDA",
      },
      {
        CITY: "ALGAR",
      },
      {
        CITY: "ALGARROBO",
      },
      {
        CITY: "ALGECIRAS",
      },
      {
        CITY: "ALGEMESI",
      },
      {
        CITY: "ALGETE",
      },
      {
        CITY: "ALGEZARES",
      },
      {
        CITY: "ALGINET",
      },
      {
        CITY: "ALGODONALES",
      },
      {
        CITY: "ALGORTA",
      },
      {
        CITY: "ALGUAZAS",
      },
      {
        CITY: "ALHAMA DE ARAGON",
      },
      {
        CITY: "ALHAMA DE GRANADA",
      },
      {
        CITY: "ALHAMA DE MURCIA",
      },
      {
        CITY: "ALHAMBRA",
      },
      {
        CITY: "ALHAURIN DE LA TORRE",
      },
      {
        CITY: "ALHAURIN EL GRANDE",
      },
      {
        CITY: "ALHENDIN",
      },
      {
        CITY: "ALICANTE",
      },
      {
        CITY: "ALISEDA",
      },
      {
        CITY: "ALJARAQUE",
      },
      {
        CITY: "ALLARIZ",
      },
      {
        CITY: "ALMAGRO",
      },
      {
        CITY: "ALMANSA",
      },
      {
        CITY: "ALMARGEN",
      },
      {
        CITY: "ALMASSERA",
      },
      {
        CITY: "ALMASSORA",
      },
      {
        CITY: "ALMAZAN",
      },
      {
        CITY: "ALMAZCARA",
      },
      {
        CITY: "ALMENAR",
      },
      {
        CITY: "ALMENARA",
      },
      {
        CITY: "ALMENDRALEJO",
      },
      {
        CITY: "ALMENSILLA",
      },
      {
        CITY: "ALMODOVAR DEL CAMPO",
      },
      {
        CITY: "ALMODOVAR DEL RIO",
      },
      {
        CITY: "ALMONACID DE ZORITA",
      },
      {
        CITY: "ALMONTE",
      },
      {
        CITY: "ALMORADI",
      },
      {
        CITY: "ALMOSTER",
      },
      {
        CITY: "ALMUDEBAR",
      },
      {
        CITY: "ALMUNA",
      },
      {
        CITY: "ALONSOTEGI",
      },
      {
        CITY: "ALORA",
      },
      {
        CITY: "ALOSNO",
      },
      {
        CITY: "ALOVERA",
      },
      {
        CITY: "ALOZAINA",
      },
      {
        CITY: "ALPEDRETE",
      },
      {
        CITY: "ALPICAT",
      },
      {
        CITY: "ALQUERIAS",
      },
      {
        CITY: "ALTA",
      },
      {
        CITY: "ALTAFULLA",
      },
      {
        CITY: "ALTEA",
      },
      {
        CITY: "ALTET",
      },
      {
        CITY: "ALTURA",
      },
      {
        CITY: "ALUMBRES",
      },
      {
        CITY: "ALZA",
      },
      {
        CITY: "ALZIRA",
      },
      {
        CITY: "AMAVIDA",
      },
      {
        CITY: "AMER",
      },
      {
        CITY: "AMES",
      },
      {
        CITY: "AMETLLA",
      },
      {
        CITY: "AMOREBIETA",
      },
      {
        CITY: "AMPOSTA",
      },
      {
        CITY: "AMPUERO",
      },
      {
        CITY: "AMURRIO",
      },
      {
        CITY: "ANCHUELO",
      },
      {
        CITY: "ANDEIRO",
      },
      {
        CITY: "ANDOAIN",
      },
      {
        CITY: "ANDORRA",
      },
      {
        CITY: "ANDOSILLA",
      },
      {
        CITY: "ANDUJAR",
      },
      {
        CITY: "ANGLESOLA",
      },
      {
        CITY: "ANGUCIANA",
      },
      {
        CITY: "ANOETA",
      },
      {
        CITY: "ANORA",
      },
      {
        CITY: "ANORBE",
      },
      {
        CITY: "ANTAS",
      },
      {
        CITY: "ANTEQUERA",
      },
      {
        CITY: "ANTIGUA",
      },
      {
        CITY: "ARACENA",
      },
      {
        CITY: "ARAFO",
      },
      {
        CITY: "ARAMA",
      },
      {
        CITY: "ARANDA DE DUERO",
      },
      {
        CITY: "ARANGUREN",
      },
      {
        CITY: "ARANJUEZ",
      },
      {
        CITY: "ARAVACA",
      },
      {
        CITY: "ARBIZU",
      },
      {
        CITY: "ARBO",
      },
      {
        CITY: "ARBOLEAS",
      },
      {
        CITY: "ARCA",
      },
      {
        CITY: "ARCADE",
      },
      {
        CITY: "ARCE",
      },
      {
        CITY: "ARCHENA",
      },
      {
        CITY: "ARCHIDONA",
      },
      {
        CITY: "ARCICOLLAR",
      },
      {
        CITY: "ARCOS DE LA FRONTERA",
      },
      {
        CITY: "ARENAS DE SAN PEDRO",
      },
      {
        CITY: "ARENYS DE MAR",
      },
      {
        CITY: "ARENYS DE MUNT",
      },
      {
        CITY: "ARES",
      },
      {
        CITY: "AREVALO",
      },
      {
        CITY: "ARGAMASILLA DE ALBA",
      },
      {
        CITY: "ARGAMASILLA DE CALATRAVA",
      },
      {
        CITY: "ARGANDA",
      },
      {
        CITY: "ARGELAGUER",
      },
      {
        CITY: "ARGENTONA",
      },
      {
        CITY: "ARGES",
      },
      {
        CITY: "ARGONOS",
      },
      {
        CITY: "ARICO",
      },
      {
        CITY: "ARINO",
      },
      {
        CITY: "ARJONA",
      },
      {
        CITY: "ARMILLA",
      },
      {
        CITY: "ARMUNIA",
      },
      {
        CITY: "ARNEDILLO",
      },
      {
        CITY: "ARNEDO",
      },
      {
        CITY: "ARNUERO",
      },
      {
        CITY: "AROCHE",
      },
      {
        CITY: "ARONA",
      },
      {
        CITY: "ARRECIFE",
      },
      {
        CITY: "ARRIATE",
      },
      {
        CITY: "ARRIGORRIAGA",
      },
      {
        CITY: "ARROYAL",
      },
      {
        CITY: "ARROYO",
      },
      {
        CITY: "ARROYO DE LA LUZ",
      },
      {
        CITY: "ARROYO DE LA MIEL",
      },
      {
        CITY: "ARROYO DEL OJANCO",
      },
      {
        CITY: "ARROYOMOLINOS",
      },
      {
        CITY: "ARRUBAL",
      },
      {
        CITY: "ARTAJO",
      },
      {
        CITY: "ARTAJONA",
      },
      {
        CITY: "ARTANA",
      },
      {
        CITY: "ARTEIXO",
      },
      {
        CITY: "ARTENARA",
      },
      {
        CITY: "ARTES",
      },
      {
        CITY: "ARTESA DE SEGRE",
      },
      {
        CITY: "ARUCAS",
      },
      {
        CITY: "ARZUA",
      },
      {
        CITY: "AS PONTES DE GARCIA RODRIGUEZ",
      },
      {
        CITY: "ASPE",
      },
      {
        CITY: "ASTEASU",
      },
      {
        CITY: "ASTIGARRAGA",
      },
      {
        CITY: "ASTORGA",
      },
      {
        CITY: "ASTRABUDUA",
      },
      {
        CITY: "ASTRAIN",
      },
      {
        CITY: "ASTUDILLO",
      },
      {
        CITY: "ATARFE",
      },
      {
        CITY: "ATARRABIA",
      },
      {
        CITY: "ATAUN",
      },
      {
        CITY: "ATECA",
      },
      {
        CITY: "AUTOL",
      },
      {
        CITY: "AVIA",
      },
      {
        CITY: "AVINYONET DEL PENEDES",
      },
      {
        CITY: "AXPE",
      },
      {
        CITY: "AYAMONTE",
      },
      {
        CITY: "AYEGUI",
      },
      {
        CITY: "AZAGRA",
      },
      {
        CITY: "AZKOITIA",
      },
      {
        CITY: "AZNALCAZAR",
      },
      {
        CITY: "AZNALCOLLAR",
      },
      {
        CITY: "AZPEITIA",
      },
      {
        CITY: "AZUAGA",
      },
      {
        CITY: "AZUQUECA DE HENARES",
      },
      {
        CITY: "BABILAFUENTE",
      },
      {
        CITY: "BADAJOZ",
      },
      {
        CITY: "BADALONA",
      },
      {
        CITY: "BAENA",
      },
      {
        CITY: "BAEZA",
      },
      {
        CITY: "BAGA",
      },
      {
        CITY: "BAILEN",
      },
      {
        CITY: "BAINA",
      },
      {
        CITY: "BAJAMAR",
      },
      {
        CITY: "BALAGUER",
      },
      {
        CITY: "BALAZOTE",
      },
      {
        CITY: "BALENYA",
      },
      {
        CITY: "BALERMA",
      },
      {
        CITY: "BALSARENY",
      },
      {
        CITY: "BALSICAS",
      },
      {
        CITY: "BANUGUES",
      },
      {
        CITY: "BARA",
      },
      {
        CITY: "BARAKALDO",
      },
      {
        CITY: "BARANAIN",
      },
      {
        CITY: "BARBARROJA",
      },
      {
        CITY: "BARBASTRO",
      },
      {
        CITY: "BARBATE",
      },
      {
        CITY: "BARCARROTA",
      },
      {
        CITY: "BARCELONA",
      },
      {
        CITY: "BARCENA DE CICERO",
      },
      {
        CITY: "BARGAS",
      },
      {
        CITY: "BARRANDA",
      },
      {
        CITY: "BARROS",
      },
      {
        CITY: "BAS",
      },
      {
        CITY: "BASAURI",
      },
      {
        CITY: "BATRES",
      },
      {
        CITY: "BAZA",
      },
      {
        CITY: "BEAS",
      },
      {
        CITY: "BEAS DE SEGURA",
      },
      {
        CITY: "BECERRIL DE LA SIERRA",
      },
      {
        CITY: "BEDMAR",
      },
      {
        CITY: "BEGIJAR",
      },
      {
        CITY: "BEGIS",
      },
      {
        CITY: "BEGUES",
      },
      {
        CITY: "BEGUR",
      },
      {
        CITY: "BEJAR",
      },
      {
        CITY: "BELGIDA",
      },
      {
        CITY: "BELICENA",
      },
      {
        CITY: "BELLPUIG",
      },
      {
        CITY: "BELLVEI",
      },
      {
        CITY: "BELLVER DE CERDANYA",
      },
      {
        CITY: "BELLVIS",
      },
      {
        CITY: "BELVIS DE JARAMA",
      },
      {
        CITY: "BELVIS DE LA JARA",
      },
      {
        CITY: "BEMBIBRE",
      },
      {
        CITY: "BENACAZON",
      },
      {
        CITY: "BENAGALBON",
      },
      {
        CITY: "BENAHADUX",
      },
      {
        CITY: "BENAHAVIS",
      },
      {
        CITY: "BENAMARGOSA",
      },
      {
        CITY: "BENAMEJI",
      },
      {
        CITY: "BENATAE",
      },
      {
        CITY: "BENAVARRI / BENABARRE",
      },
      {
        CITY: "BENAVENTE",
      },
      {
        CITY: "BENAVIDES",
      },
      {
        CITY: "BENEJUZAR",
      },
      {
        CITY: "BENIAJAN",
      },
      {
        CITY: "BENIARBEIG",
      },
      {
        CITY: "BENIARJO",
      },
      {
        CITY: "BENIARRES",
      },
      {
        CITY: "BENICASSIM",
      },
      {
        CITY: "BENICOLET",
      },
      {
        CITY: "BENIDOLEIG",
      },
      {
        CITY: "BENIDORM",
      },
      {
        CITY: "BENIEL",
      },
      {
        CITY: "BENIFAIRO DE LES VALLS",
      },
      {
        CITY: "BENIFATO",
      },
      {
        CITY: "BENIGANIM",
      },
      {
        CITY: "BENIJOFAR",
      },
      {
        CITY: "BENIMODO",
      },
      {
        CITY: "BENIPARRELL",
      },
      {
        CITY: "BENISANO",
      },
      {
        CITY: "BENISSA",
      },
      {
        CITY: "BENISUERA",
      },
      {
        CITY: "BENITACHELL",
      },
      {
        CITY: "BERANGA",
      },
      {
        CITY: "BERANGO",
      },
      {
        CITY: "BERANTEVILLA",
      },
      {
        CITY: "BERASTEGUI",
      },
      {
        CITY: "BERGA",
      },
      {
        CITY: "BERGONDO",
      },
      {
        CITY: "BERGUENDA",
      },
      {
        CITY: "BERIAIN",
      },
      {
        CITY: "BERJA",
      },
      {
        CITY: "BERMEO",
      },
      {
        CITY: "BERNUY",
      },
      {
        CITY: "BERRIATUA",
      },
      {
        CITY: "BERRIOBEITI",
      },
      {
        CITY: "BERRIOZAR",
      },
      {
        CITY: "BERRIZ",
      },
      {
        CITY: "BERROBI",
      },
      {
        CITY: "BERRON",
      },
      {
        CITY: "BESCANO",
      },
      {
        CITY: "BETANZOS",
      },
      {
        CITY: "BETELU",
      },
      {
        CITY: "BETERA",
      },
      {
        CITY: "BEZNAR",
      },
      {
        CITY: "BIAR",
      },
      {
        CITY: "BICORP",
      },
      {
        CITY: "BIGASTRO",
      },
      {
        CITY: "BIGUES I RIELLS",
      },
      {
        CITY: "BILBAO",
      },
      {
        CITY: "BINEFAR",
      },
      {
        CITY: "BITEM",
      },
      {
        CITY: "BLANCA",
      },
      {
        CITY: "BLANCOS",
      },
      {
        CITY: "BLANES",
      },
      {
        CITY: "BOADILLA DEL MONTE",
      },
      {
        CITY: "BOCAIRENT",
      },
      {
        CITY: "BOCEGUILLAS",
      },
      {
        CITY: "BOECILLO",
      },
      {
        CITY: "BOGAJO",
      },
      {
        CITY: "BOIMORTO",
      },
      {
        CITY: "BOIRO",
      },
      {
        CITY: "BOLANOS DE CALATRAVA",
      },
      {
        CITY: "BOLLULLOS DE LA MITACION",
      },
      {
        CITY: "BOLLULLOS PAR DEL CONDADO",
      },
      {
        CITY: "BOLTANA",
      },
      {
        CITY: "BOLVIR",
      },
      {
        CITY: "BONARES",
      },
      {
        CITY: "BORBEN",
      },
      {
        CITY: "BORDILS",
      },
      {
        CITY: "BORJA",
      },
      {
        CITY: "BORMUJOS",
      },
      {
        CITY: "BORNOS",
      },
      {
        CITY: "BOROX",
      },
      {
        CITY: "BORRIOL",
      },
      {
        CITY: "BOTARELL",
      },
      {
        CITY: "BOTORRITA",
      },
      {
        CITY: "BRAFIM",
      },
      {
        CITY: "BRAZATORTAS",
      },
      {
        CITY: "BREA DE ARAGON",
      },
      {
        CITY: "BREDA",
      },
      {
        CITY: "BRENES",
      },
      {
        CITY: "BRIHUEGA",
      },
      {
        CITY: "BRION",
      },
      {
        CITY: "BRIVIESCA",
      },
      {
        CITY: "BROTO",
      },
      {
        CITY: "BRUNETE",
      },
      {
        CITY: "BUENACHE DE ALARCON",
      },
      {
        CITY: "BUEU",
      },
      {
        CITY: "BUJARALOZ",
      },
      {
        CITY: "BULLAS",
      },
      {
        CITY: "BURELA DE CABO",
      },
      {
        CITY: "BURGOS",
      },
      {
        CITY: "BURGUILLOS",
      },
      {
        CITY: "BURGUILLOS DEL CERRO",
      },
      {
        CITY: "BURJASSOT",
      },
      {
        CITY: "BURLATA",
      },
      {
        CITY: "BURRIANA",
      },
      {
        CITY: "BUSOT",
      },
      {
        CITY: "BUSTARVIEJO",
      },
      {
        CITY: "BUSTURI-AXPE",
      },
      {
        CITY: "CABANA",
      },
      {
        CITY: "CABANAQUINTA",
      },
      {
        CITY: "CABANAS",
      },
      {
        CITY: "CABANAS DE LA SAGRA",
      },
      {
        CITY: "CABANES",
      },
      {
        CITY: "CABANILLAS DEL CAMPO",
      },
      {
        CITY: "CABEZA DEL BUEY",
      },
      {
        CITY: "CABEZARRUBIAS DEL PUERTO",
      },
      {
        CITY: "CABEZO DE TORRES",
      },
      {
        CITY: "CABEZON DE LA SAL",
      },
      {
        CITY: "CABORANA",
      },
      {
        CITY: "CABRA",
      },
      {
        CITY: "CABRERA DE ALMANZA",
      },
      {
        CITY: "CABRERIZOS",
      },
      {
        CITY: "CABRILS",
      },
      {
        CITY: "CACHEIRAS",
      },
      {
        CITY: "CADIAR",
      },
      {
        CITY: "CADIZ",
      },
      {
        CITY: "CADREITA",
      },
      {
        CITY: "CAION",
      },
      {
        CITY: "CAJAR",
      },
      {
        CITY: "CALA",
      },
      {
        CITY: "CALA DEL MORAL",
      },
      {
        CITY: "CALA MILLOR",
      },
      {
        CITY: "CALA RATJADA",
      },
      {
        CITY: "CALAF",
      },
      {
        CITY: "CALAFELL",
      },
      {
        CITY: "CALAHONDA",
      },
      {
        CITY: "CALAHORRA",
      },
      {
        CITY: "CALAMONTE",
      },
      {
        CITY: "CALASPARRA",
      },
      {
        CITY: "CALATAYUD",
      },
      {
        CITY: "CALATORAO",
      },
      {
        CITY: "CALDELAS",
      },
      {
        CITY: "CALDERS",
      },
      {
        CITY: "CALDES DE MALAVELLA",
      },
      {
        CITY: "CALELLA",
      },
      {
        CITY: "CALIG",
      },
      {
        CITY: "CALLOSA DE SEGURA",
      },
      {
        CITY: "CALO",
      },
      {
        CITY: "CALONGE",
      },
      {
        CITY: "CALPE",
      },
      {
        CITY: "CAMARASSA",
      },
      {
        CITY: "CAMARENILLA",
      },
      {
        CITY: "CAMARGO",
      },
      {
        CITY: "CAMARINAS",
      },
      {
        CITY: "CAMARMA DE ESTERUELAS",
      },
      {
        CITY: "CAMAS",
      },
      {
        CITY: "CAMBADOS",
      },
      {
        CITY: "CAMBRE",
      },
      {
        CITY: "CAMBRILS",
      },
      {
        CITY: "CAMOS",
      },
      {
        CITY: "CAMPANARIO",
      },
      {
        CITY: "CAMPILLO",
      },
      {
        CITY: "CAMPILLOS",
      },
      {
        CITY: "CAMPINA",
      },
      {
        CITY: "CAMPINS",
      },
      {
        CITY: "CAMPO",
      },
      {
        CITY: "CAMPO DE CRIPTANA",
      },
      {
        CITY: "CAMPO REAL",
      },
      {
        CITY: "CAMPOHERMOSO",
      },
      {
        CITY: "CAMPONARAYA",
      },
      {
        CITY: "CAMPOS",
      },
      {
        CITY: "CAMPRODON",
      },
      {
        CITY: "CAMPUZANO",
      },
      {
        CITY: "CAN PICAFORT",
      },
      {
        CITY: "CANAL",
      },
      {
        CITY: "CANALS",
      },
      {
        CITY: "CANAR",
      },
      {
        CITY: "CANDAS",
      },
      {
        CITY: "CANDELARIA",
      },
      {
        CITY: "CANDELEDA",
      },
      {
        CITY: "CANEJA",
      },
      {
        CITY: "CANET DE MAR",
      },
      {
        CITY: "CANETE DE LAS TORRES",
      },
      {
        CITY: "CANGAS DE ONIS",
      },
      {
        CITY: "CANGAS DEL NARCEA",
      },
      {
        CITY: "CANGAS DO MORRAZO",
      },
      {
        CITY: "CANILLAS DE ALBAIDA",
      },
      {
        CITY: "CANOVELLES",
      },
      {
        CITY: "CANTILLANA",
      },
      {
        CITY: "CANTIMPALOS",
      },
      {
        CITY: "CAPDEPERA",
      },
      {
        CITY: "CAPELA",
      },
      {
        CITY: "CAPELLA",
      },
      {
        CITY: "CAPELLADES",
      },
      {
        CITY: "CAPILEIRA",
      },
      {
        CITY: "CAPMANY",
      },
      {
        CITY: "CAPSEC",
      },
      {
        CITY: "CARABANA",
      },
      {
        CITY: "CARAVACA",
      },
      {
        CITY: "CARBAJAL DE LA LEGUA",
      },
      {
        CITY: "CARBAJOSA DE LA SAGRADA",
      },
      {
        CITY: "CARBALLO",
      },
      {
        CITY: "CARCABUEY",
      },
      {
        CITY: "CARCAR",
      },
      {
        CITY: "CARCASTILLO",
      },
      {
        CITY: "CARDEDEU",
      },
      {
        CITY: "CARDENAL",
      },
      {
        CITY: "CARDENOSA",
      },
      {
        CITY: "CARDONA",
      },
      {
        CITY: "CARINENA",
      },
      {
        CITY: "CARLET",
      },
      {
        CITY: "CARMONA",
      },
      {
        CITY: "CARPESA",
      },
      {
        CITY: "CARPIO",
      },
      {
        CITY: "CARRAL",
      },
      {
        CITY: "CARRANQUE",
      },
      {
        CITY: "CARRASCAL DE BARREGAS",
      },
      {
        CITY: "CARRION DE LOS CESPEDES",
      },
      {
        CITY: "CARRION DE LOS CONDES",
      },
      {
        CITY: "CARRIZO DE LA RIBERA",
      },
      {
        CITY: "CARTAGENA",
      },
      {
        CITY: "CARTAMA",
      },
      {
        CITY: "CARTAYA",
      },
      {
        CITY: "CARTELLE",
      },
      {
        CITY: "CASABERMEJA",
      },
      {
        CITY: "CASAR DE CACERES",
      },
      {
        CITY: "CASARES",
      },
      {
        CITY: "CASARRUBIOS DEL MONTE",
      },
      {
        CITY: "CASAS DEL CASTANAR",
      },
      {
        CITY: "CASAS DEL MONTE",
      },
      {
        CITY: "CASAS VIEJAS",
      },
      {
        CITY: "CASERIO EL CAMPELLO",
      },
      {
        CITY: "CASETAS",
      },
      {
        CITY: "CASILLAS DE CORIA",
      },
      {
        CITY: "CASILLAS DEL ANGEL",
      },
      {
        CITY: "CASLA",
      },
      {
        CITY: "CASO",
      },
      {
        CITY: "CASPE",
      },
      {
        CITY: "CASTALLA",
      },
      {
        CITY: "CASTANDIELLO",
      },
      {
        CITY: "CASTEJON",
      },
      {
        CITY: "CASTEJON DEL PUENTE",
      },
      {
        CITY: "CASTELL DE FERRO",
      },
      {
        CITY: "CASTELLAR DE LA FRONTERA",
      },
      {
        CITY: "CASTELLAR DE SANTIAGO",
      },
      {
        CITY: "CASTELLAR DEL VALLES",
      },
      {
        CITY: "CASTELLBELL I EL VILAR",
      },
      {
        CITY: "CASTELLBISBAL",
      },
      {
        CITY: "CASTELLDEFELS",
      },
      {
        CITY: "CASTELLET",
      },
      {
        CITY: "CASTELLO",
      },
      {
        CITY: "CASTELLVELL DEL CAMP",
      },
      {
        CITY: "CASTELLVI DE ROSANES",
      },
      {
        CITY: "CASTELSERAS",
      },
      {
        CITY: "CASTILBLANCO DE LOS ARROYOS",
      },
      {
        CITY: "CASTILLEJA DE GUZMAN",
      },
      {
        CITY: "CASTILLEJA DE LA CUESTA",
      },
      {
        CITY: "CASTILLO DE LOCUBIN",
      },
      {
        CITY: "CASTILLO DEL ROMERAL",
      },
      {
        CITY: "CASTRILLON",
      },
      {
        CITY: "CASTRO",
      },
      {
        CITY: "CASTRO URDIALES",
      },
      {
        CITY: "CASTROGONZALO",
      },
      {
        CITY: "CASTROPOL",
      },
      {
        CITY: "CASTUERA",
      },
      {
        CITY: "CATADAU",
      },
      {
        CITY: "CATARROJA",
      },
      {
        CITY: "CATI",
      },
      {
        CITY: "CATOIRA",
      },
      {
        CITY: "CATRAL",
      },
      {
        CITY: "CATRAL",
      },
      {
        CITY: "CAUDETE",
      },
      {
        CITY: "CAZALEGAS",
      },
      {
        CITY: "CAZORLA",
      },
      {
        CITY: "CEA",
      },
      {
        CITY: "CEBOLLA",
      },
      {
        CITY: "CEBREROS",
      },
      {
        CITY: "CECENAS",
      },
      {
        CITY: "CEDILLO DEL CONDADO",
      },
      {
        CITY: "CELANOVA",
      },
      {
        CITY: "CELEIRO",
      },
      {
        CITY: "CELEIROS",
      },
      {
        CITY: "CELRA",
      },
      {
        CITY: "CENES DE LA VEGA",
      },
      {
        CITY: "CENICERO",
      },
      {
        CITY: "CENICIENTOS",
      },
      {
        CITY: "CENTRAL",
      },
      {
        CITY: "CERCEDA",
      },
      {
        CITY: "CERCEDILLA",
      },
      {
        CITY: "CERDEDA",
      },
      {
        CITY: "CERRO MURIANO",
      },
      {
        CITY: "CERVELLO",
      },
      {
        CITY: "CERVERA",
      },
      {
        CITY: "CERVERA DEL RIO ALHAMA",
      },
      {
        CITY: "CERVO",
      },
      {
        CITY: "CETINA",
      },
      {
        CITY: "CEUTA",
      },
      {
        CITY: "CEUTI",
      },
      {
        CITY: "CHANTADA",
      },
      {
        CITY: "CHAPELA",
      },
      {
        CITY: "CHAUCHINA",
      },
      {
        CITY: "CHECA",
      },
      {
        CITY: "CHELVA",
      },
      {
        CITY: "CHESTE",
      },
      {
        CITY: "CHICLANA DE LA FRONTERA",
      },
      {
        CITY: "CHICLANA DE SEGURA",
      },
      {
        CITY: "CHILCHES",
      },
      {
        CITY: "CHILOECHES",
      },
      {
        CITY: "CHINCHILLA DE MONTE ARAGON",
      },
      {
        CITY: "CHIPIONA",
      },
      {
        CITY: "CHIVA",
      },
      {
        CITY: "CHOZAS DE CANALES",
      },
      {
        CITY: "CHUCENA",
      },
      {
        CITY: "CHURRA",
      },
      {
        CITY: "CHURRIANA",
      },
      {
        CITY: "CHURRIANA DE LA VEGA",
      },
      {
        CITY: "CICERO",
      },
      {
        CITY: "CIDONES",
      },
      {
        CITY: "CIEMPOZUELOS",
      },
      {
        CITY: "CIEZA",
      },
      {
        CITY: "CIFUENTES",
      },
      {
        CITY: "CIGALES",
      },
      {
        CITY: "CIJUELA",
      },
      {
        CITY: "CILLEROS",
      },
      {
        CITY: "CIRIZA",
      },
      {
        CITY: "CISTERNIGA",
      },
      {
        CITY: "CISTIERNA",
      },
      {
        CITY: "CIUDAD REAL",
      },
      {
        CITY: "CIUDAD RODRIGO",
      },
      {
        CITY: "CIUTADELLA",
      },
      {
        CITY: "CIZUR MENOR",
      },
      {
        CITY: "COBATILLAS",
      },
      {
        CITY: "COBENA",
      },
      {
        CITY: "COBISA",
      },
      {
        CITY: "COCA",
      },
      {
        CITY: "COCENTAINA",
      },
      {
        CITY: "COGOLLOS",
      },
      {
        CITY: "COIN",
      },
      {
        CITY: "COIROS",
      },
      {
        CITY: "COLERA",
      },
      {
        CITY: "COLES",
      },
      {
        CITY: "COLINAS DE TRASMONTE",
      },
      {
        CITY: "COLINDRES",
      },
      {
        CITY: "COLLADO",
      },
      {
        CITY: "COLLADO MEDIANO",
      },
      {
        CITY: "COLLADO VILLALBA",
      },
      {
        CITY: "COLLBATO",
      },
      {
        CITY: "COLMENAR DE OREJA",
      },
      {
        CITY: "COLMENAR DEL ARROYO",
      },
      {
        CITY: "COLMENAR VIEJO",
      },
      {
        CITY: "COLMENAREJO",
      },
      {
        CITY: "COLONIA DE SAN PEDRO",
      },
      {
        CITY: "COMARES",
      },
      {
        CITY: "COMILLAS",
      },
      {
        CITY: "COMPETA",
      },
      {
        CITY: "COMPOSTELA",
      },
      {
        CITY: "CONIL DE LA FRONTERA",
      },
      {
        CITY: "CONSELL",
      },
      {
        CITY: "CONSTANTI",
      },
      {
        CITY: "CONSTANTINA",
      },
      {
        CITY: "CONSUEGRA",
      },
      {
        CITY: "CORBERA",
      },
      {
        CITY: "CORBERA DE LLOBREGAT",
      },
      {
        CITY: "CORCHUELA",
      },
      {
        CITY: "CORDOVA",
      },
      {
        CITY: "CORELLA",
      },
      {
        CITY: "CORGO",
      },
      {
        CITY: "CORIA",
      },
      {
        CITY: "CORISTANCO",
      },
      {
        CITY: "CORME-PORTO",
      },
      {
        CITY: "CORNELLANA",
      },
      {
        CITY: "CORRAL DE ALMAGUER",
      },
      {
        CITY: "CORRALEJO",
      },
      {
        CITY: "CORRALES",
      },
      {
        CITY: "CORRALES",
      },
      {
        CITY: "CORTEGANA",
      },
      {
        CITY: "CORTES DE LA FRONTERA",
      },
      {
        CITY: "CORTIGUERA",
      },
      {
        CITY: "CORVERA",
      },
      {
        CITY: "CORVERA",
      },
      {
        CITY: "COSLADA",
      },
      {
        CITY: "COSTA",
      },
      {
        CITY: "COSTUR",
      },
      {
        CITY: "COUSO DE SALAS",
      },
      {
        CITY: "COVALEDA",
      },
      {
        CITY: "COX",
      },
      {
        CITY: "CREIXELL",
      },
      {
        CITY: "CREVILLENT",
      },
      {
        CITY: "CRUCE DE ARINAGA",
      },
      {
        CITY: "CRUCES",
      },
      {
        CITY: "CRUILLES",
      },
      {
        CITY: "CRUZ",
      },
      {
        CITY: "CUADROS",
      },
      {
        CITY: "CUARTE DE HUERVA",
      },
      {
        CITY: "CUBAS",
      },
      {
        CITY: "CUBAS",
      },
      {
        CITY: "CUBELLES",
      },
      {
        CITY: "CUBILLOS DEL SIL",
      },
      {
        CITY: "CUDILLERO",
      },
      {
        CITY: "CUELLAR",
      },
      {
        CITY: "CUENCA",
      },
      {
        CITY: "CUETO",
      },
      {
        CITY: "CUEVAS DE SAN MARCOS",
      },
      {
        CITY: "CUEVAS DE VINROMA",
      },
      {
        CITY: "CUEVAS DEL ALMANZORA",
      },
      {
        CITY: "CULLERA",
      },
      {
        CITY: "CULLEREDO",
      },
      {
        CITY: "CUNIT",
      },
      {
        CITY: "CUNTIS",
      },
      {
        CITY: "CURRO",
      },
      {
        CITY: "CURTIS",
      },
      {
        CITY: "DAGANZO DE ARRIBA",
      },
      {
        CITY: "DAIMIEL",
      },
      {
        CITY: "DALIAS",
      },
      {
        CITY: "DAROCA",
      },
      {
        CITY: "DAYA VIEJA",
      },
      {
        CITY: "DEBA",
      },
      {
        CITY: "DENIA",
      },
      {
        CITY: "DERIO",
      },
      {
        CITY: "DEUSTU",
      },
      {
        CITY: "DIEZMA",
      },
      {
        CITY: "DILAR",
      },
      {
        CITY: "DODRO",
      },
      {
        CITY: "DOLORES",
      },
      {
        CITY: "DOMINGO PEREZ",
      },
      {
        CITY: "DON BENITO",
      },
      {
        CITY: "DOS HERMANAS",
      },
      {
        CITY: "DOSBARRIOS",
      },
      {
        CITY: "DOSRIUS",
      },
      {
        CITY: "DUENAS",
      },
      {
        CITY: "DUMBRIA",
      },
      {
        CITY: "DURANGO",
      },
      {
        CITY: "DURCAL",
      },
      {
        CITY: "ECHARRI-ARANAZ",
      },
      {
        CITY: "EGUES-UHARTE",
      },
      {
        CITY: "EIBAR",
      },
      {
        CITY: "EJEA DE LOS CABALLEROS",
      },
      {
        CITY: "EL ABRIGO",
      },
      {
        CITY: "EL ALAMO",
      },
      {
        CITY: "EL ALAMO",
      },
      {
        CITY: "EL ARAHAL",
      },
      {
        CITY: "EL ASTILLERO",
      },
      {
        CITY: "EL BALLESTERO",
      },
      {
        CITY: "EL BARCO DE AVILA",
      },
      {
        CITY: "EL BURGO DE EBRO",
      },
      {
        CITY: "EL BURGO DE OSMA",
      },
      {
        CITY: "EL CAMP DE MIRRA",
      },
      {
        CITY: "EL CAMPELLO",
      },
      {
        CITY: "EL CASAR",
      },
      {
        CITY: "EL CATLLAR",
      },
      {
        CITY: "EL CORONIL",
      },
      {
        CITY: "EL CORRILLO",
      },
      {
        CITY: "EL CUERVO",
      },
      {
        CITY: "EL EJIDO",
      },
      {
        CITY: "EL ESCORIAL",
      },
      {
        CITY: "EL ESCORIAL",
      },
      {
        CITY: "EL ESPINAR",
      },
      {
        CITY: "EL GARROBO",
      },
      {
        CITY: "EL GRADO",
      },
      {
        CITY: "EL GRAO",
      },
      {
        CITY: "EL HOYO DE PINARES",
      },
      {
        CITY: "EL MASNOU",
      },
      {
        CITY: "EL MEDANO",
      },
      {
        CITY: "EL MOLAR",
      },
      {
        CITY: "EL MORAL",
      },
      {
        CITY: "EL MORCHE",
      },
      {
        CITY: "EL MORELL",
      },
      {
        CITY: "EL PALMAR",
      },
      {
        CITY: "EL PALMAR DE TROYA",
      },
      {
        CITY: "EL PAPIOL",
      },
      {
        CITY: "EL PEDERNOSO",
      },
      {
        CITY: "EL PERELLO",
      },
      {
        CITY: "EL POAL",
      },
      {
        CITY: "EL POZO DE LOS FRAILES",
      },
      {
        CITY: "EL PRAT DE LLOBREGAT",
      },
      {
        CITY: "EL PROVENCIO",
      },
      {
        CITY: "EL PUERTO",
      },
      {
        CITY: "EL RAAL",
      },
      {
        CITY: "EL REAL DE LA JARA",
      },
      {
        CITY: "EL SALER",
      },
      {
        CITY: "EL SAUZAL",
      },
      {
        CITY: "EL SOTILLO",
      },
      {
        CITY: "EL TABLERO",
      },
      {
        CITY: "EL TORNO",
      },
      {
        CITY: "EL TORO",
      },
      {
        CITY: "EL VELLON",
      },
      {
        CITY: "EL VENDRELL",
      },
      {
        CITY: "EL VISO DE SAN JUAN",
      },
      {
        CITY: "EL VISO DEL ALCOR",
      },
      {
        CITY: "ELBURGO",
      },
      {
        CITY: "ELCHE",
      },
      {
        CITY: "ELDA",
      },
      {
        CITY: "ELECHAS",
      },
      {
        CITY: "ELEXALDE",
      },
      {
        CITY: "ELGOIBAR",
      },
      {
        CITY: "ELGORRIAGA",
      },
      {
        CITY: "ELIZONDO",
      },
      {
        CITY: "ELORRIO",
      },
      {
        CITY: "ELS HOSTALETS DE PIEROLA",
      },
      {
        CITY: "ELS MONJOS",
      },
      {
        CITY: "ELS PALLARESOS",
      },
      {
        CITY: "ELTZABURU",
      },
      {
        CITY: "ENCINAS REALES",
      },
      {
        CITY: "ENCINASOLA",
      },
      {
        CITY: "ENERIZ",
      },
      {
        CITY: "ENGUERA",
      },
      {
        CITY: "ENTRAMBASAGUAS",
      },
      {
        CITY: "ENTREVIAS",
      },
      {
        CITY: "EPILA",
      },
      {
        CITY: "ERANDIO",
      },
      {
        CITY: "ERMUA",
      },
      {
        CITY: "ERRENTERIA",
      },
      {
        CITY: "ESCALONA",
      },
      {
        CITY: "ESCALONILLA",
      },
      {
        CITY: "ESCATRON",
      },
      {
        CITY: "ESCURIAL",
      },
      {
        CITY: "ESPARREGUERA",
      },
      {
        CITY: "ESPARTINAS",
      },
      {
        CITY: "ESPEJO",
      },
      {
        CITY: "ESPERA",
      },
      {
        CITY: "ESPIEL",
      },
      {
        CITY: "ESPINARDO",
      },
      {
        CITY: "ESPIRDO",
      },
      {
        CITY: "ESPLUGUES DE LLOBREGAT",
      },
      {
        CITY: "ESPLUS",
      },
      {
        CITY: "ESPOLLA",
      },
      {
        CITY: "ESQUIVIAS",
      },
      {
        CITY: "ESTEIRO",
      },
      {
        CITY: "ESTELLA-LIZARRA",
      },
      {
        CITY: "ESTELLENCS",
      },
      {
        CITY: "ESTEPA",
      },
      {
        CITY: "ESTEPONA",
      },
      {
        CITY: "ESTRADA",
      },
      {
        CITY: "EZCARAY",
      },
      {
        CITY: "FABERO",
      },
      {
        CITY: "FALCES",
      },
      {
        CITY: "FALSET",
      },
      {
        CITY: "FASNIA",
      },
      {
        CITY: "FAURA",
      },
      {
        CITY: "FELANITX",
      },
      {
        CITY: "FENE",
      },
      {
        CITY: "FERREIRA",
      },
      {
        CITY: "FERRERIES",
      },
      {
        CITY: "FERROL",
      },
      {
        CITY: "FIGARO",
      },
      {
        CITY: "FIGUERAS",
      },
      {
        CITY: "FINESTRAT",
      },
      {
        CITY: "FIRGAS",
      },
      {
        CITY: "FITERO",
      },
      {
        CITY: "FLIX",
      },
      {
        CITY: "FORMENTERA DE SEGURA",
      },
      {
        CITY: "FORNELLS DE LA SELVA",
      },
      {
        CITY: "FORTIA",
      },
      {
        CITY: "FORTUNA",
      },
      {
        CITY: "FORUA",
      },
      {
        CITY: "FOZ",
      },
      {
        CITY: "FRAGA",
      },
      {
        CITY: "FRAILES",
      },
      {
        CITY: "FREGENAL DE LA SIERRA",
      },
      {
        CITY: "FRESNO DE LA VEGA",
      },
      {
        CITY: "FRIGILIANA",
      },
      {
        CITY: "FRIOL",
      },
      {
        CITY: "FRUIZ",
      },
      {
        CITY: "FUENGIROLA",
      },
      {
        CITY: "FUENLABRADA",
      },
      {
        CITY: "FUENMAYOR",
      },
      {
        CITY: "FUENSALIDA",
      },
      {
        CITY: "FUENSANTA",
      },
      {
        CITY: "FUENSANTA DE MARTOS",
      },
      {
        CITY: "FUENTE DEL MAESTRE",
      },
      {
        CITY: "FUENTE EL FRESNO",
      },
      {
        CITY: "FUENTE EL SAZ",
      },
      {
        CITY: "FUENTE VAQUEROS",
      },
      {
        CITY: "FUENTE-ALAMO",
      },
      {
        CITY: "FUENTEALBILLA",
      },
      {
        CITY: "FUENTELAPENA",
      },
      {
        CITY: "FUENTERRABIA",
      },
      {
        CITY: "FUENTES",
      },
      {
        CITY: "FUENTES DE ANDALUCIA",
      },
      {
        CITY: "FUENTES DE EBRO",
      },
      {
        CITY: "FUENTES DE JILOCA",
      },
      {
        CITY: "FUENTES DE LEON",
      },
      {
        CITY: "FUENTES DE VALDEPERO",
      },
      {
        CITY: "GACETA",
      },
      {
        CITY: "GALAPAGAR",
      },
      {
        CITY: "GALAPAGOS",
      },
      {
        CITY: "GALAROZA",
      },
      {
        CITY: "GALDAKAO",
      },
      {
        CITY: "GALDAMES BEITIA",
      },
      {
        CITY: "GALERA",
      },
      {
        CITY: "GALIZANO",
      },
      {
        CITY: "GALLARTA",
      },
      {
        CITY: "GALLUR",
      },
      {
        CITY: "GALVEZ",
      },
      {
        CITY: "GAMIZ",
      },
      {
        CITY: "GAMONAL",
      },
      {
        CITY: "GANDESA",
      },
      {
        CITY: "GANDIA",
      },
      {
        CITY: "GARACHICO",
      },
      {
        CITY: "GARCIA",
      },
      {
        CITY: "GARCIAZ",
      },
      {
        CITY: "GARGALLO",
      },
      {
        CITY: "GARRAF",
      },
      {
        CITY: "GARRIGAS",
      },
      {
        CITY: "GARRIGUELLA",
      },
      {
        CITY: "GARROVILLAS",
      },
      {
        CITY: "GARRUCHA",
      },
      {
        CITY: "GATA",
      },
      {
        CITY: "GATA DE GORGOS",
      },
      {
        CITY: "GAUCIN",
      },
      {
        CITY: "GAUTEGIZ ARTEAGA",
      },
      {
        CITY: "GAVA",
      },
      {
        CITY: "GELIDA",
      },
      {
        CITY: "GELSA",
      },
      {
        CITY: "GELVES",
      },
      {
        CITY: "GENOVES",
      },
      {
        CITY: "GERENA",
      },
      {
        CITY: "GERNIKA-LUMO",
      },
      {
        CITY: "GETAFE",
      },
      {
        CITY: "GETARIA",
      },
      {
        CITY: "GILET",
      },
      {
        CITY: "GINES",
      },
      {
        CITY: "GIRONA",
      },
      {
        CITY: "GIRONELLA",
      },
      {
        CITY: "GIZABURUAGA",
      },
      {
        CITY: "GODELLA",
      },
      {
        CITY: "GODELLETA",
      },
      {
        CITY: "GOJAR",
      },
      {
        CITY: "GOLMAYO",
      },
      {
        CITY: "GOLMES",
      },
      {
        CITY: "GONDOMAR",
      },
      {
        CITY: "GORDON",
      },
      {
        CITY: "GORRITI",
      },
      {
        CITY: "GOZON DE UCIEZA",
      },
      {
        CITY: "GRADO",
      },
      {
        CITY: "GRANADA",
      },
      {
        CITY: "GRANADILLA DE ABONA",
      },
      {
        CITY: "GRANJA DE ROCAMORA",
      },
      {
        CITY: "GRANOLLERS",
      },
      {
        CITY: "GRAUS",
      },
      {
        CITY: "GRAZALEMA",
      },
      {
        CITY: "GRIJOTA",
      },
      {
        CITY: "GRINON",
      },
      {
        CITY: "GRISEN",
      },
      {
        CITY: "GROVE O",
      },
      {
        CITY: "GUADALAJARA",
      },
      {
        CITY: "GUADALCAZAR",
      },
      {
        CITY: "GUADALIX DE LA SIERRA",
      },
      {
        CITY: "GUADALUPE",
      },
      {
        CITY: "GUADALUPE",
      },
      {
        CITY: "GUADAMUR",
      },
      {
        CITY: "GUADARRAMA",
      },
      {
        CITY: "GUADIANA DEL CAUDILLO",
      },
      {
        CITY: "GUADIARO",
      },
      {
        CITY: "GUADIX",
      },
      {
        CITY: "GUAMASA",
      },
      {
        CITY: "GUARDAMAR DEL SEGURA",
      },
      {
        CITY: "GUARDO",
      },
      {
        CITY: "GUARENA",
      },
      {
        CITY: "GUARNIZO",
      },
      {
        CITY: "GUEJAR-SIERRA",
      },
      {
        CITY: "GUENES",
      },
      {
        CITY: "GUEVEJAR",
      },
      {
        CITY: "GUIJUELO",
      },
      {
        CITY: "GUILLENA",
      },
      {
        CITY: "GUIMAR",
      },
      {
        CITY: "GUITIRIZ",
      },
      {
        CITY: "GURB",
      },
      {
        CITY: "GUZMAN",
      },
      {
        CITY: "HARO",
      },
      {
        CITY: "HELLIN",
      },
      {
        CITY: "HENARES",
      },
      {
        CITY: "HERENCIA",
      },
      {
        CITY: "HERNANI",
      },
      {
        CITY: "HERNANSANCHO",
      },
      {
        CITY: "HERNIALDE",
      },
      {
        CITY: "HERRERA",
      },
      {
        CITY: "HERRERA",
      },
      {
        CITY: "HERRERA DE PISUERGA",
      },
      {
        CITY: "HERRERA DEL DUQUE",
      },
      {
        CITY: "HIGUERA LA REAL",
      },
      {
        CITY: "HIJAR",
      },
      {
        CITY: "HINOJEDO",
      },
      {
        CITY: "HINOJOS",
      },
      {
        CITY: "HINOJOSA DEL DUQUE",
      },
      {
        CITY: "HINOJOSA DEL VALLE",
      },
      {
        CITY: "HONDON DE LAS NIEVES",
      },
      {
        CITY: "HONDON DE LOS FRAILES",
      },
      {
        CITY: "HONTANARES DE ERESMA",
      },
      {
        CITY: "HONTORIA DEL PINAR",
      },
      {
        CITY: "HORCAJO DE LAS TORRES",
      },
      {
        CITY: "HORCHE",
      },
      {
        CITY: "HORNACHOS",
      },
      {
        CITY: "HORNACHUELOS",
      },
      {
        CITY: "HORTA DE SANT JOAN",
      },
      {
        CITY: "HORTICHUELA",
      },
      {
        CITY: "HOSPITAL",
      },
      {
        CITY: "HOSTALRIC",
      },
      {
        CITY: "HOYA-GONZALO",
      },
      {
        CITY: "HOYO DE MANZANARES",
      },
      {
        CITY: "HUARTE-ARAQUIL",
      },
      {
        CITY: "HUECAS",
      },
      {
        CITY: "HUELMA",
      },
      {
        CITY: "HUELVA",
      },
      {
        CITY: "HUELVES",
      },
      {
        CITY: "HUERCAL DE ALMERIA",
      },
      {
        CITY: "HUERCAL OVERA",
      },
      {
        CITY: "HUERCANOS",
      },
      {
        CITY: "HUERTAS",
      },
      {
        CITY: "HUERTO",
      },
      {
        CITY: "HUESCA",
      },
      {
        CITY: "HUETE",
      },
      {
        CITY: "HUETOR SANTILLAN",
      },
      {
        CITY: "HUETOR VEGA",
      },
      {
        CITY: "HUETOR-TAJAR",
      },
      {
        CITY: "HUEVAR",
      },
      {
        CITY: "HUMANES",
      },
      {
        CITY: "HUMANES DE MADRID",
      },
      {
        CITY: "HURCHILLO",
      },
      {
        CITY: "IBARRA",
      },
      {
        CITY: "IBARRURI",
      },
      {
        CITY: "IBI",
      },
      {
        CITY: "IBIZA",
      },
      {
        CITY: "IBROS",
      },
      {
        CITY: "ICOD DE LOS VINOS",
      },
      {
        CITY: "IDIAZABAL",
      },
      {
        CITY: "IGUALADA",
      },
      {
        CITY: "IGUALEJA",
      },
      {
        CITY: "IGUESTE",
      },
      {
        CITY: "ILLA",
      },
      {
        CITY: "ILLANA",
      },
      {
        CITY: "ILLAS",
      },
      {
        CITY: "ILLESCAS",
      },
      {
        CITY: "ILLORA",
      },
      {
        CITY: "ILLUECA",
      },
      {
        CITY: "INCA",
      },
      {
        CITY: "INCIO",
      },
      {
        CITY: "INFANTES",
      },
      {
        CITY: "INGENIO",
      },
      {
        CITY: "INIESTA",
      },
      {
        CITY: "IPAZTER",
      },
      {
        CITY: "IRUN",
      },
      {
        CITY: "IRURA",
      },
      {
        CITY: "ISLA BECOA",
      },
      {
        CITY: "ISLA CRISTINA",
      },
      {
        CITY: "ISORA",
      },
      {
        CITY: "ITZA",
      },
      {
        CITY: "IZNAJAR",
      },
      {
        CITY: "IZNATE",
      },
      {
        CITY: "JABAGA",
      },
      {
        CITY: "JABALI NUEVO",
      },
      {
        CITY: "JACA",
      },
      {
        CITY: "JACARILLA",
      },
      {
        CITY: "JARAIZ DE LA VERA",
      },
      {
        CITY: "JAVEA",
      },
      {
        CITY: "JEREZ DE LA FRONTERA",
      },
      {
        CITY: "JEREZ DE LOS CABALLEROS",
      },
      {
        CITY: "JERTE",
      },
      {
        CITY: "JESUS",
      },
      {
        CITY: "JIJONA",
      },
      {
        CITY: "JIMENA DE LA FRONTERA",
      },
      {
        CITY: "JOANETES",
      },
      {
        CITY: "JODAR",
      },
      {
        CITY: "JORBA",
      },
      {
        CITY: "JUMILLA",
      },
      {
        CITY: "JUNEDA",
      },
      {
        CITY: "LA ABADILLA",
      },
      {
        CITY: "LA ADRADA",
      },
      {
        CITY: "LA ALAMEDA DE LA SAGRA",
      },
      {
        CITY: "LA ALBERCA",
      },
      {
        CITY: "LA ALGABA",
      },
      {
        CITY: "LA ALJORRA",
      },
      {
        CITY: "LA ALMUNIA DE DONA GODINA",
      },
      {
        CITY: "LA ANTILLA",
      },
      {
        CITY: "LA ARENA",
      },
      {
        CITY: "LA ARENA",
      },
      {
        CITY: "LA BANEZA",
      },
      {
        CITY: "LA BARONIA DE RIALB",
      },
      {
        CITY: "LA BATLLORIA",
      },
      {
        CITY: "LA BISBAL",
      },
      {
        CITY: "LA BISBAL DEL PENEDES",
      },
      {
        CITY: "LA CABRERA",
      },
      {
        CITY: "LA CALZADA",
      },
      {
        CITY: "LA CALZADA DE CALATRAVA",
      },
      {
        CITY: "LA CALZADA DE OROPESA",
      },
      {
        CITY: "LA CAMPANA",
      },
      {
        CITY: "LA CANADA",
      },
      {
        CITY: "LA CANADA DE SAN URBANO",
      },
      {
        CITY: "LA CANALOSA",
      },
      {
        CITY: "LA CANONJA",
      },
      {
        CITY: "LA CARIDAD",
      },
      {
        CITY: "LA CARLOTA",
      },
      {
        CITY: "LA CAROLINA",
      },
      {
        CITY: "LA CAVADA",
      },
      {
        CITY: "LA CODOSERA",
      },
      {
        CITY: "LA ESPINA",
      },
      {
        CITY: "LA FEBRO",
      },
      {
        CITY: "LA FELGUERA",
      },
      {
        CITY: "LA FIGUERA",
      },
      {
        CITY: "LA FLECHA",
      },
      {
        CITY: "LA FLORESTA PEARSON",
      },
      {
        CITY: "LA FLORIDA",
      },
      {
        CITY: "LA FRESNEDA",
      },
      {
        CITY: "LA FRONTERA",
      },
      {
        CITY: "LA FUENTE DE SAN ESTEBAN",
      },
      {
        CITY: "LA GALERA",
      },
      {
        CITY: "LA GARGANTA",
      },
      {
        CITY: "LA GARRIGA",
      },
      {
        CITY: "LA GINETA",
      },
      {
        CITY: "LA GRANADA",
      },
      {
        CITY: "LA GRANADELLA",
      },
      {
        CITY: "LA GRANJA",
      },
      {
        CITY: "LA GRANJA DE SAN VICENTE",
      },
      {
        CITY: "LA GUANCHA",
      },
      {
        CITY: "LA GUARDIA",
      },
      {
        CITY: "LA GUARDIA DE JAEN",
      },
      {
        CITY: "LA HERRADURA",
      },
      {
        CITY: "LA HIGUERITA",
      },
      {
        CITY: "LA LANTEJUELA",
      },
      {
        CITY: "LA LLACUNA",
      },
      {
        CITY: "LA LLAGOSTA",
      },
      {
        CITY: "LA LLOSA",
      },
      {
        CITY: "LA LUISIANA",
      },
      {
        CITY: "LA MAMOLA",
      },
      {
        CITY: "LA MANCHA BLANCA",
      },
      {
        CITY: "LA MATANZA DE ACENTEJO",
      },
      {
        CITY: "LA MOJONERA",
      },
      {
        CITY: "LA MUELA",
      },
      {
        CITY: "LA NORA",
      },
      {
        CITY: "LA NOU DE BERGUEDA",
      },
      {
        CITY: "LA NUCIA",
      },
      {
        CITY: "LA OLIVA",
      },
      {
        CITY: "LA ORDEN",
      },
      {
        CITY: "LA OROTAVA",
      },
      {
        CITY: "LA PALMA DEL CONDADO",
      },
      {
        CITY: "LA PAZ",
      },
      {
        CITY: "LA PEDRAJA DE PORTILLO",
      },
      {
        CITY: "LA PEDRERA",
      },
      {
        CITY: "LA PENILLA",
      },
      {
        CITY: "LA PERDOMA",
      },
      {
        CITY: "LA PLANA",
      },
      {
        CITY: "LA PLAYA DE ARGUINEGUIN",
      },
      {
        CITY: "LA POBLA DE CLARAMUNT",
      },
      {
        CITY: "LA POBLA DE LILLET",
      },
      {
        CITY: "LA POBLA DE MAFUMET",
      },
      {
        CITY: "LA POBLA DE MONTORNES",
      },
      {
        CITY: "LA POLA DE GORDON",
      },
      {
        CITY: "LA PUEBLA DE ALMORADIEL",
      },
      {
        CITY: "LA PUEBLA DE ARGANZON",
      },
      {
        CITY: "LA PUEBLA DE CAZALLA",
      },
      {
        CITY: "LA PUEBLA DE HIJAR",
      },
      {
        CITY: "LA PUEBLA DE LOS INFANTES",
      },
      {
        CITY: "LA PUEBLA DE MONTALBAN",
      },
      {
        CITY: "LA PUEBLA DEL RIO",
      },
      {
        CITY: "LA PUEBLANUEVA",
      },
      {
        CITY: "LA PUERTA DE SEGURA",
      },
      {
        CITY: "LA RAMBLA",
      },
      {
        CITY: "LA RAMBLA",
      },
      {
        CITY: "LA RAPITA",
      },
      {
        CITY: "LA RAYA",
      },
      {
        CITY: "LA RED DE VALDETUEJAR",
      },
      {
        CITY: "LA RIBA",
      },
      {
        CITY: "LA RIERA DE GAIA",
      },
      {
        CITY: "LA RINCONADA",
      },
      {
        CITY: "LA ROBLA",
      },
      {
        CITY: "LA ROCA DEL VALLES",
      },
      {
        CITY: "LA RODA",
      },
      {
        CITY: "LA RODA DE ANDALUCIA",
      },
      {
        CITY: "LA SECA",
      },
      {
        CITY: "LA SECUITA",
      },
      {
        CITY: "LA SELVA",
      },
      {
        CITY: "LA SERNA",
      },
      {
        CITY: "LA SOLANA",
      },
      {
        CITY: "LA TORRE",
      },
      {
        CITY: "LA TORRE",
      },
      {
        CITY: "LA TORRE",
      },
      {
        CITY: "LA TORRE",
      },
      {
        CITY: "LA VALL",
      },
      {
        CITY: "LA VALL DEL BAC",
      },
      {
        CITY: "LA VERA",
      },
      {
        CITY: "LA VICTORIA",
      },
      {
        CITY: "LA VICTORIA DE ACENTEJO",
      },
      {
        CITY: "LA VINUELA",
      },
      {
        CITY: "LA VIRGEN DEL CAMINO",
      },
      {
        CITY: "LA VIRGEN DEL PILAR",
      },
      {
        CITY: "LA YEDRA",
      },
      {
        CITY: "LABAJOS",
      },
      {
        CITY: "LAGARTERA",
      },
      {
        CITY: "LAGO",
      },
      {
        CITY: "LAGUARDIA",
      },
      {
        CITY: "LAGUNA DE DUERO",
      },
      {
        CITY: "LAJARES",
      },
      {
        CITY: "LALIN",
      },
      {
        CITY: "LAMA",
      },
      {
        CITY: "LAMADRID",
      },
      {
        CITY: "LANAJA",
      },
      {
        CITY: "LANDA",
      },
      {
        CITY: "LANDA",
      },
      {
        CITY: "LANGA",
      },
      {
        CITY: "LANGA DE DUERO",
      },
      {
        CITY: "LANGREO",
      },
      {
        CITY: "LANJARON",
      },
      {
        CITY: "LANTADILLA",
      },
      {
        CITY: "LARACHA",
      },
      {
        CITY: "LARDERO",
      },
      {
        CITY: "LAREDO",
      },
      {
        CITY: "LAS CABEZAS DE SAN JUAN",
      },
      {
        CITY: "LAS GALLETAS",
      },
      {
        CITY: "LAS HORTICHUELAS",
      },
      {
        CITY: "LAS ISLAS",
      },
      {
        CITY: "LAS MATAS",
      },
      {
        CITY: "LAS NAVAS DE LA CONCEPCION",
      },
      {
        CITY: "LAS NAVAS DEL MARQUES",
      },
      {
        CITY: "LAS PALMAS DE GRAN CANARIA",
      },
      {
        CITY: "LAS ROZAS DE MADRID",
      },
      {
        CITY: "LAS TORRES DE COTILLAS",
      },
      {
        CITY: "LAS VEGAS",
      },
      {
        CITY: "LAS VENTANAS",
      },
      {
        CITY: "LAS VENTAS DE RETAMOSA",
      },
      {
        CITY: "LASARTE",
      },
      {
        CITY: "LAUDIO-LLODIO",
      },
      {
        CITY: "LAZAGURRIA",
      },
      {
        CITY: "LEBRIJA",
      },
      {
        CITY: "LEDANA",
      },
      {
        CITY: "LEDRADA",
      },
      {
        CITY: "LEGASA",
      },
      {
        CITY: "LEGAZPIA",
      },
      {
        CITY: "LEGORRETA",
      },
      {
        CITY: "LEKEITIO",
      },
      {
        CITY: "LEPE",
      },
      {
        CITY: "LERIN",
      },
      {
        CITY: "LERMA",
      },
      {
        CITY: "LES BORGES DEL CAMP",
      },
      {
        CITY: "LES FONTS",
      },
      {
        CITY: "LEZAMA",
      },
      {
        CITY: "LEZO",
      },
      {
        CITY: "LIANO",
      },
      {
        CITY: "LIBRILLA",
      },
      {
        CITY: "LIENCRES",
      },
      {
        CITY: "LIJAR",
      },
      {
        CITY: "LILLO",
      },
      {
        CITY: "LIMONES",
      },
      {
        CITY: "LIMPIAS",
      },
      {
        CITY: "LINARES",
      },
      {
        CITY: "LINYOLA",
      },
      {
        CITY: "LIRES",
      },
      {
        CITY: "LLAFRANC",
      },
      {
        CITY: "LLAGOSTERA",
      },
      {
        CITY: "LLANERA",
      },
      {
        CITY: "LLANES",
      },
      {
        CITY: "LLANO",
      },
      {
        CITY: "LLEDO",
      },
      {
        CITY: "LLEIDA",
      },
      {
        CITY: "LLERENA",
      },
      {
        CITY: "LLERS",
      },
      {
        CITY: "LLINARS DEL VALLES",
      },
      {
        CITY: "LLIRIA",
      },
      {
        CITY: "LLOFRIU",
      },
      {
        CITY: "LLORET DE MAR",
      },
      {
        CITY: "LLOSA DE RANES",
      },
      {
        CITY: "LOBIOS",
      },
      {
        CITY: "LODOSA",
      },
      {
        CITY: "LOECHES",
      },
      {
        CITY: "LOJA",
      },
      {
        CITY: "LOMO DE ARICO",
      },
      {
        CITY: "LOPERA",
      },
      {
        CITY: "LORCA",
      },
      {
        CITY: "LORIGUILLA",
      },
      {
        CITY: "LORQUI",
      },
      {
        CITY: "LOS ALCAZARES",
      },
      {
        CITY: "LOS ANGELES",
      },
      {
        CITY: "LOS BARRIOS",
      },
      {
        CITY: "LOS BARRIOS",
      },
      {
        CITY: "LOS CORRALES",
      },
      {
        CITY: "LOS CORRALES DE BUELNA",
      },
      {
        CITY: "LOS CRISTIANOS",
      },
      {
        CITY: "LOS DOLORES",
      },
      {
        CITY: "LOS GALLARDOS",
      },
      {
        CITY: "LOS GARRES",
      },
      {
        CITY: "LOS HUEROS",
      },
      {
        CITY: "LOS LLANOS DE ARIDANE",
      },
      {
        CITY: "LOS MARINES",
      },
      {
        CITY: "LOS MOLINOS",
      },
      {
        CITY: "LOS MONTES",
      },
      {
        CITY: "LOS NAREJOS",
      },
      {
        CITY: "LOS NAVALMORALES",
      },
      {
        CITY: "LOS NAVALUCILLOS",
      },
      {
        CITY: "LOS PALACIOS",
      },
      {
        CITY: "LOS PALACIOS Y VILLAFRANCA",
      },
      {
        CITY: "LOS RIOS",
      },
      {
        CITY: "LOS ROSALES",
      },
      {
        CITY: "LOS SANTOS DE LA HUMOSA",
      },
      {
        CITY: "LOS SILOS",
      },
      {
        CITY: "LOS VILLARES",
      },
      {
        CITY: "LOS YEBENES",
      },
      {
        CITY: "LOSAR DE LA VERA",
      },
      {
        CITY: "LOUSAME",
      },
      {
        CITY: "LUANCO",
      },
      {
        CITY: "LUARCA",
      },
      {
        CITY: "LUCENA",
      },
      {
        CITY: "LUCENA DEL CID",
      },
      {
        CITY: "LUCENA DEL PUERTO",
      },
      {
        CITY: "LUCENI",
      },
      {
        CITY: "LUCILLOS",
      },
      {
        CITY: "LUGO",
      },
      {
        CITY: "LUGONES",
      },
      {
        CITY: "LUOU",
      },
      {
        CITY: "LUYANDO",
      },
      {
        CITY: "LUZAIDE",
      },
      {
        CITY: "LUZMELA",
      },
      {
        CITY: "MACAEL",
      },
      {
        CITY: "MACANET DE LA SELVA",
      },
      {
        CITY: "MACEIRA",
      },
      {
        CITY: "MADRID",
      },
      {
        CITY: "MADRIDANOS",
      },
      {
        CITY: "MADRIDEJOS",
      },
      {
        CITY: "MADRIGAL DE LA VERA",
      },
      {
        CITY: "MADRIGALEJO",
      },
      {
        CITY: "MADRIGUERAS",
      },
      {
        CITY: "MADRONERA",
      },
      {
        CITY: "MAGAN",
      },
      {
        CITY: "MAHON",
      },
      {
        CITY: "MAIRENA DEL ALCOR",
      },
      {
        CITY: "MAIRENA DEL ALJARAFE",
      },
      {
        CITY: "MAJADAHONDA",
      },
      {
        CITY: "MALAGON",
      },
      {
        CITY: "MALGRAT DE MAR",
      },
      {
        CITY: "MALIANO",
      },
      {
        CITY: "MALLEN",
      },
      {
        CITY: "MALPARTIDA DE CACERES",
      },
      {
        CITY: "MALPARTIDA DE PLASENCIA",
      },
      {
        CITY: "MALPICA",
      },
      {
        CITY: "MALPICA",
      },
      {
        CITY: "MANACOR",
      },
      {
        CITY: "MANCHA REAL",
      },
      {
        CITY: "MANILVA",
      },
      {
        CITY: "MANISES",
      },
      {
        CITY: "MANLLEU",
      },
      {
        CITY: "MANRESA",
      },
      {
        CITY: "MANUEL",
      },
      {
        CITY: "MANZANARES",
      },
      {
        CITY: "MANZANARES EL REAL",
      },
      {
        CITY: "MARACENA",
      },
      {
        CITY: "MARBELLA",
      },
      {
        CITY: "MARCHAMALO",
      },
      {
        CITY: "MARCHENA",
      },
      {
        CITY: "MARCILLA",
      },
      {
        CITY: "MARGARIDA",
      },
      {
        CITY: "MARIA DE HUERVA",
      },
      {
        CITY: "MARIA DE LA SALUT",
      },
      {
        CITY: "MARIN",
      },
      {
        CITY: "MARIOLA",
      },
      {
        CITY: "MARMOLEJO",
      },
      {
        CITY: "MARTIN",
      },
      {
        CITY: "MARTINET",
      },
      {
        CITY: "MARTORELL",
      },
      {
        CITY: "MARTOS",
      },
      {
        CITY: "MARUGAN",
      },
      {
        CITY: "MARZAGAN",
      },
      {
        CITY: "MASDENVERGE",
      },
      {
        CITY: "MASIDE",
      },
      {
        CITY: "MASLLORENC",
      },
      {
        CITY: "MASPALOMAS",
      },
      {
        CITY: "MASQUEFA",
      },
      {
        CITY: "MASSAMAGRELL",
      },
      {
        CITY: "MATA",
      },
      {
        CITY: "MATADEPERA",
      },
      {
        CITY: "MATAELPINO",
      },
      {
        CITY: "MATALUENGA",
      },
      {
        CITY: "MATAMOROSA",
      },
      {
        CITY: "MATAPOZUELOS",
      },
      {
        CITY: "MAYOR",
      },
      {
        CITY: "MAZAGON",
      },
      {
        CITY: "MEANO",
      },
      {
        CITY: "MECINA FONDALES",
      },
      {
        CITY: "MECO",
      },
      {
        CITY: "MEDINA DE POMAR",
      },
      {
        CITY: "MEDINA DEL CAMPO",
      },
      {
        CITY: "MEDINA-SIDONIA",
      },
      {
        CITY: "MEDINYA",
      },
      {
        CITY: "MEDIO",
      },
      {
        CITY: "MEDIONA",
      },
      {
        CITY: "MEDRANO",
      },
      {
        CITY: "MEIRA",
      },
      {
        CITY: "MEIS",
      },
      {
        CITY: "MEJORADA",
      },
      {
        CITY: "MEJORADA DEL CAMPO",
      },
      {
        CITY: "MELIANA",
      },
      {
        CITY: "MELILLA",
      },
      {
        CITY: "MEMBRILLA",
      },
      {
        CITY: "MENDARO",
      },
      {
        CITY: "MENGIBAR",
      },
      {
        CITY: "MENTRIDA",
      },
      {
        CITY: "MEQUINENSA / MEQUINENZA",
      },
      {
        CITY: "MERCADAL",
      },
      {
        CITY: "MESTANZA",
      },
      {
        CITY: "MEZALOCHA",
      },
      {
        CITY: "MIAJADAS",
      },
      {
        CITY: "MIERES",
      },
      {
        CITY: "MIGUEL ESTEBAN",
      },
      {
        CITY: "MIGUELTURRA",
      },
      {
        CITY: "MIJAS",
      },
      {
        CITY: "MILAGRO",
      },
      {
        CITY: "MILLARES",
      },
      {
        CITY: "MINAS DE RIOTINTO",
      },
      {
        CITY: "MINISTERIO",
      },
      {
        CITY: "MINO",
      },
      {
        CITY: "MIONO",
      },
      {
        CITY: "MIRA",
      },
      {
        CITY: "MIRADOR",
      },
      {
        CITY: "MIRAFLORES DE LA SIERRA",
      },
      {
        CITY: "MIRAMAR",
      },
      {
        CITY: "MIRANDA DE ARGA",
      },
      {
        CITY: "MIRANDA DE EBRO",
      },
      {
        CITY: "MIRANDA DEL CASTANAR",
      },
      {
        CITY: "MISLATA",
      },
      {
        CITY: "MOANA",
      },
      {
        CITY: "MOCEJON",
      },
      {
        CITY: "MOGENTE",
      },
      {
        CITY: "MOGUER",
      },
      {
        CITY: "MOIA",
      },
      {
        CITY: "MOJA",
      },
      {
        CITY: "MOJACAR PUEBLO",
      },
      {
        CITY: "MOJADOS",
      },
      {
        CITY: "MOLINA DE ARAGON",
      },
      {
        CITY: "MOLINA DE SEGURA",
      },
      {
        CITY: "MOLINOS DE DUERO",
      },
      {
        CITY: "MOLINS DE REI",
      },
      {
        CITY: "MOLLINA",
      },
      {
        CITY: "MOLLO",
      },
      {
        CITY: "MOMBELTRAN",
      },
      {
        CITY: "MONACHIL",
      },
      {
        CITY: "MONCADA",
      },
      {
        CITY: "MONCOFAR",
      },
      {
        CITY: "MONDA",
      },
      {
        CITY: "MONDARIZ",
      },
      {
        CITY: "MONDONEDO",
      },
      {
        CITY: "MONDUJAR",
      },
      {
        CITY: "MONESTERIO",
      },
      {
        CITY: "MONFORTE DE LEMOS",
      },
      {
        CITY: "MONFORTE DEL CID",
      },
      {
        CITY: "MONISTROL DE CALDERS",
      },
      {
        CITY: "MONSERRAT",
      },
      {
        CITY: "MONT",
      },
      {
        CITY: "MONTAGUT",
      },
      {
        CITY: "MONTALBAN DE CORDOBA",
      },
      {
        CITY: "MONTCADA I REIXAC",
      },
      {
        CITY: "MONTE",
      },
      {
        CITY: "MONTEAGUDO",
      },
      {
        CITY: "MONTEALEGRE DEL CASTILLO",
      },
      {
        CITY: "MONTEARAGON",
      },
      {
        CITY: "MONTEFRIO",
      },
      {
        CITY: "MONTEHERMOSO",
      },
      {
        CITY: "MONTEJICAR",
      },
      {
        CITY: "MONTELLANO",
      },
      {
        CITY: "MONTEMAYOR DE PILILLA",
      },
      {
        CITY: "MONTERROSO",
      },
      {
        CITY: "MONTERRUBIO DE LA SERENA",
      },
      {
        CITY: "MONTESA",
      },
      {
        CITY: "MONTESQUIU",
      },
      {
        CITY: "MONTFULLA",
      },
      {
        CITY: "MONTGAT",
      },
      {
        CITY: "MONTIJO",
      },
      {
        CITY: "MONTILLA",
      },
      {
        CITY: "MONTILLANA",
      },
      {
        CITY: "MONTMAJOR",
      },
      {
        CITY: "MONTROY",
      },
      {
        CITY: "MONTSENY",
      },
      {
        CITY: "MONZALBARBA",
      },
      {
        CITY: "MONZON",
      },
      {
        CITY: "MORA",
      },
      {
        CITY: "MORA DE RUBIELOS",
      },
      {
        CITY: "MORALEJA DE ENMEDIO",
      },
      {
        CITY: "MORALZARZAL",
      },
      {
        CITY: "MORATA DE JALON",
      },
      {
        CITY: "MORATA DE TAJUNA",
      },
      {
        CITY: "MORATALLA",
      },
      {
        CITY: "MOREDA",
      },
      {
        CITY: "MORELLA",
      },
      {
        CITY: "MORISCOS",
      },
      {
        CITY: "MORRO DEL JABLE",
      },
      {
        CITY: "MORTERA",
      },
      {
        CITY: "MOSCAS DEL PARAMO",
      },
      {
        CITY: "MOSQUERUELA",
      },
      {
        CITY: "MOSTOLES",
      },
      {
        CITY: "MOTA DEL MARQUES",
      },
      {
        CITY: "MOTILLA DEL PALANCAR",
      },
      {
        CITY: "MOTRIL",
      },
      {
        CITY: "MOYA",
      },
      {
        CITY: "MUCHAMIEL",
      },
      {
        CITY: "MUGARDOS",
      },
      {
        CITY: "MULA",
      },
      {
        CITY: "MUNGIA",
      },
      {
        CITY: "MURA",
      },
      {
        CITY: "MURCIA",
      },
      {
        CITY: "MURIEDAS",
      },
      {
        CITY: "MURO",
      },
      {
        CITY: "MURO",
      },
      {
        CITY: "MURO DEL ALCOY",
      },
      {
        CITY: "MUROS",
      },
      {
        CITY: "MUROS DE NALON",
      },
      {
        CITY: "MUSEROS",
      },
      {
        CITY: "MUTILOA",
      },
      {
        CITY: "MUTILVA BAJA",
      },
      {
        CITY: "MUTRIKU",
      },
      {
        CITY: "NALDA",
      },
      {
        CITY: "NAMBROCA",
      },
      {
        CITY: "NANCLARES DE LA OCA",
      },
      {
        CITY: "NAQUERA",
      },
      {
        CITY: "NAVA DE LA ASUNCION",
      },
      {
        CITY: "NAVACERRADA",
      },
      {
        CITY: "NAVACONCEJO",
      },
      {
        CITY: "NAVAHERMOSA",
      },
      {
        CITY: "NAVALCAN",
      },
      {
        CITY: "NAVALCARNERO",
      },
      {
        CITY: "NAVALMORAL DE LA MATA",
      },
      {
        CITY: "NAVALPERAL DE PINARES",
      },
      {
        CITY: "NAVAMORCUENDE",
      },
      {
        CITY: "NAVARCLES",
      },
      {
        CITY: "NAVARIDAS",
      },
      {
        CITY: "NAVARRES",
      },
      {
        CITY: "NAVARRETE",
      },
      {
        CITY: "NAVAS DE RIOFRIO",
      },
      {
        CITY: "NAVAS DEL REY",
      },
      {
        CITY: "NAVATA",
      },
      {
        CITY: "NAVATEJERA",
      },
      {
        CITY: "NAVEZUELAS",
      },
      {
        CITY: "NAVIA",
      },
      {
        CITY: "NEDA",
      },
      {
        CITY: "NEDA",
      },
      {
        CITY: "NEGREIRA",
      },
      {
        CITY: "NEMBRO",
      },
      {
        CITY: "NERJA",
      },
      {
        CITY: "NERVA",
      },
      {
        CITY: "NESTARES",
      },
      {
        CITY: "NIGRAN",
      },
      {
        CITY: "NIJAR",
      },
      {
        CITY: "NINO PERDIDO",
      },
      {
        CITY: "NIVAR",
      },
      {
        CITY: "NOAIN",
      },
      {
        CITY: "NOJA",
      },
      {
        CITY: "NORA",
      },
      {
        CITY: "NORENA",
      },
      {
        CITY: "NOVALLAS",
      },
      {
        CITY: "NOVELDA",
      },
      {
        CITY: "NOVES",
      },
      {
        CITY: "NUBLEDO",
      },
      {
        CITY: "NUEVA",
      },
      {
        CITY: "NUEVO BAZTAN",
      },
      {
        CITY: "NUEZ DE EBRO",
      },
      {
        CITY: "NULES",
      },
      {
        CITY: "NUMANCIA DE LA SAGRA",
      },
      {
        CITY: "O BARCO DE VALDEORRAS",
      },
      {
        CITY: "O CARBALLINO",
      },
      {
        CITY: "O GROVE",
      },
      {
        CITY: "O MAZO",
      },
      {
        CITY: "OASIS (LA MARINA)",
      },
      {
        CITY: "OBANOS",
      },
      {
        CITY: "OCANA",
      },
      {
        CITY: "ODENA",
      },
      {
        CITY: "ODON",
      },
      {
        CITY: "OGIJARES",
      },
      {
        CITY: "OJEN",
      },
      {
        CITY: "OLABERRIA",
      },
      {
        CITY: "OLAZAGUTIA",
      },
      {
        CITY: "OLEIROS",
      },
      {
        CITY: "OLEIROS",
      },
      {
        CITY: "OLESA DE BONESVALLS",
      },
      {
        CITY: "OLESA DE MONTSERRAT",
      },
      {
        CITY: "OLIANA",
      },
      {
        CITY: "OLIAS DEL REY",
      },
      {
        CITY: "OLITE",
      },
      {
        CITY: "OLIVA",
      },
      {
        CITY: "OLIVA DE MERIDA",
      },
      {
        CITY: "OLIVA DE PLASENCIA",
      },
      {
        CITY: "OLIVARES",
      },
      {
        CITY: "OLIVELLA",
      },
      {
        CITY: "OLIVENZA",
      },
      {
        CITY: "OLMEDO",
      },
      {
        CITY: "OLOCAU",
      },
      {
        CITY: "OLOT",
      },
      {
        CITY: "OLULA DE CASTRO",
      },
      {
        CITY: "OLULA DEL RIO",
      },
      {
        CITY: "OLVEGA",
      },
      {
        CITY: "OLVERA",
      },
      {
        CITY: "ONDA",
      },
      {
        CITY: "ONDARA",
      },
      {
        CITY: "ONDARROA",
      },
      {
        CITY: "ONIL",
      },
      {
        CITY: "ONTIGOLA",
      },
      {
        CITY: "ONTINENA",
      },
      {
        CITY: "ONTINYENT",
      },
      {
        CITY: "ONTUR",
      },
      {
        CITY: "ORCE",
      },
      {
        CITY: "ORCOYEN",
      },
      {
        CITY: "ORDES",
      },
      {
        CITY: "ORDIS",
      },
      {
        CITY: "ORELLANA LA VIEJA",
      },
      {
        CITY: "ORIA",
      },
      {
        CITY: "ORIAL",
      },
      {
        CITY: "ORIHUELA",
      },
      {
        CITY: "ORIO",
      },
      {
        CITY: "OROSO",
      },
      {
        CITY: "ORPESA/OROPESA DEL MAR",
      },
      {
        CITY: "ORTIGUERO",
      },
      {
        CITY: "ORUNA",
      },
      {
        CITY: "ORUSCO",
      },
      {
        CITY: "OSUNA",
      },
      {
        CITY: "OTERO",
      },
      {
        CITY: "OTURA",
      },
      {
        CITY: "OUCES",
      },
      {
        CITY: "OURENSE",
      },
      {
        CITY: "OURENSE",
      },
      {
        CITY: "OUTES",
      },
      {
        CITY: "OVIEDO",
      },
      {
        CITY: "OYON",
      },
      {
        CITY: "OZA DE LOS RIOS",
      },
      {
        CITY: "PADILLA DEL DUCADO",
      },
      {
        CITY: "PADUL",
      },
      {
        CITY: "PAIPORTA",
      },
      {
        CITY: "PAJARA",
      },
      {
        CITY: "PALAFOLLS",
      },
      {
        CITY: "PALAFRUGELL",
      },
      {
        CITY: "PALAU",
      },
      {
        CITY: "PALENCIA",
      },
      {
        CITY: "PALMA DE MALLORCA",
      },
      {
        CITY: "PALMA DEL RIO",
      },
      {
        CITY: "PALMEIRA",
      },
      {
        CITY: "PALMERA",
      },
      {
        CITY: "PALMONES",
      },
      {
        CITY: "PALOL",
      },
      {
        CITY: "PALOMARES DEL RIO",
      },
      {
        CITY: "PALOS DE LA FRONTERA",
      },
      {
        CITY: "PALS",
      },
      {
        CITY: "PAMPLONA",
      },
      {
        CITY: "PANILLO",
      },
      {
        CITY: "PANTICOSA",
      },
      {
        CITY: "PANTOJA",
      },
      {
        CITY: "PARA",
      },
      {
        CITY: "PARACUELLOS",
      },
      {
        CITY: "PARACUELLOS DE JARAMA",
      },
      {
        CITY: "PARADA",
      },
      {
        CITY: "PARADAS",
      },
      {
        CITY: "PARADELA",
      },
      {
        CITY: "PARBAYON",
      },
      {
        CITY: "PARCENT",
      },
      {
        CITY: "PAREDES DE NAVA",
      },
      {
        CITY: "PARLA",
      },
      {
        CITY: "PARRES",
      },
      {
        CITY: "PASAI SAN PEDRO",
      },
      {
        CITY: "PASAIA",
      },
      {
        CITY: "PASTRANA",
      },
      {
        CITY: "PATERNA",
      },
      {
        CITY: "PATERNA DEL CAMPO",
      },
      {
        CITY: "PAU",
      },
      {
        CITY: "PAUELS",
      },
      {
        CITY: "PEDRAJAS",
      },
      {
        CITY: "PEDRAJAS DE SAN ESTEBAN",
      },
      {
        CITY: "PEDRALBA",
      },
      {
        CITY: "PEDREGUER",
      },
      {
        CITY: "PEDREZUELA",
      },
      {
        CITY: "PEDRO",
      },
      {
        CITY: "PEDRO ABAD",
      },
      {
        CITY: "PEDRO MUNOZ",
      },
      {
        CITY: "PEDROLA",
      },
      {
        CITY: "PEDROSILLO DE ALBA",
      },
      {
        CITY: "PEDROSILLO EL RALO",
      },
      {
        CITY: "PEGO",
      },
      {
        CITY: "PELAYOS DE LA PRESA",
      },
      {
        CITY: "PELIGROS",
      },
      {
        CITY: "PENAFIEL",
      },
      {
        CITY: "PENAFLOR",
      },
      {
        CITY: "PENAGOS",
      },
      {
        CITY: "PENARROYA-PUEBLONUEVO",
      },
      {
        CITY: "PERAFITA",
      },
      {
        CITY: "PERAFORT",
      },
      {
        CITY: "PERALEJO",
      },
      {
        CITY: "PERALES DEL ALFAMBRA",
      },
      {
        CITY: "PERALES DEL PUERTO",
      },
      {
        CITY: "PERATALLADA",
      },
      {
        CITY: "PERDIGUERA",
      },
      {
        CITY: "PEREIRO DE AGUIAR",
      },
      {
        CITY: "PERIEDO",
      },
      {
        CITY: "PERILLO",
      },
      {
        CITY: "PESCADOR",
      },
      {
        CITY: "PESCUEZA",
      },
      {
        CITY: "PETREL",
      },
      {
        CITY: "PETRES",
      },
      {
        CITY: "PEZUELA DE LAS TORRES",
      },
      {
        CITY: "PIEDRA",
      },
      {
        CITY: "PIEDRABUENA",
      },
      {
        CITY: "PIERA",
      },
      {
        CITY: "PILAR DE LA HORADADA",
      },
      {
        CITY: "PILAS",
      },
      {
        CITY: "PINA DE EBRO",
      },
      {
        CITY: "PINAR",
      },
      {
        CITY: "PINEDA DE MAR",
      },
      {
        CITY: "PINOS DEL VALLE",
      },
      {
        CITY: "PINOS PUENTE",
      },
      {
        CITY: "PINOSO",
      },
      {
        CITY: "PINSEQUE",
      },
      {
        CITY: "PINTO",
      },
      {
        CITY: "PIOZ",
      },
      {
        CITY: "PLACENCIA",
      },
      {
        CITY: "PLAN",
      },
      {
        CITY: "PLANES",
      },
      {
        CITY: "PLASENCIA",
      },
      {
        CITY: "PLASENZUELA",
      },
      {
        CITY: "PLAYA",
      },
      {
        CITY: "PLAYA BLANCA",
      },
      {
        CITY: "PLEGAMANS",
      },
      {
        CITY: "PLENTZIA",
      },
      {
        CITY: "POBLETE",
      },
      {
        CITY: "POBOA DE SAN XULIAN",
      },
      {
        CITY: "POBOLEDA",
      },
      {
        CITY: "POLA DE LAVIANA",
      },
      {
        CITY: "POLA DE LENA",
      },
      {
        CITY: "POLA DE SIERO",
      },
      {
        CITY: "POLAN",
      },
      {
        CITY: "POLINYA",
      },
      {
        CITY: "POLOP",
      },
      {
        CITY: "POMAR",
      },
      {
        CITY: "POMAR DE CINCA",
      },
      {
        CITY: "PONFERRADA",
      },
      {
        CITY: "PONT DE MOLINS",
      },
      {
        CITY: "PONTEJOS",
      },
      {
        CITY: "PONTEVEDRA",
      },
      {
        CITY: "PONTOS",
      },
      {
        CITY: "PONTS",
      },
      {
        CITY: "PORIS DE ABONA",
      },
      {
        CITY: "PORRINO",
      },
      {
        CITY: "PORT DE SAGUNT",
      },
      {
        CITY: "PORTILLO",
      },
      {
        CITY: "PORTILLO DE TOLEDO",
      },
      {
        CITY: "PORTO",
      },
      {
        CITY: "PORTO CRISTO",
      },
      {
        CITY: "PORTOCOLOM",
      },
      {
        CITY: "PORTONOVO",
      },
      {
        CITY: "PORTUGALETE",
      },
      {
        CITY: "PORTUS",
      },
      {
        CITY: "POSADAS",
      },
      {
        CITY: "POSADAS",
      },
      {
        CITY: "POZO DE GUADALAJARA",
      },
      {
        CITY: "POZO DE LA SERNA",
      },
      {
        CITY: "POZOAMARGO",
      },
      {
        CITY: "POZOBLANCO",
      },
      {
        CITY: "POZUELO DEL REY",
      },
      {
        CITY: "PRADEJON",
      },
      {
        CITY: "PRADO DEL REY",
      },
      {
        CITY: "PRAVIA",
      },
      {
        CITY: "PREMIA DE MAR",
      },
      {
        CITY: "PRIEGO",
      },
      {
        CITY: "PRIEGO DE CORDOBA",
      },
      {
        CITY: "PRUNA",
      },
      {
        CITY: "PRUVIA",
      },
      {
        CITY: "PUCOL",
      },
      {
        CITY: "PUEBLA DE ALFINDEN",
      },
      {
        CITY: "PUEBLA DE DON FADRIQUE",
      },
      {
        CITY: "PUEBLA DE LA CALZADA",
      },
      {
        CITY: "PUEBLA DEL MAESTRE",
      },
      {
        CITY: "PUENTE VIESGO",
      },
      {
        CITY: "PUENTE-GENIL",
      },
      {
        CITY: "PUENTELARRA",
      },
      {
        CITY: "PUENTENANSA",
      },
      {
        CITY: "PUERTO",
      },
      {
        CITY: "PUERTO",
      },
      {
        CITY: "PUERTO DE GANDIA",
      },
      {
        CITY: "PUERTO DE LA CRUZ",
      },
      {
        CITY: "PUERTO DEL CARMEN",
      },
      {
        CITY: "PUERTO DEL ROSARIO",
      },
      {
        CITY: "PUERTO LUMBRERAS",
      },
      {
        CITY: "PUERTO REAL",
      },
      {
        CITY: "PUERTOLLANO",
      },
      {
        CITY: "PUEYO",
      },
      {
        CITY: "PUEYO DE SANTA CRUZ",
      },
      {
        CITY: "PUIG",
      },
      {
        CITY: "PUIGPELAT",
      },
      {
        CITY: "PULGAR",
      },
      {
        CITY: "PULIANAS",
      },
      {
        CITY: "PURCHIL",
      },
      {
        CITY: "QUART DE POBLET",
      },
      {
        CITY: "QUEL",
      },
      {
        CITY: "QUER",
      },
      {
        CITY: "QUEROL",
      },
      {
        CITY: "QUIJORNA",
      },
      {
        CITY: "QUINCOCES DE YUSO",
      },
      {
        CITY: "QUINTANA DE LA SERENA",
      },
      {
        CITY: "QUINTANA DE RANEROS",
      },
      {
        CITY: "QUINTANAR DE LA ORDEN",
      },
      {
        CITY: "QUINTANAR DEL REY",
      },
      {
        CITY: "QUINTES",
      },
      {
        CITY: "RAFAL",
      },
      {
        CITY: "RAFELBUNYOL",
      },
      {
        CITY: "RAFELCOFER",
      },
      {
        CITY: "RAFELGUARAF",
      },
      {
        CITY: "RAIRIZ DE VEIGA",
      },
      {
        CITY: "RAJADELL",
      },
      {
        CITY: "RAMALES DE LA VICTORIA",
      },
      {
        CITY: "RASINES",
      },
      {
        CITY: "REAL",
      },
      {
        CITY: "REAL DE GANDIA",
      },
      {
        CITY: "REBOLLEDO",
      },
      {
        CITY: "REBORDANES",
      },
      {
        CITY: "RECAS",
      },
      {
        CITY: "REDES",
      },
      {
        CITY: "REDONDELA",
      },
      {
        CITY: "REDOVAN",
      },
      {
        CITY: "REINOSA",
      },
      {
        CITY: "RENDAR",
      },
      {
        CITY: "RENEDO",
      },
      {
        CITY: "RENEDO",
      },
      {
        CITY: "REOCIN",
      },
      {
        CITY: "REQUENA",
      },
      {
        CITY: "REUS",
      },
      {
        CITY: "REVELLINOS",
      },
      {
        CITY: "REYES",
      },
      {
        CITY: "RIANXO",
      },
      {
        CITY: "RIAZA",
      },
      {
        CITY: "RIBA",
      },
      {
        CITY: "RIBADAVIA",
      },
      {
        CITY: "RIBADEO",
      },
      {
        CITY: "RIBADESELLA",
      },
      {
        CITY: "RIBARROJA",
      },
      {
        CITY: "RIBEIRA",
      },
      {
        CITY: "RIBERA DEL FRESNO",
      },
      {
        CITY: "RIBES DE FRESER",
      },
      {
        CITY: "RICLA",
      },
      {
        CITY: "RIELLS I VIABREA",
      },
      {
        CITY: "RIELVES",
      },
      {
        CITY: "RINCON",
      },
      {
        CITY: "RINCON DE LA VICTORIA",
      },
      {
        CITY: "RINCON DE SOTO",
      },
      {
        CITY: "RIOJA",
      },
      {
        CITY: "RIOLA",
      },
      {
        CITY: "RIOS",
      },
      {
        CITY: "RIPOLL",
      },
      {
        CITY: "RIPOLLET",
      },
      {
        CITY: "RIUDELLOTS DE LA SELVA",
      },
      {
        CITY: "RIUDOMS",
      },
      {
        CITY: "RIUMORS",
      },
      {
        CITY: "RIVABELLOSA",
      },
      {
        CITY: "ROALES",
      },
      {
        CITY: "ROBLEDA-CERVANTES",
      },
      {
        CITY: "ROBLEDILLO DE GATA",
      },
      {
        CITY: "ROBLEDILLO DE LA VERA",
      },
      {
        CITY: "ROBLEDO DE CHAVELA",
      },
      {
        CITY: "ROCAFORT",
      },
      {
        CITY: "RODA DE BARA",
      },
      {
        CITY: "RODONYA",
      },
      {
        CITY: "ROJALES",
      },
      {
        CITY: "ROLDAN",
      },
      {
        CITY: "RON",
      },
      {
        CITY: "RONDA",
      },
      {
        CITY: "ROQUETAS DE MAR",
      },
      {
        CITY: "ROSELL",
      },
      {
        CITY: "ROSES",
      },
      {
        CITY: "ROTA",
      },
      {
        CITY: "RUBIELOS DE MORA",
      },
      {
        CITY: "RUENTE",
      },
      {
        CITY: "RUPIT",
      },
      {
        CITY: "RUS",
      },
      {
        CITY: "RUTE",
      },
      {
        CITY: "SAAMASAS",
      },
      {
        CITY: "SABADELL",
      },
      {
        CITY: "SABERO",
      },
      {
        CITY: "SABINANIGO",
      },
      {
        CITY: "SABIOTE",
      },
      {
        CITY: "SABUCEDO",
      },
      {
        CITY: "SADA",
      },
      {
        CITY: "SAGRA",
      },
      {
        CITY: "SAGUNTO",
      },
      {
        CITY: "SALAMANCA",
      },
      {
        CITY: "SALAS DE LOS INFANTES",
      },
      {
        CITY: "SALCEDA",
      },
      {
        CITY: "SALCEDO",
      },
      {
        CITY: "SALDANA",
      },
      {
        CITY: "SALINAS",
      },
      {
        CITY: "SALINAS",
      },
      {
        CITY: "SALLENT",
      },
      {
        CITY: "SALOBRAL",
      },
      {
        CITY: "SALOBRE",
      },
      {
        CITY: "SALOU",
      },
      {
        CITY: "SALT",
      },
      {
        CITY: "SALTERAS",
      },
      {
        CITY: "SALVATIERRA",
      },
      {
        CITY: "SALVATIERRA DE LOS BARROS",
      },
      {
        CITY: "SALVATIERRA DE MINO",
      },
      {
        CITY: "SAN ADRIAN",
      },
      {
        CITY: "SAN AGUSTIN DE GUADALIX",
      },
      {
        CITY: "SAN ANDRES DEL RABANEDO",
      },
      {
        CITY: "SAN ANTON",
      },
      {
        CITY: "SAN ANTONIO",
      },
      {
        CITY: "SAN BLAS",
      },
      {
        CITY: "SAN CLEMENTE",
      },
      {
        CITY: "SAN CRISTOBAL",
      },
      {
        CITY: "SAN CRISTOBAL DE ENTREVINAS",
      },
      {
        CITY: "SAN ESTEBAN DEL VALLE",
      },
      {
        CITY: "SAN FELICES",
      },
      {
        CITY: "SAN FELIZ DE TORIO",
      },
      {
        CITY: "SAN FERNANDO",
      },
      {
        CITY: "SAN FERNANDO",
      },
      {
        CITY: "SAN FERNANDO DE HENARES",
      },
      {
        CITY: "SAN ILDEFONSO",
      },
      {
        CITY: "SAN ISIDRO",
      },
      {
        CITY: "SAN ISIDRO",
      },
      {
        CITY: "SAN JAVIER",
      },
      {
        CITY: "SAN JORGE",
      },
      {
        CITY: "SAN JUAN",
      },
      {
        CITY: "SAN JUAN",
      },
      {
        CITY: "SAN JUAN DE ALICANTE",
      },
      {
        CITY: "SAN JUAN DE AZNALFARACHE",
      },
      {
        CITY: "SAN JUAN DE MORO",
      },
      {
        CITY: "SAN JUAN DEL PUERTO",
      },
      {
        CITY: "SAN JULIAN DE MUSKIZ",
      },
      {
        CITY: "SAN LUIS DE SABINILLAS",
      },
      {
        CITY: "SAN MARTIN",
      },
      {
        CITY: "SAN MARTIN DE LA VEGA",
      },
      {
        CITY: "SAN MARTIN DE UNX",
      },
      {
        CITY: "SAN MARTIN DE VALDEIGLESIAS",
      },
      {
        CITY: "SAN MARTINO",
      },
      {
        CITY: "SAN MIGUEL",
      },
      {
        CITY: "SAN MIGUEL",
      },
      {
        CITY: "SAN MIGUEL DE LAS DUENAS",
      },
      {
        CITY: "SAN NICOLAS",
      },
      {
        CITY: "SAN NICOLAS DEL PUERTO",
      },
      {
        CITY: "SAN PEDRO",
      },
      {
        CITY: "SAN PEDRO",
      },
      {
        CITY: "SAN PEDRO",
      },
      {
        CITY: "SAN PEDRO",
      },
      {
        CITY: "SAN PEDRO DEL PINATAR",
      },
      {
        CITY: "SAN RAFAEL",
      },
      {
        CITY: "SAN ROMAN",
      },
      {
        CITY: "SAN ROQUE",
      },
      {
        CITY: "SAN SEBASTIAN",
      },
      {
        CITY: "SAN SEBASTIAN DE LOS BALLESTEROS",
      },
      {
        CITY: "SAN VICENT DEL RASPEIG",
      },
      {
        CITY: "SAN VICENTE",
      },
      {
        CITY: "SAN VICENTE DE ALCANTARA",
      },
      {
        CITY: "SAN VICENTE DE LEON",
      },
      {
        CITY: "SANCIBRIAN",
      },
      {
        CITY: "SANCTI SPIRITUS",
      },
      {
        CITY: "SANGONERA LA VERDE",
      },
      {
        CITY: "SANLUCAR LA MAYOR",
      },
      {
        CITY: "SANT ANDREU DE LA BARCA",
      },
      {
        CITY: "SANT ANDREU DE LLAVANERES",
      },
      {
        CITY: "SANT BARTOMEU DEL GRAU",
      },
      {
        CITY: "SANT CARLES DE LA RAPITA",
      },
      {
        CITY: "SANT FELIU DE LLOBREGAT",
      },
      {
        CITY: "SANT JOAN DE LES ABADESSES",
      },
      {
        CITY: "SANT JOAN DE VILATORRADA",
      },
      {
        CITY: "SANT JOAN LES FONTS",
      },
      {
        CITY: "SANT JORDI",
      },
      {
        CITY: "SANT JOSEP DE SA TALAIA",
      },
      {
        CITY: "SANT JUST DESVERN",
      },
      {
        CITY: "SANT MARTI DE MALDA",
      },
      {
        CITY: "SANT PERE DE RIBES",
      },
      {
        CITY: "SANT PERE DE RIUDEBITLLES",
      },
      {
        CITY: "SANT PERE SANTA CATERINA I LA RIBERA",
      },
      {
        CITY: "SANT VICENC DE CASTELLET",
      },
      {
        CITY: "SANTA BARBARA",
      },
      {
        CITY: "SANTA BRIGIDA",
      },
      {
        CITY: "SANTA COLOMA DE FARNERS",
      },
      {
        CITY: "SANTA COLOMA DE GRAMENET",
      },
      {
        CITY: "SANTA COLOMA DE QUERALT",
      },
      {
        CITY: "SANTA CRISTINA",
      },
      {
        CITY: "SANTA CRISTINA DE ARO",
      },
      {
        CITY: "SANTA CRUZ",
      },
      {
        CITY: "SANTA CRUZ DE BEZANA",
      },
      {
        CITY: "SANTA CRUZ DE LA PALMA",
      },
      {
        CITY: "SANTA CRUZ DE LA ZARZA",
      },
      {
        CITY: "SANTA CRUZ DE MUDELA",
      },
      {
        CITY: "SANTA CRUZ DE TENERIFE",
      },
      {
        CITY: "SANTA EULALIA",
      },
      {
        CITY: "SANTA EULALIA",
      },
      {
        CITY: "SANTA EULALIA BAJERA",
      },
      {
        CITY: "SANTA FE",
      },
      {
        CITY: "SANTA FE DE MONDUJAR",
      },
      {
        CITY: "SANTA LUCIA",
      },
      {
        CITY: "SANTA LUCIA",
      },
      {
        CITY: "SANTA MARGARIDA",
      },
      {
        CITY: "SANTA MARIA",
      },
      {
        CITY: "SANTA MARIA",
      },
      {
        CITY: "SANTA MARIA DE PALAUTORDERA",
      },
      {
        CITY: "SANTA MARIA DEL CAMI",
      },
      {
        CITY: "SANTA MARIA DEL CAMPO",
      },
      {
        CITY: "SANTA MARIA DEL PARAMO",
      },
      {
        CITY: "SANTA MARINA DEL REY",
      },
      {
        CITY: "SANTA MARTA DE ORTIGUEIRA",
      },
      {
        CITY: "SANTA MARTA DE TORMES",
      },
      {
        CITY: "SANTA OLALLA",
      },
      {
        CITY: "SANTA OLIVA",
      },
      {
        CITY: "SANTA PAU",
      },
      {
        CITY: "SANTA PERPETUA DE MOGODA",
      },
      {
        CITY: "SANTA POLA",
      },
      {
        CITY: "SANTA UXIA DE RIBEIRA",
      },
      {
        CITY: "SANTALLA",
      },
      {
        CITY: "SANTANDER",
      },
      {
        CITY: "SANTIAGO",
      },
      {
        CITY: "SANTIAGO DE CARTES",
      },
      {
        CITY: "SANTIAGO DE COMPOSTELA",
      },
      {
        CITY: "SANTIAGO DE LA RIBERA",
      },
      {
        CITY: "SANTIAGO PENA",
      },
      {
        CITY: "SANTIPONCE",
      },
      {
        CITY: "SANTISTEBAN DEL PUERTO",
      },
      {
        CITY: "SANTIURDE DE TORANZO",
      },
      {
        CITY: "SANTO ANGEL",
      },
      {
        CITY: "SANTO DOMINGO",
      },
      {
        CITY: "SANTO DOMINGO DE LA CALZADA",
      },
      {
        CITY: "SANTOMERA",
      },
      {
        CITY: "SANTONA",
      },
      {
        CITY: "SANTOVENIA DE PISUERGA",
      },
      {
        CITY: "SANTPEDOR",
      },
      {
        CITY: "SANTURTZI",
      },
      {
        CITY: "SARANDONES",
      },
      {
        CITY: "SARDINA",
      },
      {
        CITY: "SARINENA",
      },
      {
        CITY: "SARON",
      },
      {
        CITY: "SARRIA",
      },
      {
        CITY: "SARRIA",
      },
      {
        CITY: "SARRIA DE TER",
      },
      {
        CITY: "SARROCA DE LLEIDA",
      },
      {
        CITY: "SAX",
      },
      {
        CITY: "SAYALONGA",
      },
      {
        CITY: "SEDAVI",
      },
      {
        CITY: "SEGORBE",
      },
      {
        CITY: "SEGOVIA",
      },
      {
        CITY: "SEGOVIA",
      },
      {
        CITY: "SEGURA",
      },
      {
        CITY: "SEGURA",
      },
      {
        CITY: "SEGURA DE LA SIERRA",
      },
      {
        CITY: "SELAYA",
      },
      {
        CITY: "SELVA",
      },
      {
        CITY: "SENIJA",
      },
      {
        CITY: "SEOANE",
      },
      {
        CITY: "SEPULVEDA",
      },
      {
        CITY: "SERENA",
      },
      {
        CITY: "SERRA",
      },
      {
        CITY: "SERRACINES",
      },
      {
        CITY: "SERRANILLOS DEL VALLE",
      },
      {
        CITY: "SERRATEIX",
      },
      {
        CITY: "SES SALINES",
      },
      {
        CITY: "SESENA",
      },
      {
        CITY: "SESENA NUEVO",
      },
      {
        CITY: "SESTAO",
      },
      {
        CITY: "SEVA",
      },
      {
        CITY: "SEVARES",
      },
      {
        CITY: "SEVILLA LA NUEVA",
      },
      {
        CITY: "SEVILLE",
      },
      {
        CITY: "SIERRA",
      },
      {
        CITY: "SIERRA DE FUENTES",
      },
      {
        CITY: "SIERRA DE LUNA",
      },
      {
        CITY: "SIETE IGLESIAS DE TRABANCOS",
      },
      {
        CITY: "SIGUEIRO",
      },
      {
        CITY: "SIGUENZA",
      },
      {
        CITY: "SILLA",
      },
      {
        CITY: "SILLEDA",
      },
      {
        CITY: "SILS",
      },
      {
        CITY: "SIMANCAS",
      },
      {
        CITY: "SITGES",
      },
      {
        CITY: "SIXTO",
      },
      {
        CITY: "SOBARZO",
      },
      {
        CITY: "SOBRADIEL",
      },
      {
        CITY: "SOBREMAZAS",
      },
      {
        CITY: "SOCUELLAMOS",
      },
      {
        CITY: "SODUPE",
      },
      {
        CITY: "SOJUELA",
      },
      {
        CITY: "SOLARES",
      },
      {
        CITY: "SOLLANA",
      },
      {
        CITY: "SOLLER",
      },
      {
        CITY: "SOLSONA",
      },
      {
        CITY: "SOMAHOZ",
      },
      {
        CITY: "SOMO",
      },
      {
        CITY: "SON CURT",
      },
      {
        CITY: "SON SERVERA",
      },
      {
        CITY: "SONEJA",
      },
      {
        CITY: "SOPELA",
      },
      {
        CITY: "SORBAS",
      },
      {
        CITY: "SORIA",
      },
      {
        CITY: "SOTILLO DE LA ADRADA",
      },
      {
        CITY: "SOTO",
      },
      {
        CITY: "SOTO DE CERRATO",
      },
      {
        CITY: "SOTO DE LA MARINA",
      },
      {
        CITY: "SOTOGRANDE",
      },
      {
        CITY: "SOTOPALACIOS",
      },
      {
        CITY: "SOTRONDIO",
      },
      {
        CITY: "SUANCES",
      },
      {
        CITY: "SUBIRATS",
      },
      {
        CITY: "SUDANELL",
      },
      {
        CITY: "SUECA",
      },
      {
        CITY: "SUFLI",
      },
      {
        CITY: "TABERNAS",
      },
      {
        CITY: "TABOADA",
      },
      {
        CITY: "TACORONTE",
      },
      {
        CITY: "TAFALLA",
      },
      {
        CITY: "TAHICHE",
      },
      {
        CITY: "TALAMANCA DE JARAMA",
      },
      {
        CITY: "TALARN",
      },
      {
        CITY: "TALAVERA DE LA REINA",
      },
      {
        CITY: "TAMAMES",
      },
      {
        CITY: "TAMARACEITE",
      },
      {
        CITY: "TARADELL",
      },
      {
        CITY: "TARANCON",
      },
      {
        CITY: "TARAZONA",
      },
      {
        CITY: "TARAZONA DE LA MANCHA",
      },
      {
        CITY: "TARIFA",
      },
      {
        CITY: "TARRAGONA",
      },
      {
        CITY: "TARREGA",
      },
      {
        CITY: "TEBA",
      },
      {
        CITY: "TEGUESTE",
      },
      {
        CITY: "TEGUISE",
      },
      {
        CITY: "TEJINA",
      },
      {
        CITY: "TELDE",
      },
      {
        CITY: "TENTENIGUADA",
      },
      {
        CITY: "TEO",
      },
      {
        CITY: "TERMINO",
      },
      {
        CITY: "TEROR",
      },
      {
        CITY: "TERRADILLOS",
      },
      {
        CITY: "TERRASSA",
      },
      {
        CITY: "TERRASSA",
      },
      {
        CITY: "TERRER",
      },
      {
        CITY: "TERUEL",
      },
      {
        CITY: "TEULADA",
      },
      {
        CITY: "TIAGUA",
      },
      {
        CITY: "TIANA",
      },
      {
        CITY: "TIAS",
      },
      {
        CITY: "TIGAIGA",
      },
      {
        CITY: "TIJARAFE",
      },
      {
        CITY: "TIJOLA",
      },
      {
        CITY: "TINEO",
      },
      {
        CITY: "TITULCIA",
      },
      {
        CITY: "TOBARRA",
      },
      {
        CITY: "TOLEDO",
      },
      {
        CITY: "TOLOSA",
      },
      {
        CITY: "TOMARES",
      },
      {
        CITY: "TOMBRIO DE ARRIBA",
      },
      {
        CITY: "TOMELLOSO",
      },
      {
        CITY: "TOMINO",
      },
      {
        CITY: "TONA",
      },
      {
        CITY: "TORA DE RIUBREGOS",
      },
      {
        CITY: "TORAL DE LOS VADOS",
      },
      {
        CITY: "TORDERA",
      },
      {
        CITY: "TORDESILLAS",
      },
      {
        CITY: "TORDILLOS",
      },
      {
        CITY: "TORENO",
      },
      {
        CITY: "TORIJA",
      },
      {
        CITY: "TORMANTOS",
      },
      {
        CITY: "TORO",
      },
      {
        CITY: "TORRALBA DE CALATRAVA",
      },
      {
        CITY: "TORRALBA DE OROPESA",
      },
      {
        CITY: "TORRE DEL CAMPO",
      },
      {
        CITY: "TORRE DEL MAR",
      },
      {
        CITY: "TORRE-PACHECO",
      },
      {
        CITY: "TORREAGUERA",
      },
      {
        CITY: "TORREBLASCOPEDRO",
      },
      {
        CITY: "TORRECABALLEROS",
      },
      {
        CITY: "TORRECILLA DE ALCANIZ",
      },
      {
        CITY: "TORREDEMBARRA",
      },
      {
        CITY: "TORREDONJIMENO",
      },
      {
        CITY: "TORREFARRERA",
      },
      {
        CITY: "TORREFETA",
      },
      {
        CITY: "TORREJON DE LA CALZADA",
      },
      {
        CITY: "TORREJON DEL REY",
      },
      {
        CITY: "TORRELAGUNA",
      },
      {
        CITY: "TORRELAVEGA",
      },
      {
        CITY: "TORRELLES DE LLOBREGAT",
      },
      {
        CITY: "TORRELLETAS",
      },
      {
        CITY: "TORRELODONES",
      },
      {
        CITY: "TORREMENGA",
      },
      {
        CITY: "TORREMOCHA DEL CAMPO",
      },
      {
        CITY: "TORREMOLINOS",
      },
      {
        CITY: "TORRENT",
      },
      {
        CITY: "TORRENT",
      },
      {
        CITY: "TORRENUEVA",
      },
      {
        CITY: "TORREPEROGIL",
      },
      {
        CITY: "TORRES",
      },
      {
        CITY: "TORRES DE BERRELLEN",
      },
      {
        CITY: "TORRES DE LA ALAMEDA",
      },
      {
        CITY: "TORRES DE SEGRE",
      },
      {
        CITY: "TORRESANDINO",
      },
      {
        CITY: "TORREVIEJA",
      },
      {
        CITY: "TORRIJOS",
      },
      {
        CITY: "TORROELLA DE FLUVIA",
      },
      {
        CITY: "TORROELLA DE MONTGRI",
      },
      {
        CITY: "TORROX",
      },
      {
        CITY: "TORTELLA",
      },
      {
        CITY: "TORTOLA DE HENARES",
      },
      {
        CITY: "TORTOSA",
      },
      {
        CITY: "TOTANA",
      },
      {
        CITY: "TOURO",
      },
      {
        CITY: "TOUS",
      },
      {
        CITY: "TRABADA",
      },
      {
        CITY: "TRAIGUERA",
      },
      {
        CITY: "TRASLAVINA",
      },
      {
        CITY: "TRASONA",
      },
      {
        CITY: "TRAZO",
      },
      {
        CITY: "TREBUJENA",
      },
      {
        CITY: "TREMP",
      },
      {
        CITY: "TRESPADERNE",
      },
      {
        CITY: "TREVINO",
      },
      {
        CITY: "TRIANA",
      },
      {
        CITY: "TRILLO",
      },
      {
        CITY: "TRIVES",
      },
      {
        CITY: "TROBAJO DEL CAMINO",
      },
      {
        CITY: "TRUJILLANOS",
      },
      {
        CITY: "TRUJILLO",
      },
      {
        CITY: "TUDELA",
      },
      {
        CITY: "TUDELA DE DUERO",
      },
      {
        CITY: "TUINEJE",
      },
      {
        CITY: "TURIA",
      },
      {
        CITY: "TURIS",
      },
      {
        CITY: "UBRIQUE",
      },
      {
        CITY: "UCEDA",
      },
      {
        CITY: "UGENA",
      },
      {
        CITY: "UGIJAR",
      },
      {
        CITY: "UJO",
      },
      {
        CITY: "ULLASTRELL",
      },
      {
        CITY: "UMBRETE",
      },
      {
        CITY: "URDA",
      },
      {
        CITY: "URDIAIN",
      },
      {
        CITY: "URDUNA",
      },
      {
        CITY: "URNIETA",
      },
      {
        CITY: "URROZ",
      },
      {
        CITY: "URTUELLA",
      },
      {
        CITY: "USANSOLO",
      },
      {
        CITY: "USERAS",
      },
      {
        CITY: "USURBIL",
      },
      {
        CITY: "UTEBO",
      },
      {
        CITY: "UTIEL",
      },
      {
        CITY: "UTRERA",
      },
      {
        CITY: "VACIAMADRID",
      },
      {
        CITY: "VAL DE SANTO DOMINGO",
      },
      {
        CITY: "VALCABADO",
      },
      {
        CITY: "VALDEAVERO",
      },
      {
        CITY: "VALDEAVERUELO",
      },
      {
        CITY: "VALDECABRAS",
      },
      {
        CITY: "VALDECILLA",
      },
      {
        CITY: "VALDEMAQUEDA",
      },
      {
        CITY: "VALDEMORILLO",
      },
      {
        CITY: "VALDEMORO",
      },
      {
        CITY: "VALDEPENAS",
      },
      {
        CITY: "VALDEPENAS DE LA SIERRA",
      },
      {
        CITY: "VALDEPOLO",
      },
      {
        CITY: "VALDERREY",
      },
      {
        CITY: "VALDERROBRES",
      },
      {
        CITY: "VALDERRUBIO",
      },
      {
        CITY: "VALDESOGO DE ABAJO",
      },
      {
        CITY: "VALDETORRES",
      },
      {
        CITY: "VALDETORRES DE JARAMA",
      },
      {
        CITY: "VALDEZUFRE",
      },
      {
        CITY: "VALDILECHA",
      },
      {
        CITY: "VALENCIA",
      },
      {
        CITY: "VALENCIA",
      },
      {
        CITY: "VALENCIA DE DON JUAN",
      },
      {
        CITY: "VALENCINA DE LA CONCEPCION",
      },
      {
        CITY: "VALL DE ALMONACID",
      },
      {
        CITY: "VALLADA",
      },
      {
        CITY: "VALLADOLID",
      },
      {
        CITY: "VALLADOLISES",
      },
      {
        CITY: "VALLDOREIX",
      },
      {
        CITY: "VALLE",
      },
      {
        CITY: "VALLE DE ORO",
      },
      {
        CITY: "VALLES",
      },
      {
        CITY: "VALLESECO",
      },
      {
        CITY: "VALLGORGUINA",
      },
      {
        CITY: "VALLIRANA",
      },
      {
        CITY: "VALLMOLL",
      },
      {
        CITY: "VALLS",
      },
      {
        CITY: "VALMOJADO",
      },
      {
        CITY: "VALPALMAS",
      },
      {
        CITY: "VALSEQUILLO DE GRAN CANARIA",
      },
      {
        CITY: "VALVERDE",
      },
      {
        CITY: "VALVERDE",
      },
      {
        CITY: "VALVERDE DEL CAMINO",
      },
      {
        CITY: "VALVERDE DEL FRESNO",
      },
      {
        CITY: "VALVERDE DEL MAJANO",
      },
      {
        CITY: "VEDRA",
      },
      {
        CITY: "VEJER DE LA FRONTERA",
      },
      {
        CITY: "VELEZ DE BENAUDALLA",
      },
      {
        CITY: "VELEZ-RUBIO",
      },
      {
        CITY: "VELILLA",
      },
      {
        CITY: "VELILLA DE SAN ANTONIO",
      },
      {
        CITY: "VENTA",
      },
      {
        CITY: "VENTA DE BANOS",
      },
      {
        CITY: "VENTALLO",
      },
      {
        CITY: "VENTURADA",
      },
      {
        CITY: "VERA",
      },
      {
        CITY: "VERDU",
      },
      {
        CITY: "VERGEL",
      },
      {
        CITY: "VERGES",
      },
      {
        CITY: "VERIN",
      },
      {
        CITY: "VIANA",
      },
      {
        CITY: "VIATOR",
      },
      {
        CITY: "VIC",
      },
      {
        CITY: "VICAR",
      },
      {
        CITY: "VICOLOZANO",
      },
      {
        CITY: "VIGO",
      },
      {
        CITY: "VILA",
      },
      {
        CITY: "VILA-REAL",
      },
      {
        CITY: "VILA-SECA",
      },
      {
        CITY: "VILABERTRAN",
      },
      {
        CITY: "VILACOLUM",
      },
      {
        CITY: "VILADECANS",
      },
      {
        CITY: "VILADRAU",
      },
      {
        CITY: "VILAFANT",
      },
      {
        CITY: "VILAGARCIA DE AROUSA",
      },
      {
        CITY: "VILAJUIGA",
      },
      {
        CITY: "VILAMALLA",
      },
      {
        CITY: "VILAMANISCLE",
      },
      {
        CITY: "VILANOVA",
      },
      {
        CITY: "VILANOVA DE AROUSA",
      },
      {
        CITY: "VILAPLANA",
      },
      {
        CITY: "VILASANTAR",
      },
      {
        CITY: "VILASSAR DE MAR",
      },
      {
        CITY: "VILATENIM",
      },
      {
        CITY: "VILAVELLA",
      },
      {
        CITY: "VILCHES",
      },
      {
        CITY: "VILLA",
      },
      {
        CITY: "VILLA DE DON FADRIQUE",
      },
      {
        CITY: "VILLA DEL PRADO",
      },
      {
        CITY: "VILLA DEL RIO",
      },
      {
        CITY: "VILLABALTER",
      },
      {
        CITY: "VILLABANEZ",
      },
      {
        CITY: "VILLABLINO",
      },
      {
        CITY: "VILLABONA",
      },
      {
        CITY: "VILLACANAS",
      },
      {
        CITY: "VILLACARRIEDO",
      },
      {
        CITY: "VILLACARRILLO",
      },
      {
        CITY: "VILLACASTIN",
      },
      {
        CITY: "VILLACONDIDE",
      },
      {
        CITY: "VILLACONEJOS",
      },
      {
        CITY: "VILLACUENDE",
      },
      {
        CITY: "VILLAFRANCA",
      },
      {
        CITY: "VILLAFRANCA",
      },
      {
        CITY: "VILLAFRANCA DE CORDOBA",
      },
      {
        CITY: "VILLAFRANCA DE LOS BARROS",
      },
      {
        CITY: "VILLAFRANCA DE LOS CABALLEROS",
      },
      {
        CITY: "VILLAFRANCA DEL CAMPO",
      },
      {
        CITY: "VILLAFRANQUEZA",
      },
      {
        CITY: "VILLAHERMOSA",
      },
      {
        CITY: "VILLAJOYOSA",
      },
      {
        CITY: "VILLALAR DE LOS COMUNEROS",
      },
      {
        CITY: "VILLALBA DE LOS ALCORES",
      },
      {
        CITY: "VILLALBILLA",
      },
      {
        CITY: "VILLALONGA",
      },
      {
        CITY: "VILLALPARDO",
      },
      {
        CITY: "VILLALUENGA",
      },
      {
        CITY: "VILLAMALEA",
      },
      {
        CITY: "VILLAMANRIQUE",
      },
      {
        CITY: "VILLAMANRIQUE DE LA CONDESA",
      },
      {
        CITY: "VILLAMANTA",
      },
      {
        CITY: "VILLAMARTIN",
      },
      {
        CITY: "VILLAMAYOR",
      },
      {
        CITY: "VILLAMAYOR",
      },
      {
        CITY: "VILLAMAYOR",
      },
      {
        CITY: "VILLAMAYOR DE SANTIAGO",
      },
      {
        CITY: "VILLAMEDIANA DE IREGUA",
      },
      {
        CITY: "VILLAMIEL DE TOLEDO",
      },
      {
        CITY: "VILLAMURIEL DE CERRATO",
      },
      {
        CITY: "VILLANUBLA",
      },
      {
        CITY: "VILLANUEVA",
      },
      {
        CITY: "VILLANUEVA DE AZOAGUE",
      },
      {
        CITY: "VILLANUEVA DE CASTELLON",
      },
      {
        CITY: "VILLANUEVA DE CORDOBA",
      },
      {
        CITY: "VILLANUEVA DE GALLEGO",
      },
      {
        CITY: "VILLANUEVA DE GOMEZ",
      },
      {
        CITY: "VILLANUEVA DE LA CANADA",
      },
      {
        CITY: "VILLANUEVA DE LA JARA",
      },
      {
        CITY: "VILLANUEVA DE LA REINA",
      },
      {
        CITY: "VILLANUEVA DE LA SERENA",
      },
      {
        CITY: "VILLANUEVA DE LA TORRE",
      },
      {
        CITY: "VILLANUEVA DE PERALES",
      },
      {
        CITY: "VILLANUEVA DE VILLAESCUSA",
      },
      {
        CITY: "VILLANUEVA DEL ARISCAL",
      },
      {
        CITY: "VILLANUEVA DEL ARZOBISPO",
      },
      {
        CITY: "VILLANUEVA DEL FRESNO",
      },
      {
        CITY: "VILLANUEVA DEL PARDILLO",
      },
      {
        CITY: "VILLANUEVA DEL RIO",
      },
      {
        CITY: "VILLANUEVA DEL ROSARIO",
      },
      {
        CITY: "VILLAQUILAMBRE",
      },
      {
        CITY: "VILLAR",
      },
      {
        CITY: "VILLAR DE GALLIMAZO",
      },
      {
        CITY: "VILLAR DE OLALLA",
      },
      {
        CITY: "VILLAR DEL ARZOBISPO",
      },
      {
        CITY: "VILLARALBO",
      },
      {
        CITY: "VILLARCAYO",
      },
      {
        CITY: "VILLAREJO",
      },
      {
        CITY: "VILLARES DE LA REINA",
      },
      {
        CITY: "VILLARES DEL SAZ",
      },
      {
        CITY: "VILLARICOS",
      },
      {
        CITY: "VILLARLUENGO",
      },
      {
        CITY: "VILLARQUEMADO",
      },
      {
        CITY: "VILLARREAL DE ALAVA",
      },
      {
        CITY: "VILLARROBLEDO",
      },
      {
        CITY: "VILLARRUBIA",
      },
      {
        CITY: "VILLARRUBIA DE LOS OJOS",
      },
      {
        CITY: "VILLARRUBIA DE SANTIAGO",
      },
      {
        CITY: "VILLARTA DE SAN JUAN",
      },
      {
        CITY: "VILLASANA DE MENA",
      },
      {
        CITY: "VILLASECA DE LA SAGRA",
      },
      {
        CITY: "VILLASEQUILLA DE YEPES",
      },
      {
        CITY: "VILLASEVIL",
      },
      {
        CITY: "VILLATUERTA",
      },
      {
        CITY: "VILLAVERDE DE LOS CESTOS",
      },
      {
        CITY: "VILLAVERDE DEL RIO",
      },
      {
        CITY: "VILLAVIAD",
      },
      {
        CITY: "VILLAVICIOSA",
      },
      {
        CITY: "VILLAVICIOSA",
      },
      {
        CITY: "VILLAVICIOSA DE ODON",
      },
      {
        CITY: "VILLENA",
      },
      {
        CITY: "VINAIXA",
      },
      {
        CITY: "VINALES",
      },
      {
        CITY: "VINALESA",
      },
      {
        CITY: "VINAROS",
      },
      {
        CITY: "VITIGUDINO",
      },
      {
        CITY: "VITORIA-GASTEIZ",
      },
      {
        CITY: "VIVEDA",
      },
      {
        CITY: "VIVEIRO",
      },
      {
        CITY: "VIVERO",
      },
      {
        CITY: "XUNQUEIRA DE ESPADANEDO",
      },
      {
        CITY: "YAIZA",
      },
      {
        CITY: "YEBES",
      },
      {
        CITY: "YECLA",
      },
      {
        CITY: "YELES",
      },
      {
        CITY: "YEPES",
      },
      {
        CITY: "YUNCLER",
      },
      {
        CITY: "YUNCOS",
      },
      {
        CITY: "YUNQUERA DE HENARES",
      },
      {
        CITY: "ZAFRA",
      },
      {
        CITY: "ZAHARA DE LOS ATUNES",
      },
      {
        CITY: "ZAHINOS",
      },
      {
        CITY: "ZALAMEA LA REAL",
      },
      {
        CITY: "ZAMORA",
      },
      {
        CITY: "ZAMUDIO",
      },
      {
        CITY: "ZARAGOZA",
      },
      {
        CITY: "ZARAMILLO",
      },
      {
        CITY: "ZARATAMO",
      },
      {
        CITY: "ZARATAN",
      },
      {
        CITY: "ZARAUTZ",
      },
      {
        CITY: "ZARZALEJO",
      },
      {
        CITY: "ZARZUELA",
      },
      {
        CITY: "ZIGA",
      },
      {
        CITY: "ZORRAQUIN",
      },
      {
        CITY: "ZUAZO DE VITORIA",
      },
      {
        CITY: "ZUBIA",
      },
      {
        CITY: "ZUBIRI",
      },
      {
        CITY: "ZUERA",
      },
      {
        CITY: "ZUMAIA",
      },
      {
        CITY: "ZUMARRAGA",
      },
    ],
  },
  {
    COUNTRY: "REPUBLIC OF LITHUANIA",
    CODE: "LT",
    CITIES: [
      {
        CITY: "ALYTUS",
      },
      {
        CITY: "ANCISKIAI",
      },
      {
        CITY: "ANTAKALNIS",
      },
      {
        CITY: "GARLIAVA",
      },
      {
        CITY: "IGNALINA",
      },
      {
        CITY: "JONAVA",
      },
      {
        CITY: "JURBARKAS",
      },
      {
        CITY: "JUSKONYS",
      },
      {
        CITY: "KAUNAS",
      },
      {
        CITY: "KRETINGA",
      },
      {
        CITY: "MASTAICIAI",
      },
      {
        CITY: "PALANGA",
      },
      {
        CITY: "PANEVEZYS",
      },
      {
        CITY: "SAKIAI",
      },
      {
        CITY: "SALCININKAI",
      },
      {
        CITY: "TRAKAI",
      },
      {
        CITY: "UKMERGE",
      },
      {
        CITY: "UZLIEDZIAI",
      },
      {
        CITY: "VENTA",
      },
      {
        CITY: "VIEVIS",
      },
      {
        CITY: "VILNIAUS APSKRITIS",
      },
      {
        CITY: "VILNIUS",
      },
      {
        CITY: "VISAGINAS",
      },
    ],
  },
  {
    COUNTRY: "SERBIA",
    CODE: "RS",
    CITIES: [
      {
        CITY: "ADA",
      },
      {
        CITY: "ALEKSINAC",
      },
      {
        CITY: "APATIN",
      },
      {
        CITY: "ARILJE",
      },
      {
        CITY: "AVALA",
      },
      {
        CITY: "BACKA TOPOLA",
      },
      {
        CITY: "BACKI JARAK",
      },
      {
        CITY: "BACKI PETROVAC",
      },
      {
        CITY: "BACKO GRADISTE",
      },
      {
        CITY: "BANATSKO NOVO SELO",
      },
      {
        CITY: "BARAJEVO",
      },
      {
        CITY: "BASAID",
      },
      {
        CITY: "BATAJNICA",
      },
      {
        CITY: "BECEJ",
      },
      {
        CITY: "BELGRADE",
      },
      {
        CITY: "BOCAR",
      },
      {
        CITY: "BOR",
      },
      {
        CITY: "CANTAVIR",
      },
      {
        CITY: "COKA",
      },
      {
        CITY: "CUKARICA",
      },
      {
        CITY: "CUPRIJA",
      },
      {
        CITY: "DEBELJACA",
      },
      {
        CITY: "DESPOTOVAC",
      },
      {
        CITY: "DOLOVO",
      },
      {
        CITY: "DRENOVAC",
      },
      {
        CITY: "FUTOG",
      },
      {
        CITY: "GLOZAN",
      },
      {
        CITY: "GOLUBINCI",
      },
      {
        CITY: "GORNJI MILANOVAC",
      },
      {
        CITY: "GROCKA",
      },
      {
        CITY: "INGIJA",
      },
      {
        CITY: "IZVOR",
      },
      {
        CITY: "JAGODINA",
      },
      {
        CITY: "KACAREVO",
      },
      {
        CITY: "KANJIZA",
      },
      {
        CITY: "KOVIN",
      },
      {
        CITY: "KRAGUJEVAC",
      },
      {
        CITY: "KRALJEVO",
      },
      {
        CITY: "LESKOVAC",
      },
      {
        CITY: "LOZNICA",
      },
      {
        CITY: "MELENCI",
      },
      {
        CITY: "NIKINCI",
      },
      {
        CITY: "NOVA PAZOVA",
      },
      {
        CITY: "NOVI BANOVCI",
      },
      {
        CITY: "NOVI BECEJ",
      },
      {
        CITY: "NOVI BELGRADE",
      },
      {
        CITY: "NOVI PAZAR",
      },
      {
        CITY: "NOVI SAD",
      },
      {
        CITY: "ODZACI",
      },
      {
        CITY: "PALIC",
      },
      {
        CITY: "PARACIN",
      },
      {
        CITY: "PETROVAC",
      },
      {
        CITY: "PETROVARADIN",
      },
      {
        CITY: "PIROT",
      },
      {
        CITY: "POPOVAC",
      },
      {
        CITY: "PRIBOJ",
      },
      {
        CITY: "PROKUPLJE",
      },
      {
        CITY: "RATKOVO",
      },
      {
        CITY: "RUMA",
      },
      {
        CITY: "RUMENKA",
      },
      {
        CITY: "SAVSKI VENAC",
      },
      {
        CITY: "SELO MLADENOVAC",
      },
      {
        CITY: "SENTA",
      },
      {
        CITY: "SIBAC",
      },
      {
        CITY: "SIMANOVCI",
      },
      {
        CITY: "SIRIG",
      },
      {
        CITY: "SMEDEREVO",
      },
      {
        CITY: "SOMBOR",
      },
      {
        CITY: "SRBOBRAN",
      },
      {
        CITY: "SREMCICA",
      },
      {
        CITY: "SREMSKA KAMENICA",
      },
      {
        CITY: "SREMSKA MITROVICA",
      },
      {
        CITY: "SREMSKI KARLOVCI",
      },
      {
        CITY: "STARA PAZOVA",
      },
      {
        CITY: "STARI BANOVCI",
      },
      {
        CITY: "SUBOTICA",
      },
      {
        CITY: "SURCIN",
      },
      {
        CITY: "SVILAJNAC",
      },
      {
        CITY: "SVRLJIG",
      },
      {
        CITY: "TEMERIN",
      },
      {
        CITY: "TITEL",
      },
      {
        CITY: "TORNJOS",
      },
      {
        CITY: "UGRINOVCI",
      },
      {
        CITY: "UMCARI",
      },
      {
        CITY: "UMKA",
      },
      {
        CITY: "VAJSKA",
      },
      {
        CITY: "VALJEVO",
      },
      {
        CITY: "VETERNIK",
      },
      {
        CITY: "VRBAS",
      },
      {
        CITY: "ZAJECAR",
      },
      {
        CITY: "ZEMUN POLJE",
      },
      {
        CITY: "ZLATIBOR",
      },
      {
        CITY: "ZRENJANIN",
      },
    ],
  },
  {
    COUNTRY: "ROMANIA",
    CODE: "RO",
    CITIES: [
      {
        CITY: "ADJUD",
      },
      {
        CITY: "AFUMATI",
      },
      {
        CITY: "AGNITA",
      },
      {
        CITY: "AIUD",
      },
      {
        CITY: "ALBA",
      },
      {
        CITY: "ALBA IULIA",
      },
      {
        CITY: "ALBESTI-PALEOLOGU",
      },
      {
        CITY: "ALESD",
      },
      {
        CITY: "ALEXANDRIA",
      },
      {
        CITY: "ALUNU",
      },
      {
        CITY: "APAHIDA",
      },
      {
        CITY: "APATA",
      },
      {
        CITY: "ARAD",
      },
      {
        CITY: "AVRIG",
      },
      {
        CITY: "BABA NOVAC",
      },
      {
        CITY: "BAIA MARE",
      },
      {
        CITY: "BAIA SPRIE",
      },
      {
        CITY: "BAICOI",
      },
      {
        CITY: "BAILE OLANESTI",
      },
      {
        CITY: "BAIUT",
      },
      {
        CITY: "BALOMIRU DE CAMP",
      },
      {
        CITY: "BALOTESTI",
      },
      {
        CITY: "BANESTI",
      },
      {
        CITY: "BARAOLT",
      },
      {
        CITY: "BARBULETU",
      },
      {
        CITY: "BASCOV",
      },
      {
        CITY: "BECICHERECU MIC",
      },
      {
        CITY: "BERCENI",
      },
      {
        CITY: "BERZASCA",
      },
      {
        CITY: "BETHAUSEN",
      },
      {
        CITY: "BIC",
      },
      {
        CITY: "BILCIURESTI",
      },
      {
        CITY: "BIRDA",
      },
      {
        CITY: "BLAJ",
      },
      {
        CITY: "BOBOLIA",
      },
      {
        CITY: "BOCSA",
      },
      {
        CITY: "BOGDANITA",
      },
      {
        CITY: "BOLINTIN DEAL",
      },
      {
        CITY: "BORCA",
      },
      {
        CITY: "BORSA",
      },
      {
        CITY: "BOZIENI",
      },
      {
        CITY: "BRAGADIRU",
      },
      {
        CITY: "BRANISTEA",
      },
      {
        CITY: "BRAZII DE SUS",
      },
      {
        CITY: "BREAZA",
      },
      {
        CITY: "BUCECEA",
      },
      {
        CITY: "BUCHAREST",
      },
      {
        CITY: "BUCSANI",
      },
      {
        CITY: "BUCU",
      },
      {
        CITY: "BUCURESTII NOI",
      },
      {
        CITY: "BUFTEA",
      },
      {
        CITY: "BUHUSI",
      },
      {
        CITY: "BUJOR",
      },
      {
        CITY: "BUMBESTI-JIU",
      },
      {
        CITY: "BUSTENI",
      },
      {
        CITY: "BUZIAS",
      },
      {
        CITY: "CALAFAT",
      },
      {
        CITY: "CALAN",
      },
      {
        CITY: "CALARASI",
      },
      {
        CITY: "CALINESTI",
      },
      {
        CITY: "CAMPENI",
      },
      {
        CITY: "CAMPIA",
      },
      {
        CITY: "CAMPIA TURZII",
      },
      {
        CITY: "CAMPINA",
      },
      {
        CITY: "CAMPULUNG MOLDOVENESC",
      },
      {
        CITY: "CAMPULUNG MUSCEL",
      },
      {
        CITY: "CARA",
      },
      {
        CITY: "CARACAL",
      },
      {
        CITY: "CARANSEBES",
      },
      {
        CITY: "CARBUNESTI",
      },
      {
        CITY: "CAREI",
      },
      {
        CITY: "CATCAU",
      },
      {
        CITY: "CATUNELE",
      },
      {
        CITY: "CERNISOARA",
      },
      {
        CITY: "CETATEA DE BALTA",
      },
      {
        CITY: "CETATUIA",
      },
      {
        CITY: "CHIAJNA",
      },
      {
        CITY: "CHISELET",
      },
      {
        CITY: "CHISINEU-CRIS",
      },
      {
        CITY: "CHISODA",
      },
      {
        CITY: "CHITILA",
      },
      {
        CITY: "CISNADIE",
      },
      {
        CITY: "CIUMANI",
      },
      {
        CITY: "CLUJ-NAPOCA",
      },
      {
        CITY: "COBADIN",
      },
      {
        CITY: "CODLEA",
      },
      {
        CITY: "COJASCA",
      },
      {
        CITY: "COMANA",
      },
      {
        CITY: "COMANESTI",
      },
      {
        CITY: "CORABIA",
      },
      {
        CITY: "CORBEANCA",
      },
      {
        CITY: "CORBITA",
      },
      {
        CITY: "CORLATENI",
      },
      {
        CITY: "COSBUC",
      },
      {
        CITY: "COSTESTI",
      },
      {
        CITY: "COTNARI",
      },
      {
        CITY: "COVASNA",
      },
      {
        CITY: "CRAIOVA",
      },
      {
        CITY: "CRANGASI",
      },
      {
        CITY: "CRASNA",
      },
      {
        CITY: "CRIS",
      },
      {
        CITY: "CRISTIAN",
      },
      {
        CITY: "CRISTURU SECUIESC",
      },
      {
        CITY: "CUGIR",
      },
      {
        CITY: "CURTICI",
      },
      {
        CITY: "CUZA VODA",
      },
      {
        CITY: "DAIA ROMANA",
      },
      {
        CITY: "DECEBAL",
      },
      {
        CITY: "DEJ",
      },
      {
        CITY: "DETA",
      },
      {
        CITY: "DEVA",
      },
      {
        CITY: "DIMITRIE CANTEMIR",
      },
      {
        CITY: "DITRAU",
      },
      {
        CITY: "DOAMNA",
      },
      {
        CITY: "DOBROESTI",
      },
      {
        CITY: "DOMNESTI",
      },
      {
        CITY: "DOR MARUNT",
      },
      {
        CITY: "DOROHOI",
      },
      {
        CITY: "DRAGOTESTI",
      },
      {
        CITY: "DRIDU",
      },
      {
        CITY: "DUDESTII VECHI",
      },
      {
        CITY: "DUMBRAVITA",
      },
      {
        CITY: "EFORIE",
      },
      {
        CITY: "FABRICA",
      },
      {
        CITY: "FAGET",
      },
      {
        CITY: "FAGETU",
      },
      {
        CITY: "FALCAU",
      },
      {
        CITY: "FALTICENI",
      },
      {
        CITY: "FETESTI",
      },
      {
        CITY: "FILIAS",
      },
      {
        CITY: "FILIASI",
      },
      {
        CITY: "FILIPESTII DE PADURE",
      },
      {
        CITY: "FLORESTI",
      },
      {
        CITY: "FLORESTI",
      },
      {
        CITY: "FLORESTI",
      },
      {
        CITY: "FOGARASCH",
      },
      {
        CITY: "FUNDULEA",
      },
      {
        CITY: "GAESTI",
      },
      {
        CITY: "GARLA-MARE",
      },
      {
        CITY: "GATAIA",
      },
      {
        CITY: "GEOAGIU",
      },
      {
        CITY: "GHEORGHENI",
      },
      {
        CITY: "GHEREA",
      },
      {
        CITY: "GHERLA",
      },
      {
        CITY: "GIARMATA",
      },
      {
        CITY: "GILAU",
      },
      {
        CITY: "GIROC",
      },
      {
        CITY: "GIULESTI",
      },
      {
        CITY: "GIURGIU",
      },
      {
        CITY: "GLINA",
      },
      {
        CITY: "GRAMESTI",
      },
      {
        CITY: "GROJDIBODU",
      },
      {
        CITY: "GURA HUMORULUI",
      },
      {
        CITY: "HANGULESTI",
      },
      {
        CITY: "HARSOVA",
      },
      {
        CITY: "HATEG",
      },
      {
        CITY: "HORIA",
      },
      {
        CITY: "HUEDIN",
      },
      {
        CITY: "HUMULESTI",
      },
      {
        CITY: "HUNEDOARA",
      },
      {
        CITY: "HUSI",
      },
      {
        CITY: "IADARA",
      },
      {
        CITY: "IERNUT",
      },
      {
        CITY: "ION GHICA",
      },
      {
        CITY: "IORGA",
      },
      {
        CITY: "IZVORU CRISULUI",
      },
      {
        CITY: "JIBOU",
      },
      {
        CITY: "JILAVA",
      },
      {
        CITY: "LACU",
      },
      {
        CITY: "LACU SINAIA",
      },
      {
        CITY: "LANCRAM",
      },
      {
        CITY: "LAZURI",
      },
      {
        CITY: "LEHLIU-GARA",
      },
      {
        CITY: "LENAUHEIM",
      },
      {
        CITY: "LIVEZI",
      },
      {
        CITY: "LUDESTI",
      },
      {
        CITY: "LUGOJ",
      },
      {
        CITY: "LUNGULETU",
      },
      {
        CITY: "MACIN",
      },
      {
        CITY: "MADARAS",
      },
      {
        CITY: "MAGURA",
      },
      {
        CITY: "MAGURELE",
      },
      {
        CITY: "MAGURELE",
      },
      {
        CITY: "MANDRULOC",
      },
      {
        CITY: "MANGALIA",
      },
      {
        CITY: "MANTA",
      },
      {
        CITY: "MARASHESHTY",
      },
      {
        CITY: "MARASTI",
      },
      {
        CITY: "MARGINEA",
      },
      {
        CITY: "MASLOC",
      },
      {
        CITY: "MATASARI",
      },
      {
        CITY: "MATEI",
      },
      {
        CITY: "MEDGIDIA",
      },
      {
        CITY: "MIERCUREA SIBIULUI",
      },
      {
        CITY: "MIERCUREA-CIUC",
      },
      {
        CITY: "MIHAI BRAVU",
      },
      {
        CITY: "MIHAIL KOGALNICEANU",
      },
      {
        CITY: "MIOVENI",
      },
      {
        CITY: "MIRCEA",
      },
      {
        CITY: "MIZIL",
      },
      {
        CITY: "MOARA VLASIEI",
      },
      {
        CITY: "MOCIRA",
      },
      {
        CITY: "MOGOSOAIA",
      },
      {
        CITY: "MOINESTI",
      },
      {
        CITY: "MOISEI",
      },
      {
        CITY: "MOLDOVA NOUA",
      },
      {
        CITY: "MORENI",
      },
      {
        CITY: "MORODA",
      },
      {
        CITY: "MOTRU",
      },
      {
        CITY: "MURFATLAR",
      },
      {
        CITY: "MUSENITA",
      },
      {
        CITY: "NANA",
      },
      {
        CITY: "NASAUD",
      },
      {
        CITY: "NAVODARI",
      },
      {
        CITY: "NEGRESTI",
      },
      {
        CITY: "NEGRU VODA",
      },
      {
        CITY: "NENCIULESTI",
      },
      {
        CITY: "NICOLAE BALCESCU",
      },
      {
        CITY: "NUCSOARA",
      },
      {
        CITY: "OBREJA",
      },
      {
        CITY: "OCNA MURES",
      },
      {
        CITY: "OCNITA",
      },
      {
        CITY: "ODOBESTI",
      },
      {
        CITY: "ODORHEIU SECUIESC",
      },
      {
        CITY: "OLTENITA",
      },
      {
        CITY: "ORADEA",
      },
      {
        CITY: "ORAVITA",
      },
      {
        CITY: "ORSOVA",
      },
      {
        CITY: "OTELU ROSU",
      },
      {
        CITY: "OTOPENI",
      },
      {
        CITY: "PADURENI",
      },
      {
        CITY: "PANCIU",
      },
      {
        CITY: "PANDURI",
      },
      {
        CITY: "PANTELIMON",
      },
      {
        CITY: "PASCANI",
      },
      {
        CITY: "PATARLAGELE",
      },
      {
        CITY: "PECICA",
      },
      {
        CITY: "PETRESTI",
      },
      {
        CITY: "PETRESTI",
      },
      {
        CITY: "PLOPENI",
      },
      {
        CITY: "PODU DAMBOVITEI",
      },
      {
        CITY: "POIANA ILVEI",
      },
      {
        CITY: "POPESTI",
      },
      {
        CITY: "POPESTI-LEORDENI",
      },
      {
        CITY: "POTLOGI",
      },
      {
        CITY: "PREJMER",
      },
      {
        CITY: "PRIM DECEMBRIE",
      },
      {
        CITY: "PROD",
      },
      {
        CITY: "PUCHENI",
      },
      {
        CITY: "RABAGANI",
      },
      {
        CITY: "RACARI",
      },
      {
        CITY: "RACOASA",
      },
      {
        CITY: "RACOVITA",
      },
      {
        CITY: "RADAUTI",
      },
      {
        CITY: "RADOVANU",
      },
      {
        CITY: "RAMNICU SARAT",
      },
      {
        CITY: "REGHIN",
      },
      {
        CITY: "ROATA DE JOS",
      },
      {
        CITY: "ROHIA",
      },
      {
        CITY: "ROMAN",
      },
      {
        CITY: "ROSIA",
      },
      {
        CITY: "ROSIORI DE VEDE",
      },
      {
        CITY: "SACELE",
      },
      {
        CITY: "SALATRUCEL",
      },
      {
        CITY: "SALCEA",
      },
      {
        CITY: "SALCIOARA",
      },
      {
        CITY: "SALONTA",
      },
      {
        CITY: "SANCRAIENI",
      },
      {
        CITY: "SANGEORGE",
      },
      {
        CITY: "SANGEORZ-BAI",
      },
      {
        CITY: "SANTIMBRU",
      },
      {
        CITY: "SATU MARE",
      },
      {
        CITY: "SAVENI",
      },
      {
        CITY: "SCHELA",
      },
      {
        CITY: "SEBES",
      },
      {
        CITY: "SEINI",
      },
      {
        CITY: "SFANTU GHEORGHE",
      },
      {
        CITY: "SFANTU GHEORGHE",
      },
      {
        CITY: "SFANTU-GHEORGHE",
      },
      {
        CITY: "SIBIU",
      },
      {
        CITY: "SICULENI",
      },
      {
        CITY: "SIGHISOARA",
      },
      {
        CITY: "SIMERIA",
      },
      {
        CITY: "SLATINA",
      },
      {
        CITY: "SLOBOZIA",
      },
      {
        CITY: "SOIMUS",
      },
      {
        CITY: "SOMCUTA MARE",
      },
      {
        CITY: "SOVATA",
      },
      {
        CITY: "SPIRU HARET",
      },
      {
        CITY: "STALPENI",
      },
      {
        CITY: "STANILESTI",
      },
      {
        CITY: "STEFANESTI",
      },
      {
        CITY: "STEFANESTII DE JOS",
      },
      {
        CITY: "STROESTI",
      },
      {
        CITY: "SUCEAVA",
      },
      {
        CITY: "SUSENII BARGAULUI",
      },
      {
        CITY: "SUTESTI",
      },
      {
        CITY: "TALISOARA",
      },
      {
        CITY: "TANDAREI",
      },
      {
        CITY: "TARGU GANGULESTI",
      },
      {
        CITY: "TARGU NEAMT",
      },
      {
        CITY: "TARTASESTI",
      },
      {
        CITY: "TATARANI",
      },
      {
        CITY: "TATARUSI",
      },
      {
        CITY: "TECUCI",
      },
      {
        CITY: "TEIU",
      },
      {
        CITY: "TEIUS",
      },
      {
        CITY: "TELEGA",
      },
      {
        CITY: "TIBENI",
      },
      {
        CITY: "TILEAGD",
      },
      {
        CITY: "TIMUS",
      },
      {
        CITY: "TINA",
      },
      {
        CITY: "TIPARI",
      },
      {
        CITY: "TITESTI",
      },
      {
        CITY: "TITU",
      },
      {
        CITY: "TOMESTI",
      },
      {
        CITY: "TOPOLOVENI",
      },
      {
        CITY: "TRAIAN",
      },
      {
        CITY: "TRESTIANA",
      },
      {
        CITY: "TULCEA",
      },
      {
        CITY: "TULNICI",
      },
      {
        CITY: "TUNARI",
      },
      {
        CITY: "TURDA",
      },
      {
        CITY: "TURNU MAGURELE",
      },
      {
        CITY: "TURNU RUIENI",
      },
      {
        CITY: "TUZLA",
      },
      {
        CITY: "UIOARA DE JOS",
      },
      {
        CITY: "UNGHENI",
      },
      {
        CITY: "UNIREA",
      },
      {
        CITY: "UNIREA",
      },
      {
        CITY: "URLATI",
      },
      {
        CITY: "URZICENI",
      },
      {
        CITY: "UTVIN",
      },
      {
        CITY: "VACARESTI",
      },
      {
        CITY: "VALCEA",
      },
      {
        CITY: "VALEA LUPULUI",
      },
      {
        CITY: "VALEA SEACA",
      },
      {
        CITY: "VALENII DE MUNTE",
      },
      {
        CITY: "VAMA",
      },
      {
        CITY: "VASLUI",
      },
      {
        CITY: "VATRA DORNEI",
      },
      {
        CITY: "VEDEA",
      },
      {
        CITY: "VETRESTI-HERASTRAU",
      },
      {
        CITY: "VICTORIA",
      },
      {
        CITY: "VIDELE",
      },
      {
        CITY: "VIDIN",
      },
      {
        CITY: "VIDRA",
      },
      {
        CITY: "VISEU DE JOS",
      },
      {
        CITY: "VISEU DE SUS",
      },
      {
        CITY: "VOINESTI",
      },
      {
        CITY: "VOITEG",
      },
      {
        CITY: "VOLUNTARI",
      },
      {
        CITY: "VORONET",
      },
      {
        CITY: "ZETEA",
      },
      {
        CITY: "ZIMNICEA",
      },
    ],
  },
  {
    COUNTRY: "PARAGUAY",
    CODE: "PY",
    CITIES: [
      {
        CITY: "AYOLAS",
      },
      {
        CITY: "BOQUERON",
      },
      {
        CITY: "CHORE",
      },
      {
        CITY: "CIUDAD DEL ESTE",
      },
      {
        CITY: "COLONIA MARIANO ROQUE ALONSO",
      },
      {
        CITY: "CORONEL OVIEDO",
      },
      {
        CITY: "FERNANDO DE LA MORA",
      },
      {
        CITY: "FUERTE OLIMPO",
      },
      {
        CITY: "HERNANDARIAS",
      },
      {
        CITY: "HOHENAU",
      },
      {
        CITY: "INDEPENDENCIA",
      },
      {
        CITY: "LA PAZ",
      },
      {
        CITY: "LIMPIO",
      },
      {
        CITY: "LOMA PLATA",
      },
      {
        CITY: "LUQUE",
      },
      {
        CITY: "NEMBY",
      },
      {
        CITY: "PRESIDENTE FRANCO",
      },
      {
        CITY: "SALTO DEL GUAIRA",
      },
      {
        CITY: "SAN ALBERTO",
      },
      {
        CITY: "SAN JUAN BAUTISTA",
      },
      {
        CITY: "SAN LORENZO",
      },
      {
        CITY: "SANTA RITA",
      },
      {
        CITY: "SANTA ROSA",
      },
      {
        CITY: "VILLA ELISA",
      },
      {
        CITY: "VILLA HAYES",
      },
      {
        CITY: "VILLARRICA",
      },
      {
        CITY: "YATAITY",
      },
    ],
  },
  {
    COUNTRY: "SUDAN",
    CODE: "SD",
    CITIES: [
      {
        CITY: "KASSALA",
      },
      {
        CITY: "KHARTOUM",
      },
      {
        CITY: "NYALA",
      },
      {
        CITY: "SHENDI",
      },
      {
        CITY: "THABIT",
      },
      {
        CITY: "UMM DURMAN",
      },
    ],
  },
  {
    COUNTRY: "PORTUGAL",
    CODE: "PT",
    CITIES: [
      {
        CITY: "ABOBADA",
      },
      {
        CITY: "ABRANTES",
      },
      {
        CITY: "ACORES",
      },
      {
        CITY: "AGUADA DE CIMA",
      },
      {
        CITY: "AGUALVA",
      },
      {
        CITY: "AGUCADOURA",
      },
      {
        CITY: "AGUIAR DA BEIRA MUNICIPALITY",
      },
      {
        CITY: "ALANDROAL",
      },
      {
        CITY: "ALBERGARIA-A-VELHA",
      },
      {
        CITY: "ALBUFEIRA",
      },
      {
        CITY: "ALCABIDECHE",
      },
      {
        CITY: "ALCACER DO SAL",
      },
      {
        CITY: "ALCAINS",
      },
      {
        CITY: "ALCANEDE",
      },
      {
        CITY: "ALCANENA",
      },
      {
        CITY: "ALCOBACA",
      },
      {
        CITY: "ALCOCHETE",
      },
      {
        CITY: "ALCOENTRE",
      },
      {
        CITY: "ALDEIA DE PAIO PIRES",
      },
      {
        CITY: "ALDEIA DO MEIO",
      },
      {
        CITY: "ALDEIA DOS FERNANDES",
      },
      {
        CITY: "ALENQUER",
      },
      {
        CITY: "ALFARELOS",
      },
      {
        CITY: "ALFENA",
      },
      {
        CITY: "ALFORNELOS",
      },
      {
        CITY: "ALFRAGIDE",
      },
      {
        CITY: "ALGES",
      },
      {
        CITY: "ALGOZ",
      },
      {
        CITY: "ALGUEIRAO",
      },
      {
        CITY: "ALHANDRA",
      },
      {
        CITY: "ALHOS VEDROS",
      },
      {
        CITY: "ALJEZUR",
      },
      {
        CITY: "ALJUSTREL",
      },
      {
        CITY: "ALMADA",
      },
      {
        CITY: "ALMANCIL",
      },
      {
        CITY: "ALMARGEM",
      },
      {
        CITY: "ALMEIRIM",
      },
      {
        CITY: "ALMODOVAR",
      },
      {
        CITY: "ALPIARCA",
      },
      {
        CITY: "ALPORTEL",
      },
      {
        CITY: "ALQUEIDAO DA SERRA",
      },
      {
        CITY: "ALTER DO CHAO",
      },
      {
        CITY: "ALTURA",
      },
      {
        CITY: "ALVAIAZERE",
      },
      {
        CITY: "ALVARINHOS",
      },
      {
        CITY: "ALVELOS",
      },
      {
        CITY: "ALVERCA",
      },
      {
        CITY: "AMADORA",
      },
      {
        CITY: "AMARANTE",
      },
      {
        CITY: "AMARES",
      },
      {
        CITY: "AMIAES DE CIMA",
      },
      {
        CITY: "AMOR",
      },
      {
        CITY: "AMORA",
      },
      {
        CITY: "AMOREIRA",
      },
      {
        CITY: "AMORIM",
      },
      {
        CITY: "ANADIA",
      },
      {
        CITY: "ANTA",
      },
      {
        CITY: "APULIA",
      },
      {
        CITY: "ARAZEDE",
      },
      {
        CITY: "ARCO DA CALHETA",
      },
      {
        CITY: "ARCOS DE VALDEVEZ",
      },
      {
        CITY: "ARCOZELO",
      },
      {
        CITY: "ARGANIL",
      },
      {
        CITY: "ARGONCILHE",
      },
      {
        CITY: "ARMACAO DE PERA",
      },
      {
        CITY: "AROUCA",
      },
      {
        CITY: "ARRAIOLOS",
      },
      {
        CITY: "ARRIFANA",
      },
      {
        CITY: "ARRONCHES",
      },
      {
        CITY: "ARRUDA DOS VINHOS",
      },
      {
        CITY: "ARVORE",
      },
      {
        CITY: "ASSUMAR",
      },
      {
        CITY: "ATALAIA",
      },
      {
        CITY: "ATOUGUIA DA BALEIA",
      },
      {
        CITY: "AVANCA",
      },
      {
        CITY: "AVEIRAS DE CIMA",
      },
      {
        CITY: "AVEIRO",
      },
      {
        CITY: "AVELAR",
      },
      {
        CITY: "AVIDOS",
      },
      {
        CITY: "AVINTES",
      },
      {
        CITY: "AZOIA",
      },
      {
        CITY: "AZUEIRA",
      },
      {
        CITY: "AZUREM",
      },
      {
        CITY: "BAGUIM",
      },
      {
        CITY: "BAGUIM DO MONTE",
      },
      {
        CITY: "BALEIA",
      },
      {
        CITY: "BALTAR",
      },
      {
        CITY: "BARCARENA",
      },
      {
        CITY: "BARCELOS",
      },
      {
        CITY: "BARREIRO",
      },
      {
        CITY: "BARROSAS",
      },
      {
        CITY: "BASTO",
      },
      {
        CITY: "BATALHA",
      },
      {
        CITY: "BEIRA",
      },
      {
        CITY: "BEJA",
      },
      {
        CITY: "BELAS",
      },
      {
        CITY: "BELMONTE",
      },
      {
        CITY: "BEMFICA",
      },
      {
        CITY: "BENAFIM",
      },
      {
        CITY: "BENAVENTE",
      },
      {
        CITY: "BENEDITA",
      },
      {
        CITY: "BICESSE",
      },
      {
        CITY: "BICO",
      },
      {
        CITY: "BOAVISTA",
      },
      {
        CITY: "BOBADELA",
      },
      {
        CITY: "BOLIQUEIME",
      },
      {
        CITY: "BOMBARRAL",
      },
      {
        CITY: "BORBA",
      },
      {
        CITY: "BOTICAS",
      },
      {
        CITY: "BOUCA",
      },
      {
        CITY: "BRAGA",
      },
      {
        CITY: "BRAGANCA",
      },
      {
        CITY: "BRITO",
      },
      {
        CITY: "BUCELAS",
      },
      {
        CITY: "BURACA",
      },
      {
        CITY: "CABANELAS",
      },
      {
        CITY: "CABECEIRAS DE BASTO",
      },
      {
        CITY: "CABECO DE VIDE",
      },
      {
        CITY: "CABECUDOS",
      },
      {
        CITY: "CABRELA",
      },
      {
        CITY: "CACELA",
      },
      {
        CITY: "CACEM",
      },
      {
        CITY: "CACILHAS",
      },
      {
        CITY: "CADAVAL",
      },
      {
        CITY: "CALDAS",
      },
      {
        CITY: "CALDAS DA RAINHA",
      },
      {
        CITY: "CALDAS DAS TAIPAS",
      },
      {
        CITY: "CALDAS DE SAO JORGE",
      },
      {
        CITY: "CALDAS DE VIZELA",
      },
      {
        CITY: "CALVAO",
      },
      {
        CITY: "CALVARIA DE CIMA",
      },
      {
        CITY: "CAMARATE",
      },
      {
        CITY: "CAMINHA",
      },
      {
        CITY: "CAMPANARIO",
      },
      {
        CITY: "CAMPIA",
      },
      {
        CITY: "CAMPINHO",
      },
      {
        CITY: "CAMPO",
      },
      {
        CITY: "CAMPO MAIOR",
      },
      {
        CITY: "CANECAS",
      },
      {
        CITY: "CANEDO",
      },
      {
        CITY: "CANEDO",
      },
      {
        CITY: "CANELAS",
      },
      {
        CITY: "CANHAS",
      },
      {
        CITY: "CANICAL",
      },
      {
        CITY: "CANICO",
      },
      {
        CITY: "CANIDELO",
      },
      {
        CITY: "CANO",
      },
      {
        CITY: "CANTANHEDE",
      },
      {
        CITY: "CAPARICA",
      },
      {
        CITY: "CARAMULO",
      },
      {
        CITY: "CARAPINHEIRA",
      },
      {
        CITY: "CARCAVELOS",
      },
      {
        CITY: "CARNAXIDE",
      },
      {
        CITY: "CARRAZEDO",
      },
      {
        CITY: "CARREGADO",
      },
      {
        CITY: "CARRICO",
      },
      {
        CITY: "CARTAXO",
      },
      {
        CITY: "CARVALHAL",
      },
      {
        CITY: "CARVALHO",
      },
      {
        CITY: "CARVALHOSA",
      },
      {
        CITY: "CARVOEIRO",
      },
      {
        CITY: "CASAL",
      },
      {
        CITY: "CASAL DA AREIA",
      },
      {
        CITY: "CASCAIS",
      },
      {
        CITY: "CASTANHEIRA DO RIBATEJO",
      },
      {
        CITY: "CASTELEJO",
      },
      {
        CITY: "CASTELO",
      },
      {
        CITY: "CASTELO BRANCO",
      },
      {
        CITY: "CASTELO DE PAIVA",
      },
      {
        CITY: "CASTRO DAIRE",
      },
      {
        CITY: "CASTRO MARIM",
      },
      {
        CITY: "CASTRO VERDE MUNICIPALITY",
      },
      {
        CITY: "CATRAIA DE SAO PAIO",
      },
      {
        CITY: "CAVALEIROS",
      },
      {
        CITY: "CAVALOES",
      },
      {
        CITY: "CAXIAS",
      },
      {
        CITY: "CEIRA",
      },
      {
        CITY: "CELORICO DE BASTO",
      },
      {
        CITY: "CERCAL",
      },
      {
        CITY: "CERNACHE",
      },
      {
        CITY: "CESAR",
      },
      {
        CITY: "CHAO DE COUCE",
      },
      {
        CITY: "CHARNECA",
      },
      {
        CITY: "CHAVES",
      },
      {
        CITY: "CIDADE",
      },
      {
        CITY: "CINFAES",
      },
      {
        CITY: "COIMBRA",
      },
      {
        CITY: "COIMBRA",
      },
      {
        CITY: "COLARES",
      },
      {
        CITY: "COLMEIAS",
      },
      {
        CITY: "CONDEIXA-A-NOVA",
      },
      {
        CITY: "CONSTANCE",
      },
      {
        CITY: "CONSTANCIA",
      },
      {
        CITY: "CORROIOS",
      },
      {
        CITY: "CORTEGACA",
      },
      {
        CITY: "COSTA DE CAPARICA",
      },
      {
        CITY: "COSTA DE LAVOS",
      },
      {
        CITY: "COVILHA",
      },
      {
        CITY: "CREIXOMIL",
      },
      {
        CITY: "CRUZ QUEBRADA",
      },
      {
        CITY: "CUBA",
      },
      {
        CITY: "CUCUJAES",
      },
      {
        CITY: "DALVARES",
      },
      {
        CITY: "DAMAIA",
      },
      {
        CITY: "DARQUE",
      },
      {
        CITY: "DOIS PORTOS",
      },
      {
        CITY: "DONA MARIA",
      },
      {
        CITY: "DOSSAOS",
      },
      {
        CITY: "EIXO",
      },
      {
        CITY: "ELVAS",
      },
      {
        CITY: "ENCARNACAO",
      },
      {
        CITY: "ENTRONCAMENTO",
      },
      {
        CITY: "ENTRONCAMENTO",
      },
      {
        CITY: "ERICEIRA",
      },
      {
        CITY: "ERMIDAS",
      },
      {
        CITY: "ESCAPAES",
      },
      {
        CITY: "ESCOURAL",
      },
      {
        CITY: "ESMORIZ",
      },
      {
        CITY: "ESPINHO",
      },
      {
        CITY: "ESPOSENDE",
      },
      {
        CITY: "ESTARREJA",
      },
      {
        CITY: "ESTOI",
      },
      {
        CITY: "ESTOMBAR",
      },
      {
        CITY: "ESTORIL",
      },
      {
        CITY: "ESTREMOZ",
      },
      {
        CITY: "EVORA",
      },
      {
        CITY: "FAFE",
      },
      {
        CITY: "FAMOES",
      },
      {
        CITY: "FANZERES",
      },
      {
        CITY: "FAO",
      },
      {
        CITY: "FARO",
      },
      {
        CITY: "FEIRA",
      },
      {
        CITY: "FELGUEIRAS",
      },
      {
        CITY: "FELGUEIRAS",
      },
      {
        CITY: "FELGUEIRAS",
      },
      {
        CITY: "FERRADOSA",
      },
      {
        CITY: "FERREIRA",
      },
      {
        CITY: "FERREIRA DO ALENTEJO",
      },
      {
        CITY: "FERREL",
      },
      {
        CITY: "FERRO",
      },
      {
        CITY: "FIAES",
      },
      {
        CITY: "FIGUEIRA DA FOZ MUNICIPALITY",
      },
      {
        CITY: "FIGUEIRA DE LORVAO",
      },
      {
        CITY: "FIGUEIRO DOS VINHOS",
      },
      {
        CITY: "FOGUETEIRO",
      },
      {
        CITY: "FOLGADOS",
      },
      {
        CITY: "FORTE",
      },
      {
        CITY: "FOZ DO SOUSA",
      },
      {
        CITY: "FRADELOS",
      },
      {
        CITY: "FREAMUNDE",
      },
      {
        CITY: "FRIELAS",
      },
      {
        CITY: "FUNCHAL",
      },
      {
        CITY: "FUNDAO",
      },
      {
        CITY: "GAFANHA DA NAZARE",
      },
      {
        CITY: "GALIZA",
      },
      {
        CITY: "GANDARA DOS OLIVAIS",
      },
      {
        CITY: "GANDRA",
      },
      {
        CITY: "GARRUCHAS",
      },
      {
        CITY: "GEMUNDE",
      },
      {
        CITY: "GODIM",
      },
      {
        CITY: "GOLEGA",
      },
      {
        CITY: "GONDOMAR",
      },
      {
        CITY: "GOUVEIA",
      },
      {
        CITY: "GRACA",
      },
      {
        CITY: "GRADIL",
      },
      {
        CITY: "GRANDOLA",
      },
      {
        CITY: "GRANJA",
      },
      {
        CITY: "GRANJA DO ULMEIRO",
      },
      {
        CITY: "GRIJO",
      },
      {
        CITY: "GUARDA",
      },
      {
        CITY: "GUARDIZELA",
      },
      {
        CITY: "GUIA",
      },
      {
        CITY: "GUIMARAES",
      },
      {
        CITY: "GULPILHARES",
      },
      {
        CITY: "IGREJA NOVA",
      },
      {
        CITY: "IGREJA NOVA",
      },
      {
        CITY: "ILHAVO",
      },
      {
        CITY: "INFESTA",
      },
      {
        CITY: "JOANE",
      },
      {
        CITY: "JOVIM",
      },
      {
        CITY: "JUNCAL",
      },
      {
        CITY: "LAGARINHOS",
      },
      {
        CITY: "LAGO",
      },
      {
        CITY: "LAGOA",
      },
      {
        CITY: "LAGOA",
      },
      {
        CITY: "LAGOA",
      },
      {
        CITY: "LAGOAS",
      },
      {
        CITY: "LAGOS",
      },
      {
        CITY: "LAJES",
      },
      {
        CITY: "LAMEGO",
      },
      {
        CITY: "LANDIM",
      },
      {
        CITY: "LAVOS",
      },
      {
        CITY: "LAVRA",
      },
      {
        CITY: "LAVRADIO",
      },
      {
        CITY: "LECA DA PALMEIRA",
      },
      {
        CITY: "LECA DO BAILIO",
      },
      {
        CITY: "LEIRIA",
      },
      {
        CITY: "LINDA A VELHA",
      },
      {
        CITY: "LINHACEIRA",
      },
      {
        CITY: "LINHO",
      },
      {
        CITY: "LISBON",
      },
      {
        CITY: "LORDELO",
      },
      {
        CITY: "LOULE",
      },
      {
        CITY: "LOUREL DE BAIXO",
      },
      {
        CITY: "LOURES",
      },
      {
        CITY: "LOURICAL",
      },
      {
        CITY: "LOUROSA",
      },
      {
        CITY: "LOUSA",
      },
      {
        CITY: "LOUSADA",
      },
      {
        CITY: "LOUSADO",
      },
      {
        CITY: "MACAS DE CAMINHO",
      },
      {
        CITY: "MACEDA",
      },
      {
        CITY: "MACEDO DE CAVALEIROS",
      },
      {
        CITY: "MACEIRA",
      },
      {
        CITY: "MACHICO",
      },
      {
        CITY: "MACIEIRA DA LIXA",
      },
      {
        CITY: "MADEIRA",
      },
      {
        CITY: "MAFRA",
      },
      {
        CITY: "MAIA",
      },
      {
        CITY: "MALVEIRA",
      },
      {
        CITY: "MALVEIRA DA SERRA",
      },
      {
        CITY: "MAMOUROS",
      },
      {
        CITY: "MANGUALDE",
      },
      {
        CITY: "MANIQUE DE BAIXO",
      },
      {
        CITY: "MANIQUE DO INTENDENTE",
      },
      {
        CITY: "MARCO",
      },
      {
        CITY: "MARCO DE CANAVESES",
      },
      {
        CITY: "MARGARIDE",
      },
      {
        CITY: "MARINHA",
      },
      {
        CITY: "MARINHA GRANDE",
      },
      {
        CITY: "MARINHAIS",
      },
      {
        CITY: "MARTELEIRA",
      },
      {
        CITY: "MARTINGANCA",
      },
      {
        CITY: "MASSAMA",
      },
      {
        CITY: "MASSARELOS",
      },
      {
        CITY: "MATOSINHOS MUNICIPALITY",
      },
      {
        CITY: "MEALHADA",
      },
      {
        CITY: "MEM MARTINS",
      },
      {
        CITY: "MESAO FRIO",
      },
      {
        CITY: "MESAO FRIO",
      },
      {
        CITY: "MEXILHOEIRA GRANDE",
      },
      {
        CITY: "MILHARADO",
      },
      {
        CITY: "MILHAZES",
      },
      {
        CITY: "MINDE",
      },
      {
        CITY: "MIRA",
      },
      {
        CITY: "MIRA",
      },
      {
        CITY: "MIRANDA DO CORVO",
      },
      {
        CITY: "MIRANDA DO DOURO",
      },
      {
        CITY: "MIRANDELA",
      },
      {
        CITY: "MOCARRIA",
      },
      {
        CITY: "MODIVAS",
      },
      {
        CITY: "MOITA",
      },
      {
        CITY: "MONCAO",
      },
      {
        CITY: "MONCHIQUE",
      },
      {
        CITY: "MONFORTE",
      },
      {
        CITY: "MONSANTO",
      },
      {
        CITY: "MONSARAZ",
      },
      {
        CITY: "MONTALVO",
      },
      {
        CITY: "MONTE ESTORIL",
      },
      {
        CITY: "MONTELAVAR",
      },
      {
        CITY: "MONTEMOR-O-NOVO",
      },
      {
        CITY: "MONTEMOR-O-VELHO",
      },
      {
        CITY: "MONTES",
      },
      {
        CITY: "MONTES",
      },
      {
        CITY: "MONTIJO",
      },
      {
        CITY: "MOREIRA DE CONEGOS",
      },
      {
        CITY: "MORTAGUA",
      },
      {
        CITY: "MOSCAVIDE",
      },
      {
        CITY: "MOURA",
      },
      {
        CITY: "MOURA",
      },
      {
        CITY: "MOURE",
      },
      {
        CITY: "MOURISCA DO VOUGA",
      },
      {
        CITY: "MOZELOS",
      },
      {
        CITY: "MURO",
      },
      {
        CITY: "MURTEDE",
      },
      {
        CITY: "MURTEIRA",
      },
      {
        CITY: "NADADOURO",
      },
      {
        CITY: "NEGRELOS",
      },
      {
        CITY: "NEIVA",
      },
      {
        CITY: "NELAS",
      },
      {
        CITY: "NESPEREIRA",
      },
      {
        CITY: "NINE",
      },
      {
        CITY: "NISA",
      },
      {
        CITY: "NOGUEIRA",
      },
      {
        CITY: "NOGUEIRA DA REGEDOURA",
      },
      {
        CITY: "NOGUEIRA DO CRAVO",
      },
      {
        CITY: "OBIDOS",
      },
      {
        CITY: "OBIDOS",
      },
      {
        CITY: "ODEMIRA",
      },
      {
        CITY: "ODIVELAS",
      },
      {
        CITY: "OEIRAS",
      },
      {
        CITY: "OIA",
      },
      {
        CITY: "OLHAO",
      },
      {
        CITY: "OLHOS DE AGUA",
      },
      {
        CITY: "OLIVAIS",
      },
      {
        CITY: "OLIVAIS",
      },
      {
        CITY: "OLIVAL",
      },
      {
        CITY: "OLIVEIRA",
      },
      {
        CITY: "OLIVEIRA DO BAIRRO",
      },
      {
        CITY: "OLIVEIRA DO DOURO",
      },
      {
        CITY: "OLIVEIRA DO HOSPITAL",
      },
      {
        CITY: "ORTIGA",
      },
      {
        CITY: "OTA",
      },
      {
        CITY: "OUREM",
      },
      {
        CITY: "OURIQUE",
      },
      {
        CITY: "OUTEIRO",
      },
      {
        CITY: "OVAR",
      },
      {
        CITY: "PACO DE ARCOS",
      },
      {
        CITY: "PADERNE",
      },
      {
        CITY: "PALHACA",
      },
      {
        CITY: "PALHAIS",
      },
      {
        CITY: "PALMEIRA",
      },
      {
        CITY: "PALMELA",
      },
      {
        CITY: "PARADA DE GONTA",
      },
      {
        CITY: "PARADA DO BISPO",
      },
      {
        CITY: "PARAISO",
      },
      {
        CITY: "PARCHAL",
      },
      {
        CITY: "PARDILHO",
      },
      {
        CITY: "PAREDE",
      },
      {
        CITY: "PAREDES",
      },
      {
        CITY: "PAREDES",
      },
      {
        CITY: "PAREDES DA BEIRA",
      },
      {
        CITY: "PAREDES DE COURA",
      },
      {
        CITY: "PATAIAS",
      },
      {
        CITY: "PAUL",
      },
      {
        CITY: "PECHAO",
      },
      {
        CITY: "PEDREIRAS",
      },
      {
        CITY: "PEDROSO",
      },
      {
        CITY: "PEDROUCOS",
      },
      {
        CITY: "PEGOES VELHOS",
      },
      {
        CITY: "PENACOVA",
      },
      {
        CITY: "PENAFIEL",
      },
      {
        CITY: "PENEDONO",
      },
      {
        CITY: "PENELA",
      },
      {
        CITY: "PENHA GARCIA",
      },
      {
        CITY: "PENICHE",
      },
      {
        CITY: "PENTEADO",
      },
      {
        CITY: "PEREIRA",
      },
      {
        CITY: "PERO PINHEIRO",
      },
      {
        CITY: "PEVIDEM",
      },
      {
        CITY: "PIEDADE",
      },
      {
        CITY: "PINHAL",
      },
      {
        CITY: "PINHAL NOVO",
      },
      {
        CITY: "PINHEIRO",
      },
      {
        CITY: "PINHEIRO DA BEMPOSTA",
      },
      {
        CITY: "PINHEIRO DE AZERE",
      },
      {
        CITY: "POCEIRAO",
      },
      {
        CITY: "POCO BARRETO",
      },
      {
        CITY: "POIARES",
      },
      {
        CITY: "POLIMA",
      },
      {
        CITY: "POMBAL",
      },
      {
        CITY: "PONTA",
      },
      {
        CITY: "PONTA DELGADA",
      },
      {
        CITY: "PONTA DO SOL",
      },
      {
        CITY: "PONTE",
      },
      {
        CITY: "PONTE DA BARCA",
      },
      {
        CITY: "PONTE DE LIMA",
      },
      {
        CITY: "PONTE DE SOR",
      },
      {
        CITY: "PONTE DE VAGOS",
      },
      {
        CITY: "PONTEVEL",
      },
      {
        CITY: "PONTINHA",
      },
      {
        CITY: "PORTALEGRE",
      },
      {
        CITY: "PORTEL",
      },
      {
        CITY: "PORTELA",
      },
      {
        CITY: "PORTIMAO",
      },
      {
        CITY: "PORTO",
      },
      {
        CITY: "PORTO",
      },
      {
        CITY: "PORTO",
      },
      {
        CITY: "PORTO",
      },
      {
        CITY: "PORTO ALTO",
      },
      {
        CITY: "PORTO DA CRUZ",
      },
      {
        CITY: "PORTO DE MOS",
      },
      {
        CITY: "PORTO MONIZ",
      },
      {
        CITY: "PORTO SALVO",
      },
      {
        CITY: "POVOA",
      },
      {
        CITY: "POVOA",
      },
      {
        CITY: "POVOA DA GALEGA",
      },
      {
        CITY: "POVOA DE LANHOSO",
      },
      {
        CITY: "POVOA DE SANTA IRIA",
      },
      {
        CITY: "POVOA DE SANTO ADRIAO",
      },
      {
        CITY: "POVOA DO FORNO",
      },
      {
        CITY: "PRADO",
      },
      {
        CITY: "PRAGAL",
      },
      {
        CITY: "PRAIA DA VITORIA",
      },
      {
        CITY: "PRAIA DE MIRA",
      },
      {
        CITY: "PRAIA DO ALMOXARIFE",
      },
      {
        CITY: "PRAIA DO CARVOEIRO",
      },
      {
        CITY: "PRAIA DO RIBATEJO",
      },
      {
        CITY: "PRAZINS",
      },
      {
        CITY: "PRIME",
      },
      {
        CITY: "PROENCA-A-NOVA",
      },
      {
        CITY: "QUARTEIRA",
      },
      {
        CITY: "QUEJAS",
      },
      {
        CITY: "QUELUZ",
      },
      {
        CITY: "QUELUZ DE BAIXO",
      },
      {
        CITY: "QUINCHAES",
      },
      {
        CITY: "QUINTA",
      },
      {
        CITY: "QUINTA",
      },
      {
        CITY: "QUINTA DO ANJO",
      },
      {
        CITY: "RAMADA",
      },
      {
        CITY: "RAMO",
      },
      {
        CITY: "RANA",
      },
      {
        CITY: "REAL",
      },
      {
        CITY: "REBORDOSA",
      },
      {
        CITY: "RECAREI",
      },
      {
        CITY: "REDONDO",
      },
      {
        CITY: "REGUEIRA DE PONTES",
      },
      {
        CITY: "REGUENGO GRANDE",
      },
      {
        CITY: "REGUENGOS DE MONSARAZ",
      },
      {
        CITY: "RIACHOS",
      },
      {
        CITY: "RIBA DE AVE",
      },
      {
        CITY: "RIBEIRA BRAVA",
      },
      {
        CITY: "RIBEIRA GRANDE",
      },
      {
        CITY: "RIBEIRAO",
      },
      {
        CITY: "RINCHOA",
      },
      {
        CITY: "RIO DE MOINHOS",
      },
      {
        CITY: "RIO DE MOURO",
      },
      {
        CITY: "RIO MAIOR",
      },
      {
        CITY: "RIO TINTO",
      },
      {
        CITY: "ROMARIZ",
      },
      {
        CITY: "RONFE",
      },
      {
        CITY: "ROQUE",
      },
      {
        CITY: "RORIZ",
      },
      {
        CITY: "RUNA",
      },
      {
        CITY: "SABUGAL",
      },
      {
        CITY: "SACAVEM",
      },
      {
        CITY: "SALREU",
      },
      {
        CITY: "SALTO",
      },
      {
        CITY: "SALVATERRA DE MAGOS",
      },
      {
        CITY: "SAMIL",
      },
      {
        CITY: "SAMORA CORREIA",
      },
      {
        CITY: "SAMOUCO",
      },
      {
        CITY: "SANDIM",
      },
      {
        CITY: "SANFINS DO DOURO",
      },
      {
        CITY: "SANGALHOS",
      },
      {
        CITY: "SANGUEDO",
      },
      {
        CITY: "SANTA BARBARA DE NEXE",
      },
      {
        CITY: "SANTA CATARINA",
      },
      {
        CITY: "SANTA CATARINA DA SERRA",
      },
      {
        CITY: "SANTA CLARA-A-VELHA",
      },
      {
        CITY: "SANTA COMBA DAO",
      },
      {
        CITY: "SANTA CRUZ",
      },
      {
        CITY: "SANTA IRIA DA AZOIA",
      },
      {
        CITY: "SANTA MARTA",
      },
      {
        CITY: "SANTA MARTA DE PENAGUIAO",
      },
      {
        CITY: "SANTANA",
      },
      {
        CITY: "SANTIAGO",
      },
      {
        CITY: "SANTIAGO",
      },
      {
        CITY: "SANTIAGO DA GUARDA",
      },
      {
        CITY: "SANTIAGO DE RIBA-UL",
      },
      {
        CITY: "SANTIAGO DO CACEM",
      },
      {
        CITY: "SANTO ANDRE",
      },
      {
        CITY: "SANTO ANTAO DO TOJAL",
      },
      {
        CITY: "SANTO ANTONIO",
      },
      {
        CITY: "SANTO ANTONIO DA CHARNECA",
      },
      {
        CITY: "SANTO TIRSO",
      },
      {
        CITY: "SAO BRAS",
      },
      {
        CITY: "SAO BRAS DE ALPORTEL",
      },
      {
        CITY: "SAO DOMINGOS DE RANA",
      },
      {
        CITY: "SAO FELIX DA MARINHA",
      },
      {
        CITY: "SAO FRANCISCO",
      },
      {
        CITY: "SAO JOAO",
      },
      {
        CITY: "SAO JOAO DA MADEIRA",
      },
      {
        CITY: "SAO JOAO DA TALHA",
      },
      {
        CITY: "SAO JOAO DAS LAMPAS",
      },
      {
        CITY: "SAO JOAO DE VER",
      },
      {
        CITY: "SAO JOAO DO CAMPO",
      },
      {
        CITY: "SAO JOAO DOS MONTES",
      },
      {
        CITY: "SAO JULIAO DO TOJAL",
      },
      {
        CITY: "SAO MAMEDE DE INFESTA",
      },
      {
        CITY: "SAO MARCOS",
      },
      {
        CITY: "SAO PEDRO DA CADEIRA",
      },
      {
        CITY: "SAO PEDRO DO ESTORIL",
      },
      {
        CITY: "SAO ROMAO DO CORONADO",
      },
      {
        CITY: "SAO ROQUE",
      },
      {
        CITY: "SAO VICENTE DA BEIRA",
      },
      {
        CITY: "SAO VICENTE DE LAFOES",
      },
      {
        CITY: "SARDOAL",
      },
      {
        CITY: "SARILHOS GRANDES",
      },
      {
        CITY: "SATAO",
      },
      {
        CITY: "SEIA",
      },
      {
        CITY: "SEIXAL",
      },
      {
        CITY: "SEIXAL",
      },
      {
        CITY: "SENDIM",
      },
      {
        CITY: "SENHORA DA HORA",
      },
      {
        CITY: "SEROA",
      },
      {
        CITY: "SERPA",
      },
      {
        CITY: "SERPINS",
      },
      {
        CITY: "SERTA",
      },
      {
        CITY: "SESIMBRA",
      },
      {
        CITY: "SETE RIOS",
      },
      {
        CITY: "SEVER DO VOUGA",
      },
      {
        CITY: "SILVA",
      },
      {
        CITY: "SILVARES",
      },
      {
        CITY: "SILVEIRA",
      },
      {
        CITY: "SILVES",
      },
      {
        CITY: "SINES MUNICIPALITY",
      },
      {
        CITY: "SINTRA",
      },
      {
        CITY: "SOALHEIRA",
      },
      {
        CITY: "SOBRADO",
      },
      {
        CITY: "SOBRADO DE PAIVA",
      },
      {
        CITY: "SOBRAL DE MONTE AGRACO",
      },
      {
        CITY: "SOBRALINHO",
      },
      {
        CITY: "SOBREDA",
      },
      {
        CITY: "SOBREIRO",
      },
      {
        CITY: "SOBROSA",
      },
      {
        CITY: "SOITO",
      },
      {
        CITY: "SOURE MUNICIPALITY",
      },
      {
        CITY: "SOUTO DA CARPALHOSA",
      },
      {
        CITY: "SOUZEL",
      },
      {
        CITY: "SUL",
      },
      {
        CITY: "TABUACO",
      },
      {
        CITY: "TALAIDE",
      },
      {
        CITY: "TAMEL",
      },
      {
        CITY: "TAROUCA",
      },
      {
        CITY: "TAVEIRO",
      },
      {
        CITY: "TAVIRA MUNICIPALITY",
      },
      {
        CITY: "TEIXOSO",
      },
      {
        CITY: "TELHADO",
      },
      {
        CITY: "TERCENA",
      },
      {
        CITY: "TERENA",
      },
      {
        CITY: "TIRES",
      },
      {
        CITY: "TOCHA",
      },
      {
        CITY: "TOMAR",
      },
      {
        CITY: "TONDELA",
      },
      {
        CITY: "TORRE DA MARINHA",
      },
      {
        CITY: "TORRE DE MONCORVO",
      },
      {
        CITY: "TORRES NOVAS",
      },
      {
        CITY: "TORRES VEDRAS",
      },
      {
        CITY: "TOUGUINHA",
      },
      {
        CITY: "TOURAIS",
      },
      {
        CITY: "TRAFARIA",
      },
      {
        CITY: "TRANCOSO",
      },
      {
        CITY: "TRANCOSO DE BAIXO",
      },
      {
        CITY: "TRAVANCA",
      },
      {
        CITY: "TREIXEDO",
      },
      {
        CITY: "TROFA",
      },
      {
        CITY: "TUNES",
      },
      {
        CITY: "TURCIFAL",
      },
      {
        CITY: "VAGOS",
      },
      {
        CITY: "VALADO DE FRADES",
      },
      {
        CITY: "VALBOM",
      },
      {
        CITY: "VALE",
      },
      {
        CITY: "VALE",
      },
      {
        CITY: "VALE",
      },
      {
        CITY: "VALE DA PINTA",
      },
      {
        CITY: "VALE DE ACOR",
      },
      {
        CITY: "VALE DE CAMBRA",
      },
      {
        CITY: "VALEGA",
      },
      {
        CITY: "VALENCA",
      },
      {
        CITY: "VALONGO",
      },
      {
        CITY: "VALVERDE",
      },
      {
        CITY: "VARZEA DO DOURO",
      },
      {
        CITY: "VENDA DO PINHEIRO",
      },
      {
        CITY: "VENDAS NOVAS",
      },
      {
        CITY: "VERMELHA",
      },
      {
        CITY: "VERMOIL",
      },
      {
        CITY: "VIALONGA",
      },
      {
        CITY: "VIANA DO ALENTEJO",
      },
      {
        CITY: "VIANA DO CASTELO",
      },
      {
        CITY: "VIDAGO",
      },
      {
        CITY: "VIDIGUEIRA",
      },
      {
        CITY: "VIEIRA DE LEIRIA",
      },
      {
        CITY: "VIEIRA DO MINHO",
      },
      {
        CITY: "VILA ALVA",
      },
      {
        CITY: "VILA BOA DE QUIRES",
      },
      {
        CITY: "VILA CHA",
      },
      {
        CITY: "VILA CHA DE OURIQUE",
      },
      {
        CITY: "VILA DE PORTO SANTO",
      },
      {
        CITY: "VILA DO BISPO",
      },
      {
        CITY: "VILA DO CONDE",
      },
      {
        CITY: "VILA FRANCA DE XIRA",
      },
      {
        CITY: "VILA FRANCA DO ROSARIO",
      },
      {
        CITY: "VILA NOGUEIRA DE AZEITAO",
      },
      {
        CITY: "VILA NOVA",
      },
      {
        CITY: "VILA NOVA",
      },
      {
        CITY: "VILA NOVA",
      },
      {
        CITY: "VILA NOVA",
      },
      {
        CITY: "VILA NOVA DA BARQUINHA",
      },
      {
        CITY: "VILA NOVA DE CERVEIRA",
      },
      {
        CITY: "VILA NOVA DE FAMALICAO",
      },
      {
        CITY: "VILA NOVA DE GAIA",
      },
      {
        CITY: "VILA NOVA DE PAIVA",
      },
      {
        CITY: "VILA PRAIA DE ANCORA",
      },
      {
        CITY: "VILA REAL",
      },
      {
        CITY: "VILA VERDE",
      },
      {
        CITY: "VILAR DE PERDIZES",
      },
      {
        CITY: "VILAR DO PARAISO",
      },
      {
        CITY: "VILAR DO PINHEIRO",
      },
      {
        CITY: "VILAR FORMOSO",
      },
      {
        CITY: "VILARINHO DAS PARANHEIRAS",
      },
      {
        CITY: "VILARINHO DE ARCOS",
      },
      {
        CITY: "VILARINHO DO BAIRRO",
      },
      {
        CITY: "VILELA",
      },
      {
        CITY: "VIMIEIRO",
      },
      {
        CITY: "VINHAIS",
      },
      {
        CITY: "VISEU",
      },
      {
        CITY: "VIZELA",
      },
      {
        CITY: "VIZELA",
      },
      {
        CITY: "ZAMBUJAL",
      },
      {
        CITY: "ZIBREIRA",
      },
    ],
  },
  {
    COUNTRY: "SINGAPORE",
    CODE: "SG",
    CITIES: [
      {
        CITY: "ANG MO KIO NEW TOWN",
      },
      {
        CITY: "AYER RAJA NEW TOWN",
      },
      {
        CITY: "BEDOK NEW TOWN",
      },
      {
        CITY: "BOON LAY",
      },
      {
        CITY: "BUKIT BATOK NEW TOWN",
      },
      {
        CITY: "BUKIT PANJANG NEW TOWN",
      },
      {
        CITY: "BUKIT TIMAH",
      },
      {
        CITY: "BUKIT TIMAH ESTATE",
      },
      {
        CITY: "CHANGI VILLAGE",
      },
      {
        CITY: "CHOA CHU KANG NEW TOWN",
      },
      {
        CITY: "CLEMENTI NEW TOWN",
      },
      {
        CITY: "HOLLAND VILLAGE",
      },
      {
        CITY: "HOUGANG",
      },
      {
        CITY: "JURONG EAST NEW TOWN",
      },
      {
        CITY: "JURONG TOWN",
      },
      {
        CITY: "JURONG WEST NEW TOWN",
      },
      {
        CITY: "KALANG",
      },
      {
        CITY: "KAMPONG PASIR RIS",
      },
      {
        CITY: "KEMBANGAN",
      },
      {
        CITY: "PANDAN VALLEY",
      },
      {
        CITY: "PASIR PANJANG",
      },
      {
        CITY: "PUNGGOL",
      },
      {
        CITY: "QUEENSTOWN ESTATE",
      },
      {
        CITY: "SERANGOON",
      },
      {
        CITY: "SIMEI NEW TOWN",
      },
      {
        CITY: "SINGAPORE",
      },
      {
        CITY: "TAI SENG",
      },
      {
        CITY: "TAMPINES NEW TOWN",
      },
      {
        CITY: "TANGLIN HALT",
      },
      {
        CITY: "TANJONG PAGAR",
      },
      {
        CITY: "TOA PAYOH NEW TOWN",
      },
      {
        CITY: "WOODLANDS NEW TOWN",
      },
      {
        CITY: "YEW TEE",
      },
      {
        CITY: "YISHUN NEW TOWN",
      },
    ],
  },
  {
    COUNTRY: "PANAMA",
    CODE: "PA",
    CITIES: [
      {
        CITY: "AGUADULCE",
      },
      {
        CITY: "ALBROOK",
      },
      {
        CITY: "ANCON",
      },
      {
        CITY: "AROSEMENA",
      },
      {
        CITY: "ARRAIJAN",
      },
      {
        CITY: "BALBOA",
      },
      {
        CITY: "BELLA VISTA",
      },
      {
        CITY: "BOCAS DEL TORO",
      },
      {
        CITY: "BOQUETE",
      },
      {
        CITY: "BUGABA",
      },
      {
        CITY: "CALIDONIA",
      },
      {
        CITY: "CAMPO ALEGRE",
      },
      {
        CITY: "CERRO VIENTO",
      },
      {
        CITY: "CHIGORE",
      },
      {
        CITY: "CHIRIQUI",
      },
      {
        CITY: "COCLE",
      },
      {
        CITY: "COROZAL",
      },
      {
        CITY: "CRISTOBAL",
      },
      {
        CITY: "CURUNDAME",
      },
      {
        CITY: "CURUNDU",
      },
      {
        CITY: "DAVID",
      },
      {
        CITY: "EL ARADO",
      },
      {
        CITY: "EL CANGREJO",
      },
      {
        CITY: "EL LAGO",
      },
      {
        CITY: "ELENA",
      },
      {
        CITY: "ENTRE RIOS",
      },
      {
        CITY: "FINCA BLANCO NUMERO UNO",
      },
      {
        CITY: "FUERTE AMADOR",
      },
      {
        CITY: "GUADALUPE",
      },
      {
        CITY: "JIMENEZ",
      },
      {
        CITY: "JUAN DIAZ",
      },
      {
        CITY: "JUAN DIAZ",
      },
      {
        CITY: "JUAN FRANCO",
      },
      {
        CITY: "KUBA",
      },
      {
        CITY: "LA CHORRERA",
      },
      {
        CITY: "LA EXPOSICION",
      },
      {
        CITY: "LA LOMA",
      },
      {
        CITY: "LAS MERCEDES",
      },
      {
        CITY: "LAS SABANAS",
      },
      {
        CITY: "LAS TABLAS",
      },
      {
        CITY: "LLANO TUGRI",
      },
      {
        CITY: "LOS ANGELES",
      },
      {
        CITY: "MUERTO",
      },
      {
        CITY: "MULATUPO",
      },
      {
        CITY: "PAITILLA",
      },
      {
        CITY: "PANAMA CITY",
      },
      {
        CITY: "PARQUE LEFEVRE",
      },
      {
        CITY: "PERU",
      },
      {
        CITY: "PLAYA BLANCA",
      },
      {
        CITY: "PLAZA",
      },
      {
        CITY: "PORTOBELO",
      },
      {
        CITY: "PUEBLO NUEVO",
      },
      {
        CITY: "QUEBRADA DE CAMILO",
      },
      {
        CITY: "RIO ABAJO",
      },
      {
        CITY: "SABALO",
      },
      {
        CITY: "SACRAMENTO",
      },
      {
        CITY: "SAN CRISTOBAL",
      },
      {
        CITY: "SAN FELIPE",
      },
      {
        CITY: "SAN FRANCISCO",
      },
      {
        CITY: "SAN MIGUELITO",
      },
      {
        CITY: "SANTA ANA",
      },
      {
        CITY: "SANTA CATALINA",
      },
      {
        CITY: "SANTA CLARA",
      },
      {
        CITY: "SANTA ISABEL",
      },
      {
        CITY: "SANTIAGO",
      },
      {
        CITY: "SANTO DOMINGO",
      },
      {
        CITY: "TOCUMEN",
      },
      {
        CITY: "TORRE",
      },
      {
        CITY: "TORRES BLUFF",
      },
      {
        CITY: "VERAGUAS",
      },
      {
        CITY: "VICTORIA",
      },
      {
        CITY: "VISTA ALEGRE",
      },
      {
        CITY: "VISTA HERMOSA",
      },
    ],
  },
  {
    COUNTRY: "PALESTINE",
    CODE: "PS",
    CITIES: [
      {
        CITY: "AL MAWASI",
      },
      {
        CITY: "BETHLEHEM",
      },
      {
        CITY: "GAZA",
      },
      {
        CITY: "HEBRON",
      },
      {
        CITY: "JENIN",
      },
      {
        CITY: "JERICHO",
      },
      {
        CITY: "NABLUS",
      },
      {
        CITY: "RAMALLAH",
      },
    ],
  },
  {
    COUNTRY: "SAN MARINO",
    CODE: "SM",
    CITIES: [
      {
        CITY: "ACQUAVIVA",
      },
      {
        CITY: "FALCIANO",
      },
      {
        CITY: "FIORENTINO",
      },
      {
        CITY: "SAN MARINO",
      },
      {
        CITY: "SERRAVALLE",
      },
    ],
  },
  {
    COUNTRY: "SLOVAKIA",
    CODE: "SK",
    CITIES: [
      {
        CITY: "BAHON",
      },
      {
        CITY: "BAKA",
      },
      {
        CITY: "BANOVCE NAD BEBRAVOU",
      },
      {
        CITY: "BARDEJOV",
      },
      {
        CITY: "BELA",
      },
      {
        CITY: "BELADICE",
      },
      {
        CITY: "BERNOLAKOVO",
      },
      {
        CITY: "BESENOV",
      },
      {
        CITY: "BLATNICA",
      },
      {
        CITY: "BOBROV",
      },
      {
        CITY: "BOHDANOVCE",
      },
      {
        CITY: "BOLERAZ",
      },
      {
        CITY: "BOROVCE",
      },
      {
        CITY: "BRANC",
      },
      {
        CITY: "BRATISLAVA",
      },
      {
        CITY: "BREZNO",
      },
      {
        CITY: "BREZOVA POD BRADLOM",
      },
      {
        CITY: "BYSTRICKA",
      },
      {
        CITY: "CECEJOVCE",
      },
      {
        CITY: "CECHYNCE",
      },
      {
        CITY: "CEROVA",
      },
      {
        CITY: "CERVENY HRADOK",
      },
      {
        CITY: "CERVENY KAMEN",
      },
      {
        CITY: "CHLEBNICE",
      },
      {
        CITY: "CHORVATSKY GROB",
      },
      {
        CITY: "CHTELNICA",
      },
      {
        CITY: "CIFER",
      },
      {
        CITY: "DETVA",
      },
      {
        CITY: "DIAKOVCE",
      },
      {
        CITY: "DIVIAKY NAD NITRICOU",
      },
      {
        CITY: "DLHA NAD ORAVOU",
      },
      {
        CITY: "DLHE KLCOVO",
      },
      {
        CITY: "DLHE NAD CIROCHOU",
      },
      {
        CITY: "DOLNA MARIKOVA",
      },
      {
        CITY: "DOLNA STREDA",
      },
      {
        CITY: "DOLNE ORESANY",
      },
      {
        CITY: "DOLNE VESTENICE",
      },
      {
        CITY: "DOLNY HRICOV",
      },
      {
        CITY: "DOLNY STAL",
      },
      {
        CITY: "DRUZSTEVNA PRI HORNADE",
      },
      {
        CITY: "ELIASOVCE",
      },
      {
        CITY: "FINTICE",
      },
      {
        CITY: "GALANTA",
      },
      {
        CITY: "GBELY",
      },
      {
        CITY: "GELNICA",
      },
      {
        CITY: "GIRALTOVCE",
      },
      {
        CITY: "GOLIANOVO",
      },
      {
        CITY: "HANDLOVA",
      },
      {
        CITY: "HENCOVCE",
      },
      {
        CITY: "HLINIK",
      },
      {
        CITY: "HLINIK NAD HRONOM",
      },
      {
        CITY: "HLOHOVEC",
      },
      {
        CITY: "HOLIC",
      },
      {
        CITY: "HOLICE",
      },
      {
        CITY: "HORNA LEHOTA",
      },
      {
        CITY: "HORNA STUBNA",
      },
      {
        CITY: "HORNE ORESANY",
      },
      {
        CITY: "HORNE SALIBY",
      },
      {
        CITY: "HORNY SMOKOVEC",
      },
      {
        CITY: "HRADISTE",
      },
      {
        CITY: "HRINOVA",
      },
      {
        CITY: "HROCHOTSLOVAKIA",
      },
      {
        CITY: "HRUBY SUR",
      },
      {
        CITY: "ILAVA",
      },
      {
        CITY: "ISTEBNE",
      },
      {
        CITY: "IVANKA PRI DUNAJI",
      },
      {
        CITY: "JABLONOVE",
      },
      {
        CITY: "JACOVCE",
      },
      {
        CITY: "JASENICA",
      },
      {
        CITY: "JASLOVSKE BOHUNICE",
      },
      {
        CITY: "JELENEC",
      },
      {
        CITY: "JELKA",
      },
      {
        CITY: "KAJAL",
      },
      {
        CITY: "KANIANKA",
      },
      {
        CITY: "KLATOVA NOVA VES",
      },
      {
        CITY: "KLOKOC",
      },
      {
        CITY: "KLOKOCOV",
      },
      {
        CITY: "KOLAROVO",
      },
      {
        CITY: "KOMJATICE",
      },
      {
        CITY: "KOPCANY",
      },
      {
        CITY: "KOSICKA NOVA VES",
      },
      {
        CITY: "KREMNICA",
      },
      {
        CITY: "KRIZOVANY NAD DUDVAHOM",
      },
      {
        CITY: "KROMPACHY",
      },
      {
        CITY: "KRUPINA",
      },
      {
        CITY: "KRUSOVCE",
      },
      {
        CITY: "KUKOVA",
      },
      {
        CITY: "KVETOSLAVOV",
      },
      {
        CITY: "KYSUCKY LIESKOVEC",
      },
      {
        CITY: "LADCE",
      },
      {
        CITY: "LEDNICKE ROVNE",
      },
      {
        CITY: "LEHOTA",
      },
      {
        CITY: "LEMESANY",
      },
      {
        CITY: "LEVICE",
      },
      {
        CITY: "LIKAVKA",
      },
      {
        CITY: "LIPTOVSKE REVUCE",
      },
      {
        CITY: "LIPTOVSKY HRADOK",
      },
      {
        CITY: "LOZORNO",
      },
      {
        CITY: "LUBINA",
      },
      {
        CITY: "LUDANICE",
      },
      {
        CITY: "LUKACOVCE",
      },
      {
        CITY: "MADUNICE",
      },
      {
        CITY: "MALACKY",
      },
      {
        CITY: "MALY LAPAS",
      },
      {
        CITY: "MARIANKA",
      },
      {
        CITY: "MARTIN",
      },
      {
        CITY: "MESTO",
      },
      {
        CITY: "MICHALOVCE",
      },
      {
        CITY: "MILOSLAVOV",
      },
      {
        CITY: "MOJMIROVCE",
      },
      {
        CITY: "MOLDAVA NAD BODVOU",
      },
      {
        CITY: "MORAVANY NAD VAHOM",
      },
      {
        CITY: "MUTNE",
      },
      {
        CITY: "MYJAVA",
      },
      {
        CITY: "NANA",
      },
      {
        CITY: "NEDEDZA",
      },
      {
        CITY: "NITRA",
      },
      {
        CITY: "NITRIANSKE RUDNO",
      },
      {
        CITY: "NITRIANSKE SUCANY",
      },
      {
        CITY: "NIZNA",
      },
      {
        CITY: "NIZNA KAMENICA",
      },
      {
        CITY: "NOVA BANA",
      },
      {
        CITY: "NOVAKY",
      },
      {
        CITY: "NOVE SADY",
      },
      {
        CITY: "NOVY SALAS",
      },
      {
        CITY: "NYROVCE",
      },
      {
        CITY: "OKOC",
      },
      {
        CITY: "OLESNA",
      },
      {
        CITY: "OPATOVCE NAD NITROU",
      },
      {
        CITY: "OPOJ",
      },
      {
        CITY: "ORAVSKE VESELE",
      },
      {
        CITY: "PASTUCHOV",
      },
      {
        CITY: "PATA",
      },
      {
        CITY: "PERNEK",
      },
      {
        CITY: "PEZINOK",
      },
      {
        CITY: "PLAVE VOZOKANY",
      },
      {
        CITY: "PLAVECKE PODHRADIE",
      },
      {
        CITY: "PLIESOVCE",
      },
      {
        CITY: "POBEDIM",
      },
      {
        CITY: "PODBREZOVA",
      },
      {
        CITY: "PODOLIE",
      },
      {
        CITY: "POLOMKA",
      },
      {
        CITY: "POPRAD",
      },
      {
        CITY: "POTVORICE",
      },
      {
        CITY: "PRAHA",
      },
      {
        CITY: "PRAKOVCE",
      },
      {
        CITY: "PRAZNOVCE",
      },
      {
        CITY: "PRIEVIDZA",
      },
      {
        CITY: "PROC",
      },
      {
        CITY: "RECA",
      },
      {
        CITY: "ROSINA",
      },
      {
        CITY: "ROVINKA",
      },
      {
        CITY: "RUZINDOL",
      },
      {
        CITY: "SABINOV",
      },
      {
        CITY: "SAMORIN",
      },
      {
        CITY: "SARISSKE BOHDANOVCE",
      },
      {
        CITY: "SECOVCE",
      },
      {
        CITY: "SECOVSKA POLIANKA",
      },
      {
        CITY: "SELICE",
      },
      {
        CITY: "SELPICE",
      },
      {
        CITY: "SENA",
      },
      {
        CITY: "SENEC",
      },
      {
        CITY: "SENICA",
      },
      {
        CITY: "SIPKOVE",
      },
      {
        CITY: "SKALICA",
      },
      {
        CITY: "SLADKOVICOVO",
      },
      {
        CITY: "SLOVENSKA VES",
      },
      {
        CITY: "SLOVENSKY GROB",
      },
      {
        CITY: "SMIZANY",
      },
      {
        CITY: "SNINA",
      },
      {
        CITY: "SOBRANCE",
      },
      {
        CITY: "SOLOSNICA",
      },
      {
        CITY: "SPACINCE",
      },
      {
        CITY: "SPISSKY HRUSOV",
      },
      {
        CITY: "SPISSKY STIAVNIK",
      },
      {
        CITY: "SPISSKY STVRTOK",
      },
      {
        CITY: "STARA LESNA",
      },
      {
        CITY: "STARA TURA",
      },
      {
        CITY: "STARY SMOKOVEC",
      },
      {
        CITY: "STASKOV",
      },
      {
        CITY: "STEFANOV",
      },
      {
        CITY: "STROPKOV",
      },
      {
        CITY: "STUDIENKA",
      },
      {
        CITY: "STUPAVA",
      },
      {
        CITY: "SUCANY",
      },
      {
        CITY: "SUCHA NAD PARNOU",
      },
      {
        CITY: "SVATY KRIZ",
      },
      {
        CITY: "SVATY PETER",
      },
      {
        CITY: "SVIT",
      },
      {
        CITY: "SVODIN",
      },
      {
        CITY: "TAJOV",
      },
      {
        CITY: "TERCHOVA",
      },
      {
        CITY: "TESEDIKOVO",
      },
      {
        CITY: "TLMACE",
      },
      {
        CITY: "TOMASOV",
      },
      {
        CITY: "TORYSKY",
      },
      {
        CITY: "TRNAVA",
      },
      {
        CITY: "TRNOVEC NAD VAHOM",
      },
      {
        CITY: "TRSTENA",
      },
      {
        CITY: "TURANY",
      },
      {
        CITY: "TVRDOSIN",
      },
      {
        CITY: "TVRDOSOVCE",
      },
      {
        CITY: "UDAVSKE",
      },
      {
        CITY: "URMINCE",
      },
      {
        CITY: "VAHOVCE",
      },
      {
        CITY: "VARHANOVCE",
      },
      {
        CITY: "VARIN",
      },
      {
        CITY: "VECELKOV",
      },
      {
        CITY: "VELCICE",
      },
      {
        CITY: "VELICNA",
      },
      {
        CITY: "VELKY MEDER",
      },
      {
        CITY: "VINNE",
      },
      {
        CITY: "VITANOVA",
      },
      {
        CITY: "VLCKOVCE",
      },
      {
        CITY: "VLKANOVA",
      },
      {
        CITY: "VOJCICE",
      },
      {
        CITY: "VRABLE",
      },
      {
        CITY: "VRUTKY",
      },
      {
        CITY: "VYSNE RASLAVICE",
      },
      {
        CITY: "VYSNY ORLIK",
      },
      {
        CITY: "ZAKAMENNE",
      },
      {
        CITY: "ZAVOD",
      },
      {
        CITY: "ZBINCE",
      },
      {
        CITY: "ZELENEC",
      },
      {
        CITY: "ZELIEZOVCE",
      },
      {
        CITY: "ZIAR NAD HRONOM",
      },
      {
        CITY: "ZIKAVA",
      },
      {
        CITY: "ZLATE",
      },
      {
        CITY: "ZUBEREC",
      },
      {
        CITY: "ZVOLEN",
      },
    ],
  },
  {
    COUNTRY: "SENEGAL",
    CODE: "SN",
    CITIES: [
      {
        CITY: "BOUSSINKI",
      },
      {
        CITY: "CAMBERENE",
      },
      {
        CITY: "DAKAR",
      },
      {
        CITY: "DODJI",
      },
      {
        CITY: "GUEDIAWAYE",
      },
      {
        CITY: "KAOLACK",
      },
      {
        CITY: "KEDOUGOU",
      },
      {
        CITY: "LOUGA",
      },
      {
        CITY: "MADINA KOKOUN",
      },
      {
        CITY: "SAINT-LOUIS",
      },
      {
        CITY: "SAMA",
      },
      {
        CITY: "TANAF",
      },
    ],
  },
  {
    COUNTRY: "SLOVENIA",
    CODE: "SI",
    CITIES: [
      {
        CITY: "ANKARAN",
      },
      {
        CITY: "BEGUNJE NA GORENJSKEM",
      },
      {
        CITY: "BELTINCI",
      },
      {
        CITY: "BESNICA",
      },
      {
        CITY: "BEVKE",
      },
      {
        CITY: "BISTRICA PRI RUSAH",
      },
      {
        CITY: "BLED",
      },
      {
        CITY: "BOHINJSKA BELA",
      },
      {
        CITY: "BOROVNICA",
      },
      {
        CITY: "BREGINJ",
      },
      {
        CITY: "BRESTANICA",
      },
      {
        CITY: "BREZNICA",
      },
      {
        CITY: "CEMSENIK",
      },
      {
        CITY: "CERKLJE OB KRKI",
      },
      {
        CITY: "CERKNICA",
      },
      {
        CITY: "CERSAK",
      },
      {
        CITY: "CIRKOVCE",
      },
      {
        CITY: "CRENSOVCI",
      },
      {
        CITY: "DEKANI",
      },
      {
        CITY: "DOB",
      },
      {
        CITY: "DOBRAVLJE",
      },
      {
        CITY: "DOBROVA",
      },
      {
        CITY: "DOBRUNJE",
      },
      {
        CITY: "DOL PRI LJUBLJANI",
      },
      {
        CITY: "DOLENJSKE TOPLICE",
      },
      {
        CITY: "DORNAVA",
      },
      {
        CITY: "DRAVOGRAD",
      },
      {
        CITY: "GLOBOKO",
      },
      {
        CITY: "GMAJNICA",
      },
      {
        CITY: "GORENJA VAS",
      },
      {
        CITY: "GORISNICA",
      },
      {
        CITY: "GORNJA RADGONA",
      },
      {
        CITY: "GRIZE",
      },
      {
        CITY: "GROSUPLJE",
      },
      {
        CITY: "HORJUL",
      },
      {
        CITY: "HOTEDRSICA",
      },
      {
        CITY: "HRASTNIK",
      },
      {
        CITY: "HRUSICA",
      },
      {
        CITY: "IDRIJA",
      },
      {
        CITY: "IG",
      },
      {
        CITY: "ILIRSKA BISTRICA",
      },
      {
        CITY: "IZOLA",
      },
      {
        CITY: "JESENICE",
      },
      {
        CITY: "KAMNIK",
      },
      {
        CITY: "KIDRICEVO",
      },
      {
        CITY: "KNEZAK",
      },
      {
        CITY: "KOBARID",
      },
      {
        CITY: "KOJSKO",
      },
      {
        CITY: "KOMENDA",
      },
      {
        CITY: "KOPER",
      },
      {
        CITY: "KRAJNA",
      },
      {
        CITY: "KRANJ",
      },
      {
        CITY: "KRANJSKA GORA",
      },
      {
        CITY: "LENART V SLOV. GORICAH",
      },
      {
        CITY: "LENDAVA",
      },
      {
        CITY: "LESCE",
      },
      {
        CITY: "LIMBUS",
      },
      {
        CITY: "LITIJA",
      },
      {
        CITY: "LJUBECNA",
      },
      {
        CITY: "LJUBLJANA",
      },
      {
        CITY: "LOG PRI BREZOVICI",
      },
      {
        CITY: "LOGATEC",
      },
      {
        CITY: "LOKEV",
      },
      {
        CITY: "LOVRENC NA DRAVSKEM POLJU",
      },
      {
        CITY: "LOVRENC NA POHORJU",
      },
      {
        CITY: "MARIBOR",
      },
      {
        CITY: "MARKOVCI",
      },
      {
        CITY: "MEDVODE",
      },
      {
        CITY: "MENGES",
      },
      {
        CITY: "MEZICA",
      },
      {
        CITY: "MIKLAVZ NA DRAVSKEM POLJU",
      },
      {
        CITY: "MIREN",
      },
      {
        CITY: "MIRNA",
      },
      {
        CITY: "MOJSTRANA",
      },
      {
        CITY: "MORAVCE",
      },
      {
        CITY: "MOZIRJE",
      },
      {
        CITY: "MURSKA SOBOTA",
      },
      {
        CITY: "NAKLO",
      },
      {
        CITY: "NOTRANJE GORICE",
      },
      {
        CITY: "NOVA CERKEV",
      },
      {
        CITY: "NOVA GORICA",
      },
      {
        CITY: "NOVO MESTO",
      },
      {
        CITY: "PERNICA",
      },
      {
        CITY: "PESNICA PRI MARIBORU",
      },
      {
        CITY: "PETROVCE",
      },
      {
        CITY: "PIRAN",
      },
      {
        CITY: "POBEGI",
      },
      {
        CITY: "PODBOCJE",
      },
      {
        CITY: "POLHOV GRADEC",
      },
      {
        CITY: "POLJCANE",
      },
      {
        CITY: "POLZELA",
      },
      {
        CITY: "POSTOJNA",
      },
      {
        CITY: "PREBOLD",
      },
      {
        CITY: "PRESERJE",
      },
      {
        CITY: "PRESTRANEK",
      },
      {
        CITY: "PREVALJE",
      },
      {
        CITY: "PTUJ",
      },
      {
        CITY: "PUCONCI",
      },
      {
        CITY: "RADLJE OB DRAVI",
      },
      {
        CITY: "RADOMLJE",
      },
      {
        CITY: "RADOVLJICA",
      },
      {
        CITY: "RAKEK",
      },
      {
        CITY: "RAVNE",
      },
      {
        CITY: "RAVNE NA KOROSKEM",
      },
      {
        CITY: "ROCINJ",
      },
      {
        CITY: "SECOVLJE",
      },
      {
        CITY: "SELNICA OB DRAVI",
      },
      {
        CITY: "SEMPETER PRI GORICI",
      },
      {
        CITY: "SENCUR",
      },
      {
        CITY: "SENTJANZ",
      },
      {
        CITY: "SENTVID PRI STICNI",
      },
      {
        CITY: "SEVNICA",
      },
      {
        CITY: "SKOFLJICA",
      },
      {
        CITY: "SLOVENJ GRADEC",
      },
      {
        CITY: "SLOVENSKA BISTRICA",
      },
      {
        CITY: "SLOVENSKE KONJICE",
      },
      {
        CITY: "SMARJE",
      },
      {
        CITY: "SMARJE PRI JELSAH",
      },
      {
        CITY: "SMARJE-SAP",
      },
      {
        CITY: "SMARTNO OB PAKI",
      },
      {
        CITY: "SMLEDNIK",
      },
      {
        CITY: "SOSTANJ",
      },
      {
        CITY: "SPODNJE HOCE",
      },
      {
        CITY: "SPODNJI BRNIK",
      },
      {
        CITY: "SPODNJI DUPLEK",
      },
      {
        CITY: "SPODNJI IVANJCI",
      },
      {
        CITY: "SREDISCE OB DRAVI",
      },
      {
        CITY: "STAHOVICA",
      },
      {
        CITY: "STARA CERKEV",
      },
      {
        CITY: "STARI TRG PRI LOZU",
      },
      {
        CITY: "STARSE",
      },
      {
        CITY: "TISINA",
      },
      {
        CITY: "TOLMIN",
      },
      {
        CITY: "TRBOVLJE",
      },
      {
        CITY: "TRZIC",
      },
      {
        CITY: "TRZIN",
      },
      {
        CITY: "VELENJE",
      },
      {
        CITY: "VELIKA LOKA",
      },
      {
        CITY: "VERZEJ",
      },
      {
        CITY: "VIDEM",
      },
      {
        CITY: "VIPAVA",
      },
      {
        CITY: "VODICE",
      },
      {
        CITY: "VOJNIK",
      },
      {
        CITY: "VRHNIKA",
      },
      {
        CITY: "ZAGORJE OB SAVI",
      },
      {
        CITY: "ZELEZNIKI",
      },
      {
        CITY: "ZGORNJA POLSKAVA",
      },
      {
        CITY: "ZGORNJE GORJE",
      },
      {
        CITY: "ZGORNJE HOCE",
      },
      {
        CITY: "ZGORNJE SKOFIJE",
      },
      {
        CITY: "ZIDANI MOST",
      },
      {
        CITY: "ZIRI",
      },
    ],
  },
  {
    COUNTRY: "SWEDEN",
    CODE: "SE",
    CITIES: [
      {
        CITY: "ABY",
      },
      {
        CITY: "AELMHULT",
      },
      {
        CITY: "AELVDALEN",
      },
      {
        CITY: "AELVKARLEBY",
      },
      {
        CITY: "AELVSBYN",
      },
      {
        CITY: "AGNESBERG",
      },
      {
        CITY: "AGUNNARYD",
      },
      {
        CITY: "AKARP",
      },
      {
        CITY: "AKERS STYCKEBRUK",
      },
      {
        CITY: "AKERSBERGA",
      },
      {
        CITY: "ALAFORS",
      },
      {
        CITY: "ALANDSBRO",
      },
      {
        CITY: "ALED",
      },
      {
        CITY: "ALEM",
      },
      {
        CITY: "ALFTA",
      },
      {
        CITY: "ALINGSAS",
      },
      {
        CITY: "ALLERUM",
      },
      {
        CITY: "ALMEBODA",
      },
      {
        CITY: "ALMUNGE",
      },
      {
        CITY: "ALNO",
      },
      {
        CITY: "ALSEN",
      },
      {
        CITY: "ALSTERBRO",
      },
      {
        CITY: "ALSTERMO",
      },
      {
        CITY: "ALTA",
      },
      {
        CITY: "ALTERSBRUK",
      },
      {
        CITY: "ALUNDA",
      },
      {
        CITY: "ALUNDA",
      },
      {
        CITY: "ALVANGEN",
      },
      {
        CITY: "ALVESTA",
      },
      {
        CITY: "ALVSJO",
      },
      {
        CITY: "AMAL",
      },
      {
        CITY: "AMBJORNARP",
      },
      {
        CITY: "AMOTFORS",
      },
      {
        CITY: "ANDERSLOV",
      },
      {
        CITY: "ANDERSTORP",
      },
      {
        CITY: "ANDERSTORP",
      },
      {
        CITY: "ANEBY",
      },
      {
        CITY: "ANGE",
      },
      {
        CITY: "ANGERED",
      },
      {
        CITY: "ANKARSRUM",
      },
      {
        CITY: "ANKARSVIK",
      },
      {
        CITY: "ANNELOV",
      },
      {
        CITY: "ANNERSTAD",
      },
      {
        CITY: "ANSVAR",
      },
      {
        CITY: "APLARED",
      },
      {
        CITY: "ARBOGA",
      },
      {
        CITY: "ARBRA",
      },
      {
        CITY: "ARHOLMA",
      },
      {
        CITY: "ARJAENG",
      },
      {
        CITY: "ARJEPLOG",
      },
      {
        CITY: "ARKELSTORP",
      },
      {
        CITY: "ARLA",
      },
      {
        CITY: "ARLOEV",
      },
      {
        CITY: "ARNASVALL",
      },
      {
        CITY: "ARSUNDA",
      },
      {
        CITY: "ARVIDSJAUR",
      },
      {
        CITY: "ARVIKA",
      },
      {
        CITY: "ARYD",
      },
      {
        CITY: "AS",
      },
      {
        CITY: "ASA",
      },
      {
        CITY: "ASARNA",
      },
      {
        CITY: "ASARUM",
      },
      {
        CITY: "ASBRO",
      },
      {
        CITY: "ASEDA",
      },
      {
        CITY: "ASELE",
      },
      {
        CITY: "ASENHOGA",
      },
      {
        CITY: "ASENSBRUK",
      },
      {
        CITY: "ASHAMMAR",
      },
      {
        CITY: "ASKERSUND",
      },
      {
        CITY: "ASKIM",
      },
      {
        CITY: "ASKLOSTER",
      },
      {
        CITY: "ASMUNDTORP",
      },
      {
        CITY: "ASPAS",
      },
      {
        CITY: "ASPERED",
      },
      {
        CITY: "ASPERO",
      },
      {
        CITY: "ASTORP",
      },
      {
        CITY: "ATORP",
      },
      {
        CITY: "ATRAN",
      },
      {
        CITY: "ATVIDABERG",
      },
      {
        CITY: "AVESTA",
      },
      {
        CITY: "AXVALL",
      },
      {
        CITY: "BACKA",
      },
      {
        CITY: "BACKARYD",
      },
      {
        CITY: "BACKE",
      },
      {
        CITY: "BACKEFORS",
      },
      {
        CITY: "BALINGE",
      },
      {
        CITY: "BALLINGSLOV",
      },
      {
        CITY: "BALSTA",
      },
      {
        CITY: "BANKERYD",
      },
      {
        CITY: "BARA",
      },
      {
        CITY: "BARKARBY",
      },
      {
        CITY: "BARSEBACK",
      },
      {
        CITY: "BARSEBACKSHAMN",
      },
      {
        CITY: "BASTAD",
      },
      {
        CITY: "BEDDINGE LAGE",
      },
      {
        CITY: "BENGTSFORS",
      },
      {
        CITY: "BERGA",
      },
      {
        CITY: "BERGEFORSEN",
      },
      {
        CITY: "BERGKVARA",
      },
      {
        CITY: "BERGSHAMRA",
      },
      {
        CITY: "BERGSJOE",
      },
      {
        CITY: "BERGVIK",
      },
      {
        CITY: "BETTNA",
      },
      {
        CITY: "BILLDAL",
      },
      {
        CITY: "BILLEBERGA",
      },
      {
        CITY: "BILLESHOLM",
      },
      {
        CITY: "BILLINGE",
      },
      {
        CITY: "BILLINGSFORS",
      },
      {
        CITY: "BJARNUM",
      },
      {
        CITY: "BJARRED",
      },
      {
        CITY: "BJARTRA",
      },
      {
        CITY: "BJASTA",
      },
      {
        CITY: "BJORBO",
      },
      {
        CITY: "BJORINGE",
      },
      {
        CITY: "BJORKHAGA",
      },
      {
        CITY: "BJORKLINGE",
      },
      {
        CITY: "BJORKOBY",
      },
      {
        CITY: "BJORKVIK",
      },
      {
        CITY: "BJORNA",
      },
      {
        CITY: "BJORNEBORG",
      },
      {
        CITY: "BJORNLUNDA",
      },
      {
        CITY: "BJURAKER",
      },
      {
        CITY: "BJURHOLM",
      },
      {
        CITY: "BJURSAS",
      },
      {
        CITY: "BJUV",
      },
      {
        CITY: "BLACKSTAD",
      },
      {
        CITY: "BLASMARK",
      },
      {
        CITY: "BLATTNICKSELE",
      },
      {
        CITY: "BLENTARP",
      },
      {
        CITY: "BOCKARA",
      },
      {
        CITY: "BODAFORS",
      },
      {
        CITY: "BODEN",
      },
      {
        CITY: "BOFORS",
      },
      {
        CITY: "BOHUS",
      },
      {
        CITY: "BOLIDEN",
      },
      {
        CITY: "BOLLEBYGD",
      },
      {
        CITY: "BOLLNAS",
      },
      {
        CITY: "BOLLSTABRUK",
      },
      {
        CITY: "BOR",
      },
      {
        CITY: "BORENSBERG",
      },
      {
        CITY: "BORGHAMN",
      },
      {
        CITY: "BORGHOLM",
      },
      {
        CITY: "BORGSTENA",
      },
      {
        CITY: "BORRBY",
      },
      {
        CITY: "BOTSMARK",
      },
      {
        CITY: "BOTTNARYD",
      },
      {
        CITY: "BOXHOLM",
      },
      {
        CITY: "BRAAS",
      },
      {
        CITY: "BRAECKE",
      },
      {
        CITY: "BRAKNE-HOBY",
      },
      {
        CITY: "BRALANDA",
      },
      {
        CITY: "BRAMHULT",
      },
      {
        CITY: "BRANNO",
      },
      {
        CITY: "BRASTAD",
      },
      {
        CITY: "BREDARED",
      },
      {
        CITY: "BREDARYD",
      },
      {
        CITY: "BRO",
      },
      {
        CITY: "BROBY",
      },
      {
        CITY: "BROKIND",
      },
      {
        CITY: "BROMMA",
      },
      {
        CITY: "BROMOELLA",
      },
      {
        CITY: "BROSARP",
      },
      {
        CITY: "BROTTBY",
      },
      {
        CITY: "BRUNFLO",
      },
      {
        CITY: "BRUNNBY",
      },
      {
        CITY: "BUA",
      },
      {
        CITY: "BULLMARK",
      },
      {
        CITY: "BUNKEFLOSTRAND",
      },
      {
        CITY: "BUREA",
      },
      {
        CITY: "BURTRAESK",
      },
      {
        CITY: "BYGDEA",
      },
      {
        CITY: "BYGDSILJUM",
      },
      {
        CITY: "BYSKE",
      },
      {
        CITY: "BYSKE",
      },
      {
        CITY: "CHARLOTTENBERG",
      },
      {
        CITY: "DALA-FLODA",
      },
      {
        CITY: "DALA-JARNA",
      },
      {
        CITY: "DALARO",
      },
      {
        CITY: "DALBY",
      },
      {
        CITY: "DALS LANGED",
      },
      {
        CITY: "DALS ROSTOCK",
      },
      {
        CITY: "DALSJOFORS",
      },
      {
        CITY: "DALSTORP",
      },
      {
        CITY: "DANDERYD",
      },
      {
        CITY: "DEGEBERGA",
      },
      {
        CITY: "DEGERFORS",
      },
      {
        CITY: "DEJE",
      },
      {
        CITY: "DELSBO",
      },
      {
        CITY: "DIKANAES",
      },
      {
        CITY: "DINGLE",
      },
      {
        CITY: "DIO",
      },
      {
        CITY: "DJURA",
      },
      {
        CITY: "DJURAS",
      },
      {
        CITY: "DJURHAMN",
      },
      {
        CITY: "DJURSHOLM",
      },
      {
        CITY: "DOMSJO",
      },
      {
        CITY: "DONSO",
      },
      {
        CITY: "DOROTEA",
      },
      {
        CITY: "DOSJEBRO",
      },
      {
        CITY: "DROTTNINGHOLM",
      },
      {
        CITY: "DUVED",
      },
      {
        CITY: "DYLTABRUK",
      },
      {
        CITY: "ED",
      },
      {
        CITY: "EDSBRO",
      },
      {
        CITY: "EDSBYN",
      },
      {
        CITY: "EDSELE",
      },
      {
        CITY: "EDSHULTSHALL",
      },
      {
        CITY: "EDSVALLA",
      },
      {
        CITY: "EKEBY",
      },
      {
        CITY: "EKENASSJON",
      },
      {
        CITY: "EKSHAERAD",
      },
      {
        CITY: "EKSJOE",
      },
      {
        CITY: "ELDSBERGA",
      },
      {
        CITY: "ELLOES",
      },
      {
        CITY: "EMMABODA",
      },
      {
        CITY: "ENANGER",
      },
      {
        CITY: "ENEBYBERG",
      },
      {
        CITY: "ENERYDA",
      },
      {
        CITY: "ENKOPING",
      },
      {
        CITY: "ENSKEDE-ARSTA-VANTOER",
      },
      {
        CITY: "ENVIKEN",
      },
      {
        CITY: "ERIKSLUND",
      },
      {
        CITY: "ERIKSMALA",
      },
      {
        CITY: "ESKILSTUNA",
      },
      {
        CITY: "ESLOV",
      },
      {
        CITY: "FAERGELANDA",
      },
      {
        CITY: "FAERJESTADEN",
      },
      {
        CITY: "FAGELFORS",
      },
      {
        CITY: "FAGERAS",
      },
      {
        CITY: "FAGERBERG",
      },
      {
        CITY: "FAGERED",
      },
      {
        CITY: "FAGERHULT",
      },
      {
        CITY: "FAGERSANNA",
      },
      {
        CITY: "FAGERSTA",
      },
      {
        CITY: "FAGERVIK",
      },
      {
        CITY: "FALKENBERG",
      },
      {
        CITY: "FALLFORS",
      },
      {
        CITY: "FALUN",
      },
      {
        CITY: "FARBO",
      },
      {
        CITY: "FARILA",
      },
      {
        CITY: "FARLOV",
      },
      {
        CITY: "FARNA",
      },
      {
        CITY: "FARNAS",
      },
      {
        CITY: "FARO",
      },
      {
        CITY: "FELLINGSBRO",
      },
      {
        CITY: "FENGERSFORS",
      },
      {
        CITY: "FIGEHOLM",
      },
      {
        CITY: "FILIPSTAD",
      },
      {
        CITY: "FINJA",
      },
      {
        CITY: "FINNERODJA",
      },
      {
        CITY: "FINSPANG",
      },
      {
        CITY: "FISKEBY",
      },
      {
        CITY: "FJAERAS STATION",
      },
      {
        CITY: "FJALKINGE",
      },
      {
        CITY: "FJARDHUNDRA",
      },
      {
        CITY: "FJUGESTA",
      },
      {
        CITY: "FLEN",
      },
      {
        CITY: "FLENINGE",
      },
      {
        CITY: "FLISBY",
      },
      {
        CITY: "FLOBY",
      },
      {
        CITY: "FLODA",
      },
      {
        CITY: "FLYINGE",
      },
      {
        CITY: "FOLLINGE",
      },
      {
        CITY: "FOLLINGE",
      },
      {
        CITY: "FORS",
      },
      {
        CITY: "FORSA",
      },
      {
        CITY: "FORSBACKA",
      },
      {
        CITY: "FORSBY",
      },
      {
        CITY: "FORSERUM",
      },
      {
        CITY: "FORSHAGA",
      },
      {
        CITY: "FORSHEDA",
      },
      {
        CITY: "FORSLOV",
      },
      {
        CITY: "FRANDEFORS",
      },
      {
        CITY: "FRANSTA",
      },
      {
        CITY: "FREDRIKSBERG",
      },
      {
        CITY: "FRILLESAS",
      },
      {
        CITY: "FRISTAD",
      },
      {
        CITY: "FRITSLA",
      },
      {
        CITY: "FROSO",
      },
      {
        CITY: "FROVI",
      },
      {
        CITY: "FRUFALLAN",
      },
      {
        CITY: "FURULUND",
      },
      {
        CITY: "FURUVIK",
      },
      {
        CITY: "GABO",
      },
      {
        CITY: "GAGNEF",
      },
      {
        CITY: "GALLO",
      },
      {
        CITY: "GALLSTAD",
      },
      {
        CITY: "GAMLA STAN",
      },
      {
        CITY: "GAMLEBY",
      },
      {
        CITY: "GAMMELSTAD",
      },
      {
        CITY: "GANGHESTER",
      },
      {
        CITY: "GARDS KOPINGE",
      },
      {
        CITY: "GARGNAS",
      },
      {
        CITY: "GARPENBERG",
      },
      {
        CITY: "GARPHYTTAN",
      },
      {
        CITY: "GARSAS",
      },
      {
        CITY: "GARSNAS",
      },
      {
        CITY: "GEMLA",
      },
      {
        CITY: "GENARP",
      },
      {
        CITY: "GENEVAD",
      },
      {
        CITY: "GETINGE",
      },
      {
        CITY: "GIDEA",
      },
      {
        CITY: "GIMO",
      },
      {
        CITY: "GISLAVED",
      },
      {
        CITY: "GLANSHAMMAR",
      },
      {
        CITY: "GLIMAKRA",
      },
      {
        CITY: "GLOMMEN",
      },
      {
        CITY: "GLOMMERSTRASK",
      },
      {
        CITY: "GLOSTORP",
      },
      {
        CITY: "GLUMSLOV",
      },
      {
        CITY: "GNARP",
      },
      {
        CITY: "GNESTA",
      },
      {
        CITY: "GNOSJOE",
      },
      {
        CITY: "GOETENE",
      },
      {
        CITY: "GOTA",
      },
      {
        CITY: "GOTHENBURG",
      },
      {
        CITY: "GRABO",
      },
      {
        CITY: "GRADDO",
      },
      {
        CITY: "GRAESTORP",
      },
      {
        CITY: "GRANGARDE",
      },
      {
        CITY: "GRANGESBERG",
      },
      {
        CITY: "GRASMYR",
      },
      {
        CITY: "GRASO",
      },
      {
        CITY: "GREBBESTAD",
      },
      {
        CITY: "GRILLBY",
      },
      {
        CITY: "GRIMETON",
      },
      {
        CITY: "GRIMSAS",
      },
      {
        CITY: "GRIMSLOV",
      },
      {
        CITY: "GRIMSTORP",
      },
      {
        CITY: "GRISSLEHAMN",
      },
      {
        CITY: "GRONDAL",
      },
      {
        CITY: "GRONSKARA",
      },
      {
        CITY: "GRUMS",
      },
      {
        CITY: "GRUNDSUND",
      },
      {
        CITY: "GRYCKSBO",
      },
      {
        CITY: "GRYT",
      },
      {
        CITY: "GRYTHYTTAN",
      },
      {
        CITY: "GUALOV",
      },
      {
        CITY: "GULDSMEDSHYTTAN",
      },
      {
        CITY: "GULLBRANDSTORP",
      },
      {
        CITY: "GULLSPANG",
      },
      {
        CITY: "GUNNEBO",
      },
      {
        CITY: "GUNNILSE",
      },
      {
        CITY: "GUSSELBY",
      },
      {
        CITY: "GUSTAFS",
      },
      {
        CITY: "GUSTAVSBERG",
      },
      {
        CITY: "GUSUM",
      },
      {
        CITY: "GYTTORP",
      },
      {
        CITY: "HACKAS",
      },
      {
        CITY: "HACKSVIK",
      },
      {
        CITY: "HAEGERSTEN",
      },
      {
        CITY: "HAELLEFORS",
      },
      {
        CITY: "HAERRYDA",
      },
      {
        CITY: "HAESSELBY",
      },
      {
        CITY: "HAGFORS",
      },
      {
        CITY: "HAGGLUND",
      },
      {
        CITY: "HAJOM",
      },
      {
        CITY: "HAKKAS",
      },
      {
        CITY: "HAKNAS",
      },
      {
        CITY: "HALJARP",
      },
      {
        CITY: "HALLABRO",
      },
      {
        CITY: "HALLAND",
      },
      {
        CITY: "HALLAROD",
      },
      {
        CITY: "HALLBERG",
      },
      {
        CITY: "HALLEFORSNAS",
      },
      {
        CITY: "HALLEKIS",
      },
      {
        CITY: "HALLESTAD",
      },
      {
        CITY: "HALLESTAD",
      },
      {
        CITY: "HALLEVADSHOLM",
      },
      {
        CITY: "HALLINGSJO",
      },
      {
        CITY: "HALLSBERG",
      },
      {
        CITY: "HALLSTA",
      },
      {
        CITY: "HALLSTAHAMMAR",
      },
      {
        CITY: "HALLSTAVIK",
      },
      {
        CITY: "HALMSTAD",
      },
      {
        CITY: "HALSO",
      },
      {
        CITY: "HALTA",
      },
      {
        CITY: "HAMBURGSUND",
      },
      {
        CITY: "HAMMAR",
      },
      {
        CITY: "HAMMARO",
      },
      {
        CITY: "HAMMARSTRAND",
      },
      {
        CITY: "HAMMENHOG",
      },
      {
        CITY: "HAMMERDAL",
      },
      {
        CITY: "HAMNEDA",
      },
      {
        CITY: "HAMRANGEFJARDEN",
      },
      {
        CITY: "HANASKOG",
      },
      {
        CITY: "HANDEN",
      },
      {
        CITY: "HAPARANDA",
      },
      {
        CITY: "HARADS",
      },
      {
        CITY: "HARBO",
      },
      {
        CITY: "HARBY",
      },
      {
        CITY: "HARESTAD",
      },
      {
        CITY: "HARMANGER",
      },
      {
        CITY: "HARPLINGE",
      },
      {
        CITY: "HARSLOV",
      },
      {
        CITY: "HASSELBY",
      },
      {
        CITY: "HASSELFORS",
      },
      {
        CITY: "HASSLARP",
      },
      {
        CITY: "HASSLO",
      },
      {
        CITY: "HASTVEDA",
      },
      {
        CITY: "HAVDHEM",
      },
      {
        CITY: "HEBO",
      },
      {
        CITY: "HEDARED",
      },
      {
        CITY: "HEDE",
      },
      {
        CITY: "HEDEKAS",
      },
      {
        CITY: "HEDEMORA",
      },
      {
        CITY: "HEDESUNDA",
      },
      {
        CITY: "HELGUM",
      },
      {
        CITY: "HELSINGBORG",
      },
      {
        CITY: "HEMMINGSMARK",
      },
      {
        CITY: "HEMSE",
      },
      {
        CITY: "HENAN",
      },
      {
        CITY: "HERRANGEN",
      },
      {
        CITY: "HERRLJUNGA",
      },
      {
        CITY: "HESTRA",
      },
      {
        CITY: "HILLARED",
      },
      {
        CITY: "HILLERSTORP",
      },
      {
        CITY: "HINDAS",
      },
      {
        CITY: "HJAERUP",
      },
      {
        CITY: "HJALTEBY",
      },
      {
        CITY: "HJARNARP",
      },
      {
        CITY: "HJARTUM",
      },
      {
        CITY: "HJO",
      },
      {
        CITY: "HOEGSBY",
      },
      {
        CITY: "HOEOER",
      },
      {
        CITY: "HOERBY",
      },
      {
        CITY: "HOERNEFORS",
      },
      {
        CITY: "HOFORS",
      },
      {
        CITY: "HOGANAS",
      },
      {
        CITY: "HOGSATERS",
      },
      {
        CITY: "HOGSJO",
      },
      {
        CITY: "HOK",
      },
      {
        CITY: "HOKERUM",
      },
      {
        CITY: "HOLLVIKEN",
      },
      {
        CITY: "HOLMEN",
      },
      {
        CITY: "HOLMSJO",
      },
      {
        CITY: "HOLMSUND",
      },
      {
        CITY: "HOLMSVEDEN",
      },
      {
        CITY: "HOLO",
      },
      {
        CITY: "HOLSBYBRUNN",
      },
      {
        CITY: "HOLSLJUNGA",
      },
      {
        CITY: "HONO",
      },
      {
        CITY: "HORN",
      },
      {
        CITY: "HORNDAL",
      },
      {
        CITY: "HORRED",
      },
      {
        CITY: "HORTLAX",
      },
      {
        CITY: "HOVA",
      },
      {
        CITY: "HOVAS",
      },
      {
        CITY: "HOVIKSNAS",
      },
      {
        CITY: "HOVMANTORP",
      },
      {
        CITY: "HUAROD",
      },
      {
        CITY: "HUDDINGE",
      },
      {
        CITY: "HUDIKSVALL",
      },
      {
        CITY: "HULTAFORS",
      },
      {
        CITY: "HULTSFRED",
      },
      {
        CITY: "HUNNEBOSTRAND",
      },
      {
        CITY: "HUNNESTAD",
      },
      {
        CITY: "HUSKVARNA",
      },
      {
        CITY: "HUSUM",
      },
      {
        CITY: "HYLLINGE",
      },
      {
        CITY: "HYLTEBRUK",
      },
      {
        CITY: "HYSSNA",
      },
      {
        CITY: "IGGESUND",
      },
      {
        CITY: "IMMELN",
      },
      {
        CITY: "INDAL",
      },
      {
        CITY: "INGARP",
      },
      {
        CITY: "INGELSTAD",
      },
      {
        CITY: "INGMARSO",
      },
      {
        CITY: "INSJON",
      },
      {
        CITY: "JAEMJOE",
      },
      {
        CITY: "JAERVSOE",
      },
      {
        CITY: "JAMSHOG",
      },
      {
        CITY: "JARBO",
      },
      {
        CITY: "JARLASA",
      },
      {
        CITY: "JARNA",
      },
      {
        CITY: "JARPEN",
      },
      {
        CITY: "JARVED",
      },
      {
        CITY: "JOERN",
      },
      {
        CITY: "JOHANNESHOV",
      },
      {
        CITY: "JOHANNISHUS",
      },
      {
        CITY: "JOKKMOKK",
      },
      {
        CITY: "JONAKER",
      },
      {
        CITY: "JONSERED",
      },
      {
        CITY: "JORDBRO",
      },
      {
        CITY: "JORLANDA",
      },
      {
        CITY: "JUDE",
      },
      {
        CITY: "JUNOSUANDO",
      },
      {
        CITY: "KAEVLINGE",
      },
      {
        CITY: "KAGE",
      },
      {
        CITY: "KAGEROD",
      },
      {
        CITY: "KALIX",
      },
      {
        CITY: "KALLBY",
      },
      {
        CITY: "KALLERED",
      },
      {
        CITY: "KALLINGE",
      },
      {
        CITY: "KALLO",
      },
      {
        CITY: "KALMAR",
      },
      {
        CITY: "KAREBY",
      },
      {
        CITY: "KARESUANDO",
      },
      {
        CITY: "KARL GUSTAV",
      },
      {
        CITY: "KARLSBORG",
      },
      {
        CITY: "KARLSHAMN",
      },
      {
        CITY: "KARLSKOGA",
      },
      {
        CITY: "KARLSKRONA",
      },
      {
        CITY: "KARLSTAD",
      },
      {
        CITY: "KARNA",
      },
      {
        CITY: "KARRA",
      },
      {
        CITY: "KATRINEHOLM",
      },
      {
        CITY: "KATTARP",
      },
      {
        CITY: "KATTHAMMARSVIK",
      },
      {
        CITY: "KATTILSTORP",
      },
      {
        CITY: "KIL",
      },
      {
        CITY: "KILAFORS",
      },
      {
        CITY: "KILLEBERG",
      },
      {
        CITY: "KIMSTAD",
      },
      {
        CITY: "KINNA",
      },
      {
        CITY: "KINNARED",
      },
      {
        CITY: "KINNARP",
      },
      {
        CITY: "KIRUNA",
      },
      {
        CITY: "KISA",
      },
      {
        CITY: "KISTA",
      },
      {
        CITY: "KIVIK",
      },
      {
        CITY: "KLADESHOLMEN",
      },
      {
        CITY: "KLAGERUP",
      },
      {
        CITY: "KLAGSHAMN",
      },
      {
        CITY: "KLAGSTORP",
      },
      {
        CITY: "KLASSBOL",
      },
      {
        CITY: "KLAVRESTROM",
      },
      {
        CITY: "KLINTEHAMN",
      },
      {
        CITY: "KLIPPAN",
      },
      {
        CITY: "KLOVEDAL",
      },
      {
        CITY: "KNARED",
      },
      {
        CITY: "KNISLINGE",
      },
      {
        CITY: "KNIVSTA",
      },
      {
        CITY: "KNIVSTA",
      },
      {
        CITY: "KNUTBY",
      },
      {
        CITY: "KODE",
      },
      {
        CITY: "KOLBACK",
      },
      {
        CITY: "KOLMARDEN",
      },
      {
        CITY: "KOLSVA",
      },
      {
        CITY: "KONGA",
      },
      {
        CITY: "KOPING",
      },
      {
        CITY: "KOPINGEBRO",
      },
      {
        CITY: "KOPMANHOLMEN",
      },
      {
        CITY: "KOPPARBERG",
      },
      {
        CITY: "KOPPOM",
      },
      {
        CITY: "KORPILOMBOLO",
      },
      {
        CITY: "KORSBERGA",
      },
      {
        CITY: "KORSKROGEN",
      },
      {
        CITY: "KOSKULLSKULLE",
      },
      {
        CITY: "KOSTA",
      },
      {
        CITY: "KRAMFORS",
      },
      {
        CITY: "KRISTDALA",
      },
      {
        CITY: "KRISTIANSTAD",
      },
      {
        CITY: "KRISTINEBERG",
      },
      {
        CITY: "KRISTINEHAMN",
      },
      {
        CITY: "KROKOM",
      },
      {
        CITY: "KRYLBO",
      },
      {
        CITY: "KULLA",
      },
      {
        CITY: "KULLAVIK",
      },
      {
        CITY: "KULLTORP",
      },
      {
        CITY: "KUMLA",
      },
      {
        CITY: "KUNGSAENGEN",
      },
      {
        CITY: "KUNGSBACKA",
      },
      {
        CITY: "KUNGSGARDEN",
      },
      {
        CITY: "KUNGSHAMN",
      },
      {
        CITY: "KUNGSOER",
      },
      {
        CITY: "KVANUM",
      },
      {
        CITY: "KVIBILLE",
      },
      {
        CITY: "KVICKSUND",
      },
      {
        CITY: "KVIDINGE",
      },
      {
        CITY: "KVISSLEBY",
      },
      {
        CITY: "KYRKOBYN",
      },
      {
        CITY: "LAGAN",
      },
      {
        CITY: "LAHOLM",
      },
      {
        CITY: "LAMMHULT",
      },
      {
        CITY: "LANDERYD",
      },
      {
        CITY: "LANDFJARDEN",
      },
      {
        CITY: "LANDSBRO",
      },
      {
        CITY: "LANDSKRONA",
      },
      {
        CITY: "LANDVETTER",
      },
      {
        CITY: "LANGAS",
      },
      {
        CITY: "LANGASJO",
      },
      {
        CITY: "LANGHEM",
      },
      {
        CITY: "LANGSELE",
      },
      {
        CITY: "LANGSHYTTAN",
      },
      {
        CITY: "LANGVIKSMON",
      },
      {
        CITY: "LANNA",
      },
      {
        CITY: "LANNAVAARA",
      },
      {
        CITY: "LARBRO",
      },
      {
        CITY: "LARV",
      },
      {
        CITY: "LAVSJO",
      },
      {
        CITY: "LAXA",
      },
      {
        CITY: "LEKERYD",
      },
      {
        CITY: "LEKSAND",
      },
      {
        CITY: "LENHOVDA",
      },
      {
        CITY: "LERBERGET",
      },
      {
        CITY: "LERDALA",
      },
      {
        CITY: "LERUM",
      },
      {
        CITY: "LESSEBO",
      },
      {
        CITY: "LIDEN",
      },
      {
        CITY: "LIDHULT",
      },
      {
        CITY: "LIDINGOE",
      },
      {
        CITY: "LIDKOPING",
      },
      {
        CITY: "LIKENAS",
      },
      {
        CITY: "LILLA EDET",
      },
      {
        CITY: "LILLPITE",
      },
      {
        CITY: "LIMA",
      },
      {
        CITY: "LIMHAMN",
      },
      {
        CITY: "LIMMARED",
      },
      {
        CITY: "LINDAS",
      },
      {
        CITY: "LINDESBERG",
      },
      {
        CITY: "LINDOME",
      },
      {
        CITY: "LINGHEM",
      },
      {
        CITY: "LINNERYD",
      },
      {
        CITY: "LISTERBY",
      },
      {
        CITY: "LIT",
      },
      {
        CITY: "LJUGARN",
      },
      {
        CITY: "LJUNG",
      },
      {
        CITY: "LJUNGAVERK",
      },
      {
        CITY: "LJUNGBY",
      },
      {
        CITY: "LJUNGBYHED",
      },
      {
        CITY: "LJUNGBYHOLM",
      },
      {
        CITY: "LJUNGSARP",
      },
      {
        CITY: "LJUNGSBRO",
      },
      {
        CITY: "LJUNGSKILE",
      },
      {
        CITY: "LJUSDAL",
      },
      {
        CITY: "LJUSFALLSHAMMAR",
      },
      {
        CITY: "LJUSNE",
      },
      {
        CITY: "LJUSTERO",
      },
      {
        CITY: "LJUSTORP",
      },
      {
        CITY: "LOBEROD",
      },
      {
        CITY: "LODDEKOPINGE",
      },
      {
        CITY: "LODERUP",
      },
      {
        CITY: "LODOSE",
      },
      {
        CITY: "LOMMA",
      },
      {
        CITY: "LONASHULT",
      },
      {
        CITY: "LONSBODA",
      },
      {
        CITY: "LOS",
      },
      {
        CITY: "LOTORP",
      },
      {
        CITY: "LOTTORP",
      },
      {
        CITY: "LOVANGER",
      },
      {
        CITY: "LOVESTAD",
      },
      {
        CITY: "LOVIKKA",
      },
      {
        CITY: "LUDVIGSBORG",
      },
      {
        CITY: "LUDVIKA",
      },
      {
        CITY: "LUGNVIK",
      },
      {
        CITY: "LUND",
      },
      {
        CITY: "LYCKE",
      },
      {
        CITY: "LYCKEBY",
      },
      {
        CITY: "LYCKSELE",
      },
      {
        CITY: "LYSEKIL",
      },
      {
        CITY: "LYSVIK",
      },
      {
        CITY: "MALA",
      },
      {
        CITY: "MALERAS",
      },
      {
        CITY: "MALILLA",
      },
      {
        CITY: "MALMA",
      },
      {
        CITY: "MALMA",
      },
      {
        CITY: "MALMBACK",
      },
      {
        CITY: "MALMBERGET",
      },
      {
        CITY: "MALMKOPING",
      },
      {
        CITY: "MALMO",
      },
      {
        CITY: "MALSRYD",
      },
      {
        CITY: "MALUNG",
      },
      {
        CITY: "MANKARBO",
      },
      {
        CITY: "MANSARP",
      },
      {
        CITY: "MANTORP",
      },
      {
        CITY: "MARBACK",
      },
      {
        CITY: "MARDAKLEV",
      },
      {
        CITY: "MARIANNELUND",
      },
      {
        CITY: "MARIEFRED",
      },
      {
        CITY: "MARIEHOLM",
      },
      {
        CITY: "MARIEHOLM",
      },
      {
        CITY: "MARIESTAD",
      },
      {
        CITY: "MARKARYD",
      },
      {
        CITY: "MARMA",
      },
      {
        CITY: "MARSTA",
      },
      {
        CITY: "MARSTRAND",
      },
      {
        CITY: "MATFORS",
      },
      {
        CITY: "MELLANSEL",
      },
      {
        CITY: "MELLBYSTRAND",
      },
      {
        CITY: "MELLERUD",
      },
      {
        CITY: "MJALLOM",
      },
      {
        CITY: "MJOLBY",
      },
      {
        CITY: "MOCKFJARD",
      },
      {
        CITY: "MOELLE",
      },
      {
        CITY: "MOELNDAL",
      },
      {
        CITY: "MOENSTERAS",
      },
      {
        CITY: "MOERBYLANGA",
      },
      {
        CITY: "MOHEDA",
      },
      {
        CITY: "MOHOLM",
      },
      {
        CITY: "MOLIDEN",
      },
      {
        CITY: "MOLKOM",
      },
      {
        CITY: "MOLLTORP",
      },
      {
        CITY: "MOLNBO",
      },
      {
        CITY: "MOLNLYCKE",
      },
      {
        CITY: "MORA",
      },
      {
        CITY: "MORARP",
      },
      {
        CITY: "MORGONGAVA",
      },
      {
        CITY: "MORLUNDA",
      },
      {
        CITY: "MORRUM",
      },
      {
        CITY: "MORSIL",
      },
      {
        CITY: "MORUP",
      },
      {
        CITY: "MOTALA",
      },
      {
        CITY: "MULLHYTTAN",
      },
      {
        CITY: "MULLSJOE",
      },
      {
        CITY: "MUNKA-LJUNGBY",
      },
      {
        CITY: "MUNKEDAL",
      },
      {
        CITY: "MUNKFORS",
      },
      {
        CITY: "MUSKO",
      },
      {
        CITY: "MYGGENAS",
      },
      {
        CITY: "MYRESJO",
      },
      {
        CITY: "NACKA",
      },
      {
        CITY: "NALDEN",
      },
      {
        CITY: "NASHULTA",
      },
      {
        CITY: "NASSJO",
      },
      {
        CITY: "NASUM",
      },
      {
        CITY: "NASVIKEN",
      },
      {
        CITY: "NATTRABY",
      },
      {
        CITY: "NAVEKVARN",
      },
      {
        CITY: "NAVLINGE",
      },
      {
        CITY: "NISSAFORS",
      },
      {
        CITY: "NJURUNDABOMMEN",
      },
      {
        CITY: "NJUTANGER",
      },
      {
        CITY: "NODINGE",
      },
      {
        CITY: "NODINGE-NOL",
      },
      {
        CITY: "NORA",
      },
      {
        CITY: "NORBERG",
      },
      {
        CITY: "NORDINGRA",
      },
      {
        CITY: "NORDMALING",
      },
      {
        CITY: "NORRAHAMMAR",
      },
      {
        CITY: "NORRALA",
      },
      {
        CITY: "NORRFJARDEN",
      },
      {
        CITY: "NORRHULT",
      },
      {
        CITY: "NORRSUNDET",
      },
      {
        CITY: "NORRTALJE",
      },
      {
        CITY: "NORSBORG",
      },
      {
        CITY: "NORSHOLM",
      },
      {
        CITY: "NORSJOE",
      },
      {
        CITY: "NOSSEBRO",
      },
      {
        CITY: "NYBRO",
      },
      {
        CITY: "NYGARD",
      },
      {
        CITY: "NYHAMMAR",
      },
      {
        CITY: "NYHAMNSLAGE",
      },
      {
        CITY: "NYKIL",
      },
      {
        CITY: "NYKROPPA",
      },
      {
        CITY: "NYKVARN",
      },
      {
        CITY: "NYLAND",
      },
      {
        CITY: "NYLAND",
      },
      {
        CITY: "NYNAS",
      },
      {
        CITY: "NYNASHAMN",
      },
      {
        CITY: "NYSATER",
      },
      {
        CITY: "NYVANG",
      },
      {
        CITY: "OBBOLA",
      },
      {
        CITY: "OCKELBO",
      },
      {
        CITY: "ODAKRA",
      },
      {
        CITY: "ODEBORG",
      },
      {
        CITY: "ODENSBACKEN",
      },
      {
        CITY: "OECKEROE",
      },
      {
        CITY: "OEDESHOEG",
      },
      {
        CITY: "OERKELLJUNGA",
      },
      {
        CITY: "OESTERBYMO",
      },
      {
        CITY: "OESTERVALA",
      },
      {
        CITY: "OESTHAMMAR",
      },
      {
        CITY: "OEVERKALIX",
      },
      {
        CITY: "OEVERTORNEA",
      },
      {
        CITY: "OJEBYN",
      },
      {
        CITY: "OJERSJO",
      },
      {
        CITY: "OLOFSTORP",
      },
      {
        CITY: "OLOFSTROEM",
      },
      {
        CITY: "OLSFORS",
      },
      {
        CITY: "ONNESTAD",
      },
      {
        CITY: "ORBY",
      },
      {
        CITY: "ORBYHUS",
      },
      {
        CITY: "OREGRUND",
      },
      {
        CITY: "ORMARYD",
      },
      {
        CITY: "ORNO",
      },
      {
        CITY: "ORNSKOLDSVIK",
      },
      {
        CITY: "ORREFORS",
      },
      {
        CITY: "ORSA",
      },
      {
        CITY: "ORSUNDSBRO",
      },
      {
        CITY: "ORVIKEN",
      },
      {
        CITY: "OSBY",
      },
      {
        CITY: "OSKARSHAMN",
      },
      {
        CITY: "OSKARSTROM",
      },
      {
        CITY: "OSMO",
      },
      {
        CITY: "OSTANSJO",
      },
      {
        CITY: "OSTERBYBRUK",
      },
      {
        CITY: "OSTERBYBRUK",
      },
      {
        CITY: "OSTERHANINGE",
      },
      {
        CITY: "OSTERSKAR",
      },
      {
        CITY: "OSTRA",
      },
      {
        CITY: "OSTRA FROLUNDA",
      },
      {
        CITY: "OSTRA HINDALEBYN",
      },
      {
        CITY: "OSTRA KARUP",
      },
      {
        CITY: "OSTRA RYD",
      },
      {
        CITY: "OTTERBACKEN",
      },
      {
        CITY: "OVANAKER",
      },
      {
        CITY: "OVERHORNAS",
      },
      {
        CITY: "OVERKOVLAND",
      },
      {
        CITY: "OVERLIDA",
      },
      {
        CITY: "OVERUM",
      },
      {
        CITY: "OVIKEN",
      },
      {
        CITY: "OXABACK",
      },
      {
        CITY: "OXELOSUND",
      },
      {
        CITY: "OXIE",
      },
      {
        CITY: "PAARP",
      },
      {
        CITY: "PAJALA",
      },
      {
        CITY: "PALANGE",
      },
      {
        CITY: "PALSBODA",
      },
      {
        CITY: "PARTILLE",
      },
      {
        CITY: "PASKALLAVIK",
      },
      {
        CITY: "PERSTORP",
      },
      {
        CITY: "PILGRIMSTAD",
      },
      {
        CITY: "PIXBO",
      },
      {
        CITY: "RAA",
      },
      {
        CITY: "RAETTVIK",
      },
      {
        CITY: "RAMDALA",
      },
      {
        CITY: "RAMNAS",
      },
      {
        CITY: "RAMSELE",
      },
      {
        CITY: "RAMSJO",
      },
      {
        CITY: "RAMVIK",
      },
      {
        CITY: "RANEA",
      },
      {
        CITY: "RANGEDALA",
      },
      {
        CITY: "RANSATER",
      },
      {
        CITY: "RANSTA",
      },
      {
        CITY: "RAVLANDA",
      },
      {
        CITY: "REFTELE",
      },
      {
        CITY: "REJMYRE",
      },
      {
        CITY: "REKARNE",
      },
      {
        CITY: "RENGSJO",
      },
      {
        CITY: "RESO",
      },
      {
        CITY: "RESTAD",
      },
      {
        CITY: "RIDDARHYTTAN",
      },
      {
        CITY: "RIMBO",
      },
      {
        CITY: "RIMFORSA",
      },
      {
        CITY: "RING",
      },
      {
        CITY: "RINGARUM",
      },
      {
        CITY: "RINKABY",
      },
      {
        CITY: "ROBACK",
      },
      {
        CITY: "ROBERTSFORS",
      },
      {
        CITY: "ROCKNEBY",
      },
      {
        CITY: "RODEBY",
      },
      {
        CITY: "ROKE",
      },
      {
        CITY: "ROLFSTORP",
      },
      {
        CITY: "ROMAKLOSTER",
      },
      {
        CITY: "ROMELANDA",
      },
      {
        CITY: "RONNANG",
      },
      {
        CITY: "RONNEBY",
      },
      {
        CITY: "RONNINGE",
      },
      {
        CITY: "RORO",
      },
      {
        CITY: "RORVIK",
      },
      {
        CITY: "ROSERSBERG",
      },
      {
        CITY: "ROSSON",
      },
      {
        CITY: "ROSTANGA",
      },
      {
        CITY: "ROSVIK",
      },
      {
        CITY: "ROTEBRO",
      },
      {
        CITY: "ROTTNE",
      },
      {
        CITY: "RUDA",
      },
      {
        CITY: "RUNDVIK",
      },
      {
        CITY: "RYD",
      },
      {
        CITY: "RYDAHOLM",
      },
      {
        CITY: "RYDBOHOLM",
      },
      {
        CITY: "RYDEBACK",
      },
      {
        CITY: "RYDOBRUK",
      },
      {
        CITY: "RYDSGARD",
      },
      {
        CITY: "RYSSBY",
      },
      {
        CITY: "SAEFFLE",
      },
      {
        CITY: "SAETER",
      },
      {
        CITY: "SAEVAR",
      },
      {
        CITY: "SAEVAST",
      },
      {
        CITY: "SAEVSJOE",
      },
      {
        CITY: "SAGMYRA",
      },
      {
        CITY: "SALA",
      },
      {
        CITY: "SALTSJO-DUVNAS",
      },
      {
        CITY: "SALTSJOBADEN",
      },
      {
        CITY: "SAND",
      },
      {
        CITY: "SANDARED",
      },
      {
        CITY: "SANDARNE",
      },
      {
        CITY: "SANDBY",
      },
      {
        CITY: "SANDHEM",
      },
      {
        CITY: "SANDHULT",
      },
      {
        CITY: "SANDVIKEN",
      },
      {
        CITY: "SANKT OLOF",
      },
      {
        CITY: "SARO",
      },
      {
        CITY: "SATILA",
      },
      {
        CITY: "SAVEDALEN",
      },
      {
        CITY: "SAVSJOSTROM",
      },
      {
        CITY: "SAXTORP",
      },
      {
        CITY: "SEGERSTA",
      },
      {
        CITY: "SEGLORA",
      },
      {
        CITY: "SEGMON",
      },
      {
        CITY: "SENNAN",
      },
      {
        CITY: "SEXDREGA",
      },
      {
        CITY: "SIBBHULT",
      },
      {
        CITY: "SIDENSJO",
      },
      {
        CITY: "SIFFERBO",
      },
      {
        CITY: "SIGTUNA",
      },
      {
        CITY: "SILJANSNAS",
      },
      {
        CITY: "SIMLANGSDALEN",
      },
      {
        CITY: "SIMRISHAMN",
      },
      {
        CITY: "SJALEVAD",
      },
      {
        CITY: "SJOEBO",
      },
      {
        CITY: "SJOMARKEN",
      },
      {
        CITY: "SJOTOFTA",
      },
      {
        CITY: "SJOVIK",
      },
      {
        CITY: "SJUNTORP",
      },
      {
        CITY: "SKA",
      },
      {
        CITY: "SKAERHAMN",
      },
      {
        CITY: "SKAERHOLMEN",
      },
      {
        CITY: "SKALLINGE",
      },
      {
        CITY: "SKANE",
      },
      {
        CITY: "SKANES FAGERHULT",
      },
      {
        CITY: "SKANNINGE",
      },
      {
        CITY: "SKANOR MED FALSTERBO",
      },
      {
        CITY: "SKARA",
      },
      {
        CITY: "SKARABORG",
      },
      {
        CITY: "SKARBLACKA",
      },
      {
        CITY: "SKARPLINGE",
      },
      {
        CITY: "SKARPNAECK",
      },
      {
        CITY: "SKATTKARR",
      },
      {
        CITY: "SKEE",
      },
      {
        CITY: "SKELLEFTEHAMN",
      },
      {
        CITY: "SKENE",
      },
      {
        CITY: "SKEPPLANDA",
      },
      {
        CITY: "SKILLINGARYD",
      },
      {
        CITY: "SKILLINGE",
      },
      {
        CITY: "SKINNSKATTEBERG",
      },
      {
        CITY: "SKIVARP",
      },
      {
        CITY: "SKOGAS",
      },
      {
        CITY: "SKOGHALL",
      },
      {
        CITY: "SKOGSTORP",
      },
      {
        CITY: "SKOLDINGE",
      },
      {
        CITY: "SKOLLERSTA",
      },
      {
        CITY: "SKONDAL",
      },
      {
        CITY: "SKRUV",
      },
      {
        CITY: "SKULTUNA",
      },
      {
        CITY: "SKUMMESLOVSSTRAND",
      },
      {
        CITY: "SKURUP",
      },
      {
        CITY: "SKYLLBERG",
      },
      {
        CITY: "SKYTTORP",
      },
      {
        CITY: "SLITE",
      },
      {
        CITY: "SLOINGE",
      },
      {
        CITY: "SLOTTSBRON",
      },
      {
        CITY: "SLUTARP",
      },
      {
        CITY: "SMALANDSSTENAR",
      },
      {
        CITY: "SMEDJEBACKEN",
      },
      {
        CITY: "SMEDSTORP",
      },
      {
        CITY: "SMOEGEN",
      },
      {
        CITY: "SODERAKRA",
      },
      {
        CITY: "SODERALA",
      },
      {
        CITY: "SODERBY",
      },
      {
        CITY: "SODERFORS",
      },
      {
        CITY: "SODERGARD",
      },
      {
        CITY: "SODERHAMN",
      },
      {
        CITY: "SODRA SANDBY",
      },
      {
        CITY: "SODRA SUNDERBYN",
      },
      {
        CITY: "SODRA VI",
      },
      {
        CITY: "SOEDERBAERKE",
      },
      {
        CITY: "SOEDERKOEPING",
      },
      {
        CITY: "SOLLEBRUNN",
      },
      {
        CITY: "SOLLEFTEA",
      },
      {
        CITY: "SOLLENTUNA",
      },
      {
        CITY: "SOLNA",
      },
      {
        CITY: "SOMMEN",
      },
      {
        CITY: "SORAKER",
      },
      {
        CITY: "SORBERGE",
      },
      {
        CITY: "SOROMBACKEN",
      },
      {
        CITY: "SORSELE",
      },
      {
        CITY: "SOSDALA",
      },
      {
        CITY: "SOVDE",
      },
      {
        CITY: "SPANGA",
      },
      {
        CITY: "SPARSOR",
      },
      {
        CITY: "SPEKEROD",
      },
      {
        CITY: "STAFFANSTORP",
      },
      {
        CITY: "STALLARHOLMEN",
      },
      {
        CITY: "STALLDALEN",
      },
      {
        CITY: "STANGA",
      },
      {
        CITY: "STEHAG",
      },
      {
        CITY: "STENHAMRA",
      },
      {
        CITY: "STENKULLEN",
      },
      {
        CITY: "STENSELE",
      },
      {
        CITY: "STENSTORP",
      },
      {
        CITY: "STENUNGSUND",
      },
      {
        CITY: "STIGEN",
      },
      {
        CITY: "STIGTOMTA",
      },
      {
        CITY: "STJARNHOV",
      },
      {
        CITY: "STOBY",
      },
      {
        CITY: "STOCKARYD",
      },
      {
        CITY: "STOCKHOLM",
      },
      {
        CITY: "STOCKSUND",
      },
      {
        CITY: "STODE",
      },
      {
        CITY: "STOLLET",
      },
      {
        CITY: "STORA",
      },
      {
        CITY: "STORA HOGA",
      },
      {
        CITY: "STORA MELLOSA",
      },
      {
        CITY: "STORA SKEDVI",
      },
      {
        CITY: "STORA VIKA",
      },
      {
        CITY: "STOREBRO",
      },
      {
        CITY: "STORFORS",
      },
      {
        CITY: "STORLIEN",
      },
      {
        CITY: "STORUMAN",
      },
      {
        CITY: "STORVIK",
      },
      {
        CITY: "STORVRETA",
      },
      {
        CITY: "STRA",
      },
      {
        CITY: "STRANGNAS",
      },
      {
        CITY: "STRATJARA",
      },
      {
        CITY: "STRAVALLA",
      },
      {
        CITY: "STROEMSTAD",
      },
      {
        CITY: "STROEMSUND",
      },
      {
        CITY: "STROMSBRUK",
      },
      {
        CITY: "STROMSHOLM",
      },
      {
        CITY: "STROMSNASBRUK",
      },
      {
        CITY: "STROVELSTORP",
      },
      {
        CITY: "STUGUN",
      },
      {
        CITY: "STUREFORS",
      },
      {
        CITY: "STURKO",
      },
      {
        CITY: "STYRSO",
      },
      {
        CITY: "SUNDBORN",
      },
      {
        CITY: "SUNDBYBERG",
      },
      {
        CITY: "SUNDSBRUK",
      },
      {
        CITY: "SUNDSVALL",
      },
      {
        CITY: "SUNNANSJO",
      },
      {
        CITY: "SUNNE",
      },
      {
        CITY: "SUNNEMO",
      },
      {
        CITY: "SURAHAMMAR",
      },
      {
        CITY: "SURTE",
      },
      {
        CITY: "SVALOEV",
      },
      {
        CITY: "SVANEHOLM",
      },
      {
        CITY: "SVANEHOLM",
      },
      {
        CITY: "SVANESUND",
      },
      {
        CITY: "SVANGSTA",
      },
      {
        CITY: "SVARDSJO",
      },
      {
        CITY: "SVARTA",
      },
      {
        CITY: "SVARTINGE",
      },
      {
        CITY: "SVARTSJO",
      },
      {
        CITY: "SVEDALA",
      },
      {
        CITY: "SVEG",
      },
      {
        CITY: "SVENLJUNGA",
      },
      {
        CITY: "SVENSBYN",
      },
      {
        CITY: "SVENSHOGEN",
      },
      {
        CITY: "SVENSTAVIK",
      },
      {
        CITY: "TABERG",
      },
      {
        CITY: "TABY",
      },
      {
        CITY: "TAENNDALEN",
      },
      {
        CITY: "TAERNABY",
      },
      {
        CITY: "TAFTEA",
      },
      {
        CITY: "TAGARP",
      },
      {
        CITY: "TALJE",
      },
      {
        CITY: "TALJO",
      },
      {
        CITY: "TALLASEN",
      },
      {
        CITY: "TALLBERG",
      },
      {
        CITY: "TANUMSHEDE",
      },
      {
        CITY: "TARNSJO",
      },
      {
        CITY: "TAVELSJO",
      },
      {
        CITY: "TECKOMATORP",
      },
      {
        CITY: "TEGELTORP",
      },
      {
        CITY: "TENHULT",
      },
      {
        CITY: "TIBRO",
      },
      {
        CITY: "TIDAHOLM",
      },
      {
        CITY: "TIDAN",
      },
      {
        CITY: "TIERP",
      },
      {
        CITY: "TIMMELE",
      },
      {
        CITY: "TIMMERNABBEN",
      },
      {
        CITY: "TIMMERSDALA",
      },
      {
        CITY: "TIMRA",
      },
      {
        CITY: "TINGSRYD",
      },
      {
        CITY: "TINGSTADE",
      },
      {
        CITY: "TJORNARP",
      },
      {
        CITY: "TOECKSFORS",
      },
      {
        CITY: "TOERE",
      },
      {
        CITY: "TOEREBODA",
      },
      {
        CITY: "TOLG",
      },
      {
        CITY: "TOLLARP",
      },
      {
        CITY: "TOLLERED",
      },
      {
        CITY: "TOMELILLA",
      },
      {
        CITY: "TOREKOV",
      },
      {
        CITY: "TORMESTORP",
      },
      {
        CITY: "TORPSHAMMAR",
      },
      {
        CITY: "TORSAKER",
      },
      {
        CITY: "TORSAS",
      },
      {
        CITY: "TORSBY",
      },
      {
        CITY: "TORSHALLA",
      },
      {
        CITY: "TORSLANDA",
      },
      {
        CITY: "TORUP",
      },
      {
        CITY: "TRANAS",
      },
      {
        CITY: "TRANEMO",
      },
      {
        CITY: "TRANGSUND",
      },
      {
        CITY: "TRANSTRAND",
      },
      {
        CITY: "TRARYD",
      },
      {
        CITY: "TRASLOVSLAGE",
      },
      {
        CITY: "TRASLOVSLAGE",
      },
      {
        CITY: "TRAVAD",
      },
      {
        CITY: "TREHORNINGSJO",
      },
      {
        CITY: "TREKANTEN",
      },
      {
        CITY: "TRELLEBORG",
      },
      {
        CITY: "TROLLHATTAN",
      },
      {
        CITY: "TROSA",
      },
      {
        CITY: "TULLINGE",
      },
      {
        CITY: "TUMBA",
      },
      {
        CITY: "TUN",
      },
      {
        CITY: "TUNGELSTA",
      },
      {
        CITY: "TVAAKER",
      },
      {
        CITY: "TVARALUND",
      },
      {
        CITY: "TVING",
      },
      {
        CITY: "TYGELSJO",
      },
      {
        CITY: "TYRESO STRAND",
      },
      {
        CITY: "TYRINGE",
      },
      {
        CITY: "TYSTBERGA",
      },
      {
        CITY: "UCKLUM",
      },
      {
        CITY: "UDDEBO",
      },
      {
        CITY: "UDDEHOLM",
      },
      {
        CITY: "UDDEVALLA",
      },
      {
        CITY: "UGGLARP",
      },
      {
        CITY: "ULLARED",
      },
      {
        CITY: "ULRICEHAMN",
      },
      {
        CITY: "ULRIKA",
      },
      {
        CITY: "UNDERSAKER",
      },
      {
        CITY: "UNNARYD",
      },
      {
        CITY: "UPPHARAD",
      },
      {
        CITY: "UPPLANDS VASBY",
      },
      {
        CITY: "UPPSALA",
      },
      {
        CITY: "URSHULT",
      },
      {
        CITY: "URSVIKEN",
      },
      {
        CITY: "UTANSJO",
      },
      {
        CITY: "UTTRAN",
      },
      {
        CITY: "VACKELSANG",
      },
      {
        CITY: "VADDO",
      },
      {
        CITY: "VADERSTAD",
      },
      {
        CITY: "VADSTENA",
      },
      {
        CITY: "VAEJA",
      },
      {
        CITY: "VAELLINGBY",
      },
      {
        CITY: "VAENNAES",
      },
      {
        CITY: "VAERMDOE",
      },
      {
        CITY: "VAGGERYD",
      },
      {
        CITY: "VAGNHARAD",
      },
      {
        CITY: "VALADALEN",
      },
      {
        CITY: "VALBERG",
      },
      {
        CITY: "VALBO",
      },
      {
        CITY: "VALDEMARSVIK",
      },
      {
        CITY: "VALLA",
      },
      {
        CITY: "VALLAKRA",
      },
      {
        CITY: "VALLDA",
      },
      {
        CITY: "VALLENTUNA",
      },
      {
        CITY: "VALLSTA",
      },
      {
        CITY: "VALSKOG",
      },
      {
        CITY: "VANERSBORG",
      },
      {
        CITY: "VANKIVA",
      },
      {
        CITY: "VANNASBY",
      },
      {
        CITY: "VANSBRO",
      },
      {
        CITY: "VARA",
      },
      {
        CITY: "VARBERG",
      },
      {
        CITY: "VARBY",
      },
      {
        CITY: "VAREKIL",
      },
      {
        CITY: "VARGARDA",
      },
      {
        CITY: "VARGON",
      },
      {
        CITY: "VARING",
      },
      {
        CITY: "VARMLAND",
      },
      {
        CITY: "VARNAMO",
      },
      {
        CITY: "VARNHEM",
      },
      {
        CITY: "VAROBACKA",
      },
      {
        CITY: "VARSAS",
      },
      {
        CITY: "VARSTA",
      },
      {
        CITY: "VARTOFTA",
      },
      {
        CITY: "VASE",
      },
      {
        CITY: "VASSMOLOSA",
      },
      {
        CITY: "VASTERFARNEBO",
      },
      {
        CITY: "VASTERLJUNG",
      },
      {
        CITY: "VASTERVIK",
      },
      {
        CITY: "VASTRA AMTERVIK",
      },
      {
        CITY: "VASTRA FROLUNDA",
      },
      {
        CITY: "VASTRA KARUP",
      },
      {
        CITY: "VASTRA RAMLOSA",
      },
      {
        CITY: "VASTRA TORUP",
      },
      {
        CITY: "VATO",
      },
      {
        CITY: "VATTHOLMA",
      },
      {
        CITY: "VAXHOLM",
      },
      {
        CITY: "VAXJO",
      },
      {
        CITY: "VAXTORP",
      },
      {
        CITY: "VEBEROD",
      },
      {
        CITY: "VEDDIGE",
      },
      {
        CITY: "VEDUM",
      },
      {
        CITY: "VEGA",
      },
      {
        CITY: "VEGBY",
      },
      {
        CITY: "VEINGE",
      },
      {
        CITY: "VEJBYSTRAND",
      },
      {
        CITY: "VELLINGE",
      },
      {
        CITY: "VENDELSO",
      },
      {
        CITY: "VESSIGEBRO",
      },
      {
        CITY: "VETLANDA",
      },
      {
        CITY: "VIDSEL",
      },
      {
        CITY: "VIKARBYN",
      },
      {
        CITY: "VIKEN",
      },
      {
        CITY: "VIKINGSTAD",
      },
      {
        CITY: "VIKMANSHYTTAN",
      },
      {
        CITY: "VIKSJOFORS",
      },
      {
        CITY: "VILHELMINA",
      },
      {
        CITY: "VILLAN",
      },
      {
        CITY: "VIMMERBY",
      },
      {
        CITY: "VINBERG",
      },
      {
        CITY: "VINDELN",
      },
      {
        CITY: "VINGAKER",
      },
      {
        CITY: "VINNINGA",
      },
      {
        CITY: "VINSLOV",
      },
      {
        CITY: "VINTRIE",
      },
      {
        CITY: "VINTROSA",
      },
      {
        CITY: "VIRSBO BRUK",
      },
      {
        CITY: "VIRSERUM",
      },
      {
        CITY: "VISBY",
      },
      {
        CITY: "VISKAFORS",
      },
      {
        CITY: "VISLANDA",
      },
      {
        CITY: "VISSEFJARDA",
      },
      {
        CITY: "VITABY",
      },
      {
        CITY: "VITTARYD",
      },
      {
        CITY: "VITTARYD",
      },
      {
        CITY: "VITTSJO",
      },
      {
        CITY: "VOLLSJO",
      },
      {
        CITY: "VRENA",
      },
      {
        CITY: "VRETA KLOSTER",
      },
      {
        CITY: "VRETSTORP",
      },
      {
        CITY: "YNGSJO",
      },
      {
        CITY: "YSTAD",
      },
      {
        CITY: "YTTERBY",
      },
      {
        CITY: "ZINKGRUVAN",
      },
    ],
  },
  {
    COUNTRY: "SRI LANKA",
    CODE: "LK",
    CITIES: [
      {
        CITY: "BADULLA",
      },
      {
        CITY: "BATTARAMULLA SOUTH",
      },
      {
        CITY: "BIYAGAMA",
      },
      {
        CITY: "BORALESGAMUWA SOUTH",
      },
      {
        CITY: "COLOMBO",
      },
      {
        CITY: "DEHIWALA",
      },
      {
        CITY: "DEHIWALA-MOUNT LAVINIA",
      },
      {
        CITY: "EPPAWALA",
      },
      {
        CITY: "GAMPAHA",
      },
      {
        CITY: "GANGODAWILA NORTH",
      },
      {
        CITY: "HEKITTA",
      },
      {
        CITY: "HOMAGAMA",
      },
      {
        CITY: "KADUWELA",
      },
      {
        CITY: "KANDANA",
      },
      {
        CITY: "KANDY",
      },
      {
        CITY: "KATUBEDDA",
      },
      {
        CITY: "KATUNAYAKA",
      },
      {
        CITY: "KELANIYA",
      },
      {
        CITY: "KOHUWALA",
      },
      {
        CITY: "MAHARAGAMA",
      },
      {
        CITY: "MAKOLA SOUTH",
      },
      {
        CITY: "MATALE",
      },
      {
        CITY: "MORATUWA",
      },
      {
        CITY: "NATTANDIYA TOWN",
      },
      {
        CITY: "NEGOMBO",
      },
      {
        CITY: "NUGEGODA",
      },
      {
        CITY: "ORUGODAWATTA",
      },
      {
        CITY: "PADUKKA",
      },
      {
        CITY: "PANNIPITIYA",
      },
      {
        CITY: "PERADENIYA",
      },
      {
        CITY: "PILIYANDALA",
      },
      {
        CITY: "RAGAMA",
      },
      {
        CITY: "RAJAGIRIYA",
      },
      {
        CITY: "SRI JAYEWARDENEPURA KOTTE",
      },
      {
        CITY: "TALAPATHPITIYA",
      },
      {
        CITY: "WATTALA",
      },
      {
        CITY: "WELLAMPITIYA",
      },
    ],
  },
  {
    COUNTRY: "SURINAME",
    CODE: "SR",
    CITIES: [
      {
        CITY: "BOTOPASI",
      },
      {
        CITY: "BROWNSWEG",
      },
      {
        CITY: "FRIENDSHIP",
      },
      {
        CITY: "GRONINGEN",
      },
      {
        CITY: "MOENGO",
      },
      {
        CITY: "NIEUW AMSTERDAM",
      },
      {
        CITY: "ONVERWACHT",
      },
      {
        CITY: "PARAMARIBO",
      },
      {
        CITY: "TOTNESS",
      },
    ],
  },
  {
    COUNTRY: "SAUDI ARABIA",
    CODE: "SA",
    CITIES: [
      {
        CITY: "ABHA",
      },
      {
        CITY: "ABQAIQ",
      },
      {
        CITY: "AL BAHAH",
      },
      {
        CITY: "AL FARUQ",
      },
      {
        CITY: "AL HUFUF",
      },
      {
        CITY: "AL QATIF",
      },
      {
        CITY: "AL YAMAMAH",
      },
      {
        CITY: "AT TUWAL",
      },
      {
        CITY: "BURAIDAH",
      },
      {
        CITY: "DAMMAM",
      },
      {
        CITY: "DHAHRAN",
      },
      {
        CITY: "HAYIL",
      },
      {
        CITY: "JAZIRAH",
      },
      {
        CITY: "JAZIRAH",
      },
      {
        CITY: "JEDDAH",
      },
      {
        CITY: "JIZAN",
      },
      {
        CITY: "JUBAIL",
      },
      {
        CITY: "KHAMIS MUSHAIT",
      },
      {
        CITY: "KHOBAR",
      },
      {
        CITY: "KHULAYS",
      },
      {
        CITY: "LINAH",
      },
      {
        CITY: "MADINAT YANBU` AS SINA`IYAH",
      },
      {
        CITY: "MECCA",
      },
      {
        CITY: "MEDINA",
      },
      {
        CITY: "MINA",
      },
      {
        CITY: "NAJRAN",
      },
      {
        CITY: "RABIGH",
      },
      {
        CITY: "RAHIMAH",
      },
      {
        CITY: "RAHMAN",
      },
      {
        CITY: "RAMDAH",
      },
      {
        CITY: "RAS TANURA",
      },
      {
        CITY: "RIYADH",
      },
      {
        CITY: "SABYA",
      },
      {
        CITY: "SAFWA",
      },
      {
        CITY: "SAKAKA",
      },
      {
        CITY: "SAMBAH",
      },
      {
        CITY: "SAYHAT",
      },
      {
        CITY: "TABUK",
      },
      {
        CITY: "YANBU` AL BAHR",
      },
    ],
  },
  {
    COUNTRY: "TRINIDAD AND TOBAGO",
    CODE: "TT",
    CITIES: [
      {
        CITY: "ARIMA",
      },
      {
        CITY: "AROUCA",
      },
      {
        CITY: "BARATARIA",
      },
      {
        CITY: "CALIFORNIA",
      },
      {
        CITY: "CARAPICHAIMA",
      },
      {
        CITY: "CARENAGE",
      },
      {
        CITY: "CARONI",
      },
      {
        CITY: "CHAGUANAS",
      },
      {
        CITY: "CLAXTON BAY",
      },
      {
        CITY: "COUVA",
      },
      {
        CITY: "CUMUTO",
      },
      {
        CITY: "CUNUPIA",
      },
      {
        CITY: "CUREPE",
      },
      {
        CITY: "DEBE",
      },
      {
        CITY: "DIEGO MARTIN",
      },
      {
        CITY: "EL DORADO",
      },
      {
        CITY: "FREEPORT",
      },
      {
        CITY: "FYZABAD",
      },
      {
        CITY: "GASPARILLO",
      },
      {
        CITY: "GOLDEN LANE",
      },
      {
        CITY: "LAVENTILLE",
      },
      {
        CITY: "LOPINOT",
      },
      {
        CITY: "MARABELLA",
      },
      {
        CITY: "MARAVAL",
      },
      {
        CITY: "MORIAH",
      },
      {
        CITY: "MORVANT",
      },
      {
        CITY: "PENAL",
      },
      {
        CITY: "PETIT VALLEY",
      },
      {
        CITY: "PHOENIX PARK",
      },
      {
        CITY: "PIARCO",
      },
      {
        CITY: "PORT OF SPAIN",
      },
      {
        CITY: "PRINCES TOWN",
      },
      {
        CITY: "RIO CLARO",
      },
      {
        CITY: "ROXBOROUGH",
      },
      {
        CITY: "SAINT AUGUSTINE",
      },
      {
        CITY: "SAINT CLAIR",
      },
      {
        CITY: "SAINT JAMES",
      },
      {
        CITY: "SAINT JOSEPH",
      },
      {
        CITY: "SAN FERNANDO",
      },
      {
        CITY: "SAN JUAN",
      },
      {
        CITY: "SANGRE GRANDE",
      },
      {
        CITY: "SANTA CRUZ",
      },
      {
        CITY: "SCARBOROUGH",
      },
      {
        CITY: "SIPARIA",
      },
      {
        CITY: "TUNAPUNA",
      },
      {
        CITY: "VALSAYN",
      },
      {
        CITY: "VICTORIA",
      },
      {
        CITY: "WARD OF TACARIGUA",
      },
      {
        CITY: "WOODBROOK",
      },
    ],
  },
  {
    COUNTRY: "TURKEY",
    CODE: "TR",
    CITIES: [
      {
        CITY: "ABDULLAH",
      },
      {
        CITY: "ACIBADEM",
      },
      {
        CITY: "ADA",
      },
      {
        CITY: "ADANA",
      },
      {
        CITY: "ADNAN MENDERES",
      },
      {
        CITY: "AFYONKARAHISAR",
      },
      {
        CITY: "AKHISAR",
      },
      {
        CITY: "AKSARAY",
      },
      {
        CITY: "ALANYA",
      },
      {
        CITY: "ALPASLAN",
      },
      {
        CITY: "AMASRA",
      },
      {
        CITY: "AMASYA",
      },
      {
        CITY: "ANDAC",
      },
      {
        CITY: "ANKARA",
      },
      {
        CITY: "ANTAKYA",
      },
      {
        CITY: "ANTALYA",
      },
      {
        CITY: "ARDAHAN",
      },
      {
        CITY: "ARTVIN",
      },
      {
        CITY: "ATAKOY",
      },
      {
        CITY: "ATATURK",
      },
      {
        CITY: "ATLAS",
      },
      {
        CITY: "AYAZAGA",
      },
      {
        CITY: "AYDIN",
      },
      {
        CITY: "AYDOGAN",
      },
      {
        CITY: "AYDOGDU",
      },
      {
        CITY: "BABAESKI",
      },
      {
        CITY: "BALCOVA",
      },
      {
        CITY: "BANAZ",
      },
      {
        CITY: "BANDIRMA",
      },
      {
        CITY: "BARTIN",
      },
      {
        CITY: "BASARI",
      },
      {
        CITY: "BASKENT",
      },
      {
        CITY: "BASKIL",
      },
      {
        CITY: "BATMAN",
      },
      {
        CITY: "BAYBURT",
      },
      {
        CITY: "BAYRAMPASA",
      },
      {
        CITY: "BELEK",
      },
      {
        CITY: "BERGAMA",
      },
      {
        CITY: "BESIKTAS",
      },
      {
        CITY: "BEYKENT",
      },
      {
        CITY: "BEYKOZ",
      },
      {
        CITY: "BEYSEHIR",
      },
      {
        CITY: "BIGA",
      },
      {
        CITY: "BILECIK",
      },
      {
        CITY: "BILGI",
      },
      {
        CITY: "BINGOL",
      },
      {
        CITY: "BIRBIR",
      },
      {
        CITY: "BITAM",
      },
      {
        CITY: "BITLIS",
      },
      {
        CITY: "BODRUM",
      },
      {
        CITY: "BOGAZICI",
      },
      {
        CITY: "BOLU",
      },
      {
        CITY: "BOR",
      },
      {
        CITY: "BORNOVA",
      },
      {
        CITY: "BOZUYUK",
      },
      {
        CITY: "BUCA",
      },
      {
        CITY: "BUCAK",
      },
      {
        CITY: "BUEYUEKCEKMECE",
      },
      {
        CITY: "BUGDAYLI",
      },
      {
        CITY: "BULUT",
      },
      {
        CITY: "BURDUR",
      },
      {
        CITY: "BURGAZ",
      },
      {
        CITY: "BURHANIYE",
      },
      {
        CITY: "BURSA",
      },
      {
        CITY: "CALKAYA",
      },
      {
        CITY: "CAMLIYAYLA",
      },
      {
        CITY: "CEKME",
      },
      {
        CITY: "CELAL",
      },
      {
        CITY: "CERMIK",
      },
      {
        CITY: "CEYHAN",
      },
      {
        CITY: "CINE",
      },
      {
        CITY: "CUKUROVA",
      },
      {
        CITY: "DALAMAN",
      },
      {
        CITY: "DARICA",
      },
      {
        CITY: "DAYANIKLI",
      },
      {
        CITY: "DEMETEVLERI",
      },
      {
        CITY: "DENIZ",
      },
      {
        CITY: "DENIZLI",
      },
      {
        CITY: "DOGA",
      },
      {
        CITY: "DOGANKOY",
      },
      {
        CITY: "DOGU",
      },
      {
        CITY: "DOGUS",
      },
      {
        CITY: "DOKUZ",
      },
      {
        CITY: "DORTYOL",
      },
      {
        CITY: "DORUKLU",
      },
      {
        CITY: "DUMLUPINAR",
      },
      {
        CITY: "DURAN",
      },
      {
        CITY: "DUZCE",
      },
      {
        CITY: "EDEBEY",
      },
      {
        CITY: "EDIRNE",
      },
      {
        CITY: "EDREMIT",
      },
      {
        CITY: "ELBISTAN",
      },
      {
        CITY: "ELEK",
      },
      {
        CITY: "ERBAA",
      },
      {
        CITY: "ERCIS",
      },
      {
        CITY: "ERCIYES",
      },
      {
        CITY: "ERENKOY",
      },
      {
        CITY: "ERZIN",
      },
      {
        CITY: "ERZINCAN",
      },
      {
        CITY: "ERZURUM",
      },
      {
        CITY: "ESENKENT",
      },
      {
        CITY: "ESENTEPE",
      },
      {
        CITY: "ESENYURT",
      },
      {
        CITY: "ESER",
      },
      {
        CITY: "ESKISEHIR",
      },
      {
        CITY: "ETIMESGUT",
      },
      {
        CITY: "FATIH",
      },
      {
        CITY: "FERIZLI",
      },
      {
        CITY: "FETHIYE",
      },
      {
        CITY: "FINDIKLI",
      },
      {
        CITY: "GAZI",
      },
      {
        CITY: "GAZIANTEP",
      },
      {
        CITY: "GEBZE",
      },
      {
        CITY: "GEDIZ",
      },
      {
        CITY: "GELIBOLU",
      },
      {
        CITY: "GEMLIK",
      },
      {
        CITY: "GERMENCIK",
      },
      {
        CITY: "GEULZUK",
      },
      {
        CITY: "GIRESUN",
      },
      {
        CITY: "GIRNE",
      },
      {
        CITY: "GOLMARMARA",
      },
      {
        CITY: "GONEN",
      },
      {
        CITY: "GUNBULDU",
      },
      {
        CITY: "GUZELYURT",
      },
      {
        CITY: "HAKKARI",
      },
      {
        CITY: "HALIC",
      },
      {
        CITY: "HARBIYE",
      },
      {
        CITY: "HARRAN",
      },
      {
        CITY: "HENDEK",
      },
      {
        CITY: "HURRIYET",
      },
      {
        CITY: "IBRAHIM",
      },
      {
        CITY: "IKITELLI",
      },
      {
        CITY: "ISKILIP",
      },
      {
        CITY: "ISPARTA",
      },
      {
        CITY: "ISTANBUL",
      },
      {
        CITY: "IZMIR",
      },
      {
        CITY: "KADIKOEY",
      },
      {
        CITY: "KADIKOY",
      },
      {
        CITY: "KADIR",
      },
      {
        CITY: "KADIRLI",
      },
      {
        CITY: "KAGITHANE",
      },
      {
        CITY: "KARABUK",
      },
      {
        CITY: "KARACA",
      },
      {
        CITY: "KARAKOY",
      },
      {
        CITY: "KARAMAN",
      },
      {
        CITY: "KARAMURSEL",
      },
      {
        CITY: "KARATEKIN",
      },
      {
        CITY: "KARS",
      },
      {
        CITY: "KARTAL",
      },
      {
        CITY: "KASTAMONU",
      },
      {
        CITY: "KAYSERI",
      },
      {
        CITY: "KECIOEREN",
      },
      {
        CITY: "KEMAL",
      },
      {
        CITY: "KIBRISKOY",
      },
      {
        CITY: "KILIS",
      },
      {
        CITY: "KIRIKKALE",
      },
      {
        CITY: "KISIR",
      },
      {
        CITY: "KOCAKOY",
      },
      {
        CITY: "KOCATEPE",
      },
      {
        CITY: "KONAK",
      },
      {
        CITY: "KONYA",
      },
      {
        CITY: "KORFEZ",
      },
      {
        CITY: "KOZAN",
      },
      {
        CITY: "KUCUKCEKMECE",
      },
      {
        CITY: "KUMLUCA",
      },
      {
        CITY: "KUSADASI",
      },
      {
        CITY: "KUZEY",
      },
      {
        CITY: "MAGNESIA AD SIPYLUM",
      },
      {
        CITY: "MAHMUTKOY",
      },
      {
        CITY: "MALATYA",
      },
      {
        CITY: "MALTEPE",
      },
      {
        CITY: "MANAVGAT",
      },
      {
        CITY: "MARDIN",
      },
      {
        CITY: "MARMARIS",
      },
      {
        CITY: "MASLAK",
      },
      {
        CITY: "MEHMET AKIF ERSOY",
      },
      {
        CITY: "MELIKSAH",
      },
      {
        CITY: "MERIC",
      },
      {
        CITY: "MERSIN",
      },
      {
        CITY: "MERZIFON",
      },
      {
        CITY: "MEZITLI",
      },
      {
        CITY: "MILAS",
      },
      {
        CITY: "MUGLA",
      },
      {
        CITY: "MURADIYE",
      },
      {
        CITY: "MURATPASA",
      },
      {
        CITY: "MUSTAFA",
      },
      {
        CITY: "MUSTAFA KEMAL",
      },
      {
        CITY: "MUSTAFAKEMALPASA",
      },
      {
        CITY: "NAZILLI",
      },
      {
        CITY: "NIKSAR",
      },
      {
        CITY: "NILUEFER",
      },
      {
        CITY: "NIZIP",
      },
      {
        CITY: "ORDU",
      },
      {
        CITY: "ORHANGAZI",
      },
      {
        CITY: "ORTA",
      },
      {
        CITY: "OSMANELI",
      },
      {
        CITY: "OSMANGAZI",
      },
      {
        CITY: "OSMANIYE",
      },
      {
        CITY: "OSTIM",
      },
      {
        CITY: "PAMUKKALE",
      },
      {
        CITY: "PATNOS",
      },
      {
        CITY: "PENDIK",
      },
      {
        CITY: "PIRI",
      },
      {
        CITY: "RIZE",
      },
      {
        CITY: "SABANCI",
      },
      {
        CITY: "SAFRANBOLU",
      },
      {
        CITY: "SAGLIK",
      },
      {
        CITY: "SAHINBEY",
      },
      {
        CITY: "SALIHLI",
      },
      {
        CITY: "SAMSUN",
      },
      {
        CITY: "SANAYI",
      },
      {
        CITY: "SANLIURFA",
      },
      {
        CITY: "SARIYER",
      },
      {
        CITY: "SEFERIHISAR",
      },
      {
        CITY: "SEHITKAMIL",
      },
      {
        CITY: "SELCUK",
      },
      {
        CITY: "SERDIVAN",
      },
      {
        CITY: "SERIK",
      },
      {
        CITY: "SEYDISEHIR",
      },
      {
        CITY: "SEYHAN",
      },
      {
        CITY: "SIIRT",
      },
      {
        CITY: "SILE",
      },
      {
        CITY: "SILIFKE",
      },
      {
        CITY: "SILIVIRI",
      },
      {
        CITY: "SINANKOY",
      },
      {
        CITY: "SINCAN",
      },
      {
        CITY: "SINOP",
      },
      {
        CITY: "SIRNAK",
      },
      {
        CITY: "SISLI",
      },
      {
        CITY: "SISMAN",
      },
      {
        CITY: "SIVAS",
      },
      {
        CITY: "SOEKE",
      },
      {
        CITY: "SULEYMAN",
      },
      {
        CITY: "SULTANPINAR",
      },
      {
        CITY: "SUNGURLU",
      },
      {
        CITY: "SUSURLUK",
      },
      {
        CITY: "TAKSIM",
      },
      {
        CITY: "TARSUS",
      },
      {
        CITY: "TASDELEN",
      },
      {
        CITY: "TATVAN",
      },
      {
        CITY: "TESVIKIYE",
      },
      {
        CITY: "TIRE",
      },
      {
        CITY: "TOKAT PROVINCE",
      },
      {
        CITY: "TOPRAK",
      },
      {
        CITY: "TORBALI",
      },
      {
        CITY: "TOROS",
      },
      {
        CITY: "TRABZON",
      },
      {
        CITY: "TRAKYA",
      },
      {
        CITY: "TUNCELI",
      },
      {
        CITY: "TURGUT",
      },
      {
        CITY: "TURGUTLU",
      },
      {
        CITY: "UCHISAR",
      },
      {
        CITY: "UESKUEDAR",
      },
      {
        CITY: "UGUR",
      },
      {
        CITY: "ULKER",
      },
      {
        CITY: "ULUDAG",
      },
      {
        CITY: "ULUS",
      },
      {
        CITY: "UMRANIYE",
      },
      {
        CITY: "UNIEH",
      },
      {
        CITY: "URGUB",
      },
      {
        CITY: "URLA",
      },
      {
        CITY: "UZUN KEUPRU",
      },
      {
        CITY: "VAN",
      },
      {
        CITY: "YAGCILAR",
      },
      {
        CITY: "YAKAKOY",
      },
      {
        CITY: "YALOVA",
      },
      {
        CITY: "YAMAN",
      },
      {
        CITY: "YASARKOY",
      },
      {
        CITY: "YEDITEPE",
      },
      {
        CITY: "YENI",
      },
      {
        CITY: "YENIBOSNA",
      },
      {
        CITY: "YILDIZ",
      },
      {
        CITY: "YOZGAT",
      },
      {
        CITY: "YUREGIR",
      },
      {
        CITY: "ZEKERIYA",
      },
      {
        CITY: "ZEYTINBURNU",
      },
      {
        CITY: "ZONGULDAK",
      },
    ],
  },
  {
    COUNTRY: "UNITED ARAB EMIRATES",
    CODE: "AE",
    CITIES: [
      {
        CITY: "ABU DHABI",
      },
      {
        CITY: "AL AIN",
      },
      {
        CITY: "AL KHAN",
      },
      {
        CITY: "AR RUWAYS",
      },
      {
        CITY: "AS SATWAH",
      },
      {
        CITY: "DAYRAH",
      },
      {
        CITY: "DUBAI",
      },
      {
        CITY: "FUJAIRAH",
      },
      {
        CITY: "RAS AL-KHAIMAH",
      },
      {
        CITY: "SHARJAH",
      },
    ],
  },
  {
    COUNTRY: "TANZANIA",
    CODE: "TZ",
    CITIES: [
      {
        CITY: "ARUSHA",
      },
      {
        CITY: "BUKOBA",
      },
      {
        CITY: "DAR ES SALAAM",
      },
      {
        CITY: "DODOMA",
      },
      {
        CITY: "MOROGORO",
      },
      {
        CITY: "MWANZA",
      },
      {
        CITY: "NJOMBE",
      },
      {
        CITY: "RAHA",
      },
      {
        CITY: "TANGA",
      },
      {
        CITY: "ZANZIBAR",
      },
    ],
  },
  {
    COUNTRY: "THAILAND",
    CODE: "TH",
    CITIES: [
      {
        CITY: "AMNAT CHAROEN",
      },
      {
        CITY: "AMPHAWA",
      },
      {
        CITY: "AMPHOE ARANYAPRATHET",
      },
      {
        CITY: "ANG THONG",
      },
      {
        CITY: "AYUTTHAYA",
      },
      {
        CITY: "BAN ANG THONG",
      },
      {
        CITY: "BAN BANG PHLI NAKHON",
      },
      {
        CITY: "BAN BANG PLONG",
      },
      {
        CITY: "BAN BUENG",
      },
      {
        CITY: "BAN DON SAK",
      },
      {
        CITY: "BAN HUA THALE",
      },
      {
        CITY: "BAN KHLONG NGAE",
      },
      {
        CITY: "BAN KHLONG PRASONG",
      },
      {
        CITY: "BAN KHLONG PRAWET",
      },
      {
        CITY: "BAN KHLONG TAKHIAN",
      },
      {
        CITY: "BAN KO PAO",
      },
      {
        CITY: "BAN NA",
      },
      {
        CITY: "BAN NAM HAK",
      },
      {
        CITY: "BAN PAT MON",
      },
      {
        CITY: "BAN PHICHIT",
      },
      {
        CITY: "BAN PHLAM",
      },
      {
        CITY: "BAN PONG LANG",
      },
      {
        CITY: "BAN PRATUNAM THA KHAI",
      },
      {
        CITY: "BAN TALAT RANGSIT",
      },
      {
        CITY: "BAN THA DUEA",
      },
      {
        CITY: "BAN THA PAI",
      },
      {
        CITY: "BAN THA RUEA",
      },
      {
        CITY: "BAN THAM",
      },
      {
        CITY: "BAN WANG YAI",
      },
      {
        CITY: "BANG KAPI",
      },
      {
        CITY: "BANG KHAE",
      },
      {
        CITY: "BANG KHUN THIAN",
      },
      {
        CITY: "BANG KRUAI",
      },
      {
        CITY: "BANG LAMUNG",
      },
      {
        CITY: "BANG NA",
      },
      {
        CITY: "BANG PHLAT",
      },
      {
        CITY: "BANG SAO THONG",
      },
      {
        CITY: "BANG SUE",
      },
      {
        CITY: "BANGKOK",
      },
      {
        CITY: "BANGKOK",
      },
      {
        CITY: "BURIRAM",
      },
      {
        CITY: "CHA-AM",
      },
      {
        CITY: "CHACHOENGSAO",
      },
      {
        CITY: "CHAIYAPHUM",
      },
      {
        CITY: "CHANTHABURI",
      },
      {
        CITY: "CHATUCHAK",
      },
      {
        CITY: "CHIANG MAI",
      },
      {
        CITY: "CHIANG MUAN",
      },
      {
        CITY: "CHIANG RAI",
      },
      {
        CITY: "CHON BURI",
      },
      {
        CITY: "CHULABHORN",
      },
      {
        CITY: "CHUMPHON",
      },
      {
        CITY: "DIN DAENG",
      },
      {
        CITY: "DON MUEANG",
      },
      {
        CITY: "DUSIT",
      },
      {
        CITY: "HAT YAI",
      },
      {
        CITY: "HUA HIN",
      },
      {
        CITY: "HUAI KHWANG",
      },
      {
        CITY: "KA BANG",
      },
      {
        CITY: "KALASIN",
      },
      {
        CITY: "KAMPHAENG PHET",
      },
      {
        CITY: "KANCHANABURI",
      },
      {
        CITY: "KATHU",
      },
      {
        CITY: "KHAN NA YAO",
      },
      {
        CITY: "KHLONG LUANG",
      },
      {
        CITY: "KHLONG SAN",
      },
      {
        CITY: "KHLONG TOEI",
      },
      {
        CITY: "KHLUNG",
      },
      {
        CITY: "KHON KAEN",
      },
      {
        CITY: "KLAENG",
      },
      {
        CITY: "KOSUM PHISAI",
      },
      {
        CITY: "KRABI",
      },
      {
        CITY: "KRATHUM BAEN",
      },
      {
        CITY: "KUMPHAWAPI",
      },
      {
        CITY: "LAMPANG",
      },
      {
        CITY: "LAMPHUN",
      },
      {
        CITY: "LAT KRABANG",
      },
      {
        CITY: "LOEI",
      },
      {
        CITY: "LONG",
      },
      {
        CITY: "LOPBURI",
      },
      {
        CITY: "MAE HONG SON",
      },
      {
        CITY: "MAE SOT",
      },
      {
        CITY: "MAHA SARAKHAM",
      },
      {
        CITY: "MAKKASAN",
      },
      {
        CITY: "MANOROM",
      },
      {
        CITY: "MIN BURI",
      },
      {
        CITY: "MUAK LEK",
      },
      {
        CITY: "MUEANG NONTHABURI",
      },
      {
        CITY: "MUEANG SAMUT PRAKAN",
      },
      {
        CITY: "MUKDAHAN",
      },
      {
        CITY: "NAKHON PATHOM",
      },
      {
        CITY: "NAKHON RATCHASIMA",
      },
      {
        CITY: "NAKHON SAWAN",
      },
      {
        CITY: "NAKHON SI THAMMARAT",
      },
      {
        CITY: "NAN",
      },
      {
        CITY: "NARATHIWAT",
      },
      {
        CITY: "NEW SUKHOTHAI",
      },
      {
        CITY: "NON THAI",
      },
      {
        CITY: "NONG BUA",
      },
      {
        CITY: "NONG BUA LAMPHU",
      },
      {
        CITY: "NONG KHAI",
      },
      {
        CITY: "NONG PRUE",
      },
      {
        CITY: "PAK KRET",
      },
      {
        CITY: "PATHUM THANI",
      },
      {
        CITY: "PATHUM WAN",
      },
      {
        CITY: "PATTANI",
      },
      {
        CITY: "PATTAYA",
      },
      {
        CITY: "PHACHI",
      },
      {
        CITY: "PHAN",
      },
      {
        CITY: "PHANG KHON",
      },
      {
        CITY: "PHANG NGA",
      },
      {
        CITY: "PHANNA NIKHOM",
      },
      {
        CITY: "PHATTHALUNG",
      },
      {
        CITY: "PHAYA THAI",
      },
      {
        CITY: "PHAYAO",
      },
      {
        CITY: "PHETCHABUN",
      },
      {
        CITY: "PHETCHABURI",
      },
      {
        CITY: "PHICHIT",
      },
      {
        CITY: "PHITSANULOK",
      },
      {
        CITY: "PHOTHARAM",
      },
      {
        CITY: "PHRAE",
      },
      {
        CITY: "PHUKET",
      },
      {
        CITY: "PRACHIN BURI",
      },
      {
        CITY: "PRACHUAP KHIRI KHAN",
      },
      {
        CITY: "PRAKANONG",
      },
      {
        CITY: "RANONG",
      },
      {
        CITY: "RATCHABURI",
      },
      {
        CITY: "RAWAI",
      },
      {
        CITY: "RAYONG",
      },
      {
        CITY: "ROI ET",
      },
      {
        CITY: "SA KAEO",
      },
      {
        CITY: "SAKON NAKHON",
      },
      {
        CITY: "SALAYA",
      },
      {
        CITY: "SAM KHOK",
      },
      {
        CITY: "SAM SEN",
      },
      {
        CITY: "SAMPHANTHAWONG",
      },
      {
        CITY: "SAMUT SAKHON",
      },
      {
        CITY: "SAMUT SONGKHRAM",
      },
      {
        CITY: "SAN SAI",
      },
      {
        CITY: "SATHON",
      },
      {
        CITY: "SATTAHIP",
      },
      {
        CITY: "SATUN",
      },
      {
        CITY: "SENA",
      },
      {
        CITY: "SI RACHA",
      },
      {
        CITY: "SI SA KET",
      },
      {
        CITY: "SING BURI",
      },
      {
        CITY: "SONGKHLA",
      },
      {
        CITY: "SUAN LUANG",
      },
      {
        CITY: "SUNG NOEN",
      },
      {
        CITY: "SUPHAN BURI",
      },
      {
        CITY: "SURIN",
      },
      {
        CITY: "TAK",
      },
      {
        CITY: "THALANG",
      },
      {
        CITY: "THANYABURI",
      },
      {
        CITY: "THON BURI",
      },
      {
        CITY: "TRANG",
      },
      {
        CITY: "TRAT",
      },
      {
        CITY: "UBON RATCHATHANI",
      },
      {
        CITY: "UDON THANI",
      },
      {
        CITY: "UTHAI THANI",
      },
      {
        CITY: "UTTARADIT",
      },
      {
        CITY: "WANG THONGLANG",
      },
      {
        CITY: "WATTHANA",
      },
      {
        CITY: "WIPHAWADI",
      },
      {
        CITY: "YALA",
      },
      {
        CITY: "YASOTHON",
      },
    ],
  },
  {
    COUNTRY: "TUNISIA",
    CODE: "TN",
    CITIES: [
      {
        CITY: "ARIANA",
      },
      {
        CITY: "BEJA",
      },
      {
        CITY: "GAFSA",
      },
      {
        CITY: "HAMMAMET",
      },
      {
        CITY: "LE BARDO",
      },
      {
        CITY: "MANOUBA",
      },
      {
        CITY: "MONASTIR",
      },
      {
        CITY: "RADES",
      },
      {
        CITY: "SFAX",
      },
      {
        CITY: "SIDI BOUZID",
      },
      {
        CITY: "SOUSSE",
      },
      {
        CITY: "TUNIS",
      },
    ],
  },
  {
    COUNTRY: "TAIWAN",
    CODE: "PROVINCE OF CHINA",
    CITIES: [
      {
        CITY: "BUDAI",
      },
      {
        CITY: "CAOGANG",
      },
      {
        CITY: "CHANG-HUA",
      },
      {
        CITY: "CHECHENG",
      },
      {
        CITY: "CHIAYI",
      },
      {
        CITY: "DAHU",
      },
      {
        CITY: "DOULIU",
      },
      {
        CITY: "ERLIN",
      },
      {
        CITY: "FANLU",
      },
      {
        CITY: "FENGSHAN",
      },
      {
        CITY: "GANGSHAN",
      },
      {
        CITY: "GAOZHONGYICUN",
      },
      {
        CITY: "HEMEI",
      },
      {
        CITY: "HENGCHUN",
      },
      {
        CITY: "HSINCHU",
      },
      {
        CITY: "HUALIAN",
      },
      {
        CITY: "JINCHENG",
      },
      {
        CITY: "KAO-SUNG",
      },
      {
        CITY: "KAOHSIUNG CITY",
      },
      {
        CITY: "KEELUNG",
      },
      {
        CITY: "LINKOU",
      },
      {
        CITY: "LUGANG",
      },
      {
        CITY: "MA-KUNG",
      },
      {
        CITY: "MARU",
      },
      {
        CITY: "MATSU",
      },
      {
        CITY: "MEILUN",
      },
      {
        CITY: "MIAOLI",
      },
      {
        CITY: "NANHUA",
      },
      {
        CITY: "NANKAN",
      },
      {
        CITY: "NANTOU",
      },
      {
        CITY: "NEIHU",
      },
      {
        CITY: "PINGTUNG",
      },
      {
        CITY: "POZI",
      },
      {
        CITY: "PULI",
      },
      {
        CITY: "SHALUN",
      },
      {
        CITY: "SHETOU",
      },
      {
        CITY: "SHOUFENG",
      },
      {
        CITY: "SHUILIN",
      },
      {
        CITY: "SHUILIU",
      },
      {
        CITY: "SHUISHANG",
      },
      {
        CITY: "SHULIN",
      },
      {
        CITY: "SHUYI",
      },
      {
        CITY: "SUQIN",
      },
      {
        CITY: "TAICHANG",
      },
      {
        CITY: "TAICHUNG",
      },
      {
        CITY: "TAINAN",
      },
      {
        CITY: "TAIPEI",
      },
      {
        CITY: "TAIPINGDING",
      },
      {
        CITY: "TAITUNG",
      },
      {
        CITY: "TAOYUAN",
      },
      {
        CITY: "TOUCHENG",
      },
      {
        CITY: "TUCHANG",
      },
      {
        CITY: "WAN-HUA",
      },
      {
        CITY: "WANGTONGWEN",
      },
      {
        CITY: "WANLUAN",
      },
      {
        CITY: "WUTAI",
      },
      {
        CITY: "XINJI",
      },
      {
        CITY: "XINZHUANG",
      },
      {
        CITY: "YANGMEI",
      },
      {
        CITY: "YANPING",
      },
      {
        CITY: "YILAN",
      },
      {
        CITY: "YULI",
      },
      {
        CITY: "YUNLIN COUNTY",
      },
      {
        CITY: "ZHUBEI",
      },
      {
        CITY: "ZHUNAN",
      },
      {
        CITY: "ZHUQI",
      },
    ],
  },
  {
    COUNTRY: "UKRAINE",
    CODE: "UA",
    CITIES: [
      {
        CITY: "ALEKSANDRIYA",
      },
      {
        CITY: "ALEKSANDROVKA",
      },
      {
        CITY: "ALEKSEYEVO-DRUZHKOVKA",
      },
      {
        CITY: "ALUPKA",
      },
      {
        CITY: "ALUSHTA",
      },
      {
        CITY: "BABIN",
      },
      {
        CITY: "BARASHIVKA",
      },
      {
        CITY: "BARYSHIVKA",
      },
      {
        CITY: "BELAYA",
      },
      {
        CITY: "BERDYCHIV",
      },
      {
        CITY: "BEREHOVE",
      },
      {
        CITY: "BILA HORA",
      },
      {
        CITY: "BILA TSERKVA",
      },
      {
        CITY: "BOLEKHIV",
      },
      {
        CITY: "BORODYANKA",
      },
      {
        CITY: "BORTNYCHI",
      },
      {
        CITY: "BORYSLAV",
      },
      {
        CITY: "BORYSPIL",
      },
      {
        CITY: "BORYSPIL RAION",
      },
      {
        CITY: "BOYARKA",
      },
      {
        CITY: "BOZHENKA",
      },
      {
        CITY: "BRODY",
      },
      {
        CITY: "BROVARY",
      },
      {
        CITY: "BRYANKA",
      },
      {
        CITY: "BUCHA",
      },
      {
        CITY: "BUCHACH",
      },
      {
        CITY: "BUKOVA",
      },
      {
        CITY: "BUSHTYNO",
      },
      {
        CITY: "CHERKASY",
      },
      {
        CITY: "CHERNEV",
      },
      {
        CITY: "CHERNIHIV",
      },
      {
        CITY: "CHERNIVTSI",
      },
      {
        CITY: "CHERVONA HIRKA",
      },
      {
        CITY: "CHERVONOHRAD",
      },
      {
        CITY: "CHOP",
      },
      {
        CITY: "DARAKHOV",
      },
      {
        CITY: "DAVYDOV",
      },
      {
        CITY: "DESNA",
      },
      {
        CITY: "DIMITROV",
      },
      {
        CITY: "DMITROVICHI",
      },
      {
        CITY: "DNIPROPETROVSK",
      },
      {
        CITY: "DONETSK",
      },
      {
        CITY: "DROHOBYCH",
      },
      {
        CITY: "DRUZHKOVKA",
      },
      {
        CITY: "DUBNO",
      },
      {
        CITY: "DZHANKOY",
      },
      {
        CITY: "ENERGODAR",
      },
      {
        CITY: "FASTIV",
      },
      {
        CITY: "FEODOSIYA",
      },
      {
        CITY: "FONTANKA",
      },
      {
        CITY: "FRUNZE",
      },
      {
        CITY: "GERMAN",
      },
      {
        CITY: "GONCHAROV",
      },
      {
        CITY: "GORLOVKA",
      },
      {
        CITY: "GREBENYUK",
      },
      {
        CITY: "HAYSYN",
      },
      {
        CITY: "HLUKHIV",
      },
      {
        CITY: "HORLIVKA",
      },
      {
        CITY: "HORODENKA",
      },
      {
        CITY: "HOSHIV",
      },
      {
        CITY: "ILLINTSI",
      },
      {
        CITY: "IRPIN",
      },
      {
        CITY: "IVANKOV",
      },
      {
        CITY: "IVANOV",
      },
      {
        CITY: "IZMAIL",
      },
      {
        CITY: "KAKHOVKA",
      },
      {
        CITY: "KALUSH",
      },
      {
        CITY: "KALYNIVKA",
      },
      {
        CITY: "KALYNIVKA",
      },
      {
        CITY: "KANIV",
      },
      {
        CITY: "KERCH",
      },
      {
        CITY: "KHARKIV",
      },
      {
        CITY: "KHERSON",
      },
      {
        CITY: "KHUST",
      },
      {
        CITY: "KHYZHA",
      },
      {
        CITY: "KIEV",
      },
      {
        CITY: "KIROVOHRAD",
      },
      {
        CITY: "KIROVSK",
      },
      {
        CITY: "KNYAZHYCHI",
      },
      {
        CITY: "KOLOMYIA",
      },
      {
        CITY: "KOMSOMOLSK",
      },
      {
        CITY: "KONOTOP",
      },
      {
        CITY: "KOROSTEN",
      },
      {
        CITY: "KOROSTYSHIV",
      },
      {
        CITY: "KOVEL",
      },
      {
        CITY: "KOZYATYN",
      },
      {
        CITY: "KRAMATORSK",
      },
      {
        CITY: "KRASNODON",
      },
      {
        CITY: "KRASNOHRAD",
      },
      {
        CITY: "KREMENCHUK",
      },
      {
        CITY: "KREMENETS",
      },
      {
        CITY: "KREMINNA",
      },
      {
        CITY: "KRYVYY RIH",
      },
      {
        CITY: "KUNA",
      },
      {
        CITY: "KURAKHOVO",
      },
      {
        CITY: "KURYLIVKA",
      },
      {
        CITY: "KUZNETSOVSK",
      },
      {
        CITY: "KVASYLIV",
      },
      {
        CITY: "KYSELIV",
      },
      {
        CITY: "KYYIV",
      },
      {
        CITY: "LANOVKA",
      },
      {
        CITY: "LENINA",
      },
      {
        CITY: "LETYCHIV",
      },
      {
        CITY: "LISICHANSK",
      },
      {
        CITY: "LITYN",
      },
      {
        CITY: "LOHVYNOVE",
      },
      {
        CITY: "LUBNY",
      },
      {
        CITY: "LUGANSK",
      },
      {
        CITY: "LUGANSKOYE",
      },
      {
        CITY: "LUHANSK",
      },
      {
        CITY: "LUTSK",
      },
      {
        CITY: "LUTUGINO",
      },
      {
        CITY: "LVIV",
      },
      {
        CITY: "MAKARIV",
      },
      {
        CITY: "MAKIYIVKA",
      },
      {
        CITY: "MAKSIM",
      },
      {
        CITY: "MALA TOKMACHKA",
      },
      {
        CITY: "MALYN",
      },
      {
        CITY: "MARIUPOL",
      },
      {
        CITY: "MARKOVA",
      },
      {
        CITY: "MEGA",
      },
      {
        CITY: "MELITOPOL",
      },
      {
        CITY: "MEREFA",
      },
      {
        CITY: "MONASTYRYSHCHE",
      },
      {
        CITY: "MUKACHEVE",
      },
      {
        CITY: "MYKOLAYIV",
      },
      {
        CITY: "MYKOLAYIV",
      },
      {
        CITY: "MYLOVE",
      },
      {
        CITY: "NADEZHDA",
      },
      {
        CITY: "NETISHYN",
      },
      {
        CITY: "NIZHYN",
      },
      {
        CITY: "NOSIVKA",
      },
      {
        CITY: "NOVI SANZHARY",
      },
      {
        CITY: "NOVOMOSKOVSK",
      },
      {
        CITY: "NOVYY BUH",
      },
      {
        CITY: "OBUKHOV",
      },
      {
        CITY: "ODESA",
      },
      {
        CITY: "OLEH",
      },
      {
        CITY: "OREANDA",
      },
      {
        CITY: "OSNOVA",
      },
      {
        CITY: "OSYPENKO",
      },
      {
        CITY: "PAVLOGRAD",
      },
      {
        CITY: "PAVLOHRAD",
      },
      {
        CITY: "PETRANKA",
      },
      {
        CITY: "PETRENKY",
      },
      {
        CITY: "POBEDA",
      },
      {
        CITY: "PODOL",
      },
      {
        CITY: "POLTAVA",
      },
      {
        CITY: "POPLAVY",
      },
      {
        CITY: "PYROHIV",
      },
      {
        CITY: "RAYHORODKA",
      },
      {
        CITY: "RIVNE",
      },
      {
        CITY: "RIVNE",
      },
      {
        CITY: "RODINSKOYE",
      },
      {
        CITY: "ROMANIV",
      },
      {
        CITY: "ROMNY",
      },
      {
        CITY: "ROVENKI",
      },
      {
        CITY: "RUBIZHNE",
      },
      {
        CITY: "SAKY",
      },
      {
        CITY: "SELIDOVO",
      },
      {
        CITY: "SEMEN",
      },
      {
        CITY: "SENCHA",
      },
      {
        CITY: "SERHIYI",
      },
      {
        CITY: "SEVASTOPOL",
      },
      {
        CITY: "SHEPETIVKA",
      },
      {
        CITY: "SHEVCHENKA",
      },
      {
        CITY: "SHEVCHENKO",
      },
      {
        CITY: "SHOSTKA",
      },
      {
        CITY: "SHPOLA",
      },
      {
        CITY: "SIMFEROPOL",
      },
      {
        CITY: "SLAVUTA",
      },
      {
        CITY: "SLOVIANSK",
      },
      {
        CITY: "SLYNKIVSHCHYNA",
      },
      {
        CITY: "SMILA",
      },
      {
        CITY: "SOKOL",
      },
      {
        CITY: "SOKOLIV",
      },
      {
        CITY: "SOROKA",
      },
      {
        CITY: "SOROKI",
      },
      {
        CITY: "SOROTSKOYE",
      },
      {
        CITY: "SPAS",
      },
      {
        CITY: "STAKHANOV",
      },
      {
        CITY: "STANISLAV",
      },
      {
        CITY: "STAT",
      },
      {
        CITY: "STRYI",
      },
      {
        CITY: "SUDAK",
      },
      {
        CITY: "SUMY",
      },
      {
        CITY: "SVALYAVA",
      },
      {
        CITY: "SVESSA",
      },
      {
        CITY: "TEPLODAR",
      },
      {
        CITY: "TEREMKY",
      },
      {
        CITY: "TERNOPIL",
      },
      {
        CITY: "TERNOVKA",
      },
      {
        CITY: "TKACHENKA",
      },
      {
        CITY: "TOKMAK",
      },
      {
        CITY: "TOPOROV",
      },
      {
        CITY: "TOREZ",
      },
      {
        CITY: "TRYPILLIA",
      },
      {
        CITY: "TSVETKOV",
      },
      {
        CITY: "TSYURUPINSK",
      },
      {
        CITY: "TURKA",
      },
      {
        CITY: "TYACHIV",
      },
      {
        CITY: "UGLEDAR",
      },
      {
        CITY: "UKRAYINKA",
      },
      {
        CITY: "UZHHOROD",
      },
      {
        CITY: "VASYLKIV",
      },
      {
        CITY: "VINNYTSYA",
      },
      {
        CITY: "VOLKOV",
      },
      {
        CITY: "VOLNOVAKHA",
      },
      {
        CITY: "VORONOVO",
      },
      {
        CITY: "VOROVSKIY",
      },
      {
        CITY: "VOZNESENKA",
      },
      {
        CITY: "VYSHHOROD",
      },
      {
        CITY: "VYSHNEVE",
      },
      {
        CITY: "YALTA",
      },
      {
        CITY: "YASINOVATAYA",
      },
      {
        CITY: "YENAKIYEVE",
      },
      {
        CITY: "ZABOLOTIV",
      },
      {
        CITY: "ZAPORIZHIA",
      },
      {
        CITY: "ZAPOROZHE",
      },
      {
        CITY: "ZASTAVA",
      },
      {
        CITY: "ZAVOD",
      },
      {
        CITY: "ZDOLBUNIV",
      },
      {
        CITY: "ZHASHKIV",
      },
      {
        CITY: "ZHDANOVKA",
      },
      {
        CITY: "ZHMERYNKA",
      },
      {
        CITY: "ZHYTOMYR",
      },
      {
        CITY: "ZLAZNE",
      },
      {
        CITY: "ZMIYIV",
      },
      {
        CITY: "ZOLOCHIV",
      },
      {
        CITY: "ZOLOTONOSHA",
      },
    ],
  },
  {
    COUNTRY: "U.S. VIRGIN ISLANDS",
    CODE: "VG",
    CITIES: [
      {
        CITY: "CHARLOTTE AMALIE",
      },
      {
        CITY: "CHRISTIANSTED",
      },
      {
        CITY: "FREDERIKSTED",
      },
      {
        CITY: "KINGSHILL",
      },
      {
        CITY: "ST JOHN ISLAND",
      },
    ],
  },
  {
    COUNTRY: "ZIMBABWE",
    CODE: "ZW",
    CITIES: [
      {
        CITY: "BULAWAYO",
      },
      {
        CITY: "CHINHOYI",
      },
      {
        CITY: "GREENDALE",
      },
      {
        CITY: "GWANDA",
      },
      {
        CITY: "HARARE",
      },
      {
        CITY: "KWEKWE",
      },
      {
        CITY: "MUFAKOSE",
      },
      {
        CITY: "MUTARE",
      },
      {
        CITY: "VICTORIA FALLS",
      },
    ],
  },
  {
    COUNTRY: "VIETNAM",
    CODE: "VN",
    CITIES: [
      {
        CITY: "AN DINH",
      },
      {
        CITY: "AN GIANG",
      },
      {
        CITY: "AN NHON",
      },
      {
        CITY: "AN TAM",
      },
      {
        CITY: "AN THANH",
      },
      {
        CITY: "AP SAI GON",
      },
      {
        CITY: "AP TRUNG",
      },
      {
        CITY: "BAC GIANG",
      },
      {
        CITY: "BAC KAN",
      },
      {
        CITY: "BAC NINH",
      },
      {
        CITY: "BACH MA",
      },
      {
        CITY: "BANG",
      },
      {
        CITY: "BEN NGHE",
      },
      {
        CITY: "BEN THAN",
      },
      {
        CITY: "BIEN HOA",
      },
      {
        CITY: "BINH PHUOC",
      },
      {
        CITY: "BINH THANH",
      },
      {
        CITY: "BINH THOAI",
      },
      {
        CITY: "BUON MA THUOT",
      },
      {
        CITY: "CA MAU",
      },
      {
        CITY: "CAM PHA",
      },
      {
        CITY: "CAN THO",
      },
      {
        CITY: "CHO HA",
      },
      {
        CITY: "CHUONG DUONG",
      },
      {
        CITY: "CO GIANG",
      },
      {
        CITY: "DA LAT",
      },
      {
        CITY: "DA NANG",
      },
      {
        CITY: "DAC LOC",
      },
      {
        CITY: "DI AN",
      },
      {
        CITY: "DIEN BIEN PHU",
      },
      {
        CITY: "DINH LONG",
      },
      {
        CITY: "DONG",
      },
      {
        CITY: "DONG NAI",
      },
      {
        CITY: "DONG THAP",
      },
      {
        CITY: "DUC THANG",
      },
      {
        CITY: "GIONG NGAI",
      },
      {
        CITY: "GIONG TRUONG",
      },
      {
        CITY: "GO VAP",
      },
      {
        CITY: "HA DONG",
      },
      {
        CITY: "HA LONG",
      },
      {
        CITY: "HA NAM",
      },
      {
        CITY: "HA TINH",
      },
      {
        CITY: "HAIPHONG",
      },
      {
        CITY: "HANOI",
      },
      {
        CITY: "HO CHI MINH CITY",
      },
      {
        CITY: "HOANG MAI",
      },
      {
        CITY: "HUNG YEN",
      },
      {
        CITY: "HUONG TINH",
      },
      {
        CITY: "KHANH HOA",
      },
      {
        CITY: "KON TUM",
      },
      {
        CITY: "LANG DUNG",
      },
      {
        CITY: "LANG HONG",
      },
      {
        CITY: "LANG SON",
      },
      {
        CITY: "LAO CAI",
      },
      {
        CITY: "LE LOI",
      },
      {
        CITY: "LONG AN",
      },
      {
        CITY: "LONG TAN",
      },
      {
        CITY: "LONG VAN",
      },
      {
        CITY: "LUONG HOI",
      },
      {
        CITY: "MINH KHAI",
      },
      {
        CITY: "NGHI LOC",
      },
      {
        CITY: "NHA BE",
      },
      {
        CITY: "NHA TRANG",
      },
      {
        CITY: "PHAN THON",
      },
      {
        CITY: "PHU NHUAN",
      },
      {
        CITY: "PHU THO",
      },
      {
        CITY: "PHU XUYEN",
      },
      {
        CITY: "PHU YEN",
      },
      {
        CITY: "PHUONG TAN",
      },
      {
        CITY: "PLEIKU",
      },
      {
        CITY: "QUAN NGOC",
      },
      {
        CITY: "QUAN TRANG",
      },
      {
        CITY: "QUANG NAM",
      },
      {
        CITY: "QUANG TRI",
      },
      {
        CITY: "QUANG TRUNG",
      },
      {
        CITY: "QUI NHON",
      },
      {
        CITY: "SOC TRANG",
      },
      {
        CITY: "TAM KY",
      },
      {
        CITY: "TAN BINH",
      },
      {
        CITY: "TAN THUAN DONG",
      },
      {
        CITY: "TANG",
      },
      {
        CITY: "TAY NINH",
      },
      {
        CITY: "THACH THAT",
      },
      {
        CITY: "THANH NGUYEN",
      },
      {
        CITY: "THANH PHO HA GIANG",
      },
      {
        CITY: "THANH PHO HAI DUONG",
      },
      {
        CITY: "THANH PHO HOA BINH",
      },
      {
        CITY: "THANH PHO NINH BINH",
      },
      {
        CITY: "THANH PHO THAI BINH",
      },
      {
        CITY: "THANH PHO THAI NGUYEN",
      },
      {
        CITY: "THANH PHO TUYEN QUANG",
      },
      {
        CITY: "THON BAO AN",
      },
      {
        CITY: "THONG NHAT",
      },
      {
        CITY: "THU DAU MOT",
      },
      {
        CITY: "THU DUC",
      },
      {
        CITY: "THUAN AN",
      },
      {
        CITY: "THUAN CONG",
      },
      {
        CITY: "TRA VINH",
      },
      {
        CITY: "TRAO",
      },
      {
        CITY: "TRUONG LAM",
      },
      {
        CITY: "TRUONG SON",
      },
      {
        CITY: "VAN HAI",
      },
      {
        CITY: "VAN TAN",
      },
      {
        CITY: "VI THANH",
      },
      {
        CITY: "VINH",
      },
      {
        CITY: "VINH LONG",
      },
      {
        CITY: "VINH YEN",
      },
      {
        CITY: "XOM PHUOC MY",
      },
      {
        CITY: "XOM THANG",
      },
      {
        CITY: "XOM TRUONG DINH",
      },
      {
        CITY: "YEN BAI",
      },
      {
        CITY: "YEN THANH",
      },
    ],
  },
  {
    COUNTRY: "ZAMBIA",
    CODE: "ZM",
    CITIES: [
      {
        CITY: "KALOMO",
      },
      {
        CITY: "KITWE",
      },
      {
        CITY: "LIVINGSTONE",
      },
      {
        CITY: "LUSAKA",
      },
      {
        CITY: "MACHA",
      },
      {
        CITY: "MUMBWA",
      },
      {
        CITY: "NDOLA",
      },
      {
        CITY: "SIAVONGA",
      },
      {
        CITY: "SOLWEZI",
      },
    ],
  },
  {
    COUNTRY: "URUGUAY",
    CODE: "UY",
    CITIES: [
      {
        CITY: "BARRA DE CARRASCO",
      },
      {
        CITY: "CANELONES",
      },
      {
        CITY: "COLONIA DEL SACRAMENTO",
      },
      {
        CITY: "DURAZNO",
      },
      {
        CITY: "FLORIDA",
      },
      {
        CITY: "LA FLORESTA",
      },
      {
        CITY: "LA PAZ",
      },
      {
        CITY: "LAS PIEDRAS",
      },
      {
        CITY: "MALDONADO",
      },
      {
        CITY: "MERCEDES",
      },
      {
        CITY: "MONTEVIDEO",
      },
      {
        CITY: "PUNTA DEL ESTE",
      },
      {
        CITY: "SALTO",
      },
      {
        CITY: "SAN CARLOS",
      },
      {
        CITY: "TOLEDO",
      },
      {
        CITY: "UNION",
      },
    ],
  },
  {
    COUNTRY: "VENEZUELA",
    CODE: "VE",
    CITIES: [
      {
        CITY: "ACARIGUA",
      },
      {
        CITY: "ANACO",
      },
      {
        CITY: "ARAURE",
      },
      {
        CITY: "BACHAQUERO",
      },
      {
        CITY: "BARCELONA",
      },
      {
        CITY: "BARINAS",
      },
      {
        CITY: "BARQUISIMETO",
      },
      {
        CITY: "BEJUMA",
      },
      {
        CITY: "BOLIVAR",
      },
      {
        CITY: "CABIMAS",
      },
      {
        CITY: "CABUDARE",
      },
      {
        CITY: "CAGUA",
      },
      {
        CITY: "CAJA DE AGUA",
      },
      {
        CITY: "CALABOZO",
      },
      {
        CITY: "CAMPO DE CARABOBO",
      },
      {
        CITY: "CARABALLEDA",
      },
      {
        CITY: "CARACAS",
      },
      {
        CITY: "CARIPE",
      },
      {
        CITY: "CARRIZAL",
      },
      {
        CITY: "CATIA LA MAR",
      },
      {
        CITY: "CHARALLAVE",
      },
      {
        CITY: "CHIVACOA",
      },
      {
        CITY: "CIUDAD GUAYANA",
      },
      {
        CITY: "CIUDAD OJEDA",
      },
      {
        CITY: "CIUDAD PIAR",
      },
      {
        CITY: "COLONIA TOVAR",
      },
      {
        CITY: "CORO",
      },
      {
        CITY: "CUA",
      },
      {
        CITY: "CURAZAITO",
      },
      {
        CITY: "EL CHORRO",
      },
      {
        CITY: "EL RECREO",
      },
      {
        CITY: "EL ROQUE",
      },
      {
        CITY: "EL ROSAL",
      },
      {
        CITY: "EL TIGRE",
      },
      {
        CITY: "EL VIGIA",
      },
      {
        CITY: "GUACHARA",
      },
      {
        CITY: "GUANARE",
      },
      {
        CITY: "GUARENAS",
      },
      {
        CITY: "GUATIRE",
      },
      {
        CITY: "GUAYANA",
      },
      {
        CITY: "GUEIRIA",
      },
      {
        CITY: "LA CASTELLANA",
      },
      {
        CITY: "LA CRUZ",
      },
      {
        CITY: "LA GUAIRA",
      },
      {
        CITY: "LA URBINA",
      },
      {
        CITY: "LA VERDAD",
      },
      {
        CITY: "LA VICTORIA",
      },
      {
        CITY: "LA VILLA DEL ROSARIO",
      },
      {
        CITY: "LONDRES",
      },
      {
        CITY: "LOS CHORROS",
      },
      {
        CITY: "LOS TEQUES",
      },
      {
        CITY: "MACUTO",
      },
      {
        CITY: "MAIQUETIA",
      },
      {
        CITY: "MARACAIBO",
      },
      {
        CITY: "MARACAY",
      },
      {
        CITY: "MARIA",
      },
      {
        CITY: "MARIGUITAR",
      },
      {
        CITY: "MATANZAS",
      },
      {
        CITY: "MIRANDA",
      },
      {
        CITY: "MONTE CRISTO",
      },
      {
        CITY: "NAGUANAGUA",
      },
      {
        CITY: "OCUMARE",
      },
      {
        CITY: "PAMPATAR",
      },
      {
        CITY: "PORLAMAR",
      },
      {
        CITY: "PUERTO CABELLO",
      },
      {
        CITY: "PUERTO LA CRUZ",
      },
      {
        CITY: "PUERTO ORDAZ AND SAN FELIX",
      },
      {
        CITY: "PUNTO FIJO",
      },
      {
        CITY: "SABANETA",
      },
      {
        CITY: "SAN ANTONIO",
      },
      {
        CITY: "SAN ANTONIO DE LOS ALTOS",
      },
      {
        CITY: "SAN DIEGO",
      },
      {
        CITY: "SAN FELIPE",
      },
      {
        CITY: "SAN FELIX",
      },
      {
        CITY: "SAN FRANCISCO",
      },
      {
        CITY: "SAN JOAQUIN",
      },
      {
        CITY: "SAN JOSE DE GUANIPA",
      },
      {
        CITY: "SANTA CRUZ DEL ZULIA",
      },
      {
        CITY: "SANTA FE",
      },
      {
        CITY: "SANTA TERESA",
      },
      {
        CITY: "SOLANO",
      },
      {
        CITY: "TARIBA",
      },
      {
        CITY: "TORRE",
      },
      {
        CITY: "TRUJILLO",
      },
      {
        CITY: "TUCUPITA",
      },
      {
        CITY: "TURMERO",
      },
      {
        CITY: "VALENCIA",
      },
      {
        CITY: "VALERA",
      },
      {
        CITY: "VALLE",
      },
      {
        CITY: "VARGAS",
      },
    ],
  },
  {
    COUNTRY: "UNITED STATES",
    CODE: "US",
    CITIES: [
      {
        CITY: "MARYSVILLE",
      },
      {
        CITY: "PERRIS",
      },
      {
        CITY: "CLEVELAND",
      },
      {
        CITY: "WORCESTER",
      },
      {
        CITY: "COLUMBIA",
      },
      {
        CITY: "WATERBURY",
      },
      {
        CITY: "EAGAN",
      },
      {
        CITY: "SOUTHFIELD",
      },
      {
        CITY: "LAFAYETTE",
      },
      {
        CITY: "BOISE CITY",
      },
      {
        CITY: "ELKHART",
      },
      {
        CITY: "BUENA PARK",
      },
      {
        CITY: "MARIETTA",
      },
      {
        CITY: "PARKER",
      },
      {
        CITY: "WEST PALM BEACH",
      },
      {
        CITY: "SALEM",
      },
      {
        CITY: "AURORA",
      },
      {
        CITY: "LEESBURG",
      },
      {
        CITY: "DORAL",
      },
      {
        CITY: "WESTMINSTER",
      },
      {
        CITY: "LUBBOCK",
      },
      {
        CITY: "OVERLAND PARK",
      },
      {
        CITY: "JACKSON",
      },
      {
        CITY: "GASTONIA",
      },
      {
        CITY: "DAYTONA BEACH",
      },
      {
        CITY: "KANSAS CITY",
      },
      {
        CITY: "RANCHO PALOS VERDES",
      },
      {
        CITY: "BURNSVILLE",
      },
      {
        CITY: "ROWLETT",
      },
      {
        CITY: "ROSWELL",
      },
      {
        CITY: "MONTGOMERY",
      },
      {
        CITY: "LOGAN",
      },
      {
        CITY: "WOBURN",
      },
      {
        CITY: "ALLENTOWN",
      },
      {
        CITY: "MOUNT PLEASANT",
      },
      {
        CITY: "COLUMBUS",
      },
      {
        CITY: "BUFFALO",
      },
      {
        CITY: "NEW BERLIN",
      },
      {
        CITY: "NORTH RICHLAND HILLS",
      },
      {
        CITY: "LIMA",
      },
      {
        CITY: "SACRAMENTO",
      },
      {
        CITY: "FORT SMITH",
      },
      {
        CITY: "EAST PROVIDENCE",
      },
      {
        CITY: "URBANDALE",
      },
      {
        CITY: "MOUNT VERNON",
      },
      {
        CITY: "LAKEWOOD",
      },
      {
        CITY: "HENDERSON",
      },
      {
        CITY: "COVINA",
      },
      {
        CITY: "STAMFORD",
      },
      {
        CITY: "BLOOMINGTON",
      },
      {
        CITY: "KINGSPORT",
      },
      {
        CITY: "SPOKANE",
      },
      {
        CITY: "KENNEWICK",
      },
      {
        CITY: "PHILADELPHIA",
      },
      {
        CITY: "BELLINGHAM",
      },
      {
        CITY: "VISALIA",
      },
      {
        CITY: "LA CROSSE",
      },
      {
        CITY: "COUNCIL BLUFFS",
      },
      {
        CITY: "NORTH LAUDERDALE",
      },
      {
        CITY: "DALLAS",
      },
      {
        CITY: "ANTIOCH",
      },
      {
        CITY: "STERLING HEIGHTS",
      },
      {
        CITY: "DEERFIELD BEACH",
      },
      {
        CITY: "FISHERS",
      },
      {
        CITY: "CHEYENNE",
      },
      {
        CITY: "MURRIETA",
      },
      {
        CITY: "LENEXA",
      },
      {
        CITY: "NOBLESVILLE",
      },
      {
        CITY: "SOUTHAVEN",
      },
      {
        CITY: "CHINO",
      },
      {
        CITY: "GAITHERSBURG",
      },
      {
        CITY: "FOUNTAIN VALLEY",
      },
      {
        CITY: "FREDERICK",
      },
      {
        CITY: "GREENVILLE",
      },
      {
        CITY: "DEKALB",
      },
      {
        CITY: "WEST ALLIS",
      },
      {
        CITY: "THORNTON",
      },
      {
        CITY: "WEST DES MOINES",
      },
      {
        CITY: "FALL RIVER",
      },
      {
        CITY: "TULARE",
      },
      {
        CITY: "CHULA VISTA",
      },
      {
        CITY: "CORAL SPRINGS",
      },
      {
        CITY: "CONCORD",
      },
      {
        CITY: "SMYRNA",
      },
      {
        CITY: "APOPKA",
      },
      {
        CITY: "GRAND FORKS",
      },
      {
        CITY: "LANCASTER",
      },
      {
        CITY: "NAPERVILLE",
      },
      {
        CITY: "HOLLYWOOD",
      },
      {
        CITY: "ROGERS",
      },
      {
        CITY: "NEW ORLEANS",
      },
      {
        CITY: "LAKEWOOD",
      },
      {
        CITY: "OAK LAWN",
      },
      {
        CITY: "CALDWELL",
      },
      {
        CITY: "PORTLAND",
      },
      {
        CITY: "CONCORD",
      },
      {
        CITY: "BURIEN",
      },
      {
        CITY: "ST. CHARLES",
      },
      {
        CITY: "WAUKESHA",
      },
      {
        CITY: "FLAGSTAFF",
      },
      {
        CITY: "PUYALLUP",
      },
      {
        CITY: "SCHENECTADY",
      },
      {
        CITY: "DENVER",
      },
      {
        CITY: "SPRINGFIELD",
      },
      {
        CITY: "CAMPBELL",
      },
      {
        CITY: "MORENO VALLEY",
      },
      {
        CITY: "KETTERING",
      },
      {
        CITY: "LINCOLN PARK",
      },
      {
        CITY: "LAKE HAVASU CITY",
      },
      {
        CITY: "NORTH LITTLE ROCK",
      },
      {
        CITY: "RENO",
      },
      {
        CITY: "LITTLETON",
      },
      {
        CITY: "PRESCOTT",
      },
      {
        CITY: "DUBLIN",
      },
      {
        CITY: "TEXARKANA",
      },
      {
        CITY: "PORTLAND",
      },
      {
        CITY: "BROOKLYN PARK",
      },
      {
        CITY: "WEST HAVEN",
      },
      {
        CITY: "EL CENTRO",
      },
      {
        CITY: "THE COLONY",
      },
      {
        CITY: "FLINT",
      },
      {
        CITY: "PITTSBURG",
      },
      {
        CITY: "BUCKEYE",
      },
      {
        CITY: "BREA",
      },
      {
        CITY: "INDIO",
      },
      {
        CITY: "SCOTTSDALE",
      },
      {
        CITY: "COLUMBUS",
      },
      {
        CITY: "LANCASTER",
      },
      {
        CITY: "LINCOLN",
      },
      {
        CITY: "TRENTON",
      },
      {
        CITY: "BOSTON",
      },
      {
        CITY: "GLENDORA",
      },
      {
        CITY: "ROCHESTER",
      },
      {
        CITY: "FLORISSANT",
      },
      {
        CITY: "ANNAPOLIS",
      },
      {
        CITY: "CHAPEL HILL",
      },
      {
        CITY: "LAWRENCE",
      },
      {
        CITY: "CALEXICO",
      },
      {
        CITY: "CALUMET CITY",
      },
      {
        CITY: "MISSOULA",
      },
      {
        CITY: "CLOVIS",
      },
      {
        CITY: "PEARLAND",
      },
      {
        CITY: "GILBERT",
      },
      {
        CITY: "WAUSAU",
      },
      {
        CITY: "RALEIGH",
      },
      {
        CITY: "ORANGE",
      },
      {
        CITY: "WALNUT CREEK",
      },
      {
        CITY: "BREMERTON",
      },
      {
        CITY: "PORTERVILLE",
      },
      {
        CITY: "MARANA",
      },
      {
        CITY: "TALLAHASSEE",
      },
      {
        CITY: "CONROE",
      },
      {
        CITY: "BLACKSBURG",
      },
      {
        CITY: "SAN ANTONIO",
      },
      {
        CITY: "HARTFORD",
      },
      {
        CITY: "WATERLOO",
      },
      {
        CITY: "BINGHAMTON",
      },
      {
        CITY: "MERIDIAN",
      },
      {
        CITY: "FOND DU LAC",
      },
      {
        CITY: "BAYTOWN",
      },
      {
        CITY: "CARY",
      },
      {
        CITY: "WEYMOUTH TOWN",
      },
      {
        CITY: "TULSA",
      },
      {
        CITY: "MISSION",
      },
      {
        CITY: "CUYAHOGA FALLS",
      },
      {
        CITY: "NAPA",
      },
      {
        CITY: "AURORA",
      },
      {
        CITY: "DULUTH",
      },
      {
        CITY: "ROANOKE",
      },
      {
        CITY: "BEND",
      },
      {
        CITY: "APPLE VALLEY",
      },
      {
        CITY: "WALTHAM",
      },
      {
        CITY: "FORT MYERS",
      },
      {
        CITY: "TAMPA",
      },
      {
        CITY: "UPLAND",
      },
      {
        CITY: "DANVILLE",
      },
      {
        CITY: "BELOIT",
      },
      {
        CITY: "PLANTATION",
      },
      {
        CITY: "COLORADO SPRINGS",
      },
      {
        CITY: "MODESTO",
      },
      {
        CITY: "GRAND RAPIDS",
      },
      {
        CITY: "OAK PARK",
      },
      {
        CITY: "SARASOTA",
      },
      {
        CITY: "JURUPA VALLEY",
      },
      {
        CITY: "BELLFLOWER",
      },
      {
        CITY: "BOULDER",
      },
      {
        CITY: "SANTA FE",
      },
      {
        CITY: "COACHELLA",
      },
      {
        CITY: "MARLBOROUGH",
      },
      {
        CITY: "RIVERSIDE",
      },
      {
        CITY: "PALMDALE",
      },
      {
        CITY: "NOVATO",
      },
      {
        CITY: "LAYTON",
      },
      {
        CITY: "BOLINGBROOK",
      },
      {
        CITY: "MISSION VIEJO",
      },
      {
        CITY: "GLENVIEW",
      },
      {
        CITY: "LINCOLN",
      },
      {
        CITY: "MALDEN",
      },
      {
        CITY: "ALLEN",
      },
      {
        CITY: "TUCSON",
      },
      {
        CITY: "FRANKLIN",
      },
      {
        CITY: "SHAKOPEE",
      },
      {
        CITY: "JACKSONVILLE",
      },
      {
        CITY: "ATLANTIC CITY",
      },
      {
        CITY: "ERIE",
      },
      {
        CITY: "MERCED",
      },
      {
        CITY: "ABILENE",
      },
      {
        CITY: "YORBA LINDA",
      },
      {
        CITY: "GREENVILLE",
      },
      {
        CITY: "HUNTERSVILLE",
      },
      {
        CITY: "ST. CLAIR SHORES",
      },
      {
        CITY: "HARRISBURG",
      },
      {
        CITY: "SAN BUENAVENTURA (VENTURA)",
      },
      {
        CITY: "FREMONT",
      },
      {
        CITY: "PUEBLO",
      },
      {
        CITY: "CHELSEA",
      },
      {
        CITY: "BELLEVILLE",
      },
      {
        CITY: "MINNEAPOLIS",
      },
      {
        CITY: "BARTLETT",
      },
      {
        CITY: "WICHITA FALLS",
      },
      {
        CITY: "MISSOURI CITY",
      },
      {
        CITY: "ST. LOUIS",
      },
      {
        CITY: "PHARR",
      },
      {
        CITY: "ALBUQUERQUE",
      },
      {
        CITY: "ATHENS-CLARKE COUNTY",
      },
      {
        CITY: "SOUTH JORDAN",
      },
      {
        CITY: "NORTH LAS VEGAS",
      },
      {
        CITY: "POMONA",
      },
      {
        CITY: "EASTVALE",
      },
      {
        CITY: "GROVE CITY",
      },
      {
        CITY: "LAWRENCE",
      },
      {
        CITY: "MIDLAND",
      },
      {
        CITY: "OKLAHOMA CITY",
      },
      {
        CITY: "ORO VALLEY",
      },
      {
        CITY: "MESA",
      },
      {
        CITY: "NORWALK",
      },
      {
        CITY: "KENTWOOD",
      },
      {
        CITY: "BROOKFIELD",
      },
      {
        CITY: "ROCKWALL",
      },
      {
        CITY: "MISHAWAKA",
      },
      {
        CITY: "TUSTIN",
      },
      {
        CITY: "ST. CLOUD",
      },
      {
        CITY: "PASSAIC",
      },
      {
        CITY: "ESCONDIDO",
      },
      {
        CITY: "SAN BRUNO",
      },
      {
        CITY: "BERKELEY",
      },
      {
        CITY: "SAN CLEMENTE",
      },
      {
        CITY: "LAWTON",
      },
      {
        CITY: "EAU CLAIRE",
      },
      {
        CITY: "MARICOPA",
      },
      {
        CITY: "DESOTO",
      },
      {
        CITY: "FORT WAYNE",
      },
      {
        CITY: "SHORELINE",
      },
      {
        CITY: "MEDFORD",
      },
      {
        CITY: "LA HABRA",
      },
      {
        CITY: "ALBANY",
      },
      {
        CITY: "DALY CITY",
      },
      {
        CITY: "TAYLOR",
      },
      {
        CITY: "COON RAPIDS",
      },
      {
        CITY: "SAN MATEO",
      },
      {
        CITY: "ORLANDO",
      },
      {
        CITY: "CASPER",
      },
      {
        CITY: "BEDFORD",
      },
      {
        CITY: "WILMINGTON",
      },
      {
        CITY: "PINELLAS PARK",
      },
      {
        CITY: "NORTH MIAMI BEACH",
      },
      {
        CITY: "KANSAS CITY",
      },
      {
        CITY: "FITCHBURG",
      },
      {
        CITY: "WHEELING",
      },
      {
        CITY: "NEWTON",
      },
      {
        CITY: "NORTH MIAMI",
      },
      {
        CITY: "NORWICH",
      },
      {
        CITY: "NEWARK",
      },
      {
        CITY: "ALEXANDRIA",
      },
      {
        CITY: "BLAINE",
      },
      {
        CITY: "HARLINGEN",
      },
      {
        CITY: "LOMPOC",
      },
      {
        CITY: "DENTON",
      },
      {
        CITY: "GALVESTON",
      },
      {
        CITY: "GREENWOOD",
      },
      {
        CITY: "SALINAS",
      },
      {
        CITY: "MIAMI GARDENS",
      },
      {
        CITY: "HUNTINGTON",
      },
      {
        CITY: "LAREDO",
      },
      {
        CITY: "MELBOURNE",
      },
      {
        CITY: "HALTOM CITY",
      },
      {
        CITY: "PALM BAY",
      },
      {
        CITY: "WILSON",
      },
      {
        CITY: "BOUNTIFUL",
      },
      {
        CITY: "CERES",
      },
      {
        CITY: "PARMA",
      },
      {
        CITY: "RAPID CITY",
      },
      {
        CITY: "BELL GARDENS",
      },
      {
        CITY: "COMMERCE CITY",
      },
      {
        CITY: "SPRINGFIELD",
      },
      {
        CITY: "FORT COLLINS",
      },
      {
        CITY: "SAN LEANDRO",
      },
      {
        CITY: "VISTA",
      },
      {
        CITY: "YONKERS",
      },
      {
        CITY: "CINCINNATI",
      },
      {
        CITY: "HAGERSTOWN",
      },
      {
        CITY: "SANFORD",
      },
      {
        CITY: "UNION CITY",
      },
      {
        CITY: "TOLEDO",
      },
      {
        CITY: "SIERRA VISTA",
      },
      {
        CITY: "NEWPORT NEWS",
      },
      {
        CITY: "HAMMOND",
      },
      {
        CITY: "BUFFALO GROVE",
      },
      {
        CITY: "SUGAR LAND",
      },
      {
        CITY: "HUNTSVILLE",
      },
      {
        CITY: "LODI",
      },
      {
        CITY: "EDMONDS",
      },
      {
        CITY: "DUNCANVILLE",
      },
      {
        CITY: "ROCKY MOUNT",
      },
      {
        CITY: "PONTIAC",
      },
      {
        CITY: "NATIONAL CITY",
      },
      {
        CITY: "HAYWARD",
      },
      {
        CITY: "NIAGARA FALLS",
      },
      {
        CITY: "SPARKS",
      },
      {
        CITY: "HANFORD",
      },
      {
        CITY: "CYPRESS",
      },
      {
        CITY: "DECATUR",
      },
      {
        CITY: "WOONSOCKET",
      },
      {
        CITY: "MOBILE",
      },
      {
        CITY: "VALLEJO",
      },
      {
        CITY: "ARCADIA",
      },
      {
        CITY: "COLLIERVILLE",
      },
      {
        CITY: "JEFFERSON CITY",
      },
      {
        CITY: "LONGVIEW",
      },
      {
        CITY: "CHANDLER",
      },
      {
        CITY: "ARVADA",
      },
      {
        CITY: "ALHAMBRA",
      },
      {
        CITY: "KOKOMO",
      },
      {
        CITY: "OAKLAND PARK",
      },
      {
        CITY: "INDIANAPOLIS",
      },
      {
        CITY: "BOYNTON BEACH",
      },
      {
        CITY: "APACHE JUNCTION",
      },
      {
        CITY: "MOUNT PROSPECT",
      },
      {
        CITY: "CRANSTON",
      },
      {
        CITY: "LAFAYETTE",
      },
      {
        CITY: "OLATHE",
      },
      {
        CITY: "JOHNS CREEK",
      },
      {
        CITY: "GREENSBORO",
      },
      {
        CITY: "KALAMAZOO",
      },
      {
        CITY: "TIGARD",
      },
      {
        CITY: "JACKSONVILLE",
      },
      {
        CITY: "CITRUS HEIGHTS",
      },
      {
        CITY: "TUSCALOOSA",
      },
      {
        CITY: "ANKENY",
      },
      {
        CITY: "HAWTHORNE",
      },
      {
        CITY: "HOOVER",
      },
      {
        CITY: "YOUNGSTOWN",
      },
      {
        CITY: "MADISON",
      },
      {
        CITY: "REDLANDS",
      },
      {
        CITY: "CAPE GIRARDEAU",
      },
      {
        CITY: "NAMPA",
      },
      {
        CITY: "WASHINGTON",
      },
      {
        CITY: "WHEATON",
      },
      {
        CITY: "LANCASTER",
      },
      {
        CITY: "RIALTO",
      },
      {
        CITY: "FARGO",
      },
      {
        CITY: "PEORIA",
      },
      {
        CITY: "OMAHA",
      },
      {
        CITY: "RANCHO SANTA MARGARITA",
      },
      {
        CITY: "MANHATTAN",
      },
      {
        CITY: "PALM SPRINGS",
      },
      {
        CITY: "SAN DIEGO",
      },
      {
        CITY: "SANTA ROSA",
      },
      {
        CITY: "LOWELL",
      },
      {
        CITY: "BROKEN ARROW",
      },
      {
        CITY: "KENT",
      },
      {
        CITY: "NASHVILLE",
      },
      {
        CITY: "HACKENSACK",
      },
      {
        CITY: "BROOMFIELD",
      },
      {
        CITY: "DURHAM",
      },
      {
        CITY: "WICHITA",
      },
      {
        CITY: "CATHEDRAL CITY",
      },
      {
        CITY: "LAKEVILLE",
      },
      {
        CITY: "ELMHURST",
      },
      {
        CITY: "WESLACO",
      },
      {
        CITY: "GREENFIELD",
      },
      {
        CITY: "SUNNYVALE",
      },
      {
        CITY: "SANTA CRUZ",
      },
      {
        CITY: "KENOSHA",
      },
      {
        CITY: "STATE COLLEGE",
      },
      {
        CITY: "MANSFIELD",
      },
      {
        CITY: "QUINCY",
      },
      {
        CITY: "POMPANO BEACH",
      },
      {
        CITY: "SAN ANGELO",
      },
      {
        CITY: "BLOOMINGTON",
      },
      {
        CITY: "TRACY",
      },
      {
        CITY: "SPARTANBURG",
      },
      {
        CITY: "NASHUA",
      },
      {
        CITY: "MACON",
      },
      {
        CITY: "HEMPSTEAD",
      },
      {
        CITY: "CAMDEN",
      },
      {
        CITY: "PALM DESERT",
      },
      {
        CITY: "STREAMWOOD",
      },
      {
        CITY: "CHESAPEAKE",
      },
      {
        CITY: "DUBLIN",
      },
      {
        CITY: "CAMARILLO",
      },
      {
        CITY: "MUSKEGON",
      },
      {
        CITY: "ELIZABETH",
      },
      {
        CITY: "ANCHORAGE",
      },
      {
        CITY: "MINOT",
      },
      {
        CITY: "DAVIE",
      },
      {
        CITY: "MCALLEN",
      },
      {
        CITY: "MCKINNEY",
      },
      {
        CITY: "KILLEEN",
      },
      {
        CITY: "FINDLAY",
      },
      {
        CITY: "OAKLAND",
      },
      {
        CITY: "MOUNTAIN VIEW",
      },
      {
        CITY: "LAKE ELSINORE",
      },
      {
        CITY: "AMARILLO",
      },
      {
        CITY: "PROVO",
      },
      {
        CITY: "ATLANTA",
      },
      {
        CITY: "MONTEREY PARK",
      },
      {
        CITY: "STRONGSVILLE",
      },
      {
        CITY: "MADERA",
      },
      {
        CITY: "NEWPORT BEACH",
      },
      {
        CITY: "RIVERTON",
      },
      {
        CITY: "YAKIMA",
      },
      {
        CITY: "DAYTON",
      },
      {
        CITY: "GULFPORT",
      },
      {
        CITY: "LONG BEACH",
      },
      {
        CITY: "CHICO",
      },
      {
        CITY: "HATTIESBURG",
      },
      {
        CITY: "EUGENE",
      },
      {
        CITY: "COLTON",
      },
      {
        CITY: "MONTEBELLO",
      },
      {
        CITY: "PLEASANTON",
      },
      {
        CITY: "EVERETT",
      },
      {
        CITY: "HUTCHINSON",
      },
      {
        CITY: "FAIRFIELD",
      },
      {
        CITY: "AVONDALE",
      },
      {
        CITY: "EUCLID",
      },
      {
        CITY: "BATTLE CREEK",
      },
      {
        CITY: "BLOOMINGTON",
      },
      {
        CITY: "ROCK ISLAND",
      },
      {
        CITY: "BEAVERTON",
      },
      {
        CITY: "KENNER",
      },
      {
        CITY: "CLOVIS",
      },
      {
        CITY: "TROY",
      },
      {
        CITY: "MILPITAS",
      },
      {
        CITY: "DES PLAINES",
      },
      {
        CITY: "PLACENTIA",
      },
      {
        CITY: "TAUNTON",
      },
      {
        CITY: "MONTCLAIR",
      },
      {
        CITY: "JOHNSON CITY",
      },
      {
        CITY: "SAVANNAH",
      },
      {
        CITY: "WARREN",
      },
      {
        CITY: "VANCOUVER",
      },
      {
        CITY: "SUNRISE",
      },
      {
        CITY: "MUNCIE",
      },
      {
        CITY: "LEHI",
      },
      {
        CITY: "SAN FRANCISCO",
      },
      {
        CITY: "FONTANA",
      },
      {
        CITY: "FRISCO",
      },
      {
        CITY: "CHINO HILLS",
      },
      {
        CITY: "POWAY",
      },
      {
        CITY: "HUBER HEIGHTS",
      },
      {
        CITY: "NEW BRUNSWICK",
      },
      {
        CITY: "NORFOLK",
      },
      {
        CITY: "HILLSBORO",
      },
      {
        CITY: "SAN JACINTO",
      },
      {
        CITY: "PENSACOLA",
      },
      {
        CITY: "HAMILTON",
      },
      {
        CITY: "FLORENCE",
      },
      {
        CITY: "SAN BERNARDINO",
      },
      {
        CITY: "PEACHTREE CORNERS",
      },
      {
        CITY: "VICTORVILLE",
      },
      {
        CITY: "GARY",
      },
      {
        CITY: "CASA GRANDE",
      },
      {
        CITY: "MORGAN HILL",
      },
      {
        CITY: "DUBUQUE",
      },
      {
        CITY: "SYRACUSE",
      },
      {
        CITY: "CLARKSVILLE",
      },
      {
        CITY: "LAKELAND",
      },
      {
        CITY: "VALLEY STREAM",
      },
      {
        CITY: "DOVER",
      },
      {
        CITY: "MOORHEAD",
      },
      {
        CITY: "CULVER CITY",
      },
      {
        CITY: "PALATINE",
      },
      {
        CITY: "SEATTLE",
      },
      {
        CITY: "COVINGTON",
      },
      {
        CITY: "ELK GROVE",
      },
      {
        CITY: "SHREVEPORT",
      },
      {
        CITY: "OGDEN",
      },
      {
        CITY: "HARRISONBURG",
      },
      {
        CITY: "INGLEWOOD",
      },
      {
        CITY: "PASADENA",
      },
      {
        CITY: "LAWRENCE",
      },
      {
        CITY: "LAKE OSWEGO",
      },
      {
        CITY: "PETALUMA",
      },
      {
        CITY: "SPRINGFIELD",
      },
      {
        CITY: "FOLSOM",
      },
      {
        CITY: "PRESCOTT VALLEY",
      },
      {
        CITY: "LAS CRUCES",
      },
      {
        CITY: "BOZEMAN",
      },
      {
        CITY: "SCHAUMBURG",
      },
      {
        CITY: "WARREN",
      },
      {
        CITY: "ST. PETERS",
      },
      {
        CITY: "AMES",
      },
      {
        CITY: "SURPRISE",
      },
      {
        CITY: "RICHARDSON",
      },
      {
        CITY: "MIDLAND",
      },
      {
        CITY: "SAN MARCOS",
      },
      {
        CITY: "WILKES-BARRE",
      },
      {
        CITY: "APPLETON",
      },
      {
        CITY: "SHAWNEE",
      },
      {
        CITY: "MENTOR",
      },
      {
        CITY: "LIVERMORE",
      },
      {
        CITY: "CHARLOTTE",
      },
      {
        CITY: "OWENSBORO",
      },
      {
        CITY: "JUPITER",
      },
      {
        CITY: "ROHNERT PARK",
      },
      {
        CITY: "CHICAGO",
      },
      {
        CITY: "BILLINGS",
      },
      {
        CITY: "WAUWATOSA",
      },
      {
        CITY: "LOMBARD",
      },
      {
        CITY: "NEW HAVEN",
      },
      {
        CITY: "YORK",
      },
      {
        CITY: "KNOXVILLE",
      },
      {
        CITY: "ELYRIA",
      },
      {
        CITY: "BARNSTABLE TOWN",
      },
      {
        CITY: "MURRAY",
      },
      {
        CITY: "ALAMEDA",
      },
      {
        CITY: "MANTECA",
      },
      {
        CITY: "MAPLEWOOD",
      },
      {
        CITY: "MEDFORD",
      },
      {
        CITY: "MURFREESBORO",
      },
      {
        CITY: "YUBA CITY",
      },
      {
        CITY: "SHELTON",
      },
      {
        CITY: "CEDAR FALLS",
      },
      {
        CITY: "GERMANTOWN",
      },
      {
        CITY: "SCRANTON",
      },
      {
        CITY: "AUBURN",
      },
      {
        CITY: "MANASSAS",
      },
      {
        CITY: "VACAVILLE",
      },
      {
        CITY: "SANTA MONICA",
      },
      {
        CITY: "SUFFOLK",
      },
      {
        CITY: "ROCHESTER HILLS",
      },
      {
        CITY: "LA QUINTA",
      },
      {
        CITY: "CHARLESTON",
      },
      {
        CITY: "DELANO",
      },
      {
        CITY: "ST. CLOUD",
      },
      {
        CITY: "CHARLESTON",
      },
      {
        CITY: "KANNAPOLIS",
      },
      {
        CITY: "CORONA",
      },
      {
        CITY: "ROCKLIN",
      },
      {
        CITY: "LEE'S SUMMIT",
      },
      {
        CITY: "PALM COAST",
      },
      {
        CITY: "SANDY",
      },
      {
        CITY: "BENTONVILLE",
      },
      {
        CITY: "SANTA CLARITA",
      },
      {
        CITY: "ALTAMONTE SPRINGS",
      },
      {
        CITY: "NEW BRAUNFELS",
      },
      {
        CITY: "MEMPHIS",
      },
      {
        CITY: "IRVING",
      },
      {
        CITY: "ALEXANDRIA",
      },
      {
        CITY: "HESPERIA",
      },
      {
        CITY: "HOLYOKE",
      },
      {
        CITY: "DUNWOODY",
      },
      {
        CITY: "SKOKIE",
      },
      {
        CITY: "KEARNY",
      },
      {
        CITY: "BETHLEHEM",
      },
      {
        CITY: "GOOSE CREEK",
      },
      {
        CITY: "LITTLE ROCK",
      },
      {
        CITY: "SANTA ANA",
      },
      {
        CITY: "WHITE PLAINS",
      },
      {
        CITY: "IDAHO FALLS",
      },
      {
        CITY: "BELLEVUE",
      },
      {
        CITY: "ROSEVILLE",
      },
      {
        CITY: "PERTH AMBOY",
      },
      {
        CITY: "PEABODY",
      },
      {
        CITY: "GRAND ISLAND",
      },
      {
        CITY: "COLUMBIA",
      },
      {
        CITY: "RACINE",
      },
      {
        CITY: "CARMEL",
      },
      {
        CITY: "PORTAGE",
      },
      {
        CITY: "MIDDLETOWN",
      },
      {
        CITY: "WOODLAND",
      },
      {
        CITY: "REVERE",
      },
      {
        CITY: "EULESS",
      },
      {
        CITY: "ARLINGTON HEIGHTS",
      },
      {
        CITY: "HEMET",
      },
      {
        CITY: "HIGH POINT",
      },
      {
        CITY: "NEWARK",
      },
      {
        CITY: "MIDWEST CITY",
      },
      {
        CITY: "SAN MARCOS",
      },
      {
        CITY: "PORT ORANGE",
      },
      {
        CITY: "LAKEWOOD",
      },
      {
        CITY: "WESTERVILLE",
      },
      {
        CITY: "ROCKFORD",
      },
      {
        CITY: "EL CAJON",
      },
      {
        CITY: "ST. PETERSBURG",
      },
      {
        CITY: "DOWNERS GROVE",
      },
      {
        CITY: "MILFORD",
      },
      {
        CITY: "ALBANY",
      },
      {
        CITY: "LAS VEGAS",
      },
      {
        CITY: "PASADENA",
      },
      {
        CITY: "PORT ST. LUCIE",
      },
      {
        CITY: "CEDAR RAPIDS",
      },
      {
        CITY: "ORMOND BEACH",
      },
      {
        CITY: "QUINCY",
      },
      {
        CITY: "ST. JOSEPH",
      },
      {
        CITY: "REDONDO BEACH",
      },
      {
        CITY: "HOUSTON",
      },
      {
        CITY: "TEMECULA",
      },
      {
        CITY: "ANN ARBOR",
      },
      {
        CITY: "BEAUMONT",
      },
      {
        CITY: "EAST LANSING",
      },
      {
        CITY: "SANTEE",
      },
      {
        CITY: "TAMARAC",
      },
      {
        CITY: "BELLEVUE",
      },
      {
        CITY: "PEMBROKE PINES",
      },
      {
        CITY: "SPRINGDALE",
      },
      {
        CITY: "OXNARD",
      },
      {
        CITY: "ODESSA",
      },
      {
        CITY: "STILLWATER",
      },
      {
        CITY: "BROCKTON",
      },
      {
        CITY: "BAYONNE",
      },
      {
        CITY: "PARAMOUNT",
      },
      {
        CITY: "MOLINE",
      },
      {
        CITY: "MERIDIAN",
      },
      {
        CITY: "CLEVELAND",
      },
      {
        CITY: "JERSEY CITY",
      },
      {
        CITY: "ST. PAUL",
      },
      {
        CITY: "DELTONA",
      },
      {
        CITY: "FREEPORT",
      },
      {
        CITY: "IOWA CITY",
      },
      {
        CITY: "ANAHEIM",
      },
      {
        CITY: "APPLE VALLEY",
      },
      {
        CITY: "GREAT FALLS",
      },
      {
        CITY: "WEST VALLEY CITY",
      },
      {
        CITY: "HAMPTON",
      },
      {
        CITY: "GEORGETOWN",
      },
      {
        CITY: "OREM",
      },
      {
        CITY: "MONROVIA",
      },
      {
        CITY: "KELLER",
      },
      {
        CITY: "SOUTH SAN FRANCISCO",
      },
      {
        CITY: "PLYMOUTH",
      },
      {
        CITY: "SOUTH BEND",
      },
      {
        CITY: "EDMOND",
      },
      {
        CITY: "DES MOINES",
      },
      {
        CITY: "BONITA SPRINGS",
      },
      {
        CITY: "DRAPER",
      },
      {
        CITY: "WHITTIER",
      },
      {
        CITY: "LIVONIA",
      },
      {
        CITY: "MILWAUKEE",
      },
      {
        CITY: "AUBURN",
      },
      {
        CITY: "SUMTER",
      },
      {
        CITY: "BEVERLY",
      },
      {
        CITY: "RENTON",
      },
      {
        CITY: "CENTENNIAL",
      },
      {
        CITY: "RICHMOND",
      },
      {
        CITY: "ANDERSON",
      },
      {
        CITY: "BEAUMONT",
      },
      {
        CITY: "PFLUGERVILLE",
      },
      {
        CITY: "HOBOKEN",
      },
      {
        CITY: "LEXINGTON-FAYETTE",
      },
      {
        CITY: "NORWALK",
      },
      {
        CITY: "YUMA",
      },
      {
        CITY: "HIGHLAND",
      },
      {
        CITY: "CAROL STREAM",
      },
      {
        CITY: "JOLIET",
      },
      {
        CITY: "ROCK HILL",
      },
      {
        CITY: "RANCHO CORDOVA",
      },
      {
        CITY: "DEARBORN HEIGHTS",
      },
      {
        CITY: "HONOLULU",
      },
      {
        CITY: "VICTORIA",
      },
      {
        CITY: "ATTLEBORO",
      },
      {
        CITY: "WYLIE",
      },
      {
        CITY: "CARROLLTON",
      },
      {
        CITY: "PLAINFIELD",
      },
      {
        CITY: "HOFFMAN ESTATES",
      },
      {
        CITY: "METHUEN",
      },
      {
        CITY: "NORTH PORT",
      },
      {
        CITY: "ROCKVILLE",
      },
      {
        CITY: "BURBANK",
      },
      {
        CITY: "FULLERTON",
      },
      {
        CITY: "OSHKOSH",
      },
      {
        CITY: "HUNTSVILLE",
      },
      {
        CITY: "FARMINGTON HILLS",
      },
      {
        CITY: "PICO RIVERA",
      },
      {
        CITY: "CERRITOS",
      },
      {
        CITY: "LA PUENTE",
      },
      {
        CITY: "GRAND JUNCTION",
      },
      {
        CITY: "ENID",
      },
      {
        CITY: "SAN JOSE",
      },
      {
        CITY: "ROUND ROCK",
      },
      {
        CITY: "MAPLE GROVE",
      },
      {
        CITY: "NORMAN",
      },
      {
        CITY: "HOMESTEAD",
      },
      {
        CITY: "FRIENDSWOOD",
      },
      {
        CITY: "IRVINE",
      },
      {
        CITY: "POCATELLO",
      },
      {
        CITY: "PACIFICA",
      },
      {
        CITY: "SHEBOYGAN",
      },
      {
        CITY: "BOSSIER CITY",
      },
      {
        CITY: "CEDAR PARK",
      },
      {
        CITY: "SANDY SPRINGS",
      },
      {
        CITY: "CHAMPAIGN",
      },
      {
        CITY: "CLIFTON",
      },
      {
        CITY: "MOORE",
      },
      {
        CITY: "BLUE SPRINGS",
      },
      {
        CITY: "LANCASTER",
      },
      {
        CITY: "BRYAN",
      },
      {
        CITY: "HURST",
      },
      {
        CITY: "CAPE CORAL",
      },
      {
        CITY: "CUPERTINO",
      },
      {
        CITY: "PITTSFIELD",
      },
      {
        CITY: "GILROY",
      },
      {
        CITY: "MANCHESTER",
      },
      {
        CITY: "VIRGINIA BEACH",
      },
      {
        CITY: "SALINA",
      },
      {
        CITY: "SOMERVILLE",
      },
      {
        CITY: "LARGO",
      },
      {
        CITY: "FRESNO",
      },
      {
        CITY: "PHENIX CITY",
      },
      {
        CITY: "BERWYN",
      },
      {
        CITY: "PALM BEACH GARDENS",
      },
      {
        CITY: "PAWTUCKET",
      },
      {
        CITY: "SAN GABRIEL",
      },
      {
        CITY: "WINSTON-SALEM",
      },
      {
        CITY: "ALPHARETTA",
      },
      {
        CITY: "O'FALLON",
      },
      {
        CITY: "REDWOOD CITY",
      },
      {
        CITY: "UNION CITY",
      },
      {
        CITY: "HALLANDALE BEACH",
      },
      {
        CITY: "MANKATO",
      },
      {
        CITY: "ALISO VIEJO",
      },
      {
        CITY: "MENIFEE",
      },
      {
        CITY: "LOVELAND",
      },
      {
        CITY: "CARPENTERSVILLE",
      },
      {
        CITY: "PROVIDENCE",
      },
      {
        CITY: "HIALEAH",
      },
      {
        CITY: "SAN RAMON",
      },
      {
        CITY: "ONTARIO",
      },
      {
        CITY: "KIRKLAND",
      },
      {
        CITY: "ST. GEORGE",
      },
      {
        CITY: "HILTON HEAD ISLAND",
      },
      {
        CITY: "GLENDALE",
      },
      {
        CITY: "MERIDEN",
      },
      {
        CITY: "GARDENA",
      },
      {
        CITY: "WESTFIELD",
      },
      {
        CITY: "ELGIN",
      },
      {
        CITY: "PLANO",
      },
      {
        CITY: "LONGMONT",
      },
      {
        CITY: "COPPELL",
      },
      {
        CITY: "LAKEWOOD",
      },
      {
        CITY: "JONESBORO",
      },
      {
        CITY: "COLLEGE STATION",
      },
      {
        CITY: "DEARBORN",
      },
      {
        CITY: "UTICA",
      },
      {
        CITY: "MONROE",
      },
      {
        CITY: "BALTIMORE",
      },
      {
        CITY: "SPRINGFIELD",
      },
      {
        CITY: "PINE BLUFF",
      },
      {
        CITY: "PLAINFIELD",
      },
      {
        CITY: "LYNN",
      },
      {
        CITY: "WOODBURY",
      },
      {
        CITY: "GAINESVILLE",
      },
      {
        CITY: "WEST SACRAMENTO",
      },
      {
        CITY: "SAMMAMISH",
      },
      {
        CITY: "AVENTURA",
      },
      {
        CITY: "FAYETTEVILLE",
      },
      {
        CITY: "SAN RAFAEL",
      },
      {
        CITY: "CONWAY",
      },
      {
        CITY: "TACOMA",
      },
      {
        CITY: "MIDDLETOWN",
      },
      {
        CITY: "BURLINGTON",
      },
      {
        CITY: "TWIN FALLS",
      },
      {
        CITY: "SHERMAN",
      },
      {
        CITY: "NEW YORK",
      },
      {
        CITY: "SIMI VALLEY",
      },
      {
        CITY: "WEST COVINA",
      },
      {
        CITY: "CASTLE ROCK",
      },
      {
        CITY: "VALDOSTA",
      },
      {
        CITY: "FORT WORTH",
      },
      {
        CITY: "CARSON",
      },
      {
        CITY: "EVANSVILLE",
      },
      {
        CITY: "SANTA MARIA",
      },
      {
        CITY: "BRENTWOOD",
      },
      {
        CITY: "LA MESA",
      },
      {
        CITY: "WESTMINSTER",
      },
      {
        CITY: "READING",
      },
      {
        CITY: "TURLOCK",
      },
      {
        CITY: "GREELEY",
      },
      {
        CITY: "TYLER",
      },
      {
        CITY: "WINTER GARDEN",
      },
      {
        CITY: "WACO",
      },
      {
        CITY: "MARTINEZ",
      },
      {
        CITY: "THOUSAND OAKS",
      },
      {
        CITY: "AZUSA",
      },
      {
        CITY: "BRIDGEPORT",
      },
      {
        CITY: "ST. LOUIS PARK",
      },
      {
        CITY: "CHESTERFIELD",
      },
      {
        CITY: "GREENACRES",
      },
      {
        CITY: "PASCO",
      },
      {
        CITY: "TORRANCE",
      },
      {
        CITY: "MADISON",
      },
      {
        CITY: "JANESVILLE",
      },
      {
        CITY: "WAUKEGAN",
      },
      {
        CITY: "BOWLING GREEN",
      },
      {
        CITY: "ARLINGTON",
      },
      {
        CITY: "LEWISVILLE",
      },
      {
        CITY: "FARMINGTON",
      },
      {
        CITY: "OAKLEY",
      },
      {
        CITY: "PALO ALTO",
      },
      {
        CITY: "SPRINGFIELD",
      },
      {
        CITY: "NEW BEDFORD",
      },
      {
        CITY: "REDMOND",
      },
      {
        CITY: "BRENTWOOD",
      },
      {
        CITY: "DAVIS",
      },
      {
        CITY: "LA MIRADA",
      },
      {
        CITY: "SMYRNA",
      },
      {
        CITY: "ALTOONA",
      },
      {
        CITY: "DOTHAN",
      },
      {
        CITY: "EAST ORANGE",
      },
      {
        CITY: "TINLEY PARK",
      },
      {
        CITY: "BILOXI",
      },
      {
        CITY: "URBANA",
      },
      {
        CITY: "GARDEN GROVE",
      },
      {
        CITY: "LYNWOOD",
      },
      {
        CITY: "NORTHGLENN",
      },
      {
        CITY: "CANTON",
      },
      {
        CITY: "BROOKHAVEN",
      },
      {
        CITY: "BATON ROUGE",
      },
      {
        CITY: "BRISTOL",
      },
      {
        CITY: "INDEPENDENCE",
      },
      {
        CITY: "LANSING",
      },
      {
        CITY: "ORLAND PARK",
      },
      {
        CITY: "VINELAND",
      },
      {
        CITY: "GREEN BAY",
      },
      {
        CITY: "EVANSTON",
      },
      {
        CITY: "OCOEE",
      },
      {
        CITY: "LOS ANGELES",
      },
      {
        CITY: "FEDERAL WAY",
      },
      {
        CITY: "WEST JORDAN",
      },
      {
        CITY: "YUCAIPA",
      },
      {
        CITY: "PITTSBURGH",
      },
      {
        CITY: "DOWNEY",
      },
      {
        CITY: "CICERO",
      },
      {
        CITY: "SOUTH GATE",
      },
      {
        CITY: "PANAMA CITY",
      },
      {
        CITY: "JOPLIN",
      },
      {
        CITY: "LEAGUE CITY",
      },
      {
        CITY: "SALT LAKE CITY",
      },
      {
        CITY: "FORT PIERCE",
      },
      {
        CITY: "PHOENIX",
      },
      {
        CITY: "CORVALLIS",
      },
      {
        CITY: "CEDAR HILL",
      },
      {
        CITY: "CHICOPEE",
      },
      {
        CITY: "OCALA",
      },
      {
        CITY: "ROSWELL",
      },
      {
        CITY: "PARK RIDGE",
      },
      {
        CITY: "CONCORD",
      },
      {
        CITY: "OLYMPIA",
      },
      {
        CITY: "HUNTINGTON BEACH",
      },
      {
        CITY: "CORPUS CHRISTI",
      },
      {
        CITY: "HAVERHILL",
      },
      {
        CITY: "HANOVER PARK",
      },
      {
        CITY: "CAMBRIDGE",
      },
      {
        CITY: "GLENDALE",
      },
      {
        CITY: "STANTON",
      },
      {
        CITY: "SUMMERVILLE",
      },
      {
        CITY: "DAVENPORT",
      },
      {
        CITY: "BOCA RATON",
      },
      {
        CITY: "NEW BRITAIN",
      },
      {
        CITY: "ROSEVILLE",
      },
      {
        CITY: "CLEVELAND HEIGHTS",
      },
      {
        CITY: "GRESHAM",
      },
      {
        CITY: "LAUDERHILL",
      },
      {
        CITY: "HENDERSONVILLE",
      },
      {
        CITY: "SANTA CLARA",
      },
      {
        CITY: "OCEANSIDE",
      },
      {
        CITY: "LAKE FOREST",
      },
      {
        CITY: "NOVI",
      },
      {
        CITY: "WEST NEW YORK",
      },
      {
        CITY: "EVERETT",
      },
      {
        CITY: "LINDEN",
      },
      {
        CITY: "ASHEVILLE",
      },
      {
        CITY: "COMPTON",
      },
      {
        CITY: "DELRAY BEACH",
      },
      {
        CITY: "WELLINGTON",
      },
      {
        CITY: "BULLHEAD CITY",
      },
      {
        CITY: "WESTON",
      },
      {
        CITY: "TROY",
      },
      {
        CITY: "NEW ROCHELLE",
      },
      {
        CITY: "STOCKTON",
      },
      {
        CITY: "ROYAL OAK",
      },
      {
        CITY: "TITUSVILLE",
      },
      {
        CITY: "LAGUNA NIGUEL",
      },
      {
        CITY: "SPOKANE VALLEY",
      },
      {
        CITY: "COSTA MESA",
      },
      {
        CITY: "GRAND PRAIRIE",
      },
      {
        CITY: "PORTSMOUTH",
      },
      {
        CITY: "LACEY",
      },
      {
        CITY: "APEX",
      },
      {
        CITY: "SIOUX CITY",
      },
      {
        CITY: "ENCINITAS",
      },
      {
        CITY: "SPANISH FORK",
      },
      {
        CITY: "HUNTINGTON PARK",
      },
      {
        CITY: "EDEN PRAIRIE",
      },
      {
        CITY: "PEORIA",
      },
      {
        CITY: "TEMPE",
      },
      {
        CITY: "WILMINGTON",
      },
      {
        CITY: "TAYLORSVILLE",
      },
      {
        CITY: "WESTLAND",
      },
      {
        CITY: "MESQUITE",
      },
      {
        CITY: "BURLINGTON",
      },
      {
        CITY: "GARLAND",
      },
      {
        CITY: "RICHMOND",
      },
      {
        CITY: "BISMARCK",
      },
      {
        CITY: "CARSON CITY",
      },
      {
        CITY: "MUSKOGEE",
      },
      {
        CITY: "AUGUSTA-RICHMOND COUNTY",
      },
      {
        CITY: "FAYETTEVILLE",
      },
      {
        CITY: "ALBANY",
      },
      {
        CITY: "SAYREVILLE",
      },
      {
        CITY: "NEWARK",
      },
      {
        CITY: "DIAMOND BAR",
      },
      {
        CITY: "DECATUR",
      },
      {
        CITY: "CHATTANOOGA",
      },
      {
        CITY: "JEFFERSONVILLE",
      },
      {
        CITY: "BARTLETT",
      },
      {
        CITY: "BALDWIN PARK",
      },
      {
        CITY: "MIAMI BEACH",
      },
      {
        CITY: "JACKSON",
      },
      {
        CITY: "PORT ARTHUR",
      },
      {
        CITY: "SAN LUIS OBISPO",
      },
      {
        CITY: "BURLESON",
      },
      {
        CITY: "MIRAMAR",
      },
      {
        CITY: "EL PASO",
      },
      {
        CITY: "TOPEKA",
      },
      {
        CITY: "ROSEMEAD",
      },
      {
        CITY: "KEIZER",
      },
      {
        CITY: "MINNETONKA",
      },
      {
        CITY: "AKRON",
      },
      {
        CITY: "EDINBURG",
      },
      {
        CITY: "RANCHO CUCAMONGA",
      },
      {
        CITY: "NORTH CHARLESTON",
      },
      {
        CITY: "LOUISVILLE",
      },
      {
        CITY: "WATSONVILLE",
      },
      {
        CITY: "COCONUT CREEK",
      },
      {
        CITY: "BRADENTON",
      },
      {
        CITY: "CARLSBAD",
      },
      {
        CITY: "TEXAS CITY",
      },
      {
        CITY: "REDDING",
      },
      {
        CITY: "WYOMING",
      },
      {
        CITY: "LORAIN",
      },
      {
        CITY: "ROY",
      },
      {
        CITY: "FORT LAUDERDALE",
      },
      {
        CITY: "WARNER ROBINS",
      },
      {
        CITY: "CLEARWATER",
      },
      {
        CITY: "CUTLER BAY",
      },
      {
        CITY: "FLORENCE",
      },
      {
        CITY: "TERRE HAUTE",
      },
      {
        CITY: "TEMPLE",
      },
      {
        CITY: "BEAVERCREEK",
      },
      {
        CITY: "SIOUX FALLS",
      },
      {
        CITY: "COLUMBUS",
      },
      {
        CITY: "MARGATE",
      },
      {
        CITY: "LEOMINSTER",
      },
      {
        CITY: "WARWICK",
      },
      {
        CITY: "BAKERSFIELD",
      },
      {
        CITY: "MIAMI",
      },
      {
        CITY: "LAKE CHARLES",
      },
      {
        CITY: "RICHLAND",
      },
      {
        CITY: "HICKORY",
      },
      {
        CITY: "LYNCHBURG",
      },
      {
        CITY: "BOWIE",
      },
      {
        CITY: "DANBURY",
      },
      {
        CITY: "CHARLOTTESVILLE",
      },
      {
        CITY: "CORAL GABLES",
      },
      {
        CITY: "COEUR D'ALENE",
      },
      {
        CITY: "EL MONTE",
      },
      {
        CITY: "CRYSTAL LAKE",
      },
      {
        CITY: "DANVILLE",
      },
      {
        CITY: "GOODYEAR",
      },
      {
        CITY: "PATERSON",
      },
      {
        CITY: "SALEM",
      },
      {
        CITY: "KISSIMMEE",
      },
      {
        CITY: "SAGINAW",
      },
      {
        CITY: "BROWNSVILLE",
      },
      {
        CITY: "FLOWER MOUND",
      },
      {
        CITY: "GRAPEVINE",
      },
      {
        CITY: "EDINA",
      },
      {
        CITY: "DETROIT",
      },
      {
        CITY: "FAIRFIELD",
      },
      {
        CITY: "MANSFIELD",
      },
      {
        CITY: "ROCHESTER",
      },
      {
        CITY: "BIRMINGHAM",
      },
      {
        CITY: "AUSTIN",
      },
      {
        CITY: "SANTA BARBARA",
      },
      {
        CITY: "ADDISON",
      },
      {
        CITY: "RIO RANCHO",
      },
      {
        CITY: "NORMAL",
      },
      {
        CITY: "ROMEOVILLE",
      },
    ],
  },
  {
    COUNTRY: "UNITED KINGDOM",
    CODE: "GB",
    CITIES: [
      {
        CITY: "ABBERTON",
      },
      {
        CITY: "ABBOTS LANGLEY",
      },
      {
        CITY: "ABERAERON",
      },
      {
        CITY: "ABERCHIRDER",
      },
      {
        CITY: "ABERCYNON",
      },
      {
        CITY: "ABERDARE",
      },
      {
        CITY: "ABERDEEN",
      },
      {
        CITY: "ABERFELDY",
      },
      {
        CITY: "ABERFORD",
      },
      {
        CITY: "ABERFOYLE",
      },
      {
        CITY: "ABERGAVENNY",
      },
      {
        CITY: "ABERGELE",
      },
      {
        CITY: "ABERGWYNFI",
      },
      {
        CITY: "ABERGYNOLWYN",
      },
      {
        CITY: "ABERKENFIG",
      },
      {
        CITY: "ABERLOUR",
      },
      {
        CITY: "ABERSYCHAN",
      },
      {
        CITY: "ABERTILLERY",
      },
      {
        CITY: "ABERYSTWYTH",
      },
      {
        CITY: "ABINGDON",
      },
      {
        CITY: "ABOYNE",
      },
      {
        CITY: "ACCRINGTON",
      },
      {
        CITY: "ACTON",
      },
      {
        CITY: "ADDINGTON",
      },
      {
        CITY: "ADDLESTONE",
      },
      {
        CITY: "ADLINGFLEET",
      },
      {
        CITY: "AGHALEE",
      },
      {
        CITY: "AINTREE",
      },
      {
        CITY: "AIRDRIE",
      },
      {
        CITY: "AKELEY",
      },
      {
        CITY: "ALBRIGHTON",
      },
      {
        CITY: "ALCESTER",
      },
      {
        CITY: "ALDEBURGH",
      },
      {
        CITY: "ALDERHOLT",
      },
      {
        CITY: "ALDERLEY EDGE",
      },
      {
        CITY: "ALDERMASTON",
      },
      {
        CITY: "ALDERSHOT",
      },
      {
        CITY: "ALDRIDGE",
      },
      {
        CITY: "ALEXANDRIA",
      },
      {
        CITY: "ALFOLD",
      },
      {
        CITY: "ALFORD",
      },
      {
        CITY: "ALFRETON",
      },
      {
        CITY: "ALLOA",
      },
      {
        CITY: "ALNESS",
      },
      {
        CITY: "ALNWICK",
      },
      {
        CITY: "ALRESFORD",
      },
      {
        CITY: "ALREWAS",
      },
      {
        CITY: "ALSAGER",
      },
      {
        CITY: "ALSTON",
      },
      {
        CITY: "ALTHAM",
      },
      {
        CITY: "ALTON",
      },
      {
        CITY: "ALTRINCHAM",
      },
      {
        CITY: "ALVA",
      },
      {
        CITY: "ALVECHURCH",
      },
      {
        CITY: "ALVESTON",
      },
      {
        CITY: "AMBLESIDE",
      },
      {
        CITY: "AMERSHAM",
      },
      {
        CITY: "AMESBURY",
      },
      {
        CITY: "AMLWCH",
      },
      {
        CITY: "AMMANFORD",
      },
      {
        CITY: "AMPTHILL",
      },
      {
        CITY: "ANDOVER",
      },
      {
        CITY: "ANGMERING",
      },
      {
        CITY: "ANLABY",
      },
      {
        CITY: "ANNALONG",
      },
      {
        CITY: "ANNAN",
      },
      {
        CITY: "ANNESLEY",
      },
      {
        CITY: "ANNIESLAND",
      },
      {
        CITY: "ANSTEY",
      },
      {
        CITY: "ANSTRUTHER",
      },
      {
        CITY: "ANTRIM",
      },
      {
        CITY: "APPLEBY",
      },
      {
        CITY: "APPLEFORD",
      },
      {
        CITY: "APPLETON",
      },
      {
        CITY: "APPLEY BRIDGE",
      },
      {
        CITY: "ARBROATH",
      },
      {
        CITY: "ARDINGLY",
      },
      {
        CITY: "ARDMILLAN",
      },
      {
        CITY: "ARDROSSAN",
      },
      {
        CITY: "ARLESEY",
      },
      {
        CITY: "ARLEY",
      },
      {
        CITY: "ARMADALE",
      },
      {
        CITY: "ARMAGH",
      },
      {
        CITY: "ARMITAGE",
      },
      {
        CITY: "ARNOLD",
      },
      {
        CITY: "ARROCHAR",
      },
      {
        CITY: "ARTHOG",
      },
      {
        CITY: "ARUNDEL",
      },
      {
        CITY: "ASCOT",
      },
      {
        CITY: "ASH",
      },
      {
        CITY: "ASH VALE",
      },
      {
        CITY: "ASHBOURNE",
      },
      {
        CITY: "ASHBURTON",
      },
      {
        CITY: "ASHBY DE LA LAUNDE",
      },
      {
        CITY: "ASHBY DE LA ZOUCH",
      },
      {
        CITY: "ASHDON",
      },
      {
        CITY: "ASHEN",
      },
      {
        CITY: "ASHFIELD",
      },
      {
        CITY: "ASHFORD",
      },
      {
        CITY: "ASHINGTON",
      },
      {
        CITY: "ASHLEY",
      },
      {
        CITY: "ASHMORE",
      },
      {
        CITY: "ASHTEAD",
      },
      {
        CITY: "ASHTON IN MAKERFIELD",
      },
      {
        CITY: "ASHTON-UNDER-LYNE",
      },
      {
        CITY: "ASHWELL",
      },
      {
        CITY: "ASKAM IN FURNESS",
      },
      {
        CITY: "ASKERN",
      },
      {
        CITY: "ASKHAM",
      },
      {
        CITY: "ASTLEY",
      },
      {
        CITY: "ASTON",
      },
      {
        CITY: "ASTON CLINTON",
      },
      {
        CITY: "ATHERSTONE",
      },
      {
        CITY: "ATHERTON",
      },
      {
        CITY: "ATTLEBOROUGH",
      },
      {
        CITY: "ATWORTH",
      },
      {
        CITY: "AUCHINLECK",
      },
      {
        CITY: "AUCHTERARDER",
      },
      {
        CITY: "AUCHTERMUCHTY",
      },
      {
        CITY: "AUGHER",
      },
      {
        CITY: "AUGHNACLOY",
      },
      {
        CITY: "AULTBEA",
      },
      {
        CITY: "AVELEY",
      },
      {
        CITY: "AVIEMORE",
      },
      {
        CITY: "AVOCH",
      },
      {
        CITY: "AVONMOUTH",
      },
      {
        CITY: "AXBRIDGE",
      },
      {
        CITY: "AXMINSTER",
      },
      {
        CITY: "AYCLIFFE",
      },
      {
        CITY: "AYLESBURY",
      },
      {
        CITY: "AYLESFORD",
      },
      {
        CITY: "AYLSHAM",
      },
      {
        CITY: "AYR",
      },
      {
        CITY: "BABRAHAM",
      },
      {
        CITY: "BACK",
      },
      {
        CITY: "BACTON",
      },
      {
        CITY: "BACUP",
      },
      {
        CITY: "BAGILLT",
      },
      {
        CITY: "BAGSHOT",
      },
      {
        CITY: "BAILDON",
      },
      {
        CITY: "BAINTON",
      },
      {
        CITY: "BAKEWELL",
      },
      {
        CITY: "BALA",
      },
      {
        CITY: "BALDOCK",
      },
      {
        CITY: "BALERNO",
      },
      {
        CITY: "BALHAM",
      },
      {
        CITY: "BALL",
      },
      {
        CITY: "BALLINGRY",
      },
      {
        CITY: "BALLOCH",
      },
      {
        CITY: "BALLYBOGY",
      },
      {
        CITY: "BALLYCASTLE",
      },
      {
        CITY: "BALLYCLARE",
      },
      {
        CITY: "BALLYMENA",
      },
      {
        CITY: "BALLYMONEY",
      },
      {
        CITY: "BALLYNAHINCH",
      },
      {
        CITY: "BAMPTON",
      },
      {
        CITY: "BANBRIDGE",
      },
      {
        CITY: "BANBURY",
      },
      {
        CITY: "BANCHORY",
      },
      {
        CITY: "BANFF",
      },
      {
        CITY: "BANGOR",
      },
      {
        CITY: "BANGOR-IS-Y-COED",
      },
      {
        CITY: "BANHAM",
      },
      {
        CITY: "BANSTEAD",
      },
      {
        CITY: "BARBARAVILLE",
      },
      {
        CITY: "BARGOED",
      },
      {
        CITY: "BARKING",
      },
      {
        CITY: "BARKWAY",
      },
      {
        CITY: "BARLEY",
      },
      {
        CITY: "BARMOUTH",
      },
      {
        CITY: "BARMSTON",
      },
      {
        CITY: "BARNARD CASTLE",
      },
      {
        CITY: "BARNES",
      },
      {
        CITY: "BARNET",
      },
      {
        CITY: "BARNHAM",
      },
      {
        CITY: "BARNOLDSWICK",
      },
      {
        CITY: "BARNSLEY",
      },
      {
        CITY: "BARNSTAPLE",
      },
      {
        CITY: "BARNWELL",
      },
      {
        CITY: "BARROW",
      },
      {
        CITY: "BARROW IN FURNESS",
      },
      {
        CITY: "BARROW UPON HUMBER",
      },
      {
        CITY: "BARROW UPON SOAR",
      },
      {
        CITY: "BARROWFORD",
      },
      {
        CITY: "BARRY",
      },
      {
        CITY: "BARTON IN FABIS",
      },
      {
        CITY: "BARTON ON SEA",
      },
      {
        CITY: "BARTON UNDER NEEDWOOD",
      },
      {
        CITY: "BARTON UPON HUMBER",
      },
      {
        CITY: "BARTON-LE-CLAY",
      },
      {
        CITY: "BARWELL",
      },
      {
        CITY: "BASILDON",
      },
      {
        CITY: "BASINGSTOKE",
      },
      {
        CITY: "BASLOW",
      },
      {
        CITY: "BATH",
      },
      {
        CITY: "BATHGATE",
      },
      {
        CITY: "BATLEY",
      },
      {
        CITY: "BATTLE",
      },
      {
        CITY: "BAWTRY",
      },
      {
        CITY: "BAYBLE",
      },
      {
        CITY: "BAYFORD",
      },
      {
        CITY: "BEACH",
      },
      {
        CITY: "BEACONSFIELD",
      },
      {
        CITY: "BEAL",
      },
      {
        CITY: "BEALINGS",
      },
      {
        CITY: "BEAMINSTER",
      },
      {
        CITY: "BEAUFORT",
      },
      {
        CITY: "BEAULIEU",
      },
      {
        CITY: "BEAULY",
      },
      {
        CITY: "BEAUMARIS",
      },
      {
        CITY: "BEAUMONT",
      },
      {
        CITY: "BEAWORTHY",
      },
      {
        CITY: "BEBINGTON",
      },
      {
        CITY: "BECCLES",
      },
      {
        CITY: "BECKENHAM",
      },
      {
        CITY: "BECKINGHAM",
      },
      {
        CITY: "BECKLEY",
      },
      {
        CITY: "BECKTON",
      },
      {
        CITY: "BEDALE",
      },
      {
        CITY: "BEDFORD",
      },
      {
        CITY: "BEDLINGTON",
      },
      {
        CITY: "BEDMINSTER",
      },
      {
        CITY: "BEDWORTH",
      },
      {
        CITY: "BEER",
      },
      {
        CITY: "BEESTON",
      },
      {
        CITY: "BEITH",
      },
      {
        CITY: "BEKESBOURNE",
      },
      {
        CITY: "BELBROUGHTON",
      },
      {
        CITY: "BELCOO",
      },
      {
        CITY: "BELFAST",
      },
      {
        CITY: "BELLEEK",
      },
      {
        CITY: "BELLSHILL",
      },
      {
        CITY: "BELMONT",
      },
      {
        CITY: "BELPER",
      },
      {
        CITY: "BELVEDERE",
      },
      {
        CITY: "BEMBRIDGE",
      },
      {
        CITY: "BEN RHYDDING",
      },
      {
        CITY: "BENBURB",
      },
      {
        CITY: "BENENDEN",
      },
      {
        CITY: "BENLLECH",
      },
      {
        CITY: "BENSON",
      },
      {
        CITY: "BENTLEY",
      },
      {
        CITY: "BERKELEY",
      },
      {
        CITY: "BERKHAMSTEAD",
      },
      {
        CITY: "BERMONDSEY",
      },
      {
        CITY: "BERWICK",
      },
      {
        CITY: "BERWICK-UPON-TWEED",
      },
      {
        CITY: "BESTHORPE",
      },
      {
        CITY: "BETCHWORTH",
      },
      {
        CITY: "BETTWS",
      },
      {
        CITY: "BETWS",
      },
      {
        CITY: "BEVERLEY",
      },
      {
        CITY: "BEWDLEY",
      },
      {
        CITY: "BEXHILL",
      },
      {
        CITY: "BEXLEYHEATH",
      },
      {
        CITY: "BICESTER",
      },
      {
        CITY: "BICKENHILL",
      },
      {
        CITY: "BICKLEIGH",
      },
      {
        CITY: "BIDDENDEN",
      },
      {
        CITY: "BIDDENHAM",
      },
      {
        CITY: "BIDDULPH",
      },
      {
        CITY: "BIDEFORD",
      },
      {
        CITY: "BIDFORD-ON-AVON",
      },
      {
        CITY: "BIDSTON",
      },
      {
        CITY: "BIERTON",
      },
      {
        CITY: "BIGGAR",
      },
      {
        CITY: "BIGGIN HILL",
      },
      {
        CITY: "BIGGLESWADE",
      },
      {
        CITY: "BIGNOR",
      },
      {
        CITY: "BILDESTON",
      },
      {
        CITY: "BILLERICAY",
      },
      {
        CITY: "BILLESLEY",
      },
      {
        CITY: "BILLINGHAM",
      },
      {
        CITY: "BILLINGSHURST",
      },
      {
        CITY: "BILSTHORPE",
      },
      {
        CITY: "BILSTON",
      },
      {
        CITY: "BINFIELD",
      },
      {
        CITY: "BINGHAM",
      },
      {
        CITY: "BINGLEY",
      },
      {
        CITY: "BINSTED",
      },
      {
        CITY: "BIRCHANGER",
      },
      {
        CITY: "BIRCHINGTON",
      },
      {
        CITY: "BIRDBROOK",
      },
      {
        CITY: "BIRKENHEAD",
      },
      {
        CITY: "BIRMINGHAM",
      },
      {
        CITY: "BIRNAM",
      },
      {
        CITY: "BIRSTALL",
      },
      {
        CITY: "BIRTLEY",
      },
      {
        CITY: "BISHOP AUCKLAND",
      },
      {
        CITY: "BISHOP BURTON",
      },
      {
        CITY: "BISHOPBRIGGS",
      },
      {
        CITY: "BISHOPS CLEEVE",
      },
      {
        CITY: "BISHOPS WALTHAM",
      },
      {
        CITY: "BISHOPSTOKE",
      },
      {
        CITY: "BISHOPSTROW",
      },
      {
        CITY: "BISHOPTON",
      },
      {
        CITY: "BISLEY",
      },
      {
        CITY: "BLACKBURN",
      },
      {
        CITY: "BLACKFORD",
      },
      {
        CITY: "BLACKHEATH",
      },
      {
        CITY: "BLACKPOOL",
      },
      {
        CITY: "BLACKWATER",
      },
      {
        CITY: "BLACKWOOD",
      },
      {
        CITY: "BLAENAU-FFESTINIOG",
      },
      {
        CITY: "BLAENAVON",
      },
      {
        CITY: "BLAENWAUN",
      },
      {
        CITY: "BLAINA",
      },
      {
        CITY: "BLAIRGOWRIE",
      },
      {
        CITY: "BLAKENEY",
      },
      {
        CITY: "BLANDFORD FORUM",
      },
      {
        CITY: "BLAYDON",
      },
      {
        CITY: "BLEDLOW",
      },
      {
        CITY: "BLETCHINGLEY",
      },
      {
        CITY: "BLETCHLEY",
      },
      {
        CITY: "BLEWBURY",
      },
      {
        CITY: "BLIDWORTH",
      },
      {
        CITY: "BLOOMSBURY",
      },
      {
        CITY: "BLOXWICH",
      },
      {
        CITY: "BLUNHAM",
      },
      {
        CITY: "BLUNTISHAM",
      },
      {
        CITY: "BLYTH",
      },
      {
        CITY: "BODELWYDDAN",
      },
      {
        CITY: "BODMIN",
      },
      {
        CITY: "BOGNOR REGIS",
      },
      {
        CITY: "BOLDON COLLIERY",
      },
      {
        CITY: "BOLLINGTON",
      },
      {
        CITY: "BOLNEY",
      },
      {
        CITY: "BOLSOVER",
      },
      {
        CITY: "BOLTON",
      },
      {
        CITY: "BONAR BRIDGE",
      },
      {
        CITY: "BONNYBRIDGE",
      },
      {
        CITY: "BONNYRIGG",
      },
      {
        CITY: "BOOKHAM",
      },
      {
        CITY: "BOOTLE",
      },
      {
        CITY: "BORDESLEY",
      },
      {
        CITY: "BOREHAM",
      },
      {
        CITY: "BOREHAMWOOD",
      },
      {
        CITY: "BOROUGH GREEN",
      },
      {
        CITY: "BOROUGHBRIDGE",
      },
      {
        CITY: "BOSBURY",
      },
      {
        CITY: "BOSCASTLE",
      },
      {
        CITY: "BOSTON",
      },
      {
        CITY: "BOSTON SPA",
      },
      {
        CITY: "BOTESDALE",
      },
      {
        CITY: "BOTHWELL",
      },
      {
        CITY: "BOTLEY",
      },
      {
        CITY: "BOTTESFORD",
      },
      {
        CITY: "BOURNE",
      },
      {
        CITY: "BOURNE END",
      },
      {
        CITY: "BOURNEMOUTH",
      },
      {
        CITY: "BOURNVILLE",
      },
      {
        CITY: "BOURTON ON THE WATER",
      },
      {
        CITY: "BOVEY TRACEY",
      },
      {
        CITY: "BOW",
      },
      {
        CITY: "BOWDON",
      },
      {
        CITY: "BOWES",
      },
      {
        CITY: "BOWNESS-ON-WINDERMERE",
      },
      {
        CITY: "BOX",
      },
      {
        CITY: "BOXLEY",
      },
      {
        CITY: "BOZEAT",
      },
      {
        CITY: "BRACKLEY",
      },
      {
        CITY: "BRACKNELL",
      },
      {
        CITY: "BRADFIELD ST GEORGE",
      },
      {
        CITY: "BRADFORD",
      },
      {
        CITY: "BRADFORD-ON-AVON",
      },
      {
        CITY: "BRADWELL ON SEA",
      },
      {
        CITY: "BRAINTREE",
      },
      {
        CITY: "BRAMFORD",
      },
      {
        CITY: "BRAMHALL",
      },
      {
        CITY: "BRAMLEY",
      },
      {
        CITY: "BRAMPTON",
      },
      {
        CITY: "BRANDESBURTON",
      },
      {
        CITY: "BRANDON",
      },
      {
        CITY: "BRANSFORD",
      },
      {
        CITY: "BRANSGORE",
      },
      {
        CITY: "BRASTED",
      },
      {
        CITY: "BRAUNSTONE",
      },
      {
        CITY: "BRAUNTON",
      },
      {
        CITY: "BRAYTON",
      },
      {
        CITY: "BRECHIN",
      },
      {
        CITY: "BRECON",
      },
      {
        CITY: "BREDBURY",
      },
      {
        CITY: "BREDE",
      },
      {
        CITY: "BRENCHLEY",
      },
      {
        CITY: "BRENTFORD",
      },
      {
        CITY: "BRENTWOOD",
      },
      {
        CITY: "BREWOOD",
      },
      {
        CITY: "BRIDGE",
      },
      {
        CITY: "BRIDGEMARY",
      },
      {
        CITY: "BRIDGEND",
      },
      {
        CITY: "BRIDGHAM",
      },
      {
        CITY: "BRIDGNORTH",
      },
      {
        CITY: "BRIDGWATER",
      },
      {
        CITY: "BRIDLINGTON",
      },
      {
        CITY: "BRIDPORT",
      },
      {
        CITY: "BRIERFIELD",
      },
      {
        CITY: "BRIERLEY HILL",
      },
      {
        CITY: "BRIGG",
      },
      {
        CITY: "BRIGHOUSE",
      },
      {
        CITY: "BRIGHTLINGSEA",
      },
      {
        CITY: "BRIGHTON",
      },
      {
        CITY: "BRIGSTOCK",
      },
      {
        CITY: "BRIMPTON",
      },
      {
        CITY: "BRIMSCOMBE",
      },
      {
        CITY: "BRISTOL",
      },
      {
        CITY: "BRITON FERRY",
      },
      {
        CITY: "BRIXHAM",
      },
      {
        CITY: "BRIXTON HILL",
      },
      {
        CITY: "BRIXWORTH",
      },
      {
        CITY: "BROAD BLUNSDON",
      },
      {
        CITY: "BROADSTAIRS",
      },
      {
        CITY: "BROADSTONE",
      },
      {
        CITY: "BROADWAS",
      },
      {
        CITY: "BROADWAY",
      },
      {
        CITY: "BROCKENHURST",
      },
      {
        CITY: "BROCKLEY GREEN",
      },
      {
        CITY: "BROCKWORTH",
      },
      {
        CITY: "BROMBOROUGH",
      },
      {
        CITY: "BROMHAM",
      },
      {
        CITY: "BROMPTON",
      },
      {
        CITY: "BROMSGROVE",
      },
      {
        CITY: "BROMYARD",
      },
      {
        CITY: "BROOK",
      },
      {
        CITY: "BROOKE",
      },
      {
        CITY: "BROOKLAND",
      },
      {
        CITY: "BROOM",
      },
      {
        CITY: "BROSELEY",
      },
      {
        CITY: "BROUGH",
      },
      {
        CITY: "BROUGHTON",
      },
      {
        CITY: "BROUGHTY FERRY",
      },
      {
        CITY: "BROWNHILLS",
      },
      {
        CITY: "BROXBOURNE",
      },
      {
        CITY: "BROXBURN",
      },
      {
        CITY: "BRUTON",
      },
      {
        CITY: "BUCKDEN",
      },
      {
        CITY: "BUCKFASTLEIGH",
      },
      {
        CITY: "BUCKHURST HILL",
      },
      {
        CITY: "BUCKIE",
      },
      {
        CITY: "BUCKINGHAM",
      },
      {
        CITY: "BUCKLAND",
      },
      {
        CITY: "BUCKLEY",
      },
      {
        CITY: "BUCKNELL",
      },
      {
        CITY: "BUDE",
      },
      {
        CITY: "BUDLEIGH SALTERTON",
      },
      {
        CITY: "BUGBROOKE",
      },
      {
        CITY: "BUILTH WELLS",
      },
      {
        CITY: "BULFORD",
      },
      {
        CITY: "BULKINGTON",
      },
      {
        CITY: "BULWELL",
      },
      {
        CITY: "BUNGAY",
      },
      {
        CITY: "BUNTINGFORD",
      },
      {
        CITY: "BURBAGE",
      },
      {
        CITY: "BURFORD",
      },
      {
        CITY: "BURGESS HILL",
      },
      {
        CITY: "BURGH LE MARSH",
      },
      {
        CITY: "BURGHCLERE",
      },
      {
        CITY: "BURLEY",
      },
      {
        CITY: "BURNHAM",
      },
      {
        CITY: "BURNHAM ON CROUCH",
      },
      {
        CITY: "BURNHAM-ON-SEA",
      },
      {
        CITY: "BURNLEY",
      },
      {
        CITY: "BURNOPFIELD",
      },
      {
        CITY: "BURNTISLAND",
      },
      {
        CITY: "BURNTWOOD",
      },
      {
        CITY: "BURRY PORT",
      },
      {
        CITY: "BURSCOUGH",
      },
      {
        CITY: "BURSLEM",
      },
      {
        CITY: "BURSTWICK",
      },
      {
        CITY: "BURTON",
      },
      {
        CITY: "BURTON PIDSEA",
      },
      {
        CITY: "BURTON-ON-TRENT",
      },
      {
        CITY: "BURWASH",
      },
      {
        CITY: "BURWELL",
      },
      {
        CITY: "BURY",
      },
      {
        CITY: "BURY ST EDMUNDS",
      },
      {
        CITY: "BURYTHORPE",
      },
      {
        CITY: "BUSHEY",
      },
      {
        CITY: "BUTTERTON",
      },
      {
        CITY: "BUXTED",
      },
      {
        CITY: "BUXTON",
      },
      {
        CITY: "BYFIELD",
      },
      {
        CITY: "BYFLEET",
      },
      {
        CITY: "CADBURY",
      },
      {
        CITY: "CADDINGTON",
      },
      {
        CITY: "CAERNARFON",
      },
      {
        CITY: "CAERPHILLY",
      },
      {
        CITY: "CAERSWS",
      },
      {
        CITY: "CAISTER-ON-SEA",
      },
      {
        CITY: "CAISTOR",
      },
      {
        CITY: "CALDICOT",
      },
      {
        CITY: "CALLANDER",
      },
      {
        CITY: "CALLINGTON",
      },
      {
        CITY: "CALNE",
      },
      {
        CITY: "CALSTOCK",
      },
      {
        CITY: "CALVERTON",
      },
      {
        CITY: "CAM",
      },
      {
        CITY: "CAMBERLEY",
      },
      {
        CITY: "CAMBERWELL",
      },
      {
        CITY: "CAMBORNE",
      },
      {
        CITY: "CAMBRIDGE",
      },
      {
        CITY: "CAMBUSLANG",
      },
      {
        CITY: "CAMBUSNETHAN",
      },
      {
        CITY: "CAMELFORD",
      },
      {
        CITY: "CAMPBELTOWN",
      },
      {
        CITY: "CANEWDON",
      },
      {
        CITY: "CANNOCK",
      },
      {
        CITY: "CANTERBURY",
      },
      {
        CITY: "CAPEL",
      },
      {
        CITY: "CAPENHURST",
      },
      {
        CITY: "CARBROOKE",
      },
      {
        CITY: "CARDIFF",
      },
      {
        CITY: "CARDIGAN",
      },
      {
        CITY: "CARDONALD",
      },
      {
        CITY: "CARDROSS",
      },
      {
        CITY: "CARLISLE",
      },
      {
        CITY: "CARLTON",
      },
      {
        CITY: "CARLTON LE MOORLAND",
      },
      {
        CITY: "CARLUKE",
      },
      {
        CITY: "CARMARTHEN",
      },
      {
        CITY: "CARNFORTH",
      },
      {
        CITY: "CARNMONEY",
      },
      {
        CITY: "CARNOUSTIE",
      },
      {
        CITY: "CARRBRIDGE",
      },
      {
        CITY: "CARRICKFERGUS",
      },
      {
        CITY: "CARROWDORE",
      },
      {
        CITY: "CARSHALTON",
      },
      {
        CITY: "CARTERTON",
      },
      {
        CITY: "CASTLE CARY",
      },
      {
        CITY: "CASTLE DONINGTON",
      },
      {
        CITY: "CASTLE DOUGLAS",
      },
      {
        CITY: "CASTLEDERG",
      },
      {
        CITY: "CASTLEFORD",
      },
      {
        CITY: "CASTLEREAGH",
      },
      {
        CITY: "CASTLETON",
      },
      {
        CITY: "CASTLEWELLAN",
      },
      {
        CITY: "CASTON",
      },
      {
        CITY: "CATERHAM",
      },
      {
        CITY: "CATFORD",
      },
      {
        CITY: "CATHERINGTON",
      },
      {
        CITY: "CATTERICK",
      },
      {
        CITY: "CAVERSHAM",
      },
      {
        CITY: "CAWSTON",
      },
      {
        CITY: "CAXTON",
      },
      {
        CITY: "CAYTHORPE",
      },
      {
        CITY: "CHACOMBE",
      },
      {
        CITY: "CHADDESDEN",
      },
      {
        CITY: "CHADWELL",
      },
      {
        CITY: "CHALFONT SAINT PETER",
      },
      {
        CITY: "CHALGROVE",
      },
      {
        CITY: "CHANDLERS FORD",
      },
      {
        CITY: "CHAPEL EN LE FRITH",
      },
      {
        CITY: "CHAPELTOWN",
      },
      {
        CITY: "CHARD",
      },
      {
        CITY: "CHARFIELD",
      },
      {
        CITY: "CHARING CROSS",
      },
      {
        CITY: "CHARLESTOWN",
      },
      {
        CITY: "CHARLTON ON OTMOOR",
      },
      {
        CITY: "CHARLWOOD",
      },
      {
        CITY: "CHARMOUTH",
      },
      {
        CITY: "CHARTHAM",
      },
      {
        CITY: "CHASETOWN",
      },
      {
        CITY: "CHATHAM",
      },
      {
        CITY: "CHATTERIS",
      },
      {
        CITY: "CHEADLE",
      },
      {
        CITY: "CHEADLE HULME",
      },
      {
        CITY: "CHEAM",
      },
      {
        CITY: "CHECKLEY",
      },
      {
        CITY: "CHEDDAR",
      },
      {
        CITY: "CHELLASTON",
      },
      {
        CITY: "CHELMSFORD",
      },
      {
        CITY: "CHELSFIELD",
      },
      {
        CITY: "CHELTENHAM",
      },
      {
        CITY: "CHEPSTOW",
      },
      {
        CITY: "CHERTSEY",
      },
      {
        CITY: "CHESHAM",
      },
      {
        CITY: "CHESHUNT",
      },
      {
        CITY: "CHESSINGTON",
      },
      {
        CITY: "CHESTER",
      },
      {
        CITY: "CHESTER-LE-STREET",
      },
      {
        CITY: "CHESTERFIELD",
      },
      {
        CITY: "CHESTFIELD",
      },
      {
        CITY: "CHICHESTER",
      },
      {
        CITY: "CHIGWELL",
      },
      {
        CITY: "CHILBOLTON",
      },
      {
        CITY: "CHILCOMPTON",
      },
      {
        CITY: "CHILDWALL",
      },
      {
        CITY: "CHILTON",
      },
      {
        CITY: "CHILTON TRINITY",
      },
      {
        CITY: "CHILWELL",
      },
      {
        CITY: "CHINGFORD",
      },
      {
        CITY: "CHINLEY",
      },
      {
        CITY: "CHINNOR",
      },
      {
        CITY: "CHIPPENHAM",
      },
      {
        CITY: "CHIPPING CAMPDEN",
      },
      {
        CITY: "CHIPPING NORTON",
      },
      {
        CITY: "CHIPPING ONGAR",
      },
      {
        CITY: "CHIPPING SODBURY",
      },
      {
        CITY: "CHIPSTEAD",
      },
      {
        CITY: "CHISLEHURST",
      },
      {
        CITY: "CHISWICK",
      },
      {
        CITY: "CHOPPINGTON",
      },
      {
        CITY: "CHORLEY",
      },
      {
        CITY: "CHRISTCHURCH",
      },
      {
        CITY: "CHRISTLETON",
      },
      {
        CITY: "CHRYSTON",
      },
      {
        CITY: "CHULMLEIGH",
      },
      {
        CITY: "CHURCH",
      },
      {
        CITY: "CHURCH GRESLEY",
      },
      {
        CITY: "CHURCH LEIGH",
      },
      {
        CITY: "CHURCH STRETTON",
      },
      {
        CITY: "CHURCHILL",
      },
      {
        CITY: "CINDERFORD",
      },
      {
        CITY: "CIRENCESTER",
      },
      {
        CITY: "CITY OF LONDON",
      },
      {
        CITY: "CITY OF WESTMINSTER",
      },
      {
        CITY: "CLACKMANNAN",
      },
      {
        CITY: "CLACTON-ON-SEA",
      },
      {
        CITY: "CLAPHAM",
      },
      {
        CITY: "CLARBESTON ROAD",
      },
      {
        CITY: "CLARE",
      },
      {
        CITY: "CLAVERDON",
      },
      {
        CITY: "CLAVERING",
      },
      {
        CITY: "CLAYGATE",
      },
      {
        CITY: "CLAYTON WEST",
      },
      {
        CITY: "CLEATOR",
      },
      {
        CITY: "CLEATOR MOOR",
      },
      {
        CITY: "CLECKHEATON",
      },
      {
        CITY: "CLEVEDON",
      },
      {
        CITY: "CLEVELEYS",
      },
      {
        CITY: "CLIFFE",
      },
      {
        CITY: "CLIFTON",
      },
      {
        CITY: "CLIFTON HAMPDEN",
      },
      {
        CITY: "CLIPSTONE",
      },
      {
        CITY: "CLITHEROE",
      },
      {
        CITY: "CLOVENFORDS",
      },
      {
        CITY: "CLUN",
      },
      {
        CITY: "CLUNDERWEN",
      },
      {
        CITY: "CLUTTON",
      },
      {
        CITY: "CLYDACH",
      },
      {
        CITY: "CLYDEBANK",
      },
      {
        CITY: "COALBROOKDALE",
      },
      {
        CITY: "COALISLAND",
      },
      {
        CITY: "COALVILLE",
      },
      {
        CITY: "COATBRIDGE",
      },
      {
        CITY: "COBHAM",
      },
      {
        CITY: "COCKERHAM",
      },
      {
        CITY: "COCKERMOUTH",
      },
      {
        CITY: "CODSALL",
      },
      {
        CITY: "COED-TALON",
      },
      {
        CITY: "COGENHOE",
      },
      {
        CITY: "COGGESHALL",
      },
      {
        CITY: "COLCHESTER",
      },
      {
        CITY: "COLEFORD",
      },
      {
        CITY: "COLERAINE",
      },
      {
        CITY: "COLNBROOK",
      },
      {
        CITY: "COLNE",
      },
      {
        CITY: "COLWYN BAY",
      },
      {
        CITY: "COLYFORD",
      },
      {
        CITY: "COLYTON",
      },
      {
        CITY: "COMBER",
      },
      {
        CITY: "COMPTON",
      },
      {
        CITY: "COMPTON DUNDON",
      },
      {
        CITY: "COMRIE",
      },
      {
        CITY: "CONGLETON",
      },
      {
        CITY: "CONISBROUGH",
      },
      {
        CITY: "CONNAHS QUAY",
      },
      {
        CITY: "CONON BRIDGE",
      },
      {
        CITY: "CONSETT",
      },
      {
        CITY: "CONWAY",
      },
      {
        CITY: "COOKHAM",
      },
      {
        CITY: "COOKSBRIDGE",
      },
      {
        CITY: "COOKSTOWN",
      },
      {
        CITY: "COPPENHALL",
      },
      {
        CITY: "COPPULL",
      },
      {
        CITY: "CORBRIDGE",
      },
      {
        CITY: "CORBY",
      },
      {
        CITY: "CORFE CASTLE",
      },
      {
        CITY: "CORFE MULLEN",
      },
      {
        CITY: "CORPACH",
      },
      {
        CITY: "CORRINGHAM",
      },
      {
        CITY: "CORSHAM",
      },
      {
        CITY: "CORWEN",
      },
      {
        CITY: "COSELEY",
      },
      {
        CITY: "COSHAM",
      },
      {
        CITY: "COTGRAVE",
      },
      {
        CITY: "COTTENHAM",
      },
      {
        CITY: "COTTERED",
      },
      {
        CITY: "COTTINGHAM",
      },
      {
        CITY: "COULSDON",
      },
      {
        CITY: "COUNTESS WEAR",
      },
      {
        CITY: "COUPAR ANGUS",
      },
      {
        CITY: "COVENT GARDEN",
      },
      {
        CITY: "COVENTRY",
      },
      {
        CITY: "COWBRIDGE",
      },
      {
        CITY: "COWDEN",
      },
      {
        CITY: "COWDENBEATH",
      },
      {
        CITY: "COWLEY",
      },
      {
        CITY: "CRADLEY",
      },
      {
        CITY: "CRAIGAVON",
      },
      {
        CITY: "CRAMLINGTON",
      },
      {
        CITY: "CRANBROOK",
      },
      {
        CITY: "CRANFIELD",
      },
      {
        CITY: "CRANFORD",
      },
      {
        CITY: "CRANLEIGH",
      },
      {
        CITY: "CRATHORNE",
      },
      {
        CITY: "CRAVEN ARMS",
      },
      {
        CITY: "CRAWLEY",
      },
      {
        CITY: "CRAYFORD",
      },
      {
        CITY: "CREDITON",
      },
      {
        CITY: "CREWE",
      },
      {
        CITY: "CREWKERNE",
      },
      {
        CITY: "CRICCIETH",
      },
      {
        CITY: "CRICK",
      },
      {
        CITY: "CRICKHOWELL",
      },
      {
        CITY: "CRICKLADE",
      },
      {
        CITY: "CRICKLEWOOD",
      },
      {
        CITY: "CRIEFF",
      },
      {
        CITY: "CROFTON",
      },
      {
        CITY: "CROMER",
      },
      {
        CITY: "CROMWELL",
      },
      {
        CITY: "CROOK",
      },
      {
        CITY: "CROOKHAM",
      },
      {
        CITY: "CROSBY",
      },
      {
        CITY: "CROSS",
      },
      {
        CITY: "CROSS IN HAND",
      },
      {
        CITY: "CROSS KEYS",
      },
      {
        CITY: "CROSSGAR",
      },
      {
        CITY: "CROSSGATES",
      },
      {
        CITY: "CROSSHOUSE",
      },
      {
        CITY: "CROSTON",
      },
      {
        CITY: "CROUGHTON",
      },
      {
        CITY: "CROWBOROUGH",
      },
      {
        CITY: "CROWLAND",
      },
      {
        CITY: "CROWTHORNE",
      },
      {
        CITY: "CROXLEY GREEN",
      },
      {
        CITY: "CROYDON",
      },
      {
        CITY: "CRUMLIN",
      },
      {
        CITY: "CRYMYCH",
      },
      {
        CITY: "CUBLINGTON",
      },
      {
        CITY: "CUCKFIELD",
      },
      {
        CITY: "CUFFLEY",
      },
      {
        CITY: "CULLEN",
      },
      {
        CITY: "CULLOMPTON",
      },
      {
        CITY: "CUMBERNAULD",
      },
      {
        CITY: "CUMNOCK",
      },
      {
        CITY: "CUPAR",
      },
      {
        CITY: "CURDRIDGE",
      },
      {
        CITY: "CURRIE",
      },
      {
        CITY: "CWMBRAN",
      },
      {
        CITY: "CYNWYD",
      },
      {
        CITY: "DAGENHAM",
      },
      {
        CITY: "DALBEATTIE",
      },
      {
        CITY: "DALKEITH",
      },
      {
        CITY: "DALRY",
      },
      {
        CITY: "DALTON IN FURNESS",
      },
      {
        CITY: "DARESBURY",
      },
      {
        CITY: "DARFIELD",
      },
      {
        CITY: "DARLASTON",
      },
      {
        CITY: "DARLINGTON",
      },
      {
        CITY: "DARTFORD",
      },
      {
        CITY: "DARTMOUTH",
      },
      {
        CITY: "DARVEL",
      },
      {
        CITY: "DARWEN",
      },
      {
        CITY: "DATCHET",
      },
      {
        CITY: "DAVENTRY",
      },
      {
        CITY: "DAWLEY",
      },
      {
        CITY: "DAWLISH",
      },
      {
        CITY: "DEAL",
      },
      {
        CITY: "DEAN",
      },
      {
        CITY: "DEIGHTON",
      },
      {
        CITY: "DENBIGH",
      },
      {
        CITY: "DENBY",
      },
      {
        CITY: "DENHAM",
      },
      {
        CITY: "DENNY",
      },
      {
        CITY: "DENTON",
      },
      {
        CITY: "DEPTFORD",
      },
      {
        CITY: "DERBY",
      },
      {
        CITY: "DEREHAM",
      },
      {
        CITY: "DERWEN",
      },
      {
        CITY: "DESBOROUGH",
      },
      {
        CITY: "DEVIZES",
      },
      {
        CITY: "DEWSBURY",
      },
      {
        CITY: "DIDCOT",
      },
      {
        CITY: "DIDDINGTON",
      },
      {
        CITY: "DINAS POWYS",
      },
      {
        CITY: "DINGESTOW",
      },
      {
        CITY: "DINGWALL",
      },
      {
        CITY: "DINNINGTON",
      },
      {
        CITY: "DISS",
      },
      {
        CITY: "DOAGH",
      },
      {
        CITY: "DOLGELLY",
      },
      {
        CITY: "DOLLAR",
      },
      {
        CITY: "DOLLIS HILL",
      },
      {
        CITY: "DOLWYDDELAN",
      },
      {
        CITY: "DONAGHADEE",
      },
      {
        CITY: "DONCASTER",
      },
      {
        CITY: "DONNINGTON",
      },
      {
        CITY: "DORCHESTER",
      },
      {
        CITY: "DORKING",
      },
      {
        CITY: "DORNEY",
      },
      {
        CITY: "DORNOCH",
      },
      {
        CITY: "DORRIDGE",
      },
      {
        CITY: "DOUGLAS",
      },
      {
        CITY: "DOUNE",
      },
      {
        CITY: "DOVER",
      },
      {
        CITY: "DOVERCOURT",
      },
      {
        CITY: "DOWNEND",
      },
      {
        CITY: "DOWNHAM MARKET",
      },
      {
        CITY: "DOWNPATRICK",
      },
      {
        CITY: "DRAPERSTOWN",
      },
      {
        CITY: "DRAYCOTT",
      },
      {
        CITY: "DRAYCOTT IN THE MOORS",
      },
      {
        CITY: "DRAYTON",
      },
      {
        CITY: "DRAYTON BASSETT",
      },
      {
        CITY: "DRAYTON SAINT LEONARD",
      },
      {
        CITY: "DRIFFIELD",
      },
      {
        CITY: "DRIGHLINGTON",
      },
      {
        CITY: "DROITWICH",
      },
      {
        CITY: "DROMARA",
      },
      {
        CITY: "DROMORE",
      },
      {
        CITY: "DRONFIELD",
      },
      {
        CITY: "DROXFORD",
      },
      {
        CITY: "DROYLSDEN",
      },
      {
        CITY: "DRUMAHOE",
      },
      {
        CITY: "DRUMCHAPEL",
      },
      {
        CITY: "DRYBROOK",
      },
      {
        CITY: "DRYMEN",
      },
      {
        CITY: "DUDDINGTON",
      },
      {
        CITY: "DUDLEY",
      },
      {
        CITY: "DUFFIELD",
      },
      {
        CITY: "DUFFUS",
      },
      {
        CITY: "DUKINFIELD",
      },
      {
        CITY: "DULVERTON",
      },
      {
        CITY: "DULWICH",
      },
      {
        CITY: "DUMBARTON",
      },
      {
        CITY: "DUMBLETON",
      },
      {
        CITY: "DUMFRIES",
      },
      {
        CITY: "DUNBAR",
      },
      {
        CITY: "DUNBLANE",
      },
      {
        CITY: "DUNCHURCH",
      },
      {
        CITY: "DUNDEE",
      },
      {
        CITY: "DUNDONALD",
      },
      {
        CITY: "DUNFERMLINE",
      },
      {
        CITY: "DUNGANNON",
      },
      {
        CITY: "DUNGIVEN",
      },
      {
        CITY: "DUNKELD",
      },
      {
        CITY: "DUNKESWELL",
      },
      {
        CITY: "DUNMURRY",
      },
      {
        CITY: "DUNNING",
      },
      {
        CITY: "DUNOON",
      },
      {
        CITY: "DUNS",
      },
      {
        CITY: "DUNSTABLE",
      },
      {
        CITY: "DURHAM",
      },
      {
        CITY: "DURRINGTON",
      },
      {
        CITY: "DURSLEY",
      },
      {
        CITY: "DUXFORD",
      },
      {
        CITY: "DYCE",
      },
      {
        CITY: "DYMOCK",
      },
      {
        CITY: "DYSERTH",
      },
      {
        CITY: "EAGLE",
      },
      {
        CITY: "EAGLESFIELD",
      },
      {
        CITY: "EAGLESHAM",
      },
      {
        CITY: "EARLEY",
      },
      {
        CITY: "EARLHAM",
      },
      {
        CITY: "EARLS COLNE",
      },
      {
        CITY: "EARLS COURT",
      },
      {
        CITY: "EARLSTON",
      },
      {
        CITY: "EARNLEY",
      },
      {
        CITY: "EASINGTON",
      },
      {
        CITY: "EASINGWOLD",
      },
      {
        CITY: "EAST BARNET",
      },
      {
        CITY: "EAST BERGHOLT",
      },
      {
        CITY: "EAST BOLDON",
      },
      {
        CITY: "EAST BUDLEIGH",
      },
      {
        CITY: "EAST CHALLOW",
      },
      {
        CITY: "EAST COWES",
      },
      {
        CITY: "EAST DOWN",
      },
      {
        CITY: "EAST DULWICH",
      },
      {
        CITY: "EAST GRINSTEAD",
      },
      {
        CITY: "EAST HAGBOURNE",
      },
      {
        CITY: "EAST HAM",
      },
      {
        CITY: "EAST HANNINGFIELD",
      },
      {
        CITY: "EAST HARLING",
      },
      {
        CITY: "EAST HOATHLY",
      },
      {
        CITY: "EAST HORSLEY",
      },
      {
        CITY: "EAST KEAL",
      },
      {
        CITY: "EAST KILBRIDE",
      },
      {
        CITY: "EAST LEAKE",
      },
      {
        CITY: "EAST LINTON",
      },
      {
        CITY: "EAST MALLING",
      },
      {
        CITY: "EAST MOLESEY",
      },
      {
        CITY: "EAST PECKHAM",
      },
      {
        CITY: "EAST PRESTON",
      },
      {
        CITY: "EAST RETFORD",
      },
      {
        CITY: "EAST STOUR",
      },
      {
        CITY: "EAST TILBURY",
      },
      {
        CITY: "EASTBOURNE",
      },
      {
        CITY: "EASTCHURCH",
      },
      {
        CITY: "EASTCOTE",
      },
      {
        CITY: "EASTHAM",
      },
      {
        CITY: "EASTINGTON",
      },
      {
        CITY: "EASTLEIGH",
      },
      {
        CITY: "EASTON",
      },
      {
        CITY: "EASTWOOD",
      },
      {
        CITY: "EBBW VALE",
      },
      {
        CITY: "ECCLES",
      },
      {
        CITY: "ECCLESHALL",
      },
      {
        CITY: "EDENBRIDGE",
      },
      {
        CITY: "EDENFIELD",
      },
      {
        CITY: "EDGBASTON",
      },
      {
        CITY: "EDGEFIELD",
      },
      {
        CITY: "EDGWARE",
      },
      {
        CITY: "EDINBURGH",
      },
      {
        CITY: "EDMONTON",
      },
      {
        CITY: "EDWALTON",
      },
      {
        CITY: "EDWINSTOWE",
      },
      {
        CITY: "EFFINGHAM",
      },
      {
        CITY: "EGERTON",
      },
      {
        CITY: "EGHAM",
      },
      {
        CITY: "EGREMONT",
      },
      {
        CITY: "EGTON",
      },
      {
        CITY: "ELDERSLIE",
      },
      {
        CITY: "ELGIN",
      },
      {
        CITY: "ELLAND",
      },
      {
        CITY: "ELLESMERE",
      },
      {
        CITY: "ELLESMERE PORT",
      },
      {
        CITY: "ELLINGTON",
      },
      {
        CITY: "ELLON",
      },
      {
        CITY: "ELLOUGHTON",
      },
      {
        CITY: "ELMLEY LOVETT",
      },
      {
        CITY: "ELSTEAD",
      },
      {
        CITY: "ELSTREE",
      },
      {
        CITY: "ELSWORTH",
      },
      {
        CITY: "ELTHAM",
      },
      {
        CITY: "ELY",
      },
      {
        CITY: "EMPINGHAM",
      },
      {
        CITY: "EMSWORTH",
      },
      {
        CITY: "ENFIELD",
      },
      {
        CITY: "ENGLEFIELD GREEN",
      },
      {
        CITY: "ENNISKILLEN",
      },
      {
        CITY: "ENSTONE",
      },
      {
        CITY: "EPPING",
      },
      {
        CITY: "EPPING GREEN",
      },
      {
        CITY: "EPSOM",
      },
      {
        CITY: "ERDINGTON",
      },
      {
        CITY: "ERITH",
      },
      {
        CITY: "ESHER",
      },
      {
        CITY: "ESSENDON",
      },
      {
        CITY: "ETCHINGHAM",
      },
      {
        CITY: "ETON",
      },
      {
        CITY: "EVERSHOLT",
      },
      {
        CITY: "EVESHAM",
      },
      {
        CITY: "EWELME",
      },
      {
        CITY: "EWHURST",
      },
      {
        CITY: "EXETER",
      },
      {
        CITY: "EXMINSTER",
      },
      {
        CITY: "EXMOUTH",
      },
      {
        CITY: "EYE",
      },
      {
        CITY: "EYEMOUTH",
      },
      {
        CITY: "EYNSHAM",
      },
      {
        CITY: "FAILSWORTH",
      },
      {
        CITY: "FAIRFORD",
      },
      {
        CITY: "FAIRLIGHT",
      },
      {
        CITY: "FAKENHAM",
      },
      {
        CITY: "FALKIRK",
      },
      {
        CITY: "FALKLAND",
      },
      {
        CITY: "FALMOUTH",
      },
      {
        CITY: "FANGFOSS",
      },
      {
        CITY: "FAREHAM",
      },
      {
        CITY: "FARINGDON",
      },
      {
        CITY: "FARMBOROUGH",
      },
      {
        CITY: "FARNBOROUGH",
      },
      {
        CITY: "FARNDON",
      },
      {
        CITY: "FARNHAM",
      },
      {
        CITY: "FARNHAM ROYAL",
      },
      {
        CITY: "FARNINGHAM",
      },
      {
        CITY: "FARNWORTH",
      },
      {
        CITY: "FAULDHOUSE",
      },
      {
        CITY: "FAULKLAND",
      },
      {
        CITY: "FAVERSHAM",
      },
      {
        CITY: "FELBRIDGE",
      },
      {
        CITY: "FELIXSTOWE",
      },
      {
        CITY: "FELSTED",
      },
      {
        CITY: "FELTHAM",
      },
      {
        CITY: "FERNDALE",
      },
      {
        CITY: "FERRYHILL",
      },
      {
        CITY: "FERRYSIDE",
      },
      {
        CITY: "FILEY",
      },
      {
        CITY: "FILLONGLEY",
      },
      {
        CITY: "FINCHAMPSTEAD",
      },
      {
        CITY: "FINCHLEY",
      },
      {
        CITY: "FINEDON",
      },
      {
        CITY: "FINTRY",
      },
      {
        CITY: "FISHBURN",
      },
      {
        CITY: "FISHGUARD",
      },
      {
        CITY: "FITZWILLIAM",
      },
      {
        CITY: "FIVEMILETOWN",
      },
      {
        CITY: "FLADBURY",
      },
      {
        CITY: "FLEET",
      },
      {
        CITY: "FLEETWOOD",
      },
      {
        CITY: "FLINT",
      },
      {
        CITY: "FLITWICK",
      },
      {
        CITY: "FLORDON",
      },
      {
        CITY: "FOCHABERS",
      },
      {
        CITY: "FOLKESTONE",
      },
      {
        CITY: "FORD",
      },
      {
        CITY: "FORDINGBRIDGE",
      },
      {
        CITY: "FOREST ROW",
      },
      {
        CITY: "FORFAR",
      },
      {
        CITY: "FORMBY",
      },
      {
        CITY: "FORRES",
      },
      {
        CITY: "FORT WILLIAM",
      },
      {
        CITY: "FOUR MARKS",
      },
      {
        CITY: "FOWEY",
      },
      {
        CITY: "FOWNHOPE",
      },
      {
        CITY: "FRAMLINGHAM",
      },
      {
        CITY: "FRANT",
      },
      {
        CITY: "FRASERBURGH",
      },
      {
        CITY: "FRECKLETON",
      },
      {
        CITY: "FRENSHAM",
      },
      {
        CITY: "FRESHWATER",
      },
      {
        CITY: "FRESSINGFIELD",
      },
      {
        CITY: "FRIERN BARNET",
      },
      {
        CITY: "FRIMLEY",
      },
      {
        CITY: "FRINGFORD",
      },
      {
        CITY: "FRINTON-ON-SEA",
      },
      {
        CITY: "FRISKNEY",
      },
      {
        CITY: "FRITHVILLE",
      },
      {
        CITY: "FRIZINGTON",
      },
      {
        CITY: "FRODSHAM",
      },
      {
        CITY: "FROGGATT",
      },
      {
        CITY: "FROME",
      },
      {
        CITY: "FULHAM",
      },
      {
        CITY: "FULMER",
      },
      {
        CITY: "GAERWEN",
      },
      {
        CITY: "GAINSBOROUGH",
      },
      {
        CITY: "GALASHIELS",
      },
      {
        CITY: "GALSTON",
      },
      {
        CITY: "GAMLINGAY",
      },
      {
        CITY: "GARGRAVE",
      },
      {
        CITY: "GARGUNNOCK",
      },
      {
        CITY: "GARRISON",
      },
      {
        CITY: "GARSTANG",
      },
      {
        CITY: "GARSTON",
      },
      {
        CITY: "GARTH",
      },
      {
        CITY: "GATESHEAD",
      },
      {
        CITY: "GATWICK",
      },
      {
        CITY: "GAYDON",
      },
      {
        CITY: "GAYTON THORPE",
      },
      {
        CITY: "GELLIGAER",
      },
      {
        CITY: "GIFFORD",
      },
      {
        CITY: "GIGGLESWICK",
      },
      {
        CITY: "GILLINGHAM",
      },
      {
        CITY: "GIPPING",
      },
      {
        CITY: "GIRTON",
      },
      {
        CITY: "GIRVAN",
      },
      {
        CITY: "GLASDRUMMAN",
      },
      {
        CITY: "GLASGOW",
      },
      {
        CITY: "GLASTONBURY",
      },
      {
        CITY: "GLENBOIG",
      },
      {
        CITY: "GLENROTHES",
      },
      {
        CITY: "GLENSHEE",
      },
      {
        CITY: "GLENTHAM",
      },
      {
        CITY: "GLOSSOP",
      },
      {
        CITY: "GLOUCESTER",
      },
      {
        CITY: "GNOSALL",
      },
      {
        CITY: "GODALMING",
      },
      {
        CITY: "GODMANCHESTER",
      },
      {
        CITY: "GODMERSHAM",
      },
      {
        CITY: "GODSTONE",
      },
      {
        CITY: "GOLBORNE",
      },
      {
        CITY: "GOMERSAL",
      },
      {
        CITY: "GOODMAYES",
      },
      {
        CITY: "GOODWICK",
      },
      {
        CITY: "GOOLE",
      },
      {
        CITY: "GOOSTREY",
      },
      {
        CITY: "GORDON",
      },
      {
        CITY: "GOREBRIDGE",
      },
      {
        CITY: "GORING",
      },
      {
        CITY: "GORLESTON-ON-SEA",
      },
      {
        CITY: "GORSEINON",
      },
      {
        CITY: "GORSLAS",
      },
      {
        CITY: "GORTON",
      },
      {
        CITY: "GOSBERTON",
      },
      {
        CITY: "GOSFIELD",
      },
      {
        CITY: "GOSFORTH",
      },
      {
        CITY: "GOSPORT",
      },
      {
        CITY: "GOUDHURST",
      },
      {
        CITY: "GOUROCK",
      },
      {
        CITY: "GRANBY",
      },
      {
        CITY: "GRANGE",
      },
      {
        CITY: "GRANGEMOUTH",
      },
      {
        CITY: "GRANTHAM",
      },
      {
        CITY: "GRANTOWN ON SPEY",
      },
      {
        CITY: "GRASMERE",
      },
      {
        CITY: "GRATELEY",
      },
      {
        CITY: "GRAVELEY",
      },
      {
        CITY: "GRAVESEND",
      },
      {
        CITY: "GRAYS",
      },
      {
        CITY: "GREAT AMWELL",
      },
      {
        CITY: "GREAT BADDOW",
      },
      {
        CITY: "GREAT BARTON",
      },
      {
        CITY: "GREAT BILLING",
      },
      {
        CITY: "GREAT CHESTERFORD",
      },
      {
        CITY: "GREAT DUNMOW",
      },
      {
        CITY: "GREAT FRANSHAM",
      },
      {
        CITY: "GREAT GIDDING",
      },
      {
        CITY: "GREAT GLEMHAM",
      },
      {
        CITY: "GREAT GRANSDEN",
      },
      {
        CITY: "GREAT MILTON",
      },
      {
        CITY: "GREAT MISSENDEN",
      },
      {
        CITY: "GREAT RYBURGH",
      },
      {
        CITY: "GREAT STAUGHTON",
      },
      {
        CITY: "GREAT TORRINGTON",
      },
      {
        CITY: "GREAT WALDINGFIELD",
      },
      {
        CITY: "GREAT YARMOUTH",
      },
      {
        CITY: "GREENFIELD",
      },
      {
        CITY: "GREENFORD",
      },
      {
        CITY: "GREENHEAD",
      },
      {
        CITY: "GREENHITHE",
      },
      {
        CITY: "GREENISLAND",
      },
      {
        CITY: "GREENOCK",
      },
      {
        CITY: "GREENSTED",
      },
      {
        CITY: "GREENWICH",
      },
      {
        CITY: "GRENDON",
      },
      {
        CITY: "GRENDON UNDERWOOD",
      },
      {
        CITY: "GRESFORD",
      },
      {
        CITY: "GRETNA",
      },
      {
        CITY: "GRETNA GREEN",
      },
      {
        CITY: "GRETTON",
      },
      {
        CITY: "GRIMSARGH",
      },
      {
        CITY: "GRIMSBY",
      },
      {
        CITY: "GROOMBRIDGE",
      },
      {
        CITY: "GROVE",
      },
      {
        CITY: "GUILDFORD",
      },
      {
        CITY: "GUISBOROUGH",
      },
      {
        CITY: "GUISELEY",
      },
      {
        CITY: "GULLANE",
      },
      {
        CITY: "GUNNISLAKE",
      },
      {
        CITY: "GUTHRIE",
      },
      {
        CITY: "HACKBRIDGE",
      },
      {
        CITY: "HACKNEY",
      },
      {
        CITY: "HADDENHAM",
      },
      {
        CITY: "HADDINGTON",
      },
      {
        CITY: "HADLEIGH",
      },
      {
        CITY: "HADLOW",
      },
      {
        CITY: "HADLOW DOWN",
      },
      {
        CITY: "HAGLEY",
      },
      {
        CITY: "HAILSHAM",
      },
      {
        CITY: "HALESOWEN",
      },
      {
        CITY: "HALESWORTH",
      },
      {
        CITY: "HALEWOOD",
      },
      {
        CITY: "HALIFAX",
      },
      {
        CITY: "HALSTEAD",
      },
      {
        CITY: "HALTON",
      },
      {
        CITY: "HALTWHISTLE",
      },
      {
        CITY: "HALWELL",
      },
      {
        CITY: "HAMBLE",
      },
      {
        CITY: "HAMBLEDEN",
      },
      {
        CITY: "HAMBLETON",
      },
      {
        CITY: "HAMILTON",
      },
      {
        CITY: "HAMMERSMITH",
      },
      {
        CITY: "HAMPTON",
      },
      {
        CITY: "HAMPTON LUCY",
      },
      {
        CITY: "HANDCROSS",
      },
      {
        CITY: "HANDFORTH",
      },
      {
        CITY: "HANDSWORTH",
      },
      {
        CITY: "HANLEY",
      },
      {
        CITY: "HANWELL",
      },
      {
        CITY: "HANWORTH",
      },
      {
        CITY: "HAPTON",
      },
      {
        CITY: "HARBY",
      },
      {
        CITY: "HARDHAM",
      },
      {
        CITY: "HAREFIELD",
      },
      {
        CITY: "HARLAXTON",
      },
      {
        CITY: "HARLECH",
      },
      {
        CITY: "HARLESDEN",
      },
      {
        CITY: "HARLESTON",
      },
      {
        CITY: "HARLOW",
      },
      {
        CITY: "HAROLD WOOD",
      },
      {
        CITY: "HARPENDEN",
      },
      {
        CITY: "HARROGATE",
      },
      {
        CITY: "HARROLD",
      },
      {
        CITY: "HARROW",
      },
      {
        CITY: "HARROW ON THE HILL",
      },
      {
        CITY: "HARROW WEALD",
      },
      {
        CITY: "HARTFIELD",
      },
      {
        CITY: "HARTFORD",
      },
      {
        CITY: "HARTLEPOOL",
      },
      {
        CITY: "HARTLEY",
      },
      {
        CITY: "HARTPURY",
      },
      {
        CITY: "HARTWELL",
      },
      {
        CITY: "HARWELL",
      },
      {
        CITY: "HARWICH",
      },
      {
        CITY: "HARWORTH",
      },
      {
        CITY: "HASLEMERE",
      },
      {
        CITY: "HASLINGDEN",
      },
      {
        CITY: "HASSOCKS",
      },
      {
        CITY: "HASTINGS",
      },
      {
        CITY: "HATCH",
      },
      {
        CITY: "HATFIELD",
      },
      {
        CITY: "HATHERLEIGH",
      },
      {
        CITY: "HATHERSAGE",
      },
      {
        CITY: "HATTON",
      },
      {
        CITY: "HAVANT",
      },
      {
        CITY: "HAVERFORDWEST",
      },
      {
        CITY: "HAVERHILL",
      },
      {
        CITY: "HAVERING ATTE BOWER",
      },
      {
        CITY: "HAWARDEN",
      },
      {
        CITY: "HAWES",
      },
      {
        CITY: "HAWICK",
      },
      {
        CITY: "HAWKHURST",
      },
      {
        CITY: "HAWKWELL",
      },
      {
        CITY: "HAWLEY",
      },
      {
        CITY: "HAYDOCK",
      },
      {
        CITY: "HAYDON BRIDGE",
      },
      {
        CITY: "HAYES",
      },
      {
        CITY: "HAYLE",
      },
      {
        CITY: "HAYWARDS HEATH",
      },
      {
        CITY: "HAZEL GROVE",
      },
      {
        CITY: "HAZELWOOD",
      },
      {
        CITY: "HEADCORN",
      },
      {
        CITY: "HEADINGTON",
      },
      {
        CITY: "HEADLEY",
      },
      {
        CITY: "HEANOR",
      },
      {
        CITY: "HEATH",
      },
      {
        CITY: "HEATHFIELD",
      },
      {
        CITY: "HEATHROW",
      },
      {
        CITY: "HEBBURN",
      },
      {
        CITY: "HEBDEN BRIDGE",
      },
      {
        CITY: "HECKINGTON",
      },
      {
        CITY: "HECKMONDWIKE",
      },
      {
        CITY: "HEDGERLEY",
      },
      {
        CITY: "HEDNESFORD",
      },
      {
        CITY: "HEDON",
      },
      {
        CITY: "HELENS BAY",
      },
      {
        CITY: "HELENSBURGH",
      },
      {
        CITY: "HELLESDON",
      },
      {
        CITY: "HELMSLEY",
      },
      {
        CITY: "HELSTON",
      },
      {
        CITY: "HEMEL HEMPSTEAD",
      },
      {
        CITY: "HEMINGSTONE",
      },
      {
        CITY: "HEMSWELL",
      },
      {
        CITY: "HEMSWORTH",
      },
      {
        CITY: "HENDON",
      },
      {
        CITY: "HENFIELD",
      },
      {
        CITY: "HENGOED",
      },
      {
        CITY: "HENHAM",
      },
      {
        CITY: "HENLEY",
      },
      {
        CITY: "HENLEY-ON-THAMES",
      },
      {
        CITY: "HENLOW",
      },
      {
        CITY: "HENNOCK",
      },
      {
        CITY: "HENSTRIDGE",
      },
      {
        CITY: "HEREFORD",
      },
      {
        CITY: "HERIOT",
      },
      {
        CITY: "HERMITAGE",
      },
      {
        CITY: "HERNE BAY",
      },
      {
        CITY: "HERRIARD",
      },
      {
        CITY: "HERSHAM",
      },
      {
        CITY: "HERSTMONCEUX",
      },
      {
        CITY: "HERTFORD",
      },
      {
        CITY: "HESSLE",
      },
      {
        CITY: "HESTON",
      },
      {
        CITY: "HESWALL",
      },
      {
        CITY: "HEVER",
      },
      {
        CITY: "HEXHAM",
      },
      {
        CITY: "HEYBRIDGE",
      },
      {
        CITY: "HEYSHAM",
      },
      {
        CITY: "HEYTHROP",
      },
      {
        CITY: "HEYWOOD",
      },
      {
        CITY: "HIGH BENTHAM",
      },
      {
        CITY: "HIGH BLANTYRE",
      },
      {
        CITY: "HIGH HALDEN",
      },
      {
        CITY: "HIGH LEGH",
      },
      {
        CITY: "HIGH PEAK",
      },
      {
        CITY: "HIGH PEAK JUNCTION",
      },
      {
        CITY: "HIGH WYCOMBE",
      },
      {
        CITY: "HIGHAM FERRERS",
      },
      {
        CITY: "HIGHAM ON THE HILL",
      },
      {
        CITY: "HIGHBRIDGE",
      },
      {
        CITY: "HIGHBURY",
      },
      {
        CITY: "HIGHCLIFFE",
      },
      {
        CITY: "HIGHER BEBINGTON",
      },
      {
        CITY: "HIGHTOWN",
      },
      {
        CITY: "HIGHWAY",
      },
      {
        CITY: "HIGHWORTH",
      },
      {
        CITY: "HILBOROUGH",
      },
      {
        CITY: "HILDERSTONE",
      },
      {
        CITY: "HILL",
      },
      {
        CITY: "HILLINGDON",
      },
      {
        CITY: "HILLSBOROUGH",
      },
      {
        CITY: "HILLSIDE",
      },
      {
        CITY: "HILTON",
      },
      {
        CITY: "HINCKLEY",
      },
      {
        CITY: "HINDHEAD",
      },
      {
        CITY: "HINDLEY",
      },
      {
        CITY: "HINDON",
      },
      {
        CITY: "HINGHAM",
      },
      {
        CITY: "HINTON ST GEORGE",
      },
      {
        CITY: "HISTON",
      },
      {
        CITY: "HITCHAM",
      },
      {
        CITY: "HITCHIN",
      },
      {
        CITY: "HOCKLEY",
      },
      {
        CITY: "HODDESDON",
      },
      {
        CITY: "HOLBEACH",
      },
      {
        CITY: "HOLBORN",
      },
      {
        CITY: "HOLMES CHAPEL",
      },
      {
        CITY: "HOLMEWOOD",
      },
      {
        CITY: "HOLMFIRTH",
      },
      {
        CITY: "HOLSWORTHY",
      },
      {
        CITY: "HOLT",
      },
      {
        CITY: "HOLYHEAD",
      },
      {
        CITY: "HOLYWELL",
      },
      {
        CITY: "HOLYWOOD",
      },
      {
        CITY: "HONINGHAM",
      },
      {
        CITY: "HONITON",
      },
      {
        CITY: "HOOK",
      },
      {
        CITY: "HOOKE",
      },
      {
        CITY: "HOPWOOD",
      },
      {
        CITY: "HORAM",
      },
      {
        CITY: "HORBLING",
      },
      {
        CITY: "HORBURY",
      },
      {
        CITY: "HORLEY",
      },
      {
        CITY: "HORNCASTLE",
      },
      {
        CITY: "HORNCHURCH",
      },
      {
        CITY: "HORNDEAN",
      },
      {
        CITY: "HORNDON ON THE HILL",
      },
      {
        CITY: "HORNSEA",
      },
      {
        CITY: "HORNSEY",
      },
      {
        CITY: "HORRABRIDGE",
      },
      {
        CITY: "HORSHAM",
      },
      {
        CITY: "HORSMONDEN",
      },
      {
        CITY: "HORSTED KEYNES",
      },
      {
        CITY: "HORTON KIRBY",
      },
      {
        CITY: "HORWICH",
      },
      {
        CITY: "HOTHAM",
      },
      {
        CITY: "HOUGHTON ON THE HILL",
      },
      {
        CITY: "HOUGHTON REGIS",
      },
      {
        CITY: "HOUGHTON-LE-SPRING",
      },
      {
        CITY: "HOUSTON",
      },
      {
        CITY: "HOVE",
      },
      {
        CITY: "HOWDEN",
      },
      {
        CITY: "HOYLAKE",
      },
      {
        CITY: "HUCKNALL TORKARD",
      },
      {
        CITY: "HUCKNALL UNDER HUTHWAITE",
      },
      {
        CITY: "HUDDERSFIELD",
      },
      {
        CITY: "HUISH",
      },
      {
        CITY: "HULL",
      },
      {
        CITY: "HUMBERSTON",
      },
      {
        CITY: "HUMBIE",
      },
      {
        CITY: "HUNGERFORD",
      },
      {
        CITY: "HUNSTANTON",
      },
      {
        CITY: "HUNTINGDON",
      },
      {
        CITY: "HUNTLY",
      },
      {
        CITY: "HUNTSPILL",
      },
      {
        CITY: "HURSLEY",
      },
      {
        CITY: "HURSTBOURNE TARRANT",
      },
      {
        CITY: "HURSTPIERPOINT",
      },
      {
        CITY: "HURWORTH",
      },
      {
        CITY: "HUYTON",
      },
      {
        CITY: "HYDE",
      },
      {
        CITY: "HYTHE",
      },
      {
        CITY: "IBSTOCK",
      },
      {
        CITY: "ICKENHAM",
      },
      {
        CITY: "IFIELD",
      },
      {
        CITY: "ILCHESTER",
      },
      {
        CITY: "ILFORD",
      },
      {
        CITY: "ILFRACOMBE",
      },
      {
        CITY: "ILKESTON",
      },
      {
        CITY: "ILKLEY",
      },
      {
        CITY: "ILMINSTER",
      },
      {
        CITY: "IMMINGHAM",
      },
      {
        CITY: "INCHINNAN",
      },
      {
        CITY: "INGATESTONE",
      },
      {
        CITY: "INNERLEITHEN",
      },
      {
        CITY: "INSCH",
      },
      {
        CITY: "INVERARAY",
      },
      {
        CITY: "INVERGORDON",
      },
      {
        CITY: "INVERKEILOR",
      },
      {
        CITY: "INVERKEITHING",
      },
      {
        CITY: "INVERKIP",
      },
      {
        CITY: "INVERNESS",
      },
      {
        CITY: "INVERNESS-SHIRE",
      },
      {
        CITY: "INVERURIE",
      },
      {
        CITY: "IPSWICH",
      },
      {
        CITY: "IRLAM",
      },
      {
        CITY: "IRTHLINGBOROUGH",
      },
      {
        CITY: "IRVINE",
      },
      {
        CITY: "ISHAM",
      },
      {
        CITY: "ISLEHAM",
      },
      {
        CITY: "ISLEWORTH",
      },
      {
        CITY: "ISLINGTON",
      },
      {
        CITY: "ISLIP",
      },
      {
        CITY: "ITCHEN",
      },
      {
        CITY: "ITCHEN ABBAS",
      },
      {
        CITY: "IVER",
      },
      {
        CITY: "IVYBRIDGE",
      },
      {
        CITY: "IWERNE COURTNEY",
      },
      {
        CITY: "JARROW",
      },
      {
        CITY: "JEDBURGH",
      },
      {
        CITY: "JOHNSTONE",
      },
      {
        CITY: "JORDANSTOWN",
      },
      {
        CITY: "JUNIPER GREEN",
      },
      {
        CITY: "KEDINGTON",
      },
      {
        CITY: "KEELE",
      },
      {
        CITY: "KEIGHLEY",
      },
      {
        CITY: "KEITH",
      },
      {
        CITY: "KELBROOK",
      },
      {
        CITY: "KELLY",
      },
      {
        CITY: "KELMARSH",
      },
      {
        CITY: "KELSALL",
      },
      {
        CITY: "KELSO",
      },
      {
        CITY: "KELTY",
      },
      {
        CITY: "KELVEDON",
      },
      {
        CITY: "KEMPSTON",
      },
      {
        CITY: "KENDAL",
      },
      {
        CITY: "KENILWORTH",
      },
      {
        CITY: "KENLEY",
      },
      {
        CITY: "KENNINGTON",
      },
      {
        CITY: "KENNOWAY",
      },
      {
        CITY: "KENSINGTON",
      },
      {
        CITY: "KENT",
      },
      {
        CITY: "KERESLEY",
      },
      {
        CITY: "KESTON",
      },
      {
        CITY: "KESWICK",
      },
      {
        CITY: "KETLEY",
      },
      {
        CITY: "KETTERING",
      },
      {
        CITY: "KEYNSHAM",
      },
      {
        CITY: "KIBWORTH HARCOURT",
      },
      {
        CITY: "KIDDERMINSTER",
      },
      {
        CITY: "KIDLINGTON",
      },
      {
        CITY: "KIDSGROVE",
      },
      {
        CITY: "KIDWELLY",
      },
      {
        CITY: "KILBARCHAN",
      },
      {
        CITY: "KILBIRNIE",
      },
      {
        CITY: "KILBRIDE",
      },
      {
        CITY: "KILKEEL",
      },
      {
        CITY: "KILLAMARSH",
      },
      {
        CITY: "KILLIN",
      },
      {
        CITY: "KILMACOLM",
      },
      {
        CITY: "KILMARNOCK",
      },
      {
        CITY: "KILSYTH",
      },
      {
        CITY: "KILWINNING",
      },
      {
        CITY: "KIMBERLEY",
      },
      {
        CITY: "KIMBOLTON",
      },
      {
        CITY: "KINGHAM",
      },
      {
        CITY: "KINGHORN",
      },
      {
        CITY: "KINGLASSIE",
      },
      {
        CITY: "KINGS LANGLEY",
      },
      {
        CITY: "KINGS LYNN",
      },
      {
        CITY: "KINGS NORTON",
      },
      {
        CITY: "KINGS SUTTON",
      },
      {
        CITY: "KINGSBRIDGE",
      },
      {
        CITY: "KINGSKERSWELL",
      },
      {
        CITY: "KINGSLAND",
      },
      {
        CITY: "KINGSTEIGNTON",
      },
      {
        CITY: "KINGSTON",
      },
      {
        CITY: "KINGSTON SEYMOUR",
      },
      {
        CITY: "KINGSWINFORD",
      },
      {
        CITY: "KINGSWOOD",
      },
      {
        CITY: "KINGUSSIE",
      },
      {
        CITY: "KINLOCH RANNOCH",
      },
      {
        CITY: "KINMEL",
      },
      {
        CITY: "KINNERLEY",
      },
      {
        CITY: "KINROSS",
      },
      {
        CITY: "KIRBY CROSS",
      },
      {
        CITY: "KIRK ELLA",
      },
      {
        CITY: "KIRKBY",
      },
      {
        CITY: "KIRKBY IN ASHFIELD",
      },
      {
        CITY: "KIRKBY STEPHEN",
      },
      {
        CITY: "KIRKCALDY",
      },
      {
        CITY: "KIRKCONNEL",
      },
      {
        CITY: "KIRKCUDBRIGHT",
      },
      {
        CITY: "KIRKHAM",
      },
      {
        CITY: "KIRKINTILLOCH",
      },
      {
        CITY: "KIRKLISTON",
      },
      {
        CITY: "KIRKWALL",
      },
      {
        CITY: "KIRRIEMUIR",
      },
      {
        CITY: "KIRTLINGTON",
      },
      {
        CITY: "KIRTON IN LINDSEY",
      },
      {
        CITY: "KNARESBOROUGH",
      },
      {
        CITY: "KNEBWORTH",
      },
      {
        CITY: "KNEETON",
      },
      {
        CITY: "KNIGHTON",
      },
      {
        CITY: "KNOTTINGLEY",
      },
      {
        CITY: "KNOWSLEY",
      },
      {
        CITY: "KNUTSFORD",
      },
      {
        CITY: "KYLE OF LOCHALSH",
      },
      {
        CITY: "LAINDON",
      },
      {
        CITY: "LAKENHEATH",
      },
      {
        CITY: "LAMBETH",
      },
      {
        CITY: "LAMBOURN",
      },
      {
        CITY: "LAMPETER",
      },
      {
        CITY: "LANARK",
      },
      {
        CITY: "LANCASTER",
      },
      {
        CITY: "LANCING",
      },
      {
        CITY: "LANDRAKE",
      },
      {
        CITY: "LANGHO",
      },
      {
        CITY: "LANGLEY",
      },
      {
        CITY: "LANGPORT",
      },
      {
        CITY: "LANGSTONE",
      },
      {
        CITY: "LAPWORTH",
      },
      {
        CITY: "LARBERT",
      },
      {
        CITY: "LARGS",
      },
      {
        CITY: "LARKHALL",
      },
      {
        CITY: "LARNE",
      },
      {
        CITY: "LAUDER",
      },
      {
        CITY: "LAUGHARNE",
      },
      {
        CITY: "LAUNCESTON",
      },
      {
        CITY: "LAURENCEKIRK",
      },
      {
        CITY: "LAVANT",
      },
      {
        CITY: "LAVENDON",
      },
      {
        CITY: "LAWRENCETOWN",
      },
      {
        CITY: "LAXFIELD",
      },
      {
        CITY: "LAXTON",
      },
      {
        CITY: "LEATHERHEAD",
      },
      {
        CITY: "LECHLADE",
      },
      {
        CITY: "LECONFIELD",
      },
      {
        CITY: "LEDBURY",
      },
      {
        CITY: "LEE",
      },
      {
        CITY: "LEE-ON-THE-SOLENT",
      },
      {
        CITY: "LEEDS",
      },
      {
        CITY: "LEEK",
      },
      {
        CITY: "LEEK WOOTTON",
      },
      {
        CITY: "LEICESTER",
      },
      {
        CITY: "LEIGH",
      },
      {
        CITY: "LEIGH-ON-SEA",
      },
      {
        CITY: "LEIGHTON BUZZARD",
      },
      {
        CITY: "LEISTON",
      },
      {
        CITY: "LEITHOLM",
      },
      {
        CITY: "LENHAM",
      },
      {
        CITY: "LEOMINSTER",
      },
      {
        CITY: "LERWICK",
      },
      {
        CITY: "LESMAHAGOW",
      },
      {
        CITY: "LETCHWORTH",
      },
      {
        CITY: "LEUCHARS",
      },
      {
        CITY: "LEVEN",
      },
      {
        CITY: "LEVENSHULME",
      },
      {
        CITY: "LEWES",
      },
      {
        CITY: "LEWISHAM",
      },
      {
        CITY: "LEYBURN",
      },
      {
        CITY: "LEYLAND",
      },
      {
        CITY: "LEYSDOWN-ON-SEA",
      },
      {
        CITY: "LEYTON",
      },
      {
        CITY: "LICHFIELD",
      },
      {
        CITY: "LIDLINGTON",
      },
      {
        CITY: "LIFTON",
      },
      {
        CITY: "LIMAVADY",
      },
      {
        CITY: "LIMEKILNS",
      },
      {
        CITY: "LINCOLN",
      },
      {
        CITY: "LINDAL IN FURNESS",
      },
      {
        CITY: "LINDFIELD",
      },
      {
        CITY: "LINGFIELD",
      },
      {
        CITY: "LINLITHGOW",
      },
      {
        CITY: "LINTHWAITE",
      },
      {
        CITY: "LINTON",
      },
      {
        CITY: "LINTON UPON OUSE",
      },
      {
        CITY: "LIPHOOK",
      },
      {
        CITY: "LISBURN",
      },
      {
        CITY: "LISKEARD",
      },
      {
        CITY: "LISNASKEA",
      },
      {
        CITY: "LISS",
      },
      {
        CITY: "LITHERLAND",
      },
      {
        CITY: "LITTLE BARNINGHAM",
      },
      {
        CITY: "LITTLE CANFIELD",
      },
      {
        CITY: "LITTLE EATON",
      },
      {
        CITY: "LITTLE GADDESDEN",
      },
      {
        CITY: "LITTLE HULTON",
      },
      {
        CITY: "LITTLE KIMBLE",
      },
      {
        CITY: "LITTLE LEVER",
      },
      {
        CITY: "LITTLE MILTON",
      },
      {
        CITY: "LITTLE PAXTON",
      },
      {
        CITY: "LITTLEBOROUGH",
      },
      {
        CITY: "LITTLEBURY",
      },
      {
        CITY: "LITTLEHAMPTON",
      },
      {
        CITY: "LITTLEPORT",
      },
      {
        CITY: "LIVERPOOL",
      },
      {
        CITY: "LIVERSEDGE",
      },
      {
        CITY: "LIVINGSTON",
      },
      {
        CITY: "LLANBEDR",
      },
      {
        CITY: "LLANDDULAS",
      },
      {
        CITY: "LLANDEILO",
      },
      {
        CITY: "LLANDOVERY",
      },
      {
        CITY: "LLANDRILLO",
      },
      {
        CITY: "LLANDRINDOD WELLS",
      },
      {
        CITY: "LLANDUDNO",
      },
      {
        CITY: "LLANDUDNO JUNCTION",
      },
      {
        CITY: "LLANELLI",
      },
      {
        CITY: "LLANFAIR-DYFFRYN-CLWYD",
      },
      {
        CITY: "LLANFAIRFECHAN",
      },
      {
        CITY: "LLANFYLLIN",
      },
      {
        CITY: "LLANFYRNACH",
      },
      {
        CITY: "LLANGATTOCK",
      },
      {
        CITY: "LLANGEFNI",
      },
      {
        CITY: "LLANGENNECH",
      },
      {
        CITY: "LLANGOLLEN",
      },
      {
        CITY: "LLANHARAN",
      },
      {
        CITY: "LLANIDLOES",
      },
      {
        CITY: "LLANISHEN",
      },
      {
        CITY: "LLANON",
      },
      {
        CITY: "LLANRWST",
      },
      {
        CITY: "LLANSANTFFRAID-YM-MECHAIN",
      },
      {
        CITY: "LLANTRISANT",
      },
      {
        CITY: "LLANTWIT FARDRE",
      },
      {
        CITY: "LLANTWIT MAJOR",
      },
      {
        CITY: "LLANWRDA",
      },
      {
        CITY: "LLANWRTYD WELLS",
      },
      {
        CITY: "LLANYMYNECH",
      },
      {
        CITY: "LLWYNGWRIL",
      },
      {
        CITY: "LLWYNYPIA",
      },
      {
        CITY: "LOANHEAD",
      },
      {
        CITY: "LOCHGELLY",
      },
      {
        CITY: "LOCHGILPHEAD",
      },
      {
        CITY: "LOCHMADDY",
      },
      {
        CITY: "LOCHWINNOCH",
      },
      {
        CITY: "LOCKERBIE",
      },
      {
        CITY: "LODDINGTON",
      },
      {
        CITY: "LONDON",
      },
      {
        CITY: "LONDON BOROUGH OF BROMLEY",
      },
      {
        CITY: "LONDON BOROUGH OF HOUNSLOW",
      },
      {
        CITY: "LONDON BOROUGH OF WANDSWORTH",
      },
      {
        CITY: "LONDONDERRY",
      },
      {
        CITY: "LONG BUCKBY",
      },
      {
        CITY: "LONG DITTON",
      },
      {
        CITY: "LONG EATON",
      },
      {
        CITY: "LONG MELFORD",
      },
      {
        CITY: "LONG STRATTON",
      },
      {
        CITY: "LONGDOWN",
      },
      {
        CITY: "LONGFIELD",
      },
      {
        CITY: "LONGHOPE",
      },
      {
        CITY: "LONGNIDDRY",
      },
      {
        CITY: "LONGPORT",
      },
      {
        CITY: "LONGRIDGE",
      },
      {
        CITY: "LONGSTANTON",
      },
      {
        CITY: "LONGTON",
      },
      {
        CITY: "LOOE",
      },
      {
        CITY: "LOPPINGTON",
      },
      {
        CITY: "LOSSIEMOUTH",
      },
      {
        CITY: "LOSTWITHIEL",
      },
      {
        CITY: "LOUDWATER",
      },
      {
        CITY: "LOUGHBOROUGH",
      },
      {
        CITY: "LOUGHOR",
      },
      {
        CITY: "LOUGHTON",
      },
      {
        CITY: "LOUTH",
      },
      {
        CITY: "LOW HAM",
      },
      {
        CITY: "LOWDHAM",
      },
      {
        CITY: "LOWER BEEDING",
      },
      {
        CITY: "LOWER BRAILES",
      },
      {
        CITY: "LOWER DARWEN",
      },
      {
        CITY: "LOWESTOFT",
      },
      {
        CITY: "LOWTON",
      },
      {
        CITY: "LUBENHAM",
      },
      {
        CITY: "LUDLOW",
      },
      {
        CITY: "LUPTON",
      },
      {
        CITY: "LURGAN",
      },
      {
        CITY: "LUSTLEIGH",
      },
      {
        CITY: "LUTON",
      },
      {
        CITY: "LUTTERWORTH",
      },
      {
        CITY: "LYDBURY NORTH",
      },
      {
        CITY: "LYDNEY",
      },
      {
        CITY: "LYME REGIS",
      },
      {
        CITY: "LYMINGE",
      },
      {
        CITY: "LYMINGTON",
      },
      {
        CITY: "LYMM",
      },
      {
        CITY: "LYMPSHAM",
      },
      {
        CITY: "LYNDHURST",
      },
      {
        CITY: "LYNG",
      },
      {
        CITY: "LYTCHETT MINSTER",
      },
      {
        CITY: "LYTHAM",
      },
      {
        CITY: "MABLETHORPE",
      },
      {
        CITY: "MACCLESFIELD",
      },
      {
        CITY: "MACDUFF",
      },
      {
        CITY: "MACHYNLLETH",
      },
      {
        CITY: "MAERDY",
      },
      {
        CITY: "MAESTEG",
      },
      {
        CITY: "MAGHERA",
      },
      {
        CITY: "MAGHERAFELT",
      },
      {
        CITY: "MAGHERALIN",
      },
      {
        CITY: "MAGHULL",
      },
      {
        CITY: "MAIDA VALE",
      },
      {
        CITY: "MAIDEN NEWTON",
      },
      {
        CITY: "MAIDENHEAD",
      },
      {
        CITY: "MAIDSTONE",
      },
      {
        CITY: "MALDEN",
      },
      {
        CITY: "MALDON",
      },
      {
        CITY: "MALLAIG",
      },
      {
        CITY: "MALMESBURY",
      },
      {
        CITY: "MALPAS",
      },
      {
        CITY: "MALTON",
      },
      {
        CITY: "MALVERN",
      },
      {
        CITY: "MANCHESTER",
      },
      {
        CITY: "MANNINGTREE",
      },
      {
        CITY: "MANOR",
      },
      {
        CITY: "MANSFIELD",
      },
      {
        CITY: "MANSFIELD WOODHOUSE",
      },
      {
        CITY: "MANTON",
      },
      {
        CITY: "MARCH",
      },
      {
        CITY: "MARCHAM",
      },
      {
        CITY: "MARDEN",
      },
      {
        CITY: "MARGATE",
      },
      {
        CITY: "MARHAMCHURCH",
      },
      {
        CITY: "MARK",
      },
      {
        CITY: "MARKET DEEPING",
      },
      {
        CITY: "MARKET DRAYTON",
      },
      {
        CITY: "MARKET HARBOROUGH",
      },
      {
        CITY: "MARKET RASEN",
      },
      {
        CITY: "MARKET WEIGHTON",
      },
      {
        CITY: "MARKFIELD",
      },
      {
        CITY: "MARLBOROUGH",
      },
      {
        CITY: "MARLOW",
      },
      {
        CITY: "MARNHULL",
      },
      {
        CITY: "MARPLE",
      },
      {
        CITY: "MARR",
      },
      {
        CITY: "MARSHAM",
      },
      {
        CITY: "MARSKE",
      },
      {
        CITY: "MARTIN",
      },
      {
        CITY: "MARTLEY",
      },
      {
        CITY: "MARTOCK",
      },
      {
        CITY: "MARYHILL",
      },
      {
        CITY: "MARYPORT",
      },
      {
        CITY: "MASHAM",
      },
      {
        CITY: "MATCHING",
      },
      {
        CITY: "MATLOCK",
      },
      {
        CITY: "MATTISHALL",
      },
      {
        CITY: "MAUCHLINE",
      },
      {
        CITY: "MAULDEN",
      },
      {
        CITY: "MAYBOLE",
      },
      {
        CITY: "MAYFAIR",
      },
      {
        CITY: "MAYFIELD",
      },
      {
        CITY: "MEANWOOD",
      },
      {
        CITY: "MEASHAM",
      },
      {
        CITY: "MEDMENHAM",
      },
      {
        CITY: "MEDSTEAD",
      },
      {
        CITY: "MEESDEN",
      },
      {
        CITY: "MEGGIES",
      },
      {
        CITY: "MEIFOD",
      },
      {
        CITY: "MELBOURNE",
      },
      {
        CITY: "MELDRETH",
      },
      {
        CITY: "MELKSHAM",
      },
      {
        CITY: "MELLS",
      },
      {
        CITY: "MELROSE",
      },
      {
        CITY: "MELTON",
      },
      {
        CITY: "MELTON CONSTABLE",
      },
      {
        CITY: "MELTON MOWBRAY",
      },
      {
        CITY: "MENAI BRIDGE",
      },
      {
        CITY: "MENDLESHAM",
      },
      {
        CITY: "MENHENIOT",
      },
      {
        CITY: "MENSTON",
      },
      {
        CITY: "MEOPHAM",
      },
      {
        CITY: "MERE",
      },
      {
        CITY: "MERTHYR MAWR",
      },
      {
        CITY: "MERTHYR TYDFIL",
      },
      {
        CITY: "MERTON",
      },
      {
        CITY: "METHERINGHAM",
      },
      {
        CITY: "METHIL",
      },
      {
        CITY: "MEXBOROUGH",
      },
      {
        CITY: "MICKLETON",
      },
      {
        CITY: "MID CALDER",
      },
      {
        CITY: "MIDDLESBROUGH",
      },
      {
        CITY: "MIDDLETON",
      },
      {
        CITY: "MIDDLETON ONE ROW",
      },
      {
        CITY: "MIDDLEWICH",
      },
      {
        CITY: "MIDFORD",
      },
      {
        CITY: "MIDGHAM",
      },
      {
        CITY: "MIDHURST",
      },
      {
        CITY: "MIDSOMER NORTON",
      },
      {
        CITY: "MILDENHALL",
      },
      {
        CITY: "MILFORD",
      },
      {
        CITY: "MILFORD HAVEN",
      },
      {
        CITY: "MILFORD ON SEA",
      },
      {
        CITY: "MILL HILL",
      },
      {
        CITY: "MILLBROOK",
      },
      {
        CITY: "MILLOM",
      },
      {
        CITY: "MILLTIMBER",
      },
      {
        CITY: "MILNATHORT",
      },
      {
        CITY: "MILNTHORPE",
      },
      {
        CITY: "MILTON",
      },
      {
        CITY: "MILTON KEYNES",
      },
      {
        CITY: "MILTON ON STOUR",
      },
      {
        CITY: "MINCHINHAMPTON",
      },
      {
        CITY: "MINEHEAD",
      },
      {
        CITY: "MINSTER",
      },
      {
        CITY: "MINSTER LOVELL",
      },
      {
        CITY: "MINSTERLEY",
      },
      {
        CITY: "MIRFIELD",
      },
      {
        CITY: "MITCHAM",
      },
      {
        CITY: "MITCHELDEAN",
      },
      {
        CITY: "MOBBERLEY",
      },
      {
        CITY: "MOCHDRE",
      },
      {
        CITY: "MOIRA",
      },
      {
        CITY: "MOLD",
      },
      {
        CITY: "MOLESEY",
      },
      {
        CITY: "MOLLINGTON",
      },
      {
        CITY: "MONEYMORE",
      },
      {
        CITY: "MONIFIETH",
      },
      {
        CITY: "MONKSWOOD",
      },
      {
        CITY: "MONKTON",
      },
      {
        CITY: "MONMOUTH",
      },
      {
        CITY: "MONTGOMERY",
      },
      {
        CITY: "MONTROSE",
      },
      {
        CITY: "MONYASH",
      },
      {
        CITY: "MOORSHOLM",
      },
      {
        CITY: "MOORSIDE",
      },
      {
        CITY: "MORDEN",
      },
      {
        CITY: "MORE",
      },
      {
        CITY: "MORECAMBE",
      },
      {
        CITY: "MORETON",
      },
      {
        CITY: "MORETON IN MARSH",
      },
      {
        CITY: "MORLEY",
      },
      {
        CITY: "MORPETH",
      },
      {
        CITY: "MORRISTON",
      },
      {
        CITY: "MOSELEY",
      },
      {
        CITY: "MOSS",
      },
      {
        CITY: "MOSSLEY",
      },
      {
        CITY: "MOSTON",
      },
      {
        CITY: "MOTHERWELL",
      },
      {
        CITY: "MOULTON",
      },
      {
        CITY: "MOULTON ST MICHAEL",
      },
      {
        CITY: "MOUNT BURES",
      },
      {
        CITY: "MOUNT HAMILTON",
      },
      {
        CITY: "MOUNTAIN ASH",
      },
      {
        CITY: "MOUNTSORREL",
      },
      {
        CITY: "MUCH HADHAM",
      },
      {
        CITY: "MUCH HOOLE",
      },
      {
        CITY: "MUCH WENLOCK",
      },
      {
        CITY: "MUIR OF ORD",
      },
      {
        CITY: "MUNDESLEY",
      },
      {
        CITY: "MURTON",
      },
      {
        CITY: "MUSSELBURGH",
      },
      {
        CITY: "MYDDLE",
      },
      {
        CITY: "MYTHOLMROYD",
      },
      {
        CITY: "MYTON ON SWALE",
      },
      {
        CITY: "NAFFERTON",
      },
      {
        CITY: "NAILSEA",
      },
      {
        CITY: "NAIRN",
      },
      {
        CITY: "NANTWICH",
      },
      {
        CITY: "NANTYGLO",
      },
      {
        CITY: "NAPTON ON THE HILL",
      },
      {
        CITY: "NARBERTH",
      },
      {
        CITY: "NASEBY",
      },
      {
        CITY: "NASH",
      },
      {
        CITY: "NASSINGTON",
      },
      {
        CITY: "NEASDEN",
      },
      {
        CITY: "NEATH",
      },
      {
        CITY: "NEEDINGWORTH",
      },
      {
        CITY: "NEILSTON",
      },
      {
        CITY: "NELSON",
      },
      {
        CITY: "NESTON",
      },
      {
        CITY: "NETTLEBED",
      },
      {
        CITY: "NETTLETON",
      },
      {
        CITY: "NEW BARNET",
      },
      {
        CITY: "NEW BUCKENHAM",
      },
      {
        CITY: "NEW CROSS",
      },
      {
        CITY: "NEW FERRY",
      },
      {
        CITY: "NEW MILLS",
      },
      {
        CITY: "NEW MILTON",
      },
      {
        CITY: "NEW QUAY",
      },
      {
        CITY: "NEW ROMNEY",
      },
      {
        CITY: "NEW SOUTHGATE",
      },
      {
        CITY: "NEW TREDEGAR",
      },
      {
        CITY: "NEWARK ON TRENT",
      },
      {
        CITY: "NEWBIGGIN-BY-THE-SEA",
      },
      {
        CITY: "NEWBOLD",
      },
      {
        CITY: "NEWBRIDGE",
      },
      {
        CITY: "NEWBURGH",
      },
      {
        CITY: "NEWBURY",
      },
      {
        CITY: "NEWCASTLE",
      },
      {
        CITY: "NEWCASTLE EMLYN",
      },
      {
        CITY: "NEWCASTLE UPON TYNE",
      },
      {
        CITY: "NEWCASTLE-UNDER-LYME",
      },
      {
        CITY: "NEWENT",
      },
      {
        CITY: "NEWHALL",
      },
      {
        CITY: "NEWHAM",
      },
      {
        CITY: "NEWHAVEN",
      },
      {
        CITY: "NEWICK",
      },
      {
        CITY: "NEWLANDS",
      },
      {
        CITY: "NEWMARKET",
      },
      {
        CITY: "NEWPORT",
      },
      {
        CITY: "NEWPORT PAGNELL",
      },
      {
        CITY: "NEWPORT-ON-TAY",
      },
      {
        CITY: "NEWQUAY",
      },
      {
        CITY: "NEWRY",
      },
      {
        CITY: "NEWTON ABBOT",
      },
      {
        CITY: "NEWTON AYCLIFFE",
      },
      {
        CITY: "NEWTON ON TRENT",
      },
      {
        CITY: "NEWTON STEWART",
      },
      {
        CITY: "NEWTON-LE-WILLOWS",
      },
      {
        CITY: "NEWTONMORE",
      },
      {
        CITY: "NEWTOWN",
      },
      {
        CITY: "NEWTOWNABBEY",
      },
      {
        CITY: "NEWTOWNARDS",
      },
      {
        CITY: "NORBURY",
      },
      {
        CITY: "NORDELPH",
      },
      {
        CITY: "NORHAM",
      },
      {
        CITY: "NORMANDY",
      },
      {
        CITY: "NORMANTON",
      },
      {
        CITY: "NORTH BERWICK",
      },
      {
        CITY: "NORTH ELMSALL",
      },
      {
        CITY: "NORTH FERRIBY",
      },
      {
        CITY: "NORTH HYKEHAM",
      },
      {
        CITY: "NORTH KILWORTH",
      },
      {
        CITY: "NORTH LEIGH",
      },
      {
        CITY: "NORTH MORETON",
      },
      {
        CITY: "NORTH NEWTON",
      },
      {
        CITY: "NORTH PETHERTON",
      },
      {
        CITY: "NORTH SHIELDS",
      },
      {
        CITY: "NORTH SOMERCOTES",
      },
      {
        CITY: "NORTH TAWTON",
      },
      {
        CITY: "NORTH WALSHAM",
      },
      {
        CITY: "NORTH WALTHAM",
      },
      {
        CITY: "NORTH WEALD",
      },
      {
        CITY: "NORTHALLERTON",
      },
      {
        CITY: "NORTHAMPTON",
      },
      {
        CITY: "NORTHENDEN",
      },
      {
        CITY: "NORTHFIELD",
      },
      {
        CITY: "NORTHFLEET",
      },
      {
        CITY: "NORTHLEACH",
      },
      {
        CITY: "NORTHOLT",
      },
      {
        CITY: "NORTHOP",
      },
      {
        CITY: "NORTHREPPS",
      },
      {
        CITY: "NORTHWICH",
      },
      {
        CITY: "NORTHWOOD",
      },
      {
        CITY: "NORTON",
      },
      {
        CITY: "NORTON LINDSEY",
      },
      {
        CITY: "NORWICH",
      },
      {
        CITY: "NORWOOD",
      },
      {
        CITY: "NOTTINGHAM",
      },
      {
        CITY: "NUFFIELD",
      },
      {
        CITY: "NUNEATON",
      },
      {
        CITY: "NUTFIELD",
      },
      {
        CITY: "NUTLEY",
      },
      {
        CITY: "OADBY",
      },
      {
        CITY: "OAKAMOOR",
      },
      {
        CITY: "OAKFORD",
      },
      {
        CITY: "OAKHAM",
      },
      {
        CITY: "OAKHILL",
      },
      {
        CITY: "OAKLEY",
      },
      {
        CITY: "OARE",
      },
      {
        CITY: "OBAN",
      },
      {
        CITY: "ODIHAM",
      },
      {
        CITY: "OFFORD CLUNY",
      },
      {
        CITY: "OKEHAMPTON",
      },
      {
        CITY: "OLD BASING",
      },
      {
        CITY: "OLD BUCKENHAM",
      },
      {
        CITY: "OLD COLWYN",
      },
      {
        CITY: "OLD MALTON",
      },
      {
        CITY: "OLD WINDSOR",
      },
      {
        CITY: "OLDBURY",
      },
      {
        CITY: "OLDHAM",
      },
      {
        CITY: "OLDMELDRUM",
      },
      {
        CITY: "OLNEY",
      },
      {
        CITY: "OMAGH",
      },
      {
        CITY: "ORMESBY",
      },
      {
        CITY: "ORMISTON",
      },
      {
        CITY: "ORMSKIRK",
      },
      {
        CITY: "ORPINGTON",
      },
      {
        CITY: "OSSETT",
      },
      {
        CITY: "OSWALDTWISTLE",
      },
      {
        CITY: "OSWESTRY",
      },
      {
        CITY: "OTFORD",
      },
      {
        CITY: "OTLEY",
      },
      {
        CITY: "OTTERBURN",
      },
      {
        CITY: "OTTERSHAW",
      },
      {
        CITY: "OTTERY ST MARY",
      },
      {
        CITY: "OULTON",
      },
      {
        CITY: "OUNDLE",
      },
      {
        CITY: "OVERTON",
      },
      {
        CITY: "OVING",
      },
      {
        CITY: "OVINGDEAN",
      },
      {
        CITY: "OWSLEBURY",
      },
      {
        CITY: "OXENHOPE",
      },
      {
        CITY: "OXFORD",
      },
      {
        CITY: "OXSHOTT",
      },
      {
        CITY: "OXTED",
      },
      {
        CITY: "PADBURY",
      },
      {
        CITY: "PADDOCK WOOD",
      },
      {
        CITY: "PADIHAM",
      },
      {
        CITY: "PADSTOW",
      },
      {
        CITY: "PAIGNTON",
      },
      {
        CITY: "PAINSWICK",
      },
      {
        CITY: "PAISLEY",
      },
      {
        CITY: "PALMERS GREEN",
      },
      {
        CITY: "PAMPISFORD",
      },
      {
        CITY: "PAPWORTH EVERARD",
      },
      {
        CITY: "PAR",
      },
      {
        CITY: "PARBOLD",
      },
      {
        CITY: "PARTINGTON",
      },
      {
        CITY: "PARTRIDGE GREEN",
      },
      {
        CITY: "PASTON",
      },
      {
        CITY: "PATCHWAY",
      },
      {
        CITY: "PATHHEAD",
      },
      {
        CITY: "PATRINGTON",
      },
      {
        CITY: "PAUL",
      },
      {
        CITY: "PEEBLES",
      },
      {
        CITY: "PELSALL",
      },
      {
        CITY: "PEMBROKE",
      },
      {
        CITY: "PEMBROKE DOCK",
      },
      {
        CITY: "PEMBURY",
      },
      {
        CITY: "PENARTH",
      },
      {
        CITY: "PENCADER",
      },
      {
        CITY: "PENCAITLAND",
      },
      {
        CITY: "PENCARREG",
      },
      {
        CITY: "PENCOED",
      },
      {
        CITY: "PENDLEBURY",
      },
      {
        CITY: "PENICUIK",
      },
      {
        CITY: "PENKRIDGE",
      },
      {
        CITY: "PENN",
      },
      {
        CITY: "PENNINGTON",
      },
      {
        CITY: "PENRICE",
      },
      {
        CITY: "PENRITH",
      },
      {
        CITY: "PENRYN",
      },
      {
        CITY: "PENSHURST",
      },
      {
        CITY: "PENTRAETH",
      },
      {
        CITY: "PENWORTHAM",
      },
      {
        CITY: "PENZANCE",
      },
      {
        CITY: "PERIVALE",
      },
      {
        CITY: "PERRANPORTH",
      },
      {
        CITY: "PERSHORE",
      },
      {
        CITY: "PERTH",
      },
      {
        CITY: "PETERBOROUGH",
      },
      {
        CITY: "PETERCULTER",
      },
      {
        CITY: "PETERHEAD",
      },
      {
        CITY: "PETERLEE",
      },
      {
        CITY: "PETERSFIELD",
      },
      {
        CITY: "PETWORTH",
      },
      {
        CITY: "PEVENSEY",
      },
      {
        CITY: "PEWSEY",
      },
      {
        CITY: "PICKERING",
      },
      {
        CITY: "PIDDINGTON",
      },
      {
        CITY: "PILHAM",
      },
      {
        CITY: "PILTON",
      },
      {
        CITY: "PINNER",
      },
      {
        CITY: "PINXTON",
      },
      {
        CITY: "PITLOCHRY",
      },
      {
        CITY: "PITSFORD",
      },
      {
        CITY: "PITTENWEEM",
      },
      {
        CITY: "PLAISTOW",
      },
      {
        CITY: "PLAITFORD",
      },
      {
        CITY: "PLESHEY",
      },
      {
        CITY: "PLOCKTON",
      },
      {
        CITY: "PLUMSTEAD",
      },
      {
        CITY: "PLYMOUTH",
      },
      {
        CITY: "PLYMSTOCK",
      },
      {
        CITY: "POCKLINGTON",
      },
      {
        CITY: "POLEGATE",
      },
      {
        CITY: "POLMONT",
      },
      {
        CITY: "POLPERRO",
      },
      {
        CITY: "PONDERS END",
      },
      {
        CITY: "PONTARDAWE",
      },
      {
        CITY: "PONTEFRACT",
      },
      {
        CITY: "PONTELAND",
      },
      {
        CITY: "PONTESBURY",
      },
      {
        CITY: "PONTYCYMER",
      },
      {
        CITY: "PONTYPOOL",
      },
      {
        CITY: "PONTYPRIDD",
      },
      {
        CITY: "POOLE",
      },
      {
        CITY: "POPLAR",
      },
      {
        CITY: "PORLOCK",
      },
      {
        CITY: "PORT ERROLL",
      },
      {
        CITY: "PORT GLASGOW",
      },
      {
        CITY: "PORT SUNLIGHT",
      },
      {
        CITY: "PORT TALBOT",
      },
      {
        CITY: "PORTADOWN",
      },
      {
        CITY: "PORTAFERRY",
      },
      {
        CITY: "PORTCHESTER",
      },
      {
        CITY: "PORTGLENONE",
      },
      {
        CITY: "PORTH",
      },
      {
        CITY: "PORTHCAWL",
      },
      {
        CITY: "PORTHLEVEN",
      },
      {
        CITY: "PORTISHEAD",
      },
      {
        CITY: "PORTLETHEN",
      },
      {
        CITY: "PORTMADOC",
      },
      {
        CITY: "PORTREE",
      },
      {
        CITY: "PORTRUSH",
      },
      {
        CITY: "PORTSLADE-BY-SEA",
      },
      {
        CITY: "PORTSMOUTH",
      },
      {
        CITY: "PORTSTEWART",
      },
      {
        CITY: "POSTLING",
      },
      {
        CITY: "POTTERS BAR",
      },
      {
        CITY: "POTTERSPURY",
      },
      {
        CITY: "POTTON",
      },
      {
        CITY: "POULTON LE FYLDE",
      },
      {
        CITY: "POWFOOT",
      },
      {
        CITY: "POWICK",
      },
      {
        CITY: "POYNTON",
      },
      {
        CITY: "PREES",
      },
      {
        CITY: "PREESALL",
      },
      {
        CITY: "PRESCOT",
      },
      {
        CITY: "PRESTATYN",
      },
      {
        CITY: "PRESTEIGNE",
      },
      {
        CITY: "PRESTON",
      },
      {
        CITY: "PRESTONPANS",
      },
      {
        CITY: "PRESTWICH",
      },
      {
        CITY: "PRESTWICK",
      },
      {
        CITY: "PRINCES RISBOROUGH",
      },
      {
        CITY: "PRINCETHORPE",
      },
      {
        CITY: "PRIVETT",
      },
      {
        CITY: "PRUDHOE",
      },
      {
        CITY: "PUCKERIDGE",
      },
      {
        CITY: "PUDSEY",
      },
      {
        CITY: "PULBOROUGH VILLAGE HALL",
      },
      {
        CITY: "PULLOXHILL",
      },
      {
        CITY: "PURFLEET",
      },
      {
        CITY: "PURLEIGH",
      },
      {
        CITY: "PURLEY",
      },
      {
        CITY: "PWLLHELI",
      },
      {
        CITY: "PYLE",
      },
      {
        CITY: "QUEDGELEY",
      },
      {
        CITY: "QUEENBOROUGH",
      },
      {
        CITY: "QUEENSFERRY",
      },
      {
        CITY: "QUINTON",
      },
      {
        CITY: "RADCLIFFE",
      },
      {
        CITY: "RADCLIFFE ON TRENT",
      },
      {
        CITY: "RADLETT",
      },
      {
        CITY: "RADNAGE",
      },
      {
        CITY: "RADSTOCK",
      },
      {
        CITY: "RAINFORD",
      },
      {
        CITY: "RAINHAM",
      },
      {
        CITY: "RAINHILL",
      },
      {
        CITY: "RAINWORTH",
      },
      {
        CITY: "RAMSBOTTOM",
      },
      {
        CITY: "RAMSBURY",
      },
      {
        CITY: "RAMSEY",
      },
      {
        CITY: "RAMSEY SAINT MARYS",
      },
      {
        CITY: "RAMSGATE",
      },
      {
        CITY: "RANDALSTOWN",
      },
      {
        CITY: "RATCLIFFE ON SOAR",
      },
      {
        CITY: "RATHFRILAND",
      },
      {
        CITY: "RAVENSTONE",
      },
      {
        CITY: "RAWMARSH",
      },
      {
        CITY: "RAWRETH",
      },
      {
        CITY: "RAWTENSTALL",
      },
      {
        CITY: "RAYLEIGH",
      },
      {
        CITY: "RAYNE",
      },
      {
        CITY: "RAYNES PARK",
      },
      {
        CITY: "READING",
      },
      {
        CITY: "REDBOURN",
      },
      {
        CITY: "REDCAR",
      },
      {
        CITY: "REDDISH",
      },
      {
        CITY: "REDDITCH",
      },
      {
        CITY: "REDHILL",
      },
      {
        CITY: "REDLAND",
      },
      {
        CITY: "REDRUTH",
      },
      {
        CITY: "REED",
      },
      {
        CITY: "REEPHAM",
      },
      {
        CITY: "REIGATE",
      },
      {
        CITY: "RENFREW",
      },
      {
        CITY: "RENTON",
      },
      {
        CITY: "REPTON",
      },
      {
        CITY: "REYDON",
      },
      {
        CITY: "RHAYADER",
      },
      {
        CITY: "RHONDDA",
      },
      {
        CITY: "RHOOSE",
      },
      {
        CITY: "RHOS-ON-SEA",
      },
      {
        CITY: "RHYL",
      },
      {
        CITY: "RICHMOND",
      },
      {
        CITY: "RICKMANSWORTH",
      },
      {
        CITY: "RIDINGMILL",
      },
      {
        CITY: "RIMINGTON",
      },
      {
        CITY: "RINGMER",
      },
      {
        CITY: "RINGWOOD",
      },
      {
        CITY: "RIPE",
      },
      {
        CITY: "RIPLEY",
      },
      {
        CITY: "RIPON",
      },
      {
        CITY: "RIPPONDEN",
      },
      {
        CITY: "RISCA",
      },
      {
        CITY: "RISLEY",
      },
      {
        CITY: "RIVER",
      },
      {
        CITY: "RIVINGTON",
      },
      {
        CITY: "ROADE",
      },
      {
        CITY: "ROATH",
      },
      {
        CITY: "ROBERTSBRIDGE",
      },
      {
        CITY: "ROCHDALE",
      },
      {
        CITY: "ROCHE",
      },
      {
        CITY: "ROCHESTER",
      },
      {
        CITY: "ROCHFORD",
      },
      {
        CITY: "ROCK",
      },
      {
        CITY: "ROCK FERRY",
      },
      {
        CITY: "ROEHAMPTON",
      },
      {
        CITY: "ROFFEY",
      },
      {
        CITY: "ROGERSTONE",
      },
      {
        CITY: "ROGIET",
      },
      {
        CITY: "ROMFORD",
      },
      {
        CITY: "ROMSEY",
      },
      {
        CITY: "ROSS ON WYE",
      },
      {
        CITY: "ROSSLEA",
      },
      {
        CITY: "ROSYTH",
      },
      {
        CITY: "ROTHBURY",
      },
      {
        CITY: "ROTHERFIELD",
      },
      {
        CITY: "ROTHERHAM",
      },
      {
        CITY: "ROTHESAY",
      },
      {
        CITY: "ROTHWELL",
      },
      {
        CITY: "ROTTINGDEAN",
      },
      {
        CITY: "ROWDE",
      },
      {
        CITY: "ROWHEDGE",
      },
      {
        CITY: "ROWLANDS CASTLE",
      },
      {
        CITY: "ROWLANDS GILL",
      },
      {
        CITY: "ROWLEY REGIS",
      },
      {
        CITY: "ROXWELL",
      },
      {
        CITY: "ROYAL LEAMINGTON SPA",
      },
      {
        CITY: "ROYAL TUNBRIDGE WELLS",
      },
      {
        CITY: "ROYAL WOOTTON BASSETT",
      },
      {
        CITY: "ROYDON",
      },
      {
        CITY: "ROYSTON",
      },
      {
        CITY: "RUABON",
      },
      {
        CITY: "RUDDINGTON",
      },
      {
        CITY: "RUGBY",
      },
      {
        CITY: "RUGELEY",
      },
      {
        CITY: "RUISLIP",
      },
      {
        CITY: "RUNCORN",
      },
      {
        CITY: "RUSHDEN",
      },
      {
        CITY: "RUSTINGTON",
      },
      {
        CITY: "RUTHERGLEN",
      },
      {
        CITY: "RUTHIN",
      },
      {
        CITY: "RYDE",
      },
      {
        CITY: "RYE",
      },
      {
        CITY: "RYTON",
      },
      {
        CITY: "SACRISTON",
      },
      {
        CITY: "SAFFRON WALDEN",
      },
      {
        CITY: "SAINT AGNES",
      },
      {
        CITY: "SAINT ANNES ON THE SEA",
      },
      {
        CITY: "SAINT BEES",
      },
      {
        CITY: "SAINT BRIDES MAJOR",
      },
      {
        CITY: "SAINT CLEARS",
      },
      {
        CITY: "SAINT COLUMB MAJOR",
      },
      {
        CITY: "SAINT ERME",
      },
      {
        CITY: "SAINT IVES",
      },
      {
        CITY: "SAINT LEONARDS-ON-SEA",
      },
      {
        CITY: "SAINT NEOTS",
      },
      {
        CITY: "SALE",
      },
      {
        CITY: "SALFORD",
      },
      {
        CITY: "SALFORDS",
      },
      {
        CITY: "SALISBURY",
      },
      {
        CITY: "SALTASH",
      },
      {
        CITY: "SALTBURN-BY-THE-SEA",
      },
      {
        CITY: "SALTCOATS",
      },
      {
        CITY: "SALTHOUSE",
      },
      {
        CITY: "SANDBACH",
      },
      {
        CITY: "SANDERSTEAD",
      },
      {
        CITY: "SANDHURST",
      },
      {
        CITY: "SANDIACRE",
      },
      {
        CITY: "SANDOWN",
      },
      {
        CITY: "SANDWELL",
      },
      {
        CITY: "SANDWICH",
      },
      {
        CITY: "SANDY",
      },
      {
        CITY: "SANDYCROFT",
      },
      {
        CITY: "SANQUHAR",
      },
      {
        CITY: "SARRATT",
      },
      {
        CITY: "SAUNDERSFOOT",
      },
      {
        CITY: "SAWBRIDGEWORTH",
      },
      {
        CITY: "SAWLEY",
      },
      {
        CITY: "SAXMUNDHAM",
      },
      {
        CITY: "SAXTEAD",
      },
      {
        CITY: "SCALBY",
      },
      {
        CITY: "SCAPA",
      },
      {
        CITY: "SCARBOROUGH",
      },
      {
        CITY: "SCUNTHORPE",
      },
      {
        CITY: "SEACROFT",
      },
      {
        CITY: "SEAFORD",
      },
      {
        CITY: "SEAHAM",
      },
      {
        CITY: "SEALE",
      },
      {
        CITY: "SEASCALE",
      },
      {
        CITY: "SEATON",
      },
      {
        CITY: "SEAVIEW",
      },
      {
        CITY: "SEDBERGH",
      },
      {
        CITY: "SEDGEBERROW",
      },
      {
        CITY: "SEDGEFIELD",
      },
      {
        CITY: "SEDGLEY",
      },
      {
        CITY: "SEEND",
      },
      {
        CITY: "SEGHILL",
      },
      {
        CITY: "SELBORNE",
      },
      {
        CITY: "SELBY",
      },
      {
        CITY: "SELKIRK",
      },
      {
        CITY: "SELSEY",
      },
      {
        CITY: "SELSTON",
      },
      {
        CITY: "SEND",
      },
      {
        CITY: "SETTLE",
      },
      {
        CITY: "SEVEN KINGS",
      },
      {
        CITY: "SEVENOAKS",
      },
      {
        CITY: "SHADOXHURST",
      },
      {
        CITY: "SHAFTESBURY",
      },
      {
        CITY: "SHALFORD",
      },
      {
        CITY: "SHANKLIN",
      },
      {
        CITY: "SHARDLOW",
      },
      {
        CITY: "SHAW",
      },
      {
        CITY: "SHAWBURY",
      },
      {
        CITY: "SHEERING",
      },
      {
        CITY: "SHEERNESS",
      },
      {
        CITY: "SHEFFIELD",
      },
      {
        CITY: "SHEFFORD",
      },
      {
        CITY: "SHELDON",
      },
      {
        CITY: "SHELFORD",
      },
      {
        CITY: "SHENFIELD",
      },
      {
        CITY: "SHEPPERTON",
      },
      {
        CITY: "SHEPSHED",
      },
      {
        CITY: "SHEPTON MALLET",
      },
      {
        CITY: "SHERBORNE",
      },
      {
        CITY: "SHERFIELD UPON LODDON",
      },
      {
        CITY: "SHERINGHAM",
      },
      {
        CITY: "SHIFNAL",
      },
      {
        CITY: "SHILDON",
      },
      {
        CITY: "SHILTON",
      },
      {
        CITY: "SHINFIELD",
      },
      {
        CITY: "SHIPLEY",
      },
      {
        CITY: "SHIPSTON ON STOUR",
      },
      {
        CITY: "SHIREBROOK",
      },
      {
        CITY: "SHIREHAMPTON",
      },
      {
        CITY: "SHIREOAKS",
      },
      {
        CITY: "SHOEBURYNESS",
      },
      {
        CITY: "SHOREHAM-BY-SEA",
      },
      {
        CITY: "SHOTLEY GATE",
      },
      {
        CITY: "SHOTTON",
      },
      {
        CITY: "SHOTTS",
      },
      {
        CITY: "SHREWSBURY",
      },
      {
        CITY: "SHRIVENHAM",
      },
      {
        CITY: "SIDCOT",
      },
      {
        CITY: "SIDCUP",
      },
      {
        CITY: "SIDLESHAM",
      },
      {
        CITY: "SIDMOUTH",
      },
      {
        CITY: "SILEBY",
      },
      {
        CITY: "SILLOTH",
      },
      {
        CITY: "SILSDEN",
      },
      {
        CITY: "SILSOE",
      },
      {
        CITY: "SILVERDALE",
      },
      {
        CITY: "SILVERSTONE",
      },
      {
        CITY: "SITTINGBOURNE",
      },
      {
        CITY: "SKEGNESS",
      },
      {
        CITY: "SKELMERSDALE",
      },
      {
        CITY: "SKELMORLIE",
      },
      {
        CITY: "SKELTON",
      },
      {
        CITY: "SKILGATE",
      },
      {
        CITY: "SKIPTON",
      },
      {
        CITY: "SLAPTON",
      },
      {
        CITY: "SLAWITT",
      },
      {
        CITY: "SLEAFORD",
      },
      {
        CITY: "SLINFOLD",
      },
      {
        CITY: "SLOUGH",
      },
      {
        CITY: "SMALLEY",
      },
      {
        CITY: "SMARDEN",
      },
      {
        CITY: "SMETHWICK",
      },
      {
        CITY: "SNARESBROOK",
      },
      {
        CITY: "SNETTISHAM",
      },
      {
        CITY: "SNODLAND",
      },
      {
        CITY: "SOHAM",
      },
      {
        CITY: "SOLIHULL",
      },
      {
        CITY: "SOMERSET",
      },
      {
        CITY: "SOMERSHAM",
      },
      {
        CITY: "SOMERTON",
      },
      {
        CITY: "SOMPTING",
      },
      {
        CITY: "SONNING",
      },
      {
        CITY: "SOUTH BENFLEET",
      },
      {
        CITY: "SOUTH BRENT",
      },
      {
        CITY: "SOUTH BREWHAM",
      },
      {
        CITY: "SOUTH CARLTON",
      },
      {
        CITY: "SOUTH CAVE",
      },
      {
        CITY: "SOUTH CERNEY",
      },
      {
        CITY: "SOUTH ELMSALL",
      },
      {
        CITY: "SOUTH HAYLING",
      },
      {
        CITY: "SOUTH HETTON",
      },
      {
        CITY: "SOUTH KILLINGHOLME",
      },
      {
        CITY: "SOUTH MILFORD",
      },
      {
        CITY: "SOUTH MOLTON",
      },
      {
        CITY: "SOUTH NORMANTON",
      },
      {
        CITY: "SOUTH OCKENDON",
      },
      {
        CITY: "SOUTH PETHERTON",
      },
      {
        CITY: "SOUTH SHIELDS",
      },
      {
        CITY: "SOUTHALL",
      },
      {
        CITY: "SOUTHAM",
      },
      {
        CITY: "SOUTHAMPTON",
      },
      {
        CITY: "SOUTHBOURNE",
      },
      {
        CITY: "SOUTHEND-ON-SEA",
      },
      {
        CITY: "SOUTHGATE",
      },
      {
        CITY: "SOUTHMINSTER",
      },
      {
        CITY: "SOUTHPORT",
      },
      {
        CITY: "SOUTHSEA",
      },
      {
        CITY: "SOUTHWARK",
      },
      {
        CITY: "SOUTHWATER",
      },
      {
        CITY: "SOUTHWELL",
      },
      {
        CITY: "SOUTHWICK",
      },
      {
        CITY: "SOUTHWOLD",
      },
      {
        CITY: "SOWERBY BRIDGE",
      },
      {
        CITY: "SPALDING",
      },
      {
        CITY: "SPARSHOLT",
      },
      {
        CITY: "SPEKE",
      },
      {
        CITY: "SPELDHURST",
      },
      {
        CITY: "SPENNYMOOR",
      },
      {
        CITY: "SPETISBURY",
      },
      {
        CITY: "SPILSBY",
      },
      {
        CITY: "SPITTAL",
      },
      {
        CITY: "SPONDON",
      },
      {
        CITY: "SPRATTON",
      },
      {
        CITY: "SPROWSTON",
      },
      {
        CITY: "SQUARE",
      },
      {
        CITY: "ST ALBANS",
      },
      {
        CITY: "ST ANDREWS",
      },
      {
        CITY: "ST ASAPH",
      },
      {
        CITY: "ST AUSTELL",
      },
      {
        CITY: "ST HELENS",
      },
      {
        CITY: "ST IVES",
      },
      {
        CITY: "ST. DOGMAELS",
      },
      {
        CITY: "STAFFORD",
      },
      {
        CITY: "STAINDROP",
      },
      {
        CITY: "STAINES",
      },
      {
        CITY: "STALHAM",
      },
      {
        CITY: "STALLINGBOROUGH",
      },
      {
        CITY: "STALYBRIDGE",
      },
      {
        CITY: "STAMFORD",
      },
      {
        CITY: "STANBRIDGE",
      },
      {
        CITY: "STANDLAKE",
      },
      {
        CITY: "STANFORD",
      },
      {
        CITY: "STANGROUND",
      },
      {
        CITY: "STANHOPE",
      },
      {
        CITY: "STANLEY",
      },
      {
        CITY: "STANMORE",
      },
      {
        CITY: "STANSTEAD",
      },
      {
        CITY: "STANSTED",
      },
      {
        CITY: "STANTON",
      },
      {
        CITY: "STANTON FITZWARREN",
      },
      {
        CITY: "STANWELL",
      },
      {
        CITY: "STAPLE",
      },
      {
        CITY: "STAPLEHURST",
      },
      {
        CITY: "STAR",
      },
      {
        CITY: "STAVERTON",
      },
      {
        CITY: "STAWELL",
      },
      {
        CITY: "STEEPLE CLAYDON",
      },
      {
        CITY: "STEETON",
      },
      {
        CITY: "STEPPS",
      },
      {
        CITY: "STEVENAGE",
      },
      {
        CITY: "STEVENSTON",
      },
      {
        CITY: "STEVENTON",
      },
      {
        CITY: "STEWARTON",
      },
      {
        CITY: "STEYNING",
      },
      {
        CITY: "STIFFKEY",
      },
      {
        CITY: "STIFFORD",
      },
      {
        CITY: "STILLINGTON",
      },
      {
        CITY: "STIRLING",
      },
      {
        CITY: "STISTED",
      },
      {
        CITY: "STOCK",
      },
      {
        CITY: "STOCKBRIDGE",
      },
      {
        CITY: "STOCKCROSS",
      },
      {
        CITY: "STOCKPORT",
      },
      {
        CITY: "STOCKSBRIDGE",
      },
      {
        CITY: "STOCKSFIELD",
      },
      {
        CITY: "STOCKTON",
      },
      {
        CITY: "STOCKTON-ON-TEES",
      },
      {
        CITY: "STOKE",
      },
      {
        CITY: "STOKE BY NAYLAND",
      },
      {
        CITY: "STOKE CLIMSLAND",
      },
      {
        CITY: "STOKE NEWINGTON",
      },
      {
        CITY: "STOKE POGES",
      },
      {
        CITY: "STOKE PRIOR",
      },
      {
        CITY: "STOKE UPON TERN",
      },
      {
        CITY: "STOKE-ON-TRENT",
      },
      {
        CITY: "STOKESLEY",
      },
      {
        CITY: "STONE",
      },
      {
        CITY: "STONE ALLERTON",
      },
      {
        CITY: "STONEHAVEN",
      },
      {
        CITY: "STONEHOUSE",
      },
      {
        CITY: "STONELEIGH",
      },
      {
        CITY: "STONESFIELD",
      },
      {
        CITY: "STONY STRATFORD",
      },
      {
        CITY: "STORNOWAY",
      },
      {
        CITY: "STORRINGTON",
      },
      {
        CITY: "STOTFOLD",
      },
      {
        CITY: "STOURBRIDGE",
      },
      {
        CITY: "STOURPORT ON SEVERN",
      },
      {
        CITY: "STOW",
      },
      {
        CITY: "STOW ON THE WOLD",
      },
      {
        CITY: "STOWE",
      },
      {
        CITY: "STOWMARKET",
      },
      {
        CITY: "STRABANE",
      },
      {
        CITY: "STRANMILLIS",
      },
      {
        CITY: "STRANRAER",
      },
      {
        CITY: "STRATFIELD MORTIMER",
      },
      {
        CITY: "STRATFORD",
      },
      {
        CITY: "STRATFORD-UPON-AVON",
      },
      {
        CITY: "STRATHAVEN",
      },
      {
        CITY: "STRATHMIGLO",
      },
      {
        CITY: "STRATHYRE",
      },
      {
        CITY: "STREATHAM",
      },
      {
        CITY: "STREATLEY",
      },
      {
        CITY: "STRETFORD",
      },
      {
        CITY: "STRETTON ON FOSSE",
      },
      {
        CITY: "STROMNESS",
      },
      {
        CITY: "STROOD",
      },
      {
        CITY: "STROUD",
      },
      {
        CITY: "STUARTFIELD",
      },
      {
        CITY: "STUDHAM",
      },
      {
        CITY: "STUDLEY",
      },
      {
        CITY: "STURMER",
      },
      {
        CITY: "STURMINSTER NEWTON",
      },
      {
        CITY: "STUTTON",
      },
      {
        CITY: "SUDBURY",
      },
      {
        CITY: "SULLY",
      },
      {
        CITY: "SUNBURY-ON-THAMES",
      },
      {
        CITY: "SUNDERLAND",
      },
      {
        CITY: "SUNDON PARK",
      },
      {
        CITY: "SUNNINGDALE",
      },
      {
        CITY: "SUNNINGHILL",
      },
      {
        CITY: "SURBITON",
      },
      {
        CITY: "SURREY",
      },
      {
        CITY: "SUTTON",
      },
      {
        CITY: "SUTTON BRIDGE",
      },
      {
        CITY: "SUTTON COLDFIELD",
      },
      {
        CITY: "SUTTON COURTENAY",
      },
      {
        CITY: "SUTTON IN ASHFIELD",
      },
      {
        CITY: "SUTTON ON HULL",
      },
      {
        CITY: "SUTTON ON THE HILL",
      },
      {
        CITY: "SUTTON ON TRENT",
      },
      {
        CITY: "SWADLINCOTE",
      },
      {
        CITY: "SWAFFHAM",
      },
      {
        CITY: "SWALWELL",
      },
      {
        CITY: "SWANAGE",
      },
      {
        CITY: "SWANLAND",
      },
      {
        CITY: "SWANSCOMBE",
      },
      {
        CITY: "SWANSEA",
      },
      {
        CITY: "SWAVESEY",
      },
      {
        CITY: "SWAYTHLING",
      },
      {
        CITY: "SWINDON",
      },
      {
        CITY: "SWINDON VILLAGE",
      },
      {
        CITY: "SWINTON",
      },
      {
        CITY: "SWYNNERTON",
      },
      {
        CITY: "SYMINGTON",
      },
      {
        CITY: "SYSTON",
      },
      {
        CITY: "TACKLEY",
      },
      {
        CITY: "TADCASTER",
      },
      {
        CITY: "TADLEY",
      },
      {
        CITY: "TADMARTON",
      },
      {
        CITY: "TADWORTH",
      },
      {
        CITY: "TAIN",
      },
      {
        CITY: "TAKELEY",
      },
      {
        CITY: "TALGARTH",
      },
      {
        CITY: "TALSARNAU",
      },
      {
        CITY: "TALYBONT",
      },
      {
        CITY: "TAMWORTH",
      },
      {
        CITY: "TANDRAGEE",
      },
      {
        CITY: "TANSLEY",
      },
      {
        CITY: "TAPLOW",
      },
      {
        CITY: "TARBERT",
      },
      {
        CITY: "TARLETON",
      },
      {
        CITY: "TARPORLEY",
      },
      {
        CITY: "TATSFIELD",
      },
      {
        CITY: "TATTERSHALL",
      },
      {
        CITY: "TAUNTON",
      },
      {
        CITY: "TAVISTOCK",
      },
      {
        CITY: "TAYNUILT",
      },
      {
        CITY: "TAYPORT",
      },
      {
        CITY: "TEDDINGTON",
      },
      {
        CITY: "TEIGNMOUTH",
      },
      {
        CITY: "TELFORD",
      },
      {
        CITY: "TEMPLE",
      },
      {
        CITY: "TEMPLECOMBE",
      },
      {
        CITY: "TEMPLEPATRICK",
      },
      {
        CITY: "TEMPO",
      },
      {
        CITY: "TENBURY WELLS",
      },
      {
        CITY: "TENBY",
      },
      {
        CITY: "TENDRING",
      },
      {
        CITY: "TENTERDEN",
      },
      {
        CITY: "TETBURY",
      },
      {
        CITY: "TETSWORTH",
      },
      {
        CITY: "TEWIN",
      },
      {
        CITY: "TEWKESBURY",
      },
      {
        CITY: "TEYNHAM",
      },
      {
        CITY: "THAKEHAM",
      },
      {
        CITY: "THAME",
      },
      {
        CITY: "THAMES DITTON",
      },
      {
        CITY: "THATCHAM",
      },
      {
        CITY: "THE HYDE",
      },
      {
        CITY: "THEALE",
      },
      {
        CITY: "THETFORD",
      },
      {
        CITY: "THEYDON BOIS",
      },
      {
        CITY: "THIRSK",
      },
      {
        CITY: "THORNABY",
      },
      {
        CITY: "THORNBURY",
      },
      {
        CITY: "THORNTON",
      },
      {
        CITY: "THORNTON HEATH",
      },
      {
        CITY: "THORNTON-IN-CRAVEN",
      },
      {
        CITY: "THORPE ST PETER",
      },
      {
        CITY: "THORRINGTON",
      },
      {
        CITY: "THRAPSTON",
      },
      {
        CITY: "THREE LEGGED CROSS",
      },
      {
        CITY: "THRELKELD",
      },
      {
        CITY: "THURLEIGH",
      },
      {
        CITY: "THURSO",
      },
      {
        CITY: "THURSTON",
      },
      {
        CITY: "TICEHURST",
      },
      {
        CITY: "TICKNALL",
      },
      {
        CITY: "TIDESWELL",
      },
      {
        CITY: "TILBURY",
      },
      {
        CITY: "TILE HILL",
      },
      {
        CITY: "TILEHURST",
      },
      {
        CITY: "TILLICOULTRY",
      },
      {
        CITY: "TILLINGHAM",
      },
      {
        CITY: "TIMPERLEY",
      },
      {
        CITY: "TIPTON",
      },
      {
        CITY: "TIPTREE",
      },
      {
        CITY: "TISBURY",
      },
      {
        CITY: "TIVERTON",
      },
      {
        CITY: "TODDINGTON",
      },
      {
        CITY: "TODMORDEN",
      },
      {
        CITY: "TOLLARD ROYAL",
      },
      {
        CITY: "TOLLERTON",
      },
      {
        CITY: "TONBRIDGE",
      },
      {
        CITY: "TONGHAM",
      },
      {
        CITY: "TONYPANDY",
      },
      {
        CITY: "TONYREFAIL",
      },
      {
        CITY: "TORPOINT",
      },
      {
        CITY: "TORQUAY",
      },
      {
        CITY: "TOTNES",
      },
      {
        CITY: "TOTTENHAM",
      },
      {
        CITY: "TOTTERIDGE",
      },
      {
        CITY: "TOTTERNHOE",
      },
      {
        CITY: "TOTTON",
      },
      {
        CITY: "TOWCESTER",
      },
      {
        CITY: "TRANENT",
      },
      {
        CITY: "TREDEGAR",
      },
      {
        CITY: "TREFONEN",
      },
      {
        CITY: "TREFOREST",
      },
      {
        CITY: "TREGARTH",
      },
      {
        CITY: "TREHAFOD",
      },
      {
        CITY: "TREHARRIS",
      },
      {
        CITY: "TREHERBERT",
      },
      {
        CITY: "TRENT",
      },
      {
        CITY: "TREORCHY",
      },
      {
        CITY: "TREUDDYN",
      },
      {
        CITY: "TRIMDON",
      },
      {
        CITY: "TRING",
      },
      {
        CITY: "TROON",
      },
      {
        CITY: "TROWBRIDGE",
      },
      {
        CITY: "TRURO",
      },
      {
        CITY: "TRUSTHORPE",
      },
      {
        CITY: "TUNSTALL",
      },
      {
        CITY: "TURNBERRY",
      },
      {
        CITY: "TURRIFF",
      },
      {
        CITY: "TURVEY",
      },
      {
        CITY: "TWEEDMOUTH",
      },
      {
        CITY: "TWICKENHAM",
      },
      {
        CITY: "TWYFORD",
      },
      {
        CITY: "TYLDESLEY",
      },
      {
        CITY: "TYNEMOUTH",
      },
      {
        CITY: "TYWARDREATH",
      },
      {
        CITY: "UCKFIELD",
      },
      {
        CITY: "UDDINGSTON",
      },
      {
        CITY: "UFFORD",
      },
      {
        CITY: "ULCEBY",
      },
      {
        CITY: "ULCOMBE",
      },
      {
        CITY: "ULLESTHORPE",
      },
      {
        CITY: "ULVERSTON",
      },
      {
        CITY: "UNSTONE",
      },
      {
        CITY: "UPHALL",
      },
      {
        CITY: "UPMINSTER",
      },
      {
        CITY: "UPPER BEEDING",
      },
      {
        CITY: "UPPER SLAUGHTER",
      },
      {
        CITY: "UPPINGHAM",
      },
      {
        CITY: "UPTON",
      },
      {
        CITY: "UPTON UPON SEVERN",
      },
      {
        CITY: "UPWELL",
      },
      {
        CITY: "URCHFONT",
      },
      {
        CITY: "URMSTON",
      },
      {
        CITY: "USHAW MOOR",
      },
      {
        CITY: "USK",
      },
      {
        CITY: "UTTOXETER",
      },
      {
        CITY: "UXBRIDGE",
      },
      {
        CITY: "VALLEY",
      },
      {
        CITY: "VENTNOR",
      },
      {
        CITY: "VERWOOD",
      },
      {
        CITY: "VICTORIA",
      },
      {
        CITY: "VOE",
      },
      {
        CITY: "WADDESDON",
      },
      {
        CITY: "WADEBRIDGE",
      },
      {
        CITY: "WADHURST",
      },
      {
        CITY: "WAKEFIELD",
      },
      {
        CITY: "WALDRINGFIELD",
      },
      {
        CITY: "WALES",
      },
      {
        CITY: "WALFORD",
      },
      {
        CITY: "WALGRAVE",
      },
      {
        CITY: "WALKDEN",
      },
      {
        CITY: "WALKER",
      },
      {
        CITY: "WALLASEY",
      },
      {
        CITY: "WALLINGFORD",
      },
      {
        CITY: "WALLINGTON",
      },
      {
        CITY: "WALLSEND",
      },
      {
        CITY: "WALMER",
      },
      {
        CITY: "WALSALL",
      },
      {
        CITY: "WALTHAM",
      },
      {
        CITY: "WALTHAM ABBEY",
      },
      {
        CITY: "WALTHAM CROSS",
      },
      {
        CITY: "WALTHAMSTOW",
      },
      {
        CITY: "WALTON ON THAMES",
      },
      {
        CITY: "WALTON ON THE HILL",
      },
      {
        CITY: "WALTON-ON-THE-NAZE",
      },
      {
        CITY: "WANSTEAD",
      },
      {
        CITY: "WANTAGE",
      },
      {
        CITY: "WARDEN",
      },
      {
        CITY: "WARE",
      },
      {
        CITY: "WAREHAM",
      },
      {
        CITY: "WARFIELD",
      },
      {
        CITY: "WARGRAVE",
      },
      {
        CITY: "WARINGSTOWN",
      },
      {
        CITY: "WARLINGHAM",
      },
      {
        CITY: "WARMINGTON",
      },
      {
        CITY: "WARMINSTER",
      },
      {
        CITY: "WARRENPOINT",
      },
      {
        CITY: "WARRINGTON",
      },
      {
        CITY: "WARTON",
      },
      {
        CITY: "WARWICK",
      },
      {
        CITY: "WASHINGTON",
      },
      {
        CITY: "WATCHET",
      },
      {
        CITY: "WATCHFIELD",
      },
      {
        CITY: "WATER ORTON",
      },
      {
        CITY: "WATERBEACH",
      },
      {
        CITY: "WATERLOOVILLE",
      },
      {
        CITY: "WATFORD",
      },
      {
        CITY: "WATH UPON DEARNE",
      },
      {
        CITY: "WATLINGTON",
      },
      {
        CITY: "WATTISFIELD",
      },
      {
        CITY: "WATTON",
      },
      {
        CITY: "WEALD",
      },
      {
        CITY: "WEALDSTONE",
      },
      {
        CITY: "WEARE",
      },
      {
        CITY: "WEAVERHAM",
      },
      {
        CITY: "WEDMORE",
      },
      {
        CITY: "WEDNESBURY",
      },
      {
        CITY: "WEDNESFIELD",
      },
      {
        CITY: "WEEDON",
      },
      {
        CITY: "WEEDON BEC",
      },
      {
        CITY: "WELL",
      },
      {
        CITY: "WELLING",
      },
      {
        CITY: "WELLINGBOROUGH",
      },
      {
        CITY: "WELLINGTON",
      },
      {
        CITY: "WELLS",
      },
      {
        CITY: "WELSHPOOL",
      },
      {
        CITY: "WELWYN",
      },
      {
        CITY: "WELWYN GARDEN CITY",
      },
      {
        CITY: "WEM",
      },
      {
        CITY: "WEMBLEY",
      },
      {
        CITY: "WEMYSS BAY",
      },
      {
        CITY: "WENDOVER",
      },
      {
        CITY: "WENTWORTH",
      },
      {
        CITY: "WEOBLEY",
      },
      {
        CITY: "WEST ASHBY",
      },
      {
        CITY: "WEST BROMWICH",
      },
      {
        CITY: "WEST BYFLEET",
      },
      {
        CITY: "WEST CALDER",
      },
      {
        CITY: "WEST CLANDON",
      },
      {
        CITY: "WEST COWES",
      },
      {
        CITY: "WEST DRAYTON",
      },
      {
        CITY: "WEST GRINSTEAD",
      },
      {
        CITY: "WEST HORSLEY",
      },
      {
        CITY: "WEST KILBRIDE",
      },
      {
        CITY: "WEST KIRBY",
      },
      {
        CITY: "WEST LANGDON",
      },
      {
        CITY: "WEST LINTON",
      },
      {
        CITY: "WEST MALLING",
      },
      {
        CITY: "WEST MERSEA",
      },
      {
        CITY: "WEST RAYNHAM",
      },
      {
        CITY: "WEST ROW",
      },
      {
        CITY: "WEST RUDHAM",
      },
      {
        CITY: "WEST WICKHAM",
      },
      {
        CITY: "WEST WITTERING",
      },
      {
        CITY: "WESTBURY",
      },
      {
        CITY: "WESTBURY-SUB-MENDIP",
      },
      {
        CITY: "WESTCOTT",
      },
      {
        CITY: "WESTERHAM",
      },
      {
        CITY: "WESTFIELD",
      },
      {
        CITY: "WESTGATE",
      },
      {
        CITY: "WESTGATE ON SEA",
      },
      {
        CITY: "WESTHAY",
      },
      {
        CITY: "WESTHOUGHTON",
      },
      {
        CITY: "WESTLEIGH",
      },
      {
        CITY: "WESTLETON",
      },
      {
        CITY: "WESTON",
      },
      {
        CITY: "WESTON IN GORDANO",
      },
      {
        CITY: "WESTON UNDER LIZARD",
      },
      {
        CITY: "WESTON-SUPER-MARE",
      },
      {
        CITY: "WETHERBY",
      },
      {
        CITY: "WETHERSFIELD",
      },
      {
        CITY: "WEYBRIDGE",
      },
      {
        CITY: "WEYMOUTH",
      },
      {
        CITY: "WHALEY BRIDGE",
      },
      {
        CITY: "WHALLEY",
      },
      {
        CITY: "WHEATHAMPSTEAD",
      },
      {
        CITY: "WHEATLEY",
      },
      {
        CITY: "WHEATON ASTON",
      },
      {
        CITY: "WHETSTONE",
      },
      {
        CITY: "WHICKHAM",
      },
      {
        CITY: "WHIMPLE",
      },
      {
        CITY: "WHISBY",
      },
      {
        CITY: "WHITBOURNE",
      },
      {
        CITY: "WHITBURN",
      },
      {
        CITY: "WHITBY",
      },
      {
        CITY: "WHITCHURCH",
      },
      {
        CITY: "WHITEFIELD",
      },
      {
        CITY: "WHITEHAVEN",
      },
      {
        CITY: "WHITEHOUSE",
      },
      {
        CITY: "WHITEPARISH",
      },
      {
        CITY: "WHITLAND",
      },
      {
        CITY: "WHITLEY BAY",
      },
      {
        CITY: "WHITNASH",
      },
      {
        CITY: "WHITSTABLE",
      },
      {
        CITY: "WHITTLEBURY",
      },
      {
        CITY: "WHITTLESEY",
      },
      {
        CITY: "WHITTLESFORD",
      },
      {
        CITY: "WHITTON",
      },
      {
        CITY: "WHITWELL",
      },
      {
        CITY: "WHITWICK",
      },
      {
        CITY: "WHYTELEAFE",
      },
      {
        CITY: "WICK",
      },
      {
        CITY: "WICKEN",
      },
      {
        CITY: "WICKERSLEY",
      },
      {
        CITY: "WICKFORD",
      },
      {
        CITY: "WICKHAM",
      },
      {
        CITY: "WICKHAM BISHOPS",
      },
      {
        CITY: "WICKHAM MARKET",
      },
      {
        CITY: "WIDDRINGTON",
      },
      {
        CITY: "WIDMERPOOL",
      },
      {
        CITY: "WIDNES",
      },
      {
        CITY: "WIGAN",
      },
      {
        CITY: "WIGSTON",
      },
      {
        CITY: "WIGTON",
      },
      {
        CITY: "WILBURTON",
      },
      {
        CITY: "WILDEN",
      },
      {
        CITY: "WILLASTON",
      },
      {
        CITY: "WILLENHALL",
      },
      {
        CITY: "WILLESDEN",
      },
      {
        CITY: "WILLINGALE DOE",
      },
      {
        CITY: "WILLINGHAM",
      },
      {
        CITY: "WILLINGTON",
      },
      {
        CITY: "WILLINGTON QUAY",
      },
      {
        CITY: "WILMINGTON",
      },
      {
        CITY: "WILMSLOW",
      },
      {
        CITY: "WILNECOTE",
      },
      {
        CITY: "WILSHAMSTEAD",
      },
      {
        CITY: "WIMBLEDON",
      },
      {
        CITY: "WIMBORNE MINSTER",
      },
      {
        CITY: "WINCANTON",
      },
      {
        CITY: "WINCHESTER",
      },
      {
        CITY: "WINDERMERE",
      },
      {
        CITY: "WINDLESHAM",
      },
      {
        CITY: "WINDSOR",
      },
      {
        CITY: "WING",
      },
      {
        CITY: "WINGATE",
      },
      {
        CITY: "WINKLEIGH",
      },
      {
        CITY: "WINLATON",
      },
      {
        CITY: "WINNERSH",
      },
      {
        CITY: "WINNINGTON",
      },
      {
        CITY: "WINSFORD",
      },
      {
        CITY: "WINSLOW",
      },
      {
        CITY: "WINTERBORNE KINGSTON",
      },
      {
        CITY: "WINTERBOURNE DAUNTSEY",
      },
      {
        CITY: "WINTON",
      },
      {
        CITY: "WIRKSWORTH",
      },
      {
        CITY: "WISBECH",
      },
      {
        CITY: "WISHAW",
      },
      {
        CITY: "WITHAM",
      },
      {
        CITY: "WITHERNSEA",
      },
      {
        CITY: "WITHERNWICK",
      },
      {
        CITY: "WITHINGTON",
      },
      {
        CITY: "WITLEY",
      },
      {
        CITY: "WITNEY",
      },
      {
        CITY: "WIX",
      },
      {
        CITY: "WOBURN SANDS",
      },
      {
        CITY: "WOKING",
      },
      {
        CITY: "WOKINGHAM",
      },
      {
        CITY: "WOLD NEWTON",
      },
      {
        CITY: "WOLDINGHAM",
      },
      {
        CITY: "WOLSTANTON",
      },
      {
        CITY: "WOLVERHAMPTON",
      },
      {
        CITY: "WOLVERLEY",
      },
      {
        CITY: "WOMBOURNE",
      },
      {
        CITY: "WOMBWELL",
      },
      {
        CITY: "WOOBURN",
      },
      {
        CITY: "WOODBRIDGE",
      },
      {
        CITY: "WOODFORD",
      },
      {
        CITY: "WOODFORD GREEN",
      },
      {
        CITY: "WOODHALL SPA",
      },
      {
        CITY: "WOODHAM FERRERS",
      },
      {
        CITY: "WOODHOUSE",
      },
      {
        CITY: "WOODLEY",
      },
      {
        CITY: "WOODMANCOTE",
      },
      {
        CITY: "WOODSETTS",
      },
      {
        CITY: "WOODSTOCK",
      },
      {
        CITY: "WOOLACOMBE",
      },
      {
        CITY: "WOOLWICH",
      },
      {
        CITY: "WOORE",
      },
      {
        CITY: "WOOTTON",
      },
      {
        CITY: "WORCESTER",
      },
      {
        CITY: "WORCESTER PARK",
      },
      {
        CITY: "WORKINGTON",
      },
      {
        CITY: "WORKSOP",
      },
      {
        CITY: "WORLE",
      },
      {
        CITY: "WORMINGHALL",
      },
      {
        CITY: "WORSLEY",
      },
      {
        CITY: "WORTH",
      },
      {
        CITY: "WORTHING",
      },
      {
        CITY: "WOTTON UNDERWOOD",
      },
      {
        CITY: "WOTTON-UNDER-EDGE",
      },
      {
        CITY: "WRAGBY",
      },
      {
        CITY: "WRANGATON",
      },
      {
        CITY: "WRANGLE",
      },
      {
        CITY: "WRAWBY",
      },
      {
        CITY: "WRAYSBURY",
      },
      {
        CITY: "WREA GREEN",
      },
      {
        CITY: "WREXHAM",
      },
      {
        CITY: "WRINGTON",
      },
      {
        CITY: "WRITTLE",
      },
      {
        CITY: "WROUGHTON",
      },
      {
        CITY: "WROXALL",
      },
      {
        CITY: "WYKE",
      },
      {
        CITY: "WYMESWOLD",
      },
      {
        CITY: "WYMONDHAM",
      },
      {
        CITY: "WYTHENSHAWE",
      },
      {
        CITY: "WYTON",
      },
      {
        CITY: "WYVERSTONE",
      },
      {
        CITY: "YARM",
      },
      {
        CITY: "YATE",
      },
      {
        CITY: "YATTON",
      },
      {
        CITY: "YEADON",
      },
      {
        CITY: "YELVERTON",
      },
      {
        CITY: "YEOVIL",
      },
      {
        CITY: "YORK",
      },
      {
        CITY: "YOXFORD",
      },
      {
        CITY: "YSTALYFERA",
      },
      {
        CITY: "YSTRAD MYNACH",
      },
      {
        CITY: "YSTRADGYNLAIS",
      },
      {
        CITY: "ZEALS",
      },
    ],
  },
  {
    COUNTRY: "SWITZERLAND",
    CODE: "CH",
    CITIES: [
      {
        CITY: "AADORF",
      },
      {
        CITY: "AARAU",
      },
      {
        CITY: "AARBERG",
      },
      {
        CITY: "AARBURG",
      },
      {
        CITY: "ABTWIL",
      },
      {
        CITY: "ADELBODEN",
      },
      {
        CITY: "ADLIGENSWIL",
      },
      {
        CITY: "ADLISWIL",
      },
      {
        CITY: "AESCH",
      },
      {
        CITY: "AESCHAU",
      },
      {
        CITY: "AESCHLEN OB GUNTEN",
      },
      {
        CITY: "AETTENSCHWIL",
      },
      {
        CITY: "AEUGST AM ALBIS",
      },
      {
        CITY: "AFFOLTERN AM ALBIS",
      },
      {
        CITY: "AGNO",
      },
      {
        CITY: "AGRA",
      },
      {
        CITY: "AIGLE",
      },
      {
        CITY: "AIROLO",
      },
      {
        CITY: "ALBERSWIL",
      },
      {
        CITY: "ALBLIGEN",
      },
      {
        CITY: "ALCHENSTORF",
      },
      {
        CITY: "ALLAMAN",
      },
      {
        CITY: "ALLSCHWIL",
      },
      {
        CITY: "ALPNACH",
      },
      {
        CITY: "ALT-BACHS",
      },
      {
        CITY: "ALTDORF",
      },
      {
        CITY: "ALTENDORF",
      },
      {
        CITY: "ALTENRHEIN",
      },
      {
        CITY: "ALTERSWIL",
      },
      {
        CITY: "ALTISHOFEN",
      },
      {
        CITY: "ALTNAU",
      },
      {
        CITY: "ALTSTATTEN",
      },
      {
        CITY: "AMDEN",
      },
      {
        CITY: "AMRISWIL",
      },
      {
        CITY: "AMSOLDINGEN",
      },
      {
        CITY: "ANDERMATT",
      },
      {
        CITY: "ANDWIL",
      },
      {
        CITY: "ANIERES",
      },
      {
        CITY: "APPENZELL",
      },
      {
        CITY: "APPLES",
      },
      {
        CITY: "APRO",
      },
      {
        CITY: "ARBAZ",
      },
      {
        CITY: "ARBEDO",
      },
      {
        CITY: "ARBON",
      },
      {
        CITY: "ARCEGNO",
      },
      {
        CITY: "ARDON",
      },
      {
        CITY: "ARISDORF",
      },
      {
        CITY: "ARISTAU",
      },
      {
        CITY: "ARLESHEIM",
      },
      {
        CITY: "ARNI",
      },
      {
        CITY: "AROGNO",
      },
      {
        CITY: "AROSA",
      },
      {
        CITY: "ARTH",
      },
      {
        CITY: "ARZIER",
      },
      {
        CITY: "ARZO",
      },
      {
        CITY: "ASCONA",
      },
      {
        CITY: "ASSENS",
      },
      {
        CITY: "ATTALENS",
      },
      {
        CITY: "ATTINGHAUSEN",
      },
      {
        CITY: "ATTISWIL",
      },
      {
        CITY: "AU",
      },
      {
        CITY: "AUBONNE",
      },
      {
        CITY: "AUENSTEIN",
      },
      {
        CITY: "AUGST",
      },
      {
        CITY: "AUSSER-DINHARD",
      },
      {
        CITY: "AUTIGNY",
      },
      {
        CITY: "AUVERNIER",
      },
      {
        CITY: "AUW",
      },
      {
        CITY: "AVENCHES",
      },
      {
        CITY: "AVRY",
      },
      {
        CITY: "AYENT",
      },
      {
        CITY: "AYER",
      },
      {
        CITY: "BAAR",
      },
      {
        CITY: "BACH",
      },
      {
        CITY: "BACHENBULACH",
      },
      {
        CITY: "BAD RAGAZ",
      },
      {
        CITY: "BAD ZURZACH",
      },
      {
        CITY: "BADEN",
      },
      {
        CITY: "BALERNA",
      },
      {
        CITY: "BALGACH",
      },
      {
        CITY: "BALLWIL",
      },
      {
        CITY: "BALSTHAL",
      },
      {
        CITY: "BALTERSWIL",
      },
      {
        CITY: "BANCO",
      },
      {
        CITY: "BANGERTEN",
      },
      {
        CITY: "BANNWIL",
      },
      {
        CITY: "BARBERECHE",
      },
      {
        CITY: "BARETSWIL",
      },
      {
        CITY: "BARISWIL",
      },
      {
        CITY: "BASADINGEN",
      },
      {
        CITY: "BASEL",
      },
      {
        CITY: "BASSE-NENDAZ",
      },
      {
        CITY: "BASSECOURT",
      },
      {
        CITY: "BASSERSDORF",
      },
      {
        CITY: "BATTERKINDEN",
      },
      {
        CITY: "BAULMES",
      },
      {
        CITY: "BAUMA",
      },
      {
        CITY: "BAVOIS",
      },
      {
        CITY: "BAZENHEID",
      },
      {
        CITY: "BECKENRIED",
      },
      {
        CITY: "BEGNINS",
      },
      {
        CITY: "BEINWIL",
      },
      {
        CITY: "BELFAUX",
      },
      {
        CITY: "BELLERIVE",
      },
      {
        CITY: "BELLEVUE",
      },
      {
        CITY: "BELLINZONA",
      },
      {
        CITY: "BELLMUND",
      },
      {
        CITY: "BELMONT-SUR-LAUSANNE",
      },
      {
        CITY: "BELP",
      },
      {
        CITY: "BENKEN",
      },
      {
        CITY: "BERG",
      },
      {
        CITY: "BERIKON",
      },
      {
        CITY: "BERINGEN",
      },
      {
        CITY: "BERLIKON",
      },
      {
        CITY: "BERN",
      },
      {
        CITY: "BERN / LIEBEFELD",
      },
      {
        CITY: "BERNECK",
      },
      {
        CITY: "BERNEX",
      },
      {
        CITY: "BEROMUENSTER",
      },
      {
        CITY: "BETTINGEN",
      },
      {
        CITY: "BETTLACH",
      },
      {
        CITY: "BEUSON",
      },
      {
        CITY: "BEVAIX",
      },
      {
        CITY: "BEVILARD",
      },
      {
        CITY: "BEX",
      },
      {
        CITY: "BIASCA",
      },
      {
        CITY: "BIBERIST",
      },
      {
        CITY: "BIEL-BENKEN",
      },
      {
        CITY: "BIEL/BIENNE",
      },
      {
        CITY: "BIERE",
      },
      {
        CITY: "BIGENTHAL",
      },
      {
        CITY: "BIGORIO",
      },
      {
        CITY: "BILTEN",
      },
      {
        CITY: "BINNINGEN",
      },
      {
        CITY: "BIOGGIO",
      },
      {
        CITY: "BIRMENSDORF",
      },
      {
        CITY: "BIRMENSTORF",
      },
      {
        CITY: "BIRR",
      },
      {
        CITY: "BIRSFELDEN",
      },
      {
        CITY: "BISSEGG",
      },
      {
        CITY: "BISSONE",
      },
      {
        CITY: "BITSCH",
      },
      {
        CITY: "BLONAY",
      },
      {
        CITY: "BLUMENSTEIN",
      },
      {
        CITY: "BOGIS-BOSSEY",
      },
      {
        CITY: "BOLL",
      },
      {
        CITY: "BOLLIGEN",
      },
      {
        CITY: "BONADUZ",
      },
      {
        CITY: "BONIGEN",
      },
      {
        CITY: "BONNEFONTAINE",
      },
      {
        CITY: "BONSTETTEN",
      },
      {
        CITY: "BOREX",
      },
      {
        CITY: "BOSINGEN",
      },
      {
        CITY: "BOTTENS",
      },
      {
        CITY: "BOTTENWIL",
      },
      {
        CITY: "BOTTIGHOFEN",
      },
      {
        CITY: "BOTTMINGEN",
      },
      {
        CITY: "BOUDRY",
      },
      {
        CITY: "BOUVERET",
      },
      {
        CITY: "BRAMOIS",
      },
      {
        CITY: "BREITENBACH",
      },
      {
        CITY: "BREMGARTEN",
      },
      {
        CITY: "BRENZIKOFEN",
      },
      {
        CITY: "BRIENZ",
      },
      {
        CITY: "BRIG",
      },
      {
        CITY: "BRISLACH",
      },
      {
        CITY: "BRISSAGO",
      },
      {
        CITY: "BRISTEN",
      },
      {
        CITY: "BRITTNAU",
      },
      {
        CITY: "BROC",
      },
      {
        CITY: "BRONSCHHOFEN",
      },
      {
        CITY: "BRUGG",
      },
      {
        CITY: "BRUNNEN",
      },
      {
        CITY: "BRUSINO ARSIZIO",
      },
      {
        CITY: "BRUTTEN",
      },
      {
        CITY: "BRUTTISELLEN",
      },
      {
        CITY: "BUBENDORF",
      },
      {
        CITY: "BUBIKON",
      },
      {
        CITY: "BUCH",
      },
      {
        CITY: "BUCHBERG",
      },
      {
        CITY: "BUCHRAIN",
      },
      {
        CITY: "BUCHS",
      },
      {
        CITY: "BUCHS / BUCHS (DORF)",
      },
      {
        CITY: "BUETSCHWIL",
      },
      {
        CITY: "BUIX",
      },
      {
        CITY: "BULACH",
      },
      {
        CITY: "BULLE",
      },
      {
        CITY: "BUOCHS",
      },
      {
        CITY: "BUONAS",
      },
      {
        CITY: "BURCHEN",
      },
      {
        CITY: "BUREN AN DER AARE",
      },
      {
        CITY: "BUREN NID DEM BACH",
      },
      {
        CITY: "BURGDORF",
      },
      {
        CITY: "BURGLEN",
      },
      {
        CITY: "BURSINS",
      },
      {
        CITY: "BUSSERACH",
      },
      {
        CITY: "BUSSIGNY",
      },
      {
        CITY: "BUSSWIL",
      },
      {
        CITY: "BUTTES",
      },
      {
        CITY: "BUTTISHOLZ",
      },
      {
        CITY: "BUTZBERG",
      },
      {
        CITY: "BUUS",
      },
      {
        CITY: "CADEMPINO",
      },
      {
        CITY: "CADENAZZO",
      },
      {
        CITY: "CADRO",
      },
      {
        CITY: "CAMORINO",
      },
      {
        CITY: "CAMPFER",
      },
      {
        CITY: "CANOBBIO",
      },
      {
        CITY: "CAPOLAGO",
      },
      {
        CITY: "CAROUGE",
      },
      {
        CITY: "CARTIGNY",
      },
      {
        CITY: "CASLANO",
      },
      {
        CITY: "CASTIONE",
      },
      {
        CITY: "CAZIS",
      },
      {
        CITY: "CELERINA",
      },
      {
        CITY: "CELIGNY",
      },
      {
        CITY: "CERNIER",
      },
      {
        CITY: "CHALAIS",
      },
      {
        CITY: "CHAM",
      },
      {
        CITY: "CHAMBESY",
      },
      {
        CITY: "CHAMOSON",
      },
      {
        CITY: "CHAMPAGNE",
      },
      {
        CITY: "CHAMPEX",
      },
      {
        CITY: "CHAMPSEC",
      },
      {
        CITY: "CHANCY",
      },
      {
        CITY: "CHAPELLE",
      },
      {
        CITY: "CHARDONNE",
      },
      {
        CITY: "CHARMEY",
      },
      {
        CITY: "CHARRAT-LES-CHENES",
      },
      {
        CITY: "CHATEL-SAINT-DENIS",
      },
      {
        CITY: "CHATELAINE",
      },
      {
        CITY: "CHAVANNES",
      },
      {
        CITY: "CHAVANNES DE BOGIS",
      },
      {
        CITY: "CHAVANNES-LES-FORTS",
      },
      {
        CITY: "CHAVORNAY",
      },
      {
        CITY: "CHEIRY",
      },
      {
        CITY: "CHENE-BOUGERIES",
      },
      {
        CITY: "CHENE-BOURG",
      },
      {
        CITY: "CHENE-PAQUIER",
      },
      {
        CITY: "CHESEAUX-SUR-LAUSANNE",
      },
      {
        CITY: "CHESIERES",
      },
      {
        CITY: "CHEXBRES",
      },
      {
        CITY: "CHEYRES",
      },
      {
        CITY: "CHEZ-LE-BART",
      },
      {
        CITY: "CHIASSO",
      },
      {
        CITY: "CHIPPIS",
      },
      {
        CITY: "CHUR",
      },
      {
        CITY: "CLARO",
      },
      {
        CITY: "COEUVE",
      },
      {
        CITY: "COINTRIN",
      },
      {
        CITY: "COLLOMBEY",
      },
      {
        CITY: "COLOMBIER",
      },
      {
        CITY: "CONCISE",
      },
      {
        CITY: "CONFIGNON",
      },
      {
        CITY: "CONTHEY",
      },
      {
        CITY: "CONTONE",
      },
      {
        CITY: "COPPET",
      },
      {
        CITY: "CORCELLES",
      },
      {
        CITY: "CORDAST",
      },
      {
        CITY: "CORGEMONT",
      },
      {
        CITY: "CORMINBOEUF",
      },
      {
        CITY: "CORNAUX",
      },
      {
        CITY: "CORSIER",
      },
      {
        CITY: "CORTAILLOD",
      },
      {
        CITY: "COSSONAY",
      },
      {
        CITY: "COTTENS",
      },
      {
        CITY: "COURFAIVRE",
      },
      {
        CITY: "COURGENAY",
      },
      {
        CITY: "COURRENDLIN",
      },
      {
        CITY: "COURROUX",
      },
      {
        CITY: "COURT",
      },
      {
        CITY: "COURTEDOUX",
      },
      {
        CITY: "COURTELARY",
      },
      {
        CITY: "COURTEMAICHE",
      },
      {
        CITY: "COURTEPIN",
      },
      {
        CITY: "COURTETELLE",
      },
      {
        CITY: "COURTION",
      },
      {
        CITY: "COUVET",
      },
      {
        CITY: "CRASSIER",
      },
      {
        CITY: "CREMINES",
      },
      {
        CITY: "CRESSIER",
      },
      {
        CITY: "CRISSIER",
      },
      {
        CITY: "CUDREFIN",
      },
      {
        CITY: "CUGNASCO",
      },
      {
        CITY: "CUGY",
      },
      {
        CITY: "CULLY",
      },
      {
        CITY: "DACHSEN",
      },
      {
        CITY: "DAETTLIKON",
      },
      {
        CITY: "DAGMERSELLEN",
      },
      {
        CITY: "DAILLENS",
      },
      {
        CITY: "DALLENWIL",
      },
      {
        CITY: "DANIKEN",
      },
      {
        CITY: "DARDAGNY",
      },
      {
        CITY: "DATTWIL",
      },
      {
        CITY: "DAVOS",
      },
      {
        CITY: "DAVOS DORF",
      },
      {
        CITY: "DAVOS PLATZ",
      },
      {
        CITY: "DEGERSHEIM",
      },
      {
        CITY: "DEITINGEN",
      },
      {
        CITY: "DENSBUEREN",
      },
      {
        CITY: "DERENDINGEN",
      },
      {
        CITY: "DIEGTEN",
      },
      {
        CITY: "DIELSDORF",
      },
      {
        CITY: "DIEPOLDSAU",
      },
      {
        CITY: "DIESSBACH",
      },
      {
        CITY: "DIESSENHOFEN",
      },
      {
        CITY: "DIETIKON",
      },
      {
        CITY: "DOETTINGEN",
      },
      {
        CITY: "DOMAT",
      },
      {
        CITY: "DOMBRESSON",
      },
      {
        CITY: "DOMDIDIER",
      },
      {
        CITY: "DOMPIERRE",
      },
      {
        CITY: "DORNACH",
      },
      {
        CITY: "DOTTIKON",
      },
      {
        CITY: "DOZWIL",
      },
      {
        CITY: "DUBENDORF",
      },
      {
        CITY: "DURNTEN",
      },
      {
        CITY: "DURRENASCH",
      },
      {
        CITY: "EBIKON",
      },
      {
        CITY: "EBNAT-KAPPEL",
      },
      {
        CITY: "ECUBLENS",
      },
      {
        CITY: "ECUVILLENS",
      },
      {
        CITY: "EFFRETIKON",
      },
      {
        CITY: "EGERKINGEN",
      },
      {
        CITY: "EGG",
      },
      {
        CITY: "EGGENWIL",
      },
      {
        CITY: "EGGERSRIET",
      },
      {
        CITY: "EGGIWIL",
      },
      {
        CITY: "EGLISAU",
      },
      {
        CITY: "EGLISWIL",
      },
      {
        CITY: "EGNACH",
      },
      {
        CITY: "EICH",
      },
      {
        CITY: "EICHBERG",
      },
      {
        CITY: "EIKEN",
      },
      {
        CITY: "EINIGEN",
      },
      {
        CITY: "EINSIEDELN",
      },
      {
        CITY: "ELGG",
      },
      {
        CITY: "ELLIKON AN DER THUR",
      },
      {
        CITY: "ELSAU-RAETERSCHEN",
      },
      {
        CITY: "EMBRACH",
      },
      {
        CITY: "EMMEN",
      },
      {
        CITY: "EMMENBRUECKE",
      },
      {
        CITY: "EMMETTEN",
      },
      {
        CITY: "ENDINGEN",
      },
      {
        CITY: "ENGELBERG",
      },
      {
        CITY: "ENNENDA",
      },
      {
        CITY: "ENNEY",
      },
      {
        CITY: "ENTLEBUCH",
      },
      {
        CITY: "EPALINGES",
      },
      {
        CITY: "EPAUTHEYRES",
      },
      {
        CITY: "EPENDES",
      },
      {
        CITY: "ERDE",
      },
      {
        CITY: "ERLACH",
      },
      {
        CITY: "ERLEN",
      },
      {
        CITY: "ERLENBACH",
      },
      {
        CITY: "ERMATINGEN",
      },
      {
        CITY: "ERSCHWIL",
      },
      {
        CITY: "ERSIGEN",
      },
      {
        CITY: "ERSTFELD",
      },
      {
        CITY: "ESCHENBACH",
      },
      {
        CITY: "ESCHLIKON",
      },
      {
        CITY: "ESCHOLZMATT",
      },
      {
        CITY: "ESSERTINES-SUR-ROLLE",
      },
      {
        CITY: "ESTAVAYER-LE-LAC",
      },
      {
        CITY: "ETTINGEN",
      },
      {
        CITY: "EUTHAL",
      },
      {
        CITY: "EVILARD",
      },
      {
        CITY: "EVIONNAZ",
      },
      {
        CITY: "EYSINS",
      },
      {
        CITY: "FAHRWANGEN",
      },
      {
        CITY: "FALLANDEN",
      },
      {
        CITY: "FAOUG",
      },
      {
        CITY: "FEHRALTORF",
      },
      {
        CITY: "FELBEN",
      },
      {
        CITY: "FELDBACH",
      },
      {
        CITY: "FELDBRUNNEN",
      },
      {
        CITY: "FELDMEILEN",
      },
      {
        CITY: "FELSBERG",
      },
      {
        CITY: "FELSENAU",
      },
      {
        CITY: "FERDEN",
      },
      {
        CITY: "FERENBALM",
      },
      {
        CITY: "FEUERTHALEN",
      },
      {
        CITY: "FEUSISBERG",
      },
      {
        CITY: "FINHAUT",
      },
      {
        CITY: "FISCHENTHAL",
      },
      {
        CITY: "FISCHINGEN",
      },
      {
        CITY: "FLAACH",
      },
      {
        CITY: "FLAMATT",
      },
      {
        CITY: "FLAWIL",
      },
      {
        CITY: "FLEURIER",
      },
      {
        CITY: "FLIMS",
      },
      {
        CITY: "FLUEHLI",
      },
      {
        CITY: "FLUELI",
      },
      {
        CITY: "FLUMS",
      },
      {
        CITY: "FLURLINGEN",
      },
      {
        CITY: "FONTAINEMELON",
      },
      {
        CITY: "FOREL",
      },
      {
        CITY: "FOUNEX",
      },
      {
        CITY: "FRAUBRUNNEN",
      },
      {
        CITY: "FRAUENFELD",
      },
      {
        CITY: "FREIDORF",
      },
      {
        CITY: "FREIENBACH",
      },
      {
        CITY: "FRENKENDORF",
      },
      {
        CITY: "FRIBOURG",
      },
      {
        CITY: "FRICK",
      },
      {
        CITY: "FROIDEVILLE",
      },
      {
        CITY: "FRUTIGEN",
      },
      {
        CITY: "FULENBACH",
      },
      {
        CITY: "FULL",
      },
      {
        CITY: "FULLINSDORF",
      },
      {
        CITY: "FULLY",
      },
      {
        CITY: "FURSTENAUBRUCK",
      },
      {
        CITY: "GACHLINGEN",
      },
      {
        CITY: "GAIS",
      },
      {
        CITY: "GAMPEL",
      },
      {
        CITY: "GAMPELEN",
      },
      {
        CITY: "GANDRIA",
      },
      {
        CITY: "GANTERSCHWIL",
      },
      {
        CITY: "GEBENSTORF",
      },
      {
        CITY: "GELFINGEN",
      },
      {
        CITY: "GELTERKINDEN",
      },
      {
        CITY: "GENESTRERIO",
      },
      {
        CITY: "GENEVA",
      },
      {
        CITY: "GERLAFINGEN",
      },
      {
        CITY: "GERLIKON",
      },
      {
        CITY: "GERSAU",
      },
      {
        CITY: "GERZENSEE",
      },
      {
        CITY: "GETTNAU",
      },
      {
        CITY: "GEUENSEE",
      },
      {
        CITY: "GIMEL",
      },
      {
        CITY: "GINGINS",
      },
      {
        CITY: "GIPF-OBERFRICK",
      },
      {
        CITY: "GISWIL",
      },
      {
        CITY: "GIUBIASCO",
      },
      {
        CITY: "GIVRINS",
      },
      {
        CITY: "GLAND",
      },
      {
        CITY: "GLARIS",
      },
      {
        CITY: "GLARUS",
      },
      {
        CITY: "GLATTBURG",
      },
      {
        CITY: "GLATTFELDEN",
      },
      {
        CITY: "GLION",
      },
      {
        CITY: "GLIS",
      },
      {
        CITY: "GNOSCA",
      },
      {
        CITY: "GOLDACH",
      },
      {
        CITY: "GOLDAU",
      },
      {
        CITY: "GOLDIWIL",
      },
      {
        CITY: "GOMMISWALD",
      },
      {
        CITY: "GONTEN",
      },
      {
        CITY: "GONTENSCHWIL",
      },
      {
        CITY: "GORDEVIO",
      },
      {
        CITY: "GORDOLA",
      },
      {
        CITY: "GOSLIKON",
      },
      {
        CITY: "GOSSAU",
      },
      {
        CITY: "GOTTLIEBEN",
      },
      {
        CITY: "GRABS",
      },
      {
        CITY: "GRAENICHEN",
      },
      {
        CITY: "GRANCY",
      },
      {
        CITY: "GRANDCOUR",
      },
      {
        CITY: "GRANDSON",
      },
      {
        CITY: "GRANDVAL",
      },
      {
        CITY: "GRANDVILLARD",
      },
      {
        CITY: "GRANGES",
      },
      {
        CITY: "GRANGES-PRES-SION",
      },
      {
        CITY: "GRAVESANO",
      },
      {
        CITY: "GREIFENSEE",
      },
      {
        CITY: "GRELLINGEN",
      },
      {
        CITY: "GRENCHEN",
      },
      {
        CITY: "GREPPEN",
      },
      {
        CITY: "GRIMENTZ",
      },
      {
        CITY: "GRIMISUAT",
      },
      {
        CITY: "GRINDELWALD",
      },
      {
        CITY: "GROLLEY",
      },
      {
        CITY: "GRONE",
      },
      {
        CITY: "GRONO",
      },
      {
        CITY: "GROSSANDELFINGEN",
      },
      {
        CITY: "GROSSWANGEN",
      },
      {
        CITY: "GRUENINGEN",
      },
      {
        CITY: "GRUND",
      },
      {
        CITY: "GRYON",
      },
      {
        CITY: "GSTAAD",
      },
      {
        CITY: "GUMLIGEN",
      },
      {
        CITY: "GUMMENEN",
      },
      {
        CITY: "GUNTEN",
      },
      {
        CITY: "GUNZGEN",
      },
      {
        CITY: "GUTTINGEN",
      },
      {
        CITY: "GWATT",
      },
      {
        CITY: "HABKERN",
      },
      {
        CITY: "HABSBURG",
      },
      {
        CITY: "HAGENBUCH",
      },
      {
        CITY: "HAGENDORF",
      },
      {
        CITY: "HAGGLINGEN",
      },
      {
        CITY: "HAGNECK",
      },
      {
        CITY: "HALDENSTEIN",
      },
      {
        CITY: "HASLE",
      },
      {
        CITY: "HAUPTWIL",
      },
      {
        CITY: "HAUSEN",
      },
      {
        CITY: "HAUSEN AM ALBIS / HAUSEN (DORF)",
      },
      {
        CITY: "HAUTERIVE",
      },
      {
        CITY: "HAUTEVILLE",
      },
      {
        CITY: "HEERBRUGG",
      },
      {
        CITY: "HEIDEN",
      },
      {
        CITY: "HEIMBERG",
      },
      {
        CITY: "HEIMISWIL",
      },
      {
        CITY: "HEITENRIED",
      },
      {
        CITY: "HELLBUEHL",
      },
      {
        CITY: "HEMMENTAL",
      },
      {
        CITY: "HENAU",
      },
      {
        CITY: "HENDSCHIKEN",
      },
      {
        CITY: "HENGGART",
      },
      {
        CITY: "HERBETSWIL",
      },
      {
        CITY: "HERDERN",
      },
      {
        CITY: "HERGISWIL",
      },
      {
        CITY: "HERISAU",
      },
      {
        CITY: "HERMANCE",
      },
      {
        CITY: "HERMETSCHWIL-STAFFELN",
      },
      {
        CITY: "HERRLIBERG",
      },
      {
        CITY: "HERZOGENBUCHSEE",
      },
      {
        CITY: "HETTLINGEN",
      },
      {
        CITY: "HILDISRIEDEN",
      },
      {
        CITY: "HILTERFINGEN",
      },
      {
        CITY: "HIMMELRIED",
      },
      {
        CITY: "HINDELBANK",
      },
      {
        CITY: "HINTERKAPPELEN",
      },
      {
        CITY: "HINWIL",
      },
      {
        CITY: "HIRZEL-KIRCHE",
      },
      {
        CITY: "HITTNAU",
      },
      {
        CITY: "HITZKIRCH",
      },
      {
        CITY: "HOCHDORF",
      },
      {
        CITY: "HOCHWALD",
      },
      {
        CITY: "HOERSTETTEN",
      },
      {
        CITY: "HOFSTETTEN",
      },
      {
        CITY: "HOLDERBANK",
      },
      {
        CITY: "HOLSTEIN",
      },
      {
        CITY: "HOMBRECHTIKON",
      },
      {
        CITY: "HORGEN",
      },
      {
        CITY: "HORN",
      },
      {
        CITY: "HORNUSSEN",
      },
      {
        CITY: "HORW",
      },
      {
        CITY: "HUNDWIL",
      },
      {
        CITY: "HUNENBERG",
      },
      {
        CITY: "HUNIBACH",
      },
      {
        CITY: "HUNTWANGEN",
      },
      {
        CITY: "HUNZENSCHWIL",
      },
      {
        CITY: "HUTTWIL",
      },
      {
        CITY: "IBACH",
      },
      {
        CITY: "IGIS",
      },
      {
        CITY: "ILANZ",
      },
      {
        CITY: "ILLNAU",
      },
      {
        CITY: "IMMENSEE",
      },
      {
        CITY: "INGENBOHL",
      },
      {
        CITY: "INS",
      },
      {
        CITY: "INTERLAKEN",
      },
      {
        CITY: "INWIL",
      },
      {
        CITY: "IRAGNA",
      },
      {
        CITY: "ISEO",
      },
      {
        CITY: "ISERABLES",
      },
      {
        CITY: "ISLIKON",
      },
      {
        CITY: "ITTENTHAL",
      },
      {
        CITY: "JEGENSTORF",
      },
      {
        CITY: "JENAZ",
      },
      {
        CITY: "JONA",
      },
      {
        CITY: "JONEN",
      },
      {
        CITY: "JONSCHWIL",
      },
      {
        CITY: "JUSSY",
      },
      {
        CITY: "KAISERAUGST",
      },
      {
        CITY: "KAISERSTUHL",
      },
      {
        CITY: "KAISTEN",
      },
      {
        CITY: "KALLNACH",
      },
      {
        CITY: "KALTBRUNN",
      },
      {
        CITY: "KAPPEL",
      },
      {
        CITY: "KASTANIENBAUM",
      },
      {
        CITY: "KEFIKON",
      },
      {
        CITY: "KEHRSATZ",
      },
      {
        CITY: "KEMPTTAL",
      },
      {
        CITY: "KERNENRIED",
      },
      {
        CITY: "KERNS",
      },
      {
        CITY: "KERZERS",
      },
      {
        CITY: "KIESEN",
      },
      {
        CITY: "KILCHBERG",
      },
      {
        CITY: "KILLWANGEN",
      },
      {
        CITY: "KIRCHBERG",
      },
      {
        CITY: "KIRCHDORF",
      },
      {
        CITY: "KLEINLUTZEL",
      },
      {
        CITY: "KLINGNAU",
      },
      {
        CITY: "KLOSTERS PLATZ",
      },
      {
        CITY: "KLOSTERS SERNEUS",
      },
      {
        CITY: "KLOTEN",
      },
      {
        CITY: "KOBLENZ",
      },
      {
        CITY: "KOLLBRUNN",
      },
      {
        CITY: "KOLLIKEN",
      },
      {
        CITY: "KONOLFINGEN",
      },
      {
        CITY: "KOPPIGEN",
      },
      {
        CITY: "KRADOLF",
      },
      {
        CITY: "KRAUCHTHAL",
      },
      {
        CITY: "KREUZLINGEN",
      },
      {
        CITY: "KRIEGSTETTEN",
      },
      {
        CITY: "KRIENS",
      },
      {
        CITY: "KRONBUHL",
      },
      {
        CITY: "KUESNACHT",
      },
      {
        CITY: "KULM",
      },
      {
        CITY: "LA CHAUX",
      },
      {
        CITY: "LA CHAUX-DE-FONDS",
      },
      {
        CITY: "LA CHIESAZ",
      },
      {
        CITY: "LA NEUVEVILLE",
      },
      {
        CITY: "LA PLAINE",
      },
      {
        CITY: "LA ROCHE",
      },
      {
        CITY: "LA SARRAZ",
      },
      {
        CITY: "LA TOUR-DE-PEILZ",
      },
      {
        CITY: "LAAX",
      },
      {
        CITY: "LACHEN",
      },
      {
        CITY: "LAMBOING",
      },
      {
        CITY: "LANDQUART",
      },
      {
        CITY: "LANGENBRUCK",
      },
      {
        CITY: "LANGENTHAL",
      },
      {
        CITY: "LANGNAU",
      },
      {
        CITY: "LANGNAU AM ALBIS",
      },
      {
        CITY: "LAUENEN",
      },
      {
        CITY: "LAUERZ",
      },
      {
        CITY: "LAUFELFINGEN",
      },
      {
        CITY: "LAUFEN",
      },
      {
        CITY: "LAUFENBURG",
      },
      {
        CITY: "LAUPEN",
      },
      {
        CITY: "LAUSANNE",
      },
      {
        CITY: "LAUSEN",
      },
      {
        CITY: "LE BRASSUS",
      },
      {
        CITY: "LE BRY",
      },
      {
        CITY: "LE CRET",
      },
      {
        CITY: "LE GRAND-SACONNEX",
      },
      {
        CITY: "LE LANDERON",
      },
      {
        CITY: "LE LOCLE",
      },
      {
        CITY: "LE MONT-SUR-LAUSANNE",
      },
      {
        CITY: "LE NOIRMONT",
      },
      {
        CITY: "LE SENTIER",
      },
      {
        CITY: "LE VAUD",
      },
      {
        CITY: "LEIBSTADT",
      },
      {
        CITY: "LENGNAU",
      },
      {
        CITY: "LENK",
      },
      {
        CITY: "LENZ",
      },
      {
        CITY: "LENZBURG",
      },
      {
        CITY: "LENZERHEIDE",
      },
      {
        CITY: "LES ACACIAS",
      },
      {
        CITY: "LES BREULEUX",
      },
      {
        CITY: "LES DIABLERETS",
      },
      {
        CITY: "LES GENEVEYS-SUR-COFFRANE",
      },
      {
        CITY: "LES GENEVEZ",
      },
      {
        CITY: "LES HAUTS-GENEVEYS",
      },
      {
        CITY: "LES POMMERATS",
      },
      {
        CITY: "LES VERRIERES",
      },
      {
        CITY: "LEUKERBAD",
      },
      {
        CITY: "LEUTWIL",
      },
      {
        CITY: "LEYSIN",
      },
      {
        CITY: "LICHTENSTEIG",
      },
      {
        CITY: "LIDDES",
      },
      {
        CITY: "LIEBISTORF",
      },
      {
        CITY: "LIENZ",
      },
      {
        CITY: "LIESTAL",
      },
      {
        CITY: "LIGERZ",
      },
      {
        CITY: "LIGNIERES",
      },
      {
        CITY: "LINDAU",
      },
      {
        CITY: "LITTAU",
      },
      {
        CITY: "LOCARNO",
      },
      {
        CITY: "LOCO",
      },
      {
        CITY: "LODRINO",
      },
      {
        CITY: "LOHN",
      },
      {
        CITY: "LOMMIS",
      },
      {
        CITY: "LOSONE",
      },
      {
        CITY: "LOSTORF",
      },
      {
        CITY: "LOTZWIL",
      },
      {
        CITY: "LUCENS",
      },
      {
        CITY: "LUCERNE",
      },
      {
        CITY: "LUGANO",
      },
      {
        CITY: "LUNGERN",
      },
      {
        CITY: "LURTIGEN",
      },
      {
        CITY: "LUTISBURG",
      },
      {
        CITY: "LUTRY",
      },
      {
        CITY: "LUVIS",
      },
      {
        CITY: "LYSS",
      },
      {
        CITY: "LYSSACH",
      },
      {
        CITY: "MAERSTETTEN-DORF",
      },
      {
        CITY: "MAGADINO",
      },
      {
        CITY: "MAGDEN",
      },
      {
        CITY: "MAGGIA",
      },
      {
        CITY: "MAISPRACH",
      },
      {
        CITY: "MALLERAY",
      },
      {
        CITY: "MALTERS",
      },
      {
        CITY: "MANNEDORF",
      },
      {
        CITY: "MANNO",
      },
      {
        CITY: "MARIN",
      },
      {
        CITY: "MAROGGIA",
      },
      {
        CITY: "MARSENS",
      },
      {
        CITY: "MARTIGNY-CROIX",
      },
      {
        CITY: "MARTIGNY-VILLE",
      },
      {
        CITY: "MASCHWANDEN",
      },
      {
        CITY: "MASSAGNO",
      },
      {
        CITY: "MATT",
      },
      {
        CITY: "MATZENDORF",
      },
      {
        CITY: "MATZINGEN",
      },
      {
        CITY: "MAUENSEE",
      },
      {
        CITY: "MAUR",
      },
      {
        CITY: "MEGGEN",
      },
      {
        CITY: "MEIERSKAPPEL",
      },
      {
        CITY: "MEILEN",
      },
      {
        CITY: "MEINIER",
      },
      {
        CITY: "MEINISBERG",
      },
      {
        CITY: "MEIRINGEN",
      },
      {
        CITY: "MEISTERSCHWANDEN",
      },
      {
        CITY: "MELANO",
      },
      {
        CITY: "MELCHNAU",
      },
      {
        CITY: "MELIDE",
      },
      {
        CITY: "MELLINGEN",
      },
      {
        CITY: "MELS",
      },
      {
        CITY: "MENDRISIO",
      },
      {
        CITY: "MENZIKEN",
      },
      {
        CITY: "MENZINGEN",
      },
      {
        CITY: "MERENSCHWAND",
      },
      {
        CITY: "MERISHAUSEN",
      },
      {
        CITY: "MESSEN",
      },
      {
        CITY: "METTENDORF",
      },
      {
        CITY: "METTLEN",
      },
      {
        CITY: "METTMENSTETTEN",
      },
      {
        CITY: "MEYRIN",
      },
      {
        CITY: "MIECOURT",
      },
      {
        CITY: "MINUSIO",
      },
      {
        CITY: "MOHLIN",
      },
      {
        CITY: "MOLLIS",
      },
      {
        CITY: "MONCHALTORF",
      },
      {
        CITY: "MONTAGNOLA",
      },
      {
        CITY: "MONTANA",
      },
      {
        CITY: "MONTET",
      },
      {
        CITY: "MONTHEY",
      },
      {
        CITY: "MONTREUX",
      },
      {
        CITY: "MOOSLEERAU",
      },
      {
        CITY: "MORBIO INFERIORE",
      },
      {
        CITY: "MORCOTE",
      },
      {
        CITY: "MORGES",
      },
      {
        CITY: "MORGINS",
      },
      {
        CITY: "MORIGEN",
      },
      {
        CITY: "MORIKEN",
      },
      {
        CITY: "MORSCHACH",
      },
      {
        CITY: "MORSCHWIL",
      },
      {
        CITY: "MOSNANG",
      },
      {
        CITY: "MOTIERS",
      },
      {
        CITY: "MOUDON",
      },
      {
        CITY: "MOUTIER",
      },
      {
        CITY: "MUERREN",
      },
      {
        CITY: "MUHEN",
      },
      {
        CITY: "MUHLAU",
      },
      {
        CITY: "MUHLEBERG",
      },
      {
        CITY: "MULLHEIM",
      },
      {
        CITY: "MUMLISWIL",
      },
      {
        CITY: "MUMPF",
      },
      {
        CITY: "MUNCHENBUCHSEE",
      },
      {
        CITY: "MUNCHENSTEIN",
      },
      {
        CITY: "MUNCHWILEN",
      },
      {
        CITY: "MUNSINGEN",
      },
      {
        CITY: "MUNSTER",
      },
      {
        CITY: "MUOLEN",
      },
      {
        CITY: "MUOTATHAL",
      },
      {
        CITY: "MURGENTHAL",
      },
      {
        CITY: "MURI",
      },
      {
        CITY: "MURI BEI BERN",
      },
      {
        CITY: "MURTEN",
      },
      {
        CITY: "MUTTENZ",
      },
      {
        CITY: "NAFELS",
      },
      {
        CITY: "NANIKON",
      },
      {
        CITY: "NATERS",
      },
      {
        CITY: "NAX",
      },
      {
        CITY: "NEFTENBACH",
      },
      {
        CITY: "NESSLAU",
      },
      {
        CITY: "NETSTAL",
      },
      {
        CITY: "NEU-RHEINAU",
      },
      {
        CITY: "NEUCHATEL",
      },
      {
        CITY: "NEUENHOF",
      },
      {
        CITY: "NEUENKIRCH",
      },
      {
        CITY: "NEUHAUSEN",
      },
      {
        CITY: "NEUHEIM",
      },
      {
        CITY: "NEUKIRCH",
      },
      {
        CITY: "NEUNKIRCH",
      },
      {
        CITY: "NEYRUZ",
      },
      {
        CITY: "NIDAU",
      },
      {
        CITY: "NIEDERBIPP",
      },
      {
        CITY: "NIEDERBUCHSITEN",
      },
      {
        CITY: "NIEDERBUREN",
      },
      {
        CITY: "NIEDERERLINSBACH",
      },
      {
        CITY: "NIEDERGOSGEN",
      },
      {
        CITY: "NIEDERHALLWIL",
      },
      {
        CITY: "NIEDERHELFENSCHWIL",
      },
      {
        CITY: "NIEDERLENZ",
      },
      {
        CITY: "NIEDERROHRDORF",
      },
      {
        CITY: "NIEDERSCHERLI",
      },
      {
        CITY: "NIEDERURNEN",
      },
      {
        CITY: "NIEDERUZWIL",
      },
      {
        CITY: "NIEDERWENINGEN",
      },
      {
        CITY: "NOHL",
      },
      {
        CITY: "NOREAZ",
      },
      {
        CITY: "NOTTWIL",
      },
      {
        CITY: "NOVAGGIO",
      },
      {
        CITY: "NOVAZZANO",
      },
      {
        CITY: "NUNNINGEN",
      },
      {
        CITY: "NURENSDORF",
      },
      {
        CITY: "NUSSBAUMEN",
      },
      {
        CITY: "NYON",
      },
      {
        CITY: "OBER URDORF",
      },
      {
        CITY: "OBERBALM",
      },
      {
        CITY: "OBERBIPP",
      },
      {
        CITY: "OBERBUCHSITEN",
      },
      {
        CITY: "OBERBURG",
      },
      {
        CITY: "OBERDIESSBACH",
      },
      {
        CITY: "OBERDORF",
      },
      {
        CITY: "OBEREGG",
      },
      {
        CITY: "OBEREMBRACH",
      },
      {
        CITY: "OBERENGSTRINGEN",
      },
      {
        CITY: "OBERENTFELDEN",
      },
      {
        CITY: "OBERGERLAFINGEN",
      },
      {
        CITY: "OBERGLATT",
      },
      {
        CITY: "OBERGOSGEN",
      },
      {
        CITY: "OBERHELFENSCHWIL",
      },
      {
        CITY: "OBERHOFEN",
      },
      {
        CITY: "OBERHOFEN BEI ETZGEN",
      },
      {
        CITY: "OBERKIRCH",
      },
      {
        CITY: "OBERLUNKHOFEN",
      },
      {
        CITY: "OBERONZ",
      },
      {
        CITY: "OBERRIEDEN",
      },
      {
        CITY: "OBERRIET",
      },
      {
        CITY: "OBERSAXEN",
      },
      {
        CITY: "OBERSCHLATT",
      },
      {
        CITY: "OBERSTOCKEN",
      },
      {
        CITY: "OBERUZWIL",
      },
      {
        CITY: "OBERVAZ",
      },
      {
        CITY: "OBERWANGEN",
      },
      {
        CITY: "OBERWENINGEN",
      },
      {
        CITY: "OBERWIL",
      },
      {
        CITY: "OBFELDEN",
      },
      {
        CITY: "OENSINGEN",
      },
      {
        CITY: "OETWIL / OETWIL AN DER LIMMAT",
      },
      {
        CITY: "OETWIL AM SEE",
      },
      {
        CITY: "OFTRINGEN",
      },
      {
        CITY: "OLTEN",
      },
      {
        CITY: "ONEX",
      },
      {
        CITY: "ONNENS",
      },
      {
        CITY: "OPFIKON",
      },
      {
        CITY: "ORBE",
      },
      {
        CITY: "ORMALINGEN",
      },
      {
        CITY: "ORON-LA-VILLE",
      },
      {
        CITY: "ORPUND",
      },
      {
        CITY: "ORSONNENS",
      },
      {
        CITY: "ORVIN",
      },
      {
        CITY: "OSSINGEN",
      },
      {
        CITY: "OSTERMUNDIGEN",
      },
      {
        CITY: "OTELFINGEN",
      },
      {
        CITY: "OTHMARSINGEN",
      },
      {
        CITY: "OTTENBACH",
      },
      {
        CITY: "OUCHY",
      },
      {
        CITY: "OVRONNAZ",
      },
      {
        CITY: "PALEZIEUX",
      },
      {
        CITY: "PAMPIGNY",
      },
      {
        CITY: "PAUDEX",
      },
      {
        CITY: "PAYERNE",
      },
      {
        CITY: "PERLEN",
      },
      {
        CITY: "PERROY",
      },
      {
        CITY: "PERY",
      },
      {
        CITY: "PESEUX",
      },
      {
        CITY: "PFAEFFIKON",
      },
      {
        CITY: "PFAFFNAU",
      },
      {
        CITY: "PFEFFIKON",
      },
      {
        CITY: "PFEFFINGEN",
      },
      {
        CITY: "PFUNGEN",
      },
      {
        CITY: "PFYN",
      },
      {
        CITY: "PIETERLEN",
      },
      {
        CITY: "PLAFFEIEN",
      },
      {
        CITY: "PLAN-LES-OUATES",
      },
      {
        CITY: "PORRENTRUY",
      },
      {
        CITY: "PORSEL",
      },
      {
        CITY: "PORTALBAN- DESSOUS",
      },
      {
        CITY: "PRANGINS",
      },
      {
        CITY: "PRATTELN",
      },
      {
        CITY: "PREGASSONA",
      },
      {
        CITY: "PRELES",
      },
      {
        CITY: "PREVERENGES",
      },
      {
        CITY: "PRILLY",
      },
      {
        CITY: "PUIDOUX",
      },
      {
        CITY: "PULLY",
      },
      {
        CITY: "PURA",
      },
      {
        CITY: "QUARTINO",
      },
      {
        CITY: "RAFZ",
      },
      {
        CITY: "RAMSEN",
      },
      {
        CITY: "RANCES",
      },
      {
        CITY: "RAPERSWILEN",
      },
      {
        CITY: "RAPPERSWIL",
      },
      {
        CITY: "RARON",
      },
      {
        CITY: "REBSTEIN",
      },
      {
        CITY: "RECHTHALTEN",
      },
      {
        CITY: "RECONVILIER",
      },
      {
        CITY: "REGENSDORF",
      },
      {
        CITY: "REHETOBEL",
      },
      {
        CITY: "REICHENBURG",
      },
      {
        CITY: "REIDEN",
      },
      {
        CITY: "REIGOLDSWIL",
      },
      {
        CITY: "REINACH",
      },
      {
        CITY: "REKINGEN",
      },
      {
        CITY: "RENENS",
      },
      {
        CITY: "RHAZUNS",
      },
      {
        CITY: "RHEINECK",
      },
      {
        CITY: "RHEINFELDEN",
      },
      {
        CITY: "RIAZ",
      },
      {
        CITY: "RICHENTHAL",
      },
      {
        CITY: "RICHIGEN",
      },
      {
        CITY: "RICHTERSWIL",
      },
      {
        CITY: "RICKEN",
      },
      {
        CITY: "RICKENBACH",
      },
      {
        CITY: "RIDDES",
      },
      {
        CITY: "RIED",
      },
      {
        CITY: "RIEDHOLZ",
      },
      {
        CITY: "RIFFERSWIL",
      },
      {
        CITY: "RIGGISBERG",
      },
      {
        CITY: "RIKON / RIKON (DORFKERN)",
      },
      {
        CITY: "RINGGENBERG",
      },
      {
        CITY: "RIVA SAN VITALE",
      },
      {
        CITY: "ROCHE",
      },
      {
        CITY: "ROEMERSWIL",
      },
      {
        CITY: "ROGGWIL",
      },
      {
        CITY: "ROHR",
      },
      {
        CITY: "ROHRBACH",
      },
      {
        CITY: "ROLLE",
      },
      {
        CITY: "ROMAINMOTIER",
      },
      {
        CITY: "ROMANEL",
      },
      {
        CITY: "ROMANSHORN",
      },
      {
        CITY: "ROMONT",
      },
      {
        CITY: "RONDCHATEL",
      },
      {
        CITY: "ROOT",
      },
      {
        CITY: "RORBAS",
      },
      {
        CITY: "RORSCHACH",
      },
      {
        CITY: "ROSCHENZ",
      },
      {
        CITY: "ROSSENS",
      },
      {
        CITY: "ROTHENBACH",
      },
      {
        CITY: "ROTHENTHURM",
      },
      {
        CITY: "ROTHRIST",
      },
      {
        CITY: "ROTKREUZ",
      },
      {
        CITY: "ROVEREDO",
      },
      {
        CITY: "RUBIGEN",
      },
      {
        CITY: "RUDLINGEN",
      },
      {
        CITY: "RUDOLFSTETTEN",
      },
      {
        CITY: "RUE",
      },
      {
        CITY: "RUEGGISBERG",
      },
      {
        CITY: "RUETI",
      },
      {
        CITY: "RUEYRES",
      },
      {
        CITY: "RUFI",
      },
      {
        CITY: "RUMLANG",
      },
      {
        CITY: "RUPPERSWIL",
      },
      {
        CITY: "RUSCHLIKON",
      },
      {
        CITY: "RUSSIKON",
      },
      {
        CITY: "RUSSIN",
      },
      {
        CITY: "RUSWIL",
      },
      {
        CITY: "RUTHI",
      },
      {
        CITY: "SAANEN",
      },
      {
        CITY: "SAANENMOSER",
      },
      {
        CITY: "SAAS-FEE",
      },
      {
        CITY: "SAAS-GRUND",
      },
      {
        CITY: "SACHSELN",
      },
      {
        CITY: "SAFENWIL",
      },
      {
        CITY: "SAIGNELEGIER",
      },
      {
        CITY: "SAINT-AUBIN",
      },
      {
        CITY: "SAINT-AUBIN-SAUGES",
      },
      {
        CITY: "SAINT-BLAISE",
      },
      {
        CITY: "SAINT-CERGUE",
      },
      {
        CITY: "SAINT-IMIER",
      },
      {
        CITY: "SAINT-LEONARD",
      },
      {
        CITY: "SAINT-LIVRES",
      },
      {
        CITY: "SAINT-MAURICE",
      },
      {
        CITY: "SAINT-PREX",
      },
      {
        CITY: "SAINT-SULPICE",
      },
      {
        CITY: "SAINTE-CROIX",
      },
      {
        CITY: "SALMSACH",
      },
      {
        CITY: "SAMSTAGERN",
      },
      {
        CITY: "SANKT GALLENKAPPEL",
      },
      {
        CITY: "SANKT MARGRETHEN",
      },
      {
        CITY: "SARGANS",
      },
      {
        CITY: "SARISWIL",
      },
      {
        CITY: "SARMENSTORF",
      },
      {
        CITY: "SARN",
      },
      {
        CITY: "SARNEN",
      },
      {
        CITY: "SATIGNY",
      },
      {
        CITY: "SATTEL",
      },
      {
        CITY: "SAVIESE",
      },
      {
        CITY: "SAVIGNY",
      },
      {
        CITY: "SAVOGNIN",
      },
      {
        CITY: "SAXON",
      },
      {
        CITY: "SCHACHEN",
      },
      {
        CITY: "SCHAFFHAUSEN",
      },
      {
        CITY: "SCHAFISHEIM",
      },
      {
        CITY: "SCHANIS",
      },
      {
        CITY: "SCHATTDORF",
      },
      {
        CITY: "SCHENKON",
      },
      {
        CITY: "SCHIERS",
      },
      {
        CITY: "SCHINDELLEGI",
      },
      {
        CITY: "SCHINZNACH BAD",
      },
      {
        CITY: "SCHINZNACH DORF",
      },
      {
        CITY: "SCHLATT",
      },
      {
        CITY: "SCHLEITHEIM",
      },
      {
        CITY: "SCHLIEREN",
      },
      {
        CITY: "SCHMERIKON",
      },
      {
        CITY: "SCHMITTEN",
      },
      {
        CITY: "SCHNOTTWIL",
      },
      {
        CITY: "SCHOETZ",
      },
      {
        CITY: "SCHOFTLAND",
      },
      {
        CITY: "SCHONBUHL",
      },
      {
        CITY: "SCHONENBERG",
      },
      {
        CITY: "SCHONENBUCH",
      },
      {
        CITY: "SCHONENWERD",
      },
      {
        CITY: "SCHONGAU",
      },
      {
        CITY: "SCHONHOLZERSWILEN",
      },
      {
        CITY: "SCHONRIED",
      },
      {
        CITY: "SCHUBELBACH",
      },
      {
        CITY: "SCHUEPFHEIM",
      },
      {
        CITY: "SCHUPFEN",
      },
      {
        CITY: "SCHWARZENBERG",
      },
      {
        CITY: "SCHWARZENBURG",
      },
      {
        CITY: "SCHWELLBRUNN",
      },
      {
        CITY: "SCHWYZ",
      },
      {
        CITY: "SCUOL",
      },
      {
        CITY: "SEEDORF",
      },
      {
        CITY: "SEEGRABEN",
      },
      {
        CITY: "SEELISBERG",
      },
      {
        CITY: "SEENGEN",
      },
      {
        CITY: "SEEWIS IM PRATIGAU",
      },
      {
        CITY: "SEFTIGEN",
      },
      {
        CITY: "SELTISBERG",
      },
      {
        CITY: "SELZACH",
      },
      {
        CITY: "SEMBRANCHER",
      },
      {
        CITY: "SEMENTINA",
      },
      {
        CITY: "SEMPACH",
      },
      {
        CITY: "SEMSALES",
      },
      {
        CITY: "SENNWALD",
      },
      {
        CITY: "SEON",
      },
      {
        CITY: "SESSA",
      },
      {
        CITY: "SEUZACH DORF",
      },
      {
        CITY: "SEVELEN",
      },
      {
        CITY: "SEZEGNIN",
      },
      {
        CITY: "SIEBNEN",
      },
      {
        CITY: "SIERRE",
      },
      {
        CITY: "SIGLISTORF",
      },
      {
        CITY: "SIGRISWIL",
      },
      {
        CITY: "SILS-SEGL MARIA",
      },
      {
        CITY: "SINS",
      },
      {
        CITY: "SION",
      },
      {
        CITY: "SIRNACH",
      },
      {
        CITY: "SISELEN",
      },
      {
        CITY: "SISSACH",
      },
      {
        CITY: "SIVIRIEZ",
      },
      {
        CITY: "SOLOTHURN",
      },
      {
        CITY: "SONCEBOZ",
      },
      {
        CITY: "SONVICO",
      },
      {
        CITY: "SONVILIER",
      },
      {
        CITY: "SORENGO",
      },
      {
        CITY: "SOTTENS",
      },
      {
        CITY: "SOYHIERES",
      },
      {
        CITY: "SPEICHER",
      },
      {
        CITY: "SPIEZ",
      },
      {
        CITY: "SPREITENBACH",
      },
      {
        CITY: "ST. GALLEN",
      },
      {
        CITY: "STABIO",
      },
      {
        CITY: "STADEL",
      },
      {
        CITY: "STAFA",
      },
      {
        CITY: "STALLIKON",
      },
      {
        CITY: "STANS",
      },
      {
        CITY: "STANSSTAD",
      },
      {
        CITY: "STAUFEN",
      },
      {
        CITY: "STECKBORN",
      },
      {
        CITY: "STEFFISBURG",
      },
      {
        CITY: "STEG",
      },
      {
        CITY: "STEIN",
      },
      {
        CITY: "STEIN AM RHEIN",
      },
      {
        CITY: "STEINEN",
      },
      {
        CITY: "STEINHAUSEN",
      },
      {
        CITY: "STETTFURT",
      },
      {
        CITY: "STETTLEN",
      },
      {
        CITY: "STOOS",
      },
      {
        CITY: "STRENGELBACH",
      },
      {
        CITY: "SUBINGEN",
      },
      {
        CITY: "SUHR",
      },
      {
        CITY: "SULGEN",
      },
      {
        CITY: "SULZ",
      },
      {
        CITY: "SUMISWALD",
      },
      {
        CITY: "SURSEE",
      },
      {
        CITY: "TAFERS",
      },
      {
        CITY: "TAGERWILEN",
      },
      {
        CITY: "TAMINS",
      },
      {
        CITY: "TANAY",
      },
      {
        CITY: "TANN",
      },
      {
        CITY: "TASCH",
      },
      {
        CITY: "TAUFFELEN",
      },
      {
        CITY: "TAVANNES",
      },
      {
        CITY: "TEGERFELDEN",
      },
      {
        CITY: "TENERO",
      },
      {
        CITY: "TERMEN",
      },
      {
        CITY: "TERRITET",
      },
      {
        CITY: "TESSERETE",
      },
      {
        CITY: "TEUFEN AR",
      },
      {
        CITY: "TEUFENTHAL",
      },
      {
        CITY: "THALHEIM",
      },
      {
        CITY: "THALWIL",
      },
      {
        CITY: "THAYNGEN",
      },
      {
        CITY: "THERWIL",
      },
      {
        CITY: "THIELLE",
      },
      {
        CITY: "THIERACHERN",
      },
      {
        CITY: "THIERRENS",
      },
      {
        CITY: "THONEX",
      },
      {
        CITY: "THORISHAUS",
      },
      {
        CITY: "THUN",
      },
      {
        CITY: "THUNDORF",
      },
      {
        CITY: "THUSIS",
      },
      {
        CITY: "TOFFEN",
      },
      {
        CITY: "TRAMELAN",
      },
      {
        CITY: "TRASADINGEN",
      },
      {
        CITY: "TRAVERS",
      },
      {
        CITY: "TREYVAUX",
      },
      {
        CITY: "TRIENGEN",
      },
      {
        CITY: "TRIMBACH",
      },
      {
        CITY: "TRIMMIS",
      },
      {
        CITY: "TROGEN",
      },
      {
        CITY: "TROISTORRENTS",
      },
      {
        CITY: "TRUBBACH",
      },
      {
        CITY: "TRUBSCHACHEN",
      },
      {
        CITY: "TRUN",
      },
      {
        CITY: "TSCHUGGEN",
      },
      {
        CITY: "TUGGEN",
      },
      {
        CITY: "TURBENTHAL",
      },
      {
        CITY: "TURGI",
      },
      {
        CITY: "TWANN",
      },
      {
        CITY: "UDLIGENSWIL",
      },
      {
        CITY: "UEBERSTORF",
      },
      {
        CITY: "UERIKON",
      },
      {
        CITY: "UERKHEIM",
      },
      {
        CITY: "UETENDORF",
      },
      {
        CITY: "UETIKON",
      },
      {
        CITY: "UFHUSEN",
      },
      {
        CITY: "UHWIESEN",
      },
      {
        CITY: "UITIKON",
      },
      {
        CITY: "ULRICHEN",
      },
      {
        CITY: "UNTER-TEUFEN",
      },
      {
        CITY: "UNTERAGERI",
      },
      {
        CITY: "UNTEREHRENDINGEN",
      },
      {
        CITY: "UNTERENGSTRINGEN",
      },
      {
        CITY: "UNTERERLINSBACH",
      },
      {
        CITY: "UNTERIBERG",
      },
      {
        CITY: "UNTERKULM",
      },
      {
        CITY: "UNTERLUNKHOFEN",
      },
      {
        CITY: "UNTERSEEN",
      },
      {
        CITY: "UNTERSIGGENTHAL",
      },
      {
        CITY: "UNTERVAZ",
      },
      {
        CITY: "URSENBACH",
      },
      {
        CITY: "URTENEN",
      },
      {
        CITY: "USTER",
      },
      {
        CITY: "UTZENSTORF",
      },
      {
        CITY: "UZNACH",
      },
      {
        CITY: "UZWIL",
      },
      {
        CITY: "VACALLO",
      },
      {
        CITY: "VALLORBE",
      },
      {
        CITY: "VANDOEUVRES",
      },
      {
        CITY: "VAUDERENS",
      },
      {
        CITY: "VAULRUZ",
      },
      {
        CITY: "VELTHEIM",
      },
      {
        CITY: "VERBIER",
      },
      {
        CITY: "VERCORIN",
      },
      {
        CITY: "VERNAYAZ",
      },
      {
        CITY: "VERNIER",
      },
      {
        CITY: "VERSCIO",
      },
      {
        CITY: "VERSOIX",
      },
      {
        CITY: "VESENAZ",
      },
      {
        CITY: "VESSY",
      },
      {
        CITY: "VETROZ",
      },
      {
        CITY: "VEVEY",
      },
      {
        CITY: "VEX",
      },
      {
        CITY: "VEYRIER",
      },
      {
        CITY: "VEYTAUX",
      },
      {
        CITY: "VICQUES",
      },
      {
        CITY: "VIGANELLO",
      },
      {
        CITY: "VILLA",
      },
      {
        CITY: "VILLARS",
      },
      {
        CITY: "VILLARS-SUR-GLANE",
      },
      {
        CITY: "VILLARS-SUR-OLLON",
      },
      {
        CITY: "VILLAZ-SAINT-PIERRE",
      },
      {
        CITY: "VILLENEUVE",
      },
      {
        CITY: "VILLERET",
      },
      {
        CITY: "VILLIGEN",
      },
      {
        CITY: "VILLMERGEN",
      },
      {
        CITY: "VILLNACHERN",
      },
      {
        CITY: "VILTERS",
      },
      {
        CITY: "VINZEL",
      },
      {
        CITY: "VIONNAZ",
      },
      {
        CITY: "VISP",
      },
      {
        CITY: "VITZNAU",
      },
      {
        CITY: "VOLKETSWIL",
      },
      {
        CITY: "VORDEMWALD",
      },
      {
        CITY: "VOUVRY",
      },
      {
        CITY: "VUADENS",
      },
      {
        CITY: "VUITEBOEUF",
      },
      {
        CITY: "VULLIERENS",
      },
      {
        CITY: "WABERN",
      },
      {
        CITY: "WADENSWIL",
      },
      {
        CITY: "WAGENHAUSEN",
      },
      {
        CITY: "WAHLEN",
      },
      {
        CITY: "WALCHWIL",
      },
      {
        CITY: "WALD",
      },
      {
        CITY: "WALDENBURG",
      },
      {
        CITY: "WALDHAUS",
      },
      {
        CITY: "WALDKIRCH",
      },
      {
        CITY: "WALDSTATT",
      },
      {
        CITY: "WALKRINGEN",
      },
      {
        CITY: "WALLISELLEN",
      },
      {
        CITY: "WALTENSCHWIL",
      },
      {
        CITY: "WALZENHAUSEN",
      },
      {
        CITY: "WANGEN",
      },
      {
        CITY: "WANGEN AN DER AARE",
      },
      {
        CITY: "WANGI",
      },
      {
        CITY: "WANGS",
      },
      {
        CITY: "WASEN",
      },
      {
        CITY: "WATTWIL",
      },
      {
        CITY: "WAUWIL",
      },
      {
        CITY: "WEESEN",
      },
      {
        CITY: "WEGENSTETTEN",
      },
      {
        CITY: "WEGGIS",
      },
      {
        CITY: "WEINFELDEN",
      },
      {
        CITY: "WEININGEN",
      },
      {
        CITY: "WEISSBAD",
      },
      {
        CITY: "WEISSLINGEN",
      },
      {
        CITY: "WELSCHENROHR",
      },
      {
        CITY: "WENGEN",
      },
      {
        CITY: "WERTHENSTEIN",
      },
      {
        CITY: "WETTINGEN",
      },
      {
        CITY: "WETZIKON",
      },
      {
        CITY: "WICHTRACH",
      },
      {
        CITY: "WIEDLISBACH",
      },
      {
        CITY: "WIESENDANGEN",
      },
      {
        CITY: "WIGOLTINGEN",
      },
      {
        CITY: "WIKON",
      },
      {
        CITY: "WIL",
      },
      {
        CITY: "WILA",
      },
      {
        CITY: "WILDEGG",
      },
      {
        CITY: "WILDERSWIL",
      },
      {
        CITY: "WILEROLTIGEN",
      },
      {
        CITY: "WILLISAU",
      },
      {
        CITY: "WIMMIS",
      },
      {
        CITY: "WINDISCH",
      },
      {
        CITY: "WINKEL",
      },
      {
        CITY: "WINTERBERG",
      },
      {
        CITY: "WINTERTHUR",
      },
      {
        CITY: "WITTENBACH",
      },
      {
        CITY: "WITTNAU",
      },
      {
        CITY: "WOHLEN",
      },
      {
        CITY: "WOLFENSCHIESSEN",
      },
      {
        CITY: "WOLFGANG",
      },
      {
        CITY: "WOLFHALDEN",
      },
      {
        CITY: "WOLFWIL",
      },
      {
        CITY: "WOLHUSEN",
      },
      {
        CITY: "WOLLERAU",
      },
      {
        CITY: "WORB",
      },
      {
        CITY: "WORBEN",
      },
      {
        CITY: "WORBLAUFEN",
      },
      {
        CITY: "WUPPENAU",
      },
      {
        CITY: "WURENLINGEN",
      },
      {
        CITY: "WURENLOS",
      },
      {
        CITY: "WYNAU",
      },
      {
        CITY: "YENS",
      },
      {
        CITY: "YVERDON-LES-BAINS",
      },
      {
        CITY: "YVONAND",
      },
      {
        CITY: "YVORNE",
      },
      {
        CITY: "ZAZIWIL",
      },
      {
        CITY: "ZEGLINGEN",
      },
      {
        CITY: "ZEIHEN",
      },
      {
        CITY: "ZELL",
      },
      {
        CITY: "ZERMATT",
      },
      {
        CITY: "ZIEGELBRUCKE",
      },
      {
        CITY: "ZIHLSCHLACHT",
      },
      {
        CITY: "ZIZERS",
      },
      {
        CITY: "ZOFINGEN",
      },
      {
        CITY: "ZOLLBRUCK",
      },
      {
        CITY: "ZOLLIKOFEN",
      },
      {
        CITY: "ZOLLIKON",
      },
      {
        CITY: "ZUCHWIL",
      },
      {
        CITY: "ZUCKENRIET",
      },
      {
        CITY: "ZUFIKON",
      },
      {
        CITY: "ZUG",
      },
      {
        CITY: "ZULLWIL",
      },
      {
        CITY: "ZUMIKON",
      },
      {
        CITY: "ZUNZGEN",
      },
      {
        CITY: "ZURICH",
      },
      {
        CITY: "ZUZGEN",
      },
      {
        CITY: "ZUZWIL",
      },
      {
        CITY: "ZWINGEN",
      },
    ],
  },
  {
    COUNTRY: "RUSSIA",
    CODE: "RU",
    CITIES: [
      {
        CITY: "ABAKAN",
      },
      {
        CITY: "ABINSK",
      },
      {
        CITY: "ACHINSK",
      },
      {
        CITY: "ADYGEYSK",
      },
      {
        CITY: "AGAPOVKA",
      },
      {
        CITY: "AGIDEL",
      },
      {
        CITY: "AKHTUBINSK",
      },
      {
        CITY: "AKSAY",
      },
      {
        CITY: "AKSENOVO",
      },
      {
        CITY: "ALAPAYEVSK",
      },
      {
        CITY: "ALDAN",
      },
      {
        CITY: "ALEKSANDROV",
      },
      {
        CITY: "ALEKSANDROVKA",
      },
      {
        CITY: "ALEKSEYEVKA",
      },
      {
        CITY: "ALEKSIN",
      },
      {
        CITY: "ALEKSINO",
      },
      {
        CITY: "ALEYSK",
      },
      {
        CITY: "ALIKOVO",
      },
      {
        CITY: "ALTAY",
      },
      {
        CITY: "ALTAYSKOYE",
      },
      {
        CITY: "AMUR",
      },
      {
        CITY: "AMURSK",
      },
      {
        CITY: "ANAPA",
      },
      {
        CITY: "ANGARSK",
      },
      {
        CITY: "ANUCHINO",
      },
      {
        CITY: "ANZHERO-SUDZHENSK",
      },
      {
        CITY: "APATITY",
      },
      {
        CITY: "APAZOVO",
      },
      {
        CITY: "APRELEVKA",
      },
      {
        CITY: "ARAMIL",
      },
      {
        CITY: "ARIADNOYE",
      },
      {
        CITY: "ARKHANGELSK",
      },
      {
        CITY: "ARKHARA",
      },
      {
        CITY: "ARMAVIR",
      },
      {
        CITY: "ARO",
      },
      {
        CITY: "ARTEM",
      },
      {
        CITY: "ARTI",
      },
      {
        CITY: "ARZAMAS",
      },
      {
        CITY: "ASBEST",
      },
      {
        CITY: "ASHA",
      },
      {
        CITY: "ASHCHIBUTAK",
      },
      {
        CITY: "ASINO",
      },
      {
        CITY: "ASTRAKHAN",
      },
      {
        CITY: "ATKARSK",
      },
      {
        CITY: "ATLAS",
      },
      {
        CITY: "AVANGARD",
      },
      {
        CITY: "AVRI",
      },
      {
        CITY: "AVTOZAVODSKIY RAYON",
      },
      {
        CITY: "AYKINO",
      },
      {
        CITY: "AZOV",
      },
      {
        CITY: "BAGAN",
      },
      {
        CITY: "BAKSAN",
      },
      {
        CITY: "BALABANOVO",
      },
      {
        CITY: "BALAKOVO",
      },
      {
        CITY: "BALASHIKHA",
      },
      {
        CITY: "BALASHOV",
      },
      {
        CITY: "BALTIYSK",
      },
      {
        CITY: "BARABASH",
      },
      {
        CITY: "BARANCHINSKIY",
      },
      {
        CITY: "BARDA",
      },
      {
        CITY: "BARNAUL",
      },
      {
        CITY: "BASHKORTOSTAN",
      },
      {
        CITY: "BATAYSK",
      },
      {
        CITY: "BAYKAL",
      },
      {
        CITY: "BAZA",
      },
      {
        CITY: "BEGUNITSY",
      },
      {
        CITY: "BELAYA KALITVA",
      },
      {
        CITY: "BELEBEY",
      },
      {
        CITY: "BELGOROD",
      },
      {
        CITY: "BELOGORKA",
      },
      {
        CITY: "BELOGORSK",
      },
      {
        CITY: "BELORECHENSK",
      },
      {
        CITY: "BELORETSK",
      },
      {
        CITY: "BELOVO",
      },
      {
        CITY: "BELYY",
      },
      {
        CITY: "BERDSK",
      },
      {
        CITY: "BEREZAYKA",
      },
      {
        CITY: "BEREZNIKI",
      },
      {
        CITY: "BEREZOVKA",
      },
      {
        CITY: "BERYOZOVSKY",
      },
      {
        CITY: "BETLITSA",
      },
      {
        CITY: "BEYA",
      },
      {
        CITY: "BEZHETSK",
      },
      {
        CITY: "BIBAYEVO",
      },
      {
        CITY: "BILIBINO",
      },
      {
        CITY: "BIROBIDZHAN",
      },
      {
        CITY: "BIRSK",
      },
      {
        CITY: "BIRZHA",
      },
      {
        CITY: "BIYSK",
      },
      {
        CITY: "BLAGOVAR",
      },
      {
        CITY: "BLAGOVESHCHENSK",
      },
      {
        CITY: "BOGDANOVICH",
      },
      {
        CITY: "BOGDASHINO",
      },
      {
        CITY: "BOGORODITSK",
      },
      {
        CITY: "BOGORODSK",
      },
      {
        CITY: "BOGORODSKOYE",
      },
      {
        CITY: "BOGUCHANY",
      },
      {
        CITY: "BOKSITOGORSK",
      },
      {
        CITY: "BOLOGOYE",
      },
      {
        CITY: "BOR",
      },
      {
        CITY: "BORISOGLEBSK",
      },
      {
        CITY: "BORKOVKA",
      },
      {
        CITY: "BORODINO",
      },
      {
        CITY: "BORODYNOVKA",
      },
      {
        CITY: "BOROVICHI",
      },
      {
        CITY: "BOROVSKIY",
      },
      {
        CITY: "BORZYA",
      },
      {
        CITY: "BOVYKINY",
      },
      {
        CITY: "BRAS",
      },
      {
        CITY: "BRATSK",
      },
      {
        CITY: "BROD",
      },
      {
        CITY: "BRONNITSY",
      },
      {
        CITY: "BRYANSK",
      },
      {
        CITY: "BUGRY",
      },
      {
        CITY: "BULGAKOV",
      },
      {
        CITY: "BUYANTSEVO",
      },
      {
        CITY: "BUZULUK",
      },
      {
        CITY: "CHAPAYEVSK",
      },
      {
        CITY: "CHAPLYGIN",
      },
      {
        CITY: "CHASTYYE",
      },
      {
        CITY: "CHAYKOVSKIY",
      },
      {
        CITY: "CHEBOKSARY",
      },
      {
        CITY: "CHEKHOV",
      },
      {
        CITY: "CHELBASSKAYA",
      },
      {
        CITY: "CHELYABINSK",
      },
      {
        CITY: "CHEMASHUR",
      },
      {
        CITY: "CHEREMKHOVO",
      },
      {
        CITY: "CHEREPANOVO",
      },
      {
        CITY: "CHEREPOVETS",
      },
      {
        CITY: "CHERKESSK",
      },
      {
        CITY: "CHERKIZOVO",
      },
      {
        CITY: "CHERNIGOVKA",
      },
      {
        CITY: "CHERNOGOLOVKA",
      },
      {
        CITY: "CHERNOGORSK",
      },
      {
        CITY: "CHERNUSHKA",
      },
      {
        CITY: "CHERNYAKHOVSK",
      },
      {
        CITY: "CHESNOKOVKA",
      },
      {
        CITY: "CHIK",
      },
      {
        CITY: "CHINA",
      },
      {
        CITY: "CHITA",
      },
      {
        CITY: "CHIVEREVO",
      },
      {
        CITY: "CHKALOV",
      },
      {
        CITY: "CHUDOVO",
      },
      {
        CITY: "CHYORNY YAR",
      },
      {
        CITY: "DAGOMYS",
      },
      {
        CITY: "DALMATOVO",
      },
      {
        CITY: "DAMKHURTS",
      },
      {
        CITY: "DANILOV",
      },
      {
        CITY: "DEDENEVO",
      },
      {
        CITY: "DEDOVSK",
      },
      {
        CITY: "DENISOVO",
      },
      {
        CITY: "DERBENT",
      },
      {
        CITY: "DESNOGORSK",
      },
      {
        CITY: "DETCHINO",
      },
      {
        CITY: "DIMITROVGRAD",
      },
      {
        CITY: "DINSKAYA",
      },
      {
        CITY: "DIVNOGORSK",
      },
      {
        CITY: "DIVO",
      },
      {
        CITY: "DMITRIYEVKA",
      },
      {
        CITY: "DMITROV",
      },
      {
        CITY: "DOBRYANKA",
      },
      {
        CITY: "DOLGOPRUDNYY",
      },
      {
        CITY: "DOMODEDOVO",
      },
      {
        CITY: "DONETSK",
      },
      {
        CITY: "DONSKOY",
      },
      {
        CITY: "DREZNA",
      },
      {
        CITY: "DRUZHBA",
      },
      {
        CITY: "DUBENKA",
      },
      {
        CITY: "DUBNA",
      },
      {
        CITY: "DUBOVKA",
      },
      {
        CITY: "DUBROVKA",
      },
      {
        CITY: "DUDINKA",
      },
      {
        CITY: "DYURTYULI",
      },
      {
        CITY: "DZERZHINSK",
      },
      {
        CITY: "DZERZHINSKIY",
      },
      {
        CITY: "ELEKTROGORSK",
      },
      {
        CITY: "ELEKTROSTAL",
      },
      {
        CITY: "ELEKTROUGLI",
      },
      {
        CITY: "ELISTA",
      },
      {
        CITY: "ENKHELUK",
      },
      {
        CITY: "FATEZH",
      },
      {
        CITY: "FIALKA",
      },
      {
        CITY: "FILATOVSKOYE",
      },
      {
        CITY: "FILIAL",
      },
      {
        CITY: "FROLOVO",
      },
      {
        CITY: "FRUNZENSKIY",
      },
      {
        CITY: "FRYAZINO",
      },
      {
        CITY: "FURMANOV",
      },
      {
        CITY: "GAGARIN",
      },
      {
        CITY: "GAGINO",
      },
      {
        CITY: "GATCHINA",
      },
      {
        CITY: "GAURILOV",
      },
      {
        CITY: "GELENDZHIK",
      },
      {
        CITY: "GEORGIYEVSK",
      },
      {
        CITY: "GEORGIYEVSKOYE",
      },
      {
        CITY: "GLAZOV",
      },
      {
        CITY: "GLAZUNOVKA",
      },
      {
        CITY: "GLEBYCHEVO",
      },
      {
        CITY: "GOGOLYA",
      },
      {
        CITY: "GOLITSYNO",
      },
      {
        CITY: "GOLOVCHINO",
      },
      {
        CITY: "GORBATOV",
      },
      {
        CITY: "GORIN",
      },
      {
        CITY: "GORNO-ALTAYSK",
      },
      {
        CITY: "GORNOZAVODSK",
      },
      {
        CITY: "GORODNA",
      },
      {
        CITY: "GRANIT",
      },
      {
        CITY: "GROZNY",
      },
      {
        CITY: "GRYAZOVETS",
      },
      {
        CITY: "GUBAKHA",
      },
      {
        CITY: "GUBKIN",
      },
      {
        CITY: "GUBKINSKIY",
      },
      {
        CITY: "GUDERMES",
      },
      {
        CITY: "GUKOVO",
      },
      {
        CITY: "GUSEV",
      },
      {
        CITY: "GUSINOOZYORSK",
      },
      {
        CITY: "GVARDEYSK",
      },
      {
        CITY: "IBRESI",
      },
      {
        CITY: "IBRYA",
      },
      {
        CITY: "IGLINO",
      },
      {
        CITY: "IGRA",
      },
      {
        CITY: "ILIM",
      },
      {
        CITY: "ILOVLYA",
      },
      {
        CITY: "ILYA",
      },
      {
        CITY: "INSAR",
      },
      {
        CITY: "INTA",
      },
      {
        CITY: "IRBIT",
      },
      {
        CITY: "IRKUTSK",
      },
      {
        CITY: "ISHEYEVKA",
      },
      {
        CITY: "ISHIM",
      },
      {
        CITY: "ISHIMBAY",
      },
      {
        CITY: "ISHIMSKIY",
      },
      {
        CITY: "ISHKULOVO",
      },
      {
        CITY: "ISHLEY",
      },
      {
        CITY: "ISKITIM",
      },
      {
        CITY: "ISTRA",
      },
      {
        CITY: "IVANGOROD",
      },
      {
        CITY: "IVANOVO",
      },
      {
        CITY: "IZBERBASH",
      },
      {
        CITY: "IZHEVSK",
      },
      {
        CITY: "IZMAYLOVO",
      },
      {
        CITY: "KABANOVO",
      },
      {
        CITY: "KABARDINO",
      },
      {
        CITY: "KACHKANAR",
      },
      {
        CITY: "KADROVIK",
      },
      {
        CITY: "KALASHNIKOVO",
      },
      {
        CITY: "KALININA",
      },
      {
        CITY: "KALININGRAD",
      },
      {
        CITY: "KALININSKAYA",
      },
      {
        CITY: "KALINO",
      },
      {
        CITY: "KALTAN",
      },
      {
        CITY: "KALUGA",
      },
      {
        CITY: "KAMCHATKA",
      },
      {
        CITY: "KAMENKA",
      },
      {
        CITY: "KAMENNOGORSK",
      },
      {
        CITY: "KAMENNOMOSTSKIY",
      },
      {
        CITY: "KAMENOLOMNI",
      },
      {
        CITY: "KAMENSK",
      },
      {
        CITY: "KAMENSK-SHAKHTINSKIY",
      },
      {
        CITY: "KAMYSHIN",
      },
      {
        CITY: "KAMYSHOVKA",
      },
      {
        CITY: "KANASH",
      },
      {
        CITY: "KANAVKA",
      },
      {
        CITY: "KANDALAKSHA",
      },
      {
        CITY: "KANEVSKAYA",
      },
      {
        CITY: "KANSK",
      },
      {
        CITY: "KARABASH",
      },
      {
        CITY: "KARABULAK",
      },
      {
        CITY: "KARACHEV",
      },
      {
        CITY: "KARIN PEREVOZ",
      },
      {
        CITY: "KARLA MARKSA",
      },
      {
        CITY: "KARTALY",
      },
      {
        CITY: "KASHIRA",
      },
      {
        CITY: "KASIMOV",
      },
      {
        CITY: "KASLI",
      },
      {
        CITY: "KASTORNOYE",
      },
      {
        CITY: "KATAV-IVANOVSK",
      },
      {
        CITY: "KAVKAZ",
      },
      {
        CITY: "KEMEROVO",
      },
      {
        CITY: "KHABAROVSK",
      },
      {
        CITY: "KHABARSKOYE",
      },
      {
        CITY: "KHANGASH-YURT",
      },
      {
        CITY: "KHANTY-MANSIYSK",
      },
      {
        CITY: "KHARP",
      },
      {
        CITY: "KHASAVYURT",
      },
      {
        CITY: "KHIMKI",
      },
      {
        CITY: "KHOLMISTYY",
      },
      {
        CITY: "KHOLMSK",
      },
      {
        CITY: "KHRENOVOYE",
      },
      {
        CITY: "KICHMENGSKIY GORODOK",
      },
      {
        CITY: "KIMOVSK",
      },
      {
        CITY: "KIMRY",
      },
      {
        CITY: "KINESHMA",
      },
      {
        CITY: "KINGISEPP",
      },
      {
        CITY: "KIRGIZ-MIYAKI",
      },
      {
        CITY: "KIRILLY",
      },
      {
        CITY: "KIRISHI",
      },
      {
        CITY: "KIROV",
      },
      {
        CITY: "KIROVA",
      },
      {
        CITY: "KIROVGRAD",
      },
      {
        CITY: "KIROVO-CHEPETSK",
      },
      {
        CITY: "KIROVSK",
      },
      {
        CITY: "KIROVSKAYA",
      },
      {
        CITY: "KIRSANOV",
      },
      {
        CITY: "KIRYABINSKOYE",
      },
      {
        CITY: "KIRZHACH",
      },
      {
        CITY: "KISLOVODSK",
      },
      {
        CITY: "KIZLYAR",
      },
      {
        CITY: "KIZNER",
      },
      {
        CITY: "KLIMOVSK",
      },
      {
        CITY: "KLIN",
      },
      {
        CITY: "KLINTSY",
      },
      {
        CITY: "KLYUCHI",
      },
      {
        CITY: "KLYUCHI-BULAK",
      },
      {
        CITY: "KOCHKUROVO",
      },
      {
        CITY: "KODINSK",
      },
      {
        CITY: "KOLA",
      },
      {
        CITY: "KOLOMINO",
      },
      {
        CITY: "KOLOMNA",
      },
      {
        CITY: "KOLPASHEVO",
      },
      {
        CITY: "KOLPINO",
      },
      {
        CITY: "KOMAROV",
      },
      {
        CITY: "KOMINTERN",
      },
      {
        CITY: "KOMSOMOLSK-ON-AMUR",
      },
      {
        CITY: "KONAKOVO",
      },
      {
        CITY: "KONDOPOGA",
      },
      {
        CITY: "KONOSHA",
      },
      {
        CITY: "KOPEYSK",
      },
      {
        CITY: "KOPTYUGA",
      },
      {
        CITY: "KORKINO",
      },
      {
        CITY: "KOROLEV",
      },
      {
        CITY: "KORSAKOV",
      },
      {
        CITY: "KORYAZHMA",
      },
      {
        CITY: "KOSTENIKI",
      },
      {
        CITY: "KOSTOMUKSHA",
      },
      {
        CITY: "KOSTROMA",
      },
      {
        CITY: "KOTLAS",
      },
      {
        CITY: "KOVDOR",
      },
      {
        CITY: "KOVROV",
      },
      {
        CITY: "KRASKOVO",
      },
      {
        CITY: "KRASNOARMEYSK",
      },
      {
        CITY: "KRASNOARMEYSKAYA",
      },
      {
        CITY: "KRASNOARMEYSKOYE",
      },
      {
        CITY: "KRASNODAR",
      },
      {
        CITY: "KRASNODARSKIY",
      },
      {
        CITY: "KRASNOGORSK",
      },
      {
        CITY: "KRASNOGORSKIY",
      },
      {
        CITY: "KRASNOGVARDEYSKIY",
      },
      {
        CITY: "KRASNOKAMENSK",
      },
      {
        CITY: "KRASNOKAMSK",
      },
      {
        CITY: "KRASNOOBSK",
      },
      {
        CITY: "KRASNOUFIMSK",
      },
      {
        CITY: "KRASNOYARSK",
      },
      {
        CITY: "KRASNOYE-NA-VOLGE",
      },
      {
        CITY: "KRASNOZCITYNSK",
      },
      {
        CITY: "KRASNYY KUT",
      },
      {
        CITY: "KRASNYY SULIN",
      },
      {
        CITY: "KRASNYY YAR",
      },
      {
        CITY: "KRISTALL",
      },
      {
        CITY: "KROMY",
      },
      {
        CITY: "KRONSHTADT",
      },
      {
        CITY: "KROPOTKIN",
      },
      {
        CITY: "KRUGLOYE",
      },
      {
        CITY: "KRYMSK",
      },
      {
        CITY: "KSTOVO",
      },
      {
        CITY: "KUBINKA",
      },
      {
        CITY: "KUGESI",
      },
      {
        CITY: "KULIKOV",
      },
      {
        CITY: "KUMERTAU",
      },
      {
        CITY: "KUNGUR",
      },
      {
        CITY: "KUPAVNA",
      },
      {
        CITY: "KURCHATOV",
      },
      {
        CITY: "KURGAN",
      },
      {
        CITY: "KURGANINSK",
      },
      {
        CITY: "KURSK",
      },
      {
        CITY: "KURTAMYSH",
      },
      {
        CITY: "KUSA",
      },
      {
        CITY: "KUSAKOVKA",
      },
      {
        CITY: "KUSHNARENKOVO",
      },
      {
        CITY: "KUSHVA",
      },
      {
        CITY: "KUYBYSHEV",
      },
      {
        CITY: "KUZNETSK",
      },
      {
        CITY: "KUZNETSKIY RAYON",
      },
      {
        CITY: "KUZOVATOVO",
      },
      {
        CITY: "KVATCHI",
      },
      {
        CITY: "KYSHTYM",
      },
      {
        CITY: "KYZYL",
      },
      {
        CITY: "LABINSK",
      },
      {
        CITY: "LABYTNANGI",
      },
      {
        CITY: "LADA",
      },
      {
        CITY: "LAKINSK",
      },
      {
        CITY: "LAMPOVO",
      },
      {
        CITY: "LAZO",
      },
      {
        CITY: "LEBEDEV",
      },
      {
        CITY: "LENINA",
      },
      {
        CITY: "LENINGRADSKAYA",
      },
      {
        CITY: "LENINGRADSKIY",
      },
      {
        CITY: "LENINOGORSK",
      },
      {
        CITY: "LENINSK-KUZNETSKY",
      },
      {
        CITY: "LENINSKIY",
      },
      {
        CITY: "LENINSKOYE",
      },
      {
        CITY: "LENKINO",
      },
      {
        CITY: "LERMONTOV",
      },
      {
        CITY: "LESNOY",
      },
      {
        CITY: "LESNOY GORODOK",
      },
      {
        CITY: "LESODACHA",
      },
      {
        CITY: "LESOSIBIRSK",
      },
      {
        CITY: "LESOZAVODSK",
      },
      {
        CITY: "LIKINO-DULEVO",
      },
      {
        CITY: "LINDA",
      },
      {
        CITY: "LINEVO",
      },
      {
        CITY: "LINIYA",
      },
      {
        CITY: "LIPETSK",
      },
      {
        CITY: "LISIKHA",
      },
      {
        CITY: "LISKI",
      },
      {
        CITY: "LIVNY",
      },
      {
        CITY: "LOBNYA",
      },
      {
        CITY: "LODEYNOYE POLE",
      },
      {
        CITY: "LOMONOSOV",
      },
      {
        CITY: "LOSINO-PETROVSKIY",
      },
      {
        CITY: "LUCHEGORSK",
      },
      {
        CITY: "LUKHOVITSY",
      },
      {
        CITY: "LUZINO",
      },
      {
        CITY: "LYANTOR",
      },
      {
        CITY: "LYTKARINO",
      },
      {
        CITY: "LYUBERTSY",
      },
      {
        CITY: "MAGADAN",
      },
      {
        CITY: "MAGDAGACHI",
      },
      {
        CITY: "MAGNITNYY",
      },
      {
        CITY: "MAGNITOGORSK",
      },
      {
        CITY: "MAKHACHKALA",
      },
      {
        CITY: "MAKSIMOVKA",
      },
      {
        CITY: "MALAYA VISHERA",
      },
      {
        CITY: "MALINO",
      },
      {
        CITY: "MALOYAROSLAVETS",
      },
      {
        CITY: "MALYSHEVA",
      },
      {
        CITY: "MAMONTOVKA",
      },
      {
        CITY: "MAMONTOVO",
      },
      {
        CITY: "MANTUROVO",
      },
      {
        CITY: "MARIINSKIY POSAD",
      },
      {
        CITY: "MARKOVO",
      },
      {
        CITY: "MARKS",
      },
      {
        CITY: "MASLYANINO",
      },
      {
        CITY: "MAYAKOVSKOGO",
      },
      {
        CITY: "MAYKOP",
      },
      {
        CITY: "MAYKOR",
      },
      {
        CITY: "MEDNOGORSK",
      },
      {
        CITY: "MEDVEDEVO",
      },
      {
        CITY: "MEDVENKA",
      },
      {
        CITY: "MEGA",
      },
      {
        CITY: "MEGION",
      },
      {
        CITY: "MELENKI",
      },
      {
        CITY: "MELEUZ",
      },
      {
        CITY: "METALLOSTROY",
      },
      {
        CITY: "METALLURG",
      },
      {
        CITY: "MEZHDURECHENSK",
      },
      {
        CITY: "MGA",
      },
      {
        CITY: "MIASS",
      },
      {
        CITY: "MIASSKOYE",
      },
      {
        CITY: "MICHURINSK",
      },
      {
        CITY: "MIKHAYLOVSKOYE",
      },
      {
        CITY: "MILLEROVO",
      },
      {
        CITY: "MINERALNYE VODY",
      },
      {
        CITY: "MINUSINSK",
      },
      {
        CITY: "MIRA",
      },
      {
        CITY: "MOLDAVANSKOYE",
      },
      {
        CITY: "MONCHEGORSK",
      },
      {
        CITY: "MONINO",
      },
      {
        CITY: "MOROZOV",
      },
      {
        CITY: "MOROZOVO",
      },
      {
        CITY: "MOROZOVSK",
      },
      {
        CITY: "MORSHANSK",
      },
      {
        CITY: "MOSCOW",
      },
      {
        CITY: "MOSKOVSKAYA",
      },
      {
        CITY: "MOSKOVSKIY",
      },
      {
        CITY: "MOSTOVSKOY",
      },
      {
        CITY: "MOZDOK",
      },
      {
        CITY: "MOZHAYSK",
      },
      {
        CITY: "MOZHGA",
      },
      {
        CITY: "MTSENSK",
      },
      {
        CITY: "MURAVLENKO",
      },
      {
        CITY: "MURMANSK",
      },
      {
        CITY: "MURMASHI",
      },
      {
        CITY: "MUROM",
      },
      {
        CITY: "MYSKI",
      },
      {
        CITY: "MYTISHCHI",
      },
      {
        CITY: "NABEREZHNYYE CHELNY",
      },
      {
        CITY: "NADEZHDINKA",
      },
      {
        CITY: "NADVOITSY",
      },
      {
        CITY: "NADYM",
      },
      {
        CITY: "NAKHABINO",
      },
      {
        CITY: "NAKHODKA",
      },
      {
        CITY: "NARO-FOMINSK",
      },
      {
        CITY: "NARTKALA",
      },
      {
        CITY: "NARYAN-MAR",
      },
      {
        CITY: "NAUKA",
      },
      {
        CITY: "NAUMKINO",
      },
      {
        CITY: "NAZAROVO",
      },
      {
        CITY: "NAZRAN",
      },
      {
        CITY: "NEFTEKAMSK",
      },
      {
        CITY: "NEFTEKUMSK",
      },
      {
        CITY: "NEFTEYUGANSK",
      },
      {
        CITY: "NELAZSKOYE",
      },
      {
        CITY: "NELIDOVO",
      },
      {
        CITY: "NEMAN",
      },
      {
        CITY: "NERYUNGRI",
      },
      {
        CITY: "NEVA",
      },
      {
        CITY: "NEVINNOMYSSK",
      },
      {
        CITY: "NEVSKIY",
      },
      {
        CITY: "NEYA",
      },
      {
        CITY: "NIKEL",
      },
      {
        CITY: "NIKOLAYEVSK",
      },
      {
        CITY: "NIKOLAYEVSK-ON-AMUR",
      },
      {
        CITY: "NIKULIN",
      },
      {
        CITY: "NIZHEGORODSKAYA",
      },
      {
        CITY: "NIZHNEKAMSK",
      },
      {
        CITY: "NIZHNEUDINSK",
      },
      {
        CITY: "NIZHNEVARTOVSK",
      },
      {
        CITY: "NIZHNEYASHKINO",
      },
      {
        CITY: "NIZHNIY NOVGOROD",
      },
      {
        CITY: "NIZHNIY ODES",
      },
      {
        CITY: "NIZHNIY TAGIL",
      },
      {
        CITY: "NOGINSK",
      },
      {
        CITY: "NOGINSKAYA",
      },
      {
        CITY: "NOVA",
      },
      {
        CITY: "NOVATOR",
      },
      {
        CITY: "NOVAYA IGIRMA",
      },
      {
        CITY: "NOVAYA SILAVA",
      },
      {
        CITY: "NOVOALTAYSK",
      },
      {
        CITY: "NOVOBELOKATAY",
      },
      {
        CITY: "NOVOCHEBOKSARSK",
      },
      {
        CITY: "NOVOCHERKASSK",
      },
      {
        CITY: "NOVODMITRIYEVKA",
      },
      {
        CITY: "NOVODVINSK",
      },
      {
        CITY: "NOVOKORSUNSKAYA",
      },
      {
        CITY: "NOVOKRUCHININSKIY",
      },
      {
        CITY: "NOVOKUYBYSHEVSK",
      },
      {
        CITY: "NOVOKUZNETSK",
      },
      {
        CITY: "NOVOMICHURINSK",
      },
      {
        CITY: "NOVOMOSKOVSK",
      },
      {
        CITY: "NOVOROSSIYSK",
      },
      {
        CITY: "NOVOSELOVO",
      },
      {
        CITY: "NOVOSEMEYKINO",
      },
      {
        CITY: "NOVOSHAKHTINSK",
      },
      {
        CITY: "NOVOSHAKHTINSKIY",
      },
      {
        CITY: "NOVOSIBIRSK",
      },
      {
        CITY: "NOVOSOKOLOVOGORSKIY",
      },
      {
        CITY: "NOVOTROITSK",
      },
      {
        CITY: "NOVOVARSHAVKA",
      },
      {
        CITY: "NOVOVORONEZH",
      },
      {
        CITY: "NOVOYE DEVYATKINO",
      },
      {
        CITY: "NOVOZAVEDENNOYE",
      },
      {
        CITY: "NOVOZOLOTOVSKAYA",
      },
      {
        CITY: "NOVOZYBKOV",
      },
      {
        CITY: "NOVY URENGOY",
      },
      {
        CITY: "NOVYY",
      },
      {
        CITY: "NOYABRSK",
      },
      {
        CITY: "NYAGAN",
      },
      {
        CITY: "NYTVA",
      },
      {
        CITY: "OBNINSK",
      },
      {
        CITY: "OBYEDINENIYE",
      },
      {
        CITY: "OCHER",
      },
      {
        CITY: "ODINTSOVO",
      },
      {
        CITY: "OGAREV",
      },
      {
        CITY: "OKHA",
      },
      {
        CITY: "OKHANSK",
      },
      {
        CITY: "OKULOVKA",
      },
      {
        CITY: "OLENEGORSK",
      },
      {
        CITY: "OLIMPIYSKIY",
      },
      {
        CITY: "OMCHAK",
      },
      {
        CITY: "OMSK",
      },
      {
        CITY: "OREKHOVO-ZUYEVO",
      },
      {
        CITY: "ORENBURG",
      },
      {
        CITY: "ORLOVSKIY",
      },
      {
        CITY: "ORSHANKA",
      },
      {
        CITY: "ORSK",
      },
      {
        CITY: "OSHIB",
      },
      {
        CITY: "OSNOVA",
      },
      {
        CITY: "OSTANKINO",
      },
      {
        CITY: "OSTROV",
      },
      {
        CITY: "OTRADNOYE",
      },
      {
        CITY: "OTRADNYY",
      },
      {
        CITY: "OVCHINNIKOV",
      },
      {
        CITY: "PANAYEVSK",
      },
      {
        CITY: "PANGODY",
      },
      {
        CITY: "PARAPINO",
      },
      {
        CITY: "PARFINO",
      },
      {
        CITY: "PARGOLOVO",
      },
      {
        CITY: "PARKOVYY",
      },
      {
        CITY: "PARTIZANSK",
      },
      {
        CITY: "PATRUSHI",
      },
      {
        CITY: "PAVLOVO",
      },
      {
        CITY: "PAVLOVSK",
      },
      {
        CITY: "PAVLOVSKAYA",
      },
      {
        CITY: "PAVLOVSKAYA SLOBODA",
      },
      {
        CITY: "PAVLOVSKIY POSAD",
      },
      {
        CITY: "PECHORA",
      },
      {
        CITY: "PECHORY",
      },
      {
        CITY: "PENZA",
      },
      {
        CITY: "PEREPRAVNAYA",
      },
      {
        CITY: "PERM",
      },
      {
        CITY: "PEROVKA",
      },
      {
        CITY: "PERSIANOVKA",
      },
      {
        CITY: "PERVO",
      },
      {
        CITY: "PERVOMAYSKAYA",
      },
      {
        CITY: "PERVOURALSK",
      },
      {
        CITY: "PESCHANKA",
      },
      {
        CITY: "PESTOVO",
      },
      {
        CITY: "PETERGOF",
      },
      {
        CITY: "PETROPAVLOVKA",
      },
      {
        CITY: "PETROPAVLOVSK-KAMCHATSKY",
      },
      {
        CITY: "PETROZAVODSK",
      },
      {
        CITY: "PIKALYOVO",
      },
      {
        CITY: "PIONER",
      },
      {
        CITY: "PITER",
      },
      {
        CITY: "PITKYARANTA",
      },
      {
        CITY: "PIVOVAR",
      },
      {
        CITY: "PLAVSK",
      },
      {
        CITY: "PLELOYE",
      },
      {
        CITY: "PODGORTSY",
      },
      {
        CITY: "PODOLSK",
      },
      {
        CITY: "PODSTEPKI",
      },
      {
        CITY: "POKROV",
      },
      {
        CITY: "POLEVSKOY",
      },
      {
        CITY: "POLYARNYY",
      },
      {
        CITY: "PORONAYSK",
      },
      {
        CITY: "POSAD",
      },
      {
        CITY: "POSELOK",
      },
      {
        CITY: "POSTNIKOVA",
      },
      {
        CITY: "POTOK",
      },
      {
        CITY: "POVAROVO",
      },
      {
        CITY: "POVEDNIKI",
      },
      {
        CITY: "POZTYKERES",
      },
      {
        CITY: "PREGRADNAYA",
      },
      {
        CITY: "PRIOZERSK",
      },
      {
        CITY: "PRIRECHENSK",
      },
      {
        CITY: "PROGRESS",
      },
      {
        CITY: "PROKHLADNYY",
      },
      {
        CITY: "PROTVINO",
      },
      {
        CITY: "PRYAZHA",
      },
      {
        CITY: "PSKOV",
      },
      {
        CITY: "PSKOVSKOYE",
      },
      {
        CITY: "PUGACHEV",
      },
      {
        CITY: "PULKOVO",
      },
      {
        CITY: "PUROVSK",
      },
      {
        CITY: "PUSHCHINO",
      },
      {
        CITY: "PUSHKIN",
      },
      {
        CITY: "PUSHKINA",
      },
      {
        CITY: "PUSHKINO",
      },
      {
        CITY: "PUTILKOVO",
      },
      {
        CITY: "PYATIGORSK",
      },
      {
        CITY: "PYCHAS",
      },
      {
        CITY: "PYSHMA",
      },
      {
        CITY: "RADIST",
      },
      {
        CITY: "RADUGA",
      },
      {
        CITY: "RAKITA",
      },
      {
        CITY: "RAMENSKOYE",
      },
      {
        CITY: "RASSVET",
      },
      {
        CITY: "RATMIROVO",
      },
      {
        CITY: "RAYCHIKHINSK",
      },
      {
        CITY: "REDKINO",
      },
      {
        CITY: "REFTINSKIY",
      },
      {
        CITY: "REUTOV",
      },
      {
        CITY: "REVDA",
      },
      {
        CITY: "REZH",
      },
      {
        CITY: "RIKASIKHA",
      },
      {
        CITY: "RODIONOVKA",
      },
      {
        CITY: "RODIONOVO-NESVETAYSKAYA",
      },
      {
        CITY: "RODNIKI",
      },
      {
        CITY: "ROMANOVKA",
      },
      {
        CITY: "ROSA",
      },
      {
        CITY: "ROSHCHINO",
      },
      {
        CITY: "ROSSIYSKIY",
      },
      {
        CITY: "ROSTOV",
      },
      {
        CITY: "ROSTOV-ON-DON",
      },
      {
        CITY: "ROSTOVSKAYA",
      },
      {
        CITY: "ROVNOYE",
      },
      {
        CITY: "ROZHDESTVENKA",
      },
      {
        CITY: "RUBIN",
      },
      {
        CITY: "RUBTSOVSK",
      },
      {
        CITY: "RUSSA",
      },
      {
        CITY: "RUZA",
      },
      {
        CITY: "RUZAYEVKA",
      },
      {
        CITY: "RYASNOYE",
      },
      {
        CITY: "RYAZAN",
      },
      {
        CITY: "RYBINSK",
      },
      {
        CITY: "RYBNOYE",
      },
      {
        CITY: "RZHEV",
      },
      {
        CITY: "SAFONOVO",
      },
      {
        CITY: "SAINT PETERSBURG",
      },
      {
        CITY: "SALAVAT",
      },
      {
        CITY: "SALEKHARD",
      },
      {
        CITY: "SALYM",
      },
      {
        CITY: "SAMARA",
      },
      {
        CITY: "SAMSONOVKA",
      },
      {
        CITY: "SARANSK",
      },
      {
        CITY: "SARAPUL",
      },
      {
        CITY: "SARATOV",
      },
      {
        CITY: "SAROV",
      },
      {
        CITY: "SARTYKOV",
      },
      {
        CITY: "SARY",
      },
      {
        CITY: "SATIS",
      },
      {
        CITY: "SATKA",
      },
      {
        CITY: "SAYANOGORSK",
      },
      {
        CITY: "SAYANSK",
      },
      {
        CITY: "SEBEZH",
      },
      {
        CITY: "SEGEZHA",
      },
      {
        CITY: "SELO",
      },
      {
        CITY: "SELYATINO",
      },
      {
        CITY: "SEMIBRATOVO",
      },
      {
        CITY: "SEMYONOV",
      },
      {
        CITY: "SERAFIMOVSKIY",
      },
      {
        CITY: "SERDOBSK",
      },
      {
        CITY: "SERGEYEVKA",
      },
      {
        CITY: "SERGIYEV POSAD",
      },
      {
        CITY: "SERGIYEVSKOYE",
      },
      {
        CITY: "SEROV",
      },
      {
        CITY: "SERPUKHOV",
      },
      {
        CITY: "SERTOLOVO",
      },
      {
        CITY: "SERYSHEVO",
      },
      {
        CITY: "SESTRORETSK",
      },
      {
        CITY: "SEVERO",
      },
      {
        CITY: "SEVERO-ZAPAD",
      },
      {
        CITY: "SEVERODVINSK",
      },
      {
        CITY: "SEVEROMORSK",
      },
      {
        CITY: "SEVERSK",
      },
      {
        CITY: "SEVERSKAYA",
      },
      {
        CITY: "SEVSK",
      },
      {
        CITY: "SHADRINSK",
      },
      {
        CITY: "SHAKHTY",
      },
      {
        CITY: "SHALINSKOYE",
      },
      {
        CITY: "SHALUSHKA",
      },
      {
        CITY: "SHARAPOVA OKHOTA",
      },
      {
        CITY: "SHARNUTOVSKIY",
      },
      {
        CITY: "SHATURA",
      },
      {
        CITY: "SHATURTORF",
      },
      {
        CITY: "SHCHEKINO",
      },
      {
        CITY: "SHCHELKOVO",
      },
      {
        CITY: "SHEBALINO",
      },
      {
        CITY: "SHEBEKINO",
      },
      {
        CITY: "SHEKSNA",
      },
      {
        CITY: "SHELEKHOV",
      },
      {
        CITY: "SHEREGESH",
      },
      {
        CITY: "SHOSHMA",
      },
      {
        CITY: "SHUMERLYA",
      },
      {
        CITY: "SIBAY",
      },
      {
        CITY: "SISTEMA",
      },
      {
        CITY: "SKALA",
      },
      {
        CITY: "SKAT",
      },
      {
        CITY: "SKOVORODINO",
      },
      {
        CITY: "SLANTSY",
      },
      {
        CITY: "SLAVGOROD",
      },
      {
        CITY: "SLAVYANKA",
      },
      {
        CITY: "SLAVYANSK-NA-KUBANI",
      },
      {
        CITY: "SLOBODA",
      },
      {
        CITY: "SLYUDYANKA",
      },
      {
        CITY: "SMOLENSK",
      },
      {
        CITY: "SMOLENSKAYA",
      },
      {
        CITY: "SNEGIRI",
      },
      {
        CITY: "SNEZHINSK",
      },
      {
        CITY: "SNEZHINSKIY",
      },
      {
        CITY: "SNEZHNOGORSK",
      },
      {
        CITY: "SOBINKA",
      },
      {
        CITY: "SOCHI",
      },
      {
        CITY: "SODYSHKA",
      },
      {
        CITY: "SOFRINO",
      },
      {
        CITY: "SOKOL",
      },
      {
        CITY: "SOKOLOVO",
      },
      {
        CITY: "SOLDATSKOYE",
      },
      {
        CITY: "SOLIKAMSK",
      },
      {
        CITY: "SOLNECHNOGORSK",
      },
      {
        CITY: "SOROCHINSK",
      },
      {
        CITY: "SORTAVALA",
      },
      {
        CITY: "SOSNOGORSK",
      },
      {
        CITY: "SOSNOVO",
      },
      {
        CITY: "SOSNOVOBORSK",
      },
      {
        CITY: "SOSNOVYY BOR",
      },
      {
        CITY: "SOSNY",
      },
      {
        CITY: "SOVETSK",
      },
      {
        CITY: "SOVETSKIY",
      },
      {
        CITY: "SPAS-DEMENSK",
      },
      {
        CITY: "SPASSK",
      },
      {
        CITY: "SPUTNIK",
      },
      {
        CITY: "SREDNEURALSK",
      },
      {
        CITY: "SROSTKI",
      },
      {
        CITY: "STARAYA KUPAVNA",
      },
      {
        CITY: "STARAYA RUSSA",
      },
      {
        CITY: "STAROBACHATY",
      },
      {
        CITY: "STARODUB",
      },
      {
        CITY: "STARONIZHESTEBLIYEVSKAYA",
      },
      {
        CITY: "STAROPESTEREVO",
      },
      {
        CITY: "STAROVELICHKOVSKAYA",
      },
      {
        CITY: "START",
      },
      {
        CITY: "STARY OSKOL",
      },
      {
        CITY: "STAVROPOL",
      },
      {
        CITY: "STERLITAMAK",
      },
      {
        CITY: "STREZHEVOY",
      },
      {
        CITY: "STROITELEY",
      },
      {
        CITY: "STUDENETS",
      },
      {
        CITY: "STUPINO",
      },
      {
        CITY: "SUKHANOVO",
      },
      {
        CITY: "SURGUT",
      },
      {
        CITY: "SURKHAKHI",
      },
      {
        CITY: "SURSK",
      },
      {
        CITY: "SVERDLOVA",
      },
      {
        CITY: "SVERDLOVSKAYA",
      },
      {
        CITY: "SVERDLOVSKIY",
      },
      {
        CITY: "SVETLAYA",
      },
      {
        CITY: "SVETLOGORSK",
      },
      {
        CITY: "SVETLOGRAD",
      },
      {
        CITY: "SVETLYY",
      },
      {
        CITY: "SVETOGORSK",
      },
      {
        CITY: "SVIBLOVO",
      },
      {
        CITY: "SVIRSK",
      },
      {
        CITY: "SVOBODNAYA",
      },
      {
        CITY: "SVOBODNYY",
      },
      {
        CITY: "SYAMZHA",
      },
      {
        CITY: "SYKTYVKAR",
      },
      {
        CITY: "TAGANROG",
      },
      {
        CITY: "TAGIL",
      },
      {
        CITY: "TAKSIMO",
      },
      {
        CITY: "TALDOM",
      },
      {
        CITY: "TAMBOV",
      },
      {
        CITY: "TAPKHAR",
      },
      {
        CITY: "TARKO-SALE",
      },
      {
        CITY: "TARUSA",
      },
      {
        CITY: "TATLYBAYEVO",
      },
      {
        CITY: "TAUSHKASY",
      },
      {
        CITY: "TAYGA",
      },
      {
        CITY: "TAYSHET",
      },
      {
        CITY: "TAZOVSKIY",
      },
      {
        CITY: "TECHA",
      },
      {
        CITY: "TEMRYUK",
      },
      {
        CITY: "TEYKOVO",
      },
      {
        CITY: "TIKHORETSK",
      },
      {
        CITY: "TIKHVIN",
      },
      {
        CITY: "TIM",
      },
      {
        CITY: "TIMIRYAZEV",
      },
      {
        CITY: "TOCHKA",
      },
      {
        CITY: "TOLSTOY",
      },
      {
        CITY: "TOLYATTI",
      },
      {
        CITY: "TOMILINO",
      },
      {
        CITY: "TOMSK",
      },
      {
        CITY: "TOPKI",
      },
      {
        CITY: "TORZHOK",
      },
      {
        CITY: "TOSNO",
      },
      {
        CITY: "TREKHGORNYY",
      },
      {
        CITY: "TROITSK",
      },
      {
        CITY: "TROITSKO-PECHORSK",
      },
      {
        CITY: "TRUBCHEVSK",
      },
      {
        CITY: "TSNA",
      },
      {
        CITY: "TSUDAKHAR",
      },
      {
        CITY: "TUAPSE",
      },
      {
        CITY: "TUCHKOVO",
      },
      {
        CITY: "TULA",
      },
      {
        CITY: "TULUN",
      },
      {
        CITY: "TURINSK",
      },
      {
        CITY: "TUYMAZY",
      },
      {
        CITY: "TVER",
      },
      {
        CITY: "TYNDA",
      },
      {
        CITY: "TYRMA",
      },
      {
        CITY: "TYRNYAUZ",
      },
      {
        CITY: "TYUMEN",
      },
      {
        CITY: "UCHALY",
      },
      {
        CITY: "UDOMLYA",
      },
      {
        CITY: "UFA",
      },
      {
        CITY: "UGLEGORSK",
      },
      {
        CITY: "UGLICH",
      },
      {
        CITY: "UGRA",
      },
      {
        CITY: "UGRESHA",
      },
      {
        CITY: "UKHTA",
      },
      {
        CITY: "UKTUZ",
      },
      {
        CITY: "ULAN-UDE",
      },
      {
        CITY: "ULYANOVSK",
      },
      {
        CITY: "UNECHA",
      },
      {
        CITY: "URAL",
      },
      {
        CITY: "URAY",
      },
      {
        CITY: "URENGOY",
      },
      {
        CITY: "URGAL",
      },
      {
        CITY: "URUS-MARTAN",
      },
      {
        CITY: "URYUPINSK",
      },
      {
        CITY: "URZHUM",
      },
      {
        CITY: "USINSK",
      },
      {
        CITY: "USINSKOYE",
      },
      {
        CITY: "USPENSKOYE",
      },
      {
        CITY: "USSURIYSK",
      },
      {
        CITY: "UVAROVO",
      },
      {
        CITY: "UYAR",
      },
      {
        CITY: "UZLOVAYA",
      },
      {
        CITY: "VAGAY",
      },
      {
        CITY: "VALDAY",
      },
      {
        CITY: "VALENTIN",
      },
      {
        CITY: "VANINO",
      },
      {
        CITY: "VARVAROVKA",
      },
      {
        CITY: "VEGA",
      },
      {
        CITY: "VELIKIY USTYUG",
      },
      {
        CITY: "VELIKIYE LUKI",
      },
      {
        CITY: "VELIKY NOVGOROD",
      },
      {
        CITY: "VERKHNEKAZYMSKIY",
      },
      {
        CITY: "VERKHNIY UFALEY",
      },
      {
        CITY: "VERKHNYAYA PYSHMA",
      },
      {
        CITY: "VERKHNYAYA SALDA",
      },
      {
        CITY: "VESHKI",
      },
      {
        CITY: "VICHUGA",
      },
      {
        CITY: "VIDNOYE",
      },
      {
        CITY: "VIKHOREVKA",
      },
      {
        CITY: "VINSADY",
      },
      {
        CITY: "VITEBSKAYA",
      },
      {
        CITY: "VLADIKAVKAZ",
      },
      {
        CITY: "VLADIMIR",
      },
      {
        CITY: "VLADIVOSTOK",
      },
      {
        CITY: "VLADYKINO",
      },
      {
        CITY: "VNUKOVO",
      },
      {
        CITY: "VOLGA",
      },
      {
        CITY: "VOLGODONSK",
      },
      {
        CITY: "VOLGOGRAD",
      },
      {
        CITY: "VOLGORECHENSK",
      },
      {
        CITY: "VOLKHONSHCHINO",
      },
      {
        CITY: "VOLKHOV",
      },
      {
        CITY: "VOLNA",
      },
      {
        CITY: "VOLOCHEK",
      },
      {
        CITY: "VOLODARSKIY",
      },
      {
        CITY: "VOLOGDA",
      },
      {
        CITY: "VOLOVO",
      },
      {
        CITY: "VOLZHSK",
      },
      {
        CITY: "VOLZHSKIY",
      },
      {
        CITY: "VORKUTA",
      },
      {
        CITY: "VORONEZH",
      },
      {
        CITY: "VORONEZHSKIY",
      },
      {
        CITY: "VOROVSKOGO",
      },
      {
        CITY: "VOSKRESENSK",
      },
      {
        CITY: "VOSKRESENSKOYE",
      },
      {
        CITY: "VOSTOCHNAYA",
      },
      {
        CITY: "VOSTOCHNYY",
      },
      {
        CITY: "VOTKINSK",
      },
      {
        CITY: "VSEVOLOZHSK",
      },
      {
        CITY: "VURNARY",
      },
      {
        CITY: "VYATKA",
      },
      {
        CITY: "VYATSKIYE POLYANY",
      },
      {
        CITY: "VYAZEMSKIY",
      },
      {
        CITY: "VYAZNIKI",
      },
      {
        CITY: "VYBORG",
      },
      {
        CITY: "VYKSA",
      },
      {
        CITY: "VYSOKOGORNYY",
      },
      {
        CITY: "VYSOKOVSK",
      },
      {
        CITY: "VYTEGRA",
      },
      {
        CITY: "YAKHROMA",
      },
      {
        CITY: "YAKOVLEVO",
      },
      {
        CITY: "YAKUTSK",
      },
      {
        CITY: "YALTA",
      },
      {
        CITY: "YALUTOROVSK",
      },
      {
        CITY: "YANAUL",
      },
      {
        CITY: "YARATOVO",
      },
      {
        CITY: "YAROSLAVL",
      },
      {
        CITY: "YASHKINO",
      },
      {
        CITY: "YAYA",
      },
      {
        CITY: "YEKATERINBURG",
      },
      {
        CITY: "YEKATERINOSLAVKA",
      },
      {
        CITY: "YEKATERINOVKA",
      },
      {
        CITY: "YELABUGA",
      },
      {
        CITY: "YELANSKIY",
      },
      {
        CITY: "YELETS",
      },
      {
        CITY: "YELIZOVO",
      },
      {
        CITY: "YERMOLKINO",
      },
      {
        CITY: "YESSENTUKI",
      },
      {
        CITY: "YEYSK",
      },
      {
        CITY: "YOSHKAR-OLA",
      },
      {
        CITY: "YUGORSK",
      },
      {
        CITY: "YUGRA",
      },
      {
        CITY: "YURGA",
      },
      {
        CITY: "YUZHNO-SAKHALINSK",
      },
      {
        CITY: "ZAINSK",
      },
      {
        CITY: "ZAOKSKIY",
      },
      {
        CITY: "ZAOZERSK",
      },
      {
        CITY: "ZAPRUDNYA",
      },
      {
        CITY: "ZARAYSK",
      },
      {
        CITY: "ZARECHENSKIY",
      },
      {
        CITY: "ZARECHNYY",
      },
      {
        CITY: "ZARINSK",
      },
      {
        CITY: "ZAVODOUKOVSK",
      },
      {
        CITY: "ZAVODOUSPENSKOYE",
      },
      {
        CITY: "ZELENODOLSK",
      },
      {
        CITY: "ZELENOGORSK",
      },
      {
        CITY: "ZELENOGRAD",
      },
      {
        CITY: "ZELENOGRADSK",
      },
      {
        CITY: "ZERNOGRAD",
      },
      {
        CITY: "ZEYA",
      },
      {
        CITY: "ZHELEZNODOROZHNYY",
      },
      {
        CITY: "ZHELEZNOGORSK",
      },
      {
        CITY: "ZHELEZNOGORSK-ILIMSKIY",
      },
      {
        CITY: "ZHELEZNOVODSK",
      },
      {
        CITY: "ZHIGULEVSK",
      },
      {
        CITY: "ZHIRNOVSK",
      },
      {
        CITY: "ZHUKOVSKIY",
      },
      {
        CITY: "ZHUKOVSKOGO",
      },
      {
        CITY: "ZHURAVNA",
      },
      {
        CITY: "ZIMA",
      },
      {
        CITY: "ZLATOUST",
      },
      {
        CITY: "ZOLOTAREVKA",
      },
      {
        CITY: "ZORI",
      },
      {
        CITY: "ZUBOVO",
      },
      {
        CITY: "ZVENIGOROD",
      },
      {
        CITY: "ZVENIGOVO",
      },
      {
        CITY: "ZVEZDA",
      },
    ],
  },
  {
    COUNTRY: "POLAND",
    CODE: "PL",
    CITIES: [
      {
        CITY: "ADAMA",
      },
      {
        CITY: "ALWERNIA",
      },
      {
        CITY: "ANDRESPOL",
      },
      {
        CITY: "ANDRYCHOW",
      },
      {
        CITY: "ANIN",
      },
      {
        CITY: "ANNOPOL",
      },
      {
        CITY: "ARKADIA",
      },
      {
        CITY: "BABIENICA",
      },
      {
        CITY: "BABIMOST",
      },
      {
        CITY: "BABOROW",
      },
      {
        CITY: "BABOSZEWO",
      },
      {
        CITY: "BALICE",
      },
      {
        CITY: "BANINO",
      },
      {
        CITY: "BANIOCHA",
      },
      {
        CITY: "BARAN",
      },
      {
        CITY: "BARANOW",
      },
      {
        CITY: "BARANOWKO",
      },
      {
        CITY: "BARCIANY",
      },
      {
        CITY: "BARCICE",
      },
      {
        CITY: "BARCIN",
      },
      {
        CITY: "BARCZEWO",
      },
      {
        CITY: "BARGLOWKA",
      },
      {
        CITY: "BARLINEK",
      },
      {
        CITY: "BARTOSZYCE",
      },
      {
        CITY: "BARUCHOWO",
      },
      {
        CITY: "BARWICE",
      },
      {
        CITY: "BAZANOWKA",
      },
      {
        CITY: "BEBLO",
      },
      {
        CITY: "BEDKOW",
      },
      {
        CITY: "BEJSCE",
      },
      {
        CITY: "BELK",
      },
      {
        CITY: "BELSK DUZY",
      },
      {
        CITY: "BELSZNICA",
      },
      {
        CITY: "BESTWINA",
      },
      {
        CITY: "BESTWINKA",
      },
      {
        CITY: "BIALA",
      },
      {
        CITY: "BIALOBRZEGI",
      },
      {
        CITY: "BIALY DUNAJEC",
      },
      {
        CITY: "BIBICE",
      },
      {
        CITY: "BIECZ",
      },
      {
        CITY: "BIEDRUSKO",
      },
      {
        CITY: "BIELANY",
      },
      {
        CITY: "BIELANY WROCLAWSKIE",
      },
      {
        CITY: "BIELAWA",
      },
      {
        CITY: "BIELAWY",
      },
      {
        CITY: "BIELCZA",
      },
      {
        CITY: "BIELINY",
      },
      {
        CITY: "BIELKOWO",
      },
      {
        CITY: "BIELSK",
      },
      {
        CITY: "BIELSK PODLASKI",
      },
      {
        CITY: "BIELSKO-BIALA",
      },
      {
        CITY: "BIERUN",
      },
      {
        CITY: "BIERUN NOWY",
      },
      {
        CITY: "BIERUTOW",
      },
      {
        CITY: "BIESIEKIERZ",
      },
      {
        CITY: "BIEZANOW-PROKOCIM",
      },
      {
        CITY: "BIRCZA",
      },
      {
        CITY: "BISKUPICE",
      },
      {
        CITY: "BISKUPICE OLOBOCZNE",
      },
      {
        CITY: "BISKUPIEC",
      },
      {
        CITY: "BISKUPIN",
      },
      {
        CITY: "BISZTYNEK",
      },
      {
        CITY: "BLACHOWNIA",
      },
      {
        CITY: "BLAZOWA",
      },
      {
        CITY: "BLEDZEW",
      },
      {
        CITY: "BLIZNE",
      },
      {
        CITY: "BLIZYN",
      },
      {
        CITY: "BOBROWNIKI",
      },
      {
        CITY: "BOBRZA",
      },
      {
        CITY: "BOCHNIA",
      },
      {
        CITY: "BOCHOTNICA",
      },
      {
        CITY: "BOCHOWO",
      },
      {
        CITY: "BODZENTYN",
      },
      {
        CITY: "BOGATYNIA",
      },
      {
        CITY: "BOGDASZOWICE",
      },
      {
        CITY: "BOGUMILOWICE",
      },
      {
        CITY: "BOGUNICE",
      },
      {
        CITY: "BOGUSLAW",
      },
      {
        CITY: "BOHDAN",
      },
      {
        CITY: "BOJADLA",
      },
      {
        CITY: "BOJANO",
      },
      {
        CITY: "BOJANOWO",
      },
      {
        CITY: "BOJSZOW",
      },
      {
        CITY: "BOJSZOWY",
      },
      {
        CITY: "BOLECHOWICE",
      },
      {
        CITY: "BOLESLAW",
      },
      {
        CITY: "BOLEWICE",
      },
      {
        CITY: "BOLKOW",
      },
      {
        CITY: "BOLSZEWO",
      },
      {
        CITY: "BOREK STRZELINSKI",
      },
      {
        CITY: "BORKI",
      },
      {
        CITY: "BORKOWICE",
      },
      {
        CITY: "BORKOWO",
      },
      {
        CITY: "BORNE SULINOWO",
      },
      {
        CITY: "BOROWA",
      },
      {
        CITY: "BOROWE",
      },
      {
        CITY: "BOROWIEC",
      },
      {
        CITY: "BORY",
      },
      {
        CITY: "BORZECIN",
      },
      {
        CITY: "BORZYKOWO",
      },
      {
        CITY: "BRALIN",
      },
      {
        CITY: "BRAMKI",
      },
      {
        CITY: "BRANIEWO",
      },
      {
        CITY: "BRASZEWICE",
      },
      {
        CITY: "BRATKOWICE",
      },
      {
        CITY: "BRENNA",
      },
      {
        CITY: "BROCHOCIN",
      },
      {
        CITY: "BRODNICA",
      },
      {
        CITY: "BROK",
      },
      {
        CITY: "BRUDZICE",
      },
      {
        CITY: "BRUDZOWICE",
      },
      {
        CITY: "BRYNICA",
      },
      {
        CITY: "BRZEG",
      },
      {
        CITY: "BRZEG DOLNY",
      },
      {
        CITY: "BRZESKO",
      },
      {
        CITY: "BRZESZCZE",
      },
      {
        CITY: "BRZEZINKA",
      },
      {
        CITY: "BRZEZINY",
      },
      {
        CITY: "BRZEZNICA",
      },
      {
        CITY: "BRZOSTEK",
      },
      {
        CITY: "BRZOSTOWKA",
      },
      {
        CITY: "BRZOZA KROLEWSKA",
      },
      {
        CITY: "BRZOZOW",
      },
      {
        CITY: "BRZYSKA WOLA",
      },
      {
        CITY: "BUCZKOWICE",
      },
      {
        CITY: "BUDZISKA",
      },
      {
        CITY: "BUDZYN",
      },
      {
        CITY: "BUK",
      },
      {
        CITY: "BUKOWICE",
      },
      {
        CITY: "BUKOWIEC OPOCZYNSKI",
      },
      {
        CITY: "BUKOWINA TATRZANSKA",
      },
      {
        CITY: "BUKOWNO",
      },
      {
        CITY: "BUKOWSKO",
      },
      {
        CITY: "BURZENIN",
      },
      {
        CITY: "BYCHAWA",
      },
      {
        CITY: "BYCZYNA",
      },
      {
        CITY: "BYDGOSZCZ",
      },
      {
        CITY: "BYDLIN",
      },
      {
        CITY: "BYSLAW",
      },
      {
        CITY: "BYSTRA",
      },
      {
        CITY: "BYSTRZYCA KLODZKA",
      },
      {
        CITY: "BYTOM",
      },
      {
        CITY: "CEKCYN",
      },
      {
        CITY: "CEKOW",
      },
      {
        CITY: "CHALUPKI",
      },
      {
        CITY: "CHARZYKOWY",
      },
      {
        CITY: "CHECINY",
      },
      {
        CITY: "CHELM",
      },
      {
        CITY: "CHELMEK",
      },
      {
        CITY: "CHELMSKO SLASKIE",
      },
      {
        CITY: "CHMIELNIK",
      },
      {
        CITY: "CHMIELOW",
      },
      {
        CITY: "CHOCIANOW",
      },
      {
        CITY: "CHOCIWEL",
      },
      {
        CITY: "CHOCZEWO",
      },
      {
        CITY: "CHOCZNIA",
      },
      {
        CITY: "CHOJNA",
      },
      {
        CITY: "CHOJNICE",
      },
      {
        CITY: "CHOROSZCZ",
      },
      {
        CITY: "CHORZELE",
      },
      {
        CITY: "CHORZELOW",
      },
      {
        CITY: "CHOSZCZNO",
      },
      {
        CITY: "CHOTEL",
      },
      {
        CITY: "CHOTOMOW",
      },
      {
        CITY: "CHRZAN",
      },
      {
        CITY: "CHRZASTOWKA",
      },
      {
        CITY: "CHRZESNE",
      },
      {
        CITY: "CHRZOWICE",
      },
      {
        CITY: "CHWASZCZYNO",
      },
      {
        CITY: "CHYBIE",
      },
      {
        CITY: "CHYLICE",
      },
      {
        CITY: "CHYLICZKI",
      },
      {
        CITY: "CIANOWICE DUZE",
      },
      {
        CITY: "CIASNA",
      },
      {
        CITY: "CIECHOCIN",
      },
      {
        CITY: "CIECHOCINEK",
      },
      {
        CITY: "CIERPICE",
      },
      {
        CITY: "CIESZKOW",
      },
      {
        CITY: "CIESZYN",
      },
      {
        CITY: "CIEZKOWICE",
      },
      {
        CITY: "CIGACICE",
      },
      {
        CITY: "CISIEC",
      },
      {
        CITY: "CMIELOW",
      },
      {
        CITY: "CMOLAS",
      },
      {
        CITY: "CWIKLICE",
      },
      {
        CITY: "CYBINKA",
      },
      {
        CITY: "CZANIEC",
      },
      {
        CITY: "CZAPLINEK",
      },
      {
        CITY: "CZAPURY",
      },
      {
        CITY: "CZARNA",
      },
      {
        CITY: "CZARNE",
      },
      {
        CITY: "CZARNKOW",
      },
      {
        CITY: "CZARNOZYLY",
      },
      {
        CITY: "CZARNY BOR",
      },
      {
        CITY: "CZARNY DUNAJEC",
      },
      {
        CITY: "CZARNY LAS",
      },
      {
        CITY: "CZARZE",
      },
      {
        CITY: "CZASTARY",
      },
      {
        CITY: "CZECHOWICE-DZIEDZICE",
      },
      {
        CITY: "CZEKANOW",
      },
      {
        CITY: "CZELADZ",
      },
      {
        CITY: "CZEMPIN",
      },
      {
        CITY: "CZERNICA",
      },
      {
        CITY: "CZERNICHOW",
      },
      {
        CITY: "CZERNIEWICE",
      },
      {
        CITY: "CZERSK",
      },
      {
        CITY: "CZERWIENSK",
      },
      {
        CITY: "CZERWIONKA-LESZCZYNY",
      },
      {
        CITY: "CZERWONAK",
      },
      {
        CITY: "CZLUCHOW",
      },
      {
        CITY: "CZOSNOW",
      },
      {
        CITY: "CZUDEC",
      },
      {
        CITY: "CZYZYNY",
      },
      {
        CITY: "DABKI",
      },
      {
        CITY: "DABROWA",
      },
      {
        CITY: "DABROWA BIALOSTOCKA",
      },
      {
        CITY: "DABROWA BISKUPIA",
      },
      {
        CITY: "DABROWA CHELMINSKA",
      },
      {
        CITY: "DABROWA CHOTOMOWSKA",
      },
      {
        CITY: "DABROWKA WIELKA",
      },
      {
        CITY: "DABROWNO",
      },
      {
        CITY: "DABROWSKIE",
      },
      {
        CITY: "DALESZYCE",
      },
      {
        CITY: "DAMNICA",
      },
      {
        CITY: "DANKOWICE",
      },
      {
        CITY: "DARGOSLAW",
      },
      {
        CITY: "DARNOWO",
      },
      {
        CITY: "DEBE WIELKIE",
      },
      {
        CITY: "DEBICA",
      },
      {
        CITY: "DEBINA",
      },
      {
        CITY: "DEBLIN",
      },
      {
        CITY: "DEBNO",
      },
      {
        CITY: "DEBOWIEC",
      },
      {
        CITY: "DEBRZNO WIES",
      },
      {
        CITY: "DESZCZNO",
      },
      {
        CITY: "DLUGOPOLE-ZDROJ",
      },
      {
        CITY: "DOBIESZ",
      },
      {
        CITY: "DOBIESZOWICE",
      },
      {
        CITY: "DOBRA",
      },
      {
        CITY: "DOBRE MIASTO",
      },
      {
        CITY: "DOBRODZIEN",
      },
      {
        CITY: "DOBROMIERZ",
      },
      {
        CITY: "DOBRON",
      },
      {
        CITY: "DOBROSZYCE",
      },
      {
        CITY: "DOBRZANY",
      },
      {
        CITY: "DOBRZEN WIELKI",
      },
      {
        CITY: "DOBRZYCA",
      },
      {
        CITY: "DOLASZEWO",
      },
      {
        CITY: "DOLICE",
      },
      {
        CITY: "DOMANIEWICE",
      },
      {
        CITY: "DOMARADZ",
      },
      {
        CITY: "DOMASLAW",
      },
      {
        CITY: "DOPIEWIEC",
      },
      {
        CITY: "DRAWNO",
      },
      {
        CITY: "DRAWSKO POMORSKIE",
      },
      {
        CITY: "DREWNICA",
      },
      {
        CITY: "DREZDENKO",
      },
      {
        CITY: "DROHICZYN",
      },
      {
        CITY: "DROZDOWO",
      },
      {
        CITY: "DRUZBICE",
      },
      {
        CITY: "DRZEWICA",
      },
      {
        CITY: "DUCZKI",
      },
      {
        CITY: "DUKLA",
      },
      {
        CITY: "DULCZA WIELKA",
      },
      {
        CITY: "DUNAJ",
      },
      {
        CITY: "DUSZNIKI-ZDROJ",
      },
      {
        CITY: "DYGOWO",
      },
      {
        CITY: "DYLEWO",
      },
      {
        CITY: "DYNOW",
      },
      {
        CITY: "DYWITY",
      },
      {
        CITY: "DZIALOSZYN",
      },
      {
        CITY: "DZIECHCINIEC",
      },
      {
        CITY: "DZIEGIELOW",
      },
      {
        CITY: "DZIEKANOW LESNY",
      },
      {
        CITY: "DZIELNA",
      },
      {
        CITY: "DZIERZAZNO",
      },
      {
        CITY: "DZIERZGON",
      },
      {
        CITY: "DZIERZONIOW",
      },
      {
        CITY: "DZIEWIN",
      },
      {
        CITY: "DZIKOWIEC",
      },
      {
        CITY: "DZIWNOW",
      },
      {
        CITY: "DZWIERZUTY",
      },
      {
        CITY: "ELBLAG",
      },
      {
        CITY: "ELZBIETA",
      },
      {
        CITY: "FABIANKI",
      },
      {
        CITY: "FALKOW",
      },
      {
        CITY: "FALKOWO",
      },
      {
        CITY: "FRANK",
      },
      {
        CITY: "FROMBORK",
      },
      {
        CITY: "FRYDRYCHOWICE",
      },
      {
        CITY: "FRYSZTAK",
      },
      {
        CITY: "GADKA STARA",
      },
      {
        CITY: "GARBOW",
      },
      {
        CITY: "GARBY",
      },
      {
        CITY: "GARKI",
      },
      {
        CITY: "GARWOLIN",
      },
      {
        CITY: "GASZOWICE",
      },
      {
        CITY: "GAWORZYCE",
      },
      {
        CITY: "GDOW",
      },
      {
        CITY: "GDYNIA",
      },
      {
        CITY: "GIBY",
      },
      {
        CITY: "GIERALTOWICE",
      },
      {
        CITY: "GIZALKI",
      },
      {
        CITY: "GIZYCE",
      },
      {
        CITY: "GLIWICE",
      },
      {
        CITY: "GLOGOW MALOPOLSKI",
      },
      {
        CITY: "GLOGOWEK",
      },
      {
        CITY: "GLOSKOW",
      },
      {
        CITY: "GLOWIENKA",
      },
      {
        CITY: "GLOWNA",
      },
      {
        CITY: "GLOWNO",
      },
      {
        CITY: "GLUBCZYCE",
      },
      {
        CITY: "GLUCHOLAZY",
      },
      {
        CITY: "GLUCHOW",
      },
      {
        CITY: "GLUSZYCA",
      },
      {
        CITY: "GMINA BABIAK",
      },
      {
        CITY: "GMINA BOBROWO",
      },
      {
        CITY: "GMINA CHMIELNO",
      },
      {
        CITY: "GMINA CIECHANOWIEC",
      },
      {
        CITY: "GMINA GNOJNO",
      },
      {
        CITY: "GMINA KISZKOWO",
      },
      {
        CITY: "GMINA KOLNO",
      },
      {
        CITY: "GMINA KONARZYNY",
      },
      {
        CITY: "GMINA LIPNO",
      },
      {
        CITY: "GMINA LUDWIN",
      },
      {
        CITY: "GMINA LUTOMIERSK",
      },
      {
        CITY: "GMINA MORAWICA",
      },
      {
        CITY: "GMINA MOSZCZENICA",
      },
      {
        CITY: "GMINA OPOLE LUBELSKIE",
      },
      {
        CITY: "GMINA PRZYWIDZ",
      },
      {
        CITY: "GMINA SADOWIE",
      },
      {
        CITY: "GMINA SIERAKOWICE",
      },
      {
        CITY: "GMINA STRZELIN",
      },
      {
        CITY: "GMINA SZUBIN",
      },
      {
        CITY: "GMINA WIDAWA",
      },
      {
        CITY: "GMINA WYSZKI",
      },
      {
        CITY: "GMINA ZAKLICZYN",
      },
      {
        CITY: "GNIEW",
      },
      {
        CITY: "GNIEWINO",
      },
      {
        CITY: "GNIEWKOWO",
      },
      {
        CITY: "GNIEZNO",
      },
      {
        CITY: "GNUSZYN",
      },
      {
        CITY: "GODZISZEWO",
      },
      {
        CITY: "GOGOLIN",
      },
      {
        CITY: "GOLANICE",
      },
      {
        CITY: "GOLASOWICE",
      },
      {
        CITY: "GOLCZEWO",
      },
      {
        CITY: "GOLESZOW",
      },
      {
        CITY: "GOLKOWICE",
      },
      {
        CITY: "GOLOTCZYZNA",
      },
      {
        CITY: "GOLUB-DOBRZYN",
      },
      {
        CITY: "GOMUNICE",
      },
      {
        CITY: "GORA",
      },
      {
        CITY: "GORA KALWARIA",
      },
      {
        CITY: "GORA PULAWSKA",
      },
      {
        CITY: "GORA SIEWIERSKA",
      },
      {
        CITY: "GORAZDZE",
      },
      {
        CITY: "GORKI WIELKIE",
      },
      {
        CITY: "GORLICE",
      },
      {
        CITY: "GORNO",
      },
      {
        CITY: "GORZ",
      },
      {
        CITY: "GORZKOW",
      },
      {
        CITY: "GORZKOWICE",
      },
      {
        CITY: "GORZOW",
      },
      {
        CITY: "GORZOW SLASKI",
      },
      {
        CITY: "GORZYCE",
      },
      {
        CITY: "GORZYCZKI",
      },
      {
        CITY: "GOSTYN",
      },
      {
        CITY: "GOSTYNIN",
      },
      {
        CITY: "GOSZCZYN",
      },
      {
        CITY: "GOWARZEWO",
      },
      {
        CITY: "GOWINO",
      },
      {
        CITY: "GOZDNICA",
      },
      {
        CITY: "GOZDOWO",
      },
      {
        CITY: "GRABIEC",
      },
      {
        CITY: "GRABKI DUZE",
      },
      {
        CITY: "GRABOW NAD PROSNA",
      },
      {
        CITY: "GRABOWKA",
      },
      {
        CITY: "GRACZE",
      },
      {
        CITY: "GRAJEWO",
      },
      {
        CITY: "GREBOCICE",
      },
      {
        CITY: "GREBOCIN",
      },
      {
        CITY: "GRODKI",
      },
      {
        CITY: "GRODKOW",
      },
      {
        CITY: "GRODZISK",
      },
      {
        CITY: "GRODZISK MAZOWIECKI",
      },
      {
        CITY: "GRODZISK WIELKOPOLSKI",
      },
      {
        CITY: "GRODZISKO DOLNE",
      },
      {
        CITY: "GROJEC",
      },
      {
        CITY: "GROMIEC",
      },
      {
        CITY: "GRONOWO ELBLASKIE",
      },
      {
        CITY: "GRUCZNO",
      },
      {
        CITY: "GRUNWALD",
      },
      {
        CITY: "GRUPA",
      },
      {
        CITY: "GRUSZCZYN",
      },
      {
        CITY: "GRYBOW",
      },
      {
        CITY: "GRYFICE",
      },
      {
        CITY: "GRYFINO",
      },
      {
        CITY: "GRYFOW SLASKI",
      },
      {
        CITY: "GRZEBIEN",
      },
      {
        CITY: "GRZEGORZ",
      },
      {
        CITY: "GRZMIACA",
      },
      {
        CITY: "GRZMUCIN",
      },
      {
        CITY: "GUBIN",
      },
      {
        CITY: "GZIN",
      },
      {
        CITY: "HACZOW",
      },
      {
        CITY: "HARASIUKI",
      },
      {
        CITY: "HECZNAROWICE",
      },
      {
        CITY: "HENRYKOW",
      },
      {
        CITY: "HORNOWEK",
      },
      {
        CITY: "HUCISKO JAWORNICKIE",
      },
      {
        CITY: "HUMNISKA",
      },
      {
        CITY: "HUTA DABROWA",
      },
      {
        CITY: "HUTA DLUTOWSKA",
      },
      {
        CITY: "HUTA STARA",
      },
      {
        CITY: "ILOWA",
      },
      {
        CITY: "ILOWO",
      },
      {
        CITY: "ILZA",
      },
      {
        CITY: "IMIELIN",
      },
      {
        CITY: "IWANISKA",
      },
      {
        CITY: "IWANOWICE",
      },
      {
        CITY: "IWICZNA",
      },
      {
        CITY: "IWIERZYCE",
      },
      {
        CITY: "IWLA",
      },
      {
        CITY: "IWONICZ-ZDROJ",
      },
      {
        CITY: "IZABELA",
      },
      {
        CITY: "IZABELIN",
      },
      {
        CITY: "JABLONICA",
      },
      {
        CITY: "JABLONKA",
      },
      {
        CITY: "JABLONNA",
      },
      {
        CITY: "JANIN",
      },
      {
        CITY: "JANKOWICE",
      },
      {
        CITY: "JANOW",
      },
      {
        CITY: "JANOW LUBELSKI",
      },
      {
        CITY: "JARKOWICE",
      },
      {
        CITY: "JAROCIN",
      },
      {
        CITY: "JAROSZOW",
      },
      {
        CITY: "JAROSZOWIEC",
      },
      {
        CITY: "JASIENICA",
      },
      {
        CITY: "JASIENICA DOLNA",
      },
      {
        CITY: "JASIENIEC",
      },
      {
        CITY: "JASLO",
      },
      {
        CITY: "JASTKOW",
      },
      {
        CITY: "JASTROWIE",
      },
      {
        CITY: "JASTRZEBIE",
      },
      {
        CITY: "JAWISZOWICE",
      },
      {
        CITY: "JAWOR",
      },
      {
        CITY: "JAWORZNO",
      },
      {
        CITY: "JAWORZYNA SLASKA",
      },
      {
        CITY: "JEDLICZE",
      },
      {
        CITY: "JEDLNIA-LETNISKO",
      },
      {
        CITY: "JELESNIA",
      },
      {
        CITY: "JEMIELNICA",
      },
      {
        CITY: "JERZMANOWICE",
      },
      {
        CITY: "JEZIORA WIELKIE",
      },
      {
        CITY: "JEZIORANY",
      },
      {
        CITY: "JEZOWE",
      },
      {
        CITY: "JORDANOW",
      },
      {
        CITY: "JOZEFATOW",
      },
      {
        CITY: "JOZEFOSLAW",
      },
      {
        CITY: "JOZEFOW",
      },
      {
        CITY: "JUCHNOWIEC KOSCIELNY",
      },
      {
        CITY: "JUGOW",
      },
      {
        CITY: "JUSZKOWO",
      },
      {
        CITY: "JUTROSIN",
      },
      {
        CITY: "KACZKOWO",
      },
      {
        CITY: "KACZOR",
      },
      {
        CITY: "KALETY",
      },
      {
        CITY: "KALISZ",
      },
      {
        CITY: "KALWARIA ZEBRZYDOWSKA",
      },
      {
        CITY: "KAMIEN",
      },
      {
        CITY: "KAMIEN KRAJENSKI",
      },
      {
        CITY: "KAMIEN POMORSKI",
      },
      {
        CITY: "KAMIEN SLASKI",
      },
      {
        CITY: "KAMIENICA POLSKA",
      },
      {
        CITY: "KAMIENIEC",
      },
      {
        CITY: "KAMIENNA GORA",
      },
      {
        CITY: "KAMIONKI",
      },
      {
        CITY: "KAMPINOS",
      },
      {
        CITY: "KANCZUGA",
      },
      {
        CITY: "KARCHOWICE",
      },
      {
        CITY: "KARCZEW",
      },
      {
        CITY: "KARGOWA",
      },
      {
        CITY: "KARLIKOWO",
      },
      {
        CITY: "KARLINO",
      },
      {
        CITY: "KARNICE",
      },
      {
        CITY: "KARNIOWICE",
      },
      {
        CITY: "KARPACZ",
      },
      {
        CITY: "KARPISKA",
      },
      {
        CITY: "KARSKO",
      },
      {
        CITY: "KARTUZY",
      },
      {
        CITY: "KASINKA",
      },
      {
        CITY: "KATARZYNA",
      },
      {
        CITY: "KATOWICE",
      },
      {
        CITY: "KATY",
      },
      {
        CITY: "KATY WROCLAWSKIE",
      },
      {
        CITY: "KAZIMIERZ BISKUPI",
      },
      {
        CITY: "KAZIMIERZ DOLNY",
      },
      {
        CITY: "KAZIMIERZA WIELKA",
      },
      {
        CITY: "KAZMIERZ",
      },
      {
        CITY: "KCYNIA",
      },
      {
        CITY: "KEBLOWO",
      },
      {
        CITY: "KEDZIERZYN",
      },
      {
        CITY: "KEDZIERZYN-KOZLE",
      },
      {
        CITY: "KETY",
      },
      {
        CITY: "KICIN",
      },
      {
        CITY: "KIELCE",
      },
      {
        CITY: "KIELCZ",
      },
      {
        CITY: "KIELCZOW",
      },
      {
        CITY: "KIELNO",
      },
      {
        CITY: "KIELPIN",
      },
      {
        CITY: "KIJEWO KROLEWSKIE",
      },
      {
        CITY: "KLAJ",
      },
      {
        CITY: "KLECKO",
      },
      {
        CITY: "KLECZA DOLNA",
      },
      {
        CITY: "KLECZANY",
      },
      {
        CITY: "KLENICA",
      },
      {
        CITY: "KLESZCZEWO",
      },
      {
        CITY: "KLESZCZOW",
      },
      {
        CITY: "KLIKAWA",
      },
      {
        CITY: "KLIKUSZOWA",
      },
      {
        CITY: "KLIMONTOW",
      },
      {
        CITY: "KLINISKA",
      },
      {
        CITY: "KLOBUCK",
      },
      {
        CITY: "KLODAWA",
      },
      {
        CITY: "KLOMNICE",
      },
      {
        CITY: "KLUCZBORK",
      },
      {
        CITY: "KLUCZE",
      },
      {
        CITY: "KNUROW",
      },
      {
        CITY: "KNYSZYN",
      },
      {
        CITY: "KOBIERNICE",
      },
      {
        CITY: "KOBIERZYCE",
      },
      {
        CITY: "KOBIOR",
      },
      {
        CITY: "KOBYLANKA",
      },
      {
        CITY: "KOBYLKA",
      },
      {
        CITY: "KOBYLNICA",
      },
      {
        CITY: "KOCHCICE",
      },
      {
        CITY: "KOCIERZEW POLUDNIOWY",
      },
      {
        CITY: "KOCMYRZOW",
      },
      {
        CITY: "KOJSZOWKA",
      },
      {
        CITY: "KOKOSZKOWY",
      },
      {
        CITY: "KOLBUDY",
      },
      {
        CITY: "KOLBUSZOWA",
      },
      {
        CITY: "KOLECZKOWO",
      },
      {
        CITY: "KOLNO",
      },
      {
        CITY: "KOLODZIEJEWO",
      },
      {
        CITY: "KOLONIA ZAWADA",
      },
      {
        CITY: "KOLONOWSKIE",
      },
      {
        CITY: "KOLUSZKI",
      },
      {
        CITY: "KOMORNIKI",
      },
      {
        CITY: "KOMOROW",
      },
      {
        CITY: "KOMORSK",
      },
      {
        CITY: "KONARZEWO",
      },
      {
        CITY: "KONARZYCE",
      },
      {
        CITY: "KONCZYCE MALE",
      },
      {
        CITY: "KONIAKOW",
      },
      {
        CITY: "KONIECPOL",
      },
      {
        CITY: "KONIN",
      },
      {
        CITY: "KONIUSZA",
      },
      {
        CITY: "KONOPISKA",
      },
      {
        CITY: "KONRADOWKA",
      },
      {
        CITY: "KONSTANCIN-JEZIORNA",
      },
      {
        CITY: "KONSTANTYN",
      },
      {
        CITY: "KONSTANTYNOW",
      },
      {
        CITY: "KONSTANTYNOW LODZKI",
      },
      {
        CITY: "KOPKI",
      },
      {
        CITY: "KORCZYNA",
      },
      {
        CITY: "KORFANTOW",
      },
      {
        CITY: "KORNIK",
      },
      {
        CITY: "KORONOWO",
      },
      {
        CITY: "KORSZE",
      },
      {
        CITY: "KORYTOW",
      },
      {
        CITY: "KORZENNA",
      },
      {
        CITY: "KOSAKOWO",
      },
      {
        CITY: "KOSCIELISKO",
      },
      {
        CITY: "KOSCIERZYNA",
      },
      {
        CITY: "KOSINA",
      },
      {
        CITY: "KOSTRZYN",
      },
      {
        CITY: "KOSTRZYN NAD ODRA",
      },
      {
        CITY: "KOSZALIN",
      },
      {
        CITY: "KOSZECIN",
      },
      {
        CITY: "KOSZYCE",
      },
      {
        CITY: "KOTEZE",
      },
      {
        CITY: "KOTLIN",
      },
      {
        CITY: "KOWAL",
      },
      {
        CITY: "KOWALEW",
      },
      {
        CITY: "KOWALEWO",
      },
      {
        CITY: "KOWALEWO POMORSKIE",
      },
      {
        CITY: "KOWALKOW",
      },
      {
        CITY: "KOWALOWA",
      },
      {
        CITY: "KOWARY",
      },
      {
        CITY: "KOWIESY",
      },
      {
        CITY: "KOZIEGLOWY",
      },
      {
        CITY: "KOZIENICE",
      },
      {
        CITY: "KOZMIN WIELKOPOLSKI",
      },
      {
        CITY: "KOZMINEK",
      },
      {
        CITY: "KOZUCHOW",
      },
      {
        CITY: "KOZY",
      },
      {
        CITY: "KRAKOW",
      },
      {
        CITY: "KRAPKOWICE",
      },
      {
        CITY: "KRASIEJOW",
      },
      {
        CITY: "KRASNE",
      },
      {
        CITY: "KRASNIK",
      },
      {
        CITY: "KRASNYSTAW",
      },
      {
        CITY: "KRASOCIN",
      },
      {
        CITY: "KRASZEW",
      },
      {
        CITY: "KREPA KASZUBSKA",
      },
      {
        CITY: "KROKOWA",
      },
      {
        CITY: "KROSCIENKO WYZNE",
      },
      {
        CITY: "KROSNO",
      },
      {
        CITY: "KROSNO ODRZANSKIE",
      },
      {
        CITY: "KROSNOWICE",
      },
      {
        CITY: "KROTOSZYN",
      },
      {
        CITY: "KRUSZYN",
      },
      {
        CITY: "KRUSZYNA",
      },
      {
        CITY: "KRYNICA",
      },
      {
        CITY: "KRYNICA-ZDROJ",
      },
      {
        CITY: "KRYPNO",
      },
      {
        CITY: "KRZEPICE",
      },
      {
        CITY: "KRZESZOW",
      },
      {
        CITY: "KRZESZOWICE",
      },
      {
        CITY: "KRZYKI-PARTYNICE",
      },
      {
        CITY: "KRZYSZKOWICE",
      },
      {
        CITY: "KRZYWCZA",
      },
      {
        CITY: "KRZYWIN",
      },
      {
        CITY: "KRZYZ WIELKOPOLSKI",
      },
      {
        CITY: "KRZYZANOWICE",
      },
      {
        CITY: "KSIAZENICE",
      },
      {
        CITY: "KSIEGINICE",
      },
      {
        CITY: "KUKOW",
      },
      {
        CITY: "KULIGOW",
      },
      {
        CITY: "KUNICE",
      },
      {
        CITY: "KUNOW",
      },
      {
        CITY: "KURDWANOW",
      },
      {
        CITY: "KUREK",
      },
      {
        CITY: "KURYLOWKA",
      },
      {
        CITY: "KURZETNIK",
      },
      {
        CITY: "KUSNIERZ",
      },
      {
        CITY: "KUTNO",
      },
      {
        CITY: "KUZNIA",
      },
      {
        CITY: "KUZNICA CZARNKOWSKA",
      },
      {
        CITY: "KUZNIKI",
      },
      {
        CITY: "KWACZALA",
      },
      {
        CITY: "KWIDZYN",
      },
      {
        CITY: "KWILCZ",
      },
      {
        CITY: "LABISZYN",
      },
      {
        CITY: "LABOWA",
      },
      {
        CITY: "LABUNIE",
      },
      {
        CITY: "LACHOWICE",
      },
      {
        CITY: "LACK",
      },
      {
        CITY: "LACKO",
      },
      {
        CITY: "LADEK",
      },
      {
        CITY: "LAJSK",
      },
      {
        CITY: "LAKA",
      },
      {
        CITY: "LANCUT",
      },
      {
        CITY: "LANY",
      },
      {
        CITY: "LASK",
      },
      {
        CITY: "LASKA",
      },
      {
        CITY: "LASKARZEW",
      },
      {
        CITY: "LATOWICZ",
      },
      {
        CITY: "LAZISKA",
      },
      {
        CITY: "LAZISKA GORNE",
      },
      {
        CITY: "LEBA",
      },
      {
        CITY: "LEBORK",
      },
      {
        CITY: "LECZNA",
      },
      {
        CITY: "LEDZINY",
      },
      {
        CITY: "LEGIONOWO",
      },
      {
        CITY: "LEGNICA",
      },
      {
        CITY: "LEGOWO",
      },
      {
        CITY: "LEKAWICA",
      },
      {
        CITY: "LELOW",
      },
      {
        CITY: "LESKO",
      },
      {
        CITY: "LESNA",
      },
      {
        CITY: "LESNICA",
      },
      {
        CITY: "LESZKOWICE",
      },
      {
        CITY: "LESZNO",
      },
      {
        CITY: "LEWIN BRZESKI",
      },
      {
        CITY: "LEZAJSK",
      },
      {
        CITY: "LIBIAZ",
      },
      {
        CITY: "LIDZBARK",
      },
      {
        CITY: "LIGOTA",
      },
      {
        CITY: "LIMANOWA",
      },
      {
        CITY: "LINIEWO",
      },
      {
        CITY: "LINOWKO",
      },
      {
        CITY: "LIPINKI LUZYCKIE",
      },
      {
        CITY: "LIPINY",
      },
      {
        CITY: "LIPNICA MUROWANA",
      },
      {
        CITY: "LIPNICA WIELKA",
      },
      {
        CITY: "LIPNIK",
      },
      {
        CITY: "LIPNO",
      },
      {
        CITY: "LIPOWA",
      },
      {
        CITY: "LIPSKO",
      },
      {
        CITY: "LIPUSZ",
      },
      {
        CITY: "LISEWO",
      },
      {
        CITY: "LISKOW",
      },
      {
        CITY: "LISZKI",
      },
      {
        CITY: "LIW",
      },
      {
        CITY: "LOBEZ",
      },
      {
        CITY: "LOBZENICA",
      },
      {
        CITY: "LOCHOW",
      },
      {
        CITY: "LOCHOWO",
      },
      {
        CITY: "LODYGOWICE",
      },
      {
        CITY: "LOMIANKI",
      },
      {
        CITY: "LOMIANKI DOLNE",
      },
      {
        CITY: "LOMNICA",
      },
      {
        CITY: "LOTYN",
      },
      {
        CITY: "LOWICZ",
      },
      {
        CITY: "LUBACZOW",
      },
      {
        CITY: "LUBAN",
      },
      {
        CITY: "LUBANIE",
      },
      {
        CITY: "LUBARTOW",
      },
      {
        CITY: "LUBASZOWA",
      },
      {
        CITY: "LUBAWA",
      },
      {
        CITY: "LUBAWKA",
      },
      {
        CITY: "LUBENIA",
      },
      {
        CITY: "LUBICHOWO",
      },
      {
        CITY: "LUBICZ",
      },
      {
        CITY: "LUBIEN",
      },
      {
        CITY: "LUBIN",
      },
      {
        CITY: "LUBLEWO",
      },
      {
        CITY: "LUBLIN",
      },
      {
        CITY: "LUBLINIEC",
      },
      {
        CITY: "LUBNICE",
      },
      {
        CITY: "LUBOCHNIA",
      },
      {
        CITY: "LUBOMIA",
      },
      {
        CITY: "LUBOMIERZ",
      },
      {
        CITY: "LUBON",
      },
      {
        CITY: "LUBORZYCA",
      },
      {
        CITY: "LUBRANIEC",
      },
      {
        CITY: "LUBSKO",
      },
      {
        CITY: "LUBSZA",
      },
      {
        CITY: "LUBYCZA KROLEWSKA",
      },
      {
        CITY: "LUKOW",
      },
      {
        CITY: "LULIN",
      },
      {
        CITY: "LUSOWKO",
      },
      {
        CITY: "LUTYNIA",
      },
      {
        CITY: "LUZINO",
      },
      {
        CITY: "LYSOMICE",
      },
      {
        CITY: "MACKOWICE",
      },
      {
        CITY: "MAGNUSZEW",
      },
      {
        CITY: "MAJDAN KROLEWSKI",
      },
      {
        CITY: "MAJEWO",
      },
      {
        CITY: "MAKOW",
      },
      {
        CITY: "MAKOW MAZOWIECKI",
      },
      {
        CITY: "MAKOW PODHALANSKI",
      },
      {
        CITY: "MAKOWIEC",
      },
      {
        CITY: "MAKSYMILIANOWO",
      },
      {
        CITY: "MALBORK",
      },
      {
        CITY: "MALCZYCE",
      },
      {
        CITY: "MALOGOSZCZ",
      },
      {
        CITY: "MANOWO",
      },
      {
        CITY: "MARCINOWICE",
      },
      {
        CITY: "MAREK",
      },
      {
        CITY: "MARGONIN",
      },
      {
        CITY: "MARIA",
      },
      {
        CITY: "MARKI",
      },
      {
        CITY: "MARKLOWICE",
      },
      {
        CITY: "MARTA",
      },
      {
        CITY: "MARY",
      },
      {
        CITY: "MARYLKA",
      },
      {
        CITY: "MASLICE",
      },
      {
        CITY: "MASLOW",
      },
      {
        CITY: "MASZEWO",
      },
      {
        CITY: "MAZANCOWICE",
      },
      {
        CITY: "MECHELINKI",
      },
      {
        CITY: "MEDYKA",
      },
      {
        CITY: "MEDYNIA GLOGOWSKA",
      },
      {
        CITY: "MELNO",
      },
      {
        CITY: "MESZNA",
      },
      {
        CITY: "MIALY",
      },
      {
        CITY: "MIASTECZKO SLASKIE",
      },
      {
        CITY: "MIASTKO",
      },
      {
        CITY: "MICHALOWICE",
      },
      {
        CITY: "MIECHOW",
      },
      {
        CITY: "MIECZEWO",
      },
      {
        CITY: "MIEDZNA",
      },
      {
        CITY: "MIEDZYBOROW",
      },
      {
        CITY: "MIEDZYBORZ",
      },
      {
        CITY: "MIEDZYBRODZIE ZYWIECKIE",
      },
      {
        CITY: "MIEDZYLESIE",
      },
      {
        CITY: "MIEDZYZDROJE",
      },
      {
        CITY: "MIEJSKA GORKA",
      },
      {
        CITY: "MIELEC",
      },
      {
        CITY: "MIELNO",
      },
      {
        CITY: "MIEROSZOW",
      },
      {
        CITY: "MIERZECICE",
      },
      {
        CITY: "MIERZESZYN",
      },
      {
        CITY: "MIKOLAJKI",
      },
      {
        CITY: "MIKOSZEWO",
      },
      {
        CITY: "MIKSTAT",
      },
      {
        CITY: "MILANOW",
      },
      {
        CITY: "MILCZA",
      },
      {
        CITY: "MILEJOW",
      },
      {
        CITY: "MILICZ",
      },
      {
        CITY: "MILKOWICE",
      },
      {
        CITY: "MILOBADZ",
      },
      {
        CITY: "MILORADZ",
      },
      {
        CITY: "MILOWKA",
      },
      {
        CITY: "MINOGA",
      },
      {
        CITY: "MIRKOW",
      },
      {
        CITY: "MIROSLAW",
      },
      {
        CITY: "MIROSLAWIEC",
      },
      {
        CITY: "MIRSK",
      },
      {
        CITY: "MISZKOWICE",
      },
      {
        CITY: "MNIOW",
      },
      {
        CITY: "MODLNICA",
      },
      {
        CITY: "MODLNICZKA",
      },
      {
        CITY: "MODRZE",
      },
      {
        CITY: "MOGILANY",
      },
      {
        CITY: "MOGILNO",
      },
      {
        CITY: "MOKRSKO",
      },
      {
        CITY: "MORAWICA",
      },
      {
        CITY: "MORYN",
      },
      {
        CITY: "MOSINA",
      },
      {
        CITY: "MOSTY",
      },
      {
        CITY: "MOSZCZANKA",
      },
      {
        CITY: "MRAGOWO",
      },
      {
        CITY: "MROCZA",
      },
      {
        CITY: "MROWINO",
      },
      {
        CITY: "MSCICE",
      },
      {
        CITY: "MSCIWOJOW",
      },
      {
        CITY: "MSZANA",
      },
      {
        CITY: "MSZANA DOLNA",
      },
      {
        CITY: "MUCHARZ",
      },
      {
        CITY: "MUROWANA GOSLINA",
      },
      {
        CITY: "MUSZYNA",
      },
      {
        CITY: "MYSLACHOWICE",
      },
      {
        CITY: "MYSLENICE",
      },
      {
        CITY: "MYSLIBORZ",
      },
      {
        CITY: "MYSZKOW",
      },
      {
        CITY: "MYSZYNIEC",
      },
      {
        CITY: "NACPOLSK",
      },
      {
        CITY: "NADARZYN",
      },
      {
        CITY: "NAKLO",
      },
      {
        CITY: "NALECZOW",
      },
      {
        CITY: "NAMYSLOW",
      },
      {
        CITY: "NAPRAWA",
      },
      {
        CITY: "NAREW",
      },
      {
        CITY: "NARZYM",
      },
      {
        CITY: "NASIELSK",
      },
      {
        CITY: "NAWODNA",
      },
      {
        CITY: "NEBROWO WIELKIE",
      },
      {
        CITY: "NIDZICA",
      },
      {
        CITY: "NIEBOROWICE",
      },
      {
        CITY: "NIECHANOWO",
      },
      {
        CITY: "NIEDOMICE",
      },
      {
        CITY: "NIEDRZWICA DUZA",
      },
      {
        CITY: "NIEGOSLAWICE",
      },
      {
        CITY: "NIELISZ",
      },
      {
        CITY: "NIEMCZ",
      },
      {
        CITY: "NIEMCZA",
      },
      {
        CITY: "NIEMODLIN",
      },
      {
        CITY: "NIENADOWKA",
      },
      {
        CITY: "NIEPOLOMICE",
      },
      {
        CITY: "NIEWIERZ",
      },
      {
        CITY: "NISKO",
      },
      {
        CITY: "NIWICA",
      },
      {
        CITY: "NOWA",
      },
      {
        CITY: "NOWA BIALA",
      },
      {
        CITY: "NOWA DEBA",
      },
      {
        CITY: "NOWA HUTA",
      },
      {
        CITY: "NOWA RUDA",
      },
      {
        CITY: "NOWA SLUPIA",
      },
      {
        CITY: "NOWA SOL",
      },
      {
        CITY: "NOWA WIES ELCKA",
      },
      {
        CITY: "NOWE CHECHLO",
      },
      {
        CITY: "NOWE LIGNOWY",
      },
      {
        CITY: "NOWE MIASTO LUBAWSKIE",
      },
      {
        CITY: "NOWE MIASTO NAD PILICA",
      },
      {
        CITY: "NOWE MIASTO NAD WARTA",
      },
      {
        CITY: "NOWE SKALMIERZYCE",
      },
      {
        CITY: "NOWO-ALEKSANDROWO",
      },
      {
        CITY: "NOWOGARD",
      },
      {
        CITY: "NOWOGRODZIEC",
      },
      {
        CITY: "NOWY DWOR",
      },
      {
        CITY: "NOWY DWOR GDANSKI",
      },
      {
        CITY: "NOWY DWOR MAZOWIECKI",
      },
      {
        CITY: "NOWY KORCZYN",
      },
      {
        CITY: "NOWY STAW",
      },
      {
        CITY: "NOWY SWIETOW",
      },
      {
        CITY: "NOWY TARG",
      },
      {
        CITY: "NOWY TOMYSL",
      },
      {
        CITY: "NOWY WISNICZ",
      },
      {
        CITY: "NYSA",
      },
      {
        CITY: "OBLACZKOWO",
      },
      {
        CITY: "OBORNIKI",
      },
      {
        CITY: "OBRZYCKO",
      },
      {
        CITY: "OBSZA",
      },
      {
        CITY: "ODOLANOW",
      },
      {
        CITY: "ODOLIN",
      },
      {
        CITY: "ODRZYKON",
      },
      {
        CITY: "OGRODY",
      },
      {
        CITY: "OGRODZIENIEC",
      },
      {
        CITY: "OJRZEN",
      },
      {
        CITY: "OKSYWIE",
      },
      {
        CITY: "OLAWA",
      },
      {
        CITY: "OLECKO",
      },
      {
        CITY: "OLEJNICA",
      },
      {
        CITY: "OLESNICA",
      },
      {
        CITY: "OLESNO",
      },
      {
        CITY: "OLESZNO",
      },
      {
        CITY: "OLIMPIA",
      },
      {
        CITY: "OLKUSZ",
      },
      {
        CITY: "OLSZANY",
      },
      {
        CITY: "OLSZOWICE",
      },
      {
        CITY: "OLSZTYN",
      },
      {
        CITY: "OLSZTYNEK",
      },
      {
        CITY: "OLSZYNA",
      },
      {
        CITY: "OLTARZEW",
      },
      {
        CITY: "OPALENICA",
      },
      {
        CITY: "OPATOW",
      },
      {
        CITY: "OPATOWEK",
      },
      {
        CITY: "OPOCZNO",
      },
      {
        CITY: "OPOLE",
      },
      {
        CITY: "ORCHOWO",
      },
      {
        CITY: "ORNETA",
      },
      {
        CITY: "ORNONTOWICE",
      },
      {
        CITY: "ORZEL",
      },
      {
        CITY: "ORZESZE",
      },
      {
        CITY: "ORZYSZ",
      },
      {
        CITY: "OSIECK",
      },
      {
        CITY: "OSIECZNA",
      },
      {
        CITY: "OSIEK",
      },
      {
        CITY: "OSIEK JASIELSKI",
      },
      {
        CITY: "OSIELSKO",
      },
      {
        CITY: "OSNO",
      },
      {
        CITY: "OSNO LUBUSKIE",
      },
      {
        CITY: "OSTASZEWO",
      },
      {
        CITY: "OSTROG",
      },
      {
        CITY: "OSTROSZOWICE",
      },
      {
        CITY: "OSTROW",
      },
      {
        CITY: "OSTROW LUBELSKI",
      },
      {
        CITY: "OSTROW MAZOWIECKA",
      },
      {
        CITY: "OSTROWEK",
      },
      {
        CITY: "OSTROWITE",
      },
      {
        CITY: "OSTROWY",
      },
      {
        CITY: "OSTRZESZOW",
      },
      {
        CITY: "OTOKI",
      },
      {
        CITY: "OTOMIN",
      },
      {
        CITY: "OTREBUSY",
      },
      {
        CITY: "OTWOCK",
      },
      {
        CITY: "OTYN",
      },
      {
        CITY: "OWINSKA",
      },
      {
        CITY: "OZAROW MAZOWIECKI",
      },
      {
        CITY: "OZIMEK",
      },
      {
        CITY: "OZORKOW",
      },
      {
        CITY: "PABIANICE",
      },
      {
        CITY: "PACANOW",
      },
      {
        CITY: "PAJECZNO",
      },
      {
        CITY: "PALEDZIE",
      },
      {
        CITY: "PALISZEWO",
      },
      {
        CITY: "PANIOWKI",
      },
      {
        CITY: "PAPOWO",
      },
      {
        CITY: "PARCZEW",
      },
      {
        CITY: "PASTUCHOW",
      },
      {
        CITY: "PAULINA",
      },
      {
        CITY: "PAWLOWICE",
      },
      {
        CITY: "PCIM",
      },
      {
        CITY: "PECZNIEW",
      },
      {
        CITY: "PEDZIWIATRY",
      },
      {
        CITY: "PEGOW",
      },
      {
        CITY: "PELPLIN",
      },
      {
        CITY: "PEPOWO",
      },
      {
        CITY: "PEWEL MALA",
      },
      {
        CITY: "PIASECZNO",
      },
      {
        CITY: "PIASEK",
      },
      {
        CITY: "PIASKI",
      },
      {
        CITY: "PIASTOW",
      },
      {
        CITY: "PIECHOWICE",
      },
      {
        CITY: "PIEKARY",
      },
      {
        CITY: "PIEKARY SLASKIE",
      },
      {
        CITY: "PIEKIELNIK",
      },
      {
        CITY: "PIEKOSZOW",
      },
      {
        CITY: "PIELGRZYMKA",
      },
      {
        CITY: "PIELICE",
      },
      {
        CITY: "PIENSK",
      },
      {
        CITY: "PIERWOSZYNO",
      },
      {
        CITY: "PIESZKOW",
      },
      {
        CITY: "PIESZYCE",
      },
      {
        CITY: "PIETROWICE WIELKIE",
      },
      {
        CITY: "PIETRZYKOWICE",
      },
      {
        CITY: "PILA",
      },
      {
        CITY: "PILA KOSCIELECKA",
      },
      {
        CITY: "PILAWA",
      },
      {
        CITY: "PILAWA GORNA",
      },
      {
        CITY: "PILCHOWICE",
      },
      {
        CITY: "PILICA",
      },
      {
        CITY: "PINCZOW",
      },
      {
        CITY: "PIONKI",
      },
      {
        CITY: "PISARZOWICE",
      },
      {
        CITY: "PISZ",
      },
      {
        CITY: "PLESNA",
      },
      {
        CITY: "PLESZEW",
      },
      {
        CITY: "PLEWISKA",
      },
      {
        CITY: "PLOCHOCIN",
      },
      {
        CITY: "PNIEWY",
      },
      {
        CITY: "POBIEDNA",
      },
      {
        CITY: "POBIEDZISKA",
      },
      {
        CITY: "POCZESNA",
      },
      {
        CITY: "PODEGRODZIE",
      },
      {
        CITY: "PODGORNE",
      },
      {
        CITY: "PODGORZE",
      },
      {
        CITY: "PODLASIE",
      },
      {
        CITY: "PODLEZE",
      },
      {
        CITY: "POGODKI",
      },
      {
        CITY: "POGORZELA",
      },
      {
        CITY: "POGWIZDOW",
      },
      {
        CITY: "POKRZYWNICA",
      },
      {
        CITY: "POLAJEWO",
      },
      {
        CITY: "POLANKA WIELKA",
      },
      {
        CITY: "POLCZYN-ZDROJ",
      },
      {
        CITY: "POLICE",
      },
      {
        CITY: "POLKOWICE",
      },
      {
        CITY: "POLOMIA",
      },
      {
        CITY: "POLSKIE",
      },
      {
        CITY: "POMIECHOWEK",
      },
      {
        CITY: "POMORSKIE",
      },
      {
        CITY: "POPOW",
      },
      {
        CITY: "POPOWICE",
      },
      {
        CITY: "PORAJ",
      },
      {
        CITY: "POREBA",
      },
      {
        CITY: "POSWIETNE",
      },
      {
        CITY: "POZNAN",
      },
      {
        CITY: "PRABUTY",
      },
      {
        CITY: "PRACA",
      },
      {
        CITY: "PRASZKA",
      },
      {
        CITY: "PRAWIEDNIKI",
      },
      {
        CITY: "PROCHOWICE",
      },
      {
        CITY: "PROKOCIM",
      },
      {
        CITY: "PROSNA",
      },
      {
        CITY: "PROSZOWICE",
      },
      {
        CITY: "PRUCHNA",
      },
      {
        CITY: "PRUDNIK",
      },
      {
        CITY: "PRUSICE",
      },
      {
        CITY: "PRUSZCZ GDANSKI",
      },
      {
        CITY: "PRUSZCZ POMORSKI",
      },
      {
        CITY: "PRZASNYSZ",
      },
      {
        CITY: "PRZECHLEWO",
      },
      {
        CITY: "PRZECLAW",
      },
      {
        CITY: "PRZEMET",
      },
      {
        CITY: "PRZEMYSL",
      },
      {
        CITY: "PRZEMYSLAW",
      },
      {
        CITY: "PRZEWORSK",
      },
      {
        CITY: "PRZEZMIEROWO",
      },
      {
        CITY: "PRZYGODZICE",
      },
      {
        CITY: "PRZYLEP",
      },
      {
        CITY: "PRZYROW",
      },
      {
        CITY: "PRZYSIEK",
      },
      {
        CITY: "PRZYSTAJN",
      },
      {
        CITY: "PRZYSZOWICE",
      },
      {
        CITY: "PSZCZEW",
      },
      {
        CITY: "PSZCZYNA",
      },
      {
        CITY: "PSZOW",
      },
      {
        CITY: "PUCK",
      },
      {
        CITY: "PUSTKOW",
      },
      {
        CITY: "PUSZCZEW",
      },
      {
        CITY: "PUSZCZYKOWO",
      },
      {
        CITY: "PYRZYCE",
      },
      {
        CITY: "PYSKOWICE",
      },
      {
        CITY: "PYSZNICA",
      },
      {
        CITY: "RABIEN",
      },
      {
        CITY: "RABKA-ZDROJ",
      },
      {
        CITY: "RACIAZ",
      },
      {
        CITY: "RACIAZEK",
      },
      {
        CITY: "RACULA",
      },
      {
        CITY: "RACZKA",
      },
      {
        CITY: "RACZKI",
      },
      {
        CITY: "RADGOSZCZ",
      },
      {
        CITY: "RADLIN",
      },
      {
        CITY: "RADLOW",
      },
      {
        CITY: "RADOM",
      },
      {
        CITY: "RADOMIN",
      },
      {
        CITY: "RADOMSKO",
      },
      {
        CITY: "RADOMYSL WIELKI",
      },
      {
        CITY: "RADOSLAW",
      },
      {
        CITY: "RADOSTOWICE",
      },
      {
        CITY: "RADOSZYCE",
      },
      {
        CITY: "RADYMNO",
      },
      {
        CITY: "RADZANOW",
      },
      {
        CITY: "RADZANOWO",
      },
      {
        CITY: "RADZIECHOWY",
      },
      {
        CITY: "RADZIEJOWICE",
      },
      {
        CITY: "RADZIONKOW",
      },
      {
        CITY: "RADZYMIN",
      },
      {
        CITY: "RADZYN PODLASKI",
      },
      {
        CITY: "RAJSZEW",
      },
      {
        CITY: "RAKOW",
      },
      {
        CITY: "RAKOWIEC",
      },
      {
        CITY: "RAKSZAWA",
      },
      {
        CITY: "RANIZOW",
      },
      {
        CITY: "RASZOWA",
      },
      {
        CITY: "RASZOWKA",
      },
      {
        CITY: "RASZYN",
      },
      {
        CITY: "RAWA MAZOWIECKA",
      },
      {
        CITY: "RAWICZ",
      },
      {
        CITY: "REBKOW",
      },
      {
        CITY: "REBOWO",
      },
      {
        CITY: "RECZNO",
      },
      {
        CITY: "REDA",
      },
      {
        CITY: "REGIMIN",
      },
      {
        CITY: "REJOWIEC",
      },
      {
        CITY: "RESZEL",
      },
      {
        CITY: "REWA",
      },
      {
        CITY: "REWAL",
      },
      {
        CITY: "ROCZYNY",
      },
      {
        CITY: "ROGALINEK",
      },
      {
        CITY: "ROGOW",
      },
      {
        CITY: "ROGOWO",
      },
      {
        CITY: "ROGOZNIK",
      },
      {
        CITY: "ROGOZNO",
      },
      {
        CITY: "ROKIETNICA",
      },
      {
        CITY: "ROKITKI",
      },
      {
        CITY: "ROKITNICA",
      },
      {
        CITY: "ROPCZYCE",
      },
      {
        CITY: "ROSNOWKO",
      },
      {
        CITY: "ROSTARZEWO",
      },
      {
        CITY: "ROZANKA",
      },
      {
        CITY: "ROZGARTY",
      },
      {
        CITY: "ROZPRZA",
      },
      {
        CITY: "ROZTOKA",
      },
      {
        CITY: "RUDA",
      },
      {
        CITY: "RUDKA",
      },
      {
        CITY: "RUDNA MALA",
      },
      {
        CITY: "RUDNIK NAD SANEM",
      },
      {
        CITY: "RUDY",
      },
      {
        CITY: "RUDZICA",
      },
      {
        CITY: "RUDZINIEC",
      },
      {
        CITY: "RUMIA",
      },
      {
        CITY: "RUMIANEK",
      },
      {
        CITY: "RUSIEC",
      },
      {
        CITY: "RYBNA",
      },
      {
        CITY: "RYBNIK",
      },
      {
        CITY: "RYBNO",
      },
      {
        CITY: "RYCHWAL",
      },
      {
        CITY: "RYDZYNA",
      },
      {
        CITY: "RYGLICE",
      },
      {
        CITY: "RYKI",
      },
      {
        CITY: "RYMAN",
      },
      {
        CITY: "RYMANOW",
      },
      {
        CITY: "RYN",
      },
      {
        CITY: "RYPIN",
      },
      {
        CITY: "RZASNIA",
      },
      {
        CITY: "RZECZYCE",
      },
      {
        CITY: "RZEMIEN",
      },
      {
        CITY: "RZEPIN",
      },
      {
        CITY: "RZESZOTARY",
      },
      {
        CITY: "RZEWNIE",
      },
      {
        CITY: "RZGOW PIERWSZY",
      },
      {
        CITY: "RZUCHOWA",
      },
      {
        CITY: "SADLINKI",
      },
      {
        CITY: "SADLNO",
      },
      {
        CITY: "SADY",
      },
      {
        CITY: "SAMIN",
      },
      {
        CITY: "SANDOMIERZ",
      },
      {
        CITY: "SANNIKI",
      },
      {
        CITY: "SANOK",
      },
      {
        CITY: "SANTOK",
      },
      {
        CITY: "SARBINOWO",
      },
      {
        CITY: "SCINAWA MALA",
      },
      {
        CITY: "SCINAWA NYSKA",
      },
      {
        CITY: "SEDZISZOW",
      },
      {
        CITY: "SEDZISZOW MALOPOLSKI",
      },
      {
        CITY: "SEPOLNO KRAJENSKIE",
      },
      {
        CITY: "SEROCK",
      },
      {
        CITY: "SERWIS",
      },
      {
        CITY: "SIANOW",
      },
      {
        CITY: "SIDRA",
      },
      {
        CITY: "SIDZINA",
      },
      {
        CITY: "SIECHNICE",
      },
      {
        CITY: "SIEDLCE",
      },
      {
        CITY: "SIEDLEC",
      },
      {
        CITY: "SIEKIERCZYN",
      },
      {
        CITY: "SIEMIATYCZE",
      },
      {
        CITY: "SIEMIROWICE",
      },
      {
        CITY: "SIEMKOWICE",
      },
      {
        CITY: "SIENIAWA",
      },
      {
        CITY: "SIENIAWA ZARSKA",
      },
      {
        CITY: "SIENIAWKA",
      },
      {
        CITY: "SIENNICA",
      },
      {
        CITY: "SIENNICA NADOLNA",
      },
      {
        CITY: "SIERADZ",
      },
      {
        CITY: "SIEROSZOWICE",
      },
      {
        CITY: "SIERPC",
      },
      {
        CITY: "SIEWIERZ",
      },
      {
        CITY: "SIWEK",
      },
      {
        CITY: "SKALA",
      },
      {
        CITY: "SKARSZEWY",
      },
      {
        CITY: "SKARZYSKO-KAMIENNA",
      },
      {
        CITY: "SKAWINA",
      },
      {
        CITY: "SKIERDY",
      },
      {
        CITY: "SKIERNIEWICE",
      },
      {
        CITY: "SKOCZOW",
      },
      {
        CITY: "SKOMLIN",
      },
      {
        CITY: "SKOPANIE",
      },
      {
        CITY: "SKORCZ",
      },
      {
        CITY: "SKORZEC",
      },
      {
        CITY: "SKORZEWO",
      },
      {
        CITY: "SKRBENSKO",
      },
      {
        CITY: "SKRZETUSZ",
      },
      {
        CITY: "SKRZYSZOW",
      },
      {
        CITY: "SKWIERZYNA",
      },
      {
        CITY: "SLAWKOW",
      },
      {
        CITY: "SLAWNO B",
      },
      {
        CITY: "SLAWOSZYNO",
      },
      {
        CITY: "SLEMIEN",
      },
      {
        CITY: "SLOMNIKI",
      },
      {
        CITY: "SLONE",
      },
      {
        CITY: "SLUPCA",
      },
      {
        CITY: "SLUPNO",
      },
      {
        CITY: "SMARDZEW",
      },
      {
        CITY: "SMARDZOWICE",
      },
      {
        CITY: "SMIGIEL",
      },
      {
        CITY: "SMOLEC",
      },
      {
        CITY: "SMOLNICA",
      },
      {
        CITY: "SMOLNIK",
      },
      {
        CITY: "SOBIENIE JEZIORY",
      },
      {
        CITY: "SOBOTKA",
      },
      {
        CITY: "SOBOWIDZ",
      },
      {
        CITY: "SOCHACZEW",
      },
      {
        CITY: "SOCHOCIN",
      },
      {
        CITY: "SOCZEWKA",
      },
      {
        CITY: "SOKOLOW MALOPOLSKI",
      },
      {
        CITY: "SOKOLOWSKO",
      },
      {
        CITY: "SOLEC KUJAWSKI",
      },
      {
        CITY: "SOMPOLNO",
      },
      {
        CITY: "SOPOT",
      },
      {
        CITY: "SOSNIE",
      },
      {
        CITY: "SOSNOWIEC",
      },
      {
        CITY: "SRODA WIELKOPOLSKA",
      },
      {
        CITY: "STADLA",
      },
      {
        CITY: "STALOWA WOLA",
      },
      {
        CITY: "STANIATKI",
      },
      {
        CITY: "STANISZOW",
      },
      {
        CITY: "STANKOWO",
      },
      {
        CITY: "STANOWICE",
      },
      {
        CITY: "STARA",
      },
      {
        CITY: "STARA KAMIENICA",
      },
      {
        CITY: "STARA LUBIANKA",
      },
      {
        CITY: "STARACHOWICE",
      },
      {
        CITY: "STARE BABICE",
      },
      {
        CITY: "STARE BOGACZOWICE",
      },
      {
        CITY: "STARE CZARNOWO",
      },
      {
        CITY: "STARE JUCHY",
      },
      {
        CITY: "STARE KUROWO",
      },
      {
        CITY: "STARE MIASTO",
      },
      {
        CITY: "STARY BRONISZEW",
      },
      {
        CITY: "STARY GARBOW",
      },
      {
        CITY: "STARY SACZ",
      },
      {
        CITY: "STARY ZAMOSC",
      },
      {
        CITY: "STASZOW",
      },
      {
        CITY: "STAW",
      },
      {
        CITY: "STAWIANY",
      },
      {
        CITY: "STAWIGUDA",
      },
      {
        CITY: "STEGNA",
      },
      {
        CITY: "STESZEW",
      },
      {
        CITY: "STOCZEK",
      },
      {
        CITY: "STOLNO",
      },
      {
        CITY: "STOPNICA",
      },
      {
        CITY: "STRASZYN",
      },
      {
        CITY: "STRAWCZYN",
      },
      {
        CITY: "STRAZOW",
      },
      {
        CITY: "STRONIE SLASKIE",
      },
      {
        CITY: "STROZE",
      },
      {
        CITY: "STRUMIEN",
      },
      {
        CITY: "STRYKOW",
      },
      {
        CITY: "STRYSZAWA",
      },
      {
        CITY: "STRYSZOW",
      },
      {
        CITY: "STRZALKOWO",
      },
      {
        CITY: "STRZEBIELINO",
      },
      {
        CITY: "STRZEBIN",
      },
      {
        CITY: "STRZEGOM",
      },
      {
        CITY: "STRZELCE KRAJENSKIE",
      },
      {
        CITY: "STRZELCE OPOLSKIE",
      },
      {
        CITY: "STRZELECZKI",
      },
      {
        CITY: "STRZELNO",
      },
      {
        CITY: "STRZESZOW",
      },
      {
        CITY: "STRZYZE",
      },
      {
        CITY: "STRZYZOW",
      },
      {
        CITY: "STUDZIENIEC",
      },
      {
        CITY: "SUBKOWY",
      },
      {
        CITY: "SUCHA BESKIDZKA",
      },
      {
        CITY: "SUCHEDNIOW",
      },
      {
        CITY: "SUCHOWOLA",
      },
      {
        CITY: "SUCHY DAB",
      },
      {
        CITY: "SUCHY LAS",
      },
      {
        CITY: "SULECHOW",
      },
      {
        CITY: "SULEJOW",
      },
      {
        CITY: "SULEJOWEK",
      },
      {
        CITY: "SULISTROWICE",
      },
      {
        CITY: "SULMIERZYCE",
      },
      {
        CITY: "SULMIN",
      },
      {
        CITY: "SULOSZOWA",
      },
      {
        CITY: "SUPIENIE",
      },
      {
        CITY: "SUSIEC",
      },
      {
        CITY: "SUSZ",
      },
      {
        CITY: "SUSZEC",
      },
      {
        CITY: "SUWAKI",
      },
      {
        CITY: "SWARORZYN",
      },
      {
        CITY: "SWARZEDZ",
      },
      {
        CITY: "SWARZEWO",
      },
      {
        CITY: "SWIATKI",
      },
      {
        CITY: "SWIDNICA",
      },
      {
        CITY: "SWIDNIK",
      },
      {
        CITY: "SWIDWIN",
      },
      {
        CITY: "SWIEBODZICE",
      },
      {
        CITY: "SWIEBODZIN",
      },
      {
        CITY: "SWIECIE NAD OSA",
      },
      {
        CITY: "SWIEKATOWO",
      },
      {
        CITY: "SWIERK",
      },
      {
        CITY: "SWIERKLANY",
      },
      {
        CITY: "SWIETA KATARZYNA",
      },
      {
        CITY: "SWIETNO",
      },
      {
        CITY: "SWIETONIOWA",
      },
      {
        CITY: "SWINIARY",
      },
      {
        CITY: "SWOBODA",
      },
      {
        CITY: "SYCEWICE",
      },
      {
        CITY: "SYCOW",
      },
      {
        CITY: "SYRYNIA",
      },
      {
        CITY: "SZADEK",
      },
      {
        CITY: "SZADLOWICE",
      },
      {
        CITY: "SZCZANIEC",
      },
      {
        CITY: "SZCZAWNO",
      },
      {
        CITY: "SZCZAWNO-ZDROJ",
      },
      {
        CITY: "SZCZECIN",
      },
      {
        CITY: "SZCZECINEK",
      },
      {
        CITY: "SZCZEJKOWICE",
      },
      {
        CITY: "SZCZEPANOW",
      },
      {
        CITY: "SZCZERCOW",
      },
      {
        CITY: "SZCZUCIN",
      },
      {
        CITY: "SZCZUCZYN",
      },
      {
        CITY: "SZCZUROWA",
      },
      {
        CITY: "SZCZYRK",
      },
      {
        CITY: "SZCZYTNIKI",
      },
      {
        CITY: "SZCZYTNO",
      },
      {
        CITY: "SZEBNIE",
      },
      {
        CITY: "SZEMUD",
      },
      {
        CITY: "SZEPIETOWO",
      },
      {
        CITY: "SZEWCE",
      },
      {
        CITY: "SZLACHTA",
      },
      {
        CITY: "SZOSTKA",
      },
      {
        CITY: "SZOWSKO",
      },
      {
        CITY: "SZPROTAWA",
      },
      {
        CITY: "SZRENIAWA",
      },
      {
        CITY: "SZTUM",
      },
      {
        CITY: "SZTUTOWO",
      },
      {
        CITY: "SZYCE",
      },
      {
        CITY: "SZYDLOWIEC",
      },
      {
        CITY: "SZYDLOWO",
      },
      {
        CITY: "SZYLDAK",
      },
      {
        CITY: "SZYMANOW",
      },
      {
        CITY: "SZYPLISZKI",
      },
      {
        CITY: "TANOWO",
      },
      {
        CITY: "TARCHALY WIELKIE",
      },
      {
        CITY: "TARCZYN",
      },
      {
        CITY: "TARGANICE",
      },
      {
        CITY: "TARNOBRZEG",
      },
      {
        CITY: "TARNOGROD",
      },
      {
        CITY: "TARNOW OPOLSKI",
      },
      {
        CITY: "TARNOWIEC",
      },
      {
        CITY: "TARNOWO PODGORNE",
      },
      {
        CITY: "TARNOWSKIE GORY",
      },
      {
        CITY: "TCZEW",
      },
      {
        CITY: "TECZA",
      },
      {
        CITY: "TEGOBORZE",
      },
      {
        CITY: "TENCZYN",
      },
      {
        CITY: "TEOFILOW",
      },
      {
        CITY: "TERESIN",
      },
      {
        CITY: "TERESPOL",
      },
      {
        CITY: "TLUCZAN",
      },
      {
        CITY: "TOLKMICKO",
      },
      {
        CITY: "TOMASZOW",
      },
      {
        CITY: "TOMASZOW LUBELSKI",
      },
      {
        CITY: "TOMASZOWICE",
      },
      {
        CITY: "TOPOLA MALA",
      },
      {
        CITY: "TORZYM",
      },
      {
        CITY: "TOSZEK",
      },
      {
        CITY: "TOWARZYSTWO",
      },
      {
        CITY: "TRABKI",
      },
      {
        CITY: "TRAWNIKI",
      },
      {
        CITY: "TROJANOW",
      },
      {
        CITY: "TRZCIANA",
      },
      {
        CITY: "TRZCIANKA",
      },
      {
        CITY: "TRZCIEL",
      },
      {
        CITY: "TRZCINSKO",
      },
      {
        CITY: "TRZEBINIA",
      },
      {
        CITY: "TRZEBNICA",
      },
      {
        CITY: "TRZEBOWNISKO",
      },
      {
        CITY: "TRZEBUNIA",
      },
      {
        CITY: "TRZEMESNIA",
      },
      {
        CITY: "TRZEMESZNO",
      },
      {
        CITY: "TRZEMESZNO LUBUSKIE",
      },
      {
        CITY: "TRZESNIOW",
      },
      {
        CITY: "TRZESZCZYN",
      },
      {
        CITY: "TRZYCIAZ",
      },
      {
        CITY: "TRZYDNIK DUZY",
      },
      {
        CITY: "TUCHOLA",
      },
      {
        CITY: "TUCHOW",
      },
      {
        CITY: "TUCZNO",
      },
      {
        CITY: "TULCE",
      },
      {
        CITY: "TURBIA",
      },
      {
        CITY: "TUREK",
      },
      {
        CITY: "TUROBIN",
      },
      {
        CITY: "TUROWIEC",
      },
      {
        CITY: "TUSZYN",
      },
      {
        CITY: "TWARDAWA",
      },
      {
        CITY: "TWARDOGORA",
      },
      {
        CITY: "TWOROG",
      },
      {
        CITY: "TYCHY",
      },
      {
        CITY: "TYCZYN",
      },
      {
        CITY: "TYKOCIN",
      },
      {
        CITY: "TYLICZ",
      },
      {
        CITY: "TYLMANOWA",
      },
      {
        CITY: "TYMBARK",
      },
      {
        CITY: "TYMOWA",
      },
      {
        CITY: "TYSZOWCE",
      },
      {
        CITY: "UCIECHOW",
      },
      {
        CITY: "UJAZD",
      },
      {
        CITY: "UJSCIE",
      },
      {
        CITY: "ULEZ",
      },
      {
        CITY: "UNIESZEWO",
      },
      {
        CITY: "USTANOW",
      },
      {
        CITY: "USTKA",
      },
      {
        CITY: "USTRON",
      },
      {
        CITY: "USTRZYKI DOLNE",
      },
      {
        CITY: "WABRZEZNO",
      },
      {
        CITY: "WACHOCK",
      },
      {
        CITY: "WADOWICE",
      },
      {
        CITY: "WADOWICE GORNE",
      },
      {
        CITY: "WAGANIEC",
      },
      {
        CITY: "WAGROWIEC",
      },
      {
        CITY: "WALCZ",
      },
      {
        CITY: "WALIM",
      },
      {
        CITY: "WAMBIERZYCE",
      },
      {
        CITY: "WAPIELSK",
      },
      {
        CITY: "WARKA",
      },
      {
        CITY: "WARSAW",
      },
      {
        CITY: "WARZACHEWKA POLSKA",
      },
      {
        CITY: "WARZNO",
      },
      {
        CITY: "WARZYMICE",
      },
      {
        CITY: "WASILKOW",
      },
      {
        CITY: "WASNIOW",
      },
      {
        CITY: "WAWEL",
      },
      {
        CITY: "WAWOLNICA",
      },
      {
        CITY: "WEGIERSKA GORKA",
      },
      {
        CITY: "WEGLINIEC",
      },
      {
        CITY: "WEGLOWICE",
      },
      {
        CITY: "WEGRZCE WIELKIE",
      },
      {
        CITY: "WEJHEROWO",
      },
      {
        CITY: "WIAZOW",
      },
      {
        CITY: "WICKO",
      },
      {
        CITY: "WIECKOWICE",
      },
      {
        CITY: "WIELBARK",
      },
      {
        CITY: "WIELEN ZAOBRZANSKI",
      },
      {
        CITY: "WIELGIE",
      },
      {
        CITY: "WIELICHOWO",
      },
      {
        CITY: "WIELICZKA",
      },
      {
        CITY: "WIELISZEW",
      },
      {
        CITY: "WIELKI KLINCZ",
      },
      {
        CITY: "WIELOGLOWY",
      },
      {
        CITY: "WIELOPOLE SKRZYNSKIE",
      },
      {
        CITY: "WIELOWIES",
      },
      {
        CITY: "WIERUSZOW",
      },
      {
        CITY: "WIERZCHOWISKO",
      },
      {
        CITY: "WIESZOWA",
      },
      {
        CITY: "WIJEWO",
      },
      {
        CITY: "WILCZETA",
      },
      {
        CITY: "WILCZYCE",
      },
      {
        CITY: "WILCZYN",
      },
      {
        CITY: "WILGA",
      },
      {
        CITY: "WILKANOWO",
      },
      {
        CITY: "WILKOW",
      },
      {
        CITY: "WILKOWICE",
      },
      {
        CITY: "WILKOWISKO",
      },
      {
        CITY: "WINNICA",
      },
      {
        CITY: "WINSKO",
      },
      {
        CITY: "WIOREK",
      },
      {
        CITY: "WISLA",
      },
      {
        CITY: "WISLA WIELKA",
      },
      {
        CITY: "WISNIEW",
      },
      {
        CITY: "WISNIOWA",
      },
      {
        CITY: "WISZNICE",
      },
      {
        CITY: "WITASZYCE",
      },
      {
        CITY: "WITKOWO",
      },
      {
        CITY: "WITNICA",
      },
      {
        CITY: "WLODAWA",
      },
      {
        CITY: "WLODZIMIERZ",
      },
      {
        CITY: "WLOSZCZOWA",
      },
      {
        CITY: "WOJCIECH",
      },
      {
        CITY: "WOJKOWICE",
      },
      {
        CITY: "WOJKOWICE KOSCIELNE",
      },
      {
        CITY: "WOLA BARANOWSKA",
      },
      {
        CITY: "WOLA FILIPOWSKA",
      },
      {
        CITY: "WOLA KOPCOWA",
      },
      {
        CITY: "WOLA KRZYSZTOPORSKA",
      },
      {
        CITY: "WOLA RADLOWSKA",
      },
      {
        CITY: "WOLA RASZTOWSKA",
      },
      {
        CITY: "WOLA REBKOWSKA",
      },
      {
        CITY: "WOLA ZARADZYNSKA",
      },
      {
        CITY: "WOLBORZ",
      },
      {
        CITY: "WOLBROM",
      },
      {
        CITY: "WOLIN",
      },
      {
        CITY: "WOLKA",
      },
      {
        CITY: "WOLOW",
      },
      {
        CITY: "WOLSZTYN",
      },
      {
        CITY: "WOZNIKI",
      },
      {
        CITY: "WRECZYCA WIELKA",
      },
      {
        CITY: "WRONKI",
      },
      {
        CITY: "WRZESNIA",
      },
      {
        CITY: "WRZOSOWA",
      },
      {
        CITY: "WRZOSOWKA",
      },
      {
        CITY: "WSCHOWA",
      },
      {
        CITY: "WYLATOWO",
      },
      {
        CITY: "WYMIARKI",
      },
      {
        CITY: "WYRZYSK",
      },
      {
        CITY: "WYSOGOTOWO",
      },
      {
        CITY: "WYSOKA",
      },
      {
        CITY: "WYSOKA STRZYZOWSKA",
      },
      {
        CITY: "WYSOKIE MAZOWIECKIE",
      },
      {
        CITY: "WYSTEP",
      },
      {
        CITY: "WYSZOGROD",
      },
      {
        CITY: "ZABIA WOLA",
      },
      {
        CITY: "ZABIERZOW BOCHENSKI",
      },
      {
        CITY: "ZABLOCIE",
      },
      {
        CITY: "ZABNO",
      },
      {
        CITY: "ZABOR",
      },
      {
        CITY: "ZABROWO",
      },
      {
        CITY: "ZABRZE",
      },
      {
        CITY: "ZABRZEG",
      },
      {
        CITY: "ZACZERNIE",
      },
      {
        CITY: "ZAGNANSK",
      },
      {
        CITY: "ZAGOROW",
      },
      {
        CITY: "ZAGORZYCE",
      },
      {
        CITY: "ZAGOSCINIEC",
      },
      {
        CITY: "ZAGRODNO",
      },
      {
        CITY: "ZAKOPANE",
      },
      {
        CITY: "ZALAKOWO",
      },
      {
        CITY: "ZALASEWO",
      },
      {
        CITY: "ZALESIE",
      },
      {
        CITY: "ZALESZANY",
      },
      {
        CITY: "ZALEWO",
      },
      {
        CITY: "ZALUBICE NOWE",
      },
      {
        CITY: "ZALUBICE STARE",
      },
      {
        CITY: "ZALUSKI",
      },
      {
        CITY: "ZANIEMYSL",
      },
      {
        CITY: "ZAPARCIN",
      },
      {
        CITY: "ZAROW",
      },
      {
        CITY: "ZAROWKA",
      },
      {
        CITY: "ZARSZYN",
      },
      {
        CITY: "ZARY",
      },
      {
        CITY: "ZATOR",
      },
      {
        CITY: "ZAWADA",
      },
      {
        CITY: "ZAWADY",
      },
      {
        CITY: "ZAWADZKIE",
      },
      {
        CITY: "ZAWIDOW",
      },
      {
        CITY: "ZAWIERCIE",
      },
      {
        CITY: "ZAWOJA",
      },
      {
        CITY: "ZAWONIA",
      },
      {
        CITY: "ZBASZYN",
      },
      {
        CITY: "ZBASZYNEK",
      },
      {
        CITY: "ZBICZNO",
      },
      {
        CITY: "ZBLEWO",
      },
      {
        CITY: "ZBROSLAWICE",
      },
      {
        CITY: "ZDUNSKA WOLA",
      },
      {
        CITY: "ZDUNY",
      },
      {
        CITY: "ZDZIESZOWICE",
      },
      {
        CITY: "ZEBOWICE",
      },
      {
        CITY: "ZEBRZYDOWICE",
      },
      {
        CITY: "ZEGRZE POMORSKIE",
      },
      {
        CITY: "ZELAZKOW",
      },
      {
        CITY: "ZELOW",
      },
      {
        CITY: "ZEMBRZYCE",
      },
      {
        CITY: "ZERKOW",
      },
      {
        CITY: "ZERNICA",
      },
      {
        CITY: "ZERNIKI",
      },
      {
        CITY: "ZGIERZ",
      },
      {
        CITY: "ZGLOBICE",
      },
      {
        CITY: "ZGLOBIEN",
      },
      {
        CITY: "ZGORSKO",
      },
      {
        CITY: "ZGORZALA",
      },
      {
        CITY: "ZGORZELEC",
      },
      {
        CITY: "ZIEBICE",
      },
      {
        CITY: "ZIELONA",
      },
      {
        CITY: "ZIELONE",
      },
      {
        CITY: "ZIELONKA",
      },
      {
        CITY: "ZIELONKI",
      },
      {
        CITY: "ZIELONKI-WIES",
      },
      {
        CITY: "ZLOCIENIEC",
      },
      {
        CITY: "ZLOCZEW",
      },
      {
        CITY: "ZLOTOW",
      },
      {
        CITY: "ZLOTY STOK",
      },
      {
        CITY: "ZMIGROD",
      },
      {
        CITY: "ZNIN",
      },
      {
        CITY: "ZOFIA",
      },
      {
        CITY: "ZORAWIA",
      },
      {
        CITY: "ZORAWINA",
      },
      {
        CITY: "ZOSIN",
      },
      {
        CITY: "ZRECIN",
      },
      {
        CITY: "ZUKOWO",
      },
      {
        CITY: "ZURAWICA",
      },
      {
        CITY: "ZWOLEN",
      },
      {
        CITY: "ZYRAKOW",
      },
    ],
  },
  {
    COUNTRY: "JAPAN",
    CODE: "JP",
    CITIES: [
      {
        CITY: "ABASHIRI",
      },
      {
        CITY: "ABIKO",
      },
      {
        CITY: "ABIRA",
      },
      {
        CITY: "ABOSHIKU-OKINOHAMA",
      },
      {
        CITY: "AGANO",
      },
      {
        CITY: "AGENA",
      },
      {
        CITY: "AGEO",
      },
      {
        CITY: "AIKAWA",
      },
      {
        CITY: "AIOI",
      },
      {
        CITY: "AISAI",
      },
      {
        CITY: "AITSU",
      },
      {
        CITY: "AIZU-WAKAMATSU",
      },
      {
        CITY: "AKAO",
      },
      {
        CITY: "AKASAKA",
      },
      {
        CITY: "AKASAKI",
      },
      {
        CITY: "AKASHI",
      },
      {
        CITY: "AKENO",
      },
      {
        CITY: "AKI",
      },
      {
        CITY: "AKIRUNO-SHI",
      },
      {
        CITY: "AKISHIMA",
      },
      {
        CITY: "AKITA",
      },
      {
        CITY: "AMA-HIGASHIMACHI",
      },
      {
        CITY: "AMAGASAKI",
      },
      {
        CITY: "AMI",
      },
      {
        CITY: "ANABUKI",
      },
      {
        CITY: "ANAN",
      },
      {
        CITY: "ANESAKI",
      },
      {
        CITY: "ANJO",
      },
      {
        CITY: "ANNAKA",
      },
      {
        CITY: "AOICHO",
      },
      {
        CITY: "AOMORI",
      },
      {
        CITY: "ARAI",
      },
      {
        CITY: "ARAKAWA",
      },
      {
        CITY: "ARIAKE",
      },
      {
        CITY: "ARIDA",
      },
      {
        CITY: "ARITA",
      },
      {
        CITY: "ASAHI",
      },
      {
        CITY: "ASAHIKAWA",
      },
      {
        CITY: "ASAHIMACHI",
      },
      {
        CITY: "ASAKA",
      },
      {
        CITY: "ASHIHARA",
      },
      {
        CITY: "ASHIKAGA",
      },
      {
        CITY: "ASHIYA",
      },
      {
        CITY: "ASO",
      },
      {
        CITY: "ATAMI",
      },
      {
        CITY: "ATSUGI",
      },
      {
        CITY: "AWARA",
      },
      {
        CITY: "AYASE",
      },
      {
        CITY: "AZUCHI",
      },
      {
        CITY: "AZUMINO",
      },
      {
        CITY: "BAKUROCHO",
      },
      {
        CITY: "BANDAICHO",
      },
      {
        CITY: "BANDO",
      },
      {
        CITY: "BEPPU",
      },
      {
        CITY: "BIBAI",
      },
      {
        CITY: "BUDO",
      },
      {
        CITY: "CHATAN",
      },
      {
        CITY: "CHIBA",
      },
      {
        CITY: "CHICHIBU",
      },
      {
        CITY: "CHIGASAKI",
      },
      {
        CITY: "CHIJI",
      },
      {
        CITY: "CHIKUSEI",
      },
      {
        CITY: "CHINO",
      },
      {
        CITY: "CHIRAN",
      },
      {
        CITY: "CHIRYU",
      },
      {
        CITY: "CHITA",
      },
      {
        CITY: "CHITOSE",
      },
      {
        CITY: "CHOSHI",
      },
      {
        CITY: "CHUO",
      },
      {
        CITY: "DAIDO",
      },
      {
        CITY: "DAIGO",
      },
      {
        CITY: "DAIMON",
      },
      {
        CITY: "DAISEN",
      },
      {
        CITY: "DAITOCHO",
      },
      {
        CITY: "DATE",
      },
      {
        CITY: "EBETSU",
      },
      {
        CITY: "EBINA",
      },
      {
        CITY: "EBISU",
      },
      {
        CITY: "ECHIZEN",
      },
      {
        CITY: "EDAGAWA",
      },
      {
        CITY: "EDOGAWA-KU",
      },
      {
        CITY: "ENA",
      },
      {
        CITY: "ENIWA-SHI",
      },
      {
        CITY: "ESASHI",
      },
      {
        CITY: "FUCHU-MACHI",
      },
      {
        CITY: "FUCHUCHO",
      },
      {
        CITY: "FUEFUKI-SHI",
      },
      {
        CITY: "FUJI",
      },
      {
        CITY: "FUJIEDA",
      },
      {
        CITY: "FUJIIDERA",
      },
      {
        CITY: "FUJIMI",
      },
      {
        CITY: "FUJIMINO",
      },
      {
        CITY: "FUJINOMIYA",
      },
      {
        CITY: "FUJIOKA",
      },
      {
        CITY: "FUJISATO",
      },
      {
        CITY: "FUJISAWA",
      },
      {
        CITY: "FUJITA",
      },
      {
        CITY: "FUKAI",
      },
      {
        CITY: "FUKAYACHO",
      },
      {
        CITY: "FUKIAGE-FUJIMI",
      },
      {
        CITY: "FUKUCHI",
      },
      {
        CITY: "FUKUCHIYAMA",
      },
      {
        CITY: "FUKUDA",
      },
      {
        CITY: "FUKUI",
      },
      {
        CITY: "FUKUI-SHI",
      },
      {
        CITY: "FUKUOKA",
      },
      {
        CITY: "FUKUROI",
      },
      {
        CITY: "FUKUSHIMA-SHI",
      },
      {
        CITY: "FUKUYAMA",
      },
      {
        CITY: "FUMOTO",
      },
      {
        CITY: "FUNABASHI",
      },
      {
        CITY: "FUSHIKI-MINATOMACHI",
      },
      {
        CITY: "FUSHIMI",
      },
      {
        CITY: "FUSSA",
      },
      {
        CITY: "FUTOO",
      },
      {
        CITY: "FUTTSU",
      },
      {
        CITY: "GAMAGORI",
      },
      {
        CITY: "GANNOSU",
      },
      {
        CITY: "GEINOCHO-MUKUMOTO",
      },
      {
        CITY: "GIFU",
      },
      {
        CITY: "GINOWAN",
      },
      {
        CITY: "GINZA",
      },
      {
        CITY: "GOBO",
      },
      {
        CITY: "GODO",
      },
      {
        CITY: "GOJO",
      },
      {
        CITY: "GOSEN",
      },
      {
        CITY: "GOSHOGAWARA",
      },
      {
        CITY: "GOTANDA",
      },
      {
        CITY: "GOTEMBA",
      },
      {
        CITY: "GOTSUCHO",
      },
      {
        CITY: "GOYA",
      },
      {
        CITY: "GUJO",
      },
      {
        CITY: "GUNDO",
      },
      {
        CITY: "GUSHIKAWA",
      },
      {
        CITY: "GYODA",
      },
      {
        CITY: "HABIKINO",
      },
      {
        CITY: "HACHIMAN-CHO",
      },
      {
        CITY: "HACHIMANTAI",
      },
      {
        CITY: "HACHIMORI",
      },
      {
        CITY: "HACHINOHE",
      },
      {
        CITY: "HADANO",
      },
      {
        CITY: "HAEBARU",
      },
      {
        CITY: "HAGI",
      },
      {
        CITY: "HAIBARA-AKANEDAI",
      },
      {
        CITY: "HAIJIMA",
      },
      {
        CITY: "HAKATA",
      },
      {
        CITY: "HAKODATE",
      },
      {
        CITY: "HAKONE",
      },
      {
        CITY: "HAKOZAKI",
      },
      {
        CITY: "HAKUI",
      },
      {
        CITY: "HAMA",
      },
      {
        CITY: "HAMADA",
      },
      {
        CITY: "HAMAMATSU",
      },
      {
        CITY: "HAMANA",
      },
      {
        CITY: "HAMURA",
      },
      {
        CITY: "HANABATACHO",
      },
      {
        CITY: "HANAMAKI",
      },
      {
        CITY: "HANAWADAMACHI",
      },
      {
        CITY: "HANDA",
      },
      {
        CITY: "HANNAN",
      },
      {
        CITY: "HANNO",
      },
      {
        CITY: "HANYU",
      },
      {
        CITY: "HARIGAYA",
      },
      {
        CITY: "HASHIMA",
      },
      {
        CITY: "HASHIMOTO",
      },
      {
        CITY: "HASUDA",
      },
      {
        CITY: "HATOGAYA-HONCHO",
      },
      {
        CITY: "HATOYAMA",
      },
      {
        CITY: "HATSUKAICHI",
      },
      {
        CITY: "HAYAMA",
      },
      {
        CITY: "HAYASHI",
      },
      {
        CITY: "HAYASHIMA",
      },
      {
        CITY: "HAYASHINO",
      },
      {
        CITY: "HEIWAJIMA",
      },
      {
        CITY: "HEKINAN",
      },
      {
        CITY: "HIDAKA",
      },
      {
        CITY: "HIDAKACHO-EBARA",
      },
      {
        CITY: "HIGASHI-IWASHIRO",
      },
      {
        CITY: "HIGASHI-TAKADAMA",
      },
      {
        CITY: "HIGASHIASAHIMACHI",
      },
      {
        CITY: "HIGASHIBORIDORI",
      },
      {
        CITY: "HIGASHICHO",
      },
      {
        CITY: "HIGASHIIYA-TAKANO",
      },
      {
        CITY: "HIGASHIIYA-WAKABAYASHI",
      },
      {
        CITY: "HIGASHIKAWA",
      },
      {
        CITY: "HIGASHIMURAYAMA-SHI",
      },
      {
        CITY: "HIGASHINE",
      },
      {
        CITY: "HIGASHIOMI-SHI",
      },
      {
        CITY: "HIGASHIOSAKA",
      },
      {
        CITY: "HIGASHIURA",
      },
      {
        CITY: "HIGASHIYAMA",
      },
      {
        CITY: "HIGASHIYAMATO",
      },
      {
        CITY: "HIJI",
      },
      {
        CITY: "HIKARI",
      },
      {
        CITY: "HIKAWA",
      },
      {
        CITY: "HIKI",
      },
      {
        CITY: "HIKONE",
      },
      {
        CITY: "HIMEJI",
      },
      {
        CITY: "HIMI",
      },
      {
        CITY: "HINATA",
      },
      {
        CITY: "HINO",
      },
      {
        CITY: "HINODE",
      },
      {
        CITY: "HIRAKATA",
      },
      {
        CITY: "HIRAKAWA",
      },
      {
        CITY: "HIRANUMA",
      },
      {
        CITY: "HIRATSUKA",
      },
      {
        CITY: "HIROKAWA",
      },
      {
        CITY: "HIROSAKI",
      },
      {
        CITY: "HIROSHIMA",
      },
      {
        CITY: "HITA",
      },
      {
        CITY: "HITACHI",
      },
      {
        CITY: "HITOYOSHI",
      },
      {
        CITY: "HIYOSHI",
      },
      {
        CITY: "HODOGAYACHO",
      },
      {
        CITY: "HOFU",
      },
      {
        CITY: "HOJO",
      },
      {
        CITY: "HOKOTA",
      },
      {
        CITY: "HOKUTO-SHI",
      },
      {
        CITY: "HOMMACHI",
      },
      {
        CITY: "HONCHO",
      },
      {
        CITY: "HONDEN",
      },
      {
        CITY: "HONGO",
      },
      {
        CITY: "HONJO",
      },
      {
        CITY: "HONJOCHO",
      },
      {
        CITY: "HORINOUCHI",
      },
      {
        CITY: "HORYUJI",
      },
      {
        CITY: "HOSOBARA",
      },
      {
        CITY: "HOTEI",
      },
      {
        CITY: "HYOGO",
      },
      {
        CITY: "IBARA",
      },
      {
        CITY: "IBARAKI",
      },
      {
        CITY: "ICHI",
      },
      {
        CITY: "ICHIBANHORIDORICHO",
      },
      {
        CITY: "ICHIHARA",
      },
      {
        CITY: "ICHIKAWA",
      },
      {
        CITY: "ICHIMURA",
      },
      {
        CITY: "ICHINOGO",
      },
      {
        CITY: "ICHINOMIYA",
      },
      {
        CITY: "ICHINOMIYACHO",
      },
      {
        CITY: "ICHINOMOTOCHO",
      },
      {
        CITY: "ICHINOSEKI",
      },
      {
        CITY: "IDA",
      },
      {
        CITY: "IIDA",
      },
      {
        CITY: "IIYAMA",
      },
      {
        CITY: "IIZUKA",
      },
      {
        CITY: "IJU",
      },
      {
        CITY: "IKARUGA",
      },
      {
        CITY: "IKEDA",
      },
      {
        CITY: "IKEDACHO-ITANO",
      },
      {
        CITY: "IKEJIRI",
      },
      {
        CITY: "IKOMA",
      },
      {
        CITY: "IKUNO",
      },
      {
        CITY: "IMABARI-SHI",
      },
      {
        CITY: "IMABUKU",
      },
      {
        CITY: "IMAGAMI",
      },
      {
        CITY: "IMAICHO",
      },
      {
        CITY: "IMARI",
      },
      {
        CITY: "IMIZUCHO",
      },
      {
        CITY: "INA",
      },
      {
        CITY: "INABE",
      },
      {
        CITY: "INAGE",
      },
      {
        CITY: "INAGI-SHI",
      },
      {
        CITY: "INAMI",
      },
      {
        CITY: "INASHIKI",
      },
      {
        CITY: "INAZAWA",
      },
      {
        CITY: "INNOSHIMA HARA-CHO",
      },
      {
        CITY: "INO",
      },
      {
        CITY: "INUYAMA",
      },
      {
        CITY: "INZAI",
      },
      {
        CITY: "IRUMA",
      },
      {
        CITY: "ISAHAYA",
      },
      {
        CITY: "ISAWA",
      },
      {
        CITY: "ISE",
      },
      {
        CITY: "ISEHARA",
      },
      {
        CITY: "ISESAKI",
      },
      {
        CITY: "ISHIDA",
      },
      {
        CITY: "ISHIGAKI",
      },
      {
        CITY: "ISHIGE",
      },
      {
        CITY: "ISHII",
      },
      {
        CITY: "ISHIKARI",
      },
      {
        CITY: "ISHIKAWA",
      },
      {
        CITY: "ISHIKI",
      },
      {
        CITY: "ISHINOMAKI",
      },
      {
        CITY: "ISHIOKA",
      },
      {
        CITY: "ISOMURA",
      },
      {
        CITY: "ISOYA",
      },
      {
        CITY: "ISUMI",
      },
      {
        CITY: "ITABASHI",
      },
      {
        CITY: "ITAKO",
      },
      {
        CITY: "ITAMI",
      },
      {
        CITY: "ITOI",
      },
      {
        CITY: "ITOIGAWA",
      },
      {
        CITY: "ITOMAN",
      },
      {
        CITY: "IWADE",
      },
      {
        CITY: "IWAKI",
      },
      {
        CITY: "IWAKIRI",
      },
      {
        CITY: "IWAKUNI",
      },
      {
        CITY: "IWAKURA",
      },
      {
        CITY: "IWAMIZAWA",
      },
      {
        CITY: "IWANAI",
      },
      {
        CITY: "IWANUMA",
      },
      {
        CITY: "IWATA",
      },
      {
        CITY: "IWATSUKI",
      },
      {
        CITY: "IZU",
      },
      {
        CITY: "IZUMI",
      },
      {
        CITY: "IZUMIOTSU",
      },
      {
        CITY: "IZUMO",
      },
      {
        CITY: "IZUNOKUNI",
      },
      {
        CITY: "JISSEN",
      },
      {
        CITY: "JOETSU",
      },
      {
        CITY: "JOGO",
      },
      {
        CITY: "JOSHI",
      },
      {
        CITY: "JOSO",
      },
      {
        CITY: "JUMONJI",
      },
      {
        CITY: "KABE",
      },
      {
        CITY: "KADOMA",
      },
      {
        CITY: "KAGA",
      },
      {
        CITY: "KAGAWA",
      },
      {
        CITY: "KAGOSHIMA",
      },
      {
        CITY: "KAGOYA",
      },
      {
        CITY: "KAHOKU",
      },
      {
        CITY: "KAIGANDORI",
      },
      {
        CITY: "KAINAN",
      },
      {
        CITY: "KAISEI",
      },
      {
        CITY: "KAITA",
      },
      {
        CITY: "KAIZU",
      },
      {
        CITY: "KAIZUKA",
      },
      {
        CITY: "KAKAMIGAHARA",
      },
      {
        CITY: "KAKEGAWA",
      },
      {
        CITY: "KAKOGAWA",
      },
      {
        CITY: "KAKUDA",
      },
      {
        CITY: "KAMAGAYA-SHI",
      },
      {
        CITY: "KAMAISHI",
      },
      {
        CITY: "KAMAKURA",
      },
      {
        CITY: "KAMEDA-HONCHO",
      },
      {
        CITY: "KAMEOKA",
      },
      {
        CITY: "KAMEYAMA",
      },
      {
        CITY: "KAMI-KAWAKAMI",
      },
      {
        CITY: "KAMI-NAGAREKAWACHO",
      },
      {
        CITY: "KAMIBUN",
      },
      {
        CITY: "KAMIGORI",
      },
      {
        CITY: "KAMIKAWA",
      },
      {
        CITY: "KAMIKITA-KITA",
      },
      {
        CITY: "KAMIMURA",
      },
      {
        CITY: "KAMINOKAWA",
      },
      {
        CITY: "KAMINOYAMA",
      },
      {
        CITY: "KAMIRENJAKU",
      },
      {
        CITY: "KAMISU",
      },
      {
        CITY: "KAMITOSA",
      },
      {
        CITY: "KAMO",
      },
      {
        CITY: "KAMOGATACHO-KAMOGATA",
      },
      {
        CITY: "KAMOI",
      },
      {
        CITY: "KANADA",
      },
      {
        CITY: "KANAGAWA",
      },
      {
        CITY: "KANAYA",
      },
      {
        CITY: "KANAYAMA",
      },
      {
        CITY: "KANAZAWA",
      },
      {
        CITY: "KANDA",
      },
      {
        CITY: "KANEGASAKI",
      },
      {
        CITY: "KANI",
      },
      {
        CITY: "KANIE",
      },
      {
        CITY: "KANO",
      },
      {
        CITY: "KANOMATA",
      },
      {
        CITY: "KANUMA",
      },
      {
        CITY: "KANZAKI",
      },
      {
        CITY: "KARATSU",
      },
      {
        CITY: "KARIYA",
      },
      {
        CITY: "KASAI",
      },
      {
        CITY: "KASAMA",
      },
      {
        CITY: "KASAMATSUCHO",
      },
      {
        CITY: "KASAOKA",
      },
      {
        CITY: "KASEDA-SHIRAKAME",
      },
      {
        CITY: "KASHIBA",
      },
      {
        CITY: "KASHIHARA",
      },
      {
        CITY: "KASHIMA",
      },
      {
        CITY: "KASHIMA-SHI",
      },
      {
        CITY: "KASHIWA",
      },
      {
        CITY: "KASHIWAGIMACHI",
      },
      {
        CITY: "KASHIWARA",
      },
      {
        CITY: "KASHIWAZAKI",
      },
      {
        CITY: "KASUGA",
      },
      {
        CITY: "KASUGAI",
      },
      {
        CITY: "KASUKABE",
      },
      {
        CITY: "KASUMICHO",
      },
      {
        CITY: "KASUMIGASEKI",
      },
      {
        CITY: "KASUMIGAURA",
      },
      {
        CITY: "KATAOKA",
      },
      {
        CITY: "KATORI-SHI",
      },
      {
        CITY: "KATSUMADA",
      },
      {
        CITY: "KATSURA",
      },
      {
        CITY: "KATSUURA",
      },
      {
        CITY: "KATSUYAMA",
      },
      {
        CITY: "KATTA",
      },
      {
        CITY: "KAWAGOE",
      },
      {
        CITY: "KAWAGUCHI",
      },
      {
        CITY: "KAWAHARA",
      },
      {
        CITY: "KAWANISHI",
      },
      {
        CITY: "KAWASAKI",
      },
      {
        CITY: "KAYANO",
      },
      {
        CITY: "KAZO",
      },
      {
        CITY: "KAZUKA",
      },
      {
        CITY: "KAZUNO",
      },
      {
        CITY: "KESENNUMA",
      },
      {
        CITY: "KETA",
      },
      {
        CITY: "KICHIJOJI-HONCHO",
      },
      {
        CITY: "KII-NAGASHIMAKU-NAGASHIMA",
      },
      {
        CITY: "KIKUCHI",
      },
      {
        CITY: "KIKUGAWA",
      },
      {
        CITY: "KIKYO",
      },
      {
        CITY: "KIMITSU",
      },
      {
        CITY: "KINOKAWA",
      },
      {
        CITY: "KINOSHITA",
      },
      {
        CITY: "KIRYU",
      },
      {
        CITY: "KISARAZU",
      },
      {
        CITY: "KISHIWADA",
      },
      {
        CITY: "KITA",
      },
      {
        CITY: "KITA-SANNOMARU",
      },
      {
        CITY: "KITAGATA",
      },
      {
        CITY: "KITAHIROSHIMA",
      },
      {
        CITY: "KITAIBARAKI",
      },
      {
        CITY: "KITAKAMI",
      },
      {
        CITY: "KITAKATA",
      },
      {
        CITY: "KITAKYUSHU",
      },
      {
        CITY: "KITAMI",
      },
      {
        CITY: "KITAMOTO",
      },
      {
        CITY: "KITANAGOYA",
      },
      {
        CITY: "KITAOZUMA",
      },
      {
        CITY: "KITSUKI",
      },
      {
        CITY: "KITSUREGAWA",
      },
      {
        CITY: "KIYAMA",
      },
      {
        CITY: "KIYOKAWA",
      },
      {
        CITY: "KIYOSE",
      },
      {
        CITY: "KIYOSU",
      },
      {
        CITY: "KOBE",
      },
      {
        CITY: "KOCHI-SHI",
      },
      {
        CITY: "KOCHINO",
      },
      {
        CITY: "KOCHO",
      },
      {
        CITY: "KODAIRA",
      },
      {
        CITY: "KODAMACHO-KODAMAMINAMI",
      },
      {
        CITY: "KOFU",
      },
      {
        CITY: "KOGA",
      },
      {
        CITY: "KOGANEI",
      },
      {
        CITY: "KOJIMA",
      },
      {
        CITY: "KOKA",
      },
      {
        CITY: "KOKAWA",
      },
      {
        CITY: "KOKUBU-MATSUKI",
      },
      {
        CITY: "KOKUBUNJI",
      },
      {
        CITY: "KOKUFU",
      },
      {
        CITY: "KOKURA",
      },
      {
        CITY: "KOKURYO",
      },
      {
        CITY: "KOMABA",
      },
      {
        CITY: "KOMAE-SHI",
      },
      {
        CITY: "KOMAGANE",
      },
      {
        CITY: "KOMAGAWA",
      },
      {
        CITY: "KOMAGOME",
      },
      {
        CITY: "KOMAKI",
      },
      {
        CITY: "KOMATSU",
      },
      {
        CITY: "KOMATSUBARA",
      },
      {
        CITY: "KOMATSUSHIMACHO",
      },
      {
        CITY: "KOMONO",
      },
      {
        CITY: "KOMORO",
      },
      {
        CITY: "KONAN",
      },
      {
        CITY: "KONANCHO-FUKAWA",
      },
      {
        CITY: "KONOSU",
      },
      {
        CITY: "KOORI",
      },
      {
        CITY: "KORIYAMA",
      },
      {
        CITY: "KORYOCHO-ITAZU",
      },
      {
        CITY: "KOSAI",
      },
      {
        CITY: "KOSE",
      },
      {
        CITY: "KOSHIGAYA",
      },
      {
        CITY: "KOTO",
      },
      {
        CITY: "KOTOHIRA",
      },
      {
        CITY: "KOTOKU",
      },
      {
        CITY: "KOYAMA",
      },
      {
        CITY: "KOYASAN",
      },
      {
        CITY: "KOYO",
      },
      {
        CITY: "KOZA",
      },
      {
        CITY: "KUBO",
      },
      {
        CITY: "KUDAMATSU",
      },
      {
        CITY: "KUGAYAMA",
      },
      {
        CITY: "KUKICHUO",
      },
      {
        CITY: "KUMAGAYA",
      },
      {
        CITY: "KUMAMOTO",
      },
      {
        CITY: "KUMANO",
      },
      {
        CITY: "KUNITACHI",
      },
      {
        CITY: "KURAMI",
      },
      {
        CITY: "KURASHIKI",
      },
      {
        CITY: "KURAYOSHI",
      },
      {
        CITY: "KURE",
      },
      {
        CITY: "KURIHARA",
      },
      {
        CITY: "KURIHASHI",
      },
      {
        CITY: "KUROBESHIN",
      },
      {
        CITY: "KUROGI",
      },
      {
        CITY: "KUROISHI",
      },
      {
        CITY: "KUROIWA",
      },
      {
        CITY: "KURUME",
      },
      {
        CITY: "KUSAI",
      },
      {
        CITY: "KUSATSU",
      },
      {
        CITY: "KUSE",
      },
      {
        CITY: "KUSHIMOTO",
      },
      {
        CITY: "KUSHIRO",
      },
      {
        CITY: "KUSUNOKI",
      },
      {
        CITY: "KUTCHAN",
      },
      {
        CITY: "KUWANA-SHI",
      },
      {
        CITY: "KYODO",
      },
      {
        CITY: "KYORITSU",
      },
      {
        CITY: "KYOTO",
      },
      {
        CITY: "KYOWA",
      },
      {
        CITY: "MACHI",
      },
      {
        CITY: "MACHIDA",
      },
      {
        CITY: "MACHIYA",
      },
      {
        CITY: "MAEBARU",
      },
      {
        CITY: "MAEBASHI",
      },
      {
        CITY: "MAIBARA",
      },
      {
        CITY: "MAIZURU",
      },
      {
        CITY: "MAKI",
      },
      {
        CITY: "MAKINOHARA",
      },
      {
        CITY: "MAKUHARI",
      },
      {
        CITY: "MARUGAME",
      },
      {
        CITY: "MARUNOUCHI",
      },
      {
        CITY: "MARUYAMA",
      },
      {
        CITY: "MASAKI",
      },
      {
        CITY: "MASHIKO",
      },
      {
        CITY: "MASUDA",
      },
      {
        CITY: "MATSUBARA",
      },
      {
        CITY: "MATSUDO",
      },
      {
        CITY: "MATSUE-SHI",
      },
      {
        CITY: "MATSUKAWAMACHI",
      },
      {
        CITY: "MATSUMOTO",
      },
      {
        CITY: "MATSUSAKA",
      },
      {
        CITY: "MATSUTO",
      },
      {
        CITY: "MATSUYAMA",
      },
      {
        CITY: "MEGURO",
      },
      {
        CITY: "MEIJI",
      },
      {
        CITY: "MIBU",
      },
      {
        CITY: "MIDORI",
      },
      {
        CITY: "MIEMACHI",
      },
      {
        CITY: "MIHAMA",
      },
      {
        CITY: "MIHARA",
      },
      {
        CITY: "MIKI",
      },
      {
        CITY: "MIMA",
      },
      {
        CITY: "MIMASAKA",
      },
      {
        CITY: "MINABE",
      },
      {
        CITY: "MINAMATA",
      },
      {
        CITY: "MINAMI",
      },
      {
        CITY: "MINAMIAOYAMA",
      },
      {
        CITY: "MINAMIASHIGARA",
      },
      {
        CITY: "MINAMIUONUMA",
      },
      {
        CITY: "MINATO",
      },
      {
        CITY: "MINATOGAWA",
      },
      {
        CITY: "MINATOMACHI",
      },
      {
        CITY: "MINE",
      },
      {
        CITY: "MINEYAMA",
      },
      {
        CITY: "MINO",
      },
      {
        CITY: "MINOBU",
      },
      {
        CITY: "MINOKAMO",
      },
      {
        CITY: "MINOSHIMA",
      },
      {
        CITY: "MINOWA",
      },
      {
        CITY: "MISAKICHO",
      },
      {
        CITY: "MISAKIMACHI-KONAMI",
      },
      {
        CITY: "MISATO",
      },
      {
        CITY: "MISAWA",
      },
      {
        CITY: "MISHIMA",
      },
      {
        CITY: "MITAI",
      },
      {
        CITY: "MITAKE",
      },
      {
        CITY: "MITO-SHI",
      },
      {
        CITY: "MITSUCHO-IWAMI",
      },
      {
        CITY: "MITSUI",
      },
      {
        CITY: "MITSUKAIDO",
      },
      {
        CITY: "MITSUKE",
      },
      {
        CITY: "MITSUSHIMACHO-OSAKI",
      },
      {
        CITY: "MIURA",
      },
      {
        CITY: "MIWA",
      },
      {
        CITY: "MIYAGI",
      },
      {
        CITY: "MIYAKO",
      },
      {
        CITY: "MIYAKOJIMA",
      },
      {
        CITY: "MIYAKONOJO",
      },
      {
        CITY: "MIYAMA",
      },
      {
        CITY: "MIYATA",
      },
      {
        CITY: "MIYAZAKI-SHI",
      },
      {
        CITY: "MIYAZU",
      },
      {
        CITY: "MIYOSHI",
      },
      {
        CITY: "MIZUGAMA",
      },
      {
        CITY: "MIZUKO",
      },
      {
        CITY: "MIZUNAMI",
      },
      {
        CITY: "MIZUSAWA",
      },
      {
        CITY: "MIZUSHIMA-NISHICHIDORICHO",
      },
      {
        CITY: "MOBARA",
      },
      {
        CITY: "MOCHIDA",
      },
      {
        CITY: "MOJI",
      },
      {
        CITY: "MOMBETSU",
      },
      {
        CITY: "MOMOISHI",
      },
      {
        CITY: "MONZENMACHI-IGISU",
      },
      {
        CITY: "MOOKA",
      },
      {
        CITY: "MORI",
      },
      {
        CITY: "MORIGUCHI",
      },
      {
        CITY: "MORIOKA",
      },
      {
        CITY: "MORIYA",
      },
      {
        CITY: "MORIYAMA",
      },
      {
        CITY: "MORODOMITSU",
      },
      {
        CITY: "MOROHONGO",
      },
      {
        CITY: "MOROWA",
      },
      {
        CITY: "MOTEGI",
      },
      {
        CITY: "MOTOMIYA",
      },
      {
        CITY: "MUIKAMACHI",
      },
      {
        CITY: "MUKO",
      },
      {
        CITY: "MURAI",
      },
      {
        CITY: "MURAKAMI",
      },
      {
        CITY: "MURATA",
      },
      {
        CITY: "MURAYAMA",
      },
      {
        CITY: "MURORAN",
      },
      {
        CITY: "MUROTO-MISAKICHO",
      },
      {
        CITY: "MUSA",
      },
      {
        CITY: "MUSASHIMURAYAMA",
      },
      {
        CITY: "MUSASHINO",
      },
      {
        CITY: "MUTSU",
      },
      {
        CITY: "MUTSUMI",
      },
      {
        CITY: "NABARI",
      },
      {
        CITY: "NADA",
      },
      {
        CITY: "NAEBOCHO",
      },
      {
        CITY: "NAGAHAMA",
      },
      {
        CITY: "NAGANO-SHI",
      },
      {
        CITY: "NAGANOHARA",
      },
      {
        CITY: "NAGAO",
      },
      {
        CITY: "NAGAOKA",
      },
      {
        CITY: "NAGAREYAMA",
      },
      {
        CITY: "NAGASAKI",
      },
      {
        CITY: "NAGASE",
      },
      {
        CITY: "NAGASHIMA",
      },
      {
        CITY: "NAGATO",
      },
      {
        CITY: "NAGAWADO",
      },
      {
        CITY: "NAGO",
      },
      {
        CITY: "NAGOYA-SHI",
      },
      {
        CITY: "NAHA",
      },
      {
        CITY: "NAITOBOKUJO",
      },
      {
        CITY: "NAKA",
      },
      {
        CITY: "NAKADA",
      },
      {
        CITY: "NAKAGAWA",
      },
      {
        CITY: "NAKAGOMI",
      },
      {
        CITY: "NAKAGUSUKU",
      },
      {
        CITY: "NAKAHAI",
      },
      {
        CITY: "NAKAHATA",
      },
      {
        CITY: "NAKAICHI",
      },
      {
        CITY: "NAKAMA",
      },
      {
        CITY: "NAKAMURA",
      },
      {
        CITY: "NAKANISHI",
      },
      {
        CITY: "NAKANO",
      },
      {
        CITY: "NAKANO-KAMI",
      },
      {
        CITY: "NAKANOSHIMA",
      },
      {
        CITY: "NAKATSU",
      },
      {
        CITY: "NAKATSUGAWA",
      },
      {
        CITY: "NAKAYAMA",
      },
      {
        CITY: "NAKAZAWA",
      },
      {
        CITY: "NAKIJIN",
      },
      {
        CITY: "CITYRIKAWA",
      },
      {
        CITY: "NANAE",
      },
      {
        CITY: "NANAO",
      },
      {
        CITY: "NANTO",
      },
      {
        CITY: "NARA",
      },
      {
        CITY: "NARASHINO-SHI",
      },
      {
        CITY: "NARITA",
      },
      {
        CITY: "NARUTOCHO-MITSUISHI",
      },
      {
        CITY: "NASA",
      },
      {
        CITY: "NASUKARASUYAMA",
      },
      {
        CITY: "NATORI-SHI",
      },
      {
        CITY: "NATSUMI",
      },
      {
        CITY: "NAYORO",
      },
      {
        CITY: "NEMURO",
      },
      {
        CITY: "NERIMA-KU",
      },
      {
        CITY: "NEYAGAWA",
      },
      {
        CITY: "NICHINAN",
      },
      {
        CITY: "NIHOMMATSU",
      },
      {
        CITY: "NIIGATA",
      },
      {
        CITY: "NIIHAMA",
      },
      {
        CITY: "NIIMI",
      },
      {
        CITY: "NIITSU",
      },
      {
        CITY: "NIIZA",
      },
      {
        CITY: "NIKAHO",
      },
      {
        CITY: "NINOHE",
      },
      {
        CITY: "NINOMIYA",
      },
      {
        CITY: "NIRASAKI",
      },
      {
        CITY: "NISHI",
      },
      {
        CITY: "NISHI-TOKYO-SHI",
      },
      {
        CITY: "NISHIHARA",
      },
      {
        CITY: "NISHIKAWA",
      },
      {
        CITY: "NISHIKICHO",
      },
      {
        CITY: "NISHIKO",
      },
      {
        CITY: "NISHIMACHI",
      },
      {
        CITY: "NISHIME",
      },
      {
        CITY: "NISHINOMIYA",
      },
      {
        CITY: "NISHIO",
      },
      {
        CITY: "NISHIWAKI",
      },
      {
        CITY: "NISHIYAMA",
      },
      {
        CITY: "NISSHIN",
      },
      {
        CITY: "NITA",
      },
      {
        CITY: "NOBEOKA",
      },
      {
        CITY: "NOBITOME",
      },
      {
        CITY: "NOBORIBETSU",
      },
      {
        CITY: "NODA",
      },
      {
        CITY: "NODANI",
      },
      {
        CITY: "NOGATA",
      },
      {
        CITY: "NOGUCHI",
      },
      {
        CITY: "NOMIMACHI",
      },
      {
        CITY: "NOMURA",
      },
      {
        CITY: "NONE-MURA",
      },
      {
        CITY: "NONOICHI",
      },
      {
        CITY: "NOSE",
      },
      {
        CITY: "NOSHIRO",
      },
      {
        CITY: "NUMAZU",
      },
      {
        CITY: "NYUZEN",
      },
      {
        CITY: "OARAI",
      },
      {
        CITY: "OBAMA",
      },
      {
        CITY: "OBANAZAWA",
      },
      {
        CITY: "OBARI",
      },
      {
        CITY: "OBAYASHI",
      },
      {
        CITY: "OBIHIRO",
      },
      {
        CITY: "OBU",
      },
      {
        CITY: "OBUSE",
      },
      {
        CITY: "OCHIAI",
      },
      {
        CITY: "OCHIKAWA",
      },
      {
        CITY: "ODAIRA",
      },
      {
        CITY: "ODAWARA",
      },
      {
        CITY: "ODORI",
      },
      {
        CITY: "OGAKI",
      },
      {
        CITY: "OGAWA",
      },
      {
        CITY: "OGI",
      },
      {
        CITY: "OGORI-SHIMOGO",
      },
      {
        CITY: "OGUNI",
      },
      {
        CITY: "OGURA",
      },
      {
        CITY: "OHARA",
      },
      {
        CITY: "OISO",
      },
      {
        CITY: "OIWAKE",
      },
      {
        CITY: "OIZU",
      },
      {
        CITY: "OJIYA",
      },
      {
        CITY: "OKAWA",
      },
      {
        CITY: "OKAWACHI",
      },
      {
        CITY: "OKAYA",
      },
      {
        CITY: "OKAYAMA",
      },
      {
        CITY: "OKAZAKI",
      },
      {
        CITY: "OKEGAWA",
      },
      {
        CITY: "OKINAWA",
      },
      {
        CITY: "OKUBO",
      },
      {
        CITY: "OKUCHI",
      },
      {
        CITY: "OKUMA",
      },
      {
        CITY: "OKUURA",
      },
      {
        CITY: "OMACHI",
      },
      {
        CITY: "OMAEZAKI",
      },
      {
        CITY: "OMAGARI",
      },
      {
        CITY: "OME",
      },
      {
        CITY: "OMIHACHIMAN",
      },
      {
        CITY: "OMIYA",
      },
      {
        CITY: "OMIYA-KU",
      },
      {
        CITY: "OMORI",
      },
      {
        CITY: "OMURA",
      },
      {
        CITY: "OMUTA",
      },
      {
        CITY: "ONNA",
      },
      {
        CITY: "ONO",
      },
      {
        CITY: "ONODA",
      },
      {
        CITY: "ONOGAWA",
      },
      {
        CITY: "ONOJO",
      },
      {
        CITY: "ONOMICHI",
      },
      {
        CITY: "ONUMA",
      },
      {
        CITY: "OSAKA",
      },
      {
        CITY: "OSAKI",
      },
      {
        CITY: "OSAWA",
      },
      {
        CITY: "OSHIMA",
      },
      {
        CITY: "OSHU",
      },
      {
        CITY: "OTA",
      },
      {
        CITY: "OTAKE",
      },
      {
        CITY: "OTANI",
      },
      {
        CITY: "OTARU",
      },
      {
        CITY: "OTAWARA",
      },
      {
        CITY: "OTEMACHI",
      },
      {
        CITY: "OTSUKACHO",
      },
      {
        CITY: "OTSUKI",
      },
      {
        CITY: "OTSUKU-KIBI",
      },
      {
        CITY: "OWARIASAHI",
      },
      {
        CITY: "OYABE",
      },
      {
        CITY: "OYAMA",
      },
      {
        CITY: "OYAMATO",
      },
      {
        CITY: "OZU",
      },
      {
        CITY: "RAIBA",
      },
      {
        CITY: "RANKOSHI-CHO",
      },
      {
        CITY: "RIFU",
      },
      {
        CITY: "RITTO",
      },
      {
        CITY: "ROPPONGI",
      },
      {
        CITY: "RYUGASAKI",
      },
      {
        CITY: "RYUO",
      },
      {
        CITY: "SABAE",
      },
      {
        CITY: "SADO",
      },
      {
        CITY: "SAGA-SHI",
      },
      {
        CITY: "SAGAE",
      },
      {
        CITY: "SAGAMIHARA-SHI",
      },
      {
        CITY: "SAIDAIJI-NAKA",
      },
      {
        CITY: "SAIJO",
      },
      {
        CITY: "SAIKAI",
      },
      {
        CITY: "SAIKI",
      },
      {
        CITY: "SAITAMA",
      },
      {
        CITY: "SAKADO",
      },
      {
        CITY: "SAKAE",
      },
      {
        CITY: "SAKAI",
      },
      {
        CITY: "SAKAIDECHO",
      },
      {
        CITY: "SAKAIMINATO",
      },
      {
        CITY: "SAKATA",
      },
      {
        CITY: "SAKU",
      },
      {
        CITY: "SAKURA",
      },
      {
        CITY: "SAKURACHO",
      },
      {
        CITY: "SAKURAI",
      },
      {
        CITY: "SAKURAJIMA-KOIKECHO",
      },
      {
        CITY: "SAKURAKABU",
      },
      {
        CITY: "SANDACHO",
      },
      {
        CITY: "SANGO",
      },
      {
        CITY: "SANJO",
      },
      {
        CITY: "SANMU",
      },
      {
        CITY: "SANNO",
      },
      {
        CITY: "SANNOHE",
      },
      {
        CITY: "SANO",
      },
      {
        CITY: "SANUKI",
      },
      {
        CITY: "SAPPORO",
      },
      {
        CITY: "SARUGAKUCHO",
      },
      {
        CITY: "SASAGURI",
      },
      {
        CITY: "SASAYAMA",
      },
      {
        CITY: "SASEBO",
      },
      {
        CITY: "SATOCHO-SATO",
      },
      {
        CITY: "SATTE",
      },
      {
        CITY: "SAWARA",
      },
      {
        CITY: "SAYAMA",
      },
      {
        CITY: "SAYO",
      },
      {
        CITY: "SEIRO",
      },
      {
        CITY: "SEKICHO-FURUMAYA",
      },
      {
        CITY: "SENBOKU",
      },
      {
        CITY: "SENDAI",
      },
      {
        CITY: "SENJU",
      },
      {
        CITY: "SENNAN",
      },
      {
        CITY: "SENRIYAMA",
      },
      {
        CITY: "SENZOKU",
      },
      {
        CITY: "SETA",
      },
      {
        CITY: "SETAGAYA-KU",
      },
      {
        CITY: "SETO",
      },
      {
        CITY: "SETTSU",
      },
      {
        CITY: "SHIBA-KAIGANDORI",
      },
      {
        CITY: "SHIBADAIMON",
      },
      {
        CITY: "SHIBATA",
      },
      {
        CITY: "SHIBAURA",
      },
      {
        CITY: "SHIBUKAWA",
      },
      {
        CITY: "SHICHIBANCHO",
      },
      {
        CITY: "SHIJONAWATE",
      },
      {
        CITY: "SHIKAMAKU-MIYA",
      },
      {
        CITY: "SHIKI",
      },
      {
        CITY: "SHIMA",
      },
      {
        CITY: "SHIMABARA",
      },
      {
        CITY: "SHIMADA",
      },
      {
        CITY: "SHIMIZUKOJI",
      },
      {
        CITY: "SHIMIZUMACHI",
      },
      {
        CITY: "SHIMO-IINO",
      },
      {
        CITY: "SHIMODA",
      },
      {
        CITY: "SHIMOGO",
      },
      {
        CITY: "SHIMOMEGURO",
      },
      {
        CITY: "SHIMONOSEKI",
      },
      {
        CITY: "SHIMOTODA",
      },
      {
        CITY: "SHIMOTSUCHO-KOMINAMI",
      },
      {
        CITY: "SHIMOTSUKE",
      },
      {
        CITY: "SHIN",
      },
      {
        CITY: "SHIN-SHIGAI",
      },
      {
        CITY: "SHINAGAWA",
      },
      {
        CITY: "SHINGU",
      },
      {
        CITY: "SHINJO",
      },
      {
        CITY: "SHINJUKU",
      },
      {
        CITY: "SHINKAWA",
      },
      {
        CITY: "SHINMACHI",
      },
      {
        CITY: "SHINSHIRO",
      },
      {
        CITY: "SHINSHUKU",
      },
      {
        CITY: "SHINSUKA",
      },
      {
        CITY: "SHIOBARU",
      },
      {
        CITY: "SHIOGAMA",
      },
      {
        CITY: "SHIOJIRI",
      },
      {
        CITY: "SHIOYA",
      },
      {
        CITY: "SHIRAKAWA-MACHI",
      },
      {
        CITY: "SHIRAOKA",
      },
      {
        CITY: "SHIRAYAMAMACHI",
      },
      {
        CITY: "SHIRETOKO",
      },
      {
        CITY: "SHIROI",
      },
      {
        CITY: "SHIROISHI",
      },
      {
        CITY: "SHIROKANE",
      },
      {
        CITY: "SHISUI",
      },
      {
        CITY: "SHITAMA",
      },
      {
        CITY: "SHIZUKUISHI",
      },
      {
        CITY: "SHIZUNAI-MISONO",
      },
      {
        CITY: "SHIZUOKA",
      },
      {
        CITY: "SHOBARA",
      },
      {
        CITY: "SHOBUSAWA",
      },
      {
        CITY: "SHOINMACHI-KOJI",
      },
      {
        CITY: "SHONAICHO",
      },
      {
        CITY: "SHOWA",
      },
      {
        CITY: "SHUCHI",
      },
      {
        CITY: "SODEGAURA",
      },
      {
        CITY: "SOGABE-MINAMIJO",
      },
      {
        CITY: "SOJA",
      },
      {
        CITY: "SOKA",
      },
      {
        CITY: "SONE",
      },
      {
        CITY: "SONOBE",
      },
      {
        CITY: "SUBASHIRI",
      },
      {
        CITY: "SUDA",
      },
      {
        CITY: "SUGINOMECHO",
      },
      {
        CITY: "SUGITO",
      },
      {
        CITY: "SUGIYAMA",
      },
      {
        CITY: "SUIGETSU",
      },
      {
        CITY: "SUIJO",
      },
      {
        CITY: "SUITA",
      },
      {
        CITY: "SUKAGAWA",
      },
      {
        CITY: "SUKUMO",
      },
      {
        CITY: "SUMIDACHO",
      },
      {
        CITY: "SUMIYOCHO-KAWAUCHI",
      },
      {
        CITY: "SUMIYOSHI",
      },
      {
        CITY: "SUMOTO",
      },
      {
        CITY: "SUSAKI",
      },
      {
        CITY: "SUSAMI",
      },
      {
        CITY: "SUSONO",
      },
      {
        CITY: "SUWA",
      },
      {
        CITY: "SUZAKA",
      },
      {
        CITY: "SUZUKA",
      },
      {
        CITY: "TACHIBANA",
      },
      {
        CITY: "TADOTSU",
      },
      {
        CITY: "TAGA",
      },
      {
        CITY: "TAGAJO-SHI",
      },
      {
        CITY: "TAGAWA",
      },
      {
        CITY: "TAHARA",
      },
      {
        CITY: "TAIJI",
      },
      {
        CITY: "TAIKI",
      },
      {
        CITY: "TAINOURA",
      },
      {
        CITY: "TAJIMI",
      },
      {
        CITY: "TAJIRI",
      },
      {
        CITY: "TAKAHAMA",
      },
      {
        CITY: "TAKAHASHI",
      },
      {
        CITY: "TAKAISHI",
      },
      {
        CITY: "TAKAIWA",
      },
      {
        CITY: "TAKAJO",
      },
      {
        CITY: "TAKAJOMACHI",
      },
      {
        CITY: "TAKAMATSU",
      },
      {
        CITY: "TAKAMORI",
      },
      {
        CITY: "TAKANAWA",
      },
      {
        CITY: "TAKAOKA",
      },
      {
        CITY: "TAKARA",
      },
      {
        CITY: "TAKARAZUKA",
      },
      {
        CITY: "TAKASAGOCHO-TAKASEMACHI",
      },
      {
        CITY: "TAKASAKI",
      },
      {
        CITY: "TAKASE",
      },
      {
        CITY: "TAKASHIMA",
      },
      {
        CITY: "TAKASU",
      },
      {
        CITY: "TAKATA",
      },
      {
        CITY: "TAKATSUKI",
      },
      {
        CITY: "TAKAYAMA",
      },
      {
        CITY: "TAKEDAMACHI",
      },
      {
        CITY: "TAKEMIYA",
      },
      {
        CITY: "TAKEO",
      },
      {
        CITY: "TAKETOYO",
      },
      {
        CITY: "TAKICHO-ODA",
      },
      {
        CITY: "TAKIKAWA",
      },
      {
        CITY: "TAKINOGAWA",
      },
      {
        CITY: "TAMA",
      },
      {
        CITY: "TAMACHI",
      },
      {
        CITY: "TAMAGAWA",
      },
      {
        CITY: "TAMANA",
      },
      {
        CITY: "TAMANO",
      },
      {
        CITY: "TAMASHIMA",
      },
      {
        CITY: "TAMATSUKURI",
      },
      {
        CITY: "TAMURA",
      },
      {
        CITY: "TANABE",
      },
      {
        CITY: "TANAKA",
      },
      {
        CITY: "TANIYAMA-CHUO",
      },
      {
        CITY: "TANUSHIMARUMACHI-SUGAWARA",
      },
      {
        CITY: "TASHIMA",
      },
      {
        CITY: "TASHIRO",
      },
      {
        CITY: "TATEBAYASHI",
      },
      {
        CITY: "TATEYAMA",
      },
      {
        CITY: "TATSUMI",
      },
      {
        CITY: "TATSUNO",
      },
      {
        CITY: "TATSUNOCHO-TOMINAGA",
      },
      {
        CITY: "TAWARAMOTO",
      },
      {
        CITY: "TEINE-KU",
      },
      {
        CITY: "TEMMA",
      },
      {
        CITY: "TENDO",
      },
      {
        CITY: "TENNOJICHO-KITA",
      },
      {
        CITY: "TENRI",
      },
      {
        CITY: "TOBA",
      },
      {
        CITY: "TOBATA",
      },
      {
        CITY: "TOBE",
      },
      {
        CITY: "TOBO",
      },
      {
        CITY: "TOCHIGI",
      },
      {
        CITY: "TOGANE",
      },
      {
        CITY: "TOGE",
      },
      {
        CITY: "TOGITSU",
      },
      {
        CITY: "TOKAI",
      },
      {
        CITY: "TOKAMACHI",
      },
      {
        CITY: "TOKI",
      },
      {
        CITY: "TOKIWA",
      },
      {
        CITY: "TOKIWAMACHI-TOKIWA",
      },
      {
        CITY: "TOKOCITY",
      },
      {
        CITY: "TOKOROZAWA",
      },
      {
        CITY: "TOKUSHIMA",
      },
      {
        CITY: "TOKUYAMA",
      },
      {
        CITY: "TOKYO",
      },
      {
        CITY: "TOMAKOMAI",
      },
      {
        CITY: "TOMIOKA",
      },
      {
        CITY: "TOMISATO",
      },
      {
        CITY: "TOMIYA",
      },
      {
        CITY: "TOMO",
      },
      {
        CITY: "TOMOBE",
      },
      {
        CITY: "TONAMI",
      },
      {
        CITY: "TONDABAYASHI",
      },
      {
        CITY: "TONE",
      },
      {
        CITY: "TONO",
      },
      {
        CITY: "TORANOMON",
      },
      {
        CITY: "TORIDE",
      },
      {
        CITY: "TOSHIMA MURA",
      },
      {
        CITY: "TOSU",
      },
      {
        CITY: "TOTSUKACHO",
      },
      {
        CITY: "TOTTORI-SHI",
      },
      {
        CITY: "TOWADA",
      },
      {
        CITY: "TOYAMA-SHI",
      },
      {
        CITY: "TOYO",
      },
      {
        CITY: "TOYOAKE",
      },
      {
        CITY: "TOYODA",
      },
      {
        CITY: "TOYOHASHI",
      },
      {
        CITY: "TOYOHIRA-KU",
      },
      {
        CITY: "TOYOKAWA",
      },
      {
        CITY: "TOYONAKA",
      },
      {
        CITY: "TOYONOMACHI-TOYONO",
      },
      {
        CITY: "TOYOOKA",
      },
      {
        CITY: "TOYOTA",
      },
      {
        CITY: "TSU",
      },
      {
        CITY: "TSUBAME",
      },
      {
        CITY: "TSUBATA",
      },
      {
        CITY: "TSUCHIARA",
      },
      {
        CITY: "TSUCHIURA",
      },
      {
        CITY: "TSUDANUMA",
      },
      {
        CITY: "TSUHAKO",
      },
      {
        CITY: "TSUKAWAKI",
      },
      {
        CITY: "TSUKAYAMA",
      },
      {
        CITY: "TSUKUBA",
      },
      {
        CITY: "TSUKUBAMIRAI",
      },
      {
        CITY: "TSUKUMIURA",
      },
      {
        CITY: "TSURU",
      },
      {
        CITY: "TSURUGA",
      },
      {
        CITY: "TSURUGASHIMA",
      },
      {
        CITY: "TSURUMI",
      },
      {
        CITY: "TSURUOKA",
      },
      {
        CITY: "TSUSHIMA",
      },
      {
        CITY: "TSUYAMA",
      },
      {
        CITY: "UBE",
      },
      {
        CITY: "UCHIMARU",
      },
      {
        CITY: "UEDA NAGANO",
      },
      {
        CITY: "UEKI",
      },
      {
        CITY: "UENO",
      },
      {
        CITY: "UENO-EBISUMACHI",
      },
      {
        CITY: "UENOHARA",
      },
      {
        CITY: "UJI",
      },
      {
        CITY: "UJIIE",
      },
      {
        CITY: "UKI",
      },
      {
        CITY: "UMEDA",
      },
      {
        CITY: "UONUMA",
      },
      {
        CITY: "UOZU",
      },
      {
        CITY: "URASOE",
      },
      {
        CITY: "URAYAMA",
      },
      {
        CITY: "URAYASU",
      },
      {
        CITY: "URESHINO",
      },
      {
        CITY: "URUMA",
      },
      {
        CITY: "USA",
      },
      {
        CITY: "USHIKU",
      },
      {
        CITY: "USUKI",
      },
      {
        CITY: "UTAZU",
      },
      {
        CITY: "UTSUNOMIYA",
      },
      {
        CITY: "UWAJIMA",
      },
      {
        CITY: "UWAYAMA",
      },
      {
        CITY: "WADAYAMA",
      },
      {
        CITY: "WAJIMA",
      },
      {
        CITY: "WAKAMATSUCHO",
      },
      {
        CITY: "WAKASA",
      },
      {
        CITY: "WAKAYAMA",
      },
      {
        CITY: "WAKICHO",
      },
      {
        CITY: "WAKIMACHI",
      },
      {
        CITY: "WAKKANAI",
      },
      {
        CITY: "WAKO",
      },
      {
        CITY: "WARABI-SHI",
      },
      {
        CITY: "WASEDA",
      },
      {
        CITY: "WATARI",
      },
      {
        CITY: "YABASE",
      },
      {
        CITY: "YABASEHONCHO",
      },
      {
        CITY: "YABU",
      },
      {
        CITY: "YABUKI",
      },
      {
        CITY: "YACHIMATA",
      },
      {
        CITY: "YACHIYO",
      },
      {
        CITY: "YAESE",
      },
      {
        CITY: "YAHAGI",
      },
      {
        CITY: "YAHATA",
      },
      {
        CITY: "YAITA",
      },
      {
        CITY: "YAIZU",
      },
      {
        CITY: "YAKAGE",
      },
      {
        CITY: "YAKUIN-HORIBATA",
      },
      {
        CITY: "YAKUMO",
      },
      {
        CITY: "YAMADA",
      },
      {
        CITY: "YAMADAYU",
      },
      {
        CITY: "YAMAGA",
      },
      {
        CITY: "YAMAGATA-SHI",
      },
      {
        CITY: "YAMAGUCHI",
      },
      {
        CITY: "YAMAKITA",
      },
      {
        CITY: "YAMANASHI",
      },
      {
        CITY: "YAMANO",
      },
      {
        CITY: "YAMASHITACHO",
      },
      {
        CITY: "YAMATO-TAKADA",
      },
      {
        CITY: "YAMATOMACHI",
      },
      {
        CITY: "YAMATOMACHI-MITSUAI",
      },
      {
        CITY: "YAMAUCHI",
      },
      {
        CITY: "YAMAWAKI",
      },
      {
        CITY: "YAMAZAKI",
      },
      {
        CITY: "YAMAZAKICHO-NAKABIROSE",
      },
      {
        CITY: "YANAGAWA",
      },
      {
        CITY: "YANAGI",
      },
      {
        CITY: "YANAI",
      },
      {
        CITY: "YANO",
      },
      {
        CITY: "YANOCHO-FUTATSUGI",
      },
      {
        CITY: "YAO",
      },
      {
        CITY: "YASHIMA",
      },
      {
        CITY: "YASHIO-SHI",
      },
      {
        CITY: "YASU-SHI",
      },
      {
        CITY: "YASUDA",
      },
      {
        CITY: "YASUGICHO",
      },
      {
        CITY: "YATSUSHIRO",
      },
      {
        CITY: "YAWATA",
      },
      {
        CITY: "YODOGAWA",
      },
      {
        CITY: "YOICHI",
      },
      {
        CITY: "YOKAICHIBA",
      },
      {
        CITY: "YOKKAICHI",
      },
      {
        CITY: "YOKOGAWA",
      },
      {
        CITY: "YOKOHAMA",
      },
      {
        CITY: "YOKOO",
      },
      {
        CITY: "YOKOSUKA",
      },
      {
        CITY: "YOKOTEMACHI",
      },
      {
        CITY: "YOMITAN SON",
      },
      {
        CITY: "YOMOGITA",
      },
      {
        CITY: "YONAGO",
      },
      {
        CITY: "YONEYAMACHO-NISHINO",
      },
      {
        CITY: "YONEZAWA",
      },
      {
        CITY: "YORII",
      },
      {
        CITY: "YORISHIMACHO",
      },
      {
        CITY: "YOSHIDA",
      },
      {
        CITY: "YOSHIDA-KASUGACHO",
      },
      {
        CITY: "YOSHII",
      },
      {
        CITY: "YOSHIKAWA",
      },
      {
        CITY: "YOSHIMI",
      },
      {
        CITY: "YOSHINO",
      },
      {
        CITY: "YOTSUKAIDO",
      },
      {
        CITY: "YOUKAICHI",
      },
      {
        CITY: "YUBARI",
      },
      {
        CITY: "YUFUTSU",
      },
      {
        CITY: "YUGAWARA",
      },
      {
        CITY: "YUGE",
      },
      {
        CITY: "YUKI",
      },
      {
        CITY: "YUKUHASHI",
      },
      {
        CITY: "YUNOSHIMA",
      },
      {
        CITY: "YURIHONJO",
      },
      {
        CITY: "YUTAKA",
      },
      {
        CITY: "YUZAWA",
      },
      {
        CITY: "ZAMA",
      },
      {
        CITY: "ZUSHI",
      },
    ],
  },
];
export default countryList;
export const countryCodeList =
[
  {
  "name": "Afghanistan",
  "dial_code": "+93",
  "code": "AF"
  },
  {
  "name": "Aland Islands",
  "dial_code": "+358",
  "code": "AX"
  },
  {
  "name": "Albania",
  "dial_code": "+355",
  "code": "AL"
  },
  {
  "name": "Algeria",
  "dial_code": "+213",
  "code": "DZ"
  },
  {
  "name": "AmericanSamoa",
  "dial_code": "+1684",
  "code": "AS"
  },
  {
  "name": "Andorra",
  "dial_code": "+376",
  "code": "AD"
  },
  {
  "name": "Angola",
  "dial_code": "+244",
  "code": "AO"
  },
  {
  "name": "Anguilla",
  "dial_code": "+1264",
  "code": "AI"
  },
  {
  "name": "Antarctica",
  "dial_code": "+672",
  "code": "AQ"
  },
  {
  "name": "Antigua and Barbuda",
  "dial_code": "+1268",
  "code": "AG"
  },
  {
  "name": "Argentina",
  "dial_code": "+54",
  "code": "AR"
  },
  {
  "name": "Armenia",
  "dial_code": "+374",
  "code": "AM"
  },
  {
  "name": "Aruba",
  "dial_code": "+297",
  "code": "AW"
  },
  {
  "name": "Australia",
  "dial_code": "+61",
  "code": "AU"
  },
  {
  "name": "Austria",
  "dial_code": "+43",
  "code": "AT"
  },
  {
  "name": "Azerbaijan",
  "dial_code": "+994",
  "code": "AZ"
  },
  {
  "name": "Bahamas",
  "dial_code": "+1242",
  "code": "BS"
  },
  {
  "name": "Bahrain",
  "dial_code": "+973",
  "code": "BH"
  },
  {
  "name": "Bangladesh",
  "dial_code": "+880",
  "code": "BD"
  },
  {
  "name": "Barbados",
  "dial_code": "+1246",
  "code": "BB"
  },
  {
  "name": "Belarus",
  "dial_code": "+375",
  "code": "BY"
  },
  {
  "name": "Belgium",
  "dial_code": "+32",
  "code": "BE"
  },
  {
  "name": "Belize",
  "dial_code": "+501",
  "code": "BZ"
  },
  {
  "name": "Benin",
  "dial_code": "+229",
  "code": "BJ"
  },
  {
  "name": "Bermuda",
  "dial_code": "+1441",
  "code": "BM"
  },
  {
  "name": "Bhutan",
  "dial_code": "+975",
  "code": "BT"
  },
  {
  "name": "Bolivia, Plurinational State of",
  "dial_code": "+591",
  "code": "BO"
  },
  {
  "name": "Bosnia and Herzegovina",
  "dial_code": "+387",
  "code": "BA"
  },
  {
  "name": "Botswana",
  "dial_code": "+267",
  "code": "BW"
  },
  {
  "name": "Brazil",
  "dial_code": "+55",
  "code": "BR"
  },
  {
  "name": "British Indian Ocean Territory",
  "dial_code": "+246",
  "code": "IO"
  },
  {
  "name": "Brunei Darussalam",
  "dial_code": "+673",
  "code": "BN"
  },
  {
  "name": "Bulgaria",
  "dial_code": "+359",
  "code": "BG"
  },
  {
  "name": "Burkina Faso",
  "dial_code": "+226",
  "code": "BF"
  },
  {
  "name": "Burundi",
  "dial_code": "+257",
  "code": "BI"
  },
  {
  "name": "Cambodia",
  "dial_code": "+855",
  "code": "KH"
  },
  {
  "name": "Cameroon",
  "dial_code": "+237",
  "code": "CM"
  },
  {
  "name": "Canada",
  "dial_code": "+1",
  "code": "CA"
  },
  {
  "name": "Cape Verde",
  "dial_code": "+238",
  "code": "CV"
  },
  {
  "name": "Cayman Islands",
  "dial_code": "+ 345",
  "code": "KY"
  },
  {
  "name": "Central African Republic",
  "dial_code": "+236",
  "code": "CF"
  },
  {
  "name": "Chad",
  "dial_code": "+235",
  "code": "TD"
  },
  {
  "name": "Chile",
  "dial_code": "+56",
  "code": "CL"
  },
  {
  "name": "China",
  "dial_code": "+86",
  "code": "CN"
  },
  {
  "name": "Christmas Island",
  "dial_code": "+61",
  "code": "CX"
  },
  {
  "name": "Cocos (Keeling) Islands",
  "dial_code": "+61",
  "code": "CC"
  },
  {
  "name": "Colombia",
  "dial_code": "+57",
  "code": "CO"
  },
  {
  "name": "Comoros",
  "dial_code": "+269",
  "code": "KM"
  },
  {
  "name": "Congo",
  "dial_code": "+242",
  "code": "CG"
  },
  {
  "name": "Congo, The Democratic Republic of the Congo",
  "dial_code": "+243",
  "code": "CD"
  },
  {
  "name": "Cook Islands",
  "dial_code": "+682",
  "code": "CK"
  },
  {
  "name": "Costa Rica",
  "dial_code": "+506",
  "code": "CR"
  },
  {
  "name": "Cote d'Ivoire",
  "dial_code": "+225",
  "code": "CI"
  },
  {
  "name": "Croatia",
  "dial_code": "+385",
  "code": "HR"
  },
  {
  "name": "Cuba",
  "dial_code": "+53",
  "code": "CU"
  },
  {
  "name": "Cyprus",
  "dial_code": "+357",
  "code": "CY"
  },
  {
  "name": "Czech Republic",
  "dial_code": "+420",
  "code": "CZ"
  },
  {
  "name": "Denmark",
  "dial_code": "+45",
  "code": "DK"
  },
  {
  "name": "Djibouti",
  "dial_code": "+253",
  "code": "DJ"
  },
  {
  "name": "Dominica",
  "dial_code": "+1767",
  "code": "DM"
  },
  {
  "name": "Dominican Republic",
  "dial_code": "+1849",
  "code": "DO"
  },
  {
  "name": "Ecuador",
  "dial_code": "+593",
  "code": "EC"
  },
  {
  "name": "Egypt",
  "dial_code": "+20",
  "code": "EG"
  },
  {
  "name": "El Salvador",
  "dial_code": "+503",
  "code": "SV"
  },
  {
  "name": "Equatorial Guinea",
  "dial_code": "+240",
  "code": "GQ"
  },
  {
  "name": "Eritrea",
  "dial_code": "+291",
  "code": "ER"
  },
  {
  "name": "Estonia",
  "dial_code": "+372",
  "code": "EE"
  },
  {
  "name": "Ethiopia",
  "dial_code": "+251",
  "code": "ET"
  },
  {
  "name": "Falkland Islands (Malvinas)",
  "dial_code": "+500",
  "code": "FK"
  },
  {
  "name": "Faroe Islands",
  "dial_code": "+298",
  "code": "FO"
  },
  {
  "name": "Fiji",
  "dial_code": "+679",
  "code": "FJ"
  },
  {
  "name": "Finland",
  "dial_code": "+358",
  "code": "FI"
  },
  {
  "name": "France",
  "dial_code": "+33",
  "code": "FR"
  },
  {
  "name": "French Guiana",
  "dial_code": "+594",
  "code": "GF"
  },
  {
  "name": "French Polynesia",
  "dial_code": "+689",
  "code": "PF"
  },
  {
  "name": "Gabon",
  "dial_code": "+241",
  "code": "GA"
  },
  {
  "name": "Gambia",
  "dial_code": "+220",
  "code": "GM"
  },
  {
  "name": "Georgia",
  "dial_code": "+995",
  "code": "GE"
  },
  {
  "name": "Germany",
  "dial_code": "+49",
  "code": "DE"
  },
  {
  "name": "Ghana",
  "dial_code": "+233",
  "code": "GH"
  },
  {
  "name": "Gibraltar",
  "dial_code": "+350",
  "code": "GI"
  },
  {
  "name": "Greece",
  "dial_code": "+30",
  "code": "GR"
  },
  {
  "name": "Greenland",
  "dial_code": "+299",
  "code": "GL"
  },
  {
  "name": "Grenada",
  "dial_code": "+1473",
  "code": "GD"
  },
  {
  "name": "Guadeloupe",
  "dial_code": "+590",
  "code": "GP"
  },
  {
  "name": "Guam",
  "dial_code": "+1671",
  "code": "GU"
  },
  {
  "name": "Guatemala",
  "dial_code": "+502",
  "code": "GT"
  },
  {
  "name": "Guernsey",
  "dial_code": "+44",
  "code": "GG"
  },
  {
  "name": "Guinea",
  "dial_code": "+224",
  "code": "GN"
  },
  {
  "name": "Guinea-Bissau",
  "dial_code": "+245",
  "code": "GW"
  },
  {
  "name": "Guyana",
  "dial_code": "+595",
  "code": "GY"
  },
  {
  "name": "Haiti",
  "dial_code": "+509",
  "code": "HT"
  },
  {
  "name": "Holy See (Vatican City State)",
  "dial_code": "+379",
  "code": "VA"
  },
  {
  "name": "Honduras",
  "dial_code": "+504",
  "code": "HN"
  },
  {
  "name": "Hong Kong",
  "dial_code": "+852",
  "code": "HK"
  },
  {
  "name": "Hungary",
  "dial_code": "+36",
  "code": "HU"
  },
  {
  "name": "Iceland",
  "dial_code": "+354",
  "code": "IS"
  },
  {
  "name": "India",
  "dial_code": "+91",
  "code": "IN"
  },
  {
  "name": "Indonesia",
  "dial_code": "+62",
  "code": "ID"
  },
  {
  "name": "Iran, Islamic Republic of Persian Gulf",
  "dial_code": "+98",
  "code": "IR"
  },
  {
  "name": "Iraq",
  "dial_code": "+964",
  "code": "IQ"
  },
  {
  "name": "Ireland",
  "dial_code": "+353",
  "code": "IE"
  },
  {
  "name": "Isle of Man",
  "dial_code": "+44",
  "code": "IM"
  },
  {
  "name": "Israel",
  "dial_code": "+972",
  "code": "IL"
  },
  {
  "name": "Italy",
  "dial_code": "+39",
  "code": "IT"
  },
  {
  "name": "Jamaica",
  "dial_code": "+1876",
  "code": "JM"
  },
  {
  "name": "Japan",
  "dial_code": "+81",
  "code": "JP"
  },
  {
  "name": "Jersey",
  "dial_code": "+44",
  "code": "JE"
  },
  {
  "name": "Jordan",
  "dial_code": "+962",
  "code": "JO"
  },
  {
  "name": "Kazakhstan",
  "dial_code": "+77",
  "code": "KZ"
  },
  {
  "name": "Kenya",
  "dial_code": "+254",
  "code": "KE"
  },
  {
  "name": "Kiribati",
  "dial_code": "+686",
  "code": "KI"
  },
  {
  "name": "Korea, Democratic People's Republic of Korea",
  "dial_code": "+850",
  "code": "KP"
  },
  {
  "name": "Korea, Republic of South Korea",
  "dial_code": "+82",
  "code": "KR"
  },
  {
  "name": "Kuwait",
  "dial_code": "+965",
  "code": "KW"
  },
  {
  "name": "Kyrgyzstan",
  "dial_code": "+996",
  "code": "KG"
  },
  {
  "name": "Laos",
  "dial_code": "+856",
  "code": "LA"
  },
  {
  "name": "Latvia",
  "dial_code": "+371",
  "code": "LV"
  },
  {
  "name": "Lebanon",
  "dial_code": "+961",
  "code": "LB"
  },
  {
  "name": "Lesotho",
  "dial_code": "+266",
  "code": "LS"
  },
  {
  "name": "Liberia",
  "dial_code": "+231",
  "code": "LR"
  },
  {
  "name": "Libyan Arab Jamahiriya",
  "dial_code": "+218",
  "code": "LY"
  },
  {
  "name": "Liechtenstein",
  "dial_code": "+423",
  "code": "LI"
  },
  {
  "name": "Lithuania",
  "dial_code": "+370",
  "code": "LT"
  },
  {
  "name": "Luxembourg",
  "dial_code": "+352",
  "code": "LU"
  },
  {
  "name": "Macao",
  "dial_code": "+853",
  "code": "MO"
  },
  {
  "name": "Macedonia",
  "dial_code": "+389",
  "code": "MK"
  },
  {
  "name": "Madagascar",
  "dial_code": "+261",
  "code": "MG"
  },
  {
  "name": "Malawi",
  "dial_code": "+265",
  "code": "MW"
  },
  {
  "name": "Malaysia",
  "dial_code": "+60",
  "code": "MY"
  },
  {
  "name": "Maldives",
  "dial_code": "+960",
  "code": "MV"
  },
  {
  "name": "Mali",
  "dial_code": "+223",
  "code": "ML"
  },
  {
  "name": "Malta",
  "dial_code": "+356",
  "code": "MT"
  },
  {
  "name": "Marshall Islands",
  "dial_code": "+692",
  "code": "MH"
  },
  {
  "name": "Martinique",
  "dial_code": "+596",
  "code": "MQ"
  },
  {
  "name": "Mauritania",
  "dial_code": "+222",
  "code": "MR"
  },
  {
  "name": "Mauritius",
  "dial_code": "+230",
  "code": "MU"
  },
  {
  "name": "Mayotte",
  "dial_code": "+262",
  "code": "YT"
  },
  {
  "name": "Mexico",
  "dial_code": "+52",
  "code": "MX"
  },
  {
  "name": "Micronesia, Federated States of Micronesia",
  "dial_code": "+691",
  "code": "FM"
  },
  {
  "name": "Moldova",
  "dial_code": "+373",
  "code": "MD"
  },
  {
  "name": "Monaco",
  "dial_code": "+377",
  "code": "MC"
  },
  {
  "name": "Mongolia",
  "dial_code": "+976",
  "code": "MN"
  },
  {
  "name": "Montenegro",
  "dial_code": "+382",
  "code": "ME"
  },
  {
  "name": "Montserrat",
  "dial_code": "+1664",
  "code": "MS"
  },
  {
  "name": "Morocco",
  "dial_code": "+212",
  "code": "MA"
  },
  {
  "name": "Mozambique",
  "dial_code": "+258",
  "code": "MZ"
  },
  {
  "name": "Myanmar",
  "dial_code": "+95",
  "code": "MM"
  },
  {
  "name": "Namibia",
  "dial_code": "+264",
  "code": "NA"
  },
  {
  "name": "Nauru",
  "dial_code": "+674",
  "code": "NR"
  },
  {
  "name": "Nepal",
  "dial_code": "+977",
  "code": "NP"
  },
  {
  "name": "Netherlands",
  "dial_code": "+31",
  "code": "NL"
  },
  {
  "name": "Netherlands Antilles",
  "dial_code": "+599",
  "code": "AN"
  },
  {
  "name": "New Caledonia",
  "dial_code": "+687",
  "code": "NC"
  },
  {
  "name": "New Zealand",
  "dial_code": "+64",
  "code": "NZ"
  },
  {
  "name": "Nicaragua",
  "dial_code": "+505",
  "code": "NI"
  },
  {
  "name": "Niger",
  "dial_code": "+227",
  "code": "NE"
  },
  {
  "name": "Nigeria",
  "dial_code": "+234",
  "code": "NG"
  },
  {
  "name": "Niue",
  "dial_code": "+683",
  "code": "NU"
  },
  {
  "name": "Norfolk Island",
  "dial_code": "+672",
  "code": "NF"
  },
  {
  "name": "Northern Mariana Islands",
  "dial_code": "+1670",
  "code": "MP"
  },
  {
  "name": "Norway",
  "dial_code": "+47",
  "code": "NO"
  },
  {
  "name": "Oman",
  "dial_code": "+968",
  "code": "OM"
  },
  {
  "name": "Pakistan",
  "dial_code": "+92",
  "code": "PK"
  },
  {
  "name": "Palau",
  "dial_code": "+680",
  "code": "PW"
  },
  {
  "name": "Palestinian Territory, Occupied",
  "dial_code": "+970",
  "code": "PS"
  },
  {
  "name": "Panama",
  "dial_code": "+507",
  "code": "PA"
  },
  {
  "name": "Papua New Guinea",
  "dial_code": "+675",
  "code": "PG"
  },
  {
  "name": "Paraguay",
  "dial_code": "+595",
  "code": "PY"
  },
  {
  "name": "Peru",
  "dial_code": "+51",
  "code": "PE"
  },
  {
  "name": "Philippines",
  "dial_code": "+63",
  "code": "PH"
  },
  {
  "name": "Pitcairn",
  "dial_code": "+872",
  "code": "PN"
  },
  {
  "name": "Poland",
  "dial_code": "+48",
  "code": "PL"
  },
  {
  "name": "Portugal",
  "dial_code": "+351",
  "code": "PT"
  },
  {
  "name": "Puerto Rico",
  "dial_code": "+1939",
  "code": "PR"
  },
  {
  "name": "Qatar",
  "dial_code": "+974",
  "code": "QA"
  },
  {
  "name": "Romania",
  "dial_code": "+40",
  "code": "RO"
  },
  {
  "name": "Russia",
  "dial_code": "+7",
  "code": "RU"
  },
  {
  "name": "Rwanda",
  "dial_code": "+250",
  "code": "RW"
  },
  {
  "name": "Reunion",
  "dial_code": "+262",
  "code": "RE"
  },
  {
  "name": "Saint Barthelemy",
  "dial_code": "+590",
  "code": "BL"
  },
  {
  "name": "Saint Helena, Ascension and Tristan Da Cunha",
  "dial_code": "+290",
  "code": "SH"
  },
  {
  "name": "Saint Kitts and Nevis",
  "dial_code": "+1869",
  "code": "KN"
  },
  {
  "name": "Saint Lucia",
  "dial_code": "+1758",
  "code": "LC"
  },
  {
  "name": "Saint Martin",
  "dial_code": "+590",
  "code": "MF"
  },
  {
  "name": "Saint Pierre and Miquelon",
  "dial_code": "+508",
  "code": "PM"
  },
  {
  "name": "Saint Vincent and the Grenadines",
  "dial_code": "+1784",
  "code": "VC"
  },
  {
  "name": "Samoa",
  "dial_code": "+685",
  "code": "WS"
  },
  {
  "name": "San Marino",
  "dial_code": "+378",
  "code": "SM"
  },
  {
  "name": "Sao Tome and Principe",
  "dial_code": "+239",
  "code": "ST"
  },
  {
  "name": "Saudi Arabia",
  "dial_code": "+966",
  "code": "SA"
  },
  {
  "name": "Senegal",
  "dial_code": "+221",
  "code": "SN"
  },
  {
  "name": "Serbia",
  "dial_code": "+381",
  "code": "RS"
  },
  {
  "name": "Seychelles",
  "dial_code": "+248",
  "code": "SC"
  },
  {
  "name": "Sierra Leone",
  "dial_code": "+232",
  "code": "SL"
  },
  {
  "name": "Singapore",
  "dial_code": "+65",
  "code": "SG"
  },
  {
  "name": "Slovakia",
  "dial_code": "+421",
  "code": "SK"
  },
  {
  "name": "Slovenia",
  "dial_code": "+386",
  "code": "SI"
  },
  {
  "name": "Solomon Islands",
  "dial_code": "+677",
  "code": "SB"
  },
  {
  "name": "Somalia",
  "dial_code": "+252",
  "code": "SO"
  },
  {
  "name": "South Africa",
  "dial_code": "+27",
  "code": "ZA"
  },
  {
  "name": "South Sudan",
  "dial_code": "+211",
  "code": "SS"
  },
  {
  "name": "South Georgia and the South Sandwich Islands",
  "dial_code": "+500",
  "code": "GS"
  },
  {
  "name": "Spain",
  "dial_code": "+34",
  "code": "ES"
  },
  {
  "name": "Sri Lanka",
  "dial_code": "+94",
  "code": "LK"
  },
  {
  "name": "Sudan",
  "dial_code": "+249",
  "code": "SD"
  },
  {
  "name": "Suriname",
  "dial_code": "+597",
  "code": "SR"
  },
  {
  "name": "Svalbard and Jan Mayen",
  "dial_code": "+47",
  "code": "SJ"
  },
  {
  "name": "Swaziland",
  "dial_code": "+268",
  "code": "SZ"
  },
  {
  "name": "Sweden",
  "dial_code": "+46",
  "code": "SE"
  },
  {
  "name": "Switzerland",
  "dial_code": "+41",
  "code": "CH"
  },
  {
  "name": "Syrian Arab Republic",
  "dial_code": "+963",
  "code": "SY"
  },
  {
  "name": "Taiwan",
  "dial_code": "+886",
  "code": "TW"
  },
  {
  "name": "Tajikistan",
  "dial_code": "+992",
  "code": "TJ"
  },
  {
  "name": "Tanzania, United Republic of Tanzania",
  "dial_code": "+255",
  "code": "TZ"
  },
  {
  "name": "Thailand",
  "dial_code": "+66",
  "code": "TH"
  },
  {
  "name": "Timor-Leste",
  "dial_code": "+670",
  "code": "TL"
  },
  {
  "name": "Togo",
  "dial_code": "+228",
  "code": "TG"
  },
  {
  "name": "Tokelau",
  "dial_code": "+690",
  "code": "TK"
  },
  {
  "name": "Tonga",
  "dial_code": "+676",
  "code": "TO"
  },
  {
  "name": "Trinidad and Tobago",
  "dial_code": "+1868",
  "code": "TT"
  },
  {
  "name": "Tunisia",
  "dial_code": "+216",
  "code": "TN"
  },
  {
  "name": "Turkey",
  "dial_code": "+90",
  "code": "TR"
  },
  {
  "name": "Turkmenistan",
  "dial_code": "+993",
  "code": "TM"
  },
  {
  "name": "Turks and Caicos Islands",
  "dial_code": "+1649",
  "code": "TC"
  },
  {
  "name": "Tuvalu",
  "dial_code": "+688",
  "code": "TV"
  },
  {
  "name": "Uganda",
  "dial_code": "+256",
  "code": "UG"
  },
  {
  "name": "Ukraine",
  "dial_code": "+380",
  "code": "UA"
  },
  {
  "name": "United Arab Emirates",
  "dial_code": "+971",
  "code": "AE"
  },
  {
  "name": "United Kingdom",
  "dial_code": "+44",
  "code": "GB"
  },
  {
  "name": "United States",
  "dial_code": "+1",
  "code": "US"
  },
  {
  "name": "Uruguay",
  "dial_code": "+598",
  "code": "UY"
  },
  {
  "name": "Uzbekistan",
  "dial_code": "+998",
  "code": "UZ"
  },
  {
  "name": "Vanuatu",
  "dial_code": "+678",
  "code": "VU"
  },
  {
  "name": "Venezuela, Bolivarian Republic of Venezuela",
  "dial_code": "+58",
  "code": "VE"
  },
  {
  "name": "Vietnam",
  "dial_code": "+84",
  "code": "VN"
  },
  {
  "name": "Virgin Islands, British",
  "dial_code": "+1284",
  "code": "VG"
  },
  {
  "name": "Virgin Islands, U.S.",
  "dial_code": "+1340",
  "code": "VI"
  },
  {
  "name": "Wallis and Futuna",
  "dial_code": "+681",
  "code": "WF"
  },
  {
  "name": "Yemen",
  "dial_code": "+967",
  "code": "YE"
  },
  {
  "name": "Zambia",
  "dial_code": "+260",
  "code": "ZM"
  },
  {
  "name": "Zimbabwe",
  "dial_code": "+263",
  "code": "ZW"
  }
  ]

