import React from "react";
import "./spinner.css";
function LoadingSpinner() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div className="spinner-container">
        Loading...
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
}

export default LoadingSpinner;
