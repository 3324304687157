/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Table, Spin, Form, Select,Pagination } from "antd";

import axios from "axios";
import { AlertBaseUrl } from "../../common/BaseUrl";
import FilterForm from "../../services/headerFilter/FilterForm";

const MaintainanceLog = ({newTableHeight}) => {
  const deviceType = window.localStorage.getItem("devicetype"); 
  const accessToken = window.localStorage.getItem("accessToken");
  const [maintenanceLogsData, setMaintenanceLogData] = useState();
   const [pageNo, setPageNo] = useState();
  const [pageSize, setPageSize] = useState();
  const [paginationCount, setPagiationCount] = useState();
  const [elevatorName, SetElevatorName] = useState();
  const [filterHeaderapiLoader, setFilterHeaderapiLoader]=useState(false)

  useEffect(() => {
    axios
      .post(
       `${AlertBaseUrl}/maintenanceLogs`,
        {
          STATUS: ["COMPLETED", "CANCELLED"],
          ELEVATOR_NAME: elevatorName,
          page: pageNo ? pageNo : 1,
          limit: pageSize ? pageSize : 10,
        },

        { headers: { ["x-access-token"]: accessToken } }
      )
      .then((response) => {
        setMaintenanceLogData(response?.data?.data?.result);
        setPagiationCount(Response?.data?.data?.count);
      });
  }, [filterHeaderapiLoader, pageNo,pageSize]);
  const columns = [
    {
      title: `${deviceType}`,
      dataIndex: "ELEVATOR_NAME",
    },
    {
      title: "Due Date",
      dataIndex: "DUE_DATE",
      render: (DUE_DATE) => {
        let time = DUE_DATE?.slice(0, 10);
        return time;
      },
    },
    {
      title: "Completed Date",
      dataIndex: "COMPLETED_ON",
      render: (COMPLETED_ON) => {
        let time = COMPLETED_ON?.slice(0, 10);
        return time;
      },
    },
    {
      title: "Status",
      dataIndex: "STATUS",
    },
    {
      title: "Comments",
      dataIndex: "COMMENTS",
    },
  ];
  return (
    <div>
    <div style={{ marginTop:"-25px"}}>
      <FilterForm
        filtertype="PeriodicLog"
        setFilterHeaderapiLoader={setFilterHeaderapiLoader}
        filterHeaderapiLoader={filterHeaderapiLoader}
        SetElevatorName={SetElevatorName}
      />
      </div>
      <Table
      className="MainTable"
      style={{marginTop:"-20px"}}
       pagination={false}
       rowKey="id"
        columns={columns}
        dataSource={maintenanceLogsData}
        scroll={{ y: newTableHeight }}
        size="small"
        loading={{
          indicator: (
            <div>
              <Spin />
            </div>
          ),
          spinning: !maintenanceLogsData,
        }}
      />
      <div style={{ float: "right" }}>
      
      <Pagination
        defaultCurrent={1}
        total={paginationCount}
        onChange={(page, pageSize) => {
          setPageNo(page);
          setPageSize(pageSize);
        }}
      />
    </div>
    </div>
  );
};

export default MaintainanceLog;
