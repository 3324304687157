/* eslint-disable react/prop-types */
import React from "react";
import { Column } from "@ant-design/plots";
import moment from "moment";

const EnergyChart = ({ chartData, called, selectInterval }) => {
  let data = [];
  const getSundayFromWeekNum = (weekNum, year) => {
    var sunday = new Date(year, 0, 1 + weekNum * 7 - 7);
    if (sunday.getDay() !== 0) {
      sunday.setDate(sunday.getDate() - 1);
    }
    return sunday;
  };

  if (selectInterval === 1) {
    if (called === "energyConsumption") {
      for (let i = 0; i < 24; i++) {
        if (chartData?.[i]) {
          data.push({
            hours: i,
            // energy: parseFloat(chartData?.[i]).toFixed(2),
            energy:chartData?.[i]
          });
        } else {
          data.push({ hours: i, energy: 0 });
        }
        
      }
    } else {
      for (let i = 0; i < 24; i++) {
        if (chartData?.[0]?.ENERGY?.HOURLY?.[i]) {
          data.push({
            hours: i,
            energy:
              Math.round(
                chartData?.[0]?.ENERGY?.HOURLY?.[i]
                  ?.TOTAL_HOURLY_ENERGY * 100000
              ) / 100,
          });
        } else {
          data.push({ hours: i, energy: 0 });
        }
      }
    }
  }

  if (selectInterval < 30 && selectInterval > 1) {
    if (called === "energyConsumption") {
      for (let obj in chartData) {
        
        data.push({
          hours: obj?.slice(0, obj?.indexOf("T")),
          //  energy: parseFloat(chartData?.[obj])?.toFixed(2) || 0,
          energy:chartData?.[obj]
        });
      }
    } else {
      for (let obj of chartData) {
        data.push({
          hours: obj?.DEVICE_TIME?.slice(0, obj?.DEVICE_TIME.indexOf("T")),
          energy: Math.round(obj?.ENERGY?.TOTAL_DAY_ENERGY * 100000) / 100 || 0,
        });
      }
    }
  }
  if (selectInterval < 90 && selectInterval >= 30) {
    if (called === "energyConsumption") {
      let weekEnergyData = [];
      
      for (let key in chartData) {
       
        weekEnergyData.push({
          YEAR: chartData?.[key]?.["YEAR"],
          WEEK: chartData?.[key]?.["WEEk"],
          ENERGY: chartData?.[key]?.["value"] || 0,
        });
      }
      weekEnergyData?.sort((a, b) => {
        a.YEAR = String(a.YEAR);
        b.YEAR = String(b.YEAR);
        return a.YEAR.localeCompare(b.YEAR) || a.WEEK - b.WEEK;
      });
     
      for (let obj of weekEnergyData) {
        const days = obj?.WEEK;
        const date = obj?.YEAR;
        let weekDate = getSundayFromWeekNum(days, date);
        data.push({
          hours: `${obj?.WEEK}-(${weekDate?.toLocaleDateString("zh-Hans-CN")})`,
          // energy: parseFloat((obj?.["ENERGY"]))?.toFixed(2) || 0,
          energy:obj?.["ENERGY"]
        });
      }
    } else {
      chartData?.sort((a, b) => {
        a.YEAR = String(a.YEAR);
        b.YEAR = String(b.YEAR);
        return a.YEAR.localeCompare(b.YEAR) || a.MONTH - b.MONTH;
      });
      for (let obj of chartData) {
        // const days = 4 + 7 * (obj?.WEEK - 1);
        // const date = new Date(obj?.YEAR, 0, days);
        const days = obj?.WEEK;
        const date = obj?.YEAR;
        let weekDate = getSundayFromWeekNum(days, date);
        data.push({
          hours: `${obj?.WEEK}-(${weekDate?.toLocaleDateString("zh-Hans-CN")})`,
          energy: Math.round(obj?.ENERGY * 100000) / 100 || 0,
        });
      }
    }
  }
  if (selectInterval >= 90) {
    if (called === "energyConsumption") {
      let monthEnergyData = [];
      
      for (let key in chartData) {
        monthEnergyData.push({
          YEAR: chartData?.[key]?.["YEAR"],
          MONTH: chartData?.[key]?.["MONTH"],
          ENERGY: chartData?.[key]?.["value"] || 0,
        });
      }
      
      monthEnergyData?.sort((a, b) => {
        a.YEAR = String(a.YEAR);
        b.YEAR = String(b.YEAR);
        return a.YEAR.localeCompare(b.YEAR) || a.MONTH - b.MONTH;
      });
      for (let obj of monthEnergyData) {
        data.push({
          hours: `${moment()
            .month(obj?.MONTH - 1)
            .format("MMM")} ${obj?.YEAR}`,
          // energy: parseFloat((obj?.ENERGY))?.toFixed(2) || 0,
          energy:obj?.ENERGY
        });
      }
    } else {
      chartData?.sort((a, b) => {
        a.YEAR = String(a.YEAR);
        b.YEAR = String(b.YEAR);
        return a.YEAR.localeCompare(b.YEAR) || a.MONTH - b.MONTH;
      });
      for (let obj of chartData) {
        data.push({
          hours: `${moment()
            .month(obj?.MONTH - 1)
            .format("MMM")} ${obj?.YEAR}`,
          energy: Math.round(obj?.ENERGY * 100000) / 100 || 0,
        });
      }
    }
  }

  const config = {
    data,
    xField: "hours",
    yField: "energy",
    columnWidthRatio: 0.5,
    appendPadding: [10],

    label: {
      position: "top",
      offsetY: 12,
    },


    meta: {
      hours: {
        alias: "category",
      },
      energy: {
        alias: "Energy Consumption",
      },
    },
    yAxis: {
      min: 0,
      title: {
        display: true,
        text: called === "energyConsumption" && "Energy Consumption(kWh)" || "Energy Consumption(Wh)",
      },
    },
    xAxis: {
      title: {
        display: true,
        text:
          selectInterval === 1
            ? "Hour (0-23)"
            : selectInterval <= 7
            ? "Date"
            : selectInterval >= 90
            ? "Months"
            : selectInterval >= 30
            ? "Weeks"
            : selectInterval < 30
            ? "Daily"
            : "Date",
      },
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };
  return (
    <div>
      <Column {...config} height={200} />
    </div>
  );
};
export default EnergyChart;
