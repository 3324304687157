/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Table,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Spin,
  Select,
  Button,
  notification,
  Pagination,
  Tooltip
} from "antd";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import { AlertBaseUrl } from "../../common/BaseUrl";
import FilterForm from "../../services/headerFilter/FilterForm";
const OverDue = ({ userAccess, newTableHeight }) => {
  const deviceType = window.localStorage.getItem("devicetype");
  const [form] = Form.useForm();
  const [overdueModal, setOverDueModal] = useState(false);
  const [overDueLoader, setOverDueLoader] = useState(false);
  const [overdueData, setOverdueData] = useState();
  const [overDueData, setOverDueData] = useState();
  const [pageNo, setPageNo] = useState();
  const [elevatorName, SetElevatorName] = useState();
  const [statusData, SetStatusData] = useState();
  const [filterHeaderapiLoader, setFilterHeaderapiLoader] = useState(false);
  const [pageSize, setPageSize] = useState();
  const [paginationCount, setPagiationCount] = useState();
  const accessToken = window.localStorage.getItem("accessToken");
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const [shouldDisableSubmit, setShouldDisableSubmit] = useState(false);
  const handleStatusChange = (value) => {
    if(value !== "OPEN"){
      setShouldDisableSubmit(false);
    }
  };
  useEffect(() => {
    axios
      .post(
        `${AlertBaseUrl}/maintenanceLogs`,
        {
          STATUS:
            statusData === "PENDING"
              ? ["PENDING"]
              : statusData === "OPEN"
              ? ["OPEN"]
              : statusData === "IN PROGRESS"
              ? ["INPROGRESS"]
              : ["PENDING", "OPEN", "INPROGRESS"],
          DUE_STATUS: "OVERDUE",
          ELEVATOR_NAME: elevatorName,
          page: pageNo ? pageNo : 1,
          limit: pageSize ? pageSize : 10,
        },

        { headers: { ["x-access-token"]: accessToken } }
      )
      .then((response) => {
        setOverDueData(response?.data?.data?.result);
        setPagiationCount(Response?.data?.data?.count);
      });
  }, [overDueLoader, pageNo, pageSize, filterHeaderapiLoader]);
  const columns = [
    {
      title: `${deviceType}`,
      dataIndex: "ELEVATOR_NAME",
    },
    {
      title: "Due Date",
      dataIndex: "DUE_DATE",
      render: (DUE_DATE) => {
        let time = DUE_DATE?.slice(0, 10);
        return time;
      },
    },
    {
      title: "Status",
      dataIndex: "STATUS",
    },
    {
      title: "Date updated",
      dataIndex: "CREATED_ON",
      render: (CREATED_ON) => {
        let time = CREATED_ON?.slice(0, 10);
        return time;
      },
    },
    {
      title: "Comments",
      dataIndex: "COMMENTS",
    },
    !userAccess
      ? {
          title: "Edit",
          dataIndex: "edit",
          width: "60px",
          render: (text, record, index) => (
            <div style={{ textAlign: "center" }}>
              <Button
                style={{ borderRadius: "10px" }}
                onClick={() => {
                  setOverDueModal(true);
                  setOverdueData(record);
                }}
              >
                <EditOutlined className="configurationEditIcon" />
              </Button>
            </div>
          ),
        }
      : {
          width: 0,
        },
  ];

  useEffect(() => {
    if (overdueData) {
      if(overdueData?.STATUS ==="OPEN"){
        setShouldDisableSubmit(true)
      }
      form.setFieldsValue({
        elevator: overdueData?.ELEVATOR_NAME,
        dueDate: overdueData?.DUE_DATE?.slice(0, 10),
        status: overdueData?.STATUS,
        updatedDate: overdueData?.COMPLETED_ON?.slice(0, 10),
        comment: overdueData?.COMMENTS,
      });
    }
  }, [overdueData]);
  const handleOk = () => {
    setTimeout(() => {
      setOverDueModal(false);
    }, 2000);
  };
  const onFinish = (values) => {
    axios
      .post(
        `${AlertBaseUrl}/maintenanceLogs`,
        {
          EDIT: {
            STATUS: values?.status,
            COMMENTS: values?.comment,
            ID: overdueData?._id,
            COMPLETED_ON:
              values?.status === "COMPLETED"
                ? moment().format("YYYY-MM-DDTHH:mm:ss")
                : undefined,
            DEVICE_ID: overdueData?.DEVICE_ID,
            TENANT_ID: userProfile?.["custom:tenant_id"],
            PARENT_ID: userProfile?.["custom:parent_id"],
          },
        },

        { headers: { ["x-access-token"]: accessToken } }
      )
      .then((response) => {
        setOverDueLoader(!overDueLoader);
        setOverDueModal(false);
        notification.success({
          description: "Record Edited",
        });
      });
  };

  return (
    <div>
      <div style={{ marginTop: "-25px" }}>
        <FilterForm
          filtertype="Periodiupcomming"
          setFilterHeaderapiLoader={setFilterHeaderapiLoader}
          filterHeaderapiLoader={filterHeaderapiLoader}
          SetElevatorName={SetElevatorName}
          SetStatusData={SetStatusData}
        />
      </div>
      <Table
        className="MainTable"
        style={{ marginTop: "-20px" }}
        rowKey="id"
        pagination={false}
        columns={columns}
        dataSource={overDueData}
        scroll={{ y: newTableHeight }}
        size="small"
        loading={{
          indicator: (
            <div>
              <Spin />
            </div>
          ),
          spinning: !overDueData,
        }}
      />
      <div style={{ float: "right" }}>
        <Pagination
          defaultCurrent={1}
          total={paginationCount}
          onChange={(page, pageSize) => {
            setPageNo(page);
            setPageSize(pageSize);
          }}
        />
      </div>
      <Modal
        open={overdueModal}
        onOk={handleOk}
        footer={null}
        onCancel={() => setOverDueModal(false)}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          style={{ backgroundColor: "#f3f3f3", padding: "10px" }}
        >
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item label="Elevator" name="elevator">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Due Date" name="dueDate">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Status" name="status">
                <Select
                  placeholder="Select Status"
                  options={[
                    {
                      value: "PENDING",
                      label: "PENDING",
                    },
                    {
                      value: "INPROGRESS",
                      label: "INPROGRESS",
                    },
                    {
                      value: "COMPLETED",
                      label: "COMPLETED",
                    },
                  ]}
                  onChange={handleStatusChange}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <label className="formLabelStyle">Comment</label>
              <Tooltip placement="right"  title="Please do not enter any personal information.">&nbsp;<InfoCircleOutlined /></Tooltip>
              <Form.Item name="comment">
                <Input disabled={shouldDisableSubmit} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "10px" }}
              disabled={shouldDisableSubmit}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default OverDue;
