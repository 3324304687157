/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import "../Styles.css";
import { Button, InputNumber, Col, Form, Input, Row, Select, notification } from "antd";
import { onboardingBaseUrl } from "../../../common/BaseUrl";
import axios from "axios";
const AddEditElevatorForm = ({
  setAddDeviceModal,
  setEditDeviceModal,
  userData,
  setElevatorListLoader,
  elevatorListLoader,
  editElevator,
  uniqueId,
}) => {
  const [form] = Form.useForm();
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const accessToken = window.localStorage.getItem("accessToken");
  const deviceType = window.localStorage.getItem("devicetype"); 
  const modalClose =()=>{
    setAddDeviceModal(false);
    setEditDeviceModal(false);
  }
  useEffect(() => {
    if (editElevator) {
      form.setFieldsValue({
        hardwareId: editElevator?.HARDWARE_ID,
        deviceNo: editElevator?.DEVICE_ID,
        storeIncharge: editElevator?.STORE_INCHARGE,
        maintenanceOperator: editElevator?.hardwareId,
        deviceName: editElevator?.DEVICE_NAME,
        noOfFloor: editElevator?.NO_OF_FLOOR,
        passangerCapacity: editElevator?.PASSENGER_CAPACITY,
        elevatorType: editElevator?.ELEVATOR_TYPE,
        elevatorMake: editElevator?.ELEVATOR_MAKE,
        elevatorModal: editElevator?.ELEVATOR_MODAL,
        energyConsumption: editElevator?.TOTAL_ENERGY_CONSUMPTION,
      });
    }
  }, [userData, editElevator]);
  const onFinish = (values) => {
    const addNewElevatorParams = {
      TENANT_ID: userProfile?.["custom:tenant_id"],
      STORE_NAME:userData?.STORE_NAME,
      HARDWARE_ID: values?.hardwareId,
      // DEVICE_ID: values?.deviceNo,
      DEVICE_NAME: values?.deviceName,
      NO_OF_FLOOR: values?.noOfFloor,
      PASSENGER_CAPACITY: values?.passangerCapacity ? values?.passangerCapacity : 0,
      ELEVATOR_TYPE: values?.elevatorType ? values?.elevatorType :"OTHER EQUIPMENTS",
      ELEVATOR_MAKE: values?.elevatorMake,
      ELEVATOR_MODAL: values?.elevatorModal,
      TOTAL_ENERGY_CONSUMPTION: values?.energyConsumption,
    };
    if (editElevator) {
      const formdata = form.getFieldsValue();
      const addEditElevatorParams = {
        HARDWARE_ID: editElevator?.HARDWARE_ID,
        DEVICE_ID: editElevator?.DEVICE_ID,
        DEVICE_NAME: formdata?.deviceName,
        NO_OF_FLOOR: formdata?.noOfFloor,
        PASSENGER_CAPACITY: formdata?.passangerCapacity ? formdata?.passangerCapacity:0,
        ELEVATOR_TYPE: formdata?.elevatorType ? formdata?.elevatorType:"OTHER EQUIPMENTS",
        ELEVATOR_MAKE: formdata?.elevatorMake,
        ELEVATOR_MODAL: formdata?.elevatorModal,
        TOTAL_ENERGY_CONSUMPTION: formdata?.energyConsumption,
      };
      axios
        .post(
          `${onboardingBaseUrl}/updateElevator/${uniqueId}`,
          addEditElevatorParams,
          {
            headers: { ["x-access-token"]: accessToken },
          }
        )
        .then((response) => {

          setElevatorListLoader(!elevatorListLoader)
          setAddDeviceModal(false);
          form.resetFields();
          notification.success({
           description: response?.data?.data?.message,
          });
        });
    } else {
      axios
        .post(
          `${onboardingBaseUrl}/addElevator/${userData?._id}`,
          addNewElevatorParams,
          {
            headers: { ["x-access-token"]: accessToken },
          }
        )
        .then((response) => {
          setAddDeviceModal(false);
          setElevatorListLoader(!elevatorListLoader)
          notification.success({
          description: response?.data?.data?.message,
          });
          form.resetFields();
        });
    }
  };

  return (
    <div style={{ backgroundColor: "rgb(230, 242, 252)", padding: "2px 20px" }}>
      <Form
        name="basic"
        form={form}
        labelCol={{
          span: 8,
        }}
        onFinish={onFinish}
        autoComplete="off"
        className="backgroundStyle"
      >
        <Row gutter={[12, 8]}>
          <Col
            className="regestration"
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div className="formInputStyle">
              <label className="formLabelStyle">Hardware ID</label>
              <Form.Item
                name="hardwareId"
                rules={[
                  {
                    required: true,
                    message: "Enter Hardware ID",
                  },
                ]}
              >
                <Input
                  disabled={editElevator?.HARDWARE_ID ? true : false}
                  className="formInput"
                />
              </Form.Item>
            </div>
          </Col>
          {editElevator?.DEVICE_ID?<Col
            className="regestration"
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div className="formInputStyle">
              <label className="formLabelStyle">Device Serial Number</label>
              <Form.Item name="deviceNo">
                <Input
                  disabled={editElevator?.HARDWARE_ID ? true : false}
                  className="formInput"
                 
                />
              </Form.Item>
            </div>
          </Col>:<></>}
          <Col
            className="regestration"
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div className="formInputStyle">
              <label className="formLabelStyle">{deviceType} Name</label>
              <Form.Item
                name="deviceName"
                rules={[
                  {
                    required: true,
                    message: "Enter Elevator Name",
                  },
                ]}
              >
                <Input className="formInput" />
              </Form.Item>
            </div>
          </Col>
         {deviceType === "Elevator" && <>
         <Col
            className="regestration"
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div className="formInputStyle">
              <label className="formLabelStyle">Passenger Capacity</label>
              <Form.Item
                name="passangerCapacity"
                rules={[
                  {
                    required: true,
                    message: "Enter Passenger Capacity",
                  },
                ]}
              >
                <InputNumber className="formInput" />
              </Form.Item>
            </div>
          </Col>
          <Col
            className="regestration"
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div className="formInputStyle">
              <label className="formLabelStyle">{deviceType} Type</label>
              <Form.Item
                name="elevatorType"
                rules={[
                  {
                    required: true,
                    message: `Select ${deviceType} Type`,
                  },
                ]}
              >
                <Select
                  className="formInput"
                  // placeholder="Elevator Type"
                  options={[
                    { value: "ESCALATOR", label: "Escalator" },
                    { value: "ELEVATOR", label: "Elevator" },
                  ]}
                />
              </Form.Item>
            </div>
          </Col>
          </> }
    
          <Col
            className="regestration"
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div className="formInputStyle">
              <label className="formLabelStyle">{deviceType} Make</label>
              <Form.Item
                name="elevatorMake"
                rules={[
                  {
                    required: true,
                    message: `Enter ${deviceType} Make`,
                  },
                ]}
              >
                <Input className="formInput" />
              </Form.Item>
            </div>
          </Col>
          <Col
            className="regestration"
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div className="formInputStyle">
              <label className="formLabelStyle">{deviceType} Model</label>
              <Form.Item
                name="elevatorModal"
                rules={[
                  {
                    required: true,
                    message: `Enter ${deviceType} Model`,
                  },
                ]}
              >
                <Input className="formInput" />
              </Form.Item>
            </div>
          </Col>
          <Col
            className="regestration"
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div className="formInputStyle">
              <label className="formLabelStyle">
                Total Energy Consumption(kWh)
              </label>
              <Form.Item
                name="energyConsumption"
                rules={[
                  {
                    required: true,
                    message: "Enter Energy Consumption(kWh)",
                  },
                ]}
              >
                <InputNumber className="formInput" />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Form.Item
          wrapperCol={{
            span: 5,
            offset: 19,
          }}
        >
          <Button
            type="primary"
            htmlType="cancel"
            style={{ color: "white", marginTop: "40px" }}
          >
            Submit
          </Button>
          <Button
            type="primary"
            style={{ margin: "10px", color: "white" }}
            onClick={ modalClose}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddEditElevatorForm;
