/* eslint-disable react/prop-types */
import React from "react";
import { Card, Col, Row } from "antd";
import { Column } from "@ant-design/plots";
import moment from "moment";

const FloorChart = ({ floorData, selectInterval, called}) => {
  
  
  let floorCount = 0;
  let tripCount = 0;
  let data = [];
  const getSundayFromWeekNum =(weekNum, year)=>{
    var sunday = new Date(year, 0, ((1 + weekNum * 7)-7));
    if (sunday.getDay() !== 0) {
      sunday.setDate(sunday.getDate() - 1);
    }
    return sunday;
  }
  if (called === "reportsFloor") {
    if (selectInterval === 1) {
      for (let i = 0; i < 24; i++) {
        if (floorData?.floorDetails?.[i]) {
          data.push({
            hours: i,
            floors: floorData?.floorDetails?.[i],
            type: "Floors",
          });
        } else {
          data.push({ hours: i, floors: 0, type: "Floors" });
        }
        if (floorData?.tripDetails?.[i]) {
          data.push({
            hours: i,
            floors: floorData?.tripDetails?.[i],
            type: "Trips",
          });
        } else {
          data.push({ hours: i, floors: 0, type: "Trips" });
        }
      }
    }

    if (selectInterval < 30 && selectInterval > 1) {
      for (let obj in floorData?.floorDetails) {
        floorCount += floorData?.floorDetails?.[obj] || 0;
        data.push({
          hours: obj?.slice(0, obj?.indexOf("T")),
          floors: floorData?.floorDetails?.[obj] || 0,
          type: "Floors",
        });
      }
      for (let obj in floorData?.tripDetails) {
        tripCount += floorData?.tripDetails?.[obj] || 0;
        data.push({
          hours: obj?.slice(0, obj?.indexOf("T")),
          floors: floorData?.tripDetails?.[obj] || 0,
          type: "Trips",
        });
      }
      
    }
    if (selectInterval < 90 && selectInterval >= 30) {
      let newObj = {
        floorDetails: [],
        tripDetails: [],
      };

      for (let key in floorData) {
        for (let key2 in floorData[key]) {
          newObj[key].push({
            WEEK: floorData?.[key]?.[key2]?.["WEEk"],
            YEAR: floorData?.[key]?.[key2]?.["YEAR"],
            value: floorData?.[key]?.[key2]?.["value"],
          });
        }
      }
      for (let key in newObj) {
        newObj[key].sort((a, b) => {
          a.YEAR = String(a?.YEAR)
          b.YEAR = String(b?.YEAR)

          return (
            a?.YEAR?.localeCompare(b?.YEAR) || a?.MONTH - b?.MONTH
          );
        });
      }
      for (let obj of newObj.floorDetails) {
        floorCount += floorData?.floorDetails?.[obj]?.["value"] || 0;
        const days = obj?.WEEK;
        const date = obj?.YEAR;
        let weekDate = getSundayFromWeekNum(days, date);
        data.push({
          hours: `${obj?.WEEK}-(${weekDate?.toLocaleDateString("zh-Hans-CN")})`,
          floors: obj?.["value"] || 0,
          type: "Floors",
        });
      }
  
      for (let obj of newObj.tripDetails) {
        tripCount += floorData?.tripDetails?.[obj]?.["value"] || 0;
        const days = obj?.WEEK;
        const date = obj?.YEAR;
        let weekDate = getSundayFromWeekNum(days, date);
        data.push({
          hours: `${obj?.WEEK}-(${weekDate?.toLocaleDateString("zh-Hans-CN")})`,
          floors: obj?.["value"] || 0,
          type: "Trips",
        });
      }
    }

    if (selectInterval >= 90) {
      let newObj = {
        floorDetails: [],
        tripDetails: [],
      };

      for (let key in floorData) {
        for (let key2 in floorData[key]) {
          newObj[key].push({
            MONTH: floorData?.[key]?.[key2]?.["MONTH"],
            YEAR: floorData?.[key]?.[key2]?.["YEAR"],
            value: floorData?.[key]?.[key2]?.["value"],
          });
        }
      }
      for (let key in newObj) {
        newObj[key].sort((a, b) => {
          a.YEAR = String(a?.YEAR)
          b.YEAR = String(b?.YEAR)

          return (
            a?.YEAR?.localeCompare(b?.YEAR) || a?.MONTH - b?.MONTH
          );
        });
      }
      
      for (let obj of newObj.floorDetails) {
        // floorCount += floorData?.floorDetails?.[obj]?.["value"];
        data.push({
          hours: `${moment()
            .month(obj?.MONTH - 1)
            .format("MMM")} ${obj?.YEAR}`,
          floors: obj?.["value"] || 0,
          type: "Floors",
        });
      }
      for (let obj of newObj.tripDetails) {
        // tripCount += floorData?.tripDetails?.[obj]?.["value"];
        data.push({
          hours: `${moment()
            .month(obj?.MONTH - 1)
            .format("MMM")} ${obj?.YEAR}`,
          floors: obj?.["value"] || 0,
          type: "Trips",
        });
      }
    }
  } else {
    if (selectInterval === 1) {
      for (let i = 0; i < 24; i++) {
        if (floorData?.floorDetails?.[0]?.FLOOR?.HOURLY?.[i]) {
          data.push({
            hours: i,
            floors:
              floorData?.floorDetails?.[0]?.FLOOR?.HOURLY?.[i][
                "TOTAL_HOURLY_FLOOR"
              ],
            type: "Floors",
          });
        } else {
          data.push({ hours: i, floors: 0, type: "Floors" });
        }
        if (floorData?.tripDetails?.[0]?.TRIP?.HOURLY?.[i]) {
          data.push({
            hours: i,
            floors:
              floorData?.tripDetails?.[0]?.TRIP?.HOURLY?.[i][
                "TOTAL_HOURLY_TRIP"
              ],
            type: "Trips",
          });
        } else {
          data.push({ hours: i, floors: 0, type: "Trips" });
        }
      }
    }

    if (selectInterval < 30 && selectInterval > 1) {
      for (let obj of floorData.floorDetails) {
        floorCount += obj?.FLOOR?.TOTAL_DAY_FLOORS || 0;
        data.push({
          hours: obj?.DEVICE_TIME?.slice(0, obj?.DEVICE_TIME.indexOf("T")),
          floors: obj?.FLOOR?.TOTAL_DAY_FLOORS || 0,
          type: "Floors",
        });
      }
      for (let obj of floorData.tripDetails) {
        tripCount += obj?.TRIP?.TOTAL_DAY_TRIP || 0;
        data.push({
          hours: obj?.DEVICE_TIME?.slice(0, obj?.DEVICE_TIME.indexOf("T")),
          floors: obj?.TRIP?.TOTAL_DAY_TRIP || 0,
          type: "Trips",
        });
      }
    }
    if (selectInterval < 90 && selectInterval >= 30) {
      floorData?.floorDetails?.sort((a, b) => {
        a.YEAR = String(a.YEAR)
       b.YEAR = String(b.YEAR)
        return a.YEAR.localeCompare(b.YEAR) || a.MONTH - b.MONTH
      })
      
      for (let obj of floorData.floorDetails) {
        floorCount += obj?.FLOOR || 0;
        const days = obj?.WEEK;
      const date = obj?.YEAR;
      let weekDate=getSundayFromWeekNum(days,date )
        data.push({
          hours: `${(obj?.WEEK)}-(${weekDate?.toLocaleDateString("zh-Hans-CN")})`,
          floors: obj?.FLOOR || 0,
          type: "Floors",
        });
      }
      floorData?.tripDetails?.sort((a, b) => {
        a.YEAR = String(a.YEAR)
       b.YEAR = String(b.YEAR)
        return a.YEAR.localeCompare(b.YEAR) || a.MONTH - b.MONTH
      })
      for (let obj of floorData.tripDetails) {
        tripCount += obj?.TRIP || 0;
        const days = obj?.WEEK;
      const date = obj?.YEAR;
      let weekDate=getSundayFromWeekNum(days,date )
        data.push({
          hours: `${(obj?.WEEK)}-(${weekDate?.toLocaleDateString("zh-Hans-CN")})`,
          floors: obj?.TRIP || 0,
          type: "Trips",
        });
      }
    }
    if (selectInterval >= 90) {
      for (let key in floorData){
        floorData[key].sort((a,b) => {
          a.YEAR = String(a.YEAR)
          b.YEAR = String(b.YEAR)
          return a.YEAR.localeCompare(b.YEAR) || a.MONTH - b.MONTH;
        })
      
      }
    
      for (let obj of floorData.floorDetails) {
        floorCount += obj?.FLOOR;
        data.push({
          hours: `${moment()
            .month(obj?.MONTH - 1)
            .format("MMM")} ${obj?.YEAR}`,
          floors: obj?.FLOOR || 0,
          type: "Floors",
        });
      }

      for (let obj of floorData.tripDetails) {
        tripCount += obj?.TRIP;
        data.push({
          hours: `${moment()
            .month(obj?.MONTH - 1)
            .format("MMM")} ${obj?.YEAR}`,
          floors: obj?.TRIP || 0,
          type: "Trips",
        });
      }
    }
  }
  // if(data){
  //   setFfloorTripcsvData(data)
  // }
  const headerData = [
    {
      count: selectInterval,
      label: "Total Days",
    },
    {
      count:
        floorData && selectInterval === 1
          ? floorData?.floorDetails?.[0]?.FLOOR?.TOTAL_DAY_FLOORS || 0
          : floorCount || 0,
      label: "Total Floors",
    },
    {
      count:
        floorData && selectInterval === 1
          ? floorData?.tripDetails?.[0]?.TRIP?.TOTAL_DAY_TRIP || 0
          : tripCount || 0,
      label: "Total Trips",
    },

    {
      count:
        floorData && selectInterval === 1
          ? Math.round(
              floorData?.floorDetails?.[0]?.FLOOR?.TOTAL_DAY_FLOORS /
                selectInterval
            ) || 0
          : Math.round(floorCount / selectInterval) || 0,

      label: "Average Floors Per Day",
    },
    {
      count:
        floorData && selectInterval === 1
          ? Math.round(
              floorData?.tripDetails?.[0]?.TRIP?.TOTAL_DAY_TRIP / selectInterval
            ) || 0
          : Math.round(tripCount / selectInterval) || 0,

      label: "Average Trips Per Day",
    },
  ];
 
  const config = {
    data,
    isGroup: true,
    xField: "hours",
    yField: "floors",
    seriesField: "type",
    columnWidthRatio: selectInterval === 1 ? 0.8 : 0.5,
    label: {
      position: "top",
      offsetY: 12,
    },
    yAxis: {
      title: {
        display: true,
        text: "Floors",
      },
    },
    xAxis: {
      title: {
        display: true,
        text:
          selectInterval === 1
            ? "Hour (0-23)"
            : selectInterval <= 7
            ? "Date"
            : selectInterval >= 90
            ? "Months"
            : selectInterval >= 30
            ? "Weeks"
            : selectInterval < 30
            ? "Daily"
            : "Date",
      },
    },
     
  };

  return (
    <div>
      {called !== "reportsFloor" && (
        <Card style={{ height: "120px" }}>
          <Row gutter={15}>
            {headerData?.map((data,index) => {
              return (
                <Col key={index} span={4} style={{ textAlign: "center" }}>
                  <div style={{ fontWeight: "bold" }}>{data?.count}</div>
                  <div>{data?.label}</div>
                </Col>
              );
            })}
          </Row>
        </Card>
      )}
      <Column {...config} height={250} />
    </div>
  );
};

export default FloorChart;
