export const ruleTypeOptions = [
  {
    value: "overVoltage",
    label: "OVER VOLTAGE",
  },
  {
    value: "UnderVoltage",
    label: "UNDER VOLTAGE",
  },
  {
    value: "Current",
    label: "OVER CURRENT",
  },
  {
    value: "ReactivePower",
    label: "REACTIVE POWER",
  },
  {
    value: "PF",
    label: "PF LAG",
  },
  {
    value: "V-THD",
    label: "V-THD",
  },
  {
    value: "PowerDemand",
    label: "POWER DEMAND",
  },

];

export const options = [
  {
    value: "India",
    label: "India",
  },
  {
    value: "UK",
    label: "UK",
  },
];
export const cityOptions = [
  {
    value: "blr",
    label: "Blr",
  },
  {
    value: "hyd",
    label: "hyd",
  },
];
export const radioOptions = [
  {
    value: "PHASE R",
    label: "PHASE R",
    // state: false,
  },
  {
    value: "PHASE Y",
    label: "PHASE Y",
    // state: false,
  },
  {
    value: "PHASE B",
    label: "PHASE B",
    // state: false,
  },
  {
    value: "ALL",
    label: "ALL",
    // state: false,
  },
];
const levelOptions = [
  {
    value: "operator",
    label: "Bosch(Operator)",
  },
  {
    value: "storeIncharge",
    label: "Bosch(Store Incharge)",
  },
];
export const timePeriodOptions = [
  {
    value: "DAILY",
    label: "DAILY",
  },
  {
    value: "WEEKLY",
    label: "WEEKLY",
  },
  {
    value: "MONTHLY",
    label: "MONTHLY",
  },
];
export const daysOptions = [
  {
    value: "2",
    label: "2",
  },
  {
    value: "1",
    label: "1",
  },
];
export const Priority = [
  {
    value: "High",
    label: "High",
  },
  {
    value: "Medium",
    label: "Medium",
  },
  {
    value: "Low",
    label: "Low",
  },
];
export const MaintenanceTime = [
  {
    value: "WEEKLY",
    label: "Weekly",
  },
  {
    value: "MONTHLY",
    label: "Monthly",
  },
  {
    value: "YEARLY",
    label: "Yearly",
  },
];

export const pathSelect = [
  {
    value: "timeBased",
    label: "Time Based",
  },
  {
    value: "floorBased",
    label: "Floor Based",
  },
];
export const Communication = [
  {
    value: "email",
    label: "Email",
  },
];
export const alertIelTime = [
  // {
  //   value: "Hourly",
  //   label: "Hourly",
  // },
  {
    value: "DAILY",
    label: "Daily",
  },
  {
    value: "WEEKLY",
    label: "Weekly",
  },
  {
    value: "MONTHLY",
    label: "Monthly",
  },
];
export const workingDays = [
  {
    value: "MON",
    label: "Monday",
  },
  {
    value: "TUE",
    label: "Tuesday",
  },
  {
    value: "WED",
    label: "Wednesday",
  },
  {
    value: "THU",
    label: "Thursday",
  },
  {
    value: "FRI",
    label: "Friday",
  },
  {
    value: "SAT",
    label: "Saturday",
  },
  {
    value: "SUN",
    label: "Sunday",
  },
];

export const formData = [
  { item: "Phase", itemName: "Select Phase", radioOptions: radioOptions },
  {
    item: "Threshold Limit",
    itemName: "Set Threshold Limit",
    firstPlaceHolder: "Upper Limit",
    limitU:"UpperLimit",
    limitL:"LowerLimit",
    secondPlaceHolder: "Lower Limit",
  },
  { item: "Communication Medium", itemName: "Set Communication Medium",
  communication :"CommunicationMedium"
 },
  {
    item: "Escalation Level",
    itemName: "Define Escalation Level",
    firstPlaceHolder: "Level 1",
    secondPlaceHolder: "No. of Days",
    name:"level1Days",
    countryOptions: levelOptions,
    cityOptions: daysOptions,
    rows: [
      {
        itemName: "level2",
        firstPlaceHolder: "Level 2",
        secondPlaceHolder: "No. of Days",
        countryOptions: levelOptions,
        cityOptions: daysOptions,
      },
      {
        itemName: "level3",
        firstPlaceHolder: "Level 3",
        secondPlaceHolder: "level3Days",
        countryOptions: levelOptions,
        cityOptions: daysOptions,
      },
    ],
  },
];

export const loadParameterFormData = [
  {
    itemName: "Select the following",
    cols: [
      {
        placeholder: "Country",
        label: "country",
        type: "select",
        options: options,
      },
      {
        placeholder: "City",
        label: "city",
        type: "select",
        options: options,
      },
      {
        placeholder: "Location",
        label: "location",
        type: "select",
        options: options,
      },
    ],
  },
  {
    itemName: "",
    cols: [
      {
        placeholder: "Elevator",
        label: "elevator",
        type: "select",
        options: options,
      },

      {
        placeholder: "Building",
        label: "building",
        type: "select",
        options: options,
      },
      {
        placeholder: "",
        label: "",
        type: "",
        options: "",
      },
    ],
  },
  {
    itemName: "Set Limit and Period",
    cols: [
      {
        placeholder: "Energy Target",
        label: "energyTarget",
        type: "select",
        options: options,
      },

      {
        placeholder: "For",
        label: "for",
        type: "select",
        options: options,
      },
      {
        placeholder: "Target",
        label: "target",
        type: "select",
        options: options,
      },
    ],
  },
  {
    itemName: "",
    cols: [
      { placeholder: "Start Date", label: "Start", type: "date" },
      { placeholder: "End Date", label: "End", type: "date" },
    ],
  },
  {
    itemName: "Communication Medium",
    cols: [{ placeholder: "E-mail", label: "email", type: "checkbox" }],
  },
  {
    itemName: "Define Escalation Level",
    cols: [
      { placeholder: "Level 1", label: "level1", type: "select" },
      { placeholder: "No of Days", label: "level1days", type: "select" },
      { placeholder: "", label: "", type: "" },
    ],
  },
  {
    itemName: "",
    cols: [
      { placeholder: "Level 2", label: "level2", type: "select" },
      { placeholder: "No of Days", label: "level2days", type: "select" },
      { placeholder: "", label: "", type: "" },
    ],
  },
  {
    itemName: "",
    cols: [
      { placeholder: "Level 3", label: "level3", type: "select" },
      { placeholder: "No of Days", label: "level3days", type: "select" },
    ],
  },
];

export const typeOption = [
  {
    value: "AlertTrips",
    label: "NO OF TRIPS",
  },
  {
    value: "alertMaintenance",
    label: "MAINTENANCE TIME / FLOOR BASED",
  },
  {
    value: "alertIdle",
    label: "IDLE TIME",
  },
  {
    value: "alertOperationOutside",
    label: "OPERATION OUTSIDE WORKING HOURS",
  },
  {
    value: "alertForOfflineLostCommunication",
    label: "OFFLINE/LOST COMMUNICATION/NO POWER",
  },
  // {
  //   value: "alertForManualTampering",
  //   label: "MANUAL TAMPERING",
  // },
  {
    value: "alertForManualTampering",
    label: "LIFT STUCK",
  },
  {
    value: "alertForpowerShutDown",
    label: "POWER SHUTDOWN",
  },
  {
    value: "alertForElevatorOnline",
    label: "ELEVATOR ONLINE",
  },
  // {
  //   value: "alertForLiftStuck",
  //   label: "LIFT STUCK",
  // },
];
export const typeOption2 = [ 
  {
    value: "alertIdle",
    label: "IDLE TIME",
  },
  {
    value: "alertForOfflineLostCommunication",
    label: "OFFLINE/LOST COMMUNICATION/NO POWER",
  },
];
export const alertTripSpan = [
  {
    value: "DAILY",
    label: "Daily",
  },
  {
    value: "WEEKLY",
    label: "Weekly",
  },
  {
    value: "MONTHLY",
    label: "Monthly",
  },
];
export const elevatorFormData = [
  {
    rowData: "Notification Parameter",
    firstRow: [
      {
        placeholder: "UpperThreshold Energy",
        label: "upperThreshold",
        type: "input",
      },
    ],
    secondRow: [
      {
        placeholder: "LowerThreshold Energy",
        label: "lowerThreshold",
        type: "input",
      },
    ],
  },

  {
    rowData: "Notification Medium",
    firstRow: [
      { placeholder: "Email", label: "email", type: "checkbox" },
      { placeholder: "SMS", label: "sms", type: "checkbox" },
    ],
    secondRow: [
      {
        placeholder: "Email To",
        label: "emailTo ",
        type: "input",
      },
    ],
    thirdRow: [
      {
        placeholder: "Email CC",
        label: "emailcc ",
        type: "input",
      },
    ],
    fourthRow: [
      {
        placeholder: "SMS Recepients",
        label: "smsRecepients ",
        type: "input",
      },
    ],
  },
];
