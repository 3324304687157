/* eslint-disable react/prop-types */
import React from "react";
import { downloadCSV } from "./CsvDownloader";
import { InsertRowBelowOutlined } from "@ant-design/icons";
import { Popover } from "antd";
const CsvButton = ({ data, filename, selectInterval }) => {
  const handleDownload = () => {
    downloadCSV(data, filename, selectInterval);
  };

  return (
    <Popover content={"Download Csv"} placement="topLeft">
      <InsertRowBelowOutlined
        onClick={handleDownload}
        style={{ marginTop: "10px" }}
      />
    </Popover>
  );
};

export default CsvButton;

