import React from "react";
import {
  TableOutlined,
  AreaChartOutlined,
  LineChartOutlined,
  BulbOutlined,
} from "@ant-design/icons";
import AreaChart from "../../common/visuals/areaCharts/AreaChart";
import LineAreaChart from "../../common/LineAreaChart";

export const tabsData = [
  { tab: <LineChartOutlined />, chart: <AreaChart />, type: "line" },
  { tab: <AreaChartOutlined />, chart: <LineAreaChart /> },
  { tab: <TableOutlined />, chart: "table" },
];
export const reportTablecolumns = [
  {
    title: "DEVICE_TIME",
    dataIndex: "DEVICE_TIME",
  },
  {
    title: "ACTIVE_HOURS",
    dataIndex: "ACTIVE",
  },
];
export const floorsTablecolumns = [
  {
    title: "hours",
    dataIndex: "hours",
  },
  {
    title: "value",
    dataIndex: "value",
  },
];
export const segmentOptions = [
  {
    label: (
      <>
        <BulbOutlined />
        {/* Yesterday */} Hourly
      </>
    ),
    value: "hourly",
  },
  {
    label: (
      <>
        <BulbOutlined />
        Daily
      </>
    ),
    value: "daily",
  },
  {
    label: (
      <>
        <BulbOutlined />
        Weekly
      </>
    ),
    value: "weekly",
  },
  {
    label: (
      <>
        <BulbOutlined />
        Monthly
      </>
    ),
    value: "monthly",
  },
];
