/* eslint-disable no-useless-escape */
import React from "react";
import BackGround from "../../assets/slpashscreen.png";
import BackGroundStrip from "../../assets/Strip.png";
import Logo from "../../assets/Equipment.png";
import "./Styles.css";
import { Button, Form, Input, Select, Col,Row, Divider, InputNumber } from "antd";
import axios from "axios";
import { baseUrl } from "../../common/BaseUrl";
const SignUp = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const onFinish = (values) => {
    if(values){
      axios
      .post(
        `${baseUrl}/register`,
        {
          parent_id: values?.parent_id,
          tenant_id: values?.tenant_id,
          name: values?.name,
          company_name: values?.company_name,
          phone_number: (values?.prefix?.toString())?.concat(values?.phone_number?.toString()) ,
          email: values?.email,
          userId: values?.userId,
          role: values?.role,
          password: values?.password,
          country:values?.country,
          city:values?.city,
          store_id:values?.store_id,
         },{})
      .then(() => {
        form.resetFields();
       });
    }
};
  
  const onReset = () => {
    form.resetFields();
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 70,
        }}
      >
        <Option value="+91">+91</Option>
      </Select>
    </Form.Item>
  );
  return (
    <div>
      <img
        src={BackGroundStrip}
        width="100%"
        height="20px"
        style={{ marginTop: "-10px" }}
      />
      <div style={{ padding: "10px" }}>
        <img src={Logo} height="50px" />
      </div>

      <div
        style={{
          backgroundImage: `url(${BackGround})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "85.8vh",
          paddingTop: "10px",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "30px",
        }}
      >
        <div
          style={{
            backgroundColor: "#f6f6f6",
            padding: "20px",
            width: "700px",
            height:"82vh",
            overflowY:"auto"
            
          }}
        >
         <Divider style={{fontSize:"20px",color:"#005691", fontWeight:"bold"}}>Registration</Divider>
          
          <Form form={form} name="Registration" onFinish={onFinish} >
            <Row gutter={[12,1]}>
            <Col className="regestration"  xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 8 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}>
              <div className="formInputStyle">
                <label className="formLabelStyle">Country</label>
                <Form.Item
                  name="country"
                 
                  rules={[
                    {
                      required: true,
                      message:"Enter Country"
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Col>
            <Col className="regestration"  xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}>
              <div className="formInputStyle">
                <label className="formLabelStyle">City</label>
                <Form.Item
                  name="City"
                 
                  rules={[
                    {
                      required: true,
                      message:"Enter Parent ID"
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Col>
            <Col  className="regestration" xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}>
              <div className="formInputStyle">
                <label className="formLabelStyle">Company Name</label>
                <Form.Item
                  name="company_name"
                
                  rules={[
                    {
                      required: true,
                      message:"Enter Company Name"
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Col>
            <Col className="regestration"  xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}>
              <div className="formInputStyle">
                <label className="formLabelStyle">Store ID</label>
                <Form.Item
                  name="store_id"
                 
                  rules={[
                    {
                      required: true,
                      message:"Enter Store ID"
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Col>
            <Col className="regestration"  xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}>
              <div className="formInputStyle">
                <label className="formLabelStyle">Parent ID</label>
                <Form.Item
                  name="parent_id"
                 
                  rules={[
                    {
                      required: true,
                      message:"Enter Parent ID"
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Col>
            <Col  className="regestration" xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}>
              <div className="formInputStyle">
                <label className="formLabelStyle">Tenant ID</label>
                <Form.Item
                  name="tenant_id"
            
                  rules={[
                    {
                      required: true,
                      message:"Enter Tenant ID"
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Col>
            
            <Col  className="regestration" xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}>
              <div className="formInputStyle">
                <label className="formLabelStyle">Name</label>
                <Form.Item
                  name="name"
           
                  rules={[
                    {
                      required: true,
                      message:"Enter Name"
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Col>
            <Col  className="regestration" xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}>
              <div className="formInputStyle">
                <label className="formLabelStyle">User Id</label>
                <Form.Item
              name="userId"
              rules={[
                {
                  required: true,
                  message:"Enter User ID"
                },
              ]}
            >
              <Input />
            </Form.Item>
              </div>
            </Col>
            <Col  className="regestration" xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}>
              <div className="formInputStyle">
                <label className="formLabelStyle">Role</label>
                <Form.Item
              name="role"
              rules={[
                {
                  required: true,
                  message:"Select Role"
                },
              ]}
            >
              <Select
                placeholder="Select Role"
                allowClear
              >
                <Option value="global-executive">Global Executive</Option>
                <Option value="country-executive">Country Executive</Option>
                <Option value="city-executive">City Executive</Option>
                <Option value="store-incharge">Store Incharge</Option>
                <Option value="operator">Operator</Option>
                <Option value="admin">Admin</Option>
              </Select>
            </Form.Item>
              </div>
            </Col>
            <Col  className="regestration" xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}>
              <div className="formInputStyle">
                <label className="formLabelStyle">Phone Number</label>
                <Form.Item
              name="phone_number"
          
              rules={[
                {
                  required: true,
                  message:"Enter Phone Number"
                },
              ]}
            >
              <InputNumber addonBefore={prefixSelector} style={{
        width: '100%',
      }}/>
            </Form.Item>
              </div>
            </Col>
            <Col  className="regestration" xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}>
              <div className="formInputStyle">
                <label className="formLabelStyle">Email</label>
                <Form.Item
                  name="email"
                
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Enter your E-mail!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Col>
           
           
            <Col  className="regestration" xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}>
              <div className="formInputStyle">
                <label className="formLabelStyle">Password</label>
                <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'Please input your password!' },
                  { min: 8, message: 'Password must be at least 8 characters long.' },
                  { pattern: new RegExp(/\d/), message: 'Password must contain at least one digit.' },
                  { pattern: new RegExp(/[A-Z]/), message: 'Password must contain at least one uppercase letter.' },
                  { pattern: new RegExp(/[a-z]/), message: 'Password must contain at least one lowercase letter.' },
                  { pattern: new RegExp(/[!@#\$%\^&\*]/), message: 'Password must contain at least one special character.' },
                ]}
                // hasFeedback
              >
                <Input.Password />
              </Form.Item>
              </div>
            </Col>
            <Col  className="regestration" xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}>
              <div className="formInputStyle">
                <label className="formLabelStyle">Confirm Password</label>
                <Form.Item
                name="reenterpassword"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              </div>
            </Col>
            </Row>
            <Row gutter={24} style={{display:"flex", justifyContent:"center", marginTop:"20px"}}>
                <Col className="regestration"> <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item></Col>
                <Col className="regestration"> <Form.Item {...tailLayout}>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
            </Form.Item></Col>
            </Row>
          
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
