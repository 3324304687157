import React, { useState } from "react";
import "antd/dist/antd.css";
import { CheckOutlined,ArrowLeftOutlined } from "@ant-design/icons";
import { Tabs, Row, Col } from "antd";
import "./Styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import UsersGroup from "./spaceOverviewTabs/UsersGroup";
import PhantomDevice from "./spaceOverviewTabs/PhantomDevice";
import LocationDetails from "./spaceOverviewTabs/LocationDetails";
import phantomDevice from "../../assets/common-phantom-device.png";
import userGroup from "../../assets/common-add-user.png";
import locationImg from "../../assets/locationImg.png";
import locationIcon from "../../assets/location.png";
import deviceImg from "../../assets/deviceImg.png";
import operatingImg from "../../assets/operatingImg.png";
import mappingImg from "../../assets/mappingImg.png";
import userImg from "../../assets/userImg.png";
const SpaceOverview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const deviceType = window.localStorage.getItem("devicetype");
  const [tabKey, setTabKey] = useState("1");
  const [tabDetails, setTabDetails] = useState([
    {
      key: "1",
      title: "Add Location details",
      icon: locationIcon,
      tabsState: true,
      content: "",
      children: <LocationDetails userData={location?.state} />,
      label: `Location Details`,
    },
    {
      title: `${deviceType} Details`,
      icon: phantomDevice,
      tabsState: false,
      label: `${deviceType} Details`,
      key: "3",
      children: <PhantomDevice userData={location?.state} />,
    },
    {
      title: "Add User & Groups",
      icon: userGroup,
      tabsState: false,
      label: `Users & Group`,
      key: "6",
      children: <UsersGroup locationData={location?.state} />,
    },
  ]);
  const operations = {
    left: <ArrowLeftOutlined style={{fontSize:"25px", margin:"10px 20px 5px 10px"}} onClick={() => navigate("/on-boarding")}/>   };
  const handleTabChange = (key) => {
    setTabKey(key);
    tabDetails?.map((item) => {
      if (item.key == key) {
        item.tabsState = true;
      } else {
        item.tabsState = false;
      }
    });
    setTabDetails([...tabDetails]);
  };

  return (
    <div>
      <Row
        className="headerRow"
        style={{
          background:
            tabKey === "1"
              ? "linear-gradient(145deg, #f4808b 0%, #dc80ba 70%, #7c5a9f 100%)"
              : tabKey === "2"
              ? "linear-gradient(145deg, #50237F 0%, #005691 70%, #40AADB 100%)"
              : tabKey === "3" || tabKey === "4"
              ? "linear-gradient(145deg, #005691 0%, #008ECF 70%, #00A8B0 100%)"
              : tabKey === "5"
              ? "linear-gradient(145deg, #008ECF 0%, #00A8B0 70%, #78BE20 100%)"
              : tabKey === "6" &&
                "linear-gradient(145deg, #00A8B0 0%, #78BE20 70%, #006249 100%)",
        }}
      >
        {tabDetails?.map((step, index) => {
          return (
            <Col key={index} md={4} xs={8} style={{ marginBottom: "60px" }}>
              <div
                className="circleStyle"
                style={{
                  backgroundColor:
                    index === 0
                      ? "#005691"
                      : step?.state
                      ? "#005691"
                      : step?.tabsState && "#005691",
                }}
              >
                {index === 0 ? (
                  <CheckOutlined style={{ marginTop: "15px" }} />
                ) : (
                  <img src={step.icon} className="center" />
                )}

                <label
                  className={
                    step.title === "Add Location details"
                      ? "labelStyle1"
                      : "labelStyle"
                  }
                >
                  {step.title}
                </label>
              </div>

              {index < tabDetails?.length - 1 && (
                <div className="lineStyle"></div>
              )}
            </Col>
          );
        })}
        {tabKey === "1" ? (
          <img
            src={locationImg}
            height="120px"
            style={{ marginLeft: "20vw" }}
          />
        ) : tabKey === "2" ? (
          <img src={operatingImg} height="100px" />
        ) : tabKey === "3" || tabKey === "4" ? (
          <img src={deviceImg} height="100px" />
        ) : tabKey === "5" ? (
          <img src={mappingImg} height="100px" />
        ) : (
          tabKey === "6" && <img src={userImg} height="100px" />
        )}
      </Row>
     
      <Tabs
        tabBarExtraContent={operations}
        className="SpaceOverviewTab"
        onTabClick={(key) => handleTabChange(key)}
      >
        {tabDetails?.map((tab) => {
          return (
            <Tabs.TabPane tab={tab?.label} key={tab.key}>
              {tab.children}
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default SpaceOverview;
