/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Button, Checkbox, Col, Form, Row, Select, Spin, notification } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { RTMBaseUrl, baseUrl } from "../../common/BaseUrl";
import moment from "moment";

const ReportConfigurationForm = ({
  selectIndex,
  setSelectIndex,
  filterData,
  setreportConfigModal,
  reportApiLoader, setReportApiLoader,editConfigData
}) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const accessToken = window.localStorage.getItem("accessToken");
  const deviceType = window.localStorage.getItem("devicetype"); 
  const [apiCalled, setApiCalled] = useState(false);
  const [userCityData, setUserCityData] = useState();
  const [userCountryData, setuserCountryData] = useState();
  const [operatorData, setOperatorData] = useState();
  const [inChargeData, setInchargeData] = useState();
  const [adminData, setAdminData] = useState();
  const [apiStorId, setApiStorId] = useState();
  const [apiLocation, setApiLocation] = useState();
  const [apiCity, setApiCity] = useState();
  const [apiCountry, setApiCountry] = useState();
  // eslint-disable-next-line no-unused-vars
  const [userAgainEditData, setUserAgainEditData] = useState();
  const [pageLoader, setPageLoader] = useState(false);
  const [elevatorsDeviceID, setElevatorsDeviceID] = useState();
  const [selectedDisable,setSelectedDisable]=useState(true);

  const addButtonInValid=()=>{
    console.log("4321",form.getFieldValue(['users']))
    if( userOptionData?.length === 0)
    {
      return true;
    }
    else if( (form.getFieldValue(['users']).length>0&&form.getFieldValue(['users'])?.[form.getFieldValue(['users'])?.length-1]===undefined))
    {
    
      return true;
    }
    else if( (form.getFieldValue(['users']).length>0&&form.getFieldValue(['users'])?.[form.getFieldValue(['users'])?.length-1]!=undefined))
    {
      
     if(Object.keys(form.getFieldValue(['users'])?.[0])?.length===0)
     {
      return true;
     }
     return false;
    }
    return false;
    
  }

  const removeButtonInvalid=()=>{
     if( (form.getFieldValue(['users']).length>0&&form.getFieldValue(['users'])?.[0]===undefined))
      {
      
        return true;
      }
      else if( (form.getFieldValue(['users']).length>0&&form.getFieldValue(['users'])?.[0]!=undefined))
        {
          
         if(Object.keys(form.getFieldValue(['users'])?.[0])?.length===0)
         {
          return true;
         }
         return false;
        }
        return false;
    // Object?.keys(form?.getFieldValue(['users'])?.[0]).length===0
  }
 

  useEffect(()=>{
    if(editConfigData){
      setApiStorId(editConfigData?.STORE_ID);
      setApiLocation(editConfigData?.LOCATION);
      setApiCity(editConfigData?.CITY);
      setApiCountry(editConfigData?.COUNTRY);
      form.setFieldsValue({
        country: editConfigData?.COUNTRY,
        city: editConfigData?.CITY,
        location: editConfigData?.LOCATION,
        building: editConfigData?.STORE_NAME,
        elevator: editConfigData?.DEVICE_NAME,
        recurrence: editConfigData?.FREQUENCY,
        users: editConfigData?.RESPONSIBLE_CRITERIA,
      })
    }

  },[])

  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "country") {
      form.setFieldsValue({
        city: undefined,
        location: undefined,
        building: undefined,
        elevator: undefined,
        users: [{}],
      }); //reset product selection
    } else if (formFieldName === "city") {
      form.setFieldsValue({
        location: undefined,
        building: undefined,
        elevator: undefined,
        users: [{}],
      });
    } else if (formFieldName === "location") {
      form.setFieldsValue({
        building: undefined,
        elevator: undefined,
        users: [{}],
      });
    } else if (formFieldName === "building") {
      // setUseragEditData("");
      form.setFieldsValue({
        elevator: undefined,
        users: [{}],
      });
    }
  };
  useEffect(() => {
    if (apiStorId) {
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["EXECUTIVE AREA"],
            COUNTRY: apiCountry,
            CITY: apiCity,
            LOCATION: apiLocation,
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setInchargeData(response?.data?.data);
        });
        

      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["STORE INCHARGE", "OPERATOR"],
            COUNTRY: apiCountry,
            CITY: apiCity,
            STORE_ID: apiStorId?.toString(),
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setOperatorData(response?.data?.data);
        });
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          { ROLES: ["ADMIN", "EXECUTIVE GLOBAL"] },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setAdminData(response?.data?.data);
        });
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["EXECUTIVE COUNTRY"],
            COUNTRY: apiCountry,
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setuserCountryData(response?.data?.data);
        });
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["EXECUTIVE CITY"],
            COUNTRY: apiCountry,
            CITY: apiCity,
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setUserCityData(response?.data?.data);
        });
    }
  }, [apiStorId, apiCalled]);

  let userOptionData = [];
  if (inChargeData) {
    inChargeData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (adminData) {
    adminData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (operatorData) {
    operatorData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (userCountryData) {
    userCountryData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (userCityData) {
    userCityData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  const onFinish = (values) => {
    values?.users?.map((i) => {
      i.EMAIL = true;
    });
    setPageLoader(true)
    const ReportAddParram = {
      COUNTRY: values?.country,
      CITY: values?.city,
      LOCATION: values?.location,
      STORE_ID: apiStorId,
      STORE_NAME: values?.building,
      DEVICE_NAME: values?.elevator,
      DEVICE_ID: elevatorsDeviceID,
      FREQUENCY: values?.recurrence,
      RESPONSIBLE_CRITERIA: values?.users,
      CREATED_ON: moment().format("YYYY-MM-DDTHH:mm:ss"),
    };

      const formdata = form.getFieldsValue()
      formdata?.users?.map((i) => {
        i.EMAIL = true;
      });
      const ReportEditParram = {
      COUNTRY: formdata?.country,
      CITY: formdata?.city,
      LOCATION: formdata?.location,
      STORE_NAME: formdata?.building,
      DEVICE_NAME: formdata?.elevator,
      STORE_ID: apiStorId?apiStorId:editConfigData?.STORE_ID,
      DEVICE_ID: elevatorsDeviceID? elevatorsDeviceID:editConfigData?.DEVICE_ID,
      FREQUENCY: formdata?.recurrence,
      RESPONSIBLE_CRITERIA: formdata?.users,
      CREATED_ON: editConfigData?.CREATED_ON,
      EDIT:1
      }
    axios
      .post(
        `${RTMBaseUrl}/reportsCofig`,
        editConfigData?ReportEditParram:ReportAddParram,
        {
          headers: { ["x-access-token"]: accessToken },
        }
      )
      .then((response) => {
        setReportApiLoader(!reportApiLoader );
       form.resetFields();
        

        if (response?.data?.data === "DEVICE_ID configuration already taken") {
          notification.error({
            description: response?.data?.data,
          });
          setPageLoader(false)
          setreportConfigModal(false);
        } else {
          notification.success({
            description: response?.data?.data,
          });
          setPageLoader(false)
          setreportConfigModal(false);
        }
      })
      .catch((error) => {
        setPageLoader(false)
        console.error("There was an error!", error);
        // setIsLoading(false);
      });
  };

  return (
    <div>
      <Spin spinning={pageLoader} tip="Loading..." size="large">
      <Form
        form={form}
        onFinish={onFinish}
        onValuesChange={handleFormValuesChange}
        style={{ padding: "15px" }}
        initialValues={{recurrence:['DAILY', 'WEEKLY', 'MONTHLY',"YEARLY"]}}
      >
        <Row className="rowHeadingStyle">Type Definition</Row>
        <Row gutter={[24, 15]}>
          <Col xs={12} sm={12}>
            <div className="formInputStyle">
              <label className="formLabelStyle">Country</label>
              <Form.Item
                name="country"
                rules={[
                  {
                    required: true,
                    message: "Please Select Country!",
                  },
                ]}
              >
                <Select
                placeholder="Country"
                  onChange={(value, key) => {
                    setSelectIndex({ ...selectIndex, country: key.key });
                    setApiCountry(filterData?.[key.key]?.COUNTRY);
                  }}
                >
                  {filterData?.map((country, index) => {
                    return (
                      <Option value={country?.COUNTRY} key={index}>
                        {country?.COUNTRY}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col xs={12} sm={12}>
            <div className="formInputStyle">
              <label className="formLabelStyle">City</label>
              <Form.Item
                name="city"
                rules={[
                  {
                    required: true,
                    message: "Please Select City!",
                  },
                ]}
              >
                <Select
                  onChange={(value, key) => {
                    setSelectIndex({ ...selectIndex, city: key.key });
                    setApiCity(
                      filterData?.[selectIndex?.country]?.CITIES?.[key.key]
                        ?.CITY
                    );
                  }}
                  placeholder="Cities"
                  disabled={selectIndex?.country ? false : true}
                  className="ReportModal"
                >
                  {filterData?.[selectIndex?.country]?.CITIES?.map(
                    (city, cityIndex) => {
                      return (
                        <Option value={city?.CITY} key={cityIndex}>
                          {city?.CITY}
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col xs={12} sm={12}>
            <div className="formInputStyle">
              <label className="formLabelStyle">Location</label>
              <Form.Item
                name="location"
                rules={[
                  {
                    required: true,
                    message: "Please Select Location!",
                  },
                ]}
              >
                <Select
                placeholder="Location"
                  onChange={(value, key) => {
                    setSelectIndex({ ...selectIndex, location: key.key });
                    setApiLocation(
                      filterData?.[selectIndex?.country]?.CITIES?.[
                        selectIndex?.city
                      ].LOCATION?.[key.key]?.LOCATION
                    );
                  }}
                  disabled={
                    selectIndex?.country && selectIndex?.city ? false : true
                  }
                >
                  {selectIndex?.city &&
                    filterData?.[selectIndex?.country]?.CITIES?.[
                      selectIndex?.city
                    ].LOCATION?.map((store, storeIndex) => {
                      return (
                        <Option value={store?.LOCATION} key={storeIndex}>
                          {store?.LOCATION}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col xs={12} sm={12}>
            <div className="formInputStyle">
              <label className="formLabelStyle">Building</label>
              <Form.Item
                name="building"
               
                rules={[
                  {
                    required: true,
                    message: "Please Select Building!",
                  },
                ]}
              >
                <Select
                  onChange={(value, key) => {
                    setApiStorId(
                      filterData?.[selectIndex?.country]?.CITIES?.[
                        selectIndex?.city
                      ].LOCATION?.[selectIndex?.location]?.STORE?.[key.key]
                        ?.STORE_ID
                    );

                    setSelectIndex({ ...selectIndex, store: key.key });
                  }}
                  placeholder="Building"
                  className="ReportModal"
                  disabled={selectIndex?.location ? false : true}
                >
                  {selectIndex?.location &&
                    filterData?.[selectIndex?.country]?.CITIES?.[
                      selectIndex?.city
                    ].LOCATION?.[selectIndex?.location]?.STORE?.map(
                      (store, storeIndex) => {
                        return (
                          <Option value={store?.STORE_NAME} key={storeIndex}>
                            {store?.STORE_NAME}
                          </Option>
                        );
                      }
                    )}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col xs={12} sm={12}>
            <div className="formInputStyle">
              <label className="formLabelStyle">{deviceType}</label>
              <Form.Item
                name="elevator"
                rules={[
                  {
                    required: true,
                    message: "Please Select Elevator!",
                  },
                ]}
              >
                <Select
                  placeholder={deviceType}
                  className="ReportModal"
                  onChange={(value, key) => {
                    setElevatorsDeviceID(
                      selectIndex?.store &&
                      filterData?.[selectIndex?.country]?.CITIES?.[
                          selectIndex?.city
                        ].LOCATION?.[selectIndex?.location]?.STORE?.[
                          selectIndex?.store
                        ]?.DEVICE_DETAILS?.[key.key]?.DEVICE_ID
                    );
                    setSelectIndex({
                      ...selectIndex,
                      elevator: key.key,
                    });
                  }}
                  disabled={
                    selectIndex?.country &&
                    selectIndex?.city &&
                    selectIndex?.location &&
                    selectIndex?.store
                      ? false
                      : true
                  }
                >
                  {selectIndex?.store &&
                    filterData?.[selectIndex?.country]?.CITIES?.[
                      selectIndex?.city
                    ].LOCATION?.[selectIndex?.location]?.STORE?.[
                      selectIndex?.store
                    ]?.DEVICE_DETAILS?.map((device, deviceIndex) => {
                      return (
                        <Option value={device?.DEVICE_NAME} key={deviceIndex}>
                          {device?.DEVICE_NAME}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
          </Col>

          <Col xs={12} sm={12}></Col>
        </Row>
        <Row className="rowHeadingStyle11" style={{ marginTop: "15px" }}>
          Range of Recurrence
        </Row>
        <Form.Item name="recurrence">
          <Checkbox.Group>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div style={{ width: "150px" }}>
                <Checkbox
                  value="DAILY"
                  key={1}
                  style={{
                    lineHeight: "50px",
                    fontSize: "20px",
                  }}
                >
                  DAILY
                </Checkbox>
              </div>
              <div style={{ width: "150px" }}>
                <Checkbox
                  value="WEEKLY"
                  key={2}
                  style={{
                    lineHeight: "50px",
                    fontSize: "20px",
                  }}
                >
                  WEEKLY
                </Checkbox>
              </div>
              <div style={{ width: "150px" }}>
                <Checkbox
                  value="MONTHLY"
                  key={3}
                  style={{
                    lineHeight: "50px",
                    fontSize: "20px",
                  }}
                >
                  MONTHLY
                </Checkbox>
              </div>
              <div style={{ width: "150px" }}>
                <Checkbox
                  value="YEARLY"
                  key={4}
                  style={{
                    lineHeight: "50px",
                    fontSize: "20px",
                  }}
                >
                  YEARLY
                </Checkbox>
              </div>
            </div>
          </Checkbox.Group>
        </Form.Item>
        <Row className="rowHeadingStyle11" style={{ marginTop: "15px" }}>
          User Notify
        </Row>
        <Form.List name="users" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField },index) => (
                <Row key={index} gutter={[12, 12]}>
                  <Col span={20}>
                    <div className="formInputStyle">
                      <label className="formLabelStyle">User</label>
                      <Form.Item
                        {...restField}
                        name={[name, "USERID"]}
                        rules={[
                          {
                            required: true,
                            message: "Select USERID",
                          },
                        ]}
                      >
                        <Select
                          placeholder="User"
                          className="ReportModal"
                         
                          onChange={(value, key) => {
                           
                            userOptionData.splice(key.key, 1);
                            console.log("value123",value);
                            setSelectedDisable(false);
                          }}
                        >
                          {userOptionData?.map((device, storeIndex) => {
                            return (
                              <Option value={device?.label} key={storeIndex}>
                                {device?.label}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={4}>
                    <Button
                    disabled={removeButtonInvalid()}
                    // disabled={fields.length === 1}
                      style={{
                        marginTop: "25px",
                        width: "110px",
                        height: "50px",
                      }}
                      onClick={() => {
                        if(form?.getFieldValue(['users']).length===1)
                          {
                              form.setFieldsValue({["users"]:[{}]});
                              return;
                          }
                        if (userAgainEditData) {
                          userAgainEditData.splice(name, 1);
                          setApiCalled(!apiCalled);
                        }
                        remove(name);
                      }}
                    >
                      <MinusCircleOutlined /> Remove
                    </Button>
                  </Col>
                </Row>
              ))}
              <Col span={4}>
              
                <Form.Item>
                {/* ||!form.getFieldValue(['users'])?.[form.getFieldValue(['users'])?.length-1]||Object.keys(form.getFieldValue(['users'])?.[0]).length === 0 */}
                  <Button
                    // disabled={userOptionData?.length === 0||selectedDisable}
                    disabled={addButtonInValid()}
                    // disabled={userOptionData?.length === 0||(form.getFieldValue(['users']).length>0&&form.getFieldValue(['users'])?.[form.getFieldValue(['users'])?.length-1]===undefined)}
                    type="primary"
                    onClick={() => {
                      setSelectedDisable(true);
                      // let tempUsers=form.getFieldValue(['users']);
                      // let tempUsers2=form.getFieldValue(['users'])?.filter(item=>item!==undefined);
                      // if(tempUsers?.length!==tempUsers2.length||Object.keys(tempUsers[0]).length === 0)
                      //   {
                      //     return;
                      //   }
                      add();
                      
                      let tempObj = {};
                      userAgainEditData?.map(
                        (item) => (tempObj[item["USERID"]] = 1)
                      );

                      userOptionData = userOptionData?.filter((item) => {
                        if (!tempObj[item["label"]]) {
                          return item;
                        }
                      });
                    }}
                    block
                    icon={<PlusOutlined />}
                    style={{ width: "120px", marginTop: "30px" }}
                  >
                    Add User
                  </Button>
                </Form.Item>
              </Col>
            </>
          )}
        </Form.List>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button
            className="saveButton"
            type="primary"
            style={{ color: "white", marginTop: "20px" }}
            onClick={() => {
              setreportConfigModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="saveButton"
            htmlType="submit"
            type="primary"
            style={{ color: "white", marginTop: "20px" }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      </Spin>
    </div>
  );
};

export default ReportConfigurationForm;
