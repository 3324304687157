
import React from "react";
const deviceType = window.localStorage.getItem("devicetype"); 



export const searchElements = [
  { element: "Countries", type: "Day" },
  { element: "Cities", type: "Week" },
  { element: "Locations", type: "Month" },
];
export const elevatorUtilizationtableColumns = [
  {
    title: "Metrics",
    dataIndex: "metrics",
    key: "metrics",
    align: "left",
  },
  {
    title: "Today",
    dataIndex: "today",
    key: "today",
    align: "center",
  },
  {
    title: "Yesterday",
    dataIndex: "yesterday",
    key: "yesterday",
    align: "center",
  },
];
export const elevatorUtilizationtabledata = [
  {
    key: "1",
    metrics: "Total Floors",
  },
  {
    key: "2",
    metrics: "Total Energy (kWh)",
  },
  {
    key: "3",
    metrics: "Active Hours(HH:MM)",
  },
  {
    key: "4",
    metrics: "Energy per Floor (Wh/Floor)",
  },
];
export const utilizationTables = [
  { title: "Most Utilized" },
  { title: "Least Utilized" },
];
export const alertNotificationcol = [
  {
    title: `${deviceType}`,
    dataIndex: "DEVICE_NAME",
    key: "name",
    align: "left",
  },
  {
    title: "Alert",
    dataIndex: "DESCRIPTION",
    key: "details",
    align: "center",
  },
  {
    title: "Time",
    dataIndex: "CREATED_ON",
    key: "time",
    align: "center",
  },
  {
    title: "Severity",
    dataIndex: "PRIORITY",
    key: "severity",
    align: "center",
    render: (PRIORITY) => {
      if (PRIORITY?.toUpperCase()=== "HIGH") {
        return <div className="HighSeviority center">High</div>;
      } else if (PRIORITY?.toUpperCase()=== "MEDIUM") {
        return <div className="MediumSeviority center">Medium</div>;
      } else if (PRIORITY?.toUpperCase()=== "LOW") {
        return <div className="LowSeviority center">Low</div>;
      } else {
        return <div>NF</div>;
      }
    },
  },
];

export const mostUtilizationCol = [
  {
    title: `${deviceType} Name`,
    dataIndex: "DEVICE_NAME",
    key: "DEVICE_NAME",
    align: "center",
  },
  {
    title: "No. of Floors",
    dataIndex: "noOfFloors",
    key: "noOfFloors",
    align: "center",
  },
  {
    title: "Energy Consumption (kWh)",
    dataIndex: "energyConsumption" || "-",
    key: "energyConsumption",
    align: "center",
  },
  {
    title: "Active Hours(HH:MM)",
    dataIndex: "activeHours",
    key: "activeHours",
    align: "center",
  },
];
