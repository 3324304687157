import React, { useEffect, useState } from "react";
import "../Styles.css";
import { Button, Modal, Spin, Table, Collapse, Row, Col } from "antd";
import {
  CaretRightOutlined,
  EditOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import AddEditStore from "./AddEditStore";
import { onboardingBaseUrl } from "../../../common/BaseUrl";
import axios from "axios";
const PhantomStore = () => {
  const { Panel } = Collapse;
  const accessToken = window.localStorage.getItem("accessToken");
  const [openAddStoreModal, setOpenAddStoreModal] = useState(false);
  const [openEditStoreModal, setOpenEditStoreModal] = useState(false);
  const [addStoreLoader, setAddStoreLoader] = useState(false);
  const [ParentAllData, setParentAllData] = useState(false);
  const [storeTableLoader, setStoreTableLoader] = useState(false);
  const [editStoreData, setEditStoreData] = useState();
  const [allTenants, setAllTenants] = useState([]);

  useEffect(() => {
    setStoreTableLoader(true);
    axios
      .post(
        `${onboardingBaseUrl}/parentDetailsList`,
        {},
        {
          headers: { ["x-access-token"]: accessToken },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          setParentAllData(response?.data);
          setInterval(() => setStoreTableLoader(false), 1000);
        }
      });
  }, [addStoreLoader]);


  const storeColumn = [
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: "10px",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Store Name",
      dataIndex: "STORE_NAME",
      align: "center",
      key: "storename",
    },
    {
      title: "Store Id",
      dataIndex: "STORE_ID",
      align: "center",
      key: "storename",
    },
  ];

  useEffect(() => {
    if (ParentAllData) {
      const newDataArray = ParentAllData?.map((parent) => ({
        ...parent,
        TENANTS: parent.TENANTS.map((tenant) => ({
          ...tenant,
          PARENT_ID: parent.PARENT_ID,
          PARENT_NAME: parent.PARENT_NAME,
        })),
      }));
      const flattenedTenants = newDataArray.flatMap((parent) => parent.TENANTS);
      const filteredData = flattenedTenants.filter(item => item.STORES?.length >= 1);
      setAllTenants(filteredData);
    }
  }, [ParentAllData]);
  return (
    <div className="MainLayoutCss" style={{display:"flex", flexDirection:"column",overflow:"hidden"}}>
      <div className="parentbuttonDiv">
        <Button
          type="primary"
          className="parentButton"
          onClick={() => setOpenAddStoreModal(true)}
        >
          ADD NEW STORE
        </Button>
      </div>
      <div className="content-container" style={{ textAlign: "center" }}>
        <Spin
          className="parentLoader"
          spinning={storeTableLoader}
        >
           {allTenants?.length === 0 && <DeleteOutlined style={{fontSize:"200px"}} />}
          {Array.isArray(allTenants) &&
            allTenants?.map((parent, index) => {
              return (
                <Collapse
                  key={index}
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined
                      style={{ fontSize: "30px" }}
                      rotate={isActive ? 90 : 0}
                    />
                  )}
                  className="parentColapse"
                >
                  <Panel
                    header={
                      <div>
                        <span className="LocationStoreName">
                          {parent?.TENANT_NAME}
                        </span>
                        <div style={{ float: "right" }}>
                          <EditOutlined
                            className="parentEdit"
                            onClick={() => {
                              setEditStoreData(parent);
                              setOpenEditStoreModal(true);
                            }}
                          />
                        </div>
                      </div>
                    }
                  >
                    <div className="parentpanelDiv">
                      <Row>
                        <Col span={12}>
                          <div className="parentContain">
                            <div className="parentHeaderContain">
                              PARENT NAME
                            </div>{" "}
                            : &nbsp;
                            <span>{parent?.PARENT_NAME}</span>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div className="parentContain">
                            <div className="parentHeaderContain">PARENT ID</div>{" "}
                            : &nbsp;
                            <span>{parent?.PARENT_ID}</span>
                          </div>
                        </Col>

                        <Col span={12}>
                          <div className="parentContain">
                            <div className="parentHeaderContain">
                              TENANT NAME
                            </div>{" "}
                            : &nbsp;
                            <span>{parent?.TENANT_NAME}</span>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div className="parentContain">
                            <div className="parentHeaderContain">TENANT ID</div>{" "}
                            : &nbsp;
                            <span>{parent?.TENANT_ID}</span>
                          </div>
                        </Col>
                      </Row>
                      <div style={{ marginTop: "10px" }}></div>
                      <Table
                        className="MainTable"
                        columns={storeColumn}
                        dataSource={parent?.STORES}
                        pagination={false}
                        rowKey={(record) => record?.STORE_ID}
                      />
                    </div>
                  </Panel>
                </Collapse>
              );
            })}
        </Spin>
      </div>
      <Modal
        title={<h4 style={{ textAlign: "center" }}>Add Store</h4>}
        open={openAddStoreModal}
        onOk={() => setOpenAddStoreModal(false)}
        onCancel={() => setOpenAddStoreModal(false)}
        okText="Confirm "
        cancelText="Cancel"
        width={700}
        footer={null}
        destroyOnClose={true}
      >
        <AddEditStore
          setOpenAddStoreModal={setOpenAddStoreModal}
          addStoreLoader={addStoreLoader}
          setAddStoreLoader={setAddStoreLoader}
          ParentAllData={ParentAllData}
        />
      </Modal>
      <Modal
        title={<h4 style={{ textAlign: "center" }}>Edit Store</h4>}
        open={openEditStoreModal}
        onOk={() => setOpenEditStoreModal(false)}
        onCancel={() => setOpenEditStoreModal(false)}
        okText="Confirm "
        cancelText="Cancel"
        width={700}
        footer={null}
        destroyOnClose={true}
      >
        <AddEditStore
          setOpenEditStoreModal={setOpenEditStoreModal}
          editStoreData={editStoreData}
          setAddStoreLoader={setAddStoreLoader}
          addStoreLoader={addStoreLoader}
        />
      </Modal>
    </div>
  );
};

export default PhantomStore;
