import React, { useState } from "react";
import "antd/dist/antd.css";
import {Table} from "antd";
import "./Styles.css";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { RTMBaseUrl } from "../../common/BaseUrl";
import axios from "axios";

const EvelatorList = () => {
  const [elevatorList, setelevatorList] = useState();
  const [elevatorApiListData, setElevatorApiListData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const accessToken = window.localStorage.getItem("accessToken");
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const deviceType = window.localStorage.getItem("devicetype"); 
  useEffect(() => {
    setIsLoading(true);
    axios
      .post(
        `${RTMBaseUrl}/elevatorList`,
        {
          PARENT_ID: userProfile?.["custom:parent_id"],
          TENANT_ID: userProfile?.["custom:tenant_id"],
          page: 1,
          limit: 20,
        },
        { headers: { ["x-access-token"]: accessToken } }
      )
      .then((response) => {
        setElevatorApiListData(response?.data?.data)
      })
  }, []);
  useEffect(()=>{
    if(elevatorApiListData){
      let listData = elevatorApiListData.flatMap(entry =>
        entry.DEVICE_DETAILS.map(device => ({
          HARDWARE_ID: device.HARDWARE_ID,
          DEVICE_ID: device.DEVICE_ID,
          DEVICE_NAME: device.DEVICE_NAME,
          PASSENGER_CAPACITY: device.PASSENGER_CAPACITY,
          ELEVATOR_TYPE: device.ELEVATOR_TYPE,
          ELEVATOR_MAKE: device.ELEVATOR_MAKE,
          ELEVATOR_MODAL: device.ELEVATOR_MODAL,
          TOTAL_ENERGY_CONSUMPTION: device.TOTAL_ENERGY_CONSUMPTION,
          APPLIANCE_STATUS: device.APPLIANCE_STATUS,
          STORE_NAME: entry.STORE_NAME,
          LOCATION: entry.LOCATION,
        }))
      );
      setelevatorList([...listData]);
      setIsLoading(false);
    }

   },[elevatorApiListData])


  const navigate = useNavigate();
  const columns = [
    {
      title: `${deviceType} Name`,
      dataIndex: "DEVICE_NAME",
      key: "NAME",
      align: 'center', 
    },
    {
      title: "Building",
      dataIndex: "STORE_NAME",
      key: "OWNER",
      align: 'center', 
    },

    {
      title: "Location",
      dataIndex: "LOCATION",
      key: "LOCATION",
      align: 'center', 
    },
    {
      title: "Status",
      dataIndex: "APPLIANCE_STATUS",
      key: "STATUS",
      align: 'center', 
      render: (STATUS) => {
        if (STATUS === "ACTIVE") {
          return <div className="Online">Active</div>;
        } else if (STATUS === "IDLE") {
          return <div className="idle">Idle</div>;
        } else if (STATUS === "OFFLINE") {
          return <div className="Offline">Offline</div>;
        } else {
          return <div className="idle">NF</div>;
        }
      },
    },
  ];

  const [val, setVal] = useState("");
  const [tableFilter, settableFilter] = useState([]);
  let filterArr = ['APPLIANCE_STATUS','DEVICE_NAME','STORE_NAME','LOCATION']
  let finalData = []
  const tableSearch = (e) => {
    if (e.target.value != "") {
      setVal(e.target.value);
      for(let o of elevatorList){
        for(let key of filterArr){
          if(o[key]?.toLowerCase().includes(e.target.value?.toLowerCase())){
            finalData.push(o)
            break
          }
        }
      }
      settableFilter([...finalData]);
    } else {
      setVal(e.target.value);
      setelevatorList([...elevatorList]);
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h4 className="headText" >
          {deviceType}s
        </h4>

        <input
          className="searchInput"
          style={{
          
            marginTop: "-10px",
            marginBottom: "10px",
          }}
          placeholder="Search.."
          onChange={tableSearch}
        />
      </div>
      {val.length > 0 ? (
        <Table
          className="MainTablle"
          columns={columns}
          dataSource={tableFilter}
          style={{cursor:"pointer"}}
          pagination={false}
          scroll={{
            y: 130,
          }}
          onRow={(elevator) => ({
            onClick: () => {
              navigate("/real-time-monitoring-perameter", {
                state:{ states:elevator},
              });
            },
          })}
        />
      ) : (
        <Table
          className="MainTablle"
          columns={columns}
          dataSource={elevatorList}
          pagination={false}
          style={{cursor:"pointer"}}
          scroll={{
            y: 150,
          }}
          onRow={(elevator) => ({
            onClick: () => {
              navigate("/real-time-monitoring-perameter", {
                state:{ states:elevator},
              });
            },
          })}
        />
      )}
      <h4>
        {!isLoading ? (
          <div style={{ marginTop: "10px" }}>
            {" "}
            Total {deviceType}s : {elevatorList?.length}
          </div>
        ) : (
          ""
        )}
      </h4>
    </div>
  );
};
export default EvelatorList;
