/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { onboardingBaseUrl } from "../../common/BaseUrl";
import axiosRetry from "axios-retry";

const DashboardFilter = ({
  setDashboardMapData,
}) => {
  const [filterData, setFilterData] = useState();


  const accessToken = window.localStorage.getItem("accessToken");

  useEffect(() => {
    axios
      .post(
        `${onboardingBaseUrl}/store-count/data`,
        {},
        { headers: { ["x-access-token"]: accessToken } }
      )
      .then((response) => {
        
        setFilterData(response?.data?.data);
      })

    axiosRetry(axios, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      },
      retryCondition: (error) => error.message === "Network Error",
    });
  }, []);
  useEffect(() => {
    let mapArray = [];

    if (filterData) {
      filterData?.map((country) => {
        country?.CITIES?.map((city) => {
          city?.LOCATION?.map((location) =>{
            location?.  STORE?.map((store) => {
              mapArray.push(store);
            });
          })
        
        });
      });
      setDashboardMapData([...mapArray]);
    }
  }, [filterData]);

  // const disabledDate = (current) => {
  //   if (!dates) {
  //     return false;
  //   }
  //   const tooLate = dates[0] && current.diff(dates[0], "days") > startDate;
  //   const tooEarly = dates[1] && dates[1].diff(current, "days") > startDate;

  //   return !!tooEarly || !!tooLate;
  // };
  // const onOpenChange = (open) => {
  //   if (open) {
  //     setDates([null, null]);
  //   } else {
  //     setDates(null);
  //   }
  // };

  return (
    <></>
    // <Row
    //   // style={{ backgroundColor: "#a0d7ef", padding: "10px" }}
    //   // gutter={[24, 24]}
    // >
    //   {/* <Col
    //     xl={{ span: 3 }}
    //     lg={{ span: 3 }}
    //     md={{ span: 8 }}
    //     sm={{ span: 10 }}
    //     xs={{ span: 24 }}
    //     style={{ margin: "0px 5px 0px 5px" }}
    //   >
    //     <Select
    //       style={{
    //         width: "100%",
    //       }}
    //       allowClear
    //       showSearch
    //       onChange={(value, key) => {
    //         setSelectIndex({ ...selectIndex, country: key.key });
    //       }}
    //       placeholder="Countries"
    //     >
    //       {filterData?.map((country, index) => {
    //         return (
    //           <Option value={country?.COUNTRY} key={index}>
    //             {country?.COUNTRY}
    //           </Option>
    //         );
    //       })}
    //     </Select>
    //   </Col> */}
    //   {/* <Col
    //     xl={{ span: 3 }}
    //     lg={{ span: 3 }}
    //     md={{ span: 8 }}
    //     sm={{ span: 10 }}
    //     xs={{ span: 24 }}
    //     style={{ margin: "0px 5px 0px 5px" }}
    //   >
    //     <Select
    //       style={{
    //         width: "100%",
    //       }}
    //       allowClear
    //       showSearch
    //       onChange={(value, key) => {
    //         setSelectIndex({ ...selectIndex, city: key.key });
    //       }}
    //       placeholder="Cities"
    //       disabled={selectIndex?.country ? false : true}
    //     >
    //       {filterData?.[parseInt(selectIndex?.country)]?.CITIES?.map(
    //         (city, cityIndex) => {
    //           return (
    //             <Option value={city?.CITY} key={cityIndex}>
    //               {city?.CITY}
    //             </Option>
    //           );
    //         }
    //       )}
    //     </Select>
    //   </Col> */}
    //   {/* <Col
    //     xl={{ span: 3 }}
    //     lg={{ span: 3 }}
    //     md={{ span: 8 }}
    //     sm={{ span: 10 }}
    //     xs={{ span: 24 }}
    //     style={{ margin: "0px 5px 0px 5px" }}
    //   >
    //     <Select
    //       style={{
    //         width: "100%",
    //       }}
    //       allowClear
    //       showSearch
    //       onChange={(value, key) => {
    //         setSelectIndex({ ...selectIndex, location: key.key });
    //       }}
    //       placeholder="Locations"
    //       disabled={selectIndex?.country && selectIndex?.city ? false : true}
    //     >
    //       {selectIndex?.city &&
    //         filterData?.[parseInt(selectIndex?.country)]?.CITIES?.[
    //           parseInt(selectIndex?.city)
    //         ].STORE?.map((store, storeIndex) => {
    //           return (
    //             <Option value={store?.STORE_NAME} key={storeIndex}>
    //               {store?.STORE_NAME}
    //             </Option>
    //           );
    //         })}
    //     </Select>
    //   </Col> */}
    //   {/* <Row>
    //     <div

    //       style={{ marginBottom: "5px" }}
    //     >
    //       <Button
    //         style={{
    //           backgroundColor: headerFilter.Daily && "#008ecf",
    //           color: headerFilter.Daily && "white",
    //         }}
    //         onClick={() => {
    //           setHeaderFilter({ Daily: true, Weekly: false, Monthly: false });
    //         }}
    //       >
    //         <BulbOutlined />
    //         Daily
    //       </Button> */}
    //       {/* {searchElements?.map((item, index) => {
    //       return (
    //         <Button
    //           key={index}
    //           onClick={(item, index, value) => {
    //           
    //           }}
    //           value={item?.type}
    //         >
    //           <BulbOutlined />
    //           {item?.type}
    //         </Button>
    //       )
    //     })} */}
    //     {/* </div>
    //     <div */}
    //       {/* // xl={{ span: 6 }}
    //       // lg={{ span: 6 }}
    //       // md={{ span: 10 }}
    //       // sm={{ span: 10 }}
    //       // xs={{ span: 24 }}
    //     //   style={{ marginBottom: "5px" }}
    //     // >
    //     //   <Button */}
    //     //     style={{
    //     //       backgroundColor: headerFilter.Weekly && "#008ecf",
    //     //       color: headerFilter.Weekly && "white",
    //     //     }}
    //     //     onClick={() => {
    //     //       setHeaderFilter({ Daily: false, Weekly: true, Monthly: false });
    //     //       setStartDate(29);
    //     //     }}
    //     //   >
    //     //     <BulbOutlined />
    //     //     Weekly
    //     //   </Button>
    //     // </div>
    //     // <div
    //       // xl={{ span: 6 }}
    //       // lg={{ span: 6 }}
    //       // md={{ span: 10 }}
    //       // sm={{ span: 10 }}
    //       // xs={{ span: 24 }}
    //     //   style={{ marginBottom: "5px" }}
    //     // >
    //     //   <Button
    //     //     style={{
    //     //       backgroundColor: headerFilter.Monthly && "#008ecf",
    //     //       color: headerFilter.Monthly && "white",
    //     //     }}
    //     //     onClick={() => {
    //     //       setHeaderFilter({ Daily: false, Weekly: false, Monthly: true });
    //     //     }}
    //     //   >
    //     //     <BulbOutlined />
    //     //     Monthly
    //     //   </Button>
    //     // </div>
    //   // </Row>

    //   {/* <Col
    //     xl={{ span: 5 }}
    //     lg={{ span: 8 }}
    //     md={{ span: 10 }}
    //     sm={{ span: 10 }}
    //     xs={{ span: 24 }}
    //     style={{ marginBottom: "5px" }}
    //   >
    //     <RangePicker
    //       value={
    //         dates || value || headerFilter.Weekly
    //           ? [moment().subtract(7, "days"), moment()]
    //           : (headerFilter.Monthly && [
    //               moment().subtract(30, "days"),
    //               moment(),
    //             ]) || [moment(), moment()]
    //       }
    //       disabledDate={disabledDate}
    //       onCalendarChange={(val) => setDates(val)}
    //       onChange={(val, timeString) => {
    //         setValue(val);
    //         setSelectedDates(timeString);
    //        
    //       }}
    //       onOpenChange={onOpenChange}
    //       defaultValue={[moment(), moment()]}
    //     />
    //   </Col> */}
    // </Row>

    // <Col
    //   xl={{ span: 5 }}
    //   lg={{ span: 8 }}
    //   md={{ span: 10 }}
    //   sm={{ span: 10 }}
    //   xs={{ span: 24 }}
    //   style={{ marginBottom: "5px" }}
    // >
    //   <RangePicker
    //     value={
    //       dates || value || selectedDates || headerFilter.Weekly
    //         ? [moment().subtract(7, "days"), moment()]
    //         : (headerFilter.Monthly && [
    //             moment().subtract(30, "days"),
    //             moment(),
    //           ]) || [moment(), moment()]
    //     }
    //     disabledDate={disabledDate}
    //     onCalendarChange={(val) => setDates(val)}
    //     onChange={(val, timeString) => {
    //       setValue(val);
    //       setSelectedDates(timeString);
    //     
    //     }}
    //     onOpenChange={onOpenChange}
    //     defaultValue={[moment(), moment()]}
    //   />
    // </Col>
    // </Row>
  );
};

export default DashboardFilter;
