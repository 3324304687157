/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { EditOutlined } from "@ant-design/icons";
import {
  Col,
  Modal,
  Row,
  Button,

  Card,
  Spin,
} from "antd";
import axios from "axios";
import { onboardingBaseUrl } from "../../../common/BaseUrl";
import "antd/dist/antd.css";
import "../Styles.css";
import MapModal from "./MapModal";
import AddLocationForm from "./AddLocationForm";

const LocationDetails = ({ userData }) => {
  const [mapModal, setMapModal] = useState(false);
  const [storeResult, setStoreResult] = useState();
  const [openNewLocation, setOpenNewLocation] = useState(false);
  const [allLocationData, setAllLocationData] = useState();
  const [editParent, setEditParent] = useState(false)
  const [parentAllData,setParentAllData]= useState()
  const [loadingLocationdata, setLoadingLocationdata] = useState(false);
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const accessToken = window.localStorage.getItem("accessToken");
  const divHeight = window.localStorage.getItem("SpaceHeight");
  const [newdivHeight, setNewdivHeight] = useState();
  useEffect(() => {
    setNewdivHeight(divHeight - 200);
  }, [divHeight]);
  useEffect(() => {
    setLoadingLocationdata(true);
    axios
      .post(
        `${onboardingBaseUrl}/list`,
        {
          PARENT_ID: userProfile?.["custom:parent_id"],
          TENANT_ID: userProfile?.["custom:tenant_id"],
          STORE_ID: userData?.STORE_ID,
        },
        {
          headers: { ["x-access-Token"]: accessToken },
        }
      )
      .then((response) => {
        setAllLocationData(response?.data?.data?.[0]);
        setLoadingLocationdata(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        setLoadingLocationdata(false);
      });
  }, [openNewLocation]);
  useEffect(() => {
    axios
      .post(
        `${onboardingBaseUrl}/parentDetailsList`,
        {},
        {
          headers: { ["x-access-token"]: accessToken },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
            setParentAllData(response?.data);
        }
      });
  }, [editParent]);
  return (
    <div style={{height:`${newdivHeight}px`, overflowY:"auto"}}>
      <Spin spinning={loadingLocationdata}>
        <Card className="LocationCard">
          <div className="spectrum-background_Location">
            <span className="LocationStoreName">
              {allLocationData?.STORE_NAME || "-"}
            </span>
            <div style={{ float: "right" }}>
              <EditOutlined
                style={{ fontSize: "23px", margin: "2px" }}
                onClick={() => {
                  setOpenNewLocation(true);
                  setEditParent(!editParent)
                }}
              />
            </div>
          </div>
          <Row className="LocationInternalData1">
            <Col xs={6} sm={6}>
              Store ID
            </Col>
            <Col xs={1} sm={1}>
              :
            </Col>
            <Col>{allLocationData?.STORE_ID || "-"}</Col>
          </Row>
          <Row className="LocationInternalData">
            <Col xs={6} sm={6}>
              Space Type
            </Col>
            <Col xs={1} sm={1}>
              :
            </Col>
            <Col>{allLocationData?.SPACE_TYPE || "-"}</Col>
          </Row>
          <Row className="LocationInternalData">
            <Col xs={6} sm={6}>
              Location/Address
            </Col>
            <Col xs={1} sm={1}>
              :
            </Col>
            <Col xs={6} sm={6}>
              {allLocationData?.ADDRESS || "-"}
            </Col>
          </Row>
          <Row className="LocationInternalData">
            <Col xs={6} sm={6}>
              Zip Code
            </Col>
            <Col xs={1} sm={1}>
              :
            </Col>
            <Col>{allLocationData?.ZIPCODE || "-"}</Col>
          </Row>
        </Card>
      </Spin>

      <Modal
        title="Select Location"
        width={1000}
        open={mapModal}
        onOk={() => setMapModal(false)}
        onCancel={() => setMapModal(false)}
        footer={
          <Button
            onClick={() => {
              setMapModal(false);
            }}
          >
            OK
          </Button>
        }
        closable={false}
        style={{ marginTop: "-65px" }}
      >
        <MapModal setStoreResult={setStoreResult} />
      </Modal>

      <Modal
        open={openNewLocation}
        onOk={() => setOpenNewLocation(false)}
        onCancel={() => {
          setOpenNewLocation(false);
        }}
        width={1000}
        footer={null}
      >
      <h3 style={{textAlign:"center", fontWeight:"bold"}}>Edit Location Details</h3>
        <div style={{height:"500px", overflow:"auto"}}>
        <AddLocationForm
          setOpenNewLocation={setOpenNewLocation}
          setMapModal={setMapModal}
          storeResult={storeResult}
          editLocation={userData}
          parentAllData={parentAllData}
        />
        </div>
      </Modal>
    </div>
  );
};

export default LocationDetails;
