/* eslint-disable react/prop-types */
import React from "react";
import moment from "moment";
import { DualAxes } from "@ant-design/plots";

const EnergyFloorTrip = ({chartData, selectInterval}) => {
   let floorTripData = []
   let energyfullData = []
   let tempObj = {}
   const getSundayFromWeekNum =(weekNum, year)=>{
    var sunday = new Date(year, 0, ((1 + weekNum * 7)-7));
    if (sunday.getDay() !== 0) {
      sunday.setDate(sunday.getDate() - 1);
    }
    return sunday;
  }
    if (selectInterval === 1) {
      for (let i = 0; i < 24; i++) {
        const energyfloorData = chartData?.floorDetails?.[0]?.FLOOR?.HOURLY?.[i]?.["TOTAL_HOURLY_FLOOR"]
        const energyTripData = chartData?.tripDetails?.[0]?.TRIP?.HOURLY?.[i]?.["TOTAL_HOURLY_TRIP"]
        const energyDatas = chartData?.energyData?.[0]?.ENERGY?.HOURLY?.[i]?.["TOTAL_HOURLY_ENERGY"]

        if (chartData?.floorDetails?.[0]?.FLOOR?.HOURLY?.[i]) {
          floorTripData.push({
            hours: i,
            floors:energyfloorData,
            type: "Floors",
          });
        } else {
          floorTripData.push({ hours: i, floors: 0, type: "Floors" });
        }
        if (chartData?.tripDetails?.[0]?.TRIP?.HOURLY?.[i]) {
          floorTripData.push({
            hours: i,
            floors:energyTripData,
            type: "Trips",
          });
        } else {
          floorTripData.push({ hours: i, floors: 0, type: "Trips" });
        }
        if (chartData?.energyData?.[0]?.ENERGY?.HOURLY?.[i]){

          energyfullData.push({
            hours: i,
            Energy:energyfloorData?(Math.round((energyDatas/energyfloorData)*100000)/ 100):0 ,
            type: "Energy/Floor",
          });
        } else {
          energyfullData.push({ hours: i, Energy: 0, type: "Energy/Floor" });
        }
        if (chartData?.energyData?.[0]?.ENERGY?.HOURLY?.[i]) {
          energyfullData.push({
            hours: i,
            Energy:energyTripData?(Math.round((energyDatas/energyTripData)*100000)/ 100):0 ,
            type: "Energy/Trip",
          });
        } else {
          energyfullData.push({ hours: i, Energy: 0, type: "Energy/Trip" });
        }
      }
    }

    if (selectInterval < 30 && selectInterval > 1) {
      for (let obj of chartData.floorDetails) {
       
        floorTripData.push({
          hours: obj?.DEVICE_TIME?.slice(0, obj?.DEVICE_TIME.indexOf("T")),
          floors: obj?.FLOOR?.TOTAL_DAY_FLOORS || 0,
          type: "Floors",
        });
      }
      for (let obj of chartData.tripDetails) {
        
        floorTripData.push({
          hours: obj?.DEVICE_TIME?.slice(0, obj?.DEVICE_TIME.indexOf("T")),
          floors: obj?.TRIP?.TOTAL_DAY_TRIP || 0,
          type: "Trips",
        });
      }
      for(let obj of floorTripData){
        if(tempObj[obj['hours']]){
          tempObj[obj['hours']][obj['type']] = obj['floors']
        }else{
          tempObj[obj['hours']] = {
            [obj['type']]:obj['floors']
          }
        }
      }
      for (let obj of chartData.energyData) {
        const energyFloorData = tempObj[obj?.DEVICE_TIME?.slice(0, obj?.DEVICE_TIME.indexOf("T"))]?.['Floors']
        energyfullData.push({
          hours: obj?.DEVICE_TIME?.slice(0, obj?.DEVICE_TIME.indexOf("T")),
           Energy: energyFloorData? Math.round(obj?.ENERGY?.TOTAL_DAY_ENERGY*1000/(energyFloorData)*100)/100||0:0,
          type: "Energy/Floor",
        });
      }
      for (let obj of chartData.energyData) {
        const energyTripData =tempObj[obj?.DEVICE_TIME?.slice(0, obj?.DEVICE_TIME.indexOf("T"))]?.['Trips']
        energyfullData.push({
          hours: obj?.DEVICE_TIME?.slice(0, obj?.DEVICE_TIME.indexOf("T")),
           Energy:energyTripData? Math.round(obj?.ENERGY?.TOTAL_DAY_ENERGY*1000/(energyTripData)*100)/100|| 0 :0,
          type: "Energy/Trip",
        });
      }
      
    }
    if (selectInterval < 90 && selectInterval >= 30) {
      chartData?.floorDetails?.sort((a, b) => {
        a.YEAR = String(a.YEAR)
       b.YEAR = String(b.YEAR)
        return a.YEAR.localeCompare(b.YEAR) || a.MONTH - b.MONTH
      })
      
      for (let obj of chartData.floorDetails) {
      
        const days = obj?.WEEK;
      const date = obj?.YEAR;
      let weekDate=getSundayFromWeekNum(days,date )
      floorTripData.push({
          hours: `${(obj?.WEEK)}-(${weekDate?.toLocaleDateString("zh-Hans-CN")})`,
          floors: obj?.FLOOR || 0,
          type: "Floors",
        });
      }
      chartData?.tripDetails?.sort((a, b) => {
        a.YEAR = String(a.YEAR)
       b.YEAR = String(b.YEAR)
        return a.YEAR.localeCompare(b.YEAR) || a.MONTH - b.MONTH
      })
      for (let obj of chartData.tripDetails) {
       
        const days = obj?.WEEK;
      const date = obj?.YEAR;
      let weekDate=getSundayFromWeekNum(days,date )
      floorTripData.push({
          hours: `${(obj?.WEEK)}-(${weekDate?.toLocaleDateString("zh-Hans-CN")})`,
          floors: obj?.TRIP || 0,
          type: "Trips",
        });
      }
      for(let obj of floorTripData){
        if(tempObj[obj['hours']]){
          tempObj[obj['hours']][obj['type']] = obj['floors']
        }else{
          tempObj[obj['hours']] = {
            [obj['type']]:obj['floors']
          }
        }
      }
      for (let obj of chartData.energyData) {
       
        const days = obj?.WEEK;
      const date = obj?.YEAR;
      
      let weekDate=getSundayFromWeekNum(days,date )
      const energyFloorData = tempObj[`${(obj?.WEEK)}-(${weekDate?.toLocaleDateString("zh-Hans-CN")})`]?.['Floors']
      energyfullData.push({
          hours: `${(obj?.WEEK)}-(${weekDate?.toLocaleDateString("zh-Hans-CN")})`,
          Energy:energyFloorData?  Math.round(obj?.ENERGY /(energyFloorData) * 100000) / 100 || 0 :0,
          type: "Energy/Floor",
        });
      }
      for (let obj of chartData.energyData) {
       
       
        const days = obj?.WEEK;
      const date = obj?.YEAR;
      let weekDate=getSundayFromWeekNum(days,date )
      const energyTripData =tempObj[`${(obj?.WEEK)}-(${weekDate?.toLocaleDateString("zh-Hans-CN")})`]?.['Trips']
      energyfullData.push({
          hours: `${(obj?.WEEK)}-(${weekDate?.toLocaleDateString("zh-Hans-CN")})`,
          Energy: energyTripData? Math.round(obj?.ENERGY /(energyTripData) * 100000) / 100 || 0 :0,
          type: "Energy/Trip",
        });
      }
    }
    if (selectInterval >= 90) {
      for (let key in chartData){
        chartData[key].sort((a,b) => {
          a.YEAR = String(a.YEAR)
          b.YEAR = String(b.YEAR)
          return a.YEAR.localeCompare(b.YEAR) || a.MONTH - b.MONTH;
        })
      
      }
    
      for (let obj of chartData.floorDetails) {
      
        floorTripData.push({
          hours: `${moment()
            .month(obj?.MONTH - 1)
            .format("MMM")} ${obj?.YEAR}`,
          floors: obj?.FLOOR || 0,
          type: "Floors",
        });
      }

      for (let obj of chartData.tripDetails) {
      
        floorTripData.push({
          hours: `${moment()
            .month(obj?.MONTH - 1)
            .format("MMM")} ${obj?.YEAR}`,
          floors: obj?.TRIP || 0,
          type: "Trips",
        });
      }
      for(let obj of floorTripData){
        if(tempObj[obj['hours']]){
          tempObj[obj['hours']][obj['type']] = obj['floors']
        }else{
          tempObj[obj['hours']] = {
            [obj['type']]:obj['floors']
          }
        }
      }
      for (let obj of chartData.energyData) {
        const energyFloorDaata = tempObj[`${moment().month(obj?.MONTH - 1).format("MMM")} ${obj?.YEAR}`]?.['Floors']
        energyfullData.push({
          hours: `${moment()
            .month(obj?.MONTH - 1)
            .format("MMM")} ${obj?.YEAR}`,
            Energy: energyFloorDaata ? Math.round(obj?.ENERGY/(energyFloorDaata) * 100000) / 100 || 0:0,
          type: "Energy/Floor",
        });
       
      }
      for (let obj of chartData.energyData) { 
        const energyTripDaata= tempObj[`${moment() .month(obj?.MONTH - 1).format("MMM")} ${obj?.YEAR}`]?.['Trips']
        energyfullData.push({
          hours: `${moment()
            .month(obj?.MONTH - 1)
            .format("MMM")} ${obj?.YEAR}`,
            Energy: energyTripDaata? Math.round(obj?.ENERGY/(energyTripDaata) * 100000) / 100 || 0 :0,
          type: "Energy/Trip",
        });
      }
    }
  
  const config = {
    data: [floorTripData, energyfullData],
    xField: "hours",
    yField: ["floors", "Energy"],
    yAxis:[{
      title: {
        display: true,
        text: "Floors & Trips",
      },
    },
    {
      title: {
        display: true,
        text: "Energy(W)",
        
      },
      label: {
        formatter: (value) =>{
          const numericValue = parseFloat(value);
          if (!isNaN(numericValue)) {
            return numericValue.toFixed(2); // Round off to 2 decimal places
          }
          return value;
        },
      },
      
    }]
     ,
    xAxis: {
      title: {
        display: true,
        text:
          selectInterval === 1
            ? "Hour (0-23)"
            : selectInterval <= 7
            ? "Date"
            : selectInterval >= 90
            ? "Months"
            : selectInterval >= 30
            ? "Weeks"
            : selectInterval < 30
            ? "Daily"
            : "Date",
      },
    },
    geometryOptions: [
      {
        geometry: "column",
        isGroup: true,
        seriesField: "type",
        label: {
          position: "top",
      offsetY: 12,
        },
        columnWidthRatio: 0.7,
      },
      {
        geometry: "line",
        seriesField: "type",
        // color: '#dbc114',
        lineStyle: {
          lineWidth: 2,
        },
      },
    ],
  };
  return (
    <div>
      <DualAxes {...config} height={250}/>
    </div>
  );
};

export default EnergyFloorTrip;
