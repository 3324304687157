/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Row, Button, Switch, Form, Input, notification, Spin, Modal } from "antd";
import { PlusOutlined,ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import "../Styles.css";
import { onboardingBaseUrl } from "../../../common/BaseUrl";

const AddEditParent = ({
  editTenantData,
  setAddTenantModal,
  setEditTenantModal,
  setParentListLoader,
  parentListLoader,
}) => {
  const [formLoader, setFormLoader] = useState(false);
  const accessToken = window.localStorage.getItem("accessToken");
  const userName = window.localStorage.getItem("userName");
  const [form] = Form.useForm();
  const [editedFields, setEditedFields] = useState({});
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const handleChangeSwitch = (checked, name, setFieldsValue) => {
    setFieldsValue({
      [name]: checked,
    });
  };

  useEffect(() => {
    if (editTenantData) {
      form.setFieldsValue({
        PARENT_NAME: editTenantData?.PARENT_NAME,
        TENANTS: editTenantData?.TENANTS,
      });

      // Initialize the edited fields state with all tenant names
      const editedFieldsCopy = {};
      editTenantData?.TENANTS?.forEach((tenant, index) => {
        editedFieldsCopy[index] = tenant.TENANT_NAME;
      });
      setEditedFields(editedFieldsCopy);
    }
  }, [editTenantData]);

  const onParentFormSubmit = (value) => {
    if (value.TENANTS) {
      value.TENANTS = value.TENANTS.map((tenant) => ({
        ...tenant,
        ONLY_ELECTRICAL_PARAMETER: tenant.ONLY_ELECTRICAL_PARAMETER || false,
      }));
    }
    setFormLoader(true);
    if (editTenantData) 
    {
      axios
      .post(
        `${onboardingBaseUrl}/editParentDetails/${editTenantData?.PARENT_ID}`,
        {
          PARENT_NAME: value?.PARENT_NAME,
          PARENT_ID:editTenantData?.PARENT_ID,
          EDIT_PARENT_NAME: editTenantData?.PARENT_NAME!=value?.PARENT_NAME ? true :undefined,
          CREATED_BY: editTenantData?.CREATED_BY,
          CREATED_ON: editTenantData?.CREATED_ON,
          UPDATED_BY: userName,
          UPDATED_ON: moment().format("YYYY-MM-DDTHH:mm:ss"),
          TENANTS: value?.TENANTS,
        },
        {
          headers: { ["x-access-token"]: accessToken },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          if (response?.data === "Parent name is already taken") {
            notification.error({ description: response?.data });
          } else {
            notification.success({ description: response?.data });
          }
          setEditTenantModal(false);
          setParentListLoader(!parentListLoader);
          setFormLoader(false);
        }
      });
    } else {
      axios
      .post(
        `${onboardingBaseUrl}/addParentDetails`,
        {
          PARENT_NAME: value?.PARENT_NAME,
          CREATED_BY: userName,
          CREATED_ON: moment().format("YYYY-MM-DDTHH:mm:ss"),
          UPDATED_BY: userName,
          UPDATED_ON: moment().format("YYYY-MM-DDTHH:mm:ss"),
          TENANTS: value?.TENANTS,
        },
        {
          headers: { ["x-access-token"]: accessToken },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          if (response?.data === "Parent name is already taken") {
            notification.error({ description: response?.data });
          } else {
            notification.success({ description: response?.data });
          }
          setAddTenantModal(false);
          setParentListLoader(!parentListLoader);
          setFormLoader(false);
        }
      })

    }
  };

  const handleTenantNameChange = (index, value) => {
    const tenants = form.getFieldValue("TENANTS");
    const editedFieldsCopy = { ...editedFields };
  
    if (editedFieldsCopy[index] !== undefined) {
      tenants[index].TENANT_NAME = value;
      tenants[index].EDIT_TENANT_NAME = true;
      tenants[index].ADD_TENANT_NAME = undefined;
    } else {
      tenants[index] = {
        TENANT_NAME: value,
        ONLY_ELECTRICAL_PARAMETER: false,
      };
      if (editTenantData) {
        tenants[index].ADD_TENANT_NAME = true;
      }
    }
  
    setEditedFields(editedFieldsCopy);
  
    form.setFieldsValue({
      TENANTS: tenants,
    });
  };
  const showRemoveModal = (index) => {
    const tenants = form.getFieldValue("TENANTS");
    if(editTenantData){
      if (tenants[index]?.TENANT_NAME) {
        setRemoveModalVisible(true);
      } else {
        tenants.splice(index, 1)
        form.setFieldsValue({
          TENANTS: tenants,
        });
      }
    }else{
    tenants.splice(index, 1)
    form.setFieldsValue({
      TENANTS: tenants,
    });
    }
    
  };
  const handleRemoveConfirm = (index) => {
    const tenants = form.getFieldValue("TENANTS");
    tenants.splice(index, 1); 
    form.setFieldsValue({
      TENANTS: tenants,
    });
    setRemoveModalVisible(false);
  };
  return (
    <div>
      <Spin
        className="parentFormLoader"
        tip={editTenantData ? "Editing Data ..." : "Adding Data..."}
        delay={500}
        spinning={formLoader}
      >
        <Form
          name="parent form"
          form={form}
          initialValue={{}}
          onFinish={onParentFormSubmit}
        >
          <div className="formInputStyleLocation">
            <label className="formLabelStyle">Parent Name</label>
            <Form.Item
              name="PARENT_NAME"
              rules={[
                {
                  required: true,
                  message: "Please Enter Parent Name",
                },
              ]}
            >
              <Input className="formInput101" />
            </Form.Item>
          </div>
          <Row className="rowHeadingStyle11" style={{ marginTop: "25px" }}>
            {editTenantData ? "Edit Tenant" : "Add Tenant"}
          </Row>
          <Form.List
            name="TENANTS"
            initialValue={editTenantData ? editTenantData.TENANTS : [{}]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => {
                  // const isEdited = editedFields[index] !== undefined;
                  return (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#edede8",
                        padding: "10px",
                        height: "90px",
                      }}
                    >
                      <div
                        className="formInputStyleLocation"
                        style={{ width: "250px" }}
                      >
                        <label className="formLabelStyle">Tenant Name</label>
                        <Form.Item
                          {...restField}
                          name={[name, "TENANT_NAME"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Tenant name",
                            },
                          ]}
                        >
                          <Input
                            className="formInput101"
                            placeholder="Tenant Name"
                            onChange={(e) =>
                              handleTenantNameChange(index, e.target.value)
                            }
                          />
                        </Form.Item>
                      </div>

                      <div style={{ textAlign: "center" }}>
                        <label className="formLabelStyle">
                          Electrical Parameter
                        </label>
                        <Form.Item
                          {...restField}
                          name={[name, "ONLY_ELECTRICAL_PARAMETER"]}
                          getValueFromEvent={(checked) => checked}
                          valuePropName="checked"
                          normalize={(checked) => checked}
                        >
                          <Switch
                            onChange={(checked) =>
                              handleChangeSwitch(
                                checked,
                                [name, "ONLY_ELECTRICAL_PARAMETER"],
                                form.setFieldsValue
                              )
                            }
                          />
                        </Form.Item>
                      </div>

                      <Button 
                      type="primary" onClick={() => showRemoveModal(index)}
                      disabled={fields.length === 1}>
                        remove
                      </Button>
                      
                    </div>
                  );
                })}

                <Form.Item>
                  <Button
                    style={{ marginTop: "30px", color: "white" }}
                    type="primary"
                    onClick={() => {
                      add();
                    }}
                    icon={<PlusOutlined />}
                  >
                    Add Tenant
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: "10px", color: "white" }}
                onClick={() => {
                  form.resetFields();
                  setEditTenantModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Spin>
      <Modal
        title="Remove Tenant"
        open={removeModalVisible}
        onOk={handleRemoveConfirm}
        onCancel={()=>setRemoveModalVisible(false)}
      >
          <div
          style={{ textAlign: "center", fontSize: "80px", color: "#008ecf" }}
        >
          <ExclamationCircleOutlined />
        </div>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          ARE YOU SURE YOU WANT TO REMOVE THIS TENANT?
        </h3>
      </Modal>
    </div>
  );
};

export default AddEditParent;
