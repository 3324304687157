import React, { useState, useEffect } from "react";
import axios from "axios";
import {onboardingBaseUrl } from "../../common/BaseUrl";
import { Tabs, Modal, Popover, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import AlertsTab from "./AlertsTab";
import CurrentEventsTab from "./CurrentEventsTab";
import NewEventModal from "./NewEventModal";
import "./Styles.css";
const AlertsAndNotifications = () => {
  const accessToken = window.localStorage.getItem("accessToken");
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const role = window.localStorage.getItem("role");
  let allRole = role?.split(",");
  const [screensHeight, setcreensHeight] = useState("Unknown");
  const [screensWidth, setcreensWidth] = useState("Unknown");
  const [selectFilter, setSelectFilter] = useState();
  const [selectTab, setSelectTab] = useState(false);
  const [eventModal, setEventModal] = useState(false);
  const [listAPI, setlistApi] = useState(false);
  const [userAccess, setUserAccess] = useState();
  useEffect(() => {
    setUserAccess(allRole.some((val) => val === "operator"));
  }, [allRole]);
  useEffect(() => {
    const params = {
      PARENT_ID: userProfile?.["custom:parent_id"],
      TENANT_ID: userProfile?.["custom:tenant_id"],
    };
    axios
      .post(`${onboardingBaseUrl}/store-count/data`, params, {
        headers: { ["x-access-token"]: accessToken },
      })
      .then((response) => {
        setSelectFilter(response?.data?.data);
        // setIsLoading(false);
      })
  }, []);
  const operations = (
    <Popover content="Define Alert Notifications" placement="bottomLeft">
      <Button
      type="primary"
      onClick={() => setEventModal(true)}
        style={{
          marginRight: "10px",
          color: "white",
          height: "35px",
        }}
      >
        ADD ALERT CONFIGURATION <PlusOutlined/>
      </Button>
    </Popover>
  );
 

  const updateScreenSize = () => {
  const screenHeight = window.innerHeight;
  const screenWidth =window.innerWidth
    setcreensHeight(screenHeight);
    setcreensWidth(screenWidth)
  };

  useEffect(() => {
    updateScreenSize();
    window.addEventListener("resize", updateScreenSize);
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);


  return (
    <div>
      <Tabs
        tabBarExtraContent={!userAccess ?operations:""}
        defaultActiveKey={selectTab === true ? "2" : "1"}
        className="tabBottomStyle"
      >
        <Tabs.TabPane tab="Alerts" key="1" style={{ position: "absolute" }}>
          <AlertsTab
          userAccess={userAccess}
            setSelectTab={setSelectTab}
            selectTab={selectTab}
            setSelectFilter={setSelectFilter}
            selectFilter={selectFilter}
            screensWidth={screensWidth}
            screensHeight={screensHeight}
          />
         
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Alert Configurations"
          key="2"
          style={{ position: "absolute" }}
        >
          <CurrentEventsTab
            setlistApi={setlistApi}
            listAPI={listAPI}
            setSelectTab={setSelectTab}
            selectTab={selectTab}
            setSelectFilter={setSelectFilter}
            selectFilter={selectFilter}
            userAccess={userAccess}
            screensWidth={screensWidth}
            screensHeight={screensHeight}
          />
        </Tabs.TabPane>
      </Tabs>
      {eventModal&&<Modal
        open={eventModal}
        onOk={() => setEventModal(false)}
        onCancel={() => setEventModal(false)}
        destroyOnClose={true}
        width={800}
        footer={null}
      >
        <div style={{height:"75vh", overflowY:"auto"}}>
        <NewEventModal
          setlistApi={setlistApi}
          listAPI={listAPI}
          setEventModal={setEventModal}
          setSelectTab={setSelectTab}
          selectTab={selectTab}
          setSelectFilter={setSelectFilter}
          selectFilter={selectFilter}
       
        />
        </div>
      </Modal>}
    </div>
  );
};

export default AlertsAndNotifications;
