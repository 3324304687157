/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, createRef, useEffect, useRef } from "react";
import {
  RetweetOutlined,
  PrinterOutlined,
  DownloadOutlined,
  PieChartOutlined,
  BarChartOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Table, Tabs, Spin } from "antd";
import { useScreenshot, createFileName } from "use-react-screenshot";
import "antd/dist/antd.css";
import "./Styles.css";
import {
  floorsTablecolumns,
  reportTablecolumns,
} from "./ReportsAndAnalysisData";
import axios from "axios";
import { RTMBaseUrl } from "../../common/BaseUrl";
import axiosRetry from "axios-retry";
import DemoPie from "../../common/DemoPie";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import FloorChart from "../realTimeMonitoring/elevatorDetails/Charts/FloorChart";

import EnergyChart from "../realTimeMonitoring/elevatorDetails/Charts/EnergyChart";
import DoorChart from "../realTimeMonitoring/elevatorDetails/Charts/DoorCHart";
import CsvButton from "../../services/csvConverter/CsvButton";
const ReportAnalysisCharts = ({
  parameterCheckbox,
  selectedDates,
  selectInterval,
  dateRangeValue,
  applyButton,
  selectIndex,
  reportAnaFilter,
}) => {
  const deviceType = window.localStorage.getItem("devicetype"); 
  const [floorRefresh, setFfloorRefresh] = useState(false);
  const [doorRefresh, setDdoorRefresh] = useState(false);
  const [activeHRefresh, setActiveHRefresh] = useState(false);
  const [elevatorSRefresh, setElevatorSRefresh] = useState(false);
  const [energyCRefresh, setEnergyCRefresh] = useState(false);
  const [activeHoursChart, setActiveHoursChart] = useState();
  const [activeHoursPie, setActiveHoursPie] = useState();
  const [totalFloorsChart, setTotalFloorsChart] = useState();
  const [floorLoader, setFloorLoader] = useState(false);
  const [doorLoader, setDoorLoader] = useState(false);
  const [energyLoader, setEnergyLoader] = useState(false);
  const [elevatorLoader, setElevatorLoader] = useState(false);
  const [eleActiveLoader, setEleActiveLoader] = useState(false);
  const [energyConsumptionData, setEnergyConsumptionData] = useState();
  const [doorCycleData, setDoorCycleData] = useState();
  const [selectFilter, setSelectFilter] = useState();
  const [elevatorStatus, setElevatorStatus] = useState();

  const energyRef = createRef();
  const totalRef = createRef();
  const doorRef = createRef();
  const activeRef = createRef();
  const statusRef = createRef();
  const [screenshot, takeScreenShot] = useScreenshot({
    type: "image/jpg",
    quality: 1.0,
  });

  const floorTabs = [
    {
      tab: <BarChartOutlined />,
      chart: (
        <FloorChart
          called={"reportsFloor"}
          floorData={totalFloorsChart}
          selectInterval={selectInterval}
        />
      ),
    },
    // { tab: <TableOutlined />, chart: "table" },
  ];
  const doorCycleTabs = [
    {
      tab: <BarChartOutlined />,
      chart: (
        <DoorChart
          called={"reportsDoor"}
          chartData={doorCycleData}
          selectInterval={selectInterval}
        />
      ),
    },
    // { tab: <TableOutlined />, chart: "table" },
  ];
  const hoursTabs = [
    {
      tab: <PieChartOutlined />,
      chart: (
        <DemoPie chartData={activeHoursPie} called={"reportsActiveHours"} />
      ),
    },
    // { tab: <TableOutlined />, chart: "table" },
  ];
  const elevatorTabs = [
    {
      tab: <PieChartOutlined />,
      chart: <DemoPie chartData={elevatorStatus} called={"reportsStatus"} />,
    },
  ];
  const accessToken = window.localStorage.getItem("accessToken");
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));

  let chartTitle = useRef();
  const downloadScreenshotjpg = (ref, value) => {
    chartTitle = value;
    takeScreenShot(ref.current).then(downloadjpg);
  };

  const downloadjpg = (
    screenshot,
    { name = `${chartTitle}`, extension = "jpg" } = {}
  ) => {
    const a = document.createElement("a");
    a.href = screenshot;
    a.download = createFileName(extension, name);
    a.click();
  };
  
  const handleDownloadPdf = (id, title) => {
    const input = document.getElementById(id);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "l",
        unit: "pt",
        format: [1100, 450],
      });
      var width = pdf.internal.pageSize.getWidth();
var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
      // pdf.output('dataurlnewwindow');
      pdf.save(`${title}.pdf`);
    });
  };
  let currentT = moment().format("HH:mm:ss");

  useEffect(() => {
    selectInterval > 90
      ? setSelectFilter("Monthly")
      : selectInterval > 1 && selectInterval <= 30
      ? setSelectFilter("Daily")
      : selectInterval > 30 && selectInterval <= 90
      ? setSelectFilter("Weekly")
      : setSelectFilter("Hourly");
  }, [selectInterval]);

  useEffect(() => {
    const params = {
      from: selectedDates
        ? new Date(`${selectedDates?.[0]}T${currentT}`).getTime()
        : dateRangeValue === "daily"
        ? new Date(
            `${moment().subtract(7, "days").format("YYYY-MM-DD")}T${currentT}`
          ).getTime()
        : (dateRangeValue === "weekly"
            ? new Date(
                `${moment()
                  .subtract(30, "days")
                  .format("YYYY-MM-DD")}T${currentT}`
              ).getTime()
            : dateRangeValue === "monthly" &&
              new Date(
                `${moment()
                  .subtract(90, "days")
                  .format("YYYY-MM-DD")}T${currentT}`
              ).getTime()) ||
          new Date(`${moment().format("YYYY-MM-DD")}T${currentT}`).getTime(),
      to: selectedDates
        ? new Date(`${selectedDates?.[1]}T${currentT}`).getTime()
        : new Date(`${moment().format("YYYY-MM-DD")}T${currentT}`).getTime(),
      PARENT_ID: userProfile?.["custom:parent_id"],
      TENANT_ID: userProfile?.["custom:tenant_id"],
      COUNTRY:
        selectIndex?.country &&
        reportAnaFilter?.[selectIndex?.country]?.COUNTRY,
      CITY:
        selectIndex?.city &&
        reportAnaFilter?.[selectIndex?.country]?.CITIES?.[selectIndex?.city]
          ?.CITY,
      LOCATION:
        selectIndex?.location &&
        reportAnaFilter?.[selectIndex?.country]?.CITIES?.[selectIndex?.city]
          .LOCATION?.[selectIndex?.location]?.LOCATION,
      STORE_ID:
        selectIndex?.store &&
        reportAnaFilter?.[selectIndex?.country]?.CITIES?.[selectIndex?.city]
          .LOCATION?.[selectIndex?.location]?.STORE?.[selectIndex?.store]
          ?.STORE_ID,
      DEVICE_ID:
        selectIndex?.store &&
        reportAnaFilter?.[selectIndex?.country]?.CITIES?.[selectIndex?.city]
          .LOCATION?.[selectIndex?.location]?.STORE?.[selectIndex?.store]
          ?.DEVICE_DETAILS?.[selectIndex?.elevator]?.DEVICE_ID,
      FILTER:
        selectInterval >= 90
          ? "monthly"
          : selectInterval > 1 && selectInterval < 30
          ? "daily"
          : selectInterval >= 30 && selectInterval < 90
          ? "weekly"
          : "hourly",
    };
    axiosRetry(axios, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      },
      retryCondition: (error) => error.response.status === 504,
    });
    setFloorLoader(true);
    if (parameterCheckbox?.[0]?.state && deviceType==="Elevator") {
      axios
        .post(`${RTMBaseUrl}/RAfloorTripDetails`, params, {
          headers: { ["x-access-token"]: accessToken, ["x-offset"]: -(new Date()).getTimezoneOffset() },
        })
        .then((response) => {
          setTotalFloorsChart(response?.data?.data);
          setFloorLoader(false);
        });
    }
  }, [applyButton, parameterCheckbox?.[0]?.state,floorRefresh]);
 
  useEffect(() => {
    setDoorLoader(true);
    if (parameterCheckbox?.[1]?.state && deviceType==="Elevator") {
      axios
        .post(
          `${RTMBaseUrl}/RAdoorCycleDetails`,
          {
            COUNTRY:
              selectIndex?.country &&
              reportAnaFilter?.[selectIndex?.country]?.COUNTRY,
            CITY:
              selectIndex?.city &&
              reportAnaFilter?.[selectIndex?.country]?.CITIES?.[
                selectIndex?.city
              ]?.CITY,
            LOCATION:
              selectIndex?.location &&
              reportAnaFilter?.[selectIndex?.country]?.CITIES?.[
                selectIndex?.city
              ].LOCATION?.[selectIndex?.location]?.LOCATION,
            STORE_ID:
              selectIndex?.store &&
              reportAnaFilter?.[selectIndex?.country]?.CITIES?.[
                selectIndex?.city
              ].LOCATION?.[selectIndex?.location]?.STORE?.[selectIndex?.store]
                ?.STORE_ID,
            DEVICE_ID:
              selectIndex?.store &&
              reportAnaFilter?.[selectIndex?.country]?.CITIES?.[
                selectIndex?.city
              ].LOCATION?.[selectIndex?.location]?.STORE?.[selectIndex?.store]
                ?.DEVICE_DETAILS?.[selectIndex?.elevator]?.DEVICE_ID,

            from: dateRangeValue
              ? dateRangeValue === "daily"
                ? new Date(
                    `${moment()
                      .subtract(7, "days")
                      .format("YYYY-MM-DD")}T00:30:00`
                  ).getTime()
                : (dateRangeValue === "weekly"
                    ? new Date(
                        `${moment()
                          .subtract(30, "days")
                          .format("YYYY-MM-DD")}T${currentT}`
                      ).getTime()
                    : dateRangeValue === "monthly" &&
                      new Date(
                        `${moment()
                          .subtract(90, "days")
                          .format("YYYY-MM-DD")}T${currentT}`
                      ).getTime()) ||
                  new Date(
                    `${moment().format("YYYY-MM-DD")}T${currentT}`
                  ).getTime()
              : new Date(`${selectedDates?.[0]}T${currentT}`).getTime(),
            to: dateRangeValue
              ? new Date(
                  `${moment().add(1, "days").format("YYYY-MM-DD")}T${currentT}`
                ).getTime()
              : new Date(`${selectedDates?.[1]}T${currentT}`).getTime() +
                86400000,
            PARENT_ID: userProfile?.["custom:parent_id"],
            TENANT_ID: userProfile?.["custom:tenant_id"],
            FILTER:
              selectInterval > 90
                ? "monthly"
                : selectInterval > 1 && selectInterval <= 30
                ? "daily"
                : selectInterval > 30 && selectInterval <= 90
                ? "weekly"
                : "hourly",
          },
          {
            headers: { ["x-access-token"]: accessToken, ["x-offset"]: -(new Date()).getTimezoneOffset() },
          }
        )
        .then((response) => {
          setDoorCycleData(response?.data?.data);

          setDoorLoader(false);
        });
    }
  }, [applyButton, parameterCheckbox?.[1]?.state,doorRefresh]);

  useEffect(() => {
    const params = {
      COUNTRY:
        selectIndex?.country &&
        reportAnaFilter?.[selectIndex?.country]?.COUNTRY,
      CITY:
        selectIndex?.city &&
        reportAnaFilter?.[selectIndex?.country]?.CITIES?.[selectIndex?.city]
          ?.CITY,
      LOCATION:
        selectIndex?.location &&
        reportAnaFilter?.[selectIndex?.country]?.CITIES?.[selectIndex?.city]
          .LOCATION?.[selectIndex?.location]?.LOCATION,
      STORE_ID:
        selectIndex?.store &&
        reportAnaFilter?.[selectIndex?.country]?.CITIES?.[selectIndex?.city]
          .LOCATION?.[selectIndex?.location]?.STORE?.[selectIndex?.store]
          ?.STORE_ID,
      DEVICE_ID:
        selectIndex?.store &&
        reportAnaFilter?.[selectIndex?.country]?.CITIES?.[selectIndex?.city]
          .LOCATION?.[selectIndex?.location]?.STORE?.[selectIndex?.store]
          ?.DEVICE_DETAILS?.[selectIndex?.elevator]?.DEVICE_ID,
          from: dateRangeValue
          ? dateRangeValue === "daily"
            ? new Date(
                `${moment()
                  .subtract(7, "days")
                  .format("YYYY-MM-DD")}T00:30:00`
              ).getTime()
            : (dateRangeValue === "weekly"
                ? new Date(
                    `${moment()
                      .subtract(30, "days")
                      .format("YYYY-MM-DD")}T${currentT}`
                  ).getTime()
                : dateRangeValue === "monthly" &&
                  new Date(
                    `${moment()
                      .subtract(90, "days")
                      .format("YYYY-MM-DD")}T${currentT}`
                  ).getTime()) ||
              new Date(
                `${moment().format("YYYY-MM-DD")}T${currentT}`
              ).getTime()
          : new Date(`${selectedDates?.[0]}T${currentT}`).getTime(),
      to: selectedDates
        ? selectedDates?.[1] === moment().format("YYYY-MM-DD")
          ? Math.floor(new Date().getTime() / 1000) * 1000
          : new Date(`${selectedDates?.[1]}T23:59:59`).getTime()
        : Math.floor(new Date().getTime() / 1000) * 1000,
      PARENT_ID: userProfile?.["custom:parent_id"],
      TENANT_ID: userProfile?.["custom:tenant_id"],
      FILTER:
        selectInterval > 90
          ? "monthly"
          : selectInterval > 1 && selectInterval <= 30
          ? "daily"
          : selectInterval > 30 && selectInterval <= 90
          ? "weekly"
          : "hourly",
    };
    setEleActiveLoader(true);
    if (parameterCheckbox?.[3]?.state && deviceType==="Elevator" || parameterCheckbox?.[1]?.state && deviceType==="Equipment") {
      axios
        .post(`${RTMBaseUrl}/RTMElevatorStatus`, params, {
          headers: { ["x-access-token"]: accessToken, ["x-offset"]: -(new Date()).getTimezoneOffset() },
        })
        .then((response) => {
          setActiveHoursPie(response?.data?.data);
          setEleActiveLoader(false);
        });
    }
  }, [applyButton, parameterCheckbox?.[3]?.state , activeHRefresh ,parameterCheckbox?.[1]?.state]);

  useEffect(() => {
    setElevatorLoader(true);
    if (parameterCheckbox?.[2]?.state && deviceType==="Elevator" || parameterCheckbox?.[0]?.state && deviceType==="Equipment") {
      axios
        .post(
          `${RTMBaseUrl}/app-status`,
          {
            from: dateRangeValue
            ? dateRangeValue === "daily"
              ? new Date(
                  `${moment()
                    .subtract(7, "days")
                    .format("YYYY-MM-DD")}T00:30:00`
                ).getTime()
              : (dateRangeValue === "weekly"
                  ? new Date(
                      `${moment()
                        .subtract(30, "days")
                        .format("YYYY-MM-DD")}T${currentT}`
                    ).getTime()
                  : dateRangeValue === "monthly" &&
                    new Date(
                      `${moment()
                        .subtract(90, "days")
                        .format("YYYY-MM-DD")}T${currentT}`
                    ).getTime()) ||
                new Date(
                  `${moment().format("YYYY-MM-DD")}T${currentT}`
                ).getTime()
            : new Date(`${selectedDates?.[0]}T${currentT}`).getTime(),
            to: selectedDates
              ? new Date(`${selectedDates?.[1]}T${currentT}`).getTime()
              : new Date(
                  `${moment().add(1, "days").format("YYYY-MM-DD")}T${currentT}`
                ).getTime(),
            PARENT_ID: userProfile?.["custom:parent_id"],
            TENANT_ID: userProfile?.["custom:tenant_id"],
            COUNTRY:
              selectIndex?.country &&
              reportAnaFilter?.[selectIndex?.country]?.COUNTRY,
            CITY:
              selectIndex?.city &&
              reportAnaFilter?.[selectIndex?.country]?.CITIES?.[
                selectIndex?.city
              ]?.CITY,
            LOCATION:
              selectIndex?.location &&
              reportAnaFilter?.[selectIndex?.country]?.CITIES?.[
                selectIndex?.city
              ].LOCATION?.[selectIndex?.location]?.LOCATION,
            STORE_ID:
              selectIndex?.store &&
              reportAnaFilter?.[selectIndex?.country]?.CITIES?.[
                selectIndex?.city
              ].LOCATION?.[selectIndex?.location]?.STORE?.[selectIndex?.store]
                ?.STORE_ID,
            DEVICE_ID:
              selectIndex?.store &&
              reportAnaFilter?.[selectIndex?.country]?.CITIES?.[
                selectIndex?.city
              ].LOCATION?.[selectIndex?.location]?.STORE?.[selectIndex?.store]
                ?.DEVICE_DETAILS?.[selectIndex?.elevator]?.DEVICE_ID,
            FILTER:
              selectInterval > 90
                ? "monthly"
                : selectInterval > 1 && selectInterval <= 30
                ? "daily"
                : selectInterval > 30 && selectInterval <= 90
                ? "weekly"
                : "hourly",
          },
          {
            headers: { ["x-access-token"]: accessToken, ["x-offset"]: -(new Date()).getTimezoneOffset() },
          }
        )
        .then((response) => {
          setElevatorStatus(response?.data?.data);
          setElevatorLoader(false);
        });
    }
  }, [applyButton, parameterCheckbox?.[2]?.state,parameterCheckbox?.[0]?.state,elevatorSRefresh]);
  useEffect(() => {
    setEnergyLoader(true);
    if (parameterCheckbox?.[4]?.state && deviceType==="Elevator" || parameterCheckbox?.[2]?.state && deviceType==="Equipment") {
      axios
        .post(
          `${RTMBaseUrl}/RAenergyData`,
          {
            COUNTRY:
              selectIndex?.country &&
              reportAnaFilter?.[selectIndex?.country]?.COUNTRY,
            CITY:
              selectIndex?.city &&
              reportAnaFilter?.[selectIndex?.country]?.CITIES?.[
                selectIndex?.city
              ]?.CITY,
            LOCATION:
              selectIndex?.location &&
              reportAnaFilter?.[selectIndex?.country]?.CITIES?.[
                selectIndex?.city
              ].LOCATION?.[selectIndex?.location]?.LOCATION,
            STORE_ID:
              selectIndex?.store &&
              reportAnaFilter?.[selectIndex?.country]?.CITIES?.[
                selectIndex?.city
              ].LOCATION?.[selectIndex?.location]?.STORE?.[selectIndex?.store]
                ?.STORE_ID,
            DEVICE_ID:
              selectIndex?.store &&
              reportAnaFilter?.[selectIndex?.country]?.CITIES?.[
                selectIndex?.city
              ].LOCATION?.[selectIndex?.location]?.STORE?.[selectIndex?.store]
                ?.DEVICE_DETAILS?.[selectIndex?.elevator]?.DEVICE_ID,
            from: dateRangeValue
              ? dateRangeValue === "daily"
                ? new Date(
                    `${moment()
                      .subtract(7, "days")
                      .format("YYYY-MM-DD")}T00:30:00`
                  ).getTime()
                : (dateRangeValue === "weekly"
                    ? new Date(
                        `${moment()
                          .subtract(30, "days")
                          .format("YYYY-MM-DD")}T${currentT}`
                      ).getTime()
                    : dateRangeValue === "monthly" &&
                      new Date(
                        `${moment()
                          .subtract(90, "days")
                          .format("YYYY-MM-DD")}T${currentT}`
                      ).getTime()) ||
                  new Date(
                    `${moment().format("YYYY-MM-DD")}T${currentT}`
                  ).getTime()
              : new Date(`${selectedDates?.[0]}T${currentT}`).getTime(),
            to: dateRangeValue
              ? new Date(
                  `${moment().add(1, "days").format("YYYY-MM-DD")}T${currentT}`
                ).getTime()
              : new Date(`${selectedDates?.[1]}T${currentT}`).getTime() +
                86400000,
            PARENT_ID: userProfile?.["custom:parent_id"],
            TENANT_ID: userProfile?.["custom:tenant_id"],
            FILTER:
              selectInterval > 90
                ? "monthly"
                : selectInterval > 1 && selectInterval <= 30
                ? "daily"
                : selectInterval > 30 && selectInterval <= 90
                ? "weekly"
                : "hourly",
          },
          {
            headers: { ["x-access-token"]: accessToken, ["x-offset"]: -(new Date()).getTimezoneOffset() },
          }
        )
        .then((response) => {
          setEnergyConsumptionData(response?.data?.data);
          setEnergyLoader(false);
        });
    }
  }, [applyButton, parameterCheckbox?.[4]?.state,parameterCheckbox?.[2]?.state, energyCRefresh]);
  // useEffect(() => {
  //   setElectricalLoader(true);
  //   if (parameterCheckbox?.[5]?.state) {
  //     axios
  //       .post(
  //         `${RTMBaseUrl}/deviceMsg`,
  //         {
  //           // DEVICE_ID: "99999740",
  //           from: selectedDates
  //             ? new Date(`${selectedDates?.[0]}T${currentT}`).getTime()
  //             : new Date(
  //                 `${moment().format("YYYY-MM-DD")}T${currentT}`
  //               ).getTime(),
  //           to: selectedDates
  //             ? new Date(`${selectedDates?.[1]}T${currentT}`).getTime()
  //             : new Date(
  //                 `${moment().add(1, "days").format("YYYY-MM-DD")}T${currentT}`
  //               ).getTime(),
  //           PARENT_ID: userProfile?.["custom:parent_id"],
  //           TENANT_ID: userProfile?.["custom:tenant_id"],
  //           // COUNTRY: "INDIA",
  //           // CITY: "BANGALORE",
  //           FILTER:
  //             selectInterval > 90
  //               ? "monthly"
  //               : selectInterval > 1 && selectInterval <= 30
  //               ? "daily"
  //               : selectInterval > 30 && selectInterval <= 90
  //               ? "weekly"
  //               : "hourly",
  //         },
  //         {
  //           headers: { ["x-access-token"]: accessToken, ["x-offset"]: -(new Date()).getTimezoneOffset() },
  //         }
  //       )
  //       .then((response) => {
  //         setElectricalData(response?.data?.data?.MINUTES);
  //         setElectricalLoader(false);
  //       });
  //   }
  // }, [applyButton, parameterCheckbox?.[5]?.state]);

  return (
    <div style={{ padding: "0px  20px" , height:"100px"}}>
      <div style={{ display: "none" }}>
        <table id="floors_table">
          Total Floors
          <tr>
            <th>hours</th>
            <th>value</th>
          </tr>
          {totalFloorsChart?.hourlyResponse?.map((ele, index) => {
            return (
              <tr key={index}>
                <td>{ele?.hours}</td>
                <td>{ele?.value}</td>
              </tr>
            );
          })}
        </table>
      </div>
      {parameterCheckbox?.map((parameter) => {
        return (
          parameter?.state && (
            <Tabs defaultActiveKey="2">
              {parameter?.title === "Total Floors" ? (
                floorTabs?.map((tabs, index) => {
                  return (
                    <Tabs.TabPane tab={tabs?.tab} key={index}>
                      <div
                        style={{
                          marginTop: "-50px",
                          display: "flex",
                          float: "right",
                        }}
                      >
                        <RetweetOutlined className="SmallIconsData" onClick={()=>{setFfloorRefresh(!floorRefresh)}}/>
                      
                        <DownloadOutlined
                          onClick={() =>
                            downloadScreenshotjpg(totalRef, parameter?.title)
                          }
                          className="SmallIconsData"
                        />
                        <PrinterOutlined
                          onClick={() =>
                            handleDownloadPdf("reportFloor", "Report_Foor_Trip" )
                          }
                          className="SmallIconsData"
                        />
                        <CsvButton data={totalFloorsChart} filename="ReportFloorTRip" selectInterval={selectInterval}/>
                      </div>
                      {!floorLoader ? (
                        <div id="reportFloor" style={{ padding: "0px  20px" }} ref={totalRef}>
                          {/* {parameter?.title} */}
                          {parameter?.state && (
                            <>
                              {tabs?.chart === "table" ? (
                                <Table
                                  className="MainTable"
                                  bordered
                                  columns={floorsTablecolumns}
                                  dataSource={totalFloorsChart?.hourlyResponse}
                                  scroll={
                                    totalFloorsChart?.hourlyResponse?.length >
                                      5 && { y: 120 }
                                  }
                                  size="small"
                                />
                              ) : (
                                tabs?.chart
                              )}
                              <hr />
                            </>
                          )}
                        </div>
                      ) : (
                        <Spin
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "100px",
                          }}
                        />
                      )}
                    </Tabs.TabPane>
                  );
                })
              ) : parameter?.title === "Door Cycle" ? (
                doorCycleTabs?.map((tabs, index) => {
                  return (
                    <Tabs.TabPane tab={tabs?.tab} key={index}>
                      <div
                        style={{
                          marginTop: "-50px",
                          display: "flex",
                          float: "right",
                        }}
                      >
                        <RetweetOutlined className="SmallIconsData" onClick={()=>{setDdoorRefresh(!doorRefresh)}}/>
                        <DownloadOutlined
                          onClick={() =>
                            downloadScreenshotjpg(doorRef, parameter?.title)
                          }
                          className="SmallIconsData"
                        />
                        <PrinterOutlined
                          onClick={() =>
                            handleDownloadPdf("ReportDoor", "Report_Door_Cycle")
                          }
                          className="SmallIconsData"
                        />
                        <CsvButton data={doorCycleData} filename="ReportDoor" selectInterval={selectInterval}/>
                      </div>
                      {!doorLoader ? (
                        <div id="ReportDoor" style={{ padding: "0px  20px" }} ref={doorRef}>
                          {/* {parameter?.title} */}
                          {parameter?.state && (
                            <>
                              {tabs?.chart === "table" ? (
                                <Table
                                  className="MainTable"
                                  bordered
                                  columns={floorsTablecolumns}
                                  dataSource={doorCycleData?.hourlyResponse}
                                  scroll={
                                    doorCycleData?.hourlyResponse?.length >
                                      5 && { y: 120 }
                                  }
                                  size="small"
                                />
                              ) : (
                                tabs?.chart
                              )}
                              <hr />
                            </>
                          )}
                        </div>
                      ) : (
                        <Spin
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "100px",
                          }}
                        />
                      )}
                    </Tabs.TabPane>
                  );
                })
              ) : parameter?.title === `${deviceType} Status` ? (
                elevatorTabs?.map((tabs, index) => {
                  return (
                    <Tabs.TabPane tab={tabs?.tab} key={index}>
                      <div className="parametersIconsData"  ref={statusRef}>
                        <RetweetOutlined className="SmallIconsData" onClick={()=>{setElevatorSRefresh(!elevatorSRefresh)}}/>
                        <DownloadOutlined
                          onClick={() =>
                            downloadScreenshotjpg(statusRef, parameter?.title)
                          }
                          className="SmallIconsData"
                        />
                        <PrinterOutlined
                          onClick={() =>
                            handleDownloadPdf("ElevatorStatus","Elevator_Status" )
                          }
                          className="SmallIconsData"
                        />
                        <CsvButton data={elevatorStatus} filename="ReportEleStatus" selectInterval={selectInterval}/>
                      </div>
                      {!elevatorLoader ? (<div id="ElevatorStatus"  style={{ padding: "0px  20px" }} ref={statusRef}>
                        {parameter?.state && (
                          <>
                            {tabs?.chart === "table" && activeHoursChart ? (
                              <Table
                                className="MainTable"
                                bordered
                                columns={reportTablecolumns}
                                // dataSource={activeHoursChart}
                                size="small"
                              />
                            ) : (
                              tabs?.chart
                            )}
                            <hr />
                          </>
                        )}
                      </div>): ( <Spin
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "100px",
                          }}
                        />)}
                      
                    </Tabs.TabPane>
                  );
                })
              ) : parameter?.title === "Active Hours" ? (
                hoursTabs?.map((tabs, index) => {
                  return (
                    <Tabs.TabPane tab={tabs?.tab} key={index}>
                      <div className="parametersIconsData" ref={activeRef}>
                        <RetweetOutlined className="SmallIconsData" onClick={()=>{setActiveHRefresh(!activeHRefresh)}}/>
                        <DownloadOutlined
                          onClick={() =>
                            downloadScreenshotjpg(activeRef, parameter?.title)
                          }
                          className="SmallIconsData"
                        />
                        <PrinterOutlined
                          onClick={() =>
                            handleDownloadPdf("ActiveHours", "Active_Hours")
                          }
                          className="SmallIconsData"
                        />
                        <CsvButton data={activeHoursPie} filename="ReportActiveHour" selectInterval={selectInterval}/>
                      </div>
                      {!eleActiveLoader ?( <div id="ActiveHours" style={{ padding: "0px  20px" }} ref={activeRef}>
                        {parameter?.state && (
                          <>
                            {tabs?.chart === "table" ? (
                              <Table
                                className="MainTable"
                                bordered
                                columns={reportTablecolumns}
                                dataSource={activeHoursChart}
                                size="small"
                              />
                            ) : (
                              tabs?.chart
                            )}
                            <hr />
                          </>
                        )}
                      </div>) :(<Spin
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "100px",
                          }}
                        />)}
                     
                    </Tabs.TabPane>
                  );
                })
              ) : parameter?.title === "Energy Consumption" ? (
                <Tabs.TabPane tab={<LineChartOutlined />}>
                  <div className="parametersIconsData" ref={energyRef}>
                    <RetweetOutlined className="SmallIconsData" onClick={()=>{setEnergyCRefresh(!energyCRefresh)}}/>
                    <DownloadOutlined
                      onClick={() =>
                        downloadScreenshotjpg(energyRef, parameter?.title)
                      }
                      className="SmallIconsData"
                    />
                    <PrinterOutlined
                      onClick={() =>
                        handleDownloadPdf("EnergyConsumption","Energy_Consumption")
                      }
                      className="SmallIconsData"
                    />
                    <CsvButton data={energyConsumptionData} filename="ReportEnergy" selectInterval={selectInterval}/>
                  </div>
                  {energyLoader ? (
                    <Spin
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "100px",
                      }}
                    />
                  ) : (
                    <div id="EnergyConsumption" style={{ padding: "0px  20px" }}>
                      {parameter?.state && (
                        <div ref={energyRef}>
                          <EnergyChart
                            chartData={energyConsumptionData}
                            called="energyConsumption"
                            selectInterval={selectInterval}
                            selectFilter={selectFilter}
                          />

                          <hr />
                        </div>
                      )}
                    </div>
                  )}
                </Tabs.TabPane>
              ) : (
                ""
                // parameter?.title === "Electrical Parameters" && (
                //   <Tabs.TabPane tab={<LineChartOutlined />}>
                //     <div className="parametersIconsData" ref={electricalRef}>
                //       <RetweetOutlined className="SmallIconsData" />
                //       <DownloadOutlined
                //         onClick={() =>
                //           downloadScreenshotjpg(electricalRef, parameter?.title)
                //         }
                //         className="SmallIconsData"
                //       />
                //       <PrinterOutlined
                //         onClick={() =>
                //           handleDownloadPdf(electricalRef, parameter?.title)
                //         }
                //         className="SmallIconsData"
                //       />
                //     </div>
                //     {electricalLoader ? (
                //       <Spin
                //         style={{
                //           display: "flex",
                //           justifyContent: "center",
                //           marginTop: "100px",
                //         }}
                //       />
                //     ) : (
                //       <div style={{ padding: "0px  20px" }} ref={electricalRef}>
                //         {parameter?.state && (
                //           <Tabs>
                //             {electricalChartColoumns?.map((tab, index) => {
                //               return (
                //                 <Tabs.TabPane tab={tab.tab} key={tab.key}>
                //                   <AreaChart
                //                     called={tab.key}
                //                     chartData={electricalData}
                //                     selectFilter={selectFilter}
                //                   />
                //                 </Tabs.TabPane>
                //               );
                //             })}
                //           </Tabs>
                //         )}
                //       </div>
                //     )}
                //   </Tabs.TabPane>
                // )
              )}
            </Tabs>
          )
        );
      })}
    </div>
  );
};

export default ReportAnalysisCharts;
