/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  Col,
  Row,
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  notification,
} from "antd";
import "antd/dist/antd.css";
import "../Styles.css";
import countryList from "../../../common/CountryList";
import { onboardingBaseUrl, } from "../../../common/BaseUrl";
import axios from "axios";


const AddLocationForm = ({
  
  setOpenNewLocation,
  parentAllData,
  setlistloading,
  listLoading,
  setMapModal,
  storeResult,
  editLocation,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { Option } = Select;
  const accessToken = window.localStorage.getItem("accessToken");
  const [selectCountry, setSelectCountry] = useState("disabled");
  const [selectParent, setSelectParent] = useState();
  const [selectTenant, setSelectTenant] = useState();
  const [selectedStore,setSelectedStore]=useState()
  const [showTenant,setShowTenant]=useState(false)
  const [showPerant,setShowPerant]=useState(false)
  const [selectValues, setSelectValues] = useState({
    country: "",
    spaceType: "",
    city: "",
  });
  
  useEffect(() => {
    if (useState) {
      const matchingParent = parentAllData.find(item => item.PARENT_ID === editLocation?.PARENT_ID)?.PARENT_NAME;
      const matchingTenant = parentAllData.filter(item => item.PARENT_ID === editLocation?.PARENT_ID).map(tenant => tenant?.TENANTS?.find(item => item.TENANT_ID === editLocation?.TENANT_ID))?.[0]?.TENANT_NAME;
      form.setFieldsValue({
        country: storeResult
          ? storeResult?.results?.[0]?.properties?.CntryName
          : editLocation?.COUNTRY,
        city: storeResult
          ? storeResult?.results?.[0]?.properties?.City
          : editLocation?.CITY,
        space: editLocation?.SPACE_TYPE,
        noOfFloors: editLocation?.NO_OF_FLOOR,
        storeName: editLocation?.STORE_NAME,
        location: storeResult
          ? storeResult?.results?.[0]?.properties?.District
          : editLocation?.LOCATION,
        address: storeResult ? storeResult?.text : editLocation?.ADDRESS,
        zipCode: storeResult
          ? storeResult?.results?.[0]?.properties?.Postal
          : editLocation?.ZIPCODE,
        latitude: storeResult
          ? storeResult?.results?.[0]?.properties?.DisplayY
          : editLocation?.LATTITUDE,
        longitude: storeResult
          ? storeResult?.results?.[0]?.properties?.DisplayX
          : editLocation?.LONGITUDE,
        electricParameter: editLocation?.ONLY_ELECTRICAL_PARAMETER,

        parent_id: matchingParent,
        tenant_id: matchingTenant,
      });
    }
  }, [editLocation, storeResult, form]);
  const onFinish = (values) => {
    const newLocationParams = {
      COUNTRY: values?.country,
      CITY: values?.city,
      LOCATION: values?.location,
      ADDRESS: values?.address,
      LATTITUDE: values?.latitude,
      LONGITUDE: values?.longitude,
      ZIPCODE: values?.zipCode,
      SPACE_TYPE: values?.space,
      NO_OF_FLOOR: values?.noOfFloors,
      STORE_ID: selectedStore,
      STORE_NAME: values?.storeName,
      TENANT_ID: values?.tenant_id,
      PARENT_ID: values?.parent_id,

    };

    if (editLocation) {
      const formdata = form.getFieldsValue();
      const editLocationparams = {
        COUNTRY: formdata?.country,
        CITY: formdata?.city,
        LOCATION: formdata?.location,
        ADDRESS: formdata?.address,
        LATTITUDE: formdata?.latitude,
        LONGITUDE: formdata?.longitude,
        ZIPCODE: formdata?.zipCode,
        SPACE_TYPE: formdata?.space,
        NO_OF_FLOOR: formdata?.noOfFloors,
        STORE_ID: selectedStore ?selectedStore :editLocation?.STORE_ID,
        STORE_NAME: formdata?.storeName,
        TENANT_ID: showTenant? formdata?.tenant_id : editLocation?.TENANT_ID,
        PARENT_ID: showPerant? formdata?.parent_id : editLocation?.PARENT_ID,
      };
      axios
        .post(
          `${onboardingBaseUrl}/updateLocation/${editLocation?._id}`,
          editLocationparams,
          {
            headers: { ["x-access-token"]: accessToken },
          }
        )
        .then((response) => {
          setOpenNewLocation(false);
          navigate("/on-boarding");
          notification.success({
            description: response?.data?.data?.message,
          });
        });
    } else {
      axios
        .post(`${onboardingBaseUrl}/addLocation`, newLocationParams, {
          headers: { ["x-access-token"]: accessToken },
        })
        .then((response) => {
          setOpenNewLocation(false);
          setlistloading(!listLoading);
          notification.success({
            description: response?.data?.data?.message,
          });
        });
    }
  };
  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "country") {
      form.setFieldsValue({
        city: undefined,
      }); //reset product selection
    } else if (formFieldName === "parent_id") {
      setShowPerant(true)
      form.setFieldsValue({
        tenant_id: undefined,
      });
    }
    else if (formFieldName === "tenant_id") {
      setShowTenant(true)
      form.setFieldsValue({
        storeName: undefined,
      });
    }
  };

  return (
    <div
      style={{ backgroundColor: "rgb(230, 242, 252)", padding: "12px 20px" }}
    >
 
      <Form
        name="location form"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        form={form}
        // initialValues={{ electricParameter: electricSwith }}
        onFinish={onFinish}
        onValuesChange={handleFormValuesChange}
        className="backgroundStyle"
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "10px",
            marginBottom: "20px",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              margin: "10px 1px",
            }}
          >
            <Button
              onClick={() => setMapModal(true)}
              type="primary"
              className="formInputStyleButtonLocation"
              style={{ color: "white", borderRadius: "10px" }}
              disabled={editLocation?.COUNTRY && true}
            >
              Pin Location
            </Button>
          </div>
          <Row gutter={[24, 15]}>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div className="formInputStyleLocation">
                <label className="formLabelStyle">Country</label>

                <Form.Item
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Country !",
                    },
                  ]}
                >
                  <Select
                   disabled={editLocation?.COUNTRY && true}
                    placeholder="Country"
                    className="ReportModal"
                    onChange={(value) => {
                      setSelectCountry(value);
                      setSelectValues({ ...selectValues, country: value });
                    }}
                    allowClear
                    showSearch
                  >
                    {countryList?.map((country, index) => {
                      return (
                        <Option key={index} value={country?.COUNTRY}>
                          {country?.COUNTRY}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </Col>

            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div className="formInputStyleLocation">
                <label className="formLabelStyle">City</label>
                <Form.Item
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: "Please Select City !",
                    },
                  ]}
                >
                  <Select
                    placeholder="City"
                    disabled={selectCountry === "disabled" || editLocation?.COUNTRY ? true : false}
                    allowClear
                    showSearch
                    onChange={(value) => {
                      setSelectValues({ ...selectValues, city: value });
                    }}
                  >
                    {countryList
                      ?.filter((country) => country?.COUNTRY === selectCountry)
                      ?.map((cities) =>
                        cities?.CITIES?.map((city, index) => {
                          return (
                            <Option key={index} value={city?.CITY}>{city?.CITY}</Option>
                          );
                        })
                      )}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col
              className="regestration"
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div className="formInputStyle">
                <label className="formLabelStyle">Parent</label>
                <Form.Item
                  name="parent_id"
                  rules={[
                    {
                      required: true,
                      message: "Enter Parent Name",
                    },
                  ]}
                >
                  <Select
                   disabled={editLocation?.PARENT_ID && true}
                    placeholder="Parent Name"
                    className="ReportModal"
                    // disabled={editLocation && true}
                    onChange={(value) => {
                      setSelectParent(value);
                    }}
                  >
                    {parentAllData?.map((parent, index) => {
                      return (
                        <Option key={index} value={parent?.PARENT_ID}>
                          {parent?.PARENT_NAME}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col
              className="regestration"
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div className="formInputStyle">
                <label className="formLabelStyle">Tenant</label>
                <Form.Item
                  name="tenant_id"
                  rules={[
                    {
                      required: true,
                      message: "Enter Tenant Name",
                    },
                  ]}
                >
                  <Select
                  disabled={!selectParent || editLocation?.PARENT_ID ? true: false}
                    placeholder="Tenant Name"
                    onChange={(value) => {
                      setSelectTenant(value);
                    }}
                    // disabled={selectParent ? false : true}
                  >
                    {parentAllData
                      ?.filter((parent) => parent?.PARENT_ID === selectParent)
                      ?.map((alltenants) =>
                        alltenants?.TENANTS?.map((tenant,index) => {
                          return (
                            <Option key={index} value={tenant?.TENANT_ID}>
                              {tenant?.TENANT_NAME}
                            </Option>
                          );
                        })
                      )}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col
              xl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div className="formInputStyleLocation">
                <label className="formLabelStyle">Address</label>
                <Form.Item
                  name="address"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter address!",
                    },
                  ]}
                >
                  <Input className="formInput101" disabled={editLocation?.COUNTRY ? true : false}/>
                </Form.Item>
              </div>
            </Col>

            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div className="formInputStyleLocation">
                <label className="formLabelStyle">Location</label>
                <Form.Item
                  name="location"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter location!",
                    },
                  ]}
                >
                  <Input className="formInput101" placeholder="Location" disabled={editLocation?.COUNTRY ? true : false}/>
                </Form.Item>
              </div>
            </Col>

            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div className="formInputStyleLocation">
                <label className="formLabelStyle">Zip Code</label>
                <Form.Item
                  name="zipCode"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Zip Code!",
                    },
                  ]}
                >
                  <Input
                    className="formInput101"
                    value={form.getFieldValue("zipCode")}
                    disabled={editLocation?.COUNTRY ? true : false}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div className="formInputStyleLocation">
                <label className="formLabelStyle">Latitude</label>
                <Form.Item
                  name="latitude"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter latitude!",
                    },
                  ]}
                >
                  <Input className="formInput101" disabled={editLocation?.COUNTRY ? true : false} />
                </Form.Item>
              </div>
            </Col>

            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <div className="formInputStyleLocation">
                <label className="formLabelStyle">Longitude</label>
                <Form.Item
                  name="longitude"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter longitude!",
                    },
                  ]}
                >
                  <Input
                    className="formInput101"
                    // value={form.getFieldValue("zipCode")}
                    disabled={editLocation?.COUNTRY ? true : false}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
        <Row gutter={[24, 15]}>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div className="formInputStyleLocation">
              <label className="formLabelStyle">Store Name</label>
              <Form.Item
                name="storeName"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Store Name!",
                  },
                ]}
              >
                <Select
                  placeholder="Store Name"
                  onChange={(value) => {
                    const selectedStoreData = parentAllData
                    ?.filter((parent) => parent?.PARENT_ID === selectParent)
                    ?.map((alltenants) =>
                      alltenants?.TENANTS?.filter(
                        (tenant) => tenant?.TENANT_ID === selectTenant
                      )?.map((selectedTenant) =>
                        selectedTenant?.STORES?.find((store) => ( store.STORE_NAME === value))
                      )
                    )
                  setSelectedStore(selectedStoreData?.[0]?.[0]?.STORE_ID);
                  }}
                  disabled={!selectTenant || editLocation?.PARENT_ID ? true : false}

                >
                  {parentAllData
                    ?.filter((parent) => parent?.PARENT_ID === selectParent)
                    ?.map((alltenants) =>
                      alltenants?.TENANTS?.filter(
                        (tenant) => tenant?.TENANT_ID === selectTenant
                      )?.map((selectedTenant) =>
                        selectedTenant?.STORES?.map((store) => (
                          <Option key={store?.STORE_NAME} value={store?.STORE_NAME}>
                            {store?.STORE_NAME}
                          </Option>
                        ))
                      )
                    )}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div className="formInputStyleLocation">
              <label className="formLabelStyle">Space Type</label>
              <Form.Item
                name="space"
                rules={[
                  {
                    required: true,
                    message: "Please Select Space type!",
                  },
                ]}
              >
                <Select
                  placeholder="Space Type"
                  allowClear
                  showSearch
                  onChange={(value) => {
                    setSelectValues({ ...selectValues, spaceType: value });
                  }}
                >
                  <Option value="RETAIL">RETAIL</Option>
                  <Option value="RESIDENTIAL">RESIDENTIAL</Option>
                  <Option value="COMMERCIAL">COMMERCIAL</Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col
            xl={{ span: 12 }}
            lg={{ span: 12 }}
            md={{ span: 12 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div className="formInputStyleLocation">
              <label className="formLabelStyle">No. of Floors</label>
              <Form.Item
                name="noOfFloors"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Floors Count!",
                  },
                ]}
              >
                <InputNumber
                  //   disabled={editLocation ? true : false}
                  className="formInput101"
                />
              </Form.Item>
            </div>
          </Col>
          
        </Row>

        <Form.Item
          wrapperCol={{
            span: 16,
          }}
        >
          <Button
            type="primary"
            htmlType="cancel"
            style={{ color: "white", marginTop: "40px" }}
          >
            Submit
          </Button>
          <Button
            type="primary"
            style={{ margin: "10px", color: "white" }}
            onClick={() => {
              setOpenNewLocation(false);
              form.resetFields();
            }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
      {/* )} */}
    </div>
  );
};

export default AddLocationForm;
