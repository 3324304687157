/* eslint-disable react/prop-types */
import React, { useState } from "react";
import axios from "axios";
import { Button, Form, Input, notification,Spin } from "antd";
import { baseUrl } from "../../common/BaseUrl";

const ForgotPassword = ({
  setLogin,
  setForgotPasswordn,
  setResetPassword,
  setVerificationCode,
}) => {

  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (values) => {
    setIsLoading(true)
    axios
      .post(`${baseUrl}/forgotPassword`, { email: values.emailId })
      .then((response) => {
        setIsLoading(false)
        notification.success({
          description: response?.data?.data,
        });
        setVerificationCode(true);
        onVerification();

        // setIsLoading(false);
      })
  };
  const onCancel = () => {
    setLogin(true);
    setForgotPasswordn(false);
    setResetPassword(false);
  };

  const onVerification = () => {
    setLogin(false);
    setResetPassword(true);
    setForgotPasswordn(false);
  };

  return (
    <Spin
    spinning={isLoading}
    delay={500}
    tip="Loading"
    size="large"
    style={{ marginTop: "200px", fontSize: "25px", fontWeight: "bold" }}
  >
    <Form
      name="Forgot Password Form"
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
    
      onFinish={onFinish}
      autoComplete="off"
    >
      <h3 style={{textAlign:"center", fontWeight:"bold"}}>Forgot Password</h3>
      <Form.Item
        label="Enter Your Email ID"
        name="emailId"
        rules={[
          {
            required: true,
            message: "Please input your Email ID !",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          onClick={onCancel}
          style={{ marginRight: "10px", color: "white" }}
        >
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" style={{ color: "white" }}>
          Request For verification code
        </Button>
      </Form.Item>
    </Form>
    </Spin>
  );
};

export default ForgotPassword;
