/* eslint-disable no-irregular-whitespace */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
import { Button, notification } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { baseUrl } from "../../common/BaseUrl";
import { useNavigate } from "react-router";

import "./Styles.css";
import LoadingSpinner from "../../common/pageLoader/loadingSpinner";
function EulaConfirm() {
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const isChecked = (e) => {
    if (e.target.checked) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const handleLicense = () => {
    setIsLoading(true);
    axios
      .post(
        `${baseUrl}/eula/true`,
        {
          user: localStorage.getItem("userEmail"),
        },
        {
          headers: { ["x-access-token"]: localStorage.getItem("accessToken") },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.message === "OK") {
          navigate("/dashboard");
          notification.success({ description: "Login Successful" });
        } else {
          localStorage.clear();
          navigate("/");
          notification.error({ description: "Something went wrong" });
        }
      });
  };
  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <h1 className="hh1">Data Protection Notice for Online-Offers</h1>
          <h4 className="hh2">(CD 2900 - 008) | Version – R0</h4>
          <hr></hr>
          <br></br>
          <p className="MsoNormal">
            <b>
              <span style={{ fontSize: "10.0pt" }}>&nbsp;</span>
            </b>
          </p>

          <p className="MsoListParagraph">
            <a name="_Toc25852294">
              <span className="span33" style={{ color: "black" }}>
                1.
                <span className="span44">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span className="span33" style={{ color: "black" }}>
                Data Protection Notice
              </span>
            </a>
          </p>

          <p className="MsoNormal">
            <span className="span22">
              Robert Bosch Engineering and Business Solutions Private Limited
              (hereby referred as RBEI) welcomes you to our Phantom Energy
              Monitoring Cloud applications (also referred to as “Online
              service”). We thank you for your interest in our company and our
              products.
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span22">&nbsp;</span>
          </p>

          <p className="MsoNormal">
            <span className="span33">2. RBEI respects your privacy</span>
          </p>

          <p className="MsoNormal">
            <span className="span22">
              The protection of your privacy throughout the course of processing
              personal data as well as the security of all business data are
              important concerns to us. We process personal data that was
              gathered during your visit of our Online Offers confidentially and
              only in accordance with statutory regulations.
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span22">
              Data protection and information security are included in our
              corporate policy.
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span22">&nbsp;</span>
          </p>

          <p className="MsoNormal">
            <span className="span33">3. Controller</span>
          </p>

          <p className="MsoNormal">
            <span className="span22">
              The customer of Phantom Energy Monitoring Solution is the
              controller of data and RBEI is the entity (processor) responsible
              for the processing of your data and; exceptions are outlined in
              this data protection notice.{" "}
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span22">Our contact details are as follows: </span>
          </p>

          <p className="MsoNormal">
            <span className="span22">Phantom support team | Email ID: </span>
            <a href="mailto:support.phantom@boschiot.zohodesk.com">
              <span className="span44">
                support.phantom@boschiot.zohodesk.com
              </span>
            </a>
          </p>

          <p className="MsoNormal">
            <span className="span22">
              Robert Bosch Engineering and Business Solutions Private Limited -
              (CIN: U72400KA1997PTC023164) | 123, Industrial Layout, Hosur Road,
              Koramangala | Bengaluru 560095 | INDIA |
              www.bosch-india-software.com
            </span>
          </p>

          <p className="MsoNormal">&nbsp;</p>

          <p className="MsoNormal">
            <span className="span33">
              4. Collection, processing and usage of personal data
            </span>
          </p>
          <br></br>
          <p className="MsoNormal">
            <span className="span66">
              4.1. Processed categories of data (as approved in ISDS)
            </span>
          </p>
          <br></br>
          <p className="MsoNormal">
            <span className="span22">
              The following categories of data are processed:
            </span>
          </p>

          <p style={{ lineHeight: "normal" }}>
            <span className="span22">
              ·
              <span className="span44">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span className="span55">
              Communication data (e.g. Name, telephone/mobile number, e-mail)
            </span>
          </p>

          <p className="MsoListParagraphCxSpMiddle">
            <span className="span22">
              ·
              <span className="span44">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span className="span55">
              Miscellaneous: User’s email id works as the User ID for Phantom
              Energy Monitoring Solution. When a{" "}
            </span>
          </p>

          <p className="MsoListParagraphCxSpMiddle">
            <span className="span55">
              User is deleted, all the personal data is removed except the Email
              ID that is used to depict the historical actions taken by that
              user in that account.{" "}
            </span>
          </p>

          <p className="MsoListParagraphCxSpMiddle">
            <span className="span55">&nbsp;</span>
          </p>

          <p className="MsoNormal">
            <span className="span55">4.2. Principles</span>
          </p>
          <br></br>
          <p className="MsoNormal">
            <span className="span22">
              Personal data consists of all information related to an identified
              or identifiable natural person, this includes, e.g. names, phone
              numbers, email addresses, which is an expression of a person's
              identity.
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span22">
              We collect, process and use personal data only when there is
              either a statutory legal basis to do so or if you have given your
              consent to the processing or use of personal data concerning this
              matter, e.g. by means of registration.
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span22">&nbsp;</span>
          </p>

          <p className="MsoNormal">
            <span className="span55">
              4.3. Processing purposes and legal basis
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span22">
              We as well as the service providers commissioned by us; process
              your personal data for the following processing purposes:{" "}
            </span>
          </p>
          <br></br>
          <p className="MsoNormal">
            <span className="span55">
              4.3.1: Provision of Phantom Energy Monitoring Cloud application
              including alerts and reports to configured users and User
              authentication related services
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span22">
              (Legal basis: Legitimate interest on our part in order to provide
              our Phantom Energy Monitoring Cloud application comprehensively).
            </span>
          </p>
          <br></br>
          <p className="MsoNormal">
            <span className="span55">4.3.2: </span>
            <span className="span22">
              Resolving service disruptions as well as for security reasons.
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span22">
              (Legal basis: Fulfillment of our legal obligations within the
              scope of data security and legitimate interest in resolving
              service disruptions as well as in the protection of our offers).
            </span>
          </p>
          <br></br>
          <p className="MsoNormal">
            <span className="span55">
              4.3.3: Safeguarding and defending our rights.
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span22">
              (Legal basis: Legitimate interest on our part for safeguarding and
              defending our rights).
            </span>
          </p>
          <br></br>
          <p className="MsoNormal">
            <span className="span33">5. Log files</span>
          </p>

          <p className="Calibri">
            <span className="span11">
              Each time you use the internet, your browser is transmitting
              certain information which we store in so-called log files.
            </span>
          </p>

          <p className="Calibri">
            <span className="span11">
              We store log files to determine service disruptions and for
              security reasons (e.g., to investigate attack attempts) for a
              period of 6 months and delete them afterwards. Log files which
              need to be maintained for evidence purposes are excluded from
              deletion until the respective incident is resolved and may, on a
              case-by-case basis, be passed on to investigating authorities.
            </span>
          </p>

          <p className="Calibri">
            <span className="span11">
              Log files are also used for analysis purposes (without the IP
              address or without the complete IP address).<u> </u>
            </span>
          </p>

          <p className="Calibri">
            <span className="span11">
              In log files, the following information is saved:
            </span>
          </p>

          <p style={{ lineHeight: "115%" }}>
            <span className="span55">
              ·
              <span className="span44">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span className="span66">
              IP address (internet protocol address) of the terminal device used
              to access the Online Offer;
            </span>
          </p>

          <p className="MsoListParagraphCxSpMiddle">
            <span className="span55">
              ·
              <span className="span44">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span className="span66">
              Name of the files or information accessed;
            </span>
          </p>

          <p className="MsoListParagraphCxSpMiddle">
            <span className="span55">
              ·
              <span className="span44">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span className="span66">
              Date and time as well as duration of recalling the data;
            </span>
          </p>

          <p className="MsoListParagraphCxSpMiddle">
            <span className="span55">
              ·
              <span className="span44">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span className="span66">
              Amount of data transferred; kind of data eg: request parameters
            </span>
          </p>

          <p className="MsoListParagraphCxSpMiddle">
            <span className="span55">
              ·
              <span className="span44">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span className="span66">
              Operating system and information on the internet browser used,
              including add-ons installed (e.g., Flash Player);
            </span>
          </p>

          <p className="MsoListParagraphCxSpMiddle">
            <span className="span55">
              ·
              <span className="span44">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span className="span66">Kind of resources</span>
          </p>

          <p style={{ lineHeight: "115%" }}>
            <span className="span55">
              ·
              <span className="span44">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </span>
            </span>
            <span className="span66">
              http status code (e.g., “Request successful” or “File requested
              not found”).
            </span>
          </p>
          <br></br>
          <p className="Calibri">
            <span className="span33">6. Service Provider (general)</span>
          </p>

          <p className="MsoNormal">
            <span className="span44">
              We involve external service providers with tasks such as sales and
              marketin6g services, programming, data hosting and hotline
              services. We have chosen those service providers carefully and
              monitor them on a regular basis, especially regarding their
              diligent handling of and protection of the data that they store.
              All service providers are obliged to maintain confidentiality and
              to comply with the statutory provisions. Service providers may
              also be other Bosch group companies.
            </span>
          </p>
          <br></br>
          <p className="Calibri">
            <span className="span33">7: Processing outside the EEA</span>
          </p>

          <p className="MsoNormal">
            <span className="span44">
              We might process personal data outside the EEA in so-called third
              countries. In such cases, prior to the transfer we ensure that the
              data recipient provides an appropriate level of data protection.
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span44">
              You are entitled to receive an overview of third country
              recipients and a copy of the specifically agreed-provisions
              securing an appropriate level of data protection. For this
              purpose, please use the statements made in the <u>Contact </u>
              section.{" "}
            </span>
          </p>
          <br></br>
          <p className="Calibri">
            <span className="span33">
              8: Duration of storage, retention periods
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span44">
              Principally, we store your data for as long as it is necessary to
              render our Online services and connected services. In all other
              cases we delete your personal data with the exception of data we
              are obliged to store for the fulfillment of legal obligations
              (e.g. due to retention periods under the tax and commercial codes
              we are obliged to have documents such as contracts and invoices
              available for a certain period of time) or services to the
              customer/business.
            </span>
          </p>

          <p className="MsoNormal">
            <span>&nbsp;</span>
          </p>
          <p className="Calibri">
            <span className="span33">9: Credit assessments</span>{" "}
          </p>

          <p className="Calibri">
            <span className="span11">
              We have a legitimate interest in performing the credit assessments
              set forth in this section for the purpose to protect ourselves
              from bad debts or investments. We might commission companies
              performing mathematical and statistical analysis to assess the
              risk of payment default and deliver, within the scope of what is
              allowed under law, information on the probability of payment
              defaults. For the assessment, address data may be used, but not
              exclusively.
            </span>
          </p>

          <p className="Calibri">
            <span className="span11">
              In case the result of a credit assessment does not satisfy our
              requirements, we reserve the right to ask for an assured payment
              method (e.g. credit card) or to refuse to enter into a contract.
            </span>
          </p>

          <p className="Calibri">
            <span className="span11">
              A credit assessment is based on automated decision-making. If you
              disagree with the result, you may submit your point of view in
              writing to be reviewed by a responsible person. In addition, you
              are entitled to find out about the essential reasons supporting
              the decision of the respective service provider.
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span44">
              We have commissioned the following service providers with credit
              assessments:
            </span>
          </p>
          <br></br>
          <p className="MsoNormal">
            <span className="span44">
              5.1: Unified Credit Solutions Pvt Ltd
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span44">
              Dharamdas Shastri Marg, Rajinder Nagar, New Delhi, Delhi 110060
            </span>
          </p>
          <br></br>
          <p className="MsoNormal">
            <span className="span44">5.2: Mira Inform Private Limited</span>
          </p>

          <p className="MsoNormal">
            <span className="span44">
              605, Palmspring, Above Croma, Link Road, Malad (West), Mumbai –
              400064. India
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span44">&nbsp;</span>
          </p>

          <p className="Calibri">
            <span className="span33">10: Cookies</span>{" "}
          </p>

          <p className="MsoNormal">
            <span className="span44">&nbsp;</span>
          </p>
          <span
            _ngcontent-qoc-c2=""
            style={{ fontSize: "9.0pt", lineHeight: "107%" }}
          >
            Usage of Cookies
          </span>

          <p className="Calibri">
            <span className="span11">
              In the context of our online service, cookies and tracking
              mechanisms may be used.{" "}
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span44">&nbsp;</span>
          </p>

          <p className="MsoNormal">
            <span className="span44">10.1: Categories</span>
          </p>

          <p className="Calibri">
            <span className="span11">
              We distinguish between cookies that are mandatorily required for
              the technical functions of the online service and such cookies and
              tracking mechanisms that are not mandatorily required for the
              technical function of the online service.{" "}
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span44">
              It is generally possible to use the online service without any
              cookies that serve non-technical purposes.
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span44">&nbsp;</span>
          </p>

          <p className="MsoNormal">
            <span>10.1.1: Technically required cookies</span>
          </p>

          <p className="Calibri">
            <span className="span11">
              By technically required cookies we mean cookies without those the
              technical provision of the online service cannot be ensured. These
              include e.g. cookies that store session information for retaining
              the user session in web application.
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span44">
              Such cookies will be deleted when you leave the website.
            </span>
          </p>
          <br></br>
          <p className="Calibri">
            <span>10.2: Cookie management</span>
          </p>

          <p className="Calibri">
            <span className="span66">Management of cookies </span>
          </p>

          <p className="Calibri">
            <span className="span11">
              You can manage your cookie and tracking mechanism settings in the
              browser and/or our privacy settings.
            </span>
          </p>

          <p className="Calibri">
            <span className="span11">
              Note: The settings you have made refer only to the browser used in
              each case.
            </span>
          </p>
          <br></br>
          <p className="Calibri">
            <span>10.2.1: Deactivation of all cookies</span>
          </p>

          <p className="Calibri">
            <span className="span66">Deactivation of all cookies</span>
          </p>

          <p className="Calibri">
            <span className="span11">
              If you wish to deactivate all cookies, please deactivate cookies
              in your browser settings. Please note that this may affect the
              functionality of the website.
            </span>
          </p>

          <p className="Calibri">
            <span className="span11">&nbsp;</span>
          </p>

          <p className="Calibri">
            <span className="span33">11: Security</span>
          </p>

          <p className="Calibri">
            <span className="span11">
              Our employees and the companies providing services on our behalf,
              are obliged to confidentiality and to compliance with the
              applicable data protection laws.
            </span>
          </p>

          <p className="Calibri">
            <span className="span11">We </span>
            <span className="span66">take</span>
            <span className="span11">
              {" "}
              all necessary technical and organizational{" "}
            </span>
            <span className="span11">measures</span>
            <span className="span55"> to </span>
            <span className="span11">
              ensure an appropriate level of security and to protect your data
              that are administrated by us especially from the risks of
              unintended or{" "}
            </span>
            <span className="span11">
              unlawful destruction, manipulation, loss, change or unauthorized
              disclosure or unauthorized access. Our security measures are,
              pursuant to technological progress, constantly being improved.
            </span>
          </p>
          <br></br>
          <p className="Calibri">
            <span className="span33">12: User rights</span>
          </p>

          <p className="Calibri">
            <span className="span11">
              To enforce your rights, please use the details provided in the
              <u>Contact</u>
            </span>
            <span className="span66"> </span>
            <span className="span66">
              {" "}
              section. In doing so, please ensure that an unambiguous
              identification of your person is possible.
            </span>
          </p>

          <p className="Calibri">
            <span className="span66">Right to information and access</span>
          </p>

          <p className="Calibri">
            <span className="span11">
              You have the right to obtain confirmation from us about whether or
              not your personal data is being processed, and, if this is the
              case, access to your personal data.{" "}
            </span>
          </p>

          <p className="Calibri">
            <span className="span66">
              Right to correction and deletion&nbsp;
            </span>
          </p>

          <p className="Calibri">
            <span className="span11">
              You have the right to obtain the rectification of inaccurate
              personal data.  As far as statutory requirements are fulfilled,
              you have the right to obtain the completion or deletion of your
              data.{" "}
            </span>
          </p>

          <p className="Calibri">
            <span className="span11">
              This does not apply to data which is necessary for billing or
              accounting purposes or which is subject to a statutory retention
              period. If access to such data is not required, however, its
              processing is restricted (see the following).
            </span>
          </p>

          <p className="Calibri">
            <span className="span66">Restriction of processing</span>
          </p>

          <p className="Calibri">
            <span className="span11">
              As far as statutory requirements are fulfilled you have the right
              to demand for restriction of the processing of your data.
            </span>
          </p>

          <p className="Calibri">
            <span className="span66">Data portability</span>
          </p>

          <p className="Calibri">
            <span className="span11">
              As far as statutory requirements are fulfilled you may request to
              receive data that you have provided to us in a structured,
              commonly used and machine-readable format.
            </span>
          </p>

          <p className="Calibri">
            <span className="span66">
              Objection to data processing based on the legal basis of
              “legitimate interest”
            </span>
          </p>

          <p className="Calibri">
            <span className="span66">
              In addition, you have the right to object to the processing of
              your personal data at any time, insofar as this is based on
              “legitimate interest”. We will then terminate the processing of
              your data, unless we demonstrate compelling legitimate grounds
              according to legal requirements which override your rights.{" "}
            </span>
          </p>

          <p className="Calibri">
            <span className="span66">Withdrawal of consent</span>
          </p>

          <p className="Calibri">
            <span className="span66">
              In case you consented to the processing of your data, you have the
              right to revoke this consent at any time with effect for the
              future. The lawfulness of data processing prior to your withdrawal
              remains unchanged.
            </span>
          </p>
          <br></br>
          <p className="Calibri">
            <span className="span33">
              13: Right to lodge complaint with supervisory authority
            </span>
          </p>

          <p className="Calibri">
            <span className="span11">
              You have the right to lodge a complaint with a supervisory
              authority. You can appeal to the supervisory authority which is
              responsible for your place of residence or your state of residency
              or to the supervisory authority responsible for us. The contact is
              as follows:
            </span>
          </p>

          <p className="Calibri">
            <span className="span11">DPO.India@in.bosch.com</span>
          </p>

          <p className="Calibri">
            <span className="span11">Data Protection Officer </span>
            <span style={{ fontSize: "9.0pt", lineHeight: "115%" }}>
              Bosch India
            </span>
            <span className="span66">&nbsp;</span>
            <span className="span66">
              <br></br>
            </span>
            <span className="span11">
              Post Box No 3000  Hosur Road,  Adugodi,
            </span>
            <span className="span66">
              <br></br>
            </span>
            <span className="span11">
              Bengaluru, Karnataka – 560 030 - India
            </span>
          </p>
          <br></br>
          <p className="Calibri">
            <span className="span33">
              14: Changes to the Data Protection Notice
            </span>
          </p>

          <p className="Calibri">
            <span className="span11">
              We reserve the right to change our security and data protection
              measures. In such cases, we will amend our data protection notice
              accordingly. Please, therefore, notice the current version of our
              data protection notice, as this is subject to changes.
            </span>
          </p>

          <p className="Calibri">
            <span>&nbsp;</span>
          </p>

          <p className="Calibri">
            <span className="span33">15: Contact</span>
          </p>

          <p className="Calibri">
            <span className="span11">
              If you wish to contact us, please find us at the address stated in
              the &quot;Controller&quot; section.
            </span>
          </p>

          <p className="Calibri">
            <span className="span11">
              To assert your rights and to notify data protection incidents
              please use the following link:{" "}
            </span>
            <span>
              <a href="https://www.bkms-system.net/bosch-dataprotection">
                <span className="span11">
                  https://www.bkms-system.net/bosch-dataprotection
                </span>
              </a>
            </span>
          </p>

          <p className="Calibri">
            <span className="span11">&nbsp;</span>
          </p>

          <p className="Calibri">
            <span className="span11">For </span>
            <span className="span66">
              suggestions and complaints regarding the processing of your
              personal{" "}
            </span>
            <span className="span66">
              data we recommend that you contact our data protection officer:
            </span>
          </p>

          <p className="Calibri">
            <span className="span11">Data Protection Officer</span>
            <span className="span66">
              <br></br>
            </span>
            <span className="span11">
              Information Security and Privacy (C/ISP)
            </span>
            <span className="span66">
              <br></br>
            </span>
            <span className="span11">Robert Bosch GmbH</span>
            <span className="span66">
              <br></br>
            </span>
            <span className="span11">P.O. Box 30 02 20 </span>
            <span className="span66">
              <br></br>
            </span>
            <span className="span11">70442 Stuttgart, GERMANY</span>
          </p>

          <p className="Calibri">
            <span className="span11">or</span>
          </p>

          <p className="Calibri">
            <span className="span11">mailto: </span>
            <span>
              <a href="mailto:DPO@bosch.com">
                <span className="span11">DPO@bosch.com</span>
              </a>
            </span>
          </p>

          <p className="Calibri">
            <span>
              <span className="span66">
                <span>&nbsp;</span>
              </span>
            </span>
          </p>

          <p className="Calibri">
            <span>
              <span className="span66">
                <span>&nbsp;</span>
              </span>
            </span>
          </p>

          <p className="Calibri">
            <a name="_Toc25852315">
              <span>
                <span>Effective date</span>
              </span>
            </a>
            <span>
              <span>: [2023.01.27]</span>
            </span>
          </p>

          <p className="MsoNormal">
            <span className="span22">&nbsp;</span>
          </p>

          <p className="MsoNormal">&nbsp;</p>
          <div>
              <p className="p11">
                <span className="spanb1">
                  {" "}
                  <input type="checkbox" onChange={isChecked} /> &nbsp;I have
                  read the Data Protection Notice.<br></br>
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <em>Select the checkbox and click Yes to proceed.</em>
              </p>
              <p className="p22">
                <span>&nbsp;</span>
              </p>
              <br />
              <Button
                onClick={handleLicense}
                disabled={isDisabled}
                type="primary"
                className="yes"
                style={{ width: "100px", color: "black", fontSize: "15px" }}
              >
                Yes
              </Button>
            </div>
        </div>
      )}
    </div>
  );
}

export default EulaConfirm;
