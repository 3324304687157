/* eslint-disable react/prop-types */
import { Button, Col, Modal, Row, Collapse, notification } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  CaretRightOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import "../Styles.css";
import AddEditElevatorForm from "./AddEditElevatorForm";
import { RTMBaseUrl,onboardingBaseUrl } from "../../../common/BaseUrl";
import axios from "axios";

const PhantomDevice = ({ userData }) => {
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const accessToken = window.localStorage.getItem("accessToken");
  const deviceType = window.localStorage.getItem("devicetype"); 
  const { Panel } = Collapse;
  const [elevatorDetails, setElevatorDetails] = useState();
  const [addDeviceModal, setAddDeviceModal] = useState(false);
  const [editDeviceModal, setEditDeviceModal] = useState(false);
  const [elevatorList, setElevatorList] = useState();
  const [elevatorListLoader, setElevatorListLoader] = useState();
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [deleteDeviceElevator,setDeleteDeviceElevator]= useState()
  const [uniqueId, setUniqueId]= useState()
  const divHeight = window.localStorage.getItem("SpaceHeight");
  const [newdivHeight, setNewdivHeight] = useState();
  useEffect(() => {
    setNewdivHeight(divHeight - 200);
  }, [divHeight]);
  useEffect(() => {
    // setIsLoading(true);
    const params = {
      PARENT_ID: userProfile?.["custom:parent_id"],
      TENANT_ID: userProfile?.["custom:tenant_id"],
      COUNTRY: userData?.COUNTRY,
      CITY: userData?.CITY,
      LOCATION: userData?.LOCATION,
      STORE_ID: userData?.STORE_ID,
    };

    axios
      .post(`${RTMBaseUrl}/RTMMainPage`, params, {
        headers: { ["x-access-token"]: accessToken },
      })
      .then((response) => {
        setElevatorList(response?.data?.data);
        setUniqueId(response?.data?.data?.[0]?._id)
      })
  }, [elevatorListLoader]);
  const hideModal = () => {
    setDeleteConfirmModal(false);
  };

  const ConfirmeModal = () => {
    if (deleteDeviceElevator) {
      // setLoacationdataloading(true);
      axios
        .post(
          `${onboardingBaseUrl}/deleteElevator/${uniqueId}`,
          {
            DEVICE_ID : deleteDeviceElevator?.DEVICE_ID
          },
          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((Response) => {
          setDeleteConfirmModal(false);
          setElevatorListLoader(!elevatorListLoader)
          // setLoacationdataloading(false);
          // setIsLoading(!isLoading);

          if (Response?.data?.data?.message === "Device Deleted Successfully") {
            notification.success({
              description: "Device Deleted Successfully",
            });
          }
        });
    }
  };
 
  return (
    <>
     <div style={{ float: "right", marginTop: "-45px", marginRight: "30px" }}>
        <Button
          type="primary"
          style={{ color: "white", margin: "2px 10px" }}
          onClick={() => setAddDeviceModal(true)}
        >
          Add {deviceType}
        </Button>
      </div>
     <div style={{height:`${newdivHeight}px`, overflowY:"auto"}}>
      <div 
      style={{ overflow: "auto", padding: "10px" }}>
        {elevatorList?.[0]?.DEVICE_DETAILS?.map((devices, index) => {
         
          return (
            <Collapse
            key={index}
              style={{
                backgroundColor: "#d9dbe0",
                borderRadius: "10px",
                margin: "10px",
              }}
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined
                  style={{ fontSize: "30px" }}
                  rotate={isActive ? 90 : 0}
                />
              )}
              className="site-collapse-custom-collapse"
            >
              <Panel
                header={
                  <div>
                    <span className="LocationStoreName">
                      {devices?.DEVICE_NAME}
                    </span>
                    <div style={{ float: "right" }}>
                      <EditOutlined
                        onClick={() => {
                          setEditDeviceModal(true)
                          setElevatorDetails(devices)
                        }}
                        style={{ fontSize: "23px", margin: "2px 15px" }}
                      />
                      <DeleteOutlined
                      onClick={() => {
                        setDeleteConfirmModal(true)
                        setDeleteDeviceElevator(devices)
                      }}
                        style={{ fontSize: "23px", margin: "2px" }}
                      />
                    </div>
                  </div>
                }
              >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Row gutter={[5, 20]}>
                    <Col xs={6} sm={6}>
                      <div className="elevatorheadingText">{deviceType} Name</div>
                      <div className="elevatorheading">
                        {devices?.DEVICE_NAME}
                      </div>
                    </Col>
                    <Col xs={6} sm={6}>
                      <div className="elevatorheadingText">Hardware ID</div>
                      <div className="elevatorheading">
                        {devices?.HARDWARE_ID}
                      </div>
                    </Col>
                    <Col xs={6} sm={6}>
                      <div className="elevatorheadingText">
                        Device Serial ID
                      </div>
                      <div className="elevatorheading">
                        {" "}
                        {devices?.DEVICE_ID}
                      </div>
                    </Col>
                    <Col xs={6} sm={6}>
                      <div className="elevatorheadingText">{deviceType} Type</div>
                      <div className="elevatorheading">
                        {devices?.ELEVATOR_TYPE}
                      </div>
                    </Col>
                    <Col xs={6} sm={6}>
                      <div className="elevatorheadingText">{deviceType} Modal</div>
                      <div className="elevatorheading">
                        {devices?.ELEVATOR_MODAL}
                      </div>
                    </Col>
                    <Col xs={6} sm={6}>
                      <div className="elevatorheadingText">No. of Floors</div>
                      <div className="elevatorheading">
                        {userData?.NO_OF_FLOOR}
                      </div>
                    </Col>
                    <Col xs={6} sm={6}>
                      <div className="elevatorheadingText">
                        Passenger Capacity
                      </div>
                      <div className="elevatorheading">
                        {devices?.PASSENGER_CAPACITY }
                      </div>
                    </Col>
                    <Col xs={6} sm={6}>
                      <div className="elevatorheadingText">
                        Total Energy Consumption
                      </div>
                      <div className="elevatorheading">
                        {devices?.TOTAL_ENERGY_CONSUMPTION }
                      </div>
                    </Col>
                  </Row>
                </div>
              </Panel>
            </Collapse>
          );
        })}
      </div>

      <Modal
        width={1000}
        title="Add Phantom Device"
        open={addDeviceModal}
        onOk={() => setAddDeviceModal(false)}
        onCancel={() => setAddDeviceModal(false)}
        footer={false}
      >
        <AddEditElevatorForm
          setAddDeviceModal={setAddDeviceModal}
          userData={userData}
          setElevatorListLoader={setElevatorListLoader}
          elevatorListLoader={elevatorListLoader}
        />
      </Modal>
      <Modal
        width={1000}
        title="Edit Phantom Device"
        open={editDeviceModal}
        onOk={() => setEditDeviceModal(false)}
        onCancel={() => setEditDeviceModal(false)}
        footer={false}
      >
        <AddEditElevatorForm
          setAddDeviceModal={setEditDeviceModal}
          editElevator={elevatorDetails}
          uniqueId={uniqueId}
          setElevatorListLoader={setElevatorListLoader}
          elevatorListLoader={elevatorListLoader}
        />
      </Modal>
      <Modal
        open={deleteConfirmModal}
        onOk={ConfirmeModal}
        onCancel={hideModal}
        okText="Confirm "
        cancelText="Cancel"
      >
        <div
          style={{ textAlign: "center", fontSize: "80px", color: "#008ecf" }}
        >
          <ExclamationCircleOutlined />
        </div>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          ARE YOU SURE YOU WANT TO REMOVE THIS ELEVATOR?
        </h3>
      </Modal>
    </div>
    </>
   
  );
};

export default PhantomDevice;
