/* eslint-disable react/prop-types */
import React, { useState, createRef } from "react";
import { GlobalOutlined } from "@ant-design/icons";
import { Col, Row, Checkbox } from "antd";
import "antd/dist/antd.css";
import "./Styles.css";
import ReportAnalysisCharts from "./ReportAnalysisCharts";
import { BsBuilding } from "react-icons/bs";
import { BiStore } from "react-icons/bi";
import { GiElevator } from "react-icons/gi";
import { useEffect } from "react";
const ReportsAnalysisParameter = ({
  parameterData,
  newParameterData,
  selectInterval,
  selectedDates,
  selectFilter,
  selectIndex,
  deviceId,
  dateRangeValue,
  applyButton,
  reportAnaFilter,
  electricaraVisible,
}) => {
  const ref = createRef(null);
  const [parameterCheckbox, setParameterCheckbox] = useState();
  const divHeight = window.localStorage.getItem("SpaceHeight");
  const [newdivHeight, setNewdivHeight] = useState();
  useEffect(() => {
    setNewdivHeight(divHeight - 55);
  }, [divHeight]);

  const divstyles = {
    ParameterDivColumn: {
      height: `${newdivHeight}px`,
      overflowY: "auto",
      backgroundColor: "white",
    },
    ParameterDivColumn1: {
      height: `${newdivHeight - 60}px`,
      overflowY: "auto",
      backgroundColor: "white",
    },
  };

  useEffect(() => {
    if (electricaraVisible === true) {
      setParameterCheckbox(newParameterData);
    } else {
      setParameterCheckbox(parameterData);
    }
  }, [electricaraVisible]);
  const onChange = (checkedValues) => {
    parameterCheckbox?.map((item) => {
      if (item?.title === checkedValues.title) {
        item.state = !item?.state;
      }
    });
    setParameterCheckbox([...parameterCheckbox]);
  };

  const paraData = [
    {
      icon: selectIndex?.country ? <GlobalOutlined /> : "",
      name: selectFilter?.[selectIndex?.country]?.COUNTRY,
    },
    {
      icon: selectIndex?.city ? <BsBuilding /> : "",
      name: selectFilter?.[selectIndex?.country]?.CITIES?.[selectIndex?.city]
        ?.CITY,
    },
    {
      icon: selectIndex?.location ? <BiStore /> : "",
      name: selectFilter?.[selectIndex?.country]?.CITIES?.[selectIndex?.city]
        ?.LOCATION?.[selectIndex?.location]?.LOCATION,
    },
    {
      icon: selectIndex?.store ? <GiElevator /> : "",
      name: selectFilter?.[selectIndex?.country]?.CITIES?.[selectIndex?.city]
        ?.LOCATION?.[selectIndex?.location]?.STORE?.[selectIndex?.store]
        ?.STORE_NAME,
    },
  ];

  return (
    <div ref={ref}>
      <Row style={{ backgroundColor: "#e8e9eb" }}>
        {paraData?.map((item, index) => {
          return (
            <Col
              key={index}
              xl={{ span: 4 }}
              lg={{ span: 4 }}
              md={{ span: 6 }}
              sm={{ span: 8 }}
              xs={{ span: 8 }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#e8e9eb",
                }}
              >
                <div className="bigBlueIcons" key={index}>
                  {item?.icon}
                </div>
                <div>
                  <div className="bigBlueIconsText" key={index}>
                    {item?.name}
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
      <Row className="ParameterRow">
        <Col
          xl={{ span: 4 }}
          lg={{ span: 6 }}
          md={{ span: 6 }}
          sm={{ span: 4 }}
          xs={{ span: 24 }}
          className="ParameterColumn"
        >
          <div>Parameters</div>
          {parameterCheckbox?.map((check, index) => {
            return (
              <Row key={index}>
                <Checkbox
                  className="Parameters"
                  checked={check?.state}
                  onChange={() => onChange(check)}
                >
                  {check?.title}
                </Checkbox>
              </Row>
            );
          })}
        </Col>
        <Col
          xl={{ span: 20 }}
          lg={{ span: 18 }}
          md={{ span: 18 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
          style={
            paraData?.[0]?.name === undefined
              ? divstyles.ParameterDivColumn
              : divstyles.ParameterDivColumn1
          }
        >
          <ReportAnalysisCharts
            className="ReportAnalysisChartsData"
            reportAnaFilter={reportAnaFilter}
            parameterCheckbox={parameterCheckbox}
            selectInterval={selectInterval}
            selectedDates={selectedDates}
            deviceId={deviceId}
            dateRangeValue={dateRangeValue}
            applyButton={applyButton}
            selectFilter={selectFilter}
            selectIndex={selectIndex}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ReportsAnalysisParameter;
