/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Table,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Spin,
  Select,
  Button,
  notification,
  Segmented,
  Pagination,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import { AlertBaseUrl } from "../../common/BaseUrl";
import FilterForm from "../../services/headerFilter/FilterForm";
const Upcomming = ({ userAccess ,newTableHeight}) => {
  const [form] = Form.useForm();
  const deviceType = window.localStorage.getItem("devicetype"); 
  const [openUpcoming, setOpenUpcoming] = useState(false);
  const [upcomingData, setUpcomingData] = useState();
  const [upCommingAPIData, setUpcomingAPIData] = useState();
  const [upcomingLoader, setUpcomingLoader] = useState(false);
  const [upComingFilter, setUpcomingFilter] = useState("CURRENT_WEEK");
  const [pageNo, setPageNo] = useState();
  const [elevatorName, SetElevatorName] = useState();
  const [statusData, SetStatusData] = useState();
  const [filterHeaderapiLoader, setFilterHeaderapiLoader]=useState(false)
  const [pageSize, setPageSize] = useState();
  const [paginationCount, setPagiationCount] = useState();
  const accessToken = window.localStorage.getItem("accessToken");
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const [shouldDisableSubmit, setShouldDisableSubmit] = useState(false);
  const handleStatusChange = (value) => {
    if(value !== "OPEN"){
      setShouldDisableSubmit(false);
    }
  };
  useEffect(() => {
    axios
      .post(
        `${AlertBaseUrl}/maintenanceLogs`,
        {
          STATUS: statusData === "PENDING" ?["PENDING"] : statusData === "OPEN" ?[ "OPEN"]: statusData === "IN PROGRESS" ?["INPROGRESS"] :["PENDING", "OPEN", "INPROGRESS"],
          ELEVATOR_NAME :elevatorName,
          DUE_STATUS: "UPCOMING",
          TIME_RANGE: upComingFilter,
          page: pageNo ? pageNo : 1,
          limit: pageSize ? pageSize : 10,
        },

        { headers: { ["x-access-token"]: accessToken } }
      )
      .then((response) => {
        setUpcomingAPIData(response?.data?.data?.result);
        setPagiationCount(response?.data?.data?.count);
      });
  }, [upcomingLoader, upComingFilter,filterHeaderapiLoader]);
  const columns = [
    {
      title: `${deviceType}`,
      dataIndex: "ELEVATOR_NAME",
    },
    {
      title: "Due Date",
      dataIndex: "DUE_DATE",
      render: (DUE_DATE) => {
        let time = DUE_DATE?.slice(0, 10);
        return time;
      },
    },
    {
      title: "Status",
      dataIndex: "STATUS",
    },
    {
      title: "Date updated",
      dataIndex: "CREATED_ON",
      render: (CREATED_ON) => {
        let time = CREATED_ON?.slice(0, 10);
        return time;
      },
    },
    {
      title: "Comments",
      dataIndex: "COMMENTS",
    },
    !userAccess
      ? {
          title: "Edit",
          dataIndex: "edit",
          width: "60px",
          // eslint-disable-next-line no-unused-vars
          render: (text, record, index) => (
            <div style={{ textAlign: "center" }}>
            <Button
            style={{borderRadius:"10px"}}
            onClick={() => {
              setOpenUpcoming(true);
              setUpcomingData(record);
            }}>
            <EditOutlined
            className="configurationEditIcon"
            
          /></Button>
             
            </div>
          ),
        }
      : {
          width: 0,
        },

  ];
  const onFinish = (values) => {
    axios
      .post(
        `${AlertBaseUrl}/maintenanceLogs`,
        {
          EDIT: {
            STATUS: values?.status,
            COMMENTS: values?.comment,
            ID: upcomingData?._id,
            COMPLETED_ON: values?.status === "COMPLETED" ? moment().format("YYYY-MM-DDTHH:mm:ss"):undefined,
            DEVICE_ID: upcomingData?.DEVICE_ID,
            TENANT_ID: userProfile?.["custom:tenant_id"],
            PARENT_ID: userProfile?.["custom:parent_id"],
          },
        },

        { headers: { ["x-access-token"]: accessToken } }
      )
      .then(() => {
        setUpcomingLoader(!upcomingLoader);
        setOpenUpcoming(false);
        notification.success({
          description: "Record Edited",
        });
      });
  };

  useEffect(() => {
    if (upcomingData){
      if(upcomingData?.STATUS ==="OPEN"){
        setShouldDisableSubmit(true)
      }
      form.setFieldsValue({
        elevator: upcomingData?.ELEVATOR_NAME,
        dueDate: upcomingData?.DUE_DATE?.slice(0, 10),
        status: upcomingData?.STATUS ,
        updatedDate: upcomingData?.COMPLETED_ON?.slice(0, 10),
        comment: upcomingData?.COMMENTS,
      });
    }
  }, [upcomingData]);

  useEffect(() => {
  }, [upComingFilter]);

  return (
    <div>
      <div style={{display:"flex", justifyContent:"space-between", backgroundColor: "#a0d7ef", marginTop:"-15px", height:"50px"}}>
      <FilterForm
        filtertype="Periodiupcomming"
        setFilterHeaderapiLoader={setFilterHeaderapiLoader}
        filterHeaderapiLoader={filterHeaderapiLoader}
        SetElevatorName={SetElevatorName}
        SetStatusData={SetStatusData}
      />
        <Segmented
          className="realtime"
          style={{margin:"7px" }}
          onChange={(value) => {
            setUpcomingFilter(value);
          }}
          options={[
            {
              label: "This Week",
              value: "CURRENT_WEEK",
            },
            {
              label: "Next Week",
              value: "NEXT_WEEK",
            },
            {
              label: "This Month",
              value: "CURRENT_MONTH",
            },
            {
              label: "Next Month",
              value: "NEXT_MONTH",
            },
          ]}
        />
        </div>

      <Table
       rowKey="id"
       className="MainTable"
       pagination={false}
        columns={columns}
        dataSource={upCommingAPIData}
        scroll={{ y: newTableHeight }}
        size="small"
        loading={{
          indicator: (
            <div>
              <Spin />
            </div>
          ),
          spinning: !upCommingAPIData,
        }}
      />
       <div style={{ float: "right" }}>
          <Pagination
            defaultCurrent={1}
            total={paginationCount}
            onChange={(page, pageSize) => {
              setPageNo(page);
              setPageSize(pageSize);
            }}
          />
        </div>
      <Modal
        open={openUpcoming}
        footer={null}
        onCancel={() => setOpenUpcoming(false)}
      >
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          style={{ backgroundColor: "#f3f3f3", padding: "10px" }}
        >
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item label="Elevator" name="elevator">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Due Date" name="dueDate">
                <Input disabled />
              </Form.Item>
            </Col>
   
            <Col span={12}>
              <Form.Item label="Status" name="status" required>
                <Select
                  placeholder="Select Status"
                  options={[
                    {
                      value: "PENDING",
                      label: "PENDING",
                    },
                    {
                      value: "INPROGRESS",
                      label: "INPROGRESS",
                    },
                    {
                      value: "COMPLETED",
                      label: "COMPLETED",
                    },
                  ]}
                  onChange={handleStatusChange}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Comment" name="comment">
                <Input disabled={shouldDisableSubmit} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "10px" }}
              disabled={shouldDisableSubmit}
            >
              Submit
            </Button>
           
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Upcomming;
