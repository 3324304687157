import React, { useState, useEffect } from "react";
import moment from "moment";
import { Modal, Button } from "antd";
import { useNavigate } from "react-router-dom";
 import axios from "axios";
import { baseUrl } from "./BaseUrl";
import {
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const SessionExpiration = () => {
  const navigate = useNavigate();
  const [logoutTime, setLogoutTime] = useState(
    localStorage.getItem("logoutTime")
  );
  const [sessionModalOpen, setSessionModalOpen] = useState(false);
  const [closeExpiryWarning,setCloseExpiryWarning]=useState(false);
  const [showExpired,setShowExpired]=useState(false);
  const refreshToken = window.localStorage.getItem("refreshToken");
  const UserEmail = window.localStorage.getItem("userEmail");

  useEffect(() => {
    const sessionDuration = 60*60;
    
    if (!logoutTime) {
      const loginTime = moment().unix();
      const futureLogoutTime = loginTime + sessionDuration;
      setLogoutTime(futureLogoutTime);
      localStorage.setItem("logoutTime", futureLogoutTime);
    }
    const interval = setInterval(() => {
    const timeLeft = calculateTimeLeft();
    if(timeLeft.asSeconds() <=120&&timeLeft.asSeconds()>5&&!sessionModalOpen)
      {
        setSessionModalOpen(true);
        setShowExpired(false);
      }
     else if(timeLeft.asSeconds() <=5)
        {
          setSessionModalOpen(false);
          setShowExpired(true);
          setCloseExpiryWarning(false);
        }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [logoutTime, sessionModalOpen]);

  function calculateTimeLeft() {
    if (logoutTime) {
      const now = moment().unix();
      const timeDiff = logoutTime - now;
      return moment.duration(timeDiff, "seconds");
    }
    return moment.duration(0);
  }

  const logout = () => {
    localStorage.removeItem("logoutTime");
    localStorage.clear();
    setLogoutTime(null);
    setSessionModalOpen(false);
    navigate("/");
  }; 

  let liveTime = moment().unix()
  
  if(Number(logoutTime) ===liveTime){
    localStorage.removeItem("logoutTime");
    localStorage.clear();
    setLogoutTime(null);
    setSessionModalOpen(false);
    navigate("/"); 
  }

  const ConfirmeSessionModal = () => {
    axios
      .post(
        `${baseUrl}/session`,
        {
          email: UserEmail,
          // password: password,
        },
        {
          headers: { ["x-access-token"]: refreshToken },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          window.localStorage.setItem(
            "accessToken",
            response?.data?.accessToken
          );
          window.localStorage.setItem("expTime", response?.data?.exp);
          window.localStorage.setItem(
            "refreshToken",
            response?.data?.refreshToken
          );
          const extendedLogoutTime = moment().add(1, "hour").unix();
        setLogoutTime(extendedLogoutTime);
        localStorage.setItem("logoutTime", extendedLogoutTime);
        setSessionModalOpen(false);
        }
      });
    
    // setCloseExpiryWarning(true);
  };

  return (
    <div>
            {showExpired && (
            <Modal
              title={<div style={{display:"flex",alignItems:"center",textAlign:"center",justifyContent:"center",}}>Session Expired</div>}
              open={showExpired}
              onCancel={logout}
              maskClosable={false}
              footer={null}
              closable={false}
            >           
              <div style={{ fontSize: ".9rem",display:"flex",alignItems:"center",flexDirection:"column",gap:"1rem",margin:"1rem 0 0 0" }}>
                {" "}
                <p>Session Expired, Due to inactivity</p>
                <Button style={{width:"7rem",height:'2.3rem',background:"#1890ff",color:"#fff"}}
                onClick={logout}
                >Back to Login</Button>
              </div>
            </Modal>
          )}

    {sessionModalOpen&&<Modal
        open={sessionModalOpen&&!closeExpiryWarning}
        onOk={ConfirmeSessionModal}
        onCancel={()=>{setSessionModalOpen(false);setCloseExpiryWarning(true);}}
        okText="EXTEND "
        cancelText="Cancel"
      >
        <div
          style={{ textAlign: "center", fontSize: "80px", color: "#008ecf" }}
        >
          <ExclamationCircleOutlined/>
        </div>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          SESSION GOING TO EXPIRED IN 2 MIN.
        </h3>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          DO YOU WANT TO EXTEND THE SESSION?
        </h3>
      </Modal>}
        </div>
  );
};
export default SessionExpiration;
