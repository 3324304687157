/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import {
  Button,
  Table,
  Spin,
  Tag,
  Modal,
  Form,
  Select,
  Col,
  Row,
  Input,
  Pagination,
  Tooltip,
  Popover,
} from "antd";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { AlertBaseUrl } from "../../common/BaseUrl";
import { useLocation } from "react-router";
import {
  EyeOutlined,
  EditOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import "./Styles.css";
import FilterForm from "../../services/headerFilter/FilterForm";
const AlertsTab = ({ userAccess }) => {
  const [form] = Form.useForm();
  const location = useLocation();
  
  const [alertLoader, setAlertLoader] = useState(false);
  const accessToken = window.localStorage.getItem("accessToken");
  const divHeight = window.localStorage.getItem("SpaceHeight");
  const deviceType = window.localStorage.getItem("devicetype");
  const [alertData, setAlertData] = useState();
  const [openEditAlertModal, setOpenEditAlertModal] = useState(false);
  const [showNotificationID, setShowNotificationID] = useState();
  const [notificationTableData, setNotificationTableData] = useState();
  const [alertTabEditModal, setAlertTabEditModal] = useState(false);
  const [alertFilter, setAlertFilter] = useState(false);
  const [alertStatus, setAlertStatus] = useState();
  const [pageNo, setPageNo] = useState();
  const [pageSize, setPageSize] = useState();
  const [paginationCount, setPagiationCount] = useState();
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [alertlastStatus, setAlertlastStatus] = useState();
  const [countryName, SetCountryName] = useState();
  const [cityName, SetCityName] = useState();
  const [locationName, SetLocationName] = useState();
  const [buildingName, SetBuildingName] = useState();
  const [elevatorName, SetElevatorName] = useState();
  const [alertTypeName, SetAlertTypeName] = useState();
  const [statusData, SetStatusData] = useState();
  const [priorityFilter, SetPriorityFilter] = useState();
  const [alertTypeDashboard, setAlertTypeDashboard] = useState();
  const [filterHeaderapiLoader, setFilterHeaderapiLoader] = useState(false);
  const [newtableHeight, setNewTableHeight] = useState();
  useEffect(() => {
    setNewTableHeight(divHeight - 170);
  }, [divHeight]);

  let categoryStatus = location?.state?.type;
  const options = [
    { value: "OPEN", label: "OPEN" },
    { value: "IN PROGRESS", label: "IN PROGRESS" },
    { value: "CLOSED", label: "CLOSED" },
  ].filter((option) => {
    if (alertlastStatus === "OPEN") {
      return option.value !== "OPEN";
    } else if (alertlastStatus === "IN PROGRESS") {
      return option.value !== "OPEN" && option.value !== "IN PROGRESS";
    } else if (alertlastStatus === "CLOSED") {
      return (
        option.value !== "OPEN" &&
        option.value !== "IN PROGRESS" &&
        option.value !== "CLOSED"
      );
    } else if (alertlastStatus === "AUTO-CLOSED") {
      return (
        option.value !== "OPEN" &&
        option.value !== "IN PROGRESS" &&
        option.value !== "CLOSED"
      );
    }
    return true;
  });

  const columns = [
    {
      title: "S/n",
      key: "index",
      render: (text, record, index) => index + 1,
      width: "50px",
      align: "center",
    },
    {
      title: "Alert Type",
      dataIndex: "CRITERIA",
      key: "eventType",
      width: "140px",
      render: (CRITERIA, PARAMETER) => {
        if (CRITERIA === 0 && PARAMETER?.PARAMETER === 101) {
          return "NO OF TRIPS";
        } else if (CRITERIA === 2 && PARAMETER?.PARAMETER === 101) {
          return "MAINTENANCE ON FLOOR";
        } else if (CRITERIA === 1 && PARAMETER?.PARAMETER === 101) {
          return "MAINTENANCE ON TIME";
        } else if (CRITERIA === 3 && PARAMETER?.PARAMETER === 101) {
          return "IDLE TIME";
        } else if (CRITERIA === 4 && PARAMETER?.PARAMETER === 101) {
          return "OPERATION OUTSIDE WORKING HOURS";
        } else if (CRITERIA === 5 && PARAMETER?.PARAMETER === 101) {
          return "OFFLINE/LOST COMMUNICATION/NO POWER";
        } else if (CRITERIA === 6 && PARAMETER?.PARAMETER === 101) {
          return "LIFT STUCK";
        } else if (CRITERIA === 7 && PARAMETER?.PARAMETER === 101) {
          return "POWER SHUTDOWN";
        } else if (CRITERIA === 8 && PARAMETER?.PARAMETER === 101) {
          return "ELEVATOR ONLINE";
        } else if (CRITERIA === 9 && PARAMETER?.PARAMETER === 101) {
          return "LIFT STUCK";
        } else if (CRITERIA === 0 && PARAMETER?.PARAMETER === 0) {
          return "OVER VOLTAGE";
        } else if (CRITERIA === 1 && PARAMETER?.PARAMETER === 0) {
          return "OVER CURRENT";
        } else if (CRITERIA === 2 && PARAMETER?.PARAMETER === 0) {
          return "REACTIVE POWER";
        } else if (CRITERIA === 3 && PARAMETER?.PARAMETER === 0) {
          return "PF LAG";
        } else if (CRITERIA === 4 && PARAMETER?.PARAMETER === 0) {
          return "V-THD";
        } else if (CRITERIA === 5 && PARAMETER?.PARAMETER === 0) {
          return "POWER DEMAND";
        } else if (CRITERIA === 6 && PARAMETER?.PARAMETER === 0) {
          return "UNDER VOLTAGE";
        } else if (CRITERIA === 5) {
          return "TARGET BREACH";
        } else {
          return "-";
        }
      },
    },
    {
      title: "Priority",
      dataIndex: "PRIORITY",
      key: "priority",
      align: "center",
      width: "90px",
      render: (PRIORITY) => {
        if (PRIORITY?.toUpperCase() === "HIGH") {
          return (
            <Tag className="ProprityTag" color="#ea4758">
              High
            </Tag>
          );
        } else if (PRIORITY?.toUpperCase() === "MEDIUM") {
          return (
            <Tag color="#caca57" className="ProprityTag">
              Medium
            </Tag>
          );
        } else if (PRIORITY?.toUpperCase() === "LOW") {
          return (
            <Tag color="#58cf64" className="ProprityTag">
              Low
            </Tag>
          );
        } else {
          return (
            <Tag className="ProprityTag" color="#f50">
              NF
            </Tag>
          );
        }
      },
    },
    {
      title: "Description",
      dataIndex: "DESCRIPTION",
      key: "description",
      align: "center",
      width: "140px",
    },
    {
      title: "Country",
      dataIndex: "COUNTRY",
      key: "country",
      align: "center",
      width: "90px",
    },
    {
      title: "City",
      dataIndex: "CITY",
      key: "city",
      align: "center",
      width: "100px",
    },

    {
      title: `${deviceType} Name`,
      dataIndex: "DEVICE_NAME",
      key: "elevatorName",
      align: "center",
      width: "120px",
    },
    {
      title: "Created On",
      dataIndex: "CREATED_ON",
      key: "timestamp",
      align: "center",
      width: "100px",
      render: (CREATED_ON, record) => {
        let time = CREATED_ON;
        return time;
      },
    },
    {
      title: (
        <div>
          Device Time{" "}
          <Popover content={<>TIME SHOWN IN UTC</>} trigger="hover">
            <ExclamationCircleOutlined style={{ margin: "4px 0px 0px 4px" }} />
          </Popover>
        </div>
      ),
      dataIndex: "DEVICE_TIME",
      key: "devicetime",
      align: "center",
      width: "120px",
      render: (DEVICE_TIME) => {
        if (DEVICE_TIME) {
          let time = DEVICE_TIME?.replace("T", " ")?.replace(
            /\+\d{2}:\d{2}$/,
            ""
          );
          return time;
        } else {
          return "-";
        }
      },
    },
    {
      title: "Status",
      dataIndex: "STATUS",
      key: "status",
      align: "center",
      width: "80px",
    },
    {
      title: "Comments",
      dataIndex: "COMMENTS",
      key: "comment",
      align: "center",
      width: "150px",
    },
    !userAccess
      ? {
          title: "Action",
          dataIndex: "action",
          width: "60px",
          align: "center",
          render: (text, record, index) => (
            <div style={{ textAlign: "center" }}>
              <Button
                style={{ borderRadius: "10px" }}
                onClick={() => {
                  setAlertlastStatus(record?.STATUS);
                  setAlertStatus(record);
                  setAlertTabEditModal(true);
                }}
              >
                <EditOutlined className="configurationEditIcon" />
              </Button>
            </div>
          ),
        }
      : {
          width: 0,
        },
    !userAccess
      ? {
          title: "Notification",
          dataIndex: "notification",
          align: "center",
          width: "100px",
          render: (text, record, index) => (
            <div style={{ textAlign: "center" }}>
              <Button
                style={{ borderRadius: "10px" }}
                onClick={() => {
                  setShowNotificationID(record?.ALERT_ID);
                  setOpenEditAlertModal(true);
                }}
              >
                <EyeOutlined className="configurationEditIcon" />
              </Button>
            </div>
          ),
        }
      : {
          width: 0,
        },
  ];

  useEffect(() => {
    if (alertStatus) {
      form.setFieldsValue({
        status: alertStatus?.STATUS,
        comment: alertStatus?.COMMENTS,
      });
    }
  }, [alertStatus]);

  const onFinish = (values) => {
    if (values) {
      axios
        .post(
          `${AlertBaseUrl}/editGetALert/${alertStatus?.ALERT_ID}`,
          {
            STATUS: values?.status,
            COMMENTS: values?.comment,
          },
          {
            headers: { ["x-access-token"]: accessToken },
          }
        )
        .then(() => {
          setAlertFilter(!alertFilter);
        })
        .catch(() => {});
    }
    setAlertTabEditModal(false);
    form.resetFields();
  };

  // +++++++++++++++++++++++++++++++++++++++++++

  // const [filterData, setFilterData] = useState();
  // const [selectIndex, setSelectIndex] = useState({
  //   country: "",
  //   city: "",
  //   location: "",
  //   store: "",
  // });
  // let storeName = [];
  // let storeObj = {};

  // {
  //   selectIndex?.location &&
  //     filterData?.[selectIndex?.country]?.CITIES?.[
  //       selectIndex?.city
  //     ].LOCATION?.[selectIndex?.location]?.STORE?.map((store, storeIndex) => {
  //       if (storeObj[store.STORE_NAME]) {
  //         storeObj[store.STORE_NAME].push(store.DEVICE_DETAILS?.[0]);
  //       } else {
  //         storeObj[store.STORE_NAME] = [store.DEVICE_DETAILS?.[0]];
  //       }

  //       if (!storeName.length) {
  //         storeName.push({
  //           STORE_NAME: store?.STORE_NAME,
  //           STORE_ID: store?.STORE_ID,
  //         });
  //       } else {
  //         if (!storeName.find((obj) => obj.STORE_NAME === store?.STORE_NAME))
  //           storeName.push({
  //             STORE_NAME: store?.STORE_NAME,
  //             STORE_ID: store?.STORE_ID,
  //           });
  //       }
  //     });
  // }

  useEffect(() => {
    if (location?.state?.states?.type) {
      SetAlertTypeName(location?.state?.states?.type);
      setAlertTypeDashboard(location?.state?.states?.type);
    }
  }, [location?.state?.states?.type]);
  useEffect(() => {
    if (alertTypeName) {
      setAlertTypeDashboard(alertTypeName);
    }
  }, [filterHeaderapiLoader]);
  useEffect(() => {

    setAlertLoader(true);
    axios
      .post(
        `${AlertBaseUrl}/lists/getAlerts`,
        alertTypeName
          ? {
              PRIORITY: priorityFilter,
              PARAMETER:
                alertTypeName === "IDLE TIME" ||
                alertTypeName === "OPERATION OUTSIDE WORKING HOURS" ||
                alertTypeName === "NO OF TRIPS" ||
                alertTypeName === "MAINTENANCE ON FLOOR" ||
                alertTypeName === "MAINTENANCE ON TIME" ||
                alertTypeName === "OFFLINE/LOST COMMUNICATION/NO POWER" ||
                alertTypeName === "MANUAL TAMPERING" ||
                alertTypeName === "POWER SHUTDOWN" ||
                alertTypeName === "ELEVATOR ONLINE" ||
                alertTypeName === "LIFT STUCK" ||
                alertTypeName === "POWER SHUTDOWN"
                  ? 101
                  : alertTypeName === "OVER CURRENT" ||
                    alertTypeName === "OVER VOLTAGE" ||
                    alertTypeName === "PF LAG" ||
                    alertTypeName === "REACTIVE POWER" ||
                    alertTypeName === "V-THD" ||
                    alertTypeName === "POWER DEMAND" ||
                    alertTypeName === "UNDER VOLTAGE"
                  ? 0
                  : alertTypeName === "TARGET BREACH"
                  ? 1
                  : undefined,
              CRITERIA:
                alertTypeName === "NO OF TRIPS" || alertTypeName === "OVER VOLTAGE" ? 0
                  : alertTypeName === "MAINTENANCE ON TIME" || alertTypeName === "OVER CURRENT" ? 1
                  : alertTypeName === "MAINTENANCE ON FLOOR" || alertTypeName === "REACTIVE POWER" ? 2
                  : alertTypeName === "IDLE TIME" || alertTypeName === "PF LAG" ? 3
                  : alertTypeName === "OPERATION OUTSIDE WORKING HOURS" || alertTypeName === "V-THD" ? 4
                  : alertTypeName === "OFFLINE/LOST COMMUNICATION/NO POWER" || alertTypeName === "POWER DEMAND" ? 5
                  : alertTypeName === "MANUAL TAMPERING" || alertTypeName === "UNDER VOLTAGE" ? 6
                  : alertTypeName === "POWER SHUTDOWN" ? 7 
                  : alertTypeName === "ELEVATOR ONLINE" ? 8 
                  : alertTypeName === "LIFT STUCK" ? 9 
                  : undefined,
              COUNTRY: countryName,
              CITY: cityName,
              LOCATION: locationName,
              STORE_ID: buildingName,
              DEVICE_ID: elevatorName,
              STATUS: statusData,
              page: pageNo ? pageNo : 1,
              limit: pageSize ? pageSize : 10,
            }
          :  {
            PARAMETER:
              alertTypeDashboard === "IDLE TIME" ||
              alertTypeDashboard === "OPERATION OUTSIDE WORKING HOURS" ||
              alertTypeDashboard === "NO OF TRIPS" ||
              alertTypeDashboard === "MAINTENANCE ON FLOOR" ||
              alertTypeDashboard === "MAINTENANCE ON TIME" ||
              alertTypeDashboard === "OFFLINE/LOST COMMUNICATION/NO POWER" ||
              alertTypeDashboard === "MANUAL TAMPERING" ||
              alertTypeDashboard === "POWER SHUTDOWN" ||
              alertTypeDashboard === "ELEVATOR ONLINE" ||
              alertTypeDashboard === "LIFT STUCK" 
                ? 101
                : alertTypeDashboard === "OVER CURRENT" ||
                  alertTypeDashboard === "OVER VOLTAGE" ||
                  alertTypeDashboard === "PF LAG" ||
                  alertTypeDashboard === "REACTIVE POWER" ||
                  alertTypeDashboard === "V-THD" ||
                  alertTypeDashboard === "POWER DEMAND"
                ? 0
                : alertTypeDashboard === "TARGET BREACH"
                ? 1
                : undefined,
            CRITERIA:
              alertTypeDashboard === "NO OF TRIPS" || alertTypeDashboard === "OVER VOLTAGE" ? 0
                : alertTypeDashboard === "MAINTENANCE ON TIME" || alertTypeDashboard === "OVER CURRENT" ? 1
                : alertTypeDashboard === "MAINTENANCE ON FLOOR" || alertTypeDashboard === "REACTIVE POWER" ? 2
                : alertTypeDashboard === "IDLE TIME" || alertTypeDashboard === "PF LAG" ? 3
                : alertTypeDashboard === "OPERATION OUTSIDE WORKING HOURS" || alertTypeDashboard === "V-THD" ? 4
                : alertTypeDashboard === "OFFLINE/LOST COMMUNICATION/NO POWER" || alertTypeDashboard === "POWER DEMAND" ? 5
                : alertTypeDashboard === "MANUAL TAMPERING" || alertTypeDashboard === "UNDER VOLTAGE" ? 6
                : alertTypeDashboard === "POWER SHUTDOWN" ? 7 
                : alertTypeDashboard === "ELEVATOR ONLINE" ? 8 
                : alertTypeDashboard === "LIFT STUCK" ? 9 
                : undefined,
            PRIORITY: categoryStatus ? categoryStatus : undefined,
            STATUS: statusData,
            page: pageNo ? pageNo : 1,
            limit: pageSize ? pageSize : 10,
            COUNTRY: countryName,
            CITY: cityName,
            LOCATION: locationName,
            STORE_ID: buildingName,
            DEVICE_ID: elevatorName,
          },
        {
          headers: { ["x-access-token"]: accessToken },
          ["x-offset"]: -new Date().getTimezoneOffset(),
        }
      )
      .then((Response) => {
        setAlertData(Response?.data?.data?.result);
        setPagiationCount(Response?.data?.data?.count);
        setAlertLoader(false);
      });
  }, [alertFilter, filterHeaderapiLoader, pageNo, pageSize,alertTypeDashboard]);

  const resetApiCall = (pageNo, pageSize) => {
    axios
      .post(
        `${AlertBaseUrl}/lists/getAlerts`,
        {
          page: pageNo ? pageNo : 1,
          limit: pageSize ? pageSize : 10,
        },
        {
          headers: { ["x-access-token"]: accessToken },
          ["x-offset"]: -new Date().getTimezoneOffset(),
        }
      )
      .then((Response) => {
        setAlertData(Response?.data?.data?.result);
        setPagiationCount(Response?.data?.data?.count);
        setAlertLoader(false);
      });
  };
  // ++++++++++++++++++++++++++++++++++++++++++++++++ notification modal
  useEffect(() => {
    if (showNotificationID) {
      setNotificationLoading(true);
      axios
        .post(
          `${AlertBaseUrl}/emailSMSLogs/${showNotificationID}`,
          {},
          {
            headers: { ["x-access-token"]: accessToken },
            ["x-offset"]: -new Date().getTimezoneOffset(),
          }
        )
        .then((Response) => {
          setNotificationTableData(Response?.data);
          setNotificationLoading(false);
        });
    }
  }, [showNotificationID]);

  const notificationColumn = [
    {
      title: "Alert Type",
      dataIndex: "CRITERIA",
      key: "eventType",
      render: (CRITERIA, PARAMETER) => {
        if (CRITERIA === 0 && PARAMETER?.PARAMETER === 101) {
          return "NO OF TRIPS";
        } else if (CRITERIA === 2 && PARAMETER?.PARAMETER === 101) {
          return "MAINTENANCE ON FLOOR";
        } else if (CRITERIA === 1 && PARAMETER?.PARAMETER === 101) {
          return "MAINTENANCE ON TIME";
        } else if (CRITERIA === 3 && PARAMETER?.PARAMETER === 101) {
          return "IDLE TIME";
        } else if (CRITERIA === 4 && PARAMETER?.PARAMETER === 101) {
          return "OPERATION OUTSIDE WORKING HOURS";
        } else if (CRITERIA === 5 && PARAMETER?.PARAMETER === 101) {
          return "OFFLINE/LOST COMMUNICATION/NO POWER";
        } else if (CRITERIA === 6 && PARAMETER?.PARAMETER === 101) {
          return "MANUAL TAMPERING";
        } else if (CRITERIA === 7 && PARAMETER?.PARAMETER === 101) {
          return "POWER SHUTDOWN";
        } else if (CRITERIA === 8 && PARAMETER?.PARAMETER === 101) {
          return "ELEVATOR ONLINE";
        } else if (CRITERIA === 9 && PARAMETER?.PARAMETER === 101) {
          return "LIFT STUCK";
        } else if (CRITERIA === 0 && PARAMETER?.PARAMETER === 0) {
          return "OVER VOLTAGE";
        } else if (CRITERIA === 1 && PARAMETER?.PARAMETER === 0) {
          return "OVER CURRENT";
        } else if (CRITERIA === 2 && PARAMETER?.PARAMETER === 0) {
          return "REACTIVE POWER";
        } else if (CRITERIA === 3 && PARAMETER?.PARAMETER === 0) {
          return "PF LAG";
        } else if (CRITERIA === 4 && PARAMETER?.PARAMETER === 0) {
          return "V-THD";
        } else if (CRITERIA === 5 && PARAMETER?.PARAMETER === 0) {
          return "POWER DEMAND";
        } else if (CRITERIA === 6 && PARAMETER?.PARAMETER === 0) {
          return "UNDER VOLTAGE";
        } else if (CRITERIA === 5) {
          return "TARGET BREACH";
        } else {
          return "-";
        }
      },
    },
    {
      title: "Sent To",
      dataIndex: "SENT_TO",
      key: "sentto",
      align: "center",
      width: 100,
    },
    {
      title: "Type",
      dataIndex: "NOTIFICATION_TYPE",
      key: "type",
      align: "center",
      width: 100,
    },
    {
      title: "Message",
      dataIndex: "MESSAGE_BODY",
      key: "message",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "SENT_RESPONSE",
      key: "status",
      align: "center",
      render: (text, record) => {
        if (record.ERROR_MESSAGE) {
          return (
            <>
              <Tooltip title={record?.ERROR_MESSAGE}>
                <InfoCircleOutlined style={{ color: "black" }} />
                <div style={{ fontWeight: "bold", color: "red" }}>{text}</div>
              </Tooltip>
            </>
          );
        } else {
          return (
            <div style={{ fontWeight: "bold", color: "green" }}>{text}</div>
          );
        }
      },
    },
  ];

  return (
    <div style={{ width: "99.8vw" }}>
      <div style={{ width: "99.8vw", marginTop: "-25px" }}>
        <FilterForm
          filtertype="alertNotificationtab"
          SetCountryName={SetCountryName}
          SetCityName={SetCityName}
          SetLocationName={SetLocationName}
          SetBuildingName={SetBuildingName}
          SetElevatorName={SetElevatorName}
          SetAlertTypeName={SetAlertTypeName}
          SetStatusData={SetStatusData}
          SetPriorityFilter={SetPriorityFilter}
          setFilterHeaderapiLoader={setFilterHeaderapiLoader}
          filterHeaderapiLoader={filterHeaderapiLoader}
          alertTypeName={alertTypeName}
          setAlertTypeDashboard={setAlertTypeDashboard}
          resetApiCall={resetApiCall}
        />
      </div>
      <Table
        style={{ marginTop: "-25px" }}
        className="MainTable"
        columns={columns}
        //size="small"
        dataSource={alertData}
        pagination={false}
        rowKey={(record) => record?.ALERT_ID}
        scroll={{ y: newtableHeight }}
        loading={{
          indicator: (
            <div>
              <Spin />
            </div>
          ),
          spinning: alertLoader,
        }}
      />

      <div style={{ float: "right", height: "30px", marginTop: "4px" }}>
        <Pagination
          defaultCurrent={1}
          total={paginationCount}
          onChange={(page, pageSize) => {
            setPageNo(page);
            setPageSize(pageSize);
          }}
        />
      </div>
      <Modal
        title="Update Alert"
        open={alertTabEditModal}
        onOk={() => setAlertTabEditModal(false)}
        onCancel={() => {
          setAlertTabEditModal(false);
        }}
        destroyOnClose={true}
        width={1000}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className="AlertTabModal"
        >
          <Row gutter={12}>
            <Col span={24}>
              <div className="formInputStyle">
                <label className="formLabelStyle">Status</label>
                <Form.Item name="status">
                  <Select placeholder="Select Status" options={options} />
                </Form.Item>
              </div>
            </Col>

            <Col span={24}>
              <div className="formInputStyle">
                <label className="formLabelStyle">Comment</label>
                <Tooltip placement="right"  title="Please do not enter any personal information.">&nbsp;<InfoCircleOutlined /></Tooltip>
                <Form.Item name="comment">
                  <Input className="formInput" om />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="AlertsubmitButton"
              >
                Submit
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                className="AlertsubmitButton"
                onClick={() => {
                  setAlertTabEditModal(false);
                  form.resetFields();
                }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Alert Notification"
        open={openEditAlertModal}
        onOk={() => setOpenEditAlertModal(false)}
        onCancel={() => setOpenEditAlertModal(false)}
        okText="Ok "
        cancelText="Cancel"
        width={1000}
        footer={null}
      >
        <Spin tip="Loading..." spinning={notificationLoading}>
          <Table
            columns={notificationColumn}
            dataSource={notificationTableData}
            size="middle"
          />
        </Spin>
      </Modal>
    </div>
  );
};

export default AlertsTab;
