export const alertFilterTypeElevator = [
  {
    label: "ELEVATOR",
    options: [
      { label: "NO OF TRIPS", value: "NO OF TRIPS", },
      { label: "MAINTENANCE ON TIME", value: "MAINTENANCE ON TIME", },
      { label: "MAINTENANCE ON FLOOR", value: "MAINTENANCE ON FLOOR", },
      { label: "OPERATION OUTSIDE WORKING HOURS", value: "OPERATION OUTSIDE WORKING HOURS", },
      { label: "IDLE TIME", value: "IDLE TIME", },
      { label: "OFFLINE/LOST COMMUNICATION/NO POWER", value: "OFFLINE/LOST COMMUNICATION/NO POWER", },
      { label: "POWER SHUTDOWN", value: "POWER SHUTDOWN", },
      // { label: "MANUAL TAMPERING", value: "MANUAL TAMPERING", },
      { label: "LIFT STUCK", value: "MANUAL TAMPERING", },
      { label: "ELEVATOR ONLINE", value: "ELEVATOR ONLINE", },
      // { label: "LIFT STUCK", value: "LIFT STUCK", },
    ].filter(Boolean),
  },
  {
    label: "SUPPLY SIDE PARAMETER",
    options: [
      {
        label: "OVER VOLTAGE",
        value: "OVER VOLTAGE",
      },
      {
        label: "UNDER VOLTAGE",
        value: "UNDER VOLTAGE",
      },
      {
        label: "OVER CURRENT",
        value: "OVER CURRENT",
      },
      {
        label: "REACTIVE POWER",
        value: "REACTIVE POWER",
      },
      {
        label: "PF LAG",
        value: "PF LAG",
      },
      {
        label: "V-THD",
        value: "V-THD",
      },
      {
        label: "POWER DEMAND",
        value: "POWER DEMAND",
      },
    ],
  },
  {
    label: "LOAD SIDE PARAMETER",
    options: [
      {
        label: "TARGET BREACH",
        value: "TARGET BREACH",
      },
    ],
  },
];
export const alertFilterTypeEquipment = [
  {
    label: "EQUIPMENT",
    options: [
      { label: "IDLE TIME", value: "IDLE TIME", },
      { label: "OFFLINE/LOST COMMUNICATION/NO POWER", value: "OFFLINE/LOST COMMUNICATION/NO POWER", },
    ].filter(Boolean),
  },
  {
    label: "SUPPLY SIDE PARAMETER",
    options: [
      {
        label: "OVER VOLTAGE",
        value: "OVER VOLTAGE",
      },
      {
        label: "UNDER VOLTAGE",
        value: "UNDER VOLTAGE",
      },
      {
        label: "OVER CURRENT",
        value: "OVER CURRENT",
      },
      {
        label: "REACTIVE POWER",
        value: "REACTIVE POWER",
      },
      {
        label: "PF LAG",
        value: "PF LAG",
      },
      {
        label: "V-THD",
        value: "V-THD",
      },
      {
        label: "POWER DEMAND",
        value: "POWER DEMAND",
      },
    ],
  },
  {
    label: "LOAD SIDE PARAMETER",
    options: [
      {
        label: "TARGET BREACH",
        value: "TARGET BREACH",
      },
    ],
  },
];
export const PriorityOption = ["HIGH", "MEDIUM", "LOW"];
export const StatusOption = ["OPEN", "IN PROGRESS", "CLOSED"];
export const elevatorStatusOption = ["ONLINE", "OFFLINE"];
export const maintenanceStatusOption = ["PENDING", "OPEN", "IN PROGRESS"];
export const maintenanceTypeOption = [
  {
    label: "Floor Based Maintenance",
    value: "Floor Based Maintenance",
  },
  {
    label: "Time Based Maintenance",
    value: "Time Based Maintenance",
  },
];
