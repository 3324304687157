/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Line } from "@ant-design/plots";
import moment from "moment";

function AreaChart({ chartData, called, selectInterval }) {
  const [data, setData] = useState([]);
  useEffect(() => {
    let yaxisData = [];
    if (
      (called !== "energyConsumption" || called === "reportElectrical") &&
      chartData
    ) {
      if (selectInterval === 1) {
        let dataObj = {};
        for (let obj of chartData) {
          if (!dataObj[obj["DEVICE_TIME"]]) {
            dataObj[moment(obj["DEVICE_TIME"]).format('HH:mm')] = obj["BOXDATA"];
          }
        }
        for (let i = 0; i < 24; i++) {
          let j = i;
          j = String(j).padStart(2, 0).padEnd(5, ":00");
          if (dataObj[j]) {
            yaxisData.push({
              key: i,
              category: "R-Phase",
              energy: dataObj[j]?.CURRENT_R,
            
            });
            
            yaxisData.push({
              key: i,
              category: "Y-Phase",
              energy: dataObj[j]?.CURRENT_Y,
            });
            yaxisData.push({
              key: i,
              category: "B-Phase",
              energy: dataObj[j]?.CURRENT_B,
            });
          } else {
            yaxisData.push({
              key: i,
              category: "R-Phase",
              energy: 0,
            });
            yaxisData.push({
              key: i,
              category: "Y-Phase",
              energy: 0,
            });
            yaxisData.push({
              key: i,
              category: "B-Phase",
              energy: 0,
            });
          }
        }
      } else {
        chartData?.map((chart) => {
          yaxisData.push({
            energy: chart?.BOXDATA?.CURRENT_R,
            key: moment(chart?.DEVICE_TIME).format('DD/MM/YYYY'),

            category: "R-Phase",
          });
          yaxisData.push({
            energy: chart?.BOXDATA?.CURRENT_Y,
            key: moment(chart?.DEVICE_TIME).format('DD/MM/YYYY'),

            category: "Y-Phase",
          });
          yaxisData.push({
            energy: chart?.BOXDATA?.CURRENT_B,
            key: moment(chart?.DEVICE_TIME).format('DD/MM/YYYY'),

            category: "B-Phase",
          });
        });
      }
    }
    
    setData([...yaxisData]);
  }, [chartData]);
  const config =
    called === "energyConsumption"
      ? {
          data,
          height: 280,
          padding: "10",
          xField: "key",
          yField: "value",
          // columnWidthRatio: 0.1,
          xAxis: {
            tickCount: 5,
          },
        }
      : {
          data,
          height: 300,
          padding: "10",
          xField: "key",
          yField: "energy",
          seriesField: "category",
          color: ["red", "#bf8d37", "#008ECF"],
          xAxis: {
            title: {
              display: true,
              text: selectInterval === 1 ? "Hour (0-23)" : "Date-Time",
            },
          },
          yAxis: {
            title: {
              display: true,
              text: "Current(Amps)",
            },
          },
          slider: {
            start: 0.1,
            end: 0.9,
          },
        };

  return (
    <div>
      <Line {...config} />{" "}
    </div>
  );
}

export default AreaChart;
