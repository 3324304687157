/* eslint-disable no-self-assign */
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "antd";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";
import axios from "axios";
import { RTMBaseUrl } from "../../../common/BaseUrl";
import LoadingSpinner from "../../../common/pageLoader/loadingSpinner";
import "../Styles.css"
import FilterForm from "../../../services/headerFilter/FilterForm";

const RealtimeMonitoringCards = () => {
  const location = useLocation();
  const deviceType = window.localStorage.getItem("devicetype");
  const [isLoading, setIsLoading] = useState(false);
  const [elevatorCard, setElevatorCard] = useState();
  const [countryName, SetCountryName] = useState();
  const [cityName, SetCityName] = useState();
  const [locationName, SetLocationName] = useState();
  const [buildingName, SetBuildingName] = useState();
  // eslint-disable-next-line no-unused-vars
  const [elevatorName, SetElevatorName] = useState();
  const [filterHeaderapiLoader, setFilterHeaderapiLoader] = useState(false);
  const [statusData, SetStatusData] = useState();
  const navigate = useNavigate();
  const accessToken = window.localStorage.getItem("accessToken");
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const divHeight = window.localStorage.getItem("SpaceHeight");
  const [newdivHeight, setNewdivHeight] = useState();

  useEffect(() => {
    setNewdivHeight(divHeight - 65);
  }, [divHeight]);

  const divstyles = {
    ElevatorCard: {
      height: `${newdivHeight}px`,
      overflowY: "auto",
      backgroundColor: "white",
      marginTop: "-25px"
    }
  };

  useEffect(() => {
    setIsLoading(true);

    const params = {
      PARENT_ID: userProfile?.["custom:parent_id"],
      TENANT_ID: userProfile?.["custom:tenant_id"],
      COUNTRY: countryName,
      CITY: cityName,
      LOCATION: locationName,
      STORE_ID: buildingName || location?.state?.states?.STORE_ID,
      ACTIVE_STATUS: statusData
    };

    axios.post(`${RTMBaseUrl}/getDeviceMetics`, params, {
      headers: { ["x-access-token"]: accessToken },
    }).then((response) => {
      const list = response?.data?.data;
      setElevatorCard(list);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  }, [filterHeaderapiLoader]);

  return (
    <div>
      <FilterForm
        filtertype="rtmMainmodule"
        SetCountryName={SetCountryName}
        SetCityName={SetCityName}
        SetLocationName={SetLocationName}
        SetBuildingName={SetBuildingName}
        SetElevatorName={SetElevatorName}
        SetStatusData={SetStatusData}
        setFilterHeaderapiLoader={setFilterHeaderapiLoader}
        filterHeaderapiLoader={filterHeaderapiLoader}
        disableWhileLoding={isLoading}
      />
      <div style={divstyles.ElevatorCard}>
        {isLoading ? (<LoadingSpinner />) : (

          <Row className="card-row">
            {elevatorCard?.map((card, index) => {

              return (
                <Col xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }} key={index} >
                  <Card
                    style={{ cursor: "pointer", margin: "10px 10px" }}
                    className="ElevatorCard"
                    onClick={() =>
                      navigate("/real-time-monitoring-perameter", {
                        state: { states: card, itemNo: 0 },
                      })
                    }
                  >

                    <Row
                      className="spectrum-background"
                      style={{
                        backgroundImage: card?.ACTIVE_STATUS ? "linear-gradient(to right, #24b2b1 , #82c341)" : "linear-gradient(to right, #6b7580 , #aeb4b9)",
                        borderTopRightRadius: "3px",
                        borderTopLeftRadius: "3px",
                        height: "45px",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ marginLeft: "20px", fontWeight: "bolder", color: "white", }} >
                        {card?.DEVICE_NAME} - (ID: {card?.DEVICE_ID})
                      </span>
                    </Row>

                    {deviceType === "Elevator" && (
                      <Row>
                        <Col span={8} className="elevatorCardsStyle">
                          <div className="CardAnswere"> {card?.FLOOR === "NF" ? "-" : card?.FLOOR} </div>
                          <div className="CardHeadr">Floors</div>
                        </Col>
                        <Col span={8} className="elevatorCardsStyle">
                          <div className="CardAnswere"> {card?.DOOR === "NF" ? "-" : card?.DOOR} </div>
                          <div className="CardHeadr">Door Cycle</div>
                        </Col>
                        <Col span={8} className="elevatorCardsStyle">
                          <div className="CardAnswere"> {card?.TRIP === "NF" ? "-" : card?.TRIP} </div>
                          <div className="CardHeadr">Trips</div>
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col span={8} className="elevatorCardsStyle">
                        <div className="CardAnswere"> {card?.ENERGY === "NF" ? "-" : card?.ENERGY?.toFixed(2)} </div>
                        <div className="CardHeadr">Energy(kWh)</div>
                      </Col>
                      <Col span={8} className="elevatorCardsStyle">
                        <div className="CardAnswere">
                          {card?.ACTIVE === "NF" ? "-" :
                            `${(card?.ACTIVE / 3600).toString().split(".")?.[0]} : 
                          ${String((card?.ACTIVE / 3600)?.toFixed(2).toString().split(".")?.[1] * 60)?.substring(0, 2)}`}
                        </div>
                        <div className="CardHeadr">Hours</div>
                      </Col>
                      <Col span={8} className="elevatorCardsStyle">
                        <div className="CardAnswere"> {card?.OPEN_STATUS_ALERT === "NF" ? "-" : card?.OPEN_STATUS_ALERT} </div>
                        <div className="CardHeadr">Open Alerts</div>
                      </Col>
                    </Row>

                    {deviceType === "Elevator" && (
                      <Row style={{ fontFamily: "bosch-sansreg", fontSize: "10px", fontWeight: "bold", paddingLeft: "6px", backgroundColor: "#fffff", }} >
                        Total floors Travelled from Last maintenance : &nbsp; {card?.MAINTENANCE_FLOOR_COUNT === "NF" ? "-" : card?.MAINTENANCE_FLOOR_COUNT}
                      </Row>
                    )}

                    <Row style={{ position: "absolute", fontFamily: "bosch-sansreg", fontSize: "10px", fontWeight: "bold", borderTopLeftRadius: "0", borderTopRightRadius: "0", borderBottomLeftRadius: "3px", borderBottomRightRadius: "3px", borderTop: "1px solid #f4f4f4", padding: "6px", backgroundColor: "#fffff", }} >
                      Last Maintenance on :&nbsp; {card?.COMPLETED_ON === "NF" ? "-" : moment(card?.COMPLETED_ON).format(" Do MMMM YYYY")}
                    </Row>

                  </Card>
                </Col>
              );
            })}
          </Row>
        )}
      </div>
    </div>
  );
};

export default RealtimeMonitoringCards;
