/* eslint-disable react/prop-types */
import React from "react";
import { Col, Divider, Row, Checkbox } from "antd";

const ReportConfigPreviewModal = ({ configPreviewData }) => {
  let Recurencedata = ["DAILY", "WEEKLY", "MONTHLY", "YEARLY"];
  const deviceType = window.localStorage.getItem("devicetype"); 

  return (
    <div>
      <h style={{ fontWeight:"bold"}}> Report Configuration Details</h>
      <Divider />
      <Row>
        <Col xs={24} sm={12}>
          <h2 className="headingTesxt">
            Country :
            <span className="ResultTesxt"
            >
              {configPreviewData?.COUNTRY}
            </span>
          </h2>
        </Col>
        <Col xs={24} sm={12}>
          <h2 className="headingTesxt">
            City :
            <span  className="ResultTesxt">
              {configPreviewData?.CITY}
            </span>
          </h2>
        </Col>
        <Col xs={24} sm={12}>
          <h2 className="headingTesxt">
            Location :
            <span  className="ResultTesxt"
            >
              {configPreviewData?.LOCATION}
            </span>
          </h2>
        </Col>
        <Col xs={24} sm={12}>
          <h2 className="headingTesxt">
            Building :
            <span  className="ResultTesxt"
            >
              {configPreviewData?.STORE_NAME}
            </span>
          </h2>
        </Col>
        <Col xs={24} sm={12}>
          <h2 className="headingTesxt">
            {deviceType} :
            <span  className="ResultTesxt"
            >
              {configPreviewData?.DEVICE_NAME}
            </span>
          </h2>
        </Col>
      </Row>
      <Divider />
      <div
        style={{
          padding: "40px 10px 10px 40px",
          background: "rgb(245, 243, 237)",
          borderRadius: "13px",
        }}
      >
        <Row>
          <h2 className="headingTesxt">
            Frequency :
            <Checkbox.Group value={configPreviewData?.FREQUENCY}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginLeft: "20px",
                }}
              >
                {Recurencedata.map((value, index) => (
                  <div key={index} style={{ width: "150px" }}>
                    <Checkbox
                      value={value}
                      key={index}
                      style={{
                        lineHeight: "50px",
                        fontSize: "16px",
                      }}
                    >
                      {value}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </Checkbox.Group>
          </h2>
        </Row>

        {configPreviewData?.RESPONSIBLE_CRITERIA?.map((item, index) => (
          <Row key={index}>
            <Col span={14} style={{ display: "flex" }}>
              <h2 className="headingTesxt">
              User Email Address :
                <span
                  style={{
                    margin: "0px 4px",
                    fontWeight: "bold",
                    color: "#00867dff",
                  }}
                >
                  {item?.USERID}
                </span>
              </h2>
            </Col>
           
          </Row>
        ))}
      </div>
    </div>
  );
};

export default ReportConfigPreviewModal;
