/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  TimePicker,
  Popconfirm,
  Tooltip
} from "antd";
import { baseUrl } from "../../common/BaseUrl";
import axios from "axios";
import {
  typeOption,
  typeOption2,
  alertTripSpan,
  Priority,
  MaintenanceTime,
  workingDays,
  pathSelect,
} from "./FormData";
import { PlusOutlined, MinusCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import "./Styles.css";
import axiosRetry from "axios-retry";

const Elevator = ({
  getAlertList,
  setSelectTab,
  setParams,
  setEventModal,
  selectIndex,
  setSelectIndex,
  selectFilter,
  editRowData,
  setButtonSave,
}) => {
  const { Option } = Select;
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const accessToken = window.localStorage.getItem("accessToken");
  const deviceType = window.localStorage.getItem("devicetype"); 
  const electricaraVisible =userProfile?.ONLY_ELECTRICAL_PARAMETER 
  const [form] = Form.useForm();
  const [daysArr, setDaysArr] = useState([]);
  const [alertTimeString, setAlertTimeString] = useState();
  const [yrsSelectedDate, setYrsSelectedDate] = useState();
  const [startTime, setStartTime] = useState();
  const [operatorData, setOperatorData] = useState();
  const [endDate, setEndDate] = useState();
  const [radioMvalue, setRadioMValue] = useState("floorBased");
  const [checknotification, setChecknotification] = useState("timeBased");
  const [idleStartTime, setIdleStartTime] = useState();
  const [idleEndtTime, setIdleEndTime] = useState();
  const [sTime, setSTime] = useState();
  const [eTime, setETime] = useState();
  const [yearFormate, setYearFOrmate] = useState();
  const [popOver, setPopOver] = useState(false);
  const [yearDiff, setYearDiff] = useState();
  const [inChargeData, setInchargeData] = useState();
  const [adminData, setAdminData] = useState();
  const [userCityData, setUserCityData] = useState();
  const [userCountryData, setuserCountryData] = useState();
  const [apiStorId, setApiStorId] = useState();
  const [apiLocation, setApiLocation] = useState();
  const [apiCity, setApiCity] = useState();
  const [apiCountry, setApiCountry] = useState();
  const [elevatorsDeviceID, setElevatorsDeviceID] = useState();
  const [userAgainEditData, setUserAgainEditData] = useState();
  const [apiCalled, setApiCalled] = useState(false);
  const [elevatorData, setElevatorData] = useState({
    selectDay: "MON",
    noOfDay: "1",
    noOfYear: "1",
    no_Of_Floor: "",
    noTrip: "",
    duration: "",
    idealTime: "",
    idleduration: "",
    getStartMonthNo1: "1",
    getEndMonthNo2: "1",
    timeMvalue: "WEEKLY",
    showNotification: false,
  });

  
  const [selectPartam, setSelectParam] = useState(" ");
  const [restrictedTime, setRestrictedTime] = useState();

 

  const HandleChange = (e) => {
    setRadioMValue();
    setSelectParam(e);
    setElevatorData({ ...elevatorData, showNotification: false });
  };




  useEffect(() => {
    if (editRowData) {
      setApiStorId(editRowData?.STORE_ID);
      setApiLocation(editRowData?.LOCATION);
      setApiCity(editRowData?.CITY);
      setApiCountry(editRowData?.COUNTRY);

      if (editRowData?.RESPONSIBLE_CRITERIA) {
        setUserAgainEditData(editRowData?.RESPONSIBLE_CRITERIA);
      }

      if (editRowData?.CRITERIA === 2) {
        setSelectParam("alertMaintenance");
        setRadioMValue("floorBased");
      }
      if (editRowData?.CRITERIA === 1) {
        setSelectParam("alertMaintenance");
        setRadioMValue("timeBased");
      }
      if (editRowData?.ALERT_DEFINITION?.THRESHOLD_PERIOD) {
        setYearDiff(editRowData?.ALERT_DEFINITION?.THRESHOLD_PERIOD);
        if (editRowData?.ALERT_DEFINITION?.THRESHOLD_PERIOD === "DAILY") {
          setElevatorData({
            ...elevatorData,
            timeMvalue: "WEEKLY",
          });
        } else {
          setElevatorData({
            ...elevatorData,
            timeMvalue: editRowData?.ALERT_DEFINITION?.THRESHOLD_PERIOD,
          });
        }
      }
      editRowData?.CRITERIA === 0 && editRowData?.PARAMETER === 101 ? setSelectParam("AlertTrips")
        : editRowData?.CRITERIA === 3 && editRowData?.PARAMETER === 101 ? setSelectParam("alertIdle")
        : editRowData?.CRITERIA === 4 && editRowData?.PARAMETER === 101 ? setSelectParam("alertOperationOutside")
        : editRowData?.CRITERIA === 5 && editRowData?.PARAMETER === 101 ? setSelectParam("alertForOfflineLostCommunication")
        : editRowData?.CRITERIA === 6 && editRowData?.PARAMETER === 101 ? setSelectParam("alertForManualTampering")
        : editRowData?.CRITERIA === 7 && editRowData?.PARAMETER === 101 ? setSelectParam("alertForpowerShutDown")
        : editRowData?.CRITERIA === 8 && editRowData?.PARAMETER === 101 ? setSelectParam("alertForElevatorOnline")
        : editRowData?.CRITERIA === 9 && editRowData?.PARAMETER === 101 && setSelectParam("alertForLiftStuck");

      form.setFieldsValue({
        OCCURANCE: editRowData?.OCCURANCE,
        prognosis_alert: editRowData?.IS_PROGNOSIS_ALERT_REQUIRED,
        elevator: editRowData?.DEVICE_NAME,
        country: editRowData?.COUNTRY,
        users: editRowData?.RESPONSIBLE_CRITERIA,
        city: editRowData?.CITY,
        location: editRowData?.LOCATION,
        building: editRowData?.STORE_NAME,
        description: editRowData?.DESCRIPTION,
        alertType:
          editRowData?.CRITERIA === 0 ? "AlertTrips"
            : editRowData?.CRITERIA === 1 ? "alertMaintenance"
            : editRowData?.CRITERIA === 2 ? "alertMaintenance"
            : editRowData?.CRITERIA === 3 ? "alertIdle"
            : editRowData?.CRITERIA === 4 ? "alertOperationOutside"
            : editRowData?.CRITERIA === 5 ? "alertForOfflineLostCommunication"
            : editRowData?.CRITERIA === 6 ? "alertForManualTampering"
            : editRowData?.CRITERIA === 7 ? "alertForpowerShutDown"
            : editRowData?.CRITERIA === 8 ? "alertForElevatorOnline"
            : editRowData?.CRITERIA === 9 ? "alertForLiftStuck":"",
        pathChange:
          editRowData?.CRITERIA === 1
            ? "timeBased"
            : editRowData?.CRITERIA === 2
            ? "floorBased"
            : "",
        startDate: editRowData?.VALID_FROM
          ? moment(editRowData?.VALID_FROM, "YYYY/MM/DD")
          : "",
        endDate: editRowData?.VALID_TILL
          ? moment(editRowData?.VALID_TILL, "YYYY/MM/DD")
          : "",
        priority: editRowData?.PRIORITY,
        alertTime: editRowData?.ALERT_TIME
          ? moment(editRowData?.ALERT_TIME, "hh:mm:ss A")
          : "",
        tripCount: editRowData?.ALERT_DEFINITION?.THRESHOLD_LIMIT,
        maxHours: editRowData?.ALERT_DEFINITION?.THRESHOLD_LIMIT,
        alertidleDays: editRowData?.ALERT_DEFINITION?.WORKING_DAYS,
        workingDays: editRowData?.ALERT_DEFINITION?.WORKING_DAYS,
        startTime: editRowData?.ALERT_DEFINITION?.WORKING_HOUR_START
          ? moment(editRowData?.ALERT_DEFINITION?.WORKING_HOUR_START, "hh:mm")
          : "",
        endTime: editRowData?.ALERT_DEFINITION?.WORKING_HOUR_END
          ? moment(editRowData?.ALERT_DEFINITION?.WORKING_HOUR_END, "hh:mm")
          : "",
        idleStartTime: editRowData?.ALERT_DEFINITION?.WORKING_HOUR_START
          ? moment(editRowData?.ALERT_DEFINITION?.WORKING_HOUR_START, "hh:mm")
          : "",
        idleEndTime: editRowData?.ALERT_DEFINITION?.WORKING_HOUR_END
          ? moment(editRowData?.ALERT_DEFINITION?.WORKING_HOUR_END, "hh:mm")
          : "",
        maintenance_time:
          editRowData?.ALERT_DEFINITION?.THRESHOLD_PERIOD === "DAILY"
            ? "WEEKLY"
            : editRowData?.ALERT_DEFINITION?.THRESHOLD_PERIOD,
        Working_Days:
          workingDays.some(
            (days) =>
              days.value === editRowData?.ALERT_DEFINITION?.GENERATION_DAY
          ) === true
            ? editRowData?.ALERT_DEFINITION?.GENERATION_DAY
            : "",
        no_of_week: editRowData?.ALERT_DEFINITION?.RECURRANCE
          ? parseInt(editRowData?.ALERT_DEFINITION?.RECURRANCE)
          : 1,
        no_of_Day: parseInt(editRowData?.ALERT_DEFINITION?.GENERATION_DAY),
        no_of_month: parseInt(editRowData?.ALERT_DEFINITION?.RECURRANCE),
        select_date:
          yearDiff === "YEARLY"
            ? moment(editRowData?.ALERT_DEFINITION?.GENERATION_DAY, "MM-DD")
            : "",
        date_in_Month: parseInt(editRowData?.ALERT_DEFINITION?.RECURRANCE),
        no_of_Floor: editRowData?.ALERT_DEFINITION?.THRESHOLD_LIMIT,
        set_trigger: editRowData?.ALERT_DEFINITION?.THRESHOLD_PERIOD,
      });
    }
  }, [editRowData, yearDiff]);
  const onFinish = (values) => {

    console.log('form data',values)
 
    values?.users?.map((da, i) => {
      da.EMAIL = values?.users?.[i]?.COMMUNICATION_MEDIUM?.includes("EMAIL");
      da.SMS = values?.users?.[i]?.COMMUNICATION_MEDIUM?.includes("SMS");
    });

    values.no_of_week = elevatorData?.noOfDay;
    values.date_in_Month = elevatorData?.noOfYear;
    values.no_of_Day = elevatorData?.getStartMonthNo1;
    values.no_of_month = elevatorData?.getEndMonthNo2;
    values.Working_Days = elevatorData?.selectDay;
    values.maintenance_time = elevatorData?.timeMvalue;
    values.pathChange = radioMvalue;

    const paramsValue = {
      OCCURANCE: 0,
      IS_PROGNOSIS_ALERT_REQUIRED:values?.prognosis_alert?true:false,
      TENANT_ID: userProfile?.["custom:tenant_id"],
      PARENT_ID: userProfile?.["custom:parent_id"],
      CREATED_ON: moment().format("YYYY-MM-DDTHH:mm:ss"),
      DEVICE_ID: elevatorsDeviceID,
      COUNTRY: values?.country,
      CITY: values?.city,
      STORE_ID: parseInt(
        selectIndex?.location &&
          selectFilter?.[selectIndex?.country]?.CITIES?.[selectIndex?.city]
            ?.LOCATION?.[selectIndex?.location]?.STORE?.[selectIndex?.store]
            ?.STORE_ID
      ),
      STORE_NAME: values?.building,
      DEVICE_NAME: values?.elevator,
      LOCATION: values?.location,
      RESPONSIBLE_CRITERIA: values?.users,
      DESCRIPTION: values?.description,
      PARAMETER: 101,
      EVENT_TYPE: 0,
      CRITERIA:
        values?.alertType === "AlertTrips" ? 0
          : values?.alertType === "alertIdle" ? 3
          : values?.alertType === "alertOperationOutside" ? 4
          : values?.alertType === "alertForOfflineLostCommunication" ? 5
          : values?.alertType === "alertForManualTampering" ? 6
          : values?.alertType === "alertForpowerShutDown" ? 7
          : values?.alertType === "alertForElevatorOnline" ? 8
          : values?.alertType === "alertForLiftStuck" ? 9
          : values?.alertType === "alertMaintenance" && values?.pathChange === "timeBased" ? 1
          : values?.alertType === "alertMaintenance" && values?.pathChange === "floorBased" ? 2
          : "",

      VALID_FROM:startTime ? `${startTime}T00:00:00`:undefined,
      VALID_TILL:endDate? `${endDate}T23:59:59`:undefined,
      ENABLE: true,
      PRIORITY:
        values?.alertType === "alertForOfflineLostCommunication" ? "HIGH"
          : values?.alertType === "alertForManualTampering" ? "HIGH" 
          : values?.alertType === "alertForpowerShutDown" ? "HIGH" 
          : values?.alertType === "alertForElevatorOnline" ? "HIGH" 
          : values?.alertType === "alertForLiftStuck" ? "HIGH"
          : values?.priority,
      ALERT_TIME: alertTimeString,
      LAST_ALERT_TIME: "",
      ALERT_DEFINITION:
        values?.alertType === "AlertTrips"
          ? {
              THRESHOLD_LIMIT: values?.tripCount,
              THRESHOLD_PERIOD: values?.set_trigger,
            }
          : values?.alertType === "alertIdle"
          ? {
              THRESHOLD_LIMIT: values?.maxHours,
              WORKING_DAYS: values?.alertidleDays,
              WORKING_HOUR_START: idleStartTime,
              WORKING_HOUR_END: idleEndtTime,
            }
          : values?.alertType === "alertOperationOutside"
          ? {
              WORKING_DAYS: values?.workingDays,
              WORKING_HOUR_START: sTime,
              WORKING_HOUR_END: eTime,
            }
          : values?.alertType === "alertForOfflineLostCommunication" ? { THRESHOLD_LIMIT: 0, THRESHOLD_PERIOD: "DAILY", }
          : values?.alertType === "alertForManualTampering" ? { THRESHOLD_LIMIT: 0, THRESHOLD_PERIOD: "DAILY", }
          : values?.alertType === "alertForpowerShutDown" ? { THRESHOLD_LIMIT: 0, THRESHOLD_PERIOD: "DAILY", }
          : values?.alertType === "alertForElevatorOnline" ? { THRESHOLD_LIMIT: 0, THRESHOLD_PERIOD: "DAILY", }
          : values?.alertType === "alertForLiftStuck" ? { THRESHOLD_LIMIT: 0, THRESHOLD_PERIOD: "DAILY", }
          : values?.alertType === "alertMaintenance" &&
            values?.pathChange === "timeBased" &&
            values.maintenance_time === "WEEKLY"
          ? {
              THRESHOLD_PERIOD: values?.maintenance_time,
              GENERATION_DAY: values?.Working_Days,
              RECURRANCE: values?.no_of_week?.toString(),
            }
          : values?.alertType === "alertMaintenance" &&
            values?.pathChange === "timeBased" &&
            values.maintenance_time === "MONTHLY"
          ? {
              THRESHOLD_PERIOD: values?.maintenance_time,
              GENERATION_DAY: values?.no_of_Day?.toString(),
              RECURRANCE: values?.no_of_month?.toString(),
            }
          : values?.alertType === "alertMaintenance" &&
            values?.pathChange === "timeBased" &&
            values.maintenance_time === "YEARLY"
          ? {
              THRESHOLD_PERIOD: values?.maintenance_time,
              GENERATION_DAY: yearFormate,
              RECURRANCE: values?.date_in_Month?.toString(),
            }
          : values?.alertType === "alertMaintenance" &&
            values?.pathChange === "floorBased"
          ? {
              THRESHOLD_LIMIT: values?.no_of_Floor,
              THRESHOLD_PERIOD: values?.set_trigger,
            }
          : "",
    };
    setSelectTab(true);

    if (editRowData) {
      const formdata = form.getFieldsValue();

      formdata?.users?.map((da, i) => {
        da.EMAIL =
          formdata?.users?.[i]?.COMMUNICATION_MEDIUM?.includes("EMAIL");
        da.SMS = formdata?.users?.[i]?.COMMUNICATION_MEDIUM?.includes("SMS");
      });
      paramsValue.LAST_ALERT_TIME = editRowData?.LAST_ALERT_TIME ? editRowData?.LAST_ALERT_TIME : undefined ;
      paramsValue.PARAMETER = 101;
      paramsValue.DEVICE_ID = editRowData?.DEVICE_ID;
      paramsValue.STORE_ID = parseInt(editRowData?.STORE_ID);
      paramsValue.ALERT_ID = editRowData?.ALERT_ID;
      paramsValue.DESCRIPTION = formdata?.description;
      paramsValue.CREATED_ON = editRowData?.CREATED_ON;
      paramsValue.ALERT_TIME = alertTimeString
        ? alertTimeString
        : formdata?.alertTime?.["_i"];
      paramsValue.VALID_FROM = startTime
        ? `${startTime}T00:00:00`
        : formdata?.startDate?.["_i"];
      paramsValue.VALID_TILL = endDate
        ? `${endDate}T23:59:59`
        : formdata?.endDate?.["_i"];
      paramsValue.CRITERIA =
        formdata?.alertType === "AlertTrips" ? 0
          : formdata?.alertType === "alertIdle" ? 3
          : formdata?.alertType === "alertOperationOutside" ? 4
          : formdata?.alertType === "alertForOfflineLostCommunication" ? 5
          : formdata?.alertType === "alertForManualTampering" ? 6 
          : formdata?.alertType === "alertForpowerShutDown" ? 7 
          : formdata?.alertType === "alertForElevatorOnline" ? 8 
          : formdata?.alertType === "alertForLiftStuck" ? 9
          : formdata?.alertType === "alertMaintenance" && formdata?.pathChange === "timeBased" ? 1
          : formdata?.alertType === "alertMaintenance" && formdata?.pathChange === "floorBased" ? 2
          : "";

      paramsValue.RESPONSIBLE_CRITERIA = formdata?.users;
      paramsValue.PRIORITY = formdata?.priority
        ? formdata?.priority
        : editRowData?.PRIORITY;
      paramsValue.ALERT_DEFINITION =
        formdata?.alertType === "AlertTrips"
          ? {
              THRESHOLD_LIMIT: formdata?.tripCount,
              THRESHOLD_PERIOD: formdata?.set_trigger,
            }
          : values?.alertType === "alertIdle"
          ? {
              THRESHOLD_LIMIT: formdata?.maxHours,
              WORKING_DAYS: formdata?.alertidleDays,
              WORKING_HOUR_START: idleStartTime?idleStartTime:formdata?.idleStartTime?.["_i"],
              WORKING_HOUR_END:idleEndtTime ? idleEndtTime: formdata?.idleEndTime?.["_i"],
            }
          : values?.alertType === "alertOperationOutside"
          ? {
              WORKING_DAYS: formdata?.workingDays,
              WORKING_HOUR_START: sTime ? sTime : formdata?.startTime?.["_i"],
              WORKING_HOUR_END: eTime ? eTime : formdata?.endTime?.["_i"],
            }
          : values?.alertType === "alertForOfflineLostCommunication" ? { THRESHOLD_LIMIT: 0, THRESHOLD_PERIOD: "DAILY", }
          : values?.alertType === "alertForManualTampering" ? { THRESHOLD_LIMIT: 0, THRESHOLD_PERIOD: "DAILY", }
          : values?.alertType === "alertForpowerShutDown" ? { THRESHOLD_LIMIT: 0, THRESHOLD_PERIOD: "DAILY", }
          : values?.alertType === "alertForElevatorOnline" ? { THRESHOLD_LIMIT: 0, THRESHOLD_PERIOD: "DAILY", }
          : values?.alertType === "alertForLiftStuck" ? { THRESHOLD_LIMIT: 0, THRESHOLD_PERIOD: "DAILY", }
          : formdata?.alertType === "alertMaintenance" &&
            formdata?.pathChange === "timeBased" &&
            formdata?.maintenance_time === "WEEKLY"
          ? {
              THRESHOLD_PERIOD: formdata?.maintenance_time,
              GENERATION_DAY: formdata?.Working_Days || elevatorData?.selectDay,
              RECURRANCE:
                formdata?.no_of_week?.toString() ||
                elevatorData?.noOfDay?.toString(),
            }
          : formdata?.alertType === "alertMaintenance" &&
            formdata?.pathChange === "timeBased" &&
            formdata?.maintenance_time === "MONTHLY"
          ? {
              THRESHOLD_PERIOD: formdata?.maintenance_time,
              GENERATION_DAY: formdata?.no_of_Day?.toString(),
              RECURRANCE:
                formdata?.no_of_month?.toString() ||
                elevatorData?.getStartMonthNo1?.toString(),
            }
          : formdata?.alertType === "alertMaintenance" &&
            formdata?.pathChange === "timeBased" &&
            formdata?.maintenance_time === "YEARLY"
          ? {
              THRESHOLD_PERIOD: formdata?.maintenance_time,
              GENERATION_DAY: formdata?.select_date?.["_i"] || yearFormate,
              RECURRANCE: formdata?.date_in_Month?.toString(),
            }
          : formdata?.alertType === "alertMaintenance" &&
            formdata?.pathChange === "floorBased"
          ? {
              THRESHOLD_LIMIT: formdata?.no_of_Floor,
              THRESHOLD_PERIOD: formdata?.set_trigger,
            }
          : "";
    }

    setParams({ ...paramsValue });
    axiosRetry(axios, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      },
      retryCondition: (error) => error.status.code === "504",
    });

    form.resetFields();
    setButtonSave(true);
  };

  const onChange = (e) => {
    setRadioMValue(e.target.value);
    // setElevatorData({
    //   ...elevatorData,
    //   // timeMvalue: e.target.value,
    //   showNotification: false,
    // });
    setChecknotification(e.target.value);
  };

  const monthFormat = "MMMM   DD";
  const getDays = (value) => {
    setDaysArr(value);
  };

  const HandleNotification = ({ target: { value } }) => {
    setElevatorData({
      ...elevatorData,
      timeMvalue: value,
      showNotification: false,
    });
  };

  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "country") {
      // setSelectedCompanyId(changedValues[formFieldName]);
      form.setFieldsValue({
        city: undefined,
        location: undefined,
        building: undefined,
        elevator: undefined,
        users: [{}],
      }); //reset product selection
    } else if (formFieldName === "city") {
      form.setFieldsValue({
        location: undefined,
        building: undefined,
        elevator: undefined,
        users: [{}],
      });
    } else if (formFieldName === "location") {
      form.setFieldsValue({
        building: undefined,
        elevator: undefined,
        users: [{}],
      });
    } else if (formFieldName === "building") {
      // setUseragEditData("");
      form.setFieldsValue({
        elevator: undefined,
        users: [{}],
      });
    }
  };
  // let count = !userAgainEditData ? 0 : userAgainEditData?.length;
  useEffect(() => {
    if (apiStorId) {
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["EXECUTIVE AREA"],
            COUNTRY: apiCountry,
            CITY: apiCity,
            LOCATION: apiLocation,
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setInchargeData(response?.data?.data);
        });
      
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["STORE INCHARGE", "OPERATOR"],
            COUNTRY: apiCountry,
            CITY: apiCity,
            STORE_ID: apiStorId?.toString(),
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setOperatorData(response?.data?.data);
        });
        axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          { ROLES: ["ADMIN", "EXECUTIVE GLOBAL"] },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setAdminData(response?.data?.data);
        });
      axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["EXECUTIVE COUNTRY"],
            COUNTRY: apiCountry,
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setuserCountryData(response?.data?.data);
        });
        axios
        .post(
          `${baseUrl}/roleWiseUsers`,
          {
            ROLES: ["EXECUTIVE CITY"],
            COUNTRY: apiCountry,
            CITY: apiCity,
          },

          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setUserCityData(response?.data?.data);
        });

    }
  }, [apiStorId, apiCalled]);

  let userOptionData = [];
  if (inChargeData) {
    inChargeData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (adminData) {
    adminData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (operatorData) {
    operatorData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (userCountryData) {
    userCountryData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  if (userCityData) {
    userCityData?.map((item) => {
      userOptionData.push({
        label: item?.EMAIL_ID,
        mobile: item?.PHONE,
      });
    });
  }
  // restricted decimale from input number filed
  const formatNumber = (value) => {
    if (value === null || value === undefined) return '';
    return value.toString().replace(/[^0-9]/g, '');
  };

  // date picker functionlaity
  const disabledEndDate = (current) => {
    if (!startTime) {
      return false;
    }
    return current && current < moment(startTime).endOf('day');
  };
  // handle time picker

  const addButtonInValid=()=>{
    console.log("4321",form.getFieldValue(['users']))
    if( userOptionData?.length === 0)
    {
      return true;
    }
    else if( (form.getFieldValue(['users']).length>0&&form.getFieldValue(['users'])?.[form.getFieldValue(['users'])?.length-1]===undefined))
    {
    
      return true;
    }
    else if( (form.getFieldValue(['users']).length>0&&form.getFieldValue(['users'])?.[form.getFieldValue(['users'])?.length-1]!=undefined))
    {
      
     if(Object.keys(form.getFieldValue(['users'])?.[0])?.length===0)
     {
      return true;
     }
     return false;
    }
    return false;
    
  }

  const removeButtonInvalid=()=>{
     if( (form.getFieldValue(['users']).length>0&&form.getFieldValue(['users'])?.[0]===undefined))
      {
      
        return true;
      }
      else if( (form.getFieldValue(['users']).length>0&&form.getFieldValue(['users'])?.[0]!=undefined))
        {
          
         if(Object.keys(form.getFieldValue(['users'])?.[0])?.length===0)
         {
          return true;
         }
         return false;
        }
        return false;
    // Object?.keys(form?.getFieldValue(['users'])?.[0]).length===0
  }

  const disabledEndTime = () => {
    if (!restrictedTime) {
      return [];
    }
    const startHour =parseInt(restrictedTime?.split(":")[0]);
    const startMinute = parseInt(restrictedTime?.split(":")[1]);
    const startAmPm = parseInt(restrictedTime?.split(" ")[1]);

    

    return Array.from({ length: 12 }, (_, hour) => {
      if (hour < startHour || (hour === startHour && startAmPm === 'AM')) {
        return hour;
      } else if (hour === startHour && startAmPm === 'PM') {
        return Array.from({ length: 60 }, (_, minute) => {
          if (minute < startMinute) {
            return minute;
          } else {
            return null;
          }
        });
      } else {
        return null;
      }
    });
  };
  return (
    <Form
      form={form}
      onFinish={onFinish}
      onValuesChange={handleFormValuesChange}
      style={{ padding: "0px 15px 0px 0px" }}
    >
      <Row className="rowHeadingStyle">Type Definition</Row>
            {/* PROGNOSIS checkbox */}
            {
        selectPartam==="AlertTrips"
       ||selectPartam==="alertForpowerShutDown"
      ||selectPartam==="alertForManualTampering"
      ?
     
       <Row gutter={15}>
       <Col span={15}>
               
                  <Form.Item
              
              name="prognosis_alert"
              initialValue={true} 
              valuePropName="checked"
           
            >

                <Checkbox >*prompt a special message after every 5th consecutive alert within 24 hours.</Checkbox>
      
            </Form.Item>
                  </Col>
                </Row>
      :<></>
      }
      <Row gutter={15}>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Country</label>
            <Form.Item
              name="country"
              rules={[
                {
                  required: true,
                  message: "Please Select Country!",
                },
              ]}
            >
              <Select
              placeholder="Country"
              disabled={editRowData?.COUNTRY && true}
                onChange={(value, key) => {
                  setSelectIndex({ ...selectIndex, country: key.key });
                  setApiCountry(selectFilter?.[key.key]?.COUNTRY);
                }}
              >
                {selectFilter?.map((country, index) => {
                  return (
                    <Option value={country?.COUNTRY} key={index}>
                      {country?.COUNTRY}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">City</label>
            <Form.Item
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please Select City!",
                },
              ]}
            >
              <Select
                onChange={(value, key) => {
                  setSelectIndex({ ...selectIndex, city: key.key });
                  setApiCity(
                    selectFilter?.[selectIndex?.country]?.CITIES?.[key.key]
                      ?.CITY
                  );
                }}
                placeholder="Cities"
                disabled={selectIndex?.country ? false : true}
                className="ReportModal"
              >
                {selectFilter?.[selectIndex?.country]?.CITIES?.map(
                  (city, cityIndex) => {
                    return (
                      <Option value={city?.CITY} key={cityIndex}>
                        {city?.CITY}
                      </Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Location</label>
            <Form.Item
              name="location"
              rules={[
                {
                  required: true,
                  message: "Please Select Location!",
                },
              ]}
            >
              <Select
                onChange={(value, key) => {
                  setSelectIndex({ ...selectIndex, location: key.key });
                  setApiLocation(
                    selectFilter?.[selectIndex?.country]?.CITIES?.[
                      selectIndex?.city
                    ].LOCATION?.[key.key]?.LOCATION
                  );
                }}
                disabled={
                  selectIndex?.country && selectIndex?.city ? false : true
                }
                placeholder="Location"
              >
                {selectIndex?.city &&
                  selectFilter?.[selectIndex?.country]?.CITIES?.[
                    selectIndex?.city
                  ].LOCATION?.map((store, storeIndex) => {
                    return (
                      <Option value={store?.LOCATION} key={storeIndex}>
                        {store?.LOCATION}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Building</label>
            <Form.Item
              name="building"
             
              rules={[
                {
                  required: true,
                  message: "Please Select Building!",
                },
              ]}
            >
              <Select
                onChange={(value, key) => {
                  setApiStorId(
                    selectFilter?.[selectIndex?.country]?.CITIES?.[
                      selectIndex?.city
                    ].LOCATION?.[selectIndex?.location]?.STORE?.[key.key]
                      ?.STORE_ID
                  );
              
                  setSelectIndex({ ...selectIndex, store: key.key });
                }}
                placeholder="Building"
                className="ReportModal"
                disabled={selectIndex?.location ? false : true}
              >
                {selectIndex?.location &&
                  selectFilter?.[selectIndex?.country]?.CITIES?.[
                    selectIndex?.city
                  ].LOCATION?.[selectIndex?.location]?.STORE?.map(
                    (store, storeIndex) => {
                      return (
                        <Option value={store?.STORE_NAME} key={storeIndex}>
                          {store?.STORE_NAME}
                        </Option>
                      );
                    }
                  )}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">{deviceType}</label>
            <Form.Item
              name="elevator"
              rules={[
                {
                  required: true,
                  message: "Please Select Elevator!",
                },
              ]}
            >
              <Select
                placeholder={deviceType}
                className="ReportModal"
                onChange={(value, key) => {
                
                  setElevatorsDeviceID(
                    selectIndex?.store &&
                      selectFilter?.[selectIndex?.country]?.CITIES?.[
                        selectIndex?.city
                      ].LOCATION?.[selectIndex?.location]?.STORE?.[
                        selectIndex?.store
                      ]?.DEVICE_DETAILS?.[key.key]?.DEVICE_ID
                  );
                  setSelectIndex({
                    ...selectIndex,
                    elevator: key.key,
                  });
                }}
                disabled={
                  selectIndex?.country &&
                  selectIndex?.city &&
                  selectIndex?.location &&
                  selectIndex?.store
                    ? false
                    : true
                }
              >
                {selectIndex?.store &&
                  selectFilter?.[selectIndex?.country]?.CITIES?.[
                    selectIndex?.city
                  ].LOCATION?.[selectIndex?.location]?.STORE?.[
                    selectIndex?.store
                  ]?.DEVICE_DETAILS?.map((device, deviceIndex) => {
                    return (
                      <Option value={device?.DEVICE_NAME} key={deviceIndex}>
                        {device?.DEVICE_NAME}
                      </Option>
                    );
                  })}
                {/* {elevators?.map((device, storeIndex) => {
                  return (
                    <Option value={device?.DEVICE_NAME} key={storeIndex}>
                      {device?.DEVICE_NAME}
                    </Option>
                  );
                })} */}
              </Select>
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Alert Type</label>
            <Form.Item
              name="alertType"
              rules={[
                {
                  required: true,
                  message: "Select the alert type",
                },
              ]}
            >
              <Select
              placeholder="Alert Type"
                onChange={(e) => HandleChange(e)}
                style={{
                  width: "100%",
                }}
                options={electricaraVisible === true ? typeOption2 :typeOption}
              />
            </Form.Item>
          </div>
        </Col>
        <Col xs={24} sm={24}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Description</label>
            <Tooltip placement="right"  title="Please do not enter any personal information.">&nbsp;<InfoCircleOutlined /></Tooltip>
            <Form.Item name="description">
              <Input className="formInput" placeholder="Description" style={{ border: "none" }} />
            </Form.Item>
          </div>
        </Col>
        <Col xs={12} sm={12}></Col>
      </Row>
      {selectPartam === "AlertTrips" ? (
        <Row gutter={15}>
          <Col span={8}>Set the alert trigger</Col>
          <Col span={16}>
            <Form.Item
              name="set_trigger"
              rules={[
                {
                  required: true,
                  message: "set the alert trigger",
                },
              ]}
            >
              <Radio.Group className="Trigger">
                {alertTripSpan.map((data,index) => {
                  return (
                    <Radio.Button
                    key={index}
                      value={data.value}
                      style={{
                        border: "1.5px solid #005691",
                        width: "130px",
                        textAlign: "center",
                        color: "black",
                        fontWeight: "600",
                      }}
                      onChange={(e) =>
                        setElevatorData({
                          ...elevatorData,
                          duration: e.target.value,
                        })
                      }
                    >
                      {data.label}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className="formInputStyleAlert">
              <label className="formLabelStyle">No. of Trips</label>
              <Form.Item name="tripCount">
                <InputNumber
                formatter={formatNumber}
                  min={1}
                  onChange={(e) =>
                    setElevatorData({ ...elevatorData, noTrip: e })
                  }
                  className="formInput"
                  style={{ border: "none" }}
                  required
                />
              </Form.Item>
            </div>
            {/* </div> */}
          </Col>
        </Row>
      ) : selectPartam === "alertMaintenance" ? (
        <div style={{ marginTop: "40px" }}>
          <Form.Item
            name="pathChange"
            rules={[
              {
                required: true,
                message: "set the alert",
              },
            ]}
          >
            <Radio.Group
              options={pathSelect}
              onChange={onChange}
              defaultValue={radioMvalue}
            />
          </Form.Item>
          {radioMvalue === "timeBased" ? (
            <Row>
              <Col span={4} style={{ borderRight: "1px solid #BFC0C2" }}>
                <Form.Item name="maintenance_time">
                  <Radio.Group
                    required
                    onChange={(e) => HandleNotification(e)}
                    options={MaintenanceTime}
                    defaultValue={
                      !editRowData?.ALERT_DEFINITION?.THRESHOLD_PERIOD
                        ? elevatorData?.timeMvalue
                        : editRowData?.ALERT_DEFINITION?.THRESHOLD_PERIOD ===
                          "DAILY"
                        ? "WEEKLY"
                        : editRowData?.ALERT_DEFINITION?.THRESHOLD_PERIOD
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={19} offset={1}>
                {elevatorData?.timeMvalue === "WEEKLY" ? (
                  <>
                    <Row>
                      Recur every &nbsp;&nbsp;
                      <Form.Item
                        name="no_of_week"
                        // rules={[
                        //   {
                        //     required: true,
                        //   },
                        // ]}
                      >
                        <Input
                          onChange={(e) =>
                            setElevatorData({
                              ...elevatorData,
                              noOfDay: e.target.value,
                            })
                          }
                          value={elevatorData?.noOfDay}
                          required
                        />
                      </Form.Item>
                      &nbsp; week(s) on
                    </Row>
                    <Form.Item
                      name="Working_Days"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Radio.Group
                        options={workingDays}
                        defaultValue={elevatorData.selectDay}
                        onChange={(e) =>
                          setElevatorData({
                            ...elevatorData,
                            selectDay: e.target.value,
                          })
                        }
                      />
                    </Form.Item>
                  </>
                ) : elevatorData?.timeMvalue === "MONTHLY" ? (
                  <Row>
                    Days &nbsp;
                    <Popconfirm
                      open={popOver}
                      onConfirm={() => {
                        setPopOver(false);
                      }}
                      onCancel={() => {
                        setPopOver(false);
                      }}
                      title={`Some months have fewer than ${elevatorData?.getStartMonthNo1} days. For these months, the occurrence will fall on the last day of the month`}
                      // trigger="hover"

                      disabled={
                        elevatorData?.getStartMonthNo1 == 29
                          ? false
                          : elevatorData?.getStartMonthNo1 == 30
                          ? false
                          : elevatorData?.getStartMonthNo1 == 31
                          ? false
                          : true
                      }
                    >
                      <Form.Item name="no_of_Day">
                        <InputNumber
                        formatter={formatNumber}
                          min={1}
                          max={31}
                          onChange={(e) => {
                            setElevatorData({
                              ...elevatorData,
                              getStartMonthNo1: e,
                            });
                            {
                              e < 29 ? setPopOver(false) : setPopOver(true);
                            }
                          }}
                          required
                          defaultValue={elevatorData?.getStartMonthNo1}
                        />
                      </Form.Item>
                    </Popconfirm>
                    &nbsp; of every &nbsp;&nbsp;
                    <Form.Item name="no_of_month">
                      <InputNumber
                      formatter={formatNumber}
                        min={1}
                        max={12}
                        onChange={(e) =>
                          setElevatorData({
                            ...elevatorData,
                            getEndMonthNo2: e,
                          })
                        }
                        required
                        defaultValue={elevatorData?.getEndMonthNo2}
                      />
                    </Form.Item>
                    &nbsp; month(s).
                  </Row>
                ) : (
                  elevatorData?.timeMvalue === "YEARLY" && (
                    <>
                      <Row>
                        Recur every &nbsp;&nbsp;
                        <Form.Item name="date_in_Month">
                          <InputNumber
                          formatter={formatNumber}
                            min={1}
                            onChange={(e) =>
                              setElevatorData({ ...elevatorData, noOfYear: e })
                            }
                            required
                            defaultValue={elevatorData?.noOfYear}
                          />
                        </Form.Item>
                        &nbsp; year(s) on.
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div className="formInputStyleAlert">
                            <label className="formLabelStyle">
                              Select Date
                            </label>
                            <Form.Item
                              name="select_date"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select date",
                                },
                              ]}
                            >
                              <DatePicker
                                onChange={(e, timeString) => {
                                  setYrsSelectedDate(timeString);
                                  setYearFOrmate(
                                    moment(timeString, "MMMM   DD").format(
                                      "MM-DD"
                                    )
                                  );
                                }}
                                format={monthFormat}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )
                )}
              </Col>
            </Row>
          ) : (
            radioMvalue === "floorBased" && (
              <>
                <Row gutter={15}>
                  <Col span={8}>Set the alert trigger</Col>
                  <Col span={16}>
                    <Form.Item
                      name="set_trigger"
                      rules={[
                        {
                          required: true,
                          message: "set the alert trigger",
                        },
                      ]}
                    >
                      <Radio.Group className="Trigger">
                        {alertTripSpan.map((data, index) => {
                          return (
                            <Radio.Button
                            key={index}
                              value={data.value}
                              style={{
                                border: "1.5px solid #005691",
                                width: "130px",
                                textAlign: "center",
                                color: "black",
                                fontWeight: "600",
                              }}
                              onChange={(e) =>
                                setElevatorData({
                                  ...elevatorData,
                                  duration: e.target.value,
                                })
                              }
                            >
                              {data.label}
                            </Radio.Button>
                          );
                        })}
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Col span={24}>
                  <div className="formInputStyleAlert">
                    <label className="formLabelStyle">
                      No. of Floor Travelled
                    </label>
                    <Form.Item
                      name="no_of_Floor"
                      rules={[
                        {
                          required: true,
                          message: "set the no. floors",
                        },
                      ]}
                    >
                      <InputNumber
                      formatter={formatNumber}
                        min={1}
                        className="formInput"
                        style={{ border: "none" }}
                        onChange={(e) =>
                          setElevatorData({ ...elevatorData, no_Of_Floor: e })
                        }
                        required={true}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </>
            )
          )}
        </div>
      ) : selectPartam === "alertIdle" ? (
        <div>
          <Row gutter={[24, 15]}>
            <Col span={24}>
              <label>Select working days</label>
              <Form.Item
                name="alertidleDays"
                rules={[
                  {
                    required: true,
                    message: "Select Days",
                  },
                ]}
              >
                <Checkbox.Group
                  options={workingDays}
                  onChange={(value) => getDays(value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div
                className="formInputStyleAlert"
                style={{
                  height: "56px",
                  marginBottom: "20px",
                  marginTop: "-20px",
                  borderBottom: "1px solid rgb(171, 170, 170",
                }}
              >
                <label className="formLabelStyle">Idle time (Hours)</label>
                <Form.Item name="maxHours">
                  <InputNumber
                  formatter={formatNumber}
                    required
                    min={1}
                    max={23}
                    onChange={(e) =>
                      setElevatorData({ ...elevatorData, idealTime: e })
                    }
                    className="formInput"
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <label>Select working Hours</label>
          <Row gutter={[24, 15]}>
            <Col span={12}>
              <div className="formInputStyleAlert">
                <label className="formLabelStyle">Start Time</label>
                <Form.Item
                  name="idleStartTime"
                  rules={[
                    {
                      required: true,
                      message: "Enter Start Time",
                    },
                  ]}
                >
                  <TimePicker
                    required
                    use12Hours
                    format="h:mm a"
                    className="formInput"
                    onChange={(time, timeString) => {
                      setElevatorData({
                        ...elevatorData,
                        idleTimeStart: timeString,
                      });
                      setIdleStartTime(
                        moment(timeString, "hh:mm:ss A").format("HH:mm")
                      );
                      setRestrictedTime(timeString)
                    }}
                    // defaultOpenValue={moment("00:00:00", "hh:mm:ss")}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="formInputStyleAlert">
                <label className="formLabelStyle">End Time</label>
                <Form.Item
                  name="idleEndTime"
                  rules={[
                    {
                      required: true,
                      message: "Enter End Time",
                    },
                  ]}
                >
                  <TimePicker
                    required
                    use12Hours
                    format="h:mm a"
                    className="formInput"
                    disabledHours={() => disabledEndTime()} 
                    onChange={(time, timeString) => {
                      setElevatorData({
                        ...elevatorData,
                        idleTimeEnd: timeString,
                      });
                      setIdleEndTime(
                        moment(timeString, "hh:mm:ss A").format("HH:mm")
                      );
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
      ) : selectPartam === "alertOperationOutside" ? (
        <div>
          <Row gutter={[24, 15]}>
            <Col span={24}>
              <label>Select working days</label>
              <Form.Item
                name="workingDays"
                rules={[
                  {
                    required: true,
                    message: "Select Days",
                  },
                ]}
              >
                <Checkbox.Group
                  options={workingDays}
                  onChange={(value) => getDays(value)}
                />
              </Form.Item>
            </Col>
          </Row>

          <label>Select working Hours</label>
          <Row gutter={[24, 15]}>
            <Col span={12}>
              <div className="formInputStyleAlert">
                <label className="formLabelStyle">Start Time</label>
                <Form.Item
                  name="startTime"
                  rules={[
                    {
                      required: true,
                      message: "Enter Start Time",
                    },
                  ]}
                >
                  <TimePicker
                    required
                    use12Hours
                    format="h:mm a"
                    className="formInput"
                    onChange={(time, timeString) => {
                      setElevatorData({
                        ...elevatorData,
                        sopStart: timeString,
                      });
                      setSTime(
                        moment(timeString, "hh:mm:ss A").format("HH:mm")
                      );
                      setRestrictedTime(timeString)
                    }}
                    // defaultOpenValue={moment("00:00:00", "hh:mm:ss")}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="formInputStyleAlert">
                <label className="formLabelStyle">End Time</label>
                <Form.Item
                  name="endTime"
                  rules={[
                    {
                      required: true,
                      message: "Enter End Time",
                    },
                  ]}
                >
                  <TimePicker
                    required
                    use12Hours
                    format="h:mm a"
                    className="formInput"
                    disabledHours={() => disabledEndTime()} 
                    onChange={(time, timeString) => {
                      setElevatorData({ ...elevatorData, sopEnd: timeString });
                      setETime(
                        moment(timeString, "hh:mm:ss A").format("HH:mm")
                      );
                    }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <></>
      )}








      {selectPartam === "alertForOfflineLostCommunication" 
      || selectPartam === "alertForManualTampering" 
      || selectPartam === "alertForpowerShutDown" 
      || selectPartam === "alertForElevatorOnline" 
      || selectPartam === "alertForLiftStuck" 
      || selectPartam === "alertIdle" ?<></>:
      <Row gutter={15}>
        <Col
          span={24}
          className="rowHeadingStyle11"
          style={{ marginTop: "15px", paddingLeft:"20px" }}
        >
          Range of Recurrence
        </Col>
        <Col span={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">Start Date</label>
            <Form.Item
              name="startDate"
              rules={[
                {
                  required: true,
                  message: "Select Start Date",
                },
              ]}
            >
              <DatePicker
                required
                format="YYYY-MM-DD"
                onChange={(e, timeString) => setStartTime(timeString)}
                className="formInput"
              />
            </Form.Item>
          </div>
        </Col>
        <Col span={12}>
          <div className="formInputStyleAlert">
            <label className="formLabelStyle">End Date</label>
            <Form.Item
              name="endDate"
              rules={[
                {
                  required: true,
                  message: "Select End Date",
                },
              ]}
            >
              <DatePicker
              disabledDate={disabledEndDate}
                onChange={(e, timeString) => setEndDate(timeString)}
                className="formInput"
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
      }
      {selectPartam === "alertForOfflineLostCommunication" 
      || selectPartam === "alertForManualTampering" 
      || selectPartam === "alertForpowerShutDown" 
      || selectPartam === "alertForElevatorOnline" 
      || selectPartam === "alertForLiftStuck" ? (
        <></>
      ) : (
        <Row gutter={15}>
          <Col xs={12} sm={12}>
            <div className="formInputStyleAlert">
              <label className="formLabelStyle">Priority</label>
              <Form.Item
                name="priority"
                rules={[
                  {
                    required: true,
                    message: "select the priority",
                  },
                ]}
              >
                <Select
                placeholder="Priority"
                  required
                  style={{
                    width: "100%",
                  }}
                  value={Priority.value}
                  options={Priority}
                  onChange={() => {
                    setElevatorData({
                      ...elevatorData,
                      showNotification: true,
                    });
                  }}
                />
              </Form.Item>
            </div>
          </Col>
          {radioMvalue === "timeBased" ? (
            <Col xs={12} sm={12}>
              <div className="formInputStyleAlert">
                <label className="formLabelStyle">Alert Time</label>
                <Form.Item
                  name="alertTime"
                  rules={[
                    {
                      required: true,
                      message: "Select Alert Time",
                    },
                  ]}
                >
                  <TimePicker
                    defaultValue={
                      editRowData?.ALERT_TIME
                        ? moment(editRowData?.ALERT_TIME, "HH:mm:ss")
                        : ""
                    }
                    format="h:mm a"
                    onChange={(e, timeString) => {
                      setAlertTimeString(
                        moment(timeString, "hh:mm:ss A").format("HH:mm")
                      );
                    }}
                    className="formInput"
                  />
                </Form.Item>
              </div>
            </Col>
          ) : (
            <Col xs={12} sm={12}></Col>
          )}
        </Row>
      )}
      <Row className="rowHeadingStyle11" style={{ marginTop: "15px" }}>
        User Notify
      </Row>
      <Form.List name="users" initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField },index) => (
              <Row key={index} gutter={[12, 12]} >
                <Col span={8}>
                  <div className="formInputStyleAlert">
                    <label className="formLabelStyle">User</label>
                    <Form.Item
                      {...restField}
                      name={[name, "USERID"]}
                      rules={[
                        {
                          required: true,
                          message: "Select USERID",
                        },
                      ]}
                    >
                      <Select
                        placeholder="User"
                        className="ReportModal"
                        onChange={(value, key) => {
                          let data = [...form.getFieldValue("users")];
                          // setUserAgainEditData([...form.getFieldValue("users")])
                          data[data.length - 1]["PHONE_NUMBER"] =
                            userOptionData?.[key.key]?.mobile;
                          form.setFieldsValue({ users: data });
                          // count++;
                          userOptionData.splice(key.key, 1);
                        }}
                      >
                        {userOptionData?.map((device, storeIndex) => {
                          return (
                            <Option value={device?.label} key={storeIndex}>
                              {device?.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col span={7}>
                  <div className="formInputStyleAlert">
                    <label className="formLabelStyle">Mobile No.</label>
                    <Form.Item {...restField} name={[name, "PHONE_NUMBER"]}>
                      <Input
                        disabled
                        className="formInput"
                        placeholder={"Mobile no."}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={7}>
                  <div className="formInputStyleAlert">
                    <label className="formLabelStyle">
                      Communication Medium
                    </label>
                    <div style={{ display: "flex", height: "30px" }}>
                      <Form.Item
                        {...restField}
                        name={[name, "COMMUNICATION_MEDIUM"]}
                        rules={[
                          {
                            required: true,
                            message: "Select the Communication Medium",
                          },
                        ]}
                      >
                        <Checkbox.Group>
                          <Checkbox value="EMAIL">Email</Checkbox>
                          <Checkbox value="SMS">SMS</Checkbox>
                        </Checkbox.Group>
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                <Col span={2}>
                {console.log("getFieldValue12",form.getFieldValue(['users']))}
                <Button 
                disabled={removeButtonInvalid()}
                  //  disabled={Object?.keys(form?.getFieldValue(['users'])?.[0]).length===0}
                
                  style={{ marginTop: "30px" }}  onClick={() => {
                   
                    if(form?.getFieldValue(['users']).length===1)
                    {
                        form.setFieldsValue({["users"]:[{}]});
                        return;
                    }
                        if (userAgainEditData) {
                          userAgainEditData.splice(name, 1);
                          setApiCalled(!apiCalled);
                        }
                        remove(name);
                      }}>
                    <MinusCircleOutlined
                     
                    />
                  </Button>
                </Col>
              </Row>
            ))}

       
            <Form.Item>
              <Button
                disabled={addButtonInValid()}
                type="primary"
                onClick={() => {
                  // let tempUsers=form.getFieldValue(['users']);
                  // let tempUsers2=form.getFieldValue(['users'])?.filter(item=>item!==undefined);
                  // if(tempUsers?.length!==tempUsers2.length||Object.keys(tempUsers[0]).length === 0)
                  //   {
                  //     return;
                  //   }
                  add();
                  
                  let tempObj = {};
                  userAgainEditData?.map(
                    (item) => (tempObj[item["USERID"]] = 1)
                  );

                  userOptionData = userOptionData?.filter((item) => {
                    if (!tempObj[item["label"]]) {
                      return item;
                    }
                  });
                }}
                block
                icon={<PlusOutlined />}
                style={{ width: "120px", marginTop: "30px" }}
              >
                Add User
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      {/* <Form.List name="admin" initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Row gutter={[12, 12]}>
                <Col span={8}>
                  <div className="formInputStyleAlert">
                    <label className="formLabelStyle">Admin</label>
                    <Form.Item {...restField} name={[name, "USERID"]}>
                      <Select
                        placeholder="admin"
                        className="ReportModal"
                        onChange={(value, key) => {
                          let admindata = [...form.getFieldValue("admin")];
                          admindata[adminCount]["PHONE_NUMBER"] =
                            adminOptionData?.[key.key]?.mobile;
                          form.setFieldsValue({ admin: admindata });
                          adminCount++;
                          adminOptionData.splice(key.key, 1);
                        }}
                        disabled={
                          selectIndex?.country &&
                          selectIndex?.city &&
                          selectIndex?.location
                            ? false
                            : true
                        }
                      >
                        {adminOptionData?.map((device, storeIndex) => {
                          return (
                            <Option value={device?.label} key={storeIndex}>
                              {device?.label}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="formInputStyleAlert">
                    <label className="formLabelStyle">Mobile No.</label>
                    <Form.Item {...restField} name={[name, "PHONE_NUMBER"]}>
                      <Input
                        disabled
                        className="formInput"
                        placeholder={"Mobile no."}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={8}>
                  <div className="formInputStyleAlert">
                    <label className="formLabelStyle">
                      Communication Medium
                    </label>
                    <div style={{ display: "flex", height: "30px" }}>
                      <Form.Item
                        {...restField}
                        name={[name, "SMS"]}
                        initialValue={false}
                        valuePropName="checked"
                        rules={[
                          {
                            required: false,
                            message: "Select the Communication Medium",
                          },
                        ]}
                      >
                        <Checkbox>SMS</Checkbox>
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "EMAIL"]}
                        initialValue={false}
                        valuePropName="checked"
                        // rules={[{ validator: validateField }]}
                      >
                        <Checkbox>Email</Checkbox>
                      </Form.Item>
                    </div>
                  </div>
                </Col>
                {/* <Col span={2}>
                  
                  <Button>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Button>
                </Col> */}
      {/* </Row>
            ))}
            <Form.Item>
              <Button
                disabled={
                  adminOptionData?.length === 0 || adminagEditData
                    ? true
                    : false
                }
                type="primary"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
                style={{ width: "120px", marginTop: "30px" }}
              >
                Add Admin
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>  */}

      {elevatorData?.showNotification ? (
        <div
          style={{ color: "#005691", fontWeight: "800", textAlign: "center" }}
        >
          {selectPartam === "AlertTrips" ? (
            <p>
              If the the elevator crossed {elevatorData?.noTrip} trips a{" "}
              {elevatorData?.duration?.toLowerCase()} you will get notified
            </p>
          ) : selectPartam === "alertMaintenance" ? (
            <p
              style={{
                color: "#005691",
                fontWeight: "bold",
                alignItems: "center",
                marginLeft: "75px",
              }}
            >
              {checknotification === "timeBased" &&
              elevatorData?.timeMvalue === "Weekly" ? (
                <div>
                  You will get notified for maintenance every&nbsp;
                  {elevatorData?.noOfDay}&nbsp;week(s) on&nbsp;
                  {elevatorData?.selectDay}&nbsp;starting from {startTime}
                </div>
              ) : null}
              {checknotification === "timeBased" &&
              elevatorData?.timeMvalue === "Monthly" ? (
                <div>
                  You will get notified for maintenance every{" "}
                  {elevatorData?.getStartMonthNo1} &nbsp;day of&nbsp;
                  {elevatorData?.getEndMonthNo2} month(s) starting from{" "}
                  {startTime}
                </div>
              ) : null}
              {checknotification === "timeBased" &&
              elevatorData?.timeMvalue === "Yearly" ? (
                <div>
                  You will get notified for maintenance every{" "}
                  {elevatorData?.noOfYear}&nbsp;
                  {elevatorData?.timeMvalue} on&nbsp;
                  {yrsSelectedDate}&nbsp;starting from {startTime}
                </div>
              ) : null}
              {checknotification === "floorBased" ? (
                <div>
                  You will get notified for maintenance, If the the elevator
                  crossed {elevatorData?.no_Of_Floor} Floor(s) starting from{" "}
                  {startTime}
                </div>
              ) : null}
            </p>
          ) : selectPartam === "alertIdle" ? (
            <p>
              You will get notified if Elevator is Idle on
              {daysArr.map((ele, index) => {
                return <span key={index}>&nbsp;{ele},</span>;
              })}{" "}
              from&nbsp;
              {elevatorData?.idleTimeStart} to {elevatorData?.idleTimeEnd}.
            </p>
          ) : selectPartam === "alertOperationOutside" ? (
            <p>
              You will get notified if you operate lift outside of your hours &
              days of
              {daysArr.map((ele, index) => {
                return <span key={index}>&nbsp;{ele},</span>;
              })}
              {elevatorData?.sopStart} to {elevatorData?.sopEnd}
            </p>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button
          className="saveButton"
          type="primary"
          style={{ color: "white", marginTop: "20px" }}
          onClick={() => {
            setEventModal(false);
            // form.setFieldsValue({users: editRowData?.RESPONSIBLE_CRITERIA,})
            getAlertList();
           
          }}
        >
          Cancel
        </Button>
        <Button
          className="saveButton"
          htmlType="submit"
          type="primary"
          style={{ color: "white", marginTop: "20px" }}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};


export default Elevator;
