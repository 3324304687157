import React,{useState, useEffect} from 'react'
import { Tabs } from "antd";
import Reports from './Reports';
import ReportdConfiguration from './ReportdConfiguration';
import { onboardingBaseUrl } from '../../common/BaseUrl';
import axios from 'axios';
import axiosRetry from 'axios-retry';

const ReportsModule = () => {
  const accessToken = window.localStorage.getItem("accessToken");
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const [filterData, setFilterData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [newTableHeight, setNewTableHeight] = useState();
  const divHeight =Number(window.localStorage.getItem("SpaceHeight"));
  useEffect(() => {
    setNewTableHeight(divHeight - 185);
  }, [divHeight]);
 
    
    useEffect(() => {
      const params = {
        PARENT_ID: userProfile?.["custom:parent_id"],
        TENANT_ID: userProfile?.["custom:tenant_id"],
      };
      axios
        .post(`${onboardingBaseUrl}/store-count/data`, params, {
          headers: { ["x-access-token"]: accessToken },
        })
        .then((response) => {
          setFilterData(response?.data?.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
      axiosRetry(axios, {
        retries: 3,
        retryDelay: (retryCount) => {
          return retryCount * 1000;
        },
        retryCondition: (error) => error.response.status === 504,
      });
    }, []);

  return (
    <div>
        <Tabs
            defaultActiveKey={"1"}
            style={{
              margin: "-10px 0px 0px px",
              backgroundColor: "white",
            }}
          >
            <Tabs.TabPane tab="Reports" key="1">
              <Reports isLoading={isLoading} setIsLoading={setIsLoading}  filterData={filterData}/>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Reports Configuration" key="2">
              <ReportdConfiguration isLoading={isLoading} setIsLoading={setIsLoading} filterData={filterData} accessToken={accessToken} newTableHeight={newTableHeight}/>
            </Tabs.TabPane>

          </Tabs>
    </div>
  )
}

export default ReportsModule