import React from 'react'
import DataPdf from "../assets/EM.pdf"
import EulaPdf from "../assets/09102023_EULA_Terms_revised_V1.pdf"
import IMPdf from "../assets/09142023_Phantom_Installation Manual_Final.pdf"
import IMKPdf from "../assets/Edge_Installation Manual_KAN_Revised.pdf"
import prognosisPdf from "../assets/prognosis.pdf"

const Footer = () => {
  return (
    <div style={{ backgroundColor: "#f5f5f5", fontSize: "13px", height: "50px" }}>
      <div style={{ display: "flex" }}>
        <a style={{ margin: "0px 10px", color: "#02629f" }} href="https://www.bosch.com/corporate-information/">About Bosch</a>
        <a style={{ margin: "0px 10px", color: "#02629f" }} href="https://www.bosch.com/contact/">Contact Us</a>
        <a style={{ margin: "0px 10px", color: "#02629f" }} href="https://www.bosch.com/legal-notice/">Legal Notices</a>
        <a style={{ margin: "0px 10px", color: "#02629f" }} href="https://www.bosch.com/corporate-information/">Terms Of Use</a>
        <a style={{ margin: "0px 10px", color: "#02629f" }} href={DataPdf}>Data Protection Notice</a>
        <a style={{ margin: "0px 10px", color: "#02629f" }} href="https://v1.bosch-connected-elevators.com/RBEI_PJ-IOT-IMPL2_Seam_Up_V1.0_Delta_OSS_Attribution_Document_Draft_2020_06_09.pdf">Attribution</a>
        <a style={{ margin: "0px 10px", color: "#02629f" }} href={EulaPdf}>EULA</a>
        <a style={{ margin: "0px 10px", color: "#02629f" }} href={IMPdf}>Installation Manual</a>
        <a style={{ margin: "0px 10px", color: "#02629f" }} href={IMKPdf}>Installation Manual (ಕನ್ನಡ)</a>
        <a style={{ margin: "0px 10px", color: "#02629f" }} href={prognosisPdf}>Prognosis</a>
      </div>
      <span style={{ fontSize: "11px", marginLeft: "10px" }}>Copyright © 2023,Bosch and/or its affiliates All rights reserved.</span>
    </div>
  )
}

export default Footer