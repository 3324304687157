/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import { Spin } from "antd";

const PowerDemandChart = ({
  chartData,
  setElectricalLoader,
  electricalLoader,
}) => {
  const [powerB, setPowerB] = useState([]);
  const [powerR, setPowerR] = useState([]);
  const [powerY, setPowerY] = useState([]);
  useEffect(() => {
    setElectricalLoader(true);
    let yaxisDataB = [];
    let yaxisDataR = [];
    let yaxisDataY = [];
    if (chartData) {
      chartData?.map((chart) => {
        yaxisDataR.push([
          chart?.DEVICE_TIME- new Date().getTimezoneOffset() * 60 * 1000 ,
          chart?.BOXDATA?.POWERDEMAND_R?.toFixed(2),
        ]);
        yaxisDataY.push([
          chart?.DEVICE_TIME- new Date().getTimezoneOffset() * 60 * 1000 ,
          chart?.BOXDATA?.POWERDEMAND_Y?.toFixed(2),
        ]);
        yaxisDataB.push([
          chart?.DEVICE_TIME- new Date().getTimezoneOffset() * 60 * 1000 ,
          chart?.BOXDATA?.POWERDEMAND_B?.toFixed(2),
        ]);
      });
      setPowerR([...yaxisDataR]);
      setPowerY([...yaxisDataY]);
      setPowerB([...yaxisDataB]);
    }
    setElectricalLoader(false);
  }, [chartData]);

  useEffect(() => {
    let options = {
      chart: {
        id: "power",
        type: "line",
        height: 280,
        toolbar: {
          tools: {
            zoomin: true,
            zoomout: false,
          },
        },
        zoom: {
          enabled: true,
          type: 'x',
          zoomedArea: {
            enabled: true,
            threshold: 10 // increase the drag sensitivity to activate zoomed area
          }
        }
      },
      colors: ["#EE4B2B", "#e1ad01", "#027bc7"],
      stroke: {
        width: 3,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      markers: {
        size: 0,
      },
      series: [
        {
          name: "Power_R",
          data: powerR,
        },
        {
          name: "Power_Y",
          data: powerY,
        },
        {
          name: "Power_B",
          data: powerB,
        },
      ],
      xaxis: {
        type: "datetime",
        tickPlacement: "on",
        datetimeUTC: false,
        title: {
          text: 'Date/Time',
          offsetY: 65,
          style: {
            fontSize: '12px',
            fontWeight: 600,
          },
        },
      },
      yaxis: {
        min: 0,
        tickAmount: 3,
        title: {
          text: 'Power Demand (W)',
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '12px',
            fontWeight: 600,
          },
        },
        opposite: false,
        position: 'left',
        labels: {
          offsetX: 0,
          offsetY: 0,
        },
      },
    };
    var chart1 = new ApexCharts(document.querySelector("#power"), options);

    chart1?.render();
  }, [powerB]);

  return (
    <>
      {electricalLoader ? (
        <Spin
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "140px",
          }}
        />
      ) : (
        <div id="power"></div>
      )}
    </>
  );
};

export default PowerDemandChart;
