import React, { useState } from "react";
import {
  EditOutlined,
} from "@ant-design/icons";
import { Button, Modal } from "antd";
import "./Styles.css";
import ReportsModal from "./ReportsModal";
import ReportsAnalysisParameter from "./ReportsAnalysisParameter";

const AnalysisDetails = () => {
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const electricaraVisible = userProfile?.ONLY_ELECTRICAL_PARAMETER;
  const deviceType = window.localStorage.getItem("devicetype"); 
  const [parameterData, setParameterData] = useState([
    { title: "Total Floors", state: false },
    { title: "Door Cycle", state: false },
    { title: `${deviceType} Status`, state: false },
    { title: "Active Hours", state: false },
    { title: "Energy Consumption", state: false },
  ]);
  const [newParameterData, setNewParameterData] = useState([
    { title: `${deviceType} Status`, state: false },
    { title: "Active Hours", state: false },
    { title: "Energy Consumption", state: false },
  ]);
  const [deviceId, setDeviceId] = useState();
  const [open, setOpen] = useState(true);
  const [selectedDates, setSelectedDates] = useState();
  const [selectInterval, setSelectInterval] = useState(1);
  const [saveButton, setSaveButton] = useState(false);
  const [selectFilter, setSelectFilter] = useState();
  const [selectIndex, setSelectIndex] = useState({
    country: "",
    city: "",
    location: "",
    store: "",
    elevator: "",
  });
  const [reportAnaFilter, setReportAnaFilter] = useState();
  const [dateRangeValue, setDateRangeValue] = useState("hourly");
  const [applyButton, setApplyButton] = useState(false);


  return (
    <>
      <div className="UperDiv">
      <Button 
      type="primary"
      style={{width:"110px", marginLeft:"20px"}}
      onClick={() => setOpen(true)}
      >
      Edit Filter
            <EditOutlined
              style={{fontSize:"18px", color:"white"}}
              
            />
          </Button>
        {saveButton && <div style={{ display: "flex" }}></div>}
      </div>
      {saveButton && (
        <div id="ReportAnalysis">
          <ReportsAnalysisParameter
            electricaraVisible={electricaraVisible}
            setReportAnaFilter={setReportAnaFilter}
            reportAnaFilter={reportAnaFilter}
            parameterData={parameterData}
            newParameterData={newParameterData}
            selectInterval={selectInterval}
            selectedDates={selectedDates}
            selectFilter={selectFilter}
            setSelectFilter={setSelectFilter}
            selectIndex={selectIndex}
            setSelectIndex={setSelectIndex}
            deviceId={deviceId}
            dateRangeValue={dateRangeValue}
            setDateRangeValue={setDateRangeValue}
            applyButton={applyButton}
          />
        </div>
      )}

      <Modal
        title="Query"
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1200}
        footer={null}
      >
        <div>
          <ReportsModal
            electricaraVisible={electricaraVisible}
            setOpen={setOpen}
            setReportAnaFilter={setReportAnaFilter}
            reportAnaFilter={reportAnaFilter}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            selectInterval={selectInterval}
            setSelectInterval={setSelectInterval}
            setSaveButton={setSaveButton}
            parameterData={parameterData}
            setParameterData={setParameterData}
            newParameterData={newParameterData}
            setNewParameterData={setNewParameterData}
            selectFilter={selectFilter}
            setSelectFilter={setSelectFilter}
            selectIndex={selectIndex}
            setSelectIndex={setSelectIndex}
            setDeviceId={setDeviceId}
            dateRangeValue={dateRangeValue}
            setDateRangeValue={setDateRangeValue}
            setApplyButton={setApplyButton}
          />
        </div>
      </Modal>
    </>
  );
};

export default AnalysisDetails;
