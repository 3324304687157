/* eslint-disable react/prop-types */
import { Column } from "@ant-design/plots";
import React from "react";
import { useNavigate } from "react-router";

function BarChartDashboard({ chartData, called }) {
  const navigate = useNavigate();
  let data = [];
  if (called === "alertBar") {
    data = [...chartData];
  } else {
    data = [
      {
        day: "Today",
      },
      {
        day: "Yesterday",
      },
    ];
    chartData?.map((v, i) => {
      data[i].floor = v;
    });
  }

  const config = {
    data,
    xField: called === "alertBar" ? "type" : "day",
    yField: called === "alertBar" ? "value" : "floor",
    color: ({ type }) => {
      if (type === "HIGH") {
        return "#ea4758";
      } else if (type === "MEDIUM") {
        return "#caca57";
      } else if (type === "LOW") {
        return "#58cf64";
      } else {
        return "#00A8B0";
      }
    },
    
    columnWidthRatio: 0.2,
    appendPadding: [10],
    label: {
      position: "top",
      offsetY: 12,
    },

    yAxis: {
      title: {
        display: true,
        text: called === "alertBar" ? "No. of alerts" : "Floors",
      },
    },
    onReady: (plot) => {
      plot.on('element:click', (...args) => {
        let navigationpriorityData = args?.[0]?.data?.data
        called === "alertBar" &&
        navigate("/alert-notification",{state:navigationpriorityData})})}
  };

  return (
    <div>
      <Column {...config} height={160} />
    </div>
  );
}

export default BarChartDashboard;
