/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Spin,
  Table,
  Button,
  Modal,
  notification,
  Pagination,
  Divider,
} from "antd";
import {
  EyeOutlined,
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import ReportConfigurationForm from "./ReportConfigurationForm";
import axios from "axios";
import ReportConfigPreviewModal from "./ReportConfigPreviewModal";
import { RTMBaseUrl } from "../../common/BaseUrl";
import FilterForm from "../../services/headerFilter/FilterForm";
const ReportdConfiguration = ({
  isLoading,
  setIsLoading,
  filterData,
  accessToken,
  newTableHeight,
}) => {
  const deviceType = window.localStorage.getItem("devicetype");
  const [reportConfigData, setReportConfigData] = useState(false);
  const [reportConfigPreview, setReportConfigPreview] = useState(false);
  const [reportApiLoader, setReportApiLoader] = useState(false);
  const [configLoading, setConfigLoading] = useState(false);
  const [reportConfigModal, setreportConfigModal] = useState(false);
  const [editConfigData, setEditConfigData] = useState();
  const [configDeleteData, setConfigDeleteData] = useState();
  const [configPreviewData, setconfigPreviewData] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [openDeletModal, setOpenDeletModal] = useState(false);
  const [paginationCount, setPagiationCount] = useState();
  const [countryName, SetCountryName] = useState();
  const [cityName, SetCityName] = useState();
  const [locationName, SetLocationName] = useState();
  const [buildingName, SetBuildingName] = useState();
  // eslint-disable-next-line no-unused-vars
  const [elevatorName, SetElevatorName] = useState();
  const [filterHeaderapiLoader, setFilterHeaderapiLoader] = useState(false);
  const [selectIndex, setSelectIndex] = useState({
    country: "",
    city: "",
    location: "",
    store: "",
  });
  const columns = [
    {
      title: "SN",
      dataIndex: "index",
      align: "center",
      width: "40px",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Country",
      dataIndex: "COUNTRY",
      key: "country",
      align: "center",
      width: "140px",
    },
    {
      title: "City",
      dataIndex: "CITY",
      key: "city",
      align: "center",
      width: "140px",
    },
    {
      title: "Location",
      dataIndex: "LOCATION",
      key: "location",
      align: "center",
      width: "140px",
    },
    {
      title: "Building",
      dataIndex: "STORE_NAME",
      key: "store",
      align: "center",
      width: "140px",
    },
    {
      title: `${deviceType} Name`,
      dataIndex: "DEVICE_NAME",
      key: "store",
      align: "center",
      width: "140px",
    },
    {
      title: "Edit",
      dataIndex: "action",
      width: "60px",
      align: "center",
      render: (text, record, index) => (
        <div key={index} style={{ cursor: "pointer" }}>
          <Button
            style={{ borderRadius: "10px" }}
            onClick={() => {
              setEditConfigData(record);
              setreportConfigModal(true);
            }}
          >
            <EditOutlined className="configurationEditIcon" />
          </Button>
        </div>
      ),
    },
    {
      title: "Delete",
      dataIndex: "action",
      width: "60px",
      align: "center",
      render: (text, record, index) => (
        <div key={index} style={{ cursor: "pointer" }}>
          <Button
            style={{ borderRadius: "10px" }}
            onClick={() => {
              setConfigDeleteData(record);
              setOpenDeletModal(true);
            }}
          >
            <DeleteOutlined className="configurationEditIcon" />
          </Button>
        </div>
      ),
    },
    {
      title: "Preview",
      dataIndex: "action",
      width: "80px",
      align: "center",
      render: (text, record, index) => (
        <div key={index} style={{ cursor: "pointer" }}>
          <Button
            style={{ borderRadius: "10px" }}
            onClick={() => {
              setconfigPreviewData(record);
              setReportConfigPreview(true);
            }}
          >
            <EyeOutlined className="configurationEditIcon" />
          </Button>
        </div>
      ),
    },
  ];
  const ConfirmeModal = () => {
    if (configDeleteData) {
      setConfigLoading(true);
      axios
        .post(
          `${RTMBaseUrl}/reportsCofig`,
          {
            ID: configDeleteData?._id,
          },
          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((Response) => {
          setReportApiLoader(!reportApiLoader);
          setConfigLoading(false);
          setOpenDeletModal(false);
          notification.success({
            description: Response?.data?.data,
          });
        });
    }
  };
  useEffect(() => {
    setConfigLoading(true);
    axios
      .post(
        `${RTMBaseUrl}/reportsCofigList`,
        {
          COUNTRY: countryName,
          CITY: cityName,
          LOCATION: locationName,
          STORE_ID: buildingName,
          page: pageNo ? pageNo : 1,
          limit: pageSize ? pageSize : 10,
        },
        { headers: { ["x-access-token"]: accessToken } }
      )
      .then((Response) => {
        setReportConfigData(Response?.data?.data?.result);
        setPagiationCount(Response?.data?.data?.count);
        setConfigLoading(false);
      });
  }, [reportApiLoader, pageNo, pageSize, filterHeaderapiLoader]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <Button
          type="primary"
          style={{ color: "white", marginTop: "-50px" }}
          onClick={() => {
            setreportConfigModal(true);
            setEditConfigData("");
          }}
        >
          Add Report Configuration <PlusOutlined />
        </Button>
        <Modal
          open={reportConfigModal}
          onOk={() => setreportConfigModal(false)}
          onCancel={() => {
            setreportConfigModal(false);
          }}
          destroyOnClose={true}
          width={800}
          footer={null}
        >
          <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
            Report Configuration
          </h3>
          <Divider />
          <div
            style={{
              height: "28vw",
              overflowY: "auto",
            }}
          >
            <ReportConfigurationForm
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              selectIndex={selectIndex}
              setSelectIndex={setSelectIndex}
              filterData={filterData}
              setreportConfigModal={setreportConfigModal}
              reportApiLoader={reportApiLoader}
              setReportApiLoader={setReportApiLoader}
              editConfigData={editConfigData}
            />
          </div>
        </Modal>
        <Modal
          open={reportConfigPreview}
          onOk={() => setReportConfigPreview(false)}
          onCancel={() => {
            setReportConfigPreview(false);
          }}
          destroyOnClose={true}
          width={1000}
          footer={null}
        >
          <ReportConfigPreviewModal configPreviewData={configPreviewData} />
        </Modal>
      </div>
      <div style={{ height: "40px", marginBottom: "10px", marginTop: "-25px" }}>
        <FilterForm
          filtertype="rtmMainPage"
          SetCountryName={SetCountryName}
          SetCityName={SetCityName}
          SetLocationName={SetLocationName}
          SetBuildingName={SetBuildingName}
          SetElevatorName={SetElevatorName}
          setFilterHeaderapiLoader={setFilterHeaderapiLoader}
          filterHeaderapiLoader={filterHeaderapiLoader}
        />
      </div>
      <Table
        className="MainTable"
        columns={columns}
        size="middle"
        dataSource={reportConfigData}
        pagination={false}
        scroll={{ y: newTableHeight }}
        loading={{
          indicator: (
            <div>
              <Spin />
            </div>
          ),
          spinning: configLoading,
        }}
      />
      <div style={{ float: "right" }}>
        <Pagination
          defaultCurrent={1}
          total={paginationCount}
          onChange={(page, pageSize) => {
            setPageNo(page);
            setPageSize(pageSize);
          }}
        />
      </div>
      <Modal
        open={openDeletModal}
        onOk={ConfirmeModal}
        onCancel={() => setOpenDeletModal(false)}
        okText="Confirm "
        cancelText="Cancel"
      >
        <div className="confirmModalExc">
          <ExclamationCircleOutlined />
        </div>
        <h3 className="confirmYText">
          ARE YOU SURE YOU WANT TO REMOVE THIS RECORD..?
        </h3>
      </Modal>
    </div>
  );
};

export default ReportdConfiguration;
