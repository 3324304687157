/* eslint-disable react/prop-types */
import React from "react";
import { Column } from "@ant-design/plots";
import moment from "moment";
const DoorChart = ({ chartData, selectInterval, called }) => {
  let data = [];

  const getSundayFromWeekNum = (weekNum, year) => {
    var sunday = new Date(year, 0, 1 + weekNum * 7 - 7);
    if (sunday.getDay() !== 0) {
      sunday.setDate(sunday.getDate() - 1);
    }
    return sunday;
  };

  if (selectInterval === 1) {
    if (called === "reportsDoor") {
      for (let i = 0; i < 24; i++) {
        if (chartData?.[i]) {
          data.push({
            hours: i,
            doors: chartData?.[i],
          });
        } else {
          data.push({ hours: i, doors: 0 });
        }
      }
    } else {
      for (let i = 0; i < 24; i++) {
        if (chartData?.[0]?.DOOR?.HOURLY?.[i]) {
          data.push({
            hours: i,
            doors: chartData?.[0]?.DOOR?.HOURLY?.[i]["TOTAL_HOURLY_DOOR"],
          });
        } else {
          data.push({ hours: i, doors: 0 });
        }
      }
    }
  }

  if (selectInterval < 30 && selectInterval > 1) {
    if (called === "reportsDoor") {
      for (let obj in chartData) {
        data.push({
          hours: obj?.slice(0, obj?.indexOf("T")),
          doors: chartData?.[obj] || 0,
        });
      }
    } else {
      for (let obj of chartData) {
        data.push({
          hours: obj?.DEVICE_TIME?.slice(0, obj?.DEVICE_TIME.indexOf("T")),
          doors: obj?.DOOR?.TOTAL_DAY_DOOR || 0,
        });
      }
    }
  }
  if (selectInterval < 90 && selectInterval >= 30) {
    if (called === "reportsDoor") {
      let monthDoorData = [];
      
      for (let key in chartData) {
        monthDoorData.push({
          YEAR: chartData?.[key]?.["YEAR"],
          WEEK: chartData?.[key]?.["WEEk"],
          DOOR: chartData?.[key]?.["value"] || 0,
        });
      }
      monthDoorData?.sort((a, b) => {
        a.YEAR = String(a.YEAR);
        b.YEAR = String(b.YEAR);
        return a.YEAR.localeCompare(b.YEAR) || a.WEEK - b.WEEK;
      });

      for (let obj of monthDoorData) {
        const days = obj?.WEEK;
        const date = obj?.YEAR;
        let weekDate = getSundayFromWeekNum(days, date);
        data.push({
          hours: `${obj?.WEEK}-(${weekDate?.toLocaleDateString("zh-Hans-CN")})`,
          doors: obj?.["DOOR"] || 0,
        });
      }
    } else {
      chartData?.sort((a, b) => {
        a.YEAR = String(a.YEAR);
        b.YEAR = String(b.YEAR);
        return a.YEAR.localeCompare(b.YEAR) || a.MONTH - b.MONTH;
      });

      for (let obj of chartData) {
        const days = obj?.WEEK;
        const date = obj?.YEAR;
        let weekDate = getSundayFromWeekNum(days, date);
        //   const days = 4 + 7 * (obj?.WEEK - 1);
        // const date = new Date(obj?.YEAR, 0, days);
        data.push({
          hours: `${obj?.WEEK}-(${weekDate?.toLocaleDateString("zh-Hans-CN")})`,
          doors: obj?.DOOR || 0,
        });
      }
    }
  }
  if (selectInterval >= 90) {
    // chartData?.sort((a, b) => {
    //   return a.DEVICE_TIME.localeCompare(b.DEVICE_TIME) || b.MONTH - a.MONTH
    // })

    if (called === "reportsDoor") {
      let monthDoorData = [];
      for (let key in chartData) {
        monthDoorData.push({
          YEAR: chartData?.[key]?.["YEAR"],
          MONTH: chartData?.[key]?.["MONTH"],
          DOOR: chartData?.[key]?.["value"] || 0,
        });
      }
      monthDoorData?.sort((a, b) => {
        a.YEAR = String(a.YEAR);
        b.YEAR = String(b.YEAR);
        return a.YEAR.localeCompare(b.YEAR) || a.MONTH - b.MONTH;
      });
      for (let obj of monthDoorData) {
        data.push({
          hours: `${moment()
            .month(obj?.MONTH - 1)
            .format("MMM")} ${obj?.YEAR}`,
          doors: obj?.DOOR || 0,
        });
      }
    } else {
      chartData?.sort((a, b) => {
        a.YEAR = String(a.YEAR);
        b.YEAR = String(b.YEAR);
        return a.DEVICE_TIME.localeCompare(b.DEVICE_TIME) || a.MONTH - b.MONTH;
      });
      for (let obj of chartData) {
        data.push({
          hours: `${moment()
            .month(obj?.MONTH - 1)
            .format("MMM")} ${obj.DEVICE_TIME.slice(0, 4)}`,
          doors: obj?.DOOR || 0,
        });
      }
    }
  }
  const config = {
    data,
    xField: "hours",
    yField: "doors",
    color: "#00A8B0",
    columnWidthRatio: selectInterval === 1 ? 0.8 : 0.4,
    appendPadding: [10],
    label: {
      position: "top",
      offsetY: 12,
    },
    yAxis: {
      title: {
        display: true,
        text: "Doors",
      },
    },
    xAxis: {
      title: {
        display: true,
        text:
          selectInterval === 1
            ? "Hour (0-23)"
            : selectInterval <= 7
            ? "Date"
            : selectInterval >= 90
            ? "Months"
            : selectInterval >= 30
            ? "Weeks"
            : selectInterval < 30
            ? "Daily"
            : "Date",
      },
    },
    meta: {
      doors: {
        alias: "Doors",
      },
    },
  };

  return (
    <div>
      <Column {...config} height={250} />
    </div>
  );
};

export default DoorChart;
