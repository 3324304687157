/* eslint-disable react/prop-types */
import React, { useState } from "react";
import "antd/dist/antd.css";
import { Button, Form, Input, notification, Spin } from "antd";
import { baseUrl } from "../../common/BaseUrl";
import axios from "axios";
const ChnagePasswod = ({
  setLogin,
  setForgotPasswordn,
  setResetPassword,
  verificationCode,
  setVerificationCode,
}) => {
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const onFinish = (values) => {
    setIsLoadingLogin(true);
    axios
      .post(
        verificationCode
          ? `${baseUrl}/updatePassword`
          : `${baseUrl}/changePassword`,
        verificationCode
          ? {
              email: values.email,
              verificationCode: values.verificationCode,
              newPassword: values.password,
            }
          : {
              oldPassword: values.oldPassword,
              newPassword: values.password,
              email: values.email,
            }
      )
      .then((response) => {
        setIsLoadingLogin(false);
        if(!response == undefined){
          notification.success({
            description: response?.data?.data,
          });
        }
        setVerificationCode(false);
        onCancel();
      })
  };

  const tailLayout = {
    wrapperCol: {
      span: 16,
    },
  };
  const onCancel = () => {
    setLogin(true);
    setForgotPasswordn(false);
    setResetPassword(false);
  };
  return (
    <Spin
      spinning={isLoadingLogin}
      delay={500}
      tip="Loading"
      size="large"
      style={{ marginTop: "200px", fontSize: "25px", fontWeight: "bold" }}
    >
      <h3 style={{textAlign:"center", fontWeight:"bold" ,marginTop:"-5px"}}>Change Password</h3>
      <Form
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <div className="formInputDiv11" >
            <label className="formInputLabel">Email</label>
            <Input className="formInput1111" style={{ border: "none" }} />
          </div>
        </Form.Item>
        {verificationCode ? (
          <Form.Item
            name="verificationCode"
            rules={[
              {
                required: true,
                message: "Please input your Verification Code!",
              },
            ]}
          >
            <div className="formInputDiv11" style={{ marginTop: "-5px" }}>
              <label className="formInputLabel">Verification Code</label>
              <Input className="formInput1111" style={{ border: "none" }} />
            </div>
          </Form.Item>
        ) : (
          <Form.Item
            name="oldPassword"
            rules={[
              {
                required: true,
                message: "Please input your Old Password!",
              },
            ]}
          >
            <div className="formInputDiv11" style={{ marginTop: "-5px" }}>
              <label className="formInputLabel">Old Password</label>
              <Input className="formInput1111" style={{ border: "none" }} />
            </div>
          </Form.Item>
        )}

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
        >
          <div className="formInputDiv11" style={{ marginTop: "-5px" }}>
            <label className="formInputLabel">Password</label>

            <Input.Password
              className="formInput1111"
              style={{ border: "none" }}
            />
          </div>
        </Form.Item>

        <Form.Item
          name="confirm"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <div className="formInputDiv11" style={{ marginTop: "-5px" }}>
            <label className="formInputLabel">Confirm Password</label>
            <Input.Password
              className="formInput1111"
              style={{ border: "none" }}
            />
          </div>
        </Form.Item>
        <Form.Item {...tailLayout} >
          <Button type="primary" onClick={onCancel} block> Cancel</Button>
        </Form.Item>
        <Form.Item {...tailLayout} >
          <Button type="primary" htmlType="submit" block>Change Password</Button>
        </Form.Item>
        {/* <Form.Item {...tailLayout}> */}
        {/* </Form.Item> */}
      </Form>
    </Spin>
  );
};

export default ChnagePasswod;
