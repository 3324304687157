/* eslint-disable react/prop-types */
import React, { useEffect} from "react";
import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import markerIcon from "../../../assets/blue-darker.png";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder";
import * as ELG from "esri-leaflet-geocoder";
const MapModal = ({ setStoreResult }) => {
  // let marker;
  useEffect(() => {
    let map = L.map("leafletmap").setView([10, 60], 3);
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
      map
    );
    L.Marker.prototype.setIcon(
      L.icon({
        iconUrl: markerIcon,
        iconSize: [25, 40],
      })
    );
    let marker = L.marker([12.91, 77.63]).addTo(map);
    const searchControl = new ELG.Geosearch();
    const results = new L.LayerGroup().addTo(map);

    searchControl
      .on("results", function (data) {
        results.clearLayers();
        setStoreResult(data);
        for (let i = data.results.length - 1; i >= 0; i--) {
          map.removeLayer(marker);
          results.addLayer(L.marker(data.results[i].latlng));
        }
      })
      .addTo(map);

    return () => {
      map.remove();
    };
  }, []);
  return <div id="leafletmap" style={{ width: "100%", height: "400px" }}></div>;
};

export default MapModal;
