/* eslint-disable react/prop-types */
import React, { useRef } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { useScreenshot, createFileName } from "use-react-screenshot";

const JpgdownloadButton = ({ data, filename }) => {
  let chartTitle = useRef();
  // eslint-disable-next-line no-unused-vars
  const [screenshot, takeScreenShot] = useScreenshot({
    type: "image/jpg",
    quality: 1.0,
  });
  const jpgDownload = () => {
    chartTitle = filename;
    takeScreenShot(data.current).then(downloadjpg);
  };
  const downloadjpg = (
    screenshot,
    { name = `${chartTitle}`, extension = "jpg" } = {}
  ) => {
    const a = document.createElement("a");
    a.href = screenshot;
    a.download = createFileName(extension, name);
    a.click();
  };
 

  return (
    <Popover content={"Download JPG"} placement="topLeft">
    <DownloadOutlined
      onClick={jpgDownload}
      style={{ marginTop: "10px", marginLeft: "10px"  }}
    />
  </Popover>
  );
};

export default JpgdownloadButton;
