import React from "react";
import { Button, Tabs } from "antd";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import RealTimeMonitoring from "./RealTimeMonitoring";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ElectricPerameter from "./ElectricPerameter";

const RealTimeMainTabpage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const items = [
    {
      key: "1",
      label: (
        <div
          style={{ fontSize: "13px", fontWeight: "bold", }}
        >
          Operational Parameter
        </div>
      ),
      children: <RealTimeMonitoring device={location?.state?.states?.DEVICE_ID} />,
    },
    {
      key: "2",
      label: (
        <div
          style={{ fontSize: "13px", fontWeight: "bold",}}
        >
          Electrical Parameter
        </div>
      ),
      children: <ElectricPerameter device={location?.state?.states?.DEVICE_ID} />,
    },
  ];

  const OperationsSlot = {
    left: (
      <Button
        style={{
          borderRadius: "50%",
          width: "35px",
           marginLeft: "15px",
          marginRight: "10px",
        }}
      >
        <ArrowLeftOutlined
          onClick={() => {
            location?.state?.itemNo === 1
              ? navigate("/dashboard")
              : navigate("/real-time-monitoring");
          }}
          style={{ fontSize: "18px", fontWeight: "bolder", marginLeft: "-7px" }}
        />
      </Button>
    ),
    right: (
      <div
        className="fontStyle"
        // style={{ marginRight: "20px", marginTop: "20px" }}
      >{location?.state?.states?.DEVICE_NAME} - (ID:
        {location?.state?.states?.DEVICE_ID})
        
      </div>
    ),
  };
  return (
    <div style={{height:"5vh"}}>
      <Tabs
        tabBarExtraContent={OperationsSlot}
        defaultActiveKey="1"
        items={items}
      />
    </div>
  );
};

export default RealTimeMainTabpage;
