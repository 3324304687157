import React, { useEffect, useState } from "react";
import "./Styles.css";
import axios from "axios";
import axiosRetry from "axios-retry";
import { countryList } from "../../common/CountryList";
import { baseUrl, onboardingBaseUrl } from "../../common/BaseUrl";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  notification,
  Button,
  Table,
  Modal,
  Switch,
  Form,
  Input,
  Select,
  Col,
  Row,
  Pagination,
  Spin
} from "antd";
import UserPreviewModal from "./UserPreviewModal";

const UserControl = () => {
  const accessToken = window.localStorage.getItem("accessToken");
  const userEmail = window.localStorage.getItem("userEmail");
  const [form] = Form.useForm();
  const { Option } = Select;
  const [open, setOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewData, setPreviewData] = useState();
  const [userData, setUserData] = useState();
  const [paginationCount, setPagiationCount] = useState();
  const [deleteUser, setDeleteUser] = useState();
  const [userTableLoad, setUserTableLoad] = useState(false);
  const [editUser, setEditUser] = useState();
  const [selectCountry, setSelectCountry] = useState("disabled");
  // const [tableHeight, setTableHeight] = useState();
  const [loading, setLoading] = useState(true);
  const [showTenant, setShowTenant] = useState(false);
  const [showPerant, setShowPerant] = useState(false);
  const [editUserManagement, setEditUserManagement] = useState();
  const [popUpShow, setPopUpShow] = useState(false);
  const [selectParent, setSelectParent] = useState();
  const [pageNo, setPageNo] = useState();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [pageSize, setPageSize] = useState();
  const [userRole, setUserRole] = useState();
  const [parentAllData, setParentAllData] = useState();
  const [parentTenantAPi, setParentTenantAPi] = useState(false);
  const [selectValues, setSelectValues] = useState({
    country: "",
    city: "",
  });

  const [newTableHeight, setNewTableHeight] = useState();
  const divHeight = Number(window.localStorage.getItem("SpaceHeight"));
  useEffect(() => {
    setNewTableHeight(divHeight - 130);
  }, [divHeight]);
  const isAdmin =
    userRole === "admin" || form.getFieldValue("role") === "admin";
  const isGlobalExecutive =
    userRole === "global-executive" ||
    form.getFieldValue("role") === "global-executive";
  const isCountryExecutive =
    userRole === "country-executive" ||
    form.getFieldValue("role") === "country-executive";

  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "country") {
      form.setFieldsValue({ City: undefined });
    }
    if (formFieldName === "parent_id") {
      setShowPerant(true);
      form.setFieldsValue({ tenant_id: undefined });
    }
    if (formFieldName === "tenant_id") {
      setShowTenant(true);
    }
  };
  useEffect(() => {
    axios
      .post(
        `${onboardingBaseUrl}/parentDetailsList`,
        {},
        {
          headers: { ["x-access-token"]: accessToken },
        }
      )
      .then((response) => {
        if (response?.status === 200) {
          setParentAllData(response?.data);
        }
      });

    axiosRetry(axios, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      },
      retryCondition: (error) => error.response.status === 504,
    });
  }, [parentTenantAPi]);

  useEffect(() => {
    if (editUserManagement) {
      const matchingParent = parentAllData.find(
        (item) => item.PARENT_ID === editUserManagement?.["custom:parent_id"]
      )?.PARENT_NAME;
      const matchingTenant = parentAllData
        .filter(
          (item) => item.PARENT_ID === editUserManagement?.["custom:parent_id"]
        )
        .map((tenant) =>
          tenant?.TENANTS?.find(
            (item) =>
              item.TENANT_ID === editUserManagement?.["custom:tenant_id"]
          )
        )?.[0]?.TENANT_NAME;

      if (editUserManagement) {
        setSelectCountry(editUserManagement?.["custom:country"]);
      }
      form.setFieldsValue({
        role: editUserManagement?.role,
        parent_id: matchingParent,
        tenant_id: matchingTenant,
        name: editUserManagement?.["name"],
        company_name: editUserManagement?.["custom:company_name"],
        email: editUserManagement?.["email"],
        country: editUserManagement?.["custom:country"],
        City: editUserManagement?.["custom:city"],
        phone_number: editUserManagement?.["phone_number"],
      });
    }
  }, [editUserManagement]);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "100px",
    },
    {
      title: "Role",
      dataIndex: "role",
      width: "150px",
      render: (role) => {
        if (role === "global-executive") {
          return <>Global Executive</>;
        } else if (role === "country-executive") {
          return <>Country Executive</>;
        } else if (role === "city-executive") {
          return <>City Executive</>;
        } else if (role === "area-executive") {
          return <>Area Executive</>;
        } else if (role === "store-incharge") {
          return <>Building Incharge</>;
        } else if (role === "operator") {
          return <>Maintenance Engineer</>;
        } else if (role === "admin") {
          return <>Admin</>;
        }
      },
    },
    {
      title: "Company Name",
      dataIndex: "custom:company_name",
      align: "center",
      width: "130px",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "150px",
    },
    {
      title: "Country",
      dataIndex: "custom:country",
      align: "center",
      width: "90px",
      render: (COUNTRY) => {
        if (COUNTRY) {
          return <div>{COUNTRY.toUpperCase()}</div>;
        } else {
          return <div>-</div>;
        }
      },
    },
    {
      title: "City",
      dataIndex: "custom:city",
      align: "center",
      width: "100px",
      render: (CITY) => {
        if (CITY) {
          return <div>{CITY.toUpperCase()}</div>;
        } else {
          return <div>-</div>;
        }
      },
    },
    {
      title: "Location",
      dataIndex: "custom:area",
      align: "center",
      width: "90px",
      render: (LOCATION) => {
        if (LOCATION) {
          return <div>{LOCATION.toUpperCase()}</div>;
        } else {
          return <div>-</div>;
        }
      },
    },
    {
      title: "Phone No.",
      dataIndex: "phone_number",
      align: "center",
      width: "130px",
    },
    {
      title: "Action",
      dataIndex: "Enabled",
      width: "100px",
      align: "center",
      render: (text, record, index) => (
        <Switch
          key={index}
          disabled={record?.email === userEmail?.toUpperCase() ? true : false}
          checkedChildren="Enable"
          unCheckedChildren="Disable"
          checked={record?.Enabled}
          onChange={() => setEditUser(record)}
        />
      ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      width: "60px",
      align: "center",
      render: (text, record, index) => (
        <div key={index} style={{ textAlign: "center" }}>
          <Button
            onClick={() => {
              setParentTenantAPi(!parentTenantAPi);
              setOpen(true);
              setShowTenant(false);
              setShowPerant(false);
              setEditUserManagement(record);
            }}
            style={{ borderRadius: "10px" }}
          >
            <EditOutlined className="configurationEditIcon" />
          </Button>
        </div>
      ),
    },
    {
      title: "Delete",
      dataIndex: "action",
      width: "60px",
      align: "center",
      render: (text, record, index) => (
        <div key={index} style={{ textAlign: "center" }}>
          <Button
            disabled={record?.email === userEmail?.toUpperCase() ? true : false}
            onClick={() => {
              setDeleteUser(record);
              setOpenConfirmDelete(true);
            }}
            style={{ borderRadius: "10px" }}
          >
            <DeleteOutlined className="configurationEditIcon" />
          </Button>
        </div>
      ),
    },
    {
      title: "Preview",
      dataIndex: "action",
      width: "70px",
      align: "center",
      render: (text, record, index) => (
        <div key={index} style={{ textAlign: "center" }}>
          <EyeOutlined
            className="configurationEditIcon"
            onClick={() => {
              setPreviewData(record);
              setPreviewOpen(true);
            }}
          />
        </div>
      ),
    },
  ];
  const ConfirmeModal = () => {
    if (deleteUser) {
      setLoading(true);
      axios
        .post(
          `${baseUrl}/changeUserStatus`,
          {
            action: "delete",
            username: deleteUser?.email,
          },
          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((Response) => {
          setUserTableLoad(!userTableLoad);
          setLoading(false);
          setOpenConfirmDelete(false);
          if (Response?.data?.data?.message?.status === true) {
            notification.success({
              description: "Deleted",
            });
          }
        });
    }
  };

  const onFinish = (values) => {
    if (editUserManagement && popUpShow === true) {
      {
        const formdata = form.getFieldsValue();
        const editParams = {
          name: formdata?.name,
          company_name: formdata?.company_name,
          phone_number: formdata?.phone_number,
          email: formdata?.email,
          parent_id: showPerant
            ? formdata?.parent_id
            : editUserManagement?.["custom:parent_id"],
          tenant_id: showTenant
            ? formdata?.tenant_id
            : editUserManagement?.["custom:tenant_id"],
          country:
            formdata?.role === "global-executive"
              ? undefined
              : formdata?.country,
          city:
            formdata?.role === "global-executive"
              ? undefined
              : formdata?.role === "country-executive"
              ? undefined
              : formdata?.City,
          role: formdata?.role,
          password: formdata?.password,
        };
        setLoading(true);
        axios
          .post(`${baseUrl}/resetUser`, editParams, {
            headers: { ["x-access-token"]: accessToken },
          })
          .then(() => {
            setUserTableLoad(!userTableLoad);
            setLoading(false);
            setOpen(false);
            form.resetFields();
            notification.success({
              description: "User Edited Successfully",
            });
          });
      }
    } else if (editUserManagement) {
      setLoading(true);
      const formdata = form.getFieldsValue();
      const editRollParams = {
        name: formdata?.name,
        phone_number: formdata?.phone_number,
        email: formdata?.email,
        userId: formdata?.userId,
        role: formdata?.role,
        ["custom:parent_id"]:
          showPerant === true
            ? formdata?.parent_id
            : editUserManagement?.["custom:parent_id"],
        ["custom:tenant_id"]:
          showTenant === true
            ? formdata?.tenant_id
            : editUserManagement?.["custom:tenant_id"],
        ["custom:company_name"]: formdata?.company_name,
        ["custom:country"]:
          formdata?.role === "global-executive" ? undefined : formdata?.country,
        ["custom:city"]:
          formdata?.role === "global-executive"
            ? undefined
            : formdata?.role === "country-executive"
            ? undefined
            : formdata?.City,
      };
      axios
        .post(`${baseUrl}/changeInfo`, editRollParams, {
          headers: { ["x-access-token"]: accessToken },
        })
        .then(() => {
          setUserTableLoad(!userTableLoad);
          setLoading(false);
          setOpen(false);
          form.resetFields();
          notification.success({
            description: "User Edited Successfully",
          });
        });
    } else {
      setLoading(true);
      axios
        .post(
          `${baseUrl}/register`,

          {
            parent_id: values?.parent_id,
            tenant_id: values?.tenant_id,
            name: values?.name,
            company_name: values?.company_name,
            phone_number: values?.phone_number,
            email: values?.email,
            userId: values?.userId,
            role: values?.role,
            password: values?.password,
            country: values?.country,
            city: values?.City,
            store_id: values?.store_id,
          },
          { headers: { ["x-access-token"]: accessToken } }
        )
        .then(() => {
          setUserTableLoad(!userTableLoad);
          setLoading(false);
          setOpen(false);
          form.resetFields();
          if (Response?.data?.code === 200) {
            notification.success({
              description: "User Added Successfully",
            });
          }
        });
    }
    form.resetFields();
    setPopUpShow(false);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .post(
        `${baseUrl}/userList`,
        {
          page: pageNo ? pageNo : 1,
          limit: pageSize ? pageSize : 10,
        },

        {
          headers: { ["x-access-token"]: accessToken },
        }
      )
      .then((response) => {
        setUserData(response?.data?.data?.userList);
        setPagiationCount(response?.data?.data?.count);

        setLoading(false);
      });
  }, [userTableLoad, pageSize, pageNo]);
  useEffect(() => {
    if (editUser) {
      setLoading(true);
      axios
        .post(
          `${baseUrl}/UserAction`,
          {
            email: editUser?.email,
            status: editUser?.Enabled == true ? false : true,
          },
          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((Response) => {
          setUserTableLoad(!userTableLoad);
          setLoading(false);
          if (Response?.data?.code === 200) {
            notification.success({
              description: Response?.data?.data,
            });
          }
        });
    }
  }, [editUser]);

  return (
    <div>
    <div   style={{display:"flex", justifyContent:"right"}}>
    <Button
    onClick={() => {
      setOpen(true);
      setPopUpShow(true);
      setParentTenantAPi(!parentTenantAPi);
    }}
    type="primary"
    style={{ color: "white", margin: "8px"}}
  >
    Create New User
  </Button>
  </div>
     
      <Spin spinning={loading}>
        <Table
          className="MainTable"
          columns={columns}
          dataSource={userData}
          size="small"
          pagination={false}
          scroll={{ y: newTableHeight }}
          rowKey={(record) => record?.sub}
        />
        <div style={{ float: "right" }}>
          <Pagination
            defaultCurrent={1}
            total={paginationCount}
            onChange={(page, pageSize) => {
              setPageNo(page);
              setPageSize(pageSize);
            }}
          />
        </div>
      </Spin>

      <Modal
        destroyOnClose={true}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => {
          setEditUserManagement("");
          form.resetFields();
          setOpen(false);
          setPopUpShow(false);
        }}
        width={900}
        footer={null}
        style={{ backgroundColor: "#f6f6f6" }}
      >
        <h3
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {editUserManagement?.["custom:country"]
            ? "Edit User Details"
            : "User Registration"}
        </h3>
        
        <div style={{ textAlign: "center", color: "red" }}>
          {popUpShow === true && editUserManagement
            ? "This action will remove user from User and Group"
            : ""}
        </div>
        <div style={{ height: "30vw", overflowY: "auto" }}>
          <Form
            form={form}
            onValuesChange={handleFormValuesChange}
            name="Registration"
            onFinish={onFinish}
            style={{ padding: "15px" }}
          >
            <Row gutter={[12, 1]}>
              <Col
                className="regestration"
                xl={{ span: 12 }}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="formInputStyle">
                  <label className="formLabelStyle">Role</label>
                  <Form.Item
                    name="role"
                    rules={[
                      {
                        required: true,
                        message: "Select Role",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Role"
                      onChange={(value) => {
                        setPopUpShow(true);
                        setUserRole(value);
                      }}
                    >
                      <Option value="global-executive">Global Executive</Option>
                      <Option value="country-executive">
                        Country Executive
                      </Option>
                      <Option value="city-executive">City Executive</Option>
                      <Option value="area-executive">Area Executive</Option>
                      <Option value="store-incharge">Building Incharge</Option>
                      <Option value="operator">Maintenance Engineer</Option>
                      <Option value="admin">Admin</Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              {isAdmin || isGlobalExecutive ? (
                <></>
              ) : (
                <Col
                  className="regestration"
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="formInputStyle">
                    <label className="formLabelStyle">Country</label>

                    <Form.Item
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: "Enter Country",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Country"
                        className="ReportModal"
                        onChange={(value) => {
                          setSelectCountry(value);
                          setSelectValues({ ...selectValues, country: value });
                        }}
                        allowClear
                        showSearch
                      >
                        {countryList?.map((country, index) => {
                          return (
                            <Option value={country?.COUNTRY} key={index}>
                              {country?.COUNTRY}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              )}

              {isAdmin || isGlobalExecutive || isCountryExecutive ? (
                <></>
              ) : (
                <Col
                  className="regestration"
                  xl={{ span: 12 }}
                  lg={{ span: 12 }}
                  md={{ span: 24 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                >
                  <div className="formInputStyle">
                    <label className="formLabelStyle">City</label>
                    <Form.Item
                      name="City"
                      rules={[
                        {
                          required: true,
                          message: "Enter City",
                        },
                      ]}
                    >
                      <Select
                        placeholder="City"
                        disabled={selectCountry === "disabled" ? true : false}
                        allowClear
                        showSearch
                        onChange={(value) => {
                          setSelectValues({ ...selectValues, city: value });
                        }}
                      >
                        {countryList
                          ?.filter(
                            (country) => country?.COUNTRY === selectCountry
                          )
                          ?.map((cities) =>
                            cities?.CITIES?.map((city, index) => {
                              return (
                                <Option value={city?.CITY} key={index}>
                                  {city?.CITY}
                                </Option>
                              );
                            })
                          )}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              )}

              <Col
                className="regestration"
                xl={{ span: 12 }}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="formInputStyle">
                  <label className="formLabelStyle">Company Name</label>
                  <Form.Item
                    name="company_name"
                    rules={[
                      {
                        required: true,
                        message: "Enter Company Name",
                      },
                    ]}
                  >
                    <Input className="formInput" />
                  </Form.Item>
                </div>
              </Col>

              <Col
                className="regestration"
                xl={{ span: 12 }}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="formInputStyle">
                  <label className="formLabelStyle">Parent Name</label>
                  <Form.Item
                    name="parent_id"
                    rules={[
                      {
                        required: true,
                        message: "Enter Parent ID",
                      },
                    ]}
                  >
                    <Select
                      disabled={editUserManagement && true}
                      placeholder="Parent Name"
                      className="ReportModal"
                      onChange={(value) => {
                        setSelectParent(value);
                      }}
                    >
                      {parentAllData?.map((parent, index) => {
                        return (
                          <Option value={parent?.PARENT_ID} key={index}>
                            {parent?.PARENT_NAME}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col
                className="regestration"
                xl={{ span: 12 }}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="formInputStyle">
                  <label className="formLabelStyle">Tenant Name</label>
                  <Form.Item
                    name="tenant_id"
                    rules={[
                      {
                        required: true,
                        message: "Enter Tenant ID",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Tenant Name"
                      disabled={
                        !selectParent || editUserManagement ? true : false
                      }
                    >
                      {parentAllData
                        ?.filter((parent) => parent?.PARENT_ID === selectParent)
                        ?.map((alltenants) =>
                          alltenants?.TENANTS?.map((tenant, index) => {
                            return (
                              <Option value={tenant?.TENANT_ID} key={index}>
                                {tenant?.TENANT_NAME}
                              </Option>
                            );
                          })
                        )}
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col
                className="regestration"
                xl={{ span: 12 }}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="formInputStyle">
                  <label className="formLabelStyle">Name</label>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Enter Name",
                      },
                    ]}
                  >
                    <Input className="formInput" />
                  </Form.Item>
                </div>
              </Col>

              <Col
                className="regestration"
                xl={{ span: 12 }}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="formInputStyle">
                  <label className="formLabelStyle">Phone Number</label>
                  <Form.Item
                    name="phone_number"
                    rules={[
                      {
                        required: true,
                        message: "Enter Phone Number",
                      },
                      {
                        pattern: new RegExp(/[+]/),
                        message: "Mobile No. must contain Country Code.",
                      },
                    ]}
                  >
                    <Input
                      className="formInput"
                      // addonBefore={prefixSelector}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col
                className="regestration"
                xl={{ span: 12 }}
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="formInputStyle">
                  <label className="formLabelStyle">Email</label>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        message: "Enter your E-mail!",
                      },
                    ]}
                  >
                    <Input
                      className="formInput"
                      disabled={editUserManagement?.["email"] ? true : false}
                    />
                  </Form.Item>
                </div>
              </Col>
              {popUpShow === true ? (
                <>
                  <Col
                    className="regestration"
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <div className="formInputStyle">
                      <label className="formLabelStyle">Password</label>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                          {
                            min: 8,
                            message:
                              "Password must be at least 8 characters long.",
                          },
                          {
                            pattern: new RegExp(/\d/),
                            message:
                              "Password must contain at least one digit.",
                          },
                          {
                            pattern: new RegExp(/[A-Z]/),
                            message:
                              "Password must contain at least one uppercase letter.",
                          },
                          {
                            pattern: new RegExp(/[a-z]/),
                            message:
                              "Password must contain at least one lowercase letter.",
                          },
                          {
                            // eslint-disable-next-line no-useless-escape
                            pattern: new RegExp(/[!@#\$%\^&\*]/),
                            message:
                              "Password must contain at least one special character.",
                          },
                        ]}
                        // hasFeedback
                      >
                        <Input.Password
                          className="formInput"
                          onCopy={(e) => {
                            e.preventDefault();
                            return false;
                          }}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col
                    className="regestration"
                    xl={{ span: 12 }}
                    lg={{ span: 12 }}
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                  >
                    <div className="formInputStyle">
                      <label className="formLabelStyle">Confirm Password</label>
                      <Form.Item
                        name="reenterpassword"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "The two passwords that you entered do not match!"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password className="formInput" />
                      </Form.Item>
                    </div>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
            <Row
              gutter={24}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <div style={{ display: "flex" }}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ color: "white", marginRight: "10px" }}
                  >
                    Submit
                  </Button>
                </Form.Item>
                {editUserManagement ? (
                  <></>
                ) : (
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => {
                        form.resetFields();
                      }}
                      style={{ color: "white" }}
                    >
                      Reset
                    </Button>
                  </Form.Item>
                )}
              </div>
            </Row>
          </Form>
        </div>
      </Modal>
      <Modal
        centered
        open={previewOpen}
        onOk={() => setPreviewOpen(false)}
        onCancel={() => setPreviewOpen(false)}
        footer={null}
        width={1000}
      >
        <UserPreviewModal previewData={previewData} />
      </Modal>
      <Modal
        open={openConfirmDelete}
        onOk={ConfirmeModal}
        onCancel={() => setOpenConfirmDelete(false)}
        okText="Confirm "
        cancelText="Cancel"
        destroyOnClose
      >
        <div
          style={{ textAlign: "center", fontSize: "80px", color: "#008ecf" }}
        >
          <ExclamationCircleOutlined />
        </div>
        <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
          ARE YOU SURE YOU WANT TO REMOVE THIS USER?
        </h3>
      </Modal>
    </div>
  );
};

export default UserControl;
