/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import { Spin } from "antd";
const VoltageChart = ({ chartData, setElectricalLoader, electricalLoader }) => {
  const [voltageB, setVoltageB] = useState([]);
  const [voltageR, setVoltageR] = useState([]);
  const [voltageY, setVoltageY] = useState([]);
  useEffect(() => {
    setElectricalLoader(true);
    let yaxisDataB = [];
    let yaxisDataR = [];
    let yaxisDataY = [];
    if (chartData) {
      chartData?.map((chart) => {
        yaxisDataR.push([
          chart?.DEVICE_TIME- new Date().getTimezoneOffset() * 60 * 1000 ,
          chart?.BOXDATA?.VOLTAGE_R?.toFixed(2),
        ]);
        yaxisDataY.push([
          chart?.DEVICE_TIME- new Date().getTimezoneOffset() * 60 * 1000 ,
          chart?.BOXDATA?.VOLTAGE_Y?.toFixed(2),
        ]);
        yaxisDataB.push([
          chart?.DEVICE_TIME- new Date().getTimezoneOffset() * 60 * 1000 ,
          chart?.BOXDATA?.VOLTAGE_B?.toFixed(2),
        ]);
      });
      setVoltageR(yaxisDataR);
      setVoltageY(yaxisDataY);
      setVoltageB(yaxisDataB);
    }
    setElectricalLoader(false);
  }, [chartData]);

  useEffect(() => {
    let options = {
      chart: {
        id: "voltage",
        type: "line",
        height: 280,
        toolbar: {
          tools: {
            zoomin: true,
            zoomout: false,
          },
        },
      },
      colors: ["#EE4B2B", "#e1ad01", "#027bc7"],
      stroke: {
        width: 3,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      markers: {
        size: 0,
      },
      series: [
        {
          name: "Voltage_R",
          data: voltageR,
        },
        {
          name: "Voltage_Y",
          data: voltageY,
        },
        {
          name: "Voltage_B",
          data: voltageB,
        },
      ],

      xaxis: {
        type: "datetime",
        tickPlacement: "on",
        datetimeUTC: false,
        title: {
          text: 'Date/Time',
          offsetY: 65,
          style: {
            fontSize: '12px',
            fontWeight: 600,
          },
        },
      },
      yaxis: {
        min: 0,
        tickAmount: 3,
        title: {
          text: 'Voltage(V)',
          rotate: -90,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '12px',
            fontWeight: 600,
          },
        },
        opposite: false,
        position: 'left',
        labels: {
          offsetX: 0,
          offsetY: 0,
        },
      },
    };
    var chart1 = new ApexCharts(document.querySelector("#voltage"), options);

    chart1?.render();
  }, [voltageB]);

  return (
    <>
      {electricalLoader ? (
        <Spin
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "140px",
          }}
        />
      ) : (
        <div id="voltage"></div>
      )}
    </>
  );
};

export default VoltageChart;
