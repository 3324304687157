import moment from "moment";
export const downloadCSV = (data, filename, selectInterval) => {
  let result;
  const csvRows = [];
  let flootTripEnergyHeader =
    "Frequency,Energy(kWh),Floors,Energy(kWh)/Floor,Trips,Energy(kWh)/Trip";
  let flootTripHeader = "Frequency,Floor,Trip";
  let energyHeader = "Frequency,Energy(kWh)";
  let doorHeader = "Frequency,Door";
  let activeHour = "Active Hours, Idle Hours, Total Hours";
  let elevatorSts = "Total, Online, Offline";
  if (filename === "Floor-Trip" || filename === "ReportFloorTRip") {
    csvRows.push(flootTripHeader);
  } else if (filename === "Door-Cycle" || filename === "ReportDoor") {
    csvRows.push(doorHeader);
  } else if (filename === "Energy-Per-Floor-&-Trip") {
    csvRows.push(flootTripEnergyHeader);
  } else if (filename === "Energy-Consumption" || filename === "ReportEnergy") {
    csvRows.push(energyHeader);
  } else if (
    filename === "Elevator-Status" ||
    filename === "ReportActiveHour"
  ) {
    csvRows.push(activeHour);
  } else if (filename === "ReportEleStatus") {
    csvRows.push(elevatorSts);
  }

  const getSundayFromWeekNum = (weekNum, year) => {
    var sunday = new Date(year, 0, 1 + weekNum * 7 - 7);
    if (sunday.getDay() !== 0) {
      sunday.setDate(sunday.getDate() - 1);
    }
    return sunday?.toLocaleDateString("zh-Hans-CN");
  };
  if (filename === "Elevator-Status" || filename === "ReportActiveHour") {
    const realtimeconverter = (hourMin) => {
      const hours = Math.floor(hourMin);
      const minutes = Math.round((hourMin - hours) * 60);
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedTime = `${hours}:${formattedMinutes}`;
      return formattedTime;
    };
    const total = realtimeconverter(data?.active + data?.idle);
    const active = realtimeconverter(data?.active);
    const idle = realtimeconverter(data?.idle);
    csvRows.push([active, idle, total]);
  } else if (filename === "ReportEleStatus") {
    csvRows.push([
      data?.active?.value + data?.offline?.value,
      data?.active?.value,
      data?.offline?.value,
    ]);
  } else if (
    filename === "Power_Demand" ||
    filename === "Voltage" ||
    filename === "Current" ||
    filename === "PF" ||
    filename === "V-THD" ||
    filename === "Reactive Power"
  ) {
    if (filename === "Power_Demand") {
      csvRows.push("DATE,TIME,POWER_R(W),POWER_Y(W),POWER_B(W)");
    } else if (filename === "Voltage") {
      csvRows.push("DATE,TIME,VOLTAGE_R(V),VOLTAGE_Y(V),VOLTAGE_B(V)");
    } else if (filename === "Current") {
      csvRows.push("DATE,TIME,CURRENT_R(A),CURRENT_Y(A),CURRENT_B(A)");
    } else if (filename === "PF") {
      csvRows.push("DATE,TIME,PF_R,PF_Y,PF_B");
    } else if (filename === "V-THD") {
      csvRows.push("DATE,TIME,V-THD_R,V-THD_Y,V-THD_B");
    } else if (filename === "Reactive Power") {
      csvRows.push(
        "DATE,TIME,REACTIVE POWER_R(VAR),REACTIVE POWER_Y(VAR),REACTIVE POWER_B(VAR)"
      );
    }
    for (let i = 0; i < data?.length; i++) {
      const timeStamp = data[i]?.DEVICE_TIME / 1000;
      const date = new Date(timeStamp * 1000);
      const formattedTime = date.toLocaleTimeString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      });
      const frequency = formattedTime;

      const VOLTAGE_R = data[i]?.BOXDATA?.VOLTAGE_R;
      const VOLTAGE_Y = data[i]?.BOXDATA?.VOLTAGE_Y;
      const VOLTAGE_B = data[i]?.BOXDATA?.VOLTAGE_B;

      const CURRENT_R = data[i]?.BOXDATA?.CURRENT_R;
      const CURRENT_Y = data[i]?.BOXDATA?.CURRENT_Y;
      const CURRENT_B = data[i]?.BOXDATA?.CURRENT_B;

      const POWERDEMAND_R = data[i]?.BOXDATA?.POWERDEMAND_R;
      const POWERDEMAND_Y = data[i]?.BOXDATA?.POWERDEMAND_Y;
      const POWERDEMAND_B = data[i]?.BOXDATA?.POWERDEMAND_B;

      const PF_R = data[i]?.BOXDATA?.PF_R;
      const PF_Y = data[i]?.BOXDATA?.PF_Y;
      const PF_B = data[i]?.BOXDATA?.PF_B;

      const VTHD_R = data[i]?.BOXDATA?.VTHD_R;
      const VTHD_Y = data[i]?.BOXDATA?.VTHD_Y;
      const VTHD_B = data[i]?.BOXDATA?.VTHD_B;

      const RP_R = data[i]?.BOXDATA?.RP_R;
      const RP_Y = data[i]?.BOXDATA?.RP_Y;
      const RP_B = data[i]?.BOXDATA?.RP_B;

      if (filename === "Power_Demand") {
        csvRows.push([frequency, POWERDEMAND_R, POWERDEMAND_Y, POWERDEMAND_B]);
      } else if (filename === "Voltage") {
        csvRows.push([frequency, VOLTAGE_R, VOLTAGE_Y, VOLTAGE_B]);
      } else if (filename === "Current") {
        csvRows.push([frequency, CURRENT_R, CURRENT_Y, CURRENT_B]);
      } else if (filename === "PF") {
        csvRows.push([frequency, PF_R, PF_Y, PF_B]);
      } else if (filename === "V-THD") {
        csvRows.push([frequency, VTHD_R, VTHD_Y, VTHD_B]);
      } else if (filename === "Reactive Power") {
        csvRows.push([frequency, RP_R, RP_Y, RP_B]);
      }
    }
  } else if (
    filename === "ReportFloorTRip" ||
    filename === "ReportDoor" ||
    filename === "ReportEnergy"
  ) {
    if (selectInterval === 1) {
      for (let hour = 0; hour < 24; hour++) {
        let floorDetail = data?.floorDetails?.[hour]
          ? data?.floorDetails?.[hour]
          : 0;
        let tripDetail = data?.tripDetails?.[hour]
          ? data?.tripDetails?.[hour]
          : 0;
        let doorDetails = data[hour] ? data[hour] : 0;
        let energyDetails = data[hour] ? data[hour] : 0;

        if (filename === "ReportFloorTRip") {
          csvRows.push([hour, floorDetail, tripDetail]);
        } else if (filename === "ReportDoor") {
          csvRows.push([hour, doorDetails]);
        } else if (filename === "ReportEnergy") {
          csvRows.push([hour, energyDetails]);
        }
      }
    } else if (selectInterval < 30 && selectInterval > 1) {
      let dates;
      if (filename === "ReportFloorTRip") {
        dates = Object.keys(data.floorDetails);
      } else {
        dates = Object.keys(data);
      }

      for (const date of dates) {
        const formattedDate = date?.split("T")[0];
        const floorDetail = data?.floorDetails?.[date] || 0;
        const tripDetail = data?.tripDetails?.[date] || 0;
        const doorDetails = data[date] || 0;
        const energyDetails = data[date] || 0;
        if (filename === "ReportFloorTRip") {
          csvRows.push([formattedDate, floorDetail, tripDetail]);
        } else if (filename === "ReportDoor") {
          csvRows.push([formattedDate, doorDetails]);
        } else if (filename === "ReportEnergy") {
          csvRows.push([formattedDate, energyDetails]);
        }
      }
    } else if (selectInterval >= 30) {
      let dates;
      if (filename === "ReportFloorTRip") {
        dates = Object.keys(data.floorDetails);
      } else {
        dates = Object.keys(data);
      }

      for (const week of dates) {
        let weekno = data?.floorDetails
          ? data?.floorDetails[week]?.["WEEk"]
          : data?.[week]?.["WEEk"];
        let monthno = data?.floorDetails
          ? data?.floorDetails[week]?.["MONTH"]
          : data?.[week]?.["MONTH"];
        let yearno = data?.floorDetails
          ? data?.floorDetails[week]?.["YEAR"]
          : data?.[week]?.["YEAR"];
        const sundayDate = getSundayFromWeekNum(weekno, yearno);
        const monthDate = `${moment()
          .month(monthno - 1)
          .format("MMM")} ${yearno}`;
        const date = selectInterval >= 90 ? monthDate : sundayDate;
        const floorDetail = data?.floorDetails?.[week]?.["value"] || 0;
        const tripDetail = data?.tripDetails?.[week]?.["value"] || 0;
        const doorDetails = data?.[week]?.["value"] || 0;
        const energyDetails = data?.[week]?.["value"] || 0;
        if (filename === "ReportFloorTRip") {
          csvRows.push([date, floorDetail, tripDetail]);
        } else if (filename === "ReportDoor") {
          csvRows.push([date, doorDetails]);
        } else if (filename === "ReportEnergy") {
          csvRows.push([date, energyDetails]);
        }
      }
    }
  } else {
    if (
      // eslint-disable-next-line no-prototype-builtins
      data.hasOwnProperty("floorDetails") &&
      Array.isArray(data.floorDetails)
    ) {
      result = data.floorDetails;
    } else {
      result = data;
    }
    if (selectInterval === 1) {
      if (data?.floorDetails?.length !== 0) {
        let energyData =
          data?.energyData && data?.energyData?.[0]?.ENERGY?.HOURLY;
        let floorData =
          data?.floorDetails && data?.floorDetails?.[0]?.FLOOR?.HOURLY;
        let tripData = data?.tripDetails && data?.tripDetails?.[0].TRIP?.HOURLY;
        let energyGraphData = data?.[0]?.ENERGY?.HOURLY;
        let doorGraphData = data?.[0]?.DOOR?.HOURLY;
        for (let hour = 0; hour < 24; hour++) {
          let energyValue = energyData
            ? energyData?.[hour]?.TOTAL_HOURLY_ENERGY
            : 0;
          let floorValue = floorData?.[hour]?.TOTAL_HOURLY_FLOOR
            ? floorData[hour]?.TOTAL_HOURLY_FLOOR
            : 0;
          let tripValue = tripData?.[hour]?.TOTAL_HOURLY_TRIP
            ? tripData[hour]?.TOTAL_HOURLY_TRIP
            : 0;
          let energyGraphValue = energyGraphData
            ? energyGraphData?.[hour]?.TOTAL_HOURLY_ENERGY * 1000
            : 0; //energy graphvalue
          let doorGraphValue = doorGraphData?.[hour]
            ? doorGraphData?.[hour]?.TOTAL_HOURLY_DOOR
            : 0; //energy graphvalue
          let EnergytripValue =
            tripData?.[hour] && energyData?.[hour]
              ? (energyData?.[hour]?.TOTAL_HOURLY_ENERGY /
                  tripData[hour].TOTAL_HOURLY_TRIP) *
                1000
              : 0;
          let EnergyFloorValue =
            floorData?.[hour] && energyData?.[hour]
              ? (energyData?.[hour]?.TOTAL_HOURLY_ENERGY /
                  floorData[hour].TOTAL_HOURLY_FLOOR) *
                1000
              : 0;
          if (filename === "Floor-Trip") {
            csvRows.push([hour, floorValue, tripValue]);
          } else if (filename === "Door-Cycle") {
            csvRows.push([hour, doorGraphValue]);
          } else if (filename === "Energy-Per-Floor-&-Trip") {
            csvRows.push([
              hour,
              energyValue,
              floorValue,
              EnergyFloorValue?.toFixed(2),
              tripValue,
              EnergytripValue?.toFixed(2),
            ]);
          } else if (filename === "Energy-Consumption") {
            csvRows.push([hour, energyGraphValue]);
          }
        }
      }
    } else if (selectInterval < 30 && selectInterval > 1) {
      if (result?.length !== 0) {
        for (let i = 0; i < result?.length; i++) {
          const energyDetail =
            data?.energyData?.[i]?.ENERGY?.TOTAL_DAY_ENERGY * 1000;
          const floorDetail = data?.floorDetails?.[i]?.FLOOR?.TOTAL_DAY_FLOORS;
          const tripDetail = data?.tripDetails?.[i]?.TRIP?.TOTAL_DAY_TRIP;
          const deviceTime = result?.[i]?.DEVICE_TIME?.split("T")[0];
          const energyGraphValue = data?.[i]?.ENERGY?.TOTAL_DAY_ENERGY * 1000;
          const doorDetail = data?.[i]?.DOOR?.TOTAL_DAY_DOOR;
          if (filename === "Floor-Trip") {
            csvRows.push([deviceTime, floorDetail, tripDetail]);
          } else if (filename === "Door-Cycle") {
            csvRows.push([deviceTime, doorDetail]);
          } else if (filename === "Energy-Per-Floor-&-Trip") {
            csvRows.push(
              `${deviceTime},${energyDetail},${floorDetail},${
                energyDetail / floorDetail
              },${tripDetail},${energyDetail / tripDetail}`
            );
          } else if (filename === "Energy-Consumption") {
            csvRows.push([deviceTime, energyGraphValue]);
          }
        }
      }
    } else if (selectInterval < 90 && selectInterval >= 30) {
      if (result?.length !== 0) {
        for (let i = 0; i < result.length; i++) {
          const floorDetail = data?.floorDetails?.[i]?.FLOOR;
          const tripDetail = data?.tripDetails?.[i]?.TRIP;
          const energyDetail = data?.energyData?.[i]?.ENERGY * 1000;
          const doorDetail = data?.[i]?.DOOR;
          const energyValue = data?.[i]?.ENERGY * 1000;
          const year = result[i]?.YEAR;
          const week = result[i]?.WEEK;
          const sundayDate = getSundayFromWeekNum(week, year);

          if (filename === "Floor-Trip") {
            csvRows.push([sundayDate, floorDetail, tripDetail]);
          } else if (filename === "Door-Cycle") {
            csvRows.push([sundayDate, doorDetail]);
          } else if (filename === "Energy-Per-Floor-&-Trip") {
            csvRows.push(
              `${sundayDate},${energyDetail},${floorDetail},${
                energyDetail / floorDetail
              },${tripDetail},${energyDetail / tripDetail}`
            );
          } else if (filename === "Energy-Consumption") {
            csvRows.push([sundayDate, energyValue]);
          }
        }
      }
    } else if (selectInterval >= 90) {
      if (result?.length !== 0) {
        for (let i = 0; i < result?.length; i++) {
          let month = `${moment()
            .month(result[i]?.MONTH - 1)
            .format("MMM")} ${result?.[i]?.YEAR}`;
          let floor = data?.floorDetails?.[i]?.FLOOR;
          let trip = data?.tripDetails?.[i]?.TRIP;
          let energy = data?.energyData?.[i]?.ENERGY;
          let energygraph = data[i]?.ENERGY;
          let door = data[i]?.DOOR;
          if (filename === "Floor-Trip") {
            csvRows.push([month, floor, trip]);
          } else if (filename === "Door-Cycle") {
            csvRows.push([month, door]);
          } else if (filename === "Energy-Per-Floor-&-Trip") {
            csvRows.push(
              `${month},${energy},${floor},${energy / floor},${trip},${
                energy / trip
              }`
            );
          } else if (filename === "Energy-Consumption") {
            csvRows.push([month, energygraph]);
          }
        }
      }
    }
  }

  const csvString = csvRows.join("\n");
  const blob = new Blob([csvString], { type: "text/csv" });

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${filename}.csv`;
  link.click();
};
