import React, { useEffect, useState ,createRef} from "react";
import { Card, DatePicker, Popover } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import { RTMBaseUrl } from "../../common/BaseUrl";
import PowerDemandChart from "./elevatorDetails/Charts/electricalParameters/PowerDemandChart";
import VoltageChart from "./elevatorDetails/Charts/electricalParameters/VoltageChart";
import CurrentChart from "./elevatorDetails/Charts/electricalParameters/CurrentChart";
import PFChart from "./elevatorDetails/Charts/electricalParameters/PFChart";
import VtChart from "./elevatorDetails/Charts/electricalParameters/VtChart";
import ReactivePowerChart from "./elevatorDetails/Charts/electricalParameters/ReactivePowerChart";
import CsvButton from "../../services/csvConverter/CsvButton";
import JpgdownloadButton from "../../services/JpgConverter/JpgdownloadButton";
const ElectricPerameter = (device) => {
  const PowerDemandRef = createRef();
  const VoltageRef = createRef();
  const CurrentRef = createRef();
  const PFRef = createRef();
  const VTHDRef = createRef();
  const ReactivePowerRef = createRef();
  const [value, setValue] = useState();
  const [electricalData, setElectricalData] = useState();
  const [selectInterval, setSelectInterval] = useState(1);
  const [selectedDates, setSelectedDates] = useState();
  const [electricalLoader, setElectricalLoader] = useState(false);
  const [Electricdates, setElectricdates] = useState(null);
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const accessToken = window.localStorage.getItem("accessToken");
  const currentT = moment().format("HH:mm:ss");
  const divHeight = window.localStorage.getItem("SpaceHeight");
  const [newdivHeight, setNewdivHeight] = useState();
  useEffect(() => {
    setNewdivHeight(divHeight - 150);
  }, [divHeight]);
  const divstyles = {
    RTMDivColumn: {
      height: `${newdivHeight}px`,
      overflowY: "auto",
      backgroundColor: "white",
    },
  };

  const { RangePicker } = DatePicker;
  let today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;
  const disabledDate = (current) => {
    if (!Electricdates) {
      return false;
    }
    const tooLate =
      Electricdates[0] && current.diff(Electricdates[0], "days") > 7;
    const tooEarly =
      Electricdates[1] && Electricdates[1].diff(current, "days") > 7;
    return !!tooEarly || !!tooLate;
  };
  const onOpenChange = (open) => {
    if (open) {
      setElectricdates([null, null]);
    } else {
      setElectricdates(null);
    }
  };

  useEffect(() => {
    setElectricalLoader(true);
    const params = {
      from: selectedDates
        ? new Date(`${selectedDates?.[0]}T${currentT}`).getTime()
        : new Date(`${moment().format("YYYY-MM-DD")}T${currentT}`).getTime(),
      to:
        new Date(`${selectedDates?.[1]}T${currentT}`).getTime() ||
        new Date(`${moment().format("YYYY-MM-DD")}T${currentT}`).getTime(),
      PARENT_ID: userProfile?.["custom:parent_id"],
      TENANT_ID: userProfile?.["custom:tenant_id"],
      DEVICE_ID: device?.device,
    };

    axios
      .post(
        `${RTMBaseUrl}/deviceMsg`, params, 
        {
        headers: { ["x-access-token"]: accessToken, ["x-offset"]: -(new Date()).getTimezoneOffset() },
      })
      .then((response) => {
        setElectricalData(response?.data?.data?.MINUTES);
      });
  }, [selectInterval, selectedDates]);
  return (
    <div >
      <Card style={{ backgroundColor: "#a0d7ef" }}>
        <div style={{ float: "right" }}>
          <RangePicker
            value={Electricdates || value || [moment(), moment()]}
            disabledDate={disabledDate}
            onCalendarChange={(val) => setElectricdates(val)}
            onOpenChange={onOpenChange}
            onChange={(val, timeString) => {
              setValue(val);
              setSelectedDates(timeString);
              setSelectInterval(
                Math.abs(
                  (new Date(timeString?.[0]).getTime() -
                    new Date(timeString?.[1]).getTime()) /
                    (24 * 60 * 60 * 1000)
                ) + 1
              );
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
            marginTop: "5px",
            width: "300px",
          }}
        >
          <Popover
            content={<>Time shown is as per user’s time zone</>}
            trigger="hover"
          >
            <ExclamationCircleOutlined />
          </Popover>
          {/* <DownloadOutlined
                    onClick={(e) => handleDownload()}
                    style={{ margin: "0px 14px" }}
                  /> */}
          <div style={{ margin: "0px 4px" }}>Last Updated : {dateTime}</div>
        </div>
      </Card>
      <div style={divstyles.RTMDivColumn}>
      <Card style={{ margin: "10px 5px" }}  ref={PowerDemandRef}>
      <div className="fontStyle" style={{display:"flex", justifyContent:"space-between"}}>
      <span>Power Demand</span>
      <span style={{marginRight:"10px"}}>
      <CsvButton data={electricalData} selectInterval={selectInterval} filename="Power_Demand"  />
      <JpgdownloadButton data={PowerDemandRef}  filename="Power Demand"  />
      </span>
      
      </div>
        <PowerDemandChart
        
          chartData={electricalData}
          setElectricalLoader={setElectricalLoader}
          electricalLoader={electricalLoader}
        />
      </Card>
      <Card style={{ margin: "10px 5px" }} ref={VoltageRef}>
      <div className="fontStyle" style={{display:"flex", justifyContent:"space-between"}}>
      <span>Voltage</span>
      <span style={{marginRight:"10px"}}>
      <CsvButton data={electricalData} selectInterval={selectInterval} filename="Voltage"  />
      <JpgdownloadButton data={VoltageRef}  filename="Voltage"  />
      </span>
      </div>
        <VoltageChart
          chartData={electricalData}
          setElectricalLoader={setElectricalLoader}
          electricalLoader={electricalLoader}
        />
      </Card>
      <Card style={{ margin: "10px 5px" }} ref={CurrentRef}>
      <div className="fontStyle" style={{display:"flex", justifyContent:"space-between"}}>
      <span>Current</span>
      <span style={{marginRight:"10px"}}>
      <CsvButton data={electricalData} selectInterval={selectInterval} filename="Current"  />
      <JpgdownloadButton data={CurrentRef}  filename="Current"  />
      </span>
      </div>
        <CurrentChart
          chartData={electricalData}
          setElectricalLoader={setElectricalLoader}
          electricalLoader={electricalLoader}
        />
      </Card>
      <Card style={{ margin: "10px 5px" }} ref={PFRef}>
      <div className="fontStyle" style={{display:"flex", justifyContent:"space-between"}}>
      <span>PF</span>
      <span style={{marginRight:"10px"}}>
      <CsvButton data={electricalData} selectInterval={selectInterval} filename="PF"  />
      <JpgdownloadButton data={PFRef}  filename="PF"  />
      </span>
      </div>
        <PFChart
          chartData={electricalData}
          setElectricalLoader={setElectricalLoader}
          electricalLoader={electricalLoader}
        />
      </Card>
      <Card style={{ margin: "10px 5px" }} ref={VTHDRef}>
      <div className="fontStyle" style={{display:"flex", justifyContent:"space-between"}}>
      <span>V-THD</span>
      <span style={{marginRight:"10px"}}>
      <CsvButton data={electricalData} selectInterval={selectInterval} filename="V-THD"  />
      <JpgdownloadButton data={VTHDRef}  filename="V-THD"  />
      </span>
      </div>
        <VtChart
          chartData={electricalData}
          setElectricalLoader={setElectricalLoader}
          electricalLoader={electricalLoader}
        />
      </Card>
      <Card style={{ margin: "10px 5px" }} ref={ReactivePowerRef}>
      <div className="fontStyle" style={{display:"flex", justifyContent:"space-between"}}>
      <span>Reactive Power</span>
      <span style={{marginRight:"10px"}}>
      <CsvButton data={electricalData} selectInterval={selectInterval} filename="Reactive Power"  />
      <JpgdownloadButton data={ReactivePowerRef}  filename="Reactive Power"  />
      </span>
      </div>
        <ReactivePowerChart
          chartData={electricalData}
          setElectricalLoader={setElectricalLoader}
          electricalLoader={electricalLoader}
        />
      </Card>
      </div>
    </div>
  );
};

export default ElectricPerameter;
