import React, { useEffect, useState ,createRef} from "react";
import {
  Card,
  Col,
  Popover,
  Row,
  Segmented,
  DatePicker,
  Spin,
} from "antd";
import {
  RedoOutlined,
  PrinterOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import FloorChart from "./elevatorDetails/Charts/FloorChart";
import "./Styles.css";
import { segmentOptions } from "./RealTimeMonitoringData";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import axios from "axios";
import { RTMBaseUrl } from "../../common/BaseUrl";
import DemoPie from "../../common/DemoPie";
import axiosRetry from "axios-retry";
import DoorChart from "./elevatorDetails/Charts/DoorCHart";
import EnergyChart from "./elevatorDetails/Charts/EnergyChart";
import EnergyFloorTrip from "./elevatorDetails/Charts/EnergyFloorTrip";
import CsvButton from "../../services/csvConverter/CsvButton";
import JpgdownloadButton from "../../services/JpgConverter/JpgdownloadButton";

const RealTimeMonitoring = (device) => {
  const { RangePicker } = DatePicker;
  const [floorRefresh, setFloorRefresh] = useState(false);
  const [doorRefresh, setDoorRefresh] = useState(false);
  const [floorTripEnergyRefresh, setFloorTripEnergyRefresh] = useState(false);
  const [energyRefresh, setEnergyRefresh] = useState(false);
  const [elevatorStatusRefresh, setElevatorStatusRefresh] = useState(false);
  const [selectInterval, setSelectInterval] = useState(1);
  const [selectedDates, setSelectedDates] = useState();
  const [floorLoader, setFloorLoader] = useState(false);
  const [rtmData, setRtmData] = useState();
  const [floortripEnergyLoader, setFloortripEnergyLoader] = useState(false);
  const [floorTripEnergyData, setFloorTripEnergyData] = useState();
  const [rtmStatus, setRtmStatus] = useState();
  const [energyConsumptionData, setEnergyConsumptionData] = useState();
  const [energyLoader, setEnergyLoader] = useState(false);
  const [value, setValue] = useState();
  const [doorLoader, setDoorLoader] = useState();
  const [doorCycleData, setDoorCycleData] = useState();
  const [statusLoader, setStatusLoader] = useState();
  const [selectFilter, setSelectFilter] = useState("Hourly");
  const [floorTripcsvData, setFfloorTripcsvData] = useState([])
  const accessToken = window.localStorage.getItem("accessToken");
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));
  const electricaraVisible =userProfile?.ONLY_ELECTRICAL_PARAMETER 
  const divHeight = window.localStorage.getItem("SpaceHeight");
  const [newdivHeight, setNewdivHeight] = useState();
  useEffect(() => {
    setNewdivHeight(divHeight - 135);
  }, [divHeight]);

  const divstyles = {
    RTMDivColumn: {
      height: `${newdivHeight}px`,
      overflowY: "auto",
      backgroundColor: "white",
    },
  };

  const FloorRef = createRef();
  const DoorRef = createRef();
  const FloorTripEnergy = createRef();
  const energyRef = createRef();
  const consumptionRef = createRef();
  let currentDate = new Date();
  let currentDateStr = currentDate.toISOString().split("T")[0];
  let currentT = moment().format("HH:mm:ss");

  const handleDownload = (id, title) => {
    const input = document.getElementById(id);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "l",
        unit: "pt",
        format: [1400, 450],
      });
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, "JPEG", 0, 0, width, height);
      // pdf.output('dataurlnewwindow');
      pdf.save(`${title}.pdf`);
    });
  };
  useEffect(() => {
    setStatusLoader(true);
    const param = {
      from: selectFilter
        ? selectFilter === "Daily"
          ? new Date(
              `${moment().subtract(7, "days").format("YYYY-MM-DD")}T${currentT}`
            ).getTime()
          : (selectFilter === "Weekly"
              ? new Date(
                  `${moment()
                    .subtract(30, "days")
                    .format("YYYY-MM-DD")}T${currentT}`
                ).getTime()
              : selectFilter === "Monthly" &&
                new Date(
                  `${moment()
                    .subtract(90, "days")
                    .format("YYYY-MM-DD")}T${currentT}`
                ).getTime()) ||
            new Date(`${moment().format("YYYY-MM-DD")}T${currentT}`).getTime()
        : new Date(`${selectedDates?.[0]}T${currentT}`).getTime(),
      to: selectFilter
        ? Math.floor(new Date().getTime() / 1000) * 1000
        : currentDateStr === selectedDates?.[1]
        ? Math.floor(new Date().getTime() / 1000) * 1000
        : new Date(`${selectedDates?.[1]}T23:59:59`).getTime(),
      FILTER:
        selectInterval >= 90
          ? "monthly"
          : selectInterval > 1 && selectInterval < 30
          ? "daily"
          : selectInterval >= 30 && selectInterval < 90
          ? "weekly"
          : "hourly",

      PARENT_ID: userProfile?.["custom:parent_id"],
      TENANT_ID: userProfile?.["custom:tenant_id"],
      DEVICE_ID: device?.device,
    };
    axios
      .post(`${RTMBaseUrl}/RTMElevatorStatus`, param, {
        headers: { ["x-access-token"]: accessToken, ["x-offset"]: -(new Date()).getTimezoneOffset() },
      })
      .then((response) => {
        setRtmStatus(response?.data?.data);
        setStatusLoader(false);
      });
    axiosRetry(axios, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      },
      retryCondition: (error) => error.response.status === 504,
    });
  }, [selectedDates, selectFilter, selectInterval, elevatorStatusRefresh]);

  useEffect(() => {
    setFloorLoader(true);
    const params = {
      DEVICE_ID: device?.device,
      from: selectFilter
        ? selectFilter === "Daily"
          ? new Date(
              `${moment().subtract(7, "days").format("YYYY-MM-DD")}T${currentT}`
            ).getTime()
          : (selectFilter === "Weekly"
              ? new Date(
                  `${moment()
                    .subtract(30, "days")
                    .format("YYYY-MM-DD")}T${currentT}`
                ).getTime()
              : selectFilter === "Monthly" &&
                new Date(
                  `${moment()
                    .subtract(90, "days")
                    .format("YYYY-MM-DD")}T${currentT}`
                ).getTime()) ||
            new Date(`${moment().format("YYYY-MM-DD")}T${currentT}`).getTime()
        : new Date(`${selectedDates?.[0]}T${currentT}`).getTime(),
      to: selectFilter
        ? new Date(`${moment().format("YYYY-MM-DD")}T${currentT}`).getTime()
        : new Date(`${selectedDates?.[1]}T${currentT}`).getTime(),
      PARENT_ID: userProfile?.["custom:parent_id"],
      TENANT_ID: userProfile?.["custom:tenant_id"],
      FILTER:
        selectInterval >= 90
          ? "monthly"
          : selectInterval > 1 && selectInterval < 30
          ? "daily"
          : selectInterval >= 30 && selectInterval < 90
          ? "weekly"
          : "hourly",
    };

    axios
      .post(`${RTMBaseUrl}/floorTripDetails`, params, {
        headers: { ["x-access-token"]: accessToken, ["x-offset"]: -(new Date()).getTimezoneOffset() },
      })
      .then((response) => {
        setRtmData(response?.data?.data);
        setFloorLoader(false);
      })
      .catch(() => {
        setFloorLoader(false);
      });
  }, [selectFilter, selectedDates, selectInterval, floorRefresh]);
  useEffect(() => {
    setFloortripEnergyLoader(true);
    const params = {
      DEVICE_ID: device?.device,
      from: selectFilter
        ? selectFilter === "Daily"
          ? new Date(
              `${moment().subtract(7, "days").format("YYYY-MM-DD")}T${currentT}`
            ).getTime()
          : (selectFilter === "Weekly"
              ? new Date(
                  `${moment()
                    .subtract(30, "days")
                    .format("YYYY-MM-DD")}T${currentT}`
                ).getTime()
              : selectFilter === "Monthly" &&
                new Date(
                  `${moment()
                    .subtract(90, "days")
                    .format("YYYY-MM-DD")}T${currentT}`
                ).getTime()) ||
            new Date(`${moment().format("YYYY-MM-DD")}T${currentT}`).getTime()
        : new Date(`${selectedDates?.[0]}T${currentT}`).getTime(),
      to: selectFilter
        ? new Date(`${moment().format("YYYY-MM-DD")}T${currentT}`).getTime()
        : new Date(`${selectedDates?.[1]}T${currentT}`).getTime(),
      PARENT_ID: userProfile?.["custom:parent_id"],
      TENANT_ID: userProfile?.["custom:tenant_id"],
      FILTER:
        selectInterval >= 90
          ? "monthly"
          : selectInterval > 1 && selectInterval < 30
          ? "daily"
          : selectInterval >= 30 && selectInterval < 90
          ? "weekly"
          : "hourly",
    };

    axios
      .post(`${RTMBaseUrl}/energyPerFloorTripData`, params, {
        headers: { ["x-access-token"]: accessToken, ["x-offset"]: -(new Date()).getTimezoneOffset() },
      })
      .then((response) => {
        setFloorTripEnergyData(response?.data?.data);
        setFloortripEnergyLoader(false);
      })
      .catch(() => {
        setFloortripEnergyLoader(false);
      });
  }, [selectFilter, selectedDates, selectInterval, floorTripEnergyRefresh]);
  useEffect(() => {
    setDoorLoader(true);
    const params = {
      DEVICE_ID: device?.device,
      from: selectFilter
        ? selectFilter === "Daily"
          ? new Date(
              `${moment().subtract(7, "days").format("YYYY-MM-DD")}T17:30:00`
            ).getTime()
          : (selectFilter === "Weekly"
              ? new Date(
                  `${moment()
                    .subtract(30, "days")
                    .format("YYYY-MM-DD")}T${currentT}`
                ).getTime()
              : selectFilter === "Monthly" &&
                new Date(
                  `${moment()
                    .subtract(90, "days")
                    .format("YYYY-MM-DD")}T${currentT}`
                ).getTime()) ||
            new Date(`${moment().format("YYYY-MM-DD")}T${currentT}`).getTime()
        : new Date(`${selectedDates?.[0]}T${currentT}`).getTime(),
      to: selectFilter
        ? new Date(`${moment().format("YYYY-MM-DD")}T${currentT}`).getTime()
        : new Date(`${selectedDates?.[1]}T${currentT}`).getTime(),
      PARENT_ID: userProfile?.["custom:parent_id"],
      TENANT_ID: userProfile?.["custom:tenant_id"],
      FILTER:
        selectInterval >= 90
          ? "monthly"
          : selectInterval > 1 && selectInterval < 30
          ? "daily"
          : selectInterval >= 30 && selectInterval < 90
          ? "weekly"
          : "hourly",
    };

    axios
      .post(`${RTMBaseUrl}/doorCycleDetails`, params, {
        headers: { ["x-access-token"]: accessToken, ["x-offset"]: -(new Date()).getTimezoneOffset() },
      })
      .then((response) => {
        setDoorCycleData(response?.data?.data);
        setDoorLoader(false);
      })
      .catch(() => {
        setDoorLoader(false);
      });
  }, [selectFilter, selectedDates, selectInterval, doorRefresh]);
  useEffect(() => {
    setEnergyLoader(true);
    const params = {
      DEVICE_ID: device?.device,
      from: selectFilter
        ? selectFilter === "Daily"
          ? new Date(
              `${moment()
                .subtract(7, "days")
                .format("YYYY-MM-DD")}T${moment().format("HH:mm:ss")}`
            ).getTime()
          : (selectFilter === "Weekly"
              ? new Date(
                  `${moment()
                    .subtract(30, "days")
                    .format("YYYY-MM-DD")}T${moment().format("HH:mm:ss")}`
                ).getTime()
              : selectFilter === "Monthly" &&
                new Date(
                  `${moment()
                    .subtract(90, "days")
                    .format("YYYY-MM-DD")}T${moment().format("HH:mm:ss")}`
                ).getTime()) ||
            new Date(
              `${moment().format("YYYY-MM-DD")}T${moment().format("HH:mm:ss")}`
            ).getTime()
        : new Date(
            `${selectedDates?.[0]}T${moment().format("HH:mm:ss")}`
          ).getTime(),
      to: selectFilter
        ? new Date(
            `${moment().format("YYYY-MM-DD")}T${moment().format("HH:mm:ss")}`
          ).getTime()
        : new Date(
            `${selectedDates?.[1]}T${moment().format("HH:mm:ss")}`
          ).getTime(),
      PARENT_ID: userProfile?.["custom:parent_id"],
      TENANT_ID: userProfile?.["custom:tenant_id"],
      FILTER:
        selectInterval >= 90
          ? "monthly"
          : selectInterval > 1 && selectInterval < 30
          ? "daily"
          : selectInterval >= 30 && selectInterval < 90
          ? "weekly"
          : "hourly",
    };
    axios
      .post(`${RTMBaseUrl}/energyData`, params, {
        headers: { ["x-access-token"]: accessToken, ["x-offset"]: -(new Date()).getTimezoneOffset() },
      })
      .then((response) => {
        setEnergyConsumptionData(response?.data?.data);
        setEnergyLoader(false);
      });
  }, [selectFilter, selectedDates, selectInterval, energyRefresh]);

  let today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  var dateTime = date + " " + time;


  return (
      <div
      // className="RTMoptionalPerraTab"
      >

          <Row className="segmentStyle" style={{ backgroundColor: "#a0d7ef"}}>
            <Col
              xl={{ span: 12 }}
              lg={{ span: 12 }}
              md={{ span: 8 }}
              sm={{ span: 8 }}
              xs={{ span: 24 }}
            >
              <Segmented
                value={selectFilter}
                className="realtime"
                options={segmentOptions}
                onChange={(value) => {
                  setSelectFilter(value);
                  setSelectInterval(
                    value === "Hourly"
                      ? 1
                      : value === "Daily"
                      ? 8
                      : value === "Weekly"
                      ? 30
                      : 90
                  );
                }}
              />
            </Col>
            <Col
              xl={{ offset: 7, span: 5 }}
              lg={{ offset: 5, span: 7 }}
              md={{ offset: 7, span: 9 }}
              sm={{ offset: 7, span: 9 }}
              xs={{ span: 24 }}
            >
              <div >
                <RangePicker
                style={{height:"25px"}}
                  value={
                    selectFilter === "Daily"
                      ? [moment().subtract(7, "days"), moment()]
                      : selectFilter === "Weekly"
                      ? [moment().subtract(30, "days"), moment()]
                      : selectFilter === "Monthly"
                      ? [moment().subtract(90, "days"), moment()]
                      : (selectFilter === "Hourly" && [moment(), moment()]) ||
                        value
                  }
                  onChange={(val, timeString) => {
                    setValue(val);
                    setSelectedDates(timeString);
                    if (timeString) setSelectFilter("");
                    setSelectInterval(
                      Math.abs(
                        (new Date(timeString?.[0]).getTime() -
                          new Date(timeString?.[1]).getTime()) /
                          (24 * 60 * 60 * 1000)
                      ) + 1
                    );
                  }}
                />
                <div
                  style={{
                    display: "flex"
                  }}
                >
                  <Popover
                    content={<>Time shown is as per user’s time zone</>}
                    trigger="hover"
                  >
                    <ExclamationCircleOutlined  style={{ margin: "4px 0px 0px 4px" }}/>
                  </Popover>
                  {/* <DownloadOutlined
                    onClick={(e) => handleDownload()}
                    style={{ margin: "0px 14px" }}
                  /> */}
                  <div style={{ margin: "0px 0px 0px 4px" }}>
                    Last Updated : {dateTime}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
       
        <div style={divstyles.RTMDivColumn}>
          {electricaraVisible === true ? 
          <Row>
            <Col span={24}>
                  <Card id="elevatorStatus" ref={energyRef}>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div className="fontStyle">Active Hours</div>
                      <div
                        className="parametersIconsData"
                        style={{ backgroundColor: "white" }}
                      >
                        <Popover content={"Refresh"}>
                          <RedoOutlined
                            className="SmallIconsData"
                            onClick={() =>
                              setElevatorStatusRefresh(!elevatorStatusRefresh)
                            }
                          />
                        </Popover>
                        <JpgdownloadButton data={energyRef}  filename="Active Hours"  />
                        <Popover content={"Download Pdf"}>
                          <PrinterOutlined
                            onClick={() =>
                              handleDownload("elevatorStatus", "Active Hours")
                            }
                            className="SmallIconsData"
                          />
                        </Popover>
                        <CsvButton data={rtmStatus} filename="Elevator-Status" selectInterval={selectInterval}/>
                      </div>
                    </div>
    
                    <div ref={energyRef}>
                      {statusLoader ? (
                        <Spin
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "140px",
                          }}
                        />
                      ) : (
                        <DemoPie chartData={rtmStatus} called="RTMElevator" />
                      )}
                    </div>
                  </Card>
                </Col>
            <Col span={24} >
              <Card id="EnergyConsumption" ref={consumptionRef}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="fontStyle">Energy Consumption</div>
                  <div
                    className="parametersIconsData"
                    style={{ backgroundColor: "white" }}
                  >
                    <Popover content={"Refresh"}>
               
                      <RedoOutlined
                        className="SmallIconsData"
                        onClick={() => setEnergyRefresh(!energyRefresh)}
                      />
                    </Popover>
                    <JpgdownloadButton data={consumptionRef}  filename="Energy Consumption"  />                   
                    <Popover content={"Download Pdf"}>
                      <PrinterOutlined
                        onClick={() =>
                          handleDownload(
                            "EnergyConsumption",
                            "Energy_Consumption"
                          )
                        }
                        className="SmallIconsData"
                      />
                    </Popover>
                    <Popover content={"Download Csv"}>
                      <CsvButton data={energyConsumptionData} filename="Energy-Consumption" selectInterval={selectInterval}/>
                      </Popover> 
                  </div>
                </div>
                {energyLoader ? (
                  <Spin
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "140px",
                    }}
                  />
                ) : (
                  <EnergyChart
                    chartData={energyConsumptionData}
                    called="rtmenergyConsumption"
                    selectFilter={selectFilter}
                    selectInterval={selectInterval}
                  />
                )}
              </Card>
            </Col>
          </Row>
           :
              <>
              <Row style={{ marginTop: "10px" }}>
                <Col span={24}>
                  <Card id="capture" ref={FloorRef}>
                    <div className="fontStyle">Floors Travelled</div>
                    <div
                      className="parametersIconsData"
                      style={{ backgroundColor: "white" }}
                    >
                      <Popover content={"Refresh"}>
                        <RedoOutlined
                          className="SmallIconsData"
                          onClick={() => setFloorRefresh(!floorRefresh)}
                        />
                      </Popover>
                      <JpgdownloadButton data={FloorRef}  filename="Floor Travelled" />
                      <Popover content={"Download Pdf"}>
                        <PrinterOutlined
                          onClick={() => handleDownload("capture", "Floor")}
                          className="SmallIconsData"
                        />
                      </Popover>
                      <CsvButton data={rtmData} filename="Floor-Trip" selectInterval={selectInterval}/>
                    </div>
                    {floorLoader ? (
                      <Spin
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "100px",
                        }}
                      />
                    ) : (
                      <FloorChart
                        floorData={rtmData}
                        called="rtmFlorrData"
                        selectInterval={selectInterval}
                        setFfloorTripcsvData={setFfloorTripcsvData}
                        floorTripcsvData ={floorTripcsvData}
                        
                      />
                    )}
                  </Card>
                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col span={24}>
                  <Card id="doorCycle" ref={DoorRef}>
                    <div className="fontStyle">Doors Cycle</div>
                    <div
                      className="parametersIconsData"
                      style={{ backgroundColor: "white" }}
                    >
                      <Popover content={"Refresh"}>
                        <RedoOutlined
                          className="SmallIconsData"
                          onClick={() => setDoorRefresh(!doorRefresh)}
                        />
                      </Popover>
                      <JpgdownloadButton data={DoorRef}  filename="Door Cycle"  />
                      <Popover content={"Download Pdf"}>
                        <PrinterOutlined
                          onClick={() => handleDownload("doorCycle", "Door")}
                          className="SmallIconsData"
                        />
                      </Popover>
                      <Popover placement="leftTop" content={"Download Csv"}  trigger="hover">
                      <CsvButton data={doorCycleData} filename="Door-Cycle" selectInterval={selectInterval}/>
                      </Popover> 
                    </div>
                    {doorLoader ? (
                      <Spin
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "100px",
                        }}
                      />
                    ) : (
                      <DoorChart
                        chartData={doorCycleData}
                        selectInterval={selectInterval}
                      />
                    )}
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Card id="floorTripEnergy" ref={FloorTripEnergy}>
                    <div className="fontStyle">Energy per Floor & Trip</div>
                    <div
                      className="parametersIconsData"
                      style={{ backgroundColor: "white" }}
                    >
                      <Popover content={"Refresh"}>
                        <RedoOutlined
                          className="SmallIconsData"
                          onClick={() =>
                            setFloorTripEnergyRefresh(!floorTripEnergyRefresh)
                          }
                        />
                      </Popover>
                      <JpgdownloadButton data={FloorTripEnergy}  filename="Energy per Trip & Floor"  />
                      <Popover content={"Download Pdf"}>
                        <PrinterOutlined
                          onClick={() =>
                            handleDownload(
                              "floorTripEnergy",
                              "Energy per Floor & TRip"
                            )
                          }
                          className="SmallIconsData"
                        />
                      </Popover>
                      <Popover  content={"Download Csv"}>
                      <CsvButton data={floorTripEnergyData} filename="Energy-Per-Floor-&-Trip" selectInterval={selectInterval}/>
                      </Popover> 
                    </div>
                    {floortripEnergyLoader ? (
                      <Spin
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "100px",
                        }}
                      />
                    ) : (
                      <EnergyFloorTrip
                        chartData={floorTripEnergyData}
                        selectInterval={selectInterval}
                      />
                    )}
                  </Card>
                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }}>
              <Col span={24}>
                  <Card id="elevatorStatus" ref={energyRef}>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div className="fontStyle">Active Hours</div>
                      <div
                        className="parametersIconsData"
                        style={{ backgroundColor: "white" }}
                      >
                        <Popover content={"Refresh"}>
                          <RedoOutlined
                            className="SmallIconsData"
                            onClick={() =>
                              setElevatorStatusRefresh(!elevatorStatusRefresh)
                            }
                          />
                        </Popover>
                        <JpgdownloadButton data={energyRef}  filename="Active Hours"  />
                        <Popover content={"Download Pdf"}>
                          <PrinterOutlined
                            onClick={() =>
                              handleDownload("elevatorStatus", "Active Hours")
                            }
                            className="SmallIconsData"
                          />
                        </Popover>
                        <CsvButton data={rtmStatus} filename="Elevator-Status" selectInterval={selectInterval}/>
                      </div>
                    </div>
    
                    <div ref={energyRef}>
                      {statusLoader ? (
                        <Spin
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "140px",
                          }}
                        />
                      ) : (
                        <DemoPie chartData={rtmStatus} called="RTMElevator" />
                      )}
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }}>
               <Col span={24} >
                  <Card id="EnergyConsumption" ref={consumptionRef}>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div className="fontStyle">Energy Consumption</div>
                      <div
                        className="parametersIconsData"
                        style={{ backgroundColor: "white" }}
                      >
                        <Popover content={"Refresh"}>
                          {" "}
                          <RedoOutlined
                            className="SmallIconsData"
                            onClick={() => setEnergyRefresh(!energyRefresh)}
                          />
                        </Popover>
                        <JpgdownloadButton data={consumptionRef}  filename="Energy Consumption"  />
                        <Popover content={"Download Pdf"}>
                          <PrinterOutlined
                            onClick={() =>
                              handleDownload(
                                "EnergyConsumption",
                                "Energy_Consumption"
                              )
                            }
                            className="SmallIconsData"
                          />
                        </Popover>
                        <Popover content={"Download Csv"}>
                      <CsvButton data={energyConsumptionData} filename="Energy-Consumption" selectInterval={selectInterval}/>
                      </Popover> 
                        
                      </div>
                    </div>
                    {energyLoader ? (
                      <Spin
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "140px",
                        }}
                      />
                    ) : (
                      <EnergyChart
                        chartData={energyConsumptionData}
                        called="rtmenergyConsumption"
                        selectFilter={selectFilter}
                        selectInterval={selectInterval}
                      />
                    )}
                  </Card>
                </Col>
              </Row>
              </>
              }
        
          {/* <Row style={{ marginTop: "10px" }}>
            <Col span={24} id="electricPErameter">
              <ElectricPerameter />
            </Col>
          </Row> */}
        </div>
      </div>

  );
};

export default RealTimeMonitoring;
