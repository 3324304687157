import { Col, Divider, Row } from "antd";
import React from "react";
import "./Styles.css";

const UserPreviewModal = (previewData) => {
  return (
    <>
      <h2 style={{ fontWeight: "bold", fontSize: "18px", textAlign: "center" }}>
       User Details
      </h2>
      <Divider />
      <Row>
        <Col xs={24} sm={12}>
          <h3>
           <span style={{marginRight: "20px",}}>Country :</span> 
            <span
              style={{
                margin: "0px 4px",
                fontWeight: "bold",
                color: "#00867dff",
              }}
            >
              {previewData?.previewData?.["custom:country"] || "-"}
            </span>
          </h3>
        </Col>
        <Col xs={24} sm={12}>
          <h3>
           
            <span style={{marginRight: "20px",}}> City :</span>
            <span
              style={{
                margin: "0px 4px",
                fontWeight: "bold",
                color: "#00867dff",
              }}
            >
              {previewData?.previewData?.["custom:city"] || "-"}
            </span>
          </h3>
        </Col>
        <Col xs={24} sm={12}>
          <h3>
          
            <span style={{marginRight: "20px",}}>  Location :</span>
            <span
              style={{
                margin: "0px 4px",
                fontWeight: "bold",
                color: "#00867dff",
              }}
            >
              {previewData?.previewData?.["custom:area"] || "-"}
            </span>
          </h3>
        </Col>
        <Col xs={24} sm={12}>
          <h3>
            
            <span style={{marginRight: "20px",}}>Company :</span>
            <span
              style={{
                margin: "0px 4px",
                fontWeight: "bold",
                color: "#00867dff",
              }}
            >
              {previewData?.previewData?.["custom:company_name"]}
            </span>
          </h3>
        </Col>
        <Col xs={24} sm={12}>
          <h3>
           
            <span style={{marginRight: "20px",}}> Parent ID :</span>
            <span
              style={{
                margin: "0px 4px",
                fontWeight: "bold",
                color: "#00867dff",
              }}
            >
              {previewData?.previewData?.["custom:parent_id"]}
            </span>
          </h3>
        </Col>
        <Col xs={24} sm={12}>
          <h3>
           
            <span style={{marginRight: "20px",}}> Tenant ID :</span>
            <span
              style={{
                margin: "0px 4px",
                fontWeight: "bold",
                color: "#00867dff",
              }}
            >
              {previewData?.previewData?.["custom:tenant_id"]}
            </span>
          </h3>
        </Col>
        <Col xs={24} sm={12}>
          <h3>
            
            <span style={{marginRight: "20px",}}>Name :</span>
            <span
              style={{
                margin: "0px 4px",
                fontWeight: "bold",
                color: "#00867dff",
              }}
            >
              {previewData?.previewData?.name}
            </span>
          </h3>
        </Col>
        <Col xs={24} sm={12}>
          <h3>
          
            <span style={{marginRight: "20px",}}>  Active:</span>
            <span
              style={{
                margin: "0px 4px",
                fontWeight: "bold",
                color: "#00867dff",
              }}
            >
              {previewData?.previewData?.Enabled === true ? "YES" : "NO"}
            </span>
          </h3>
        </Col>
        <Col xs={24} sm={12}>
          <h3>
           
            <span style={{marginRight: "20px",}}> Email :</span>
            <span
              style={{
                margin: "0px 4px",
                fontWeight: "bold",
                color: "#00867dff",
              }}
            >
              {previewData?.previewData?.email}
            </span>
          </h3>
        </Col>
        <Col xs={24} sm={12}>
          <h3>
            <span style={{marginRight: "20px",}}>Email Verified :</span>
            <span
              style={{
                margin: "0px 4px",
                fontWeight: "bold",
                color: "#00867dff",
              }}
            >
              {previewData?.previewData?.email_verified === "true"
                ? "YES"
                : "NO"}
            </span>
          </h3>
        </Col>
        <Col xs={24} sm={12}>
          <h3>
          <span style={{marginRight: "20px",}}>  Phone No. :</span>
            <span
              style={{
                margin: "0px 4px",
                fontWeight: "bold",
                color: "#00867dff",
              }}
            >
              {previewData?.previewData?.phone_number}
            </span>
          </h3>
        </Col>
        <Col xs={24} sm={12}>
          <h3>
            <span style={{marginRight: "20px",}}>Phone No. Verified :</span>
            <span
              style={{
                margin: "0px 4px",
                fontWeight: "bold",
                color: "#00867dff",
              }}
            >
              {previewData?.previewData?.phone_number_verified === "true"
                ? "YES"
                : "NO"}
            </span>
          </h3>
        </Col>
      </Row>
    </>
  );
};

export default UserPreviewModal;


