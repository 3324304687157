import React, { useEffect} from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import "../App.css";
import "./Styles.css";
import Footer from "./Footer";
import SessionExpiration from "./SessionExpiary";
const AuthLayout = () => {

  useEffect(() => {
    const updateTableHeight = () => {
      const divHeight = document.getElementById('alerttab123').offsetHeight; // Use offsetHeight
      window.localStorage.setItem("SpaceHeight", divHeight);
    }
    
    updateTableHeight();
    window.addEventListener('resize', updateTableHeight);
  
    return () => {
      window.removeEventListener('resize', updateTableHeight);
    };
  }, []);

  return (
    <>
    <SessionExpiration/>
<div className="main-container">
    <Header />
    <div className="content-container" id="alerttab123">
        <Outlet />
    </div>
    <Footer />
</div>
</>
  );
};
export default AuthLayout;
