/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form, Select, DatePicker, Segmented } from "antd";
import moment from "moment";
import { segmentOptions } from "./ReportsAndAnalysisData";
import { onboardingBaseUrl } from "../../common/BaseUrl";
import axios from "axios";
import "./Styles.css";
const ReportsModal = ({
  setReportAnaFilter,
  setOpen,
  setSelectedDates,
  selectInterval,
  setSelectInterval,
  setSaveButton,
  parameterData,
  newParameterData,
  setParameterData,
  setNewParameterData,
  selectFilter,
  setSelectFilter,
  selectIndex,
  setSelectIndex,
  setDeviceId,
  setDateRangeValue,
  dateRangeValue,
  setApplyButton,
  electricaraVisible
}) => {
  const [value, setValue] = useState();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [form] = Form.useForm();
  const accessToken = window.localStorage.getItem("accessToken");
  const deviceType = window.localStorage.getItem("devicetype"); 
 let allParameter;
 if(electricaraVisible === true){
  allParameter=newParameterData
 }else{
  allParameter = parameterData
 }
  const onFinish = () => {
    setOpen(false);
    setSaveButton(true);
  };

  const handleChange = (para) => {
    if(electricaraVisible === true){
      newParameterData?.map((item) => {
        if (item?.title === para.title) {
          item.state = !item?.state;
        }
      });
  
      setNewParameterData([...newParameterData]);
    }else{
      parameterData?.map((item) => {
        if (item?.title === para.title) {
          item.state = !item?.state;
        }
      });
  
      setParameterData([...parameterData]);
    }
    
  };
  useEffect(() => {
    axios
      .post(
        `${onboardingBaseUrl}/store-count/data`,
        {},
        { headers: { ["x-access-token"]: accessToken } }
      )
      .then((response) => {
        
        setSelectFilter(response?.data?.data);
        setReportAnaFilter(response?.data?.data);
      })
  }, []);
  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "country") {
      form.setFieldsValue({ city: undefined, location:undefined, category:undefined,elevator:undefined });
    }
    else if (formFieldName === "city") {
      form.setFieldsValue({ location:undefined, category:undefined,elevator:undefined  }); 
    }
    else if (formFieldName === "location") {
      form.setFieldsValue({ category:undefined,elevator:undefined  });
    }
    else if (formFieldName === "category") {
      form.setFieldsValue({ elevator:undefined  }); 
    }
  };
  return (
    <div style={{ height: "300px", overflow: "scroll", overflowX: "hidden" }}>
      <Form
      onValuesChange={handleFormValuesChange}
        className="backgroundStyle"
        onFinish={onFinish}
        form={form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
      >
        <h3 style={{ marginLeft: "18px", fontWeight: "600" }}>
          Select Parameter :
        </h3>
        <Form.Item
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Row>
            {allParameter?.map((para, index) => {
              return (
                <Col key={index}>
                  <div
                    onClick={() => {
                      handleChange(para);
                    }}
                    className={
                      para?.state
                        ? "toggleParametersactive"
                        : "toggleParametersIdle"
                    }
                  >
                    {para?.title}
                  </div>
                </Col>
              );
            })}
          </Row>
        </Form.Item>

        <Row gutter={[24, 15]}>
          <Col
            xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div className="formInputStyle">
              <label className="formLabelStyle">Country</label>
              <Form.Item className="selectStyle" name="country">
                <Select
                  placeholder="Country"
                  onChange={(value, key) => {
                    setSelectIndex({ ...selectIndex, country: key.key });
                  }}
                >
                  {selectFilter?.map((country, index) => {
                    return (
                      <Option value={country?.COUNTRY} key={index}>
                        {country?.COUNTRY}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col
            xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div className="formInputStyle">
              <label className="formLabelStyle">City</label>
              <Form.Item className="selectStyle" name="city">
                <Select
                  onChange={(value, key) => {
                    setSelectIndex({ ...selectIndex, city: key.key });
                  }}
                  placeholder="City"
                  disabled={selectIndex?.country ? false : true}
                  className="ReportModal"
                >
                  {selectFilter?.[selectIndex?.country]?.CITIES?.map(
                    (city, cityIndex) => {
                      return (
                        <Option value={city?.CITY} key={cityIndex}>
                          {city?.CITY}
                        </Option>
                      );
                    }
                  )}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col
            xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div className="formInputStyle">
              <label className="formLabelStyle">Location</label>
              <Form.Item className="selectStyle" name="location">
                <Select
                  onChange={(value, key) => {
                    setSelectIndex({ ...selectIndex, location: key.key });
                  }}
                  placeholder="Location"
                  disabled={
                    selectIndex?.country && selectIndex?.city ? false : true
                  }
                  className="ReportModal"
                >
                  {selectIndex?.city &&
                    selectFilter?.[selectIndex?.country]?.CITIES?.[
                      selectIndex?.city
                    ].LOCATION?.map((store, storeIndex) => {
                      return (
                        <Option value={store?.LOCATION} key={storeIndex}>
                          {store?.LOCATION}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </div>
          </Col>

          <Col span={12}>
            <div className="formInputStyle">
              <label className="formLabelStyle">Building</label>
              <Form.Item name="category" className="selectStyle">
                <Select
                  onChange={(value, key) => {
                    setSelectIndex({ ...selectIndex, store: key.key });
                  }}
                  placeholder="Building"
                  className="ReportModal"
                  disabled={selectIndex?.location ? false : true}
                >
                    {
                      selectIndex?.location &&
                      selectFilter?.[selectIndex?.country]?.CITIES?.[selectIndex?.city
                      ].LOCATION?.[selectIndex?.location]?.STORE?.map((store, storeIndex)=>{
                        return (
                          <Option value={store?.STORE_ID} key={storeIndex}>
                            {store?.STORE_NAME}
                          </Option>)
                      })
                    }                    
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <div className="formInputStyle">
              <label className="formLabelStyle">{deviceType}</label>
              <Form.Item className="selectStyle" name="elevator">
                <Select
                  placeholder={deviceType}
                  className="ReportModal"
                  disabled={selectIndex?.store ? false : true}
                  onChange={(value, key) => {
                    setDeviceId(value);
                    setSelectIndex({ ...selectIndex, elevator: key.key });
                  }}
                >
                    {
                      selectIndex?.store &&
                      selectFilter?.[selectIndex?.country]?.CITIES?.[selectIndex?.city
                      ].LOCATION?.[selectIndex?.location]?.STORE?.[selectIndex?.store]?.DEVICE_DETAILS?.map((device, deviceIndex)=>{
                        return (
                          <Option value={device?.DEVICE_ID} key={deviceIndex}>
                            {device?.DEVICE_NAME}
                          </Option>)
                      })
                    }
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Segmented
          className="realtime"
          style={{marginTop:"20px"}}
          value={dateRangeValue}
          options={segmentOptions}
          onChange={(e) => {
            setDateRangeValue(e);
            setSelectInterval(
              e === "hourly" ? 1 : e === "daily" ? 7 : e === "weekly" ? 31 : 91
            );
          }}
        />

        <h2 style={{ marginLeft: "120px" , marginTop:"10px" }}>OR</h2>
        <Row>
          <Col
            xl={{ span: 16 }}
            lg={{ span: 16 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Form.Item name="dateRange">
              <>
                <RangePicker
                  // format="YYYY-MM-DD"
                  value={
                    dateRangeValue === "daily"
                      ? [moment().subtract(7, "days"), moment()]
                      : dateRangeValue === "weekly"
                      ? [moment().subtract(30, "days"), moment()]
                      : dateRangeValue === "monthly"
                      ? [moment().subtract(90, "days"), moment()]
                      : (dateRangeValue === "hourly" && [moment(), moment()]) ||
                        value
                  }
                  onChange={(val, timeString) => {
                    setValue(val);
                    setSelectedDates(timeString);
                    if (timeString) setDateRangeValue("");

                    setSelectInterval(
                      Math.abs(
                        (new Date(timeString?.[0]).getTime() -
                          new Date(timeString?.[1]).getTime()) /
                          (24 * 60 * 60 * 1000)
                      ) + 1
                    );
                  }}
                />
              </>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            xl={{ span: 8 }}
            lg={{ span: 8 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Form.Item name="timeSpan">
              <div className="formInputDiv">
                <label className="formInputLabel">Interval</label>
                <Select
                  required
                  value={
                    selectInterval > 90
                      ? "monthly"
                      : selectInterval > 1 && selectInterval <= 30
                      ? "daily"
                      : selectInterval > 30 && selectInterval <= 90
                      ? "weekly"
                      : "hourly"
                  }
                >
                  <Option
                    value="hourly"
                    disabled={
                      selectInterval == 0 || selectInterval == 1 ? false : true
                    }
                  >
                    Hourly
                  </Option>
                  <Option
                    value="daily"
                    disabled={
                      selectInterval > 1 && selectInterval <= 30 ? false : true
                    }
                  >
                    Daily
                  </Option>
                  <Option
                    value="weekly"
                    disabled={
                      selectInterval > 30 && selectInterval <= 90 ? false : true
                    }
                  >
                    Weekly
                  </Option>
                  <Option
                    value="monthly"
                    disabled={selectInterval > 90 ? false : true}
                  >
                    Monthly
                  </Option>
                </Select>
              </div>
            </Form.Item>
          </Col>
        </Row>

        <div style={{ display: "flex", justifyContent: "end" }}>
          <Form.Item
            rules={[
              {
                required: true,
              },
            ]}
          >
            <div style={{ display: "flex" }}>
              <Button
                onClick={() => {
                  form.resetFields();
                  setSelectIndex("")
                }}
                type="primary"
                style={{ marginRight: "10px", color: "white" }}
              >
                Reset
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                style={{ color: "white" }}
                onClick={() => {
                  setApplyButton([selectInterval, selectIndex]);
                }}
              >
                Apply
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ReportsModal;
