/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  FolderOpenFilled,
  FilePdfTwoTone,
  ArrowLeftOutlined,
  DeleteOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import axiosRetry from "axios-retry";
import { Row, Col, Spin } from "antd";
import { RTMBaseUrl } from "../../common/BaseUrl";
import "./ReportStyle.css";
import FilterForm from "../../services/headerFilter/FilterForm";

let linkvlaue;
let downloadFile;
let breadComb;
const Reports = ({ isLoading, setIsLoading }) => {
  useEffect(() => {
    linkvlaue = "";
    breadComb = "";
    downloadFile = 0;
  }, []);
  const accessToken = window.localStorage.getItem("accessToken");
  const [isClicked, setIsClicked] = useState(false);
  const [folderData, setFolderData] = useState();
  const [pathData, setPathData] = useState();
  const [backOption, setBackOption] = useState(false);
  const [callBackApi, setCallBackApi] = useState(false);
  const [apiRecall, setApiRecall] = useState(false);
  const [countryName, SetCountryName] = useState();
  const [cityName, SetCityName] = useState();
  const [locationName, SetLocationName] = useState();
  const [buildingName, SetBuildingName] = useState();
  // eslint-disable-next-line no-unused-vars
  const [elevatorName, SetElevatorName] = useState();
  const [filterHeaderapiLoader, setFilterHeaderapiLoader] = useState(false);
  const divHeight = window.localStorage.getItem("SpaceHeight");
  const [newtableHeight, setNewTableHeight] = useState();
  useEffect(() => {
    setNewTableHeight(divHeight - 98);
  }, [divHeight]);


  const handleCompleteBack = () => {
    linkvlaue = "";
    breadComb = "";
    downloadFile = 0;
    setCallBackApi(!callBackApi);
    setBackOption(false);
  };

  useEffect(() => {
    if (folderData?.includes(".")) {
      setIsLoading(true);
      // handleCompleteBack();
      window.open(folderData, "_blank");
      setIsLoading(false);
      const filename = linkvlaue.split("/").pop();
      const output = linkvlaue.replace(`${filename}`, "");
      linkvlaue = output;
      axios
        .post(
          `${RTMBaseUrl}/reportGeneration`,
          {
            path: output,
            download: 0,
          },
          {
            headers: { ["x-access-token"]: accessToken },
          }
        )
        .then((response) => {
          setFolderData(response?.data?.data);
          setIsLoading(false);
          setTimeout(() => {
            setIsClicked(false);
          }, 500);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [folderData]);

  const handleBack = () => {
    downloadFile = 0;
    let lastSpaceIndex = linkvlaue.lastIndexOf("/");
    let secondLastSpaceIndex = linkvlaue.lastIndexOf("/", lastSpaceIndex - 1);
    linkvlaue = linkvlaue.substring(0, secondLastSpaceIndex);
    linkvlaue = linkvlaue ? linkvlaue + "/" : linkvlaue;

    // setPathData()
    setCallBackApi(!callBackApi);

    let lastSpaceIndexbreadComb = breadComb.lastIndexOf("/");
    let secondLastSpaceIndexbreadComb = breadComb.lastIndexOf(
      "/",
      lastSpaceIndexbreadComb - 1
    );
    breadComb = breadComb.substring(0, secondLastSpaceIndexbreadComb);
    breadComb = breadComb ? breadComb + "/" : breadComb;
  };
  useEffect(() => {
    if (pathData && pathData?.includes(".")) {
      linkvlaue = linkvlaue + `${pathData}`;
      breadComb = breadComb + `${pathData}`;
      downloadFile = 1;
    } else if (pathData && pathData?.includes(":")) {
      let res = pathData.slice(
        pathData.lastIndexOf(":") + 2,
        pathData.lastIndexOf(")")
      );
      linkvlaue = linkvlaue + `${res}/`;
      breadComb = breadComb + `${pathData}/`;
    } else if (pathData) {
      linkvlaue = linkvlaue + `${pathData}/`;
      breadComb = breadComb + `${pathData}/`;
    }
  }, [pathData, apiRecall]);

  useEffect(() => {
    setIsLoading(true);
    setIsClicked(true);
    axios
      .post(
        `${RTMBaseUrl}/reportGeneration`,
        {
          path: linkvlaue,
          download: downloadFile,
          COUNTRY: countryName,
          CITY: cityName,
          LOCATION: locationName,
          STORE_ID: buildingName,
        },
        {
          headers: { ["x-access-token"]: accessToken },
        }
      )
      .then((response) => {
        setFolderData(response?.data?.data);
        setIsLoading(false);
        setTimeout(() => {
          setIsClicked(false);
        }, 500);
      })
      .catch(() => {
        setIsLoading(false);
      });
    axiosRetry(axios, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      },
      retryCondition: (error) => error.response.status === 504,
    });
  }, [
    pathData,
    callBackApi,
    apiRecall,
    filterHeaderapiLoader,
  ]);

  try {
    if (folderData) {
      folderData?.sort((a, b) =>
        a?.localeCompare(b, undefined, { numeric: true })
      );
    }
  } catch (error) {
    console.error("Error occurred while sorting folderData:", error);
  }
  return (
    <>
      {!linkvlaue ? (
        <div
          style={{ height: "40px", marginBottom: "10px", marginTop: "-25px" }}
        >
          <FilterForm
            filtertype="rtmMainPage"
            SetCountryName={SetCountryName}
            SetCityName={SetCityName}
            SetLocationName={SetLocationName}
            SetBuildingName={SetBuildingName}
            SetElevatorName={SetElevatorName}
            setFilterHeaderapiLoader={setFilterHeaderapiLoader}
            filterHeaderapiLoader={filterHeaderapiLoader}
          />
        </div>
      ) : (
        " "
      )}

      {backOption ? (
        <Row
          style={{
            backgroundColor: "#a0d7ef",
            height: linkvlaue ? "40px" : "0px",
          }}
        >
          <h1 className="backButton" onClick={handleBack}>
            <ArrowLeftOutlined
              className="BackErrowStyle"
              style={{ color: linkvlaue ? "black" : "#f0f2f5" }}
            />
          </h1>
          <h2
            style={{
              fontSize: "14px",
              marginLeft: "20px",
              fontWeight: "bold",
              color: "#8a8988",
              marginTop:"10px"
            }}
          >
            {breadComb?.replace(/\//g, " > ")}
          </h2>
        </Row>
      ) : (
        ""
      )}

      <div style={{height:`${newtableHeight}px`, overflowY: "auto" }}>
        <Spin
          spinning={isLoading}
          delay={500}
          size="large"
          tip="Loading..."
          style={{ marginTop: "30vh" }}
        >
          {folderData?.includes(".") ? (
            <Col
              xs={8}
              sm={6}
              md={4}
              lg={3}
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              {folderData?.includes(".xlsx") ? (
                <FileExcelOutlined
                  className="filestyleGreen"
                  onClick={handleCompleteBack}
                />
              ) : (
                <FilePdfTwoTone
                  className="filestyle"
                  onClick={handleCompleteBack}
                />
              )}
            </Col>
          ) : folderData?.[0]?.includes(".") ? (
            <Row>
              {folderData?.map((folder, index) => {
                return (
                  <Col
                    xs={8}
                    sm={8}
                    md={6}
                    lg={6}
                    key={index}
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      padding: "10px",
                    }}
                  >
                    {folder?.includes(".xlsx") ? (
                      <FileExcelOutlined
                        className="filestyleGreen"
                        onClick={() => {
                          setBackOption(true);
                          setPathData(folder);
                        }}
                      />
                    ) : (
                      <FilePdfTwoTone
                        className="filestyle"
                        style={{ color: "red" }}
                        onClick={() => {
                          setBackOption(true);
                          setPathData(folder);
                        }}
                      />
                    )}
                    <div
                      className="foldernameStyle"
                      style={{ textAlign: "center", marginTop: "20px" }}
                    >
                      {folder}
                    </div>
                  </Col>
                );
              })}
            </Row>
          ) : (
            <Row>
              {folderData?.length === 0 ? (
                <div>
                  <DeleteOutlined
                    style={{
                      fontSize: "200px",
                      marginLeft: "45vw",
                      marginTop: "10vh",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "30px",
                      marginLeft: "48.5vw",
                      marginTop: "-2vh",
                      fontWeight: "bold",
                    }}
                  >
                    No data
                  </div>
                </div>
              ) : (
                <>
                  {folderData?.map((folder, index) => {
                    return (
                      <Col
                        xs={8}
                        sm={6}
                        md={4}
                        lg={4}
                        key={index}
                        style={{ textAlign: "center", }}
                      >
                        <FolderOpenFilled
                          className="folderstyle"
                          style={{ pointerEvents: isClicked ? "none" : "auto" }}
                          onClick={() => {
                            setBackOption(true);
                            setPathData(folder);
                            setApiRecall(!apiRecall);
                          }}
                        />
                        <div className="foldernameStyle" style={{padding:"4px 15px"}}>{folder}</div>
                      </Col>
                    );
                  })}
                </>
              )}
            </Row>
          )}
        </Spin>
      </div>
    </>
  );
};

export default Reports;
