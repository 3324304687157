/* eslint-disable react/prop-types */
import { notification, Tabs, Spin } from "antd";
import React, { useState, useEffect } from "react";
import Elevator from "./Elevator";
import { AlertBaseUrl } from "../../common/BaseUrl";
import axios from "axios";
import LoadParameterTab from "./LoadParameterTab";
import SupplyParamterTab from "./SupplyParamterTab";
import "./Styles.css";
const NewEventModal = ({
  setSelectFilter,
  selectFilter,
  setSelectTab,
  selectTab,
  setEventModal,
  editRowData,
  setTableLoad,
  tableLoad,
  // setEditRowData,
  getAlertList,
  setNewTableLoad,
  newtableLoad,
  listAPI,
  setlistApi,
}) => {
  const accessToken = window.localStorage.getItem("accessToken");
  const [selectIndex, setSelectIndex] = useState({
    country: "",
    city: "",
    location: "",
    store: "",
    elevator: "",
  });
  const [deviceId, setDeviceId] = useState();
  const [params, setParams] = useState();
  const [buttonSave, setButtonSave] = useState(false);
  const [responseApi, setResponseApi] = useState(false);
  const [pageLoader, setPageLoader] = useState(false)



  useEffect(() => {
    if (buttonSave) {
      if (editRowData) {
        setPageLoader(true)
        axios
          .post(`${AlertBaseUrl}/${editRowData?.ALERT_ID}`, params, {
            headers: { ["x-access-token"]: accessToken },
          })
          .then((response) => {
            setPageLoader(false)
            setEventModal(false);
            setTableLoad(!tableLoad);
            setResponseApi(!responseApi);
            if (response?.data?.data?.messsage === "Alert Already Exists") {
              
              notification.success({
                description: response?.data?.data?.message,
              });
            } else {
              
              notification.success({
                description: response?.data?.data?.message,
              });
            }
          })
          .catch((error) => {
            console.error("There was an error!", error);
            // setIsLoading(false);
          });
        // setEditRowData("");
      } else {
        setPageLoader(true)
        axios
          .post(AlertBaseUrl, params, {
            headers: { ["x-access-token"]: accessToken },
          })
          .then((response) => {
            setPageLoader(false)
            // form.resetFields();
            setEventModal(false);
            setlistApi(!listAPI);

            if (response?.data?.data?.messsage === "Alert Already Exists") {
              notification.success({
                description: response?.data?.data?.message,
              });
            } else {
              notification.success({
                description: response?.data?.data?.message,
              });
            }
          })
          .catch((error) => {
            console.error("There was an error!", error);
            // setIsLoading(false);
          });
      }
    }
  }, [buttonSave, params]);
 

  return (
    <>
      <h3 style={{textAlign:"center", fontWeight:"bold",}}>ALERT CONFIGURATION</h3>
      <Spin spinning={pageLoader} tip="Loading..." size="large">
      <Tabs
        defaultActiveKey={
          editRowData?.PARAMETER === 101
            ? "1"
            : editRowData?.PARAMETER === 0
            ? "2"
            : editRowData?.PARAMETER === 1
            ? "3"
            : "1"
        }
        className="tabBottomStyle"
      >
        <Tabs.TabPane disabled={editRowData?.PARAMETER ==1 || editRowData?.PARAMETER ===0 ?true:false} tab="OPERATIONAL SIDE PARAMETER" key="1">
          <Elevator
          getAlertList={getAlertList}
            setSelectTab={setSelectTab}
            selectTab={selectTab}
            setEventModal={setEventModal}
            selectFilter={selectFilter}
            setSelectFilter={setSelectFilter}
            selectIndex={selectIndex}
            setSelectIndex={setSelectIndex}
            deviceId={deviceId}
            setDeviceId={setDeviceId}
            editRowData={editRowData}
            setTableLoad={setTableLoad}
            setNewTableLoad={setNewTableLoad}
            newtableLoad={newtableLoad}
            // setEditRowData={setEditRowData}
            tableLoad={tableLoad}
            setParams={setParams}
            params={params}
            setButtonSave={setButtonSave}
            responseApi={responseApi}
          />
        </Tabs.TabPane>
        <Tabs.TabPane disabled={editRowData?.PARAMETER ==101 || editRowData?.PARAMETER ===1 ?true:false} tab="SUPPLY SIDE PARAMETER" key="2">
          <SupplyParamterTab
          getAlertList={getAlertList}
            setSelectTab={setSelectTab}
            selectTab={selectTab}
            setEventModal={setEventModal}
            selectFilter={selectFilter}
            setSelectFilter={setSelectFilter}
            selectIndex={selectIndex}
            setSelectIndex={setSelectIndex}
            deviceId={deviceId}
            setDeviceId={setDeviceId}
            editRowData={editRowData}
            setTableLoad={setTableLoad}
            // setEditRowData={setEditRowData}
            tableLoad={tableLoad}
            setParams={setParams}
            params={params}
            setButtonSave={setButtonSave}
            responseApi={responseApi}
          />
        </Tabs.TabPane>
        <Tabs.TabPane disabled={editRowData?.PARAMETER ==101 || editRowData?.PARAMETER ===0 ?true:false} tab="LOAD SIDE PARAMETER" key="3">
          <LoadParameterTab
          getAlertList={getAlertList}
            setSelectTab={setSelectTab}
            selectTab={selectTab}
            setEventModal={setEventModal}
            selectFilter={selectFilter}
            setSelectFilter={setSelectFilter}
            selectIndex={selectIndex}
            setSelectIndex={setSelectIndex}
            deviceId={deviceId}
            setDeviceId={setDeviceId}
            editRowData={editRowData}
            setTableLoad={setTableLoad}
            // setEditRowData={setEditRowData}
            tableLoad={tableLoad}
            setParams={setParams}
            params={params}
            setButtonSave={setButtonSave}
            responseApi={responseApi}
          />
        </Tabs.TabPane>
      </Tabs>
      </Spin>
    </>
  );
};

export default NewEventModal;
