import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import RealTimeMonitoring from "./pages/realTimeMonitoring/RealTimeMonitoring";
import "./App.css";
import AnalysisDetails from "./pages/reportsAndAnalysis/AnalysisDetails";
import ReportsAnalysisParameter from "./pages/reportsAndAnalysis/ReportsAnalysisParameter";
import Login from "./pages/login/Login";
import AuthLayout from "./common/AuthLayout";
import OnBoarding from "./pages/onBoarding/OnBoarding";
import AlertsAndNotifications from "./pages/alertsAndNotifications/AlertsAndNotifications";
import RealtimeMonitoringCards from "./pages/realTimeMonitoring/elevatorDetails/RealtimeMonitoringCards";
import SpaceOverview from "./pages/onBoarding/SpaceOverview";
import SignUp from "./pages/login/SignUp";
import UserControl from "./pages/userControl/UserControl";
import EulaConfirm from "./pages/login/EulaConfirm";
import PeriodicMaintanance from "./pages/periodicMaintanance/PeriodicMaintanance";
import RealTimeMainTabpage from "./pages/realTimeMonitoring/RealTimeMainTabpage";
import ReportsModule from "./pages/reports/ReportsModule";
import Parent from "./pages/onBoarding/parentData/Parent";
import PhantomStore from "./pages/onBoarding/storeData/PhantomStore";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/license" element={<EulaConfirm />} />
        <Route exact path="/registration" element={<SignUp />} />

        <Route exact element={<AuthLayout />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route
            exact
            path="/real-time-monitoring"
            element={<RealtimeMonitoringCards />}
          />
          <Route
            exact
            path="/real-time-monitoring-Details"
            element={<RealTimeMonitoring />}
          />
          <Route
            exact
            path="/real-time-monitoring-perameter"
            element={<RealTimeMainTabpage />}
          />
          <Route exact path="/reports-analysis" element={<AnalysisDetails />} />

          <Route
            exact
            path="/reports-analysis-details-parameters"
            element={<ReportsAnalysisParameter />}
          />
          <Route
            exact
            path="/alert-notification"
            element={<AlertsAndNotifications />}
          />
             <Route
            exact
            path="/periodic-maintainance"
            element={<PeriodicMaintanance/>}
          />
           <Route
            exact
            path="/reports"
            element={<ReportsModule/>}
          />
          <Route exact path="/on-boarding" element={<OnBoarding />} />
          <Route exact path="/user-control" element={<UserControl />} />
          <Route exact path="/parent-configuration" element={<Parent/>} />
          <Route exact path="/store-configuration" element={<PhantomStore/>} />
          <Route
            exact
            path="/on-boarding-overview"
            element={<SpaceOverview />}
          />
        </Route>
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
