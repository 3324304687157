/* eslint-disable react/prop-types */
import React from "react";
import { Pie, G2 } from "@ant-design/plots";
import { useNavigate } from "react-router";

const DemoPie = ({ chartData, called }) => {
  const navigate = useNavigate();
  const G = G2.getEngine("canvas");
  let data = [];
  let active;
  let idle;

  if (
    chartData &&
    (called === "RTMElevator" || called === "reportsActiveHours")
  ) {
    for (let k in chartData) {
      data.push({ key: k?.toUpperCase(), value: chartData[k] });
    }
    active = chartData?.active;
    idle = chartData?.idle;
  }
  if (called === "alerts") {
    data = [...chartData];
  }
  if (called === "elevatorStatus" || called === "reportsStatus") {
    for (let k in chartData) {
      data.push({
        key: k?.toUpperCase(),
        value: chartData[k]?.value,
        devices: chartData[k]?.devices,
      });
    }
    active = chartData?.active?.value;
    idle = chartData?.idle?.value;
  }

  let toralActive = (active + idle)?.toFixed(2);

  let arr = toralActive?.split(".");

  const config = {
    data,

    angleField: "value",
    colorField:
      called === "elevatorStatus" ||
      called === "RTMElevator" ||
      called === "reportsStatus" ||
      called === "reportsActiveHours"
        ? "key"
        : "type",
    color:
      called === "elevatorStatus" ||
      called === "RTMElevator" ||
      called === "reportsStatus" ||
      called === "reportsActiveHours"
        ? ["#78BE20", "#008ECF", "#BFC0C2"]
        : [
            "#008ECF",
            "#F0871E",
            "#BFC0C2",
            "#00A8B0",
            "#EA0016",
            "#B90276",
            "#50237E",
            "#78BE20",
            "#006249",
            "#525F6B",
          ],

    radius:
      called === "RTMElevator" && "reportsActiveHours"
        ? 0.75
        : called === "reportsActiveHours"
        ? 0.75
        : 1,
    innerRadius: 0.64,

    label:
      called === "RTMElevator"
        ? {
            type: "spider",
            labelHeight: 40,
            formatter: (data, mappingData) => {
              const group = new G.Group({});
              let rr = data.value.toFixed(2);

              let arr = rr.split(".");
              group.addShape({
                type: "circle",
                attrs: {
                  x: 0,
                  y: 0,
                  width: 40,
                  height: 50,
                  r: 5,
                  fill: mappingData.color,
                },
              });
              group.addShape({
                type: "text",
                attrs: {
                  x: 10,
                  y: 8,
                  text: `${data.key}`,
                  fill: mappingData.color,
                },
              });
              group.addShape({
                type: "text",
                attrs: {
                  x: 0,
                  y: 25,
                  text: `${arr[0] <= 9 ? "0" + arr[0] : arr[0]}:${
                    ((arr[1] * 60) / 100).toFixed() <= 9
                      ? "0" + ((arr[1] * 60) / 100).toFixed()
                      : ((arr[1] * 60) / 100).toFixed()
                  } (HH:MM)`,
                  fill: "rgba(0, 0, 0, 0.65)",
                  fontWeight: 700,
                },
              });
              return group;
            },
          }
        : called === "reportsActiveHours"
        ? {
            type: "spider",
            labelHeight: 40,
            formatter: (data, mappingData) => {
              const group = new G.Group({});
              let rr = data.value.toFixed(2);
              let arr = rr.split(".");
              group.addShape({
                type: "circle",
                attrs: {
                  x: 0,
                  y: 0,
                  width: 40,
                  height: 50,
                  r: 5,
                  fill: mappingData.color,
                },
              });
              group.addShape({
                type: "text",
                attrs: {
                  x: 10,
                  y: 8,
                  text: `${data.key}`,
                  fill: mappingData.color,
                },
              });
              group.addShape({
                type: "text",
                attrs: {
                  x: 0,
                  y: 25,
                  text: `${arr[0] <= 9 ? "0" + arr[0] : arr[0]}:${
                    ((arr[1] * 60) / 100).toFixed() <= 9
                      ? "0" + ((arr[1] * 60) / 100).toFixed()
                      : ((arr[1] * 60) / 100).toFixed()
                  } (HH:MM)`,
                  fill: "rgba(0, 0, 0, 0.65)",
                  fontWeight: 700,
                },
              });
              return group;
            },
          }
        : {
            type: "inner",
            offset: "-50%",
            style: {
              textAlign: "center",
            },
            autoRotate: false,
            content: "{value}",
          },
    meta: {
      PERIODIC_MAINTANANCE: {
        alias: "PERIODIC MAINTANANCE",
      },
      SOP_DEVIATION: {
        alias: "SOP DEVIATION",
      },
      OFFLINE: {
        alias: "OFFLINE",
      },
      NOT_OPERATIONAL: {
        alias: "NOT OPERATIONAL",
      },
      DISRUPTIONS: {
        alias: "DISRUPTIONS",
      },
    },
    legend: {
      layout: "vertical",
      position: "right",
      flipPage: false,
      offsetX: called === "alerts" && -265,
    },

    interactions: [
      {
        type: "tooltip",
        enable:
          called === "reportsActiveHours"
            ? false
            : called === "RTMElevator"
            ? false
            : true,
      },
    ],
    onReady: (plot) => {
      plot.on("element:click", (...args) => {
        let navigationData = args?.[0]?.data?.data;

        called === "elevatorStatus"
          ? navigate("/real-time-monitoring", { state: navigationData })
          : called === "alerts" &&
            navigate("/alert-notification", {
              state: { states: navigationData, types: "CATEGORY" },
            });
      });
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: called === "RTMElevator" ? "15px" : "20px",
        },
        content:
          called === "elevatorStatus" || called === "reportsStatus"
            ? `${chartData?.active?.value}/${
                chartData?.active?.value +
                chartData?.offline?.value
              }\nActive`
            : called === "RTMElevator" || called === "reportsActiveHours"
            ? `${arr[0] <= 9 ? "0" + arr[0] : arr[0]}:${
                ((arr[1] * 60) / 100).toFixed() <= 9
                  ? "0" + ((arr[1] * 60) / 100).toFixed()
                  : ((arr[1] * 60) / 100).toFixed()
              }\nHours`
            : called === "alerts"
            ? `${
              chartData.reduce((accumulator, item) => accumulator + item.value, 0)
              
              } \nAlerts`
            : "",
      },
    },
  };

  return (
    <div style={{ padding: "20px", marginTop: "-10px" }}>
      <Pie
        {...config}
        style={{
          marginLeft: called === "alerts" && "-19vw",
          height:
            called === "RTMElevator"
              ? "15rem"
              : called === "alerts"
              ? "10.5rem"
              : "16rem",
        }}
      />
    </div>
  );
};

export default DemoPie;
